/* eslint react/sort-comp:0 */
/* eslint class-methods-use-this:0 */
import { PropTypes } from 'prop-types';
import { Component } from 'react';

class Scroll extends Component {
  constructor(props) {
    super(props);
    this.state = { position: 0 };

    this.scrolling = false;
    this.startCoordinates = 0;
    this.startPosition = 0;
    this.scrollTimestamp = 0;

    this.startScrolling = this.startScrolling.bind(this);
    this.scroll = this.scroll.bind(this);
    this.stopScrolling = this.stopScrolling.bind(this);
    this.onWheel = this.onWheel.bind(this);

    this.touchStart = this.touchStart.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.swipeStart = this.swipeStart.bind(this);
    this.touchSwipe = this.touchSwipe.bind(this);

    this.backgroundColor = '#ddd';
    this.barColor = '#06bbfe';
    this.width = 0;
  }
  componentDidMount() {
    window.addEventListener('mousemove', this.scroll);
    window.addEventListener('mouseup', this.stopScrolling);
    this.scrollbar.addEventListener('mousedown', this.startScrolling);

    this.scrollbar.addEventListener('touchstart', this.touchStart);
    this.scrollbar.addEventListener('touchmove', this.touchMove);
    this.scrollbar.addEventListener('touchend', this.touchEnd);

    if (!this.props.vertical) {
      if (this.container) {
        this.width = this.container.offsetWidth;
      }
    }
    setTimeout(() => {
      let target = this.props.target();
      if (target) {
        target = target.childNodes[0];
        target.addEventListener('wheel', this.onWheel);
        target.addEventListener('touchstart', this.swipeStart);
        target.addEventListener('touchmove', this.touchSwipe);
        target.addEventListener('touchend', this.touchEnd);
      }
    }, 1);
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.scroll);
    window.removeEventListener('mouseup', this.stopScrolling);
    this.scrollbar.removeEventListener('mousedown', this.startScrolling);

    this.scrollbar.removeEventListener('touchstart', this.touchStart);
    this.scrollbar.removeEventListener('touchmove', this.touchMove);
    this.scrollbar.removeEventListener('touchend', this.touchEnd);
    setTimeout(() => {
      let target = this.props.target();
      if (target) {
        target = target.childNodes[0];
        target.removeEventListener('wheel', this.onWheel);
        target.removeEventListener('touchstart', this.swipeStart);
        target.removeEventListener('touchmove', this.touchSwipe);
        target.removeEventListener('touchend', this.touchEnd);
      }
    }, 1);
  }
  componentWillReceiveProps() {
    if (!this.props.vertical) {
      setTimeout(() => {
        if (this.container) {
          this.width = this.container.offsetWidth;
        }
      }, 500);
    }
  }
  touchStart(e) {
    this.startScrolling(e);
  }
  touchEnd(e) {
    this.stopScrolling(e);
  }
  touchMove(e) {
    this.scroll(e);
  }
  onWheel(e) {
    e.preventDefault();
    if (e.timeStamp - this.scrollTimestamp > 50) {
      let margin = this.startPosition;
      if (this.props.vertical) {
        if (e.deltaY === 0) return;
        let delta = e.deltaY > 0 ? 1 : -1;
        if (e.wheelDelta) {
          delta = e.deltaY / Math.abs(e.wheelDeltaY);
        }
        delta *= 40 + 5 * this.props.proportion;
        margin = this.trimMargin(this.startPosition + delta);
      } else {
        if (e.deltaX === 0) return;
        let delta = e.deltaX > 0 ? 1 : -1;
        if (e.wheelDelta) {
          delta = e.deltaX / Math.abs(e.wheelDeltaX);
        }
        delta *= 40 + 5 * this.props.proportion;
        margin = this.trimMargin(this.startPosition + delta);
      }
      this.startPosition = margin;
      this.setState({ position: `${margin}px` });
      this.props.onScroll(margin, e);
      this.scrollTimestamp = e.timeStamp;
    }
  }
  trimMargin(margin) {
    if (margin < 0) return 0;
    if (this.props.vertical) {
      const maxMargin = this.props.height - this.props.barHeight;
      if (margin > maxMargin) return maxMargin;
    } else {
      const maxMargin = this.width - this.props.barWidth;
      if (margin > maxMargin) return maxMargin;
    }

    return margin;
  }
  getPageXY(e) {
    if (e.touches) {
      return { pageY: e.touches[0].pageY, pageX: e.touches[0].pageX };
    }
    return { pageY: e.pageY, pageX: e.pageX };
  }
  startScrolling(e) {
    const { pageY, pageX } = this.getPageXY(e);
    this.scrolling = true;
    if (this.props.vertical) {
      this.startCoordinates = pageY;
      let start = parseInt(this.scrollbar.style.marginTop, 10);
      if (Number.isNaN(start)) start = 0;
      this.startPosition = start;
    } else {
      this.startCoordinates = pageX;
      let start = parseInt(this.scrollbar.style.marginLeft, 10);
      if (Number.isNaN(start)) start = 0;
      this.startPosition = start;
    }
  }
  scroll(e) {
    if (this.scrolling && e.timeStamp - this.scrollTimestamp > 100) {
      const { pageY, pageX } = this.getPageXY(e);
      e.preventDefault();
      let margin = 0;
      if (this.props.vertical) {
        margin = this.trimMargin(this.startPosition + pageY - this.startCoordinates);
      } else {
        margin = this.trimMargin(this.startPosition + pageX - this.startCoordinates);
      }
      this.setState({ position: `${margin}px` });
      this.props.onScroll(margin, e);
    }
  }
  stopScrolling() {
    if (this.scrolling) {
      this.scrolling = false;
      if (!this.scrollbar) return;

      let start;
      if (this.props.vertical) {
        start = parseInt(this.scrollbar.style.marginTop, 10);
      } else {
        start = parseInt(this.scrollbar.style.marginLeft, 10);
      }
      if (Number.isNaN(start)) {
        start = 0;
      }
      this.startPosition = start;
    }
  }
  swipeStart(e) {
    if (e.target === this.scrollbar) return;
    this.touchStart(e);
  }
  touchSwipe(e) {
    e.preventDefault();
    let margin = 0;
    if (this.props.vertical) {
      const shift = -1 * (e.touches[0].pageY - this.startCoordinates);
      margin = this.trimMargin(this.startPosition + shift);
    } else {
      const shift = -1 * (e.touches[0].pageX - this.startCoordinates);
      margin = this.trimMargin(this.startPosition + shift);
    }
    this.setState({ position: `${margin}px` });
    this.props.onScroll(margin, e);
  }
  setScroll(position) {
    this.startPosition = position;
    this.setState({ position });
  }
  render() {
    if (this.props.vertical) {
      return (
        <div
          style={{
            height: this.props.height,
            width: '16px',
            background: this.backgroundColor,
            position: 'absolute',
            top: '0',
            right: '-16px',
          }}
        >
          <div
            ref={ref => {
              this.scrollbar = ref;
            }}
            style={{
              height: this.props.barHeight,
              width: '16px',
              background: this.barColor,
              marginTop: this.state.position,
            }}
          />
        </div>
      );
    }
    return (
      <div
        ref={ref => {
          this.container = ref;
        }}
        style={{
          height: '16px',
          width: '100%',
          background: this.backgroundColor,
          float: 'left',
        }}
      >
        <div
          ref={ref => {
            this.scrollbar = ref;
          }}
          style={{
            height: '16px',
            width: this.props.barWidth,
            background: this.barColor,
            marginLeft: this.state.position,
          }}
        />
      </div>
    );
  }
}
Scroll.propTypes = {
  vertical: PropTypes.bool,
  target: PropTypes.func,
  proportion: PropTypes.number,
  onScroll: PropTypes.func,
  height: PropTypes.number,
  barHeight: PropTypes.number,
  barWidth: PropTypes.number,
};
export default Scroll;
