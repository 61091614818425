import { connect } from 'react-redux';

import SideMenu from './SideMenu.jsx';

const mapStateToProps = state => ({
  location: state.routing.locationBeforeTransitions,
});

const SideMenuContainer = connect(mapStateToProps)(SideMenu);

export default SideMenuContainer;
