const TARGET_API = import.meta.env.VITE_TARGET_API;
const USE_PATTERN_TARGET_API = TARGET_API === 'pattern';

export const GOOGLE_CLIENT_ID = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_GOOGLE_CLIENT_ID}}'
  : import.meta.env.VITE_GOOGLE_CLIENT_ID;

export const GOOGLE_MAPS_API = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_GOOGLE_MAPS_API}}'
  : import.meta.env.VITE_GOOGLE_MAPS_API;

export const TALKJS_APP_ID = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_TALKJS_APP_ID}}'
  : import.meta.env.VITE_TALKJS_APP_ID;

export const LOG_ROCKET_APP_ID = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_LOG_ROCKET_APP_ID}}'
  : import.meta.env.VITE_LOG_ROCKET_APP_ID;

export const AZURE_APP_CLIENT_ID = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_AZURE_APP_CLIENT_ID}}'
  : import.meta.env.VITE_AZURE_APP_CLIENT_ID;

export const INTERCOM_ID_PREFIX = USE_PATTERN_TARGET_API
  ? '{{RUNTIME_ENV_INTERCOM_ID_PREFIX}}'
  : import.meta.env.VITE_INTERCOM_ID_PREFIX;

export const SENTRY_DSN = USE_PATTERN_TARGET_API ? '{{RUNTIME_ENV_SENTRY_DSN}}' : import.meta.env.VITE_SENTRY_DSN;
