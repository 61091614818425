import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';

import { MDCheckbox, MDMultiSelectHeader } from '../../MDComponents';
import { messages } from './KadroListSelect.messages';

import './KadroListSelect.scss';

const KadroListSelect = (props, { intl }) => {
  const { items, handlers, customHeight, withoutSelectAll, withoutDeselectAll, title } = props;

  return (
    <>
      <MDMultiSelectHeader
        title={title || intl.formatMessage(messages.headerTitle)}
        selectAll={handlers.selectAll}
        deselectAll={handlers.deselectAll}
        selectAllMessage={intl.formatMessage(messages.selectAll)}
        deselectAllMessage={intl.formatMessage(messages.deselectAll)}
        withoutSelectAll={withoutSelectAll}
        withoutDeselectAll={withoutDeselectAll}
      />
      <OverlayScrollbarsComponent
        className="k-overlay-scrollbar k-kadroListSelect__box"
        style={{ minHeight: customHeight, maxHeight: customHeight }}
      >
        {items.map(item => (
          <MDCheckbox
            key={item.value}
            id={item.value}
            text={item.label}
            value={item.active}
            onChange={() => handlers.toggle(item)}
            reverse
          />
        ))}
      </OverlayScrollbarsComponent>
    </>
  );
};

KadroListSelect.contextTypes = {
  intl: PropTypes.shape({}),
};

KadroListSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handlers: PropTypes.shape({
    selectAll: PropTypes.func,
    deselectAll: PropTypes.func,
    toggle: PropTypes.func,
  }),
  customHeight: PropTypes.string,
  withoutSelectAll: PropTypes.bool,
  withoutDeselectAll: PropTypes.bool,
  title: PropTypes.string,
};

export default KadroListSelect;
