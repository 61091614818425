import { defineMessages } from 'react-intl';

import { DEFAULT_AVA_TYPE } from '@/constants/availabilityDefaultTypes';
import { compareByKey } from '@/utils/baseHelpers.js';

export const messages = defineMessages({
  available: {
    id: 'avaTypesTable.available',
    defaultMessage: 'Dostępny',
  },
  unavailable: {
    id: 'avaTypesTable.unavailable',
    defaultMessage: 'Niedostępny',
  },
  availableInHours: {
    id: 'avaTypesTable.availableInHours',
    defaultMessage: 'Dostępny w danych godzinach',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.avaTypesTable.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.avaTypesTable.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaFilterText: {
    id: 'companyManage.avaTypesTable.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

export const defaultAvaTypes = [
  {
    name: messages.available,
    requires_time: false,
    requires_approval: false,
    optional_comment: false,
    type: DEFAULT_AVA_TYPE,
    color: '#86bf00',
    id: 'available',
  },
  {
    name: messages.unavailable,
    requires_time: false,
    requires_approval: false,
    optional_comment: false,
    type: DEFAULT_AVA_TYPE,
    color: '#ff0000',
    id: 'unavailable',
  },
  {
    name: messages.availableInHours,
    requires_time: true,
    requires_approval: false,
    optional_comment: false,
    type: DEFAULT_AVA_TYPE,
    color: '#dddddd',
    id: 'freeTime',
  },
];

export const defaultAvaTypeIds = defaultAvaTypes.map(type => type.id);

export const sortedAvaByType = (data, avaType, compareKeys, direction) =>
  data.filter(ava => ava.type === avaType).sort(compareByKey(compareKeys, direction));
