import { dateMoveDirections } from '@/constants/dateModes';

import {
  generateDateArrayForCustomMode,
  generateDateArrayWithShiftInt,
  generateDateTextFromArray,
  generateHeaderDateArray,
  getDateShiftInt,
  getRangeBetweenDates,
  getToday,
  isCustomDateMode,
} from './dateHelper.js';

export const changeMode = (state, action) => {
  const changedModeState = { ...state };
  changedModeState.dateMode = action.payload;
  changedModeState.dateShiftInt = 0;
  changedModeState.dateArray = isCustomDateMode(changedModeState)
    ? getRangeBetweenDates(changedModeState.customDate.start, changedModeState.customDate.end)
    : generateDateArrayWithShiftInt(action.payload, changedModeState.dateShiftInt);
  changedModeState.customDate = {
    start: changedModeState.dateArray[0],
    end: changedModeState.dateArray[changedModeState.dateArray.length - 1],
  };
  changedModeState.previousDateArray = generateDateArrayWithShiftInt(action.payload, changedModeState.dateShiftInt - 1);
  changedModeState.headerDateArray = generateHeaderDateArray(action.payload, changedModeState.dateArray);
  changedModeState.dateText = generateDateTextFromArray(action.payload, changedModeState.dateArray);
  changedModeState.today = getToday();
  return changedModeState;
};

export const moveLeft = state => {
  const leftState = { ...state };
  const isCustomMode = isCustomDateMode(leftState);
  leftState.dateShiftInt = isCustomMode ? 0 : leftState.dateShiftInt - 1;
  leftState.previousDateArray = isCustomMode
    ? [...leftState.dateArray]
    : generateDateArrayWithShiftInt(leftState.dateMode, leftState.dateShiftInt - 1);
  leftState.dateArray = isCustomMode
    ? generateDateArrayForCustomMode(leftState.dateArray, dateMoveDirections.previous)
    : generateDateArrayWithShiftInt(leftState.dateMode, leftState.dateShiftInt, leftState.dateArray);
  leftState.customDate = {
    start: leftState.dateArray[0],
    end: leftState.dateArray[leftState.dateArray.length - 1],
  };
  leftState.headerDateArray = generateHeaderDateArray(leftState.dateMode, leftState.dateArray);
  leftState.dateText = generateDateTextFromArray(leftState.dateMode, leftState.dateArray);
  leftState.today = getToday();
  return leftState;
};

export const moveRight = state => {
  const rightState = { ...state };
  const isCustomMode = isCustomDateMode(rightState);
  rightState.dateShiftInt = isCustomMode ? 0 : rightState.dateShiftInt + 1;
  rightState.previousDateArray = isCustomMode
    ? [...rightState.dateArray]
    : generateDateArrayWithShiftInt(rightState.dateMode, rightState.dateShiftInt - 1);
  rightState.dateArray = isCustomMode
    ? generateDateArrayForCustomMode(rightState.dateArray, dateMoveDirections.next)
    : generateDateArrayWithShiftInt(rightState.dateMode, rightState.dateShiftInt, rightState.dateArray);
  rightState.customDate = {
    start: rightState.dateArray[0],
    end: rightState.dateArray[rightState.dateArray.length - 1],
  };
  rightState.headerDateArray = generateHeaderDateArray(rightState.dateMode, rightState.dateArray);
  rightState.dateText = generateDateTextFromArray(rightState.dateMode, rightState.dateArray);
  rightState.today = getToday();
  return rightState;
};

export const moveToDate = (state, action) => {
  const newState = { ...state };
  newState.dateShiftInt = action.shiftInt;
  newState.dateArray = generateDateArrayWithShiftInt(newState.dateMode, newState.dateShiftInt);
  newState.customDate = {
    start: newState.dateArray[0],
    end: newState.dateArray[newState.dateArray.length - 1],
  };
  newState.previousDateArray = generateDateArrayWithShiftInt(newState.dateMode, newState.dateShiftInt - 1);
  newState.headerDateArray = generateHeaderDateArray(newState.dateMode, newState.dateArray);
  newState.dateText = generateDateTextFromArray(newState.dateMode, newState.dateArray);
  newState.today = getToday();
  return newState;
};

export const changeCustomDate = (state, action) => {
  const newState = { ...state };
  newState.dateArray = getRangeBetweenDates(action.payload.start, action.payload.end);
  newState.customDate = {
    start: action.payload.start,
    end: action.payload.end,
  };
  newState.headerDateArray = generateHeaderDateArray(newState.dateMode, newState.dateArray);
  newState.dateText = generateDateTextFromArray(newState.dateMode, newState.dateArray);
  newState.today = getToday();
  return newState;
};

export const changeModeAndDate = (state, action) => {
  const { dateMode, start, end } = action.payload;
  const newState = { ...state };
  const isCustomMode = isCustomDateMode(newState);
  newState.dateMode = dateMode;
  newState.previousDateArray = isCustomMode
    ? [...newState.dateArray]
    : generateDateArrayWithShiftInt(newState.dateMode, newState.dateShiftInt - 1);
  newState.dateShiftInt = isCustomDateMode(newState) ? 0 : getDateShiftInt(dateMode, start);
  newState.dateArray = getRangeBetweenDates(start, end);
  newState.customDate = { start, end };
  newState.headerDateArray = generateHeaderDateArray(dateMode, newState.dateArray);
  newState.dateText = generateDateTextFromArray(dateMode, newState.dateArray);
  newState.today = getToday();
  return newState;
};
