import {
  ADD_NEW_JOBTITLE,
  CHANGE_JOBTITLE_FILTER,
  CHANGE_JOBTITLE_FILTER_GROUPED,
  CLEAR_DEMO_DATA,
} from '@/constants/ActionTypes.js';

const initialState = {
  selectedJobtitles: [],
  selectedJobtitlesGrouped: [],
};

const jobtitleFilter = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_JOBTITLE_FILTER:
      return Object.assign({}, state, { selectedJobtitles: action.payload });
    case CHANGE_JOBTITLE_FILTER_GROUPED:
      return Object.assign({}, state, { selectedJobtitlesGrouped: action.payload });
    case CLEAR_DEMO_DATA:
      return initialState;
    case ADD_NEW_JOBTITLE:
      return Object.assign({}, state, { selectedJobtitles: [...state.selectedJobtitles, action.payload] });
    default:
      return state;
  }
};
export default jobtitleFilter;
