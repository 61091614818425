import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { userHasPermissionsToRoute } from '@/utils/permissionHelpers';
import { allNavs } from '@/utils/routes';

export const MainPermissionsWrapper = props => {
  const [showError, setShowError] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkRoute(props, location);
    props.onRouteChange(location);
  }, [location.pathname, props]);

  const checkRoute = (props, location) => {
    let route = allNavs().find(nav => nav.path === `/${location?.pathname.split('/')[1]}`);
    if (route) route = { ...route };
    const isDashboard = route?.path === '/';
    const userHasPermissionForRoute = userHasPermissionsToRoute(props.userPermissions, route);
    const userHasRestrictionForRoute = route?.restriction?.some(restriction =>
      props.userPermissions.restrictions.includes(restriction),
    );
    const userHasAccessBlockedForRoute =
      route && route.accessCheckFunction
        ? !route.accessCheckFunction(props.userPermissions, props.user, props.company)
        : false;

    if (
      route &&
      (userHasRestrictionForRoute || !userHasPermissionForRoute || userHasAccessBlockedForRoute) &&
      !isDashboard
    ) {
      navigate('/403');
    }

    if (route && (userHasRestrictionForRoute || !userHasPermissionForRoute) && isDashboard) {
      const newRoute = allNavs().find(nav => {
        const userHasPermissionForNav = userHasPermissionsToRoute(props.userPermissions, nav);
        const userHasRestrictionForNav = nav.restriction?.some(restriction =>
          props.userPermissions.restrictions.includes(restriction),
        );

        return userHasPermissionForNav && !userHasRestrictionForNav;
      });
      navigate(newRoute.path);
    }

    setShowError(false);
  };

  return (
    <div>
      {showError ? null : (
        <div>
          <Outlet />
        </div>
      )}
    </div>
  );
};

MainPermissionsWrapper.propTypes = {
  userPermissions: PropTypes.shape({ isEmployee: PropTypes.bool }),
  user: PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  onRouteChange: PropTypes.func,
};
