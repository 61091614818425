import { humanFormToMinutes } from '@/utils/dateHelper';

export const filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings = (
  columns,
  userPermissions,
  payoutSettingType,
  companySettings,
) =>
  columns.filter(o => {
    const hasPermissions = !o.permissions.length || o.permissions.some(p => userPermissions.permissions.includes(p));
    const hasRestrictions = o.restrictions.length && o.restrictions.some(r => userPermissions.restrictions.includes(r));
    const hasEnabledCompanySettings =
      !o.companySettings || o.companySettings.every(setting => companySettings[setting]);
    const isShiftsPayoutType = payoutSettingType === 'shifts';
    const shouldBeHiddenForColumnFilter = o.hideForShiftsPayoutType && isShiftsPayoutType;

    return hasPermissions && !hasRestrictions && !shouldBeHiddenForColumnFilter && hasEnabledCompanySettings;
  });

const PAYROLL_STATUSES_ORDER = ['unapproved', 'approved'];
const PAYROLL_LOCATION_STATUSES_ORDER = ['unapproved', 'pending', 'approved'];

export const payrollLocationStatusSortingFunction = (a, b, key, desc) => {
  const aStatus = a[key]?.props?.status;
  const bStatus = b[key]?.props?.status;

  const aOrder = PAYROLL_LOCATION_STATUSES_ORDER.indexOf(aStatus);
  const bOrder = PAYROLL_LOCATION_STATUSES_ORDER.indexOf(bStatus);

  return desc ? bOrder - aOrder : aOrder - bOrder;
};
export const payrollStatusSortingFunction = (a, b, key, desc) => {
  const aStatus = a[key]?.props?.status;
  const bStatus = b[key]?.props?.status;

  const aOrder = PAYROLL_STATUSES_ORDER.indexOf(aStatus);
  const bOrder = PAYROLL_STATUSES_ORDER.indexOf(bStatus);

  return desc ? bOrder - aOrder : aOrder - bOrder;
};

const decimalHoursSortingFunction = (a, b, key, desc) => {
  if (typeof a[key] === 'object') {
    return 1;
  }

  if (typeof b[key] === 'object') {
    return -1;
  }

  const aHours = !a[key] || a[key] === '-' ? 0 : parseFloat(a[key]);
  const bHours = !b[key] || b[key] === '-' ? 0 : parseFloat(b[key]);

  return desc ? bHours - aHours : aHours - bHours;
};

export const hoursSortingFunction = (a, b, key, desc, timeFormatType) => {
  if (timeFormatType === 'decimal') {
    return decimalHoursSortingFunction(a, b, key, desc);
  }
  if (typeof a[key] === 'object') {
    return 1;
  }

  if (typeof b[key] === 'object') {
    return -1;
  }

  const aMinutes = !a[key] || a[key] === '-' ? 0 : humanFormToMinutes(a[key]);
  const bMinutes = !b[key] || b[key] === '-' ? 0 : humanFormToMinutes(b[key]);

  return desc ? bMinutes - aMinutes : aMinutes - bMinutes;
};

export const moneySortingFunction = (a, b, key, desc) => {
  if (typeof a[key] === 'object') {
    return 1;
  }

  if (typeof b[key] === 'object') {
    return -1;
  }
  const aMoney = !a[key] || a[key] === '-' ? 0 : parseFloat(a[key]);
  const bMoney = !b[key] || b[key] === '-' ? 0 : parseFloat(b[key]);

  return desc ? bMoney - aMoney : aMoney - bMoney;
};

const defaultSortingFunction = (a, b, key, desc) =>
  desc ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);

export const sortByMultipleKeys = (array, keys, options = { desc: false }, columns) =>
  array.sort((a, b) => {
    for (const key of keys) {
      if (key !== 'status' && (typeof a[key] === 'object' || typeof b[key] === 'object')) {
        continue;
      }

      const relevantColumn = columns.find(column => column.accessor === key);

      const keyResult = relevantColumn.sortingFunction
        ? relevantColumn.sortingFunction(a, b, key, options.desc, options.timeFormatType)
        : defaultSortingFunction(a, b, key, options.desc);
      if (keyResult !== 0) {
        return keyResult;
      }
    }
    return 0;
  });
