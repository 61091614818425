import PropTypes from 'prop-types';

const SortingArrow = props => {
  if (props.display) {
    return (
      <i
        className="material-icons sortdir noselect"
        style={{
          marginTop: '0px',
        }}
      >
        {props.up ? 'arrow_drop_up' : 'arrow_drop_down'}
      </i>
    );
  }
  return <span />;
};

SortingArrow.propTypes = {
  display: PropTypes.bool.isRequired,
  up: PropTypes.bool.isRequired, // is arrow up if not then down
};

export default SortingArrow;
