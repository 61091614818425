import { PropTypes } from 'prop-types';

const SmallToggle = props => {
  const { checked, left, right, onChange, testId } = props;
  const toggle = () => onChange(!checked);
  return (
    <div className="swichbox period" onClick={toggle} role="presentation" data-test={testId}>
      <span className="noselect">{left}</span>
      <div className="switch-small">
        <input
          id="switch-small-1"
          className="toggle toggle-small"
          type="checkbox"
          onClick={e => e.stopPropagation()}
          checked={checked}
          disabled={false}
        />
        <label htmlFor="switch-small-1" />
      </div>
      <span className="noselect">{right}</span>
    </div>
  );
};
SmallToggle.propTypes = {
  checked: PropTypes.bool,
  left: PropTypes.element,
  right: PropTypes.element,
  onChange: PropTypes.func,
  testId: PropTypes.string,
};
export default SmallToggle;
