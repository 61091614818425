/* eslint-disable camelcase */
import { memo } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { AvatarSvg } from '@/components/svgComponents';
import { getRoleToShow } from '@/utils/accounts/accounts.utils.ts';

import { messages } from './ProfileDetailsModal.messages';
import { useProfileDetailsModal } from './useProfileDetailsModal';

import './ProfileDetailsModal.scss';

export const ProfileDetailsModal = memo(() => {
  const { account, intl, isModalOpen, onCloseModal } = useProfileDetailsModal();

  if (!account) return null;

  const { avatar, firstName, jobTitleNames, lastName, locationNames, roleName, companyName } = account;

  return (
    <MDKadroModal
      show={isModalOpen}
      onHide={onCloseModal}
      modifiers={['narrow']}
      hideCancelButton
      hideConfirmButton
      title={intl.formatMessage(messages.title)}
    >
      <div className="k-profileDetailsModal__personalData">
        <div className="k-profileDetailsModal__avatar">
          {avatar ? <img src={avatar} alt="avatar" /> : <AvatarSvg size={40} />}
        </div>
        <div className="k-profileDetailsModal__personalDataDetails">
          <div className="k-profileDetailsModal__title">
            {firstName} {lastName}
          </div>
          <div className="k-profileDetailsModal__subTitle">{companyName}</div>
        </div>
      </div>
      <div className="k-profileDetailsModal__jobData">
        <div>
          <div className="k-profileDetailsModal__sectionText">{intl.formatMessage(messages.locations)}</div>
          {locationNames.length > 0 ? (
            locationNames.map(name => (
              <div key={name} className="k-profileDetailsModal__text">
                {name}
              </div>
            ))
          ) : (
            <div className="k-profileDetailsModal__text">{intl.formatMessage(messages.noLocations)}</div>
          )}
        </div>
        {roleName !== 'owner' && (
          <div>
            <div className="k-profileDetailsModal__sectionText">{intl.formatMessage(messages.jobTitles)}</div>
            {jobTitleNames.length > 0 ? (
              jobTitleNames.map(title => (
                <div key={title} className="k-profileDetailsModal__text">
                  {title}
                </div>
              ))
            ) : (
              <div className="k-profileDetailsModal__text">{intl.formatMessage(messages.noJobTitles)}</div>
            )}
          </div>
        )}
        <div>
          <div className="k-profileDetailsModal__sectionText">{intl.formatMessage(messages.role)}</div>
          <div className="k-profileDetailsModal__text">{getRoleToShow(roleName, intl)}</div>
        </div>
      </div>
    </MDKadroModal>
  );
});

ProfileDetailsModal.displayName = 'ProfileDetailsModal';
