import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import MDKadroModalHeader from '@/components/common/MDKadroModal/MDKadroModalHeader/ModalHeader.jsx';
import MDModal from '@/components/common/MDModal/Modal.jsx';
import { endOfMonth, startOfMonth } from '@/utils/dateHelper.js';

import { LOAD_BUDGET_TARGET_TYPE } from './LoadBudgetTargetModalInitialView/LoadBudgetTargetModalInitialView.helpers';
import LoadBudgetTargetModalStep from './LoadBudgetTargetModalStep/LoadBudgetTargetModalStep.jsx';

import './loadBudgetTargetModal.scss';

const LoadBudgetTargetModal = ({
  budgetSummary,
  showModal,
  hideModal,
  isModalVisible,
  scheduleLocations,
  firstDayInSchedule,
  editBudgetTargets,
  isMonthMode,
}) => {
  const { estimated_budget: estimatedBudget } = budgetSummary;
  const [step, setStep] = useState(1);
  const initData = {
    scheduleLocationId: scheduleLocations ? scheduleLocations[0] : null,
    estimatedBudget: isMonthMode && estimatedBudget ? Math.round(Number(estimatedBudget) / 100) : 0,
    colTarget: '',
    spmhTarget: '',
    colBudget: '',
    spmhBudget: '',
  };
  const [formData, setFormData] = useState(initData);
  const [selectedStepOption, setSelectedStepOption] = useState(LOAD_BUDGET_TARGET_TYPE.fromExcel.id);
  const from = startOfMonth(firstDayInSchedule);
  const to = endOfMonth(firstDayInSchedule);
  const isSubmitDisabled = !formData.colBudget && !formData.spmhBudget && step === 2;

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      scheduleLocationId: scheduleLocations ? scheduleLocations[0] : null,
      estimatedBudget: isMonthMode && estimatedBudget ? Math.round(Number(estimatedBudget) / 100) : 0,
    }));
  }, [estimatedBudget, scheduleLocations]);

  const getInitialState = () => {
    setStep(1);
    setSelectedStepOption(LOAD_BUDGET_TARGET_TYPE.fromExcel.id);
    setFormData(initData);
  };

  const onHide = () => {
    hideModal();
    getInitialState();
  };

  const onSubmit = () => {
    editBudgetTargets({
      location_id: formData.scheduleLocationId,
      from,
      to,
      target_money: formData.colBudget && formData.colBudget * 100,
      target_minutes: formData.spmhBudget,
    });
    onHide();
  };

  const changeStep = useCallback(value => {
    switch (step) {
      case 1:
        if (selectedStepOption === LOAD_BUDGET_TARGET_TYPE.fromExcel.id) {
          showModal(LOAD_BUDGET_TARGET_TYPE[selectedStepOption].modalType);
        } else {
          setStep(step + 1);
        }
        break;
      case 2:
        if (value === 'increase') {
          onSubmit();
        } else {
          getInitialState();
        }
        break;
      default:
        break;
    }
  });

  return (
    <MDModal
      className="k-loadBudgetTargetModal"
      show={isModalVisible}
      onHide={onHide}
      modifiers={['narrow']}
      withoutOverlayScroll
    >
      <>
        <MDModal.Content withoutOverlayScroll>
          <MDModal.Header>
            <MDKadroModalHeader
              title={<FormattedMessage id="loadBudgetTargetModal.title" defaultMessage="Wczytaj targety budżetu" />}
              onHide={onHide}
            />
          </MDModal.Header>

          {step === 2 && (
            <div className="k-loadBudgetTargetModal__subTitle">
              <FormattedMessage
                id="loadBudgetTargetModal.subTitle"
                defaultMessage="Wartości budżetu zostaną zaimportowane dla okresu {from} – {to}"
                values={{ from, to }}
              />
            </div>
          )}

          <LoadBudgetTargetModalStep
            step={step}
            selectedStepOption={selectedStepOption}
            setSelectedStepOption={setSelectedStepOption}
            formData={formData}
            setFormData={setFormData}
          />
        </MDModal.Content>

        <MDModal.Footer>
          <div className="k-loadBudgetTargetModal__buttons">
            <Button
              modifiers="orange teeny high uppercase"
              className="k-loadBudgetTargetModal__button--next"
              onClick={() => changeStep('increase')}
              disabled={isSubmitDisabled}
            >
              {step === 2 ? (
                <FormattedMessage id="common.save" defaultMessage="Zapisz" />
              ) : (
                <FormattedMessage id="common.next" defaultMessage="Dalej" />
              )}
            </Button>
            {step < 2 ? (
              <Button modifiers="teeny high uppercase reverse-orange" onClick={onHide}>
                <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />
              </Button>
            ) : (
              <Button
                modifiers="reverse-orange teeny high uppercase"
                onClick={() => changeStep('decrease')}
                disabled={step < 2}
              >
                <FormattedMessage id="common.back" defaultMessage="Wstecz" />
              </Button>
            )}
          </div>
        </MDModal.Footer>
      </>
    </MDModal>
  );
};

LoadBudgetTargetModal.propTypes = {
  isModalVisible: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  budgetSummary: PropTypes.shape({
    estimated_budget: PropTypes.number,
  }),
  scheduleLocations: PropTypes.arrayOf(PropTypes.string),
  firstDayInSchedule: PropTypes.string,
  editBudgetTargets: PropTypes.func,
  isMonthMode: PropTypes.bool,
};

export default LoadBudgetTargetModal;
