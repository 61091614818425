import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { absencesModesIds } from '@/constants/viewDateModes';

import TopBarAbsencesDisplayMode from '../filters/TopBarAbsencesDisplayMode/TopBarAbsencesDisplayMode.redux.js';
import TopBarCalendar from '../filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBar from '../TopBar.jsx';

const TopBarAbsences = ({ mainDateChangeMode }) => {
  useEffect(() => {
    mainDateChangeMode('year');
  }, []);

  return (
    <TopBar
      leftElements={[
        <TopBarCalendar mode="schedule" key="calswitch" modes={absencesModesIds} />,
        <TopBarAbsencesDisplayMode />,
      ]}
    />
  );
};

TopBarAbsences.propTypes = {
  mainDateChangeMode: PropTypes.func,
  mainDateStore: PropTypes.shape({}),
};

export default TopBarAbsences;
