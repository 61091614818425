import { connect } from 'react-redux';

import { addAttendancesOvertime } from '@/actions/attendanceOvertime.js';
import { hideModal } from '@/actions/uiState';
import { ACCEPT_OVERTIME_MODAL } from '@/constants/modalTypes.js';

import AcceptOvertimeModal from './AcceptOvertimeModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === ACCEPT_OVERTIME_MODAL,
  modalObject: state.reducer.uiState.modalObject[ACCEPT_OVERTIME_MODAL],
});

const mapDispatchToProps = {
  hideModal,
  addAttendancesOvertime,
};

const AcceptOvertimeModalContainer = connect(mapStateToProps, mapDispatchToProps)(AcceptOvertimeModal);

export default AcceptOvertimeModalContainer;
