import classnames from 'classnames';
import PropTypes from 'prop-types';

import './IconWithLabel.scss';

const IconWithLabel = ({ icon, label, modifier }) => {
  const containerClassnames = classnames('iconWithLabel', {
    'iconWithLabel--error': modifier === 'error',
    'iconWithLabel--warning': modifier === 'warning',
  });
  return (
    <div className={containerClassnames}>
      <i className="material-icons iconWithLabel__icon">{icon}</i>
      <div className="iconWithLabel__label">{label}</div>
    </div>
  );
};

IconWithLabel.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  modifier: PropTypes.oneOf(['error', 'warning']),
};

export default IconWithLabel;
