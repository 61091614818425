import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'addTemplateFromExistingModal.title',
    defaultMessage: 'Dodaj szablon',
  },
  back: {
    id: 'addTemplateFromExistingModal.back',
    defaultMessage: 'wstecz',
  },
  chooseTemplate: {
    id: 'addTemplateFromExistingModal.chooseTemplate',
    defaultMessage: 'Wybierz szablon',
  },
});
