import { connect } from 'react-redux';

import { logoutUser } from '@/actions/auth.jsx';

import AppBlocked from './AppBlocked.jsx';

const mapDispatchToProps = {
  logoutUser,
};

const Container = connect(null, mapDispatchToProps)(AppBlocked);

export default Container;
