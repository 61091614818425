import { connect } from 'react-redux';

import { changeSorting } from '@/actions/companymanage';

import EmploymentConditionsTable from './EmploymentConditionsTable.jsx';

const mapStateToProps = state => ({
  employmentConditions: state.reducer.employmentConditions,
  listsUi: state.reducer.listsUi,
});

const mapDispatchToProps = {
  changeSorting,
};

const EmploymentConditionsTableContainer = connect(mapStateToProps, mapDispatchToProps)(EmploymentConditionsTable);

export default EmploymentConditionsTableContainer;
