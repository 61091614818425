import PropTypes from 'prop-types';

import './MultipleSettingsOptions.scss';

const MultipleSettingsOptions = props => {
  const {
    moreItemsText,
    renderRowOptions,
    rowsData,
    rowName,
    renderHeaderRow,
    firstColumnName = '',
    errorMessage,
    addRow,
    deleteRow,
  } = props;

  return (
    <>
      {renderHeaderRow && (
        <div className="multipleSettingsOptions__row">
          <label className="multipleSettingsOptions__rowName">{firstColumnName}</label>
          <div className="multipleSettingsOptions__options">
            {renderHeaderRow()}
            <div className="multipleSettingsOptions__deleteSpacer" />
          </div>
        </div>
      )}
      {rowsData.map((rowData, index) => (
        <div className="multipleSettingsOptions__row" key={rowData.id}>
          <label className="multipleSettingsOptions__rowName">
            {rowName} {`#${index + 1}`}
          </label>
          <div className="multipleSettingsOptions__options">
            <div>{renderRowOptions(rowData)}</div>
            <div className="multipleSettingsOptions__deleteSpacer">
              {index > 0 && (
                <i className="material-icons multipleSettingsOptions__delete" onClick={() => deleteRow(rowData.id)}>
                  delete
                </i>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="multipleSettingsOptions__addMore" onClick={addRow}>
        <i className="material-icons multipleSettingsOptions__iconAddMore">add</i>
        <div className="multipleSettingsOptions__textAddMore">{moreItemsText}</div>
      </div>
      {errorMessage && <div className="multipleSettingsOptions__errorMessage">{errorMessage}</div>}
    </>
  );
};

MultipleSettingsOptions.propTypes = {
  moreItemsText: PropTypes.node,
  renderRowOptions: PropTypes.func,
  rowsData: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  rowName: PropTypes.node,
  firstColumnName: PropTypes.node,
  renderHeaderRow: PropTypes.func,
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  addRow: PropTypes.func,
  deleteRow: PropTypes.func,
};

export default MultipleSettingsOptions;
