import { connect } from 'react-redux';

import ScheduleBudgetTableSpmhTotalCell from './ScheduleBudgetTableSpmhTotalCell.jsx';

const mapStateToProps = state => ({
  allEstimatedBudget: state.reducer.schedule.budget.budgetEstimates.summary.estimated_budget,
  allSpmhTargets: state.reducer.schedule.budget.budgetEstimates.summary.target_spmh,
  shiftsSummary: state.reducer.schedule.budget.shiftsSummary,
  dateArray: state.reducer.mainDateStore.dateArray,
});

export default connect(mapStateToProps)(ScheduleBudgetTableSpmhTotalCell);
