import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import EditBreaks from '@/components/attendance/breaks/EditBreaks.jsx';
import { MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import MultiSelectOptionManager from '@/components/common/inputs/MultiSelectOptionManager/MultiSelectOptionManager';
import Lock from '@/components/settings/SettingsFeature/EditDisabledView.jsx';
import SettingsFeatureWrapper from '@/components/settings/SettingsFeature/SettingsFeatureWrapper.redux.js';
import { INPUT_MODIFIERS } from '@/constants/modifiers';

class AttendanceEditDataView extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getBreaksChanges = this.getBreaksChanges.bind(this);
  }

  onChange(e) {
    this.props.onInputChange({ attendanceId: this.props.attendance.id, name: 'hours', value: e });
  }

  getBreaksChanges() {
    return this.breaks.getChanges();
  }

  render() {
    const {
      attendance,
      index,
      isAttendanceEditingBlocked,
      labels,
      selectItemsId,
      labelsForAttendance,
      hideAddAndEditLabels,
      multiSelectOptionManagerClassnames,
      selectPopoverStatus,
      showLabels,
    } = this.props;
    if (attendance.isAvailability) return null;

    return (
      <SettingsFeatureWrapper date={attendance.date} location={attendance.location}>
        {({ editAttendancesDisabled }) => (
          <>
            {(editAttendancesDisabled || isAttendanceEditingBlocked) && <Lock />}
            <div className="editDataView__sectionWrapper">
              <div className="editDataView__header">
                <span className="editDataView__headerTitle">
                  {typeof index === 'number' ? (
                    <FormattedMessage
                      id="attendance.PayrollEditShiftsAndAttendancesModal.attendance"
                      defaultMessage="Obecność {index}"
                      values={{ index: ` #${index}` }}
                    />
                  ) : (
                    <FormattedMessage
                      id="attendance.PayrollEditShiftsAndAttendancesModal.attendanceWithoutIndex"
                      defaultMessage="Obecność"
                    />
                  )}
                </span>
                {this.props.onAttendanceDelete && (
                  <div className=" editDataView__deleteBtn">
                    <span
                      className="k-textInput__label"
                      role="presentation"
                      onClick={() => this.props.onAttendanceDelete(attendance.id)}
                    >
                      <FormattedMessage
                        id="attendance.PayrollEditShiftsAndAttendancesModal.removeAttendance"
                        defaultMessage="Usuń"
                      />
                    </span>
                  </div>
                )}
              </div>
              <MDTimeRangeInput
                label={<FormattedMessage id="payroll.AttendanceEditDataView.inputLabelText" defaultMessage="Czas" />}
                onChange={this.onChange}
                value={attendance.hours}
                errorMessage={this.props.error?.defaultMessage}
                modifiers={INPUT_MODIFIERS}
              />
              {showLabels && (
                <MultiSelectOptionManager
                  options={labels}
                  defaultOptions={labelsForAttendance}
                  selectItemsId={selectItemsId}
                  asInput
                  hideAddAndEdit={hideAddAndEditLabels}
                  className={multiSelectOptionManagerClassnames}
                  selectPopoverStatus={selectPopoverStatus}
                  isModal
                />
              )}
              <EditBreaks
                attendance={attendance}
                breaks={attendance.breaks}
                ref={ref => {
                  this.breaks = ref;
                }}
              />
            </div>
          </>
        )}
      </SettingsFeatureWrapper>
    );
  }
}

AttendanceEditDataView.propTypes = {
  onInputChange: PropTypes.func,
  error: PropTypes.string,
  onAttendanceDelete: PropTypes.func,
  attendance: PropTypes.shape({
    id: PropTypes.string,
  }),
  index: PropTypes.number,
  isAttendanceEditingBlocked: PropTypes.bool,
};

export default AttendanceEditDataView;
