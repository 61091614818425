import PropTypes from 'prop-types';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { DEFAULT_COLOR_TAG } from '@/constants/labels.ts';
import { INPUT_MODIFIERS } from '@/constants/modifiers';
import { getRandomColorTag } from '@/utils/colorHelpers.js';

import { MDTextInput } from '../../inputs/MDComponents';
import {
  getInitialOptionState,
  getLabelsColorWithStatus,
  LabelOption,
  LABELS_COLORS,
} from './addAndEditLabelPopoverContent.helpers.ts';
import { messages } from './addAndEditLabelPopoverContent.messages.tsx';
import AddAndEditLabelPopoverContentFooter from './AddAndEditLabelPopoverContentFooter/AddAndEditLabelPopoverContentFooter.redux.js';

import './addAndEditLabelPopoverContent.scss';

interface AddAndEditLabelPopoverContentProps {
  search?: string;
  label: LabelOption;
  edit: boolean;
  popoverRef: any;
  createLabel: (labelData: { label: string; color: string }) => void;
  updateLabel: (labelData: { label: string; color: string; id: string }) => void;
  labels: { id: string; company_id: string; label: string; color: string; type: string }[];
  clearInputValue: () => void;
  setSelectedColor: (color: string) => void;
}

const AddAndEditLabelPopoverContent = (
  {
    search,
    label,
    edit,
    popoverRef,
    createLabel,
    updateLabel,
    labels,
    clearInputValue,
    setSelectedColor,
  }: AddAndEditLabelPopoverContentProps,
  { intl },
) => {
  const [option, setOption] = useState(getInitialOptionState(label, search));

  const [colors, setColors] = useState(getLabelsColorWithStatus(LABELS_COLORS, label?.color));

  const titleName = edit ? intl.formatMessage(messages.editLabelTitle) : intl.formatMessage(messages.createLabelTitle);

  const onHide = () => popoverRef.current.hide();

  const handleColorSelect = useCallback(
    (color: string) => {
      setOption({ ...option, color });
      setColors(prev =>
        prev.map(prevColor => {
          const isColorActive = prevColor.color === color;
          return { ...prevColor, active: isColorActive };
        }),
      );

      if (option.error.colorPickerError)
        setOption({
          ...option,
          error: { ...option.error, colorPickerError: '' },
        });
    },
    [option, setOption, setColors, setSelectedColor],
  );

  const onSubmit = () => {
    const labelToCreate = { label: option.label, color: option.color };

    if (!option.label) {
      setOption({
        ...option,
        error: { ...option.error, inputError: intl.formatMessage(messages.tagNameReqiuredError) },
      });
      return;
    }
    if (!option.color) {
      setOption({
        ...option,
        error: { ...option.error, colorPickerError: intl.formatMessage(messages.selectedLabelColorError) },
      });

      return;
    }

    const doesLabelExist = labels.some(({ label: existingLabel }) => existingLabel === option.label);

    const isTagNameError =
      (doesLabelExist && !edit) ||
      (edit && doesLabelExist && label.color === option.color && label.label !== option.label);

    if (isTagNameError) {
      setOption({
        ...option,
        error: { ...option.error, colorPickerError: intl.formatMessage(messages.colorLabelRequiredError) },
      });
      return;
    }

    if (edit) {
      updateLabel({ ...labelToCreate, id: option.id });
    } else {
      createLabel(labelToCreate);
    }

    onHide();
    clearInputValue();
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const optionName = event.target.value;
      if (optionName.length <= 63) {
        setOption({ ...option, label: optionName, error: { ...option.error, inputError: '', colorPickerError: '' } });
      } else {
        setOption({
          ...option,
          error: { ...option.error, inputError: intl.formatMessage(messages.valueTooLongError) },
        });
      }
    },
    [option, setOption],
  );

  useEffect(() => {
    if (!edit) {
      const randomColor = getRandomColorTag(LABELS_COLORS);
      setOption({ ...option, color: randomColor });
      setColors(prev =>
        prev.map(prevColor => {
          const isColorActive = prevColor.color === randomColor;
          return { ...prevColor, active: isColorActive };
        }),
      );
      setSelectedColor(randomColor);
    }
    return () => {
      if (!edit) setSelectedColor(DEFAULT_COLOR_TAG);
    };
  }, []);

  return (
    <div className="k-AddAndEditLabelPopoverContent">
      <div className="k-AddAndEditLabelPopoverContent__title">
        <span className="k-AddAndEditLabelPopoverContent__titleName">{titleName}</span>
        <button className="material-symbols-outlined k-AddAndEditLabelPopoverContent__icon" onClick={onHide}>
          close
        </button>
      </div>
      <div className="k-AddAndEditLabelPopoverContent__body">
        <MDTextInput
          id="labelName"
          value={option.label}
          type="text"
          label={intl.formatMessage(messages.tagName)}
          modifiers={INPUT_MODIFIERS}
          onChange={handleChange}
          errorMessage={option.error.inputError}
          preventSpecialCharacters
        />
        <div className="k-AddAndEditLabelPopoverContent__colorPicker">
          <span className="k-AddAndEditLabelPopoverContent__colorPickerLabel">
            {intl.formatMessage(messages.tagColor)}
          </span>
          <div className="k-AddAndEditLabelPopoverContent__colorPickerContainer">
            {colors.map(({ index, color, active }) => (
              <button
                className="k-AddAndEditLabelPopoverContent__colorPickerItem"
                style={{ backgroundColor: color }}
                onClick={() => handleColorSelect(color)}
                key={index}
              >
                {active && (
                  <span className="material-symbols-outlined k-AddAndEditLabelPopoverContent__checkSamllIcon">
                    check_small
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
        <div className="k-AddAndEditLabelPopoverContent__colorPickerErrorText">{option.error.colorPickerError}</div>
      </div>
      <AddAndEditLabelPopoverContentFooter
        onHide={onHide}
        onSubmit={onSubmit}
        option={option}
        hideDeleteButton={!edit}
      />
    </div>
  );
};

// @ts-ignore
AddAndEditLabelPopoverContent.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AddAndEditLabelPopoverContent;
