import { FormattedMessage } from 'react-intl';

import { FREEMIUM_EVENT_ROLE_VIEW } from '@/constants/intercom';
import {
  AVAILABILITY_BLOCK_CREATE,
  COMPANY_MANAGE_PERMISSIONS_HIDE,
  EMPLOYMENT_CONDITIONS_CREATE,
  PERMISSIONS_VIEW_GET,
} from '@/constants/Permissions';
import {
  COMPANY_MANAGE_ABSENCE_TYPES_HIDE,
  COMPANY_MANAGE_AVAILABILITIES_HIDE,
  COMPANY_MANAGE_CONDITIONS_HIDE,
  COMPANY_MANAGE_DEVICES_HIDE,
  COMPANY_MANAGE_EMPLOYEES_HIDE,
  COMPANY_MANAGE_JOBTITLES_HIDE,
  COMPANY_MANAGE_LOCATIONS_HIDE,
  COMPANY_MANAGE_SHIFTBLOCKS_HIDE,
  FREEMIUM_DISABLE_ROLE_VIEW,
  FREEMIUM_HIDE_COMPANY_MANAGE_ABSENCE_TYPES,
  FREEMIUM_HIDE_COMPANY_MANAGE_AVAILABILITIES,
  FREEMIUM_HIDE_COMPANY_MANAGE_DEVICES,
} from '@/constants/Restrictions';

import { NavigationSubRoute } from './routes.types';

export const companyManageRoutes: NavigationSubRoute[] = [
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeLocation"
        defaultMessage="Lokalizacje"
      />
    ),
    path: '/companymanage/locations',
    restrictions: [COMPANY_MANAGE_LOCATIONS_HIDE],
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeJobtitles"
        defaultMessage="Stanowiska"
      />
    ),
    path: '/companymanage/jobtitles',
    restrictions: [COMPANY_MANAGE_JOBTITLES_HIDE],
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeShiftBlocks"
        defaultMessage="Godziny pracy"
      />
    ),
    path: '/companymanage/shiftblocks',
    restrictions: [COMPANY_MANAGE_SHIFTBLOCKS_HIDE],
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeEmployees"
        defaultMessage="Pracownicy"
      />
    ),
    path: '/companymanage/employees',
    restrictions: [COMPANY_MANAGE_EMPLOYEES_HIDE],
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeDevices"
        defaultMessage="Urządzenia"
      />
    ),
    path: '/companymanage/devices',
    restrictions: [
      COMPANY_MANAGE_DEVICES_HIDE,
      FREEMIUM_HIDE_COMPANY_MANAGE_DEVICES,
    ],
    permission: 'COMPANY_MANAGE_DEVICES:SHOW',
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeEmploymentConditions"
        defaultMessage="Warunki zatrudnienia"
      />
    ),
    path: '/companymanage/employmentConditions',
    permission: EMPLOYMENT_CONDITIONS_CREATE,
    restrictions: [COMPANY_MANAGE_CONDITIONS_HIDE],
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeAvailabilityTypes"
        defaultMessage="Typy dostępności"
      />
    ),
    path: '/companymanage/avatypes',
    permission: AVAILABILITY_BLOCK_CREATE,
    restrictions: [
      COMPANY_MANAGE_AVAILABILITIES_HIDE,
      FREEMIUM_HIDE_COMPANY_MANAGE_AVAILABILITIES,
    ],
  },
  {
    title: (
      <FormattedMessage id="topBar.companymanage.roles" defaultMessage="Role" />
    ),
    path: '/companymanage/roles',
    permission: PERMISSIONS_VIEW_GET,
    restrictions: [COMPANY_MANAGE_PERMISSIONS_HIDE],
    freemiumRestrictions: [FREEMIUM_DISABLE_ROLE_VIEW],
    intercomEvent: FREEMIUM_EVENT_ROLE_VIEW,
  },
  {
    title: (
      <FormattedMessage
        id="topBar.companymanage.routeAbsenceTypes"
        defaultMessage="Typy urlopów"
      />
    ),
    path: '/companymanage/absence-types',
    restrictions: [
      COMPANY_MANAGE_ABSENCE_TYPES_HIDE,
      FREEMIUM_HIDE_COMPANY_MANAGE_ABSENCE_TYPES,
    ],
  },
];
