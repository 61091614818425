import PropTypes from 'prop-types';

import LockButton from '@/components/common/LockButton/LockButton';

import messages from '../messages';

const OpenShiftRowTitle = (
  { onOpenShiftRowClick, locationId, toggleOpenShiftsRowStickiness, rowSticky, isEmployee },
  { intl },
) => (
  <th
    onClick={() => !isEmployee && onOpenShiftRowClick(locationId)}
  >
    {intl.formatMessage(messages.scheduleTableOpenShiftTitle, {})}
    <LockButton
      onClick={e => {
        e.stopPropagation();
        toggleOpenShiftsRowStickiness();
      }}
      locked={rowSticky}
    />
  </th>
);

OpenShiftRowTitle.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OpenShiftRowTitle.propTypes = {
  locationId: PropTypes.string,
  onOpenShiftRowClick: PropTypes.func,
  toggleOpenShiftsRowStickiness: PropTypes.func,
  rowSticky: PropTypes.bool,
  isEmployee: PropTypes.bool,
};
export default OpenShiftRowTitle;
