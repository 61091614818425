import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { showModal } from '@/actions/uiState';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { FreeDayItem, showDeleteFreeDayConfirmModal } from '@/redux-store/freeDaysMarking';
import { selectDateArray } from '@/redux-store/mainDateStore';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { selectEmployeeById } from '@/selectors/employees.selectors';
import { getFreeDays } from '@/utils/freeDaysMarking/freeDaysMarking';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import { getClasses, getModalObject } from './ScheduleFreeDayBlock.utils';

export const useScheduleFreeDayBlock = (freeDay: FreeDayItem) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const dateArray = useAppSelector(selectDateArray);
  const employee = useAppSelector(selectEmployeeById(freeDay.employeeId));
  const isMonthlyView = useMemo(() => checkIsMonthlyScheduleView(dateArray), [dateArray]);
  const freeDaysDict = useMemo(() => getFreeDays(intl), [intl]);
  const shortcut = useMemo(() => freeDaysDict[freeDay.markingId].shortcut, [freeDaysDict, freeDay.markingId]);
  const classes = useMemo(
    () => getClasses({ isQuickPlanningEnabled, isMonthlyView }),
    [isQuickPlanningEnabled, isMonthlyView],
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      dispatch(showDeleteFreeDayConfirmModal(freeDay.id));
    },
    [freeDay.id, dispatch],
  );

  const onEdit = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      const modalObject = getModalObject(freeDay, employee);
      dispatch(showModal(ADD_SHIFT_AND_ABSENCE_MODAL, modalObject));
    },
    [dispatch, employee, freeDay],
  );

  return { classes, shortcut, onDelete, onEdit };
};
