import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DisabledAccountModal = props => {
  if (!props.show) return null;
  return (
    <div className="k-blockingMsg">
      <div className="k-blockingMsg__box">
        <i className="material-icons k-blockingMsg__icon">lock</i>
        <div className="k-blockingMsg__header">
          <FormattedMessage
            id="DisabledAccountModal.yourAccountIsDisabled"
            defaultMessage="Twoje konto jest nieaktywne"
          />
        </div>
        <div className="k-blockingMsg__subheader">
          <FormattedMessage
            id="DisabledAccountModal.contactUs"
            defaultMessage="Skontaktuj się z naszym działem obsługi klienta w celu wyjaśnienia zaistniałej sytuacji."
          />
        </div>
        <div>
          <div className="k-blockingMsg__contactInfo">
            <a href="tel:22 103 18 49">
              <i className="material-icons">phone</i>+48 22 103 18 49{' '}
            </a>
            <br />
            <a href="mailto:kontakt@kadromierz.pl">
              <i className="material-icons">email</i>kontakt@kadromierz.pl
            </a>
          </div>
        </div>
      </div>
      <div className="k-blockingMsg__back" />
    </div>
  );
};
DisabledAccountModal.propTypes = {
  show: PropTypes.bool,
};
export default DisabledAccountModal;
