import classNames from 'classnames';
import { PropTypes } from 'prop-types';

const validateNumberInput = (event, isPositive, isInteger) => {
  const invalidChars = ['+', 'e', 'E'];
  if (isPositive) {
    invalidChars.push('-');
  }
  if (isInteger) {
    invalidChars.push(',', '.');
  }
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
};

const handleChange = (event, type, minValue, maxValue, isPositive, onChange) => {
  const { value } = event.target;
  if (type === 'number' && (value > maxValue || value < minValue || (isPositive && value < 0))) {
    return;
  }

  onChange(event);
};

const TextInput = props => {
  let modifiers = props.modifiers || [];
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }
  const className = classNames(
    'k-textInput',
    props.className,
    ...modifiers.map(modifier => `k-textInput--${modifier}`),
  );

  return (
    <input
      type={props.type}
      value={props.value || ''}
      className={className}
      placeholder={props.placeholder}
      onChange={event =>
        handleChange(event, props.type, props.minValue, props.maxValue, props.isPositive, props.onChange)
      }
      onBlur={props.onBlur}
      style={props.style}
      id={props.id}
      name={props.id}
      defaultValue={props.defaultValue}
      required={props.required}
      onKeyDown={e => props.type === 'number' && validateNumberInput(e, props.isPositive, props.isInteger)}
      disabled={props.disabled}
      max={props.type === 'number' ? props.maxValue : undefined}
      data-test={props.testId}
    />
  );
};

TextInput.defaultProps = {
  type: 'text',
  style: {},
  id: '',
  required: true,
  isPositive: true,
  maxValue: 1000000,
};

TextInput.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.shape({}),
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  isInteger: PropTypes.bool,
  isPositive: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};
export default TextInput;
