import { defineMessages } from 'react-intl';

export default defineMessages({
  attendancesTableApproveOvertimeButton: {
    id: 'attendancesTable.approveOvertime.button',
    defaultMessage: 'Zatwierdź nadgodziny',
  },
  attendancesTableEditButton: {
    id: 'attendancesTable.edit.button',
    defaultMessage: 'Edytuj',
  },
  attendancesTableEmptyStateHeader: {
    id: 'attendancesTable.emptyState.header',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
  attendancesTableEmptyStateText: {
    id: 'attendancesTable.emptyState.text',
    defaultMessage: 'Brak obecności w okresie podanego tygodnia',
  },
  attendancesTableEmptySearchResult: {
    id: 'attendancesTable.emptyState.emptySearch',
    defaultMessage: 'Brak obecności dla szukanego pracownika',
  },
  overtime: {
    id: 'attendancesTable.status.overtime',
    defaultMessage: 'Praca w nadgodzinach',
  },
  present: {
    id: 'attendancesTable.status.present',
    defaultMessage: 'Obecny',
  },
  onBreak: {
    id: 'attendancesTable.status.onBreak',
    defaultMessage: 'Na przerwie',
  },
  late: {
    id: 'attendancesTable.status.late',
    defaultMessage: 'Spóźniony',
  },
  refresh: {
    id: 'attendancesTable.tooltip.refresh',
    defaultMessage: 'Odśwież dane',
  },
  createAttendance: {
    id: 'attendanceTable.tooltip.createAttendance',
    defaultMessage: 'Dodaj obecność',
  },
  add: {
    id: 'attendanceButtonBar.tooltip.add',
    defaultMessage: 'Dodaj obecność zgodnie z czasem wg. grafiku pracy',
  },
});
