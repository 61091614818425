import PropTypes from 'prop-types';

import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getLocationNameForId } from '@/utils/payrollHelpers';

const PayrollTableRowDetails = (props) => (
  <table className="table">
    {props.colgroup}
    <tbody>
      {props.details
        .sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1))
        .map((detail) => {
          const trStyle = {};
          if (detail.errorHighlight) {
            trStyle.backgroundColor = 'rgba(252, 55, 55, 0.2)';
          }
          return (
            <tr key={detail.id} style={trStyle}>
              <td style={{ backgroundColor: '#eceff1' }} />
              <td>
                {getLocationNameForId(detail.location.id, props.userLocations)}
              </td>
              <td>
                {detail.start_timestamp
                  ? detail.start_timestamp.split(' ')[1].slice(0, 5)
                  : ''}
              </td>
              <td>
                {detail.end_timestamp
                  ? detail.end_timestamp.split(' ')[1].slice(0, 5)
                  : ''}
              </td>
              <td>{detail.jobTitle}</td>
              <td>{parseMinutesToFormat(detail.timeWorked)}</td>
            </tr>
          );
        })}
    </tbody>
  </table>
);

PayrollTableRowDetails.propTypes = {
  colgroup: PropTypes.node,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PayrollTableRowDetails;
