import { connect } from 'react-redux';

import { openOpenShiftModal } from '@/actions/uiState';

import Component from './ScheduleTableOpenShiftCell.jsx';

const mapStateToProps = (state, ownProps) => {
  const currentUserId = state.reducer.currentUser.user.id;
  return {
    openShiftIds: state.reducer.schedule.openShifts.structure?.[ownProps.locationId]?.[ownProps.date],
    isEmployee: state.reducer.userPermissions.isEmployee,
    openShiftData: state.reducer.schedule.openShifts.data,
    selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
    currentUserContracts: state.reducer.contracts[currentUserId],
    permissions: state.reducer.userPermissions.permissions,
  };
};

const mapDispatchToProps = {
  openOpenShiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
