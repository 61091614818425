import { connect } from 'react-redux';

import { onRouteChange } from '@/actions/routes';
import { MainPermissionsWrapper } from '@/components/MainPermissionsWrapper.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  user: state.reducer.currentUser.user,
  company: state.reducer.currentCompany,
});

const mapDispatchToProps = {
  onRouteChange,
};

const MainPermissionsWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(MainPermissionsWrapper);

export default MainPermissionsWrapperContainer;
