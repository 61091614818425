import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton';
import TextInput from '@/components/common/inputs/TextInput.jsx';
import KadroSkeleton from '@/components/common/Skeleton/Skeleton';
import { filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings } from '@/components/newPayrollViews/columns.helpers';
import { BLUE_100, BLUE_500, GREEN_100, GREEN_500, RED_100, RED_500 } from '@/constants/colors';
import { PAYROLL_APPROVAL_START_DATE, PAYROLL_MODES } from '@/constants/payrollSettings';
import {
  PAYROLL_HOURS_RANGE_PLACEHOLDER,
  PAYROLL_WORK_HOURS_PLACEHOLDER,
  payrollTableColumnsOptions,
  TIME_FORMAT,
} from '@/constants/tables/payroll/payrollTableColumns';
import { getEndAttendanceColor, getStartAttendanceColor } from '@/utils/attendanceHelpers';
import { isDefined } from '@/utils/baseHelpers';
import { parseMinutesToFormat, parseMinutesToHumanForm, timestampsToWorkingHours } from '@/utils/dateHelper';
import { getFreeDays } from '@/utils/freeDaysMarking/freeDaysMarking';
import { getColumnWidth } from '@/utils/tableHelpers';

import MDAlert from '../../../common/Basic/MDAlert/MDAlert';
import { messages } from './PayrollTable.messages';
import PayrollTableLabels from './PayrollTableLabels.redux.js';

export const TABS = [
  {
    id: 'all',
    color: BLUE_500,
    lightColor: BLUE_100,
  },
  {
    id: 'approved',
    color: GREEN_500,
    lightColor: GREEN_100,
  },
  {
    id: 'unapproved',
    color: RED_500,
    lightColor: RED_100,
  },
];

const getColumnsForFilterWithHiddenColumn = (allColumns, column, intl, isTableWithScroll) => {
  const columns = [];
  column.columnAccessorsToShow.forEach(additionalColumnAccessor => {
    const additionalColumn = allColumns.find(aCol => aCol.accessor === additionalColumnAccessor);
    if (additionalColumn)
      columns.push({
        Header: intl.formatMessage(additionalColumn.title, {}),
        accessor: additionalColumn.accessor,
        minWidth: getColumnWidth(isTableWithScroll, additionalColumn.width),
      });
  });
  return columns;
};

export const getColumns = (allColumns, visibleColumns, isTableWithScroll, intl) => {
  const columns = [];
  visibleColumns.forEach(col => {
    if (!allColumns.some(column => column.accessor === col.accessor)) {
      return;
    }

    if (col.shouldBeHiddenInTable) {
      columns.push(...getColumnsForFilterWithHiddenColumn(allColumns, col, intl, isTableWithScroll));
    } else {
      columns.push({
        ...col,
        Header: intl.formatMessage(col.title),
        accessor: col.accessor,
        width: getColumnWidth(isTableWithScroll || col.sticky, col.width),
        sticky: col.sticky,
        Cell: col.Cell,
        shadow: col.shadow,
      });
    }
  });

  columns.forEach(column => {
    if (!column.Cell) {
      delete column.Cell;
    }
  });

  return columns;
};

const getAbsenceColumnContent = ({ absenceName, absenceTime }) => {
  if (!absenceName) return '-';
  const absenceTimeFormatted = parseMinutesToHumanForm(absenceTime);
  return `${absenceName} - ${absenceTimeFormatted}`;
};

const formatMinutes = (value, timeFormatType) => {
  if (value) return parseMinutesToFormat(value, timeFormatType);
  return '-';
};

const formatMinutesWithZero = (value, timeFormatType) => parseMinutesToFormat(value || 0, timeFormatType);

const formatMoney = value => (value || value === 0 ? `${(value / 100).toFixed(2)} PLN` : '-');

const formatMoneyWithZero = value => `${((value || 0) / 100).toFixed(2)} PLN`;

const getOvertime50ColumnContent = ({ overtimeCollectionId, overtime50, overtimeCollectionTime }, timeFormatType) => {
  if (!overtimeCollectionId) return formatMinutes(overtime50?.value, timeFormatType);
  return `-${formatMinutes(overtimeCollectionTime, timeFormatType)}`;
};

const getJobTitleColumnContent = ({ jobTitleName, overtimeCollectionId, availabilityName }) => {
  if (overtimeCollectionId)
    return <FormattedMessage id="absences.overtimeCollection" defaultMessage="Odbiór nadgodzin" />;
  if (availabilityName) return availabilityName;
  return jobTitleName || '-';
};

const getFromTo = (rowData, payoutSettingType, intl) => {
  const { from: fromTimestamp, to: toTimestamp, matchingShiftFrom, matchingShiftTo, attendanceId } = rowData;
  if (!fromTimestamp) {
    return {
      from: PAYROLL_HOURS_RANGE_PLACEHOLDER,
      to: PAYROLL_HOURS_RANGE_PLACEHOLDER,
    };
  }
  const [from, to] = timestampsToWorkingHours(fromTimestamp, toTimestamp).split('-');

  if (payoutSettingType === 'shifts') {
    return { from, to };
  }

  const attendanceFrom = attendanceId ? from : TIME_FORMAT;
  const attendanceTo = attendanceId ? to : TIME_FORMAT;

  let [shiftFrom, shiftTo] =
    matchingShiftFrom && matchingShiftTo ? timestampsToWorkingHours(matchingShiftFrom, matchingShiftTo).split('-') : [];

  if (!attendanceId) {
    shiftFrom = from;
    shiftTo = to;
  }

  return {
    from: (
      <div className="k-payrollTable__hoursWrapper">
        {attendanceFrom !== TIME_FORMAT ? (
          <span style={{ color: getStartAttendanceColor(fromTimestamp, matchingShiftFrom) }}>{attendanceFrom}</span>
        ) : (
          TIME_FORMAT
        )}
        <span> / {shiftFrom || TIME_FORMAT}</span>
        {rowData.attendanceEarlyStartAccepted && (
          <MDAlert icon="more_time" label={intl.formatMessage(messages.acceptedOvertime)} />
        )}
      </div>
    ),
    to: (
      <div className="k-payrollTable__hoursWrapper">
        {attendanceTo !== TIME_FORMAT ? (
          <span style={{ color: getEndAttendanceColor(toTimestamp, matchingShiftTo) }}>{attendanceTo}</span>
        ) : (
          TIME_FORMAT
        )}
        <span> / {shiftTo || TIME_FORMAT}</span>
        {rowData.attendanceLateEndAccepted && (
          <MDAlert icon="more_time" label={intl.formatMessage(messages.acceptedOvertime)} />
        )}
      </div>
    ),
  };
};

const getBonusColumnContent = (
  rowData,
  selectedEmployeeId,
  bonusValues = {},
  payoutSettingType,
  payrollMode,
  addBonusToAttendance,
  setBonusValues,
  currentUserLocations,
  openShiftsAssignments = [],
) => {
  const attendance = { id: rowData.attendanceId, start_timestamp: rowData.from };
  const { matchingShiftFrom, matchingShiftTo } = rowData;
  const isSameAsOpenShiftAssignments = openShiftsAssignments.some(assignment => {
    const formattedStart = moment(assignment.start_timestamp).utc().format('YYYY-MM-DD HH:mm:ss');
    const formattedEnd = moment(assignment.end_timestamp).utc().format('YYYY-MM-DD HH:mm:ss');
    return formattedStart === matchingShiftFrom && formattedEnd === matchingShiftTo;
  });
  const currentUserLocationsNames = currentUserLocations.map(({ name }) => name);
  const disabled =
    !rowData.attendanceId ||
    payoutSettingType === 'shifts' ||
    payrollMode !== PAYROLL_MODES.editable ||
    (isSameAsOpenShiftAssignments && !currentUserLocationsNames.includes(rowData.locationName));
  return (
    <TextInput
      onBlur={e => addBonusToAttendance(attendance, parseInt(e.target.value * 100), selectedEmployeeId)}
      type="number"
      className={classnames('k-payrollTable__bonusInput', { 'k-payrollTable__bonusInput--disabled': disabled })}
      value={isDefined(bonusValues[rowData.attendanceId]) ? bonusValues[rowData.attendanceId] : rowData.bonusAmount}
      disabled={disabled}
      onChange={e => setBonusValues(rowData.attendanceId, e.target.value, selectedEmployeeId)}
      isPositive={false}
    />
  );
};

const getDifferenceContent = (rowData, timeFormatType) => {
  const difference = rowData.realization - rowData.plannedTime;

  return difference || difference === 0 ? formatMinutesWithZero(difference, timeFormatType) : '-';
};

const getWarningsColumnContent = (rowData, intl) => {
  const {
    absenceOverlappedByAttendance,
    draftAbsence,
    longAttendance,
    outOfRangeAttendance,
    overlappingAttendances,
    unfinishedAttendance,
    unfinishedBreak,
    overlappingFreeDayMarkingWithAbsence,
    overlappingFreeDayMarkingWithAttendance,
    overlappingFreeDayMarkingWithShift,
  } = rowData.warnings;

  const freeDaysMarkingsWarnings = {
    overlappingFreeDayMarkingWithAbsence,
    overlappingFreeDayMarkingWithAttendance,
    overlappingFreeDayMarkingWithShift,
  };
  const numberOfFreeDaysMarkingsWarnings = Object.values(freeDaysMarkingsWarnings).filter(Boolean).length;
  const freeDaysMarkingsWarningMessage = Object.keys(freeDaysMarkingsWarnings)
    .filter(warning => freeDaysMarkingsWarnings[warning])
    .map(warning => intl.formatMessage(messages[warning]))
    .join('\n');

  return [
    absenceOverlappedByAttendance && (
      <MDAlert icon="flight" label={intl.formatMessage(messages.absenceOverlappedByAttendance)} />
    ),
    draftAbsence && <MDAlert icon="unpublished" label={intl.formatMessage(messages.draftAbsence)} />,
    longAttendance && <MDAlert icon="nights_stay" label={intl.formatMessage(messages.longAttendance)} />,
    outOfRangeAttendance && <MDAlert icon="wrong_location" label={intl.formatMessage(messages.outOfRangeAttendance)} />,
    overlappingAttendances && (
      <MDAlert icon="punch_clock" label={intl.formatMessage(messages.overlappingAttendances)} />
    ),
    unfinishedAttendance && (
      <MDAlert icon="pending_actions" label={intl.formatMessage(messages.unfinishedAttendance)} />
    ),
    unfinishedBreak && <MDAlert icon="warning" label={intl.formatMessage(messages.unfinishedBreak)} />,
    numberOfFreeDaysMarkingsWarnings > 0 && (
      <MDAlert
        icon="person_alert"
        materialSymbols
        style={{ width: '24px', height: '24px' }}
        label={freeDaysMarkingsWarningMessage}
        numberOfAlerts={numberOfFreeDaysMarkingsWarnings > 1 ? numberOfFreeDaysMarkingsWarnings : null}
      />
    ),
  ];
};

const getStatusColumnLabel = (date, isUnapproved, intl) => {
  if (date < PAYROLL_APPROVAL_START_DATE) {
    return intl.formatMessage(messages.payrollApprovalUnavailable, { date: PAYROLL_APPROVAL_START_DATE });
  }

  return intl.formatMessage(isUnapproved ? messages.approveTooltip : messages.unapproveTooltip);
};

const getStatusColumnContent = (rowData, employeeId, changePayrollDaysStatus, intl, isLoanedEmployee) => {
  const { isUnapproved, date } = rowData;
  const modifier = isUnapproved ? 'unapproved' : 'approved';
  const disabled = date < PAYROLL_APPROVAL_START_DATE || isLoanedEmployee;
  const label = getStatusColumnLabel(date, isUnapproved, intl);

  return (
    <MDIconButton
      status={modifier}
      disabled={disabled}
      label={label}
      icon="check_circle"
      className={`k-payrollTable__approvalIcon--${modifier}`}
      onClick={() => changePayrollDaysStatus(employeeId, [date], isUnapproved ? 'approved' : 'unapproved')}
    />
  );
};

const getLabelsContent = labelsFromRow =>
  labelsFromRow?.length ? <PayrollTableLabels labelsFromRow={labelsFromRow} numberOfMaxItems={3} /> : '-';

const checkIfHasOvertimeToAccept = rowData => {
  const isAttendance = Boolean(rowData.attendanceId);
  if (!isAttendance) {
    return false;
  }
  const isFinishedAttendance = Boolean(rowData.to);
  if (!isFinishedAttendance) {
    return false;
  }
  const isAttendanceWithoutShift = isAttendance && !rowData.matchingShiftFrom;
  if (isAttendanceWithoutShift) {
    return true;
  }
  const hasEarlyIn = rowData.from < rowData.matchingShiftFrom;
  const hasLateOut = rowData.to > rowData.matchingShiftTo;

  return hasEarlyIn || hasLateOut;
};

const getSkeleton = () => <KadroSkeleton />;

const getSkeletonData = () => {
  const skeleton = getSkeleton();
  return {
    from: skeleton,
    to: skeleton,
    workHours: skeleton,
    breaks: skeleton,
    absence: skeleton,
    workTime: skeleton,
    jobTitleName: skeleton,
    nightWorkTime: skeleton,
    overtime50: skeleton,
    overtime100: skeleton,
    potentialOvertime: skeleton,
    fillUp: skeleton,
    overtimeWeekly: skeleton,
    plan: skeleton,
    difference: skeleton,
    wage: skeleton,
    payout: skeleton,
    warnings: skeleton,
    status: skeleton,
    labels: skeleton,
  };
};

export const getTableData = (
  data,
  selectedEmployee,
  timeFormatType,
  bonusValues,
  payoutSettingType,
  addBonusToAttendance,
  setBonusValues,
  payrollMode,
  changePayrollDaysStatus,
  intl,
  labels,
  companyRoles,
  currentUser,
  userPermissions,
  showLabels,
  payrollLoanedEmployees,
  openShiftsAssignments = [],
) =>
  data.map(rowData => {
    const { from, to } = getFromTo(rowData, payoutSettingType, intl);
    const hasOvertimeToAccept = checkIfHasOvertimeToAccept(rowData);
    if (rowData.isRefreshing) {
      const skeletonData = getSkeletonData();
      return {
        ...rowData,
        ...skeletonData,
        date: rowData.date,
        locationName: rowData.locationName || '-',
        bonusAmount: getBonusColumnContent(
          rowData,
          selectedEmployee?.id,
          bonusValues,
          payoutSettingType,
          payrollMode,
          addBonusToAttendance,
          setBonusValues,
          currentUser.user.locations,
        ),
        disabledCheckbox: rowData.date < PAYROLL_APPROVAL_START_DATE,
        hasOvertimeToAccept,
      };
    }

    const workHours = rowData.workHours || PAYROLL_WORK_HOURS_PLACEHOLDER;
    const isLoanedEmployee = payrollLoanedEmployees.some(({ id }) => id === selectedEmployee?.id);
    const freeDays = getFreeDays(intl);
    const freeDayMarkingName = freeDays[rowData.freeDayMarkingId]?.shortcut || '';

    const baseData = {
      ...rowData,
      date: rowData.date,
      from,
      to,
      workHours,
      breaks: formatMinutesWithZero(rowData.breaksTime, timeFormatType),
      absence: getAbsenceColumnContent(rowData),
      workTime: formatMinutesWithZero(rowData.workTime, timeFormatType),
      jobTitleName: getJobTitleColumnContent(rowData),
      locationName: rowData.locationName || '-',
      nightWorkTime: formatMinutesWithZero(rowData.nightWorkTime, timeFormatType),
      overtime50: getOvertime50ColumnContent(rowData, timeFormatType),
      overtime100: formatMinutes(rowData.overtime100?.value, timeFormatType),
      potentialOvertime: formatMinutes(rowData.potentialOvertime.value, timeFormatType),
      fillUp: formatMinutes(rowData.fillUp, timeFormatType),
      overtimeWeekly: '-',
      plan: formatMinutesWithZero(rowData.plannedTime, timeFormatType),
      difference: getDifferenceContent(rowData, timeFormatType),
      bonusAmount: getBonusColumnContent(
        rowData,
        selectedEmployee?.id,
        bonusValues,
        payoutSettingType,
        payrollMode,
        addBonusToAttendance,
        setBonusValues,
        currentUser.user.locations,
        openShiftsAssignments,
      ),
      wage: formatMoney(rowData.wage),
      payout: formatMoneyWithZero(rowData.payout),
      warnings: getWarningsColumnContent(rowData, intl),
      status: getStatusColumnContent(rowData, selectedEmployee?.id, changePayrollDaysStatus, intl, isLoanedEmployee),
      disabledCheckbox: rowData.date < PAYROLL_APPROVAL_START_DATE,
      hasOvertimeToAccept,
      labels: getLabelsContent(rowData.labels),
      freeDayMarkingName,
    };

    if (rowData.isRefreshingOvertime) {
      const skeleton = getSkeleton();
      return {
        ...baseData,
        overtime50: skeleton,
        overtime100: skeleton,
        potentialOvertime: skeleton,
        fillUp: skeleton,
      };
    }

    return baseData;
  });

export const getRowsWithMergedColumns = (data, columns) =>
  data.reduce((result, row, index) => {
    if (row.overtimeCollectionId) {
      const columnIndex = columns.findIndex(({ accessor }) => accessor === 'overtime50');
      if (columnIndex === -1) return result;
      return {
        ...result,
        [index]: [...(result[index] || []), columnIndex],
      };
    }
    return result;
  }, {});

export const getPayrollColumns = (userPermissions, payoutSettingType, companySettings) =>
  filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
    payrollTableColumnsOptions,
    userPermissions,
    payoutSettingType,
    companySettings,
  );
