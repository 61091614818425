import { connect } from 'react-redux';

import ScheduleBudgetTableRecommendedScheduleTotalCell from './ScheduleBudgetTableRecommendedScheduleTotalCell.jsx';

const mapStateToProps = state => ({
  recommendedSchedule: state.reducer.schedule.budget.recommendedSchedule,
  dateArray: state.reducer.mainDateStore.dateArray,
  selectedLocations: state.reducer.scheduleLocationFilter,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitlesGrouped,
});

export default connect(mapStateToProps)(ScheduleBudgetTableRecommendedScheduleTotalCell);
