import { defineMessages } from 'react-intl';

export const employeesMultiSelectMessages = defineMessages({
  headerTitle: { id: 'loanEmployeesProposal.employeesStep.employees', defaultMessage: 'Pracownicy' },
  selectAll: { id: 'loanEmployeesProposal.employeesStep.employees.all', defaultMessage: 'wszyscy' },
  deselectAll: { id: 'loanEmployeesProposal.employeesStep.employees.none', defaultMessage: 'żaden' },
  clearAll: { id: 'loanEmployeesProposal.employeesStep.employees.clearAll', defaultMessage: 'wyczyść' },
  bodyLabel: { id: 'loanEmployeesProposal.employeesStep.employees.choose', defaultMessage: 'Wybierz pracowników' },
  footerTitle: { id: 'loanEmployeesProposal.employeesStep.employees.footer', defaultMessage: 'Wybrani pracownicy' },
});
