import { connect } from 'react-redux';

import Component from './ReportWidgets.tsx';

const mapStateToProps = state => ({
  statistics: state.reducer.reports.data.statistics,
  customDate: state.reducer.reports.dateStore.customDate,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
