import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  companySettings: {
    id: 'settings.options.companySettings',
    defaultMessage: 'Ustawienia firmy',
  },
  companyName: {
    id: 'settings.options.companyName',
    defaultMessage: 'Nazwa firmy',
  },
});

export const nameMessages = defineMessages({
  availabilitiesArePublic: {
    id: 'settings.options.availabilitiesArePublic',
    defaultMessage: 'Pracownik ma wgląd do dostępności pozostałych pracowników',
  },
  scheduleIsPublicForEmployees: {
    id: 'settings.options.scheduleIsPublicForEmployees',
    defaultMessage: 'Pracownik ma wgląd w grafik innych pracowników',
  },
  sameRankIsVisible: {
    id: 'settings.options.sameRankIsVisible',
    defaultMessage: 'Użytkownik ma wgląd i może edytować innych użytkowników z tą samą rangą',
  },
  countOnlyDaysWithShiftsSelectedByDefault: {
    id: 'settings.options.countOnlyDaysWithShiftsSelectedByDefault',
    defaultMessage: 'Opcja "Planuj urlop na podstawie grafiku" jest domyślnie zaznaczona',
  },
  showUnpaidAbsences: {
    id: 'settings.options.showUnpaidAbsences',
    defaultMessage: 'Pokazuj urlopy bezpłatne w Ewidencji czasu pracy',
  },
  payrollAbsenceEnabled: {
    id: 'settings.options.payrollAbsenceEnabled',
    defaultMessage: 'Oblicz kwotę do wypłaty w dniu z płatnym urlopem',
  },
  payrollApprovalEnabled: {
    id: 'settings.options.payrollApprovalEnabled',
    defaultMessage: 'Włącz funkcjonalność zatwierdzania godzin pracy w widoku Ewidencja czasu pracy',
  },
  employeeECPEnabled: {
    id: 'settings.options.employeeECPEnabled',
    defaultMessage: 'Pracownik ma wgląd w ewidencję przepracowanych godzin',
  },
  showUnapprovedEmployeeECPData: {
    id: 'settings.options.showUnapprovedEmployeeECPData',
    defaultMessage: 'Pokazuj niezatwierdzone dni',
  },
  showWagesInEmployeeECP: {
    id: 'settings.options.showWagesInEmployeeECP',
    defaultMessage: 'Pokazuj wynagrodzenie',
  },
});

export const labelMessages = defineMessages({
  availabilitiesArePublic: {
    id: 'settings.options.availabilitiesArePublicDesc',
    defaultMessage:
      'Włączenie tej opcji umożliwi użytkownikom z rolą Pracownik na wgląd w dostępności innych użytkowników w panelu Web w widoku Grafik Pracy.',
  },
  scheduleIsPublicForEmployees: {
    id: 'settings.options.scheduleIsPublicForEmployeesDesc',
    defaultMessage:
      'Włączenie tej opcji umożliwi użytkownikom z rolą Pracownik na wgląd w grafik pracy innych użytkowników w panelu Web w widoku Grafik Pracy oraz w aplikacji mobilnej Kadromierz.',
  },
  sameRankIsVisible: {
    id: 'settings.options.sameRankIsVisibleDesc',
    defaultMessage:
      'Włączenie tej opcji pozwala na edycję danych użytkowników z taką samą rangą lub wyższą względem użytkownika dokonującego edycji.',
  },
  countOnlyDaysWithShiftsSelectedByDefault: {
    id: 'settings.options.countOnlyDaysWithShiftsSelectedByDefaultDesc',
    defaultMessage:
      'Włączenie tej opcji sprawi, że przy dodawaniu wniosków urlopowych opcja “Planuje urlop na podstawie grafiku” będzie domyślnie zaznaczona.',
  },
  showUnpaidAbsences: {
    id: 'settings.options.showUnpaidAbsencesDesc',
    defaultMessage:
      'Włączenie tej opcji wpłynie na wyświetlanie w widoku Ewidencja czasu pracy również urlopów, które nie mają włączonej opcji “Płatny”.',
  },
  payrollAbsenceEnabled: {
    id: 'settings.options.payrollAbsenceEnabledDesc',
    defaultMessage:
      'Włączenie tej opcji sprawi, że dla nieobecności płatnych zostanie wyliczona kwota do wypłaty. Wartość ta będzie liczona na podstawie stanowiska i przypisanej do niego stawki godzinowej oraz długości zmiany w tym dniu.',
  },
  payrollApprovalEnabled: {
    id: 'settings.options.payrollApprovalEnabledDesc',
    defaultMessage:
      'Włączenie tej opcji wyświetli w widoku Ewidencja czasu pracy dodatkową kolumnę “Status” oraz funkcjonalność umożliwiającą zatwierdzenie godzin pracy.',
  },
  employeeECPEnabled: {
    id: 'settings.options.employeeECPEnabled.description',
    defaultMessage:
      'Włączenie tej opcji da pracownikom wgląd w ich przepracowane godziny w aplikacji mobilnej oraz webowej. Pozwoli zobaczyć również wynagrodzenie bonusowe oraz kwotę wypłaty.',
  },
  showUnapprovedEmployeeECPData: {
    id: 'settings.options.showUnapprovedEmployeeECPData.description',
    defaultMessage:
      'Włączenie tej opcji spowoduje pokazanie pracownikom ich aktualnej liczby godzin pracy, w tym tych, które nie zostały uprzednio zatwierdzone. Pozostawienie tej opcji wyłączonej sprawi, że widoczne będą jedynie godziny zatwierdzone.',
  },
  showWagesInEmployeeECP: {
    id: 'settings.options.showWagesInEmployeeECP.description',
    defaultMessage:
      'Włączenie tej opcji spowoduje pokazanie pracownikom zarówno ich przepracowanych godzin, jak i wynagrodzenia. Pozostawienie tej opcji wyłączonej sprawi, że widoczne będą jedynie przepracowane godziny pracownika.',
  },
});
