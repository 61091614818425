import { connect } from 'react-redux';

import { changeAbsencesData } from '@/actions/absences.js';
import { showModal } from '@/actions/uiState.js';

import AbsencesView from './AbsencesView.jsx';

const mapStateToProps = state => ({
  absences: state.reducer.absences.absences,
  absencesTable: state.reducer.absences.absencesTable,
  mainDateStore: state.reducer.mainDateStore,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  changeAbsencesData,
};

const AbsencesViewContainer = connect(mapStateToProps, mapDispatchToProps)(AbsencesView);

export default AbsencesViewContainer;
