import { connect } from 'react-redux';

import { clearBudgetTargetsImports, runExport } from '@/actions/exports.js';
import { clearImportModal, toggleImportModalOverlay, uploadFileToImport } from '@/actions/schedule.jsx';
import { hideModal, showModal } from '@/actions/uiState';
import { BUDGET_TARGET_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportBudgetTargetModal from './ImportBudgetTargetModal.jsx';

const mapStateToProps = ({ reducer }) => ({
  isModalVisible: reducer.uiState.showModal === BUDGET_TARGET_IMPORT_MODAL,
  from: reducer.mainDateStore.customDate.start,
  to: reducer.mainDateStore.customDate.end,
  userLocations: reducer.userLocations,
  importModalObject: reducer.scheduleUIState.importModal,
  pendingExports: reducer.exports.pendingExports,
});

const mapDispatchToProps = {
  clearImportModal,
  hideModal,
  runExport,
  uploadFileToImport,
  toggleImportModalOverlay,
  clearBudgetTargetsImports,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportBudgetTargetModal);
