import { deburr,sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents';
import { createEvent } from '@/utils/inputHelpers';

const messages = defineMessages({
  title: {
    id: 'locationSelect.title',
    defaultMessage: 'Lokalizacja',
  },
});

const LocationSelect = (
  { value, onChange, withinFilters, scheduleLocationFilter, userLocations, name, errorMessage },
  { intl },
) => {
  const locationSource = userLocations.filter(loc => !withinFilters || scheduleLocationFilter.includes(loc.id));

  const sortedLocations = sortBy([...locationSource], location => deburr(location.name.toLowerCase()));
  const options = sortedLocations.map(loc => ({ key: loc.name, value: loc.id }));

  const handleChange = newValue => {
    if (onChange) {
      onChange(createEvent(name, newValue));
    }
  };

  return (
    <div>
      <MDSelect
        name={intl.formatMessage(messages.title)}
        defaultValue={value}
        options={options}
        onChange={handleChange}
        closeOnClick
        errorMessage={errorMessage}
      />
    </div>
  );
};

LocationSelect.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LocationSelect.propTypes = {
  value: PropTypes.string,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  withinFilters: PropTypes.bool,
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default LocationSelect;
