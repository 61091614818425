import { defineMessages } from 'react-intl';

import { KadroLocaleEnum } from '@/types/locale.types.ts';

export const baseLocationSettings = {
  allowEmployeeAttendaceOpenClose: false,
  attendanceLocationRadiusLimit: 0,
  attendanceType: 0,
  authorization_method: 'pin',
  authorization_pin_enable: true,
  authorization_qrcode_activity_time: 0,
  authorization_qrcode_enable: false,
  authorization_nfc_enable: false,
  closeAttendanceAfter: 5,
  closeStrayAttendanceAfter: 5,
  customSettings: null,
  deviceViewEmployeeShowAttendanceList: false,
  deviceViewEmployeeShowEvents: false,
  deviceViewManagerShowAttendanceList: false,
  deviceViewManagerShowEvents: false,
  disable_location_attendances_edit_until: null,
  disable_location_schedule_shifts_edit_until: null,
  enableAttendancesAutoclosingNoShift: false,
  enableAttendancesAutoclosingShift: false,
  enableLocalizationCheck: false, // for Kadromierz Pracownik app
  rcpRequiresGeolocation: false,
  enableOpenShift: false,
  enable_reason: false,
  enableRestBreak: false,
  enableTradeShift: false,
  enableUsageProductionQuotasForPayrollCalculation: false,
  enableWorkingRulesCheck: false,
  latitude: 0.0,
  location_coordinates: null,
  longitude: 0.0,
  photoSettingsShowCamera: false,
  showSettingsOnDevice: false,
  translationType: KadroLocaleEnum.PL,
  budgetEstimatesPeriod: 'day',
  attendanceSurveyTime: null,
  attendanceSurveyReportTime: null,
  openHours: null,
  openHoursMethod: 'schedule',
  locationOpeningHoursEnabled: false,
  open_hours_based_on_schedule: false,
  location_system_bonus_enabled: false,
  bonusHourBlocks: null,
  bonusThresholds: null,
  autoclose_attendances_at_time: null,
  autoclose_attendances: false,
  enableBudgetTarget: false,
  budgetTargetHoursBlocksPublish: false,
  budgetTargetMoneyBlocksPublish: false,
};

export const allAuthMethods = [
  { name: 'Kod PIN', id: 'pin' },
  { name: 'Kody QR', id: 'qr' },
];

const messages = defineMessages({
  openingHoursBasedOnSchedule: {
    id: 'settings.locations.openingHoursBasedOnSchedule',
    defaultMessage: 'Wg. grafiku pracy',
  },
  openingHoursCustom: {
    id: 'settings.locations.openingHoursCustom',
    defaultMessage: 'Wg. godziny otwarć',
  },
});

export const getOpenHoursMethods = (intl) => [
  {
    name: intl.formatMessage(messages.openingHoursBasedOnSchedule),
    id: 'schedule',
  },
  {
    name: intl.formatMessage(messages.openingHoursCustom),
    id: 'custom',
  },
];
