import { connect } from 'react-redux';

 import { hideModal } from '@/actions/uiState';
import { SETTINGS_DEVICE_MODAL } from '@/constants/modalTypes.js';

import SettingsDeviceModal from './SettingsDeviceModal.jsx';
import { saveSettings } from '@/actions/companymanage/devices.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === SETTINGS_DEVICE_MODAL,
  modalObject: state.reducer.uiState.modalObject[SETTINGS_DEVICE_MODAL],
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
  saveSettings,
};

const SettingsDeviceModalContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsDeviceModal);

export default SettingsDeviceModalContainer;
