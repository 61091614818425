import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BUDGET_ESTIMATES_EDIT } from '@/constants/Permissions.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { isWeekend } from '@/utils/dateHelper';

import ScheduleBudgetTableRowInput from '../../ScheduleBudgetTableRowInput/ScheduleBudgetTableRowInput.jsx';

const ScheduleBudgetTableSpmhCell = ({
  sumHours = 0,
  estimate = {},
  canEdit,
  permissions,
  selectedDisplayMode,
  showHolidaysSetting,
  date,
  today,
  onEstimateChange,
  setCellError,
  dayType,
}) => {
  const getClassname = isUnder =>
    classnames('k-budgetTable__spmh', {
      'k-budgetTable__spmh--under': isUnder,
      'k-budgetTable__spmh--over': !isUnder,
    });
  const plannedMinutes = sumHours;
  const estimated =
    !estimate.estimated_budget || !plannedMinutes
      ? 0
      : roundToTwoSigDigits(estimate.estimated_budget / 100 / (plannedMinutes / 60));
  const showHolidays = selectedDisplayMode !== 'templates' && showHolidaysSetting;
  const className = classnames('k-budgetTable__cell', {
    today: date === today,
    weekend: isWeekend(date),
    freeFromWorkHoliday: showHolidays && dayType === 'freeFromWorkHoliday',
    workDayHoliday: showHolidays && dayType === 'holiday',
  });

  return (
    <td className={className}>
      {canEdit ? (
        <ScheduleBudgetTableRowInput
          key={estimate.date}
          name="target_spmh"
          estimate={estimate}
          disabled={!permissions.includes(BUDGET_ESTIMATES_EDIT)}
          onChange={e => onEstimateChange(e, estimate, 'target_spmh', date)}
          setError={setCellError}
        >
          <div className={getClassname(estimate.target_spmh / 100 > estimated)}>{estimated} PLN</div>
        </ScheduleBudgetTableRowInput>
      ) : (
        <div>
          <div className="k-budgetTable__spmh">{estimate.target_spmh / 100 || 0} PLN</div>
          <div className="k-budgetTable__spmh">{estimated} PLN</div>
        </div>
      )}
    </td>
  );
};

ScheduleBudgetTableSpmhCell.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  sumHours: PropTypes.number,
  estimate: PropTypes.shape({
    date: PropTypes.string,
    estimated_budget: PropTypes.number,
    target_spmh: PropTypes.number,
  }),
  canEdit: PropTypes.bool,
  selectedDisplayMode: PropTypes.string,
  showHolidaysSetting: PropTypes.bool,
  date: PropTypes.string,
  dayType: PropTypes.string,
  today: PropTypes.string,
  onEstimateChange: PropTypes.func,
  setCellError: PropTypes.func,
};

export default ScheduleBudgetTableSpmhCell;
