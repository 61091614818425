export const getSortingDirection = (oldColumn, newColumn, oldDirection) => {
  if (oldDirection === 'desc' && oldColumn === newColumn) {
    return 'asc';
  }

  if (oldColumn === newColumn) {
    return 'desc';
  }

  return 'desc';
};

export const getColumnId = sortedColumns => {
  if (sortedColumns[0].id === 'statusChip') {
    return 'status';
  }

  return sortedColumns[0].id;
};
