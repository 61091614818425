import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import MDModal from '../MDModal/Modal.jsx';
import MDKadroModalFooter from './MDKadroModalFooter/ModalFooter.jsx';
import MDKadroModalHeader from './MDKadroModalHeader/ModalHeader.jsx';

import './MDKadroModal.scss';

class MDKadroModal extends Component {
  constructor(props) {
    super(props);
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.bindEnter();
      } else {
        this.unbindEnter();
      }
    }
  }

  componentWillUnmount() {
    this.unbindEnter();
  }

  bindEnter() {
    if (this.props.inputAwareSubmit) {
      Mousetrap.bind(['enter'], this.props.onSubmit);
    } else {
      document.addEventListener('keyup', this.submit);
    }
  }

  unbindEnter() {
    if (this.props.inputAwareSubmit) {
      Mousetrap.unbind(['enter']);
    } else {
      document.removeEventListener('keyup', this.submit);
    }
  }

  submit(e) {
    if (e.key === 'Enter' && !this.props.disableConfirm) {
      this.props.onSubmit();
    }
  }

  render() {
    const confirmBtnModifiers = ['teeny', 'high', 'uppercase', ...this.props.confirmBtnModifiers];
    const cancelBtnModifiers = ['teeny', 'high', 'uppercase', ...this.props.cancelBtnModifiers];

    return (
      <MDModal
        show={this.props.show}
        onHide={this.props.onHide}
        modalStyle={this.props.modalStyle}
        modifiers={this.props.modifiers}
        withoutOverlayScroll={this.props.withoutOverlayScroll}
        withOverlayScrollContent={this.props.withOverlayScrollContent}
        loading={this.props.loading}
        className={this.props.className}
        onHideEnd={this.props.onHideEnd}
      >
        <div className="mdKadroModal" data-test={this.props.testId && `${this.props.testId}-modal`}>
          {this.props.showSidebar && (
            <MDModal.Sidebar
              options={this.props.options}
              activeTab={this.props.activeTab}
              onTabClick={this.props.onTabClick}
              user={this.props.user}
              content={this.props.sidebarContent}
            />
          )}
          {this.props.withOverlayScrollContent ? (
            <div className="mdKadroModal__wrapper">
              <MDModal.Header>
                <MDKadroModalHeader
                  title={this.props.title}
                  options={this.props.headerOptions}
                  onHide={this.props.onHide}
                  withoutOptions={this.props.headerWithoutOptions}
                  headerModifiers={this.props.headerModifiers}
                  withOverlayScrollContent={this.props.withOverlayScrollContent}
                />
              </MDModal.Header>
              <MDModal.Content
                withOverlayScrollContent={this.props.withOverlayScrollContent}
                contentErrorMessage={this.props.contentErrorMessage}
                modifiers={this.props.modifiers}
              >
                {this.props.children}
              </MDModal.Content>
              <MDModal.Footer>
                <MDKadroModalFooter
                  errorMessage={this.props.errorMessage}
                  cancelText={this.props.cancelText}
                  confirmText={this.props.confirmText}
                  onCancel={this.props.onCancelFooter || this.props.onHide}
                  onSubmit={this.props.onSubmit}
                  cancelBtnModifiers={cancelBtnModifiers}
                  confirmBtnModifiers={confirmBtnModifiers}
                  hideFooter={this.props.hideFooter}
                  options={this.props.footerOptions}
                  hideConfirmButton={this.props.hideConfirmButton}
                  hideCancelButton={this.props.hideCancelButton}
                  currentStep={this.props.currentStep}
                  stepsCount={this.props.stepsCount}
                  showProgressBar={this.props.showProgressBar}
                  disableConfirm={this.props.disableConfirm}
                  disableCancel={this.props.disableCancel}
                  modifiers={this.props.footerModifiers}
                  withOverlayScrollContent={this.props.withOverlayScrollContent}
                />
              </MDModal.Footer>
            </div>
          ) : (
            <MDModal.Content withoutOverlayScroll={this.props.withoutOverlayScroll}>
              <MDModal.Header>
                <MDKadroModalHeader
                  title={this.props.title}
                  options={this.props.headerOptions}
                  onHide={this.props.onHide}
                  withoutOptions={this.props.headerWithoutOptions}
                />
              </MDModal.Header>
              {this.props.children}
              <MDModal.Footer>
                <MDKadroModalFooter
                  errorMessage={this.props.errorMessage}
                  cancelText={this.props.cancelText}
                  confirmText={this.props.confirmText}
                  onCancel={this.props.onCancelFooter || this.props.onHide}
                  onSubmit={this.props.onSubmit}
                  cancelBtnModifiers={cancelBtnModifiers}
                  confirmBtnModifiers={confirmBtnModifiers}
                  hideFooter={this.props.hideFooter}
                  options={this.props.footerOptions}
                  hideConfirmButton={this.props.hideConfirmButton}
                  hideCancelButton={this.props.hideCancelButton}
                  currentStep={this.props.currentStep}
                  stepsCount={this.props.stepsCount}
                  showProgressBar={this.props.showProgressBar}
                  disableConfirm={this.props.disableConfirm}
                  disableCancel={this.props.disableCancel}
                  modifiers={this.props.footerModifiers}
                />
              </MDModal.Footer>
            </MDModal.Content>
          )}
        </div>
      </MDModal>
    );
  }
}

MDKadroModal.defaultProps = {
  showSidebar: false,
  modalStyle: {},
  modifiers: [],
  cancelText: <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />,
  confirmText: <FormattedMessage id="common.save" defaultMessage="Zapisz" />,
  errorMessage: '',
  cancelBtnModifiers: ['reverse-orange'],
  confirmBtnModifiers: ['orange'],
  footerModifiers: [],
  withoutOverlayScroll: false,
};

MDKadroModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  modalStyle: PropTypes.shape({}),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  headerOptions: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
  cancelText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  confirmText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  footerModifiers: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  cancelBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  confirmBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.number,
  onTabClick: PropTypes.func,
  user: PropTypes.shape({
    avatar: PropTypes.string,
  }),
  hideFooter: PropTypes.bool,
  footerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      handleClick: PropTypes.func,
    }),
  ),
  sidebarContent: PropTypes.node,
  hideConfirmButton: PropTypes.bool,
  onCancelFooter: PropTypes.func,
  withoutOverlayScroll: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  showProgressBar: PropTypes.bool,
  showSidebar: PropTypes.bool,
  inputAwareSubmit: PropTypes.bool,
  children: PropTypes.node,
  disableConfirm: PropTypes.bool,
  disableCancel: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  headerWithoutOptions: PropTypes.bool,
  testId: PropTypes.string,
  onHideEnd: PropTypes.func,
  withOverlayScrollContent: PropTypes.bool,
  headerModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default MDKadroModal;
