import { useAppSelector } from '@/redux-store';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { getScheduleColSpan } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useScheduleTableLoanEmployeesProposalsCell = () => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const colSpan = getScheduleColSpan(isSingleDay);

  return { colSpan };
};
