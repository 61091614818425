export const validateNightHours = (nightHours: string): boolean => {
  const [start, end] = nightHours.split('-');
  if (!start || !end) {
    return false;
  }

  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');

  const isHoursRangeValid =
    parseInt(startHour) >= 21 &&
    parseInt(endHour) <= 7 &&
    parseInt(startMinute) >= 0 &&
    parseInt(startMinute) <= 59 &&
    parseInt(endMinute) >= 0 &&
    parseInt(endMinute) <= 59;

  if (!isHoursRangeValid) {
    return false;
  }

  const minDurationInMinutes = 8 * 60;
  const maxDurationInMinutes = 10 * 60;
  const startHourMinutes = parseInt(startHour) * 60 + parseInt(startMinute);
  const endHourMinutes = parseInt(endHour) * 60 + parseInt(endMinute);

  const isHoursDurationValid =
    endHourMinutes + 24 * 60 - startHourMinutes >= minDurationInMinutes &&
    endHourMinutes + 24 * 60 - startHourMinutes <= maxDurationInMinutes;

  if (!isHoursDurationValid) {
    return false;
  }

  return true;
};
