import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents';

import { messages } from './DevicesModal.messages';

const DeviceLoginMethods = (
  { loginMethodPin, loginMethodQr, loginMethodRfid, loginMethodNfc, os, onChange },
  context,
) => {
  const disabled = os === 'ios';
  return (
    <>
      <div className="addDeviceModal__subtitle">
        <FormattedMessage id="companymanage.devices.loginMethodsTitle" defaultMessage="Metody logowania" />
      </div>
      <MDCheckbox
        id="loginMethodPin"
        text={context.intl.formatMessage(messages.loginMethodPin)}
        value={loginMethodPin}
        onChange={onChange}
        tooltip={context.intl.formatMessage(messages.loginMethodPinTooltip)}
        reverse
      />
      <MDCheckbox
        id="loginMethodQr"
        text={context.intl.formatMessage(messages.loginMethodQr)}
        value={loginMethodQr}
        onChange={onChange}
        tooltip={context.intl.formatMessage(messages.loginMethodQrTooltip)}
        reverse
      />
      <MDCheckbox
        id="loginMethodRfid"
        disabled={disabled}
        text={context.intl.formatMessage(messages.loginMethodRfid)}
        value={loginMethodRfid}
        onChange={onChange}
        tooltip={context.intl.formatMessage(messages.loginMethodRfidTooltip)}
        reverse
      />
      <MDCheckbox
        id="loginMethodNfc"
        disabled={disabled}
        text={context.intl.formatMessage(messages.loginMethodNfc)}
        value={loginMethodNfc}
        onChange={onChange}
        tooltip={context.intl.formatMessage(messages.loginMethodNfcTooltip)}
        reverse
      />
    </>
  );
};

DeviceLoginMethods.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DeviceLoginMethods.propTypes = {
  loginMethodPin: PropTypes.bool,
  loginMethodQr: PropTypes.bool,
  loginMethodRfid: PropTypes.bool,
  loginMethodNfc: PropTypes.bool,
  os: PropTypes.string,
  onChange: PropTypes.func,
};

export default DeviceLoginMethods;
