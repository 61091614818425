import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  scheduleTableItemPersonOffOnHover: {
    id: 'scheduleTable.item.personOff.onHover',
    defaultMessage: 'Wskazany dzień wykracza poza okres zatrudnienia tego pracownika',
  },
  scheduleTableItemPersonOffOnHoverForLoanedEmployee: {
    id: 'scheduleTable.item.personOff.onHover.loanedEmployee',
    defaultMessage: 'Nie można dodać zmiany udostępnionemu pracownikowi',
  },
});
