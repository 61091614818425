import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';

import { isUndefinedType } from '@/utils/variableTypes/variableTypes';

import { useToggle } from '../useToggle/useToggle';

type HoveredContextType = [boolean, () => void];

const HoveredContext = createContext<HoveredContextType>(null!);

type Props = {
  children: React.ReactNode;
};

export const HoveredProvider = ({ children }: Props) => {
  const toggleValues = useToggle();
  const [isHovered, , onHover, offHover] = toggleValues;

  const values: HoveredContextType = useMemo(() => [isHovered, offHover], [isHovered, offHover]);

  return (
    <HoveredContext.Provider value={values}>
      <div onMouseEnter={onHover} onMouseLeave={offHover}>
        {children}
      </div>
    </HoveredContext.Provider>
  );
};

export const useIsHovered = () => {
  const context = useContext(HoveredContext);

  if (isUndefinedType(context)) throw Error('You are outside of hover provider');

  return context;
};
