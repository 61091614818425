import PropTypes from 'prop-types';

const ListSection = ({ data, render }) => (
  <div>
    {data.map((d, i) => (
      <div className="editModalListSection__list" key={d.id}>
        {render(d, i)}
      </div>
    ))}
  </div>
);

ListSection.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  render: PropTypes.func,
};

export default ListSection;
