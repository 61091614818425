import { CANCEL_AUTOSCHEDULER, FINISH_AUTOSCHEDULER, START_AUTOSCHEDULER } from '@/constants/ActionTypes';

const initialState = {
  pendingRequestId: null,
  result: null,
  openShiftsIdsToDelete: null,
  isScheduleGenerationCancelled: false,
};

const autoscheduler = (state = initialState, { type, payload }) => {
  switch (type) {
    case START_AUTOSCHEDULER:
      return {
        ...state,
        pendingRequestId: payload.id,
        openShiftsIdsToDelete: payload.openShiftsIdsToDelete,
        isScheduleGenerationCancelled: false,
      };
    case FINISH_AUTOSCHEDULER:
      return {
        pendingRequestId: null,
        result: payload,
        isScheduleGenerationCancelled: false,
      };
    case CANCEL_AUTOSCHEDULER:
      return {
        ...state,
        isScheduleGenerationCancelled: true,
      };
    default:
      return state;
  }
};

export default autoscheduler;
