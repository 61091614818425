import { connect } from 'react-redux';

import { createBudgetMetric, editBudgetMetric } from '@/actions/budgetMetrics';
import { hideModal } from '@/actions/uiState.js';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes.js';

import AddOrEditBudgetMetricModal from './AddOrEditBudgetMetricModal.jsx';

const mapStateToProps = state => ({
  visible: state.reducer.uiState.showModal === ADD_OR_EDIT_BUDGET_METRIC_MODAL,
  editedBudgetMetricId: state.reducer.uiState.modalObject[ADD_OR_EDIT_BUDGET_METRIC_MODAL],
  userBudgetMetrics: state.reducer.budgetMetrics,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
  createBudgetMetric,
  editBudgetMetric,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditBudgetMetricModal);
