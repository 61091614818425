import { GROUP_JOBTITLES_SUCCESS } from '@/constants/ActionTypes';

const initialState = {};

const jobTitlesGrouped = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_JOBTITLES_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
};
export default jobTitlesGrouped;
