import { CLOSE_NOTIFICATION_BAR,SHOW_NOTIFICATION_BAR } from '@/constants/ActionTypes.js';

export const showNotificationBar = (content, canClose) => ({
  type: SHOW_NOTIFICATION_BAR,
  payload: content,
  canClose,
});

export const closeNotificationBar = () => ({
  type: CLOSE_NOTIFICATION_BAR,
});
