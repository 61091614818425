import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { getExports } from '@/components/common/exports/exportsConfig.ts';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { EXPORTS_TYPES } from '@/constants/exportFilesFormats';
import {
  CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL,
  NEW_PAYROLL_EXPORT_MODAL,
  SHIFTS_AND_ATTENDANCES_IMPORT_MODAL,
} from '@/constants/modalTypes';
import { CALCULATE_SPMH_BASED_BONUS, IMPORT_SHIFTS_AND_ATTENDANCES } from '@/constants/Permissions.js';
import { FREEMIUM_HIDE_ATTENDANCE_VIEW } from '@/constants/Restrictions';

const messages = defineMessages({
  options: {
    id: 'payrollLocation.button.options',
    defaultMessage: 'Opcje',
  },
});

const PayrollLocationActionButtons = (
  {
    showModal,
    areSomeEmployeesInTable,
    calculateSpmhBasedBonusesForNewPayrollLocation,
    restrictions,
    permissions,
    togglePayrollSettingsModal,
    visibleEmployeeIds,
    payoutSettingType,
  },
  { intl },
) => {
  const actionsButtonRef = useRef(null);
  const isExportBlocked = restrictions.includes(RESTRICTIONS.EXPORT_VIEW_HIDE);

  const handleClick = callback => {
    callback();
    if (actionsButtonRef?.current?.hide) actionsButtonRef.current.hide();
  };

  return (
    <ActionsButton
      icon={<Icon name="more_vert" />}
      buttonTitle={intl.formatMessage(messages.options)}
      ref={actionsButtonRef}
    >
      <button
        className="k-actionsButton__element k-actionsButton__element--with-icon"
        onClick={togglePayrollSettingsModal}
      >
        <i className="material-icons-outlined">tune</i>
        <FormattedMessage id="payrollActionButtons.payrollSettings" defaultMessage="Ustawienia czasu pracy" />
      </button>
      {!isExportBlocked && (
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          disabled={!visibleEmployeeIds?.length}
          onClick={() =>
            showModal(NEW_PAYROLL_EXPORT_MODAL, {
              formats: getExports(showModal, payoutSettingType, permissions, intl, false, true).filter(
                ({ showInEcpView }) => showInEcpView,
              ),
              preselectedExport: EXPORTS_TYPES.payrollLocationXls,
              visibleEmployeeIds,
            })
          }
        >
          <i className="material-icons mirrorFlip">reply</i>
          <FormattedMessage id="payrollLocationActionButtons.export" defaultMessage="Eksportuj" />
        </button>
      )}
      <FeatureWrapper permission={CALCULATE_SPMH_BASED_BONUS}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          onClick={() => handleClick(calculateSpmhBasedBonusesForNewPayrollLocation)}
          disabled={!areSomeEmployeesInTable}
        >
          <i className="material-icons">request_quote</i>
          <FormattedMessage
            id="payrollLocationActionButtons.calculateSpmhBasedBonuses"
            defaultMessage="Oblicz bonusy"
          />
        </button>
      </FeatureWrapper>
      <FeatureWrapper restriction={FREEMIUM_HIDE_ATTENDANCE_VIEW}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          onClick={() => showModal(CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL)}
        >
          <i className="material-icons">dataset</i>
          <FormattedMessage id="payrollActionButtons.chooseSummaryValues" defaultMessage="Dostosuj podsumowanie" />
        </button>
      </FeatureWrapper>
      <FeatureWrapper permission={IMPORT_SHIFTS_AND_ATTENDANCES}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          onClick={() => showModal(SHIFTS_AND_ATTENDANCES_IMPORT_MODAL)}
        >
          <i className="material-icons">playlist_add</i>
          <FormattedMessage
            id="payrollLocationActionButtons.importWorkingHours"
            defaultMessage="Importuj godziny pracy"
          />
        </button>
      </FeatureWrapper>
    </ActionsButton>
  );
};

PayrollLocationActionButtons.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollLocationActionButtons.propTypes = {
  showModal: PropTypes.func,
  areSomeEmployeesInTable: PropTypes.bool,
  calculateSpmhBasedBonusesForNewPayrollLocation: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  togglePayrollSettingsModal: PropTypes.func,
  visibleEmployeeIds: PropTypes.arrayOf(PropTypes.string),
  payoutSettingType: PropTypes.string,
};

export default PayrollLocationActionButtons;
