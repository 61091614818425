import { conn, connectionError, deleteAvailability, showConfirmModal } from '@/actions/index';
import { getAvailabilityFromUserEmployees } from '@/actions/schedule/availabilities.helpers';
import messages from '@/actions/schedule/availabilities.messages';
import * as actionTypes from '@/constants/ActionTypes';
import { getAvaCornerPopover } from '@/utils/schedule/availabilityCornerHelpers';

export const confirmAvailabilitySuccessful = availability => ({
  type: actionTypes.CHANGE_AVAILABILITY_SUCCESFUL,
  payload: availability,
});

export const confirmAvailability = (availabilityId, employeeId) => (dispatch, getState, intl) => {
  const { userEmployees, employees } = getState().reducer;
  const availability = getAvailabilityFromUserEmployees(userEmployees, availabilityId, employeeId);
  if (!availability) return;
  conn
    .publishAvailability(availability)
    .then(() => {
      const employee = employees.data[employeeId];
      const ava = { ...availability, draft: false, employee: { id: employeeId } };
      dispatch(confirmAvailabilitySuccessful({ ...ava, popover: getAvaCornerPopover(ava, employee, intl) }));
    })
    .catch(err => connectionError(err));
};

export const deleteAvailabilityConfirm = (employeeId, availabilityId) => (dispatch, getState, intl) => {
  const { userEmployees } = getState().reducer;
  const availability = getAvailabilityFromUserEmployees(userEmployees, availabilityId, employeeId);
  if (!availability) return;
  const title = intl.formatMessage(messages.deleteAvailabilityConfirmTitle);
  const description = intl.formatMessage(messages.deleteAvailabilityConfirmDesc);
  const confirmText = intl.formatMessage(messages.delete);
  const confirmFunc = () => {
    dispatch(deleteAvailability(employeeId, availability));
  };

  const confirmType = 'info';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

const deleteAvailabilitiesSuccesful = availabilitiesIds => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_AVAILABILITIES_SUCCESFUL,
    payload: availabilitiesIds,
    notification: {
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.deleteAvailabilitiesSuccesful),
      type: 'success',
    },
  });
};

const deleteAvailabilitiesError = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_AVAILABILITIES_ERROR,
    notification: {
      title: intl.formatMessage(messages.failToast),
      description: intl.formatMessage(messages.deleteAvailabilitiesError),
      type: 'error',
    },
  });
};

const deleteAvailabilitiesByIds = (availabilitiesIds, requestType = 'blocking') => async dispatch => {
  try {
    await conn.deleteAvailability(availabilitiesIds, requestType);
    dispatch(deleteAvailabilitiesSuccesful(availabilitiesIds));
  } catch (err) {
    dispatch(deleteAvailabilitiesError());
  }
};

export const deleteAvailabilitiesConfirm = availabilitiesIds => (dispatch, getState, intl) => {
  const confirmFunc = () => {
    dispatch(deleteAvailabilitiesByIds(availabilitiesIds));
  };
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteAvailabilitiesConfirmTitle),
      description: intl.formatMessage(messages.deleteAvailabilitiesConfirmDesc, {
        availabilityNo: availabilitiesIds.length,
      }),
      confirmText: intl.formatMessage(messages.delete),
      confirmFunc,
    }),
  );
};
