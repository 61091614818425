import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';

import messages from './AttendancesTableOptions/messages.js';

const AttendanceTableCheckbox = ({ isDisabled, handleCheckboxChange, attendanceId, isSelected }) => (
  <MDCheckbox
    value={isSelected}
    onChange={() => {
      handleCheckboxChange(attendanceId);
    }}
    reverse
    disabled={isDisabled}
    hoverLabel={isDisabled && <FormattedMessage {...messages.attendancesTableOptionsEditBlocked} />}
  />
);

AttendanceTableCheckbox.propTypes = {
  isDisabled: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  isSelected: PropTypes.bool,
  attendanceId: PropTypes.string,
};

export default AttendanceTableCheckbox;
