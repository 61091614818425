import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';

import './locationsHeader.scss';

const LocationsHeader = props => (
  <thead className="k-locationsHeader">
    <tr>
      <Checkbox
        className="k-locationsHeader__checkbox"
        checked={props.stateUI.selected.length > 0 && props.stateUI.selected.length === props.visible.length}
        toggle={props.toggle}
      />
      <th
        className="tab_title tab_title--unsortedSpace k-locationsHeader__headerCell k-locationsHeader__headerCell--sortable"
        onClick={() => {
          props.sortFunc(0, 'locations');
        }}
      >
        <span role="none" className="noselect">
          <FormattedMessage id="companymanage.LocationsHeader.locations" defaultMessage="Lokalizacje" />
        </span>
        <SortingArrow display={props.stateUI.sortColumn === 0} up={!props.stateUI.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(1, 'locations');
        }}
        className="k-locationsHeader__headerCell k-locationsHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.LocationsHeader.city" defaultMessage="Miasto" />
        <SortingArrow display={props.stateUI.sortColumn === 1} up={!props.stateUI.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(2, 'locations');
        }}
        className="k-locationsHeader__headerCell k-locationsHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.LocationsHeader.status" defaultMessage="Status" />
        <SortingArrow display={props.stateUI.sortColumn === 2} up={!props.stateUI.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(3, 'locations');
        }}
        className="k-locationsHeader__headerCell k-locationsHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.LocationsHeader.pin" defaultMessage="PIN" />
        <SortingArrow display={props.stateUI.sortColumn === 3} up={!props.stateUI.sortDecreesing} />
      </th>
      <th className="k-locationsHeader__headerCell">
        <FormattedMessage id="companymanage.LocationsHeader.options" defaultMessage="Opcje" />
      </th>
    </tr>
  </thead>
);

LocationsHeader.propTypes = {
  sortFunc: PropTypes.func.isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
  }).isRequired,
  visible: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
};

export default LocationsHeader;
