import ProductionQuotasTableViewContainer from '@/containers/productionQuotas/ProductionQuotasTableViewContainer.jsx';

import TopBarProductionQuotas from './TopBars/TopBarProductionQuotas.jsx';

const ProductionQuotasView = () => (
  <div>
    <TopBarProductionQuotas />
    <ProductionQuotasTableViewContainer />
  </div>
);

export default ProductionQuotasView;
