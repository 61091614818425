import { connect } from 'react-redux';

import EmployeesEditMassActionSelect from './EmployeesEditMassActionSelect.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions.permissions,
});

const EmployeesEditMassActionSelectContainer = connect(mapStateToProps)(EmployeesEditMassActionSelect);

export default EmployeesEditMassActionSelectContainer;
