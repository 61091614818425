import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';

export const getRelevantDatesToApprove = selectedRows =>
  Array.from(
    new Set(
      selectedRows.filter(row => row.isUnapproved && row.date >= PAYROLL_APPROVAL_START_DATE).map(row => row.date),
    ),
  );

export const getRelevantDatesToUnapprove = selectedRows =>
  Array.from(
    new Set(
      selectedRows.filter(row => !row.isUnapproved && row.date >= PAYROLL_APPROVAL_START_DATE).map(row => row.date),
    ),
  );
