import { connect } from 'react-redux';

import { draggedShiftToOpenShift } from '@/actions/schedule/dragAndDrop';

import Component from './OpenShiftDropArea.jsx';

const mapStateToProps = (state, ownProps) => ({
  enabled: state.reducer.schedule.dragAndDrop.draggedShiftDate === ownProps.date,
});

const mapDispatchToProps = { draggedShiftToOpenShift };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
