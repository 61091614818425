import PropTypes from 'prop-types';
import * as React from 'react';

import Tooltip from '@/components/common/Tooltip/Tooltip';

import { messages } from './personOffIconWithTooltip.messages.ts';

interface PersonOffIconWithTooltipProps {
  isLoanedEmployee: boolean;
  isQuickPlanningEnabled: boolean;
  personOffIconClassName: string;
}

const PersonOffIconWithTooltip: React.FC<PersonOffIconWithTooltipProps> = (
  { isLoanedEmployee, isQuickPlanningEnabled, personOffIconClassName },
  { intl },
) => {
  let message = messages.scheduleTableItemPersonOffOnHover;
  if (isLoanedEmployee) message = messages.scheduleTableItemPersonOffOnHoverForLoanedEmployee;

  return (
    <Tooltip content={intl.formatMessage(message)} yOffset={30}>
      <div>{!isQuickPlanningEnabled && <span className={personOffIconClassName}>person_off</span>}</div>
    </Tooltip>
  );
};

PersonOffIconWithTooltip.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default PersonOffIconWithTooltip;
