import { connect } from 'react-redux';

import { addMassUserEvent, addUserEvent, changeUserEvent, deleteUserEvent, getDaysFreeFromWork } from '@/actions';
import { toggleNonWorkingDays } from '@/actions/schedule';
import { hideModal } from '@/actions/uiState.js';

import EditEventModal from './EditEventModal';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal,
  modalObject: state.reducer.uiState.modalObject,
  companyId: state.reducer.currentCompany.id,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
});

const mapDispatchToProps = {
  changeUserEvent,
  addUserEvent,
  hideModal,
  deleteUserEvent,
  toggleNonWorkingDays,
  getDaysFreeFromWork,
  addMassUserEvent,
};
const EditEventModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditEventModal);

export default EditEventModalContainer;
