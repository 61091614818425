import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

import { inputValidation } from '@/utils/inputValidation.js';

import { getFormattedEstimate } from '../ScheduleBudgetTable.helpers.js';

const ScheduleBudgetTableRowInput = props => {
  const [value, setValue] = useState(props.estimate[props.name]);

  useEffect(() => {
    setValue(getFormattedEstimate(props.estimate[props.name]));
  }, [props.estimate, props.name]);

  const onChange = ({ target }) => {
    setValue(target.value);
  };

  const onEstimateChange = ({ target }) => {
    const { name } = target;
    const newValue = target.value.replace(',', '.').replace(/^-$/, '');
    const error = newValue ? inputValidation(name, newValue) : '';
    setValue(newValue ? Number(newValue) : '-');
    props.setError(name, props.estimate.date, error !== '');
    const valueToSave = newValue ? Number(newValue) * 100 : null;
    props.onChange({ [props.name]: valueToSave });
  };

  return (
    <>
      <input
        name={props.name}
        onBlur={onEstimateChange}
        onChange={onChange}
        type="text"
        className="form-control"
        value={value}
        disabled={props.disabled}
      />
      {props.children}
    </>
  );
};

ScheduleBudgetTableRowInput.propTypes = {
  estimate: PropTypes.shape({
    date: PropTypes.string,
    location_id: PropTypes.string,
  }),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  setError: PropTypes.func,
};

export default ScheduleBudgetTableRowInput;
