import PropTypes from 'prop-types';
import { Component } from 'react';

class Range extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || 0,
    };
    this.changeFunc = this.changeFunc.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!Number.isNaN(this.props.value) && this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  changeFunc(e) {
    if (this.props.disabled) return;
    const { value } = e.target;
    this.props.onChangeFunc(value);
    this.setState({ value });
  }

  render() {
    return (
      <div className="range-big" style={this.props.style ? this.props.style : {}}>
        <input
          id={this.props.labelId}
          value={this.state.value}
          onChange={this.changeFunc}
          style={{
            backgroundColor: 'transparent',
            width: this.props.width ? this.props.width : 'null',
          }}
          type="range"
          name="points"
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          disabled={this.props.disabled}
          className="range-big__input"
        />
      </div>
    );
  }
}

Range.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  labelId: PropTypes.string,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default Range;
