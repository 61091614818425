import { connect } from 'react-redux';

import { mainDateChangeMode,mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
// import {  } from '@/actions/'
import EmployeePayrollTable from '@/components/employee/payroll/EmployeePayrollTable.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  userLocations: state.reducer.userLocations,
  userEmployees: state.reducer.userEmployees,
  payrollSettings: state.reducer.payrollSettings,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  pathname: state.routing.pathname,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  mainDateChangeMode,
};

const EmployeePayrollTableContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeePayrollTable);

export default EmployeePayrollTableContainer;
