export const mapDataForChart = rawData =>
  Object.entries(rawData).map(([date, dataItem]) => {
    const plannedHours = dataItem.planned_minutes / 60;
    const workedHours = dataItem.worked_minutes / 60;

    return {
      date,
      plannedLabourCost:
        plannedHours && dataItem.estimated_budget
          ? Math.round((dataItem.planned_cost / dataItem.estimated_budget) * 100)
          : 0,
      actualLabourCost:
        workedHours && dataItem.actual_budget ? Math.round((dataItem.actual_cost / dataItem.actual_budget) * 100) : 0,
    };
  });
