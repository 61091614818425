/* eslint-disable camelcase */
import moment from 'moment';

import { messages } from './AbsenceProposalModal.messages';

export const emptyAbsence = {
  isEmpty: true,
  absence_hours: '',
  request_number: '',
  status: '',
  created_at_timestamp: null,
  absence_type_name: '',
  note: '',
  duration: null,
  from: null,
  to: null,
  omit_weekends: false,
  omit_holidays: false,
  count_only_days_with_shifts: false,
};

export const getAbsenceCreatedDate = absence => {
  if (absence.isEmpty) return '';
  return moment(absence.created_at_timestamp).format('DD.MM.YYYY');
};

export const getAbsenceInterval = (absence, intl) => {
  if (absence.isEmpty) return '';
  const { absence_hours, from, to } = absence;
  if (absence_hours) return absence_hours;
  const fromText = intl.formatMessage(messages.absenceHoursFrom);
  const toText = intl.formatMessage(messages.absenceHoursTo);
  return `${fromText} ${from} ${toText} ${to}`;
};

export const getAbsenceDurationDetails = (absence, intl) => {
  const { omit_weekends, omit_holidays, count_only_days_with_shifts } = absence;
  const messagesArray = [];
  if (omit_weekends && omit_holidays) {
    messagesArray.push(intl.formatMessage(messages.omitWeekendsAndHolidays));
  } else {
    if (omit_weekends) messagesArray.push(intl.formatMessage(messages.omitWeekends));
    if (omit_holidays) messagesArray.push(intl.formatMessage(messages.omitHolidays));
  }
  if (count_only_days_with_shifts) messagesArray.push(intl.formatMessage(messages.countedDaysWithShifts));
  return messagesArray.length > 0 ? `(${messagesArray.join(', ')})` : '';
};
