const countExportsStatistics = pendingExports =>
  pendingExports.reduce(
    (stats, pendingExport) => {
      if (!pendingExport.status) return { ...stats, pending: stats.pending + 1 };
      if (pendingExport.status === 'success' || pendingExport.status === 'unseen')
        return { ...stats, success: stats.success + 1 };
      if (pendingExport.status === 'error') return { ...stats, error: stats.error + 1 };
      return stats;
    },
    { pending: 0, success: 0, error: 0 },
  );

export const getDetailsForExportIcon = pendingExports => {
  const exportsStatistics = countExportsStatistics(pendingExports);
  if (exportsStatistics.pending > 0) return { content: exportsStatistics.pending, progress: 75 };
  if (exportsStatistics.error > 0)
    return { content: <i className="material-icons k-sideBarExportIcon__error">priority_high</i>, progress: 100 };
  if (exportsStatistics.success > 0) {
    const content = exportsStatistics.success > 9 ? '9+' : exportsStatistics.success;
    return { content, progress: 100 };
  }
  return {};
};
