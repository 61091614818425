export const payrollExportData = {
  headers: [
    'Dzień',
    'Lokalizacja',
    'Od',
    'Do',
    'Stanowisko',
    'Stawka',
    'Nieobecność',
    'Suma godzin',
    'Godziny nocne',
    'Bonus',
    'Realizacja / Plan',
    'Różnica',
    'Nadgodziny 50%',
    'Nadgodziny 100%',
    'Nadgodziny ŚT',
    'Przerwy',
    'Do wypłaty',
    'Podpis kierownika',
    'Podpis pracownika',
    'Podpis zleceniobiorcy',
  ],
  summaryHeaders: [
    '',
    '',
    '',
    '',
    '',
    '',
    'Nieobecność',
    'Suma godzin',
    'Godziny nocne',
    'Bonusy',
    'Realizacja / Plan',
    'Różnica',
    'Nadgodziny 50%',
    'Nadgodziny 100%',
    'Nadgodziny ŚT',
    'Przerwy',
    'Razem do wypłaty',
    '',
    '',
    '',
  ],
  columnsWidth: [
    'width:12%',
    'width:12%',
    'width:6%',
    'width:6%',
    'width:12%',
    'width:6%',
    'width:6%',
    'width:10%',
    'width:10%',
    'width:8%',
    'width:10%',
    'width:10%',
    'width:14%',
    'width:14%',
    'width:12%',
    'width:10%',
    'width:12%',
    'width:10%',
    'width:10%',
    'width:14%',
  ],
  columnsStyle: [
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
  ],
  columnsPermission: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
};

export const payrollLocationExportData = {
  headers: [
    'Pracownik',
    'Stanowiska',
    'Stawka [PLN/h]',
    'Nieobecności',
    'Suma godzin',
    'Godziny nocne',
    'Bonus [PLN]',
    'Realizacja / Plan',
    'Różnica',
    'Nadgodziny 50%',
    'Nadgodziny 100%',
    'Do wypłaty [PLN]',
    'Podpis kierownika',
    'Podpis pracownika',
  ],
  contractorHeaders: [
    'Zleceniobiorca',
    'Stanowiska',
    'Stawka [PLN/h]',
    'Nieobecności',
    'Suma godzin',
    'Godziny nocne',
    'Bonus [PLN]',
    'Realizacja / Plan',
    'Różnica',
    'Nadgodziny 50%',
    'Nadgodziny 100%',
    'Do wypłaty [PLN]',
    'Podpis zleceniodawcy',
    'Podpis zleceniobiorcy',
  ],
  summaryHeaders: [
    '',
    '',
    '',
    'Nieobecności',
    'Suma godzin',
    'Godziny nocne',
    'Bonusy',
    'Realizacja / Plan',
    'Różnica',
    'Nadgodziny 50%',
    'Nadgodziny 100%',
    'Razem do wypłaty',
    '',
    '',
  ],
  columnsWidth: [
    'width:20%',
    'width:20%',
    'width:15%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
    'width:10%',
  ],
  columnsStyle: [
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
  ],
};

export const AttendanceExportData = {
  headers: ['Pracownik', 'Lokalizacja', 'Od/Do (Grafik)', 'Od/Do (RCP)', 'Podpis kierownika', 'Podpis pracownika'],
  summaryHeaders: [],
  columnsWidth: ['width:24%', 'width:16%', 'width:16%', 'width:16%', 'width:16%', 'width:16%'],
  columnsStyle: ['colCenter', 'colCenter', 'colCenter', 'colCenter', 'colCenter', 'colCenter'],
};

export const dayReportExportData = {
  headers: [
    'Lokalizacja',
    'Suma godzin',
    'Suma zaplanowanych godzin',
    'Różnica',
    'Koszt',
    'Zaplanowany koszt',
    'Ilość obecności',
    'Spóźnienia',
    'Nieobecności',
    'Frekwencja',
  ],
  summaryHeaders: ['', '', '', '', '', '', '', '', '', ''],
  columnsWidth: [
    'width:15%',
    'width:12%',
    'width:12%',
    'width:12%',
    'width:8%',
    'width:8%',
    'width:8%',
    'width:8%',
    'width:8%',
    'width:8%',
  ],
  columnsStyle: [
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
    'colCenter',
  ],
};
