import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { KadroCalendarMultiple } from '@/components/common/KadroCalendars';

import Popover from '../../Popover/Popover.jsx';

const DateInput = props => {
  const onChange = date => {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
    props.handleInput({
      target: {
        name: props.slug,
        type: 'date',
        value: formattedDate === props.value ? '' : formattedDate,
      },
    });
  };

  const className = classNames({ labelTitle: props.label });

  return (
    <div className="k-dateInput">
      <div className="k-dateInput__name">
        <div className={className}>{props.name}</div>
        {props.label ? <div className="labelDesc">{props.label}</div> : null}
      </div>
      <Popover
        position="left"
        yOffset={5}
        content={<KadroCalendarMultiple onChange={onChange} selected={props.value ? [props.value] : []} />}
        wrapperStyle={{ display: 'inline-block', margin: '0 8px' }}
      >
        <div className="k-dateInput__wrapper">
          <div className="k-dateInput__input">{props.value}</div>
          <div className="k-dateInput__label">
            <i className="k-icon k-icon-calendar" />
          </div>
        </div>
      </Popover>
    </div>
  );
};

DateInput.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.node,
  label: PropTypes.shape({}),
  value: PropTypes.string,
  handleInput: PropTypes.func,
};

export default DateInput;
