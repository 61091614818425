import classnames from 'classnames';
import PropTypes from 'prop-types';

const UnauthenticatedLayoutShowcase = props => {
  const classname = classnames('k-unauthenticatedShowcase', props.className);
  return (
    <div className={classname}>
      <div className="k-unauthenticatedShowcase__background">
        <div
          className="k-unauthenticatedShowcase__wrapper"
          dangerouslySetInnerHTML={{ __html: props.promoContents.html }}
        />
      </div>
    </div>
  );
};

UnauthenticatedLayoutShowcase.propTypes = {
  promoContents: PropTypes.shape({
    html: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default UnauthenticatedLayoutShowcase;
