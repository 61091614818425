import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'blockedAccountView.paymentTitle',
    defaultMessage: 'Konto zostało zablokowane',
  },
  content: {
    id: 'blockedAccountView.content',
    defaultMessage: 'Konto zostało zablokowane. Skontaktuj się z nami, aby je odblokować.',
  },
  button: {
    id: 'blockedAccountView.button',
    defaultMessage: 'Napisz na czacie',
  },
  intercomMessage: {
    id: 'blockedAccountView.intercomMessage',
    defaultMessage: 'Dzień dobry\nMoje konto zostało zablokowane.\nCo mogę zrobić, aby zostało odblokowane?',
  },
  blockedAccountImgAltText: {
    id: 'blockedAccountView.content.blockedAccountImgAltText',
    defaultMessage: 'Kobieta siedząca przy komputerze',
  },
});
