import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import AbsencesProposalItem from './AbsencesProposalItem.jsx';

const mapStateToProps = (state, ownProps) => ({
  first_name: state.reducer.employees.data[ownProps.employeeId]?.first_name,
  last_name: state.reducer.employees.data[ownProps.employeeId]?.last_name,
  typeName: state.reducer.absences.absencesTypes.find(t => t.id === ownProps.absence.type_id)?.name,
});

const mapDispatchToProps = {
  showModal,
};

const AbsencesProposalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsencesProposalItem);

export default AbsencesProposalContainer;
