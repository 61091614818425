import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ShownCounter from '@/components/common/ShownCounter.jsx';
import EmptyState from '@/components/emptyState/EmptyState';
import { CUSTOM_AVA_TYPE, DEFAULT_AVA_TYPE } from '@/constants/availabilityDefaultTypes';
import { COMPANY_MANAGE_AVAILABILITIES_HIDE_DELETE } from '@/constants/Restrictions.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import AddAvaTypeModal from '../AddAvaTypeModal/AddAvaTypeModal.redux';
import AvaTypeHeader from '../AvaTypeHeader.jsx';
import AvaTypeRow from '../AvaTypeRow.jsx';
import AvaTypeRowTitle from '../AvaTypeRowTitle.jsx';
import { defaultAvaTypeIds, defaultAvaTypes, messages, sortedAvaByType } from './AvaTypesTable.helpers';

import './avaTypesTable.scss';

class AvaTypesTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.compareKeys = ['compareName', 'requires_time', 'requires_approval', 'optional_comment', 'type'];
    bindPrototypeFunctions(this);
  }

  filterAndSort(fraze) {
    const data = [];
    const defaultAvaTypesWithNames = defaultAvaTypes.map(avaType => ({
      ...avaType,
      name: this.context.intl.formatMessage(avaType.name),
    }));
    const allTypes = [...defaultAvaTypesWithNames, ...this.props.userCustomTypes];
    for (const ava of allTypes) {
      const compareName = ava.name.toLowerCase();
      if (compareName.includes(fraze.toLowerCase())) data.push({ ...ava, compareName });
    }
    const column = this.props.avaTypesListUi.sortColumn;
    const direction = this.props.avaTypesListUi.sortDecreesing ? -1 : 1;

    if (column >= 0) {
      const sortedDefaultAvaTypes = sortedAvaByType(data, DEFAULT_AVA_TYPE, this.compareKeys[column], direction);
      const sortedCustomAvaTypes = sortedAvaByType(data, CUSTOM_AVA_TYPE, this.compareKeys[column], direction);
      return [...sortedDefaultAvaTypes, ...sortedCustomAvaTypes];
    }
    return data;
  }

  render() {
    const rawData = this.filterAndSort(this.props.searchWord);
    const visibleAvailablitiesIds = rawData.map(a => a.id);
    const tableHeight = this.props.deviceInfo.windowSize.height - 300;
    const isRawDataEmpty = isEmpty(rawData);
    const leftColumnClassName = classnames({ hide: isRawDataEmpty });

    return (
      <div>
        <AddAvaTypeModal />
        <div className="add_blue">
          <Button
            onClick={() => {
              this.props.toggleModal('avaTypes');
            }}
            modifiers="blue medium inline"
          >
            <FormattedMessage id="companyManage.avaTypesList.addAvaType" defaultMessage="Dodaj dostępność" />
          </Button>
        </div>
        <FeatureWrapper restriction={COMPANY_MANAGE_AVAILABILITIES_HIDE_DELETE}>
          <div className="delete_selected">
            <Button
              onClick={this.props.massDelete}
              modifiers="reverse-red medium inline"
              disabled={!this.props.avaTypesListUi.selected.length}
            >
              <i className="material-icons">delete</i>
              <FormattedMessage id="companyManage.lists.removeSelected" defaultMessage="Usuń zaznaczone" />
            </Button>
          </div>
        </FeatureWrapper>
        <Grid width={800} height={tableHeight} columnStyle={{ width: '25vw', minWidth: '350px' }} static>
          <LeftHeader>
            <>
              <Checkbox
                className="k-avaTypeHeader__checkbox"
                checked={
                  visibleAvailablitiesIds.length !== 0 &&
                  this.props.avaTypesListUi.selected.length === visibleAvailablitiesIds.length - defaultAvaTypes.length
                }
                toggle={() => {
                  const filteredIds = visibleAvailablitiesIds.filter(id => !defaultAvaTypeIds.includes(id));
                  this.props.toggleAllCheckboxes(filteredIds, 'avaTypes');
                }}
              />
              <th
                className="tab_title tab_title--unsortedSpace k-avaTypeHeader__headerCell k-avaTypeHeader__headerCell--sortable"
                onClick={() => {
                  this.props.changeSorting(0, 'avaTypes');
                }}
              >
                <span className="noselect" role="none">
                  <FormattedMessage id="companyManage.avaTypesList.avaTypes" defaultMessage="Typy dostępności" />
                </span>
                <SortingArrow
                  display={this.props.avaTypesListUi.sortColumn === 0}
                  up={!this.props.avaTypesListUi.sortDecreesing}
                />
              </th>
            </>
          </LeftHeader>
          <Header>
            <AvaTypeHeader sortFunc={this.props.changeSorting} uiData={this.props.avaTypesListUi} />
          </Header>
          {isRawDataEmpty && (
            <div className="k-avaTypesTable__emptyState">
              <EmptyState
                emptyStateHeader={this.context.intl.formatMessage(messages.emptyStateFilterTitle)}
                emptyStateText={this.context.intl.formatMessage(messages.emptyStateFilterMsg)}
                ctaHandler={this.props.clearSearchWord}
                ctaText={this.context.intl.formatMessage(messages.ctaFilterText)}
              />
            </div>
          )}

          <LeftColumn className={leftColumnClassName}>
            {rawData.map(availability => (
              <AvaTypeRowTitle
                key={availability.id}
                data={availability}
                toggle={() => {
                  this.props.toggleCheckbox(availability.id, 'avaTypes');
                }}
                checked={this.props.avaTypesListUi.selected.includes(availability.id)}
                disabled={defaultAvaTypeIds.includes(availability.id)}
              />
            ))}
          </LeftColumn>

          <Rows className="table_company">
            {rawData.map(availability => (
              <AvaTypeRow
                key={availability.id}
                data={availability}
                edit={() => {
                  this.props.startEdit(availability?.id, 'avaTypes');
                }}
                delete={() => {
                  this.props.deleteAva(availability?.id, 'avaTypes');
                }}
                userPermissions={this.props.userPermissions}
              />
            ))}
          </Rows>
        </Grid>

        <ShownCounter
          visible={visibleAvailablitiesIds.length}
          total={this.props.userCustomTypes.length + defaultAvaTypes.length}
        />
      </div>
    );
  }
}
AvaTypesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AvaTypesTable.propTypes = {
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  avaTypesListUi: PropTypes.shape({
    showModal: PropTypes.bool,
    editMode: PropTypes.bool,
    editedId: PropTypes.string,
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  userPermissions: PropTypes.shape({}),
  toggleModal: PropTypes.func.isRequired,
  searchWord: PropTypes.string,
  toggleCheckbox: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  massDelete: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  deleteAva: PropTypes.func.isRequired,
  clearSearchWord: PropTypes.func,
};
export default AvaTypesTable;
