import type { Moment } from 'moment';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { endOfMonth, isAfterTimestamp, isBeforeTimestamp, startOfMonth } from '@/utils/dateHelper';

const messages = defineMessages({
  customEmploymentCondition: {
    id: 'scheduleTableRowTitle.popover.employmentConditionCustom',
    defaultMessage: 'Własny warunek',
  },
});

export const divideEmploymentConditionsIntoTypes = (employmentConditions, employmentConditionsIds) =>
  employmentConditions.reduce(
    (conditions, condition) =>
      employmentConditionsIds.includes(condition.id)
        ? {
            default: [...conditions.default, condition],
            custom: conditions.custom,
          }
        : {
            custom: [...conditions.custom, condition],
            default: conditions.default,
          },
    {
      custom: [],
      default: [],
    },
  );

export const workingHoursToMinutes = (workingHours) => {
  const [hours, minutes] = workingHours.split(':');
  return hours * 60 + parseInt(minutes);
};

export const workingMinutesToHours = (workingMinutes) => {
  const hours = Math.floor(workingMinutes / 60);
  const minutes = workingMinutes % 60;
  const formattedMinutes = moment.utc().minutes(minutes).format('mm');
  if (hours.toString().length === 1) {
    return `0${hours}:${formattedMinutes}`;
  }
  return `${hours}:${formattedMinutes}`;
};

export const getHiringDate = (scheduleCycle) => {
  const { year } = scheduleCycle;
  const month = scheduleCycle.month - 1;
  return moment({ year, month }).format('YYYY-MM-DD');
};

export const getEmploymentConditionName = (employmentConditions, employmentConditionList) => {
  if (employmentConditions.template_id && employmentConditions.template_id === 'custom') {
    return messages.customEmploymentCondition.defaultMessage;
  }
  return employmentConditionList.find((con) => con.id === employmentConditions.template_id)?.name || '-';
};

const areDatesEqual = (firstDate: string, secondDate: string): boolean =>
  moment(firstDate).format('YYYY-MM-DD') === moment(secondDate).format('YYYY-MM-DD');

const getFromToForBusinessDays = (firstDate: string, secondDate: string) => {
  const result = {
    from: moment(firstDate),
    to: moment(secondDate),
    areDatesEqual: areDatesEqual(firstDate, secondDate),
  };
  if (result.areDatesEqual) return result;

  const shouldSwitchDates = result.to.isBefore(result.from);
  if (shouldSwitchDates) {
    result.to = moment(firstDate);
    result.from = moment(secondDate);
  }
  return result;
};

const adjustFromDate = (date: Moment): void => {
  const isSaturday = date.day() === 6;
  const isSunday = date.day() === 0;
  if (isSaturday) {
    // Move date to next week monday
    date.day(8);
  } else if (isSunday) {
    // Move date to current week monday
    date.day(1);
  }
};

const adjustToDate = (date: Moment): void => {
  const isSaturday = date.day() === 6;
  const isSunday = date.day() === 0;
  if (isSaturday) {
    // Move date to current week friday
    date.day(5);
  } else if (isSunday) {
    // Move date to previous week friday
    date.day(-2);
  }
};

export const calculateBusinessDays = (firstDate, secondDate) => {
  const { from, to, areDatesEqual } = getFromToForBusinessDays(firstDate, secondDate);
  if (areDatesEqual) return 0;
  let adjust = 0;
  adjustFromDate(from);
  adjustToDate(to);

  const fromWeek = from.week();
  let toWeek = to.week();

  const areWeeksDifferent = fromWeek !== toWeek;
  if (areWeeksDifferent) {
    // Check if second date's year is different from first date's year
    if (toWeek < fromWeek) {
      toWeek += moment(to).subtract(1, 'year').weeksInYear();
    }
    // Calculate adjust value to be substracted from difference between two dates
    adjust = -2 * (toWeek - fromWeek);
  }

  return to.diff(from, 'days') + adjust + 1;
};

export const calculateTotalWorkingDays = (startDate, endDate, holidays) => {
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).startOf('day');
  const startStr = start.format('YYYY-MM-DD');
  const endStr = end.format('YYYY-MM-DD');

  const workingDays = calculateBusinessDays(startStr, endStr);
  const relevantHolidays = holidays.filter(
    (h) => h.day !== 7 && h.freeFromWork && h.date >= startStr && h.date <= endStr,
  );
  const totalWorkingDays = workingDays - relevantHolidays.length;
  return totalWorkingDays;
};

export const getNormForDateRange = (startDate, endDate, holidays) => {
  const totalWorkingDays = calculateTotalWorkingDays(startDate, endDate, holidays);
  return totalWorkingDays * 8;
};

export const calculateWorkingDaysForEmployee = (employeeEmploymentConditions, startDate, endDate, holidays) => {
  const { hire_date: hireDate, release_date: releaseDate } = employeeEmploymentConditions;
  const from = hireDate > startDate ? hireDate : startDate;
  const to = releaseDate && releaseDate < endDate ? releaseDate : endDate;
  return calculateTotalWorkingDays(from, to, holidays);
};

export const calculateNormForEmployeeHiredOrReleasedInMonth = (employmentConditions, date, holidays) => {
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  const { hire_date: hireDate, release_date: releaseDate } = employmentConditions || {};
  const isHireDateAfterPeriod = hireDate && isAfterTimestamp(hireDate, endDate, 'day');
  const isReleaseDateBeforePeriod = releaseDate && isBeforeTimestamp(releaseDate, startDate, 'day');
  if (isHireDateAfterPeriod || isReleaseDateBeforePeriod) {
    return 0;
  }
  const isHiredInPeriod =
    hireDate && isAfterTimestamp(hireDate, startDate, 'day') && isBeforeTimestamp(hireDate, endDate, 'day');
  const isReleasedInPeriod =
    releaseDate && isAfterTimestamp(releaseDate, startDate, 'day') && isBeforeTimestamp(releaseDate, endDate, 'day');
  if (!isHiredInPeriod && !isReleasedInPeriod) return null;
  const from = isHiredInPeriod ? hireDate : startDate;
  const to = isReleasedInPeriod ? releaseDate : endDate;
  return getNormForDateRange(from, to, holidays);
};
