import { PropTypes } from 'prop-types';

import Panel from './Panel.jsx';

const WidgetPanel = props => (
  <Panel modifiers={['nopad', ...(props.modifiers || [])]}>
    <div className="panel__sectionContainer">
      {props.items.map((item, i) => (
        <div className="panel__section" key={i}>
          {item}
        </div>
      ))}
    </div>
  </Panel>
);
WidgetPanel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  modifiers: PropTypes.arrayOf(PropTypes.string),
};
export default WidgetPanel;
