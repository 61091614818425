/* eslint-disable max-len */
import PropTypes from 'prop-types';

import { sortByKey } from '@/utils/arrayHelpers.js';

import EmployeePropListLocations from '../../EmployeeModal/EmployeeModalLocations/EmployeeModalPropList/EmployeePropListLocations.redux.js';

const EmplyeesEditMassLocationField = ({ locations, selectedLocations, onChange }) => {
  const mappedLocations = locations.map(location => ({
    value: location.id,
    label: location.name,
    active: selectedLocations.some(l => location.id === l.id),
  }));
  const sortedLocations = sortByKey([...mappedLocations], 'label', true);

  const sortedSelectedLocations = sortByKey([...selectedLocations], 'name', true);

  const handleAddLocation = (item, options = { all: false }) => {
    if (options.all) {
      onChange([...locations]);
    } else {
      const newLocation = locations.find(loc => loc.id === item.value);
      onChange([...selectedLocations, newLocation]);
    }
  };

  const handleDeleteLocation = (deletedId, options = { all: false }) => {
    const newLocations = options.all ? [] : selectedLocations.filter(loc => loc.id !== deletedId);
    onChange(newLocations);
  };

  const handleToggleLocation = item => {
    if (!item.active) {
      handleAddLocation(item);
    } else {
      handleDeleteLocation(item.value);
    }
  };

  return (
    <div className="employeeModal__propList">
      <EmployeePropListLocations
        isManager={false}
        locations={sortedLocations}
        selectedLocations={sortedSelectedLocations}
        showSupplementaryLocations={false}
        supplementaryLocations={[]}
        addLocation={handleAddLocation}
        deleteLocation={handleDeleteLocation}
        onChangeLocation={handleToggleLocation}
      />
    </div>
  );
};

EmplyeesEditMassLocationField.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

export default EmplyeesEditMassLocationField;
