import cn from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements.js';
import { getSizeOfDates } from '@/utils/shiftHelpers';
import { getEmployeeName, isEmployeeInactive } from '@/utils/userEmployeesHelpers.js';

import TradeShiftShift from '../TradeShiftShift/TradeShiftShift.jsx';
import TradeShiftRowTitle from './TradeShiftRowTitle/TradeShiftRowTitle.redux';

import './ScheduleTableTradeShift.scss';

const ScheduleTableTradeShift = ({
  mainDateStore,
  currentUser,
  userEmployees,
  tradeShifts,
  userPermissions,
  openCoworkerTradeShiftModal,
  openApprovalTradeShiftModal,
  locationId,
  rowSticky,
  selectedJobTitles,
  isEmployeeView,
  rowSelected,
}) => {
  const tradeShiftRef = useRef(null);
  const [top] = useMultipleStickyElements(tradeShiftRef, 'scheduleTableHeader--sticky');
  const tradeShiftsWithEmployeeData = tradeShifts.map(tradeShift => ({
    ...tradeShift,
    user: userEmployees.find(u => u.id === tradeShift.user.id) || tradeShift.user,
  }));

  const handleAction = shift => {
    if (userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE)) return;
    if (shift.user.id !== currentUser.user.id && userPermissions.isEmployee) {
      openCoworkerTradeShiftModal(shift);
    } else {
      openApprovalTradeShiftModal(shift);
    }
  };

  const size = getSizeOfDates(mainDateStore.dateArray.length);

  const className = cn('scheduleTable__tradeShift', 'scheduleTableHeader', {
    'scheduleTableHeader--sticky': rowSticky,
    'scheduleTable__tradeShift--selected': rowSelected,
  });
  const selectedJobTitlesIds = selectedJobTitles.map(({ id }) => id);

  const getTradeShiftsForDate = useCallback(
    date =>
      tradeShiftsWithEmployeeData
        .filter(
          shift =>
            shift.date === date &&
            shift.status !== 'rejected' &&
            !isEmployeeInactive(shift.user.id, userEmployees) &&
            (isEmployeeView || selectedJobTitlesIds.includes(shift.job_title.id)),
        )
        .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1)),
    [tradeShiftsWithEmployeeData, userEmployees, isEmployeeView, selectedJobTitlesIds],
  );

  return (
    <tr
      ref={tradeShiftRef}
      className={className}
      style={{
        top,
      }}
    >
      <TradeShiftRowTitle locationId={locationId} />
      {mainDateStore.dateArray.map((date, index) => {
        const tradeShiftsForDate = getTradeShiftsForDate(date);

        return (
          <td key={index}>
            {tradeShiftsForDate.map(shift => (
              <TradeShiftShift
                key={shift.id}
                shift={shift}
                size={size}
                userEmployees={userEmployees}
                date={date}
                title={getEmployeeName(shift.user, mainDateStore.dateMode)}
                currentUserId={currentUser.user.id}
                handleAction={() => handleAction(shift)}
                isEmployee={userPermissions.isEmployee}
              />
            ))}
          </td>
        );
      })}
    </tr>
  );
};

ScheduleTableTradeShift.contextTypes = {
  intl: PropTypes.shape({}),
};

ScheduleTableTradeShift.propTypes = {
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    today: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  tradeShifts: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  isEmployeeView: PropTypes.bool,
  rowSelected: PropTypes.bool,
};

export default ScheduleTableTradeShift;
