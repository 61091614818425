import { connect } from 'react-redux';

import { createConversation } from '@/actions/chat/chat';
import { hideModal } from '@/actions/uiState.js';
import { NEW_CHAT_MODAL } from '@/constants/modalTypes';

import NewChatModal from './NewChatModal';

const mapStateToProps = state => ({
  chatUsers: state.reducer.chat.users,
  showModal: state.reducer.uiState.showModal === NEW_CHAT_MODAL,
});

const mapDispatchToProps = {
  createConversation,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChatModal);
