import { PropTypes } from 'prop-types';

const SmallNumberWidget = props => {
  const { title, subtitle, number, color } = props;
  return (
    <div>
      <div className="widget__title">{title}</div>
      <div className="widget__subtitle">{subtitle}</div>
      <div className={`widget__bigValue widget__bigValue--${color}`}>{number}</div>
    </div>
  );
};
SmallNumberWidget.propTypes = {
  title: PropTypes.element,
  subtitle: PropTypes.element,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
export default SmallNumberWidget;
