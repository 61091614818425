import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HelpModal from '@/containers/HelpModalContainer.js';

const subpageTips = {
  '/settings/user-data': [],
  '/settings/options': [
    <tr key="options">
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="help.settings.attendanceAssuranceMode" defaultMessage="Typ potwierdzenia obecności" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.settings.attendanceAssuranceModeDesc"
          defaultMessage="Wybierz sposób potwierdzenia obecnośc przez pracownika:"
        />
        <br />
        <FormattedMessage
          id="help.settings.attendanceAssuranceModeDesc.pin"
          defaultMessage="- Pin - pracownik musi podać swój unikatowy numer pin"
        />
        <br />
        <FormattedMessage
          id="help.settings.attendanceAssuranceModeDesc.photo"
          defaultMessage="- Zdjęcie - system wykona zdjęcie pracownika"
        />
        <br />
        <FormattedMessage
          id="help.settings.attendanceAssuranceModeDesc.qr"
          defaultMessage="- Kod QR - pracownik musi pokazać swój kod QR"
        />
        <br />
        <FormattedMessage
          id="help.settings.attendanceAssuranceModeDesc.beacon"
          defaultMessage="- Beacon - pracownik musi mieć przy sobie nadajnik"
        />
      </td>
    </tr>,
  ],
  '/settings/password': [],
  '/settings/import': [],
  '/settings/history': [],
};

const SettingsHelpModal = props => {
  const showInstructions = subpageTips[props.path] ? subpageTips[props.path].length > 0 : false;
  return (
    <HelpModal title={<FormattedMessage id="common.settings" defaultMessage="Ustawienia" />}>
      <tr>
        <td colSpan="2" className="helpModal__tabTitle">
          <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
        </td>
      </tr>
      <tr>
        <td>
          <strong>
            <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
            <br />
            <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
          </strong>
        </td>
        <td>
          <FormattedMessage
            id="help.zoomDescription"
            defaultMessage='Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
          />
        </td>
      </tr>
      {showInstructions ? (
        <tr>
          <td colSpan="2" className="helpModal__tabTitle">
            <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
          </td>
        </tr>
      ) : null}
      {subpageTips[props.path]}
    </HelpModal>
  );
};

SettingsHelpModal.propTypes = {
  path: PropTypes.string,
};

export default SettingsHelpModal;
