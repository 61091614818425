import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ProgressBar from '@/components/common/ProgressBar/ProgressBar';
import { getMilestones } from '@/utils/companyHelpers';

import messages from './startSteps.messages';
import Step from './Step';
import { trialMilestones } from './trialMilestones.constants';

import './StartSteps.scss';

const StartSteps = ({ companyMilestones, companyPurpose, showModal }) => {
  const [completedSteps, setCompletedSteps] = useState([]);

  const milestones = useMemo(
    () =>
      getMilestones(companyPurpose).map(name => ({
        name,
        ...trialMilestones[name],
      })),
    [companyPurpose],
  );
  const totalSteps = milestones.length;

  useEffect(() => {
    const completed = milestones.filter(mil => companyMilestones.includes(mil.name)).map(milestone => milestone.name);
    setCompletedSteps(completed);
  }, [companyMilestones]);
  return (
    <div className="md-dashboardContainer startSteps">
      <div className="md-dashboardContainer__title md-dashboardContainer__title--large">
        <FormattedMessage {...messages.title} />
      </div>
      <div className="md-dashboardContainer__sub-title">
        <FormattedMessage {...messages.subtitle} />
      </div>

      <div className="startSteps__content">
        <div className="startSteps__progress-container">
          <span className="startSteps__current-step">
            <FormattedMessage
              {...messages.steps}
              values={{
                current: completedSteps.length,
                total: totalSteps,
              }}
            />
          </span>
          <ProgressBar className="startSteps__progress-bar" max={totalSteps} value={completedSteps.length} />
        </div>

        <div className="startSteps__steps-container">
          {milestones.map(step => {
            const isCompleted = completedSteps.includes(step.name);

            return (
              <Step {...step} key={step.name} isCompleted={isCompleted} isNextStep={false} showModal={showModal} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

StartSteps.propTypes = {
  companyPurpose: PropTypes.arrayOf(PropTypes.string),
  companyMilestones: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.func,
};

export default StartSteps;
