import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'tradeShift.requestModal.title',
    defaultMessage: 'Stwórz prośbę wymiany',
  },
  confirmText: {
    id: 'tradeShift.requestModal.confirmText',
    defaultMessage: 'Stwórz',
  },
  shiftStarted: {
    id: 'tradeShift.requestModal.shiftStarted',
    defaultMessage: 'Nie możesz stworzyć wymiany, ponieważ ta zmiana już się rozpoczęła',
  },
  shiftRejected: {
    id: 'tradeShift.requestModal.shiftRejected',
    defaultMessage: 'Nie możesz stworzyć wymiany, ponieważ wymiana dla tej zmiany została już odrzucona',
  },
  shiftCreated: {
    id: 'tradeShift.requestModal.shiftCreated',
    defaultMessage: 'Nie możesz stworzyć wymiany, ponieważ wymiana dla tej zmiany została już stworzona',
  },
  notePlaceholder: {
    id: 'tradeShift.requestModal.noteText',
    defaultMessage: 'Treść notatki',
  },
  noteLabel: {
    id: 'tradeShift.requestModal.note',
    defaultMessage: 'Notatka',
  },
  employeesSelectLabel: {
    id: 'tradeShift.requestModal.employees',
    defaultMessage: 'Pracownicy',
  },
  employeesDeselectAll: {
    id: 'tradeShift.requestModal.deselectAllButton',
    defaultMessage: 'Żaden',
  },
  employeesSelectAll: {
    id: 'tradeShift.requestModal.selectAllButton',
    defaultMessage: 'Wszyscy',
  },
  chooseEmployees: {
    id: 'tradeShift.requestModal.chooseEmployees',
    defaultMessage: 'Wybierz pracowników',
  },
});
