import classnames from 'classnames';

interface Props {
  tab: {
    id: string;
    label: string;
    numberOfItems: number;
    color: string;
    lightColor: string;
    onSelect: () => void;
  };
  isActive: boolean;
}

const MDTableTab = ({ tab, isActive }: Props) => (
  <div
    key={tab.id}
    className={classnames('mdTable__tab', {
      'mdTable__tab--active': isActive,
    })}
    onClick={() => {
      if (tab.onSelect && !isActive) {
        tab.onSelect();
      }
    }}
    role="presentation"
  >
    <span className="mdTable__tabNumber" style={{ backgroundColor: tab.lightColor, color: tab.color }}>
      {tab.numberOfItems}
    </span>
    <span className="mdTable__tabLabel">{tab.label}</span>
  </div>
);

export default MDTableTab;
