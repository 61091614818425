import moment from 'moment';

import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';

export const mapDataForTable = rawData =>
  Object.entries(rawData).map(([date, dataItem]) => ({
    date: moment(date).format('dddd, DD.MM.YYYY'),
    workedHours: parseMinutesToHumanForm(dataItem.worked_minutes, { roundToWholeMinutes: true }),
    plannedHours: parseMinutesToHumanForm(dataItem.planned_minutes, { roundToWholeMinutes: true }),
    numberOfEmployeesWorked: dataItem.number_of_employees_worked,
    numberOfEmployeesPlanned: dataItem.number_of_employees_planned,
    averageHoursPerEmployeeWorked: parseMinutesToHumanForm(dataItem.average_minutes_per_employee_worked, {
      roundToWholeMinutes: true,
    }),
    averageHoursPerEmployeeWPlanned: parseMinutesToHumanForm(dataItem.average_minutes_per_employee_planned, {
      roundToWholeMinutes: true,
    }),
  }));
