import { conn } from '@/actions/index';
import { ADD_SHIFT_AND_ABSENCE_MODAL, ADD_SHIFT_MODAL, OBJECT_HISTORY_MODAL } from '@/constants/modalTypes.js';

import { showModal } from './uiState.js';

export const getShiftHistory = (shift, requestType) => async dispatch => {
  try {
    const response = await conn.getObjectsActivityLog(shift.id, 'shift', requestType);
    dispatch(
      showModal(OBJECT_HISTORY_MODAL, {
        history: response.data,
        item: shift,
        previousModalName: ADD_SHIFT_AND_ABSENCE_MODAL,
      }),
    );
  } catch (err) {
    console.error(err);
  }
};

export const getHistoryForShift = (shift, requestType) => dispatch =>
  conn.getObjectsActivityLog(shift.id, 'shift', requestType).then(response => {
    dispatch(
      showModal(OBJECT_HISTORY_MODAL, {
        history: response.data,
        item: shift,
        previousModalName: ADD_SHIFT_MODAL,
      }),
    );
  });

export const getHistoryForAttendance = (attendance, requestType) => dispatch =>
  conn.getObjectsActivityLog(attendance.id, 'attendance', requestType).then(response => {
    dispatch(
      showModal(OBJECT_HISTORY_MODAL, {
        history: response.data,
        item: attendance,
      }),
    );
  });
