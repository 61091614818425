import { getRelevantContractForDate } from '@/utils/contracts';

const getRelevantJobTitleIdsForOpenShifts = (selectedJobTitles, currentUserContracts, isEmployee, date) => {
  if (isEmployee) {
    const relevantContract = getRelevantContractForDate(currentUserContracts || [], date);
    return relevantContract?.job_titles.map(jobTitle => jobTitle.job_title_id);
  }
  return selectedJobTitles.map(({ id }) => id);
};

export const getRelevantOpenShiftIds = (
  selectedJobTitles,
  filteredOpenShiftIds,
  openShiftData,
  currentUserContracts,
  isEmployee,
  date,
) => {
  if (!filteredOpenShiftIds.length) return [];
  const relevantJobTitleIds = getRelevantJobTitleIdsForOpenShifts(
    selectedJobTitles,
    currentUserContracts,
    isEmployee,
    date,
  );
  return filteredOpenShiftIds.filter(openShiftId =>
    relevantJobTitleIds.includes(openShiftData[openShiftId].job_title.id),
  );
};
