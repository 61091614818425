import { connect } from 'react-redux';

import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { toggleExportModal } from '@/actions/uiState.js';
import ReportView from '@/components/ReportView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  userCustomTypes: state.reducer.userCustomTypes,
  userJobTitles: state.reducer.userJobTitles,
  userPermissions: state.reducer.userPermissions,
  mainDateStore: state.reducer.mainDateStore,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  demo: state.reducer.demo,
  payrollSettings: state.reducer.payrollSettings,
  selectedJobtitles: state.reducer.jobtitleFilter.selectedJobtitles,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  recommendedSchedule: state.reducer.recommendedSchedule.recommendedSchedule,
  payrollLocationSummary: state.reducer.payrollLocation.summary,
  contracts: state.reducer.contracts,
  attendancesData: state.reducer.attendances.attendancesData.attendances,
});

const mapDispatchToProps = {
  toggleExportModal,
  mainDateMoveLeft,
  mainDateMoveRight,
};

const ReportViewContainer = connect(mapStateToProps, mapDispatchToProps)(ReportView);

export default ReportViewContainer;
