import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { NARROW } from '@/constants/modalModifiers';
import { useMultiSelect, useStepsCounter } from '@/hooks';
import { User } from '@/types/currentUser.types.ts';
import { SelectedJobTitles } from '@/types/jobTitles.types.ts';
import { CreatedProposalData } from '@/types/loanEmployeesProposals.ts';
import { Location } from '@/types/location.types.ts';
import { GroupLocationEmployee } from '@/types/locationGroup.ts';
import { inputValidation } from '@/utils/inputValidation.js';

import { messages } from '../incomingLoanEmployeesProposalModal/incomingLoanEmployeesProposalModal/incomingLoanEmployeesProposalModal.messages.ts';
import {
  displayRelevantSteps,
  ERRORS_INITIAL_STATE,
  formatNewProposalData,
  getEmployeesOptions,
  getInitialState,
} from './OutgoingLoanEmployeesProposalModal.helpers.tsx';

const INITIAL_STEPS_COUNT = 3;

interface OutgoingLoanEmployeesProposalModalProps {
  modalObject: { locationId: string; date: string };
  hideModalAndLaterClearModalObject: () => void;
  selectedJobTitles: SelectedJobTitles[];
  getByLocationGroupAndJobTitle: (
    locationId: string,
    jobTitleId: string,
    date: string,
  ) => Promise<GroupLocationEmployee[]>;
  createLoanEmployeesProposal: (payload: CreatedProposalData) => void;
  userLocations: Location[];
  currentUser: User;
}

const OutgoingLoanEmployeesProposalModal = (
  {
    modalObject,
    hideModalAndLaterClearModalObject,
    selectedJobTitles,
    getByLocationGroupAndJobTitle,
    createLoanEmployeesProposal,
    userLocations,
    currentUser,
  }: OutgoingLoanEmployeesProposalModalProps,
  { intl },
) => {
  const { currentStep, stepsCount, isLastStep, handlers: stepsHandlers } = useStepsCounter(INITIAL_STEPS_COUNT);

  const [proposalData, setProposalData] = useState(
    getInitialState(selectedJobTitles, userLocations, modalObject, currentUser),
  );

  const [showLoader, setShowLoader] = useState(false);

  const [errors, setErrors] = useState(ERRORS_INITIAL_STATE);

  const [employeesItems, employeesHandlers] = useMultiSelect([]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const showModal = !isEmpty(modalObject);

  const fetchData = async () => {
    const { locationId, date } = modalObject;
    setShowLoader(true);
    const groupLocationEmployees = await getByLocationGroupAndJobTitle(
      locationId,
      proposalData.selectedJobTitleId,
      date,
    );

    const filteredGroupLocationEmployees = groupLocationEmployees.filter(
      ({ locations }) => !locations.includes(locationId),
    );

    employeesHandlers.setItems(getEmployeesOptions(filteredGroupLocationEmployees));
    setShowLoader(false);
  };

  useEffect(() => {
    if (!showModal) return;

    stepsHandlers.setInitialValues();

    setProposalData(getInitialState(selectedJobTitles, userLocations, modalObject, currentUser));
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      setIsSubmitted(false);
      return;
    }

    if (currentStep === 1) employeesHandlers.setItems([]);

    if (currentStep === 2 && !employeesItems.length) {
      fetchData();
    }
  }, [showModal, currentStep, setIsSubmitted, isSubmitted]);

  useEffect(() => {
    if (!showModal) return;

    const numberOfSelectedEmployees = employeesItems.filter(({ active }) => active).length;

    if (proposalData.employees_required <= numberOfSelectedEmployees || currentStep !== 2) {
      setErrors(prev => ({ ...prev, declaredSelectedEmployees: '' }));
    }
  }, [currentStep, employeesItems, setErrors]);

  const changeJobTitle = useCallback(
    (jobTitleId: string) => {
      const jobTitle = selectedJobTitles.find(({ id }) => id === jobTitleId).title;
      setProposalData(prev => ({
        ...prev,
        selectedJobTitleId: jobTitleId,
        jobTitle,
      }));
    },
    [selectedJobTitles, setProposalData],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setProposalData(prev => ({
      ...prev,
      [id]: id === 'employees_required' && value !== '0' ? Number(value) : value,
    }));

    if (id === 'comment') {
      const error = inputValidation('comment', value);
      setErrors(prev => ({
        ...prev,
        comment: isEmpty(error) ? '' : intl.formatMessage(error),
      }));
    }

    if (id === 'employees_required' && Boolean(errors.employeesNumber))
      setErrors(prev => ({ ...prev, employeesNumber: '' }));
  };

  const changeWorkingHours = useCallback(
    (workingHours: string) => {
      setProposalData(prev => ({ ...prev, working_hours: workingHours }));
      setErrors(prev => ({ ...prev, workingHours: '' }));
    },
    [setProposalData, setErrors],
  );

  const clearAndHide = useCallback(() => {
    hideModalAndLaterClearModalObject();
    setErrors(ERRORS_INITIAL_STATE);
  }, [
    hideModalAndLaterClearModalObject,
    selectedJobTitles,
    userLocations,
    modalObject,
    currentUser,
    employeesItems,
    showModal,
  ]);

  const onCancel = useCallback(() => {
    stepsHandlers.decreaseStep();
    if (currentStep === 1) clearAndHide();
  }, [stepsHandlers, currentStep, clearAndHide]);

  const createProposal = useCallback(() => {
    const { locationId, date } = modalObject;
    const employeeItemsIds = employeesItems.filter(({ active }) => active).map(({ value }) => value);
    const payload = formatNewProposalData(proposalData, locationId, date, employeeItemsIds);

    createLoanEmployeesProposal(payload);
    clearAndHide();
  }, [
    modalObject,
    employeesItems,
    proposalData,
    createLoanEmployeesProposal,
    clearAndHide,
    employeesItems,
    setIsSubmitted,
    isSubmitted,
  ]);

  const onSubmit = useCallback(() => {
    if (currentStep === 1) {
      const workingHoursError = inputValidation('workingHours', proposalData.working_hours);
      if (!isEmpty(workingHoursError)) {
        setErrors(prev => ({ ...prev, workingHours: intl.formatMessage(workingHoursError) }));
        return;
      }

      const employeesNumberError = inputValidation('employeesNumber', proposalData.employees_required);
      if (!isEmpty(employeesNumberError)) {
        setErrors(prev => ({ ...prev, employeesNumber: intl.formatMessage(employeesNumberError) }));
        return;
      }
      if (errors.comment) return;
    }

    if (currentStep === 2) {
      const numberOfSelectedEmployees = employeesItems.filter(({ active }) => active).length;
      const error = inputValidation('declaredSelectedEmployees', proposalData.employees_required, {
        valueToCompare: numberOfSelectedEmployees,
      });

      if (!isEmpty(error)) {
        setErrors(prev => ({ ...prev, declaredSelectedEmployees: intl.formatMessage(error) }));
        return;
      }
    }
    stepsHandlers.increaseStep();
    if (isLastStep && !isSubmitted) {
      setIsSubmitted(true);
      createProposal();
    }
  }, [
    currentStep,
    employeesItems,
    proposalData,
    inputValidation,
    isLastStep,
    isSubmitted,
    setIsSubmitted,
    createProposal,
  ]);

  const confirmText = intl.formatMessage(messages[isLastStep ? 'approved' : 'next']);
  const cancelText = intl.formatMessage(messages[currentStep === 1 ? 'cancel' : 'back']);

  const multiSelect = {
    employeesItems,
    employeesHandlers,
  };

  const handlers = {
    changeJobTitle,
    handleInputChange,
    changeWorkingHours,
  };
  const errorMessage =
    errors.declaredSelectedEmployees || errors.workingHours || errors.employeesNumber || errors.comment;

  return (
    <MDKadroModal
      title={intl.formatMessage(messages.title)}
      show={showModal}
      showProgressBar
      currentStep={currentStep}
      stepsCount={stepsCount}
      modifiers={NARROW}
      onHide={clearAndHide}
      onCancelFooter={onCancel}
      onSubmit={onSubmit}
      confirmText={confirmText}
      cancelText={cancelText}
      errorMessage={errorMessage}
    >
      <div className="k-outgoingLoanEmployeesProposalModal">
        {displayRelevantSteps(currentStep, multiSelect, proposalData, handlers, showLoader)}
      </div>
    </MDKadroModal>
  );
};

OutgoingLoanEmployeesProposalModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default OutgoingLoanEmployeesProposalModal;
