import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { KadroCalendarMultiple } from '@/components/common/KadroCalendars';

import Popover from '../../Popover/Popover.jsx';

const messages = defineMessages({
  choicePickerLabel: {
    id: 'choicePicker.label',
    defaultMessage: 'Wybierz dni',
  },
  choicePickerInput: {
    id: 'choicePicker.input',
    defaultMessage: 'Kliknij by wybrać dni',
  },
});

const ChoicePicker = ({ label, changePick, selectedChoices, maxDate, minDate, calText }, { intl }) => {
  const choicePickerLabel = label || intl.formatMessage(messages.choicePickerLabel);
  const choicePickerMinDate = minDate || undefined;
  const choicePickerMaxDate = maxDate || undefined;
  return (
    <div>
      <fieldset className="form-group">
        <label htmlFor="czas">{choicePickerLabel}</label>
        <div className="form_right" role="presentation">
          <Popover
            position="left"
            sideOffset={185}
            yOffset={15}
            content={
              <KadroCalendarMultiple
                onChange={changePick}
                selected={selectedChoices}
                maxDate={choicePickerMaxDate}
                minDate={choicePickerMinDate}
              />
            }
          >
            <div>
              <input
                style={{ cursor: 'pointer' }}
                type="text"
                className="form-control k-textInput k-textInput--disabled"
                id="od"
                placeholder={intl.formatMessage(messages.choicePickerInput)}
                value={calText}
                disabled
              />
            </div>
          </Popover>
        </div>
      </fieldset>
    </div>
  );
};

ChoicePicker.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ChoicePicker.propTypes = {
  calText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changePick: PropTypes.func,
  selectedChoices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default ChoicePicker;
