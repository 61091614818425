export const filterTemplateShifts = (templateShifts, selectedJobTitles) =>
  templateShifts.filter(shift =>
    selectedJobTitles.some(selectedJobTitle => selectedJobTitle.id === shift.job_title_id),
  );

export const formatEmploymentConditions = employmentConditions =>
  employmentConditions.map(employmentCondition => ({
    value: employmentCondition.id,
    label: employmentCondition.name,
    active: true,
  }));
