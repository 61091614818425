import Tag from '@/components/common/Tag/Tag.tsx';
import { Label } from '@/types/labels.types';

import './PayrollTableLabels.scss';

interface PayrollTableLabelsProps {
  labelsFromRow: Label[];
  numberOfMaxItems?: number;
}

const PayrollTableLabels = ({ labelsFromRow, numberOfMaxItems }: PayrollTableLabelsProps) => {
  const displayedTags = numberOfMaxItems ? labelsFromRow.slice(0, numberOfMaxItems) : labelsFromRow;

  const numberOfTagsLeft = Math.max(0, labelsFromRow.length - numberOfMaxItems);

  if (numberOfTagsLeft > 0) {
    displayedTags.push({
      id: 'more-tags',
      color: '#131313',
      label: `+${numberOfTagsLeft}`,
      company_id: '',
      type: 'attendance',
    });
  }

  return (
    <div className="k-payrollTableLabels">
      {displayedTags.map(option => (
        <Tag
          key={option.id}
          color={option.color}
          name={option.label}
          className="k-multiSelectOptionManager__tag"
          disabled
        />
      ))}
    </div>
  );
};

export default PayrollTableLabels;
