import { connect } from 'react-redux';

import { sendCustomNotification } from '@/actions/customNotifications';
import { hideModal } from '@/actions/uiState.js';
import { CUSTOM_NOTIFICATION_MODAL } from '@/constants/modalTypes.js';

import CustomNotificationsModal from './CustomNotificationsModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === CUSTOM_NOTIFICATION_MODAL,
});

const mapDispatchToProps = {
  onHide: hideModal,
  sendCustomNotification,
};

const AddEmployeeModalContainer = connect(mapStateToProps, mapDispatchToProps)(CustomNotificationsModal);

export default AddEmployeeModalContainer;
