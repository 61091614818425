import { connect } from 'react-redux';

import { toggleAllScheduleSettings, toggleScheduleSettings } from '@/actions/schedule.jsx';

import ScheduleSettingsButton from './ScheduleSettingsButton.jsx';

const mapStateToProps = state => ({
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  locationSettings: state.reducer.settings.locationSettings,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  restrictions: state.reducer.userPermissions.restrictions,
});

const mapDispatchToProps = { toggleScheduleSettings, toggleAllScheduleSettings };

const ScheduleSettingsButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleSettingsButton);

export default ScheduleSettingsButtonContainer;
