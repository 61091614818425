import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';

import './avaTypeHeader.scss';

const AvaTypeHeader = props => (
  <thead className="k-avaTypeHeader">
    <tr>
      <th
        onClick={() => {
          props.sortFunc(1, 'avaTypes');
        }}
        className="k-avaTypeHeader__headerCell k-avaTypeHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.availabilityHeader.requireHours" defaultMessage="Wymaga podania godzin" />
        <SortingArrow display={props.uiData.sortColumn === 1} up={!props.uiData.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(2, 'avaTypes');
        }}
        className="k-avaTypeHeader__headerCell k-avaTypeHeader__headerCell--sortable"
      >
        <FormattedMessage
          id="companymanage.availabilityHeader.requireConfirmation"
          defaultMessage="Wymaga potwierdzenia"
        />
        <SortingArrow display={props.uiData.sortColumn === 2} up={!props.uiData.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(3, 'avaTypes');
        }}
        className="k-avaTypeHeader__headerCell k-avaTypeHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.availabilityHeader.enableComment" defaultMessage="Wł. Komentarze" />
        <SortingArrow display={props.uiData.sortColumn === 3} up={!props.uiData.sortDecreesing} />
      </th>
      <th className="k-avaTypeHeader__headerCell">
        <FormattedMessage id="companymanage.availabilityHeader.type" defaultMessage="Typ" />
      </th>
      <th className="k-avaTypeHeader__headerCell">
        <FormattedMessage id="companymanage.availabilityHeader.options" defaultMessage="Opcje" />
      </th>
    </tr>
  </thead>
);

AvaTypeHeader.propTypes = {
  sortFunc: PropTypes.func.isRequired,
  uiData: PropTypes.shape({
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
  }),
};

export default AvaTypeHeader;
