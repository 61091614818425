import { FormattedMessage } from 'react-intl';

import { generateFromToWithShiftInt } from '@/utils/dateHelper';

import * as dateModes from './dateModes';

const getDates = (mode, shiftInt = 0) => {
  const { from, to } = generateFromToWithShiftInt(mode, shiftInt);
  return { startDate: new Date(from), endDate: new Date(to) };
};

export const calendarRangeOptions = [
  {
    ...getDates(dateModes.DATE_MODE_DAY, -1),
    label: (
      <FormattedMessage
        id="calendar.range.yesterday"
        defaultMessage="Wczoraj"
      />
    ),
    dateMode: dateModes.DATE_MODE_DAY_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_DAY),
    label: (
      <FormattedMessage id="calendar.range.today" defaultMessage="Dzisiaj" />
    ),
    dateMode: dateModes.DATE_MODE_DAY_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_DAY, 1),
    label: (
      <FormattedMessage id="calendar.range.tomorrow" defaultMessage="Jutro" />
    ),
    dateMode: dateModes.DATE_MODE_DAY_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_WEEK, -1),
    label: (
      <FormattedMessage
        id="calendar.range.lastWeek"
        defaultMessage="Poprzedni tydzień"
      />
    ),
    dateMode: dateModes.DATE_MODE_WEEK_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_WEEK),
    label: (
      <FormattedMessage
        id="calendar.range.thisWeek"
        defaultMessage="Ten tydzień"
      />
    ),
    dateMode: dateModes.DATE_MODE_WEEK_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_WEEK, 1),
    label: (
      <FormattedMessage
        id="calendar.range.nextWeek"
        defaultMessage="Następny tydzień"
      />
    ),
    dateMode: dateModes.DATE_MODE_WEEK_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_MONTH, -1),
    label: (
      <FormattedMessage
        id="calendar.range.lastMonth"
        defaultMessage="Poprzedni miesiąc"
      />
    ),
    dateMode: dateModes.DATE_MODE_MONTH_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_MONTH),
    label: (
      <FormattedMessage
        id="calendar.range.thisMonth"
        defaultMessage="Ten miesiąc"
      />
    ),
    dateMode: dateModes.DATE_MODE_MONTH_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_MONTH, 1),
    label: (
      <FormattedMessage
        id="calendar.range.nextMonth"
        defaultMessage="Następny miesiąc"
      />
    ),
    dateMode: dateModes.DATE_MODE_MONTH_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_YEAR, -1),
    label: (
      <FormattedMessage
        id="calendar.range.lastYear"
        defaultMessage="Poprzedni rok"
      />
    ),
    dateMode: dateModes.DATE_MODE_YEAR_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_YEAR),
    label: (
      <FormattedMessage id="calendar.range.thisYear" defaultMessage="Ten rok" />
    ),
    dateMode: dateModes.DATE_MODE_YEAR_ID,
  },
  {
    ...getDates(dateModes.DATE_MODE_YEAR, 1),
    label: (
      <FormattedMessage
        id="calendar.range.nextYear"
        defaultMessage="Następny rok"
      />
    ),
    dateMode: dateModes.DATE_MODE_YEAR_ID,
  },
];
