import Lottie from 'lottie-react';
import { FormattedMessage } from 'react-intl';

import autoSchedulerAnimation from '@/assets/animations/autoscheduler.json';

import './AutoGenerateLoader.scss';

const AutoGenerateLoader = () => (
  <div className="k-autoGenerateLoader">
    <div className="k-autoGenerateLoader__animation">
      <Lottie animationData={autoSchedulerAnimation} />
    </div>
    <FormattedMessage id="autoGenerate.loader.title" defaultMessage="Generujemy Twój grafik">
      {text => <h4 className="k-autoGenerateLoader__title">{text}</h4>}
    </FormattedMessage>
    <div className="k-autoGenerateLoader__description">
      <FormattedMessage
        id="autoGenerate.loader.description1"
        defaultMessage="Generowanie grafiku pracy może potrwać kilka minut."
      />
      <FormattedMessage
        id="autoGenerate.loader.description2"
        defaultMessage="Możesz zamknąć to okno i kontynuować swoją pracę w aplikacji,"
      />
      <FormattedMessage
        id="autoGenerate.loader.description3"
        defaultMessage="a generowanie grafiku będzie kontynuowane w tle."
      />
    </div>
  </div>
);

AutoGenerateLoader.propTypes = {};

export default AutoGenerateLoader;
