import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconDropdownButton from '@/components/common/iconDropdownButton/IconDropdownButton.jsx';
import { ATTENDANCE_ABSENCE, ATTENDANCE_WITHOUT_SHIFT } from '@/constants/attendanceDetailTypes.js';
import { ATTENDANCE_ABSENCE_COLOR } from '@/constants/colors.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';

import AttendanceTimeSpan from '../../AttendanceTimeSpan/AttendanceTimeSpan.jsx';
import AttendancesTableAlerts from './AttendancesTableAlerts/AttendancesTableAlerts.jsx';
import AttendancesTableOptions from './AttendancesTableOptions/AttendancesTableOptions.redux.js';
import { statusColors } from './constants.js';
import messages from './messages.js';

const AttendancesTableCell = ({ column, row, showModal, isAttendanceEditingBlocked, isShiftEditingBlocked }) => {
  const isShiftWithoutAttendance = row.type === ATTENDANCE_ABSENCE;
  switch (column.type) {
    case 'time': {
      const time = column.accessor === 'start' ? row.startTimeShift : row.endTimeShift;

      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          <span className={`attendancesTable__${column.accessor}`}>
            {!isShiftWithoutAttendance ? (
              <AttendanceTimeSpan
                type={column.accessor}
                attendance={row.attendanceObject}
                employee={row.employeeObject}
                showModal={showModal}
                isAttendanceEditingBlocked={isAttendanceEditingBlocked}
                isShiftEditingBlocked={isShiftEditingBlocked}
                showPhotoPreview={false}
                blocks={
                  !row.attendanceWithShift && [
                    {
                      startTimestamp: row.attendanceObject.start_timestamp,
                      endTimestamp: row.attendanceObject.end_timestamp,
                      type: { name: ATTENDANCE_WITHOUT_SHIFT },
                    },
                  ]
                }
              />
            ) : (
              <span style={{ color: ATTENDANCE_ABSENCE_COLOR, marginRight: '6px' }}>__:__</span>
            )}
            {row.attendanceWithShift || isShiftWithoutAttendance ? `/ ${time}` : '/ __;__'}
          </span>
        </>
      );
    }
    case 'duration':
      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          {!Number.isNaN(row[column.accessor])
            ? parseMinutesToHumanForm(row[column.accessor])
            : parseMinutesToHumanForm(0)}
        </>
      );
    case 'options':
      if (isShiftWithoutAttendance) {
        return null;
      }
      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          <IconDropdownButton rowData={row}>
            <AttendancesTableOptions disabled={isAttendanceEditingBlocked} />
          </IconDropdownButton>
        </>
      );
    case 'alerts':
      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          <AttendancesTableAlerts rowData={row} />
        </>
      );
    case 'status':
      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          <div className="attendancesTable__status">
            <div className="status-dot" style={{ backgroundColor: statusColors[row[column.accessor]] }} />
            {messages[row[column.accessor]] ? (
              <FormattedMessage {...messages[row[column.accessor]]} />
            ) : (
              row[column.accessor]
            )}
          </div>
        </>
      );
    default:
      return (
        <>
          <span className="attendancesTable__mobileLabel">{column.header}</span>
          {row[column.accessor]}
        </>
      );
  }
};

AttendancesTableCell.propTypes = {
  column: PropTypes.shape({
    header: PropTypes.string,
    accessor: PropTypes.string,
    type: PropTypes.string,
  }),
  row: PropTypes.shape({
    startTimeShift: PropTypes.string,
    endTimeShift: PropTypes.string,
    attendanceObject: PropTypes.shape({}),
    attendanceData: PropTypes.shape({
      blocks: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    employeeObject: PropTypes.shape({}),
    attendanceWithShift: PropTypes.bool,
  }),
  showModal: PropTypes.func,
  isAttendanceEditingBlocked: PropTypes.bool,
  isShiftEditingBlocked: PropTypes.bool,
};

export default AttendancesTableCell;
