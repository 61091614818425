import classNames from 'classnames';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { isEmptyArray } from '@/utils/array/array.helpers';

import { MultiAccountsInstance } from './Instance/MultiAccountsInstance';
import { MultiAccountsSetting } from './Setting/MultiAccountsSetting';
import { useMultiAccountsMenu } from './useMultiAccountsMenu';

import './MultiAccountsMenu.scss';

export const MultiAccountsMenu = memo(() => {
  const { accountsDetails, isOverflowY, profilesListRef, setDefaultAccount } = useMultiAccountsMenu();

  return (
    <div className="k-multiAccountsMenu">
      {!isEmptyArray(accountsDetails) && (
        <>
          <FormattedMessage defaultMessage="Twoje profile" id="multiAccounts.yourProfiles" />
          <div
            ref={profilesListRef}
            className={classNames('k-multiAccountsMenu__list', isOverflowY && 'k-multiAccountsMenu__list--overflowed')}
          >
            {accountsDetails.map((accountDetail) => (
              <MultiAccountsInstance key={accountDetail.userId} {...{ accountDetail, setDefaultAccount }} />
            ))}
          </div>
        </>
      )}
      <MultiAccountsSetting />
    </div>
  );
});

MultiAccountsMenu.displayName = 'MultiAccountsMenu';
