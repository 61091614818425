import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absenceAddModalHeader: {
    id: 'absenceAddModal.header',
    defaultMessage: 'Dodaj urlop',
  },
  absenceAddModalSaveButton: {
    id: 'absenceAddModal.saveButton',
    defaultMessage: 'Zapisz',
  },
  absenceAddModalCancelButton: {
    id: 'absenceAddModal.cancelButton',
    defaultMessage: 'Anuluj',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Dodaj',
  },
});

export const absenceErrorMessages = defineMessages({
  absencesOverlap: {
    id: 'error.absences.overlap',
    defaultMessage: 'Urlopy na siebie nachodzą',
  },
  limitExceeded: {
    id: 'error.absences.limitExceeded',
    defaultMessage: 'Wykorzystałeś już cały limit dostępnych nieobecności',
  },
  overtimeCollectionsExceed: {
    id: 'error.absences.overtimeCollectionsExceed',
    defaultMessage: 'Pracownik otrzyma {difference} mniej niż powinien. Wskaż poprawną liczbę nadgodzin do odebrania',
  },
  overtimeCollectionsWrong: {
    id: 'error.absences.overtimeCollectionsWrong',
    defaultMessage: 'Wybrane odbiory nadgodzin są błędne',
  },
  scheduleBlocked: {
    id: 'error.absence.scheduleBlocked',
    defaultMessage:
      'Nie można dodać nieobecności, ponieważ {singleDay, select, true {wybrana data nachodzi} other {wybrane daty nachodzą}} na zablokowany okres grafiku w {numberOfLocations, plural, one {lokalizacji {locationName}} other {# lokalizacjach}}',
  },
});
