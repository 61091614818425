import { isNotEmptyText } from '@/utils/stringHelpers';

export const getAvaBlockDisplayData = (avaBlock, isMonthView, intl) => {
  const { typeName, shortTypeName, icon, type, hours, comment } = avaBlock;
  let title = typeName.id ? intl.formatMessage(typeName) : typeName;
  if (isMonthView && shortTypeName) title = shortTypeName;
  let showHours = !!hours;
  let showIcon = !!icon;
  if (type === 'freetime') {
    title = hours;
    showHours = false;
  }
  if (isMonthView && icon) {
    showHours = false;
    title = null;
  }
  if (isMonthView && hours) {
    showHours = false;
    showIcon = false;
    const [from, to] = hours.split('-');
    title = (
      <>
        {from}
        <br />
        {to}
      </>
    );
  }
  const hasComment = isNotEmptyText(comment);
  return { title, showHours, showIcon, hasComment };
};
