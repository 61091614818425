import {
  CHANGE_SCHEDULE_MODE,
  CHANGE_SCHEDULE_SEARCH_STRING,
  CHANGE_SCHEDULE_TABLE_SIZE,
  CLEAR_IMPORT_MODAL,
  DESELECT_ALL_SCHEDULE,
  FLIP_SCHEDULE_MODE,
  GET_SCHEDULE_INIT_DATA,
  IMPORT_SCHEDULE_DATA,
  MOVE_SELECTED_COLUMNS,
  SET_USER_RESTICTIONS_SUCCESSFUL,
  TOGGLE_IMPORT_BUDGET_MODAL_ERROR,
  TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY,
  TOGGLE_IMPORT_MODAL_OVERLAY,
  TOGGLE_NON_WORKING_DAYS,
  TOGGLE_SCHEDULE_AVAILABILITIES_MODE,
  TOGGLE_SCHEDULE_COLUMN,
  TOGGLE_SCHEDULE_ROW,
  TOGGLE_SCHEDULE_SETTING,
  TURN_SCHEDULE_SETTINGS_ON,
  UPDATE_PERMISSIONS_SUCCESSFUL,
  UPLOAD_FILE_TO_IMPORT,
} from '@/constants/ActionTypes.js';
import { SCHEDULE_DISPLAY_MODE_TYPE, scheduleDisplayModes } from '@/constants/scheduleDisplayModes.js';
import { deleteIfExistsOrAdd } from '@/utils/arrayHelpers.js';
import { shiftArrayOfDates } from '@/utils/dateHelper.js';

const displayModes = scheduleDisplayModes({
  permissions: [],
  restrictions: [],
});

const initialState = {
  selectedRows: [],
  selectedColumns: [],
  displayModes,
  selectedDisplayMode: displayModes.find(mode => mode.id === 4),
  selected: false,
  selectedRowsLocationsIds: {},
  searchString: '',
  settings: [],
  nonWorkingDays: true,
  importModal: {
    isTemplateImported: false,
    importedData: [],
    uploadedFile: null,
    showOverlay: false,
  },
  size: 0,
  importBudgetModal: {
    showOverlay: false,
    showInputError: false,
  },
};

const scheduleUIState = (state = initialState, action) => {
  let tmp;
  switch (action.type) {
    case GET_SCHEDULE_INIT_DATA: {
      return { ...state, ...action.payload };
    }
    case TOGGLE_SCHEDULE_ROW: {
      const { employeeId, locationId } = action.payload;
      const tmpLocationId = state.selectedRowsLocationsIds[employeeId];
      let locationRecord = null;
      if (tmpLocationId && tmpLocationId === locationId) {
        tmp = state.selectedRows.filter(r => r !== employeeId);
      } else {
        tmp = [...state.selectedRows, employeeId];
        locationRecord = locationId;
      }
      return Object.assign({}, state, {
        selectedRows: tmp,
        selectedColumns: [],
        selected: tmp.length > 0,
        selectedRowsLocationsIds: {
          ...state.selectedRowsLocationsIds,
          [action.payload.employeeId]: locationRecord,
        },
      });
    }
    case TOGGLE_SCHEDULE_COLUMN:
      tmp = deleteIfExistsOrAdd(state.selectedColumns, action.payload);
      return Object.assign({}, state, {
        selectedRows: [],
        selectedRowsLocationsIds: {},
        selectedColumns: tmp,
        selected: tmp.length > 0,
      });
    case DESELECT_ALL_SCHEDULE:
      return Object.assign({}, state, {
        selectedRows: [],
        selectedColumns: [],
        selectedRowsLocationsIds: {},
        selected: false,
      });
    case CHANGE_SCHEDULE_MODE:
      return Object.assign({}, state, { selectedDisplayMode: action.mode });
    case FLIP_SCHEDULE_MODE:
      return Object.assign({}, state, {
        selectedDisplayMode: displayModes[state.selectedDisplayMode.nextId],
      });
    case MOVE_SELECTED_COLUMNS:
      return Object.assign({}, state, {
        selectedColumns: shiftArrayOfDates(state.selectedColumns, action.dateShiftInt, action.dateMode),
      });
    case UPDATE_PERMISSIONS_SUCCESSFUL:
      return {
        ...state,
        displayModes: scheduleDisplayModes(action.payload),
      };
    case SET_USER_RESTICTIONS_SUCCESSFUL:
      return {
        ...state,
        settings: state.settings.map(setting => {
          const disabled = !!(setting.restriction && action.payload.includes(setting.restriction));
          return {
            ...setting,
            value: disabled ? false : setting.value,
            disabled,
          };
        }),
      };
    case CHANGE_SCHEDULE_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case TOGGLE_SCHEDULE_SETTING:
      return {
        ...state,
        settings: action.payload,
      };
    case TURN_SCHEDULE_SETTINGS_ON:
      return {
        ...state,
        settings: action.payload,
      };
    case TOGGLE_NON_WORKING_DAYS:
      return {
        ...state,
        nonWorkingDays: action.payload,
      };
    case IMPORT_SCHEDULE_DATA:
      return {
        ...state,
        importModal: {
          ...state.importModal,
          isTemplateImported: true,
          importedData: action.payload,
        },
      };
    case UPLOAD_FILE_TO_IMPORT:
      return {
        ...state,
        importModal: {
          ...state.importModal,
          uploadedFile: action.payload,
        },
      };
    case CLEAR_IMPORT_MODAL:
      return {
        ...state,
        importModal: {
          ...state.importModal,
          isTemplateImported: false,
          importedData: [],
          uploadedFile: null,
        },
      };
    case TOGGLE_IMPORT_MODAL_OVERLAY:
      return {
        ...state,
        importModal: {
          ...state.importModal,
          showOverlay: action.payload,
        },
      };
    case CHANGE_SCHEDULE_TABLE_SIZE:
      return {
        ...state,
        size: action.payload,
      };
    case TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY:
      return {
        ...state,
        importBudgetModal: {
          ...state.importBudgetModal,
          showOverlay: action.payload,
        },
      };
    case TOGGLE_IMPORT_BUDGET_MODAL_ERROR:
      return {
        ...state,
        importBudgetModal: {
          ...state.importBudgetModal,
          showInputError: action.payload,
        },
      };
    case TOGGLE_SCHEDULE_AVAILABILITIES_MODE: {
      if (state.selectedDisplayMode.type === SCHEDULE_DISPLAY_MODE_TYPE) {
        const displayMode = state.displayModes.find(mode => mode.type === 'availabilities');
        return {
          ...state,
          selectedDisplayMode: displayMode,
        };
      }

      if (state.selectedDisplayMode.type === 'availabilities') {
        const displayMode = state.displayModes.find(mode => mode.type === SCHEDULE_DISPLAY_MODE_TYPE);
        return {
          ...state,
          selectedDisplayMode: displayMode,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
export default scheduleUIState;
