import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useEffect } from 'react';

const MDTableCheckbox = forwardRef(({ indeterminate, checked, onChange, disabled }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <span
      className={classnames('mdTableCheckbox', 'material-icons', { 'mdTableCheckbox--disabled': disabled })}
      role="button"
      ref={resolvedRef}
      tabIndex={0}
      onClick={disabled ? null : e => onChange(e)}
    >
      {checked ? 'done' : 'x'}
    </span>
  );
});

MDTableCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MDTableCheckbox;
