import PropTypes from 'prop-types';

import LockButton from '@/components/common/LockButton/LockButton';

import { messages } from '../ScheduleTableLoanEmployeesProposals.messages';

interface LoanEmployeesProposalRowTitleProps {
  toggleLoanEmployeesProposalRowStickiness: () => void;
  isLoanEmployeesProposalsSticky: boolean;
}

const LoanEmployeesProposalRowTitle = (
  { toggleLoanEmployeesProposalRowStickiness, isLoanEmployeesProposalsSticky }: LoanEmployeesProposalRowTitleProps,
  { intl },
) => (
  <th className="k-loanEmployeesProposalRowTitle">
    {intl.formatMessage(messages.loanEmployeesProposalsRowTitle)}
    <LockButton
      onClick={e => {
        e.stopPropagation();
        toggleLoanEmployeesProposalRowStickiness();
      }}
      locked={isLoanEmployeesProposalsSticky}
    />
  </th>
);

LoanEmployeesProposalRowTitle.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default LoanEmployeesProposalRowTitle;
