import {
  ADD_NEW_JOBTITLE,
  CLEAR_DEMO_DATA,
  DELETE_JOBTITLE,
  DELETE_JOBTITLE_SET,
  GET_JOBTITLES_SUCCESFUL,
  UPDATE_JOBTITLE,
} from '@/constants/ActionTypes';

const initialState = {};

const userJobTitles = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBTITLES_SUCCESFUL:
      return action.payload.reduce((agg, jobTitle) => ({ ...agg, [jobTitle.id]: jobTitle }), {});
    case ADD_NEW_JOBTITLE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_JOBTITLE:
      return Object.keys(state).reduce((acc, key) => {
        if (key !== action.payload) {
          acc[key] = state[key];
        }
        return acc;
      }, {});

    case DELETE_JOBTITLE_SET: {
      const newState = { ...state };
      action.payload.forEach(locationId => {
        delete newState[locationId];
      });
      return newState;
    }
    case UPDATE_JOBTITLE: {
      const jobTitle = action.payload;
      return { ...state, [jobTitle.id]: jobTitle };
    }
    case CLEAR_DEMO_DATA: {
      return {};
    }
    default:
      return state;
  }
};
export default userJobTitles;
