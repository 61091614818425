import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  generateQrCodesTitle: {
    id: 'export.exportSettings.generateQrCodes',
    defaultMessage: 'Generuj QR Kody',
  },
  printTitle: {
    id: 'export.exportSettings.versionForPrint',
    defaultMessage: 'Wersja do druku',
  },
  printDescription: {
    id: 'export.exportSettings.versionForPrintDescription',
    defaultMessage: 'Pobrany plik będzie zawierał sumaryczne zestawienie z wybranego okresu dla wybranych pracowników',
  },
  separateFiles: {
    id: 'export.exportSettings.separateFiles',
    defaultMessage: 'Osobne pliki',
  },
  separateFilesDescription: {
    id: 'export.exportSettings.separateFilesDescription',
    defaultMessage: 'Dla każdego kodu QR zostanie wygenerowany osobny plik',
  },
  confirmText: {
    id: 'export.exportSettings.confirm',
    defaultMessage: 'Export',
  },
});

export const GENERATE_QR_CODE_OPTIONS = {
  single: 'single',
  multiple: 'multiple',
};

export const qrCodeGenerationOptions = intl => [
  {
    name: intl.formatMessage(messages.printTitle),
    type: GENERATE_QR_CODE_OPTIONS.single,
    description: intl.formatMessage(messages.printDescription),
  },
  {
    name: intl.formatMessage(messages.separateFiles),
    type: GENERATE_QR_CODE_OPTIONS.multiple,
    description: intl.formatMessage(messages.separateFilesDescription),
  },
];
