import { flatten } from 'lodash';
import { PropTypes } from 'prop-types';

import { findMaxElementInArray } from '@/utils/arrayHelpers';
import { getDateFormatForGraphTooltip } from '@/utils/dateHelper';
import {
  checkRecommendedScheduleDisplayPermissions,
  getNuberOfEmployeesForEveryMinute,
  getRecommendedScheduleForDate,
} from '@/utils/recommendedScheduleHelpers';
import { getNumberOfShiftsForEveryMinute, getShiftsForCurrentDay } from '@/utils/schedulerHelpers';

import ScheduleTableHeaderGraphCell from '../ScheduleTableHeaderGraphCell.jsx';

const SingleDayGraphHeader = props => {
  let recommendedEmployeesAndShiftsPoints;
  const showRecommendedScheduleGraph = checkRecommendedScheduleDisplayPermissions(props.userPermissions.restrictions);
  const relevantShifts = props.visibleEmployees.reduce((sum, employeeId) => {
    const shifts = props.shifts[employeeId] ? Object.values(props.shifts[employeeId].shifts) : [];
    return [...sum, shifts];
  }, []);
  const shiftsForDate = getShiftsForCurrentDay(
    relevantShifts,
    props.location.id,
    props.dateStore.dateArray[0],
    props.jobtitleFilter.selectedJobtitlesGrouped,
  );
  const shiftsForHour = getNumberOfShiftsForEveryMinute(shiftsForDate);
  const maxElementForShifts = findMaxElementInArray(flatten(shiftsForHour));
  let maxElement = maxElementForShifts;
  let numOfEmployeesForMinutes = [];
  if (showRecommendedScheduleGraph) {
    const recommendedScheduleDataForCurrentDay = getRecommendedScheduleForDate(
      props.recommendedSchedule.recommendedSchedule,
      props.location.id,
      props.dateStore.dateArray[0],
      props.jobtitleFilter.selectedJobtitlesGrouped,
    );
    numOfEmployeesForMinutes = getNuberOfEmployeesForEveryMinute(recommendedScheduleDataForCurrentDay);
    const maxElementForEmployees = findMaxElementInArray(flatten(numOfEmployeesForMinutes));
    maxElement = maxElementForShifts > maxElementForEmployees ? maxElementForShifts : maxElementForEmployees;
  }

  return (
    <tr className={`${props.dateStore.dateArray.length <= 7 ? 'schedule' : ''} empty`}>
      {shiftsForHour.map((shifts, index) => {
        const points = shifts.reduce((acc, val, i) => acc.concat({ x: i, ye: val }), []);
        if (showRecommendedScheduleGraph) {
          recommendedEmployeesAndShiftsPoints = shifts.reduce(
            (acc, val, i) => acc.concat({ x: i, ye: val, ys: numOfEmployeesForMinutes[index][i] }),
            [],
          );
        }
        return (
          <th key={index} className="graph">
            <ScheduleTableHeaderGraphCell
              day
              hour={index}
              points={showRecommendedScheduleGraph ? recommendedEmployeesAndShiftsPoints : points}
              max={maxElement}
              date={getDateFormatForGraphTooltip(props.dateStore.dateArray[0])}
              showRecommendedScheduleGraph={showRecommendedScheduleGraph}
            />
          </th>
        );
      })}
    </tr>
  );
};

SingleDayGraphHeader.defaultProps = {
  location: {
    id: '',
  },
};

SingleDayGraphHeader.propTypes = {
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  visibleEmployees: PropTypes.arrayOf(PropTypes.string),
  shifts: PropTypes.shape({}),
  location: PropTypes.shape({
    id: PropTypes.number,
  }),
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  recommendedSchedule: PropTypes.shape({
    recommendedSchedule: PropTypes.shape({}),
  }),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitlesGrouped: PropTypes.arrayOf({}),
  }),
};

export default SingleDayGraphHeader;
