const SingleDayHeader = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${`0${i}`.slice(-2)}:00`);
  }
  return (
    <tr className="k-timeScale">
      {hours.map((hour, i) => (
        <th key={i}>{hour}</th>
      ))}
    </tr>
  );
};

SingleDayHeader.propTypes = {};
export default SingleDayHeader;
