import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const getValues = attendance => ({
  timestamp: attendance.timestamp.slice(-8),
  link: (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`http://www.openstreetmap.org/?mlat=${attendance.coords.lat}&mlon=${attendance.coords.lon}&zoom=19`}
    >
      <FormattedMessage id="common.showOnMap" defaultMessage="pokaż na mapie" />
    </a>
  ),
});

const AttendancesGpsWarningContent = ({ relevantAttendances }) => (
  <div className="k-attendanceWarningIcon__popover">
    <FormattedMessage id="attendance.warningIcon.gpsTitle" defaultMessage="Błędy GPS:">
      {text => <h4>{text}</h4>}
    </FormattedMessage>
    <ul className="k-attendanceWarningIcon__gpsList">
      {relevantAttendances.map((attendance, index) => (
        <li className="k-attendanceWarningIcon__gpsElement" key={index}>
          {attendance.out_of_range === 'open' ? (
            <FormattedMessage
              id="attendance.warningIcon.outOfRangeOpen"
              defaultMessage="Pracownik był poza wyznaczonym promieniem podczas otwierania obecności o {timestamp} ({link})"
              values={getValues(attendance)}
            />
          ) : (
            <FormattedMessage
              id="attendance.warningIcon.outOfRangeClose"
              defaultMessage="Pracownik był poza wyznaczonym promieniem podczas zamykania obecności o {timestamp} ({link})"
              values={getValues(attendance)}
            />
          )}
        </li>
      ))}
    </ul>
  </div>
);

AttendancesGpsWarningContent.propTypes = {
  relevantAttendances: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendancesGpsWarningContent;
