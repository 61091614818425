import { useCallback } from 'react';

import { alignAttendancesWeek } from '@/actions/attendances.js';
import { AlignAttendancesModalContent } from '@/components/attendance/modals/AlignAttendancesModalContent/AlignAttendancesModalContent.tsx';
import { ALIGN_ATTENDANCES_MODAL_WEEK } from '@/constants/modalTypes.js';
import { useModal } from '@/hooks/useModal/useModal.ts';
import { useAppDispatch } from '@/redux-store';

export const AlignAttendancesMultipleDaysModal = () => {
  const dispatch = useAppDispatch();
  const { showModal, modalObject } = useModal(ALIGN_ATTENDANCES_MODAL_WEEK);
  const handleAlignAttendances = useCallback(
    (
      startHour: string,
      endHour: string,
      dates: string[],
      relevantAttendances: {
        startAttendances: { date: string }[];
        endAttendances: { date: string }[];
      },
    ) => {
      if (relevantAttendances?.startAttendances?.length || relevantAttendances?.endAttendances?.length) {
        dispatch(alignAttendancesWeek(dates, startHour, endHour, relevantAttendances));
      }
    },
    [dispatch],
  );

  return (
    <AlignAttendancesModalContent
      alignAttendances={handleAlignAttendances}
      showModal={showModal}
      modalObject={modalObject}
      addMatchingShiftsWhenAdding
    />
  );
};
