/* eslint-disable no-return-assign */
import { isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { validateOptionChange } from '@/components/autoscheduler/autoscheduler.helpers';
import Button from '@/components/common/Basic/Button.jsx';
import MDEmployeeSelectBlockWithJobTitles from '@/components/common/MDEmployeesSelectBlock/MDEmployeeSelectBlockWithJobTitles.jsx';
import MDKadroModalHeader from '@/components/common/MDKadroModal/MDKadroModalHeader/ModalHeader.jsx';
import MDModal from '@/components/common/MDModal/Modal.jsx';
import SwitchToFullApp from '@/components/onboarding/SwitchToFullApp.jsx';
import { getDataForRecommendedScheduleFromShifts } from '@/components/scheduler/modals/LoadRecommendedScheduleModal/RecommendedScheduleFromExistingTemplate/RecommendedScheduleFromExistingTemplate.helpers.js';
import { basicTemplateGenerationOptions, scheduleGenerationOptions } from '@/constants/autoSchedule';
import { AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes';
import { OPEN_SHIFTS_TEMPLATE_ID, TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { validateOptions } from '@/utils/autoschedulerHelpers.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import {
  findEmployeesWithDifferentJobTitlesInContractsForDateRange,
  getJobTitleIdsFromContracts,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts.ts';
import { getCalendarRangeText, getRangeBetweenDates } from '@/utils/dateHelper.js';

import AutoGenerateCreateScheduleOptions from '../AutoGenerateCreateScheduleOptions/AutoGenerateCreateScheduleOptions.jsx';
import AutoGenerateMonthPicker from '../AutoGenerateMonthPicker/AutoGenerateMonthPicker.jsx';
import { filterTemplateShifts, formatEmploymentConditions } from './AutoGenerateScheduleModal.helpers.js';
import { messages } from './AutoGenerateScheduleModal.messages.ts';
import {
  DISPLAYED_STEPS_COUNT,
  DISPLAYED_STEPS_COUNT_WHEN_OPEN_SHIFTS_TEMPLATE,
  STEPS_OFFSET,
  STEPS_OFFSET_WHEN_OPEN_SHIFTS_TEMPLATE,
} from './autoGenerateScheduleModalConstants.js';

import '../AutoGenerateStepsModal/AutoGenerateStepsModal.scss';

class AutoGenerateScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState();

    bindPrototypeFunctions(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.showModal && this.state.activeStep !== 0) {
      this.setState({
        activeStep: 0,
      });
    }
    if (nextProps.userTemplates.currentTemplate.id !== this.props.userTemplates.currentTemplate.id) {
      this.setState({ selectedTemplateId: nextProps.userTemplates.currentTemplate.id });
    }
    const isClosingAfterOpenShiftTemplate =
      !nextProps.showModal && nextProps.userTemplates.currentTemplate.id === OPEN_SHIFTS_TEMPLATE_ID;
    if (isClosingAfterOpenShiftTemplate) {
      this.props.deleteOpenShiftTemplate();
    }
    if (!isEqual(nextProps.mainDateStore.customDate, this.props.mainDateStore.customDate)) {
      this.setCalendarDateRange(nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevState.selectedJobTitles, this.state.selectedJobTitles) ||
      !isEqual(prevState.selectedEmploymentConditions, this.state.selectedEmploymentConditions)
    ) {
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    }

    if (
      this.state.templateRanges.length === 1 &&
      prevState.templateRanges[0].templateId !== this.props.userTemplates.currentTemplate.id &&
      this.props.userTemplates.currentTemplate.id === OPEN_SHIFTS_TEMPLATE_ID
    ) {
      const { mainDateStore } = this.props;
      const { start: startDate, end: endDate } = mainDateStore.customDate;
      this.setState({
        templateRanges: [
          {
            id: 0,
            templateId: this.props.userTemplates.currentTemplate.id,
            dateText: getCalendarRangeText(startDate, endDate),
            date: { start: startDate, end: endDate },
          },
        ],
      });
    }

    if (!isEqual(prevState.selectedEmployees, this.state.selectedEmployees) && this.state.activeStep === 1) {
      const { selectedEmployees } = this.state;
      const { mainDateStore } = this.props;
      const { customDate } = mainDateStore;
      const employeeIds = selectedEmployees.map(({ value }) => value);

      const inValidEmployeeIds = findEmployeesWithDifferentJobTitlesInContractsForDateRange(
        employeeIds,
        customDate,
        this.props.contracts,
      );

      const numberOfInvalidEmployeeIds = inValidEmployeeIds.length;
      if (numberOfInvalidEmployeeIds > 0) {
        this.setState({
          errors: {
            ...this.state.errors,
            contracts: this.context.intl.formatMessage(messages.autoGenerateStepsContractsError),
          },
          employeeIdsWithContractError: inValidEmployeeIds,
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            contracts: '',
          },
          employeeIdsWithContractError: [],
        });
      }
    }
  }

  onHide() {
    this.props.hideModal();
    this.setState(this.getInitState());
  }

  onOptionsChange(options, error) {
    this.setState({ options, errors: { ...this.state.errors, step0: error } });
  }

  getSelectedJobTitles(templateShifts) {
    return templateShifts.reduce((prev, shift) => {
      const shiftJobTitleId = shift.job_title_id;
      const jobTitle = this.props.userJobTitles.find(jt => jt.id === shiftJobTitleId);

      if (jobTitle && !prev.some(jt => jt.id === shiftJobTitleId)) {
        prev.push(jobTitle);
      }
      return prev;
    }, []);
  }

  getInitState() {
    const { mainDateStore, userTemplates } = this.props;
    const { start: startDate, end: endDate } = mainDateStore.customDate;

    return {
      activeStep: 0,
      hideCancel: false,
      selectedTemplateId: userTemplates.currentTemplate.id,
      templateRanges: [
        {
          id: 0,
          templateId: userTemplates.currentTemplate.id,
          dateText: getCalendarRangeText(startDate, endDate),
          date: { start: startDate, end: endDate },
        },
      ],
      selectedEmployees: [],
      errors: {
        step0: <div />,
        step1: <div />,
        contracts: '',
      },
      options: Object.values(basicTemplateGenerationOptions),
      shortTimeout: true,
      selectedEmploymentConditions: formatEmploymentConditions(this.props.employmentConditions),
      employeeIdsWithContractError: [],
    };
  }

  getSelectedEmployees(selectedJobTitles, selectedEmploymentConditions) {
    if (!selectedJobTitles) return [];
    const {
      selectedLocations: selectedLocationIds,
      contracts,
      mainDateStore: {
        customDate: { start: from, end: to },
      },
    } = this.props;
    const supplementaryEmployeesOption = this.state.options.find(
      option => option.name === 'include_supplementary_employees',
    );
    const filterOutSupplementaryEmployees = supplementaryEmployeesOption && !supplementaryEmployeesOption.enabled;

    function detectType(data) {
      if (typeof data === 'string') {
        return data;
      }
      if (typeof data === 'object' && data !== null && data instanceof Date) {
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, '0');
        const day = String(data.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return 'unknown';
    }

    const templateStart = detectType(this.state.templateRanges[0].date.start);
    let templateEnd;
    if (this.state.templateRanges.length > 1) {
      templateEnd = detectType(this.state.templateRanges[this.state.templateRanges.length - 1].date.end);
    } else {
      templateEnd = detectType(this.state.templateRanges[0].date.end);
    }
    const filteredEmployees = this.props.userEmployees.filter(employee => {
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
      const hasJobTitle = employeeJobTitleIds.some(jobTitleId =>
        selectedJobTitles.map(jobTitle => jobTitle.id).includes(jobTitleId),
      );

      return (
        !employee.inactive &&
        employee.locations.map(l => l.id).some(id => selectedLocationIds.includes(id)) &&
        (!filterOutSupplementaryEmployees ||
          !employee.supplementary_locations_ids
            .map(id => id.toString())
            .some(id => selectedLocationIds.includes(id))) &&
        hasJobTitle &&
        selectedEmploymentConditions.some(
          ({ active, value }) => active && value === employee.employment_conditions.template_id,
        ) &&
        (employee.employment_conditions.release_date >= templateStart ||
          !employee.employment_conditions.release_date) &&
        (employee.employment_conditions.hire_date <= templateEnd || !employee.employment_conditions.hire_date)
      );
    });

    return filteredEmployees.map(employee => ({
      value: employee.id,
      label: `${employee.first_name} ${employee.last_name}`,
    }));
  }

  getEmployeesOptions() {
    return this.employeesToChoose.map(({ label, value }) => {
      const {
        first_name: firstName,
        last_name: lastName,
        id,
      } = this.props.userEmployees.find(empl => empl.id === value);
      return {
        value,
        sortValue: firstName + lastName + id,
        label,
        active: this.isEmployeeSelected({ value }),
      };
    });
  }

  getJobTitlesOptions() {
    return this.jobTitlesToChoose.map(jobTitle => ({
      value: jobTitle.id,
      sortValue: jobTitle.title + jobTitle.id,
      label: jobTitle.title,
      color: jobTitle.color,
      active: this.isJobTitleSelected(jobTitle),
    }));
  }

  setSelectedTemplateId(templateId) {
    this.setState(prev => ({
      selectedTemplateId: templateId,
      errors: {
        ...prev.errors,
        step0: <div />,
      },
    }));
  }

  setConfiguration(option) {
    const newOptions = this.state.options.map(opt => {
      if (opt.name === option.name) {
        return { ...option, enabled: !option.enabled };
      }
      return opt;
    });
    const error = validateOptions(newOptions);
    this.setState(prev => ({
      options: newOptions,
      errors: { ...prev.errors, ...error },
    }));
  }

  setConfigurationInputValue(option, value) {
    const newOptions = this.state.options.map(opt => {
      if (
        opt.name === option.name &&
        validateOptionChange(this.state.options, option, value, this.props.mainDateStore)
      ) {
        return { ...option, value };
      }
      return opt;
    });
    const error = validateOptions(newOptions);

    this.setState(prev => ({
      options: newOptions,
      errors: { ...prev.errors, ...error },
    }));
  }

  setShortTimeout() {
    this.setState(prev => ({
      shortTimeout: !prev.shortTimeout,
    }));
  }

  setDailyOvertime() {
    this.setState(prev => ({
      dailyOvertimeEnabled: !prev.dailyOvertimeEnabled,
    }));
  }

  setTemplateRanges(ranges, validate = false) {
    this.setState(
      {
        templateRanges: ranges,
      },
      () => {
        if (validate) {
          const isValidMonth = this.validatePeriod();
          const { isValidRange } = this.validateRanges();
          if (isValidMonth && isValidRange) {
            this.setState(prev => ({
              errors: {
                ...prev.errors,
                step0: <div />,
              },
            }));
          }
        }
      },
    );
  }

  setSelectedEmployees(selectedEmployee) {
    if (this.isEmployeeSelected(selectedEmployee)) {
      const newEmployees = this.state.selectedEmployees.filter(employee => employee.value !== selectedEmployee.value);
      this.setState({ selectedEmployees: newEmployees });
    } else {
      this.setState(prev => ({
        selectedEmployees: [...prev.selectedEmployees, selectedEmployee],
      }));
    }
  }

  setSelectedJobTitles(selectedJobTitle) {
    if (this.isJobTitleSelected(selectedJobTitle)) {
      const newJobTitles = this.state.selectedJobTitles.filter(({ id }) => id !== selectedJobTitle.value);
      this.setState({ selectedJobTitles: newJobTitles });
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    } else {
      const newJobTitle = this.jobTitlesToChoose.find(({ id }) => id === selectedJobTitle.value);
      this.setState(prev => ({
        selectedJobTitles: [...prev.selectedJobTitles, newJobTitle],
      }));
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    }
  }

  setCalendarDateRange(nextProps) {
    this.setState({
      templateRanges: [
        {
          id: 0,
          templateId: this.props.userTemplates.currentTemplate.id,
          dateText: getCalendarRangeText(
            nextProps.mainDateStore.customDate.start,
            nextProps.mainDateStore.customDate.end,
          ),
          date: { start: nextProps.mainDateStore.customDate.start, end: nextProps.mainDateStore.customDate.end },
        },
      ],
    });
  }

  isEmployeeSelected(employee) {
    const id = employee.value || employee.id;
    return this.state.selectedEmployees.some(selectedEmployee => selectedEmployee.value === id);
  }

  isJobTitleSelected(jobTitle) {
    const id = jobTitle.value || jobTitle.id;
    return this.state.selectedJobTitles.some(selectedJobTitle => selectedJobTitle.id === id);
  }

  selectSome() {
    this.setState({ selectedEmployees: this.employeesToChoose });
  }

  deselectAll() {
    this.setState({ selectedEmployees: [] });
  }

  deselectAllJobTitles() {
    this.setState({ selectedJobTitles: [] });
    this.employeesToChoose = [];
    this.deselectAll();
  }

  selectAllJobTitles() {
    this.setState({ selectedJobTitles: this.jobTitlesToChoose });
    this.employeesToChoose = this.getSelectedEmployees(this.jobTitlesToChoose, this.state.selectedEmploymentConditions);
    this.selectSome();
  }

  validatePeriod() {
    const { mainDateStore } = this.props;
    const { dateArray, dateMode } = mainDateStore;
    const currentPeriodStart = dateArray[0];
    const currentPeriodEnd = dateArray[dateArray.length - 1];

    const isValid = !this.state.templateRanges.some(
      range =>
        !(
          moment(range.date.start).isBetween(currentPeriodStart, currentPeriodEnd, 'day', '[]') &&
          moment(range.date.end).isBetween(currentPeriodStart, currentPeriodEnd, 'day', '[]')
        ),
    );
    if (!isValid) {
      let message = (
        <FormattedMessage
          id="schedule.autoGenerateMonth.invalidPeriod"
          defaultMessage="Musisz wybrać daty z aktualnie wybranego okresu ({dateRange})"
          values={{
            dateRange: `${moment(currentPeriodStart).format('DD.MM')}-${moment(currentPeriodEnd).format(
              'DD.MM.YYYYr.',
            )}`,
          }}
        />
      );
      if (dateMode === 'month') {
        message = (
          <FormattedMessage
            id="schedule.autoGenerateMonth.invalidMonth"
            defaultMessage="Musisz wybrać daty z aktualnie wybranego miesiąca ({month})"
            values={{
              month: moment(this.props.mainDateStore.dateArray[0]).format('MMMM'),
            }}
          />
        );
      }
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: message,
        },
      }));
    }
    return isValid;
  }

  validateRanges() {
    const sortedRanges = [...this.state.templateRanges]
      .filter(range => range.date.start && range.date.end)
      .sort((a, b) => (moment(a.date.start).isAfter(b.date.start) ? 1 : -1));

    const isValidRange = sortedRanges.reduce((prev, currentRange, index, ranges) => {
      if (index === 0) {
        return true;
      }
      const previousRange = ranges[index - 1];
      return moment(previousRange.date.end).isSameOrAfter(currentRange.date.start) ? false : prev;
    }, true);
    if (!isValidRange) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.invalidRange"
              defaultMessage="Wybrane daty nie mogą na siebie nachodzić"
            />
          ),
        },
      }));
    }
    return { isValidRange, sortedRanges };
  }

  validateOptions() {
    const { options } = this.state;
    const error = validateOptions(options);
    if (error) {
      this.setState({
        errors: {
          ...this.state.errors,
          step0: error,
        },
      });
      return { hasValidOptions: false };
    }
    this.setState({
      errors: {
        ...this.state.errors,
        step0: <div />,
      },
    });
    return {
      hasValidOptions: true,
    };
  }

  validateSelectedJobTitles(selectedJobTitles) {
    const hasSelectedJobTitles = selectedJobTitles.length;
    if (!hasSelectedJobTitles) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.invalidTemplate"
              defaultMessage="Wybrany szablon jest pusty"
            />
          ),
        },
      }));
    }
    return hasSelectedJobTitles;
  }

  validateSelectedEmployees() {
    const hasSelectedEmployees = this.state.selectedEmployees.length;
    if (!hasSelectedEmployees) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step1: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.noEmployeesSelected"
              defaultMessage="Proszę wybrać conajmniej jednego pracownika"
            />
          ),
        },
      }));
    }
    return hasSelectedEmployees;
  }

  selectAllEmploymentConditions() {
    this.setState({
      selectedEmploymentConditions: this.state.selectedEmploymentConditions.map(employmentCondition => ({
        ...employmentCondition,
        active: true,
      })),
    });
  }

  deselectAllEmploymentConditions() {
    this.setState({
      selectedEmploymentConditions: this.state.selectedEmploymentConditions.map(employmentCondition => ({
        ...employmentCondition,
        active: false,
      })),
    });
  }

  setSelectedEmploymentConditions(e) {
    const { value } = e;
    this.setState(prevState => {
      const selectedEmploymentConditions = prevState.selectedEmploymentConditions.map(employmentCondition =>
        employmentCondition.value === value
          ? { ...employmentCondition, active: !employmentCondition.active }
          : employmentCondition,
      );
      return { selectedEmploymentConditions };
    });
  }

  render() {
    let modalBody;
    const userTemplates = {
      ...this.props.userTemplates,
      templatesArray: this.props.userTemplates.templatesArray.filter(t => t.type === TEMPLATE_TYPES.BASIC),
    };
    const { openShifts } = this.props;
    const selectedOpenShiftsTemplate = this.state.selectedTemplateId === OPEN_SHIFTS_TEMPLATE_ID;
    const { activeStep, errors } = this.state;

    const options = (
      <AutoGenerateCreateScheduleOptions
        error={this.state.errors.step0}
        handleTemplateChange={this.setSelectedTemplateId}
        handleToggle={this.setConfiguration}
        handleInputChange={this.setConfigurationInputValue}
        options={this.state.options}
        selectedTemplate={this.state.selectedTemplateId}
        templates={userTemplates}
        showTemplateSelect={this.props.mainDateStore.dateMode === 'week'}
      />
    );

    switch (activeStep) {
      case 0:
        this.previousHandler = () => this.props.showModalFunc(AUTO_GENERATE_STEPS_MODAL, { step: 0 });

        if (this.props.mainDateStore.dateMode === 'week') {
          this.nextHandler = () => {
            const { hasValidOptions } = this.validateOptions();
            if (!hasValidOptions) return;
            const template = this.props.userTemplates.templatesArray.find(
              item => item.id === this.state.selectedTemplateId,
            );

            const templateShifts = template.shifts
              .filter(shift => !shift.draft)
              .map(shift => {
                const date = shift.date === 0 ? 7 : shift.date;
                return {
                  date: this.props.mainDateStore.dateArray[date - 1],
                  working_hours: shift.working_hours,
                  job_title_id: shift?.job_title_id || shift?.job_title?.id,
                  amount: shift.amount,
                  gid: shift.gid,
                  openShiftId: shift.openShiftId,
                };
              });
            this.jobTitlesToChoose = this.getSelectedJobTitles(templateShifts);
            const hasSelectedJobTitles = this.validateSelectedJobTitles(this.jobTitlesToChoose);
            this.setState({
              selectedJobTitles: this.getSelectedJobTitles(templateShifts),
            });
            this.employeesToChoose = this.getSelectedEmployees(
              this.jobTitlesToChoose,
              this.state.selectedEmploymentConditions,
            );

            if (!hasSelectedJobTitles || !this.employeesToChoose || this.employeesToChoose.length === 0) return;

            this.setState({
              activeStep: 1,
              templateShifts,
              selectedEmployees: this.employeesToChoose,
            });
          };
          modalBody = options;
        } else {
          this.nextHandler = () => {
            const isValidMonth = this.validatePeriod();
            const { isValidRange, sortedRanges } = this.validateRanges();
            const { hasValidOptions } = this.validateOptions();
            if (!isValidMonth || !isValidRange || !hasValidOptions) return;
            const templateShifts = sortedRanges.reduce((prev, range) => {
              const dateArray = getRangeBetweenDates(range.date.start, range.date.end);
              const template = this.props.userTemplates.templatesArray.find(
                userTemplate => userTemplate.id === range.templateId,
              );
              if (template.id === OPEN_SHIFTS_TEMPLATE_ID) {
                const mappedTemplateShifts = template.shifts.map(shift => {
                  const matchedOpenShift = openShifts.find(openShift => openShift.id === shift.openShiftId);
                  return {
                    date: matchedOpenShift.date,
                    working_hours: shift.working_hours,
                    job_title_id: shift?.job_title_id || shift?.job_title?.id,
                    amount: shift.amount,
                    openShiftId: shift.openShiftId,
                  };
                });
                return [...prev, ...mappedTemplateShifts];
              }
              return prev.concat(
                ...dateArray.map(date => {
                  const dayOfWeek = moment(date).day();
                  return [
                    ...template.shifts
                      .filter(shift => shift.date === dayOfWeek)
                      .map(shift => ({
                        date,
                        working_hours: shift.working_hours,
                        job_title_id: shift?.job_title_id || shift?.job_title?.id,
                        amount: shift.amount,
                        gid: shift.gid,
                        openShiftId: shift.openShiftId,
                      })),
                  ];
                }),
              );
            }, []);

            this.jobTitlesToChoose = this.getSelectedJobTitles(templateShifts);
            this.setState({
              selectedJobTitles: this.jobTitlesToChoose,
            });
            const hasSelectedJobTitles = this.validateSelectedJobTitles(this.jobTitlesToChoose);

            if (!hasSelectedJobTitles) return;
            this.employeesToChoose = this.getSelectedEmployees(
              this.state.selectedJobTitles,
              this.state.selectedEmploymentConditions,
            );
            this.setState({
              activeStep: 1,
              templateShifts,
              selectedEmployees: this.employeesToChoose,
            });
          };
          modalBody = (
            <AutoGenerateMonthPicker
              userTemplates={userTemplates}
              onRangeChange={this.setTemplateRanges}
              mainDateStore={this.props.mainDateStore}
              ranges={this.state.templateRanges}
              options={options}
              selectedTemplate={this.state.selectedTemplateId}
            />
          );
        }
        break;
      case 1: {
        this.previousHandler = () =>
          this.setState({
            activeStep: 0,
          });

        this.nextHandler = () => {
          const { options } = this.state;
          const showForecastScheduleForBasicTemplate = this.state.options.find(
            opt => opt.name === scheduleGenerationOptions.show_forecast_schedule_for_basic_template.name,
          ).enabled;
          const hasSelectedEmployees = this.validateSelectedEmployees();
          if (!hasSelectedEmployees) {
            this.props.noEmployeesSelected();
            return;
          }
          const configOptions = options.filter(opt => opt.isConfigurationOption);
          const shortTimeout = options.find(
            opt => opt.name === basicTemplateGenerationOptions.short_timeout.name,
          ).enabled;
          const availableUndefinedDays = options.find(
            opt => opt.name === basicTemplateGenerationOptions.availability_without_entered.name,
          ).enabled;

          this.setState({
            activeStep: 2,
          });

          const filteredTemplateShifts = filterTemplateShifts(this.state.templateShifts, this.state.selectedJobTitles);
          this.props.sendAutoSchedulerInformation(
            filteredTemplateShifts,
            this.props.mainDateStore.dateArray,
            this.state.selectedEmployees,
            configOptions,
            shortTimeout,
            availableUndefinedDays,
            TEMPLATE_TYPES.BASIC,
          );

          this.props.showModalFunc(AUTO_GENERATE_STEPS_MODAL, { step: 3, selectedOpenShiftsTemplate });

          if (showForecastScheduleForBasicTemplate) {
            const { templateShifts } = this.state;
            const { mainDateStore, selectedLocations, addRecommendedSchedule } = this.props;
            const data = getDataForRecommendedScheduleFromShifts(
              templateShifts,
              mainDateStore.dateArray,
              selectedLocations[0],
            );
            addRecommendedSchedule(data);
          }
        };
        const selectedEmployees = this.state.selectedEmployees.map(employee => {
          if (this.state.employeeIdsWithContractError.includes(employee.value))
            return {
              ...employee,
              tooltipError: this.context.intl.formatMessage(messages.autoGenerateStepsContractsTooltipError),
            };
          return employee;
        });

        modalBody = (
          <MDEmployeeSelectBlockWithJobTitles
            selectedJobTitles={this.state.selectedJobTitles}
            setSelectedJobTitles={this.setSelectedJobTitles}
            selectAllJobTitles={this.selectAllJobTitles}
            jobTitlesToChoose={this.jobTitlesToChoose}
            deselectAllJobTitles={this.deselectAllJobTitles}
            neededCount={this.employeesToChoose.length}
            options={this.getEmployeesOptions()}
            jobTitleOptions={this.getJobTitlesOptions()}
            selectedItems={selectedEmployees}
            shouldBeSorted={false}
            handleChange={this.setSelectedEmployees}
            handleSelectAll={this.selectSome}
            handleDeselectAll={this.deselectAll}
            selectAllEmploymentConditions={this.selectAllEmploymentConditions}
            deselectAllEmploymentConditions={this.deselectAllEmploymentConditions}
            selectedEmploymentConditions={this.state.selectedEmploymentConditions}
            setSelectedEmploymentConditions={this.setSelectedEmploymentConditions}
          />
        );
        break;
      }
      default:
        this.nextHandler = () => null;
        this.previousHandler = () => null;
        modalBody = <div />;
    }
    if (this.props.demo.demoAccount && this.props.showModal) {
      return <SwitchToFullApp cancel={this.onHide} onHide={this.onHide} />;
    }

    const progressBarWitdh = (100 / DISPLAYED_STEPS_COUNT) * (activeStep + STEPS_OFFSET);

    const stepsCount = selectedOpenShiftsTemplate
      ? `${activeStep + STEPS_OFFSET_WHEN_OPEN_SHIFTS_TEMPLATE}/
        ${DISPLAYED_STEPS_COUNT_WHEN_OPEN_SHIFTS_TEMPLATE}`
      : `${activeStep + STEPS_OFFSET} / ${DISPLAYED_STEPS_COUNT}`;
    const hideBackButton = selectedOpenShiftsTemplate && activeStep === 0;

    return (
      <MDModal
        className="k-autoGenerateSteps"
        show={this.props.showModal}
        onHide={this.onHide}
        modifiers={['narrow']}
        onSubmit={this.nextHandler}
        errorMessage={this.state.errorMessage}
        isAnimated={false}
      >
        <>
          <MDModal.Content withoutOverlayScroll>
            <MDKadroModalHeader onHide={this.onHide} />
            {modalBody}
            {errors.contracts && this.state.activeStep === 1 && (
              <span className="k-autoGenerateSteps__error">
                {this.context.intl.formatMessage(messages.autoGenerateStepsContractsError)}
              </span>
            )}
          </MDModal.Content>

          <MDModal.Footer>
            <div className="k-autoGenerateSteps__buttons">
              <Button
                onClick={this.nextHandler}
                modifiers="orange teeny uppercase"
                className="k-autoGenerateSteps__button--next"
                disabled={this.state.error || Boolean(this.state.errors.contracts)}
              >
                {activeStep === 1 ? (
                  <FormattedMessage id="common.generate" defaultMessage="Generuj" />
                ) : (
                  <FormattedMessage id="common.next" defaultMessage="Dalej" />
                )}
              </Button>
              {!hideBackButton && (
                <Button onClick={this.previousHandler} modifiers="reverse-orange teeny uppercase">
                  <FormattedMessage id="common.back" defaultMessage="Wstecz" />
                </Button>
              )}
            </div>
            <div className="k-autoGenerateSteps__progress">
              <div className="k-autoGenerateSteps__progressText">{stepsCount}</div>
              <div className="k-autoGenerateSteps__progressBar" style={{ width: `${progressBarWitdh}%` }} />
            </div>
          </MDModal.Footer>
        </>
      </MDModal>
    );
  }
}

AutoGenerateScheduleModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AutoGenerateScheduleModal.propTypes = {
  showModal: PropTypes.bool,
  userTemplates: PropTypes.shape({
    templatesArray: PropTypes.arrayOf(PropTypes.shape),
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape),
  userJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  showModalFunc: PropTypes.func,
  hideModal: PropTypes.func,
  sendAutoSchedulerInformation: PropTypes.func,
  demo: PropTypes.shape({
    demoAccount: PropTypes.bool,
  }),
  noEmployeesSelected: PropTypes.func,
  openShifts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, date: PropTypes.string })),
  deleteOpenShiftTemplate: PropTypes.func,
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  addRecommendedSchedule: PropTypes.func,
};

export default AutoGenerateScheduleModal;
