import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';

import './ReportsButtonBar.scss';

const ReportsButtonBar = ({ getSystemBonusExportData }) => (
  <div className="reportsButtonBar">
    <ButtonBar>
      <ActionsButton icon={<Icon name="more_vert" />}>
        <button className="k-actionsButton__element" onClick={getSystemBonusExportData}>
          <FormattedMessage id="export.reports" defaultMessage="Eksportuj raport" />
        </button>
      </ActionsButton>
    </ButtonBar>
  </div>
  );

ReportsButtonBar.propTypes = {
  getSystemBonusExportData: PropTypes.func,
};

export default ReportsButtonBar;
