import { connect } from 'react-redux';

import { changePayrollColumns as changeNewPayrollColumns } from '@/actions/payroll/payroll.js';
import { changePayrollLocationColumns as changeNewPayrollLocationColumns } from '@/actions/payrollLocation/payrollLocation';
import {
  changePayrollColumns,
  changePayrollLocationColumns,
  getPayrollColumns,
  getPayrollLocationColumns,
} from '@/actions/uiState';

import TopBarMultipleColumnFilter from './TopBarMultipleColumnFilter.jsx';

const mapStateToProps = state => ({
  visibleLocationColumns: state.reducer.uiState.payrollLocationVisibleColumns,
  visiblePayrollColumns: state.reducer.uiState.payrollVisibleColumns,
  visibleNewPayrollColumns: state.reducer.payroll.visibleColumns,
  visibleNewPayrollLocationColumns: state.reducer.payrollLocation.visibleColumns,
  userPermissions: state.reducer.userPermissions,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
  companySettings: state.reducer.currentCompany.settings,
});

const mapDispatchToProps = {
  getPayrollLocationColumns,
  getPayrollColumns,
  changePayrollLocationColumns,
  changePayrollColumns,
  changeNewPayrollColumns,
  changeNewPayrollLocationColumns,
};

const TopBarMultipleColumnFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarMultipleColumnFilter);

export default TopBarMultipleColumnFilterContainer;
