import { createSlice } from '@reduxjs/toolkit';

import { getOpenShiftsAssignments } from './openShiftsAssignments.asyncActions.ts';
import { OpenShiftsAssignmentsStoreState } from './openShiftsAssignments.types.ts';

export const initialState: OpenShiftsAssignmentsStoreState = {};
export const openShiftsAssignmentsSliceName = 'openShiftsAssignments';

const OpenShiftsAssignmentsSlice = createSlice({
  name: openShiftsAssignmentsSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOpenShiftsAssignments.fulfilled, (_state, action) => action.payload);
  },
});

export const openShiftsAssignmentsReducer = OpenShiftsAssignmentsSlice.reducer;
