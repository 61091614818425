import { conn } from '@/actions';
import { openShiftsAssignmentsSliceName } from '@/redux-store/openShiftsAssignments/openShiftsAssignemnts.slice.ts';
import { OpenShiftsAssignmentsStoreState } from '@/redux-store/openShiftsAssignments/openShiftsAssignments.types.ts';
import { openShiftsAssignmentsToStore } from '@/redux-store/openShiftsAssignments/openShiftsAssignments.utils.ts';
import { createActionName, createAppAsyncThunk } from '@/redux-store/utils.ts';
import { Location } from '@/types';
import { StandardDate } from '@/types/dates.types.ts';

export const getOpenShiftsAssignments = createAppAsyncThunk(
  createActionName(openShiftsAssignmentsSliceName, 'getOpenShiftsAssignments'),
  async ({
    from,
    to,
    locationIds,
  }: {
    from: StandardDate;
    to: StandardDate;
    locationIds: Location['id'][];
  }): Promise<OpenShiftsAssignmentsStoreState> => {
    const response = await conn.getOpenShiftsAssignments(from, to, locationIds);
    return openShiftsAssignmentsToStore(response.data);
  },
);
