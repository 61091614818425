import PropTypes from 'prop-types';
import { Component } from 'react';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import GoogleButton from '@/components/common/Basic/GoogleButton/GoogleButton.redux';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import CircleLoader from '@/components/common/UI/CircleLoader/CircleLoader.jsx';
import ShadowLoader from '@/components/misc/ShadowLoader/ShadowLoader';
import browserHistory from '@/constants/browserHistory';
import { GOOGLE_CLIENT_ID } from '@/constants/envVariables.ts';
import { base64ToUtf8 } from '@/utils/base64.helpers';
import { addDefaultProductToCart, gaEvents, getDefaultGAProducts } from '@/utils/ga.helpers';
import { inputHasErrors, inputValidation } from '@/utils/inputValidation.js';

import messages from './messages';

class OnboardingFirstHello extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: '',
      errors: {},
      acceptedRegulations: false,
      showLoader: false,
    };

    this.initEmailCheck = this.initEmailCheck.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.validateAll = this.validateAll.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    TagManager.initialize({ gtmId: 'GTM-NBVJFJ3' });
    this.initEmailCheck();
    const productsQueryString = this.props.location?.query.p;
    const useDefaultProducts = !productsQueryString;
    const products = useDefaultProducts ? getDefaultGAProducts() : JSON.parse(base64ToUtf8(productsQueryString));
    window.gaProducts = products;
    if (useDefaultProducts) addDefaultProductToCart();
    gaEvents.checkoutStart(window.gaProducts);
    gaEvents.setOnboardingType('4-step-flow');
  }

  initEmailCheck() {
    if (this.props.location?.query.email) {
      const { email } = this.props.location.query;
      this.setState(
        {
          email,
          showLoader: true,
        },
        () => {
          this.validateInput({
            target: {
              name: 'email',
              value: email,
            },
          }).then(() => {
            if (!this.state.errors.email) {
              this.props
                .checkEmail(this.state.email)
                .then(() => {
                  this.props.signUp(this.state.email);
                  browserHistory.push('/onboarding/stepOne');
                })
                .catch(() => {
                  this.setState({
                    errors: {
                      ...this.state.errors,
                      email: this.context.intl.formatMessage(messages.emailUsed, {}),
                    },
                  });
                  this.setState({
                    showLoader: false,
                  });
                });
            } else {
              this.setState({
                showLoader: false,
              });
            }
          });
        },
      );
    }
  }

  handleInputChange(e) {
    const { target } = e;
    const { name } = target;
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });

    const { errors } = this.state;

    if (inputHasErrors(name, errors)) {
      this.validateInput(e);
    }
  }

  submit(e) {
    e.preventDefault();
    return this.validateAll().then((valid) => {
      if (!valid) return;
      this.props
        .checkEmail(this.state.email)
        .then(() => {
          this.props.signUp(this.state.email);
          browserHistory.push('/onboarding/stepOne');
        })
        .catch(() => {
          this.setState({
            errors: {
              ...this.state.errors,
              email: this.context.intl.formatMessage(messages.emailUsed, {}),
            },
          });
        });
    });
  }

  validateInput(event) {
    return new Promise((resolve) => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const error = inputValidation(target.name, value);
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [target.name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  async validateAll() {
    const inputs = ['email'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    let canContinue = true;
    Object.values(this.state.errors).map((err) => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  render() {
    return (
      <div className="k-onboardingLayout">
        <div className="k-onboardingLayout__top" />
        <div className="k-onboardingLayout__bottom" />
        <div className="k-onboardingLayout__box">
          <div className="k-onboardingLayout__logo">
            <a href="/">
              <img className="k-onboardingLayout__outscreenLogo" src="/img/logo4.png" alt="logo" />
            </a>
          </div>
          <div className="k-onboardingFirstHello">
            <CircleLoader show={this.state.showLoader} />
            <div className="k-onboardingFirstHello__title">
              <FormattedMessage
                id="onboarding.firstHello.title"
                defaultMessage="Witaj w {highlight}"
                values={{
                  highlight: <span className="k-onboardingFirstHello__welcomeHighlight">Kadromierz</span>,
                }}
              />
            </div>
            <form onSubmit={this.submit}>
              <div className="k-onboardingFirstHello__subtitle">
                <FormattedMessage
                  id="onboarding.firstHello.subtitle"
                  defaultMessage="Aby rozpocząć, podaj swój adres e-mail:"
                />
              </div>
              <div className="k-onboardingFirstHello__emailButton">
                <MDTextInput
                  id="email"
                  value={this.state.email}
                  errorMessage={this.state.errors.email}
                  icon="k-icon-email"
                  label={this.context.intl.formatMessage(messages.email, {})}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="k-onboardingFirstHello__loginButton">
                <Button className="k-onboardingFirstHello__nextButton" type="submit" modifiers="high uppercase orange">
                  <FormattedMessage id="common.next" defaultMessage="Dalej" />
                </Button>
                {GOOGLE_CLIENT_ID && (
                  <>
                    <span className="k-onboardingFirstHello__loginOrText">
                      <FormattedMessage id="login.view.or" defaultMessage="lub" />
                    </span>
                    <GoogleButton />
                  </>
                )}
              </div>
            </form>
            <div className="k-onboardingFirstHello__termsText">
              <FormattedMessage
                id="onboarding.firstHello.terms"
                defaultMessage="Rejestrując się potwierdzasz, że zapoznałeś się z {tos} Kadromierz i {policy} oraz akceptujesz ich postanowienia."
                values={{
                  tos: (
                    <a
                      href="https://kadromierz.pl/wp-content/uploads/2023/08/Kadromierz_regulamin_serwisu.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="onboarding.firstHello.tos" defaultMessage="regulaminem" />
                    </a>
                  ),
                  policy: (
                    <a
                      href="https://kadromierz.pl/wp-content/uploads/2023/05/Kadromierz_Polityka-prywatnosci.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage id="onboarding.firstHello.policy" defaultMessage="Polityką prywatności" />
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <ShadowLoader showLoading={this.props.showLoader} />
      </div>
    );
  }
}

OnboardingFirstHello.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

OnboardingFirstHello.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
  signUp: PropTypes.func,
  checkEmail: PropTypes.func,
};

export default OnboardingFirstHello;
