import { connect } from 'react-redux';

import EmployeesEditMassAddContractField from './EmployeesEditMassContractsField';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
  selectedEmployeesIds: state.reducer.listsUi.employees.selected,
  contracts: state.reducer.contracts,
});

export default connect(mapStateToProps)(EmployeesEditMassAddContractField);
