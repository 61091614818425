import {
  Bar,
  BarChart as RechartBarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

import { LIGHTER_GRAY } from '@/constants/colors';

type BarSeries = {
  dataKey: Bar['props']['dataKey'];
  fill: Bar['props']['fill'];
  fillActive: Bar['props']['fill'];
  id: string;
  label: Bar['props']['label'];
  stroke: Bar['props']['stroke'];
  strokeActive: Bar['props']['stroke'];
};

export type BarChartProps = {
  data: CategoricalChartProps['data'];
  height?: number;
  series: BarSeries[];
  xAxisAngle?: number;
  xKey: string;
};

export const BarChart = ({ data, height = 300, series, xAxisAngle = 0, xKey }: BarChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <RechartBarChart width={500} height={height} data={data}>
        <CartesianGrid strokeDasharray="0" opacity={0.3} />
        <XAxis
          dataKey={xKey}
          textAnchor={xAxisAngle ? 'end' : 'middle'}
          angle={xAxisAngle}
          style={{ fontSize: '12px' }}
          height={60}
        />
        <YAxis />
        <Tooltip cursor={{ fill: LIGHTER_GRAY, opacity: 0.5 }} />
        <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '10px' }} />
        {series.map((s) => (
          <Bar key={s.id} {...s} activeBar={<Rectangle fill={s.fillActive} stroke={s.strokeActive} />} />
        ))}
      </RechartBarChart>
    </ResponsiveContainer>
  );
};
