import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  blockedAccountTitle: {
    id: 'blockedAccountView.paymentTitle.blockedAccountTitle',
    defaultMessage: 'Konto zostało zablokowane',
  },
  blockedNotOwnerAccountTitle: {
    id: 'blockedAccountView.paymentTitle.blockedNotOwnerAccountTitle',
    defaultMessage: 'Dostęp do konta jest zablokowany',
  },
  blockedAccountContent: {
    id: 'blockedAccountView.content.blockedAccountContent',
    defaultMessage:
      'Konto firmowe, na które próbujesz się zalogować zostało zablokowane. Skontaktuj się z administratorem tego konta firmowego, aby uzyskać pomoc.',
  },
  blockedAccountImgAltText: {
    id: 'blockedAccountView.content.blockedAccountImgAltText',
    defaultMessage: 'Kobieta siedząca przy komputerze',
  },
});
