import { connect } from 'react-redux';

import LoadBudgetTargetModalManually from './LoadBudgetTargetModalManually.jsx';

const mapStateToProps = ({ reducer }) => {
  const locationId = reducer.scheduleLocationFilter[0];
  return {
    budgetSummary: reducer.schedule.budget.budgetEstimates.summary,
    isBudgetTargetEnabled: reducer.settings.locationSettings[locationId].enable_budget_target,
  };
};

export default connect(mapStateToProps)(LoadBudgetTargetModalManually);
