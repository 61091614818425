import { Locale } from 'date-fns/locale';

export enum KadroLocaleEnum {
  PL = 'pl',
  EN = 'en',
  PL_ADECCO = 'pl-adecco',
}

type LocalConfig = {
  dateFnsLocale: Locale;
  kadroExportLanguage: string;
  momentLanguage: string;
  weatherLanguage: string;
  momentLocale: unknown;
};

export type LocaleConfigs = Record<KadroLocaleEnum, LocalConfig>;
