import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import { checkIfCanCopy, checkIfCanPaste } from '@/actions/copyPaste.js';
import { copy, deleteSelected, paste } from '@/actions/schedule.jsx';
import { showModal } from '@/actions/uiState.js';

import TemplatesButtonBar from './TemplatesButtonBar.jsx';

const mapStateToProps = state => ({
  copyPaste: state.reducer.copyPaste,
  currentTemplate: state.reducer.userTemplates.currentTemplate,
  selectedDay: state.reducer.scheduleState.selectedDay,
  scheduleSettings: state.reducer.scheduleUIState.settings,
});

const mapDispatchToProps = {
  showConfirmModal,
  deleteSelected,
  copy,
  paste,
  checkIfCanCopy,
  checkIfCanPaste,
  showModal,
};

const TemplatesButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(TemplatesButtonBar);

export default TemplatesButtonBarContainer;
