import { connect } from 'react-redux';

import PermissionWrapper from '@/components/PermissionWrapper.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

const PermissionWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(PermissionWrapper);

export default PermissionWrapperContainer;
