import { LOAN_EMPLOYEES_ENABLE, SMS_NOTIFICATION_SEND } from '@/constants/Permissions';
import { OPTION_NAMES } from '@/constants/settingsConstants';

const notifications = {
  sms: 'sms',
  email: 'email',
  mobile: 'mobile',
};

export const getInitState = userSettings =>
  userSettings?.notifications.reduce(
    (acc, { user_id: _userId, ...cur }) => ({
      ...acc,
      [cur.notification_name]: {
        ...cur,
        email: Boolean(cur.email),
        sms: Boolean(cur.sms),
        mobile: Boolean(cur.mobile),
      },
    }),
    {},
  ) || {};

const headersLabels = {
  [notifications.email]: 'Email',
  label: '',
  [notifications.mobile]: 'Push',
  [notifications.sms]: 'SMS',
};

export const checkIfNotificationSettingShouldBeDisabled = (
  notificationName,
  locationsSettings,
  notificationChannel,
  userRoles,
) => {
  if (notificationChannel === notifications.mobile && userRoles.includes('ROLE_OWNER')) {
    return true;
  }

  const locationsSettingsArray = Object.values(locationsSettings);
  if (notificationName === OPTION_NAMES.APPLY_FOR_OPEN_SHIFT) {
    return locationsSettingsArray.every(locationSetting => !locationSetting.enable_open_shift);
  }
  if (notificationName === OPTION_NAMES.LOCATION_NOT_OPENED) {
    return locationsSettingsArray.every(locationSetting => !locationSetting.location_opening_hours_enabled);
  }

  return false;
};

export const checkIfNotificationShouldBeVisible = (notificationName, userPermissions) => {
  if (
    [OPTION_NAMES.ACCEPT_LOAN_EMPLOYEES_PROPOSAL, OPTION_NAMES.CREATE_LOAN_EMPLOYEES_PROPOSAL].includes(
      notificationName,
    )
  ) {
    return userPermissions.includes(LOAN_EMPLOYEES_ENABLE);
  }
  return true;
};

export const getNotificationSettingsTable = (state, userPermissions, locationsSettings, userRoles) => {
  const { permissions } = userPermissions;
  const settingsHeaders = [notifications.sms, notifications.email, notifications.mobile].filter(headerKey => {
    if (!permissions.includes(SMS_NOTIFICATION_SEND) && headerKey === notifications.sms) {
      return false;
    }

    return true;
  });
  const headers = [headersLabels.label, ...settingsHeaders.map(headerKey => headersLabels[headerKey])];

  const colSizes = ['auto', ...settingsHeaders.map(() => 10)];

  const rows = Object.entries(state).reduce((acc, [notificationName, notificationSettings]) => {
    const { notification_description: notificationDescription, notification_title: notificationTitle } =
      notificationSettings;
    if (notificationDescription && notificationTitle && notificationName) {
      const row = {
        id: notificationName,
        label: notificationTitle,
        description: notificationDescription,
        options: settingsHeaders.map(key => ({
          id: key,
          value: state[notificationName][key],
          disabled: checkIfNotificationSettingShouldBeDisabled(notificationName, locationsSettings, key, userRoles),
        })),
      };
      if (checkIfNotificationShouldBeVisible(notificationName, permissions)) {
        acc.push(row);
      }
    }
    return acc;
  }, []);

  return { colSizes, headers, rows };
};
