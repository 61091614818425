import * as AT from '@/constants/ActionTypes';
import { FilterParamEnum } from '@/constants/ui';
import { EmployeeGroupingStoreState, setSelectedEmployeeGroups } from '@/redux-store/employeeGrouping';
import { ScheduleUIStoreState } from '@/redux-store/scheduleUIState';
import { UiStateStoreState } from '@/redux-store/uiState';
import { EmploymentConditionsFilter } from '@/types/employmentConditions.types';
import { JobTitleFilter } from '@/types/jobTitles.types';
import { MainDateStore } from '@/types/mainDateStore';
import { ScheduleLocationFilter } from '@/types/schedule';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { objectLength } from '@/utils/objectHelpers/objectHelpers';
import { SCHEDULE_PATH } from '@/utils/routes';

import { getSchedulePath } from './schedule/urlModifySchedule';

const ACTION_TYPES_FOR_SAVE_URL = [
  AT.ROUTER_LOCATION_CHANGE,
  AT.CHANGE_SCHEDULE_LOCATION_FILTER,
  AT.CHANGE_JOBTITLE_FILTER_GROUPED,
  AT.CHANGE_EMPLOYMENT_CONDITION_FILTER,
  AT.CHANGE_SCHEDULE_MODE,
  AT.MAIN_DATE_CHANGE_MODE_AND_DATE,
  AT.SET_FILTERS_SUCCESS,
  AT.CHANGE_JOBTITLE_FILTER,
  AT.UPDATE_URL_FILTER_STATUS,
  setSelectedEmployeeGroups.type,
];

export const shouldSaveUrl = (filtersLoaded: UiStateStoreState['filtersLoaded'], actionType: string) =>
  filtersLoaded && ACTION_TYPES_FOR_SAVE_URL.includes(actionType) && window.location.pathname === SCHEDULE_PATH;

export type FilterParamsObject = { [key in FilterParamEnum]?: string | null };

export const createPathFromParamObject = (paramsObject: FilterParamsObject) => {
  const searchParams = Object.entries(paramsObject).reduce((queryPath, [key, value], index) => {
    const delimiter = index + 1 !== objectLength(paramsObject) ? '&' : '';
    return `${queryPath}${key}=${value}${delimiter}`;
  }, '');
  return `${window.location.pathname}?${searchParams.toString()}`;
};

export const formatArrayForUrl = (array: string[]) => (isEmptyArray(array) ? null : array.join(','));

export const getPathName = (
  scheduleLocationFilter: ScheduleLocationFilter,
  scheduleUIStateSelectedDisplayModeType: ScheduleUIStoreState['selectedDisplayMode']['type'],
  jobTitleFilter: JobTitleFilter,
  customDate: MainDateStore['customDate'],
  selectedEmploymentConditions: EmploymentConditionsFilter['selected'],
  selectedGroups: EmployeeGroupingStoreState['selectedGroups'],
) => {
  const { pathname } = window.location;
  if (pathname === SCHEDULE_PATH)
    return getSchedulePath(
      scheduleLocationFilter,
      scheduleUIStateSelectedDisplayModeType,
      jobTitleFilter,
      customDate,
      selectedEmploymentConditions,
      selectedGroups,
    );
};
