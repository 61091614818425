import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDMonthPicker } from '@/components/common/inputs/MDComponents.jsx';

const EmployeesEditMassCycleStartField = ({ value, onChange }) => {
  const fieldValue = value || { month: moment().month() + 1, year: moment().year() };
  return (
    <MDMonthPicker
      onChange={e => onChange(e.target.value)}
      value={fieldValue}
      name="scheduleCycleStart"
      label={
        <FormattedMessage
          id="companymanage.employmentConditions.scheduleCycleStart"
          defaultMessage="Początek okresu rozliczeniowego"
        />
      }
    />
  );
};

EmployeesEditMassCycleStartField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmployeesEditMassCycleStartField;
