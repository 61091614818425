import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { inputValidation } from '@/utils/inputValidation.js';

import AbsenceTypeModalBasic from './AbsenceTypeModalBasic/AbsenceTypeModalBasic.jsx';

const messages = defineMessages({
  absenceTypeModalAddHeader: {
    id: 'absenceTypeModal.add.header',
    defaultMessage: 'Dodaj typ urlopu',
  },
  absenceTypeModalEditHeader: {
    id: 'absenceTypeModal.edit.header',
    defaultMessage: 'Edytuj typ urlopu',
  },
  absenceTypeModalSaveButton: {
    id: 'absenceTypeModal.saveButton',
    defaultMessage: 'Zapisz',
  },
  absenceTypeModalCancelButton: {
    id: 'absenceTypeModal.cancelButton',
    defaultMessage: 'Anuluj',
  },
});

const AbsenceTypeModal = (props, { intl }) => {
  const [state, setState] = useState({
    fullName: '',
    shortName: '',
    code: '',
    hasNote: false,
    addingByEmployee: false,
    requiresApproval: false,
    isPaid: false,
    reduceUwLimit: false,
    definedInHours: false,
    allowAttendance: false,
    hasLimit: false,
    limitUnit: null,
    limitPeriod: false,
    errors: {},
  });

  useEffect(() => {
    const { absenceType } = props.modalObject;
    if (absenceType?.id) {
      setState({
        fullName: absenceType.name,
        shortName: absenceType.short_name,
        code: absenceType.code || '',
        hasNote: absenceType.has_note,
        addingByEmployee: absenceType.adding_by_employee,
        requiresApproval: absenceType.requires_approval,
        isPaid: absenceType.is_paid,
        reduceUwLimit: absenceType.is_default_uw_absence ? true : absenceType.reduce_uw_limit,
        definedInHours: absenceType.defined_in_hours,
        allowAttendance: absenceType.allow_attendance,
        hasLimit: absenceType.is_default_uw_absence ? true : absenceType.has_limit,
        limitUnit: absenceType.limit_unit,
        limitPeriod: absenceType.limit_period,
        errors: {},
      });
    }
  }, [props.modalObject]);

  const validateInput = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    const error = inputValidation(name, value);

    setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [name]: error ? intl.formatMessage(error, {}) : error,
      },
    }));

    return !error;
  };

  const validateAll = () => {
    const inputs = ['fullName', 'shortName'];
    let valid = true;
    for (const input of inputs) {
      const result = validateInput({
        target: {
          name: input,
          value: state[input],
        },
      });
      valid = valid && result;
    }
    return valid;
  };

  const handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    if (state.errors[name]) {
      validateInput(event);
    }

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const hideAndClear = () => {
    setState({
      fullName: '',
      shortName: '',
      code: '',
      hasNote: false,
      addingByEmployee: false,
      requiresApproval: false,
      isPaid: false,
      reduceUwLimit: false,
      definedInHours: false,
      allowAttendance: false,
      hasLimit: false,
      limitUnit: false,
      limitPeriod: null,
      errors: {},
    });
    props.hideModal();
  };

  const handleSubmit = () => {
    const valid = validateAll();
    if (!valid) return;

    const absenceType = {
      name: state.fullName,
      short_name: state.shortName,
      code: state.code,
      has_note: state.hasNote,
      adding_by_employee: state.addingByEmployee,
      requires_approval: state.requiresApproval,
      is_paid: state.isPaid,
      reduce_uw_limit: state.reduceUwLimit,
      defined_in_hours: state.definedInHours,
      allow_attendance: state.allowAttendance,
      has_limit: state.hasLimit,
      limit_unit: state.limitUnit,
      limit_period: state.limitPeriod,
    };

    if (props.modalObject?.absenceType?.id) {
      props.editAbsenceType(props.modalObject.absenceType.id, absenceType);
    } else {
      props.addAbsenceType(absenceType);
    }

    hideAndClear();
  };

  const {
    fullName,
    shortName,
    code,
    hasNote,
    addingByEmployee,
    requiresApproval,
    isPaid,
    reduceUwLimit,
    definedInHours,
    allowAttendance,
    hasLimit,
    limitUnit,
    limitPeriod,
    errors,
  } = state;

  const { absenceType } = props.modalObject;
  const isDisabled = absenceType?.isOvertimeCollection;

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={hideAndClear}
      title={intl.formatMessage(
        props.modalObject?.absenceType ? messages.absenceTypeModalEditHeader : messages.absenceTypeModalAddHeader,
      )}
      modifiers={['narrow']}
      onSubmit={handleSubmit}
      confirmText={intl.formatMessage(messages.absenceTypeModalSaveButton)}
      cancelText={intl.formatMessage(messages.absenceTypeModalCancelButton)}
      disableConfirm={isDisabled}
    >
      <AbsenceTypeModalBasic
        fullName={fullName}
        shortName={shortName}
        code={code}
        hasNote={hasNote}
        addingByEmployee={addingByEmployee}
        requiresApproval={requiresApproval}
        isPaid={isPaid}
        reduceUwLimit={reduceUwLimit}
        definedInHours={definedInHours}
        allowAttendance={allowAttendance}
        hasLimit={hasLimit}
        limitUnit={limitUnit}
        limitPeriod={limitPeriod}
        handleInputChange={handleInputChange}
        validateInput={validateInput}
        errors={errors}
        isDefaultUwAbsence={absenceType?.is_default_uw_absence || false}
        isDisabled={isDisabled}
      />
    </MDKadroModal>
  );
};

AbsenceTypeModal.defaultProps = {
  modalObject: {},
};

AbsenceTypeModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceTypeModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    absenceType: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      short_name: PropTypes.string,
      code: PropTypes.string,
      has_note: PropTypes.bool,
      adding_by_employee: PropTypes.bool,
      requires_approval: PropTypes.bool,
      is_paid: PropTypes.bool,
      reduce_uw_limit: PropTypes.bool,
      defined_in_hours: PropTypes.bool,
      allow_attendance: PropTypes.bool,
      has_limit: PropTypes.bool,
      limit_period: PropTypes.string,
      limit_unit: PropTypes.string,
      is_default_uw_absence: PropTypes.bool,
      isOvertimeCollection: PropTypes.bool,
    }),
  }),
  hideModal: PropTypes.func,
  addAbsenceType: PropTypes.func,
  editAbsenceType: PropTypes.func,
};

export default AbsenceTypeModal;
