import PropTypes from 'prop-types';

import { messages } from './DashboardLocationsHeader.messsages';

import './DashboardLocationsHeader.scss';

const columns = ['numberOfAttendances', 'numberOfLate', 'frequency', 'absences'];

const DashboardLocationsHeader = (props, context) => {
  const { sortColumn, sortDecreasing } = props.locationsSorting;
  return (
    <div className="md-dashboardLocationsItem md-dashboardLocationHeader__tableHeader">
      <div />
      {columns.map(columnName => (
        <div key={columnName} onClick={() => props.setSortingColumn('locations', columnName)}>
          <span>{context.intl.formatMessage(messages[columnName])}</span>
          {sortColumn === columnName &&
            (sortDecreasing ? (
              <i className="material-icons md-dashboardLocationHeader__icon">arrow_drop_down</i>
            ) : (
              <i className="material-icons md-dashboardLocationHeader__icon">arrow_drop_up</i>
            ))}
        </div>
      ))}
    </div>
  );
};

DashboardLocationsHeader.contextTypes = {
  intl: PropTypes.shape({}),
};

DashboardLocationsHeader.propTypes = {
  locationsSorting: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDecreasing: PropTypes.bool,
  }),
};

export default DashboardLocationsHeader;
