import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chartTitle: {
    id: 'attendancesAndAbsencesChart.chartTitle',
    defaultMessage: '{isEcpPayoutSettingsType, select, true {Obecności} other {Zmiany}} i absencje',
  },
  tooltipMessage: {
    id: 'attendancesAndAbsencesChart.tooltipMessage',
    defaultMessage:
      'Przegląd poszczególnych typów zdarzeń, które mają wpływ na czas pracy względem sumarycznej wartości dla danego dnia wg wybranego parametru.',
  },
});
