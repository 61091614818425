import { connect } from 'react-redux';

import { changeReportsJobTitlesFilter } from '@/actions/reports';
import TopBarJobTitleFilter from '@/components/TopBars/filters/TopBarJobTitleFilter/TopBarJobTitleFilter.jsx';

const mapStateToProps = state => ({
  jobtitleFilter: state.reducer.reports.filters.selectedJobTitles,
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  changeJobTitleFilter: changeReportsJobTitlesFilter,
};

const TopBarJobTitleFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarJobTitleFilter);

export default TopBarJobTitleFilterContainer;
