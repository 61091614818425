import { connect } from 'react-redux';

import { startEdit } from '@/actions/companymanage';

import ScheduleTableWeatherPopoverContent from './ScheduleTableWeatherPopoverContent';

const mapDispatchToProps = {
  startEdit,
};

export default connect(null, mapDispatchToProps)(ScheduleTableWeatherPopoverContent);
