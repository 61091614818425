import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { getOS } from '@/utils/baseHelpers';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { findJobTitleForShift, getScheduleShiftTitle } from '@/utils/shiftHelpers';

import ScheduleShiftBlock from '../ScheduleShiftBlock/ScheduleShiftBlock.jsx';
import ScheduleShiftPopup from './ScheduleShiftPopup/ScheduleShiftPopup.redux';

const ScheduleShift = props => {
  const {
    shift,
    employeeId,
    shiftId,
    userJobTitles,
    userLocations,
    currentLocationId,
    handleDeleteAction,
    handleAddAction,
    handleEditAction,
    size,
    startShiftDrag,
    stopShiftDrag,
    employeeContracts,
    isQuickPlanningEnabled,
    isEditDisabled,
    availabilitiesModeEnabled,
    isShiftDragged,
    startSwapping,
    stopSwapping,
    isAddDisabled,
    absencesForDate,
    isAbsenceConnectingWithAnyScheduleBlock,
    isLoanedEmployee,
  } = props;

  if (!shift) return null;
  const shiftJobTitle = useMemo(() => findJobTitleForShift(shift, userJobTitles), [shift.job_title.id, userJobTitles]);
  const missingJobTitle = !shiftJobTitle;

  const jobTitleNotAssigned = useMemo(() => {
    if (employeeContracts.length) {
      const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
      const employeeJobTitleIds = getJobTitleIdsFromContracts([relevantContract]);

      return !employeeJobTitleIds.includes(String(shift.job_title.id));
    }
    return null;
  }, [employeeContracts, shift.job_title.id]);

  const shiftLocation = useMemo(() => {
    if (shift.location.name) return shift.location;
    return userLocations.find(location => String(location.id) === String(shift.location.id));
  }, [userLocations, shift.location.id]);

  const isEditAllowed = !!shiftLocation && !isEditDisabled;
  const color = shiftJobTitle?.color || '#FF0000';
  const hasComment = shift.comment?.length > 0;
  const isFromOtherLocation = shift.location.id !== currentLocationId;
  const isDraft = shift.draft;
  const shiftTitle = missingJobTitle ? (
    <FormattedMessage defaultMessage="-" id="shift.missingJobTitlePlaceholder" />
  ) : (
    getScheduleShiftTitle(shift, userJobTitles)
  );

  const { working_hours: workingHours, date } = shift;
  const [from, to] = workingHours.split('-');
  const onEdit = () => {
    if (isQuickPlanningEnabled) return;
    handleEditAction(shift, !isEditAllowed);
  };

  const onDragEnd = e => {
    const isMacOs = getOS() === 'mac';
    const isSwapButton = isMacOs ? e.metaKey && e.altKey : e.altKey;
    if (isSwapButton) stopSwapping();
    stopShiftDrag();
  };

  const onDragCapture = e => {
    const isMacOs = getOS() === 'mac';
    const isSwapButton = isMacOs ? e.metaKey && e.altKey : e.altKey;
    if (isSwapButton) {
      startSwapping();
    } else {
      stopSwapping();
    }
  };

  const scheduleBlock = (
    <ScheduleShiftBlock
      from={from}
      to={to}
      title={shiftTitle}
      hasComment={hasComment}
      color={color}
      isFromOtherLocation={isFromOtherLocation}
      isInvalid={missingJobTitle || jobTitleNotAssigned}
      isDraft={isDraft}
      isDraggable={isEditAllowed && !isQuickPlanningEnabled && !isLoanedEmployee}
      onDragStart={() => startShiftDrag(shift.id, shift.employee.id, from, to, date)}
      onDragEnd={onDragEnd}
      size={size}
      onDelete={isEditAllowed ? handleDeleteAction : undefined}
      onEdit={isEditAllowed ? onEdit : undefined}
      onAdd={!isLoanedEmployee && handleAddAction}
      onClick={isEditAllowed ? onEdit : undefined}
      isQuickPlanningEnabled={isQuickPlanningEnabled}
      isEditDisabled={isEditDisabled}
      availabilitiesModeEnabled={availabilitiesModeEnabled}
      shiftId={shiftId}
      employeeId={employeeId}
      isShiftDragged={isShiftDragged}
      startSwapping={startSwapping}
      onDragCapture={onDragCapture}
      isAddDisabled={isAddDisabled}
      absencesForDate={absencesForDate}
      shift={shift}
      isAbsenceConnectingWithAnyScheduleBlock={isAbsenceConnectingWithAnyScheduleBlock}
    />
  );

  return (
    <ScheduleShiftPopup
      employeeId={employeeId}
      shiftId={shiftId}
      jobTitle={shiftJobTitle}
      isJobTitleNotAssigned={jobTitleNotAssigned}
      isFromOtherLocation={isFromOtherLocation}
      shiftLocationName={shiftLocation?.name}
      isLoaned={shift.isLoaned}
      isOpenShiftFromLocationGroups={shift.isOpenShiftFromLocationGroups}
    >
      {scheduleBlock}
    </ScheduleShiftPopup>
  );
};

ScheduleShift.defaultProps = {
  employeeContracts: [],
};

ScheduleShift.propTypes = {
  shift: PropTypes.shape({
    id: PropTypes.string,
    employee: PropTypes.shape({
      id: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    working_hours: PropTypes.string,
    draft: PropTypes.bool,
    job_title: PropTypes.shape({
      id: PropTypes.string,
    }),
    date: PropTypes.string,
    comment: PropTypes.string,
    isLoaned: PropTypes.string,
    isOpenShiftFromLocationGroups: PropTypes.bool,
  }),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  employeeId: PropTypes.string,
  shiftId: PropTypes.string,
  currentLocationId: PropTypes.string,
  handleDeleteAction: PropTypes.func,
  handleAddAction: PropTypes.func,
  handleEditAction: PropTypes.func,
  size: PropTypes.string,
  startShiftDrag: PropTypes.func,
  stopShiftDrag: PropTypes.func,
  isQuickPlanningEnabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  availabilitiesModeEnabled: PropTypes.bool,
  isShiftDragged: PropTypes.bool,
  startSwapping: PropTypes.func,
  stopSwapping: PropTypes.func,
  isAddDisabled: PropTypes.bool,
  absencesForDate: PropTypes.arrayOf(PropTypes.shape({})),
  isAbsenceConnectingWithAnyScheduleBlock: PropTypes.bool,
  isLoanedEmployee: PropTypes.bool,
};

export default ScheduleShift;
