import { connect } from 'react-redux';

import { toogleQuickPlanningScheduleMode } from '@/actions/schedule/quickPlanning';

import QuickPlanningToggle from './QuickPlanningToggle.jsx';

const mapStateToProps = state => ({
  isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  restrictions: state.reducer.userPermissions.restrictions,
  viewMode: state.reducer.schedule.viewMode.viewMode,
});

const mapDispatchToProps = {
  toogleQuickPlanningScheduleMode,
};

const ScheduleButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(QuickPlanningToggle);

export default ScheduleButtonBarContainer;
