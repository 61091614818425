import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TimeRangeInput from './inputs/TimeRangeInput.jsx';

class AddAva extends Component {
  constructor(props) {
    super(props);
    this.initialTypes = [
      { id: 'availability', name: 'Dostępny', type: 'availability' },
      { id: 'unavailability', name: 'Niedostępny', type: 'unavailability' },
      { id: 'freetime', name: 'Dostępność w godzinach', type: 'freetime', requires_time: true },
    ];
    this.types = this.initialTypes.concat(props.avaTypes);
    let hours = '';
    let avaType = 0;
    let comment = '';

    if (props.ava && props.ava.availability) {
      if (props.ava.availability.hours) {
        hours = props.ava.availability.hours;
      }
      for (const i in this.types) {
        if (
          (this.types[i].id === props.ava.availability.type_id && props.ava.availability.type === 'custom') ||
          this.types[i].id === props.ava.availability.type
        ) {
          avaType = parseInt(i, 10);
        }
      }
      if (props.ava.availability.optional_comment) {
        comment = props.ava.availability.optional_comment;
      }
    }

    this.state = {
      hours,
      avaType,
      comment,
      showError: false,
    };

    this.formatChar = {
      '9': '[0-9]',
      '5': '[0-5]',
      a: '[A-Za-z]',
      '*': '[A-Za-z0-9]',
    };
    this.listUpdate = false;

    this.selectAvaFunc = this.selectAva.bind(this);
    this.setTimeFunc = this.setTime.bind(this);
    this.showErrorMsgFunc = this.showErrorMsg.bind(this);
    this.setCommentFunc = this.setComment.bind(this);
    this.abortFunc = this.abort.bind(this);
    this.submitFunc = this.submit.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.listUpdate) {
      this.listUpdate = false;
      return;
    }
    this.types = this.initialTypes.concat(nextProps.avaTypes);
    let hours = null;
    let avaType = 0;
    let comment = '';

    if (nextProps.ava) {
      if (nextProps.ava.availability) {
        if (nextProps.ava.availability.hours) {
          hours = nextProps.ava.availability.hours;
        }
        for (const i in this.types) {
          if (
            this.types[i].id === nextProps.ava.availability.type_id ||
            this.types[i].id === nextProps.ava.availability.type
          ) {
            avaType = parseInt(i, 10);
          }
        }
        if (nextProps.ava.availability.optional_comment) {
          comment = nextProps.ava.availability.optional_comment;
        }
      }

      this.setState({ hours, avaType, comment });
    }
  }

  setTime(e, altInp) {
    let val;
    if (e == null) {
      val = altInp;
    } else {
      val = e.target.value;
    }

    this.setState({ hours: val }, () => {
      if (this.state.showError) this.showErrorMsg();
    });
  }
  setComment(e) {
    this.setState({ comment: e.target.value });
  }
  selectAva(e) {
    this.listUpdate = true;
    this.setState({ avaType: e.target.value });
  }
  submit() {
    let { hours } = this.state;
    const index = this.state.avaType;
    const availabilityType = this.types[index];
    if (availabilityType.requires_time && this.showErrorMsg()) return;
    let requiresApproval = false;
    let comment = '';
    if (availabilityType.optional_comment) {
      comment = this.state.comment;
    }

    if (!availabilityType.requires_time) hours = null;

    if (!availabilityType.requiresApproval) requiresApproval = true;
    const ava = {
      hours,
      optional_comment: comment,
      requiresApproval,
      type: this.state.avaType < 3 ? this.initialTypes[this.state.avaType].type : 'custom',
      type_id: this.state.avaType > 2 ? this.props.avaTypes[this.state.avaType - 3].id : 38,
    };
    this.props.submit(ava);
    this.clear();
  }

  clear() {
    this.setState({
      avaType: 0,
      comment: null,
    });
  }
  abort() {
    this.props.abort();
    this.clear();
  }

  validateTime() {
    if (!this.state.hours || this.state.hours.search('_') > 0) return false;
    return true;
  }
  showErrorMsg() {
    const validation = !this.validateTime();
    if (validation) {
      this.setState({ showError: true });
      return true;
    }
    if (this.state.showError) {
      this.setState({ showError: false });
    }
    return false;
  }
  render() {
    const types = this.initialTypes.concat(this.props.avaTypes);

    return (
      <div className="calendar-list-options" style={{ display: this.props.display ? 'block' : 'none' }}>
        <div className="calendar-list-options-content">
          <div className="mobile-input-header" style={{ marginTop: '10px' }}>
            <FormattedMessage id="common.addAvailabilityModal.header" defaultMessage="Typ dostępności" />
          </div>
          <select className="select-styled mobile-input" value={this.state.avaType} onChange={this.selectAvaFunc}>
            {types.map((type, i) => (
              <option key={i} value={i}>
                {type.name}
              </option>
            ))}
          </select>
          <div
            style={{
              display: types[this.state.avaType].requires_time ? 'block' : 'none',
            }}
          >
            <div className="mobile-input-header">
              <FormattedMessage id="common.addAvailabilityModal.hours" defaultMessage="w godzinach" />
            </div>
            <TimeRangeInput
              className="mobile-input"
              onChange={this.setTimeFunc}
              onBlur={this.showErrorMsgFunc}
              value={this.state.hours}
            />
            {this.state.showError ? (
              <div className="form-error">
                <FormattedMessage
                  id="common.addAvailabilityModal.errorHours"
                  defaultMessage="Proszę podać zakres czasu"
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: types[this.state.avaType].optional_comment ? 'block' : 'none',
            }}
          >
            <div className="mobile-input-header">
              <FormattedMessage id="common.addAvailabilityModal.comment" defaultMessage="komentarz" />
            </div>
            <textarea
              className="mobile-input form-control"
              rows="4"
              value={this.state.comment}
              id="comment"
              onChange={this.setCommentFunc}
              style={{ height: 'auto' }}
            />
          </div>
        </div>
        <div className="calendar-list-option-buttons">
          <button className="calendar-ava-button-del" onClick={this.abortFunc}>
            <FormattedMessage id="common.addAvailabilityModal.cancel" defaultMessage="Anuluj" />
          </button>
          <button className="calendar-ava-button-edit" onClick={this.submitFunc}>
            <FormattedMessage id="common.addAvailabilityModal.save" defaultMessage="Zapisz" />
          </button>
        </div>
      </div>
    );
  }
}

AddAva.propTypes = {
  avaTypes: PropTypes.arrayOf(PropTypes.shape({})),
  ava: PropTypes.shape({}),
  submit: PropTypes.func,
  abort: PropTypes.func,
  display: PropTypes.bool,
};

export default AddAva;
