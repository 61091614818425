import { PERMISSIONS, RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import ImportShiftsAndAttendancesModal from '@/components/scheduler/modals/ImportShiftsAndAttendancesModal/ImportShiftsAndAttendancesModal.redux.js';
import { SHIFTS_AND_ATTENDANCES_IMPORT_MODAL } from '@/constants/modalTypes';
import { ENOVA_INTEGRATION } from '@/constants/Permissions.js';
import PayrollSettingsModal from '@/containers/payroll/PayrollSettingsModalContainer.js';
import ExportModal from '@/containers/payrollLocation/ExportPayrollLocationModalContainer.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import {
  getJobTitleIdsFromContracts,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts';
import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getPayrollLocationData } from '@/utils/getPayrollLocationData.js';
import { columnSorting } from '@/utils/payroll/payrollColumnSorting';
import { displayPlannedDiff, getPayrollData } from '@/utils/payrollHelpers.jsx';
import { stripEmployeeForTransit } from '@/utils/userEmployeesHelpers.js';

import HelpModal from './PayrollLocationHelpModal.jsx';
import PayrollLocationSummaryRow from './PayrollLocationSummaryRow.jsx';
import PayrollLocationTable from './PayrollLocationTable.jsx';
import PayrollLocationTableRow from './PayrollLocationTableRow.jsx';

const messages = defineMessages({
  emptyStateFilterTitle: {
    id: 'companyManage.payrollLocationTableView.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateSearchStringText: {
    id: 'attendance.payrollLocationTableView.emptyStateSearchStringText',
    defaultMessage:
      'Wybierz pracowników, lokalizacje oraz stanowiska dla których zostanie wyświetlone zestawienie lub ',
  },
  ctaSearchStringText: {
    id: 'companyManage.payrollLocationTableView.ctaSearchStringText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
  emptyFiltersText: {
    id: 'companyManage.payrollLocationTableView.emptyFiltersText',
    defaultMessage:
      'Sprawdź czy są zaznaczone lokalizacje, stanowiska i warunki zatrudnienia lub ',
  },
  ctaEmptyFiltersText: {
    id: 'companyManage.payrollLocationTableView.ctaEmptyFiltersText',
    defaultMessage: 'kliknij tutaj, aby zaznaczyć wszystkie.',
  },
});

class PayrollLocationTableView extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.relevantEmployeeRows = [];
    this.rowMatchesSearchWord = this.rowMatchesSearchWord.bind(this);
    this.getOvertime = this.getOvertime.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }

  getOvertime(relevantEmployees) {
    const { mainDateStore } = this.props;
    const from = mainDateStore.dateArray[0];
    const to = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    this.props.getAllOvertimeData(
      relevantEmployees.map((i) => i.employee.id),
      from,
      to
    );
    if (this.actionButton) this.actionButton.hide();
  }

  rowMatchesSearchWord(row) {
    const phrase = this.props.searchString.toLowerCase();
    return (
      row.employee.first_name +
      row.employee.last_name +
      String(row.relevantRowInfo.sumBonuses) +
      String(row.relevantRowInfo.sumPayout) +
      parseMinutesToFormat(
        row.relevantRowInfo.sumHours,
        this.props.payrollSettings.timeFormatSetting.type
      ) +
      row.relevantRowInfo.details.reduce(
        (prev, detail) => prev + detail.wage + detail.jobTitle,
        ''
      )
    )
      .toLowerCase()
      .includes(phrase);
  }

  render() {
    const {
      payrollSettings,
      userEmployees,
      multipleLocationFilter,
      mainDateStore,
      selectedJobtitles,
      listsUi,
      uiState,
      userCustomTypes,
      employmentConditionsFilter,
      searchString,
      visibleLocationColumns,
      overtimeCollections,
      absences,
      absenceTypes,
      calculateSpmhBasedBonuses,
      increaseLoaderCounter,
      decreaseLoaderCounter,
      exportEnovaAttendances,
      exportEnovaScheduleWork,
      showUnpaidAbsences,
      showModal,
      contracts,
    } = this.props;

    increaseLoaderCounter('blocking');

    const {
      relevantEmployeeRows,
      totalHours,
      totalHoursReal,
      totalHoursPlanned,
      totalBonuses,
      totalPayout,
      totalNightHours,
      totalOvertime50,
      totalOvertime100,
      totalAbsences,
    } = getPayrollLocationData(
      userEmployees,
      payrollSettings,
      mainDateStore,
      multipleLocationFilter,
      selectedJobtitles,
      userCustomTypes,
      this.rowMatchesSearchWord,
      employmentConditionsFilter.selected,
      overtimeCollections,
      absences,
      absenceTypes,
      { showUnpaidAbsences }
    );

    this.relevantEmployeeRows = relevantEmployeeRows.sort(
      columnSorting(
        listsUi.payrollLocation.sortColumn,
        listsUi.payrollLocation.sortDirection,
        uiState.sortingUseLastName
      )
    );

    const sumedScheduleCycleOvertimeMinutes = this.relevantEmployeeRows.reduce(
      (sum, row) => {
        const { employee } = row;
        const { overtime } = employee.overtimeStats;
        if (overtime) {
          return sum + overtime.scheduleCycleOvertime;
        }
        return sum;
      },
      0
    );

    const sumedScheduleCycleOvertime = parseMinutesToFormat(
      sumedScheduleCycleOvertimeMinutes,
      payrollSettings.timeFormatSetting.type
    );
    const sumedHours = parseMinutesToFormat(
      totalHours,
      payrollSettings.timeFormatSetting.type
    );
    const sumedPlannedDiff = displayPlannedDiff(
      totalHoursReal,
      totalHoursPlanned,
      this.props.payrollSettings.timeFormatSetting.type
    );
    const sumedHoursReal = parseMinutesToFormat(
      totalHoursReal,
      this.props.payrollSettings.timeFormatSetting.type
    );
    const sumedHoursPlanned = parseMinutesToFormat(
      totalHoursPlanned,
      this.props.payrollSettings.timeFormatSetting.type
    );
    const sumedNightHours = parseMinutesToFormat(
      totalNightHours,
      payrollSettings.timeFormatSetting.type
    );
    const sumedBonuses = totalBonuses;
    const sumedPayout = roundToTwoSigDigits(totalPayout);
    const sumedOvertime50 = parseMinutesToFormat(
      totalOvertime50,
      payrollSettings.timeFormatSetting.type
    );
    const sumedOvertime100 = parseMinutesToFormat(
      totalOvertime100,
      payrollSettings.timeFormatSetting.type
    );
    const sumAbsences = parseMinutesToFormat(
      totalAbsences,
      payrollSettings.timeFormatSetting.type
    );
    // All the necessary data needed for a proper export.
    const { selectedEmployees } = this.props.payrollUI;
    const shouldExportMultipleFiles = (format) =>
      selectedEmployees.length > 1 &&
      ((format.text === 'XLS' && !format.subtitle) || format.text === 'PDF');
    const exportRawData = (format) => {
      if (format.subtitle === 'R2Płatnik') {
        return {
          type: 'payrollLocation',
          userEmployees,
          multipleLocationFilter,
          selectedJobtitles,
          employmentConditionsFilter: employmentConditionsFilter.selected,
          absences,
          mainDateStore,
          payrollSettings,
        };
      }

      const filteredRelevantRows = selectedEmployees.length
        ? this.relevantEmployeeRows.filter((row) =>
            selectedEmployees.includes(row.employee.id)
          )
        : this.relevantEmployeeRows;
      if (shouldExportMultipleFiles(format) && format.text === 'XLS') {
        return {
          type: 'payroll-multiple',
          employeeData: filteredRelevantRows.map((row) => ({
            employee: row.employee,
            relevantRows: [row.relevantRowInfo],
            sumHours: row.relevantRowInfo.sumHours,
            sumBonuses: row.relevantRowInfo.sumBonuses,
            sumNightHours: row.relevantRowInfo.sumNightHours,
            sumPayout: row.relevantRowInfo.sumPayout,
            sumAbsences: row.relevantRowInfo.sumAbsences,
          })),
        };
      }
      if (!shouldExportMultipleFiles(format) && format.subtitle !== 'Sage') {
        return {
          type: 'payrollLocation',
          totalHours,
          totalBonuses,
          totalPayout,
          totalNightHours,
          totalHoursReal,
          totalHoursPlanned,
          totalOvertime50,
          totalOvertime100,
          totalAbsences,
          relevantEmployeeRows: filteredRelevantRows,
        };
      }

      const employeeData = [];
      let relevantEmployees = this.props.userEmployees;
      relevantEmployees = relevantEmployees.filter((e) => {
        const employeeContracts = contracts[e.id];
        const relevantContracts = getRelevantContractsForMultipleDates(
          employeeContracts,
          mainDateStore.customDate.start,
          mainDateStore.customDate.end
        );
        const employeeJobTitleIds =
          getJobTitleIdsFromContracts(relevantContracts);
        return (
          e.locations.some((l) => multipleLocationFilter.includes(l.id)) &&
          employeeJobTitleIds.some((jobTitleId) =>
            selectedJobtitles.map((i) => i.id).includes(jobTitleId)
          )
        );
      });
      if (selectedEmployees.length)
        relevantEmployees = relevantEmployees.filter((e) =>
          selectedEmployees.includes(e.id)
        );
      const isSageExport = format.subtitle === 'Sage';
      relevantEmployees.forEach((employee) => {
        const {
          relevantRows,
          sumHours,
          sumBonuses,
          sumPayout,
          sumNightHours,
          sumHoursReal,
          sumAbsences: employeeSumAbsences,
        } = getPayrollData(
          employee,
          payrollSettings,
          mainDateStore,
          multipleLocationFilter,
          userCustomTypes,
          {
            addAvailabilityTimestamps: isSageExport,
            jobTitlesToFilterWith: isSageExport ? selectedJobtitles : null,
          },
          {},
          absences,
          absenceTypes
        );
        employeeData.push({
          employee: stripEmployeeForTransit(employee),
          relevantRows,
          sumHours,
          sumHoursReal,
          sumBonuses,
          sumNightHours,
          sumPayout,
          sumAbsences: employeeSumAbsences,
        });
      });

      return {
        type: shouldExportMultipleFiles(format)
          ? 'payroll-multiple'
          : 'payrollLocation',
        employeeData,
      };
    };

    const selectedColumns = [
      { id: 0, columns: [0] },
      ...visibleLocationColumns,
    ];

    let visibleColumnsIndexes = [];
    selectedColumns.forEach((option) => {
      visibleColumnsIndexes = [...visibleColumnsIndexes, ...option.columns];
    });
    const employeeIds = this.relevantEmployeeRows.map((e) => e.employee.id);

    decreaseLoaderCounter('blocking');

    return (
      <div className="k-wrapper k-wrapper--searchBar animated fadeInRight">
        <HelpModal />
        <PayrollSettingsModal />
        <ImportShiftsAndAttendancesModal />
        <ButtonBar>
          <ActionsButton
            icon={<Icon name="more_vert" />}
            ref={(ref) => {
              this.actionButton = ref;
            }}
          >
            <FeatureWrapper restriction={RESTRICTIONS.BUDGET_INFO_HIDE}>
              <button
                className="k-actionsButton__element"
                onClick={this.props.toggleExportModal}
                disabled={this.relevantEmployeeRows.length < 1}
              >
                <FormattedMessage
                  id="export.toFile"
                  defaultMessage="Eksportuj do pliku"
                />
              </button>
            </FeatureWrapper>
            <FeatureWrapper permission={ENOVA_INTEGRATION}>
              <button
                className="k-actionsButton__element"
                onClick={() => exportEnovaAttendances(employeeIds)}
              >
                <FormattedMessage
                  id="export.enovaAttendances"
                  defaultMessage="Eksportuj czas pracy"
                />
              </button>
            </FeatureWrapper>
            <FeatureWrapper permission={ENOVA_INTEGRATION}>
              <button
                className="k-actionsButton__element"
                onClick={() => exportEnovaScheduleWork(employeeIds)}
              >
                <FormattedMessage
                  id="export.enovaSchedule"
                  defaultMessage="Eksportuj grafik pracy"
                />
              </button>
            </FeatureWrapper>
            <button
              className="k-actionsButton__element"
              onClick={() => this.getOvertime(relevantEmployeeRows)}
            >
              <FormattedMessage
                id="payrollLocation.calculateOvertimeSummary"
                defaultMessage="Oblicz nadgodziny"
              />
            </button>
            <FeatureWrapper permission={PERMISSIONS.CALCULATE_SPMH_BASED_BONUS}>
              <button
                className="k-actionsButton__element"
                onClick={() => calculateSpmhBasedBonuses(employeeIds)}
                disabled={this.relevantEmployeeRows.length < 1}
              >
                <FormattedMessage
                  id="export.calculateSpmhBasedBonuses"
                  defaultMessage="Oblicz bonusy"
                />
              </button>
            </FeatureWrapper>
            <FeatureWrapper>
              <button
                className="k-actionsButton__element"
                onClick={() => showModal(SHIFTS_AND_ATTENDANCES_IMPORT_MODAL)}
                permission={PERMISSIONS.IMPORT_SHIFTS_AND_ATTENDANCES}
              >
                <FormattedMessage
                  id="export.importWorkingHours"
                  defaultMessage="Importuj godziny pracy"
                />
              </button>
            </FeatureWrapper>
            <button
              className="k-actionsButton__element"
              onClick={this.props.togglePayrollSettingsModal}
            >
              <FormattedMessage id="settings" defaultMessage="Ustawienia" />
            </button>
          </ActionsButton>
        </ButtonBar>
        <ExportModal
          exportRawData={exportRawData}
          shouldExportMultipleFiles={shouldExportMultipleFiles}
        />
        <PayrollLocationTable
          changeSorting={this.props.changeSorting}
          sortingData={this.props.listsUi.payrollLocation}
          setSelected={this.props.payrollLocationSetSelected}
          employeesIds={employeeIds}
          payrollUI={this.props.payrollUI}
          visibleLocationColumns={visibleColumnsIndexes}
        >
          {!this.relevantEmployeeRows.length ? (
            <tr>
              <td
                className="no-content-to-show"
                colSpan={selectedColumns.length}
              >
                <EmptyState
                  name="payrollCTA"
                  imgSrc="/img/payrollCTA.png"
                  emptyStateHeader={this.context.intl.formatMessage(
                    messages.emptyStateFilterTitle,
                    {}
                  )}
                  emptyStateText={
                    searchString
                      ? this.context.intl.formatMessage(
                          messages.emptyStateSearchStringText,
                          {}
                        )
                      : this.context.intl.formatMessage(
                          messages.emptyFiltersText,
                          {}
                        )
                  }
                  ctaHandler={
                    searchString
                      ? this.props.clearSearchWord
                      : this.props.selectAllJobTitlesAndEmploymentConditions
                  }
                  ctaText={
                    searchString
                      ? this.context.intl.formatMessage(
                          messages.ctaSearchStringText,
                          {}
                        )
                      : this.context.intl.formatMessage(
                          messages.ctaEmptyFiltersText,
                          {}
                        )
                  }
                />
              </td>
            </tr>
          ) : (
            this.relevantEmployeeRows.map((rowObject) => (
              <PayrollLocationTableRow
                visibleLocationColumns={visibleColumnsIndexes}
                key={rowObject.employee.id}
                employee={rowObject.employee}
                userLocations={this.props.userLocations}
                relevantRowInfo={rowObject.relevantRowInfo}
                addBonusToAttendance={this.props.addBonusToAttendance}
                toggleSelected={this.props.payrollLocationToggleSelected}
                payrollUI={this.props.payrollUI}
                payrollSettings={this.props.payrollSettings}
                lastNameFirst={uiState.sortingUseLastName}
              />
            ))
          )}
          <PayrollLocationSummaryRow
            visibleColumns={visibleColumnsIndexes}
            sumHours={sumedHours}
            sumHoursReal={sumedHoursReal}
            sumHoursPlanned={sumedHoursPlanned}
            sumPlannedDiff={sumedPlannedDiff}
            sumNightHours={sumedNightHours}
            sumBonuses={sumedBonuses}
            sumPayout={sumedPayout}
            sumOvertime50={sumedOvertime50}
            sumOvertime100={sumedOvertime100}
            sumedScheduleCycleOvertime={sumedScheduleCycleOvertime}
            sumAbsences={sumAbsences}
          />
        </PayrollLocationTable>
      </div>
    );
  }
}

PayrollLocationTableView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollLocationTableView.propTypes = {
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  toggleExportModal: PropTypes.func,
  payrollSettings: PropTypes.shape({
    timeFormatSetting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  uiState: PropTypes.shape({
    sortingUseLastName: PropTypes.bool,
  }),
  listsUi: PropTypes.shape({
    payrollLocation: PropTypes.shape({
      sortColumn: PropTypes.string,
      sortDirection: PropTypes.number,
    }),
  }),
  selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
  payrollUI: PropTypes.shape({
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  visibleLocationColumns: PropTypes.arrayOf(PropTypes.shape({})),
  addBonusToAttendance: PropTypes.func,
  changeSorting: PropTypes.func,
  togglePayrollSettingsModal: PropTypes.func,
  payrollLocationSetSelected: PropTypes.func,
  payrollLocationToggleSelected: PropTypes.func,
  getAllOvertimeData: PropTypes.func,
  clearSearchWord: PropTypes.func,
  selectAllJobTitlesAndEmploymentConditions: PropTypes.func,
  searchString: PropTypes.string,
  overtimeCollections: PropTypes.shape({}),
  absences: PropTypes.shape({}),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  calculateSpmhBasedBonuses: PropTypes.func,
  increaseLoaderCounter: PropTypes.func,
  decreaseLoaderCounter: PropTypes.func,
  exportEnovaAttendances: PropTypes.func,
  exportEnovaScheduleWork: PropTypes.func,
  showUnpaidAbsences: PropTypes.bool,
  showModal: PropTypes.func,
};

export default PayrollLocationTableView;
