import { EmployeeWhole, Location } from '@/types';
import { MainDateStore } from '@/types/mainDateStore';
import { LoanedEmployeesData } from '@/types/scheduleLoanedEmployees';

export const checkIsEmployeeLoaned = (
  loanedEmployees: LoanedEmployeesData,
  employeeId: EmployeeWhole['id'],
  locationId: Location['id'],
  dateArray: MainDateStore['dateArray'],
) => {
  const employee = loanedEmployees[employeeId];
  if (!employee) return false;
  return employee.shiftsData.some(
    shift => shift.isLoaned && shift.location.id === locationId && dateArray.includes(shift.date),
  );
};
