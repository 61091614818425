import { defineMessages } from 'react-intl';

import { ABSENCES_LEAVE_REQUEST_VIEW_HIDE, ABSENCES_LIMIT_VIEW_HIDE } from '@/constants/Restrictions';

const messages = defineMessages({
  requestView: {
    id: 'absences.requestView',
    defaultMessage: 'Widok wniosków',
  },
  limitsView: {
    id: 'absences.limitsView',
    defaultMessage: 'Widok limitów',
  },
});

export const absencesDisplayModes = (restrictions = []) =>
  [
    {
      id: 0,
      type: 'proposals',
      displayText: messages.requestView,
      disabled: restrictions.includes(ABSENCES_LEAVE_REQUEST_VIEW_HIDE),
    },
    {
      id: 1,
      type: 'limits',
      displayText: messages.limitsView,
      disabled: restrictions.includes(ABSENCES_LIMIT_VIEW_HIDE),
    },
  ].filter(({ disabled }) => !disabled);
