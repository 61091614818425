import PropTypes from 'prop-types';
import { useRef } from 'react';
import { defineMessages,FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';

import './AutoGenerateCreateScheduleHeader.scss';

const messages = defineMessages({
  fileTooBig: {
    id: 'autoSchedule.fileSizeToBig',
    defaultMessage: 'Plik jest zbyt duży ({maxSize} MB max.)',
  },
  badFormat: {
    id: 'autoSchedule.badFormat',
    defaultMessage: 'Nieobsługiwany typ pliku!',
  },
});

const AutoGenerateCreateScheduleHeader = (props, { intl }) => {
  const { maxSize, exportTemplate, importScheduleTemplate, autoScheduleImportError } = props;

  const fileInputRef = useRef(null);
  const acceptFileType = '.xls';

  const chooseFile = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFile = files => {
    const newFile = files[0];
    if (!newFile) {
      return;
    }
    const extension = `.${newFile.name.split('.').pop()}`;

    if (maxSize && newFile.size > maxSize * 1000000) {
      console.error('File size is too big');
      autoScheduleImportError(intl.formatMessage(messages.fileTooBig, { maxSize }));
    } else if (!acceptFileType || acceptFileType === extension) {
      importScheduleTemplate(newFile);
    } else {
      console.error(`Bad file extension ${extension}`);
      autoScheduleImportError(intl.formatMessage(messages.badFormat));
    }
  };

  const fileChange = e => {
    handleFile(e.target.files);
    e.target.value = null;
  };

  return (
    <div className="k-autoGenerateCreateScheduleHeader">
      <div className="k-autoGenerateCreateScheduleHeader__text">
        <FormattedMessage id="autogenerate.createSchedule.title" defaultMessage="Stwórz nowy szablon">
          {text => <h4 className="k-autoGenerateCreateScheduleHeader__title">{text}</h4>}
        </FormattedMessage>
        <FormattedMessage
          id="autogenerate.createSchedule.description"
          defaultMessage="Podaj ilości pracowników w danych  godzinach, dla których chcesz ułożyć grafik."
        >
          {text => <p className="k-autoGenerateCreateScheduleHeader__description">{text}</p>}
        </FormattedMessage>
      </div>
      <div className="k-autoGenerateCreateScheduleHeader__buttons-container">
        <Button
          className="k-autoGenerateCreateScheduleHeader__button"
          onClick={() => exportTemplate()}
          modifiers={['reverse-orange', 'teeny']}
        >
          <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />
        </Button>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={fileChange} accept=".xls" />
        <Button
          className="k-autoGenerateCreateScheduleHeader__button"
          onClick={chooseFile}
          modifiers={['reverse-orange', 'teeny']}
        >
          <FormattedMessage id="import" defaultMessage="Importuj" />
        </Button>
      </div>
    </div>
  );
};

AutoGenerateCreateScheduleHeader.contextTypes = {
  intl: PropTypes.shape({}),
};

AutoGenerateCreateScheduleHeader.propTypes = {
  maxSize: PropTypes.number,
  exportTemplate: PropTypes.func,
  importScheduleTemplate: PropTypes.func,
  autoScheduleImportError: PropTypes.func,
};

export default AutoGenerateCreateScheduleHeader;
