import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents';

import { appOptions, messages } from './OnboardingAppPurposeHelper';

import './OnboardingAppPurpose.scss';

const OnboardingAppPurpose = (props, context) => {
  const { purposes, handleInputChange, errorMessage } = props;

  const changePurposes = id => {
    const newPurposes = purposes.includes(id) ? purposes.filter(p => p !== id) : [...purposes, id];
    handleInputChange({ target: { value: newPurposes, name: 'purposes' } });
  };

  return (
    <>
      <div className="k-onboardingAppPurpose__text k-onboardingAppPurpose__text--desktop">
        <FormattedMessage
          id="onboarding.secondStep.appPurpose"
          defaultMessage="Do jakich celów będzie używana aplikacja {app}?"
          values={{
            app: <span className="k-onboardingAppPurpose__text--highlight">Kadromierz</span>,
          }}
        />
      </div>
      <div className="k-onboardingAppPurpose__text k-onboardingAppPurpose__text--mobile">
        <FormattedMessage
          id="onboarding.secondStep.appPurposeMobile"
          defaultMessage="Co jest dla Ciebie najważniejsze w aplikacji {app}?"
          values={{
            app: <span className="k-onboardingAppPurpose__text--highlight">Kadromierz</span>,
          }}
        />
      </div>
      <div className="k-onboardingAppPurpose__options">
        {appOptions.map(({ id, icon }) => (
          <MDCheckbox
            key={id}
            id={id}
            value={purposes.includes(id)}
            onChange={() => changePurposes(id)}
            testId={`purpose-${id}`}
            reverse
            text={
              <div className="k-onboardingAppPurpose__item">
                <i className="k-onboardingAppPurpose__icon material-icons">{icon}</i>
                {context.intl.formatMessage(messages[id])}
              </div>
            }
          />
        ))}
      </div>
      {errorMessage && <div className="k-onboardingAppPurpose__errorMessage">{errorMessage}</div>}
    </>
  );
};

OnboardingAppPurpose.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

OnboardingAppPurpose.propTypes = {
  purposes: PropTypes.arrayOf(PropTypes.string),
  handleInputChange: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default OnboardingAppPurpose;
