import { connect } from 'react-redux';

import { changeScheduleMode } from '@/actions/schedule';
import { hideModal } from '@/actions/uiState.js';

import AutoGenerateFlowPicker from './AutoGenerateFlowPicker';

const mapStateToProps = state => ({
  templates: state.reducer.userTemplates.templatesArray,
});

const mapDispatchToProps = {
  changeScheduleMode,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoGenerateFlowPicker);
