import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { EMPLOYEE_SHIFT_MODAL } from '@/constants/modalTypes';

const EmployeeTimesheetButtonBar = ({ showModal }) => (
  <Button modifiers={['orange', 'medium']} onClick={() => showModal(EMPLOYEE_SHIFT_MODAL)}>
    <span className="material-icons">add</span>
    <FormattedMessage id="employeeTimesheet.headerOptions.addWorkTime" defaultMessage="Dodaj czas pracy" />
  </Button>
);

EmployeeTimesheetButtonBar.propTypes = {
  showModal: PropTypes.func,
};

export default EmployeeTimesheetButtonBar;
