import { IntlShape } from 'react-intl';

import { ReportStatisticsForPeriod } from '@/types/reports.types';
import { numberWithSpaces, roundToTwoSigDigits } from '@/utils/baseHelpers';
import { convertDateToCustomFormat, getPreviousPeriod, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { calculateIndicator } from '@/utils/reports';

import { messages } from './messages';

type FormattedValues = {
  workedMinutesValue: string;
  activeUsersValue: string;
  payoutSumValue: string;
};

type Indicators = {
  absencesIndicator: number;
  activeUsersIndicator: number;
  workedMinutesIndicator: number;
  payoutSumIndicator: number;
};

export const formatWorkedHours = (
  currentWorkedMinutes: number,
  currentPlannedMinutes: number,
  isEcpPayoutSettingsType: boolean,
): string => {
  const workedHours = parseMinutesToHumanForm(currentWorkedMinutes);
  const plannedWorkedHours = parseMinutesToHumanForm(currentPlannedMinutes);
  return isEcpPayoutSettingsType ? `${workedHours}/${plannedWorkedHours}` : plannedWorkedHours;
};

export const formatActiveUsers = (
  currentActiveUsers: number,
  currentPlannedActiveUsers: number,
  isEcpPayoutSettingsType: boolean,
): string =>
  isEcpPayoutSettingsType ? `${currentActiveUsers}/${currentPlannedActiveUsers}` : String(currentPlannedActiveUsers);

export const formatPayoutSum = (plannedPayoutSum: number, payoutSum: number, isEcpPayout: boolean): string => {
  const formattedPlannedPayout = numberWithSpaces(roundToTwoSigDigits(plannedPayoutSum / 100));
  const formattedPayout = numberWithSpaces(roundToTwoSigDigits(payoutSum / 100));

  return isEcpPayout ? `${formattedPayout}/${formattedPlannedPayout} PLN` : `${formattedPlannedPayout} PLN`;
};

export const getIndicator = (currentValue: number, previousValue: number): number =>
  calculateIndicator(currentValue, previousValue);

export const getIndicatorTooltipContent = (start: string, end: string, intl: IntlShape): string => {
  const { previousFrom, previousTo } = getPreviousPeriod(start, end);
  const isOneDay = previousFrom === previousTo;
  return intl.formatMessage(messages.indicatorTooltipContent, {
    isOneDay,
    previousFrom: convertDateToCustomFormat(previousFrom, 'YYYY-MM-DD'),
    previousTo: convertDateToCustomFormat(previousTo, 'YYYY-MM-DD'),
  });
};

export const computeFormattedValues = (
  currentPeriod: ReportStatisticsForPeriod,
  isEcpPayoutSettingsType: boolean,
): FormattedValues => {
  const workedMinutesValue = formatWorkedHours(
    currentPeriod.workedMinutes,
    currentPeriod.plannedMinutes,
    isEcpPayoutSettingsType,
  );

  const activeUsersValue = formatActiveUsers(
    currentPeriod.activeUsers,
    currentPeriod.plannedActiveUsers,
    isEcpPayoutSettingsType,
  );

  const payoutSumValue = formatPayoutSum(
    currentPeriod.plannedPayoutSum,
    currentPeriod.payoutSum,
    isEcpPayoutSettingsType,
  );

  return { workedMinutesValue, activeUsersValue, payoutSumValue };
};

export const computeIndicators = (
  currentPeriod: ReportStatisticsForPeriod,
  previousPeriod: ReportStatisticsForPeriod,
  isEcpPayoutSettingsType: boolean,
): Indicators => {
  const absencesIndicator = getIndicator(currentPeriod.absences, previousPeriod.absences);

  const activeUsersIndicator = isEcpPayoutSettingsType
    ? getIndicator(currentPeriod.activeUsers, previousPeriod.activeUsers)
    : getIndicator(currentPeriod.plannedActiveUsers, previousPeriod.plannedActiveUsers);

  const workedMinutesIndicator = isEcpPayoutSettingsType
    ? getIndicator(currentPeriod.workedMinutes, previousPeriod.workedMinutes)
    : getIndicator(currentPeriod.plannedMinutes, previousPeriod.plannedMinutes);

  const payoutSumIndicator = isEcpPayoutSettingsType
    ? getIndicator(currentPeriod.payoutSum, previousPeriod.payoutSum)
    : getIndicator(currentPeriod.plannedPayoutSum, previousPeriod.plannedPayoutSum);

  return {
    absencesIndicator,
    activeUsersIndicator,
    workedMinutesIndicator,
    payoutSumIndicator,
  };
};
