import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import EmptyState from '@/components/emptyState/EmptyState';
import { CHART_MODIFIER } from '@/constants/modifiers';
import { PayoutSettingType } from '@/constants/payrollSettings';
import {
  ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_METADATA,
  ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_MODES,
  MINUTES,
  STROKE_DASHARRAY,
} from '@/constants/reports';
import { ContextWithIntl } from '@/types/intl.types';
import { formatDecimalAsPercent } from '@/utils/baseHelpers';
import { createFormattersForSelectedChartType } from '@/utils/reports';

import ChartHeader from '../../chartHeader/ChartHeader';
import { messages as chartsMessages } from '../charts.messages';
import { messages } from './absencesAndAttendancesOrShiftsChart.messages';
import { formatDataToChart, getCustomLegend } from './attendancesAndAbsencesChart.helpers';
import TooltipContent from './TooltipContent/TooltipContent';
import { useAbsencesAndAttendancesOrShiftsChart } from './useAbsencesAndAttendancesOrShiftsChart';

import './absencesAndAttendancesOrShiftsChart.scss';

const AREA_CHART_MARGIN = {
  top: 10,
  right: 30,
  left: 0,
  bottom: 0,
};
const STACK_OFFSET = 'expand';

const LEGEND_WRAPPER_STYLE = { fontSize: '10px' };

export const AbsencesAndAttendancesOrShiftsChart = (_, { intl }: ContextWithIntl) => {
  const [chartMode, setChartMode] = useState(MINUTES);
  const { data, payoutSetting } = useAbsencesAndAttendancesOrShiftsChart();
  const isEcpPayoutSettingsType = payoutSetting.type === PayoutSettingType.ECP;

  const rows = useMemo(
    () => formatDataToChart(data, chartMode, isEcpPayoutSettingsType),
    [data, chartMode, isEcpPayoutSettingsType],
  );

  const legend = getCustomLegend(isEcpPayoutSettingsType, intl);

  const hasDataToShow = useMemo(
    () =>
      rows.length &&
      rows.some(
        ({ absences, attendancesWithoutShift, earlyOrPunctualStartAttendances, lateAttendances }) =>
          absences !== 0 ||
          attendancesWithoutShift !== 0 ||
          earlyOrPunctualStartAttendances !== 0 ||
          lateAttendances !== 0,
      ),
    [rows],
  );

  const { yTickFormatter } = useMemo(() => createFormattersForSelectedChartType(chartMode), [chartMode]);
  return (
    <div className="k-absencesAndAttendancesOrShiftsChart">
      <ChartHeader
        title={intl.formatMessage(messages.chartTitle, { isEcpPayoutSettingsType })}
        chartMode={chartMode}
        onChange={setChartMode}
        options={ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_MODES}
        tooltipMessage={intl.formatMessage(messages.tooltipMessage)}
      />
      {hasDataToShow ? (
        <ResponsiveContainer width="100%" height="85.62%">
          <AreaChart data={rows} stackOffset={STACK_OFFSET} margin={AREA_CHART_MARGIN}>
            <CartesianGrid strokeDasharray={STROKE_DASHARRAY} />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={value => formatDecimalAsPercent(value, 0)} />
            <Tooltip content={<TooltipContent formatter={yTickFormatter} />} />
            <Legend wrapperStyle={LEGEND_WRAPPER_STYLE} payload={legend} />
            {ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_METADATA.map(({ lineType, dataKey, stackId, fill }) => (
              <Area key={dataKey} type={lineType} dataKey={dataKey} stackId={stackId} fill={fill} stroke={fill} />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <EmptyState
          name="scheduleCTA"
          imgSrc="/img/scheduleCTA.png"
          emptyStateHeader={intl.formatMessage(chartsMessages.emptyStateHeader)}
          emptyStateText={intl.formatMessage(chartsMessages.emptyStateText)}
          modifiers={[CHART_MODIFIER]}
        />
      )}
    </div>
  );
};

AbsencesAndAttendancesOrShiftsChart.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AbsencesAndAttendancesOrShiftsChart;
