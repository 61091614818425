import {
  MAIN_DATE_CHANGE_CUSTOM_DATE,
  MAIN_DATE_CHANGE_MODE,
  MAIN_DATE_CHANGE_MODE_AND_DATE,
  MAIN_DATE_MOVE_LEFT,
  MAIN_DATE_MOVE_RIGHT,
  MAIN_DATE_MOVE_TO_DATE,
  SET_MAIN_DATE_STORE,
} from '@/constants/ActionTypes.js';
import {
  generateDateArrayWithShiftInt,
  generateDateTextFromArray,
  generateHeaderDateArray,
  generateTemplatesDateArray,
  getToday,
} from '@/utils/dateHelper.js';
import {
  changeCustomDate,
  changeMode,
  changeModeAndDate,
  moveLeft,
  moveRight,
  moveToDate,
} from '@/utils/dateStoreHelpers.js';

const baseDateArray = generateDateArrayWithShiftInt('week', 0);
const basePreviousDateArray = generateDateArrayWithShiftInt('week', -1);
const baseDateText = generateDateTextFromArray('week', baseDateArray);
const baseHeaderDateArray = generateHeaderDateArray('week', baseDateArray);
const baseTemplatesDateArray = generateTemplatesDateArray();
const initialState = {
  dateShiftInt: 0,
  dateMode: 'week', // month, week, custom
  dateArray: baseDateArray,
  customDate: {
    start: baseDateArray[0],
    end: baseDateArray[baseDateArray.length - 1],
  },
  // Previous date array refers to the previous period, use f.e. in schedule
  previousDateArray: basePreviousDateArray,
  headerDateArray: baseHeaderDateArray,
  templatesDateArray: baseTemplatesDateArray,
  dateText: baseDateText,
  today: getToday(),
};

const mainDateStore = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_DATE_CHANGE_MODE: {
      return changeMode(state, action);
    }
    case MAIN_DATE_MOVE_LEFT: {
      return moveLeft(state);
    }
    case MAIN_DATE_MOVE_RIGHT: {
      return moveRight(state);
    }
    case MAIN_DATE_MOVE_TO_DATE: {
      return moveToDate(state, action);
    }
    case MAIN_DATE_CHANGE_CUSTOM_DATE: {
      return changeCustomDate(state, action);
    }
    case MAIN_DATE_CHANGE_MODE_AND_DATE: {
      return changeModeAndDate(state, action);
    }
    case SET_MAIN_DATE_STORE:
      return action.payload;
    default:
      return state;
  }
};
export default mainDateStore;
