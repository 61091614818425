import { connect } from 'react-redux';

import { getAttendanceImageUrls } from '@/actions';
import { getHistoryForAttendance } from '@/actions/history.js';
import { showModal } from '@/actions/uiState.js';

import AttendancesTableOptions from './AttendancesTableOptions.jsx';

const mapStateToProps = (state, ownProps) => ({
  locationSettings: state.reducer.settings.locationSettings,
  availablePhotos: state.reducer.rcpPhotos.data[ownProps?.rowData?.attendanceObject?.id] || null,
});

const mapDispatchToProps = {
  showModal,
  getHistoryForAttendance,
  getAttendanceImageUrls,
};

const AttendancesTableOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(AttendancesTableOptions);

export default AttendancesTableOptionsContainer;
