import { connect } from 'react-redux';

import { addMassEmployees } from '@/actions/companymanage';
import { hideModal } from '@/actions/uiState';
import { MASS_EMPLOYEES_ADD_MODAL } from '@/constants/modalTypes';

import AddMassEmployeesModal from './AddMassEmployeesModal';

const mapStateToProps = state => ({
  jobTitles: state.reducer.jobTitles,
  userLocations: state.reducer.userLocations,
  showModal: state.reducer.uiState.showModal === MASS_EMPLOYEES_ADD_MODAL,
  companyEmploymentConditions: state.reducer.employmentConditions,
  companyRoles: state.reducer.roles.companyRoles,
});
const mapDispatchToProps = {
  hideModal,
  addMassEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMassEmployeesModal);
