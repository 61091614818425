import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import OptionsCell from '@/components/common/OptionsCell.jsx';
import LabelsContainer from '@/components/common/UI/LabelsContainer/LabelsContainer.jsx';
import {
  BUDGET_INFO_HIDE,
  COMPANY_MANAGE_EMPLOYEES_HIDE_DELETE,
  COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT,
} from '@/constants/Restrictions.js';
import { employeesTable } from '@/constants/tableColumns.jsx';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';

class EmployeesRow extends PureComponent {
  render() {
    const { data, currentEmployeeContract, jobTitles } = this.props;
    const {
      pin,
      role,
      locations,
      email,
      phone,
      employmentConditionsName,
      reference_id: referenceId,
      nfc_code: nfcCode,
      supplementary_locations_ids: supplementaryLocationsIds,
      id,
      created_at: createdAt,
      employment_conditions: employmentConditions,
      compareName,
    } = data;

    const hideEditEmployee = this.props.userPermissions.restrictions.includes(COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT);

    const rowOptions = [
      {
        option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
        restriction: COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT,
        style: 'edit',
        function: this.props.edit,
      },
      {
        option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
        restriction: COMPANY_MANAGE_EMPLOYEES_HIDE_DELETE,
        style: 'delete',
        function: this.props.delete,
        isDisabled: id === this.props.currentUserId,
      },
    ];

    const { options, styles, functions, disabledArray } = applyPermissionsForOptions(
      rowOptions,
      this.props.userPermissions,
    );
    const visibleColumnsKeys = this.props.visibleColumns.map(column => column.key);
    const mappedJobTitles =
      currentEmployeeContract?.job_titles
        .map(contractJobTitle => {
          const jobTitle = jobTitles.find(jobTitle => jobTitle.id === contractJobTitle.job_title_id);

          if (!jobTitle) return null;

          return {
            ...jobTitle,
            individualHourlyWage: contractJobTitle.wage,
          };
        })
        .filter(Boolean) || [];
    const employeeJobTitles = mappedJobTitles.map(jobTitle => (
      <ColorLabel key={jobTitle.id} text={jobTitle.title} color={jobTitle.color} />
    ));

    const employeeWages = mappedJobTitles.map(jobTitle => (
      <ColorLabel
        key={jobTitle.id}
        text={(
          (jobTitle.individualHourlyWage || jobTitle.individualHourlyWage === 0
            ? jobTitle.individualHourlyWage
            : jobTitle.hourly_wage) / 100
        ).toFixed(2)}
        color={jobTitle.color}
      />
    ));

    const allCells = {
      jobTitle: (
        <td className="center" key="jobTitle">
          <LabelsContainer limit={4} moreAction={this.props.edit} moreActionDisabled={hideEditEmployee}>
            {employeeJobTitles}
          </LabelsContainer>
        </td>
      ),
      hourlyWage: (
        <FeatureWrapper restriction={BUDGET_INFO_HIDE} key="hourlyWage">
          <td className="center">
            <LabelsContainer limit={4} moreAction={this.props.edit} moreActionDisabled={hideEditEmployee}>
              {employeeWages}
            </LabelsContainer>
          </td>
        </FeatureWrapper>
      ),
      locations: (
        <td className="center" key="primaryLocation">
          <LabelsContainer limit={4} moreAction={this.props.edit} moreActionDisabled={hideEditEmployee}>
            {locations.map((location, index) => (
              <ColorLabel
                key={index}
                text={location.name}
                color={location.color}
                extra={supplementaryLocationsIds.includes(parseInt(location.id))}
              />
            ))}
          </LabelsContainer>
        </td>
      ),
      role: (
        <td className="capitalize center" key="role">
          {role}
        </td>
      ),
      pin: (
        <td key="pin" className="center">
          {pin}
        </td>
      ),
      email: (
        <td key="email" className="ellipsis center">
          {email}
        </td>
      ),
      phone: (
        <td key="phone" className="center">
          {phone}
        </td>
      ),
      employmentConditions: (
        <td key="employmentConditions" className="ellipsis center">
          {employmentConditionsName}
        </td>
      ),
      referenceId: (
        <td key="referenceId" className="ellipsis center">
          {referenceId}
        </td>
      ),
      nfc: (
        <td key="nfc" className="ellipsis center">
          {nfcCode}
        </td>
      ),
      createdAt: (
        <td key="createdAt" className="ellipsis center">
          {convertDateToStandardFormat(createdAt)}
        </td>
      ),
      hireDate: (
        <td key="hireDate" className="ellipsis center">
          {employmentConditions?.hire_date ? convertDateToStandardFormat(employmentConditions.hire_date) : ''}
        </td>
      ),
      releaseDate: (
        <td key="releaseDate" className="ellipsis center">
          {employmentConditions?.release_date ? convertDateToStandardFormat(employmentConditions.release_date) : ''}
        </td>
      ),
      options: (
        <td className="center" key="options" data-test={`${compareName}-options`}>
          <OptionsCell options={options} styles={styles} functions={functions} disabledArray={disabledArray} />
        </td>
      ),
    };

    return (
      <tr className="h-sorce" data-test={compareName}>
        {Object.keys(allCells)
          .filter(
            key =>
              visibleColumnsKeys.includes(key) ||
              employeesTable.headers.find(header => header.key === key)?.cannotBeHidden,
          )
          .map(key => allCells[key])}
      </tr>
    );
  }
}
EmployeesRow.propTypes = {
  data: PropTypes.shape({
    pin: PropTypes.string,
    role: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    employee_group_id: PropTypes.number,
    supplementary_locations_ids: PropTypes.arrayOf(PropTypes.number),
    email: PropTypes.string,
    phone: PropTypes.string,
    reference_id: PropTypes.string,
    nfc_code: PropTypes.string,
    employmentConditionsName: PropTypes.string,
    compareName: PropTypes.string,
  }).isRequired,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  currentUserId: PropTypes.string,
};

export default EmployeesRow;
