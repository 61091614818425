import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import SearchBox from '@/components/common/SearchBox.jsx';

import Notification from './Notification.jsx';

class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
    };
    this.loadMore = this.loadMore.bind(this);
    this.getSearchWord = this.getSearchWord.bind(this);
    this.notificationToggleReadStatus = this.notificationToggleReadStatus.bind(this);
    this.markAllRead = this.markAllRead.bind(this);
    this.deleteAllNotifications = this.deleteAllNotifications.bind(this);
  }

  componentDidMount() {
    if (!this.props.userNotifications.nextTimestamp) this.loadMore();
  }

  getSearchWord(e) {
    this.setState({ searchWord: e.target.value });
  }

  loadMore() {
    this.setState(this.props.pullNotifications(25));
  }

  notificationToggleReadStatus(notification) {
    this.props.notificationToggleReadStatus(notification);
  }

  deleteNotification(notificationId, event) {
    event.stopPropagation();
    this.props.deleteNotification(notificationId);
  }

  markAllRead() {
    const messages = defineMessages({
      modalTitle: {
        id: 'notification.view.markAllReadModal.title',
        defaultMessage: 'Oznacz jako przeczytane',
      },
      modalText: {
        id: 'notification.view.markAllReadModal.text',
        defaultMessage: 'Czy na pewno chcesz oznaczyć wszystkie notyfikacje ({unreadNumber}) jako przeczytane?',
      },
      modalConfirmBtn: {
        id: 'notification.view.markAllReadModal.button',
        defaultMessage: 'Oznacz',
      },
    });
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(messages.modalTitle, {}),
      description: this.context.intl.formatMessage(messages.modalText, {
        unreadNumber: this.props.userNotifications.unreadNotifications,
      }),
      confirmText: this.context.intl.formatMessage(messages.modalConfirmBtn, {}),
      confirmFunc: this.props.markAllNotificationAsRead.bind(this),
      confirmType: 'info',
    });
  }

  deleteAllNotifications() {
    const messages = defineMessages({
      modalTitle: {
        id: 'notification.view.deleteAllModal.title',
        defaultMessage: 'Usuń wszystkie',
      },
      modalText: {
        id: 'notification.view.deleteAllModal.text',
        defaultMessage: 'Czy na pewno chcesz usunąć wszystkie notyfikacje ({unreadNumber})?',
      },
      modalConfirmBtn: {
        id: 'notification.view.deleteAllModal.button',
        defaultMessage: 'usuń',
      },
    });
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(messages.modalTitle, {}),
      description: this.context.intl.formatMessage(messages.modalText, {
        unreadNumber: this.props.userNotifications.unreadNotifications,
      }),
      confirmText: this.context.intl.formatMessage(messages.modalConfirmBtn, {}),
      confirmFunc: this.props.deleteAllNotifications.bind(this),
      confirmType: 'warning',
    });
  }

  render() {
    const { hasMore } = this.props.userNotifications;
    let notificationsToRender = this.props.userNotifications.notificationArray;

    if (this.state.searchWord !== '') {
      notificationsToRender = notificationsToRender.filter(notification => {
        const searchString = notification.message.toLowerCase();
        return searchString.indexOf(this.state.searchWord.toLowerCase()) >= 0;
      });
    }

    return (
      <div className="notification-view">
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchWord} />
        <div className="k-wrapper k-wrapper--smallPadding animated fadeInRight notification-view__wrapper">
          <div className="notification-view__list-container">
            <ul className="notification-view__list">
              {notificationsToRender.length ? (
                notificationsToRender.map(notification => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    notificationToggleReadStatus={this.notificationToggleReadStatus}
                    notificationGoToContext={this.props.notificationGoToContext}
                  />
                ))
              ) : (
                <p className="notific_item empty no_notif">
                  <FormattedMessage
                    id="notification.view.emptyList"
                    defaultMessage="Brak notyfikacji do wyświetlenia."
                  />
                </p>
              )}
            </ul>
            {hasMore && (
              <div className="notification-view__load-more">
                <Button onClick={() => this.props.pullNotifications(25)} modifiers="reverse-blue">
                  <FormattedMessage id="notification.view.loadMoreButton" defaultMessage="Pokaż więcej" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NotificationView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

NotificationView.propTypes = {
  userNotifications: PropTypes.shape({
    notificationArray: PropTypes.arrayOf(PropTypes.shape({})),
    nextTimestamp: PropTypes.string,
    unreadNotifications: PropTypes.number,
    hasMore: PropTypes.bool,
  }),
  pullNotifications: PropTypes.func,
  notificationToggleReadStatus: PropTypes.func,
  markAllNotificationAsRead: PropTypes.func,
  deleteNotification: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
  notificationGoToContext: PropTypes.func,
  showConfirmModal: PropTypes.func,
};

export default NotificationView;
