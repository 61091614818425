import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

const modalMessages = defineMessages({
  title: {
    id: 'openShift.addFromAutoGenerateModal.title',
    defaultMessage: 'Dodaj otwarte zmiany',
  },
  confirmText: {
    id: 'openShift.addFromAutoGenerateModal.confirmText',
    defaultMessage: 'Dodaj',
  },
  oneShift: {
    id: 'openShift.addFromAutoGenerateModal.itemAmount.oneShift',
    defaultMessage: 'otwartą zmiane dla stanowiska',
  },
  fewShifts: {
    id: 'openShift.addFromAutoGenerateModal.itemAmount.fewShifts',
    defaultMessage: 'otwarte zmiany dla stanowiska',
  },
  manyShifts: {
    id: 'openShift.addFromAutoGenerateModal.itemAmount.manyShifts',
    defaultMessage: 'otwartych zmian dla stanowiska',
  },
});

const getShiftsText = (amount, intl) => {
  if (amount === 1) return intl.formatMessage(modalMessages.oneShift);
  if (amount < 5) return intl.formatMessage(modalMessages.fewShifts);
  return intl.formatMessage(modalMessages.manyShifts);
};

class CreateOpenShiftFromAutoGenerateModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmBtnModifiers: ['orange', 'disabled'],
      jobTitles: {},
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showModal, modalObject, userJobTitles } = this.props;
    if (showModal && showModal !== prevProps.showModal && modalObject.length) {
      this.setState({
        jobTitles: modalObject.reduce((prev, shift) => {
          const jobTitle = userJobTitles.find((j) => shift.job_title_id === j.id);
          if (!jobTitle) return prev;
          return {
            ...prev,
            [shift.job_title_id]: {
              ...jobTitle,
              amount: ((prev[shift.job_title_id] && prev[shift.job_title_id].amount) || 0) + shift.amount,
              selected: (prev[shift.job_title_id] && prev[shift.job_title_id].selected) || false,
            },
          };
        }, {}),
      });
    }
  }

  onSubmit() {
    const { autoschedulerLocation, setAutoschedulerLocation, createOpenShift } = this.props;

    const jobTitlesIds = Object.values(this.state.jobTitles)
      .filter((jobTitle) => jobTitle.selected)
      .map((jobTitle) => jobTitle.id);

    const openShiftsToCreate = this.props.modalObject.filter((shift) => jobTitlesIds.includes(shift.job_title_id));

    openShiftsToCreate.forEach((openShift) => {
      createOpenShift({
        comment: '',
        working_hours: openShift.hours,
        draft: true,
        date: openShift.date,
        shifts_required: openShift.amount,
        job_title: {
          id: openShift.job_title_id,
        },
        location: {
          id: autoschedulerLocation,
        },
      });
    });
    this.onCancel();
    setAutoschedulerLocation(null);
  }

  onCancel() {
    this.props.onHide();
  }

  onChange(itemId, itemChecked) {
    const newJobTitles = {
      ...this.state.jobTitles,
      [itemId]: {
        ...this.state.jobTitles[itemId],
        selected: itemChecked,
      },
    };
    this.setState({
      jobTitles: newJobTitles,
      confirmBtnModifiers: Object.values(newJobTitles).some((jobTitle) => jobTitle.selected)
        ? ['orange']
        : ['orange', 'disabled'],
    });
  }

  render() {
    if (!this.props.showModal) return null;
    const title = this.context.intl.formatMessage(modalMessages.title, {});
    const confirmText = this.context.intl.formatMessage(modalMessages.confirmText, {});
    return (
      <MDKadroModal
        show={this.props.showModal}
        title={title}
        confirmText={confirmText}
        confirmBtnModifiers={this.state.confirmBtnModifiers}
        onSubmit={this.onSubmit}
        onHide={this.onCancel}
        modifiers={['narrow']}
      >
        <div className="k-autoGenerateOpenShiftModal">
          <FormattedMessage
            id="openShift.addFromAutoGenerateModal.header"
            defaultMessage="Dla niektórych zmian nie znaleźliśmy odpowiedniego pracownika. Poniżej znajdziesz listę zmian oraz stanowisk, które mogą zostać zapisane jako otwarte zmiany do przejęcia przez pracowników."
          >
            {(text) => <p className="k-autoGenerateOpenShiftModal__header">{text}</p>}
          </FormattedMessage>
          <ul className="k-autoGenerateOpenShiftModal__list">
            {Object.values(this.state.jobTitles).map((jobTitle) => (
              <li className="k-autoGenerateOpenShiftModal__listItem" key={jobTitle.id}>
                <MDCheckbox
                  id={jobTitle.id}
                  text={
                    <div className="k-autoGenerateOpenShiftModal__jobTitleContainer">
                      <div className="change_num">{jobTitle.amount}</div>
                      <div className="k-autoGenerateOpenShiftModal__jobTitleDetails">
                        <div>{getShiftsText(jobTitle.amount, this.context.intl)}</div>
                        <ColorLabel text={jobTitle.title} color={jobTitle.color} modifiers={['large']} />
                      </div>
                    </div>
                  }
                  value={jobTitle.selected}
                  onChange={(e) => this.onChange(jobTitle.id, e.target.checked)}
                  reverse
                />
              </li>
            ))}
          </ul>
        </div>
      </MDKadroModal>
    );
  }
}

CreateOpenShiftFromAutoGenerateModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CreateOpenShiftFromAutoGenerateModal.defaultProps = {
  modalObject: [],
};

CreateOpenShiftFromAutoGenerateModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  onHide: PropTypes.func,
  createOpenShift: PropTypes.func,
  autoschedulerLocation: PropTypes.string,
  setAutoschedulerLocation: PropTypes.func,
};

export default CreateOpenShiftFromAutoGenerateModal;
