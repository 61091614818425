import PropTypes from 'prop-types';
import { useState } from 'react';

import { FreemiumWrapper } from '@/components/common/Freemium';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import AccountSwitcher from '@/components/settings/AccountSwitcher/AccountSwitcher.redux';
import SettingsSection from '@/components/settings/SettingsSection/SettingsSection';
import { FREEMIUM_EVENT_USER_VIEW_NOTIFICATIONS } from '@/constants/intercom.js';
import { ACCOUNT_SWITCHER_USER_SETTINGS_VIEW } from '@/constants/Permissions';
import { FREEMIUM_DISABLE_USER_VIEW_NOTIFICATIONS } from '@/constants/Restrictions.js';
import { getLoggedAccounts } from '@/utils/accounts/accounts.utils';

import ChangePasswordBlock from './ChangePasswordBlock/ChangePasswordBlock.redux';
import LanguageSettings from './LanguageSettings/LanguageSettings.redux.js';
import NotificationSettings from './NotificationsSettings/NotificationSettings.redux.js';
import UserDataSettings from './UserDataSettings/UserDataSettings.redux';

const NOTIFICATION_FREEMIUM_RESTRICTIONS = [FREEMIUM_DISABLE_USER_VIEW_NOTIFICATIONS];

const UserDataView = ({ oauth }) => {
  const [accounts, setAccounts] = useState(getLoggedAccounts);

  return (
    <div className="k-wrapper animated fadeInRight">
      <FeatureWrapper permission={ACCOUNT_SWITCHER_USER_SETTINGS_VIEW}>
        {accounts.length > 0 && (
          <div className="row">
            <div className="col-lg-12">
              <SettingsSection canSave={false} title="Twoje konta">
                <AccountSwitcher accounts={accounts} setAccounts={setAccounts} />
              </SettingsSection>
            </div>
          </div>
        )}
      </FeatureWrapper>
      <div className="row">
        <div className="col-lg-12">
          <UserDataSettings />
          <LanguageSettings />
          {!oauth && <ChangePasswordBlock />}
          <FreemiumWrapper
            freemiumRestrictions={NOTIFICATION_FREEMIUM_RESTRICTIONS}
            intercomEvent={FREEMIUM_EVENT_USER_VIEW_NOTIFICATIONS}
          >
            <NotificationSettings />
          </FreemiumWrapper>
        </div>
      </div>
    </div>
  );
};

UserDataView.propTypes = {
  oauth: PropTypes.bool,
};

export default UserDataView;
