import { connect } from 'react-redux';

import { changeEmployeesOrderForLocation, startCustomSorting } from '@/actions/employeeSorting.js';
import { toggleScheduleSettings } from '@/actions/schedule.jsx';
import { refreshScheduleData } from '@/actions/schedule/scheduleView';
import { changeScheduleSearchString } from '@/actions/uiState.js';
import { getWorkingRulesViolationsForCurrentDateArrayWithToastr } from '@/actions/workingRules.js';

import ScheduleButtonBar from './ScheduleButtonBar.jsx';

const mapStateToProps = state => ({
  selectedRowsLocationsIds: state.reducer.scheduleUIState.selectedRowsLocationsIds,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  searchString: state.reducer.scheduleUIState.searchString,
  workingRulesEnabled: state.reducer.schedule.viewSettings.workingRules?.value,
  isAutogenerateScheduleEnabled: state.reducer.schedule.viewSettings.autogenerateSchedule?.value,
  uiState: state.reducer.uiState,
  employeeSorting: state.reducer.employeeSorting,
  deleteOptions: state.reducer.deleteOptions,
  locationFilter: state.reducer.locationFilter,
  mainDateStore: state.reducer.mainDateStore,
  locationSettings: state.reducer.settings.locationSettings,
  isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
  canCopy: state.reducer.schedule.buttonsState.canCopy,
  canPaste: state.reducer.schedule.buttonsState.canPaste,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  restrictions: state.reducer.userPermissions.restrictions,
  scheduleSettings: state.reducer.scheduleUIState.settings,
});

const mapDispatchToProps = {
  changeEmployeesOrderForLocation,
  startCustomSorting,
  getWorkingRulesViolationsForCurrentDateArrayWithToastr,
  changeScheduleSearchString,
  toggleScheduleSettings,
  refreshData: refreshScheduleData,
};

const ScheduleButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleButtonBar);

export default ScheduleButtonBarContainer;
