import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import './photoEmpty.scss';

const PhotoEmpty = ({ clockIn, clockOut, clockInUrl, clockOutUrl }) => {
  const photoEmptyClassName = classnames('k-photoEmpty', {
    'k-photoEmpty--loading': (clockIn && !clockInUrl) || (clockOut && !clockOutUrl),
  });

  return (
    <div className={photoEmptyClassName}>
      <div className="k-photoEmpty__container">
        <i className="k-photoEmpty__icon material-icons photo_camera">photo_camera</i>
      </div>
      <a className="k-photoEmpty__hyperLink" href={clockInUrl || clockOutUrl} download>
        <img className="k-photoEmpty__img" src={clockInUrl || clockOutUrl} alt="clockIn" />
      </a>
    </div>
  );
};

PhotoEmpty.propTypes = {
  clockIn: PropTypes.bool,
  clockOut: PropTypes.bool,
  clockInUrl: PropTypes.string,
  clockOutUrl: PropTypes.string,
};
export default PhotoEmpty;
