import { connect } from 'react-redux';

import { addRecommendedSchedule } from '@/actions/recommendedSchedule.js';
import { hideModal } from '@/actions/uiState.js';
import { RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE } from '@/constants/modalTypes.js';

import RecommendedScheduleFromExistingTemplate from './RecommendedScheduleFromExistingTemplate.jsx';

const mapStateToProps = state => ({
  isVisibleModal: state.reducer.uiState.showModal === RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE,
  templatesArray: state.reducer.userTemplates.templatesArray,
  dateArray: state.reducer.mainDateStore.dateArray,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

const mapDispatchToProps = {
  hideModal,
  addRecommendedSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedScheduleFromExistingTemplate);
