import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TextInput from '@/components/common/inputs/TextInput.jsx';
import PayrollTableRowWarning from '@/components/payroll/PayrollTableRowWarning.jsx';
import { isDefined } from '@/utils/baseHelpers.js';
import {
  parseMinutesToFormat,
  parseMinutesToHumanForm,
} from '@/utils/dateHelper.js';
import {
  getLocationNameForId,
  getWeeklyOvertimeEstimate,
} from '@/utils/payrollHelpers.jsx';

const onBonusBlur = (event, detail, addBonusToAttendance, employeeId) => {
  addBonusToAttendance(detail, parseInt(event.target.value * 100), employeeId);
};

const onBonusChange = (value, id, employeeId, setBonusValues) => {
  setBonusValues(id, value, employeeId);
};

const PayrollLocationTableRowDetails = (props) => {
  const items = (detail) => {
    const {
      absence_time: detailAbsenceTime,
      absence_short_name: absenceShortName,
    } = detail;
    const absenceMinutes = detailAbsenceTime
      ? Math.round(detailAbsenceTime * 60)
      : 0;
    const absenceTime = parseMinutesToHumanForm(absenceMinutes);
    return [
      <td key="date">
        {moment(detail.date).format('dddd DD.MM')}
        {detail.warningTypes?.length ? (
          <div className="k-payroll_location__warning">
            <PayrollTableRowWarning warningTypes={detail.warningTypes} />
          </div>
        ) : null}
      </td>,
      <td key="jobTitle">
        {detail.isOvertimeCollection ? (
          <FormattedMessage
            id="absences.overtimeCollection"
            defaultMessage="Odbiór nadgodzin"
          />
        ) : (
          detail.jobTitle
        )}
      </td>,
      <td key="locations">
        {detail.location
          ? getLocationNameForId(detail.location.id, props.userLocations)
          : '-'}
      </td>,
      <td key="wage">
        {detail.wage || detail.wage === 0 ? detail.wage : '-'}
      </td>,
      <td key="absence">
        {absenceShortName ? `${absenceShortName} - ${absenceTime}` : '---'}
      </td>,
      <td key="timeWorked">
        {detail.timeWorked || detail.timeWorked === 0
          ? parseMinutesToFormat(
              detail.timeWorked,
              props.payrollSettings.timeFormatSetting.type
            )
          : '---'}
      </td>,
      <td key="empty1">---</td>,
      <td key="empty2">---</td>,
      <td key="nightTimeWorked">
        {detail.nightTimeWorked || detail.nightTimeWorked === 0
          ? parseMinutesToFormat(
              detail.nightTimeWorked,
              props.payrollSettings.timeFormatSetting.type
            )
          : '---'}
      </td>,
      !detail.isOvertimeCollection && (
        <>
          <td key="overtime50">
            {parseMinutesToFormat(
              detail.overtime.overtime50,
              props.payrollSettings.timeFormatSetting.type
            )}
          </td>
          <td key="overtime100">
            {parseMinutesToFormat(
              detail.overtime.overtime100,
              props.payrollSettings.timeFormatSetting.type
            )}
          </td>
        </>
      ),
      detail.isOvertimeCollection && (
        <td key="overtime" colSpan="2">
          {parseMinutesToFormat(
            -moment(detail.end_timestamp).diff(
              detail.start_timestamp,
              'minutes'
            ),
            props.payrollSettings.timeFormatSetting.type
          )}
        </td>
      ),
      <td key="overtimeStats">
        {detail.timePlanned && detail.timeWorked ? (
          <div>
            {parseMinutesToFormat(
              getWeeklyOvertimeEstimate(detail.timePlanned, detail.timeWorked),
              props.payrollSettings.timeFormatSetting.type
            )}
          </div>
        ) : (
          '---'
        )}
      </td>,
      <td key="bonus">
        <TextInput
          onBlur={(e) =>
            onBonusBlur(e, detail, props.addBonusToAttendance, props.employeeId)
          }
          type="number"
          className="k-payroll__detailsBonus form-control"
          disabled={detail.disableBonus}
          onChange={({ target }) =>
            onBonusChange(
              target.value,
              detail.id,
              props.employeeId,
              props.setBonusValues
            )
          }
          value={
            isDefined(props.bonusValues[detail.id])
              ? props.bonusValues[detail.id]
              : detail.bonus_amount
          }
          isPositive={false}
        />
      </td>,
      <td key="sumPaid">{detail.sumPaid}</td>,
    ];
  };

  return (
    <table className="table">
      {props.colgroup}
      <tbody>
        {props.details
          .sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1))
          .map((detail) => {
            const trStyle = {};
            if (detail.errorHighlight) {
              trStyle.backgroundColor = 'rgba(252, 55, 55, 0.2)';
            }
            return (
              <tr
                key={`${detail.id}_${detail.date}`}
                className="payrollDetailsRow"
                style={trStyle}
              >
                {items(detail).map((item, i) => {
                  if (props.visibleLocationColumns.includes(i)) return item;
                  return null;
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

PayrollLocationTableRowDetails.propTypes = {
  colgroup: PropTypes.node,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  payrollSettings: PropTypes.shape({
    timeFormatSetting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  addBonusToAttendance: PropTypes.func,
  visibleLocationColumns: PropTypes.arrayOf(PropTypes.number),
  setBonusValues: PropTypes.func,
  bonusValues: PropTypes.shape({}),
  employeeId: PropTypes.string,
};

export default PayrollLocationTableRowDetails;
