import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MDMultiSelectFooter } from '@/components/common/inputs/MDComponents';
import { EmplMassEditActions } from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { getSelectedEmployees } from '@/components/companymanage/employees/EditMassEmployeesModal/employeesEditMassHelpers.ts';
import { Contract } from '@/types/contracts.types.ts';

import { FieldValue } from '../EmployeesEditMassValueField.helpers';
import EmployeeMassEditSummaryHeaderText from './EmployeeMassEditSummaryStepTexts/EmployeeMassEditSummaryHeaderText.tsx';
import EmployeesMassEditSummaryBottomSection from './EmployeesMassEditSummaryBottomSection/EmployeesMassEditSummaryBottomSection.tsx';
import {
  getContractsForSelectedEmployees,
  getRelevantAndIncorrectEmployee,
} from './employeesMassEditSummaryStep.helpers';

import './employeesMassEditSummaryStep.scss';

interface EmployeesMassEditSummaryStepProps {
  fieldValue: FieldValue;
  onChange: (value: any) => void;
  selectedAction: EmplMassEditActions;
  selectedEmployeesIds: string[];
  employees: { [employeeId: string]: { id: string } };
  contracts: Contract[];
}

const EmployeesMassEditSummaryStep = (
  {
    fieldValue,
    onChange,
    selectedAction,
    selectedEmployeesIds,
    employees,
    contracts,
  }: EmployeesMassEditSummaryStepProps,
  { intl },
) => {
  const selectedEmployees = useMemo(() => getSelectedEmployees(selectedEmployeesIds, employees, selectedAction), [
    selectedEmployeesIds,
    employees,
    selectedAction,
  ]);

  const contractsForSelectedEmployees = useMemo(
    () => getContractsForSelectedEmployees(selectedEmployees, selectedEmployeesIds, contracts),
    [selectedEmployees, selectedEmployeesIds, contracts],
  );

  const relevantAndIncorrectEmployees = useMemo(
    () => getRelevantAndIncorrectEmployee(contractsForSelectedEmployees, fieldValue, intl, selectedAction),
    [contractsForSelectedEmployees, fieldValue, intl],
  );

  const [incorrectEmployees] = useState(relevantAndIncorrectEmployees.incorrectEmployees);
  const [correctEmployees, setCorrectEmployee] = useState(relevantAndIncorrectEmployees.correctEmployees);
  const [relatedContracts, setRelatedContracts] = useState(relevantAndIncorrectEmployees.relatedContracts);

  const deleteCorrectEmployee = useCallback(
    (employee: { value: string }) => {
      const newEmployees = correctEmployees.filter(({ value }) => value !== employee.value);
      const newRelatedContracts = relatedContracts.filter(contract => contract.employee_id !== employee.value);
      setRelatedContracts(newRelatedContracts);
      setCorrectEmployee(newEmployees);
    },
    [correctEmployees, setCorrectEmployee],
  );

  const copyIncorrectEmployees = useCallback(() => {
    const labelIncorrectEmployees: string = incorrectEmployees.map(({ label }) => label).join();
    navigator.clipboard.writeText(labelIncorrectEmployees);
  }, [incorrectEmployees]);

  useEffect(() => {
    const newValue = {
      ...fieldValue,
      incorrectEmployees,
      correctEmployees,
      relatedContracts,
    };

    onChange(newValue);
  }, [incorrectEmployees, correctEmployees, relatedContracts]);

  return (
    <div className="k-employeesMassEditSummaryStep">
      <EmployeeMassEditSummaryHeaderText
        actionType={selectedAction}
        newStartContractDate={fieldValue?.newStartContractDate}
      />
      <div className="k-employeesMassEditSummaryStep__correctEmployeeFooter">
        <MDMultiSelectFooter selectedItems={correctEmployees} deleteItem={deleteCorrectEmployee} />
      </div>
      <EmployeesMassEditSummaryBottomSection
        selectedAction={selectedAction}
        copyIncorrectEmployees={copyIncorrectEmployees}
        incorrectEmployees={incorrectEmployees}
        selectedJobTitles={fieldValue.jobTitles}
      />
    </div>
  );
};

EmployeesMassEditSummaryStep.contextTypes = {
  intl: PropTypes.shape({}),
};

export default EmployeesMassEditSummaryStep;
