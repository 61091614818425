import * as AT from '@/constants/ActionTypes';

const initialState = {
  locations: {},
  selectedDay: 1,
};

const addUpdateData = (locations, newLocationData) => {
  const result = { ...locations };
  Object.keys(newLocationData).forEach(key => {
    if (result[key]) {
      result[key] = { ...result[key], ...newLocationData[key] };
    } else {
      result[key] = { ...newLocationData[key] };
    }
  });
  return result;
};
const scheduleState = (state = initialState, action) => {
  switch (action.type) {
    case AT.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST: {
      return { ...state, locations: addUpdateData(state.locations, action.payload) };
    }
    case AT.FLEX_SCHEDULE_SELECTED_DAY_CHANGED: {
      return { ...state, selectedDay: action.payload };
    }

    default:
      return state;
  }
};
export default scheduleState;
