import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import browserHistory from '@/constants/browserHistory';
import { getCookie } from '@/utils/cookieHandlers.js';
import { gaEvents } from '@/utils/ga.helpers';
import { inputHasErrors, inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  email: {
    id: 'onboarding.stepOne.email',
    defaultMessage: 'E-mail',
  },
  name: {
    id: 'onboarding.stepOne.name',
    defaultMessage: 'Imię',
  },
  lastName: {
    id: 'onboarding.stepOne.lastName',
    defaultMessage: 'Nazwisko',
  },
  password: {
    id: 'onboarding.stepOne.password',
    defaultMessage: 'Hasło',
  },
});

class OnboardingStepOne extends Component {
  constructor(props, context) {
    super(props, context);
    const email = getCookie('userEmail');
    this.state = {
      email,
      name: '',
      lastName: '',
      password: '',
      errors: {},
      passwordVisible: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.nextAction = this.nextAction.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount() {
    if (!this.state.email) {
      browserHistory.push('/onboarding');
    } else {
      gaEvents.setNewStep(2, window.gaProducts);
    }
  }

  handleInputChange(e) {
    const { target } = e;
    const { name } = target;
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });

    const { errors } = this.state;

    if (inputHasErrors(name, errors)) {
      this.validateInput(e);
    }
  }

  togglePassword() {
    this.setState(prev => ({
      ...prev,
      passwordVisible: !prev.passwordVisible,
    }));
  }

  validateInput(event) {
    return new Promise(resolve => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const error = inputValidation(target.name, value);
      this.setState(
        prevState => ({
          errors: {
            ...prevState.errors,
            [target.name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        }),
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  async validateAll() {
    const inputs = ['name', 'lastName', 'password'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    let canContinue = true;
    Object.values(this.state.errors).map(err => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  nextAction(e) {
    e.preventDefault();
    return this.validateAll().then(valid => {
      if (!valid) return;
      const { email, name, lastName, password } = this.state;

      this.props.saveStepData(
        {
          email,
          firstName: name,
          lastName,
          password,
        },
        'stepTwo',
      );
    });
  }

  render() {
    return (
      <div className="k-onboardingStep">
        <div className="k-onboardingStep__info">
          <FormattedMessage
            id="onboarding.stepOne.infoText"
            defaultMessage="Aby rozpocząć używanie platformy, powiedz nam coś o sobie:"
          />
        </div>
        <form onSubmit={this.nextAction} className="k-onboardingStep__form">
          <MDTextInput
            modifiers="disabled"
            id="email"
            value={this.state.email}
            icon="k-icon-email"
            label={this.context.intl.formatMessage(messages.email, {})}
            onChange={() => {}}
          />
          <MDTextInput
            id="name"
            value={this.state.name}
            errorMessage={this.state.errors.name}
            icon="k-icon-profile"
            label={this.context.intl.formatMessage(messages.name, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
          />
          {/* Two hidden inputs to prevent shitty firefox browser from autofilling */}
          <input type="text" style={{ display: 'none' }} />
          <input type="password" style={{ display: 'none' }} />
          <MDTextInput
            id="lastName"
            value={this.state.lastName}
            errorMessage={this.state.errors.lastName}
            icon="k-icon-profile"
            label={this.context.intl.formatMessage(messages.lastName, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
          />
          {this.state.passwordVisible ? (
            <MDTextInput
              id="password"
              value={this.state.password}
              errorMessage={this.state.errors.password}
              autoComplete="new-password"
              type="text"
              icon="k-icon-visibility"
              label={this.context.intl.formatMessage(messages.password, {})}
              onChange={this.handleInputChange}
              onBlur={this.validateInput}
              onIconClick={this.togglePassword}
            />
          ) : (
            <MDTextInput
              id="password"
              value={this.state.password}
              errorMessage={this.state.errors.password}
              autoComplete="new-password"
              type="password"
              icon="k-icon-visibility_off"
              label={this.context.intl.formatMessage(messages.password, {})}
              onChange={this.handleInputChange}
              onBlur={this.validateInput}
              onIconClick={this.togglePassword}
            />
          )}
          <div className="k-onboardingStep__button">
            <Button type="submit" modifiers="orange high uppercase">
              <FormattedMessage id="common.next" defaultMessage="Dalej" />
            </Button>
          </div>
        </form>
        <div className="k-onboardingStep__termsText">
          <FormattedMessage
            id="onboarding.stepOne.terms"
            defaultMessage="Rejestrując się potwierdzasz, że zapoznałeś się z {tos} Kadromierz i {policy} oraz akceptujesz ich postanowienia."
            values={{
              tos: (
                <a
                  href="https://kadromierz.pl/wp-content/uploads/2023/08/Kadromierz_regulamin_serwisu.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="onboarding.stepOne.tos" defaultMessage="regulaminem" />
                </a>
              ),
              policy: (
                <a
                  href="https://kadromierz.pl/wp-content/uploads/2023/05/Kadromierz_Polityka-prywatnosci.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="onboarding.stepOne.policy" defaultMessage="Polityką prywatności" />
                </a>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}

OnboardingStepOne.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

OnboardingStepOne.propTypes = {
  saveStepData: PropTypes.func,
};

export default OnboardingStepOne;
