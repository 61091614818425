import PropTypes from 'prop-types';

import { availabilityMessages } from '@/constants/intl.js';

const EmployeeAvailabilitiesBlock = (props, context) => {
  const { employee } = props;
  const { first_name: firstName, last_name: lastName, supplementary } = employee;
  const relevantAvailability = props.employee.availability_blocks.find(block => block.date === props.date);
  let availability;
  if (relevantAvailability) {
    let color;
    let name;
    switch (relevantAvailability.type) {
      case 'availability':
        name = context.intl.formatMessage(availabilityMessages.availability, {});
        color = '#417505';
        break;
      case 'unavailability':
        name = context.intl.formatMessage(availabilityMessages.unavailability, {});
        color = '#d0021b';
        break;
      case 'freetime':
        color = '#454e57';
        name = context.intl.formatMessage(availabilityMessages.freetimeShort, {});
        break;
      case 'custom': {
        const customAva = props.userCustomTypes.find(ava => ava.id === relevantAvailability.type_id);
        if (!customAva) break;
        color = customAva.color;
        name = customAva.name;
        break;
      }
      default:
        color = '';
        name = '';
    }
    availability = (
      <span style={{ color, paddingLeft: '15px' }}>
        {name} {relevantAvailability.hours}
      </span>
    );
  }
  return (
    <div>
      <span className="k-multiselect__text">
        {firstName} {lastName}
      </span>
      {availability}

      {supplementary ? <i className="material-icons k-supplementaryEmployeeIcon">person_add</i> : null}
    </div>
  );
};

EmployeeAvailabilitiesBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeAvailabilitiesBlock.propTypes = {
  employee: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    availability_blocks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  date: PropTypes.string,
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeAvailabilitiesBlock;
