import { connect } from 'react-redux';

import { confirmDraftAvailabilities } from '@/actions/schedule.jsx';
import { changeSendPanelVisibility } from '@/actions/uiState.js';

import AvailabilitySendPanel from './AvailabilitySendPanel.jsx';

const mapStateToProps = state => ({
  showSendPanel: state.reducer.uiState.showSendPanel,
  customDate: state.reducer.mainDateStore.customDate,
});
const mapDispatchToProps = {
  changeSendPanelVisibility,
  confirmDraftAvailabilities,
};
const AvailabilitySendPanelContainer = connect(mapStateToProps, mapDispatchToProps)(AvailabilitySendPanel);

export default AvailabilitySendPanelContainer;
