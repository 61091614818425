export const OPTION_NAMES = {
  NEW_ABSENCE_REQUEST: 'NEW_ABSENCE_REQUEST',
  LOCATION_NOT_OPENED: 'LOCATION_NOT_OPENED',
  CANCEL_ABSENCE_REQUEST: 'CANCEL_ABSENCE_REQUEST',
  APPLY_FOR_OPEN_SHIFT: 'APPLY_FOR_OPEN_SHIFT',
  ACCEPT_LOAN_EMPLOYEES_PROPOSAL: 'ACCEPT_LOAN_EMPLOYEES_PROPOSAL',
  CREATE_LOAN_EMPLOYEES_PROPOSAL: 'CREATE_LOAN_EMPLOYEES_PROPOSAL',
};

export const OPTION_TYPE = {
  BOOL: 'bool',
  TOLERANCE: 'tolerance',
  SELECT: 'select',
  DATE: 'date',
  TEXT: 'text',
  CHECKBOX_LIST: 'checkboxList',
  TIME_RANGE: 'timeRange',
  TITLE_WITH_DESC: 'TITLE_WITH_DESC',
  WORKING_HOURS_INPUTS: 'WORKING_HOURS_INPUTS',
  BONUS_TARGET_OPTIONS: 'BONUS_TARGET_OPTIONS',
  SINGLE_TIME_INPUT: 'SINGLE_TIME_INPUT',
};

export const SCHEDULE_SETTING_TYPE = {
  BUDGET: 'budget',
  OPEN_SHIFTS: 'openShifts',
};

export const DISPLAY_SETTINGS_SCHEDULE_KEYS = [
  'showShiftsFromOtherLocations',
  'showEmployeesWithoutShifts',
  'showSupplementaryEmployeesWithoutShift',
  'supplementaryEmployees',
  'loanedEmployees',
];

export const EMPLOYEE_SCHEDULE_SETTING_TYPES = ['openShifts', 'tradeShifts'];

export const DATE_MATCH_REGEXP = /(\d{4}-\d{2}-\d{2})/;
