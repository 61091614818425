import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  error: {
    id: 'common.error',
    defaultMessage: 'Błąd',
  },
  success: {
    id: 'common.success',
    defaultMessage: 'Sukces!',
  },
  deleleUserShiftModalTitle: {
    id: 'employeeTimesheet.deleleUserShiftModalTitle',
    defaultMessage: 'Usuń czas pracy',
  },
  deleleUserShiftModalDescription: {
    id: 'employeeTimesheet.deleleUserShiftModalDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć ten czas pracy?',
  },
  deleleUserShiftsErrorDescription: {
    id: 'employeeTimesheet.deleleUserShiftsErrorDescription',
    defaultMessage: 'Nie udało się usunąć czas pracy.',
  },
  deleleUserOneShiftSuccessDescription: {
    id: 'employeeTimesheet.deleleUserOneShiftSuccessDescription',
    defaultMessage: 'Usunięto czas pracy.',
  },
  deleleUserManyShiftsSuccessDescription: {
    id: 'employeeTimesheet.deleleUserManyShiftsSuccessDescription',
    defaultMessage: 'Usunięto czasy pracy.',
  },
  addUserShiftsErrorDescription: {
    id: 'employeeTimesheet.addUserShiftsErrorDescription',
    defaultMessage: 'Nie udało się dodać czas pracy.',
  },
  addUserOneShiftSuccessDescription: {
    id: 'employeeTimesheet.addUserOneShiftSuccessDescription',
    defaultMessage: 'Dodano poprawnie czas pracy.',
  },
  addUserManyShiftsSuccessDescription: {
    id: 'employeeTimesheet.addUserManyShiftsSuccessDescription',
    defaultMessage: 'Dodano poprawnie czasy pracy.',
  },
  editUserShiftSuccessDescription: {
    id: 'employeeTimesheet.editUserShiftSuccessDescription',
    defaultMessage: 'Czas peacy poprawnie edytowano.',
  },
  editUserShiftErrorDescription: {
    id: 'employeeTimesheet.editUserShiftErrorDescription',
    defaultMessage: 'Nie powiodła się edycja czasu pracy',
  },
});
