import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  modalTitle: {
    id: 'chat.newChatModal.modalTitle',
    defaultMessage: 'Nowy czat',
  },
  modalDescription: {
    id: 'chat.newChatModal.modalDescription',
    defaultMessage: 'Rozpocznij nową wiadomość z wybranym pracownikiem lub grupą pracowników',
  },
  headerTitle: {
    id: 'chat.newChatModal.headerTitle',
    defaultMessage: 'Pracownicy',
  },
  selectAll: {
    id: 'chat.newChatModal.selectAll',
    defaultMessage: 'Wszyscy',
  },
  deselectAll: {
    id: 'chat.newChatModal.deselectAll',
    defaultMessage: 'Źaden',
  },
  bodyLabel: {
    id: 'chat.newChatModal.bodyLabel',
    defaultMessage: 'Wybierz pracowników',
  },
  begin: {
    id: 'chat.newChatModal.begin',
    defaultMessage: 'Rozpocznij',
  },
  notSelectedEmployees: {
    id: 'chat.newChatModal.notSelectedEmployees',
    defaultMessage: 'Nie wybrano żadnego pracownika',
  },
  admin: {
    id: 'chat.newChatModal.admin',
    defaultMessage: 'Administrator systemu',
  },
});
