import moment from 'moment';

import { hoursFormatted } from '@/constants/dates';

export const MODAL_MODIFIERS = ['narrow'];

const addDateToShifts = (shifts, dateArray) =>
  dateArray.reduce((shiftsWithDates, date) => {
    const dayIndex = moment(date).day();
    const shiftsForDate = shifts.reduce(
      (prev, shift) => (shift.date === dayIndex ? [...prev, { ...shift, date }] : prev),
      [],
    );
    return shiftsForDate.length > 0 ? [...shiftsWithDates, ...shiftsForDate] : shiftsWithDates;
  }, []);

export const splitTemplateShift = shift => {
  const { date, working_hours: workingHours, amount, job_title_id: jobTitleId } = shift;
  const [rawStart, rawEnd] = workingHours.split('-');

  const [startHour] = rawStart.split(':');
  const start = `${startHour}:00`;
  const [endHour, endMinutes] = rawEnd.split(':');
  let roundedEndHour = endHour;
  if (parseInt(endMinutes) > 0) {
    roundedEndHour = `${parseInt(endHour) + 1}`.padStart(2, '0');
  }
  let end = `${roundedEndHour}:00`;

  if (end < start && end !== '00:00') {
    const partOne = splitTemplateShift({ ...shift, working_hours: `${start}-23:59` });
    const partTwo = splitTemplateShift({
      ...shift,
      working_hours: `00:00-${end}`,
      date: moment(date)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    });
    return [...partOne, ...partTwo];
  } else if (end === '00:00') {
    end = '24:00';
  }

  const hoursDuringShift = hoursFormatted.filter(hour => hour >= start && hour < end);

  return hoursDuringShift.map(hour => ({ hour, amount: parseInt(amount), date, jobTitleId }));
};

export const createBaseShiftChunks = (shifts, dateArray) => {
  const jobTitles = shifts.reduce((prev, shift) => {
    if (prev.includes(shift.job_title_id)) return prev;
    return [...prev, shift.job_title_id];
  }, []);
  return jobTitles.flatMap(jobTitleId =>
    dateArray.flatMap(date => hoursFormatted.map(hour => ({ hour, date, jobTitleId, amount: 0 }))),
  );
};

export const sumShiftChunks = shiftChunks => {
  const summedChunks = shiftChunks.reduce((prev, shift) => {
    const { date, hour, jobTitleId, amount } = shift;
    const key = `${date};${jobTitleId};${hour}`;
    return { ...prev, [key]: (prev[key] || 0) + amount };
  }, {});
  const parsedSummedChunks = Object.keys(summedChunks).map(key => {
    const [date, jobTitleId, hour] = key.split(';');
    const value = summedChunks[key];
    return { date, jobTitleId, hour, value };
  });
  return parsedSummedChunks;
};

export const groupShiftChunks = chunks =>
  chunks.reduce((prev, chunk) => {
    const { date, jobTitleId, hour, value } = chunk;
    const dateData = prev[date] || {};
    const jobTitleData = dateData[jobTitleId] || {};
    return { ...prev, [date]: { ...dateData, [jobTitleId]: { ...jobTitleData, [hour]: value } } };
  }, {});

export const formatShiftChunkGroupsIntoRecommendedSchedule = (groupedChunks, locationId) =>
  Object.keys(groupedChunks).reduce((prev, date) => {
    const jobTitlesForDate = Object.keys(groupedChunks[date]).reduce(
      (jobTitleObjects, jobTitleId) => [
        ...jobTitleObjects,
        {
          job_title_id: jobTitleId,
          values: groupedChunks[date][jobTitleId],
        },
      ],
      [],
    );
    return [...prev, { date, location_id: locationId, template: jobTitlesForDate }];
  }, []);

export const getDataForRecommendedScheduleFromShifts = (shifts, dateArray, locationId) => {
  const shiftChunks = shifts.flatMap(splitTemplateShift);

  const baseShiftChunks = createBaseShiftChunks(shifts, dateArray);

  const summedChunks = sumShiftChunks([...baseShiftChunks, ...shiftChunks]);

  const groupedChunks = groupShiftChunks(summedChunks);

  const recommendedSchedule = formatShiftChunkGroupsIntoRecommendedSchedule(groupedChunks, locationId);

  return recommendedSchedule;
};

export const getDataForRecommendedScheduleFromTemplate = (template, dateArray, locationId) => {
  const shiftsWithDates = addDateToShifts(template.shifts, dateArray);

  return getDataForRecommendedScheduleFromShifts(shiftsWithDates, dateArray, locationId);
};
