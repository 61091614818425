import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import { getRolesAvailableForCurrentUser } from '@/utils/userEmployeesHelpers.js';

const messages = defineMessages({
  employee: {
    id: 'roles.employee',
    defaultMessage: 'Pracownik',
  },
  manager: {
    id: 'roles.manager',
    defaultMessage: 'Menadżer',
  },
});

const EmployeesEditMassRolesField = ({ currentUser, companyRoles, userPermissions, onChange, companySettings }) => {
  const [selected, setSelected] = useState('employee');
  const options = getRolesAvailableForCurrentUser(
    currentUser,
    companyRoles,
    userPermissions,
    null,
    companySettings.same_rank_is_visible,
  ).map(role => ({
    key: role.name || this.context.intl.formatMessage(messages[role.id], {}),
    value: role.id,
  }));

  useEffect(() => {
    if (selected) {
      const { id: role_id, is_manager: isManager } = companyRoles.find(companyRole => companyRole.id === selected);
      const role = isManager ? 'manager' : 'employee';
      onChange({ role, role_id });
    }
  }, [selected]);

  return (
    <MDSelect
      id="employeeRole"
      options={options}
      defaultValue={selected}
      name={<FormattedMessage id="emplyees.editMass.employeeRole" defaultMessage="Rola" />}
      onChange={value => {
        setSelected(value);
      }}
      closeOnClick
    />
  );
};

EmployeesEditMassRolesField.propTypes = {
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  currentUser: PropTypes.shape({
    role_id: PropTypes.string,
    role: PropTypes.string,
  }),
  onChange: PropTypes.func,
  companySettings: PropTypes.shape({
    same_rank_is_visible: PropTypes.bool,
  }),
};

export default EmployeesEditMassRolesField;
