import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';

class TabNav extends PureComponent {
  static getLabel(route) {
    if (!route.label) {
      return null;
    }

    return <span className="k-tabNav__label label default-label">{route.label}</span>;
  }

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  isActive(url) {
    if (this.props.isExactPath) {
      return url === this.props.activePath;
    }
    return this.props.activePath.includes(url);
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  hideDropdown() {
    this.setState({
      dropdownOpen: false,
    });
  }

  onBlur() {
    setTimeout(this.hideDropdown, 200);
  }

  render() {
    const dropdownMenuClassnames = classnames('k-tabNav__dropdownMenu', {
      'k-tabNav__dropdownMenu--open': this.state.dropdownOpen,
    });
    const activeRoute = this.props.routes.find(route => route.path === this.props.activePath);

    return (
      <div className="k-tabNav" tabIndex="-1" onBlur={this.onBlur}>
        <div className="k-tabNav__dropdown">
          <button className="k-tabNav__dropdownToggle" onClick={this.toggleDropdown}>
            {activeRoute?.title}
            <span className="caret" />
          </button>
          <ul className={dropdownMenuClassnames}>
            {this.props.routes.map((route, i) => (
              <FreemiumWrapper freemiumRestrictions={route.freemiumRestrictions} intercomEvent={route.intercomEvent}>
                <li
                  role="presentation"
                  key={i}
                  className={this.isActive(route.path) ? 'hidden' : ''}
                  onClick={this.hideDropdown}
                >
                  <Link className="k-tabNav__dropdownElement" to={route.path}>
                    {route.title}
                    {route.showFreemiumStar && <FreemiumStar />}
                  </Link>
                </li>
              </FreemiumWrapper>
            ))}
          </ul>
        </div>
        <div className="k-tabNav__container">
          {this.props.routes.map((route, i) => (
            <div
              key={i}
              className={classnames('k-tabNav__route', { 'k-tabNav__route--active': this.isActive(route.path) })}
            >
              <FreemiumWrapper freemiumRestrictions={route.freemiumRestrictions} intercomEvent={route.intercomEvent}>
                <Link className="k-tabNav__routeLink" to={route.path}>
                  {route.title}
                  {TabNav.getLabel(route)}
                  {route.showFreemiumStar && <FreemiumStar />}
                </Link>
              </FreemiumWrapper>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

TabNav.defaultProps = {
  isExactPath: true,
};

TabNav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  activePath: PropTypes.string,
  isExactPath: PropTypes.bool,
};

export default TabNav;
