import * as AT from '@/constants/ActionTypes';

const initialState = {};

const overtimeStats = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_OVERTIME_STATS_SUCCESS:
      return action.payload;
    case AT.MAIN_DATE_CHANGE_MODE:
    case AT.MAIN_DATE_MOVE_LEFT:
    case AT.MAIN_DATE_MOVE_RIGHT:
    case AT.MAIN_DATE_CHANGE_CUSTOM_DATE:
    case AT.MAIN_DATE_CHANGE_MODE_AND_DATE:
    case AT.CHANGE_JOBTITLE_FILTER:
    case AT.CHANGE_JOBTITLE_FILTER_GROUPED:
    case AT.CHANGE_MULTIPLE_LOCATION_FILTER:
    case AT.CHANGE_EMPLOYMENT_CONDITION_FILTER:
      return {};
    default:
      return state;
  }
};

export default overtimeStats;
