import { connect } from 'react-redux';

import { changeExportsHistoryData } from '@/actions/exports/exportsHistory/exportsHistory.js';

import ExportAllItems from './ExportAllItems';

const mapStateToProps = state => ({
  exportsItems: state.reducer.exportsHistory.data,
  currentPage: state.reducer.exportsHistory.currentPage,
  numberOfItemsPerPage: state.reducer.exportsHistory.numberOfItemsPerPage,
});

const mapDispatchToProps = {
  changeExportsHistoryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportAllItems);
