import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getLocationNameForId } from '@/utils/payrollHelpers';

import EmployeePayrollTableRowDetails from './EmployeePayrollTableRowDetails.jsx';

const messages = defineMessages({
  noAssignedShift: {
    id: 'common.noAssignedShift',
    defaultMessage: 'Brak przypisanej zmiany',
  },
});

class PayrollTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
    this.showDetails = this.showDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showDetails: nextProps.data.details.length
        ? this.state.showDetails
        : false,
    });
  }

  showDetails() {
    this.setState((prev) => ({
      showDetails: this.props.data.details.length
        ? !prev.showDetails
        : prev.showDetails,
    }));
  }

  render() {
    const detailsClassNames = classnames('k-payroll__details', {
      'k-payroll__details--show': this.state.showDetails,
    });
    const dateClassNames = classnames('col_name day', {
      'k-payroll__col-name': !this.props.data.details.length,
    });
    const locations = this.props.data.locations
      .map((location) =>
        getLocationNameForId(location, this.props.userLocations)
      )
      .join(' / ');

    return (
      <tr>
        <td colSpan={6} style={{ padding: 0 }}>
          <table className="table">
            {this.props.colgroup}
            <tbody>
              <tr key="summaryRowBottom">
                <td
                  onClick={this.showDetails}
                  className={dateClassNames}
                  role="presentation"
                >
                  {this.props.data.details.length ? (
                    <i
                      className="material-icons sortdir noselect"
                      style={{ marginTop: '0px' }}
                    >
                      {this.state.showDetails
                        ? 'arrow_drop_down'
                        : 'arrow_drop_up'}
                    </i>
                  ) : null}
                  {this.props.data.humanDate}
                </td>
                <td>{locations}</td>
                <td>{this.props.data.start}</td>
                <td>{this.props.data.end}</td>
                <td>
                  {this.props.data.jobTitles || (
                    <FormattedMessage {...messages.noAssignedShift} />
                  )}
                </td>
                <td>{parseMinutesToFormat(this.props.data.timeWorked)}</td>
              </tr>
              <tr className={detailsClassNames}>
                <td colSpan={6} style={{ padding: 0 }}>
                  <EmployeePayrollTableRowDetails
                    details={this.props.data.details}
                    colgroup={this.props.colgroup}
                    userLocations={this.props.userLocations}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

PayrollTableRow.propTypes = {
  data: PropTypes.shape({}),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  colgroup: PropTypes.node,
};

export default PayrollTableRow;
