import { produce } from 'immer';
import { Location } from 'kadro-helpers/lib/types';

import {
  ADD_NEW_LOCATION,
  DELETE_LOCATION,
  DELETE_LOCATION_SET,
  GET_LOCATIONS_SUCCESFUL,
  UPDATE_LOCATION,
} from '@/constants/ActionTypes';
import {
  AddNewLocationAction,
  DeleteLocationAction,
  DeleteLocationSetAction,
  GetLocationsSuccesfulAction,
  UpdateLocationAction,
} from '@/types/actions/location.types.ts';

const initialState: Location[] = [];

type Action =
  | GetLocationsSuccesfulAction
  | AddNewLocationAction
  | DeleteLocationAction
  | DeleteLocationSetAction
  | UpdateLocationAction;

const userLocations = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LOCATIONS_SUCCESFUL:
        return action.payload;
      case ADD_NEW_LOCATION:
        draft.push(action.payload);
        break;
      case DELETE_LOCATION:
        return state.filter(location => location.id !== action.payload);
      case DELETE_LOCATION_SET:
        return state.filter(l => !action.payload.includes(l.id));
      case UPDATE_LOCATION: {
        const index = draft.findIndex(item => item.id === action.payload.id);
        draft[index] = action.payload;
        break;
      }
      default:
        return state;
    }
  });

export default userLocations;
