import PropTypes from 'prop-types';
import { useState } from 'react';
import { defineMessages } from 'react-intl';

import { MDTextInput } from '../../inputs/MDComponents.jsx';

import './CustomFilter.scss';

const messages = defineMessages({
  absenceCustomFilterPlaceholder: {
    id: 'absenceCustomFilter.placeholder',
    defaultMessage: 'Filtruj',
  },
});

const AbsenceCustomFilter = (props, context) => {
  const { name, onChange } = props;

  const [value, setValue] = useState(props.value);

  const handleOnChange = e => {
    setValue(e.target.value);
    onChange(e.target.name, e.target.value);
  };

  return (
    <span>
      <MDTextInput
        id={name}
        name={name}
        value={value}
        className="mdTable__filter"
        onChange={handleOnChange}
        placeholder={context.intl.formatMessage(messages.absenceCustomFilterPlaceholder, {})}
      />
    </span>
  );
};

AbsenceCustomFilter.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceCustomFilter.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default AbsenceCustomFilter;
