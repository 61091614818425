import classnames from 'classnames';
import PropTypes from 'prop-types';

import './MDInfoWithLabel.scss';

const MDInfoWithLabel = props => {
  const { label, info, infoModifiers } = props;

  const infoClassnames = classnames(
    'mdInfoWithLabel__info',
    infoModifiers.map(modifier => `mdInfoWithLabel__info--${modifier}`),
  );

  return (
    <div className="mdInfoWithLabel">
      <div className="mdInfoWithLabel__label">{label}</div>
      <div className={infoClassnames}>{info}</div>
    </div>
  );
};

MDInfoWithLabel.defaultProps = {
  infoModifiers: [],
};

MDInfoWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  info: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  infoModifiers: PropTypes.arrayOf(PropTypes.string),
};

export default MDInfoWithLabel;
