import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'attendance.alignModal.title',
    defaultMessage: 'Edytuj Obecności',
  },
  invalidEndHourError: {
    id: 'attendance.alignModal.invalidEndHourError',
    defaultMessage: 'Istnieje obecność rozpoczynająca się o podanej godzinie zakończenia',
  },
  startHourMissingError: {
    id: 'attendance.alignModal.startHourMissingError',
    defaultMessage: 'Proszę podać godzinę rozpoczęcia',
  },
});
