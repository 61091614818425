import { defineMessages } from 'react-intl';

import {
  GET_BUDGET_METRIC_DATA_FAILURE,
  GET_BUDGET_METRIC_DATA_SUCCESS,
  UPDATE_BUDGET_METRIC_DATA_FAILURE,
  UPDATE_BUDGET_METRIC_DATA_SUCCESS,
} from '@/constants/ActionTypes';
import { formatBudgetMetricData } from '@/utils/budgetHelpers';

import { conn } from './index';

const messages = defineMessages({
  getBudgetMetricDataErrorTitle: {
    id: 'budgetMetricsData.getBudgetMetricsData.error.title',
    defaultMessage: 'Błąd podczas pobierania danych metryk budżetu',
  },
  getBudgetMetricDataErrorMessage: {
    id: 'budgetMetricsData.getBudgetMetricsData.error.message',
    defaultMessage: 'Wystąpił niespodziewany błąd. Proszę spróbować później.',
  },
  updateBudgetMetricDataErrorTitle: {
    id: 'budgetMetricsData.updateBudgetMetricsData.error.title',
    defaultMessage: 'Błąd edycji metryki',
  },
  updateBudgetMetricDataErrorMessage: {
    id: 'budgetMetricsData.updateBudgetMetricsData.error.message',
    defaultMessage: 'Wystąpił niespodziewany błąd. Proszę spróbować później.',
  },
});

export const getBudgetMetricData = (id, from, to) => async (dispatch, getState, intl) => {
  try {
    const { data: budgetMetricData } = await conn.getBudgetMetricsData(id, from, to);

    dispatch({
      type: GET_BUDGET_METRIC_DATA_SUCCESS,
      payload: formatBudgetMetricData(budgetMetricData || []),
    });
  } catch (e) {
    console.error(e);

    dispatch({
      type: GET_BUDGET_METRIC_DATA_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.getBudgetMetricDataErrorTitle),
        description: intl.formatMessage(messages.getBudgetMetricDataErrorMessage),
        type: 'error',
      },
    });
  }
};

export const updateBudgetMetricData = (id, date, data) => async (dispatch, getState, intl) => {
  try {
    await conn.updateBudgetMetricData(id, date, data);

    dispatch({
      type: UPDATE_BUDGET_METRIC_DATA_SUCCESS,
      payload: formatBudgetMetricData([
        {
          budget_metric_id: id,
          date,
          data,
        },
      ]),
      notification: {},
    });
  } catch (e) {
    console.error(e);

    dispatch({
      type: UPDATE_BUDGET_METRIC_DATA_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.updateBudgetMetricDataErrorTitle),
        description: intl.formatMessage(messages.updateBudgetMetricDataErrorMessage),
        type: 'error',
      },
    });
  }
};
