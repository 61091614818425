import {
  ATTENDANCE_ABSENCE,
  ATTENDANCE_LATE_QUIT,
  FINISHED_ATTENDANCE,
  UNFINISHED_ATTENDANCE,
} from '@/constants/attendanceDetailTypes';
import { calculateDurationBetweenTimestamps, EMPTY_HOUR } from '@/utils/dateHelper';

export const isAttendanceVisible = (attendanceData, employeeAttendances, attendancesSettings, index) => {
  let blocksType = [];
  if (!attendanceData[index].length && !employeeAttendances.length) blocksType.push(ATTENDANCE_ABSENCE);
  else {
    const isEmptyHour = employeeAttendances.some(att => att.hours.includes(EMPTY_HOUR));
    blocksType = attendanceData[index].detailBlocks.map(block => block.type.name);

    if (blocksType.includes(ATTENDANCE_LATE_QUIT) && isEmptyHour)
      blocksType = blocksType.filter(block => block !== ATTENDANCE_LATE_QUIT);
    if (isEmptyHour) blocksType.push(UNFINISHED_ATTENDANCE);
    if (!isEmptyHour) blocksType.push(FINISHED_ATTENDANCE);
  }

  return attendancesSettings.some(setting => blocksType?.includes(setting.type) && setting.value);
};

export const calculateTotalMinutes = filteredRelevantEmployees =>
  filteredRelevantEmployees.reduce(
    (prev, employee) =>
      prev +
      employee.attendances.reduce(
        (previous, attendance) =>
          previous + parseInt(calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp)),
        0,
      ),
    0,
  );

export const calculateTotalBreaks = filteredRelevantEmployees =>
  filteredRelevantEmployees.reduce(
    (prev, employee) =>
      prev +
      employee.attendances.reduce(
        (previous, attendance) =>
          previous +
          attendance.breaks.reduce(
            (sum, br) => sum + parseInt(calculateDurationBetweenTimestamps(br.end_timestamp, br.start_timestamp)),
            0,
          ),
        0,
      ),
    0,
  );

export const filterAttendanceData = (relevantEmployees, attendanceData, attendancesSettings) => {
  const indexesToRemoved = [];
  const filteredEmployees = relevantEmployees.filter((employee, index) => {
    if (!attendanceData[index]) return false;
    const showAttendance = isAttendanceVisible(attendanceData, employee.attendances, attendancesSettings, index);

    if (!showAttendance) indexesToRemoved.push(index);

    return showAttendance;
  });
  const filteredAttendanceData = attendanceData.filter((element, index) => !indexesToRemoved.includes(index));
  return { filteredEmployees, filteredAttendanceData };
};
