import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import Select from '@/components/common/inputs/MDSelect/Select';
import { generateTooltip } from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.helpers.jsx';
import { newPayoutSettings, roundingSettings, timeFormatSettings } from '@/constants/payrollSettings';
import { findSettingById, getSelectOptionsFromSettings } from '@/utils/inputHelpers';
import { validateNightHours } from '@/utils/payroll/payrollSettingsHelpers';

import PayrollSettingsModalSettingRow from '../PayrollSettingsModalSettingRow/PayrollSettingsModalSettingRow';
import { messages } from './PayrollSettingsModalGeneralSection.messages.js';

const timeRangeInputModifiers = ['modal'];

const PayrollSettingsModalGeneralSection = (
  { changeSetting, payoutSetting, roundingSetting, timeFormatSetting, nighthours, errors, setError },
  { intl },
) => {
  const payoutSettingOptions = useMemo(() => getSelectOptionsFromSettings(newPayoutSettings, intl), [
    newPayoutSettings,
  ]);

  const roundingSettingOptions = useMemo(() => getSelectOptionsFromSettings(roundingSettings, intl), [
    roundingSettings,
  ]);

  const timeFormatSettingOptions = useMemo(() => getSelectOptionsFromSettings(timeFormatSettings, intl), [
    timeFormatSettings,
  ]);

  const payoutTooltip = generateTooltip('payout', intl);
  const roundingTooltip = generateTooltip('roundingGeneral', intl);
  const timeFormatTooltip = generateTooltip('timeFormat', intl);
  const nightHoursTimeTooltip = generateTooltip('nightHoursTime', intl);

  const onNightHoursBlur = () => {
    const isValid = validateNightHours(nighthours);

    if (!isValid) {
      setError('nighthours', messages.nightHoursError);
    }
  };

  return (
    <>
      <PayrollSettingsModalSettingRow
        label={messages.payout}
        tooltip={payoutTooltip}
        settingInput={
          <Select
            onChange={value => {
              changeSetting('payoutSetting', findSettingById(newPayoutSettings, value));
            }}
            defaultValue={payoutSetting.id}
            options={payoutSettingOptions}
            closeOnClick
          />
        }
        isForSelect
      />
      <PayrollSettingsModalSettingRow
        label={messages.rounding}
        tooltip={roundingTooltip}
        settingInput={
          <Select
            onChange={value => {
              changeSetting('roundingSetting', findSettingById(roundingSettings, value));
            }}
            defaultValue={roundingSetting.id}
            options={roundingSettingOptions}
            closeOnClick
          />
        }
        isForSelect
      />
      <PayrollSettingsModalSettingRow
        label={messages.timeFormat}
        tooltip={timeFormatTooltip}
        settingInput={
          <Select
            onChange={value => {
              changeSetting('timeFormatSetting', findSettingById(timeFormatSettings, value));
            }}
            defaultValue={timeFormatSetting.id}
            options={timeFormatSettingOptions}
            closeOnClick
          />
        }
        isForSelect
      />
      <PayrollSettingsModalSettingRow
        label={messages.nightHoursTime}
        tooltip={nightHoursTimeTooltip}
        settingInput={
          <MDTimeRangeInput
            value={nighthours}
            onChange={value => changeSetting('nighthours', value)}
            className="k-payrollSettingsModal__input"
            modifiers={timeRangeInputModifiers}
            errorMessage={errors.nighthours}
            onBlur={onNightHoursBlur}
          />
        }
      />
    </>
  );
};

PayrollSettingsModalGeneralSection.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollSettingsModalGeneralSection.propTypes = {
  changeSetting: PropTypes.func,
  payoutSetting: PropTypes.shape({}),
  roundingSetting: PropTypes.shape({}),
  nighthours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  timeFormatSetting: PropTypes.shape({}),
};

export default PayrollSettingsModalGeneralSection;
