import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { weeklyWorkingHours } from '@/constants/employmentConditions';

import { GroupNodeType } from '../../../GroupedEmployees.types';

type Props = {
  groupKey: GroupNodeType['groupKey'];
};

const messages = defineMessages({
  none: {
    id: 'groupingEmployees.restWorkingTime',
    defaultMessage: 'Pozostałe',
  },
});

export const GroupWorkingTime = memo(({ groupKey }: Props) => {
  const intl = useIntl();
  const text = weeklyWorkingHours.find(({ value }) => value.toString() === groupKey.toString())?.name;

  return <span className="k-groupBar__title">{intl.formatMessage(text || messages.none)}</span>;
});

GroupWorkingTime.displayName = 'GroupWorkingTime';
