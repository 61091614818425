import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { isWeekend, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { filterAndSumRecommendedSchedule } from '@/utils/recommendedScheduleHelpers';

const ScheduleBudgetTableRecommendedScheduleCell = ({
  date,
  recommendedSchedule = 0,
  selectedDisplayMode,
  showHolidaysSetting,
  dayType,
  today,
  selectedJobTitles,
  selectedLocationIds,
}) => {
  const showHolidays = selectedDisplayMode !== 'templates' && showHolidaysSetting;
  const className = classnames('k-budgetTable__cell', {
    today: date === today,
    weekend: isWeekend(date),
    freeFromWorkHoliday: showHolidays && dayType === 'freeFromWorkHoliday',
    workDayHoliday: showHolidays && dayType === 'holiday',
  });
  const jobTitleIds = useMemo(() => selectedJobTitles.map(jobTitle => jobTitle.id), [selectedJobTitles]);
  const sumRecommendedMinutes = useMemo(
    () => filterAndSumRecommendedSchedule(recommendedSchedule, jobTitleIds, selectedLocationIds),
    [recommendedSchedule, jobTitleIds, selectedLocationIds],
  );
  return (
    <td key={date} className={className}>
      <div>{parseMinutesToHumanForm(sumRecommendedMinutes)}</div>
    </td>
  );
};

ScheduleBudgetTableRecommendedScheduleCell.propTypes = {
  date: PropTypes.string,
  recommendedSchedule: PropTypes.shape({
    template: PropTypes.arrayOf(PropTypes.shape({ job_title_id: PropTypes.string, values: PropTypes.shape({}) })),
  }),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedLocationIds: PropTypes.arrayOf(PropTypes.string),
  selectedDisplayMode: PropTypes.string,
  showHolidaysSetting: PropTypes.bool,
  dayType: PropTypes.string,
  today: PropTypes.string,
};

export default ScheduleBudgetTableRecommendedScheduleCell;
