import { conn } from '@/actions';
import * as AT from '@/constants/ActionTypes';

import { messages } from './payrollEmployee.messages';

const getEmployeePayrollDataSuccessful = data => ({
  type: AT.GET_EMPLOYEE_PAYROLL_SUCCESS,
  payload: data,
});

const getEmployeePayrollDataError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.GET_EMPLOYEE_PAYROLL_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.getEmployeePayrollDataError),
      type: 'error',
    },
  });
};

const startFetchingPayrollDataForEmployee = () => ({
  type: AT.START_FETCHING_EMPLOYEE_PAYROLL_DATA,
});

export const getPayrollForCurrentEmployee = (from: string, to: string) => async dispatch => {
  dispatch(startFetchingPayrollDataForEmployee());
  const response = await conn.getPayrollForEmployee(from, to).catch(() => {
    dispatch(getEmployeePayrollDataError());
  });
  dispatch(getEmployeePayrollDataSuccessful(response.data));
};
