import { connect } from 'react-redux';

import { changeAbsencesMode } from '@/actions/absences.js';

import AbsencesModeView from './AbsencesModeView.jsx';

const mapStateToProps = state => ({
  selectedDisplayMode: state.reducer.absences.selectedDisplayMode,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  changeAbsencesMode,
};

const AbsencesModeViewContainer = connect(mapStateToProps, mapDispatchToProps)(AbsencesModeView);

export default AbsencesModeViewContainer;
