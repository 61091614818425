import { connect } from 'react-redux';

import { checkPasswordToken,setNewPassword } from '@/actions/auth.jsx';
import ResetPasswordSetNew from '@/components/password/ResetPasswordSetNew.jsx';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setNewPassword,
  checkPasswordToken,
};

const ResetPasswordSetNewContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordSetNew);

export default ResetPasswordSetNewContainer;
