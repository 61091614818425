import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absencesTimeInput: {
    id: 'absences.time.input',
    defaultMessage: 'Czas',
  },
  absencesSelectedDay: {
    id: 'absences.selectedDay',
    defaultMessage: 'Wybrany dzień',
  },
  absenceHoursNotDividableBy15: {
    id: 'absences.absenceHoursNotDividableBy15',
    defaultMessage: 'Czas trwania odbioru nadgodzin musi być wielokrotnością 15 minut',
  },
});
