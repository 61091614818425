import PropTypes from 'prop-types';

import { Position } from '@/types';
import { ContextWithIntl } from '@/types/intl.types.ts';

import DatePickerField from '../../DatePickerField/DatePickerField.tsx';
import { FieldValue } from '../../EmployeesEditMassValueField.helpers.tsx';
import { messages } from './EmployeeEditMassDeleteContractsFields.messages';

interface EmployeeEditMassDeleteContractsFieldsProps {
  fieldValues: FieldValue;
  onChange: (value: FieldValue) => void;
}

const EmployeeEditMassDeleteContractsFields = (
  { onChange, fieldValues }: EmployeeEditMassDeleteContractsFieldsProps,
  { intl }: ContextWithIntl,
) => (
  <DatePickerField
    id="date"
    onChange={onChange}
    fieldValues={fieldValues}
    label={intl.formatMessage(messages.label)}
    tooltip={intl.formatMessage(messages.tooltip)}
    position={Position.TOP}
  />
);

EmployeeEditMassDeleteContractsFields.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeeEditMassDeleteContractsFields;
