import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';
import {
  AVAILABILITIES_SORTING_OPTION_ID,
  CUSTOM_SORTING_OPTION_ID,
  employeeSortingOptions,
} from '@/constants/employeeSortingOptions.js';
import { AVAILABILITIES, JOBTITLE_COUNT } from '@/constants/scheduleDisplayModes';

import { messages } from './TopBarSortingFilter.messages';

const TopBarSortingFilter = (
  { employeeSorting, hideFilter, isEmployee, changeEmployeeSorting, selectedDisplayMode },
  { intl },
) => {
  if (hideFilter) return null;
  const { currentSorting } = employeeSorting;
  const labelText = intl.formatMessage(messages.sortingLabel);

  const objectsToChoose = useMemo(
    () =>
      employeeSortingOptions
        .filter(option => {
          if (selectedDisplayMode !== AVAILABILITIES) return option.id !== AVAILABILITIES_SORTING_OPTION_ID;
          if (isEmployee) return option.id !== CUSTOM_SORTING_OPTION_ID;
          return option;
        })
        .map(option => ({ ...option, displayText: intl.formatMessage(option.message) })),
    [selectedDisplayMode],
  );

  const selected = useMemo(() => {
    if (selectedDisplayMode !== AVAILABILITIES && currentSorting.type === JOBTITLE_COUNT) {
      changeEmployeeSorting(employeeSortingOptions[0]);
      return { ...objectsToChoose[0], displayText: intl.formatMessage(objectsToChoose[0].message) };
    }
    return {
      ...currentSorting,
      displayText: intl.formatMessage(currentSorting.message),
    };
  }, [selectedDisplayMode, currentSorting, objectsToChoose]);
  return (
    <div className="k-topBarSortingFilter k-topBar__leftElement">
      <TopBarSingleChoice
        {...{ labelText, objectsToChoose, selected }}
        onChange={option => changeEmployeeSorting({ ...option, elements: null })}
        icon={<Icon className="k-topBarChoice k-topBarChoice__selectedIcon" name="sort" />}
        displayKey="displayText"
        className="k-topBarChoice k-topBarChoice--sortingFilter"
      />
    </div>
  );
};

TopBarSortingFilter.propTypes = {
  employeeSorting: PropTypes.shape({
    currentSorting: PropTypes.shape({
      message: PropTypes.shape({}),
      type: PropTypes.string,
    }),
  }),
  hideFilter: PropTypes.bool,
  isEmployee: PropTypes.bool,
  changeEmployeeSorting: PropTypes.func,
  selectedDisplayMode: PropTypes.string,
};

TopBarSortingFilter.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default TopBarSortingFilter;
