import { inputValidation } from './inputValidation.js';

export const validateInput = (event, valueToCompare = '') =>
  new Promise(resolve => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const error = inputValidation(name, value, { valueToCompare });
    resolve([name, error]);
  });

export const createEvent = (name, value, eventProperties = {}, targetProperties = {}) => ({
  target: { name, value, ...targetProperties },
  ...eventProperties,
});
export const getSelectOptionsFromSettings = (settings, intl) =>
  settings.map(setting => ({
    value: setting.id,
    key: intl.formatMessage(setting.message),
  }));
export const findSettingById = (settings, settingsId) => {
  const foundSetting = settings.find(setting => setting.id === settingsId);
  return foundSetting || null;
};

export const isInputValueNotEmpty = value => value !== undefined && value !== null && value !== '';
