import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedPlural } from 'react-intl';

import { MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import NumberInCircle from '@/components/common/UI/NumberInCircle/NumberInCircle.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { countTemplatesShiftsToSave } from '@/utils/scheduleTemplatesHelpers';

import { SendPanelFooter,SendPanelHeader } from '../../SendPanel';
import { messages } from './ScheduleTemplatesSendPanel.messages';

import './ScheduleTemplatesSendPanel.scss';

class ScheduleTemplatesSendPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { name: '' };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showSendPanel, userTemplates } = this.props;
    const showSendPanelChanged = showSendPanel && showSendPanel !== prevProps.showSendPanel;
    if (showSendPanelChanged) {
      this.setState({ name: userTemplates.currentTemplate.name });
    }
  }

  changeName(e) {
    this.setState({ name: e.target.value });
  }

  cancel() {
    this.props.changeSendPanelVisibility(false);
  }

  publish() {
    const name = this.state.name !== this.props.userTemplates.currentTemplate.name ? this.state.name : '';
    this.props.publishDraftTemplateShifts(name);
    this.props.changeSendPanelVisibility(false);
  }

  render() {
    const panelClass = classnames('send_panel', 'send_panel--templates', { open: this.props.showSendPanel });
    const selectedJobTitleIds = this.props.selectedJobTitles.map(({ id }) => id);
    const numberOfDraftShifts = countTemplatesShiftsToSave(
      this.props.userTemplates.currentTemplate.shifts,
      selectedJobTitleIds,
    );
    return (
      <div className={panelClass}>
        <SendPanelHeader onClose={this.cancel} title={this.context.intl.formatMessage(messages.sendPanelTitle)} />
        <div className="content_pane">
          <MDTextInput
            id="name"
            value={this.state.name}
            label={this.context.intl.formatMessage(messages.templateName)}
            modifiers="modal"
            onChange={this.changeName}
          />
          <div className="scheduleTemplatesSendPanel__shiftsInfo">
            <NumberInCircle number={numberOfDraftShifts} />
            <FormattedPlural
              value={numberOfDraftShifts}
              one={this.context.intl.formatMessage(messages.oneShift)}
              few={this.context.intl.formatMessage(messages.fewShifts)}
              other={this.context.intl.formatMessage(messages.otherShiftsNumber)}
            />
          </div>
          <SendPanelFooter
            submitText={this.context.intl.formatMessage(messages.confirm)}
            onCancel={this.cancel}
            onSubmit={this.publish}
          />
        </div>
      </div>
    );
  }
}

ScheduleTemplatesSendPanel.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTemplatesSendPanel.propTypes = {
  publishDraftTemplateShifts: PropTypes.func,
  changeSendPanelVisibility: PropTypes.func,
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      name: PropTypes.string,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  showSendPanel: PropTypes.bool,
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default ScheduleTemplatesSendPanel;
