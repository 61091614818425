import { WritableDraft } from 'immer';
import { defineMessages } from 'react-intl';

import { FreeDayItem, FreeDayItemDto, FreeDaysMarkingStoreState } from './freeDaysMarking.types';

export const messages = defineMessages({
  deleteFreeDayTitle: {
    id: 'freeDaysMarking.deleteFreeDayTitle',
    defaultMessage: 'Usuń oznaczenie dnia wolnego',
  },
  deleteFreeDayDescription: {
    id: 'freeDaysMarking.deleteFreeDayDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć oznaczenie dnia wolnego?',
  },
});

export const freeDayToDto = (freeDay: FreeDayItem): FreeDayItemDto => ({
  id: freeDay.id,
  date: freeDay.date,
  employee_id: freeDay.employeeId,
  marking_id: freeDay.markingId,
});

export const dtoToFreeDay = (freeDayDto: FreeDayItemDto): FreeDayItem => ({
  id: freeDayDto.id,
  date: freeDayDto.date,
  employeeId: freeDayDto.employee_id,
  markingId: freeDayDto.marking_id,
});

export const freeDaysItemsToStore = (freeDaysDtoItems: FreeDayItemDto[]): FreeDaysMarkingStoreState =>
  freeDaysDtoItems.reduce((p, freeDayDto) => {
    if (!p[freeDayDto.employee_id]) p[freeDayDto.employee_id] = {};
    if (!p[freeDayDto.employee_id][freeDayDto.date]) p[freeDayDto.employee_id][freeDayDto.date] = {};
    return {
      ...p,
      [freeDayDto.employee_id]: { ...p[freeDayDto.employee_id], [freeDayDto.date]: dtoToFreeDay(freeDayDto) },
    };
  }, {});

export const deleteFreeDaysInState = (
  freeDaysIds: FreeDayItemDto['id'][],
  state: WritableDraft<FreeDaysMarkingStoreState>,
) => {
  Object.entries(state).forEach(([employeeId, employeeFreeDays]) => {
    Object.entries(employeeFreeDays).forEach(([date, freeDay]) => {
      if (freeDaysIds.includes(freeDay.id)) delete state[employeeId][date];
    });
  });
};
