import { connect } from 'react-redux';

import { changeCurrentCompany } from '@/actions';
import { setCompanySettings, showPasswordRefreshConfirmModal } from '@/actions/settings';

import OptionsView from './OptionsView.jsx';

const mapStateToProps = state => ({
  userRole: state.reducer.currentUser.user.role,
  userPermissions: state.reducer.userPermissions,
  passwordChangedAt: state.reducer.currentUser.user.password_changed_at,
  currentCompany: state.reducer.currentCompany,
});

const mapDispatchToProps = {
  setCompanySettings,
  changeCurrentCompany,
  showPasswordRefreshConfirmModal,
};
const OptionsViewContainer = connect(mapStateToProps, mapDispatchToProps)(OptionsView);

export default OptionsViewContainer;
