import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';

import ErrorModalContent from './ErrorModalContent/ErrorModalContent.tsx';

import './ModalContent.scss';
import 'overlayscrollbars/styles/overlayscrollbars.css';

function ModalContent({ withOverlayScrollContent, withoutOverlayScroll, contentErrorMessage, children, modifiers }) {
  const modalContentClassNames = classnames(
    'mdModal__content',
    ...modifiers.map(modifier => `mdModal__content--${modifier}`),
    {
      'mdModal__content--withoutOverlayScroll': withoutOverlayScroll,
      'mdModal__content--scrollContent': withOverlayScrollContent,
    },
  );

  return withOverlayScrollContent || !withoutOverlayScroll ? (
    <OverlayScrollbarsComponent className="k-overlay-scrollbar">
      <div className={modalContentClassNames}>
        {contentErrorMessage && <ErrorModalContent errorMessage={contentErrorMessage} />}
        {children}
      </div>
    </OverlayScrollbarsComponent>
  ) : (
    <div className={modalContentClassNames}>
      {contentErrorMessage && <ErrorModalContent errorMessage={contentErrorMessage} />}
      {children}
    </div>
  );
}

ModalContent.defaultProps = {
  withOverlayScrollContent: false,
  withoutOverlayScroll: false,
  modifiers: [],
};

ModalContent.propTypes = {
  withOverlayScrollContent: PropTypes.bool,
  withoutOverlayScroll: PropTypes.bool,
  contentErrorMessage: PropTypes.string,
  children: PropTypes.node,

  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default ModalContent;
