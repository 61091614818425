import { memo } from 'react';

import { ACCOUNT_SWITCHER_VIEW } from '@/constants/Permissions';
import { useAppSelector } from '@/redux-store';
import { selectPermissionsDict } from '@/redux-store/userPermissions';
import { getLoggedAccounts } from '@/utils/accounts/accounts.utils.ts';

import { MultiAccounts } from './MultiAccounts/MultiAccounts';
import { ProfileDetailsModal } from './MultiAccounts/ProfileDetailsModal/ProfileDetailsModal';
import { SingleAccount } from './SingleAccount/SingleAccount';

export const UserDetails = memo(() => {
  const permissionsDict = useAppSelector(selectPermissionsDict);
  const loggedAccounts = getLoggedAccounts();

  return (
    <>
      <ProfileDetailsModal />
      {permissionsDict[ACCOUNT_SWITCHER_VIEW] && loggedAccounts.length > 1 ? <MultiAccounts /> : <SingleAccount />}
    </>
  );
});

UserDetails.displayName = 'UserDetails';
