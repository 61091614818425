import { classes, messages } from './AddFreeDayTab.helpers';
import { AddFreeDayTabProps } from './AddFreeDayTab.types';
import { Definition } from './Definition/Definition';
import { Legend } from './Legend/Legend';
import { useAddFreeDayTab } from './useAddFreeDayTab';

import './AddFreeDayTab.scss';

export const AddFreeDayTab = (props: AddFreeDayTabProps) => {
  const { selectedFreeDayId, repeatComponent } = props;
  const { freeDays, freeDayError, intl, onFreeDayClick } = useAddFreeDayTab(props);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.title}>
          {intl.formatMessage(messages.chooseFromList)}
          <Definition />
        </div>
        <div className={classes.legend}>
          <Legend />
          {intl.formatMessage(messages.legend)}
        </div>
      </div>
      <div className={classes.freeDaysContainer}>
        {freeDays.map(({ id, shortcut }) => {
          const className = id === selectedFreeDayId ? classes.freeDaySelected : classes.freeDay;
          return (
            <div key={id} className={className} onClick={() => onFreeDayClick(id)} role="presentation">
              {shortcut}
            </div>
          );
        })}
      </div>
      <div className={classes.repeatDays}>{repeatComponent}</div>
      {freeDayError && <div className={classes.error}>{intl.formatMessage(messages.onlyOneFreeDayByDayError)}</div>}
    </div>
  );
};
