import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

export const HelpCenter = memo(() => (
  <li className="k-sideBarMenu__item">
    <a
      className="k-sideBarMenu__itemLink"
      href="http://pomoc.kadromierz.pl"
      target="_blank"
      rel="noopener noreferrer"
      role="button"
      tabIndex={0}
    >
      <i className="k-sideBarMenu__itemIcon material-icons">chrome_reader_mode</i>{' '}
      <span className="k-sideBarMenu__itemLabel">
        <FormattedMessage id="view.sidebar.helpCenter" defaultMessage="Centrum pomocy" />
      </span>
    </a>
  </li>
));

HelpCenter.displayName = 'HelpCenter';
