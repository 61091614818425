import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { availabilityMessages } from '@/constants/intl.js';

import AddAva from './AddAva.jsx';

function findAvaType(ava) {
  if (ava.availability == null || ava.availability.type !== 'custom') return null;

  const id = ava.availability.type_id;

  for (const type in ava.userCustomAvaTypes) {
    if (ava.userCustomAvaTypes[type].id === id) {
      return ava.userCustomAvaTypes[type];
    }
  }
  return null;
}

class CalendarDayView extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
    this.editEndFunc = this.editEnd.bind(this);
    this.addNewAvaFunc = this.addNewAva.bind(this);
  }

  editEnd(ava) {
    const availabilityObject = {
      type: ava.type,
      id: this.props.ava.availability.id,
      employee: { id: this.props.employeeId },
      date: this.props.ava.date,
      company_id: this.props.currentCompany.id,
      type_id: ava.type_id,
    };
    if (ava.optional_comment) {
      availabilityObject.optional_comment = ava.optional_comment;
    }
    if (ava.hours) {
      availabilityObject.hours = ava.hours;
    }
    if (ava.requires_approval === true) {
      availabilityObject.draft = true;
    }
    this.props.onAbortEditFunc(this.props.index);
    this.props.editAva(availabilityObject);
  }
  addNewAva(ava) {
    const availabilityObject = {
      type: ava.type,
      employee: { id: this.props.employeeId },
      date: this.props.day.format('YYYY-MM-DD'),
      company_id: this.props.currentCompany.id,
      type_id: ava.type_id,
    };
    if (ava.optional_comment) {
      availabilityObject.optional_comment = ava.optional_comment;
    }
    if (ava.hours) {
      availabilityObject.hours = ava.hours;
    }
    if (ava.requires_approval === true) {
      availabilityObject.draft = true;
    }
    this.props.onAbortEditFunc(this.props.index);
    this.props.addAva(availabilityObject);
  }

  render() {
    let type = null;
    let hasAva = false;
    let text = '';
    let textColor = '#333';
    let color = '#ceff9a';
    let hours = '';
    let day = moment();
    if (this.props.ava) {
      day = moment(this.props.ava.date);
      type = findAvaType(this.props.ava);
      if (this.props.ava.availability) {
        hasAva = true;
      }
      if (type) {
        color = type.color;
        text = type.name;
      }
      if (this.props.ava.availability) {
        if (this.props.ava.availability.hours) {
          hours = ` ${this.props.ava.availability.hours}`;
        }

        switch (this.props.ava.availability.type) {
          case 'availability':
            text = this.context.intl.formatMessage(availabilityMessages.availability, {});
            textColor = '#417505';
            break;
          case 'unavailability':
            text = this.context.intl.formatMessage(availabilityMessages.unavailability, {});
            color = '#d0021b';
            textColor = '#d0021b';
            break;
          case 'freetime':
            color = '#eceff1';
            textColor = '#454e57';
            text = this.context.intl.formatMessage(availabilityMessages.freetime, {});
            break;
          default:
            break;
        }
      }
    }
    let dayName = day.format('dddd');
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

    if (hasAva) {
      return (
        <div
          role="presentation"
          className="calendar-day-block avaListElement"
          style={{ backgroundColor: this.props.selected ? '#f1f0f0' : '' }}
          onClick={
            this.props.selected
              ? () => {}
              : () => {
                  this.props.onClickFunc(this.props.index);
                }
          }
        >
          <div
            className="emp-name"
            style={{
              fontWeight: this.props.selected ? '800' : '600',
              textDecoration: this.props.selected ? 'underline' : '',
            }}
          >
            {`${dayName} ${day.format('DD/MM')}`}
          </div>
          <div
            className="calendar-ava"
            style={{
              display: !this.props.edited ? 'block' : 'none',
              color: textColor,
              fontWeight: '700',
            }}
          >
            <div className="calendar-list-circle" style={{ backgroundColor: color }} />
            <div className="calendar-list-text">{text + hours}</div>
          </div>
          {this.props.selected && !this.props.edited ? (
            <div>
              <button
                className="calendar-ava-button-del"
                onClick={() => {
                  this.props.deleteAva(this.props.employeeId, this.props.ava.availability);
                }}
              >
                <FormattedMessage id="common.calendarDayView.delete" defaultMessage="Usuń" />
              </button>
              <button
                className="calendar-ava-button-edit"
                onClick={() => {
                  this.props.onStartEditFunc(this.props.index);
                }}
              >
                <FormattedMessage id="common.calendarDayView.edit" defaultMessage="Edytuj" />
              </button>
            </div>
          ) : null}
          {this.props.edited ? (
            <AddAva
              avaTypes={this.props.ava.userCustomAvaTypes}
              display={this.props.edited}
              abort={() => {
                this.props.onAbortEditFunc(this.props.index);
              }}
              submit={this.editEndFunc}
              ava={this.props.ava}
            />
          ) : null}
        </div>
      );
    } else if (day.format('DM') === this.props.day.format('DM')) {
      return (
        <div className="calendar-day-block avaListElement">
          <div className="emp-name">{`${dayName} ${day.format('DD/MM')}`}</div>
          <div className="calendar-ava-add" style={{ display: this.props.edited ? 'none' : 'block' }}>
            <button
              className="calendar-ava-button-add"
              onClick={() => {
                this.props.onStartEditFunc(this.props.index);
              }}
            >
              <FormattedMessage id="common.calendarDayView.addAvailability" defaultMessage="Dodaj dostępność" />
            </button>
          </div>
          {this.props.edited ? (
            <AddAva
              avaTypes={this.props.ava.userCustomAvaTypes}
              display={this.props.edited}
              abort={() => {
                this.props.onAbortEditFunc(this.props.index);
              }}
              submit={this.addNewAvaFunc}
            />
          ) : null}
        </div>
      );
    }
    return <div className="avaListElement" />;
  }
}

CalendarDayView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CalendarDayView.propTypes = {
  day: PropTypes.shape({}),
  ava: PropTypes.shape({}),
  addAva: PropTypes.func,
  editAva: PropTypes.func,
  deleteAva: PropTypes.func,
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentCompany: PropTypes.shape({}),
  onAbortEditFunc: PropTypes.func,
  index: PropTypes.number,
  selected: PropTypes.bool,
  onClickFunc: PropTypes.func,
  edited: PropTypes.bool,
  onStartEditFunc: PropTypes.func,
};

export default CalendarDayView;
