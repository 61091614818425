import { connect } from 'react-redux';

import { changeCurrentCompany, changeCurrentUser } from '@/actions';
import { createOwner, registerWithGoogleOAuth } from '@/actions/auth.jsx';
import OnboardingStepLayout from '@/components/onboarding/OnboardingStepLayout/OnboardingStepLayout.jsx';

const mapStateToProps = state => ({
  deviceInfo: state.reducer.deviceInfo,
});

const mapDispatchToProps = {
  createOwner,
  changeCurrentCompany,
  changeCurrentUser,
  registerWithGoogleOAuth,
};

const OnboardingStepLayoutContainer = connect(mapStateToProps, mapDispatchToProps)(OnboardingStepLayout);

export default OnboardingStepLayoutContainer;
