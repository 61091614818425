import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import {
  cancelSchedulingProcessPerformedByAutoScheduler,
  sendSplitShiftsToAutoscheduler,
} from '@/actions/autoscheduler.js';
import { addRecommendedSchedule } from '@/actions/recommendedSchedule.js';
import { hideModal, showModal, toggleAutoGenerateScheduleStatus } from '@/actions/uiState.js';
import { AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes.js';

import AutoGenerateStepsModal from './AutoGenerateStepsModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === AUTO_GENERATE_STEPS_MODAL,
  modalObject: state.reducer.uiState.modalObject[AUTO_GENERATE_STEPS_MODAL],
  userJobTitles: state.reducer.userJobTitles,
  userTemplates: state.reducer.userTemplates,
  userEmployees: state.reducer.userEmployees,
  contracts: state.reducer.contracts,
  mainDateStore: state.reducer.mainDateStore,
});

const mapDispatchToProps = {
  hideModal,
  openModal: showModal,
  sendSplitShiftsToAutoscheduler,
  addRecommendedSchedule,
  cancelSchedulingProcessPerformedByAutoScheduler,
  toggleAutoGenerateScheduleStatus,
  showConfirmModal,
};

const AutoGenerateStepsModalContainer = connect(mapStateToProps, mapDispatchToProps)(AutoGenerateStepsModal);

export default AutoGenerateStepsModalContainer;
