/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages,FormattedMessage } from 'react-intl';

import MDComment from '@/components/common/inputs/MDComment/MDComment.jsx';
import { MDSelect,MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import { getAvailabilityTypeForGivenAvailabilityObject } from '@/utils/availabilitiesHelpers';
import { inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  title: {
    id: 'availability.editModal.title',
    defaultMessage: 'Edytuj Dostępność',
  },
  select: {
    id: 'availability.editModal.select',
    defaultMessage: 'Dostępność',
  },
});

const EditAvailabilityModal = (props, context) => {
  const [state, setState] = useState({
    selected:
      getAvailabilityTypeForGivenAvailabilityObject(props.modalObject.availability, props.userCustomTypes) || {},
    comment: props.modalObject.availability ? props.modalObject.availability.optional_comment : '',
    hours: '__:__-__:__',
    errorMessage: '',
  });

  const getAvailabilityType = (availabilityObject, userCustomTypes) => {
    if (typeof availabilityObject === 'undefined') {
      return defaultAvailabilityTypes[0];
    }
    switch (availabilityObject.type) {
      case 'availability':
      case 'unavailability':
      case 'freetime':
        return defaultAvailabilityTypes.find(t => t.type === availabilityObject.type);
      case 'custom':
        return userCustomTypes.find(t => t.id === availabilityObject.type_id);
      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (props.modalObject) {
      const { modalObject } = props;
      const { availability } = modalObject;
      const hours = availability ? availability.hours : '__:__-__:__';
      const comment = availability ? availability.optional_comment : '';
      let selected = getAvailabilityType(availability, props.userCustomTypes);
      if (!selected) selected = {};
      setState(prevState => ({
        ...prevState,
        selected,
        comment,
        hours,
      }));
    }
  }, [props.modalObject]);

  const createAvailabilityObject = () => {
    const { selected, comment } = state;

    const { modalObject, company_id } = props;
    const { employee, availability } = modalObject;

    const availabilityObject = {
      type: selected.type,
      employee,
      date: availability.date,
      company_id,
      id: availability.id,
      type_id: selected.id,
      count_hours: selected.count_hours,
      count_hours_in_payroll: selected.count_hours_in_payroll,
    };

    if (selected.requires_time === true) {
      availabilityObject.hours = state.hours;
    }
    if (selected.optional_comment === true) {
      availabilityObject.optional_comment = comment;
    }
    if (selected.requires_approval === true) {
      availabilityObject.draft = true;
    }

    return availabilityObject;
  };

  const onSubmit = () => {
    const { selected } = state;

    if (selected.requires_time === true) {
      const err = inputValidation('workingHours', state.hours);
      if (err !== '' || selected === null) {
        setState(prevState => ({
          ...prevState,
          errorMessage: err,
        }));
        return;
      }
    }

    const availabilityObject = createAvailabilityObject();

    props.changeAvailability(availabilityObject);
    props.closeEditAvailabilityModal();
  };

  const validateHours = e => {
    const { value } = e.target;
    setState(prevState => ({
      ...prevState,
      errorMessage: inputValidation('workingHours', value),
    }));
  };

  const deleteAvailability = () => {
    const { employee, availability } = props.modalObject;
    props.deleteAvailability(employee.id, availability);
    props.closeEditAvailabilityModal();
  };

  const handleInputChange = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getFooterOptions = () => {
    if (!state.selected.requires_approval || (props.modalObject.availability && props.modalObject.availability.draft)) {
      return [
        {
          icon: 'remove_circle_outline',
          text: <FormattedMessage id="availability.editModal.removeAvailability" defaultMessage="Usuń dostępność" />,
          handleClick: deleteAvailability,
        },
      ];
    }
    return [];
  };

  const { modalObject, isEmployee, userCustomTypes } = props;
  const avaTypes = isEmployee ? userCustomTypes.filter(type => type.visible_for_employees) : userCustomTypes;
  const { employee } = modalObject;

  const availabilityTypes = [...defaultAvailabilityTypes, ...avaTypes];

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={props.closeEditAvailabilityModal}
      modifiers={['tall', 'narrow']}
      title={context.intl.formatMessage(messages.title, {})}
      onSubmit={onSubmit}
      errorMessage={state.errorMessage && context.intl.formatMessage(state.errorMessage, {})}
      footerOptions={getFooterOptions()}
    >
      {!isEmployee ? (
        <fieldset className="form-group">
          <FormattedMessage
            id="availability.editModal.headerMessage"
            defaultMessage="Edytuj dostępność w dniu {date} {employeeName}"
            values={{
              date: modalObject.availability ? modalObject.availability.date : '',
              employeeName: employee ? `dla ${employee.first_name} ${employee.last_name}` : '',
            }}
          />
        </fieldset>
      ) : null}
      <div className="radios">
        <MDSelect
          id="availability"
          name={context.intl.formatMessage(messages.select, {})}
          defaultValue={state.selected.id}
          options={availabilityTypes.map(availabilityType => ({
            key: availabilityType.name,
            value: availabilityType.id,
          }))}
          onChange={id =>
            handleInputChange(
              'selected',
              availabilityTypes.find(u => u.id === id),
            )
          }
          closeOnClick
          withSearch
        />
      </div>
      {state.selected.requires_time ? (
        <MDTimeInput
          value={state.hours}
          onChange={e => handleInputChange('hours', e.target.value)}
          onBlur={validateHours}
          label={<FormattedMessage id="availability.editModal.hours" defaultMessage="Godziny" />}
          modifiers={['modal']}
          short={false}
        />
      ) : null}
      {state.selected.optional_comment ? (
        <MDComment
          name="comment"
          label={<FormattedMessage id="availability.editModal.comment" defaultMessage="Komentarz" />}
          value={state.comment}
          onChange={e => handleInputChange('comment', e.target.value)}
        />
      ) : null}
    </MDKadroModal>
  );
};

EditAvailabilityModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EditAvailabilityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalObject: PropTypes.shape({
    availability: PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      hours: PropTypes.string,
      draft: PropTypes.bool,
      optional_comment: PropTypes.string,
    }),
    employee: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  company_id: PropTypes.string,
  changeAvailability: PropTypes.func,
  closeEditAvailabilityModal: PropTypes.func,
  deleteAvailability: PropTypes.func,
  isEmployee: PropTypes.bool,
};

export default EditAvailabilityModal;
