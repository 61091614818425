import classNames from 'classnames';
import PropTypes from 'prop-types';

import ExportsWidgetHeader from './ExportsWidgetHeader/ExportsWidgetHeader.jsx';
import ExportsWidgetRow from './ExportsWidgetRow/ExportsWidgetRow.redux.js';

import './ExportsWidgetBody.scss';

const ExportsWidgetBody = ({ pendingExports, setIsWidgetBodyVisible, isWidgetBodyVisible }) => {
  const className = classNames('k-exportsWidgetBody', { 'k-exportsWidgetBody__hidden': !isWidgetBodyVisible });
  return (
    <div className={className}>
      <ExportsWidgetHeader setIsWidgetBodyVisible={setIsWidgetBodyVisible} />
      <div className="k-exportsWidgetBody__list">
        {pendingExports.map(({ name, id, link, status, fileIconText }, index) => (
          <ExportsWidgetRow key={index} {...{ name, link, id, status, fileIconText }} />
        ))}
      </div>
    </div>
  );
};

ExportsWidgetBody.propTypes = {
  pendingExports: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      isShownInExportsWidget: PropTypes.bool,
      link: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
  isWidgetBodyVisible: PropTypes.bool,
  setIsWidgetBodyVisible: PropTypes.func,
};

export default ExportsWidgetBody;
