import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { showNotificationPanel } from '@/actions/uiState';
import { useMediaQuery } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectCurrentActiveNavBarElementId, selectShowNotificationPanel } from '@/redux-store/uiState';
import { selectIsLoggedUserEmployee } from '@/redux-store/userPermissions/userPermissions.selectors';
import { allNavs, NOTIFICATIONS_PATH } from '@/utils/routes';

import { UseSideBarValuesType } from '../useSideBar';

export const useSidebarNotification = (closeMobileMenu: UseSideBarValuesType['closeMobileMenu']) => {
  const isNotificationPanelShown = useAppSelector(selectShowNotificationPanel);
  const isLoggedUserEmployee = useAppSelector(selectIsLoggedUserEmployee);
  const currentActiveNavBarElementId = useAppSelector(selectCurrentActiveNavBarElementId);
  const { isAtLeastTabletLandscape } = useMediaQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const notificationItem = useMemo(() => allNavs().find(({ path }) => path === NOTIFICATIONS_PATH)?.id, []);

  const isActive = isNotificationPanelShown || currentActiveNavBarElementId === notificationItem;

  const openNotification = useCallback(() => {
    if (isAtLeastTabletLandscape) return dispatch(showNotificationPanel());
    closeMobileMenu();
    navigate(NOTIFICATIONS_PATH);
  }, [closeMobileMenu, isAtLeastTabletLandscape, dispatch, navigate]);

  return { isActive, currentActiveNavBarElementId, isLoggedUserEmployee, openNotification };
};
