import PropTypes from 'prop-types';
import { useCallback } from 'react';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import { ASCENT, DESCEND, DEVICE_HEADER, DEVICES_COLUMN_NAME } from '@/constants/device';

import DeviceHeaderCell from './DeviceHeaderCell';

import './devicesHeader.scss';

const DevicesHeader = ({
  sortFunc,
  uiData,
  selectedDevices,
  relevantDevices,
  relevantSelectedDevicesIds,
  toggleAllCheckboxes,
}) => {
  const sortClick = useCallback(
    columnId => {
      sortFunc(columnId, DEVICES_COLUMN_NAME);
    },
    [sortFunc],
  );

  const toogleAllCheckboxes = useCallback(() => {
    toggleAllCheckboxes(
      relevantDevices.map(c => c.uuid).filter(c => !relevantSelectedDevicesIds.includes(c)),
      DEVICES_COLUMN_NAME,
    );
  }, [toggleAllCheckboxes, relevantDevices, relevantSelectedDevicesIds]);

  const checked = selectedDevices.length && !relevantDevices.some(device => !selectedDevices.includes(device.uuid));
  const { sortDecreesing, sortColumn } = uiData;

  return (
    <thead>
      <tr>
        <Checkbox className="k-devicesHeader__checkbox" checked={checked} toggle={toogleAllCheckboxes} />
        {DEVICE_HEADER.map(deviceHeader => {
          const { columnId, message, title, option } = deviceHeader;
          let sort;
          if (sortColumn === columnId) {
            sort = sortDecreesing ? DESCEND : ASCENT;
          }

          return (
            <DeviceHeaderCell
              key={columnId}
              onClick={() => sortClick(columnId)}
              message={message}
              isTitle={title}
              isOption={option}
              sort={sort}
            />
          );
        })}
      </tr>
    </thead>
  );
};

DevicesHeader.propTypes = {
  uiData: PropTypes.shape({
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.string,
  }),
  selectedDevices: PropTypes.arrayOf(PropTypes.string),
  relevantDevices: PropTypes.arrayOf(PropTypes.shape({})),
  relevantSelectedDevicesIds: PropTypes.arrayOf(PropTypes.string),
  toggleAllCheckboxes: PropTypes.func,
  sortFunc: PropTypes.func,
};

export default DevicesHeader;
