import Indicator from '@/components/common/Indicator/Indicator.tsx';
import MDTooltip from '@/components/common/inputs/MDTooltip/MDTooltip';
import Panel from '@/components/common/UI/Panel/Panel';

import './widget.scss';

type WidgetProps = {
  title?: string;
  value: React.ReactNode | string;
  indicator: number;
  indicatorContent: string;
  subTitle: string;
  tooltipMessage?: string;
};
const Widget = ({ title, subTitle, value, indicator, indicatorContent, tooltipMessage }: WidgetProps) => (
  <Panel className="k-reportWidget">
    {title ? (
      <div className="k-reportWidget__titleContainer">
        <div className="k-reportWidget__title">
          {title}
          <MDTooltip text={tooltipMessage} />
        </div>
        <Indicator value={indicator} tootltipContent={indicatorContent} />
      </div>
    ) : null}
    <div className="k-reportWidget_subTitleContainer">
      <div className="k-reportWidget__subTitle">{subTitle}</div>
      <div className="k-reportWidget__value">{value}</div>
    </div>
  </Panel>
);

export default Widget;
