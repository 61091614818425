import { getBudgetEstimates } from '@/actions/budgetEstimates.js';
import { CHANGE_SCHEDULE_LOCATION_FILTER } from '@/constants/ActionTypes.js';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { getNewDataForDate } from '../index';

export const changeScheduleLocationFilterSuccess = locations => ({
  type: CHANGE_SCHEDULE_LOCATION_FILTER,
  payload: locations,
});

export const changeScheduleLocationFilter = (locations, preventUpdate = false) => (dispatch, getState) => {
  const { mainDateStore } = getState().reducer;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  dispatch(changeScheduleLocationFilterSuccess(locations));
  if (locations.length > 0 && !preventUpdate) {
    dispatch(getNewDataForDate(from, to));
  } else {
    dispatch(getBudgetEstimates(from, to, locations));
  }
};
