import { connect } from 'react-redux';

import { changeAbsenceFilter } from '@/actions/absenceFilter.js';
import { mainDateChangeMode } from '@/actions/mainDate.js';

import TopBarAbsenceFilter from './TopBarAbsenceFilter.jsx';

const mapStateToProps = state => ({
  absenceFilter: state.reducer.absenceFilter,
  absencesTypes: state.reducer.absences.absencesTypes,
});

const mapDispatchToProps = {
  changeAbsenceFilter,
  mainDateChangeMode,
};

const TopBarAbsenceFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarAbsenceFilter);

export default TopBarAbsenceFilterContainer;
