import { FormattedMessage } from 'react-intl';

import { createEvent } from '@/utils/inputHelpers.js';

import RepeatShift from '../RepeatShift/RepeatShift';
import AddAbsenceTab from './AddAbsenceTab/AddAbsenceTab.redux';
import { AddFreeDayTab } from './AddFreeDayTab/AddFreeDayTab';
import AddShiftTab from './AddShiftTab/AddShiftTab.redux';

export const displayRelevantTab = (props, state, handleInputChange, onSubmitShift) => {
  const changeRepeatShift = (name, value) => {
    handleInputChange(createEvent(name, value));
  };

  switch (state.activeTab) {
    case 0:
      return (
        <>
          <AddShiftTab
            workingHours={state.working_hours}
            comment={state.comment}
            employeeId={state.employee?.id}
            errors={state.errors}
            selectedJobTitle={state.selectedJobTitle}
            isEditing={Boolean(props.modalObject.shift)}
            handleInputChange={handleInputChange}
            onSubmitShift={onSubmitShift}
            displayRepeat={state.displayRepeat}
            date={props.modalObject.date || props.modalObject.shift?.date}
            disableInputsInModal={props.modalObject?.isLoanedEmployee || props.modalObject?.shift?.isLoaned}
            permissions={props.userPermissions.permissions}
          />
          {!props.modalObject.shift && (
            <RepeatShift
              displayRepeat={state.displayRepeat}
              activeRepeatTab={state.activeRepeatTab}
              selectedRange={state.selectedRange}
              selectedChoices={state.selectedChoices}
              selectedWeekdays={state.selectedWeekdays}
              perWhichDay={state.perWhichDay}
              handleInputChange={changeRepeatShift}
              error={state.errors.repeatedShiftOverlap}
              nonWorkingDays={props.nonWorkingDays}
              toggleNonWorkingDays={props.toggleNonWorkingDays}
              minDate={state.minDate}
              maxDate={state.maxDate}
            />
          )}
        </>
      );
    case 1:
      return (
        <AddAbsenceTab
          absenceOmitWeekends={state.absenceOmitWeekends}
          absenceOmitHolidays={state.absenceOmitHolidays}
          countOnlyDaysWithShifts={state.countOnlyDaysWithShifts}
          allDay={state.allDay}
          absenceComment={state.absenceComment}
          absenceHours={state.absenceHours}
          selectedEmployeeId={props.modalObject?.employee?.id}
          selectedAbsence={state.selectedAbsence}
          selectedAbsenceRange={state.selectedAbsenceRange}
          selectedDay={state.selectedDay}
          handleInputChange={handleInputChange}
          errors={state.errors}
          isOvertimeCollection={props.modalObject.isOvertimeCollection}
          overtimeCycle={state.overtimeCycle}
          multiplier={state.multiplier}
          amount50={state.amount50}
          amount100={state.amount100}
          amountPotential={state.amountPotential}
          availableOvertimes={state.availableOvertimes}
        />
      );
    case 2: {
      const repeatComponent = !props.modalObject?.freeDayItemId ? (
        <RepeatShift
          displayRepeat={state.displayRepeat}
          activeRepeatTab={state.activeRepeatTab}
          selectedRange={state.selectedRange}
          selectedChoices={state.selectedChoices}
          selectedWeekdays={state.selectedWeekdays}
          perWhichDay={state.perWhichDay}
          handleInputChange={changeRepeatShift}
          error={state.errors.repeatedShiftOverlap}
          nonWorkingDays={props.nonWorkingDays}
          toggleNonWorkingDays={props.toggleNonWorkingDays}
          minDate={state.minDate}
          maxDate={state.maxDate}
          hideNonWorkingDays
          repeatShiftMessage={
            <FormattedMessage id="addFreeDayTab.freeDaysRepeat" defaultMessage="Powtórz oznaczenie dni wolnych" />
          }
        />
      ) : null;
      return (
        <AddFreeDayTab
          date={props.modalObject.date || props.modalObject.shift?.date}
          employeeId={state.employee?.id}
          freeDayItemId={props.modalObject.freeDayItemId}
          selectedFreeDayId={state.selectedFreeDayId}
          handleInputChange={handleInputChange}
          repeatComponent={repeatComponent}
        />
      );
    }
    default:
      return null;
  }
};
