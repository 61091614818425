import { connect } from 'react-redux';

import DropArea from '@/components/scheduler/DropArea.jsx';

const mapStateToProps = state => ({
  dateMode: state.reducer.mainDateStore.dateMode,
});
const mapDispatchToProps = {};
const DropAreaContainer = connect(mapStateToProps, mapDispatchToProps)(DropArea);

export default DropAreaContainer;
