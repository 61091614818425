import { connect } from 'react-redux';

import EmptyStateScheduleView from './EmptyStateScheduleView';

const mapStateToProps = state => ({
  noDefinedEmployees: !state.reducer.userEmployees.length,
});

const EmptyStateScheduleViewContainer = connect(mapStateToProps, null)(EmptyStateScheduleView);

export default EmptyStateScheduleViewContainer;
