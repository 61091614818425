import classnames from 'classnames';
import { PropTypes } from 'prop-types';

const Panel = props => {
  let modifiers = props.modifiers || [];
  modifiers = modifiers.map(modifier => `panel--${modifier}`);
  const panelClassNames = classnames('panel', modifiers);

  return (
    <div className={panelClassNames}>
      {props.title ? (
        <div className="panel__header">
          <div className="panel__headerText">{props.title}</div>
          {props.headerItems}
        </div>
      ) : null}
      <div className="panel__body">{props.children}</div>
    </div>
  );
};
Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  modifiers: PropTypes.arrayOf(PropTypes.string),
  headerItems: PropTypes.node,
  children: PropTypes.node,
};
export default Panel;
