import { connect } from 'react-redux';

import { changeCustomSorting } from '@/actions/employeeSorting';

import Component from './ScheduleTableRowTitleDropArea.jsx';

const mapDispatchToProps = {
  changeCustomSorting,
};

export default connect(null, mapDispatchToProps)(Component);
