import { useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';

import { getClasses } from './GroupBar.utils';

export const useGroupBar = (isOpen: boolean, recordsAmount?: number) => {
  const dateArray = useAppSelector(selectDateArray);
  const colSpan = dateArray.length;

  const classes = useMemo(() => getClasses(isOpen, recordsAmount), [isOpen, recordsAmount]);

  return { classes, colSpan };
};
