import classnames from 'classnames';
import PropTypes from 'prop-types';

import './MDChip.scss';

const MDChip = props => {
  const modifiers = !Array.isArray(props.modifiers) ? props.modifiers.split(' ') : props.modifiers;
  const className = classnames('mdChip', props.className, ...modifiers.map(modifier => `mdChip--${modifier}`));

  return <div className={className}>{props.children}</div>;
};

MDChip.defaultProps = {
  modifiers: [],
};

MDChip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default MDChip;
