import {
  ASSIGN_USER_OPEN_SHIFT_SUCCESFUL,
  CHANGE_OPEN_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_SUCCESFUL,
  DELETE_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFTS_SUCCESFUL,
  MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
  PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL,
  USER_APPLY_OPEN_SHIFT_SUCCESFUL,
  USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL,
} from '@/constants/ActionTypes.js';

const initialState = {
  structure: {},
  data: {},
};

const mapAndSortOpenShiftIds = (ids, openShiftMap) =>
  ids
    .map(openShiftId => openShiftMap[openShiftId])
    .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1))
    .map(openShift => openShift.id);

const mapOpenShifts = openShiftsArray => {
  if (!openShiftsArray) return {};
  const openShiftMap = openShiftsArray.reduce((sum, openShift) => ({ ...sum, [openShift.id]: openShift }), {});
  const structure = openShiftsArray.reduce((sum, openShift) => {
    const { date, location } = openShift;
    const result = { ...sum };

    result[location.id] = {
      ...(sum[location.id] || {}),
      [date]: [...(sum?.[location.id]?.[date] || []), openShift.id],
    };
    return result;
  }, {});
  Object.keys(structure).forEach(locationId => {
    Object.keys(structure[locationId]).forEach(date => {
      structure[locationId][date] = mapAndSortOpenShiftIds(structure[locationId][date], openShiftMap);
    });
  });

  return [structure, openShiftMap];
};

const addOpenShiftToStructure = (structure, openShift, openShiftMap) => {
  const { location, date, id: openShiftId } = openShift;
  let newStructure = { ...structure };
  newStructure = {
    ...newStructure,
    [location.id]: {
      ...(newStructure[location.id] || {}),
      [date]: [...(newStructure[location.id]?.[date] || []), openShiftId],
    },
  };
  if (newStructure[location.id][date].length > 1) {
    newStructure[location.id][date] = mapAndSortOpenShiftIds(newStructure[location.id][date], openShiftMap);
  }
  return newStructure;
};

const openShifts = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPEN_SHIFTS_SUCCESFUL: {
      const [structure, data] = mapOpenShifts(action.payload);
      return { ...state, structure: { ...state.structure, ...structure }, data: { ...state.data, ...data } };
    }
    case CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL:
    case CREATE_OPEN_SHIFT_SUCCESFUL: {
      const newOpenShift =
        action.type === CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL ? action.payload.openShift : action.payload;
      const newData = { ...state.data, [newOpenShift.id]: newOpenShift };
      const newStructure = addOpenShiftToStructure(state.structure, newOpenShift, newData);
      return { ...state, structure: newStructure, data: newData };
    }
    case CHANGE_OPEN_SHIFT_SUCCESFUL:
    case GET_OPEN_SHIFT_SUCCESFUL:
    case ASSIGN_USER_OPEN_SHIFT_SUCCESFUL: {
      const { id: openShiftId, working_hours: workingHours, shifts_remaining: remainingShifts } = action.payload;
      const currentValue = state.data[openShiftId];
      if (!currentValue) return state;
      if (remainingShifts === 0) {
        const { location, date } = currentValue;
        const newData = { ...state.data };
        delete newData[openShiftId];
        const newStructure = {
          ...state.structure,
          [location.id]: {
            ...state.structure[location.id],
            [date]: state.structure[location.id][date].filter(id => id !== openShiftId),
          },
        };
        return { ...state, data: newData, structure: newStructure };
      }
      if (workingHours && currentValue.working_hours !== workingHours) {
        const { location, date } = currentValue;
        const newData = { ...state.data, [openShiftId]: { ...currentValue, ...action.payload } };
        const newStructure = {
          ...state.structure,
          [location.id]: {
            ...state.structure[location.id],
            [date]: mapAndSortOpenShiftIds(state.structure[location.id][date], newData),
          },
        };
        return { ...state, data: newData, structure: newStructure };
      }
      return {
        ...state,
        data: {
          ...state.data,
          [openShiftId]: {
            ...currentValue,
            ...action.payload,
            job_title: { ...currentValue.job_title, ...action.payload.job_title },
          },
        },
      };
    }
    case DELETE_OPEN_SHIFT_SUCCESFUL: {
      const openShiftToDelete = state.data[action.payload];
      if (!openShiftToDelete) return state;
      const { date, location } = openShiftToDelete;
      const newStructure = {
        ...state.structure,
        [location.id]: {
          ...state.structure[location.id],
          [date]: state.structure[location.id][date].filter(id => id !== action.payload),
        },
      };
      const newData = { ...state.data };
      delete newData[action.payload];
      return { ...state, structure: newStructure, data: newData };
    }
    case MASS_DELETE_OPEN_SHIFTS_SUCCESFUL: {
      const formattedData = Object.keys(state.data).reduce(
        (result, openShift) =>
          state.data[openShift] === undefined ? result : { ...result, [openShift]: state.data[openShift] },
        {},
      );

      const newStructure = Object.entries(state.structure).reduce((result, [locationId, openShiftsByDate]) => {
        const newValues = Object.entries(openShiftsByDate).reduce(
          (res, [date, dateOpenShiftIds]) => ({
            ...res,
            [date]: dateOpenShiftIds.filter(id => !action.payload.includes(id)),
          }),
          {},
        );

        return {
          ...result,
          [locationId]: newValues,
        };
      }, {});
      const newData = Object.values(formattedData).reduce((result, openShift) => {
        if (action.payload.includes(openShift.id)) {
          return result;
        }

        return {
          ...result,
          [openShift.id]: openShift,
        };
      }, {});

      return {
        ...state,
        data: newData,
        structure: newStructure,
      };
    }
    case USER_APPLY_OPEN_SHIFT_SUCCESFUL: {
      const openShiftId = action.payload.id;
      const currentValue = state.data[openShiftId];
      if (!currentValue) return state;
      return {
        ...state,
        data: {
          ...state.data,
          [openShiftId]: {
            ...currentValue,
            eager_users_count: currentValue.eager_users_count + 1,
            users: [...currentValue.users, action.payload.currentUser.user],
          },
        },
      };
    }
    case USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL: {
      const openShiftId = action.payload.id;
      const currentValue = state.data[openShiftId];
      if (!currentValue) return state;
      return {
        ...state,
        data: {
          ...state.data,
          [openShiftId]: {
            ...currentValue,
            eager_users_count: currentValue.eager_users_count - 1,
            users: currentValue.users.filter(u => u.id !== action.payload.employeeId),
          },
        },
      };
    }

    case PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL: {
      const { dateArray, locationId, selectedJobTitlesIds } = action.payload;
      const locationStructure = state.structure[locationId];
      if (!locationStructure) return state;
      const idsToPublish = dateArray.reduce((sum, date) => [...sum, ...(locationStructure[date] || [])], []);

      const newData = { ...state.data };
      idsToPublish.forEach(id => {
        const shiftToPublish = selectedJobTitlesIds.includes(state.data[id].job_title.id);
        if (shiftToPublish) {
          newData[id] = { ...newData[id], draft: false };
        }
      });
      return { ...state, data: newData };
    }

    default:
      return state;
  }
};

export default openShifts;
