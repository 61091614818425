import { connect } from 'react-redux';

import { toggleGroup } from '@/actions/companymanage/roles.js';

import RolesTableLeftRow from './RolesTableLeftRow.jsx';

const mapStateToProps = state => ({
  toggledGroups: state.reducer.roles.toggledGroups,
});

const mapDispatchToProps = {
  toggleGroup,
};

const RolesTableLeftRowContainer = connect(mapStateToProps, mapDispatchToProps)(RolesTableLeftRow);

export default RolesTableLeftRowContainer;
