import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employeesAmount: {
    id: 'selectPackageEmployeesAndPrice.employeesAmount',
    defaultMessage: 'Liczba pracowników',
  },
  monthlyLicence: {
    id: 'selectPackageEmployeesAndPrice.monthlyLicence',
    defaultMessage: 'Abonament miesięczny',
  },
});
