import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';

import { getTotalLabourCost } from '../../ScheduleBudgetTable.helpers';

const ScheduleBudgetTableTotalCell = ({ shiftsSummary = {}, dateArray, budgetEstimates = {}, summaryLabourLimit }) => {
  const { totalBudgetEstimates, totalLabourCost } = getTotalLabourCost(dateArray, budgetEstimates, shiftsSummary);
  const totalLabourCostPercent = (totalLabourCost / totalBudgetEstimates) * 100;

  return (
    <td className="k-budgetTable__leftColumn">
      <div className="k-budgetTable__leftColumnData">
        <FormattedMessage id="schedule.budget.plannedLabourLimit" defaultMessage="%COL Target" />
        <span className="k-budgetTable__leftColumnDataValue">
          {`${roundToTwoSigDigits(summaryLabourLimit / 100)} %`}
        </span>
      </div>
      <div className="k-budgetTable__leftColumnData">
        <FormattedMessage id="schedule.budget.realLabourLimit" defaultMessage="%COL Plan" />
        <span className="k-budgetTable__leftColumnDataValue">{roundToTwoSigDigits(totalLabourCostPercent)} %</span>
      </div>
    </td>
  );
};

ScheduleBudgetTableTotalCell.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  summaryLabourLimit: PropTypes.number,
  shiftsSummary: PropTypes.shape({}),
  budgetEstimates: PropTypes.shape({}),
};

export default ScheduleBudgetTableTotalCell;
