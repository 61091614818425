import { RootState } from '@/redux-store';

export const selectPlannedAndWorkedData = (state: RootState) => state.reducer.reports.data.charts.plannedAndWorkedData;

export const selectJobTitlesChartData = (state: RootState) => state.reducer.reports.data.charts.jobTitlesChartData;

export const selectUserLocationsChartData = (state: RootState) => state.reducer.reports.data.charts.locationsChartData;

export const selectAbsencesAndAttendancesOrShiftsChartData = (state: RootState) =>
  state.reducer.reports.data.charts.absencesAndAttendancesOrShiftsChartData;

export const selectAbsencesChartData = (state: RootState) => state.reducer.reports.data.charts.absencesChartData;

export const selectTableData = (state: RootState) => state.reducer.reports.data.tableData;
