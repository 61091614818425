import { CHANGE_SORTING_PAYROLL_LOCATION, ENABLE_PAYROLL_LOAD_BUTTON } from '@/constants/ActionTypes.js';

export const changeSorting = sortColumn => dispatch => {
  dispatch({
    type: CHANGE_SORTING_PAYROLL_LOCATION,
    payload: sortColumn,
  });
};

export const enableLoadButton = () => ({
  type: ENABLE_PAYROLL_LOAD_BUTTON,
});
