import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SmallNumberWidget from '@/components/common/UI/SmallNumberWidget.jsx';
import WidgetPanel from '@/components/common/UI/WidgetPanel.jsx';
import { ATTENDANCE_WIDGET_PANEL_MODIFIERS } from '@/constants/modifiers';

const AttendanceStatisticsHeader = ({ attendancesData }) => {
  const numberOfAttendance = attendancesData.present + attendancesData.attendance_without_shift;
  return (
    <WidgetPanel
      modifiers={ATTENDANCE_WIDGET_PANEL_MODIFIERS}
      items={[
        <SmallNumberWidget
          key={1}
          title={<FormattedMessage id="attendances.statistics.attendances" defaultMessage="Obecności" />}
          color="green"
          number={numberOfAttendance}
        />,
        <SmallNumberWidget
          key={2}
          title={
            <FormattedMessage id="attendances.statistics.extraAttendances" defaultMessage="Obecności niezaplanowane" />
          }
          color="orange"
          number={attendancesData.attendance_without_shift}
        />,
        <SmallNumberWidget
          key={3}
          title={<FormattedMessage id="attendances.statistics.lateAtWrok" defaultMessage="Spóźnienia" />}
          color="yellow"
          number={attendancesData.attedance_late_start}
        />,
        <SmallNumberWidget
          key={4}
          title={<FormattedMessage id="attendances.statistics.absences" defaultMessage="Nieobecności" />}
          color="red"
          number={attendancesData.absence}
        />,
      ]}
    />
  );
};

AttendanceStatisticsHeader.propTypes = {
  attendancesData: PropTypes.shape({
    present: PropTypes.number,
    attendance_without_shift: PropTypes.number,
    attedance_late_start: PropTypes.number,
    absence: PropTypes.number,
  }),
};

export default AttendanceStatisticsHeader;
