import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editJobTitle: {
    id: 'companymanage.jobtitles.editJobtitle',
    defaultMessage: 'Edytuj stanowisko',
  },
  addJobTitle: {
    id: 'companymanage.jobtitles.addJobtitle',
    defaultMessage: 'Dodaj stanowisko',
  },
  jobTitle: {
    id: 'companymanage.jobTitles.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  wage: {
    id: 'companymanage.jobTitles.wage',
    defaultMessage: 'Stawka',
  },
  color: {
    id: 'companymanage.jobtitles.color',
    defaultMessage: 'Kolor',
  },
  factor: {
    id: 'companymanage.jobTitles.factor',
    defaultMessage: 'Mnożnik',
  },
});
