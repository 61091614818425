import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';

import Icon from '@/components/common/Basic/Icon/Icon';

import './ExportsWidgetHeader.scss';

const messages = defineMessages({
  history: {
    id: 'exportsWidget.header.historyLink',
    defaultMessage: 'Historia eksportów',
  },
});

const ExportsWidgetHeader = ({ setIsWidgetBodyVisible }, { intl }) => (
  <div className="k-exportsWidgetHeader">
    <Link className="k-exportsWidgetHeader__historyLink" to="/exports/history" onlyActiveOnIndex={false}>
      {intl.formatMessage(messages.history)}
    </Link>
    <button onClick={() => setIsWidgetBodyVisible(false)} className="k-exportsWidgetHeader__minimize">
      <Icon name="remove" type="material-symbols" />
    </button>
  </div>
);

ExportsWidgetHeader.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
ExportsWidgetHeader.propTypes = {
  setIsWidgetBodyVisible: PropTypes.func,
};

export default ExportsWidgetHeader;
