import { reportsModesIds } from '@/constants/viewDateModes.ts';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';

import TopBarCalendar from './filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmploymentConditionsFilter from './filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarMultipleLocationFilter from './filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from './TopBar.jsx';

const TopBarReports = () => (
  <TopBar
    leftElements={[
      <TopBarCalendar key="calswitch" modes={reportsModesIds} />,
      <TopBarMultipleLocationFilter key="multipleLocationFilter" />,
      <TopBarJobTitleFilterContainer key="jobTitleFilter" />,
      <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
    ]}
    rightElements={[]}
  />
);

TopBarReports.propTypes = {};

export default TopBarReports;
