import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton.jsx';
import { ABSENCE_TYPE_MODAL } from '@/constants/modalTypes.js';

const messages = defineMessages({
  absenceTypesRowOptionsEditIcon: {
    id: 'absenceTypesRowOptions.edit.icon',
    defaultMessage: 'Edytuj',
  },
  absenceTypesRowOptionsDeleteIcon: {
    id: 'absenceTypesRowOptions.delete.icon',
    defaultMessage: 'Usuń',
  },
  absenceTypesRowOptionsDeleteIconBlocked: {
    id: 'absenceTypesRowOptions.delete.iconBlocked',
    defaultMessage: 'Urlop wypoczynkowy nie może zostać usunięty',
  },
});

const AbsenceTypesRowOptions = (props, context) => (
  <>
    <MDIconButton
      icon="create"
      label={context.intl.formatMessage(messages.absenceTypesRowOptionsEditIcon)}
      onClick={() =>
        props.showModal(ABSENCE_TYPE_MODAL, {
          absenceType: props.rowData,
        })
      }
    />
    <MDIconButton
      icon="delete"
      label={
        props.rowData.is_default_uw_absence
          ? context.intl.formatMessage(messages.absenceTypesRowOptionsDeleteIconBlocked)
          : context.intl.formatMessage(messages.absenceTypesRowOptionsDeleteIcon)
      }
      onClick={() => props.deleteAbsenceTypes([props.rowData])}
      disabled={props.rowData.is_default_uw_absence || props.rowData.isOvertimeCollection}
    />
  </>
);

AbsenceTypesRowOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceTypesRowOptions.propTypes = {
  rowData: PropTypes.shape({
    is_default_uw_absence: PropTypes.bool,
    isOvertimeCollection: PropTypes.bool,
  }),
  deleteAbsenceTypes: PropTypes.func,
  showModal: PropTypes.func,
};

export default AbsenceTypesRowOptions;
