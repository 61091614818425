import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { MDRadios } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import {
  getRecommendedScheduleVersionsOptions,
  MODAL_MODIFIERS,
  RECOMMENDED_SCHEDULE_VERSIONS,
} from './LoadRecommendedScheduleModal.helpers';
import { messages } from './LoadRecommendedScheduleModal.messages';

const LoadRecommendedSchedule = (props, { intl }) => {
  const [recommendedScheduleVersion, setRecommendedScheduleVersion] = useState(
    RECOMMENDED_SCHEDULE_VERSIONS.fromExcel.id,
  );
  const recommendedScheduleVersionsOptions = useMemo(() => getRecommendedScheduleVersionsOptions(intl), []);

  const onSubmit = useCallback(() => {
    const { modalType } = RECOMMENDED_SCHEDULE_VERSIONS[recommendedScheduleVersion];
    props.showModal(modalType);
  }, [recommendedScheduleVersion]);

  return (
    <MDKadroModal
      show={props.isVisibleModal}
      onSubmit={onSubmit}
      onHide={props.hideModal}
      title={intl.formatMessage(messages.title)}
      modifiers={MODAL_MODIFIERS}
      confirmText={intl.formatMessage(messages.forth)}
    >
      <MDRadios
        elements={recommendedScheduleVersionsOptions}
        selectedItemType={recommendedScheduleVersion}
        onChangeType={setRecommendedScheduleVersion}
      />
    </MDKadroModal>
  );
};

LoadRecommendedSchedule.contextTypes = {
  intl: PropTypes.shape({}),
};

LoadRecommendedSchedule.propTypes = {
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  isVisibleModal: PropTypes.bool,
};

export default LoadRecommendedSchedule;
