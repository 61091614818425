import { connect } from 'react-redux';

import { getAttendanceImageUrls } from '@/actions/index';

import PhotoPreview from './PhotoPreview.jsx';

const mapStateToProps = (state, ownProps) => ({
  availablePhotos: state.reducer.rcpPhotos.data[ownProps.attendanceId] || null,
});

const mapDispatchToProps = {
  getAttendanceImageUrls,
};

const PhotoPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(PhotoPreview);

export default PhotoPreviewContainer;
