import {
  CHANGE_SINGLE_EMPLOYEE_FILTER,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_MASS_SUCCESS,
} from '@/constants/ActionTypes.js';
import { isEmptyObject } from '@/utils/baseHelpers';

const initialState = {
  id: 'Empty',
  first_name: 'Brak',
  last_name: 'Pracownikow',
  name: 'Brak pracowników!',
};

const singleEmployeeFilter = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SINGLE_EMPLOYEE_FILTER:
      return action.payload;
    case UPDATE_EMPLOYEE: {
      if (action.payload.id === state.id) {
        return {
          ...action.payload,
          name: `${action.payload.first_name} ${action.payload.last_name}`,
        };
      }

      return state;
    }

    case UPDATE_EMPLOYEE_MASS_SUCCESS: {
      const updatedEmployees = action.payload.employees;
      const employee = updatedEmployees.find(({ id }) => id === state.id);
      if (employee && !isEmptyObject(employee))
        return { ...employee, name: `${employee.first_name} ${employee.last_name}` };

      return state;
    }
    default:
      return state;
  }
};
export default singleEmployeeFilter;
