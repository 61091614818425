import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { KadroMultiSelect } from '@/components/common/inputs/MDComponents.jsx';
import ShadowLoader from '@/components/misc/ShadowLoader/ShadowLoader.redux';

import { EmployeeItem } from '../../loanEmployeesProposalModals.helpers';
 import { getEmployeesTitlesMessages } from './loanEmployeesProposalEmployeesStep.helpers';

interface LoanEmployeesProposalEmployeesStepProps {
  employeesItems: EmployeeItem[];
  employeesHandlers: (employeeItem: EmployeeItem[]) => void;
  showLoader?: boolean;
}

const LoanEmployeesProposalEmployeesStep = (
  { employeesItems, employeesHandlers, showLoader }: LoanEmployeesProposalEmployeesStepProps,
  { intl },
) => {
  const employeesMessages = useMemo(() => getEmployeesTitlesMessages(intl, employeesItems), [employeesItems]);

  return (
    <>
      <ShadowLoader show={showLoader} />
      <KadroMultiSelect
        messages={employeesMessages}
        items={employeesItems}
        handlers={employeesHandlers}
        customHeight="250px"
      />
    </>
  );
};

LoanEmployeesProposalEmployeesStep.contextTypes = {
  intl: PropTypes.shape({}),
};

export default LoanEmployeesProposalEmployeesStep;
