import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ButtonBar.scss';

const ButtonBar = ({ children, className }) => {
  const buttonBarClassName = classNames('k-buttonBar', className);
  return <div className={buttonBarClassName}>{children}</div>;
};

ButtonBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ButtonBar;
