import classNames from 'classnames';
import { defineMessages } from 'react-intl';

import { FreeDayItemPostDto } from '@/redux-store/freeDaysMarking';
import { FreeDaysMarkingEnum } from '@/types/freeDaysMarking.types';
import { getDatesFromRepeatObj } from '@/utils/dateHelper';
import { createRepeatObject } from '@/utils/repeatShiftHelpers';
import { getBem } from '@/utils/styles';

import { AddFreeDayModalObject } from './AddFreeDayTab.types';

export const messages = defineMessages({
  chooseFromList: {
    id: 'addFreeDayTab.chooseFromList',
    defaultMessage: 'Wybierz z listy',
  },
  legend: {
    id: 'addFreeDayTab.legend',
    defaultMessage: 'Legenda',
  },
  onlyOneFreeDayByDayError: {
    id: 'addFreeDayTab.onlyOneFreeDayByDayError',
    defaultMessage: 'Wybrany dzień jest już oznaczony jako wolny. Nie można dodać kolejnego oznaczenia dnia wolnego.',
  },
});

const bem = getBem('k-addFreeDayTab');

export const classes = {
  header: bem.element('header'),
  title: bem.element('title'),
  legend: bem.element('legend'),
  freeDaysContainer: bem.element('freeDaysContainer'),
  freeDay: bem.element('freeDay'),
  freeDaySelected: classNames(bem.element('freeDay'), bem.elementModifier('freeDay', 'selected')),
  repeatDays: bem.element('repeatDays'),
  error: bem.element('error'),
};

export const createFreeDayItems = (
  repeatObj: ReturnType<typeof createRepeatObject>,
  { date: singleDate, employee }: AddFreeDayModalObject,
  selectedFreeDayId: FreeDaysMarkingEnum,
): FreeDayItemPostDto[] => {
  const dates = repeatObj ? getDatesFromRepeatObj(repeatObj) : [singleDate];
  return dates.map(date => ({
    date,
    employee_id: employee.id,
    marking_id: selectedFreeDayId,
  }));
};
