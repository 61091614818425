import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState.js';
import { CONFIRM_MODAL } from '@/constants/modalTypes.js';

import ConfirmModal from './ConfirmModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === CONFIRM_MODAL,
  confirmModalObject: state.reducer.uiState.modalObject[CONFIRM_MODAL],
});

const mapDispatchToProps = {
  hideModal,
};

const ConfirmModalContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);

export default ConfirmModalContainer;
