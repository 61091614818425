import { getFromToFromDateStore } from '@/utils/dateHelper';

export const getShiftIdsForSelectedCells = (schedule, employees, shifts, settings, loanedEmployees) => {
  const { selectedCells } = schedule;
  const { locationSettings } = settings;
  const selected = Object.values(selectedCells.selectionsPerLocation).reduce(
    (agg, location) => [
      ...agg,
      ...Object.keys(location.selected).reduce(
        (employeeAgg, employeeId) => [
          ...employeeAgg,
          { employeeId, dates: Object.keys(location.selected[employeeId]) },
        ],
        [],
      ),
    ],
    [],
  );

  const locationIdsToDeleteFrom = Object.keys(selectedCells.selectionsPerLocation);
  const locationsEditBlockDates = locationIdsToDeleteFrom.reduce(
    (agg, id) => ({
      ...agg,
      [id]: locationSettings[id]?.disable_location_schedule_shifts_edit_until,
    }),
    {},
  );

  const shiftIds = selected.reduce((agg, item) => {
    const { shifts: shiftIdsPerDate } = employees.data[item.employeeId] || loanedEmployees[item.employeeId] || {};

    if (!shiftIdsPerDate) return agg;

    const ids = item.dates.reduce((employeeAgg, date) => {
      const filteredShifts = (shiftIdsPerDate[date] || []).filter(shiftId => {
        const shiftObject = shifts.data[item.employeeId]?.shifts?.[shiftId];
        if (!shiftObject) return false;
        const shiftLocationId = shiftObject.location.id;
        const shiftEditBlockDate = locationsEditBlockDates[shiftLocationId];
        const isInBlockedPeriod = shiftEditBlockDate && shiftEditBlockDate >= shiftObject.date;
        return locationIdsToDeleteFrom.includes(shiftLocationId) && !isInBlockedPeriod;
      });
      return [...employeeAgg, ...filteredShifts];
    }, []);
    return [...agg, ...ids];
  }, []);

  return shiftIds;
};

export const getAvailabilitiesIdsForSelectedCells = (schedule, employees, disableAvailabilitiesEditUntil) => {
  const { selectedCells } = schedule;
  const selected = Object.values(selectedCells.selectionsPerLocation).reduce(
    (agg, location) => [
      ...agg,
      ...Object.keys(location.selected).reduce(
        (employeeAgg, employeeId) => [
          ...employeeAgg,
          { employeeId, dates: Object.keys(location.selected[employeeId]) },
        ],
        [],
      ),
    ],
    [],
  );
  const availabilityIds = selected.reduce((agg, item) => {
    const { availability_blocks: availabilitiesPerDate } = employees.data[item.employeeId] || {};
    const ids = item.dates.reduce((prev, date) => {
      const isInBlockedPeriod = disableAvailabilitiesEditUntil && disableAvailabilitiesEditUntil >= date;
      return availabilitiesPerDate[date] && !isInBlockedPeriod ? [...prev, ...availabilitiesPerDate[date]] : prev;
    }, []);
    return [...agg, ...ids];
  }, []);
  return availabilityIds;
};

export const isAnyOpenShiftSelected = (mainDateStore, openShifts, schedule, selectedJobTitlesIds) => {
  const { openShiftsRowSelect } = schedule;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const locationIdsWithSelectedOpenShiftsRow = Object.keys(openShiftsRowSelect).filter(
    locationId => openShiftsRowSelect[locationId],
  );
  const isSelectedAnyOpenShifts = openShifts.some(
    openShift =>
      openShift.date >= from &&
      openShift.date <= to &&
      locationIdsWithSelectedOpenShiftsRow.includes(openShift.location.id) &&
      selectedJobTitlesIds.includes(openShift.job_title.id),
  );
  return isSelectedAnyOpenShifts;
};

export const isAnyTradeShiftSelected = (mainDateStore, tradeShifts, schedule, selectedJobTitlesIds) => {
  const { tradeShiftsRowSelect } = schedule;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const locationIdsWithSelectedOpenShiftsRow = Object.keys(tradeShiftsRowSelect).filter(
    locationId => tradeShiftsRowSelect[locationId],
  );
  if (!tradeShifts?.length) return false;

  const isSelectedAnyTradeShifts = tradeShifts.some(
    tradeShift =>
      tradeShift.date >= from &&
      tradeShift.date <= to &&
      locationIdsWithSelectedOpenShiftsRow.includes(tradeShift.location.id) &&
      selectedJobTitlesIds.includes(tradeShift.job_title.id) &&
      tradeShift.status === 'pending',
  );
  return isSelectedAnyTradeShifts;
};
