import * as actionTypes from '@/constants/ActionTypes.js';

/**
 *
 * @param {string} mode - "month", "week", "custom"
 */
export const mainDateChangeMode = (mode, preventUpdate = false) => ({
  type: actionTypes.MAIN_DATE_CHANGE_MODE,
  payload: mode,
  preventUpdate,
});

export const mainDateMoveLeft = () => ({
  type: actionTypes.MAIN_DATE_MOVE_LEFT,
});

export const mainDateMoveRight = () => ({
  type: actionTypes.MAIN_DATE_MOVE_RIGHT,
});

export const mainDateMoveToDate = date => (dispatch, getState) => {
  const { mainDateStore } = getState().reducer;
  if (mainDateStore.dateMode === 'custom') return;
  dispatch({
    type: actionTypes.MAIN_DATE_MOVE_TO_DATE,
    payload: date,
  });
};

export const mainDateChangeCustom = customDate => ({
  type: actionTypes.MAIN_DATE_CHANGE_CUSTOM_DATE,
  payload: customDate,
});

export const setMainDateStore = data => ({
  type: actionTypes.SET_MAIN_DATE_STORE,
  payload: data,
});

export const mainDateChangeModeAndDate = (start, end, dateMode) => ({
  type: actionTypes.MAIN_DATE_CHANGE_MODE_AND_DATE,
  payload: { start, end, dateMode },
});
