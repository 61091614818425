import { PropTypes } from 'prop-types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import ScheduleTableGraphTooltip from '@/components/common/ScheduleTableGraphTooltip.jsx';

import {
  TOOLTIP_OFFSET,
  TOOLTIP_POSITION,
  TOOLTIP_THREE_DAYS_POSITION,
} from './ScheduleTableHeaderGraphCell.helpers.js';

const ScheduleTableHeaderGraphCell = memo(
  ({
    date,
    points,
    day,
    hour,
    showRecommendedScheduleGraph,
    maxValues,
    numberOfDays,
    budgetMetrics,
    showEmployeeAmount,
    showBudgetMetrics,
  }) => {
    const max = showRecommendedScheduleGraph
      ? Math.max(maxValues?.recommended, maxValues?.employees)
      : maxValues?.employees;

    const metricsMax = maxValues?.budgetMetricsMax;
    const areMaxThreeDays = numberOfDays < 4;

    return (
      <div className="graphWrapper">
        <ResponsiveContainer>
          <ComposedChart
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            data={points && points.length ? [...points, { x: points.length, ...points[points.length - 1] }] : points}
          >
            <XAxis hide />
            <YAxis yAxisId="schedule" hide type="number" domain={[0, max || 10]} />
            {/* <YAxis yAxisId="budget" hide type="number" domain={[0, budgetMax || 10]} />
            <YAxis yAxisId="spmh" hide type="number" domain={[0, spmhMax || 10]} /> */}
            {budgetMetrics.map(
              ({ id, visible }) =>
                visible && <YAxis key={id} yAxisId={id} hide type="number" domain={[0, metricsMax?.[id] || 10]} />,
            )}
            <Tooltip
              content={
                <ScheduleTableGraphTooltip
                  date={date}
                  hour={hour}
                  day={day}
                  showRecommendedScheduleGraph={showRecommendedScheduleGraph}
                />
              }
              cursor={false}
              offset={areMaxThreeDays ? TOOLTIP_OFFSET : undefined}
              position={areMaxThreeDays ? TOOLTIP_THREE_DAYS_POSITION : TOOLTIP_POSITION}
            />
            {showEmployeeAmount && (
              <Area
                key="ye"
                yAxisId="schedule"
                type="stepAfter"
                dataKey="ye"
                stroke="#85669F"
                fill="#85669F"
                fillOpacity={0.7}
                name={<FormattedMessage id="schedule.graph.employees" defaultMessage="Zaplanowany personel" />}
              />
            )}
            {showEmployeeAmount && (
              <Area
                key="ys"
                yAxisId="schedule"
                type="stepAfter"
                dataKey="ys"
                stroke="#1E90FF"
                fill="#1E90FF"
                fillOpacity={0.7}
                name={<FormattedMessage id="schedule.graph.suggestedEmployees" defaultMessage="Zalecany personel" />}
              />
            )}
            {showBudgetMetrics &&
              budgetMetrics.map(
                ({ id, color, visible, name, type }) =>
                  visible && (
                    <Line
                      key={id}
                      yAxisId={id}
                      type="monotone"
                      dataKey={id}
                      stroke={color}
                      unit={type}
                      name={`budget-metric-${name}`}
                      dot={false}
                      strokeWidth={2}
                    />
                  ),
              )}
            {/* TODO: needed for budget estimates */}
            {/* <Line
            yAxisId="budget"
            // yAxisId={item.yAxisId}
            type="monotone"
            dataKey="budgetEstimate"
            // stroke={item.color}
            strokeWidth={1}
            dot={false}
            name={<FormattedMessage id="schedule.graph.estimatedBudget" defaultMessage="Prognozowana sprzedaż" />}
            unit="PLN"
            // name={item.label}
          />
          <Line
            yAxisId="spmh"
            // yAxisId={item.yAxisId}
            type="monotone"
            dataKey="planSpmh"
            // stroke={item.color}
            strokeWidth={1}
            dot={false}
            name={<FormattedMessage id="schedule.graph.planSpmh" defaultMessage="Plan SPMH" />}
            stroke="#000"
            // unit="PLN"
            // name={item.label}
          /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  },
);

ScheduleTableHeaderGraphCell.propTypes = {
  day: PropTypes.bool,
  hour: PropTypes.number,
  date: PropTypes.string,
  showRecommendedScheduleGraph: PropTypes.bool,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      ys: PropTypes.number,
      ye: PropTypes.number,
    }),
  ),
  maxValues: PropTypes.shape({
    employees: PropTypes.number,
    recommended: PropTypes.number,
    budgetMetricsMax: PropTypes.shape({}),
  }),
  numberOfDays: PropTypes.number,
  budgetMetrics: PropTypes.arrayOf(PropTypes.shape({})),
  showEmployeeAmount: PropTypes.bool,
  showBudgetMetrics: PropTypes.bool,
};

export default ScheduleTableHeaderGraphCell;
