import { connect } from 'react-redux';

import { downloadExport, hideExportInWidget } from '@/actions/exports/exportsHistory/exportsHistory';

import ExportsWidgetRow from './ExportsWidgetRow.tsx';

const mapDispatchToProps = {
  downloadExport,
  hideExportInWidget,
};

export default connect(null, mapDispatchToProps)(ExportsWidgetRow);
