import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ButtonsState } from './buttonsState.types';

export const initialScheduleButtonsState: ButtonsState = {
  canDelete: false,
  canDeselect: false,
  canCopy: false,
  canPaste: false,
};

const sliceName = 'buttonsState';

const ButtonsStateSlice = createSlice({
  name: sliceName,
  initialState: initialScheduleButtonsState,
  reducers: {
    setScheduleButtonsState: (state, action: PayloadAction<ButtonsState>) => ({ ...state, ...action.payload }),
  },
});

export const { setScheduleButtonsState } = ButtonsStateSlice.actions;

export const buttonsStateReducer = ButtonsStateSlice.reducer;
