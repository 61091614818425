import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDMultiSelect, MDMultiSelectFooter, MDMultiSelectHeader } from '../inputs/MDComponents.jsx';

const MDEmployeesConditionsBlock = ({
  deselectAllEmploymentConditions,
  selectAllEmploymentConditions,
  selectedEmploymentConditions,
  setSelectedEmploymentConditions,
}) => {
  const employmentConditionsAsFooterOptions = selectedEmploymentConditions.filter(({ active }) => active);

  return (
    <div className="k-autoGenerateEmployeePicker__selectedEmploymentConditions">
      <MDMultiSelectHeader
        title={<FormattedMessage id="openShift.addModal.employeesConditions" defaultMessage="Warunki zatrudnienia" />}
        selectAll={selectAllEmploymentConditions}
        deselectAll={deselectAllEmploymentConditions}
        selectAllMessage={
          <FormattedMessage id="openShift.addModal.selectAllEmployeesConditions" defaultMessage="Wszystkie" />
        }
        deselectAllMessage={
          <FormattedMessage id="openShift.addModal.deselectAllEmployeesConditionsButton" defaultMessage="Żadne" />
        }
      />
      <MDMultiSelect
        label={
          <FormattedMessage
            id="openShift.requestModal.chosenEmployeesConditions"
            defaultMessage="Wybierz warunki zatrudnienia"
          />
        }
        options={selectedEmploymentConditions}
        onChange={setSelectedEmploymentConditions}
      />
      <MDMultiSelectFooter
        selectedItems={employmentConditionsAsFooterOptions}
        deleteItem={setSelectedEmploymentConditions}
        autoHeight
      />
    </div>
  );
};

MDEmployeesConditionsBlock.propTypes = {
  selectAllEmploymentConditions: PropTypes.func,
  deselectAllEmploymentConditions: PropTypes.func,
  setSelectedEmploymentConditions: PropTypes.func,
  selectedEmploymentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

export default MDEmployeesConditionsBlock;
