/* eslint max-len:0 */
import PropTypes from 'prop-types';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ScrollWrapper from '@/components/common/ScrollWrapper.jsx';
import AddPositionShiftModalContainer from '@/components/scheduler/modals/AddPositionShiftModal/AddPositionShiftModal.redux';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import EditEventModalContainer from '@/components/scheduler/modals/EventModal/EditEventModal.redux.js';
import ImportBudgetModal from '@/components/scheduler/modals/ImportBudgetModal/ImportBudgetModal.redux.js';
import TopBarSchedule from '@/components/TopBars/TopBarSchedule/TopBarSchedule.redux.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';
import AddTemplatesShiftModalContainer from '@/containers/scheduler/AddTemplatesShiftModalContainer.jsx';
import ScheduleEventsTableContainer from '@/containers/scheduler/Event/ScheduleEventsTableContainer.js';
import SchedulePositionTableRowContainer from '@/containers/scheduler/Position/SchedulePositionTableRowContainer.jsx';
import SchedulePositionTableRowTitleContainer from '@/containers/scheduler/Position/SchedulePositionTableRowTitleContainer.jsx';
import ScheduleHelpModalContainer from '@/containers/scheduler/ScheduleHelpModalContainer.js';
import ScheduleTableContainer from '@/containers/scheduler/ScheduleTableContainer.jsx';
import ScheduleTemplatesTableRowContainer from '@/containers/scheduler/Templates/ScheduleTemplatesTableRowContainer.jsx';
import ScheduleTemplatesTableRowTitleContainer from '@/containers/scheduler/Templates/ScheduleTemplatesTableRowTitleContainer.jsx';
import {
  checkIfContractHasJobTitle,
  getJobTitleIdsFromContracts,
  getRelevantContractForDate,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts';
import { getScheduleSettingsValue } from '@/utils/schedulerHelpers.js';

import ScheduleBudgetTable from '../budget/ScheduleBudgetTable/ScheduleBudgetTable.redux.js';
import ScheduleButtonBar from '../Buttons/ScheduleButtonBar/ScheduleButtonBar.redux.js';
import TemplatesButtonBar from '../Buttons/TemplatesButtonBar/TemplatesButtonBar.redux.js';
import EmptyFilterView from '../EmptyStates/EmptyFilterView/EmptyFilterView.redux.js';
import EmptyStateJobtitleView from '../EmptyStates/EmptyStateJobTitleView/EmptyStateJobTitleView.redux.js';
import EmptyStateLocationView from '../EmptyStates/EmptyStateLocationView/EmptyStateLocationView.redux.js';
import EmptyStateNoEmployeesView from '../EmptyStates/EmptyStateNoEmployeesView/EmptyStateNoEmployeesView.redux.js';
import AddNewTemplateModal from '../modals/AddTemplateModal/AddNewTemplateModal/AddNewTemplateModal.redux';
import AddTemplateFromExistingModal from '../modals/AddTemplateModal/AddTemplateFromExistingModal/AddTemplateFromExistingModal.redux';
import ChooseAddTemplateVersionModal from '../modals/AddTemplateModal/ChooseAddTemplateVersionModal/ChooseTemplateVersionModal.redux';
import { ManagerNoteModal } from '../modals/ManagerNotesModal/ManagerNotesModal';
import ShowConflictsModal from '../modals/ShowConflictsModal/ShowConflictsModal.redux.js';
import ScheduleTemplateFlexTable from '../Templates/ScheduleTemplateFlex/ScheduleTemplateFlexTable.redux.js';
import ScheduleTemplatesSendPanelContainer from '../Templates/ScheduleTemplatesSendPanel/ScheduleTemplatesSendPanel.redux';

const styles = {
  columnStyle: {
    width: '17vw',
    minWidth: '200px',
    maxWidth: '260px',
  },
  budgetCol: {
    fontSize: '16px',
    textAlign: 'left',
  },
};

const ScheduleView = (props) => {
  const {
    scheduleLocationFilter,
    mainDateStore: { customDate },
  } = props;
  const filterRelevantEmployees = (employees, location = scheduleLocationFilter[0], skipLocation = false) =>
    employees.filter((employee) => {
      const employeeContracts = props.contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(
        employeeContracts,
        customDate.start,
        customDate.end,
      );
      const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);

      return (
        !employee.inactive &&
        (employee.locations.some((l) => l.id === location) || skipLocation) &&
        employeeJobTitleIds.some((jobTitleId) =>
          props.jobtitleFilter.selectedJobtitlesGrouped.map((j) => j.id).includes(jobTitleId),
        )
      );
    });

  const containerWidth = props.mainDateStore.dateArray.length > 7 ? 120 * props.mainDateStore.dateArray.length : 945;

  let windowScale = document.getElementsByTagName('body')[0].style.zoom;
  if (windowScale <= 0 || !windowScale) {
    windowScale = 1;
  }
  const { canPaste } = props.copyPaste;
  const containerHeight = (window.innerHeight - 290) / windowScale;
  const tablesArray = [];
  let relevantEmployees = [];
  let rowDataArray;
  let tableRows;
  let tableLeftColumn;
  let sendPanel = null;
  let scheduleModals = null;
  let templatesView = false;
  let ButtonBar = null;
  const templateType = props.userTemplates.currentTemplate?.type;
  const isTemplateFlexSchedule =
    props.scheduleUIState.selectedDisplayMode.type === 'templates' && templateType === TEMPLATE_TYPES.FLEX;

  const scheduleSettingBudget = getScheduleSettingsValue(props.scheduleUIState.settings, 'budget');
  const scheduleSettingEvents = getScheduleSettingsValue(props.scheduleUIState.settings, 'events');

  const getEmptyStateView = () => {
    if (!props.userLocations.length) {
      return <EmptyStateLocationView />;
    }
    if (!props.userJobTitles.length) {
      return <EmptyStateJobtitleView />;
    }
    if (!props.userEmployees.length) {
      return <EmptyStateNoEmployeesView />;
    }
    return <EmptyFilterView />;
  };

  let showEmptyState = !props.userLocations.length || !props.userEmployees.length || !props.userJobTitles.length;

  switch (props.scheduleUIState.selectedDisplayMode.type) {
    case 'positions':
      scheduleModals = (
        <div>
          <EditEventModalContainer />
          <ImportBudgetModal />
          <AddShiftModalContainer />
          <AddPositionShiftModalContainer />
          <ScheduleHelpModalContainer />
          <ShowConflictsModal />
          <ObjectHistoryModalContainer />
          <ManagerNoteModal />
        </div>
      );
      scheduleLocationFilter.forEach((location) => {
        const locationObject = props.userLocations.find((l) => l.id === location) || {};
        relevantEmployees = filterRelevantEmployees(props.userEmployees, location);
        rowDataArray = props.jobtitleFilter.selectedJobtitlesGrouped.map((job) => ({
          ...job,
          employees: [
            ...relevantEmployees.map((employee) => {
              const employeeContracts = props.contracts[employee.id] || [];
              return {
                ...employee,
                shifts: employee.shifts
                  .map((shift) => {
                    const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
                    return {
                      ...shift,
                      invalidJobTitle: !checkIfContractHasJobTitle(relevantContract, shift.job_title.id),
                    };
                  })
                  .filter((shift) => job.id === shift.job_title.id),
              };
            }),
          ],
        }));
        showEmptyState = showEmptyState || !relevantEmployees.length;
        tableRows = showEmptyState
          ? [getEmptyStateView()]
          : rowDataArray.map((rowData) => (
              <SchedulePositionTableRowContainer
                key={rowData.id}
                rowData={rowData}
                canPaste={canPaste}
                location={locationObject}
              />
            ));
        tableLeftColumn = showEmptyState
          ? [
              <tr className="empty">
                <th className="no-content-to-show" />
              </tr>,
            ]
          : rowDataArray.map((rowData, i) => (
              <SchedulePositionTableRowTitleContainer rowData={rowData} key={i} location={locationObject} />
            ));
        tablesArray.push({
          tableRows,
          tableLeftColumn,
          location: locationObject,
        });
      });

      ButtonBar = <ScheduleButtonBar rowData={rowDataArray} />;

      break;
    case 'templates': {
      templatesView = true;
      scheduleModals = (
        <div>
          <AddTemplatesShiftModalContainer />
          <ScheduleHelpModalContainer />
          <ChooseAddTemplateVersionModal />
          <AddTemplateFromExistingModal />
          <AddNewTemplateModal />
        </div>
      );
      sendPanel = <ScheduleTemplatesSendPanelContainer />;

      rowDataArray = props.jobtitleFilter.selectedJobtitlesGrouped.map((job) => ({
        ...job,
        shifts: props.userTemplates.currentTemplate.shifts
          ?.filter((shift) => {
            const shiftJobTitleId = shift.job_title_id || shift.job_title?.id;
            return shiftJobTitleId === job.id;
          })
          .map((shift) => {
            const shiftJobTitleId = shift.job_title_id || shift.job_title?.id;
            const jobTitle = props.userJobTitles.find((j) => j.id === shiftJobTitleId);
            return { ...shift, job_title: jobTitle };
          }),
      }));
      relevantEmployees = rowDataArray;

      tableRows = showEmptyState
        ? [getEmptyStateView()]
        : rowDataArray.map((rowData) => <ScheduleTemplatesTableRowContainer key={rowData.id} rowData={rowData} />);
      tableLeftColumn = showEmptyState
        ? [
            <tr className="empty">
              <th className="no-content-to-show" />
            </tr>,
          ]
        : rowDataArray.map((rowData, i) => <ScheduleTemplatesTableRowTitleContainer rowData={rowData} key={i} />);

      tablesArray.push({ tableRows, tableLeftColumn, location: {} });

      ButtonBar = <TemplatesButtonBar />;
      break;
    }
    default:
      rowDataArray = [];
      tableRows = [];
      tableLeftColumn = [];
  }
  const relevantShifts = relevantEmployees.map((employee) => employee.shifts);
  return (
    <div>
      {sendPanel}
      <TopBarSchedule />
      <div className="k-wrapper k-wrapper--tablePadding animated fadeInRight">
        {scheduleModals}
        {ButtonBar}
        <ScrollWrapper>
          {isTemplateFlexSchedule ? (
            <ScheduleTemplateFlexTable />
          ) : (
            tablesArray.map((table, i) => (
              <ScheduleTableContainer
                key={i}
                width={containerWidth}
                height={containerHeight}
                styles={styles}
                tableRows={table.tableRows}
                tableLeftColumn={table.tableLeftColumn}
                location={table.location}
                relevantShifts={relevantShifts}
                showEmptyState={showEmptyState}
              />
            ))
          )}

          <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
            {scheduleSettingBudget ? (
              <ScheduleBudgetTable
                width={containerWidth}
                height={containerHeight}
                styles={styles}
                relevantLocations={tablesArray.map((t) => t.location.id)}
                relevantEmployees={
                  scheduleLocationFilter.length > 1 && !templatesView
                    ? filterRelevantEmployees(props.userEmployees, null, true)
                    : relevantEmployees
                }
                tableClass={props.mainDateStore.dateArray.length > 7 ? 'budget tabcont monthly' : 'budget tabcont'}
              />
            ) : null}
          </FeatureWrapper>
          {scheduleSettingEvents ? (
            <ScheduleEventsTableContainer
              width={containerWidth}
              styles={styles}
              tableClass={props.mainDateStore.dateArray.length > 7 ? 'budget tabcont monthly' : 'budget tabcont'}
              hide={props.scheduleUIState.selectedDisplayMode.type === 'templates'}
            />
          ) : null}
        </ScrollWrapper>
      </div>
    </div>
  );
};

ScheduleView.propTypes = {
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  copyPaste: PropTypes.shape({
    canPaste: PropTypes.bool,
  }),

  scheduleUIState: PropTypes.shape({
    searchString: PropTypes.string,
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      type: PropTypes.string,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitlesGrouped: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({}),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  listUi: PropTypes.shape({
    locations: PropTypes.shape({
      showModal: PropTypes.bool,
    }),
    jobTitles: PropTypes.shape({
      showModal: PropTypes.bool,
    }),
  }),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ScheduleView;
