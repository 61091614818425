import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PublishButton from '@/components/common/PublishButton.jsx';
import TopBar from '@/components/TopBars/TopBar.jsx';
import { newReportsModesIds } from '@/constants/viewDateModes';

import TopBarCalendar from './Calendar.redux.js';
import TopBarEmploymentConditionsFilter from './EmploymentConditionsFilter.redux.js';
import TopBarJobTitleFilterContainer from './JobTitleFilter.redux.js';
import TopBarMultipleLocationFilter from './LocationFilter.redux.js';

const ReportsTopBar = ({ getWorkTimeReport, getSalesReport, isButtonDisabled }) => (
  <TopBar
    leftElements={[
      <TopBarCalendar key="calswitch" modes={newReportsModesIds} />,
      <TopBarMultipleLocationFilter key="locationFilter" />,
      <TopBarJobTitleFilterContainer key="jobTitleFilter" />,
      <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
    ]}
    rightElements={[
      <PublishButton
        text={<FormattedMessage id="loadData" defaultMessage="Wczytaj" />}
        onClickFunc={() => {
          getWorkTimeReport();
          getSalesReport();
        }}
        key="getData"
        disabled={isButtonDisabled}
        icon="update"
      />,
    ]}
  />
);

ReportsTopBar.propTypes = {
  getWorkTimeReport: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  getSalesReport: PropTypes.func,
};

export default ReportsTopBar;
