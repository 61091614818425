import { connect } from 'react-redux';

import { checkPermissions, togglePermission, uncheckPermissions } from '@/actions/companymanage/roles.js';

import RolesTableRow from './RolesTableRow.jsx';

const mapStateToProps = state => ({
  toggledGroups: state.reducer.roles.toggledGroups,
  checkedPermissions: state.reducer.roles.checkedPermissions,
});

const mapDispatchToProps = {
  togglePermission,
  checkPermissions,
  uncheckPermissions,
};

const RolesTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(RolesTableRow);

export default RolesTableRowContainer;
