import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import { MDTimeRangeInput } from '@/components/common/inputs/MDComponents.jsx';
import ChoicePicker from '@/components/common/RangePicker/ChoicePicker/ChoicePicker';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';
import { createEvent } from '@/utils/inputHelpers.js';

import { messages } from './PartDayAbsenceOptions.messages';

const timeRangeInputModifiers = ['modal'];

export const PartDayAbsenceOptions = (props, { intl }) => {
  const { absenceHours, selectedDay, absenceHoursError, handleInputChange } = props;

  const changeAbsenceHours = useCallback(hours => handleInputChange(createEvent('absenceHours', hours)), []);

  const changeSelectedDay = useCallback(
    date => handleInputChange(createEvent('selectedDay', convertDateToStandardFormat(date))),
    [],
  );

  return (
    <>
      <MDTimeRangeInput
        value={absenceHours}
        onChange={changeAbsenceHours}
        label={intl.formatMessage(messages.absencesTimeInput, {})}
        modifiers={timeRangeInputModifiers}
        errorMessage={absenceHoursError}
      />
      <ChoicePicker
        label={intl.formatMessage(messages.absencesSelectedDay, {})}
        selectedChoices={[selectedDay]}
        calText={selectedDay}
        changePick={changeSelectedDay}
        position="bottom"
      />
    </>
  );
};

PartDayAbsenceOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PartDayAbsenceOptions.propTypes = {
  absenceHours: PropTypes.string,
  handleInputChange: PropTypes.func,
  absenceHoursError: PropTypes.string,
  selectedDay: PropTypes.string,
};

export default memo(PartDayAbsenceOptions);
