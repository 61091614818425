import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents.jsx';

const OpenShiftModalDetails = props => {
  const { openShift } = props;
  const jobTitle = openShift.job_title ? openShift.job_title.title : '';
  return (
    <div className="k-open-shifts__details-container">
      <MDInfoWithLabel
        label={<FormattedMessage id="openShift.applyModal.jobTitle" defaultMessage="Stanowisko" />}
        info={jobTitle}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="openShift.applyModal.date" defaultMessage="Data" />}
        info={
          <>
            <span className="mdInfoWithLabel--extraMarginRight">{openShift.date}</span>
            <span>{openShift.working_hours}</span>
          </>
        }
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="openShift.applyModal.location" defaultMessage="Lokalizacja" />}
        info={openShift.locationName}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="openShift.applyModal.shiftsRemaining" defaultMessage="Ilość potrzebnych zmian" />}
        info={openShift.shifts_remaining}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="openShift.applyModal.comment" defaultMessage="Notatka" />}
        info={openShift.comment}
        infoModifiers={['note']}
      />
    </div>
  );
};

OpenShiftModalDetails.propTypes = {
  openShift: PropTypes.shape({
    job_title: PropTypes.shape({
      title: PropTypes.string,
    }),
    date: PropTypes.string,
    working_hours: PropTypes.string,
    shifts_remaining: PropTypes.number,
    eager_users_count: PropTypes.number,
    comment: PropTypes.string,
  }),
};

export default OpenShiftModalDetails;
