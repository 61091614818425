import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import Component from './EventsTable.jsx';

const mapStateToProps = state => ({
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  eventsStructure: state.reducer.schedule.events.structure,
  dateArray: state.reducer.mainDateStore.dateArray,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
