import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import { sortByMultipleKeys } from '@/components/newPayrollViews/columns.helpers';
import { tabsMessages } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.messages';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { useTableWithScroll } from '@/hooks';
import { useAppSelector } from '@/redux-store';
import { selectOpenShiftsAssignments } from '@/redux-store/openShiftsAssignments/openShiftsAssignments.selectors';
import { showLabelsFeature } from '@/utils/labels';

import PayrollButtonBar from './PayrollButtonBar/PayrollButtonBar.redux';
import { getColumns, getPayrollColumns, getRowsWithMergedColumns, getTableData, TABS } from './PayrollTable.helpers';
import PayrollTableEmpty from './PayrollTableEmpty/PayrollTableEmpty';
import PayrollTableOptions from './PayrollTableOptions/PayrollTableOptions.redux';

import './PayrollTable.scss';

const PayrollTable = (props, { intl }) => {
  const {
    data,
    visibleColumns,
    timeFormatType,
    addBonusToAttendance,
    payoutSettingType,
    setBonusValues,
    selectedEmployee,
    bonusValues,
    payrollMode,
    changePayrollDaysStatus,
    userPermissions,
    labels,
    roles,
    currentUser,
    companySettings,
    getPayrollViewData,
    payrollApprovalEnabled,
    payrollLoanedEmployees,
  } = props;

  const getRowId = useCallback(row => row.rowId, []);

  const { companyRoles } = roles;
  const { role } = currentUser.user;
  const { permissions } = userPermissions;

  const tabs = useMemo(() => {
    if (!payrollApprovalEnabled) {
      return [];
    }
    return TABS.map(tab => {
      const numberOfItems =
        tab.id === 'all'
          ? data.length
          : data.filter(row => (row.isUnapproved ? tab.id === 'unapproved' : tab.id === 'approved')).length;

      return {
        ...tab,
        label: intl.formatMessage(tabsMessages[tab.id]),
        onSelect: () => {
          const allSelected = tab.id === 'all';
          setActiveTab(tab.id);
          getPayrollViewData(undefined, undefined, { mergeWithOldData: true }, 'standard', allSelected ? null : tab.id);
        },
        numberOfItems,
      };
    });
  }, [intl, data, payrollApprovalEnabled]);
  const openShiftsAssignments = useAppSelector(selectOpenShiftsAssignments(selectedEmployee?.id));

  const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const [sortBy, setSortBy] = useState('date');
  const [sortDesc, setSortDesc] = useState(false);
  const allColumns = useMemo(
    () => getPayrollColumns(userPermissions, payoutSettingType, companySettings),
    [userPermissions, payoutSettingType, companySettings],
  );
  const { isTableWithScroll } = useTableWithScroll(visibleColumns, allColumns, { checkboxColumnWidth: 0 });
  const columns = useMemo(
    () => getColumns(allColumns, visibleColumns, isTableWithScroll, intl),
    [allColumns, visibleColumns, isTableWithScroll, intl],
  );
  const showLabels = showLabelsFeature(role, permissions);

  const tableData = useMemo(() => {
    const filteredData =
      activeTab === 'all' || tabs.length === 0
        ? data
        : data.filter(item => (item.isUnapproved ? activeTab === 'unapproved' : activeTab === 'approved'));
    return getTableData(
      filteredData,
      selectedEmployee,
      timeFormatType,
      bonusValues,
      payoutSettingType,
      addBonusToAttendance,
      setBonusValues,
      payrollMode,
      changePayrollDaysStatus,
      intl,
      labels,
      companyRoles,
      currentUser,
      userPermissions,
      showLabels,
      payrollLoanedEmployees,
      openShiftsAssignments,
    );
  }, [
    activeTab,
    tabs.length,
    data,
    selectedEmployee,
    timeFormatType,
    bonusValues,
    payoutSettingType,
    addBonusToAttendance,
    setBonusValues,
    payrollMode,
    changePayrollDaysStatus,
    intl,
    labels,
    companyRoles,
    currentUser,
    userPermissions,
    showLabels,
    payrollLoanedEmployees,
  ]);
  const rowsWithMergedColumns = useMemo(() => getRowsWithMergedColumns(data, columns), [data, columns]);
  const maxHeight = window.innerHeight - 180;
  const onCheckboxToggle = (row, rows, toggleRowSelected) => {
    const clickedDate = row.original.date;
    const alreadyChecked = row.isSelected;
    const rowsWithTheSameDate = rows.filter(r => r.original.date === clickedDate);

    rowsWithTheSameDate.forEach(r => {
      toggleRowSelected(r.id, !alreadyChecked);
    });
  };
  const disableSelectAllCheckbox = useMemo(
    () => tableData.every(row => row.date < PAYROLL_APPROVAL_START_DATE),
    [tableData],
  );

  const onSort = useCallback(
    sortColumns => {
      if (sortColumns.length === 0) {
        return;
      }
      const [{ id, desc }] = sortColumns;
      const column = columns.find(c => c.id === id);
      if (column) {
        setSortBy(column.accessor);
        setSortDesc(desc);
      }
    },
    [columns],
  );

  const sortedTableData = useMemo(
    () => sortByMultipleKeys(tableData, [sortBy, 'date', 'from', 'to'], { desc: sortDesc, timeFormatType }, columns),
    [tableData, sortBy, sortDesc, timeFormatType, columns],
  );

  return (
    <MDTable
      data={sortedTableData}
      columns={columns}
      withSearch={false}
      withCheckbox={companySettings.payroll_approval_enabled}
      tableEmpty={<PayrollTableEmpty />}
      rowsWithMergedColumns={rowsWithMergedColumns}
      options={<PayrollTableOptions />}
      tableOptions={<PayrollButtonBar employeeId={selectedEmployee?.id} />}
      withHorizontalScroll={tableData.length > 0 && isTableWithScroll}
      maxHeight={maxHeight}
      customOnSelectHandler={onCheckboxToggle}
      disableSelectAllCheckbox={disableSelectAllCheckbox}
      withShadowOnStickyColumns
      tabs={tabs}
      activeTab={activeTab}
      onSort={onSort}
      getRowId={getRowId}
    />
  );
};

PayrollTable.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  timeFormatType: PropTypes.string,
  addBonusToAttendance: PropTypes.func,
  payoutSettingType: PropTypes.string,
  setBonusValues: PropTypes.func,
  selectedEmployee: PropTypes.string,
  bonusValues: PropTypes.shape({ id: PropTypes.shape({}) }),
  payrollMode: PropTypes.string,
  changePayrollDaysStatus: PropTypes.func,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  companySettings: PropTypes.shape({
    payroll_approval_enabled: PropTypes.bool,
  }),
  getPayrollViewData: PropTypes.func,
  payrollApprovalEnabled: PropTypes.bool,
  payrollLoanedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      deleted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      reference_id: PropTypes.string,
      isLoaned: PropTypes.bool,
      attendances: PropTypes.arrayOf(),
    }),
  ),
};

export default PayrollTable;
