import { defineMessages } from 'react-intl';

const messages = defineMessages({
  deleteMultipleShiftSuccessTitle: {
    id: 'deleteMultipleShifts.success.title',
    defaultMessage: 'Zmiany zostały usunięte',
  },
  deleteMultipleShiftErrorTitle: {
    id: 'deleteMultipleShifts.error.title',
    defaultMessage: 'Nie udało się usunąć zmian',
  },
  deleteShiftTitle: {
    id: 'shift.confirmModal.deleteShiftTitle',
    defaultMessage: 'Usuń zmianę',
  },
  deleteShiftDescription: {
    id: 'shift.confirmModal.deleteShiftDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć tę zmianę?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

export default messages;
