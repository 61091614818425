import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import MDPasswordInput from '@/components/common/inputs/MDPasswordInput/MDPasswordInput.jsx';
import UnauthenticatedLayout from '@/components/misc/UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from '@/components/misc/UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';
import browserHistory from '@/constants/browserHistory';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { inputValidation, validationMessages } from '@/utils/inputValidation.js';

import { messages } from './expiredPassword.messages.js';

class PasswordView extends Component {
  constructor(props, context) {
    super(props, context);

    this.initialState = {
      passwordCurrent: '',
      password: '',
      passwordConfirm: '',
      errors: {
        passwordCurrent: '',
        password: '',
        passwordConfirm: '',
      },
      success: false,
    };
    this.state = this.initialState;
    bindPrototypeFunctions(this);
  }

  componentDidMount() {
    if (!this.props.currentUser.authenticated) browserHistory.push('/');
  }

  submit() {
    this.validateAll().then(resolve => {
      if (!resolve) return;
      const { passwordCurrent, password } = this.state;
      this.props
        .changePassword(passwordCurrent, password)
        .then(() => {
          this.props.requestAuthenticateUser(this.props.currentUser.user.email, password, true, false);
          browserHistory.push('/');
        })
        .catch(error => {
          const { data } = error.response;
          const errors = Object.assign({}, this.state.errors);
          if (data.errors && data.errors[0]?.field === 'old_password') {
            errors.passwordCurrent = (
              <FormattedMessage id="validation.passwordCurrentInvalid" defaultMessage="Podano błędne hasło" />
            );
          }

          if (data.passwordUsedBefore) {
            errors.password = <FormattedMessage {...validationMessages.passwordNotPreviouslyUsed} />;
          }
          // TODO:Change with backend - message converted into additional field
          if (data.message === 'New password is the same as old') {
            errors.password = <FormattedMessage {...validationMessages.passwordNotPreviouslyUsed} />;
          }
          if (data.message === 'Old password mismatch') {
            errors.passwordCurrent = <FormattedMessage {...validationMessages.passwordOldMismatch} />;
          }

          this.setState({ errors });
        });
    });
  }

  async validateAll() {
    const inputs = ['passwordCurrent', 'password', 'passwordConfirm'];
    this.setState({
      errors: {
        passwordCurrent: '',
        password: '',
        passwordConfirm: '',
      },
    });

    const validationPromises = inputs.map(i => this.validateInput({ target: { name: i, value: this.state[i] } }));
    await Promise.all(validationPromises);
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  validateInput(e) {
    return new Promise(resolve => {
      const { name, value } = e.target;
      let valueToCompare = '';
      if (name === 'passwordConfirm') valueToCompare = this.state.password;
      if (name === 'password') valueToCompare = this.state.passwordCurrent;

      const error = inputValidation(name, value, { valueToCompare });
      this.setState(
        prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : '',
          },
        }),
        () => {
          resolve();
        },
      );
    });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
        <div onSubmit={this.formSubmit}>
          <div className="k-password__info">
            <FormattedMessage
              id="settings.password.expiredInfo"
              defaultMessage="Twoje hasło wygasło. Wypełnij formularz poniżej, aby ustawić nowe."
            />
          </div>
          <MDPasswordInput
            id="passwordCurrent"
            value={this.state.passwordCurrent}
            label={this.context.intl.formatMessage(messages.currentPassword, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
            errorMessage={this.state.errors.passwordCurrent}
          />
          <MDPasswordInput
            id="password"
            value={this.state.password}
            label={this.context.intl.formatMessage(messages.password, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
            errorMessage={this.state.errors.password}
          />
          <MDPasswordInput
            id="passwordConfirm"
            value={this.state.passwordConfirm}
            label={this.context.intl.formatMessage(messages.passwordConfirm, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
            errorMessage={this.state.errors.passwordConfirm}
          />
          <div className="k-password__button">
            <Button onClick={this.submit} modifiers="orange high uppercase">
              <FormattedMessage id="resetPassword.setPassword" defaultMessage="Ustaw hasło" />
            </Button>
          </div>
        </div>
      </UnauthenticatedLayout>
    );
  }
}

PasswordView.contextTypes = {
  intl: PropTypes.shape({}),
};

PasswordView.propTypes = {
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string,
    }),
    authToken: PropTypes.shape({}),
  }),
  requestAuthenticateUser: PropTypes.func,
  changePassword: PropTypes.func,
};

export default PasswordView;
