import { connect } from 'react-redux';

import { enableLoadButton } from '@/actions/payroll';
import { getPayrollViewData } from '@/actions/payroll/payroll';

import PayrollView from './PayrollView';

const mapStateToProps = state => ({
  selectedEmployeeId: state.reducer.singleEmployeeFilter?.id,
  isLoadButtonEnabled: state.reducer.payroll.isLoadButtonEnabled,
});

const mapDispatchToProps = {
  getPayrollViewData,
  enableLoadButton,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollView);
