import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FREEMIUM_DISABLE_USER_VIEW_NOTIFICATIONS } from '@/constants/Restrictions.js';
import { settingsHasChanged } from '@/utils/objectHelpers.js';

import MDCheckboxTable from '../../MDCheckboxTable/MDCheckboxTable.jsx';
import SettingsSection from '../../SettingsSection/SettingsSection.jsx';
import { getInitState, getNotificationSettingsTable } from './notificationSettingsHelpers.js';

const NotificationSettings = ({ userPermissions, userSettings, locationsSettings, setNotificationSettings }) => {
  const [initState, setInitState] = useState(getInitState(userSettings));
  const [state, setState] = useState(getInitState(userSettings));
  const canSave = settingsHasChanged(initState, state);

  const handleInputChange = (notificationType, field, checked) => {
    setState(prev => ({ ...prev, [notificationType]: { ...prev[notificationType], [field]: checked } }));
  };

  const handleSubmit = () => {
    const data = Object.values(state);
    setNotificationSettings(data);
    setInitState(state);
  };

  const { colSizes, headers, rows } = getNotificationSettingsTable(
    state,
    userPermissions,
    locationsSettings,
    userPermissions.roles,
  );

  return (
    <SettingsSection
      canSave={canSave}
      title={<FormattedMessage id="settings.options.notifications" defaultMessage="Notyfikacje" />}
      onSubmit={handleSubmit}
      panelModifiers={['no-scroll']}
      showFreemiumStar={userPermissions.restrictions.includes(FREEMIUM_DISABLE_USER_VIEW_NOTIFICATIONS)}
    >
      <MDCheckboxTable colSizes={colSizes} headers={headers} rows={rows} onOptionChange={handleInputChange} />
    </SettingsSection>
  );
};

NotificationSettings.propTypes = {
  userSettings: PropTypes.shape({
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.bool,
        mobile: PropTypes.bool,
        sms: PropTypes.bool,
        notification_name: PropTypes.string,
      }),
    ),
  }),
  setNotificationSettings: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  locationsSettings: PropTypes.shape({
    [PropTypes.number]: PropTypes.shape({}),
  }),
};

export default NotificationSettings;
