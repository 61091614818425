import { connect } from 'react-redux';

import { deleteTemplatesShift, replaceTemplatesShifts } from '@/actions/templates.js';
import DropArea from '@/components/scheduler/Templates/TemplatesDropArea.jsx';

const mapStateToProps = state => ({
  userTemplates: state.reducer.userTemplates,
  userJobTitles: state.reducer.userJobTitles,
});
const mapDispatchToProps = {
  deleteTemplatesShift,
  replaceTemplatesShifts,
};
const DropAreaContainer = connect(mapStateToProps, mapDispatchToProps)(DropArea);

export default DropAreaContainer;
