import PropTypes from 'prop-types';

import './NumberInCircle.scss';

const NumberInCircle = ({ number }) => <span className="numberInCircle">{number}</span>;

NumberInCircle.propTypes = {
  number: PropTypes.number,
};

export default NumberInCircle;
