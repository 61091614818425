import { connect } from 'react-redux';

import { editBudgetTargets } from '@/actions/budgetEstimates.js';
import { hideModal, replaceModal,showModal } from '@/actions/uiState';
import { LOAD_BUDGET_TARGET_MODAL } from '@/constants/modalTypes';

import LoadBudgetTargetModal from './LoadBudgetTargetModal.jsx';

const mapStateToProps = ({ reducer }) => ({
  isModalVisible: reducer.uiState.showModal === LOAD_BUDGET_TARGET_MODAL,
  budgetSummary: reducer.schedule.budget.budgetEstimates.summary,
  scheduleLocations: reducer.scheduleLocationFilter,
  firstDayInSchedule: reducer.mainDateStore.customDate.start,
  isMonthMode: reducer.mainDateStore.dateMode === 'month',
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  replaceModal,
  editBudgetTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadBudgetTargetModal);
