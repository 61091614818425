import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';

import './DashboardError.scss';

const DashboardError = ({ getDashboard }) => (
  <div className="k-dashboardError">
    <div className="k-dashboardError__description">
      <FormattedMessage id="dashboard.errorFetching.description" defaultMessage="Nie udało się pobrać informacji" />
    </div>
    <Button modifiers={['orange', 'teeny']} className="k-dashboardError__button" onClick={getDashboard}>
      <FormattedMessage id="dashboard.errorFetching.tryAgain" defaultMessage="Spróbuj ponownie" />
    </Button>
  </div>
);

DashboardError.propTypes = {
  getDashboard: PropTypes.func,
};

export default DashboardError;
