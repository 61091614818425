import cn from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import OpenShiftDropArea from '../../OpenShiftDropArea/OpenShiftDropArea.redux';
import OpenShiftShift from '../OpenShiftShift/OpenShiftShift.redux';
import { useVisibleOpenShiftsIdsForLocationGroups } from '../useVisibleOpenShiftsIdsForLocationGroups';
import { getRelevantOpenShiftIds } from './ScheduleTableOpenShiftCell.helpers';

const ScheduleTableOpenShiftCell = ({
  openShiftIds = [],
  size,
  date,
  locationId,
  openOpenShiftModal,
  offsetTop,
  selectedJobTitles,
  openShiftData,
  isEditDisabled,
  isEmployee,
  currentUserContracts,
}) => {
  const openShiftsIdsForLocationGroups = useVisibleOpenShiftsIdsForLocationGroups(openShiftData, locationId, date);
  const relevantOpenShiftIds = openShiftsIdsForLocationGroups.length ? openShiftsIdsForLocationGroups : openShiftIds;
  const visibleOpenShiftIds = useMemo(
    () =>
      getRelevantOpenShiftIds(
        selectedJobTitles,
        relevantOpenShiftIds,
        openShiftData,
        currentUserContracts,
        isEmployee,
        date,
      ),
    [selectedJobTitles, openShiftData, relevantOpenShiftIds, currentUserContracts, date, isEmployee],
  );
  const isEmpty = !visibleOpenShiftIds.length;
  const className = cn('k-openShiftRow__cell', {
    empty: isEmpty && !isEmployee,
    'k-openShiftRow__cell--readonly': isEditDisabled,
  });
  const onAdd = e => {
    if (!isEmpty) return;
    e.stopPropagation();
    openOpenShiftModal({}, date, locationId);
  };

  return (
    <td className={className} onClick={onAdd} role="presentation" style={{ top: `${offsetTop}px` }}>
      <span className="material-icons-outlined k-openShiftRow__addIcon">add</span>
      <OpenShiftDropArea date={date} locationId={locationId} />
      {visibleOpenShiftIds.map(id => (
        <OpenShiftShift shiftId={id} size={size} key={id} date={date} locationId={locationId} />
      ))}
    </td>
  );
};
ScheduleTableOpenShiftCell.propTypes = {
  openShiftIds: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  date: PropTypes.string,
  locationId: PropTypes.string,
  openOpenShiftModal: PropTypes.func,
  offsetTop: PropTypes.number,
  isEmployee: PropTypes.bool,
  openShiftData: PropTypes.arrayOf(PropTypes.shape({ job_title: PropTypes.shape({ id: PropTypes.string }) })),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  isEditDisabled: PropTypes.bool,
  currentUserContracts: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ScheduleTableOpenShiftCell;
