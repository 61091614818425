import moment from 'moment';

import { changeFlexTemplate, clearFlexScheduleDay } from '@/actions/templates.js';
import { COPY_CONTENT, SET_CAN_COPY,SET_CAN_PASTE } from '@/constants/ActionTypes.js';
import { scheduleMessages } from '@/constants/intl.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { compareDateArraysDayOfSpanMatches } from '@/utils/dateHelper.js';
import { getDisableEditUntil } from '@/utils/schedulerHelpers.js';

import { showConfirmModal } from './index';

export const setCanPaste = canPaste => ({
  type: SET_CAN_PASTE,
  payload: canPaste,
});

export const setCanCopy = canCopy => ({
  type: SET_CAN_COPY,
  payload: canCopy,
});

export const copyContent = (context, selection) => ({
  type: COPY_CONTENT,
  context,
  payload: selection,
});

export const checkIfCanPasteForSchedule = (mainDateStore, copyPaste, scheduleUIState, disableEditUntil) => {
  // If start of the date range is before disabled date
  if (disableEditUntil && moment(mainDateStore.dateArray[0]).isBefore(moment(disableEditUntil))) return false;
  // If not copied from schedule
  if (copyPaste.context !== scheduleUIState.selectedDisplayMode.type) return false;
  // or doesn't have selection property
  if (!copyPaste.selection) return false;
  // or we are in different mode then the one we copied from
  if (mainDateStore.dateMode !== copyPaste.selection.dateStore.dateMode) return false;

  //----------------------------------------------------------------------------
  // Copying between employees - copy one -> paste to one or more
  if (copyPaste.selection.rows.length === 1) {
    const copiedRowEmployeeId = copyPaste.selection.rows[0];
    if (!scheduleUIState.selectedRows.includes(copiedRowEmployeeId) && scheduleUIState.selectedRows.length > 0) {
      return true;
    }
  }
  // ---------------------------------------------------------------------------

  // or we are in the same week/month we copied from
  if (mainDateStore.dateArray[0] === copyPaste.selection.dateStore.dateArray[0]) return false;

  // Checking if number of selected rows matches
  if (copyPaste.selection.rows.length !== scheduleUIState.selectedRows.length) return false;
  // Checking if number of selected columns matches
  if (copyPaste.selection.columns.length !== scheduleUIState.selectedColumns.length) return false;

  // Checking if the same rows are selected as when copied
  if (copyPaste.selection.rows.some(element => !scheduleUIState.selectedRows.includes(element))) return false;

  // Checking if the same columns are selected
  // but since rows are changed when we change week
  // we compare if thouse are the same days of week
  if (
    !compareDateArraysDayOfSpanMatches(
      copyPaste.selection.columns,
      scheduleUIState.selectedColumns,
      mainDateStore.dateMode,
    )
  ) {
    return false;
  }

  return true;
};

export const checkIfCanPaste = () => (dispatch, getState) => {
  const {
    mainDateStore,
    copyPaste,
    scheduleUIState,
    locationFilter,
    settings,
    currentCompany,
    userTemplates,
  } = getState().reducer;

  if (scheduleUIState.selectedDisplayMode.type === 'templates') {
    const isFlex = userTemplates.currentTemplate.type === TEMPLATE_TYPES.FLEX;
    const canPaste = isFlex && copyPaste.selection?.data.length > 0;
    dispatch(setCanPaste(canPaste));
  } else {
    const selectedLocationSettings = settings.locationSettings[locationFilter.selectedLocation.id];
    const disableEditUntil = getDisableEditUntil(scheduleUIState, selectedLocationSettings, currentCompany);

    // When we have more context to copy form we can add switch to change this function
    const canPaste = checkIfCanPasteForSchedule(mainDateStore, copyPaste, scheduleUIState, disableEditUntil);
    dispatch(setCanPaste(canPaste));
  }
};

export const checkIfCanCopy = () => (dispatch, getState) => {
  const { scheduleUIState, scheduleState, userTemplates } = getState().reducer;

  if (scheduleUIState.selectedDisplayMode.type === 'templates') {
    const isFlex = userTemplates.currentTemplate.type === TEMPLATE_TYPES.FLEX;
    const canCopy = isFlex && userTemplates.currentTemplate.shifts.some(s => s.date === scheduleState.selectedDay);
    dispatch(setCanCopy(canCopy));
  }
};

export const copyShifts = employeesIds => (dispatch, getState) => {
  const { mainDateStore, scheduleUIState, jobtitleFilter, locationFilter } = getState().reducer;
  const rowsToCopy = scheduleUIState.selectedRows;
  const rowsLocationIds = scheduleUIState.selectedRowsLocationsIds;
  const columnsToCopy = scheduleUIState.selectedColumns;
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);
  const locationId = locationFilter.selectedLocation.id;

  dispatch(
    copyContent('schedule', {
      columns: columnsToCopy,
      rows: rowsToCopy,
      locationId,
      relevantEmployees: employeesIds,
      relevantJobTitles: jobTitlesIds,
      dateStore: mainDateStore,
      rowsLocationIds,
    }),
  );
};

export const copyAvailabilities = employeesIds => (dispatch, getState) => {
  const { mainDateStore, scheduleUIState, jobtitleFilter, locationFilter } = getState().reducer;
  const rowsToCopy = scheduleUIState.selectedRows;
  const columnsToCopy = scheduleUIState.selectedColumns;
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);
  const locationId = locationFilter.selectedLocation.id;

  dispatch(
    copyContent('availabilities', {
      columns: columnsToCopy,
      rows: rowsToCopy,
      locationId,
      relevantEmployees: employeesIds,
      relevantJobTitles: jobTitlesIds,
      dateStore: mainDateStore,
    }),
  );
};

export const copyFlexSchedule = () => (dispatch, getState) => {
  const { userTemplates, scheduleState } = getState().reducer;
  const data = userTemplates.currentTemplate.shifts.filter(s => s.date === scheduleState.selectedDay % 7);

  dispatch(
    copyContent('templates', {
      data,
    }),
  );
};

export const pasteFlexSchedule = () => (dispatch, getState, intl) => {
  const { copyPaste, scheduleState } = getState().reducer;

  if (copyPaste.canPaste) {
    const newShifts = copyPaste.selection.data.map(item => ({
      ...item,
      date: scheduleState.selectedDay % 7,
      draft: true,
    }));

    dispatch(
      showConfirmModal({
        title: intl.formatMessage(scheduleMessages.pasteFlexConfirmationTitle, {}),
        description: intl.formatMessage(scheduleMessages.pasteFlexConfirmationDesc, {}),
        confirmText: intl.formatMessage(scheduleMessages.paste, {}),
        confirmType: 'warning',
        confirmFunc: () => {
          dispatch(clearFlexScheduleDay(scheduleState.selectedDay));
          dispatch(changeFlexTemplate(newShifts));
        },
      }),
    );
  }
};
