import { useAppSelector } from '@/redux-store';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectRegularEmployees } from '@/redux-store/scheduleState/locations';
import { Location } from '@/types';
import { getScheduleColSpan } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useRegularEmployees = (locationId: Location['id']) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const colSpan = getScheduleColSpan(isSingleDay);
  const regularEmployeeIds = useAppSelector(selectRegularEmployees(locationId));

  return { colSpan, isSingleDay, regularEmployeeIds };
};
