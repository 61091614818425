import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';

import './WeekdayPicker.scss';

const messages = defineMessages({
  monday: {
    id: 'days.monday',
    defaultMessage: 'Pn',
  },
  tuesday: {
    id: 'days.tuesday',
    defaultMessage: 'Wt',
  },
  wednesday: {
    id: 'days.wednesday',
    defaultMessage: 'Śr',
  },
  thursday: {
    id: 'days.thursday',
    defaultMessage: 'Cz',
  },
  friday: {
    id: 'days.friday',
    defaultMessage: 'Pt',
  },
  saturday: {
    id: 'days.saturday',
    defaultMessage: 'So',
  },
  sunday: {
    id: 'days.sunday',
    defaultMessage: 'Nd',
  },
});

const week = [
  {
    ...messages.monday,
    value: 1,
  },
  {
    ...messages.tuesday,
    value: 2,
  },
  {
    ...messages.wednesday,
    value: 3,
  },
  {
    ...messages.thursday,
    value: 4,
  },
  {
    ...messages.friday,
    value: 5,
  },
  {
    ...messages.saturday,
    value: 6,
  },
  {
    ...messages.sunday,
    value: 0,
  },
];

const WeekdayPicker = (props, context) => (
  <div className="weekdayPicker">
    {props.title && <span className="weekdayPicker__title">{props.title}</span>}
    <div className="weekdayPicker__days">
      {week.map(day => (
        <div className="weekdayPicker__checkbox" key={day.value}>
          <MDCheckbox
            id={`checkbox${day.value}`}
            value={props.selectedWeekdays.includes(day.value)}
            text={context.intl.formatMessage(day, {})}
            onChange={() => props.changeWeekdays(day.value)}
            reverse
          />
        </div>
      ))}
    </div>
  </div>
);

WeekdayPicker.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

WeekdayPicker.propTypes = {
  title: PropTypes.shape({}),
  selectedWeekdays: PropTypes.arrayOf(PropTypes.number).isRequired,
  changeWeekdays: PropTypes.func.isRequired,
};

export default WeekdayPicker;
