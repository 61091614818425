import PropTypes from 'prop-types';

import Popover from '@/components/common/Popover/Popover';
import { DIRECTIONS } from '@/constants/ui.ts';
import { useAppSelector } from '@/redux-store/hooks';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore/mainDateStore.selectors';

import PopoverContent from './ScheduleTableWeatherPopoverContent/ScheduleTableWeatherPopoverContent.redux.js';

const ScheduleTableWeatherPopover = ({
  children,
  locationCoordinatesExist,
  weatherForecast,
  city,
  locationId,
  canEditLocations,
}) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);

  if (isSingleDay && locationCoordinatesExist) return children;
  return (
    <Popover
      showOnHover
      preventPopoverHide
      position={DIRECTIONS.TOP}
      showShadow={false}
      content={
        <PopoverContent
          locationCoordinatesExist={locationCoordinatesExist}
          weatherForecast={weatherForecast}
          city={city}
          locationId={locationId}
          canEditLocations={canEditLocations}
        />
      }
      hideTimeout={0}
      yOffset={20}
      useParentDimensions
    >
      <div>{children}</div>
    </Popover>
  );
};

ScheduleTableWeatherPopover.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableWeatherPopover.propTypes = {
  children: PropTypes.node,
  locationCoordinatesExist: PropTypes.bool,
  weatherForecast: PropTypes.shape({
    locationId: PropTypes.number,
    date: PropTypes.string,
    weatherType: PropTypes.string,
    description: PropTypes.string,
    minTemp: PropTypes.number,
    maxTemp: PropTypes.number,
    chanceForPrecipitation: PropTypes.number,
    rainVolume: PropTypes.number,
    snowVolume: PropTypes.number,
  }),
  city: PropTypes.string,
  locationId: PropTypes.string,
  canEditLocations: PropTypes.bool,
};

export default ScheduleTableWeatherPopover;
