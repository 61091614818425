import PropTypes from 'prop-types';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import AddMassEmployeesForm from './AddMassEmployeesForm/AddMassEmployeesForm.redux';
import { getInitState, getTheads } from './addMassEmployeesModal.helpers';
import { messages } from './addMassEmployeesModal.messages';
import { useAddMassEmployee } from './useAddMassEmployee';

import './addMassEmployeesModal.scss';

const AddMassEmployeesModal = ({ jobTitles, userLocations, showModal, hideModal, addMassEmployees }, { intl }) => {
  const [
    employees,
    clearAndHide,
    handleNameChange,
    handleSingleSelectChange,
    selectJobTitles,
    selectLocations,
    handleSubmit,
    validateInput,
    createRow,
    deleteRow,
  ] = useAddMassEmployee(getInitState(2, jobTitles, userLocations), hideModal, addMassEmployees, intl);

  const disabledDeleteIcon = employees.length <= 1;

  return (
    <MDKadroModal
      onSubmit={handleSubmit}
      title={
        <div>
          {intl.formatMessage(messages.addMassEmployeesModalTitle)}
          <span className="mdKadroModalHeader__describe">
            {intl.formatMessage(messages.addMassEmployeesModalDescription)}
          </span>
        </div>
      }
      className="k-massEmployeesAddModal"
      show={showModal}
      onHide={clearAndHide}
      confirmText={intl.formatMessage(messages.addMassEmployeesConfirmButton)}
      hideCancelButton
      withoutOverlayScroll
    >
      <div className="k-massEmployeesAddModal__wrapper">
        <div className="k-massEmployeesAddModal__header">
          {getTheads(intl).map(th => (
            <div className="k-massEmployeesAddModal__titleColumn">{th}</div>
          ))}
        </div>
        <div className="k-massEmployeesAddModal__container">
          {employees.map(
            (
              { name, lastName, role, employmentCondition, employeeLocationOptions, employeeJobTitleOptions, errors },
              index,
            ) => (
              <AddMassEmployeesForm
                {...{
                  name,
                  lastName,
                  role,
                  employmentCondition,
                  employeeLocationOptions,
                  employeeJobTitleOptions,
                  handleNameChange,
                  handleSingleSelectChange,
                  selectJobTitles,
                  selectLocations,
                  validateInput,
                  errors,
                  employeeNumber: index,
                  deleteRow,
                  disabledDeleteIcon,
                }}
              />
            ),
          )}
        </div>
      </div>
      <button onClick={createRow} className="k-massEmployeesAddModal__addEmployeeButton">
        <i className="material-symbols-outlined k-massEmployeesAddModal__addEmployeeIcon">add_circle</i>
        <span className="k-massEmployeesAddModal__descriptionAddEmployeeButton">
          {intl.formatMessage(messages.addMassEmployeesAddEmployeeButton)}
        </span>
      </button>
    </MDKadroModal>
  );
};

AddMassEmployeesModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddMassEmployeesModal.propTypes = {
  jobTitles: PropTypes.shape({}),
  userLocations: PropTypes.arrayOf(),
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  companyEmploymentConditions: PropTypes.arrayOf(),
  companyRoles: PropTypes.arrayOf(),
  addMassEmployees: PropTypes.func,
};

export default AddMassEmployeesModal;
