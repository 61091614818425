import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';

const SettingsFeatureWrapper = ({ children, date, location, locationSettings, restrictions }) => {
  const {
    disable_location_attendances_edit_until: disableLocationAttendancesEditUntil,
    disable_location_schedule_shifts_edit_until: disableLocationScheduleShiftsEditUntil,
  } = locationSettings[location.id] || {};

  const checkingDate = date ? moment(date) : moment();
  const editAttendancesDisabled = checkingDate.diff(disableLocationAttendancesEditUntil, 'days') <= 0;
  const isShiftEditingBlocked = useMemo(() => restrictions.includes(SCHEDULE_EDIT_DISABLE), [restrictions]);
  const editShitsDisabled =
    checkingDate.diff(disableLocationScheduleShiftsEditUntil, 'days') <= 0 || isShiftEditingBlocked;

  return children({
    editAttendancesDisabled,
    editAttendancesDisabledUntil: disableLocationAttendancesEditUntil,
    editShitsDisabled,
    editShitsDisabledUntil: disableLocationScheduleShiftsEditUntil,
  });
};

SettingsFeatureWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  locationSettings: PropTypes.shape({
    id: PropTypes.string,
    disable_location_attendances_edit_until: PropTypes.string,
    disable_location_schedule_shifts_edit_until: PropTypes.string,
  }),
};

export default SettingsFeatureWrapper;
