import { connect } from 'react-redux';

import { requestAuthenticateUser, requestAuthenticateUserFromCode, startLogRocket } from '@/actions/auth.jsx';
import LoginView from '@/components/LoginView.jsx';

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  authUser: requestAuthenticateUser,
  requestAuthenticateUserFromCode,
  startLogRocket,
};

const MainViewLogin = connect(mapStateToProps, mapDispatchToProps)(LoginView);

export default MainViewLogin;
