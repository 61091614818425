import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'attendance.addModal.title',
    defaultMessage: 'Dodaj obecność',
  },
  confirmText: {
    id: 'common.add',
    defaultMessage: 'Dodaj',
  },
  headerText: {
    id: 'attendance.addModal.addAttendanceInDay',
    defaultMessage: 'Dodaj obecność w dniu {date}',
  },
  chooseLocation: {
    id: 'attendance.addModal.chooseLocation',
    defaultMessage: 'Lokalizacja',
  },
  chooseEmployee: {
    id: 'attendance.addModal.chooseEmployee',
    defaultMessage: 'Wybierz pracownika',
  },
  time: {
    id: 'common.time',
    defaultMessage: 'Czas',
  },
  addAttendanceForEmployee: {
    id: 'attendance.addModal.addAttendanceForEmployee',
    defaultMessage: 'Dodaj obecność dla {name}',
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Data',
  },
});
