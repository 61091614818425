import classnames from 'classnames';
import PropTypes from 'prop-types';

import MDIconButton from '../Basic/MDIconButton/MDIconButton';

import './dropdownMenu.scss';

const DropdownMenu = ({ options }) => (
  <div className="k-dropdownMenu">
    {options.map(({ id, icon, title, onClick, disabled, show }) => {
      const buttonClassName = classnames('k-dropdownMenu__button', { 'k-dropdownMenu__button--disabled': disabled });
      if (!show) return null;
      return (
        <button className={buttonClassName} key={id} onClick={onClick} disabled={disabled}>
          <MDIconButton icon={icon} />
          <span className="k-dropdownMenu__title">{title}</span>
        </button>
      );
    })}
  </div>
);

DropdownMenu.propTypes = {
  options: PropTypes.arrayOf({
    id: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

export default DropdownMenu;
