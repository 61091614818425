import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDListContainer, MDRadiosWithSublabel, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import AttachEmployee from '@/containers/common/AttachEmployeeContainer';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers';
import { getEmployeeRank, MAX_RANK } from '@/utils/userEmployeesHelpers.js';

import { getEmployeesWithRole, getRolesOptions, messages } from './RolesModalHelper';

class PermissionRoleModal extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();
    bindPrototypeFunctions(this);
  }

  getInitialState() {
    const { currentUser, companyRoles, companySettings } = this.props;
    const userRank = getEmployeeRank(currentUser, companyRoles);
    const userMaxRank = companySettings.same_rank_is_visible ? userRank : userRank - 1;
    const maxValue = userRank > MAX_RANK ? MAX_RANK : userMaxRank;
    return { roleName: '', rank: '', is_manager: false, errors: {}, maxValue };
  }

  componentDidUpdate(prevProps) {
    const { modalObject } = this.props;
    if (modalObject && modalObject?.id !== prevProps.modalObject?.id) {
      this.setState({
        roleName: modalObject.name,
        rank: modalObject.rank,
        is_manager: modalObject.is_manager,
      });
    }
  }

  async onSubmit() {
    return this.validateAll().then(valid => {
      if (!valid) return;
      const baseRole = this.state.is_manager ? 'manager' : 'employee';
      const role = {
        company_id: this.props.currentCompanyId,
        is_manager: this.state.is_manager,
        name: this.state.roleName,
        rank: parseInt(this.state.rank),
        permissions: this.props.modalObject
          ? this.props.preCheckedPermissions[this.props.modalObject.id].map(permission => permission.id)
          : this.props.companyRoles.find(companyRole => companyRole.id === baseRole).permissions,
      };
      if (this.props.modalObject) {
        this.props.editRole(
          { toAdd: [], toDelete: [] },
          {
            ...role,
            id: this.props.modalObject.id,
            is_manager: this.props.modalObject.is_manager,
          },
        );
      } else {
        const attachedEmployees = this.AttachEmployee.getChanges();
        this.props.addRole(attachedEmployees, role);
      }

      this.hideAndClear();
    });
  }

  async validateAll() {
    const inputs = ['roleName', 'rank'];
    const promises = inputs.map(i =>
      this.validateInput({
        target: { name: i, value: this.state[i] },
      }),
    );

    await Promise.all(promises);
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  validateInput(event) {
    const { currentUser, companyRoles } = this.props;
    const currentUserRole = companyRoles.find(role =>
      currentUser.role_id ? role.id === currentUser.role_id : role.id === currentUser.role,
    );
    const errorMessageValues = {
      rank: { roleName: currentUserRole?.name },
    };

    validateInputHelper(event, this.state.maxValue).then(res => {
      const [name, error] = res;
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          [name]: error && this.context.intl.formatMessage(error, { ...errorMessageValues[name] }),
        },
      }));
    });
  }

  handleInputChange(event) {
    const { type, checked, value, name } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    this.validateInput(event);
    this.setState({ [name]: newValue });
  }

  hideAndClear() {
    this.setState(this.getInitialState());
    this.props.hideModal();
  }

  handleRadiosChange(e) {
    const isManager = e.target.value === 'manager';
    this.setState({ is_manager: isManager });
  }

  render() {
    const { modalObject, userEmployees } = this.props;
    const title = modalObject
      ? this.context.intl.formatMessage(messages.editPermissionRole, {})
      : this.context.intl.formatMessage(messages.addPermissionRole, {});
    const preSelected = modalObject
      ? userEmployees.reduce((ids, emp) => (emp.role_id === modalObject.id ? [...ids, emp.id] : ids), [])
      : [];
    return (
      <MDKadroModal
        show={this.props.show}
        onHide={this.hideAndClear}
        onCancel={this.hideAndClear}
        modifiers={['narrow']}
        onSubmit={this.onSubmit}
        title={title}
      >
        <MDTextInput
          value={this.state.roleName}
          onChange={this.handleInputChange}
          onBlur={this.validateInput}
          type="text"
          id="roleName"
          label={<FormattedMessage id="addPermissionRole.name" defaultMessage="Nazwa" />}
          errorMessage={this.state.errors.roleName}
          modifiers={['modal']}
        />
        <MDTextInput
          id="rank"
          type="number"
          label={<FormattedMessage id="addPermissionRole.rank" defaultMessage="Ranga" />}
          value={this.state.rank}
          onChange={e => this.handleInputChange(e)}
          modifiers={['modal']}
          isNumberInteger
          errorMessage={this.state.errors.rank}
          maxValue={MAX_RANK}
          tooltip={
            <FormattedMessage
              {...messages.rankTooltip}
              values={{
                clickHere: (
                  <a
                    href="https://pomoc.kadromierz.pl/pl/articles/3967133-role-uprawnienia-i-restrykcje#h_3c904d99ad"
                    target="__blank"
                  >
                    {this.context.intl.formatMessage(messages.clickHere)}
                  </a>
                ),
              }}
            />
          }
        />
        {modalObject ? (
          <MDListContainer
            items={getEmployeesWithRole(this.props.userEmployees, modalObject.id)}
            title={<FormattedMessage id="common.attachEmployee.header" defaultMessage="Przypisani pracownicy" />}
          />
        ) : (
          <>
            <MDRadiosWithSublabel
              options={getRolesOptions()}
              onChange={this.handleRadiosChange}
              selectedItemId={this.state.is_manager ? 'manager' : 'employee'}
              radiosId="role"
            />
            <AttachEmployee
              ref={ref => {
                this.AttachEmployee = ref;
              }}
              preSelected={preSelected}
            />
          </>
        )}
      </MDKadroModal>
    );
  }
}

PermissionRoleModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PermissionRoleModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  editRole: PropTypes.func,
  addRole: PropTypes.func,
  currentCompanyId: PropTypes.string,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
    role_id: PropTypes.string,
  }),
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    rank: PropTypes.number,
    is_manager: PropTypes.bool,
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  preCheckedPermissions: PropTypes.shape({}),
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  companySettings: PropTypes.shape({
    same_rank_is_visible: PropTypes.bool,
  }),
};

export default PermissionRoleModal;
