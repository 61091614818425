import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { getRolePermissionFilter } from '@/actions/companymanage/roles.helpers';
 import * as actionTypes from '@/constants/ActionTypes.js';
import { permissionsLangs, permissionsOrder, rolesLangs } from '@/constants/Roles.js';
import { formatSupplementaryLocationsIdsForEmployeeUpdate } from '@/utils/userEmployeesHelpers';

import { conn, showConfirmModal } from '../index';

const permissionRoleToastr = defineMessages({
  toastrAddSuccess: {
    id: 'addPermissionRole.toastr.addSuccess',
    defaultMessage: 'Pomyślnie zapisano rolę',
  },
  toastrAddError: {
    id: 'addPermissionRole.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas zapisywania roli',
  },
  toastrEditSuccess: {
    id: 'addPermissionRole.toastr.editSuccess',
    defaultMessage: 'Pomyślnie edytowano rolę',
  },
  toastrEditError: {
    id: 'addPermissionRole.toastr.editError',
    defaultMessage: 'Wystąpił błąd podczas edytowania roli',
  },
  toastrSaveSuccess: {
    id: 'addPermissionRole.toastr.saveSuccess',
    defaultMessage: 'Pomyślnie zapisano role',
  },
  toastrSaveError: {
    id: 'addPermissionRole.toastr.saveError',
    defaultMessage: 'Wystąpił błąd podczas zapisywania roli',
  },
  toastrDeleteSuccess: {
    id: 'addPermissionRole.toastr.deleteSuccess',
    defaultMessage: 'Pomyślnie usunięto rolę',
  },
  toastrDeleteError: {
    id: 'addPermissionRole.toastr.deleteError',
    defaultMessage: 'Wystąpił błąd podczas usuwania roli',
  },
  confirmModalTitle: {
    id: 'deleteRole.confirmModal.title',
    defaultMessage: 'Usuń rolę',
  },
  confirmModalDescription: {
    id: 'deleteRole.confirmModal.description',
    defaultMessage: 'Usuwając rolę utracisz możliwość przywrócenia jej',
  },
  confirmModalButtonText: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
  choosenOneRole: {
    id: 'deleteRole.confirmModal.choosenOneRole',
    defaultMessage: 'wybraną rolę',
  },
});

export const getPermissionsSuccess = (permissions, grouppedPermissions) => ({
  type: actionTypes.GET_PERMISSIONS_SUCCESS,
  payload: { permissions, grouppedPermissions },
});

export const getRolesSuccess = (companyRoles, preCheckedPermissions) => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload: { companyRoles, preCheckedPermissions },
});

export const changeUserRoleSuccess = userRoles => ({
  type: actionTypes.CHANGE_USER_ROLE_SUCCESS,
  payload: userRoles,
});

export const toggleGroupSuccess = id => ({
  type: actionTypes.TOGGLE_GROUP_SUCCESS,
  payload: id,
});

export const togglePermissionSuccess = (roleId, perm) => ({
  type: actionTypes.TOGGLE_PERMISSION_SUCCESS,
  payload: { roleId, perm },
});

export const checkPermissionsSuccess = (roleId, perms) => ({
  type: actionTypes.CHECK_PERMISSIONS_SUCCESS,
  payload: { roleId, perms },
});

export const uncheckPermissionsSuccess = (roleId, groupId) => ({
  type: actionTypes.UNCHECK_PERMISSIONS_SUCCESS,
  payload: { roleId, groupId },
});

export const saveRolesSuccess = roles => ({
  type: actionTypes.SAVE_ROLES_SUCCESS,
  payload: roles,
});

export const addRoleSuccess = (role, preCheckedPermissions) => ({
  type: actionTypes.ADD_ROLE_SUCCESS,
  payload: { role, preCheckedPermissions },
});

export const editRoleSuccess = role => ({
  type: actionTypes.EDIT_ROLE_SUCCESS,
  payload: role,
});

export const deleteRoleSuccess = id => ({
  type: actionTypes.DELETE_ROLE_SUCCESS,
  payload: id,
});

export const removeSupplementaryLocationsAfterRoleAssign = employeesIds => ({
  type: actionTypes.REMOVE_SUPPLEMENTARY_LOCATIONS_AFTER_ROLE_ASSIGN,
  payload: employeesIds,
});

export const toggleGroup = id => dispatch => {
  dispatch(toggleGroupSuccess(id));
};

export const togglePermission = (roleId, permission) => dispatch => {
  const perm = { id: permission.id, groupId: permission.group_id };
  dispatch(togglePermissionSuccess(roleId, perm));
};

export const checkPermissions = (roleId, permissions) => dispatch => {
  const perms = permissions.map(perm => ({ id: perm.id, groupId: perm.group_id }));
  dispatch(checkPermissionsSuccess(roleId, perms));
};

export const uncheckPermissions = (roleId, groupId) => dispatch => {
  dispatch(uncheckPermissionsSuccess(roleId, groupId));
};

export const updateUserForRole = (toAdd, toDelete, roleID, isManager) => async dispatch => {
  if (isManager) {
    dispatch(removeSupplementaryLocationsAfterRoleAssign(toAdd.map(e => e.id)));
  }

  const data = [
    ...toAdd.map(emp => ({ ...emp, role_id: roleID, role: isManager ? 'manager' : 'employee' })),
    ...toDelete.map(emp => ({ ...emp, role_id: emp.role })),
  ];
  const employeesToUpdate = formatSupplementaryLocationsIdsForEmployeeUpdate(data);
  if (employeesToUpdate.length) {
    await conn.massChange('userEmployees', employeesToUpdate);
    dispatch(changeUserRoleSuccess(employeesToUpdate));
  }
};

export const getPermissions = () => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .getPermissions()
      .then(result => {
        const permissions = result.data.available_permissions.filter(getRolePermissionFilter()).map(permission => ({
          ...permission,
          name: intl.formatMessage(permissionsLangs[permission.id], {}),
          order: permissionsOrder[permission.id] || 0,
        }));
        const grouppedPermissions = permissions.reduce((prev, curr) => {
          if (prev[curr.group_id]) {
            prev[curr.group_id].push(curr);
          } else {
            prev[curr.group_id] = [curr];
          }
          return prev;
        }, {});
        dispatch(getPermissionsSuccess(permissions, grouppedPermissions));
        resolve();
      })
      .catch(reject);
  });

export const getRoles = () => (dispatch, getState, intl) => {
  conn.getRoles().then(result => {
    const { availablePermissions } = getState().reducer.roles;
    const companyRoles = result.data.roles.map(role => {
      if (rolesLangs[role.id]) return { ...role, name: intl.formatMessage(rolesLangs[role.id], {}) };
      return role;
    });
    const preCheckedPermissions = {};
    companyRoles.forEach(role => {
      preCheckedPermissions[role.id] = role.permissions
        .map(permId => {
          const foundPerm = availablePermissions.find(avaPerm => avaPerm.id === permId);
          return foundPerm ? { id: foundPerm.id, groupId: foundPerm.group_id } : null;
        })
        .filter(perm => perm);
    });
    dispatch(getRolesSuccess(companyRoles, preCheckedPermissions));
  });
};

export const saveRoles = roles => async (dispatch, getState, intl) => {
  const { availablePermissions, companyRoles } = getState().reducer.roles;
  const availablePermissionIds = availablePermissions.map(permission => permission.id);
  const permissions = Object.keys(roles)
    .filter(roleId => roleId !== 'employee' && roleId !== 'manager')
    .map(roleId => {
      const relevantRole = companyRoles.find(role => role.id === roleId);
      return {
        ...relevantRole,
        permissions: roles[roleId].map(permission => permission.id),
      };
    });
  await conn
    .saveRoles({ permissions, availablePermissionIds })
    .then(() => {
      dispatch(saveRolesSuccess(roles));
      toastr.success(intl.formatMessage(permissionRoleToastr.toastrSaveSuccess, {}));
    })
    .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrSaveError, {})));
};

export const addRole = (employees, role) => (dispatch, getState, intl) => {
  conn
    .saveRoles([role])
    .then(result => {
      const { availablePermissions } = getState().reducer.roles;
      const preCheckedPermissions = {};
      preCheckedPermissions[result.data[0].id] = role.permissions
        .map(permId => {
          const foundPerm = availablePermissions.find(avaPerm => avaPerm.id === permId);
          return foundPerm ? { id: foundPerm.id, groupId: foundPerm.group_id } : null;
        })
        .filter(perm => perm);
      const { toAdd, toDelete } = employees;
      dispatch(updateUserForRole(toAdd, toDelete, result.data[0].id, result.data[0].is_manager))
        .then(() => toastr.success(intl.formatMessage(permissionRoleToastr.toastrAddSuccess, {})))
        .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrAddError, {})));
      dispatch(addRoleSuccess(result.data, preCheckedPermissions));
    })
    .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrAddError, {})));
};

export const editRole = (employees, role) => (dispatch, getState, intl) => {
  conn
    .saveRoles([role])
    .then(result => {
      const { toAdd, toDelete } = employees;
      dispatch(updateUserForRole(toAdd, toDelete, role.id, role.is_manager))
        .then(() => toastr.success(intl.formatMessage(permissionRoleToastr.toastrEditSuccess, {})))
        .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrAddError, {})));
      dispatch(editRoleSuccess(result.data));
    })
    .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrAddError, {})));
};

export const deleteRole = (id, roleToReplace) => (dispatch, getState, intl) => {
  const { userEmployees } = getState().reducer;
  const toAdd = userEmployees.filter(emp => emp.role_id === id);
  dispatch(updateUserForRole(toAdd, [], roleToReplace.id, roleToReplace.is_manager))
    .then(() => {
      conn
        .deleteRole(id)
        .then(() => {
          dispatch(deleteRoleSuccess(id));
          toastr.success(intl.formatMessage(permissionRoleToastr.toastrDeleteSuccess, {}));
        })
        .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrDeleteError, {})));
    })
    .catch(() => toastr.error(intl.formatMessage(permissionRoleToastr.toastrDeleteError, {})));
};

export const deleteRoleConfirm = (id, roleToReplace) => (dispatch, getState, intl) => {
  const title = intl.formatMessage(permissionRoleToastr.confirmModalTitle, {});
  const description = intl.formatMessage(permissionRoleToastr.confirmModalDescription, {});
  const confirmText = intl.formatMessage(permissionRoleToastr.confirmModalButtonText, {});
  const confirmFunc = () => {
    dispatch(deleteRole(id, roleToReplace));
  };
  const deletedThing = intl.formatMessage(permissionRoleToastr.choosenOneRole, {});
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};
