import PropTypes from 'prop-types';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton';
import { ADD_ABSENCE_PROPOSAL_MODAL,EMPLOYEE_SHIFT_MODAL } from '@/constants/modalTypes';

import { messages } from './EmployeeTimesheetOptions.messages';

const EmployeeTimesheetOptions = (props, context) => {
  const { rowData, locationSettings, showModal, showDeleteCurrentUserShiftConfirmModal } = props;
  const disabledLocationScheduleDate =
    locationSettings[rowData.location?.id]?.disable_location_schedule_shifts_edit_until;
  const isAbsence = rowData.blockType === 'absence';
  const isShift = rowData.blockType === 'shift';
  const isBeforeDisableDate = disabledLocationScheduleDate ? disabledLocationScheduleDate < rowData.date : true;
  const canBeShiftChanged = isShift && isBeforeDisableDate;

  const handleEditShift = () => {
    showModal(EMPLOYEE_SHIFT_MODAL, rowData);
  };

  const handleDeleteShift = () => {
    showDeleteCurrentUserShiftConfirmModal([rowData.id], rowData.employee.id);
  };

  const handleOpenProposalAbsence = () => {
    showModal(ADD_ABSENCE_PROPOSAL_MODAL, rowData);
  };

  return (
    <div>
      {isShift && (
        <>
          <MDIconButton
            disabled={!canBeShiftChanged}
            icon="create"
            label={context.intl.formatMessage(messages.edit)}
            onClick={handleEditShift}
          />
          <MDIconButton
            disabled={!canBeShiftChanged}
            icon="delete"
            label={context.intl.formatMessage(messages.delete)}
            onClick={handleDeleteShift}
          />
        </>
      )}
      {isAbsence && (
        <MDIconButton
          icon="description"
          label={context.intl.formatMessage(messages.proposal)}
          onClick={handleOpenProposalAbsence}
        />
      )}
    </div>
  );
};

EmployeeTimesheetOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeTimesheetOptions.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    blockType: PropTypes.string,
    type_id: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.shape({ id: PropTypes.string }),
    employee: PropTypes.shape({ id: PropTypes.string }),
  }),
  showModal: PropTypes.func,
  locationSettings: PropTypes.shape({}),
  showDeleteCurrentUserShiftConfirmModal: PropTypes.func,
};

export default EmployeeTimesheetOptions;
