import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerTitle: { id: 'common.attachEmployee.header', defaultMessage: 'Przypisani pracownicy' },
  selectAll: { id: 'common.attachEmployee.selectAllButton', defaultMessage: 'Wszyscy' },
  deselectAll: { id: 'common.attachEmployee.deselectAllButton', defaultMessage: 'Żaden' },
  bodyLabel: { id: 'common.attachEmployee.chooseEmployees', defaultMessage: 'Wybierz pracowników:' },
});

export const getAttachedEmployeesMessages = intl => ({
  headerTitle: intl.formatMessage(messages.headerTitle),
  selectAll: intl.formatMessage(messages.selectAll),
  deselectAll: intl.formatMessage(messages.deselectAll),
  bodyLabel: intl.formatMessage(messages.bodyLabel),
});
