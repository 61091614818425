import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  earlyOrPunctualStartAttendances: {
    id: 'attendancesAndAbsencesChart.attendances.earlyOrPunctualStartAttendances',
    defaultMessage: 'Ob. punktualne',
  },
  lateAttendances: {
    id: 'attendancesAndAbsencesChart.attendances.late',
    defaultMessage: 'Ob. ze spóźnieniem',
  },
  attendancesWithoutShift: {
    id: 'attendancesAndAbsencesChart.attendances.attendancesWithoutShift',
    defaultMessage: 'Ob. niezaplanowane',
  },
  absences: {
    id: 'attendancesAndAbsencesChart.absences',
    defaultMessage: 'Nieob. planowane',
  },
  shifts: {
    id: 'attendancesAndAbsencesChart.shifts',
    defaultMessage: 'Zmiany',
  },
});
