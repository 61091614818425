import { unionBy } from 'lodash';
import moment from 'moment';

import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  recommendedSchedule: [],
  imported: false,
  errorMessages: [],
};

const recommendedSchedule = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_RECOMMENDED_SCHEDULE_SUCCESS:
      return {
        ...state,
        recommendedSchedule: unionBy(action.payload, state, 'id'),
      };
    case AT.ADD_RECOMMENDED_SCHEDULE_SUCCESS:
      return {
        ...state,
        recommendedSchedule: unionBy(action.payload, state, 'date'),
        imported: true,
      };
    case AT.ADD_RECOMMENDED_SCHEDULE_ERROR:
      return { ...state, imported: true, errorMessages: action.payload };
    case AT.DELETE_RECOMMENDED_SCHEDULE_SUCCESS:
      return {
        ...state,
        recommendedSchedule: state.recommendedSchedule.filter(
          val =>
            !moment(val.date).isBetween(action.payload.from, action.payload.to) &&
            action.payload.locationId === val.locationId,
        ),
      };
    case AT.HIDE_MODAL:
      return { ...state, imported: false };
    default:
      return state;
  }
};

export default recommendedSchedule;
