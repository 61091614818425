import PropTypes from 'prop-types';

import EmployeeScheduleAvaCorner from '../EmployeeScheduleAvaCorner/EmployeeScheduleAvaCorner.redux';
import EmployeeScheduleBlocks from '../EmployeeScheduleBlocks/EmployeeScheduleBlocks.redux';
import EmployeeScheduleTableItem from '../EmployeeScheduleTableItem/EmployeeScheduleTableItem.redux';
import EmployeeScheduleTableRowTitle from '../EmployeeScheduleTableRowTitle/EmployeeScheduleTableRowTitle.redux.js';

const EmployeeScheduleTableRow = props => {
  const { dateArray, employee, locationId } = props;

  return (
    <tr>
      <EmployeeScheduleTableRowTitle employeeId={employee.id} key={employee.id} />
      {dateArray.map((date, index) => (
        <EmployeeScheduleTableItem employeeId={employee.id} key={date} date={date}>
          <EmployeeScheduleAvaCorner date={date} employee={employee} />
          <EmployeeScheduleBlocks date={date} employee={employee} locationId={locationId} index={index} />
        </EmployeeScheduleTableItem>
      ))}
    </tr>
  );
};

EmployeeScheduleTableRow.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    shifts_for_other_locations: PropTypes.arrayOf(PropTypes.shape({})),
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  locationId: PropTypes.string,
};

export default EmployeeScheduleTableRow;
