import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

import './EmployeeScheduleTableRowTitle.scss';

const getFormattedWorkedHours = (workedHoursForSelectedPeriod, currentUserId, employeeId) => {
  if (currentUserId !== employeeId) {
    return '';
  }

  if (workedHoursForSelectedPeriod === undefined) {
    return '-';
  }

  return parseMinutesToFormat(workedHoursForSelectedPeriod * 60);
};

const EmployeeScheduleTableRowTitle = ({
  firstName,
  lastName,
  workedHoursForSelectedPeriod,
  currentUserId,
  employeeId,
  userEmployees,
}) => {
  const employee = useMemo(() => firstName && lastName
      ? {
          first_name: firstName,
          last_name: lastName,
        }
      : userEmployees.find(employee => employee.id === employeeId), [firstName, lastName, userEmployees, employeeId]);
  const employeeName = getEmployeeNameShort(employee, 17);
  const formattedWorkedHours = getFormattedWorkedHours(workedHoursForSelectedPeriod, currentUserId, employeeId);
  return (
    <td>
      <div className="k-employeeScheduleTableRowTitle">{employeeName}</div>
      {formattedWorkedHours && <div className="k-scheduleTableRowTitle__hours">{formattedWorkedHours} </div>}
    </td>
  );
};

EmployeeScheduleTableRowTitle.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  workedHoursForSelectedPeriod: PropTypes.number,
  currentUserId: PropTypes.string,
  employeeId: PropTypes.string,
};

export default memo(EmployeeScheduleTableRowTitle);
