import {
  OpenShiftAssignment,
  OpenShiftsAssignmentsStoreState,
} from '@/redux-store/openShiftsAssignments/openShiftsAssignments.types.ts';

export const openShiftsAssignmentsToStore = (
  openShiftsAssignments: OpenShiftAssignment[],
): OpenShiftsAssignmentsStoreState =>
  openShiftsAssignments.reduce((acc, assignment) => {
    if (!acc[assignment.employee_id]) {
      acc[assignment.employee_id] = [];
    }
    acc[assignment.employee_id].push(assignment);
    return acc;
  }, {} as OpenShiftsAssignmentsStoreState);
