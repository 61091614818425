import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  locations: {
    id: 'exportsItem.locations',
    defaultMessage: 'Lokalizacje',
  },
  jobTitles: {
    id: 'exportsItem.jobTitles',
    defaultMessage: 'Stanowiska',
  },
  download: {
    id: 'exportsItem.download',
    defaultMessage: 'Pobierz',
  },
  generatedAt: {
    id: 'exportsItem.generatedAt',
    defaultMessage: 'Wygenerowano: {timestamp}',
  },
});
