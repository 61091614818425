import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { MDMultiSelectHeader, MDRadios } from '@/components/common/inputs/MDComponents';
import MDMultiSelectPopover from '@/components/common/inputs/MDMultiSelect/MDMultiSelectPopover/MDMultiSelectPopover';
import { modalMessages } from '@/components/scheduler/modals/AddOpenShiftModal/AddOpenShiftModal.messages';
import { OpenShiftAssignedForEnum } from '@/constants/openShifts.ts';
import { MultiSelectOptionType } from '@/types';
import { getLabelForItems } from '@/utils/filters/filters.helpers';

import './AddOpenShiftModalSteps.scss';

type AddOpenShiftAdditionalStepProps = {
  changeOpenShiftAssignedFor: (type: OpenShiftAssignedForEnum) => void;
  openShiftAssignedFor: OpenShiftAssignedForEnum;
  locationGroupsMappedForMultiselect: MultiSelectOptionType[];
  handleChangeMultiSelect: (e: MultiSelectOptionType[], key: string) => void;
  selectAllMultiSelect: (key: string, options: MultiSelectOptionType[]) => void;
  deselectAllMultiSelect: (key: string) => void;
  companyEmploymentConditionsMappedForMultiselect: MultiSelectOptionType[];
  errors: Record<string, string>;
};

const AddOpenShiftAdditionalStep = ({
  changeOpenShiftAssignedFor,
  openShiftAssignedFor,
  locationGroupsMappedForMultiselect,
  handleChangeMultiSelect,
  selectAllMultiSelect,
  deselectAllMultiSelect,
  companyEmploymentConditionsMappedForMultiselect,
  errors,
}: AddOpenShiftAdditionalStepProps) => {
  const intl = useIntl();
  const openShiftTargetOptions = useMemo(
    () => [
      {
        name: intl.formatMessage(modalMessages.openShiftAssignedToCurrentLocationTitle),
        type: OpenShiftAssignedForEnum.singleLocation,
        description: intl.formatMessage(modalMessages.openShiftAssignedToCurrentLocation),
      },
      {
        name: intl.formatMessage(modalMessages.openShiftAssignedToLocationGroupsTitle),
        type: OpenShiftAssignedForEnum.locationGroups,
        description: intl.formatMessage(modalMessages.openShiftAssignedToLocationGroups),
      },
    ],
    [intl],
  );
  return (
    <>
      <MDRadios
        elements={openShiftTargetOptions}
        selectedItemType={openShiftAssignedFor}
        onChangeType={changeOpenShiftAssignedFor}
      />

      {openShiftAssignedFor === OpenShiftAssignedForEnum.locationGroups && (
        <div className="k-openShiftModalAdditionalStep__forLocationGroupsSection">
          <div>
            <MDMultiSelectHeader
              title={intl.formatMessage(modalMessages.locationGroups)}
              selectAll={() => selectAllMultiSelect('selectedLocationGroupsIds', locationGroupsMappedForMultiselect)}
              deselectAll={() => deselectAllMultiSelect('selectedLocationGroupsIds')}
              testId="selectAllLocationGroups"
            />
            <MDMultiSelectPopover
              label={
                getLabelForItems(locationGroupsMappedForMultiselect) ||
                intl.formatMessage(modalMessages.chooseLocationGroups)
              }
              options={locationGroupsMappedForMultiselect}
              onChange={e => handleChangeMultiSelect(e, 'selectedLocationGroupsIds')}
              testId="selectLocationGroups"
            />
            <span className="k-openShiftModalAdditionalStep__errorMessage">{errors.selectedLocationGroupsIds}</span>
          </div>
          <div>
            <MDMultiSelectHeader
              title={intl.formatMessage(modalMessages.employmentConditions)}
              selectAll={() =>
                selectAllMultiSelect('employmentConditionsIds', companyEmploymentConditionsMappedForMultiselect)
              }
              deselectAll={() => deselectAllMultiSelect('employmentConditionsIds')}
              testId="selectAllEmploymentConditions"
            />
            <MDMultiSelectPopover
              label={
                getLabelForItems(companyEmploymentConditionsMappedForMultiselect) ||
                intl.formatMessage(modalMessages.employmentConditionsPlaceholder)
              }
              options={companyEmploymentConditionsMappedForMultiselect}
              onChange={e => handleChangeMultiSelect(e, 'employmentConditionsIds')}
              testId="selectEmploymentConditions"
            />
            <span className="k-openShiftModalAdditionalStep__errorMessage">{errors.employmentConditionsIds}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default AddOpenShiftAdditionalStep;
