import { AppDispatch, GetStateType } from '@/redux-store';
import { intercomTrackEvent } from '@/utils/intercomHelpers';

export const trackIntercomEvent =
  (intercomEventType: string) =>
  (_: AppDispatch, getState: GetStateType): void => {
    const {
      currentUser: { user },
    } = getState().reducer;
    const { id: userId, company_id: companyId } = user;

    intercomTrackEvent(intercomEventType, { userId, companyId });
  };
