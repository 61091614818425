import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { MDTooltip } from '@/components/common/inputs/MDComponents.jsx';
import SearchBox from '@/components/common/inputs/SearchBox/SearchBox.jsx';
import { ACCEPT_OVERTIME_MODAL, ADD_ATTENDANCE_MODAL, ALIGN_ATTENDANCES_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import {
  getAttendancesFromShifts,
  getAttendancesWithoutShiftsForSelectedEmployees,
  getShiftsWithoutAttendancesForSelectedEmployees,
} from '@/utils/attendanceHelpers';
import { getOvertimeData } from '@/utils/attendanceOvertimeHelpers.js';
import { getRelevantContractForDate } from '@/utils/contracts';

import { messages } from './AttendanceButtonBar.messages';
import AttendancesSettingsButton from './attendancesSettingsButton/AttendancesSettingsButton.redux.ts';

import './attendanceButtonBar.scss';

const AttendanceButtonBar = (
  {
    shiftsWithoutAttendances,
    unassignedAttendances,
    selectedEmployees,
    createAttendance,
    exportEmployeesData,
    relevantAttendances,
    currentDate,
    relevantEmployees,
    showModal,
    toggleExportModal,
    approveAttendances,
    selectAllAttendances,
    unselectAllAttendances,
    attendanceDataForEmployees,
    locationSettings,
    locationFilter,
    mainDateStore,
    searchString,
    setAttendanceSearchString,
    disabled,
    refreshData,
    noApprovedAttendances,
    createMassAttendances,
    restrictions,
    contracts,
    userJobTitles,
    locationData,
  },
  { intl },
) => {
  const [blockedAdding, setBlockedAdding] = useState(false);
  const shiftsWithoutAttendancesForSelectedEmployees = getShiftsWithoutAttendancesForSelectedEmployees(
    shiftsWithoutAttendances,
    selectedEmployees,
  );
  const unassignedAttendancesDataForSelectedEmployees = getAttendancesWithoutShiftsForSelectedEmployees(
    unassignedAttendances,
    selectedEmployees,
  );

  const relevantEmployeesIds = relevantEmployees.map(employee => employee.id);

  const employeesAttendanceOvertimeData = getOvertimeData(attendanceDataForEmployees);

  const selectedEmployeesAttendanceOvertimeData = employeesAttendanceOvertimeData.filter(
    data =>
      selectedEmployees.some(
        selected =>
          selected?.employeeId === data?.employee?.id && selected?.locationId === data?.attendance?.location?.id,
      ) && data.attendance.end_timestamp,
  );

  const hasAllItemsSelected = useMemo(
    () =>
      selectedEmployees.length &&
      !relevantEmployeesIds.some(
        visibleEmployeeId => !selectedEmployees.some(selected => selected.employeeId === visibleEmployeeId),
      ),
    [relevantEmployeesIds, selectedEmployees],
  );

  const onApprove = unassignedAttendancesData => {
    approveAttendances(
      unassignedAttendancesData.map(att => {
        const employeeContracts = contracts[att.employee.id] || [];
        const relevantContract = getRelevantContractForDate(employeeContracts, att.startTimestamp);
        const jobTitle = userJobTitles.find(jobtitle => jobtitle.id === relevantContract?.job_titles[0]?.job_title_id);
        const newAtt = {
          ...att,
          job_title: jobTitle,
        };
        return newAtt;
      }),
    );
  };

  const onAdd = async shiftsWithoutAttendancesData => {
    setBlockedAdding(true);
    if (shiftsWithoutAttendancesData.length === 1) {
      const [{ detail, employee, locationId }] = shiftsWithoutAttendancesData;
      await createAttendance(employee.id, detail.startTimestamp, detail.endTimestamp, locationId);
    } else {
      const attendances = getAttendancesFromShifts(shiftsWithoutAttendancesData);
      await createMassAttendances(attendances);
    }
    setBlockedAdding(false);
  };

  const handleToggleAll = () => {
    const selected = locationData.flatMap(({ locationId, relevantEmployees }) =>
      relevantEmployees.map(employee => ({
        employeeId: employee.id,
        locationId,
      })),
    );

    if (hasAllItemsSelected) {
      unselectAllAttendances();
    } else {
      selectAllAttendances(selected);
    }
  };

  const isLocationAttendancesEditDisabled =
    locationSettings[locationFilter.selectedLocation.id] &&
    locationSettings[locationFilter.selectedLocation.id].disable_location_attendances_edit_until
      ? moment(mainDateStore.dateArray[0]).isSameOrBefore(
          locationSettings[locationFilter.selectedLocation.id].disable_location_attendances_edit_until,
        )
      : false;

  const isLocationShiftsEditDisabled =
    locationSettings[locationFilter.selectedLocation.id] &&
    locationSettings[locationFilter.selectedLocation.id].disable_location_schedule_shifts_edit_until
      ? moment(mainDateStore.dateArray[0]).isSameOrBefore(
          locationSettings[locationFilter.selectedLocation.id].disable_location_schedule_shifts_edit_until,
        )
      : false;

  const selectedShiftsWithoutAttendancesForSelectedEmployees = noApprovedAttendances.flatMap(({ employee }) =>
    shiftsWithoutAttendances.filter(shift => shift.employee.id === employee.id),
  );

  const editDisabled =
    !selectedEmployees.length ||
    disabled ||
    isLocationAttendancesEditDisabled ||
    (!relevantAttendances.startAttendances.length &&
      !relevantAttendances.endAttendances.length &&
      !noApprovedAttendances.length);

  const addDisabled =
    blockedAdding ||
    disabled ||
    isLocationAttendancesEditDisabled ||
    !shiftsWithoutAttendancesForSelectedEmployees.length;

  const confirmAttendancesDisabled =
    disabled ||
    isLocationAttendancesEditDisabled ||
    !unassignedAttendancesDataForSelectedEmployees.length ||
    isLocationShiftsEditDisabled ||
    restrictions.includes(SCHEDULE_EDIT_DISABLE);

  const acceptOvertimeDisabled =
    disabled ||
    isLocationAttendancesEditDisabled ||
    !selectedEmployees.length ||
    !selectedEmployeesAttendanceOvertimeData.length;

  return (
    <ButtonBar className="k-attendanceButtonBar">
      <ActionsButton icon={<Icon name="more_vert" />}>
        <button className="k-actionsButton__element" onClick={toggleExportModal} disabled={!exportEmployeesData.length}>
          <FormattedMessage id="export" defaultMessage="Eksport" />
        </button>
      </ActionsButton>
      <AttendancesSettingsButton />

      <SearchBox searchString={searchString} changeSearchString={setAttendanceSearchString} />

      <MDTooltip text={intl.formatMessage(messages.refresh)} withoutIcon>
        <Button onClick={refreshData} modifiers="reverse-blue onlyIcon">
          <i className="material-icons">sync</i>
        </Button>
      </MDTooltip>

      <MDTooltip text={intl.formatMessage(hasAllItemsSelected ? messages.unselectAll : messages.selectAll)} withoutIcon>
        <Button modifiers="reverse-blue onlyIcon" onClick={handleToggleAll}>
          <i className="material-icons">content_paste</i>
        </Button>
      </MDTooltip>

      <MDTooltip text={intl.formatMessage(messages.edit)} withoutIcon>
        <Button
          modifiers="reverse-blue onlyIcon"
          onClick={() => {
            showModal(ALIGN_ATTENDANCES_MODAL, {
              relevantAttendances,
              date: currentDate,
              selectedShiftsWithoutAttendancesForSelectedEmployees,
            });
          }}
          disabled={editDisabled}
        >
          <i className="material-icons">edit</i>
        </Button>
      </MDTooltip>
      <MDTooltip text={intl.formatMessage(messages.add)} withoutIcon>
        <Button
          modifiers="reverse-blue onlyIcon"
          onClick={() => onAdd(shiftsWithoutAttendancesForSelectedEmployees)}
          disabled={addDisabled}
        >
          <i className="material-icons">add</i>
        </Button>
      </MDTooltip>

      <MDTooltip text={intl.formatMessage(messages.confirmAttendances)} withoutIcon>
        <Button
          modifiers="reverse-blue onlyIcon"
          onClick={() => onApprove(unassignedAttendancesDataForSelectedEmployees)}
          disabled={confirmAttendancesDisabled}
        >
          <i className="material-icons">check</i>
        </Button>
      </MDTooltip>

      <MDTooltip text={intl.formatMessage(messages.acceptOvertime)} withoutIcon>
        <Button
          modifiers="reverse-blue onlyIcon"
          onClick={() =>
            showModal(ACCEPT_OVERTIME_MODAL, {
              selectedEmployeesAttendanceOvertimeData,
            })
          }
          disabled={acceptOvertimeDisabled}
        >
          <i className="material-icons">more_time</i>
        </Button>
      </MDTooltip>
      <div className="k-attendanceButtonBar__addTooltipAttendance">
        <MDTooltip withoutIcon>
          <Button modifiers="orange small" onClick={() => showModal(ADD_ATTENDANCE_MODAL)}>
            <i className="material-icons">add</i>
            <span> {intl.formatMessage(messages.createAttendance)}</span>
          </Button>
        </MDTooltip>
      </div>
    </ButtonBar>
  );
};

AttendanceButtonBar.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendanceButtonBar.propTypes = {
  showModal: PropTypes.func,
  toggleExportModal: PropTypes.func,
  createAttendance: PropTypes.func,
  approveAttendances: PropTypes.func,
  shiftsWithoutAttendances: PropTypes.arrayOf(PropTypes.shape({})),
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  unassignedAttendances: PropTypes.arrayOf(PropTypes.shape({})),
  selectedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      locationId: PropTypes.string,
    }),
  ),
  exportEmployeesData: PropTypes.arrayOf(PropTypes.shape({})),
  relevantAttendances: PropTypes.shape({
    startAttendances: PropTypes.arrayOf(PropTypes.shape({})),
    endAttendances: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentDate: PropTypes.string,
  attendanceDataForEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  locationSettings: PropTypes.shape({}),
  locationFilter: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  searchString: PropTypes.string,
  setAttendanceSearchString: PropTypes.string,
  refreshData: PropTypes.func,
  noApprovedAttendances: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  createMassAttendances: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  selectAllAttendances: PropTypes.func,
  unselectAllAttendances: PropTypes.func,
  contracts: PropTypes.shape({}),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  locationData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceButtonBar;
