import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { SINGLE_DAY_COL_SPAN } from '@/constants/scheduleStyleChangePoints';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements.js';
import { getSizeOfDates, getTradeShiftsForDate } from '@/utils/shiftHelpers';
import { getEmployeeName } from '@/utils/userEmployeesHelpers.js';

import TradeShiftShift from '../../TradeShiftShift/TradeShiftShift.jsx';
import TradeShiftRowTitle from '../TradeShiftRowTitle/TradeShiftRowTitle.redux';

import '../ScheduleTableTradeShift.scss';

const ScheduleTableSingleDayTradeShift = ({
  mainDateStore,
  currentUser,
  userEmployees,
  tradeShifts,
  userPermissions,
  openCoworkerTradeShiftModal,
  openApprovalTradeShiftModal,
  locationId,
  rowSticky,
}) => {
  const tradeShiftRef = useRef(null);
  const [top] = useMultipleStickyElements(tradeShiftRef, 'scheduleTableHeader--sticky');
  const { dateArray, dateMode } = mainDateStore;
  const tradeShiftsWithEmployeeData = tradeShifts.map(tradeShift => ({
    ...tradeShift,
    user: userEmployees.find(u => u.id === tradeShift.user.id) || tradeShift.user,
  }));

  const handleAction = shift => {
    if (userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE)) return;
    if (shift.user.id !== currentUser.user.id && userPermissions.isEmployee) {
      openCoworkerTradeShiftModal(shift);
    } else {
      openApprovalTradeShiftModal(shift);
    }
  };

  const size = getSizeOfDates(dateArray.length);

  const className = cn('scheduleTable__tradeShift', 'scheduleTableHeader', {
    'scheduleTable__tradeShift--unlocked': !rowSticky,
    'scheduleTableHeader--sticky': rowSticky,
  });

  return (
    <tr
      ref={tradeShiftRef}
      className={className}
      style={{
        top,
      }}
    >
      <TradeShiftRowTitle locationId={locationId} />
      <td colSpan={SINGLE_DAY_COL_SPAN}>
        {getTradeShiftsForDate(dateArray[0] || [], tradeShiftsWithEmployeeData, userEmployees).map(shift => (
          <TradeShiftShift
            key={shift.id}
            shift={shift}
            size={size}
            date={dateArray[0]}
            userEmployees={userEmployees}
            title={getEmployeeName(shift.user, dateMode)}
            currentUserId={currentUser.user.id}
            handleAction={() => handleAction(shift)}
            isEmployee={userPermissions.isEmployee}
          />
        ))}
      </td>
    </tr>
  );
};

ScheduleTableSingleDayTradeShift.contextTypes = {
  intl: PropTypes.shape({}),
};

ScheduleTableSingleDayTradeShift.propTypes = {
  tradeShiftRef: PropTypes.shape({}),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    today: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  tradeShifts: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  openCoworkerTradeShiftModal: PropTypes.func,
  openApprovalTradeShiftModal: PropTypes.func,
  locationId: PropTypes.string,
  rowSticky: PropTypes.bool,
};

export default ScheduleTableSingleDayTradeShift;
