import { PERMISSIONS } from 'kadro-helpers/lib/helpers';
import { deburr, isEmpty, sortBy } from 'lodash';
import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { sortLocationsByLocationIds } from '@/actions/filters';
import { AlignAttendancesSingleDayModal } from '@/components/attendance/modals/AlignAttendancesSingleDayModal';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import {
  ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE,
  ATTENDANCE_EDIT_OTHERS_DISABLE,
  MANAGER_EDIT_OWN_ATTENDANCES,
} from '@/constants/Restrictions.js';
import AddAttendanceModalContainer from '@/containers/attendance/AddAttendanceModalContainer.js';
import EditAttendanceModalContainer from '@/containers/attendance/EditAttendanceModalContainer.js';
import ExportModal from '@/containers/attendance/ExportAttendanceModalContainer.js';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';
import {
  generateDataForAttendances,
  getFirstAndLastAttendancesForEmployees,
  groupSelectedAttendances,
  isAttendanceEditDisabled,
} from '@/utils/attendanceHelpers';
import {
  getJobTitleIdsFromContracts,
  getRelevantContractForDate,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import { filterAttendances, parseLoanedEmployees } from '@/utils/loanedAttendances.ts';
import { getLocationIdsFromEmployeesWithCommonLocations } from '@/utils/locationsHelpers';
import { getStylesForAttendanceDisabledEditOverlay } from '@/utils/schedulerHelpers.js';
import { checkEmployeeIsManager, getEmployeeRank } from '@/utils/userEmployeesHelpers';

import AttendanceButtonBar from './AttandanceButtonBar/AttendanceButtonBar.redux.js';
import AttendanceTable from './AttendancesTable/AttendanceTable.redux.js';
import AttendanceTableRow from './AttendanceTableRow.jsx';
import { calculateTotalBreaks, calculateTotalMinutes, filterAttendanceData } from './AttendanceTableView.helpers.js';
import AcceptOvertimeModal from './modals/AcceptOvertimeModal/AcceptOvertimeModal.redux.js';
import HelpModal from './modals/AttendanceHelpModal.jsx';
import CreateAttendanceModal from './modals/CreateAttendanceModal/CreateAttendanceModal.redux.js';

import './attendanceTableView.scss';

const messages = defineMessages({
  emptyStateFilterTitle: {
    id: 'companyManage.attendanceTableView.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateSearchStringMsg: {
    id: 'companyManage.attendanceTableView.emptyStateSearchStringMsg',
    defaultMessage: 'Wybierz odpowiednią lokalizację, utwórz grafik pracy lub ',
  },
  ctaSearchStringText: {
    id: 'companyManage.attendanceTableView.ctaSearchStringText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
  emptyFiltersMsg: {
    id: 'companyManage.attendanceTableView.emptyFiltersMsg',
    defaultMessage: 'Sprawdź czy zaznaczone są lokalizacje, stanowiska i warunki zatrudnienia lub ',
  },
  ctaEmptyFiltersText: {
    id: 'companyManage.attendanceTableView.ctaEmptyFiltersText',
    defaultMessage: 'kliknij tutaj, aby zaznaczyć wszystkie.',
  },
});

class AttendanceTableView extends Component {
  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }

  getEmptyStateView(paddingRight) {
    const { searchString } = this.props;
    const headerText = this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = searchString
      ? this.context.intl.formatMessage(messages.emptyStateSearchStringMsg)
      : this.context.intl.formatMessage(messages.emptyFiltersMsg);
    const ctaText = searchString
      ? this.context.intl.formatMessage(messages.ctaSearchStringText)
      : this.context.intl.formatMessage(messages.ctaEmptyFiltersText);

    return (
      <tr>
        <td className="no-content-to-show" colSpan="5">
          <EmptyState
            style={{ paddingRight: paddingRight || '16vw' }}
            name="attendanceCTA"
            imgSrc="/img/attendanceViewCTA.png"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={() => {
              if (searchString) {
                this.props.setAttendanceSearchString('');
              } else {
                this.props.selectAllJobTitlesAndEmploymentConditions();
                this.props.selectFirstLocation();
                this.props.sellectAllAttendancesSettings();
              }
            }}
            ctaText={ctaText}
          />
        </td>
      </tr>
    );
  }

  filterAndSort(relevantEmployees) {
    const {
      contracts,
      userJobTitles,
      mainDateStore: {
        customDate: { start: from, end: to },
      },
    } = this.props;
    const phrase = this.props.searchString.toLowerCase();
    if (!phrase) return relevantEmployees;

    return relevantEmployees.reduce((prev, employee) => {
      const compareName = (employee.first_name + employee.last_name).toLowerCase();
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
      const employeeJobTitles = userJobTitles.filter(jobTitle => employeeJobTitleIds.includes(jobTitle.id));
      const compareJobTitles = employeeJobTitles.reduce(
        (previous, jobTitle) => previous + jobTitle.title.toLowerCase(),
        '',
      );
      const compareWorkingHours = employee?.shifts.reduce((previous, shift) => previous + shift.working_hours, '');
      if ((compareName + compareJobTitles + compareWorkingHours).includes(phrase)) {
        return prev.concat(employee);
      }

      return prev;
    }, []);
  }

  render() {
    const {
      mainDateStore,
      selectedAttendances,
      scheduleLocationFilter,
      locationSettings,
      absences,
      userLocations,
      currentUser,
      companyRoles,
      userEmployees,
      deviceInfo,
      jobtitleFilter,
      employmentConditionsFilter,
      employeeSorting,
      userPermissions,
      closeAddAttendanceModal,
      attendancesSettings,
      overtimeCollections,
      contracts,
      userJobTitles,
      attendancesData,
      employeesNames,
      scheduleLoanedEmployees,
    } = this.props;
    const [currentDate] = mainDateStore.dateArray;
    const currentUserRank = getEmployeeRank(currentUser.user, companyRoles);
    const isManager = checkEmployeeIsManager(currentUser.user, companyRoles);
    const hasManagerAccessToOtherLocations =
      userPermissions.permissions.includes(PERMISSIONS.ATTENDANCES_FROM_OTHER_LOCATIONS_GET) && isManager;
    const attendanceDataForEmployees = [];

    const unassignedAttendances = [];
    const shiftsWithoutAttendances = [];
    const locations = hasManagerAccessToOtherLocations
      ? getLocationIdsFromEmployeesWithCommonLocations(userEmployees, userLocations)
      : userLocations.filter(loc => scheduleLocationFilter.includes(loc.id));
    const sortedLocations = sortLocationsByLocationIds(locations, scheduleLocationFilter);
    const userLocationIds = userLocations.map(userLocation => userLocation.id);
    let allRelevantEmployees = [];
    const locationData = [];
    sortedLocations.forEach(location => {
      let viewToRender;
      let relevantEmployees;
      const regularEmployees = userEmployees
        .filter(employee => {
          const employeeContracts = contracts[employee.id] || [];
          const relevantContracts = getRelevantContractForDate(employeeContracts, currentDate);
          const hasJobTitle = relevantContracts?.job_titles.some(contractJobTitle =>
            jobtitleFilter.selectedJobtitles.map(j => j.id).includes(contractJobTitle.job_title_id),
          );

          return (
            !employee.inactive &&
            employee.locations.filter(l => l.id === location.id).length > 0 &&
            (!hasManagerAccessToOtherLocations || employee.locations.some(l => userLocationIds.includes(l.id))) &&
            hasJobTitle &&
            employmentConditionsFilter.selected.some(
              condition => condition.id === employee.employment_conditions.template_id,
            ) &&
            // We make sure that the shifts are from the same location as
            // selected. or that the attendances are from the same location
            (employee.shifts.some(s => s.date === currentDate && s.location.id === location.id) ||
              attendancesData[employee.id]?.some(a => a.date === currentDate && a.location.id === location.id) ||
              employee.availability_blocks.some(
                availability => availability.date === currentDate && availability.count_hours_in_payroll,
              ) ||
              absences[employee.id]?.some(
                absence => currentDate >= absence.from && currentDate <= absence.to && absence.status === 'accepted',
              ) ||
              overtimeCollections[employee.id]?.some(overtimeCollection => currentDate === overtimeCollection.date))
          );
        })
        .map(employee => {
          const relevantShifts = employee.shifts.filter(s => s.date === currentDate && s.location.id === location.id);
          const relevantAttendances =
            attendancesData[employee.id]?.filter(a => a.date === currentDate && a.location.id === location.id) || [];
          const relevantAbsence = (absences[employee.id] || []).find(
            absence => currentDate <= absence.to && currentDate >= absence.from && absence.status === 'accepted',
          );
          const relevantOvertimeCollections = (overtimeCollections[employee.id] || []).filter(
            overtimeCollection => currentDate === overtimeCollection.date,
          );
          const employeeContracts = contracts[employee.id] || [];
          const relevantContract = getRelevantContractForDate(employeeContracts, currentDate);
          const jobTitleIds = relevantContract ? getJobTitleIdsFromContracts([relevantContract]) : [];
          const employeeJobTitles = userJobTitles.filter(jobTitle => jobTitleIds.includes(jobTitle.id));

          return {
            ...employee,
            shifts: relevantShifts,
            attendances: relevantAttendances,
            absence: relevantAbsence,
            overtimeCollections: relevantOvertimeCollections,
            jobTitles: employeeJobTitles,
          };
        });

      const selectedLocationIds = locations.map(({ id }) => id);
      const filteredAttendances = filterAttendances(attendancesData, selectedLocationIds, userEmployees, currentDate);
      const loanedEmployees = parseLoanedEmployees(
        filteredAttendances,
        employeesNames,
        scheduleLoanedEmployees,
        currentDate,
        [location.id],
      );

      relevantEmployees = [...regularEmployees, ...loanedEmployees];

      if (employeeSorting.currentSorting.sortFunc != null) {
        // Quick implementation to sort by relevant jobtitle count
        if (employeeSorting.currentSorting.type === 'jobtitle_count') {
          relevantEmployees
            .map(e => {
              e.currentWeekArray = mainDateStore.dateArray;
              return e;
            })
            .sort(employeeSorting.currentSorting.sortFunc);
        } else {
          relevantEmployees.sort(employeeSorting.currentSorting.sortFunc);
        }
      }
      if (employeeSorting.currentSorting.type === 'custom') {
        const employeesOrderInLocation = employeeSorting.employeeOrders[location.id] || {};
        relevantEmployees.sort((a, b) => employeesOrderInLocation[a.id] - employeesOrderInLocation[b.id]);
      }
      relevantEmployees = this.filterAndSort(relevantEmployees);
      const employeesToExport = relevantEmployees.filter(
        employee => employee.shifts?.length > 0 || employee.attendances.length > 0,
      );

      if (relevantEmployees.length > 0) {
        let attendanceData = generateDataForAttendances(relevantEmployees, currentDate);
        attendanceData.forEach((data, i) => {
          data.blocks.forEach(block => {
            if (!block.type.name && !block.combinedBlockStart && !block.combinedBlockEnd) {
              shiftsWithoutAttendances.push({
                detail: block,
                employee: relevantEmployees[i],
                locationId: location.id,
              });
            }
          });
          data.detailBlocks.forEach(block => {
            if (
              block.detailType === 'attendance_without_shift' &&
              block.sourceAttendance &&
              block.sourceAttendance.id
            ) {
              unassignedAttendances.push({
                detail: block,
                employee: relevantEmployees[i],
              });
            }
            if (block.detailType === 'absence') {
              shiftsWithoutAttendances.push({
                detail: block,
                employee: relevantEmployees[i],
                locationId: location.id,
              });
            }
          });
        });
        relevantEmployees.forEach((employee, i) =>
          attendanceDataForEmployees.push({
            employee,
            location,
            attendanceData: attendanceData[i],
          }),
        );

        const { filteredEmployees, filteredAttendanceData } = filterAttendanceData(
          relevantEmployees,
          attendanceData,
          attendancesSettings,
        );

        relevantEmployees = filteredEmployees;
        attendanceData = filteredAttendanceData;

        viewToRender = relevantEmployees.map((employee, i) => {
          const disabled = isAttendanceEditDisabled(userPermissions, currentUser, employee, companyRoles);
          return (
            <AttendanceTableRow
              key={`${employee.id}att`}
              employee={employee}
              location={location}
              date={currentDate}
              today={mainDateStore.today}
              relevantShifts={employee?.shifts}
              relevantAttendances={employee.attendances}
              openAddAttendanceModal={this.props.openAddAttendanceModal}
              closeAddAttendanceModal={closeAddAttendanceModal}
              showModal={this.props.showModal}
              attendanceData={attendanceData[i]}
              disabled={disabled}
              restrictions={userPermissions.restrictions}
            />
          );
        });
      } else {
        viewToRender = this.getEmptyStateView();
      }

      const totalMinutes = calculateTotalMinutes(relevantEmployees);

      const totalBreaks = calculateTotalBreaks(relevantEmployees);

      const summary = [
        <tr key="summaryRow" className="summaryrow">
          <td className="sum_blank" colSpan="3" />
          <td>
            <FormattedMessage id="attendance.tableSummary.sumBreaks" defaultMessage="Suma przerw" />
          </td>
          <td>
            <FormattedMessage id="attendance.tableSummary.sumHours" defaultMessage="Suma godzin" />
          </td>
        </tr>,
        <tr key="summaryRowBottom">
          <td className="sum_blank" colSpan="3" />
          <td className="imptnt">{parseMinutesToHumanForm(totalBreaks)}</td>
          <td className="imptnt">{parseMinutesToHumanForm(totalMinutes)}</td>
        </tr>,
      ];
      allRelevantEmployees = [...allRelevantEmployees, relevantEmployees].flat();
      locationData.push({
        viewToRender,
        summary,
        relevantEmployees,
        employeesToExport,
        locationId: location.id,
      });
    });
    const relevantAttendances = getFirstAndLastAttendancesForEmployees(
      allRelevantEmployees.filter(employee =>
        selectedAttendances.some(selected => selected.employeeId === employee.id),
      ),
      currentDate,
      selectedAttendances,
    );

    const relevantSelectedAttendances = groupSelectedAttendances(relevantAttendances, selectedAttendances);

    const exportEmployeesData = sortBy(
      locationData.reduce((prev, data) => prev.concat(data.employeesToExport), []),
      employee => deburr(`${employee.first_name} ${employee.last_name}`.toLowerCase()),
    );

    const isEditOwnDisabled =
      userPermissions.restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) &&
      selectedAttendances.some(selected => selected.employeeId === currentUser.user.id);
    const isEditOthersDisabled =
      selectedAttendances.some(selected => selected.employeeId !== currentUser.user.id) &&
      (userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) ||
        (userPermissions.restrictions.includes(ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE) &&
          selectedAttendances.some(({ employeeId }) => {
            const employee = userEmployees.find(({ id }) => String(employeeId) === String(id));

            return getEmployeeRank(employee, companyRoles) >= currentUserRank;
          })));

    const isButtonBarDisabled = isEditOwnDisabled || isEditOthersDisabled;
    const noApprovedAttendances = [];
    selectedAttendances.forEach(attendance => {
      const attendanceData = attendanceDataForEmployees.filter(
        att => att.employee.id === attendance.employeeId && isEmpty(att.employee.attendances),
      );
      if (!isEmpty(attendanceData)) {
        noApprovedAttendances.push(...attendanceData);
      }
    });

    const allLocationsAreEmpty = locationData.every(data => isEmpty(data.relevantEmployees));

    return (
      <>
        <AddAttendanceModalContainer />
        <EditAttendanceModalContainer />
        <AddShiftModalContainer />
        <ObjectHistoryModalContainer />
        <AlignAttendancesSingleDayModal />
        <AcceptOvertimeModal />
        <HelpModal />
        <CreateAttendanceModal showEmployeePicker />

        <ExportModal
          exportRawData={() => ({
            relevantEmployees: exportEmployeesData,
            type: 'attendance',
          })}
        />
        <div className="k-wrapper k-wrapper--searchBar animated fadeInRight">
          <AttendanceButtonBar
            attendanceDataForEmployees={attendanceDataForEmployees}
            locationData={locationData}
            shiftsWithoutAttendances={shiftsWithoutAttendances}
            unassignedAttendances={unassignedAttendances}
            relevantEmployees={locationData.reduce((prev, data) => prev.concat(data.relevantEmployees), [])}
            relevantAttendances={relevantSelectedAttendances}
            currentDate={currentDate}
            exportEmployeesData={exportEmployeesData}
            disabled={isButtonBarDisabled}
            noApprovedAttendances={noApprovedAttendances}
          />
          {locationData.map((data, i) => {
            const { locationId, relevantEmployees } = data;
            let { viewToRender } = data;
            const relevantLocationSettings = locationSettings[locationId] || {};
            const disabledEditOverlayStyle = getStylesForAttendanceDisabledEditOverlay(
              relevantLocationSettings.disable_location_attendances_edit_until,
              mainDateStore.dateArray,
            );

            if (!relevantEmployees.length) {
              viewToRender = this.getEmptyStateView();
            }
            if (!hasManagerAccessToOtherLocations || (hasManagerAccessToOtherLocations && viewToRender.length))
              return (
                <AttendanceTable
                  key={locationId}
                  data={data}
                  currentDate={currentDate}
                  location={locations[i]}
                  showLocation={locations.length > 1}
                  height={deviceInfo.windowSize.height}
                  disabledEditOverlayStyle={disabledEditOverlayStyle}
                >
                  {viewToRender}
                </AttendanceTable>
              );
          })}
          {(allLocationsAreEmpty && hasManagerAccessToOtherLocations) || !locationData.length ? (
            <div className="k-attendanceTableView__emptyState">{this.getEmptyStateView('0')}</div>
          ) : null}
        </div>
      </>
    );
  }
}

AttendanceTableView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendanceTableView.propTypes = {
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
    isEmployee: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employeeSorting: PropTypes.shape({
    currentSorting: PropTypes.shape({
      sortFunc: PropTypes.func,
      type: PropTypes.string,
    }),
    employeeOrders: PropTypes.shape({}),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  locationSettings: PropTypes.shape({}),
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  listsUi: PropTypes.shape({
    attendance: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  closeAddAttendanceModal: PropTypes.func,
  searchString: PropTypes.string,
  setAttendanceSearchString: PropTypes.func,
  selectAllJobTitlesAndEmploymentConditions: PropTypes.func,
  absences: PropTypes.shape({}),
  selectedAttendances: PropTypes.arrayOf(
    PropTypes.shape({
      locationId: PropTypes.string,
      employeeId: PropTypes.string,
    }),
  ),
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  attendancesSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectFirstLocation: PropTypes.func,
  sellectAllAttendancesSettings: PropTypes.func,
  showModal: PropTypes.func,
  openAddAttendanceModal: PropTypes.func,
};
export default AttendanceTableView;
