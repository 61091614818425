import AbsenceHistoryModal from '@/components/absences/modals/AbsenceHistoryModal/AbsenceHistoryModal.redux';
import AbsenceProposalModal from '@/components/absences/modals/AbsenceProposalModal/AbsenceProposalModal.redux';
import TopBarTimesheet from '@/components/TopBars/TopBarTimesheet/TopBarTimesheet';

import EmployeeShiftModal from '../EmployeeShiftModal/EmployeeShiftModal.redux';
import EmployeeTimesheetTable from '../EmployeeTimesheetTable/EmployeeTimesheetTable.redux.js';

const EmployeeTimesheetView = () => (
  <>
    <TopBarTimesheet />
    <EmployeeShiftModal />
    <AbsenceProposalModal />
    <AbsenceHistoryModal />
    <EmployeeTimesheetTable />
  </>
);

export default EmployeeTimesheetView;
