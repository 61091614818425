import { toastr } from 'react-redux-toastr';

import { RcpEmitType } from '@/types/sockets';

import { conn } from './index';

export const refreshDeviceData = (deviceUuid: string) => async () => {
  try {
    await conn.emitRcpSockets([deviceUuid], RcpEmitType.REFRESH_DATA);
    toastr.success('Sukces!', 'Odświeżono dane na urządzeniu');
  } catch (error) {
    toastr.error('Błąd!', 'Urządzenie jest offline');
  }
};
