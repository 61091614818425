import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

class ImportOpenShiftModal extends Component {
  constructor(props) {
    super(props);
    let selectedTemplateId = null;
    if (props.userTemplates.templatesArray.length) selectedTemplateId = props.userTemplates.currentTemplate.id;
    this.state = { selectedTemplateId };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { show, userTemplates } = this.props;
    if (show && show !== prevProps.show) {
      this.setState({
        selectedTemplateId: userTemplates.currentTemplate.id,
      });
    }
  }

  setSelectedTemplateId(value) {
    this.setState({
      selectedTemplateId: value,
    });
  }

  addOpenShifts() {
    const { locationId, dateStore } = this.props;
    const template = this.props.userTemplates.templatesArray.find(tmp => tmp.id === this.state.selectedTemplateId);

    dateStore.dateArray.forEach(date => {
      const relevantShifts = template.shifts.filter(shift => shift.date === moment(date).day());
      relevantShifts.forEach(shift => {
        this.props.createOpenShift({
          ...shift,
          shifts_required: shift.amount,
          location: { id: locationId },
          job_title: shift.job_title ? shift.job_title : { id: shift.job_title_id },
          date,
          draft: true,
        });
      });
    });
    this.props.toggleImportOpenShiftsModal();
  }

  render() {
    const options = this.props.userTemplates.templatesArray.reduce(
      (templates, { name: key, id: value, type }) => (type === 'standard' ? [...templates, { key, value }] : templates),
      [],
    );

    return (
      <MDKadroModal
        show={this.props.show !== null}
        onHide={this.props.toggleImportOpenShiftsModal}
        onSubmit={this.addOpenShifts}
        title={<FormattedMessage id="openShiftModal.addOpenShifts" defaultMessage="Dodaj otwarte zmiany" />}
        modifiers={['narrow']}
        withoutOverlayScroll
      >
        <MDSelect
          name={<FormattedMessage id="schedule.autoGenerateModal.select" defaultMessage="Wybierz szablon" />}
          options={options}
          onChange={this.setSelectedTemplateId}
          defaultValue={this.state.selectedTemplateId}
          withSearch
          closeOnClick
          isSorted
        />
      </MDKadroModal>
    );
  }
}
ImportOpenShiftModal.propTypes = {
  userTemplates: PropTypes.shape({
    templatesArray: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  locationId: PropTypes.string,
  dateStore: PropTypes.shape({ dateArray: PropTypes.arrayOf(PropTypes.string) }),
  createOpenShift: PropTypes.func,
  toggleImportOpenShiftsModal: PropTypes.func,
  show: PropTypes.string,
};
export default ImportOpenShiftModal;
