import moment from 'moment';
import { defineMessages } from 'react-intl';

import {
  convertDateToStandardFormat,
  getScheduleCycleForDateAndEmployee,
  hoursToMinutes,
  isHourFulfilled,
  timestampsToWorkingHours,
  workingHoursToTimestamp,
} from './dateHelper.js';

const messages = defineMessages({
  overtimeCollection: {
    id: 'absences.overtimeCollection',
    defaultMessage: 'Odbiór nadgodzin',
  },
});

export const compareScheduleCycles = (a, b) => {
  if (parseInt(a.duration) !== parseInt(b.duration)) return false;
  const aMonthstamp = (a.year - 2000) * 12 + a.month;
  const bMonthstamp = (b.year - 2000) * 12 + b.month;
  const diff = Math.abs(aMonthstamp - bMonthstamp);
  return diff % a.duration === 0;
};

export const calculateScheduleCycleDates = (scheduleCycle, date) => {
  const dateObject = moment(date);
  const month = dateObject.month() + 1;
  const year = dateObject.year();

  let diff = (year - 2000) * 12 + month - ((scheduleCycle.year - 2000) * 12 + scheduleCycle.month);
  diff %= scheduleCycle.duration;

  let before = 0;
  let after = 0;
  if (diff >= 0) {
    before = diff;
    after = scheduleCycle.duration - diff - 1;
  } else {
    before = scheduleCycle.duration + diff;
    after = -1 * diff - 1;
  }

  const startOfMonth = moment(dateObject).startOf('month');
  const from = moment(startOfMonth).subtract(before, 'months');
  const to = moment(startOfMonth)
    .add(after, 'months')
    .endOf('month');
  return { from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD') };
};

export const divideEmployeesIntoScheduleCycleGroups = employees => {
  const scheduleCycles = [];
  employees.forEach(e => {
    const existingScheduleCycle = scheduleCycles.find(s =>
      compareScheduleCycles(e.employment_conditions.schedule_cycle, s),
    );
    if (existingScheduleCycle) {
      existingScheduleCycle.employeesIds.push(e.id);
    } else {
      scheduleCycles.push({ ...e.employment_conditions.schedule_cycle, employeesIds: [e.id] });
    }
  });
  return scheduleCycles;
};

export const addDatesToScheduleCycleGroups = (scheduleCycles, date) =>
  scheduleCycles.map(s => ({ ...s, ...calculateScheduleCycleDates(s, date) }));

export const createOvertimeScheduleCycleSpanLabel = (from, to) => {
  const fromMonth = moment(from).format('MMM');
  const toMonth = moment(to).format('MMM');
  let scheduleCycleSpan = '';
  if (fromMonth !== toMonth) {
    scheduleCycleSpan = `${moment(from).format('DD.MM')}-${moment(to).format('DD.MM')}`;
  } else {
    scheduleCycleSpan = `1-${moment(from)
      .endOf('month')
      .format('DD.MM')}`;
  }
  return scheduleCycleSpan;
};

export const createOvertimeCollectionsBlocks = overtimeCollections =>
  overtimeCollections.map(overtime => {
    const hours = timestampsToWorkingHours(overtime.start_timestamp, overtime.end_timestamp);
    const date = convertDateToStandardFormat(overtime.start_timestamp);
    return { date, hours };
  });

export const getBasicOvertimeCollectionObject = intl => ({
  id: 'overtimeCollection',
  defined_in_hours: true,
  name: intl.formatMessage(messages.overtimeCollection),
  overtimeCollection: true,
  has_note: true,
});

export const createOvertimeCollectionObject = (
  { employee, absence = {} },
  absenceHours,
  absenceComment,
  selectedDay,
  amount50,
  amount100,
  amountPotential,
  multiplier,
  overtimeCycle,
) => {
  const date = convertDateToStandardFormat(selectedDay);
  const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = workingHoursToTimestamp(absenceHours, date);
  const scheduleCycle = getScheduleCycleForDateAndEmployee(date, employee.employment_conditions.schedule_cycle);
  const overtimeCollection = {
    schedule_cycle: scheduleCycle,
    comment: absenceComment,
    employee_id: employee.id,
    start_timestamp: moment(startTimestamp).format('YYYY-MM-DD HH:mm'),
    end_timestamp: moment(endTimestamp).format('YYYY-MM-DD HH:mm'),
    multiplier: Number(multiplier),
    amount50: isHourFulfilled(amount50) ? hoursToMinutes(amount50) : 0,
    amount100: isHourFulfilled(amount100) ? hoursToMinutes(amount100) : 0,
    amount_potential: isHourFulfilled(amountPotential) ? hoursToMinutes(amountPotential) : 0,
    month: overtimeCycle.month,
    year: overtimeCycle.year,
  };
  if (absence.id) overtimeCollection.id = absence.id;
  return overtimeCollection;
};

export const createOldOvertimeCollectionObject = (
  { employee, absence = {} },
  absenceHours,
  absenceComment,
  selectedDay,
) => {
  const date = convertDateToStandardFormat(selectedDay);
  const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = workingHoursToTimestamp(absenceHours, date);
  const scheduleCycle = getScheduleCycleForDateAndEmployee(date, employee.employment_conditions.schedule_cycle);
  const overtimeCollection = {
    schedule_cycle: scheduleCycle,
    comment: absenceComment,
    employee_id: employee.id,
    start_timestamp: moment(startTimestamp).format('YYYY-MM-DD HH:mm'),
    end_timestamp: moment(endTimestamp).format('YYYY-MM-DD HH:mm'),
  };
  if (absence.id) overtimeCollection.id = absence.id;
  return overtimeCollection;
};
