import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@/components/common/Tooltip/Tooltip';

import './LockButton.scss';

const LockButton = ({ onClick, locked }) => (
  <Tooltip
    content={
      <FormattedMessage
        id="scheduleTable.tradeAndOpenShift.onHover"
        defaultMessage="Podczas przewijania pozostałej części tabeli zachowaj w polu widzenia ten wiersz"
      />
    }
  >
    <span
      className={`${locked ? 'material-icons' : 'material-icons-outlined'} k-lockButton`}
      onClick={onClick}
      role="presentation"
    >
      push_pin
    </span>
  </Tooltip>
);

LockButton.propTypes = {
  locked: PropTypes.bool,
  onClick: PropTypes.func,
};
export default LockButton;
