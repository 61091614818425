import * as AT from '@/constants/ActionTypes.js';
import {
  deleteAvailabilitiesFromScheduleData,
  extractAvailabilityBlockDataFromScheduleData,
  updateAvaCornerState,
  updateAvailabilityTypeDataForCorners,
} from '@/utils/schedule/availabilityCornerHelpers';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_SCHEDULE_SUCCESFUL:
      return extractAvailabilityBlockDataFromScheduleData(action.payload);
    case AT.CHANGE_AVAILABILITY_SUCCESFUL:
    case AT.ADD_AVAILABILITY_SUCCESFUL:
      return updateAvaCornerState(state, action.payload);
    case AT.DELETE_AVAILABILITY_SUCCESFUL: {
      const { employee_id: employeeId, availabilityObject } = action.payload;
      return { ...state, [employeeId]: { ...state[employeeId], [availabilityObject.date]: undefined } };
    }
    case AT.UPDATE_AVA_TYPE:
      return updateAvailabilityTypeDataForCorners(state, action.payload);
    case AT.DELETE_AVAILABILITIES_SUCCESFUL: {
      return deleteAvailabilitiesFromScheduleData(state, action.payload);
    }
    default:
      return state;
  }
};

export default reducer;
