import { deburr,sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';
import { getGroupedUserTemplates } from '@/utils/schedulerHelpers.js';

class TopBarTemplatesFilter extends Component {
  constructor(props) {
    super(props);

    this.changeFunc = this.changeFunc.bind(this);
  }

  changeFunc(template) {
    if (template.id !== this.props.userTemplates.currentTemplate.id) {
      this.props.changeCurrentTemplate(template);
    }
  }

  render() {
    if (this.props.scheduleUIState.selectedDisplayMode.type !== 'templates') return null;
    const sortedTemplates = sortBy([...this.props.userTemplates.templatesArray], template =>
      deburr(template.name.toLowerCase()),
    );

    const groupedTemplates = getGroupedUserTemplates(sortedTemplates);

    return (
      <div className="k-topBarTemplatesFilter" style={{ float: 'right', margin: '5px 0' }}>
        <TopBarSingleChoice
          displayKey="name"
          hasGroups
          objectsToChoose={groupedTemplates}
          onChange={this.changeFunc}
          selected={this.props.userTemplates.currentTemplate}
        />
      </div>
    );
  }
}

TopBarTemplatesFilter.propTypes = {
  userTemplates: PropTypes.shape({
    templatesArray: PropTypes.arrayOf(PropTypes.shape({})),
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  changeCurrentTemplate: PropTypes.func,
};

export default TopBarTemplatesFilter;
