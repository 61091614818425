import classnames from 'classnames';
import PropTypes from 'prop-types';

import ScheduleTableWeatherPopover from '@/components/scheduler/ScheduleTable/ScheduleTableWeather/ScheduleTableWeatherPopover/ScheduleTableWeatherPopover';
import { isWeekend } from '@/utils/dateHelper.js';

import ScheduleTableWeatherPreview from '../ScheduleTableWeatherPreview/ScheduleTableWeatherPreview.redux.js';
import { useScheduleTableWeatherCell } from './useScheduleTableWeatherCell';

const ScheduleTableWeatherCell = ({
  date,
  locationCoordinatesExist,
  weatherForecast,
  selectedDisplayModeType,
  holidaysEnabled,
  dayType,
  city,
  locationId,
  canEditLocations,
}) => {
  const { colSpan } = useScheduleTableWeatherCell();
  const showHolidays = selectedDisplayModeType !== 'templates' && holidaysEnabled;
  const classNames = classnames('k-weatherRow__background', {
    'k-weatherRow__background--holiday': showHolidays && dayType === 'holiday',
    'k-weatherRow__background--freeDayHoliday': showHolidays && dayType === 'freeFromWorkHoliday',
    'k-weatherRow__background--weekend': isWeekend(date),
  });
  return (
    <td colSpan={colSpan} className="k-weatherRow__cell">
      <div className={classNames} />

      <ScheduleTableWeatherPopover
        {...{ locationCoordinatesExist, weatherForecast, city, locationId, canEditLocations }}
      >
        <ScheduleTableWeatherPreview {...{ locationCoordinatesExist, weatherForecast, city, date }} />
      </ScheduleTableWeatherPopover>
    </td>
  );
};

ScheduleTableWeatherCell.propTypes = {
  locationCoordinatesExist: PropTypes.bool,
  weatherForecast: PropTypes.shape({}),
  date: PropTypes.string,
  dayType: PropTypes.string,
  selectedDisplayModeType: PropTypes.string,
  holidaysEnabled: PropTypes.bool,
  city: PropTypes.string,
  locationId: PropTypes.string,
  canEditLocations: PropTypes.bool,
};
export default ScheduleTableWeatherCell;
