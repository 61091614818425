import * as AT from '@/constants/ActionTypes';
import * as EXPORT_STATES from '@/constants/exportStates';

const initialState = {
  data: [],
  currentPage: 1,
  numberOfPages: 3,
  numberOfItemsPerPage: 10,
  numberOfPendingItems: 0,
  numberOfUnseenItems: 0,
  showError: false,
  initialized: false,
  exportsHistoryError: false,
};

const exportsHistory = (state = initialState, action) => {
  switch (action.type) {
    case AT.EXPORTS_HISTORY_GET_SUCCESS:
      return {
        ...state,
        initialized: true,
        data: action.payload.exportHistory,
        numberOfPages: Math.ceil(action.payload.numberOfItems / state.numberOfItemsPerPage),
        exportsHistoryError: action.payload.exportHistory.length > 0 ? false : state.currentPage !== 1,
        numberOfPendingItems: action.payload.numberOfPendingItems,
        numberOfUnseenItems: action.payload.numberOfUnseenItems,
        showError: false,
      };
    case AT.EXPORTS_HISTORY_GET_ERROR:
      return {
        ...state,
        data: [],
        initialized: true,
        exportsHistoryError: true,
        numberOfPendingItems: 0,
        numberOfUnseenItems: 0,
      };
    case AT.EXPORTS_HISTORY_DELETE_SUCCESS: {
      const itemToDelete = state.data.find(({ id }) => id === action.payload);
      if (!itemToDelete) return state;
      const newData = state.data.filter(({ id }) => id !== action.payload);
      switch (itemToDelete.status) {
        case EXPORT_STATES.PENDING:
          return {
            ...state,
            data: newData,
            numberOfPendingItems: state.numberOfPendingItems - 1,
          };
        case EXPORT_STATES.UNSEEN:
          return {
            ...state,
            data: newData,
            numberOfUnseenItems: state.numberOfUnseenItems - 1,
          };
        default:
          return {
            ...state,
            data: newData,
          };
      }
    }
    case AT.CHANGE_EXPORTS_HISTORY_PAGINATION:
      return { ...state, ...action.payload };
    case AT.GET_EXPORT_FAILED: {
      const failedExport = state.data.find(item => item.id === action.payload);
      if (!failedExport) return state;
      const data = state.data.map(item =>
        item.id === action.payload
          ? {
              ...item,
              status: EXPORT_STATES.ERR,
            }
          : item,
      );
      return {
        ...state,
        data,
        numberOfPendingItems: state.numberOfPendingItems - 1,
        showError: true,
      };
    }
    case AT.GET_EXPORT_SUCCESS: {
      const data = state.data.map(item =>
        item.id === action.payload.id
          ? {
              ...item,
              status: EXPORT_STATES.UNSEEN,
            }
          : item,
      );
      return {
        ...state,
        data,
        numberOfPendingItems: state.numberOfPendingItems - 1,
        numberOfUnseenItems: state.numberOfUnseenItems + 1,
      };
    }
    case AT.POST_EXPORT:
      return {
        ...state,
        numberOfPendingItems: state.numberOfPendingItems + 1,
      };
    case AT.EXPORT_DOWNLOAD_SUCCESS: {
      const selectedExport = state.data.find(item => item.id === action.payload.id);
      if (!selectedExport) return state;
      const shouldUpdateStatus = selectedExport.status !== EXPORT_STATES.DONE;
      if (!shouldUpdateStatus) return state;
      const data = state.data.map(item =>
        item.id === action.payload.id
          ? {
              ...item,
              status: EXPORT_STATES.DONE,
            }
          : item,
      );

      return {
        ...state,
        data,
        numberOfUnseenItems: state.numberOfUnseenItems - 1,
      };
    }
    default:
      return state;
  }
};

export default exportsHistory;
