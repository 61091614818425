import { FormattedMessage } from 'react-intl';

const MDFileDropDragView = () => (
  <>
    <div className="material-icons mdFileDropActive__icon">arrow_circle_down</div>
    <div className="mdFileDropActive__text">
      <FormattedMessage id="fileDrop.dropFileHere" defaultMessage="Upuść plik tutaj" />
    </div>
  </>
);

export default MDFileDropDragView;
