import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { AGGREGATION_OPTIONS_VALUES } from '@/constants/budgetMetrics.js';

import { getDatesFromRepeatObj, isHourFulfilled, splitWorkingHours } from './dateHelper.js';
import { checkIfOverlap, checkShiftOverlap } from './schedulerHelpers.js';

/* eslint-disable no-useless-escape */
/**
 * https://stackoverflow.com/questions/46155/validate-email-address-in-javascript
 * @param {string} email
 */
export const validateEmail = email => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

/**
 * Password - at least 1 digit, 1 special sign, 1 letter, min. length 8, max. lenght 25
 * @param {string} password
 */

export const validatePassword = password => {
  const re = /((?=.*[a-z])(?=.*[A-Z]))(?=.*[0-9])(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])\S{8,32}/;
  return re.test(password);
};
/* eslint-enable */

export const validateTimeFormat = timeFormat => {
  const re = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return re.test(timeFormat);
};

export const validateTimeRangeFormat = timeRange => {
  const [from, to] = timeRange.split('-');
  const re = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return re.test(from) && re.test(to);
};

export const validateWageFormat = wage => {
  const re = /^\d+(?:\.\d{1,2})?$/;
  return re.test(wage);
};

export const validateLongWageFormat = wage => {
  const re = /^\d+(?:\.\d{1,9})?$/;
  return re.test(wage);
};

export const validateWageFormatWithMaxLimit = wage => {
  const re = /^\d{1,6}(?:\.\d{1,2})?$/;
  return re.test(wage);
};

export const validateLongWageFormatWithMaxLimit = wage => {
  const re = /^\d{1,6}(?:\.\d{1,9})?$/;
  return re.test(wage);
};

export const validateInteger = number => {
  const re = /^\d+$/;
  return re.test(number);
};

export const validateFloat = number => {
  const re = /^-?\d+(\.\d+)?$/;
  return re.test(number);
};

export const validateLimit = (limit, period) => {
  const val = Number(limit);
  if (!Number.isInteger(val) || (limit.length > 1 && (limit.startsWith('0') || limit.startsWith('-'))) || val < 0)
    return false;
  switch (period) {
    case 'week':
      return val <= 7;
    case 'month':
      return val <= 31;
    case 'year':
      return val <= 366;
    default:
      return false;
  }
};

const intersect = (x, y) => x[0] < y[1] && y[0] < x[1];
/**
 * Validates if given working hours are overlapping with any of given shifts in array
 * @param {string} workingHours - given working hours in format 'HH:mm-HH:mm',
 * @param {Array} shiftsArray - array of shifts filtered from userEmployees by date
 *
 * @returns {Array} employeeArray - array with employees fullname
 */
export const validateWorkingHoursOverlap = (workingHours, shiftsArray) => {
  const workingHoursSplit = splitWorkingHours(workingHours);

  return shiftsArray.reduce((prev, shift) => {
    const hoursSplit = splitWorkingHours(shift.working_hours);
    if (intersect(workingHoursSplit, hoursSplit)) {
      return prev.concat(`${shift.employee.first_name} ${shift.employee.last_name}`);
    }
    return prev;
  }, []);
};

export const validateScheduleCycleDuration = duration => {
  const regExp = /^([1-9]|1[012])$/gm;
  return regExp.test(duration);
};

export const validateMaxDailyWorkingMinutes = workingMinutes => {
  const regExp = /^((?:[1-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
  return regExp.test(workingMinutes);
};

export const validateDateFormat = dates => dates.some(d => !moment(d, 'YYYY-MM-DD', true).isValid());

export const validateBudgetEstimatesValuesLength = (values, len) => values.some(v => v.toString().length > len);

export const validateRecommendedScheduleEmployeesNumber = values => values.some(v => Number.isInteger(Number(v)));

export const validateNip = nip => {
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const controlNumber = parseInt(nip.substring(9, 10));
  const sum = weights.reduce((acc, weight, i) => acc + parseInt(nip.substring(i, i + 1)) * weight, 0);
  return sum % 11 === controlNumber;
};

export const validationMessages = defineMessages({
  nameMissing: {
    id: 'validation.nameMissing',
    defaultMessage: 'Proszę podać imię',
  },
  nameInvalid: {
    id: 'validation.nameInvalid',
    defaultMessage: 'Podane imię jest niepoprawne',
  },
  nameTooLong: {
    id: 'validation.nameTooLong',
    defaultMessage: 'Podane imię jest za długie',
  },
  objectNameMissing: {
    id: 'validation.objectNameMissing',
    defaultMessage: 'Proszę podać nazwę',
  },
  objectNameTooLong: {
    id: 'validation.objectNameTooLong',
    defaultMessage: 'Podana nazwa jest zbyt długa',
  },
  templateTypeMissing: {
    id: 'validation.templateTypeMissing',
    defaultMessage: 'Typ jest wymagany',
  },
  lastNameMissing: {
    id: 'validation.lastNameMissing',
    defaultMessage: 'Proszę podać nazwisko',
  },
  lastNameInvalid: {
    id: 'validation.lastNameInvalid',
    defaultMessage: 'Podane nazwisko jest niepoprawne',
  },
  lastNameTooLong: {
    id: 'validation.lastNameTooLong',
    defaultMessage: 'Podane nazwisko jest za długie',
  },
  emailMissing: {
    id: 'validation.emailMissing',
    defaultMessage: 'Proszę podać adres email',
  },
  emailInvalid: {
    id: 'validation.emailInvalid',
    defaultMessage: 'Podany adres email jest niepoprawny',
  },
  phoneMissing: {
    id: 'validation.phoneMissing',
    defaultMessage: 'Proszę podać numer telefonu',
  },
  phoneInvalid: {
    id: 'validation.phoneInvalid',
    defaultMessage: 'Podany numer jest niepoprawny',
  },
  activationCodeInvalid: {
    id: 'validation.activationCodeInvalid',
    defaultMessage: 'Błędny kod aktywacyjny',
  },
  companyNameMissing: {
    id: 'validation.companyNameMissing',
    defaultMessage: 'Proszę podać nazwę firmy',
  },
  companyNameTooLong: {
    id: 'validation.companyNameMaxLength',
    defaultMessage: 'Nazwa firmy może mieć maksymalnie 200 znaków',
  },
  passwordMissing: {
    id: 'validation.passwordMissing',
    defaultMessage: 'Proszę podać hasło',
  },
  passwordInvalid: {
    id: 'validation.passwordInvalid',
    defaultMessage: 'Hasło musi zawierać min. 8 znaków w tym co najmniej 1 cyfrę, 1 znak specjalny i 1 dużą literę',
  },
  passwordNewSameAsOld: {
    id: 'validation.passwordNewSameAsOld',
    defaultMessage: 'Hasło nie może się powtarzać',
  },
  passwordNotPreviouslyUsed: {
    id: 'validation.passwordNotPreviouslyUsed',
    defaultMessage: 'Wymagane jest podanie hasła, które nie zostało wcześniej użyte dla tego konta',
  },
  passwordConfirmMissing: {
    id: 'validation.passwordConfirmMissing',
    defaultMessage: 'Proszę potwierdzić hasło',
  },
  passwordConfirmInvalid: {
    id: 'validation.passwordConfirmInvalid',
    defaultMessage: 'Hasła się nie zgadzają',
  },
  passwordOldMismatch: {
    id: 'validation.passwordOldMismatch',
    defaultMessage: 'Podano błędne hasło',
  },
  pin4Length: {
    id: 'validation.pin4Length',
    defaultMessage: 'Kod PIN powinien mieć 4 cyfry',
  },
  pin4Invalid: {
    id: 'validation.pin4Invalid',
    defaultMessage: 'Kod PIN jest niepoprawny',
  },
  referenceIdTooLong: {
    id: 'validation.referenceIdTooLong',
    defaultMessage: 'Podany kod referencyjny jest za długi',
  },
  refCodeMissing: {
    id: 'validation.refCodeMissing',
    defaultMessage: 'Kod miejsca pracy jest wymagany',
  },
  refCodeTooLong: {
    id: 'validation.refCodeTooLong',
    defaultMessage: 'Podany kod miejsca pracy jest za długi',
  },
  fillAllRequiredFields: {
    id: 'validation.fillAllRequiredFields',
    defaultMessage: 'Proszę wypełnić wszystkie wymagane pola',
  },
  workingHoursMissing: {
    id: 'validation.workingHoursMissing',
    defaultMessage: 'Podaj poprawny czas, np. 10:00-18:00',
  },
  workingHoursSingleOverlap: {
    id: 'validation.workingHoursSingleOverlap',
    defaultMessage: 'Zmiany się pokrywają. Wprowadź niepokrywającą się zmianę',
  },
  workingHoursOverlap: {
    id: 'validation.workingHoursOverlap',
    defaultMessage: 'Zmiany dla {employeeArray} pokrywają się.',
  },
  locationNameMissing: {
    id: 'validation.locationNameMissing',
    defaultMessage: 'Proszę podać nazwę lokalizacji',
  },
  locationNameTooLong: {
    id: 'validation.locationNameTooLong',
    defaultMessage: 'Podana nazwa jest zbyt długa',
  },
  cityMissing: {
    id: 'validation.cityMissing',
    defaultMessage: 'Proszę podać nazwę miasta',
  },
  cityTooLong: {
    id: 'validation.cityTooLong',
    defaultMessage: 'Podana nazwa jest zbyt długa',
  },
  addressMissing: {
    id: 'validation.addressMissing',
    defaultMessage: 'Proszę podać adres',
  },
  addressTooLong: {
    id: 'validation.addressTooLong',
    defaultMessage: 'Podana nazwa jest zbyt długa',
  },
  additionaInformationTooLong: {
    id: 'validation.additionaInformationTooLong',
    defaultMessage: 'Podane dodatkowe informacje są zbyt długie',
  },
  jobTitleMissing: {
    id: 'validation.jobTitleMissing',
    defaultMessage: 'Proszę podać nazwę stanowiska',
  },
  wageMissing: {
    id: 'validation.wageMissing',
    defaultMessage: 'Proszę podać stawkę',
  },
  wageInvalid: {
    id: 'validation.wageInvalid',
    defaultMessage: 'Proszę podać poprawną wartość dla stawki np.: 16.55',
  },
  wageTooHigh: {
    id: 'validation.wageTooHeight',
    defaultMessage: 'Stawka nie może być większa niż 1 000 000',
  },
  maxDailyWorkingMinutesMissing: {
    id: 'validation.maxDailyWorkingMinutesMissing',
    defaultMessage: 'Proszę podać dobowy wymiar czasu pracy',
  },
  maxDailyWorkingMinutesInvalid: {
    id: 'validation.maxDailyWorkingMinutesInvalid',
    defaultMessage: 'Dobowy wymiar czasu pracy powinien być w przedziale 00:01 - 23:59',
  },
  weeklyWorkingMinutesMissing: {
    id: 'validation.weeklyWorkingMinutesMissing',
    defaultMessage: 'Proszę podać tygodniowy wymiar czasu pracy',
  },
  weeklyWorkingMinutesInvalid: {
    id: 'validation.weeklyWorkingMinutesInvalid',
    defaultMessage:
      'Tygodniowy wymiar czasu pracy powinien być w przedziale 00:00 - 99:59 oraz nie mniejszy niż dobowy wymiaru czasu pracy',
  },
  factorMissing: {
    id: 'validation.factorMissing',
    defaultMessage: 'Proszę podać mnożnik',
  },
  factorInvalid: {
    id: 'validation.factorInvalid',
    defaultMessage: 'Proszę podać poprawną wartość dla mnożnika np.: 6.50',
  },
  factorTooHigh: {
    id: 'validation.factorTooHeight',
    defaultMessage: 'Mnożnik nie może być większy niż 1 000 000',
  },
  factorLongInvalid: {
    id: 'validation.factorLongInvalid',
    defaultMessage: 'Proszę podać poprawną wartość dla mnożnika np.: 6.123456789',
  },
  timeRangeMissing: {
    id: 'validation.timeRangeMissing',
    defaultMessage: 'Proszę podać pełne godziny pracy',
  },
  timeRangeForAbsenceMissing: {
    id: 'validation.timeRangeForAbsenceMissing',
    defaultMessage: 'Proszę podać pełne godziny nieobecności',
  },
  scheduleCycleMissing: {
    id: 'validation.scheduleCycleMissing',
    defaultMessage: 'Proszę podać długość okresu rozliczeniowego',
  },
  scheduleCycleInvalid: {
    id: 'validation.scheduleCycleInvalid',
    defaultMessage: 'Długość okresu rozliczeniowego musi być w przedziale 1-12 miesięcy',
  },
  timeFormatMissing: {
    id: 'validation.timeFormatMissing',
    defaultMessage: 'Proszę podać godzinę',
  },
  timeFormatInvalid: {
    id: 'validation.timeFormatInvalid',
    defaultMessage: 'Godzina powinna być w przedziale 00:00 - 23:59 oraz w formacie HH:mm np.: 08:00',
  },
  scheduleEventDescriptionMissing: {
    id: 'validation.scheduleEventDescriptionMissing',
    defaultMessage: 'Proszę podać treść wydarzenia',
  },
  scheduleEventDescriptionTooLong: {
    id: 'validation.scheduleEventDescriptionTooLong',
    defaultMessage: 'Treść wydarzenia jest zbyt długa',
  },
  scheduleEventTypeMissing: {
    id: 'validation.scheduleEventTypeMissing',
    defaultMessage: 'Proszę wybrać typ wydarzenia',
  },
  budgetEstimateMissing: {
    id: 'validation.budgetEstimateMissing',
    defaultMessage: 'Proszę podać wartość',
  },
  budgetEstimateInvalid: {
    id: 'validation.budgetEstimateInvalid',
    defaultMessage: 'Proszę podać poprawną wartość np.: 20.55',
  },
  budgetEstimateTooLong: {
    id: 'validation.budgetEstimateTooLong',
    defaultMessage: 'Podana wartość jest zbyt długa',
  },
  importedBudgetEstimatesValueTooLong: {
    id: 'validation.importedBudgetEstimatesValueTooLong',
    defaultMessage: 'Maksymalna długość dla prognozy sprzedaży wynosi 8 znaków ',
  },
  importedLabourLimitValueTooLong: {
    id: 'validation.importedLabourLimitTooLong',
    defaultMessage: 'Maksymalna długość dla planowanego % kosztów wynosi 7 znaków ',
  },
  employeesNumberInvalid: {
    id: 'validation.employeesNumberInvalid',
    defaultMessage: 'Liczba pracowników musi być równa conajmniej 1',
  },
  fileNameInvalid: {
    id: 'validation.import.fileNameInvalid',
    defaultMessage: 'Niepoprawna nazwa pliku',
  },
  emptyFile: {
    id: 'validation.import.emptyFile',
    defaultMessage: 'Pusty plik, proszę wypełnić formularz',
  },
  dateFormatInvalid: {
    id: 'validation.import.dateFormatInvalid',
    defaultMessage: 'Niepoprawny format daty. Dopuszczalny format: 2018-01-01',
  },
  availabilityLimitMissing: {
    id: 'validation.availabilityLimitsLimitMissing',
    defaultMessage: 'Proszę wpisać limit dla dostępności',
  },
  availabilityLimitInvalid: {
    id: 'validation.availabilityLimitInvalid',
    defaultMessage: 'Proszę podać prawidłową wartość dla wybranego okresu',
  },
  repeatObjDisabledDate: {
    id: 'validation.repeatObjDisabledDate',
    defaultMessage: 'Nie możesz dodać zmian dla wybranych dat',
  },
  repeatObjNoDates: {
    id: 'validation.repeatObjNoDates',
    defaultMessage: 'Proszę wybrać przynajmniej jedną datę',
  },
  aliasTooLong: {
    id: 'validation.aliasTooLong',
    defaultMessage: 'Podany alias jest za długi',
  },
  nfcCodeTooLong: {
    id: 'validation.nfcCodeTooLong',
    defaultMessage: 'Podany kod NFC jest za długi',
  },
  nfcAlreadyUsed: {
    id: 'validation.nfcAlreadyUsed',
    defaultMessage: 'Podany kod NFC jest już przypisany do innego pracownika',
  },
  importDatesRangeInvalid: {
    id: 'validation.importDatesRangeInvalid',
    defaultMessage: 'Wprowadzony zakres dat nie zgadza się z zakresem dat wybranego grafiku',
  },
  employeesNumberTooLong: {
    id: 'validation.employeesNumberTooLong',
    defaultMessage: 'Liczba potrzebnych pracowników nie może by większa niż 10 000',
  },
  attendanceLocationRadiusLimitTooLow: {
    id: 'validation.attendanceLocationRadiusLimitTooLow',
    defaultMessage: 'Promień nie może być mniejszy niż 20',
  },
  attendanceLocationRadiusLimitTooBig: {
    id: 'validation.attendanceLocationRadiusLimitTooBig',
    defaultMessage: 'Podany promień jest zbyt duży',
  },
  latitudeInvalid: {
    id: 'validation.latitudeInvalid',
    defaultMessage: 'Szerokość geograficzna powinna być w przedziale od -90 do 90',
  },
  longitudeInvalid: {
    id: 'validation.longitudeInvalid',
    defaultMessage: 'Długość geograficzna powinna być w przedziale od -180 do 180',
  },
  openHoursInvalid: {
    id: 'validation.openHoursInvalid',
    defaultMessage: 'Dni pracy lokacji muszą posiadać prawidłowe godziny otwarcia i zamknięcia',
  },
  valueIsNotNumber: {
    id: 'validation.valueIsNotNumber',
    defaultMessage: 'Podana wartość powinna być liczbą',
  },
  valueIsNotInteger: {
    id: 'validation.valueIsNotInteger',
    defaultMessage: 'Podana wartość powinna być liczbą całkowitą',
  },
  valueIsRequired: {
    id: 'validation.valueIsRequired',
    defaultMessage: 'To pole jest wymagane',
  },
  notificationTitleMissing: {
    id: 'validation.notificationTitleMissing',
    defaultMessage: 'Proszę podać tytuł',
  },
  notificationTitleTooLong: {
    id: 'validation.notificationTitleTooLong',
    defaultMessage: 'Tytuł powiadomienia nie może przekraczać 30 znaków',
  },
  notificationMessageMissing: {
    id: 'validation.notificationMessageMissing',
    defaultMessage: 'Proszę podać treść powiadomienia',
  },
  notificationMessageTooLong: {
    id: 'validation.notificationMessageTooLong',
    defaultMessage: 'Treść powiadomienia nie może przekraczać 255 znaków',
  },
  commentTooLong: {
    id: 'validation.commentTooLong',
    defaultMessage: 'Treść komentarza nie może przekraczać 255 znaków',
  },
  invalidRoleName: {
    id: 'validation.invalidRoleName',
    defaultMessage: 'Istnieje już rola o tej samej nazwie',
  },
  rankEqualsZero: {
    id: 'validation.rankEqualsZero',
    defaultMessage: 'Ranga musi być większa niż 0',
  },
  invalidRankNumber: {
    id: 'validation.invalidRankNumber',
    defaultMessage: 'Ranga musi być liczbą całkowitą',
  },
  tooBigRankNumber: {
    id: 'validation.tooBigRankNumber',
    defaultMessage: 'Przypisana ranga musi być niższa niż ranga przypisana do roli {roleName}',
  },
  numberOutOfRange: {
    id: 'validation.numberOutOfRange',
    defaultMessage: 'Liczba jest spoza oczekiwanego zakresu',
  },
  employmentConditionsTemplateMissing: {
    id: 'validation.employmentConditionsTemplateMissing',
    defaultMessage: 'Proszę wybrać szablon',
  },
  authCodeWrong: {
    id: 'validation.authCodeTooLong',
    defaultMessage: 'Kod urządzenia musi mieć 6 znaków',
  },
  deviceNameTooLong: {
    id: 'validation.deviceNameTooLong',
    defaultMessage: 'Nazwa urządzenia nie może przekraczać 100 znaków',
  },
  deviceNameTooShort: {
    id: 'validation.deviceNameTooShort',
    defaultMessage: 'Nazwa urządzenia nie może być pusta',
  },
  fullNameTooShort: {
    id: 'validation.fullNameTooShort',
    defaultMessage: 'Nazwa nie może być pusta',
  },
  fullNameTooLong: {
    id: 'validation.absenceFullNameIsTooLong',
    defaultMessage: 'Nazwa nie może przekraczać 45 znaków',
  },
  shortNameTooShort: {
    id: 'validation.shortNameTooShort',
    defaultMessage: 'Skrót nie może być pusty',
  },
  shortNameTooLong: {
    id: 'validation.shortNameTooLong',
    defaultMessage: 'Skrót może mieć maks. 3 znaki',
  },
  employeesRequired: {
    id: 'validation.employeesRequired',
    defaultMessage: 'Nie wybrano żadnego pracownika',
  },
  locationsRequired: {
    id: 'validation.locationsRequired',
    defaultMessage: 'Należy wybrać co najmniej jedną lokalizacje',
  },
  startEqualToEnd: {
    id: 'validation.startEqualToEnd',
    defaultMessage: 'Godzina rozpoczęcia i zakończenia zmiany nie może być taka sama',
  },
  locationIdRequired: {
    id: 'validation.locationIdRequired',
    defaultMessage: 'Lokalizacja jest wymagana',
  },
  discountCode: {
    id: 'validation.discountCode',
    defaultMessage: 'Kod promocyjny może mieć maksymalnie 500 znaków',
  },
  avaTypeNameMissing: {
    id: 'validation.avaTypeNameMissing',
    defaultMessage: 'Pole nazwa nie powinno być puste',
  },
  avaTypeNameTooLong: {
    id: 'validation.avaTypeNameTooLong',
    defaultMessage: 'Nazwa dostępności nie może przekraczać 50 znaków',
  },
  avaShortNameTooLong: {
    id: 'validation.avaShortNameTooLong',
    defaultMessage: 'Pole Skrócona Nazwa nie powinno być dłuższe niż 3 znaki',
  },
  avaCountHoursZeros: {
    id: 'validation.avaCountHoursZeros',
    defaultMessage: 'Liczba godzin musi być większa od zera',
  },
  avaCountHoursIncorrectFormat: {
    id: 'validation.avaCountHoursIncorrectFormat',
    defaultMessage: 'Nieprawidłowy format',
  },
  purposesRequired: {
    id: 'validation.purposesRequired',
    defaultMessage: 'Należy wybrać przynajmniej jeden cel',
  },
  jobTitleIdRequired: {
    id: 'validation.jobTitleIdRequired',
    defaultMessage: 'Stanowisko jest wymagane',
  },
  nipRequired: {
    id: 'validation.nipRequired',
    defaultMessage: 'Proszę podać numer NIP',
  },
  nipLength: {
    id: 'validation.nipLength',
    defaultMessage: 'Numer NIP musi mieć 10 cyfr',
  },
  nipInvalid: {
    id: 'validation.nipInvalid',
    defaultMessage: 'Numer NIP jest niepoprawny',
  },
  zipCodeRequired: {
    id: 'validation.zipCodeRequired',
    defaultMessage: 'Proszę podać kod pocztowy',
  },
  budgetMetricNameRequired: {
    id: 'validation.budgetMetricNameRequired',
    defaultMessage: 'Proszę podać nazwę metryki',
  },
  budgetMetricNameTooLong: {
    id: 'validation.budgetMetricNameTooLong',
    defaultMessage: 'Nazwa metryki jest zbyt długa',
  },
  budgetMetricTypeRequired: {
    id: 'validation.budgetMetricTypeRequired',
    defaultMessage: 'Proszę podać typ metryki',
  },
  budgetMetricTypeInvalid: {
    id: 'validation.budgetMetricTypeInvalid',
    defaultMessage: 'Proszę podać poprawny typ metryki',
  },
  timeIntervalRequired: {
    id: 'validation.timeIntervalRequired',
    defaultMessage: 'Proszę podać przedział czasowy',
  },
  timeIntervalInvalid: {
    id: 'validation.timeIntervalInvalid',
    defaultMessage: 'Proszę podać poprawny przedział czasowy',
  },
  changeTemplateName: {
    id: 'changeTemplateName',
    defaultMessage: 'Szablon o tej nazwie już istnieje. Zmień nazwę, aby stworzyć nowy szablon.',
  },
  jobTitleNameTooLong: {
    id: 'validation.jobTitleNameTooLong',
    defaultMessage: 'Podana nazwa stanowiska jest za długa. Podaj nazwę krótszą niż 50 znaków.',
  },
  timeInputRequired: {
    id: 'timeInputRequired',
    defaultMessage: 'Proszę podać wartość.',
  },
  aggregationRequired: {
    id: 'validation.aggregationRequired',
    defaultMessage: 'Proszę podać typ agregacji metryki',
  },
  aggregationOptionInvlaid: {
    id: 'validation.aggregationOptionInvlaid',
    defaultMessage: 'Wybierz jedną z dostępnych opcji: Suma lub Średnia',
  },
  contractStartDateMustBeProvided: {
    id: 'validation.contractStartDateMustBeProvided',
    defaultMessage: 'Kontrakt musi posiadać datę rozpoczęcia',
  },
  selectedEmployeesLessNumberThanDeclared: {
    id: 'validation.selectedLessEmployeesThanDeclared',
    defaultMessage: 'Wybrano mniej niż zadeklarowana liczba pracowników',
  },
  selectedEmployeesMoreNumberThanDeclared: {
    id: 'validation.selectedEmployeesMoreNumberThanDeclared',
    defaultMessage: 'Wybrano zbyt wielu pracowników',
  },
  shiftMustBeAtLeastOneMinute: {
    id: 'validation.shiftMustBeAtLeastOneMinute',
    defaultMessage: 'Minimalna długość zmiany to 1 minuta.',
  },
  absenceMustBeAtLeastOneMinute: {
    id: 'validation.absenceMustBeAtLeastOneMinute',
    defaultMessage: 'Minimalna długość urlopu to 1 minuta.',
  },
  locationGroupsMustBeAtLeastOneSelected: {
    id: 'validation.locationGroupsMustBeAtLeastOneSelected',
    defaultMessage: 'Należy wybrać minimum jedną grupę lokalizacji',
  },
  employmentConditionsMustBeSelectedAtLeastOnce: {
    id: 'validation.employmentConditionsMustBeSelectedAtLeastOnce',
    defaultMessage: 'Należy wybrać minimum jeden warunek zatrudnienia',
  },
});
const MAX_NAME_LENGTH = 30;
const MAX_LAST_NAME_LENGTH = 30;

export const validateFileHandler = (fileName, expectedFileName, fileData) => {
  const messages = [];
  if (!fileName.includes(expectedFileName)) {
    messages.push({
      message: validationMessages.fileNameInvalid.defaultMessage,
      type: 'error',
    });
  }

  if (!fileData.length) {
    messages.push({
      message: validationMessages.emptyFile.defaultMessage,
      type: 'error',
    });
  }
  return messages;
};

export const validateRecommendedScheduleFileHandler = (
  fileName,
  expectedFileName,
  fileData,
  selectedPeriodDates,
  validateFileName = true,
) => {
  const messages = [];
  const dates = fileData.map(d => d.date);
  const hours = fileData.map(d => d.hour);
  const employeesNumbers = fileData.reduce((acc, val) => acc.concat(Object.values(val.jobTitlesData)), []);
  if (validateDateFormat(dates)) {
    messages.push({
      message: validationMessages.dateFormatInvalid.defaultMessage,
      type: 'error',
    });
  }
  if (hours.some(hour => !validateTimeFormat(hour))) {
    messages.push({
      message: validationMessages.timeFormatInvalid.defaultMessage,
      type: 'error',
    });
  }
  if (employeesNumbers.some(num => Number.isNaN(parseFloat(num)))) {
    messages.push({
      message: validationMessages.employeesNumberInvalid.defaultMessage,
      type: 'error',
    });
  }
  if (employeesNumbers.some(num => num.length > 5)) {
    messages.push({
      message: validationMessages.employeesNumberTooLong.defaultMessage,
      type: 'error',
    });
  }
  if (dates.some(date => !selectedPeriodDates.includes(date))) {
    messages.push({
      message: validationMessages.importDatesRangeInvalid.defaultMessage,
      type: 'info',
    });
  }

  if (validateFileName) {
    return messages.concat(validateFileHandler(fileName, expectedFileName, fileData));
  }
  return messages.concat(validateFileHandler(expectedFileName, expectedFileName, fileData));
};

export const validateBudgetFileHandler = (fileName, expectedFileName, fileData, validateFileName = true) => {
  const messages = [];
  const dates = fileData.map(data => data.date);
  const estimates = fileData.map(data =>
    Number.isNaN(data.estimated_budget) || !data.estimated_budget ? 0 : data.estimated_budget / 100,
  );
  const labourLimits = fileData.map(data =>
    Number.isNaN(data.labour_limit) || !data.estimated_budget ? 0 : data.labour_limit / 100,
  );
  if (validateBudgetEstimatesValuesLength(estimates, 8)) {
    messages.push({
      message: validationMessages.importedBudgetEstimatesValueTooLong.defaultMessage,
      type: 'error',
    });
  }
  if (validateBudgetEstimatesValuesLength(labourLimits, 7)) {
    messages.push({
      message: validationMessages.importedLabourLimitValueTooLong.defaultMessage,
      type: 'error',
    });
  }
  if (validateDateFormat(dates)) {
    messages.push({
      message: validationMessages.dateFormatInvalid.defaultMessage,
      type: 'error',
    });
  }
  if (validateFileName) {
    return messages.concat(validateFileHandler(fileName, expectedFileName, fileData));
  }
  return messages.concat(validateFileHandler(expectedFileName, expectedFileName, fileData));
};

export const inputValidation = (name, inputValue, { valueToCompare = '', required = true } = {}) => {
  const value = inputValue || '';
  switch (name) {
    case 'name':
      if (required && !value.trim().length) {
        return validationMessages.nameMissing;
      }
      if (value.length > MAX_NAME_LENGTH) {
        return validationMessages.nameTooLong;
      }
      break;
    case 'roleName':
      if (required && !value.trim().length) {
        return validationMessages.objectNameMissing;
      }
      if (value.length > 20) {
        return validationMessages.objectNameTooLong;
      }
      break;
    case 'rank': {
      const parsedValue = parseInt(value);
      const correctFormat = /^[0-9]{1,2}$/;

      if (parsedValue > valueToCompare) {
        return validationMessages.tooBigRankNumber;
      }

      if (required && !value) {
        return validationMessages.valueIsRequired;
      }

      if (parsedValue === 0) {
        return validationMessages.rankEqualsZero;
      }

      if (!parsedValue || !correctFormat.test(value)) {
        return validationMessages.invalidRankNumber;
      }
      if (!(parsedValue >= 1 && parsedValue <= 10)) {
        return validationMessages.numberOutOfRange;
      }
      if (value.length > 2) {
        return validationMessages.objectNameTooLong;
      }
      break;
    }
    case 'objectName': {
      const doesTemplateExist = valueToCompare ? valueToCompare.some(({ name }) => name === value) : false;
      if (required && !value.trim().length) {
        return validationMessages.objectNameMissing;
      }
      if (value.length > 32) {
        return validationMessages.objectNameTooLong;
      }

      if (required && doesTemplateExist) {
        return validationMessages.changeTemplateName;
      }
      break;
    }
    case 'templateType':
      if (required && !value.trim().length) {
        return validationMessages.templateTypeMissing;
      }
      break;

    case 'lastName':
      if (required && !value.trim().length) {
        return validationMessages.lastNameMissing;
      }
      if (value.length > MAX_LAST_NAME_LENGTH) {
        return validationMessages.lastNameTooLong;
      }
      break;
    case 'email':
      if (!validateEmail(value)) {
        if (required && !value.length) {
          return validationMessages.emailMissing;
        }
        return validationMessages.emailInvalid;
      }
      break;
    case 'email2':
      if (!validateEmail(value)) {
        if (value.length) {
          return validationMessages.emailInvalid;
        }
      }
      break;
    case 'phone': {
      if (!required && !value.length) break;
      if (required && !value.length) {
        return validationMessages.phoneMissing;
      }
      try {
        const phoneNumber = parsePhoneNumber(`+${valueToCompare}${value}`);
        const isValid = phoneNumber.isValid();
        if (!isValid) return validationMessages.phoneInvalid;
      } catch (err) {
        return validationMessages.phoneInvalid;
      }
      break;
    }
    case 'phone2':
      if (required && !value.length) {
        return validationMessages.phoneMissing;
      }
      if (!/^\d{9}$/.test(value) && value.length) {
        return validationMessages.phoneInvalid;
      }
      break;
    case 'activationCode':
      if (!/^\d{6}$/.test(value)) {
        return validationMessages.activationCodeInvalid;
      }
      // TODO: CHECK IF CODE IS VALID IN BASE
      break;
    case 'companyName':
      if (required && !value.trim().length) {
        return validationMessages.companyNameMissing;
      }
      if (value.length > 200) {
        return validationMessages.companyNameTooLong;
      }
      break;
    case 'password':
      if (!validatePassword(value)) {
        return validationMessages.passwordInvalid;
      }
      if (required && !value.length) {
        return validationMessages.passwordMissing;
      }
      if (value === valueToCompare) {
        return validationMessages.passwordNotPreviouslyUsed;
      }
      break;
    case 'passwordLogin':
      if (required && !value.length) {
        return validationMessages.passwordMissing;
      }
      break;
    case 'passwordConfirm':
      if (required && !value.length) {
        return validationMessages.passwordConfirmMissing;
      }
      if (value !== valueToCompare) {
        return validationMessages.passwordConfirmInvalid;
      }
      break;
    case 'pin4':
      if (value.length !== 4) {
        return validationMessages.pin4Length;
      }
      if (!/^\d{4}$/.test(value)) {
        return validationMessages.pin4Invalid;
      }
      break;
    case 'referenceId':
      if (required && !value.length) {
        return validationMessages.valueIsRequired;
      }
      if (value.length > 60) {
        return validationMessages.referenceIdTooLong;
      }
      break;
    case 'shiftOverlap': {
      if (Object.keys(valueToCompare).length) {
        const checkIfShiftsOverlaps = checkShiftOverlap(valueToCompare, value, [value.id]);
        if (checkIfShiftsOverlaps) {
          return validationMessages.workingHoursSingleOverlap;
        }
      }
      break;
    }
    case 'shiftsOverlap': {
      if (Object.keys(valueToCompare).length) {
        const overlap = checkIfOverlap(value, valueToCompare);
        if (overlap) {
          return validationMessages.workingHoursSingleOverlap;
        }
      }
      break;
    }
    case 'absencesOverlap': {
      if (Object.keys(valueToCompare).length) {
        const overlap = checkIfOverlap(value, valueToCompare);
        if (overlap) {
          return validationMessages.workingHoursSingleOverlap;
        }
      }
      break;
    }
    case 'refCode': {
      if (!value.length) {
        return validationMessages.refCodeMissing;
      }
      if (value.length > 12) {
        return validationMessages.refCodeTooLong;
      }
      break;
    }
    case 'workingHours': {
      if (required && (!value.length || !!~value.search('_'))) {
        return validationMessages.workingHoursMissing;
      }
      if (valueToCompare.length) {
        const employeeArray = validateWorkingHoursOverlap(value, valueToCompare);
        if (employeeArray.length === 1) {
          return validationMessages.workingHoursSingleOverlap;
        }
        if (employeeArray.length > 1) {
          return {
            ...validationMessages.workingHoursOverlap,
            values: {
              employeeArray: employeeArray.join(', '),
            },
          };
        }
        break;
      }
      break;
    }
    case 'locationName':
      if (required && !value.trim().length) {
        return validationMessages.locationNameMissing;
      }
      if (value.length > 30) {
        return validationMessages.locationNameTooLong;
      }
      break;
    case 'city':
      if (required && !value.trim().length) {
        return validationMessages.cityMissing;
      }
      if (value.length > 50) {
        return validationMessages.cityTooLong;
      }
      break;
    case 'address':
      if (!value.trim().length) {
        return validationMessages.addressMissing;
      }
      if (value.length > 100) {
        return validationMessages.addressTooLong;
      }
      break;
    case 'additionalInformation':
      if (value.length > 100) {
        return validationMessages.additionaInformationTooLong;
      }
      break;
    case 'jobTitle':
      if (required && !value.trim().length) {
        return validationMessages.jobTitleMissing;
      }
      if (value.length > 50) return validationMessages.jobTitleNameTooLong;

      break;
    case 'wage':
      if (required && !value.length) {
        return validationMessages.wageMissing;
      }
      if (!validateWageFormat(value)) {
        return validationMessages.wageInvalid;
      }
      break;
    case 'estimated_budget':
      if (required && !value.length) {
        return validationMessages.budgetEstimateMissing;
      }
      if (!validateWageFormat(value)) {
        return validationMessages.budgetEstimateInvalid;
      }
      if (value.length > 8) {
        return validationMessages.budgetEstimateTooLong;
      }
      break;
    case 'labour_limit':
      if (required && !value.length) {
        return validationMessages.budgetEstimateMissing;
      }
      if (!validateWageFormat(value)) {
        return validationMessages.budgetEstimateInvalid;
      }
      if (value.length > 7) {
        return validationMessages.budgetEstimateTooLong;
      }
      break;
    case 'factor':
      if (required && !value.length) {
        return validationMessages.factorMissing;
      }
      if (!validateLongWageFormat(value)) {
        return validationMessages.factorLongInvalid;
      }
      break;
    case 'working_hours': {
      if (required && (value.search('_') > -1 || !value.length)) return validationMessages.timeRangeMissing;
      const [start, end] = value.split('-');
      if (start === end) return validationMessages.shiftMustBeAtLeastOneMinute;
      break;
    }
    case 'absenceHours': {
      if (required && (value.search('_') > -1 || !value.length)) return validationMessages.timeRangeForAbsenceMissing;
      const [start, end] = value.split('-');
      if (start === end) return validationMessages.absenceMustBeAtLeastOneMinute;
      break;
    }
    case 'timerange':
      if (required && (value.search('_') > -1 || !value.length)) return validationMessages.timeRangeMissing;
      break;
    case 'working_hours_2': {
      if (required && (value.search('_') > -1 || !value.length)) {
        return validationMessages.timeRangeMissing;
      }
      const [start, end] = value.split('-');
      if (start === end) return validationMessages.startEqualToEnd;
      break;
    }
    case 'scheduleCycle':
      if (value === '0' || value < 0 || value > 12) {
        return validationMessages.scheduleCycleInvalid;
      }
      break;
    case 'startHour':
    case 'endHour':
    case 'autoclose_attendances_at_time':
      if (!value) break; // TODO: we need require flag in inputValidation func
      if (!validateTimeFormat(value)) {
        return validationMessages.timeFormatInvalid;
      }
      break;
    case 'attendanceHours': {
      const [time1, time2] = value.split('-');
      if (time1 === time2) return validationMessages.startEqualToEnd;
      if (!validateTimeFormat(time1)) {
        return validationMessages.timeFormatInvalid;
      }
      if (!time2.match(/^__:__$/) && !validateTimeFormat(time2)) {
        return validationMessages.timeFormatInvalid;
      }
      break;
    }
    case 'scheduleEventDescription':
      if (required && !value.length) {
        return validationMessages.scheduleEventDescriptionMissing;
      }
      if (value.length > 100) {
        return validationMessages.scheduleEventDescriptionTooLong;
      }
      break;
    case 'scheduleEventType':
      if (required && !value.length) {
        return validationMessages.scheduleEventTypeMissing;
      }
      break;
    case 'maxDailyWorkingMinutes':
      if (required && value.search('_') === 0) {
        return validationMessages.maxDailyWorkingMinutesMissing;
      }
      if (value.search('_') > -1) {
        return validationMessages.maxDailyWorkingMinutesInvalid;
      }
      break;
    case 'weeklyWorkingMinutes':
      if (required && value.search('_') === 0) {
        return validationMessages.weeklyWorkingMinutesMissing;
      }
      if (value.search('_') > -1 || valueToCompare > value) {
        return validationMessages.weeklyWorkingMinutesInvalid;
      }
      break;
    case 'scheduleCycleDuration':
      if (required && !value) {
        return validationMessages.scheduleCycleMissing;
      }
      if (!validateScheduleCycleDuration(value)) {
        return validationMessages.scheduleCycleInvalid;
      }
      break;
    case 'shifts_required':
    case 'employeesNumber':
      if (required && value < 1) {
        return validationMessages.employeesNumberInvalid;
      }
      break;
    case 'availabilityLimits':
      {
        const validLimits = value.some(v => !validateLimit(v.limit, v.period));
        const limits = value.filter(v => v.limit === '');
        if (required && (limits.length || validLimits)) {
          return validationMessages.availabilityLimitInvalid;
        }
      }
      break;
    case 'repeatObj': {
      const dateRange = getDatesFromRepeatObj(inputValue);
      if (dateRange.some(date => date < valueToCompare)) {
        return validationMessages.repeatObjDisabledDate;
      }
      if (dateRange.length === 0) {
        return validationMessages.repeatObjNoDates;
      }
      break;
    }
    case 'nfcCode': {
      if (value && value.length && value.length > 30) {
        return validationMessages.nfcCodeTooLong;
      }
      if (Array.isArray(valueToCompare) && value && valueToCompare.includes(value)) {
        return validationMessages.nfcAlreadyUsed;
      }
      break;
    }
    case 'alias': {
      if (value && value.length && value.length > 30) {
        return validationMessages.aliasTooLong;
      }
      break;
    }
    case 'latitude':
      if (required && !value.length) {
        return validationMessages.valueIsRequired;
      }
      if (!validateFloat(value)) {
        return validationMessages.valueIsNotNumber;
      }
      if (value < -90 || value > 90) {
        return validationMessages.latitudeInvalid;
      }
      break;
    case 'longitude':
      if (required && !value.length) {
        return validationMessages.valueIsRequired;
      }
      if (!validateFloat(value)) {
        return validationMessages.valueIsNotNumber;
      }
      if (value < -180 || value > 180) {
        return validationMessages.longitudeInvalid;
      }
      break;
    case 'openHours': {
      const isInvalid = Object.values(value).some(
        timeRange => timeRange && (typeof timeRange !== 'string' || !validateTimeRangeFormat(timeRange)),
      );
      if (isInvalid) {
        return validationMessages.openHoursInvalid;
      }
      break;
    }
    case 'attendanceLocationRadiusLimit':
    case 'radiusLimit':
      if (required && !String(value).length) {
        return validationMessages.valueIsRequired;
      }
      if (!validateInteger(value)) {
        return validationMessages.valueIsNotInteger;
      }
      if (value < 20) {
        return validationMessages.attendanceLocationRadiusLimitTooLow;
      }
      if (value > 40075000) {
        return validationMessages.attendanceLocationRadiusLimitTooBig;
      }
      break;
    case 'notificationTitle':
      if (required && !value.trim().length) {
        return validationMessages.notificationTitleMissing;
      }
      if (value.length > 30) {
        return validationMessages.notificationTitleTooLong;
      }
      break;
    case 'notificationMessage':
      if (required && !value.trim().length) {
        return validationMessages.notificationMessageMissing;
      }
      if (value.length > 255) {
        return validationMessages.notificationMessageTooLong;
      }
      break;
    case 'comment':
      if (value.length > 255) {
        return validationMessages.commentTooLong;
      }
      break;
    case 'employmentConditionsTemplateId':
      if (value === -1) {
        return validationMessages.employmentConditionsTemplateMissing;
      }
      break;
    case 'authCode':
      if (value.replace(/ /g, '').length !== 6) {
        return validationMessages.authCodeWrong;
      }
      break;
    case 'deviceName':
      if (!value.length) {
        return validationMessages.deviceNameTooShort;
      }
      if (value.length > 100) {
        return validationMessages.deviceNameTooLong;
      }
      break;
    case 'fullName':
      if (value.length === 0) {
        return validationMessages.fullNameTooShort;
      }
      if (value.length > 45) {
        return validationMessages.fullNameTooLong;
      }
      break;
    case 'shortName':
      if (value.length === 0) {
        return validationMessages.shortNameTooShort;
      }
      if (value.length > 3) {
        return validationMessages.shortNameTooLong;
      }
      break;
    case 'absenceComment':
      if (value.length > 255) {
        return validationMessages.commentTooLong;
      }
      break;
    case 'selectedEmployee':
    case 'selectedEmployeeId':
      if (!value) {
        return validationMessages.valueIsRequired;
      }
      break;
    case 'selectedEmployees':
      if (!value || value.length === 0) {
        return validationMessages.employeesRequired;
      }
      break;
    case 'locations':
      if (!value || value.length === 0) {
        return validationMessages.locationsRequired;
      }
      break;
    case 'locationId':
      if (required && (!value || value.length === 0)) {
        return validationMessages.locationIdRequired;
      }
      break;
    case 'discountCode':
      if (value.length > 500) {
        return validationMessages.discountCode;
      }
      break;
    case 'avaTypeName':
      if (required && !value.trim().length) {
        return validationMessages.avaTypeNameMissing;
      }
      if (value.length > 50) {
        return validationMessages.avaTypeNameTooLong;
      }
      break;
    case 'avaShortName':
      if (value.length > 3) return validationMessages.avaShortNameTooLong;
      break;
    case 'avaCountHours':
      if (!value.length || !!~value.search('_')) return validationMessages.avaCountHoursIncorrectFormat;
      if (value === '00:00') return validationMessages.avaCountHoursZeros;
      break;
    case 'purposes':
      if (value.length === 0) return validationMessages.purposesRequired;
      break;
    case 'timeRanges': {
      const [start, end] = value.split('-');
      if (!validateTimeFormat(start) || !validateTimeFormat(end) || start === end)
        return validationMessages.timeFormatInvalid;
      break;
    }
    case 'selectedJobTitle': {
      if (!value?.id) return validationMessages.jobTitleIdRequired;
      const jobTitlesIds = (valueToCompare || []).map(({ job_title: jobTitle }) => jobTitle.id);
      if (!jobTitlesIds.includes(value.id)) return validationMessages.jobTitleIdRequired;
      break;
    }
    case 'nip': {
      if (!value.length) return validationMessages.nipRequired;
      if (value.length !== 10) return validationMessages.nipLength;
      if (!validateNip(value)) return validationMessages.nipInvalid;
      break;
    }
    case 'zipCode': {
      if (!value.length) return validationMessages.zipCodeRequired;
      break;
    }
    case 'budgetMetricName': {
      if (required && !value.trim().length) {
        return validationMessages.budgetMetricNameRequired;
      }
      if (value.length > 30) {
        return validationMessages.budgetMetricNameTooLong;
      }
      break;
    }
    case 'budgetMetricType': {
      if (required && !value.trim().length) {
        return validationMessages.budgetMetricTypeRequired;
      }
      if (!['number', 'percent', 'unit', 'currency', 'hour'].includes(value)) {
        return validationMessages.budgetMetricTypeInvalid;
      }
      break;
    }
    case 'timeInterval': {
      if (required && value === undefined) {
        return validationMessages.timeIntervalRequired;
      }
      if (![15, 30, 60, 1440].includes(value)) {
        return validationMessages.budgetMetricNameTooLong;
      }
      break;
    }
    case 'settings': {
      if (required && !value.aggregation) {
        return validationMessages.aggregationRequired;
      }
      if (!AGGREGATION_OPTIONS_VALUES.includes(value.aggregation)) {
        return validationMessages.aggregationOptionInvlaid;
      }
      break;
    }
    case 'amount50':
    case 'amount100': {
      if (!isHourFulfilled(value)) return validationMessages.timeInputRequired;
      break;
    }
    case 'contractStartDate': {
      if (value.some(({ isTemp, start_date: startDate }) => isTemp && !startDate))
        return validationMessages.contractStartDateMustBeProvided;
      break;
    }
    case 'declaredSelectedEmployees': {
      if (inputValue > valueToCompare) return validationMessages.selectedEmployeesLessNumberThanDeclared;
      break;
    }

    case 'declaredMoreThanSelectedEmployees': {
      if (inputValue < valueToCompare) return validationMessages.selectedEmployeesMoreNumberThanDeclared;
      break;
    }
    case 'selectedLocationGroupsIds': {
      if (!inputValue.length) return validationMessages.locationGroupsMustBeAtLeastOneSelected;
      break;
    }
    case 'employmentConditionsIds': {
      if (!inputValue.length) return validationMessages.employmentConditionsMustBeSelectedAtLeastOnce;
      break;
    }
    default:
      return '';
  }
  return '';
};

const EMAIL = 'email';
const PASSWORD = 'password';
const NAME = 'name';
const LAST_NAME = 'lastName';
const PHONE_2 = 'phone2';
const COMPANY_NAME = 'companyName';

export const inputHasErrors = (name, { email, password, name: userNameError, lastName, phone2, companyName }) => {
  switch (name) {
    case EMAIL:
      return !!email;
    case PASSWORD:
      return !!password;
    case NAME:
      return !!userNameError;
    case LAST_NAME:
      return !!lastName;
    case PHONE_2:
      return !!phone2;
    case COMPANY_NAME:
      return !!companyName;
    default:
      return false;
  }
};
