import TopBarPayroll from '@/components/TopBars/TopBarPayroll.jsx';
import EmployeePayrollTableContainer from '@/containers/employee/payroll/EmployeePayrollTableContainer.js';

import HelpModal from './EmployeePayrollHelpModal.jsx';

const PayrollView = () => (
  <div>
    <TopBarPayroll isEmployee />
    <HelpModal />
    <EmployeePayrollTableContainer />
  </div>
);

export default PayrollView;
