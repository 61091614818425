import { connect } from 'react-redux';

import {
  changeEndRounding,
  changeEndRoundingType,
  changeEndTolerance,
  changePayoutSetting,
  changePayrollSettings,
  changeRoundingSetting,
  changeStartRounding,
  changeStartRoundingType,
  changeStartTolerance,
  changeTimeFormatSettings,
  savePayrollSettings,
} from '@/actions/payrollSettings.js';
import { togglePayrollSettingsModal } from '@/actions/payrollUI.js';
import OLDPayrollSettingsModal from '@/components/payroll/OLDPayrollSettingsModal.jsx';

const mapStateToProps = state => ({
  settings: state.reducer.payrollSettings,
  payrollUI: state.reducer.payrollUI,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  changeStartRounding,
  changeStartRoundingType,
  changeEndRounding,
  changeEndRoundingType,
  changeStartTolerance,
  changeEndTolerance,
  changePayoutSetting,
  changeRoundingSetting,
  togglePayrollSettingsModal,
  changePayrollSettings,
  changeTimeFormatSettings,
  savePayrollSettings,
};

const PayrollSettingsModalContainer = connect(mapStateToProps, mapDispatchToProps)(OLDPayrollSettingsModal);

export default PayrollSettingsModalContainer;
