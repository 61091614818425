import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import TimeRangeInput from './TimeRangeInput.jsx';
import TimeSingleInput from './TimeSingleInput.jsx';

const compareTimes = (time, sourceTime) => {
  for (let i = 0; i < time.length; i++) {
    if (time[i] !== sourceTime[i]) {
      if (sourceTime[i] !== '_') return false;
    }
  }
  return true;
};

class TimeInput extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.showTips = this.showTips.bind(this);
    this.hideTips = this.hideTips.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.container = {};
    this.onKeyDownFunc = this.onKeyDownFunc.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getInitialState() {
    const value = this.props.short ? '__:__' : '__:__-__:__';
    return {
      showTips: false,
      edited: false,
      value: this.props.initialValue ? this.props.initialValue : value,
      selected: this.props.initialValue ? this.props.initialValue : value,
    };
  }

  componentDidMount() {
    this.container.addEventListener('keydown', this.onKeyDownFunc);
    setTimeout(() => {
      if (this.input && this.props.autoFocus) {
        this.input.input.input.focus();
        this.showTips();
      }
    }, 100);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue && nextProps.initialValue) {
      this.setValue(nextProps.initialValue);
    }
  }
  componentWillUnmount() {
    this.container.removeEventListener('keydown', this.onKeyDownFunc);
  }

  onKeyDownFunc(e) {
    const { key } = e;
    if (!this.props.options) return;
    if (key === 'ArrowRight') {
      this.hideTips();
      this.input.input.input.blur();
      e.stopPropagation();
      e.preventDefault();
      this.setValue(this.state.selected);
      return;
    }
    if (key === 'ArrowDown' || key === 'ArrowUp') {
      const options = this.state.edited
        ? this.props.options.filter(time => compareTimes(time, this.state.value))
        : this.props.options;
      if (key === 'ArrowDown') {
        let selected = options.findIndex(option => option === this.state.selected) + 1;
        if (selected > options.length - 1) selected = options.length - 1;
        this.setState({
          selected: options[selected],
        });
      } else {
        let selected = options.findIndex(option => option === this.state.selected) - 1;
        if (selected < -1) selected = -1;
        let value = options[selected];
        if (selected === -1) value = this.props.short ? '__:__' : '__:__-__:__';
        this.setState({
          selected: value,
        });
      }
    }
  }

  setValue(value) {
    if (value !== '' && value !== this.state.value) {
      this.setState({ value, edited: true });
      this.props.onChange(value);
    }
  }

  showTips() {
    this.setState({ showTips: true });
  }

  hideTips() {
    setTimeout(() => {
      this.setState({ showTips: false, edited: false });
    }, 120);
  }

  handleBlur(event) {
    this.hideTips();

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  inputChanged(e) {
    let { value } = e.target;
    if (this.props.autocomplete && this.props.options) {
      if (value.split('').filter(c => c === '_').length < this.state.value.split('').filter(c => c === '_').length) {
        const options = this.props.options.filter(time => compareTimes(time, value));
        if (options.length === 1) {
          value = options[0];
        }
      }
    }
    this.setValue(value);
  }

  render() {
    return (
      <div
        style={Object.assign({}, this.props.style)}
        className={this.props.className}
        ref={ref => {
          this.container = ref;
        }}
      >
        {this.props.short ? (
          <TimeSingleInput
            onChange={this.inputChanged}
            onClick={this.showTips}
            onBlur={this.handleBlur}
            value={this.state.value}
            error={this.props.error}
            modifiers={this.props.modifiers}
            max={this.props.max}
            ref={ref => {
              this.input = ref;
            }}
          />
        ) : (
          <TimeRangeInput
            onChange={this.inputChanged}
            onClick={this.showTips}
            onBlur={this.handleBlur}
            value={this.state.value}
            error={this.props.error}
            modifiers={this.props.modifiers}
            ref={ref => {
              this.input = ref;
            }}
          />
        )}
        {this.state.showTips && this.props.options ? (
          <div className="k-timeInput__itemContainer">
            {this.props.options
              .filter(time => {
                if (!this.state.edited) return true;
                return compareTimes(time, this.state.value);
              })
              .map((time, i) => {
                const itemClassname = classnames('k-timeInput__item', {
                  'k-timeInput__item--selected': this.state.selected === time,
                });
                return (
                  <div
                    role="presentation"
                    className={itemClassname}
                    key={i}
                    onClick={() => {
                      this.setValue(time);
                    }}
                  >
                    {time}
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    );
  }
}

TimeInput.propTypes = {
  initialValue: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  autocomplete: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.shape({}),
  max: PropTypes.string,
  autoFocus: PropTypes.bool,
  short: PropTypes.bool,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

export default TimeInput;
