import { connect } from 'react-redux';

import ScheduleBudgetTableSpmhCell from './ScheduleBudgetTableSpmhCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  estimate: state.reducer.schedule.budget.budgetEstimates.dailyValues[ownProps.date],
  showHolidaysSetting: state.reducer.schedule.viewSettings.holidays.value,
  today: state.reducer.mainDateStore.today,
  permissions: state.reducer.userPermissions.permissions,
  sumHours: state.reducer.schedule.budget.shiftsSummary[ownProps.date]?.totalMinutes,
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
});

export default connect(mapStateToProps)(ScheduleBudgetTableSpmhCell);
