import PropTypes from 'prop-types';

import './MDComment.scss';

const MDComment = props => {
  const { name, label, value, onChange, placeholder } = props;

  return (
    <div className="mdComment">
      <fieldset>
        <label className="mdComment__label" htmlFor="komentarz">
          {label}
        </label>
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          id="komentarz"
          className="mdComment__textarea"
          rows="5"
          placeholder={placeholder}
        />
      </fieldset>
    </div>
  );
};

MDComment.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
};

export default MDComment;
