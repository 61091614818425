import './ModalLoading.scss';

const ModalLoading = () => (
  <div className="modalLoding">
    <div className="k-spinner k-spinner--modal">
      <img src="/img/loading-spin.svg" alt="loader" />
    </div>
  </div>
);

export default ModalLoading;
