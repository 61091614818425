import browserHistory from '@/constants/browserHistory';
import { allNavs, DASHBOARD_PATH, ROUTE_IDS } from '@/utils/routes';
import { findFirstUnrestrictedNav } from '@/utils/routesHelpers';

import { setCurrentNavBarElementId } from './uiState.js';

export const onRouteChange = location => dispatch => {
  const menuPath = allNavs().find(nav => {
    const viewName = location.pathname.split('/')[1];
    return viewName && nav.path.includes(viewName);
  });
  if (menuPath) {
    dispatch(setCurrentNavBarElementId(menuPath.id));
  } else if (location.pathname === '/') {
    dispatch(setCurrentNavBarElementId(ROUTE_IDS.DASHBOARD));
  }
};

export const onRouteEnter = nextState => dispatch => {
  dispatch(onRouteChange(null, nextState));
};

export const redirectToFirstPossibleView = () => (dispatch, getState) => {
  const { userPermissions } = getState().reducer;
  const { restrictions, permissions } = userPermissions;
  const navs = allNavs(permissions);
  const firstUnrestrictedNav = findFirstUnrestrictedNav(navs, restrictions, DASHBOARD_PATH);
  if (firstUnrestrictedNav) browserHistory.push(firstUnrestrictedNav.path);
};
