export enum CustomFieldType {
  boolean = 'boolean',
  text = 'text',
  number = 'number',
  date = 'date',
  select = 'select',
  dateRange = 'dateRange',
  dateTime = 'dateTime',
  dateTimeRange = 'dateTimeRange',
}
export type CustomFieldValue = string | number | boolean | number[] | { from: string; to: string };

export interface CustomFieldDefinitionBase {
  id: string;
  company_id: string;
  name: string;
  key: string;
  type: CustomFieldType;
  is_required: boolean;
}

export type CustomFieldSettings = CustomFieldSelectSettings | null;

export interface CustomFieldDefinition extends CustomFieldDefinitionBase {
  settings: CustomFieldSettings;
}

export interface CustomFieldSelectOption {
  id: number;
  label: string;
}

export interface CustomFieldSelectSettings {
  options: CustomFieldSelectOption[];
  maxSelectedOptions: number;
}

export interface UserCustomFields {
  [fieldName: string]: CustomFieldValue;
}

export interface UsersCustomFieldsData {
  [employeeId: string]: UserCustomFields;
}

export interface CustomFieldDefinitionData {
  custom_fields_definitions: CustomFieldDefinition[];
}

export interface ParsedCustomField {
  id: string;
  isRequired: number;
  key: string;
  name: string;
  type: CustomFieldType;
  value: CustomFieldValue;
  settings?: CustomFieldSelectSettings;
}
