import { EmployeeEmploymentCondition } from '@/types/employmentConditions.types';

export const checkIsEmployeeHired = (
  { employment_conditions }: { employment_conditions: EmployeeEmploymentCondition },
  date: EmployeeEmploymentCondition['hire_date'],
) => {
  const { hire_date, release_date } = employment_conditions;
  const startedWorking = hire_date ? hire_date <= date : true;
  const endedWorking = release_date ? date > release_date : false;
  return startedWorking && !endedWorking;
};
