import PropTypes from 'prop-types';
import { Component } from 'react';

import { MDTextArea,MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers';

import { getInitialState,messages } from './CustomNotificationsModalHelper';

class CustomNotificationsModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = getInitialState();
    bindPrototypeFunctions(this);
  }

  submit() {
    return this.validateAll().then(valid => {
      if (!valid) return;
      const { notificationTitle, notificationMessage } = this.state;
      const { relevantEmployees, sendCustomNotification } = this.props;
      sendCustomNotification({ title: notificationTitle, message: notificationMessage, employees: relevantEmployees });
      this.hideAndClear();
    });
  }

  handleInputChange(event) {
    const { type, checked, value, name } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    this.validateInput(event);
    this.setState({ [name]: newValue });
  }

  validateInput(event) {
    validateInputHelper(event).then(res => {
      const [name, error] = res;
      this.setState({
        errors: { ...this.state.errors, [name]: error ? this.context.intl.formatMessage(error, {}) : error },
      });
    });
  }

  async validateAll() {
    const inputs = ['notificationTitle', 'notificationMessage'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  hideAndClear() {
    this.props.onHide();
    this.setState(getInitialState());
  }

  render() {
    const { handleInputChange, validateInput, state, props, context } = this;
    const { errors } = state;

    return (
      <MDKadroModal
        confirmText={context.intl.formatMessage(messages.send, {})}
        show={this.props.showModal}
        title={context.intl.formatMessage(messages.sendNotification, {})}
        onHide={this.hideAndClear}
        onSubmit={this.submit}
        modifiers={['narrow']}
        inputAwareSubmit
      >
        <MDTextInput
          value={state.notificationTitle}
          type="text"
          onChange={handleInputChange}
          onBlur={validateInput}
          id="notificationTitle"
          label={context.intl.formatMessage(messages.messageTitle, {})}
          errorMessage={errors.notificationTitle}
          modifiers={['modal']}
        />
        <MDTextArea
          value={state.notificationMessage}
          onChange={handleInputChange}
          onBlur={validateInput}
          name="notificationMessage"
          label={context.intl.formatMessage(messages.messageText, {})}
          id="notificationMessage"
          errorMessage={errors.notificationMessage}
        />
        {context.intl.formatMessage(messages.noInfo, { empNo: props.relevantEmployees.length })}
      </MDKadroModal>
    );
  }
}

CustomNotificationsModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CustomNotificationsModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  relevantEmployees: PropTypes.arrayOf(PropTypes.string),
  sendCustomNotification: PropTypes.func,
};

export default CustomNotificationsModal;
