import { productionQuotasModesIds } from '@/constants/viewDateModes.ts';

import TopBarCalendar from './filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarLocationFilter from './filters/TopBarLocationFilter/TopBarLocationFilter.redux.js';
import TopBar from './TopBar.jsx';

const TopBarProductionQuotas = () => (
  <TopBar
    leftElements={[
      <TopBarCalendar key="calswitch" modes={productionQuotasModesIds} />,
      <TopBarLocationFilter key="locationFilter" />,
    ]}
    rightElements={[]}
  />
);

TopBarProductionQuotas.propTypes = {};

export default TopBarProductionQuotas;
