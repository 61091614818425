import { PAYROLL_SETTINGS_EDIT_DISABLE } from '@/constants/Restrictions';

export const userHasPermissionsToRoute = (userPermissions, route) =>
  route?.userPermission
    ? (!route.allPermissionsRequired &&
        route.userPermission.some(permission => userPermissions.permissions.includes(permission))) ||
      (route.allPermissionsRequired &&
        route.userPermission.every(permission => userPermissions.permissions.includes(permission)))
    : true;

export const userHasRestrictedPayrollSettingsEdit = userPermissions =>
  !userPermissions.roles.includes('ROLE_OWNER') && userPermissions.restrictions.includes(PAYROLL_SETTINGS_EDIT_DISABLE);

export const filterBasedOnPermissions = (items, userPermissions, permissionsKey = 'permissions') =>
  items.filter(item => {
    if (!item?.[permissionsKey]) return true;
    return item[permissionsKey].some(permission => userPermissions.includes(permission));
  });
