import { connect } from 'react-redux';

import {
   loginWithGoogleOAuth,
  moveToOnboardingStepTwoRegistrationByOAuth,
} from '@/actions/auth.jsx';

import GoogleButton from './GoogleButton';

const mapDispatchToProps = {
  loginWithGoogleOAuth,
  moveToOnboardingStepTwoRegistrationByOAuth,
};

export default connect(null, mapDispatchToProps)(GoogleButton);
