import xlsx from 'xlsx';

export default class XLSExport {
  constructor() {
    this.url = null;
    this.workBook = xlsx.utils.book_new();
    this.workBook.Props = {
      Title: 'Kadromierz export',
      Subject: '',
      Author: 'Kadromierz',
      CreatedDate: new Date(),
    };
  }
  aoaToXLS(data, sheetName, formating, fileType = 'xlsx') {
    this.generateSheet(data, sheetName, formating);
    this.createDownloadUrl(fileType);
    return this.url;
  }

  aoaToXlsMultipleSheets(sheets, formating, fileType = 'xlsx', omitRows) {
    sheets.forEach(sheet => {
      this.generateSheet(sheet.data, sheet.name, formating, omitRows);
    });
    this.createDownloadUrl(fileType);
    return this.url;
  }

  generateSheet(data, sheetName, formating, omitRows = { start: undefined, end: 0 }) {
    const defaultIndex =  data[1]?.length ? 1 : 3;
    const startIndex = omitRows.start ? omitRows.start : defaultIndex;
    const endIndex = data.length - omitRows.end;
    this.workBook.SheetNames.push(sheetName);
    const ws = xlsx.utils.aoa_to_sheet(data);
    if (formating)
      /* 
        d - Date
        t - Time
        n - Number
        dp - Decimal Placees
        https://github.com/SheetJS/sheetjs#cell-object
      */
      formating.forEach((format, index) => {
        switch (format) {
          case 'd': {
            for (let i = startIndex; i < endIndex; i++) {
              const column = String.fromCharCode(65 + index);
              ws[column + (i + 1)].z = 'DD-MM-YYYY';
              ws[column + (i + 1)].t = 'n';
              const date = new Date(ws[column + (i + 1)].v);
              ws[column + (i + 1)].v = date.getTime() / (86400 * 1000) + (25567 + 2);
            }
            break;
          }
          case 't': {
            for (let i = startIndex; i < endIndex; i++) {
              const column = String.fromCharCode(65 + index);
              const date = new Date(`1989-01-01 ${ws[column + (i + 1)].v}`);
              if (Number.isNaN(date.getTime())) continue;
              ws[column + (i + 1)].z = 'HH:MM';
              ws[column + (i + 1)].t = 'n';

              ws[column + (i + 1)].v = date.getTime() / (86400 * 1000) + (25567 + 2) + 1 / 24;
            }
            break;
          }
          case 'n': {
            for (let i = startIndex; i < endIndex; i++) {
              const column = String.fromCharCode(65 + index);
              ws[column + (i + 1)].t = 'n';
            }
            break;
          }
          case 'dp': {
            for (let i = startIndex; i < endIndex; i++) {
              const column = String.fromCharCode(65 + index);
              ws[column + (i + 1)].z = '0.00';
            }
            break;
          }
          default:
            break;
        }
      });
    this.workBook.Sheets[sheetName] = ws;
  }

  createDownloadUrl(fileType) {
    const wbout = xlsx.write(this.workBook, { bookType: fileType, type: 'binary' });
    const blob = new Blob([this.binary2ocet(wbout)], { type: 'octet/stream' });
    this.url = window.URL.createObjectURL(blob);
  }

  download(fileName) {
    const link = document.createElement('a');
    link.setAttribute('href', this.url);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  binary2ocet(data) {
    const buf = new ArrayBuffer(data.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf); // create uint8array as viewer
    for (let i = 0; i < data.length; i++) view[i] = data.charCodeAt(i) & 0xff; // convert to octet
    return buf;
  }
}
