import PropTypes from 'prop-types';

import { payrollTableColumns } from '@/constants/tableColumns.jsx';

const PayrollTableSummaryRow = (props, context) => {
  const items = [
    <td key={0} className="sum_blank" />,
    <td key={1} className="sum_blank" />,
    <td key={2} className="sum_blank" />,
    <td key={3} className="sum_blank" />,
    <td key={4} className="sum_blank" />,
    <td key={5} className="sum_blank" />,
    <td key={6} className="sum_blank" />,
    <td key="sumBreaks" className="imptnt">
      {props.sumBreaks}
    </td>,
    <td key="absences" className="imptnt">
      {props.sumAbsences}
    </td>,
    <td key="sumHours" className="imptnt">
      {props.sumHours}
    </td>,
    <td key="sumHoursReal" className="imptnt">
      {props.sumHoursReal} / {props.sumHoursPlanned}
    </td>,
    <td key="sumPlannedDiff" className="imptnt">
      {props.sumPlannedDiff}
    </td>,
    <td key="sumNightHours" className="imptnt">
      {props.sumNightHours}
    </td>,
    <td key="sumQuotas" className="imptnt">
      {props.sumQuotas.toFixed(2)}
    </td>,
    <td key="sumBonuses" className="imptnt">
      {props.sumBonuses}
    </td>,
    <td key="sumOvertime50" className="imptnt">
      {props.sumOvertime50}
    </td>,
    <td key="sumOvertime100" className="imptnt">
      {props.sumOvertime100}
    </td>,
    <td key="sumOvertime" className="imptnt">
      {props.sumedScheduleCycleOvertime}
    </td>,
    <td key="sumPayout" className="imptnt">
      {props.sumPayout}
    </td>,
  ];
  return (
    <tr>
      <td colSpan={10} style={{ padding: 0 }}>
        <table className="table">
          <colgroup>
            {payrollTableColumns.colgroup.map((width, i) => {
              if (props.visibleColumns.includes(i)) return <col key={i} style={{ width: `${width}%` }} />;
              return null;
            })}
          </colgroup>
          <thead>
            <tr key="summaryRow" className="summaryrow">
              {payrollTableColumns.summary.map((header, i) => {
                if (props.visibleColumns.includes(i)) {
                  if (!header) return <th key={i} className="sum_blank" />;
                  return <th key={i}>{context.intl.formatMessage(header, {})}</th>;
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            <tr key="summaryRowBottom">
              {items.map((item, i) => {
                if (props.visibleColumns.includes(i)) return item;
                return null;
              })}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

PayrollTableSummaryRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollTableSummaryRow.propTypes = {
  sumHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumHoursReal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumHoursPlanned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumPlannedDiff: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  sumQuotas: PropTypes.number,
  sumBonuses: PropTypes.number,
  sumPayout: PropTypes.number,
  sumBreaks: PropTypes.string,
  sumNightHours: PropTypes.string,
  visibleColumns: PropTypes.arrayOf(PropTypes.number),
  sumOvertime50: PropTypes.string,
  sumOvertime100: PropTypes.string,
  sumedScheduleCycleOvertime: PropTypes.number,
  sumAbsences: PropTypes.number,
};

export default PayrollTableSummaryRow;
