import { connect } from 'react-redux';

import { deleteUserEvent } from '@/actions';
import { showModal } from '@/actions/uiState.js';
import ScheduleEventsTable from '@/components/scheduler/Event/ScheduleEventsTable.jsx';

const mapStateToProps = state => ({
  scheduleUIState: state.reducer.scheduleUIState,
  holidays: state.reducer.calendarData.holidays,
  dateStore: state.reducer.mainDateStore,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userEvents: state.reducer.userEvents,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  deleteUserEvent,
};

const ScheduleEventsTableContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleEventsTable);

export default ScheduleEventsTableContainer;
