import { defineMessages } from 'react-intl';

import * as actionTypes from '@/constants/ActionTypes';

import { conn } from './index';

const messages = defineMessages({
  successToast: {
    id: 'toastr.successTitles',
    defaultMessage: 'Sukces!',
  },
  errorToast: {
    id: 'toastr.errorTitles',
    defaultMessage: 'Błąd!',
  },
  invoiceDataHasBeenChanged: {
    id: 'payments.changeInvoiceData.success',
    defaultMessage: 'Dane do faktury zostały zmienione.',
  },
  invoiceDataError: {
    id: 'payments.changeInvoiceData.error',
    defaultMessage: 'Wystąpił błąd podczas zmiany danych do faktury.',
  },
});

export const getPaymentData = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { currentCompany } = getState().reducer;
    conn
      .getPaymentData(currentCompany.id)
      .then(result => {
        resolve(result.data);
        dispatch({ type: actionTypes.GET_PAYMENT_DATA_SUCCESSFULL, payload: result.data });
      })
      .catch(err => {
        console.warn(err);
        reject(err);
      });
  });

export const toggleNotification = toggle => dispatch => {
  dispatch({ type: actionTypes.CHANGE_PAYMENT_NOTIFICATION_DATA, payload: toggle });
};

export const payForInvoice = invoiceId => () =>
  new Promise((resolve, reject) => {
    conn
      .payForInvoice(invoiceId)
      .then(result => {
        resolve(result.data);
      })
      .catch(err => {
        console.warn(err);
        reject(err);
      });
  });

export const getInvoicePdf = invoiceId => () =>
  new Promise((resolve, reject) => {
    conn
      .getInvoicePdf(invoiceId)
      .then(result => {
        resolve(result.data);
      })
      .catch(err => {
        console.warn(err);
        reject(err);
      });
  });

export const editPaymentData = invoiceData => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    const { currentCompany } = getState().reducer;
    conn
      .editPaymentData({ companyId: currentCompany.id, invoiceData })
      .then(result => {
        resolve(result.data);
        dispatch({
          type: actionTypes.CHANGE_PAYMENT_DATA_SUCCESSFULL,
          payload: invoiceData,
          notification: {
            type: 'success',
            title: intl.formatMessage(messages.successToast),
            description: intl.formatMessage(messages.invoiceDataHasBeenChanged),
          },
        });
      })
      .catch(err => {
        console.warn(err);
        reject(err);
        dispatch({
          type: actionTypes.CHANGE_PAYMENT_DATA_FAILED,
          payload: invoiceData,
          notification: {
            type: 'error',
            title: intl.formatMessage(messages.errorToast),
            description: intl.formatMessage(messages.invoiceDataError),
          },
        });
      });
  });
