import moment from 'moment';
import { useMemo } from 'react';

import { ActivityLog, ActivityLogDisplayData } from '@/types/activityLog.types.ts';
import { getActivityLogObjectChanges } from '@/utils/activityLogHelpers.tsx';
import { getEditorName, getIcon, getMessage } from '@/utils/objectHistoryHelpers.jsx';

interface ObjectHistoryProps {
  displayData: ActivityLogDisplayData;
  log: ActivityLog;
}

const ObjectHistory = ({ displayData, log }: ObjectHistoryProps) => {
  const date = useMemo(() => moment(log.created_at), [log.created_at]);
  const changes = useMemo(() => getActivityLogObjectChanges(log, displayData), [log, displayData]);
  return (
    <div className="objectHistoryModal__item">
      <div className="objectHistoryModal__date">
        <div className="objectHistoryModal__hour">{date.format('HH:mm')}</div>
        <div className="objectHistoryModal__day">{date.format('D MMM')}</div>
      </div>
      <div className="objectHistoryModal__accent">
        <div className="objectHistoryModal__historyIcon">
          <span className="material-icons">{getIcon(log)}</span>
        </div>
      </div>
      <div className="objectHistoryModal__info">
        <div className="objectHistoryModal__user">{getEditorName(log, displayData.employees)}</div>
        <div className="objectHistoryModal__message">{getMessage(log)}</div>
        <div className="objectHistoryModal__changes">{changes}</div>
      </div>
    </div>
  );
};

export default ObjectHistory;
