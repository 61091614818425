import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import { MORE_LABELS_BACKGROUND } from '@/constants/colors.js';

import './LabelsContainer.scss';

const LabelsContainer = props => {
  const { children, limit, moreActionDisabled = false } = props;
  const hidden = children.length - limit;

  const buttonClassName = classnames({
    'k-labelsContainer__button--notActive': moreActionDisabled,
  });

  return (
    <div className="k-labelContainer">
      {children.slice(0, limit)}
      {hidden > 0 && (
        <button onClick={() => !moreActionDisabled && props.moreAction()} className={buttonClassName}>
          <ColorLabel key="moreLables" text={`+${hidden} ...`} color={MORE_LABELS_BACKGROUND} />
        </button>
      )}
    </div>
  );
};

LabelsContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  limit: PropTypes.number,
  moreAction: PropTypes.func,
  moreActionDisabled: PropTypes.bool,
};

export default LabelsContainer;
