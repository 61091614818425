import { defineMessages } from 'react-intl';

export const tooltipMessages = defineMessages({
  addContract: {
    id: 'companyManage.employees.massEditSummary.copyIncorrectEmployeesForAddContracts',
    defaultMessage: 'Kliknij, aby skopiować imiona\ni nazwiska pracowników, dla których nie utworzy się nowy kontrakt',
  },
  remove: {
    id: 'companyManage.employees.massEditSummary.copyIncorrectEmployeesForDeleteContracts',
    defaultMessage: 'Kliknij, aby skopiować imiona\ni nazwiska pracowników posiadających tylko jeden kontrakt',
  },
  editContractsStartDate: {
    id: 'companyManage.employees.massEditSummary.copyIncorrectEmployeesForEditContractsStartDate',
    defaultMessage:
      'Kliknij, aby skopiować imiona\ni nazwiska pracowników, dla których nie zostanie zmieniona data rozpoczęcia kontraktu',
  },
});
