import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import moment from 'moment';

import { DAY_DIVIDED_IN_15_MINUTES } from '@/constants/budgetMetrics';

import { roundToTwoSigDigits } from './baseHelpers';
import { budgetMetricHash } from './budgetHelpers';
import { getTimeFormatFromHour } from './dateHelper';
import { getNumberOfShiftsPer15Minutes, getShiftsForCurrentDay } from './schedulerHelpers';

export const groupEmployeesAmountForHourByEmployeeTitle = (recommendedScheduleByDate, recommendedScheduleValue) =>
  recommendedScheduleValue && recommendedScheduleValue.jobTitlesData
    ? Object.keys(recommendedScheduleValue.jobTitlesData).reduce(
        (jobTitlesData, jobId) => ({
          ...jobTitlesData,
          [jobId]: {
            ...(recommendedScheduleByDate && recommendedScheduleByDate[recommendedScheduleValue.date]
              ? recommendedScheduleByDate[recommendedScheduleValue.date][jobId]
              : {}),
            ...jobTitlesData[jobId],
            [recommendedScheduleValue.hour]: recommendedScheduleValue.jobTitlesData[jobId],
          },
        }),
        {},
      )
    : {};

export const groupRecommendedScheduleByDate = recommendedScheduleData =>
  recommendedScheduleData
    ? recommendedScheduleData.reduce(
        (recommendedScheduleByDate, val) => ({
          ...recommendedScheduleByDate,
          [val.date]: {
            ...recommendedScheduleByDate[val.date],
            ...groupEmployeesAmountForHourByEmployeeTitle(recommendedScheduleByDate, val),
          },
        }),
        {},
      )
    : {};

export const adjustRecommendedScheduleFormat = (groupedRecommendedSchedule, locationId) =>
  groupedRecommendedSchedule
    ? Object.entries(groupedRecommendedSchedule).map(val => ({
        date: val[0],
        location_id: locationId,
        template: Object.keys(val[1]).reduce(
          (template, v) => template.concat({ job_title_id: v, values: val[1][v] }),
          [],
        ),
      }))
    : [];

export const groupAndAdjustRecommendedSchedule = (data, locationId) =>
  adjustRecommendedScheduleFormat(groupRecommendedScheduleByDate(data), locationId);

export const filterScheduleByEmployeesJobTitle = (recommendedScheduleForDay, selectedJobTitles) => {
  if (!recommendedScheduleForDay) return {};
  const { template } = recommendedScheduleForDay;
  const selectedJobTitlesIds = (selectedJobTitles || []).map(obj => obj.id);
  const filteredTemplate = (template || []).filter(val => selectedJobTitlesIds.includes(val.job_title_id));
  return { ...recommendedScheduleForDay, template: filteredTemplate };
};

export const getRecommendedScheduleForDate = (recommendedScheduleData, locationId, date, selectedJobtitles) => {
  if (!recommendedScheduleData) return {};
  const recommendedScheduleForDay =
    recommendedScheduleData.find(schedule => schedule.date === date && schedule.location_id === locationId) || {};
  return filterScheduleByEmployeesJobTitle(recommendedScheduleForDay, selectedJobtitles);
};

export const getEmploeesAmountForEachHour = recommendedScheduleForDay =>
  recommendedScheduleForDay && recommendedScheduleForDay.template
    ? recommendedScheduleForDay.template.reduce(
        (acc, val) => ({
          ...acc,
          ...Object.keys(val.values).reduce(
            (emploeesAmountArray, hour) => ({
              ...emploeesAmountArray,
              [hour]: (acc[hour] || 0) + parseFloat(val.values[hour]),
            }),
            {},
          ),
        }),
        {},
      )
    : {};

export const getNumberOfEmployeesForEveryHour = recommendedScheduleForDay => {
  const hourValues = getEmploeesAmountForEachHour(recommendedScheduleForDay);
  return Array.from({ length: 24 }, (_, h) => h).map(hour =>
    hourValues[getTimeFormatFromHour(hour)] ? hourValues[getTimeFormatFromHour(hour)] : 0,
  );
};

export const getNuberOfEmployeesForEveryMinute = recommendedScheduleForDay => {
  const hourValues = getEmploeesAmountForEachHour(recommendedScheduleForDay);
  return Array.from({ length: 24 }, (_, h) => h).map(hour =>
    Array.from({ length: 60 }, (_, m) => m).map(() =>
      hourValues[getTimeFormatFromHour(hour)] ? hourValues[getTimeFormatFromHour(hour)] : 0,
    ),
  );
};

export const getPointsForShifts = (date, relevantShifts, locationId, selectedJobtitles, absences) => {
  const shiftsForCurrentDay = getShiftsForCurrentDay(relevantShifts, locationId, date, selectedJobtitles);
  const numberOfShiftsPer15Minutes = getNumberOfShiftsPer15Minutes(shiftsForCurrentDay, absences);
  return numberOfShiftsPer15Minutes.map((val, i) => ({ x: i, ye: val }));
};

export const getPointsForShiftsAndEmployees = (
  date,
  recommendedSchedule,
  locationId,
  numberOfShiftsPer15Minutes,
  selectedJobtitles,
) => {
  const recommendedScheduleDataForCurrentDay = getRecommendedScheduleForDate(
    recommendedSchedule.recommendedSchedule,
    locationId,
    date,
    selectedJobtitles,
  );
  const numOfEmployeesForHours = getNumberOfEmployeesForEveryHour(recommendedScheduleDataForCurrentDay);

  return numberOfShiftsPer15Minutes.map(({ ye }, index) => ({
    x: index,
    ye,
    ys: numOfEmployeesForHours[Math.floor(index / 4)],
  }));
};

const getShiftDurationWithinTimestamps = (startTimestamp, endTimestamp, shift) => {
  const start = startTimestamp > shift.start_timestamp ? startTimestamp : shift.start_timestamp;
  const end = endTimestamp < shift.end_timestamp ? endTimestamp : shift.end_timestamp;

  return moment(end).diff(start, 'minutes');
};

export const getEstimatedBudgetPoints = (
  date,
  budgetEstimates,
  hourlyEstimates,
  locationSettings,
  locationId,
  shifts,
) => {
  const flatShifts = Object.values(shifts).flatMap(item => Object.values(item.shifts));
  const hoursArray = Array.from({ length: 24 }, (_, hour) => hour);
  if (locationSettings.budget_estimates_period === 'hour') {
    const estimatesForDate = hourlyEstimates.filter(estimate => moment(estimate.from).format('YYYY-MM-DD') === date);

    return hoursArray.map(hour => {
      const relevantEstimate = estimatesForDate.find(estimate => moment(estimate.from).hours() === hour);
      const startTimestamp = `${date} ${String(hour).padStart(2, '0')}:00:00`;
      const endTimestamp = moment(startTimestamp)
        .add(1, 'hour')
        .format('YYYY-MM-DD HH:mm:ss');
      const relevantShifts = flatShifts.filter(
        shift =>
          shift.location.id === locationId &&
          shift.start_timestamp <= endTimestamp &&
          shift.end_timestamp >= startTimestamp,
      );
      const plannedMinutes = relevantShifts.reduce(
        (sum, shift) => sum + getShiftDurationWithinTimestamps(startTimestamp, endTimestamp, shift),
        0,
      );
      const planSpmh =
        !relevantEstimate || !relevantEstimate.estimated_budget || !plannedMinutes
          ? 0
          : roundToTwoSigDigits(relevantEstimate.estimated_budget / 100 / (plannedMinutes / 60));

      return {
        x: hour,
        budgetEstimate: relevantEstimate?.estimated_budget || 0,
        planSpmh,
      };
    });
  }

  const estimateForDate = budgetEstimates.find(estimate => estimate.date === date);
  const plannedMinutes = flatShifts.reduce((sum, shift) => {
    if (moment(shift.start_timestamp).format('YYYY-MM-DD') === date && shift.location.id === locationId) {
      return sum + shift.duration;
    }

    return sum;
  }, 0);
  const planSpmh =
    !estimateForDate || !estimateForDate.estimated_budget || !plannedMinutes
      ? 0
      : roundToTwoSigDigits(estimateForDate.estimated_budget / 100 / (plannedMinutes / 60));

  return hoursArray.map(hour => ({
    x: hour,
    budgetEstimate: estimateForDate?.estimated_budget || 0,
    planSpmh,
  }));
};

export const filterAndSumRecommendedSchedule = (recommendedSchedule = {}, jobTitleIds = [], locationIds = []) =>
  Object.keys(recommendedSchedule)
    .filter(id => locationIds.includes(id))
    .reduce((sum, locationId) => {
      const recommendedScheduleForLocation = recommendedSchedule[locationId];
      if (!recommendedScheduleForLocation) return sum;
      const sumForLocation =
        recommendedScheduleForLocation.template
          .filter(valuesForJobTitle => jobTitleIds.includes(valuesForJobTitle.job_title_id))
          .reduce(
            (prev, item) => prev + Object.values(item.values).reduce((subSum, value) => subSum + parseInt(value), 0),
            0,
          ) * 60;
      return sum + sumForLocation;
    }, 0);

export const filterAndSumRecommendedScheduleForTimePeriod = (
  recommendedSchedule,
  jobTitleIds,
  locationIds,
  dateArray,
) =>
  dateArray.reduce(
    (sum, date) => sum + filterAndSumRecommendedSchedule(recommendedSchedule[date], jobTitleIds, locationIds),
    0,
  );

export const checkRecommendedScheduleDisplayPermissions = restrictions =>
  ![RESTRICTIONS.BUDGET_INFO_HIDE, RESTRICTIONS.RECOMMENDED_SCHEDULE_HIDE].some(restriction =>
    restrictions.includes(restriction),
  );

export const getBudgetMetricsPoints = (date, budgetMetrics, budgetMetricsData) =>
  Array.from({ length: DAY_DIVIDED_IN_15_MINUTES }, (_, minute) => minute).map(minute => ({
    x: minute,
    ...budgetMetrics.reduce((previousPoints, { id }) => {
      const dataHash = budgetMetricHash(id, date);
      const data = budgetMetricsData[dataHash];

      if (!data) return previousPoints;

      const divisionFactor = DAY_DIVIDED_IN_15_MINUTES / data.length;

      return {
        ...previousPoints,
        [id]: data[parseInt(minute / divisionFactor)] / 100,
      };
    }, {}),
  }));
