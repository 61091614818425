import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements';
import { getSizeOfDates } from '@/utils/shiftHelpers';

import OpenShiftRowTitle from '../OpenShiftRowTitle/OpenShiftRowTitle.redux';
import ScheduleTableOpenShiftCell from '../ScheduleTableOpenShiftCell/ScheduleTableOpenShiftCell.redux';

import './ScheduleTableOpenShift.scss';

const ScheduleTableOpenShiftRow = ({ dateArray, locationId, rowSelected, isOpenShiftsRowSticky, restrictions }) => {
  const openShiftRef = useRef(null);
  const [top] = useMultipleStickyElements(openShiftRef, 'scheduleTableHeader--sticky');

  const isEditDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const size = getSizeOfDates(dateArray.length);
  const className = cn('k-openShiftRow', 'scheduleTableHeader', {
    'k-openShiftRow--selected': rowSelected,
    'k-openShiftRow--unlocked': !isOpenShiftsRowSticky,
    'scheduleTableHeader--sticky': isOpenShiftsRowSticky,
  });

  return (
    <tr
      ref={openShiftRef}
      className={className}
      style={{
        top,
      }}
    >
      <OpenShiftRowTitle locationId={locationId} />
      {dateArray.map(date => (
        <ScheduleTableOpenShiftCell
          date={date}
          key={date}
          locationId={locationId}
          size={size}
          isEditDisabled={isEditDisabled}
        />
      ))}
    </tr>
  );
};
ScheduleTableOpenShiftRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableOpenShiftRow.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationId: PropTypes.string,
  isOpenShiftsRowSticky: PropTypes.bool,
  rowSelected: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};
export default ScheduleTableOpenShiftRow;
