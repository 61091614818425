import { RootState } from '../types';

export const selectShowNotificationPanel = (state: RootState) => state.reducer.uiState.showNotificationPanel;

export const selectCurrentActiveNavBarElementId = (state: RootState) =>
  state.reducer.uiState.currentActiveNavBarElementId;

export const selectShowModal = (state: RootState) => state.reducer.uiState.showModal;

export const selectModalObject = (state: RootState) => state.reducer.uiState.modalObject;

export const selectRearrangingEmployeesOrder = (state: RootState) => state.reducer.uiState.rearrangingEmployeesOrder;
