import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Tooltip from '@/components/common/Tooltip/Tooltip.jsx';

import Button from '../../Basic/Button.jsx';

import './ModalFooter.scss';

const ModalFooter = props => {
  const {
    errorMessage,
    cancelText,
    cancelBtnModifiers,
    confirmText,
    confirmBtnModifiers,
    hideConfirmButton,
    hideCancelButton,
    modifiers,
    options,
    onCancel,
    onSubmit,
    disableConfirm,
    disableCancel,
    withOverlayScrollContent,
  } = props;

  if (props.hideFooter) {
    return null;
  }

  const footerClassnames = classnames(
    'mdKadroModalFooter',
    ...modifiers.map(modifier => `mdKadroModalFooter--${modifier}`),
    { 'mdKadroModalFooter--scrollContent': withOverlayScrollContent },
  );

  const actionClassnames = classnames('mdKadroModalFooterAction', {
    'mdKadroModalFooterAction--smallerMargin': options.length === 1,
  });

  return (
    <>
      <div className={footerClassnames}>
        <div className="mdKadroModalFooter__group--left">
          <div className="mdKadroModalFooter__error" data-test="errorMessage">
            {errorMessage}
          </div>
          {options.map((option, index) => (
            <button
              disabled={option.disabled}
              className={actionClassnames}
              onClick={e => option.handleClick(e)}
              key={index}
            >
              {option.tooltip ? (
                <Tooltip content={option.tooltip}>
                  <div className="mdKadroModalFooterAction__contentWrapper">
                    <i className="material-icons mdKadroModalFooterAction__icon">{option.icon}</i>
                    <span className="mdKadroModalFooterAction__text">{option.text}</span>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <i className="material-icons mdKadroModalFooterAction__icon">{option.icon}</i>
                  <span className="mdKadroModalFooterAction__text">{option.text}</span>
                </>
              )}
            </button>
          ))}
        </div>
        <div className="mdKadroModalFooter__group--right">
          {!hideCancelButton && (
            <Button onClick={onCancel} modifiers={cancelBtnModifiers} disabled={disableCancel} testId="cancelButton">
              {cancelText}
            </Button>
          )}
          {!hideConfirmButton && (
            <Button onClick={onSubmit} modifiers={confirmBtnModifiers} disabled={disableConfirm} testId="confirmButton">
              {confirmText}
            </Button>
          )}
        </div>
      </div>
      {props.showProgressBar && (
        <>
          <div className="mdKadroModalFooter__progressText">
            {props.currentStep}/{props.stepsCount}
          </div>
          <div
            className="mdKadroModalFooter__progressBar"
            style={{ width: `calc(${(100 / props.stepsCount) * props.currentStep}%)` }}
          />
        </>
      )}
    </>
  );
};

ModalFooter.defaultProps = {
  cancelText: <FormattedMessage id="common.cancel" defaultMessage="Anuluj" />,
  confirmText: <FormattedMessage id="common.save" defaultMessage="Zapisz" />,
  hideFooter: false,
  options: [],
  hideConfirmButton: false,
  hideCancelButton: false,
  showProgressBar: false,
};

ModalFooter.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  confirmText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  cancelText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  confirmBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  cancelBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  modifiers: PropTypes.arrayOf(PropTypes.string),
  hideFooter: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      handleClick: PropTypes.func,
    }),
  ),
  hideConfirmButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  showProgressBar: PropTypes.bool,
  disableConfirm: PropTypes.bool,
  disableCancel: PropTypes.bool,
  withOverlayScrollContent: PropTypes.bool,
};

export default ModalFooter;
