import { useState } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import { getLoggedAccounts, LoggedAccount, storeLoggedAccounts } from '@/utils/accounts/accounts.utils';

type Props = {
  accounts: LoggedAccount[];
  addAccount: (email: string, password: string) => void;
  setAccounts: (newAccounts: any[]) => void;
  switchAccount: (userId: string) => void;
};
export const AccountSwitcher = ({ accounts, addAccount, setAccounts, switchAccount }: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      {accounts.map((account) => (
        <div key={account.userId}>
          <span>{account.email}</span>
          {account.active ? (
            'AKTYWNE'
          ) : (
            <Button
              modifiers="small white-orange"
              onClick={() => {
                switchAccount(account.userId);
              }}
            >
              Switch
            </Button>
          )}
          <Button
            modifiers="small white-orange"
            onClick={() => {
              const loggedAccounts = getLoggedAccounts();
              const filteredAccounts = loggedAccounts.filter((a) => a.userId !== account.userId);
              storeLoggedAccounts(filteredAccounts);
              setAccounts(filteredAccounts);
              if (account.active) {
                const firstUserId = accounts.find((a) => a.userId !== account.userId)?.userId;
                switchAccount(firstUserId);
              }
            }}
          >
            Usuń
          </Button>
        </div>
      ))}
      <div>
        <div>Dodaj konto</div>
        <div>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <Button
            modifiers="small white-orange"
            onClick={() => {
              if (!email || !password) return;
              if (accounts.some((account) => account.email === email)) return;
              addAccount(email, password);
              setEmail('');
              setPassword('');
            }}
          >
            Dodaj
          </Button>
        </div>
      </div>
    </>
  );
};
