import * as AT from '@/constants/ActionTypes.js';

const initialState = {};

const budgetMetricsData = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_BUDGET_METRIC_DATA_SUCCESS:
    case AT.UPDATE_BUDGET_METRIC_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default budgetMetricsData;
