import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages } from 'react-intl';

import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { ADD_DEVICE_MODAL } from '@/constants/modalTypes.js';
import { compareByKey } from '@/utils/baseHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import DevicesHeader from '../DevicesHeader/DevicesHeader.jsx';
import DevicesTableRow from '../DevicesTableRow/DevicesTableRow.redux.js';

import './DevicesTable.scss';

const messages = defineMessages({
  emptyStateLocationTitle: {
    id: 'companyManage.devicesTable.emptyStateLocationTitle',
    defaultMessage: 'Brak dodanych lokalizacji ',
  },
  emptyStateTitle: {
    id: 'companyManage.devicesTable.emptyStateTitle',
    defaultMessage: 'Brak dodanych urządzeń ',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.devicesTable.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateLocationMsg: {
    id: 'companyManage.devicesTable.emptyStateLocationMsg',
    defaultMessage: 'Aby dodać miejsce pracy dodaj najpierw lokalizację ',
  },
  emptyStateMsg: {
    id: 'companyManage.devicesTable.emptyStateMsg',
    defaultMessage: 'Aby dodać pierwsze urządzenie ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.devicesTable.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaLocationText: {
    id: 'companyManage.devicesTable.ctaLocationText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaText: {
    id: 'companyManage.devicesTable.ctaText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaFilterText: {
    id: 'companyManage.devicesTable.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

class DevicesTable extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.compareKeys = ['name', 'brand', 'model', 'authorized_at', 'locations'];

    bindPrototypeFunctions(this);
  }

  getEmptyStateView() {
    const showModal = !this.props.devices.length && !this.props.searchString.length;
    let headerText, descriptionText, ctaText, ctaHandler;
    if (showModal) {
      headerText = this.context.intl.formatMessage(messages.emptyStateTitle, {});
      descriptionText = this.context.intl.formatMessage(messages.emptyStateMsg, {});
      ctaText = this.context.intl.formatMessage(messages.ctaText, {});
      ctaHandler = () => {
        this.props.showModal(ADD_DEVICE_MODAL);
      };
    } else {
      headerText = this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
      descriptionText = this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
      ctaText = this.context.intl.formatMessage(messages.ctaFilterText, {});
      ctaHandler = this.props.clearSearchWord;
    }

    return (
      <tr>
        <td className="no-content-to-show" colSpan="6">
          <EmptyState
            filerStyle={{ width: '16vw', minWidth: '270px' }}
            style={{ display: 'block', padding: 0 }}
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
          />
        </td>
      </tr>
    );
  }

  filterAndSort(phrase, relevantDevices, userLocations) {
    const filteredDevices = relevantDevices.reduce((prev, device) => {
      const { settings, brand, model } = device;

      const locationsNames = device.settings
        ? userLocations.filter(loc => device.settings.locations.includes(loc.id)).map(loc => loc.name)
        : [];

      if (`${settings.name + brand + model + locationsNames.join('')}`.toLowerCase().includes(phrase.toLowerCase())) {
        return [...prev, { ...device, name: settings.name, locations: locationsNames.join('') }];
      }
      return prev;
    }, []);

    const column = this.props.listsUi.devices.sortColumn;
    const direction = this.props.listsUi.devices.sortDecreesing ? -1 : 1;

    if (column >= 0) filteredDevices.sort(compareByKey(this.compareKeys[column], direction));

    return filteredDevices;
  }

  render() {
    const { devices, listsUi, searchString, userLocations, toggleAllCheckboxes, toggleCheckbox, changeSorting } =
      this.props;
    const selectedDevices = listsUi.devices.selected;
    const relevantDevices = this.filterAndSort(searchString, devices, userLocations);
    const relevantSelectedDevicesIds = selectedDevices.filter(deviceId =>
      relevantDevices.map(device => device.id).includes(deviceId),
    );
    return (
      <div>
        <Grid
          width={700}
          height={800}
          columnStyle={{ width: '16vw', minWidth: '270px' }}
          columnGroup={
            relevantDevices.length ? (
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
            ) : null
          }
          static
        >
          <Header>
            <DevicesHeader
              sortFunc={changeSorting}
              uiData={this.props.listsUi.devices}
              selectedDevices={selectedDevices}
              relevantDevices={relevantDevices}
              relevantSelectedDevicesIds={relevantSelectedDevicesIds}
              toggleAllCheckboxes={toggleAllCheckboxes}
            />
          </Header>
          <Rows className="table_company k-devicesTable">
            {!relevantDevices.length
              ? this.getEmptyStateView()
              : relevantDevices.map(device => (
                  <DevicesTableRow
                    device={device.error}
                    key={device.uuid}
                    uiData={this.props.listsUi.devices}
                    toggleCheckbox={toggleCheckbox}
                  />
                ))}
          </Rows>
        </Grid>
      </div>
    );
  }
}

DevicesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DevicesTable.propTypes = {
  listsUi: PropTypes.shape({
    devices: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
      sortColumn: PropTypes.number,
      sortDecreesing: PropTypes.bool,
    }),
  }),
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  searchString: PropTypes.string,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  clearSearchWord: PropTypes.func,
  toggleAllCheckboxes: PropTypes.func,
  toggleCheckbox: PropTypes.func,
  showModal: PropTypes.func,
  changeSorting: PropTypes.func,
};

export default DevicesTable;
