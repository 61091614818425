import { connect } from 'react-redux';

import { changeCurrentUser } from '@/actions';
import { toggleMainModal } from '@/actions/uiState.js';
import AvatarEditor from '@/components/common/AvatarEditor.jsx';

const mapStateToProps = state => ({
  user: state.reducer.currentUser.user,
  show: state.reducer.uiState.showMainModal,
});

const mapDispatchToProps = {
  changeCurrentUser,
  toggleMainModal,
};

const AvatarEditorContainer = connect(mapStateToProps, mapDispatchToProps)(AvatarEditor);

export default AvatarEditorContainer;
