import { connect } from 'react-redux';

import SettingsView from '@/components/SettingsView.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  enablePayu: state.reducer.payments.client.enable_payu,
});

const SettingsViewContainer = connect(mapStateToProps)(SettingsView);

export default SettingsViewContainer;
