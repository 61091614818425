import cn from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDTooltip } from '@/components/common/inputs/MDComponents.jsx';
import Toggle from '@/components/common/inputs/Toggle.jsx';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { AVAILABILITIES_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';
import { useAppSelector } from '@/redux-store';
import { selectAreEmployeesGrouped } from '@/redux-store/employeeGrouping';

import './QuickPlanningToggle.scss';

const QuickPlanningToggle = props => {
  const { isQuickPlanningEnabled, toogleQuickPlanningScheduleMode, scheduleLocationFilter, restrictions, viewMode } =
    props;
  const areEmployeesGrouped = useAppSelector(selectAreEmployeesGrouped);
  const isEditDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const toggleClassName = cn('quickPlanningToggle', {
    'quickPlanningToggle--disabled': isEditDisabled || viewMode === AVAILABILITIES_DISPLAY_MODE_TYPE,
  });

  return (
    <div className={toggleClassName}>
      <MDTooltip
        text={<FormattedMessage id="quickPlanningToogle.chooseMode" defaultMessage="Wybierz tryb działania tabeli" />}
        withoutIcon
      >
        <Toggle
          labelId="quickPlanningToggle"
          value={isQuickPlanningEnabled}
          onChangeFunc={toogleQuickPlanningScheduleMode}
          disabled={scheduleLocationFilter.length !== 1 || areEmployeesGrouped}
          left={
            <i className="material-icons quickPlanningToggle__icon quickPlanningToggle__icon--keyboard">keyboard</i>
          }
          right={<i className="material-icons quickPlanningToggle__icon quickPlanningToggle__icon--mouse">mouse</i>}
        />
      </MDTooltip>
    </div>
  );
};

QuickPlanningToggle.propTypes = {
  isQuickPlanningEnabled: PropTypes.bool,
  toogleQuickPlanningScheduleMode: PropTypes.func,
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  viewMode: PropTypes.string,
};

export default memo(QuickPlanningToggle);
