import EmployeeAvailabilitiesBlock from '@/components/common/EmployeeAvailabilitiesBlock.jsx';
import { checkIfContractHasJobTitle, getRelevantContractForDate } from '@/utils/contracts';

const isEmployeeRelevant = (employee, rowData, location, employeeContracts) => {
  const relevantContract = getRelevantContractForDate(employeeContracts, rowData.date);
  const hasJobTitle = checkIfContractHasJobTitle(relevantContract, rowData.id);
  return !employee.inactive && hasJobTitle && employee.locations.some(l => l.id === location.id);
};

export const isEmployeeSelected = (employee, selectedEmployees) =>
  selectedEmployees.some(({ id }) => employee.id === id);

export const getEmployeesOptions = (
  modalObject,
  userEmployees,
  userCustomTypes,
  selectedEmployees,
  contracts,
  selectAll = false,
) => {
  const { location } = modalObject;
  const rowData = modalObject.rowData || [];
  return userEmployees.reduce((prev, employee) => {
    const employeeContracts = contracts[employee.id] || [];
    if (!isEmployeeRelevant(employee, rowData, location, employeeContracts)) return prev;
    const supplementary = employee.supplementary_locations_ids.includes(parseInt(location.id));
    return prev.concat({
      value: employee,
      sortValue: (supplementary ? '' : '_') + employee.first_name + employee.last_name,
      label: (
        <EmployeeAvailabilitiesBlock
          employee={{ ...employee, supplementary }}
          date={modalObject.date}
          userCustomTypes={userCustomTypes}
        />
      ),
      active: selectAll || isEmployeeSelected(employee, selectedEmployees),
    });
  }, []);
};
