import { defineMessages } from 'react-intl';

const messages = defineMessages({
  attendanceTableRowTypeAvailability: {
    id: 'attendance.table.rowTypeAvailability',
    defaultMessage: 'Dostępny',
  },

  attendanceTableRowTypeUnavailability: {
    id: 'attendance.table.rowTypeUnavailability',
    defaultMessage: 'Niedostępny',
  },
  rowTypeAvailabilityFreetime: {
    id: 'attendance.table.rowTypeAvailability.freetime',
    defaultMessage: 'Dostępny w danych godzinach',
  },
  missingAvaType: {
    id: 'attendance.missingAvaType',
    defaultMessage: 'Usunięty typ dostępności',
  },
});

export const DEFAULT_AVA_TYPE = 'default';
export const CUSTOM_AVA_TYPE = 'custom';

export const defaultAvailabilityTypes = [
  {
    id: 'availability',
    name: messages.attendanceTableRowTypeAvailability,
    color: '#e5ffc6',
    cornColor: '#9de000',
    requires_time: false,
    requires_approval: false,
    optional_comment: false,
    is_default: true,
    label_addition: 'ava',
    type: 'availability',
    is_availability: true,
  },
  {
    id: 'unavailability',
    name: messages.attendanceTableRowTypeUnavailability,
    color: '#fbe9e7',
    cornColor: '#ff3333',
    requires_time: false,
    requires_approval: false,
    optional_comment: false,
    is_default: true,
    label_addition: 'not',
    type: 'unavailability',
    is_availability: false,
  },
  {
    id: 'freetime',
    name: messages.rowTypeAvailabilityFreetime,
    color: '#eceffi',
    cornColor: '#dddddd',
    requires_time: true,
    requires_approval: false,
    optional_comment: false,
    is_default: true,
    label_addition: 'part',
    type: 'freetime',
    is_availability: true,
  },
];

export const missingTypeAvaType = {
  id: 'missing',
  name: messages.missingAvaType,
  color: '#fbe9e7',
  cornColor: '#ff0000',
  requires_time: false,
  requires_approval: false,
  optional_comment: false,
  is_default: true,
  label_addition: 'not',
  type: 'error',
  is_availability: false,
};
