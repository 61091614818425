import { connect } from 'react-redux';

import { changeLocationSettings, changeMassLocationsSettings,getLocationSettings } from '@/actions/settings';

import LocationSettingsContent from './LocationSettingsContent.jsx';

const mapStateToProps = state => ({
  companyRoles: state.reducer.roles.companyRoles,
  currentUser: state.reducer.currentUser,
  settings: state.reducer.settings,
  userLocations: state.reducer.userLocations,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  changeLocationSettings,
  changeLocationSettingsMass: changeMassLocationsSettings,
  getLocationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSettingsContent);
