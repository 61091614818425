import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import { useAppSelector } from '@/redux-store';
import { selectUserJobTitle } from '@/redux-store/userJobTitles';

import { GroupNodeType } from '../../../GroupedEmployees.types';

type Props = {
  groupKey: GroupNodeType['groupKey'];
};

const messages = defineMessages({
  none: {
    id: 'groupingEmployees.rest',
    defaultMessage: 'Pozostałe',
  },
});

export const GroupJobTitle = memo(({ groupKey }: Props) => {
  const jobTitle = useAppSelector(selectUserJobTitle(groupKey));
  const intl = useIntl();

  if (!jobTitle) return <span className="k-groupBar__title">{intl.formatMessage(messages.none)}</span>;

  return <ColorLabel color={jobTitle.color} modifiers="groupingSchedule" text={jobTitle.title} />;
});

GroupJobTitle.displayName = 'GroupJobTitle';
