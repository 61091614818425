import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { v4 } from 'uuid';

import Button from '@/components/common/Basic/Button';
import { MDDatesPicker } from '@/components/common/inputs/MDComponents';
import Select from '@/components/common/inputs/MDSelect/Select';
import KadroFeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { CONTRACTS_EDIT_SHOW } from '@/constants/Permissions';
import { BUDGET_INFO_HIDE, CONTRACTS_EDIT_HIDE } from '@/constants/Restrictions';
import { adjustContractDates, sortContractsByDates } from '@/utils/contracts';

import EmployeeModalContractJobTitles from './EmployeeModalContractJobTitles/EmployeeModalContractJobTitles';
import { getContractLabel, getInitialContract } from './EmployeeModalContracts.helpers';
import { messages } from './EmployeeModalContracts.messages';

import './EmployeeModalContracts.scss';

const EmployeeModalContracts = (
  {
    contracts = [],
    userJobTitles,
    restrictions,
    updateContract,
    addContract,
    startDateError,
    confirmDeleteContract,
    changeTab,
  },
  { intl },
) => {
  const sortedContracts = useMemo(() => sortContractsByDates(contracts), [contracts]);
  const currentContract = getInitialContract(sortedContracts);
  const [selectedContractId, setSelectedContractId] = useState(currentContract?.id || sortedContracts[0]?.id);
  const hasAccessToWage = !restrictions.includes(BUDGET_INFO_HIDE);
  const disabledDates = sortedContracts.reduce((acc, contract) => {
    if (!contract.start_date) return acc;

    acc.push(new Date(contract.start_date));
    return acc;
  }, []);
  const mappedContracts = adjustContractDates(sortedContracts);
  const selectedContract = useMemo(() => mappedContracts.find(c => c.id === selectedContractId), [
    selectedContractId,
    contracts,
  ]);
  const isFirstContractSelected = useMemo(() => sortedContracts[0]?.id === selectedContractId, [
    selectedContractId,
    contracts,
  ]);
  const options = useMemo(
    () =>
      mappedContracts.map(contract => {
        const key = getContractLabel(contract, intl);
        return {
          value: contract.id,
          key,
        };
      }),
    [mappedContracts],
  );
  const isSelectAndAddButtonDisabled = useMemo(() => {
    const contractsWithoutStartDate = sortedContracts.filter(contract => !contract.start_date);
    return contractsWithoutStartDate.length > 1;
  }, [sortedContracts]);
  if (!selectedContract) return null;

  const updateContractStartDate = event => {
    const value = event.target.value[0];
    if (value) {
      updateContract(selectedContractId, 'start_date', value);
    }
  };

  const updateContractJobTitles = newJobTitles => {
    updateContract(selectedContractId, 'job_titles', newJobTitles);
  };

  const deleteCurrentContract = () => {
    const index = sortedContracts.findIndex(contract => contract.id === selectedContractId);
    const previousContract = sortedContracts[index - 1];
    const nextContractId = previousContract ? previousContract.id : sortedContracts[index + 1].id;
    confirmDeleteContract(selectedContractId, nextContractId, setSelectedContractId, changeTab, contracts);
  };

  const addNewContract = () => {
    const newId = v4();
    addContract(newId, contracts.length === 1 ? contracts[0].job_titles : undefined);
    setSelectedContractId(newId);
  };

  const addContractButton = (
    <Button onClick={addNewContract} modifiers="orange small" disabled={isSelectAndAddButtonDisabled}>
      <i className="material-icons">add</i>
    </Button>
  );

  return (
    <>
      <KadroFeatureWrapper restriction={CONTRACTS_EDIT_HIDE} permission={CONTRACTS_EDIT_SHOW}>
        <div className="employeeModal__topContractSectionWrapper">
          <Select
            name={intl.formatMessage(messages.contract)}
            id="selected_contracts"
            options={options}
            onChange={value => setSelectedContractId(value)}
            defaultValue={selectedContractId}
            closeOnClick
            testId="selected_contract"
            selectInputPlaceholder={intl.formatMessage(messages.selectPlaceholder)}
            disabled={isSelectAndAddButtonDisabled}
          />
          {isSelectAndAddButtonDisabled ? (
            <Tooltip yOffset={10} content={intl.formatMessage(messages.addButtonTooltip)}>
              {addContractButton}
            </Tooltip>
          ) : (
            addContractButton
          )}
          <Button
            onClick={deleteCurrentContract}
            modifiers="reverse-orange small only-icon"
            disabled={!selectedContract.start_date && !selectedContract.isTemp}
          >
            <i className="material-icons">delete</i>
          </Button>
        </div>
        <div className="employeeModal__datePickersWrapper">
          <MDDatesPicker
            id="contractStartDate"
            values={selectedContract.start_date ? [selectedContract.start_date] : []}
            handleInputChange={updateContractStartDate}
            singleDate
            label={intl.formatMessage(messages.startDate)}
            tooltip={
              selectedContract.start_date || (!selectedContract.start_date && selectedContract.isTemp)
                ? undefined
                : intl.formatMessage(messages.noStartDateTooltip)
            }
            disabled={isFirstContractSelected}
            disabledDates={disabledDates}
            position="bottom"
            errorMessage={startDateError}
            customDisplayValue={
              selectedContract.start_date || selectedContract.isTemp
                ? undefined
                : intl.formatMessage(messages.noStartDate)
            }
          />
          <MDDatesPicker
            id="contractEndDate"
            values={selectedContract.end_date ? [selectedContract.end_date] : []}
            singleDate
            label={intl.formatMessage(messages.endDate)}
            tooltip={intl.formatMessage(
              selectedContract.end_date ? messages.endDateTooltip : messages.noEndDateTooltip,
              {
                date: moment(selectedContract.end_date)
                  .add(1, 'day')
                  .format('DD.MM.YYYY'),
              },
            )}
            disabled
            customDisplayValue={selectedContract.end_date ? undefined : intl.formatMessage(messages.noEndDate)}
          />
        </div>
      </KadroFeatureWrapper>
      <div className="employeeModal__jobTitles">
        <EmployeeModalContractJobTitles
          allJobTitles={userJobTitles}
          selectedContractId={selectedContractId}
          contractJobTitles={selectedContract.job_titles}
          updateContractJobTitles={updateContractJobTitles}
          hasAccessToWage={hasAccessToWage}
        />
      </div>
    </>
  );
};

EmployeeModalContracts.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeModalContracts.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.shape({})),
  updateContract: PropTypes.func,
  addContract: PropTypes.func,
  deleteContract: PropTypes.func,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  confirmDeleteContract: PropTypes.func,
  startDateError: PropTypes.string,
  changeTab: PropTypes.func,
};

export default EmployeeModalContracts;
