import { sortByKey } from '@/utils/arrayHelpers';
import {
  calculateDurationMinutes,
  getRangeBetweenDates,
  parseMinutesToFormat,
  parseMinutesToHumanForm,
} from '@/utils/dateHelper';
import { getDefaultAbsenceTime, getLocationNameForId } from '@/utils/payrollHelpers';

const getAbsenceTime = (absence, employee) => {
  if (absence.absence_hours) return calculateDurationMinutes(absence.absence_hours);
  if (!absence.count_only_days_with_shifts)
    return getDefaultAbsenceTime(absence.count_only_days_with_shifts, employee.employment_conditions);
  return absence.shiftsForAbsence.reduce((sum, shift) => sum + (shift.duration || 0), 0);
};

const createShiftBlockObject = (block, userLocations) => ({
  ...block,
  locationName: getLocationNameForId(block.location.id, userLocations),
  startHour: block.working_hours.slice(0, 5),
  endHour: block.working_hours.slice(6),
  jobTitleName: block.job_title.title,
  absenceInfo: '',
  durationTime: parseMinutesToFormat(block.duration),
});

const createAbsenceBlockObject = (block, absencesTypes, employee) => {
  const { short_name: shortName } = absencesTypes.find(({ id }) => id === block.type_id);
  const duration = parseMinutesToHumanForm(getAbsenceTime(block, employee));
  return {
    ...block,
    locationName: '',
    startHour: block.absence_hours?.slice(0, 5) || '',
    endHour: block.absence_hours?.slice(6) || '',
    jobTitleName: '',
    absenceInfo: `${shortName} - ${duration}`,
    durationTime: duration,
  };
};

const createBlockObject = (block, userLocations, absencesTypes, employee) => {
  switch (block.blockType) {
    case 'shift': {
      return createShiftBlockObject(block, userLocations);
    }
    case 'absence': {
      return createAbsenceBlockObject(block, absencesTypes, employee);
    }
    default:
      return null;
  }
};

const getRelevantShifts = (shifts, dateArray) =>
  shifts
    .reduce(
      (shiftList, shift) =>
        !dateArray.includes(shift.date) ? shiftList : [...shiftList, { ...shift, blockType: 'shift' }],
      [],
    )
    .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1));

const getShiftsForAbsences = (absence, shifts, currentDate) =>
  absence.count_only_days_with_shifts ? shifts.filter(shift => shift.date === currentDate) : [];

const getRelevantAbsences = (absences, shifts, dateArray) =>
  absences.reduce((absencesList, absence) => {
    const isAbsenceAccepted = absence.status === 'accepted';
    if (isAbsenceAccepted && absence.from !== absence.to) {
      const [minDate, maxDate] = [dateArray[0], dateArray[dateArray.length - 1]];
      const startDate = minDate > absence.from ? minDate : absence.from;
      const endDate = maxDate < absence.to ? maxDate : absence.to;
      const absencesDatesArray = getRangeBetweenDates(startDate, endDate);
      const splittedAbsence = absencesDatesArray.reduce((absencesDatesList, currentDate) => {
        if (absence.omitted_dates.includes(currentDate)) return absencesDatesList;
        const shiftsForAbsence = getShiftsForAbsences(absence, shifts, currentDate);
        return [...absencesDatesList, { ...absence, blockType: 'absence', date: currentDate, shiftsForAbsence }];
      }, []);
      return [...absencesList, ...splittedAbsence];
    }
    if (isAbsenceAccepted && absence.from === absence.to && !absence.omitted_dates.includes(absence.from)) {
      const shiftsForAbsence = getShiftsForAbsences(absence, shifts, absence.from);
      return [...absencesList, { ...absence, blockType: 'absence', date: absence.from, shiftsForAbsence }];
    }
    return absencesList;
  }, []);

export const getTableData = (mainDateStore, employee, absences, userLocations, absencesTypes) => {
  const relevantShifts = getRelevantShifts(employee.shifts, mainDateStore.dateArray);
  const relevantAbsences = getRelevantAbsences(absences, relevantShifts, mainDateStore.dateArray);
  const allBlocks = [...relevantAbsences, ...relevantShifts];
  const resultsBlocks = allBlocks.reduce((blockList, block) => {
    const newBlock = createBlockObject(block, userLocations, absencesTypes, employee);
    return newBlock ? [...blockList, newBlock] : blockList;
  }, []);
  return sortByKey(resultsBlocks, 'date');
};
