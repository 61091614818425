import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  paymentTitle: {
    id: 'unblockAccountView.paymentTitle',
    defaultMessage: 'Minął termin płatności',
  },
  paymentContent: {
    id: 'unblockAccountView.paymentContent',
    defaultMessage: `Termin Twojej płatności minął {days} {days, plural,
      one {dzień}
      few {dni}
      many {dni}
      other {dni}
    } temu. Platforma Kadromierz została zablokowana dla Ciebie i Twoich pracowników do
czasu uregulowania rachunku`,
  },
  paymentContentToday: {
    id: 'unblockAccountView.paymentContentToday',
    defaultMessage: `Dzisiaj minął termin Twojej płatności.
    Platforma Kadromierz została zablokowana dla Ciebie i Twoich pracowników do
czasu uregulowania rachunku`,
  },
  paymentButton: {
    id: 'unblockAccountView.paymentButton',
    defaultMessage: 'Opłać usługę',
  },
  amount: {
    id: 'unblockAccountView.paymentAmount',
    defaultMessage: 'Kwota do zapłaty: {amount} PLN',
  },
});
