import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  autoGenerateStepsContractsError: {
    id: 'autoGenerateSteps.error.contracts',
    defaultMessage:
      'Wybierz tylko takich pracowników, którym nie zmieniły się stanowiska w wybranym przedziale czasowym.',
  },
  autoGenerateStepsContractsTooltipError: {
    id: 'autoGenerateSteps.tooltipError.contracts',
    defaultMessage:
      'Usuń tego pracownika z listy lub wybierz te same stanowiska w jego kontraktach w przedziale, dla którego tworzony jest grafik.',
  },
});
