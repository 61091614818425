import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectLoanedEmployees } from '@/redux-store/scheduleState/locations';
import { Location } from '@/types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getScheduleDaysColSpan } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useLoanedEmployees = (locationId: Location['id']) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const dateArray = useAppSelector(selectDateArray);
  const colSpan = getScheduleDaysColSpan(dateArray, isSingleDay);
  const loanedEmployeeIds = useAppSelector(selectLoanedEmployees(locationId));

  const hideLoanedEmployees = isEmptyArray(loanedEmployeeIds);

  return {
    colSpan,
    hideLoanedEmployees,
    isSingleDay,
    loanedEmployeeIds,
  };
};
