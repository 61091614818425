import {
  ATTENDANCE_EARLY_QUIT,
  ATTENDANCE_EARLY_START,
  ATTENDANCE_LATE_QUIT,
  ATTENDANCE_LATE_START,
  ATTENDANCE_WITHOUT_SHIFT,
} from '@/constants/attendanceDetailTypes';
import {
  ATTENDANCE_ACCEPTED_OVERTIME_COLOR,
  ATTENDANCE_LATE_COLOR,
  ATTENDANCE_SUCCESS_COLOR,
  KADRO_BLUE_COLOR,
  KADRO_ORANGE_COLOR,
} from '@/constants/colors';

const DEFAULT_BLOCK_COLOR = '#000';

export const isAttendanceWithoutShift = blockType => blockType === ATTENDANCE_WITHOUT_SHIFT;

const isAttendanceEarlyStartOrLateQuit = blockType =>
  [ATTENDANCE_EARLY_START, ATTENDANCE_LATE_QUIT].includes(blockType);

export const findRelevantBlockToType = (type, blocks, attendance) =>
  type === 'start'
    ? blocks?.find(b => b.startTimestamp === attendance.start_timestamp)
    : blocks?.find(b => b.endTimestamp === attendance.end_timestamp);

const isLateStartOrEarlyQuit = (type, blocks) =>
  type === 'start'
    ? blocks?.find(b => b.type.name === ATTENDANCE_LATE_START)
    : blocks?.find(b => b.type.name === ATTENDANCE_EARLY_QUIT);

export const getTimeColorWithoutMatchingShift = (type, attendance, blocks) => {
  if (isLateStartOrEarlyQuit(type, blocks)) return ATTENDANCE_LATE_COLOR;
  const block = findRelevantBlockToType(type, blocks, attendance);
  const blockType = block?.type?.name;
  if (isAttendanceWithoutShift(blockType)) return KADRO_ORANGE_COLOR;
  if (isAttendanceEarlyStartOrLateQuit(blockType)) return KADRO_BLUE_COLOR;
  if (attendance.late_out) return ATTENDANCE_ACCEPTED_OVERTIME_COLOR;
  if (blockType === 'present') return ATTENDANCE_SUCCESS_COLOR;
  return DEFAULT_BLOCK_COLOR;
};
