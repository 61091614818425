import { defineMessages } from 'react-intl';

import { onboardingIndustries } from '@/constants/onboarding';

export const messages = defineMessages({
  companyName: {
    id: 'onboarding.stepTwo.companyName',
    defaultMessage: 'Nazwa firmy',
  },
  industry: {
    id: 'onboarding.stepTwo.industry',
    defaultMessage: 'Branża',
  },
  companySize: {
    id: 'onboarding.stepTwo.companySize',
    defaultMessage: 'Liczba pracowników',
  },
});

export const getIndustrySelectOptions = intl =>
  onboardingIndustries
    .map(opt => ({
      ...opt,
      key: intl.formatMessage(opt.label),
    }))
    .sort((a, b) => a.order - b.order || a.key.localeCompare(b.key));
