import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import { FormattedMessage } from 'react-intl';

import { FREEMIUM_HIDE_SETTINGS_IMPORT_VIEW, SETTINGS_LOCATIONS_HIDE } from '@/constants/Restrictions.js';

import { BUDGET_METRICS_ENABLE } from './Permissions';

export const locationSettingsPath = '/settings/location';

export const settingsEmployeeRoutes = [
  {
    title: <FormattedMessage id="topBar.settings.routeUserData" defaultMessage="Twoje dane" />,
    path: '/settings/user-data',
  },
];

export const settingsRoutes = payuEnabled => [
  {
    title: <FormattedMessage id="topBar.settings.routeUserData" defaultMessage="Twoje dane" />,
    path: '/settings/user-data',
  },
  {
    title: <FormattedMessage id="topBar.settings.routeLocation" defaultMessage="Ustawienia lokalizacji" />,
    path: '/settings/location',
    restriction: SETTINGS_LOCATIONS_HIDE,
  },
  {
    title: <FormattedMessage id="topBar.settings.routeOptions" defaultMessage="Opcje" />,
    path: '/settings/options',
    restriction: SETTINGS_LOCATIONS_HIDE,
    roles: ['ROLE_OWNER'],
  },
  {
    title: <FormattedMessage id="topBar.settings.routeBudgetMetrics" defaultMessage="Metryki budżetu" />,
    path: '/settings/budget-metrics',
    restrictions: [
      RESTRICTIONS.BUDGET_INFO_HIDE,
      RESTRICTIONS.BUDGET_TARGETS_HIDE,
    ],
    permission: BUDGET_METRICS_ENABLE,
    roles: ['ROLE_OWNER', 'ROLE_MANAGER'],
  },
  {
    title: <FormattedMessage id="topBar.settings.routeImport" defaultMessage="Import" />,
    path: '/settings/import',
    restriction: FREEMIUM_HIDE_SETTINGS_IMPORT_VIEW,
  },
  {
    title: <FormattedMessage id="topBar.settings.routePayments" defaultMessage="Płatności" />,
    path: '/settings/payments',
    roles: ['ROLE_OWNER'],
    isHidden: !payuEnabled,
  },

];
