import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  delete: {
    id: 'scheduleSelectingButton.tooltip.delete',
    defaultMessage: 'Usuń',
  },
  deselect: {
    id: 'scheduleSelectingButton.tooltip.deselect',
    defaultMessage: 'Odznacz',
  },
});
