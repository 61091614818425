import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import KebabMenuWrapper from '@/components/common/Basic/KebabMenu/KebabMenuWrapper/KebabMenuWrapper';
import DropDownMenu from '@/components/common/DropdownMenu/DropdownMenu';
import Popover from '@/components/common/Popover/Popover';

import './kebabMenu.scss';

const KebabMenu = ({ options, title, disabled, className }) => {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const handleClick = () => {
    if (disabled) return;
    setDropdownStatus(prev => !prev);
  };

  const kebabClassName = classnames('k-kebabMenu', className);
  return (
    <KebabMenuWrapper disabled={disabled} title={title}>
      <div className={kebabClassName}>
        <Popover
          addUpdateListeners
          showShadow={false}
          hideOnBlur
          yOffset={0}
          popoverStyle={{ padding: 0 }}
          setArrowStatus={setDropdownStatus}
          centerOnMobile={false}
          showPopover={dropdownStatus}
          showOnHover={false}
          content={<DropDownMenu options={options} isOpen={dropdownStatus} />}
          disabled={disabled}
          hideOnDropDownClick
          arrowPosition="right"
          align="right"
        >
          <button
            className={classnames('k-kebabMenu__icon', { 'k-kebabMenu__icon--disabled': disabled })}
            onClick={handleClick}
          >
            <Icon name="more_vert" />
          </button>
        </Popover>
      </div>
    </KebabMenuWrapper>
  );
};

KebabMenu.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      icon: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
};

export default KebabMenu;
