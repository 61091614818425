import PropTypes from 'prop-types';
import { defineMessages,FormattedMessage } from 'react-intl';

import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { INFO_EVENT_MODAL } from '@/constants/modalTypes.js';

const messages = defineMessages({
  cancelText: {
    id: 'eventInfo.close',
    defaultMessage: 'Zamknij',
  },
  title: {
    id: 'eventInfo.event',
    defaultMessage: 'Wydarzenie',
  },
});

const InfoEventModal = (props, context) => {
  const modalObject = props.modalObject[INFO_EVENT_MODAL];
  const showModal = props.showModal === INFO_EVENT_MODAL;
  const location = props.userLocations.find(({ id }) => id === modalObject?.location.id);
  return (
    <MDKadroModal
      cancelText={context.intl.formatMessage(messages.cancelText, {})}
      show={showModal}
      title={context.intl.formatMessage(messages.title, {})}
      onHide={props.hideModal}
      modifiers={['narrow']}
      hideConfirmButton
    >
      <MDInfoWithLabel
        label={<FormattedMessage id="eventInfo.date" defaultMessage="Data" />}
        info={modalObject?.date}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="eventInfo.time" defaultMessage="Czas" />}
        info={modalObject?.hours}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="eventInfo.location" defaultMessage="Lokalizacja" />}
        info={location?.name}
      />
      <MDInfoWithLabel
        label={<FormattedMessage id="eventInfo.note" defaultMessage="Notatka" />}
        info={modalObject?.description}
        infoModifiers={['note']}
      />
    </MDKadroModal>
  );
};

InfoEventModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

InfoEventModal.defaultProps = {
  modalObject: {},
};

InfoEventModal.propTypes = {
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
    hours: PropTypes.string,
    location: PropTypes.shape({}),
  }),
  hideModal: PropTypes.func,
  showModal: PropTypes.string,
};

export default InfoEventModal;
