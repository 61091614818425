import Mousetrap from 'mousetrap';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectAreEmployeesGrouped } from '@/redux-store/employeeGrouping';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { DisplayModeEnum, selectSelectedDisplayModeType } from '@/redux-store/scheduleUIState';

import { getInitBinds } from './ScheduleShortcutManager.utils';

// it can be modified to hook useScheduleShortcutManager
// when kadro/project1/kadro/components/scheduler/ScheduleMode/ScheduleModeView.jsx
// will be refactored to functional component

export const ScheduleShortcutManager = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);
  const selectedDisplayModeType = useAppSelector(selectSelectedDisplayModeType);
  const areEmployeesGrouped = useAppSelector(selectAreEmployeesGrouped);
  const disabledQuickPlanning = selectedDisplayModeType === DisplayModeEnum.AVAILABILITIES || areEmployeesGrouped;

  const currentBinds = useMemo(
    () => getInitBinds(dispatch, intl, { disabledQuickPlanning, isQuickPlanningEnabled }),
    [dispatch, disabledQuickPlanning, intl, isQuickPlanningEnabled],
  );

  useEffect(() => {
    currentBinds.forEach(item => {
      const { shortcut, func, keyEvent } = item;
      Mousetrap.bind(shortcut, func, keyEvent);
    });
    return () => {
      Mousetrap.reset();
    };
  }, [currentBinds]);

  return null;
};
