import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  changePassword: {
    id: 'settings.password.changePassword',
    defaultMessage: 'Zmień hasło',
  },
  currentPassword: {
    id: 'settings.password.currentPassword',
    defaultMessage: 'Aktualne hasło',
  },
  newPassword: {
    id: 'settings.password.newPassword',
    defaultMessage: 'Nowe hasło',
  },
  passwordConfirm: {
    id: 'settings.password.confirmPassword',
    defaultMessage: 'Potwierdź hasło',
  },
});

export const errorMessages = defineMessages({
  repeatedPassword: {
    id: 'settings.password.repeatedPassword',
    defaultMessage: 'Nowe hasło nie może być takie samo jak jedno z ostatnich 6 haseł',
  },
  passwordOldMismatch: {
    id: 'validation.passwordOldMismatch',
    defaultMessage: 'Podano błędne hasło',
  },
  usedOldPassword: {
    id: 'settings.password.usedOldPassword',
    defaultMessage: 'Nowy hasło musi być inne niż poprzednie hasło',
  },
});
