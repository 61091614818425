import { useCallback } from 'react';

import { UseSideBarValuesType } from '../useSideBar';

export const useSideBarHeader = (closeMobileMenu: UseSideBarValuesType['closeMobileMenu']) => {
  const historyBack = useCallback(() => {
    closeMobileMenu();
    window.history.back();
  }, [closeMobileMenu]);

  return {
    historyBack,
  };
};
