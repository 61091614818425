import { FilterParamEnum } from '@/constants/ui.ts';
import { EmployeeGroupingStoreState } from '@/redux-store/employeeGrouping';
import { DisplayModeEnum, ScheduleUIStoreState } from '@/redux-store/scheduleUIState';
import { EmploymentConditionsFilter } from '@/types/employmentConditions.types';
import { JobTitleFilter } from '@/types/jobTitles.types';
import { MainDateStore } from '@/types/mainDateStore';
import { ScheduleLocationFilter } from '@/types/schedule';
import { mapId } from '@/utils/array/array.helpers';

import { createPathFromParamObject, FilterParamsObject, formatArrayForUrl } from '../urlModify.utils';

const getScheduleTemplateParams = (
  selectedJobTitles: JobTitleFilter['selectedJobtitles'],
  displayMode: DisplayModeEnum,
): FilterParamsObject => ({
  [FilterParamEnum.JOB_TITLES]: formatArrayForUrl(mapId(selectedJobTitles)),
  [FilterParamEnum.DISPLAY_MODE]: displayMode,
});

const getSchedulePositionParams = (
  scheduleLocationFilter: ScheduleLocationFilter,
  selectedJobtitlesGrouped: JobTitleFilter['selectedJobtitlesGrouped'],
  { start, end }: MainDateStore['customDate'],
  displayMode: DisplayModeEnum,
): FilterParamsObject => {
  const paramsObject = {
    [FilterParamEnum.LOCATIONS]: formatArrayForUrl(scheduleLocationFilter),
    [FilterParamEnum.JOB_TITLES_GROUPED]: formatArrayForUrl(mapId(selectedJobtitlesGrouped)),
    [FilterParamEnum.DISPLAY_MODE]: displayMode,
  };
  if (start && end) {
    paramsObject[FilterParamEnum.FROM] = start;
    paramsObject[FilterParamEnum.TO] = end;
  }
  return paramsObject;
};

const getScheduleAndAvailabilitiesParams = (
  scheduleLocationFilter: ScheduleLocationFilter,
  selectedJobtitlesGrouped: JobTitleFilter['selectedJobtitlesGrouped'],
  { start, end }: MainDateStore['customDate'],
  displayMode: DisplayModeEnum,
  selectedEmploymentConditions: EmploymentConditionsFilter['selected'],
  selectedGroups: EmployeeGroupingStoreState['selectedGroups'],
): FilterParamsObject => {
  const paramsObject = {
    [FilterParamEnum.LOCATIONS]: formatArrayForUrl(scheduleLocationFilter),
    [FilterParamEnum.JOB_TITLES_GROUPED]: formatArrayForUrl(mapId(selectedJobtitlesGrouped)),
    [FilterParamEnum.EMPLOYMENT_CONDITIONS]: formatArrayForUrl(mapId(selectedEmploymentConditions)),
    [FilterParamEnum.DISPLAY_MODE]: displayMode,
    [FilterParamEnum.GROUPS]: formatArrayForUrl(selectedGroups),
  };
  if (start && end) {
    paramsObject[FilterParamEnum.FROM] = start;
    paramsObject[FilterParamEnum.TO] = end;
  }
  return paramsObject;
};

export const getSchedulePath = (
  scheduleLocationFilter: ScheduleLocationFilter,
  displayMode: ScheduleUIStoreState['selectedDisplayMode']['type'],
  { selectedJobtitles, selectedJobtitlesGrouped }: JobTitleFilter,
  customDate: MainDateStore['customDate'],
  selectedEmploymentConditions: EmploymentConditionsFilter['selected'],
  selectedGroups: EmployeeGroupingStoreState['selectedGroups'],
) => {
  if (displayMode === DisplayModeEnum.TEMPLATES)
    return createPathFromParamObject(getScheduleTemplateParams(selectedJobtitles, displayMode));

  if (displayMode === DisplayModeEnum.POSITIONS)
    return createPathFromParamObject(
      getSchedulePositionParams(scheduleLocationFilter, selectedJobtitlesGrouped, customDate, displayMode),
    );

  if ([DisplayModeEnum.SCHEDULE, DisplayModeEnum.AVAILABILITIES].includes(displayMode))
    return createPathFromParamObject(
      getScheduleAndAvailabilitiesParams(
        scheduleLocationFilter,
        selectedJobtitlesGrouped,
        customDate,
        displayMode,
        selectedEmploymentConditions,
        selectedGroups,
      ),
    );

  throw new Error(`Invalid display mode ${displayMode}`);
};
