import { Employee } from '@/types';
import { LoanEmployeesProposals } from '@/types/loanEmployeesProposals';

export const getIncomingAndOutgoingProposals = (
  loanEmployeesProposals: LoanEmployeesProposals[],
  locationId: string,
  userEmployees: Employee[],
) => {
  const relevantEmployeeIds = userEmployees
    .filter(employee => employee.locations.some(({ id }) => id === locationId))
    .map(({ id }) => id);

  const result = loanEmployeesProposals.reduce(
    (acc, proposal) => {
      const { incomingLoanEmployeesProposals, outgoingLoanEmployeesProposals } = acc;
      const selectedEmployeeIds = Object.values(proposal.selected_employees).map(({ id }) => String(id));
      const matchedSelectedEmployeesInLocation = selectedEmployeeIds.some(relevantEmployeeId =>
        relevantEmployeeIds.includes(relevantEmployeeId),
      );
      const isMatchedLocation = proposal.location.id === locationId;

      const isIncomingProposal = !isMatchedLocation && matchedSelectedEmployeesInLocation;

      if (isIncomingProposal) {
        incomingLoanEmployeesProposals.push(proposal);
      } else if (isMatchedLocation) {
        outgoingLoanEmployeesProposals.push(proposal);
      }

      return acc;
    },
    { incomingLoanEmployeesProposals: [], outgoingLoanEmployeesProposals: [] },
  );

  return result;
};
