import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { parseMinutesToHumanForm } from '@/utils/dateHelper';
import { filterAndSumRecommendedScheduleForTimePeriod } from '@/utils/recommendedScheduleHelpers';

const ScheduleBudgetTableRecommendedScheduleTotalCell = ({
  recommendedSchedule,
  dateArray,
  selectedLocations,
  selectedJobTitles,
}) => {
  const jobTitleIds = useMemo(() => selectedJobTitles.map(jobTitle => jobTitle.id), [selectedJobTitles]);

  const allRecommendedHours = useMemo(
    () => filterAndSumRecommendedScheduleForTimePeriod(recommendedSchedule, jobTitleIds, selectedLocations, dateArray),
    [recommendedSchedule, jobTitleIds, selectedLocations, dateArray],
  );

  return (
    <td className="k-budgetTable__leftColumn" style={{ width: '12vw', minWidth: '120px' }}>
      <div className="k-budgetTable__leftColumnData">
        <FormattedMessage id="schedule.budget.recommendedSchedule" defaultMessage="Prognozowany grafik" />
        <span className="k-budgetTable__leftColumnDataValue">{parseMinutesToHumanForm(allRecommendedHours)}</span>
      </div>
    </td>
  );
};

ScheduleBudgetTableRecommendedScheduleTotalCell.propTypes = {
  recommendedSchedule: PropTypes.shape({}),
  dateArray: PropTypes.arrayOf(PropTypes.string),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableRecommendedScheduleTotalCell;
