export const mapDataForChart = rawData =>
  Object.entries(rawData).map(([date, dataItem]) => ({
    date,
    estimatedSalesPerManHour: dataItem.planned_minutes
      ? parseFloat((dataItem.estimated_budget / 100 / (dataItem.planned_minutes / 60)).toFixed(2))
      : 0,
    actualSalesPerManHour: dataItem.worked_minutes
      ? parseFloat((dataItem.actual_budget / 100 / (dataItem.worked_minutes / 60)).toFixed(2))
      : 0,
    estimatedBudget: Math.round(dataItem.estimated_budget / 100),
    actualBudget: Math.round(dataItem.actual_budget / 100),
  }));
