import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import Button from '@/components/common/Basic/Button.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import UnauthenticatedLayout from '@/components/misc/UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from '@/components/misc/UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';
import browserHistory from '@/constants/browserHistory';
import { inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  emailSent: {
    id: 'resetPassword.emailSent',
    defaultMessage: 'Email resetujący hasło został wysłany',
  },
  email: {
    id: 'view.login.email',
    defaultMessage: 'E-mail',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Błąd',
  },
  success: {
    id: 'common.success',
    defaultMessage: 'Sukces!',
  },
  incorrectEmail: {
    id: 'resetPassword.incorrectEmail',
    defaultMessage: 'Brak konta z podanym adresem e-mail',
  },
});

class ResetPasswordSendEmail extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      email: '',
      error: '',
    };
    this.onChange = this.onChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  onChange({ target }) {
    this.setState({
      email: target.value,
    });
  }

  formSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const emailValidationMessage = inputValidation('email', this.state.email);
    if (emailValidationMessage) {
      this.setState({
        error: this.context.intl.formatMessage(emailValidationMessage, {}),
      });
    } else {
      if (this.state.error) this.setState({ error: '' });
      this.props
        .requestResetPassword(this.state.email)
        .then(() => {
          toastr.success(
            this.context.intl.formatMessage(messages.success, {}),
            this.context.intl.formatMessage(messages.emailSent, {}),
            {},
          );
          browserHistory.push('/');
        })
        .catch(() => {
          this.setState({ error: this.context.intl.formatMessage(messages.incorrectEmail, {}) });
        });
    }
  }

  render() {
    const tokenExpired = this.props.location?.state ? this.props.location.state.tokenExpired : false;
    return (
      <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
        <div className="k-loginView__welcomeMessage">
          <FormattedMessage id="resetPassword.view.welcomeMessage" defaultMessage="Zresetuj hasło" />
        </div>
        {tokenExpired ? (
          <span className="k-textInput__errorMessage" style={{ fontSize: '14px' }}>
            <FormattedMessage
              id="resetPassword.view.tokenExpired"
              defaultMessage="Link do zmiany hasła wygasł. Możesz wygenerować nowy poprzez poniższy formularz."
            />
          </span>
        ) : null}
        <form onSubmit={this.formSubmit}>
          <MDTextInput
            id="email"
            value={this.state.email}
            icon="k-icon-profile"
            label={this.context.intl.formatMessage(messages.email, {})}
            onChange={this.onChange}
            errorMessage={this.state.error}
          />
          <div className="k-password__button">
            <Button onClick={this.formSubmit} modifiers="orange high uppercase">
              <FormattedMessage id="resetPassword.sendPassword" defaultMessage="Wyślij hasło" />
            </Button>
          </div>
        </form>
      </UnauthenticatedLayout>
    );
  }
}

ResetPasswordSendEmail.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ResetPasswordSendEmail.propTypes = {
  requestResetPassword: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      tokenExpired: PropTypes.bool,
    }),
  }),
};

export default ResetPasswordSendEmail;
