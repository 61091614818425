import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { toogleQuickPlanningScheduleMode } from '@/actions/schedule/quickPlanning';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectSelectedEmployeeGroups, setSelectedEmployeeGroups } from '@/redux-store/employeeGrouping';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectIsQuickPlanningEnabled } from '@/redux-store/schedule/quickPlanning';
import { EmployeeGroupingEnum } from '@/types/employeeGrouping';

import { TopBarSingleChoiceOption } from '../../common/TopBarSingleChoice/TopBarSingleChoice.types';
import { getEmployeeGroupings } from './TopBarEmployeeGroupingFilter.utils';

export const useTopBarEmployeeGroupingFilter = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const options = useMemo(() => getEmployeeGroupings(intl), [intl]);
  const selectedEmployeesGroups = useAppSelector(selectSelectedEmployeeGroups);
  const isSingleDateMode = useAppSelector(selectIsDayDateMode);
  const isQuickPlanningEnabled = useAppSelector(selectIsQuickPlanningEnabled);

  const selected = useMemo(
    () => options.find(o => o.id === selectedEmployeesGroups[0]),
    [selectedEmployeesGroups, options],
  );

  const onChange = useCallback(
    (e: TopBarSingleChoiceOption<EmployeeGroupingEnum>) => {
      dispatch(setSelectedEmployeeGroups(e.id));
      if (e.id !== EmployeeGroupingEnum.NONE && isQuickPlanningEnabled) dispatch(toogleQuickPlanningScheduleMode());
    },
    [dispatch, isQuickPlanningEnabled],
  );

  return { intl, isSingleDateMode, onChange, options, selected };
};
