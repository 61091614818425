import { connect } from 'react-redux';

import RolesTable from './RolesTable.jsx';

const mapStateToProps = state => ({
  companyRoles: state.reducer.roles.companyRoles,
  grouppedPermissions: state.reducer.roles.grouppedPermissions,
  currentUser: state.reducer.currentUser.user,
  companySettings: state.reducer.currentCompany.settings,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

const RolesTableContainer = connect(mapStateToProps, mapDispatchToProps)(RolesTable);

export default RolesTableContainer;
