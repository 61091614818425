import { LocationId } from '@/types';

import { GroupExtraRow } from './GroupExtraRow/GroupExtraRow';
import { GroupNode } from './GroupNode/GroupNode';
import { useGroupedEmployees } from './useGroupedEmployees';

import './GroupedEmployees.scss';

type Props = {
  locationId: LocationId;
};

export const GroupedEmployees = ({ locationId }: Props) => {
  const { groupNodes } = useGroupedEmployees(locationId);

  return (
    <>
      {groupNodes.map(node => (
        <GroupNode key={node.id} locationId={locationId} node={node} level={0} />
      ))}
      <GroupExtraRow />
    </>
  );
};
