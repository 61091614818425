import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';

import { KADRO_LIGHT_ORANGE_COLOR, YELLOW_500 } from '@/constants/colors';
import { NO_JOBTITLE_ID } from '@/constants/reports';
import { UserJobTitle, UserLocation } from '@/types';
import { JobTitlesChartDay, LocationsChartDay, ReportChartMetadata } from '@/types/reports.types';

import { roundToTwoSigDigits } from './baseHelpers';

export const calculateIndicator = (currentValue: number, previousValue: number): number =>
  Number((((currentValue - previousValue) / (previousValue || 1)) * 100).toFixed(2));

export const createFormattersForSelectedChartType = (
  chartMode: string,
): { valueFormatter: (value: number) => string; yTickFormatter: (value: number) => string } => {
  switch (chartMode) {
    case 'minutes':
      return {
        valueFormatter: parseMinutesToHumanForm,
        yTickFormatter: parseMinutesToHumanForm,
      };
    case 'payout':
      return {
        valueFormatter: (value: number) => `${roundToTwoSigDigits(value / 100)} PLN`,
        yTickFormatter: (value: number) => `${roundToTwoSigDigits(value / 100)} PLN`,
      };

    case 'employees':
    default:
      return {
        valueFormatter: (value: number) => String(value),
        yTickFormatter: (value: number) => String(value),
      };
  }
};

export const getLabel = (entity: UserLocation | UserJobTitle): string => {
  if ('name' in entity) {
    return entity.name;
  }
  if ('title' in entity) {
    return entity.title;
  }
  return '';
};

export const generateMetadata = (
  selectedIds: string[],
  entities: UserJobTitle[] | UserLocation[],
  messages: {
    otherData: string;
    unknownData?: string;
  },
  includeOther: boolean,
  isNoJobTitle?: boolean,
): ReportChartMetadata[] => {
  const metadata = selectedIds.reduce((acc: ReportChartMetadata[], id) => {
    const relevantEntity = entities.find(entity => entity.id === id);

    if (relevantEntity) {
      acc.push({
        key: id,
        color: relevantEntity.color,
        label: getLabel(relevantEntity),
      });
    }
    return acc;
  }, []);

  if (includeOther) {
    metadata.push({
      key: 'other',
      color: YELLOW_500,
      label: messages.otherData,
    });
  }
  if (isNoJobTitle) {
    metadata.push({
      key: NO_JOBTITLE_ID,
      color: KADRO_LIGHT_ORANGE_COLOR,
      label: messages.unknownData,
    });
  }

  return metadata;
};

export const aggregateRows = (
  data: Record<string, JobTitlesChartDay | LocationsChartDay>,
  type: string,
  otherIds: string[],
): Record<string, string | number>[] =>
  Object.entries(data).map(([date, dayData]) => {
    const values = dayData[type] as Record<string, number>;
    const row: Record<string, string | number> = { date, ...values };

    row.other = otherIds.reduce((sum, id) => sum + (values[id] || 0), 0);

    return row;
  });

const getIdsSortedBySummedValue = (
  data: Record<string, JobTitlesChartDay | LocationsChartDay>,
  type: string,
  getKey: (key: string) => string,
): { id: string; value: number }[] => {
  const totalValuePerKey = Object.values(data).reduce<Record<string, number>>((acc, dayData) => {
    const values = dayData[type];
    Object.keys(values).forEach(key => {
      const id = getKey(key);
      acc[id] = (acc[id] || 0) + values[key];
    });
    return acc;
  }, {});

  return Object.entries(totalValuePerKey)
    .map(([id, value]) => ({ id, value }))
    .filter(({ value }) => value > 0)
    .sort((a, b) => b.value - a.value);
};

export const prepareRowsAndMetadataForChart = (
  data: Record<string, JobTitlesChartDay | LocationsChartDay>,
  entities: UserJobTitle[] | UserLocation[],
  type: string,
  maxItems: number,
  messages: {
    otherData: string;
    unknownData?: string;
  },
  getKey: (key: string) => string,
): { rows: Record<string, string | number>[]; metadata: ReportChartMetadata[] } => {
  const idsSortedBySummedValue = getIdsSortedBySummedValue(data, type, getKey);
  const maxNumberOfItems = Math.min(maxItems, idsSortedBySummedValue.length);
  const selectedIds = idsSortedBySummedValue.slice(0, maxNumberOfItems).map(({ id }) => id);
  const otherIds = idsSortedBySummedValue.slice(maxNumberOfItems).map(({ id }) => id);
  const valueOfOtherItems = idsSortedBySummedValue.slice(maxNumberOfItems).reduce((sum, { value }) => sum + value, 0);

  const includeOtherItems = valueOfOtherItems > 0;
  const isNoJobTitle = messages.unknownData ? idsSortedBySummedValue.some(({ id }) => id === NO_JOBTITLE_ID) : false;

  const rows = aggregateRows(data, type, otherIds);
  const metadata = generateMetadata(selectedIds, entities, messages, includeOtherItems, isNoJobTitle);

  return { rows, metadata };
};
