import { IntlShape } from 'react-intl';

import { columnsMultiSelectMessages } from './ExportOptionsAndColumnConfigStep.messages';

export const getColumnsTitlesMessages = (intl: IntlShape, columnsItems: { active: boolean }[]) => {
  const selectedColumnsAmount = columnsItems.filter(({ active }) => active).length;

  return {
    headerTitle: intl.formatMessage(columnsMultiSelectMessages.headerTitle),
    selectAll: intl.formatMessage(columnsMultiSelectMessages.selectAll),
    deselectAll: intl.formatMessage(columnsMultiSelectMessages.deselectAll),
    bodyLabel: intl.formatMessage(columnsMultiSelectMessages.bodyLabel),
    footerTitle: `${intl.formatMessage(columnsMultiSelectMessages.footerTitle)} (${selectedColumnsAmount}/${
      columnsItems.length
    })`,
  };
};
