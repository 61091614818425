import { connect } from 'react-redux';

import {
  addEmployee,
  changeEmployee,
  changeSorting,
  deleteEmployeeConfirm,
  inviteEmployees,
  toggleAllCheckboxes,
  toggleCheckbox,
} from '@/actions/companymanage';
import { showModal } from '@/actions/uiState.js';

import EmployeesTable from './EmployeesTable.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  currentCompany: state.reducer.currentCompany,
  currentUser: state.reducer.currentUser,
  employeesListUi: state.reducer.listsUi.employees,
  deviceInfo: state.reducer.deviceInfo,
  userPermissions: state.reducer.userPermissions,
  companyRoles: state.reducer.roles.companyRoles,
  employmentConditions: state.reducer.employmentConditions,
  visibleColumns: state.reducer.uiState.employeesVisibleColumns,
  contracts: state.reducer.contracts,
  jobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  addEmployee,
  changeEmployee,
  toggleCheckbox,
  toggleAllCheckboxes,
  showModal,
  changeSorting,
  deleteEmployeeConfirm,
  inviteEmployees,
};

const EmployeesTableContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeesTable);

export default EmployeesTableContainer;
