/* eslint-disable no-await-in-loop */

 
import messages from '@/actions/schedule/shifts.messages';
import * as AT from '@/constants/ActionTypes';

import { conn, deleteShiftRequest, showConfirmModal } from '../index';
import { deleteShiftForPayroll } from '../payroll/payroll';
 
export const getShiftDataByIdForLoanedEmployees = () => (dispatch, getState) => {
  const { scheduleLoanedEmployees, shifts } = getState().reducer;
  const shiftIds = Object.values(scheduleLoanedEmployees.scheduleLoanedEmployees).reduce((acc, loanedEmployee) => {
    if (loanedEmployee && loanedEmployee.shifts) {
      const employeeShiftIds = Object.values(loanedEmployee.shifts).flat();
      return [...acc, ...employeeShiftIds];
    }
    return acc;
  }, []);
  const relevantShifts = Object.values(shifts.data).flatMap(employeeData =>
    Object.values(employeeData.shifts).filter(shift => shiftIds.includes(shift.id)),
  );
  return relevantShifts.reduce((agg, shift) => {
    const { id, date, location } = shift;
    const { id: employeeId } = shift.employee;
    return {
      ...agg,
      [id]: {
        employeeId,
        date,
        locationId: location.id,
      },
    };
  }, {});
};

export const deleteShiftsByIds = shiftIds => async (dispatch, getState, intl) => {
  const { userEmployees } = getState().reducer;
  const shiftDataById = userEmployees.reduce((agg, employee) => {
    const current = employee.shifts.reduce(
      (agg2, shift) => ({
        ...agg2,
        [shift.id]: { employeeId: employee.id, date: shift.date, locationId: shift.location.id },
      }),
      {},
    );
    return { ...agg, ...current };
  }, {});

  const shiftDataByIdForLoanedEmployee = dispatch(getShiftDataByIdForLoanedEmployees());

  const shiftDataByIdForAllEmployees = { ...shiftDataById, ...shiftDataByIdForLoanedEmployee };
  const shiftsDataByEmployee = shiftIds.reduce((acc, id) => {
    const data = shiftDataByIdForAllEmployees[id];
    return {
      ...acc,
      [data.employeeId]: [
        ...(acc[data.employeeId] || []),
        { shiftId: id, date: data.date, locationId: data.locationId },
      ],
    };
  }, {});

  const chunkSize = 1000;
  const deletedShifts = [];
  for (let i = 0; i < shiftIds.length; i += chunkSize) {
    const chunk = shiftIds.slice(i, i + chunkSize);
    await conn.deleteMassShifts(chunk).catch(() => {
      dispatch({
        type: AT.DELETE_MULTIPLE_SHIFTS_ERROR,
        notification: {
          type: 'error',
          title: intl.formatMessage(messages.deleteMultipleShiftErrorTitle),
        },
      });
    });
    deletedShifts.push(...chunk);
    await new Promise(resolve => setTimeout(resolve, 500));
  }

  if (deletedShifts.length > 0) {
    dispatch({
      type: AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
      payload: shiftsDataByEmployee,
      notification: {
        type: 'success',
        title: intl.formatMessage(messages.deleteMultipleShiftSuccessTitle),
      },
    });

   }
};

export const showDeleteShiftConfirmModal = (employeeId, shiftId) => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteShiftTitle, {}),
      description: intl.formatMessage(messages.deleteShiftDescription, {}),
      confirmText: intl.formatMessage(messages.delete, {}),
      confirmFunc: () => {
        dispatch(deleteShiftRequest(employeeId, shiftId));
      },
    }),
  );
};

export const showDeleteShiftForPayrollConfirmModal = shift => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteShiftTitle, {}),
      description: intl.formatMessage(messages.deleteShiftDescription, {}),
      confirmText: intl.formatMessage(messages.delete, {}),
      confirmFunc: () => {
        dispatch(deleteShiftForPayroll(shift));
      },
    }),
  );
};
