import { useMemo } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import LegacyObjectHistory from '@/components/objectHistory/LegacyObjectHistory.redux.js';
import ObjectHistory from '@/components/objectHistory/ObjectHistory.tsx';
import { NARROW } from '@/constants/modalModifiers.js';
import { ActivityLog, ActivityLogDisplayData } from '@/types/activityLog.types.ts';

interface ObjectHistoryModalProps {
  showModal: boolean;
  modalObject: {
    history: {
      logs: ActivityLog[];
      displayData: ActivityLogDisplayData;
    };
    previousModalName?: string;
  };
  onHide: () => void;
  replaceModal: (modalName: string | undefined) => void;
}

const ObjectHistoryModal: React.FC<ObjectHistoryModalProps> = ({
  showModal = false,
  modalObject = {
    history: {
      logs: [],
      displayData: {},
    },
  },
  onHide,
  replaceModal,
}) => {
  const headerOptions = useMemo(() => {
    if (modalObject.previousModalName) {
      return [
        {
          icon: 'keyboard_return',
          handleClick: () => replaceModal?.(modalObject.previousModalName),
        },
      ];
    }
    return [];
  }, [modalObject.previousModalName]);

  return (
    <MDKadroModal
      show={showModal}
      onHide={onHide}
      title={<FormattedMessage id="objectHistory.title" defaultMessage="Historia edycji" />}
      modifiers={NARROW}
      hideFooter
      headerOptions={headerOptions}
    >
      <div className="objectHistoryModal__history">
        {modalObject.history?.logs
          ?.filter(item => item.action !== 'unpublish')
          .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
          .map((item, index) =>
            item.object_user_id ? (
              <ObjectHistory displayData={modalObject.history.displayData} log={item} key={index} />
            ) : (
              <LegacyObjectHistory item={item} key={index} />
            ),
          )}
      </div>
    </MDKadroModal>
  );
};

export default ObjectHistoryModal;
