import { connect } from 'react-redux';

import { addShiftForPayroll, getPayrollViewData } from '@/actions/payroll/payroll.js';
import { showModal } from '@/actions/uiState';

import PayrollAddRecordButton from './PayrollAddRecordButton.jsx';

const mapStateToProps = ({ reducer }) => ({
  selectedEmployee: reducer.singleEmployeeFilter,
  payrollMode: reducer.payroll.mode,
  date: reducer.mainDateStore.dateArray[0],
  companyRoles: reducer.roles.companyRoles,
  currentUser: reducer.currentUser,
  userPermissions: reducer.userPermissions,
  multipleLocationFilter: reducer.multipleLocationFilter,
});

const mapDispatchToProps = {
  getPayrollViewData,
  showModal,
  addShiftForPayroll,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollAddRecordButton);
