/* eslint-disable camelcase */
import { messages } from './PayrollEditShiftAndAttendanceModal.messages';

export const MODAL_MODIFIERS = ['narrow'];

export const initialState = {
  fullName: '',
  date: '',
  errors: {},
  attendance: {},
  shift: {},
  employee: {},
  originalShift: {},
  originalAttendance: {},
  isLoaned: false,
};

export const getModalTitle = (shiftId, attendanceId, intl) => {
  if (shiftId && attendanceId) return intl.formatMessage(messages.editShiftWithAttendance);
  if (shiftId && !attendanceId) return intl.formatMessage(messages.editShiftWithoutAttendance);
  if (!shiftId && attendanceId) return intl.formatMessage(messages.editAttendanceWithoutShift);
  return '';
};

const getAttendanceFooterOption = (intl, attendance, employeeId, onDelete, disabled) => ({
  icon: 'remove_circle_outline',
  text: intl.formatMessage(messages.deleteAttendance),
  handleClick: () => onDelete(employeeId, attendance),
  disabled,
});

const getShiftFooterOption = (intl, originalAttendance, shift, onDelete, onAdd, disabled) =>
  shift.id
    ? {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteShift),
        handleClick: () => onDelete(shift),
        disabled,
      }
    : {
        icon: 'add_circle_outline',
        text: intl.formatMessage(messages.addShift),
        handleClick: () => onAdd(originalAttendance),
        disabled,
      };

export const getFooterOptions = (
  intl,
  shift,
  originalAttendance,
  employeeId,
  callbacks,
  isAttendanceEditingBlocked,
  isShiftEditingBlocked,
) => {
  if (!employeeId) return [];

  const shiftOption = getShiftFooterOption(
    intl,
    originalAttendance,
    shift,
    callbacks.showDeleteShiftForPayrollConfirmModal,
    callbacks.showModalToAddShiftForExistingAttendance,
    isShiftEditingBlocked,
  );

  if (!originalAttendance.id) return [shiftOption];

  const attendanceOption = getAttendanceFooterOption(
    intl,
    originalAttendance,
    employeeId,
    callbacks.showDeleteAttendanceConfirmModal,
    isAttendanceEditingBlocked,
  );

  return [attendanceOption, shiftOption];
};

export const getUpdatedState = (
  userEmployees,
  selectedEmployeeId,
  shift,
  attendance,
  payrollLoanedEmployees,
  userLocations,
) => {
  const loanedEmployee = payrollLoanedEmployees.find(({ id }) => id === selectedEmployeeId);
  const employee = userEmployees.find(({ id }) => id === selectedEmployeeId) || loanedEmployee;
  const isLoaned = Boolean(loanedEmployee) || !userLocations.some(({ id }) => id === shift?.location.id);
  return {
    ...initialState,
    fullName: `${employee.first_name} ${employee.last_name}`,
    attendance: attendance || {},
    shift: shift || {},
    date: shift?.date || attendance?.date,
    employee,
    locationId: shift?.location?.id || attendance?.location?.id,
    originalShift: shift || {},
    originalAttendance: attendance || {},
    labels: attendance?.labels || [],
    isLoaned,
  };
};

export const getDataForValidation = state => {
  const dataForValidation = [];
  if (state.attendance.id)
    dataForValidation.push({ id: 'attendanceHours', name: 'workingHours', value: state.attendance.hours });
  if (state.shift.id)
    dataForValidation.push({ id: 'shiftHours', name: 'workingHours', value: state.shift.working_hours });
  return dataForValidation;
};
