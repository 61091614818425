import { defineMessages } from 'react-intl';

import * as AT from '@/constants/ActionTypes';

import { conn } from './index';

const messages = defineMessages({
  sendNotificationSuccessTitle: {
    id: 'customNotifications.sendNotificationSuccessTitle',
    defaultMessage: 'Wysłano powiadomienie',
  },
  sendNotificationSuccess: {
    id: 'customNotifications.sendNotificationSuccess',
    defaultMessage: `Wysłano powiadomienie do {empNo} {empNo, plural,
     one {pracownika}
     few {pracowników}
     many {pracowników}
     other {pracowników}
   }`,
  },
  sendNotificationErrorTitle: {
    id: 'customNotifications.sendNotificationErrorTitle',
    defaultMessage: 'Błąd',
  },
  sendNotificationError: {
    id: 'customNotifications.sendNotificationError',
    defaultMessage: 'Nie udało się wysłać powiadomienia',
  },
});

export const sendCustomNotification = notificationData => (dispatch, getState, intl) => {
  conn
    .sendCustomNotification(notificationData)
    .then(() => {
      const notification = {
        title: intl.formatMessage(messages.sendNotificationSuccessTitle, {}),
        description: intl.formatMessage(messages.sendNotificationSuccess, { empNo: notificationData.employees.length }),
        type: 'success',
      };
      dispatch({
        type: AT.SEND_CUSTOM_NOTIFICATION_SUCCESS,
        notification,
      });
    })
    .catch(err => {
      const notification = {
        title: intl.formatMessage(messages.sendNotificationErrorTitle, {}),
        description: intl.formatMessage(messages.sendNotificationError, {}),
        type: 'error',
      };
      dispatch({
        type: AT.SEND_CUSTOM_NOTIFICATION_ERROR,
        notification,
      });
      console.error(err);
    });
};
