import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes';
import { GetEmployeesNamamesAction } from '@/types/actions/employeesNames';
import { EmployeeNameData } from '@/types/employeesNames';

interface InitialState {
  data: EmployeeNameData;
}

const initialState: InitialState = {
  data: {},
};

type Action = GetEmployeesNamamesAction;

const employeesNames = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.GET_EMPLOYEES_NAMES: {
        action.payload.forEach(employee => {
          if (!draft.data[employee.id]) {
            draft.data[employee.id] = { ...employee };
          }
        });
        break;
      }

      default:
        return state;
    }
  });

export default employeesNames;
