import PropTypes from 'prop-types';

import { createEvent } from '@/utils/inputHelpers';

import { DEFAULT_EMPLOYEE_AMOUNT } from '../SelectPackageContent.helpers';
import { messages } from './SelectPackageEmployeesAndPrice.messages';

import './SelectPackageEmployeesAndPrice.scss';

const MAX_EMPLOYEES_AMOUNT = 10000;

const SelectPackageEmployeesAndPrice = (props, { intl }) => {
  const { cost, employeesAmount, onChange } = props;

  const changeEmployeesAmount = value => onChange(createEvent('employeesAmount', value));

  const handleChangeEmployeesAmount = e => {
    const { value } = e.target;
    const correctValue = !value || /^[0-9]*$/.test(value);
    if (!correctValue) return;
    changeEmployeesAmount(value ? Math.min(value, MAX_EMPLOYEES_AMOUNT) : value);
  };

  const decrementEmployeesAmount = () => {
    const value = Math.max(parseInt(employeesAmount || 0) - 1, 1);
    changeEmployeesAmount(value);
  };

  const incrementEmployeesAmount = () => {
    const value = !employeesAmount ? 1 : Math.min(parseInt(employeesAmount) + 1, MAX_EMPLOYEES_AMOUNT);
    changeEmployeesAmount(value);
  };

  return (
    <div className="k-selectPackageEmployeesAndPrice">
      <div className="k-selectPackageEmployeesAndPrice__row">
        <div className="k-selectPackageEmployeesAndPrice__text">{intl.formatMessage(messages.employeesAmount)}:</div>
        <div className="k-selectPackageEmployeesAndPrice__content">
          <button className="k-selectPackageEmployeesAndPrice__changeSign" onClick={decrementEmployeesAmount}>
            <i className="material-icons">remove</i>
          </button>
          <input
            value={employeesAmount}
            onChange={handleChangeEmployeesAmount}
            className="k-selectPackageEmployeesAndPrice__input"
            onBlur={() => changeEmployeesAmount(employeesAmount || DEFAULT_EMPLOYEE_AMOUNT)}
          />
          <button className="k-selectPackageEmployeesAndPrice__changeSign" onClick={incrementEmployeesAmount}>
            <i className="material-icons">add</i>
          </button>
        </div>
      </div>
      <div className="k-selectPackageEmployeesAndPrice__row">
        <div className="k-selectPackageEmployeesAndPrice__text">{intl.formatMessage(messages.monthlyLicence)}:</div>
        <div className="k-selectPackageEmployeesAndPrice__content k-selectPackageEmployeesAndPrice__cost">
          {(cost * (employeesAmount || 0)).toFixed(2)} zł
        </div>
      </div>
    </div>
  );
};

SelectPackageEmployeesAndPrice.contextTypes = {
  intl: PropTypes.shape({}),
};

SelectPackageEmployeesAndPrice.propTypes = {
  cost: PropTypes.number,
  employeesAmount: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectPackageEmployeesAndPrice;
