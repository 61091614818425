import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  deleteTradeShiftsFromSelectedCellsConfirm: {
    id: 'deleteSelectedTradeShifts.confirm',
    defaultMessage: 'Usuń',
  },
  deleteTradeShiftsFromSelectedCellsTitle: {
    id: 'deleteSelectedTradeShifts.title',
    defaultMessage: 'Czy odrzucić zaznaczone wymiany zmiany?',
  },
  deleteTradeShiftsFromSelectedCellsMessage: {
    id: 'deleteSelectedTradeShifts.message',
    defaultMessage:
      'Zaznaczono {numberOfShifts, plural, one {1 wymianę zmiany} =2 {2 wymiany zmian} =3 {3 wymiany zmian} =4 {4 wymiany zmian} other {# wymian zmian}}. Czy na pewno chcesz je usunąć?',
  },
});
