import { connect } from 'react-redux';

import { addMassShift, addMultipleShiftsWithRepeat, addShift } from '@/actions';
import { toggleNonWorkingDays } from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState.js';
import { ADD_POSITION_SHIFT_MODAL } from '@/constants/modalTypes.js';

import AddPositionShiftModal from './AddPositionShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ADD_POSITION_SHIFT_MODAL,
  modalObject: state.reducer.uiState.modalObject[ADD_POSITION_SHIFT_MODAL],
  mainDateStore: state.reducer.mainDateStore,
  userShiftblocks: state.reducer.userShiftblocks,
  locationSettings: state.reducer.settings.locationSettings,
  nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
});

const mapDispatchToProps = {
  addShift,
  addMassShift,
  onHide: hideModal,
  toggleNonWorkingDays,
  addMultipleShiftsWithRepeat,
};

const AddPositionShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddPositionShiftModal);

export default AddPositionShiftModalContainer;
