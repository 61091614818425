/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MultiSelectOptionManager from '@/components/common/inputs/MultiSelectOptionManager/MultiSelectOptionManager';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { moveDateForwardByOne } from '@/utils/dateHelper.js';
import { inputValidation } from '@/utils/inputValidation.js';
import { checkIfCanAddEditDeleteLabels, showLabelsFeature } from '@/utils/labels';

import './addAttendanceModal.scss';

const messages = defineMessages({
  confirmText: {
    id: 'attendance.addModal.confirmText',
    defaultMessage: 'Dodaj',
  },
  title: {
    id: 'attendance.addModal.title',
    defaultMessage: 'Dodaj Obecność',
  },
});

class AddAttendanceModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hours: '',
      errorMessage: '',
      isSubmitting: false,
      selectedLabels: [],
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { defaultHours } = this.props.modalObject;
    if (defaultHours !== prevProps.modalObject.defaultHours) {
      this.setState({ hours: defaultHours, errorMessage: '' });
    }
  }

  setSelectedLabels(selectedLabels) {
    this.setState({ selectedLabels });
  }

  async onSubmit() {
    const { hours, selectedLabels } = this.state;
    const { date, employee, locationId } = this.props.modalObject;
    const hour_split = hours.split('-');

    const err = inputValidation('attendanceHours', hours);
    this.setState({ errorMessage: err });
    if (err !== '') return;

    // TODO: Add next day split
    const startDate = date;
    let endDate = date;
    if (hour_split[1] < hour_split[0]) {
      endDate = moveDateForwardByOne('day', startDate);
    }
    const start_timestamp = `${startDate} ${hour_split[0]}:00`;
    const end_timestamp = `${endDate} ${hour_split[1]}:00`;

    try {
      this.setState(prev => ({ ...prev, isSubmitting: true }));
      await this.props.createAttendance(employee.id, start_timestamp, end_timestamp, locationId, selectedLabels);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(prev => ({ ...prev, isSubmitting: false }));
    }
  }

  render() {
    const { labels, currentUser, userPermissions, companyRoles } = this.props;
    const { date, defaultHours } = this.props.modalObject;
    let { employee } = this.props.modalObject;
    employee = employee || {
      first_name: '',
      last_name: '',
    };
    const { role_id: roleId, role } = currentUser.user;
    const { permissions } = userPermissions;
    const disabledAddAndEditLabels = !checkIfCanAddEditDeleteLabels(companyRoles, roleId, role, permissions);
    const showLabelsSelect = showLabelsFeature(role, permissions);

    return (
      <MDKadroModal
        className="k-addAttendanceModal"
        confirmText={this.context.intl.formatMessage(messages.confirmText, {})}
        disableConfirm={this.state.isSubmitting}
        errorMessage={this.state.errorMessage && this.context.intl.formatMessage(this.state.errorMessage, {})}
        modifiers={['narrow']}
        onHide={this.props.onHide}
        onSubmit={this.onSubmit}
        show={this.props.showModal}
        title={this.context.intl.formatMessage(messages.title, {})}
      >
        <div className="heading heading--extraTopMargin">
          <FormattedMessage
            id="attendance.addModal.headerMessage"
            defaultMessage="Dodaj obecność w dniu {date} dla {name}"
            values={{
              date,
              name: `${employee.first_name} ${employee.last_name}`,
            }}
          />
        </div>
        <MDTimeInput
          label={<FormattedMessage id="attendance.addModal.hours" defaultMessage="Czas" />}
          value={this.state.hours || defaultHours}
          onChange={e => this.setState({ hours: e.target.value })}
          id="time"
          modifiers={['modal']}
          short={false}
        />
        {showLabelsSelect && (
          <MultiSelectOptionManager
            options={labels}
            defaultOptions={[]}
            selectItemsId={this.setSelectedLabels}
            asInput
            hideAddAndEdit={disabledAddAndEditLabels}
            isModal
          />
        )}
      </MDKadroModal>
    );
  }
}

AddAttendanceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddAttendanceModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    onHide: PropTypes.func,
    date: PropTypes.string,
    employee: PropTypes.shape({}),
    locationId: PropTypes.string,
  }),
  createAttendance: PropTypes.func,
};

export default AddAttendanceModal;
