import * as AT from '@/constants/ActionTypes.js';

const NOT_LIMITED = 0;

const initialState = {
  usersLimit: NOT_LIMITED,
  locationsLimit: NOT_LIMITED,
  jobTitlesLimit: NOT_LIMITED,
  usersLimitReached: false,
  locationsLimitReached: false,
  jobTitlesLimitReached: false,
};

const companyLimitsUsage = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_COMPANY_LIMITS_USAGE_SUCCESSFUL:
      return action.payload;
    default:
      return state;
  }
};
export default companyLimitsUsage;
