import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import KadroCalendarPopoverInput from '@/components/common/inputs/KadroCalendarPopoverInput';
import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import { OPEN_SHIFTS_TEMPLATE_ID } from '@/constants/scheduleDisplayModes.js';
import { getCalendarRangeText } from '@/utils/dateHelper.js';

import { showOption } from '../autoscheduler.helpers.js';

class AutoGenerateMonthPicker extends Component {
  constructor(props, context) {
    super(props, context);
    this.onTemplateChange = this.onTemplateChange.bind(this);
    this.changeRange = this.changeRange.bind(this);
    this.addRange = this.addRange.bind(this);
    this.deleteRange = this.deleteRange.bind(this);
  }

  onTemplateChange(templateId, id) {
    const newRanges = this.props.ranges.map(range => {
      if (range.id === id) {
        return {
          ...range,
          templateId,
        };
      }
      return range;
    });
    this.props.onRangeChange(newRanges);
  }

  addRange() {
    const { ranges, mainDateStore, userTemplates, onRangeChange } = this.props;
    const { start: startDate } = mainDateStore.customDate;

    const newRanges = [
      ...ranges,
      {
        id: ranges[ranges.length - 1].id + 1,
        templateId: userTemplates.currentTemplate.id,
        dateText: '',
        date: {
          start: startDate,
          end: startDate,
        },
      },
    ];
    onRangeChange(newRanges);
  }

  deleteRange(id) {
    let newRanges = [
      {
        id: 0,
        templateId: this.props.userTemplates.currentTemplate.id,
        dateText: '',
        date: {
          start: null,
          end: null,
        },
      },
    ];
    if (this.props.ranges.length > 1) {
      newRanges = this.props.ranges.filter(range => range.id !== id);
    }
    this.props.onRangeChange(newRanges, true);
  }

  changeRange(e, id) {
    const newRanges = this.props.ranges.map(range => {
      if (range.id === id) {
        return {
          ...range,
          dateText: getCalendarRangeText(e.start, e.end),
          date: {
            start: e.start,
            end: e.end,
          },
        };
      }
      return range;
    });
    this.props.onRangeChange(newRanges, true);
  }

  render() {
    const { mainDateStore, selectedTemplate, userTemplates } = this.props;

    const selectedOpenShiftTemplate = selectedTemplate === OPEN_SHIFTS_TEMPLATE_ID;

    const popoverInputModifier = selectedOpenShiftTemplate ? 'disabled' : null;

    const errorClassName = classnames('k-autoGenerateMonthPicker__error', {
      'k-autoGenerateMonthPicker__error--hideError': selectedOpenShiftTemplate,
    });

    const fieldsetClassName = classnames('k-autoGenerateMonthPicker__rangeWrapper', {
      'k-autoGenerateMonthPicker__rangeWrapper--openShiftTempalte': selectedOpenShiftTemplate,
    });

    return (
      <div className="k-autoGenerateMonthPicker">
        {!selectedOpenShiftTemplate && (
          <FormattedMessage
            id="schedule.autoGenerateMonth.title"
            defaultMessage="Wybierz szablony i okresy dla nich, aby wygenerować grafik dla wybranego miesiąca ({month})"
            values={{
              month: moment(mainDateStore.dateArray[0]).format('MMMM'),
            }}
          >
            {text => <div className="k-autoGenerateMonthPicker__title">{text}</div>}
          </FormattedMessage>
        )}
        {this.props.ranges.map((range, idx) => (
          <fieldset key={range.id} className={fieldsetClassName}>
            {!selectedOpenShiftTemplate && (
              <MDSelect
                name={`${this.context.intl.formatMessage({ id: 'common.template', defaultMessage: 'Szablon' })} ${
                  idx + 1
                }`}
                defaultValue={range.templateId}
                options={showOption(userTemplates.templatesArray)}
                onChange={value => this.onTemplateChange(value, range.id)}
                closeOnClick
                withSearch
              />
            )}
            <div className="k-autoGenerateMonthPicker__calendar">
              {!selectedOpenShiftTemplate && (
                <KadroCalendarPopoverInput
                  inputValue={range.dateText}
                  selectedRange={range.date}
                  onRangeChange={e => this.changeRange(e, range.id)}
                  modifiers={popoverInputModifier}
                />
              )}
            </div>
            {!selectedOpenShiftTemplate && (
              <button
                className="k-autoGenerateMonthPicker__deleteIcon"
                onClick={() => {
                  this.deleteRange(range.id);
                }}
              >
                <i className="material-icons">delete_forever</i>
              </button>
            )}
          </fieldset>
        ))}
        {!selectedOpenShiftTemplate && (
          <button className="k-autoGenerateMonthPicker__add" onClick={this.addRange}>
            <i className="material-icons">add</i>
            <FormattedMessage id="schedule.autoGenerateMonth.addRange" defaultMessage="dodaj nowy okres" />
          </button>
        )}
        <div className={errorClassName}>{this.props.error}</div>
        {this.props.options}
      </div>
    );
  }
}

AutoGenerateMonthPicker.contextTypes = {
  intl: PropTypes.shape({}),
};

AutoGenerateMonthPicker.propTypes = {
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
    }),
    templatesArray: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    customDate: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }),
  }),
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  error: PropTypes.element,
  onRangeChange: PropTypes.func,
  options: PropTypes.node,
  selectedTemplate: PropTypes.string,
};

export default AutoGenerateMonthPicker;
