import PropTypes from 'prop-types';
import { useState } from 'react';
import { injectIntl } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes.js';

import messages from './messages';

import './BudgetMetricsTableOptions.scss';

const BudgetMetricsTableOptions = ({
  deleteBudgetMetrics,
  selectedData,
  showModal,
  intl,
  canEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRemoveAbsenceTypes = async () => {
    setLoading(true);
    await deleteBudgetMetrics(selectedData.map(({ id }) => id));
    setLoading(false);
  };

  return (
    <div className="k-budgetMetricsTableOptions">
      <Button
        className='k-budgetMetricsTableOptions__button'
        modifiers={['orange']}
        onClick={() => showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL)}
        disabled={!canEdit}
      >
        <i className="material-icons">add</i>
        {intl.formatMessage(messages.addButton)}
      </Button>
      {
        canEdit &&
        <Button
          className='k-budgetMetricsTableOptions__button'
          modifiers={['reverse-orange']}
          onClick={handleRemoveAbsenceTypes}
          disabled={!selectedData.length || loading}
        >
          <i className="material-icons">remove</i>
          {intl.formatMessage(messages.deleteButton)}
        </Button> 
      }
    </div>
  );
};

BudgetMetricsTableOptions.propTypes = {
  selectedData: PropTypes.arrayOf(PropTypes.shape({})),
  deleteBudgetMetrics: PropTypes.func,
  showModal: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  canEdit: PropTypes.bool,
};

export default injectIntl(BudgetMetricsTableOptions);
