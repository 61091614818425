import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';

import ColorPicker from '@/components/common/ColorPicker.jsx';
import { KadroMultiSelect, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { getAttachedEmployeesMessages } from '@/constants/attachedEmployees';
import { PRODUCTION_QUOTAS_GET } from '@/constants/Permissions.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import { useInputChange, useMultiSelect } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import {
  createJobTitleObject,
  getChangedSelectedEmployees,
  getEmployeesOptions,
  getInitialState,
  modalModifiers,
  updatedSelectedEmployeesId,
  updatedStateForEdit,
} from './AddJobTitleModal.helpers';
import { messages } from './AddJobTitleModal.messages';

const AddJobTitleModal = (props, context) => {
  const [state, handlers] = useInputChange(getInitialState(props.restrictions), context.intl);
  const [employeesItems, multiSelectHandlers] = useMultiSelect(getEmployeesOptions(props.userEmployees));

  const attachedEmployeesMessages = useMemo(() => getAttachedEmployeesMessages(context.intl), []);
  const editData = useMemo(() => props.userJobTitles.find(({ id }) => id === props.editedElementId), [
    props.editedElementId,
    props.userJobTitles,
  ]);
  const showProductionQuotasFactor = useMemo(() => props.permissions.includes(PRODUCTION_QUOTAS_GET), [
    props.permissions,
  ]);
  const preselectedEmployeesIds = useMemo(
    () => updatedSelectedEmployeesId(props.userEmployees, editData?.id, props.contracts),
    [props.userEmployees, props.editedElementId, props.contracts],
  );
  useEffect(() => {
    if (!props.editedElementId) return;
    const newState = { ...state, ...updatedStateForEdit(editData, showProductionQuotasFactor) };
    handlers.updateState(newState);
    multiSelectHandlers.setActiveItems(preselectedEmployeesIds);
  }, [props.userEmployees, props.editedElementId]);

  const changeColor = useCallback(color => {
    handlers.changeInput(createEvent('color', color));
  }, []);

  const validateAll = async () => {
    const inputs = ['jobTitle', 'wage', 'factor'];
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const clearModal = useCallback(() => {
    handlers.setInitState();
    multiSelectHandlers.deselectAll();
  }, []);

  const hideModal = useCallback(() => {
    props.toggleModal('jobTitles');
  }, []);

  const submit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    const attachedEmployees = getChangedSelectedEmployees(props.userEmployees, employeesItems, preselectedEmployeesIds);
    const jobTitleObject = createJobTitleObject(state, attachedEmployees);
    if (props.editedElementId) {
      props.prepareChangeJobTitle(jobTitleObject, props.editedElementId);
    } else {
      props.prepareAddJobTitle(jobTitleObject);
    }
    hideModal();
  }, [state, props.editedElementId, props.userEmployees, employeesItems, preselectedEmployeesIds]);

  return (
    <MDKadroModal
      show={props.visible}
      onHide={hideModal}
      title={context.intl.formatMessage(props.editedElementId ? messages.editJobTitle : messages.addJobTitle)}
      modifiers={modalModifiers}
      onSubmit={submit}
      onHideEnd={clearModal}
      testId="jobTitle"
    >
      <MDTextInput
        value={state.jobTitle}
        onChange={handlers.changeInput}
        id="jobTitle"
        label={context.intl.formatMessage(messages.jobTitle)}
        errorMessage={state.errors.jobTitle}
        modifiers="modal"
        required
        testId="jobTitle"
      />
      <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
        <MDTextInput
          value={state.wage}
          onChange={handlers.changeInput}
          type="number"
          id="wage"
          label={context.intl.formatMessage(messages.wage)}
          errorMessage={state.errors.wage}
          modifiers="modal"
          testId="wage"
        />
      </FeatureWrapper>
      <fieldset className="form-group">
        <label htmlFor="nazwa" className="k-textInput__label k-textInput__label--side">
          {context.intl.formatMessage(messages.color)}
        </label>
        <ColorPicker color={state.color} colorFunc={changeColor} testId="jobTitlesColorPicker" />
      </fieldset>
      {showProductionQuotasFactor && (
        <MDTextInput
          value={state.factor}
          onChange={handlers.changeInput}
          type="number"
          id="factor"
          label={context.intl.formatMessage(messages.factor)}
          errorMessage={state.errors.factor}
          modifiers="modal"
        />
      )}
      {props.editedElementId && (
        <KadroMultiSelect
          messages={attachedEmployeesMessages}
          items={employeesItems}
          handlers={multiSelectHandlers}
          multiSelectTestId="jobTitlesMultiSelect"
          selectAllTestId="jobTitlesSelectAll"
          withoutSelectAll
          withoutDeselectAll
          disabled
          withoutDropdown
        />
      )}
    </MDKadroModal>
  );
};

AddJobTitleModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddJobTitleModal.propTypes = {
  editedElementId: PropTypes.string,
  prepareChangeJobTitle: PropTypes.func,
  prepareAddJobTitle: PropTypes.func,
  toggleModal: PropTypes.func,
  visible: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AddJobTitleModal;
