import moment from 'moment';

import { GET_WEATHER_FORECAST_SUCCESS } from '@/constants/ActionTypes';
import { getRangeBetweenDates } from '@/utils/dateHelper.js';
import { getLocationsWithCoordinates, mapWeatherDataToState } from '@/utils/weatherForecastHelpers.js';

import { conn } from './index';

export const getWeatherForecastSuccess = forecast => ({
  type: GET_WEATHER_FORECAST_SUCCESS,
  payload: { ...forecast },
});

export const getWeatherForecast = () => (dispatch, getState) => {
  const { reducer } = getState();
  const { scheduleLocationFilter, userLocations, currentUser, mainDateStore } = reducer;
  const { today, dateArray } = mainDateStore;
  const { language } = currentUser.user.settings;
  const fifteenDaysAfterToday = moment(today).add(15, 'd');
  const forecastDateRange = getRangeBetweenDates(today, fifteenDaysAfterToday);
  const isForecastDateRangeInScheduleDateRange = forecastDateRange.some(date => dateArray.includes(date));
  const locationsWithCoordinates = getLocationsWithCoordinates(scheduleLocationFilter, userLocations);
  if (locationsWithCoordinates.length > 0 && isForecastDateRangeInScheduleDateRange) {
    conn.getWeatherForecast(locationsWithCoordinates, language).then(result => {
      const mappedData = mapWeatherDataToState(result.data);
      dispatch(getWeatherForecastSuccess(mappedData));
    });
  }
};
