import L from 'leaflet';
import pinIco from 'leaflet/dist/images/marker-icon-2x.png';
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: { id: 'attendance.map.title', defaultMessage: 'Zarejestrowana obecność' },
  entry: { id: 'common.entry', defaultMessage: 'Wejście' },
  quit: { id: 'common.quit', defaultMessage: 'Wyjście' },
});

const startPinIco = L.icon({
  iconUrl: pinIco,
  iconSize: [20, 32],
  iconAnchor: [11, 32],
  popupAnchor: [0, -32],
});

const endPinIco = L.icon({
  iconUrl: pinIco,
  iconSize: [18, 30],
  iconAnchor: [8, 30],
  popupAnchor: [0, -30],
});

export const getAttendanceMarkers = (startAttendanceCoords, endAttendanceCoords) => {
  const markers = [];
  if (startAttendanceCoords.lat && startAttendanceCoords.lon) {
    markers.push({ position: startAttendanceCoords, icon: startPinIco, popup: messages.entry });
  }
  if (endAttendanceCoords.lat && endAttendanceCoords.lon) {
    markers.push({ position: endAttendanceCoords, icon: endPinIco, popup: messages.quit });
  }
  return markers;
};
