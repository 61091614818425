import { hoursToMinutes, isHourFulfilled } from '@/utils/dateHelper';

export const combineLegacyAndNewOvertimeCollections = (legacyOvertimeCollections, newOvertimeCollections) => {
  const combined = Object.values(legacyOvertimeCollections).reduce((acc, legacyOvertimes) => {
    legacyOvertimes.forEach(overtime => {
      acc[overtime.employee_id] = acc[overtime.employee_id] || [];
      acc[overtime.employee_id].push({ ...overtime, isLegacyOvertime: true });
    });
    return acc;
  }, {});

  Object.values(newOvertimeCollections).forEach(overtimes => {
    overtimes.forEach(overtime => {
      combined[overtime.employee_id] = combined[overtime.employee_id] || [];
      combined[overtime.employee_id].push({ ...overtime, isLegacyOvertime: false });
    });
  });
  return combined;
};

export const getSumOfOvertimeAcceptancesInMinutes = (amount50, amount100, amountPotential) => {
  if (![amount50, amount100, amountPotential].every(isHourFulfilled)) return 0;
  const [amount50InMinutes, amount100InMinutes, amountPotentialInMinutes] = [amount50, amount100, amountPotential].map(
    hoursToMinutes,
  );
  return amount50InMinutes + amount100InMinutes + amountPotentialInMinutes;
};
