import moment from 'moment';
import { defineMessages } from 'react-intl';

import { pullAvailabilitiesForEmployee,pullAvailabilitiesForLocation } from '@/connections/availabilities.js';
import * as actionTypes from '@/constants/ActionTypes.js';
import { checkAvailabilityOverlap } from '@/utils/schedulerHelpers.js';

import { addAvailability,addObjWithRepeat, conn, connectionError } from './index';

const messages = defineMessages({
  repeatAvailabilitiesErrorEmployee: {
    id: 'error.repeatAvailabilities.employee',
    defaultMessage: 'Nie udało się dodać dostępności dla danego pracownika',
  },
  repeatAvailabilitiesErrorOverlap: {
    id: 'error.repeatAvailabilities.overlap',
    defaultMessage: 'Powtarzające się dostępności nachodzą na inne dostępności',
  },
  repeatAvailabilitiesErrorDisabled: {
    id: 'error.repeatAvailabilities.disabled',
    defaultMessage: 'Nie możesz dodać dostępności dla wybranych dat',
  },
});

export const updateAddEmpAva = avaObject => ({
  type: actionTypes.ADD_EMP_AVA,
  payload: avaObject,
});

export const updateEditEmpAva = avaObject => ({
  type: actionTypes.UPDATE_EMP_AVA,
  payload: avaObject,
});

export const updateDeleteEmpAva = avaObject => ({
  type: actionTypes.DELETE_EMP_AVA,
  payload: avaObject,
});

export const getCurrentAvailabilitySuccesful = (employeeId, availabilities) => ({
  type: actionTypes.UPDATE_EMPLOYEE_AVAILABILITIES,
  payload: {
    employee: {
      id: employeeId,
    },
    availability_blocks: availabilities,
  },
});

export const getAvailabilitySuccesful = availabilities => ({
  type: actionTypes.GET_AVAILABILITIES_SUCCESFUL,
  // map to old availability format
  // we could remove this once we will only use new single endpoints
  payload: availabilities.map(availability => ({
    ...availability,
    id: String(availability.id),
    date: availability.start_at.slice(0, 10),
    hours: `${availability.start_at.slice(11, 16)}-${availability.end_at.slice(11, 16)}`,
  })),
});

export const addEmpAva = avaObject => dispatch => {
  dispatch(updateAddEmpAva(avaObject));
};

export const editEmpAva = avaObject => dispatch => {
  dispatch(updateEditEmpAva(avaObject));
};

export const deleteEmpAva = avaObject => dispatch => {
  dispatch(updateDeleteEmpAva(avaObject));
};

export const getCurrentAvailability = (from, to, requestType) => (dispatch, getState) => {
  const { user } = getState().reducer.currentUser;
  conn
    .getCurrentAvailability(from, to, requestType)
    .then(response => {
      dispatch(getCurrentAvailabilitySuccesful(user.id, response.data.availability_blocks));
    })
    .catch(err => {
      connectionError(err);
    });
};

export const getAvailabilitiesForLocation = (locationId, from, to, employeeIds, requestType) => dispatch =>
  pullAvailabilitiesForLocation(locationId, from, to, employeeIds, requestType).then(response => {
    dispatch(getAvailabilitySuccesful(response.data));
  });

export const getAvailabilitiesForEmployee = (employeeId, from, to, locationIds, requestType) => dispatch =>
  pullAvailabilitiesForEmployee(employeeId, from, to, locationIds, requestType).then(response => {
    dispatch(getAvailabilitySuccesful(response.data));
  });

export const addAvailabilitiesWithRepeat = (employeeId, availability, repeatObj) => (dispatch, getState) => {
  const { userEmployees, currentCompany } = getState().reducer;

  const relevantEmployee = userEmployees.find(e => e.id === employeeId);
  if (!relevantEmployee) return messages.repeatAvailabilitiesErrorEmployee;

  const availabilitiesToAdd = addObjWithRepeat(availability, repeatObj);
  const disabledDate = currentCompany.settings.disable_availabilities_edit_until;

  let overlaps = false;
  let disabled = false;
  availabilitiesToAdd.some(a => {
    if (checkAvailabilityOverlap(relevantEmployee, a)) {
      overlaps = true;
      return true;
    }
    if (disabledDate && moment(a.date).isSameOrBefore(disabledDate)) {
      disabled = true;
      return true;
    }
    return false;
  });
  if (overlaps) return messages.repeatAvailabilitiesErrorOverlap;
  if (disabled) return messages.repeatAvailabilitiesErrorDisabled;

  availabilitiesToAdd.forEach(availabilityToAdd => {
    dispatch(addAvailability(availabilityToAdd, false));
  });
  return '';
};
