import { memo, useCallback, useEffect, useMemo } from 'react';

import { showModal } from '@/actions/uiState';
import { clearWorkingRulesForEmployee } from '@/actions/workingRules';
import WorkingRulesIcon from '@/components/common/WorkingRulesIcon.jsx';
import { CONFLICTS_MODAL } from '@/constants/modalTypes.js';
import { workingRulesForEmployeesWithBillingPeriodGreaterThanOneMonth } from '@/constants/workingRules';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectShiftsForEmployee } from '@/redux-store/shifts';
import { selectRearrangingEmployeesOrder } from '@/redux-store/uiState';
import { selectUserEmployeeById } from '@/redux-store/userEmployees';
import { selectViolationsPerEmployee } from '@/redux-store/workingRules';
import { EmployeeWhole } from '@/types';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

type Props = {
  employeeId: EmployeeWhole['id'];
};

export const ScheduleTableRowTitleKPIcon = memo(({ employeeId }: Props) => {
  const dispatch = useAppDispatch();
  const userEmployee = useAppSelector(selectUserEmployeeById(employeeId));
  const employeeShifts = useAppSelector(selectShiftsForEmployee(employeeId));
  const rearrangingEmployeesOrder = useAppSelector(selectRearrangingEmployeesOrder);
  const workingRulesViolations = useAppSelector(selectViolationsPerEmployee(employeeId));

  const employeeScheduleCycle = useMemo(
    () => userEmployee?.employment_conditions?.schedule_cycle?.duration,
    [userEmployee],
  );

  const filteredWorkingRulesViolations = useMemo(
    () =>
      workingRulesViolations?.filter(rule =>
        employeeScheduleCycle > 1
          ? workingRulesForEmployeesWithBillingPeriodGreaterThanOneMonth.includes(rule.code)
          : true,
      ),
    [workingRulesViolations, employeeScheduleCycle],
  );

  const hasNoShifts = isEmptyObject(employeeShifts);

  useEffect(() => {
    if (hasNoShifts) dispatch(clearWorkingRulesForEmployee(employeeId));
  }, [dispatch, hasNoShifts, employeeId]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      dispatch(
        showModal(CONFLICTS_MODAL, {
          conflicts: filteredWorkingRulesViolations,
        }),
      );
    },
    [dispatch, filteredWorkingRulesViolations],
  );

  if (hasNoShifts || rearrangingEmployeesOrder || !filteredWorkingRulesViolations?.length) return null;

  return (
    <button type="button" onClick={onClick}>
      <WorkingRulesIcon number={filteredWorkingRulesViolations?.length} />
    </button>
  );
});

ScheduleTableRowTitleKPIcon.displayName = 'ScheduleTableRowTitleKPIcon';
