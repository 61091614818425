import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import PresentationWrapper from '@/components/common/PresentationWrapper.jsx';
import HelpModal from '@/containers/HelpModalContainer.js';

const PayrollHelpModal = () => (
  <HelpModal title={<FormattedMessage id="common.payroll" defaultMessage="Karta Pracownika" />}>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowRight" defaultMessage="Strzałka w prawo" />
        </strong>
      </td>
      <td>
        <FormattedMessage id="help.goToNextSpanInCalendar" defaultMessage="Przejdź do następnego okresu w kalendarzu" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowLeft" defaultMessage="Strzałka w lewo" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.goToPrevSpanInCalendar"
          defaultMessage="Przejdź do poprzedniego okresu w kalendarzu"
        />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
          <br />
          <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.zoomDescription"
          defaultMessage='  Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.locations" defaultMessage="Lokalizacje" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payroll.locationsDesc"
          defaultMessage="Wybierz lokalizację z listy dla której chcesz wyświetlić obecności pracownika"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.employee" defaultMessage="Pracownik" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payroll.employeeDesc"
          defaultMessage="Wybierz pracownika dla którego wyświetlić obecności"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.wageCalcMode" defaultMessage="Rozliczenie" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payrollLoc.wageCalcModeDesc"
          defaultMessage="Wybierz sposób obliczania wypłaty pracowników:"
        />
        <br />
        <FormattedMessage
          id="help.payrollLoc.wageCalcModeDesc.attendance"
          defaultMessage="- Czas pracy - ewidencja godzin jest obliczana na podstawie obecności pracowników."
        />
        <br />
        <FormattedMessage
          id="help.payrollLoc.wageCalcModeDesc.shifts"
          defaultMessage="- Grafik pracy - czas pracy jest obliczany na podstawie grafiku."
        />
        <br />
        <FormattedMessage
          id="help.payrollLoc.wageCalcModeDesc.attendanceNoEarly"
          defaultMessage="- Czas pracy bez wczesnego przyjścia - ewidencja godzin jest obliczana na podstawie obecności, ale nie uwzględnia czasu obecności przed początkiem zmiany."
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="payrollLocation.rounding" defaultMessage="Zaokrąglenia" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payrollLoc.roundingDesc"
          defaultMessage="Suma godzin może być zaokrąglana do pełnej ilości godzin, lub z dokładnością do 30, 15, 1 minuty."
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="payroll.photos" defaultMessage="Zdjęcia" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payrollLoc.photosDesc"
          defaultMessage="Można wyświetlić zdjęcia wykonane podczas używania RCP"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.returnToToday" defaultMessage="Powrót do dzisiaj" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.payrollLocation.returnToTodayDesc"
          defaultMessage="Pokaż okres zawierający dzisiejszy dzień "
        />
      </td>
    </tr>

    <tr>
      <td>◀</td>
      <td>
        <FormattedMessage id="help.prevSpan" defaultMessage="Poprzedni okres" />
      </td>
    </tr>
    <tr>
      <td>▶</td>
      <td>
        <FormattedMessage id="help.nextSpan" defaultMessage="Następny okres" />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-blue small floatright">
            <i className="material-icons mirrorFlip">reply</i>
            <FormattedMessage id="export" defaultMessage="Eksport" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.payroll.exportDesc"
          defaultMessage='Kliknij przycisk "Eksport" aby wyeksportować kartę pracownika dla zaznaczonego okresu.'
        />
      </td>
    </tr>
  </HelpModal>
);

PayrollHelpModal.propTypes = {};

export default PayrollHelpModal;
