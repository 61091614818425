import moment from 'moment';

import { KadroLocaleEnum, LocaleConfigs } from '@/types/locale.types';

import enMessages from '../../../intl/compiledLang/en.json';
import plMessages from '../../../intl/compiledLang/pl.json';
import plAdeccoMessages from '../../../intl/lang/pl-adecco.json';
import { enGbMoment } from './moment/en-gb';
import { plMoment } from './moment/pl';

export const localeConfigs: LocaleConfigs = {
  [KadroLocaleEnum.PL]: {
    dateFnsLocale: require('date-fns/locale/pl'),
    kadroExportLanguage: 'pl',
    momentLanguage: 'pl',
    momentLocale: plMoment,
    weatherLanguage: 'pl',
  },
  [KadroLocaleEnum.EN]: {
    dateFnsLocale: require('date-fns/locale/en-GB'),
    kadroExportLanguage: 'en',
    momentLanguage: 'en',
    momentLocale: enGbMoment,
    weatherLanguage: 'en',
  },
  [KadroLocaleEnum.PL_ADECCO]: {
    dateFnsLocale: require('date-fns/locale/pl'),
    kadroExportLanguage: 'pl',
    momentLanguage: 'pl',
    momentLocale: plMoment,
    weatherLanguage: 'pl',
  },
};

export const getLocaleConfig = (locale: KadroLocaleEnum) => {
  const config = localeConfigs[locale];
  if (!config) throw new Error(`Locale ${locale} is not supported`);
  return config;
};

export const loadLocaleData = (locale: KadroLocaleEnum) => {
  const config = getLocaleConfig(locale);
  moment.updateLocale(config.momentLanguage, config.momentLocale);

  switch (locale) {
    case KadroLocaleEnum.PL_ADECCO:
      return plAdeccoMessages;
    case KadroLocaleEnum.EN:
      return enMessages;
    case KadroLocaleEnum.PL:
    default:
      return plMessages;
  }
};
