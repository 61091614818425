import { isEmptyArray } from '@/utils/array/array.helpers';
import { isAttendanceEditDisabled } from '@/utils/attendanceHelpers';
import { getFirstMatchingLocationOption } from '@/utils/locationsHelpers';

export const getRelevantLocationsAsOptions = (scheduleLocationFilter, userLocations) => {
  if (!scheduleLocationFilter || !userLocations) return null;
  return userLocations.reduce(
    (agg, userLocation) =>
      scheduleLocationFilter.some(scheduleLocation => scheduleLocation === userLocation.id)
        ? [...agg, { value: userLocation.id, key: userLocation.name }]
        : agg,

    [],
  );
};

export const getRelevantLocationsAsOptionsForLoanedEmployee = (
  userLocations,
  loanEmployeesProposalsAssignments,
  employeeId,
  selectedLocationsIds,
  openShiftsAssignments,
) =>
  userLocations.reduce(
    (agg, userLocation) =>
      selectedLocationsIds.some(
        scheduleLocation =>
          scheduleLocation === userLocation.id &&
          (loanEmployeesProposalsAssignments.some(
            loanEmployeesProposalsAssignments =>
              loanEmployeesProposalsAssignments.location_id === scheduleLocation &&
              loanEmployeesProposalsAssignments.employee_id === employeeId,
          ) ||
            openShiftsAssignments?.some(
              assignment => assignment.location_id === scheduleLocation && assignment.employee_id === employeeId,
            )),
      )
        ? [...agg, { value: userLocation.id, key: userLocation.name }]
        : agg,
    [],
  );

export const getSingleEmployeeFilterLocationsAsOptions = singleEmployeeFilter =>
  singleEmployeeFilter.locations.map(location => ({
    value: location.id,
    key: location.name,
  }));

export const getRelevantEmployeesAsOptions = (
  userEmployees,
  locationId,
  companyRoles,
  currentUser,
  userPermissions,
) => {
  if (!userEmployees || !locationId) return [];

  return userEmployees.reduce((agg, userEmployee) => {
    const isEditDisabled = isAttendanceEditDisabled(userPermissions, currentUser, userEmployee, companyRoles);
    if (
      userEmployee.locations.some(userEmployeeLocation => userEmployeeLocation.id === locationId) &&
      !isEditDisabled &&
      !userEmployee.inactive
    ) {
      return [...agg, { value: userEmployee.id, key: `${userEmployee.first_name} ${userEmployee.last_name}` }];
    }
    return agg;
  }, []);
};

export const getLocationValue = (
  relevantLocations,
  useMultipleLocationFilter,
  multipleLocationFilter,
  scheduleLocationFilter,
) => {
  if (isEmptyArray(relevantLocations)) return null;
  if (useMultipleLocationFilter)
    return getFirstMatchingLocationOption(multipleLocationFilter, relevantLocations)?.value;
  return getFirstMatchingLocationOption(scheduleLocationFilter, relevantLocations)?.value;
};
