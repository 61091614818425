import { connect } from 'react-redux';

import ObjectHistoryModal from '@/components/objectHistory/LegacyObjectHistory.tsx';
import { OBJECT_HISTORY_MODAL } from '@/constants/modalTypes.js';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[OBJECT_HISTORY_MODAL],
  userJobTitles: state.reducer.userJobTitles,
  userEmployees: state.reducer.userEmployees,
});

const LegacyObjectHistory = connect(mapStateToProps, null)(ObjectHistoryModal);

export default LegacyObjectHistory;
