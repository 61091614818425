import { validateAllBonusTargetOptions } from '../../locationSettingsComponents/BonusTargetOptions/BonusTargetOptions.helpers';
import { validateAllWorkingHours } from '../../locationSettingsComponents/WorkingHoursInputs/WorkingHoursInputs.helpers';
import { budgetEstimatesPeriodMessages } from './BudgetOptions.messages';

export const getBudgetEstimatesPeriodOptions = intl => {
  const idsOptions = ['15minutes', 'hour', 'day'];
  return idsOptions.map(id => ({ name: intl.formatMessage(budgetEstimatesPeriodMessages[id]), id }));
};

export const roundingPrecisionTargetOptions = {
  bonusPercentage: 2,
  spmhThreshold: 2,
};

const initialBonusHoursBlocks = [
  { id: 0, workingHours: '__:__-__:__', error: '' },
  { id: 1, workingHours: '__:__-__:__', error: '' },
];

export const getInitialBonusHoursBlocks = bonusHoursBlocks => {
  if (!bonusHoursBlocks) return null;
  if (!bonusHoursBlocks.length) return initialBonusHoursBlocks;
  return bonusHoursBlocks.map((hoursBlock, index) => ({
    id: index,
    workingHours: `${hoursBlock.start_hour}-${hoursBlock.end_hour}`,
    error: '',
  }));
};

const initialBonusTargetOptions = [
  { id: 0, bonusPercentage: null, spmhThreshold: null, errors: { bonusPercentage: '', spmhThreshold: '' } },
  { id: 1, bonusPercentage: null, spmhThreshold: null, errors: { bonusPercentage: '', spmhThreshold: '' } },
];

export const getInitialBonusTargetOptions = bonusTargetOptions => {
  if (!bonusTargetOptions) return null;
  if (!bonusTargetOptions.length) return initialBonusTargetOptions;
  return bonusTargetOptions.map((targetOptions, index) => ({
    bonusPercentage: targetOptions.bonus_percentage.toFixed(roundingPrecisionTargetOptions.bonusPercentage),
    id: index,
    spmhThreshold: targetOptions.spmh_threshold.toFixed(roundingPrecisionTargetOptions.spmhThreshold),
    errors: { bonusPercentage: '', spmhThreshold: '' },
  }));
};

export const validateBonusSettings = ({ locationSystemBonusEnabled, bonusTargetOptions, bonusHoursBlocks }, intl) => {
  if (!locationSystemBonusEnabled)
    return {
      areBonusSettingsCorrect: true,
      bonusHoursBlocks,
      bonusTargetOptions,
      bonusHoursOverlapsError: false,
    };

  const [isBonusHoursBlocksError, bonusHoursOverlapsError, newBonusHoursBlocks] = validateAllWorkingHours(
    bonusHoursBlocks,
    intl,
  );
  const [isBonutTargetOptionsError, newBonusTargetOptions] = validateAllBonusTargetOptions(bonusTargetOptions, intl);
  const areBonusSettingsCorrect =
    !locationSystemBonusEnabled || (!isBonusHoursBlocksError && !isBonutTargetOptionsError && !bonusHoursOverlapsError);

  return {
    areBonusSettingsCorrect,
    bonusHoursBlocks: newBonusHoursBlocks,
    bonusTargetOptions: newBonusTargetOptions,
    bonusHoursOverlapsError,
  };
};
