import { connect } from 'react-redux';

import {
  selectAllDisplaySettings,
  selectAllJobTitlesGroupedAndEmploymentConditions,
  selectFirstLocation,
} from '@/actions/filters.js';
import { changeScheduleSearchString } from '@/actions/uiState.js';

import EmptyFilterView from './EmptyFilterView.jsx';

const mapStateToProps = ({ reducer }) => ({
  searchString: reducer.scheduleUIState.searchString,
  scheduleLocationFilter: reducer.scheduleLocationFilter,
  areFiltersNotSelected: !reducer.scheduleLocationFilter.length,
});

const mapDispatchToProps = {
  changeScheduleSearchString,
  selectAllJobTitlesGroupedAndEmploymentConditions,
  selectFirstLocation,
  selectAllDisplaySettings,
};

const EmptyFilterViewContainer = connect(mapStateToProps, mapDispatchToProps)(EmptyFilterView);

export default EmptyFilterViewContainer;
