import { DATE_MATCH_REGEXP } from '@/constants/settingsConstants';
import { FilterParamEnum } from '@/constants/ui.ts';
import { generateQueryParametersFromSearchString } from '@/utils/urlModifyHelpers.ts';

export const areFiltersPresentInUrl = (): boolean => {
  const { location } = window;
  const query = generateQueryParametersFromSearchString(location.search);

  return Object.values(FilterParamEnum).some(param => !!query[param]);
};

export const doCustomFiltersExist = () => {
  const { search } = window.location;
  const query = generateQueryParametersFromSearchString(search);
  const { from: queryFrom, to: queryTo } = query as { from?: string; to?: string };
  const dateFrom = queryFrom?.match(DATE_MATCH_REGEXP) ? queryFrom : '';
  const dateTo = queryTo?.match(DATE_MATCH_REGEXP) ? queryTo : '';

  return !!dateFrom && !!dateTo;
};

export const getRelevantValueDependOnQuery = (query: string, localStorageValue: { id: string }[]) => {
  if (query === 'null') return [];
  return query?.split(',') || localStorageValue;
};

export const getMatchingValues = (targetValues: string[], referenceValues: { id: string }[]) =>
  targetValues.some(item => typeof item === 'object')
    ? targetValues
    : referenceValues.filter(({ id }) => targetValues.includes(id));
