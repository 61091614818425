import { useEffect, useState } from 'react';

export const getPositionOfPreviousStickyElement = (currentElement, stickyClass) => {
  const stickyElements = currentElement?.parentElement.querySelectorAll(`.${stickyClass}`);

  if (!stickyElements) return 0;

  let position = 0;

  for (const stickyElement of stickyElements) {
    if (stickyElement === currentElement) break;
    position += stickyElement.clientHeight;
  }

  return position;
};

export const useMultipleStickyElements = (currentElementRef, stickyClass) => {
  const [top, setTop] = useState(0);
  const [updatePosition, setUpdatePosition] = useState(true);

  const mutationObserver = new MutationObserver((observedElements) => {
    const shouldUpdates = observedElements.some((element) => element.type === 'childList' ||
      (element.type === 'attributes' && ['style', 'class'].includes(element.attributeName)));

    if (shouldUpdates)
      setUpdatePosition((value) => !value);
  });

  useEffect(() => {
    setTop(getPositionOfPreviousStickyElement(currentElementRef.current, stickyClass));

    if (currentElementRef.current) {
      mutationObserver.disconnect();
      mutationObserver.observe(currentElementRef.current.parentElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      mutationObserver.disconnect();
    };
  }, [currentElementRef, updatePosition]);
  

  return [top];
};