import { connect } from 'react-redux';

 import { showModal } from '@/actions/uiState.js';

import DevicesButtonBar from './DevicesButtonBar.jsx';
import { massDeleteDevicesConfirm } from '@/actions/companymanage/devices.jsx';

const mapStateToProps = state => ({
  listsUi: state.reducer.listsUi,
});

const mapDispatchToProps = {
  showModal,
  massDeleteDevicesConfirm,
};

const DevicesButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(DevicesButtonBar);

export default DevicesButtonBarContainer;
