import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import Range from './Range.jsx';

const labelPresets = {
  mh: { first: 'min', second: 'h' },
  sm: { first: 'sec', second: 'm' },
};

class MinuteHourInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || 0,
      error: false,
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    if (!Number.isNaN(this.props.value) && this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  changeFunc(e) {
    const value = parseInt(e.target.value);
    let error = false;
    if ((this.props.min !== null && value < this.props.min) || (this.props.max && value > this.props.max)) {
      error = true;
    }
    this.props.onChangeFunc({ target: { value, type: this.props.type || 'range', name: this.props.labelId } });
    this.setState({ value, error });
  }

  rangeChangeFunc(value) {
    if (this.props.toleranceDisabled) return;
    let error = false;
    if ((this.props.min !== null && value < this.props.min) || (this.props.max && value > this.props.max)) {
      error = true;
    }
    this.props.onChangeFunc({ target: { value, type: this.props.type || 'range', name: this.props.labelId } });
    this.setState({ value, error });
  }

  render() {
    const { toleranceDisabled, modifiers } = this.props;
    const modifiersArray = modifiers || [];
    const classNames = classnames(
      'minuteHourInputContainer',
      ...modifiersArray.map(modifier => `minuteHourInputContainer--${modifier}`),
    );
    const { first, second } = labelPresets[this.props.labelType || 'mh'];
    const minValue = toleranceDisabled ? '-' : first;
    const hourValue = toleranceDisabled ? '- ' : `${Math.round((10 * this.state.value) / 60) / 10}${second}`;
    return (
      <div style={{ float: 'right', clear: 'right' }} className={classNames}>
        <Range
          value={this.state.value}
          onChangeFunc={this.rangeChangeFunc}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          style={{ minWidth: '0', float: 'left' }}
          labelId={this.props.labelId}
          disabled={toleranceDisabled}
        />
        <div className="min-hour-input">
          <input
            type="number"
            onChange={this.changeFunc}
            value={toleranceDisabled ? '' : this.state.value}
            disabled={toleranceDisabled}
            className="min-hour-input__input"
          />
          <div className="min-text">{minValue}</div>
          <div className="hour-label">{hourValue}</div>
          {this.state.error ? <div className="error-label">{this.props.errorMsg}</div> : null}
        </div>
      </div>
    );
  }
}

MinuteHourInput.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorMsg: PropTypes.string,
  labelId: PropTypes.string,
  type: PropTypes.string,
  toleranceDisabled: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default MinuteHourInput;
