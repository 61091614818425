import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import PublishButton from '@/components/common/PublishButton.jsx';
import { getRelevantEmployees } from '@/components/TopBars/filters/TopBarEmployeeFilter/topBarEmployeeFilterHelpers.js';

const PayrollLoadDataButton = props => {
  const {
    getPayrollViewData,
    isLoadPayrollButtonEnabled,
    multipleLocationFilter,
    currentUser,
    userEmployees,
    userPermissions,
    companyRoles,
    companySettings,
    payrollLoanedEmployees,
  } = props;
  const isEmployeeSelected = useMemo(
    () =>
      getRelevantEmployees(
        currentUser,
        multipleLocationFilter,
        userEmployees,
        userPermissions,
        companyRoles,
        companySettings,
        payrollLoanedEmployees,
      ).length > 0,
    [
      currentUser,
      multipleLocationFilter,
      userEmployees,
      userPermissions,
      companyRoles,
      companySettings,
      payrollLoanedEmployees,
    ],
  );

  const isButtonEnabled = isLoadPayrollButtonEnabled && multipleLocationFilter.length > 0 && isEmployeeSelected;
  const onClick = useCallback(() => {
    getPayrollViewData();
  }, [getPayrollViewData]);

  return (
    <PublishButton
      text={<FormattedMessage id="loadData" defaultMessage="Wczytaj" />}
      onClickFunc={onClick}
      key="getData"
      disabled={!isButtonEnabled}
      icon="update"
    />
  );
};

PayrollLoadDataButton.propTypes = {
  getPayrollViewData: PropTypes.func,
  isLoadPayrollButtonEnabled: PropTypes.bool,
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.shape({}),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({}),
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  companySettings: PropTypes.shape({}),
  payrollLoanedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default PayrollLoadDataButton;
