import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MDMultiSelect,
  MDMultiSelectFooter,
  MDMultiSelectHeader,
  MDTextInput,
  MDTimeInput,
} from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { getTimeFormatFromHourAndMinutes } from '@/utils/dateHelper';
import { inputValidation } from '@/utils/inputValidation.js';

import './AddShift.css';

class AddShiftBlock extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.startValue = null;
    this.preSelected = null;

    bindPrototypeFunctions(this);
  }

  getInitialState() {
    let hoursString = '__:__-__:__';
    if (this.props.edit) hoursString = this.props.startValue;
    return {
      start: null,
      end: null,
      duration: '-',
      jobList: this.getJobList(this.props.jobTitles),
      timerange: hoursString,
      errors: { time: '' },
    };
  }

  getJobList(jobList, prevSelected) {
    return jobList.map(({ id, title, color }) => ({
      value: id,
      label: title,
      active: this.isJobActive(prevSelected || [], id),
      color,
    }));
  }

  isJobActive(selected, id) {
    return selected.includes(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.edit) {
      this.startValue = nextProps.editData.working_hours;
      const tempObj = this.calcTime(this.startValue);
      this.setState(
        tempObj,
        (() => {
          this.setState({
            jobList: this.getJobList(this.props.jobTitles, nextProps.editData.job_titles),
          });
        }).bind(this, nextProps),
      );
    } else {
      this.setState({ preSelected: null, timerange: '__:__-__:__' });
    }
  }

  makeWorkingHours() {
    const { hour: startHour, minutes: startMinutes } = this.state.start;
    const { hour: endHour, minutes: endMinutes } = this.state.end;

    return `${getTimeFormatFromHourAndMinutes(startHour, startMinutes)}-${getTimeFormatFromHourAndMinutes(
      endHour,
      endMinutes,
    )}`;
  }

  async validateAll() {
    const inputs = ['timerange'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    let canContinue = true;
    Object.values(this.state.errors).map(err => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  submit() {
    return this.validateAll().then(valid => {
      if (!valid) return;
      if (this.props.edit) {
        this.props.submitFunc({
          id: this.props.editData.id,
          working_hours: this.makeWorkingHours(),
          job_titles: this.getValuesOfSelectedJobs(),
        });
      } else {
        this.props.submitFunc({
          working_hours: this.makeWorkingHours(),
          job_titles: this.getValuesOfSelectedJobs(),
        });
      }

      this.props.closingFunc();
      this.clear();
    });
  }

  getValuesOfSelectedJobs() {
    return this.getSelectedJobs().reduce((values, item) => [...values, item.value], []);
  }

  getSelectedJobs() {
    return this.state.jobList.filter(({ active }) => active);
  }

  validateInput(event) {
    return new Promise(resolve => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      const error = inputValidation(name, value);
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  abort() {
    this.props.closingFunc();
    this.clear();
  }

  clear() {
    this.setState(this.getInitialState());
  }

  setTime(e, altInp) {
    let val;
    if (e == null) {
      val = altInp;
    } else {
      val = e.target.value;
    }

    this.setState(this.calcTime(val));
  }

  calcTime(val) {
    let { start, end } = this.state;
    let duration = null;
    let i = 0;
    if (val.slice(0, 5).search('_') < 0) {
      start = {
        hour: parseInt(val.slice(0, 2), 10),
        minutes: parseInt(val.slice(3, 5), 10),
      };
      i++;
    }
    if (val.slice(6, 11).search('_') < 0) {
      end = {
        hour: parseInt(val.slice(6, 8), 10),
        minutes: parseInt(val.slice(9, 11), 10),
      };
      i++;
    }
    if (i === 2) {
      let hours = end.hour - start.hour;
      let minutes = end.minutes - start.minutes;
      if (minutes < 0) {
        hours--;
        minutes += 60;
      }
      if (hours < 0) hours += 24;
      duration = (hours > 0 ? `${hours}h ` : '') + (minutes > 0 ? `${minutes}m` : '');
    }
    return { start, end, duration, timerange: val };
  }

  handleChangeList(e) {
    this.setState(prevState => ({
      jobList: prevState.jobList.map(job => {
        const active = job.value === e.value ? !job.active : job.active;
        return { ...job, active };
      }),
    }));
  }

  selectAll() {
    this.setState(prevState => ({
      jobList: prevState.jobList.map(job => ({ ...job, active: true })),
    }));
  }

  deselectAll() {
    this.setState(prevState => ({
      jobList: prevState.jobList.map(job => ({ ...job, active: false })),
    }));
  }

  render() {
    return (
      <MDKadroModal
        show={this.props.visible}
        onHide={this.abort}
        modifiers={['narrow']}
        title={
          this.props.edit ? (
            <FormattedMessage id="companymanage.shiftBlocks.editShiftBlock" defaultMessage="Edytuj zmianę" />
          ) : (
            <FormattedMessage id="companymanage.shiftBlocks.addShiftBlock" defaultMessage="Dodaj zmianę" />
          )
        }
        onSubmit={this.submit}
        testId="shiftBlock"
      >
        <MDTimeInput
          label={<FormattedMessage id="companymanage.shiftBlocks.time" defaultMessage="Czas" />}
          value={this.state.timerange || '__:__-__:__'}
          onChange={this.setTime}
          id="time"
          onBlur={e => {
            this.validateInput({ ...e, target: { ...e.target, name: 'timerange' } });
          }}
          errorMessage={this.state.errors.timerange}
          modifiers={['modal']}
          short={false}
          testId="shiftBlock-timeRange"
        />
        <MDTextInput
          value={this.state.duration || '-'}
          type="text"
          id="length"
          label={<FormattedMessage id="companymanage.shiftBlocks.length" defaultMessage="Długość" />}
          errorMessage={this.state.errors.locationName}
          modifiers={['modal']}
          disabled
        />
        <MDMultiSelectHeader
          icon="work_outline"
          title={<FormattedMessage id="common.jobTitles" defaultMessage="Stanowiska" />}
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
          testId="selectedAllJobTitles"
        />
        <MDMultiSelect
          label={
            <FormattedMessage id="companymanage.shiftBlocks.chooseJobTitles" defaultMessage="Wybierz stanowiska:" />
          }
          options={this.state.jobList}
          onChange={this.handleChangeList}
          testId="selectedJobTitles"
        />
        <MDMultiSelectFooter selectedItems={this.getSelectedJobs()} deleteItem={this.handleChangeList} />
      </MDKadroModal>
    );
  }
}

AddShiftBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddShiftBlock.propTypes = {
  edit: PropTypes.string,
  editData: PropTypes.shape({
    working_hours: PropTypes.string,
    job_titles: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }),
  submitFunc: PropTypes.func,
  closingFunc: PropTypes.func,
  visible: PropTypes.bool,
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  startValue: PropTypes.string,
};
export default AddShiftBlock;
