import PropTypes from 'prop-types';

import Button from '@/components/common/Basic/Button.jsx';

import './ExportsButtonBar.scss';

const ExportsButtonBar = ({ togglePayrollSettingsModal }) => (
  <div className="k-exportsButtonBar">
    <Button modifiers="onlyIcon reverse-gray" onClick={togglePayrollSettingsModal}>
      <i className="material-icons">settings</i>
    </Button>
  </div>
);

ExportsButtonBar.propTypes = {
  togglePayrollSettingsModal: PropTypes.func,
};

export default ExportsButtonBar;
