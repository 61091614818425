import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  jobTitle: {
    id: 'loanEmployeesProposalEditStep.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  employeesRequired: {
    id: 'loanEmployeesProposalEditStep.employeesRequired',
    defaultMessage: 'Liczba pracowników',
  },
  workingHours: {
    id: 'loanEmployeesProposalEditStep.workingHours',
    defaultMessage: 'Czas',
  },
  note: {
    id: 'loanEmployeesProposalEditStep.note',
    defaultMessage: 'Komentarz',
  },
  notePlaceholder: {
    id: 'loanEmployeesProposalEditStep.notePlaceholder',
    defaultMessage: 'Treść komentarza',
  },
});
