import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sectionTitle: {
    id: 'settings.locations.bonusSystem.title',
    defaultMessage: 'Ustawienia budżetu',
  },
  budgetEstimatesPeriod: {
    id: 'settings.locations.budgetEstimatesPeriod',
    defaultMessage: 'Okres prognozy budżetu',
  },
  budgetEstimatesPeriodDesc: {
    id: 'settings.locations.budgetEstimatesPeriod.desc',
    defaultMessage: 'Wybierz przedział czasowy dla którego importowane oraz wyświetlone będą dane w budżecie',
  },
  systemBonus: {
    id: 'settings.locations.bonusSystem.systemBonus',
    defaultMessage: 'System bonusowy',
  },
  systemBonusDesc: {
    id: 'settings.locations.bonusSystem.systemBonus.desc',
    defaultMessage:
      'System bonusowy pozwala na wyliczenie dodatkowego wynagrodzenia dla pracowników w oparciu o ich efektywność czasu pracy oraz wskaźnik SPMH.',
  },
  workHours: {
    id: 'settings.locations.bonusSystem.workHours',
    defaultMessage: 'Godziny pracy',
  },
  workHoursDesc: {
    id: 'settings.locations.bonusSystem.workHours.desc',
    defaultMessage: 'Utwórz godziny pracy, dla których wyliczony zostanie wskaźnik SPMH.',
  },
  shift: {
    id: 'settings.locations.bonusSystem.shift',
    defaultMessage: 'Zmiana',
  },
  addShiftHoursText: {
    id: 'settings.locations.bonusSystem.addShiftHoursText',
    defaultMessage: 'dodaj godziny zmiany',
  },
  spmhTarget: {
    id: 'settings.locations.bonusSystem.spmhTarget',
    defaultMessage: 'Target SPMH oraz wynagrodzenie',
  },
  spmhTargetDesc: {
    id: 'settings.locations.bonusSystem.spmhTarget.desc',
    defaultMessage:
      'Wpisz target wartości SPMH. Jeżeli na danej zmianie wypracowana wartość SPMH będzie większa niż target wówczas system wyliczy wynagrodzenie bonusowe w oparciu o zrealizowaną sprzedaż oraz wpisany % wynagrodzenia.',
  },
  target: {
    id: 'settings.locations.bonusSystem.target',
    defaultMessage: 'Target',
  },
  addTargetText: {
    id: 'settings.locations.bonusSystem.addTargetText',
    defaultMessage: 'dodaj target',
  },
  budgetTargets: {
    id: 'settings.locations.budget.enableTarget',
    defaultMessage: 'Włącz opcje ustalenia targetu dla budżetu godzinowego i/lub kosztowego w grafiku pracy.',
  },
  budgetTargetsDescription: {
    id: 'settings.locations.budget.enableTargetDescription',
    defaultMessage:
      'Włączenie tej opcji pozwoli Tobie na wpisanie targetu miesięcznego dla ilości godzin lub kosztów przy planowaniu grafiku pracy dla tej lokalizacji',
  },
  hoursBudgetBlocksPublishing: {
    id: 'settings.locations.budget.hoursBudgetBlocksPublishing',
    defaultMessage: 'Blokuj publikowanie grafiku dla którego czas pracy przekracza zadany budżet',
  },
  hoursBudgetBlocksPublishingLabel: {
    id: 'settings.locations.budget.hoursBudgetBlocksPublishingLabel',
    defaultMessage:
      'Blokuje mozliwość opublikowania grafiku pracy, gdy ilość zaplanowanych godzin w danym okresie przekracza zadany budżet godzinowy',
  },
  costBudgetBlocksPublishing: {
    id: 'settings.locations.budget.costBudgetBlocksPublishing',
    defaultMessage: 'Blokuj publikowanie grafiku dla którego koszt przekracza zadany budżet',
  },
  costBudgetBlocksPublishingLabel: {
    id: 'settings.locations.budget.costBudgetBlocksPublishingLabel',
    defaultMessage:
      'Blokuje mozliwość opublikowania grafiku pracy, gdy zaplanowany koszt w danym okresie przekracza zadany budżet kosztowy',
  },
});

export const budgetEstimatesPeriodMessages = defineMessages({
  '15minutes': { id: 'settings.locations.15minutes', defaultMessage: '15 minut' },
  hour: { id: 'settings.locations.hour', defaultMessage: 'Godzina' },
  day: { id: 'settings.locations.day', defaultMessage: 'Dzień' },
});
