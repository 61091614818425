import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton.jsx';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes.js';

import { messages } from './messages';

const BudgetMetricsTableRowOptions = ({ showModal, intl, rowData, deleteBudgetMetric, canEdit }) => (
  <>
    <MDIconButton
      icon="create"
      label={intl.formatMessage(messages.budgetMetricsTableRowOptionsEditIcon)}
      onClick={() =>
        showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL, rowData.id)
      }
      disabled={!canEdit}
    />
    <MDIconButton
      icon="delete"
      label={intl.formatMessage(messages.budgetMetricsTableRowOptionsDeleteIcon)}
      onClick={() => deleteBudgetMetric(rowData.id)}
      disabled={!canEdit}
    />
  </>
);

BudgetMetricsTableRowOptions.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
  }),
  deleteBudgetMetric: PropTypes.func,
  showModal: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  canEdit: PropTypes.bool,
};

export default injectIntl(BudgetMetricsTableRowOptions);
