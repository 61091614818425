import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  selectedConversationId: null,
  signature: '',
  users: [],
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case AT.SET_SELECTED_CONVERSATION_ID:
      return { ...state, selectedConversationId: action.payload };
    case AT.SET_CHAT_SIGNATURE:
      return { ...state, signature: action.payload };
    case AT.GET_CHAT_USERS:
      return { ...state, users: action.payload };
    default:
      return state;
  }
};
export default chat;
