import { EmployeeFromSchedule } from '@/types';
import { ParsedLoanEmployee } from '@/types/scheduleLoanedEmployees';
import { ShiftsData } from '@/types/shifts.types';

export const updateShiftsByDate = (loanedEmployee: EmployeeFromSchedule) =>
  loanedEmployee.shifts.reduce((acc, shift) => {
    const { date, id } = shift;
    const updatedShifts = [...(acc[date] || []), id];
    return { ...acc, [date]: updatedShifts };
  }, {});

export const getShiftDataForLoanedEmployees = (
  scheduleLoanedEmployees: ParsedLoanEmployee,
  shifts: { data: ShiftsData },
) => {
  const shiftIds = Object.values(scheduleLoanedEmployees).reduce((acc, loanedEmployee) => {
    if (loanedEmployee && loanedEmployee.shifts) {
      const employeeShiftIds = Object.values(loanedEmployee.shifts).flat();
      acc.push(...employeeShiftIds);
    }
    return acc;
  }, []);
  return Object.values(shifts.data).flatMap(employeeData =>
    Object.values(employeeData.shifts).filter(shift => shiftIds.includes(shift.id)),
  );
};
