import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { isWeekend,parseMinutesToHumanForm } from '@/utils/dateHelper';

const ScheduleBudgetTableHeaderCell = ({
  date,
  sumEmployees = 0,
  sumHours = 0,
  sumCosts = 0,
  selectedDisplayMode,
  showHolidaysSetting,
  today,
  dayType,
  currentTemplateType,
  dateArray,
}) => {
  const showHolidays = selectedDisplayMode !== 'templates' && showHolidaysSetting;
  const className = classnames('k-budgetTable__cell', {
    today: date === today,
    weekend: isWeekend(date),
    freeFromWorkHoliday: showHolidays && dayType === 'freeFromWorkHoliday',
    workDayHoliday: showHolidays && dayType === 'holiday',
  });
  const isEmployeeCountHidden =
    (selectedDisplayMode.type === 'templates' && currentTemplateType === TEMPLATE_TYPES.FLEX) || dateArray.length > 14;

  return (
    <td key={date} className={className}>
      <FeatureWrapper hide={isEmployeeCountHidden}>
        <div className="k-budgetTable__cell--employee">
          <Icon type="kadro" name="profile" />
          {sumEmployees}
        </div>
      </FeatureWrapper>
      <div className="k-budgetTable__cell--data">{parseMinutesToHumanForm(sumHours)}</div>
      <div className="k-budgetTable__cell--data">{roundToTwoSigDigits(sumCosts / 100)} PLN</div>
    </td>
  );
};

ScheduleBudgetTableHeaderCell.propTypes = {
  date: PropTypes.string,
  currentTemplateType: PropTypes.string,
  sumEmployees: PropTypes.number,
  sumHours: PropTypes.number,
  sumCosts: PropTypes.number,
  showHolidaysSetting: PropTypes.bool,
  selectedDisplayMode: PropTypes.string,
  today: PropTypes.string,
  dayType: PropTypes.string,
  dateArray: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableHeaderCell;
