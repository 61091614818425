/* eslint-disable max-len */
import PropTypes from 'prop-types';

import EmployeeModalContractJobTitles from '@/components/companymanage/employees/EmployeeModal/EmployeeModalContracts/EmployeeModalContractJobTitles/EmployeeModalContractJobTitles';

const EmployeesEditMassJobTitlesField = ({ userJobTitles, selectedTerms, onChange, showWages = false }) => (
  <div className="employeeModal__propList">
    <EmployeeModalContractJobTitles
      allJobTitles={userJobTitles}
      contractJobTitles={selectedTerms}
      updateContractJobTitles={onChange}
      hasAccessToWage={showWages}
      disableWagesChange
      disableJobTitleDelete
    />
  </div>
);
EmployeesEditMassJobTitlesField.propTypes = {
  userJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  selectedTerms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  showWages: PropTypes.bool,
};

export default EmployeesEditMassJobTitlesField;
