import { LABELS_MANAGE } from '@/constants/Permissions';
import { ATTENDANCE_LABELS_MANAGE_SHOW, MANAGER, OWNER } from '@/constants/Roles';

export const checkIfCanAddEditDeleteLabels = (
  companyRoles: { id: string; permissions: string[] }[],
  roleId: string,
  role: string,
  permissions: string[],
) => {
  const isLabelsPermissionsForUser = permissions.includes(LABELS_MANAGE);
  const isOwner = roleId === OWNER;

  if (isLabelsPermissionsForUser && isOwner) return true;

  const roleToFind = roleId || role;

  const isLabelsPermissionsForRole = companyRoles
    .find(({ id }) => id === roleToFind)
    ?.permissions.includes(ATTENDANCE_LABELS_MANAGE_SHOW);

  return isLabelsPermissionsForRole && isLabelsPermissionsForUser;
};

export const showLabelsFeature = (role: string, permissions: string[]) =>
  [OWNER, MANAGER].includes(role) && permissions.includes(LABELS_MANAGE);
