import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { getExports } from '@/components/common/exports/exportsConfig.ts';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { EXPORTS_TYPES } from '@/constants/exportFilesFormats';
import { CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL, NEW_PAYROLL_EXPORT_MODAL } from '@/constants/modalTypes';
import { CALCULATE_SPMH_BASED_BONUS } from '@/constants/Permissions';
import { FREEMIUM_HIDE_ATTENDANCE_VIEW } from '@/constants/Restrictions';

const messages = defineMessages({
  options: {
    id: 'payroll.button.options',
    defaultMessage: 'Opcje',
  },
});

const PayrollActionButtons = (
  {
    showModal,
    calculateSpmhBasedBonusesForNewPayroll,
    togglePayrollSettingsModal,
    disabledExport,
    payoutSettingType,
    permissions,
  },
  { intl },
) => {
  const actionsButtonRef = useRef(null);

  const handleClick = callback => () => {
    callback();
    if (actionsButtonRef?.current?.hide) actionsButtonRef.current.hide();
  };

  return (
    <ActionsButton
      icon={<Icon name="more_vert" />}
      buttonTitle={intl.formatMessage(messages.options)}
      ref={actionsButtonRef}
    >
      <button
        className="k-actionsButton__element k-actionsButton__element--with-icon"
        onClick={togglePayrollSettingsModal}
      >
        <i className="material-icons-outlined">tune</i>
        <FormattedMessage id="payrollActionButtons.payrollSettings" defaultMessage="Ustawienia czasu pracy" />
      </button>
      <FeatureWrapper restriction={RESTRICTIONS.EXPORT_VIEW_HIDE}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          disabled={disabledExport}
          onClick={() =>
            showModal(NEW_PAYROLL_EXPORT_MODAL, {
              formats: getExports(showModal, payoutSettingType, permissions, intl, false, true).filter(
                ({ showInEcpView }) => showInEcpView,
              ),
              preselectedExport: EXPORTS_TYPES.payrollXls,
            })
          }
        >
          <i className="material-icons mirrorFlip">reply</i>
          <FormattedMessage id="payrollActionButtons.export" defaultMessage="Eksportuj" />
        </button>
      </FeatureWrapper>
      <FeatureWrapper permission={CALCULATE_SPMH_BASED_BONUS}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          onClick={handleClick(calculateSpmhBasedBonusesForNewPayroll)}
        >
          <i className="material-icons">request_quote</i>
          <FormattedMessage id="payrollActionButtons.calculateSpmhBasedBonuses" defaultMessage="Oblicz bonusy" />
        </button>
      </FeatureWrapper>
      <FeatureWrapper restriction={FREEMIUM_HIDE_ATTENDANCE_VIEW}>
        <button
          className="k-actionsButton__element k-actionsButton__element--with-icon"
          onClick={() => showModal(CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL)}
        >
          <i className="material-icons">dataset</i>
          <FormattedMessage id="payrollActionButtons.chooseSummaryValues" defaultMessage="Dostosuj podsumowanie" />
        </button>
      </FeatureWrapper>
    </ActionsButton>
  );
};

PayrollActionButtons.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollActionButtons.propTypes = {
  calculateSpmhBasedBonusesForNewPayroll: PropTypes.func,
  showModal: PropTypes.func,
  togglePayrollSettingsModal: PropTypes.func,
  disabledExport: PropTypes.bool,
  payoutSettingType: PropTypes.string,
};

export default PayrollActionButtons;
