import { memo } from 'react';

import './Avatar.scss';

type Props = {
  size: number;
};

export const AvatarSvg = memo(({ size }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 44 40">
    <g filter="url(#filter0_d_410_84)">
      <path
        className="k-avatarSvg__background"
        d="M40 20c0 6.338-2.948 11.987-7.547 15.65A19.912 19.912 0 0120 40a19.911 19.911 0 01-12.453-4.35C2.947 31.988 0 26.339 0 20 0 8.954 8.954 0 20 0s20 8.954 20 20z"
      />
      <path
        className="k-avatarSvg__person"
        d="M20 23.938a8.407 8.407 0 100-16.814 8.407 8.407 0 000 16.814zM32.453 35.651A19.912 19.912 0 0120 40.001a19.912 19.912 0 01-12.453-4.35c1.618-5.344 6.58-9.235 12.453-9.235 5.872 0 10.835 3.89 12.453 9.235z"
      />
    </g>
  </svg>
));

AvatarSvg.displayName = 'AvatarSvg';
