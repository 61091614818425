import {
  employmentConditionsMultiSelectMessages,
  jobTitlesMultiSelectMessages,
  locationsMultiSelectMessages,
} from './filters.messages';

const MAX_LABEL_LENGTH = 50;

export const getLabelForItems = items => {
  const labelsDetails = items.reduce(
    (prev, item) => {
      if (!item.active) return prev;
      const allLabels = [...prev.allLabels, item.label];
      if (prev.allLabels.join(', ').length > MAX_LABEL_LENGTH)
        return {
          allLabels,
          visibleLabels: prev.visibleLabels,
          notVisibleLabels: [...prev.notVisibleLabels, item.label],
        };
      return { allLabels, visibleLabels: [...prev.visibleLabels, item.label], notVisibleLabels: prev.notVisibleLabels };
    },
    { allLabels: [], notVisibleLabels: [], visibleLabels: [] },
  );
  const visibleLabels = labelsDetails.visibleLabels.join(', ');
  const dotsForVisible = visibleLabels.length > MAX_LABEL_LENGTH;
  const visible = dotsForVisible ? `${visibleLabels.slice(0, MAX_LABEL_LENGTH + 3)}...` : visibleLabels;
  const additional =
    labelsDetails.notVisibleLabels.length > 0
      ? `${!dotsForVisible ? '...' : ''} (+${labelsDetails.notVisibleLabels.length})`
      : '';
  return `${visible}${additional}`;
};

export const getJobTitlesMessages = (intl, jobTitlesItems) => ({
  headerTitle: intl.formatMessage(jobTitlesMultiSelectMessages.headerTitle),
  selectAll: intl.formatMessage(jobTitlesMultiSelectMessages.selectAll),
  deselectAll: intl.formatMessage(jobTitlesMultiSelectMessages.deselectAll),
  bodyLabel: getLabelForItems(jobTitlesItems) || intl.formatMessage(jobTitlesMultiSelectMessages.bodyLabel),
});

export const getLocationsMessages = (intl, locationsItems) => ({
  headerTitle: intl.formatMessage(locationsMultiSelectMessages.headerTitle),
  selectAll: intl.formatMessage(locationsMultiSelectMessages.selectAll),
  deselectAll: intl.formatMessage(locationsMultiSelectMessages.deselectAll),
  bodyLabel: getLabelForItems(locationsItems) || intl.formatMessage(locationsMultiSelectMessages.bodyLabel),
});

export const getEmploymentConditionsMessages = (intl, employmentConditionsItems) => ({
  headerTitle: intl.formatMessage(employmentConditionsMultiSelectMessages.headerTitle),
  selectAll: intl.formatMessage(employmentConditionsMultiSelectMessages.selectAll),
  deselectAll: intl.formatMessage(employmentConditionsMultiSelectMessages.deselectAll),
  bodyLabel:
    getLabelForItems(employmentConditionsItems) ||
    intl.formatMessage(employmentConditionsMultiSelectMessages.bodyLabel),
});
