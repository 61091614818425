import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { BUDGET_ESTIMATES_VIEW } from '@/constants/Permissions.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { isWeekend } from '@/utils/dateHelper';

import ScheduleBudgetTableEstimates from './ScheduleBudgetTableEstimates/ScheduleBudgetTableEstimates.redux.js';
import ScheduleBudgetTableHeader from './ScheduleBudgetTableHeader/ScheduleBudgetTableHeader.redux.js';
import ScheduleBudgetTableMetrics from './ScheduleBudgetTableMetrics/ScheduleBudgetTableMetrics.redux';
import ScheduleBudgetTablePercentEstimates from './ScheduleBudgetTablePercentEstimates/ScheduleBudgetTablePercentEstimates.redux.js';
import ScheduleBudgetTableRecommendedSchedule from './ScheduleBudgetTableRecommendedSchedule/ScheduleBudgetTableRecommendedSchedule.redux.js';
import ScheduleBudgetTableSpmh from './ScheduleBudgetTableSpmh/ScheduleBudgetTableSpmh.redux.js';

class ScheduleBudgetTableRows extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };

    bindPrototypeFunctions(this);
  }

  onEstimateChange(event, estimate, name, date) {
    const { scheduleLocationFilter } = this.props;
    if (scheduleLocationFilter.length !== 1) {
      return;
    }

    const eventKey = Object.keys(event)[0];
    const hasError = this.state.errors[name] && this.state.errors[name][date];
    if (event[eventKey] !== estimate[eventKey] && !hasError) {
      this.props.budgetEstimatesChange(estimate, {
        ...event,
        location_id: this.props.scheduleLocationFilter[0],
        date,
      });
    }
  }

  getBudgetEstimateClassname(estimate, name, dayType, date, showHolidays) {
    const isToday = estimate.date === this.props.today;

    const hasError = Boolean(this.state.errors[name] && this.state.errors[name][estimate.date]);

    return classnames('k-budgetTable__cell', {
      today: isToday,
      'k-budgetTable__cell--error': hasError,
      weekend: isWeekend(date),
      freeFromWorkHoliday: showHolidays && dayType === 'freeFromWorkHoliday',
      workDayHoliday: showHolidays && dayType === 'holiday',
    });
  }

  setCellError(name, date, error) {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: {
          ...prevState.errors[name],
          [date]: error,
        },
      },
    }));
  }

  render() {
    const { selectedDisplayModeType, showRecommendedSchedule, selectedOptions, scheduleLocationFilter } = this.props;
    const selectedLocationId = scheduleLocationFilter[0];

    return (
      <>
        <ScheduleBudgetTableHeader locationId={selectedLocationId} />
        {showRecommendedSchedule && selectedOptions.includes('schedule') && <ScheduleBudgetTableRecommendedSchedule />}
        {selectedOptions.includes('budgetMetrics') && (
          <ScheduleBudgetTableMetrics />
        )}
        <FeatureWrapper permission={BUDGET_ESTIMATES_VIEW} hide={selectedDisplayModeType === 'templates'}>
          {selectedOptions.includes('estimates') && (
            <ScheduleBudgetTableEstimates
              setCellError={this.setCellError}
              onEstimateChange={this.onEstimateChange}
              getClassName={this.getBudgetEstimateClassname}
            />
          )}
          {selectedOptions.includes('percentEstimates') && (
            <ScheduleBudgetTablePercentEstimates
              getBudgetEstimateClassname={this.getBudgetEstimateClassname}
              setCellError={this.setCellError}
              onEstimateChange={this.onEstimateChange}
            />
          )}
          {selectedOptions.includes('sale') && (
            <ScheduleBudgetTableSpmh setCellError={this.setCellError} onEstimateChange={this.onEstimateChange} />
          )}
        </FeatureWrapper>
      </>
    );
  }
}

ScheduleBudgetTableRows.propTypes = {
  selectedDisplayModeType: PropTypes.string,
  today: PropTypes.string,
  budgetEstimatesChange: PropTypes.func,
  showRecommendedSchedule: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableRows;
