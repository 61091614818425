import { produce } from 'immer';

import { GET_EMPLOYEE_PAYROLL_SUCCESS, START_FETCHING_EMPLOYEE_PAYROLL_DATA } from '@/constants/ActionTypes.js';
import { GetEmployeePayrollAction } from '@/types/actions/payrollEmployee.types';

const initialState = {
  data: [],
  summary: {
    sumHours: 0,
    sumPayout: 0,
    sumBonus: 0,
  },
  showSkeletons: false,
};

const payrollEmployee = (state = initialState, action: GetEmployeePayrollAction) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_EMPLOYEE_PAYROLL_SUCCESS: {
        draft.data = action.payload.data;
        draft.summary = action.payload.summary;
        draft.showSkeletons = false;
        break;
      }
      case START_FETCHING_EMPLOYEE_PAYROLL_DATA: {
        draft.showSkeletons = true;
        break;
      }
      default:
        return state;
    }
  });

export default payrollEmployee;
