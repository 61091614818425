import { connect } from 'react-redux';

import { addRole, editRole } from '@/actions/companymanage/roles';
import { hideModal } from '@/actions/uiState';
import { ROLE_MODAL } from '@/constants/modalTypes.js';

import RolesModal from './RolesModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === ROLE_MODAL,
  currentUser: state.reducer.currentUser.user,
  companyRoles: state.reducer.roles.companyRoles,
  currentCompanyId: state.reducer.currentCompany.id,
  modalObject: state.reducer.uiState.modalObject[ROLE_MODAL],
  userEmployees: state.reducer.userEmployees,
  preCheckedPermissions: state.reducer.roles.preCheckedPermissions,
  companySettings: state.reducer.currentCompany.settings,
});

const mapDispatchToProps = {
  hideModal,
  addRole,
  editRole,
};

const RolesModalContainer = connect(mapStateToProps, mapDispatchToProps)(RolesModal);

export default RolesModalContainer;
