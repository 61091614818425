import { connect } from 'react-redux';

import { addAbsencesLimitTemplates, getAbsencesLimitTemplates, resetAbsencesLimits } from '@/actions/absences.js';
import {
  addEmploymentCondition,
  editEmploymentConditionConfirmation,
  updateEmploymentCondition,
} from '@/actions/companymanage/employmentConditions';
import { hideModal } from '@/actions/uiState';
import { EMPLOYMENT_CONDITIONS_MODAL } from '@/constants/modalTypes.js';

import EmploymentConditionsModal from './EmploymentConditionsModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === EMPLOYMENT_CONDITIONS_MODAL,
  modalObject: state.reducer.uiState.modalObject[EMPLOYMENT_CONDITIONS_MODAL],
  userAvailabilities: state.reducer.userCustomTypes,
  userPermissions: state.reducer.userPermissions,
  employmentConditions: state.reducer.employmentConditions,
  absencesTypes: state.reducer.absences.absencesTypes,
  absencesLimitTemplates: state.reducer.absences.absencesLimitTemplates,
});

const mapDispatchToProps = {
  hideModal,
  addEmploymentCondition,
  updateEmploymentCondition,
  editEmploymentConditionConfirmation,
  getAbsencesLimitTemplates,
  addAbsencesLimitTemplates,
  resetAbsencesLimits,
};

const EmploymentConditionsModalContainer = connect(mapStateToProps, mapDispatchToProps)(EmploymentConditionsModal);

export default EmploymentConditionsModalContainer;
