import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ScheduleHeaderDate from '../ScheduleHeaderDate/ScheduleHeaderDate';
import EventsTableCell from './EventsTableCell/EventsTableCell.redux';

const EventsTable = ({ scheduleLocationFilter, eventsStructure, dateArray }) => {
  const tableClassName = classnames('k-newScheduleTable', {
    'k-newScheduleTable--month': dateArray.length > 10,
  });
  return (
    <div className={tableClassName}>
      <table>
        <thead>
          <tr className="scheduleTableHeader">
            <th>
              <FormattedMessage id="schedule.events.header" defaultMessage="Wydarzenia" />
            </th>
            {dateArray.map(date => (
              <th key={date}>
                <ScheduleHeaderDate date={date} isMonthView={dateArray.length > 10} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            {dateArray.map(date => {
              const eventsForDate = eventsStructure[date];
              return (
                <EventsTableCell
                  key={date}
                  eventIds={eventsForDate}
                  size={dateArray.length > 10 ? 'month' : 'week'}
                  locationIds={scheduleLocationFilter}
                  date={date}
                />
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

EventsTable.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  eventsStructure: PropTypes.shape({}),
  dateArray: PropTypes.arrayOf(PropTypes.string),
};

export default EventsTable;
