import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import './ScheduleQuickPlanningItem.scss';

const ScheduleQuickPlanningItem = props => {
  const {
    isQuickPlanningEnabled,
    withBorder,
    isSelectedItemToCopy,
    isHoveredItem,
    withBorderTop,
    withBorderBottom,
    withBorderLeft,
    withBorderRight,
    showIcon,
  } = props;
  if (!isQuickPlanningEnabled) return null;

  const className = classNames('scheduleQuickPlanningItem', {
    'scheduleQuickPlanningItem--withBorder': withBorder,
    'scheduleQuickPlanningItem--selected': isSelectedItemToCopy,
    'scheduleQuickPlanningItem--hovered': isHoveredItem,
    'scheduleQuickPlanningItem--withBorderTop': withBorderTop,
    'scheduleQuickPlanningItem--withBorderBottom': withBorderBottom,
    'scheduleQuickPlanningItem--withBorderLeft': withBorderLeft,
    'scheduleQuickPlanningItem--withBorderRight': withBorderRight,
  });
  const showPersonOffIcon = showIcon && withBorder;

  return (
    <div className={className}>
      {showPersonOffIcon && (
        <div className="scheduleQuickPlanningItem__iconWrapper">
          <span className="material-icons-outlined scheduleQuickPlanningItem__icon">person_off</span>
        </div>
      )}
    </div>
  );
};

ScheduleQuickPlanningItem.propTypes = {
  isQuickPlanningEnabled: PropTypes.bool,
  position: PropTypes.shape({
    start: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    end: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  }),
  withBorder: PropTypes.bool,
  isHoveredItem: PropTypes.bool,
  isSelectedItemToCopy: PropTypes.bool,
  withBorderTop: PropTypes.bool,
  withBorderBottom: PropTypes.bool,
  withBorderLeft: PropTypes.bool,
  withBorderRight: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default memo(ScheduleQuickPlanningItem);
