import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MDImportBox = props => (
  <div className="mdFileDrop">
    <div className="mdFileDrop__text mdFileDrop__text--fileDropped">
      <FormattedMessage id="settings.import.importSuccessful" defaultMessage="Import zakończony" />
    </div>
    <div>
      {props.messages.map((message, i) => {
        switch (message.type) {
          case 'info':
            return (
              <div key={i} className="importBox__info">
                {message.message}
              </div>
            );
          case 'error':
            return (
              <div key={i} className="importBox__error">
                {message.line && (
                  <FormattedMessage
                    id="settings.import.row"
                    defaultMessage="wiersz {nr} - "
                    values={{ nr: message.line }}
                  />
                )}
                {message.message}
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  </div>
);

MDImportBox.defaultProps = {
  messages: [],
};

MDImportBox.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
      line: PropTypes.string,
    }),
  ),
};
export default MDImportBox;
