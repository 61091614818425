import moment from 'moment';
import PropTypes from 'prop-types';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { AUTO_GENERATE_SCHEDULE_VIEW } from '@/constants/Permissions';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { sortByKey } from '@/utils/arrayHelpers';

const ScheduleActionsButton = ({
  scheduleLocationFilter,
  locationSettings,
  mainDateStore,
  userEmployees,
  openShifts,
  userTemplates,
  userPermissions,
  scheduleActions,
  selectedDisplayModeType,
  budgetMetrics,
}) => {
  const [selectedLocationId] = scheduleLocationFilter;
  const selectedLocationSettings = locationSettings[selectedLocationId];
  const shiftEditDisabledUntil = selectedLocationSettings
    ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
    : null;
  const isShiftEditDisabled = moment(shiftEditDisabledUntil).isSameOrAfter(
    mainDateStore.dateArray[mainDateStore.dateArray.length - 1],
  );

  const relevantShifts = userEmployees
    .map(e => e.shifts.filter(s => mainDateStore.dateArray.includes(s.date) && s.location.id === selectedLocationId))
    .reduce((prev, employeeShifts) => prev.concat(employeeShifts), []);

  let numberOfDraftShifts = relevantShifts.filter(s => s.draft).length;
  numberOfDraftShifts += openShifts.filter(
    shift => mainDateStore.dateArray.includes(shift.date) && shift.location.id === selectedLocationId && shift.draft,
  ).length;

  const numberOfPublishedShifts = relevantShifts.filter(s => !s.draft).length;

  const numberOfDraftAvailabilities = userEmployees
    .map(e => e.availability_blocks.filter(s => mainDateStore.dateArray.includes(s.date) && s.draft === true).length)
    .reduce((a, b) => a + b, 0);

  const numberOfDraftTemplateShifts =
    userTemplates.currentTemplate.shifts &&
    userTemplates.currentTemplate.shifts.filter(shift => shift.draft === true).length;

  const editingScheduleDisabledForUser = userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);

  const hasAtLeastOnePublishedOpenShift = openShifts.some(openShift => !openShift.draft);
  const userHasAccessToAutoscheduler = userPermissions.permissions.includes(AUTO_GENERATE_SCHEDULE_VIEW);

  const options = {
    disableDuplicateView: mainDateStore.dateMode === 'custom' || isShiftEditDisabled || editingScheduleDisabledForUser,
    disableDuplicateViewPositions: editingScheduleDisabledForUser,
    disableDuplicateViewAvailabilities: editingScheduleDisabledForUser,
    disableDeleteUnpublishedShifts: !numberOfDraftShifts || isShiftEditDisabled || editingScheduleDisabledForUser,
    disableClearView: isShiftEditDisabled || editingScheduleDisabledForUser,
    disableClearViewForUser: editingScheduleDisabledForUser,
    disableDeleteCurrentTemplate: userTemplates.templatesArray.length <= 1,
    disableDeleteDraftAvailabilities: !numberOfDraftAvailabilities,
    disableDeleteDraftTemplateShifts: !numberOfDraftTemplateShifts,
    disableImportBudget: scheduleLocationFilter.length !== 1 || editingScheduleDisabledForUser,
    disableLoadBudgetTarget: scheduleLocationFilter.length < 1 || editingScheduleDisabledForUser,
    disableImportBudgetPositions: scheduleLocationFilter.length !== 1 || editingScheduleDisabledForUser,
    disableImportBudgetAvailabilities: scheduleLocationFilter.length !== 1 || editingScheduleDisabledForUser,
    disableExport: scheduleLocationFilter.length !== 1,
    disableSaveScheduleAsTemplate: !numberOfPublishedShifts || mainDateStore.dateMode !== 'week',
    disableImportRecommendedSchedule: scheduleLocationFilter.length !== 1,
    disableImportSchedule: scheduleLocationFilter.length !== 1 || editingScheduleDisabledForUser,
    disableImportOpenShifts: scheduleLocationFilter.length !== 1 || editingScheduleDisabledForUser,
    disableAutoGenerateScheduleForOpenShifts: scheduleLocationFilter.length !== 1 || !hasAtLeastOnePublishedOpenShift,
    hideAutoGenerateScheduleForOpenShifts:
      !scheduleLocationFilter.some(id => locationSettings[id]?.enable_open_shift) || !userHasAccessToAutoscheduler,
    disableImportBudgetMetricsData: !budgetMetrics.filter(({ visible }) => visible).length,
  };

  const scheduleOptions = sortByKey(scheduleActions(selectedDisplayModeType, options), 'label');
  return (
    <ActionsButton icon={<Icon name="more_vert" />}>
      {scheduleOptions.map((option, index) => (
        <FeatureWrapper
          permission={option.permission}
          restriction={option.restriction}
          permissions={option.permissions}
          restrictions={option.restrictions}
          hide={option.hide}
          key={index}
        >
          <button className="k-actionsButton__element" onClick={option.action} disabled={!!option.disabled}>
            {option.label}
          </button>
        </FeatureWrapper>
      ))}
    </ActionsButton>
  );
};

ScheduleActionsButton.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({}),
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  openShifts: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDisplayModeType: PropTypes.string,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userTemplates: PropTypes.shape({
    templatesArray: PropTypes.arrayOf(PropTypes.shape({})),
    currentTemplate: PropTypes.shape({
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  scheduleActions: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  budgetMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool,
    }),
  ),
};

export default ScheduleActionsButton;
