import { memo } from 'react';

import { EmployeeGroupingEnum } from '@/types/employeeGrouping';

import { GroupNodeType } from '../../GroupedEmployees.types';
import { GroupEmploymentCondition } from './GroupEmploymentCondition/GroupEmploymentCondition';
import { GroupJobTitle } from './GroupJobTitle/GroupJobTitle';
import { GroupWorkingTime } from './GroupWorkingTime/GroupWorkingTime';

type Props = {
  groupKey: GroupNodeType['groupKey'];
  groupId: GroupNodeType['groupId'];
};

export const GroupBarContent = memo(({ groupId, groupKey }: Props) => {
  switch (groupId) {
    case EmployeeGroupingEnum.JOB_TITLE:
      return <GroupJobTitle groupKey={groupKey} />;
    case EmployeeGroupingEnum.EMPLOYMENT_CONDITION:
      return <GroupEmploymentCondition groupKey={groupKey} />;
    case EmployeeGroupingEnum.WORKING_TIME:
      return <GroupWorkingTime groupKey={groupKey} />;
    default:
      return null;
  }
});

GroupBarContent.displayName = 'GroupContent';
