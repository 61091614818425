import {
  ADD_NEW_JOBTITLE,
  CLEAR_DEMO_DATA,
  DELETE_JOBTITLE,
  DELETE_JOBTITLE_SET,
  GET_JOBTITLES_SUCCESFUL,
  UPDATE_JOBTITLE,
} from '@/constants/ActionTypes';

const initialState = [];

const userJobTitles = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBTITLES_SUCCESFUL:
      return action.payload;
    case ADD_NEW_JOBTITLE:
      return [...state, action.payload];
    case DELETE_JOBTITLE:
      return state.filter(title => title.id !== action.payload);
    case DELETE_JOBTITLE_SET:
      return state.filter(j => !action.payload.includes(j.id));
    case UPDATE_JOBTITLE: {
      const newState5 = state.slice(0);
      const jobTitle = action.payload;
      for (let i = 0; i < newState5.length; i++) {
        if (newState5[i].id === jobTitle.id) {
          newState5[i] = jobTitle;
        }
      }
      return newState5;
    }
    case CLEAR_DEMO_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
export default userJobTitles;
