import { isEqual } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { AVAILABILITY_LIMITS_VIEW } from '@/constants/Permissions';
import { COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT } from '@/constants/Restrictions';
import { createLimits, getInitialLimitsState } from '@/utils/absenceHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { workingHoursToMinutes, workingMinutesToHours } from '@/utils/employmentConditionsHelpers';
import { inputValidation } from '@/utils/inputValidation.js';

import AbsencesLimitsTab from '../../employees/EmployeeModal/AbsenceLimitsTab/AbsenceLimitsTab.redux.js';
import EmployeeModalConditions from '../../employees/EmployeeModal/EmployeeModalConditions/EmployeeModalConditions.jsx';

const messages = defineMessages({
  addEmploymentCondition: {
    id: 'employmentConditions.add',
    defaultMessage: 'Dodaj warunek',
  },
  editEmploymentCondition: {
    id: 'employmentConditions.edit',
    defaultMessage: 'Edytuj warunek',
  },
  saveChanges: {
    id: 'common.saveChanges',
    defaultMessage: 'Zapisz zmiany',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Dodaj',
  },
  tabNameConditions: {
    id: 'employmentConditions.tab.conditions',
    defaultMessage: 'Dodaj warunek',
  },
  tabNameAbsencesLimits: {
    id: 'employmentConditions.tab.absencesLimits',
    defaultMessage: 'Limity urlopów',
  },
});

class EmploymentConditionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props.modalObject, props.absencesTypes, props.absencesLimitTemplates);
    bindPrototypeFunctions(this);
  }

  getInitialState(modalObject, absencesTypes, absencesLimitTemplates) {
    const limits = getInitialLimitsState(absencesTypes, absencesLimitTemplates);
    if (modalObject.id) {
      /* eslint camelcase:0 */
      const {
        name,
        weekly_working_minutes,
        max_daily_working_minutes,
        validate_working_rules,
        schedule_cycle,
        availability_limits,
        elastic_work_day,
        show_absences,
        is_medical,
        isDefaultEmploymentCondition,
        allowed_to_work_at_night_hours,
      } = modalObject;

      return {
        objectName: name,
        weeklyWorkingMinutes: workingMinutesToHours(weekly_working_minutes),
        maxDailyWorkingMinutes: workingMinutesToHours(max_daily_working_minutes),
        validateWorkingRules: validate_working_rules,
        elasticWorkDay: elastic_work_day,
        showAbsences: show_absences,
        isMedical: is_medical,
        allowedToWorkAtNightHours: allowed_to_work_at_night_hours,
        scheduleCycleStart: {
          month: schedule_cycle?.month,
          year: schedule_cycle?.year,
        },
        scheduleCycleDuration: schedule_cycle?.duration,
        availabilityLimits: availability_limits,
        limits,
        changedLimits: [],
        errors: [],
        activeTab: isDefaultEmploymentCondition ? 1 : 0,
      };
    }

    return {
      objectName: '',
      weeklyWorkingMinutes: '40:00',
      maxDailyWorkingMinutes: '08:00',
      validateWorkingRules: true,
      elasticWorkDay: false,
      showAbsences: true,
      isMedical: false,
      allowedToWorkAtNightHours: false,
      scheduleCycleStart: {
        month: moment().month() + 1,
        year: moment().year(),
      },
      scheduleCycleDuration: 1,
      availabilityLimits: [],
      partTimeMultiplier: 1,
      activeTab: 0,
      limits,
      changedLimits: [],
      errors: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { modalObject } = nextProps;
    if (modalObject.id) {
      this.setState(this.getInitialState(modalObject, nextProps.absencesTypes, nextProps.absencesLimitTemplates));
      if (this.props.modalObject.id !== modalObject.id) {
        this.props.getAbsencesLimitTemplates(modalObject.id);
      }
    } else {
      this.setState(this.getInitialState({}, nextProps.absencesTypes, nextProps.absencesLimitTemplates));
    }
  }

  onSubmit() {
    const templates = createLimits(this.props.absencesTypes, this.state.limits, this.state.changedLimits);
    const employmentConditionsId = this.props.modalObject.id;

    if (this.props.modalObject.isDefaultEmploymentCondition && templates.length > 0) {
      this.props.addAbsencesLimitTemplates(templates, employmentConditionsId, true);
    }

    if (this.props.modalObject.isDefaultEmploymentCondition) {
      this.hideAndClear();
      return;
    }

    this.validateAll().then((valid) => {
      if (!valid) return;
      let baseObj = {
        name: this.state.objectName,
        weekly_working_minutes: workingHoursToMinutes(this.state.weeklyWorkingMinutes),
        max_daily_working_minutes: workingHoursToMinutes(this.state.maxDailyWorkingMinutes),
        schedule_cycle: {
          month: this.state.scheduleCycleStart.month,
          year: this.state.scheduleCycleStart.year,
          duration: parseInt(this.state.scheduleCycleDuration),
        },
        validate_working_rules: this.state.validateWorkingRules,
        elastic_work_day: this.state.elasticWorkDay,
        availability_limits: this.state.availabilityLimits,
        show_absences: this.state.showAbsences,
        is_medical: this.state.isMedical,
        allowed_to_work_at_night_hours: this.state.allowedToWorkAtNightHours,
      };

      if (this.props.modalObject.id) {
        baseObj = this.getChangesObject(baseObj, this.props.modalObject.id);
        this.props.editEmploymentConditionConfirmation((override) => {
          this.props.updateEmploymentCondition({
            ...baseObj,
            override,
          });
          if (templates.length !== 0) {
            this.props.addAbsencesLimitTemplates(templates, employmentConditionsId);
          }
          this.hideAndClear();
        });
      } else {
        this.props.addEmploymentCondition(baseObj, templates);
        this.hideAndClear();
      }
    });
  }

  getChangesObject(baseObj, id) {
    const employmentCondition = this.props.employmentConditions.find((condition) => condition.id === id);
    return Object.keys(baseObj).reduce(
      (prev, key) => {
        if (key === 'schedule_cycle') {
          let schedule_cycle = {};
          if (
            employmentCondition.schedule_cycle.month !== baseObj.schedule_cycle.month ||
            employmentCondition.schedule_cycle.year !== baseObj.schedule_cycle.year
          ) {
            schedule_cycle = {
              ...schedule_cycle,
              month: baseObj.schedule_cycle.month,
              year: baseObj.schedule_cycle.year,
            };
          }
          if (employmentCondition.schedule_cycle.duration !== baseObj.schedule_cycle.duration) {
            schedule_cycle = {
              ...schedule_cycle,
              duration: baseObj.schedule_cycle.duration,
            };
          }
          return {
            ...prev,
            ...(Object.keys(schedule_cycle).length ? { schedule_cycle } : {}),
          };
        }
        if (!isEqual(baseObj[key], employmentCondition[key])) {
          return {
            ...prev,
            [key]: baseObj[key],
          };
        }
        return prev;
      },
      { id },
    );
  }

  getTabs() {
    const { context, state } = this;
    const edit = !!this.props.modalObject.id;

    const tabs = [
      {
        id: 0,
        name: edit
          ? this.context.intl.formatMessage(messages.editEmploymentCondition, {})
          : this.context.intl.formatMessage(messages.addEmploymentCondition, {}),
        fields: ['objectName', 'scheduleCycleDuration'],
        disabled: this.props.modalObject.isDefaultEmploymentCondition,
      },
      {
        id: 1,
        name: context.intl.formatMessage(messages.tabNameAbsencesLimits, {}),
        fields: [],
        disabled:
          this.props.userPermissions.restrictions.includes(COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT) ||
          !this.state.showAbsences,
      },
    ];
    return tabs.map((tab) => {
      const error = tab.fields.some((field) => state.errors[field]);
      return { ...tab, error };
    });
  }

  setLimits(limits) {
    this.setState({
      limits,
    });
  }

  handleLimitChange(value, id) {
    this.setState((prevState) => ({
      limits: {
        ...prevState.limits,
        [id]: value,
      },
      changedLimits: !prevState.changedLimits.includes(id) ? [...prevState.changedLimits, id] : prevState.changedLimits,
    }));
  }

  handleIsMedicalToggle(value) {
    if (!value) {
      this.setState({
        isMedical: value,
      });
      return;
    }

    this.setState({
      isMedical: value,
      maxDailyWorkingMinutes: '07:35',
      weeklyWorkingMinutes: '37:55',
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (this.state.errors[name]) {
      this.validateInput(event);
    }

    if (name === 'isMedical') {
      this.handleIsMedicalToggle(value);
      return;
    }

    this.setState({
      [name]: value,
    });
  }

  validateInput(event) {
    return new Promise((resolve) => {
      const { target, valueToCompare } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      const error = inputValidation(name, value, { valueToCompare });
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  async validateAll() {
    const inputs = [
      'objectName',
      'color',
      'scheduleCycleStart',
      'scheduleCycle',
      'scheduleCycleDuration',
      'maxDailyWorkingMinutes',
      'weeklyWorkingMinutes',
    ];
    if (this.props.userPermissions.permissions.includes(AVAILABILITY_LIMITS_VIEW)) {
      inputs.push('availabilityLimits');
    }
    for (const i of inputs) {
      if (i === 'weeklyWorkingMinutes') {
        /* eslint-disable no-await-in-loop */
        await this.validateInput({
          target: { name: i, value: this.state[i] },
          valueToCompare: this.state.maxDailyWorkingMinutes,
        });
      } else {
        /* eslint-disable no-await-in-loop */
        await this.validateInput({ target: { name: i, value: this.state[i] } });
      }
    }
    let canContinue = true;
    Object.values(this.state.errors).map((err) => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  hideAndClear() {
    this.setState(this.getInitialState({}, [], []));
    this.props.resetAbsencesLimits();
    this.props.hideModal();
  }

  changeTab(id) {
    this.setState({
      activeTab: id,
    });
  }

  displayRelevantTab() {
    switch (this.state.activeTab) {
      case 0: {
        return (
          <EmployeeModalConditions
            templateId={this.props.modalObject.id}
            objectName={this.state.objectName}
            showObjectName
            weeklyWorkingMinutes={this.state.weeklyWorkingMinutes}
            maxDailyWorkingMinutes={this.state.maxDailyWorkingMinutes}
            scheduleCycleStart={this.state.scheduleCycleStart}
            scheduleCycleDuration={this.state.scheduleCycleDuration}
            validateWorkingRules={this.state.validateWorkingRules}
            elasticWorkDay={this.state.elasticWorkDay}
            showAbsences={this.state.showAbsences}
            isMedical={this.state.isMedical}
            allowedToWorkAtNightHours={this.state.allowedToWorkAtNightHours}
            handleInputChange={this.handleInputChange}
            validateInput={this.validateInput}
            errors={this.state.errors}
            isTemplateEditing
          />
        );
      }
      case 1: {
        return <AbsencesLimitsTab limits={this.state.limits} handleLimitChange={this.handleLimitChange} />;
      }
      default:
        return null;
    }
  }

  render() {
    const allTabs = this.getTabs();
    const title = allTabs[this.state.activeTab].name;
    const edit = !!this.props.modalObject.id;

    return (
      <MDKadroModal
        show={this.props.showModal}
        onHide={this.hideAndClear}
        modifiers={['tall']}
        title={title}
        onSubmit={this.onSubmit}
        showSidebar
        options={allTabs}
        activeTab={this.state.activeTab}
        onTabClick={this.changeTab}
        confirmText={
          edit
            ? this.context.intl.formatMessage(messages.saveChanges, {})
            : this.context.intl.formatMessage(messages.add, {})
        }
      >
        {this.displayRelevantTab()}
      </MDKadroModal>
    );
  }
}

EmploymentConditionsModal.defaultProps = {
  modalObject: { id: null },
};

EmploymentConditionsModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmploymentConditionsModal.propTypes = {
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    isDefaultEmploymentCondition: PropTypes.bool,
  }),
  showModal: PropTypes.bool,
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  absencesLimitTemplates: PropTypes.arrayOf(PropTypes.shape({})),
  addEmploymentCondition: PropTypes.func,
  updateEmploymentCondition: PropTypes.func,
  hideModal: PropTypes.func,
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  editEmploymentConditionConfirmation: PropTypes.func,
  getAbsencesLimitTemplates: PropTypes.func,
  addAbsencesLimitTemplates: PropTypes.func,
  resetAbsencesLimits: PropTypes.func,
};

export default EmploymentConditionsModal;
