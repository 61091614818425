import moment from 'moment';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { createMassAttendances } from '@/actions/attendances.js';
import { hideModal } from '@/actions/uiState.js';
import { MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { MODAL_MODIFIERS, NARROW } from '@/constants/modalModifiers.js';
import { useAppDispatch } from '@/redux-store';
import { checkIfEndHourIsAfterAttendancesStart } from '@/utils/attendanceHelpers.jsx';
import { changeTimeHour } from '@/utils/dateHelper.js';
import { inputValidation } from '@/utils/inputValidation.js';

import { messages } from './AlignAttendancesModalContent.messages';

type SelectedShiftWithoutAttendance = {
  employee: { id: string };
  detail: { startTimestamp: string; endTimestamp: string };
  locationId: string;
};

type Props = {
  addMatchingShiftsWhenAdding?: boolean;
  showModal: boolean;
  modalObject: {
    relevantAttendances: {
      startAttendances: { date: string }[];
      endAttendances: { date: string }[];
    };
    selectedShiftsWithoutAttendancesForSelectedEmployees: SelectedShiftWithoutAttendance[];
  };
  alignAttendances: (
    startHour: string,
    endHour: string,
    dates: string[],
    relevantAttendances: {
      startAttendances: { date: string }[];
      endAttendances: { date: string }[];
    },
  ) => void;
};

export const AlignAttendancesModalContent = ({
  alignAttendances,
  showModal,
  modalObject,
  addMatchingShiftsWhenAdding = false,
}: Props) => {
  const dispatch = useAppDispatch();

  const [inputState, setInputState] = useState({
    startHour: '',
    endHour: '',
  });
  const [errors, setErrors] = useState({
    startHour: '',
    endHour: '',
  });

  const intl = useIntl();

  const handleInputChange = useCallback(
    (key: string, value: string) => {
      setInputState({
        ...inputState,
        [key]: value,
      });
    },
    [inputState],
  );

  const validateInput = useCallback(
    (key: string, value: string) => {
      const error = inputValidation(key, value);
      setErrors({
        ...errors,
        [key]: error ? intl.formatMessage(error) : error,
      });
    },
    [errors, intl],
  );

  const handleHide = useCallback(() => {
    setInputState({
      startHour: '',
      endHour: '',
    });
    setErrors({
      startHour: '',
      endHour: '',
    });

    dispatch(hideModal());
  }, [dispatch]);

  const addAttendances = useCallback(
    (shiftsWithoutAttendancesData: SelectedShiftWithoutAttendance[], startHour: string, endHour: string) => {
      const attendances = shiftsWithoutAttendancesData.reduce((acc, shift) => {
        const existingItem = acc.find(
          item =>
            item.employee_id === shift.employee.id &&
            moment(item.start_timestamp).format('YYYY-MM-DD') ===
              moment(shift.detail.startTimestamp).format('YYYY-MM-DD'),
        );

        if (!existingItem) {
          acc.push({
            employee_id: shift.employee.id,
            start_timestamp:
              startHour && startHour !== '__:__' ? changeTimeHour(shift.detail.startTimestamp, startHour) : undefined,
            end_timestamp:
              endHour && endHour !== '__:__' ? changeTimeHour(shift.detail.endTimestamp, endHour) : undefined,
            location_id: shift.locationId,
            draft: false,
          });
        }
        return acc;
      }, []);

      dispatch(createMassAttendances(attendances, { addMatchingShifts: addMatchingShiftsWhenAdding }));
    },
    [dispatch, addMatchingShiftsWhenAdding],
  );

  const validateAll = useCallback(async () => {
    const inputsToValidate = ['startHour', 'endHour'];
    inputsToValidate.forEach(i => validateInput(i, inputState[i]));

    return Object.values(errors).every(err => !err);
  }, [validateInput, inputState, errors]);

  const onSubmit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    if (!inputState.startHour.length) {
      setErrors({
        ...errors,
        startHour: intl.formatMessage(messages.startHourMissingError),
      });
      return;
    }
    const { relevantAttendances, selectedShiftsWithoutAttendancesForSelectedEmployees } = modalObject;

    if (!checkIfEndHourIsAfterAttendancesStart(inputState.endHour, relevantAttendances.endAttendances)) {
      setErrors({
        ...errors,
        endHour: intl.formatMessage(messages.invalidEndHourError),
      });
      return;
    }
    const dates = Array.from(
      new Set([...relevantAttendances.startAttendances, ...relevantAttendances.endAttendances].map(a => a.date)),
    );

    if (selectedShiftsWithoutAttendancesForSelectedEmployees?.length) {
      addAttendances(selectedShiftsWithoutAttendancesForSelectedEmployees, inputState.startHour, inputState.endHour);
    }

    alignAttendances(inputState.startHour, inputState.endHour, dates, relevantAttendances);

    handleHide();
  }, [
    validateAll,
    inputState.startHour,
    inputState.endHour,
    modalObject,
    alignAttendances,
    handleHide,
    errors,
    intl,
    addAttendances,
  ]);

  return (
    <MDKadroModal
      title={intl.formatMessage(messages.title)}
      show={showModal}
      onHide={handleHide}
      onSubmit={onSubmit}
      modifiers={NARROW}
    >
      <MDTimeInput
        label={<FormattedMessage id="attendance.attendance.startHour" defaultMessage="Godzina rozpoczęcia" />}
        value={inputState.startHour}
        onChange={value => handleInputChange('startHour', value)}
        onBlur={e => validateInput('startHour', e.target.value)}
        errorMessage={errors.startHour}
        modifiers={MODAL_MODIFIERS}
      />
      <MDTimeInput
        label={<FormattedMessage id="attendance.attendance.endHour" defaultMessage="Godzina zakończenia" />}
        value={inputState.endHour}
        onChange={value => handleInputChange('endHour', value)}
        onBlur={e => validateInput('endHour', e.target.value)}
        errorMessage={errors.endHour}
        modifiers={MODAL_MODIFIERS}
      />
    </MDKadroModal>
  );
};
