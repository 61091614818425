import PropTypes from 'prop-types';

import KadroComposedChart from '@/components/common/charts/KadroComposedChart.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';

import { reportsMessages } from '../messages.js';
import { axisMetadata,metadata } from './constants.js';
import { mapDataForChart } from './utils.js';

const DailyWorkTimeChart = ({ rawData }, { intl }) => {
  const data = mapDataForChart(rawData);
  const isEmpty = data.every(item => !item.workedHours && !item.plannedHours);
  const formattedMetadata = metadata.map(item => ({
    ...item,
    label: intl.formatMessage(item.label),
  }));

  if (isEmpty) {
    return (
      <EmptyState
        name="jobtitleCTA"
        className="k-reports-empty-state"
        imgSrc="/img/jobtitlesCTA.png"
        emptyStateText={intl.formatMessage(reportsMessages.noData)}
        shiftX="0"
      />
    );
  }

  return <KadroComposedChart data={data} metadata={formattedMetadata} axisMetadata={axisMetadata} />;
};

DailyWorkTimeChart.contextTypes = {
  intl: PropTypes.shape({}),
};

DailyWorkTimeChart.propTypes = {
  rawData: PropTypes.shape({}),
};

export default DailyWorkTimeChart;
