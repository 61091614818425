import * as AT from '@/constants/ActionTypes';

export const toggleScheduleAvailabilityMode = () => ({
  type: AT.TOGGLE_SCHEDULE_AVAILABILITIES_MODE,
});

export const selectAvailabilitiesMode = () => ({
  type: AT.SELECT_AVAILABILITIES_MODE,
});

export const selectShiftsMode = () => ({
  type: AT.SELECT_SHIFTS_MODE,
});
