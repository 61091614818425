import { connect } from 'react-redux';

import { changeSingleEmployeeFilter } from '@/actions/singleEmployeeFilter.js';

import TopBarEmployeeFilter from './TopBarEmployeeFilter.jsx';

const mapStateToProps = state => ({
  companyRoles: state.reducer.roles.companyRoles,
  companySettings: state.reducer.currentCompany.settings,
  currentUser: state.reducer.currentUser,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  singleEmployeeFilter: state.reducer.singleEmployeeFilter,
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
  payrollLoanedEmployees: state.reducer.payrollLoanedEmployees.data,
});

const mapDispatchToProps = {
  changeSingleEmployeeFilter,
};

const TopBarEmployeeFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarEmployeeFilter);

export default TopBarEmployeeFilterContainer;
