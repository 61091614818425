import { defineMessages } from 'react-intl';

import * as actionTypes from '@/constants/ActionTypes.js';
import { OPEN_SHIFTS_TEMPLATE_ID,TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { formatOpenShiftForTemplates, formatShiftForTemplates } from '@/utils/scheduleTemplatesHelpers.js';

import { conn, connectionError } from './index';

const messages = defineMessages({
  addedShiftTitle: {
    id: 'scheduleTemplates.addedShift.title',
    defaultMessage: 'Dodano zmianę!',
  },
  addedShiftDesc: {
    id: 'scheduleTemplates.addedShift.desc',
    defaultMessage: 'Dodano zmianę dla {jobTitle}',
  },
  editedShiftTitle: {
    id: 'scheduleTemplates.editedShift.title',
    defaultMessage: 'Edytowano zmianę!',
  },
  editedShiftDesc: {
    id: 'scheduleTemplates.editedShift.desc',
    defaultMessage: 'Edytowano zmianę dla {jobTitle}',
  },
  deletedAllShiftsTitle: {
    id: 'scheduleTemplates.deletedAllShifts.title',
    defaultMessage: 'Wyczyszczono szablon!',
  },
  deletedAllShiftsDesc: {
    id: 'scheduleTemplates.deletedAllShifts.desc',
    defaultMessage: 'Usunięto wszystkie dane w szablonie {templateName}',
  },
  deletedShiftTitle: {
    id: 'scheduleTemplates.deletedShift.title',
    defaultMessage: 'Usunięto zmianę!',
  },
  deletedUnpublishedShiftsTitle: {
    id: 'scheduleTemplates.deletedUnpublishedShifts.title',
    defaultMessage: 'Sukces!',
  },
  deletedUnpublishedShiftsDesc: {
    id: 'scheduleTemplates.deletedUnpublishedShifts.desc',
    defaultMessage: 'Usunięto nieopublikowane zmiany',
  },
  openShiftTemplate: {
    id: 'scheduleTemplates.openShiftTemplate',
    defaultMessage: 'Otwarte zmiany',
  },
});

export const getTemplatesSuccesful = data => ({
  type: actionTypes.GET_TEMPLATES_SUCCESFUL,
  payload: data,
});

export const deleteTemplateSuccesful = templateId => ({
  type: actionTypes.DELETE_TEMPLATE_SUCCESFUL,
  payload: templateId,
});

export const addTemplatesShiftSuccesful = (template, jobTitle, notify = true) => (dispatch, getState, intl) => {
  let notification;
  if (notify === true) {
    notification = {
      title: intl.formatMessage(messages.addedShiftTitle),
      description: intl.formatMessage(messages.addedShiftDesc, { jobTitle }),
      type: 'success',
    };
  }
  dispatch({
    type: actionTypes.ADD_TEMPLATES_SHIFT_SUCCESFUL,
    payload: template,
    notification,
  });
};

export const editTemplatesShiftSuccesful = (template, jobTitle, notify = true) => (dispatch, getState, intl) => {
  let notification;
  if (notify === true) {
    notification = {
      title: intl.formatMessage(messages.editedShiftTitle),
      description: intl.formatMessage(messages.editedShiftDesc, { jobTitle }),
      type: 'success',
    };
  }
  dispatch({
    type: actionTypes.EDIT_TEMPLATES_SHIFT_SUCCESFUL,
    payload: template,
    notification,
  });
};
export const deleteAllTemplatesShiftsSuccesful = (template, templateName, notify = true) => (
  dispatch,
  getState,
  intl,
) => {
  let notification;
  if (notify === true) {
    notification = {
      title: intl.formatMessage(messages.deletedAllShiftsTitle),
      description: intl.formatMessage(messages.deletedAllShiftsDesc, { templateName }),
      type: 'success',
    };
  }
  dispatch({
    type: actionTypes.EDIT_TEMPLATES_SHIFT_SUCCESFUL,
    payload: template,
    notification,
  });
};

export const deleteTemplatesShiftSuccesful = (template, notify = true) => (dispatch, getState, intl) => {
  let notification;
  if (notify === true) {
    notification = {
      type: 'success',
      title: intl.formatMessage(messages.deletedShiftTitle),
      description: '',
    };
  }
  dispatch({
    type: actionTypes.DELETE_TEMPLATES_SHIFT_SUCCESFUL,
    payload: template,
    notification,
  });
};

export const changeTemplateNameSuccesful = template => ({
  type: actionTypes.CHANGE_TEMPLATE_NAME_SUCCESFUL,
  payload: template,
});

export const deleteUnpublishedTemplateShiftsSuccesful = template => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_UNPUBLISHED_TEMPLATES_SHIFTS_SUCCESFUL,
    payload: template,
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.deletedUnpublishedShiftsTitle),
      description: intl.formatMessage(messages.deletedUnpublishedShiftsDesc),
    },
  });
};

export const publishDraftTemplateShiftsSuccesful = template => ({
  type: actionTypes.PUBLISH_DRAFT_TEMPLATE_SHIFTS_SUCCESFUL,
  payload: template,
});

export const changeCurrentTemplateSuccesful = template => ({
  type: actionTypes.CHANGE_CURRENT_TEMPLATE_SUCCESFUL,
  payload: template,
});

export const addNewTemplateSuccesful = template => ({
  type: actionTypes.ADD_NEW_TEMPLATE_SUCCESFUL,
  payload: template,
});

export const saveTemplates = data => () => {
  localStorage.setItem('kadro-shifts-templates', JSON.stringify({ ...data }));
};

export const addNewTemplate = (
  name = 'Nowy niezapisany szablon',
  shifts = [],
  type = TEMPLATE_TYPES.BASIC,
) => dispatch => {
  const newTemplate = {
    name,
    shifts: shifts.map(formatShiftForTemplates),
    type,
  };
  conn.addScheduleTemplate(newTemplate).then(response => {
    dispatch(
      addNewTemplateSuccesful({
        ...newTemplate,
        id: response.data.template_id,
      }),
    );
  });
};

export const deleteOpenShiftTemplate = () => ({
  type: actionTypes.DELETE_OPEN_SHIFT_TEMPLATE,
});

export const createTemplateFromOpenShifts = () => (dispatch, getState, intl) => {
  const { openShifts, scheduleLocationFilter, jobtitleFilter } = getState().reducer;
  const selectedJobtitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
  const relevantShifts = openShifts.reduce(
    (shifts, openShift) =>
      scheduleLocationFilter.includes(openShift.location.id) && selectedJobtitlesIds.includes(openShift.job_title.id)
        ? [...shifts, formatOpenShiftForTemplates(openShift)]
        : shifts,
    [],
  );
  const openShiftTemplate = {
    name: intl.formatMessage(messages.openShiftTemplate),
    id: OPEN_SHIFTS_TEMPLATE_ID,
    shifts: relevantShifts,
    type: TEMPLATE_TYPES.BASIC,
  };
  dispatch(addNewTemplateSuccesful(openShiftTemplate));
};

export const getTemplates = requestType => dispatch => {
  conn
    .getScheduleTemplates(requestType)
    .then(response => {
      if (!response.data.length) {
        dispatch(addNewTemplate());
      }
      dispatch(
        getTemplatesSuccesful(
          response.data.map(rawTemplate => {
            const { template_id: _, ...template } = rawTemplate;
            return { ...template, id: rawTemplate.template_id };
          }),
        ),
      );
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const replaceTemplatesShifts = (shifts, currentTemplate, edit = false, notify = false) => dispatch => {
  const shiftKeys = Array.from(new Set(shifts.map(s => s.key))).filter(Boolean);
  const filteredCurrentShifts = currentTemplate.shifts.filter(s => {
    const jobTitleId = s.job_title_id || s.job_title?.id;
    const key = `${s.date}_${s.working_hours}_${jobTitleId}`;

    return !shiftKeys.includes(key);
  });
  const shiftsToAdd = [...shifts, ...filteredCurrentShifts].map(formatShiftForTemplates).reduce((acc, val) => {
    const jobTitleId = val.job_title_id;
    const key = `${val.date}-${val.working_hours}-${jobTitleId}`;
    return acc[key] ? { ...acc, [key]: { ...acc[key], amount: acc[key].amount + val.amount } } : { ...acc, [key]: val };
  }, {});

  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: Object.values(shiftsToAdd),
  };
  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      if (edit) {
        dispatch(editTemplatesShiftSuccesful(newCurrentTemplate, shifts[0].job_title.title, notify));
      } else {
        dispatch(addTemplatesShiftSuccesful(newCurrentTemplate, shifts[0].job_title.title, notify));
      }
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const deleteTemplatesShift = (shiftToDelete, userTemplates, notify = false) => dispatch => {
  const { currentTemplate } = userTemplates;
  const filteredShifts = currentTemplate.shifts.filter(shift => {
    const shiftJobTitle = shift.job_title_id || shift.job_title?.id;
    const shiftKey = `${shift.date}_${shift.working_hours}_${shiftJobTitle}`;
    const shiftToDeleteJobTitle = shiftToDelete.job_title_id || shiftToDelete.job_title?.id;
    const shiftToDeleteKey = `${shiftToDelete.date}_${shiftToDelete.working_hours}_${shiftToDeleteJobTitle}`;

    return shiftKey !== shiftToDeleteKey;
  });
  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: filteredShifts,
  };
  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      dispatch(deleteTemplatesShiftSuccesful(newCurrentTemplate, notify));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const deleteUnpublishedTemplateShifts = () => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;

  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: [...currentTemplate.shifts.filter(shift => !shift.draft)],
  };

  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      dispatch(deleteUnpublishedTemplateShiftsSuccesful(newCurrentTemplate));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const changeTemplateName = name => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;

  const newCurrentTemplate = {
    ...currentTemplate,
    name,
  };

  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      dispatch(changeTemplateNameSuccesful(newCurrentTemplate));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const publishDraftTemplateShifts = name => (dispatch, getState) => {
  const { userTemplates, jobtitleFilter } = getState().reducer;
  const { currentTemplate } = userTemplates;
  const selectedJobtitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);

  const shifts = currentTemplate.shifts.reduce((shiftsList, shift) => {
    if (!shift.draft) return [...shiftsList, shift];
    if (shift.draft && selectedJobtitlesIds.includes(shift.job_title_id))
      return [...shiftsList, { ...shift, draft: false }];
    return shiftsList;
  }, []);

  const newCurrentTemplate = {
    ...currentTemplate,
    name: name || currentTemplate.name,
    shifts,
  };

  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      dispatch(publishDraftTemplateShiftsSuccesful(newCurrentTemplate));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const changeCurrentTemplate = template => dispatch => {
  dispatch(changeCurrentTemplateSuccesful(template));
};

export const deleteTemplate = () => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;
  return conn.deleteScheduleTemplate(currentTemplate.id).then(() => {
    dispatch(deleteTemplateSuccesful(currentTemplate.id));
  });
};

export const changeFlexTemplate = shifts => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;

  const shiftsToAdd = [...currentTemplate.shifts, ...shifts].map(formatShiftForTemplates);

  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: Object.values(shiftsToAdd),
  };
  dispatch(editTemplatesShiftSuccesful(newCurrentTemplate, '', false));
};

const getUniqueShiftSequence = shift => `${shift.job_title_id};${shift.working_hours};${shift.date}`;

export const changeFlexTemplateSingleShift = shift => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;
  const uniqueSequnce = getUniqueShiftSequence(shift);
  const filteredShifts = currentTemplate.shifts.filter(
    s => getUniqueShiftSequence(s) !== uniqueSequnce && s.amount !== '',
  );
  if (shift.amount !== '') filteredShifts.push(shift);
  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: filteredShifts,
  };
  dispatch(editTemplatesShiftSuccesful(newCurrentTemplate, '', false));
};

export const deleteCurrentTemplateShifts = () => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;

  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: [],
  };

  conn
    .changeScheduleTemplate(currentTemplate.id, newCurrentTemplate)
    .then(() => {
      dispatch(deleteAllTemplatesShiftsSuccesful(newCurrentTemplate, newCurrentTemplate.name, true));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const clearFlexScheduleDay = dayIndex => (dispatch, getState) => {
  const { currentTemplate } = getState().reducer.userTemplates;

  const newShifts = currentTemplate.shifts.filter(s => s.date !== dayIndex);
  const newCurrentTemplate = {
    ...currentTemplate,
    shifts: Object.values(newShifts),
  };

  dispatch(editTemplatesShiftSuccesful(newCurrentTemplate, '', false));
};
