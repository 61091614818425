/* eslint-disable max-len */
import PropTypes from 'prop-types';

import LoadBudgetTargetModalCalculate from '../LoadBudgetTargetModalCalculate/LoadBudgetTargetModalCalculate.redux';
import { LOAD_BUDGET_TARGET_TYPE } from '../LoadBudgetTargetModalInitialView/LoadBudgetTargetModalInitialView.helpers';
import LoadBudgetTargetModalInitialView from '../LoadBudgetTargetModalInitialView/LoadBudgetTargetModalInitialView.redux';
import LoadBudgetTargetModalManually from '../LoadBudgetTargetModalManually/LoadBudgetTargetModalManually.redux';

const LoadBudgetTargetModalStep = ({ step, formData, setFormData, selectedStepOption, setSelectedStepOption }) => {
  switch (step) {
    case 1:
      return (
        <LoadBudgetTargetModalInitialView
          selectedStepOption={selectedStepOption}
          setSelectedStepOption={setSelectedStepOption}
        />
      );
    case 2:
      if (selectedStepOption === LOAD_BUDGET_TARGET_TYPE.fillManually.id)
        return <LoadBudgetTargetModalManually formData={formData} setFormData={setFormData} />;
      return <LoadBudgetTargetModalCalculate formData={formData} setFormData={setFormData} />;
    default:
      return null;
  }
};

LoadBudgetTargetModalStep.propTypes = {
  step: PropTypes.number,
  formData: PropTypes.shape({
    estimatedBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setFormData: PropTypes.func,
  selectedStepOption: PropTypes.string,
  setSelectedStepOption: PropTypes.func,
};

export default LoadBudgetTargetModalStep;
