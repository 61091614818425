import classnames from 'classnames';
import PropTypes, { arrayOf } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { validateOptionChange } from '@/components/autoscheduler/autoscheduler.helpers';
import Button from '@/components/common/Basic/Button.jsx';
import MDKadroModalHeader from '@/components/common/MDKadroModal/MDKadroModalHeader/ModalHeader.jsx';
import MDModal from '@/components/common/MDModal/Modal.jsx';
import { elasticTemplateGenerationOptions, scheduleGenerationOptions } from '@/constants/autoSchedule';
import { AUTO_GENERATE_SCHEDULE_MODAL, AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import {
  getStepsModalModifiers,
  userTemplateToRecommendedSchedule,
  validateOptions,
} from '@/utils/autoschedulerHelpers.jsx';
import {
  findEmployeesWithDifferentJobTitlesInContractsForDateRange,
  getJobTitleIdsFromContracts,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts.ts';

import AutoGenerateCreateSchedule from '../AutoGenerateCreateSchedule/AutoGenerateCreateSchedule.redux';
import AutoGenerateCreateScheduleOptions from '../AutoGenerateCreateScheduleOptions/AutoGenerateCreateScheduleOptions.jsx';
import AutoGenerateFlowPicker from '../AutoGenerateFlowPicker/AutoGenerateFlowPicker.redux';
import AutoGenerateLoader from '../AutoGenerateLoader/AutoGenerateLoader.jsx';
import { messages } from './autoGenerateStepsModal.messages.ts';

import './AutoGenerateStepsModal.scss';

const STEPS_COUNT = 4;

class AutoGenerateStepsModal extends Component {
  static getInitialState(props, step = 0) {
    return {
      step,
      schedule: {},
      recommendedSchedule: null,
      selectedTemplate: null,
      configuration: elasticTemplateGenerationOptions,
      error: '',
      maxValues: {},
      selectedJobTitles: props.userJobTitles,
    };
  }

  static getMaxValue(scheduleForDay) {
    return Object.values(scheduleForDay).reduce((acc, jobTitleValues) => {
      const values = jobTitleValues.filter(v => v !== '-');

      if (!values.length) return acc;

      const jobTitleMax = Math.max(...values);
      if (jobTitleMax > acc) {
        return jobTitleMax;
      }
      return acc;
    }, 0);
  }

  constructor(props, context) {
    super(props, context);
    this.state = AutoGenerateStepsModal.getInitialState(props);

    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setConfiguration = this.setConfiguration.bind(this);
    this.setConfigurationInputValue = this.setConfigurationInputValue.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
    this.setMaxValue = this.setMaxValue.bind(this);
    this.setRecommendedSchedule = this.setRecommendedSchedule.bind(this);
    this.setSelectedTemplate = this.setSelectedTemplate.bind(this);
    this.changeHourValue = this.changeHourValue.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.renderStep = this.renderStep.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if ((!nextProps.showModal && this.state.step !== 0) || nextProps.modalObject.step !== 0) {
      this.setState(AutoGenerateStepsModal.getInitialState(this.props, nextProps.modalObject.step));
    }
  }

  clickOnX = () => {
    const { showConfirmModal, hideModal, openModal } = this.props;
    const { step } = this.state;

    if (step === 3) {
      const title = (
        <FormattedMessage id="autoGenerate.cancelTitle" defaultMessage="Zatrzymaj proces generowania grafiku" />
      );
      const description = (
        <FormattedMessage
          id="autoGenerate.cancelQuestion"
          defaultMessage="Czy na pewno chcesz zatrzymać proces generowania grafiku?"
        />
      );
      const confirmText = <FormattedMessage id="autoGenerate.cancelProcess" defaultMessage="Zatrzymaj" />;
      const cancelText = <FormattedMessage id="autoGenerate.goBack" defaultMessage="Wstecz" />;
      const confirmFunc = this.confirmAbortingScheduleGenerationProcess;
      const cancelFunc = () => openModal(AUTO_GENERATE_STEPS_MODAL, { step: 3 });
      showConfirmModal({ title, description, confirmText, cancelText, confirmFunc, cancelFunc, skipHide: true });
    } else {
      hideModal();
    }
  };

  confirmAbortingScheduleGenerationProcess = () => {
    const { cancelSchedulingProcessPerformedByAutoScheduler, toggleAutoGenerateScheduleStatus, hideModal } = this.props;

    cancelSchedulingProcessPerformedByAutoScheduler();
    toggleAutoGenerateScheduleStatus(false);
    hideModal();
  };

  onHide() {
    // currently there is a problem with keep state when switching modals
    this.props.hideModal();
  }

  onSubmit() {
    const { configuration, recommendedSchedule, schedule, selectedJobTitles } = this.state;
    const error = validateOptions(configuration);
    if (!error) {
      const configOptions = configuration.filter(opt => opt.isConfigurationOption);

      const shortTimeout = configuration.find(opt => opt.name === scheduleGenerationOptions.short_timeout.name).enabled;
      const availableUndefinedDays = configuration.find(
        opt => opt.name === scheduleGenerationOptions.availability_without_entered.name,
      ).enabled;
      if (recommendedSchedule) {
        this.props.addRecommendedSchedule(recommendedSchedule);
      }
      const selectedEmployees = this.props.userEmployees.reduce((accumulator, employee) => {
        const employeeContracts = this.props.contracts[employee.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(
          employeeContracts,
          this.props.mainDateStore.customDate.start,
          this.props.mainDateStore.customDate.end,
        );
        const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);

        if (
          !employee.inactive &&
          employeeJobTitleIds.some(jobTitleId =>
            this.state.selectedJobTitles.map(jobTitle => jobTitle.id).includes(jobTitleId),
          ) &&
          !employee.deleted
        ) {
          accumulator.push(employee.id);
        }

        return accumulator;
      }, []);

      const employeeIdsWithDifferentJobTitlesInContracts = findEmployeesWithDifferentJobTitlesInContractsForDateRange(
        selectedEmployees,
        this.props.mainDateStore.customDate,
        this.props.contracts,
      );

      this.props.sendSplitShiftsToAutoscheduler(
        schedule,
        selectedJobTitles,
        configOptions,
        shortTimeout,
        availableUndefinedDays,
        TEMPLATE_TYPES.FLEX,
        employeeIdsWithDifferentJobTitlesInContracts,
      );
    }
  }

  setConfiguration(option) {
    const newOptions = this.state.configuration.map(opt => {
      if (opt.name === option.name) {
        return { ...option, enabled: !option.enabled };
      }
      return opt;
    });
    const error = validateOptions(newOptions);
    this.setState({
      configuration: newOptions,
      error,
    });
  }

  setConfigurationInputValue(option, value) {
    const newOptions = this.state.configuration.map(opt => {
      if (
        opt.name === option.name &&
        validateOptionChange(this.state.configuration, option, value, this.props.mainDateStore)
      ) {
        return { ...option, value };
      }
      return opt;
    });
    const error = validateOptions(newOptions);

    this.setState({
      configuration: newOptions,
      error,
    });
  }

  setSchedule(schedule) {
    this.setState({
      schedule,
    });
  }

  setMaxValue(selectedDay, scheduleForDay) {
    const newDayMax = AutoGenerateStepsModal.getMaxValue(scheduleForDay);
    this.setState(prev => ({
      maxValues: {
        ...prev.maxValues,
        [selectedDay]: newDayMax,
      },
    }));
  }

  setRecommendedSchedule(schedule) {
    this.setState(() => ({
      recommendedSchedule: schedule,
    }));
  }

  setSelectedTemplate(templateId) {
    this.setState(() => ({
      selectedTemplate: templateId,
    }));
  }

  changeHourValue(value, hour, jobTitleId, selectedDay) {
    this.setState(prev => {
      const newDaySchedule = {
        ...prev.schedule[selectedDay],
        [jobTitleId]: [
          ...prev.schedule[selectedDay][jobTitleId].slice(0, hour),
          value,
          ...prev.schedule[selectedDay][jobTitleId].slice(hour + 1),
        ],
      };
      const newDayMax = AutoGenerateStepsModal.getMaxValue(newDaySchedule);
      return {
        schedule: {
          ...prev.schedule,
          [selectedDay]: newDaySchedule,
        },
        maxValues: {
          ...prev.maxValues,
          [selectedDay]: newDayMax,
        },
      };
    });
  }

  changeStep(value) {
    if (this.state.step === 2 && value === 'increase') {
      this.onSubmit();
    }
    this.setState(prev => ({
      step: value === 'increase' ? prev.step + 1 : prev.step - 1,
    }));
  }

  renderStep() {
    const showForecastSchedule = this.state.configuration.find(
      opt => opt.name === scheduleGenerationOptions.show_forecast_schedule.name,
    ).enabled;

    switch (this.state.step) {
      case 0:
        return (
          <AutoGenerateFlowPicker
            onHide={this.onHide}
            nextStep={() => this.changeStep('increase')}
            openAutoGenerateScheduleModal={() => this.props.openModal(AUTO_GENERATE_SCHEDULE_MODAL)}
          />
        );
      case 1: {
        const templates = {
          ...this.props.userTemplates,
          templatesArray: this.props.userTemplates.templatesArray.filter(t => t.type === TEMPLATE_TYPES.FLEX),
        };
        return (
          <AutoGenerateCreateScheduleOptions
            error={this.state.error}
            handleTemplateChange={this.setSelectedTemplate}
            handleToggle={this.setConfiguration}
            handleInputChange={this.setConfigurationInputValue}
            options={this.state.configuration}
            selectedTemplate={this.state.selectedTemplate}
            templates={templates}
            showTemplateSelect
          />
        );
      }
      case 2: {
        const recommendedSchedule =
          this.state.selectedTemplate &&
          userTemplateToRecommendedSchedule(
            this.props.userTemplates.templatesArray.find(t => t.id === this.state.selectedTemplate).shifts,
          );
        return (
          <AutoGenerateCreateSchedule
            setSchedule={this.setSchedule}
            setMaxValue={this.setMaxValue}
            setRecommendedSchedule={this.setRecommendedSchedule}
            recommendedSchedule={recommendedSchedule}
            changeHourValue={this.changeHourValue}
            schedule={this.state.schedule}
            maxValues={this.state.maxValues}
            selectedJobTitles={this.state.selectedJobTitles}
            showForecastSchedule={showForecastSchedule}
          />
        );
      }
      case 3:
        return (
          <div className="k-autoGenerateSteps__loaderContainer">
            <AutoGenerateLoader />
            <Button
              className="k-autoGenerateSteps__loaderCloseButton"
              onClick={this.onHide}
              modifiers="orange small uppercase"
            >
              <FormattedMessage id="autoGenerate.loader.button" defaultMessage="Generuj grafik w tle" />
            </Button>
          </div>
        );
      default:
        break;
    }
  }

  renderTitle() {
    if (this.state.step === 0) {
      return (
        <h4 className="k-autoGenerateFlowPicker__title">
          <FormattedMessage id="autoGenerate.flowPicker.title" defaultMessage="Wybierz rodzaj szablonu" />
        </h4>
      );
    }
    return '';
  }

  render() {
    const buttonsClassnames = classnames('k-autoGenerateSteps__buttons', {
      'k-autoGenerateSteps__buttons--hide': this.state.step === 0 || this.state.step === 3,
    });
    const modifiers = getStepsModalModifiers(this.state.step);

    const stepsCount = this.props.modalObject?.selectedOpenShiftsTemplate
      ? `${this.state.step}/${this.state.step}`
      : `${this.state.step + 1}/${STEPS_COUNT}`;

    const selectedEmployees = this.props.userEmployees
      .filter(employee => {
        const employeeContracts = this.props.contracts[employee.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(
          employeeContracts,
          this.props.mainDateStore.customDate.start,
          this.props.mainDateStore.customDate.end,
        );
        const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
        return (
          !employee.inactive &&
          employeeJobTitleIds.some(jobTitleId =>
            this.state.selectedJobTitles.map(jobTitle => jobTitle.id).includes(jobTitleId),
          ) &&
          !employee.deleted
        );
      })
      .map(employee => employee.id);

    const employeeIdsWithDifferentJobTitlesInContracts = findEmployeesWithDifferentJobTitlesInContractsForDateRange(
      selectedEmployees,
      this.props.mainDateStore.customDate,
      this.props.contracts,
    );
    const employeeNamesWithDifferentJobTitlesInContracts = employeeIdsWithDifferentJobTitlesInContracts.map(
      employeeId => {
        const employee = this.props.userEmployees.find(e => e.id === employeeId);
        return `${employee.first_name} ${employee.last_name}`;
      },
    );
    const contractWarning =
      employeeIdsWithDifferentJobTitlesInContracts.length > 0 && this.state.step === 2 ? (
        <span className="k-autoGenerateSteps__error">
          {this.context.intl.formatMessage(
            employeeIdsWithDifferentJobTitlesInContracts.length > 1
              ? messages.autoGenerateStepsModalContractsWarningMultipleEmployees
              : messages.autoGenerateStepsModalContractsWarningOneEmployee,
            {
              employeeNames: employeeNamesWithDifferentJobTitlesInContracts.join(', '),
            },
          )}
        </span>
      ) : null;

    return (
      <MDModal
        className="k-autoGenerateSteps"
        show={this.props.showModal}
        onHide={this.onHide}
        modifiers={modifiers}
        onSubmit={() => this.changeStep('increase')}
        errorMessage={this.state.errorMessage}
      >
        <>
          <MDModal.Content withoutOverlayScroll>
            <MDKadroModalHeader onHide={this.clickOnX} title={this.renderTitle()} />
            {this.renderStep()}
            {contractWarning}
          </MDModal.Content>
          <MDModal.Footer>
            <div className={buttonsClassnames}>
              <Button
                onClick={() => this.changeStep('increase')}
                modifiers="orange teeny uppercase"
                className="k-autoGenerateSteps__button--next"
                disabled={this.state.error}
              >
                {this.state.step === 2 ? (
                  <FormattedMessage id="common.generate" defaultMessage="Generuj" />
                ) : (
                  <FormattedMessage id="common.next" defaultMessage="Dalej" />
                )}
              </Button>
              <Button onClick={() => this.changeStep('decrease')} modifiers="reverse-orange teeny uppercase">
                <FormattedMessage id="common.back" defaultMessage="Wstecz" />
              </Button>
            </div>
            <div className="k-autoGenerateSteps__progress">
              <div className="k-autoGenerateSteps__progressText">{stepsCount}</div>
              <div
                className="k-autoGenerateSteps__progressBar"
                style={{ width: `calc(${(100 / STEPS_COUNT) * (this.state.step + 1)}% + 20px)` }}
              />
            </div>
          </MDModal.Footer>
        </>
      </MDModal>
    );
  }
}

AutoGenerateStepsModal.defaultProps = {
  modalObject: {},
};

AutoGenerateStepsModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AutoGenerateStepsModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    step: PropTypes.number,
    selectedOpenShiftsTemplate: PropTypes.bool,
  }),

  recommendedSchedule: PropTypes.shape({
    date: PropTypes.string,
    template: arrayOf({
      job_title_id: PropTypes.string,
    }),
  }),
  cancelSchedulingProcessPerformedByAutoScheduler: PropTypes.func,
  toggleAutoGenerateScheduleStatus: PropTypes.func,
  hideModal: PropTypes.func,
  openModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  addRecommendedSchedule: PropTypes.func,
  sendSplitShiftsToAutoscheduler: PropTypes.func,
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    templatesArray: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
};

export default AutoGenerateStepsModal;
