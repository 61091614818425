import { connect } from 'react-redux';

import CompanyManageView from './CompanyManageView.jsx';

const mapStateToProps = state => ({
  permissions: state.reducer.userPermissions.permissions,
  restrictions: state.reducer.userPermissions.restrictions,
});

export default connect(mapStateToProps)(CompanyManageView);
