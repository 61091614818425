import { memo } from 'react';

import { ClosedSidebarAvatar } from './ClosedSidebarAvatar/ClosedSidebarAvatar';
import { MultiAccountsMenu } from './Menu/MultiAccountsMenu';
import { MultiAccountsSwitcher } from './Switcher/MultiAccountsSwitcher';
import { useMultiAccounts } from './useMultiAccounts';

import './MultiAccounts.scss';

export const MultiAccounts = memo(() => {
  const { classes, isAtLeastTabletLandscape, isAnyModalOpened, isSidebarHovered, isMenuOpen, toggleMenu } =
    useMultiAccounts();

  if (isAtLeastTabletLandscape && (!isSidebarHovered || isAnyModalOpened)) return <ClosedSidebarAvatar />;

  return (
    <div className={classes.spaceContainer}>
      <div className={classes.container}>
        <MultiAccountsSwitcher {...{ toggleMenu }} />
        {isMenuOpen && <MultiAccountsMenu />}
      </div>
    </div>
  );
});

MultiAccounts.displayName = 'MultiAccounts';
