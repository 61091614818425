import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MDCheckbox from '@/components/common/inputs/MDCheckbox/MDCheckbox.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import { emplMassEditTypeActions } from './constants';

const AdditionalOptionsModal = ({ modalObject, hideModal, massEditEmployees }, { intl }) => {
  const initialOptions = modalObject?.availableOptions.reduce(
    (result, option) => ({
      ...result,
      [option.id]: false,
    }),
    {},
  );
  const [options, setOptions] = useState(initialOptions || {});

  if (!modalObject) {
    return null;
  }
  const { selectedEmployees, selectedType, selectedAction, currentFieldValue } = modalObject;
  const action = emplMassEditTypeActions[selectedType][selectedAction];

  const handleToggle = id => {
    setOptions(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const clearAndHide = () => {
    hideModal();
    setOptions(initialOptions);
  };

  return (
    <MDKadroModal
      title={<FormattedMessage id="employees.editMass.title" defaultMessage="Edycja pracowników" />}
      show
      onHide={clearAndHide}
      onSubmit={async () => {
        await massEditEmployees(selectedEmployees, currentFieldValue, action, options);
        hideModal();
      }}
      withoutOverlayScroll
      hideCancelButton
      modifiers={['narrow']}
    >
      {(modalObject.availableOptions || []).map(option => (
        <MDCheckbox
          id={option.id}
          text={intl.formatMessage(option.label)}
          value={options[option.id]}
          onChange={() => handleToggle(option.id)}
          reverse
        />
      ))}
    </MDKadroModal>
  );
};
AdditionalOptionsModal.contextTypes = {
  intl: PropTypes.shape({}),
};

AdditionalOptionsModal.propTypes = {
  modalObject: PropTypes.shape({
    availableOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      }),
    ),
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
    selectedType: PropTypes.string,
    selectedAction: PropTypes.string,
    currentFieldValue: PropTypes.string,
  }),
  hideModal: PropTypes.func,
  massEditEmployees: PropTypes.func,
};

export default AdditionalOptionsModal;
