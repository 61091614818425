import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { calculateAbsenceConnectorLength } from '@/components/scheduler/ScheduleTable/ScheduleAbsenceBlock/ScheduleAbsenceBlock.helpers';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes.js';

import './ScheduleAbsenceBlock.scss';

const ScheduleAbsenceBlock = props => {
  const {
    absence,
    isMonthView,
    date,
    lastVisibleDate,
    isEmployee,
    showModal,
    absenceName,
    employeeName,
    typeName,
    statusName,
    size,
    isQuickPlanningEnabled,
    isEditDisabled,
    isAvaMode,
  } = props;
  const isDraft = absence.status === 'draft';

  const absenceClass = classnames('k-absenceBlock', `k-absenceBlock--${size}`, {
    'k-absenceBlock--employee': isEmployee,
    'k-absenceBlock--draft': isDraft,
    'k-absenceBlock--disabled': isEditDisabled,
    'k-absenceBlock--avaMode': isAvaMode,
  });
  const lastDate = lastVisibleDate < absence.to ? lastVisibleDate : absence.to;
  const numberOfDays = moment(lastDate).diff(date, 'days') + 1;
  const hasBoldBorder = !isMonthView && moment(absence.from).isoWeekday() < 6 && moment(absence.to).isoWeekday() >= 6;
  const isMonth = size === 'month';
  const cellPaddingWidth = isMonth ? 4 : 17;
  const width = `calc((100% + ${cellPaddingWidth}px) * ${numberOfDays} - ${isMonth ? 1 : cellPaddingWidth}px + ${
    hasBoldBorder ? '4px' : '0px'
  })`;
  const marginTop = isMonth ? '2px' : '8px';

  return (
    <div
      onClick={event => {
        event.stopPropagation();
        if (!isEmployee && !isQuickPlanningEnabled && !isEditDisabled) {
          showModal(ADD_ABSENCE_PROPOSAL_MODAL, {
            ...absence,
            employee_name: employeeName,
            type_name: typeName,
            status_name: statusName,
          });
        }
      }}
      className={absenceClass}
      role="presentation"
      style={{ width, marginTop: !isEmployee ? marginTop : '6px' }}
    >
      <div className="k-absenceBlock__connector" />
      <div className="sch_block__info">
        <div className="absence-name">
          {absenceName} {absence.absence_hours}
        </div>
      </div>
      <Icon name="fact_check" />
    </div>
  );
};

ScheduleAbsenceBlock.propTypes = {
  absence: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    absence_hours: PropTypes.string,
    status: PropTypes.string,
  }),
  isMonthView: PropTypes.bool,
  date: PropTypes.string,
  lastVisibleDate: PropTypes.string,
  isEmployee: PropTypes.bool,
  showModal: PropTypes.func,
  absenceName: PropTypes.string,
  employeeName: PropTypes.string,
  typeName: PropTypes.string,
  statusName: PropTypes.string,
  isQuickPlanningEnabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  size: PropTypes.string,
  isAvaMode: PropTypes.bool,
};

export default ScheduleAbsenceBlock;
