import PropTypes from 'prop-types';
import { Component } from 'react';

import CalendarDayView from './CalendarDayView.jsx';
import Modal from './Modal/Modal.jsx';

class CalendarAvaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: -1,
      edited: -1,
    };
    this.calendarAvaList = {};
    this.closeFunc = this.close.bind(this);
    this.selectItemFunc = this.selectItem.bind(this);
    this.startEditFunc = this.startEdit.bind(this);
    this.abortEditFunc = this.abortEdit.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.day.format('DM') !== this.props.day.format('DM')) {
      this.selectItem(this.props.day.date() - 1);
      const scrollTo = this.props.day.date();
      const elements = document.getElementsByClassName('avaListElement');
      if (elements.length > 0) this.calendarAvaList.scrollTop = elements[scrollTo - 1].offsetTop;
    }
  }

  clear() {
    const newState = {};
    this.setState(newState);
  }

  startEdit(id) {
    this.setState({ edited: id, selected: id });
    const scrollTo = id;
    const elements = document.getElementsByClassName('avaListElement');
    if (elements.length > 0) this.calendarAvaList.scrollTop = elements[scrollTo].offsetTop;
  }
  abortEdit(id) {
    this.setState({ edited: -1, selected: id });
    const scrollTo = id;
    const elements = document.getElementsByClassName('avaListElement');
    if (elements.length > 0) this.calendarAvaList.scrollTop = elements[scrollTo].offsetTop;
  }

  selectItem(id) {
    this.setState({ selected: id });
  }
  close() {
    this.setState({ selected: -1, edited: -1 });
    this.props.closeFunc();
  }

  render() {
    return (
      <div>
        <Modal show={this.props.visible} onHide={this.closeFunc} className="calendar-modal">
          <Modal.Header closeButton>
            <Modal.Title>Dostępność</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                position: 'relative',
              }}
              ref={ref => {
                this.calendarAvaList = ref;
              }}
            >
              {this.props.avas.map((ava, i) => (
                <CalendarDayView
                  day={this.props.day}
                  ava={ava}
                  onClickFunc={this.selectItemFunc}
                  onStartEditFunc={this.startEditFunc}
                  onAbortEditFunc={this.abortEditFunc}
                  selected={i === this.state.selected}
                  edited={i === this.state.edited}
                  key={i}
                  index={i}
                  deleteAva={this.props.deleteAva}
                  addAva={this.props.addAva}
                  editAva={this.props.editAva}
                  employeeId={this.props.employeeId}
                  currentCompany={this.props.currentCompany}
                />
              ))}
              <div style={{ width: '100%', height: 'calc(100vh - 270px)' }} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
CalendarAvaList.propTypes = {
  day: PropTypes.shape({}),
  addAva: PropTypes.func,
  editAva: PropTypes.func,
  deleteAva: PropTypes.func,
  employeeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeFunc: PropTypes.func,
  visible: PropTypes.bool,
  avas: PropTypes.arrayOf(PropTypes.shape({})),
  currentCompany: PropTypes.shape({}),
};

export default CalendarAvaList;
