import { connect } from 'react-redux';

import { togglePayrollSettingsModal } from '@/actions/payrollUI.js';

import ExportsButtonBar from './ExportsButtonBar';

const mapDispatchToProps = {
  togglePayrollSettingsModal,
};

export default connect(null, mapDispatchToProps)(ExportsButtonBar);
