import classnames from 'classnames';

export const getIconName = (value: number): string => {
  if (value > 0) return 'arrow_upward_alt';
  if (value < 0) return 'arrow_downward_alt';
  return 'equal';
};

export const getIndicatorClassName = (base: string, condition: number): string =>
  classnames(base, {
    [`${base}--upward`]: condition > 0,
    [`${base}--downward`]: condition < 0,
  });
