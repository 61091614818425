import PropTypes from 'prop-types';
import { createContext, Component } from 'react';

export const ScrollContext = createContext({ scrollCallback: () => {}, scrollPosition: 0 });

class ScrollWrapper extends Component {
  constructor(props) {
    super(props);
    this.scrollCallback = this.scrollCallback.bind(this);
    this.state = {
      scroll: 0,
      providerData: { scrollCallback: this.scrollCallback, scrollPosition: 0 },
    };

    this.minDelta = props.minDelta || 5;
  }

  scrollCallback(value) {
    if (Math.abs(this.state.scroll - value) >= this.minDelta) {
      this.setState({
        scroll: value,
        providerData: { scrollCallback: this.scrollCallback, scrollPosition: value },
      });
    }
  }

  render() {
    return (
      <div style={{ clear: 'both' }}>
        <ScrollContext.Provider value={this.state.providerData}>{this.props.children}</ScrollContext.Provider>
      </div>
    );
  }
}

ScrollWrapper.propTypes = {
  children: PropTypes.node,
  minDelta: PropTypes.number,
};

export default ScrollWrapper;
