import { connect } from 'react-redux';

import { changeCurrentUser } from '@/actions';
import { checkEmail } from '@/actions/auth.jsx';

import UserDataSettings from './UserDataSettings';

const mapStateToProps = state => ({
  user: state.reducer.currentUser.user,
});

const mapDispatchToProps = {
  changeCurrentUser,
  checkEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataSettings);
