import { employmentConditionsFilterOptions } from '@/constants/employmentConditions.js';
import { AVAILABILITIES_DISPLAY_MODE_TYPE, SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes.js';
import { DISPLAY_SETTINGS_SCHEDULE_KEYS } from '@/constants/settingsConstants.js';

import { changeEmploymentConditionsFilter } from './employmentConditionsFilter.js';
import { changeJobTitleFilter } from './jobtitleFilter.js';
import { changeFilter } from './jobTitlesGrouping.js';
import { changeScheduleMode, turnScheduleSettingsOn } from './schedule.jsx';
import { toogleQuickPlanningScheduleMode } from './schedule/quickPlanning.js';
import { changeScheduleLocationFilter } from './schedule/scheduleLocationFilter.js';
import { selectAvailabilitiesMode, selectShiftsMode } from './schedule/viewMode.js';

export const selectAllJobTitlesGroupedAndEmploymentConditions = () => (dispatch, getState) => {
  const { reducer } = getState();
  const allEmploymentConditions = [...reducer.employmentConditions, ...employmentConditionsFilterOptions];

  dispatch(changeFilter());
  dispatch(changeEmploymentConditionsFilter(allEmploymentConditions));
};

export const selectAllJobTitlesAndEmploymentConditions = () => (dispatch, getState) => {
  const { reducer } = getState();
  const allJobTitles = reducer.userJobTitles;
  const allEmploymentConditions = [...reducer.employmentConditions, ...employmentConditionsFilterOptions];

  dispatch(changeJobTitleFilter(allJobTitles));
  dispatch(changeEmploymentConditionsFilter(allEmploymentConditions));
};

export const selectFirstLocation = () => (dispatch, getState) => {
  if (getState().reducer.userLocations.length) {
    const location = getState().reducer.userLocations[0];
    dispatch(changeScheduleLocationFilter([location.id]));
  }
};

export const selectAllDisplaySettings = () => (dispatch, getState) => {
  const scheduleSettings = getState().reducer.scheduleUIState.settings;
  const filteredSettings = scheduleSettings.filter(setting => DISPLAY_SETTINGS_SCHEDULE_KEYS.includes(setting.type));
  dispatch(turnScheduleSettingsOn(filteredSettings));
};

export const changeQuickPlanningAndDisplayMode = mode => (dispatch, getState) => {
  const isQuickPlanningMode = getState().reducer.schedule.quickPlanning.enabled;
  const { type } = mode;

  switch (type) {
    case AVAILABILITIES_DISPLAY_MODE_TYPE:
      dispatch(selectAvailabilitiesMode());
      isQuickPlanningMode && dispatch(toogleQuickPlanningScheduleMode());
      break;
    case SCHEDULE_DISPLAY_MODE_TYPE:
      dispatch(selectShiftsMode());
      break;
    default:
      break;
  }
  dispatch(changeScheduleMode(mode));
};

export const sortLocationsByLocationIds = (locations, locationIds) => {
  const sortLocationsByIds = (a, b) => {
    const indexA = locationIds.indexOf(a.id);
    const indexB = locationIds.indexOf(b.id);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    }

    return 0;
  };

  return locations.sort(sortLocationsByIds);
};
