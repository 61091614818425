import classnames from 'classnames';
import PropTypes from 'prop-types';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton.jsx';

import { messages } from './ScheduleBudgetTable.messages.js';

const renderButtons = (showRecommendedSchedule, showBudgetEstimateView, showBudgetMetrics) => [
  { name: 'schedule', show: showRecommendedSchedule, optionName: 'schedule' },
  { name: 'attach_money', show: showBudgetEstimateView, optionName: 'estimates' },
  { name: '%', modifers: ['percent'], show: showBudgetEstimateView, optionName: 'percentEstimates' },
  { name: 'point_of_sale', show: showBudgetEstimateView, optionName: 'sale' },
  { name: 'bar_chart', modifers: ['barChart'], show: showBudgetMetrics, optionName: 'budgetMetrics' },
];

const ScheduleBudgetTableOptions = (
  {
    showRecommendedSchedule,
    showBudgetEstimateView,
    selectedOptions,
    toggleOption,
    showBudgetMetrics,
  },
  { intl },
) => {
  const options = renderButtons(showRecommendedSchedule, showBudgetEstimateView, showBudgetMetrics);
  const filteredOptions = options.filter(option => option.show);
  if (filteredOptions.length === 0) return null;

  return (
    <thead>
      <tr>
        <th className="options">
          <div className="iconsWrapper">
            {filteredOptions.map(option => {
              const modifiers = classnames('icon', option.modifers, {
                active: selectedOptions.includes(option.optionName),
              });
              return (
                <MDIconButton
                  key={option.optionName}
                  modifiers="labelWithTopOffset"
                  onClick={() => toggleOption(option.optionName)}
                  className={modifiers}
                  label={intl.formatMessage(messages[option.optionName])}
                  icon={option.name}
                />
              );
            })}
          </div>
        </th>
      </tr>
    </thead>
  );
};

ScheduleBudgetTableOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleBudgetTableOptions.propTypes = {
  showRecommendedSchedule: PropTypes.bool,
  showBudgetEstimateView: PropTypes.bool,
  showBudgetMetrics: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  toggleOption: PropTypes.func,
};

export default ScheduleBudgetTableOptions;
