import { defineMessages } from 'react-intl';

const messages = defineMessages({
  plannedLabourCost: {
    id: 'reports.plannedLabourCost',
    defaultMessage: 'Zaplanowany koszt pracy',
  },
  actualLabourCost: {
    id: 'reports.actualLabourCost',
    defaultMessage: 'Realny koszt pracy',
  },
});

export const metadata = [
  {
    key: 'plannedLabourCost',
    color: '#FF6B01',
    chartType: 'bar',
    yAxisId: 'left',
    order: 1,
    label: messages.plannedLabourCost,
  },
  {
    key: 'actualLabourCost',
    color: '#2C223F',
    chartType: 'bar',
    yAxisId: 'left',
    order: 2,
    label: messages.actualLabourCost,
  },
];

export const axisMetadata = {
  left: {
    unit: '%',
  },
};
