import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAppSelector } from '@/redux-store';
import { selectEmploymentCondition } from '@/redux-store/employmentConditions';

import { GroupNodeType } from '../../../GroupedEmployees.types';

type Props = {
  groupKey: GroupNodeType['groupKey'];
};

const messages = defineMessages({
  none: {
    id: 'groupingEmployees.rest',
    defaultMessage: 'Pozostałe',
  },
});

export const GroupEmploymentCondition = memo(({ groupKey }: Props) => {
  const employmentCondition = useAppSelector(selectEmploymentCondition(groupKey));
  const intl = useIntl();

  return <span className="k-groupBar__title">{employmentCondition?.name || intl.formatMessage(messages.none)}</span>;
});

GroupEmploymentCondition.displayName = 'GroupEmploymentCondition';
