import { connect } from 'react-redux';

import { toggleScheduleColumn } from '@/actions/schedule.jsx';
import ScheduleTableHeader from '@/components/scheduler/ScheduleTableHeader.jsx';

const mapStateToProps = (state, ownProps) => ({
  holidays: state.reducer.calendarData.holidays,
  dateStore: state.reducer.mainDateStore,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode,
  settings: state.reducer.scheduleUIState.settings,
  selectedColumns: state.reducer.scheduleUIState.selectedColumns,
  canPaste: state.reducer.copyPaste.canPaste,
  managerNotes: state.reducer.managerNotes[ownProps.locationId],
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  toggleScheduleColumn,
};
const ScheduleTableHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleTableHeader);

export default ScheduleTableHeaderContainer;
