import { useDebugValue, useEffect, useRef, useState } from 'react';

export const useSimplePopover = () => {
  const [visibility, setVisibility] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const toogleDropdown = ({ target }) => {
      const { current: dropdownCurrentObject } = dropdownRef;
      const { current: buttonCurrentObject } = buttonRef;
      if (dropdownCurrentObject && !dropdownCurrentObject.contains(target)) {
        setVisibility(false);
      } else if (buttonCurrentObject.contains(target)) {
        setVisibility(true);
      }
    };
    document.body.addEventListener('click', toogleDropdown);
    return () => document.body.removeEventListener('click', toogleDropdown);
  }, []);

  useDebugValue(visibility ? 'visiable' : 'hidden');

  return [dropdownRef, buttonRef, visibility];
};
