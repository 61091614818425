import {
  GET_WORKTIME_REPORT,
  REPORTS_DATE_CHANGE_CUSTOM_DATE,
  REPORTS_DATE_CHANGE_MODE,
  REPORTS_DATE_CHANGE_MODE_AND_DATE,
  REPORTS_DATE_MOVE_LEFT,
  REPORTS_DATE_MOVE_RIGHT,
  REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE,
  REPORTS_JOB_TITLES_FILTER_CHANGE,
  REPORTS_LOCATIONS_FILTER_CHANGE,
} from '@/constants/ActionTypes.js';

const initialState = {
  selectedLocations: [],
  selectedJobTitles: [],
  selectedEmploymentConditions: [],
  isButtonDisabled: true,
};

export const filters = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_DATE_CHANGE_MODE:
    case REPORTS_DATE_MOVE_LEFT:
    case REPORTS_DATE_MOVE_RIGHT:
    case REPORTS_DATE_CHANGE_CUSTOM_DATE:
    case REPORTS_DATE_CHANGE_MODE_AND_DATE:
      return {
        ...state,
        isButtonDisabled: false,
      };
    case REPORTS_LOCATIONS_FILTER_CHANGE:
      return {
        ...state,
        selectedLocations: action.locations,
        isButtonDisabled: false,
      };
    case REPORTS_JOB_TITLES_FILTER_CHANGE:
      return {
        ...state,
        selectedJobTitles: action.jobTitles,
        isButtonDisabled: false,
      };
    case REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE:
      return {
        ...state,
        selectedEmploymentConditions: action.employmentConditions,
        isButtonDisabled: false,
      };
    case GET_WORKTIME_REPORT:
      return {
        ...state,
        isButtonDisabled: true,
      };
    default:
      return state;
  }
};
