import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { MDTimeInput } from '@/components/common/inputs/MDComponents';
import { createEvent } from '@/utils/inputHelpers.js';
import { inputValidation } from '@/utils/inputValidation';

import MultipleSettingsOptions from '../MultipleSettingsOptions/MultipleSettingsOptions';
import { validationErrorMessages } from './WorkingHoursInputs.messages';

import './WorkingHoursInputs.scss';

const WorkingHoursInputs = (props, { intl }) => {
  const { moreItemsText, rowName, rowsData = [], handleInput, slug, errorMessage } = props;

  const addRow = useCallback(() => {
    const newId = Math.max(...rowsData.map(({ id }) => id)) + 1;
    const newWorkingHours = [...rowsData, { workingHours: '__:__-__:__', id: newId }];
    handleInput(createEvent(slug, newWorkingHours));
  }, [rowsData, slug]);

  const deleteRow = useCallback(
    id => {
      const newWorkingHours = rowsData.filter(row => row.id !== id);
      handleInput(createEvent(slug, newWorkingHours));
    },
    [rowsData, slug],
  );

  const changeWorkingHours = (e, id) => {
    const newWorkingHours = rowsData.map(row => row.id !== id ? row : { ...row, workingHours: e.target.value });
    handleInput(createEvent(slug, newWorkingHours));
  };

  const validate = id => {
    const newWorkingHours = rowsData.map(row => {
      if (row.id !== id) return row;
      const error = inputValidation('working_hours_2', row.workingHours);
      const errorMessage = error ? intl.formatMessage(validationErrorMessages.incorrectWorkingHours) : '';
      return { ...row, error: errorMessage };
    });
    handleInput(createEvent(slug, newWorkingHours));
  };

  const renderRowOptions = rowData => (
    <div className="workingHoursInputs__timeInput">
      <MDTimeInput
        alwaysShowMask
        short={false}
        value={rowData.workingHours}
        onChange={e => changeWorkingHours(e, rowData.id)}
        modifiers="alignCenter"
        errorMessage={rowData.error}
        onBlur={() => validate(rowData.id)}
      />
    </div>
  );

  return (
    <MultipleSettingsOptions
      renderRowOptions={renderRowOptions}
      moreItemsText={moreItemsText}
      rowsData={rowsData}
      rowName={rowName}
      addRow={addRow}
      deleteRow={deleteRow}
      errorMessage={errorMessage ? intl.formatMessage(validationErrorMessages.workingHoursOverlaps) : ''}
    />
  );
};

WorkingHoursInputs.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

WorkingHoursInputs.propTypes = {
  moreItemsText: PropTypes.node,
  rowName: PropTypes.node,
  slug: PropTypes.string,
  handleInput: PropTypes.func,
};

export default WorkingHoursInputs;
