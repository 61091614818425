import { connect } from 'react-redux';

import { openApprovalTradeShiftModal, openCoworkerTradeShiftModal } from '@/actions/uiState';

import ScheduleTableTradeShift from './ScheduleTableSingleDayTradeShift.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.mainDateStore,
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  tradeShifts: state.reducer.tradeShifts,
  userPermissions: state.reducer.userPermissions,
  rowSticky: !!state.reducer.schedule.stickyRows.tradeShiftsSticky,
});

const mapDispatchToProps = {
  openCoworkerTradeShiftModal,
  openApprovalTradeShiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableTradeShift);
