import { PropTypes } from 'prop-types';

import { getDateFormatForGraphTooltip } from '@/utils/dateHelper';
import {
  checkRecommendedScheduleDisplayPermissions,
  getPointsForShifts,
  getPointsForShiftsAndEmployees,
} from '@/utils/recommendedScheduleHelpers';

import ScheduleTableHeaderGraphCell from './ScheduleTableHeaderGraphCell.jsx';

const ScheduleTableGraphsHeader = props => {
  const showRecommendedScheduleGraph = checkRecommendedScheduleDisplayPermissions(props.userPermissions.restrictions);
  const relevantShifts = props.visibleEmployees.reduce((sum, employeeId) => {
    const shifts = props.shifts[employeeId] ? Object.values(props.shifts[employeeId].shifts) : [];
    return [...sum, shifts];
  }, []);

  return (
    <tr className={`${props.dateStore.dateArray.length <= 7 ? 'schedule' : ''} empty`}>
      {props.dateStore.dateArray.map((date, index) => {
        const pointsForShifts = getPointsForShifts(
          date,
          relevantShifts,
          props.location.id,
          props.jobtitleFilter.selectedJobtitlesGrouped,
        );
        const shiftsMax = Math.max(...pointsForShifts.map(p => p.ye));
        const pointsForEmployees = getPointsForShiftsAndEmployees(
          date,
          props.recommendedSchedule,
          props.location.id,
          pointsForShifts,
          props.jobtitleFilter.selectedJobtitlesGrouped,
        );
        const employeesMax = Math.max(...pointsForEmployees.map(p => p.ye));
        const suggestedMax = Math.max(...pointsForEmployees.map(p => p.ys));
        const finalEmployeesMax = Math.max(employeesMax, suggestedMax);
        return (
          <th key={index} className="graph">
            <ScheduleTableHeaderGraphCell
              showRecommendedScheduleGraph={showRecommendedScheduleGraph}
              dayNumber={index}
              points={showRecommendedScheduleGraph ? pointsForEmployees : pointsForShifts}
              max={showRecommendedScheduleGraph ? finalEmployeesMax : shiftsMax}
              date={getDateFormatForGraphTooltip(date)}
            />
          </th>
        );
      })}
    </tr>
  );
};

ScheduleTableGraphsHeader.defaultProps = {
  location: {
    id: '',
  },
};

ScheduleTableGraphsHeader.propTypes = {
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  visibleEmployees: PropTypes.arrayOf(PropTypes.string),
  shifts: PropTypes.shape({}),
  location: PropTypes.shape({
    id: PropTypes.number,
  }),
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  recommendedSchedule: PropTypes.shape({
    recommendedSchedule: PropTypes.shape({}),
  }),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitlesGrouped: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ScheduleTableGraphsHeader;
