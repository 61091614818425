import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import FormatIcon from '@/components/common/exports/FormatIcon';
import * as EXPORT_STATES from '@/constants/exportStates';
import { useBlockingActions } from '@/hooks';
import { convertDateToCustomFormat } from '@/utils/dateHelper';

import { EXPORT_ACTIONS, getJobTitlesText, getLocationsText } from './ExportItem.helpers';
import { messages } from './ExportItem.messages';

import './ExportItem.scss';

const ExportItem = (props, { intl }) => {
  const { item, allJobTitles, userLocations, deleteExportHistoryItem, downloadExport, setDeletedItem } = props;
  const { from, to, job_titles: jobTitles = [], locations = [] } = item.payload;
  const loading = item.status === 'pending';
  const error = item.status === 'error';
  const downloadDisabled = loading || error;
  const hasNotBeenSeen = item.status === EXPORT_STATES.UNSEEN;
  const { fileIconText, fileIconSubtitle, color, backgroundColor, title } = item;

  const { runBlockingAction } = useBlockingActions({
    [EXPORT_ACTIONS.delete]: false,
    [EXPORT_ACTIONS.download]: false,
  });
  const jobTitlesText = useMemo(() => getJobTitlesText(jobTitles, allJobTitles), [jobTitles, allJobTitles]);
  const locationsText = useMemo(() => getLocationsText(locations, userLocations), [locations, userLocations]);

  const itemClassname = classnames('k-exportItem', {
    'k-exportItem--loading': loading,
    'k-exportItem--error': error,
    'k-exportItem--unseen': hasNotBeenSeen,
  });
  const downloadBtnClassname = classnames('k-exportItem__downloadButton', {
    'k-exportItem__downloadButton--disabled': downloadDisabled,
    'k-exportItem__downloadButton--unseen': hasNotBeenSeen,
  });

  const handleDeleteItem = async id => {
    await deleteExportHistoryItem(id);
    setDeletedItem(id);
  };

  return (
    <div className={itemClassname}>
      <div className="k-exportItem__content">
        <span
          onClick={() => runBlockingAction(handleDeleteItem, EXPORT_ACTIONS.delete)(item.id)}
          className="material-icons k-exportItem__deleteButton"
          role="presentation"
        >
          close
        </span>
        <div className="k-exportItem__left">
          <FormatIcon
            text={fileIconText}
            subtitle={fileIconSubtitle}
            color={color}
            backgroundColor={backgroundColor}
            withoutHoverEffect
          />
          <div
            role="presentation"
            className={downloadBtnClassname}
            onClick={() => !downloadDisabled && runBlockingAction(downloadExport, EXPORT_ACTIONS.download)(item.id)}
          >
            {intl.formatMessage(messages.download)}
          </div>
        </div>
        <div className="k-exportItem__right">
          <div className="k-exportItem__text k-exportItem__text--primary">
            {convertDateToCustomFormat(from)} - {convertDateToCustomFormat(to)}
          </div>
          <div className="k-exportItem__titleContainer">
            {loading && (
              <div className="k-exportItem__spinner">
                <img src="/img/loading-spin.svg" alt="loader" />
              </div>
            )}
            {error && <i className="material-icons k-exportItem__errorIcon">priority_high</i>}
            <span className="k-exportItem__title">{title}</span>
          </div>
          <div className="k-exportItem__jobAndLocContainer">
            <div>
              <div className="k-exportItem__text k-exportItem__text--secondary">
                {intl.formatMessage(messages.locations)} ({locations.length})
              </div>
              <div className="k-exportItem__text k-exportItem__text--primary">{locationsText}</div>
            </div>
            <div>
              <div className="k-exportItem__text k-exportItem__text--secondary">
                {intl.formatMessage(messages.jobTitles)} ({jobTitles.length})
              </div>
              <div className="k-exportItem__text k-exportItem__text--primary">{jobTitlesText}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="k-exportItem__footer">
        {intl.formatMessage(messages.generatedAt, { timestamp: moment(item.created_at).format('DD.MM.YYYY HH:mm:ss') })}
      </div>
    </div>
  );
};

ExportItem.propTypes = {
  item: PropTypes.shape({
    payload: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.string),
      job_titles: PropTypes.arrayOf(PropTypes.string),
    }),
    id: PropTypes.string,
    status: PropTypes.string,
    export_type: PropTypes.string,
    created_at: PropTypes.string,
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  allJobTitles: PropTypes.shape({}),
  deleteExportHistoryItem: PropTypes.func,
  downloadExport: PropTypes.func,
  setDeletedItem: PropTypes.func,
  fileIconText: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  displayName: PropTypes.shape({
    typeName: PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
    }),
  }),
};

ExportItem.contextTypes = {
  intl: PropTypes.shape({}),
};

export default ExportItem;
