import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import { changeEmployeesOrderForLocation, startCustomSorting } from '@/actions/employeeSorting.js';
import { copy, deleteSelected, deselectAllEmployeesRows, exportSchedule, paste } from '@/actions/schedule.jsx';
import { changeScheduleSearchString, showModal } from '@/actions/uiState.js';
import { getWorkingRulesViolationsForCurrentDateArrayWithToastr } from '@/actions/workingRules.js';

import ScheduleButtonBar from './ScheduleButtonBar.jsx';

const mapStateToProps = state => ({
  copyPaste: state.reducer.copyPaste,
  selected: state.reducer.scheduleUIState.selected,
  selectedRows: state.reducer.scheduleUIState.selectedRows,
  selectedColumns: state.reducer.scheduleUIState.selectedColumns,
  selectedRowsLocationsIds: state.reducer.scheduleUIState.selectedRowsLocationsIds,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  searchString: state.reducer.scheduleUIState.searchString,
  workingRulesEnabled: state.reducer.schedule.viewSettings.workingRules?.value,
  uiState: state.reducer.uiState,
  employeeSorting: state.reducer.employeeSorting,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  deleteOptions: state.reducer.deleteOptions,
  locationSettings: state.reducer.settings.locationSettings,
  isVerifyWorkingRulesButtonActive: state.reducer.workingRules.isVerifyWorkingRulesButtonActive,
  shifts: state.reducer.shifts.data,
  openShifts: state.reducer.openShifts,
  userPermissions: state.reducer.userPermissions,
  scheduleSettings: state.reducer.scheduleUIState.settings,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode,
});

const mapDispatchToProps = {
  showConfirmModal,
  exportSchedule,
  deselectAllEmployeesRows,
  deleteSelected,
  copy,
  paste,
  changeEmployeesOrderForLocation,
  startCustomSorting,
  showModal,
  getWorkingRulesViolationsForCurrentDateArrayWithToastr,
  changeScheduleSearchString,
};

const ScheduleButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleButtonBar);

export default ScheduleButtonBarContainer;
