import NoLocationModal from '@/components/misc/NoLocationModal/NoLocationModal.redux';

import UnauthenticatedLayout from './UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from './UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';

const FullScreenLoader = () => (
  <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
    <NoLocationModal />
    <div className="k-spinner k-spinner--unauthenticated">
      <img src="/img/loading-spin.svg" alt="loader" />
    </div>
  </UnauthenticatedLayout>
);

export default FullScreenLoader;
