import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import { ROLE_DELETE_MODAL,ROLE_MODAL } from '@/constants/modalTypes';

import './RolesTableHeader.scss';

const RolesTableHeader = props => {
  const { roles } = props;
  if (!roles && !roles.length) return null;

  return (
    <thead>
      <tr>
        {roles.map((role, i) => {
          const showButtons = role.id !== 'employee' && role.id !== 'manager' && role.canEdit;
          const roleNameClasses = classnames('k-rolesTableHeader__roleName', {
            'k-rolesTableHeader__roleName--buttons': showButtons,
          });
          return (
            <th key={role.name + i} onFocus={() => {}}>
              <div className={roleNameClasses}>
                <span>{role.name}</span>
                {showButtons && (
                  <div>
                    <button onClick={() => props.showModal(ROLE_MODAL, role)}>
                      <i className="material-icons" style={{ marginTop: '0px' }}>
                        edit
                      </i>
                    </button>
                    <button onClick={() => props.showModal(ROLE_DELETE_MODAL, role)}>
                      <i className="material-icons" style={{ marginTop: '0px' }}>
                        delete
                      </i>
                    </button>
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

RolesTableHeader.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  showModal: PropTypes.func,
};

export default RolesTableHeader;
