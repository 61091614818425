import { connect } from 'react-redux';

import EmployeeScheduleAllTables from './EmployeeScheduleAllTables';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

export default connect(mapStateToProps)(EmployeeScheduleAllTables);
