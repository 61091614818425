import classNames from 'classnames';
import PropTypes from 'prop-types';

import MinuteHourInput from '../MinuteHourInput.jsx';
import Toggle from '../Toggle.jsx';

const ToleranceBlock = props => {
  const className = classNames('settingsRow', {
    'disabled disabled--gray': props.disabled,
  });
  const classNameLabel = classNames({ labelTitle: props.label });
  return (
    <div className={className}>
      <label>
        <div className={classNameLabel}>{props.name}</div>
        {props.label ? <div className="labelDesc">{props.label}</div> : null}
      </label>
      <Toggle onChangeFunc={props.handleToggle} value={props.toggleValue} labelId={props.toggleSlug} />
      {props.toggleValue ? (
        <div>
          <label style={{ display: 'none' }} />
          <label>{props.labelText}</label>
          <MinuteHourInput
            min={props.min}
            max={props.max}
            step={props.step}
            onChangeFunc={props.handleRange}
            value={props.rangeValue}
            labelId={props.rangeSlug}
            labelType={props.labelType}
            type={props.type}
          />
        </div>
      ) : null}
    </div>
  );
};

ToleranceBlock.propTypes = {
  name: PropTypes.shape({}),
  label: PropTypes.shape({}),
  labelText: PropTypes.shape({}),
  handleToggle: PropTypes.func,
  handleRange: PropTypes.func,
  toggleValue: PropTypes.bool,
  rangeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  toggleSlug: PropTypes.string,
  rangeSlug: PropTypes.string,
  labelType: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToleranceBlock;
