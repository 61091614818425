import { EmplMassEditActions } from '../constants.ts';
import EmployeeMassEditConfirmStep from '../EmployeeMassEditConfirmStep/EmployeeMassEditConfirmStep.tsx';
import EmployeesMassEditStep from '../EmployeesMassEditStep/EmployeesMassEditStep.tsx';
import EmployeesMassEditSummaryStep from '../EmployeesMassEditSummaryStep/EmployeesMassEditSummaryStep.redux';

interface EditMassEmployeesModalStepSelectorProps {
  currentStep: number;
  selectedType: string;
  handleTypeChange: (type: string) => void;
  selectedAction: EmplMassEditActions;
  setSelectedAction: (actionType: string) => void;
  currentFieldValue: any;
  setFieldValue: (actionType: string) => void;
}

const EditMassEmployeesModalStepSelector = ({
  currentStep,
  selectedType,
  handleTypeChange,
  selectedAction,
  setSelectedAction,
  currentFieldValue,
  setFieldValue,
}: EditMassEmployeesModalStepSelectorProps) => {
  switch (currentStep) {
    case 1:
      return (
        <EmployeesMassEditStep
          selectedType={selectedType}
          handleTypeChange={handleTypeChange}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          currentFieldValue={currentFieldValue}
          setFieldValue={setFieldValue}
        />
      );
    case 2:
      return (
        <EmployeesMassEditSummaryStep
          fieldValue={currentFieldValue}
          onChange={setFieldValue}
          selectedAction={selectedAction}
        />
      );
    case 3:
      return <EmployeeMassEditConfirmStep />;

    default:
      return null;
  }
};

export default EditMassEmployeesModalStepSelector;
