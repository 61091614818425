import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import ExportsCatalogView from './ExportsCatalogView';

const mapStateToProps = state => ({
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
  permissions: state.reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportsCatalogView);
