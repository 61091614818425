import cn from 'classnames';
import PropTypes from 'prop-types';

import { isWeekend } from '@/utils/dateHelper.js';

import './ScheduleTableItemBackground.scss';

const ScheduleTableItemBackground = ({
  dayType,
  selectedDisplayModeType,
  holidaysEnabled,
  availabilitiesModeEnabled,
  date,
  currentEmployeeRow,
  isAvaDragged,
}) => {
  const showHolidays = selectedDisplayModeType !== 'templates' && holidaysEnabled;
  const classname = cn('k-scheduleTableItemBackground', {
    'k-scheduleTableItemBackground--holiday': showHolidays && dayType === 'holiday',
    'k-scheduleTableItemBackground--freeDayHoliday': showHolidays && dayType === 'freeFromWorkHoliday',
    'k-scheduleTableItemBackground--weekend': isWeekend(date) && !(showHolidays && dayType !== 'workDay'),
    'k-scheduleTableItemBackground--coverShiftsLayer': availabilitiesModeEnabled,
    'k-scheduleTableItemBackground--currentEmployeeRow': currentEmployeeRow,
    'k-scheduleTableItemBackground--addWhenDragged': isAvaDragged,
  });
  return <div className={classname} />;
};

ScheduleTableItemBackground.propTypes = {
  dayType: PropTypes.string,
  selectedDisplayModeType: PropTypes.string,
  holidaysEnabled: PropTypes.bool,
  date: PropTypes.string,
  currentEmployeeRow: PropTypes.bool,
  availabilitiesModeEnabled: PropTypes.bool,
  isAvaDragged: PropTypes.bool,
};

export default ScheduleTableItemBackground;
