import * as AT from '@/constants/ActionTypes.js';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_COMPANY_MILESTONES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
