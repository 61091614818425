import { connect } from 'react-redux';

import { setAccountAsFremium } from '@/actions/freemium/freemium.js';

import SelectPackageContent from './SelectPackageContent';

const mapDispatchToProps = {
  setAccountAsFremium,
};

export default connect(null, mapDispatchToProps)(SelectPackageContent);
