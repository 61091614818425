export const absenceStatuses = {
  accepted: 'accepted',
  rejected: 'rejected',
  draft: 'draft',
  canceled: 'canceled',
};

export const NOT_COUNTED_ABSENCE_STATUSES = [absenceStatuses.canceled, absenceStatuses.rejected];

export const absenceDetailTitles = {
  absenceNrMessageId: 'absenceNrMessageId',
  absenceTypeMessageId: 'absenceTypeMessageId',
  periodMessageId: 'periodMessageId',
  createdMessageId: 'createdMessageId',
  periodLengthId: 'periodLengthId',
};

export const OVERTIME_COLLECTION = 'overtime_collection';

export const OVERTIME_COLLECTION_ID = 'overtimeCollectionFakeTypeId';
export const OVERTIME_LABEL = 'ON';
