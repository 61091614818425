import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes.js';
import { AVAILABILITIES_DISPLAY_MODE_TYPE, SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';

import PersonOffIconWithTooltip from '../PersonOffIconWithTooltup/PersonOffIconWithTooltip';
import ScheduleItemSelectMarker from '../ScheduleItemSelectMarker/ScheduleItemSelectMarker.redux';
import ScheduleQuickPlanningItem from '../ScheduleQuickPlanningItem/ScheduleQuickPlanningItem.redux';
import { ScheduleQuickPlanningNewFreeDay } from '../ScheduleQuickPlanningNewFreeDay/ScheduleQuickPlanningNewFreeDay';
import ScheduleQuickPlanningNewShift from '../ScheduleQuickPlanningNewShift/ScheduleQuickPlanningNewShift.redux';
import ScheduleTableDropArea from '../ScheduleTableDropArea/ScheduleTableDropArea.redux';
import ScheduleTableItemBackground from '../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';

import './ScheduleTableItem.scss';

const ScheduleTableItem = ({
  date,
  employee,
  location,
  showModal,
  mainDateStore,
  paddingToAdd,
  children,
  isQuickPlanningEnabled,
  isEditDisabled,
  handleMouseDownItem,
  handleMouseUpItem,
  handleMouseOverItem,
  size,
  isEmpty,
  viewMode,
  canDeselect,
  isDateWithinEmploymentPeriod,
  isLoanedEmployee,
  initialJobTitleId,
}) => {
  const isShowModalDisabled = viewMode === AVAILABILITIES_DISPLAY_MODE_TYPE && canDeselect;

  const tableItemClassName = classnames('scheduleTable__item', {
    empty: isEmpty,
    today: date === mainDateStore.today,
    'scheduleTable__item--editDisabled':
      isEditDisabled || isShowModalDisabled || !isDateWithinEmploymentPeriod || isLoanedEmployee,
  });

  const addIconClassName = classnames(
    'material-icons-outlined scheduleTable__item__add',
    paddingToAdd && `scheduleTable__item__add--${paddingToAdd}`,
  );

  const personOffIconClassName = classnames(addIconClassName, 'scheduleTable__item__personOff');

  const blockEdit = isEditDisabled || isQuickPlanningEnabled || isLoanedEmployee;

  const handleOnTableItemClick = () => {
    if (!isEmpty || blockEdit || isShowModalDisabled || !isDateWithinEmploymentPeriod) return;
    showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
      employee,
      date,
      location,
      initialJobTitleId,
    });
  };

  const onMouseDown = () => {
    if (!isQuickPlanningEnabled) return;
    handleMouseDownItem(employee.id, date);
  };

  const onMouseUp = e => {
    if (!isQuickPlanningEnabled) return;
    handleMouseUpItem(employee.id, date, e);
  };

  const onMouseOver = () => {
    if (!isQuickPlanningEnabled) return;
    handleMouseOverItem(employee.id, date);
  };
  const showPersonOffIconForScheduleView =
    (!isDateWithinEmploymentPeriod || isLoanedEmployee) && viewMode === SCHEDULE_DISPLAY_MODE_TYPE;
  const showPersonOffIconForQuickPlanningMode = !isDateWithinEmploymentPeriod && isEmpty;

  return (
    <td
      className={tableItemClassName}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onClick={handleOnTableItemClick}
      onMouseOver={onMouseOver}
      role="presentation"
    >
      <ScheduleTableItemBackground date={date} />
      {!isLoanedEmployee && <ScheduleTableDropArea date={date} employeeId={employee.id} />}
      <ScheduleItemSelectMarker date={date} employeeId={employee.id} locationId={location.id} />
      {!isLoanedEmployee && (
        <ScheduleQuickPlanningItem
          date={date}
          employeeId={employee.id}
          locationId={location.id}
          showIcon={showPersonOffIconForQuickPlanningMode}
        />
      )}
      {(showPersonOffIconForScheduleView && (
        <PersonOffIconWithTooltip
          isLoanedEmployee={isLoanedEmployee}
          isQuickPlanningEnabled={isQuickPlanningEnabled}
          personOffIconClassName={personOffIconClassName}
        />
      )) ||
        (!blockEdit && <span className={addIconClassName}>add</span>)}
      {children}
      {!isLoanedEmployee && (
        <ScheduleQuickPlanningNewShift
          date={date}
          employeeId={employee.id}
          size={size}
          isEditEnabled={isDateWithinEmploymentPeriod}
        />
      )}
      <ScheduleQuickPlanningNewFreeDay date={date} employeeId={employee.id} />
    </td>
  );
};

ScheduleTableItem.propTypes = {
  date: PropTypes.string,
  isEmpty: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.string,
    employment_conditions: PropTypes.shape({ hire_date: PropTypes.string, release_date: PropTypes.string }),
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  showModal: PropTypes.func,
  mainDateStore: PropTypes.shape({
    today: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedDisplayMode: PropTypes.shape({
    type: PropTypes.string,
  }),
  paddingToAdd: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isQuickPlanningEnabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  handleMouseDownItem: PropTypes.func,
  handleMouseUpItem: PropTypes.func,
  handleMouseOverItem: PropTypes.func,
  size: PropTypes.string,
  employeeShifts: PropTypes.shape({}),
  viewMode: PropTypes.string,
  canDeselect: PropTypes.bool,
  isDateWithinEmploymentPeriod: PropTypes.bool,
  isLoanedEmployee: PropTypes.bool,
  initialJobTitleId: PropTypes.string,
};

export default ScheduleTableItem;
