import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { getContrastYIQ } from '@/utils/colorHelpers.js';

class PositionScheduleShift extends PureComponent {
  constructor(props) {
    super(props);
    this.dragStart = this.dragStart.bind(this);
  }

  dragStart(e) {
    if (this.props.editingScheduleDisabled) return;
    e.dataTransfer.setData('shift', JSON.stringify(this.props.shift));
    e.dataTransfer.setData(`location:${this.props.location.id}`, ' ');
    e.dataTransfer.setData(`employee:${this.props.shift.employee.id}`, ' ');
    e.dataTransfer.setData(this.props.shift.date, ' ');
    const img = document.createElement('div');
    img.className = 'dragImage';
    img.innerHTML += '<i class="material-icons">insert_drive_file</i>';
    document.body.appendChild(img);

    e.dataTransfer.setDragImage(img, 0, 0);
  }

  render() {
    const {
      jobTitleNotAssigned,
      jobTitleDeleted,
      className,
      shift,
      otherLocation,
      blocked,
      employee_name: employeeName,
      location,
      handleEditAction,
      title,
      handleDeleteAction,
      handleAddAction,
    } = this.props;

    const invalidJobTitle = jobTitleNotAssigned || jobTitleDeleted;
    const shiftClass = classnames('sch_block', className, {
      draft_block: shift.draft,
      otherLocation,
      invalid_block: invalidJobTitle,
      blocked,
      sch_block_no_hover: this.props.editingScheduleDisabled,
    });
    const color = shift.job_title.color || '#F00';
    const validComment = shift.comment && shift.comment.length > 0;
    const hideColor = otherLocation || shift.invalidJobTitle;

    const shiftStyle = { backgroundColor: hideColor ? 'none' : color };
    const showOverlay = otherLocation || validComment || invalidJobTitle;
    const popover = { title: `${employeeName} ${shift.date}`, content: [] };
    let locationName;
    if (typeof location === 'undefined') {
      locationName = (
        <FormattedMessage
          id="employeeSchedule.shiftBlock.locationUndefined"
          defaultMessage="nie przypisanej do niego"
        />
      );
    } else {
      locationName =
        location && location.name ? (
          location.name
        ) : (
          <FormattedMessage id="employeeSchedule.shiftBlock.locationRemoved" defaultMessage="która została usunięta" />
        );
    }

    if (jobTitleDeleted) {
      popover.content.push(
        <div key="deletedJobTitle">
          <FormattedMessage
            id="positionSchedule.employeeHasShift"
            defaultMessage="Pracownik ma utworzoną zmianę na stanowisku"
          />{' '}
          <strong>{shift.job_title.title}</strong>
          <FormattedMessage id="positionSchedule.jobTitleRemoved" defaultMessage=", które zostało usunięte." />
        </div>,
      );
    } else if (jobTitleNotAssigned) {
      popover.content.push(
        <div key="invalidJobTitle">
          <FormattedMessage
            id="employeeSchedule.shiftBlock.invalidJobTitle"
            defaultMessage="Pracownik ma utworzoną zmianę na stanowisku {jobTitle} do którego nie został przypisany"
            values={{ jobTitle: shift.job_title.title }}
          />
        </div>,
      );
    }

    if (otherLocation) {
      popover.content.push(
        <div key="otherLocationComment">
          <FormattedMessage
            id="employeeSchedule.positionShiftBlock.otherLocation"
            defaultMessage="Ten pracownik ma zmiane w lokalizacji {locationName}"
            values={{ locationName }}
          />
        </div>,
      );
    }
    if (validComment) {
      popover.content.push(
        <div key="comment">
          <FormattedMessage
            id="positionSchedule.commentToShift"
            defaultMessage="Komentarz do zmiany {shift}"
            values={{
              shift: <b>{this.props.shift.comment}</b>,
            }}
          />
        </div>,
      );
    }

    const contrastColor = otherLocation ? 'black' : getContrastYIQ(color);

    // I have no idea why but even if shiftClass has background-color:none it still doesnt update color
    const renderedShift = (
      <div
        draggable={!this.props.editingScheduleDisabled}
        onDragStart={this.dragStart}
        onClick={!otherLocation && !this.props.editingScheduleDisabled ? handleEditAction : () => {}}
        style={otherLocation || invalidJobTitle ? {} : shiftStyle}
        className={shiftClass}
        role="presentation"
      >
        {otherLocation ? <i className="material-icons lock-block">lock</i> : null}
        {validComment ? <i className="material-icons hascomment">message</i> : null}

        <div className="sch_block__info">
          <div className="time">{shift.working_hours}</div>
          <div className="person">{title}</div>
        </div>
        {!otherLocation && (
          <>
            <i className={classnames('sch_block__editIcon', 'material-icons', `sch_block__editIcon--${contrastColor}`)}>
              edit
            </i>
            <i
              onClick={handleDeleteAction}
              className={classnames('material-icons', 'del-block', `del-block--${contrastColor}`)}
              role="presentation"
            >
              delete
            </i>
          </>
        )}
        <i
          onClick={handleAddAction}
          className={classnames('material-icons', 'add-block', `add-block--${contrastColor}`)}
          role="presentation"
        >
          add
        </i>
      </div>
    );
    return (
      <div>
        {showOverlay ? (
          <HoverPopOver name="shiftHover" popover={popover}>
            {renderedShift}
          </HoverPopOver>
        ) : (
          renderedShift
        )}
      </div>
    );
  }
}

PositionScheduleShift.propTypes = {
  otherLocation: PropTypes.bool,
  blocked: PropTypes.bool,
  employee_name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  shift: PropTypes.shape({
    draft: PropTypes.bool,
    invalidJobTitle: PropTypes.bool,
    job_title: PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
    }),
    employee: PropTypes.shape({
      id: PropTypes.string,
    }),
    comment: PropTypes.string,
    date: PropTypes.string,
    working_hours: PropTypes.string,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  handleAddAction: PropTypes.func,
  handleEditAction: PropTypes.func,
  handleDeleteAction: PropTypes.func,
  jobTitleNotAssigned: PropTypes.bool,
  jobTitleDeleted: PropTypes.bool,
  editingScheduleDisabled: PropTypes.bool,
};

export default PositionScheduleShift;
