import {
  Area,
  AreaChart as RechartAreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

type AreaSeries = {
  dataKey: Area['props']['dataKey'];
  dot: Area['props']['dot'];
  fill: Area['props']['fill'];
  id: string;
  label: Area['props']['label'];
  stroke: Area['props']['stroke'];
  type: Area['props']['type'];
};

export type AreaChartProps = {
  data: CategoricalChartProps['data'];
  height?: number;
  series: AreaSeries[];
  xKey: string;
  xAxisAngle?: number;
};

export const AreaChart = ({ data, height = 300, series, xAxisAngle = 0, xKey }: AreaChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <RechartAreaChart width={500} height={height} data={data}>
        <CartesianGrid strokeDasharray="0" opacity={0.3} />
        <XAxis
          dataKey={xKey}
          textAnchor={xAxisAngle ? 'end' : 'middle'}
          angle={xAxisAngle}
          style={{ fontSize: '12px' }}
          height={xAxisAngle ? 60 : 30}
        />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '10px' }} />
        {series.map((s, index) => (
          <Area key={index} {...s} />
        ))}
      </RechartAreaChart>
    </ResponsiveContainer>
  );
};
