import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import {
  emplMassEditActions,
  emplMassEditTypeActions,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { SUPPLEMENTARY_LOCATIONS_VIEW } from '@/constants/Permissions.js';

const messages = defineMessages({
  [emplMassEditActions.addNewItem]: { id: 'employees.editMass.addNewItem', defaultMessage: 'Dodaj nową' },
  [emplMassEditActions.addNewItems]: { id: 'employees.editMass.addNewItems', defaultMessage: 'Dodaj nowe' },
  [emplMassEditActions.delete]: {
    id: 'employees.editMass.delete',
    defaultMessage: 'Usuń z przypisanych',
  },
  [emplMassEditActions.addNewSupplementary]: {
    id: 'employees.editMass.addNewSupplementary',
    defaultMessage: 'Dodaj nową pomocniczą',
  },
  [emplMassEditActions.edit]: { id: 'employees.editMass.edit', defaultMessage: 'Edytuj' },
  [emplMassEditActions.addContract]: { id: 'employees.editMass.addContract', defaultMessage: 'Dodaj nowy' },
  [emplMassEditActions.remove]: { id: 'employees.editMass.remove', defaultMessage: 'Usuń' },
  [emplMassEditActions.addContractsJobTitles]: {
    id: 'employees.editMass.addContractsJobTitles',
    defaultMessage: 'Edytuj (dodawanie stanowisk)',
  },
  [emplMassEditActions.removeContractsJobTitles]: {
    id: 'employees.editMass.removeContractsJobTitles',
    defaultMessage: 'Edytuj (usuwanie stanowisk)',
  },
  [emplMassEditActions.editContractsStartDate]: {
    id: 'employees.editMass.editContractsStartDate',
    defaultMessage: 'Edytuj (zmiana daty rozpoczęcia)',
  },
});

const EmployeesEditMassActionSelect = ({ userPermissions, selectedAction, selectedType, onChange }, { intl }) => {
  const options = Object.keys(emplMassEditTypeActions[selectedType])?.reduce((acc, cur) => {
    if (cur === emplMassEditActions.addNewSupplementary && !userPermissions.includes(SUPPLEMENTARY_LOCATIONS_VIEW)) {
      return acc;
    }

    const option = {
      key: intl.formatMessage(messages[cur]),
      value: cur,
    };
    return [...acc, option];
  }, []);

  const defaultValue = options.find(opt => opt.value === selectedAction)?.value || options[0]?.value;

  useEffect(() => {
    onChange(defaultValue);
  }, [selectedType]);

  return (
    <MDSelect
      id="actionType"
      name={<FormattedMessage id="employees.editMass.actionsTitle" defaultMessage="Rodzaj akcji" />}
      options={options}
      defaultValue={defaultValue}
      onChange={value => {
        onChange(value);
      }}
      disabled={!selectedType}
      closeOnClick
    />
  );
};

EmployeesEditMassActionSelect.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeesEditMassActionSelect.propTypes = {
  userPermissions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  selectedAction: PropTypes.string,
  selectedType: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmployeesEditMassActionSelect;
