import { connect } from 'react-redux';

import { deleteDeviceConfirm } from '@/actions/companymanage/devices.jsx';
import { showModal } from '@/actions/uiState.js';
import { refreshDeviceData } from '@/actions/rcpSockets';

import DevicesTableRow from './DevicesTableRow.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  showModal,
  deleteDeviceConfirm,
  refreshDeviceData,
};

const DevicesTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(DevicesTableRow);

export default DevicesTableRowContainer;
