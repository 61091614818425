import AttendancesWeekView from '@/components/attendance/AttendancesWeekView/AttendancesWeekView.redux.js';
import AttendanceTableViewContainer from '@/components/attendance/AttendanceTableView.redux.js';
import { useAppSelector } from '@/redux-store';
import { selectDateArrayLength } from '@/selectors/mainDateStore.selectors';
import { selectPermissionsIsEmployee } from '@/selectors/userPermissions.selectors';

import TopBarAttendance from '../TopBars/TopBarAttendance';

const AttendanceView = () => {
  const isEmployee = useAppSelector(selectPermissionsIsEmployee);
  const dateArrayLength = useAppSelector(selectDateArrayLength);
  const isSingleDay = dateArrayLength === 1;

  return (
    <>
      <TopBarAttendance isSingleDay={isSingleDay} isEmployee={isEmployee} />
      {isSingleDay ? <AttendanceTableViewContainer /> : <AttendancesWeekView />}
    </>
  );
};

export default AttendanceView;
