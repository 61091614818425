import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import { MDCheckbox, MDMultiSelectHeader } from '../MDComponents.jsx';

import './MDCheckboxList.scss';

class MDCheckboxList extends Component {
  constructor(props) {
    super(props);
    const selectedOptions = props.preselected ? [...props.preselected] : [];
    this.state = {
      selectedOptions,
    };
    bindPrototypeFunctions(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.preselected !== this.props.preselected) {
      this.setState({ selectedOptions: nextProps.preselected });
    }
  }

  getSelected() {
    return this.state.selectedOptions;
  }

  toggleCheckbox(i) {
    const newSelectedOptions = this.isOptionSelected(i)
      ? this.state.selectedOptions.filter(o => o !== i)
      : [...this.state.selectedOptions, i];
    this.setState({ selectedOptions: newSelectedOptions });
  }

  selectAll() {
    const allOptions = this.props.options.map((option, i) => i);
    this.setState({ selectedOptions: allOptions });
  }

  deselectAll() {
    this.setState({ selectedOptions: [] });
  }

  isOptionSelected(id) {
    return this.state.selectedOptions.includes(id);
  }

  render() {
    return (
      <>
        <MDMultiSelectHeader
          title={
            this.props.title.defaultMessage ? (
              <FormattedMessage {...this.props.title} />
            ) : (
              <FormattedMessage id="export.chooseColumns" defaultMessage="Wybierz kolumny" />
            )
          }
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
        />
        <OverlayScrollbarsComponent className="k-overlay-scrollbar mdCheckboxList__box">
          {this.props.options.map((option, i) =>
            this.props.hidden && this.props.hidden.includes(i) ? null : (
              <MDCheckbox
                key={option + i}
                id={option}
                text={option}
                value={this.isOptionSelected(i)}
                onChange={() => {
                  this.toggleCheckbox(i);
                }}
                reverse
              />
            ),
          )}
        </OverlayScrollbarsComponent>
      </>
    );
  }
}

MDCheckboxList.propTypes = {
  preselected: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  hidden: PropTypes.arrayOf(PropTypes.number),
};
export default MDCheckboxList;
