import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ImportBox from '@/components/common/ImportBox.jsx';
import FileDrop from '@/components/common/inputs/FileDrop.jsx';

class ImportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 0, // 0 - no file, 1 - importing, 2 - import complete
      messages: null, // messages form import process
    };
    this.importData = this.importData.bind(this);
  }

  importData(file) {
    this.setState({ stage: 1 });
    this.props.importFromFile(file).then((messages) => {
      this.setState({ stage: 2, messages });
    });
  }

  render() {
    return (
      <div className="k-wrapper animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div className="k-panel panel-nopad panel-setting">
              <h2 className="k-panel__title">
                <FormattedMessage id="settings.import.import" defaultMessage="Import" />
              </h2>
              <fieldset className="form-group last" />

              <div className="importStoryContainer">
                <div className="step">
                  <span className="stepNumber">1</span>
                  <i className="material-icons">file_download</i>
                  <a href="/templates/szablon_importu.xls" download>
                    <Button onClick={() => {}} modifiers="blue small">
                      <FormattedMessage id="settings.import.downloadTemplate" defaultMessage="Pobierz szablon" />
                    </Button>
                  </a>
                </div>
                <div className="arrow">
                  <i className="material-icons">arrow_forward</i>
                </div>
                <div className="step">
                  <span className="stepNumber">2</span>
                  <i className="material-icons">border_color</i>
                  <Button onClick={() => {}} modifiers="blue small" disabled>
                    <FormattedMessage id="settings.import.changeData" defaultMessage="Wprowadź potrzebne dane" />
                  </Button>
                </div>
                <div className="arrow">
                  <i className="material-icons">arrow_forward</i>
                </div>
                <div className="step">
                  <span className="stepNumber">3</span>
                  <i className="material-icons">add_to_queue</i>
                  <Button onClick={() => {}} modifiers="blue small" disabled>
                    <FormattedMessage id="settings.import.addDataToAccount" defaultMessage="Dodaj dane do konta" />
                  </Button>
                </div>
              </div>
              {this.state.stage === 0 ? (
                <FileDrop
                  confirmText={<FormattedMessage id="import" defaultMessage="Importuj" />}
                  onChange={this.importData}
                  accept={['xls', 'xlsx']}
                />
              ) : null}
              {this.state.stage > 0 ? <ImportBox messages={this.state.messages} /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImportView.propTypes = {
  importFromFile: PropTypes.func,
};

export default ImportView;
