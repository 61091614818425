import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { MDCheckbox } from '@/components/common/inputs/MDComponents';
import MDInputTooltip from '@/components/common/inputs/MDInputTooltip/MDInputTooltip';
import {
  EmplMassEditActions,
  emplMassEditActions,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { checkIfAllSelectedEmployeesHaveOnlyOneContract } from '@/components/companymanage/employees/EditMassEmployeesModal/employeesEditMassHelpers.ts';
import { Contract } from '@/types/contracts.types.ts';
import { UserJobTitle } from '@/types/jobTitles.types.ts';

import EmployeeModalContractJobTitles from '../../../EmployeeModal/EmployeeModalContracts/EmployeeModalContractJobTitles/EmployeeModalContractJobTitles.jsx';
import DatePickerField from '../../DatePickerField/DatePickerField';
import { FieldValue } from '../../EmployeesEditMassValueField.helpers.tsx';
import { messages } from './employeesEditMassContractsField.messages.ts';

import './employeesEditMassContractsField.scss';

export interface EmployeesEditMassContractsFieldProps {
  fieldValue: FieldValue;
  onChange: (value: FieldValue) => void;
  userJobTitles: UserJobTitle[];
  selectedAction?: EmplMassEditActions;
  selectedEmployeesIds: string[];
  contracts: { [employeeId: string]: Contract[] };
}

const EmployeesEditMassContractsField = (
  {
    fieldValue,
    onChange,
    userJobTitles,
    selectedAction,
    selectedEmployeesIds,
    contracts,
  }: EmployeesEditMassContractsFieldProps,
  { intl },
) => {
  const allSelectedEmployeesHaveOnlyOneContract = useMemo(
    () =>
      selectedAction !== emplMassEditActions.editContractsStartDate
        ? false
        : checkIfAllSelectedEmployeesHaveOnlyOneContract(selectedEmployeesIds, contracts),
    [selectedEmployeesIds, contracts, selectedAction],
  );
  const handleToggle = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    onChange({ ...fieldValue, assignJobTitlesAndWageForContract: value });
  };

  const datePickerLabel = intl.formatMessage(
    selectedAction === emplMassEditActions.addContract ? messages.startContractDate : messages.relatedContractDate,
  );

  const datePickerTooltip =
    selectedAction !== emplMassEditActions.addContract ? intl.formatMessage(messages.effectiveDateOfContract) : null;

  return (
    <div className="k-employeesEditMassAddContractField">
      <DatePickerField
        id="date"
        onChange={onChange}
        fieldValues={fieldValue}
        label={datePickerLabel}
        tooltip={datePickerTooltip}
      />
      {selectedAction === emplMassEditActions.addContract && (
        <div className="k-employeesEditMassAddContractField__checkboxWrapper">
          <MDCheckbox
            id="assignJobTitlesAndWageForContract"
            value={fieldValue?.assignJobTitlesAndWageForContract}
            onChange={handleToggle}
            reverse
          />
          <span className="k-employeesEditMassAddContractField__checkboxDescription">
            {intl.formatMessage(messages.checkboxText)}
          </span>
          <MDInputTooltip content={intl.formatMessage(messages.checkboxTooltipText)} />
        </div>
      )}
      {allSelectedEmployeesHaveOnlyOneContract && fieldValue?.date && (
        <div className="k-employeesEditMassAddContractField__selectedEmployeesHaveOnlyOneContract">
          {intl.formatMessage(messages.changeStartContractDateIsUnavailableForAllSelectedEmployees)}
        </div>
      )}
      {selectedAction === emplMassEditActions.editContractsStartDate ? (
        <DatePickerField
          id="newStartContractDate"
          onChange={onChange}
          fieldValues={fieldValue}
          label={intl.formatMessage(messages.newStartContractDate)}
        />
      ) : (
        <EmployeeModalContractJobTitles
          contractJobTitles={fieldValue?.jobTitles || []}
          allJobTitles={userJobTitles}
          updateContractJobTitles={(jobTitles: { job_title_id: string; wage?: string }[]) =>
            onChange({ ...fieldValue, jobTitles })
          }
          hasAccessToWage
          disabled={Boolean(fieldValue?.assignJobTitlesAndWageForContract)}
        />
      )}
    </div>
  );
};

EmployeesEditMassContractsField.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeesEditMassContractsField;
