import { conn } from '@/actions';
import { EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';

import { createActionName, createAppAsyncThunk } from '../utils';
import { sliceName } from './freeDaysMarking.slice';
import { FreeDayItemDto, FreeDayItemPostDto, FreeDaysMarkingStoreState } from './freeDaysMarking.types';
import { freeDaysItemsToStore } from './freeDaysMarking.utils';

export const getFreeDaysMarkings = createAppAsyncThunk(
  createActionName(sliceName, 'getFreeDaysMarkings'),
  async ({
    from,
    to,
    employeesIds,
  }: {
    from: StandardDate;
    to: StandardDate;
    employeesIds: EmployeeWhole['id'][];
  }): Promise<FreeDaysMarkingStoreState> => {
    const response = await conn.getFreeDaysMarkings(from, to, employeesIds);
    const freeDays = response.data.items as FreeDayItemDto[];
    return freeDaysItemsToStore(freeDays);
  },
);

export const createFreeDayMarking = createAppAsyncThunk(
  createActionName(sliceName, 'createFreeDayMarking'),
  async (freeDay: FreeDayItemPostDto) => {
    const response = await conn.createFreeDayMarkings([freeDay]);
    return response.data.items[0] as FreeDayItemDto;
  },
);

export const createFreeDayMarkings = createAppAsyncThunk(
  createActionName(sliceName, 'createFreeDayMarkings'),
  async (freeDays: FreeDayItemPostDto[]) => {
    const response = await conn.createFreeDayMarkings(freeDays);
    return response.data.items as FreeDayItemDto[];
  },
);

export const updateFreeDayMarking = createAppAsyncThunk(
  createActionName(sliceName, 'updateFreeDayMarking'),
  async (freeDay: FreeDayItemDto) => {
    const response = await conn.updateFreeDayMarkings([freeDay]);
    return response.data.items[0] as FreeDayItemDto;
  },
);

export const updateFreeDayMarkings = createAppAsyncThunk(
  createActionName(sliceName, 'updateFreeDayMarkings'),
  async (freeDays: FreeDayItemDto[]) => {
    const response = await conn.updateFreeDayMarkings(freeDays);
    return response.data.items as FreeDayItemDto[];
  },
);

export const deleteFreeDayMarking = createAppAsyncThunk(
  createActionName(sliceName, 'deleteFreeDayMarking'),
  async (freeDayItemId: FreeDayItemDto['id']) => {
    const response = await conn.deleteFreeDayMarkings([freeDayItemId]);
    return response.data.ids as FreeDayItemDto['id'][];
  },
);

export const deleteFreeDayMarkings = createAppAsyncThunk(
  createActionName(sliceName, 'deleteFreeDayMarkings'),
  async (freeDayItemIds: FreeDayItemDto['id'][]) => {
    const response = await conn.deleteFreeDayMarkings(freeDayItemIds);
    return response.data.ids as FreeDayItemDto['id'][];
  },
);
