import moment from 'moment';
import PropTypes from 'prop-types';

import CalendarMonthHeader from './CalendarMonthHeader';
import CalendarWeeks from './CalendarWeeks';

const CalendarMonth = ({ monthDate, data, customDayRender }) => (
  <div className="yearCalendar__month">
    <div className="yearCalendar__monthName">{moment(monthDate).format('MMMM')}</div>
    <table className="yearCalendar__monthTable">
      <CalendarMonthHeader />
      <CalendarWeeks data={data} monthDate={monthDate} customDayRender={customDayRender} />
    </table>
  </div>
);

CalendarMonth.propTypes = {
  monthDate: PropTypes.shape({}),
  data: PropTypes.shape({}),
  customDayRender: PropTypes.func,
};

export default CalendarMonth;
