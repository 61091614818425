import { connect } from 'react-redux';

import { changeJobTitleFilter, changeJobTitleFilterGrouped } from '@/actions/jobtitleFilter.js';
import TopBarJobTitleFilter from '@/components/TopBars/filters/TopBarJobTitleFilter/TopBarJobTitleFilter.jsx';

const mapStateToProps = state => ({
  jobtitleFilter: state.reducer.jobtitleFilter,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userJobTitles: state.reducer.userJobTitles,
  userJobTitlesGrouped: state.reducer.jobTitlesGrouped,
  areFiltersInUrl: state.reducer.uiState.areFiltersInUrl,
});

const mapDispatchToProps = {
  changeJobTitleFilter,
  changeJobTitleFilterGrouped,
};

const TopBarJobTitleFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarJobTitleFilter);

export default TopBarJobTitleFilterContainer;
