import * as AT from '@/constants/ActionTypes.js';

const initialState = [];

const scheduleLocationFilter = (state = initialState, action) => {
  switch (action.type) {
    case AT.CHANGE_SCHEDULE_LOCATION_FILTER:
      return action.payload.map(loc => (loc.id ? loc.id : loc));
    case AT.CLEAR_DEMO_DATA:
      return initialState;
    case AT.ADD_NEW_LOCATION:
      return [...state, action.payload.id];
    case AT.DELETE_LOCATION:
      return state.filter(id => id !== action.payload);
    case AT.DELETE_LOCATION_SET:
      return state.filter(id => !action.payload.includes(id));
    default:
      return state;
  }
};

export default scheduleLocationFilter;
