import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import EmptyState from '@/components/emptyState/EmptyState';
import { PURPLE_600 } from '@/constants/colors';
import { CHART_MODIFIER } from '@/constants/modifiers';
import { ABSENCES_CHART_MODES, MAX_ELEMENT_IN_CHART_LEGEND, MINUTES } from '@/constants/reports';
import { ContextWithIntl } from '@/types/intl.types';

import ChartHeader from '../../chartHeader/ChartHeader';
import { messages as chartsMessages } from '../charts.messages';
import { prepareDataForAbsencesChart } from './absencesChart.helpers';
import { messages } from './absencesChart.messages';
import AbsencesChartShape from './AbsencesChartShape/AbsencesChartShape';
import { useAbsencesChart } from './useAbsencesChart';

import './absencesChart.scss';

const INNER_RADIUS = '40%';
const OUTER_RADIUS = '60%';
const CX = '50%';
const CY = '50%';

const AbsencesChart = (_, { intl }: ContextWithIntl) => {
  const [chartMode, setChartMode] = useState(MINUTES);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, absencesTypes } = useAbsencesChart();

  const preparedData = useMemo(
    () =>
      prepareDataForAbsencesChart(data, absencesTypes, chartMode, MAX_ELEMENT_IN_CHART_LEGEND, {
        other: intl.formatMessage(messages.otherJobTitles),
      }),
    [data, absencesTypes, chartMode, intl],
  );
  const hasDataToShow = preparedData.length;
  const headerClassNames = classNames({
    'k-absencesChart__header': hasDataToShow,
  });
  return (
    <div className="k-absencesChart">
      <ChartHeader
        title={intl.formatMessage(messages.chartTitle)}
        chartMode={chartMode}
        onChange={setChartMode}
        options={ABSENCES_CHART_MODES}
        tooltipMessage={intl.formatMessage(messages.tooltipMessage)}
        className={headerClassNames}
      />
      {hasDataToShow ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={AbsencesChartShape}
              data={preparedData}
              cx={CX}
              cy={CY}
              innerRadius={INNER_RADIUS}
              outerRadius={OUTER_RADIUS}
              fill={PURPLE_600}
              dataKey="value"
              onMouseEnter={(_, index) => setActiveIndex(index)}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <EmptyState
          name="scheduleCTA"
          imgSrc="/img/scheduleCTA.png"
          emptyStateHeader={intl.formatMessage(chartsMessages.emptyStateHeader)}
          emptyStateText={intl.formatMessage(chartsMessages.emptyStateText)}
          modifiers={[CHART_MODIFIER]}
        />
      )}
    </div>
  );
};

AbsencesChart.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AbsencesChart;
