import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  estimatedBudget: {
    id: 'loadBudgetTargetModal.estimatedBudget',
    defaultMessage: 'Sprzedaż planowana',
  },
  spmhTarget: {
    id: 'loadBudgetTargetModal.spmhTarget',
    defaultMessage: 'SPMH Target',
  },
  colTarget: {
    id: 'loadBudgetTargetModal.colTarget',
    defaultMessage: '%COL Target',
  },
  spmhBudget: {
    id: 'loadBudgetTargetModal.targetSpmh',
    defaultMessage: 'Budżet godzin pracy',
  },
  colBudget: {
    id: 'loadBudgetTargetModal.labourLimit',
    defaultMessage: 'Budżet kosztów pracy',
  },
  currency: {
    id: 'loadBudgetTargetModal.currency',
    defaultMessage: 'PLN',
  },
  estimatedBudgetMustBePositive: {
    id: 'loadBudgetTargetModal.estimatedBudgetMustBePositive',
    defaultMessage: 'Sprzedaż planowana musi mieć wartość większą niż 0.',
  },
  spmhBudgetTooltip: {
    id: 'loadBudgetTargetModal.spmhBudget.tooltip',
    defaultMessage: 'Budżet godzin pracy = Sprzedaż planowana / SPMH Target',
  },
  colBudgetTooltip: {
    id: 'loadBudgetTargetModal.colBudget.tooltip',
    defaultMessage: 'Budżet kosztów pracy = Sprzedaż planowana * %COL Target',
  },
  editBudgetTargetDisabled: {
    id: 'loadBudgetTargetModal.editBudgetTargetDisabled',
    defaultMessage: 'Aby móc edytować targety budżetu włącz tę opcję w ustawieniach lokalizacji.',
  },
  spmhBudgetTargetPlaceholder: {
    id: 'loadBudgetTargetModal.spmhBudgetPlaceholder',
    defaultMessage: 'Podaj budżet godzin pracy',
  },
  colBudgetTargetPlaceholder: {
    id: 'loadBudgetTargetModal.colBudgetPlaceholder',
    defaultMessage: 'Podaj budżet kosztów pracy',
  },
  budgetEstimatesPlaceholder: {
    id: 'loadBudgetTargetModal.budgetEstimatesPlaceholder',
    defaultMessage: 'Podaj sprzedaż planowaną',
  },
  spmhPlaceholder: {
    id: 'loadBudgetTargetModal.spmhPlaceholder',
    defaultMessage: 'Podaj SPMH Target',
  },
  colPlaceholder: {
    id: 'loadBudgetTargetModal.colPlaceholder',
    defaultMessage: 'Podaj %COL Target',
  },
  spmhSuffix: {
    id: 'loadBudgetTargetModal.spmhSuffix',
    defaultMessage: 'PLN/h',
  },
  colSuffix: {
    id: 'loadBudgetTargetModal.colSuffix',
    defaultMessage: '%',
  },
  spmhBudgetSuffix: {
    id: 'loadBudgetTargetModal.spmhBudgetSuffix',
    defaultMessage: 'h',
  },
  budgetSuffix: {
    id: 'loadBudgetTargetModal.budgetSuffix',
    defaultMessage: 'PLN',
  },
  noSpmhBudget: {
    id: 'loadBudgetTargetModal.noSpmhBudget',
    defaultMessage: '_h _m',
  },
});
