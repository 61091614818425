import { connect } from 'react-redux';

import { getDashboard } from '@/actions/dashboard';

import DashboardView from './DashboardView.jsx';

const mapStateToProps = ({ reducer }) => ({
  dashboardError: reducer.dashboard.dashboardError,
  permissions: reducer.userPermissions.permissions,
});

const mapDispatchToProps = { getDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
