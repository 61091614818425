import PropTypes from 'prop-types';
import { memo } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { DIRECTIONS } from '@/constants/ui';
import { SCHEDULE_TOOLTIP_OFFSET_Y } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import { messages } from './QuickPlanningButtons.messages';

const QuickPlanningButtons = (props, { intl }) => {
  const {
    quickPlanningCopy,
    quickPlanningPaste,
    canCopy,
    canPaste,
    canDelete,
    canDeselect,
    deleteFromSelectedCells,
    quickPlanningClearSelection,
  } = props;
  return (
    <>
      <Tooltip
        yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
        position={DIRECTIONS.BOTTOM}
        content={intl.formatMessage(messages.copy)}
        withoutIcon
      >
        <Button onClick={quickPlanningCopy} modifiers="reverse-blue onlyIcon" disabled={!canCopy}>
          <i className="material-icons">content_copy</i>
        </Button>
      </Tooltip>
      <Tooltip
        yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
        position={DIRECTIONS.BOTTOM}
        content={intl.formatMessage(messages.paste)}
        withoutIcon
      >
        <Button onClick={quickPlanningPaste} modifiers="reverse-orange onlyIcon" disabled={!canPaste}>
          <i className="material-icons">content_paste</i>
        </Button>
      </Tooltip>
      <Tooltip
        yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
        position={DIRECTIONS.BOTTOM}
        content={intl.formatMessage(messages.delete)}
        withoutIcon
      >
        <Button onClick={deleteFromSelectedCells} modifiers="reverse-red onlyIcon" disabled={!canDelete}>
          <i className="material-icons">delete</i>
        </Button>
      </Tooltip>
      <Tooltip
        yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
        position={DIRECTIONS.BOTTOM}
        content={intl.formatMessage(messages.deselect)}
        withoutIcon
      >
        <Button onClick={quickPlanningClearSelection} modifiers="reverse-blue onlyIcon" disabled={!canDeselect}>
          <i className="material-icons">clear</i>
        </Button>
      </Tooltip>
    </>
  );
};

QuickPlanningButtons.propTypes = {
  quickPlanningCopy: PropTypes.func,
  quickPlanningPaste: PropTypes.func,
  canCopy: PropTypes.bool,
  canPaste: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDeselect: PropTypes.bool,
  deleteFromSelectedCells: PropTypes.func,
  quickPlanningClearSelection: PropTypes.func,
};

QuickPlanningButtons.contextTypes = {
  intl: PropTypes.shape({}),
};

export default memo(QuickPlanningButtons);
