import { Location } from '@/types';

import ScheduleTableRow from '../../ScheduleTableRow/ScheduleTableRow.redux';
import ScheduleTableSingleRow from '../../ScheduleTableSingleRow/ScheduleTableSingleRow.redux';
import { useRegularEmployees } from './useRegularEmployees';

type Props = {
  locationId: Location['id'];
};

export const RegularEmployees = ({ locationId }: Props) => {
  const { isSingleDay, regularEmployeeIds } = useRegularEmployees(locationId);

  return regularEmployeeIds.map(employeeId =>
    isSingleDay ? (
      <ScheduleTableSingleRow key={employeeId} employeeId={employeeId} locationId={locationId} />
    ) : (
      <ScheduleTableRow key={employeeId} employeeId={employeeId} locationId={locationId} />
    ),
  );
};
