import moment from 'moment';

import {
  getDatesRangeWithDayBeforeAndAfterFromDate,
  getDatesRangeWithDayBeforeAndAfterFromDates,
} from '@/utils/dateHelper';

import * as AT from '../../constants/ActionTypes';

export const extractDatesRange = action => {
  switch (action.type) {
    case AT.ADD_ATTENDANCE_SUCCESFUL: {
      const { createdAttendance } = action.payload;
      return getDatesRangeWithDayBeforeAndAfterFromDate(createdAttendance.date);
    }
    case AT.ACCEPT_ABSENCE_SUCCESS:
    case AT.REJECT_ABSENCE_SUCCESS:
    case AT.ADD_ABSENCE_SUCCESS:
    case AT.DELETE_ABSENCE_SUCCESS: {
      const absence = action.payload;
      return getDatesRangeWithDayBeforeAndAfterFromDates(absence.from, absence.to);
    }
    case AT.ADD_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.DELETE_SHIFT_FOR_PAYROLL_SUCCESS: {
      const { shift } = action.payload;
      return getDatesRangeWithDayBeforeAndAfterFromDate(shift.date);
    }
    case AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS:
    case AT.DELETE_ATTENDANCE_SUCCESFUL:
    case AT.ADD_ATTENDANCES_OVERTIME_SUCCESS: {
      const { attendance } = action.payload;
      return getDatesRangeWithDayBeforeAndAfterFromDate(attendance.date);
    }
    case AT.DELETE_BREAK_SUCCESFUL:
    case AT.ADD_BREAK_SUCCESFUL:
    case AT.CHANGE_BREAK_SUCCESFUL: {
      const restBreak = action.payload;
      const date = moment(restBreak.start_timestamp).format('YYYY-MM-DD');

      return getDatesRangeWithDayBeforeAndAfterFromDate(date);
    }
    case AT.ADD_ATTENDANCE_BONUS_SUCCESFUL: {
      const { attendance } = action.payload;
      const date = moment(attendance.start_timestamp).format('YYYY-MM-DD');

      return getDatesRangeWithDayBeforeAndAfterFromDate(date);
    }
    default:
      break;
  }
};
