import moment from 'moment';
import { ChangeEvent } from 'react';

import { Location } from '@/types';
import { SelectedJobTitles } from '@/types/jobTitles.types';
import { NewProposalData } from '@/types/loanEmployeesProposals';

import { EmployeeItem } from '../loanEmployeesProposalModals.helpers';
import LoanEmployeesProposalDetailsStep from '../loanEmployeesPropsalModalSteps/loanEmployeesProposalDetailsStep/LoanEmployeesProposalDetailsStep';
import LoanEmployeesProposalEditStep from '../loanEmployeesPropsalModalSteps/loanEmployeesProposalEditStep/LoanEmployeesProposalEditStep.redux';
import LoanEmployeesProposalEmployeesStep from '../loanEmployeesPropsalModalSteps/loanEmployeesProposalEmployeesStep/LoanEmployeesProposalEmployeesStep';

export interface ProposalHandlers {
  changeJobTitle: (jobTitleId: string) => void;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  changeWorkingHours: (workingHours: string) => void;
}

export const ERRORS_INITIAL_STATE = {
  workingHours: '',
  declaredSelectedEmployees: '',
  employeesNumber: '',
  comment: '',
};

export const getInitialState = (
  selectedJobTitles: SelectedJobTitles[],
  userLocations: Location[],
  modalObject: { locationId: string; date: string },
  currentUser: { first_name: string; last_name: string },
) => ({
  selectedJobTitleId: selectedJobTitles[0]?.id || '',
  employees_required: 1,
  working_hours: '',
  comment: '',
  location: { name: userLocations.find(({ id }) => id === modalObject?.locationId)?.name || '' },
  date: modalObject?.date || '',
  created_by: { first_name: currentUser.first_name, last_name: currentUser.last_name },
  jobTitle: selectedJobTitles[0]?.title || '',
  employees_remaining: null,
});

export const getEmployeesOptions = (
  employees: { first_name: string; last_name: string; id: string; locations: string[] }[],
) =>
  employees.map(employee => ({
    label: `${employee.first_name} ${employee.last_name}`,
    value: employee.id,
    active: true,
  }));

export const formatNewProposalData = (
  state: NewProposalData,
  locationId: string,
  date: string,
  selectedEmployeeIds: string[],
) => {
  const { selectedJobTitleId, employees_required, working_hours, comment } = state;

  const [start, end] = working_hours.split('-');

  const startTimestamp = moment(`${date} ${start}`).format('YYYY-MM-DD HH:mm:ss');
  const endTimestamp = moment(`${date} ${end}`).format('YYYY-MM-DD HH:mm:ss');
  return {
    location_id: locationId,
    job_title_id: selectedJobTitleId,
    employees_required,
    start_timestamp: startTimestamp,
    end_timestamp: endTimestamp,
    comment,
    selected_employees: selectedEmployeeIds,
  };
};

export const displayRelevantSteps = (
  currentStep: number,
  multiSelect: {
    employeesItems: EmployeeItem[];
    employeesHandlers: (employeeItem: EmployeeItem[]) => void;
  },
  state: NewProposalData,
  handlers: ProposalHandlers,
  showLoader: boolean,
) => {
  switch (currentStep) {
    case 1:
      return <LoanEmployeesProposalEditStep state={state} handlers={handlers} />;
    case 2:
      return (
        <LoanEmployeesProposalEmployeesStep
          employeesItems={multiSelect.employeesItems}
          employeesHandlers={multiSelect.employeesHandlers}
          showLoader={showLoader}
        />
      );

    case 3: {
      return (
        <LoanEmployeesProposalDetailsStep loanEmployeesProposal={state} employeeItems={multiSelect.employeesItems} />
      );
    }
    default:
      return null;
  }
};
