import { connect } from 'react-redux';

import { deleteShiftRequest, showConfirmModal } from '@/actions';
import { changeTradeShift, closeTradeShift, createOpenShiftFromTradeShift } from '@/actions/tradeShifts.js';
import { closeApprovalTradeShiftModal } from '@/actions/uiState.js';
import ApprovalTradeShiftModal from '@/components/scheduler/modals/ApprovalTradeShiftModal.jsx';

const mapStateToProps = state => {
  const modalObject = state.reducer.uiState.approvalTradeShiftModalObject;

  return {
    showModal: state.reducer.uiState.showApprovalTradeShiftModal,
    modalObject,
    userEmployees: state.reducer.userEmployees,
    enableOpenShifts:
      state.reducer.settings.locationSettings[modalObject.tradeShiftData.location.id]?.enable_open_shift,
    contracts: state.reducer.contracts,
  };
};

const mapDispatchToProps = {
  onHide: closeApprovalTradeShiftModal,
  closeTradeShift,
  changeTradeShift,
  createOpenShiftFromTradeShift,
  showConfirmModal,
  deleteShiftRequest,
};
const ApprovalTradeShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(ApprovalTradeShiftModal);

export default ApprovalTradeShiftModalContainer;
