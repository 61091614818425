import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { PASSWORD_CHANGE_INTERVAL_VIEW } from '@/constants/Permissions';
import {
  DISABLE_PAYROLL_APPROVAL_ENABLED_TUTORIAL_ID,
  ENABLE_PAYROLL_APPROVAL_ENABLED_TUTORIAL_ID,
} from '@/constants/tutorialsId.js';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers.js';
import { startTour } from '@/utils/intercomHelpers.js';

import CompanySettings from './CompanySettings/CompanySettings.jsx';
import { getCompanyToggledOptions, getInitialState, getNewCompanySettings } from './OptionsView.helpers';
import PasswordSettings from './PasswordSettings/PasswordSettings.redux';
import RestrictionsSettings from './RestrictionsSettings/RestrictionsSettings.redux';

class OptionsView extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = getInitialState(props.currentCompany);
    this.initialState = this.state;

    bindClassFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { currentCompany } = this.props;
    if (!isEqual(currentCompany, prevProps.currentCompany)) {
      this.setState(getInitialState(currentCompany));
    }
  }

  handleInputChange(e) {
    const { target } = e;
    const { name, checked, value, type } = target;
    const changedValue = type === 'checkbox' ? checked : value;

    const showDisablePayrollApprovalNotification =
      name === 'payrollApprovalEnabled' &&
      !changedValue &&
      this.state.employeeECPEnabled &&
      this.state.showWagesInEmployeeECP &&
      !this.state.showUnapprovedEmployeeECPData;

    const showEnableUnapprovedEmployeeECPDataNotification =
      name === 'showUnapprovedEmployeeECPData' && !changedValue && this.state.employeeECPEnabled;

    if (showDisablePayrollApprovalNotification) startTour(DISABLE_PAYROLL_APPROVAL_ENABLED_TUTORIAL_ID);

    if (showEnableUnapprovedEmployeeECPDataNotification) startTour(ENABLE_PAYROLL_APPROVAL_ENABLED_TUTORIAL_ID);

    if (name === 'employeeECPEnabled' && changedValue) {
      this.setState({
        employeeECPEnabled: true,
        payrollApprovalEnabled: true,
      });
      return;
    }
    if (name === 'employeeECPEnabled' && !changedValue) {
      this.setState({
        employeeECPEnabled: false,
        showUnapprovedEmployeeECPData: false,
        showWagesInEmployeeECP: false,
      });
      return;
    }
    if (name === 'showUnapprovedEmployeeECPData' && !changedValue && !this.state.payrollApprovalEnabled) {
      this.setState({
        payrollApprovalEnabled: true,
        showUnapprovedEmployeeECPData: false,
      });
      return;
    }
    if (name === 'payrollApprovalEnabled' && !changedValue && !this.state.showUnapprovedEmployeeECPData) {
      this.setState({
        payrollApprovalEnabled: false,
        employeeECPEnabled: false,
        showUnapprovedEmployeeECPData: false,
        showWagesInEmployeeECP: false,
      });
      return;
    }
    this.setState({
      [target.name]: changedValue,
    });
  }

  validateInput(event) {
    validateInputHelper(event).then(res => {
      const [name, error] = res;
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          [name]: error ? this.context.intl.formatMessage(error, {}) : error,
        },
      }));
    });
  }

  changeCompanySettings() {
    if (this.state.companyName !== this.initialState.companyName) {
      this.props.changeCurrentCompany({ ...this.props.currentCompany, name: this.state.companyName });
    }
    if (this.state.passwordRuleInterval !== this.initialState.passwordRuleInterval) {
      this.props.showPasswordRefreshConfirmModal(this.state.passwordRuleInterval, this.props.passwordChangedAt);
    }
    const settings = getNewCompanySettings(this.state, this.props.userRole);
    this.props.setCompanySettings({ settings });
    this.initialState = this.state;
  }

  canSaveCompanySettings() {
    const { errors, ...stateWithoutErrors } = this.state;
    const { errors: _initialStateErrors, ...initialStateWithoutErrors } = this.initialState;
    const isValid = !Object.values(errors).some(err => err !== '');
    return isValid && JSON.stringify(stateWithoutErrors) !== JSON.stringify(initialStateWithoutErrors);
  }

  render() {
    const { currentCompany, userPermissions } = this.props;

    const companySettingsButtonVisible = this.canSaveCompanySettings();

    const companyToggledOptions = getCompanyToggledOptions(this.state, userPermissions.permissions);
    const hasPasswordSettingsPermission = userPermissions.permissions.includes(PASSWORD_CHANGE_INTERVAL_VIEW);

    return (
      <div className="k-wrapper animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="k-panel panel-setting"
              style={{ marginTop: '20px', marginBottom: '100px', overflow: 'visible' }}
            >
              <CompanySettings
                companyName={this.state.companyName}
                companyToggledOptions={companyToggledOptions}
                errors={this.state.errors}
                handleInputChange={this.handleInputChange}
                validateInput={this.validateInput}
              />
              {this.props.userRole === 'owner' && (
                <RestrictionsSettings
                  handleInputChange={this.handleInputChange}
                  disableAvailabilitiesEditUntil={this.state.disableAvailabilitiesEditUntil}
                />
              )}
              {(hasPasswordSettingsPermission || currentCompany.settings.password_change_interval !== 0) && (
                <PasswordSettings
                  passwordRuleInterval={this.state.passwordRuleInterval}
                  handleInputChange={this.handleInputChange}
                />
              )}
              {companySettingsButtonVisible && (
                <div className="settingsButton" style={{ marginTop: '20px' }}>
                  <Button onClick={this.changeCompanySettings} modifiers="blue small">
                    <FormattedMessage id="settings.options.saveChanges" defaultMessage="Zapisz zmiany" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OptionsView.contextTypes = {
  intl: PropTypes.shape({}),
};

OptionsView.propTypes = {
  currentCompany: PropTypes.shape({
    name: PropTypes.string,
    settings: PropTypes.shape({
      password_change_interval: PropTypes.number,
      availabilities_are_public: PropTypes.bool,
      disable_availabilities_edit_until: PropTypes.string,
      schedule_is_public_for_employees: PropTypes.bool,
      same_rank_is_visible: PropTypes.bool,
      count_only_days_with_shifts_selected_by_default: PropTypes.bool,
    }),
  }),
  userRole: PropTypes.string,
  userPermissions: PropTypes.shape({ permissions: PropTypes.arrayOf(PropTypes.string) }),
  passwordChangedAt: PropTypes.string,
  setCompanySettings: PropTypes.func,
  changeCurrentCompany: PropTypes.func,
  showPasswordRefreshConfirmModal: PropTypes.func,
};

export default OptionsView;
