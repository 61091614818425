import { createSlice } from '@reduxjs/toolkit';

import { getLocationGroups } from './locationGroups.asyncActions.ts';
import { LocationGroupsStoreState } from './locationGroups.types.ts';

export const locationGroupsSliceName = 'locationGroups';

export const initialState: LocationGroupsStoreState = { locationGroups: [] };

const LocationGroupsSlice = createSlice({
  name: locationGroupsSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getLocationGroups.fulfilled, (_state, action) => action.payload);
  },
});

export const locationGroupsReducer = LocationGroupsSlice.reducer;
