import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import { Component } from 'react';

import AddAvailabilityModal from '@/containers/availability/AddAvailabilityModalContainer.js';
import EditAvailabilityModalContainer from '@/containers/availability/EditAvailabilityModalContainer.js';
import { getStylesForAvailabilitiesDisabledEditOverlay } from '@/utils/schedulerHelpers.js';

import AvailabilityTable from './AvailabilityTable.jsx';
import AvailabilityTableRow from './AvailabilityTableRow.jsx';
import HelpModal from './modals/AvailabilityHelpModal.jsx';

class AvailabilityTableView extends Component {
  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }
  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }
  render() {
    const availabilityRows = [];
    const disabledEditOverlayStyle = getStylesForAvailabilitiesDisabledEditOverlay(
      this.props.currentCompany.settings.disable_availabilities_edit_until,
      this.props.mainDateStore.dateArray,
    );
    const currentUserId = this.props.currentUser.user.id;
    // We want the employee from userEmployees in case of updates
    const employee = this.props.userEmployees.find(e => e.id === currentUserId);

    for (let x = 0; x < this.props.mainDateStore.dateArray.length; x++) {
      const date = this.props.mainDateStore.dateArray[x];
      const availability = employee ? employee.availability_blocks.find(a => a.date === date) : null;

      availabilityRows.push(
        <AvailabilityTableRow
          key={date}
          date={date}
          isToday={date === this.props.mainDateStore.today}
          employee={employee}
          availability={availability}
          userCustomAvaTypes={this.props.userCustomTypes}
          openAddAvailabilityModal={this.props.openAddAvailabilityModal}
          openEditAvailabilityModal={this.props.openEditAvailabilityModal}
        />,
      );
    }

    return (
      <div>
        <HelpModal />
        <AddAvailabilityModal />
        <EditAvailabilityModalContainer />
        <AvailabilityTable mainDateStore={this.props.mainDateStore} disabledEditOverlayStyle={disabledEditOverlayStyle}>
          {availabilityRows}
        </AvailabilityTable>
      </div>
    );
  }
}

AvailabilityTableView.propTypes = {
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    headerDateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  currentCompany: PropTypes.shape({
    settings: PropTypes.shape({
      disable_availabilities_edit_until: PropTypes.string,
    }),
  }),
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  openEditAvailabilityModal: PropTypes.func,
  openAddAvailabilityModal: PropTypes.func,
};

export default AvailabilityTableView;
