import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import OptionsCell from '@/components/common/OptionsCell.jsx';
import { employmentConditionsIds } from '@/constants/employmentConditions.js';
import { EMPLOYMENT_CONDITIONS_DELETE_MODAL,EMPLOYMENT_CONDITIONS_MODAL } from '@/constants/modalTypes.js';
import {
  COMPANY_MANAGE_CONDITIONS_HIDE_EDIT,
  COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT,
  COMPANY_MANAGE_EMPLOYMENTCONDITIONS_HIDE_DELETE,
} from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';
import { workingMinutesToHours } from '@/utils/employmentConditionsHelpers';

const EmploymentConditionsTableRow = props => {
  const { employmentCondition } = props;
  const isDefaultEmploymentCondition = employmentConditionsIds.includes(employmentCondition.id);

  const rowOptions = [
    {
      option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
      restriction: COMPANY_MANAGE_CONDITIONS_HIDE_EDIT,
      style: 'edit',
      function: () =>
        props.showModal(EMPLOYMENT_CONDITIONS_MODAL, { ...employmentCondition, isDefaultEmploymentCondition }),
    },
    {
      option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
      restriction: COMPANY_MANAGE_EMPLOYMENTCONDITIONS_HIDE_DELETE,
      style: 'delete',
      function: () => props.showModal(EMPLOYMENT_CONDITIONS_DELETE_MODAL, employmentCondition),
    },
  ];

  const { options, styles, functions } = applyPermissionsForOptions(rowOptions, props.userPermissions);
  const isLimitEditDisabled =
    props.userPermissions.restrictions.includes(COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT) ||
    employmentCondition.id === 'uoz';

  return (
    <tr>
      <td className="center">{workingMinutesToHours(employmentCondition.weekly_working_minutes)}</td>
      <td className="center">{workingMinutesToHours(employmentCondition.max_daily_working_minutes)}</td>

      <td className="center">
        <OptionsCell
          options={options}
          styles={styles}
          functions={functions}
          disabledArray={isDefaultEmploymentCondition ? [isLimitEditDisabled, true] : [false, false]}
        />
      </td>
    </tr>
  );
};

EmploymentConditionsTableRow.propTypes = {
  employmentCondition: PropTypes.shape({
    weekly_working_minutes: PropTypes.number,
    max_daily_working_minutes: PropTypes.number,
    id: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  showModal: PropTypes.func,
};

export default EmploymentConditionsTableRow;
