import PropTypes from 'prop-types';
import { Circle, MapContainer as LeafletMap, Marker, Popup, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import './Map.scss';

const openStreetMapUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const MapContainer = ({ markers, circles, position, zoom = 13 }) => (
  <LeafletMap center={position} zoom={zoom} className="mapContainer">
    <TileLayer url={openStreetMapUrl} />
    {markers.map(({ popup, ...marker }) => (
      <Marker {...marker} key={popup}>
        {popup && <Popup>{popup}</Popup>}
      </Marker>
    ))}

    {circles.map(({ popup, ...circle }) => (
      <Circle {...circle} key={popup}>
        {popup && <Popup>{popup}</Popup>}
      </Circle>
    ))}
  </LeafletMap>
);

MapContainer.propTypes = {
  children: PropTypes.node,
  circles: PropTypes.arrayOf(
    PropTypes.shape({
      center: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }).isRequired,
      pathOptions: PropTypes.shape({
        color: PropTypes.string,
      }),
      radius: PropTypes.string,
      popup: PropTypes.string,
    }),
  ),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }).isRequired,
      icon: PropTypes.shape({
        iconUrl: PropTypes.string,
        iconSize: PropTypes.number,
        iconAnchor: PropTypes.number,
        popupAnchor: PropTypes.number,
      }),
      popup: PropTypes.string,
    }),
  ),
  position: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  zoom: PropTypes.number,
};

export default MapContainer;
