import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { DELETE_EMPLOYEE } from '@/constants/ActionTypes';
import { EXPORT_FILE_TYPES } from '@/constants/exportConstants.js';
import { FREEMIUM_EVENT_EMPLOYEE_VIEW_EXPORT } from '@/constants/intercom.js';
import {
  CUSTOM_NOTIFICATION_MODAL,
  DELETE_RELEASED_EMPLOYEES_DATA_MODAL,
  EDIT_MASS_EMPLOYEES_MODAL,
  GENERATE_QR_CODE_MODAL,
} from '@/constants/modalTypes.js';
import { RELEASED_EMPLOYEE_CLEAR_DATA_ENABLE } from '@/constants/Permissions';
import { FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT } from '@/constants/Restrictions.js';

import employeesButtonBarMessages from './employeesButtonBar.messages.js';

const EmployeesActionsButton = ({
  employeesListUi,
  showModal,
  massDeleteEmployeesConfirm,
  relevantSelectedEmployeesIds,
  currentUserId,
  inviteEmployees,
  inactive,
  restrictions,
  relevantEmployees,
  exportSelectedEmployeesAsFile,
  trackIntercomEvent,
}) => {
  const editSelected = useCallback(() => {
    showModal(EDIT_MASS_EMPLOYEES_MODAL);
  }, [showModal]);

  const deleteSelected = useCallback(() => {
    massDeleteEmployeesConfirm(relevantSelectedEmployeesIds);
    trackIntercomEvent(DELETE_EMPLOYEE);
  }, [massDeleteEmployeesConfirm, relevantSelectedEmployeesIds, trackIntercomEvent]);

  const sendNotification = useCallback(() => {
    showModal(CUSTOM_NOTIFICATION_MODAL);
  }, [showModal]);
  const itemClassName = 'k-actionsButton__element k-actionsButton__element--with-icon';
  const exportDisabled = restrictions.includes(FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT);

  return (
    <ActionsButton
      asTopbarButton
      buttonTitle={
        <FormattedMessage
          id="applyForSelected"
          defaultMessage="Zastosuj dla zaznaczonych ({selectedCount})"
          values={{ selectedCount: employeesListUi.selected.length }}
        />
      }
      disabled={!employeesListUi.selected.length}
      icon={<Icon name="more_vert" />}
      testId="applyForSelected"
    >
      <>
        <FeatureWrapper restriction={RESTRICTIONS.COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT}>
          <button
            className={itemClassName}
            onClick={editSelected}
            disabled={!employeesListUi.selected.length}
            data-test="applyForSelectedEdit"
          >
            <i className="material-icons">edit</i>
            <FormattedMessage {...employeesButtonBarMessages.applyForSelected.edit} />
          </button>
        </FeatureWrapper>

        {!inactive && (
          <>
            <button
              className={itemClassName}
              onClick={sendNotification}
              disabled={!employeesListUi.selected.length}
              data-test="sendNotification"
            >
              <i className="material-icons">mobile_screen_share</i>
              <FormattedMessage {...employeesButtonBarMessages.applyForSelected.sendPush} />
            </button>
            <button
              className={itemClassName}
              onClick={inviteEmployees}
              disabled={!employeesListUi.selected.length}
              data-test="sendInvite"
            >
              <i className="material-icons">group_add</i>
              <FormattedMessage {...employeesButtonBarMessages.applyForSelected.sendInvite} />
            </button>
          </>
        )}

        <FreemiumWrapper
          freemiumRestrictions={FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT}
          intercomEvent={FREEMIUM_EVENT_EMPLOYEE_VIEW_EXPORT}
        >
          <button
            className={itemClassName}
            onClick={() =>
              exportSelectedEmployeesAsFile(EXPORT_FILE_TYPES.CSV, relevantEmployees, relevantSelectedEmployeesIds)
            }
            disabled={exportDisabled}
            data-test="export"
          >
            <i className="material-icons mirrorFlip">reply</i>
            <FormattedMessage {...employeesButtonBarMessages.applyForSelected.export} />
            {restrictions.includes(FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT) && <FreemiumStar />}
          </button>
        </FreemiumWrapper>

        <FreemiumWrapper
          freemiumRestrictions={FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT}
          intercomEvent={FREEMIUM_EVENT_EMPLOYEE_VIEW_EXPORT}
        >
          <button
            className={itemClassName}
            onClick={() => showModal(GENERATE_QR_CODE_MODAL)}
            disabled={exportDisabled}
            data-test="qrCode"
          >
            <i className="material-icons">qr_code</i>
            <FormattedMessage {...employeesButtonBarMessages.applyForSelected.generateQrCodes} />
            {restrictions.includes(FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT) && <FreemiumStar />}
          </button>
        </FreemiumWrapper>

        <FeatureWrapper restriction={RESTRICTIONS.COMPANY_MANAGE_EMPLOYEES_HIDE_DELETE}>
          <button
            className={itemClassName}
            onClick={deleteSelected}
            disabled={!relevantSelectedEmployeesIds.length || relevantSelectedEmployeesIds.includes(currentUserId)}
            data-test="delete"
          >
            <i className="material-icons">delete</i>
            <FormattedMessage {...employeesButtonBarMessages.applyForSelected.delete} />
          </button>
        </FeatureWrapper>

        <FeatureWrapper permission={RELEASED_EMPLOYEE_CLEAR_DATA_ENABLE}>
          <button
            className={itemClassName}
            onClick={() => showModal(DELETE_RELEASED_EMPLOYEES_DATA_MODAL, { relevantSelectedEmployeesIds })}
            disabled={!relevantSelectedEmployeesIds.length || relevantSelectedEmployeesIds.includes(currentUserId)}
            data-test="deleteReleasedEmployeesData"
          >
            <Icon name="contract_delete" type="material-symbols" />
            <FormattedMessage {...employeesButtonBarMessages.applyForSelected.deleteReleasedEmployeesData} />
          </button>
        </FeatureWrapper>
      </>
    </ActionsButton>
  );
};

EmployeesActionsButton.propTypes = {
  employeesListUi: PropTypes.shape({ selected: PropTypes.arrayOf(PropTypes.string) }),
  showModal: PropTypes.func,
  massDeleteEmployeesConfirm: PropTypes.func,
  toggleExportModal: PropTypes.func,
  relevantSelectedEmployeesIds: PropTypes.arrayOf(PropTypes.string),
  currentUserId: PropTypes.string,
  inviteEmployees: PropTypes.func,
  inactive: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  exportSelectedEmployeesAsFile: PropTypes.func,
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  trackIntercomEvent: PropTypes.func,
};

export default EmployeesActionsButton;
