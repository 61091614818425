import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getTextForTimeDiff } from '@/utils/dateHelper';

const DashboardTimeTrackerInitial = (props, { intl }) => {
  const { nextShift, location, spanPhrase, dayDiff, handleStartWork, loading } = props;
  const nextShiftText = intl.formatMessage(getTextForTimeDiff(dayDiff), { dayDiff });

  return (
    <div className="ibox-content employeeTimeTracker__content">
      <div className="employeeTimeTrackerContent__text">
        {nextShift ? (
          <>
            <span className="employeeTimeTrackerContentText__mainText employeeTimeTrackerContentText__mainText--active">
              <span>{`${nextShiftText} - ${moment(nextShift.date).format('D MMMM')} `}</span>
              <FormattedMessage
                id="dashboard.time.fromToHour"
                defaultMessage="od godziny {from} do {to}"
                values={{
                  from: nextShift.working_hours.slice(0, 5),
                  to: nextShift.working_hours.slice(6, 11),
                }}
              />
            </span>
            <span className="employeeTimeTrackerContentText__subText">
              {location && (
                <span>
                  <FormattedMessage id="dashboard.inLocation" defaultMessage="w lokalizacji" /> {location.name}{' '}
                  <FormattedMessage id="dashboard.onJobTitle" defaultMessage="na stanowisku" />{' '}
                  {nextShift.job_title.title}
                </span>
              )}
            </span>
          </>
        ) : (
          <div className="employeeTimeTrackerContentText__mainText">
            <FormattedMessage
              id="dashboard.timeTacker.noShiftInThisSpan"
              defaultMessage="Nie masz zaplanowanych żadnych zmian w {span}"
              values={{
                span: spanPhrase,
              }}
            />
          </div>
        )}
      </div>

      <div className="employeeTimeTracker__buttonsWrapper">
        <button
          className="employeeTimeTracker__button employeeTimeTracker__button--large"
          onClick={handleStartWork}
          disabled={loading}
        >
          <span className="material-icons employeeTimeTrackerButton__icon">play_arrow</span>
          <FormattedMessage id="dashboard.timeTacker.startShift" defaultMessage="Rozpocznij pracę" />
        </button>
      </div>
    </div>
  );
};

DashboardTimeTrackerInitial.propTypes = {
  nextShift: PropTypes.shape({
    date: PropTypes.string,
    working_hours: PropTypes.string,
    job_title: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  spanPhrase: PropTypes.string,
  dayDiff: PropTypes.string,
  handleStartWork: PropTypes.func,
  loading: PropTypes.bool,
};

DashboardTimeTrackerInitial.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default DashboardTimeTrackerInitial;
