import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import EmptyLocationSettings from './locationSettingsComponents/EmptyLocationSettings/EmptyLocationSettings';
import LocationSettingsContent from './locationSettingsComponents/LocationSettingsContent/LocationSettingsContent.redux';

const LocationSettingsView = ({ userLocations }) => {
  if (!userLocations.length) return <EmptyLocationSettings />;
  const params = useParams();
  return <LocationSettingsContent params={params} />;
};

LocationSettingsView.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  params: PropTypes.shape({
    locationName: PropTypes.string,
  }),
};

export default LocationSettingsView;
