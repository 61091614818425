import classnames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';

import './ModalSidebar.scss';

const ModalSidebar = ({ options, activeTab, onTabClick, user, content }) => (
  <div className="mdModal__sidebar">
    {user && (
      <div className="user__data">
        <img className="user__avatar" src={user.avatar ? user.avatar : '/img/user-avatar.svg'} alt="user-avatar" />
        <span className="user__info">
          {user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''}
        </span>
      </div>
    )}
    {!user && <div style={{ height: '100px' }} />}
    {options.map(tab => {
      const isActive = tab.id === activeTab;
      const tabClassName = classnames('mdModal__sidebar__tab', {
        'mdModal__sidebar__tab--active': isActive,
        'mdModal__sidebar__tab--error': tab.error,
        'mdModal__sidebar__tab--disabled': tab.disabled && !tab.showFreemiumStar,
      });
      return (
        <FreemiumWrapper freemiumRestrictions={tab.freemiumRestrictions} intercomEvent={tab.intercomEvent} key={tab.id}>
          <div
            className={tabClassName}
            onClick={tab.disabled ? null : () => onTabClick(tab.id)}
            role="presentation"
            data-test={tab.testId}
          >
            {tab.name}
            {tab.showFreemiumStar && <FreemiumStar />}
          </div>
        </FreemiumWrapper>
      );
    })}
    {content && cloneElement(content)}
  </div>
);

ModalSidebar.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.number,
  onTabClick: PropTypes.func,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  content: PropTypes.node,
};

export default ModalSidebar;
