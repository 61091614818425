import { connect } from 'react-redux';

import { changeFlexTemplateSingleShift } from '@/actions/templates.js';

import Component from './ScheduleTemplateFlexTable.jsx';

const mapStateToProps = state => ({
  currentTemplate: state.reducer.userTemplates.currentTemplate,
  mainDateStore: state.reducer.mainDateStore,
  selectedDay: state.reducer.scheduleState.selectedDay,
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  changeFlexTemplateSingleShift,
};

const SchedulerViewContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default SchedulerViewContainer;
