import { connect } from 'react-redux';

import { addNewTemplate } from '@/actions/templates.js';
import { hideModal } from '@/actions/uiState.js';
import { SAVE_SCHEDULE_AS_TEMPLATE_MODAL } from '@/constants/modalTypes.js';

import SaveScheduleAsTemplateModal from './SaveScheduleAsTemplateModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === SAVE_SCHEDULE_AS_TEMPLATE_MODAL,
  modalObject: state.reducer.uiState.modalObject[SAVE_SCHEDULE_AS_TEMPLATE_MODAL],
  userEmployees: state.reducer.userEmployees,
  locationFilter: state.reducer.locationFilter,
  selectedLocations: state.reducer.scheduleLocationFilter,
  jobtitleFilter: state.reducer.jobtitleFilter,
  scheduleUIState: state.reducer.scheduleUIState,
  mainDateStore: state.reducer.mainDateStore,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  hideModal,
  addNewTemplate,
};

const SaveScheduleAsTemplateModalContainer = connect(mapStateToProps, mapDispatchToProps)(SaveScheduleAsTemplateModal);

export default SaveScheduleAsTemplateModalContainer;
