import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  createLabelTitle: {
    id: 'addAndEditLabelPopoverContent.createLabelTitle',
    defaultMessage: 'Stwórz etykietę',
  },
  editLabelTitle: {
    id: 'addAndEditLabelPopoverContent.editLabelTitle',
    defaultMessage: 'Edytuj etykietę',
  },
  tagName: {
    id: 'addAndEditLabelPopoverContent.tagName',
    defaultMessage: 'Nazwa etykiety',
  },
  tagColor: {
    id: 'addAndEditLabelPopoverContent.tagColor',
    defaultMessage: 'Kolor etykiety',
  },
  delete: {
    id: 'addAndEditLabelPopoverContent.delete',
    defaultMessage: 'Usuń',
  },
  cancel: {
    id: 'addAndEditLabelPopoverContent.cancel',
    defaultMessage: 'Anuluj',
  },
  save: {
    id: 'addAndEditLabelPopoverContent.save',
    defaultMessage: 'Zapisz',
  },
  valueTooLongError: {
    id: 'addAndEditLabelPopoverContent.valueTooLongError',
    defaultMessage: 'Przekraczono limit 63 znaków',
  },

  selectedLabelColorError: {
    id: 'addAndEditLabelPopoverContent.selectLabelColorError',
    defaultMessage: 'Wybierz kolor tagu',
  },

  colorLabelRequiredError: {
    id: 'addAndEditLabelPopoverContent.colorLabelRequiredError',
    defaultMessage: 'Tag już istnieje',
  },

  tagNameReqiuredError: {
    id: 'addAndEditLabelPopoverContent.tagNameReqiuredError',
    defaultMessage: 'Podaj nazwę tagu',
  },
});
