import { MDColorInput } from '@/components/common/inputs/MDColorInput/MDColorInput';
import { MDTextArea } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { OpenModal } from '@/components/common/OpenModal/OpenModal';
import { NARROW } from '@/constants/modalModifiers';
import { MANAGER_NOTE_MODAL } from '@/constants/modalTypes';

import { messages } from './ManagerNoteModal.utils';
import { useManagerNoteModal } from './useManagerNoteModal';

export const ManagerNoteModal = () => (
  <OpenModal modalType={MANAGER_NOTE_MODAL} modalComponent={ManagerNoteModalComponent} />
);

const ManagerNoteModalComponent = () => {
  const { changeInput, footerOptions, intl, isEdited, isModalOpen, locationName, onCloseModal, onSubmitModal, state } =
    useManagerNoteModal();

  return (
    <MDKadroModal
      show={isModalOpen}
      onHide={onCloseModal}
      onSubmit={onSubmitModal}
      title={intl.formatMessage(isEdited ? messages.editManagerNote : messages.addManagerNote)}
      modifiers={NARROW}
      footerOptions={footerOptions}
    >
      <div className="heading heading--extraTopMargin">
        {state.date}, {locationName}
      </div>
      <MDColorInput color={state.color} name="color" onChange={changeInput} />
      <MDTextArea
        label={intl.formatMessage(messages.note)}
        placeholder={intl.formatMessage(messages.notePlaceholder)}
        value={state.comment}
        onChange={changeInput}
        name="comment"
      />
    </MDKadroModal>
  );
};
