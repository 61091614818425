import { connect } from 'react-redux';

import { changeEmployeeSorting } from '@/actions/employeeSorting.js';

import TopBarSortingFilter from './TopBarSortingFilter.jsx';

const mapStateToProps = state => ({
  employeeSorting: state.reducer.employeeSorting,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode.type,
});

const mapDispatchToProps = {
  changeEmployeeSorting,
};

const TopBarSortingFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarSortingFilter);

export default TopBarSortingFilterContainer;
