import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages } from 'react-intl';

import { ADD_TEMPLATES_SHIFT_MODAL } from '@/constants/modalTypes.js';
import TemplatesDropAreaContainer from '@/containers/scheduler/Templates/TemplatesDropAreaContainer.jsx';

import ScheduleTemplatesShift from './ScheduleTemplatesShift.jsx';

const messages = defineMessages({
  deleteShiftTitle: {
    id: 'templates.tableRow.deleteShiftTitle',
    defaultMessage: 'Usuń zmianę',
  },
  deleteShiftDescription: {
    id: 'templates.tableRow.deleteShiftDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć zmianę?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

class ScheduleTemplatesTableRow extends PureComponent {
  deleteShift(shift, e) {
    e.stopPropagation();
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(messages.deleteShiftTitle, {}),
      description: this.context.intl.formatMessage(messages.deleteShiftDescription, {}),
      confirmText: this.context.intl.formatMessage(messages.delete, {}),
      confirmFunc: () => {
        this.props.deleteTemplatesShift(shift, this.props.userTemplates, this.props.templateID, true);
      },
      confirmType: 'warning',
    });
  }

  editShift(shift) {
    this.props.showModal(ADD_TEMPLATES_SHIFT_MODAL, {
      rowData: { ...shift, ...shift.job_title, shiftID: shift.id },
      date: shift.date,
      templateID: this.props.templateID,
    });
  }

  addShift(date, e) {
    e.stopPropagation();
    this.props.showModal(ADD_TEMPLATES_SHIFT_MODAL, {
      rowData: this.props.rowData,
      date,
      templateID: this.props.templateID,
    });
  }

  render() {
    const { rowData, dateStore, templateID, showModal } = this.props;

    const relevantRowData = {
      ...rowData,
      shifts: rowData.shifts.filter(shift => dateStore.templatesDateArray.includes(shift.date)),
    };
    return (
      <tr id="row1" className="h-sorce2">
        {dateStore.templatesDateArray.map((date, index) => {
          const shifts = relevantRowData.shifts
            .filter(shift => shift.date === date)
            .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1));

          const tdClassNames = classnames({
            empty: shifts.length === 0,
            weekend: date === 6 || date === 0,
          });

          return (
            <td
              key={index}
              onClick={
                shifts.length === 0
                  ? () => {
                      showModal(ADD_TEMPLATES_SHIFT_MODAL, {
                        rowData,
                        date,
                        templateID,
                      });
                    }
                  : () => {}
              }
              className={tdClassNames}
              role="presentation"
            >
              <TemplatesDropAreaContainer
                date={date}
                jobTitleID={relevantRowData.id}
                rowData={this.props.rowData}
                templateId={templateID}
              >
                {shifts.map((shift, i) => (
                  <ScheduleTemplatesShift
                    key={shift.id + i}
                    className="position__sch_block"
                    shift={shift}
                    handleAddAction={e => this.addShift(date, e)}
                    handleEditAction={() => this.editShift(shift)}
                    handleDeleteAction={e => this.deleteShift(shift, e)}
                  />
                ))}
              </TemplatesDropAreaContainer>
            </td>
          );
        })}
      </tr>
    );
  }
}

ScheduleTemplatesTableRow.contextTypes = {
  intl: PropTypes.shape({}),
};

ScheduleTemplatesTableRow.propTypes = {
  rowData: PropTypes.shape({
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dateStore: PropTypes.shape({
    templatesDateArray: PropTypes.arrayOf(PropTypes.number),
  }),
  userTemplates: PropTypes.arrayOf(PropTypes.shape({})),
  templateID: PropTypes.string,
  showModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  deleteTemplatesShift: PropTypes.func,
};

export default ScheduleTemplatesTableRow;
