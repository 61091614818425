import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import {
  getDataForRecommendedScheduleFromTemplate,
  MODAL_MODIFIERS,
} from './RecommendedScheduleFromExistingTemplate.helpers';
import { messages } from './RecommendedScheduleFromExistingTemplate.messages';

const RecommendedScheduleFromExistingTemplate = (props, { intl }) => {
  const { templatesArray, dateArray, scheduleLocationFilter } = props;
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    setSelectedTemplateId(null);
  }, [props.isVisibleModal]);

  const templatesOptions = useMemo(() => props.templatesArray.map(t => ({ key: t.name, value: t.id })), [
    props.templatesArray,
  ]);

  const onSubmit = useCallback(() => {
    const template = templatesArray.find(({ id }) => id === selectedTemplateId);
    const data = getDataForRecommendedScheduleFromTemplate(template, dateArray, scheduleLocationFilter[0]);
    props.addRecommendedSchedule(data);
    props.hideModal();
  }, [selectedTemplateId, templatesArray, dateArray, scheduleLocationFilter]);

  return (
    <MDKadroModal
      show={props.isVisibleModal}
      onSubmit={onSubmit}
      onHide={props.hideModal}
      title={intl.formatMessage(messages.title)}
      modifiers={MODAL_MODIFIERS}
      disableConfirm={!selectedTemplateId}
      withoutOverlayScroll
    >
      <MDSelect
        name={intl.formatMessage(messages.chooseTemplate)}
        options={templatesOptions}
        defaultValue={selectedTemplateId}
        onChange={setSelectedTemplateId}
        closeOnClick
        withSearch
      />
    </MDKadroModal>
  );
};

RecommendedScheduleFromExistingTemplate.contextTypes = {
  intl: PropTypes.shape({}),
};

RecommendedScheduleFromExistingTemplate.propTypes = {
  hideModal: PropTypes.func,
  isVisibleModal: PropTypes.bool,
  templatesArray: PropTypes.arrayOf(PropTypes.shape({})),
  dateArray: PropTypes.arrayOf(PropTypes.string),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  addRecommendedSchedule: PropTypes.func,
};

export default RecommendedScheduleFromExistingTemplate;
