import { getHoursForSingleDayView } from '@/utils/absenceHelpers';
import { calculateDurationBetweenTimestamps } from '@/utils/dateHelper';

export const getStylesForAbsenceBlock = (absence, shiftsForDate, hiddenShiftsForDate) => {
  const date = absence.from;
  const top = shiftsForDate.length > 0 ? '50px' : 0;
  const hours = getHoursForSingleDayView(absence, [...shiftsForDate, ...hiddenShiftsForDate]);
  const [start, end] = hours.split('-');
  const left = `${(start.split(':')[0] * 60 + 1 * start.split(':')[1]) / 14.4}%`;
  const startTimestamp = `${date} ${start}`;
  const endTimestamp = end < start ? `${date} 23:59` : `${date} ${end}`;
  const width = `${calculateDurationBetweenTimestamps(endTimestamp, startTimestamp) / 14.4}%`;
  return { top, left, width };
};
