import { connect } from 'react-redux';

import { onOpenShiftRowClick, onTradeShiftRowClick } from '@/actions/schedule/selectedCells';
import { toggleTradeShiftsRowStickiness } from '@/actions/schedule/stickyRows';

import Component from './TradeShiftRowTitle.jsx';

const mapStateToProps = state => ({
  rowSticky: !!state.reducer.schedule.stickyRows.tradeShiftsSticky,
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  onOpenShiftRowClick,
  toggleTradeShiftsRowStickiness,
  onTradeShiftRowClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
