import PropTypes from 'prop-types';

import { MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import { emplMassEditTypes } from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';

import EmployeesEditMasAbsenceLimitsField from './AbsenceLimitsField/EmployeesEditMasAbsenceLimits.redux.js';
import EmplyeesEditMassCycleDurationField from './CycleDurationField/EmplyeesEditMassCycleDurationField.jsx';
import EmployeesEditMassCycleStartField from './CycleStartField/EmployeesEditMassCycleStartField.jsx';
import EditMassEmployeesElasticWorkDayField from './ElasticWorkDayField/EditMassEmployeesElasticWorkDayField.jsx';
import EmplyeesEditMassEmplConditions from './EmplConditionsField/EmployeesEditMassEmplConditionsField.redux.js';
import { displayRelevantContractField } from './EmployeesEditMassValueField.helpers.tsx';
import EmplyoeesEditMassLocationField from './LocationsField/EmployeesEditMassLoactionField.redux.js';
import EmplyeesEditMassRolesField from './RolesField/EmployeesEditMassRolesField.redux.js';
import EmployeesEditMassWorkingHours from './WorkingHoursField/EmployeesEditMassWorkingHoursField.jsx';

const EmployeesEditMassValueField = ({ fieldValue, selectedType, onChange, selectedAction }) => {
  switch (selectedType) {
    case emplMassEditTypes.location:
      return <EmplyoeesEditMassLocationField selectedLocations={fieldValue || []} onChange={onChange} />;
    case emplMassEditTypes.employmentConditions:
      return <EmplyeesEditMassEmplConditions onChange={onChange} />;
    case emplMassEditTypes.weeklyWorkingMinutes:
      return <EmployeesEditMassWorkingHours selected={fieldValue || ''} onChange={onChange} />;
    case emplMassEditTypes.scheduleCycleStart:
      return <EmployeesEditMassCycleStartField value={fieldValue} onChange={onChange} />;
    case emplMassEditTypes.scheduleCycleDuration:
      return <EmplyeesEditMassCycleDurationField value={fieldValue || ''} onChange={onChange} />;
    case emplMassEditTypes.role:
      return <EmplyeesEditMassRolesField onChange={onChange} />;
    case emplMassEditTypes.elasticWorkDay:
      return <EditMassEmployeesElasticWorkDayField value={fieldValue || false} onChange={onChange} />;
    case emplMassEditTypes.limits:
      return <EmployeesEditMasAbsenceLimitsField onChange={onChange} />;
    case emplMassEditTypes.contracts:
      return displayRelevantContractField(fieldValue, onChange, selectedAction);
    default:
      return (
        <MDTextInput
          value={fieldValue || ''}
          onChange={e => {
            onChange(e.target.value);
          }}
        />
      );
  }
};

EmployeesEditMassValueField.propTypes = {
  fieldValue: PropTypes.string,
  selectedType: PropTypes.string,
  onChange: PropTypes.func,
  selectedAction: PropTypes.string,
};

export default EmployeesEditMassValueField;
