import { memo } from 'react';

import { LocationId } from '@/types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import ScheduleTableRow from '../../../ScheduleTableRow/ScheduleTableRow.redux';
import { GroupBar } from '../GroupBar/GroupBar';
import type { GroupNodeType } from '../GroupedEmployees.types';
import { useGroupNode } from './useGroupNode';

type Props = {
  level: number;
  locationId: LocationId;
  node: GroupNodeType;
};

export const GroupNode = memo(({ level, locationId, node }: Props) => {
  const { id, groupId, groupKey, children, employeesIds } = node;
  const { isOpen, loanedEmployeesDict, toggle } = useGroupNode(locationId);

  return (
    <>
      <GroupBar
        isOpen={isOpen}
        toggle={toggle}
        level={level}
        groupId={groupId}
        groupKey={groupKey}
        recordsAmount={employeesIds?.length}
      />
      {isOpen && (
        <>
          {children?.map(childNode => (
            <GroupNode key={childNode.id} locationId={locationId} node={childNode} level={level + 1} />
          ))}
          {!isEmptyArray(employeesIds) && (
            <tbody className="k-groupedEmployees__tbody">
              {employeesIds.map(employeeId => (
                <ScheduleTableRow
                  key={employeeId}
                  groupNodeId={id}
                  employeeId={employeeId}
                  locationId={locationId}
                  isLoanedEmployee={loanedEmployeesDict[employeeId]}
                />
              ))}
            </tbody>
          )}
        </>
      )}
    </>
  );
});

GroupNode.displayName = 'GroupNode';
