import { connect } from 'react-redux';

import { hideModal, showModal } from '@/actions/uiState.js';
import { LOAD_RECOMMENDED_SCHEDULE_MODAL } from '@/constants/modalTypes.js';

import LoadRecommendedScheduleModal from './LoadRecommendedScheduleModal.jsx';

const mapStateToProps = state => ({
  isVisibleModal: state.reducer.uiState.showModal === LOAD_RECOMMENDED_SCHEDULE_MODAL,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadRecommendedScheduleModal);
