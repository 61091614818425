import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  targetSPMH: {
    id: 'bonusTargetOptions.header.targetSPMH',
    defaultMessage: 'Target SPMH',
  },
  percentSalary: {
    id: 'bonusTargetOptions.header.percentSalary',
    defaultMessage: '% wynagrodzenia',
  },
});
