import { Dispatch, SetStateAction } from 'react';

import { Option } from '../MultiSelectOptionManagerDropDown.tsx';
import OptionItem from './OptionItem/OptionItem.tsx';

interface OptionsListProps {
  selectedOptions: Option[];
  handleOptionSelect: (option: Option) => void;
  setPopoverStatus: Dispatch<SetStateAction<boolean>>;
  clearInputValue: () => void;
  hideEdit: boolean;
}

const OptionsList = ({
  selectedOptions,
  handleOptionSelect,
  setPopoverStatus,
  clearInputValue,
  hideEdit,
}: OptionsListProps) => (
  <>
    {selectedOptions.map(option => (
      <OptionItem
        key={option.label}
        option={option}
        handleOptionSelect={handleOptionSelect}
        setPopoverStatus={setPopoverStatus}
        clearInputValue={clearInputValue}
        hideEdit={hideEdit}
      />
    ))}
  </>
);

export default OptionsList;
