import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import browserHistory from '@/constants/browserHistory';

import NotificationList from './NotificationList.jsx';

const NotificationPanel = props => {
  const {
    uiState,
    userNotifications,
    closeNotificationPanel,
    notificationToggleReadStatus,
    notificationGoToContext,
    pullNotifications,
  } = props;

  useEffect(() => {
    if (uiState.showNotificationPanel) {
      pullNotifications(25, false);
    }
    const handleClickOutside = event => {
      if (uiState.showNotificationPanel && event.target.classList.contains('modal-backdrop')) {
        closeNotificationPanel();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [uiState.showNotificationPanel, closeNotificationPanel]);

  const showAllNotifications = () => {
    closeNotificationPanel();
    browserHistory.push('/notifications');
  };

  const panelClass = classnames('notipanel', {
    'open nav-shadow': uiState.showNotificationPanel,
  });

  return (
    <div className={panelClass}>
      <div className="title_pane">
        <i className="material-icons close" onClick={closeNotificationPanel} role="presentation">
          close
        </i>
      </div>
      <NotificationList
        userNotifications={userNotifications.notificationArray}
        notificationGoToContext={notificationGoToContext}
        notificationToggleReadStatus={notificationToggleReadStatus}
      />
      <div className="notipanel__button">
        <Button onClick={showAllNotifications} modifiers="blue">
          <FormattedMessage id="notification.panel.showAll" defaultMessage="Pokaż wszystkie" />
        </Button>
      </div>
    </div>
  );
};

NotificationPanel.propTypes = {
  uiState: PropTypes.shape({
    showNotificationPanel: PropTypes.bool,
  }),
  userNotifications: PropTypes.shape({
    notificationArray: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  closeNotificationPanel: PropTypes.func,
  notificationToggleReadStatus: PropTypes.func,
  notificationGoToContext: PropTypes.func,
  pullNotifications: PropTypes.func,
};

export default NotificationPanel;
