import { FreeDayItem, FreeDaysMarkingStoreState } from '@/redux-store/freeDaysMarking';
import { SelectedCellsStoreState } from '@/redux-store/schedule/selectedCells';

export const getFreeDaysIdsForSelectedCells = (
  selectionPerLocation: SelectedCellsStoreState['selectionsPerLocation'],
  freeDaysMarking: FreeDaysMarkingStoreState,
): FreeDayItem['id'][] =>
  Object.values(selectionPerLocation).reduce((acc, { selected }) => {
    Object.entries(selected).forEach(([employeeId, dates]) => {
      Object.entries(dates).forEach(([date, isSelected]) => {
        const freeDay = freeDaysMarking[employeeId]?.[date];
        if (isSelected && freeDay) acc.push(freeDay.id);
      });
    });
    return acc;
  }, []);
