/* eslint max-len:0 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AbsenceHistoryModalContainer from '@/components/absences/modals/AbsenceHistoryModal/AbsenceHistoryModal.redux.js';
import AbsenceProposalModal from '@/components/absences/modals/AbsenceProposalModal/AbsenceProposalModal.redux.js';
import AutoGenerateScheduleModal from '@/components/autoscheduler/AutoGenerateScheduleModal/AutoGenerateScheduleModal.redux.js';
import AutoGenerateScheduleModalViaProxy from '@/components/autoscheduler/AutoGenerateScheduleModal/AutoGenerateScheduleModalForProxy.redux.js';
import AutoGenerateStepsModal from '@/components/autoscheduler/AutoGenerateStepsModal/AutoGenerateStepsModal.redux';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import EmployeeModal from '@/components/companymanage/employees/EmployeeModal/EmployeeModal/EmployeeModal.redux';
import AddOpenShiftModalContainer from '@/components/scheduler/modals/AddOpenShiftModal/AddOpenShiftModal.redux.js';
import AddShiftAndAbsenceModalContainer from '@/components/scheduler/modals/AddShiftAndAbsenceModal/AddShiftAndAbsenceModal.redux.js';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import ApplyForOpenShiftModalContainer from '@/components/scheduler/modals/ApplyForOpenShiftModal/ApplyForOpenShiftModal.redux';
import EditEventModalContainer from '@/components/scheduler/modals/EventModal/EditEventModal.redux.js';
import TopBarSchedule from '@/components/TopBars/TopBarSchedule/TopBarSchedule.redux.js';
import { AUTOSCHEDULER_PROXY_ENABLE } from '@/constants/Permissions.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import AddAvailabilityModal from '@/containers/availability/AddAvailabilityModalContainer';
import EditAvailabilityModalContainer from '@/containers/availability/EditAvailabilityModalContainer';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';
import ApprovalTradeShiftModalContainer from '@/containers/scheduler/ApprovalTradeShiftModalContainer.jsx';
import AssignUsersOpenShiftModalContainer from '@/containers/scheduler/AssignUsersOpenShiftModalContainer.js';
import CoworkerTradeShiftModalContainer from '@/containers/scheduler/CoworkerTradeShiftModalContainer.jsx';
import ImportOpenShiftModalContainer from '@/containers/scheduler/openShift/ImportOpenShiftModalContainer.jsx';
import RequestTradeShiftModalContainer from '@/containers/scheduler/RequestTradeShiftModalContainer.jsx';
import ScheduleHelpModalContainer from '@/containers/scheduler/ScheduleHelpModalContainer.js';
import { calculateScheduleTableHeight } from '@/utils/schedulerHelpers';

import AvailabilitySendPanel from '../Availability/AvailabilitySendPanel/AvailabilitySendPanel.redux';
import ScheduleBudgetTable from '../budgetBeta/ScheduleBudgetTable/ScheduleBudgetTable.redux.js';
import EmptyStateScheduleView from '../EmptyStates/EmptyStateScheduleView/EmptyStateScheduleView.redux.js';
import IncomingLoanEmployeesProposalModal from '../loanEmployeesProposalModals/incomingLoanEmployeesProposalModal/incomingLoanEmployeesProposalModal/IncomingLoanEmployeesProposalModal.redux.js';
import OutgoingLoanEmployeesProposalModal from '../loanEmployeesProposalModals/outgoingLoanEmployeesProposalModal/OutgoingLoanEmployeesProposalModal.redux.js';
import { DeleteScheduleItemsModal } from '../modals/DeleteScheduleItemsModal/DeleteScheduleItemsModal';
import ExportModal from '../modals/ExportScheduleModal/ExportScheduleModal.redux.js';
import ImportBudgetMetricsModal from '../modals/ImportBudgetMetricsModal/ImportBudgetMetricsModal.redux';
import ImportBudgetModal from '../modals/ImportBudgetModal/ImportBudgetModal.redux.js';
import ImportBudgetTargetModal from '../modals/ImportBudgetTargetModal/ImportBudgetTargetModal.redux.js';
import ImportScheduleModal from '../modals/ImportScheduleModal/ImportScheduleModal.redux.js';
import LoadBudgetTargetModal from '../modals/LoadBudgetTargetModal/LoadBudgetTargetModal.redux.js';
import ImportRecommendedScheduleModal from '../modals/LoadRecommendedScheduleModal/ImportRecommendedScheduleModal/ImportRecommendedScheduleModal.redux.js';
import LoadRecommendedScheduleModal from '../modals/LoadRecommendedScheduleModal/LoadRecommendedScheduleModal.redux';
import RecommendedScheduleFromExistingTemplate from '../modals/LoadRecommendedScheduleModal/RecommendedScheduleFromExistingTemplate/RecommendedScheduleFromExistingTemplate.redux';
import { ManagerNoteModal } from '../modals/ManagerNotesModal/ManagerNotesModal';
import QuickPlanningWarningModal from '../modals/QuickPlanningWarningModal/QuickPlanningWarningModal.redux';
import SaveScheduleAsTemplateModal from '../modals/SaveScheduleAsTemplateModal/SaveScheduleAsTemplateModal.redux.js';
import ShortcutsModal from '../modals/ShortcutsModal/ShortcutsModal.redux.js';
import ShowConflictsModal from '../modals/ShowConflictsModal/ShowConflictsModal.redux.js';
import ScheduleSendPanel from '../ScheduleSendPanel/ScheduleSendPanel.redux.js';
import EventsTable from '../ScheduleTable/EventsTable/EventsTable.redux';
import ScheduleButtonBar from '../ScheduleTable/ScheduleButtonBar/ScheduleButtonBar.redux.js';
import { ScheduleShortcutManager } from '../ScheduleTable/ScheduleShortcutManager/ScheduleShortcutManager';
import ScheduleTableContainer from '../ScheduleTable/ScheduleTable.redux.js';

class ScheduleBetaModeView extends PureComponent {
  render() {
    const { scheduleLocationFilter, scheduleState, eventsTableEnabled, showBudgetTable, displayMode, userPermissions } =
      this.props;
    const showEventsTable = eventsTableEnabled;
    const windowHeight = window.innerHeight;
    const height = calculateScheduleTableHeight(windowHeight, { showBudgetTable, showEventsTable });
    const employeesPerLocation = Object.values(scheduleState.locations).filter(l =>
      scheduleLocationFilter.includes(l.locationId),
    );
    const areLocationsNotSelected = !employeesPerLocation.length;
    const noEmployeesAreVisible = employeesPerLocation.length === 1 && employeesPerLocation[0].visible.length === 0;

    const scheduleModals = (
      <div>
        <ExportModal
          exportRawData={() => ({
            locationsToDisplay: scheduleLocationFilter,
            type: 'schedule',
          })}
        />
        <ImportBudgetMetricsModal />
        <ImportBudgetModal />
        <LoadRecommendedScheduleModal />
        <ImportBudgetTargetModal />
        <LoadBudgetTargetModal />
        <ImportRecommendedScheduleModal />
        <RecommendedScheduleFromExistingTemplate />
        <ImportScheduleModal />
        <EditEventModalContainer />
        <ObjectHistoryModalContainer />
        <AddShiftModalContainer />
        <AddShiftAndAbsenceModalContainer />
        <AbsenceHistoryModalContainer />
        <AutoGenerateStepsModal />
        {userPermissions.permissions.includes(AUTOSCHEDULER_PROXY_ENABLE) ? (
          <AutoGenerateScheduleModalViaProxy />
        ) : (
          <AutoGenerateScheduleModal />
        )}
        <AddOpenShiftModalContainer />
        <ApplyForOpenShiftModalContainer />
        <AssignUsersOpenShiftModalContainer />
        <ScheduleHelpModalContainer />
        <RequestTradeShiftModalContainer />
        <CoworkerTradeShiftModalContainer />
        <ApprovalTradeShiftModalContainer />
        <ShowConflictsModal />
        <ImportOpenShiftModalContainer />
        <SaveScheduleAsTemplateModal />
        <ManagerNoteModal />
        <AbsenceProposalModal />
        <EditAvailabilityModalContainer />
        <AddAvailabilityModal />
        <QuickPlanningWarningModal />
        <ShortcutsModal />
        <EmployeeModal />
        <IncomingLoanEmployeesProposalModal />
        <OutgoingLoanEmployeesProposalModal />
        <DeleteScheduleItemsModal />
      </div>
    );

    return (
      <div>
        {displayMode === 'availabilities' ? <AvailabilitySendPanel /> : <ScheduleSendPanel />}
        <TopBarSchedule />
        <div className="k-wrapper k-wrapper--tablePadding animated fadeInRight">
          {scheduleModals}
          <ScheduleButtonBar />
          <ScheduleShortcutManager />

          {areLocationsNotSelected || noEmployeesAreVisible ? (
            <EmptyStateScheduleView />
          ) : (
            employeesPerLocation.map(location => (
              <ScheduleTableContainer key={location.locationId} height={height} locationId={location.locationId} />
            ))
          )}
          {showBudgetTable && (
            <FeatureWrapper restriction={BUDGET_INFO_HIDE} key="budget-table">
              <ScheduleBudgetTable relevantLocations={scheduleLocationFilter} />
            </FeatureWrapper>
          )}
          {showEventsTable && <EventsTable />}
        </div>
      </div>
    );
  }
}

ScheduleBetaModeView.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  scheduleState: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  eventsTableEnabled: PropTypes.bool,
  showBudgetTable: PropTypes.bool,
  displayMode: PropTypes.string,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ScheduleBetaModeView;
