import PropTypes from 'prop-types';
import { memo } from 'react';

import { SINGLE_DAY_COL_SPAN } from '@/constants/scheduleStyleChangePoints';

import ScheduleItemSelectMarker from '../ScheduleItemSelectMarker/ScheduleItemSelectMarker.redux';
import ScheduleTableDropArea from '../ScheduleTableDropArea/ScheduleTableDropArea.redux';
import ScheduleTableItemBackground from '../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';

import './ScheduleTableSingleDayItem.scss';

const ScheduleTableSingleDayItem = ({ date, employeeId, location, children }) => (
  <td className="scheduleTableSingleDayItem" colSpan={SINGLE_DAY_COL_SPAN}>
    <ScheduleTableItemBackground date={date} />
    <ScheduleTableDropArea date={date} employeeId={employeeId} />
    <ScheduleItemSelectMarker date={date} employeeId={employeeId} locationId={location.id} />
    {children}
  </td>
);

ScheduleTableSingleDayItem.propTypes = {
  date: PropTypes.string,
  employeeId: PropTypes.string,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedDisplayMode: PropTypes.shape({
    type: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default memo(ScheduleTableSingleDayItem);
