import { FormattedMessage } from 'react-intl';

import './EditDisabledView.scss';

const EditDisabledView = () => (
  <div className="lock">
    <i className="k-icon k-icon-lock_circle_close" />
    <FormattedMessage id="kadroGrid.disabledEdit.blockedMessage" defaultMessage="Edycja zablokowana" />
  </div>
);

export default EditDisabledView;
