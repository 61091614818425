import { connect } from 'react-redux';

import { exportEmployeeScheduleData } from '@/actions/exports.js';

import EmployeeScheduleButtonBar from './EmployeeScheduleButtonBar';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  currentUserId: state.reducer.currentUser.user.id,
  scheduleSettings: state.reducer.scheduleUIState.settings,
});

const mapDispatchToProps = {
  exportEmployeeScheduleData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeScheduleButtonBar);
