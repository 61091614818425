import PropTypes from 'prop-types';
import { memo } from 'react';

import TabNav from './common/TabNav.jsx';
import TopBar from './TopBar.jsx';

const TopBarCompanyManage = ({ activePath, filteredRoutes }) => (
  <TopBar leftElements={[<TabNav key="tabNav" activePath={activePath} routes={filteredRoutes} />]} rightElements={[]} />
);

TopBarCompanyManage.propTypes = {
  activePath: PropTypes.string,
  filteredRoutes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(TopBarCompanyManage);
