import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { showConfirmModal } from '@/actions';
import { hideModal } from '@/actions/uiState';
import { MANAGER_NOTE_MODAL } from '@/constants/modalTypes';
import { useInputChange } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { createUpdateManagerNote, deleteManagerNote } from '@/redux-store/managerNotes';
import { selectManagerNote } from '@/redux-store/managerNotes/managerNotes.selectors';
import { selectLocationById } from '@/redux-store/userLocations';
import { selectIsModalOpen, selectModalObject } from '@/selectors/uiState.selectors';

import { MangerNoteModalObject } from './ManagerNoteModal.types';
import { getFooterOptions, getInitialState, messages } from './ManagerNoteModal.utils';

export const useManagerNoteModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen(MANAGER_NOTE_MODAL));
  const modalObject = useAppSelector(selectModalObject<MangerNoteModalObject>(MANAGER_NOTE_MODAL));
  const managerNote = useAppSelector(selectManagerNote(modalObject.locationId, modalObject.date));
  const locationName = useAppSelector(selectLocationById(modalObject.locationId))?.name;

  const [state, { changeInput, setInitState }] = useInputChange(getInitialState(modalObject, managerNote));

  const isEdited = !!managerNote?.id;

  const onCloseModal = useCallback(() => {
    setInitState();
    dispatch(hideModal());
  }, [dispatch, setInitState]);

  const onSubmitModal = useCallback(() => {
    dispatch(createUpdateManagerNote(state));
    onCloseModal();
  }, [dispatch, onCloseModal, state]);

  const onDelete = useCallback(() => {
    onCloseModal();
    dispatch(
      showConfirmModal({
        title: intl.formatMessage(messages.deleteText),
        description: intl.formatMessage(messages.deleteDescriptionText),
        confirmText: intl.formatMessage(messages.deleteConfirmText),
        confirmFunc: () => dispatch(deleteManagerNote(managerNote)),
      }),
    );
  }, [dispatch, intl, managerNote, onCloseModal]);

  const footerOptions = useMemo(() => getFooterOptions(isEdited, intl, onDelete), [isEdited, intl, onDelete]);

  return { changeInput, footerOptions, intl, isEdited, isModalOpen, locationName, onCloseModal, onSubmitModal, state };
};
