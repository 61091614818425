import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import { useAppSelector } from '@/redux-store';
import { selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { isBeforeTimestamp } from '@/utils/dateHelper';

const ScheduleTableWeatherPreview = ({
  numberOfDaysInSchedule,
  locationCoordinatesExist,
  weatherForecast,
  city,
  date,
  today,
}) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const showTemperature = useMemo(() => numberOfDaysInSchedule < 14, [numberOfDaysInSchedule]);
  const showPrecipitation = useMemo(() => numberOfDaysInSchedule < 5, [numberOfDaysInSchedule]);
  const isDayFromThePast = isBeforeTimestamp(date, today, 'day');
  const noWeatherClassNames = classnames(
    'k-weatherRow__previewIcon',
    isDayFromThePast ? 'k-weatherRow__previewIcon--gray' : 'k-weatherRow__previewIcon--orange',
  );
  const previewContainerClassNames = classnames('k-weatherRow__previewContainer', {
    'k-weatherRow__previewContainer--centered':
      (!showTemperature && !showPrecipitation) || !locationCoordinatesExist || !weatherForecast,
    'k-weatherRow__previewContainer--singleDay': isSingleDay,
  });
  const iconClassNames = classnames('k-weatherRow__previewIcon ', 'k-weatherRow__previewIcon--orange', {
    'k-weatherRow__previewIcon--higher': isSingleDay,
  });

  const { minTemp, maxTemp, chanceForPrecipitation, weatherType, description } = weatherForecast || '';

  if (!locationCoordinatesExist)
    return (
      <div className={previewContainerClassNames}>
        <Icon
          type="material-outlined"
          className="k-weatherRow__previewIcon k-weatherRow__previewIcon--orange"
          name="wrong_location"
        />
      </div>
    );
  if (!weatherForecast)
    return (
      <div className={previewContainerClassNames}>
        <Icon type="material-outlined" className={noWeatherClassNames} name="cloud_off" />
      </div>
    );
  return (
    <div className={previewContainerClassNames}>
      {showTemperature && (
        <div className="k-weatherRow__temperatureContainer">
          <div className="k-weatherRow__temperature k-weatherRow__temperature--high">{maxTemp}°</div>
          <div className="k-weatherRow__temperature k-weatherRow__temperature--low">{minTemp}°</div>
          {showPrecipitation && (
            <div className="k-weatherRow__precipitation">
              <Icon
                type="material-outlined"
                className="k-weatherRow__previewIcon k-weatherRow__previewIcon--small"
                name="water_drop"
              />
              {chanceForPrecipitation}%
            </div>
          )}
        </div>
      )}
      <div className="k-weatherRow__iconAndCity">
        {isSingleDay && <span className="k-weatherRow__city">{city}</span>}
        <Icon type="material-symbols" className={iconClassNames} name={weatherType} />
      </div>
      {isSingleDay && <div className="k-weatherRow__description">{description}</div>}
    </div>
  );
};

ScheduleTableWeatherPreview.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableWeatherPreview.propTypes = {
  numberOfDaysInSchedule: PropTypes.number,
  locationCoordinatesExist: PropTypes.bool,
  weatherForecast: PropTypes.shape({
    locationId: PropTypes.number,
    date: PropTypes.string,
    weatherType: PropTypes.string,
    description: PropTypes.string,
    minTemp: PropTypes.number,
    maxTemp: PropTypes.number,
    chanceForPrecipitation: PropTypes.number,
    rainVolume: PropTypes.number,
    snowVolume: PropTypes.number,
  }),
  city: PropTypes.string,
  date: PropTypes.string,
  today: PropTypes.string,
};

export default ScheduleTableWeatherPreview;
