import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';
import { convertDateToStandardFormat,timestampsToWorkingHours } from '@/utils/dateHelper';

import { getStylesForOvertimeCollectionBlock } from './ScheduleSingleDayOvertimeCollection.helpers';

const ScheduleSingleDayOvertimeCollection = props => {
  const { overtimeCollection, dayHasAtLeastOneShift, showModal, employee } = props;
  const { start_timestamp: startTimestamp, end_timestamp: endTimestamp, id } = overtimeCollection;

  const className = classnames('k-shiftBlock', 'absence_block');

  const openModal = useCallback(() => {
    showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
      employee,
      absence: overtimeCollection,
      isOvertimeCollection: true,
      date: convertDateToStandardFormat(overtimeCollection.start_timestamp),
    });
  }, [employee, overtimeCollection]);

  const { top, left, width } = getStylesForOvertimeCollectionBlock(overtimeCollection, dayHasAtLeastOneShift);

  return (
    <div
      key={id}
      className={className}
      style={{ top, left, width: `${width}%` }}
      onClick={openModal}
      role="presentation"
    >
      <div id="shiftItem" className="sch_block__info">
        <span className="absence-name" role="presentation">
          {width < 5 ? (
            <FormattedMessage id="absences.overtimeCollectionShortcut" defaultMessage="ON" />
          ) : (
            <>
              <FormattedMessage id="absences.overtimeCollection" defaultMessage="Odbiór nadgodzin" />{' '}
              {timestampsToWorkingHours(startTimestamp, endTimestamp)}
            </>
          )}
        </span>
      </div>
      <Icon name="fact_check" />
    </div>
  );
};

ScheduleSingleDayOvertimeCollection.propTypes = {
  overtimeCollection: PropTypes.shape({
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    id: PropTypes.string,
  }),
  dayHasAtLeastOneShift: PropTypes.bool,
  showModal: PropTypes.func,
  employee: PropTypes.func,
};

export default memo(ScheduleSingleDayOvertimeCollection);
