import { PropTypes } from 'prop-types';

const FadedIcon = props => {
  const { icon, color } = props;
  return (
    <div className="k-fadedIcon">
      <i className="material-icons" style={{ color }}>
        {icon}
      </i>
    </div>
  );
};
FadedIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
};
export default FadedIcon;
