import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absencesWidgetTitle: {
    id: 'reportsView.reportWidgets.absences',
    defaultMessage: 'Nieobecności',
  },
  attendancesWidgetTitle: {
    id: 'reportsView.reportWidgets.attendances',
    defaultMessage: 'Obecni w pracy',
  },
  workedHoursWidgetTitle: {
    id: 'reportsView.reportWidgets.workedHours',
    defaultMessage: 'Suma godzin',
  },
  payoutSumWidgetTitle: {
    id: 'reportsView.reportWidgets.payoutSum',
    defaultMessage: 'Suma kosztów',
  },
  indicatorTooltipContent: {
    id: 'reportsView.indicator.tooltipContent',
    defaultMessage:
      '{isOneDay, select, true {Porównując do {previousFrom}} other {Porównując do {previousFrom} — {previousTo}}}',
  },
  widgetSubTitle: {
    id: 'reportsView.reportWidgets.widgetSubTitlee',
    defaultMessage: '{isEcpPayoutSettingsType, select, true {Realizacja/Plan} other {Plan}}',
  },
  planTitle: {
    id: 'reportsView.reportWidgets.planTitle',
    defaultMessage: 'Plan',
  },
  absencesTooltipMessage: {
    id: 'reportsView.reportWidgets.absencesTooltipMessage',
    defaultMessage: 'Liczba nieobecności, które zostały zatwierdzone oraz są płatne.',
  },
  attendancesTooltipMessage: {
    id: 'reportsView.reportWidgets.attendancesTooltipMessage',
    defaultMessage: 'Liczba obecnych pracowników\nw wyświetlanym okresie.',
  },
  workedHoursTooltipMessage: {
    id: 'reportsView.reportWidgets.workedHoursTooltipMessage',
    defaultMessage:
      'Suma godzin pracy zarejestrowana w wyświetlanym okresie. Są to zarówno godziny z tytułu obecności oraz nieobecności zatwierdzonych i płatnych.',
  },
  payoutTooltipMessage: {
    id: 'reportsView.reportWidgets.payoutTooltipMessage',
    defaultMessage:
      'Sumaryczny koszt pracy wyliczony na podstawie zarejestrowanych obecności oraz nieobecności zatwierdzonych i płatnych.',
  },
});
