import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'settings.userData.yourData',
    defaultMessage: 'Twoje dane',
  },
  name: {
    id: 'settings.userData.name',
    defaultMessage: 'Imię',
  },
  lastName: {
    id: 'settings.userData.lastName',
    defaultMessage: 'Nazwisko',
  },
  email: {
    id: 'settings.userData.email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'settings.userData.phone',
    defaultMessage: 'Telefon',
  },
  emailUsed: {
    id: 'settings.userData.emailUsed',
    defaultMessage: 'Podany e-mail został już użyty',
  },
});
