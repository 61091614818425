import PropTypes from 'prop-types';
import { useCallback } from 'react';

import ExportModalListElement from '@/components/common/exports/ExportModalListElement/ExportModalListElement';
import { createEvent } from '@/utils/inputHelpers.js';

import './ExportFileFormatSelectStep.scss';

const ExportFileFormatSelectStep = ({ formats, selectedFormat, handlers }) => {
  const handleClick = useCallback(
    exportId => {
      handlers.changeInput(createEvent('modalExportId', exportId));
    },
    [handlers.changeInput],
  );

  return (
    <div className="k-exportFileFormatSelectStep">
      {formats.map(({ exportId, fileIconText, title, description }) => (
        <ExportModalListElement
          key={exportId}
          text={fileIconText}
          onClick={() => handleClick(exportId)}
          isSelected={exportId === selectedFormat}
          title={title}
          description={description}
        />
      ))}
    </div>
  );
};

ExportFileFormatSelectStep.propTypes = {
  formats: PropTypes.arrayOf(
    PropTypes.shape({
      format: PropTypes.string,
      fileIconText: PropTypes.string,
      displayName: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  handlers: PropTypes.shape({
    changeInput: PropTypes.func,
  }),
  selectedFormat: PropTypes.string,
};

export default ExportFileFormatSelectStep;
