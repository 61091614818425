import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';

import ColorPicker from '@/components/common/ColorPicker.jsx';
import { AutocompleteInput, KadroMultiSelect, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { getAttachedEmployeesMessages } from '@/constants/attachedEmployees';
import { useInputChange, useMultiSelect } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import {
  createLocationObject,
  getChangedSelectedEmployees,
  getEmployeesOptions,
  getInitialState,
  getUpdatedSelectedEmployeesIds,
  getUpdatedStateForEdit,
  inputModifiers,
  modalModifiers,
} from './AddLocationModal.helpers';
import { messages } from './AddLocationModal.messages';

const AddLocationModal = (props, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState(), intl);
  const [employeesItems, multiSelectHandlers] = useMultiSelect(getEmployeesOptions(props.userEmployees));

  const attachedEmployeesMessages = useMemo(() => getAttachedEmployeesMessages(intl), []);
  const preselectedEmployeesIds = useMemo(
    () =>
      getUpdatedSelectedEmployeesIds(
        props.userEmployees,
        props.editedLocationId,
        props.currentUserId,
        props.isUserManager,
      ),
    [props.userEmployees, props.editedLocationId, props.visible],
  );

  const changeColor = useCallback(color => {
    handlers.changeInput(createEvent('color', color));
  }, []);

  const validateAutocomplete = () => {
    let validationError = '';
    if (!(state.coords.lat && state.coords.lng)) validationError = messages.invalidAddress;

    handlers.setError('address', validationError);

    return validationError;
  };

  const validateAll = async () => {
    const inputs = ['locationName', 'city', 'address', 'additionalInformation'];
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    validationResult.push(validateAutocomplete());
    return !Object.values(validationResult).some(err => err !== '');
  };

  const handleAddrSelect = ({ lat, lng, ...addr }, numberOfPredictions) => {
    const newState = {
      ...addr,
      coords: {
        lat,
        lng,
      },
      errors: {
        ...state.errors,
        address: '',
      },
    };

    if (!numberOfPredictions) newState.errors.address = intl.formatMessage(messages.noAddressFound);

    handlers.updateState(newState);
  };

  const clearModal = useCallback(() => {
    handlers.setInitState();
    multiSelectHandlers.deselectAll();
  }, []);

  const hideModal = useCallback(() => {
    props.toggleModal('locations');
  }, []);

  const handleBlur = value => {
    handlers.updateState({
      blurAutocomplete: value,
    });
  };

  const submit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    const attachedEmployees = getChangedSelectedEmployees(props.userEmployees, employeesItems, preselectedEmployeesIds);
    const locationObject = createLocationObject(state, attachedEmployees);
    if (props.editedLocationId) {
      props.prepareChangeLocation({ ...locationObject, id: props.editedLocationId });
    } else {
      props.prepareAddLocation(locationObject);
    }
    hideModal();
  }, [state, props.editedLocationId, props.userEmployees, employeesItems, preselectedEmployeesIds]);

  useEffect(() => {
    if (state.city && state.address) {
      validateAutocomplete();
    }
  }, [state.city, state.address, state.coords]);

  useEffect(() => {
    if (state.blurAutocomplete) validateAutocomplete();
  }, [state.blurAutocomplete]);

  useEffect(() => {
    if (state.defaultAddress && !state.coords.lat && !state.coords.lng) {
      handlers.setError('address', messages.invalidDefaultAddress);
    }
  }, [state.defaultAddress]);

  useEffect(() => {
    multiSelectHandlers.setActiveItems(preselectedEmployeesIds);
  }, [preselectedEmployeesIds]);

  useEffect(() => {
    if (!props.editedLocationId) return;
    const newState = { ...state, ...getUpdatedStateForEdit(props.editedLocationId, props.userLocations) };
    handlers.updateState(newState);
  }, [props.editedLocationId]);

  return (
    <MDKadroModal
      show={props.visible}
      onHide={hideModal}
      onHideEnd={clearModal}
      title={intl.formatMessage(messages[props.editedLocationId ? 'editLocation' : 'addLocation'])}
      modifiers={modalModifiers}
      onSubmit={submit}
    >
      <MDTextInput
        value={state.locationName}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        id="locationName"
        label={intl.formatMessage(messages.locationName)}
        errorMessage={state.errors.locationName}
        modifiers={inputModifiers}
      />
      <AutocompleteInput
        id="address"
        defaultValue={state.defaultAddress}
        onSelect={handleAddrSelect}
        onFocus={() => handleBlur(false)}
        onBlur={() => handleBlur(true)}
        label={intl.formatMessage(messages.address)}
        errorMessage={state.errors.address}
        modifiers={inputModifiers}
        deviceLocation={props.deviceLocation}
      />
      <MDTextInput
        value={state.additionalInformation}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        id="additionalInformation"
        label={intl.formatMessage(messages.additionalInformation)}
        errorMessage={state.errors.additionalInformation}
        modifiers={inputModifiers}
      />
      <fieldset className="form-group">
        <label htmlFor="nazwa" className="k-textInput__label k-textInput__label--side">
          {intl.formatMessage(messages.color)}
        </label>
        <ColorPicker color={state.color} colorFunc={changeColor} />
      </fieldset>
      <KadroMultiSelect messages={attachedEmployeesMessages} items={employeesItems} handlers={multiSelectHandlers} />
    </MDKadroModal>
  );
};

AddLocationModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddLocationModal.propTypes = {
  toggleModal: PropTypes.func,
  editedLocationId: PropTypes.string,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  visible: PropTypes.bool,
  isUserManager: PropTypes.bool,
  currentUserId: PropTypes.string,
  prepareAddLocation: PropTypes.func,
  prepareChangeLocation: PropTypes.func,
  deviceLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default AddLocationModal;
