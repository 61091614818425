import * as actionTypes from '@/constants/ActionTypes.js';

import { conn } from './index';

export const addAttendancesOvertimeSuccess = (attendancesOvertime, attendance) => ({
  type: actionTypes.ADD_ATTENDANCES_OVERTIME_SUCCESS,
  payload: {
    attendance,
    attendancesOvertime,
  },
});

export const addAttendancesOvertime = (attendancesOvertime, attendance) => async dispatch => {
  await conn.addAttendancesOvertime(attendancesOvertime).then(() => {
    dispatch(addAttendancesOvertimeSuccess(attendancesOvertime, attendance));
  });
};
