import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes';

import { getStylesForAbsenceBlock } from './ScheduleSingleDayAbsence.helpers';

const ScheduleSingleDayAbsence = props => {
  const { absence, hiddenShiftsForDate, shiftsForDate, absenceTypes, showModal } = props;
  const { absence_hours: absenceHours, type_id: typeId, status, id } = absence;

  const className = classnames('k-shiftBlock', 'absence_block', {
    'absence_block--draft': status === 'draft',
  });
  const absenceTypeName = absenceTypes.find(type => type.id === typeId)?.name || '';

  const openModal = useCallback(() => {
    showModal(ADD_ABSENCE_PROPOSAL_MODAL, absence);
  }, []);

  const absenceStyle = useMemo(
    () => getStylesForAbsenceBlock(absence, shiftsForDate, hiddenShiftsForDate),
    [absence, hiddenShiftsForDate, shiftsForDate],
  );

  return (
    <div key={id} className={className} style={absenceStyle} onClick={openModal} role="presentation">
      <div className="sch_block__info">
        <span className="absence-name" role="presentation">
          {absenceTypeName} {absenceHours && `${absenceHours.slice(0, 5)}-${absenceHours.slice(6)}`}
        </span>
      </div>
      <Icon name="fact_check" />
    </div>
  );
};

ScheduleSingleDayAbsence.propTypes = {
  absence: PropTypes.shape({
    absence_hours: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.string,
    from: PropTypes.string,
    type_id: PropTypes.string,
  }),
  shiftsForDate: PropTypes.arrayOf(PropTypes.shape({})),
  hiddenShiftsForDate: PropTypes.arrayOf(PropTypes.shape({})),
  shifts: PropTypes.shape({}),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  showModal: PropTypes.func,
};

export default memo(ScheduleSingleDayAbsence);
