import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { BUDGET_ESTIMATES_VIEW } from '@/constants/Permissions.js';
import { sumArrayOfNumbers } from '@/utils/arrayHelpers.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper';

const ScheduleBudgetLeftRow = props => {
  const {
    sumCosts,
    sumHours,
    sumRecommendedHours,
    relevantBudgetEstimates,
    showRecommendedSchedule,
    displayModeType,
    scheduleLocationFilter,
    selectedOptions,
  } = props;
  const allRecommendedHours = showRecommendedSchedule ? sumRecommendedHours.reduce((a, b) => a + b, 0) : undefined;
  const allCosts = sumCosts.reduce((a, b) => a + b.value, 0);
  const allHours = sumHours.reduce((a, b) => a + b.value, 0);
  const allEstimatedBudget = relevantBudgetEstimates.reduce(
    (a, b) => a + (b.estimated_budget ? parseFloat(b.estimated_budget / 100) : 0),
    0,
  );

  const allSpmhTargets = relevantBudgetEstimates.reduce((sum, estimate) => {
    if (Array.isArray(estimate.target_spmh)) {
      return sum + sumArrayOfNumbers(estimate.target_spmh);
    }

    return sum + (estimate.target_spmh || 0);
  }, 0);

  return (
    <>
      <tr>
        <td className="k-budgetTable__leftColumn">
          <div className="k-budgetTable__leftColumnData">
            <FormattedMessage id="schedule.budget.hours" defaultMessage="Godziny" />
            <span className="k-budgetTable__leftColumnDataValue">{parseMinutesToHumanForm(allHours)}</span>
          </div>
          <div className="k-budgetTable__leftColumnData">
            <FormattedMessage id="schedule.budget.costs" defaultMessage="Stawki" />
            <span className="k-budgetTable__leftColumnDataValue">{roundToTwoSigDigits(allCosts)} PLN</span>
          </div>
        </td>
      </tr>
      {showRecommendedSchedule && selectedOptions.includes('schedule') && (
        <tr>
          <td className="k-budgetTable__leftColumn">
            <div className="k-budgetTable__leftColumnData">
              <FormattedMessage id="schedule.budget.recommendedSchedule" defaultMessage="Prognozowany grafik" />
              <span className="k-budgetTable__leftColumnDataValue">{parseMinutesToHumanForm(allRecommendedHours)}</span>
            </div>
          </td>
        </tr>
      )}

      <FeatureWrapper
        permission={BUDGET_ESTIMATES_VIEW}
        hide={displayModeType === 'templates' || scheduleLocationFilter.length > 1}
      >
        {selectedOptions.includes('estimates') && (
          <tr>
            <td className="k-budgetTable__leftColumn">
              <div className="k-budgetTable__leftColumnData">
                <FormattedMessage id="schedule.budget.estimatedBudgetPlan" defaultMessage="Sprzedaż plan" />
                <span className="k-budgetTable__leftColumnDataValue">
                  {roundToTwoSigDigits(allEstimatedBudget)} PLN
                </span>
              </div>
            </td>
          </tr>
        )}
        {selectedOptions.includes('percentEstimates') && (
          <tr>
            <td className="k-budgetTable__leftColumn">
              <div className="k-budgetTable__leftColumnData">
                <FormattedMessage id="schedule.budget.plannedLabourLimit" defaultMessage="%COL Target" />
                <span className="k-budgetTable__leftColumnDataValue">
                  {`${roundToTwoSigDigits(
                    props.relevantBudgetEstimates.reduce((a, b) => {
                      const labourLimit = b.labour_limit ? b.labour_limit : 0;
                      return a + parseInt(labourLimit / 100);
                    }, 0) / props.relevantBudgetEstimates.length,
                  )} %`}
                </span>
              </div>
              <div className="k-budgetTable__leftColumnData">
                <FormattedMessage id="schedule.budget.realLabourLimit" defaultMessage="%COL Plan" />
                <span className="k-budgetTable__leftColumnDataValue">
                  {!allEstimatedBudget || !allCosts ? 0 : roundToTwoSigDigits((allCosts * 100) / allEstimatedBudget)} %
                </span>
              </div>
            </td>
          </tr>
        )}
        {selectedOptions.includes('sale') && (
          <tr>
            <td className="k-budgetTable__leftColumn">
              <div className="k-budgetTable__leftColumnData">
                <FormattedMessage id="schedule.budget.spmhTarget" defaultMessage="SPMH Target" />
                <span className="k-budgetTable__leftColumnDataValue">
                  {roundToTwoSigDigits(allSpmhTargets / props.relevantBudgetEstimates.length / 100)} PLN
                </span>
              </div>
              <div className="k-budgetTable__leftColumnData">
                <FormattedMessage id="schedule.budget.spmhPlan" defaultMessage="SPMH Plan" />
                <span className="k-budgetTable__leftColumnDataValue">
                  {!allEstimatedBudget || !allHours ? 0 : roundToTwoSigDigits(allEstimatedBudget / (allHours / 60))} PLN
                </span>
              </div>
            </td>
          </tr>
        )}
      </FeatureWrapper>
    </>
  );
};

ScheduleBudgetLeftRow.propTypes = {
  sumCosts: PropTypes.arrayOf(PropTypes.shape({})),
  sumHours: PropTypes.arrayOf(PropTypes.shape({})),
  relevantBudgetEstimates: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  displayModeType: PropTypes.string,
  sumRecommendedHours: PropTypes.arrayOf(PropTypes.number),
  showRecommendedSchedule: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
};

export default ScheduleBudgetLeftRow;
