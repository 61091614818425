import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';

import KadroSkeleton from '@/components/common/Skeleton/Skeleton';
import { HOUR_PLACEHOLDER } from '@/constants/strings';
import { EmployeePayroll } from '@/types';

const MAX_NUMBER_OF_SKELETONS = 20;

export const getSkeleton = () => <KadroSkeleton />;

export const getSkeletonData = () => {
  const skeleton = getSkeleton();
  return {
    date: skeleton,
    startHour: skeleton,
    bonus: skeleton,
    payout: skeleton,
    sumHours: skeleton,
  };
};

export const getSkeletonRows = () => {
  const skeletonRow = getSkeletonData();
  const skeletonRows = Array.from({ length: MAX_NUMBER_OF_SKELETONS }, () => skeletonRow);
  return skeletonRows;
};

export const getTableData = (data: EmployeePayroll[], showSkeletons: boolean) => {
  if (showSkeletons) return getSkeletonRows();

  const payrollData = data.map(({ date, startHour, endHour, sumHours, bonus, payout }) => ({
    date,
    startHour: `${startHour || HOUR_PLACEHOLDER} - ${endHour || HOUR_PLACEHOLDER}`,
    bonus: `${bonus.toFixed(2)} PLN`,
    payout: `${(payout / 100).toFixed(2)} PLN`,
    sumHours: parseMinutesToHumanForm(sumHours),
  }));

  return [...payrollData];
};
