import { memo } from 'react';

import { useSingleAccount } from './useSingleAccount';

export const SingleAccount = memo(() => {
  const { avatar, companyName, userShortName } = useSingleAccount();

  return (
    <div className="k-sideBar__profile k-sideBarProfile">
      <div className="k-sideBarProfile__profileElement">
        <div className="k-sideBarProfile__accountAvatar">
          {avatar && avatar.medium !== 'https://static.kadromierz.pl/avatars/placeholder-avatar-medium.png' ? (
            <img src={avatar.medium} alt="avatar" />
          ) : (
            <i className="material-icons">account_circle</i>
          )}
        </div>
        <div className="k-sideBarProfile__userDetails">
          <span className="m-t-xs k-sideBarProfile__name"> {userShortName} </span>
          <span className="text-xs k-sideBarProfile__company">{companyName}</span>
        </div>
      </div>
    </div>
  );
});

SingleAccount.displayName = 'SingleAccount';
