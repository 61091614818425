import {
  absencesHeaders,
  getAbsencesData,
  getDate,
  getWorkingHoursData,
  workingHoursCodes,
  workingHoursHeaders,
} from './helpers';

export const getGsnExportSheets = (rawData, mainDateStore, monthlyNorms, timeFormatType) => {
  const date = getDate(mainDateStore);
  const workingHoursData = getWorkingHoursData(rawData, mainDateStore, monthlyNorms, timeFormatType);
  const workingHoursSheet = [[date], workingHoursHeaders, workingHoursCodes, ...workingHoursData];

  const absencesData = getAbsencesData(rawData);
  const absencesSheet = [[date], absencesHeaders, ...absencesData];
  return [
    {
      data: workingHoursSheet,
      name: 'Czas pracy',
    },
    {
      data: absencesSheet,
      name: 'Nieobecności',
    },
  ];
};
