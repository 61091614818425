const weatherIconMap = {
  Sunny: 'sunny',
  Snow: 'cloudy_snowing',
  Clouds: 'cloud',
  PartlyCloudy: 'partly_cloudy_day',
  Thunderstorm: 'thunderstorm',
  Rain: 'rainy',
};

export const mapWeatherTypeToIcon = weatherType => weatherIconMap[weatherType] || 'partly_cloudy_day';

export const getLocationsWithCoordinates = (locationIds, userLocations) =>
  locationIds.reduce((agg, locationId) => {
    const location = userLocations.find(({ id }) => id === locationId);
    if (location && location.lat && location.lng) {
      return [...agg, locationId];
    }
    return agg;
  }, []);

export const mapWeatherDataToState = data => {
  let result = {};
  for (const locationId in data) {
    if (Object.hasOwn(data, locationId)) {
      const mappedForecast = data[locationId].reduce(
        (forecastAgg, forecastData) => ({
          ...forecastAgg,
          [forecastData.date]: {
            ...forecastData,
            minTemp: Math.round(forecastData.minTemp),
            maxTemp: Math.round(forecastData.maxTemp),
            weatherType: mapWeatherTypeToIcon(forecastData.weatherType),
            chanceForPrecipitation: Math.round([forecastData.chanceForPrecipitation] * 100),
          },
        }),
        {},
      );
      result = { ...result, [locationId]: mappedForecast };
    }
  }
  return result;
};
