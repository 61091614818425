import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sendPanelTitle: {
    id: 'schedule.sendPanel.title',
    defaultMessage: 'Publikuj Grafik',
  },
  publish: {
    id: 'publish',
    defaultMessage: 'Publikuj',
  },
});
