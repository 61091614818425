import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  priceList: {
    id: 'selectPackageContentFooter.priceList',
    defaultMessage: 'Zobacz pełny cennik',
  },
  next: {
    id: 'selectPackageContentFooter.next',
    defaultMessage: 'Dalej',
  },
  contact: {
    id: 'selectPackageContentFooter.contact',
    defaultMessage: 'Napisz do nas',
  },
});
