import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';

const PaymentModal = props => {
  const dateToPay = moment(props.invoice.payment_date);
  const daysLeft = dateToPay.diff(props.currentDate, 'days') + 1;

  return (
    <Modal show verticalCenter className="k-paymentsModal">
      <Modal.Body>
        <div className="title">
          <FormattedMessage id="payment.notification.title" defaultMessage="Twój termin płatności mija za" />
        </div>
        <div className="days">{daysLeft}</div>
        <div className="daysText">
          <FormattedMessage
            id="payment.notification.days"
            defaultMessage="{days, plural,
              one {dzień}
              few {dni}
              many {dni}
              other {dni}
            }"
            values={{ days: daysLeft }}
          />
        </div>
        <div className="desc">
          <FormattedMessage
            id="payment.notification.desc"
            defaultMessage="Termin Twojej płatności minie za {days} {days, plural,
              one {dzień}
              few {dni}
              many {dni}
              other {dni}
            }. Platforma Kadromierz będzie zablokowana dla Ciebie i dla Twoich pracowników do czasu uregulowania rachunku."
            values={{
              days: daysLeft,
            }}
          />
        </div>
        <div className="buttons">
          <Button
            modifiers="orange small"
            onClick={() => props.showInvoice(props.invoice.id).then(data => window.open(data.redirect_link))}
          >
            <FormattedMessage id="payment.notification.pay" defaultMessage="Zapłacę teraz" />
          </Button>
          <Button modifiers="reverse-orange small" onClick={() => props.toggleNotification(false)}>
            <FormattedMessage id="payment.notification.payLater" defaultMessage="Zapłacę później" />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

PaymentModal.defaultProps = {
  currentDate: moment(),
};

PaymentModal.propTypes = {
  invoice: PropTypes.shape({
    payment_date: PropTypes.string,
    id: PropTypes.number,
  }),
  showInvoice: PropTypes.func,
  toggleNotification: PropTypes.func,
  currentDate: PropTypes.shape({}),
};

export default PaymentModal;
