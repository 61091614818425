import * as AT from '@/constants/ActionTypes';
import { TALKJS_CHAT_ENABLE } from '@/constants/Permissions.js';

import { conn } from '../index';
import { getChatSubject } from './chat.helpers';
import { messages } from './chat.messages';

const createConversationError = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.CREATE_CONVERSATION_ERROR,
    notification: {
      title: intl.formatMessage(messages.createConversationError),
      type: 'error',
    },
  });
};

const setSelectedConversationId = conversationId => ({
  type: AT.SET_SELECTED_CONVERSATION_ID,
  payload: conversationId,
});

export const createConversation = selectedEmployees => async (dispatch, getState) => {
  const { currentUser } = getState().reducer;
  const participants = [...selectedEmployees.map(({ id }) => id), currentUser.user.id];
  const subject = getChatSubject(selectedEmployees, currentUser.user);
  try {
    const result = await conn.createConversation(participants, subject);
    dispatch(setSelectedConversationId(result.data.id));
  } catch (err) {
    dispatch(createConversationError());
  }
};

export const getChatSessionSignature = () => (dispatch, getState) => {
  const { userPermissions } = getState().reducer;
  if (!userPermissions.permissions.includes(TALKJS_CHAT_ENABLE)) return;
  conn.getChatSessionSignature().then(result => {
    const { signature } = result.data;
    dispatch({ type: AT.SET_CHAT_SIGNATURE, payload: signature });
  });
};

const getChatUsersError = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.GET_CHAT_USERS_ERRORS,
    notification: {
      title: intl.formatMessage(messages.fetchingChatUsersError),
      type: 'error',
    },
  });
};

export const getChatUsers = () => dispatch => {
  conn
    .getChatUsers()
    .then(result => {
      const { users } = result.data;
      dispatch({ type: AT.GET_CHAT_USERS, payload: users });
    })
    .catch(() => dispatch(getChatUsersError()));
};
