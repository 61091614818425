import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useDebounce } from '@/hooks';

import ExportEmptyState from './ExportEmptyState/ExportEmptyState';
import ExportItem from './ExportItem/ExportItem.redux';
import ExportPagination from './ExportPagination/ExportPagination.redux';

const ExportAllItems = props => {
  const { exportsItems, currentPage, numberOfItemsPerPage, changeExportsHistoryData } = props;
  const [deletedItem, setDeletedItem] = useState(null);
  const debouncedValue = useDebounce(deletedItem, 5000);

  useEffect(() => {
    if (!debouncedValue) return;
    changeExportsHistoryData(currentPage, numberOfItemsPerPage);
  }, [debouncedValue]);

  return (
    <>
      {exportsItems.length === 0 ? (
        <ExportEmptyState />
      ) : (
        <>
          {exportsItems.map(item => (
            <ExportItem item={item} key={item.id} setDeletedItem={setDeletedItem} />
          ))}
          <ExportPagination />
        </>
      )}
    </>
  );
};

ExportAllItems.propTypes = {
  exportsItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  currentPage: PropTypes.number,
  numberOfItemsPerPage: PropTypes.number,
  changeExportsHistoryData: PropTypes.func,
};

export default ExportAllItems;
