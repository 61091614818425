import moment from 'moment';

import {
  hoursSortingFunction,
  moneySortingFunction,
  payrollStatusSortingFunction,
} from '@/components/newPayrollViews/columns.helpers';
import { FREE_DAYS_MARKING_ENABLE, LABELS_MANAGE, OVERTIME_V2_ENABLE } from '@/constants/Permissions';
import {
  BUDGET_INFO_HIDE,
  FREEMIUM_HIDE_ABSENCES_VIEW,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
} from '@/constants/Restrictions.js';

import { filterMessages, messages } from './payrollTable.messages';

export const payrollTableColumnsOptions = [
  {
    accessor: 'date',
    cannotBeHidden: true,
    shouldBeHiddenForColumnFilter: true,
    width: 100,
    sticky: 'left',
    Cell: ({ row }) => <span>{moment(row.original.date).format('dd DD.MM')}</span>,
  },
  {
    accessor: 'freeDayMarkingName',
    permissions: [FREE_DAYS_MARKING_ENABLE],
    width: 100,
  },
  {
    accessor: 'from',
    width: 140,
  },
  {
    accessor: 'to',
    width: 140,
  },
  {
    accessor: 'workHours',
    width: 130,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'breaks',
    width: 100,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
    accessor: 'absence',
    width: 110,
  },
  {
    accessor: 'workTime',
    width: 120,
    sortingFunction: hoursSortingFunction,
  },
  {
    accessor: 'jobTitleName',
    width: 150,
  },
  {
    accessor: 'locationName',
    width: 150,
  },

  {
    accessor: 'nightWorkTime',
    width: 150,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'overtime50',
    shouldBeHiddenForColumnFilter: true,
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [],
    accessor: 'overtime100',
    shouldBeHiddenForColumnFilter: true,
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessor: 'potentialOvertime',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessor: 'fillUp',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'overtime_50_100',
    shouldBeHiddenInTable: true,
    columnAccessorsToShow: ['overtime50', 'overtime100'],
    hideForShiftsPayoutType: true,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'plan',
    width: 80,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'difference',
    width: 70,
    sortingFunction: hoursSortingFunction,
  },
  {
    restrictions: [BUDGET_INFO_HIDE, FREEMIUM_HIDE_ABSENCES_VIEW],
    accessor: 'bonusAmount',
    width: 70,
  },
  {
    restrictions: [BUDGET_INFO_HIDE],
    accessor: 'wage',
    width: 70,
    sortingFunction: moneySortingFunction,
  },
  {
    accessor: 'labels',
    width: 150,
    permissions: [LABELS_MANAGE],
  },
  {
    accessor: 'payout',
    width: 100,
    sortingFunction: moneySortingFunction,
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'warnings',
    id: 'warnings',
    width: 150,
    sticky: 'right',
  },
  {
    companySettings: ['payroll_approval_enabled'],
    accessor: 'status',
    width: 70,
    sticky: 'right',
    sortingFunction: payrollStatusSortingFunction,
  },
].map((option, index) => ({
  ...option,
  id: option.id || index + 1,
  title: messages[option.accessor],
  filterTitle: filterMessages[option.accessor] || messages[option.accessor],
  restrictions: option.restrictions || [],
  permissions: option.permissions || [],
}));

export const TIME_FORMAT = '__:__';
export const PAYROLL_HOURS_RANGE_PLACEHOLDER = `${TIME_FORMAT} / ${TIME_FORMAT}`;
export const PAYROLL_WORK_HOURS_PLACEHOLDER = `${TIME_FORMAT} - ${TIME_FORMAT}`;
