import { PERMISSIONS } from 'kadro-helpers/lib/helpers';
import mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AlignAttendancesMultipleDaysModal } from '@/components/attendance/AttendancesWeekView/AlignAttendancesMultipleDaysModal';
import PhotoPreviewModal from '@/components/common/PhotoPreviewModal/PhotoPreviewModal.redux';
import AddShiftModalContainer from '@/components/scheduler/modals/AddShiftModal/AddShiftModal.redux.js';
import EditAttendanceModalContainer from '@/containers/attendance/EditAttendanceModalContainer.js';
import ObjectHistoryModalContainer from '@/containers/common/ObjectHistoryModalContainer.js';
import { createAttendancesTableData } from '@/utils/attendanceHelpers';
import { getLocationIdsFromEmployeesWithCommonLocations } from '@/utils/locationsHelpers';
import { checkEmployeeIsManager } from '@/utils/userEmployeesHelpers';

import AcceptOvertimeModal from '../modals/AcceptOvertimeModal/AcceptOvertimeModal.redux.js';
import AttendanceMapModal from '../modals/AttendanceMapModal/AttendanceMapModal.redux.jsx';
import CreateAttendanceModal from '../modals/CreateAttendanceModal/CreateAttendanceModal.redux.js';
import AttendancesTable from './AttendancesTable/AttendancesTable.redux.js';
import { messages } from './AttendancesWeekView.messages.js';
import { getColumns } from './columns.js';

import './AttendancesWeekView.scss';

const AttendancesWeekView = ({
  userEmployees,
  userLocations,
  jobtitleFilter,
  employmentConditionsFilter,
  mainDateStore,
  absences,
  mainDateMoveLeft,
  mainDateMoveRight,
  scheduleLocationFilter,
  attendancesSettings,
  permissions,
  currentUser,
  companyRoles,
  contracts,
  attendancesData,
  employeesNames,
  scheduleLoanedEmployees,
}) => {
  const intl = useIntl();
  useEffect(() => {
    mousetrap.bind(['left'], mainDateMoveLeft);
    mousetrap.bind(['right'], mainDateMoveRight);

    return () => {
      mousetrap.unbind(['left']);
      mousetrap.unbind(['right']);
    };
  }, [mainDateMoveLeft, mainDateMoveRight]);
  const hasManagerAccessToOtherLocations = useMemo(() => {
    const isManager = checkEmployeeIsManager(currentUser.user, companyRoles);
    return permissions.includes(PERMISSIONS.ATTENDANCES_FROM_OTHER_LOCATIONS_GET) && isManager;
  }, [currentUser, companyRoles, permissions]);
  const showLocationsColumn = scheduleLocationFilter.length > 1 || hasManagerAccessToOtherLocations;

  const columns = useMemo(
    () =>
      getColumns(showLocationsColumn).map(col => ({
        ...col,
        header: intl.formatMessage(messages[col.header]),
      })),
    [intl, showLocationsColumn],
  );

  const locations = hasManagerAccessToOtherLocations
    ? getLocationIdsFromEmployeesWithCommonLocations(userEmployees, userLocations)
    : userLocations.filter(loc => scheduleLocationFilter.includes(loc.id));
  const userLocationIds = userLocations.map(userLocation => userLocation.id);
  const days = mainDateStore.dateArray;
  const data = useMemo(
    () =>
      createAttendancesTableData(
        locations,
        days,
        userEmployees,
        jobtitleFilter,
        employmentConditionsFilter,
        absences,
        attendancesSettings,
        userLocationIds,
        hasManagerAccessToOtherLocations,
        contracts,
        attendancesData,
        employeesNames,
        scheduleLoanedEmployees,
      ),
    [
      locations,
      days,
      userEmployees,
      jobtitleFilter,
      employmentConditionsFilter,
      absences,
      attendancesSettings,
      userLocationIds,
      hasManagerAccessToOtherLocations,
      contracts,
      attendancesData,
      employeesNames,
      scheduleLoanedEmployees,
    ],
  );

  return (
    <>
      <EditAttendanceModalContainer />
      <ObjectHistoryModalContainer />
      <PhotoPreviewModal />
      <AlignAttendancesMultipleDaysModal />
      <AddShiftModalContainer />
      <AcceptOvertimeModal />
      <AttendanceMapModal />
      <div className="attendancesWeekView">
        <AttendancesTable days={data} columns={columns} />
      </div>
      <CreateAttendanceModal showDatePicker showEmployeePicker />
    </>
  );
};

AttendancesWeekView.propTypes = {
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  absences: PropTypes.shape({}),
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  attendancesSettings: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
};

export default AttendancesWeekView;
