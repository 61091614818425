import { connect } from 'react-redux';

import { deleteShiftRequest,showConfirmModal } from '@/actions';
import { showModal } from '@/actions/uiState.js';
import SchedulePositionTableRow from '@/components/scheduler/Position/SchedulePositionTableRow.jsx';

const mapStateToProps = state => ({
  holidays: state.reducer.calendarData.holidays,
  dateStore: state.reducer.mainDateStore,
  scheduleUIState: state.reducer.scheduleUIState,
  locationFilter: state.reducer.locationFilter,
  userLocations: state.reducer.userLocations,
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  showConfirmModal,
  deleteShiftRequest,
};
const SchedulePositionTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(SchedulePositionTableRow);

export default SchedulePositionTableRowContainer;
