import { connect } from 'react-redux';

import {
  changeSorting,
  deleteLocationConfirm,
  massDeleteLocationsConfirm,
  startEdit,
  toggleAllCheckboxes,
  toggleCheckbox,
  toggleModal,
} from '@/actions/companymanage';
import { trackIntercomEvent } from '@/actions/intercom';

import LocationsTable from './LocationsTable.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  locationsListUi: state.reducer.listsUi.locations,
  deviceInfo: state.reducer.deviceInfo,
  userPermissions: state.reducer.userPermissions,
  locationsLimitReached: state.reducer.companyLimitsUsage.locationsLimitReached,
});

const mapDispatchToProps = {
  toggleCheckbox,
  toggleAllCheckboxes,
  toggleModal,
  startEdit,
  changeSorting,
  massDeleteLocationsConfirm,
  deleteLocationConfirm,
  trackIntercomEvent,
};

const LocationsTableContainer = connect(mapStateToProps, mapDispatchToProps)(LocationsTable);

export default LocationsTableContainer;
