import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import AvatarInput from 'react-avatar-editor';
import { FormattedMessage } from 'react-intl';

import FileDrop from './inputs/FileDrop.jsx';
import Range from './inputs/Range.jsx';
import KadroModal from './KadroModal.jsx';

class AvatarEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 100,
      url: props.user.avatar ? props.user.avatar.large : null,
      changed: false,
    };
    bindClassFunctions(this);
  }
  changeZoom(e) {
    this.setState({ zoom: e });
  }
  importNew(file) {
    this.setState({ url: URL.createObjectURL(file), changed: true });
  }
  saveChanges() {
    if (this.state.changed) {
      const canvas = this.editor.getImage();
      const image = canvas.toDataURL('image/png');
      if (image) {
        this.props.changeCurrentUser({
          avatar: {
            content: image.split('base64,')[1],
            'mime-type': 'image/png',
          },
        });
      }
    }
    this.props.toggleMainModal();
  }
  render() {
    return (
      <div>
        <div className="k-avatarPreview" role="presentation" onClick={this.props.toggleMainModal}>
          <i className="material-icons k-avatarPreview__icon">settings</i>
          <div className="k-avatarPreview__overlay" />
          {this.props.user.avatar ? (
            <img src={this.props.user.avatar.large} alt="avatar" />
          ) : (
            <div className="k-avatarPreview__initials">
              {this.props.user.first_name.charAt(0).toUpperCase() + this.props.user.last_name.charAt(0).toUpperCase()}
            </div>
          )}
        </div>
        <KadroModal
          onSubmit={this.saveChanges}
          onHide={this.props.toggleMainModal}
          showModal={this.props.show}
          title={<FormattedMessage id="settings.userData.avatar" defaultMessage="Zdjęcie profilowe" />}
          confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
          className="kmodal--thin"
        >
          <div className="k-avatarEditor">
            <AvatarInput
              ref={ref => {
                this.editor = ref;
              }}
              image={this.state.url}
              width={290}
              height={290}
              border={0}
              color={[255, 255, 255, 0.8]}
              scale={this.state.zoom / 100}
              rotate={0}
              borderRadius={145}
            />
            <i className="material-icons k-avatarEditor__icon">zoom_in</i>
            <Range min={100} max={300} step={1} width="250px" onChangeFunc={this.changeZoom} value={this.state.zoom} />
            <FileDrop
              confirmText={<FormattedMessage id="common.changePhoto" defaultMessage="Zmień zdjęcie" />}
              maxSize={4}
              onChange={this.importNew}
              accept={['jpg', 'png']}
            />
          </div>
        </KadroModal>
      </div>
    );
  }
}
AvatarEditor.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      large: PropTypes.string,
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  changeCurrentUser: PropTypes.func,
  toggleMainModal: PropTypes.func,
  show: PropTypes.bool,
};
export default AvatarEditor;
