import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  shiftSelectName: {
    id: 'shift.select.name',
    defaultMessage: 'Stanowisko',
  },
  shiftCommentPlaceholder: {
    id: 'shift.comment.placeholder',
    defaultMessage: 'Treść komentarza',
  },
  note: {
    id: 'common.note',
    defaultMessage: 'Notatka',
  },
  notePlaceholder: {
    id: 'common.noteText',
    defaultMessage: 'Treść notatki',
  },
  time: {
    id: 'shift.time',
    defaultMessage: 'Czas',
  },
  blockadesMessage: {
    id: 'shift.blockadesMessage',
    defaultMessage: 'Pracownik posiada zaplanowaną zmianę na innym profilu w godzinach:',
  },
  blockadesMessageDatesPassesMidnight: {
    id: 'shift.blockadesMessageDatesPassesMidnight',
    defaultMessage: 'od {startHour} w dniu {startDate} do {endHour} w dniu {endDate}',
  },
  blockadesMessageDates: {
    id: 'shift.blockadesMessageDates',
    defaultMessage: '{startHour} - {endHour} w dniu {date}',
  },
});
