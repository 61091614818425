import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'payrollTableOptions.edit',
    defaultMessage: 'Edytuj',
  },
  editBlocked: {
    id: 'payrollTableOptions.editBlocked',
    defaultMessage: 'Aby wprowadzić modyfikacje odblokuj tryb edycji klikając w ikonkę ',
  },
  absenceHistory: {
    id: 'payrollTableOptions.history',
    defaultMessage: 'Podgląd wniosku',
  },
  acceptOvertime: {
    id: 'payrollTableOptions.acceptOvertime',
    defaultMessage: 'Zatwierdź nadgodziny',
  },
  addShift: {
    id: 'payrollTableOptions.addShift',
    defaultMessage: 'Dodaj zmianę',
  },
  addAttendance: {
    id: 'payrollTableOptions.addAttendance',
    defaultMessage: 'Dodaj obecność',
  },
  addAbsence: {
    id: 'payrollTableOptions.addAbsence',
    defaultMessage: 'Dodaj urlop',
  },
});
