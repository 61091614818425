import { useState } from 'react';

const useDelayFunction = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const delayFunction = (callback, delay = 500) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    setTimeout(async () => {
      callback();
      setIsSubmitting(false);
    }, delay);
  };

  return delayFunction;
};

export default useDelayFunction;
