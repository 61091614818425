import { connect } from 'react-redux';

import { deleteUserEvent } from '@/actions/';
import { showModal } from '@/actions/uiState';

import Component from './ScheduleEvent.jsx';

const mapStateToProps = (state, ownProps) => ({
  event: state.reducer.schedule.events.data[ownProps.eventId],
  userLocations: state.reducer.userLocations,
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  deleteUserEvent,
  showModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
