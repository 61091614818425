export const payrollLocationBriefHeaders = [
  'Pracownik',
  'Stanowiska',
  'Stawka',
  'Nieobecności',
  'Suma Godzin',
  'Godziny nocne',
  'Bonus',
  'Realizacja / Plan',
  'Różnica',
  'Nadgodziny 50%',
  'Nadgodziny 100%',
  'Nadgodziny ŚT',
  'Do wypłaty',
  'ID referencyjne',
];

export const payrollLocationBasicXLSHeaders = [
  'Imię',
  'Nazwisko',
  'Stanowiska',
  'Stawka',
  'Nieobecności',
  'Suma Godzin',
  'Godziny nocne',
  'Bonus',
  'Norma za okres',
  'Realizacja',
  'Plan',
  'Różnica',
  'Nadgodziny 50%',
  'Nadgodziny 100%',
  'Nadgodziny ŚT',
  'Do wypłaty',
  'ID referencyjne',
];

export const payrollLocationECPFullHeaders = [
  'Imie',
  'Nazwisko',
  'ID referencyjne',
  'Data',
  'Dzień tygodnia',
  'Lokalizacja',
  'Stanowisko',
  'Stawka',
  'Od Grafik',
  'Do Grafik',
  'Czas Grafik',
  'Od RCP',
  'Do RCP',
  'Czas RCP',
  'Od Obecność',
  'Do Obecność',
  'Czas Obecności',
  'Godziny nocne',
  'Bonus',
  'Różnica',
  'Przerwa',
  'Nadgodziny 50%',
  'Nadgodziny 100%',
  'Nadgodziny ŚT',
  'Do Wyplaty',
];

export const payrollLocationScheduleFullHeaders = [
  'Imie',
  'Nazwisko',
  'ID referencyjne',
  'Data',
  'Dzień tygodnia',
  'Lokalizacja',
  'Stanowisko',
  'Stawka',
  'Od Grafik',
  'Do Grafik',
  'Czas Grafik',
  'Godziny nocne',
  'Bonus',
  'Przerwa',
  'Nadgodziny 50%',
  'Nadgodziny 100%',
  'Nadgodziny ŚT',
  'Do Wyplaty',
];

export const payrollLocationShiftsUnlessFixedFullHeaders = [
  'Imie',
  'Nazwisko',
  'ID referencyjne',
  'Data',
  'Dzień tygodnia',
  'Lokalizacja',
  'Stanowisko',
  'Stawka',
  'Od Grafik',
  'Do Grafik',
  'Czas Grafik',
  'Od RCP',
  'Do RCP',
  'Czas RCP',
  'Od Obecność',
  'Do Obecność',
  'Czas Obecności',
  'Godziny nocne',
  'Bonus',
  'Różnica',
  'Przerwa',
  'Nadgodziny 50%',
  'Nadgodziny 100%',
  'Nadgodziny ŚT',
  'Do Wyplaty',
];

export const absencesSummaryXLSHeaders = ['Nieobecność', 'Kod nieobecności', 'Czas trwania nieobecności'];
