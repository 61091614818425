import classnames from 'classnames';
import { PropTypes } from 'prop-types';

const Header = props => {
  const disabledEditOverlayClassnames = classnames('kadroGrid__disabledEditOverlay', {
    'kadroGrid__disabledEditOverlay--full': props.columnsNo === 1,
    'kadroGrid__disabledEditOverlay--small': props.children?.length < 7,
  });

  return (
    <div
      className={`kadroGrid__header ${props.className || ''}`}
      ref={props.headerRef}
      style={{
        borderLeft: props.fixedColumn ? 'none' : '1px solid #ddd',
      }}
    >
      <table style={{ minWidth: `${props.width}px` }}>
        {props.columnGroup ? props.columnGroup : null}
        {props.children}
        {!props.showEmptyState && !props.hideOverlay && (
          <div className={disabledEditOverlayClassnames} style={{ ...props.disabledEditOverlayStyle, top: '60px' }} />
        )}
      </table>
    </div>
  );
};

Header.propTypes = {
  fixedColumn: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.number,
  columnGroup: PropTypes.shape({}),
  headerRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  columnsNo: PropTypes.number,
  disabledEditOverlayStyle: PropTypes.shape({}),
  showEmptyState: PropTypes.bool,
  hideOverlay: PropTypes.bool,
};
export default Header;
