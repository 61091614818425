import { memo } from 'react';

import { UseSideBarValuesType } from '../useSideBar';
import { LogoLoader } from './logoLoader/LogoLoader';
import { useSideBarHeader } from './useSideBarHeader';

import './SideBarHeader.scss';

type Props = {
  closeMobileMenu: UseSideBarValuesType['closeMobileMenu'];
  toggleMobileMenu: UseSideBarValuesType['toggleMobileMenu'];
};

export const SideBarHeader = memo(({ closeMobileMenu, toggleMobileMenu }: Props) => {
  const { historyBack } = useSideBarHeader(closeMobileMenu);

  return (
    <div className="k-sideBar__header">
      <button className="k-sideBar__backButton" onClick={historyBack} type="button">
        <i className="material-icons">keyboard_arrow_left</i>
      </button>
      <div className="k-sideBar__logoContainer">
        <LogoLoader />
        <img className="k-sideBar__logoText" src="/img/logo3.png" alt="logo" />
      </div>
      <button className="k-sideBar__mobileMenu" onClick={toggleMobileMenu} type="button">
        <i className="material-icons">menu</i>
      </button>
    </div>
  );
});

SideBarHeader.displayName = 'SidebarHeader';
