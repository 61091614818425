import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import { MDTooltip } from '@/components/common/inputs/MDComponents.jsx';
import SearchBox from '@/components/common/inputs/SearchBox/SearchBox.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { ATTENDANCE_ABSENCE } from '@/constants/attendanceDetailTypes.js';
import { ACCEPT_OVERTIME_MODAL, ADD_ATTENDANCE_MODAL, ALIGN_ATTENDANCES_MODAL_WEEK } from '@/constants/modalTypes.js';
import {
  ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE,
  ATTENDANCE_EDIT_OTHERS_DISABLE,
  MANAGER_EDIT_OWN_ATTENDANCES,
} from '@/constants/Restrictions.js';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers.js';

import AttendancesSettingsButton from '../../AttandanceButtonBar/attendancesSettingsButton/AttendancesSettingsButton.redux.ts';
import AttendancesTableHeader from './AttendancesTableHeader/AttendancesTableHeader.jsx';
import { canEditOwnAvailabilities } from './AttendanceTableHelpers.js';
import AttendanceTableRow from './AttendanceTableRow.redux.js';
import messages from './messages.js';

import './AttendancesTable.scss';

const directions = ['ASC', 'DESC'];

const AttendancesTable = (
  {
    days,
    columns,
    showModal,
    currentUser,
    locationSettings,
    userPermissions,
    refreshData,
    companyRoles,
    createMassAttendances,
  },
  { intl },
) => {
  const [sortField, setSortField] = useState(null);
  const [direction, setDirection] = useState('ASC');
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [filteredDays, setFilteredDays] = useState(days.filter(day => day.rows.length > 0));
  const [isAddingAttendances, setIsAddingAttendances] = useState(false);

  const showAddAttendanceModal = useCallback(() => showModal(ADD_ATTENDANCE_MODAL), [showModal]);

  const currentUserId = currentUser.id;
  const currentUserRank = getEmployeeRank(currentUser, companyRoles);

  useEffect(() => {
    setFilteredDays(days.filter(day => day.rows.length > 0));
  }, [days]);

  const filterRows = (rows, searchString) =>
    rows.filter(({ employee }) => (searchString ? employee.toLowerCase().match(searchString.toLowerCase()) : true));

  const searchAttendances = searchString => {
    setFilteredDays(
      days
        .map(({ rows, ...rest }) => ({ rows: filterRows(rows, searchString), ...rest }))
        .filter(day => day.rows.length > 0),
    );
  };

  const handleHeaderClick = key => {
    if (sortField === key) {
      if (direction === directions[0]) {
        setDirection(directions[1]);
      } else if (direction === directions[1]) {
        setSortField(null);
        setDirection('ASC');
      } else {
        setDirection(directions[0]);
      }
    } else {
      setSortField(key);
      setDirection(directions[0]);
    }
  };

  const handleCheckboxChange = attendanceId => {
    if (selectedDataIds.includes(attendanceId)) {
      setSelectedDataIds(prevSelectedData => prevSelectedData.filter(id => id !== attendanceId));
    } else {
      setSelectedDataIds(prevSelectedData => [...prevSelectedData, attendanceId]);
    }
  };

  const compareElements = (a, b) => {
    if (a[sortField] < b[sortField]) {
      return direction === 'ASC' ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return direction === 'ASC' ? 1 : -1;
    }
    return 0;
  };

  days.forEach(day => {
    if (!sortField) return;
    day.rows.sort(compareElements);
  });

  const rows = useMemo(
    () =>
      days.reduce(
        (acc, day) => [
          ...acc,
          ...day.rows.map(({ attendanceObject, employeeObject, ...row }) => {
            const isEditOwnAttendancesDisabled =
              userPermissions.restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) &&
              currentUserId === employeeObject.id;

            const employeeRank = getEmployeeRank(employeeObject, companyRoles);
            const isEditOtherAttendancesDisabled =
              currentUserId !== employeeObject.id &&
              (userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) ||
                (userPermissions.restrictions.includes(ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE) &&
                  employeeRank >= currentUserRank));

            const isSingleEditDisabled = isEditOwnAttendancesDisabled || isEditOtherAttendancesDisabled;

            return {
              ...row,
              attendanceObject,
              employeeObject,
              id: attendanceObject.id,
              date: day.date,
              hasEarlyIn: attendanceObject.start_timestamp < attendanceObject.matching_shift?.start_timestamp,
              hasLateOut:
                attendanceObject.end_timestamp > attendanceObject.matching_shift?.end_timestamp ||
                !attendanceObject.matching_shift,
              shift: attendanceObject.matching_shift,
              disabled: isSingleEditDisabled,
            };
          }),
        ],
        [],
      ),
    [companyRoles, currentUserId, currentUserRank, days, userPermissions.restrictions],
  );
  const selectedData = useMemo(
    () => selectedDataIds.map(id => rows.find(row => row.id === id)).filter(Boolean),
    [selectedDataIds, rows],
  );

  const selectedShiftsWithoutAttendance = useMemo(
    () => selectedData.filter(row => row.type === ATTENDANCE_ABSENCE),
    [selectedData],
  );

  const notBlockedRows = rows.filter(row => {
    const employeeId = Number(row.employee.id);
    const isOwnAttendanceEditBlocked = canEditOwnAvailabilities(userPermissions, employeeId);

    if (!isOwnAttendanceEditBlocked) return false;

    const relevantSettings = locationSettings[row.location.id];
    const isBlocked =
      relevantSettings.disable_location_attendances_edit_until >= row.attendanceObject.date || row.disabled;

    return !isBlocked;
  });

  const handleCheckboxAllChange = () => {
    if (selectedData.length >= notBlockedRows.length) {
      setSelectedDataIds([]);
    } else {
      setSelectedDataIds(notBlockedRows.map(r => r.id));
    }
  };

  const handleAddAttendancesForShifts = async () => {
    setIsAddingAttendances(true);
    const attendancesToAdd = selectedShiftsWithoutAttendance.map(row => {
      const endDate =
        row.startTimeShift > row.endTimeShift ? moment(row.date).add(1, 'day').format('YYYY-MM-DD') : row.date;
      return {
        location_id: row.location.id,
        employee_id: row.employeeObject.id,
        start_timestamp: `${row.date} ${row.startTimeShift}:00`,
        end_timestamp: `${endDate} ${row.endTimeShift}:00`,
        draft: false,
      };
    });
    await createMassAttendances(attendancesToAdd, { addMatchingShifts: true });
    setSelectedDataIds([]);
    setIsAddingAttendances(false);
  };

  const selectedDataWithOvertime = selectedData.filter(
    ({ attendanceObject: attendance }) =>
      attendance.end_timestamp &&
      (!attendance.matching_shift ||
        attendance.start_timestamp < attendance.matching_shift.start_timestamp ||
        attendance.end_timestamp > attendance.matching_shift.end_timestamp),
  );

  const isEditOwnSelectedDisabled =
    userPermissions.restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) &&
    selectedData.some(data => String(data.employee.id) === String(currentUser.id));

  const isEditOthersSelectedDisabled =
    userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) &&
    selectedData.some(data => String(data.employee.id) !== String(currentUser.id));

  const isSelectedDataDisabled = isEditOwnSelectedDisabled || isEditOthersSelectedDisabled;
  const isEditButtonDisabled = isSelectedDataDisabled || selectedData.length === 0;
  const isAddAttendanceButtonDisabled =
    isAddingAttendances || isSelectedDataDisabled || selectedShiftsWithoutAttendance.length === 0;

  const tooltipClassName = 'attendancesTable__mdTootlip';
  return (
    <div className="attendancesTable">
      <div className="attendancesTable__buttonBar">
        <div className="attendancesTable__addTooltipAttendance">
          <MDTooltip withoutIcon className={tooltipClassName}>
            <Button modifiers="orange small" onClick={showAddAttendanceModal}>
              <i className="material-icons">add</i>
              <span> {intl.formatMessage(messages.createAttendance)}</span>
            </Button>
          </MDTooltip>
        </div>
        <MDTooltip text={intl.formatMessage(messages.refresh)} withoutIcon className={tooltipClassName}>
          <Button onClick={refreshData} modifiers="reverse-blue onlyIcon">
            <i className="material-icons">sync</i>
          </Button>
        </MDTooltip>
        <MDTooltip
          text={intl.formatMessage(messages.attendancesTableApproveOvertimeButton)}
          withoutIcon
          className={tooltipClassName}
        >
          <Button
            onClick={() =>
              showModal(ACCEPT_OVERTIME_MODAL, {
                selectedEmployeesAttendanceOvertimeData: selectedDataWithOvertime.map(
                  ({ attendanceObject, employeeObject, ...rest }) => ({
                    ...rest,
                    attendance: attendanceObject,
                    employee: employeeObject,
                  }),
                ),
              })
            }
            modifiers="reverse-blue onlyIcon"
            disabled={selectedDataWithOvertime.length === 0}
          >
            <i className="material-icons">more_time</i>
          </Button>
        </MDTooltip>
        <MDTooltip text={intl.formatMessage(messages.add)} withoutIcon className={tooltipClassName}>
          <Button
            modifiers="reverse-blue onlyIcon"
            onClick={handleAddAttendancesForShifts}
            disabled={isAddAttendanceButtonDisabled}
          >
            <i className="material-icons">add</i>
          </Button>
        </MDTooltip>

        <MDTooltip
          text={intl.formatMessage(messages.attendancesTableEditButton)}
          withoutIcon
          className={tooltipClassName}
        >
          <Button
            onClick={() =>
              showModal(ALIGN_ATTENDANCES_MODAL_WEEK, {
                relevantAttendances: {
                  startAttendances: selectedData.filter(i => i.type !== 'absence'),
                  endAttendances: selectedData.filter(i => i.type !== 'absence'),
                },
                selectedShiftsWithoutAttendancesForSelectedEmployees: selectedShiftsWithoutAttendance.map(item => ({
                  locationId: item.location.id,
                  employee: {
                    id: item.employeeObject.id,
                  },
                  detail: {
                    startTimestamp: `${item.date} ${item.startTimeShift}:00`,
                    endTimestamp: `${item.date} ${item.endTimeShift}:00`,
                  },
                })),
              })
            }
            modifiers="reverse-blue onlyIcon"
            disabled={isEditButtonDisabled}
          >
            <i className="material-icons">edit</i>
          </Button>
        </MDTooltip>

        <SearchBox changeSearchString={searchAttendances} />
        <AttendancesSettingsButton />
      </div>
      {filteredDays.length > 0 ? (
        filteredDays.map((day, index) => (
          <div className="attendancesTable__day" key={index}>
            <div className="attendancesTable__date">
              <span>{moment(day.date).format('DD/MM')}</span>
              <span>{moment(day.date).format('ddd')}</span>
            </div>
            <div className="attendancesTable__table">
              <table>
                {index === 0 && (
                  <AttendancesTableHeader
                    field={sortField}
                    direction={direction}
                    value={selectedData.length === rows.length}
                    columns={columns}
                    onClick={handleCheckboxAllChange}
                    onHeaderClick={handleHeaderClick}
                    isSelectAllBlocked={
                      notBlockedRows.length === 0 ||
                      userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE)
                    }
                  />
                )}
                <tbody>
                  {day.rows.map(row => {
                    const isEditOwnAttendancesDisabled =
                      userPermissions.restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) &&
                      currentUserId === row.employeeObject.id;

                    const employeeRank = getEmployeeRank(row.employeeObject, companyRoles);
                    const isEditOtherAttendancesDisabled =
                      currentUserId !== row.employeeObject.id &&
                      (userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) ||
                        (userPermissions.restrictions.includes('ATTENDANCE_EDIT_HIGHER_EQUAL:DISABLE') &&
                          employeeRank >= currentUserRank));

                    const isSingleEditDisabled = isEditOwnAttendancesDisabled || isEditOtherAttendancesDisabled;

                    return (
                      <AttendanceTableRow
                        row={row}
                        handleCheckboxChange={handleCheckboxChange}
                        columns={columns}
                        key={row.attendanceObject.id}
                        isSelected={selectedDataIds.includes(row.attendanceObject.id)}
                        isDisabled={isSingleEditDisabled}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ))
      ) : (
        <EmptyState
          imgSrc="/img/attendanceViewCTA.png"
          emptyStateHeader={intl.formatMessage(messages.attendancesTableEmptyStateHeader, {})}
          emptyStateText={intl.formatMessage(messages.attendancesTableEmptySearchResult, {})}
        />
      )}
    </div>
  );
};

AttendancesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendancesTable.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      employee: PropTypes.string,
      position: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      breaks: PropTypes.string,
      duration: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
    }),
  ),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.func,
  locationSettings: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    user_id: PropTypes.number,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
  refreshData: PropTypes.func,
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  createMassAttendances: PropTypes.func,
};

export default AttendancesTable;
