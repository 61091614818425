import { PropTypes } from 'prop-types';
import { Component } from 'react';

import DropArea from '@/containers/scheduler/DropAreaContainer.jsx';

class TemplatesDropArea extends Component {
  constructor(props) {
    super(props);
    this.cutDrop = this.cutDrop.bind(this);
    this.pasteDrop = this.pasteDrop.bind(this);
  }

  cutDrop(e) {
    const shift = JSON.parse(e.dataTransfer.getData('shift'));
    const { currentTemplate } = this.props.userTemplates;
    const shiftJobTitleId = shift.job_title_id || shift.job_title?.id;
    const shiftKey = `${shift.date}_${shift.working_hours}_${shiftJobTitleId}`;
    const filteredShifts = currentTemplate.shifts.filter(s => {
      const jobTitleId = s.job_title_id || s.job_title?.id;
      const key = `${s.date}_${s.working_hours}_${jobTitleId}`;
      return key !== shiftKey;
    });
    const jobTitle = this.props.userJobTitles.find(jobtitle => jobtitle.id === this.props.jobTitleID);
    const { date } = this.props;
    const newShift = {
      ...shift,
      job_title: jobTitle,
      job_title_id: this.props.jobTitleID,
      date,
      draft: true,
    };
    this.props.replaceTemplatesShifts([newShift], {
      ...currentTemplate,
      shifts: filteredShifts,
    });
  }

  pasteDrop(e) {
    const shift = JSON.parse(e.dataTransfer.getData('shift'));
    const { date, jobTitleID } = this.props;
    const { currentTemplate } = this.props.userTemplates;
    const jobTitle = this.props.userJobTitles.find(jobtitle => jobtitle.id === jobTitleID);
    const newShift = { ...shift, job_title: jobTitle, job_title_id: jobTitleID, date, draft: true };
    this.props.replaceTemplatesShifts([newShift], currentTemplate);
  }

  render() {
    return (
      <DropArea cut={this.cutDrop} paste={this.pasteDrop}>
        {this.props.children}
      </DropArea>
    );
  }
}

TemplatesDropArea.propTypes = {
  replaceTemplatesShifts: PropTypes.func,
  date: PropTypes.number,
  jobTitleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userJobTitles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({ shifts: PropTypes.arrayOf(PropTypes.shape({})) }),
  }),
  children: PropTypes.node,
  rowData: PropTypes.shape({
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default TemplatesDropArea;
