import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import ChatTopBar from './ChatTopBar';

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(ChatTopBar);
