import { updateRowStatistics } from '@/actions/schedule/rowStatistics';
import * as AT from '@/constants/ActionTypes';

import { calculateRowStatisticsForEmployees } from './scheduleRowStatistics.helpers';

let statsUpdateTimeout;
let employeeIdsToUpdate;

const scheduleRowStatisticsMiddleware = store => next => action => {
  let returnState;
  try {
    returnState = next(action);
  } catch (err) {
    console.error('Error when running action', err);
    returnState = store.getState();
  }

  // From this point we have data after action
  let employeeIds = [];
  switch (action.type) {
    case AT.ADD_SHIFT_SUCCESFUL:
    case AT.EDIT_SHIFT_SUCCESFUL:
    case AT.DELETE_SHIFT:
    case AT.ADD_ABSENCE_SUCCESS:
    case AT.EDIT_ABSENCE_SUCCESS:
    case AT.DELETE_ABSENCE_SUCCESS:
    case AT.DELETE_AVAILABILITY_SUCCESFUL:
      employeeIds = [action.payload.employee_id];
      break;
    case AT.CLOSE_TRADE_SHIFT_SUCCESFUL:
      employeeIds = [action.payload.employeeId];
      break;
    case AT.GET_SCHEDULE_SUCCESFUL:
    case AT.GET_SCHEDULE_SUCCESFUL_FOR_EMPLOYEE:
      employeeIds = action.payload.map(employee => employee.id);
      break;
    case AT.ADD_MASS_SHIFTS_SUCCESFUL:
    case AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS:
      employeeIds = action.payload.reduce((agg, shift) => {
        const employeeId = shift.employee.id;
        if (agg.includes(employeeId)) return agg;
        return [...agg, employeeId];
      }, []);
      break;
    case AT.ADD_SHIFTS_SUCCESFUL:
      employeeIds = action.newShifts.reduce((agg, shift) => {
        const employeeId = shift.employee.id;
        if (agg.includes(employeeId)) return agg;
        return [...agg, employeeId];
      }, []);
      break;
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
      employeeIds = Object.keys(action.payload);
      break;
    case AT.GET_ABSENCES_FOR_SCHEDULE:
      employeeIds = Object.keys(action.payload);
      break;
    case AT.ADD_AVAILABILITY_SUCCESFUL:
      employeeIds = [action.payload.employee.id];
      break;
    default:
      return returnState;
  }

  if (statsUpdateTimeout) {
    clearTimeout(statsUpdateTimeout);
    employeeIdsToUpdate = Array.from(new Set([...employeeIdsToUpdate, ...employeeIds]));
  } else {
    employeeIdsToUpdate = [...employeeIds];
  }

  statsUpdateTimeout = setTimeout(() => {
    const {
      userEmployees,
      userPermissions,
      userCustomTypes,
      absences,
      mainDateStore,
      calendarData,
      contracts,
      userJobTitles,
    } = store.getState().reducer;
    const { scheduleAbsences, absencesTypes } = absences;
    const employees = employeeIdsToUpdate.map(id => userEmployees.find(employee => employee.id === id));

    const stats = calculateRowStatisticsForEmployees(
      employees,
      userPermissions,
      mainDateStore,
      scheduleAbsences,
      absencesTypes,
      userCustomTypes,
      calendarData,
      contracts,
      userJobTitles,
    );

    const result = {};
    employeeIdsToUpdate.forEach((employeeId, i) => {
      result[employeeId] = stats[i];
    });

    store.dispatch(updateRowStatistics(result));

    employeeIdsToUpdate = [];
  }, 1000);

  return returnState;
};
export default scheduleRowStatisticsMiddleware;
