import {
  CHANGE_CUSTOM_SORTING,
  CHANGE_CUSTOM_SORTING_SUCCESS,
  CHANGE_EMPLOYEE_SORTING,
  GET_CUSTOM_SORTING_SUCCESS,
  START_CUSTOM_SORTING,
} from '@/constants/ActionTypes.js';

import { conn } from './index';

export const changeEmployeeSorting = sortingObject => ({
  type: CHANGE_EMPLOYEE_SORTING,
  payload: sortingObject,
});

export const changeCustomSorting = (locationId, changeData) => ({
  type: CHANGE_CUSTOM_SORTING,
  locationId,
  changeData,
});

export const changeEmployeesOrderForLocationSuccesful = () => ({
  type: CHANGE_CUSTOM_SORTING_SUCCESS,
});

export const startCustomSorting = () => (dispatch, getState) => {
  const { scheduleLocationFilter, userEmployees } = getState().reducer;
  const locationIds = scheduleLocationFilter;

  dispatch({
    type: START_CUSTOM_SORTING,
    locationIds,
    userEmployees,
  });
};

export const getEmployeesOrderForLocations = locationIds => dispatch => {
  conn.getEmployeesOrder().then(result => {
    const order = result.data;
    locationIds.forEach(location => {
      if (!order[location]) order[location] = {};
    });
    dispatch({
      type: GET_CUSTOM_SORTING_SUCCESS,
      payload: order,
    });
  });
};

export const changeEmployeesOrderForLocation = () => (dispatch, getState) => {
  const { employeeSorting, scheduleLocationFilter } = getState().reducer;
  const locationIds = scheduleLocationFilter;

  locationIds.forEach(locationId => {
    const data = employeeSorting.employeeOrders[locationId];
    conn
      .changeEmployeesOrderForLocation(locationId, { sort_order: { ...data } })
      .then(() => {
        dispatch(changeEmployeesOrderForLocationSuccesful());
      })
      .catch(err => {
        console.error(err);
      });
  });
};
