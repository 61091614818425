import { connect } from 'react-redux';

import { changeLocationFilter } from '@/actions/locationFilter.js';

import TopBarLocationFilter from './TopBarLocationFilter.jsx';

const mapStateToProps = state => ({
  locationFilter: state.reducer.locationFilter,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  changeLocationFilter,
};

const TopBarLocationFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarLocationFilter);

export default TopBarLocationFilterContainer;
