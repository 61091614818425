import { connect } from 'react-redux';

import { publishDraftTemplateShifts } from '@/actions/templates.js';
import { changeSendPanelVisibility } from '@/actions/uiState.js';

import ScheduleTemplatesSendPanel from './ScheduleTemplatesSendPanel';

const mapStateToProps = state => ({
  userTemplates: state.reducer.userTemplates,
  showSendPanel: state.reducer.uiState.showSendPanel,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
});
const mapDispatchToProps = {
  changeSendPanelVisibility,
  publishDraftTemplateShifts,
};
const ScheduleTemplatesSendPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleTemplatesSendPanel);

export default ScheduleTemplatesSendPanelContainer;
