import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import EmptyState from '@/components/emptyState/EmptyState';
import ChartHeader from '@/components/reports-NN/chartHeader/ChartHeader';
import { GREY_300, LIME_GREEN_500, PURPLE_900 } from '@/constants/colors';
import { CHART_MODIFIER } from '@/constants/modifiers';
import {
  CHART_MODES,
  LEGEND_WRAPPER_STYLE,
  LineType,
  MINUTES,
  STROKE_DASHARRAY,
  TICK_STYLE,
  TOOLTIP_CONTENT_STYLE,
} from '@/constants/reports';
import { ContextWithIntl } from '@/types/intl.types';
import { createFormattersForSelectedChartType } from '@/utils/reports';

import { messages as chartsMessages } from '../charts.messages';
import { prepareDataForPlannedAndWorkedChart } from './PlannedAndWorkedChart.helpers';
import { messages } from './plannedAndWorkedChart.messages';
import { usePlannedAndWorkedChart } from './usePlannedAndWorkedChart';

const XAxis_PADDING = { left: 30, right: 30 };

const PlannedAndWorkedChart = (_, { intl }: ContextWithIntl) => {
  const [chartMode, setChartMode] = useState(MINUTES);
  const { data, payoutSetting } = usePlannedAndWorkedChart();
  const preparedData = useMemo(
    () => prepareDataForPlannedAndWorkedChart(data, chartMode, payoutSetting.type),
    [data, chartMode, payoutSetting.type],
  );
  const { valueFormatter, yTickFormatter } = useMemo(
    () => createFormattersForSelectedChartType(chartMode),
    [chartMode],
  );

  const hasDataToShow = useMemo(
    () => preparedData.length && preparedData.some(({ planned, worked }) => planned !== 0 || worked !== 0),
    [preparedData],
  );

  const isEcpPayoutSettingsType = payoutSetting.type === 'ecp';

  return (
    <div className="k-plannedAndWorkedChart">
      <ChartHeader
        title={intl.formatMessage(messages.chartTitle, { isEcpPayoutSettingsType })}
        chartMode={chartMode}
        onChange={setChartMode}
        options={CHART_MODES}
        tooltipMessage={intl.formatMessage(messages.tooltipMessage)}
      />
      {hasDataToShow ? (
        <ResponsiveContainer width="100%" height="85.62%">
          <LineChart data={preparedData}>
            <CartesianGrid stroke={GREY_300} strokeDasharray={STROKE_DASHARRAY} />
            <XAxis dataKey="date" padding={XAxis_PADDING} stroke={GREY_300} tick={TICK_STYLE} />
            <YAxis tickFormatter={yTickFormatter} allowDecimals={false} />
            <Tooltip
              wrapperStyle={{ color: GREY_300 }}
              contentStyle={TOOLTIP_CONTENT_STYLE}
              formatter={valueFormatter}
            />
            <Legend wrapperStyle={LEGEND_WRAPPER_STYLE} />
            <Line
              type={LineType.MONOTONE}
              dataKey="planned"
              name={intl.formatMessage(messages.planLineName)}
              stroke={PURPLE_900}
              activeDot={{ r: 4 }}
              dot={{ r: 4 }}
              strokeWidth={2}
            />
            {isEcpPayoutSettingsType && (
              <Line
                type={LineType.MONOTONE}
                dataKey="worked"
                name={intl.formatMessage(messages.workedLineName)}
                stroke={LIME_GREEN_500}
                strokeWidth={2}
                activeDot={{ r: 6 }}
                dot={{ r: 4 }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <EmptyState
          name="scheduleCTA"
          imgSrc="/img/scheduleCTA.png"
          emptyStateHeader={intl.formatMessage(chartsMessages.emptyStateHeader)}
          emptyStateText={intl.formatMessage(chartsMessages.emptyStateText)}
          modifiers={[CHART_MODIFIER]}
        />
      )}
    </div>
  );
};

PlannedAndWorkedChart.contextTypes = {
  intl: PropTypes.shape({}),
};

export default PlannedAndWorkedChart;
