import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import Tile from './Tile.jsx';

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(Tile);
