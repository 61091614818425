import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import CustomFilter from '@/components/common/MDTable/CustomFilter/CustomFilter.jsx';
import MDTable from '@/components/common/MDTable/MDTable.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import TopBarAbsencesLimits from '@/components/TopBars/TopBarAbsencesLimits/TopBarAbsencesLimits.redux.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import AbsenceCalendarModal from '../modals/AbsenceCalendarModal/AbsenceCalendarModal.redux';
import AbsencesLimitsViewOptions from './AbsencesLimitsViewOptions/AbsencesLimitsViewOptions.redux';
import columnsData from './columns.json';
import employeeColumnsData from './employeeColumns.json';
import { messages } from './messages.js';
import { getTableData } from './utils.jsx';

import './AbsencesLimitsView.scss';

class AbsencesLimitsView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filters: {},
    };
    bindPrototypeFunctions(this);
    this.initDebounce();
  }

  componentDidMount() {
    if (this.props.isEmployee) {
      this.props.getAbsenceLimitsForEmployee(
        this.props.userId,
        this.props.mainDateStore.customDate.start,
        this.props.mainDateStore.customDate.end,
      );
    } else if (this.props.mainDateStore.dateMode !== this.props.selectedAbsence.limit_period) {
      this.props.mainDateChangeMode(this.props.selectedAbsence.limit_period);
    } else {
      this.updateData(1, this.props.absenceLimitsUsageTable.numberOfItemsPerPage);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedAbsence !== prevProps.selectedAbsence ||
      this.props.mainDateStore !== prevProps.mainDateStore ||
      this.props.userId !== prevProps.userId
    ) {
      if (!this.props.isEmployee) {
        if (this.props.mainDateStore.dateMode !== this.props.selectedAbsence.limit_period) {
          this.props.mainDateChangeMode(this.props.selectedAbsence.limit_period);
        } else {
          this.updateData(1, this.props.absenceLimitsUsageTable.numberOfItemsPerPage);
        }
      } else if (
        !(
          this.props.mainDateStore.customDate.start >= prevProps.mainDateStore.customDate.start &&
          this.props.mainDateStore.customDate.end <= prevProps.mainDateStore.customDate.end
        )
      ) {
        this.props.getAbsenceLimitsForEmployee(
          this.props.userId,
          this.props.mainDateStore.customDate.start,
          this.props.mainDateStore.customDate.end,
        );
      }
    }
  }

  getColumnFilter(column) {
    return column.showFilter ? (
      <CustomFilter
        value={this.state.filters[column.accessor] || ''}
        name={column.accessor}
        onChange={(name, value) => {
          this.setState(
            prevState => ({
              filters: {
                ...prevState.filters,
                [name]: value,
              },
            }),
            () => {
              if (column.accessor === 'employee') {
                this.filterByEmployee();
              }
            },
          );
        }}
      />
    ) : (
      <div />
    );
  }

  initDebounce() {
    this.filterByEmployee = this.props.isEmployee
      ? debounce(() => {
          this.props.getAbsenceLimitsForEmployee(
            this.props.userId,
            this.props.mainDateStore.customDate.start,
            this.props.mainDateStore.customDate.end,
          );
        }, 500)
      : debounce(() => {
          this.props.getAbsenceLimitsUsage(
            this.props.selectedAbsence,
            this.props.mainDateStore.customDate.start,
            this.props.mainDateStore.customDate.end,
            1,
            this.props.absenceLimitsUsageTable.numberOfItemsPerPage,
            this.state.filters.employee,
          );
        }, 500);
  }

  changeNumberOfItemsPerPage(event) {
    this.updateData(1, event.target.value);
  }

  changeCurrentPage(newPage) {
    this.updateData(newPage);
  }

  updateData(newPage, numberOfItemsPerPage = this.props.absenceLimitsUsageTable.numberOfItemsPerPage) {
    const { getAbsenceLimitsUsage, mainDateStore, selectedAbsence } = this.props;
    const { employee: employeeQueryString } = this.state.filters;

    getAbsenceLimitsUsage(
      selectedAbsence,
      mainDateStore.customDate.start,
      mainDateStore.customDate.end,
      newPage,
      numberOfItemsPerPage,
      employeeQueryString,
    );
  }

  render() {
    const {
      absencesTypes,
      absenceLimitsUsage,
      absenceLimitsForEmployee,
      absenceLimitsUsageTable,
      userEmployees,
      mainDateStore,
      isEmployee,
    } = this.props;
    const { currentPage, numberOfItemsPerPage, numberOfItems } = absenceLimitsUsageTable;
    const columns = columnsData.map(col => ({
      Header: this.context.intl.formatMessage(messages[col.Header]),
      accessor: col.accessor,
      Filter: this.getColumnFilter(col),
    }));

    const employeeColumns = employeeColumnsData.map(col => ({
      Header: this.context.intl.formatMessage(messages[col.Header]),
      accessor: col.accessor,
    }));

    return (
      <div className="absencesLimitsView">
        <AbsenceCalendarModal />
        <TopBarAbsencesLimits />
        <div className="absencesLimitsView__table">
          <MDTable
            data={getTableData(
              absenceLimitsUsage,
              absenceLimitsForEmployee,
              absencesTypes,
              userEmployees,
              mainDateStore,
              isEmployee,
              this.context.intl,
            )}
            columns={!this.props.isEmployee ? columns : employeeColumns}
            currentPage={currentPage}
            changeCurrentPage={this.changeCurrentPage}
            numberOfItemsPerPage={numberOfItemsPerPage}
            changeNumberOfItemsPerPage={this.changeNumberOfItemsPerPage}
            numberOfItems={numberOfItems}
            numberOfPages={Math.ceil(numberOfItems / numberOfItemsPerPage)}
            options={<AbsencesLimitsViewOptions />}
            tableEmpty={
              <div className="absencesLimitsView__emptyTable">
                <EmptyState
                  name="jobtitleCTA"
                  imgSrc="/img/jobtitlesCTA.png"
                  emptyStateHeader={this.context.intl.formatMessage(messages.absencesLimitsViewEmptyTable, {})}
                  shiftX="0"
                />
              </div>
            }
            withPagination={!this.props.isEmployee}
            disableSortBy
            withBigPadding
          />
        </div>
      </div>
    );
  }
}

AbsencesLimitsView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsencesLimitsView.propTypes = {
  selectedAbsence: PropTypes.shape({
    limit_period: PropTypes.string,
  }),
  absenceLimitsUsage: PropTypes.arrayOf(PropTypes.shape({})),
  absenceLimitsForEmployee: PropTypes.arrayOf(PropTypes.shape({})),
  absenceLimitsUsageTable: PropTypes.shape({
    currentPage: PropTypes.number,
    numberOfItems: PropTypes.number,
    numberOfItemsPerPage: PropTypes.number,
  }),
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    dateMode: PropTypes.string,
  }),
  getAbsenceLimitsUsage: PropTypes.func,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateChangeMode: PropTypes.func,
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  isEmployee: PropTypes.bool,
  getAbsenceLimitsForEmployee: PropTypes.func,
};

export default AbsencesLimitsView;
