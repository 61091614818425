import moment from 'moment';

import { Contract } from '@/types/contracts.types.ts';

export const getRelevantContractForDate = (
  contracts: { start_date?: string; end_date?: string }[],
  date: string,
): any =>
  contracts.find((contract) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const { start_date: startDate, end_date: endDate } = contract;
    return (!startDate || formattedDate >= startDate) && (!endDate || formattedDate <= endDate);
  });

export const getRelevantContractsForMultipleDates = (
  contracts: { start_date?: string; end_date?: string }[],
  from: string,
  to: string,
): any[] =>
  contracts.filter((contract) => {
    const formattedFrom = moment(from).format('YYYY-MM-DD');
    const formattedTo = moment(to).format('YYYY-MM-DD');

    const { start_date: contractStartDate, end_date: contractEndDate } = contract;
    return (
      (!contractStartDate || formattedTo >= contractStartDate) && (!contractEndDate || formattedFrom <= contractEndDate)
    );
  });

export const getJobTitleIdsFromContracts = (contracts: any[]) => [
  ...new Set(
    contracts.flatMap((contract) => contract.job_titles.map((contractJobTitle) => contractJobTitle.job_title_id)),
  ),
];

export const checkIfContractHasJobTitle = (contract: any, jobTitleId: string) =>
  contract?.job_titles.some((contractJobTitle) => contractJobTitle.job_title_id === jobTitleId);

export const findEmployeesWithDifferentJobTitlesInContractsForDateRange = (
  employeeIds: string[],
  dateRange: { start: string; end: string },
  contracts: Record<string, Contract[]>,
) => {
  if (!employeeIds.length) return [];
  return employeeIds.filter((id) => {
    const contractsForEmployee = contracts[id];
    if (contractsForEmployee.length === 1) return false;

    const filteredContractsByDate = getRelevantContractsForMultipleDates(
      contractsForEmployee,
      dateRange.start,
      dateRange.end,
    );

    const areJobTitlesDifferentLength = filteredContractsByDate.some(
      (obj) => obj.job_titles.length !== filteredContractsByDate[0].job_titles.length,
    );
    if (areJobTitlesDifferentLength) return true;

    const referenceJobTitleIds = filteredContractsByDate[0]?.job_titles.map((title) => title.job_title_id);

    return filteredContractsByDate.some((contract) =>
      contract.job_titles
        .map(({ job_title_id }) => job_title_id)
        .some((element) => !referenceJobTitleIds.includes(element)),
    );
  });
};

export const sortContractsByDates = (contracts: Contract[]): Contract[] =>
  [...contracts].sort((a, b) => {
    if (!a.start_date) {
      return -1;
    }
    if (!b.start_date) {
      return 1;
    }
    return a.start_date > b.start_date ? 1 : -1;
  });

export const adjustContractDates = (contracts: Contract[]): Contract[] =>
  contracts.map((contract, i) => {
    const nextContract = contracts[i + 1];
    const endDate =
      nextContract?.start_date && (contract.start_date || !contract.isTemp)
        ? moment(nextContract.start_date).subtract(1, 'day').format('YYYY-MM-DD')
        : null;

    return {
      ...contract,
      end_date: endDate,
    };
  });
