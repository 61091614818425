import { defineMessages } from 'react-intl';

export const filtersModalMessages = defineMessages({
  modalHeader: {
    id: 'reportsFiltersModal.header',
    defaultMessage: 'Wybierz pracowników',
  },
  show: {
    id: 'reportsFiltersModal.show',
    defaultMessage: 'Wyświetl',
  },
});
