import * as AT from '@/constants/ActionTypes.js';
import {
  ATTENDANCE_SETTINGS_TYPE_ARRAY,
  ATTENDANCES_SETTINGS,
  SHOW_ALL_ATTENDANCE,
} from '@/constants/attendanceDetailTypes';
import { kilometers2meters } from '@/utils/unitsHelpers.js';

const initialState = {
  locationSettings: {},
  usersSettings: {},
  attendancesSettings: ATTENDANCES_SETTINGS,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case AT.CHANGE_LOCATION_SETTINGS_SUCCESSFUL:
    case AT.GET_LOCATION_SETTINGS_SUCCESSFUL: {
      return {
        ...state,
        locationSettings: {
          ...state.locationSettings,
          [action.payload.id]: {
            ...state.locationSettings[action.payload.id],
            ...action.payload.settings,
            attendance_location_radius_limit: Number.isFinite(action.payload.settings.attendance_location_radius_limit)
              ? kilometers2meters(action.payload.settings.attendance_location_radius_limit)
              : state.locationSettings[action.payload.id]?.attendance_location_radius_limit,
          },
        },
      };
    }
    case AT.CHANGE_MASS_LOCATIONS_SETTINGS_SUCCESSFUL: {
      const newSettings = Object.keys(state.locationSettings).reduce((acc, cur) => {
        if (action.payload[cur]) {
          return { ...acc, [cur]: { ...state.locationSettings[cur], ...action.payload[cur] } };
        }
        return { ...acc, [cur]: state.locationSettings[cur] };
      }, {});
      return {
        ...state,
        locationSettings: newSettings,
      };
    }
    case AT.GET_USERS_SETTINGS_SUCCESFUL: {
      return {
        ...state,
        usersSettings: action.payload,
      };
    }
    case AT.SET_USERS_SETTINGS_SUCCESFUL: {
      const prevUserSettings = state.usersSettings[action.payload.userId] || {};
      return {
        ...state,
        usersSettings: {
          ...state.usersSettings,
          [action.payload.userId]: { ...prevUserSettings, [action.payload.name]: action.payload.data },
        },
      };
    }
    case AT.CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS: {
      return {
        ...state,
        locationSettings: {
          ...state.locationSettings,
          [action.payload.locationId]: {
            ...state.locationSettings[action.payload.locationId],
            bonus_hour_blocks: action.payload.hourBlocks,
            bonus_thresholds: action.payload.bonusThresholds,
          },
        },
      };
    }
    case AT.CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS_MASS: {
      const bonusThresholdsLocationIds = action.payload.bonusThresholds.map(item => item.location_id);
      const hourBlocksLocationIds = action.payload.hourBlocks.map(item => item.location_id);
      const locationIds = Array.from(new Set([...bonusThresholdsLocationIds, ...hourBlocksLocationIds]));
      const newLocationSettings = locationIds.reduce((result, locationId) => {
        const hourBlocks = action.payload.hourBlocks.filter(item => item.location_id === locationId);
        const bonusThresholds = action.payload.bonusThresholds.filter(item => item.location_id === locationId);

        return {
          ...result,
          [locationId]: {
            ...state.locationSettings[locationId],
            bonus_hour_blocks: hourBlocks,
            bonus_thresholds: bonusThresholds,
          },
        };
      }, {});

      return {
        ...state,
        locationSettings: {
          ...state.locationSettings,
          ...newLocationSettings,
        },
      };
    }

    case AT.TOGGLE_ATTENDANCE_SETTINGS: {
      const { id, value, type: attendanceType } = action.payload;
      const attendancesSettings = state.attendancesSettings.map(setting => {
        if (attendanceType === SHOW_ALL_ATTENDANCE && ATTENDANCE_SETTINGS_TYPE_ARRAY.includes(setting.type))
          return { ...setting, value: !value };

        if (setting.id === id) {
          return { ...setting, value: !setting.value };
        }

        return setting;
      });
      const hasUncheckedAttendanceSettings = attendancesSettings.some(
        set => ATTENDANCE_SETTINGS_TYPE_ARRAY.includes(set.type) && !set.value,
      );

      const finalAttendancesSettings = attendancesSettings.map(attendanceSetting => {
        if (attendanceSetting.type === SHOW_ALL_ATTENDANCE)
          return { ...attendanceSetting, value: !hasUncheckedAttendanceSettings };
        return attendanceSetting;
      });
      return { ...state, attendancesSettings: finalAttendancesSettings };
    }
    case AT.SELECT_ALL_ATENDANCES_SETTINGS: {
      const attendancesSettings = state.attendancesSettings.map(setting => ({ ...setting, value: true }));
      return { ...state, attendancesSettings };
    }
    case AT.DESELLECT_ALL_ATTENDNACES_SETTINGS: {
      const attendancesSettings = state.attendancesSettings.map(setting => ({ ...setting, value: false }));
      return { ...state, attendancesSettings };
    }
    default:
      return state;
  }
};

export default settings;
