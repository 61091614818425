/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ScheduleAbsenceBlock from '@/components/scheduler/ScheduleTable/ScheduleAbsenceBlock/ScheduleAbsenceBlock.jsx';
import ScheduleOvertimeCollection from '@/components/scheduler/ScheduleTable/ScheduleOvertimeCollection/ScheduleOvertimeCollection.redux.js';

import EmployeeScheduleShift from '../EmployeeScheduleShift/EmployeeScheduleShift.redux';
import {
  getAbsenceName,
  getEmployeeAbsences,
  getEmployeeOvertimeCollectionForDate,
  getEmployeeShifts,
  getEmployeeShiftsForOtherLocations,
  getSortedEmployeeShifts,
  hasEmployeeAbsence,
} from './EmployeeScheduleBlocks.helpers';

const EmployeeScheduleBlocks = (props, { intl }) => {
  const {
    employee,
    date,
    locationId,
    employeeOvertimeCollections = [],
    employeeAbsences = [],
    index,
    dateArray,
    absencesTypes,
    currentUserId,
  } = props;
  const shifts = useMemo(() => getEmployeeShifts(employee.shifts, date, locationId), [employee, date, locationId]);
  const shiftsForOtherLocations = useMemo(
    () => getEmployeeShiftsForOtherLocations(employee.shifts_for_other_locations, date, locationId),
    [employee.shifts_for_other_locations, date, locationId],
  );
  const overtimeCollectionsForDate = useMemo(
    () => getEmployeeOvertimeCollectionForDate(employeeOvertimeCollections, date),
    [employeeOvertimeCollections, date],
  );
  const absencesForDate = useMemo(() => getEmployeeAbsences(employeeAbsences, date, index), [
    employeeAbsences,
    date,
    index,
  ]);
  const sortedShifts = useMemo(() => getSortedEmployeeShifts(shifts, shiftsForOtherLocations), [
    shifts,
    shiftsForOtherLocations,
  ]);
  const allBlocksWithAbsences = useMemo(() => [...absencesForDate, ...overtimeCollectionsForDate, ...sortedShifts], [
    absencesForDate,
    sortedShifts,
  ]);
  const hasAbsence = useMemo(() => hasEmployeeAbsence(employeeAbsences, date), [employeeAbsences, date]);
  const additionalGap = hasAbsence && absencesForDate.length === 0 && sortedShifts.length !== 0;
  const isMonthView = dateArray.length > 10;
  const blockSize = isMonthView ? 'month' : 'week';
  const lastVisibleDate = dateArray[dateArray.length - 1];

  return (
    <>
      {additionalGap && <div className="k-absencePlaceholder" />}
      {allBlocksWithAbsences.map(block => {
        if (block.blockType === 'absence') {
          const absenceName = getAbsenceName(block, absencesTypes, isMonthView, currentUserId, intl);
          return (
            <ScheduleAbsenceBlock
              key={block.id}
              absence={block}
              absenceName={absenceName}
              isMonthView={isMonthView}
              date={date}
              lastVisibleDate={lastVisibleDate}
              size={blockSize}
              isEditDisabled
            />
          );
        }

        if (block.blockType === 'shift') {
          return <EmployeeScheduleShift key={block.id} shift={block} employee={employee} />;
        }

        if (block.blockType === 'overtimeCollection') {
          return (
            <ScheduleOvertimeCollection
              key={block.id}
              overtimeCollection={block}
              size={blockSize}
              isMonthView={isMonthView}
              isEditDisabled
              isEmployee
            />
          );
        }

        return null;
      })}
    </>
  );
};

EmployeeScheduleBlocks.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeScheduleBlocks.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    shifts_for_other_locations: PropTypes.arrayOf(PropTypes.shape({})),
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employeeOvertimeCollections: PropTypes.shape({}),
  locationId: PropTypes.string,
  date: PropTypes.string,
  index: PropTypes.number,
  employeeAbsences: PropTypes.arrayOf(PropTypes.shape({})),
  dateArray: PropTypes.arrayOf(PropTypes.string),
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  currentUserId: PropTypes.string,
};

export default EmployeeScheduleBlocks;
