import mousetrap from 'mousetrap';
import { useEffect } from 'react';

import { reportsDateMoveLeft, reportsDateMoveRight } from '@/actions/reports.js';
import ReportsWidgets from '@/components/reports-NN/ReportWidgets/ReportWidgets.redux';
import ReportsTopBar from '@/components/reports-NN/TopBar/ReportsTopBar.redux';
import { STICKY_MODIFIER } from '@/constants/modifiers.js';
import { useAppDispatch } from '@/redux-store';

import AbsencesAndAttendancesOrShiftsChart from '../Charts/AbsencesAndAttendancesOrShiftsChart/AbsencesAndAttendancesOrShiftsChart.tsx';
import AbsencesChart from '../Charts/AbsencesChart/AbsencesChart.tsx';
import JobTitlesChart from '../Charts/JobTitlesChart/JobTitlesChart.tsx';
import LocationsChart from '../Charts/LocationsChart/LocationsChart.tsx';
import PlannedAndWorkedChart from '../Charts/PlannedAndWorkedChart/PlannedAndWorkedChart.tsx';
import ReportsTable from '../ReportsTable/ReportsTable.tsx';

import './reportsView.scss';

const ReportsView = _props => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    mousetrap.bind('left', () => dispatch(reportsDateMoveLeft()));
    mousetrap.bind('right', () => dispatch(reportsDateMoveRight()));

    return () => {
      mousetrap.unbind('left');
      mousetrap.unbind('right');
    };
  }, [reportsDateMoveLeft, reportsDateMoveRight, dispatch]);
  return (
    <div className="k-reportsView">
      <ReportsTopBar className="k-reportsView__topBar" modifiers={[STICKY_MODIFIER]} />
      <div className="k-reportsView__content">
        <div className="k-reportsView__widgetRow">
          <ReportsWidgets />
        </div>
        <div className="k-reportsView__plannedAndWorkedChart">
          <PlannedAndWorkedChart />
        </div>
        <div className="k-reportsView__chartArea">
          <JobTitlesChart />
        </div>
        <div className="k-reportsView__chartArea">
          <LocationsChart />
        </div>
        <div className="k-reportsView__chartArea">
          <AbsencesAndAttendancesOrShiftsChart />
        </div>
        <div className="k-reportsView__chartArea">
          <AbsencesChart />
        </div>
        <div className="k-reportsView__tableArea">
          <ReportsTable />
        </div>
      </div>
    </div>
  );
};

export default ReportsView;
