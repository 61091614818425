export const getColumns = showLocation => [
  ...(showLocation
    ? [
        {
          header: 'attendancesTableLocation',
          accessor: 'locationName',
        },
      ]
    : []),
  {
    header: 'attendancesTableEmployee',
    accessor: 'employee',
  },
  {
    header: 'attendancesTableStatus',
    accessor: 'status',
    type: 'status',
  },
  {
    header: 'attendancesTablePosition',
    accessor: 'position',
  },
  {
    header: 'attendancesTableStart',
    accessor: 'start',
    type: 'time',
  },
  {
    header: 'attendancesTableEnd',
    accessor: 'end',
    type: 'time',
  },
  {
    header: 'attendancesTableBreaks',
    accessor: 'breaks',
    type: 'duration',
  },
  {
    header: 'attendancesTableDuration',
    accessor: 'duration',
    type: 'duration',
  },
  {
    header: 'attendancesTableOptions',
    accessor: 'options',
    type: 'options',
  },
  {
    header: 'attendancesTableAlerts',
    accessor: 'alerts',
    type: 'alerts',
  },
];
