import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';

const EmplyeesEditMassEmplConditionsField = ({ employmentConditions, onChange }) => {
  const [selected, setSelected] = useState(null);
  const options = employmentConditions.map(emplCond => ({ key: emplCond.name, value: emplCond.id }));

  useEffect(() => {
    if (selected) {
      const selectedCondition = employmentConditions.find(empCond => empCond.id === selected);
      onChange(selectedCondition);
    }
  }, [selected]);

  return (
    <MDSelect
      id="propertyType"
      options={options}
      defaultValue={selected}
      name={
        <FormattedMessage id="emplyees.editMass.emplConditionsName" defaultMessage="Szablon warunków zatrudnienia" />
      }
      onChange={value => {
        setSelected(value);
      }}
      closeOnClick
    />
  );
};

EmplyeesEditMassEmplConditionsField.propTypes = {
  employmentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

export default EmplyeesEditMassEmplConditionsField;
