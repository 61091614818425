import { connect } from 'react-redux';

import { addShift, addShiftsWithRepeat, editShift } from '@/actions';
import { addAbsence, getAbsenceLimitsForEmployee } from '@/actions/absences.js';
import { getShiftHistory } from '@/actions/history.js';
import {
  addOldOvertimeCollection,
  addOvertimeCollection,
  editOldOvertimeCollection,
  showDeleteOvertimeCollectionConfirmModal,
} from '@/actions/overtimeCollections/overtimeCollections.js';
import { toggleNonWorkingDays } from '@/actions/schedule.jsx';
import { showDeleteShiftConfirmModal } from '@/actions/schedule/shifts.js';
import { hideModal } from '@/actions/uiState';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes.js';
import { addFreeDays, editFreeDays, showDeleteFreeDayConfirmModal } from '@/redux-store/freeDaysMarking';

import AddShiftAndAbsenceModal from './AddShiftAndAbsenceModal.jsx';

const mapStateToProps = state => {
  const modalObject = state.reducer.uiState.modalObject[ADD_SHIFT_AND_ABSENCE_MODAL];
  return {
    showModal: state.reducer.uiState.showModal === ADD_SHIFT_AND_ABSENCE_MODAL,
    modalObject,
    mainDateStore: state.reducer.mainDateStore,
    selectedLocation: state.reducer.locationFilter.selectedLocation,
    userJobTitles: state.reducer.userJobTitles,
    userPermissions: state.reducer.userPermissions,
    userEmployees: state.reducer.userEmployees,
    nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
    employeeShifts: state.reducer.shifts.data[modalObject?.employee.id]?.shifts,
    scheduleAbsences: state.reducer.absences.scheduleAbsences,
    absenceTypes: state.reducer.absences.absencesTypes,
    countOnlyDaysWithShiftsSelectedByDefault:
      state.reducer.currentCompany.settings.count_only_days_with_shifts_selected_by_default,
    employeeContracts: state.reducer.contracts[modalObject?.employee.id],
    loanedEmployees: state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees,
  };
};

const mapDispatchToProps = {
  addShift,
  addShiftsWithRepeat,
  addFreeDays,
  editFreeDays,
  editShift,
  onHide: hideModal,
  getShiftHistory,
  toggleNonWorkingDays,
  addAbsence,
  addOvertimeCollection,
  addOldOvertimeCollection,
  editOldOvertimeCollection,
  showDeleteOvertimeCollectionConfirmModal,
  getAbsenceLimitsForEmployee,
  showDeleteShiftConfirmModal,
  showDeleteFreeDayConfirmModal,
};

const AddShiftAndAbsenceModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddShiftAndAbsenceModal);

export default AddShiftAndAbsenceModalContainer;
