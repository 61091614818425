import { useAppSelector } from '@/redux-store';
import { selectFreeDay } from '@/redux-store/freeDaysMarking';

import { ScheduleFreeDayProps } from './ScheduleFreeDay.types';

export const useScheduleFreeDay = ({ date, employeeId }: ScheduleFreeDayProps) => {
  const freeDay = useAppSelector(selectFreeDay(employeeId, date));

  const isFreeDayVisible = !!freeDay;

  return { freeDay, isFreeDayVisible };
};
