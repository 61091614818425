import {
  ADD_MASS_USER_EVENT_SUCCESFUL,
  ADD_USER_EVENT_SUCCESFUL,
  CHANGE_USER_EVENT_SUCCESFUL,
  DELETE_USER_EVENT_SUCCESFUL,
  GET_SCHEDULE_EVENTS_SUCCESFUL,
} from '@/constants/ActionTypes.js';
import { mergeArrayById } from '@/utils/arrayHelpers.js';

const initialState = [];

const userEvents = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_EVENTS_SUCCESFUL:
      return mergeArrayById(state, action.payload);
    case ADD_USER_EVENT_SUCCESFUL:
      return [...state, action.payload];
    case CHANGE_USER_EVENT_SUCCESFUL:
      return [...state.filter(e => e.id !== action.payload.id), action.payload];
    case DELETE_USER_EVENT_SUCCESFUL:
      return [...state].filter(e => e.id !== action.payload.id);
    case ADD_MASS_USER_EVENT_SUCCESFUL:
      return [...state, ...action.payload];
    default:
      return state;
  }
};

export default userEvents;
