/* eslint camelcase: 0 no-console: 0 */
import moment from 'moment';
import { defineMessages } from 'react-intl';

import { trackIntercomEvent } from '@/actions/intercom';
import { hideModal } from '@/actions/uiState';
import { attendanceView } from '@/actions/views';
import {
  ADD_ATTENDANCE_BONUS_SUCCESFUL,
  ADD_ATTENDANCE_ERROR,
  ADD_ATTENDANCE_SUCCESFUL,
  ADD_BREAK_SUCCESFUL,
  ADD_MASS_ATTENDANCE_SUCCESSFUL,
  ASSIGN_LABEL_TO_ATTENDANCE,
  ASSIGN_LABEL_TO_ATTENDANCE_ERROR,
  CHANGE_ATTENDANCE_SUCCESFUL,
  CHANGE_ATTENDANCES_MODE,
  CHANGE_BREAK_SUCCESFUL,
  CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL,
  CLOSE_ADD_ATTENDANCE_MODAL,
  DELETE_ATTENDANCE_SUCCESFUL,
  DELETE_BREAK_SUCCESFUL,
  END_ATTENDANCE_ERROR,
  END_ATTENDANCE_SUCCESFUL,
  END_BREAK_ERROR,
  END_BREAK_SUCCESFUL,
  SELECT_ALL_ATTENDANCES,
  SET_ATTENDANCES_SEARCH_STRING,
  SHOW_ADD_ATTENDANCE_MODAL,
  START_ATTENDANCE_ERROR,
  START_ATTENDANCE_SUCCESFUL,
  START_BREAK_ERROR,
  START_BREAK_SUCCESFUL,
  TOGGLE_ATTENDANCE_SELECT,
  UNASSIGN_LABEL_FROM_ATTENDANCE,
  UNASSIGN_LABEL_TO_ATTENDANCE_ERROR,
  UNSELECT_ALL_ATTENDANCES,
} from '@/constants/ActionTypes.js';
import browserHistory from '@/constants/browserHistory';
import { dateModes } from '@/constants/dateModes';
import { CHOOSE_LOCALIZATION_MODAL } from '@/constants/modalTypes.js';
import {
  checkIfAttendancesOverlap,
  computeTimestampsForNewAttendanceHours,
  formatAttendancesToAlign,
} from '@/utils/attendanceHelpers';
import { changeAttendanceForDemo } from '@/utils/demoHelpers.js';

import { addShift, conn, connectionError, showConfirmModal } from './index';
import { changeScheduleLocationFilter } from './schedule/scheduleLocationFilter.js';

const messages = defineMessages({
  startAttendanceSuccess: {
    id: 'attendances.startAttendanceSuccess',
    defaultMessage: 'Obecność została rozpoczęta',
  },
  startAttendanceError: {
    id: 'attendances.startAttendanceError',
    defaultMessage: 'Nie udało się rozpocząć obecności',
  },
  endAttendenceSuccess: {
    id: 'attendances.endAttendenceSuccess',
    defaultMessage: 'Obecność została zakończona',
  },
  endAttendenceError: {
    id: 'attendances.endAttendenceError',
    defaultMessage: 'Nie udało się zakończyć obecności',
  },
  startBreakSuccess: {
    id: 'breaks.startBreakSuccess',
    defaultMessage: 'Przerwa została rozpoczęta',
  },
  startBreakError: {
    id: 'breaks.startBreakError',
    defaultMessage: 'Nie udało się rozpocząć przerwy',
  },
  endBreakSuccess: {
    id: 'breaks.endBreakSuccess',
    defaultMessage: 'Przerwa została zakończona',
  },
  endBreakError: {
    id: 'breaks.endBreakError',
    defaultMessage: 'Nie udało się zakończyć przerwy',
  },
  changeAttendanceSuccesful: {
    id: 'success.changeAttendanceSuccesful',
    defaultMessage: 'Poprawnie zmieniono obecność',
  },
  attendanceSuccessTitle: {
    id: 'success.attendanceSuccessTitle',
    defaultMessage: 'Sukces!',
  },
  deleteAttendanceTitle: {
    id: 'attendance.editModal.deleteAttendanceTitle',
    defaultMessage: 'Usuń obecność',
  },
  deleteAttendanceDescription: {
    id: 'attendance.editModal.deleteAttendanceDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć obecność?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
  atendanceErrorTitle: {
    id: 'success.attendanceErrorTitle',
    defaultMessage: 'Błąd!',
  },
  addAttendanceError: {
    id: 'success.addAttendanceError',
    defaultMessage: 'Dodanie obecności nie powiodło się.',
  },
  addMassAttendancesSuccess: {
    id: 'addMassAttendanceSuccess',
    defaultMessage: 'Dodanie {numberOfAttendances} obecności powiodło się.',
  },
});

export const openAddAttendanceModal = (employee, date, defaultHours = null, locationId = null) => ({
  type: SHOW_ADD_ATTENDANCE_MODAL,
  payload: { date, employee, defaultHours, locationId },
});

export const showAddAttendanceForShiftModal = (shiftId, employeeId) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const employee = userEmployees.find(({ id }) => id === employeeId);
  const shift = employee.shifts.find(({ id }) => id === shiftId);
  dispatch(openAddAttendanceModal(employee, shift.date, shift.working_hours, shift.location.id));
};

export const closeAddAttendanceModal = () => ({
  type: CLOSE_ADD_ATTENDANCE_MODAL,
});

const addAttendanceSuccesful = (employee_id, createdAttendance) => dispatch => {
  dispatch({
    type: ADD_ATTENDANCE_SUCCESFUL,
    notification: {
      title: 'Sukces!',
      description: 'Poprawnie dodano obecność',
      type: 'success',
    },
    payload: {
      employee_id,
      createdAttendance,
    },
  });
  dispatch(trackIntercomEvent(ADD_ATTENDANCE_SUCCESFUL));
};

export const addAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: ADD_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.atendanceErrorTitle),
      description: intl.formatMessage(messages.addAttendanceError),
      type: 'error',
    },
  });
};

const changeAttendanceSuccesful = (employee_id, newAttendance, notify) => (dispatch, getState, intl) => {
  let notification;
  if (notify) {
    notification = {
      title: intl.formatMessage(messages.attendanceSuccessTitle),
      description: intl.formatMessage(messages.changeAttendenceSuccess),
      type: 'success',
    };
  }
  dispatch({
    type: CHANGE_ATTENDANCE_SUCCESFUL,
    notification,
    payload: { employee_id, newAttendance },
  });
};

const changeMultipleAttendancesSuccesful = newAttendances => ({
  type: CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL,
  payload: { newAttendances },
});

const addBonusToAttendanceSuccesful = (employee_id, attendance, bonus_amount) => ({
  type: ADD_ATTENDANCE_BONUS_SUCCESFUL,
  payload: {
    employee_id,
    attendance_id: attendance.id,
    bonus_amount,
    attendance,
  },
});

const deleteAttendanceSuccesful = (employee_id, attendance_id, attendance) => ({
  type: DELETE_ATTENDANCE_SUCCESFUL,
  notification: {
    title: 'Sukces!',
    description: 'Poprawnie usunięto obecność',
    type: 'success',
  },
  payload: { employee_id, attendance_id, attendance },
});

const startAttendanceSuccess = (employee_id, createdAttendance) => (dispatch, getState, intl) => {
  dispatch({
    type: START_ATTENDANCE_SUCCESFUL,
    notification: {
      title: intl.formatMessage(messages.startAttendanceSuccess),
      type: 'success',
    },
    payload: {
      employee_id,
      createdAttendance,
    },
  });
};

const endAttendanceSuccess = (employeeId, newAttendance) => (dispatch, getState, intl) => {
  dispatch({
    type: END_ATTENDANCE_SUCCESFUL,
    notification: {
      title: intl.formatMessage(messages.endAttendenceSuccess),
      type: 'success',
    },
    payload: { employee_id: employeeId, newAttendance },
  });
};

const startBreakSuccess = (breakObject, employeeId) => (dispatch, getState, intl) => {
  dispatch({
    type: START_BREAK_SUCCESFUL,
    notification: {
      title: intl.formatMessage(messages.startBreakSuccess),
      type: 'success',
    },
    payload: breakObject,
    employeeId,
  });
};

const endBreakSuccess = (breakObject, employeeId) => (dispatch, getState, intl) => {
  dispatch({
    type: END_BREAK_SUCCESFUL,
    notification: {
      title: intl.formatMessage(messages.endBreakSuccess),
      type: 'success',
    },
    payload: breakObject,
    employeeId,
  });
};

const startAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: START_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.startAttendanceError),
      type: 'error',
    },
  });
};

const endAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: END_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.endAttendanceError),
      type: 'error',
    },
  });
};
const startBreakError = () => (dispatch, getState, intl) => {
  dispatch({
    type: START_BREAK_ERROR,
    notification: {
      title: intl.formatMessage(messages.startBreakError),
      type: 'error',
    },
  });
};

const endBreakError = () => (dispatch, getState, intl) => {
  dispatch({
    type: END_BREAK_ERROR,
    notification: {
      title: intl.formatMessage(messages.endBreakError),
      type: 'error',
    },
  });
};
export const createAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: ADD_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.atendanceErrorTitle),
      description: intl.formatMessage(messages.addAttendanceError),
      type: 'error',
    },
  });
};
export const createMassAttendances =
  (attendances, { addMatchingShifts } = { addMatchingShifts: false }) =>
  async (dispatch, getState, intl) => {
    try {
      const addedAttendances = await conn.createMassAttendances(attendances, { addMatchingShifts });
      const numberOfAttendances = addedAttendances.length;

      dispatch({
        type: ADD_MASS_ATTENDANCE_SUCCESSFUL,
        payload: addedAttendances,
        notification: {
          title: intl.formatMessage(messages.attendanceSuccessTitle),
          description: intl.formatMessage(messages.addMassAttendancesSuccess, { numberOfAttendances }),
          type: 'success',
        },
      });
    } catch (e) {
      dispatch(createAttendanceError());
    }
  };

export const createAttendance =
  (employee_id, start_timestamp, end_timestamp, locationId = null, labelIds) =>
  async (dispatch, getState) => {
    const {
      payrollLoanedEmployees,
      attendances: {
        attendancesData: { attendances },
      },
    } = getState().reducer;
    const payrollLoanedEmployeesData = payrollLoanedEmployees.data;
    const attendancesSelectedEmployee = attendances[employee_id] || [];

    const haveAttendancesOverlap = checkIfAttendancesOverlap(
      attendancesSelectedEmployee,
      start_timestamp,
      end_timestamp,
    );
    try {
      if (haveAttendancesOverlap) throw new Error('Attendances overlap with an existing attendance');

      const { locationFilter } = getState().reducer;
      const location_id = locationId || locationFilter.selectedLocation.id;
      let final_end_timestamp = end_timestamp;
      if (end_timestamp.includes('_')) final_end_timestamp = null;
      const skipLocationCheck = payrollLoanedEmployeesData.some(employee => employee.id === employee_id);
      const { data } = await conn.createAttendance(
        location_id,
        employee_id,
        start_timestamp,
        final_end_timestamp,
        labelIds,
        skipLocationCheck,
      );
      const createdAttendance = data;
      dispatch(addAttendanceSuccesful(employee_id, createdAttendance));
      dispatch(closeAddAttendanceModal());
    } catch (error) {
      dispatch(addAttendanceError());
      console.warn(error);
    }
  };

export const deleteAttendance = (employee_id, attendance_id, attendance) => dispatch => {
  conn.deleteAttendance(attendance_id).then(() => {
    dispatch(deleteAttendanceSuccesful(employee_id, attendance_id, attendance));
  });
};

export const massDeleteAttendances = (employee_id, attendancesIds) => dispatch => {
  attendancesIds.forEach(attendanceId => dispatch(deleteAttendance(employee_id, attendanceId)));
};

export const changeAttendance =
  (employee_id, attendance, start_timestamp, end_timestamp, newLocation) => (dispatch, getState) => {
    const { demo, currentCompany } = getState().reducer;
    let final_end_timestamp = end_timestamp;
    // This means the end timestamp should be null.
    if (end_timestamp.includes('_')) {
      final_end_timestamp = null;
    }
    const attenadnceLocationId = newLocation || attendance.location.id;
    conn
      .changeAttendance(attendance.id, start_timestamp, final_end_timestamp, undefined, newLocation)
      .then(() =>
        // TODO: KADR-1486
        conn
          .getAttendances(
            currentCompany.id,
            [attenadnceLocationId],
            moment(start_timestamp).format('YYYY-MM-DD'),
            moment(final_end_timestamp || start_timestamp).format('YYYY-MM-DD'),
          )
          .then(result => {
            const locationAttendances = Object.values(result.data).reduce(
              (attendances, val) => attendances.concat(val),
              [],
            );
            const updatedAttendance = {
              ...locationAttendances.find(att => att.id === attendance.id),
              location_id: attenadnceLocationId,
            };
            if (demo.demoAccount) {
              dispatch(
                changeAttendanceSuccesful(
                  employee_id,
                  changeAttendanceForDemo(attendance, start_timestamp, end_timestamp, employee_id),
                ),
              );
            } else {
              dispatch(changeAttendanceSuccesful(employee_id, updatedAttendance));
            }
          }),
      )
      .catch(err => console.log('[CHANGE ATTENDANCE] Failed to change attendance with error: ', err));
  };

export const massChangeAttendance = (employee, attendances, newHours) => dispatch =>
  attendances.map((attendance, index) => {
    const newTimestamps = computeTimestampsForNewAttendanceHours(newHours[index], attendance);
    return dispatch(
      changeAttendance(employee.id, attendance, newTimestamps.start_timestamp, newTimestamps.end_timestamp),
    );
  });

export const addBonusToAttendance = (attendance, bonus_amount, employeeId) => dispatch => {
  conn
    .editAttendanceBonus(attendance.id, {
      bonus_amount,
    })
    .then(result => {
      dispatch(addBonusToAttendanceSuccesful(employeeId, attendance, result.data.bonus_amount));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

const approveAttendancesSuccesful = () => ({
  type: 'APPROVE_ATTENDANCES_SUCCESFUL',
  notification: {
    title: 'Sukces!',
    description: 'Poprawnie dodano zmiany dla obecności!',
    type: 'success',
  },
});

// This function grabs ATTENDANCES_WITHOUT_SHIFTS and adds the relevant shifts
export const approveAttendances = detailsList => dispatch => {
  detailsList.map(d => {
    const workingHours = d.detail.sourceAttendance.hours;
    const shiftObject = {
      comment: 'Zatwierdzona zmiana z listy obecności',
      date: d.detail.sourceAttendance.date,
      draft: false,
      employee: { id: d.employee.id },
      job_title: d.job_title,
      working_hours: workingHours,
      location: d.detail.sourceAttendance.location,
    };
    dispatch(addShift(d.employee, shiftObject, false)).then(() => dispatch(approveAttendancesSuccesful()));
  });
};

export const alignAttendances = (dates, startHour, endHour, relevantAttendances) => dispatch => {
  const formattedAttendances = formatAttendancesToAlign(dates, startHour, endHour, relevantAttendances);
  const formattedAttendancesForSingleDay = formattedAttendances[0];

  conn
    .alignAttendances({ objects: formattedAttendancesForSingleDay })
    .then(response => {
      const start = response.data.attendances && response.data.attendances.start ? response.data.attendances.start : [];
      const end = response.data.attendances && response.data.attendances.end ? response.data.attendances.end : [];
      dispatch(changeMultipleAttendancesSuccesful([...start, ...end]));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const alignAttendancesWeek = (dates, startHour, endHour, relevantAttendances) => dispatch => {
  const formattedAttendances = formatAttendancesToAlign(dates, startHour, endHour, relevantAttendances);

  conn
    .alignAttendancesWeek(formattedAttendances)
    .then(response => {
      const start = response.data.attendances?.start ?? [];
      const end = response.data.attendances?.end ?? [];
      dispatch(changeMultipleAttendancesSuccesful([...start, ...end]));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const addBreakSuccesful = (breakObject, employeeId) => ({
  type: ADD_BREAK_SUCCESFUL,
  payload: breakObject,
  employeeId,
});

export const changeBreakSuccesful = (breakObject, employeeId) => ({
  type: CHANGE_BREAK_SUCCESFUL,
  payload: breakObject,
  employeeId,
});

export const deleteBreakSuccesful = (breakObject, employeeId) => ({
  type: DELETE_BREAK_SUCCESFUL,
  payload: breakObject,
  employeeId,
});

export const addBreak = (breakObject, employeeId) => dispatch => {
  conn.addBreak(breakObject).then(result => {
    dispatch(addBreakSuccesful(result.data, employeeId));
  });
};

export const changeBreak = (breakObject, employeeId) => dispatch => {
  conn.changeBreak(breakObject).then(result => {
    dispatch(changeBreakSuccesful(result.data, employeeId));
  });
};

export const deleteBreak = (breakObject, employeeId) => dispatch => {
  conn.deleteBreak(breakObject).then(() => {
    dispatch(deleteBreakSuccesful(breakObject, employeeId));
  });
};

export const handleMultipleBreaksActions = (changes, employeeId) => dispatch => {
  changes.forEach(change => {
    switch (change.action) {
      case 'add':
        dispatch(addBreak(change.data, employeeId));
        break;
      case 'change':
        dispatch(changeBreak(change.data, employeeId));
        break;
      case 'delete': {
        dispatch(deleteBreak(change.data, employeeId));
        break;
      }
      default:
        break;
    }
  });
};

export const setAttendanceSearchString = searchString => ({
  type: SET_ATTENDANCES_SEARCH_STRING,
  payload: searchString,
});

export const startAttendance = (locationId, employeeId) => dispatch =>
  new Promise((resolve, reject) => {
    const data = {
      attendance: {
        location_id: locationId,
        user_id: employeeId,
        client_timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        use_client_start_timestamp: false,
        use_client_end_timestamp: false,
      },
    };

    conn
      .startAttendance(data)
      .then(response => {
        dispatch(startAttendanceSuccess(response.data.employee_id, response.data));
        dispatch(hideModal(CHOOSE_LOCALIZATION_MODAL));
        resolve();
      })
      .catch(() => {
        dispatch(startAttendanceError());
        reject();
      });
  });

export const endAttendance = attendanceId => dispatch =>
  new Promise((resolve, reject) => {
    const payload = {
      attendance: {
        client_timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        use_client_start_timestamp: false,
        use_client_end_timestamp: false,
      },
    };

    conn
      .endAttendance(attendanceId, payload)
      .then(({ data }) => {
        dispatch(endAttendanceSuccess(data.employee_id, data));
        resolve();
      })
      .catch(() => {
        dispatch(endAttendanceError());
        reject();
      });
  });

export const startBreak = (attendanceId, employeeId) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .startBreak(attendanceId, {
        client_timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      })
      .then(res => {
        dispatch(startBreakSuccess(res.data, employeeId));
        resolve();
      })
      .catch(() => {
        dispatch(startBreakError());
        reject();
      });
  });

export const changeAttendancesMode = dateModeType => dispatch => {
  const mode = dateModes.find(({ type }) => type === dateModeType);
  if (!mode) throw new Error('Mode was not found');
  dispatch({
    type: CHANGE_ATTENDANCES_MODE,
    payload: mode,
  });
};

export const showAttendancesForLocation = locationId => dispatch => {
  dispatch(changeScheduleLocationFilter([locationId]));
  dispatch(changeAttendancesMode('day'));
  browserHistory.push('/attendance');
};

export const endBreak = (attendanceId, breakId, employeeId) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .endBreak(attendanceId, breakId, {
        client_timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      })
      .then(res => {
        dispatch(endBreakSuccess(res.data, employeeId));
        resolve();
      })
      .catch(() => {
        dispatch(endBreakError());
        reject();
      });
  });

export const showDeleteAttendanceConfirmModal = (employeeId, attendance) => (dispatch, getState, intl) => {
  const attendanceId = attendance.id;
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteAttendanceTitle),
      description: intl.formatMessage(messages.deleteAttendanceDescription),
      confirmText: intl.formatMessage(messages.delete),
      confirmFunc: () => dispatch(deleteAttendance(employeeId, attendanceId, attendance)),
    }),
  );
};

export const toggleSelectAttendance = attendance => dispatch => {
  dispatch({
    type: TOGGLE_ATTENDANCE_SELECT,
    payload: attendance,
  });
};

export const selectAllAttendances = items => ({
  type: SELECT_ALL_ATTENDANCES,
  payload: items,
});

export const unselectAllAttendances = () => ({
  type: UNSELECT_ALL_ATTENDANCES,
});

export const assignLabelsToAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: ASSIGN_LABEL_TO_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.assignLabelToAttendanceError),
      type: 'error',
    },
  });
};

export const unassignLabelsToAttendanceError = () => (dispatch, getState, intl) => {
  dispatch({
    type: UNASSIGN_LABEL_TO_ATTENDANCE_ERROR,
    notification: {
      title: intl.formatMessage(messages.unassignLabelToAttendanceError),
      type: 'error',
    },
  });
};

export const assignLabelsToAttendance = (attendanceId, employeeId, labelIds) => async (dispatch, getState) => {
  try {
    const { labels } = getState().reducer;

    const { data } = await conn.assignLabelsToAttendance(attendanceId, labelIds);

    const labelIdsFromData = data.map(({ label_id }) => label_id);
    const relevantLabels = labels.filter(({ id }) => labelIdsFromData.includes(id));

    dispatch({
      type: ASSIGN_LABEL_TO_ATTENDANCE,
      payload: { relevantLabels, employeeId, attendanceId },
    });
  } catch (e) {
    dispatch(assignLabelsToAttendanceError());
    console.error(e);
  }
};

export const unassignLabelsFromAttendance = (attendanceId, employeeId, labelIds) => async dispatch => {
  try {
    await conn.unassignLabelsFromAttendance(attendanceId, labelIds);

    dispatch({
      type: UNASSIGN_LABEL_FROM_ATTENDANCE,
      payload: { unassignLabelIds: labelIds, employeeId, attendanceId },
    });
  } catch (e) {
    dispatch(unassignLabelsToAttendanceError());
    console.error(e);
  }
};
export const refreshData = () => (dispatch, getState) => {
  const { start, end } = getState().reducer.mainDateStore.customDate;
  dispatch(attendanceView(start, end));
};

export function assignOrUnassignLabelsFromAttendance(labels, selectedLabels, attendanceId, employeeId) {
  return function (dispatch) {
    const labelsAssignedToAttendance = labels?.map(({ id }) => id);
    const unassignedLabelIdsFromAttendance = labelsAssignedToAttendance?.filter(
      label => !selectedLabels.includes(label),
    );
    const assignLabelIdsToAttendance = selectedLabels?.filter(label => !labelsAssignedToAttendance.includes(label));

    if (unassignedLabelIdsFromAttendance.length)
      dispatch(unassignLabelsFromAttendance(attendanceId, employeeId, unassignedLabelIdsFromAttendance));
    if (assignLabelIdsToAttendance.length)
      dispatch(assignLabelsToAttendance(attendanceId, employeeId, assignLabelIdsToAttendance));
  };
}
