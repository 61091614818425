import { produce } from 'immer';

import { GET_REPORT_DATA_ERROR, GET_REPORT_DATA_INIT, GET_REPORT_DATA_SUCCESS } from '@/constants/ActionTypes';
import {
  GetReportDataErrorAction,
  GetReportDataInitAction,
  GetReportDataSuccessAction,
  ReportViewData,
} from '@/types/reports.types';

export type State = ReportViewData;
const initialState: State = {
  statistics: {
    currentPeriod: {
      absences: 0,
      activeUsers: 0,
      plannedActiveUsers: 0,
      payoutSum: 0,
      plannedMinutes: 0,
      plannedPayoutSum: 0,
      workedMinutes: 0,
    },
    previousPeriod: {
      absences: 0,
      activeUsers: 0,
      payoutSum: 0,
      plannedMinutes: 0,
      plannedPayoutSum: 0,
      workedMinutes: 0,
    },
  },
  charts: {
    jobTitlesChartData: {},
    plannedAndWorkedData: {},
    locationsChartData: {},
    absencesChartData: {},
    absencesAndAttendancesOrShiftsChartData: {},
  },
  tableData: {
    summary: {
      plannedMinutes: 0,
      workedMinutes: 0,
      differenceMinutes: 0,
      differencePercent: '-',
      employeesCount: 0,
      frequency: '-',
      plannedLaborCost: 0,
      actualLaborCost: 0,
      differenceCost: 0,
      differenceCostPercent: '-',
      absencesMinutes: 0,
    },
  },
};

type Action = GetReportDataInitAction | GetReportDataSuccessAction | GetReportDataErrorAction;

export const data = (state = initialState, action: Action) =>
  produce(state, () => {
    switch (action.type) {
      case GET_REPORT_DATA_INIT: {
        return { ...initialState };
      }
      case GET_REPORT_DATA_SUCCESS: {
        return { ...state, ...action.payload };
      }
      case GET_REPORT_DATA_ERROR: {
        return { ...initialState };
      }
      default:
        return state;
    }
  });
