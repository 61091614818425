import { GET_PROMO_CONTENT_SUCCESS } from '@/constants/ActionTypes.js';

import { conn, connectionError } from './index';

export const getPromoContent = () => dispatch => {
  const query = import.meta.env.VITE_TARGET_API === 'master' ? '' : '/TEST';

  conn
    .getPromoContent(query)
    .then(result => {
      dispatch({
        type: GET_PROMO_CONTENT_SUCCESS,
        payload: result.data,
      });
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};
