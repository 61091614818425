import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'dashboard.ownerTrial.title',
    defaultMessage: 'Skontaktuj się z nami',
  },
  subTitle: {
    id: 'dashboard.ownerTrial.subTitle',
    defaultMessage: 'Skorzystaj z dostępnych form kontaktu, aby uzyskać wsparcie przy wdrożeniu systemu.',
  },
  messageTitle: {
    id: 'dashboard.ownerTrial.messageTitle',
    defaultMessage: 'Komunikator',
  },
  messageInfo: {
    id: 'dashboard.ownerTrial.messageInfo',
    defaultMessage: 'Napisz do nas, a odpowiemy Tobie na powstałe pytania poprzez nasz komunikator.',
  },
  messageButton: {
    id: 'dashboard.ownerTrial.messageButton',
    defaultMessage: 'Napisz na czacie',
  },
  phoneTitle: {
    id: 'dashboard.ownerTrial.phoneTitle',
    defaultMessage: 'Telefon',
  },
  phoneInfo: {
    id: 'dashboard.ownerTrial.phoneInfo',
    defaultMessage: 'Preferujesz kontakt telefoniczny? Zapraszamy do rozmowy pod numer +48 22 103 18 49.',
  },
  phoneButton: {
    id: 'dashboard.ownerTrial.phoneButton',
    defaultMessage: 'Zadzwoń do nas',
  },
  videoCallTitle: {
    id: 'dashboard.ownerTrial.onlineCall',
    defaultMessage: 'Rozmowa online',
  },
  videoCallInfo: {
    id: 'dashboard.ownerTrial.videoCallInfo',
    defaultMessage: 'Umów się na 15-minutowe konsultacje, a pomożemy skonfigurować Twoje konto.',
  },
  videoCallButton: {
    id: 'dashboard.ownerTrial.videoCallButton',
    defaultMessage: 'Umów się na konsultację',
  },
});
