import { connect } from 'react-redux';

import AddAbsenceTab from './AddAbsenceTab.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
  absenceTypes: state.reducer.absences.absencesTypes,
});

const AddAbsenceTabContainer = connect(mapStateToProps)(AddAbsenceTab);

export default AddAbsenceTabContainer;
