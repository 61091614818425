import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useOutletContext } from 'react-router';

import Button from '@/components/common/Basic/Button.jsx';
import { MDSelect } from '@/components/common/inputs/MDComponents';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import browserHistory from '@/constants/browserHistory';
import { LARGE_MODIFIER } from '@/constants/modifiers';
import {
  onboardingCompanySizes,
  onboardingIndustries,
} from '@/constants/onboarding.js';
import { gaEvents } from '@/utils/ga.helpers';
import { validateInput as inputValidationHelper } from '@/utils/inputHelpers.js';
import { inputHasErrors } from '@/utils/inputValidation.js';

import {
  getIndustrySelectOptions,
  messages,
} from './onboardingStepTwoHelpers.js';
import OnboardingAppPurpose from '@/components/newOnboarding/OnboardingAppPurpose/OnboardingAppPurpose.jsx';

const OnboardingStepTwo = (props, { intl }) => {
  const [saveStepData, stepData] = useOutletContext();
  const industryOptions = getIndustrySelectOptions(intl);
  const [values, setValues] = useState({
    ...stepData,
    companyName: '',
    industry: '',
    companySize: '',
    purposes: ['schedule', 'rcp'],
  });
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const validateInput = (e) =>
    new Promise(async (resolve, reject) => {
      const [name, error] = await inputValidationHelper(e);
      if (error) {
        const errorMessage = intl.formatMessage(error);
        setErrors((prev) => ({ ...prev, [name]: errorMessage }));
        reject(new Error(errorMessage));
      } else {
        setErrors((prev) => ({ ...prev, [name]: null }));
        resolve();
      }
    });

  const handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    setValues((prev) => ({
      ...prev,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    }));

    if (inputHasErrors(name, errors)) {
      validateInput(e);
    }
  };

  const handleSelectChange = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateAll = async (inputs) => {
    const inputsToValidate = inputs?.length
      ? inputs
      : ['companyName', 'industry', 'companySize', 'purposes'];
    const validationPromises = inputsToValidate.map((inputName) =>
      validateInput({ target: { name: inputName, value: values[inputName] } })
    );
    const validationErrors = await Promise.all(
      validationPromises.map((promise) => promise.catch((err) => err))
    );
    return !validationErrors.some((error) => error);
  };

  const handleStepSubmit = (e) => {
    e.preventDefault();
    return validateAll().then((valid) => {
      if (!valid) return;
      const { companyName, industry, companySize, purposes } = values;

      saveStepData(
        {
          companyName,
          industry,
          companySize,
          purposes,
        },
        'stepThree'
      );
    });
  };

  useEffect(() => {
    validateAll(['email', 'firstName', 'lastName', 'password']).then(
      (valid) => {
        if (!location.state.initialLoginWithOauth && !valid) {
          browserHistory.push('/onboarding/stepOne');
        }

        setValues((prev) => ({
          ...prev,
          industry: onboardingIndustries[0].value,
          companySize: onboardingCompanySizes[0].value,
        }));
        gaEvents.setNewStep(3, window.gaProducts);
      }
    );
  }, []);

  const onPurposeChange = useCallback(
    (e) => {
      gaEvents.setCheckoutOption(3, e.target.value.join('+'));
      return handleInputChange(e);
    },
    [validateInput]
  );
  const onIndustryChange = useCallback(
    (e) => {
      gaEvents.setCheckoutOption(3, e);
      return handleSelectChange('industry', e);
    },
    [handleSelectChange]
  );

  const onCompanySizeChange = useCallback(
    (e) => {
      gaEvents.setCheckoutOption(3, e);
      handleSelectChange('companySize', e);
    },
    [handleSelectChange]
  );

  return (
    <div className="k-onboardingStep">
      <form onSubmit={handleStepSubmit} className="k-onboardingStep__form">
        <MDTextInput
          id="companyName"
          value={values.companyName}
          errorMessage={errors.companyName}
          icon="k-icon-store_mall_directory"
          label={intl.formatMessage(messages.companyName)}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
        <MDSelect
          name={intl.formatMessage(messages.industry)}
          onChange={onIndustryChange}
          options={industryOptions}
          testId="industry-select"
          id="industry"
          defaultValue={values.industry}
          closeOnClick
          modifiers={LARGE_MODIFIER}
        />
        <MDSelect
          name={intl.formatMessage(messages.companySize)}
          onChange={onCompanySizeChange}
          options={onboardingCompanySizes}
          testId="companySize-select"
          id="companySize"
          defaultValue={values.companySize}
          closeOnClick
          modifiers={LARGE_MODIFIER}
        />
        <OnboardingAppPurpose
          handleInputChange={onPurposeChange}
          purposes={values.purposes}
          errorMessage={errors.purposes}
        />
        <div className="k-onboardingStep__button">
          <Button type="submit" modifiers="orange high uppercase">
            <FormattedMessage id="common.next" defaultMessage="Dalej" />
          </Button>
        </div>
      </form>
    </div>
  );
};

OnboardingStepTwo.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

OnboardingStepTwo.propTypes = {};

export default OnboardingStepTwo;
