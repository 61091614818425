import { connect } from 'react-redux';

import EmployeesEditMassJobTitlesField from './EmployeesEditMassJobTitlesField.jsx';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
});

const EmployeesEditMassJobTitlesFieldContainer = connect(mapStateToProps)(EmployeesEditMassJobTitlesField);

export default EmployeesEditMassJobTitlesFieldContainer;
