import { getAbsenceData, getData } from './getData.js';
import { getAbsenceBody, getBody } from './helpers.js';

export const r2platnikExport = (params, absenceTypes, selectedEmployees) => {
  const dataByEmployeeAndDate = getData(params, selectedEmployees);
  const absenceData = getAbsenceData(params, absenceTypes, selectedEmployees);
  const body = getBody(dataByEmployeeAndDate);
  const absenceBody = getAbsenceBody(absenceData);

  return [
    {
      data: body,
      name: 'Ewidencja czasu pracy',
    },
    {
      data: absenceBody,
      name: 'Nieobecności',
    },
  ];
};
