import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import { ScrollContext } from '@/components/common/ScrollWrapper.jsx';
import ScheduleTableHeaderContainer from '@/containers/scheduler/ScheduleTableHeaderContainer.js';
import SingleDayHeader from '@/containers/scheduler/SingleDay/SingleDayHeaderContainer.js';
import { getCookie,setCookie } from '@/utils/cookieHandlers.js';
import { getScheduleSettingsValue,getStylesForScheduleDisabledEditOverlay } from '@/utils/schedulerHelpers.js';

import ScheduleTableGraphsHeader from './ScheduleTableHeaderGraphs/ScheduleTableGraphsHeader.redux.js';
import SingleDayGraphHeader from './ScheduleTableHeaderGraphs/SingleDayGraphHeader/SingleDayGraphHeader.redux.js';


const modeColumns = {
  day: 1,
  week: 7,
  month: 28,
};

export class ScheduleTable extends Component {
  constructor(props) {
    super(props);

    let cookieHeight;
    if (this.props.location) cookieHeight = getCookie(`gridHeight:${this.props.location.id}`);
    let gridHeight = props.height;
    if (cookieHeight) gridHeight = parseInt(cookieHeight);
    this.state = {
      gridHeight,
    };

    this.resizeStart = this.resizeStart.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.resizeMove = this.resizeMove.bind(this);

    this.startY = 0;
    this.resizeTimestamp = 0;
  }
  componentDidMount() {
    Mousetrap.bind('tab', this.tabFunc.bind(this));
    if (this.props.mainDateStore.dateMode !== 'month') {
      Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
      Mousetrap.bind(['right'], this.props.mainDateMoveRight);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.location) return;
    const height = getCookie(`gridHeight:${nextProps.location.id}`);
    if (height && this.state.gridHeight !== height) {
      this.setState({ gridHeight: parseInt(height) });
    } else if (!height) {
      this.setState({ gridHeight: nextProps.height });
    }
  }
  componentWillUpdate(nextProps) {
    if (nextProps.scheduleUIState.selectedDisplayMode.type !== this.props.scheduleUIState.selectedDisplayMode.type) {
      this.props.saveTemplates(this.props.userTemplates);
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
    this.props.deselectAllEmployeesRows();
    Mousetrap.unbind('tab');
  }

  tabFunc(e) {
    e.preventDefault();
    this.props.flipScheduleMode();
  }

  resizeStart(e) {
    window.addEventListener('mouseup', this.resizeStop);
    window.addEventListener('mousemove', this.resizeMove);
    this.startY = e.pageY;
    this.oldHeight = this.state.gridHeight;
  }
  resizeStop() {
    if (this.props.location) setCookie(`gridHeight:${this.props.location.id}`, this.state.gridHeight, 999);
    window.removeEventListener('mouseup', this.resizeStop);
    window.removeEventListener('mousemove', this.resizeMove);
  }
  resizeMove(e) {
    e.preventDefault();
    if (e.timeStamp - this.resizeTimestamp > 150 && this.resizeContainer) {
      this.resizeTimestamp = e.timeStamp;
      let gridHeight = e.pageY - this.startY + this.oldHeight;
      if (gridHeight - this.resizeContainer.offsetHeight > 50) {
        gridHeight = this.resizeContainer.offsetHeight;
      }
      if (gridHeight < 400) gridHeight = 400;
      if (gridHeight > 10000) gridHeight = 10000;
      this.setState({ gridHeight });
    }
  }

  render() {
    const {
      mainDateStore,
      scheduleUIState,
      locationSettings,
      location,
      currentCompany,
      width,
      scrollCallback,
      scrollPosition,
      styles,
      tableRows,
      relevantShifts,
      tableLeftColumn,
      showEmptyState,
      fixedRows,
      fixedLeftColumn,
      scheduleLocationFilter,
    } = this.props;
    const displayMultiple = scheduleLocationFilter.length > 1;
    const singleDay = mainDateStore.dateMode === 'day' || mainDateStore.dateArray.length === 1;
    const showEmployeesGraph =
      scheduleUIState.selectedDisplayMode.type === 'schedule' &&
      getScheduleSettingsValue(scheduleUIState.settings, 'budgetMetrics', true);
    const selectedLocationSettings = locationSettings[location.id];
    let disableEditUntil = selectedLocationSettings
      ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    if (scheduleUIState.selectedDisplayMode.type === 'availabilities') {
      disableEditUntil = currentCompany.settings.disable_availabilities_edit_until;
    }
    if (scheduleUIState.selectedDisplayMode.type === 'templates') {
      disableEditUntil = null;
    }
    const disabledEditOverlayStyle = getStylesForScheduleDisabledEditOverlay(
      disableEditUntil,
      mainDateStore.dateArray,
      mainDateStore.dateMode,
      width,
    );
    const rowsClassnames = classnames('kadroGrid__rows--disabled', {
      monthview: mainDateStore.dateArray.length > 7,
      schedule: mainDateStore.dateArray.length <= 7,
    });

    return (
      <div
        ref={ref => {
          this.resizeContainer = ref;
        }}
        style={{
          position: 'relative',
          marginBottom: '20px',
        }}
      >
        <Grid
          width={width}
          height={this.state.gridHeight}
          scrollCallback={scrollCallback}
          scrollPosition={scrollPosition}
          columnStyle={styles.columnStyle}
          static={tableRows.length < 50}
          slowLoad={tableRows.length > 120}
          mode={scheduleUIState.selectedDisplayMode.type + location.id}
          className="k-scheduleTable"
          // just to update Grid when absence is added/edited/deleted.
          // It is needed because absences are not a part of employee object
          absences={this.props.absences}
        >
          <LeftHeader showGraph={showEmployeesGraph} multiRows={Boolean(fixedLeftColumn)}>
            <th className="tab_title">
              <i className="material-icons drag_handle">drag_handle</i>
              {displayMultiple ? (
                location.name
              ) : (
                <FormattedMessage id="scheduler.view.title" defaultMessage="Grafik pracy" />
              )}
            </th>
            {fixedLeftColumn}
          </LeftHeader>
          <Header
            className={classnames({ graph: showEmployeesGraph, monthview: mainDateStore.dateArray.length > 7 })}
            disabledEditOverlayStyle={disabledEditOverlayStyle}
            columnsNo={modeColumns[mainDateStore.dateMode]}
            showEmptyState={showEmptyState}
          >
            <thead>
              {showEmployeesGraph &&
                (singleDay ? (
                  <SingleDayGraphHeader location={location} singleDay={singleDay} relevantShifts={relevantShifts} />
                ) : (
                  <ScheduleTableGraphsHeader location={location} relevantShifts={relevantShifts} />
                ))}
              {singleDay ? (
                <SingleDayHeader />
              ) : (
                <>
                  <ScheduleTableHeaderContainer
                    locationId={location.id}
                    selectable={!['positions', 'templates'].includes(scheduleUIState.selectedDisplayMode.type)}
                    addAndEditManagerNote
                  />
                  {fixedRows}
                </>
              )}
            </thead>
          </Header>
          <LeftColumn>{tableLeftColumn}</LeftColumn>
          <Rows
            showEmptyState={showEmptyState}
            columnsNo={modeColumns[mainDateStore.dateMode]}
            className={rowsClassnames}
            disabledEditOverlayStyle={disabledEditOverlayStyle}
          >
            {tableRows}
          </Rows>
        </Grid>
        <div className="resizeHandle" onMouseDown={this.resizeStart} role="button" tabIndex="0">
          <i className="material-icons resizeHandle__arrow">keyboard_arrow_up</i>
          <i className="material-icons resizeHandle__arrow">keyboard_arrow_up</i>
        </div>
      </div>
    );
  }
}

ScheduleTable.propTypes = {
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  styles: PropTypes.shape({
    columnStyle: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  locationSettings: PropTypes.shape({}),
  userTemplates: PropTypes.shape({}),
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentCompany: PropTypes.shape({
    settings: PropTypes.shape({
      disable_availabilities_edit_until: PropTypes.string,
    }),
  }),
  relevantShifts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  width: PropTypes.number,
  height: PropTypes.number,
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  deselectAllEmployeesRows: PropTypes.func,
  flipScheduleMode: PropTypes.func,
  tableRows: PropTypes.node,
  tableLeftColumn: PropTypes.node,
  fixedRows: PropTypes.node,
  fixedLeftColumn: PropTypes.node,
  saveTemplates: PropTypes.func,
  scrollCallback: PropTypes.func,
  scrollPosition: PropTypes.number,
  showEmptyState: PropTypes.bool,
  absences: PropTypes.shape({}),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default props => (
  <ScrollContext.Consumer>
    {({ scrollCallback, scrollPosition }) => (
      <ScheduleTable {...props} scrollCallback={scrollCallback} scrollPosition={scrollPosition} />
    )}
  </ScrollContext.Consumer>
);
