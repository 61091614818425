import { connect } from 'react-redux';

import { acceptLoanEmployeesProposal } from '@/actions/loanEmployeesProposals';
import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL } from '@/constants/ActionTypes';

import LoanEmployeesProposalEditStep from './LoanEmployeesProposalEditStep';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL],
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
});

const mapDispatchToProps = { hideModalAndLaterClearModalObject, acceptLoanEmployeesProposal };

export default connect(mapStateToProps, mapDispatchToProps)(LoanEmployeesProposalEditStep);
