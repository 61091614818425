import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BUDGET_ESTIMATES_EDIT } from '@/constants/Permissions.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';

import ScheduleBudgetTableRowInput from '../../ScheduleBudgetTableRowInput/ScheduleBudgetTableRowInput.jsx';

const ScheduleBudgetTablePercentEstimatesCell = ({
  estimate = {},
  sumCosts = 0,
  getBudgetEstimateClassname,
  permissions,
  setCellError,
  onEstimateChange,
  dayType,
  date,
  canEdit,
  showHolidays,
}) => {
  const getClassname = isUnder =>
    classnames('k-budgetTable__actualLabour', {
      'k-budgetTable__actualLabour--under': isUnder,
      'k-budgetTable__actualLabour--over': !isUnder,
    });
  const cost = sumCosts;
  const result =
    !estimate.estimated_budget || !cost ? '-' : roundToTwoSigDigits(cost / (estimate.estimated_budget / 100));
  const valueToDisplay = result === '-' ? result : `${result} %`;
  const labourLimitPercent = estimate.labour_limit / 100;
  return (
    <td className={getBudgetEstimateClassname(estimate, 'labour_limit', dayType, date, showHolidays)}>
      {canEdit ? (
        <ScheduleBudgetTableRowInput
          key={estimate.date}
          name="labour_limit"
          estimate={estimate}
          disabled={!permissions.includes(BUDGET_ESTIMATES_EDIT)}
          onChange={e => onEstimateChange(e, estimate, 'labour_limit', date)}
          setError={setCellError}
        >
          <span className={getClassname(result > labourLimitPercent)}>{valueToDisplay}</span>
        </ScheduleBudgetTableRowInput>
      ) : (
        <div>
          {labourLimitPercent || 0}%<div className={getClassname(result > labourLimitPercent)}>{valueToDisplay}</div>
        </div>
      )}
    </td>
  );
};

ScheduleBudgetTablePercentEstimatesCell.propTypes = {
  estimate: PropTypes.shape({
    labour_limit: PropTypes.number,
    estimated_budget: PropTypes.number,
    location_id: PropTypes.string,
    date: PropTypes.string,
  }),
  locationSettings: PropTypes.shape({
    budget_estimates_period: PropTypes.string,
  }),
  sumCosts: PropTypes.number,
  canEdit: PropTypes.bool,
  getBudgetEstimateClassname: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
  setCellError: PropTypes.func,
  onEstimateChange: PropTypes.func,
  dayType: PropTypes.string,
  date: PropTypes.string,
  showHolidays: PropTypes.bool,
};

export default ScheduleBudgetTablePercentEstimatesCell;
