import { connect } from 'react-redux';

import AttendanceBarBlock from './AttendanceBarBlock';

const mapStateToProps = state => ({
  permissions: state.reducer.userPermissions.permissions,
  role: state.reducer.currentUser.user.role,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceBarBlock);
