import PropTypes from 'prop-types';
import { Component } from 'react';

import { KADRO_LIGHT_ORANGE_COLOR } from '@/constants/colors.js';

import './AutoGenerateCreateScheduleRowItem.scss';

class AutoGenerateCreateScheduleRowItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.changeValue = this.changeValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedDay !== this.props.selectedDay || this.state.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // We want to update in 3 cases:
    // 1. Max Value changed
    // 2. State was changed
    // 3. value was updated from different method like clearDay
    return (
      nextProps.maxValue !== this.props.maxValue ||
      nextState.value !== this.state.value ||
      nextProps.value !== this.state.value
    );
  }

  changeValue(event, index) {
    const { value } = event.target;
    const changed = this.state.value !== value;

    this.setState(
      {
        value,
      },
      () => {
        if (changed) this.props.changeHourValue(value, index, this.props.jobTitleId, this.props.selectedDay);
      },
    );
  }

  validateValue(event, index) {
    const value = ![''].includes(event.target.value) ? parseInt(event.target.value) : 0;
    let newValue = value;
    if (value <= 0 || Number.isNaN(parseInt(value))) {
      newValue = '';
    } else if (value > 99) {
      newValue = '99';
    }
    this.changeValue({ target: { value: String(newValue) } }, index);
  }

  render() {
    const { rowIndex, index, maxValue } = this.props;
    const level = this.state.value ? parseInt((this.state.value / maxValue) * 3) + 1 : 0;

    const style = level ? { backgroundColor: `${KADRO_LIGHT_ORANGE_COLOR}${level * 10}` } : {};
    return (
      <td style={style} className="k-autoGenerateCreateScheduleRowItem">
        <input
          className="k-autoGenerateCreateScheduleRowItem__input"
          data-cellindex={24 * rowIndex + index}
          type="text"
          value={this.state.value}
          placeholder="-"
          onChange={e => this.changeValue(e, index)}
          onBlur={e => this.validateValue(e, index)}
        />
      </td>
    );
  }
}

AutoGenerateCreateScheduleRowItem.propTypes = {
  value: PropTypes.string,
  maxValue: PropTypes.number,
  rowIndex: PropTypes.number,
  jobTitleId: PropTypes.string,
  selectedDay: PropTypes.number,
  index: PropTypes.number,
  changeHourValue: PropTypes.func,
};

export default AutoGenerateCreateScheduleRowItem;
