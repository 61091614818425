import Icon from '../../../Basic/Icon/Icon';

interface ErrorModalContentProps {
  errorMessage: string;
}

const ErrorModalContent = ({ errorMessage }: ErrorModalContentProps) => (
  <div className="mdModal__contentErrorWrapper">
    <Icon name="error" type="material-symbols" className="mdModal__contentErrorIcon" />
    <span className="mdModal__contentErrorMessage">{errorMessage}</span>
  </div>
);

export default ErrorModalContent;
