import { connect } from 'react-redux';

import { confirmDeleteContract } from '@/actions/contracts';

import EmployeeModalContracts from './EmployeeModalContracts.jsx';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
  restrictions: state.reducer.userPermissions.restrictions,
});

const mapDispatchToProps = {
  confirmDeleteContract,
};

const EmployeeModalJobsAndLocationsContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeModalContracts);

export default EmployeeModalJobsAndLocationsContainer;
