import { useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput';
import { GOOGLE_MAPS_API } from '@/constants/envVariables';

import { COORDINATES_LANDSLIDE } from './AutocompleteInput.helpers';
import AutocompletePredictions from './AutocompletePredictions/AutocompletePredictions';

import './autocompleteInput.scss';

const libraries = ['places'];

const AutocompleteInput = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API,
    libraries,
  });

  const { lat, lng } = props.deviceLocation;
  const defaultBounds = {
    north: lat + COORDINATES_LANDSLIDE,
    south: lat - COORDINATES_LANDSLIDE,
    east: lng + COORDINATES_LANDSLIDE,
    west: lng - COORDINATES_LANDSLIDE,
  };

  const {
    value,
    suggestions: { data, loading },
    setValue,
    init,
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false,
    bounds: defaultBounds,
  });

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const isInitial = useRef(true);

  const handleBlur = () => {
    if (props.onBlur) props.onBlur();
  };

  const handleSetIsOpen = (newIsOpenValue) => {
    let isClosing;
    setIsOpen((current) => {
      isClosing = current && !newIsOpenValue;
      return newIsOpenValue;
    });

    if (isClosing) {
      handleBlur();
    }
  };

  const handleFocus = (e) => {
    handleSetIsOpen(true);
    if (props.onFocus) props.onFocus(e);
  };

  const handleInput = (e) => {
    setValue(e.target.value);
    handleFocus();
  };

  const onSelect = (id) => {
    handleSetIsOpen(false);

    getDetails({ placeId: id, fields: ['geometry', 'address_components'] })
      .then((res) => {
        const { geometry, address_components: addr } = res;
        const { location } = geometry;

        const streetNumber =
          addr.find(({ types }) => types.includes('street_number'))?.long_name ||
          addr.find(({ types }) => types.includes('premise'))?.long_name ||
          '1';
        const route = addr.find(({ types }) => types.includes('route'))?.long_name.split(' ') || [''];
        const city = addr.find(({ types }) => types.includes('locality'))?.long_name || route[0];
        const postalCode = addr.find(({ types }) => types.includes('postal_code'))?.long_name || '';

        const street = city === route[0] ? route.splice(1).join(' ') : route.join(' ');

        isInitial.current = true;
        setValue(`${city}, ${street} ${streetNumber}`.trim(), false);

        props.onSelect({
          city,
          address: `${street} ${streetNumber}`.trim(),
          postalCode,
          lat: location.lat(),
          lng: location.lng(),
        });
      })
      .catch(console.error);
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      handleSetIsOpen(false);
    }
  };

  useEffect(() => {
    setValue(props.defaultValue, false);
  }, [props.defaultValue]);

  useEffect(() => {
    if (isLoaded) init();
  }, [isLoaded]);

  useEffect(() => {
    if (inputRef.current) document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [inputRef]);

  useEffect(() => {
    if (props.defaultValue !== value && !isInitial.current && !loading) {
      props.onSelect(
        {
          city: '',
          address: value,
          postalCode: '',
          lat: null,
          lng: null,
        },
        data.length,
      );
    }

    isInitial.current = false;
  }, [value, loading]);

  return (
    <div className="k-autocompleteInput" ref={inputRef}>
      <MDTextInput
        {...props}
        className="k-autocompleteInput__input"
        value={value}
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={() => {}}
      />
      <AutocompletePredictions
        className="k-autocompleteInput__predictions"
        predictions={data}
        onSelect={onSelect}
        show={!!data.length && isOpen}
      />
    </div>
  );
};

AutocompleteInput.propTypes = {
  ...MDTextInput.propTypes,
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func,
  deviceLocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default AutocompleteInput;
