import PropTypes from 'prop-types';

import { generateArrOfWeeksInMonth } from '@/utils/dateHelper';

import CalendarDay from './CalendarDay';

const CalendarWeeks = ({ monthDate, data, customDayRender }) => {
  const weeks = generateArrOfWeeksInMonth(monthDate, data);

  return (
    <tbody>
      {weeks.map((week, i) => (
        <tr className="yearCalendar__week" key={i}>
          {week.map((day, index) =>
            customDayRender ? customDayRender(day.day, day.data, index) : <CalendarDay day={day.day} data={day.data} />,
          )}
        </tr>
      ))}
    </tbody>
  );
};

CalendarWeeks.propTypes = {
  monthDate: PropTypes.shape({}),
  data: PropTypes.shape({}),
  customDayRender: PropTypes.func,
};

export default CalendarWeeks;
