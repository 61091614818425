import { connect } from 'react-redux';

import { changeExportsHistoryData } from '@/actions/exports/exportsHistory/exportsHistory.js';

import ExportPagination from './ExportPagination';

const mapStateToProps = state => ({
  currentPage: state.reducer.exportsHistory.currentPage,
  numberOfPages: state.reducer.exportsHistory.numberOfPages,
  numberOfItemsPerPage: state.reducer.exportsHistory.numberOfItemsPerPage,
});

const mapDispatchToProps = {
  changeExportsHistoryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPagination);
