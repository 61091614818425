import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { hoursFormatted } from '@/constants/dates.js';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements.js';
import { useAppSelector } from '@/redux-store/hooks';
import { selectLocationById } from '@/redux-store/userLocations';
import { isHoliday, isWeekend } from '@/utils/dateHelper.js';

import ManagerNoteCorn from '../../ManagerNoteCorn/ManagerNoteCorn.redux.js';
import ScheduleHeaderDate from '../ScheduleHeaderDate/ScheduleHeaderDate.jsx';

import './ScheduleTableHeader.scss';

const ScheduleTableHeader = ({
  locationId,
  size,
  holidays,
  mainDateStore,
  selectedDisplayMode,
  holidaysEnabled,
  managerNotes,
  onColumnClick,
  singleDay,
}) => {
  const tableHeaderRef = useRef(null);
  const [top] = useMultipleStickyElements(tableHeaderRef, 'scheduleTableHeader--sticky');
  const location = useAppSelector(selectLocationById(locationId));
  const headerTitle = location.name;
  const isMonthView = size === 'month';
  const showHolidays = selectedDisplayMode.type !== 'templates' && holidaysEnabled;

  return (
    <tr
      ref={tableHeaderRef}
      className="scheduleTableHeader scheduleTableHeader--sticky"
      style={{
        top,
      }}
    >
      <th className="scheduleTableHeader__titleCell">
        <div>{headerTitle}</div>
      </th>
      {singleDay
        ? hoursFormatted.map(hour => (
            <th key={hour} className="scheduleTableHeader__hour">
              {hour}
            </th>
          ))
        : mainDateStore.dateArray.map((date, index) => {
            const holiday = isHoliday(date, holidays);
            const freeFromWorkHoliday = showHolidays && holiday.freeFromWork;
            const workDayHoliday = showHolidays && holiday.workDay;

            const managerNote = managerNotes && managerNotes[date];
            const showManagerNote = managerNote && selectedDisplayMode.type !== 'templates';

            const showManagerNoteWithCommentIcon = showManagerNote && managerNote.comment;

            const showPopover = (showHolidays && holiday?.name) || showManagerNoteWithCommentIcon;

            const headerClassName = classnames('scheduleTableHeader__day', {
              'scheduleTableHeader--today': date === mainDateStore.today,
              'scheduleTableHeader--weekend': isWeekend(date),
              'scheduleTableHeader--freeFromWorkHoliday': freeFromWorkHoliday,
              'scheduleTableHeader--workDayHoliday': workDayHoliday,
              'scheduleTableHeader--withPopover': showPopover,
            });

            const popover = {
              content: [
                <div key={index} className="holidayPopOver">
                  {holiday.name}
                  {'\n'}
                  {managerNote ? managerNote.comment : null}
                </div>,
              ],
            };

            const renderedCell = (
              <ScheduleHeaderDate
                date={date}
                isMonthView={isMonthView}
                showCommentIcon={showManagerNoteWithCommentIcon}
                showFreeFromWorkIcon={freeFromWorkHoliday}
                showWorkHolidayIcon={workDayHoliday}
              />
            );

            return (
              <th
                key={index}
                className={headerClassName}
                onClick={e => onColumnClick(location.id, date, { ctrl: e.ctrlKey || e.metaKey, shift: e.shiftKey })}
              >
                {showManagerNote && (
                  <div
                    className="scheduleTableHeader__line"
                    style={{
                      backgroundColor: `${managerNote.color}`,
                    }}
                  />
                )}
                {showPopover ? (
                  <HoverPopOver name="holidayHover" popover={popover}>
                    <div>{renderedCell}</div>
                  </HoverPopOver>
                ) : (
                  renderedCell
                )}

                <ManagerNoteCorn locationId={location.id} date={date} managerNote={managerNote} show />
              </th>
            );
          })}
    </tr>
  );
};

ScheduleTableHeader.propTypes = {
  size: PropTypes.string,
  locationId: PropTypes.string,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    today: PropTypes.string,
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedDisplayMode: PropTypes.shape({
    type: PropTypes.string,
  }),
  holidaysEnabled: PropTypes.bool,
  managerNotes: PropTypes.shape({}),
  onColumnClick: PropTypes.func,
  singleDay: PropTypes.bool,
};

export default ScheduleTableHeader;
