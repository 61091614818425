export const NUMBER_OF_MINUTES_IN_DAY = 1440;
export const DAY_DIVIDED_IN_15_MINUTES = 96;

export const BUDGET_TYPES = {
  NUMBER: 'number',
  PERCENT: 'percent',
  UNIT: 'unit',
  HOUR: 'hour',
  CURRENCY: 'currency',
};

export const BUDGET_TIME_INTERVALS = {
  '15m': 15,
  '30m': 30,
  hourly: 60,
  daily: NUMBER_OF_MINUTES_IN_DAY,
};

export const BUDGET_ICONS = [
  'public',
  'room_service',
  'local_shipping',
  'groups',
  'workspace_premium',
  'restaurant',
  'festival',
  'cleaning_services',
  'sports',
  'build',
  'attach_money',
  'schedule',
  'phone_in_talk',
  'spa',
  'electric_bolt',
  'shopping_cart',
];

export const SUM_OPTION = 'sum';
export const AVG_OPTION = 'avg';
export const AGGREGATION_OPTIONS_VALUES = [SUM_OPTION, AVG_OPTION];
