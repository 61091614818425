import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { MDRadios } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import {
  getTemplatesVersionsOptions,
  MODAL_MODIFIERS,
  TEMPLATES_VERSIONS,
} from './ChooseAddTemplateVersionModal.helpers';
import { messages } from './ChooseAddTemplateVersionModal.messages';

const ChooseAddTemplateVersionModal = (props, { intl }) => {
  const [selectedTemplateVersion, setTemplateVersion] = useState(TEMPLATES_VERSIONS.new.id);
  const templatesVersionsOptions = useMemo(() => getTemplatesVersionsOptions(intl), []);

  const onSubmit = () => {
    const { modalType } = TEMPLATES_VERSIONS[selectedTemplateVersion];
    props.showModal(modalType);
  };

  return (
    <MDKadroModal
      show={props.isVisibleModal}
      onSubmit={onSubmit}
      onHide={props.hideModal}
      title={intl.formatMessage(messages.title)}
      modifiers={MODAL_MODIFIERS}
      confirmText={intl.formatMessage(messages.forth)}
    >
      <MDRadios
        elements={templatesVersionsOptions}
        selectedItemType={selectedTemplateVersion}
        onChangeType={setTemplateVersion}
      />
    </MDKadroModal>
  );
};

ChooseAddTemplateVersionModal.contextTypes = {
  intl: PropTypes.shape({}),
};

ChooseAddTemplateVersionModal.propTypes = {
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  isVisibleModal: PropTypes.bool,
};

export default ChooseAddTemplateVersionModal;
