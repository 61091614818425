import { connect } from 'react-redux';

import EmployeeScheduleTable from './EmployeeScheduleTable';

const mapStateToProps = (state, ownProps) => ({
  mainDateStore: state.reducer.mainDateStore,
  userEmployees: state.reducer.userEmployees,
  currentUserId: state.reducer.currentUser.user.id,
  isSchedulePublicForEmployees: state.reducer.currentCompany.settings.schedule_is_public_for_employees,
  employeeSorting: state.reducer.employeeSorting,
  restrictions: state.reducer.userPermissions.restrictions,
  isTradeShiftEnabled:
    state.reducer.schedule.viewSettings?.tradeShifts?.value &&
    state.reducer.settings.locationSettings[ownProps.location?.id]?.enable_trade_shift,
  isOpenShiftEnabled:
    state.reducer.schedule.viewSettings?.openShifts?.value &&
    state.reducer.settings.locationSettings[ownProps.location?.id]?.enable_open_shift,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
  employees: state.reducer.employees.data,
});

export default connect(mapStateToProps)(EmployeeScheduleTable);
