import { validationErrorMessages } from './BonusTargetOptions.messages';

export const validateAllBonusTargetOptions = (bonusTargetOptions, intl) => {
  let isAnyError = false;
  const newBonusTargetOptions = bonusTargetOptions.map(option => {
    const bonusPercentageError = validateBonusTarget('bonusPercentage', option.bonusPercentage, intl);
    const spmhThresholdError = validateBonusTarget('spmhThreshold', option.spmhThreshold, intl);
    if (bonusPercentageError || spmhThresholdError) isAnyError = true;
    return { ...option, errors: { bonusPercentage: bonusPercentageError, spmhThreshold: spmhThresholdError } };
  });
  return [isAnyError, newBonusTargetOptions];
};

export const validateBonusTarget = (name, value, intl) => {
  switch (name) {
    case 'bonusPercentage':
      return validateBonusPercentage(value, intl);
    case 'spmhThreshold':
      return validateSpmhTreshold(value, intl);
    default:
      return null;
  }
};

const validateSpmhTreshold = (value, intl) => {
  if (!value) return intl.formatMessage(validationErrorMessages.incorrectValue);
  return '';
};

const validateBonusPercentage = (value, intl) => {
  if (!value || Number(value) < 0.1 || Number(value) > 100) {
    return intl.formatMessage(validationErrorMessages.incorrectValue);
  }
  return '';
};
