import EmptyColumnFilter from '@/components/common/EmptyColumnFilter/EmptyColumnFilter';
import {
  hoursSortingFunction,
  moneySortingFunction,
  payrollLocationStatusSortingFunction,
} from '@/components/newPayrollViews/columns.helpers';
import { OVERTIME_V2_ENABLE } from '@/constants/Permissions';
import {
  BUDGET_INFO_HIDE,
  FREEMIUM_HIDE_ABSENCES_VIEW,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
} from '@/constants/Restrictions.js';

import { filterMessages, messages } from './payrollLocationTable.messages';

export const payrollLocationTableColumnsOptions = [
  {
    accessor: 'employee',
    cannotBeHidden: true,
    shouldBeHiddenForColumnFilter: true,
    width: 200,
    sticky: 'left',
    sortingKey: 'employee',
  },
  {
    restrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
    accessor: 'absences',
    width: 150,
    sortingKey: 'absences_time',
    sortingFunction: hoursSortingFunction,
  },
  {
    accessor: 'workTime',
    width: 130,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'work_time',
  },
  {
    accessor: 'nightWorkTime',
    width: 130,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'night_hours',
  },
  {
    accessor: 'timePlanned',
    width: 80,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'timePlanned',
  },
  {
    accessor: 'timeDiff',
    width: 130,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'timeDiff',
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'overtime50',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'overtime50',
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    accessor: 'overtime100',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'overtime100',
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessor: 'potentialOvertime',
    hideForShiftsPayoutType: true,
    width: 150,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'potentialOvertime',
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    permissions: [OVERTIME_V2_ENABLE],
    accessor: 'fillUp',
    width: 150,
    hideForShiftsPayoutType: true,
    sortingFunction: hoursSortingFunction,
    sortingKey: 'fillUp',
  },
  {
    restrictions: [BUDGET_INFO_HIDE, FREEMIUM_HIDE_ABSENCES_VIEW],
    accessor: 'bonus',
    width: 100,
    sortingFunction: moneySortingFunction,
    sortingKey: 'bonus',
  },
  {
    restrictions: [BUDGET_INFO_HIDE],
    accessor: 'payout',
    width: 80,
    sortingFunction: moneySortingFunction,
    sortingKey: 'payout',
  },
  {
    restrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
    id: 'warnings',
    accessor: 'warnings',
    width: 50,
    Filter: EmptyColumnFilter,
    sticky: 'right',
  },
  {
    accessor: 'status',
    width: 150,
    sticky: 'right',
    companySettings: ['payroll_approval_enabled'],
    sortingKey: 'status',
    sortingFunction: payrollLocationStatusSortingFunction,
  },
].map((option, index) => ({
  ...option,
  id: option.id || index + 1,
  title: messages[option.accessor],
  filterTitle: filterMessages[option.accessor] || messages[option.accessor],
  restrictions: option.restrictions || [],
  permissions: option.permissions || [],
  sticky: option.sticky,
}));
