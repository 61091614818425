import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Toggle extends Component {
  constructor(props) {
    super(props);
    let checked = false;
    if (this.props.value) {
      checked = this.props.value;
    }
    this.state = {
      checked,
    };
    this.changeFunc = this.changeFunc.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.checked) {
      this.setState({ checked: nextProps.value });
    }
  }

  changeFunc() {
    if (!this.props.disabled) {
      this.props.onChangeFunc({ target: { checked: !this.state.checked, type: 'checkbox', name: this.props.labelId } });
      this.setState({ checked: !this.state.checked });
    }
  }

  render() {
    let modifiers = this.props.modifiers || [];
    if (!Array.isArray(modifiers)) {
      modifiers = modifiers.split(' ');
    }
    const className = classNames('k-toggle', ...modifiers.map(modifier => `k-toggle--${modifier}`), {
      'k-toggle--disabled': this.props.disabled,
    });

    const toggleInputClassNames = classNames('k-toggle__input', {
      'k-toggle__input--relative': this.props.toggleInInput,
    });

    const toggleClassNames = classNames({ 'k-toggle__label--input': this.props.toggleInInput });

    return (
      <div className={className}>
        <input
          id={this.props.labelId}
          name={this.props.labelId}
          checked={this.state.checked}
          className={toggleInputClassNames}
          type="checkbox"
          onChange={this.changeFunc}
        />
        <label className={toggleClassNames} htmlFor={this.props.labelId}>
          <span className="k-toggle__text k-toggle__yesText" style={this.props.hideText ? { display: 'none' } : {}}>
            {this.props.left || <FormattedMessage id="common.yes" defaultMessage="TAK" />}
          </span>

          <span className="k-toggle__text k-toggle__noText" style={this.props.hideText ? { display: 'none' } : {}}>
            {this.props.right || <FormattedMessage id="common.no" defaultMessage="NIE" />}
          </span>
        </label>
      </div>
    );
  }
}

Toggle.propTypes = {
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.bool,
  labelId: PropTypes.string,
  hideText: PropTypes.bool,
  disabled: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  left: PropTypes.node,
  right: PropTypes.node,
  toggleInInput: PropTypes.bool,
};

export default Toggle;
