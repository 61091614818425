import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  save: {
    id: 'common.save',
    defaultMessage: 'Zapisz',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Dalej',
  },
  back: {
    id: 'common.back',
    defaultMessage: 'Wstecz',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Anuluj',
  },
  approve: {
    id: 'common.approve',
    defaultMessage: 'Zatwierdź',
  },
  overridePrimaryLocations: {
    id: 'companymanage.employees.editMassModal.overridePrimaryLocations',
    defaultMessage:
      'Nadpisz aktualnie dodane do pracowników lokalizacje nominalne wybranymi lokalizacjami pomocniczymi',
  },
});
