import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getQueryString } from './SideMenuLink.helpers';
import { useLocation } from 'react-router';
import { generateQueryParametersFromSearchString } from '@/utils/urlModifyHelpers';

const SideMenuLink = ({ children, index: _, name, sectionName, ...props }) => {
  const location = useLocation();
  const locationQuery = generateQueryParametersFromSearchString(location.search);
  const query = getQueryString(locationQuery, sectionName);

  const className = classnames('sideMenuLink', {
    'sideMenuLink--active': locationQuery[sectionName] === name || (name === 'all' && !locationQuery[sectionName]),
  });

  const to = `${location.pathname}?${sectionName}=${name}${query ? `&${query}` : ''}`;

  return (
    <Link className={className} to={to} {...props}>
      {children}
    </Link>
  );
};

SideMenuLink.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  to: PropTypes.string,
  name: PropTypes.string,
  sectionName: PropTypes.string,
  location: PropTypes.shape({
    query: PropTypes.shape({}),
    pathname: PropTypes.string,
  }),
};

export default SideMenuLink;
