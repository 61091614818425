import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon';
import { MDTooltip } from '@/components/common/inputs/MDComponents';
import { PREVIEW_MODAL } from '@/constants/modalTypes';

import Button from '../Basic/Button.jsx';
import { getCategoriesNames } from './Tile.helpers';
import { messages } from './Tile.messages';

const Tile = (
  {
    title,
    description,
    image,
    showMoreFunc,
    actionButtonFunc,
    exportId,
    categories,
    sections,
    showModal,
    displayFormat,
  },
  { intl },
) => {
  const categoriesNames = getCategoriesNames(categories, sections);
  return (
    <div className="tile">
      {image && (
        <MDTooltip text={intl.formatMessage(messages.showPreview)} withoutIcon>
          <img
            className="tile__img"
            src={image}
            alt={description}
            onClick={() =>
              showModal(PREVIEW_MODAL, {
                image,
                title,
                description,
                actionButtonFunc,
                exportId,
                displayFormat,
                categories: categoriesNames,
              })
            }
          />
        </MDTooltip>
      )}
      <div className="tile__content">
        <h1>{title}</h1>
        <p className="tile__description">{description}</p>
        <div className="tile__specifications">
          <p>
            <span className="tile__specificationName">{intl.formatMessage(messages.format)}</span>
            <span className="tile__format">{displayFormat}</span>
          </p>
          <p>
            <span className="tile__specificationName">{intl.formatMessage(messages.category)}</span>
            <span>{categoriesNames}</span>
          </p>
        </div>
        {showMoreFunc && (
          <div className="exportsCatalogView__showMore" onClick={showMoreFunc} role="presentation">
            {intl.formatMessage(messages.preview)}
          </div>
        )}
      </div>
      {actionButtonFunc && (
        <div className="tile__footer">
          <Button modifiers={['no-bg']} onClick={actionButtonFunc}>
            <span>{intl.formatMessage(messages.generate)}</span>
            <Icon name="arrow_forward" />
          </Button>
        </div>
      )}
    </div>
  );
};

Tile.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  showMoreFunc: PropTypes.func,
  actionButtonFunc: PropTypes.func,
  exportId: PropTypes.string,
  displayFormat: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
  showModal: PropTypes.func,
};

Tile.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default Tile;
