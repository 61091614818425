import { connect } from 'react-redux';

import { changeEmploymentConditionsFilter } from '@/actions/employmentConditionsFilter.js';

import TopBarEmploymentConditionsFilter from './TopBarEmploymentConditionsFilter.jsx';

const mapStateToProps = state => ({
  employmentConditions: state.reducer.employmentConditions,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter.selected,
});

const mapDispatchToProps = {
  changeEmploymentConditionsFilter,
};

const TopBarEmploymentConditionsFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarEmploymentConditionsFilter);

export default TopBarEmploymentConditionsFilterContainer;
