import { useCallback, useEffect, useMemo } from 'react';

import { closeNotificationPanel } from '@/actions/uiState';
import { useMediaQuery, useToggle } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { selectCurrentCompany } from '@/redux-store/currentCompany';
import { selectCurrentActiveNavBarElementId, selectShowNotificationPanel } from '@/redux-store/uiState';
import { selectUserPermissions } from '@/redux-store/userPermissions';
import { selectCurrentUser } from '@/selectors/currentUser.selectors';

import { getClasses, getSidebarNavs, setSidebarWidth } from './SideBar.utils';
import { useTalkChat } from './useTalkChat';

export const useSideBar = () => {
  const { unreadConversations } = useTalkChat();
  const user = useAppSelector(selectCurrentUser);
  const userPermissions = useAppSelector(selectUserPermissions);
  const company = useAppSelector(selectCurrentCompany);
  const currentActiveNavBarElementId = useAppSelector(selectCurrentActiveNavBarElementId);
  const showNotificationPanel = useAppSelector(selectShowNotificationPanel);
  const [isMobileMenuOpen, toggleMobileMenu, , closeMobileMenu] = useToggle();
  const { isAtLeastTabletLandscape } = useMediaQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSidebarWidth(userPermissions);
  }, [userPermissions.permissions]);

  useEffect(() => {
    if (isAtLeastTabletLandscape) {
      closeMobileMenu();
    } else {
      dispatch(closeNotificationPanel());
    }
  }, [isAtLeastTabletLandscape]);

  const classes = useMemo(
    () => getClasses(isMobileMenuOpen, showNotificationPanel),
    [isMobileMenuOpen, showNotificationPanel],
  );

  const items = useMemo(
    () => getSidebarNavs(userPermissions, user, company, currentActiveNavBarElementId, unreadConversations),
    [userPermissions, user, company, currentActiveNavBarElementId, unreadConversations],
  );

  const handleMenu = useCallback(() => {
    if (isAtLeastTabletLandscape) return;
    toggleMobileMenu();
  }, [isAtLeastTabletLandscape]);

  return { classes, closeMobileMenu, items, toggleMobileMenu: handleMenu };
};

export type UseSideBarValuesType = ReturnType<typeof useSideBar>;
