import { connect } from 'react-redux';

import { showDeleteLoanEmployeesProposalConfirmModal } from '@/actions/loanEmployeesProposals';
import { showModal } from '@/actions/uiState.js';

import LoanEmployeesProposalBlock from './LoanEmployeesProposalBlock';

const mapStateToProps = state => ({
  jobTitles: state.reducer.jobTitles,
  currentUser: state.reducer.currentUser.user,
});

const mapDispatchToProps = {
  showModal,
  showDeleteLoanEmployeesProposalConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanEmployeesProposalBlock);
