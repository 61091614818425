import { connect } from 'react-redux';

import { closeNotificationBar } from '@/actions/notificationBar.js';
import NotificationBar from '@/components/common/NotificationBar/NotificationBar.jsx';

const mapStateToProps = state => ({
  ...state.reducer.notificationBar,
});

const mapDispatchToProps = {
  close: closeNotificationBar,
};

const NotificationBarContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationBar);

export default NotificationBarContainer;
