import PropTypes from 'prop-types';
import { memo } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions.js';
import { AVAILABILITIES_DISPLAY_MODE_TYPE, SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';
import { DIRECTIONS } from '@/constants/ui';
import { SCHEDULE_TOOLTIP_OFFSET_Y } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import { messages } from './ScheduleSelectingButtons.messages';

const ScheduleSelectingButtons = (
  {
    deleteFromSelectedCells,
    canDelete,
    canDeselect,
    clearScheduleSelections,
    isTempleteScheduleViewSelected,
    restrictions,
    selectedDisplayModeType,
  },
  { intl },
) => {
  const isEditingScheduleDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const viewAllowsDeselect = [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE].includes(
    selectedDisplayModeType,
  );
  const viewAllowsDelete = [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE].includes(
    selectedDisplayModeType,
  );

  return (
    <>
      <FeatureWrapper hide={isEditingScheduleDisabled && !isTempleteScheduleViewSelected}>
        <Tooltip
          yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
          position={DIRECTIONS.BOTTOM}
          content={intl.formatMessage(messages.delete)}
          withoutIcon
        >
          <Button
            onClick={deleteFromSelectedCells}
            modifiers="reverse-red onlyIcon"
            disabled={!(canDelete && viewAllowsDelete)}
          >
            <i className="material-icons">delete</i>
          </Button>
        </Tooltip>
      </FeatureWrapper>
      <FeatureWrapper hide={isEditingScheduleDisabled && !isTempleteScheduleViewSelected}>
        <Tooltip
          yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
          position={DIRECTIONS.BOTTOM}
          content={intl.formatMessage(messages.deselect)}
          withoutIcon
        >
          <Button
            onClick={clearScheduleSelections}
            modifiers="reverse-blue onlyIcon"
            disabled={!(canDeselect && viewAllowsDeselect)}
          >
            <i className="material-icons">clear</i>
          </Button>
        </Tooltip>
      </FeatureWrapper>
    </>
  );
};

ScheduleSelectingButtons.propTypes = {
  deleteFromSelectedCells: PropTypes.func,
  canDelete: PropTypes.bool,
  canDeselect: PropTypes.bool,
  clearScheduleSelections: PropTypes.func,
  isTempleteScheduleViewSelected: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  selectedDisplayModeType: PropTypes.string,
};

ScheduleSelectingButtons.contextTypes = {
  intl: PropTypes.shape({}),
};

export default memo(ScheduleSelectingButtons);
