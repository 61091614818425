import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AvaOverlay from '@/components/scheduler/ScheduleTable/AvaMode/AvaOverlay/AvaOverlay.redux.js';
import { calculateAbsenceConnectorLength } from '@/components/scheduler/ScheduleTable/ScheduleAbsenceBlock/ScheduleAbsenceBlock.helpers';
import { getIndexOfLastConnectingBlock } from '@/components/scheduler/ScheduleTable/ScheduleShiftBlock/ScheduleShiftBlock.helpers';
import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences';
import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { getAbsenceStatusName } from '@/utils/absenceHelpers';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import {
  checkIsMonthlyScheduleView,
  checkIsWeekScheduleView,
  getScheduleBlockSize,
} from '@/utils/schedule/scheduleStyles/scheduleStyles';
import { isDateWithinEmploymentPeriod } from '@/utils/schedulerHelpers.js';

import ScheduleAbsenceBlock from '../ScheduleAbsenceBlock/ScheduleAbsenceBlock.jsx';
import { ScheduleFreeDay } from '../ScheduleFreeDay/ScheduleFreeDay';
import ScheduleOvertimeCollection from '../ScheduleOvertimeCollection/ScheduleOvertimeCollection.redux.js';
import ScheduleShift from '../ScheduleShift/ScheduleShift.redux.js';
import ScheduleTableItem from '../ScheduleTableItem/ScheduleTableItem.redux.js';
import ScheduleTableRowTitle from '../ScheduleTableRowTitle/ScheduleTableRowTitle.redux';
import messages from './messages.js';
import { getGroupJobTitleId, getPlaceholderPadding, getShiftsForDate } from './ScheduleTableRow.utils';

import './ScheduleTableRow.scss';

class ScheduleTableRow extends PureComponent {
  render() {
    const { props, context } = this;
    const {
      employeeId,
      employee,
      employeeShifts,
      locationId,
      mainDateStore,
      showModal,
      showConfirmModal,
      deleteShiftRequest,
      employeeAbsences,
      absenceTypes,
      employeeOvertimeCollections,
      isQuickPlanningEnabled,
      restrictions,
      availabilitiesModeEnabled,
      scheduleModeEnabled,
      employeeContracts,
      isLoanedEmployee,
      loanedEmployee,
      userLocations,
      freeDaysForEmployee,
      groupNodeId,
      showShiftsFromOtherLocation,
    } = props;

    const relevantEmployee = employee || loanedEmployee;
    const employeeName = `${relevantEmployee?.first_name} ${relevantEmployee?.last_name}`;
    const location = { id: locationId };
    const initialJobTitleId = getGroupJobTitleId(groupNodeId);

    const isEditDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);

    const addShift = (date, e) => {
      if (isEditDisabled) return;
      e.stopPropagation();
      showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
        employee: relevantEmployee,
        date,
        location,
        initialJobTitleId,
      });
    };

    const editShift = (shift, blocked, hideDeleteIcon) => {
      if (blocked || isEditDisabled) return;
      showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
        employee: relevantEmployee,
        shift,
        location,
        isLoanedEmployee,
        deleteDisabled: hideDeleteIcon,
      });
    };

    const editOvertimeCollection = (overtimeCollection, blocked) => {
      if (blocked || isQuickPlanningEnabled || isEditDisabled) return;
      showModal(ADD_SHIFT_AND_ABSENCE_MODAL, {
        employee: relevantEmployee,
        absence: overtimeCollection,
        isOvertimeCollection: true,
        date: moment(overtimeCollection.start_timestamp).format('YYYY-MM-DD'),
      });
    };

    const deleteShift = (shiftId, e) => {
      if (isEditDisabled) return;
      e.stopPropagation();
      showConfirmModal({
        title: context.intl.formatMessage(messages.deleteShiftTitle, {}),
        description: context.intl.formatMessage(messages.deleteShiftDescription, {
          employeeName,
        }),
        confirmText: context.intl.formatMessage(messages.delete, {}),
        confirmFunc: () => {
          deleteShiftRequest(relevantEmployee.id, shiftId);
        },
        confirmType: 'warning',
      });
    };

    const lastVisibleDate = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    const scheduleBlockSize = getScheduleBlockSize(mainDateStore.dateArray);

    return (
      <tr className="scheduleTable__row">
        <ScheduleTableRowTitle
          employeeId={employeeId}
          key={employeeId}
          locationId={location.id}
          isLoanedEmployee={isLoanedEmployee}
        />
        {mainDateStore.dateArray.map((date, index) => {
          const { visible: shiftsForDate } = getShiftsForDate(
            relevantEmployee,
            showShiftsFromOtherLocation,
            employeeShifts,
            location.id,
            date,
          );
          const relevantEmployeeAbsences = (employeeAbsences || []).filter(
            absence => !NOT_COUNTED_ABSENCE_STATUSES.includes(absence.status),
          );
          const absencesForDate = relevantEmployeeAbsences
            .filter(absence => absence.from === date || (index === 0 && absence.from < date && absence.to >= date))
            .map(absence => ({ ...absence, blockType: 'absence' }));
          const unfilteredAbsencesForDate = relevantEmployeeAbsences
            .filter(absence => absence.from === date || (absence.from < date && absence.to >= date))
            .map(absence => ({ ...absence, blockType: 'absence' }));
          const hasAbsence = relevantEmployeeAbsences.some(absence => absence.from < date && absence.to >= date);
          const hasAbsenceStartingToday = relevantEmployeeAbsences.some(absence => absence.from === date);
          const needsAbsencePadding = hasAbsence && !hasAbsenceStartingToday && index !== 0;

          const overtimeCollectionsForDate = (employeeOvertimeCollections || [])
            .filter(overtimeCollection => moment(overtimeCollection.start_timestamp).isSame(date, 'day'))
            .map(overtimeCollection => ({ ...overtimeCollection, blockType: 'overtimeCollection' }));

          const isMonthView = checkIsMonthlyScheduleView(mainDateStore.dateArray);
          const isWeekView = checkIsWeekScheduleView(mainDateStore.dateArray);
          const blocksSize = isMonthView ? 'month' : 'week';
          let isWithinHireAndReleaseDate = true;
          if (!isLoanedEmployee) {
            const { employment_conditions: employmentConditions } = employee;
            const { hire_date: hireDate, release_date: releaseDate } = employmentConditions;

            isWithinHireAndReleaseDate = isDateWithinEmploymentPeriod(date, hireDate, releaseDate);
          }
          const isAbsenceInDay = hasAbsence || hasAbsenceStartingToday;
          const isOvertimeInDay = !!overtimeCollectionsForDate.length;
          const isAbsenceDraft = unfilteredAbsencesForDate[0]?.status === 'draft';
          const absenceConnectorClasnames = classNames('k-absenceConnector', {
            'k-absenceConnector--month': isMonthView,
            'k-absenceConnector--week': isWeekView,
            'k-absenceConnector--draft': isAbsenceDraft,
          });

          const hasAbsencesAndOvertimeCollectionsInDay =
            unfilteredAbsencesForDate.length && overtimeCollectionsForDate.length;

          const lastConnectingAbsenceShiftBlockIndex =
            !hasAbsencesAndOvertimeCollectionsInDay &&
            (unfilteredAbsencesForDate.length === 1 || overtimeCollectionsForDate.length === 1)
              ? getIndexOfLastConnectingBlock(
                  shiftsForDate,
                  unfilteredAbsencesForDate[0] || overtimeCollectionsForDate[0],
                )
              : null;

          const connectorLength = lastConnectingAbsenceShiftBlockIndex
            ? calculateAbsenceConnectorLength(lastConnectingAbsenceShiftBlockIndex, isWeekView, isMonthView)
            : 0;

          return (
            <ScheduleTableItem
              key={`${employeeId}-${date}`}
              date={date}
              employee={relevantEmployee}
              location={location}
              showModal={showModal}
              size={scheduleBlockSize}
              paddingToAdd={getPlaceholderPadding(
                isAbsenceInDay,
                isOvertimeInDay,
                !!freeDaysForEmployee?.[date],
                isMonthView,
              )}
              isEditDisabled={isEditDisabled}
              isEmpty={isEmptyArray(shiftsForDate)}
              isDateWithinEmploymentPeriod={isWithinHireAndReleaseDate}
              isLoanedEmployee={isLoanedEmployee}
              initialJobTitleId={initialJobTitleId}
            >
              <AvaOverlay
                employeeId={employeeId}
                date={date}
                isMonthView={isMonthView}
                isAddDisabled={!isWithinHireAndReleaseDate}
              />
              {needsAbsencePadding ? <div className="k-absencePlaceholder" /> : null}
              {lastConnectingAbsenceShiftBlockIndex > 0 && (
                <div className={absenceConnectorClasnames} style={{ height: connectorLength }} />
              )}
              {absencesForDate.map(block => {
                const absencesDatesArray = Array.from(
                  { length: moment(block.to).diff(block.from, 'days') + 1 },
                  (_, idx) => moment(block.from).add(idx, 'days').format('YYYY-MM-DD'),
                );

                const absenceType = absenceTypes.find(type => type.id === block.type_id);
                const absenceName =
                  (isMonthView || block.absence_hours) && absencesDatesArray.length === 1
                    ? absenceType.short_name
                    : absenceType.name;
                const typeName = absenceType?.name;
                const statusName = getAbsenceStatusName(block.status, context.intl);

                if (scheduleModeEnabled) {
                  return (
                    <ScheduleAbsenceBlock
                      key={block.id}
                      absence={block}
                      absenceName={absenceName}
                      isMonthView={isMonthView}
                      date={date}
                      lastVisibleDate={lastVisibleDate}
                      employeeName={employeeName}
                      typeName={typeName}
                      statusName={statusName}
                      newVersion
                      size={blocksSize}
                      showModal={showModal}
                      isQuickPlanningEnabled={isQuickPlanningEnabled}
                      isEditDisabled={isEditDisabled}
                      isAvaMode={availabilitiesModeEnabled}
                    />
                  );
                }
              })}

              {scheduleModeEnabled &&
                overtimeCollectionsForDate.map(block => (
                  <ScheduleOvertimeCollection
                    key={block.id}
                    overtimeCollection={block}
                    handleEditAction={(overtimeCollection, blocked) =>
                      editOvertimeCollection(overtimeCollection, blocked)
                    }
                    size={blocksSize}
                    isMonthView={mainDateStore.dateArray.length > 10}
                    isEditDisabled={isEditDisabled}
                  />
                ))}

              {scheduleModeEnabled &&
                shiftsForDate.map(block => {
                  const relevantContract = !isLoanedEmployee && getRelevantContractForDate(employeeContracts, date);
                  const employeeJobTitleIds = !isLoanedEmployee
                    ? getJobTitleIdsFromContracts([relevantContract])
                    : block.job_title?.id;
                  const hideDeleteIcon = block.isLoaned && !userLocations.some(({ id }) => id === block.location.id);
                  return (
                    <ScheduleShift
                      key={block.id}
                      shiftId={block.id}
                      employeeId={employeeId}
                      employee_name={employeeName}
                      currentLocationId={location.id}
                      employeeJobTitleIds={employeeJobTitleIds}
                      handleAddAction={e => addShift(date, e)}
                      handleEditAction={(s, blocked) => editShift(s, blocked, hideDeleteIcon)}
                      handleDeleteAction={!hideDeleteIcon ? e => deleteShift(block.id, e) : undefined}
                      date={date}
                      size={scheduleBlockSize}
                      isEditDisabled={isEditDisabled}
                      isAddDisabled={!isWithinHireAndReleaseDate}
                      absencesForDate={[...unfilteredAbsencesForDate, ...overtimeCollectionsForDate]}
                      isAbsenceConnectingWithAnyScheduleBlock={connectorLength > 0}
                      isLoanedEmployee={isLoanedEmployee}
                    />
                  );
                })}

              {scheduleModeEnabled && <ScheduleFreeDay {...{ date, employeeId }} />}

              {(isAbsenceInDay || isOvertimeInDay) && freeDaysForEmployee?.[date] && shiftsForDate.length === 0 && (
                <div className="k-placeholder" />
              )}
            </ScheduleTableItem>
          );
        })}
      </tr>
    );
  }
}

ScheduleTableRow.contextTypes = {
  intl: PropTypes.shape({}),
};

ScheduleTableRow.propTypes = {
  employeeId: PropTypes.string,
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    shifts: PropTypes.shape({}),
    employment_conditions: PropTypes.shape({
      hire_date: PropTypes.string,
      release_date: PropTypes.string,
    }),
  }),
  employeeShifts: PropTypes.shape({}),
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  showModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  deleteShiftRequest: PropTypes.func,
  isQuickPlanningEnabled: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  employeeAbsences: PropTypes.arrayOf(PropTypes.shape({})),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  employeeOvertimeCollections: PropTypes.arrayOf(PropTypes.shape({})),
  locationId: PropTypes.string,
  availabilitiesModeEnabled: PropTypes.bool,
  scheduleModeEnabled: PropTypes.bool,
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoanedEmployee: PropTypes.bool,
  loanedEmployee: PropTypes.shape({}),
  freeDaysForEmployee: PropTypes.shape({}),
  groupNodeId: PropTypes.string,
  showShiftsFromOtherLocation: PropTypes.bool,
};

export default ScheduleTableRow;
