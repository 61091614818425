import { connect } from 'react-redux';

import {
  addCurrentUserShifts,
  addCurrentUserShiftsWithRepeat,
  editCurrentUserShift,
} from '@/actions/employeeTimesheet/employeeTimesheet';
import { toggleNonWorkingDays } from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState';
import { EMPLOYEE_SHIFT_MODAL } from '@/constants/modalTypes.js';

import EmployeeShiftModal from './EmployeeShiftModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === EMPLOYEE_SHIFT_MODAL,
  modalObject: state.reducer.uiState.modalObject[EMPLOYEE_SHIFT_MODAL],
  user: state.reducer.currentUser.user,
  mainDateStore: state.reducer.mainDateStore,
  nonWorkingDays: state.reducer.scheduleUIState.nonWorkingDays,
  locationSettings: state.reducer.settings.locationSettings,
  userContracts: state.reducer.contracts[state.reducer.currentUser.user.id],
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  hideModal,
  toggleNonWorkingDays,
  addCurrentUserShifts,
  addCurrentUserShiftsWithRepeat,
  editCurrentUserShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeShiftModal);
