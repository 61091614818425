import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Widget from '@/components/reports-NN/ReportWidgets/Widget/Widget';
import { ContextWithIntl } from '@/types/intl.types';
import { ReportStatistics } from '@/types/reports.types';

import { messages } from './messages';
import { computeFormattedValues, computeIndicators, getIndicatorTooltipContent } from './reportWidgets.helpers';

import './reportWidgets.scss';

export interface ReportWidgetsProps {
  statistics: ReportStatistics;
  customDate: { start: string; end: string };
  payoutSettingType: string;
}

const ReportWidgets = (
  { statistics, customDate, payoutSettingType }: ReportWidgetsProps,
  { intl }: ContextWithIntl,
) => {
  const { currentPeriod, previousPeriod } = statistics;

  const { start, end } = customDate;
  const isEcpPayoutSettingsType = payoutSettingType === 'ecp';

  const { workedMinutesValue, activeUsersValue, payoutSumValue } = useMemo(
    () => computeFormattedValues(currentPeriod, isEcpPayoutSettingsType),
    [currentPeriod, isEcpPayoutSettingsType],
  );

  const { absencesIndicator, activeUsersIndicator, workedMinutesIndicator, payoutSumIndicator } = useMemo(
    () => computeIndicators(currentPeriod, previousPeriod, isEcpPayoutSettingsType),
    [currentPeriod, previousPeriod, isEcpPayoutSettingsType],
  );

  const indicatorTooltipContent = getIndicatorTooltipContent(start, end, intl);
  const subTitle = intl.formatMessage(messages.widgetSubTitle, { isEcpPayoutSettingsType });

  return (
    <div className="k-reportWidgets">
      <Widget
        title={intl.formatMessage(messages.attendancesWidgetTitle)}
        value={activeUsersValue}
        indicator={activeUsersIndicator}
        indicatorContent={indicatorTooltipContent}
        subTitle={subTitle}
        tooltipMessage={intl.formatMessage(messages.attendancesTooltipMessage)}
      />
      <Widget
        title={intl.formatMessage(messages.absencesWidgetTitle)}
        value={currentPeriod.absences}
        indicator={absencesIndicator}
        indicatorContent={indicatorTooltipContent}
        subTitle={intl.formatMessage(messages.planTitle)}
        tooltipMessage={intl.formatMessage(messages.absencesTooltipMessage)}
      />
      <Widget
        title={intl.formatMessage(messages.workedHoursWidgetTitle)}
        value={workedMinutesValue}
        indicator={workedMinutesIndicator}
        indicatorContent={indicatorTooltipContent}
        subTitle={subTitle}
        tooltipMessage={intl.formatMessage(messages.workedHoursTooltipMessage)}
      />
      <Widget
        title={intl.formatMessage(messages.payoutSumWidgetTitle)}
        value={payoutSumValue}
        indicator={payoutSumIndicator}
        indicatorContent={indicatorTooltipContent}
        subTitle={subTitle}
        tooltipMessage={intl.formatMessage(messages.payoutTooltipMessage)}
      />
    </div>
  );
};

ReportWidgets.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default ReportWidgets;
