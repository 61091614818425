import { PayoutSettingType } from '@/constants/payrollSettings';
import { PlannedAndWorkedChartDay } from '@/types/reports.types';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

type FormattedData = { date: string; planned: number; worked?: number };

export const prepareDataForPlannedAndWorkedChart = (
  data: Record<string, PlannedAndWorkedChartDay>,
  chartMode: string,
  payoutSettingType: string,
): { date: string; planned: number; worked?: number }[] => {
  if (isEmptyObject(data)) return [];
  return Object.entries(data).reduce((acc, [date, dataForDay]) => {
    const formattedData: FormattedData = {
      date,
      planned: dataForDay[chartMode].planned,
    };

    if (payoutSettingType === PayoutSettingType.ECP) {
      formattedData.worked = dataForDay[chartMode].worked;
    }

    acc.push(formattedData);
    return acc;
  }, []);
};
