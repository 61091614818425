import PropTypes from 'prop-types';
import { useEffect } from 'react';

import NewPayrollExportModal from '@/components/common/exports/NewPayrollExportModal/NewPayrollExportModal.redux';
import ChoosePayrollSummaryValuesModal from '@/components/newPayrollViews/ChoosePayrollSummaryValuesModal/ChoosePayrollSummaryValuesModal.redux';
import PayrollLocationFiltersModal from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationFiltersModal/PayrollLocationFiltersModal.redux';
import PayrollSettingsModal from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.redux';
import ImportShiftsAndAttendancesModal from '@/components/scheduler/modals/ImportShiftsAndAttendancesModal/ImportShiftsAndAttendancesModal.redux.js';
import { REPORTS_FILTERS_MODAL } from '@/constants/modalTypes';
import { useChangeDateByArrow } from '@/hooks';

import PayrollLocationTable from '../PayrollLocationTable/PayrollLocationTable.redux';
import PayrollLocationTopBar from '../PayrollLocationTopBar/PayrollLocationTopBar.redux';

import './PayrollLocationView.scss';

const PayrollLocationView = ({
  showModal,
  alreadyLoadedData,
  getPayrollLocationViewData,
  enableLoadButton,
  isLoadButtonEnabled,
}) => {
  useChangeDateByArrow(enableLoadButton);
  useEffect(() => {
    if (!alreadyLoadedData) {
      showModal(REPORTS_FILTERS_MODAL);
    }
  }, []);

  return (
    <div className="k-payrollLocationView">
      <PayrollLocationFiltersModal />
      <PayrollSettingsModal hideShiftsNoExtra showSaveButton onSave={getPayrollLocationViewData} />
      <NewPayrollExportModal shouldBaseExportsOnOldFiltersState={isLoadButtonEnabled} />
      <ImportShiftsAndAttendancesModal />
      <ChoosePayrollSummaryValuesModal />
      <PayrollLocationTopBar />
      <div className="k-payrollLocationView__table">
        <PayrollLocationTable />
      </div>
    </div>
  );
};

PayrollLocationView.propTypes = {
  showModal: PropTypes.func,
  alreadyLoadedData: PropTypes.bool,
  getPayrollLocationViewData: PropTypes.func,
  enableLoadButton: PropTypes.func,
  isLoadButtonEnabled: PropTypes.bool,
};

export default PayrollLocationView;
