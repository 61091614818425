import { EMPLOYMENT_CONDITIONS_CREATE } from '@/constants/Permissions';
import {
  getAbsencesCalculatedFromEmploymentConditions,
  getAbsencesForPayroll,
  getAbsencesPerDatesFromAbsences,
} from '@/utils/absenceHelpers';
import {
  calculateTotalWageFromShifts,
  getBalanceForScheduleCycle,
  getNormForDate,
  getWorkedHoursFromShifts,
} from '@/utils/schedulerHelpers';

const calculateRowStatisticsForEmployee = (
  employee,
  userPermissions,
  dateStore,
  absences,
  absenceTypes,
  availabilityTypes,
  monthlyNorms,
  holidays,
  employeeContracts,
  jobTitles,
) => {
  const {
    shifts: shiftsFromLocation,
    employment_conditions: employmentConditions,
    shifts_for_other_locations: shiftsForOtherLocations,
  } = employee;
  const allShifts = [...shiftsFromLocation, ...shiftsForOtherLocations].reduce((acc, shift) => {
    const existingShift = acc.some(s => s.id === shift.id);

    if (!existingShift) {
      acc.push(shift);
    }

    return acc;
  }, []);
  const { dateArray } = dateStore;
  const [date] = dateArray;

  const relevantShifts = allShifts.filter(shift => dateArray.includes(shift.date));
  const relevantAvailabilityBlocks = employee.availability_blocks.filter(availabilityBlock =>
    dateArray.includes(availabilityBlock.date),
  );
  const relevantAbsences = getAbsencesForPayroll(absences, absenceTypes, dateStore);
  const absencesPerDate = getAbsencesPerDatesFromAbsences(relevantAbsences, dateStore);
  const absencesWithoutShift = getAbsencesCalculatedFromEmploymentConditions(
    absencesPerDate,
    employee.employment_conditions,
  );

  const hasEmploymentConditionsEnabled = userPermissions?.permissions.includes(EMPLOYMENT_CONDITIONS_CREATE);
  const validateWorkingRulesForEmployee = employee?.employment_conditions?.validate_working_rules;
  const hasWeeklyWorkingMinutes = !!employee?.employment_conditions?.weekly_working_minutes;

  const showScheduleCycleBalance =
    dateStore.dateMode === 'month' &&
    hasEmploymentConditionsEnabled &&
    validateWorkingRulesForEmployee &&
    hasWeeklyWorkingMinutes;

  const workedHoursForSelectedPeriod = getWorkedHoursFromShifts(
    relevantShifts,
    relevantAvailabilityBlocks,
    availabilityTypes,
    absencesWithoutShift,
    employmentConditions,
  );

  const totalWageForSelectedPeriod = calculateTotalWageFromShifts(relevantShifts, employeeContracts, jobTitles);

  const normForPeriod = getNormForDate(date, monthlyNorms, employee, holidays).totalHours;

  const balanceDuringPeriod = getBalanceForScheduleCycle(
    date,
    employee,
    workedHoursForSelectedPeriod,
    monthlyNorms,
    holidays,
  );

  return {
    showScheduleCycleBalance,
    workedHoursForSelectedPeriod,
    totalWage: totalWageForSelectedPeriod,
    norm: normForPeriod,
    balance: balanceDuringPeriod,
  };
};

export const calculateRowStatisticsForEmployees = (
  employees,
  userPermissions,
  dateStore,
  scheduleAbsences,
  absenceTypes,
  availabilityTypes,
  calendarData,
  contracts,
  jobTitles,
) => {
  const { monthlyNorms, holidays } = calendarData;
  const result = employees.map(employee => {
    if (!employee) return {};
    const absences = scheduleAbsences[employee.id] || [];
    return calculateRowStatisticsForEmployee(
      employee,
      userPermissions,
      dateStore,
      absences,
      absenceTypes,
      availabilityTypes,
      monthlyNorms,
      holidays,
      contracts[employee.id] || [],
      jobTitles,
    );
  });
  return result;
};
