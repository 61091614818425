import { defineMessages } from 'react-intl';

import { PRODUCTION_QUOTAS_GET } from '@/constants/Permissions.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';

const messages = defineMessages({
  day: {
    id: 'common.day',
    defaultMessage: 'Dzień',
  },
  location: {
    id: 'common.location',
    defaultMessage: 'Lokalizacja',
  },
  from: {
    id: 'common.from',
    defaultMessage: 'Od',
  },
  to: {
    id: 'common.to',
    defaultMessage: 'Do',
  },
  jobTitle: {
    id: 'common.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  wage: {
    id: 'common.wage',
    defaultMessage: 'Stawka [PLN/h]',
  },
  sumHours: {
    id: 'common.sumHours',
    defaultMessage: 'Suma godzin',
  },
  acords: {
    id: 'common.acords',
    defaultMessage: 'Akordy',
  },
  bonus: {
    id: 'common.bonus',
    defaultMessage: 'Bonus [PLN]',
  },
  bonuses: {
    id: 'common.bonuses',
    defaultMessage: 'Bonusy',
  },
  totalPay: {
    id: 'common.totalPay',
    defaultMessage: 'Do wypłaty [PLN]',
  },
  totalToPay: {
    id: 'common.totalToPay',
    defaultMessage: 'Razem do wypłaty',
  },
  fromTo: {
    id: 'common.fromTo',
    defaultMessage: 'Od/Do',
  },
  workingHours: {
    id: 'common.workingHours',
    defaultMessage: 'Godziny pracy',
  },
  acordsSum: {
    id: 'common.acordsSum',
    defaultMessage: 'Suma akordów',
  },
  breaks: {
    id: 'common.breaks',
    defaultMessage: 'Przerwy',
  },
  fromToCompare: {
    id: 'common.fromToCompare',
    defaultMessage: 'Grafik pracy / Czas pracy',
  },
  nighthours: {
    id: 'common.nighthours',
    defaultMessage: 'Godziny nocne',
  },
  plannedHours: {
    id: 'common.plannedHours',
    defaultMessage: 'Realizacja / Plan',
  },
  plannedDiff: {
    id: 'common.plannedDiff',
    defaultMessage: 'Różnica',
  },
  employee: {
    id: 'common.employee',
    defaultMessage: 'Pracownik',
  },
  overtime50: {
    id: 'common.overtime50',
    defaultMessage: 'Nadgodziny (50%)',
  },
  overtime100: {
    id: 'common.overtime100',
    defaultMessage: 'Nadgodziny (100%)',
  },
  overtimeSum: {
    id: 'common.overtimeSum',
    defaultMessage: 'Nadgodziny ŚT',
  },
  absences: {
    id: 'common.absences',
    defaultMessage: 'Nieobecności',
  },
  locations: {
    id: 'companymanage.employeesHeader.locations',
    defaultMessage: 'Lokalizacje',
  },
});

export const payrollLocationTableColumns = {
  headers: [
    messages.employee,
    messages.jobTitle,
    messages.location,
    messages.wage,
    messages.absences,
    messages.sumHours,
    messages.plannedHours,
    messages.plannedDiff,
    messages.nighthours,
    messages.overtime50,
    messages.overtime100,
    messages.overtimeSum,
    messages.bonus,
    messages.totalPay,
  ],
  colgroup: ['18', '16', '16', '10', '10', '10', '10', '8', '12', '10', '10', '10', '10', '10'],
  classNames: [
    'name',
    'stanow',
    'stanow',
    'stawka',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'sumgodz',
    'bonus',
    'dowyplaty',
  ],
  summary: [
    false,
    false,
    false,
    false,
    messages.absences,
    messages.sumHours,
    messages.plannedHours,
    messages.plannedDiff,
    messages.nighthours,
    messages.overtime50, // TODO: ask Radek how to name this
    messages.overtime100,
    messages.overtimeSum,
    messages.bonuses,
    messages.totalToPay,
  ],
  sort: [
    'name',
    'jobTitle',
    'location',
    'wage',
    'absences',
    'sumHours',
    'sumHours',
    'plannedDiff',
    'sumNightHours',
    'sumOvertime50',
    'sumOvertime100',
    'overtimeSum',
    'sumBonuses',
    'sumPayout',
  ],
  options: [
    { id: 1, title: messages.jobTitle, columns: [1] },
    { id: 2, title: messages.location, columns: [2] },
    { id: 3, title: messages.wage, columns: [3] },
    { id: 4, title: messages.absences, columns: [4] },
    { id: 5, title: messages.sumHours, columns: [5] },
    { id: 6, title: messages.plannedHours, columns: [6] },
    { id: 7, title: messages.plannedDiff, columns: [7] },
    { id: 8, title: messages.nighthours, columns: [8] },
    { id: 9, title: messages.overtime50, columns: [9] },
    { id: 10, title: messages.overtime100, columns: [10] },
    { id: 11, title: messages.overtimeSum, columns: [11] },
    { id: 12, title: messages.bonus, columns: [12] },
    { id: 13, title: messages.totalPay, columns: [13] },
  ],
  restrictions: [[], [], [BUDGET_INFO_HIDE], [], [], [], [], [], [], [], [], [BUDGET_INFO_HIDE], [BUDGET_INFO_HIDE]],
  permissions: [[], [], [], [], [], [], [], [], [], [], [], [], [], []],
};

export const payrollTableColumns = {
  headers: [
    messages.day,
    messages.location,
    messages.from,
    messages.to,
    messages.workingHours,
    messages.jobTitle,
    messages.wage,
    messages.breaks,
    messages.absences,
    messages.sumHours,
    messages.plannedHours,
    messages.plannedDiff,
    messages.nighthours,
    messages.acords,
    messages.bonus,
    messages.overtime50,
    messages.overtime100,
    messages.overtimeSum,
    messages.totalPay,
  ],
  colgroup: [
    '14',
    '12',
    '6',
    '6',
    '10',
    '12',
    '10',
    '10',
    '12',
    '10',
    '10',
    '10',
    '10',
    '10',
    '10',
    '11',
    '11',
    '11',
    '10',
  ],
  summary: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    messages.breaks,
    messages.absences,
    messages.sumHours,
    messages.plannedHours,
    messages.plannedDiff,
    messages.nighthours,
    messages.acordsSum,
    messages.bonuses,
    messages.overtime50,
    messages.overtime100,
    messages.overtimeSum,
    messages.totalToPay,
  ],
  options: [
    { id: 1, title: messages.location, columns: [1] },
    { id: 2, title: messages.fromTo, columns: [2, 3] },
    { id: 3, title: messages.fromToCompare, columns: [4] },
    { id: 4, title: messages.jobTitle, columns: [5] },
    { id: 5, title: messages.wage, columns: [6] },
    { id: 6, title: messages.breaks, columns: [7] },
    { id: 7, title: messages.absences, columns: [8] },
    { id: 8, title: messages.sumHours, columns: [9] },
    { id: 9, title: messages.plannedHours, columns: [10] },
    { id: 10, title: messages.plannedDiff, columns: [11] },
    { id: 11, title: messages.nighthours, columns: [12] },
    { id: 12, title: messages.acords, columns: [13] },
    { id: 13, title: messages.bonus, columns: [14] },
    { id: 14, title: messages.overtime50, columns: [15] },
    { id: 15, title: messages.overtime100, columns: [16] },
    { id: 16, title: messages.overtimeSum, columns: [17] },
    { id: 17, title: messages.totalPay, columns: [18] },
  ],
  restrictions: [
    [],
    [],
    [],
    [],
    [BUDGET_INFO_HIDE],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [BUDGET_INFO_HIDE],
    [BUDGET_INFO_HIDE],
    [],
    [],
    [],
  ],
  permissions: [[], [], [], [], [], [], [], [], [], [], [], [PRODUCTION_QUOTAS_GET], [], [], [], [], []],
};

export const JOB_TITLE_KEY = 'jobTitle';
export const HOURLY_WAGE_KEY = 'hourlyWage';
export const LOCATIONS_KEY = 'locations';
export const ROLE_KEY = 'role';
export const PIN_KEY = 'pin';
export const OPTIONS_KEY = 'options';
export const HIRE_DATE = 'hireDate';
export const RELEASE_DATE = 'releaseDate';

export const employeesTable = {
  headers: [
    { key: 'jobTitle', id: 'companymanage.employeesHeader.jobTitle', defaultMessage: 'Stanowisko' },
    { key: 'hourlyWage', id: 'companymanage.employeesHeader.hourlyWage', defaultMessage: 'Stawka[PLN/h]' },
    { key: 'locations', id: 'companymanage.employeesHeader.locations', defaultMessage: 'Lokalizacje' },
    { key: 'role', id: 'companymanage.employeesHeader.role', defaultMessage: 'Rola' },
    { key: 'pin', id: 'companymanage.employeesHeader.pin', defaultMessage: 'Pin' },
    { key: 'email', id: 'companymanage.employeesHeader.email', defaultMessage: 'E-mail' },
    { key: 'phone', id: 'companymanage.employeesHeader.phone', defaultMessage: 'Telefon' },
    {
      key: 'employmentConditions',
      id: 'companymanage.employeesHeader.employmentConditions',
      defaultMessage: 'Warunki zatrudnienia',
    },
    { key: 'referenceId', id: 'companymanage.employeesHeader.referenceId', defaultMessage: 'Kod referencyjny' },
    { key: 'nfc', id: 'companymanage.employeesHeader.nfc', defaultMessage: 'NFC' },
    { key: 'createdAt', id: 'companymanage.employeesHeader.createdAt', defaultMessage: 'Data utworzenia' },
    { key: 'hireDate', id: 'companymanage.employeesHeader.hireDate', defaultMessage: 'Data zatrudnienia' },
    { key: 'releaseDate', id: 'companymanage.employeesHeader.releaseDate', defaultMessage: 'Data zwolnienia' },
    { key: 'options', id: 'companymanage.employeesHeader.options', defaultMessage: 'Opcje', cannotBeHidden: true },
  ],
  colgroup: ['18', '24', '18', '18', '10', '18', '16', '20', '20', '16', '18', '18', '18', '32'],
  sortingKeys: {
    name: 'compareName',
    jobTitle: 'compareJobTitles',
    hourlyWage: 'compareHourlyWages',
    locations: 'compareLocations',
    role: 'role',
    pin: 'pin',
    email: 'email',
    phone: 'comparePhone',
    employmentConditions: 'employmentConditionsName',
    referenceId: 'compareReferenceId',
    nfc: 'nfcCode',
    createdAt: 'createdAt',
    hireDate: 'hireDate',
    releaseDate: 'releaseDate',
  },
  restrictions: { hourlyWage: [BUDGET_INFO_HIDE] },
  permissions: {},
  defaultVisibleColumns: [
    { key: JOB_TITLE_KEY, id: 'companymanage.employeesHeader.jobTitle', defaultMessage: 'Stanowisko' },
    { key: HOURLY_WAGE_KEY, id: 'companymanage.employeesHeader.hourlyWage', defaultMessage: 'Stawka[PLN/h]' },
    { key: LOCATIONS_KEY, id: 'companymanage.employeesHeader.locations', defaultMessage: 'Lokalizacje' },
    { key: ROLE_KEY, id: 'companymanage.employeesHeader.role', defaultMessage: 'Rola' },
    { key: PIN_KEY, id: 'companymanage.employeesHeader.pin', defaultMessage: 'Pin' },
    { key: OPTIONS_KEY, id: 'companymanage.employeesHeader.options', defaultMessage: 'Opcje' },
  ],
};
