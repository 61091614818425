import classNames from 'classnames';
import { PropTypes } from 'prop-types';

import TimeRangeInput from '../TimeRangeInput.jsx';
import TimeSingleInput from '../TimeSingleInput.jsx';

const MDTimeInput = props => {
  const modifiers = Array.isArray(props.modifiers) ? props.modifiers : props.modifiers.split(' ');
  const className = classNames(
    'kmd-textInput',
    props.className,
    ...modifiers.map(modifier => `kmd-textInput--${modifier}`),
    {
      'kmd-textInput--disabled': props.disabled,
      'kmd-textInput--valid': props.value && !props.errorMessage,
      'kmd-textInput--error': props.errorMessage,
      'kmd-textInputModal': props.isModalInput,
      'kmd-textInput--readOnly': props.readOnly,
    },
  );

  const iconClassname = classNames('k-icon', props.icon, {
    'k-icon--clicky': !!props.onIconClick,
  });

  const inputChanged = e => {
    const { value } = e.target;
    props.onChange(value);
  };

  return (
    <div className={className}>
      <div className="kmd-textInput__inputContainer">
        {props.short ? (
          <TimeSingleInput
            alwaysShowMask={props.alwaysShowMask}
            max={props.max}
            value={props.value}
            onChange={inputChanged}
            testId={props.testId}
            className={props.className}
            onBlur={props.onBlur}
          />
        ) : (
          <TimeRangeInput
            alwaysShowMask={props.alwaysShowMask}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            modifiers={[...props.modifiers, 'alignLeft']}
            classNameInput="kmd-textInput"
            testId={props.testId}
          />
        )}
        <label>{props.label}</label>
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            if (props.onIconClick) props.onIconClick();
          }}
          className="kmd-textInput__icon"
          tabIndex={-1}
          data-test={props.testId && `${props.testId}--button`}
        >
          <i className={iconClassname} style={props.iconStyle} />
        </button>
      </div>
      <span className="kmd-textInput__bar" />
      <div className="kmd-textInput__error">{props.errorMessage}</div>
    </div>
  );
};

MDTimeInput.defaultProps = {
  style: {},
  value: '',
  short: true,
  modifiers: [],
};

MDTimeInput.propTypes = {
  alwaysShowMask: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({}),
  rangeInputStyle: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
  isModalInput: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  short: PropTypes.bool,
  iconStyle: PropTypes.shape({}),
  testId: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default MDTimeInput;
