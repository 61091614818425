import { getCompanyLimitsUsage } from '@/actions/companyLimitsUsage/companyLimitsUsage';
import * as AT from '@/constants/ActionTypes';

const actionsThatChangeCompanyLimitUsage = [
  AT.ADD_NEW_EMPLOYEE,
  AT.DELETE_EMPLOYEE,
  AT.DELETE_EMPLOYEE_SET,
  AT.ADD_NEW_LOCATION,
  AT.DELETE_LOCATION,
  AT.DELETE_LOCATION_SET,
  AT.ADD_NEW_JOBTITLE,
  AT.DELETE_JOBTITLE,
  AT.DELETE_JOBTITLE_SET,
];

const companyLimitsUsageMiddleware = store => next => action => {
  const returnState = next(action);
  // From this point we have data after action
  if (actionsThatChangeCompanyLimitUsage.includes(action.type)) {
    store.dispatch(getCompanyLimitsUsage());
  }
  return returnState;
};
export default companyLimitsUsageMiddleware;
