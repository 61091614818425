import { PropTypes } from 'prop-types';
import { useCallback, useEffect } from 'react';

import ColorPicker from '@/components/common/ColorPicker.jsx';
import { MDCheckbox, MDTextInput, MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers';

import {
  createAvaTypeObject,
  getInitialState,
  getOptionsForAvaTypes,
  getUpdatedStateForEditAvaType,
  INPUT_MODIFIERS,
  MODAL_MODIFIERS,
} from './AddAvaTypeModal.helpers';
import { messages } from './AddAvaTypeModal.messages';

const AddAvaTypeModal = (props, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState, intl);

  const changeAvaCountHours = useCallback(hours => handlers.changeInput(createEvent('avaCountHours', hours)), []);

  const changeColor = useCallback(color => handlers.changeInput(createEvent('color', color)), []);

  useEffect(() => {
    if (!props.editedId || !props.visible) return;
    handlers.updateState(getUpdatedStateForEditAvaType(props.editedId, props.userCustomTypes));
  }, [props.editedId, props.visible]);

  const validateAll = async () => {
    const inputs = ['avaTypeName', 'avaShortName'];
    if (state.count_hours_in_payroll) inputs.push('avaCountHours');
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err);
  };

  const clearModal = useCallback(() => {
    handlers.setInitState();
  }, []);

  const hideModal = useCallback(() => {
    props.toggleModal('avaTypes');
  }, []);

  const submit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    const avaTypeObject = createAvaTypeObject(state);
    if (props.editedId) {
      props.changeAvaType({ ...avaTypeObject, id: props.editedId });
    } else {
      props.addAvaType(avaTypeObject);
    }
    hideModal();
  }, [state, props.editedId]);

  return (
    <MDKadroModal
      show={props.visible}
      title={intl.formatMessage(messages[props.editedId ? 'editAva' : 'addAva'])}
      onHide={hideModal}
      onHideEnd={clearModal}
      onSubmit={submit}
      modifiers={MODAL_MODIFIERS}
    >
      <MDTextInput
        value={state.avaTypeName}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        id="avaTypeName"
        label={intl.formatMessage(messages.avaTypeName)}
        errorMessage={state.errors.avaTypeName}
        modifiers={INPUT_MODIFIERS}
      />
      <MDTextInput
        value={state.avaShortName}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        id="avaShortName"
        label={intl.formatMessage(messages.shortName)}
        errorMessage={state.errors.avaShortName}
        modifiers={INPUT_MODIFIERS}
      />
      <fieldset className="form-group">
        <label htmlFor="nazwa" className="k-textInput__label k-textInput__label--side">
          {intl.formatMessage(messages.color)}
        </label>
        <ColorPicker color={state.color} colorFunc={changeColor} />
      </fieldset>
      {getOptionsForAvaTypes(state, intl).map(option => (
        <MDCheckbox
          key={option.id}
          id={option.id}
          text={option.text}
          value={option.value}
          onChange={handlers.changeInput}
          reverse
        />
      ))}
      {state.count_hours_in_payroll && (
        <MDTimeInput
          label={intl.formatMessage(messages.count_hours)}
          value={state.avaCountHours}
          onChange={changeAvaCountHours}
          onBlur={handlers.validateInput}
          errorMessage={state.errors.avaCountHours}
          modifiers={INPUT_MODIFIERS}
          short
        />
      )}
    </MDKadroModal>
  );
};

AddAvaTypeModal.contextTypes = {
  intl: PropTypes.shape({}),
};

AddAvaTypeModal.propTypes = {
  changeAvaType: PropTypes.func,
  addAvaType: PropTypes.func,
  toggleModal: PropTypes.func,
  visible: PropTypes.bool,
  editedId: PropTypes.string,
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AddAvaTypeModal;
