import { PropTypes } from 'prop-types';

const EmploymentConditionsTableRowTitle = props => {
  const { employmentCondition } = props;
  return (
    <tr className="h-sorce2">
      <td className="middle">
        <div className="col_fullname col_fullname--padLeft">{employmentCondition.name}</div>
      </td>
    </tr>
  );
};

EmploymentConditionsTableRowTitle.propTypes = {
  employmentCondition: PropTypes.shape({}),
  listsUi: PropTypes.shape({}),
};

export default EmploymentConditionsTableRowTitle;
