import { connect } from 'react-redux';

import { userApplyOpenShift, userRemoveApplicationOpenShift } from '@/actions/openShifts.js';
import { closeApplyForOpenShiftModal } from '@/actions/uiState.js';

import ApplyForOpenShiftModal from './ApplyForOpenShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showApplyForOpenShiftModal,
  modalObject: state.reducer.uiState.applyForOpenShiftModalObject,
  userEmployees: state.reducer.userEmployees,
  userLocations: state.reducer.userLocations,
  currentUser: state.reducer.currentUser,
});

const mapDispatchToProps = {
  onHide: closeApplyForOpenShiftModal,
  userApplyOpenShift,
  userRemoveApplicationOpenShift,
};
const ApplyForOpenShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyForOpenShiftModal);

export default ApplyForOpenShiftModalContainer;
