/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MDSelect, MDTextArea, MDTextInput, MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import MDEmployeeSelectBlock from '@/components/common/MDEmployeesSelectBlock/MDEmployeeSelectBlock';
import { modalMessages } from '@/components/scheduler/modals/AddOpenShiftModal/AddOpenShiftModal.messages';
import { MODAL_MODIFIER } from '@/constants/modalModifiers';
import { useAppSelector } from '@/redux-store';
import { selectCompanyEmploymentConditions } from '@/redux-store/employmentConditions';

type JobTitle = {
  id: string;
  title: string;
};

type ErrorState = {
  shifts_required: string;
  working_hours: string;
};

type State = {
  comment: string;
  working_hours: string;
  jobTitleId: string;
  shifts_required: number;
  errors: ErrorState;
  selectedEmployees: any[];
  currentStep: number;
  locationGroups: any[];
  employmentConditions: any[];
};

type ModalObject = {
  openShiftData: {
    id?: string;
    comment?: string;
    working_hours?: string;
    job_title?: {
      id: string;
    };
    shifts_required?: number;
    location: {
      id: string;
      name?: string;
    };
  };
  date: string;
  location: {
    id: string;
    name?: string;
  };
};

type AddOpenShiftBaseStepProps = {
  state: State;
  selectedJobTitles: JobTitle[];
  changeJobTitle: (e: any) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  filteredShiftBlockOptions: string[];
  changeWorkingHours: (hours: string) => void;
  modalObject: ModalObject;
  availableEmployees: any[];
  getSelectedEmployees: () => any[];
  handleChangeList: (selectedEmployee: any) => void;
  selectSome: () => void;
  deselectAll: () => void;
  locationGroupsNames: string[];
  openShiftEmploymentConditionsNames: string[];
};

const MAX_INPUT_LENGTH = 57;

const AddOpenShiftBaseStep = ({
  state,
  selectedJobTitles,
  changeJobTitle,
  handleInputChange,
  filteredShiftBlockOptions,
  changeWorkingHours,
  modalObject,
  availableEmployees,
  getSelectedEmployees,
  handleChangeList,
  selectSome,
  deselectAll,
  locationGroupsNames,
  openShiftEmploymentConditionsNames,
}: AddOpenShiftBaseStepProps) => {
  const intl = useIntl();
  const locationGroupsLabel = useMemo(
    () =>
      locationGroupsNames?.length === 1
        ? intl.formatMessage(modalMessages.locationGroup)
        : intl.formatMessage(modalMessages.locationGroups),
    [locationGroupsNames, intl],
  );
  const employmentConditionsLabel = useMemo(
    () =>
      openShiftEmploymentConditionsNames?.length === 1
        ? intl.formatMessage(modalMessages.employmentCondition)
        : intl.formatMessage(modalMessages.employmentConditions),
    [openShiftEmploymentConditionsNames, intl],
  );
  const companyEmploymentConditionsNames = useAppSelector(selectCompanyEmploymentConditions).map(({ name }) => name);
  const employmentsConditionsDisplayNames = useMemo(
    () =>
      modalObject.openShiftData.id && locationGroupsNames
        ? openShiftEmploymentConditionsNames?.join(', ') || companyEmploymentConditionsNames.join(', ')
        : '',
    [
      modalObject.openShiftData.id,
      locationGroupsNames,
      openShiftEmploymentConditionsNames,
      companyEmploymentConditionsNames,
    ],
  );

  return (
    <>
      <MDSelect
        name={<FormattedMessage id="openShift.addModal.jobTitle" defaultMessage="Stanowisko" />}
        defaultValue={state.jobTitleId}
        options={selectedJobTitles.map(jobTitle => ({
          key: jobTitle.title,
          value: jobTitle.id,
        }))}
        onChange={changeJobTitle}
        withSearch
        closeOnClick
      />
      <MDTextInput
        id="shifts_required"
        type="number"
        label={<FormattedMessage id="openShift.addModal.shifts_required" defaultMessage="Liczba pracowników" />}
        value={state.shifts_required}
        onChange={handleInputChange}
        errorMessage={state.errors.shifts_required}
        modifiers={[MODAL_MODIFIER]}
        isNumberInteger
      />
      <MDTimeRangeInput
        value={state.working_hours}
        options={filteredShiftBlockOptions}
        onChange={changeWorkingHours}
        label={<FormattedMessage id="openShift.addModal.workingHours" defaultMessage="Czas" />}
        modifiers={[MODAL_MODIFIER]}
        errorMessage={state.errors.working_hours}
      />
      {modalObject.openShiftData.id && locationGroupsNames && (
        <>
          <MDTextInput
            id="locationGroups"
            type="string"
            label={locationGroupsLabel}
            value={locationGroupsNames.join(', ')}
            modifiers={[MODAL_MODIFIER]}
            disabled
            readOnly
            maxInputLength={MAX_INPUT_LENGTH}
          />
          <MDTextInput
            id="location"
            type="string"
            label={<FormattedMessage id="openShift.addModal.location" defaultMessage="Lokalizacja" />}
            value={modalObject.openShiftData.location.name}
            modifiers={[MODAL_MODIFIER]}
            disabled
            readOnly
            maxInputLength={MAX_INPUT_LENGTH}
          />
          <MDTextInput
            id="employmentConditions"
            type="string"
            label={employmentConditionsLabel}
            value={employmentsConditionsDisplayNames}
            modifiers={[MODAL_MODIFIER]}
            disabled
            readOnly
            maxInputLength={MAX_INPUT_LENGTH}
          />
        </>
      )}
      {modalObject.openShiftData.id && (
        <MDEmployeeSelectBlock
          neededCount={state.shifts_required}
          options={availableEmployees}
          selectedItems={getSelectedEmployees()}
          shouldBeSorted={false}
          handleChange={handleChangeList}
          handleSelectAll={selectSome}
          handleDeselectAll={deselectAll}
        />
      )}
      <MDTextArea
        label={<FormattedMessage id="openShift.addModal.note" defaultMessage="Notatka" />}
        placeholder={intl.formatMessage(modalMessages.notePlaceholder, {})}
        value={state.comment}
        onChange={handleInputChange}
        id="comment"
        name="comment"
      />
    </>
  );
};

export default AddOpenShiftBaseStep;
