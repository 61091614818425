import { Absence, AbsenceType, AvailabilityBlock } from 'kadro-helpers/lib/types';
import { useMemo } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { OvertimeCollection } from '@/types';
import { AvailabilityType } from '@/types/availability/availabilityTypes.types';
import { getAvailabilityName } from '@/utils/availabilitiesHelpers';

interface AttendancesUnavailabilityWarningContentProps {
  availability: AvailabilityBlock;
  customAvaTypes: AvailabilityType[];
  absence: Absence;
  absenceTypes: AbsenceType[];
  overtimeCollections: OvertimeCollection[];
}

const AttendancesUnavailabilityWarningContent: React.FC<AttendancesUnavailabilityWarningContentProps> = ({
  availability,
  customAvaTypes,
  absence,
  absenceTypes,
  overtimeCollections,
}) => {
  const availabilityName = availability ? getAvailabilityName(availability, customAvaTypes) : null;
  const absenceName = absence ? absenceTypes.find(type => type.id === absence.type_id)?.name : null;
  const absenceHours = absence?.absence_hours;
  const overtimeCollectionMessages = useMemo(
    () =>
      overtimeCollections?.map(overtimeCollection => {
        const startHour = overtimeCollection.start_timestamp.split(' ')[1].slice(0, 5);
        const endHour = overtimeCollection.end_timestamp.split(' ')[1].slice(0, 5);
        const overtimeCollectionHours = `${startHour}-${endHour}`;
        return (
          <FormattedMessage
            key={overtimeCollection.id}
            id="attendance.warningIcon.overtimeCollection"
            defaultMessage="Pracownik w tym dniu ma zaplanowany urlop: Odbiór nadgodzin w godzinach {overtimeCollectionHours}"
            values={{ overtimeCollectionHours }}
            tagName="div"
          />
        );
      }),
    [overtimeCollections],
  );

  return (
    <div className="k-attendanceWarningIcon__smallPopover">
      {availabilityName && (
        <FormattedMessage
          id="attendance.warningIcon.unavailability"
          defaultMessage="Pracownik w tym dniu ma zaplanowaną dostępność: {availabilityName}"
          values={{ availabilityName }}
          tagName="div"
        />
      )}
      {absenceName && (
        <FormattedMessage
          id="attendance.warningIcon.absence"
          defaultMessage="Pracownik w tym dniu ma zaplanowany urlop: {absenceName}"
          values={{ absenceName }}
          tagName="div"
        />
      )}
      {absenceName && absenceHours && (
        <FormattedMessage
          id="attendance.warningIcon.absenceHours"
          defaultMessage=" w godzinach {absenceHours}"
          values={{ absenceHours }}
          tagName="div"
        />
      )}
      {overtimeCollectionMessages}
    </div>
  );
};
export default AttendancesUnavailabilityWarningContent;
