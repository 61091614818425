import { connect } from 'react-redux';

import { confirmAvailability, deleteAvailabilityConfirm } from '@/actions/schedule/availabilities';
import { startAvailabilityDrag, stopAvailabilityDrag } from '@/actions/schedule/dragAndDrop';
import { openEditAvailabilityModalByIds } from '@/actions/uiState';

import Component from './AvaBlock.jsx';

const mapDispatchToProps = {
  openEditAvailabilityModalByIds,
  confirmAvailability,
  deleteAvailability: deleteAvailabilityConfirm,
  startAvailabilityDrag,
  stopAvailabilityDrag,
};

export default connect(null, mapDispatchToProps)(Component);
