import classnames from 'classnames';

import SmallExportIcon from '@/components/common/exports/SmallExportIcon/SmallExportIcon';

import './exportModalListElement.scss';

interface IProps {
  text: string;
  title: string;
  description: string;
  onClick: () => void;
  isSelected: boolean;
}

const ExportModalListElement = ({ text, title, description, onClick, isSelected }: IProps) => {
  const className = classnames('k-exportModalListElement', {
    'k-exportModalListElement--selected': isSelected,
  });
  return (
    <div role="presentation" className={className} onClick={onClick}>
      <SmallExportIcon type={text} />
      <div className="k-exportModalListElement__textContainer">
        <div className="k-exportModalListElement__title"> {title} </div>
        <div className="k-exportModalListElement__description"> {description} </div>
      </div>
    </div>
  );
};

export default ExportModalListElement;
