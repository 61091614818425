import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import {
  EmplMassEditActions,
  emplMassEditActions,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { ContextWithIntl } from '@/types/intl.types.ts';

const messages = defineMessages({
  added: {
    id: 'companymanage.employee.summaryContracts.add.incorrectEmployees',
    defaultMessage:
      'Poniższym pracownikom nie zostanie dodany nowy kontrakt, gdyż posiadają kontrakt rozpoczynający się w wybranej dacie',
  },
  deleted: {
    id: 'companymanage.employee.summaryContracts.delete.incorrectEmployees',
    defaultMessage:
      'Dla poniższych pracowników nie można usunąć kontraktów, ponieważ posiadają oni tylko jeden kontrakt, lub wybrana data obowiązuje dla kontraktu bez daty rozpoczęcia',
  },
  jobTitles: {
    id: 'companymanage.employee.summaryContracts.jobTitles',
    defaultMessage: 'Zostaną dodane do nich następujące stanowiska i zaktualizowane stawki',
  },
  removeJobTitles: {
    id: 'companymanage.employee.summaryContracts.removeJobTitles',
    defaultMessage: 'Zostaną usunięte następujące stanowiska',
  },
  changeStartDateJobTitle: {
    id: 'companymanage.employee.summaryContracts.changeStartDateJobTitle',
    defaultMessage:
      'Dla poniższych pracowników data rozpoczęcia nie może zostać edytowana, ponieważ posiadają oni tylko jeden kontrakt, lub posiadają kontrakt zaczynający się w wybranej dacie',
  },
});

interface EmployeeMassEditSummaryIncorrectEmployeesTextProps {
  actionType: EmplMassEditActions;
}

const EmployeeMassEditSummaryIncorrectEmployeesText = (
  { actionType }: EmployeeMassEditSummaryIncorrectEmployeesTextProps,
  { intl }: ContextWithIntl,
) => {
  switch (actionType) {
    case emplMassEditActions.addContract:
      return <span>{intl.formatMessage(messages.added)}</span>;
    case emplMassEditActions.remove:
      return <span>{intl.formatMessage(messages.deleted)}</span>;
    case emplMassEditActions.addContractsJobTitles:
      return <span>{intl.formatMessage(messages.jobTitles)}</span>;
    case emplMassEditActions.removeContractsJobTitles:
      return <span>{intl.formatMessage(messages.removeJobTitles)}</span>;
    case emplMassEditActions.editContractsStartDate:
      return <span>{intl.formatMessage(messages.changeStartDateJobTitle)}</span>;

    default:
      return null;
  }
};

EmployeeMassEditSummaryIncorrectEmployeesText.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeeMassEditSummaryIncorrectEmployeesText;
