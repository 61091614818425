import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  numberOfShifts: {
    id: 'scheduler.openShifts.numberOfShifts',
    defaultMessage: 'Liczba zmian: {numberOfShifts}',
  },
  openShiftFroLocationGroups: {
    id: 'scheduler.openShifts.openShiftFroLocationGroups',
    defaultMessage: 'Otwarta zmiana z lokalizacji {locationName}',
  },
});
