import moment from 'moment';
import PropTypes from 'prop-types';

import {
  ATTENDANCE_ABSENCE,
  ATTENDANCE_WITHOUT_SHIFT,
} from '@/constants/attendanceDetailTypes.js';
import { ATTENDANCE_SUCCESS_COLOR } from '@/constants/colors.js';
import {
  ADD_SHIFT_MODAL,
  EDIT_ATTENDANCE_MODAL,
} from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import {
  calculatePositionAndLength,
  getBreaksSumFromBlock,
  pickAttendanceToEditForBlock,
} from '@/utils/attendanceHelpers';
import {
  calculateDurationBetweenTimestamps,
  calculateDurationMinutes,
  convertDateToStandardFormat,
  parseMinutesToHumanForm,
  sortShiftsByStartTimestamp,
} from '@/utils/dateHelper.js';
import { getDefaultAbsenceTime } from '@/utils/payrollHelpers';

import AttendanceBarBlock from './AttendanceBarBlock.redux.js';
import AttendanceTimeSpan from './AttendanceTimeSpan/AttendanceTimeSpan.jsx';
import TimeBar from './TimeBar.jsx';

const AttendanceTableRow = (props) => {
  const { relevantShifts, relevantAttendances, employee, restrictions } = props;
  const sortedRelevantShifts = sortShiftsByStartTimestamp(relevantShifts);
  const sortedRelevantAttendances =
    sortShiftsByStartTimestamp(relevantAttendances);
  let breaks = [];
  sortedRelevantAttendances.forEach((attendance) => {
    const completedBreaks = attendance.breaks.map((attBreak) => ({
      ...attBreak,
      end_timestamp:
        attBreak.end_timestamp || moment().format('YYYY-MM-DD HH:mm:ss'),
      shouldBeHidden:
        attendance.date !==
        convertDateToStandardFormat(attBreak.start_timestamp),
    }));
    breaks.push(...completedBreaks);
  });
  breaks = calculatePositionAndLength(breaks);
  const breaksSum = getBreaksSumFromBlock(breaks);
  const { blocks } = props.attendanceData;
  const scheduleEditRestricted =
    props.disabled || restrictions.includes(SCHEDULE_EDIT_DISABLE);
  blocks.forEach((block) => {
    if (props.disabled) {
      block.onClick = () => {};
      return;
    }
    switch (block.type.name) {
      case ATTENDANCE_WITHOUT_SHIFT:
        if (scheduleEditRestricted) {
          break;
        }
        if (block.isLoaned) {
          block.onClick = () => {
            const attendanceToEdit = pickAttendanceToEditForBlock(
              block,
              sortedRelevantAttendances,
              props.date
            );
            props.showModal(EDIT_ATTENDANCE_MODAL, {
              employee: props.employee,
              attendance: attendanceToEdit,
            });
          };
          break;
        }

        block.onClick = () => {
          props.showModal(ADD_SHIFT_MODAL, {
            employee: props.employee,
            date: props.date,
            location: block?.sourceAttendance?.location || null,
            defaultHours: block.sourceAttendance.hours,
            attendanceToDelete: block.sourceAttendance,
            preApproved: true,
          });
        };

        break;
      case undefined:
      case ATTENDANCE_ABSENCE:
        block.onClick = () =>
          props.openAddAttendanceModal(
            props.employee,
            props.date,
            `${(block.combinedBlockStart || block.startTimestamp).slice(
              11,
              16
            )}-${(block.combinedBlockEnd || block.endTimestamp).slice(11, 16)}`,
            props.location.id,
            block.isLoaned
          );
        break;
      default:
        block.onClick = () => {
          const attendanceToEdit = pickAttendanceToEditForBlock(
            block,
            sortedRelevantAttendances,
            props.date
          );
          props.showModal(EDIT_ATTENDANCE_MODAL, {
            employee: props.employee,
            attendance: attendanceToEdit,
          });
        };
        break;
    }
  });

  const showAbsence = employee.absence && blocks.length === 0;
  const absenceDuration = employee.absence?.absence_hours
    ? calculateDurationMinutes(employee.absence.absence_hours)
    : getDefaultAbsenceTime(
        employee.absence?.count_only_days_with_shifts,
        employee.employment_conditions
      );
  const [absenceStartHour, absenceEndHour] =
    employee.absence?.absence_hours?.split('-') || [];
  return (
    (<tr>
      <td style={{ padding: '0', overflow: 'hidden' }}>
        <div className="k-attendanceBar">
          {blocks.map((block, i) => (
            <AttendanceBarBlock
              key={i}
              block={block}
              editDisabled={props.disabled}
            />
          ))}
          {breaks.map((b, i) => (
            <AttendanceBarBlock
              key={i}
              block={{ ...b, type: { name: 'break' } }}
              editDisabled={props.disabled}
            />
          ))}
        </div>
        {props.today === props.date ? <TimeBar date={props.date} /> : ''}
      </td>
      <td>
        {/* FROM */}
        {sortedRelevantShifts.map((s) => (
          <AttendanceTimeSpan
            key={s.id}
            text={s.working_hours.split('-')[0]}
            isAttendanceEditingBlocked={props.disabled}
            isShiftEditingBlocked={scheduleEditRestricted}
          />
        ))}

        {sortedRelevantAttendances.map((a) => (
          // TODO: One of the things that should be done on the backend. ALl of
          // these instances of matching_shift and shift will contain a start
          // and end sometime in the future :).
          (<AttendanceTimeSpan
            key={a.id}
            attendance={a}
            type="start"
            blocks={blocks}
            showModal={props.showModal}
            employee={props.employee}
            isAttendanceEditingBlocked={props.disabled}
            isShiftEditingBlocked={scheduleEditRestricted}
          />)
        ))}
        {showAbsence && absenceStartHour}
        {sortedRelevantAttendances.length === 0 &&
          sortedRelevantShifts.length === 0 &&
          !absenceStartHour &&
          '-'}
      </td>
      <td>
        {/* TO */}
        {sortedRelevantShifts.map((s) => (
          <AttendanceTimeSpan
            key={s.id}
            text={s.working_hours.split('-')[1]}
            isShiftEditingBlocked={scheduleEditRestricted}
          />
        ))}
        {sortedRelevantAttendances.map((a) => (
          // TODO: One of the things that should be done on the backend. ALl of
          // these instances of matching_shift and shift will contain a start
          // and end sometime in the future :).
          (<AttendanceTimeSpan
            attendance={a}
            type="end"
            key={a.id}
            blocks={blocks}
            showModal={props.showModal}
            employee={props.employee}
            isAttendanceEditingBlocked={props.disabled}
            isShiftEditingBlocked={scheduleEditRestricted}
          />)
        ))}
        {showAbsence && absenceEndHour}
        {sortedRelevantAttendances.length === 0 &&
          sortedRelevantShifts.length === 0 &&
          !absenceEndHour &&
          '-'}
      </td>
      <td>{breaksSum}</td>
      <td style={{ textAlign: 'center' }}>
        {sortedRelevantShifts.map((s) => (
          <AttendanceTimeSpan
            key={s.id}
            text={parseMinutesToHumanForm(
              calculateDurationMinutes(s.working_hours)
            )}
            isAttendanceEditingBlocked={props.disabled}
            isShiftEditingBlocked={props.disabled}
          />
        ))}
        {sortedRelevantAttendances.map((a) => {
          const duration = calculateDurationBetweenTimestamps(
            a.end_timestamp,
            a.start_timestamp
          );
          return (
            <AttendanceTimeSpan
              key={a.id}
              text={parseMinutesToHumanForm(duration)}
              color={ATTENDANCE_SUCCESS_COLOR}
              isAttendanceEditingBlocked={props.disabled}
              isShiftEditingBlocked={props.disabled}
            />
          );
        })}
        {showAbsence && (
          <AttendanceTimeSpan
            text={parseMinutesToHumanForm(absenceDuration)}
            isAttendanceEditingBlocked={props.disabled}
            isShiftEditingBlocked={props.disabled}
          />
        )}
        {sortedRelevantAttendances.length === 0 &&
          sortedRelevantShifts.length === 0 &&
          !showAbsence &&
          '-'}
      </td>
    </tr>)
  );
};

AttendanceTableRow.propTypes = {
  employee: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    absence: PropTypes.shape({
      absence_hours: PropTypes.string,
      count_only_days_with_shifts: PropTypes.bool,
    }),
    employment_conditions: PropTypes.shape({}),
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  relevantShifts: PropTypes.arrayOf(PropTypes.shape({})),
  relevantAttendances: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.string,
  today: PropTypes.string,
  attendanceData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  disabled: PropTypes.bool,
  showModal: PropTypes.func,
  openAddAttendanceModal: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default AttendanceTableRow;
