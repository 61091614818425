import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INFO_EVENT_MODAL } from '@/constants/modalTypes.js';
import { convertDateToCustomFormat } from '@/utils/dateHelper.js';

import './EventsProposalsItem.scss';

const EventsProposalsItem = ({ event, showModal, userLocations }) => {
  const location = userLocations.find(({ id }) => id === event.location.id);
  return (
    <div className="eventProposalItem">
      <p className="eventProposalItem__location">
        {location.city} - {location.name}
      </p>
      <div className="eventProposalItem__content">
        <div className="eventProposalItem__info">
          <p className="eventProposalItem__description">
            {convertDateToCustomFormat(event.date)}, {event.hours}
          </p>
          <p className="eventProposalItem__description">{event.description}</p>
        </div>
        <button onClick={() => showModal(INFO_EVENT_MODAL, event)} className="eventProposalItem__link">
          <FormattedMessage id="dashboard.readMore" defaultMessage="Czytaj więcej" />
        </button>
      </div>
    </div>
  );
};

EventsProposalsItem.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.string,
    type: PropTypes.string,
    hours: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  showModal: PropTypes.func,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EventsProposalsItem;
