import PropTypes from 'prop-types';

import KeyboardButton from './KeyboardButton';

import './KeyboardButtonWithDescription.scss';

const KeyboardButtonWithDescription = props => (
  <div className="keyboardButton__wrapper">
    <KeyboardButton text={props.text} wide={props.wide} />
    <div className="keyboardButton__description">{props.description}</div>
  </div>
);

KeyboardButtonWithDescription.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  wide: PropTypes.bool,
};

export default KeyboardButtonWithDescription;
