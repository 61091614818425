import PropTypes from 'prop-types';

import MdCheckboxTableCheckbox from './MDCheckboxTableCheckbox/MDCheckboxTableCheckbox';

import './MDCheckboxTable.scss';

const MDCheckboxTable = ({ colSizes, headers, rows, onOptionChange }) => {
  const handleOptionChange = (e, field) => {
    const { checked, name } = e.target;
    if (onOptionChange) {
      onOptionChange(field, name, checked);
    }
  };

  return (
    <table className="mdCheckboxTable">
      <colgroup>
        {colSizes.map((colSize, idx) => {
          const size = typeof colSize === 'number' ? `${colSize}%` : colSize;
          return <col key={idx} style={{ width: size }} />;
        })}
      </colgroup>
      <thead className="mdCheckboxTable__headersContainer">
        {headers.map((head, idx) => (
          <th key={idx}>
            <div className="mdCheckboxTable__header">{head}</div>
          </th>
        ))}
      </thead>
      <tbody className="mdCheckboxTable__rowsContainer">
        {rows.map(row => (
          <tr key={row.id} className="mdCheckboxTable__row">
            <td>
              <div className="mdCheckboxTable__cell--label">
                <h4>{row.label}</h4>
                <p>{row.description}</p>
              </div>
            </td>
            {row.options.map(option => (
              <td key={row.id + option.id}>
                <div className="mdCheckboxTable__cell">
                  <MdCheckboxTableCheckbox {...{ row, handleOptionChange, option }} />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

MDCheckboxTable.propTypes = {
  colSizes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      description: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          value: PropTypes.bool,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onOptionChange: PropTypes.func,
};

export default MDCheckboxTable;
