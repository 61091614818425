import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import { MDCheckbox, MDRadios, MDTextArea } from '@/components/common/inputs/MDComponents.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { SMS_NOTIFICATION_SEND } from '@/constants/Permissions.js';
import { createEvent } from '@/utils/inputHelpers.js';

import { messages } from './NotificationsOptions.messages';
import { getNotificationsOptions } from './NotificationsOptionsHelpers';

import './NotificationsOptions.scss';

const NotificationsOptions = (props, context) => {
  const { sendNotif, sendSms, comment, handleInputChange } = props;
  const notificationsOptions = useMemo(() => getNotificationsOptions(context.intl), []);

  const changeNotificationOption = useCallback(type => {
    const shouldNotify = type === 'notify';
    handleInputChange(createEvent('sendNotif', shouldNotify));
    handleInputChange(createEvent('sendSms', false));
  }, []);

  return (
    <div className="notificationsOptions">
      <div className="heading">{context.intl.formatMessage(messages.notifications)}</div>
      <MDRadios
        elements={notificationsOptions}
        selectedItemType={sendNotif ? 'notify' : 'noNotify'}
        onChangeType={changeNotificationOption}
      />
      <FeatureWrapper permission={SMS_NOTIFICATION_SEND} hide={!sendNotif}>
        <MDCheckbox
          id="sendSms"
          text={context.intl.formatMessage(messages.sendSMS)}
          value={sendSms}
          onChange={handleInputChange}
          reverse
        />
      </FeatureWrapper>
      {sendNotif && (
        <MDTextArea
          value={comment}
          onChange={handleInputChange}
          name="comment"
          label={context.intl.formatMessage(messages.comment)}
          id="comment"
        />
      )}
    </div>
  );
};

NotificationsOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

NotificationsOptions.propTypes = {
  sendNotif: PropTypes.bool,
  sendSms: PropTypes.bool,
  comment: PropTypes.string,
  handleInputChange: PropTypes.func,
};

export default memo(NotificationsOptions);
