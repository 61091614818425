import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { KadroCalendarRange } from '@/components/common/KadroCalendars';
import Popover from '@/components/common/Popover/Popover.jsx';
import { DATE_MODE_CUSTOM } from '@/constants/dateModes';

import { getModeAndDates } from './TopBarCalendar.helpers.js';

const TopBarCalendar = ({
  mainDateStore,
  additionalChangeMode,
  modes,
  onChangeCallback,
  allowSingleDay,
  mainDateChangeModeAndDate,
  mainDateMoveLeft,
  mainDateMoveRight,
  hideFilter,
}) => {
  const location = useLocation();
  const [dateRanges, setDateRanges] = useState(mainDateStore.customDate);
  const [localDateRanges, setLocalDateRanges] = useState(mainDateStore.customDate);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const { customDate } = mainDateStore;
    if (customDate.start !== dateRanges.start || customDate.end !== dateRanges.end) {
      setDateRanges(customDate);
    }
  }, [mainDateStore.customDate, dateRanges]);

  const changeDateRange = useCallback(
    (event) => {
      const { start, end, dateMode } = getModeAndDates(modes, location.pathname, event);
      setDateRanges({ start, end });
      mainDateChangeModeAndDate(start, end, dateMode);
      if (additionalChangeMode && mainDateStore.type !== dateMode) {
        additionalChangeMode(dateMode);
      }
      if (onChangeCallback) {
        onChangeCallback();
      }
    },
    [modes, mainDateChangeModeAndDate, onChangeCallback, location.pathname],
  );

  const onHideCalendar = useCallback(() => {
    if (!allowSingleDay || isEndDateSelected) return;
    changeDateRange(localDateRanges);
  }, [allowSingleDay, isEndDateSelected, localDateRanges]);

  const moveLeft = useCallback(() => {
    mainDateMoveLeft();
    if (onChangeCallback) {
      onChangeCallback();
    }
  }, [onChangeCallback, mainDateMoveLeft]);

  const moveRight = useCallback(() => {
    mainDateMoveRight();
    if (onChangeCallback) {
      onChangeCallback();
    }
  }, [mainDateMoveRight, onChangeCallback]);

  const setLocalDateRangesInCalendar = useCallback(
    (newDateRanges, isEndDateSelectedInCalendar) => {
      setLocalDateRanges(newDateRanges);
      setIsEndDateSelected(isEndDateSelectedInCalendar);
    },
    [setLocalDateRanges, setIsEndDateSelected],
  );

  if (hideFilter) {
    return null;
  }

  const allowCustomRange = mainDateStore.dateMode === DATE_MODE_CUSTOM;
  const calendarClassNames = classnames('k-topBarCalendar__picker', {
    'k-topBarCalendar__picker--custom': allowCustomRange,
  });

  return (
    <div className="k-topBarFilter k-topBarCalendar k-topBar__leftElement">
      <div className={calendarClassNames}>
        <button className="k-topBarCalendar__move k-topBarCalendar__move--left" onClick={moveLeft} />
        <Popover
          onHide={onHideCalendar}
          ref={popoverRef}
          className="k-topBarCalendar__popup"
          content={
            <>
              <span
                onClick={() => popoverRef.current.hide()}
                className="material-icons k-topBarCalendar__close"
                role="presentation"
              >
                close
              </span>
              <KadroCalendarRange
                onChange={changeDateRange}
                selectedRange={dateRanges}
                modes={modes}
                mainDateStore={mainDateStore}
                initialMinMaxDatesEnable
                allowSingleDay={allowSingleDay}
                setLocalDateRanges={setLocalDateRangesInCalendar}
              />
            </>
          }
        >
          <div className="k-topBarCalendar__dateText">{mainDateStore.dateText}</div>
        </Popover>
        <button className="k-topBarCalendar__move k-topBarCalendar__move--right" onClick={moveRight} />
      </div>
    </div>
  );
};

TopBarCalendar.propTypes = {
  mainDateStore: PropTypes.shape({
    dateText: PropTypes.string,
    dateMode: PropTypes.string,
    customDate: PropTypes.shape({}),
  }),
  hideFilter: PropTypes.bool,
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  mainDateChangeModeAndDate: PropTypes.func,
  modes: PropTypes.arrayOf(PropTypes.number),
  currentRoute: PropTypes.string,
  allowSingleDay: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  additionalChangeMode: PropTypes.func,
};

export default TopBarCalendar;
