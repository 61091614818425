/* eslint-disable no-debugger */
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import InputMask from 'react-input-mask';

import Icon from '../Basic/Icon/Icon';

function setChar(string, position, char) {
  return string.substr(0, position) + char + string.substr(position + 1);
}

class TimeSingleInput extends Component {
  constructor(props) {
    super(props);
    this.formatChar = {
      9: '[0-9]',
      5: '[0-5]',
      a: '[A-Za-z]',
      '*': '[A-Za-z0-9]',
    };

    this.inputChanged = this.inputChanged.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(e) {
    const { key } = e;
    const { value } = e.target;
    if (key === 'Tab') {
      const valueToSet = value.replace(/_/g, '0');
      this.props.onChange({
        target: {
          value: valueToSet,
        },
      });
    } else if (key === ' ') {
      e.preventDefault();
      const cursor = this.input.getCursorPosition();
      if (value.charAt(cursor) === '_') {
        const valueToSet = setChar(value, cursor, '0');
        this.props.onChange({
          target: {
            value: valueToSet,
          },
        });
      }
      this.input.setCursorPosition(cursor + 1);
    }
  }

  inputChanged(e) {
    const { value } = e.target;
    let valueToSet = value;
    const digit = value.charAt(0);
    const firstTwoDigits = value.substr(0, 2);
    if (this.props.max) {
      if (firstTwoDigits > this.props.max) valueToSet = `${this.props.max}${value.substr(2)}`;
    } else if (digit > 2) {
      valueToSet = setChar(value, 0, '0');
      valueToSet = setChar(valueToSet, 1, digit);
      setTimeout(() => {
        this.input.setCursorPosition(3);
      }, 1);
    } else if (firstTwoDigits === '24') {
      valueToSet = `00${value.substr(2)}`;
    } else if (firstTwoDigits > 24) {
      valueToSet = `23${value.substr(2)}`;
    }
    this.props.onChange({
      target: {
        value: valueToSet,
      },
    });
  }

  render() {
    const modifiers = Array.isArray(this.props.modifiers) ? this.props.modifiers : this.props.modifiers.split(' ');
    const className = classNames(
      'k-textInput',
      this.props.className,
      ...modifiers.map((modifier) => `k-textInput--${modifier}`),
    );
    return (
      <>
        <InputMask
          className={className}
          {...this.props}
          mask="99:59"
          maskChar="_"
          datatype="time"
          type="input"
          formatChars={this.formatChar}
          onChange={(e) => this.inputChanged(e, this.props.max)}
          onKeyDown={this.onKeyDown}
          value={this.props.modifiers && this.props.modifiers.includes('disabled') ? '' : this.props.value}
          ref={(ref) => {
            this.input = ref;
          }}
          data-test={this.props.testId}
        />
        {this.props.iconName && (
          <Icon className={this.props.iconClassNames} type={this.props.iconType} name={this.props.iconName} />
        )}
      </>
    );
  }
}

TimeSingleInput.defaultProps = {
  modifiers: [],
};

TimeSingleInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  testId: PropTypes.string,
  iconClassNames: PropTypes.string,
  iconType: PropTypes.string,
  iconName: PropTypes.string,
};

export default TimeSingleInput;
