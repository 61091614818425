import './errorMessage.scss';

interface ErrorMessageProps {
  children?: string;
}

function ErrorMessage({ children }: ErrorMessageProps) {
  if (!children) return null;
  return <div className="k-errorMessage">{children}</div>;
}

export default ErrorMessage;
