import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const KadroSkeleton = ({ width, height, className }) => (
  <Skeleton width={width} height={height} className={className} />
);

KadroSkeleton.defaultProps = {
  width: '75%',
  height: '16px',
};

KadroSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};
export default KadroSkeleton;
