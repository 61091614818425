import moment from 'moment';
import PropTypes from 'prop-types';

import CalendarMonth from './CalendarMonth';

import './yearCalendar.scss';

const YearCalendar = ({ year, data, customDayRender }) => {
  const months = moment.months(true);
  return (
    <div className="yearCalendar">
      {months.map((month, index) => (
        <CalendarMonth data={data[index]} monthDate={moment([year, index, 1])} customDayRender={customDayRender} />
      ))}
    </div>
  );
};

YearCalendar.propTypes = {
  modalObject: PropTypes.shape({}),
  year: PropTypes.number,
  data: PropTypes.shape({}),
  customDayRender: PropTypes.func,
};

export default YearCalendar;
