import { connect } from 'react-redux';

import {
  deleteAbsence,
  editAbsence,
  exportAbsenceItemToPdf,
  getAbsence,
  getAbsenceHistory,
} from '@/actions/absences.js';
import { deleteNewOvertimeCollections } from '@/actions/overtimeCollections/overtimeCollections';
import { hideModal } from '@/actions/uiState.js';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes.js';

import AbsenceProposalModal from './AbsenceProposalModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ADD_ABSENCE_PROPOSAL_MODAL,
  modalObject: state.reducer.uiState.modalObject[ADD_ABSENCE_PROPOSAL_MODAL],
  isEmployee: state.reducer.userPermissions.isEmployee,
  restrictions: state.reducer.userPermissions.restrictions,
  userId: state.reducer.currentUser.user.id,
  locationSettings: state.reducer.settings.locationSettings,
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  editAbsence,
  deleteAbsence,
  onHide: hideModal,
  exportAbsenceItemToPdf,
  getAbsenceHistory,
  getAbsence,
  deleteNewOvertimeCollections,
};

const AbsenceProposalModalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceProposalModal);

export default AbsenceProposalModalContainer;
