import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useInputChange } from '@/hooks';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { getInitialState, modalModifiers } from './QuickPlanningWarningModal.helpers';
import { messages } from './QuickPlanningWarningModal.messages';

import './QuickPlanningWarningModal.scss';

const QuickPlanningWarningModal = (props, { intl }) => {
  const {
    freeDaysToPaste,
    freeDaysAfterDateSelection,
    freeDaysForNotExistingEmployeeId,
    showModal,
    shiftsForNotExistingEmployeeId,
    shiftsAfterDateSelection,
    shiftsToPaste,
    shiftsNotAdded,
    hideModal,
    addMultipleShifts,
    addOrEditFreeDays,
    notifyAboutNoShiftsToPaste,
  } = props;
  const [state, handlers] = useInputChange(getInitialState(), intl);

  const clearAndHide = useCallback(() => {
    handlers.setInitState();
    hideModal();
  }, []);

  const onSubmit = useCallback(() => {
    const shouldFilterShifts = shiftsAfterDateSelection.length > 0 && !state.allowToPasteShiftsInNotVisibleDates;
    const shifts = shouldFilterShifts ? shiftsToPaste : [...shiftsToPaste, ...shiftsAfterDateSelection];
    if (!isEmptyArray(shifts)) {
      addMultipleShifts(shifts, shiftsNotAdded);
    } else {
      notifyAboutNoShiftsToPaste();
    }
    const shouldFilterFreeDays =
      !isEmptyArray(freeDaysAfterDateSelection) && !state.allowToFreeDaysPasteInNotVisibleDates;
    const freeDays = shouldFilterFreeDays ? freeDaysToPaste : [...freeDaysToPaste, ...freeDaysAfterDateSelection];
    addOrEditFreeDays(freeDays);
    clearAndHide();
  }, [
    shiftsAfterDateSelection,
    shiftsToPaste,
    state,
    shiftsNotAdded,
    freeDaysAfterDateSelection,
    freeDaysToPaste,
    addMultipleShifts,
    addOrEditFreeDays,
    clearAndHide,
    notifyAboutNoShiftsToPaste,
  ]);

  return (
    <MDKadroModal
      show={showModal}
      onHide={clearAndHide}
      title={intl.formatMessage(messages.title)}
      modifiers={modalModifiers}
      onSubmit={onSubmit}
    >
      {shiftsForNotExistingEmployeeId.length > 0 && (
        <div className="quickPlanningWarningModal__employeeWarning">
          {intl.formatMessage(messages.shiftsForNotExistingEmployeeId)}
        </div>
      )}
      {!isEmptyArray(freeDaysForNotExistingEmployeeId) && (
        <div className="quickPlanningWarningModal__employeeWarning">
          {intl.formatMessage(messages.freeDaysForNotExistingEmployeeId)}
        </div>
      )}
      {shiftsAfterDateSelection.length > 0 && (
        <div className="quickPlanningWarningModal__dateWarning">
          <MDCheckbox
            id="allowToPasteShiftsInNotVisibleDates"
            text={intl.formatMessage(messages.shiftsAfterDateSelection)}
            value={state.allowToPasteShiftsInNotVisibleDates}
            onChange={handlers.changeInput}
            reverse
          />
        </div>
      )}
      {!isEmptyArray(freeDaysAfterDateSelection) && (
        <div className="quickPlanningWarningModal__dateWarning">
          <MDCheckbox
            id="allowToFreeDaysPasteInNotVisibleDates"
            text={intl.formatMessage(messages.freeDaysAfterDateSelection)}
            value={state.allowToFreeDaysPasteInNotVisibleDates}
            onChange={handlers.changeInput}
            reverse
          />
        </div>
      )}
    </MDKadroModal>
  );
};

QuickPlanningWarningModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

QuickPlanningWarningModal.propTypes = {
  freeDaysToPaste: PropTypes.shape({}),
  freeDaysAfterDateSelection: PropTypes.arrayOf(PropTypes.shape({})),
  freeDaysForNotExistingEmployeeId: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.bool,
  shiftsForNotExistingEmployeeId: PropTypes.arrayOf(PropTypes.string),
  shiftsAfterDateSelection: PropTypes.arrayOf(PropTypes.shape({})),
  hideModal: PropTypes.func,
  shiftsToPaste: PropTypes.shape({}),
  addMultipleShifts: PropTypes.func,
  notifyAboutNoShiftsToPaste: PropTypes.func,
  shiftsNotAdded: PropTypes.number,
  addOrEditFreeDays: PropTypes.func,
};

export default QuickPlanningWarningModal;
