import classNames from 'classnames';
import PropTypes from 'prop-types';

const KeyboardButton = props => (
  <div className={classNames('keyboardButton', { 'keyboardButton--wide': props.wide })}>{props.text}</div>
);

KeyboardButton.propTypes = {
  text: PropTypes.string,
  wide: PropTypes.bool,
};

export default KeyboardButton;
