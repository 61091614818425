import TagManager from 'react-gtm-module';

import {
  GET_SCHEDULE_INIT_DATA,
  SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL,
  SET_USER_RESTICTIONS_SUCCESSFUL,
  UPDATE_PERMISSIONS_SUCCESSFUL,
} from '@/constants/ActionTypes.js';
import { APP_IN_MAINTANANCE_MODAL } from '@/constants/modalTypes.js';
import {
  AVAILABILITY_BLOCK_CREATE,
  ECP_GET,
  OPEN_SHIFTS_GET,
  OPEN_SHIFTS_SETTING_SHOW,
  SMS_NOTIFICATION_SEND,
  TRADE_SHIFTS_GET,
  TRADE_SHIFTS_SETTING_SHOW,
} from '@/constants/Permissions';
import { LOCATION_ATTENDANCE_CLOCK_REQUIRE_ALL } from '@/constants/Restrictions';
import {
  employeeRestrictions,
  managerPermissions,
  managerRestrictions,
} from '@/utils/routes';
import { getScheduleSettings } from '@/utils/schedulerHelpers';

import { logoutUser } from './auth.jsx';
import { conn, connectionError } from './index';
import { showModal } from './uiState.js';

export const setUserRestrictionsSuccessful = restrictions => ({
  type: SET_USER_RESTICTIONS_SUCCESSFUL,
  payload: restrictions,
});

export const updateUserPermissionsSuccess = change => ({
  type: UPDATE_PERMISSIONS_SUCCESSFUL,
  payload: change,
});

export const setProductionQuotasPermissionSuccesful = isEnabled => ({
  type: SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL,
  payload: isEnabled,
});

export const setUserRestrictions = (isEmployee, restrictions) => dispatch => {
  let finalRestrictions = isEmployee ? employeeRestrictions : managerRestrictions;
  finalRestrictions = finalRestrictions.concat(restrictions);
  dispatch(setUserRestrictionsSuccessful(finalRestrictions));
};

export const addUserRestrictions = restrictions => dispatch => {
  dispatch(setUserRestrictionsSuccessful(restrictions));
};

export const updateScheduleInitSettings = userPermissions => dispatch => {
  const settings = getScheduleSettings(userPermissions);
  dispatch({
    type: GET_SCHEDULE_INIT_DATA,
    payload: { settings },
  });
};

// Updates the permissions from worker service.
export const updatePermissionsRequest = (aditionalPermissions = {}, requestType) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getUserStatus(requestType)
      .then(response => {
        const { data } = response;
        TagManager.dataLayer({ dataLayer: { userId: data.user_id } });

        const rolePermissions = data.isEmployee ? [] : managerPermissions;
        const userData = { ...data, permissions: [...data.permissions, ...rolePermissions] };
        dispatch(updateScheduleInitSettings(data));
        dispatch(updateUserPermissionsSuccess({ ...userData, ...aditionalPermissions }));
        dispatch(setUserRestrictions(data.isEmployee, data.restrictions));
        resolve(data);
      })
      .catch(err => {
        dispatch(connectionError(err));
        dispatch(logoutUser());
        setTimeout(() => {
          dispatch(showModal(APP_IN_MAINTANANCE_MODAL));
        }, 1000);
        reject(err);
      });
  });

// Updates the permissions from worker service or mock data for local testing
export const updatePermissions = (aditionalPermissions = {}, requestType) => dispatch => {
  if (import.meta.env.VITE_WORKER_ENV === 'local') {
    return new Promise(resolve => {
      const response = {
        data: {
          roles: ['ROLE_USER', 'ROLE_OWNER'],
          isEmployee: false,
          accountActive: true,
          verifyWorkingRules: true,
          restrictions: [LOCATION_ATTENDANCE_CLOCK_REQUIRE_ALL],
          permissions: [
            TRADE_SHIFTS_GET,
            OPEN_SHIFTS_GET,
            SMS_NOTIFICATION_SEND,
            ECP_GET,
            AVAILABILITY_BLOCK_CREATE,
            OPEN_SHIFTS_SETTING_SHOW,
            TRADE_SHIFTS_SETTING_SHOW,
          ],
        },
      };
      dispatch(updateUserPermissionsSuccess({ ...response.data, ...aditionalPermissions }));
      dispatch(setUserRestrictions(response.data.isEmployee, response.data.restrictions));
      resolve(response.data);
    });
  }
  return dispatch(updatePermissionsRequest(aditionalPermissions, requestType));
};

export const setProductionQuotasPermission = settings => dispatch => {
  const isProductionQuotasEnabled = settings.some(
    setting => setting.enable_usage_production_quotas_for_payroll_calculation,
  );
  dispatch(setProductionQuotasPermissionSuccesful(isProductionQuotasEnabled));
};
