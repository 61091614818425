import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { defineMessages } from 'react-intl';

import AddJobTitleModal from '@/components/companymanage/jobtitles/AddJobTitleModal/AddJobTitleModal.redux';
import EmptyState from '@/components/emptyState/EmptyState.jsx';

const messages = defineMessages({
  emptyStateTitle: {
    id: 'scheduleView.emptyState.emptyStateTitle',
    defaultMessage: 'Brak dodanych stanowisk pracy ',
  },
  emptyStateMsg: {
    id: 'scheduleView.emptyState.emptyStateMsg',
    defaultMessage: 'Zanim utworzysz grafik ',
  },
  ctaText: {
    id: 'scheduleView.emptyState.ctaText',
    defaultMessage: 'dodaj stanowiska',
  },
});

const EmptyStateJobTitleView = (props, context) => (
  <Fragment>
    <AddJobTitleModal />
    <tr>
      <td className="no-content-to-show">
        <EmptyState
          name="jobtitleCTA"
          imgSrc="/img/jobtitlesCTA.png"
          emptyStateHeader={context.intl.formatMessage(messages.emptyStateTitle, {})}
          emptyStateText={context.intl.formatMessage(messages.emptyStateMsg, {})}
          ctaHandler={() => {
            props.toggleModal('jobTitles');
          }}
          filerStyle={{
            width: '17vw',
            minWidth: '200px',
            maxWidth: '260px',
          }}
          ctaText={context.intl.formatMessage(messages.ctaText, {})}
        />
      </td>
    </tr>
  </Fragment>
);

EmptyStateJobTitleView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmptyStateJobTitleView.propTypes = {
  toggleModal: PropTypes.func,
};

export default EmptyStateJobTitleView;
