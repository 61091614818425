import { useEffect, useState } from 'react';
import Talk from 'talkjs';

import { useAppSelector } from '@/redux-store';
import { selectChatSignature } from '@/redux-store/chat';
import { selectCurrentUserId } from '@/selectors/currentUser.selectors';
import { TALKJS_APP_ID } from '@/constants/envVariables';

declare global {
  interface Window {
    talkSession: Talk.Session;
  }
}

export const useTalkChat = () => {
  const [unreadConversations, setUnreadConversations] = useState(0);
  const userId = useAppSelector(selectCurrentUserId);
  const signature = useAppSelector(selectChatSignature);

  useEffect(() => {
    const initTalkJs = () => {
      const appId = TALKJS_APP_ID;
      if (!appId) return;
      Talk.ready.then(() => {
        const me = new Talk.User(userId);
        window.talkSession = new Talk.Session({ appId, me, signature });
        window.talkSession.unreads.on('change', (unreadAmount) => {
          setUnreadConversations(unreadAmount.length);
        });
      });
    };
    initTalkJs();
  }, [signature, userId]);

  return { unreadConversations };
};
