import * as actionTypes from '@/constants/ActionTypes';
import { SHORTCUTS_MODAL } from '@/constants/modalTypes';
import { selectAreEmployeesGrouped } from '@/redux-store/employeeGrouping';
import {
  selectAreEmployeesWithoutShiftsShown,
  selectAreLoanedEmployeesShown,
  selectAreShiftsFromOtherLocationsShown,
  selectAreSupplementaryEmployeesShown,
  selectAreSupplementaryEmployeesWithoutShiftsShown,
} from '@/redux-store/schedule/viewSettings';
import { getRelevantContractsForMultipleDates } from '@/utils/contracts';
import { supplementaryEmployeeShouldBeHidden } from '@/utils/schedulerHelpers';
import { isEmployeeHiredInPeriod, searchEmployees, sortEmployees } from '@/utils/userEmployeesHelpers';

import { getRouteInitData } from '../auth';
import { toggleModal } from '../uiState';
import { updateSettingsForQuickPlanning } from './quickPlanning';
import {
  getEmployeesWithSupplementaryLoanedAtTheEnd,
  getLoanedEmployees,
  getSupplementaryEmployeesIds,
  handleShowEmployees,
} from './scheduleView.helpers';

export const toggleShortcutsModal = () => dispatch => {
  dispatch(
    toggleModal({
      modalName: SHORTCUTS_MODAL,
      ignoreWhenOtherModalIsOpen: true,
    }),
  );
};
export const filterScheduleViewState = () => (dispatch, getState) => {
  const state = getState();
  const {
    employees,
    employeeSorting,
    userLocations,
    jobtitleFilter,
    employmentConditionsFilter,
    scheduleUIState,
    shifts,
    mainDateStore,
    scheduleLoanedEmployees: { scheduleLoanedEmployees },
    userPermissions: { isEmployee },
    contracts,
    userJobTitles,
  } = state.reducer;
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(i => i.id.toString());
  const selectedEmploymentConditionsIds = employmentConditionsFilter.selected.map(c => (c.id ? c.id.toString() : null));
  const result = {};
  const employeesArray = Object.values(employees.data);
  const supplementaryEmployeesWithoutShift = selectAreSupplementaryEmployeesWithoutShiftsShown(state);
  const showEmployeesWithoutShifts = selectAreEmployeesWithoutShiftsShown(state);
  const showShiftsFromOtherLocations = selectAreShiftsFromOtherLocationsShown(state);
  const hideLoanedEmployees = !selectAreLoanedEmployeesShown(state);
  const hideSupplementaryEmployees = !selectAreSupplementaryEmployeesShown(state);
  const hideSupplementaryEmployeesWithoutShift = !hideSupplementaryEmployees && !supplementaryEmployeesWithoutShift;
  userLocations.forEach(location => {
    result[location.id] = {};
    result[location.id].locationId = location.id;
    result[location.id].employees = [
      ...employeesArray.filter(
        e =>
          e.locations.map(l => l.id.toString()).includes(location.id.toString()) &&
          isEmployeeHiredInPeriod(e, mainDateStore.dateArray),
      ),
      ...getLoanedEmployees(scheduleLoanedEmployees, location.id, mainDateStore.dateArray),
    ];
    result[location.id].visible = searchEmployees(
      result[location.id].employees.filter(e => {
        if (e.isLoaned) return !hideLoanedEmployees;

        const employeeContracts = contracts[e.id] || [];
        const { start: from, end: to } = mainDateStore.customDate;
        const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
        const relevantJobTitleIds = [
          ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
        ];

        return (
          (isEmployee || relevantJobTitleIds.some(id => selectedJobTitlesIds.includes(id))) &&
          selectedEmploymentConditionsIds.includes(
            e.employment_conditions.template_id ? e.employment_conditions.template_id.toString() : 'custom',
          ) &&
          !e.inactive &&
          !supplementaryEmployeeShouldBeHidden(
            e,
            String(location.id),
            shifts,
            hideSupplementaryEmployees,
            hideSupplementaryEmployeesWithoutShift,
          ) &&
          (e.isLoaned ? !hideLoanedEmployees : true) &&
          handleShowEmployees(showEmployeesWithoutShifts, showShiftsFromOtherLocations, e, location.id)
        );
      }),
      scheduleUIState.searchString,
    ).map(e => e.id.toString());
    result[location.id].supplementary = getSupplementaryEmployeesIds(result, location.id);
    result[location.id].loaned = result[location.id].employees.reduce((p, e) => (e.isLoaned ? [...p, e.id] : p), []);
    const employeesSorted = sortEmployees(
      result[location.id].employees,
      { employeeSorting, mainDateStore },
      location.id,
      contracts,
      userJobTitles,
    ).map(e => e.id.toString());
    result[location.id].order = getEmployeesWithSupplementaryLoanedAtTheEnd(
      employeesSorted,
      employees.data,
      location.id,
      mainDateStore.dateArray,
      scheduleLoanedEmployees,
      selectAreEmployeesGrouped(state),
    );
    delete result[location.id].employees;
  });
  dispatch({ type: actionTypes.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST, payload: result });
};

export const sortScheduleViewState = () => (dispatch, getState) => {
  const state = getState();
  const {
    employees,
    userLocations,
    employeeSorting,
    mainDateStore,
    employmentConditionsFilter,
    jobtitleFilter,
    scheduleLoanedEmployees: { scheduleLoanedEmployees },
    scheduleUIState,
    shifts,
    contracts,
    userJobTitles,
  } = state.reducer;
  const result = {};
  const employeesArray = Object.values(employees.data);
  const selectedEmploymentConditionsIds = employmentConditionsFilter.selected.map(c => (c.id ? c.id.toString() : null));
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(i => i.id.toString());
  const supplementaryEmployeesWithoutShift = selectAreSupplementaryEmployeesWithoutShiftsShown(state);
  const showEmployeesWithoutShifts = selectAreEmployeesWithoutShiftsShown(state);
  const showShiftsFromOtherLocations = selectAreShiftsFromOtherLocationsShown(state);
  const hideLoanedEmployees = !selectAreLoanedEmployeesShown(state);
  const hideSupplementaryEmployees = !selectAreSupplementaryEmployeesShown(state);
  const hideSupplementaryEmployeesWithoutShift = !hideSupplementaryEmployees && !supplementaryEmployeesWithoutShift;

  userLocations.forEach(location => {
    result[location.id] = {};
    result[location.id].locationId = location.id;
    result[location.id].employees = [
      ...employeesArray.filter(
        e =>
          e.locations.map(l => l.id.toString()).includes(location.id.toString()) &&
          isEmployeeHiredInPeriod(e, mainDateStore.dateArray),
      ),
      ...getLoanedEmployees(scheduleLoanedEmployees, location.id, mainDateStore.dateArray),
    ];
    result[location.id].supplementary = getSupplementaryEmployeesIds(result, location.id);
    result[location.id].loaned = result[location.id].employees.reduce((p, e) => (e.isLoaned ? [...p, e.id] : p), []);
    const employeesSorted = sortEmployees(
      result[location.id].employees,
      { employeeSorting, mainDateStore },
      location.id,
      contracts,
      userJobTitles,
    ).map(e => e.id.toString());
    result[location.id].order = getEmployeesWithSupplementaryLoanedAtTheEnd(
      employeesSorted,
      employees.data,
      location.id,
      mainDateStore.dateArray,
      scheduleLoanedEmployees,
      selectAreEmployeesGrouped(state),
    );
    const { start: from, end: to } = mainDateStore.customDate;
    result[location.id].visible = searchEmployees(
      result[location.id].employees.filter(e => {
        if (e.isLoaned) return !hideLoanedEmployees;

        const employeeContracts = contracts[e.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
        const relevantJobTitleIds = [
          ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
        ];
        return (
          relevantJobTitleIds.some(id => selectedJobTitlesIds.includes(id)) &&
          selectedEmploymentConditionsIds.includes(
            e.employment_conditions.template_id ? e.employment_conditions.template_id.toString() : 'custom',
          ) &&
          !e.inactive &&
          !supplementaryEmployeeShouldBeHidden(
            e,
            String(location.id),
            shifts,
            hideSupplementaryEmployees,
            hideSupplementaryEmployeesWithoutShift,
          ) &&
          (e.isLoaned ? !hideLoanedEmployees : true) &&
          handleShowEmployees(showEmployeesWithoutShifts, showShiftsFromOtherLocations, e, location.id)
        );
      }),
      scheduleUIState.searchString,
    ).map(e => e.id.toString());
    delete result[location.id].employees;
  });

  dispatch({ type: actionTypes.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST, payload: result });
  dispatch(updateSettingsForQuickPlanning());
};

export const initScheduleViewState = () => dispatch => {
  dispatch(filterScheduleViewState());
  dispatch(sortScheduleViewState());
};

export const refreshScheduleData = () => dispatch => dispatch(getRouteInitData());
