import classnames from 'classnames';
import { memo } from 'react';

import { getContrastYIQv2 } from '@/utils/colorHelpers.js';

import './ColorLabel.scss';

type Props = {
  color: string;
  extra?: boolean;
  modifiers?: string | string[];
  text: string;
};

export const ColorLabel = memo(({ color, extra, modifiers = [], text }: Props) => {
  const relevantModifiers = Array.isArray(modifiers) ? modifiers : modifiers.split(' ');
  const labelClassNames = classnames(
    'label-employee label label-default',
    ...relevantModifiers.map(modifier => `colorLabel--${modifier}`),
  );

  return (
    <span
      className={labelClassNames}
      style={{
        backgroundColor: color,
        color: getContrastYIQv2(color.slice(1)),
      }}
    >
      {text}
      {extra ? <i className="material-icons label__star">add</i> : null}
    </span>
  );
});

ColorLabel.displayName = 'ColorLabel';
