import { connect } from 'react-redux';

import { deselectAllCheckboxes } from '@/actions/companymanage';

import ManageEmployeesView from './ManageEmployeesView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  deselectAllCheckboxes,
};

const ManageEmployeesViewContainer = connect(mapStateToProps, mapDispatchToProps)(ManageEmployeesView);

export default ManageEmployeesViewContainer;
