import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { MDTextArea, MDTimeRangeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { getFilteredShiftBlockOptions } from '@/utils/dateHelper';
import { createEvent, validateInput as validateInputHelper } from '@/utils/inputHelpers';

import RepeatShift from '../AddShiftAndAbsenceModal/RepeatShift/RepeatShift.jsx';
import {
  createNewShift,
  createRepeatObject,
  getDisabledLocationDate,
  getInitialState,
  getSummaryErrorMessage,
  getValueToCompare,
} from './AddPositionShiftModal.helpers';
import { messages } from './AddPositionShiftModal.messages';
import EmployeesForPosition from './EmployeesForPosition/EmployeesForPosition.redux';

import './AddPositionShiftModal.scss';

class AddPositionShiftModal extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props.mainDateStore);
    bindClassFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { start, end } = this.props.mainDateStore.customDate;
    const { start: prevStart, end: prevEnd } = prevProps.mainDateStore.customDate;

    if (start !== prevStart || end !== prevEnd) {
      this.setState({
        selectedRange: {
          start: this.props.mainDateStore.dateArray[0],
          end: this.props.mainDateStore.dateArray[this.props.mainDateStore.dateArray.length - 1],
        },
      });
    }
  }

  async onSubmit() {
    const valid = await this.validateAll();
    if (!valid) return;
    const shift = createNewShift(this.state, this.props.modalObject);

    if (this.state.displayRepeat) {
      const numberOfEmployeesWithError = this.addShiftsWithRepeat(shift);

      if (numberOfEmployeesWithError) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            massShiftsOverlap:
              numberOfEmployeesWithError &&
              this.context.intl.formatMessage(messages.repeatShiftErrorOverlap, {
                numberOfEmployee: numberOfEmployeesWithError,
              }),
          },
        }));

        return;
      }
    } else {
      this.addMassShift(shift);
    }
    this.hideAndClear();
  }

  addMassShift(shift) {
    const shiftsPromises = this.state.selectedEmployees.reduce(
      (prev, employee) => prev.concat(this.props.addShift(employee, { ...shift, employee }, false)),
      [],
    );
    this.props.addMassShift(shiftsPromises);
  }

  addShiftsWithRepeat(shift) {
    const repeatObj = createRepeatObject(this.state);
    const numberOfEmployeesWithError = this.props.addMultipleShiftsWithRepeat(
      this.state.selectedEmployees,
      shift,
      repeatObj,
    );

    return numberOfEmployeesWithError;
  }

  hideAndClear() {
    this.setState(getInitialState(this.props.mainDateStore));
    this.props.onHide();
  }

  handleInputChange(event) {
    const { value, name } = event.target;
    this.validateInput(event);
    this.setState({ [name]: value });
  }

  validateInput(event, valueToCompare) {
    validateInputHelper(event, valueToCompare).then(res => {
      const [name, error] = res;
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          [name]: error && this.context.intl.formatMessage(error, error.values),
        },
      }));
    });
  }

  async validateAll() {
    const inputs = this.state.displayRepeat ? ['selectedEmployees'] : ['selectedEmployees', 'workingHours'];

    const eventsToValidate = inputs.map(inputName =>
      createEvent(inputName, this.state[inputName], {
        valueToCompare: getValueToCompare(inputName, this.state, this.props.modalObject),
      }),
    );
    const disabledLocationDate = getDisabledLocationDate(this.state, this.props);
    if (disabledLocationDate) {
      eventsToValidate.push(
        createEvent('repeatObj', createRepeatObject(this.state), { valueToCompare: disabledLocationDate }),
      );
    }
    await Promise.all(eventsToValidate.map(event => this.validateInput(event, event.valueToCompare)));
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  render() {
    const filteredShiftBlockOptions = getFilteredShiftBlockOptions(
      this.props.userShiftblocks,
      this.props.modalObject.rowData.id,
    );

    return (
      <MDKadroModal
        show={this.props.showModal}
        title={this.context.intl.formatMessage(messages.title, {})}
        onSubmit={this.onSubmit}
        onHide={this.hideAndClear}
        errorMessage={getSummaryErrorMessage(this.state.errors)}
        modifiers={['narrow']}
      >
        <div className="heading addPositionShift__heading">
          {this.context.intl.formatMessage(messages.header, { jobTitle: this.props.modalObject.rowData.title })}
        </div>
        <EmployeesForPosition
          selectedEmployees={this.state.selectedEmployees}
          handleInputChange={this.handleInputChange}
        />
        <MDTimeRangeInput
          value={this.state.workingHours}
          options={filteredShiftBlockOptions}
          onChange={workingHours => this.handleInputChange(createEvent('workingHours', workingHours))}
          label={this.context.intl.formatMessage(messages.time)}
          modifiers={['modal']}
          errorMessage={this.state.errors.workingHours}
        />
        <MDTextArea
          label={this.context.intl.formatMessage(messages.note)}
          placeholder={this.context.intl.formatMessage(messages.notePlaceholder, {})}
          value={this.state.comment}
          onChange={this.handleInputChange}
          id="comment"
          name="comment"
        />
        <RepeatShift
          displayRepeat={this.state.displayRepeat}
          activeRepeatTab={this.state.activeRepeatTab}
          selectedRange={this.state.selectedRange}
          selectedChoices={this.state.selectedChoices}
          selectedWeekdays={this.state.selectedWeekdays}
          perWhichDay={this.state.perWhichDay}
          handleInputChange={(name, value) => this.handleInputChange(createEvent(name, value))}
          nonWorkingDays={this.props.nonWorkingDays}
          toggleNonWorkingDays={this.props.toggleNonWorkingDays}
          error={this.state.errors.massShiftsOverlap}
        />
      </MDKadroModal>
    );
  }
}

AddPositionShiftModal.defaultProps = {
  modalObject: {
    rowData: {
      title: '',
    },
  },
};

AddPositionShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddPositionShiftModal.propTypes = {
  userShiftblocks: PropTypes.arrayOf(PropTypes.shape({})),
  modalObject: PropTypes.shape({
    rowData: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    date: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  locationSettings: PropTypes.shape({}),
  addMultipleShiftsWithRepeat: PropTypes.func,
  onHide: PropTypes.func,
  addMassShift: PropTypes.func,
  addShift: PropTypes.func,
  showModal: PropTypes.bool,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  toggleNonWorkingDays: PropTypes.func,
  nonWorkingDays: PropTypes.bool,
};

export default AddPositionShiftModal;
