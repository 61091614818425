import {
  ACCEPT_CURRENT_REGULATIONS,
  GET_CURRENT_COMPANY_SUCCESFUL,
  SET_COMPANY_SETTINGS_SUCCESSFUL,
  SET_USER_EMAIL,
  UPDATE_CURRENT_COMPANY_SUCCESFUL,
} from '@/constants/ActionTypes.js';

const initialState = {
  user: {},
  status: {},
};

const currentCompany = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_COMPANY_SUCCESFUL: {
      const company = { ...action.payload.company };

      return {
        ...state,
        ...company,
      };
    }
    case UPDATE_CURRENT_COMPANY_SUCCESFUL:
      return {
        ...state,
        ...action.payload.company,
      };
    case SET_USER_EMAIL: {
      const user = Object.assign({}, state.user);
      user.email = action.payload;
      return Object.assign({}, state, { user });
    }
    case SET_COMPANY_SETTINGS_SUCCESSFUL:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case ACCEPT_CURRENT_REGULATIONS: {
      return {
        ...state,
        regulations_accepted: true,
      };
    }
    default:
      return state;
  }
};
export default currentCompany;
