import './smallExportIcon.scss';

interface IProps {
  type: string;
}

const SmallExportIcon = ({ type }: IProps) => {
  switch (type) {
    case 'XLS':
      return <div className="k-smallExportIcon k-smallExportIcon--xls">XLS</div>;
    case 'PDF':
      return <div className="k-smallExportIcon k-smallExportIcon--pdf">PDF</div>;
    case 'XML':
      return <div className="k-smallExportIcon k-smallExportIcon--xml">XML</div>;
    case 'CSV':
      return <div className="k-smallExportIcon k-smallExportIcon--csv">CSV</div>;
    default:
      return null;
  }
};

export default SmallExportIcon;
