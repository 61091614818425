import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'selectPackageContent.title',
    defaultMessage: 'Wybierz plan',
  },
});

export const messagesForIntercom = defineMessages({
  goodMorning: {
    id: 'selectPackageContent.goodMorning',
    defaultMessage: 'Dzień dobry',
  },
  buyPackage: {
    id: 'selectPackageContent.buyPackage',
    defaultMessage:
      'Interesuje mnie wykupienie pakietu {selectedPackageName} dla {employeesAmount, plural, one {pracownika} other {# pracowników}} i okresu {paymentPeriod}. W jaki sposób mogę aktywować moje konto?',
  },
  oneMonth: {
    id: 'selectPackageContent.oneMonth',
    defaultMessage: '1 miesiąca',
  },
  twelveMonths: {
    id: 'selectPackageContent.twelveMonths',
    defaultMessage: '12 miesięcy',
  },
});
