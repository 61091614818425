import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import './attendancePhoto.scss';

const AttendancePhoto = ({ attendanceStatus, attendanceStatusUrl, photoDescription }, { intl }) => {
  if (!attendanceStatus) return null;
  const attendancePhotoClassName = classnames('k-attendancePhoto__photo', {
    'k-attendancePhoto__photo--loading': attendanceStatus && !attendanceStatusUrl,
  });

  return (
    <div className="k-attendancePhoto">
      <span className="k-attendancePhoto__photoDescription">{intl.formatMessage(photoDescription)}</span>
      <div className={attendancePhotoClassName}>
        <a href={attendanceStatusUrl} download>
          <img className="k-attendancePhoto__img" src={attendanceStatusUrl} alt="clockIn" />
        </a>
      </div>
    </div>
  );
};

export default AttendancePhoto;

AttendancePhoto.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendancePhoto.propTypes = {
  attendanceStatus: PropTypes.bool,
  attendanceStatusUrl: PropTypes.string,
  photoDescription: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
};
