import {
  ASSIGN_USER_OPEN_SHIFT_SUCCESFUL,
  CHANGE_OPEN_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_SUCCESFUL,
  DELETE_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFTS_SUCCESFUL,
  MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
  PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL,
  USER_APPLY_OPEN_SHIFT_SUCCESFUL,
  USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL,
} from '@/constants/ActionTypes.js';

const initialState = [];

const openShifts = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPEN_SHIFTS_SUCCESFUL:
      return action.payload;
    case CREATE_OPEN_SHIFT_SUCCESFUL:
      return [...state, action.payload];
    case CHANGE_OPEN_SHIFT_SUCCESFUL:
    case GET_OPEN_SHIFT_SUCCESFUL:
    case ASSIGN_USER_OPEN_SHIFT_SUCCESFUL:
      if (action.payload.shifts_remaining === 0) return state.filter(openShift => openShift.id !== action.payload.id);
      return [
        ...state.map(openShift => {
          if (openShift.id !== action.payload.id) {
            return openShift;
          }
          return {
            ...openShift,
            ...action.payload,
          };
        }),
      ];
    case DELETE_OPEN_SHIFT_SUCCESFUL: {
      const openShiftToDeleteIndex = state.findIndex(openShift => openShift.id === action.payload);
      return [...state.slice(0, openShiftToDeleteIndex), ...state.slice(openShiftToDeleteIndex + 1)];
    }
    case USER_APPLY_OPEN_SHIFT_SUCCESFUL:
      return [
        ...state.map(openShift => {
          if (openShift.id !== action.payload.id) {
            return openShift;
          }
          return {
            ...openShift,
            eager_users_count: openShift.eager_users_count + 1,
            users: [...openShift.users, action.payload.currentUser.user],
          };
        }),
      ];
    case USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL:
      return [
        ...state.map(openShift => {
          if (openShift.id !== action.payload.id) {
            return openShift;
          }
          return {
            ...openShift,
            eager_users_count: openShift.eager_users_count - 1,
            users: openShift.users.filter(u => u.id !== action.payload.employeeId),
          };
        }),
      ];
    case PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL: {
      const { dateArray, locationId, selectedJobTitlesIds } = action.payload;
      return [
        ...state.map(shift => {
          if (
            dateArray.includes(shift.date) &&
            shift.location.id === locationId &&
            shift.draft === true &&
            selectedJobTitlesIds.includes(shift.job_title.id)
          ) {
            return {
              ...shift,
              draft: false,
            };
          }
          return shift;
        }),
      ];
    }
    case CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL:
      return [...state, action.payload.openShift];
    case MASS_DELETE_OPEN_SHIFTS_SUCCESFUL:
      return state.filter(openShift => !action.payload.includes(openShift.id));
    default:
      return state;
  }
};

export default openShifts;
