import { messages } from './DashboardChart.messages.js';

export const axisMetadata = {
  main: {
    dataKey: 'hour',
  },
};

export const metadata = [
  {
    key: 'numberOfAttendances',
    color: '#9ad752',
    label: messages.attendances,
    order: 1,
    yAxisId: 'left',
    chartType: 'line',
  },
  {
    key: 'numberOfShifts',
    color: '#2C223F',
    label: messages.shifts,
    order: 2,
    yAxisId: 'left',
    chartType: 'line',
  },
];
