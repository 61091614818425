export const INPUT_MODIFIERS = ['modal'];
export const TOOGLE_SMALL_MODIFIERS = 'small';
export const ATTENDANCE_WIDGET_PANEL_MODIFIERS = ['attendance'];
export const ORANGE_MODIFIER = ['orange'];

export const LARGE_MODIFIER = 'large';
export const CHART_MODIFIER = 'chart';
export const ROUNDING_MODIFIER = 'rounding';
export const NO_PADDING = 'noPadding';
export const STICKY_MODIFIER = 'sticky';
