import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getContrastYIQ } from '@/utils/colorHelpers.js';
import { calculateDurationMinutes } from '@/utils/dateHelper.js';

const calculateStyle = hours => {
  if (hours === null) {
    return { width: '99%' };
  }
  const duration = calculateDurationMinutes(hours);
  const startHour = calculateDurationMinutes(`00:00-${hours.split('-')[0]}`);
  const endHour = calculateDurationMinutes(`00:00-${hours.split('-')[1]}`);
  let width = (duration / 60 / 24) * 100;
  let left;
  if (startHour === 0) {
    left = 0.5;
  } else {
    left = (startHour / 60 / 24) * 100;
  }
  if (endHour < startHour) {
    width = 100 - left;
  }
  return { left: `${left}%`, width: `${width}%` };
};

/**
 * Returns the JSX block
 * @param {object} availability
 * @param {array} customAvaTypes
 */
const AvailabilityTableRow = ({
  availability,
  userCustomAvaTypes,
  date,
  employee,
  isToday,
  openAddAvailabilityModal,
  openEditAvailabilityModal,
}) => {
  const tdClassnames = classnames({
    empty: availability === null || typeof availability === 'undefined',
    today: isToday,
  });

  if (availability === null || typeof availability === 'undefined') {
    return (
      <tr>
        <td
          role="presentation"
          onClick={() => openAddAvailabilityModal(date, employee.id)}
          colSpan="24"
          className={tdClassnames}
        />
      </tr>
    );
  }
  let blockContent;
  const { draft } = availability;
  switch (availability.type) {
    case 'availability':
      blockContent = (
        <div
          role="presentation"
          onClick={() => openEditAvailabilityModal(availability, employee)}
          className="ava_block ava"
        >
          <div className="status">
            <FormattedMessage id="attendance.table.rowTypeAvailability" defaultMessage="Dostępny" />
          </div>
        </div>
      );
      break;
    case 'unavailability':
      blockContent = (
        <div
          role="presentation"
          onClick={() => openEditAvailabilityModal(availability, employee)}
          className="ava_block noava"
        >
          <div className="status">
            <FormattedMessage id="attendance.table.rowTypeUnavailability" defaultMessage="Niedostępny" />
          </div>
        </div>
      );
      break;
    case 'freetime': {
      const fStyle = calculateStyle(availability.hours);
      blockContent = (
        <div
          role="presentation"
          onClick={() => openEditAvailabilityModal(availability, employee)}
          className="ava_block partava"
          style={fStyle}
        >
          <div className="status">{availability.hours}</div>
        </div>
      );
      break;
    }
    case 'custom': {
      const cType = userCustomAvaTypes.find(c => c.id === availability.type_id);
      if (typeof cType === 'undefined') {
        blockContent = (
          <div
            role="presentation"
            onClick={() => openEditAvailabilityModal(availability, employee)}
            className="ava_block"
          >
            <div className="status">
              <FormattedMessage
                id="attendance.table.rowTypeCustom"
                defaultMessage="Niezidentyfikowana dostępność, popraw!"
              />
            </div>
          </div>
        );
        break;
      }
      const canEdit = cType.visible_for_employees;
      const barClass = classnames('ava_block', {
        draft_background: draft,
        'ava_block--disableEdit': !canEdit,
      });
      const dText = cType.name;
      const hours = cType.requires_time ? availability.hours : null;
      const cStyle = calculateStyle(hours);
      cStyle.backgroundColor = cType.color;
      cStyle.color = getContrastYIQ(cType.color);
      blockContent = (
        <div
          role="presentation"
          onClick={canEdit ? () => openEditAvailabilityModal(availability, employee) : () => {}}
          style={cStyle}
          className={barClass}
        >
          <div className="status">{dText}</div>
        </div>
      );
      break;
    }
    default:
      throw Error('Unkown avaiability type passed to getAvaText');
  }
  return (
    <tr>
      <td colSpan="24" className={tdClassnames}>
        {blockContent}
      </td>
    </tr>
  );
};

AvailabilityTableRow.propTypes = {
  openAddAvailabilityModal: PropTypes.func,
  openEditAvailabilityModal: PropTypes.func,
  availability: PropTypes.shape({}),
  userCustomAvaTypes: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.string,
  isToday: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default AvailabilityTableRow;
