import classNames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, useCallback } from 'react';

import { getCellWidth } from './MDTable.helpers';

const MDTableBody = ({
  rows,
  cols,
  getTableBodyProps,
  activeFilter,
  prepareRow,
  withOptions,
  empty,
  tableEmpty,
  rowsWithMergedColumns,
  withCheckbox,
  withHorizontalScroll,
  withBigPadding,
  shadowRightColumn,
  shadowLeftColumn,
  onRowClick,
}) => {
  const onRowClickCallback = useCallback(
    (row, event) => {
      if (!onRowClick) return;
      if (event.target.role === 'button') return;
      const selected = window.getSelection();
      const hasSelectedText = selected.toString().length > 0;
      if (!hasSelectedText) {
        onRowClick(row.original);
      }
    },
    [onRowClick],
  );

  const rowClassname = classNames('mdTable__row', {
    'mdTable__row--clickable': onRowClick,
  });

  return (
    <div className="mdTable__tbody" {...getTableBodyProps()}>
      {activeFilter && (
        <div className="mdTable__row mdTable__filterRow">
          {withCheckbox && !withHorizontalScroll && <td />}
          {cols.map(e => {
            const style = {
              maxWidth: e.id !== 'options' ? e.totalMaxWidth : undefined,
              minWidth: e.totalMinWidth,
              width: e.totalWidth,
              flexGrow: e.sticky ? 0 : 1,
            };
            return (
              <div
                className={classNames('mdTable__cell', {
                  'mdTable__cell--bigPadding': e.id !== 'selection' && withBigPadding,
                })}
                {...e.getHeaderProps()}
                style={style}
              >
                {e.render('Filter')}
              </div>
            );
          })}
          {withOptions && !withHorizontalScroll && <td />}
        </div>
      )}
      {empty ? (
        <tr>
          <th className="mdTable__emptyBody">{cloneElement(tableEmpty)}</th>
        </tr>
      ) : (
        rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <div
              className={rowClassname}
              {...row.getRowProps()}
              role="row"
              onMouseUp={event => onRowClickCallback(row, event)}
              tabIndex={rowIndex}
            >
              {row.cells.map((cell, cellIndex) => {
                if (rowsWithMergedColumns[rowIndex] && rowsWithMergedColumns[rowIndex].includes(cellIndex - 1))
                  return null;
                const columnToSpan =
                  rowsWithMergedColumns[rowIndex] && rowsWithMergedColumns[rowIndex].includes(cellIndex) ? 2 : 1;
                const cellClassName = classNames('mdTable__cell-content', {
                  'mdTable__cell-content--center': columnToSpan > 1,
                  'mdTable__cell-content--overflowVisible': ['selection', 'options', 'warnings'].includes(
                    cell.column.id,
                  ),
                });
                const cellProps = cell.getCellProps();
                const width = getCellWidth(
                  withHorizontalScroll,
                  cellProps?.style?.width,
                  row.cells,
                  cellIndex + 1,
                  columnToSpan,
                );
                const shouldHasShadowRight = cell.column.id === shadowRightColumn;
                const shouldHasShadowLeft = cell.column.id === shadowLeftColumn;

                return (
                  <div
                    {...cellProps}
                    className={classNames('mdTable__cell', {
                      'mdTable__cell--bigPadding': withBigPadding,
                      'mdTable__cell--shadowLeft': shouldHasShadowLeft,
                      'mdTable__cell--shadowRight': shouldHasShadowRight,
                    })}
                    style={{ ...cellProps.style, ...width }}
                  >
                    <span className="mdTable__label--mobile">{cell.column.render('Header')}</span>
                    <div className={cellClassName}>{cell.render('Cell')}</div>
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
};

MDTableBody.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

MDTableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  cols: PropTypes.arrayOf(PropTypes.shape({})),
  getTableBodyProps: PropTypes.func,
  activeFilter: PropTypes.bool,
  prepareRow: PropTypes.func,
  options: PropTypes.node,
  withOptions: PropTypes.bool,
  empty: PropTypes.bool,
  tableEmpty: PropTypes.node,
  rowsWithMergedColumns: PropTypes.shape({}),
  withCheckbox: PropTypes.bool,
  withHorizontalScroll: PropTypes.bool,
  withBigPadding: PropTypes.bool,
  shadowRightColumn: PropTypes.string,
  shadowLeftColumn: PropTypes.string,
  onRowClick: PropTypes.func,
};

export default MDTableBody;
