import { connect } from 'react-redux';

import { openRequestTradeShiftModal } from '@/actions/uiState.js';

import EmployeeScheduleShift from './EmployeeScheduleShift.jsx';

const mapStateToProps = (state, ownProps) => ({
  userJobTitles: state.reducer.userJobTitles,
  dateArray: state.reducer.mainDateStore.dateArray,
  userLocations: state.reducer.userLocations,
  currentUserId: state.reducer.currentUser.user.id,
  tradeShifts: state.reducer.tradeShifts,
  isTradeShiftEnabled: state.reducer.settings.locationSettings[ownProps.shift?.location?.id]?.enable_trade_shift,
  restrictions: state.reducer.userPermissions.restrictions,
  employeeContracts: state.reducer.contracts[ownProps.employee.id],
});

const mapDispatchToProps = {
  openRequestTradeShiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeScheduleShift);
