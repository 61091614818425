import TextColumnFilter from '@/components/common/MDTable/TextColumnFilter';

export const getCellWidth = (withHorizontalScroll, cellWidth, rowCells, index, columnToSpan) => {
  if (!withHorizontalScroll || !cellWidth || columnToSpan === 1) return {};
  const mergedCellWidth = rowCells[index].getCellProps()?.style?.width;
  return { width: parseInt(cellWidth) + parseInt(mergedCellWidth) };
};

export const defaultColumn = {
  Filter: TextColumnFilter,
  minWidth: 50,
  width: 50,
  maxWidth: 400,
};

export const getShadowColumns = (columns, { withShadowOnStickyColumns, withHorizontalScroll }) => {
  const areShadowColumnsNeeded = withShadowOnStickyColumns && withHorizontalScroll;
  if (!areShadowColumnsNeeded) {
    return {
      shadowRightColumn: null,
      shadowLeftColumn: null,
    };
  }

  const stickyLeftColumns = columns.filter(column => column.sticky === 'left');
  const stickyRightColumns = columns.filter(column => column.sticky === 'right');

  return {
    shadowLeftColumn: stickyRightColumns.length ? stickyRightColumns[0].id : null,
    shadowRightColumn: stickyLeftColumns.length ? stickyLeftColumns[stickyLeftColumns.length - 1].id : null,
  };
};
