import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Icon from '@/components/common/Basic/Icon/Icon';
import PayrollReloadInstruction from '@/components/common/PayrollReloadInstruction/PayrollReloadInstruction';
import TopBarMultipleColumnFilter from '@/components/TopBars/filters/TopBarMultipleColumnFilter/TopBarMultipleColumnFilter.redux';
import { REVERSE_GREEN, REVERSE_RED } from '@/constants/buttonModifiers';

import PayrollLocationActionButtons from './PayrollLocationActionButtons/PayrollLocationActionButtons.redux';
import PayrollLocationSummary from './PayrollLocationSummary/PayrollLocationSummary.redux';

import './PayrollLocationButtonBar.scss';

const PayrollLocationButtonBar = ({
  visibleRows,
  setActiveFilter,
  isLoadButtonEnabled,
  getPayrollLocationViewData,
  changePayrollDaysStatusForMultipleEmployees,
  selectedData,
  isFetching,
  payrollApprovalEnabled,
}) => {
  const handleApproveClick = useCallback(() => {
    const employeeIds = selectedData.map(item => item.employee_id);

    if (employeeIds.length > 0) {
      changePayrollDaysStatusForMultipleEmployees(employeeIds, 'approved');
    }
  }, [changePayrollDaysStatusForMultipleEmployees, selectedData]);
  const handleUnapproveClick = useCallback(() => {
    const employeeIds = selectedData.map(item => item.employee_id);

    if (employeeIds.length > 0) {
      changePayrollDaysStatusForMultipleEmployees(employeeIds, 'unapproved');
    }
  }, [changePayrollDaysStatusForMultipleEmployees, selectedData]);
  const visibleEmployeeIds = useMemo(() => visibleRows.map(item => item.employee_id), [visibleRows]);

  return (
    <div className="k-payrollLocationButtonBar">
      <div className="k-payrollButtonBar__row">
        <div className="k-payrollLocationButtonBar__right">
          {isLoadButtonEnabled && !isFetching && <PayrollReloadInstruction handleClick={getPayrollLocationViewData} />}
        </div>
        <div className="k-payrollLocationButtonBar__right">
          {payrollApprovalEnabled && (
            <>
              <Button modifiers={REVERSE_GREEN} onClick={handleApproveClick} disabled={selectedData.length === 0}>
                <span className="material-icons">check</span>
                <FormattedMessage id="payrollLocationTable.approve.button" defaultMessage="Oznacz jako zatwierdzone" />
              </Button>
              <Button modifiers={REVERSE_RED} onClick={handleUnapproveClick} disabled={selectedData.length === 0}>
                <span className="material-icons">remove_done</span>
                <FormattedMessage id="payrollLocationTable.unapprove.button" defaultMessage="Oznacz jako oczekujące" />
              </Button>
            </>
          )}
          <TopBarMultipleColumnFilter
            key="multipleColumnFilter"
            payrollType="newPayrollLocation"
            onlyIcon
            className="k-topBarChoice--small k-topBarChoice--pinned-right"
            icon={<Icon type="material-outlined" name="view_column" />}
          />
          <Button modifiers="onlyIcon reverse-gray" onClick={() => setActiveFilter(active => !active)}>
            <i className="material-icons">search</i>
          </Button>
          <PayrollLocationActionButtons {...{ visibleEmployeeIds }} />
        </div>
      </div>
      <PayrollLocationSummary />
    </div>
  );
};

PayrollLocationButtonBar.propTypes = {
  setActiveFilter: PropTypes.func,
  getPayrollLocationViewData: PropTypes.func,
  isLoadButtonEnabled: PropTypes.bool,
  changePayrollDaysStatusForMultipleEmployees: PropTypes.func,
  selectedData: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.arrayOf(PropTypes.string),
  payrollApprovalEnabled: PropTypes.bool,
  visibleRows: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
};

export default PayrollLocationButtonBar;
