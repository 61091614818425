import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';

import CalendarAvaList from './CalendarAvaList.jsx';

import './CalendarView.css';

class CalendarView extends Component {
  constructor(props) {
    super(props);
    const now = moment();
    this.month = now.month();
    const weeks = this.generateMonth(now);

    this.state = {
      weeks,
      month: moment([now.year(), now.month()]),
      day: moment(),
      showModal: false,
      ava: null,
    };

    // touch vars
    this.touchStartX = 0;
    this.touchStartY = 0;

    this.container = {};

    this.closeModalFunc = this.closeModal.bind(this);
    this.dayClickFunc = this.dayClick.bind(this);

    this.lastMDays = 0; // how many days we display from last month
    if (this.props.mainDateStore.dateMode === 'week') {
      this.props.mainDateChangeMode('month');
    }

    this.handleStart = this.touchStart.bind(this);
    this.handleEnd = this.touchEnd.bind(this);
  }

  componentDidMount() {
    const el = this.container;
    el.addEventListener('touchstart', this.handleStart, false);
    el.addEventListener('touchend', this.handleEnd, false);
  }
  componentWillUnmount() {
    const el = this.container;
    el.removeEventListener('touchstart', this.handleStart, false);
    el.removeEventListener('touchend', this.handleEnd, false);
  }

  touchStart(evt) {
    // evt.preventDefault();
    const pointer = evt.changedTouches[0];
    this.touchStartX = pointer.pageX;
    this.touchStartY = pointer.pageY;
  }
  touchEnd(evt) {
    // evt.preventDefault();
    const pointer = evt.changedTouches[0];
    const pozX = pointer.pageX;
    const divX = this.touchStartX - pozX;
    if (divX > 100) {
      this.changeMonth(1);
    } else if (divX < -100) {
      this.changeMonth(-1);
    }
  }

  changeMonth(dif) {
    const { month } = this.state;
    month.add(dif, 'month');
    const weeks = this.generateMonth(month);
    this.setState({ month, weeks });
    if (dif === 1) {
      this.props.mainDateMoveRight();
    } else {
      this.props.mainDateMoveLeft();
    }
  }

  generateMonth(input) {
    const lastM = moment({ year: input.year(), month: input.month(), day: 1 });
    const date = moment(lastM);
    lastM.add(-1, 'month');
    const last = date.day() - 1;
    const days = [];
    const daysDone = 42;
    this.last = last;
    date.add(-1 * last, 'day');
    for (let i = 1; i <= daysDone; i++) {
      days.push(moment(date));
      date.add(1, 'day');
    }
    const weeks = [];
    for (let i = 0; i < 6; i++) {
      const w = [];
      for (let j = 0; j < 7; j++) {
        w.push(days[i * 7 + j]);
      }
      weeks.push(w);
    }
    return weeks;
  }

  dayClick(day, ava) {
    this.setState({
      showModal: true,
      day,
      ava,
    });
  }
  closeModal() {
    this.setState({
      showModal: false,
      day: moment(),
    });
  }

  render() {
    const availabilities = [];
    const selectedEmployeeId = this.props.singleEmployeeFilter.id;

    // We want the employee from userEmployees in case of updates
    const employee = this.props.userEmployees.find(e => e.id === selectedEmployeeId);

    for (let x = 0; x < this.props.mainDateStore.dateArray.length; x++) {
      // Splits into ["dddd", "DD.MM"] ex. poniedziałek 01.12
      const date = this.props.mainDateStore.dateArray[x];

      let availability;

      if (employee) {
        availability = employee.availability_blocks.find(a => a.date === date);
      } else {
        availability = null;
      }
      const today = this.props.mainDateStore.dateArray[x] === this.props.mainDateStore.today;

      availabilities.push({
        date,
        availability,
        today,
        userCustomAvaTypes: this.props.userCustomTypes,
      });
    }

    return (
      <div
        className="calendar-view-container"
        ref={e => {
          this.container = e;
        }}
      >
        <CalendarAvaList
          avas={availabilities}
          day={this.state.day}
          visible={this.state.showModal}
          closeFunc={this.closeModalFunc}
          ava={this.state.ava}
          deleteAva={this.props.deleteAvailability}
          addAva={this.props.addAvailability}
          editAva={this.props.changeAvailability}
          employeeId={employee ? employee.id : -1}
          currentCompany={this.props.currentCompany}
        />
        <h1 className="calendar-view-header">{this.state.month.format('MMMM YYYY')}</h1>
        <table className="calendar-view-table">
          <thead>
            <tr>
              <th>Pn</th>
              <th>Wt</th>
              <th>Śr</th>
              <th>Czw</th>
              <th>Pt</th>
              <th>So</th>
              <th>Nd</th>
            </tr>
          </thead>
          <tbody>
            {this.state.weeks.map((week, i) => (
              <tr key={i}>
                {week.map((day, j) => (
                  <DayCircle
                    day={day}
                    month={this.state.month.month()}
                    key={7 * i + j}
                    clickFunc={this.dayClickFunc}
                    ava={7 * i + j >= this.last ? availabilities[7 * i + j - this.last] : null}
                  />
                ))}
              </tr>
            ))}
            {}
          </tbody>
        </table>
      </div>
    );
  }
}

function findAvaType(ava) {
  if (!ava.availability || ava.availability.type !== 'custom') return null;

  const id = ava.availability.type_id;

  for (const type in ava.userCustomAvaTypes) {
    if (ava.userCustomAvaTypes[type].id === id) {
      return ava.userCustomAvaTypes[type];
    }
  }
  return null;
}
const DayCircle = props => {
  let type = null;
  let color = '#ceff9a';

  if (props.ava) {
    type = findAvaType(props.ava);
    if (type) {
      color = type.color;
    }
    if (props.ava.availability) {
      switch (props.ava.availability.type) {
        case 'unavailability':
          color = '#ffa298';
          break;
        case 'freetime':
          color = '#999';
          break;
        default:
          break;
      }
    }
  }

  if (props.day.month() === props.month) {
    if (props.ava && props.ava.availability) {
      return (
        <td
          role="none"
          onClick={() => {
            props.clickFunc(props.day, props.ava);
          }}
        >
          <div className="calendar-circle" style={{ backgroundColor: color }}>
            <div className="circle-date">{props.day.date()}</div>
          </div>
        </td>
      );
    }
    return (
      <td
        role="none"
        onClick={() => {
          props.clickFunc(props.day, null);
        }}
      >
        {props.day.date()}
      </td>
    );
  }
  return (
    <td className="calendar-cell-other" style={{ color: '#888' }}>
      {props.day.date()}
    </td>
  );
};
CalendarView.propTypes = {
  mainDateChangeMode: PropTypes.func,
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  singleEmployeeFilter: PropTypes.shape({}),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({}),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  deleteAvailability: PropTypes.func,
  addAvailability: PropTypes.func,
  changeAvailability: PropTypes.func,
  currentCompany: PropTypes.shape({}),
};

DayCircle.propTypes = {
  ava: PropTypes.shape({}),
  day: PropTypes.shape({}),
  clickFunc: PropTypes.func,
  month: PropTypes.number,
};
export default CalendarView;
