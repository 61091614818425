export const ACCOUNT_DISABLE = 'ACCOUNT:DISABLE';

export const ATTENDANCE_VIEW_HIDE = 'ATTENDANCE_VIEW:HIDE';

export const ATTENDANCE_EDIT_OTHERS_DISABLE = 'ATTENDANCE_EDIT_OTHERS:DISABLE';

export const AVAILABILITIES_VIEW_DISABLE_CONFIRM = 'AVAILABILITIES_VIEW:DISABLE_CONFIRM';

export const AVAILABILITIES_VIEW_HIDE = 'AVAILABILITIES_VIEW:HIDE';

export const EMPLOYEE_PAYROLL_HIDE = 'EMPLOYEE_PAYROLL:HIDE';

export const BUDGET_INFO_HIDE = 'BUDGET_INFO:HIDE';

export const BUDGET_INFO_HIDE_EDIT = 'BUDGET_INFO:EDIT';

export const COMPANY_MANAGE_ABSENCETYPES_HIDE = 'COMPANY_MANAGE_ABSENCETYPESW:HIDE';

export const COMPANY_MANAGE_AVAILABILITIES_HIDE = 'COMPANY_MANAGE_AVAILABILITIES:HIDE';

export const COMPANY_MANAGE_AVAILABILITIES_HIDE_DELETE = 'COMPANY_MANAGE_AVAILABILITIES:HIDE_DELETE';

export const COMPANY_MANAGE_AVAILABILITIES_HIDE_EDIT = 'COMPANY_MANAGE_AVAILABILITIES:HIDE_EDIT';

export const COMPANY_MANAGE_CONDITIONS_HIDE = 'COMPANY_MANAGE_CONDITIONS:HIDE';

export const COMPANY_MANAGE_CONDITIONS_HIDE_EDIT = 'COMPANY_MANAGE_CONDITIONS:HIDE_EDIT';

export const COMPANY_MANAGE_EMPLOYMENTCONDITIONS_HIDE_DELETE = 'COMPANY_MANAGE_EMPLOYMENTCONDITIONS:HIDE_DELETE';

export const COMPANY_MANAGE_DEVICES_HIDE = 'COMPANY_MANAGE_DEVICES:HIDE';

export const COMPANY_MANAGE_DEVICES_HIDE_DELETE = 'COMPANY_MANAGE_DEVICES:HIDE_DELETE';

export const COMPANY_MANAGE_DEVICES_HIDE_EDIT = 'COMPANY_MANAGE_DEVICES:HIDE_EDIT';

export const COMPANY_MANAGE_EMPLOYEES_HIDE = 'COMPANY_MANAGE_EMPLOYEES:HIDE';

export const COMPANY_MANAGE_EMPLOYEES_HIDE_ADD = 'COMPANY_MANAGE_EMPLOYEES:HIDE_ADD';

export const COMPANY_MANAGE_EMPLOYEES_HIDE_DELETE = 'COMPANY_MANAGE_EMPLOYEES:HIDE_DELETE';

export const COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT = 'COMPANY_MANAGE_EMPLOYEES:HIDE_EDIT';

export const COMPANY_MANAGE_JOBTITLES_HIDE = 'COMPANY_MANAGE_JOBTITLES:HIDE';

export const COMPANY_MANAGE_JOBTITLES_HIDE_ADD = 'COMPANY_MANAGE_JOBTITLES:HIDE_ADD';

export const COMPANY_MANAGE_JOBTITLES_HIDE_DELETE = 'COMPANY_MANAGE_JOBTITLES:HIDE_DELETE';

export const COMPANY_MANAGE_JOBTITLES_HIDE_EDIT = 'COMPANY_MANAGE_JOBTITLES:HIDE_EDIT';

export const COMPANY_MANAGE_LOCATIONS_HIDE = 'COMPANY_MANAGE_LOCATIONS:HIDE';

export const COMPANY_MANAGE_LOCATIONS_HIDE_ADD = 'COMPANY_MANAGE_LOCATIONS:HIDE_ADD';

export const COMPANY_MANAGE_LOCATIONS_HIDE_DELETE = 'COMPANY_MANAGE_LOCATIONS:HIDE_DELETE';

export const COMPANY_MANAGE_LOCATIONS_HIDE_EDIT = 'COMPANY_MANAGE_LOCATIONS:HIDE_EDIT';

export const COMPANY_MANAGE_SHIFTBLOCKS_HIDE = 'COMPANY_MANAGE_SHIFTBLOCKS:HIDE';

export const COMPANY_MANAGE_SHIFTBLOCKS_HIDE_DELETE = 'COMPANY_MANAGE_SHIFTBLOCKS:HIDE_DELETE';

export const COMPANY_MANAGE_SHIFTBLOCKS_HIDE_EDIT = 'COMPANY_MANAGE_SHIFTBLOCKS:HIDE_EDIT';

export const COMPANY_MANAGE_VIEW_HIDE = 'COMPANY_MANAGE_VIEW:HIDE';

export const COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT = 'COMPANY_MANAGE_EMPLOYEES_LIMITS:HIDE_EDIT';

export const DASHBOARD_VIEW_HIDE = 'DASHBOARD_VIEW:HIDE';

export const ECP_HIDE = 'ECP:HIDE';

export const EMPLOYEES_CODE_NAMES = 'EMPLOYEES:CODE_NAMES';

export const EMPLOYMENT_CONDITIONS_DISABLE_DEFAULT = 'EMPLOYMENT_CONDITIONS:DISABLE_DEFAULT';

export const EXPORT_VIEW_HIDE = 'EXPORT_VIEW:HIDE';

export const INTERCOM_DISABLE = 'INTERCOM:DISABLE';

export const LANGUAGE_SETTINGS_HIDE = 'LANGUAGE_SETTINGS:HIDE';

export const LOCATION_ATTENDANCE_CLOCK_REQUIRE_ALL = 'LOCATION_ATTENDANCE_CLOCK:REQUIRE_ALL';

export const MANAGER_EDIT_OWN_ATTENDANCES = 'MANAGER:EDIT_OWN_ATTENDANCES';

export const MANAGER_ROLE_HIDE = 'MANAGER_ROLE:HIDE';

export const MANAGER_VIEW_EMPLOYEES_PAYROLL = 'MANAGER:VIEW_EMPLOYEES_PAYROLL';

export const NOTIFICATIONS_VIEW_HIDE = 'NOTIFICATIONS_VIEW:HIDE';

export const PAYROLL_LOCATION_VIEW_HIDE = 'PAYROLL_LOCATION_VIEW:HIDE';

export const PAYROLL_SETTINGS_EDIT_DISABLE = 'PAYROLL_SETTINGS_EDIT:DISABLE';

export const PAYROLL_VIEW_HIDE = 'PAYROLL_VIEW:HIDE';

export const PAYROLL_VIEW_HIDE_EDIT = 'PAYROLL_VIEW:HIDE_EDIT';

export const PRODUCTION_QUOTAS_VIEW_HIDE = 'PRODUCTION_QUOTAS_VIEW:HIDE';

export const REPORTS_VIEW_HIDE = 'REPORTS_VIEW:HIDE';

export const SCHEDULE_VIEW_HIDE = 'SCHEDULE_VIEW:HIDE';

export const ALL_SETTINGS_VIEW_HIDE = 'ALL_SETTINGS_VIEW:HIDE';

export const SETTINGS_LOCATIONS_HIDE = 'SETTINGS_VIEW:HIDE';

export const WORKING_RULES_HIDE = 'WORKING_RULES:HIDE';

export const SUPPLEMENTARY_EMPLOYEES_HIDE_MODIFY = 'SUPPLEMENTARY_EMPLOYEES:HIDE_MODIFY';

export const SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES_HIDE_ADD = 'SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES:HIDE_ADD';

export const ABSENCES_LEAVE_REQUEST_VIEW_HIDE = 'ABSENCES_LEAVE_REQUEST_VIEW:HIDE';

export const ABSENCES_LIMIT_VIEW_HIDE = 'ABSENCES_LIMIT_VIEW:HIDE';

export const COMPANY_MANAGE_ABSENCE_TYPES_HIDE = 'COMPANY_MANAGE_ABSENCE_TYPES:HIDE';

export const SCHEDULE_EDIT_DISABLE = 'SCHEDULE_EDIT:DISABLE';

export const COUNT_DAYS_WITH_SHIFTS_HIDE = 'COUNT_DAYS_WITH_SHIFTS:HIDE';

export const OPENING_HOURS_HIDE = 'OPENING_HOURS:HIDE';

export const ABSENCES_FOR_OTHERS_HIDE_ADD = 'ABSENCES_FOR_OTHERS:HIDE_ADD';

export const COMPANY_MANAGE_LOCATIONS_HIDE_ASSIGN = 'COMPANY_MANAGE_LOCATIONS:HIDE_ASSIGN';

export const ABSENCES_EDIT_HIDE = 'ABSENCES_EDIT:HIDE';

export const FREEMIUM_HIDE_COMPANY_MANAGE_AVAILABILITIES = 'FREEMIUM:HIDE_COMPANY_MANAGE_AVAILABILITIES';

export const FREEMIUM_HIDE_COMPANY_MANAGE_DEVICES = 'FREEMIUM:HIDE_COMPANY_MANAGE_DEVICES';

export const FREEMIUM_HIDE_ABSENCES_VIEW = 'FREEMIUM:HIDE_ABSENCES_VIEW';

export const FREEMIUM_HIDE_ATTENDANCE_VIEW = 'FREEMIUM:HIDE_ATTENDANCE_VIEW';

export const FREEMIUM_HIDE_REPORTS_VIEW = 'FREEMIUM:HIDE_REPORTS_VIEW';

export const FREEMIUM_DISABLE_USER_VIEW_NOTIFICATIONS = 'FREEMIUM:DISABLE_USER_VIEW_NOTIFICATIONS';

export const FREEMIUM_DISABLE_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS =
  'FREEMIUM:DISABLE_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS';

export const FREEMIUM_DISABLE_EMPLOYEE_MODAL_ABSENCE_LIMITS = 'FREEMIUM:DISABLE_EMPLOYEE_MODAL_ABSENCE_LIMITS';

export const FREEMIUM_HIDE_OPEN_SHIFTS = 'FREEMIUM:HIDE_OPEN_SHIFTS';

export const FREEMIUM_HIDE_TRADE_SHIFTS = 'FREEMIUM:HIDE_TRADE_SHIFTS';

export const FREEMIUM_DISABLE_ROLE_VIEW = 'FREEMIUM:DISABLE_ROLE_VIEW';

export const FREEMIUM_HIDE_COMPANY_MANAGE_ABSENCE_TYPES = 'FREEMIUM:HIDE_COMPANY_MANAGE_ABSENCE_TYPES';

export const FREEMIUM_DISABLE_ADD_ABSENCE = 'FREEMIUM:DISABLE_ADD_ABSENCE';

export const FREEMIUM_HIDE_SETTINGS_IMPORT_VIEW = 'FREEMIUM:HIDE_SETTINGS_IMPORT_VIEW';

export const FREEMIUM_DISABLE_EMPLOYEE_VIEW_EXPORT = 'FREEMIUM:DISABLE_EMPLOYEE_VIEW_EXPORT';

export const FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW = 'FREEMIEM:DISABLE_LOCATION_SETTINGS_VIEW';

export const FREEMIUM_DISABLE_PAYROLL_SETTINGS = 'FREEMIUM:DISABLE_PAYROLL_SETTINGS';

export const FREEMIUM_DISABLE_PASSWORD_REFRESH = 'FREEMIUM:DISABLE_PASSWORD_REFRESH';

export const FREEMIUM_DISABLE_RESTRICTIONS_SETTINGS = 'FREEMIUM:DISABLE_RESTRICTIONS_SETTINGS';

export const FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE = 'FREEMIUM:DISABLE_AUTO_GENERATE_SCHEDULE';

export const FREEMIUM_HIDE_WORKING_RULES = 'FREEMIUM:HIDE_WORKING_RULES';

export const FREEMIUM_HIDE_SCHEDULE_TEMPLATES = 'FREEMIUM:HIDE_SCHEDULE_TEMPLATES';

export const INACTIVE_EMPLOYEES_HIDE = 'INACTIVE_EMPLOYEES:HIDE';

export const OLD_PAYROLL_HIDE = 'OLD_PAYROLL:HIDE';

export const ATTENDANCE_EDIT_HIGHER_EQUAL_DISABLE = 'ATTENDANCE_EDIT_HIGHER_EQUAL:DISABLE';

export const SKIP_WEEKENDS_AND_HOLIDAYS_HIDE = 'SKIP_WEEKENDS_AND_HOLIDAYS:HIDE';

export const OVERTIME_COLLECTION_HIDE = 'OVERTIME_COLLECTION:HIDE';

export const CONTRACTS_EDIT_HIDE = 'CONTRACTS_EDIT:HIDE';
export const EMPLOYEE_PAYROLL_VIEW_HIDE = 'EMPLOYEE_PAYROLL_VIEW:HIDE';
