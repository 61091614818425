import classnames from 'classnames';
import { uniq } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import Checkbox from '@/components/common/inputs/KadroCheckbox.jsx';
import PopoverCorner from '@/components/common/PopOverCorner.jsx';
import PayrollTableRowWarning from '@/components/payroll/PayrollTableRowWarning.jsx';
import { payrollLocationTableColumns } from '@/constants/tableColumns.jsx';
import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import { createOvertimeScheduleCycleSpanLabel } from '@/utils/overtimeHelpers.js';
import {
  displayPlannedDiff,
  getLocationNameForId,
} from '@/utils/payrollHelpers.jsx';

import PayrollLocationTableRowDetails from './PayrollLocationTableRowDetails.redux.js';

const messages = defineMessages({
  overtimeTitle: {
    id: 'payrollTable.overtime.title',
    defaultMessage: 'Nadgodziny {type}',
  },
  estimatedValue: {
    id: 'payrollTable.estimatedValue',
    defaultMessage: 'Prognozowana wartość',
  },
  noData: {
    id: 'payroll.noData',
    defaultMessage: 'Brak danych',
  },
  notFoundLocation: {
    id: 'location.notFoundLocation',
    defaultMessage: 'Brak lokalizacji',
  },
});

class PayrollLocationTableRow extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showDetails: false,
    };

    this.showDetails = this.showDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showDetails: nextProps.relevantRowInfo.details.length
        ? this.state.showDetails
        : false,
    });
  }

  showDetails() {
    this.setState((prev) => ({
      showDetails: this.props.relevantRowInfo.details.length
        ? !prev.showDetails
        : prev.showDetails,
    }));
  }

  render() {
    const { employee, relevantRowInfo } = this.props;

    const blocks50 = relevantRowInfo?.details?.reduce(
      (acc, cur) => [
        ...acc,
        ...(cur.overtime?.blocks?.filter(
          (block) => block.type === 'overtime50'
        ) || []),
      ],
      []
    );
    const blocks100 = relevantRowInfo?.details?.reduce(
      (acc, cur) => [
        ...acc,
        ...(cur.overtime?.blocks?.filter(
          (block) => block.type === 'overtime100'
        ) || []),
      ],
      []
    );

    const overtime50PopOver = {
      title: this.context.intl.formatMessage(messages.overtimeTitle, {
        type: '50%',
      }),
      content: (
        <div className="k-payroll__OvertimePopoverContent">
          {blocks50.map((block) => (
            <div className="k-payroll__popoverPayrollRow">
              <span>{moment(block.startAt).format('dd DD.MM')}</span>
              <span>{`${moment(block.startAt).format('HH:mm')} - ${moment(
                block.endAt
              ).format('HH:mm')}`}</span>
            </div>
          ))}
        </div>
      ),
    };
    const overtime100PopOver = {
      title: this.context.intl.formatMessage(messages.overtimeTitle, {
        type: '100%',
      }),
      content: (
        <div className="k-payroll__OvertimePopoverContent">
          {blocks100.map((block) => (
            <div className="k-payroll__popoverPayrollRow">
              <span>{moment(block.startAt).format('dd DD.MM')}</span>
              <span>{`${moment(block.startAt).format('HH:mm')} - ${moment(
                block.endAt
              ).format('HH:mm')}`}</span>
            </div>
          ))}
        </div>
      ),
    };

    let scheduleCycleOvertime = null;
    if (employee.overtimeStats.overtime) {
      const { from, to } = employee.overtimeStats;
      const value = parseMinutesToFormat(
        employee.overtimeStats.overtime.scheduleCycleOvertime,
        this.props.payrollSettings.timeFormatSetting.type
      );
      const scheduleCycleSpan = createOvertimeScheduleCycleSpanLabel(from, to);
      scheduleCycleOvertime = { value, scheduleCycleSpan };
    }
    const detailsClassNames = classnames('k-payroll__details', {
      'k-payroll__details--show':
        this.state.showDetails && this.props.visibleLocationColumns.length > 1,
    });
    const employeeClassNames = classnames('k-colname day', {
      'k-payroll__col-name':
        !this.props.relevantRowInfo.details.length &&
        this.props.visibleLocationColumns.length > 1,
    });
    const colgroup = (
      <colgroup>
        {payrollLocationTableColumns.colgroup.map((width, i) => {
          if (this.props.visibleLocationColumns.includes(i))
            return <col key={i} style={{ width: `${width}%` }} />;
          return null;
        })}
      </colgroup>
    );
    const { sumOvertime50, sumOvertime100 } = relevantRowInfo;

    const locations = relevantRowInfo.details
      .reduce((locationsList, { location }) => {
        const locationName = getLocationNameForId(
          location?.id,
          this.props.userLocations,
          location?.id
            ? this.context.intl.formatMessage(messages.notFoundLocation)
            : ''
        );
        return location?.id && !locationsList.includes(locationName)
          ? [...locationsList, locationName]
          : locationsList;
      }, [])
      .join(' / ');

    const formattedSumOvertime100 =
      sumOvertime100 || sumOvertime100 === 0
        ? parseMinutesToFormat(
            relevantRowInfo.sumOvertime100,
            this.props.payrollSettings.timeFormatSetting.type
          )
        : '-';
    const formattedSumOvertime50 =
      sumOvertime50 || sumOvertime50 === 0
        ? parseMinutesToFormat(
            relevantRowInfo.sumOvertime50,
            this.props.payrollSettings.timeFormatSetting.type
          )
        : '-';

    const items = [
      <td
        onClick={this.showDetails}
        className={employeeClassNames}
        role="presentation"
        key="employee"
      >
        <Checkbox
          onChange={() => {
            this.props.toggleSelected(employee.id);
          }}
          checked={this.props.payrollUI.selectedEmployees.includes(employee.id)}
        />
        {this.props.lastNameFirst ? (
          <span>
            {employee.last_name} {employee.first_name}
          </span>
        ) : (
          <span>
            {employee.first_name} {employee.last_name}
          </span>
        )}
        {this.props.relevantRowInfo.details.length &&
          this.props.visibleLocationColumns.length > 1 && (
            <i
              className="material-icons sortdir noselect"
              style={{ marginTop: '0px' }}
            >
              {this.state.showDetails ? 'arrow_drop_down' : 'arrow_drop_up'}
            </i>
          )}
        {relevantRowInfo.numberOfWarnings > 0 && (
          <PayrollTableRowWarning count={relevantRowInfo.numberOfWarnings} />
        )}
      </td>,
      <td key="jobTitle">
        {uniq(
          relevantRowInfo.details
            .filter((detail) => detail.jobTitle)
            .map((detail) => detail.jobTitle)
        ).join(' / ')}
      </td>,
      <td key="location">{locations}</td>,
      <td key="wage">
        {uniq(
          relevantRowInfo.details.map((detail) =>
            detail.wage
              ? String(detail.wage)
              : this.context.intl.formatMessage(messages.noData)
          )
        ).join(' / ')}
      </td>,
      <td key="absence">
        {relevantRowInfo.sumAbsences
          ? parseMinutesToFormat(
              relevantRowInfo.sumAbsences,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '---'}
      </td>,
      <td key="sumHours">
        {parseMinutesToFormat(
          relevantRowInfo.sumHours,
          this.props.payrollSettings.timeFormatSetting.type
        )}
      </td>,
      <td key="plannedHours">
        {`${parseMinutesToFormat(
          relevantRowInfo.sumHoursReal,
          this.props.payrollSettings.timeFormatSetting.type
        )} / ${parseMinutesToFormat(
          relevantRowInfo.sumHoursPlanned,
          this.props.payrollSettings.timeFormatSetting.type
        )}`}
      </td>,
      <td key="plannedDiff">
        {displayPlannedDiff(
          relevantRowInfo.sumHoursReal,
          relevantRowInfo.sumHoursPlanned,
          this.props.payrollSettings.timeFormatSetting.type
        )}
      </td>,
      <td key="sumNightHours">
        {parseMinutesToFormat(
          relevantRowInfo.sumNightHours,
          this.props.payrollSettings.timeFormatSetting.type
        )}
      </td>,

      <td key="overtime50" className="k-payroll__popoverCell">
        {this.props.relevantRowInfo.sumOvertime50 > 0 ? (
          <PopoverCorner
            key="overtime50"
            popover={overtime50PopOver}
            materialIcon="help"
          >
            {formattedSumOvertime50}
          </PopoverCorner>
        ) : (
          formattedSumOvertime50
        )}
      </td>,
      <td key="overtime100" className="k-payroll__popoverCell">
        {this.props.relevantRowInfo.sumOvertime100 > 0 ? (
          <PopoverCorner
            key="overtime100"
            popover={overtime100PopOver}
            materialIcon="help"
          >
            {formattedSumOvertime100}
          </PopoverCorner>
        ) : (
          formattedSumOvertime100
        )}
      </td>,
      <td key="overtimeSum">
        {scheduleCycleOvertime ? (
          <div>
            <div className="scheduleCycleOvertimeCell__date">
              {scheduleCycleOvertime.scheduleCycleSpan}
            </div>
            <div>{scheduleCycleOvertime.value}</div>
          </div>
        ) : (
          '---'
        )}
      </td>,
      <td key="sumBonuses">{relevantRowInfo.sumBonuses}</td>,
      <td key="sumPayout">{relevantRowInfo.sumPayout}</td>,
    ];

    return (
      <tr>
        <td
          colSpan={this.props.visibleLocationColumns.length}
          style={{ padding: 0 }}
        >
          <table className="table">
            {colgroup}
            <tbody>
              <tr>
                {items.map((item, i) => {
                  if (this.props.visibleLocationColumns.includes(i))
                    return item;
                  return null;
                })}
              </tr>
              <tr className={detailsClassNames}>
                <td
                  colSpan={this.props.visibleLocationColumns.length}
                  style={{ padding: 0 }}
                >
                  <PayrollLocationTableRowDetails
                    visibleLocationColumns={this.props.visibleLocationColumns}
                    userLocations={this.props.userLocations}
                    details={this.props.relevantRowInfo.details}
                    colgroup={colgroup}
                    payrollSettings={this.props.payrollSettings}
                    employeeId={employee.id}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

PayrollLocationTableRow.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollLocationTableRow.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    overtime: PropTypes.shape({
      blocks: PropTypes.arrayOf({
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    overtimeStats: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      overtime: PropTypes.shape({
        scheduleCycleOvertime: PropTypes.number,
      }),
    }),
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  relevantRowInfo: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({})),
    sumPayout: PropTypes.number,
    sumBonuses: PropTypes.number,
    date: PropTypes.string,
    sumOvertime100: PropTypes.number,
    sumOvertime50: PropTypes.number,
    sumNightHours: PropTypes.number,
    sumHoursPlanned: PropTypes.number,
    sumHoursReal: PropTypes.number,
    sumHours: PropTypes.number,
    sumAbsences: PropTypes.number,
    numberOfWarnings: PropTypes.number,
  }),
  payrollSettings: PropTypes.shape({
    timeFormatSetting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  payrollUI: PropTypes.shape({
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  lastNameFirst: PropTypes.bool,
  toggleSelected: PropTypes.func,
  visibleLocationColumns: PropTypes.arrayOf(PropTypes.number),
};
PayrollLocationTableRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default PayrollLocationTableRow;
