import PropTypes from 'prop-types';
import { useMemo } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import EmptyState from '@/components/emptyState/EmptyState';

import EmployeeTimesheetButtonBar from '../EmployeeTimesheetButtonBar/EmployeeTimesheetButtonBar';
import EmployeeTimesheetOptions from '../EmployeeTimesheetOptions/EmployeeTimesheetOptions.redux';
import { columnsData } from './columns';
import { getTableData } from './EmployeeTimesheetTable.helpers';
import { messages } from './EmployeeTimesheetTable.messages';

import './EmployeeTimesheetTable.scss';

const EmployeeTimesheetTable = (props, context) => {
  const columns = useMemo(
    () =>
      columnsData.map(col => ({
        Header: context.intl.formatMessage(col.Header, {}),
        accessor: col.accessor,
      })),
    [],
  );

  const employee = props.userEmployees.find(({ id }) => props.userId === id);
  const absences = props.scheduleAbsences[employee.id] || [];

  return (
    <div className="employeeTimesheetTable">
      <MDTable
        data={getTableData(props.mainDateStore, employee, absences, props.userLocations, props.absencesTypes)}
        columns={columns}
        tableOptions={<EmployeeTimesheetButtonBar showModal={props.showModal} />}
        options={<EmployeeTimesheetOptions />}
        withSearch={false}
        disableSortBy
        tableEmpty={
          <div className="employeeTimesheetTable__emptyTable">
            <EmptyState
              name="jobtitleCTA"
              imgSrc="/img/jobtitlesCTA.png"
              emptyStateHeader={context.intl.formatMessage(messages.emptyTable)}
              shiftX="0"
            />
          </div>
        }
      />
    </div>
  );
};

EmployeeTimesheetTable.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeTimesheetTable.propTypes = {
  userId: PropTypes.string,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  scheduleAbsences: PropTypes.shape({}),
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeTimesheetTable;
