import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MDCheckbox,
  MDMultiSelect,
  MDMultiSelectFooter,
  MDMultiSelectHeader,
  MDTextInput,
} from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { LOGIN_METHOD } from '@/constants/devices';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { formatLoginMethods, hasLoginMethod } from '@/utils/devices';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers';

import { messages } from './DevicesModal.messages';
import DeviceLoginMethods from './DeviceLoginMethods';

class SettingsDeviceModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    bindPrototypeFunctions(this);
  }

  getInitialState() {
    return {
      selectedLocations: [],
      takePhotos: false,
      managerGroupLogin: false,
      errors: {},
      loginMethodsError: null,
      autologin: false,
      autologinTime: 3,
      loginMethodPin: false,
      loginMethodQr: false,
      loginMethodNfc: false,
      loginMethodRfid: false,
      os: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalObject) {
      const { settings, os } = nextProps.modalObject;
      this.setState({
        selectedLocations: settings?.locations ?? [],
        takePhotos: settings?.take_photos ?? false,
        managerGroupLogin: settings?.manager_group_login ?? false,
        autologin: settings?.autologin ?? false,
        autologinTime: settings?.autologin_time ?? 3,
        loginMethodPin: hasLoginMethod(
          LOGIN_METHOD.PIN,
          settings?.login_methods
        ),
        loginMethodQr: hasLoginMethod(LOGIN_METHOD.QR, settings?.login_methods),
        loginMethodNfc: hasLoginMethod(
          LOGIN_METHOD.NFC,
          settings?.login_methods
        ),
        loginMethodRfid: hasLoginMethod(
          LOGIN_METHOD.RFID,
          settings?.login_methods
        ),
        deviceName: settings.name,
        os,
      });
    }
  }

  onSubmit() {
    this.validateAll().then((valid) => {
      if (!valid || this.state.loginMethodsError) return;
      const {
        selectedLocations,
        takePhotos,
        managerGroupLogin,
        deviceName,
        autologin,
        os,
        autologinTime,
      } = this.state;
      const { loginMethodPin, loginMethodQr, loginMethodRfid, loginMethodNfc } =
        this.state;
      this.props.saveSettings(
        {
          locations: selectedLocations,
          take_photos: takePhotos,
          manager_group_login: managerGroupLogin,
          name: deviceName,
          autologin,
          autologin_time: parseInt(autologinTime),
          login_methods: formatLoginMethods(
            { loginMethodPin, loginMethodQr, loginMethodRfid, loginMethodNfc },
            os
          ),
        },
        this.props.modalObject.uuid
      );
      this.hideAndClear();
    });
  }

  hideAndClear() {
    this.setState(this.getInitialState());
    this.props.hideModal();
  }

  handleFieldChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (['deviceName', 'authCode'].includes[name]) {
      this.validateInput(event);
    }
    this.setState({
      [name]: value,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  validateInput(event) {
    validateInputHelper(event).then((res) => {
      const [name, error] = res;
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: error ? this.context.intl.formatMessage(error, {}) : error,
        },
      });
    });
  }

  checkIfAtLeastOneLocationIsSelected() {
    if (!this.state.selectedLocations.length) {
      this.setState({
        errors: {
          ...this.state.errors,
          selectedLocations: this.context.intl.formatMessage(
            messages.noLocationsSelectedError,
            {}
          ),
        },
      });
      return false;
    }
    if (this.state.errors.selectedLocations) {
      this.setState({
        errors: { ...this.state.errors, selectedLocations: '' },
      });
    }
    return true;
  }

  async validateAll() {
    const inputs = ['deviceName'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(
        this.validateInput({ target: { name: i, value: this.state[i] } })
      );
    }

    await Promise.all(validationRequests);
    const atLeastOneLocationIsSelected =
      this.checkIfAtLeastOneLocationIsSelected();
    return (
      !Object.values(this.state.errors).some((err) => err !== '') &&
      atLeastOneLocationIsSelected
    );
  }

  handleChangeList(e) {
    const selectedLocationId = e.value;
    const newLocations = this.isLocationSelected(selectedLocationId)
      ? this.state.selectedLocations.filter((id) => id !== selectedLocationId)
      : [...this.state.selectedLocations, selectedLocationId];
    this.setState({ selectedLocations: newLocations }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  isLocationSelected(locationId) {
    return this.state.selectedLocations.includes(locationId);
  }

  selectAll() {
    const newLocations = this.props.userLocations.map(({ id }) => id);
    this.setState({ selectedLocations: newLocations }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  deselectAll() {
    this.setState({ selectedLocations: [] }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  getSelectedLocations() {
    return this.props.userLocations.reduce(
      (list, location) =>
        this.isLocationSelected(location.id)
          ? [
              ...list,
              {
                value: location.id,
                label: location.name,
              },
            ]
          : list,
      []
    );
  }

  render() {
    const {
      managerGroupLogin,
      takePhotos,
      deviceName,
      autologin,
      errors,
      loginMethodsError,
      os,
      autologinTime,
    } = this.state;
    const { loginMethodNfc, loginMethodRfid, loginMethodPin, loginMethodQr } =
      this.state;
    const userLocationsOptions = this.props.userLocations.map((location) => ({
      value: location.id,
      label: location.name,
      active: this.isLocationSelected(location.id),
    }));

    return (
      <MDKadroModal
        errorMessage={
          loginMethodsError
            ? this.context.intl.formatMessage(messages.loginMethodsError)
            : false
        }
        show={this.props.showModal}
        title={this.context.intl.formatMessage(
          messages.changeSettingsDevice,
          {}
        )}
        onSubmit={this.onSubmit}
        onHide={this.hideAndClear}
        modifiers={['narrow']}
      >
        <MDTextInput
          value={deviceName}
          onChange={this.handleFieldChange}
          type="text"
          id="deviceName"
          label={
            <FormattedMessage
              id="companymanage.devices.name"
              defaultMessage="Nazwa urządzenia"
            />
          }
          errorMessage={errors.deviceName}
          modifiers={['modal']}
        />
        <MDMultiSelectHeader
          icon="location_on"
          title={
            <FormattedMessage
              id="companymanage.devices.locations"
              defaultMessage="Lokalizacje"
            />
          }
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
        />
        <MDMultiSelect
          label={
            <FormattedMessage
              id="companymanage.devices.chooseLocation"
              defaultMessage="Wybierz lokalizacje"
            />
          }
          options={userLocationsOptions}
          onChange={this.handleChangeList}
        />
        <MDMultiSelectFooter
          selectedItems={this.getSelectedLocations()}
          deleteItem={this.handleChangeList}
          errorMessage={errors.selectedLocations}
        />
        <DeviceLoginMethods
          {...{
            os,
            loginMethodNfc,
            loginMethodRfid,
            loginMethodPin,
            loginMethodQr,
          }}
          onChange={this.handleInputChange}
        />
        <div className="addDeviceModal__divider" />
        <div className="addDeviceModal__subtitle">
          <FormattedMessage
            id="companymanage.devices.additionalOptionsTitle"
            defaultMessage="Dodatkowe opcje"
          />
        </div>
        <MDCheckbox
          id="takePhotos"
          text={
            <FormattedMessage
              id="companymanage.devices.takePhotos"
              defaultMessage="Zdjęcia"
            />
          }
          value={takePhotos}
          onChange={this.handleFieldChange}
          reverse
          tooltip={this.context.intl.formatMessage(messages.takePhotosTooltip)}
        />
        <MDCheckbox
          id="managerGroupLogin"
          text={
            <FormattedMessage
              id="companymanage.devices.managerGroupLogin"
              defaultMessage="Grupowe logowanie"
            />
          }
          value={managerGroupLogin}
          onChange={this.handleFieldChange}
          reverse
          tooltip={this.context.intl.formatMessage(
            messages.managerGroupLoginTooltip
          )}
        />
        <MDCheckbox
          id="autologin"
          text={this.context.intl.formatMessage(messages.autologin)}
          value={autologin}
          onChange={this.handleFieldChange}
          tooltip={this.context.intl.formatMessage(messages.autologinTooltip)}
          reverse
        />
        {autologin && (
          <MDTextInput
            label={this.context.intl.formatMessage(messages.autologinTimeLabel)}
            value={autologinTime}
            onChange={this.handleFieldChange}
            type="number"
            disabled={!autologin}
            minValue={0}
            maxValue={5}
            id="autologinTime"
            modifiers="modal"
          />
        )}
      </MDKadroModal>
    );
  }
}

SettingsDeviceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SettingsDeviceModal.propTypes = {
  showModal: PropTypes.bool,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  modalObject: PropTypes.shape({
    uuid: PropTypes.string,
    settings: PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  hideModal: PropTypes.func,
  saveSettings: PropTypes.func,
};

export default SettingsDeviceModal;
