import classnames from 'classnames';
import PropTypes from 'prop-types';

const PhotoPreviewPayrollIcon = ({ availablePhotos }) => {
  if (!availablePhotos) {
    return null;
  }
  const { clockIn, clockOut } = availablePhotos;

  const inIconClassnames = classnames('k-photoPreview__icon k-photoPreview__icon--in material-icons photo_camera', {
    'k-photoPreview__icon--active': clockIn,
  });
  const outIconClassnames = classnames('k-photoPreview__icon k-photoPreview__icon--out material-icons photo_camera', {
    'k-photoPreview__icon--active': clockOut,
  });
  const containerClassnames = classnames('k-photoPreview k-photoPreview--active');

  return (
    <div className={containerClassnames}>
      <i className={inIconClassnames}>photo_camera</i>
      <i className={outIconClassnames}>photo_camera</i>
    </div>
  );
};

PhotoPreviewPayrollIcon.propTypes = {
  availablePhotos: PropTypes.shape({
    clockIn: PropTypes.bool,
    clockOut: PropTypes.bool,
  }),
};

export default PhotoPreviewPayrollIcon;
