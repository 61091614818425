import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { SCHEDULE_TABLE_EMPTY_STATE_STYLES } from '@/constants/scheduleDisplayModes.js';

const messages = defineMessages({
  emptyStateFilterTitle: {
    id: 'scheduleView.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateSearchStringMsg: {
    id: 'scheduleView.emptyStateSearchStringMsg',
    defaultMessage: 'Dostosuj filtry, sprawdź czy pracownicy są dodani do lokalizacji lub ',
  },
  ctaSearchStringText: {
    id: 'scheduleView.ctaSearchStringText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
  emptyFiltersMsg: {
    id: 'scheduleView.emptyFiltersMsg',
    defaultMessage: 'Sprawdź czy zaznaczone są lokalizacje, stanowiska i warunki zatrudnienia lub ',
  },
  ctaEmptyFiltersText: {
    id: 'scheduleView.ctaemptyFiltersText',
    defaultMessage: 'kliknij tutaj, aby zaznaczyć wszystkie.',
  },
});

const EmptyFilterView = (
  {
    searchString,
    areFiltersNotSelected,
    changeScheduleSearchString,
    selectAllJobTitlesGroupedAndEmploymentConditions,
    selectFirstLocation,
    selectAllDisplaySettings,
  },
  context,
) => {
  const emptyStateText = searchString
    ? context.intl.formatMessage(messages.emptyStateSearchStringMsg)
    : context.intl.formatMessage(messages.emptyFiltersMsg);
  const ctaText = searchString
    ? context.intl.formatMessage(messages.ctaSearchStringText)
    : context.intl.formatMessage(messages.ctaEmptyFiltersText);
  const ctaHandler = () => {
    if (searchString) {
      changeScheduleSearchString('');
    } else {
      selectAllJobTitlesGroupedAndEmploymentConditions();
      selectAllDisplaySettings();
      areFiltersNotSelected && selectFirstLocation();
    }
  };

  return (
    <EmptyState
      name="scheduleCTA"
      imgSrc="/img/scheduleCTA.png"
      emptyStateHeader={context.intl.formatMessage(messages.emptyStateFilterTitle, {})}
      emptyStateText={emptyStateText}
      ctaHandler={ctaHandler}
      filerStyle={SCHEDULE_TABLE_EMPTY_STATE_STYLES.filerStyle}
      ctaText={ctaText}
    />
  );
};

EmptyFilterView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmptyFilterView.propTypes = {
  searchString: PropTypes.string,
  areFiltersNotSelected: PropTypes.bool,
  changeScheduleSearchString: PropTypes.func,
  selectAllJobTitlesGroupedAndEmploymentConditions: PropTypes.func,
  selectFirstLocation: PropTypes.func,
  selectAllDisplaySettings: PropTypes.func,
};

export default EmptyFilterView;
