import { combineReducers } from 'redux';

import { AUTH_USER_LOGOUT } from '@/constants/ActionTypes';
import loanEmployeesProposalsAssignments from '@/reducers/loanEmployeesProposalsAssignments';
import payrollEmployee from '@/reducers/payrollEmployee';

import absenceFilter from '../reducers/absenceFilter.ts';
import absences from '../reducers/absences.js';
import attendances from '../reducers/attendances/index.js';
import autoscheduler from '../reducers/autoscheduler.js';
import budgetEstimates from '../reducers/budgetEstimates.js';
import budgetMetrics from '../reducers/budgetMetrics.js';
import budgetMetricsData from '../reducers/budgetMetricsData.js';
import calendarData from '../reducers/calendarData.js';
import chat from '../reducers/chat.js';
import companyHistoryStore from '../reducers/companyHistoryStore.js';
import companyLimitsUsage from '../reducers/companyLimitsUsage.js';
import contracts from '../reducers/contracts.ts';
import copyPaste from '../reducers/copyPaste.js';
import currentCompany from '../reducers/currentCompany.js';
import currentUser from '../reducers/currentUser.js';
import customFields from '../reducers/customFields/index.ts';
import dashboard from '../reducers/dashboard.js';
import deleteOptions from '../reducers/deleteOptions.js';
import demo from '../reducers/demo.js';
import deviceInfo from '../reducers/deviceInfo.js';
import devices from '../reducers/devices.js';
import employees from '../reducers/employees.js';
import employeesNames from '../reducers/employeesNames.ts';
import employeeSorting from '../reducers/employeeSorting.js';
import employmentConditions from '../reducers/employmentConditions.js';
import employmentConditionsFilter from '../reducers/employmentConditionsFilter.js';
import exports from '../reducers/exports.js';
import exportsHistory from '../reducers/exports/exportsHistory/exportsHistory.js';
import ghostLogin from '../reducers/ghostLogin.ts';
import googleOAuth from '../reducers/googleOAuth.ts';
import jobtitleFilter from '../reducers/jobtitleFilter.js';
import jobTitles from '../reducers/jobTitles.js';
import jobTitlesGrouped from '../reducers/jobTitlesGrouped.js';
import labels from '../reducers/labels.ts';
import listsUi from '../reducers/listsUi.js';
import loanEmployeesProposals from '../reducers/loanEmployeesProposals.ts';
import locationFilter from '../reducers/locationFilter.js';
import mainDateStore from '../reducers/mainDateStore.js';
import multipleLocationFilter from '../reducers/multipleLocationFilter.js';
import notificationBar from '../reducers/notificationBar.js';
import onboarding from '../reducers/onboarding/index.js';
import openShifts from '../reducers/openShifts.js';
import overtimeCollections from '../reducers/overtimeCollections.js';
import overtimeStats from '../reducers/overtimeStats.js';
import payments from '../reducers/payments.js';
import payroll from '../reducers/payroll/payroll.js';
import payrollLoanedEmployees from '../reducers/payrollLoanedEmployees.js';
import payrollLocation from '../reducers/payrollLocation/payrollLocation.js';
import payrollSettings from '../reducers/payrollSettings.js';
import { payrollUI } from '../reducers/payrollUI.js';
import promoContents from '../reducers/promoContents.js';
import rcp from '../reducers/rcp.js';
import rcpPhotos from '../reducers/rcpPhotos.js';
import recommendedSchedule from '../reducers/recommendedSchedule.js';
import reports from '../reducers/reports/index.js';
import roles from '../reducers/roles.js';
import schedule from '../reducers/schedule/index.js';
import scheduleLocationFilter from '../reducers/schedule/scheduleLocationFilter.js';
import scheduleLoanedEmployees from '../reducers/scheduleLoanedEmployees.ts';
import scheduleState from '../reducers/scheduleState.js';
import scheduleUIState from '../reducers/scheduleUIState.js';
import settings from '../reducers/settings.js';
import shifts from '../reducers/shifts.js';
import singleEmployeeFilter from '../reducers/singleEmployeeFilter.js';
import tradeShifts from '../reducers/tradeShifts.js';
import uiState from '../reducers/uiState.js';
import userCustomTypes from '../reducers/userCustomTypes.js';
import userEmployees from '../reducers/userEmployees.js';
import userEvents from '../reducers/userEvents.js';
import userJobTitles from '../reducers/userJobTitles.js';
import userLocations from '../reducers/userLocations.ts';
import userNotifications from '../reducers/userNotifications.js';
import userPermissions from '../reducers/userPermissions.js';
import userProductionQuotas from '../reducers/userProductionQuotas.js';
import userShiftblocks from '../reducers/userShiftblocks.ts';
import userTemplates from '../reducers/userTemplates.js';
import weatherForecast from '../reducers/weatherForecast.js';
import workingRules from '../reducers/workingRules.js';
import { employeeGroupingReducer } from './employeeGrouping/employeeGrouping.slice.ts';
import { freeDaysMarkingReducer } from './freeDaysMarking/freeDaysMarking.slice.ts';
import { locationGroupsReducer } from './locationGroups/index.ts';
import { managerNotesReducer } from './managerNotes/index.ts';
import { openShiftsAssignmentsReducer } from './openShiftsAssignments/openShiftsAssignemnts.slice.ts';

const kadroApp = combineReducers({
  autoscheduler,
  schedule,
  jobTitles,
  absences,
  absenceFilter,
  attendances,
  budgetEstimates,
  budgetMetrics,
  budgetMetricsData,
  calendarData,
  chat,
  companyHistoryStore,
  companyLimitsUsage,
  contracts,
  copyPaste,
  currentCompany,
  currentUser,
  customFields,
  dashboard,
  deleteOptions,
  demo,
  deviceInfo,
  devices,
  employeeSorting,
  employees,
  employeesNames,
  employmentConditions,
  employmentConditionsFilter,
  employeeGrouping: employeeGroupingReducer,
  exports,
  exportsHistory,
  freeDaysMarking: freeDaysMarkingReducer,
  ghostLogin,
  jobtitleFilter,
  jobTitlesGrouped,
  listsUi,
  loanEmployeesProposals,
  locationFilter,
  locationGroups: locationGroupsReducer,
  mainDateStore,
  managerNotes: managerNotesReducer,
  multipleLocationFilter,
  notificationBar,
  onboarding,
  openShifts,
  openShiftsAssignments: openShiftsAssignmentsReducer,
  overtimeCollections,
  overtimeStats,
  payments,
  payrollSettings,
  payrollUI,
  promoContents,
  rcp,
  rcpPhotos,
  recommendedSchedule,
  reports,
  roles,
  scheduleLocationFilter,
  scheduleLoanedEmployees,
  scheduleState,
  scheduleUIState,
  settings,
  shifts,
  singleEmployeeFilter,
  tradeShifts,
  uiState,
  userCustomTypes,
  userEmployees,
  userEvents,
  userJobTitles,
  userLocations,
  userNotifications,
  userPermissions,
  userProductionQuotas,
  userShiftblocks,
  userTemplates,
  workingRules,
  weatherForecast,
  payroll,
  payrollLocation,
  payrollEmployee,
  labels,
  googleOAuth,
  payrollLoanedEmployees,
  loanEmployeesProposalsAssignments,
});

export const rootReducer = (state, action) => {
  const newState = action.type === AUTH_USER_LOGOUT ? undefined : state;
  return kadroApp(newState, action);
};
