import { sumArrayOfNumbers } from '@/utils/arrayHelpers.js';

const defaultData = {
  estimated_budget: 0,
  actual_budget: 0,
  labour_limit: 0,
  target_spmh: 0,
};

const initialEstimators = {
  estimated_budget: null,
  actual_budget: null,
  labour_limit: null,
  target_spmh: null,
};

const countEstimatorForDate = (estimates, date, key) =>
  estimates.reduce(
    (prev, e) => {
      if (!(e.date === date && typeof e[key][0] === 'number')) return prev;
      return {
        sum: prev.sum + sumArrayOfNumbers(e[key]),
        counter: prev.counter + 1,
      };
    },
    { sum: 0, counter: 0 },
  );

export const aggregateEstimatesByDate = estimates => {
  const result = estimates.reduce(
    (uniqueDates, { date }) =>
      Object.keys(uniqueDates).includes(date)
        ? uniqueDates
        : { ...uniqueDates, [date]: { ...JSON.parse(JSON.stringify(initialEstimators)), date } },
    {},
  );
  Object.keys(result).forEach(date => {
    const estimatedBudgetForDate = countEstimatorForDate(estimates, date, 'estimated_budget');
    const actualBudgetForDate = countEstimatorForDate(estimates, date, 'actual_budget');
    const labourLimitForDate = countEstimatorForDate(estimates, date, 'labour_limit');
    const targetSpmhForDate = countEstimatorForDate(estimates, date, 'target_spmh');
    if (estimatedBudgetForDate.counter) result[date].estimated_budget = estimatedBudgetForDate.sum;
    if (actualBudgetForDate.counter) result[date].actual_budget = actualBudgetForDate.sum;
    if (labourLimitForDate.counter) result[date].labour_limit = labourLimitForDate.sum / labourLimitForDate.counter;
    if (targetSpmhForDate.counter) result[date].target_spmh = targetSpmhForDate.sum / targetSpmhForDate.counter;
  });
  return result;
};

export const aggregateEstimates = estimates => {
  const aggregatedPayload = estimates.reduce(
    (result, estimate) => ({
      ...result,
      estimated_budget: result.estimated_budget + sumArrayOfNumbers(estimate.estimated_budget),
      actual_budget: result.actual_budget + sumArrayOfNumbers(estimate.actual_budget),
      labour_limit: result.labour_limit + sumArrayOfNumbers(estimate.labour_limit),
      target_spmh: result.target_spmh + sumArrayOfNumbers(estimate.target_spmh),
    }),
    defaultData,
  );

  const numberOfLabourLimitItems =
    estimates.filter(({ labour_limit: labourLimit }) => typeof labourLimit[0] === 'number').length || 1;
  const numberOfTargetSpmhItems = estimates.filter(({ target_spmh: targetSpmh }) =>
    typeof targetSpmh[0] === 'number').length || 1;

  return {
    ...aggregatedPayload,
    labour_limit: aggregatedPayload.labour_limit / numberOfLabourLimitItems,
    target_spmh: aggregatedPayload.target_spmh / numberOfTargetSpmhItems,
  };
};
