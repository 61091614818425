export const EXPORTS_TYPES = {
  payrollLocationPdf: 'f-payroll-location-pdf',
  payrollLocationXlsSum: 'f-xls-brief-export',
  payrollXls: 'f-payroll-xls',
  payrollPdf: 'f-payroll-pdf',
  payrollLocationXlsOld: 'f-payroll-location-xls-v1',
  payrollLocationXls: 'f-payroll-location-xls',
  ecpExport: 'f-ecp-export',
  positionsLocations: 'f-positions-locations',
  jobTitleReport: 'f-job-title-report',
  extraPositions: 'f-extra-positions',
  enovaXml: 'f-enova-xml',
  adeccoPayroll: 'f-adecco-payroll-export',
  scheduleExecutionExport: 'f-schedule-execution-export',
  spmhExport: 'f-spmh-export',
  spmhExportByDay: 'f-spmh-export-by-day',
  adpExport: 'f-adp-export',
  xlsComarch: 'f-xls-comarch',
  xlsSage: 'f-xls-sage',
  csvR2platnik: 'f-csv-r2platnik',
  xlsGsn: 'f-xls-gsn',
  worktimeBreakdown: 'f-worktime-breakdown-xls',
  budgetPerLocation: 'f-budget-per-location-export',
  r2platnikAbsenceExport: 'f-r2platnik-absence-export',
  overtime: 'f-overtime-export',
  r2platnikShiftsExport: 'f-r2platnik-shifts-export',
  absence: 'f-absence-export',
  timesheetControllingExport: 'f-timesheet-controlling-export',
  symfoniaXml: 'f-absence-and-overtime-symfonia-export',
};

export const EXPORT_IMAGES = {
  F_PAYROLL_LOCATION_XLS: '/img/exports/f-payroll-location-xls.jpg',
  F_XLS_BRIEF_EXPORT: '/img/exports/f-xls-brief-export.jpg',
  F_PAYROLL_PDF: '/img/exports/f-payroll-pdf.jpg',
  F_PAYROLL_LOCATION_PDF: '/img/exports/f-payroll-location-pdf.jpeg',
  F_XLS_COMARCH: '/img/exports/f-xls-comarch.jpg',
  F_CSV_R2PLATNIK: '/img/exports/f-csv-r2platnik.jpg',
  F_ENOVA_XML: '/img/exports/f-enova-xml.jpg',
  F_SCHEDULE_EXECUTION_EXPORT: '/img/exports/f-schedule-execution-export.jpg',
  F_SPMH_EXPORT: '/img/exports/f-spmh-export.jpg',
  F_SPMH_EXPORT_BY_DAY: '/img/exports/f-spmh-export-by-day.jpg',
  F_WORKTIME_BREAKDOWN_XLS: '/img/exports/f-worktime-breakdown-xls.jpg',
  F_ECP_EXPORT: '/img/exports/f-ecp-export.jpg',
  F_ADP_EXPORT: '/img/exports/f-adp-export.jpg',
  F_BUDGET_PER_LOCATION_EXPORT: '/img/exports/f-budget-per-location-export.jpg',
  F_OVERTIME_EXPORT: '/img/exports/f-overtime-export.jpg',
  F_R2PLATNIK_ABSENCE_EXPORT: '/img/exports/f-r2platnik-absence-export.jpeg',
  F_R2PLATNIK_SHIFTS_EXPORT: '/img/exports/f-r2platnik-shifts-export.jpg',
  F_ABSENCE_EXPORT: '/img/exports/f-absence-export.jpg',
  F_TIMESHEET_CONTROLLING_EXPORT: '/img/exports/f-timesheet-controlling-export.jpg',
  F_XLS_SAGE: '/img/exports/f-sage-xls-export.jpg',
  F_ABSENCE_AND_OVERTIME_SYMFONIA_EXPORT: '/img/exports/f-absence-and-overtime-xml-export.jpg',
  F_XLS_GSN: '/img/exports/f-xls-gsn.jpg',
};
