import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { checkShiftOverlap } from '@/utils/schedulerHelpers.js';

const modalMessages = defineMessages({
  title: {
    id: 'tradeShift.coworkerModal.title',
    defaultMessage: 'Potwierdź chęć wymiany',
  },
  confirmText: {
    id: 'tradeShift.coworkerModal.confirmText',
    defaultMessage: 'Wymień się',
  },
  rejectText: {
    id: 'tradeShift.coworkerModal.rejectText',
    defaultMessage: 'Odrzuć',
  },
});

class CoworkerTradeShiftModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showError: false,
    };
    bindPrototypeFunctions(this);
  }

  onHide() {
    this.setState({
      showError: false,
    });
    this.props.onHide();
  }

  onReject() {
    const currentUser = this.props.modalObject.tradeShiftData.indicated_coworkers.find(
      coworker => coworker.user.id === this.props.currentUser.user.id,
    );
    if (!currentUser) throw new Error('Coworker not found');
    this.props.changeStatusTradeShift(this.props.modalObject.tradeShiftData.id, currentUser.id, 'rejected');
    this.onHide();
  }

  onSubmit() {
    if (
      checkShiftOverlap(
        this.props.userEmployees.find(user => user.id === this.props.currentUser.user.id),
        this.props.modalObject.tradeShiftData,
      )
    ) {
      this.setState({
        showError: true,
      });
    } else {
      const currentUser = this.props.modalObject.tradeShiftData.indicated_coworkers.find(
        coworker => coworker.user.id === this.props.currentUser.user.id,
      );
      if (!currentUser) throw new Error('Coworker not found');
      this.props.changeStatusTradeShift(this.props.modalObject.tradeShiftData.id, currentUser.id, 'approved');
      this.onHide();
    }
  }

  getFooterOptions() {
    return [
      {
        icon: 'remove_circle_outline',
        text: this.context.intl.formatMessage(modalMessages.rejectText, {}),
        handleClick: this.onReject,
      },
    ];
  }

  render() {
    const errorClassNames = classnames('k-trade-shifts__error', {
      'k-trade-shifts__error--show': this.state.showError,
    });
    const tradeShift = this.props.modalObject.tradeShiftData;
    const jobTitle = tradeShift.job_title && tradeShift.job_title.name ? tradeShift.job_title.name : '';
    const title = this.context.intl.formatMessage(modalMessages.title, {});
    const confirmText = this.context.intl.formatMessage(modalMessages.confirmText, {});

    return (
      <MDKadroModal
        show={this.props.showModal}
        title={title}
        confirmText={confirmText}
        onSubmit={this.onSubmit}
        onHide={this.onHide}
        modifiers={['narrow']}
        footerOptions={this.getFooterOptions()}
      >
        <div className="k-trade-shifts__coworker">
          <MDInfoWithLabel
            label={<FormattedMessage id="tradeShift.coworkerModal.employee" defaultMessage="Pracownik" />}
            info={`${tradeShift.user.first_name} ${tradeShift.user.last_name}`}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="tradeShift.coworkerModal.date" defaultMessage="Data" />}
            info={
              <>
                <span className="mdInfoWithLabel--extraMarginRight">{tradeShift.date}</span>
                <span>{tradeShift.working_hours}</span>
              </>
            }
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="tradeShift.coworkerModal.location" defaultMessage="Lokalizacja" />}
            info={tradeShift.location.name}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="tradeShift.coworkerModal.jobTitle" defaultMessage="Stanowisko" />}
            info={jobTitle}
          />
          <MDInfoWithLabel
            label={<FormattedMessage id="tradeShift.coworkerModal.note" defaultMessage="Notatka" />}
            info={tradeShift.comment}
            infoModifiers={['note']}
          />
          <div className={errorClassNames}>
            <FormattedMessage
              id="tradeShift.coworkerModal.overlapError"
              defaultMessage="Nie możesz się zapisać, ponieważ masz w tym czasie inną zmianę."
            />
          </div>
        </div>
      </MDKadroModal>
    );
  }
}

CoworkerTradeShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CoworkerTradeShiftModal.propTypes = {
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    tradeShiftData: PropTypes.shape({
      id: PropTypes.string,
      indicated_coworkers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  onHide: PropTypes.func,
  changeStatusTradeShift: PropTypes.func,
};

export default CoworkerTradeShiftModal;
