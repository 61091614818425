import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { MDPhoneInput, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import AvatarEditor from '@/containers/common/AvatarEditorContainer.js';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import SettingsSection from '../../SettingsSection/SettingsSection';
import { createNewUserSettingsObject, getInitialState, inputsModifiers } from './UserDataSettings.helpers';
import { messages } from './UserDataSettings.messages';

import './UserDataSettings.scss';

const UserDataSettings = (props, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState(props.user), intl, { preventClearErrors: true });
  const [loading, setLoading] = useState(false);

  const canSave = useMemo(() => {
    const { errors, ...stateWithoutErrors } = state;
    const { errors: _initialErrors, ...initialState } = getInitialState(props.user);
    return !loading && !isEqual(stateWithoutErrors, initialState) && !Object.values(errors).some(err => err !== '');
  }, [state, props.user]);

  const validateAll = async () => {
    const inputs = ['name', 'lastName', 'email', 'phone'];
    const validationResult = await Promise.all(
      inputs.map(inputName => {
        const event = createEvent(inputName, state[inputName]);
        if (inputName === 'phone') event.valueToCompare = state.phone_prefix;
        return handlers.validateInput(event);
      }),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const onSubmit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    try {
      setLoading(true);
      await props.changeCurrentUser(createNewUserSettingsObject(state));
    } catch (err) {
      if (err.response?.data?.message === 'The email is already used') {
        handlers.setError('email', messages.emailUsed);
      }
    } finally {
      setLoading(false);
    }
  }, [state]);

  return (
    <SettingsSection canSave={canSave} title={intl.formatMessage(messages.title)} onSubmit={onSubmit}>
      <form autoComplete="off" className="userDataSettings">
        <div className="userDataSettings__options">
          <MDTextInput
            id="name"
            name="name"
            value={state.name}
            label={intl.formatMessage(messages.name)}
            modifiers={inputsModifiers}
            onChange={handlers.changeInput}
            onBlur={handlers.validateInput}
            errorMessage={state.errors.name}
          />
          <MDTextInput
            id="lastName"
            name="lastName"
            value={state.lastName}
            label={intl.formatMessage(messages.lastName)}
            modifiers={inputsModifiers}
            onChange={handlers.changeInput}
            onBlur={handlers.validateInput}
            errorMessage={state.errors.lastName}
          />
          <MDTextInput
            id="email"
            name="email"
            value={state.email}
            label={intl.formatMessage(messages.email)}
            modifiers={inputsModifiers}
            onChange={handlers.changeInput}
            onBlur={handlers.validateInput}
            errorMessage={state.errors.email}
            disabled={state.accountUsesOAuth}
          />
          <MDPhoneInput
            phoneNumber={state.phone}
            prefix={state.phone_prefix}
            type="text"
            onChange={handlers.changeInput}
            onBlur={handlers.validateInput}
            id="phone"
            label={intl.formatMessage(messages.phone)}
            errorMessage={state.errors.phone}
            modifiers={inputsModifiers}
          />
        </div>
        <AvatarEditor />
      </form>
    </SettingsSection>
  );
};

UserDataSettings.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

UserDataSettings.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  changeCurrentUser: PropTypes.func,
};

export default UserDataSettings;
