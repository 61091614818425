import * as actionTypes from '@/constants/ActionTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { payrollLocationTableColumns, payrollTableColumns } from '@/constants/tableColumns.jsx';

import { openAddAttendanceModal } from './attendances.js';
import { getOpenShift } from './openShifts.js';
import { checkIfCanDelete } from './schedule.jsx';
import { changeDefaultViewDateStore } from './uiState/uiState.actions';

/**
 * Shows notification panel
 */
export const showNotificationPanel = () => ({
  type: actionTypes.SHOW_NOTIFICATION_PANEL,
});
/**
 * Hides notification panel
 */
export const closeNotificationPanel = () => ({
  type: actionTypes.CLOSE_NOTIFICATION_PANEL,
});

/**
 *
 * @param {integer} elementId
 */
export const setCurrentNavBarElementId = (elementId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_NAVBAR_ELEMENT_ID,
    payload: elementId,
  });
  dispatch(closeNotificationPanel());
};

/**
 * Decreases the loader counter. If the counter reaches 0 the reducer hides the loading screen
 */
export const increaseLoaderCounter = (type) => ({
  type: actionTypes.INCREASE_LOADER_COUNTER,
  payload: type,
});

/**
 * Decreases the loader counter. If the counter reaches 0 the reducer hides the loading screen
 */
export const decreaseLoaderCounter = (type) => ({
  type: actionTypes.DECREASE_LOADER_COUNTER,
  payload: type,
});

export const resetLoaderCounter = () => ({
  type: actionTypes.RESET_LOADER_COUNTER,
});

/**
 * Shows background shadow
 */
export const showShadow = () => ({
  type: actionTypes.SHOW_SHADOW,
});
/**
 * Hides shadow
 */
export const hideShadow = () => ({
  type: actionTypes.HIDE_SHADOW,
});

export const showModal = (name, data) => ({
  type: actionTypes.SHOW_MODAL,
  payload: {
    name,
    data,
  },
});

export const replaceModal = (name) => ({
  type: actionTypes.REPLACE_MODAL,
  payload: {
    name,
  },
});

export const hideModal = () => ({
  type: actionTypes.HIDE_MODAL,
});

export const hideModalAndLaterClearModalObject = () => (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_MODAL_WITHOUT_CLEARING_MODAL_OBJECT,
  });
  setTimeout(() => dispatch({ type: actionTypes.CLEAR_MODAL_OBJECT }), 450);
};

export const toggleModal = (payload) => ({
  type: actionTypes.TOGGLE_MODAL,
  payload,
});

export const changeSendPanel = (value) => ({
  type: actionTypes.CHANGE_SEND_PANEL_VISIBILITY,
  payload: value,
});

export const changeSendPanelVisibility = (value) => (dispatch, getState) => {
  const { restrictions } = getState().reducer.userPermissions;
  const isEditDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  if (isEditDisabled) return;

  if (value === true) {
    dispatch(showShadow());
  } else {
    dispatch(hideShadow());
  }
  dispatch(changeSendPanel(value));
};

export const openOpenShiftModal = (openShiftData, date, location) => (dispatch, getState) => {
  const currentLocation =
    typeof location !== 'object' ? getState().reducer.userLocations.find((l) => l.id === location) : location;

  if (openShiftData.id) {
    dispatch(getOpenShift(openShiftData.id)).then((result) => {
      dispatch({
        type: actionTypes.SHOW_OPEN_SHIFT_MODAL,
        payload: {
          showOpenShiftModal: true,
          modalObject: {
            openShiftData: result.openShift,
            date,
            location: currentLocation,
          },
        },
      });
    });
  } else {
    dispatch({
      type: actionTypes.SHOW_OPEN_SHIFT_MODAL,
      payload: {
        showOpenShiftModal: true,
        modalObject: {
          openShiftData,
          date,
          location: currentLocation,
        },
      },
    });
  }
};

export const closeOpenShiftModal = () => ({
  type: actionTypes.CLOSE_OPEN_SHIFT_MODAL,
});

export const openApplyForOpenShiftModal = (openShift) => (dispatch) => {
  dispatch(getOpenShift(openShift.id)).then((result) => {
    dispatch({
      type: actionTypes.SHOW_APPLY_FOR_OPEN_SHIFT_MODAL,
      payload: {
        showApplyForOpenShiftModal: true,
        modalObject: result,
      },
    });
  });
};

export const closeApplyForOpenShiftModal = () => ({
  type: actionTypes.CLOSE_APPLY_FOR_OPEN_SHIFT_MODAL,
});

export const openAssignUsersOpenShiftModal = (openShift) => (dispatch) => {
  dispatch(getOpenShift(openShift.id)).then((result) => {
    dispatch({
      type: actionTypes.SHOW_ASSIGN_USERS_OPEN_SHIFT_MODAL,
      payload: {
        showAssignUsersOpenShiftModal: true,
        modalObject: result,
      },
    });
  });
};

export const closeAssignUsersOpenShiftModal = () => ({
  type: actionTypes.CLOSE_ASSIGN_USERS_OPEN_SHIFT_MODAL,
});

export const changeAddShiftModalVisibility = (showAddShiftModal, modalObject) => ({
  type: actionTypes.CHANGE_ADD_SHIFT_MODAL_VISIBILITY,
  payload: { showAddShiftModal, modalObject },
});

export const openAddAvailabilityModal = (date, employeeId) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const employee = userEmployees.find((emp) => emp.id === employeeId);
  if (!employee) return;
  dispatch({
    type: actionTypes.SHOW_ADD_AVAILABILITY_MODAL,
    payload: {
      date,
      employee,
    },
  });
};

export const closeAddAvailabilityModal = (date, employee) => ({
  type: actionTypes.CLOSE_ADD_AVAILABILITY_MODAL,
  payload: {
    date,
    employee,
  },
});

export const openEditAvailabilityModal = (availability, employee) => ({
  type: actionTypes.SHOW_EDIT_AVAILABILITY_MODAL,
  payload: {
    availability,
    employee,
  },
});
export const openEditAvailabilityModalByIds = (availabilityId, employeeId) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const employee = userEmployees.find((employee) => employee.id === employeeId);
  if (!employee) return;
  const availability = employee.availability_blocks.find((block) => block.id === availabilityId);
  if (!availability) return;
  dispatch(openEditAvailabilityModal(availability, employee));
};

export const closeEditAvailabilityModal = (availability, employee) => ({
  type: actionTypes.CLOSE_EDIT_AVAILABILITY_MODAL,
  payload: {
    availability,
    employee,
  },
});
export const toggleExportModal = () => ({
  type: actionTypes.TOGGLE_EXPORT_MODAL,
});

export const toggleHelpModal = () => ({
  type: actionTypes.TOGGLE_HELP_MODAL,
});

export const offlineMessageOn = () => ({
  type: actionTypes.OFFLINE_MESSAGE_ON,
});
export const offlineMessageOff = () => ({
  type: actionTypes.OFFLINE_MESSAGE_OFF,
});

export const openRequestTradeShiftModal = (shift, tradeShiftData = {}) => ({
  type: actionTypes.SHOW_REQUEST_TRADE_SHIFT_MODAL,
  payload: {
    showRequestTradeShiftModal: true,
    modalObject: {
      shift,
      tradeShiftData,
    },
  },
});

export const closeRequestTradeShiftModal = () => ({
  type: actionTypes.CLOSE_REQUEST_TRADE_SHIFT_MODAL,
});

export const openCoworkerTradeShiftModal = (tradeShiftData) => ({
  type: actionTypes.SHOW_COWORKER_TRADE_SHIFT_MODAL,
  payload: {
    showCoworkerTradeShiftModal: true,
    modalObject: {
      tradeShiftData,
    },
  },
});

export const closeCoworkerTradeShiftModal = () => ({
  type: actionTypes.CLOSE_COWORKER_TRADE_SHIFT_MODAL,
});

export const openApprovalTradeShiftModal = (tradeShiftData) => ({
  type: actionTypes.SHOW_APPROVAL_TRADE_SHIFT_MODAL,
  payload: {
    showApprovalTradeShiftModal: true,
    modalObject: {
      tradeShiftData,
    },
  },
});

export const closeApprovalTradeShiftModal = () => ({
  type: actionTypes.CLOSE_APPROVAL_TRADE_SHIFT_MODAL,
});

export function getInitPayrollColumns() {
  const initialColumns = [
    'common.location',
    'common.fromTo',
    'common.fromToCompare',
    'common.wage',
    'common.sumHours',
    'common.totalPay',
  ];
  return payrollTableColumns.options.filter((col) => initialColumns.includes(col.title.id));
}

export function getInitPayrollLocationColumns() {
  const initialColumns = ['common.jobTitle', 'common.location', 'common.wage', 'common.sumHours', 'common.totalPay'];
  return payrollLocationTableColumns.options.filter((col) => initialColumns.includes(col.title.id));
}

export const getPayrollColumns = () => (dispatch, getState) => {
  const { userPermissions } = getState().reducer;
  const { restrictions, permissions } = payrollTableColumns;
  return payrollTableColumns.options.filter((c, i) => {
    if (restrictions[i].some((r) => userPermissions.restrictions.includes(r))) return false;
    if (permissions[i].some((r) => !userPermissions.permissions.includes(r))) return false;
    return true;
  });
};

export const getPayrollLocationColumns = () => (dispatch, getState) => {
  const { userPermissions } = getState().reducer;
  const { restrictions, permissions } = payrollLocationTableColumns;
  return payrollLocationTableColumns.options.filter((c, i) => {
    if (restrictions[i].some((r) => userPermissions.restrictions.includes(r))) return false;
    if (permissions[i].some((r) => !userPermissions.permissions.includes(r))) return false;
    return true;
  });
};

export const changePayrollColumns = (selected) => (dispatch) => {
  const columns = dispatch(getPayrollColumns());
  const selectedColumns = columns.filter((col) => selected.find((sel) => sel.id === col.id));

  dispatch({
    type: actionTypes.CHANGE_PAYROLL_COLUMNS,
    payload: selectedColumns,
  });
};

export const changePayrollLocationColumns = (selected) => (dispatch) => {
  const columns = dispatch(getPayrollLocationColumns());
  const selectedColumns = columns.filter((col) => selected.find((sel) => sel.id === col.id));

  dispatch({
    type: actionTypes.CHANGE_PAYROLL_LOCATION_COLUMNS,
    payload: selectedColumns,
  });
};

export const toggleImportOpenShiftsModal = (locationId) => ({
  type: actionTypes.TOGGLE_IMPORT_OPEN_SHIFTS_MODAL,
  payload: locationId,
});

export const toggleMainModal = () => ({
  type: actionTypes.TOGGLE_MAIN_MODAL,
});

export const showOpenShiftFromAutoGenerateModal = (shifts) => ({
  type: actionTypes.SHOW_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL,
  payload: shifts,
});

export const closeOpenShiftFromAutoGenerateModal = () => ({
  type: actionTypes.CLOSE_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL,
});

export const toggleOpenShiftRowSelect = (locationId) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_OPEN_SHIFT_ROW_SELECT,
    payload: locationId,
  });
  dispatch(checkIfCanDelete());
};



export const changeAllDefaultViewDateModes = (modes) => ({
  type: actionTypes.CHANGE_ALL_DEFAULT_VIEW_DATE_STORES,
  payload: modes,
});

export const checkDefaultViewDateStore = () => (dispatch, getState) => {
  const { reducer } = getState();
  const { pathname } = window.location;
  const { mainDateStore, uiState } = reducer;
  const defaultDateStore = uiState.defaultDateStores[pathname];
  if (defaultDateStore && defaultDateStore.dateText !== mainDateStore.dateText) {
    dispatch(changeDefaultViewDateStore(pathname, mainDateStore));
  }
};

export const changeSortingNameType = (useLastName) => ({
  type: actionTypes.CHANGE_SORTING_NAME_TYPE,
  payload: useLastName,
});

export const changeScheduleSearchString = (searchString) => ({
  type: actionTypes.CHANGE_SCHEDULE_SEARCH_STRING,
  payload: searchString,
});

export const setEmployeesVisibleColumns = (columns) => ({
  type: actionTypes.SET_EMPLOYEES_VISIBLE_COLUMNS,
  payload: columns,
});

export const hideTrialInfoBar = () => ({
  type: actionTypes.HIDE_TRIAL_INFO_BAR,
});

export const toggleAutoGenerateScheduleStatus = (payload) => ({
  type: actionTypes.TOOGLE_AUTO_GENERATE_SCHEDULE_STATUS,
  payload,
});

export const setAutoschedulerLocation = (payload) => ({
  type: actionTypes.SET_AUTOSCHEDULER_LOCATION,
  payload,
});

export const setFiltersFromUrlSuccess = () => ({
  type: actionTypes.SET_FILTERS_SUCCESS,
});

export const replaceOnAddAttendanceModal = (addAttendanceModalObject) => (dispatch) => {
  const { employee, date, defaultHours, locationId } = addAttendanceModalObject;
  dispatch(openAddAttendanceModal(employee, date, defaultHours, locationId));
};
export const updateUrlFilterStatus = (payload) => ({
  type: actionTypes.UPDATE_URL_FILTER_STATUS,
  payload,
});
