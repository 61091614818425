import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  locationBeforeTransitions: null,
};

const router = (state = initialState, action) => {
  switch (action.type) {
    case AT.LOCATION_CHANGE:
      return {
        ...state,
        locationBeforeTransitions: action.payload,
      };
    default:
      return state;
  }
};
export const routerReducer = router;
