import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  MDCheckbox,
  MDDatesPicker,
  MDSelect,
  MDTextArea,
  MDTimeRangeInput,
} from '@/components/common/inputs/MDComponents.jsx';
import { getFirstMatchingLocationOption, getLocationsListAsMdSelectOptions } from '@/utils/locationsHelpers';

import './AddShiftBasic.scss';

const messages = defineMessages({
  notePlaceholder: {
    id: 'common.noteText',
    defaultMessage: 'Treść notatki',
  },
});

const AddShiftBasic = (
  {
    employeeJobTitles,
    workingHours,
    comment,
    employee,
    userJobTitles,
    handleInputChange,
    errors,
    selectedJobTitle,
    userShiftblocks,
    isEditing,
    addShiftWithLocationAndDateSelect,
    location,
    date,
    showAddAttendanceForShift,
    addAttendanceForShift,
    useMultipleLocationFilter,
    multipleLocationFilter,
    minDate,
    maxDate,
  },
  { intl },
) => {
  if (isEmpty(employee)) return null;
  const name = `${employee.first_name} ${employee.last_name}`;
  const relevantShiftBlocks = userShiftblocks
    .filter(
      shiftBlock =>
        shiftBlock.job_titles === null ||
        !shiftBlock.job_titles.length ||
        shiftBlock.job_titles.filter(jobTitleId => jobTitleId === selectedJobTitle.id).length,
    )
    .map(shiftBlock => shiftBlock.working_hours);

  const locationsToSelect = useMemo(() => getLocationsListAsMdSelectOptions(employee.locations), [employee.locations]);
  const datesToSelect = useMemo(() => [date], [date]);

  useEffect(() => {
    if (useMultipleLocationFilter) {
      const firstMatchedLocation = getFirstMatchingLocationOption(multipleLocationFilter, locationsToSelect);
      handleInputChange(
        'location',
        employee.locations.find(locationToSelect => locationToSelect.id === firstMatchedLocation.value),
      );
    }
  }, [locationsToSelect, multipleLocationFilter, useMultipleLocationFilter]);

  return (
    <>
      <div className="heading heading--extraTopMargin">
        {isEditing ? (
          <FormattedMessage
            id="shifts.addModal.headerTitleEdit"
            defaultMessage="Edytuj zmianę dla {name}"
            values={{
              name,
            }}
          />
        ) : (
          <FormattedMessage
            id="shifts.addModal.headerTitleAdd"
            defaultMessage="Dodaj zmianę dla {name}"
            values={{
              name,
            }}
          />
        )}
      </div>
      <div className="addShiftBasic__select">
        <MDSelect
          name={<FormattedMessage id="shifts.addModal.jobTitle" defaultMessage="Stanowisko" />}
          defaultValue={selectedJobTitle.id}
          options={employeeJobTitles.map(({ title, id }) => ({
            key: title,
            value: id,
          }))}
          onChange={id =>
            handleInputChange(
              'selectedJobTitle',
              userJobTitles.find(j => j.id === id),
              employeeJobTitles,
            )
          }
          withSearch
          closeOnClick
          errorMessage={errors.selectedJobTitle}
        />
      </div>
      {addShiftWithLocationAndDateSelect ? (
        <>
          <MDSelect
            name={<FormattedMessage id="shifts.addModal.locations" defaultMessage="Lokalizacja" />}
            defaultValue={location.id}
            options={locationsToSelect}
            onChange={value =>
              handleInputChange(
                'location',
                employee.locations.find(locationToSelect => locationToSelect.id === value),
              )
            }
            closeOnClick
          />
          <MDDatesPicker
            id="date"
            handleInputChange={e => handleInputChange('date', ...e.target.value)}
            values={datesToSelect}
            singleDate
            label={<FormattedMessage id="shifts.addModal.date" defaultMessage="Data" />}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
          />
        </>
      ) : null}
      <MDTimeRangeInput
        value={workingHours}
        options={relevantShiftBlocks}
        onChange={hours => handleInputChange('working_hours', hours)}
        label={<FormattedMessage id="companymanage.shiftBlocks.time" defaultMessage="Czas" />}
        errorMessage={errors.working_hours || errors.shiftsOverlap}
        modifiers={['modal']}
      />
      {showAddAttendanceForShift ? (
        <MDCheckbox
          value={addAttendanceForShift}
          text={
            <FormattedMessage id="shifts.addModal.addAttendanceForShift" defaultMessage="Dodaj obecność do zmiany" />
          }
          onChange={() => handleInputChange('addAttendanceForShift', !addAttendanceForShift)}
          reverse
        />
      ) : null}
      <MDTextArea
        label={<FormattedMessage id="common.note" defaultMessage="Notatka" />}
        placeholder={intl.formatMessage(messages.notePlaceholder, {})}
        value={comment}
        onChange={e => handleInputChange(e.target.name, e.target.value)}
        id="comment"
        name="comment"
      />
    </>
  );
};

AddShiftBasic.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddShiftBasic.propTypes = {
  employeeJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  workingHours: PropTypes.string,
  comment: PropTypes.string,
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userShiftblocks: PropTypes.arrayOf(PropTypes.shape({})),
  locationSettings: PropTypes.shape({}),
  shift: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedDisplayMode: PropTypes.shape({}),
  errors: PropTypes.shape({
    working_hours: PropTypes.string,
    shiftsOverlap: PropTypes.string,
  }),
  selectedJobTitle: PropTypes.shape({
    id: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  isEditing: PropTypes.bool,
  addShiftWithLocationAndDateSelect: PropTypes.bool,
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  date: PropTypes.string,
  showAddAttendanceForShift: PropTypes.bool,
  addAttendanceForShift: PropTypes.bool,
  useMultipleLocationFilter: PropTypes.bool,
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
};

export default AddShiftBasic;
