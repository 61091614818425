import { connect } from 'react-redux';

import { mainDateChangeModeAndDate, mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';

import TopBarCalendar from './TopBarCalendar.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.mainDateStore,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  mainDateChangeModeAndDate,
};

const TopBarCalendarContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarCalendar);

export default TopBarCalendarContainer;
