const RodoInfo = () => (
  <div>
    <div className="k-newsModal__content k-newsModal__content--fullHeight">
      <h1>Informacje o RODO</h1>
      <p>
        25 maja zaczynają obowiązywać nowe przepisy o ochronie danych osobowych. Dlatego też wprowadziliśmy niezbędne
        zmiany w naszej polityce prywatności oraz regulaminie odbiorcy.
      </p>
      <p>
        Zależy nam na ochronie Twoich danych osobowych i przejrzystym informowaniu o tym, jakie informacje gromadzimy i
        co z nimi robimy.
      </p>
      <h2>Kto jest administratorem Twoich danych osobowych?</h2>
      <p>
        Administratorem Twoich danych osobowych jest Kadromierz sp. Z o.o. z siedzibą we Wrocławiu, przy ulicy
        Parafialnej 34 – Operator serwisu kadromierz.pl
      </p>
      <h2>W jaki sposób otrzymaliśmy Twoje dane osobowe?</h2>
      <p>Otrzymaliśmy je od Ciebie w momencie rejestracji konta.</p>
      <h2>W jakim celu przetwarzamy Twoje dane osobowe?</h2>
      <p>
        Przetwarzamy Twoje dane osobowe w celu realizacji naszej umowy z Tobą na świadczenie usług drogą elektroniczną.
        Dodatkowo przetwarzamy Twoje dane osobowe za każdym razem, gdy kontaktujemy się z Tobą, bądź Ty kontaktujesz się
        z nami, drogą elektroniczną lub telefonicznie. W przypadku gdy wyrazisz na to oddzielną zgodę, przetwarzamy
        Twoje dane osobowe w celu przesłania newslettera.
      </p>
      <h2>Jakie masz prawa związane ze swoimi danymi osobowymi?</h2>
      <ul>
        <li>
          dostęp do Twoich danych osobowych (sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia danych do
          innego Administratora),
        </li>
        <li>
          cofnięcie zgody na dalsze przetwarzanie Twoich danych osobowych przez nas (wystarczy, że wyślesz dyspozycję
          cofnięcia pod wyżej wskazany adres e-mail, lub złożysz ją bezpośrednio w naszej siedzibie),
        </li>
      </ul>

      <p>
        Pamiętaj, że w przypadku usunięcia wszystkich Twoich danych nie możemy już dłużej świadczyć naszych usług, co
        wiąże się z usunięciem Twojego konta w serwisie.
      </p>
      <p>W razie jakichkolwiek pytań, zawsze pozostajemy do Twojej dyspozycji.</p>
    </div>
  </div>
);

RodoInfo.propTypes = {};

export default RodoInfo;
