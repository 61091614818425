import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { KadroMultiSelect } from '@/components/common/inputs/MDComponents.jsx';

import { getEmployeesTitlesMessages } from './ExportEmployeesStep.helpers';

const ExportEmployeesStep = ({ employeesItems, employeesHandlers }, { intl }) => {
  const employeesMessages = useMemo(() => getEmployeesTitlesMessages(intl, employeesItems), [employeesItems]);

  return (
    <KadroMultiSelect
      messages={employeesMessages}
      items={employeesItems}
      handlers={employeesHandlers}
      customHeight="250px"
    />
  );
};

ExportEmployeesStep.propTypes = {
  employeesItems: PropTypes.arrayOf(PropTypes.shape({})),
  employeesHandlers: PropTypes.shape({}),
};

ExportEmployeesStep.contextTypes = {
  intl: PropTypes.shape({}),
};

export default ExportEmployeesStep;
