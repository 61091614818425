import { connect } from 'react-redux';

import { addBonusToAttendance } from '@/actions/attendances.js';
import { changePayrollDaysStatus, getPayrollViewData } from '@/actions/payroll/payroll.js';
import { setBonusValues } from '@/actions/payrollUI.js';

import PayrollTable from './PayrollTable';

const mapStateToProps = state => ({
  data: state.reducer.payroll.data,
  visibleColumns: state.reducer.payroll.visibleColumns,
  timeFormatType: state.reducer.payrollSettings.timeFormatSetting.type,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
  selectedEmployee: state.reducer.singleEmployeeFilter,
  bonusValues: state.reducer.payrollUI.bonusValues[state.reducer.singleEmployeeFilter?.id],
  payrollMode: state.reducer.payroll.mode,
  userPermissions: state.reducer.userPermissions,
  labels: state.reducer.labels,
  currentUser: state.reducer.currentUser,
  roles: state.reducer.roles,
  companySettings: state.reducer.currentCompany.settings,
  payrollApprovalEnabled: state.reducer.currentCompany.settings.payroll_approval_enabled,
  payrollLoanedEmployees: state.reducer.payrollLoanedEmployees.data,
});

const mapDispatchToProps = {
  addBonusToAttendance,
  setBonusValues,
  changePayrollDaysStatus,
  getPayrollViewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTable);
