import { connect } from 'react-redux';

import ScheduleTableLoanEmployeesProposal from './ScheduleTableLoanEmployeesProposals.tsx';

const mapStateToProps = state => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  isLoanEmployeesProposalsSticky: state.reducer.schedule.stickyRows.loanEmployeesProposalsSticky,
  restrictions: state.reducer.userPermissions.restrictions,
  loanEmployeesProposals: state.reducer.loanEmployeesProposals.data,
  userEmployees: state.reducer.userEmployees,
});

export default connect(mapStateToProps)(ScheduleTableLoanEmployeesProposal);
