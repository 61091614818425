import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getContrastYIQ } from '@/utils/colorHelpers.js';

const dragStart = (shift, e) => {
  e.dataTransfer.setData('shift', JSON.stringify(shift));
  const img = document.createElement('div');
  img.className = 'dragImage';
  img.innerHTML += '<i class="material-icons">insert_drive_file</i>';
  document.body.appendChild(img);

  e.dataTransfer.setDragImage(img, 0, 0);
};

const ScheduleTemplatesShift = props => {
  const shiftClass = classnames('sch_block', {
    draft_block: props.shift.draft,
  });
  const color = props.shift.job_title.color || '#FF0000';
  const shiftStyle = { backgroundColor: color };
  const contrastColor = getContrastYIQ(color);

  return (
    <div
      onClick={props.handleEditAction}
      className={shiftClass}
      style={shiftStyle}
      onDragStart={dragStart.bind(this, props.shift)}
      draggable
      role="presentation"
    >
      <div className="sch_block__info">
        <div className="time">{props.shift.working_hours}</div>
        <div className="person">
          <FormattedMessage
            id="scheduler.templates.shiftAmount"
            defaultMessage="Liczba zmian: {amount}"
            values={{
              amount: props.shift.amount,
            }}
          />
        </div>
      </div>
      <i className={classnames('sch_block__editIcon', 'material-icons', `sch_block__editIcon--${contrastColor}`)}>
        edit
      </i>
      <i
        onClick={props.handleDeleteAction}
        className={classnames('material-icons', 'del-block', `del-block--${contrastColor}`)}
        role="presentation"
      >
        delete
      </i>
      <i
        onClick={props.handleAddAction}
        className={classnames('material-icons', 'add-block', `add-block--${contrastColor}`)}
        role="presentation"
      >
        add
      </i>
    </div>
  );
};

ScheduleTemplatesShift.propTypes = {
  shift: PropTypes.shape({
    working_hours: PropTypes.string,
    amount: PropTypes.number,
    job_title: PropTypes.shape({
      color: PropTypes.string,
    }),
    draft: PropTypes.bool,
  }),
  handleAddAction: PropTypes.func,
  handleEditAction: PropTypes.func,
  handleDeleteAction: PropTypes.func,
};

export default ScheduleTemplatesShift;
