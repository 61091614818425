import AvailabilityCalendarViewContainer from '@/containers/availability/AvailabilityCalendarViewContainer.js';
import AvailabilityTableViewContainer from '@/containers/availability/AvailabilityTableViewContainer.js';

import AvailabilitySendPanelContainer from './scheduler/Availability/AvailabilitySendPanel/AvailabilitySendPanel.redux';
import TopBarAvailailbility from './TopBars/TopBarAvailability.jsx';

const AvailabilityView = () => {
  // TODO: Rethink
  if (window.matchMedia('screen and (max-width: 680px)').matches) {
    return (
      <div>
        <AvailabilityCalendarViewContainer />
      </div>
    );
  }
  return (
    <div>
      <TopBarAvailailbility />
      <AvailabilityTableViewContainer />
      <AvailabilitySendPanelContainer />
    </div>
  );
};

AvailabilityView.propTypes = {};

export default AvailabilityView;
