import { PropTypes } from 'prop-types';
import { Component } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';

class Regulations extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rules: false,
      policy: false,
    };
  }

  render() {
    return (
      <div>
        <div className="k-newsModal__content">
          <h1 align="center">Regulamin Odbiorcy</h1>
          <p align="justify">
            Niniejszy Regulamin Odbiorcy określa zasady współpracy Stron oraz zasady korzystania z programu Kadromierz
            udostępnianego przez Kadromierz sp. z o.o. za pomocą serwisu internetowego umieszczonego na domenie{' '}
            <a href="https://kadromierz.pl/">
              <u>https://kadromierz.pl/</u>
            </a>
            . Poprzez akceptację Regulaminu Odbiorcy, Strony zawierają Umowę o świadczenie usług.
          </p>
          <ol>
            <li>
              <p align="justify">
                <u>Definicje.</u>
                Pojęcia używane w niniejszym Regulaminie Odbiorcy powinny być rozumiane w następujący sposób:
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <strong>Dzień aktywacji</strong>– dzień, w którym Odbiorca uzyskuje dostęp do programu Kadromierz,
                    następujący w terminie 7 dni od dnia wyboru Pakietu i akceptacji Regulaminu Odbiorcy przez Odbiorcę;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Kadromierz</strong>– program komputerowy i utwór w rozumieniu ustawy z dnia 4 lutego 1994 r.
                    o prawie autorskim i prawach pokrewnych, będący przedmiotem Usług świadczonych przez operatora;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Konto</strong>– wyodrębnione miejsce w serwisie internetowym Operatora, w którym zapisywane
                    są dane Odbiorców i Użytkowników, do których Odbiorca lub Użytkownik uzyskuje dostęp po skutecznym
                    zalogowaniu się w serwisie internetowym Operatora przy użyciu loginu i hasła;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Licencja - licencja niewyłączna, o której mowa w punkcie 4. Regulaminu Odbiorcy;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Odbiorca </strong>– podmiot będący stroną Umowy i odbiorcą Usług;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Okres aktywacji Pakietu</strong>– czas, przez który Operator udostępnia Odbiorcy program
                    Kadromierz, zgodnie wykupionym Pakietem;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Operator </strong>– Kadromierz sp. z o.o. z siedzibą we Wrocławiu, przy ul. Parafialnej 34,
                    52-233 Wrocław;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Pakiet</strong>– wariant działania programu Kadromierz, wybierany przez Odbiorcę na
                    podstawie oferty przedstawionej przez Operatora;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Regulamin Odbiorcy</strong>– niniejszy regulamin określający zasady świadczenia Usług;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>RODO</strong>- rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia
                    2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                    swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Strona </strong>– Odbiorca albo Operator;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Strony </strong>– Odbiorca i Operator;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Umowa</strong>– umowa o świadczenie usług Kadromierz;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Usługa</strong>– usługa świadczona przez Operatora drogą elektroniczną na rzecz Użytkownika,
                    polegająca na udostępnianiu Użytkownikowi programu Kadromierz. Usługa jest nieodpłatna tylko w
                    takich przypadkach i tylko w takim zakresie, w jakich zostało to wyraźnie wskazane przez Operatora;
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <strong>Użytkownik</strong>– korzystająca z Usług osoba fizyczna, zgłoszona przez Odbiorcę.;
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="2">
            <li>
              <p align="justify">
                <u>Warunki świadczenia Usług.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    Na podstawie Regulaminu Odbiorcy, Operator świadczy na rzecz Odbiorcy Usługę polegającą na czasowym
                    udostępnieniu programu Kadromierz. Zakres Usługi zależy od wybranego przez Odbiorcę Pakietu.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Usługa świadczona jest od Dnia aktywacji zamówionego Pakietu przez Okres aktywacji Pakietu.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W celu rozpoczęcia korzystania z programu Kadromierz, Odbiorca wypełnia formularz internetowy
                    znajdujący się w serwisie internetowym
                    <a href="https://kadromierz.pl/">
                      <u>https://kadromierz.pl/</u>
                    </a>
                    . Formularz rejestracyjny obejmuje dane Odbiorcy, w szczególności:
                  </p>
                  <ol type="a">
                    <ol type="i">
                      <li>
                        <p align="justify">nazwę;</p>
                      </li>
                      <li>
                        <p align="justify">NIP;</p>
                      </li>
                      <li>
                        <p align="justify">adres poczty elektronicznej;</p>
                      </li>
                      <li>
                        <p align="justify">indywidualnie oznaczone hasło;</p>
                      </li>
                      <li>
                        <p align="justify">numer telefonu.</p>
                      </li>
                    </ol>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    Po wypełnieniu formularza rejestracyjnego, Odbiorca w celu dokończenia rejestracji będzie
                    zobowiązany do akceptacji niniejszego Regulaminu Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Po wypełnieniu formularza, akceptacji Regulaminu Odbiorcy i utworzeniu Konta, Odbiorca uzyskuje
                    dostęp do Usługi.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca, dokonując rejestracji zobowiązany jest do podania danych zgodnych z prawdą, dokładnych,
                    aktualnych, niewprowadzających w błąd oraz nienaruszających praw osób trzecich. Ponadto, Odbiorca
                    jest odpowiedzialny za utrzymywanie w tajemnicy swojego hasła i nieudostępniania go osobom trzecim.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W przypadku zmiany danych wpisanych w formularzu rejestracyjnym, Odbiorca jest zobowiązany do ich
                    aktualizacji poprzez ich zmianę na Koncie Odbiorcy w terminie 7 dni od dnia, w którym dane uległy
                    zmianie.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Po założeniu Konta, Odbiorca uzyskuje dostęp do funkcji określonych w wybranym Pakiecie, a także
                    możliwość dodawania Użytkowników. Konta Użytkowników zakładane są przez Odbiorcę, poprzez
                    wypełnienie formularza zawierającego imię i nazwisko oraz adres e-mail. Podanie danych Użytkownika
                    przez Odbiorcę związane z zakładaniem Konta Użytkownika jest równoznaczne z ich powierzeniem,
                    zgodnie z Załącznikiem nr 1 do Regulaminu Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Po udostępnieniu programu Kadromierz na podstawie Regulaminu Odbiorcy, Odbiorca jest samodzielnie
                    odpowiedzialny za prawidłowe wykorzystywanie programu Kadromierz. Operator nie ponosi
                    odpowiedzialności za skutki niewłaściwego korzystania z Programu i wykorzystywania go niezgodnie z
                    jego przeznaczeniem przewidzianym w Regulaminie Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca, korzystając z Usług, nie może umieszczać w programie Kadromierz treści niezgodnych z
                    prawem, naruszających prawa osób trzecich, podejmować działań zmierzających do utrudnienia lub
                    zakłócenia funkcjonowania programu Kadromierz lub niszczenia, usuwania, uszkadzania lub utrudniania
                    dostępu do Kont osób trzecich.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca ponosi pełną odpowiedzialność za treść podanych przez siebie danych. Operator informuje, że
                    wszelkie treści przesyłane lub wszelkie czynności dokonane na Koncie Odbiorcy przez osoby trzecie
                    uzyskujące dostęp do Konta Odbiorcy za pomocą hasła Odbiorcy, będą przypisane Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca zobowiązuje się do używania programu Kadromierz w sposób nienaruszający praw osób trzecich,
                    dobrych obyczajów i przepisów prawa.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca oświadcza, że jest Administratorem danych osobowych w rozumieniu RODO, które przetwarza w
                    ramach Usług udostępnionych przez Operatora.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca, jako Administrator danych w pełni odpowiada za udokumentowanie podstawy prawnej
                    przetwarzania danych osobowych powierzanych Operatorowi w celu świadczenia Usługi.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca jako administrator danych, powierza Operatorowi przetwarzanie danych osobowych niezbędnych
                    do realizacji Usługi na zasadach określonych w Umowie powierzenia przetwarzania danych osobowych,
                    stanowiącej załącznik nr 1 do Regulaminu Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator nie ponosi żadnej odpowiedzialności za treść prezentowaną na Koncie założonym przez
                    Odbiorcę lub Użytkownika.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W przypadku otrzymania informacji o bezprawnym użyciu danych, Operator ma prawo, zgodnie z art. 14
                    ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, do zablokowania dostępu do
                    danych Odbiorcy lub Użytkownika. W takim przypadku Odbiorca lub Użytkownik zostanie niezwłocznie
                    powiadomiony o wprowadzeniu blokady Konta.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator nie ponosi odpowiedzialności za szkodę wynikającą z utraty danych przez Odbiorcę lub
                    Użytkownika będącej skutkiem awarii technicznej lub siły wyższej.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W Okresie aktywacji Pakietu, Odbiorca ma możliwość zmiany wybranego Pakietu. Zmiana Pakietu jest
                    skuteczna od Dnia aktywacji nowego Pakietu. W przypadku zmiany Pakietu na droższy, opłata za
                    uruchomienie nowego Pakietu jest zmniejszona proporcjonalnie do ilości dni, które minęły od Dnia
                    aktywacji zmienionego Pakietu. W przypadku zmiany Pakietu na tańszy, opłata wniesiona za aktywację
                    zmienionego Pakietu nie podlega zwrotowi.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca zapewnia, że Użytkownicy, których konta zostały utworzone przez Odbiorcę, będą przestrzegać
                    postanowień, o których mowa w punktach 2.10. - 2.12. Regulaminu Odbiorcy. W przypadku ich naruszenia
                    przez Użytkownika, Odbiorca odpowiada za te działania Użytkownika jak za działania własne.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="3">
            <li>
              <p align="justify">
                <u>Dane osobowe.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    W przypadku osób fizycznych prowadzących działalność gospodarczą, będących Odbiorcami, Operator
                    oświadcza, że jest administratorem danych osobowych.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Wszelkie postanowienia zawarte w niniejszym punkcie 3. Umowy odnoszą się wyłącznie do Odbiorcy
                    będącego osobą fizyczną prowadzącą działalność gospodarczą.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Wszelkie zasady przetwarzania danych osobowych Odbiorcy zostały wskazane w Polityce Prywatności,
                    którą Odbiorca akceptuje w celu rozpoczęcia korzystania z Usługi.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="4">
            <li>
              <p align="justify">
                <u>Warunki techniczne.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    W celu korzystania z Usług, Odbiorca musi dysponować platformą sprzętową pozwalającą na dostęp do
                    sieci Internet wraz z programem pozwalającym na przeglądanie jej zasobów www i akceptującym pliki
                    typu cookies, a także pocztą elektroniczną pozwalającą na otrzymywanie wiadomości typu e-mail.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator ma prawo do czasowej przerwy w świadczeniu Usług, związanej z wprowadzaniem zmian
                    technicznych do programu Kadromierz. Jednorazowa przerwa w świadczeniu Usług nie może przekraczać 3
                    dni roboczych. Operator dołoży najwyższej staranności, aby przerwy techniczne miały miejsce w
                    godzinach nocnych i trwały możliwie najkrócej. W przypadku przerwy przekraczającej 24 godziny,
                    Odbiorca ma prawo przedłużenia Okresu aktywacji Pakietu o czas trwania przerwy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator zobowiązuje się do niezwłocznego usunięcia wszelkich usterek uniemożliwiających dostęp lub
                    prawidłowe korzystanie z programu Kadromierz. W przypadku nie usunięcia zgłoszonych awarii w
                    terminie 14 dni, Odbiorca może wypowiedzieć Umowę ze skutkiem natychmiastowym.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie Usługi z przyczyn
                    leżących po stronie osób trzecich, w szczególności operatorów sieci telekomunikacyjnych, a także
                    spowodowanych siłą wyższą.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="5">
            <li>
              <p align="justify">
                <u>Prawa autorskie Operatora.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    Operator oświadcza, że przysługują mu majątkowe prawa autorskie do programu Kadromierz w rozumieniu
                    Ustawy prawo autorskie, za wyjątkiem tych elementów, które stanowią biblioteki pochodzące z tzw.
                    Otwartego oprogramowania (biblioteki open source). Biblioteki zostały załączone do programu
                    Kadromierz oraz są rozpowszechniane w ramach programu Kadromierz zgodnie z warunkami licencji
                    określającymi zasady ich eksploatacji.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator oświadcza, że strona internetowa
                    <a href="https://kadromierz.pl/">
                      <u>https://kadromierz.pl/</u>
                    </a>
                    zawiera chronione Ustawą Prawo autorskie i stanowiące własność Operatora znaki towarowe oraz inne
                    utwory, w szczególności interfejs oraz poszczególne elementy strony: grafikę, rysunki i kolorystykę,
                    które zostały stworzone na potrzeby Operatora. Zastosowany przez Operatora układ oraz wybór
                    prezentowanych treści stanowi samoistny przedmiot prawa autorskiego w rozumieniu Ustawy prawo
                    autorskie.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Poprzez zawarcie Umowy, Operator udziela Odbiorcy licencji niewyłącznej na korzystanie z programu
                    Kadromierz, zgodnie z postanowieniami Regulaminu Odbiorcy, w szczególności z uwzględnieniem
                    wybranego w Pakietu.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca lub Użytkownik nie ma prawa do udzielania dalszych licencji na korzystanie z programu
                    Kadromierz.
                  </p>
                </li>
                <li>
                  <p align="justify">Licencja zostaje udzielone na następujących polach eksploatacji:</p>
                  <ol type="a">
                    <li>
                      <p align="justify">
                        w zakresie korzystania z programu Kadromierz - uruchamianie przez liczbę Użytkowników
                        uzależnioną od liczby założonych kont i korzystanie z programu Kadromierz przez Odbiorcę
                        wyłącznie w odniesieniu do pracowników lub współpracowników Odbiorcy, przy czym jeden Użytkownik
                        może jednocześnie korzystać z Programu Kadromierz na kilku różnych urządzeniach.
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        w zakresie rozpowszechniania programu Kadromierz - umożliwienie pracownikom i współpracownikom
                        korzystania z programu Kadromierz.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    Licencja zostaje udzielona na terytorium Polski z zastrzeżeniem korzystania z programu Kadromierz w
                    sieci Internet.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Licencja obowiązuje przez Okres aktywacji Pakietu. Po jego zakończeniu Licencja wygasa.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Opłata za udzielenie licencji, o której mowa w pkt 5.3. Regulaminu Odbiorcy, zawiera się w
                    wynagrodzeniu, o którym mowa w pkt 7. Regulaminu Odbiorcy.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="6">
            <li>
              <p align="justify">
                <u>Powierzenie przetwarzania danych osobowych</u>
              </p>
            </li>
          </ol>
          <p align="justify">
            Akceptacja Regulaminu Odbiorcy oznacza dla Odbiorcy konieczność powierzenia Operatorowi przetwarzania danych
            osobowych należących do osób trzecich, których dane są wprowadzane przez Odbiorcę do programu Kadromierz.
            Powierzenie przetwarzania danych osobowych reguluje Umowa powierzenia przetwarzania danych, stanowiący
            Załącznik nr 1 do Regulaminu Odbiorcy.
          </p>
          <ol start="7">
            <li>
              <p align="justify">
                <u>Wynagrodzenie.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    Wynagrodzenie za świadczoną usługę będzie obliczone zgodnie z wybranym przez Odbiorcę Pakietem.
                    Wynagrodzenie płatne w cyklach miesięcznych, z góry, za każdy miesiąc Okresu aktywacji Pakietu.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator zobowiązuje się do wystawienia pierwszej faktury VAT w terminie 7 dni od dnia wyboru
                    Pakietu i akceptacji niniejszego Regulaminu Odbiorcy. Kolejne faktury wystawiane będą w terminie 7
                    dni od dnia rozpoczęcia kolejnego miesiąca trwania Okresu aktywacji Pakietu, przy czym za jeden
                    miesiąc uważa się 30 dni. Wynagrodzenie będzie płatne na rachunek bankowy Operatora wskazany na
                    fakturze VAT. Odbiorca zobowiązuje się zapłacić wynagrodzenie w terminie 7 dni od dnia doręczenia mu
                    faktury VAT.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Za dzień zapłaty kwot z tytułu wystawionych przez Operatora faktur Strony przyjmują dzień uznania
                    rachunku bankowego Operatora, o którym mowa w pkt 7.2. Regulaminu Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Brak wpłaty z tytułu wystawionej przez Operatora faktury VAT w terminie i na rachunek bankowy w niej
                    określony skutkuje zablokowaniem dostępu Odbiorcy i Użytkowników do programu Kadromierz. Wraz z
                    informacją o zablokowaniu dostępu, Odbiorca wezwie Operatora do zapłaty oraz poinformuje go o
                    skutkach braku zapłaty wynagrodzenia.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Dokonanie wpłaty w terminie 14 dni od dnia zablokowania dostępu pozwala na odblokowanie dostępu w
                    dniu uznania rachunku bankowego Operatora. Bezskuteczny upływ 14-dniowego terminu, o którym mowa w
                    zdaniu poprzedzającym, skutkuje prawem Odbiorcy do usunięcia Kont Odbiorcy i Użytkowników i
                    rozwiązania Umowy ze skutkiem natychmiastowym z winy Odbiorcy.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="8">
            <li>
              <p align="justify">
                <u>Warunki zawierania i rozwiązywania umów o świadczenie Usług.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    Umowa o świadczenie Usług zawierane są poprzez wybór Pakietu i akceptację Regulaminu Odbiorcy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Umowa zawierana jest na czas trwania Okresu aktywacji Pakietu. W przypadku, gdy Odbiorca nie złoży
                    oświadczenia o rezygnacji ze świadczenia usługi na co najmniej 3 dni przed końcem Okresu aktywacji
                    Pakietu, Pakiet ulega automatycznemu przedłużeniu o poprzedni Okres aktywacji Pakietu. W takim
                    przypadku, Operator uprawniony jest do wystawienia faktury VAT za Okres aktywacji Pakietu, o który
                    przedłużono świadczenie Usługi, a Odbiorca zobowiązany jest do jej zapłacenia.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator zastrzega sobie prawo do zawierania odrębnych umów z Odbiorcami, na indywidualnie
                    uzgodnionych warunkach.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator jest uprawniony do rozwiązania Umowy bez zachowania terminu wypowiedzenia w przypadku
                    określonym w pkt 7.5. Regulaminu Odbiorcy, a także w przypadku naruszenia przez Odbiorcę postanowień
                    Regulaminu Odbiorcy lub Regulaminu Użytkownika, w przypadku naruszenia przez Użytkownika Regulaminu
                    Użytkownika oraz w przypadku naruszenia przez Odbiorcę lub Użytkownika przepisów prawa lub praw osób
                    trzecich w związku z wykonaniem Umowy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Odbiorca jest uprawniony do rozwiązania Umowy bez zachowania terminu wypowiedzenia w przypadku
                    określonym w pkt 4.3. Regulaminu Odbiorcy. W takim przypadku, Odbiorcy przysługuje zwrot zapłaconego
                    wynagrodzenia w wysokości proporcjonalnej do pozostałego okresu, liczonego od dnia rozwiązania Umowy
                    do ostatniego dnia Okresu aktywacji Pakietu, zgodnie z wybranym i opłaconym Pakietem.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W przypadku rozwiązania Umowy w jakimkolwiek trybie i przez którąkolwiek ze Stron lub wygaśnięcia
                    Umowy, w sytuacji, gdy Odbiorca zapłacił z góry za okres korzystania z programu Kadromierz i okres
                    ten wykracza poza datę rozwiązania lub wygaśnięcia Umowy, zapłacone przez Odbiorcę wynagrodzenie nie
                    podlega zwrotowi, z zastrzeżeniem pkt 7.5. Regulaminu Odbiorcy.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="9">
            <li>
              <p align="justify">
                <u>Reklamacje.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    Jeżeli w ocenie Odbiorcy, Usługa jest świadczona w sposób nieprawidłowy, jest on uprawniony do
                    przesłania reklamacji pocztą elektroniczną na adres Operatora: kontakt@kadromierz.pl lub za
                    pośrednictwem poczty na adres: Parafialna 34, Wrocław 52-233.
                  </p>
                </li>
                <li>
                  <p align="justify">Reklamacja powinna obejmować:</p>
                  <ol type="a">
                    <li>
                      <p align="justify">Dokładne oznaczenie Odbiorcy;</p>
                    </li>
                    <li>
                      <p align="justify">Dokładny opis nieprawidłowości w świadczeniu Usług oraz treść żądania;</p>
                    </li>
                    <li>
                      <p align="justify">Termin zaistnienia nieprawidłowości i czas jej trwania;</p>
                    </li>
                    <li>
                      <p align="justify">Preferowany sposób otrzymania odpowiedzi;</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">Reklamacje będą rozpatrywane w terminie 30 dni.</p>
                </li>
              </ol>
            </li>
          </ol>
          <ol start="10">
            <li>
              <p align="justify">
                <u>Postanowienia końcowe.</u>
              </p>
              <ol>
                <li>
                  <p align="justify">Regulamin Odbiorcy wchodzi w życie z dniem jego akceptacji.</p>
                </li>
                <li>
                  <p align="justify">
                    Regulamin Odbiorcy obowiązuje do ostatniego dnia Okresu aktywacji Pakietu włącznie lub do dnia
                    rozwiązania Umowy.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    Operator zastrzega sobie prawo do zmiany Regulaminu Odbiorcy. Wszelkie zmiany niniejszego Regulaminu
                    Odbiorcy zostaną przedstawione Odbiorcy na co najmniej 14 dni przed planowanym wejściem w życie
                    zmian.
                  </p>
                </li>
                <li>
                  <p align="justify">
                    W sprawach nieuregulowanych w niniejszym Regulaminie Odbiorcy zastosowanie mają polskie przepisy
                    powszechnie obowiązujące.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p align="justify">
            <strong>Załącznik nr 1 do Regulaminu Odbiorcy</strong>
          </p>
          <p align="center">
            <strong>Umowa powierzenia przetwarzania danych osobowych</strong>
          </p>
          <p align="justify">
            Zawarta we Wrocławiu w dniu akceptacji Regulaminu Odbiorcy pomiędzy Odbiorcą zwanym dalej “Administratorem
            danych”, “Administratorem”
          </p>
          <p align="justify">a</p>
          <p align="justify">
            <strong>Kadromierz Sp. z o.o.</strong>, z siedzibą we Wrocławiu przy ulicy Parafialnej 34, 52-233 Wrocław,
            zarejestrowaną w Krajowym Rejestrze Sądowym prowadzonym przez Sąd Rejonowy dla Wrocławia-Fabrycznej we
            Wrocławiu VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000612634, NIP 8992784752,
            REGON 363744630, o kapitale zakładowym w wysokości 109.000,00 zł
          </p>
          <p align="justify">
            zwaną dalej <strong>“Podmiotem przetwarzającym”</strong>
          </p>
          <p align="justify">
            <strong>Definicje:</strong>
          </p>
          <p align="justify">
            Dane osobowe - wszelkie informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby
            fizycznej;
          </p>
          <p align="justify">
            Przetwarzanie danych - jakiekolwiek operacje wykonywane na danych osobowych, takie jak zbieranie,
            utrwalanie, przechowywanie, opracowywanie, zmienianie, udostępnianie i usuwanie, a zwłaszcza te operacje,
            które wykonuje się w systemach informatycznych;
          </p>
          <p align="justify">
            <br />
            RODO - rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 roku w sprawie
            ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
            danych oraz uchylenia dyrektywy 95/46/WE;
          </p>
          <p align="justify">Umowa - poniższa umowa powierzenia przetwarzania danych osobowych;</p>
          <p align="justify">Umowa główna - umowa zawarta przez Strony w celu świadczenia usług;</p>
          <p align="justify">
            Ustawa – Ustawa z 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2016 r. poz. 922 z późn. zm.) ub
            inny akt prawny, który zastąpi rzeczoną ustawę.
            <br />
            <br />
          </p>
          <p align="justify">
            <strong>ZWAŻYWSZY, ŻE:</strong>
          </p>
          <p align="justify">
            Administrator danych i Podmiot przetwarzający zawarli Umowę na świadczenie usług, a niniejsza umowa jest
            niezbędna dla realizacji celów wynikających z tej umowy na podstawie art. 28 RODO,
          </p>
          <p align="justify">
            <strong>STRONY ZAWIERAJĄ UMOWĘ O NASTĘPUJĄCEJ TREŚCI: </strong>
          </p>
          <p align="center">
            <strong>§ 1 </strong>
          </p>
          <p align="center">
            <strong>Powierzenie przetwarzania danych osobowych</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                Administrator danych powierza Podmiotowi przetwarzającemu, w trybie art. 28 ogólnego rozporządzenia o
                ochronie danych z dnia 27 kwietnia 2016 r. (zwanego w dalszej części „Rozporządzeniem”) dane osobowe do
                przetwarzania, na zasadach i w celu określonym w niniejszej Umowie.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się przetwarzać powierzone mu dane osobowe zgodnie z niniejszą umową,
                Rozporządzeniem oraz z innymi przepisami prawa powszechnie obowiązującego, które chronią prawa osób,
                których dane dotyczą.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający oświadcza, iż stosuje środki bezpieczeństwa spełniające wymogi Rozporządzenia.
              </p>
            </li>
          </ol>
          <p align="center">
            <strong>§ 2</strong>
          </p>
          <p align="center">
            <strong>Zakres i cel przetwarzania danych</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                Administrator danych powierza Podmiotowi przetwarzającemu Dane osobowe swoich kontrahentów, pracowników,
                współpracowników. Zakres Danych osobowych powierzonych Podmiotowi przetwarzającemu obejmuje:
              </p>
            </li>
          </ol>
          <ul>
            <li>
              <p align="justify">imiona i nazwiska;</p>
            </li>
            <li>
              <p align="justify">numery telefonów;</p>
            </li>
            <li>
              <p align="justify">adresy poczty elektronicznej;</p>
            </li>
            <li>
              <p align="justify">nazwy stanowisk pracy,</p>
            </li>
            <li>
              <p align="justify">wizerunki</p>
            </li>
          </ul>
          <ol start="2">
            <li>
              <p align="justify">
                Powierzone przez Administratora danych Dane osobowe będą przetwarzane przez Podmiot przetwarzający
                wyłącznie w celu:
              </p>
            </li>
          </ol>
          <ul>
            <li>
              <p align="justify">
                umożliwienia dostępu i korzystania z usługi świadczonej przez Podmiot przetwarzający w ramach Umowy
                głównej;
              </p>
            </li>
            <li>
              <p align="justify">
                świadczenia pomocy w korzystaniu z usługi świadczonej przez podmiot przetwarzający w ramach Umowy
                głównej;
              </p>
            </li>
            <li>
              <p align="justify">
                tworzenia kopii zapasowych systemu niezbędnych do zapewnienia ciągłości dostępu do Usługi;
              </p>
            </li>
            <li>
              <p align="justify">rozwiązywanie problemów związanych z funkcjonowaniem usługi</p>
            </li>
          </ul>
          <p align="center">
            <strong>§ 3</strong>
          </p>
          <p align="center">
            <strong>Obowiązki i odpowiedzialność Podmiotu przetwarzającego</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się, przy przetwarzaniu powierzonych Danych osobowych, do ich
                zabezpieczenia poprzez stosowanie odpowiednich środków technicznych i organizacyjnych zapewniających
                adekwatny stopień bezpieczeństwa odpowiadający ryzyku związanym z przetwarzaniem Danych osobowych, o
                których mowa w art. 32 Rozporządzenia.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się do nadania upoważnień do przetwarzania Danych osobowych wszystkim
                osobom, które będą przetwarzały powierzone Dane osobowe w celu realizacji Umowy.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się zapewnić zachowanie w tajemnicy, (o której mowa w art. 28 ust 3
                lit. b Rozporządzenia) przetwarzanych Danych osobowych przez osoby, które upoważnia do przetwarzania
                Danych osobowych w celu realizacji Umowy, zarówno w trakcie zatrudnienia ich w Podmiocie
                przetwarzającym, jak i po jego ustaniu.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający po zakończeniu świadczenia usług związanych z przetwarzaniem usuwa wszelkie Dane
                osobowe oraz usuwa wszelkie ich istniejące kopie, chyba że prawo Unii Europejskiej lub prawo państwa
                członkowskiego nakazują przechowywanie Danych osobowych.
              </p>
            </li>
            <li>
              <p align="justify">
                W miarę możliwości Podmiot przetwarzający pomaga Administratorowi w niezbędnym zakresie wywiązywać się z
                obowiązków określonych w art. 32-36 Rozporządzenia, a także pomaga Administratorowi wywiązać się z
                obowiązku odpowiadania na żądania osoby, której Dane osobowe dotyczą, w szczególności poprzez
                informowanie Administratora o skierowaniu do Podmiotu przetwarzającego żądań od osób, których Dane
                osobowe dotyczą.
              </p>
            </li>
            <li>
              <p align="justify">
                W przypadku stwierdzenia naruszenia ochrony Danych osobowych Podmiot przetwarzający niezwłocznie
                informuje o tym fakcie Administratora danych.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający jest odpowiedzialny za udostępnienie lub wykorzystanie powierzonych mu Danych
                osobowych niezgodnie z treścią umowy, a w szczególności za udostępnienie powierzonych do przetwarzania
                Danych osobowych osobom nieupoważnionym.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się do niezwłocznego poinformowania Administratora danych o
                jakimkolwiek postępowaniu, w szczególności administracyjnym lub sądowym, dotyczącym przetwarzania przez
                Podmiot przetwarzający Danych osobowych określonych w Umowie, o jakiejkolwiek decyzji administracyjnej
                lub orzeczeniu dotyczącym przetwarzania tych danych, skierowanych do Podmiotu przetwarzającego, a także
                o wszelkich planowanych, o ile są wiadome, lub realizowanych kontrolach i inspekcjach dotyczących
                przetwarzania w Podmiocie przetwarzającym tych danych osobowych, w szczególności prowadzonych przez
                inspektorów upoważnionych przez Generalnego Inspektora Ochrony Danych Osobowych lub inny organ nadzorczy
                zajmujący się ochroną Danych osobowych. Niniejszy ustęp dotyczy wyłącznie Danych osobowych powierzonych
                przez Administratora danych.
                <br />
                <br />
              </p>
            </li>
          </ol>
          <p align="center">
            <strong>§ 4</strong>
          </p>
          <p align="center">
            <strong>Prawo kontroli</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                Administrator danych jest uprawniony do przeprowadzenia audytu Podmiotu przetwarzającego pod względem
                przetwarzania danych osobowych maksymalnie raz w roku. Audyt będzie przeprowadzany przez wykwalifikowany
                podmiot zewnętrzny, specjalizujący się w zakresie bezpieczeństwa informacji oraz ochrony danych
                osobowych, zaakceptowany zarówno przez Administratora danych, jak i Podmiot przetwarzający.
                Administrator danych ma prawo zażądać wglądu w wyniki przeprowadzonego audytu. Wszelkie koszty związane
                z przeprowadzeniem audytu, w tym koszty wynagrodzenia podmiotu wykonującego audyt i koszty powstałe po
                stronie Podmiotu przetwarzającego ponosi wyłącznie Administrator danych. Termin przeprowadzenia audytu
                zostanie uzgodniony przez Strony na co najmniej 14 dni przed planowanym dniem rozpoczęcia audytu.
              </p>
            </li>
            <li>
              <p align="justify">
                W przypadku uzyskania przez Podmiot przetwarzający certyfikatu zgodności operacji przetwarzania z RODO,
                o którym mowa w art. 42 RODO, informacje zawarte w ust. 1 nie mają zastosowania.
              </p>
            </li>
            <li>
              <p align="justify">
                Administrator danych jest uprawniony do weryfikacji przestrzegania zasad przetwarzania danych osobowych
                wynikających z RODO oraz niniejszej Umowy przez Podmiot przetwarzający. Podmiot przetwarzający
                udostępnia Administratorowi wszelkie informacje niezbędne do wykazania spełnienia obowiązków określonych
                w Art. 28 RODO.
              </p>
            </li>
            <li>
              <p align="justify">
                Administratorowi danych zgodnie z art. 28 ust. 3 lit. h Rozporządzenia przysługuje prawo kontroli, czy
                środki zastosowane przez Podmiot przetwarzający przy przetwarzaniu i zabezpieczeniu powierzonych danych
                osobowych spełniają postanowienia umowy.
              </p>
            </li>
            <li>
              <p align="justify">
                Przeprowadzenie audytu każdorazowo wymaga wcześniejszego uzgodnienia terminu audytu przez Strony.
              </p>
            </li>
            <li>
              <p align="justify">
                Administrator danych realizować będzie prawo kontroli w godzinach pracy Podmiotu przetwarzającego i z
                minimum 14-dniowym jego uprzedzeniem.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający zobowiązuje się do usunięcia uchybień stwierdzonych podczas kontroli w terminie
                wskazanym przez Administratora danych nie dłuższym niż 7 dni.
              </p>
            </li>
          </ol>
          <p align="center">
            <strong>§ 5</strong>
          </p>
          <p align="center">
            <strong>Dalsze powierzenie danych do przetwarzania</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                W celu wykonania Umowy głównej Administrator udziela zgody Podmiotowi przetwarzającemu na dalsze
                powierzenie przetwarzania danych osobowych, o których mowa w § 2. Niniejsza zgoda obejmuje dalsze
                powierzanie przetwarzania danych w ramach usług zlecanych przez Podmiot przetwarzający w zakresie
                realizacji usług hostingowych i komunikacji.
              </p>
            </li>
            <li>
              <p align="justify">
                W momencie podpisywania niniejszej Umowy, podmiotem, któremu Przetwarzający powierza przetwarzanie
                danych jest: Amazon Web Services Ireland Limited, Intercom INC i LogRocket, Inc.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający informuje Administratora o wszelkich zmianach dotyczących dodania lub zastąpienia
                innych podmiotów przetwarzających. Administrator danych ma prawo wyrażenia sprzeciwu wobec takich zmian
                w terminie 7 dni od momentu otrzymania zawiadomienia.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający może korzystać z usług innego podmiotu przetwarzającego wyłącznie na podstawie
                uprzedniej szczegółowej lub ogólnej pisemnej zgody Administratora.
              </p>
            </li>
          </ol>
          <ol start="5">
            <li>
              <p align="justify">
                Przekazanie powierzonych danych do państwa trzeciego w rozumieniu RODO może nastąpić jedynie na pisemne
                polecenie Administratora danych chyba, że obowiązek taki nakłada na Podmiot przetwarzający prawo Unii
                lub prawo państwa członkowskiego, któremu podlega Podmiot przetwarzający. W takim przypadku przed
                rozpoczęciem przetwarzania Podmiot przetwarzający informuje Administratora danych o tym obowiązku
                prawnym, o ile prawo to nie zabrania udzielania takiej informacji z uwagi na ważny interes publiczny.
              </p>
            </li>
            <li>
              <p align="justify">
                Podwykonawca, o którym mowa w §5 ust. 3. Umowy winien spełniać te same gwarancje i obowiązki jakie
                zostały nałożone na Podmiot przetwarzający w niniejszej Umowie.
              </p>
            </li>
            <li>
              <p align="justify">
                Podmiot przetwarzający ponosi pełną odpowiedzialność wobec Administratora za niewywiązanie się ze
                spoczywających na podwykonawcy obowiązków ochrony danych.
              </p>
            </li>
          </ol>
          <p align="center">
            <strong>§ 6</strong>
          </p>
          <p align="center">
            <strong>Postanowienia końcowe</strong>
          </p>
          <ol>
            <li>
              <p align="justify">
                Niniejsza Umowa Powierzenia wchodzi w życie z dniem jej zawarcia i obowiązuje przez czas obowiązywania
                Umowy głównej.
              </p>
            </li>
            <li>
              <p align="justify">
                W sprawach nieuregulowanych zastosowanie będą miały przepisy Kodeksu cywilnego oraz Rozporządzenia.
              </p>
            </li>
            <li>
              <p align="justify">
                Sądem właściwym dla rozpatrzenia sporów wynikających z niniejszej umowy będzie sąd właściwy Podmiotu
                przetwarzającego.
              </p>
            </li>
          </ol>
        </div>
        <div className="k-newsModal__checkboxes">
          <div className="k-newsModal__checkbox">
            <Checkbox
              checked={this.state.rules}
              toggle={() => {
                this.setState({ rules: !this.state.rules });
              }}
            />

            <span>
              Zapoznałem/am się i akceptuje{' '}
              <a href="https://kadromierz.pl/wp-content/uploads/2023/08/Kadromierz_regulamin_serwisu.pdf">regulamin</a>{' '}
              serwisu Kadromierz
            </span>
          </div>
          <div className="k-newsModal__checkbox">
            <Checkbox
              checked={this.state.policy}
              toggle={() => {
                this.setState({ policy: !this.state.policy });
              }}
              style={{ float: 'none', display: 'inline-block' }}
            />

            <span>
              Zapoznałem/am się i akceptuje{' '}
              <a href="https://kadromierz.pl/wp-content/uploads/2023/05/Kadromierz_Polityka-prywatnosci.pdf">
                Politykę prywatności
              </a>{' '}
              serwisu Kadromierz
            </span>
          </div>
        </div>

        <Button
          disabled={!this.state.rules || !this.state.policy}
          modifiers={['orange', 'high', 'medium', 'center', 'centerIcon']}
          onClick={() => {
            this.props
              .confirmRegulations(this.props.regulations.id)
              .then(() => {
                this.props.closeModal();
              })
              .catch(err => {
                console.warn('Cannot accept regulations', err);
              });
          }}
        >
          Przejdź do aplikacji
        </Button>
      </div>
    );
  }
}

Regulations.contextTypes = {
  intl: PropTypes.shape({}),
};

Regulations.propTypes = {};

export default Regulations;
