import * as AT from '@/constants/ActionTypes';

export const toggleOpenShiftsRowStickiness = () => ({
  type: AT.TOGGLE_OPEN_SHIFTS_ROW_STICKINESS,
});

export const toggleTradeShiftsRowStickiness = () => ({
  type: AT.TOGGLE_TRADE_SHIFTS_ROW_STICKINESS,
});

export const toggleLoanEmployeesProposalRowStickiness = () => ({
  type: AT.TOGGLE_LOAN_EMPLOYEES_PROPOSAL_ROW_STICKINESS,
});
