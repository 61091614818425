import { CHANGE_SCHEDULE_CHARTS_DATA, CHANGE_SCHEDULE_CHARTS_MAX_VALUES } from '@/constants/ActionTypes.js';

export const changeScheduleChartsData = (data, locationId) => ({
  type: CHANGE_SCHEDULE_CHARTS_DATA,
  payload: data,
  locationId,
});

export const changeScheduleMaxValues = (data, locationId) => ({
  type: CHANGE_SCHEDULE_CHARTS_MAX_VALUES,
  payload: data,
  locationId,
});
