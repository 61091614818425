// LEGACY COMPONENT
// This component is deprecated and should not be used in new code.
// Use Select from kadro/components/common/inputs/MDSelect/Select.jsx instead.
// It's here only for old payroll settings modal.
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';
import Select from 'react-select';

const messages = defineMessages({
  placeholder: {
    id: 'common.selectInput.placeholder',
    defaultMessage: 'Wybierz...',
  },
});

class SelectInput extends Component {
  constructor(props, context) {
    super(props, context);
    let value = null;
    if (props.value) {
      value = props.value.id;
    }
    this.state = {
      extraStyle: '',
      value,
    };
    this.changeFunc = this.changeFunc.bind(this);
    this.focusFunc = this.focusFunc.bind(this);
    this.blurFunc = this.blurFunc.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value.id !== this.state.value) {
      this.setState({
        value: nextProps.value.id,
      });
    }
  }

  focusFunc() {
    this.setState({ extraStyle: 'selected' });
  }

  blurFunc() {
    this.setState({ extraStyle: '' });
  }

  changeFunc(data) {
    this.setState({ value: data.value });
    this.props.onChangeFunc(data);
  }

  render() {
    const { disableScrollMenuIntoView } = this.props;
    const modifiers = this.props.modifiers || [];
    const className = classnames(
      'kadro-select',
      this.state.extraStyle,
      ...modifiers.map(modifier => `kadro-select--${modifier}`),
    );
    return (
      <Select
        onChange={this.changeFunc}
        className={className}
        onFocus={this.focusFunc}
        onBlur={this.blurFunc}
        options={this.props.options}
        scrollMenuIntoView={!disableScrollMenuIntoView}
        placeholder={
          this.props.placeholder ? this.props.placeholder : this.context.intl.formatMessage(messages.placeholder, {})
        }
        noResultsText={this.props.noResults ? this.props.noResults : ''}
        clearable={false}
        defaultValue={this.props.options.find(option => option.value === this.state.value)}
      />
    );
  }
}

// OPTIONS FORMAT
// [  { value: "1", label: "Adam Adamski" }, ...]

SelectInput.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SelectInput.propTypes = {
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  noResults: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disableScrollMenuIntoView: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default SelectInput;
