import moment from 'moment';

import { Employee, EmployeesData, EmployeeWhole } from '@/types';
import { Contracts } from '@/types/contracts.types';
import { UserJobTitle } from '@/types/jobTitles.types';
import { MainDateStore } from '@/types/mainDateStore';
import { Shift, ShiftsData, ShiftToCreate } from '@/types/shifts.types';
import { checkBlocksOverlap } from '@/utils/baseHelpers';
import { getRelevantContractForDate } from '@/utils/contracts';
import { convertDateToStandardFormat } from '@/utils/dateHelper';
import { checkIsEmployeeHired } from '@/utils/employee';
import { isEmptyObject, objectLength } from '@/utils/objectHelpers/objectHelpers';

import {
  QuickPlanningSelected,
  QuickPlanningSpecialSelections,
  QuickPlanningStoreState,
  ReasonsForNotAddShiftInQPEnum,
} from '../../../redux-store/schedule/quickPlanning';

export const getInitialShiftsSummary = (): QuickPlanningStoreState['shiftsSummary'] => ({
  shiftsAfterDateSelection: [],
  shiftsForNotExistingEmployeeId: [],
  shiftsToPaste: [],
  reasonsToNotAddShift: [],
});

const isOnlyOneEmployeeForOneDaySelected = (selectedToCopy: QuickPlanningStoreState['selected']) => {
  const selectedToCopyEmployees = Object.keys(selectedToCopy);
  const isOnlyOneCellSelected =
    selectedToCopyEmployees.length === 1 && Object.keys(selectedToCopy[selectedToCopyEmployees[0]]).length === 1;
  return isOnlyOneCellSelected;
};

const checkIsOnlyOneEmployeeSelected = (selected: QuickPlanningSelected) => Object.keys(selected).length === 1;

export const checkAreAllDaysSelected = (selected: QuickPlanningSelected, dateArray: MainDateStore['dateArray']) =>
  !Object.values(selected).some(datesObj => Object.keys(datesObj).length !== dateArray.length);

const checkIsOnlyOneRowSelectedForAllDays = (selected: QuickPlanningSelected, dateArray: MainDateStore['dateArray']) =>
  checkIsOnlyOneEmployeeSelected(selected) && checkAreAllDaysSelected(selected, dateArray);

export const checkAreAllEmployeesSelected = (
  selected: QuickPlanningSelected,
  sortedVisibleEmployeeIds: Employee['id'][],
) => Object.keys(selected).length === sortedVisibleEmployeeIds.length;

const dayConnector = '#';

const checkAreDatesSelected = (selected: QuickPlanningSelected) => {
  const uniqueDatesJoined = Object.keys(Object.values(selected)[0])?.sort()?.join(dayConnector);
  return !Object.values(selected).some(
    dateBoolOb => !(Object.keys(dateBoolOb).sort().join(dayConnector) === uniqueDatesJoined),
  );
};

const checkIsOnlyOneDaySelected = (selected: QuickPlanningSelected) =>
  Object.keys(Object.values(selected)[0] || {}).length === 1;

const checkIsOnlyOneColumnSelectedForAllEmployees = (
  selected: QuickPlanningSelected,
  sortedVisibleEmployeeIds: Employee['id'][],
) => {
  if (!checkAreAllEmployeesSelected(selected, sortedVisibleEmployeeIds) || !checkIsOnlyOneDaySelected(selected))
    return false;
  return checkAreDatesSelected(selected);
};

const checkIsOneCellWithShiftAndFreeDayCopied = (
  shiftsToCopy: QuickPlanningStoreState['shiftsToCopy'],
  freeDaysToCopy: QuickPlanningStoreState['freeDaysToCopy'],
) => {
  if (objectLength(shiftsToCopy) !== 1 || objectLength(freeDaysToCopy) !== 1) return false;
  const employeeId = Object.keys(shiftsToCopy)?.[0];
  const date = Object.values(shiftsToCopy[employeeId])?.[0]?.date;
  if (!employeeId || !date) return false;
  const freeDayEmployeeId = Object.keys(freeDaysToCopy)?.[0];
  const freeDayDate = Object.values(freeDaysToCopy[freeDayEmployeeId])?.[0]?.date;
  return freeDayEmployeeId === employeeId && freeDayDate === date;
};

export const getSpecialSelections = (
  selected: QuickPlanningSelected,
  dateArray: MainDateStore['dateArray'],
  sortedVisibleEmployeeIds: Employee['id'][],
  shiftsToCopy: QuickPlanningStoreState['shiftsToCopy'],
  freeDaysToCopy: QuickPlanningStoreState['freeDaysToCopy'],
  isCopiedOnlyNotSelectedCell: boolean,
): QuickPlanningSpecialSelections => {
  const noShiftsSelected = isEmptyObject(shiftsToCopy);
  const noFreeDaysSelected = isEmptyObject(freeDaysToCopy);
  const isSingle = isOnlyOneEmployeeForOneDaySelected(selected) || isCopiedOnlyNotSelectedCell;
  const isOnlyOneShiftCopied = isSingle && !noShiftsSelected && noFreeDaysSelected;
  const isOnlyOneFreeDayCopied = isSingle && !noFreeDaysSelected && noShiftsSelected;
  const isSingleShiftAndFreeDayCopied =
    isSingle && checkIsOneCellWithShiftAndFreeDayCopied(shiftsToCopy, freeDaysToCopy);
  const isOnlyOneCellCopied = isOnlyOneFreeDayCopied || isOnlyOneShiftCopied || isSingleShiftAndFreeDayCopied;

  return {
    isOnlyOneShiftCopied: isOnlyOneShiftCopied || isSingleShiftAndFreeDayCopied,
    isOnlyOneRowCopied: !isOnlyOneCellCopied && checkIsOnlyOneRowSelectedForAllDays(selected, dateArray),
    isOnlyOneColumnCopied:
      !isOnlyOneCellCopied && checkIsOnlyOneColumnSelectedForAllEmployees(selected, sortedVisibleEmployeeIds),
    isOnlyOneFreeDayCopied: isOnlyOneFreeDayCopied || isSingleShiftAndFreeDayCopied,
  };
};

const checkIsSuitableLocation = (employees: EmployeesData, employeeId: Employee['id'], selectedShift: Shift) => {
  const { location } = selectedShift;
  const employeeLocations = employees[employeeId].locations.map(({ id }) => id);
  return employeeLocations.includes(location.id);
};

export const checkIsShiftOverlappingWithEmployeeShifts = (
  shiftsData: ShiftsData,
  employeeId: Employee['id'],
  newDate: MainDateStore['dateArray'][number],
  selectedShift: Shift,
) => {
  const employeeShifts = Object.values(shiftsData[employeeId].shifts);
  const [from, to] = selectedShift.working_hours.split('-');
  return employeeShifts.some(shift => {
    const [shiftFrom, shiftTo] = shift.working_hours.split('-');
    return shift.date === newDate && checkBlocksOverlap(from, to, shiftFrom, shiftTo);
  });
};

export const getReasonToNotAddShift = (
  employees: EmployeesData,
  shiftsData: ShiftsData,
  employeeId: Employee['id'],
  newDate: MainDateStore['dateArray'][number],
  selectedShift: Shift,
): ReasonsForNotAddShiftInQPEnum | undefined => {
  if (!checkIsEmployeeHired(employees[employeeId], newDate)) return ReasonsForNotAddShiftInQPEnum.EMPLOYEE_NOT_HIRED;
  if (checkIsShiftOverlappingWithEmployeeShifts(shiftsData, employeeId, newDate, selectedShift))
    return ReasonsForNotAddShiftInQPEnum.EMPLOYEE_HAS_OVERLAPPING_SHIFT;
  if (!checkIsSuitableLocation(employees, employeeId, selectedShift))
    return ReasonsForNotAddShiftInQPEnum.EMPLOYEE_HAS_NOT_LOCATION;
};

const createShift = (
  selectedShift: Shift,
  newDate: MainDateStore['dateArray'][number],
  employeeId: EmployeeWhole['id'],
  employeeContracts: RecordSecondType<Contracts>,
  userJobTitles: UserJobTitle[],
): ShiftToCreate => {
  const { comment, working_hours, job_title, location, date } = selectedShift;
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const employeeJobTitlesIds = (relevantContract?.job_titles || []).map(
    contractJobTitle => contractJobTitle.job_title_id,
  );
  const jobTitle = employeeJobTitlesIds.includes(job_title.id)
    ? job_title
    : userJobTitles.find(j => j.id === employeeJobTitlesIds[0]);

  return {
    date: newDate,
    employee: { id: employeeId },
    job_title: jobTitle,
    comment,
    working_hours,
    draft: true,
    location,
  };
};

const getShiftsToPasteFromOneRowToManyEmployees = (
  selectedEmployeeIds: EmployeeWhole['id'][],
  shiftsToCopy: QuickPlanningStoreState['shiftsToCopy'],
  employees: EmployeesData,
  shiftsData: ShiftsData,
  contracts: Contracts,
  userJobTitles: UserJobTitle[],
): QuickPlanningStoreState['shiftsSummary'] => {
  const result = getInitialShiftsSummary();
  selectedEmployeeIds.forEach(newEmployeeId => {
    Object.values(shiftsToCopy).forEach(employeeShifts => {
      Object.values(employeeShifts).forEach(selectedShift => {
        const { date: newDate } = selectedShift;
        const reason = getReasonToNotAddShift(employees, shiftsData, newEmployeeId, newDate, selectedShift);
        if (reason) {
          result.reasonsToNotAddShift.push(reason);
          return;
        }
        result.shiftsToPaste.push(
          createShift(selectedShift, newDate, employees[newEmployeeId].id, contracts[newEmployeeId], userJobTitles),
        );
      });
    });
  });
  return result;
};

const getShiftsToPasteFromOneColumnToManyDays = (
  shiftsToCopy: QuickPlanningStoreState['shiftsToCopy'],
  selected: QuickPlanningSelected,
  employees: EmployeesData,
  shiftsData: ShiftsData,
  contracts: Contracts,
  userJobTitles: UserJobTitle[],
): QuickPlanningStoreState['shiftsSummary'] => {
  const result = getInitialShiftsSummary();
  const newDates = Object.keys(Object.values(selected)[0]);
  newDates.forEach(newDate => {
    Object.values(shiftsToCopy).forEach(employeeShifts => {
      Object.values(employeeShifts).forEach(selectedShift => {
        const newEmployeeId = selectedShift.employee.id;
        const reason = getReasonToNotAddShift(employees, shiftsData, newEmployeeId, newDate, selectedShift);
        if (reason) {
          result.reasonsToNotAddShift.push(reason);
          return;
        }
        result.shiftsToPaste.push(
          createShift(selectedShift, newDate, employees[newEmployeeId].id, contracts[newEmployeeId], userJobTitles),
        );
      });
    });
  });
  return result;
};

/*
  checkCorrectnessOfRanges - checks if new date and employee id exist in visible schedule
*/
export const checkCorrectnessOfRanges = (
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
  dateArray: MainDateStore['dateArray'],
  newDate: MainDateStore['dateArray'][number],
  employeeId: EmployeeWhole['id'],
) => {
  const isDateCorrect = dateArray.includes(newDate);
  const isEmployeeCorrect = sortedVisibleEmployeeIds.includes(employeeId);
  return { isDateCorrect, isEmployeeCorrect };
};

export const getNewDate = (
  dateArray: MainDateStore['dateArray'],
  currentDateIndex: number,
  datesRanges: QuickPlanningStoreState['datesRanges'],
  selectedShiftDate: MainDateStore['dateArray'][number],
) => {
  const selectedStartDate = dateArray[currentDateIndex];
  const dateDiff = moment(selectedStartDate).diff(datesRanges.minDate, 'd');
  const newDate = convertDateToStandardFormat(new Date(moment(selectedShiftDate).add(dateDiff, 'd').toLocaleString()));
  return newDate;
};

export const getNewEmployeeId = (
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
  oldEmployeeId: EmployeeWhole['id'],
  currentEmployeeIndex: number,
  employeesIdsRanges: QuickPlanningStoreState['employeesIdsRanges'],
) => {
  const employeeDiff = currentEmployeeIndex - employeesIdsRanges.minEmployeeIndex;
  const shiftEmployeeIndex = sortedVisibleEmployeeIds.findIndex(empId => empId === oldEmployeeId);
  const newEmployeeIndex = shiftEmployeeIndex + employeeDiff;
  const employeeId = sortedVisibleEmployeeIds[newEmployeeIndex];
  return employeeId;
};

const getShiftsToPasteFromCustomSelection = (
  shiftsToCopy: QuickPlanningStoreState['shiftsToCopy'],
  employees: EmployeesData,
  shiftsData: ShiftsData,
  contracts: Contracts,
  userJobTitles: UserJobTitle[],
  datesRanges: QuickPlanningStoreState['datesRanges'],
  employeesIdsRanges: QuickPlanningStoreState['employeesIdsRanges'],
  position: QuickPlanningStoreState['position'],
  dateArray: MainDateStore['dateArray'],
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
): QuickPlanningStoreState['shiftsSummary'] => {
  const result = getInitialShiftsSummary();
  const [currentEmployeeIndex, currentDateIndex] = [position.start.y, position.start.x];

  Object.keys(shiftsToCopy).forEach(employeeId => {
    Object.values(shiftsToCopy[employeeId]).forEach(selectedShift => {
      const { date, employee, id } = selectedShift;
      const newDate = getNewDate(dateArray, currentDateIndex, datesRanges, date);
      const newEmployeeId = getNewEmployeeId(
        sortedVisibleEmployeeIds,
        employee.id,
        currentEmployeeIndex,
        employeesIdsRanges,
      );
      const correctness = checkCorrectnessOfRanges(sortedVisibleEmployeeIds, dateArray, newDate, newEmployeeId);
      if (!correctness.isEmployeeCorrect) {
        result.shiftsForNotExistingEmployeeId.push(id);
        return;
      }
      const reason = getReasonToNotAddShift(employees, shiftsData, newEmployeeId, newDate, selectedShift);
      if (reason) {
        result.reasonsToNotAddShift.push(reason);
        return;
      }
      const newShift = createShift(
        selectedShift,
        newDate,
        employees[newEmployeeId].id,
        contracts[newEmployeeId],
        userJobTitles,
      );
      if (!correctness.isDateCorrect) {
        result.shiftsAfterDateSelection.push(newShift);
        return;
      }
      result.shiftsToPaste.push(newShift);
    });
  });

  return result;
};

const getShiftsToPasteToMultipleCells = (
  { selected, shiftsToCopy, position }: QuickPlanningStoreState,
  employees: EmployeesData,
  shiftsData: ShiftsData,
  contracts: Contracts,
  userJobTitles: UserJobTitle[],
): QuickPlanningStoreState['shiftsSummary'] => {
  const result = getInitialShiftsSummary();
  const selectedCellsToPaste = { ...selected };
  if (!Object.keys(selectedCellsToPaste).includes(position.borderCell.employeeId))
    selectedCellsToPaste[position.borderCell.employeeId] = { [position.borderCell.date]: true };
  const selectedShifts = shiftsToCopy[Object.keys(shiftsToCopy)[0]];
  Object.entries(selectedCellsToPaste).forEach(([employeeId, datesObj]) => {
    Object.keys(datesObj).forEach(date => {
      selectedShifts.forEach(currentShift => {
        const reason = getReasonToNotAddShift(employees, shiftsData, employeeId, date, currentShift);
        if (reason) {
          result.reasonsToNotAddShift.push(reason);
          return;
        }
        result.shiftsToPaste.push(
          createShift(currentShift, date, employees[employeeId].id, contracts[employeeId], userJobTitles),
        );
      });
    });
  });
  return result;
};

export const getShiftsToPasteSummary = (
  quickPlanning: QuickPlanningStoreState,
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
  dateArray: MainDateStore['dateArray'],
  employees: EmployeesData,
  shiftsData: ShiftsData,
  contracts: Contracts,
  userJobTitles: UserJobTitle[],
): QuickPlanningStoreState['shiftsSummary'] => {
  const { shiftsToCopy, datesRanges, employeesIdsRanges, position, selected, specialSelections } = quickPlanning;

  if (specialSelections.isOnlyOneShiftCopied)
    return getShiftsToPasteToMultipleCells(quickPlanning, employees, shiftsData, contracts, userJobTitles);

  if (specialSelections.isOnlyOneColumnCopied && checkAreAllEmployeesSelected(selected, sortedVisibleEmployeeIds))
    return getShiftsToPasteFromOneColumnToManyDays(
      shiftsToCopy,
      selected,
      employees,
      shiftsData,
      contracts,
      userJobTitles,
    );

  if (specialSelections.isOnlyOneRowCopied && checkAreAllDaysSelected(selected, dateArray))
    return getShiftsToPasteFromOneRowToManyEmployees(
      Object.keys(selected),
      shiftsToCopy,
      employees,
      shiftsData,
      contracts,
      userJobTitles,
    );

  return getShiftsToPasteFromCustomSelection(
    shiftsToCopy,
    employees,
    shiftsData,
    contracts,
    userJobTitles,
    datesRanges,
    employeesIdsRanges,
    position,
    dateArray,
    sortedVisibleEmployeeIds,
  );
};
