import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import FullScreenLoader from '@/components/misc/FullScreenLoader.jsx';
import AuthenticatedApp from '@/containers/AuthenticatedAppContainer.js';
import NotificationBar from '@/containers/common/NotificationBarContainer.js';
import MainViewLogin from '@/containers/MainViewLogin';
import NewsBar from '@/containers/news/NewsBarContainer.js';
import { useLocationChange } from '@/hooks/useLocationChange/useLocationChange';

import ConfirmModal from './common/ConfirmModal/ConfirmModal.redux.js';
import DisabledAccountModal from './common/DisabledAccountModal.jsx';
import OfflineMessage from './common/OfflineMessage.jsx';
import ShadowLoader from './misc/ShadowLoader/ShadowLoader.redux';
import TrialInfoTopBar from './TrialInfoTopBar/TrialInfoTopBar.redux.js';

const MainApp = props => {
  useLocationChange();
  const onExitFunc = async () => beforeExit();

  useEffect(() => {
    window.addEventListener('beforeunload', onExitFunc);

    props.getGeolocation();

    return () => {
      window.removeEventListener('beforeunload', onExitFunc);
    };
  }, []);

  const beforeExit = async () => {
    await props.saveAllViewSettings();
    return null;
  };

  let viewToRender = <MainViewLogin />;
  if (props.showFullScreenLoader) {
    viewToRender = <FullScreenLoader />;
  } else if (props.currentUser && props.currentUser.authenticated) {
    if (props.currentUser.disabled) {
      viewToRender = (
        <div>
          <DisabledAccountModal show />
        </div>
      );
    } else {
      viewToRender = (
        <AuthenticatedApp>
          <NotificationBar />
          <NewsBar />
          <TrialInfoTopBar />
          <ConfirmModal />
          <Outlet />
        </AuthenticatedApp>
      );
    }
  }
  return (
    <div>
      <OfflineMessage show={props.showOfflineMsg} />
      <ShadowLoader />
      {viewToRender}
    </div>
  );
};

MainApp.propTypes = {
  currentUser: PropTypes.shape({
    authenticated: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  saveAllViewSettings: PropTypes.func,
  showFullScreenLoader: PropTypes.bool,
  showOfflineMsg: PropTypes.bool,
  getGeolocation: PropTypes.func,
};

export default MainApp;
