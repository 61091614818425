import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { COMPANY_MANAGE_LOCATIONS_HIDE_ASSIGN } from '@/constants/Restrictions.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import { MDMultiSelect, MDMultiSelectFooter, MDMultiSelectHeader } from './inputs/MDComponents.jsx';

class AttachEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.getPreselected() };
    bindPrototypeFunctions(this);
  }

  getPreselected() {
    if (!this.props.item) {
      return this.props.preSelected ? this.props.preSelected : [];
    }
    switch (this.props.attachTo) {
      case 'locations':
        return this.props.userEmployees
          .filter(employee => employee.locations.some(location => location.id === this.props.item.id))
          .map(employee => employee.id);
      default:
        break;
    }
    return [];
  }

  getChanges() {
    const old = this.props.item ? this.getPreselected() : [];
    const selected = Array.from(this.state.selected);

    const toAdd = selected
      .filter(emp => !old.includes(emp))
      .map(e => this.props.userEmployees.find(employee => employee.id === e));
    const toDelete = old
      .filter(emp => !selected.includes(emp))
      .map(e => this.props.userEmployees.find(employee => employee.id === e));

    return { toAdd, toDelete };
  }

  handleChangeList(e) {
    if (this.state.selected.includes(e.value)) {
      this.setSelected(this.state.selected.filter(id => id !== e.value));
    } else {
      this.setSelected([...this.state.selected, e.value]);
    }
  }

  selectAll() {
    const relevantItemsId = this.props.userEmployees.reduce(
      (list, employee) => (employee.inactive ? list : [...list, employee.id]),
      [],
    );
    this.setSelected(relevantItemsId);
  }

  deselectAll() {
    this.setSelected([]);
  }

  setSelected(selected) {
    this.setState({ selected });
  }

  getSelectedItems() {
    return this.getRelevantItems().reduce(
      (list, employee) =>
        this.state.selected.includes(employee.id)
          ? [
              ...list,
              {
                label: `${employee.first_name} ${employee.last_name}`,
                value: employee.id,
              },
            ]
          : list,
      [],
    );
  }

  getRelevantItems() {
    return this.props.userEmployees.filter(employee => !employee.inactive);
  }

  render() {
    const relevantItems = this.getRelevantItems();
    const isEditDisabled = this.props.userPermissions.restrictions.includes(COMPANY_MANAGE_LOCATIONS_HIDE_ASSIGN);

    return (
      <>
        <MDMultiSelectHeader
          title={<FormattedMessage id="common.attachEmployee.header" defaultMessage="Przypisani pracownicy" />}
          selectAll={this.selectAll}
          isSelectAllDisabled={isEditDisabled}
          deselectAll={this.deselectAll}
          isDeselectAllDisabled={isEditDisabled}
          selectAllMessage={<FormattedMessage id="common.attachEmployee.selectAllButton" defaultMessage="Wszyscy" />}
          deselectAllMessage={<FormattedMessage id="common.attachEmployee.deselectAllButton" defaultMessage="Żaden" />}
        />
        <MDMultiSelect
          label={<FormattedMessage id="common.attachEmployee.chooseEmployees" defaultMessage="Wybierz pracowników:" />}
          disabled={isEditDisabled}
          options={relevantItems.map(employee => ({
            label: `${employee.first_name} ${employee.last_name}`,
            value: employee.id,
            active: this.state.selected.includes(employee.id),
          }))}
          onChange={this.handleChangeList}
        />
        <MDMultiSelectFooter
          disabled={isEditDisabled}
          selectedItems={this.getSelectedItems()}
          deleteItem={this.handleChangeList}
        />
      </>
    );
  }
}

AttachEmployee.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
  }),
  attachTo: PropTypes.string,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  preSelected: PropTypes.arrayOf(PropTypes.string),
};

export default AttachEmployee;
