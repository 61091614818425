export const formatShiftForTemplates = shift => ({
  working_hours: shift.working_hours,
  amount: shift.amount,
  date: shift.date,
  job_title_id: shift.job_title_id || shift.job_title?.id,
  draft: shift.draft,
  gid: shift.gid,
});

export const formatOpenShiftForTemplates = openShift => ({
  working_hours: openShift.working_hours,
  amount: openShift.shifts_remaining,
  date: new Date(openShift.date).getDay(),
  job_title_id: openShift.job_title.id,
  draft: false,
  openShiftId: openShift.id,
});

export const countTemplatesShiftsToSave = (templatesShifts, selectedJobTitleIds) =>
  templatesShifts.reduce((uniqueShifts, shift) => {
    const uniqueSequnce = `${shift.job_title_id};${shift.working_hours};${shift.date}`;
    return shift.draft === true &&
      selectedJobTitleIds.includes(shift.job_title_id) &&
      !uniqueShifts.includes(uniqueSequnce)
      ? [...uniqueShifts, uniqueSequnce]
      : uniqueShifts;
  }, []).length;
