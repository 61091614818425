import { FormattedMessage } from 'react-intl';

import {
  absencesView,
  attendanceView,
  availabilityView,
  chatView,
  companyManageView,
  dashboardView,
  employeePayrollView,
  exportsView,
  newPayrollLocationView,
  payrollLocationView,
  payrollView,
  productionQuotasView,
  reportsNNView,
  reportsView,
  scheduleView,
  settingsView,
  startView,
  timesheetView,
} from '@/actions/views';
import {
  EMPLOYEE_SCHEDULE_EDIT_ENABLE,
  NEW_REPORTS_VIEW,
  OLD_PAYROLL_VIEW,
  OLD_REPORTS_VIEW,
  PAYROLL_VIEW_GET,
  PRODUCTION_QUOTAS_GET,
  SHORTER_ABSENCE_NAME,
  TALKJS_CHAT_ENABLE,
} from '@/constants/Permissions.js';
import {
  ALL_SETTINGS_VIEW_HIDE,
  ATTENDANCE_VIEW_HIDE,
  AVAILABILITIES_VIEW_HIDE,
  BUDGET_INFO_HIDE,
  COMPANY_MANAGE_VIEW_HIDE,
  DASHBOARD_VIEW_HIDE,
  EMPLOYEE_PAYROLL_VIEW_HIDE,
  EMPLOYEES_CODE_NAMES,
  EXPORT_VIEW_HIDE,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
  FREEMIUM_HIDE_REPORTS_VIEW,
  MANAGER_VIEW_EMPLOYEES_PAYROLL,
  NOTIFICATIONS_VIEW_HIDE,
  OLD_PAYROLL_HIDE,
  PAYROLL_LOCATION_VIEW_HIDE,
  PAYROLL_VIEW_HIDE,
  PRODUCTION_QUOTAS_VIEW_HIDE,
  REPORTS_VIEW_HIDE,
  SCHEDULE_VIEW_HIDE,
} from '@/constants/Restrictions.js';
import { TRIAL_STATUS } from '@/constants/status.js';
import { UserPermissionsStoreState } from '@/redux-store/userPermissions/userPermissions.types.ts';

import { canBeAccessedAbsencesView } from '../absenceHelpers.js';
import { companyManageRoutes } from './companyManageRoutes.tsx';
import { exportsRoutes } from './exportsRoutes.tsx';
import { NavigationItem } from './routes.types.ts';

export const TRIAL_PATH = '/trial';
export const EXPORTS_PATH = '/exports';
export const DASHBOARD_PATH = '/';
export const COMPANY_MANAGE_PATH = '/companymanage';
export const SCHEDULE_PATH = '/schedule';
export const AVAILABILITIES_PATH = '/availabilities';
export const ATTENDANCE_PATH = '/attendance';
export const ABSENCES_PATH = '/absences';
export const TIMESHEET_PATH = '/timesheet';
export const PAYROLL_PATH = '/payroll';
export const PAYROLL_LOCATION_PATH = '/payroll_location';
export const NEW_PAYROLL_PATH = '/newPayroll';
export const EMPLOYEE_PAYROLL_PATH = '/employee-payroll';
export const NEW_PAYROLL_LOCATION_PATH = '/newPayrollLocation';
export const REPORTS_PATH = '/reports';
export const REPORTS_V2_PATH = '/reports-v2';
export const CHAT_PATH = '/chat';
export const SETTINGS_PATH = '/settings';
export const SETTINGS_USER_DATA_PATH = `${SETTINGS_PATH}/user-data`;
export const NOTIFICATIONS_PATH = '/notifications';
export const PRODUCTION_QUOTAS_PATH = '/production_quotas';
export const ONBOARDING_STEP_ONE_PATH = '/onboarding/stepOne';

export const ROUTE_IDS = {
  DASHBOARD: 'DASHBOARD',
  TRIAL: 'TRIAL',
  EXPORT: 'EXPORT',
  CHAT: 'CHAT',
  COMPANY_MANAGE: 'COMPANY_MANAGE',
  SCHEDULE: 'SCHEDULE',
  AVAILABILITIES: 'AVAILABILITIES',
  ATTENDANCE: 'ATTENDANCE',
  ABSENCES: 'ABSENCES',
  TIMESHEET: 'TIMESHEET',
  PAYROLL: 'PAYROLL',
  PAYROLL_LOCATION: 'PAYROLL_LOCATION',
  NEW_PAYROLL_LOCATION: 'NEW_PAYROLL_LOCATION',
  REPORTS: 'REPORTS',
  REPORTS_V2: 'REPORTS_V2',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SETTINGS: 'SETTINGS',
  PRODUCTION_QUOTAS: 'PRODUCTION_QUOTAS',
  EMPLOYEE_PAYROLL: 'EMPLOYEE_PAYROLL',
} as const;

export const OLD_REPORTS_VIEW_PERMISSIONS = [NEW_REPORTS_VIEW, OLD_REPORTS_VIEW];

export const FIRST_SECTION_NAVS_ID: NavigationItem['id'][] = [ROUTE_IDS.EXPORT, ROUTE_IDS.CHAT];

export const allNavs = (permissions: UserPermissionsStoreState['permissions'] = []): NavigationItem[] => [
  {
    id: ROUTE_IDS.TRIAL,
    name: <FormattedMessage id="common.startPage" defaultMessage="Strona startowa" />,
    path: TRIAL_PATH,
    icon: 'home',
    selected: false,
    accessCheckFunction: (_, user, company) => user?.role === 'owner' && company?.status.status === TRIAL_STATUS,
    updateFunc: startView,
  },
  {
    id: ROUTE_IDS.EXPORT,
    name: <FormattedMessage id="common.exports" defaultMessage="Eksporty" />,
    icon: 'insert_drive_file',
    customIcon: true,
    path: EXPORTS_PATH,
    selected: false,
    restriction: [EXPORT_VIEW_HIDE],
    updateFunc: exportsView,
    subRoutes: exportsRoutes,
  },
  {
    id: ROUTE_IDS.DASHBOARD,
    name: <FormattedMessage id="common.dashboard" defaultMessage="Pulpit" />,
    icon: 'dashboard',
    path: DASHBOARD_PATH,
    selected: true,
    restriction: [DASHBOARD_VIEW_HIDE],
    updateFunc: dashboardView,
  },
  {
    id: ROUTE_IDS.COMPANY_MANAGE,
    name: <FormattedMessage id="common.companymanage" defaultMessage="Moja firma" />,
    icon: 'location_city',
    path: COMPANY_MANAGE_PATH,
    selected: false,
    restriction: [COMPANY_MANAGE_VIEW_HIDE, EMPLOYEES_CODE_NAMES],
    subRoutes: companyManageRoutes,
    updateFunc: companyManageView,
  },
  {
    id: ROUTE_IDS.SCHEDULE,
    name: <FormattedMessage id="common.schedule" defaultMessage="Grafik pracy" />,
    icon: 'today',
    path: SCHEDULE_PATH,
    selected: false,
    restriction: [SCHEDULE_VIEW_HIDE],
    updateFunc: scheduleView,
  },
  {
    id: ROUTE_IDS.AVAILABILITIES,
    name: <FormattedMessage id="common.availability" defaultMessage="Dostępności" />,
    icon: 'sort',
    path: AVAILABILITIES_PATH,
    selected: false,
    restriction: [AVAILABILITIES_VIEW_HIDE],
    updateFunc: availabilityView,
  },
  {
    id: ROUTE_IDS.ATTENDANCE,
    name: <FormattedMessage id="common.attendance" defaultMessage="Lista Obecności" />,
    icon: 'event_available',
    path: ATTENDANCE_PATH,
    selected: false,
    restriction: [ATTENDANCE_VIEW_HIDE, FREEMIUM_HIDE_ATTENDANCE_VIEW],
    updateFunc: attendanceView,
  },
  {
    id: ROUTE_IDS.ABSENCES,
    name: permissions.includes(SHORTER_ABSENCE_NAME) ? (
      <FormattedMessage id="common.absencesAndTrainig" defaultMessage="Absencje/Szkolenia PSD" />
    ) : (
      <FormattedMessage id="common.absencesAndDelegations" defaultMessage="Urlopy i delegacje" />
    ),
    icon: 'airplanemode_active',
    path: ABSENCES_PATH,
    selected: false,
    accessCheckFunction: canBeAccessedAbsencesView,
    restriction: [],
    updateFunc: absencesView,
  },
  {
    id: ROUTE_IDS.TIMESHEET,
    name: <FormattedMessage id="common.timesheet" defaultMessage="Rejestr godzin" />,
    icon: 'view_list',
    path: TIMESHEET_PATH,
    selected: false,
    restriction: [],
    userPermission: [EMPLOYEE_SCHEDULE_EDIT_ENABLE],
    updateFunc: timesheetView,
  },
  {
    id: ROUTE_IDS.PAYROLL,
    name: <FormattedMessage id="common.payroll" defaultMessage="Karta Pracownika" />,
    icon: 'receipt',
    path: PAYROLL_PATH,
    selected: false,
    restriction: [PAYROLL_VIEW_HIDE, OLD_PAYROLL_HIDE],
    userPermission: [PAYROLL_VIEW_GET, OLD_PAYROLL_VIEW],
    updateFunc: payrollView,
    allPermissionsRequired: true,
  },
  {
    id: ROUTE_IDS.PAYROLL_LOCATION,
    name: <FormattedMessage id="common.payrollLocation" defaultMessage="Wypłaty" />,
    icon: 'attach_money',
    path: PAYROLL_LOCATION_PATH,
    selected: false,
    restriction: [PAYROLL_LOCATION_VIEW_HIDE, MANAGER_VIEW_EMPLOYEES_PAYROLL, OLD_PAYROLL_HIDE],
    userPermission: [OLD_PAYROLL_VIEW],
    updateFunc: payrollLocationView,
  },
  {
    id: ROUTE_IDS.NEW_PAYROLL_LOCATION,
    name: <FormattedMessage id="common.newPayrollLocation" defaultMessage="Ewidencja czasu pracy" />,
    icon: 'format_list_bulleted',
    path: NEW_PAYROLL_LOCATION_PATH,
    selected: false,
    restriction: [PAYROLL_LOCATION_VIEW_HIDE, MANAGER_VIEW_EMPLOYEES_PAYROLL],
    updateFunc: newPayrollLocationView,
  },
  {
    id: ROUTE_IDS.EMPLOYEE_PAYROLL,
    name: <FormattedMessage id="navigation.employeePayroll.viewName" defaultMessage="Ewidencja czasu pracy" />,
    icon: 'format_list_bulleted',
    path: EMPLOYEE_PAYROLL_PATH,
    selected: false,
    restriction: [EMPLOYEE_PAYROLL_VIEW_HIDE],
    updateFunc: employeePayrollView,
    accessCheckFunction: ({ restrictions, isEmployee }, _user, company) =>
      company?.settings?.employee_ecp_enabled && (restrictions.includes(PAYROLL_LOCATION_VIEW_HIDE) || isEmployee),
  },
  {
    id: ROUTE_IDS.REPORTS,
    name: <FormattedMessage id="common.reports" defaultMessage="Raporty" />,
    icon: 'show_chart',
    path: REPORTS_PATH,
    beta: true,
    selected: false,
    restriction: [REPORTS_VIEW_HIDE, FREEMIUM_HIDE_REPORTS_VIEW],
    accessCheckFunction: permissions =>
      permissions?.permissions.some(permission => OLD_REPORTS_VIEW_PERMISSIONS.includes(permission)),
    updateFunc: reportsView,
    additionalLabel: permissions.includes(NEW_REPORTS_VIEW) ? 'BETA' : null,
  },
  {
    id: ROUTE_IDS.REPORTS_V2,
    name: <FormattedMessage id="common.reports-v2" defaultMessage="Raporty" />,
    icon: 'show_chart',
    path: REPORTS_V2_PATH,
    beta: true,
    selected: false,
    updateFunc: reportsNNView,
    restriction: [REPORTS_VIEW_HIDE, FREEMIUM_HIDE_REPORTS_VIEW],
    accessCheckFunction: ({ permissions }) =>
      !permissions.some(permission => OLD_REPORTS_VIEW_PERMISSIONS.includes(permission)),
  },
  {
    id: ROUTE_IDS.CHAT,
    name: <FormattedMessage id="common.companyChat" defaultMessage="Czat firmowy" />,
    icon: 'chat',
    path: CHAT_PATH,
    selected: false,
    userPermission: [TALKJS_CHAT_ENABLE],
    updateFunc: chatView,
  },
  {
    id: ROUTE_IDS.SETTINGS,
    name: <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />,
    icon: 'settings',
    path: SETTINGS_PATH,
    selected: false,
    restriction: [ALL_SETTINGS_VIEW_HIDE],
    updateFunc: settingsView,
  },
  {
    id: ROUTE_IDS.NOTIFICATIONS,
    name: <FormattedMessage id="common.notifications" defaultMessage="Notyfikacje" />,
    path: NOTIFICATIONS_PATH,
    selected: false,
    restriction: [NOTIFICATIONS_VIEW_HIDE],
  },
  {
    id: ROUTE_IDS.PRODUCTION_QUOTAS,
    name: <FormattedMessage id="common.productionQuotas" defaultMessage="Prace akordowe" />,
    icon: 'assignment_ind',
    path: PRODUCTION_QUOTAS_PATH,
    selected: false,
    restriction: [PRODUCTION_QUOTAS_VIEW_HIDE],
    userPermission: [PRODUCTION_QUOTAS_GET],
    updateFunc: productionQuotasView,
  },
];

export const employeeRestrictions = [
  COMPANY_MANAGE_VIEW_HIDE,
  ATTENDANCE_VIEW_HIDE,
  PAYROLL_LOCATION_VIEW_HIDE,
  REPORTS_VIEW_HIDE,
  PRODUCTION_QUOTAS_VIEW_HIDE,
  BUDGET_INFO_HIDE,
  EXPORT_VIEW_HIDE,
];

export const managerRestrictions = [AVAILABILITIES_VIEW_HIDE];

export const managerPermissions = [PAYROLL_VIEW_GET];
