import { connect } from 'react-redux';

import { editShift, massDeleteShifts, massEditShiftsForPayroll } from '@/actions';
import {
  changeAttendance,
  handleMultipleBreaksActions,
  massChangeAttendance,
  massDeleteAttendances,
} from '@/actions/attendances.js';
import { hideModal } from '@/actions/uiState.js';

import PayrollEditShiftsAndAttendancesModal from './PayrollEditModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === 'EDIT_SHIFTS_AND_ATTENDANCES_MODAL',
  modalObject: state.reducer.uiState.modalObject.EDIT_SHIFTS_AND_ATTENDANCES_MODAL,
  userEmployees: state.reducer.userEmployees,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  hideModal,
  editShift,
  massDeleteAttendances,
  massEditShifts: massEditShiftsForPayroll,
  massChangeAttendance,
  handleMultipleBreaksActions,
  changeAttendance,
  massDeleteShifts,
};

const PayrollEditShiftsAndAttendancesModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayrollEditShiftsAndAttendancesModal);

export default PayrollEditShiftsAndAttendancesModalContainer;
