import PropTypes from 'prop-types';

import {
  KadroDynamicList,
  KadroListSelect,
  MDCheckbox,
  MDRadiosWithSublabel,
} from '@/components/common/inputs/MDComponents.jsx';
import { EXPORT_OPTIONS } from '@/constants/exportConstants.js';
import { createEvent } from '@/utils/inputHelpers.js';

const getMultiSelectItemsAndHandler = (multiSelects, dataKey) => {
  switch (dataKey) {
    case 'absenceTypes':
      return {
        items: multiSelects.absenceTypesItems,
        handlers: multiSelects.absenceTypesHandlers,
      };
    default:
      return {
        items: multiSelects.columnsItems,
        handlers: multiSelects.columnsSelectionHandlers,
      };
  }
};

const ExportConfigurationStep = ({ exportOptions, options, handlers, multiSelects }) => {
  const handleCheckbox = e => {
    const { id, checked } = e.target;
    handlers.changeInput(createEvent('options', { ...options, [id]: checked }));
  };

  const handleRadio = e => {
    const { target, name } = e;
    handlers.changeInput(createEvent('options', { ...options, [name]: target.value }));
  };

  const handleDynamicList = e => {
    const { value, name } = e.target;
    handlers.changeInput(createEvent('options', { ...options, [name]: value }));
  };

  return (
    <>
      {exportOptions.map(option => {
        const { type, id, text, tooltip, values, messages, dataKey } = option;
        switch (type) {
          case EXPORT_OPTIONS.CHECKBOX: {
            return (
              <MDCheckbox
                key={id}
                id={id}
                text={text}
                value={options[id]}
                onChange={handleCheckbox}
                tooltip={tooltip}
                reverse
              />
            );
          }
          case EXPORT_OPTIONS.RADIO: {
            return (
              <MDRadiosWithSublabel
                options={values}
                selectedItemId={options[id] || values[0].id}
                onChange={handleRadio}
                key={id}
                radiosId={id}
              />
            );
          }
          case EXPORT_OPTIONS.LIST_SELECTION: {
            const { items, handlers: multiSelectHandlers } = getMultiSelectItemsAndHandler(multiSelects, dataKey);
            return <KadroListSelect key={id} handlers={multiSelectHandlers} items={items} customHeight="250px" />;
          }
          case EXPORT_OPTIONS.DYNAMIC_LIST: {
            return (
              <KadroDynamicList
                key={id}
                id={id}
                messages={messages}
                items={options[id]}
                handleChange={handleDynamicList}
              />
            );
          }
          default:
            return null;
        }
      })}
    </>
  );
};

ExportConfigurationStep.propTypes = {
  exportOptions: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    tooltip: PropTypes.string,
    payoutSettingType: PropTypes.bool,
    text: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        active: PropTypes.bool,
      }),
    ),
    dataKey: PropTypes.string,
  }),
  handlers: PropTypes.shape({
    changeInput: PropTypes.func,
  }),
  options: PropTypes.shape({}),
  multiSelects: PropTypes.shape({}),
};

export default ExportConfigurationStep;
