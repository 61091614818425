export const inputsModifiers = ['modal'];

export const getInitialState = user => {
  const prefix = user.phone_prefix || '48';
  return {
    name: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone,
    accountUsesOAuth: !!user.oauth_provider,
    phone_prefix: prefix,
    errors: {},
  };
};

export const createNewUserSettingsObject = ({ name, lastName, email, phone, phone_prefix: phonePrefix }) => ({
  first_name: name,
  last_name: lastName,
  email,
  phone: phone === '' ? null : phone,
  phone_prefix: phonePrefix,
});
