import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { deleteShiftsByIds } from '@/actions/schedule/shifts';
import { hideModal } from '@/actions/uiState';
import { DELETE_SCHEDULE_ITEMS_MODAL } from '@/constants/modalTypes';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import { deleteFreeDays } from '@/redux-store/freeDaysMarking';
import { selectIsModalOpen, selectModalObject } from '@/selectors/uiState.selectors';

import { DeleteScheduleItemEnum, DeleteScheduleItemsModalObject } from './DeleteScheduleItemsModal.types';
import { getCheckboxList } from './DeleteScheduleItemsModal.utils';

export const useDeleteScheduleItemsModal = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen(DELETE_SCHEDULE_ITEMS_MODAL));
  const modalObject = useAppSelector(selectModalObject<DeleteScheduleItemsModalObject>(DELETE_SCHEDULE_ITEMS_MODAL));
  const checkboxList = useMemo(() => (modalObject ? getCheckboxList(modalObject, intl) : []), [modalObject, intl]);
  const [items, setItems] = useState<DeleteScheduleItemEnum[]>([]);

  const onCloseModal = useCallback(() => {
    setItems([]);
    dispatch(hideModal());
  }, [dispatch, setItems]);

  const onToggleItem = useCallback(
    (itemId: DeleteScheduleItemEnum) => {
      setItems(prevItems => {
        if (prevItems.includes(itemId)) return prevItems.filter(prevItem => prevItem !== itemId);
        return [...prevItems, itemId];
      });
    },
    [setItems],
  );

  const onSubmit = useCallback(() => {
    const { freeDayIds, shiftIds } = modalObject;
    if (items.includes(DeleteScheduleItemEnum.FREE_DAYS_MARKINGS)) dispatch(deleteFreeDays(freeDayIds));
    if (items.includes(DeleteScheduleItemEnum.SHIFTS)) dispatch(deleteShiftsByIds(shiftIds));
    onCloseModal();
  }, [dispatch, items, modalObject, onCloseModal]);

  return { checkboxList, isModalOpen, intl, items, onCloseModal, onSubmit, onToggleItem };
};
