import PropTypes from 'prop-types';

import { MDSelect, MDTextArea, MDTextInput, MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import { MODAL } from '@/constants/buttonModifiers';
import { NewProposalData } from '@/types/loanEmployeesProposals';

import { ProposalHandlers } from '../../outgoingLoanEmployeesProposalModal/OutgoingLoanEmployeesProposalModal.helpers';
import { messages } from './LoanEmployeesProposalEditStep.messages';

interface LoanEmployeesProposalEditStepProps {
  state: NewProposalData;
  handlers: ProposalHandlers;
  selectedJobTitles: { id: string; title: string }[];
}

const LoanEmployeesProposalEditStep = (
  { state, handlers, selectedJobTitles }: LoanEmployeesProposalEditStepProps,
  { intl },
) => (
  <>
    <MDSelect
      name={intl.formatMessage(messages.jobTitle)}
      defaultValue={state.selectedJobTitleId}
      options={selectedJobTitles.map(jobTitle => ({
        key: jobTitle.title,
        value: jobTitle.id,
      }))}
      onChange={handlers.changeJobTitle}
      withSearch
      closeOnClick
    />
    <MDTextInput
      id="employees_required"
      type="number"
      label={intl.formatMessage(messages.employeesRequired)}
      value={state.employees_required}
      onChange={handlers.handleInputChange}
      modifiers={MODAL}
      isNumberInteger
    />
    <MDTimeRangeInput
      value={state.working_hours}
      onChange={handlers.changeWorkingHours}
      label={intl.formatMessage(messages.workingHours)}
      modifiers={MODAL}
    />
    <MDTextArea
      label={intl.formatMessage(messages.note)}
      placeholder={intl.formatMessage(messages.notePlaceholder)}
      value={state.comment}
      onChange={handlers.handleInputChange}
      id="comment"
      name="comment"
    />
  </>
);

LoanEmployeesProposalEditStep.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default LoanEmployeesProposalEditStep;
