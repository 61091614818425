import classnames from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({ checked, disabled, toggle, style, className, label, testId, name }) => {
  const checkboxClassNames = classnames('checkbox', 'checkbox-inline', 'table-checkbox', className);
  return (
    <div className="checkboxContainer">
      <div className={checkboxClassNames} style={style}>
        <input
          id="checkbox"
          type="checkbox"
          className="styled"
          checked={checked}
          onChange={toggle}
          disabled={disabled}
          data-test={testId}
          name={name}
        />
        <label htmlFor="checkbox">{label}</label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  testId: PropTypes.string,
};

export default Checkbox;
