import { connect } from 'react-redux';

import { payForInvoice } from '@/actions/payments';

import Component from './UnlockAccountViewPayment.jsx';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  payForInvoice,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
