import { connect } from 'react-redux';

import { hideModal, replaceModal } from '@/actions/uiState';
import { ABSENCE_CALENDAR_MODAL } from '@/constants/modalTypes';

import AbsenceCalendarModal from './AbsenceCalendarModal';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ABSENCE_CALENDAR_MODAL,
  modalObject: state.reducer.uiState.modalObject[ABSENCE_CALENDAR_MODAL],
  year: state.reducer.mainDateStore.dateText,
});

const mapDispatchToProps = {
  hideModal,
  replaceModal,
};

const AbsenceCalendarModalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceCalendarModal);

export default AbsenceCalendarModalContainer;
