import { conn } from '@/actions';
import * as AT from '@/constants/ActionTypes';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { messages } from './payrollEnovaExports.messages';

const noDataToExport = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EXPORT_ENOVA_NO_DATA_TO_EXPORT,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: intl.formatMessage(messages.noDataToExport),
      type: 'error',
    },
  });
};

const exportEnovaAttendancesError = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EXPORT_ENOVA_ATTENDANCES_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: intl.formatMessage(messages.exportEnovaAttendancesError),
      type: 'error',
    },
  });
};

const exportEnovaAttendancesSuccess = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EXPORT_ENOVA_ATTENDANCES_SUCCESS,
    payload: {},
    notification: {
      description: intl.formatMessage(messages.exportEnovaAttendancesSuccess),
      type: 'success',
    },
  });
};

export const exportEnovaAttendances = employeeIds => async (dispatch, getState) => {
  const { multipleLocationFilter: locationIds, mainDateStore } = getState().reducer;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  if (!employeeIds.length || !locationIds.length) return dispatch(noDataToExport());
  try {
    await conn.exportEnovaAttendances(employeeIds, locationIds, from, to);
    dispatch(exportEnovaAttendancesSuccess());
  } catch (err) {
    dispatch(exportEnovaAttendancesError());
  }
};

const exportEnovaScheduleError = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EXPORT_ENOVA_SCHEDULE_ERROR,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: intl.formatMessage(messages.exportEnovaScheduleError),
      type: 'error',
    },
  });
};

const exportEnovaScheduleSuccess = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EXPORT_ENOVA_SCHEDULE_SUCCESS,
    payload: {},
    notification: {
      description: intl.formatMessage(messages.exportEnovaScheduleSuccess),
      type: 'success',
    },
  });
};

export const exportEnovaScheduleWork = employeeIds => async (dispatch, getState) => {
  const { multipleLocationFilter: locationIds, mainDateStore } = getState().reducer;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  if (!employeeIds.length || !locationIds.length) return dispatch(noDataToExport());
  try {
    await conn.exportEnovaSchedule(employeeIds, locationIds, from, to);
    dispatch(exportEnovaScheduleSuccess());
  } catch (err) {
    dispatch(exportEnovaScheduleError());
  }
};
