import { weeklyWorkingHours } from '@/constants/employmentConditions';
import { EmployeesData, EmployeeWhole } from '@/types';
import { EmployeeGroupingEnum } from '@/types/employeeGrouping';
import { EmploymentCondition } from '@/types/employmentConditions.types';
import { UserJobTitle } from '@/types/jobTitles.types';
import { sortNumbersDesc } from '@/utils/array/array.helpers';

import { GroupingType, GroupNodeType } from './GroupedEmployees.types';

const separator = '$#$';

const encodeGroupNodeId = (groupId: GroupNodeType['groupId'], groupKey: GroupNodeType['groupKey']) =>
  `${groupId}${separator}${groupKey}`;

export const decodeGroupNodeId = (id: Nullable<GroupNodeType['id']>) => {
  const [groupId, groupKey] = (id || '').split(separator);
  return { groupId, groupKey };
};

const defaultSortProperties = (properties: string[]) => properties.sort();

const sortJobTitles = (userJobTitlesDict: Record<UserJobTitle['id'], UserJobTitle>) => (properties: string[]) =>
  properties.sort((a, b) => userJobTitlesDict[a]?.title.localeCompare(userJobTitlesDict[b]?.title));

const sortEmploymentConditions =
  (employmentConditionsDict: Record<EmploymentCondition['id'], EmploymentCondition>) => (properties: string[]) =>
    properties.sort((a, b) => employmentConditionsDict[a]?.name.localeCompare(employmentConditionsDict[b]?.name));

const getGroupingOptions = (
  userJobTitlesDict: Record<UserJobTitle['id'], UserJobTitle>,
  employmentConditionsDict: Record<EmploymentCondition['id'], EmploymentCondition>,
  selectedJobTitlesIds: UserJobTitle['id'][],
): Record<EmployeeGroupingEnum, GroupingType> => {
  const minWorkingHours = (Math.min(...weeklyWorkingHours.map(w => w.value)) - 1).toString();
  return {
    [EmployeeGroupingEnum.JOB_TITLE]: {
      getProperties: (e: EmployeeWhole) =>
        e.terms.reduce((p, t) => (selectedJobTitlesIds.includes(t.job_title.id) ? [...p, t.job_title.id] : p), []),
      defaultValue: 'groupingEmployees.withoutJobTitle',
      sort: sortJobTitles(userJobTitlesDict),
      getValue: (jobId: string) => (userJobTitlesDict[jobId] ? jobId : 'unknownJobTitle'),
    },
    [EmployeeGroupingEnum.EMPLOYMENT_CONDITION]: {
      getProperties: (e: EmployeeWhole) => e.employment_conditions.template_id,
      defaultValue: 'groupingEmployees.withoutEmploymentCondition',
      sort: sortEmploymentConditions(employmentConditionsDict),
      getValue: (templateId: string) =>
        employmentConditionsDict[templateId] ? templateId : 'unknownEmploymentCondition',
    },
    [EmployeeGroupingEnum.WORKING_TIME]: {
      getProperties: (e: EmployeeWhole) => e.employment_conditions.weekly_working_minutes,
      defaultValue: minWorkingHours,
      sort: arr => sortNumbersDesc(arr.map(Number)).map(String),
      getValue: (minutes: number) =>
        weeklyWorkingHours.find(w => w.value === minutes) ? minutes.toString() : minWorkingHours,
    },
    [EmployeeGroupingEnum.NONE]: {
      getProperties: () => undefined,
      defaultValue: 'groupingEmployees.withoutGrouping',
      sort: defaultSortProperties,
      getValue: () => '',
    },
  };
};

export const groupEmployees = (
  initEmployeesIds: EmployeeWhole['id'][],
  initGroups: EmployeeGroupingEnum[],
  employeesData: EmployeesData,
  userJobTitlesDict: Record<UserJobTitle['id'], UserJobTitle>,
  employmentConditionsDict: Record<EmploymentCondition['id'], EmploymentCondition>,
  selectedJobTitlesIds: UserJobTitle['id'][],
): GroupNodeType[] => {
  const recursiveGroup = (employeesIds: EmployeeWhole['id'][], groups: EmployeeGroupingEnum[], parentId: string) => {
    const [groupId, ...restGroups] = groups;
    const groupOption = getGroupingOptions(userJobTitlesDict, employmentConditionsDict, selectedJobTitlesIds)[groupId];

    const grouped = employeesIds.reduce<Record<GroupNodeType['groupKey'], Array<EmployeeWhole['id']>>>(
      (acc, employeeId) => {
        const employee = employeesData[employeeId];
        const properties = groupOption.getProperties(employee);
        const keys = Array.isArray(properties) ? properties : [properties];
        keys.forEach(key => {
          const groupKey = key !== undefined ? groupOption.getValue(key) : groupOption.defaultValue;
          if (!acc[groupKey]) acc[groupKey] = [];
          acc[groupKey].push(employeeId);
        });
        return acc;
      },
      {},
    );

    return groupOption.sort(Object.keys(grouped)).map<GroupNodeType>(groupKey => {
      const newEmpIds = grouped[groupKey];
      const isLast = restGroups.length === 0;
      const id = encodeGroupNodeId(groupId, groupKey);
      return {
        id,
        parentId,
        groupId,
        groupKey,
        children: isLast ? undefined : recursiveGroup(newEmpIds, restGroups, id),
        employeesIds: isLast ? newEmpIds : undefined,
      };
    });
  };

  return recursiveGroup(initEmployeesIds, initGroups, 'root');
};
