import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import moment from 'moment';

import { getPayrollForCurrentEmployee } from '@/actions/payrollEmployee/payrollEmployee';
import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';
import { getFiltersStateForNNReports, getReportData } from '@/actions/reports';
import { redirectToFirstPossibleView } from '@/actions/routes';
import { filterScheduleViewState } from '@/actions/schedule/scheduleView';
import { BUDGET_METRICS_ENABLE, LOAN_EMPLOYEES_ENABLE, PRODUCTION_QUOTAS_GET } from '@/constants/Permissions.js';
import { DASHBOARD_VIEW_HIDE } from '@/constants/Restrictions';
import { getFreeDaysMarkingsIfNecessary } from '@/redux-store/freeDaysMarking/freeDaysMarking.actions.ts';
import { getManagerNotes } from '@/redux-store/managerNotes';
import { isMonthPeriod } from '@/utils/dateHelper.js';

import {
  changeAbsencesData,
  getAbsenceLimitsForEmployee,
  getAbsenceLimitsUsage,
  getAbsences,
  getAbsencesForSchedule,
} from './absences.js';
import { getAvailabilitiesForEmployee, getAvailabilitiesForLocation } from './availability.js';
import { getBudgetEstimates } from './budgetEstimates.js';
import { getBudgetMetrics, setMetricsVisible } from './budgetMetrics.js';
import { getBudgetMetricData } from './budgetMetricsData.js';
import { getChatUsers } from './chat/chat.js';
import { getCompanyLimitsUsage } from './companyLimitsUsage/companyLimitsUsage.js';
import { getDashboard } from './dashboard.js';
import { getExportsHistory } from './exports/exportsHistory/exportsHistory';
import {
  getAttendances,
  getAttendancesImages,
  getMultipleScheduleEvents,
  getScheduleAndAttendancesForLocations,
  getScheduleEvents,
  getScheduleForEmployee,
  getShiftsSummary,
} from './index';
import { removeSelectedButNotPresentJobTitles } from './jobTitlesGrouping';
import { getLabels } from './labels.ts';
import { getLoanEmployeesProposals } from './loanEmployeesProposals.ts';
import { mainDateChangeMode } from './mainDate.js';
import { getCompanyMilestones } from './onboarding/companyMilestones.js';
import { getOvertimeCollections } from './overtimeCollections/overtimeCollections.js';
import { getPaymentData } from './payments';
import { getRecommendedSchedule } from './recommendedSchedule.js';
import { getBudgetTargets } from './schedule/budget';
import { getProductionQuotas, getProductionQuotasPayroll } from './userProductionQuotas.js';
import { getWeatherForecast } from './weatherForecast.js';
import { activateVerifyWorkingRulesButton, deactivateVerifyWorkingRulesButton } from './workingRules.js';

export const dashboardView = (from, to, requestType) => (dispatch, getState) => {
  const { multipleLocationFilter, userPermissions, userLocations } = getState().reducer;
  if (userPermissions.restrictions.includes(DASHBOARD_VIEW_HIDE)) {
    dispatch(redirectToFirstPossibleView());
    return;
  }
  const userLocationIds = userLocations.map(location => location.id);
  if (userLocationIds.length === 0) return;

  const today = moment().format('YYYY-MM-DD');

  if (userPermissions.permissions.includes(LOAN_EMPLOYEES_ENABLE))
    dispatch(getLoanEmployeesProposals(today, moment().add(1, 'month').format('YYYY-MM-DD'), userLocationIds));

  if (userPermissions.isEmployee) {
    multipleLocationFilter.forEach(locationId =>
      dispatch(getScheduleForEmployee(locationId, from, to, false, requestType)),
    );
    dispatch(getMultipleScheduleEvents(multipleLocationFilter, from, to));
  } else {
    dispatch(getMultipleScheduleEvents(userLocationIds, today, moment().add(1, 'week').format('YYYY-MM-DD')));
    dispatch(getAbsences(today, moment().add(1, 'month').format('YYYY-MM-DD')));
    dispatch(getDashboard());
  }
};

export const getBudgetMetricsIfNecessary = (from, to, locationIds) => async (dispatch, getState) => {
  const { userPermissions } = getState().reducer;

  if (
    !userPermissions.permissions.includes(BUDGET_METRICS_ENABLE) ||
    userPermissions.restrictions.includes(RESTRICTIONS.BUDGET_INFO_HIDE) ||
    userPermissions.restrictions.includes(RESTRICTIONS.BUDGET_TARGETS_HIDE)
  )
    return;

  await dispatch(getBudgetMetrics());

  const parsedLocationIds = locationIds.map(id => parseInt(id));
  const { budgetMetrics } = getState().reducer;

  let visibleBudgetMetricIds = [];
  budgetMetrics.forEach(budgetMetric => {
    const metricLocations = budgetMetric.location_ids?.filter(locationId =>
      parsedLocationIds.includes(parseInt(locationId)),
    );

    if (metricLocations?.length) {
      dispatch(getBudgetMetricData(budgetMetric.id, from, to, metricLocations));
      visibleBudgetMetricIds = [...visibleBudgetMetricIds, budgetMetric.id];
    }
  });

  dispatch(setMetricsVisible(visibleBudgetMetricIds));
};

export const getBudgetTargetIfNecessary = (from, to, locationIds) => (dispatch, getState) => {
  const { locationSettings } = getState().reducer.settings;
  const isSingleLocation = locationIds.length === 1;
  const isMonthSelected = isMonthPeriod(from, to);
  const locationId = locationIds[0];
  const relevantLocationSettings = locationSettings[locationId];

  if (isMonthSelected && isSingleLocation && relevantLocationSettings?.enable_budget_target) {
    dispatch(getBudgetTargets(from, to, locationIds[0]));
  }
};

export const scheduleView = (from, to, requestType) => async (dispatch, getState) => {
  const {
    userPermissions,
    currentCompany,
    currentUser,
    scheduleUIState,
    scheduleLocationFilter: locationIds,
  } = getState().reducer;

  if (!userPermissions.isEmployee && scheduleUIState.selectedDisplayMode.type !== 'templates') {
    dispatch(removeSelectedButNotPresentJobTitles(locationIds, locationIds));
    dispatch(getScheduleAndAttendancesForLocations(locationIds, from, to, null, requestType));
    dispatch(getBudgetEstimates(from, to, locationIds));
    dispatch(getRecommendedSchedule(from, to, locationIds));
    dispatch(getManagerNotes({ from, to }));
    dispatch(getShiftsSummary(from, locationIds));
    dispatch(getWeatherForecast(locationIds));
  }

  const employeeScheduleRequests = [];
  for (let i = 0; i < locationIds.length; i++) {
    if (userPermissions.isEmployee) {
      employeeScheduleRequests.push(dispatch(getScheduleForEmployee(locationIds[i], from, to, false, requestType)));
    }
  }

  await Promise.all(employeeScheduleRequests);

  for (let i = 0; i < locationIds.length; i++) {
    const locationId = locationIds[i];
    if (userPermissions.isEmployee) {
      if (currentCompany.settings.availabilities_are_public) {
        dispatch(getAvailabilitiesForLocation(locationId, from, to, [], requestType));
      } else {
        dispatch(getAvailabilitiesForEmployee(currentUser.user.id, from, to, [], requestType));
      }
    }
    dispatch(getScheduleEvents(locationId, from, to, requestType));
  }

  dispatch(filterScheduleViewState());

  const { scheduleState } = getState().reducer;

  const visibleEmployees = locationIds.reduce(
    (result, locationId) => [...result, ...(scheduleState.locations[locationId]?.visible || [])],
    [],
  );
  const hasVisibleEmployees = visibleEmployees.length > 0;
  if (!userPermissions.isEmployee && scheduleUIState.selectedDisplayMode.type !== 'templates') {
    if (hasVisibleEmployees) {
      dispatch(activateVerifyWorkingRulesButton());
    } else {
      dispatch(deactivateVerifyWorkingRulesButton());
    }
  }

  if (visibleEmployees.length > 0) {
    dispatch(getFreeDaysMarkingsIfNecessary(from, to, visibleEmployees));
    dispatch(getOvertimeCollections(from, to, visibleEmployees));
  }
  dispatch(getAbsencesForSchedule(from, to, locationIds));
  dispatch(getBudgetTargetIfNecessary(from, to, locationIds));
  dispatch(getBudgetMetricsIfNecessary(from, to, locationIds));
};

export const availabilityView = (from, to, requestType) => (dispatch, getState) => {
  const { currentUser } = getState().reducer;
  dispatch(getAvailabilitiesForEmployee(currentUser.user.id, from, to, [], requestType));
};

export const attendanceView = (from, to, requestType) => (dispatch, getState) => {
  const { scheduleLocationFilter, currentCompany } = getState().reducer;
  const locationIds = scheduleLocationFilter;
  dispatch(getScheduleAndAttendancesForLocations(locationIds, from, to, null, requestType));

  dispatch(getAttendances(currentCompany.id, locationIds, from, to, requestType)).then(() => {
    dispatch(getAttendancesImages(locationIds, from, to));
    dispatch(getLabels());
  });
  dispatch(getAbsencesForSchedule(from, to, locationIds));
  dispatch(getOvertimeCollections(from, to));
};

export const payrollView = (from, to, requestType) => (dispatch, getState) => {
  const { multipleLocationFilter, userPermissions, currentCompany, singleEmployeeFilter } = getState().reducer;
  const isQuotasPermitted = userPermissions.permissions.includes(PRODUCTION_QUOTAS_GET);

  if (userPermissions.isEmployee) {
    multipleLocationFilter.forEach(locationId =>
      dispatch(getScheduleForEmployee(locationId, from, to, false, requestType)),
    );
  } else {
    dispatch(
      getScheduleAndAttendancesForLocations(multipleLocationFilter, from, to, [singleEmployeeFilter.id], requestType),
    );
    dispatch(getAttendances(currentCompany.id, multipleLocationFilter, from, to, requestType)).then(() => {
      dispatch(getAttendancesImages(multipleLocationFilter, from, to));
    });

    if (isQuotasPermitted) dispatch(getProductionQuotasPayroll(multipleLocationFilter, from, to, requestType));
  }

  if (singleEmployeeFilter?.id && singleEmployeeFilter.id !== 'Empty') {
    dispatch(getOvertimeCollections(from, to, [singleEmployeeFilter.id]));
  }
  dispatch(getAbsencesForSchedule(from, to, multipleLocationFilter));
};

export const payrollLocationView = (from, to, requestType) => (dispatch, getState) => {
  const { multipleLocationFilter, userPermissions, currentCompany, userEmployees } = getState().reducer;
  const isQuotasPermitted = userPermissions.permissions.includes(PRODUCTION_QUOTAS_GET);
  const employeeIds = userEmployees.map(employee => employee.id);

  dispatch(getScheduleAndAttendancesForLocations(multipleLocationFilter, from, to, null, requestType));
  dispatch(getAttendances(currentCompany.id, multipleLocationFilter, from, to, requestType));
  if (isQuotasPermitted) dispatch(getProductionQuotasPayroll(multipleLocationFilter, from, to, requestType));

  dispatch(getOvertimeCollections(from, to, employeeIds));
  dispatch(getAbsencesForSchedule(from, to, multipleLocationFilter));
};

export const newPayrollLocationView = () => dispatch => {
  dispatch(getLabels());
};

export const reportsView = (from, to, requestType) => (dispatch, getState) => {
  const { multipleLocationFilter, currentCompany } = getState().reducer;

  dispatch(getScheduleAndAttendancesForLocations(multipleLocationFilter, from, to, null, requestType));
  dispatch(getAttendances(currentCompany.id, multipleLocationFilter, from, to, requestType));

  dispatch(getRecommendedSchedule(from, to, multipleLocationFilter));
  dispatch(getPayrollLocationViewData());
};

export const productionQuotasView = (from, to, requestType) => (dispatch, getState) => {
  const { locationFilter, currentCompany } = getState().reducer;

  const locationId = locationFilter.selectedLocation.id;
  dispatch(getAttendances(currentCompany.id, [locationId], from, to, requestType));
  dispatch(getProductionQuotas(locationId, from, to, requestType));
};

export const settingsView = () => dispatch => {
  dispatch(getPaymentData());
};

export const absencesView = (from, to) => (dispatch, getState) => {
  const { absences, absenceFilter, userPermissions, currentUser } = getState().reducer;
  const { selectedDisplayMode, absencesTable, absenceLimitsUsageTable } = absences;
  const { selectedAbsence } = absenceFilter;

  if (selectedDisplayMode.type === 'limits') {
    if (selectedAbsence) {
      const startOfPeriod = moment(from).startOf(selectedAbsence.limit_period).format('YYYY-MM-DD');
      const endOfPeriod = moment(to).endOf(selectedAbsence.limit_period).format('YYYY-MM-DD');

      if (userPermissions.isEmployee) {
        dispatch(getAbsenceLimitsForEmployee(currentUser.user.id, startOfPeriod, endOfPeriod));
      } else {
        dispatch(
          getAbsenceLimitsUsage(
            selectedAbsence,
            startOfPeriod,
            endOfPeriod,
            1,
            absenceLimitsUsageTable.numberOfItemsPerPage,
          ),
        );
      }
    } else {
      dispatch(mainDateChangeMode(selectedAbsence.limit_period));
    }
  } else {
    dispatch(changeAbsencesData(1, absencesTable.numberOfItemsPerPage, from, to, {}, 'request_number', 'desc'));
  }
};

export const timesheetView = (from, to, requestType) => (dispatch, getState) => {
  const { userPermissions, userLocations, currentUser } = getState().reducer;

  const locationsIds = userLocations.map(l => l.id);

  if (userPermissions.isEmployee) {
    locationsIds.forEach(locationId => dispatch(getScheduleForEmployee(locationId, from, to, false, requestType)));
  } else {
    dispatch(getScheduleAndAttendancesForLocations(locationsIds, from, to, [currentUser.user.id], requestType));
  }

  dispatch(getAbsencesForSchedule(from, to, locationsIds));
};

export const exportsView = () => (dispatch, getState) => {
  const { exportsHistory } = getState().reducer;

  if (window.location.href.endsWith('/exports/history') || window.location.href.endsWith('/exports/history/')) {
    dispatch(getExportsHistory(exportsHistory.numberOfItemsPerPage));
  }
};

export const chatView = () => dispatch => {
  dispatch(getChatUsers());
};

export const startView = () => dispatch => {
  dispatch(getCompanyMilestones());
};

export const companyManageView = () => dispatch => {
  dispatch(getCompanyLimitsUsage());
};

export const employeePayrollView = (from, to) => dispatch => {
  dispatch(getPayrollForCurrentEmployee(from, to));
};

export const reportsNNView = (from, to) => dispatch => {
  dispatch(getFiltersStateForNNReports());

  dispatch(getReportData(from, to));
};
