import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import { MDTooltip } from '@/components/common/inputs/MDComponents.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { AUTO_GENERATE_SCHEDULE, FREEMIUM_EVENT_AUTO_GENERATE_SCHEDULE } from '@/constants/intercom';
import { AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes.js';
import { AUTO_GENERATE_SCHEDULE_VIEW } from '@/constants/Permissions';
import { FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE, SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions.js';

import { getAutogenerateScheduleTooltip } from './AutogenerateScheduleButton.helpers';

import './AutogenerateScheduleButton.scss';

const AutogenerateScheduleButton = (props, { intl }) => {
  const {
    dateArray,
    dateMode,
    isSchedule,
    scheduleLocationFilter,
    selectedLocationSettings,
    showModal,
    restrictions,
    areWorkingRulesVisible,
    scheduleGeneration,
    trackIntercomEvent,
  } = props;
  const shiftEditDisabledUntil = selectedLocationSettings?.disable_location_schedule_shifts_edit_until;
  const isShiftEditDisabled = moment(shiftEditDisabledUntil).isSameOrAfter(dateArray[dateArray.length - 1]);
  const isOnlyOneLocationSelected = scheduleLocationFilter.length === 1;
  const autogenerateScheduleButtonClassname = classnames('autogenerateScheduleButton', {
    'autogenerateScheduleButton--pushLeft': !areWorkingRulesVisible,
  });
  const freemiumDisabled = restrictions.includes(FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE);
  const tooltipMessage = getAutogenerateScheduleTooltip(intl, freemiumDisabled, isOnlyOneLocationSelected);

  const showAutoGenerateScheduleModal = () => {
    showModal(AUTO_GENERATE_STEPS_MODAL, { step: !scheduleGeneration ? 0 : 3 });
    trackIntercomEvent(AUTO_GENERATE_SCHEDULE);
  };

  return (
    <FeatureWrapper
      restriction={SCHEDULE_EDIT_DISABLE}
      permission={AUTO_GENERATE_SCHEDULE_VIEW}
      hide={!isSchedule || dateMode === 'day'}
    >
      <div className={autogenerateScheduleButtonClassname}>
        <FreemiumWrapper
          freemiumRestrictions={[FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE]}
          intercomEvent={FREEMIUM_EVENT_AUTO_GENERATE_SCHEDULE}
          modifiers={['withFullOpacity', 'enablePointer']}
        >
          <MDTooltip text={tooltipMessage} withoutIcon>
            <Button
              onClick={showAutoGenerateScheduleModal}
              modifiers={classnames('orange', 'small')}
              disabled={!isOnlyOneLocationSelected || isShiftEditDisabled || freemiumDisabled}
            >
              <span role="img" aria-label="rocket" className="kButton__emoji--rocket">
                🚀
              </span>
              <FormattedMessage id="autoGenerate" defaultMessage="Generuj grafik">
                {text => <span style={{ verticalAlign: 'middle' }}>{text}</span>}
              </FormattedMessage>
              {freemiumDisabled && <FreemiumStar />}
            </Button>
          </MDTooltip>
        </FreemiumWrapper>
      </div>
    </FeatureWrapper>
  );
};

AutogenerateScheduleButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AutogenerateScheduleButton.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  dateMode: PropTypes.string,
  isSchedule: PropTypes.bool,
  selectedLocationSettings: PropTypes.shape({
    disable_location_schedule_shifts_edit_until: PropTypes.string,
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  areWorkingRulesVisible: PropTypes.bool,
  scheduleGeneration: PropTypes.bool,
  trackIntercomEvent: PropTypes.func,
};

export default memo(AutogenerateScheduleButton);
