import { connect } from 'react-redux';

import { hideModal, showModal } from '@/actions/uiState.js';
import { ADD_TEMPLATE_MODAL } from '@/constants/modalTypes.js';

import ChooseAddTemplateVersionModal from './ChooseAddTemplateVersionModal.jsx';

const mapStateToProps = state => ({
  isVisibleModal: state.reducer.uiState.showModal === ADD_TEMPLATE_MODAL,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAddTemplateVersionModal);
