import { connect } from 'react-redux';

import Component from './ScheduleShiftPopup.jsx';

const mapStateToProps = (state, ownProps) => {
  const employee = state.reducer.employees.data[ownProps.employeeId];
  const shift = state.reducer.shifts.data[ownProps.employeeId]?.shifts?.[ownProps.shiftId];
  return {
    comment: shift?.comment,
    date: shift?.date,
    employeeFirstName: employee?.first_name,
    employeeLastName: employee?.last_name,
    isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
