import { RECENTLY_USED_WORKING_HOURS } from '@/constants/localStorageKeys';

export const getRecentlyUsedWorkingHours = () => {
  const recentlyUsedWorkingHoursRaw = localStorage.getItem(RECENTLY_USED_WORKING_HOURS);
  const recentlyUsedWorkingHours = recentlyUsedWorkingHoursRaw ? JSON.parse(recentlyUsedWorkingHoursRaw) : [];

  return recentlyUsedWorkingHours.filter(workingHours => {
    const [start, end] = workingHours.split('-');
    return start && end && start !== end;
  });
};

export const saveRecentlyUsedWorkingHours = workingHours => {
  const recentlyUsedWorkingHours = getRecentlyUsedWorkingHours();
  if (!recentlyUsedWorkingHours.includes(workingHours)) recentlyUsedWorkingHours.unshift(workingHours);
  if (recentlyUsedWorkingHours.length > 6) recentlyUsedWorkingHours.pop();
  localStorage.setItem(RECENTLY_USED_WORKING_HOURS, JSON.stringify(recentlyUsedWorkingHours));
};
