import PropTypes from 'prop-types';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { getShiftPopover } from '@/utils/shiftHelpers';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

const ScheduleShiftPopup = (props, context) => {
  const {
    jobTitle,
    isJobTitleNotAssigned,
    isFromOtherLocation,
    shiftLocationName,
    children,
    employeeFirstName,
    employeeLastName,
    comment,
    date,
    isQuickPlanningEnabled,
    isLoaned,
    withoutPopover,
    isOpenShiftFromLocationGroups,
  } = props;
  const { intl } = context;
  const employeeName = getEmployeeNameShort({ first_name: employeeFirstName, last_name: employeeLastName }, 17);
  const popover =
    !isQuickPlanningEnabled &&
    getShiftPopover(
      employeeName,
      date,
      comment,
      jobTitle,
      isJobTitleNotAssigned,
      isFromOtherLocation,
      shiftLocationName,
      intl,
      isLoaned,
      isOpenShiftFromLocationGroups,
    );

  return popover && !withoutPopover ? (
    <HoverPopOver
      name="shiftHover"
      popover={{ ...popover, content: popover.content.map((info, i) => <div key={i}>{info}</div>) }}
    >
      <div>{children}</div>
    </HoverPopOver>
  ) : (
    children
  );
};

ScheduleShiftPopup.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

ScheduleShiftPopup.propTypes = {
  jobTitle: PropTypes.shape({}),
  isJobTitleNotAssigned: PropTypes.bool,
  isFromOtherLocation: PropTypes.bool,
  children: PropTypes.node,
  employeeFirstName: PropTypes.string,
  employeeLastName: PropTypes.string,
  shiftLocationName: PropTypes.string,
  comment: PropTypes.string,
  date: PropTypes.string,
  isQuickPlanningEnabled: PropTypes.bool,
  isLoaned: PropTypes.string,
  withoutPopover: PropTypes.bool,
  isOpenShiftFromLocationGroups: PropTypes.bool,
};

export default ScheduleShiftPopup;
