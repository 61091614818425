import PropTypes from 'prop-types';

import { scheduleMessages } from '@/constants/intl.js';

const ClearViewConfirmationModalDescription = (props, { intl }) => (
  <span style={{ textAlign: 'center', display: 'inline-block', whiteSpace: 'pre-wrap' }}>
    <i className="material-icons" style={{ fontSize: '120px', color: '#e43a3a' }}>
      error_outline
    </i>
    <br />
    <strong>{intl.formatMessage(scheduleMessages.clearViewModalDescription, {})}</strong>
  </span>
);

ClearViewConfirmationModalDescription.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default ClearViewConfirmationModalDescription;
