import * as AT from '@/constants/ActionTypes';

import { getInitPayrollLocationColumns } from './payrollLocation.helpers';

const initialState = {
  data: [],
  summary: {},
  isLoadButtonEnabled: false,
  visibleColumns: getInitPayrollLocationColumns(),
  showSkeletons: false,
  isFetching: false,
  currentRequestId: null,
};

const mergeData = (data, newData) => {
  const relevantOldRows = data.filter(row => !newData.some(oldRow => oldRow.employee_id === row.employee_id));
  return [...relevantOldRows, ...newData];
};

const payroll = (state = initialState, action) => {
  switch (action.type) {
    case AT.PAYROLL_LOCATION_GET_DATA_SUCCESS: {
      if (action.payload.fetchRequestId !== state.currentRequestId) {
        return state;
      }

      if (action.payload.mergeData) {
        const newData = action.payload.data.data;
        const relevantData = mergeData(state.data, newData);

        return {
          ...state,
          data: relevantData,
          summary: action.payload.data.summary,
          isLoadButtonEnabled: false,
          isFetching: false,
          currentRequestId: null,
        };
      }

      return { ...state, ...action.payload.data, isLoadButtonEnabled: false, isFetching: false };
    }
    case AT.PAYROLL_LOCATION_GET_DATA_ERROR:
      if (action.payload.fetchRequestId !== state.currentRequestId) {
        return state;
      }
      return { ...state, data: [], summary: {}, isFetching: false, currentRequestId: null };
    case AT.PAYROLL_LOCATION_CHANGE_VISIBLE_COLUMNS:
      return { ...state, visibleColumns: action.payload };
    case AT.CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_SUCCESS: {
      const { employeeIds, newStatus } = action.payload;
      return {
        ...state,
        data: state.data.map(row => {
          if (employeeIds.includes(row.employee_id)) {
            return {
              ...row,
              status: newStatus,
            };
          }

          return row;
        }),
      };
    }
    case AT.GET_PAYROLL_LOCATION_SUMMARY_SUCCESS: {
      return { ...state, summary: action.payload };
    }
    case AT.START_FETCHING_PAYROLL_LOCATION:
      return { ...state, isFetching: true, currentRequestId: action.payload.fetchRequestId };
    case AT.ENABLE_PAYROLL_LOAD_BUTTON:
    case AT.CHANGE_JOBTITLE_FILTER:
    case AT.CHANGE_MULTIPLE_LOCATION_FILTER:
    case AT.CHANGE_EMPLOYMENT_CONDITION_FILTER:
    case AT.DELETE_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.ADD_SHIFT_FOR_PAYROLL_SUCCESS:
    case AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS:
    case AT.DELETE_ATTENDANCE_SUCCESFUL:
    case AT.ADD_ATTENDANCE_BONUS_SUCCESFUL:
    case AT.ADD_SHIFT_SUCCESFUL:
    case AT.ADD_SHIFTS_SUCCESFUL:
    case AT.EDIT_SHIFT_SUCCESFUL:
    case AT.DELETE_SHIFT:
    case AT.DELETE_MASS_SHIFTS_SUCCESFUL:
    case AT.ADD_MASS_SHIFTS_SUCCESFUL:
    case AT.EDIT_MASS_SHIFTS_SUCCESFUL:
    case AT.PUBLISH_DRAFT_SHIFTS_SUCCESFUL:
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
    case AT.SWAP_SHIFTS_SUCCESS:
    case AT.ADD_AVAILABILITY_SUCCESFUL:
    case AT.DELETE_AVAILABILITY_SUCCESFUL:
    case AT.CHANGE_AVAILABILITY_SUCCESFUL:
    case AT.DELETE_AVAILABILITIES_SUCCESFUL:
    case AT.ADD_ATTENDANCE_SUCCESFUL:
    case AT.CHANGE_ATTENDANCE_SUCCESFUL:
    case AT.CHANGE_MULTIPLE_ATTENDANCES_SUCCESFUL:
    case AT.START_ATTENDANCE_SUCCESFUL:
    case AT.END_ATTENDANCE_SUCCESFUL:
    case AT.ADD_BREAK_SUCCESFUL:
    case AT.CHANGE_BREAK_SUCCESFUL:
    case AT.DELETE_BREAK_SUCCESFUL:
    case AT.START_BREAK_SUCCESFUL:
    case AT.END_BREAK_SUCCESFUL:
    case AT.UPDATE_EMPLOYMENT_CONDITION:
    case AT.ADD_ABSENCE_SUCCESS:
    case AT.EDIT_ABSENCE_SUCCESS:
    case AT.DELETE_ABSENCE_SUCCESS:
    case AT.CHANGE_PAYROLL_DAYS_STATUS_SUCCESS:
    case AT.ASSIGN_LABEL_TO_ATTENDANCE:
    case AT.UNASSIGN_LABEL_FROM_ATTENDANCE:
      return { ...state, isLoadButtonEnabled: true };
    case AT.START_FETCHING_PAYROLL_LOCATION_IN_BATCHES:
      return {
        ...state,
        showSkeletons: !action.payload.options.staleWhileRevalidate,
        summary: {},
        data: action.payload.options.staleWhileRevalidate ? state.data : [],
        isFetching: true,
        currentRequestId: action.payload.fetchRequestId,
      };
    case AT.FINISH_FETCHING_PAYROLL_LOCATION_IN_BATCHES:
      if (action.payload.fetchRequestId !== state.currentRequestId) {
        return state;
      }
      return {
        ...state,
        showSkeletons: false,
        isLoadButtonEnabled: false,
        isFetching: false,
        currentRequestId: null,
      };
    case AT.GET_PAYROLL_LOCATION_DATA_BATCH_SUCCESS: {
      if (action.payload.fetchRequestId !== state.currentRequestId) {
        return state;
      }
      const relevantData = action.payload.options.staleWhileRevalidate
        ? mergeData(state.data, action.payload.data)
        : [...state.data, ...action.payload.data];

      return {
        ...state,
        data: relevantData,
      };
    }
    default:
      return state;
  }
};

export default payroll;
