import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes.js';

const initialState = {
  data: [],
};

const loanEmployeesProposalsAssignments = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.GET_LOAN_EMPLOYEES_PROPOSALS_ASSIGNMENTS_SUCCESSFUL: {
        draft.data = action.payload;
        break;
      }
      default:
        return state;
    }
  });

export default loanEmployeesProposalsAssignments;
