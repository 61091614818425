import { connect } from 'react-redux';

import ScheduleBudgetTablePercentEstimatesCell from './ScheduleBudgetTablePercentEstimatesCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
  estimate: state.reducer.schedule.budget.budgetEstimates.dailyValues[ownProps.date],
  permissions: state.reducer.userPermissions.permissions,
  sumCosts: state.reducer.schedule.budget.shiftsSummary[ownProps.date]?.totalCosts,
  showHolidays:
    state.reducer.scheduleUIState.selectedDisplayMode.type !== 'templates' &&
    state.reducer.schedule.viewSettings.holidays.value,
});

export default connect(mapStateToProps)(ScheduleBudgetTablePercentEstimatesCell);
