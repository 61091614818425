import { connect } from 'react-redux';

import { exportSelectedEmployeesAsFile } from '@/actions/exports';
import { hideModal } from '@/actions/uiState';
import { GENERATE_QR_CODE_MODAL } from '@/constants/modalTypes';

import ExportQrCodeModal from './ExportQrCodeModal';

const mapStateToProps = ({ reducer }) => ({
  isModalVisible: reducer.uiState.showModal === GENERATE_QR_CODE_MODAL,
  modalObject: reducer.uiState.modalObject[GENERATE_QR_CODE_MODAL],
});

const mapDispatchToProps = {
  hideModal,
  exportSelectedEmployeesAsFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportQrCodeModal);
