import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip';

const ButtonWithDropdownWrapper = ({ showTooltip, tooltipContent, children }) => {
  if (showTooltip && tooltipContent)
    return (
      <Tooltip className="mdIconButton__tooltip" content={tooltipContent}>
        {children}
      </Tooltip>
    );
  return children;
};

ButtonWithDropdownWrapper.propTypes = {
  showTooltip: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default ButtonWithDropdownWrapper;
