import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { ATTENDANCE_MAP_MODAL } from '@/constants/modalTypes.js';

import AttendanceMapModal from './AttendanceMapModal.jsx';

const mapStateToProps = state => ({
  isVisible: state.reducer.uiState.showModal === ATTENDANCE_MAP_MODAL,
  modalObject: state.reducer.uiState.modalObject[ATTENDANCE_MAP_MODAL],
});

const mapDispatchToProps = {
  hideModal,
};

const AttendanceMapModalContainer = connect(mapStateToProps, mapDispatchToProps)(AttendanceMapModal);

export default AttendanceMapModalContainer;
