export const getFinishedExports = pendingExports =>
  pendingExports.reduce(
    (agg, pendingExport) => {
      if (pendingExport.status === 'success' || pendingExport.status === 'unseen')
        return { ...agg, finishedExports: agg.finishedExports + 1 };
      if (pendingExport.status === 'error') return { ...agg, isAnyError: true };
      return agg;
    },
    {
      isAnyError: false,
      finishedExports: 0,
    },
  );

export const getButtonStatus = (isAnyError, amountOfExports, amountOfFinishedExports) => {
  if (isAnyError) return 'error';
  if (amountOfExports === amountOfFinishedExports) return 'finished';
  return 'inProgress';
};
