import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  attendances: {
    id: 'dashboard.attendances',
    defaultMessage: 'Obecni w pracy',
  },
  shifts: {
    id: 'dashboard.shifts',
    defaultMessage: 'Zaplanowana l. pracowników',
  },
  noData: {
    id: 'dashboard.noData',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
});
