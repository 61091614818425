import classnames from 'classnames';
import PropTypes from 'prop-types';

const UnauthenticatedLayout = props => {
  const logoClassnames = classnames('k-unauthenticatedLayout__logo', {
    'k-unauthenticatedLayout__logo--hide': props.hideLogo,
  });
  return (
    <div className="k-unauthenticatedLayout">
      <div className="k-unauthenticatedLayout__showcase">{props.showcase}</div>
      <div className="k-unauthenticatedLayout__stepContainer">
        <div className={logoClassnames}>
          <a href="/">
            <img className="k-unauthenticatedLayout__outscreenLogo" src="/img/logo4.png" alt="logo" />
          </a>
        </div>
        {props.children}
      </div>
    </div>
  );
};

UnauthenticatedLayout.propTypes = {
  hideLogo: PropTypes.bool,
  showcase: PropTypes.node.isRequired,
  children: PropTypes.node,
};

export default UnauthenticatedLayout;
