import { connect } from 'react-redux';

import { runExport } from '@/actions/exports.js';
import { getOldFiltersStateForPayrollLocation } from '@/actions/payrollLocation/payrollLocation';
import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { NEW_PAYROLL_EXPORT_MODAL } from '@/constants/modalTypes';

import NewPayrollExportModal from './NewPayrollExportModal';

const mapStateToProps = state => ({
  isModalVisible: state.reducer.uiState.showModal === NEW_PAYROLL_EXPORT_MODAL,
  modalObject: state.reducer.uiState.modalObject[NEW_PAYROLL_EXPORT_MODAL],
  selectedLocations: state.reducer.multipleLocationFilter,
  absenceTypes: state.reducer.absences.absencesTypes,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  selectedEmploymentConditions: state.reducer.employmentConditionsFilter.selected,
  dateArray: state.reducer.mainDateStore.dateArray,
  userId: state.reducer.currentUser.user.id,
  sortingUseLastName: state.reducer.uiState.sortingUseLastName,
});

const mapDispatchToProps = {
  hideModalAndLaterClearModalObject,
  startExport: runExport,
  getOldFiltersState: getOldFiltersStateForPayrollLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPayrollExportModal);
