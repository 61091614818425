import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addEventTitle: {
    id: 'scheduleEvents.addModal.title',
    defaultMessage: 'Dodaj wydarzenie',
  },
  editModalTitle: {
    id: 'scheduleEvents.editModal.title',
    defaultMessage: 'Edytuj wydarzenie',
  },
  addEventDescription: {
    id: 'scheduleEvent.addModal.eventDescription',
    defaultMessage: 'Dodajesz wydarzenie dla dnia',
  },
  editEventDescription: {
    id: 'scheduleEvent.editModal.eventDescription',
    defaultMessage: 'Zmieniasz wydarzenie w dniu',
  },
  eventType: {
    id: 'scheduleEvent.addModal.type',
    defaultMessage: 'Typ wydarzenia: ',
  },
  eventHours: {
    id: 'scheduleEvent.addModal.hours',
    defaultMessage: 'Czas',
  },
  eventNote: {
    id: 'scheduleEvent.addModal.note',
    defaultMessage: 'Notatka',
  },
  notePlaceholder: {
    id: 'scheduleEvents.editModal.noteText',
    defaultMessage: 'Treść notatki',
  },
  removeEvent: {
    id: 'scheduleEvents.editModal.removeEvent',
    defaultMessage: 'Usuń wydarzenie',
  },
});
