import { connect } from 'react-redux';

import { changeScheduleLocationFilter } from '@/actions/schedule/scheduleLocationFilter.js';

import TopBarMultipleLocationFilter from '../TopBarMultipleLocationFilter/TopBarMultipleLocationFilter';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  selectedLocations: state.reducer.scheduleLocationFilter,
});

const mapDispatchToProps = {
  changeFilter: changeScheduleLocationFilter,
};

const TopBarMultipleLocationFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarMultipleLocationFilter);

export default TopBarMultipleLocationFilterContainer;
