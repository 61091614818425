export const cornerColors = {
  availability: '#9de000',
  unavailability: '#ff3333',
  freetime: '#dddddd',
};

export const blockColors = {
  availability: '#e5ffc6',
  unavailability: '#fbe9e7',
  freetime: '#e7eaec',
};

export const accentColors = {
  availability: '#2ec23e',
  unavailability: '#ff0000',
  freetime: '#454e57',
};

export const icons = {
  availability: 'radio_button_checked',
  unavailability: 'not_interested',
  freetime: 'timelapse',
};
