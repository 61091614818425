import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import ExportsWidgetBody from '@/components/common/exports/ExportsWidget/ExportsWidgetBody/ExportsWidgetBody';
import ExportsWidgetButton from '@/components/common/exports/ExportsWidget/ExportsWidgetButton/ExportsWidgetButton.redux.js';

import './ExportsWidget.scss';

const ExportsWidget = ({ pendingExports }) => {
  const [isWidgetBodyVisible, setIsWidgetBodyVisible] = useState(false);
  const filteredExports = useMemo(
    () => pendingExports.filter(exportToFilter => exportToFilter.isShownInExportsWidget),
    [pendingExports],
  );

  useEffect(() => {
    if (!filteredExports.length) setIsWidgetBodyVisible(false);
  }, [filteredExports]);

  if (!filteredExports.length) return null;
  return (
    <div className="k-exportWidget">
      <ExportsWidgetBody
        pendingExports={filteredExports}
        isWidgetBodyVisible={isWidgetBodyVisible}
        setIsWidgetBodyVisible={setIsWidgetBodyVisible}
      />

      <ExportsWidgetButton
        pendingExports={filteredExports}
        isWidgetBodyVisible={isWidgetBodyVisible}
        setIsWidgetBodyVisible={setIsWidgetBodyVisible}
      />
    </div>
  );
};

ExportsWidget.propTypes = {
  pendingExports: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      isShownInExportsWidget: PropTypes.bool,
      link: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
};

export default ExportsWidget;
