import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loanEmployeesProposalItemLocationName: {
    id: 'dashboard.loanEmployeesProposalItem.locationName',
    defaultMessage: 'Wniosek z lokalizacji {locationName}',
  },
  loanEmployeesProposalItemJobTitleAndEmployee: {
    id: 'dashboard.loanEmployeesProposalItemJobTitleAndEmployee.jobTitleAndEmployee',
    defaultMessage: '{jobTitle}, {numberOfEmployee, plural, one {1 pracownik} other {{numberOfEmployee} pracowników}}',
  },
  readMore: {
    id: 'dashboard.loanEmployeesProposalItem. readMore',
    defaultMessage: 'Czytaj więcej',
  },
});
