import { connect } from 'react-redux';

import { importFromFile } from '@/actions/import';

import ImportView from './ImportView.jsx';

const mapDispatchToProps = {
  importFromFile,
};

export default connect(null, mapDispatchToProps)(ImportView);
