import { defineMessages } from 'react-intl';

export const modalMessages = defineMessages({
  addTitle: {
    id: 'openShift.addModal.addTitle',
    defaultMessage: 'Dodaj otwartą zmianę',
  },
  addConfirmText: {
    id: 'openShift.addModal.addConfirmText',
    defaultMessage: 'Dodaj',
  },
  editTitle: {
    id: 'openShift.addModal.editTitle',
    defaultMessage: 'Edytuj otwartą zmianę',
  },
  editConfirmText: {
    id: 'openShift.addModal.editConfirmText',
    defaultMessage: 'Edytuj',
  },
  deleteText: {
    id: 'openShift.addModal.deleteText',
    defaultMessage: 'Usuń zmianę',
  },
  notePlaceholder: {
    id: 'openShift.addModal.noteText',
    defaultMessage: 'Treść notatki',
  },
  nextStepText: {
    id: 'openShift.addModal.nextStepText',
    defaultMessage: 'Dalej',
  },
  cancelText: {
    id: 'openShift.addModal.cancelText',
    defaultMessage: 'Anuluj',
  },
  previousText: {
    id: 'openShift.addModal.previousText',
    defaultMessage: 'Wstecz',
  },
  openShiftAssignedToCurrentLocation: {
    id: 'openShift.addModal.openShiftAssignedToCurrentLocation',
    defaultMessage: 'Otwarta zmiana widoczna dla pracowników przypisanych do tej lokalizacji.',
  },
  openShiftAssignedToCurrentLocationTitle: {
    id: 'openShift.addModal.openShiftAssignedToCurrentLocationTitle',
    defaultMessage: 'Dla tej lokalizacji',
  },
  openShiftAssignedToLocationGroupsTitle: {
    id: 'openShift.addModal.openShiftAssignedToLocationGroupsTitle',
    defaultMessage: 'Dla grup lokalizacji',
  },
  openShiftAssignedToLocationGroups: {
    id: 'openShift.addModal.openShiftAssignedToLocationGroups',
    defaultMessage: 'Otwarta zmiana widoczna dla pracowników przypisanych do wybranych grup lokalizacji.',
  },
  locationGroups: {
    id: 'common.locationGroups',
    defaultMessage: 'Grupy lokalizacji',
  },
  locationGroup: {
    id: 'common.locationGroup',
    defaultMessage: 'Grupa lokalizacji',
  },
  chooseLocationGroups: {
    id: 'openShift.addModal.chooseLocationGroups',
    defaultMessage: 'Wybierz grupy lokalizacji',
  },
  employmentConditions: {
    id: 'openShift.addModal.employmentConditions',
    defaultMessage: 'Warunki zatrudnienia',
  },
  employmentCondition: {
    id: 'openShift.addModal.employmentCondition',
    defaultMessage: 'Warunek zatrudnienia',
  },
  employmentConditionsPlaceholder: {
    id: 'openShift.addModal.employmentConditionsPlaceholder',
    defaultMessage: 'Wybierz warunki zatrudnienia',
  },
});
