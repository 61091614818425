import { connect } from 'react-redux';

import { exportData } from '@/actions/exports.js';
import { toggleExportModal } from '@/actions/uiState.js';
import { EXPORT_DAY_REPORT_MODAL } from '@/constants/modalTypes.js';

import ExportDayReportModal from './ExportDayReportModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === EXPORT_DAY_REPORT_MODAL,
  modalObject: state.reducer.uiState.modalObject[EXPORT_DAY_REPORT_MODAL],
});

const mapDispatchToProps = {
  exportData,
  toggleExportModal,
};

const ExportDayReportModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExportDayReportModal);

export default ExportDayReportModalContainer;
