import { connect } from 'react-redux';

import { trackIntercomEvent } from '@/actions/intercom';
import { showModal } from '@/actions/uiState.js';

import AutogenerateScheduleButton from './AutogenerateScheduleButton';

const mapStateToProps = state => ({
  dateMode: state.reducer.mainDateStore.dateMode,
  dateArray: state.reducer.mainDateStore.dateArray,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  selectedLocationSettings: state.reducer.settings.locationSettings[state.reducer.locationFilter.selectedLocation?.id],
  restrictions: state.reducer.userPermissions.restrictions,
  scheduleGeneration: state.reducer.uiState.scheduleGeneration,
});

const mapDispatchToProps = {
  showModal,
  trackIntercomEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutogenerateScheduleButton);
