import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { templateDisplayMode } from '@/constants/scheduleDisplayModes';
import { CREATE_TEMPLATE_TUTORIAL_ID } from '@/constants/tutorialsId';
import { startTour } from '@/utils/intercomHelpers.js';

import './AutoGenerateFlowPicker.scss';

const AutoGenerateFlowPicker = props => {
  const { templates, changeScheduleMode, hideModal } = props;

  const hasAnyTemplateWithShifts = templates.some(template => {
    const { shifts } = template;

    return shifts.length;
  });

  const startCreateTemplateTutorial = () => {
    hideModal();
    changeScheduleMode(templateDisplayMode);
    startTour(CREATE_TEMPLATE_TUTORIAL_ID);
  };

  return (
    <Fragment>
      <div className="k-autoGenerateFlowPicker__choices">
        <div className="k-autoGenerateFlowPicker__choice">
          <div className="k-autoGenerateFlowPicker__choiceImage">
            <img src="/img/classicSchedule.png" alt="classic schedule icon" />
          </div>
          <FormattedMessage id="autoGenerate.flowPicker.classicScheduleTitle" defaultMessage="Szablon podstawowy">
            {text => <h5 className="k-autoGenerateFlowPicker__choiceTitle">{text}</h5>}
          </FormattedMessage>
          <FormattedMessage
            id="autoGenerate.flowPicker.classicScheduleDescription"
            defaultMessage="Wybierz jeden z przygotowanych wcześniej szablonów. Rekomendowane dla umowy o pracę."
          >
            {text => <p className="k-autoGenerateFlowPicker__choiceDesc">{text}</p>}
          </FormattedMessage>
          {hasAnyTemplateWithShifts ? (
            <Button
              className="k-autoGenerateFlowPicker__choiceButton"
              onClick={props.openAutoGenerateScheduleModal}
              modifiers="reverse-orange small"
            >
              <FormattedMessage id="common.pick" defaultMessage="Wybierz" />
            </Button>
          ) : (
            <Button
              className="k-autoGenerateFlowPicker__choiceButton"
              onClick={startCreateTemplateTutorial}
              modifiers="reverse-orange small"
            >
              <FormattedMessage id="autoGenerate.flowPicker.templateCreateButton" defaultMessage="Utwórz szablon" />
            </Button>
          )}

          <FormattedMessage id="common.learnMore" defaultMessage="Dowiedz się więcej">
            {text => (
              <a
                className="k-autoGenerateFlowPicker__learnMore"
                target="_blank"
                rel="noopener noreferrer"
                href="https://pomoc.kadromierz.pl/pl/articles/6056164-funkcja-automatycznego-planowania-grafiku-pracy"
              >
                {text}
              </a>
            )}
          </FormattedMessage>
        </div>
        <div className="k-autoGenerateFlowPicker__choice">
          <div className="k-autoGenerateFlowPicker__choiceImage">
            <img src="/img/newSchedule.png" alt="new schedule icon" />
          </div>
          <FormattedMessage id="autoGenerate.flowPicker.newScheduleTitle" defaultMessage="Szablon elastyczny">
            {text => <h5 className="k-autoGenerateFlowPicker__choiceTitle">{text}</h5>}
          </FormattedMessage>
          <FormattedMessage
            id="autoGenerate.flowPicker.newScheduleDescription"
            defaultMessage="Stwórz nowy szablon, podając tylko ilość pracowników na stanowisku w danych godzinach. Rekomendowane dla umowy zlecenia."
          >
            {text => <p className="k-autoGenerateFlowPicker__choiceDesc">{text}</p>}
          </FormattedMessage>
          <Button
            className="k-autoGenerateFlowPicker__choiceButton"
            onClick={props.nextStep}
            modifiers="reverse-orange small"
          >
            <FormattedMessage id="common.pick" defaultMessage="Wybierz" />
          </Button>
          <FormattedMessage id="common.learnMore" defaultMessage="Dowiedz się więcej">
            {text => (
              <a
                className="k-autoGenerateFlowPicker__learnMore"
                target="_blank"
                rel="noopener noreferrer"
                href="https://pomoc.kadromierz.pl/pl/articles/6056164-funkcja-automatycznego-planowania-grafiku-pracy"
              >
                {text}
              </a>
            )}
          </FormattedMessage>
        </div>
      </div>
    </Fragment>
  );
};

AutoGenerateFlowPicker.propTypes = {
  openAutoGenerateScheduleModal: PropTypes.func,
  nextStep: PropTypes.func,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  changeScheduleMode: PropTypes.func,
  hideModal: PropTypes.func,
};

export default AutoGenerateFlowPicker;
