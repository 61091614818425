import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  emptyStateHeader: {
    id: 'settings.locations.emptyStateHeader',
    defaultMessage: 'Brak dodanych lokalizacji',
  },
  emptyStateDescription: {
    id: 'settings.locations.emptyStateDescription',
    defaultMessage: 'Aby móc zmienić ustawienia musisz dodać lokalizacje',
  },
});
