import { useCallback } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import ExportsWidgetRowStatus from '@/components/common/exports/ExportsWidget/ExportsWidgetBody/ExportsWidgetRow/ExportsWidgetRowStatus/ExportsWidgetRowStatus';
import SmallExportIcon from '@/components/common/exports/SmallExportIcon/SmallExportIcon';

import './ExportsWidgetRow.scss';

interface ExportsWidgetRowProps {
  name: string;
  downloadExport?: () => void;
  fileIconText?: string;
  hideExportInWidget?: (id: string) => void;
  id?: string;
  link?: string;
  status?: string;
}

const ExportsWidgetRow: ExportsWidgetRowProps = ({
  name,
  downloadExport,
  fileIconText,
  hideExportInWidget,
  id,
  link,
  status,
}) => {
  const handleClick = useCallback(() => {
    if (id && hideExportInWidget) {
      hideExportInWidget(id);
    }
  }, [hideExportInWidget, id]);

  return (
    <div className="k-exportsWidgetRow">
      <SmallExportIcon type={fileIconText} />
      <div className="k-exportsWidgetRow__textContainer">
        <div className="k-exportsWidgetRow__title">{name}</div>
        <ExportsWidgetRowStatus {...{ link, status, downloadExport, id }} />
      </div>
      <button onClick={handleClick} className="k-exportsWidgetRow__closeButton">
        <Icon name="close" />
      </button>
    </div>
  );
};

export default ExportsWidgetRow;
