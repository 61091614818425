import classnames from 'classnames';
import PropTypes from 'prop-types';

import CalendarDay from '@/components/common/YearCalendar/CalendarDay';

import AbsenceCalendarDayPopover from './AbsenceCalendarDayPopover';

const AbsenceCalendarDay = ({ day, data }) => {
  if (data) {
    const { isStartDate, isEndDate, weekDayNumber } = data;
    const popoverClassNames = classnames(
      'absenceCalendar__popover',
      { 'absenceCalendar__popover--firstDay': isStartDate },
      { 'absenceCalendar__popover--lastDay': isEndDate },
      { 'absenceCalendar__popover--firstDayOfWeek': weekDayNumber === 1 },
      { 'absenceCalendar__popover--lastDayOfWeek': weekDayNumber === 0 },
    );
    return (
      <td key={day}>
        <AbsenceCalendarDayPopover data={data}>
          <div className={popoverClassNames} style={{ backgroundColor: data.color }}>
            {day}
          </div>
        </AbsenceCalendarDayPopover>
      </td>
    );
  }
  return <CalendarDay day={day} />;
};

AbsenceCalendarDay.propTypes = {
  modalObject: PropTypes.shape({}),
  day: PropTypes.number,
  data: PropTypes.shape({
    date: PropTypes.string,
    color: PropTypes.string,
    popoverData: PropTypes.arrayOf(PropTypes.shape({})),
    isStartDate: PropTypes.bool,
    isEndDate: PropTypes.bool,
    weekDayNumber: PropTypes.number,
  }),
};

export default AbsenceCalendarDay;
