import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import PopoverCorner from '@/components/common/PopOverCorner.jsx';
import { EDIT_SHIFTS_AND_ATTENDANCES_MODAL } from '@/constants/modalTypes.js';
import {
  ATTENDANCE_EDIT_OTHERS_DISABLE,
  PAYROLL_VIEW_HIDE_EDIT,
} from '@/constants/Restrictions.js';
import { payrollTableColumns } from '@/constants/tableColumns.jsx';
import {
  parseMinutesToFormat,
  parseMinutesToHumanForm,
} from '@/utils/dateHelper.js';
import {
  displayPlannedDiff,
  getHoursAndColorsForPayrollRow,
  getLocationNameForId,
  getPayrollHours,
} from '@/utils/payrollHelpers.jsx';

import { isOwnAttendanceEditBlocked } from './PayrollTableRow.helpers.js';
import PayrollTableRowDetails from './PayrollTableRowDetails.redux.js';
import PayrollTableRowWarning from './PayrollTableRowWarning.jsx';

const messages = defineMessages({
  overtimeTitle: {
    id: 'payrollTable.overtime.title',
    defaultMessage: 'Nadgodziny {type}',
  },
  notFoundLocation: {
    id: 'location.notFoundLocation',
    defaultMessage: 'Brak lokalizacji',
  },
});

class PayrollTableRow extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showDetails: false,
    };
    this.showDetails = this.showDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      showDetails: nextProps.data.details.length
        ? this.state.showDetails
        : false,
    });
  }

  showDetails() {
    this.setState((prev) => ({
      showDetails: this.props.data.details.length
        ? !prev.showDetails
        : prev.showDetails,
    }));
  }

  render() {
    const { employee, data, userPermissions } = this.props;
    const showIcon =
      data.details.length > 0 &&
      data.details.some((detail) => detail.startAtt || detail.startShift);
    const row = getHoursAndColorsForPayrollRow(this.props.data, employee);
    const {
      attendanceStart,
      attendanceEnd,
      shiftStart,
      shiftEnd,
      startColor,
      endColor,
      early_in: earlyIn,
      late_out: lateOut,
    } = row.displayHours;

    const blocks50 =
      employee?.overtime?.[data.date]?.blocks?.filter(
        (block) => block.type === 'overtime50'
      ) || [];
    const blocks100 =
      employee?.overtime?.[data.date]?.blocks?.filter(
        (block) => block.type === 'overtime100'
      ) || [];

    const overtime50PopOver = {
      title: this.context.intl.formatMessage(messages.overtimeTitle, {
        type: '50%',
      }),
      content: (
        <div className="k-payroll__OvertimePopoverContent">
          {blocks50.map((block) => (
            <div>
              {moment(block.startAt).format('dd DD.MM, HH:mm')} -{' '}
              {moment(block.endAt).format('HH:mm')}
            </div>
          ))}
        </div>
      ),
    };
    const overtime100PopOver = {
      title: this.context.intl.formatMessage(messages.overtimeTitle, {
        type: '100%',
      }),
      content: (
        <div className="k-payroll__OvertimePopoverContent">
          {blocks100.map((block) => (
            <div>
              {moment(block.startAt).format('dd DD.MM, HH:mm')} -{' '}
              {moment(block.endAt).format('HH:mm')}
            </div>
          ))}
        </div>
      ),
    };

    const detailsClassNames = classnames('k-payroll__details', {
      'k-payroll__details--show':
        this.state.showDetails && this.props.visibleColumns.length > 1,
    });
    const dateClassNames = classnames('col_name day', {
      'k-payroll__col-name':
        !this.props.data.details.length && this.props.visibleColumns.length > 1,
    });

    const locations = this.props.data.locations
      .reduce((locationsList, location) => {
        const locationName = getLocationNameForId(
          location,
          this.props.userLocations,
          this.context.intl.formatMessage(messages.notFoundLocation)
        );
        return !locationsList.includes(locationName)
          ? [...locationsList, locationName]
          : locationsList;
      }, [])
      .join(' / ');

    const detailIds = row.details.map((detail) => detail.id);

    const {
      details,
      humanDate,
      photo,
      start,
      end,
      jobTitles,
      wage,
      absence_short_name: absenceShortName,
      absence_time: dataAbsenceTime,
      timeWorked,
      timeWorkedReal,
      timePlanned,
      sumQuotas,
      bonus_amount: bonusAmount,
      sumOvertime50,
      sumOvertime100,
      sumPaid,
      sumBreaks,
      nightTimeWorked,
      hours,
      warningTypes,
      absence,
    } = data;

    const isAbsenceWithHours =
      (!attendanceStart || attendanceStart === '__:__') &&
      (!shiftStart || shiftStart === '__:__') &&
      data.absence &&
      data.absence.absence_hours;

    const isAttendanceEditingBlocked =
      userPermissions.restrictions.includes(ATTENDANCE_EDIT_OTHERS_DISABLE) ||
      isOwnAttendanceEditBlocked(
        userPermissions.user_id,
        userPermissions.restrictions,
        Number(employee.id)
      );

    const formattedSumOvertime100 =
      sumOvertime100 || sumOvertime100 === 0
        ? parseMinutesToFormat(
            this.props.data.sumOvertime100,
            this.props.payrollSettings.timeFormatSetting.type
          )
        : '-';
    const formattedSumOvertime50 =
      sumOvertime50 || sumOvertime50 === 0
        ? parseMinutesToFormat(
            this.props.data.sumOvertime50,
            this.props.payrollSettings.timeFormatSetting.type
          )
        : '-';
    const formattedHours =
      absence && hours
        ? getPayrollHours(hours)
        : getPayrollHours(`${start || ''}-${end || ''}`);

    const absenceMinutes = dataAbsenceTime
      ? Math.round(dataAbsenceTime * 60)
      : 0;
    const absenceTime = parseMinutesToHumanForm(absenceMinutes);

    const items = [
      <td key={0} className={dateClassNames}>
        <div
          onClick={this.showDetails}
          role="presentation"
          className="dayWrapper"
        >
          <div>
            {details.length > 0 && this.props.visibleColumns.length > 1 && (
              <i
                className="material-icons sortdir noselect"
                style={{ marginTop: '0px' }}
              >
                {this.state.showDetails ? 'arrow_drop_down' : 'arrow_drop_up'}
              </i>
            )}
            {humanDate}
          </div>
          {warningTypes?.length ? (
            <PayrollTableRowWarning warningTypes={warningTypes} />
          ) : null}
        </div>
        {showIcon &&
          !this.props.userPermissions.restrictions.includes(
            PAYROLL_VIEW_HIDE_EDIT
          ) &&
          !isAttendanceEditingBlocked && (
            <div
              className="iconWrapper"
              role="presentation"
              onClick={() => {
                this.props.showModal(EDIT_SHIFTS_AND_ATTENDANCES_MODAL, {
                  employee: this.props.employee,
                  data,
                  userLocations: this.props.userLocations,
                  payoutSetting: this.props.payrollSettings.payoutSetting,
                });
              }}
            >
              <i className="material-icons">edit</i>
            </div>
          )}
      </td>,
      <td key={1}>{locations}</td>,
      isAbsenceWithHours ? (
        <td key={2}>{data.absence.absence_hours.slice(0, 5)}</td>
      ) : (
        <td key={2}>
          {row.disableBonus ? ( // When we are displaying form shifts row data has different structure
            // start_timestamp is form shifts and not attendance
            // and we have no matching attendance this.props.data.humanDat,so we put it this way
            (<span className="attendanceHoursSpan">
              {attendanceStart}
              {earlyIn && <span className="acceptedOvertimeIcon">N</span>}
            </span>)
          ) : (
            <span>
              {shiftStart}
              <br />
              <span
                className="attendanceHoursSpan"
                style={{ color: startColor }}
              >
                {attendanceStart}
                {earlyIn && <span className="acceptedOvertimeIcon">N</span>}
              </span>
            </span>
          )}
          {photo ? (
            <i className="material-icons showphoto">photo_camera</i>
          ) : (
            ''
          )}
        </td>
      ),
      isAbsenceWithHours ? (
        <td key={3}>{data.absence.absence_hours.slice(6, 11)}</td>
      ) : (
        <td key={3}>
          {row.disableBonus ? (
            <span className="attendanceHoursSpan">
              {attendanceEnd}
              {lateOut && <span className="acceptedOvertimeIcon">N</span>}
            </span>
          ) : (
            <span>
              {shiftEnd}
              <br />
              <span className="attendanceHoursSpan" style={{ color: endColor }}>
                {attendanceEnd}
                {lateOut && <span className="acceptedOvertimeIcon">N</span>}
              </span>
            </span>
          )}
        </td>
      ),
      <td key={10}>{formattedHours}</td>,
      <td key={4}>{jobTitles || '-'}</td>,
      <td key={5}>{wage || wage === 0 ? wage : '-'}</td>,
      <td key={11}>
        {sumBreaks || sumBreaks === 0
          ? parseMinutesToFormat(
              sumBreaks,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}
      </td>,
      <td key={20}>
        {absenceShortName ? `${absenceShortName} - ${absenceTime}` : '-'}
      </td>,
      <td key={6}>
        {timeWorked || timeWorked === 0
          ? parseMinutesToFormat(
              timeWorked,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}
      </td>,
      <td key={13}>
        {timeWorkedReal || timeWorkedReal === 0
          ? parseMinutesToFormat(
              timeWorkedReal,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}{' '}
        /{' '}
        {timePlanned || timePlanned === 0
          ? parseMinutesToFormat(
              timePlanned,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}
      </td>,
      <td key={14}>
        {(timeWorkedReal || timeWorkedReal === 0) &&
        (timePlanned || timePlanned === 0)
          ? displayPlannedDiff(
              timeWorkedReal,
              timePlanned,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}
      </td>,
      <td key={12}>
        {nightTimeWorked || nightTimeWorked === 0
          ? parseMinutesToFormat(
              nightTimeWorked,
              this.props.payrollSettings.timeFormatSetting.type
            )
          : '-'}
      </td>,
      <td key={7}>
        {sumQuotas || sumQuotas === 0 ? sumQuotas.toFixed(2) : '-'}
      </td>,
      <td key={8}>{bonusAmount || bonusAmount === 0 ? bonusAmount : '-'}</td>,
      <td key={16} className="k-payroll__popoverCell">
        {this.props.data.sumOvertime50 > 0 ? (
          <PopoverCorner popover={overtime50PopOver} materialIcon="help">
            {formattedSumOvertime50}
          </PopoverCorner>
        ) : (
          formattedSumOvertime50
        )}
      </td>,

      <td key={17} className="k-payroll__popoverCell">
        {this.props.data.sumOvertime100 > 0 ? (
          <PopoverCorner
            key={17}
            popover={overtime100PopOver}
            materialIcon="help"
          >
            {formattedSumOvertime100}
          </PopoverCorner>
        ) : (
          formattedSumOvertime100
        )}
      </td>,

      <td key={18} className="k-payroll__popoverCell">
        -
      </td>,
      <td key={15}>{sumPaid || sumPaid === 0 ? sumPaid.toFixed(2) : '-'}</td>,
    ];
    const colgroup = (
      <colgroup>
        {payrollTableColumns.colgroup.map((width, i) => {
          if (this.props.visibleColumns.includes(i))
            return <col key={i} style={{ width: `${width}%` }} />;
          return null;
        })}
      </colgroup>
    );
    return (
      <tr>
        <td colSpan={this.props.visibleColumns.length} style={{ padding: 0 }}>
          <table className="table">
            {colgroup}
            <tbody>
              <tr key="summaryRowBottom">
                {items.map((item, i) => {
                  if (this.props.visibleColumns.includes(i)) return item;
                  return null;
                })}
              </tr>
              <tr className={detailsClassNames}>
                <td
                  colSpan={this.props.visibleColumns.length}
                  style={{ padding: 0 }}
                >
                  <PayrollTableRowDetails
                    visibleColumns={this.props.visibleColumns}
                    details={row.details}
                    colgroup={colgroup}
                    userLocations={this.props.userLocations}
                    showQuotas={this.props.showQuotas}
                    payrollSettings={this.props.payrollSettings}
                    detailIds={detailIds}
                    employeeId={employee.id}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

PayrollTableRow.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        isAvailability: PropTypes.bool,
      })
    ),
    locations: PropTypes.arrayOf(PropTypes.string),
    humanDate: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    photo: PropTypes.string,
    jobTitles: PropTypes.string,
    wage: PropTypes.string,
    timeWorked: PropTypes.number,
    timeWorkedReal: PropTypes.number,
    timePlanned: PropTypes.number,
    sumQuotas: PropTypes.number,
    sumPaid: PropTypes.number,
    bonus_amount: PropTypes.number,
    sumBreaks: PropTypes.number,
    nightTimeWorked: PropTypes.number,
    sumOvertime50: PropTypes.number,
    sumOvertime100: PropTypes.number,
    absence: PropTypes.shape({
      absence_hours: PropTypes.string,
    }),
    absence_short_name: PropTypes.string,
    absence_time: PropTypes.number,
    hours: PropTypes.string,
    warningTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  payrollSettings: PropTypes.shape({
    timeFormatSetting: PropTypes.shape({
      type: PropTypes.string,
    }),
    payoutSetting: PropTypes.shape({}),
  }),
  showQuotas: PropTypes.bool,
  visibleColumns: PropTypes.arrayOf(PropTypes.number),
  showModal: PropTypes.func,
  employee: PropTypes.shape({
    id: PropTypes.string,
    overtime: PropTypes.shape({
      blocks: PropTypes.arrayOf({
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
  payoutSetting: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
    user_id: PropTypes.number,
  }),
};

PayrollTableRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default PayrollTableRow;
