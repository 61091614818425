import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedNumber } from 'react-intl';

const CompareWidget = props => {
  const { value1, value2, unit } = props;

  const percentClass = classnames('compareWidget__percent', {
    'compareWidget__percent--red': value1 > value2,
    'compareWidget__percent--up': value1 > value2,
    'compareWidget__percent--green': value1 < value2,
  });

  let diff = Math.round(((value2 - value1) / value2) * 1000) / -10;
  if (value2 === 0) diff = 0; // We dont want to have NaN or Inf

  return (
    <div className="compareWidget">
      <div className="compareWidget__valueContainer">
        <div className="compareWidget__values">
          <div className="compareWidget__upperValue">
            {props.currency ? (
              <FormattedNumber value={value1} currency={unit} minimumFractionDigits={0} />
            ) : (
              <span>
                {value1} {unit}
              </span>
            )}
          </div>

          <div className="compareWidget__lowerValue">
            {props.currency ? (
              <FormattedNumber value={value2} currency={unit} minimumFractionDigits={0} />
            ) : (
              <span>
                {value2} {unit}
              </span>
            )}
          </div>
        </div>
        <div className={percentClass}>
          {diff > 0 ? '+' : null}
          {diff}%
        </div>
      </div>
    </div>
  );
};
CompareWidget.propTypes = {
  value1: PropTypes.number,
  value2: PropTypes.number,
  unit: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
export default CompareWidget;
