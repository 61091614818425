import { defineMessages } from 'react-intl';

const headerMessages = defineMessages({
  issueDate: {
    id: 'payments.issueDate',
    defaultMessage: 'Data wystawienia',
  },
  payToDate: {
    id: 'payments.payToDate',
    defaultMessage: 'Termin płatności',
  },
  paymentAmount: {
    id: 'payments.paymentAmount',
    defaultMessage: 'Kwota',
  },
  paymentStatus: {
    id: 'payments.paymentStatus',
    defaultMessage: 'Status',
  },
  options: {
    id: 'payments.options',
    defaultMessage: 'Opcje',
  },
});

export const statusMessages = defineMessages({
  issued: {
    id: 'payments.status.issued',
    defaultMessage: 'Nieopłacona',
  },
  pending: {
    id: 'payments.status.pending',
    defaultMessage: 'Oczekuje na potwierdzenie',
  },
  paid: {
    id: 'payments.status.paid',
    defaultMessage: 'Opłacona',
  },
  unknown: {
    id: 'payments.status.unknown',
    defaultMessage: 'Nieznany status',
  },
});

export const headers = [
  headerMessages.issueDate,
  headerMessages.payToDate,
  headerMessages.paymentAmount,
  headerMessages.paymentStatus,
  headerMessages.options,
];

export const planMessages = defineMessages({
  schedule: {
    id: 'payments.plan.schedule',
    defaultMessage: 'Planuję czas pracy',
  },
  scheduleAndRcp: {
    id: 'payments.plan.scheduleAndRcp',
    defaultMessage: 'Planuję i rozliczam',
  },
  premium: {
    id: 'payments.plan.premium',
    defaultMessage: 'Pakiet premium',
  },
  dedicated: {
    id: 'payments.plan.dedicated',
    defaultMessage: 'Plan dedykowany',
  },
  custom: {
    id: 'payments.plan.custom',
    defaultMessage: 'Plan indywidualny',
  },
});
