import { CHANGE_JOBTITLE_FILTER, CHANGE_JOBTITLE_FILTER_GROUPED } from '@/constants/ActionTypes.js';

export const changeJobTitleFilter = jobTitleObjects => ({
  type: CHANGE_JOBTITLE_FILTER,
  payload: jobTitleObjects,
});

export const changeJobTitleFilterGrouped = jobTitleObjects => ({
  type: CHANGE_JOBTITLE_FILTER_GROUPED,
  payload: jobTitleObjects,
});
