export const limitPeriods = {
  week: 7,
  month: 31,
  year: 366,
};

export const limitUnits = {
  minutes: 24 * 60,
  days: 1,
};

export const limitPeriodOptions = Object.keys(limitPeriods);
export const limitUnitOptions = Object.keys(limitUnits);
