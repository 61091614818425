import ExportModal from '@/components/common/exports/ExportModal/ExportModal.redux';
import { FILE_PDF_BACKGROUND, FILE_PDF_COLOR } from '@/constants/colors.js';
import { AttendanceExportData } from '@/constants/exportData.js';

const columnList = columns => ({
  type: 'columnList',
  id: 'selectedColumns',
  values: columns,
  preselected: [0, 2, 3, 5],
});

const ExportAttendanceModal = props => (
  <ExportModal
    {...props}
    formats={[
      {
        text: 'PDF',
        backgroundColor: FILE_PDF_BACKGROUND,
        color: FILE_PDF_COLOR,
        options: [columnList(AttendanceExportData.headers)],
      },
    ]}
  />
);

ExportAttendanceModal.propTypes = {};

export default ExportAttendanceModal;
