import { PropTypes } from 'prop-types';
import { useRef } from 'react';

import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements.js';
import { checkRecommendedScheduleDisplayPermissions } from '@/utils/recommendedScheduleHelpers';

import ScheduleTableHeaderGraphCell from './ScheduleTableHeaderGraphCell.redux.js';
import { useScheduleTableHeaderGraphs } from './useScheduleTableHeaderGraphs';

import './ScheduleTableGraphsHeader.scss';

const ScheduleTableGraphsHeader = ({ dateArray, userPermissions, locationId }) => {
  const graphHeaderRef = useRef(null);
  const [top] = useMultipleStickyElements(graphHeaderRef, 'scheduleTableHeader--sticky');
  const showRecommendedScheduleGraph = checkRecommendedScheduleDisplayPermissions(userPermissions.restrictions);
  const { colSpan } = useScheduleTableHeaderGraphs();

  return (
    <tr
      ref={graphHeaderRef}
      className="scheduleTableHeader scheduleTableHeader--sticky scheduleTableGraphsHeader"
      style={{
        top,
      }}
    >
      <th className="schedule-chart-cell schedule-chart" />
      {dateArray.map(date => (
        <th key={`${date}-workers`} className="schedule-chart" colSpan={colSpan}>
          <ScheduleTableHeaderGraphCell
            showRecommendedScheduleGraph={showRecommendedScheduleGraph}
            date={date}
            locationId={locationId}
          />
        </th>
      ))}
    </tr>
  );
};

ScheduleTableGraphsHeader.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationId: PropTypes.number,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleTableGraphsHeader;
