import * as AT from '@/constants/ActionTypes';
import { MainDateStore } from '@/types/mainDateStore';

import { formatDefaultViewDates } from './uiState.utils';

export const changeDefaultViewDateStore = (path: string, mainDateStore: MainDateStore) => (dispatch, getState) => {
  dispatch({
    type: AT.CHANGE_DEFAULT_VIEW_DATE_STORE,
    payload: formatDefaultViewDates(path, mainDateStore, getState().reducer.uiState.defaultDateStores),
  });
};
