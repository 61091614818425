export const formatHighlightData = (highlight, textToHighlight, position = 0) => {
  if (!textToHighlight) return [];
  if (highlight.length === 0) return [{
    value: textToHighlight,
    highlighted: false,
  }];

  const { offset, length } = highlight[0];
  const lengthOfHighlight = offset+length-position;
  return [
    {
      value: textToHighlight.slice(0, offset-position),
      highlighted: false,
    },
    {
      value: textToHighlight.slice(offset-position, offset+length-position),
      highlighted: true,
    },
    ...formatHighlightData(
      highlight.slice(1),
      textToHighlight.slice(offset+length-position),
      position+lengthOfHighlight),
  ];
};