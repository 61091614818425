import { GET_GEOLOCATION, GET_USER_MEDIA, UPDATE_WINDOW_SIZE } from '@/constants/ActionTypes.js';

export const updateWindowSize = () => ({
  type: UPDATE_WINDOW_SIZE,
});

export const getUserMedia = userMedia => ({
  type: GET_USER_MEDIA,
  payload: userMedia,
});

export const getGeolocation = () => dispatch => {
  const geolocationAPI = navigator.geolocation;
  if (geolocationAPI) {
    geolocationAPI.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      dispatch({
        type: GET_GEOLOCATION,
        payload: { lat: latitude, lng: longitude },
      });
    });
  }
};