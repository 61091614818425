import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { KadroMultiSelect } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useMultiSelect } from '@/hooks';

import { getEmployeesOptions, getMultiSelectEmployeesMessages, modalModifiers } from './NewChatModal.helpers';
import { messages } from './NewChatModal.messages';

const NewChatModal = (props, context) => {
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [employeesItems, multiSelectHandlers] = useMultiSelect(getEmployeesOptions(props.chatUsers, context.intl));

  const multiSelectEmployeesMessages = useMemo(() => getMultiSelectEmployeesMessages(context.intl), []);

  useEffect(() => {
    multiSelectHandlers.setItems(getEmployeesOptions(props.chatUsers));
  }, [props.chatUsers]);

  useEffect(() => {
    setErrors('');
  }, [employeesItems]);

  const clearAndHide = useCallback(() => {
    multiSelectHandlers.deselectAll();
    props.hideModal();
  }, []);

  const submit = useCallback(async () => {
    const selectedEmployees = multiSelectHandlers.getSelected();
    if (!selectedEmployees.length) {
      setErrors(context.intl.formatMessage(messages.notSelectedEmployees));
      return;
    }
    try {
      setLoading(true);
      await props.createConversation(selectedEmployees);
    } finally {
      setLoading(false);
      clearAndHide();
    }
  }, [employeesItems]);

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={clearAndHide}
      title={context.intl.formatMessage(messages.modalTitle)}
      modifiers={modalModifiers}
      onSubmit={submit}
      disableConfirm={loading}
      confirmText={context.intl.formatMessage(messages.begin)}
      errorMessage={errors}
    >
      <div>{context.intl.formatMessage(messages.modalDescription)}</div>
      <KadroMultiSelect messages={multiSelectEmployeesMessages} items={employeesItems} handlers={multiSelectHandlers} />
    </MDKadroModal>
  );
};

NewChatModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

NewChatModal.propTypes = {
  hideModal: PropTypes.func,
  showModal: PropTypes.bool,
  chatUsers: PropTypes.arrayOf(PropTypes.shape({})),
  createConversation: PropTypes.func,
};

export default NewChatModal;
