import { connect } from 'react-redux';

import { toggleModal } from '@/actions/companymanage';
import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import {
  deselectAllEmployeesRows,
  flipScheduleMode,
} from '@/actions/schedule.jsx';
import { saveTemplates } from '@/actions/templates.js';
import { changeScheduleSearchString, showModal } from '@/actions/uiState.js';
import ScheduleTable from '@/components/scheduler/ScheduleTable.jsx';

const mapStateToProps = (state) => ({
  userTemplates: state.reducer.userTemplates,
  mainDateStore: state.reducer.mainDateStore,
  scheduleUIState: state.reducer.scheduleUIState,
  currentCompany: state.reducer.currentCompany,
  locationSettings: state.reducer.settings.locationSettings,
  userEmployees: state.reducer.userEmployees,
  userLocations: state.reducer.userLocations,
  userJobTitles: state.reducer.userJobTitles,
  openShiftsLength: state.reducer.openShifts.length,
  absences: state.reducer.overtimeCollections,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

const mapDispatchToProps = {
  saveTemplates,
  mainDateMoveLeft,
  mainDateMoveRight,
  deselectAllEmployeesRows,
  flipScheduleMode,
  toggleModal,
  showModal,
  changeScheduleSearchString,
};

const SchedulerTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleTable);

export default SchedulerTableContainer;
