import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'dashboard.ownerTrial.title',
    defaultMessage: 'Zaczynamy!',
  },
  subtitle: {
    id: 'dashboard.ownerTrial.subtitle',
    defaultMessage: 'Wykonaj poniższe kroki, aby zapoznać się z możliwościami platformy w czasie okresu próbnego.',
  },
  steps: {
    id: 'dashboard.ownerTrial.steps',
    defaultMessage: '{current}/{total} kroków',
  },
  watchHow: {
    id: 'dashboard.ownerTrial.watchHow',
    defaultMessage: 'zobacz jak',
  },
  help: {
    id: 'dashboard.ownerTrial.help',
    defaultMessage: 'centrum pomocy',
  },
  video: {
    id: 'dashboard.ownerTrial.video',
    defaultMessage: 'video',
  },
  tutorial: {
    id: 'dashboard.ownerTrial.trialTutorial',
    defaultMessage: 'samouczek',
  },
});
