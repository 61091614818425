import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  date: {
    id: 'employeeTimesheet.table.date',
    defaultMessage: 'Dzień',
  },
  location: {
    id: 'employeeTimesheet.table.location',
    defaultMessage: 'Lokalizacja',
  },
  from: {
    id: 'employeeTimesheet.table.from',
    defaultMessage: 'Od',
  },
  to: {
    id: 'employeeTimesheet.table.to',
    defaultMessage: 'Do',
  },
  jobTitle: {
    id: 'employeeTimesheet.table.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  absence: {
    id: 'employeeTimesheet.table.absence',
    defaultMessage: 'Nieobecność',
  },
  duration: {
    id: 'employeeTimesheet.table.duration',
    defaultMessage: 'Czas trwania',
  },
  emptyTable: {
    id: 'employeeTimesheet.table.emptyTable',
    defaultMessage: 'Nie masz wpisanych żadnych godzin pracy w wybranym przedziale czasowym',
  },
});
