import moment from 'moment-timezone';
import { defineMessages } from 'react-intl';

import { deleteRecommendedSchedule } from '@/actions/recommendedSchedule';
import {
  hideModal,
  setAutoschedulerLocation,
  showModal,
  showOpenShiftFromAutoGenerateModal,
  toggleAutoGenerateScheduleStatus,
} from '@/actions/uiState.js';
import { getWorkingRulesViolationsForCurrentDateArray } from '@/actions/workingRules.js';
import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences.js';
import {
  AUTO_GENERATE_ERROR,
  AUTO_GENERATE_IMPORT_ERROR,
  AUTO_GENERATE_IMPORT_SUCCESS,
  AUTO_GENERATE_SUBMIT_NO_EMPLOYEES_SELECTED,
  CANCEL_AUTOSCHEDULER,
  FINISH_AUTOSCHEDULER,
} from '@/constants/ActionTypes.js';
import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import { AUTO_GENERATE_SCHEDULE_MODAL } from '@/constants/modalTypes.js';
import { IGNORE_VALIDATING_WORKING_RULES } from '@/constants/Permissions.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes';
import { formatExistingShiftsForFlexibleTemplate, getConfigurationOptions } from '@/utils/autoschedulerHelpers.jsx';
import { pad } from '@/utils/baseHelpers.js';
import { getJobTitleIdsFromContracts, getRelevantContractsForMultipleDates } from '@/utils/contracts';
import { getRelevantDateRange } from '@/utils/dateHelper.js';
import { createOvertimeCollectionsBlocks } from '@/utils/overtimeHelpers';
import { generateTimestamps, getNumberOfExistingShifts, timezone } from '@/utils/shiftHelpers';

import { conn } from './index';
import { massDeleteOpenShiftsByIds } from './openShifts.js';
import { addMultipleShifts } from './schedule.jsx';
import { createTemplateFromOpenShifts } from './templates.js';

const messages = defineMessages({
  autoschedulerFailure: {
    id: 'schedule.autoGenerate.error',
    defaultMessage: 'Nie udało się wygenerować grafiku. Skontaktuj się z nami po więcej informacji.',
  },
  errorTitle: {
    id: 'schedule.autoGenerate.errorTitle',
    defaultMessage: 'Błąd!',
  },
  successTitle: {
    id: 'schedule.autoGenerate.successTitle',
    defaultMessage: 'Sukces!',
  },
  importSuccess: {
    id: 'schedule.autoGenerate.importTemplateSuccess',
    defaultMessage: 'Poprawnie zaimportowano szablon.',
  },
  noEmployeesSelected: {
    id: 'schedule.autoGenerate.noEmployeesSelected',
    defaultMessage: 'Aby wygenerować grafik musisz wybrać conajmniej jednego pracownika.',
  },
  autoschedulerCanceled: {
    id: 'schedule.autoGenerate.canceled',
    defaultMessage: 'Generowanie grafiku zostało anulowane.',
  },
  cancelTitle: {
    id: 'schedule.autoGenerate.cancelTitle',
    defaultMessage: 'Anulowano!',
  },
});

export const assignAvailabilityType = (availabilityBlocks, userCustomTypes) =>
  availabilityBlocks.map((block) => {
    let availabilityType;

    if (block.type === 'custom') {
      availabilityType = userCustomTypes.find((uct) => uct.id === block.type_id);
    } else {
      availabilityType = defaultAvailabilityTypes.find((uct) => uct.id === block.type);
    }

    if (!availabilityType) {
      availabilityType = {
        requires_time: !!block.hours,
        countHoursInPayroll: false,
        count_hours: 0,
        is_availability: false,
      };
    }

    return {
      ...block,
      isAvailability: availabilityType.is_availability,
    };
  });

export const scheduleAutogenerateFailure = (data) => (dispatch, getState, intl) => {
  const notification = {
    title: intl.formatMessage(messages.errorTitle, {}),
    description: intl.formatMessage(messages.autoschedulerFailure, {}),
    type: 'error',
    options: { timeOut: 0, attention: true },
  };
  dispatch(
    (() => ({
      type: AUTO_GENERATE_ERROR,
      payload: data,
      notification,
    }))(),
  );
};
export const cancelScheduleGenerationAndShowNotification = (data) => (dispatch, getState, intl) => {
  const notification = {
    title: intl.formatMessage(messages.cancelTitle, {}),
    description: intl.formatMessage(messages.autoschedulerCanceled, {}),
    type: 'success',
  };
  dispatch({ type: FINISH_AUTOSCHEDULER, payload: data, notification });
};

const getAbsencesBlocks = (absences) =>
  absences.reduce((acc, cur) => {
    if (NOT_COUNTED_ABSENCE_STATUSES.includes(cur.status)) {
      return acc;
    }

    const from = moment(cur.from);
    const to = moment(cur.to);
    const daysCount = to.diff(from, 'day') + 1;

    if (daysCount === 1) {
      return [...acc, { date: cur.from, hours: cur.absence_hours }];
    }

    const splittedAbsences = [];
    for (let i = 0; i < daysCount; i++) {
      const date = moment(from).add(i, 'day').format('YYYY-MM-DD');
      splittedAbsences.push({ date });
    }

    return [...acc, ...splittedAbsences];
  }, []);

const getAvaTimestamps = (ava) => {
  let startMoment = null;
  let endMoment = null;
  if (ava.hours) {
    startMoment = moment.tz(`${ava.date} ${ava.hours.split('-')[0]}`, timezone);
    endMoment = moment.tz(`${ava.date} ${ava.hours.split('-')[1]}`, timezone);
  } else {
    startMoment = moment.tz(ava.date, timezone).startOf('day');
    endMoment = moment(startMoment).add(1, 'days');
  }

  if (!endMoment.isAfter(startMoment)) {
    endMoment = endMoment.add(1, 'days');
  }

  return { start_timestamp: startMoment.format(), end_timestamp: endMoment.format() };
};

const formatAvaBlocks = (ava) => ({
  start_timestamp: ava.start_timestamp,
  end_timestamp: ava.end_timestamp,
  count_hours_in_payroll: ava.count_hours_in_payroll,
  count_hours: ava.count_hours,
});

export const getAvabilityBlocks = (
  avaBlocks,
  absences,
  overtimeCollections,
  relevantDateArray,
  availableUndefinedDays,
) => {
  const absenceBlocks = getAbsencesBlocks(absences);
  const overtimeCollectionsBlocks = createOvertimeCollectionsBlocks(overtimeCollections);
  const blocks = [...avaBlocks, ...absenceBlocks, ...overtimeCollectionsBlocks].reduce(
    (acc, cur) => {
      const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = getAvaTimestamps(cur);

      if (cur.isAvailability) {
        const newAvailability = {
          date: cur.date,
          hours: cur.hours,
          start_timestamp: startTimestamp,
          end_timestamp: endTimestamp,
          count_hours_in_payroll: !!cur.count_hours_in_payroll,
          count_hours: cur.count_hours || 0,
        };
        const isAbsenceForDateWithAvailability = [...absenceBlocks, ...overtimeCollectionsBlocks].some(
          (absence) => absence.date === cur.date,
        );
        return isAbsenceForDateWithAvailability
          ? acc
          : { ...acc, available_at: [...acc.available_at, newAvailability] };
      }

      const duplicate = acc.unavailable_at.find((item) => moment(item.date).diff(moment(cur.date), 'day') === 0);
      if (duplicate) {
        return acc;
      }
      return {
        ...acc,
        unavailable_at: [
          ...acc.unavailable_at,
          {
            date: cur.date,
            hours: cur.hours,
            start_timestamp: startTimestamp,
            end_timestamp: endTimestamp,
            count_hours_in_payroll: !!cur.count_hours_in_payroll,
            count_hours: cur.count_hours || 0,
          },
        ],
      };
    },
    { available_at: [], unavailable_at: [] },
  );

  const unavailableForEmptyDates = !availableUndefinedDays
    ? relevantDateArray
        .filter(
          (date) =>
            !blocks.available_at.some((ava) => ava.date === date) &&
            !blocks.unavailable_at.some((ava) => ava.date === date),
        )
        .map((date) => {
          const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = getAvaTimestamps({ date });
          return {
            date,
            hours: null,
            start_timestamp: startTimestamp,
            end_timestamp: endTimestamp,
            count_hours_in_payroll: false,
            count_hours: 0,
          };
        })
    : [];

  return {
    available_at: blocks.available_at.map(formatAvaBlocks),
    unavailable_at: [...blocks.unavailable_at, ...unavailableForEmptyDates].map(formatAvaBlocks),
  };
};

const getEmployeesForAutoscheduler = (
  employees,
  selectedEmployees,
  userCustomTypes,
  scheduleAbsences,
  overtimeCollections,
  relevantDateArray,
  availableUndefinedDays,
  contracts,
) =>
  employees
    .filter((e) => selectedEmployees.map((employee) => employee.value).includes(e.id) && !e.deleted)
    .map((employee) => {
      const avaBlocksWithType = assignAvailabilityType(employee.availability_blocks, userCustomTypes);
      const { available_at: availableAt, unavailable_at: unavailableAt } = getAvabilityBlocks(
        avaBlocksWithType,
        scheduleAbsences[employee.id] || [],
        overtimeCollections[employee.id] || [],
        relevantDateArray,
        availableUndefinedDays,
      );
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(
        employeeContracts,
        relevantDateArray[0],
        relevantDateArray[relevantDateArray.length - 1],
      );
      const jobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
      return {
        id: employee.id,
        first_name: employee.first_name,
        last_name: employee.last_name,
        job_title_ids: jobTitleIds,
        available_at: availableAt,
        unavailable_at: unavailableAt,
      };
    });

const getExistingShifts = (employees, relevantDateArray) =>
  employees
    .reduce((result, employee) => [...result, ...employee.shifts], [])
    .filter((shift) => relevantDateArray.includes(shift.date))
    .map((shift) => ({
      id: shift.id,
      startTimestamp: shift.start_timestamp,
      endTimestamp: shift.end_timestamp,
      jobTitleId: shift.job_title.id,
      employeeId: shift.employee.id,
      isPersisted: true,
      workingHours: shift.working_hours,
      date: shift.date,
    }));
const getShiftsToSend = (
  shifts,
  relevantDateArray,
  existingShifts,
  configuration,
  calendarData,
  userJobTitles,
  templateType,
) => {
  const relevantExistingShifts =
    templateType === 'flexible' && configuration.include_current_shifts.enabled
      ? formatExistingShiftsForFlexibleTemplate(existingShifts)
      : existingShifts;
  const openShiftsIdsToDelete = [];
  const shiftsToSend = shifts
    .filter((shift) => {
      if (configuration.omit_holidays.enabled) {
        return (
          !calendarData.holidays.find((h) => h.freeFromWork && moment(h.date).diff(moment(shift.date), 'day') === 0) &&
          relevantDateArray.includes(shift.date)
        );
      }
      return relevantDateArray.includes(shift.date);
    })
    .map((shift, index) => {
      const shiftJobTitleId = shift.job_title_id || shift.job_title?.id;
      const jobTitle = userJobTitles.find((j) => j.id === shiftJobTitleId);
      const { startTimestamp, endTimestamp } = generateTimestamps(shift.working_hours, shift.date);
      const numberOfExistingShifts = getNumberOfExistingShifts(shift, relevantExistingShifts);

      const amount = configuration.include_current_shifts.enabled
        ? shift.amount - numberOfExistingShifts
        : shift.amount;

      if (amount <= 0) {
        return null;
      }

      if (shift.openShiftId) openShiftsIdsToDelete.push(shift.openShiftId);

      const formattedShift = {
        date: shift.date,
        hours: shift.working_hours,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
        duration: 8,
        job_title_id: shiftJobTitleId,
        job_title: jobTitle,
        amount,
        id: index,
      };

      if (configuration.optimize_job_category_distribution_rule?.enabled) {
        formattedShift.job_category = shift.gid || 0;
      }
      return formattedShift;
    })
    .filter(Boolean);
  return { shiftsToSend, openShiftsIdsToDelete };
};

const getShiftsToAdd = (scheduled, currentLocationId, userJobTitles) =>
  scheduled.map((shift) => {
    const startHours = moment(shift.start_timestamp).format('HH:mm');
    const endHours = moment(shift.end_timestamp).format('HH:mm');
    const shiftToAdd = {
      comment: '',
      working_hours: `${startHours}-${endHours}`,
      employee: { id: shift.employee_id },
      job_title: userJobTitles.find((jobTitle) => jobTitle.id === shift.job_title_id),
      draft: true,
      date: shift.start_timestamp.split('T')[0],
      location: { id: currentLocationId },
    };
    return shiftToAdd;
  });

const processUnsetShifts = (result) => (dispatch) => {
  if (result.unset.length) {
    dispatch(
      showOpenShiftFromAutoGenerateModal(
        result.unset.map((shift) => {
          const startHours = moment(shift.start_timestamp).format('HH:mm');
          const endHours = moment(shift.end_timestamp).format('HH:mm');
          return {
            ...shift,
            hours: `${startHours}-${endHours}`,
            date: shift.start_timestamp.split('T')[0],
          };
        }),
      ),
    );
  }
};

const fetchAutoschedulerResultsSuccess = (result, currentLocationId) => (dispatch, getState) => {
  const { userJobTitles, scheduleLocationFilter } = getState().reducer;

  if (!scheduleLocationFilter[0]) return;
  dispatch(toggleAutoGenerateScheduleStatus(false));

  if (result?.scheduled?.length) {
    const shifts = getShiftsToAdd(result.scheduled, currentLocationId, userJobTitles);
    dispatch(addMultipleShifts(shifts, 0, 'regular')).then(() => {
      dispatch(getWorkingRulesViolationsForCurrentDateArray());
      dispatch(hideModal());
      dispatch(processUnsetShifts(result));
    });
  } else {
    dispatch(hideModal());
    dispatch(processUnsetShifts(result));
  }

  // Stop polling the endpoint.
};

export const finishAutoscheduler = (result, currentLocationId) => async (dispatch, getState) => {
  const { openShiftsIdsToDelete } = getState().reducer.autoscheduler;
  await dispatch(massDeleteOpenShiftsByIds(openShiftsIdsToDelete));
  dispatch(fetchAutoschedulerResultsSuccess(result, currentLocationId));
  dispatch({
    type: FINISH_AUTOSCHEDULER,
    payload: result,
  });
};

export const sendAutoSchedulerInformation =
  (
    shifts,
    dateArray,
    selectedEmployees,
    configurationArray,
    shortTimeout = true,
    availableUndefinedDays,
    templateType,
  ) =>
  async (dispatch, getState) => {
    const timeout = shortTimeout ? 1 : 10;
    const {
      scheduleLocationFilter,
      settings,
      userCustomTypes,
      calendarData,
      absences: { scheduleAbsences },
      userJobTitles,
      overtimeCollections,
      userPermissions,
      contracts,
    } = getState().reducer;
    const configuration = getConfigurationOptions(configurationArray);

    const currentLocationId = scheduleLocationFilter[0];
    const currentLocationSettings = settings.locationSettings[currentLocationId];
    const disableShiftsEditUntilDate = currentLocationSettings
      ? currentLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    const relevantDateArray = getRelevantDateRange(dateArray, disableShiftsEditUntilDate);

    const ignoreValidatingWorkingRules = userPermissions.permissions.includes(IGNORE_VALIDATING_WORKING_RULES);

    const startDate = ignoreValidatingWorkingRules
      ? relevantDateArray[0]
      : moment(relevantDateArray[0]).startOf('week').subtract(4, 'weeks').format('YYYY-MM-DD');
    const endDate = ignoreValidatingWorkingRules
      ? relevantDateArray[relevantDateArray.length - 1]
      : moment(relevantDateArray[relevantDateArray.length - 1])
          .endOf('week')
          .add(4, 'weeks')
          .format('YYYY-MM-DD');

    const response = await conn.getSchedule(currentLocationId, startDate, endDate, 'regular', true);
    const existingShifts = getExistingShifts(response.data.schedule.employees, relevantDateArray);
    const { shiftsToSend, openShiftsIdsToDelete } = getShiftsToSend(
      shifts,
      relevantDateArray,
      existingShifts,
      configuration,
      calendarData,
      userJobTitles,
      templateType,
    );

    const employees = getEmployeesForAutoscheduler(
      response.data.schedule.employees,
      selectedEmployees,
      userCustomTypes,
      scheduleAbsences,
      overtimeCollections,
      relevantDateArray,
      availableUndefinedDays,
      contracts,
    );

    dispatch(toggleAutoGenerateScheduleStatus(true));
    dispatch(setAutoschedulerLocation(currentLocationId));

    try {
      const autoscheduleObject = {
        shifts: shiftsToSend,
        employees,
        solving_limit_minutes: timeout,
        configuration,
        existingShifts,
        elastic_template: templateType === TEMPLATE_TYPES.FLEX,
      };

      const autoscheduleObjectToSend =
        templateType === TEMPLATE_TYPES.BASIC ? { ...autoscheduleObject, merge_shifts: false } : autoscheduleObject;

      const result = await conn.autoschedule(autoscheduleObjectToSend);

      const timer = setInterval(async () => {
        const {
          autoscheduler: { isScheduleGenerationCancelled },
        } = getState().reducer;

        try {
          const results = await conn.fetchAutoschedulerResults(result.data.task_id);

          if (isScheduleGenerationCancelled && ['SUCCESS', 'PENDING'].includes(results.data.state)) {
            dispatch(cancelScheduleGenerationAndShowNotification(results.data));
            dispatch(deleteRecommendedSchedule(startDate, endDate, currentLocationId));
            clearInterval(timer);
          }

          if (results.data.state === 'SUCCESS') {
            await dispatch(massDeleteOpenShiftsByIds(openShiftsIdsToDelete));
            dispatch(fetchAutoschedulerResultsSuccess(results.data.result, currentLocationId));
            clearInterval(timer);
          }

          if (results.data.state === 'ERROR') {
            dispatch(hideModal());
            dispatch(scheduleAutogenerateFailure(results.data));
            clearInterval(timer);
          }
        } catch (error) {
          // Something went wrong
          clearInterval(timer);
          dispatch(hideModal());
          dispatch(scheduleAutogenerateFailure(error));
          console.error(error);
        }
      }, 5000);
    } catch (error) {
      dispatch(hideModal());
      dispatch(scheduleAutogenerateFailure(error));
      console.error(error);
    }
  };

export const sendAutoSchedulerInformationViaProxy =
  (shifts, dateArray, selectedEmployees, configurationArray, availableUndefinedDays, templateType, timeout) =>
  async (dispatch, getState) => {
    const {
      scheduleLocationFilter,
      settings,
      userCustomTypes,
      calendarData,
      absences: { scheduleAbsences },
      userJobTitles,
      overtimeCollections,
      userPermissions,
      contracts,
    } = getState().reducer;
    const configuration = getConfigurationOptions(configurationArray);
    const currentLocationId = scheduleLocationFilter[0];
    const currentLocationSettings = settings.locationSettings[currentLocationId];
    const disableShiftsEditUntilDate = currentLocationSettings
      ? currentLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    const relevantDateArray = getRelevantDateRange(dateArray, disableShiftsEditUntilDate);

    const ignoreValidatingWorkingRules = userPermissions.permissions.includes(IGNORE_VALIDATING_WORKING_RULES);

    const startDate = ignoreValidatingWorkingRules
      ? relevantDateArray[0]
      : moment(relevantDateArray[0]).startOf('week').subtract(4, 'weeks').format('YYYY-MM-DD');
    const endDate = ignoreValidatingWorkingRules
      ? relevantDateArray[relevantDateArray.length - 1]
      : moment(relevantDateArray[relevantDateArray.length - 1])
          .endOf('week')
          .add(4, 'weeks')
          .format('YYYY-MM-DD');

    const response = await conn.getSchedule(currentLocationId, startDate, endDate, 'regular', true);
    const existingShifts = getExistingShifts(response.data.schedule.employees, relevantDateArray);
    const { shiftsToSend, openShiftsIdsToDelete } = getShiftsToSend(
      shifts,
      relevantDateArray,
      existingShifts,
      configuration,
      calendarData,
      userJobTitles,
      templateType,
    );

    const employees = getEmployeesForAutoscheduler(
      response.data.schedule.employees,
      selectedEmployees,
      userCustomTypes,
      scheduleAbsences,
      overtimeCollections,
      relevantDateArray,
      availableUndefinedDays,
      contracts,
    );
    dispatch(toggleAutoGenerateScheduleStatus(true));
    dispatch(setAutoschedulerLocation(currentLocationId));
    try {
      const autoscheduleObject = {
        shifts: shiftsToSend,
        employees,
        solvingTime: timeout,
        configuration,
        existingShifts,
        is_elastic_template: templateType === TEMPLATE_TYPES.FLEX,
      };
      const autoscheduleObjectToSend =
        templateType === TEMPLATE_TYPES.BASIC ? { ...autoscheduleObject, merge_shifts: false } : autoscheduleObject;

      const result = await conn.autoscheduleProxy(autoscheduleObjectToSend);

      const { task_id: taskId } = result.data;

      dispatch({
        type: 'START_AUTOSCHEDULER',
        payload: {
          id: taskId,
          openShiftsIdsToDelete,
        },
      });
    } catch (error) {
      dispatch(hideModal());
      dispatch(scheduleAutogenerateFailure(error));
      console.error(error);
    }
  };

export const cancelSchedulingProcessPerformedByAutoScheduler = () => (dispatch) => {
  dispatch({
    type: CANCEL_AUTOSCHEDULER,
  });
};

export const getWorkingHoursForIndex = (index) => {
  if (!Number.isInteger(index)) {
    return '';
  }
  return `${pad(index)}:00-${pad(index + 1)}:00`;
};

export const getShiftsForJobTitle = (jobTitleData, jobTitle, date) => {
  if (!jobTitleData || !jobTitle || !date) return [];

  return jobTitleData.reduce((acc3, amount, index) => {
    if (!['-', ''].includes(amount)) {
      return [
        ...acc3,
        {
          date,
          working_hours: getWorkingHoursForIndex(index),
          job_title: jobTitle,
          amount,
        },
      ];
    }
    return acc3;
  }, []);
};

export const getShiftsForDay = (dayData, userJobTitles, date) => {
  if (!dayData || !userJobTitles || !date) return [];

  return Object.keys(dayData).reduce((acc2, jobTitleId) => {
    const jobTitleData = dayData[jobTitleId];
    const jobTitle = userJobTitles.find((jt) => jt.id === jobTitleId);
    return [...acc2, ...getShiftsForJobTitle(jobTitleData, jobTitle, date)];
  }, []);
};

export const getShiftsForSchedule = (schedule, selectedJobTitles, mainDateStore) => {
  if (!schedule || !selectedJobTitles || !mainDateStore) return [];
  // eslint-disable-next-line no-underscore-dangle
  const isSundayFirstDayOfWeek = moment.localeData()._week.dow === 0;
  return Object.keys(schedule).reduce((acc, day) => {
    const dayData = schedule[day];
    const dayNumber = isSundayFirstDayOfWeek ? parseInt(day) : (day === '0' ? 7 : parseInt(day)) - 1;
    const dates = mainDateStore.dateArray.filter((date) => moment(date).weekday() === dayNumber);

    return [
      ...acc,
      ...dates.reduce((prev, date) => [...prev, ...getShiftsForDay(dayData, selectedJobTitles, date)], []),
    ];
  }, []);
};

export const sendSplitShiftsToAutoscheduler =
  (
    schedule,
    selectedJobTitles,
    configuration,
    shortTimeout,
    availableUndefinedDays,
    templateType,
    employeeIdsWithDifferentJobTitlesInContracts,
  ) =>
  (dispatch, getState) => {
    const { mainDateStore, userEmployees, contracts } = getState().reducer;
    const selectedEmployees = userEmployees
      .filter((employee) => {
        const employeeContracts = contracts[employee.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(
          employeeContracts,
          mainDateStore.customDate.start,
          mainDateStore.customDate.end,
        );
        const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
        return (
          !employee.inactive &&
          employeeJobTitleIds.some((jobTitleId) =>
            selectedJobTitles.map((jobTitle) => jobTitle.id).includes(jobTitleId),
          ) &&
          !employee.deleted &&
          !employeeIdsWithDifferentJobTitlesInContracts.includes(employee.id)
        );
      })
      .map((employee) => ({ value: employee.id }));

    const templateShifts = getShiftsForSchedule(schedule, selectedJobTitles, mainDateStore);
    dispatch(
      sendAutoSchedulerInformation(
        templateShifts,
        mainDateStore.dateArray,
        selectedEmployees,
        configuration,
        shortTimeout,
        availableUndefinedDays,
        templateType,
      ),
    );
  };

export const autoScheduleImportSuccess = () => (dispatch, getState, intl) => {
  dispatch({
    type: AUTO_GENERATE_IMPORT_SUCCESS,
    notification: {
      title: intl.formatMessage(messages.successTitle),
      description: intl.formatMessage(messages.importSuccess),
      type: 'success',
    },
  });
};

export const autoScheduleImportError = (error) => (dispatch, getState, intl) => {
  dispatch({
    type: AUTO_GENERATE_IMPORT_ERROR,
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: error,
      type: 'error',
    },
  });
};

export const noEmployeesSelected = () => (dispatch, getState, intl) => {
  dispatch({
    type: AUTO_GENERATE_SUBMIT_NO_EMPLOYEES_SELECTED,
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: intl.formatMessage(messages.noEmployeesSelected),
      type: 'error',
    },
  });
};

export const showAutoGenerateStepsModalWithOpenShiftsAsTemplate = () => (dispatch) => {
  dispatch(createTemplateFromOpenShifts());
  dispatch(showModal(AUTO_GENERATE_SCHEDULE_MODAL));
};
