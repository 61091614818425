import { connect } from 'react-redux';

import { changeLanguage } from '@/actions/intl.js';

import LanguageSettings from './LanguageSettings.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser.user,
});

const mapDispatchToProps = {
  changeLanguage,
};

const LanguageSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);

export default LanguageSettingsContainer;
