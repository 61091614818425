import { getUnique } from 'kadro-helpers/lib/helpers';

import { GROUP_JOBTITLES_SUCCESS } from '@/constants/ActionTypes.js';
import { getRelevantContractsForMultipleDates } from '@/utils/contracts';

import { changeJobTitleFilterGrouped } from './jobtitleFilter';

export const groupJobTitlesSuccess = groupedJobTitles => ({
  type: GROUP_JOBTITLES_SUCCESS,
  payload: groupedJobTitles,
});

export const changeFilter = () => (dispatch, getState) => {
  const { scheduleLocationFilter, jobTitlesGrouped, userJobTitles } = getState().reducer;
  if (scheduleLocationFilter.length > 0) {
    const newJobTitlesGrouped = Array.from(
      new Set(
        scheduleLocationFilter.reduce((result, locationId) => [...result, ...(jobTitlesGrouped[locationId] || [])], []),
      ),
    );
    dispatch(changeJobTitleFilterGrouped(userJobTitles.filter(jobTitle => newJobTitlesGrouped.includes(jobTitle.id))));
  }
};

export const groupJobTitles = (dispatchChangeFilter = false) => (dispatch, getState) => {
  const {
    userEmployees,
    userLocations,
    contracts,
    mainDateStore: {
      customDate: { start: from, end: to },
    },
  } = getState().reducer;
  const groupedJobTitles = {};
  userLocations.forEach(location => {
    groupedJobTitles[location.id] = [];
  });
  userEmployees.forEach(emp => {
    const locationIds = emp.locations.map(loc => loc.id);
    const employeeContracts = contracts[emp.id] || [];
    const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);

    const relevantJobTitleIds = [
      ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
    ];

    relevantJobTitleIds.forEach(contractJobTitleId => {
      locationIds.forEach(id => {
        if (!groupedJobTitles[id]) return;
        const foundJobTitle = groupedJobTitles[id].find(jobTitleId => jobTitleId === contractJobTitleId);
        if (!foundJobTitle) {
          groupedJobTitles[id].push(contractJobTitleId);
        }
      });
    });
  });
  dispatch(groupJobTitlesSuccess(groupedJobTitles));
  if (dispatchChangeFilter) dispatch(changeFilter());
};

export const removeSelectedButNotPresentJobTitles = (prevLocationIds, nextLocationIds) => (dispatch, getState) => {
  const { jobTitlesGrouped, jobtitleFilter, userJobTitles } = getState().reducer;
  const prevJobTitlesGrouped = getUnique(
    prevLocationIds.reduce((result, locationId) => [...result, ...(jobTitlesGrouped[locationId] || [])], []),
  );
  const nextJobTitlesGrouped = getUnique(
    nextLocationIds.reduce((result, locationId) => [...result, ...(jobTitlesGrouped[locationId] || [])], []),
  );
  if (jobtitleFilter.selectedJobtitlesGrouped.length === prevJobTitlesGrouped.length) {
    dispatch(changeJobTitleFilterGrouped(userJobTitles.filter(jobTitle => nextJobTitlesGrouped.includes(jobTitle.id))));
    return;
  }
  const selectedJobTitles = jobtitleFilter.selectedJobtitlesGrouped.filter(jobTitle =>
    nextJobTitlesGrouped.includes(jobTitle.id),
  );
  dispatch(changeJobTitleFilterGrouped(selectedJobTitles));
};
