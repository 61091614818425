import { employeesMultiSelectMessages } from './ExportEmployeesStep.messages';

export const getEmployeesTitlesMessages = (intl, employeesItems) => {
  const selectedEmployeesAmount = employeesItems.filter(({ active }) => active).length;

  return {
    headerTitle: intl.formatMessage(employeesMultiSelectMessages.headerTitle),
    selectAll: intl.formatMessage(employeesMultiSelectMessages.selectAll),
    deselectAll: intl.formatMessage(employeesMultiSelectMessages.deselectAll),
    bodyLabel: intl.formatMessage(employeesMultiSelectMessages.bodyLabel),
    footerTitle: `${intl.formatMessage(employeesMultiSelectMessages.footerTitle)} (${selectedEmployeesAmount}/${
      employeesItems.length
    })`,
  };
};
