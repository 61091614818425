import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Checkbox from '@/components/common/inputs/KadroCheckbox.jsx';

import './SendPanelFooter.scss';

export const SendPanelFooter = props => {
  const [isForceChecked, setIsForceChecked] = useState(false);

  const { submitText, submitDisabled = false, onCancel, onSubmit, allowToForcePublish } = props;
  return (
    <>
      <div className="sendPanelFooter">
        <Button onClick={onCancel} modifiers="reverse-orange small">
          <FormattedMessage id="cancel" defaultMessage="Anuluj" />
        </Button>
        <Button onClick={onSubmit} modifiers="orange small" disabled={submitDisabled && !isForceChecked}>
          {submitText}
        </Button>
      </div>
      {allowToForcePublish && (
        <div className="sendPanelFooter__checkbox">
          <Checkbox checked={isForceChecked} onChange={() => setIsForceChecked(prev => !prev)} />
          <FormattedMessage
            id="sendPanel.publishDespiteTarget"
            defaultMessage="Opublikuj grafik pracy pomimo przekroczonego targetu"
          />
        </div>
      )}
    </>
  );
};

SendPanelFooter.propTypes = {
  submitText: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  allowToForcePublish: PropTypes.bool,
};

export default memo(SendPanelFooter);
