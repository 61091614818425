import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';
import onClickOutside from 'react-onclickoutside';

import { sortByKey } from '@/utils/arrayHelpers';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './MultiSelect.scss';

const messages = defineMessages({
  search: {
    id: 'ui.search',
    defaultMessage: 'Szukaj',
  },
});

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filter: '',
    };
    bindPrototypeFunctions(this);
  }

  handleClickOutside() {
    this.setState({ open: false });
  }

  handleOpenChange() {
    if (!this.props.disabled) {
      this.setState({
        open: !this.state.open,
        filter: '',
      });
    }
  }

  handleFilterChange(e) {
    this.setState({
      filter: e.target.value,
    });
  }

  filteredOptions() {
    const sortKey =
      this.props.options.length > 0 && this.props.options[0].hasOwnProperty('sortValue') ? 'sortValue' : 'label';
    const sortedOptions = sortByKey([...this.props.options], sortKey, true);
    return sortedOptions.filter(option => option[sortKey].toLowerCase().includes(this.state.filter.toLowerCase()));
  }

  render() {
    const { open, filter } = this.state;
    const { label, onChange, testId } = this.props;

    const inputClassNames = classnames('mdMultiSelect__input', {
      'mdMultiSelect__input--active': open,
      'mdMultiSelect__input--disabled': this.props.disabled,
    });

    return (
      <div className="mdMultiSelect">
        <div onClick={this.handleOpenChange} className={inputClassNames} data-test={testId}>
          <span>{label}</span>
          <span className="mdMultiSelect__input__arrow" />
        </div>
        {open && (
          <div className="mdMultiSelect__dropdown" data-test={`${testId}-dropdownList`}>
            <OverlayScrollbarsComponent className="overlay-scrollbar">
              <div className="mdMultiSelect__dropdown__search">
                <span className="mdMultiSelect__dropdown__item__search">
                  <span className="material-icons">search</span>
                </span>
                <input
                  value={filter}
                  onChange={this.handleFilterChange}
                  placeholder={this.context.intl.formatMessage(messages.search, {})}
                  data-test={testId && `${testId}-input-search`}
                />
              </div>
              {this.filteredOptions().map(option => (
                <div
                  key={option.hasOwnProperty('sortValue') ? option.sortValue : option.value}
                  className="mdMultiSelect__dropdown__item"
                  onClick={() =>
                    onChange({
                      value: option.value,
                      label: option.label,
                      active: option.active,
                    })
                  }
                >
                  <span className="mdMultiSelect__dropdown__item__checkbox">
                    {option.active && <span className="material-icons">done</span>}
                  </span>
                  <span className="mdMultiSelect__dropdown__item__text">{option.label}</span>
                </div>
              ))}
            </OverlayScrollbarsComponent>
          </div>
        )}
      </div>
    );
  }
}

MultiSelect.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

MultiSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    }),
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default onClickOutside(MultiSelect);
