import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import { isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { getCalendarRangeText, getInitCalendarChoice } from '@/utils/dateHelper.js';

import ChoicePicker from './ChoicePicker/ChoicePicker.jsx';
import DayPicker from './DayPicker/DayPicker.jsx';
import PeriodPicker from './PeriodPicker/PeriodPicker.jsx';

import './RangePicker.scss';

class RangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChoices: getInitCalendarChoice(moment().format('YYYY-MM-DD')),
      selectedWeekdays: this.props.selectedWeekdays || [],
    };
    bindClassFunctions(this);
  }

  componentDidUpdate() {
    if (!isEqual(this.props.selectedChoices, this.state.selectedChoices)) {
      this.setState({ selectedChoices: this.props.selectedChoices });
    }
  }

  activeRepeatTabChange(name) {
    this.props.handleInputChange('activeRepeatTab', name);
  }

  changeRange(e) {
    this.props.handleInputChange('selectedRange', { start: e.start, end: e.end });
  }

  changePick(e) {
    const dateIndex = this.state.selectedChoices.findIndex(choice => moment(choice).isSame(e));
    if (!~dateIndex) {
      const newSelectedChoices = [...this.props.selectedChoices, e];
      this.setState(
        {
          selectedChoices: newSelectedChoices,
        },
        () => this.props.handleInputChange('selectedChoices', newSelectedChoices),
      );
    } else {
      const newSelectedChoices = [
        ...this.state.selectedChoices.slice(0, dateIndex),
        ...this.state.selectedChoices.slice(dateIndex + 1),
      ];
      this.setState(
        {
          selectedChoices: newSelectedChoices,
        },
        () => this.props.handleInputChange('selectedChoices', newSelectedChoices),
      );
    }
  }

  changeWeekdays(e) {
    const dayIndex = this.state.selectedWeekdays.indexOf(e);
    if (!~dayIndex) {
      const newSelectedChoices = [...this.state.selectedWeekdays, e];
      this.setState({
        selectedWeekdays: newSelectedChoices,
      });
      this.props.handleInputChange('selectedWeekdays', newSelectedChoices);
    } else {
      const newSelectedChoices = [
        ...this.state.selectedWeekdays.slice(0, dayIndex),
        ...this.state.selectedWeekdays.slice(dayIndex + 1),
      ];
      this.setState({
        selectedWeekdays: newSelectedChoices,
      });
      this.props.handleInputChange('selectedWeekdays', newSelectedChoices);
    }
  }

  displayProperPicker() {
    const { selectedRange, selectedWeekdays, perWhichDay, minDate, maxDate } = this.props;
    switch (this.props.activeRepeatTab) {
      case 'period':
        return (
          <PeriodPicker
            changeRange={this.changeRange}
            calText={getCalendarRangeText(selectedRange.start, selectedRange.end)}
            selectedRange={selectedRange}
            changeWeekdays={this.changeWeekdays}
            selectedWeekdays={selectedWeekdays}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      case 'days':
        return (
          <DayPicker
            changeRange={this.changeRange}
            calText={getCalendarRangeText(selectedRange.start, selectedRange.end)}
            selectedRange={selectedRange}
            perWhichDay={perWhichDay}
            setPerWhichDay={e => this.props.handleInputChange('perWhichDay', e)}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      case 'choice':
        return (
          <ChoicePicker
            changePick={this.changePick}
            calText={this.state.selectedChoices.length}
            selectedChoices={this.props.selectedChoices}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { activeRepeatTab } = this.props;

    return (
      <div className="rangePicker">
        <div className="rangePicker__options">
          <div className="rangePicker__option">
            <input
              onChange={() => this.activeRepeatTabChange('period')}
              checked={activeRepeatTab === 'period'}
              id="activeTab1"
              name="activeTab"
              type="radio"
            />
            <label htmlFor="activeTab1">
              <FormattedMessage id="availability.addModal.period" defaultMessage="Okres" />
            </label>
          </div>
          <div className="rangePicker__option">
            <input
              onChange={() => this.activeRepeatTabChange('days')}
              checked={activeRepeatTab === 'days'}
              id="activeTab2"
              name="activeTab"
              type="radio"
            />
            <label htmlFor="activeTab2">
              <FormattedMessage id="availability.addModal.days" defaultMessage="Dni" />
            </label>
          </div>
          <div className="rangePicker__option">
            <input
              onChange={() => this.activeRepeatTabChange('choice')}
              checked={activeRepeatTab === 'choice'}
              id="activeTab3"
              name="activeTab"
              type="radio"
            />
            <label htmlFor="activeTab3">
              <FormattedMessage id="availability.addModal.choice" defaultMessage="Wybór" />
            </label>
          </div>
        </div>
        {this.displayProperPicker()}
        {this.props.error && <span className="rangePicker__error">{this.props.error}</span>}
      </div>
    );
  }
}

RangePicker.propTypes = {
  activeRepeatTab: PropTypes.string,
  selectedRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  selectedChoices: PropTypes.arrayOf(PropTypes.shape({})),
  selectedWeekdays: PropTypes.arrayOf(PropTypes.number),
  perWhichDay: PropTypes.bool,
  handleInputChange: PropTypes.func,
  error: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default RangePicker;
