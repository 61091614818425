import PropTypes from 'prop-types';

import { MDMultiSelect, MDMultiSelectFooter, MDMultiSelectHeader } from '@/components/common/inputs/MDComponents';
import { createEvent } from '@/utils/inputHelpers';

import { getEmployeesOptions, isEmployeeSelected } from './EmployeesForPosition.helpers';
import { messages } from './EmployeesForPosition.messages';

import './EmployeesForPosition.scss';

const EmployeesForPosition = (props, context) => {
  const { handleInputChange, modalObject = {}, userEmployees, userCustomTypes, selectedEmployees, contracts } = props;

  const selectedItems = getEmployeesOptions(
    modalObject,
    userEmployees,
    userCustomTypes,
    selectedEmployees,
    contracts,
  ).filter(({ active }) => active);

  const changeSelectedEmployees = newEmployees => handleInputChange(createEvent('selectedEmployees', newEmployees));

  const handleChangeList = e => {
    const selectedEmployee = e.value;
    const newEmployees = isEmployeeSelected(selectedEmployee, selectedEmployees)
      ? selectedEmployees.filter(({ id }) => id !== selectedEmployee.id)
      : [...selectedEmployees, selectedEmployee];
    changeSelectedEmployees(newEmployees);
  };

  const selectAll = () => {
    const newEmployees = getEmployeesOptions(
      modalObject,
      userEmployees,
      userCustomTypes,
      selectedEmployees,
      contracts,
      true,
    );
    changeSelectedEmployees(newEmployees.map(({ value }) => value));
  };

  const deselectAll = () => {
    changeSelectedEmployees([]);
  };

  return (
    <div className="k-employeesForPosition">
      <MDMultiSelectHeader
        title={context.intl.formatMessage(messages.employees)}
        selectAll={selectAll}
        deselectAll={deselectAll}
        selectAllMessage={context.intl.formatMessage(messages.selectAllMessage)}
        deselectAllMessage={context.intl.formatMessage(messages.deselectAllMessage)}
      />
      <MDMultiSelect
        label={context.intl.formatMessage(messages.chooseEmployees)}
        options={getEmployeesOptions(modalObject, userEmployees, userCustomTypes, selectedEmployees, contracts)}
        onChange={handleChangeList}
      />
      <MDMultiSelectFooter selectedItems={selectedItems} deleteItem={handleChangeList} />
    </div>
  );
};

EmployeesForPosition.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeesForPosition.propTypes = {
  handleInputChange: PropTypes.func,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  modalObject: PropTypes.shape({
    rowData: PropTypes.shape({
      id: PropTypes.string,
    }),
    date: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  contracts: PropTypes.shape({}),
};

export default EmployeesForPosition;
