import PropTypes from 'prop-types';

import SideMenuLink from './SideMenuLink.jsx';

import './SideMenu.scss';

const SideMenu = ({ location, sections }) => (
  <nav className="sideMenu">
    {sections.map(section => (
      <section className="sideMenu__section">
        <h4 className="sideMenu__sectionHeader">{section.displayName}</h4>
        {section.items.map((item, idx) => (
          <SideMenuLink index={idx} key={item.name} name={item.name} sectionName={section.name} location={location}>
            {item.displayName}
          </SideMenuLink>
        ))}
      </section>
    ))}
  </nav>
);

SideMenu.contextTypes = {
  intl: PropTypes.shape({}),
};

SideMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      category: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          displayName: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default SideMenu;
