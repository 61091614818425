import { defineMessages } from 'react-intl';

import {
  CREATE_BUDGET_METRICS_FAILURE,
  CREATE_BUDGET_METRICS_SUCCESS,
  DELETE_BUDGET_METRICS_FAILURE,
  DELETE_BUDGET_METRICS_SUCCESS,
  EDIT_BUDGET_METRICS_FAILURE,
  EDIT_BUDGET_METRICS_SUCCESS,
  GET_BUDGET_METRICS_FAILURE,
  GET_BUDGET_METRICS_SUCCESS, 
  SET_VISIBLE_METRICS } from '@/constants/ActionTypes';

import { conn, showConfirmModal } from './index';

const messages = defineMessages({
  getBudgetMetricsErrorTitle: {
    id: 'budgetMetrics.getBudgetMetrics.error.title',
    defaultMessage: 'Błąd podczas pobierania metryk',
  },
  getBudgetMetricsErrorMessage: {
    id: 'budgetMetrics.getBudgetMetrics.error.message',
    defaultMessage: 'Wystąpił błąd podczas pobierania metryk. Spróbuj ponownie później',
  },
  deleteMetricsErrorTitle: {
    id: 'budgetMetrics.deleteBudgetMetrics.error.title',
    defaultMessage: 'Błąd podczas usuwania metryk',
  },
  metricsNotFoundErrorMessage: {
    id: 'budgetMetrics.metricsNotFound.error.message',
    defaultMessage: 'Dana metryki nie istnieją',
  },
  deleteMetricsErrorMessage: {
    id: 'budgetMetrics.deleteBudgetMetrics.error.message',
    defaultMessage: 'Wystąpił błąd podczas usuwania metryk. Spróbuj ponownie później',
  },
  deleteMetricsSuccessTitle: {
    id: 'budgetMetrics.deleteBudgetMetrics.success.title',
    defaultMessage: 'Sukces',
  },
  deleteMetricsSuccessMessage: {
    id: 'budgetMetrics.deleteBudgetMetrics.success.message',
    defaultMessage: 'Z powodzeniem usunięto metryki',
  },

  deleteMetricErrorTitle: {
    id: 'budgetMetrics.deleteBudgetMetric.error.title',
    defaultMessage: 'Błąd podczas usuwania metryki',
  },
  metricNotFoundErrorMessage: {
    id: 'budgetMetrics.metricNotFound.error.message',
    defaultMessage: 'Dana metryka nie istnieje',
  },
  deleteMetricErrorMessage: {
    id: 'budgetMetrics.deleteBudgetMetric.error.message',
    defaultMessage: 'Wystąpił błąd podczas usuwania metryki. Spróbuj ponownie później',
  },
  deleteMetricSuccessTitle: {
    id: 'budgetMetrics.deleteBudgetMetric.success.title',
    defaultMessage: 'Sukces',
  },
  deleteMetricSuccessMessage: {
    id: 'budgetMetrics.deleteBudgetMetric.success.message',
    defaultMessage: 'Z powodzeniem usunięto metrykę',
  },
  createMetricErrorTitle: {
    id: 'budgetMetrics.createBudgetMetric.error.title',
    defaultMessage: 'Błąd podczas tworzenia metryki',
  },
  createMetricErrorMessage: {
    id: 'budgetMetrics.createBudgetMetric.error.message',
    defaultMessage: 'Spróbuj ponownie później',
  },
  editMetricErrorTitle: {
    id: 'budgetMetrics.editBudgetMetric.error.title',
    defaultMessage: 'Błąd podczas edytowania metryki',
  },
  editMetricErrorMessage: {
    id: 'budgetMetrics.editBudgetMetric.error.message',
    defaultMessage: 'Spróbuj ponownie później',
  },

  deleteBudgetMetricConfirmModalTitle: {
    id: 'budgetMetrics.confirmModal.title',
    defaultMessage: 'Usuń metryki budżetu',
  },
  deleteBudgetMetricConfirmModalDescription: {
    id: 'budgetMetrics.confirmModal.description',
    defaultMessage: 'Usuwając metrykę budżetu, tracisz ją bezpowrotnie.',
  },
  deleteBudgetMetricConfirmModalText: {
    id: 'budgetMetrics.confirmModal.text',
    defaultMessage: 'Usuń',
  },
  deleteBudgetMetrics: {
    id: 'budgetMetrics.confirmModal.action',
    defaultMessage: 'metryki budżetu',
  },
  deleteBudgetMetric: {
    id: 'budgetMetrics.confirmModal.action.single',
    defaultMessage: 'metrykę budżetu',
  },
});

export const getBudgetMetrics = ids => async (dispatch, _getState, intl) => {
  try {
    const { data: budgetMetrics } = await conn.getBudgetMetrics(ids);

    await dispatch({
      type: GET_BUDGET_METRICS_SUCCESS,
      payload: budgetMetrics,
    });
  } catch(e) {
    console.error(e);

    dispatch({
      type: GET_BUDGET_METRICS_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.getBudgetMetricsErrorTitle),
        description: intl.formatMessage(messages.getBudgetMetricsErrorMessage),
        type: 'error',
      },
    });
  }
};

export const createBudgetMetric = (budgetMetricObject) => async (dispatch, _getState, intl) => {

  try {
    const { data: budgetMetrics } = await conn.createBudgetMetrics([budgetMetricObject]);

    dispatch({
      type: CREATE_BUDGET_METRICS_SUCCESS,
      payload: budgetMetrics,
    });
  } catch(e) {
    dispatch({
      type: CREATE_BUDGET_METRICS_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.createMetricErrorTitle),
        description: intl.formatMessage(messages.createMetricErrorMessage),
        type: 'error',
      },
    });
  }
};

export const editBudgetMetric = (budgetMetricObject) => async (dispatch, _getState, intl) => {
  try {
    await conn.editBudgetMetrics([budgetMetricObject]);

    dispatch({
      type: EDIT_BUDGET_METRICS_SUCCESS,
      payload: [budgetMetricObject],
    });
  } catch(e) {
    dispatch({
      type: EDIT_BUDGET_METRICS_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.editMetricErrorTitle),
        description: intl.formatMessage(messages.editMetricErrorMessage),
        type: 'error',
      },
    });
  }
};

export const deleteBudgetMetrics = ids => async (dispatch, getState, intl) => {
  const { budgetMetrics } = getState().reducer;

  const metricsExist = ids.reduce((previousCondition, id) =>
    previousCondition && budgetMetrics.findIndex(metric => metric.id === id) > -1, true);

  if (!metricsExist) {
    return dispatch({
      type: DELETE_BUDGET_METRICS_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.deleteMetricsErrorTitle),
        description: intl.formatMessage(messages.metricsNotFoundErrorMessage),
        type: 'error',
      },
    });
  }

  dispatch(showConfirmModal({
    title: intl.formatMessage(messages.deleteBudgetMetricConfirmModalTitle),
    description: intl.formatMessage(messages.deleteBudgetMetricConfirmModalDescription, {}),
    confirmText: intl.formatMessage(messages.deleteBudgetMetricConfirmModalText),
    deletedThing: intl.formatMessage(
      ids.length > 1 ? messages.deleteBudgetMetrics : messages.deleteBudgetMetric,
      {},
    ),
    showDeleteInput: true,
    confirmFunc: async () => {
      try {
        await conn.deleteBudgetMetrics(ids);

        return dispatch({
          type: DELETE_BUDGET_METRICS_SUCCESS,
          payload: {
            ids,
          },
          notification: {
            title: intl.formatMessage(messages.deleteMetricsSuccessTitle),
            description: intl.formatMessage(messages.deleteMetricsSuccessMessage),
            type: 'success',
          },
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: DELETE_BUDGET_METRICS_FAILURE,
          payload: {
            ids,
          },
          notification: {
            title: intl.formatMessage(messages.deleteMetricsErrorTitle),
            description: intl.formatMessage(messages.deleteMetricsErrorMessage),
            type: 'error',
          },
        });
      }
    },
  }));
};

export const deleteBudgetMetric = id => async (dispatch, getState, intl) => {
  const { budgetMetrics } = getState().reducer;

  if (budgetMetrics.findIndex(metric => metric.id === id) === -1) {
    return dispatch({
      type: DELETE_BUDGET_METRICS_FAILURE,
      payload: {},
      notification: {
        title: intl.formatMessage(messages.deleteMetricsErrorTitle),
        description: intl.formatMessage(messages.metricsNotFoundErrorMessage),
        type: 'error',
      },
    });
  }

  dispatch(showConfirmModal({
    title: intl.formatMessage(messages.deleteBudgetMetricConfirmModalTitle),
    description: intl.formatMessage(messages.deleteBudgetMetricConfirmModalDescription, {}),
    confirmText: intl.formatMessage(messages.deleteBudgetMetricConfirmModalText),
    deletedThing: intl.formatMessage(messages.deleteBudgetMetric),
    showDeleteInput: true,
    confirmFunc: async () => {
      try {
        await conn.deleteBudgetMetrics([id]);
    
        return dispatch({
          type: DELETE_BUDGET_METRICS_SUCCESS,
          payload: {
            ids: [id],
          },
          notification: {
            title: intl.formatMessage(messages.deleteMetricSuccessTitle),
            description: intl.formatMessage(messages.deleteMetricSuccessMessage),
            type: 'success',
          },
        });
      } catch(err) {
        return dispatch({
          type: DELETE_BUDGET_METRICS_FAILURE,
          payload: {},
          notification: {
            title: intl.formatMessage(messages.deleteMetricsErrorTitle),
            description: intl.formatMessage(messages.deleteMetricsErrorMessage),
            type: 'error',
          },
        });
      }
    },
  }));
};

export const setMetricsVisible = ids => dispatch => {
  dispatch({
    type: SET_VISIBLE_METRICS,
    payload: ids,
  });
};