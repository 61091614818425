import { connect } from 'react-redux';

import { editPaymentData } from '@/actions/payments.js';
import { hideModal } from '@/actions/uiState.js';
import { EDIT_BILLING_DATA_MODAL } from '@/constants/modalTypes.js';

import EditBillingDataModal from './EditBillingDataModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === EDIT_BILLING_DATA_MODAL,
  modalObject: state.reducer.uiState.modalObject[EDIT_BILLING_DATA_MODAL],
  paymentsData: state.reducer.payments.client,
});

const mapDispatchToProps = {
  hideModal,
  editPaymentData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBillingDataModal);
