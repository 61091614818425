import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ShownCounter from '@/components/common/ShownCounter.jsx';
import DeleteReleasedEmployeesDataModal from '@/components/companymanage/employees/DeleteReleasedEmployeesDataModal/DeleteReleasedEmployeesDataModal.redux';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { EMPLOYEE_MODAL } from '@/constants/modalTypes.js';
import { SUPPLEMENTARY_EMPLOYEES_HIDE_MODIFY } from '@/constants/Restrictions';
import { employeesTable } from '@/constants/tableColumns.jsx';
import { compareByKey } from '@/utils/baseHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getRelevantContractForDate } from '@/utils/contracts';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers.js';

import EmployeesButtonBar from '../ButtonBar/EmployeesButtonBar.redux.js';
import AdditionalOptionsModal from '../EditMassEmployeesModal/AdditionalOptionsModal.redux.js';
import EditMassEmployeesModal from '../EditMassEmployeesModal/EmployeesEditMassModal.redux.js';
import EmployeeModal from '../EmployeeModal/EmployeeModal/EmployeeModal.redux.js';
import EmployeesHeader from '../EmployeesHeader.jsx';
import EmployeesRow from '../EmployeesRow.jsx';
import EmployeesRowTitle from '../EmployeesRowTitle.jsx';
import ExportQrCodeModal from '../ExportQrCodeModal/ExportQrCodeModal.redux.js';
import CustomNotificationsModal from './../CustomNotificationModal/CustomNotificationsModal.redux.js';

import './employeesTable.scss';

const messages = defineMessages({
  employee: {
    id: 'companyManage.employeesList.employee',
    defaultMessage: 'Pracownik',
  },
  manager: {
    id: 'companyManage.employeesList.manager',
    defaultMessage: 'Manager',
  },
  invited: {
    id: 'companyManage.employeesList.invited',
    defaultMessage: 'Zaproszony',
  },
  uninvited: {
    id: 'companyManage.employeesList.uninvited',
    defaultMessage: 'Niezaproszony',
  },
  emptyStateTitle: {
    id: 'companyManage.employeesList.emptyStateTitle',
    defaultMessage: 'Brak dodanych pracowników ',
  },
  emptyStateMsg: {
    id: 'companyManage.employeesList.emptyStateMsg',
    defaultMessage: 'Aby dodać pierwszego pracownika ',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.employeesList.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.employeesList.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaText: {
    id: 'companyManage.employeesList.ctaText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaFilterText: {
    id: 'companyManage.employeesList.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

class EmployeesTable extends Component {
  constructor(props, context) {
    super(props, context);
    bindPrototypeFunctions(this);
  }

  getEmptyStateView() {
    const showModal = !this.props.userEmployees.length && !this.props.searchWord.length;
    const headerText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateTitle, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateMsg, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
    const ctaText = showModal
      ? this.context.intl.formatMessage(messages.ctaText, {})
      : this.context.intl.formatMessage(messages.ctaFilterText, {});
    const ctaHandler = showModal
      ? () => {
          this.props.showModal(EMPLOYEE_MODAL);
        }
      : this.props.clearSearchWord;
    return (
      <tr>
        <td className="no-content-to-show" colSpan="5">
          <EmptyState
            imgSrc="/img/employeesCTA.png"
            name="employeeCTA"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
            filerStyle={{ width: '18vw', minWidth: '170px' }}
          />
        </td>
      </tr>
    );
  }

  // Function that filters data based on given fraze and then sorts it based on values form given column
  // fraze - string
  // sortColumn - positive int starting form 0
  filterAndSort(fraze, relevantEmployees) {
    const normalizedFraze = fraze
      .trim()
      .replace(/ +(?= )/g, '')
      .toLowerCase();
    const data = [];
    for (const emp of relevantEmployees) {
      let compareJobTitles = '';
      let compareHourlyWages = '';
      let compareLocations = '';
      const relevantContract = getRelevantContractForDate(
        this.props.contracts[emp.id] || [],
        moment().format('YYYY-MM-DD'),
      );

      relevantContract?.job_titles.forEach(contractJobTitle => {
        const jobTitle = this.props.jobTitles.find(jobTitle => jobTitle.id === contractJobTitle.job_title_id);
        compareJobTitles += jobTitle?.title;
        compareHourlyWages += `0000000000${
          contractJobTitle.wage || contractJobTitle.wage === 0 ? contractJobTitle.wage : jobTitle?.hourly_wage
        }`.slice(-10);
      });

      emp.locations.forEach(location => {
        compareLocations += location.name;
      });

      compareLocations = compareLocations.toLowerCase();
      compareHourlyWages = compareHourlyWages.toLowerCase();
      compareJobTitles = compareJobTitles.toLowerCase();
      const customRole = this.props.companyRoles.find(companyRole => companyRole.id === emp.role_id);
      const role = (emp.role === 'employee'
        ? this.context.intl.formatMessage(messages.employee, {})
        : this.context.intl.formatMessage(messages.manager, {})
      ).toLowerCase();
      const { pin, email, reference_id: referenceId, employment_conditions: employmentConditions, phone } = emp;
      const invited = (emp.invited
        ? this.context.intl.formatMessage(messages.invited, {})
        : this.context.intl.formatMessage(messages.uninvited, {})
      ).toLowerCase();
      const compareName = `${emp.first_name} ${emp.last_name}`.toLowerCase();
      const nfcCode = (emp.nfc_code || '').toLowerCase();
      const employmentConditionsName =
        employmentConditions && employmentConditions.template_id === 'custom'
          ? this.context.intl.formatMessage({
              id: 'employeesTable.custom',
              defaultMessage: 'Własny',
            })
          : (this.props.employmentConditions &&
              this.props.employmentConditions.find(
                employmentCondition => employmentCondition.id === employmentConditions.template_id,
              )?.name) ||
            '';
      const comparePhone = phone || '';
      const compareReferenceId = referenceId || '';
      const createdAt = convertDateToStandardFormat(emp.created_at);
      const hireDate = employmentConditions?.hire_date
        ? convertDateToStandardFormat(employmentConditions.hire_date)
        : '';
      const releaseDate = employmentConditions?.release_date
        ? convertDateToStandardFormat(employmentConditions.release_date)
        : '';

      const sortingData = {
        compareName,
        compareJobTitles,
        compareHourlyWages,
        compareLocations,
        role: customRole ? customRole.name : role,
        invited,
        checked: this.props.employeesListUi.selected.includes(emp.id),
        nfcCode,
        email,
        comparePhone,
        compareReferenceId,
        employmentConditionsName,
        createdAt,
        hireDate,
        releaseDate,
      };

      if (
        (
          compareName +
          compareJobTitles +
          compareLocations +
          compareHourlyWages +
          pin +
          invited +
          (nfcCode ?? '') +
          (customRole?.name.toLowerCase() ?? role) +
          email +
          phone +
          referenceId +
          (employmentConditionsName || '').toLowerCase() +
          createdAt +
          hireDate +
          releaseDate
        ).includes(normalizedFraze)
      )
        data.push(Object.assign({}, emp, sortingData));
    }

    const column = this.props.employeesListUi.sortColumn;

    const direction = this.props.employeesListUi.sortDecreesing ? -1 : 1;

    if (column) data.sort(compareByKey(employeesTable.sortingKeys[column], direction));

    return data;
  }

  isGivenEmployeeVisible(employee) {
    const {
      currentCompany,
      companyRoles,
      currentUser: { user },
    } = this.props;
    const { same_rank_is_visible: sameRankIsVisible } = currentCompany.settings;

    const rankCondition = sameRankIsVisible
      ? getEmployeeRank(employee, companyRoles) <= getEmployeeRank(user, companyRoles)
      : getEmployeeRank(employee, companyRoles) < getEmployeeRank(user, companyRoles);

    return (user.id === employee.id || rankCondition) && employee.inactive === this.props.inactive;
  }

  render() {
    let relevantEmployees = this.props.userEmployees.filter(this.isGivenEmployeeVisible);
    const isManager = this.props.currentUser.user.role === 'manager';
    const disableSupplementaryEmployees = this.props.userPermissions.restrictions.includes(
      SUPPLEMENTARY_EMPLOYEES_HIDE_MODIFY,
    );
    if (isManager && disableSupplementaryEmployees) {
      const managerLocationIds = this.props.currentUser.user.locations.map(l => l.id.toString());
      relevantEmployees = relevantEmployees.filter(employee => {
        const employeeLocationsIds = employee.locations.map(l => l.id.toString());
        const employeeSupplementaryLocationsIds = employee.supplementary_locations_ids.map(id => id.toString());
        const employeeNoSupplementaryLocationIds = employeeLocationsIds.filter(
          id => !employeeSupplementaryLocationsIds.includes(id),
        );
        const relevantForThisManager = employeeNoSupplementaryLocationIds.some(id => managerLocationIds.includes(id));
        return relevantForThisManager;
      });
    }
    const rawData = this.filterAndSort(this.props.searchWord, relevantEmployees);
    const visibleEmployeesIds = rawData.map(e => e.id);
    const relevantSelectedEmployeesIds = this.props.employeesListUi.selected.filter(employeeId =>
      visibleEmployeesIds.includes(employeeId),
    );
    const tableHeight = this.props.deviceInfo.windowSize.height - 300;
    const visibleColumnsKeys = this.props.visibleColumns.map(column => column.key);
    return (
      <div>
        <EmployeeModal isManager={isManager} />
        <EditMassEmployeesModal />
        <AdditionalOptionsModal />
        <CustomNotificationsModal relevantEmployees={relevantSelectedEmployeesIds} />
        <ExportQrCodeModal
          relevantEmployees={relevantEmployees}
          relevantSelectedEmployeesIds={relevantSelectedEmployeesIds}
        />
        <DeleteReleasedEmployeesDataModal />
        <EmployeesButtonBar
          inactive={this.props.inactive}
          relevantSelectedEmployeesIds={relevantSelectedEmployeesIds}
          relevantEmployees={relevantEmployees}
        />
        <div style={{ clear: 'both' }}>
          <Grid
            width={900}
            height={tableHeight}
            columnStyle={{ width: '18vw', minWidth: '170px' }}
            columnGroup={
              rawData.length > 0 && (
                <colgroup>
                  {employeesTable.colgroup
                    .filter(
                      (width, index) =>
                        visibleColumnsKeys.includes(employeesTable.headers[index].key) ||
                        employeesTable.headers[index].cannotBeHidden,
                    )
                    .map((width, index) => {
                      const restrictions = employeesTable.restrictions[index];
                      const permissions = employeesTable.permissions[index];
                      const column = <col style={{ width: `${width}%` }} key={employeesTable.headers[index].key} />;

                      if (restrictions?.length || permissions?.length) {
                        return (
                          <FeatureWrapper
                            restriction={restrictions.length ? restrictions[0] : null}
                            permissions={permissions.length ? permissions[0] : null}
                            key={employeesTable.headers[index].key}
                          >
                            {column}
                          </FeatureWrapper>
                        );
                      }

                      return column;
                    })}
                </colgroup>
              )
            }
          >
            <LeftHeader testId="employeesTable">
              <>
                <Checkbox
                  className="k-employeesHeader__checkbox"
                  checked={
                    this.props.employeesListUi.selected.length > 0 &&
                    !visibleEmployeesIds.some(
                      visibleEmployeeId => !this.props.employeesListUi.selected.includes(visibleEmployeeId),
                    )
                  }
                  toggle={() => {
                    this.props.toggleAllCheckboxes(visibleEmployeesIds, 'employees');
                  }}
                  testId="selectAllEmployees"
                />
                <th
                  onClick={() => {
                    this.props.changeSorting('name', 'employees');
                  }}
                  className="tab_title tab_title--unsortedSpace k-employeesHeader__headerCell k-employeesHeader__headerCell--sortable"
                  data-test="employeeNameTableHeader"
                >
                  <span role="none">
                    <FormattedMessage id="companyManage.employeesList.fullName" defaultMessage="Imię i nazwisko" />
                  </span>
                  <SortingArrow
                    display={this.props.employeesListUi.sortColumn === 'name'}
                    up={!this.props.employeesListUi.sortDecreesing}
                  />
                </th>
              </>
            </LeftHeader>
            <Header>
              <EmployeesHeader
                sortFunc={this.props.changeSorting}
                uiData={this.props.employeesListUi}
                visibleColumns={this.props.visibleColumns}
              />
            </Header>
            <LeftColumn className={!rawData.length ? 'empty' : ''}>
              {!rawData.length ? (
                <tr>
                  <th className="no-content-to-show" />
                </tr>
              ) : (
                rawData.map((employee, index) => (
                  <EmployeesRowTitle
                    key={index}
                    checked={this.props.employeesListUi.selected.includes(employee.id)}
                    toggle={() => {
                      this.props.toggleCheckbox(employee.id, 'employees');
                    }}
                    data={employee}
                  />
                ))
              )}
            </LeftColumn>
            <Rows className="table_company" testId="employeesTable">
              {rawData.length === 0
                ? this.getEmptyStateView()
                : rawData.map((employee, index) => {
                    const relevantContract = getRelevantContractForDate(
                      this.props.contracts[employee.id] || [],
                      moment().format('YYYY-MM-DD'),
                    );
                    return (
                      <EmployeesRow
                        key={index}
                        data={employee}
                        currentEmployeeContract={relevantContract}
                        edit={() => {
                          this.props.showModal(EMPLOYEE_MODAL, employee);
                        }}
                        delete={() => {
                          this.props.deleteEmployeeConfirm(employee.id, 'employees');
                        }}
                        userPermissions={this.props.userPermissions}
                        visibleColumns={this.props.visibleColumns}
                        currentUserId={this.props.currentUser.user.id}
                        jobTitles={this.props.jobTitles}
                      />
                    );
                  })}
            </Rows>
          </Grid>
        </div>
        <ShownCounter visible={visibleEmployeesIds.length} total={relevantEmployees.length} />
      </div>
    );
  }
}

EmployeesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeesTable.propTypes = {
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  employeesListUi: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.string,
    sortDecreesing: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.shape()),
      id: PropTypes.string,
    }),
  }),
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  searchWord: PropTypes.string.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
  deleteEmployeeConfirm: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  inactive: PropTypes.bool.isRequired,
  clearSearchWord: PropTypes.func,
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  currentCompany: PropTypes.shape({
    settings: PropTypes.shape({
      same_rank_is_visible: PropTypes.bool,
    }),
  }),
};

export default EmployeesTable;
