export const getInitialState = currentCompany => ({
  errors: {},
  passwordRuleInterval: currentCompany.settings.password_change_interval || 0,
  availabilitiesArePublic: currentCompany.settings.availabilities_are_public,
  scheduleIsPublicForEmployees: currentCompany.settings.schedule_is_public_for_employees,
  sameRankIsVisible: currentCompany.settings.same_rank_is_visible || false,
  countOnlyDaysWithShiftsSelectedByDefault: currentCompany.settings.count_only_days_with_shifts_selected_by_default,
  showUnpaidAbsences: currentCompany.settings.show_unpaid_absences,
  companyName: currentCompany.name,
  disableAvailabilitiesEditUntil: currentCompany.settings.disable_availabilities_edit_until,
  payrollAbsenceEnabled: currentCompany.settings.payroll_absence_enabled,
  payrollApprovalEnabled: currentCompany.settings.payroll_approval_enabled,
  employeeECPEnabled: currentCompany.settings.employee_ecp_enabled,
  showUnapprovedEmployeeECPData: currentCompany.settings.show_unapproved_employee_ecp_data,
  showWagesInEmployeeECP: currentCompany.settings.show_wages_in_employee_ecp,
});

export const getCompanyToggledOptions = state => {
  let options = {
    availabilitiesArePublic: state.availabilitiesArePublic,
    scheduleIsPublicForEmployees: state.scheduleIsPublicForEmployees,
    sameRankIsVisible: state.sameRankIsVisible,
    countOnlyDaysWithShiftsSelectedByDefault: state.countOnlyDaysWithShiftsSelectedByDefault,
    showUnpaidAbsences: state.showUnpaidAbsences,
    payrollAbsenceEnabled: state.payrollAbsenceEnabled,
  };
  if (state.employeeECPEnabled) {
    options = {
      payrollApprovalEnabled: state.payrollApprovalEnabled,
      employeeECPEnabled: state.employeeECPEnabled,
      showUnapprovedEmployeeECPData: state.showUnapprovedEmployeeECPData,
      showWagesInEmployeeECP: state.showWagesInEmployeeECP,
      ...options,
    };
  } else {
    options = {
      payrollApprovalEnabled: state.payrollApprovalEnabled,
      employeeECPEnabled: state.employeeECPEnabled,
      ...options,
    };
  }
  return options;
};

export const getNewCompanySettings = (state, userRole) => {
  const settings = {
    availabilities_are_public: state.availabilitiesArePublic,
    schedule_is_public_for_employees: state.scheduleIsPublicForEmployees,
    same_rank_is_visible: state.sameRankIsVisible,
    count_only_days_with_shifts_selected_by_default: state.countOnlyDaysWithShiftsSelectedByDefault,
    show_unpaid_absences: state.showUnpaidAbsences,
    payroll_absence_enabled: state.payrollAbsenceEnabled,
    payroll_approval_enabled: state.payrollApprovalEnabled,
    employee_ecp_enabled: state.employeeECPEnabled,
    show_unapproved_employee_ecp_data: state.showUnapprovedEmployeeECPData,
    show_wages_in_employee_ecp: state.showWagesInEmployeeECP,
  };
  if (userRole === 'owner') settings.disable_availabilities_edit_until = state.disableAvailabilitiesEditUntil;
  return settings;
};
