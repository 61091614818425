import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import MDTextInput from '../MDTextInput/MDTextInput.jsx';

const MDHoursAndMinutesInput = ({ onChange, value, testId }) => {
  const [hoursValue, setHoursValue] = useState('');
  const [minutesValue, setMinutesValue] = useState('');

  useEffect(() => {
    if (typeof value === 'number' && !Number.isNaN(value)) {
      const minutes = value % 60;
      const hours = Math.floor(value / 60);

      setHoursValue(hours === 0 ? '' : String(hours));
      setMinutesValue(String(minutes));
    }
  }, [value, hoursValue, minutesValue]);

  const handleChange = (event, inputId) => {
    const { value: inputValue } = event.target;

    if (inputId === 'hours') {
      setHoursValue(inputValue);

      if (inputValue === '' && minutesValue === '') {
        onChange(null);
      } else {
        onChange(parseInt(inputValue || 0) * 60 + parseInt(minutesValue || 0));
      }
    }

    if (inputId === 'minutes') {
      setMinutesValue(inputValue);

      if (inputValue === '' && hoursValue === '') {
        onChange(null);
      } else {
        onChange(parseInt(hoursValue || 0) * 60 + parseInt(inputValue || 0));
      }
    }
  };

  return (
    <>
      <MDTextInput
        id="hours"
        value={hoursValue}
        onChange={event => handleChange(event, 'hours')}
        type="number"
        isNumberInteger
        isNumberPositive
        modifiers={['modal', 'no-padding', 'alignRight']}
        className={classnames('kmd-hours-input', { 'kmd-hours-input--extended': hoursValue.length > 3 })}
        maxValue={9999}
        testId={`${testId}-hours`}
      />
      h
      <MDTextInput
        id="minutes"
        value={minutesValue}
        onChange={event => handleChange(event, 'minutes')}
        type="number"
        isNumberInteger
        modifiers={['modal', 'no-padding', 'alignRight']}
        isNumberPositive
        maxValue={59}
        className="kmd-minutes-input"
        testId={`${testId}-minutes`}
      />
      m
    </>
  );
};

MDHoursAndMinutesInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  testId: PropTypes.string,
};

export default MDHoursAndMinutesInput;
