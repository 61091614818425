import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState.js';
import { INACTIVE_USER_MODAL } from '@/constants/modalTypes.js';

import InactiveUser from './InactiveUser.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === INACTIVE_USER_MODAL,
});

const mapDispatchToProps = {
  hideModal,
};

const InactiveUserContainer = connect(mapStateToProps, mapDispatchToProps)(InactiveUser);

export default InactiveUserContainer;
