import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  getPaymentDataError: {
    id: 'payroll.getPaymentDataError',
    defaultMessage: 'Nie udało się wczytać danych. Spróbuj ponownie',
  },
  getPayrollShiftOrAttendanceError: {
    id: 'payroll.getPayrollShiftOrAttendanceError',
    defaultMessage: 'Nie udało się wczytać danych. Spróbuj ponownie',
  },
  deleteShiftForPayrollError: {
    id: 'payroll.deleteShiftForPayrollError',
    defaultMessage: 'Nie udało się usunąć zmiany',
  },
  deleteShiftForPayrollSuccess: {
    id: 'payroll.deleteShiftForPayrollSuccess',
    defaultMessage: 'Zmiana została usunięta',
  },
  editShiftForPayrollError: {
    id: 'payroll.editShiftForPayrollError',
    defaultMessage: 'Nie udało się edytować zmiany',
  },
  editShiftForPayrollSuccess: {
    id: 'payroll.editShiftForPayrollSuccess',
    defaultMessage: 'Poprawnie edytowano zmiane',
  },
  editAttendanceForPayrollError: {
    id: 'payroll.editAttendanceForPayrollError',
    defaultMessage: 'Nie udało się edytować obecności',
  },
  editAttendanceForPayrollSuccess: {
    id: 'payroll.editAttendanceForPayrollSuccess',
    defaultMessage: 'Poprawnie edytowano obecność',
  },
  addShiftForPayrollError: {
    id: 'payroll.addShiftForPayrollError',
    defaultMessage: 'Nie udało się dodać zmiany',
  },
  addShiftForPayrollSuccess: {
    id: 'payroll.addShiftForPayrollSuccess',
    defaultMessage: 'Zmiana została dodana',
  },
  addShiftForPayrollSuccessDescription: {
    id: 'payroll.addShiftForPayrollSuccessDescription',
    defaultMessage: 'Poprawnie dodano zmianę',
  },
  changePayrollDaysStatusSuccessTitle: {
    id: 'payroll.changePayrollDaysStatusSuccessTitle',
    defaultMessage: 'Sukces',
  },
  changePayrollDaysStatusSuccessDescription: {
    id: 'payroll.changePayrollDaysStatusSuccessDescription',
    defaultMessage: 'Poprawnie zmieniono status wybranych dni',
  },
  changePayrollDaysStatusFailureTitle: {
    id: 'payroll.changePayrollDaysStatusFailureTitle',
    defaultMessage: 'Błąd',
  },
  changePayrollDaysStatusFailureDescription: {
    id: 'payroll.changePayrollDaysStatusFailureDescription',
    defaultMessage: 'Spróbuj ponownie',
  },
  changePayrollDaysStatusForMultipleEmployeesSuccessTitle: {
    id: 'payroll.changePayrollDaysStatusForMultipleEmployeeSuccessTitle',
    defaultMessage: 'Sukces',
  },
  changePayrollDaysStatusForMultipleEmployeesSuccessDescription: {
    id: 'payroll.changePayrollDaysStatusForMultipleEmployeeSuccessDescription',
    defaultMessage: 'Poprawnie zmieniono status wybranych pracowników w wybranych dniach',
  },
});
