import { parseMinutesToDecimalForm } from '@/utils/dateHelper';

const headers = ['', '', 'Początek', 'Koniec', 'Sprzedaż', 'Czas pracy', 'SPMH', '% wynagrodzenie', 'Bonus'];

export const bonusSystemFormattingHeaders = ['', '', '', '', 'n', 'n', 'n', 'n', 'n'];

export const bonusSystemOmitRows = { start: 2, end: 1 }; // start rows: location name, headers row; end: summary row

export const getSummaryRow = detailsRows => {
  let [summaryActualSales, summaryTimes, summaryBonus] = [0, 0, 0];
  detailsRows.forEach(row => {
    const [_date, _shiftName, _startHour, _endHour, actualSales, timeWorked, _spmh, _bonusThreshold, bonus] = row;
    summaryActualSales += Number(actualSales);
    summaryTimes += Number(timeWorked);
    summaryBonus += Number(bonus);
  });
  const summarySpmh = (summaryActualSales / summaryTimes).toFixed(2);
  return [
    '',
    '',
    '',
    'Suma',
    Number(summaryActualSales.toFixed(2)),
    Number(summaryTimes.toFixed(2)),
    Number(summarySpmh),
    '-',
    Number(summaryBonus.toFixed(2)),
  ];
};

export const sortRowsByDate = rows => {
  const sortedRows = [...rows];
  sortedRows.sort((a, b) => {
    const [dateA] = a;
    const [dateB] = b;
    return new Date(dateA) - new Date(dateB);
  });
  return sortedRows;
};

export const getFormattedRowsForDate = (groupedByDate, date) =>
  Object.values(groupedByDate[date]).map((obj, index) => [
    obj.date,
    `Zmiana #${index + 1}`,
    obj.start_hour,
    obj.end_hour,
    obj.actual_sales.toFixed(2),
    parseMinutesToDecimalForm(obj.time_worked, true),
    obj.spmh.toFixed(2),
    (obj.bonus_threshold / 100).toFixed(3),
    obj.bonus.toFixed(2),
  ]);

export const getGroupedByDates = locationRawData =>
  locationRawData.reduce((dates, obj) => {
    const { date } = obj;
    dates[date] = dates[date] ? [...dates[date], obj] : [obj];
    return dates;
  }, {});

const getDetailsRows = locationRawData => {
  const groupedByDate = getGroupedByDates(locationRawData);

  const rows = [];
  Object.keys(groupedByDate).forEach(date => {
    rows.push(...getFormattedRowsForDate(groupedByDate, date));
  });
  return sortRowsByDate(rows);
};

const getBodyData = (locationName, locationRawData) => {
  const locationNameRow = [locationName];
  const detailsRows = getDetailsRows(locationRawData);
  const summaryRow = getSummaryRow(detailsRows);
  return [locationNameRow, headers, ...detailsRows, summaryRow];
};

export const getBonusSystemExportsSheets = (rawData, userLocations) =>
  Object.keys(rawData).map(locationId => {
    const locationName = userLocations.find(({ id }) => id === locationId).name;
    return {
      data: getBodyData(locationName, rawData[locationId]),
      name: locationName,
    };
  });
