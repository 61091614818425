import { connect } from 'react-redux';

import {
  changeSorting,
  deleteAvaTypeConfirm,
  massDeleteAvaTypeConfirm,
  startEdit,
  toggleAllCheckboxes,
  toggleCheckbox,
  toggleModal,
} from '@/actions/companymanage';

import AvaTypesTable from './AvaTypesTable.jsx';

const mapStateToProps = state => ({
  userCustomTypes: state.reducer.userCustomTypes,
  avaTypesListUi: state.reducer.listsUi.avaTypes,
  deviceInfo: state.reducer.deviceInfo,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  toggleCheckbox,
  toggleAllCheckboxes,
  massDelete: massDeleteAvaTypeConfirm,
  deleteAva: deleteAvaTypeConfirm,
  toggleModal,
  startEdit,
  changeSorting,
};

const AvaTypesTableContainer = connect(mapStateToProps, mapDispatchToProps)(AvaTypesTable);

export default AvaTypesTableContainer;
