import { getFromToFromDateStore, getInitCalendarChoice, getToday } from './dateHelper';

export const getInitialRepeatShiftState = mainDateStore => {
  const [start, end] = getFromToFromDateStore(mainDateStore);
  return {
    activeRepeatTab: 'period',
    selectedRange: { start, end },
    selectedChoices: getInitCalendarChoice(getToday()),
    selectedWeekdays: [],
    perWhichDay: null,
  };
};

export const createRepeatObject = ({
  selectedRange,
  selectedChoices,
  selectedWeekdays,
  perWhichDay,
  activeRepeatTab,
}) => ({
  type: activeRepeatTab,
  selected: {
    selectedRange,
    selectedChoices,
    selectedWeekdays,
    perWhichDay,
  },
});
