import { conn } from '@/actions';
import { StandardDate } from '@/types/dates.types';

import { createActionName, createAppAsyncThunk } from '../utils';
import { sliceName } from './managerNotes.slice';
import { ManagerNote, ManagerNotesStoreState, ManagerNoteToPost } from './managerNotes.types';

export const getManagerNotes = createAppAsyncThunk(
  createActionName(sliceName, 'getManagerNotes'),
  async ({ from, to }: { from: StandardDate; to: StandardDate }, thunkApi) => {
    const state = thunkApi.getState();
    const locationsIds = state.reducer.scheduleLocationFilter;
    const managerNotes = await conn.getManagerNotes(from, to, locationsIds);
    return managerNotes.data as ManagerNotesStoreState;
  },
);

export const deleteManagerNote = createAppAsyncThunk(
  createActionName(sliceName, 'deleteManagerNote'),
  async (note: ManagerNote) => {
    await conn.deleteManagerNotes([note.id]);
  },
);

export const createUpdateManagerNote = createAppAsyncThunk(
  createActionName(sliceName, 'createUpdateManagerNote'),
  async (note: ManagerNoteToPost) => {
    const response = await conn.addManagerNote(note);
    return response.data as ManagerNote;
  },
);
