import { defineMessages } from 'react-intl';

const messages = defineMessages({
  failToast: {
    id: 'toastr.errorTitle',
    defaultMessage: 'Błąd!',
  },
  successToast: {
    id: 'toastr.successTitle',
    defaultMessage: 'Sukces!',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Usuń',
  },
  deleteAvailabilityConfirmTitle: {
    id: 'schedule.deleteAvailabilityConfirm.title',
    defaultMessage: 'Usuń dostępność',
  },
  deleteAvailabilityConfirmDesc: {
    id: 'schedule.deleteAvailabilityConfirm.description',
    defaultMessage: 'Czy na pewno chcesz usunąć dostępność?',
  },
  deleteAvailabilitiesConfirmTitle: {
    id: 'schedule.deleteAvailabilitiesConfirm.title',
    defaultMessage: 'Usuń dostępności',
  },
  deleteAvailabilitiesConfirmDesc: {
    id: 'schedule.deleteAvailabilitiesConfirm.description',
    defaultMessage:
      'Zaznaczono {availabilityNo, plural,  one {1 dostępność} other {# dostępności}}. Czy na pewno chcesz je usunąć?',
  },
  deleteAvailabilitiesSuccesful: {
    id: 'schedule.deleteAvailabilitiesSuccesful.description',
    defaultMessage: 'Poprawnie usunięto dostępności',
  },
  deleteAvailabilitiesError: {
    id: 'schedule.deleteAvailabilitiesError.description',
    defaultMessage: 'Nie udało się usunąć dostępności',
  },
});

export default messages;
