export const baseStyle = {
  fill: true,
  lineTension: 0.05,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

export const blueChart = {
  // Chart style
  borderColor: 'rgba(6, 187, 254,1)',
  backgroundColor: 'rgba(6, 187, 254,0.5)',
  // Point style
  pointBorderColor: 'rgba(6, 187, 254,1)',
  pointHoverBackgroundColor: 'rgba(6, 187, 254,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointBackgroundColor: '#fff',
};

export const grayChart = {
  // Chart style
  borderColor: 'rgba(170, 170, 170,1)',
  backgroundColor: 'rgba(170, 170, 170,0.5)',
  // Point style
  pointBorderColor: 'rgba(120,120,120,1)',
  pointHoverBackgroundColor: 'rgba(120,120,120,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointBackgroundColor: '#fff',
};

export const purpleChart = {
  // Chart style
  borderColor: 'rgba(133, 102, 159, 1)',
  backgroundColor: 'rgba(133, 102, 159, 0.5)',
  // Point style
  pointBorderColor: 'rgba(133, 102, 159, 1)',
  pointHoverBackgroundColor: 'rgba(133, 102, 159, 1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointBackgroundColor: '#fff',
};
