import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employees: {
    id: 'position.addModal.employees',
    defaultMessage: 'Pracownicy',
  },
  selectAllMessage: {
    id: 'position.addModal.selectAllButton',
    defaultMessage: 'Wszyscy',
  },
  deselectAllMessage: {
    id: 'position.addModal.deselectAllButton',
    defaultMessage: 'Żaden',
  },
  chooseEmployees: {
    id: 'position.requestModal.chooseEmployee',
    defaultMessage: 'Wybierz pracowników',
  },
});
