import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import AttendanceTableRow from './AttendanceTableRow.jsx';

const mapStateToProps = ({ reducer }) => ({
  locationSettings: reducer.settings.locationSettings,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceTableRow);
