import { connect } from 'react-redux';

import { clearBudgetImports,runExport } from '@/actions/exports.js';
import { toggleImportBudgetModalInputError,toggleImportBudgetModalOverlay } from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState';
import { BUDGET_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportBudgetModal from './MDImportBudgetModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === BUDGET_IMPORT_MODAL,
  dateArray: state.reducer.mainDateStore.dateArray,
  pendingExports: state.reducer.exports.pendingExports,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  showOverlay: state.reducer.scheduleUIState.importBudgetModal.showOverlay,
  showInputError: state.reducer.scheduleUIState.importBudgetModal.showInputError,
});

const mapDispatchToProps = {
  clearBudgetImports,
  hideModal,
  runExport,
  toggleImportBudgetModalOverlay,
  toggleImportBudgetModalInputError,
};

const ImportBudgetModalContainer = connect(mapStateToProps, mapDispatchToProps)(ImportBudgetModal);

export default ImportBudgetModalContainer;
