import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import IconDropdownButton from '@/components/common/iconDropdownButton/IconDropdownButton';
import MDChip from '@/components/common/MDChip/MDChip.jsx';
import CustomFilter from '@/components/common/MDTable/CustomFilter/CustomFilter.jsx';
import MDTable from '@/components/common/MDTable/MDTable.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import TopBarAbsences from '@/components/TopBars/TopBarAbsences/TopBarAbsences.redux.js';
import { getAbsenceStatusName, getAbsenceTypeName, getColorFromAbsenceStatus } from '@/utils/absenceHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getColumnId, getSortingDirection } from '@/utils/tableHelpers/tableHelpers.js';

import AbsenceAddModal from '../modals/AbsenceAddModal/AbsenceAddModal.redux.js';
import AbsenceCalendarModal from '../modals/AbsenceCalendarModal/AbsenceCalendarModal.redux.js';
import AbsenceHistoryModal from '../modals/AbsenceHistoryModal/AbsenceHistoryModal.redux.js';
import AbsenceProposalModal from '../modals/AbsenceProposalModal/AbsenceProposalModal.redux.js';
import AbsencesViewHeaderOptions from './AbsencesViewHeaderOptions/AbsencesViewHeaderOptions.redux';
import AbsenceViewOptions from './AbsenceViewOptions/AbsenceViewOptions.redux.js';
import { columns } from './columns.js';
import { messages } from './messages.js';

import './AbsencesView.scss';

class AbsencesView extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedData: [],
      filters: {
        request_number: '',
        employee: '',
        absence_type_name: '',
        from: '',
        to: '',
        recent_status_change_by: '',
        status: '',
      },
      orderBy: '',
      direction: 'desc',
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterChangeDebounce = debounce(this.onFilterChange, 500);
    bindPrototypeFunctions(this);
  }

  onFilterChange(name, value) {
    this.setState(
      prevState => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this.changeFilter(),
    );
  }

  getColumns() {
    const filteredColumns = this.props.userPermissions.isEmployee
      ? columns.filter(column => !column.hideForEmployee)
      : columns;

    return filteredColumns.map(column => ({
      Header: this.context.intl.formatMessage(column.Header),
      accessor: column.accessor,
      Filter: () => (
        <CustomFilter
          value={this.state.filters[column.accessor]}
          name={column.accessor}
          onChange={this.onFilterChangeDebounce}
        />
      ),
    }));
  }

  changeFilter() {
    const { filters, orderBy, direction } = this.state;
    const { from: fromSearch, to: toSearch, statusChip: status, ...restOfFilters } = filters;

    this.props.changeAbsencesData(
      1,
      this.props.absencesTable.numberOfItemsPerPage,
      this.props.mainDateStore.customDate.start,
      this.props.mainDateStore.customDate.end,
      {
        ...restOfFilters,
        status,
        from_search: fromSearch,
        to_search: toSearch,
      },
      orderBy,
      direction,
    );
  }

  changeNumberOfItemsPerPage(event) {
    this.setState({
      selectedData: [],
    });
    this.props.changeAbsencesData(
      1,
      parseInt(event.target.value),
      this.props.mainDateStore.customDate.start,
      this.props.mainDateStore.customDate.end,
    );
  }

  changeCurrentPage(newPage) {
    const { filters, orderBy, direction } = this.state;
    this.setState({
      selectedData: [],
    });
    this.props.changeAbsencesData(
      newPage,
      this.props.absencesTable.numberOfItemsPerPage,
      this.props.mainDateStore.customDate.start,
      this.props.mainDateStore.customDate.end,
      {
        ...filters,
        from_search: filters.from,
        to_search: filters.to,
      },
      orderBy,
      direction,
    );
  }

  toggleSelectedData(element) {
    if (this.state.selectedData.some(data => data.id === element.id)) {
      this.setState(prevState => ({
        selectedData: prevState.selectedData.filter(data => element.id !== data.id),
      }));
    } else {
      this.setState(prevState => ({
        selectedData: [...prevState.selectedData, element],
      }));
    }
  }

  async handleSort(sortedColumns) {
    if (sortedColumns.length === 0) {
      return;
    }
    const columnId = getColumnId(sortedColumns);

    const { filters } = this.state;
    const direction = getSortingDirection(this.state.order_by, columnId, this.state.direction);

    await this.props.changeAbsencesData(
      1,
      this.props.absencesTable.numberOfItemsPerPage,
      this.props.mainDateStore.customDate.start,
      this.props.mainDateStore.customDate.end,
      {
        ...filters,
        from_search: filters.from,
        to_search: filters.to,
        status: filters.statusChip,
        statusChip: undefined,
      },
      columnId,
      direction,
    );

    this.setState({
      order_by: columnId,
      direction,
    });
  }

  render() {
    const { props } = this;
    const { currentPage, numberOfItemsPerPage, numberOfItems } = props.absencesTable;
    return (
      <div className="absencesView">
        <AbsenceProposalModal />
        <AbsenceHistoryModal />
        <AbsenceCalendarModal />
        <AbsenceAddModal />
        <TopBarAbsences />
        <div className="absencesView__table">
          <MDTable
            data={props.absences.slice(0, numberOfItemsPerPage).map(absence => ({
              ...absence,
              employee: absence.employee_full_name,
              absence_type_name: getAbsenceTypeName(absence.category || absence.absence_type_name, this.context.intl),
              statusChip: (
                <MDChip modifiers={[getColorFromAbsenceStatus(absence.status)]}>
                  {getAbsenceStatusName(absence.status, this.context.intl)}
                </MDChip>
              ),
              recent_status_change_by: absence.recent_status_change_by_full_name || '-',
              recent_status_change_by_id: absence.recent_status_change_by,
            }))}
            columns={this.getColumns()}
            selectedData={this.state.selectedData}
            toggleSelectedData={this.toggleSelectedData}
            currentPage={currentPage}
            changeCurrentPage={this.changeCurrentPage}
            numberOfItemsPerPage={numberOfItemsPerPage}
            changeNumberOfItemsPerPage={this.changeNumberOfItemsPerPage}
            numberOfItems={numberOfItems}
            numberOfPages={Math.ceil(numberOfItems / numberOfItemsPerPage)}
            options={<AbsenceViewOptions />}
            tableOptions={<AbsencesViewHeaderOptions showModal={props.showModal} />}
            tableEmpty={
              <div className="absencesView__emptyTable">
                <EmptyState
                  name="jobtitleCTA"
                  imgSrc="/img/jobtitlesCTA.png"
                  emptyStateHeader={this.context.intl.formatMessage(messages.absencesViewEmptyTable, {})}
                  shiftX="0"
                />
              </div>
            }
            withPagination
            withSearch
            onSort={this.handleSort}
            withBigPadding
            isDescSorting
          />
        </div>
      </div>
    );
  }
}

AbsencesView.defaultProps = {
  absences: [],
};

AbsencesView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsencesView.propTypes = {
  absences: PropTypes.arrayOf(PropTypes.shape({})),
  absencesTable: PropTypes.shape({
    currentPage: PropTypes.number,
    numberOfItems: PropTypes.number,
    numberOfItemsPerPage: PropTypes.number,
  }),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  showModal: PropTypes.func,
  changeAbsencesData: PropTypes.func,
};

export default AbsencesView;
