import { connect } from 'react-redux';

import { createOpenShift } from '@/actions/openShifts.js';
import { openOpenShiftModal } from '@/actions/uiState';

import Component from './ScheduleTableSingleDayOpenShiftRow.jsx';

const mapStateToProps = (state, ownProps) => ({
  date: state.reducer.mainDateStore.dateArray[0],
  rowSelected: !!state.reducer.schedule.openShiftsRowSelect[ownProps.locationId],
  isOpenShiftsRowSticky: !!state.reducer.schedule.stickyRows.openShiftsSticky,
  isTradeShiftsRowSticky: state.reducer.schedule.stickyRows.tradeShiftsSticky,
  restrictions: state.reducer.userPermissions.restrictions,
  userJobTitles: state.reducer.userJobTitles,
  openShiftsData: state.reducer.schedule.openShifts.structure,
  openShiftIds:
    state.reducer.schedule.openShifts.structure?.[ownProps.locationId]?.[state.reducer.mainDateStore.dateArray[0]],
});

const mapDispatchToProps = {
  openOpenShiftModal,
  createOpenShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
