export const MODAL_MODIFIERS = ['narrow'];
export const INPUT_MODIFIERS = ['modal'];

export const getInitialState = ({
  email,
  city,
  nip_number: nipNumber,
  postal_code: postalCode,
  address,
  company_name: companyName }) => ({
  email: email || '',
  companyName: companyName || '',
  nip: nipNumber || '',
  address: address || '',
  city: city || '',
  zipCode: postalCode || '',
  errors: {},
});

export const mappedDataToRequestObject = data => ({
  email: data.email,
  company_name: data.companyName,
  nip_number: data.nip,
  city: data.city,
  address: data.address,
  postal_code: data.zipCode,
});
