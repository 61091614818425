import { MDDatesPicker } from '@/components/common/inputs/MDComponents';
import { FieldValue } from '@/components/companymanage/employees/EditMassEmployeesModal/EmployeesEditMassValueField.helpers.tsx';
import { Position } from '@/types';

import './datePickerField.scss';

interface DatePickerFieldProps {
  label: string;
  fieldValues: FieldValue;
  onChange: (value: FieldValue) => void;
  id: string;
  tooltip?: string;
  position?: Position;
}

const DatePickerField = ({ label, onChange, fieldValues, id, tooltip, position }: DatePickerFieldProps) => (
  <MDDatesPicker
    id={id}
    handleInputChange={e => {
      onChange({ ...fieldValues, [id]: e.target.value[0] });
    }}
    values={fieldValues?.[id] ? [fieldValues[id]] : []}
    singleDate
    label={label}
    position={position || Position.BOTTOM}
    tooltip={tooltip}
  />
);

export default DatePickerField;
