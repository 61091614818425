import classnames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import Popover from '@/components/common/Popover/Popover';
import { EMPLOYEE_PAYROLL_VIEW_SHOW, LABELS_MANAGE } from '@/constants/Permissions';
import { ATTENDANCE_LABELS_MANAGE_SHOW, PERMISSIONS_TO_EDIT_ATTENDANCE } from '@/constants/Roles';
import { DIRECTIONS } from '@/constants/ui.ts';

import { messages } from './RolesTableRowDetails.messages';

import './RolesTableRowDetails.scss';

const RolesTableRowDetails = (props, { intl }) => {
  const { permissions, roles, checkedPermissions, togglePermission, userPermissions, companySettings } = props;

  const singleCheckboxes = (permission) => {
    const hideLabelsCheckboxes =
      permission.id === ATTENDANCE_LABELS_MANAGE_SHOW && !userPermissions.permissions.includes(LABELS_MANAGE);

    const hideEmployeePayrollCheckboxes =
      permission.id === EMPLOYEE_PAYROLL_VIEW_SHOW && !companySettings.employee_ecp_enabled;

    if (hideLabelsCheckboxes || hideEmployeePayrollCheckboxes) return null;

    return roles.map((role) => {
      const disabledLabelCheckbox =
        permission.id === ATTENDANCE_LABELS_MANAGE_SHOW &&
        !checkedPermissions[role.id].some(({ id }) => PERMISSIONS_TO_EDIT_ATTENDANCE.includes(id));

      const checked = !!checkedPermissions[role.id].find((perm) => perm.id === permission.id);
      const disabled =
        role.id === 'manager' ||
        role.id === 'employee' ||
        !role.canEdit ||
        !(permission.for_employee === !role.is_manager || permission.for_manager === role.is_manager) ||
        disabledLabelCheckbox;
      const checkboxClasses = classnames('k-rolesTableRowDetails__checkbox', {
        'k-rolesTableRowDetails__disabledCheckedCheckbox': disabled && checked,
      });

      return (
        <td className="k-rolesTableRowDetails__checkboxColumn day" key={permission.id + role.id}>
          <div className="k-rolesTableRowDetails__checkboxContainer">
            {disabledLabelCheckbox ? (
              <Popover
                content={<div>{intl.formatMessage(messages.disabledCheckboxWhenEditAttendancesIsUnavailable)}</div>}
                showShadow={false}
                showOnHover
                isDark
                hideOnBlur
                position={DIRECTIONS.TOP}
                noArrow
                noBorder
              >
                <div>
                  <Checkbox
                    className={checkboxClasses}
                    checked={checked}
                    toggle={() => togglePermission(role.id, permission)}
                    disabled={disabled}
                  />
                </div>
              </Popover>
            ) : (
              <Checkbox
                className={checkboxClasses}
                checked={checked}
                toggle={() => togglePermission(role.id, permission)}
                disabled={disabled}
              />
            )}
          </div>
        </td>
      );
    });
  };

  return (
    <table className="table">
      <tbody>
        {permissions.map((permission) => (
          <tr key={permission.id} className="k-rolesTableRowDetails__details">
            {singleCheckboxes(permission)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

RolesTableRowDetails.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape({})),
  checkedPermissions: PropTypes.shape({}),
  togglePermission: PropTypes.func,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  companySettings: PropTypes.shape({
    employee_ecp_enabled: PropTypes.bool,
  }),
};

RolesTableRowDetails.contextTypes = {
  intl: PropTypes.shape({}),
};

export default RolesTableRowDetails;
