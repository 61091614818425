import { connect } from 'react-redux';

import { getGeolocation } from '@/actions/deviceInfo';
import { getAllViewSettings, saveAllViewSettings } from '@/actions/settingsSaver/settingsSaver.ts';
import { hideModal } from '@/actions/uiState.js';
import MainApp from '@/components/MainApp.jsx';
import { CONFIRM_MODAL } from '@/constants/modalTypes.js';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  showOfflineMsg: state.reducer.uiState.showOfflineMsg,
  showFullScreenLoader: state.unauthorizedReducer.loaderState.init > 0,
  showConfirmModal: state.reducer.uiState.showModal === CONFIRM_MODAL,
  confirmModalObject: state.reducer.uiState.modalObject[CONFIRM_MODAL],
  notificationBar: state.reducer.notificationBar,
});

const mapDispatchToProps = {
  hideModal,
  saveAllViewSettings,
  getAllViewSettings,
  getGeolocation,
};

const MainAppView = connect(mapStateToProps, mapDispatchToProps)(MainApp);

export default MainAppView;
