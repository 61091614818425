import { connect } from 'react-redux';

import { sendAutoSchedulerInformation, sendAutoSchedulerInformationViaProxy } from '@/actions/autoscheduler.js';
import { addRecommendedSchedule } from '@/actions/recommendedSchedule.js';
import { deleteOpenShiftTemplate } from '@/actions/templates.js';
import { hideModal, showModal } from '@/actions/uiState.js';
import { AUTO_GENERATE_SCHEDULE_MODAL } from '@/constants/modalTypes.js';

import AutoGenerateScheduleModal from './AutoGenerateScheduleModalForProxy.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === AUTO_GENERATE_SCHEDULE_MODAL,
  modalObject: state.reducer.uiState.modalObject[AUTO_GENERATE_SCHEDULE_MODAL],
  userTemplates: state.reducer.userTemplates,
  userEmployees: state.reducer.userEmployees,
  userJobTitles: state.reducer.userJobTitles,
  selectedLocations: state.reducer.scheduleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  demo: state.reducer.demo,
  openShifts: state.reducer.openShifts,
  employmentConditions: state.reducer.employmentConditions,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  showModalFunc: showModal,
  hideModal,
  sendAutoSchedulerInformationViaProxy,
  sendAutoSchedulerInformation,
  deleteOpenShiftTemplate,
  addRecommendedSchedule,
};

const AutoGenerateScheduleModalContainer = connect(mapStateToProps, mapDispatchToProps)(AutoGenerateScheduleModal);

export default AutoGenerateScheduleModalContainer;
