import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import { MDTextInput, MDTimeRangeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import WeekdayPicker from '@/components/common/RangePicker/WeekdayPicker/WeekdayPicker.jsx';
import { AUTO_GENERATE_SCHEDULE_VIEW, AUTOSCHEDULER_PROXY_ENABLE } from '@/constants/Permissions';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getFirstDayOfWeekAsMonday } from '@/utils/dateHelper';
import { inputValidation } from '@/utils/inputValidation.js';

const modalMessages = defineMessages({
  addTitle: {
    id: 'templatesShift.addModal.addTitle',
    defaultMessage: 'Dodaj zmiany',
  },
  editTitle: {
    id: 'templatesShift.addModal.editTitle',
    defaultMessage: 'Edytuj zmiany',
  },
});

const ADD_TITLE = 'addTitle';
const EDIT_TITLE = 'editTitle';

class AddTemplatesShiftModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selectedWeekdays: [],
      workingHours: '__:__-__:__',
      errorMessage: '',
      employeesNumber: this.props.modalObject.rowData.amount || 1,
      gid: '',
    };
    bindPrototypeFunctions(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      workingHours: nextProps.modalObject.rowData.working_hours,
      selectedWeekdays: [nextProps.modalObject.date],
      employeesNumber: nextProps.modalObject.rowData.amount || 1,
      gid: nextProps.modalObject.rowData.gid,
    });
  }

  onSubmit() {
    const { workingHours, selectedWeekdays, employeesNumber, gid } = this.state;
    const { rowData } = this.props.modalObject;
    const { color, company_id: companyId, hourly_wage: hourlyWage, id, title, date } = rowData;
    const { currentTemplate } = this.props.userTemplates;
    const err = inputValidation('workingHours', workingHours) || inputValidation('employeesNumber', employeesNumber);
    this.setState({
      errorMessage: err,
    });
    if (err !== '') return;

    const rowDataJobTitleId = rowData.job_title_id || rowData.job_title?.id;
    const isEditing = Boolean(getFirstDayOfWeekAsMonday(date) && rowData.working_hours && rowDataJobTitleId);
    const shift = {
      working_hours: workingHours,
      job_title: {
        color,
        company_id: companyId,
        hourly_wage: hourlyWage,
        id,
        title,
      },
      draft: true,
      date,
      amount: parseInt(employeesNumber),
      gid: gid ? parseInt(gid) : undefined,
    };
    const newShifts = selectedWeekdays.map(day => {
      const key = isEditing ? `${day}_${rowData.working_hours}_${rowDataJobTitleId}` : undefined;
      return {
        ...shift,
        date: day,
        key,
      };
    });
    this.props.replaceTemplatesShifts(newShifts, currentTemplate, isEditing, true);
    this.props.onHide();
  }

  changeWorkingHours(hours) {
    this.setState({ workingHours: hours });
  }

  changeEmployeesNumber(e) {
    const employeesNumber = e.target.value;
    const newEmployeesNumber = Number(employeesNumber) === 0 ? '' : Number(employeesNumber);
    this.setState({ employeesNumber: newEmployeesNumber });
  }

  changeCategoryGroupId(e) {
    this.setState({ gid: e.target.value });
  }

  changeWeekdays(e) {
    const dayIndex = this.state.selectedWeekdays.indexOf(e);
    if (!~dayIndex) {
      const newSelectedChoices = [...this.state.selectedWeekdays, e];
      this.setState({
        selectedWeekdays: newSelectedChoices,
      });
    } else {
      const newSelectedChoices = [
        ...this.state.selectedWeekdays.slice(0, dayIndex),
        ...this.state.selectedWeekdays.slice(dayIndex + 1),
      ];
      this.setState({
        selectedWeekdays: newSelectedChoices,
      });
    }
  }

  render() {
    const { userShiftblocks, modalObject, showModal, onHide, permissions } = this.props;
    const { intl } = this.context;

    const filteredShiftBlockOptions =
      userShiftblocks
        .filter(
          shiftBlock =>
            shiftBlock.job_titles === null ||
            shiftBlock.job_titles.filter(jobTitleId => jobTitleId === modalObject.rowData.id).length,
        )
        .map(s => s.working_hours) || [];

    const isEmptyShift = modalObject.rowData.shifts;

    const title = isEmptyShift ? ADD_TITLE : EDIT_TITLE;
    const userHasAccessToAutoschedulerAndProxy =
      permissions.includes(AUTO_GENERATE_SCHEDULE_VIEW) && permissions.includes(AUTOSCHEDULER_PROXY_ENABLE);

    return (
      <MDKadroModal
        show={showModal}
        onHide={onHide}
        onSubmit={this.onSubmit}
        title={intl.formatMessage(modalMessages[title], {})}
        modifiers={['narrow']}
        errorMessage={this.state.errorMessage && this.context.intl.formatMessage(this.state.errorMessage, {})}
      >
        <div className="heading heading--extraTopMargin">
          {isEmptyShift ? (
            <FormattedMessage
              id="templatesShift.addModal.header"
              defaultMessage="Dodaj zmianę dla {jobTitleLabel}"
              values={{
                jobTitleLabel: <ColorLabel text={modalObject.rowData.title} color={modalObject.rowData.color} />,
              }}
            />
          ) : (
            <FormattedMessage
              id="templatesShift.editModal.header"
              defaultMessage="Edytuj zmianę dla {jobTitleLabel}"
              values={{
                jobTitleLabel: <ColorLabel text={modalObject.rowData.title} color={modalObject.rowData.color} />,
              }}
            />
          )}
        </div>
        <MDTextInput
          id="employeesNumber"
          type="number"
          label={<FormattedMessage id="templatesShift.addModal.employeesNumber" defaultMessage="Liczba pracowników" />}
          value={this.state.employeesNumber}
          onChange={this.changeEmployeesNumber}
          modifiers={['modal']}
          isNumberInteger
        />
        <MDTimeRangeInput
          value={this.state.workingHours}
          options={filteredShiftBlockOptions}
          onChange={this.changeWorkingHours}
          label={<FormattedMessage id="templatesShift.addModal.workingHours" defaultMessage="Czas" />}
          modifiers={['modal']}
        />
        {userHasAccessToAutoschedulerAndProxy && (
          <MDTextInput
            id="gid"
            type="number"
            label={<FormattedMessage id="templatesShift.addModal.categoryGroup" defaultMessage="Kategoria" />}
            value={this.state.gid}
            onChange={this.changeCategoryGroupId}
            modifiers={['modal']}
            isNumberInteger
          />
        )}
        {isEmptyShift && (
          <WeekdayPicker
            title={
              <FormattedMessage
                id="templatesShift.addModal.weekdayPicker"
                defaultMessage="Określ dni obowiązywania grafiku:"
              />
            }
            selectedWeekdays={this.state.selectedWeekdays}
            changeWeekdays={this.changeWeekdays}
          />
        )}
      </MDKadroModal>
    );
  }
}

AddTemplatesShiftModal.defaultProps = {
  modalObject: {
    rowData: {
      title: '',
      color: '',
    },
  },
};

AddTemplatesShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddTemplatesShiftModal.propTypes = {
  userShiftblocks: PropTypes.arrayOf(PropTypes.shape({})),
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({ shifts: PropTypes.arrayOf(PropTypes.shape({})) }),
  }),
  modalObject: PropTypes.shape({
    rowData: PropTypes.shape({
      id: PropTypes.string,
      shiftID: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
      company_id: PropTypes.string,
      amount: PropTypes.number,
      working_hours: PropTypes.string,
      hourly_wage: PropTypes.number,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
      date: PropTypes.number,
      job_title_id: PropTypes.string,
      job_title: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    date: PropTypes.number,
    templateID: PropTypes.string,
  }),
  onHide: PropTypes.func,
  replaceTemplatesShifts: PropTypes.func,
  showModal: PropTypes.bool,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default AddTemplatesShiftModal;
