import { connect } from 'react-redux';

import { createOpenShift } from '@/actions/openShifts.js';
import { closeOpenShiftFromAutoGenerateModal, setAutoschedulerLocation } from '@/actions/uiState.js';
import CreateOpenShiftFromAutoGenerateModal from '@/components/scheduler/modals/CreateOpenShiftFromAutoGenerateModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showOpenShiftFromAutoGenerate,
  modalObject: state.reducer.uiState.openShiftFromAutoGenerateModalObject,
  selectedLocations: state.reducer.scheduleLocationFilter,
  userJobTitles: state.reducer.userJobTitles,
  autoschedulerLocation: state.reducer.uiState.autoschedulerLocation,
});

const mapDispatchToProps = {
  onHide: closeOpenShiftFromAutoGenerateModal,
  createOpenShift,
  setAutoschedulerLocation,
};
const CreateOpenShiftFromAutoGenerateModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOpenShiftFromAutoGenerateModal);

export default CreateOpenShiftFromAutoGenerateModalContainer;
