import PropTypes from 'prop-types';

import LockButton from '@/components/common/LockButton/LockButton';

import messages from '../messages';

const TradeShiftRowTitle = (
  { offsetTop, locationId, toggleTradeShiftsRowStickiness, rowSticky, isEmployee, onTradeShiftRowClick },
  { intl },
) => (
  <th
    style={{
      top: `${offsetTop}px`,
    }}
    onClick={() => !isEmployee && onTradeShiftRowClick(locationId)}
  >
    {intl.formatMessage(messages.scheduleTableTradeShiftTitle, {})}
    <LockButton
      onClick={e => {
        e.stopPropagation();
        toggleTradeShiftsRowStickiness();
      }}
      locked={rowSticky}
    />
  </th>
);
TradeShiftRowTitle.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

TradeShiftRowTitle.propTypes = {
  offsetTop: PropTypes.number,
  locationId: PropTypes.string,
  onTradeShiftRowClick: PropTypes.func,
  toggleTradeShiftsRowStickiness: PropTypes.func,
  rowSticky: PropTypes.bool,
  isEmployee: PropTypes.bool,
};
export default TradeShiftRowTitle;
