import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState.js';
import { VIDEO_TUTORIAL_MODAL } from '@/constants/modalTypes.js';

import VideoTutorialModal from './VideoTutorialModal';

const mapStateToProps = ({ reducer }) => ({
  showModal: reducer.uiState.showModal === VIDEO_TUTORIAL_MODAL,
  ...reducer.uiState.modalObject[VIDEO_TUTORIAL_MODAL],
});

const mapDispatchToProps = {
  onHide: hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoTutorialModal);
