import type { IntlShape } from 'react-intl';

import { EmployeeItem } from '../../loanEmployeesProposalModals.helpers.ts';
import { employeesMultiSelectMessages } from './loanEmployeesProposalEmployeesStep.messages.ts';

export const getEmployeesTitlesMessages = (intl: IntlShape, employeesItems: EmployeeItem[]) => {
  const selectedEmployeesAmount = employeesItems.filter(({ active }) => active).length;

  return {
    headerTitle: intl.formatMessage(employeesMultiSelectMessages.headerTitle),
    selectAll: intl.formatMessage(employeesMultiSelectMessages.selectAll),
    deselectAll: intl.formatMessage(employeesMultiSelectMessages.deselectAll),
    bodyLabel: intl.formatMessage(employeesMultiSelectMessages.bodyLabel),
    footerTitle: `${intl.formatMessage(employeesMultiSelectMessages.footerTitle)} (${selectedEmployeesAmount}/${
      employeesItems.length
    })`,
  };
};
