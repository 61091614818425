import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import ReportsView from './ReportsView.jsx';

const mapStateToProps = ({ reducer }) => ({
  permissions: reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsView);
