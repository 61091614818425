import { connect } from 'react-redux';

import ScheduleBudgetTableEstimatesCell from './ScheduleBudgetTableEstimatesCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  estimate: state.reducer.schedule.budget.budgetEstimates.dailyValues[ownProps.date],
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
  permissions: state.reducer.userPermissions.permissions,
  showHolidays:
    state.reducer.scheduleUIState.selectedDisplayMode.type !== 'templates' &&
    state.reducer.schedule.viewSettings.holidays.value,
});

export default connect(mapStateToProps)(ScheduleBudgetTableEstimatesCell);
