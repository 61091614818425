import cn from 'classnames';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import ScheduleSingleDayNewShiftLayer from '@/components/scheduler/ScheduleTable/ScheduleSingleDayNewShiftLayer/ScheduleSingleDayNewShiftLayer.redux';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { SINGLE_DAY_COL_SPAN } from '@/constants/scheduleStyleChangePoints';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements';

import OpenShiftDropArea from '../../OpenShiftDropArea/OpenShiftDropArea.redux';
import OpenShiftRowTitle from '../OpenShiftRowTitle/OpenShiftRowTitle.redux';
import OpenShiftShift from '../OpenShiftShift/OpenShiftShift.redux';
import { useVisibleOpenShiftsIdsForLocationGroups } from '../useVisibleOpenShiftsIdsForLocationGroups';

const ScheduleTableSingleDayOpenShiftRow = ({
  date,
  locationId,
  rowSelected,
  isOpenShiftsRowSticky,
  restrictions,
  openOpenShiftModal,
  createOpenShift,
  userJobTitles,
  openShiftIds,
  openShiftsData,
}) => {
  const openShiftRef = useRef(null);
  const [top] = useMultipleStickyElements(openShiftRef, 'scheduleTableHeader--sticky');
  const openShiftsIdsForLocationGroups = useVisibleOpenShiftsIdsForLocationGroups(openShiftsData, locationId, date);
  const relevantOpenShiftsIds = openShiftsIdsForLocationGroups.length ? openShiftsIdsForLocationGroups : openShiftIds;
  const className = cn('k-openShiftRow', 'scheduleTableHeader', 'k-openShiftRow--singleDay', {
    'k-openShiftRow--selected': rowSelected,
    'k-openShiftRow--unlocked': !isOpenShiftsRowSticky,
    'k-openShiftRow--readonly': restrictions.includes(SCHEDULE_EDIT_DISABLE),
    'scheduleTableHeader--sticky': isOpenShiftsRowSticky,
  });

  const handleAdd = async (duration, startTime, endTime) => {
    if (duration > 60) {
      const openShiftData = {
        comment: '',
        working_hours: `${startTime}-${endTime}`,
        draft: true,
        date,
        shifts_required: 1,
        job_title: {
          id: userJobTitles[0].id,
        },
        location: {
          id: locationId,
        },
      };

      const id = await createOpenShift(openShiftData);
      openOpenShiftModal(
        {
          id,
          ...openShiftData,
        },
        date,
        locationId,
      );
    } else {
      openOpenShiftModal({}, date, locationId);
    }
  };

  return (
    <tr
      ref={openShiftRef}
      className={className}
      style={{
        top,
      }}
    >
      <OpenShiftRowTitle locationId={locationId} />
      <td colSpan={SINGLE_DAY_COL_SPAN}>
        <ScheduleSingleDayNewShiftLayer date={date} locationId={locationId} onAdd={handleAdd} />
        <OpenShiftDropArea date={date} locationId={locationId} />
        {(relevantOpenShiftsIds || []).map(id => (
          <OpenShiftShift shiftId={id} size="day" key={id} date={date} locationId={locationId} />
        ))}
      </td>
    </tr>
  );
};
ScheduleTableSingleDayOpenShiftRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableSingleDayOpenShiftRow.propTypes = {
  openShiftIds: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.string,
  locationId: PropTypes.string,
  isOpenShiftsRowSticky: PropTypes.bool,
  rowSelected: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  openOpenShiftModal: PropTypes.func,
  createOpenShift: PropTypes.func,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  openShiftsData: PropTypes.arrayOf(PropTypes.shape({ job_title: PropTypes.shape({ id: PropTypes.string }) })),
};
export default ScheduleTableSingleDayOpenShiftRow;
