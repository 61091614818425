import { connect } from 'react-redux';

import { clearScheduleImports, runExport } from '@/actions/exports.js';
import {
  clearImportModal,
  getVisibleEmployeesIdsForScheduleView,
  importScheduleData,
  toggleImportModalOverlay,
  uploadFileToImport,
} from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState';
import { SCHEDULE_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportScheduleModal from './ImportScheduleModal.jsx';

const mapStateToProps = ({ reducer }) => ({
  showModal: reducer.uiState.showModal === SCHEDULE_IMPORT_MODAL,
  userEmployees: reducer.userEmployees,
  mainDateStore: reducer.mainDateStore,
  locations: reducer.scheduleLocationFilter,
  importModalObject: reducer.scheduleUIState.importModal,
  pendingExports: reducer.exports.pendingExports,
  userJobTitles: reducer.userJobTitles,
  contracts: reducer.contracts,
});

const mapDispatchToProps = {
  getVisibleEmployeesIdsForScheduleView,
  clearImportModal,
  hideModal,
  runExport,
  importScheduleData,
  uploadFileToImport,
  toggleImportModalOverlay,
  clearScheduleImports,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportScheduleModal);
