import PropTypes from 'prop-types';

import { payrollLocationTableColumns } from '@/constants/tableColumns.jsx';

const PayrollLocationTableSummaryRow = (props, context) => {
  const items = [
    <td key={0} className="sum_blank" />,
    <td key={1} className="sum_blank" />,
    <td key={2} className="sum_blank" />,
    <td key={3} className="sum_blank" />,
    <td key={13} className="imptnt">
      {props.sumAbsences}
    </td>,
    <td key={4} className="imptnt">
      {props.sumHours}
    </td>,
    <td key={5} className="imptnt">
      {`${props.sumHoursReal} / ${props.sumHoursPlanned}`}
    </td>,
    <td key={6} className="imptnt">
      {props.sumPlannedDiff}
    </td>,
    <td key={7} className="imptnt">
      {props.sumNightHours}
    </td>,
    <td key={8} className="imptnt">
      {props.sumOvertime50}
    </td>,
    <td key={9} className="imptnt">
      {props.sumOvertime100}
    </td>,
    <td key={10} className="imptnt">
      {props.sumedScheduleCycleOvertime}
    </td>,
    <td key={11} className="imptnt">
      {props.sumBonuses}
    </td>,
    <td key={12} className="imptnt">
      {props.sumPayout}
    </td>,
  ];

  return (
    <tr>
      <td colSpan={props.visibleColumns.length} style={{ padding: 0 }}>
        <table className="table">
          <colgroup>
            {payrollLocationTableColumns.colgroup.map((width, i) => {
              if (props.visibleColumns.includes(i)) return <col key={i} style={{ width: `${width}%` }} />;
              return null;
            })}
          </colgroup>
          <thead>
            <tr key="summaryRow" className="summaryrow">
              {payrollLocationTableColumns.summary.map((header, i) => {
                if (props.visibleColumns.includes(i)) {
                  if (!header) return <th key={i} className="sum_blank" />;
                  return <th key={i}>{context.intl.formatMessage(header, {})}</th>;
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            <tr key="summaryRowBottom">
              {items.map((item, i) => {
                if (props.visibleColumns.includes(i)) return item;
                return null;
              })}
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

PayrollLocationTableSummaryRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollLocationTableSummaryRow.propTypes = {
  sumHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumHoursReal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumHoursPlanned: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sumPlannedDiff: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  sumBonuses: PropTypes.number,
  sumPayout: PropTypes.number,
  sumNightHours: PropTypes.string,
  sumOvertime100: PropTypes.string,
  sumOvertime50: PropTypes.string,
  sumAbsences: PropTypes.string,
  sumedScheduleCycleOvertime: PropTypes.string,
  visibleColumns: PropTypes.arrayOf(PropTypes.number),
};

export default PayrollLocationTableSummaryRow;
