import { connect } from 'react-redux';

import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { openAddAvailabilityModal, openEditAvailabilityModal } from '@/actions/uiState.js';
import AvailabilityTableView from '@/components/availability/AvailabilityTableView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  mainDateStore: state.reducer.mainDateStore,
  userCustomTypes: state.reducer.userCustomTypes,
  currentUser: state.reducer.currentUser,
  currentCompany: state.reducer.currentCompany,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  openAddAvailabilityModal,
  openEditAvailabilityModal,
};

const AvailabilityTableViewContainer = connect(mapStateToProps, mapDispatchToProps)(AvailabilityTableView);

export default AvailabilityTableViewContainer;
