import { defineMessages } from 'react-intl';

export const violationMessages = defineMessages({
  OVERTIME: {
    id: 'workingRules.violations.overtime',
    defaultMessage: 'Zaplanowano zmiany przekraczające dobowy wymiar czasu pracy pracownika, który wynosi {time}.',
  },
  DAILY_REST: {
    id: 'workingRules.violations.dailyRest',
    defaultMessage:
      'Grafik pracy nie uwzględnia co najmniej 11 godzin nieprzerwanego odpoczynku w ciągu 24 godzinnej doby pracowniczej. Upewnij się, aby przerwa pomiędzy dwoma zmianami wynosiła minimum 11 godzin.',
  },
  COMPENSATION_REST: {
    id: 'workingRules.violations.compensationRest',
    defaultMessage:
      'Grafik pracy nie uwzględnia co najmniej 11 godzin nieprzerwanego odpoczynku w ciągu 24 godzinnej doby pracowniczej. Upewnij się, aby przerwa pomiędzy dwoma zmianami wynosiła minimum 11 godzin.',
  },
  WEEKLY_REST: {
    id: 'workingRules.violations.weeklyRest',
    defaultMessage:
      'Grafik pracy pracownika nie uwzględnia 35 godzinnego nieprzerwanego odpoczynku w pełnym tygodniu rozliczeniowym. Sprawdź początek i długość okresu rozliczeniowego dla tego pracownika.',
  },
  FREE_SUNDAY: {
    id: 'workingRules.violations.freeSunday',
    defaultMessage:
      'Grafik pracy nie zapewnia pracownikowi co 4-tej wolnej niedzieli. Zaplanuj grafik, w którym pracownik będzie miał zaplanowane maksymalnie 3 niedziele pod rząd.',
  },
  STANDARD_WORKING_TIME: {
    id: 'workingRules.violations.standardWorkingTime',
    defaultMessage: 'Zaplanowany czas pracy przekracza miesięczną normę czasu pracy pracownika, która wynosi {time}.',
  },
  LABOR_DAY: {
    id: 'workingRules.violations.laborDay',
    defaultMessage:
      'Zaplanowano wielokrotne rozpoczęcie pracy w tej samej dobie pracowniczej. Upewnij się, aby pracownik w trakcie tej doby miał zaplanowaną jedną zmianę.',
  },
  STANDARD_WORKING_TIME_DAYS: {
    id: 'workingRules.violations.standardWorkingTimeDays',
    defaultMessage:
      'Liczba zaplanowanych dni pracy przekracza maksymalną miesięczną liczbę dni roboczych, która wynosi {numberOfWorkingDays}.',
  },
  NIGHT_SHIFT: {
    id: 'workingRules.violations.nightShift',
    defaultMessage: 'Zaplanowano czas pracy w godzinach nocnych',
  },
});

export const workingRulesNotBlockingSchedule = ['STANDARD_WORKING_TIME'];
export const workingRulesForEmployeesWithBillingPeriodGreaterThanOneMonth = [
  'LABOR_DAY',
  'OVERTIME',
  'DAILY_REST',
  'COMPENSATION_REST',
  'WEEKLY_REST',
  'FREE_SUNDAY',
  'NIGHT_SHIFT',
];
