import PropTypes from 'prop-types';

import messages from '../messages';

const ScheduleTableWeatherRowTitle = (_, { intl }) => (
  <th className="k-weatherRow__title">{intl.formatMessage(messages.scheduleTableWeatherTitle)}</th>
);

ScheduleTableWeatherRowTitle.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableWeatherRowTitle.propTypes = {};

export default ScheduleTableWeatherRowTitle;
