import { Action, Dispatch } from '@reduxjs/toolkit';

import { changeDefaultViewDateStore } from '@/actions/uiState/uiState.actions';
import { pagesWithCalendar } from '@/constants/viewDateModes';

import { actionsChangingMainDateStore } from './mainDateStoreUpdates.constant';

export const mainDateStoreUpdates = (store) => (next: Dispatch) => (action: Action) => {
  const nextResult = next(action);
  const { pathname } = window.location;

  if (actionsChangingMainDateStore.includes(action.type) && pagesWithCalendar.includes(pathname)) {
    const { mainDateStore } = store.getState().reducer;
    store.dispatch(changeDefaultViewDateStore(pathname, mainDateStore));
  }

  return nextResult;
};
