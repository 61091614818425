import { CLOSE_NOTIFICATION_BAR,SHOW_NOTIFICATION_BAR } from '@/constants/ActionTypes.js';

const initialState = {
  show: false,
  content: null,
  canClose: true,
};
const notificationBar = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION_BAR:
      return Object.assign({}, state, {
        show: true,
        content: action.payload,
        canClose: action.canClose,
      });
    case CLOSE_NOTIFICATION_BAR:
      return initialState;
    default:
      return state;
  }
};
export default notificationBar;
