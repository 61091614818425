import moment from 'moment';

import { getNewDataForDate } from '@/actions';
import { getBudgetMetrics } from '@/actions/budgetMetrics';
import { mainDateChangeCustom, mainDateChangeMode, setMainDateStore } from '@/actions/mainDate.js';
import { changeDefaultViewDateStore } from '@/actions/uiState/uiState.actions';
 import { commonDatePages, pagesWithCalendar, viewDateModes } from '@/constants/viewDateModes';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';
import { doCustomFiltersExist } from '@/utils/settingsSaverHelpers';

/**
 * Middleware to update data on location change
 */
const backendDataForInitViewUpdateMiddleware = store => next => action => {
  const oldLocation = store.getState().routing.locationBeforeTransitions;
  if (!['LOCATION_CHANGE', 'GET_ROUTE_INIT_DATA'].includes(action.type)) return next(action);
  let { mainDateStore } = store.getState().reducer;
  const state = store.getState();
  const { userPermissions, uiState, scheduleUIState, absences, absenceFilter } = state.reducer;
  const { locationBeforeTransitions } = state.routing;
  let from = '';
  let to = '';
  const pathname = action.payload?.pathname ? action.payload.pathname : locationBeforeTransitions?.pathname || '/';

  if (oldLocation) {
    const oldPath = oldLocation?.pathname;
    let defaultDateStore = uiState.defaultDateStores[pathname];
    if (
      (!defaultDateStore || defaultDateStore.dateText !== mainDateStore.dateText) &&
      scheduleUIState.selectedDisplayMode.type !== 'templates' &&
      pagesWithCalendar.includes(oldPath)
    ) {
      store.dispatch(changeDefaultViewDateStore(oldPath, mainDateStore));
    }
    defaultDateStore = uiState.defaultDateStores[pathname];

    const isChangeFromScheduleTemplates =
      oldPath === '/schedule' && scheduleUIState.selectedDisplayMode.type === 'templates';

    const isChangeBetweenCommonDatePages =
      commonDatePages.includes(oldPath) &&
      commonDatePages.includes(action.payload?.pathname) &&
      scheduleUIState.selectedDisplayMode.type !== 'templates';

    const isDateChanged = defaultDateStore?.dateText && defaultDateStore.dateText !== mainDateStore.dateText;
    const isTemplatesPage =
      scheduleUIState.selectedDisplayMode.type === 'templates' && action.payload?.pathname === '/schedule';

    if (isTemplatesPage) {
      store.dispatch(mainDateChangeMode('week'));
    } else if (
      oldPath !== pathname &&
      isDateChanged &&
      (!isChangeBetweenCommonDatePages || isChangeFromScheduleTemplates)
    ) {
      store.dispatch(setMainDateStore(defaultDateStore));
    } else if (mainDateStore.dateMode === 'custom') {
      const maxDayDiff = viewDateModes[action.payload?.pathname]?.maxDayDiff;
      if (maxDayDiff < mainDateStore.dateArray.length) {
        const start = convertDateToStandardFormat(moment(mainDateStore.dateArray[0]).startOf('month'));
        const end = convertDateToStandardFormat(moment(mainDateStore.dateArray[0]).endOf('month'));
        store.dispatch(mainDateChangeCustom({ start, end }));
      }
    }
  }

  const { reducer } = store.getState();

  ({ mainDateStore } = reducer);

  if (pathname === '/settings/budget-metrics') {
    store.dispatch(getBudgetMetrics());
  }

  switch (`/${pathname.split('/')[1]}`) {
    case '/': {
      if (userPermissions.isEmployee) {
        from = mainDateStore.customDate.start;
        to = mainDateStore.customDate.end;
      } else {
        from = mainDateStore.today;
        to = mainDateStore.today;
      }
      break;
    }
    case '/attendance':
      if (doCustomFiltersExist()) break;

      store.dispatch(mainDateChangeMode('day'));

      from = mainDateStore.customDate.start;
      to = from;

      break;
    case '/production_quotas':
      from = mainDateStore.today;
      to = mainDateStore.today;
      break;
    case '/employee-payroll':
      store.dispatch(mainDateChangeMode('month'));
      mainDateStore = store.getState().reducer.mainDateStore;
      from = mainDateStore.customDate.start;
      to = mainDateStore.customDate.end;
      break;
    case '/schedule':
    case '/availabilities':
    case '/payroll':
    case '/payroll_location':
    case '/exports':
    case '/reports':
    case '/timesheet':
      from = mainDateStore.customDate.start;
      to = mainDateStore.customDate.end;
      break;
    case '/absences':
      if (absences.selectedDisplayMode.type === 'limits') {
        const newPeriod = absenceFilter.selectedAbsence.limit_period;
        from = moment()
          .startOf(newPeriod)
          .format('YYYY-MM-DD');
        to = moment()
          .endOf(newPeriod)
          .format('YYYY-MM-DD');
        store.dispatch(mainDateChangeMode(newPeriod));
      } else {
        from = mainDateStore.customDate.start;
        to = mainDateStore.customDate.end;
      }

      break;
    default:
      break;
  }
  store.dispatch(getNewDataForDate(from, to, pathname, action.payload.requestType));
  return next(action);
};

export default backendDataForInitViewUpdateMiddleware;
