import { connect } from 'react-redux';

import { quickPlanningClearSelection,quickPlanningCopy, quickPlanningPaste } from '@/actions/schedule/quickPlanning';
import { deleteFromSelectedCells } from '@/actions/schedule/selectedCells';

import QuickPlanningButtons from './QuickPlanningButtons';

const mapStateToProps = state => ({
  canCopy: state.reducer.schedule.buttonsState.canCopy,
  canPaste: state.reducer.schedule.buttonsState.canPaste,
  canDeselect: state.reducer.schedule.buttonsState.canDeselect,
  canDelete: state.reducer.schedule.buttonsState.canDelete,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

const mapDispatchToProps = {
  quickPlanningCopy,
  quickPlanningPaste,
  quickPlanningClearSelection,
  deleteFromSelectedCells,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickPlanningButtons);
