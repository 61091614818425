import { defineMessages } from 'react-intl';

import {
  CREATE_LABEL,
  CREATE_LABEL_ERROR,
  DELETE_LABEL,
  DELETE_LABELS_ERROR,
  GET_LABELS,
  GET_LABELS_ERROR,
  UPDATE_LABEL,
  UPDATE_LABEL_ERROR,
} from '@/constants/ActionTypes';

import { closeAddAttendanceModal } from './attendances';
import { conn, showConfirmModal } from './index';
import { replaceModal, replaceOnAddAttendanceModal } from './uiState';

const messages = defineMessages({
  getLabelsError: {
    id: 'labels.getLabelsError',
    defaultMessage: 'Nie udało się pobrać etykiet',
  },
  createLabelsError: {
    id: 'labels.createLabelsError',
    defaultMessage: 'Nie udało się stworzyć etykiety',
  },
  updateLabelsError: {
    id: 'labels.updateLabelsError',
    defaultMessage: 'Edycja etykiety nie powiodła się',
  },
  deleteLabelsError: {
    id: 'labels.deleteLabelsError',
    defaultMessage: 'Nie udało sie usunąć etykiety',
  },
  confirmModalTitle: {
    id: 'labels.confirmModalTitle',
    defaultMessage: 'Usuń etykietę',
  },
  confirmModalDescription: {
    id: 'labels.confirmModalDescription',
    defaultMessage:
      'Usunięcie etykiety spowoduje jednocześnie usunięcie jej relacji względem obecności do jakich została przypisana!',
  },
  confirmModalDeleteThing: {
    id: 'labels.confirmModalDeleteThing',
    defaultMessage:
      'Usunięcie etykiety spowoduje jednocześnie usunięcie jej relacji względem obecności do jakich została przypisana!',
  },
  confirmModalDeletedThing: {
    id: 'labels.confirmModalDeletedThing',
    defaultMessage: 'wybraną etykietę',
  },
});

export const getLabelsError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: GET_LABELS_ERROR,
    notification: {
      title: intl.formatMessage(messages.getLabelsError),
      type: 'error',
    },
  });
};

export const createLabelError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: CREATE_LABEL_ERROR,
    notification: {
      title: intl.formatMessage(messages.createLabelsError),
      type: 'error',
    },
  });
};

export const updateLabelError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: UPDATE_LABEL_ERROR,
    notification: {
      title: intl.formatMessage(messages.updateLabelsError),
      type: 'error',
    },
  });
};

export const deleteLabelsError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: DELETE_LABELS_ERROR,
    notification: {
      title: intl.formatMessage(messages.deleteLabelsError),
      type: 'error',
    },
  });
};

export const getLabels = () => async dispatch => {
  try {
    const { data } = await conn.getLabels();

    dispatch({
      type: GET_LABELS,
      payload: data.labels,
    });
  } catch (e) {
    dispatch(getLabelsError());
    console.error(e);
  }
};

export const createLabel = (label: { label: string; color: string }) => async dispatch => {
  try {
    const { data } = await conn.createLabel(label);

    dispatch({
      type: CREATE_LABEL,
      payload: data.addedLabel,
    });
  } catch (e) {
    dispatch(createLabelError());
    console.error(e);
  }
};

export const updateLabel = (label: { id: string; label: string; color: string }) => async dispatch => {
  try {
    const { data } = await conn.updateLabel(label);

    dispatch({
      type: UPDATE_LABEL,
      payload: data.updatedLabel,
    });
  } catch (e) {
    dispatch(updateLabelError());
    console.error(e);
  }
};

export const deleteLabels = (labelIds: string[]) => async dispatch => {
  try {
    const { data } = await conn.deleteLabels(labelIds);

    dispatch({
      type: DELETE_LABEL,
      payload: data.deletedIds,
    });
  } catch (e) {
    dispatch(deleteLabelsError());
    console.error(e);
  }
};

export const showModalToDeleteLabel = (labelIds: string[]) => async (dispatch, getState, intl) => {
  const { showModal, showAddAttendanceModal, addAttendanceModalObject } = getState().reducer.uiState;
  if (showAddAttendanceModal) dispatch(closeAddAttendanceModal());

  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.confirmModalTitle),
      description: intl.formatMessage(messages.confirmModalDescription),
      deletedThing: intl.formatMessage(messages.confirmModalDeletedThing),
      showDeleteInput: true,
      skipHide: true,
      preventClose: true,
      confirmFunc: () => {
        dispatch(deleteLabels(labelIds));
        if (showAddAttendanceModal) {
          dispatch(replaceOnAddAttendanceModal(addAttendanceModalObject));
        } else dispatch(replaceModal(showModal));
      },
      cancelFunc: () => {
        if (showAddAttendanceModal) {
          dispatch(replaceOnAddAttendanceModal(addAttendanceModalObject));
        } else dispatch(replaceModal(showModal));
      },
    }),
  );
};
