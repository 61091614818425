import { connect } from 'react-redux';

import AddShiftTab from './AddShiftTab';

const mapStateToProps = (state, ownProps) => ({
  userJobTitles: state.reducer.userJobTitles,
  userShiftblocks: state.reducer.userShiftblocks,
  employeeContracts: state.reducer.contracts[ownProps.employeeId],
});

export default connect(mapStateToProps)(AddShiftTab);
