import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import EmployeeTimesheetTable from './EmployeeTimesheetTable';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  userId: state.reducer.currentUser.user.id,
  mainDateStore: state.reducer.mainDateStore,
  userLocations: state.reducer.userLocations,
  scheduleAbsences: state.reducer.absences.scheduleAbsences,
  absencesTypes: state.reducer.absences.absencesTypes,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimesheetTable);
