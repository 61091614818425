import { FreeDaysMarkingStoreState } from '@/redux-store/freeDaysMarking';
import { ScheduleStoreState } from '@/redux-store/schedule';
import { ButtonsState } from '@/redux-store/schedule/buttonsState';
import { QuickPlanningStoreState } from '@/redux-store/schedule/quickPlanning';

export const getNewButtonsStatusFreeDaysForQP = (
  freeDaysMarking: FreeDaysMarkingStoreState,
  selected: QuickPlanningStoreState['selected'],
  borderCell: QuickPlanningStoreState['position']['borderCell'],
): ScheduleStoreState['buttonsState'] => {
  const isAnyFreeDayForPosition = !!freeDaysMarking[borderCell.employeeId]?.[borderCell.date];
  const isAnyFreeDaySelected = checkIsAnyFreeDayInSelectedForQuickPlanning(selected, freeDaysMarking);

  const buttonsState: ButtonsState = {
    canDelete: isAnyFreeDayForPosition || isAnyFreeDaySelected,
    canDeselect: false,
    canCopy: isAnyFreeDayForPosition || isAnyFreeDaySelected,
    canPaste: false,
  };
  return buttonsState;
};

const checkIsAnyFreeDayInSelectedForQuickPlanning = (
  selected: QuickPlanningStoreState['selected'],
  freeDaysMarking: FreeDaysMarkingStoreState,
) =>
  Object.entries(selected).some(([employeeId, values]) =>
    Object.keys(values).some(date => !!freeDaysMarking[employeeId]?.[date]),
  );
