import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './ScheduleTableRowTitleDropArea.scss';

class ScheduleTableRowTitleDropArea extends Component {
  constructor(props) {
    super(props);
    this.state = { indicator: null };

    bindClassFunctions(this);

    this.dragCounter = 0;
    this.dragInside = false;
  }

  dragEnter(e) {
    e.preventDefault();
    if (this.dragCounter === 0) {
      this.dragInside = true;
    }
    this.dragCounter++;
  }
  dragLeave() {
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.dragInside = false;
      this.setState({ indicator: null });
    }
  }
  drop(e) {
    if (this.state.indicator) this.setState({ indicator: null });
    this.dragCounter = 0;
    if (!e.dataTransfer.types.includes(this.props.locationId)) return;

    const { locationId, employeeId } = this.props;
    const employeeToMoveId = JSON.parse(e.dataTransfer.getData('employeeId'));
    const position = this.state.indicator === 'bottom' ? 'after' : 'before';
    this.props.changeCustomSorting(locationId, { refEmpId: employeeId, position, empId: employeeToMoveId });
  }
  mouseMove(e) {
    e.preventDefault();
    if (this.dragInside) {
      const rect = this.container.getBoundingClientRect();
      if (e.clientY - rect.top > rect.height / 2) {
        this.setState({ indicator: 'bottom' });
      } else {
        this.setState({ indicator: 'top' });
      }
    }
  }
  render() {
    const { indicator } = this.state;

    return (
      <div
        className="k-scheduleTableRowTitleDropArea"
        onDragEnter={this.dragEnter}
        onDragLeave={this.dragLeave}
        onDragOver={this.mouseMove}
        onDrop={this.drop}
        ref={ref => {
          this.container = ref;
        }}
      >
        {indicator && (
          <div
            className={`
              k-scheduleTableRowTitleDropArea__indicator
              k-scheduleTableRowTitleDropArea__indicator--${indicator}`
            }
          />
        )}
      </div>
    );
  }
}

ScheduleTableRowTitleDropArea.propTypes = {
  locationId: PropTypes.string,
  employeeId: PropTypes.string,
  changeCustomSorting: PropTypes.func,
};

export default ScheduleTableRowTitleDropArea;
