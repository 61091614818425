import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import AddAbsenceTab from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddAbsenceTab/AddAbsenceTab.redux.js';
import { useInputChange } from '@/hooks';
import {
  absenceTypeIds,
  checkIfAbsencesOverlap,
  createAbsenceObject,
  isOvertimeCollectionsDurationIncorrect,
} from '@/utils/absenceHelpers.js';
import { calculateDurationMinutes, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { createEvent } from '@/utils/inputHelpers';
import { getSumOfOvertimeAcceptancesInMinutes } from '@/utils/overtimeCollectionsHelpers';
import { createOvertimeCollectionObject } from '@/utils/overtimeHelpers.js';

import {
  getErrorMessage,
  getInitialState,
  getInputsToValidate,
  getLocationsDisabledIds,
  handleRequestError,
  modalModifiers,
} from './AbsenceAddModal.helpers.js';
import { absenceErrorMessages, messages } from './AbsenceAddModal.messages';

import './AbsenceAddModal.scss';

const AbsenceAddModal = (props, context) => {
  const [state, handlers] = useInputChange(getInitialState(props), context.intl);
  const [loading, setLoading] = useState(false);
  const {
    hideModal,
    showModal,
    userEmployees,
    locationSettings,
    absences,
    permissions,
    addAbsence,
    addOvertimeCollection,
  } = props;

  const {
    selectedAbsence,
    selectedEmployeeId,
    selectedAbsenceRange,
    multiplier,
    amount50,
    amount100,
    amountPotential,
    availableOvertimes,
    absenceHours,
    errors,
    absenceComment,
    selectedDay,
    overtimeCycle,
  } = state;

  const hideAndClear = useCallback(() => {
    handlers.setInitState();
    hideModal();
  }, []);

  const validateAll = async absence => {
    const relevantEmployeeId = selectedEmployeeId || props.modalObject?.employee?.id;
    const inputs = getInputsToValidate(state.selectedAbsence, state.allDay, props.modalObject?.showEmployeeSelect);
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    if (!relevantEmployeeId) return;
    const employeeLocations = userEmployees.find(({ id }) => id === relevantEmployeeId).locations;
    const employeeLocationsIds = employeeLocations.map(({ id }) => id);

    const locationsDisabledIds = getLocationsDisabledIds(
      locationSettings,
      selectedAbsenceRange.start,
      employeeLocationsIds,
    );

    const isLocationDisabledForEmployee = employeeLocationsIds.some(locationId =>
      locationsDisabledIds.includes(locationId),
    );

    if (isLocationDisabledForEmployee) {
      const numberOfLocations = locationsDisabledIds.length;
      const isSelectedSingleDay = selectedAbsenceRange.start === selectedAbsenceRange.end;

      if (numberOfLocations === 1) {
        const locationName = employeeLocations.find(({ id }) => id === locationsDisabledIds[0]).name;

        handlers.setError('scheduleBlocked', absenceErrorMessages.scheduleBlocked, {
          singleDay: isSelectedSingleDay,
          numberOfLocations,
          locationName,
        });
        validationResult.push(absenceErrorMessages.scheduleBlocked);
      } else {
        handlers.setError('scheduleBlocked', absenceErrorMessages.scheduleBlocked, {
          singleDay: isSelectedSingleDay,
          numberOfLocations,
        });
        validationResult.push(absenceErrorMessages.scheduleBlocked);
      }
    }
    if (
      absence.type_id === absenceTypeIds.overtimeCollection &&
      isOvertimeCollectionsDurationIncorrect(
        multiplier,
        amount50,
        amount100,
        amountPotential,
        availableOvertimes,
        absenceHours,
      )
    ) {
      const sumOfOvertimeAcceptances = getSumOfOvertimeAcceptancesInMinutes(amount50, amount100, amountPotential);
      const overtimeUsageInMinutes = Math.floor(calculateDurationMinutes(absenceHours) / Number(multiplier));
      const isOvertimeUsageExceeded = overtimeUsageInMinutes < sumOfOvertimeAcceptances;
      handlers.setError(
        'overtimeCollectionsExceed',
        isOvertimeUsageExceeded
          ? absenceErrorMessages.overtimeCollectionsExceed
          : absenceErrorMessages.overtimeCollectionsWrong,
        { difference: parseMinutesToHumanForm(sumOfOvertimeAcceptances - overtimeUsageInMinutes) },
      );
      validationResult.push(
        isOvertimeUsageExceeded
          ? absenceErrorMessages.overtimeCollectionsExceed
          : absenceErrorMessages.overtimeCollectionsWrong,
      );
    }

    if (
      absence.type_id !== absenceTypeIds.overtimeCollection &&
      checkIfAbsencesOverlap(absences, absence, permissions)
    ) {
      handlers.setError('absencesOverlap', absenceErrorMessages.absencesOverlap);
      validationResult.push(absenceErrorMessages.absencesOverlap);
    }
    return !Object.values(validationResult).some(err => err !== '');
  };

  const handleSubmit = useCallback(async () => {
    const absence = createAbsenceObject(props.modalObject?.employee?.id || state.selectedEmployeeId, state);
    const valid = await validateAll(absence);
    if (!valid) return;
    if (selectedAbsence.overtimeCollection) return saveOvertimeCollection();
    setLoading(true);
    try {
      await addAbsence(absence);
      hideAndClear();
    } catch (err) {
      const requestError = handleRequestError(err);
      handlers.setError('requestError', requestError);
    } finally {
      setLoading(false);
    }
  }, [state, props.modalObject, selectedAbsence, addAbsence]);

  const saveOvertimeCollection = () => {
    const relevantEmployeeId = props.modalObject?.employee?.id || state.selectedEmployeeId;
    const employee = userEmployees.find(e => e.id === relevantEmployeeId);

    const overtimeCollection = createOvertimeCollectionObject(
      { employee },
      absenceHours,
      absenceComment,
      selectedDay,
      amount50,
      amount100,
      amountPotential,
      multiplier,
      overtimeCycle,
    );
    addOvertimeCollection(overtimeCollection);
    hideAndClear();
  };

  useEffect(() => {
    handlers.setInitState();
  }, [showModal]);

  return (
    <MDKadroModal
      show={showModal}
      onHide={hideAndClear}
      modifiers={modalModifiers}
      title={context.intl.formatMessage(messages.absenceAddModalHeader, {})}
      onSubmit={handleSubmit}
      errorMessage={getErrorMessage(errors)}
      disableConfirm={loading}
      confirmText={context.intl.formatMessage(messages.add)}
    >
      <AddAbsenceTab
        absenceOmitWeekends={state.absenceOmitWeekends}
        absenceOmitHolidays={state.absenceOmitHolidays}
        countOnlyDaysWithShifts={state.countOnlyDaysWithShifts}
        allDay={state.allDay}
        absenceComment={state.absenceComment}
        absenceHours={state.absenceHours}
        selectedAbsence={state.selectedAbsence}
        selectedAbsenceRange={state.selectedAbsenceRange}
        selectedDay={state.selectedDay}
        selectedEmployeeId={props.modalObject?.employee?.id || state.selectedEmployeeId}
        employeeName={props.modalObject?.employee?.name}
        showEmployeeSelect={props.modalObject?.showEmployeeSelect}
        handleInputChange={handlers.changeInput}
        errors={errors}
        overtimeCycle={overtimeCycle}
        multiplier={multiplier}
        amount50={amount50}
        amount100={amount100}
        amountPotential={amountPotential}
        availableOvertimes={availableOvertimes}
      />
    </MDKadroModal>
  );
};

AbsenceAddModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceAddModal.propTypes = {
  absences: PropTypes.arrayOf(PropTypes.shape({})),
  addAbsence: PropTypes.func,
  showModal: PropTypes.bool,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      employment_conditions: PropTypes.shape({ show_absences: PropTypes.bool }),
    }),
  ),
  hideModal: PropTypes.func,
  addOvertimeCollection: PropTypes.func,
  modalObject: PropTypes.shape({
    showEmployeeSelect: PropTypes.bool,
    employee: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  locationSettings: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      disable_location_attendances_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      disable_location_schedule_shifts_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
  }),
};

export default AbsenceAddModal;
