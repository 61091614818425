import { connect } from 'react-redux';

import { enableLoadButton } from '@/actions/payroll';

import PayrollTopBar from './PayrollTopBar';

const mapDispatchToProps = {
  enableLoadButton,
};

export default connect(null, mapDispatchToProps)(PayrollTopBar);
