import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  photoPreviewModalTitle: {
    id: 'photoPreviewModal.title',
    defaultMessage: 'Zdjęcia rozpoczęcia i zakończenia obecności',
  },
  photoPreviewModalIn: {
    id: 'photoPreviewModal.in',
    defaultMessage: 'Wejście',
  },
  photoPreviewModalOut: {
    id: 'photoPreviewModal.out',
    defaultMessage: 'Wyjście',
  },
});
