import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const OfflineMsg = props => {
  if (!props.show) return null;
  return (
    <div className="k-blockingMsg">
      <div className="k-blockingMsg__box">
        <i className="material-icons k-blockingMsg__icon" style={{ fontSize: '140px' }}>
          wifi_off
        </i>

        <div className="k-blockingMsg__header">
          <FormattedMessage id="network.cannotConnectToSerwer" defaultMessage="Nie można połączyć się z serwerem" />
        </div>
        <div className="k-blockingMsg__subheader">
          <FormattedMessage id="network.checkYourConnection" defaultMessage="Sprawdź swoje połączenie internetowe" />
        </div>
      </div>
      <div className="k-blockingMsg__back" />
    </div>
  );
};
OfflineMsg.propTypes = {
  show: PropTypes.bool,
};
export default OfflineMsg;
