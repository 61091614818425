import { EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';

import { useScheduleQuickPlanningNewFreeDay } from './useScheduleQuickPlanningNewFreeDay';

import './ScheduleQuickPlanningNewFreeDay.scss';

type Props = {
  employeeId: EmployeeWhole['id'];
  date: StandardDate;
};

export const ScheduleQuickPlanningNewFreeDay = ({ employeeId, date }: Props) => {
  const { classes, handleChange, handleKeyPress, inputRef, isVisible, shortcut } = useScheduleQuickPlanningNewFreeDay(
    employeeId,
    date,
  );

  if (!isVisible) return null;

  return (
    <div className={classes.container}>
      <input ref={inputRef} value={shortcut} onChange={handleChange} onKeyDown={handleKeyPress} />
    </div>
  );
};
