import * as AT from '@/constants/ActionTypes.js';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.ROW_STATISTICS_CHANGED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default reducer;
