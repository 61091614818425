import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chartTitle: {
    id: 'absencesChart.chartTitle',
    defaultMessage: 'Typy absencji',
  },
  otherJobTitles: { id: 'absencesChart.otherAbsences', defaultMessage: 'Pozostałe' },
  tooltipMessage: {
    id: 'absencesChart.tooltipMessage',
    defaultMessage: 'Udział poszczególnych typów nieobecności względem sumarycznej wartości wg wybranego parametru.',
  },
});
