import EmployeePayrollTable from '@/components/employee/newPayroll/employeePayrollTable/EmployeePayrollTable.redux';
import EmployeePayrollTopBar from '@/components/employee/newPayroll/employeePayrollTopBar/EmployeePayrollTopBar';

const EmployeePayrollView = () => (
  <div className="k-employeePayrollView">
    <EmployeePayrollTopBar />
    <EmployeePayrollTable />
  </div>
);

export default EmployeePayrollView;
