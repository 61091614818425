import { useAppSelector } from '@/redux-store';
import { selectAbsencesTypes } from '@/selectors/absences.selectors';
import { selectAbsencesChartData } from '@/selectors/reports.selectors';

export const useAbsencesChart = () => {
  const data = useAppSelector(selectAbsencesChartData);
  const absencesTypes = useAppSelector(selectAbsencesTypes);

  return { data, absencesTypes };
};
