import PropTypes from 'prop-types';

import DropdownMenu from '@/components/common/DropdownMenu/DropdownMenu';
import { ATTENDANCE_MAP_MODAL, EDIT_ATTENDANCE_MODAL, PHOTO_PREVIEW_MODAL } from '@/constants/modalTypes.js';

import messages from './messages.js';

import './AttendancesTableOptions.scss';

const AttendancesTableOptions = (
  { showModal, getHistoryForAttendance, locationSettings, rowData, disabled, availablePhotos, getAttendanceImageUrls },
  context,
) => {
  const { attendanceObject, location } = rowData;
  const locationSettingsForAttendance = locationSettings[attendanceObject.location.id];
  const isEditingDisabled =
    locationSettingsForAttendance.disable_location_attendances_edit_until >= attendanceObject.date || disabled;
  const isGeolocationEnabled =
    locationSettingsForAttendance.enable_localization_check || locationSettingsForAttendance.rcp_requires_geolocation;
  const hasAttendanceCoords =
    Object.keys(attendanceObject.open_coords).length || Object.keys(attendanceObject.close_coords).length;

  const showAttendanceMapModal = () => {
    showModal(ATTENDANCE_MAP_MODAL, {
      attendance: attendanceObject,
      locationSettings: locationSettingsForAttendance,
      location,
    });
  };

  const showPhotoPreviewModal = () => {

    getAttendanceImageUrls(attendanceObject?.location.id, attendanceObject?.id);

    showModal(PHOTO_PREVIEW_MODAL, {
      attendanceId: attendanceObject.id,
      locationId: attendanceObject.location.id,
    });

  };

  const options = [
    {
      id: '0',
      icon: 'create',
      title: isEditingDisabled
        ? context.intl.formatMessage(messages.attendancesTableOptionsEditBlocked)
        : context.intl.formatMessage(messages.attendancesTableOptionsEdit),
      onClick: () =>
        showModal(EDIT_ATTENDANCE_MODAL, {
          employee: rowData.employeeObject,
          attendance: attendanceObject,
        }),
      disabled: isEditingDisabled,
      show: true,
    },

    {
      id: '1',
      icon: 'history',
      title: context.intl.formatMessage(messages.attendancesTableOptionsHistory),
      onClick: () => getHistoryForAttendance(attendanceObject),
      show: true,
    },
    {
      id: '2',
      icon: 'portrait',
      title: context.intl.formatMessage(messages.attendancesTableOptionsPhotos),
      onClick: () => showPhotoPreviewModal(),
      show: !!availablePhotos,
    },

    {
      id: '3',
      icon: 'gps_fixed',
      title: context.intl.formatMessage(messages.attendancesTableOptionsLocation),
      onClick: () => showAttendanceMapModal(),
      disabled: !hasAttendanceCoords,
      show: isGeolocationEnabled,
    },
  ];

  return (
    <div className="k-attendancesTableOptions">
      <DropdownMenu options={options} />
    </div>
  );
};

AttendancesTableOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendancesTableOptions.propTypes = {
  rowData: PropTypes.shape({
    employeeObject: PropTypes.shape({}),
    attendanceObject: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
      location: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    attendanceOutOfRange: PropTypes.bool,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  locationSettings: PropTypes.shape({}),
  showModal: PropTypes.func,
  getHistoryForAttendance: PropTypes.func,
  disabled: PropTypes.bool,
  availablePhotos: PropTypes.string,
  getAttendanceImageUrls:PropTypes.func,
};

export default AttendancesTableOptions;
