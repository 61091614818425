import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';

const messages = defineMessages({
  firstName: {
    id: 'topbar.nameFilter.firstName',
    defaultMessage: 'Imię pracownika',
  },
  lastName: {
    id: 'topbar.nameFilter.lastName',
    defaultMessage: 'Nazwisko pracownika',
  },
});

class TopBarNameFilter extends Component {
  constructor(props, context) {
    super(props, context);

    this.changeFunc = this.changeFunc.bind(this);
  }

  changeFunc(option) {
    this.props.changeSortingNameType(option.id > 0);
  }

  render() {
    if (this.props.listsUi.payrollLocation.sortColumn !== 'name') return null;
    const options = [
      { id: 0, displayText: this.context.intl.formatMessage(messages.firstName, {}) },
      { id: 1, displayText: this.context.intl.formatMessage(messages.lastName, {}) },
    ];
    const selectedObject = this.props.uiState.sortingUseLastName ? options[1] : options[0];
    return (
      <div className="k-topBarNameFilter">
        <TopBarSingleChoice
          objectsToChoose={options}
          selected={selectedObject}
          onChange={this.changeFunc}
          displayKey="displayText"
          className="k-topBarChoice--nameFilter"
        />
      </div>
    );
  }
}

TopBarNameFilter.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

TopBarNameFilter.propTypes = {
  uiState: PropTypes.shape({
    sortingUseLastName: PropTypes.bool,
  }),
  listsUi: PropTypes.shape({
    payrollLocation: PropTypes.shape({
      sortColumn: PropTypes.string,
    }),
  }),
  changeSortingNameType: PropTypes.func,
};

export default TopBarNameFilter;
