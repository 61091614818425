import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import Toggle from '@/components/common/inputs/Toggle.jsx';
import { createEvent } from '@/utils/inputHelpers';

import { messages } from './PaymentToggle.messages';

import './PaymentToggle.scss';

const SAVED_AMOUNT = '20%';

const PaymentToggle = ({ payment, onChange }, { intl }) => {
  const setPayment = newPayment => {
    onChange(createEvent('payment', newPayment));
  };

  const togglePayment = () => {
    const newPayment = payment === 'monthly' ? 'annually' : 'monthly';
    setPayment(newPayment);
  };

  const savedAmoundClassName = classnames('paymentToggle__saved', {
    'paymentToggle__saved--visible': payment === 'annually',
  });

  return (
    <div className="paymentToggle">
      <div className="paymentToggle__text">
        <p className={savedAmoundClassName}>
          {intl.formatMessage(messages.savedAmount)} {SAVED_AMOUNT}
        </p>
        <div onClick={() => setPayment('annually')} role="presentation">
          {intl.formatMessage(messages.annuallyPayment)}
        </div>
      </div>
      <Toggle
        labelId="paymentToggle"
        value={payment !== 'annually'}
        onChangeFunc={togglePayment}
        left={<span />}
        right={<span />}
      />
      <div className="paymentToggle__text" onClick={() => setPayment('monthly')} role="presentation">
        {intl.formatMessage(messages.monthlyPayment)}
      </div>
    </div>
  );
};

PaymentToggle.contextTypes = {
  intl: PropTypes.shape({}),
};

PaymentToggle.propTypes = {
  payment: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(PaymentToggle);
