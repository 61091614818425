import { deburr, sortBy, unionBy } from 'lodash';

import { MANAGER_VIEW_EMPLOYEES_PAYROLL } from '@/constants/Restrictions';
import { getEmployeeRank } from '@/utils/userEmployeesHelpers';

export const getRelevantEmployees = (
  currentUser,
  selectedLocationsIds,
  userEmployees,
  userPermissions,
  companyRoles,
  companySettings,
  payrollLoanedEmployees = [],
) => {
  const currentUserRank = getEmployeeRank(currentUser.user, companyRoles);
  const relevantEmployees = [...userEmployees, ...payrollLoanedEmployees];
  const filteredEmployees = relevantEmployees.filter(employee => {
    if (employee.isLoaned) return true;
    const employeeRank = getEmployeeRank(employee, companyRoles);
    const isCurrentUser = employee.id === currentUser.user.id;
    const mainLocations = employee.locations.filter(
      loc => !employee.supplementary_locations_ids.includes(parseInt(loc.id)),
    );

    if (currentUserRank < employeeRank && !isCurrentUser) {
      return false;
    }

    if (!companySettings.same_rank_is_visible && currentUserRank === employeeRank && !isCurrentUser) {
      return false;
    }

    if (userPermissions.restrictions.includes(MANAGER_VIEW_EMPLOYEES_PAYROLL) && !isCurrentUser) {
      return false;
    }

    if (!mainLocations.some(loc => selectedLocationsIds.includes(loc.id))) {
      return false;
    }

    return true;
  });

  const filteredEmployeesWithLoanedEmployees = unionBy(filteredEmployees, payrollLoanedEmployees, 'id');

  const formattedEmployees = filteredEmployeesWithLoanedEmployees.map(emp => ({
    ...emp,
    name: `${emp.first_name} ${emp.last_name}`,
    class_name: emp.inactive ? 'k-topBarChoice__element--inactive' : '',
  }));

  const sortedEmployees = sortBy(formattedEmployees, ['inactive', user => deburr(user.name.toLowerCase())]);
  return sortedEmployees;
};
