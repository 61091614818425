import { IntlShape } from 'react-intl';

import * as AT from '@/constants/ActionTypes';
import { FREE_DAYS_MARKING_ENABLE } from '@/constants/Permissions';
import { AppDispatch, GetStateType } from '@/redux-store';
import {
  addOrEditFreeDays,
  FreeDayItem,
  FreeDayItemPostDto,
  FreeDaysMarkingStoreState,
} from '@/redux-store/freeDaysMarking';
import { QuickPlanningStoreState, ReasonsForNotAddFreeDayInQPEnum } from '@/redux-store/schedule/quickPlanning';
import { EmployeesData, EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { MainDateStore } from '@/types/mainDateStore';
import { checkIsEmployeeHired } from '@/utils/employee';
import { findFreeDayMarkingIdByShortcut } from '@/utils/freeDaysMarking/freeDaysMarking';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';

import { quickPlanningClearSelection } from '../quickPlanning';
import {
  getSelectedWithBorderCell,
  getSortedVisibleEmployeeIds,
  isPositionWithinEmploymentPeriod,
} from '../quickPlanning.helpers';
import {
  checkAreAllDaysSelected,
  checkAreAllEmployeesSelected,
  checkCorrectnessOfRanges,
  getNewDate,
  getNewEmployeeId,
} from './quickPlanning.helpers';

export const getInitialFreeDaysSummary = (): QuickPlanningStoreState['freeDaysSummary'] => ({
  freeDaysAfterDateSelection: [],
  freeDaysForNotExistingEmployeeId: [],
  freeDaysToPaste: [],
  reasonsToNotAddFreeDay: [],
});

export const getFreeDaysToCopy = (
  selected: QuickPlanningStoreState['selected'],
  freeDaysMarking: FreeDaysMarkingStoreState,
): QuickPlanningStoreState['freeDaysToCopy'] =>
  Object.keys(selected).reduce((freeDaysGroupedByEmployee, employeeId) => {
    const employeeFreeDays = Object.keys(selected[employeeId]).reduce((prev, date) => {
      const freeDate = freeDaysMarking[employeeId]?.[date];
      return freeDate ? [...prev, freeDate] : prev;
    }, []);
    return employeeFreeDays.length
      ? { ...freeDaysGroupedByEmployee, [employeeId]: employeeFreeDays }
      : freeDaysGroupedByEmployee;
  }, {});

const getReasonToNotAddFreeDay = (
  employees: EmployeesData,
  employeeId: EmployeeWhole['id'],
  newDate: StandardDate,
): ReasonsForNotAddFreeDayInQPEnum | undefined => {
  if (!checkIsEmployeeHired(employees[employeeId], newDate)) return ReasonsForNotAddFreeDayInQPEnum.EMPLOYEE_NOT_HIRED;
};

const createFreeDayQP = (
  freeDay: FreeDayItem,
  freeDaysMarking: FreeDaysMarkingStoreState,
  employeeId: EmployeeWhole['id'],
  date: StandardDate,
): QuickPlanningStoreState['freeDaysSummary']['freeDaysToPaste'][number] => ({
  date,
  employee_id: employeeId,
  id: freeDaysMarking[employeeId]?.[date]?.id,
  marking_id: freeDay.markingId,
});

const getFreeDaysToPasteToMultipleCells = (
  { selected, position, freeDaysToCopy }: QuickPlanningStoreState,
  freeDaysMarking: FreeDaysMarkingStoreState,
  employees: EmployeesData,
): QuickPlanningStoreState['freeDaysSummary'] => {
  const result = getInitialFreeDaysSummary();

  const selectedCellsToPaste = { ...selected };
  if (!Object.keys(selectedCellsToPaste).includes(position.borderCell.employeeId))
    selectedCellsToPaste[position.borderCell.employeeId] = { [position.borderCell.date]: true };

  const freeDayToCopy = freeDaysToCopy[Object.keys(freeDaysToCopy)[0]]?.[0];
  if (!freeDayToCopy) return result;

  Object.entries(selectedCellsToPaste).forEach(([employeeId, dates]) => {
    Object.keys(dates).forEach(date => {
      const reason = getReasonToNotAddFreeDay(employees, employeeId, date);
      if (reason) {
        result.reasonsToNotAddFreeDay.push(reason);
      }
      result.freeDaysToPaste.push(createFreeDayQP(freeDayToCopy, freeDaysMarking, employeeId, date));
    });
  });

  return result;
};

const getFreeDaysToPasteFromOneColumnToManyDays = (
  { selected, freeDaysToCopy }: QuickPlanningStoreState,
  freeDaysMarking: FreeDaysMarkingStoreState,
  employees: EmployeesData,
): QuickPlanningStoreState['freeDaysSummary'] => {
  const result = getInitialFreeDaysSummary();

  const newDates = Object.keys(Object.values(selected)[0]);
  newDates.forEach(newDate => {
    Object.values(freeDaysToCopy).forEach(employeeFreeDays => {
      employeeFreeDays.forEach(freeDay => {
        const newEmployeeId = freeDay.employeeId;
        const reason = getReasonToNotAddFreeDay(employees, newEmployeeId, newDate);
        if (reason) {
          result.reasonsToNotAddFreeDay.push(reason);
          return;
        }
        result.freeDaysToPaste.push(createFreeDayQP(freeDay, freeDaysMarking, newEmployeeId, newDate));
      });
    });
  });

  return result;
};

const getFreeDaysToPasteFromOneRowToManyEmployees = (
  { selected, freeDaysToCopy }: QuickPlanningStoreState,
  freeDaysMarking: FreeDaysMarkingStoreState,
  employees: EmployeesData,
): QuickPlanningStoreState['freeDaysSummary'] => {
  const result = getInitialFreeDaysSummary();

  Object.keys(selected).forEach(newEmployeeId => {
    Object.values(freeDaysToCopy).forEach(employeeFreeDays => {
      employeeFreeDays.forEach(freeDay => {
        const newDate = freeDay.date;
        const reason = getReasonToNotAddFreeDay(employees, newEmployeeId, newDate);
        if (reason) {
          result.reasonsToNotAddFreeDay.push(reason);
          return;
        }
        result.freeDaysToPaste.push(createFreeDayQP(freeDay, freeDaysMarking, newEmployeeId, newDate));
      });
    });
  });

  return result;
};

const getFreeDaysToPasteFromCustomSelection = (
  { datesRanges, employeesIdsRanges, freeDaysToCopy, position }: QuickPlanningStoreState,
  freeDaysMarking: FreeDaysMarkingStoreState,
  employees: EmployeesData,
  dateArray: MainDateStore['dateArray'],
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
): QuickPlanningStoreState['freeDaysSummary'] => {
  const result = getInitialFreeDaysSummary();

  const [currentEmployeeIndex, currentDateIndex] = [position.start.y, position.start.x];

  Object.values(freeDaysToCopy).forEach(employeeFreeDays => {
    employeeFreeDays.forEach(freeDay => {
      const { date, employeeId, id } = freeDay;
      const newDate = getNewDate(dateArray, currentDateIndex, datesRanges, date);
      const newEmployeeId = getNewEmployeeId(
        sortedVisibleEmployeeIds,
        employeeId,
        currentEmployeeIndex,
        employeesIdsRanges,
      );
      const correctness = checkCorrectnessOfRanges(sortedVisibleEmployeeIds, dateArray, newDate, newEmployeeId);
      if (!correctness.isEmployeeCorrect) {
        result.freeDaysForNotExistingEmployeeId.push(id);
        return;
      }
      const reason = getReasonToNotAddFreeDay(employees, newEmployeeId, newDate);
      if (reason) {
        result.reasonsToNotAddFreeDay.push(reason);
        return;
      }
      const newFreeDay = createFreeDayQP(freeDay, freeDaysMarking, newEmployeeId, newDate);
      if (!correctness.isDateCorrect) {
        result.freeDaysAfterDateSelection.push(newFreeDay);
        return;
      }
      result.freeDaysToPaste.push(newFreeDay);
    });
  });

  return result;
};

export const getFreeDaysToPasteSummary = (
  quickPlanning: QuickPlanningStoreState,
  sortedVisibleEmployeeIds: EmployeeWhole['id'][],
  dateArray: MainDateStore['dateArray'],
  freeDaysMarking: FreeDaysMarkingStoreState,
  employees: EmployeesData,
): QuickPlanningStoreState['freeDaysSummary'] => {
  const { selected, specialSelections } = quickPlanning;

  if (specialSelections.isOnlyOneFreeDayCopied)
    return getFreeDaysToPasteToMultipleCells(quickPlanning, freeDaysMarking, employees);

  if (specialSelections.isOnlyOneColumnCopied && checkAreAllEmployeesSelected(selected, sortedVisibleEmployeeIds))
    return getFreeDaysToPasteFromOneColumnToManyDays(quickPlanning, freeDaysMarking, employees);

  if (specialSelections.isOnlyOneRowCopied && checkAreAllDaysSelected(selected, dateArray))
    return getFreeDaysToPasteFromOneRowToManyEmployees(quickPlanning, freeDaysMarking, employees);

  return getFreeDaysToPasteFromCustomSelection(
    quickPlanning,
    freeDaysMarking,
    employees,
    dateArray,
    sortedVisibleEmployeeIds,
  );
};

export const getFreeDaysIdsToDelete = (
  selected: QuickPlanningStoreState['selected'],
  borderCell: QuickPlanningStoreState['position']['borderCell'],
  freeDaysMarking: FreeDaysMarkingStoreState,
): FreeDayItem['id'][] => {
  const result: FreeDayItem['id'][] = [];

  if (isEmptyObject(selected)) {
    const freeDay = freeDaysMarking[borderCell.employeeId]?.[borderCell.date];
    if (freeDay) result.push(freeDay.id);
    return result;
  }

  Object.entries(selected).forEach(([employeeId, values]) => {
    const dates = Object.keys(values);
    dates.forEach(date => {
      const freeDay = freeDaysMarking[employeeId]?.[date];
      if (freeDay) result.push(freeDay.id);
    });
  });
  return result;
};

export const setShortcutForNewFreeDay = (shortcut: string) => ({
  type: AT.QUICK_PLANNING_SET_SHORTCUT_FOR_NEW_FREE_DAY,
  payload: shortcut,
});

export const startInsertingShortcutForNewFreeDay = (key: string) => (dispatch: AppDispatch, getState: GetStateType) => {
  const { scheduleLocationFilter, mainDateStore, scheduleState, userEmployees, schedule, userPermissions } =
    getState().reducer;
  const { enabled, workingHours, freeDayShortcut, position } = schedule.quickPlanning;

  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);

  const isEmployeeAvailableForPlanning = isPositionWithinEmploymentPeriod(
    sortedVisibleEmployeeIds,
    mainDateStore.dateArray,
    userEmployees,
    position,
  );
  if (
    !enabled ||
    workingHours.length ||
    freeDayShortcut.length ||
    !isEmployeeAvailableForPlanning ||
    !userPermissions.permissions.includes(FREE_DAYS_MARKING_ENABLE)
  )
    return;

  dispatch(setShortcutForNewFreeDay(key));
};

const getNewFreeDaysToAdd = (
  selected: QuickPlanningStoreState['selected'],
  borderCell: QuickPlanningStoreState['position']['borderCell'],
  markingId: FreeDayItem['markingId'],
  freeDaysMarking: FreeDaysMarkingStoreState,
): Array<FreeDayItemPostDto & { id?: string }> => {
  const allSelected = getSelectedWithBorderCell(selected, borderCell);
  return Object.entries(allSelected).reduce((prev, [employeeId, employeeData]) => {
    const freeDays = [];
    Object.keys(employeeData).forEach(date => {
      const newFreeDay: FreeDayItemPostDto & { id?: string } = {
        date,
        employee_id: employeeId,
        marking_id: markingId,
        id: freeDaysMarking[employeeId]?.[date]?.id,
      };
      freeDays.push(newFreeDay);
    });
    return [...prev, ...freeDays];
  }, []);
};

export const quickPlanningAddNewFreeDays =
  () =>
  (dispatch: AppDispatch, getState: GetStateType, { intl }: { intl: IntlShape }) => {
    const { freeDaysMarking, schedule } = getState().reducer;
    const { selected, position, freeDayShortcut } = schedule.quickPlanning;
    const markingId = findFreeDayMarkingIdByShortcut(freeDayShortcut, intl);
    if (!markingId) return;
    const freeDays = getNewFreeDaysToAdd(selected, position.borderCell, markingId, freeDaysMarking);
    dispatch(addOrEditFreeDays(freeDays));
    dispatch(quickPlanningClearSelection());
  };
