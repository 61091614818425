import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ShownCounter = props => (
  <div className="ile_rekordow" style={{ padding: '0' }}>
    <FormattedMessage
      id="common.shownCounter.shown"
      defaultMessage="Wyświetlono {visible} z {total}"
      values={{
        visible: props.visible,
        total: props.total,
      }}
    />
  </div>
);

ShownCounter.propTypes = {
  visible: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ShownCounter;
