import { defineMessages } from 'react-intl';

import { processLoanedEmployees } from '@/actions/loanEmployeesProposals';
import { addLoanedEmployees, getScheduleLoanedEmployees } from '@/actions/scheduleLoanedEmployees';
import {
  ASSIGN_USER_OPEN_SHIFT_SUCCESFUL,
  CHANGE_OPEN_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_ERROR,
  CREATE_OPEN_SHIFT_SUCCESFUL,
  DELETE_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFT_SUCCESFUL,
  GET_OPEN_SHIFTS_SUCCESFUL,
  MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
  PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL,
  USER_APPLY_OPEN_SHIFT_SUCCESFUL,
  USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL,
} from '@/constants/ActionTypes.js';
import { uuid4 } from '@/utils/baseHelpers.js';
import { getFromToFromDateStore } from '@/utils/dateHelper.js';

import { addInitShifts, addShiftsSuccessful, conn, connectionError, getScheduleSuccesful } from './index';

const messages = defineMessages({
  successToast: {
    id: 'toastr.successTitle',
    defaultMessage: 'Sukces!',
  },
  massDeleteOpenShiftsCorrectly: {
    id: 'success.massDeleteOpenShiftsCorrectly',
    defaultMessage: 'Poprawnie usunięto otwarte zmiany (ilość: {shiftsAmount})',
  },
  errorToast: {
    id: 'toastr.errorTitle',
    defaultMessage: 'Błąd!',
  },
  massDeleteOpenShiftsError: {
    id: 'success.massDeleteOpenShiftsError',
    defaultMessage: 'Nie udało się usunąć otwartych zmian. Spróbuj ponownie.',
  },
  createOpenShiftNotAllowedForLocation: {
    id: 'openShifts.createOpenShiftNotAllowedForLocation',
    defaultMessage: 'Dla wybranej lokalizacji nie można dodać otwartą zmianę',
  },
  createOpenShiftError: {
    id: 'openShifts.createOpenShiftError',
    defaultMessage: 'Nie udało się dodać otwartą zmianę. Spróbuj ponownie.',
  },
});

export const getOpenShiftsSuccesful = openShiftsArray => ({
  type: GET_OPEN_SHIFTS_SUCCESFUL,
  payload: openShiftsArray,
});

export const createOpenShiftSuccesful = openShift => ({
  type: CREATE_OPEN_SHIFT_SUCCESFUL,
  payload: openShift,
});

export const createOpenShiftError = err => (dispatch, getState, intl) => {
  const errorType =
    err.response.data.message === 'Location needs to have open shifts enabled'
      ? 'createOpenShiftNotAllowedForLocation'
      : 'createOpenShiftError';
  dispatch({
    type: CREATE_OPEN_SHIFT_ERROR,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages[errorType]),
    },
  });
};

export const changeOpenShiftSuccesful = openShift => ({
  type: CHANGE_OPEN_SHIFT_SUCCESFUL,
  payload: openShift,
});

export const deleteOpenShiftSuccesful = openShiftId => ({
  type: DELETE_OPEN_SHIFT_SUCCESFUL,
  payload: openShiftId,
});

export const userApplyOpenShiftSuccesful = (openShiftId, currentUser) => ({
  type: USER_APPLY_OPEN_SHIFT_SUCCESFUL,
  payload: {
    id: openShiftId,
    currentUser,
  },
});

export const userRemoveApplicationOpenShiftSuccesful = (openShiftId, employeeId) => ({
  type: USER_REMOVE_APPLICATION_OPEN_SHIFT_SUCCESFUL,
  payload: {
    id: openShiftId,
    employeeId,
  },
});

export const getOpenShiftSuccesful = openShiftIds => ({
  type: GET_OPEN_SHIFT_SUCCESFUL,
  payload: openShiftIds,
});

export const assignUsersOpenShiftSuccesful = openShift => ({
  type: ASSIGN_USER_OPEN_SHIFT_SUCCESFUL,
  payload: openShift,
});

export const publishDraftOpenShiftsSuccesful = (locationId, from, to, dateArray, selectedJobTitlesIds) => ({
  type: PUBLISH_DRAFT_OPEN_SHIFTS_SUCCESFUL,
  payload: {
    locationId,
    from,
    to,
    dateArray,
    selectedJobTitlesIds,
  },
});

export const createOpenShift = openShift => (dispatch, getState) =>
  conn
    .createOpenShift(openShift)
    .then(result => {
      const newOpenShift = result.data;
      const { userJobTitles } = getState().reducer;
      newOpenShift.job_title = userJobTitles.find(({ id }) => id === newOpenShift.job_title.id);
      dispatch(createOpenShiftSuccesful(result.data));
      return newOpenShift.id;
    })
    .catch(err => {
      dispatch(createOpenShiftError(err));
      dispatch(connectionError(err));
    });

export const changeOpenShift = (openShiftId, openShift) => (dispatch, getState) =>
  conn
    .changeOpenShift(openShiftId, openShift)
    .then(result => {
      const changedOpenShift = result.data;
      const { userJobTitles } = getState().reducer;
      changedOpenShift.job_title = userJobTitles.find(({ id }) => id === changedOpenShift.job_title.id);
      if (changedOpenShift.users.length) {
        const { employees } = getState().reducer;
        changedOpenShift.users = changedOpenShift.users.map(userId => {
          const userObject = employees.data[userId];
          if (!userObject) console.error('Missing employee object for id', userId);
          return userObject;
        });
      }
      dispatch(changeOpenShiftSuccesful(changedOpenShift));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });

export const deleteOpenShift = openShiftId => dispatch =>
  conn
    .deleteOpenShift(openShiftId)
    .then(() => {
      dispatch(deleteOpenShiftSuccesful(openShiftId));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });

export const massDeleteOpenShiftsSuccesful = openShiftIds => (dispatch, getState, intl) => {
  const shiftsAmount = openShiftIds.length;
  dispatch({
    type: MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.successToast),
      description: intl.formatMessage(messages.massDeleteOpenShiftsCorrectly, { shiftsAmount }),
    },
    payload: openShiftIds,
  });
};

export const massDeleteOpenShiftsError = () => (dispatch, getState, intl) => {
  dispatch({
    type: MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.errorToast),
      description: intl.formatMessage(messages.massDeleteOpenShiftserror),
    },
  });
};

export const massDeleteOpenShiftsByIds = openShiftsToDeleteIds => async dispatch => {
  try {
    if (openShiftsToDeleteIds.length === 0) return;
    await conn.massDeleteOpenShifts(openShiftsToDeleteIds);
    dispatch(massDeleteOpenShiftsSuccesful(openShiftsToDeleteIds));
  } catch (error) {
    dispatch(massDeleteOpenShiftsError());
  }
};

export const massDeleteOpenShifts = (locationIds, from, to) => async (dispatch, getState) => {
  const { openShifts } = getState().reducer;
  const shiftsToDelete = openShifts.filter(
    ({ date, location, draft }) => date <= to && date >= from && locationIds.includes(location.id) && draft,
  );
  const shiftsToDeleteIds = shiftsToDelete.map(shift => shift.id);

  dispatch(massDeleteOpenShiftsByIds(shiftsToDeleteIds));
};

export const userApplyOpenShift = openShiftId => (dispatch, getState) => {
  const { currentUser } = getState().reducer;
  conn
    .userApplyOpenShift(openShiftId)
    .then(() => {
      dispatch(userApplyOpenShiftSuccesful(openShiftId, currentUser));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const userRemoveApplicationOpenShift = openShiftId => (dispatch, getState) => {
  const { currentUser } = getState().reducer;
  conn
    .userRemoveApplicationOpenShift(openShiftId)
    .then(() => {
      dispatch(userRemoveApplicationOpenShiftSuccesful(openShiftId, currentUser.user.id));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const getOpenShift = openShiftId => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getOpenShift(openShiftId)
      .then(result => {
        dispatch(getOpenShiftSuccesful(result.data));
        resolve(result.data);
      })
      .catch(err => {
        dispatch(connectionError(err));
        reject(err);
      });
  });

export const assignUsersOpenShift = (openShift, employeeIds) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { userEmployees, mainDateStore } = getState().reducer;
      const shifts = employeeIds.map(employeeId => ({
        id: uuid4(),
        comment: openShift.comment,
        date: openShift.date,
        draft: false,
        employee: { id: employeeId },
        job_title: openShift.job_title,
        working_hours: openShift.working_hours,
        location: openShift.location,
      }));

      const userEmployeeIds = userEmployees.map(({ id }) => id);
      const loanedEmployeeIds = employeeIds.filter(id => !userEmployeeIds.includes(id));
      const shiftsForRegularEmployees = shifts.filter(shift => userEmployeeIds.includes(shift.employee.id));
      const uuids = shiftsForRegularEmployees.map(s => s.id);

      if (shiftsForRegularEmployees.length) dispatch(addInitShifts(shiftsForRegularEmployees));
      await conn.assignUsersOpenShift(openShift.id, employeeIds).then(response => {
        dispatch(assignUsersOpenShiftSuccesful(response.data.open_shift));
        if (shiftsForRegularEmployees.length) dispatch(processLoanedEmployees(response.data.created_shifts));
        if (uuids.length) dispatch(addShiftsSuccessful(response.data.created_shifts, uuids, true));
      });

      if (loanedEmployeeIds.length > 0) {
        const [from, to] = getFromToFromDateStore(mainDateStore);
        const scheduleForLoanedEmployees = await conn.getScheduleForMultipleLocations(
          [openShift.location.id],
          from,
          to,
          loanedEmployeeIds,
          false,
        );
        const loanedEmployees = scheduleForLoanedEmployees.data.schedule.employees;
        dispatch(getScheduleSuccesful(loanedEmployees));
        dispatch(addLoanedEmployees(loanedEmployees));
        dispatch(getScheduleLoanedEmployees(loanedEmployees));
      }

      resolve();
    } catch (error) {
      const { errors } = error.response.data.message;
      const messages = errors?.filter(err => typeof err === 'object' && err !== null);
      reject(messages);
    }
  });
