import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import { sendInputEvent, splitByIndex } from '@/utils/baseHelpers';
import { createEvent } from '@/utils/inputHelpers.js';

import './MDPhoneInput.scss';

const MDPhoneInput = ({ errorMessage, id, label, modifiers, phoneNumber, prefix, onChange, testId, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const className = classNames('kmd-textInput', ...modifiers.map(modifier => `kmd-textInput--${modifier}`), {
    'kmd-textInput--valid': !errorMessage,
    'kmd-textInput--error': errorMessage,
  });

  const handleInputChange = useCallback((value, data, e, formattedValue) => {
    const [newPrefix, newPhoneNumber] = splitByIndex(formattedValue.replace('+', ''), data.dialCode.length);

    setInputValue(`${newPrefix}${newPhoneNumber}`);

    if (onChange) {
      onChange(sendInputEvent({ name: `${id}_prefix`, value: newPrefix }));
      onChange(createEvent(id, newPhoneNumber, { valueToCompare: newPrefix }));
    }
  }, []);

  useEffect(() => {
    const prefixPlaceholder = prefix || '48';
    setInputValue(prefixPlaceholder + phoneNumber);
  }, [prefix, phoneNumber]);

  const onBlur = (e, countryData) => {
    const value = e.target.value.replace('+', '');
    const [newPrefix, newPhoneNumber] = splitByIndex(value, countryData.dialCode.length);
    props.onBlur(createEvent(id, newPhoneNumber, { valueToCompare: newPrefix }));
  };

  return (
    <div className={className}>
      <div className="kmd-textInput__inputContainer">
        <PhoneInput
          {...props}
          country="pl"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={onBlur}
          countryCodeEditable={false}
          data-test={testId}
          regions={['europe']}
        />
      </div>
      <label>{label}</label>
      <span className="kmd-textInput__bar" />
      <div className="kmd-textInput__error">{errorMessage}</div>
    </div>
  );
};

MDPhoneInput.propTypes = {
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  phoneNumber: PropTypes.string,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  testId: PropTypes.string,
};

export default MDPhoneInput;
