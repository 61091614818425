import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import MDPhoneInput from '@/components/common/inputs/MDPhoneInput/MDPhoneInput.jsx';
import MDSwitch from '@/components/common/inputs/MDSwitch/MDSwitch.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';

const messages = defineMessages({
  referenceCodeTooltip: {
    id: 'companymanage.referenceCodeTooltip',
    defaultMessage:
      'Kod referencyjny to uniwersalne pole w którym możesz wpisać dowolną wartość dla danego pracownika. Często jest wykorzystywany do mapowania użytkowników z innych systemów, np. systemy kadrowo-płacowe.',
  },
  PINTooltip: {
    id: 'companymanage.PINTooltip',
    defaultMessage:
      'PIN służy do identyfikacji danego pracownika w module rejestracji czasu pracy. Długość numeru PIN jest zawsze 4-cyfrowa.',
  },
  NFCTooltip: {
    id: 'companymanage.NFCTooltip',
    defaultMessage:
      'NFC służy do identyfikacji danego pracownika w module rejestracji czasu pracy. Długość kodu NFC jest 10-cyfrowa.',
  },
  inactiveTooltip: {
    id: 'companymanage.inactiveTooltip',
    defaultMessage:
      'Gdy pracownik zakończy współpracę z Twoją firmą, zmień jego status na nieaktywny. Przestanie figurować w zakładce moja firma na liście pracowników aktywnych, nie będzie również widoczny w grafiku pracy oraz na liście obecności. {clickHere}, aby dowiedzieć się więcej na temat tej opcji',
  },
  clickHere: {
    id: 'common.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ',
  },
});

const EmployeeModalAdditional = (props, { intl }) => {
  const {
    phone,
    phonePrefix,
    pin4,
    referenceId,
    alias,
    nfcCode,
    inactive,
    errors,
    handleInputChange,
    validateInput,
    inactiveDisabled,
    isInactiveHidden,
  } = props;

  return (
    <div className="employeeModal__additional">
      <MDPhoneInput
        phoneNumber={phone}
        prefix={phonePrefix}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        id="phone"
        label={<FormattedMessage id="companymanage.employees.phone" defaultMessage="Telefon" />}
        errorMessage={errors.phone}
        modifiers={['modal']}
        testId="phone"
      />

      <MDTextInput
        value={pin4}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        id="pin4"
        label={<FormattedMessage id="companymanage.employees.pin" defaultMessage="Pin" />}
        tooltip={intl.formatMessage(messages.PINTooltip)}
        errorMessage={errors.pin4}
        modifiers={['modal']}
        testId="pin4"
      />
      <MDTextInput
        value={referenceId}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        id="referenceId"
        label={<FormattedMessage id="companymanage.employees.referenceId" defaultMessage="Kod referencyjny" />}
        tooltip={intl.formatMessage(messages.referenceCodeTooltip)}
        errorMessage={errors.referenceId}
        modifiers={['modal']}
        testId="referenceId"
      />
      <MDTextInput
        value={alias}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        id="alias"
        label={<FormattedMessage id="companymanage.employees.alias" defaultMessage="Alias" />}
        error={errors.alias}
        modifiers={['modal']}
        testId="alias"
      />
      <MDTextInput
        value={nfcCode}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        id="nfcCode"
        label={<FormattedMessage id="companymanage.employees.nfc_code" defaultMessage="Kod NFC" />}
        tooltip={intl.formatMessage(messages.NFCTooltip)}
        errorMessage={errors.nfcCode}
        modifiers={['modal']}
        testId="nfcCode"
      />
      {!isInactiveHidden && (
        <MDSwitch
          value={!inactive}
          id="inactive"
          label={<FormattedMessage id="employee.inactive" defaultMessage="Nieaktywny" />}
          tooltip={
            <FormattedMessage
              {...messages.inactiveTooltip}
              values={{
                clickHere: (
                  <a
                    href="https://pomoc.kadromierz.pl/pl/articles/2495724-pracownik-aktywny-nieaktywny"
                    target="__blank"
                  >
                    {intl.formatMessage(messages.clickHere)}
                  </a>
                ),
              }}
            />
          }
          onChange={handleInputChange}
          disabled={inactiveDisabled}
          left="nieaktywny"
          right="aktywny"
          testId="toggleEmployeeActive"
        />
      )}
    </div>
  );
};

EmployeeModalAdditional.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeModalAdditional.propTypes = {
  phone: PropTypes.string,
  phonePrefix: PropTypes.string,
  pin4: PropTypes.string,
  referenceId: PropTypes.string,
  alias: PropTypes.string,
  nfcCode: PropTypes.string,
  inactive: PropTypes.bool,
  inactiveDisabled: PropTypes.bool,
  errors: PropTypes.shape({
    phone: PropTypes.string,
    pin4: PropTypes.string,
    referenceId: PropTypes.string,
    alias: PropTypes.string,
    nfcCode: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  validateInput: PropTypes.func,
  isInactiveHidden: PropTypes.bool,
};

export default EmployeeModalAdditional;
