import MDChip from '@/components/common/MDChip/MDChip';
import TextColumnFilter from '@/components/common/MDTable/TextColumnFilter';
import KadroSkeleton from '@/components/common/Skeleton/Skeleton';
import { filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings } from '@/components/newPayrollViews/columns.helpers';
import { messages, statusMessages } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.messages';
import { BLUE_100, BLUE_500, GREEN_100, GREEN_500, RED_100, RED_500, YELLOW_100, YELLOW_500 } from '@/constants/colors';
import { PAYROLL_APPROVAL_START_DATE } from '@/constants/payrollSettings';
import { payrollLocationTableColumnsOptions } from '@/constants/tables/payrollLocation/payrollLocationTableColumns';
import { parseMinutesToFormat } from '@/utils/dateHelper';
import { createOvertimeScheduleCycleSpanLabel } from '@/utils/overtimeHelpers.js';
import { getColumnWidth } from '@/utils/tableHelpers';

import MDAlert from '../../../common/Basic/MDAlert/MDAlert';

export const TABS = [
  {
    id: 'all',
    color: BLUE_500,
    lightColor: BLUE_100,
  },
  {
    id: 'approved',
    color: GREEN_500,
    lightColor: GREEN_100,
  },
  {
    id: 'unapproved',
    color: RED_500,
    lightColor: RED_100,
  },
  {
    id: 'pending',
    color: YELLOW_500,
    lightColor: YELLOW_100,
  },
];

export const getColumns = (allColumns, visibleColumns, isTableWithScroll, intl) =>
  visibleColumns.reduce((acc, col) => {
    if (allColumns.some(column => column.accessor === col.accessor)) {
      acc.push({
        ...col,
        Header: intl.formatMessage(col.title, {}),
        accessor: col.accessor,
        minWidth: getColumnWidth(isTableWithScroll || col.sticky, col.width),
        width: getColumnWidth(isTableWithScroll || col.sticky, col.width),
        sticky: col.sticky,
        Filter: col.Filter || TextColumnFilter,
        shadow: col.shadow,
      });
    }

    return acc;
  }, []);

const formatMinutes = (value, timeFormatType) => {
  if (value) return parseMinutesToFormat(value, timeFormatType);
  return '-';
};

const formatMinutesWithZero = (value, timeFormatType) => parseMinutesToFormat(value || 0, timeFormatType);

const formatMoney = value => (value ? `${(value / 100).toFixed(2)} PLN` : '-');

const formatMoneyWithZero = value => `${((value || 0) / 100).toFixed(2)} PLN`;

const formatFullName = (firstName, lastName, sortingUseLastName) => {
  if (sortingUseLastName) return `${lastName} ${firstName}`;
  return `${firstName} ${lastName}`;
};

const getOvertimeWeeklyColumnContent = (overtimeStatsForEmployee, timeFormatType) => {
  if (!overtimeStatsForEmployee?.overtime) return '-';
  const { from, to } = overtimeStatsForEmployee;
  const value = parseMinutesToFormat(overtimeStatsForEmployee.overtime.scheduleCycleOvertime, timeFormatType);
  const scheduleCycleSpan = createOvertimeScheduleCycleSpanLabel(from, to);
  return (
    <div>
      <div className="k-payrollLocationTable__scheduleCyclePeriod">{scheduleCycleSpan}</div>
      <div>{value}</div>
    </div>
  );
};

const getWarningsColumnContent = (rowData, intl) => {
  const sumOfWarnings = Object.values(rowData.warnings).reduce((sum, value) => sum + value, 0);

  return [
    sumOfWarnings > 0 && (
      <MDAlert
        icon="warning"
        iconType="outlined"
        label={intl.formatMessage(messages.payrollWarnings)}
        numberOfAlerts={sumOfWarnings}
      />
    ),
  ];
};

const statusColors = {
  approved: 'green',
  unapproved: 'red',
  pending: 'yellow',
};

const getStatusColumnContent = (rowData, intl) => {
  const label = intl.formatMessage(statusMessages[rowData.status]);

  return (
    <MDChip status={rowData.status} modifiers={[statusColors[rowData.status]]}>
      {label}
    </MDChip>
  );
};

const getSkeleton = () => <KadroSkeleton />;

const getSkeletonData = () => {
  const skeleton = getSkeleton();
  return {
    employee: skeleton,
    absences: skeleton,
    workTime: skeleton,
    nightWorkTime: skeleton,
    timePlanned: skeleton,
    timeDiff: skeleton,
    overtime50: skeleton,
    overtime100: skeleton,
    potentialOvertime: skeleton,
    fillUp: skeleton,
    overtimeWeekly: skeleton,
    bonus: skeleton,
    payout: skeleton,
    warnings: skeleton,
    status: skeleton,
    disabledCheckbox: true,
  };
};

export const getTableData = (
  data,
  timeFormatType,
  sortingUseLastName,
  overtimeStats,
  intl,
  dateFilter,
  isFetchingBatches,
  numberOfRelevantEmployees,
) => {
  const payrollData = data.map(rowData => ({
    ...rowData,
    employee: formatFullName(rowData.first_name, rowData.last_name, sortingUseLastName),
    absences: formatMinutes(rowData.absences_time, timeFormatType),
    workTime: formatMinutesWithZero(rowData.work_time, timeFormatType),
    nightWorkTime: formatMinutesWithZero(rowData.night_hours, timeFormatType),
    timePlanned: formatMinutesWithZero(rowData.timePlanned, timeFormatType),
    timeDiff: formatMinutes(rowData.timeDiff, timeFormatType),
    overtime50: formatMinutes(rowData.overtime50, timeFormatType),
    overtime100: formatMinutes(rowData.overtime100, timeFormatType),
    potentialOvertime: formatMinutes(rowData.potentialOvertime, timeFormatType),
    fillUp: formatMinutes(rowData.fillUp, timeFormatType),
    overtimeWeekly: getOvertimeWeeklyColumnContent(overtimeStats[rowData.employee_id], timeFormatType),
    bonus: formatMoney(rowData.bonus),
    payout: formatMoneyWithZero(rowData.payout),
    warnings: getWarningsColumnContent(rowData, intl),
    status: getStatusColumnContent(rowData, intl),
    disabledCheckbox: dateFilter.end < PAYROLL_APPROVAL_START_DATE,
  }));

  if (!isFetchingBatches) {
    return payrollData;
  }

  const MAX_NUMBER_OF_SKELETONS = 50;
  const numberOfSkeletons = Math.min(numberOfRelevantEmployees - data.length + 1, MAX_NUMBER_OF_SKELETONS);

  const skeletonRow = getSkeletonData();
  const skeletonRows = Array.from({ length: numberOfSkeletons }, () => skeletonRow);

  return [...payrollData, ...skeletonRows];
};

export const getPayrollLocationColumns = (userPermissions, payoutSettingType, companySettings) =>
  filterColumnsBasedOnPermissionsAndPayoutSettingAndCompanySettings(
    payrollLocationTableColumnsOptions,
    userPermissions,
    payoutSettingType,
    companySettings,
  );
