import { useState } from 'react';
import ReactSlider from 'react-slider';

import './MDSlider.scss';

interface Props {
  className?: string;
  minValue?: number;
  maxValue?: number;
  onChange: (val: number) => void;
  defaultValue?: number;
  formatLabel?: (val: number) => string;
}

function MDSingleSlider(props: Props) {
  const { formatLabel, minValue = 0, maxValue = 100, className = '', onChange, defaultValue = 0 } = props;

  const [value, setValue] = useState<number>(defaultValue);

  const minLabel = formatLabel ? formatLabel(minValue) : minValue;
  const maxLabel = formatLabel ? formatLabel(maxValue) : maxValue;

  return (
    <div className={`mdInputSlider ${className}`}>
      <ReactSlider
        className="mdInputSlider__slider"
        thumbClassName="mdInputSlider__thumb"
        trackClassName="mdInputSlider__track"
        max={maxValue}
        min={minValue}
        value={value}
        onChange={value => {
          setValue(value);
          onChange(value);
        }}
        renderThumb={(props, state) => (
          <div {...props}>
            <div>{formatLabel ? formatLabel(state.valueNow) : state.valueNow}</div>
            <div className="mdInputSlider__circle" />
          </div>
        )}
      />
      <div className="mdInputSlider__labels">
        <div>{minLabel}</div>
        <div>{maxLabel}</div>
      </div>
    </div>
  );
}

export default MDSingleSlider;
