import PropTypes from 'prop-types';

import TopBarCalendar from '@/components/TopBars/filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmployeeFilter from '@/components/TopBars/filters/TopBarEmployeeFilter/TopBarEmployeeFilter.redux.js';
import TopBarMultipleLocationFilter from '@/components/TopBars/filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from '@/components/TopBars/TopBar';
import { payrollModesIdsForManager } from '@/constants/viewDateModes';

import PayrollLoadDataButton from './PayrollLoadDataButton/PayrollLoadDataButton.redux';

import './PayrollTopBar.scss';

const PayrollTopBar = ({ enableLoadButton }) => (
  <TopBar
    className="k-topBar--payrollLocation"
    leftElements={[
      <TopBarCalendar key="calswitch" modes={payrollModesIdsForManager} onChangeCallback={enableLoadButton} />,
      <TopBarMultipleLocationFilter key="multipleLocationFilter" />,
      <TopBarEmployeeFilter key="payoutEmployee" />,
    ]}
    rightElements={[<PayrollLoadDataButton key="loadDataButton" />]}
  />
);

PayrollTopBar.propTypes = {
  enableLoadButton: PropTypes.func,
};

export default PayrollTopBar;
