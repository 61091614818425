import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@/components/common/Basic/Button';
import { createEvent } from '@/utils/inputHelpers.js';

const RecentAddedShift = ({ handleInputChange, usedWorkingHours, onSubmitShift, displayRepeat, workingHours }) => {
  const modifiers = classnames('high', 'teeny', workingHours === usedWorkingHours ? 'orange' : 'reverse-orange');

  const onClick = () => {
    handleInputChange(createEvent('working_hours', usedWorkingHours));
    if (!displayRepeat) setTimeout(() => onSubmitShift(), 100);
  };
  return (
    <Button className="k-recentAddedShift" onClick={onClick} modifiers={modifiers}>
      {usedWorkingHours}
    </Button>
  );
};

RecentAddedShift.propTypes = {
  handleInputChange: PropTypes.func,
  usedWorkingHours: PropTypes.string,
  onSubmitShift: PropTypes.string,
  displayRepeat: PropTypes.bool,
  workingHours: PropTypes.string,
};

export default RecentAddedShift;
