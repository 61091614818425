import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { MDTextInput } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import {
  getInitialState,
  INPUT_MODIFIERS,
  mappedDataToRequestObject,
  MODAL_MODIFIERS,
} from './EditBillingDataModal.helpers';
import { messages } from './EditBillingDataModal.messages';

import './editBillingDataModal.scss';

const EditBillingDataModal = ({ showModal, hideModal, editPaymentData, paymentsData }, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState(paymentsData), intl);
  const [errorMessage, setErrorMessage] = useState('');

  const validateAll = async () => {
    const inputs = ['city', 'address', 'email', 'companyName', 'zipCode', 'nip'];
    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const handleHide = () => {
    handlers.setInitState(getInitialState(paymentsData));
    setErrorMessage('');
    hideModal();
  };

  const handleSubmit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) {
      setErrorMessage(intl.formatMessage(messages.modalErrorMessage));
      return;
    }
    editPaymentData(mappedDataToRequestObject(state));
    handleHide();
  }, [state, paymentsData]);

  useEffect(() => {
    handlers.setInitState(getInitialState(paymentsData));
  }, [paymentsData]);

  return (
    <MDKadroModal
      title={intl.formatMessage(messages.modalTitle)}
      onSubmit={handleSubmit}
      show={showModal}
      onHide={handleHide}
      modifiers={MODAL_MODIFIERS}
      className="k-editBillingDataModal"
      errorMessage={errorMessage}
    >
      <MDTextInput
        id="email"
        value={state.email}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        label={intl.formatMessage(messages.email)}
        errorMessage={state.errors.email}
        modifiers={INPUT_MODIFIERS}
      />
      <MDTextInput
        id="companyName"
        value={state.companyName}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        label={intl.formatMessage(messages.companyName)}
        modifiers={INPUT_MODIFIERS}
        errorMessage={state.errors.companyName}
      />
      <MDTextInput
        id="nip"
        value={state.nip}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        label={intl.formatMessage(messages.nip)}
        errorMessage={state.errors.nip}
        modifiers={INPUT_MODIFIERS}
      />
      <MDTextInput
        id="address"
        value={state.address}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        label={intl.formatMessage(messages.address)}
        modifiers={INPUT_MODIFIERS}
        errorMessage={state.errors.address}
      />

      <div className="k-editBillingDataModal__twoInputsRow">
        <MDTextInput
          id="city"
          value={state.city}
          onChange={handlers.changeInput}
          onBlur={handlers.validateInput}
          label={intl.formatMessage(messages.city)}
          modifiers={INPUT_MODIFIERS}
          errorMessage={state.errors.city}
        />
        <MDTextInput
          id="zipCode"
          value={state.zipCode}
          onChange={handlers.changeInput}
          onBlur={handlers.validateInput}
          label={intl.formatMessage(messages.zipCode)}
          modifiers={INPUT_MODIFIERS}
          errorMessage={state.errors.zipCode}
        />
      </div>
    </MDKadroModal>
  );
};

EditBillingDataModal.contextTypes = {
  intl: PropTypes.shape({}),
};

EditBillingDataModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  editPaymentData: PropTypes.func,
  paymentsData: PropTypes.shape({}),
};

export default EditBillingDataModal;
