import {
  PAYROLL_LOCATION_SET_SELECTED,
  PAYROLL_LOCATION_TOGGLE_SELECTED,
  SET_BONUS_VALUES,
  SET_PAYROLL_SEARCH_STRING,
  TOGGLE_PAYROLL_SETTINGS_MODAL,
} from '@/constants/ActionTypes.js';

export const payrollLocationSetSelected = selected => ({
  type: PAYROLL_LOCATION_SET_SELECTED,
  payload: selected,
});

export const payrollLocationToggleSelected = selected => ({
  type: PAYROLL_LOCATION_TOGGLE_SELECTED,
  payload: selected,
});

export const togglePayrollSettingsModal = () => ({
  type: TOGGLE_PAYROLL_SETTINGS_MODAL,
});

export const setBonusValues = (id, value, employeeId) => ({
  type: SET_BONUS_VALUES,
  payload: {
    id,
    value,
    employeeId,
  },
});

export const setPayrollSearchString = searchString => ({
  type: SET_PAYROLL_SEARCH_STRING,
  payload: searchString,
});
