import PropTypes from 'prop-types';
import { Component } from 'react';
import Talk from 'talkjs';

import ChatTopBar from './ChatTopBar/ChatTopBar.redux';
import NewChatModal from './NewChatModal/NewChatModal.redux';

import './Chat.scss';

class Chat extends Component {
  constructor(props) {
    super(props);

    this.inbox = undefined;
  }

  componentDidMount() {
    Talk.ready.then(() => {
      this.inbox = window.talkSession?.createInbox();
      this.inbox?.mount(this.chatRef);
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedConversationId } = this.props;
    if (selectedConversationId && prevProps.selectedConversationId !== selectedConversationId) {
      const conversation = window.talkSession.getOrCreateConversation(selectedConversationId);
      this.inbox = window.talkSession.createInbox({ selected: conversation });
      this.inbox?.mount(this.chatRef);
    }
  }

  render() {
    return (
      <div className="chat">
        <ChatTopBar />
        <NewChatModal />
        <div
          className="chat__content"
          ref={c => {
            this.chatRef = c;
          }}
        >
          <div className="k-spinner">
            <img src="/img/loading-spin.svg" alt="loader" />
          </div>
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  location: PropTypes.shape({ state: PropTypes.shape({ selectedEmployeeId: PropTypes.string }) }),
  selectedConversationId: PropTypes.string,
};

export default Chat;
