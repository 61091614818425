import classnames from 'classnames';
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import { ScrollContext } from '@/components/common/ScrollWrapper.jsx';
import { BUDGET_ESTIMATES_VIEW } from '@/constants/Permissions.js';
import { calculateDailyStats } from '@/utils/budgetHelpers';

import ScheduleBudgetLeftRow from '../ScheduleBudgetLeftRow/ScheduleBudgetLeftRow.jsx';
import ScheduleBudgetTableRows from '../ScheduleBudgetTableRows/ScheduleBudgetTableRows.redux.js';

import './ScheduleBudgetTable.scss';

const renderButtons = (showRecommendedSchedule, showBudgetEstimateView) => [
  { name: 'schedule', show: showRecommendedSchedule, optionName: 'schedule' },
  { name: 'swap_horiz', show: showBudgetEstimateView, optionName: 'estimates' },
  { name: '%', modifers: ['percent'], show: showBudgetEstimateView, optionName: 'percentEstimates' },
  { name: 'attach_money', show: showBudgetEstimateView, optionName: 'sale' },
];

class ScheduleBudgetTable extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOptions: [] };
    this.dailyStats = this.dailyStats.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
  }

  dailyStats() {
    const selectedJobTitlesIds = this.props.jobtitleFilter.selectedJobtitlesGrouped.map(jobtitle => jobtitle.id);

    const filterByLocation = this.props.scheduleUIState.selectedDisplayMode.type !== 'templates';

    const mainDateArray =
      this.props.scheduleUIState.selectedDisplayMode.type !== 'templates'
        ? this.props.mainDateStore.dateArray
        : this.props.mainDateStore.templatesDateArray;

    const filterByJobTitle = this.props.scheduleUIState.selectedDisplayMode.type === 'positions';

    const relevantEmployeeIds = this.props.relevantLocations.reduce((sum, current) => {
      const location = this.props.scheduleState.locations[current] || { visible: [] };
      return [...sum, ...location.visible.filter(i => !sum.includes(i))];
    }, []);
    const relevantEmployees =
      this.props.relevantEmployees ||
      relevantEmployeeIds.map(i => this.props.userEmployees.find(e => e.id.toString() === i.toString()));
    return calculateDailyStats(
      relevantEmployees,
      mainDateArray,
      this.props.budgetEstimates,
      this.props.recommendedSchedule,
      {
        selectedJobTitlesIds,
        selectedLocationsIds: this.props.scheduleLocationFilter,
        filterByLocation,
        filterByJobTitle,
        displayModeType: this.props.scheduleUIState.selectedDisplayMode.type,
      },
      this.props.contracts,
    );
  }

  toggleOption(option) {
    this.setState(prevState => ({
      selectedOptions: prevState.selectedOptions.includes(option)
        ? prevState.selectedOptions.filter(o => o !== option)
        : [...prevState.selectedOptions, option],
    }));
  }

  renderOptions(options) {
    const filteredOptions = options.filter(option => option.show);
    if (filteredOptions.length === 0) return null;
    return (
      <th>
        <div className="options">
          {filteredOptions.map(option => {
            const modifers = classnames('icon', option.modifers, {
              active: this.state.selectedOptions.includes(option.optionName),
            });
            return (
              <span
                key={option.optionName}
                className={modifers}
                onClick={() => this.toggleOption(option.optionName)}
                role="presentation"
              >
                <i className="material-icons">{option.name}</i>
              </span>
            );
          })}
        </div>
      </th>
    );
  }

  render() {
    const isTemplatesView = this.props.scheduleUIState.selectedDisplayMode.type === 'templates';
    const { sumHours, sumCosts, sumEmployees, relevantBudgetEstimates, sumRecommendedHours } = this.dailyStats();

    const tableClassname = classnames('kadroGrid--budget k-budgetTable', {
      'k-budgetTable--month': this.props.mainDateStore.dateArray.length > 7,
    });

    const showRecommendedSchedule =
      !this.props.userPermissions.restrictions.includes(RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT) && !isTemplatesView;

    const showBudgetEstimateView =
      this.props.userPermissions.permissions.includes(BUDGET_ESTIMATES_VIEW) && !isTemplatesView;

    return (
      <Grid
        width={this.props.width}
        height={this.props.height}
        columnStyle={this.props.styles.columnStyle}
        scrollCallback={this.props.scrollCallback}
        scrollPosition={this.props.scrollPosition}
        static
        className={tableClassname}
        style={{ position: 'sticky' }}
      >
        <LeftHeader extraStyle={{ minWidth: '100px', maxWidth: '130px' }}>
          {this.renderOptions(renderButtons(showRecommendedSchedule, showBudgetEstimateView))}
        </LeftHeader>
        <Header />
        <LeftColumn>
          <ScheduleBudgetLeftRow
            showRecommendedSchedule={showRecommendedSchedule}
            displayModeType={this.props.scheduleUIState.selectedDisplayMode.type}
            sumCosts={sumCosts}
            sumHours={sumHours}
            sumRecommendedHours={sumRecommendedHours}
            relevantBudgetEstimates={relevantBudgetEstimates}
            selectedOptions={this.state.selectedOptions}
            scheduleLocationFilter={this.props.scheduleLocationFilter}
          />
        </LeftColumn>
        <Rows className={this.props.tableClass}>
          <ScheduleBudgetTableRows
            showRecommendedSchedule={showRecommendedSchedule}
            sumEmployees={sumEmployees}
            sumCosts={sumCosts}
            sumHours={sumHours}
            sumRecommendedWorkedHours={sumRecommendedHours}
            relevantBudgetEstimates={relevantBudgetEstimates}
            selectedOptions={this.state.selectedOptions}
            scheduleLocationFilter={this.props.scheduleLocationFilter}
          />
        </Rows>
      </Grid>
    );
  }
}

ScheduleBudgetTable.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  styles: PropTypes.shape({
    columnStyle: PropTypes.shape({}),
    budgetCol: PropTypes.shape({}),
  }),
  tableClass: PropTypes.string,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    templatesDateArray: PropTypes.arrayOf(PropTypes.number),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  scheduleState: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  relevantLocations: PropTypes.arrayOf(PropTypes.string),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitlesGrouped: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  budgetEstimates: PropTypes.arrayOf(PropTypes.shape({})),
  scrollCallback: PropTypes.func,
  scrollPosition: PropTypes.number,
  recommendedSchedule: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default props => (
  <ScrollContext.Consumer>
    {({ scrollCallback, scrollPosition }) => (
      <ScheduleBudgetTable {...props} scrollCallback={scrollCallback} scrollPosition={scrollPosition} />
    )}
  </ScrollContext.Consumer>
);
