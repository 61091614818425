import { combineReducers } from 'redux';

import attendancesData from './attendancesData.ts';
import displayMode from './displayMode';
import selected from './selected';

export default combineReducers({
  displayMode,
  selected,
  attendancesData,
});
