import { connect } from 'react-redux';

import { changeCustomSorting } from '@/actions/employeeSorting.js';
import { toggleScheduleRow } from '@/actions/schedule.jsx';
import { onRowClick } from '@/actions/schedule/selectedCells';
import { showModal } from '@/actions/uiState.js';

import ScheduleTableRowTitle from './ScheduleTableRowTitle.jsx';

const mapStateToProps = (state, ownProps) => {
  const employee = state.reducer.employees.data[ownProps.employeeId];
  const stats = state.reducer.schedule.rowStatistics[ownProps.employeeId];
  const loanedEmployee = state.reducer.scheduleLoanedEmployees?.scheduleLoanedEmployees[ownProps.employeeId];
  const relevantEmployee = employee || loanedEmployee;
  return {
    firstName: relevantEmployee.first_name,
    lastName: relevantEmployee.last_name,
    rearrangingEmployeesOrder: state.reducer.uiState.rearrangingEmployeesOrder,
    totalWage: stats?.totalWage,
    norm: stats?.norm,
    balance: stats?.balance,
    workedHoursForSelectedPeriod: stats?.workedHoursForSelectedPeriod,
    showScheduleCycleBalance: stats?.showScheduleCycleBalance,
    budgetEnabled: state.reducer.schedule.viewSettings.budget ? state.reducer.schedule.viewSettings.budget.value : true,
    hoursBalanceEnabled: state.reducer.schedule.viewSettings.hoursBalance
      ? state.reducer.schedule.viewSettings.hoursBalance.value
      : true,
    userPermissions: state.reducer.userPermissions,
  };
};

const mapDispatchToProps = { toggleScheduleRow, showModal, changeCustomSorting, onRowClick };

const ScheduleTableRowTitleContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleTableRowTitle);

export default ScheduleTableRowTitleContainer;
