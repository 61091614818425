import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  categories: {
    id: 'exportsCatalog.categories',
    defaultMessage: 'Kategorie eksportów',
  },
  format: {
    id: 'exportsCatalog.format',
    defaultMessage: 'Format eksportów',
  },
  all: {
    id: 'exportsCatalog.all',
    defaultMessage: 'Wszystkie',
  },
  xls: {
    id: 'exportsCatalog.xls',
    defaultMessage: 'XLSX / CSV',
  },
  pdf: {
    id: 'exportsCatalog.pdf',
    defaultMessage: 'PDF',
  },
  xml: {
    id: 'exportsCatalog.xml',
    defaultMessage: 'XML',
  },
  csv: {
    id: 'exportsCatalog.csv',
    defaultMessage: 'CSV',
  },
  timeWorked: {
    id: 'exportsCatalog.timeWorked',
    defaultMessage: 'Ewidencja czasu pracy',
  },
  otherSystems: {
    id: 'exportsCatalog.otherSystems',
    defaultMessage: 'Systemy kadrowo-płacowe',
  },
  operational: {
    id: 'exportsCatalog.operational',
    defaultMessage: 'Raporty operacyjne',
  },
  budget: {
    id: 'exportsCatalog.budget',
    defaultMessage: 'Efektywność i sprzedaż',
  },
  emptyState: {
    id: 'exportsCatalog.emptyState',
    defaultMessage: 'Brak eksportów spełniających kryteria',
  },
  emptyStateDescription: {
    id: 'exportsCatalog.emptyStateDescription',
    defaultMessage: 'Zmień kategorię lub format eksportu w menu po lewej stronie',
  },
});

export const exportDataMessages = defineMessages({
  xls: {
    id: 'exportsCatalog.exports.xls',
    defaultMessage: 'Szczegółowy raport czasu pracy',
  },
  xlsDesc: {
    id: 'exportsCatalog.exports.xlsDesc',
    defaultMessage:
      'Zestawienie każdej obecności, zaplanowanej zmiany oraz nieobecności wraz ze szczegółowymi informacjami.',
  },
  xlsOld: {
    id: 'exportsCatalog.exports.xlsOld',
    defaultMessage: 'Szczegółowy raport czasu pracy (v1)',
  },
  xlsOldDesc: {
    id: 'exportsCatalog.exports.xlsOldDesc',
    defaultMessage:
      'Zestawienie każdej obecności, zaplanowanej zmiany oraz nieobecności wraz ze szczegółowymi informacjami.',
  },
  xlsSum: {
    id: 'exportsCatalog.exports.xlsSum',
    defaultMessage: 'Sumaryczny raport czasu pracy',
  },
  xlsSumDesc: {
    id: 'exportsCatalog.exports.xlsSumDesc',
    defaultMessage:
      'Podsumowanie czasu pracy pracowników wraz z nieobecnościami, godziny przepracowane, godziny nocne oraz inne informacje towarzyszące ewidencji czasu pracy.',
  },
  pdf: {
    id: 'exportsCatalog.exports.pdf',
    defaultMessage: 'Karta ewidencji czasu pracy',
  },
  pdfDesc: {
    id: 'exportsCatalog.exports.pdfDesc',
    defaultMessage: 'Ewidencja czasu pracy przygotowana do wydruku.',
  },
  pdfSum: {
    id: 'exportsCatalog.exports.pdfSum',
    defaultMessage: 'Sumaryczne zestawienie czasu pracy',
  },
  pdfSumDesc: {
    id: 'exportsCatalog.exports.pdsSumDesc',
    defaultMessage: 'Sumaryczne zestawienie czasu pracy pracowników przygotowane do wydruku.',
  },
  comarch: {
    id: 'exportsCatalog.exports.comarch',
    defaultMessage: 'Eksport do systemu kadrowo-płacowego Comarch Optima',
  },
  comarchDesc: {
    id: 'exportsCatalog.exports.comarchDesc',
    defaultMessage:
      'Plik zawierający plan pracy, przepracowane godziny oraz nieobecności. Przygotowany do importu w programie Comarch Optima.',
  },
  r2platnik: {
    id: 'exportsCatalog.exports.r2platnik',
    defaultMessage: 'Eksport obecności do systemu R2Płatnik',
  },
  r2platnikDesc: {
    id: 'exportsCatalog.exports.r2platnikDesc',
    defaultMessage:
      'Eksport ten możesz wczytać do programu R2Płatnik w celu szybkiego przeniesienia danych o czasie obecności pracowników.',
  },
  gsn: {
    id: 'exportsCatalog.exports.XlsGsn',
    defaultMessage: 'Eksport GSN',
  },
  gsnDesc: {
    id: 'exportsCatalog.exports.XlsGsnDesc',
    defaultMessage:
      'Eksport zawiera arkusz z rozliczeniem godzin pracy i nadgodzin pracowników oraz arkusz z ich nieobecnościami w wybranym okresie.',
  },
  enovaXml: {
    id: 'exportsCatalog.exports.enovaXml',
    defaultMessage: 'Eksport do systemu kadrowo-płacowego Enova',
  },
  enovaXmlDesc: {
    id: 'exportsCatalog.exports.enovaXmlDesc',
    defaultMessage:
      'Plik zawierający plan pracy, przepracowane godziny oraz nieobecności. Przygotowany do importu w programie Enova.',
  },
  scheduleExecution: {
    id: 'exportsCatalog.exports.scheduleExecution',
    defaultMessage: 'Plan vs wykonanie',
  },
  scheduleExecutionDesc: {
    id: 'exportsCatalog.exports.scheduleExecutionDesc',
    defaultMessage:
      'Porównanie zaplanowanych godzin pracy względem ilości rzeczywiście przepracowanych godzin w wybranym okresie.',
  },
  spmh: {
    id: 'exportsCatalog.exports.spmh',
    defaultMessage: 'Sumaryczne zestawienie SPMH',
  },
  spmhDesc: {
    id: 'exportsCatalog.exports.spmhDesc',
    defaultMessage:
      'Sumaryczne zestawienie sprzedaży wykonanej, godzin przepracowanych oraz wskaźnika SPMH z podziałem na lokalizacje.',
  },
  spmhByDay: {
    id: 'exportsCatalog.exports.spmhByDay',
    defaultMessage: 'Dzienne zestawienie SPMH',
  },
  spmhByDayDesc: {
    id: 'exportsCatalog.exports.spmhByDayDesc',
    defaultMessage:
      'Dzienne zestawienie sprzedaży wykonanej, godzin przepracowanych oraz wskaźnika SPMH z podziałem na lokalizacje.',
  },
  worktimeBreakdown: {
    id: 'exportsCatalog.exports.worktimeBreakdown',
    defaultMessage: 'Czas pracy z podziałem na warunki zatrudnienia i stanowiska',
  },
  worktimeBreakdownDesc: {
    id: 'exportsCatalog.exports.worktimeBreakdownDesc',
    defaultMessage:
      'Zestawienie czasu pracy dla wybranych lokalizacji z podziałem na warunku zatrudnienia oraz stanowiska.',
  },
  ecp: {
    id: 'exportsCatalog.exports.ecp',
    defaultMessage: 'Miesięczna karta ewidencji czasu pracy',
  },
  ecpDesc: {
    id: 'exportsCatalog.exports.ecpDesc',
    defaultMessage:
      'Eksport ewidencji czasu pracy dla jednego lub wielu pracowników dopasowany do wytycznych Państwowej Inspekcji Pracy.',
  },
  adp: {
    id: 'exportsCatalog.exports.adp',
    defaultMessage: 'Eksport do systemu kadrowo-płacowego ADP',
  },
  adpDesc: {
    id: 'exportsCatalog.exports.adpDesc',
    defaultMessage:
      'Eksport ten możesz wczytać do programu ADP w celu szybkiego przeniesienia danych o planie pracy, godzinach przepracowanych oraz nieobecnościach pracowników.',
  },
  budgetPerLocation: {
    id: 'exportsCatalog.exports.budgetPerLocation',
    defaultMessage: 'Dzienne zestawienie sprzedaży',
  },
  budgetPerLocationDesc: {
    id: 'exportsCatalog.exports.budgetPerLocationDesc',
    defaultMessage: 'Dzienne porównanie sprzedaży wykonanej względem planowanej z podziałem na lokalizacje.',
  },
  r2platnikAbsenceExport: {
    id: 'exportsCatalog.exports.r2platnikAbsenceExport',
    defaultMessage: 'Eksport nieobecności do systemu R2Płatnik',
  },
  r2platnikAbsenceExportDesc: {
    id: 'exportsCatalog.exports.r2platnikAbsenceExportDesc',
    defaultMessage:
      'Eksport ten możesz wczytać do programu R2Płatnik w celu szybkiego przeniesienia danych o nieobecnościach płatnych pracowników.',
  },
  overtime: {
    id: 'exportsCatalog.exports.overtime',
    defaultMessage: 'Nadgodziny dobowe i średniotygodniowe',
  },
  overtimeDesc: {
    id: 'exportsCatalog.exports.overtimeDesc',
    defaultMessage:
      'Miesięczne zestawienie nadgodzin dobowych, średniotygodniowych w tym potencjalnych średniotygodniowych oraz odbiorów nadgodzin.  ',
  },
  r2platnikShiftsExport: {
    id: 'exportsCatalog.exports.r2platnikShiftsExport',
    defaultMessage: 'Eksport harmonogramu do systemu R2Płatnik',
  },
  r2platnikShiftsExportDesc: {
    id: 'exportsCatalog.exports.r2platnikShiftsExportDesc',
    defaultMessage:
      'Eksport ten możesz wczytać do programu R2Płatnik w celu szybkiego przeniesienia danych o harmonogramie pracy pracowników.',
  },
  absence: {
    id: 'exportsCatalog.exports.absence',
    defaultMessage: 'Nieobecności',
  },
  absenceDesc: {
    id: 'exportsCatalog.exports.absenceDesc',
    defaultMessage:
      'Eksport nieobecności i urlopów do pliku XLS. Można wybrać zakres dat, oraz konkretnych pracowników.',
  },
  timesheetControllingExport: {
    id: 'exportsCatalog.exports.timesheetControllingExport',
    defaultMessage: 'Raport obecności',
  },
  timesheetControllingExportDesc: {
    id: 'exportsCatalog.exports.timesheetControllingExportDesc',
    defaultMessage:
      'Zestawienie czasu obecności pracowników. Raport pozwala sprawdzić, czy pracownicy rejestrują czas w RCP oraz czy czas jest później edytowany przez managerów.',
  },
  xlsSage: {
    id: 'exportsCatalog.exports.xlsSage',
    defaultMessage: 'Eksport do Sage (Legacy)',
  },
  xlsSageDesc: {
    id: 'exportsCatalog.exports.xlsSageDesc',
    defaultMessage:
      'Eksport ten możesz wczytać do starej wersji programu Sage w celu szybkiego przeniesienia danych o czasie pracy pracowników oraz zleceniobiorców.',
  },
  symfoniaXml: {
    id: 'exportsCatalog.exports.symfoniaXml',
    defaultMessage: 'Eksport do systemu kadrowo-płacowego Sage',
  },
  symfoniaXmlDesc: {
    id: 'exportsCatalog.exports.symfoniaXmlDesc',
    defaultMessage:
      'Plik zawierający plan pracy, przepracowane godziny oraz nieobecności. Przygotowany do importu w programie Sage Symfonia.',
  },
});
