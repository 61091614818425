import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CHOOSE_LOCALIZATION_MODAL, TIME_TRACKER_SUMMARY } from '@/constants/modalTypes.js';
import { sortShiftsByStartTimestamp } from '@/utils/dateHelper';

import DashboardTimeTrackerInitial from './DashboardTimeTrackerInitial.jsx';
import DashboardTimeTrackerStarted from './DashboardTimeTrackerStarted.jsx';
import ChooseLocationModal from './modals/ChooseLocationModal.redux.js';
import DashboardTimeTrackerSummaryModal from './modals/DashboardTimeTrackerSummary.redux.js';

import './DashboardTimeTracker.scss';

const DashboardTimeTracker = props => {
  const {
    nextShift,
    employee,
    location,
    spanPhrase,
    locationSettings,
    rcpData,
    showModal,
    startAttendance,
    endAttendance,
    startBreak,
    endBreak,
    relevantLocations,
  } = props;

  const [loading, setLoading] = useState(false);

  const dayDiff = nextShift && moment(nextShift.date).diff(moment().startOf('day'), 'days');
  const isTodayShift = dayDiff === 0;
  const currentAttendance =
    rcpData.length > 0 &&
    sortShiftsByStartTimestamp(
      rcpData.filter(data => locationSettings[data.location_id]?.allow_employee_attendace_open_close),
    )[0];

  const currentBreak = currentAttendance?.breaks && currentAttendance.breaks.find(br => !br.end_timestamp);
  const enableRestBreak = currentAttendance && locationSettings[currentAttendance.location_id].enable_rest_break;

  const handleStartWork = async () => {
    setLoading(true);
    if (isTodayShift) {
      await startAttendance(location.id, employee.id);
    } else if (relevantLocations.length === 1) {
      await startAttendance(relevantLocations[0].id, employee.id);
    } else {
      showModal(CHOOSE_LOCALIZATION_MODAL);
    }
    setLoading(false);
  };

  const handleEndWork = () => {
    setLoading(true);
    endAttendance(currentAttendance.id).finally(() => setLoading(false));
  };

  const handleStartBreak = () => {
    if (currentAttendance) {
      setLoading(true);
      startBreak(currentAttendance.id, employee.id).finally(() => setLoading(false));
    }
  };

  const handleEndBreak = () => {
    if (currentAttendance && currentBreak) {
      setLoading(true);
      endBreak(currentAttendance.id, currentBreak.id, employee.id).finally(() => setLoading(false));
    }
  };

  const showSummaryModal = () => {
    showModal(TIME_TRACKER_SUMMARY);
  };

  const initialAttendanceProps = {
    dayDiff,
    employee,
    handleStartWork,
    location,
    nextShift,
    spanPhrase,
    loading,
  };

  const startedAttendanceProps = {
    currentAttendance,
    currentBreak,
    enableRestBreak,
    showSummaryModal,
    handleEndWork,
    handleStartBreak,
    handleEndBreak,
    loading,
  };

  return (
    relevantLocations.length > 0 && (
      <div className="employeeTimeTracker">
        <div className="ibox-title employeeTimeTracker__header">
          <FormattedMessage id="dashboard.nextShift" defaultMessage="Następna zmiana" />
        </div>
        {currentAttendance && (
          <DashboardTimeTrackerSummaryModal currentAttendance={currentAttendance} handleEndWork={handleEndWork} />
        )}
        <ChooseLocationModal
          locations={relevantLocations}
          currentUser={employee}
          onSubmit={locId => {
            setLoading(true);
            startAttendance(locId, employee.id).finally(() => setLoading(false));
          }}
        />

        {!currentAttendance ? (
          <DashboardTimeTrackerInitial {...initialAttendanceProps} />
        ) : (
          <DashboardTimeTrackerStarted {...startedAttendanceProps} />
        )}
      </div>
    )
  );
};

DashboardTimeTracker.propTypes = {
  nextShift: PropTypes.shape({
    date: PropTypes.string,
    working_hours: PropTypes.string,
    job_title: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  employee: PropTypes.shape({
    id: PropTypes.string,
    attendances: PropTypes.arrayOf({
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
      breaks: PropTypes.arrayOf({
        start_timestamp: PropTypes.string,
        end_timestamp: PropTypes.string,
      }),
    }),
    locations: PropTypes.arrayOf({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  rcpData: PropTypes.arrayOf({
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    breaks: PropTypes.arrayOf({
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
    }),
    location_id: PropTypes.string,
    id: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  spanPhrase: PropTypes.string,
  locationSettings: PropTypes.shape({
    enable_rest_break: PropTypes.bool,
    allow_employee_attendace_open_close: PropTypes.bool,
  }),
  showModal: PropTypes.func,
  startAttendance: PropTypes.func,
  endAttendance: PropTypes.func,
  startBreak: PropTypes.func,
  endBreak: PropTypes.func,
  relevantLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

DashboardTimeTracker.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default DashboardTimeTracker;
