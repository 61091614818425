/* eslint-disable camelcase */
import moment from 'moment';

import { COLOR_PALETT_DEFAULT_COLOR } from '@/constants/colors';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import { getRelevantContractForDate } from '@/utils/contracts';
import { getRoundedFactor } from '@/utils/jobTitlesHelpers';

export const modalModifiers = ['narrow'];

export const getInitialState = restrictions => {
  const hideWage = restrictions.includes(BUDGET_INFO_HIDE);
  return {
    jobTitle: '',
    wage: hideWage ? '0' : '',
    color: COLOR_PALETT_DEFAULT_COLOR,
    factor: '0.00',
    errors: {},
  };
};

export const getEmployeesOptions = userEmployees =>
  userEmployees.reduce((options, employee) => {
    if (employee.inactive) return options;
    return [
      ...options,
      {
        label: `${employee.first_name} ${employee.last_name}`,
        value: employee.id,
        active: false,
      },
    ];
  }, []);

export const createJobTitleObject = ({ jobTitle, wage, color, factor }, attachedEmployees) => ({
  title: jobTitle,
  hourly_wage: parseInt(parseFloat(wage) * 100 + 0.1, 10),
  color,
  attachedEmployees,
  factor: factor || '0.00',
});

export const updatedStateForEdit = (editData, showProductionQuotasFactor) => {
  const { title, hourly_wage, color, factor } = editData;
  const updatedState = {
    jobTitle: title,
    wage: (hourly_wage / 100).toFixed(2),
    color,
    factor: parseFloat(factor || 0).toFixed(2),
  };
  if (showProductionQuotasFactor) updatedState.factor = getRoundedFactor(factor);
  return updatedState;
};

export const updatedSelectedEmployeesId = (userEmployees, selectedJobTitleId, contracts) => {
  if (!selectedJobTitleId) return [];
  return userEmployees.reduce((ids, employee) => {
    const employeeContracts = contracts[employee.id] || [];
    const relevantContract = getRelevantContractForDate(employeeContracts, moment().format('YYYY-MM-DD'));
    const relevantJobTitleIds = relevantContract ? relevantContract.job_titles.map(j => j.job_title_id) : [];
    if (relevantJobTitleIds.some(jobTitleId => jobTitleId === selectedJobTitleId)) return [...ids, employee.id];
    return ids;
  }, []);
};

const getEmployeeById = (userEmployees, selectedId) => userEmployees.find(({ id }) => id === selectedId);

export const getChangedSelectedEmployees = (userEmployees, employeesItems, preselectedEmployeesIds) => {
  const toAdd = employeesItems.reduce((employeesToAdd, item) => {
    if (!item.active || preselectedEmployeesIds.includes(item.value)) return employeesToAdd;
    return [...employeesToAdd, getEmployeeById(userEmployees, item.value)];
  }, []);
  const toDelete = employeesItems.reduce((employessToDelete, item) => {
    if (!item.active && preselectedEmployeesIds.includes(item.value))
      return [...employessToDelete, getEmployeeById(userEmployees, item.value)];
    return employessToDelete;
  }, []);
  return { toAdd, toDelete };
};
