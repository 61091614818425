import { SET_DEMO_ACCOUNT } from '@/constants/ActionTypes.js';

const initialState = {
  demoAccount: false,
  type: null,
};

const demo = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEMO_ACCOUNT:
      return Object.assign({}, state, { demoAccount: action.payload });
    default:
      return state;
  }
};

export default demo;
