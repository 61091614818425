import { AbsenceType } from 'kadro-helpers/lib/types';
import { isEmpty } from 'lodash';

import { ExportConfigurationStep, ExportFileFormatSelectStep } from '@/components/common/exports/NewExportModalSteps';
import { NewPayrollExportModalProps } from '@/components/common/exports/NewPayrollExportModal/NewPayrollExportModal';
import {
  ExportModalMultiSelectOptions,
  LocalStorageExportModalData,
  NewPayrollExportModalState,
} from '@/components/common/exports/NewPayrollExportModal/NewPayrollExportModal.types';
import { EXPORT_OPTIONS, EXPORT_STEPS } from '@/constants/exportConstants';
import { ExportConfig, ExportModalObject, PayrollLocationOldFiltersState } from '@/types';

import { formatAdditionalOptions } from '../exports.helpers';

export const MODAL_MODIFIERS = ['narrow'];

export const DEFAULT_EXPORTS_STEPS_COUNT = 2;

export const LOCAL_STORAGE_KEY_BASE = 'NEW_PAYROLL_MODAL_STATE';
export const LOCAL_STORAGE_KEY_VERSION = '3';

export const getInitialState = (modalObject: ExportModalObject) => ({
  modalExportId: modalObject?.preselectedExport || '',
  options: {},
});

const generateLocalStorageKey = (currentUserId: string, exportType: string): string =>
  `V${LOCAL_STORAGE_KEY_VERSION}_${LOCAL_STORAGE_KEY_BASE}_${currentUserId}_${exportType}`;

export const saveExportOptionsToLocalStorage = (
  state: NewPayrollExportModalState,
  multiSelectOptions: ExportModalMultiSelectOptions,
  currentUserId: string,
): void => {
  const localStorageKey = generateLocalStorageKey(currentUserId, state.modalExportId);
  localStorage.setItem(localStorageKey, JSON.stringify({ options: state.options, multiSelectOptions }));
};

export const getExportOptionsFromLocalStorage = (
  exportType: string,
  currentUserId: string,
): LocalStorageExportModalData | null => {
  const localStorageKey = generateLocalStorageKey(currentUserId, exportType);
  const rawOptions = localStorage.getItem(localStorageKey);
  return rawOptions ? JSON.parse(rawOptions) : null;
};

export const getAbsenceTypesOptions = (absenceTypes: AbsenceType[]) =>
  absenceTypes.map(absenceType => ({
    label: absenceType.name,
    value: absenceType.id,
    active: false,
  }));

const stepsToRemove = [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES];

const getExportSteps = (selectedFormat: ExportConfig) =>
  selectedFormat?.steps?.filter(step => !stepsToRemove.includes(step));

const getSortOrder = (visibleEmployeeIds: string[]) =>
  visibleEmployeeIds.reduce<Record<string, number>>((obj, empId, idx) => {
    obj[empId] = idx;
    return obj;
  }, {});

export const canContinue = (currentStep: number, state: { modalExportId: string }, formats: ExportConfig[] = []) => {
  const selectedFormat = formats.find(({ exportId }) => exportId === state.modalExportId);
  const steps = getExportSteps(selectedFormat) || [EXPORT_STEPS.SELECTING_FORMAT];
  const stepName = steps[currentStep - 1];
  if (stepName === EXPORT_STEPS.SELECTING_FORMAT) {
    return !!state.modalExportId;
  }
  return true;
};

export const getExportPayload = (
  state,
  {
    modalObject,
    dateArray,
    selectedEmploymentConditions,
    selectedJobTitles,
    selectedLocations,
    absenceTypes,
    selectedEmployeeId,
    sortingUseLastName,
  }: NewPayrollExportModalProps,
  multiSelects,
  fileIconText: string,
  oldFiltersState?: PayrollLocationOldFiltersState,
) => {
  const { modalExportId } = state;
  const selectedFormat = modalObject.formats.find(({ exportId }) => exportId === modalExportId);
  const multiSelectOptions = selectedFormat.options
    .filter(option => [EXPORT_OPTIONS.MULTI_SELECT, EXPORT_OPTIONS.LIST_SELECTION].includes(option.type))
    .map(option => ({ id: option.id }));
  const visibleEmployeeIds = modalObject?.visibleEmployeeIds || [];
  const employees = selectedEmployeeId ? [selectedEmployeeId] : visibleEmployeeIds;

  const additionalOptions = formatAdditionalOptions(multiSelectOptions, multiSelects, absenceTypes, modalExportId);

  const from = oldFiltersState?.from ?? dateArray[0];
  const to = oldFiltersState?.to ?? dateArray[dateArray.length - 1];
  const employmentConditionsIds =
    oldFiltersState?.employmentConditionIds ?? selectedEmploymentConditions.map(({ id }) => id);
  const jobTitlesIds = oldFiltersState?.jobTitleIds ?? selectedJobTitles.map(({ id }) => id);
  const locations = oldFiltersState?.selectedLocationIds ?? selectedLocations;

  const sortOrder = selectedEmployeeId ? undefined : getSortOrder(visibleEmployeeIds);

  const relevantOptionIds = selectedFormat?.options.map(({ id }) => id);
  const newOptions = relevantOptionIds.reduce((acc, optionId) => {
    acc[optionId] = state.options[optionId];

    return acc;
  }, {});

  return {
    from,
    to,
    dateArray,
    locations,
    job_titles: jobTitlesIds,
    extra_job_titles: [],
    employment_conditions_ids: employmentConditionsIds,
    employees,
    options: { ...newOptions, ...additionalOptions, sortOrder, sortingUseLastName },
    fileIconText,
  };
};

export const displayRelevantStep = (
  modalObject: ExportModalObject,
  state: { modalExportId: string; options: any },
  handlers,
  currentStep,
  multiSelects,
) => {
  if (isEmpty(modalObject)) return null;
  const selectedFormat = modalObject.formats.find(({ exportId }) => exportId === state.modalExportId);
  const steps = selectedFormat?.steps?.length > 0 ? getExportSteps(selectedFormat) : [EXPORT_STEPS.SELECTING_FORMAT];
  const stepName = steps[currentStep - 1];
  switch (stepName) {
    case EXPORT_STEPS.SELECTING_FORMAT:
      return (
        <ExportFileFormatSelectStep
          formats={modalObject.formats}
          selectedFormat={state.modalExportId}
          handlers={handlers}
        />
      );
    case EXPORT_STEPS.SETTINGS_OPTIONS: {
      return (
        <ExportConfigurationStep
          options={state.options}
          exportOptions={selectedFormat.options}
          handlers={handlers}
          multiSelects={multiSelects}
        />
      );
    }
    default: {
      const customSteps = selectedFormat?.customSteps;
      if (isEmpty(customSteps)) return null;
      const CustomStep = customSteps[stepName];
      return (
        <CustomStep state={state} selectedFormat={selectedFormat} handlers={handlers} multiSelects={multiSelects} />
      );
    }
  }
};
