import { UserEmployee } from '@/types';
import { getLimitedArrayWithRemainder } from '@/utils/arrayHelpers.js';
import { getFirstAndLastNameOfEmployee, getUnreleasedEmployeesByIds } from '@/utils/userEmployeesHelpers.js';

export const getUnreleasedEmployeesNames = (
  userEmployees: UserEmployee[],
  employeesIds: string[],
): {
  employeeNames: string[];
  numberOfAdditionalEmployees: number;
} => {
  if (!employeesIds) return;
  const unreleasedEmployees = getUnreleasedEmployeesByIds(userEmployees, employeesIds);
  const unreleasedEmployeesNames = unreleasedEmployees.map(employee => getFirstAndLastNameOfEmployee(employee));
  const { limitedArray: employeeNames, remainder: numberOfAdditionalEmployees } = getLimitedArrayWithRemainder(
    unreleasedEmployeesNames,
    3,
  );
  return { employeeNames, numberOfAdditionalEmployees };
};
