import { defineMessages } from 'react-intl';

import { CHANGE_PAYROLL_SETTING, SAVE_NEW_PAYROLL_FAILURE, SAVE_NEW_PAYROLL_SUCCESS } from '@/constants/ActionTypes.js';
import { PAYROLL_SETTINGS_EDIT_DISABLE } from '@/constants/Restrictions.js';

import { conn } from './index';

const messages = defineMessages({
  successToast: {
    id: 'toastr.successTitles',
    defaultMessage: 'Sukces!',
  },
  errorToast: {
    id: 'toastr.errorTitles',
    defaultMessage: 'Błąd!',
  },
  payrollSettingsSaved: {
    id: 'payrollSettings.saved',
    defaultMessage: 'Ustawienia zostały zapisane.',
  },
  payrollSettingsError: {
    id: 'payrollSettings.error',
    defaultMessage: 'Wystąpił błąd podczas zapisywania ustawień.',
  },
});

export const changePayoutSetting = payoutSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    payoutSetting,
  },
});
export const changeRoundingSetting = roundingSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    roundingSetting,
  },
});
export const changeTimeSetting = timeSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    timeSetting,
  },
});
export const changePhotoSetting = showPhoto => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    showPhoto,
  },
});
export const changeShowSetting = showSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    showSetting,
  },
});

export const changeStartRounding = startRoundingSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    startRoundingSetting,
  },
});

export const changeEndRounding = endRoundingSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    endRoundingSetting,
  },
});
export const changeStartRoundingType = startRoundingType => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    startRoundingType,
  },
});

export const changeEndRoundingType = endRoundingType => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    endRoundingType,
  },
});

export const changeStartTolerance = startTolerance => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    startTolerance,
  },
});

export const changeEndTolerance = endTolerance => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    endTolerance,
  },
});

export const changePayrollSettings = settings => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: settings,
});

export const changeTimeFormatSettings = timeFormatSetting => ({
  type: CHANGE_PAYROLL_SETTING,
  payload: {
    timeFormatSetting,
  },
});

export const getPayrollSettings = () => dispatch => {
  conn.getPayrollSettings().then(result => {
    // backend returns array because we want to be able to turn it to multiple templates
    dispatch({ type: CHANGE_PAYROLL_SETTING, payload: result.data.settings[0] });
  });
};

export const savePayrollSettings = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { payrollSettings, userPermissions } = getState().reducer;
    if (
      !userPermissions.roles.includes('ROLE_OWNER') &&
      userPermissions.restrictions.includes(PAYROLL_SETTINGS_EDIT_DISABLE)
    ) {
      resolve();
      return;
    }
    conn
      .savePayrollSettings(payrollSettings)
      .then(resolve)
      .catch(reject);
  });

export const saveNewPayrollSettingsSuccess = intl => ({
  type: SAVE_NEW_PAYROLL_SUCCESS,
  notification: {
    type: 'success',
    title: intl.formatMessage(messages.successToast),
    description: intl.formatMessage(messages.payrollSettingsSaved),
  },
});

export const saveNewPayrollSettingsFailure = intl => ({
  type: SAVE_NEW_PAYROLL_FAILURE,
  notification: {
    type: 'error',
    title: intl.formatMessage(messages.errorToast),
    description: intl.formatMessage(messages.payrollSettingsError),
  },
});

export const saveNewPayrollSettings = () => async (dispatch, getState, intl) => {
  const { payrollSettings, userPermissions } = getState().reducer;
  const { startTolerance, endTolerance } = payrollSettings;
  if (
    !userPermissions.roles.includes('ROLE_OWNER') &&
    userPermissions.restrictions.includes(PAYROLL_SETTINGS_EDIT_DISABLE)
  ) {
    return;
  }
  try {
    await conn.savePayrollSettings({
      ...payrollSettings,
      endTolerance: endTolerance > 120 ? 9999 : endTolerance,
      startTolerance: startTolerance > 120 ? 9999 : startTolerance,
    });
    dispatch(saveNewPayrollSettingsSuccess(intl));
  } catch (error) {
    dispatch(saveNewPayrollSettingsFailure(intl));
    throw new Error(error);
  }
};
