import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { checkShiftOverlap } from '@/utils/schedulerHelpers.js';

import OpenShiftModalDetails from '../../openShift/OpenShiftModalDetails.jsx';

const applyModalMessages = defineMessages({
  applyTitle: {
    id: 'openShift.applyModal.applyTitle',
    defaultMessage: 'Zapisz się na otwartą zmianę',
  },
  applyConfirmText: {
    id: 'openShift.applyModal.applyConfirmText',
    defaultMessage: 'Zapisz się',
  },
  deassignTitle: {
    id: 'openShift.applyModal.deassignTitle',
    defaultMessage: 'Wypisz się z otwartej zmiany',
  },
  deassignConfirmText: {
    id: 'openShift.applyModal.deassignConfirmText',
    defaultMessage: 'Wypisz się',
  },
  confirmedTitle: {
    id: 'openShift.applyModal.confirmedTitle',
    defaultMessage: 'Otwarta zmiana',
  },
  confirmedConfirmText: {
    id: 'openShift.applyModal.confirmedConfirmText',
    defaultMessage: 'OK',
  },
  overlapError: {
    id: 'openShift.applyModal.overlapError',
    defaultMessage: 'Nie możesz się zapisać, ponieważ masz w tym czasie inną zmianę.',
  },
  confirmedUserMessage: {
    id: 'openShift.applyModal.confirmedUserMessage',
    defaultMessage: 'Twoje zgłoszenie na tę otwartą zmianę zostało już potwierdzone.',
  },
});

class ApplyForOpenShiftModal extends Component {
  constructor(props, context) {
    super(props, context);
    bindPrototypeFunctions(this);
    this.state = {
      showError: false,
    };
  }

  onHide() {
    this.setState({
      showError: false,
    });
    this.props.onHide();
  }

  onSubmit(userDidApplied) {
    if (userDidApplied) {
      this.props.userRemoveApplicationOpenShift(this.props.modalObject.openShift.id);
      this.onHide();
    } else if (
      !userDidApplied &&
      checkShiftOverlap(
        this.props.userEmployees.find(user => user.id === this.props.currentUser.user.id),
        this.props.modalObject.openShift,
      )
    ) {
      this.setState({
        showError: true,
      });
    } else {
      this.props.userApplyOpenShift(this.props.modalObject.openShift.id);
      this.onHide();
    }
  }

  getLocationName() {
    const { openShift } = this.props.modalObject;
    if (isEmpty(openShift)) return '';
    if (openShift.location?.name) return openShift.location.name;
    const selectedLocationId = this.props.modalObject.openShift.location.id;
    const location = this.props.userLocations.find(location => location.id === selectedLocationId);
    return location.name;
  }

  getErrorMessage() {
    return this.state.showError && this.didUserNotApplied
      ? this.context.intl.formatMessage(applyModalMessages.overlapError, {})
      : '';
  }

  didUserNotApplied() {
    return !this.props.modalObject.current_user.has_applied;
  }

  render() {
    const { openShift, current_user: currentUser } = this.props.modalObject;
    const userDidApplied = currentUser.has_applied;
    const userIsConfirmed = currentUser.is_confirmed;

    let title = '';
    let confirmText = '';
    let confirmBtnModifiers = ['orange'];
    let onSubmit = () => this.onSubmit(userDidApplied);
    let modalContent = <OpenShiftModalDetails openShift={{ ...openShift, locationName: this.getLocationName() }} />;
    if (userIsConfirmed) {
      title = this.context.intl.formatMessage(applyModalMessages.confirmedTitle, {});
      confirmText = this.context.intl.formatMessage(applyModalMessages.confirmedConfirmText, {});
      onSubmit = this.onHide;
      modalContent = (
        <div className="k-open-shifts__apply">
          {this.context.intl.formatMessage(applyModalMessages.confirmedUserMessage, {})}
        </div>
      );
    } else if (userDidApplied) {
      title = this.context.intl.formatMessage(applyModalMessages.deassignTitle, {});
      confirmText = this.context.intl.formatMessage(applyModalMessages.deassignConfirmText, {});
      confirmBtnModifiers = ['red'];
    } else {
      title = this.context.intl.formatMessage(applyModalMessages.applyTitle, {});
      confirmText = this.context.intl.formatMessage(applyModalMessages.applyConfirmText, {});
    }

    return (
      <MDKadroModal
        show={this.props.showModal}
        title={title}
        confirmText={confirmText}
        confirmBtnModifiers={confirmBtnModifiers}
        onSubmit={onSubmit}
        onHide={this.onHide}
        modifiers={['narrow']}
        hideCancelButton={userIsConfirmed}
        errorMessage={this.getErrorMessage()}
      >
        {modalContent}
      </MDKadroModal>
    );
  }
}

ApplyForOpenShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ApplyForOpenShiftModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  userApplyOpenShift: PropTypes.func,
  userRemoveApplicationOpenShift: PropTypes.func,
  modalObject: PropTypes.shape({
    openShift: PropTypes.shape({
      id: PropTypes.string,
      location: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
    current_user: PropTypes.shape({}),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape()),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default ApplyForOpenShiftModal;
