import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ADD_EVENT_MODAL } from '@/constants/modalTypes';

import ScheduleEvent from '../../ScheduleEvent/ScheduleEvent.redux';
import ScheduleTableItemBackground from '../../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';

const EventsTable = ({ isEmployee, locationIds, eventIds, size, showModal, date }) => {
  const eventsForSelectedLocations = useMemo(
    () =>
      Object.keys(eventIds || {}).reduce((agg, locationId) => {
        if (locationIds.includes(locationId)) return [...agg, ...(eventIds[locationId] || [])];
        return agg;
      }, []),
    [locationIds, eventIds],
  );
  const isEmpty = !eventsForSelectedLocations.length;
  const tableItemClassName = classnames('scheduleTable__item', {
    empty: !eventsForSelectedLocations.length && !isEmployee,
  });
  return (
    <td
      className={tableItemClassName}
      role="presentation"
      onClick={() => {
        if (isEmpty && !isEmployee) showModal(ADD_EVENT_MODAL, { date });
      }}
    >
      <ScheduleTableItemBackground date={date} />

      {!isEmployee && <span className="material-icons-outlined scheduleTable__item__add">add</span>}
      {eventsForSelectedLocations.map(eventId => (
        <ScheduleEvent
          {...{ size, eventId, date }}
          key={eventId}
          multipleLocationsView={locationIds.length > 1}
        />
      ))}
    </td>
  );
};

EventsTable.propTypes = {
  isEmployee: PropTypes.bool,
  locationIds: PropTypes.arrayOf(PropTypes.string),
  eventIds: PropTypes.shape({}),
  size: PropTypes.string,
  showModal: PropTypes.func,
  date: PropTypes.string,
};

export default EventsTable;
