import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import NameCell from '@/components/common/NameCell.jsx';
import OptionsCell from '@/components/common/OptionsCell.jsx';
import {
  COMPANY_MANAGE_LOCATIONS_HIDE_DELETE,
  COMPANY_MANAGE_LOCATIONS_HIDE_EDIT,
  SETTINGS_LOCATIONS_HIDE,
} from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';

class LocationsRow extends PureComponent {
  render() {
    const { id, name, color, city, pin } = this.props.data;
    const { disabledDeleteButton } = this.props;

    const rowOptions = [
      {
        option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
        restriction: COMPANY_MANAGE_LOCATIONS_HIDE_EDIT,
        style: 'edit',
        function: this.props.edit,
      },
      {
        option: (
          <Link to={`/settings/location/${name}`}>
            <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />
          </Link>
        ),
        restriction: SETTINGS_LOCATIONS_HIDE,
        style: 'settings',
        function: null,
      },
      {
        option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
        restriction: COMPANY_MANAGE_LOCATIONS_HIDE_DELETE,
        style: 'delete',
        function: this.props.delete,
        isDisabled: disabledDeleteButton,
      },
    ];

    const { options, styles, functions, disabledArray } = applyPermissionsForOptions(
      rowOptions,
      this.props.userPermissions,
    );
    return (
      <tr>
        <td>
          <Checkbox
            checked={this.props.stateUI.selected.includes(id)}
            toggle={this.props.toggle}
            style={{ margin: '0', marginLeft: '3px' }}
          />
          <NameCell name={name} color={color} />
        </td>
        <td>{city}</td>
        <td>Aktywna</td>
        <td>{pin}</td>
        <td>
          <OptionsCell options={options} styles={styles} functions={functions} disabledArray={disabledArray} />
        </td>
      </tr>
    );
  }
}

LocationsRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    city: PropTypes.string,
    pin: PropTypes.string,
  }).isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  disabledDeleteButton: PropTypes.bool,
};
export default LocationsRow;
