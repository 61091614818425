import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { absenceErrorMessages } from '@/components/absences/modals/AbsenceAddModal/AbsenceAddModal.messages.js';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { MEDIUM, SCROLL_CONTENT } from '@/constants/modalModifiers.js';
import { OLD_PAYROLL_VIEW } from '@/constants/Permissions';
import { freeDayToDto } from '@/redux-store/freeDaysMarking';
import {
  checkIfAbsencesOverlap,
  createAbsenceObject,
  isOvertimeCollectionsDurationIncorrect,
} from '@/utils/absenceHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { checkIfContractHasJobTitle, getRelevantContractForDate } from '@/utils/contracts';
import { calculateDurationMinutes, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { createEvent, validateInput as validateInputHelper } from '@/utils/inputHelpers';
import { getSumOfOvertimeAcceptancesInMinutes } from '@/utils/overtimeCollectionsHelpers';
import { createOldOvertimeCollectionObject, createOvertimeCollectionObject } from '@/utils/overtimeHelpers.js';
import { createRepeatObject } from '@/utils/repeatShiftHelpers.js';
import { addShiftModalOnSubmit, editShiftModalOnSubmit } from '@/utils/shiftHelpers';

import {
  createItemShift,
  createShift,
  createUserSidebarObject,
  getErrorMessage,
  getFooterOptions,
  getInitialState,
  getInputsToValidateForAbsence,
  getTabs,
  handleAbsenceRequestError,
  updatedStateForEditFreeDay,
  updatedStateForEditOvertimeCollection,
  updatedStateForEditShift,
  updatedStateForNewShiftOrAbsence,
} from './AddShiftAndAbsenceModal.helpers.js';
import { messages } from './AddShiftAndAbsenceModal.messages';
import SidebarContent from './Sidebar/Sidebar.redux';
import { createFreeDayItems } from './Tabs/AddFreeDayTab/AddFreeDayTab.helpers';
import { displayRelevantTab } from './Tabs/Tabs.jsx';

class AddShiftAndAbsenceModal extends Component {
  constructor(props, context) {
    super(props, context);
    bindPrototypeFunctions(this);
    this.state = getInitialState(props, context.intl);
  }

  componentDidUpdate(prevProps, prevState) {
    const { modalObject, employeeContracts = [], userJobTitles, userEmployees, loanedEmployees } = this.props;
    const employee =
      userEmployees.find(e => e.id === modalObject.employee.id) || loanedEmployees[modalObject.employee.id];

    if (!isEmpty(this.state.employee) && !isEqual(prevState.employee, this.state.employee) && !modalObject.shift) {
      const { employment_conditions } = this.state.employee;
      const { start, end } = this.props.mainDateStore.customDate;
      const { hire_date: hireDate, release_date: releaseDate } = employment_conditions;

      if (!releaseDate && hireDate && start <= hireDate) {
        this.setState(
          updatedStateForNewShiftOrAbsence(
            modalObject,
            employee,
            this.props.mainDateStore,
            hireDate,
            null,
            employeeContracts,
            userJobTitles,
          ),
        );
      } else if (!hireDate && releaseDate && end >= releaseDate) {
        this.setState(
          updatedStateForNewShiftOrAbsence(
            modalObject,
            employee,
            this.props.mainDateStore,
            null,
            releaseDate,
            employeeContracts,
            userJobTitles,
          ),
        );
      } else if (hireDate && releaseDate && start <= hireDate && end >= releaseDate) {
        this.setState(
          updatedStateForNewShiftOrAbsence(
            modalObject,
            employee,
            this.props.mainDateStore,
            hireDate,
            releaseDate,
            employeeContracts,
            userJobTitles,
          ),
        );
      } else if (hireDate && releaseDate && start >= hireDate && end >= releaseDate) {
        this.setState(
          updatedStateForNewShiftOrAbsence(
            modalObject,
            employee,
            this.props.mainDateStore,
            start,
            releaseDate,
            employeeContracts,
            userJobTitles,
          ),
        );
      }

      const minDate = hireDate ? new Date(hireDate) : null;
      const maxDate = releaseDate ? new Date(releaseDate) : null;
      this.setState(previousState => ({ ...previousState, minDate, maxDate }));
    }
    const showModalChanged = this.props.showModal && prevProps.showModal !== this.props.showModal;
    if (!showModalChanged) return;

    if (modalObject.date) {
      this.props.getAbsenceLimitsForEmployee(employee.id, modalObject.date, modalObject.date);
    }
    if (this.props.modalObject.isOvertimeCollection)
      return this.setState(updatedStateForEditOvertimeCollection(modalObject, employee, this.context.intl));
    if (modalObject.employee && modalObject.shift)
      return this.setState(updatedStateForEditShift(modalObject, employee));
    if (modalObject.freeDayItemId) return this.setState(updatedStateForEditFreeDay(modalObject));
    this.setState(
      updatedStateForNewShiftOrAbsence(
        modalObject,
        employee,
        this.props.mainDateStore,
        this.state.minDate,
        null,
        employeeContracts,
        userJobTitles,
      ),
    );
  }

  changeTab(id) {
    this.setState({ errors: {}, activeTab: id });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    const emptySpaceInComment = name === 'comment' && /^[\s]+$/.test(value);

    if (emptySpaceInComment) {
      this.setState({ [name]: '', errors: {} });
    } else {
      this.setState({ [name]: value, errors: {} });
    }
    this.validateInput(createEvent(name, value), e.valueToCompare);
  }

  async validateSelectedInputs(inputs) {
    const validationResult = await Promise.all(inputs.map(i => this.validateInput(createEvent(i, this.state[i]))));
    return !Object.values(validationResult).some(err => err !== '');
  }

  async validateInput(event, valueToCompare) {
    const [name, error] = await validateInputHelper(event, valueToCompare);
    this.setError(name, error);
    return error;
  }

  setError(name, error, values = {}) {
    this.setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [name]: error && typeof error !== 'string' ? this.context.intl.formatMessage(error, values) : error,
      },
    }));
  }

  hideAndClear() {
    this.props.onHide();
    this.setState(getInitialState(this.props, this.context.intl));
  }

  async onSubmitShift() {
    const valid = await this.validateShift();
    if (!valid) return;
    await this.saveShift();
  }

  async validateShift() {
    const inputs = ['comment', 'working_hours'];
    const { state } = this;
    const eventsToValidate = inputs.map(inputName => createEvent(inputName, state[inputName]));
    const relevantContract = getRelevantContractForDate(
      this.props.employeeContracts || [],
      this.props.modalObject.date || this.props.modalObject.shift?.date,
    );
    const relevantJobTitles = this.props.userJobTitles
      .filter(jobTitle => checkIfContractHasJobTitle(relevantContract, jobTitle.id))
      .map(jobTitle => ({ job_title: jobTitle }));
    eventsToValidate.push(
      createEvent('selectedJobTitle', state.selectedJobTitle, { valueToCompare: relevantJobTitles }),
      createEvent('shiftsOverlap', createItemShift(state), {
        valueToCompare: state.displayRepeat ? [] : Object.values(this.props.employeeShifts),
      }),
    );
    const validationResult = await Promise.all(eventsToValidate.map(e => this.validateInput(e, e.valueToCompare)));
    return !Object.values(validationResult).some(err => err !== '');
  }

  async saveShift() {
    const shift = createShift(this.props, this.state);
    if (this.props.modalObject?.shift) {
      editShiftModalOnSubmit(this.props, shift);
    } else {
      const error = await addShiftModalOnSubmit(this.state, this.props, shift);
      if (error) return this.setError('repeatedShiftOverlap', error);
    }
    this.hideAndClear();
  }

  async onSubmitAbsence() {
    if (this.state.selectedAbsence.overtimeCollection) return this.onSubmitOvertimeCollection();
    const absenceObject = createAbsenceObject(this.props.modalObject.employee.id, this.state);
    const valid = await this.validateAbsence(absenceObject);
    if (!valid) return;
    this.saveAbsence(absenceObject);
  }

  async validateAbsence(absence) {
    const inputs = getInputsToValidateForAbsence(this.state.selectedAbsence, this.state.allDay);
    const valid = await this.validateSelectedInputs(inputs);
    if (!valid) return;
    const absences = this.props.scheduleAbsences[this.props.modalObject.employee.id] || [];
    const absencesOverlap = checkIfAbsencesOverlap(absences, absence, this.props.userPermissions.permissions);
    if (absencesOverlap) this.setError('absencesOverlap', absenceErrorMessages.absencesOverlap);
    return !absencesOverlap;
  }

  async saveAbsence(absenceObject) {
    try {
      this.setState({ loading: true });
      await this.props.addAbsence(absenceObject);
      this.hideAndClear();
    } catch (err) {
      const requestAbsenceError = handleAbsenceRequestError(err);
      this.setError('requestAbsenceError', requestAbsenceError);
    } finally {
      this.setState({ loading: false });
    }
  }

  async onSubmitOldOvertimeCollection() {
    const { absenceHours, absenceComment, selectedDay } = this.state;
    const { modalObject, addOldOvertimeCollection, editOldOvertimeCollection } = this.props;
    const valid = await this.validateSelectedInputs(['absenceHours', 'absenceComment']);
    if (!valid) return;
    const overtimeCollection = createOldOvertimeCollectionObject(
      modalObject,
      absenceHours,
      absenceComment,
      selectedDay,
    );
    this.setState({ loading: true });
    if (modalObject.isOvertimeCollection) {
      await editOldOvertimeCollection(overtimeCollection);
    } else {
      await addOldOvertimeCollection(overtimeCollection);
    }
    this.setState({ loading: false });
    this.hideAndClear();
  }

  async onSubmitOvertimeCollection() {
    if (this.props.userPermissions.permissions.includes(OLD_PAYROLL_VIEW)) {
      await this.onSubmitOldOvertimeCollection();
      return;
    }
    const {
      absenceHours,
      absenceComment,
      selectedDay,
      amount50,
      amount100,
      amountPotential,
      multiplier,
      overtimeCycle,
      availableOvertimes,
    } = this.state;
    const { modalObject, addOvertimeCollection } = this.props;
    const valid = await this.validateSelectedInputs(['absenceHours', 'absenceComment']);
    if (!valid) return;
    if (
      isOvertimeCollectionsDurationIncorrect(
        multiplier,
        amount50,
        amount100,
        amountPotential,
        availableOvertimes,
        absenceHours,
      )
    ) {
      const sumOfOvertimeAcceptances = getSumOfOvertimeAcceptancesInMinutes(amount50, amount100, amountPotential);
      const overtimeUsageInMinutes = Math.floor(calculateDurationMinutes(absenceHours) / Number(multiplier));
      const isOvertimeUsageExceeded = overtimeUsageInMinutes < sumOfOvertimeAcceptances;
      this.setError(
        'overtimeCollectionsExceed',
        isOvertimeUsageExceeded
          ? absenceErrorMessages.overtimeCollectionsExceed
          : absenceErrorMessages.overtimeCollectionsWrong,
        { difference: parseMinutesToHumanForm(sumOfOvertimeAcceptances - overtimeUsageInMinutes) },
      );
      return;
    }
    const overtimeCollection = createOvertimeCollectionObject(
      modalObject,
      absenceHours,
      absenceComment,
      selectedDay,
      amount50,
      amount100,
      amountPotential,
      multiplier,
      overtimeCycle,
    );
    this.setState({ loading: true });
    await addOvertimeCollection(overtimeCollection);
    this.setState({ loading: false });
    this.hideAndClear();
  }

  async onSubmitFreeDay() {
    const repeatObj = this.state.displayRepeat ? createRepeatObject(this.state) : null;
    const { modalObject } = this.props;
    const { freeDayItemId, employee } = modalObject;
    const { selectedFreeDayId } = this.state;
    const date = modalObject.date || modalObject.shift?.date;
    if (modalObject.freeDayItemId) {
      const item = freeDayToDto({ id: freeDayItemId, date, employeeId: employee.id, markingId: selectedFreeDayId });
      this.props.editFreeDays([item]);
    } else {
      const items = createFreeDayItems(repeatObj, { ...modalObject, date }, selectedFreeDayId);
      this.props.addFreeDays(items);
    }
    this.hideAndClear();
  }

  async getObjectHistory() {
    if (!this.editingHistoryHasBeenClicked) {
      this.editingHistoryHasBeenClicked = true;
      await this.props.getShiftHistory(this.props.modalObject.shift);
      this.editingHistoryHasBeenClicked = false;
    }
  }

  handleSubmit() {
    switch (this.state.activeTab) {
      case 0:
        return this.onSubmitShift();
      case 1:
        return this.onSubmitAbsence();
      case 2:
        return this.onSubmitFreeDay();
      default:
        throw new Error('Invalid tab');
    }
  }

  render() {
    const { modalObject, showModal, employeeShifts } = this.props;
    const { activeTab } = this.state;
    const allTabs = getTabs(this.props, this.state, this.context.intl);
    const title = modalObject?.isOvertimeCollection
      ? this.context.intl.formatMessage(messages.editOvertimeCollection)
      : allTabs[activeTab].name;

    return (
      <MDKadroModal
        show={showModal}
        onHide={this.hideAndClear}
        modifiers={[MEDIUM]}
        title={title}
        onSubmit={this.handleSubmit}
        errorMessage={getErrorMessage(this.state.errors)}
        showSidebar
        options={allTabs}
        activeTab={activeTab}
        onTabClick={this.changeTab}
        user={createUserSidebarObject(modalObject?.employee)}
        disableConfirm={
          this.state.loading ||
          this.props.modalObject?.isLoanedEmployee ||
          this.props.modalObject?.shift?.isLoaned ||
          (this.state.activeTab === 2 && !this.state.selectedFreeDayId)
        }
        sidebarContent={
          <SidebarContent
            activeTab={this.state.activeTab}
            working_hours={this.state.working_hours}
            selectedJobTitle={this.state.selectedJobTitle}
            selectedAbsence={this.state.selectedAbsence}
            employeeShifts={employeeShifts}
            date={this.state.date}
          />
        }
        footerOptions={getFooterOptions(this.props, this.context.intl, this.getObjectHistory)}
        withOverlayScrollContent
      >
        {displayRelevantTab(this.props, this.state, this.handleInputChange, this.onSubmitShift)}
      </MDKadroModal>
    );
  }
}

AddShiftAndAbsenceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddShiftAndAbsenceModal.defaultProps = {
  modalObject: {
    employee: {},
  },
};

AddShiftAndAbsenceModal.propTypes = {
  selectedLocation: PropTypes.shape({}),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    customDate: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }),
  }),
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.shape({
        medium: PropTypes.string,
      }),
      employment_conditions: PropTypes.shape({
        schedule_cycle: PropTypes.shape({}),
      }),
      availability_blocks: PropTypes.shape({}),
    }),
    date: PropTypes.string,
    location: PropTypes.shape({}),
    shift: PropTypes.shape({
      date: PropTypes.string,
      comment: PropTypes.string,
      job_title: PropTypes.shape({}),
      working_hours: PropTypes.string,
    }),
    absence: PropTypes.shape({
      id: PropTypes.string,
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
      comment: PropTypes.string,
    }),
    isOvertimeCollection: PropTypes.bool,
    isLoanedEmployee: PropTypes.bool,
    freeDayItemId: PropTypes.string,
  }),
  onHide: PropTypes.func,
  showModal: PropTypes.bool,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
    user_id: PropTypes.string,
  }),
  getShiftHistory: PropTypes.func,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      employment_conditions: PropTypes.shape({
        show_absences: PropTypes.bool,
      }),
    }),
  ),
  employeeShifts: PropTypes.shape({}),
  addOvertimeCollection: PropTypes.func,
  addOldOvertimeCollection: PropTypes.func,
  addFreeDays: PropTypes.func,
  editOldOvertimeCollection: PropTypes.func,
  editFreeDays: PropTypes.func,
  addAbsence: PropTypes.func,
  getAbsenceLimitsForEmployee: PropTypes.func,
  scheduleAbsences: PropTypes.shape({}),
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AddShiftAndAbsenceModal;
