import classnames from 'classnames';
import PropTypes from 'prop-types';

import HighlightText from '@/components/HighlightText/HighlightText';
import { formatHighlightData } from '@/utils/formatHighlightText';

import './autocompletePredictions.scss';

const AutocompletePredictions = props => (
  <ul
    className={classnames('k-autocompletePredictions', {
      'k-autocompletePredictions--show': props.show,
    })}
  >
    {
      props.predictions.map((pred) => (
        <li
          key={pred.place_id}
          className='k-autocompletePredictions__prediction'
          onClick={() => props.onSelect(pred.place_id)}
          role="menuitem"
        >
          <div className='k-autocompletePredictions__mainText'>
            <HighlightText
              highlights={formatHighlightData(
                pred.structured_formatting.main_text_matched_substrings,
                pred.structured_formatting.main_text,
              )}
            />
          </div>
          <div className='k-autocompletePredictions__secondaryText'>
            <HighlightText
              highlights={formatHighlightData(
                pred.structured_formatting.secondary_text_matched_substrings || [],
                pred.structured_formatting.secondary_text,
              )}
            />
          </div>
        </li>
      ))
    }
    <li className="k-autocompletePredictions__poweredBy">
      <img
        className='k-autocompletePredictions__googleImg'
        src="/img/poweredByGoogle.png"
        alt="Powered by Google"
      />
    </li>
  </ul>
);

AutocompletePredictions.propTypes = {
  predictions: PropTypes.arrayOf(PropTypes.shape({
    place_id: PropTypes.string,
    structured_formatting: PropTypes.shape({
      main_text: PropTypes.string,
      main_text_matched_substrings: PropTypes.arrayOf(PropTypes.shape({
        length: PropTypes.number,
        offset: PropTypes.number,
      })),
      secondary_text: PropTypes.string,
      secondary_text_matched_substrings: PropTypes.arrayOf(PropTypes.shape({
        length: PropTypes.number,
        offset: PropTypes.number,
      })),
    }),
  })),
  onSelect: PropTypes.func,
  show: PropTypes.bool,
};

export default AutocompletePredictions;