import { connect } from 'react-redux';

import { changeCurrentTemplate } from '@/actions/templates.js';
import TopBarTemplatesFilter from '@/components/TopBars/filters/TopBarTemplatesFilter/TopBarTemplatesFilter.jsx';

const mapStateToProps = state => ({
  scheduleUIState: state.reducer.scheduleUIState,
  userTemplates: state.reducer.userTemplates,
});

const mapDispatchToProps = {
  changeCurrentTemplate,
};

const TopBarTemplatesFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarTemplatesFilter);

export default TopBarTemplatesFilterContainer;
