import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import RolesTableLeftRowDetails from '../RolesTableLeftRowDetails/RolesTableLeftRowDetails.redux';

import './RolesTableLeftRow.scss';

class RolesTableLeftRow extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    this.props.toggleGroup(id);
  }

  render() {
    const { group, permissions, toggledGroups } = this.props;
    const toggled = toggledGroups.includes(group.id);
    const toggledLeftRowGroup = classnames('k-rolesTable__details', {
      'k-rolesTable__details--show': toggled,
    });
    const toggledLeftRow = classnames('k-rolesTableLeftRow', {
      'k-rolesTableLeftRow__row--hide': !toggled,
    });
    return (
      <tr className={toggledLeftRow}>
        <td>
          <table className="table">
            <tbody>
              <tr className="k-rolesTableLeftRow__row">
                <td style={{ border: 'none', padding: 'none' }}>
                  <div className="k-rolesTableLeftRow__rowTextContainer">
                    <span>{group.name}</span>
                    {permissions.length ? (
                      <i
                        className="material-icons sortdir noselect"
                        style={{ marginTop: '0px' }}
                        role="button"
                        onClick={() => this.toggle(group.id)}
                        tabIndex={0}
                      >
                        {toggled ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                      </i>
                    ) : null}
                  </div>
                </td>
              </tr>
              <tr className={toggledLeftRowGroup}>
                <td className="k-rolesTable__detailsRow">
                  <RolesTableLeftRowDetails permissions={permissions} />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

RolesTableLeftRow.propTypes = {
  group: PropTypes.shape({}),
  permissions: PropTypes.arrayOf(PropTypes.shape({})),
  toggledGroups: PropTypes.arrayOf(PropTypes.string),
  toggleGroup: PropTypes.func,
};

export default RolesTableLeftRow;
