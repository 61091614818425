import PropTypes from 'prop-types';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import {
  calculateTotalDurationInMinutesForShifts,
  calculateTotalWageFromShifts,
  getScheduleSettingsValue,
} from '@/utils/schedulerHelpers.js';

const ScheduleTemplatesTableRowTitle = props => {
  const relevantRowData = {
    ...props.rowData,
    shifts: props.rowData.shifts.filter(shift => props.dateStore.templatesDateArray.includes(shift.date)),
  };

  const sumHours = calculateTotalDurationInMinutesForShifts(relevantRowData.shifts);
  const sumCosts = calculateTotalWageFromShifts(relevantRowData.shifts, [], []);

  const details = (
    <div className="emp_det">
      {parseMinutesToHumanForm(sumHours)}, {roundToTwoSigDigits(sumCosts)}
      PLN
    </div>
  );

  const scheduleSettingBudget = getScheduleSettingsValue(props.scheduleUIState.settings, 'budget');

  return (
    <tr id="row1" className="h-sorce">
      <td className="col_name">
        <div className="col_name_wrap">
          <div className="col_fullname">
            {props.rowData.title}
            {scheduleSettingBudget ? details : null}
          </div>
        </div>
      </td>
    </tr>
  );
};

ScheduleTemplatesTableRowTitle.propTypes = {
  rowData: PropTypes.shape({
    shifts: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
  }),
  scheduleUIState: PropTypes.shape({
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dateStore: PropTypes.shape({
    templatesDateArray: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default ScheduleTemplatesTableRowTitle;
