import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import DashboardTeam from './DashboardTeam.jsx';

const mapStateToProps = ({ reducer }) => ({
  attendances: reducer.dashboard.currentAttendances,
  teamSorting: reducer.dashboard.team,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTeam);
