export const FREEMIUM_EVENT_USER_VIEW_NOTIFICATIONS = 'FREEMIUM_EVENT_USER_VIEW_NOTIFICATIONS';

export const FREEMIUM_EVENT_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS =
  'FREEMIUM_EVENT_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS';

export const FREEMIUM_EVENT_EMPLOYEE_MODAL_ABSENCE_LIMITS = 'FREEMIUM_EVENT_EMPLOYEE_MODAL_ABSENCE_LIMITS';

export const FREEMIUM_EVENT_TOGGLE_OPEN_SHIFT = 'FREEMIUM_EVENT_TOGGLE_OPEN_SHIFT';

export const FREEMIUM_EVENT_TOGGLE_TRADE_SHIFT = 'FREEMIUM_EVENT_TOGGLE_TRADE_SHIFT';

export const FREEMIUM_EVENT_WORKING_RULES = 'FREEMIUM_EVENT_WORKING_RULES';

export const FREEMIUM_EVENT_ROLE_VIEW = 'FREEMIUM_EVENT_ROLE_VIEW';

export const FREEMIUM_EVENT_ADD_ABSENCE = 'FREEMIUM_EVENT_ADD_ABSENCE';

export const FREEMIUM_EVENT_ADD_ATTENDANCE = 'FREEMIUM_EVENT_ADD_ATTENDANCE';

export const FREEMIUM_EVENT_ADD_MORE_LOCATIONS = 'FREEMIUM_EVENT_ADD_MORE_LOCATIONS';

export const FREEMIUM_EVENT_ADD_MORE_JOB_TITLES = 'FREEMIUM_EVENT_ADD_MORE_JOB_TITLES';

export const FREEMIUM_EVENT_ADD_MORE_EMPLOYEES = 'FREEMIUM_EVENT_ADD_MORE_EMPLOYEES';

export const FREEMIUM_EVENT_EMPLOYEE_VIEW_EXPORT = 'FREEMIUM_EVENT_EMPLOYEE_VIEW_EXPORT';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_NOTIFICATION = 'FREEMIUM_EVENT_LOCATION_SETTINGS_NOTIFICATION';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_WORKING_RULES = 'FREEMIUM_EVENT_LOCATION_SETTINGS_WORKING_RULES';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_RESTRICTIONS = 'FREEMIUM_EVENT_LOCATION_SETTINGS_RESTRICTIONS';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_DEVICE_SETTINGS = 'FREEMIUM_EVENT_LOCATION_SETTINGS_DEVICE_SETTINGS';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_OTHER_SETTINGS = 'FREEMIUM_EVENT_LOCATION_SETTINGS_OTHER_SETTINGS';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_OPENING_HOURS = 'FREEMIUM_EVENT_LOCATION_SETTINGS_OPENING_HOURS';

export const FREEMIUM_EVENT_LOCATION_SETTINGS_BONUS_SYSTEM = 'FREEMIUM_EVENT_LOCATION_SETTINGS_BONUS_SYSTEM';

export const FREEMIUM_EVENT_PAYROLL_SETTINGS = 'FREEMIUM_EVENT_PAYROLL_SETTINGS';

export const FREEMIUM_EVENT_PASSWORD_REFRESH = 'FREEMIUM_EVENT_PASSWORD_REFRESH';

export const FREEMIUM_EVENT_RESTRICTIONS_SETTINGS = 'FREEMIUM_EVENT_RESTRICTIONS_SETTINGS';

export const FREEMIUM_EVENT_AUTO_GENERATE_SCHEDULE = 'FREEMIUM_EVENT_AUTO_GENERATE_SCHEDULE';
export const AUTO_GENERATE_SCHEDULE = 'AUTO_GENERATE_SCHEDULE';
export const INTERCOM_APP_ID = {
  master: 'rtvcnrru',
  test: 'j4zn92p0',
};
