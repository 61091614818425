import moment from 'moment';
import PropTypes from 'prop-types';

import { defineMessages } from 'react-intl';

import { KADRO_REAL_COLOR } from '@/constants/colors.js';
import { getRGBA } from '@/utils/colorHelpers.js';
import {
  calculateDurationBetweenTimestamps,
  calculateDurationMinutes,
  roundUpDateTo,
} from '@/utils/dateHelper.js';
import { getAreaChartData, getAreaSeries } from '@/components/reports/rechart.utils';
import { AreaChart } from '@/components/common/charts';

const STEP_IN_MINUTES = 15;
const KADRO_EXPECTED_COLOR = '#737373';
const commonData = {
  borderDash: [],
  borderDashOffset: 0,
  borderJoinStyle: 'miter',
  fill: true,
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 5,
  steppedLine: false,
  lineTension: 0.3,
};

const getColors = (color) => ({
  backgroundColor: getRGBA(color, 0.6),
  borderColor: color,
  pointBorderColor: color,
  pointHoverBackgroundColor: color,
});


const getStartPoint = (attendances, shifts) => {
  const startPoint = [...attendances, ...shifts].reduce((prev, curr) =>
    moment(prev.start_timestamp).unix() < moment(curr.start_timestamp).unix()
      ? prev
      : curr
  );

  return roundUpDateTo(
    moment(startPoint.start_timestamp),
    STEP_IN_MINUTES,
    'minutes'
  );
};

const getEndPoint = (attendances, shifts) => {
  let endPoint = [...attendances, ...shifts].filter((obj) => obj.end_timestamp);
  if (!endPoint.length) {
    endPoint = {
      end_timestamp: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    };
  } else {
    endPoint = endPoint.reduce((prev, curr) =>
      moment(prev.end_timestamp).unix() > moment(curr.end_timestamp).unix()
        ? prev
        : curr
    );
  }
  return roundUpDateTo(
    moment(endPoint.end_timestamp),
    STEP_IN_MINUTES,
    'minutes'
  );
};

const getTicks = (mainDateStore) => mainDateStore.dateArray;

const getChartData = (ticks, attendances, shifts) => {
  const shiftsData = [];
  const attendancesData = [];
  let isMonth = false;
  if (ticks.length > 7) isMonth = true;
  ticks.forEach((timestamp) => {
    let hours = 0;
    let workedHours = 0;
    shifts
      .filter((shift) => shift.date === timestamp)
      .map((shift) => {
        hours += calculateDurationMinutes(shift.working_hours) / 60;
      });
    attendances
      .filter(
        (attendance) => attendance.start_timestamp.slice(0, 10) === timestamp
      )
      .map((attendance) => {
        workedHours +=
          calculateDurationBetweenTimestamps(
            attendance.end_timestamp,
            attendance.start_timestamp
          ) / 60;
      });

    shiftsData.push({
      x: moment(timestamp).format(isMonth ? 'D' : 'D MMM'),
      y: hours,
    });
    attendancesData.push({
      x: moment(timestamp).format(isMonth ? 'D' : 'D MMM'),
      y: workedHours,
    });
  });
  return {
    shiftsData,
    attendancesData,
  };
};

const EmployeePresenceChart = (props, context) => {
  const { attendances, shifts } = props;
  const chartMessages = defineMessages({
    plannedShifts: {
      id: 'dashboard.employeePresenceChart.plannedWorkingHours',
      defaultMessage: 'Zaplanowane godziny pracy',
    },
    attendances: {
      id: 'dashboard.employeePresenceChart.workedHours',
      defaultMessage: 'Przepracowane godziny',
    },
  });
  const ticks = props.mainDateStore.dateArray;

  const { shiftsData, attendancesData } = getChartData(
    ticks,
    attendances,
    shifts
  );

  const data = {
    labels: ticks.map((tick) =>
      moment(tick).format(ticks.length > 7 ? 'D' : 'D MMMM')
    ),
    datasets: [
      Object.assign(
        {
          label: context.intl.formatMessage(chartMessages.attendances, {}),
          data: attendancesData.map(a => a.y),
        },
        commonData,
        getColors(KADRO_REAL_COLOR)
      ),
      Object.assign(
        {
          label: context.intl.formatMessage(chartMessages.plannedShifts, {}),
          data: shiftsData.map(s => s.y),
        },
        commonData,
        getColors(KADRO_EXPECTED_COLOR)
      ),
    ],
  };

  return (
    <div className="dashboard-chart">
      <AreaChart height={400} xKey='label' data={getAreaChartData(data)} series={getAreaSeries(data)} />
    </div>
  );
};

EmployeePresenceChart.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeePresenceChart.propTypes = {
  attendances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
};

export {
  EmployeePresenceChart,
  getChartData,
  getEndPoint,
  getStartPoint,
  getTicks,
};
