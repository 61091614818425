import { defineMessages } from 'react-intl';

const messages = defineMessages({
  spmhForecast: {
    id: 'reports.spmhForecast',
    defaultMessage: 'Prognoza SPMH',
  },
  spmhRealization: {
    id: 'reports.spmhRealization',
    defaultMessage: 'Realizacja SPMH',
  },
  estimatedBudget: {
    id: 'reports.estimatedBudget',
    defaultMessage: 'Prognoza sprzedaży',
  },
  actualBudget: {
    id: 'reports.actualBudget',
    defaultMessage: 'Wykonanie sprzedaży',
  },
});

export const metadata = [
  {
    key: 'estimatedSalesPerManHour',
    color: '#FF6B01',
    chartType: 'bar',
    yAxisId: 'left',
    order: 1,
    label: messages.spmhForecast,
  },
  {
    key: 'actualSalesPerManHour',
    color: '#2C223F',
    chartType: 'bar',
    yAxisId: 'left',
    order: 2,
    label: messages.spmhRealization,
  },
  {
    key: 'estimatedBudget',
    color: '#0000FF',
    chartType: 'line',
    yAxisId: 'right',
    order: 3,
    label: messages.estimatedBudget,
  },
  {
    key: 'actualBudget',
    color: '#FF00FF',
    chartType: 'line',
    yAxisId: 'right',
    order: 4,
    label: messages.actualBudget,
  },
];

export const axisMetadata = {
  left: {
    unit: 'PLN',
  },
  right: {
    unit: 'PLN',
  },
};
