import { calculateDurationBetweenTimestamps } from './dateHelper';

const filterRelevantAttendances = (attendance, jobTitle, date, selectedLocationId) =>
  attendance.matching_shift_id &&
  attendance.location.id === selectedLocationId &&
  attendance.matching_shift_job_title.id === jobTitle.id &&
  attendance.date === date &&
  attendance.end_timestamp;

const getSumHours = (jobTitle, date, selectedLocationId, userEmployees) => {
  const result = userEmployees.reduce((sumMinutes, curEmployee) => {
    if (curEmployee.attendances) {
      const curEmployeeSum = curEmployee.attendances
        .filter(att => filterRelevantAttendances(att, jobTitle, date, selectedLocationId))
        .reduce(
          (sum, attendance) =>
            sum + calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp),
          0,
        );
      return sumMinutes + curEmployeeSum;
    }

    return sumMinutes;
  }, 0);

  return result / 60;
};

export const mapQuotasToJobtitles = (
  userEmployees,
  userJobTitles,
  mainDateStore,
  userProductionQuotas,
  selectedLocationId,
) =>
  userJobTitles.map(jobTitle => {
    const quotasData = mainDateStore.dateArray.reduce(
      (prev, date) => {
        const productionQuotaJobTitle = userProductionQuotas[date]
          ? userProductionQuotas[date].find(
              quota => quota.job_title.id === jobTitle.id && quota.location.id === parseInt(selectedLocationId),
            )
          : null;

        if (productionQuotaJobTitle && (productionQuotaJobTitle.amount || productionQuotaJobTitle.target)) {
          let newTarget = productionQuotaJobTitle.target || 0;
          if (mainDateStore.dateMode === 'month') {
            const sumHours = getSumHours(jobTitle, date, selectedLocationId, userEmployees);
            newTarget *= sumHours;
          }

          return {
            amount: prev.amount + (productionQuotaJobTitle.amount || 0),
            target: prev.target + newTarget,
            relevantDates: [...prev.relevantDates, date],
          };
        }
        return prev;
      },
      { amount: 0, target: 0, relevantDates: [] },
    );

    return {
      ...jobTitle,
      ...quotasData,
    };
  });
