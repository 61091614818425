import { memo } from 'react';

import { AvatarSvg } from '@/components/svgComponents';
import { useAppSelector } from '@/redux-store';
import { selectCurrentUserAvatar } from '@/selectors/currentUser.selectors';

import './ClosedSidebarAvatar.scss';

export const ClosedSidebarAvatar = memo(() => {
  const avatar = useAppSelector(selectCurrentUserAvatar);

  return (
    <div className="k-closedSidebarAvatar">
      {avatar ? <img src={avatar.medium} alt="avatar" /> : <AvatarSvg size={30} />}
    </div>
  );
});

ClosedSidebarAvatar.displayName = 'ClosedSidebarAvatar';
