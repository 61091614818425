import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState.jsx';
import InfoEventModalContainer from '@/components/scheduler/modals/InfoEventModal/InfoEventModal.redux.js';
import { getSortedEventsForWeek } from '@/utils/eventsHelpers';

import EventsProposalsItem from '../EventsProposalsItem/EventsProposalsItem.redux.js';

import './EventsProposals.scss';

const EventsProposals = ({ userEvents }) => {
  const relevantEvents = getSortedEventsForWeek(userEvents);
  const eventsContainerClassname = classnames('md-dashboardContainer', {
    'eventProposal__container--empty': relevantEvents.length === 0,
  });

  return (
    <>
      <InfoEventModalContainer />
      <div className={eventsContainerClassname}>
        <div className="md-dashboardContainer__title">
          <span className="material-symbols-outlined md-dashboardContainer_icon--margin">approval_delegation</span>
          <FormattedMessage id="dashboard.eventsProposal" defaultMessage="Nadchodzące wydarzenia" />
        </div>
        {relevantEvents.length > 0 ? (
          <OverlayScrollbarsComponent className="k-overlay-scrollbar md-dashboardContainer__list">
            {relevantEvents.map(event => (
              <EventsProposalsItem event={event} key={event.id} />
            ))}
          </OverlayScrollbarsComponent>
        ) : (
          <EmptyState
            name="attendanceCTA"
            className="dashboard"
            imgSrc="/img/attendanceViewCTA.png"
            emptyStateHeader=""
            emptyStateText={
              <FormattedMessage id="dashboard.eventsProposal.noEvents" defaultMessage="Brak nadchodzących wydarzeń" />
            }
            ctaText=""
          />
        )}
      </div>
    </>
  );
};

EventsProposals.propTypes = {
  userEvents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EventsProposals;
