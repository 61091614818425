import { connect } from 'react-redux';

import AbsencesViewHeaderOptions from './AbsencesViewHeaderOptions.jsx';

const mapStateToProps = state => ({
  restrictions: state.reducer.userPermissions.restrictions,
  currentUser: state.reducer.currentUser.user,
});

export default connect(mapStateToProps)(AbsencesViewHeaderOptions);
