import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Popover from '@/components/common/Popover/Popover.jsx';

import MDMultiSelectPopoverContent from './MDMultiSelectPopoverContent/MDMultiSelectPopoverContent';

import './MDMultiSelectPopover.scss';

const Y_OFFSET = -16;

const MDMultiSelectPopover = ({ disabled, label, onChange, testId, options, hasFrame }) => {
  const [filter, setFilter] = useState('');
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [dropdownListWidth, setDropdownListWidth] = useState();
  const multiSelectRef = useRef(null);

  const handleClick = () => setDropdownStatus(prev => !prev);

  const buttonClassNames = classnames('mdMultiSelectPopover__button', {
    'mdMultiSelectPopover__button--disabled': disabled,
    'mdMultiSelectPopover__button--active': dropdownStatus,
    'mdMultiSelectPopover__button--frame': hasFrame,
  });

  return (
    <Popover
      addUpdateListeners
      showShadow={false}
      hideOnBlur
      hideOnChildClick
      noArrow
      noBorder
      yOffset={Y_OFFSET}
      popoverStyle={{ padding: 0 }}
      getRef={ref => setDropdownListWidth(ref?.clientWidth)}
      setArrowStatus={setDropdownStatus}
      centerOnMobile={false}
      content={
        <MDMultiSelectPopoverContent {...{ options, testId, onChange, setFilter, filter }} width={dropdownListWidth} />
      }
      disabled={disabled}
    >
      <div className="mdMultiSelectPopover">
        <button ref={multiSelectRef} className={buttonClassNames} onClick={handleClick} data-test={testId}>
          <span>{label}</span>
          <span className="mdMultiSelectPopover__arrow" />
        </button>
      </div>
    </Popover>
  );
};

MDMultiSelectPopover.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    }),
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  hasFrame: PropTypes.string,
};

export default MDMultiSelectPopover;
