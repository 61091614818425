import PropTypes from 'prop-types';

import { checkIsEditEnable } from '../../ScheduleBudgetTable.helpers.js';
import ScheduleBudgetTablePercentEstimatesCell from './ScheduleBudgetTablePercentEstimatesCell.redux.js';
import ScheduleBudgetTablePercentEstimatesTotalCell from './ScheduleBudgetTablePercentEstimatesTotalCell.redux.js';

const ScheduleBudgetTablePercentEstimates = ({
  dateArray,
  getBudgetEstimateClassname,
  setCellError,
  onEstimateChange,
  scheduleLocationFilter,
  locationSettings,
}) => {
  const canEdit = checkIsEditEnable(scheduleLocationFilter, locationSettings);
  return (
    <tr>
      <ScheduleBudgetTablePercentEstimatesTotalCell />
      {dateArray.map(date => (
        <ScheduleBudgetTablePercentEstimatesCell
          date={date}
          key={date}
          canEdit={canEdit}
          getBudgetEstimateClassname={getBudgetEstimateClassname}
          setCellError={setCellError}
          onEstimateChange={onEstimateChange}
        />
      ))}
    </tr>
  );
};

ScheduleBudgetTablePercentEstimates.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({
    budget_estimates_period: PropTypes.string,
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  getBudgetEstimateClassname: PropTypes.func,
  setCellError: PropTypes.func,
  onEstimateChange: PropTypes.func,
};

export default ScheduleBudgetTablePercentEstimates;
