import { PropTypes } from 'prop-types';

import { intercomShowNewMessage } from '@/utils/intercomHelpers';

import AppBlocked from '../AppBlocked/AppBlocked.redux.js';
import { messages } from './AppBlockedWithoutPaymentOption.messages.js';

const AppBlockedWithoutPaymentOption = (_, { intl }) => (
  <AppBlocked
    handleButtonClick={() => {
      intercomShowNewMessage(intl.formatMessage(messages.intercomMessage));
    }}
    titleMessage={messages.title}
    contentMessage={messages.content}
    buttonText={messages.button}
    imgSrc="img/blockedAccount.png"
    alt={intl.formatMessage(messages.blockedAccountImgAltText)}
  />
);

AppBlockedWithoutPaymentOption.propTypes = {};
AppBlockedWithoutPaymentOption.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AppBlockedWithoutPaymentOption;
