import PropTypes from 'prop-types';

import { checkIsEditEnable } from '../../ScheduleBudgetTable.helpers.js';
import ScheduleBudgetTableEstimatesCell from './ScheduleBudgetTableEstimatesCell.redux.js';
import ScheduleBudgetTableEstimatesTotalCell from './ScheduleBudgetTableEstimatesTotalCell.redux.js';

const ScheduleBudgetTableEstimates = ({
  dateArray,
  locationSettings,
  scheduleLocationFilter,
  setCellError,
  onEstimateChange,
  getClassName,
}) => {
  const canEdit = checkIsEditEnable(scheduleLocationFilter, locationSettings);

  return (
    <tr>
      <ScheduleBudgetTableEstimatesTotalCell />
      {dateArray.map(date => (
        <ScheduleBudgetTableEstimatesCell
          key={date}
          date={date}
          canEditBudget={canEdit}
          setCellError={setCellError}
          onEstimateChange={onEstimateChange}
          getClassName={getClassName}
        />
      ))}
    </tr>
  );
};

ScheduleBudgetTableEstimates.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({
    budget_estimates_period: PropTypes.string,
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  setCellError: PropTypes.func,
  onEstimateChange: PropTypes.func,
  getClassName: PropTypes.func,
};

export default ScheduleBudgetTableEstimates;
