import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AvatarSvg } from '@/components/svgComponents';
import { getRoleToShow } from '@/utils/accounts/accounts.utils.ts';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { UseMultiAccountsMenuValuesType } from '../useMultiAccountsMenu';
import { InstanceLabel } from './InstanceLabel/InstanceLabel';
import { useMultiAccountsInstance } from './useMultiAccountsInstance';

import './MultiAccountsInstance.scss';

type Props = {
  accountDetail: UseMultiAccountsMenuValuesType['accountsDetails'][number];
  setDefaultAccount: UseMultiAccountsMenuValuesType['setDefaultAccount'];
};

export const MultiAccountsInstance = memo(({ accountDetail, setDefaultAccount }: Props) => {
  const intl = useIntl();
  const { additionalJobTitlesAmount, additionalLocationsAmount, onSwitchAccount, openModal, roleName } =
    useMultiAccountsInstance(accountDetail);

  const { avatarSrc, isAccountActive, jobTitlesNames, locationsNames } = accountDetail;
  const roleToShow = getRoleToShow(roleName, intl);

  return (
    <div className="k-multiAccountsInstance" role="presentation" onClick={onSwitchAccount}>
      <InstanceLabel {...{ setDefaultAccount, accountDetail }} />
      <div className="k-multiAccountsInstance__avatar">
        {avatarSrc ? <img src={avatarSrc} alt="avatar" /> : <AvatarSvg size={32} />}
      </div>
      <div className="k-multiAccountsInstance__details">
        <div className="k-multiAccountsInstance__detailRow k-multiAccountsInstance__title">
          {isEmptyArray(locationsNames) ? (
            <FormattedMessage id="location.notFoundLocation" defaultMessage="Brak lokalizacji" />
          ) : (
            <>
              <div className="k-multiAccountsInstance__title--short">{locationsNames[0]}</div>
              <div>{additionalLocationsAmount}</div>
            </>
          )}
        </div>
        <div className="k-multiAccountsInstance__detailRow k-multiAccountsInstance__title">
          {isEmptyArray(jobTitlesNames) ? (
            <FormattedMessage id="jobTitle.notFoundJobTitle" defaultMessage="Brak stanowiska" />
          ) : (
            <>
              <div className=" k-multiAccountsInstance__title--short">{jobTitlesNames[0]}</div>
              <div>{additionalJobTitlesAmount}</div>
            </>
          )}
        </div>
        <div className="k-multiAccountsInstance__subTitle">{roleToShow}</div>
        <div className="k-multiAccountsInstance__link" onClick={openModal} role="presentation">
          <FormattedMessage id="multiAccounts.moreDetails" defaultMessage="Więcej szczegółów..." />
        </div>
      </div>
      <div className="k-multiAccountsInstance__check">{isAccountActive && <i className="material-icons">check</i>}</div>
    </div>
  );
});

MultiAccountsInstance.displayName = 'MultiAccountsInstance';
