import moment from 'moment';
import PropTypes from 'prop-types';

import DateTimeInput from '@/components/common/inputs/DateTimeInput/DateTimeInput';
import { KadroMultiSelect, MDDatesPicker, MDTextInput, MDTimeInput } from '@/components/common/inputs/MDComponents';
import Select from '@/components/common/inputs/MDSelect/Select';
import { MEDIUM_PADDING_MODIFIER, MODAL_MODIFIER, POSITION_FOR_TOGGLE_MODIFIER } from '@/constants/modalModifiers';
import { CustomFieldType, ParsedCustomField } from '@/types/customFields.types';
import { isDateRangeObject } from '@/utils/customFields/customFields';

import './customFieldsTab.scss';

interface CustomFieldsTabProps {
  customFields: ParsedCustomField[];
}

const CustomFieldsTab = ({ customFields }: CustomFieldsTabProps) => {
  const booleanTypeFields = customFields.filter(({ type }) => type === CustomFieldType.boolean);

  return (
    <div className="k-customFieldsTab">
      <div className="k-customFieldsTab__section k-customFieldsTab__section--extraMargin">
        {booleanTypeFields.map(({ key, value, name }) => (
          <MDTextInput
            key={key}
            id={`textInput--${key}`}
            value={name}
            type="text"
            modifiers={[MODAL_MODIFIER, POSITION_FOR_TOGGLE_MODIFIER, MEDIUM_PADDING_MODIFIER]}
            readOnly
            withToogle
            toggleValue={value}
            tooltipClassName="k-customFieldsTab__tooltip--wide"
            maxLabelLength={60}
            maxInputLength={57}
            className="k-customFieldsTab__textInput"
          />
        ))}
      </div>

      {customFields.map(({ id, key, name, value, type, settings }) => {
        if (type === CustomFieldType.date && typeof value === 'string')
          return (
            <div key={key} className="k-customFieldsTab__section">
              <MDDatesPicker
                key={id}
                id={`date--${id}`}
                values={[value]}
                label={name}
                singleDate
                position="bottom"
                readOnly
                tooltipClassName="k-customFieldsTab__tooltip--wide"
                maxLabelLength={60}
              />
            </div>
          );

        if (type === CustomFieldType.dateTime && typeof value === 'string') {
          const date = moment(value).format('YYYY-MM-DD');
          const time = moment(value).format('HH:mm');
          return (
            <div key={key} className="k-customFieldsTab__section k-customFieldsTab__section--flex">
              <MDDatesPicker
                key={id}
                id={`date--${id}`}
                values={[date]}
                label={name}
                singleDate
                position="bottom"
                readOnly
                className="k-customFieldsTab__dateAndTime"
                tooltipClassName="k-customFieldsTab__tooltip--wide"
                maxLabelLength={60}
              />

              <MDTimeInput
                value={time}
                modifiers={[MODAL_MODIFIER]}
                short
                readOnly
                className="k-customFieldsTab__dateAndTime"
              />
            </div>
          );
        }

        if (type === CustomFieldType.dateRange && isDateRangeObject(value)) {
          const fromDate = value.from;
          const toDate = value.to;

          return (
            <div key={key} className="k-customFieldsTab__section">
              <MDDatesPicker
                key={id}
                id={`date--${id}`}
                customDisplayValue={`${toDate}  -  ${fromDate}`}
                label={name}
                singleDate
                position="bottom"
                readOnly
                tooltipClassName="k-customFieldsTab__tooltip--wide"
                maxLabelLength={60}
              />
            </div>
          );
        }

        if (type === CustomFieldType.dateTimeRange && typeof value === 'object' && isDateRangeObject(value)) {
          const dateFrom = moment(value.from).format('YYYY-MM-DD');
          const timeFrom = moment(value.from).format('HH:mm');
          const dateTo = moment(value.to).format('YYYY-MM-DD');
          const timeTo = moment(value.to).format('HH:mm');

          return (
            <div key={key} className="k-customFieldsTab__section k-customFieldsTab__section--flex">
              <DateTimeInput
                className="k-customFieldsTab__dateTimeInput"
                label={name}
                date={dateFrom}
                time={timeFrom}
                tooltipClassName="k-customFieldsTab__tooltip--narrow"
                maxLabelLength={30}
              />
              <DateTimeInput
                className="k-customFieldsTab__dateTimeInput"
                label={name}
                date={dateTo}
                time={timeTo}
                tooltipClassName="k-customFieldsTab__tooltip--narrow"
                maxLabelLength={30}
              />
            </div>
          );
        }

        if (
          (typeof value === 'string' && CustomFieldType.text) ||
          (type === CustomFieldType.number && typeof value === 'number')
        )
          return (
            <div key={key} className="k-customFieldsTab__section">
              <MDTextInput
                id={`number--${id}`}
                value={value}
                type="text"
                label={name}
                modifiers={[MODAL_MODIFIER]}
                readOnly
                tooltipClassName="k-customFieldsTab__tooltip--wide"
                maxLabelLength={60}
                maxInputLength={57}
              />
            </div>
          );

        if (type === CustomFieldType.select && settings.maxSelectedOptions === 1)
          return (
            <div key={key} className="k-customFieldsTab__section">
              <Select
                key={id}
                name={name}
                id={`single_select--${id}`}
                withSearch
                options={settings.options}
                defaultValue={value[0].value}
                closeOnClick
                readOnly
                maxInputLength={52}
              />
            </div>
          );

        if (type === CustomFieldType.select && settings.maxSelectedOptions > 1) {
          const messages = {
            footerTitle: name,
          };

          return (
            <div key={key} className="k-customFieldsTab__section">
              <KadroMultiSelect
                messages={messages}
                withoutSelectAll
                withoutDeselectAll
                withoutDropdown
                withoutHeader
                items={settings.options}
                //@ts-ignore 
                selectedItems={value}
                readOnly
                autoHeight
                footerModifiers={[MODAL_MODIFIER]}
                footerTitleTooltipMessage={name}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

CustomFieldsTab.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default CustomFieldsTab;
