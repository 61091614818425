import {
  ACTIVATE_VERIFY_WORKING_RULES_BUTTON,
  CLEAR_WORKING_RULES,
  CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS,
  DEACTIVATE_VERIFY_WORKING_RULES_BUTTON,
  GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS,
  GET_WORKING_RULES_VIOLATIONS_SUCCESS,
  UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS,
} from '@/constants/ActionTypes.js';
import { arrayToArrayMapByKey } from '@/utils/baseHelpers';

const initialState = {
  employeeViolations: [],
  violationsPerEmployee: {},
  isVerifyWorkingRulesButtonActive: false,
};

const workingRules = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKING_RULES_VIOLATIONS_SUCCESS:
    case UPDATE_WORKING_RULES_VIOLATIONS_SUCCESS: {
      return {
        ...state,
        employeeViolations: action.payload.employeeViolations,
        violationsPerEmployee: arrayToArrayMapByKey(action.payload.employeeViolations, 'employee_id'),
      };
    }
    case GET_WORKING_RULES_VIOLATIONS_FOR_EMPLOYEE_SUCCESS: {
      const employeeViolations = [
        ...state.employeeViolations.filter(violation => violation.employee_id !== action.payload.employeeId),
        ...action.payload.employeeViolations,
      ];
      return {
        ...state,
        employeeViolations,
        violationsPerEmployee: arrayToArrayMapByKey(employeeViolations, 'employee_id'),
      };
    }
    case ACTIVATE_VERIFY_WORKING_RULES_BUTTON:
      return {
        ...state,
        isVerifyWorkingRulesButtonActive: true,
      };
    case DEACTIVATE_VERIFY_WORKING_RULES_BUTTON:
      return {
        ...state,
        isVerifyWorkingRulesButtonActive: false,
      };
    case CLEAR_WORKING_RULES:
      return {
        ...state,
        employeeViolations: [],
        violationsPerEmployee: {},
      };
    case CLEAR_WORKING_RULES_FOR_EMPLOYEE_SUCCESS: {
      const updatedViolationsPerEmployee = Object.fromEntries(
        Object.entries(state.violationsPerEmployee).filter(([key]) => key !== action.payload),
      );

      return {
        ...state,
        violationsPerEmployee: updatedViolationsPerEmployee,
      };
    }
    default:
      return state;
  }
};

export default workingRules;
