import PropTypes from 'prop-types';

import EmptyFilterView from '../EmptyFilterView/EmptyFilterView.redux';
import EmptyStateNoEmployeesView from '../EmptyStateNoEmployeesView/EmptyStateNoEmployeesView.redux.js';

import './emptyStateScheduleView.scss';

const EmptyStateScheduleView = ({ noDefinedEmployees }) => (
  <div className="k-emptyStateScheduleViewContainer">
    {noDefinedEmployees ? <EmptyStateNoEmployeesView /> : <EmptyFilterView />}
  </div>
);

EmptyStateScheduleView.propTypes = {
  noDefinedEmployees: PropTypes.bool,
};

export default EmptyStateScheduleView;
