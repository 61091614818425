import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import ScheduleShiftBlock from '@/components/scheduler/ScheduleTable/ScheduleShiftBlock/ScheduleShiftBlock.jsx';
import { FREEMIUM_HIDE_TRADE_SHIFTS } from '@/constants/Restrictions.js';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { getShiftPopover } from '@/utils/shiftHelpers';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

const EmployeeScheduleShift = (props, { intl }) => {
  const {
    shift,
    dateArray,
    employee,
    userLocations,
    openRequestTradeShiftModal,
    currentUserId,
    tradeShifts,
    restrictions,
    isTradeShiftEnabled,
    employeeContracts,
  } = props;
  const color = shift.job_title?.color || '#FF0000';
  const [from, to] = shift.working_hours.split('-');
  const scheduleBlockSize = dateArray.length > 7 ? 'month' : 'week';
  const jobTitleNotAssigned = useMemo(() => {
    const relevantContract = employeeContracts ? getRelevantContractForDate(employeeContracts, shift.date) : undefined;
    const jobTitleIds = relevantContract ? getJobTitleIdsFromContracts([relevantContract]) : [];
    return !jobTitleIds.find(jobTitleId => jobTitleId === String(shift.job_title.id));
  }, [employeeContracts, shift.date, shift.job_title.id]);
  const employeeName = useMemo(() => getEmployeeNameShort(employee, 17), [employee]);

  const shiftLocation = useMemo(() => {
    if (shift.location.name) return shift.location;
    return userLocations.find(location => String(location.id) === String(shift.location.id));
  }, [shift.location, userLocations]);

  const showTradeShifts = isTradeShiftEnabled && !restrictions.includes(FREEMIUM_HIDE_TRADE_SHIFTS);
  const isRequestTradeDisabled =
    !showTradeShifts ||
    shift.otherLocation ||
    employee.id !== currentUserId ||
    tradeShifts.some(tradeShift => tradeShift.shift.id === shift.id);

  const onClickShift = useCallback(() => {
    if (isRequestTradeDisabled) return;
    openRequestTradeShiftModal(shift);
  }, [shift, isRequestTradeDisabled]);

  const shiftBlock = (
    <ScheduleShiftBlock
      color={color}
      from={from}
      to={to}
      size={scheduleBlockSize}
      onClick={onClickShift}
      title={shift.job_title.title}
      isEmployee
      isEditDisabled={isRequestTradeDisabled}
      isFromOtherLocation={shift.otherLocation}
      hasComment={shift.comment?.length > 0}
    />
  );
  const shouldShowNoteInPopover = employee.id === currentUserId;

  const popover = getShiftPopover(
    employeeName,
    shift.date,
    shift.comment,
    shift.job_title.title,
    jobTitleNotAssigned,
    shift.otherLocation,
    shiftLocation?.name,
    intl,
    shift.isLoaned,
    shouldShowNoteInPopover,
    shift.isOpenShiftFromLocationGroups,
  );

  const showOverlay = popover.content && (shift.otherLocation || shift.comment?.length > 0);

  return (
    <div>
      {showOverlay ? (
        <HoverPopOver popover={{ ...popover, content: popover.content.map((info, i) => <div key={i}>{info}</div>) }}>
          <div>{shiftBlock}</div>
        </HoverPopOver>
      ) : (
        shiftBlock
      )}
    </div>
  );
};

EmployeeScheduleShift.contextTypes = {
  intl: PropTypes.shape(),
};

EmployeeScheduleShift.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  shift: PropTypes.shape({
    id: PropTypes.string,
    otherLocation: PropTypes.bool,
    working_hours: PropTypes.string,
    date: PropTypes.string,
    comment: PropTypes.string,
    job_title: PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    isLoaned: PropTypes.string,
    isOpenShiftFromLocationGroups: PropTypes.bool,
  }),
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  openRequestTradeShiftModal: PropTypes.func,
  currentUserId: PropTypes.string,
  tradeShifts: PropTypes.arrayOf(PropTypes.shape({})),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  isTradeShiftEnabled: PropTypes.bool,
};

export default EmployeeScheduleShift;
