import PropTypes from 'prop-types';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { locationSettingsPath, settingsEmployeeRoutes, settingsRoutes } from '@/constants/settingsRoutes';

import HelpModal from './settings/SettingsHelpModal.jsx';
import TopBarTabSettings from './TopBars/TopBarTabSettings.jsx';

const SettingsView = props => {
  const {
    userPermissions: { permissions, restrictions, roles, isEmployee },
    enablePayu,
  } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const routesArray = isEmployee ? settingsEmployeeRoutes : settingsRoutes(enablePayu);

  const filteredRoutes = routesArray.filter(route => {
    const isRestrictedByPermissions = route.permission && !permissions?.includes(route.permission);
    const isRestrictedByRestrictions = route.restriction
      ? route.restriction && restrictions?.includes(route.restriction)
      : restrictions?.some(restriction => route.restrictions?.includes(restriction));
    const isRestrictedByRoles = route.roles && roles.every(userRole => !route.roles.includes(userRole));
    return !(isRestrictedByPermissions || isRestrictedByRestrictions || isRestrictedByRoles || route.isHidden);
  });

  const isRouteAllowed = filteredRoutes.find(
    ({ path }) => path === pathname || (path === locationSettingsPath && pathname.startsWith(locationSettingsPath)),
  );
  if (!isRouteAllowed) {
    navigate('/settings/user-data');
  }

  return (
    <div>
      <TopBarTabSettings
        activePath={pathname}
        userPermissions={props.userPermissions}
        filteredRoutes={filteredRoutes}
      />
      <HelpModal path={pathname} />
      <Outlet />
    </div>
  );
};

SettingsView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
    isEmployee: PropTypes.bool,
  }),
  enablePayu: PropTypes.bool,
};

export default SettingsView;
