import { conn } from '@/actions';

export const pullAvailabilitiesForEmployee = (employeeId, from, to, locationIds = [], requestType) => {
  const locationsQuery = locationIds.length ? `&locations[]=${locationIds.join('&locations[]=')}` : '';

  return conn.getJSON(
    `${conn.base_url}/employees/${employeeId}/availability-blocks?from=${from}&to=${to}${locationsQuery}`,
    requestType,
  );
};

export const pullAvailabilitiesForLocation = (locationId, from, to, employeeIds = [], requestType) => {
  const employeesQuery = employeeIds.length ? `&employees[]=${employeeIds.join('&employees[]=')}` : '';

  return conn.getJSON(
    `${conn.base_url}/locations/${locationId}/availability-blocks?from=${from}&to=${to}${employeesQuery}`,
    requestType,
  );
};

export const pullAvailabilities = (from, to, locationIds = [], employeeIds = [], requestType) => {
  const locationsQuery = locationIds.length ? `&locations[]=${locationIds.join('&locations[]=')}` : '';
  const employeesQuery = employeeIds.length ? `&employees[]=${employeeIds.join('&employees[]=')}` : '';

  return conn.getJSON(
    `${conn.base_url}/availability-blocks?from=${from}&to=${to}${locationsQuery}${employeesQuery}`,
    requestType,
  );
};

export const pullAvailability = (availabilityId, requestType) =>
  conn.getJSON(`${conn.base_url}/availability-blocks/${availabilityId}`, requestType);
