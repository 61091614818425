import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import PresentationWrapper from '@/components/common/PresentationWrapper.jsx';
import PublishButton from '@/components/common/PublishButton.jsx';
import HelpModal from '@/containers/HelpModalContainer.js';

import PositionScheduleShift from '../PositionScheduleShift.jsx';

const ScheduleHelpModal = props => (
  <HelpModal
    {...props}
    helpCenterLink="http://pomoc.kadromierz.pl/manager#zakladka-tworzenie-grafiku-pracy"
    title={<FormattedMessage id="common.schedule" defaultMessage="Grafik pracy" />}
  >
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowRight" defaultMessage="Strzałka w prawo" />
        </strong>
      </td>
      <td>
        <FormattedMessage id="help.goToNextSpanInCalendar" defaultMessage="Przejdź do następnego okresu w kalendarzu" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowLeft" defaultMessage="Strzałka w lewo" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.goToPrevSpanInCalendar"
          defaultMessage="Przejdź do poprzedniego okresu w kalendarzu"
        />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
          <br />
          <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.zoomDescription"
          defaultMessage='  Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.locations" defaultMessage="Lokalizacje" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.locationsDesc"
          defaultMessage="Wybierz lokalizację z listy dla której chcesz wyświetlić grafik"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.jobTitles" defaultMessage="Stanowiska" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.jobTitlesDesc" defaultMessage="Filtrowanie wyświetlanych na grafiku stanowisk" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.sort" defaultMessage="Sortuj" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.sortDesc" defaultMessage="Sortuj grafik pracy" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.returnToToday" defaultMessage="Powrót do dzisiaj" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.returnToTodayDesc" defaultMessage="Pokaż grafik zawierający dzisiejszy dzień" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="help.displayType" defaultMessage="Tryb wyświetlania" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.displayType.choose" defaultMessage="Wybierz jeden z następujących trybów:" />
        <br />
        <FormattedMessage
          id="help.displayType.shifts"
          defaultMessage="- Zmiany - grafik ze zmianami dla poszczególnych pracowników"
        />
        <br />
        <FormattedMessage
          id="help.displayType.availabilities"
          defaultMessage="- Dostępności - grafik z dostępnościami poszczególnych pracowników"
        />
        <br />
        <FormattedMessage
          id="help.displayType.positions"
          defaultMessage="- Stanowiska - grafik pokazujący pracowników na danym stanowisku"
        />
        <br />
        <FormattedMessage
          id="help.displayType.templates"
          defaultMessage="- Szablony - tworzenie szablonów do automatycznego układania grafiku"
        />
        <br />
      </td>
    </tr>
    <tr>
      <td>◀</td>
      <td>
        <FormattedMessage id="help.prevSpan" defaultMessage="Poprzedni okres" />
      </td>
    </tr>
    <tr>
      <td>▶</td>
      <td>
        <FormattedMessage id="help.nextSpan" defaultMessage="Następny okres" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.clearSpan" defaultMessage="Wyczyść bieżący widok" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.clearSpanDesc"
          defaultMessage="Naciśnięcie powoduje usunięcie zmian w wyświetlanym okresie"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.clearUnpublishedShifts" defaultMessage="Usuń nieopublikowane zmiany" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.clearUnpublishedShiftsDesc"
          defaultMessage="Naciśnięcie powoduje usunięcie nieopublikowanych zmian w wyświetlanym okresie"
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <PublishButton text={<FormattedMessage id="common.publish" defaultMessage="Publikuj" />} />
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.publishDesc"
          defaultMessage='Kliknji przycisk "Publikuj" aby otworzyć panel publikacji zmian w grafiku"'
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.duplicatePrevSpan" defaultMessage="Duplikuj poprzedni okres" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.duplicatePrevSpanDesc"
          defaultMessage="Naciśnięcie powoduje skopiowanie zmian z porzedniego okresu. Obecne zmiany w tym okresie zostaną usunięte"
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-blue small">
            <i className="material-icons">build</i>
            <FormattedMessage id="generate" defaultMessage="Generuj" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.generateDesc"
          defaultMessage='Kliknji przycisk "Generuj" aby automatycznie wygenerować grafik na dany okres'
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-red small">
            <i className="material-icons">delete</i>
            <FormattedMessage id="delete" defaultMessage="Usuń" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage id="help.deleteDesc" defaultMessage='Kliknji przycisk "Usuń" aby usunąc zaznaczone zmiany' />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-blue small">
            <i className="material-icons">content_copy</i>
            <FormattedMessage id="copy" defaultMessage="Kopiuj" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.copyDesc"
          defaultMessage='Kliknji przycisk "Kopiuj" aby skopiować zaznaczone zmiany'
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-orange small">
            <i className="material-icons">content_paste</i>
            <FormattedMessage id="paste" defaultMessage="Wklej" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.pasteDesc"
          defaultMessage='Kliknji przycisk "Wklej" aby wkleić wcześniej zaznaczone zmiany'
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-blue small">
            <i className="material-icons">content_paste</i>
            <FormattedMessage id="unmark" defaultMessage="Odznacz" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.deselectDesc"
          defaultMessage='Kliknji przycisk "Odznacz" aby odznaczyć zaznaczone wiersze lub kolumny'
        />
      </td>
    </tr>
    <tr>
      <td>
        <PresentationWrapper>
          <Button modifiers="reverse-blue small">
            <i className="material-icons mirrorFlip">reply</i>
            <FormattedMessage id="export" defaultMessage="Eksport" />
          </Button>
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.exportDesc"
          defaultMessage='Kliknji przycisk "Eksport" aby pobrać grafik w wybranym formacie'
        />
      </td>
    </tr>
    <tr>
      <td>
        <div style={{ width: '150px', display: 'inline-block' }}>
          <PositionScheduleShift
            title={<FormattedMessage id="common.jobTitle" defaultMessage="Stanowisko" />}
            shift={{ job_title: { color: '#00b8d4' }, working_hours: '07:00-15:00' }}
            employee_name={<FormattedMessage id="common.johnDoe" defaultMessage="Jan Nowak" />}
            blocked={false}
            handleAddAction={() => {}}
            handleEditAction={() => {}}
            handleDeleteAction={() => {}}
          />
        </div>
      </td>
      <td>
        <FormattedMessage
          id="help.pressBlockToEdit"
          defaultMessage="Naciśnij na bloczek zmiany aby edytować zmianę. Po najechaniu pojawią sie dwie ikonki."
        />
        <br />
        <i className="material-icons">add</i>
        <FormattedMessage id="help.pressToAddShift" defaultMessage=" - Naciśnij aby dodać kolejną zmianę" />
        <br />
        <i className="material-icons">delete</i>
        <FormattedMessage id="help.pressToDeleteShift" defaultMessage=" - Naciśnij aby usunąć zmianę" />
      </td>
    </tr>
  </HelpModal>
);

ScheduleHelpModal.propTypes = {};

export default ScheduleHelpModal;
