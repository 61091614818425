import { IntlShape } from 'react-intl';

import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate';
import { toggleSettingByType } from '@/actions/schedule';
import {
  navigateUsingArrows,
  quickPlanningClearSelection,
  quickPlanningCopy,
  quickPlanningPaste,
  startInsertingWorkingHoursForNewShift,
  toggleQuickPlanningSelection,
  toogleQuickPlanningScheduleMode,
} from '@/actions/schedule/quickPlanning';
import { startInsertingShortcutForNewFreeDay } from '@/actions/schedule/quickPlanning/freeDaysQP';
import { toggleShortcutsModal } from '@/actions/schedule/scheduleView';
import { deleteFromSelectedCells } from '@/actions/schedule/selectedCells';
import { toggleScheduleAvailabilityMode } from '@/actions/schedule/viewMode';
import { changeSendPanelVisibility } from '@/actions/uiState';
import { SCHEDULE_SETTING_TYPE } from '@/constants/settingsConstants';
import { AppDispatch } from '@/redux-store';
import { getFreeDaysKeyboardShortcutsFirstLetter } from '@/utils/freeDaysMarking/freeDaysMarking';

type Bind = {
  shortcut: string | string[];
  func: (e: KeyboardEvent) => void;
  keyEvent: 'keydown' | 'keyup';
};

type OptionsArg = {
  disabledQuickPlanning: boolean;
  isQuickPlanningEnabled: boolean;
};

export const getInitBinds = (
  dispatch: AppDispatch,
  intl: IntlShape,
  { disabledQuickPlanning, isQuickPlanningEnabled }: OptionsArg,
): Bind[] => [
  {
    shortcut: 'b',
    func: () => dispatch(toggleSettingByType(SCHEDULE_SETTING_TYPE.BUDGET)),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'o',
    func: () => dispatch(toggleSettingByType(SCHEDULE_SETTING_TYPE.OPEN_SHIFTS)),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'p',
    func: () => dispatch(changeSendPanelVisibility(true)),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'backspace',
    func: () => dispatch(deleteFromSelectedCells()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'del',
    func: () => dispatch(deleteFromSelectedCells()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'a',
    func: () => dispatch(toggleScheduleAvailabilityMode()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'k',
    func: () => !disabledQuickPlanning && dispatch(toogleQuickPlanningScheduleMode()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'shift+left',
    func: () => dispatch(navigateUsingArrows('shift+left')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'shift+up',
    func: () => dispatch(navigateUsingArrows('shift+up')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'shift+right',
    func: () => dispatch(navigateUsingArrows('shift+right')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'shift+down',
    func: () => dispatch(navigateUsingArrows('shift+down')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'left',
    func: () => dispatch(navigateUsingArrows('left')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'left',
    func: () => {
      if (!isQuickPlanningEnabled) dispatch(mainDateMoveLeft());
    },
    keyEvent: 'keyup',
  },
  {
    shortcut: 'up',
    func: () => dispatch(navigateUsingArrows('up')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'right',
    func: () => dispatch(navigateUsingArrows('right')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'right',
    func: () => {
      if (!isQuickPlanningEnabled) dispatch(mainDateMoveRight());
    },
    keyEvent: 'keyup',
  },
  {
    shortcut: 'down',
    func: () => dispatch(navigateUsingArrows('down')),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'enter',
    func: () => dispatch(toggleQuickPlanningSelection()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'ctrl+c',
    func: () => dispatch(quickPlanningCopy()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'command+c',
    func: () => dispatch(quickPlanningCopy()),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'ctrl+v',
    func: () => dispatch(quickPlanningPaste()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'command+v',
    func: () => dispatch(quickPlanningPaste()),
    keyEvent: 'keydown',
  },
  {
    shortcut: 'space',
    func: () => dispatch(toggleShortcutsModal()),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'space',
    func: e => {
      e.preventDefault();
    },
    keyEvent: 'keydown',
  },
  {
    shortcut: Array.from({ length: 10 }, (_, i) => i.toString()),
    func: ({ key }) => dispatch(startInsertingWorkingHoursForNewShift(key)),
    keyEvent: 'keyup',
  },
  {
    shortcut: getFreeDaysKeyboardShortcutsFirstLetter(intl),
    func: ({ key }) => dispatch(startInsertingShortcutForNewFreeDay(key)),
    keyEvent: 'keyup',
  },
  {
    shortcut: 'esc',
    func: () => dispatch(quickPlanningClearSelection()),
    keyEvent: 'keyup',
  },
];
