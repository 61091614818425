/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  mergeInDay: {
    id: 'exportPayroll.mergeInDay',
    defaultMessage: 'Połącz obecności dla danego dnia',
  },
  mergeInDayTooltip: {
    id: 'exportPayroll.mergeInDayTooltip',
    defaultMessage:
      'Sumuje czas obecności w sytuacji w której pracownik zarejestrował dwie lub więcej obecności w trakcie jednej doby.',
  },
  freeDays: {
    id: 'exportPayroll.freeDays',
    defaultMessage: 'Dni wolne',
  },
  freeDaysTooltip: {
    id: 'exportPayroll.freeDaysTooltip',
    defaultMessage:
      'W raporcie będą widoczne wszystkie dni z wybranego okresu, również te w których pracownik nie miał utworzonej zmiany, obecności oraz nieobecności.',
  },
  availabilities: {
    id: 'exportPayroll.availabilities',
    defaultMessage: 'Dostępności',
  },
  availabilitiesTooltip: {
    id: 'exportPayroll.availabilitiesTooltip',
    defaultMessage: 'Wygenerowane zostaną godziny dostępności.',
  },
  absences: {
    id: 'exportPayroll.absences',
    defaultMessage: 'Nieobecności (szczegółowe)',
  },
  absencesTooltip: {
    id: 'exportPayroll.absencesTooltip',
    defaultMessage: 'Wygenerowane zostaną kolumny ze wszystkimi typami nieobecności wraz z godzinami.',
  },
  unfinalized: {
    id: 'exportPayroll.unfinalized',
    defaultMessage: 'Niezakończone obecności',
  },
  unfinalizedTooltip: {
    id: 'exportPayroll.unfinalizedTooltip',
    defaultMessage: 'W raporcie będą widoczne obecności, które nie zostały jeszcze zakończone.',
  },
  overtime: {
    id: 'exportPayroll.overtime',
    defaultMessage: 'Nadgodziny',
  },
  overtimeTooltip: {
    id: 'exportPayroll.overtimeTooltip',
    defaultMessage:
      'W raporcie będą widoczne nadgodziny dobowe 50%, 100%, nadgodziny potencjalnie średniotygodniowe oraz dopełnienia.',
  },
  attendanceGPS: {
    id: 'exportPayroll.attendanceGPS',
    defaultMessage: 'GPS Obecności',
  },
  attendanceGPSTooltip: {
    id: 'exportPayroll.attendanceGPSTooltip',
    defaultMessage: 'W raporcie będą widoczne odległości GPS dla zarejestrowanych obecności.',
  },
  addExtraStats: {
    id: 'exportPayroll.addExtraStats',
    defaultMessage: 'Dodatkowe statystyki',
  },
  addPlaceForSignature: {
    id: 'exportPayroll.addPlaceForSignature',
    defaultMessage: 'Miejsce na podpisy',
  },
  basicVersion: {
    id: 'exportPayrollLocation.basicVersion',
    defaultMessage: 'Wersja podstawowa',
  },
  basicVersionDescription: {
    id: 'exportPayrollLocation.basicVersionDescription',
    defaultMessage: 'Pobrany plik będzie zawierał sumaryczne zestawienie z wybranego okresu dla wybranych pracowników',
  },
  extendedVersion: {
    id: 'exportPayrollLocation.extendedVersion',
    defaultMessage: 'Wersja rozszerzona',
  },
  extendedVersionDescription: {
    id: 'exportPayrollLocation.extendedVersionDescription',
    defaultMessage:
      'Pobrany plik będzie zawierał zestawienie pojedynczych dni, które pojawiły się w danym okresie dla wybranych pracowników',
  },
  noAdditional: {
    id: 'exportPayrollLocation.noAdditional',
    defaultMessage: 'Bez nieobecności i dostępności',
  },
  extraColumns: {
    id: 'exportPayrollLocation.extraColumns',
    defaultMessage: 'Dodatkowe kolumny',
  },
  deleteAll: {
    id: 'exportPayrollLocation.deleteAll',
    defaultMessage: 'Usuń wszystkie',
  },
  add: {
    id: 'exportPayrollLocation.add',
    defaultMessage: 'Dodaj',
  },
  showOvertime: {
    id: 'exportPayrollLocation.showOvertime',
    defaultMessage: 'Nadgodziny',
  },
  showOvertimeTooltip: {
    id: 'exportPayrollLocation.showOvertimeTooltip',
    defaultMessage: 'Do eksportu zostaną dodane informacje o nadgodzinach.',
  },
  reverseStructure: {
    id: 'exportOptions.reverseStructure',
    defaultMessage: 'Grupuj po stanowiskach',
  },
  enovaAbsences: {
    id: 'exports.options.enovaAbsences',
    defaultMessage: 'Nieobecności',
  },
  enovaWorkTime: {
    id: 'exports.options.enovaWorkTime',
    defaultMessage: 'Czas pracy',
  },
});

export const columnsPdfMessages = defineMessages({
  day: {
    id: 'exportPayroll.columnsPdfMessages.day',
    defaultMessage: 'Dzień',
  },
  typeOfDay: {
    id: 'exportPayroll.columnsPdfMessages.typeOfDay',
    defaultMessage: 'Typ dnia',
  },
  location: {
    id: 'exportPayroll.columnsPdfMessages.location',
    defaultMessage: 'Lokalizacja',
  },
  jobTitle: {
    id: 'exportPayroll.columnsPdfMessages.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  wage: {
    id: 'exportPayroll.columnsPdfMessages.wage',
    defaultMessage: 'Stawka',
  },
  fromToSchedule: {
    id: 'exportPayroll.columnsPdfMessages.fromToSchedule',
    defaultMessage: 'Od/Do Grafik',
  },
  fromTo: {
    id: 'exportPayroll.columnsPdfMessages.fromTo',
    defaultMessage: 'Godziny pracy',
  },
  workTime: {
    id: 'exportPayroll.columnsPdfMessages.workTime',
    defaultMessage: 'Suma godzin',
  },
  nightTime: {
    id: 'exportPayroll.columnsPdfMessages.nightTime',
    defaultMessage: 'Godziny nocne',
  },
  bonus: {
    id: 'exportPayroll.columnsPdfMessages.bonus',
    defaultMessage: 'Bonus',
  },
  realization: {
    id: 'exportPayroll.columnsPdfMessages.realization',
    defaultMessage: 'Realizacja / Plan',
  },
  difference: {
    id: 'exportPayroll.columnsPdfMessages.difference',
    defaultMessage: 'Różnica',
  },
  payout: {
    id: 'exportPayroll.columnsPdfMessages.payout',
    defaultMessage: 'Do wypłaty',
  },
  breaks: {
    id: 'exportPayroll.columnsPdfMessages.breaks',
    defaultMessage: 'Przerwy',
  },
  absence: {
    id: 'exportPayroll.columnsPdfMessages.absence',
    defaultMessage: 'Nieobecność',
  },
  overtime50: {
    id: 'exportPayroll.columnsPdfMessages.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'exportPayroll.columnsPdfMessages.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  absences: {
    id: 'exportPayrollLocation.columnsPdfMessages.absences',
    defaultMessage: 'Nieobecności',
  },
  freeDays: {
    id: 'exportPayrollLocation.columnsPdfMessages.freeDays',
    defaultMessage: 'Dni wolne',
  },
  signaturePlace: {
    id: 'exportPayrollLocation.columnsPdfMessages.signaturePlace',
    defaultMessage: 'Miejsce na podpisy',
  },
  additionalStatistics: {
    id: 'exportPayrollLocation.columnsPdfMessages.additionalStatistics',
    defaultMessage: 'Dodatkowe statystyki',
  },
  employee: {
    id: 'exportPayrollLocation.columnsPdfMessages.employee',
    defaultMessage: 'Pracownik',
  },
  availabilities: {
    id: 'exportPayrollLocation.columnsPdfMessages.availabilities',
    defaultMessage: 'Dostępności',
  },
});

export const columnsXLSMessages = defineMessages({
  firstName: {
    id: 'exportPayrollLocation.columnsXLSMessages.firstName',
    defaultMessage: 'Imię',
  },
  lastName: {
    id: 'exportPayrollLocation.columnsXLSMessages.lastName',
    defaultMessage: 'Nazwisko',
  },
  referenceId: {
    id: 'exportPayrollLocation.columnsXLSMessages.referenceId',
    defaultMessage: 'ID referencyjne',
  },
  employmentCondition: {
    id: 'exportPayrollLocation.columnsXLSMessages.employmentCondition',
    defaultMessage: 'Warunek zatrudnienia',
  },
  role: {
    id: 'exportPayrollLocation.columnsXLSMessages.role',
    defaultMessage: 'Rola',
  },
  date: {
    id: 'exportPayrollLocation.columnsXLSMessages.date',
    defaultMessage: 'Data',
  },
  weekday: {
    id: 'exportPayrollLocation.columnsXLSMessages.weekday',
    defaultMessage: 'Dzień tygodnia',
  },
  locationName: {
    id: 'exportPayrollLocation.columnsXLSMessages.locationName',
    defaultMessage: 'Lokalizacja',
  },
  locationExtraInfo: {
    id: 'exportPayrollLocation.columnsXLSMessages.locationExtraInfo',
    defaultMessage: 'Dodatkowe informacje',
  },
  jobTitleName: {
    id: 'exportPayrollLocation.columnsXLSMessages.jobTitleName',
    defaultMessage: 'Stanowisko',
  },
  wage: {
    id: 'exportPayrollLocation.columnsXLSMessages.wage',
    defaultMessage: 'Stawka',
  },
  shiftStart: {
    id: 'exportPayrollLocation.columnsXLSMessages.shiftStart',
    defaultMessage: 'Od Grafik',
  },
  shiftEnd: {
    id: 'exportPayrollLocation.columnsXLSMessages.shiftEnd',
    defaultMessage: 'Do Grafik',
  },
  plannedDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.plannedDuration',
    defaultMessage: 'Czas Grafik',
  },
  shiftDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.shiftDuration',
    defaultMessage: 'Czas Zmiany',
  },
  attendanceStart: {
    id: 'exportPayrollLocation.columnsXLSMessages.attendanceStart',
    defaultMessage: 'Od RCP',
  },
  attendanceEnd: {
    id: 'exportPayrollLocation.columnsXLSMessages.attendanceEnd',
    defaultMessage: 'Do RCP',
  },
  attendanceDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.attendanceDuration',
    defaultMessage: 'Czas RCP',
  },
  payrollStart: {
    id: 'exportPayrollLocation.columnsXLSMessages.payrollStart',
    defaultMessage: 'Od Obecność',
  },
  payrollEnd: {
    id: 'exportPayrollLocation.columnsXLSMessages.payrollEnd',
    defaultMessage: 'Do Obecność',
  },
  payrollDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.payrollDuration',
    defaultMessage: 'Czas Obecności',
  },
  accountedDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.accountedDuration',
    defaultMessage: 'Suma godzin',
  },
  nightHours: {
    id: 'exportPayrollLocation.columnsXLSMessages.nightHours',
    defaultMessage: 'Godziny nocne',
  },
  bonusAmount: {
    id: 'exportPayrollLocation.columnsXLSMessages.bonusAmount',
    defaultMessage: 'Bonus',
  },
  diffDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.diffDuration',
    defaultMessage: 'Różnica',
  },
  underworkedMinutes: {
    id: 'exportPayrollLocation.columnsXLSMessages.underworkedMinutes',
    defaultMessage: 'Niedopracowanie',
  },
  breaksDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.breaksDuration',
    defaultMessage: 'Przerwa',
  },
  attendanceLabels: {
    id: 'exportPayrollLocation.columnsXLSMessages.attendanceLabels',
    defaultMessage: 'Etykiety',
  },
  overtime50: {
    id: 'exportPayrollLocation.columnsXLSMessages.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'exportPayrollLocation.columnsXLSMessages.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  potentialOvertime: {
    id: 'exportPayrollLocation.columnsXLSMessages.potentialOvertime',
    defaultMessage: 'Nadgodziny PŚT',
  },
  fillUp: {
    id: 'exportPayrollLocation.columnsXLSMessages.fillUp',
    defaultMessage: 'Dopełnienia',
  },
  payAmount: {
    id: 'exportPayrollLocation.columnsXLSMessages.payAmount',
    defaultMessage: 'Do Wypłaty',
  },
  absenceName: {
    id: 'exportPayrollLocation.columnsXLSMessages.absenceName',
    defaultMessage: 'Nieobecność',
  },
  absenceCode: {
    id: 'exportPayrollLocation.columnsXLSMessages.absenceCode',
    defaultMessage: 'Kod nieobecności',
  },
  absenceDuration: {
    id: 'exportPayrollLocation.columnsXLSMessages.absenceDuration',
    defaultMessage: 'Czas trwania nieobecności',
  },
  attendanceGPS: {
    id: 'exportPayrollLocation.columnsXLSMessages.attendanceGPS',
    defaultMessage: 'GPS Obecności',
  },
  absences: {
    id: 'exportPayrollLocation.columnsXLSMessages.absences',
    defaultMessage: 'Nieobecności',
  },

  availabilities: {
    id: 'exportPayrollLocation.columnsXLSMessages.availabilities',
    defaultMessage: 'Dostępności',
  },
});
