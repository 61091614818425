import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip';

import './MDAlert.scss';

const MDAlert = ({ icon, label, iconType, numberOfAlerts, materialSymbols, style }) => {
  const icoClassName = materialSymbols
    ? 'material-symbols-outlined'
    : classNames('mdAlert__icon', {
        'material-icons': !iconType,
        [`material-icons-${iconType}`]: iconType,
      });

  const content = <span className={icoClassName}>{icon}</span>;

  return (
    <div className="mdAlert" style={style}>
      {label ? <Tooltip content={label}>{content}</Tooltip> : content}
      {numberOfAlerts && <span className="mdAlert__counter">{numberOfAlerts <= 9 ? numberOfAlerts : '+9'}</span>}
    </div>
  );
};

MDAlert.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string,
  numberOfAlerts: PropTypes.number,
  materialSymbols: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default MDAlert;
