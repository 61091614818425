import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store/types';
import { EmployeeWhole, Location } from '@/types';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

const selectScheduleStateLocations = (state: RootState) => state.reducer.scheduleState.locations;

export const selectRegularEmployees = (locationId: Location['id']) =>
  createSelector(selectScheduleStateLocations, locations => {
    const locationState = locations[locationId];
    if (!locationState) return [];
    const { order, visible, loaned, supplementary } = locationState;
    const visibleDict = transformToBoolDict(visible);
    const loanedDict = transformToBoolDict(loaned);
    const supplementaryDict = transformToBoolDict(supplementary);
    return order.filter(id => visibleDict[id] && !supplementaryDict[id] && !loanedDict[id]);
  });

export const selectSupplementaryEmployees = (locationId: Location['id']) =>
  createSelector(selectScheduleStateLocations, locations => {
    const locationState = locations[locationId];
    if (!locationState) return [];
    const { order, visible, supplementary } = locationState;
    const visibleDict = transformToBoolDict(visible);
    const supplementaryDict = transformToBoolDict(supplementary);
    return order.filter(id => visibleDict[id] && supplementaryDict[id]);
  });

export const selectLoanedEmployees = (locationId: Location['id']) =>
  createSelector(selectScheduleStateLocations, locations => {
    const locationState = locations[locationId];
    if (!locationState) return [];
    const { order, visible, loaned } = locationState;
    const visibleDict = transformToBoolDict(visible);
    const loanedDict = transformToBoolDict(loaned);
    return order.filter(id => visibleDict[id] && loanedDict[id]);
  });

export const selectAllLoanedEmployeesIds = (state: RootState) => {
  const { locations } = state.reducer.scheduleState;
  return [
    ...new Set(
      Object.values(locations).reduce<EmployeeWhole['id'][]>((acc, location) => {
        const { order, visible, loaned } = location;
        const visibleDict = transformToBoolDict(visible);
        const loanedDict = transformToBoolDict(loaned);
        const loanedEmployees = order.filter(id => visibleDict[id] && loanedDict[id]);
        return [...acc, ...loanedEmployees];
      }, []),
    ),
  ];
};

export const selectScheduleStateForLocation = (locationId: Location['id']) =>
  createSelector(selectScheduleStateLocations, locations => locations[locationId]);
