import PropTypes from 'prop-types';

import DropArea from '../DropArea/DropArea.jsx';

const OpenShiftDropArea = ({ enabled, draggedShiftToOpenShift, date, locationId }) => {
  const onDrop = () => {
    draggedShiftToOpenShift(date, locationId);
  };
  return <DropArea enabled={enabled} onDrop={onDrop} />;
};

OpenShiftDropArea.propTypes = {
  enabled: PropTypes.bool,
};
export default OpenShiftDropArea;
