import { connect } from 'react-redux';

import DashboardStatistics from './DashboardStatistics.jsx';

const mapStateToProps = ({ reducer }) => ({
  summary: reducer.dashboard.summary,
  userLocations: reducer.userLocations,
});

export default connect(mapStateToProps)(DashboardStatistics);
