import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, LeftColumn, LeftHeader,Rows } from '@/components/common/Grid/Grid.jsx';
import ScrollWrapper from '@/components/common/ScrollWrapper.jsx';

class AvailabilityTable extends Component {
  constructor(props) {
    super(props);
    this.width = 1200;
  }

  render() {
    return (
      <div className="k-wrapper animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div id="tab-1" className="tab-pane active">
              <div className="panel-nopad panel-blank">
                <ScrollWrapper>
                  <Grid height={999999} width={this.width} columnStyle={{ width: '20vw', minWidth: '250px' }}>
                    <LeftHeader>
                      <th className="tab_title">
                        <FormattedMessage id="attendanceList.table.title" defaultMessage="Dostępności" />
                      </th>
                    </LeftHeader>
                    <Header>
                      <thead>
                        <tr>
                          <th>00:00</th>
                          <th>01:00</th>
                          <th>02:00</th>
                          <th>03:00</th>
                          <th>04:00</th>
                          <th>05:00</th>
                          <th>06:00</th>
                          <th>07:00</th>
                          <th>08:00</th>
                          <th>09:00</th>
                          <th>10:00</th>
                          <th>11:00</th>
                          <th>12:00</th>
                          <th>13:00</th>
                          <th>14:00</th>
                          <th>15:00</th>
                          <th>16:00</th>
                          <th>17:00</th>
                          <th>18:00</th>
                          <th>19:00</th>
                          <th>20:00</th>
                          <th>21:00</th>
                          <th>22:00</th>
                          <th>23:00</th>
                        </tr>
                      </thead>
                    </Header>
                    <LeftColumn>
                      {this.props.mainDateStore.dateArray.map((date, index) => {
                        const today = this.props.mainDateStore.dateArray[index] === this.props.mainDateStore.today;
                        const dayText = moment(date).format('dddd DD.MM');
                        const columnClassNames = classnames('col_name day', {
                          today,
                        });

                        return (
                          <tr>
                            <td className={columnClassNames}>
                              <span className="date">{`${dayText}`}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </LeftColumn>
                    <Rows
                      className="table availability kadroGrid__rows--disabled"
                      columnsNo={1}
                      disabledEditOverlayStyle={this.props.disabledEditOverlayStyle}
                    >
                      {this.props.children}
                    </Rows>
                  </Grid>
                </ScrollWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AvailabilityTable.propTypes = {
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    headerDateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  disabledEditOverlayStyle: PropTypes.shape({}),
  children: PropTypes.node,
};

export default AvailabilityTable;
