import { getOS } from '@/utils/baseHelpers';
import { intercomShowNewMessage } from '@/utils/intercomHelpers';

import contactMessages from './contact.messages';

const os = getOS();

const intercomMessage =
  'Dzień dobry, mam kilka pytań w związku z moim okresem próbnym. Prosilibyśmy o Wasze wsparcie 😊';

export const getContactItems = () => [
  {
    icon: 'message',
    key: 'chat',
    title: contactMessages.messageTitle,
    info: contactMessages.messageInfo,
    buttonText: contactMessages.messageButton,
    onClick: () => {
      intercomShowNewMessage(intercomMessage);
    },
  },
  {
    icon: 'video_call',
    key: 'video-call',
    title: contactMessages.videoCallTitle,
    info: contactMessages.videoCallInfo,
    buttonText: contactMessages.videoCallButton,
    onClick: () => {
      window.open('https://calendly.com/kadromierzteam', '_blank');
    },
  },
  {
    icon: 'phone',
    key: 'phone-call',
    title: contactMessages.phoneTitle,
    info: contactMessages.phoneInfo,
    buttonText: contactMessages.phoneButton,
    onClick: () => {
      if (os === 'mac') {
        window.open('facetime:+48221031849');
      }

      if (os === 'windows') {
        window.open('skype:+48221031849?call');
      }

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.open('tel:+48221031849');
      }
    },
  },
];
