import * as AT from '@/constants/ActionTypes';
import { DELETE_SCHEDULE_ITEMS_MODAL, QUICK_PLANNING_WARNING_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions.js';
import { addOrEditFreeDays } from '@/redux-store/freeDaysMarking';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';
import { checkIsShiftForLocations } from '@/utils/schedule/scheduleFilters/scheduleFilters';

// eslint-disable-next-line import/no-cycle
import { addMultipleShifts, notifyAboutFailureAddManyShifts } from '../schedule';
import { showModal } from '../uiState';
import {
  getCells,
  getCopiedMarkedArea,
  getDatesRanges,
  getEmployeesRanges,
  getForSelectedColumnNewPosition,
  getForSelectedRowNewPosition,
  getInitPosition,
  getNewPositionAndTranslationForAdvancedNavigation,
  getNewPositionForSimpleNavigation,
  getNewShiftsToAdd,
  getPositionForMouseSelection,
  getShiftsIdsForPosition,
  getShiftsToCopy,
  getShiftsToDelete,
  getSortedVisibleEmployeeIds,
  isPositionWithinEmploymentPeriod,
} from './quickPlanning.helpers';
import { messages } from './quickPlanning.messages';
import { getFreeDaysIdsToDelete, getFreeDaysToCopy, getFreeDaysToPasteSummary } from './quickPlanning/freeDaysQP';
import { getShiftsToPasteSummary, getSpecialSelections } from './quickPlanning/quickPlanning.helpers';

const toggleQuickPlanning = () => ({
  type: AT.TOGGLE_QUICK_PLANNING,
});

export const quickPlanningClearSelection = () => ({
  type: AT.QUICK_PLANNING_CLEAR_SELECTION,
});

const setNewSelection = cells => ({
  type: AT.QUICK_PLANNING_SET_NEW_SELECTED,
  payload: cells,
});

const addToSelection = cells => ({
  type: AT.QUICK_PLANNING_ADD_TO_SELECTED,
  payload: cells,
});

const removeFromSelection = cells => ({
  type: AT.QUICK_PLANNING_REMOVE_FROM_SELECTED,
  payload: cells,
});

const setHoveredItems = cells => ({
  type: AT.QUICK_PLANNNING_SET_HOVERED_ITEMS,
  payload: cells,
});

const toggleSingleSelection = ({ employeeId, date }, lastBorderCell = {}) => ({
  type: AT.QUICK_PLANNING_TOGGLE_SINGLE_SELECTION,
  payload: { employeeId, date, lastBorderCell },
});

const setPosition = (position, sortedVisibleEmployeeIds, dateArray) => dispatch => {
  const employeeId = sortedVisibleEmployeeIds[position.start.y];
  const date = dateArray[position.start.x];
  const borderCell = {
    employeeId,
    date,
  };

  dispatch({
    type: AT.QUICK_PLANNING_SET_POSITION,
    payload: { ...position, borderCell },
  });
};

const setDataToCopy = (
  datesRanges,
  employeesIdsRanges,
  freeDaysToCopy,
  shiftsToCopy,
  copiedMarkedArea,
  specialSelections,
) => ({
  type: AT.QUICK_PLANNING_SET_DATA_TO_COPY,
  payload: { datesRanges, employeesIdsRanges, freeDaysToCopy, shiftsToCopy, copiedMarkedArea, specialSelections },
});

const setShiftsSummary = shiftsSummary => ({
  type: AT.QUICK_PLANNING_SET_SHIFTS_SUMMARY,
  payload: shiftsSummary,
});

const setFreeDaysSummary = freeDaysSummary => ({
  type: AT.QUICK_PLANNING_SET_FREE_DAYS_SUMMARY,
  payload: freeDaysSummary,
});

const setTranslationWithShift = translationWithShift => ({
  type: AT.QUICK_PLANNING_SET_TRANSLATION_WITH_SHIFT,
  payload: translationWithShift,
});

const clearTranslationWithShift = () => dispatch => {
  const initialTranslationWithShift = { x: 0, y: 0 };
  dispatch(setTranslationWithShift(initialTranslationWithShift));
};

export const notifyAboutNoShiftsToPaste = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.QUICK_PLANNING_NO_SHIFTS_TO_PASTE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.noShiftsToPaste),
      type: 'success',
    },
  });
};

export const toogleQuickPlanningScheduleMode = () => (dispatch, getState) => {
  const { scheduleLocationFilter, userPermissions, mainDateStore, scheduleState, userEmployees } = getState().reducer;
  const isEditingScheduleDisabled = userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);
  if (scheduleLocationFilter.length !== 1 || isEditingScheduleDisabled) return;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);

  const initialPostion = getInitPosition(sortedVisibleEmployeeIds, userEmployees, mainDateStore.dateArray);

  dispatch(setPosition(initialPostion, sortedVisibleEmployeeIds, mainDateStore.dateArray));
  dispatch(toggleQuickPlanning());
};

const handleAdvancedNavigation = direction => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { dateArray } = mainDateStore;
  const { position, translationWithShift } = schedule.quickPlanning;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  const { newPosition, translation } = getNewPositionAndTranslationForAdvancedNavigation(
    direction,
    position,
    sortedVisibleEmployeeIds,
    dateArray,
    translationWithShift,
  );
  dispatch(setTranslationWithShift(translation));
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(setNewSelection(cells));
};

const handleSimpleNavigation = direction => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { dateArray } = mainDateStore;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  const newPosition = getNewPositionForSimpleNavigation(
    direction,
    schedule.quickPlanning.position,
    sortedVisibleEmployeeIds,
    dateArray,
  );
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  dispatch(clearTranslationWithShift());
};

export const navigateUsingArrows = direction => (dispatch, getState) => {
  const { schedule, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { enabled } = schedule.quickPlanning;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  if (!enabled || !sortedVisibleEmployeeIds.length) return;
  if (direction.includes('shift')) {
    dispatch(handleAdvancedNavigation(direction));
  } else {
    dispatch(handleSimpleNavigation(direction));
  }
};

export const toggleQuickPlanningSelection = () => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleState, scheduleLocationFilter } = getState().reducer;
  const { enabled, position } = schedule.quickPlanning;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  if (!enabled || !sortedVisibleEmployeeIds.length) return;
  dispatch(setPosition(position, sortedVisibleEmployeeIds, mainDateStore.dateArray));
  const newSelection = {
    employeeId: sortedVisibleEmployeeIds[position.start.y],
    date: mainDateStore.dateArray[position.start.x],
  };
  dispatch(toggleSingleSelection(newSelection));
};

const selectSingleColumnWithCtrl = (date, selected, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedColumnNewPosition(date, sortedVisibleEmployeeIds, dateArray);
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const datesSelected = Object.values(selected).flatMap(e => Object.keys(e));
  const isSomeDayInColumnSelected = datesSelected.some(d => d === date);
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  if (isSomeDayInColumnSelected) {
    dispatch(removeFromSelection(cells));
  } else {
    dispatch(addToSelection(cells));
  }
};

const selectSingleColumn = (date, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedColumnNewPosition(date, sortedVisibleEmployeeIds, dateArray);
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(setNewSelection(cells));
  dispatch(setTranslationWithShift({ x: 0, y: sortedVisibleEmployeeIds.length - 1 }));
};

const selectMultipleColumns = (date, lastPosition, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedColumnNewPosition(date, sortedVisibleEmployeeIds, dateArray);
  const [lastStartX, lastEndX] = [lastPosition.start.x, lastPosition.end.x];
  const selectedX = newPosition.start.x;
  if (selectedX > lastStartX) {
    newPosition.start.x = lastStartX;
    newPosition.end.x = selectedX;
  } else {
    newPosition.start.x = selectedX;
    newPosition.end.x = lastEndX;
  }
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(addToSelection(cells));
};

export const handleClickOnColumnInQuickPlannig = (date, specialKeys) => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { selected, position: lastPosition } = schedule.quickPlanning;
  const { dateArray } = mainDateStore;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  if (specialKeys.shift)
    return dispatch(selectMultipleColumns(date, lastPosition, dateArray, sortedVisibleEmployeeIds));
  if (specialKeys.ctrl)
    return dispatch(selectSingleColumnWithCtrl(date, selected, dateArray, sortedVisibleEmployeeIds));
  dispatch(selectSingleColumn(date, dateArray, sortedVisibleEmployeeIds));
};

const selectSingleRowWithCtrl = (employeeId, selected, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedRowNewPosition(employeeId, sortedVisibleEmployeeIds, dateArray);
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const isSomeEmployeeInRowSelected = Object.values(selected[employeeId] || {}).length;
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  if (isSomeEmployeeInRowSelected) {
    dispatch(removeFromSelection(cells));
  } else {
    dispatch(addToSelection(cells));
  }
};

const selectSingleRow = (employeeId, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedRowNewPosition(employeeId, sortedVisibleEmployeeIds, dateArray);
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(setNewSelection(cells));
  dispatch(setTranslationWithShift({ x: dateArray.length - 1, y: 0 }));
};

const selectMultipleRows = (employeeId, lastPosition, dateArray, sortedVisibleEmployeeIds) => dispatch => {
  const newPosition = getForSelectedRowNewPosition(employeeId, sortedVisibleEmployeeIds, dateArray);
  const [lastStartY, lastEndY] = [lastPosition.start.y, lastPosition.end.y];
  const selectedY = newPosition.start.y;
  if (selectedY > lastStartY) {
    newPosition.start.y = lastStartY;
    newPosition.end.y = selectedY;
  } else {
    newPosition.start.y = selectedY;
    newPosition.end.y = lastEndY;
  }
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(addToSelection(cells));
};

export const handleClickOnRowInQuickPlannig = (employeeId, specialKeys) => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  const { selected, position: lastPosition } = schedule.quickPlanning;
  const { dateArray } = mainDateStore;
  if (specialKeys.shift)
    return dispatch(selectMultipleRows(employeeId, lastPosition, dateArray, sortedVisibleEmployeeIds));
  if (specialKeys.ctrl)
    return dispatch(selectSingleRowWithCtrl(employeeId, selected, dateArray, sortedVisibleEmployeeIds));
  dispatch(selectSingleRow(employeeId, dateArray, sortedVisibleEmployeeIds));
};

export const handleMouseDownItem = (employeeId = null, date = null) => ({
  type: AT.QUICK_PLANNING_DYNAMIC_SET_ITEM_DOWN,
  payload: { employeeId, date },
});

export const handleMouseUpItem = (employeeId, date, e) => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { downItem, position } = schedule.quickPlanning;
  const { canPaste } = schedule.buttonsState.canPaste;
  const { dateArray } = mainDateStore;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  const upItem = { employeeId, date };
  const newPosition = getPositionForMouseSelection(downItem, upItem, dateArray, sortedVisibleEmployeeIds);
  dispatch(setPosition(newPosition, sortedVisibleEmployeeIds, dateArray));
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);

  if (!e.ctrlKey && !e.metaKey && !e.shiftKey) {
    const newCells = cells.length === 1 && canPaste ? [] : cells;
    dispatch(setNewSelection(newCells));
  } else if (e.shiftKey) {
    const cellsWithShift = getCells(sortedVisibleEmployeeIds, dateArray, {
      start: position.start,
      end: newPosition.start,
    });
    dispatch(addToSelection(cellsWithShift));
    dispatch(setPosition(position, sortedVisibleEmployeeIds, dateArray));
  } else if (upItem.employeeId === downItem.employeeId && upItem.date === downItem.date) {
    dispatch(toggleSingleSelection(upItem, position.borderCell));
  } else {
    dispatch(addToSelection(cells));
  }
  dispatch(clearTranslationWithShift());
  dispatch(handleMouseDownItem());
  dispatch(setHoveredItems([]));
};

export const handleMouseOverItem = (employeeId, date) => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState } = getState().reducer;
  const { downItem } = schedule.quickPlanning;
  if (!downItem.employeeId) return;
  const { dateArray } = mainDateStore;
  const hoverItem = { employeeId, date };
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  const newPosition = getPositionForMouseSelection(downItem, hoverItem, dateArray, sortedVisibleEmployeeIds);
  const cells = getCells(sortedVisibleEmployeeIds, dateArray, newPosition);
  dispatch(setHoveredItems(cells));
};

export const quickPlanningCopy = () => (dispatch, getState) => {
  const { schedule, employees, freeDaysMarking, mainDateStore, scheduleState, scheduleLocationFilter, shifts } =
    getState().reducer;
  const { quickPlanning, buttonsState } = schedule;
  const { selected, position, enabled } = quickPlanning;
  const { borderCell } = position;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);

  if (!enabled || !sortedVisibleEmployeeIds.length || !buttonsState.canCopy) return;

  const shiftsToCopy = getShiftsToCopy(employees.data, selected, shifts.data, scheduleLocationFilter);
  const freeDaysToCopy = getFreeDaysToCopy(selected, freeDaysMarking);

  const shiftsIdsForPosition = getShiftsIdsForPosition(borderCell, employees.data);
  const freeDayForPosition = freeDaysMarking[borderCell.employeeId]?.[borderCell.date];

  const isCopiedOnlyNotSelectedCell =
    (isEmptyObject(shiftsToCopy) && !isEmptyArray(shiftsIdsForPosition)) ||
    (isEmptyObject(freeDaysToCopy) && freeDayForPosition);

  if (isCopiedOnlyNotSelectedCell) {
    const shiftsForNotSelectedCell = shiftsIdsForPosition.reduce((prev, shiftId) => {
      const shift = shifts.data[borderCell.employeeId].shifts[shiftId];
      return checkIsShiftForLocations(scheduleLocationFilter, shift) ? [...prev, shift] : prev;
    }, []);
    if (!isEmptyArray(shiftsForNotSelectedCell)) shiftsToCopy[borderCell.employeeId] = shiftsForNotSelectedCell;
    if (freeDayForPosition) freeDaysToCopy[borderCell.employeeId] = [freeDayForPosition];
  }

  if (isEmptyObject(shiftsToCopy) && isEmptyObject(freeDaysToCopy)) return;

  const datesRanges = getDatesRanges(selected, mainDateStore.dateArray, isCopiedOnlyNotSelectedCell, borderCell);
  const employeesIdsRanges = getEmployeesRanges(
    selected,
    sortedVisibleEmployeeIds,
    isCopiedOnlyNotSelectedCell,
    borderCell,
  );

  const copiedMarkedArea = getCopiedMarkedArea(
    selected,
    sortedVisibleEmployeeIds,
    mainDateStore.dateArray,
    isCopiedOnlyNotSelectedCell,
    borderCell,
  );

  const specialSelections = getSpecialSelections(
    selected,
    mainDateStore.dateArray,
    sortedVisibleEmployeeIds,
    shiftsToCopy,
    freeDaysToCopy,
    isCopiedOnlyNotSelectedCell,
  );

  dispatch(
    setDataToCopy(datesRanges, employeesIdsRanges, freeDaysToCopy, shiftsToCopy, copiedMarkedArea, specialSelections),
  );
};

export const quickPlanningPaste = () => (dispatch, getState) => {
  const {
    schedule,
    mainDateStore,
    scheduleState,
    scheduleLocationFilter,
    employees,
    shifts,
    contracts,
    userJobTitles,
    freeDaysMarking,
  } = getState().reducer;
  const { quickPlanning, buttonsState } = schedule;
  const { enabled } = quickPlanning;
  const { dateArray } = mainDateStore;
  const { data: allEmployees } = employees;
  const { data: allShifts } = shifts;
  if (!enabled || !buttonsState.canPaste) return;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);

  const shiftsSummary = getShiftsToPasteSummary(
    quickPlanning,
    sortedVisibleEmployeeIds,
    dateArray,
    allEmployees,
    allShifts,
    contracts,
    userJobTitles,
  );

  const freeDaysSummary = getFreeDaysToPasteSummary(
    quickPlanning,
    sortedVisibleEmployeeIds,
    dateArray,
    freeDaysMarking,
    allEmployees,
  );

  const isIncorrectDestination =
    !isEmptyArray(shiftsSummary.shiftsAfterDateSelection) ||
    !isEmptyArray(shiftsSummary.shiftsForNotExistingEmployeeId) ||
    !isEmptyArray(freeDaysSummary.freeDaysAfterDateSelection) ||
    !isEmptyArray(freeDaysSummary.freeDaysForNotExistingEmployeeId);

  dispatch(setShiftsSummary(shiftsSummary));
  dispatch(setFreeDaysSummary(freeDaysSummary));

  if (isEmptyArray(shiftsSummary.shiftsToPaste)) {
    if (!isEmptyArray(shiftsSummary.reasonsToNotAddShift))
      dispatch(notifyAboutFailureAddManyShifts(shiftsSummary.reasonsToNotAddShift.length));
    else dispatch(notifyAboutNoShiftsToPaste());
    if (isIncorrectDestination) dispatch(showModal(QUICK_PLANNING_WARNING_MODAL));
    else dispatch(addOrEditFreeDays(freeDaysSummary.freeDaysToPaste));
  } else if (isIncorrectDestination) {
    dispatch(showModal(QUICK_PLANNING_WARNING_MODAL));
  } else {
    dispatch(addMultipleShifts(shiftsSummary.shiftsToPaste, shiftsSummary.reasonsToNotAddShift.length));
    dispatch(addOrEditFreeDays(freeDaysSummary.freeDaysToPaste));
  }
};

export const updateSettingsForQuickPlanning = () => (dispatch, getState) => {
  const { schedule, mainDateStore, scheduleLocationFilter, scheduleState, userEmployees } = getState().reducer;
  const { quickPlanning } = schedule;
  const { enabled } = quickPlanning;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);
  if (!enabled) return;

  const initialPostion = getInitPosition(sortedVisibleEmployeeIds, userEmployees, mainDateStore.dateArray);
  dispatch(setPosition(initialPostion, sortedVisibleEmployeeIds, mainDateStore.dateArray));
  dispatch(setNewSelection([]));
};

export const quickPlanningDeleteShifts = () => (dispatch, getState) => {
  const { freeDaysMarking, schedule, employees, shifts, scheduleLocationFilter } = getState().reducer;
  const { selected, position } = schedule.quickPlanning;
  if (!schedule.buttonsState.canDelete) return;
  const shiftIds = getShiftsToDelete(selected, employees.data, shifts.data, scheduleLocationFilter);
  if (isEmptyObject(selected)) shiftIds.push(...getShiftsIdsForPosition(position.borderCell, employees.data));
  const freeDayIds = getFreeDaysIdsToDelete(selected, position.borderCell, freeDaysMarking);
  dispatch(showModal(DELETE_SCHEDULE_ITEMS_MODAL, { shiftIds, freeDayIds }));
};

export const setWorkingHoursForNewShift = workingHours => ({
  type: AT.QUICK_PLANNING_SET_WORKING_HOURS_FOR_NEW_SHIFT,
  payload: workingHours,
});

export const startInsertingWorkingHoursForNewShift = key => (dispatch, getState) => {
  const { scheduleLocationFilter, mainDateStore, scheduleState, userEmployees, schedule } = getState().reducer;
  const { quickPlanning } = schedule;
  const { dateArray } = mainDateStore;
  const sortedVisibleEmployeeIds = getSortedVisibleEmployeeIds(scheduleLocationFilter, scheduleState);

  const isEmployeeAvailableForPlanning = isPositionWithinEmploymentPeriod(
    sortedVisibleEmployeeIds,
    dateArray,
    userEmployees,
    quickPlanning.position,
  );
  const { enabled, workingHours, freeDayShortcut } = schedule.quickPlanning;
  if (!enabled || workingHours.length || freeDayShortcut.length || !isEmployeeAvailableForPlanning) return;
  const value = /[3-9]/.test(key) ? `0${key}` : key;

  dispatch(setWorkingHoursForNewShift(value));
};

export const quickPlanningAddNewShifts = () => (dispatch, getState) => {
  const { schedule, contracts, userJobTitles, shifts, scheduleLocationFilter } = getState().reducer;
  const { selected, workingHours, position } = schedule.quickPlanning;
  const locationId = scheduleLocationFilter[0];
  const newShifts = getNewShiftsToAdd(
    workingHours,
    selected,
    position.borderCell,
    locationId,
    shifts.data,
    contracts,
    userJobTitles,
  );

  if (!newShifts.length) {
    dispatch(notifyAboutNoShiftsToPaste());
    dispatch(quickPlanningClearSelection());
  } else {
    dispatch(addMultipleShifts(newShifts)).finally(() => dispatch(quickPlanningClearSelection()));
  }
};
