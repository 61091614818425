import { checkIsEmployeeLoaned } from '@/utils/loanedEmployees/loanedEmployees';
import { isEmployeeHiredInPeriod } from '@/utils/userEmployeesHelpers';

export const handleShowEmployees = (showEmployeesWithoutShifts, showShiftsFromOtherLocations, e, locationId) => {
  if (showEmployeesWithoutShifts) return true;
  if (showShiftsFromOtherLocations) {
    return Object.values(e.numberOfShiftsPerLocation).some(value => value > 0);
  }
  return e.numberOfShiftsPerLocation[locationId] > 0;
};

export const getSupplementaryEmployeesIds = (result, locationId) =>
  result[locationId].employees.reduce((acc, e) => {
    if ((e.supplementary_locations_ids || []).some(id => id.toString() === locationId)) acc.push(e.id);
    return acc;
  }, []);

export const getLoanedEmployees = (scheduleLoanedEmployees, locationId, dateArray) =>
  Object.values(scheduleLoanedEmployees).reduce((p, loanedEmp) => {
    if (checkIsEmployeeLoaned(scheduleLoanedEmployees, loanedEmp.id, locationId, dateArray))
      return [...p, { ...scheduleLoanedEmployees[loanedEmp.id], isLoaned: true }];
    return p;
  }, []);

export const getEmployeesWithSupplementaryLoanedAtTheEnd = (
  oldEmployeesOrder,
  employees,
  locationId,
  dateArray,
  scheduleLoanedEmployees,
  areEmployeesGrouped,
) => {
  const newOrder = oldEmployeesOrder.reduce(
    (prev, employeeId) => {
      if (!isEmployeeHiredInPeriod(employees[employeeId] || scheduleLoanedEmployees[employeeId], dateArray))
        return prev;
      if (areEmployeesGrouped) return { ...prev, regular: [...prev.regular, employeeId] };
      if (checkIsEmployeeLoaned(scheduleLoanedEmployees, employeeId, locationId, dateArray))
        return { ...prev, loaned: [...prev.loaned, employeeId] };
      const isLocationSupplementaryForEmployee = (employees[employeeId].supplementary_locations_ids || []).includes(
        parseInt(locationId),
      );
      if (isLocationSupplementaryForEmployee)
        return {
          ...prev,
          supplementary: [...prev.supplementary, employeeId],
        };
      return { ...prev, regular: [...prev.regular, employeeId] };
    },
    { regular: [], supplementary: [], loaned: [] },
  );
  return [...newOrder.regular, ...newOrder.supplementary, ...newOrder.loaned];
};
