import { MAIN_COLOR_PALETT } from '@/constants/colors.js';

export const getContrastYIQ = hexcolor => {
  const color = hexcolor.replace('#', '');

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const getContrastYIQv2 = hexcolor => {
  const color = hexcolor.replace('#', '');

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 188 ? '#555' : 'white';
};
/**
 * Return string with rgba value
 * @param {string} hexcolor - string with color in hex with #, example #FAAFFA
 * @param {number|string} opacity - opacity to be applied for color
 *
 * @return {string} rgba value
 */
export const getRGBA = (hexcolor, opacity) => {
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getRandomColor = () => {
  const colorLength = MAIN_COLOR_PALETT.length;
  const randomIndex = Math.floor(Math.random() * colorLength);

  return MAIN_COLOR_PALETT[randomIndex];
};

export const getRandomColorTag = colorArray => {
  const colorArrayLength = colorArray.length;
  const randomIndex = Math.floor(Math.random() * colorArrayLength);
  return colorArray[randomIndex];
};
