import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  next: {
    id: 'incomingLoanEmployeesProposal.next',
    defaultMessage: 'dalej',
  },
  approved: {
    id: 'incomingLoanEmployeesProposal.approved',
    defaultMessage: 'akceptuj',
  },
  back: {
    id: 'incomingLoanEmployeesProposal.back',
    defaultMessage: 'wróć',
  },
  cancel: {
    id: 'incomingLoanEmployeesProposal.cancel',
    defaultMessage: 'anuluj',
  },
  title: {
    id: 'incomingLoanEmployeesProposal.title',
    defaultMessage: 'Prośba o udostępnienie pracownika',
  },
  deleteProposal: {
    id: 'incomingLoanEmployeesProposal.delete',
    defaultMessage: 'Usuń prośbę',
  },
});
