import { connect } from 'react-redux';

import { deleteEmploymentConditionConfirm } from '@/actions/companymanage/employmentConditions';
import { hideModal } from '@/actions/uiState';
import { EMPLOYMENT_CONDITIONS_DELETE_MODAL } from '@/constants/modalTypes.js';

import EmploymentConditionsDeleteModal from './EmploymentConditionsDeleteModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === EMPLOYMENT_CONDITIONS_DELETE_MODAL,
  modalObject: state.reducer.uiState.modalObject[EMPLOYMENT_CONDITIONS_DELETE_MODAL],
  employmentConditions: state.reducer.employmentConditions,
});

const mapDispatchToProps = {
  hideModal,
  deleteEmploymentConditionConfirm,
};

const EmploymentConditionsDeleteModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentConditionsDeleteModal);

export default EmploymentConditionsDeleteModalContainer;
