import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import EmptyStateNoEmployeesView from './EmptyStateNoEmployeesView.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
});

const mapDispatchToProps = {
  showModal,
};

const EmptyStateNoEmployeesViewContainer = connect(mapStateToProps, mapDispatchToProps)(EmptyStateNoEmployeesView);

export default EmptyStateNoEmployeesViewContainer;
