import { connect } from 'react-redux';

import { changeMultipleLocationFilter } from '@/actions/multipleLocationFilter.js';

import TopBarMultipleLocationFilter from './TopBarMultipleLocationFilter.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  selectedLocations: state.reducer.multipleLocationFilter,
});

const mapDispatchToProps = {
  changeFilter: changeMultipleLocationFilter,
};

const TopBarMultipleLocationFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarMultipleLocationFilter);

export default TopBarMultipleLocationFilterContainer;
