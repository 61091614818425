import { connect } from 'react-redux';

import { State } from '@/types';

import Component from './EmployeePayrollTable';

const mapStateToProps = (state: State) => ({
  summary: state.reducer.payrollEmployee.summary,
  data: state.reducer.payrollEmployee.data,
  showWagesInEmployeeECP: state.reducer.currentCompany.settings.show_wages_in_employee_ecp,
  showSkeletons: state.reducer.payrollEmployee.showSkeletons,
});

export default connect(mapStateToProps)(Component);
