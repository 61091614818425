import { connect } from 'react-redux';

import { mainDateChangeMode } from '@/actions/mainDate.js';
import TopBarDateMode from '@/components/TopBars/filters/TopBarDateMode/TopBarDateMode.jsx';

const mapStateToProps = state => ({
  dateMode: state.reducer.mainDateStore.dateMode,
});

const mapDispatchToProps = {
  mainDateChangeMode,
};

const TopBarDateModeContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarDateMode);

export default TopBarDateModeContainer;
