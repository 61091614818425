import OrangeSplitHorizontal from '@/components/common/layouts/OrangeSplitHorizontal/OrangeSplitHorizontal.jsx';

import SelectPackageContent from './selectPackageViewContent/SelectPackageContent.redux';

const SelectPackageView = () => (
  <OrangeSplitHorizontal hideLogo>
    <SelectPackageContent />
  </OrangeSplitHorizontal>
);

export default SelectPackageView;
