import { connect } from 'react-redux';

import { mainDateChangeMode } from '@/actions/mainDate.js';

import TopBarAbsences from './TopBarAbsences.jsx';

const mapDispatchToProps = {
  mainDateChangeMode,
};

const TopBarAbsencesContainer = connect(null, mapDispatchToProps)(TopBarAbsences);

export default TopBarAbsencesContainer;
