import { connect } from 'react-redux';

import LoanEmployeesProposals from './LoanEmployeesProposalsSection.tsx';

const mapStateToProps = state => ({
  loanEmployeesProposals: state.reducer.loanEmployeesProposals.data,
  currentUser: state.reducer.currentUser.user,
  jobTitles: state.reducer.jobTitles,
  userLocations: state.reducer.userLocations,
  userEmployees: state.reducer.userEmployees,
});

export default connect(mapStateToProps)(LoanEmployeesProposals);
