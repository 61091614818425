import PropTypes from 'prop-types';
import { useCallback } from 'react';

import FiltersModal from '@/components/common/FiltersModal/FiltersModal';

const ReportsFiltersModal = ({
  isOpen,
  getWorkTimeReport,
  getSalesReport,
  mainDateStore,
  locations,
  jobTitles,
  employmentConditions,
  reportsDateChangeModeAndDate,
  changeReportsLocationsFilter,
  changeReportsJobTitlesFilter,
  changeReportsEmploymentConditionsFilter,
  hideModal,
  reportsFilters,
  reportsDate,
  isConfirmButtonDisabled,
}) => {
  const selectedDates = reportsDate || customDate;
  const handleSubmit = useCallback(() => {
    getWorkTimeReport();
    getSalesReport();
  }, [getWorkTimeReport, getSalesReport]);

  return (
    <FiltersModal
      isOpen={isOpen}
      handleSubmit={handleSubmit}
      mainDateStore={mainDateStore}
      locations={locations}
      jobTitles={jobTitles}
      employmentConditions={employmentConditions}
      changeLocationsFilter={changeReportsLocationsFilter}
      changeJobTitlesFilter={changeReportsJobTitlesFilter}
      changeEmploymentConditionsFilter={changeReportsEmploymentConditionsFilter}
      hideModal={hideModal}
      filters={reportsFilters}
      selectedDates={selectedDates}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      mainDateChangeModeAndDate={reportsDateChangeModeAndDate}
    />
  );
};

ReportsFiltersModal.propTypes = {
  isOpen: PropTypes.bool,
  getWorkTimeReport: PropTypes.func,
  getSalesReport: PropTypes.func,
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  reportsDateChangeModeAndDate: PropTypes.func,
  changeReportsLocationsFilter: PropTypes.func,
  changeReportsJobTitlesFilter: PropTypes.func,
  changeReportsEmploymentConditionsFilter: PropTypes.func,
  hideModal: PropTypes.func,
  reportsFilters: PropTypes.shape({
    selectedLocations: PropTypes.arrayOf(PropTypes.shape({})),
    selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
    selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  reportsDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  isConfirmButtonDisabled: PropTypes.bool,
};

export default ReportsFiltersModal;
