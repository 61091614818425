import { connect } from 'react-redux';

import PayrollLocationSummary from './PayrollLocationSummary';

const mapStateToProps = state => ({
  summary: state.reducer.payrollLocation.summary,
  showSkeletons: state.reducer.payrollLocation.isFetching,
  timeFormatType: state.reducer.payrollSettings.timeFormatSetting.type,
  summaryValues: state.reducer.payroll.summaryValues,
  restrictions: state.reducer.userPermissions.restrictions,
});

export default connect(mapStateToProps)(PayrollLocationSummary);
