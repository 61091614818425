import { defineMessages } from 'react-intl';

const applyForSelected = defineMessages({
  edit: {
    id: 'employeesButtonBar.applyForSelectedMessages.edit',
    defaultMessage: 'Edytuj',
  },
  sendPush: {
    id: 'employeesButtonBar.applyForSelectedMessages.sendPush',
    defaultMessage: 'Wyślij powiadomienie PUSH',
  },
  sendInvite: {
    id: 'employeesButtonBar.applyForSelectedMessages.sendInvite',
    defaultMessage: 'Wyślij zaproszenie do systemu',
  },
  export: {
    id: 'employeesButtonBar.applyForSelectedMessages.export',
    defaultMessage: 'Eksportuj do pliku',
  },
  generateQrCodes: {
    id: 'employeesButtonBar.applyForSelectedMessages.generateQrCodes',
    defaultMessage: 'Generuj QR Kody',
  },
  delete: {
    id: 'employeesButtonBar.applyForSelectedMessages.delete',
    defaultMessage: 'Usuń ',
  },
  deleteReleasedEmployeesData: {
    id: 'employeesButtonBar.applyForSelectedMessages.deleteReleasedEmployeesData',
    defaultMessage: 'Usuń dane zwolnionych pracowników',
  },
});

export default {
  applyForSelected,
};
