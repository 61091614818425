import PropTypes from 'prop-types';

import SidebarExportIcon from './SideBarExportIcon/SideBarExportIcon.redux';

const SideBarCustomIcon = ({ path }) => {
  switch (path) {
    case '/exports':
      return <SidebarExportIcon />;
    default:
      return null;
  }
};

SideBarCustomIcon.propTypes = {
  path: PropTypes.string,
};

export default SideBarCustomIcon;
