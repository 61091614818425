import { IntlShape } from 'react-intl';

import { FreeDaysMarkingEnum } from '@/types/freeDaysMarking.types';

import { normalizeDiacritics } from '../stringHelpers';

type FreeDay = {
  id: FreeDaysMarkingEnum;
  name: string;
  shortcut: string;
  keyboardFirstLetterShortcuts: string[];
  legendOrder: number;
};

export const getFreeDays = ({ formatMessage }: IntlShape): Record<FreeDaysMarkingEnum, FreeDay> => ({
  [FreeDaysMarkingEnum.SUNDAY]: {
    id: FreeDaysMarkingEnum.SUNDAY,
    name: formatMessage({
      id: 'freeDaysMarking.sunday',
      defaultMessage: 'Niedziela',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.sunday.shortcut',
      defaultMessage: 'N',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.sunday.k2',
        defaultMessage: 'n',
      }),
    ],
    legendOrder: 1,
  },
  [FreeDaysMarkingEnum.HOLIDAY]: {
    id: FreeDaysMarkingEnum.HOLIDAY,
    name: formatMessage({
      id: 'freeDaysMarking.holiday',
      defaultMessage: 'Święto',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.holiday.shortcut',
      defaultMessage: 'Ś',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.holiday.k2',
        defaultMessage: 'alt+s',
      }),
      formatMessage({
        id: 'freeDaysMarking.holiday.k2',
        defaultMessage: 's',
      }),
    ],
    legendOrder: 3,
  },
  [FreeDaysMarkingEnum.DAY_OFF_FOR_5_DAY_WORKWEEK]: {
    id: FreeDaysMarkingEnum.DAY_OFF_FOR_5_DAY_WORKWEEK,
    name: formatMessage({
      id: 'freeDaysMarking.dayOffFor5DayWorkweek',
      defaultMessage: 'Dzień wolny z tytułu 5-dniowego tygodnia pracy',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.dayOffFor5DayWorkweek.shortcut',
      defaultMessage: 'DW5',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.dayOffFor5DayWorkweek.k1',
        defaultMessage: 'd',
      }),
    ],
    legendOrder: 5,
  },
  [FreeDaysMarkingEnum.DAY_OFF_FOR_SUNDAY]: {
    id: FreeDaysMarkingEnum.DAY_OFF_FOR_SUNDAY,
    name: formatMessage({
      id: 'freeDaysMarking.dayOffForSunday',
      defaultMessage: 'Dzień wolny za niedzielę',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.dayOffForSunday.shortcut',
      defaultMessage: 'WN',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.dayOffForSunday.k1',
        defaultMessage: 'w',
      }),
    ],
    legendOrder: 2,
  },
  [FreeDaysMarkingEnum.DAY_OFF_FOR_HOLIDAY]: {
    id: FreeDaysMarkingEnum.DAY_OFF_FOR_HOLIDAY,
    name: formatMessage({
      id: 'freeDaysMarking.dayOffForHoliday',
      defaultMessage: 'Dzień wolny za święto',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.dayOffForHoliday.shortcut',
      defaultMessage: 'WŚ',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.dayOffForHoliday.k1',
        defaultMessage: 'w',
      }),
    ],
    legendOrder: 4,
  },
  [FreeDaysMarkingEnum.SCHEDULED_DAY_OFF]: {
    id: FreeDaysMarkingEnum.SCHEDULED_DAY_OFF,
    name: formatMessage({
      id: 'freeDaysMarking.scheduledDayOff',
      defaultMessage: 'Dzień harmonogramowo wolny',
    }),
    shortcut: formatMessage({
      id: 'freeDaysMarking.scheduledDayOff.shortcut',
      defaultMessage: 'H',
    }),
    keyboardFirstLetterShortcuts: [
      formatMessage({
        id: 'freeDaysMarking.scheduledDayOff.k1',
        defaultMessage: 'h',
      }),
    ],
    legendOrder: 6,
  },
});

export const getFreeDaysKeyboardShortcutsFirstLetter = (intl: IntlShape): string[] =>
  Object.values(getFreeDays(intl)).flatMap(({ keyboardFirstLetterShortcuts }) =>
    keyboardFirstLetterShortcuts.flatMap(shortcut => [shortcut, `shift+${shortcut}`]),
  );

export const getFreeDaysShortcuts = (intl: IntlShape): string[] =>
  Object.values(getFreeDays(intl)).map(({ shortcut }) => shortcut);

export const findFreeDayMarkingIdByShortcut = (shortcut: string, intl: IntlShape): FreeDaysMarkingEnum | undefined =>
  Object.values(getFreeDays(intl)).find(
    freeDay => normalizeDiacritics(freeDay.shortcut).toLowerCase() === normalizeDiacritics(shortcut).toLowerCase(),
  )?.id;
