import PropTypes from 'prop-types';

import PayrollSettingsModalWorkTimeColumn from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModalWorkTimeSection/PayrollSettingsModalWorkTimeColumn/PayrollSettingsModalWorkTimeColumn';

const PayrollSettingsModalWorkTimeSection = ({ settings, toleranceDisabled, changeSetting }) => (
  <div className="k-payrollSettingsModal__workTimeSection">
    <PayrollSettingsModalWorkTimeColumn {...{ settings, toleranceDisabled, changeSetting }} type="start" />
    <PayrollSettingsModalWorkTimeColumn {...{ settings, toleranceDisabled, changeSetting }} type="end" />
  </div>
);

PayrollSettingsModalWorkTimeSection.propTypes = {
  type: PropTypes.string,
  settings: PropTypes.shape({
    startRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  toleranceDisabled: PropTypes.bool,
  changeSetting: PropTypes.func,
};

export default PayrollSettingsModalWorkTimeSection;
