import { connect } from 'react-redux';

import { deleteRoleConfirm } from '@/actions/companymanage/roles';
import { hideModal } from '@/actions/uiState';
import { ROLE_DELETE_MODAL } from '@/constants/modalTypes.js';

import RolesDeleteModal from './RolesDeleteModal.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser.user,
  showModal: state.reducer.uiState.showModal === ROLE_DELETE_MODAL,
  modalObject: state.reducer.uiState.modalObject[ROLE_DELETE_MODAL],
  companyRoles: state.reducer.roles.companyRoles,
  userPermissions: state.reducer.userPermissions,
  companySettings: state.reducer.currentCompany.settings,
  sameRankIsVisible: state.reducer.currentCompany.settings.same_rank_is_visible,
});

const mapDispatchToProps = {
  deleteRoleConfirm,
  hideModal,
};

const RolesDeleteModalContainer = connect(mapStateToProps, mapDispatchToProps)(RolesDeleteModal);

export default RolesDeleteModalContainer;
