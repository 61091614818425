import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { SHORTCUTS_MODAL } from '@/constants/modalTypes';

import ShortcutsModal from './ShortcutsModal';

const mapStateToProps = ({ reducer }) => ({
  show: reducer.uiState.showModal === SHORTCUTS_MODAL,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutsModal);
