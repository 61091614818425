import PropTypes from 'prop-types';

import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents';
import { MappedLoanEmployeesProposal, NewProposalData } from '@/types/loanEmployeesProposals';

import { EmployeeItem } from '../../loanEmployeesProposalModals.helpers';
import { getLoanEmployeesProposalDetails } from './loanEmployeesProposalDetailsStep.helpers';

interface LoanEmployeesProposalDetailsStepProps {
  loanEmployeesProposal: MappedLoanEmployeesProposal | NewProposalData;
  employeeItems?: EmployeeItem[];
}

const LoanEmployeesProposalDetailsStep = (
  { loanEmployeesProposal, employeeItems }: LoanEmployeesProposalDetailsStepProps,
  { intl },
) => (
  <div className="k-loanEmployeesProposalDetailsSteps">
    {getLoanEmployeesProposalDetails(loanEmployeesProposal, intl, employeeItems).map(
      ({ id, label, info, infoModifiers }) => (
        <MDInfoWithLabel key={id} label={label} info={info} infoModifiers={infoModifiers} />
      ),
    )}
  </div>
);

LoanEmployeesProposalDetailsStep.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default LoanEmployeesProposalDetailsStep;
