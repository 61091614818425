export const getShiftNumberPerLocation = scheduleData => {
  if (Object.keys(scheduleData).length > 0) {
    return Object.entries(scheduleData).reduce((agg, shift) => {
      const locationId = shift[1].location.id;
      const shiftNumberPerLocation = { ...agg, [locationId]: (agg[locationId] || 0) + 1 };
      return shiftNumberPerLocation;
    }, {});
  }
  return {};
};

export const subtractShiftNumberPerLocation = (employeeData, dataToSubtract) => dataToSubtract.reduce((agg, data) => {
    const { locationId } = data;
    return { ...agg, [locationId]: agg[locationId] - 1 };
  }, employeeData);
