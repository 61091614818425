import { checkWorkingHoursOverlaps } from '@/utils/dateHelper';
import { inputValidation } from '@/utils/inputValidation';

import { validationErrorMessages } from './WorkingHoursInputs.messages';

export const validateAllWorkingHours = (workingHoursArray, intl) => {
  let isAnyError = false;
  const newWorkingHoursArray = workingHoursArray.map(block => {
    const error = inputValidation('working_hours_2', block.workingHours);
    if (error) isAnyError = true;
    const errorMessage = error ? intl.formatMessage(validationErrorMessages.incorrectWorkingHours) : '';
    return { ...block, error: errorMessage };
  });
  const workingHoursInputsOverlapsError =
    !isAnyError && checkWorkingHoursOverlaps(workingHoursArray.map(({ workingHours }) => workingHours));
  return [isAnyError, workingHoursInputsOverlapsError, newWorkingHoursArray];
};
