/* eslint-disable camelcase */
import moment from 'moment';

import { getInitCalendarChoice } from '@/utils/dateHelper';
import { extractShiftsFromEmployeesByDate } from '@/utils/schedulerHelpers';

export const getInitialState = mainDateStore => ({
  selectedEmployees: [],
  displayRepeat: false,
  comment: '',
  activeRepeatTab: 'period',
  selectedRange: {
    start: mainDateStore.dateArray[0],
    end: mainDateStore.dateArray[mainDateStore.dateArray.length - 1],
  },
  selectedChoices: getInitCalendarChoice(moment().format('YYYY-MM-DD')),
  selectedWeekdays: [],
  perWhichDay: null,
  workingHours: '__:__-__:__',
  errors: {},
});

export const createNewShift = (state, modalObject) => {
  const { workingHours, comment } = state;
  const { rowData, date, location } = modalObject;
  const { id, color, company_id, hourly_wage, title } = rowData;

  const shift = {
    comment,
    working_hours: workingHours,
    job_title: {
      color,
      company_id,
      hourly_wage,
      id,
      title,
    },
    draft: true,
    date,
    location,
  };
  return shift;
};

export const createRepeatObject = state => {
  const { selectedRange, selectedChoices, selectedWeekdays, perWhichDay, activeRepeatTab } = state;
  return {
    type: activeRepeatTab,
    selected: {
      selectedRange,
      selectedChoices,
      selectedWeekdays,
      perWhichDay,
    },
  };
};

export const getDisabledLocationDate = (state, { modalObject, locationSettings }) => {
  const { location } = modalObject;
  const selectedLocationSettings = locationSettings[location.id];
  return state.displayRepeat && selectedLocationSettings.disable_location_schedule_shifts_edit_until;
};

export const getValueToCompare = (input, state, modalObject) => {
  if (input === 'workingHours') {
    return extractShiftsFromEmployeesByDate(state.selectedEmployees, modalObject.date);
  }
  return undefined;
};

export const getSummaryErrorMessage = errors => errors.selectedEmployees || errors.repeatObj || '';
