import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import { emplMassEditTypes } from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { CONTRACTS_EDIT_SHOW } from '@/constants/Permissions';
import { CONTRACTS_EDIT_HIDE } from '@/constants/Restrictions';

const messages = defineMessages({
  [emplMassEditTypes.location]: { id: 'employees.editMass.location', defaultMessage: 'Lokalizacja' },
  [emplMassEditTypes.employmentConditions]: {
    id: 'employees.editMass.employmentConditions',
    defaultMessage: 'Warunki zatrudnienia',
  },
  [emplMassEditTypes.weeklyWorkingMinutes]: {
    id: 'employees.editMass.weeklyWorkingMinutes',
    defaultMessage: 'Wymiar czasu pracy',
  },
  [emplMassEditTypes.scheduleCycleStart]: {
    id: 'employees.editMass.startOfBillingPerion',
    defaultMessage: 'Początek okresu rozliczeniowego',
  },
  [emplMassEditTypes.scheduleCycleDuration]: {
    id: 'employees.editMass.billingPeriodDuration',
    defaultMessage: 'Długość okresu rozliczeniowego',
  },
  [emplMassEditTypes.role]: { id: 'employees.editMass.role', defaultMessage: 'Rola' },
  [emplMassEditTypes.elasticWorkDay]: {
    id: 'employees.editMass.elasticWorkDay',
    defaultMessage: 'Elastyczny czas pracy',
  },
  [emplMassEditTypes.limits]: { id: 'employees.editMass.limits', defaultMessage: 'Limity' },
  [emplMassEditTypes.contracts]: {
    id: 'employees.editMass.contracts',
    defaultMessage: 'Kontrakty',
    restriction: CONTRACTS_EDIT_HIDE,
    permission: CONTRACTS_EDIT_SHOW,
  },
});

const restrictionsForOptions = { [emplMassEditTypes.contracts]: CONTRACTS_EDIT_HIDE };
const permissionsForOptions = { [emplMassEditTypes.contracts]: CONTRACTS_EDIT_SHOW };

const EmployeesEditMassTypeSelect = ({ selected, onChange, userPermissions }, { intl }) => {
  const options = Object.entries(messages)
    .map(([key, value]) => {
      if (
        (restrictionsForOptions[key] && userPermissions.restrictions.includes(restrictionsForOptions[key])) ||
        (permissionsForOptions[key] && !userPermissions.permissions.includes(permissionsForOptions[key]))
      ) {
        return undefined;
      }
      return { key: intl.formatMessage(value), value: key };
    })
    .filter(Boolean);

  return (
    <MDSelect
      id="propertyType"
      name={<FormattedMessage id="employees.editMass.typesTitle" defaultMessage="Typ pola" />}
      options={options}
      defaultValue={selected}
      onChange={value => {
        onChange(value);
      }}
      closeOnClick
    />
  );
};

EmployeesEditMassTypeSelect.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeesEditMassTypeSelect.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default EmployeesEditMassTypeSelect;
