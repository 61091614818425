import { COLOR_PALETT_DEFAULT_COLOR } from '@/constants/colors';
import { parseFloatToHoursAndMinutes, parseHoursAndMinutesToFloat } from '@/utils/availabilitiesHelpers';

import { optionsMessages } from './AddAvaTypeModal.messages';

export const MODAL_MODIFIERS = ['narrow'];

export const INPUT_MODIFIERS = ['modal'];

export const getInitialState = {
  avaTypeName: '',
  avaShortName: '',
  color: COLOR_PALETT_DEFAULT_COLOR,
  requires_time: false,
  requires_approval: false,
  optional_comment: false,
  count_hours_in_payroll: false,
  is_not_available: true,
  avaCountHours: '__:__',
  visible_for_employees: true,
  errors: {},
};

export const getUpdatedStateForEditAvaType = (editedId, userCustomTypes) => {
  const avaType = userCustomTypes.find(({ id }) => editedId === id);
  return {
    avaTypeName: avaType.name,
    avaShortName: avaType.availability_short_name,
    color: avaType.color,
    requires_time: avaType.requires_time,
    requires_approval: avaType.requires_approval,
    optional_comment: avaType.optional_comment,
    count_hours_in_payroll: avaType.count_hours_in_payroll,
    is_not_available: !avaType.is_availability,
    visible_for_employees: avaType.visible_for_employees,
    avaCountHours: avaType.count_hours_in_payroll ? parseFloatToHoursAndMinutes(avaType.count_hours) : '__:__',
    errors: {},
  };
};

export const getOptionsForAvaTypes = (state, intl) => [
  {
    id: 'is_not_available',
    text: intl.formatMessage(optionsMessages.isNotAvailable),
    value: state.is_not_available,
  },
  {
    id: 'requires_time',
    text: intl.formatMessage(optionsMessages.requiresTime),
    value: state.requires_time,
  },
  {
    id: 'requires_approval',
    text: intl.formatMessage(optionsMessages.requiresApproval),
    value: state.requires_approval,
  },
  {
    id: 'optional_comment',
    text: intl.formatMessage(optionsMessages.optionalComment),
    value: state.optional_comment,
  },
  {
    id: 'visible_for_employees',
    text: intl.formatMessage(optionsMessages.visibleForEmployess),
    value: state.visible_for_employees,
  },
  {
    id: 'count_hours_in_payroll',
    text: intl.formatMessage(optionsMessages.countHoursInPayroll),
    value: state.count_hours_in_payroll,
  },
];

export const createAvaTypeObject = state => ({
  name: state.avaTypeName,
  availability_short_name: state.avaShortName,
  color: state.color,
  requires_time: state.requires_time,
  requires_approval: state.requires_approval,
  optional_comment: state.optional_comment,
  count_hours_in_payroll: state.count_hours_in_payroll,
  is_availability: !state.is_not_available,
  visible_for_employees: state.visible_for_employees,
  count_hours: state.count_hours_in_payroll ? parseHoursAndMinutesToFloat(state.avaCountHours) : 0,
  type: 'custom',
});
