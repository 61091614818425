import { GET_WEATHER_FORECAST_SUCCESS } from '../constants/ActionTypes';

const initialState = {};

const weatherForecast = (state = initialState, action) => {
  switch (action.type) {
    case GET_WEATHER_FORECAST_SUCCESS: {
      const weather = action.payload;
      return { ...state, ...weather };
    }
    default:
      return state;
  }
};

export default weatherForecast;
