import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';

import { KadroMultiSelect, MDCheckbox } from '@/components/common/inputs/MDComponents';
import { EXPORT_OPTIONS } from '@/constants/exportConstants';
import { createEvent } from '@/utils/inputHelpers';

import { getColumnsForFormat } from '../../../exports.helpers.ts';
import { CustomStepProps } from '../customSteps.types.ts';
import {
  selectExportOptions,
  updateColumns,
  updateColumnsSelection,
} from './ExportOptionsAndColumnConfigStep.helpers.ts';
import { getColumnsTitlesMessages } from './ExportOptionsAndColumnConfigStep.ts';

const ExportOptionsAndColumnConfig = ({ state, selectedFormat, handlers, multiSelects }: CustomStepProps, { intl }) => {
  const { options } = state;
  const { columnsItems, columnsSelectionHandlers } = multiSelects;
  const handleCheckbox = (e: { target: { id: string; checked: boolean } }) => {
    const { id, checked } = e.target;

    handlers.changeInput(createEvent('options', { ...options, [id]: checked }));
    const selectedFreeDaysColumns = updateColumns(columnsItems, id, checked);
    columnsSelectionHandlers.setItems(selectedFreeDaysColumns);
  };

  const selectAllOptionsWithDefaultOrder = useCallback(() => {
    columnsSelectionHandlers.setItems(getColumnsForFormat(selectedFormat));
  }, [columnsSelectionHandlers, getColumnsForFormat, selectedFormat]);

  useEffect(() => {
    const selectedOptions = selectExportOptions(columnsItems);
    handlers.updateState({ options: { ...options, ...selectedOptions } });
  }, [columnsItems]);

  useEffect(() => {
    const columnsForSelectedFormat = getColumnsForFormat(selectedFormat);
    const relevantColumn = updateColumnsSelection(columnsForSelectedFormat, columnsItems);
    columnsSelectionHandlers.setItems(relevantColumn);
  }, []);

  return (
    <>
      {selectedFormat.options.map(option => {
        const { type, id, text, tooltip } = option;
        switch (type) {
          case EXPORT_OPTIONS.CHECKBOX: {
            return (
              <MDCheckbox
                key={id}
                id={id}
                text={text}
                value={options[id]}
                onChange={handleCheckbox}
                tooltip={tooltip}
                reverse
              />
            );
          }
          case EXPORT_OPTIONS.MULTI_SELECT: {
            const columnsMessages = getColumnsTitlesMessages(intl, columnsItems);
            return (
              <KadroMultiSelect
                messages={columnsMessages}
                items={columnsItems}
                handlers={columnsSelectionHandlers}
                selectAllOptionsWithDefaultOrder={selectAllOptionsWithDefaultOrder}
                customHeight="250px"
                isFooterDraggable
              />
            );
          }
          default:
            return null;
        }
      })}
    </>
  );
};
ExportOptionsAndColumnConfig.contextTypes = {
  intl: PropTypes.shape({}),
};

export default ExportOptionsAndColumnConfig;
