import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import TopBarSearchInput from '../TopBarSearchInput/TopBarSearchInput.jsx';
import TopBarGroupedList from './TopBarGrouped/TopBarGroupedList.jsx';

const messages = defineMessages({
  defaultEmptyMessage: {
    id: 'topbarSingleChoice.defaultEmptyMessage',
    defaultMessage: 'Brak wyników',
  },
});

class TopBarSingleChoice extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      searchPhrase: '',
      isFocused: false,
      isDisabled: false,
    };
    bindPrototypeFunctions(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.blurHandler);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.objectsToChoose !== this.props.objectsToChoose) {
      this.setState({ isDisabled: !this.props.objectsToChoose.length || this.props.isDisabled });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.blurHandler);
  }

  setFocus() {
    if (this.state.isDisabled) {
      return;
    }

    this.setState(
      prev => ({
        isFocused: !prev.isFocused,
      }),
      () => {
        if (this.state.isFocused && this.searchInput) this.searchInput.setFocus();
      },
    );
  }

  searchFilterChange(e) {
    this.setState({ searchPhrase: e.target.value });
  }

  blurHandler(e) {
    if (this.singleChoiceRef && !this.singleChoiceRef.contains(e.target)) {
      this.setState({
        isFocused: false,
      });
    }
  }

  changeFunc(object) {
    this.props.onChange(object);
  }

  getLabelText() {
    const { labelText } = this.props;
    if (labelText) return labelText;

    const { emptyMessage, selected } = this.props;
    const { isDisabled } = this.state;
    const { intl } = this.context;
    return isDisabled ? intl.formatMessage(emptyMessage) : this.displayText(selected);
  }

  displayText(object) {
    return object[this.props.displayKey];
  }

  render() {
    const { hasGroups } = this.props;
    const className = classnames('k-topBarFilter k-topBarChoice', this.props.className, {
      'k-topBarChoice--focused': this.state.isFocused,
      'k-topBarChoice--short': this.props.icon,
      'k-topBarChoice--disabled': this.state.isDisabled,
    });

    let objectsToRender = this.props.objectsToChoose;

    if (!hasGroups) {
      objectsToRender = objectsToRender.filter(
        object =>
          object.id !== this.props.selected.id &&
          object[this.props.displayKey].toLowerCase().includes(this.state.searchPhrase.toLowerCase()),
      );
      objectsToRender = [this.props.selected, ...objectsToRender];
    } else {
      objectsToRender = objectsToRender.map(group => {
        const items =
          group.items?.filter(item =>
            item[this.props.displayKey].toLowerCase().includes(this.state.searchPhrase.toLowerCase()),
          ) || [];
        return { ...group, items };
      });
    }

    return (
      <div
        className={className}
        ref={el => {
          this.singleChoiceRef = el;
        }}
      >
        <div className="k-topBarChoice__selected" onClick={this.setFocus} role="presentation">
          <div className="k-topBarChoice__selectedContent">
            <span>{this.props.icon}</span>
            <span className="k-topBarChoice__selectedText">{this.getLabelText()}</span>
            <span className="k-topBarChoice__arrowDown" />
          </div>
        </div>
        <div
          className="k-topBarChoice__list"
          onBlur={this.blurHandler}
          tabIndex={0}
          role="option"
          aria-selected={false}
          data-test="topBarChoiceList"
        >
          {!this.props.hideSearch ? (
            <TopBarSearchInput
              onRef={ref => {
                this.searchInput = ref;
              }}
              searchPhrase={this.state.searchPhrase}
              onChange={this.searchFilterChange}
            />
          ) : null}

          {hasGroups ? (
            <TopBarGroupedList
              items={objectsToRender}
              selected={this.props.selected}
              onChange={this.changeFunc}
              showText={this.displayText}
            />
          ) : (
            objectsToRender.map(object => {
              const isSelected = object.id === this.props.selected.id;
              const elementClassName = classnames('k-topBarChoice__element', object.class_name, {
                'k-topBarChoice__element--paddingRight': object.elements,
                'k-topBarChoice__element--singleSelect': isSelected,
              });
              return (
                <button
                  key={object.id}
                  className={elementClassName}
                  onMouseDown={() => this.changeFunc(object)}
                  type="button"
                >
                  <span className="k-topBarChoice__displayText">
                    <span>{this.displayText(object)}</span>
                    {this.props.showCheckedIcon && isSelected && <i className="material-icons">check</i>}
                  </span>
                  {object.elements}
                </button>
              );
            })
          )}
        </div>
      </div>
    );
  }
}

TopBarSingleChoice.contextTypes = {
  intl: PropTypes.shape({}),
};

TopBarSingleChoice.defaultProps = {
  emptyMessage: messages.defaultEmptyMessage,
  isDisabled: false,
};

TopBarSingleChoice.propTypes = {
  className: PropTypes.string,
  displayKey: PropTypes.string.isRequired,
  emptyMessage: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  hasGroups: PropTypes.bool,
  hideSearch: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  labelText: PropTypes.string,
  objectsToChoose: PropTypes.arrayOf(PropTypes.shape()),
  selected: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  onChange: PropTypes.func,
  showCheckedIcon: PropTypes.bool,
};

export default TopBarSingleChoice;
