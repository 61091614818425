import { GET_ATTENDANCES_IMAGE_URLS_SUCCESFUL,GET_ATTENDANCES_IMAGES_SUCCESFUL } from '@/constants/ActionTypes.js';

const initialState = {
  data: {},
};

const rcpPhotos = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCES_IMAGES_SUCCESFUL: {
      const newData = { ...state.data };
      Object.keys(action.payload).forEach(key => {
        const oldValues = state.data[key] || { clockInUrl: null, clockOutUrl: null, urlReady: false };
        const { clockInUrl, clockOutUrl, urlReady } = oldValues;
        const newValues = action.payload[key];
        const newUrlStatus =
          urlReady && oldValues.clockIn === newValues.clock_in && oldValues.clockOut === newValues.clock_out;
        newData[key] = {
          clockIn: newValues.clock_in,
          clockOut: newValues.clock_out,
          clockOutUrl,
          clockInUrl,
          urlReady: newUrlStatus,
        };
      });
      return { ...state, data: newData };
    }
    case GET_ATTENDANCES_IMAGE_URLS_SUCCESFUL: {
      const { attendance_id: attendanceId, clock_in: clockInUrl, clock_out: clockOutUrl } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [attendanceId]: {
            ...state.data[attendanceId],
            clockInUrl,
            clockOutUrl,
            urlReady: true,
          },
        },
      };
    }

    default:
      return state;
  }
};
export default rcpPhotos;
