import { Cell, Legend,Pie,  PieChart as RechartPieChart, ResponsiveContainer, Tooltip } from 'recharts';

export type PieChartProps = {
    data: { name: string, value: number, color: string }[],
    height?: number,
}

export const PieChart = ({data, height = 300}: PieChartProps) => {
    return (
      <ResponsiveContainer width="100%" height={height}>
        <RechartPieChart width={400} height={height}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius={75}
            outerRadius={150}
            dataKey="value"
          >
            {data.map(({color, name}) => (
              <Cell key={`cell-${name}`} fill={color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            verticalAlign="top"
            align="left"
            layout="vertical"
            wrapperStyle={{
              left: 0,
              top: 0,
              paddingBottom: 10,
            }}/>
        </RechartPieChart>
      </ResponsiveContainer>
    );
}
