import { messages } from './NotificationsOptions.messages';

export const getNotificationsOptions = intl => [
  {
    name: intl.formatMessage(messages.sendNotification),
    type: 'notify',
  },
  {
    name: intl.formatMessage(messages.noNotify),
    type: 'noNotify',
  },
];
