import { connect } from 'react-redux';

import {
  changeReportsEmploymentConditionsFilter,
  changeReportsJobTitlesFilter,
  changeReportsLocationsFilter,
  getSalesReport,
  getWorkTimeReport,
  reportsDateChangeModeAndDate,
} from '@/actions/reports.js';
import { hideModal, showModal } from '@/actions/uiState.js';
import { REPORTS_FILTERS_MODAL } from '@/constants/modalTypes';

import ReportsFiltersModal from './ReportsFiltersModal';

const mapStateToProps = ({ reducer }) => ({
  isOpen: reducer.uiState.showModal === REPORTS_FILTERS_MODAL,
  mainDateStore: reducer.mainDateStore,
  locations: reducer.userLocations,
  jobTitles: reducer.userJobTitles,
  employmentConditions: reducer.employmentConditions,
  reportsFilters: reducer.reports.filters,
  reportsDate: reducer.reports.dateStore.customDate,
  isConfirmButtonDisabled:
    reducer.reports.filters.selectedLocations.length === 0 ||
    reducer.reports.filters.selectedJobTitles.length === 0 ||
    reducer.reports.filters.selectedEmploymentConditions.length === 0,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  getWorkTimeReport,
  getSalesReport,
  reportsDateChangeModeAndDate,
  changeReportsLocationsFilter,
  changeReportsJobTitlesFilter,
  changeReportsEmploymentConditionsFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFiltersModal);
