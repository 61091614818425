import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import KadroTableOptions from '@/components/common/KadroTableOptions';

const messages = defineMessages({
  absencesLimitsViewOptionsCalendar: {
    id: 'absence.options.calendar',
    defaultMessage: 'Kalendarz nieobecności',
  },
});

const AbsencesLimitsViewOptions = ({ showAbsenceCalendar, rowData }, { intl }) => {
  const options = [
    {
      icon: 'date_range',
      label: intl.formatMessage(messages.absencesLimitsViewOptionsCalendar, {}),
      onClick: async () => {
        await showAbsenceCalendar(rowData);
      },
    },
  ];

  return <KadroTableOptions options={options} />;
};

AbsencesLimitsViewOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsencesLimitsViewOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsencesLimitsViewOptions.propTypes = {
  rowData: PropTypes.shape({}),
  showAbsenceCalendar: PropTypes.func,
};

export default AbsencesLimitsViewOptions;
