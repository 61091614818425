import * as AT from '@/constants/ActionTypes';

const initialState = {};

const tradeShiftsRowSelect = (state = initialState, action) => {
  switch (action.type) {
    case AT.TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT: {
      const { locationId } = action.payroll;
      return { ...state, [locationId]: !state[locationId] };
    }
    case AT.TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT: {
      const { locationId } = action.payroll;
      return { ...state, [locationId]: false };
    }
    case AT.REPLACE_SCHEDULE_SELECTED_CELLS:
    case AT.ADD_SCHEDULE_SELECTED_CELLS:
    case AT.REMOVE_SCHEDULE_SELECTED_CELLS:
    case AT.CLEAR_ALL_SCHEDULE_SELECTED_CELLS:
      return { ...initialState };

    default:
      return state;
  }
};

export default tradeShiftsRowSelect;
