import classnames from 'classnames';
import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component } from 'react';

import CircleLoader from '@/components/common/UI/CircleLoader/CircleLoader.jsx';
import { KADRO_ORANGE_COLOR } from '@/constants/colors.js';
import { areDateArraysDifferent } from '@/utils/dateHelper.js';

import { SendPanelFooter, SendPanelHeader } from '../SendPanel';
import NotificationsOptions from './NotificationsOptions/NotificationsOptions.jsx';
import {
  countShifts,
  getEmployeesIdsForWorkingRules,
  getInitialState,
  isWorkingRulesEnabled,
  shouldShowWorkingRulesError,
} from './ScheduleSendPanel.helpers.js';
import { messages } from './ScheduleSendPanel.messages.js';
import ShiftsToPublishOptions from './ShiftsToPublishOptions/ShiftsToPublishOptions';
import ShiftsToPublishSummary from './ShiftsToPublishSummary/ShiftsToPublishSummary';

import './ScheduleSendPanel.scss';

class ScheduleSendPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = getInitialState();
    bindClassFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showSendPanel, locationSettings, scheduleLocationFilter, mainDateStore } = this.props;
    const showSendPanelChanged = showSendPanel && showSendPanel !== prevProps.showSendPanel;
    const isBudgetTargetEnabled = Object.values(locationSettings).some(settings => settings.enable_budget_target);

    if (
      (showSendPanelChanged || areDateArraysDifferent(prevProps.mainDateStore, mainDateStore)) &&
      isWorkingRulesEnabled(locationSettings, scheduleLocationFilter)
    ) {
      this.setState({ isWorkingRulesValidating: true }, () => this.verifyWorkingRules());
    }

    if (showSendPanelChanged && isBudgetTargetEnabled) {
      this.setState({ isBudgetTargetValidating: true }, () => this.verifyBudgetTarget());
    }
  }

  componentWillUnmount() {
    this.hideAndClear();
  }

  async verifyBudgetTarget() {
    try {
      const budgetTargetUsage = await this.props.getBudgetTargetUsage();

      const { hoursTargetExceeded, moneyTargetExceeded, isPublishBlockedByTarget } = budgetTargetUsage.reduce(
        (result, targetUsageItem) => {
          const relevantSettings = this.props.locationSettings[targetUsageItem.location_id];
          const isTargetMinutesDefined = targetUsageItem.target_minutes || targetUsageItem.target_minutes === 0;
          const isTargetMoneyDefined = targetUsageItem.target_money || targetUsageItem.target_money === 0;
          const newHoursTargetExceeded =
            result.hoursTargetExceeded ||
            (isTargetMinutesDefined && targetUsageItem.minutes_usage > targetUsageItem.target_minutes);
          const newMoneyTargetExceeded =
            result.moneyTargetExceeded ||
            (isTargetMoneyDefined && targetUsageItem.money_usage > targetUsageItem.target_money);
          const newIsPublishBlockedByTarget =
            (relevantSettings.budget_target_hours_blocks_publish &&
              isTargetMinutesDefined &&
              targetUsageItem.minutes_usage > targetUsageItem.target_minutes) ||
            (relevantSettings.budget_target_money_blocks_publish &&
              isTargetMoneyDefined &&
              targetUsageItem.money_usage > targetUsageItem.target_money);

          return {
            hoursTargetExceeded: newHoursTargetExceeded,
            moneyTargetExceeded: newMoneyTargetExceeded,
            isPublishBlockedByTarget: result.isPublishBlockedByTarget || newIsPublishBlockedByTarget,
          };
        },
        { hoursTargetExceeded: false, moneyTargetExceeded: false, isPublishBlockedByTarget: false },
      );

      this.setState({
        isBudgetTargetValidating: false,
        hoursTargetExceeded,
        moneyTargetExceeded,
        isPublishBlockedByTarget,
      });
    } catch (err) {
      this.setState({
        isBudgetTargetValidating: false,
        hoursTargetExceeded: false,
        moneyTargetExceeded: false,
        isPublishBlockedByTarget: false,
      });
    }
  }

  async verifyWorkingRules() {
    const { userEmployees, scheduleLocationFilter, mainDateStore, scheduleState } = this.props;
    const relevantEmployeeIds = getEmployeesIdsForWorkingRules(scheduleLocationFilter, mainDateStore, userEmployees);
    try {
      const workingRules = await this.props.getWorkingRulesViolationsForCurrentDateArray(relevantEmployeeIds, true);

      const showWorkingRulesError = shouldShowWorkingRulesError(
        workingRules,
        scheduleLocationFilter,
        scheduleState,
        userEmployees,
      );
      this.setState({ isWorkingRulesValidating: false, showWorkingRulesError });
    } catch (err) {
      this.setState({ isWorkingRulesValidating: false, showWorkingRulesError: false });
    }
  }

  hideAndClear() {
    this.setState(getInitialState());
    this.props.changeSendPanelVisibility(false);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
  }

  publish() {
    const { publishShifts, publishOpenShifts, sendNotif, comment, sendSms } = this.state;
    this.props.publishDraftShifts(publishShifts, publishOpenShifts, sendNotif, comment, sendSms);
    this.hideAndClear();
  }

  render() {
    const {
      scheduleLocationFilter,
      scheduleState,
      mainDateStore,
      userEmployees,
      userJobTitles,
      openShifts,
      selectedJobTitles,
    } = this.props;
    const panelClass = classnames('send_panel', { open: this.props.showSendPanel });
    const { numberOfDraftShifts, numberOfDraftOpenShifts, numberOfShiftsWithErrors } = countShifts({
      scheduleLocationFilter,
      scheduleState,
      mainDateStore,
      userEmployees,
      userJobTitles,
      openShifts,
      selectedJobTitles,
    });
    const publishDisabled =
      (numberOfShiftsWithErrors ||
        this.state.showWorkingRulesError ||
        this.state.hoursTargetExceeded ||
        this.state.moneyTargetExceeded) &&
      this.state.publishShifts &&
      numberOfDraftShifts > 0;

    const isPublishBlockedByErrors = numberOfShiftsWithErrors && this.state.publishShifts;
    const isPublishBlockedByWorkingRules = this.state.showWorkingRulesError && this.state.publishShifts;
    const isPublishBlockedByBudgetTargets =
      (this.state.hoursTargetExceeded || this.state.moneyTargetExceeded) &&
      this.state.publishShifts &&
      numberOfDraftShifts > 0 &&
      this.state.isPublishBlockedByTarget;

    const isPublishBlocked =
      isPublishBlockedByErrors || isPublishBlockedByWorkingRules || isPublishBlockedByBudgetTargets;
    const allowToForcePublish = publishDisabled && !isPublishBlocked;
    const showLoader = this.state.isWorkingRulesValidating || this.state.isBudgetTargetValidating;

    return (
      <div className={panelClass}>
        <CircleLoader show={showLoader} circleStrokeColor={KADRO_ORANGE_COLOR} modifier="white" />
        <SendPanelHeader onClose={this.hideAndClear} title={this.context.intl.formatMessage(messages.sendPanelTitle)} />
        <OverlayScrollbarsComponent className="k-overlay-scrollbar scheduleSendPanel__overlay">
          <div className="content_pane">
            <ShiftsToPublishOptions
              publishShifts={this.state.publishShifts}
              publishOpenShifts={this.state.publishOpenShifts}
              numberOfDraftShifts={numberOfDraftShifts}
              numberOfDraftOpenShifts={numberOfDraftOpenShifts}
              handleInputChange={this.handleInputChange}
            />
            <NotificationsOptions
              sendNotif={this.state.sendNotif}
              sendSms={this.state.sendSms}
              comment={this.state.comment}
              handleInputChange={this.handleInputChange}
            />
            <ShiftsToPublishSummary
              numberOfShiftsWithErrors={numberOfShiftsWithErrors}
              numberOfDraftShifts={numberOfDraftShifts}
              publishShifts={this.state.publishShifts}
              showWorkingRulesError={this.state.showWorkingRulesError}
              showHoursTargetError={this.state.hoursTargetExceeded && !this.state.moneyTargetExceeded}
              showMoneyTargetError={this.state.moneyTargetExceeded && !this.state.hoursTargetExceeded}
              showHoursAndMoneyTargetError={this.state.hoursTargetExceeded && this.state.moneyTargetExceeded}
              showWorkingRulesWarning={this.props.showWorkingRulesWarning}
              customDate={this.props.mainDateStore.customDate}
            />
            <SendPanelFooter
              submitText={this.context.intl.formatMessage(messages.publish)}
              submitDisabled={publishDisabled}
              onCancel={this.hideAndClear}
              onSubmit={this.publish}
              allowToForcePublish={allowToForcePublish}
            />
          </div>
        </OverlayScrollbarsComponent>
      </div>
    );
  }
}

ScheduleSendPanel.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleSendPanel.propTypes = {
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  openShifts: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  showSendPanel: PropTypes.bool,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  locationSettings: PropTypes.shape({}),
  getWorkingRulesViolationsForCurrentDateArray: PropTypes.func,
  publishDraftShifts: PropTypes.func,
  changeSendPanelVisibility: PropTypes.func,
  showWorkingRulesWarning: PropTypes.bool,
  scheduleState: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  getBudgetTargetUsage: PropTypes.func,
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ScheduleSendPanel;
