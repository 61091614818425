/* eslint-disable max-len */
import ApplyForOpenShiftModalContainer from '@/components/scheduler/modals/ApplyForOpenShiftModal/ApplyForOpenShiftModal.redux';
import EventsTable from '@/components/scheduler/ScheduleTable/EventsTable/EventsTable.redux.js';
import TopBarSchedule from '@/components/TopBars/TopBarSchedule/TopBarSchedule.redux.js';
import CoworkerTradeShiftModalContainer from '@/containers/scheduler/CoworkerTradeShiftModalContainer.jsx';
import RequestTradeShiftModalContainer from '@/containers/scheduler/RequestTradeShiftModalContainer.jsx';
import { useChangeDateByArrow } from '@/hooks';

import EmployeeScheduleAllTables from '../EmployeeScheduleAllTables/EmployeeScheduleAllTables.redux';
import EmployeeScheduleButtonBar from '../EmployeeScheduleButtonBar/EmployeeScheduleButtonBar.redux';

const EmployeeScheduleView = () => {
  useChangeDateByArrow();

  const scheduleModals = (
    <>
      <ApplyForOpenShiftModalContainer />
      <CoworkerTradeShiftModalContainer />
      <RequestTradeShiftModalContainer />
    </>
  );

  return (
    <div>
      <TopBarSchedule isEmployee />
      <div className="k-wrapper k-wrapper--tablePadding animated fadeInRight">
        {scheduleModals}
        <EmployeeScheduleButtonBar />
        <EmployeeScheduleAllTables />
        <EventsTable />
      </div>
    </div>
  );
};

export default EmployeeScheduleView;
