import { connect } from 'react-redux';

import { prepareAddJobTitle, prepareChangeJobTitle, toggleModal } from '@/actions/companymanage';

import AddJobTitleModal from './AddJobTitleModal.jsx';

const mapStateToProps = ({ reducer }) => ({
  restrictions: reducer.userPermissions.restrictions,
  permissions: reducer.userPermissions.permissions,
  visible: reducer.listsUi.jobTitles.showModal,
  editedElementId: reducer.listsUi.jobTitles.editedId,
  userJobTitles: reducer.userJobTitles,
  userEmployees: reducer.userEmployees,
  contracts: reducer.contracts,
});

const mapDispatchToProps = {
  prepareChangeJobTitle,
  prepareAddJobTitle,
  toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJobTitleModal);
