import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes.js';

const initialState = {
  data: [],
};

const loanEmployeesProposals = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.GET_LOAN_EMPLOYEES_PROPOSALS_SUCCESSFUL: {
        const loanEmployeesProposalsData = action.payload.loanEmployeesProposals.filter(
          proposal => proposal.employees_remaining !== 0,
        );

        draft.data = loanEmployeesProposalsData;
        break;
      }
      case actionTypes.ACCEPT_LOAN_EMPLOYEES_PROPOSAL_SUCCESSFUL: {
        const newProposal = action.payload.loanEmployeesProposal;
        if (newProposal.employees_remaining === 0) {
          draft.data = draft.data.filter(proposal => proposal.id !== newProposal.id);
        } else {
          draft.data = draft.data.map(proposal => (proposal.id === newProposal.id ? newProposal : proposal));
        }
        break;
      }

      case actionTypes.CREATE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL: {
        const newProposal = action.payload.loanEmployeesProposal;
        draft.data.push(newProposal);
        break;
      }

      case actionTypes.DELETE_LOAN_EMPLOYEES_POROPOSAL_SUCCESSFUL: {
        draft.data = draft.data.filter(proposal => proposal.id !== action.payload);
        break;
      }

      default:
        return state;
    }
  });

export default loanEmployeesProposals;
