import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import './MDProgressRing.scss';

class MDProgressRing extends Component {
  constructor(props) {
    super(props);
    const { radius, stroke } = this.props;
    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, strokeColor, strokeColorActive, children, animated } = this.props;
    const progressNormed = progress > 100 ? 100 : progress;
    const strokeDashoffset = this.circumference - (progressNormed / 100) * this.circumference;
    const angle = (360 * progressNormed) / 100 - 90;
    const circleClassName = classNames('mdProgressRing__circle', {
      'mdProgressRing__circle--active': animated,
    });

    return (
      <div className="mdProgressRing">
        <div className="mdProgressRing__text">{children || `${progress}%`}</div>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            className={circleClassName}
            stroke={strokeColorActive}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${this.circumference} ${this.circumference}`}
            style={{
              strokeDashoffset,
              transform: 'rotate(-90deg)',
            }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className="mdProgressRing__circle"
            stroke={strokeColor}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${this.circumference} ${this.circumference}`}
            style={{ strokeDashoffset: this.circumference - strokeDashoffset, transform: `rotate(${angle}deg)` }}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </div>
    );
  }
}

MDProgressRing.propTypes = {
  strokeColorActive: PropTypes.string,
  strokeColor: PropTypes.string,
  progress: PropTypes.number.isRequired,
  animated: PropTypes.bool,
  stroke: PropTypes.number,
  radius: PropTypes.number,
  children: PropTypes.node,
};
MDProgressRing.defaultProps = {
  animated: true,
  strokeColorActive: '#ff6b01',
  strokeColor: '#dddddd',
};

export default MDProgressRing;
