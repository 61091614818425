import { defineMessages } from 'react-intl';

export default defineMessages({
  budgetMetricsTableName: {
    id: 'budgetMetricsTable.name',
    defaultMessage: 'Nazwa metryki',
  },
  budgetMetricsTableLocations: {
    id: 'budgetMetricsTable.locations',
    defaultMessage: 'Lokalizacje',
  },
  budgetMetricsTableFormat: {
    id: 'budgetMetricsTable.format',
    defaultMessage: 'Format',
  },
  budgetMetricsTableTimeInterval: {
    id: 'budgetMetricsTable.timeInterval',
    defaultMessage: 'Przedział czasowy',
  },
  budgetMetricsTableIcon: {
    id: 'budgetMetricsTable.icon',
    defaultMessage: 'Ikonka',
  },
  percent: {
    id: 'budgetMetricTable.format.percent',
    defaultMessage: 'Procentowy',
  },
  number: {
    id: 'budgetMetricTable.format.number',
    defaultMessage: 'Liczbowy',
  },
  unit: {
    id: 'budgetMetricTable.format.unit',
    defaultMessage: 'Jednostkowy',
  },
  hour: {
    id: 'budgetMetricTable.format.hour',
    defaultMessage: 'Godzinowy',
  },
  currency: {
    id: 'budgetMetricTable.format.currency',
    defaultMessage: 'Walutowy',
  },
  budgetMetricsTableNoLocations: {
    id: 'budgetMetricsTable.noLocations',
    defaultMessage: 'Brak lokalizacji',
  },
  budgetMetricsTableDailyInterval: {
    id: 'budgetMetricsTable.timeInterval.daily',
    defaultMessage: 'Dniowy',
  },
  budgetMetricsTableMinutelyInterval: {
    id: 'budgetMetricsTable.timeInterval.minutely',
    defaultMessage: '{value} minutowy',
  },
});
