import { SCHEDULE_AND_SETTLE_PACKAGE_ID } from './PackagesOptions/PackagesOptions.helpers';
import { messagesForIntercom } from './SelectPackageContent.messages';

export const DEFAULT_EMPLOYEE_AMOUNT = 25;

export const initialState = {
  payment: 'monthly',
  selectedPackage: { id: SCHEDULE_AND_SETTLE_PACKAGE_ID },
  employeesAmount: DEFAULT_EMPLOYEE_AMOUNT,
};

export const getIntercomMessage = (intl, selectedPackage, employeesAmount, payment) => {
  const paymentPeriod = intl.formatMessage(messagesForIntercom[payment === 'monthly' ? 'oneMonth' : 'twelveMonths']);
  const goodMorningText = intl.formatMessage(messagesForIntercom.goodMorning);
  const messageText = intl.formatMessage(messagesForIntercom.buyPackage, {
    selectedPackageName: selectedPackage.name,
    employeesAmount: employeesAmount || 0,
    paymentPeriod,
  });
  const message = `
${goodMorningText},
${messageText}
  `;
  return message;
};
