import { messages } from './messages.js';

export const columns = [
  {
    Header: messages.absencesViewTableNr,
    accessor: 'request_number',
  },
  {
    Header: messages.absencesViewTableEmployeeName,
    accessor: 'employee',
    hideForEmployee: true,
  },
  {
    Header: messages.absencesViewTableTypeName,
    accessor: 'absence_type_name',
  },
  {
    Header: messages.absencesViewTableFrom,
    accessor: 'from',
  },
  {
    Header: messages.absencesViewTableTo,
    accessor: 'to',
  },
  {
    Header: messages.absencesViewTableRecentApprovedBy,
    accessor: 'recent_status_change_by',
  },
  {
    Header: messages.absencesViewTableStatusName,
    accessor: 'statusChip',
  },
];
