 
import { checkArrayIntersection } from './arrayHelpers.js';
import { companyManageRoutes } from './routes';

export const getDefaultCompanyManagePage = (restrictions, permissions) => {
  const employeesRoute = companyManageRoutes.find(route => route.path === '/companymanage/employees');
  
  if (
    !checkArrayIntersection(restrictions, employeesRoute.restrictions) &&
    (!employeesRoute.permission || permissions.includes(employeesRoute.permission))
  ) {
    return employeesRoute.path;
  }

  let defaultPath = '/';
  companyManageRoutes.some(route => {
    if (
      !checkArrayIntersection(restrictions, route.restrictions) &&
      (!route.permission || permissions.includes(route.permission))
    ) {
      defaultPath = route.path;
      return true;
    }

    return false;
  });

  return defaultPath;
};

export const findFirstUnrestrictedNav = (navs, restrictions, startingPath) => {
  let startIndex = 0;
  if (startingPath) {
    startIndex = navs.findIndex(nav => nav.path === startingPath);
    if (startIndex === -1) {
      return null;
    }
  }

  for (let i = startIndex; i < navs.length; i++) {
    const nav = navs[i];
    if (!nav.restriction || !nav.restriction.some(r => restrictions.includes(r))) {
      return nav;
    }
  }
  return null;
};
