import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chartTitle: {
    id: 'jobtTitlesChart.chartTitle',
    defaultMessage: 'Dzienny udział stanowisk',
  },
  otherJobTitles: { id: 'jobTitlesChart.otherJobTitles', defaultMessage: 'Pozostałe' },
  unknownJobTitle: {
    id: 'jobtTitlesChart.unknownJobTitle',
    defaultMessage: 'Brak stanowiska',
  },
  tooltipMessage: {
    id: 'jobtTitlesChart.tooltipMessage',
    defaultMessage:
      'Udział poszczególnych stanowisk względem sumarycznej wartości dla danego dnia wg wybranego parametru.',
  },
});
