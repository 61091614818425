import { useEffect, useState } from 'react';

const useFetchData = <T>(fetchFunction: () => { data: T }, shouldFetch = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!shouldFetch) {
        return;
      }
      setIsError(false);
      setIsLoading(true);

      try {
        const result = await fetchFunction();
        setData(result.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return {
    isLoading,
    isError,
    data,
  };
};

export default useFetchData;
