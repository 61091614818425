import classNames from 'classnames';

import { getBem } from '@/utils/styles';

export const getClasses = (isOwner: boolean) => {
  const bem = getBem('k-multiAccounts');
  return {
    container: classNames(bem.block, isOwner && bem.modifier('isOwner')),
    spaceContainer: bem.element('spaceContainer'),
  };
};
