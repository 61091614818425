import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import KadroCalendarPopoverInput from '@/components/common/inputs/KadroCalendarPopoverInput';
import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { COUNT_DAYS_WITH_SHIFTS_HIDE, SKIP_WEEKENDS_AND_HOLIDAYS_HIDE } from '@/constants/Restrictions.js';
import { convertDateToStandardFormat, getCalendarRangeText } from '@/utils/dateHelper.js';
import { createEvent } from '@/utils/inputHelpers.js';

import { messages } from './WholeDayAbsenceOptions.messages';

import './WholeDayAbsenceOptions.scss';

export const WholeDayAbsenceOptions = (props, { intl }) => {
  const {
    absenceOmitWeekends,
    absenceOmitHolidays,
    countOnlyDaysWithShifts,
    handleInputChange,
    selectedAbsenceRange,
    isEmployee,
    absenceTypeWithLimit,
    countOnlyDaysWithShiftsSelectedByDefault,
    restrictions,
  } = props;

  const changeAbsenceOmitWeekends = useCallback(
    () => handleInputChange(createEvent('absenceOmitWeekends', !absenceOmitWeekends)),
    [absenceOmitWeekends],
  );

  const changeAbsenceOmitHolidays = useCallback(
    () => handleInputChange(createEvent('absenceOmitHolidays', !absenceOmitHolidays)),
    [absenceOmitHolidays],
  );

  const changeCountOnlyDaysWithShifts = useCallback(
    () => handleInputChange(createEvent('countOnlyDaysWithShifts', !countOnlyDaysWithShifts)),
    [countOnlyDaysWithShifts],
  );

  const changeSelectedRange = useCallback(e => {
    handleInputChange(
      createEvent('selectedAbsenceRange', {
        start: convertDateToStandardFormat(e.start),
        end: convertDateToStandardFormat(e.end),
      }),
    );
  }, []);

  const showSkipWeekendsAndHolidays = useMemo(
    () => !restrictions.includes(SKIP_WEEKENDS_AND_HOLIDAYS_HIDE),
    [restrictions],
  );

  const formattedCountOnlyDaysWithShifts =
    absenceTypeWithLimit && countOnlyDaysWithShiftsSelectedByDefault
      ? !countOnlyDaysWithShifts
      : countOnlyDaysWithShifts;

  return (
    <>
      <KadroCalendarPopoverInput
        inputValue={getCalendarRangeText(selectedAbsenceRange.start, selectedAbsenceRange.end)}
        selectedRange={selectedAbsenceRange}
        onRangeChange={changeSelectedRange}
      />
      {showSkipWeekendsAndHolidays && (
        <div className="wholeDayAbsenceOptions__checkboxInputs">
          <MDCheckbox
            value={absenceTypeWithLimit || absenceOmitWeekends}
            text={intl.formatMessage(messages.absencesOmitWeekendsCheckbox, {})}
            onChange={changeAbsenceOmitWeekends}
            tooltip={intl.formatMessage(messages.absenceOmitWeekends)}
            reverse
          />
          <MDCheckbox
            value={absenceTypeWithLimit || absenceOmitHolidays}
            text={intl.formatMessage(messages.absencesOmitHolidaysCheckbox, {})}
            onChange={changeAbsenceOmitHolidays}
            tooltip={intl.formatMessage(messages.absenceOmitHolidays)}
            reverse
          />
        </div>
      )}
      <FeatureWrapper restriction={COUNT_DAYS_WITH_SHIFTS_HIDE} hide={isEmployee}>
        <div className="addAbsenceTab__checkboxInputs">
          <MDCheckbox
            value={formattedCountOnlyDaysWithShifts}
            text={intl.formatMessage(messages.countOnlyDaysWithShifts, {})}
            onChange={changeCountOnlyDaysWithShifts}
            reverse
            tooltip={intl.formatMessage(messages.countOnlyDaysWithShiftsTooltip)}
          />
        </div>
      </FeatureWrapper>
    </>
  );
};

WholeDayAbsenceOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

WholeDayAbsenceOptions.propTypes = {
  absenceOmitWeekends: PropTypes.bool,
  absenceOmitHolidays: PropTypes.bool,
  handleInputChange: PropTypes.func,
  selectedAbsenceRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  countOnlyDaysWithShifts: PropTypes.bool,
  isEmployee: PropTypes.bool,
  absenceTypeWithLimit: PropTypes.bool,
  countOnlyDaysWithShiftsSelectedByDefault: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default memo(WholeDayAbsenceOptions);
