import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { employmentConditionsIds } from '@/constants/employmentConditions.js';
import { divideEmploymentConditionsIntoTypes } from '@/utils/employmentConditionsHelpers.ts';

import EmploymentConditionsTableRow from '../EmploymentConditionsTableRow/EmploymentConditionsTableRow.redux.js';
import EmploymentConditionsTableRowTitle from '../EmploymentConditionsTableRowTitle/EmploymentConditionsTableRowTitle.jsx';

import './employmentConditionsTable.scss';

const messages = defineMessages({
  emptyStateFilterTitle: {
    id: 'companyManage.employmentConditionsTable.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.employmentConditionsTable.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaFilterText: {
    id: 'companyManage.employmentConditionsTable.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

class EmploymentConditionsTable extends Component {
  getEmptyStateView() {
    const headerText = this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
    const ctaText = this.context.intl.formatMessage(messages.ctaFilterText, {});
    const ctaHandler = this.props.clearSearchWord;
    return (
      <tr>
        <td className="no-content-to-show" colSpan="4">
          <EmptyState
            style={{ 'padding-right': '16vw' }}
            name="scheduleCTA"
            imgSrc="/img/scheduleCTA.png"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
          />
        </td>
      </tr>
    );
  }

  render() {
    const { employmentConditions, searchString, listsUi, changeSorting } = this.props;

    const filteredConditions = employmentConditions.filter(condition =>
      JSON.stringify(condition)
        .toLowerCase()
        .includes(searchString.toLowerCase()),
    );

    const conditions = divideEmploymentConditionsIntoTypes(filteredConditions, employmentConditionsIds);

    const relevantConditions = [
      ...conditions.default.sort((a, b) => {
        if (listsUi.employmentConditions.sortDecreesing) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
      ...conditions.custom.sort((a, b) => {
        if (listsUi.employmentConditions.sortDecreesing) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),
    ];

    return (
      <div style={{ clear: 'both' }}>
        <Grid
          width={700}
          height={500}
          columnStyle={{ width: '16vw', minWidth: '270px' }}
          columnGroup={
            relevantConditions.length ? (
              <colgroup>
                <col style={{ width: '40%' }} />
                <col style={{ width: '40%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
            ) : null
          }
        >
          <LeftHeader>
            <th
              onClick={() => {
                changeSorting(0, 'employmentConditions');
              }}
              className="tab_title k-employmentConditionsHeader__headerCell k-employmentConditionsHeader__headerCell--sortable"
            >
              <span role="none">
                <FormattedMessage id="companyManage.employmentConditions.name" defaultMessage="Nazwa warunku" />
              </span>
              <SortingArrow
                display={listsUi.employmentConditions.sortColumn === 0}
                up={!listsUi.employmentConditions.sortDecreesing}
              />
            </th>
          </LeftHeader>
          <Header>
            <thead className="k-employmentConditionsHeader">
              <tr>
                <th className="k-employmentConditionsHeader__headerCell">
                  <FormattedMessage
                    id="companymanage.employmentConditionsHeader.weeklyWorkingHours"
                    defaultMessage="Tygodniowy wymiar"
                  />
                </th>
                <th className="k-employmentConditionsHeader__headerCell">
                  <FormattedMessage
                    id="companymanage.employmentConditionsHeader.dailyWorkingHours"
                    defaultMessage="Dobowy wymiar"
                  />
                </th>
                <th className="k-employmentConditionsHeader__headerCell">
                  <FormattedMessage id="companymanage.employmentConditionsHeader.options" defaultMessage="Opcje" />
                </th>
              </tr>
            </thead>
          </Header>
          <LeftColumn className={!relevantConditions.length ? 'empty' : ''}>
            {!relevantConditions.length ? (
              <tr>
                <th className="no-content-to-show k-employmentConditionsHeader__headerCell" />
              </tr>
            ) : (
              relevantConditions.map(condition => (
                <EmploymentConditionsTableRowTitle
                  employmentCondition={condition}
                  key={condition.id}
                  listsUi={listsUi}
                />
              ))
            )}
          </LeftColumn>
          <Rows className="table_company">
            {!relevantConditions.length
              ? this.getEmptyStateView()
              : relevantConditions.map(condition => (
                  <EmploymentConditionsTableRow employmentCondition={condition} key={condition.id} />
                ))}
          </Rows>
        </Grid>
      </div>
    );
  }
}

EmploymentConditionsTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmploymentConditionsTable.propTypes = {
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  searchString: PropTypes.string,
  listsUi: PropTypes.shape({
    employmentConditions: PropTypes.shape({
      sortDecreesing: PropTypes.bool,
      sortColumn: PropTypes.number,
    }),
  }),
  changeSorting: PropTypes.func,
  clearSearchWord: PropTypes.func,
};

export default EmploymentConditionsTable;
