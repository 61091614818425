import {
  END_ATTENDANCE_SUCCESFUL,
  END_BREAK_SUCCESFUL,
  GET_RCP_FAILURE,
  GET_RCP_SUCCESS,
  START_ATTENDANCE_SUCCESFUL,
  START_BREAK_SUCCESFUL,
} from '@/constants/ActionTypes';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_RCP_SUCCESS:
      return payload;
    case GET_RCP_FAILURE:
      return state;

    case START_ATTENDANCE_SUCCESFUL:
      return [...state, payload.createdAttendance];
    case END_ATTENDANCE_SUCCESFUL:
      return state.filter(att => att.id !== payload.newAttendance.id);

    case START_BREAK_SUCCESFUL:
      return state.map(att => (att.id === payload.attendance_id ? { ...att, breaks: [...att.breaks, payload] } : att));
    case END_BREAK_SUCCESFUL:
      return state.map(att => {
        if (att.id === payload.attendance_id) {
          const breaks = att.breaks.map(br => (br.id === payload.id ? payload : br));
          return { ...att, breaks };
        }
        return att;
      });

    default:
      return state;
  }
};
