import { defineMessages } from 'react-intl';

import {
  CALCULATE_SPMH_BASED_BONUSES_FAILURE,
  CALCULATE_SPMH_BASED_BONUSES_SUCCESS,
  CHANGE_BONUS_SYSTEM_SETTINGS_ERROR,
  CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS,
  CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS_MASS,
} from '@/constants/ActionTypes.js';
import { getJobTitleIdsFromContracts, getRelevantContractsForMultipleDates } from '@/utils/contracts';

import { conn } from './index';
import { getPayrollViewData } from './payroll/payroll';
import { getPayrollLocationViewData } from './payrollLocation/payrollLocation.js';

const messages = defineMessages({
  calculateBonusesError: {
    id: 'payroll.calculateBonusesError',
    defaultMessage: 'Nie udało się obliczyć bonusów',
  },
  calculateBonusesSuccess: {
    id: 'payroll.calculateBonusesSuccess',
    defaultMessage: 'Bonusy zostały obliczone',
  },
  changeBonusSettingsError: {
    id: 'spmhBasedBonuses.changeBonusSettingsError',
    defaultMessage: 'Nie udało się zmienić ustawień bonusów.',
  },
});

const formatBonusHourBlocks = (locationId, bonusHoursBlocks) =>
  bonusHoursBlocks.map(hb => {
    const [startHour, endHour] = hb.workingHours.split('-');
    return { location_id: locationId, start_hour: startHour, end_hour: endHour };
  });

const formatBonusThresholds = (locationId, bonusTargetOptions) =>
  bonusTargetOptions.map(option => ({
    location_id: locationId,
    bonus_percentage: Number(option.bonusPercentage),
    spmh_threshold: Number(option.spmhThreshold),
  }));

const changeBonusSystemSettingsError = () => (dispatch, getState, intl) => {
  dispatch({
    type: CHANGE_BONUS_SYSTEM_SETTINGS_ERROR,
    notification: {
      title: intl.formatMessage(messages.changeBonusSettingsError),
      type: 'error',
    },
  });
};

export const changeBonusSystemSettingsMass = ({
  bonus_hours_blocks: bonusHoursBlocks,
  bonus_target_options: bonusTargetOptions,
  location_system_bonus_enabled: locationSystemBonusEnabled,
}) => async (dispatch, getState) => {
  try {
    if (!locationSystemBonusEnabled) return;
    const { userLocations } = getState().reducer;
    const [formattedBonusHourBlocks, formattedBonusThresholds] = [[], []];
    userLocations.forEach(({ id: locationId }) => {
      formattedBonusHourBlocks.push(...formatBonusHourBlocks(locationId, bonusHoursBlocks));
      formattedBonusThresholds.push(...formatBonusThresholds(locationId, bonusTargetOptions));
    });
    await conn.changeBonusSettings({
      hour_blocks: formattedBonusHourBlocks,
      bonus_thresholds: formattedBonusThresholds,
    });
    dispatch({
      type: CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS_MASS,
      payload: {
        hourBlocks: formattedBonusHourBlocks,
        bonusThresholds: formattedBonusThresholds,
      },
    });
  } catch (err) {
    dispatch(changeBonusSystemSettingsError());
  }
};

export const changeBonusSystemSettings = (settings, locationId) => async dispatch => {
  const {
    bonus_hours_blocks: bonusHoursBlocks,
    bonus_target_options: bonusTargetOptions,
    location_system_bonus_enabled: locationSystemBonusEnabled,
  } = settings;
  try {
    if (!locationSystemBonusEnabled) return;
    const formattedHourBlocks = formatBonusHourBlocks(locationId, bonusHoursBlocks);
    const formattedBonusThresholds = formatBonusThresholds(locationId, bonusTargetOptions);
    await conn.changeBonusSettings({ hour_blocks: formattedHourBlocks, bonus_thresholds: formattedBonusThresholds });
    dispatch({
      type: CHANGE_BONUS_SYSTEM_SETTINGS_SUCCESS,
      payload: {
        hourBlocks: formattedHourBlocks,
        bonusThresholds: formattedBonusThresholds,
        locationId,
      },
    });
  } catch (err) {
    dispatch(changeBonusSystemSettingsError());
  }
};

export const calculateSpmhBasedBonuses = employeeIds => async (dispatch, getState, intl) => {
  const { multipleLocationFilter, mainDateStore } = getState().reducer;
  const locationIds = multipleLocationFilter;
  const { start, end } = mainDateStore.customDate;

  try {
    const { data } = await conn.calculateSpmhBasedBonuses(employeeIds, locationIds, start, end);

    dispatch({
      type: CALCULATE_SPMH_BASED_BONUSES_SUCCESS,
      payload: data,
      notification: {
        title: intl.formatMessage(messages.calculateBonusesSuccess),
        type: 'success',
      },
    });
  } catch (error) {
    dispatch({
      type: CALCULATE_SPMH_BASED_BONUSES_FAILURE,
      notification: {
        title: intl.formatMessage(messages.calculateBonusesError),
        type: 'error',
      },
    });
  }
};

export const calculateSpmhBasedBonusesForNewPayroll = () => (dispatch, getState) => {
  const { singleEmployeeFilter } = getState().reducer;
  if (!singleEmployeeFilter.id) return;
  dispatch(calculateSpmhBasedBonuses([singleEmployeeFilter.id])).then(() => {
    dispatch(getPayrollViewData());
  });
};

export const calculateSpmhBasedBonusesForNewPayrollLocation = () => (dispatch, getState) => {
  const { payrollLocation } = getState().reducer;
  const employeesIds = payrollLocation.data.map(({ employee_id: employeeId }) => employeeId);
  dispatch(calculateSpmhBasedBonuses(employeesIds)).then(() => dispatch(getPayrollLocationViewData()));
};

const getEmployeesIdsForSystemBonus = (reports, userEmployees, contracts, from, to) => {
  const { selectedJobTitles: selectedJobTitlesIds, selectedEmploymentConditions } = reports.filters;
  const selectedEmploymentConditionsIds =
    typeof selectedEmploymentConditions[0] === 'object'
      ? selectedEmploymentConditions.map(({ id }) => id)
      : selectedEmploymentConditions;

  return userEmployees.reduce((idsList, employee) => {
    const isEmployeeEmploymentConditionSelected = selectedEmploymentConditionsIds.includes(
      employee.employment_conditions.template_id,
    );
    if (!isEmployeeEmploymentConditionSelected) return idsList;

    const employeeContracts = contracts[employee.id] || [];
    const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
    const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
    const isEmployeeJobTitleSelected = employeeJobTitleIds.some(jobTitleIds =>
      selectedJobTitlesIds.includes(jobTitleIds),
    );

    return isEmployeeJobTitleSelected ? [...idsList, employee.id] : idsList;
  }, []);
};

export const getBonuses = () => async (dispatch, getState) => {
  const { reports, userEmployees, contracts } = getState().reducer;
  const { selectedLocations } = reports.filters;
  const { start, end } = reports.dateStore.customDate;
  const employeeIds = getEmployeesIdsForSystemBonus(reports, userEmployees, contracts, start, end);
  if (!employeeIds.length || !selectedLocations.length) throw new Error('change_filtering');
  try {
    const { data } = await conn.getBonus(employeeIds, selectedLocations, start, end);
    return data;
  } catch (err) {
    throw err;
  }
};
