import { connect } from 'react-redux';

import { addMultipleShifts } from '@/actions/schedule';
import { notifyAboutNoShiftsToPaste } from '@/actions/schedule/quickPlanning';
import { hideModal } from '@/actions/uiState.js';
import { QUICK_PLANNING_WARNING_MODAL } from '@/constants/modalTypes';
import { addOrEditFreeDays } from '@/redux-store/freeDaysMarking';

import QuickPlanningWarningModal from './QuickPlanningWarningModal';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === QUICK_PLANNING_WARNING_MODAL,
  shiftsToPaste: state.reducer.schedule.quickPlanning.shiftsSummary.shiftsToPaste,
  shiftsAfterDateSelection: state.reducer.schedule.quickPlanning.shiftsSummary.shiftsAfterDateSelection,
  shiftsForNotExistingEmployeeId: state.reducer.schedule.quickPlanning.shiftsSummary.shiftsForNotExistingEmployeeId,
  shiftsNotAdded: state.reducer.schedule.quickPlanning.shiftsSummary.reasonsToNotAddShift.length,
  freeDaysToPaste: state.reducer.schedule.quickPlanning.freeDaysSummary.freeDaysToPaste,
  freeDaysAfterDateSelection: state.reducer.schedule.quickPlanning.freeDaysSummary.freeDaysAfterDateSelection,
  freeDaysForNotExistingEmployeeId:
    state.reducer.schedule.quickPlanning.freeDaysSummary.freeDaysForNotExistingEmployeeId,
});

const mapDispatchToProps = {
  hideModal,
  addMultipleShifts,
  addOrEditFreeDays,
  notifyAboutNoShiftsToPaste,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickPlanningWarningModal);
