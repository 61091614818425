export const mergeCellsToCurrentStructure = (structure, cells) => {
  const newStructure = { ...structure };
  cells.forEach(cell => {
    const { employeeId, date } = cell;
    newStructure[employeeId] = { ...(newStructure[employeeId] || {}), [date]: true };
  });
  return newStructure;
};

export const removeCellsFromCurrentStructure = (structure, cells) => {
  const newStructure = { ...structure };
  cells.forEach(cell => {
    const { employeeId, date } = cell;
    const newEmployeeData = { ...(newStructure[employeeId] || {}) };
    delete newEmployeeData[date];
    newStructure[employeeId] = newEmployeeData;
  });
  Object.keys(newStructure).forEach(employeeId => {
    if (!newStructure[employeeId] || !Object.keys(newStructure[employeeId]).length) {
      delete newStructure[employeeId];
    }
  });
  return newStructure;
};
