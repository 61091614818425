import PropTypes from 'prop-types';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';
import { absencesDisplayModes } from '@/constants/absencesDisplayModes';

const TopBarAbsencesDisplayMode = (props, { intl }) => {
  const changeFunc = mode => {
    props.changeAbsencesMode(mode);
  };

  const objectsToChoose = absencesDisplayModes(props.userPermissions.restrictions).map(displayMode => ({
    ...displayMode,
    displayText: intl.formatMessage(displayMode.displayText),
  }));

  return (
    <div className="k-topBarDisplayMode k-topBar__leftElement">
      <TopBarSingleChoice
        objectsToChoose={objectsToChoose}
        selected={props.selectedDisplayMode}
        onChange={changeFunc}
        displayKey="displayText"
        hideSearch
        className="k-topBarChoice--displayMode"
      />
    </div>
  );
};

TopBarAbsencesDisplayMode.contextTypes = {
  intl: PropTypes.shape({}),
};

TopBarAbsencesDisplayMode.propTypes = {
  selectedDisplayMode: PropTypes.shape({}),
  changeAbsencesMode: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default TopBarAbsencesDisplayMode;
