import { PropTypes } from 'prop-types';

import { getTimeFormatFromHourAndMinutes } from '@/utils/dateHelper';

import { getMetricValueSuffix } from '../scheduler/budgetBeta/ScheduleBudgetTableRows/ScheduleBudgetTableMetrics/ScheduleBudgetTableMetrics.helpers';

const ScheduleTableGraphTooltip = ({ payload, label, date, showRecommendedScheduleGraph }) => {
  if (!payload?.length) return null;

  const recommendedNum = payload[1] ? payload[1].value : null;
  const currentNum = payload[0] ? payload[0].value : null;

  const map15MinuteDivideToHourlyDivide = value => Math.floor((value * 15) / 60);

  const currentEmployeeAmount = payload.find(({ dataKey }) => dataKey === 'ye');
  const recommendedEmployeeAmount = payload.find(({ dataKey }) => dataKey === 'ys');

  return (
    (<div className="graphTooltip">
      <p className="graphTooltip__title">
        {date}: {getTimeFormatFromHourAndMinutes(map15MinuteDivideToHourlyDivide(label), ((label || 0) * 15) % 60)}
      </p>
      {currentEmployeeAmount && (
        <p className="graphTooltip__text graphTooltip__text--current">
          {currentEmployeeAmount.name} : {currentNum}
        </p>
      )}
      {showRecommendedScheduleGraph && recommendedEmployeeAmount && (
        <p className="graphTooltip__text graphTooltip__text--recommended">
          {recommendedEmployeeAmount.name} : {recommendedNum}
        </p>
      )}
      {payload
        .filter(({ name }) => typeof name === 'string' && name.match(/^budget-metric-(.)+$/))
        .map(({ name, value, dataKey, stroke, unit }) => (
          <p
            key={name}
            id={dataKey}
            className="graphTooltip__text"
            style={{
              color: stroke,
            }}
          >
            <span>
              {name.slice(14)}: {value} {getMetricValueSuffix({ type: unit })}
            </span>
          </p>
        ))}
      {/* TODO: */}
      {/* <p className="graphTooltip__text graphTooltip__text--current">
        {payload[2]?.name} : {estimatedBudget} {payload[2]?.unit}
      </p>
      <p className="graphTooltip__text graphTooltip__text--current">
        {payload[3]?.name} : {spmhPlan}
      </p> */}
    </div>)
  );
};

ScheduleTableGraphTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  label: PropTypes.number,
  date: PropTypes.string,
  showRecommendedScheduleGraph: PropTypes.bool,
};

export default ScheduleTableGraphTooltip;
