import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';

import './ExportError.scss';

const ExportError = ({ changeExportsHistoryData, numberOfItemsPerPage }) => {
  const handleClick = useCallback(() => changeExportsHistoryData(1, numberOfItemsPerPage), [numberOfItemsPerPage]);
  return (
    <div className="k-exportError">
      <div className="k-exportError__description">
        <FormattedMessage id="exportError.errorFetching.description" defaultMessage="Nie udało się pobrać informacji" />
      </div>
      <Button modifiers="orange teeny uppercase" className="k-exportError__button" onClick={handleClick}>
        <FormattedMessage id="exportError.errorFetching.tryAgain" defaultMessage="Spróbuj ponownie" />
      </Button>
    </div>
  );
};

ExportError.propTypes = {
  changeExportsHistoryData: PropTypes.func,
  numberOfItemsPerPage: PropTypes.number,
};

export default ExportError;
