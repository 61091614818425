import { connect } from 'react-redux';

import { changePayrollMode } from '@/actions/payroll/payroll.js';

import PayrollMode from './PayrollMode';

const mapStateToProps = state => ({
  mode: state.reducer.payroll.mode,
});

const mapDispatchToProps = {
  changePayrollMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollMode);
