import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import RolesTableHeader from './RolesTableHeader.jsx';

const mapDispatchToProps = {
  showModal,
};

const RolesTableHeaderContainer = connect(null, mapDispatchToProps)(RolesTableHeader);

export default RolesTableHeaderContainer;
