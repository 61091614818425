import { toastr } from 'react-redux-toastr';

import { addHistoryObject } from '@/actions/companyHistory.js';
import { groupJobTitles, removeSelectedButNotPresentJobTitles } from '@/actions/jobTitlesGrouping.js';
import * as AT from '@/constants/ActionTypes';

import { notificationLogger } from './notifications/notificationsLogger';

export const notificationMiddleware = intlManager => store => next => action => {
  notificationLogger(action, intlManager.intl);
  if (!action.notification || !action.notification.type) {
    return next(action);
  }
  const { notification } = action;
  switch (notification.type) {
    case 'success':
      toastr.success(notification.title, notification.description, notification.options || {});
      break;
    case 'error':
      toastr.error(notification.title, notification.description, notification.options || {});
      break;
    case 'push':
      notification.push = true;
      break;
    default:
      console.warn('[Notification Middleware] Unrecognized notification type');
      break;
  }
  if (notification.push) {
    const { deviceInfo } = store.getState().reducer;
    if (deviceInfo.supportsNotifications && Notification.permission === 'granted') {
      const n = new Notification(notification.title, {
        body: notification.body,
        icon: '/img/logo2.png',
      });
      n.addEventListener('click', notification.onClick);
      setTimeout(() => {
        n.removeEventListener(notification.onClick);
        n.close.bind(n);
      }, 5000);
    }
  }
  return next(action);
};

/**
 * This middleware adds history of actions to our backend storage. The actions
 * are used both by the users to get to know what is actually happening on their
 * account, who did what as well for us to sip through the data and see what our
 * customers and users are doing.
 * This middleware assumes that the action has a standard payload
 * @returns {next} - triggers next on the wrapped action
 */
export const historyStoreMiddleware = store => next => action => {
  if (!action.addToHistory) {
    return next(action);
  }
  // Reducer action if we need to know what the helll happend
  store.dispatch(addHistoryObject(action.payload));
  // Save to our servers
  return next(action);
};

export const jobtitlesGroupingMiddleware = store => next => action => {
  if (
    [
      AT.DELETE_EMPLOYEE,
      AT.UPDATE_EMPLOYEE,
      AT.DELETE_EMPLOYEE_SET,
      AT.ADD_NEW_EMPLOYEE,
      AT.MAIN_DATE_CHANGE_MODE_AND_DATE,
      AT.MAIN_DATE_MOVE_TO_DATE,
      AT.MAIN_DATE_CHANGE_CUSTOM_DATE,
      AT.MAIN_DATE_MOVE_LEFT,
      AT.MAIN_DATE_MOVE_RIGHT,
      AT.UPDATE_EMPLOYEE_MASS_SUCCESS,
    ].includes(action.type)
  ) {
    const returnState = next(action);
    store.dispatch(groupJobTitles());
    const prevLocationIds = store.getState().reducer.scheduleLocationFilter;
    store.dispatch(removeSelectedButNotPresentJobTitles(prevLocationIds, prevLocationIds));
    return returnState;
  }

  if ([AT.CHANGE_SCHEDULE_LOCATION_FILTER].includes(action.type)) {
    store.dispatch(groupJobTitles());
    const prevLocationIds = store.getState().reducer.scheduleLocationFilter;
    const nextLocationIds = action.payload;
    store.dispatch(removeSelectedButNotPresentJobTitles(prevLocationIds, nextLocationIds));
  }

  return next(action);
};
