import { connect } from 'react-redux';

import FreemiumWrapper from './FreemiumWrapper';

const mapStateToProps = state => ({
  userRestrictions: state.reducer.userPermissions.restrictions,
  userId: state.reducer.currentUser.user.id,
  companyId: state.reducer.currentCompany.id,
});

export default connect(mapStateToProps)(FreemiumWrapper);
