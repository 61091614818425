import { useAppSelector } from '@/redux-store';
import { selectModalObject, selectShowModal } from '@/redux-store/uiState';

export const useModal = (modalName: string) => {
  const modal = useAppSelector(selectShowModal);
  const showModal = modal === modalName;
  const modalObject = useAppSelector(selectModalObject)?.[modalName];

  return { showModal, modalObject };
};
