import { deleteOpenShiftsInSelectedLocationsBetweenDates } from '@/actions/schedule/openShifts';
import {
  handleClickOnColumnInQuickPlannig,
  handleClickOnRowInQuickPlannig,
  quickPlanningDeleteShifts,
} from '@/actions/schedule/quickPlanning';
import * as AT from '@/constants/ActionTypes';
import { DELETE_SCHEDULE_ITEMS_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getFreeDaysIdsForSelectedCells } from '@/utils/schedule/selection/selection';
import { getAvailabilitiesIdsForSelectedCells, getShiftIdsForSelectedCells } from '@/utils/schedule/selectionHelpers';

import { showModal } from '../uiState';
import { deleteAvailabilitiesConfirm } from './availabilities';
import { deleteTradeShiftsInSelectedLocationsBetweenDates } from './tradeShifts';

export const onColumnClick = (locationId, date, specialKeys) => (dispatch, getState) => {
  const { schedule, scheduleState, mainDateStore } = getState().reducer;
  let actionType = AT.REPLACE_SCHEDULE_SELECTED_CELLS;
  if (schedule.quickPlanning.enabled) {
    dispatch(handleClickOnColumnInQuickPlannig(date, specialKeys));
    return;
  }
  const { visible, order } = scheduleState.locations[locationId];
  const sortedVisibleEmployeeIds = order.filter(id => visible.includes(id));
  let cells = sortedVisibleEmployeeIds.map(employeeId => ({ date, employeeId }));
  let pivot = cells[0];
  const { selectedCells } = schedule;
  const selectDataForLocation = selectedCells.selectionsPerLocation[locationId] || { selected: {}, pivot: null };
  const selectionsForLocation = selectDataForLocation.selected;
  const currentPivot = selectDataForLocation.pivot;
  if (specialKeys.shift && currentPivot) {
    const { dateArray } = mainDateStore;
    const pivotDateIndex = dateArray.findIndex(id => id === currentPivot.date);
    const currentDateIndex = dateArray.findIndex(id => id === date);
    let datesToSelect = [];
    if (pivotDateIndex < currentDateIndex) {
      datesToSelect = dateArray.slice(pivotDateIndex, currentDateIndex + 1);
    } else {
      datesToSelect = dateArray.slice(currentDateIndex, pivotDateIndex + 1);
    }
    cells = datesToSelect.reduce(
      (agg, date) => [...agg, ...sortedVisibleEmployeeIds.map(id => ({ date, employeeId: id }))],
      [],
    );
    if (specialKeys.ctrl) {
      actionType = AT.ADD_SCHEDULE_SELECTED_CELLS;
    }
  } else if (specialKeys.ctrl) {
    const selectionsPerEmployee = Object.values(selectionsForLocation);
    const isCurrentlySelected = selectionsPerEmployee.length && selectionsPerEmployee.every(item => !!item[date]);
    if (isCurrentlySelected) {
      actionType = AT.REMOVE_SCHEDULE_SELECTED_CELLS;
      pivot = null;
    } else {
      actionType = AT.ADD_SCHEDULE_SELECTED_CELLS;
    }
  } else {
    const selectionsPerEmployee = Object.values(selectionsForLocation);
    const isCurrentlySelected = selectionsPerEmployee.length && selectionsPerEmployee.every(item => !!item[date]);
    const areOtherRowsSelected = Object.values(selectionsForLocation).some(item => Object.keys(item).length > 1);
    if (isCurrentlySelected && !areOtherRowsSelected) {
      actionType = AT.REMOVE_SCHEDULE_SELECTED_CELLS;
      pivot = null;
    }
  }
  dispatch({ type: actionType, payload: cells, locationId, pivot });
};

export const onRowClick = (locationId, employeeId, specialKeys) => (dispatch, getState) => {
  const { mainDateStore, schedule, scheduleState } = getState().reducer;
  const { dateArray } = mainDateStore;
  const { selectedCells } = schedule;
  const selectDataForLocation = selectedCells.selectionsPerLocation[locationId] || { selected: {}, pivot: null };
  const selectionsForLocation = selectDataForLocation.selected;
  const currentPivot = selectDataForLocation.pivot;
  let cells = dateArray.map(date => ({ date, employeeId }));
  let actionType = AT.REPLACE_SCHEDULE_SELECTED_CELLS;
  if (schedule.quickPlanning.enabled) {
    dispatch(handleClickOnRowInQuickPlannig(employeeId, specialKeys));
    return;
  }
  let pivot = cells[0];
  if (specialKeys.shift) {
    if (currentPivot) {
      const { visible, order } = scheduleState.locations[locationId];
      const sortedVisibleIds = order.filter(id => visible.includes(id));
      const pivotEmpIndex = sortedVisibleIds.findIndex(id => id === currentPivot.employeeId);
      const currentEmpIndex = sortedVisibleIds.findIndex(id => id === employeeId);
      let employeesToSelect;
      if (pivotEmpIndex < currentEmpIndex) {
        employeesToSelect = sortedVisibleIds.slice(pivotEmpIndex, currentEmpIndex + 1);
      } else {
        employeesToSelect = sortedVisibleIds.slice(currentEmpIndex, pivotEmpIndex + 1);
      }
      cells = employeesToSelect.reduce((agg, id) => [...agg, ...dateArray.map(date => ({ date, employeeId: id }))], []);
      if (specialKeys.ctrl) {
        actionType = AT.ADD_SCHEDULE_SELECTED_CELLS;
      }
    }
  } else if (specialKeys.ctrl) {
    const isCurrentlySelected = Object.keys(selectionsForLocation[employeeId] || {}).length === dateArray.length;
    if (isCurrentlySelected) {
      actionType = AT.REMOVE_SCHEDULE_SELECTED_CELLS;
      pivot = null;
    } else {
      actionType = AT.ADD_SCHEDULE_SELECTED_CELLS;
    }
  } else {
    const isCurrentlySelected = Object.keys(selectionsForLocation[employeeId] || {}).length === dateArray.length;
    const areOtherRowsSelected = Object.keys(selectionsForLocation).length > 1;
    if (isCurrentlySelected && !areOtherRowsSelected) {
      actionType = AT.REMOVE_SCHEDULE_SELECTED_CELLS;
      pivot = null;
    }
  }
  dispatch({ type: actionType, payload: cells, locationId, pivot });
};

export const deleteFromSelectedCells = () => (dispatch, getState) => {
  const { schedule, userPermissions } = getState().reducer;
  switch (schedule.viewMode.viewMode) {
    case 'schedule': {
      const isEditDisabled = userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);
      if (isEditDisabled) return;
      if (schedule.quickPlanning.enabled) {
        dispatch(quickPlanningDeleteShifts());
      } else {
        dispatch(deleteShiftsFromSelectedCells());
      }
      break;
    }
    case 'availabilities':
      dispatch(deleteAvailabilitiesFromSelectedCells());
      break;
    default:
      break;
  }
};

export const deleteShiftsFromSelectedCells = () => (dispatch, getState) => {
  const { freeDaysMarking, schedule, employees, shifts, settings, scheduleLoanedEmployees, scheduleUIState } =
    getState().reducer;
  const { openShiftsRowSelect, tradeShiftsRowSelect } = schedule;

  const anyTradeShiftRowSelected = Object.keys(tradeShiftsRowSelect).some(
    locationId => tradeShiftsRowSelect[locationId],
  );
  const anyOpenShiftRowSelected = Object.keys(openShiftsRowSelect).some(locationId => openShiftsRowSelect[locationId]);

  if (anyTradeShiftRowSelected) {
    dispatch(deleteTradeShiftsInSelectedLocationsBetweenDates());
  } else if (anyOpenShiftRowSelected) {
    dispatch(deleteOpenShiftsInSelectedLocationsBetweenDates());
  } else {
    const shiftIds = getShiftIdsForSelectedCells(
      schedule,
      employees,
      shifts,
      settings,
      scheduleLoanedEmployees.scheduleLoanedEmployees,
    );
    const freeDayIds = getFreeDaysIdsForSelectedCells(
      schedule.selectedCells.selectionsPerLocation,
      freeDaysMarking,
      scheduleUIState.settings,
    );
    if (isEmptyArray(freeDayIds) && isEmptyArray(shiftIds)) return;
    dispatch(showModal(DELETE_SCHEDULE_ITEMS_MODAL, { shiftIds, freeDayIds }));
  }
};

const deleteAvailabilitiesFromSelectedCells = () => (dispatch, getState) => {
  const { schedule, employees, currentCompany } = getState().reducer;
  const disableAvailabilitiesEditUntil = currentCompany.settings.disable_availabilities_edit_until;
  const availabilitiesIds = getAvailabilitiesIdsForSelectedCells(schedule, employees, disableAvailabilitiesEditUntil);
  if (!availabilitiesIds.length) return;
  dispatch(deleteAvailabilitiesConfirm(availabilitiesIds));
};

export const onOpenShiftRowClick = locationId => ({
  type: AT.TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT,
  payroll: { locationId },
});

export const clearScheduleSelections = () => ({
  type: AT.CLEAR_ALL_SCHEDULE_SELECTED_CELLS,
});

export const onTradeShiftRowClick = locationId => ({
  type: AT.TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT,
  payroll: { locationId },
});
