/* eslint camelcase:0 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import OptionsCell from '@/components/common/OptionsCell.jsx';
import {
  COMPANY_MANAGE_AVAILABILITIES_HIDE_DELETE,
  COMPANY_MANAGE_AVAILABILITIES_HIDE_EDIT,
} from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';

class AvaTypeRow extends PureComponent {
  render() {
    const { requires_time, requires_approval, optional_comment, type } = this.props.data;

    const rowOptions = [
      {
        option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
        restriction: COMPANY_MANAGE_AVAILABILITIES_HIDE_EDIT,
        style: 'edit',
        function: this.props.edit,
      },
      {
        option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
        restriction: COMPANY_MANAGE_AVAILABILITIES_HIDE_DELETE,
        style: 'delete',
        function: this.props.delete,
      },
    ];

    const { options, styles, functions } = applyPermissionsForOptions(rowOptions, this.props.userPermissions);

    return (
      <tr className="h-sorce2">
        <td className="center">
          {requires_time ? (
            <FormattedMessage id="common.yes" defaultMessage="Tak" />
          ) : (
            <FormattedMessage id="common.no" defaultMessage="Nie" />
          )}
        </td>
        <td className="center">
          {requires_approval ? (
            <FormattedMessage id="common.yes" defaultMessage="Tak" />
          ) : (
            <FormattedMessage id="common.no" defaultMessage="Nie" />
          )}
        </td>
        <td className="center">
          {optional_comment ? (
            <FormattedMessage id="common.yes" defaultMessage="Tak" />
          ) : (
            <FormattedMessage id="common.no" defaultMessage="Nie" />
          )}
        </td>
        <td className="center">
          {type === 'default' ? (
            <FormattedMessage id="companymanage.availabilityRow.defaultType" defaultMessage="Domyślny" />
          ) : (
            <FormattedMessage id="companymanage.availabilityRow.type" defaultMessage="Własny" />
          )}
        </td>

        <td className="center">
          <OptionsCell options={options} styles={styles} functions={functions} disabled={type === 'default'} />
        </td>
      </tr>
    );
  }
}

AvaTypeRow.propTypes = {
  data: PropTypes.shape({
    requires_time: PropTypes.bool,
    requires_approval: PropTypes.bool,
    optional_comment: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
};
export default AvaTypeRow;
