import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { conn } from '@/actions';
import Icon from '@/components/common/Basic/Icon/Icon';
import { MDSelect, MDTextArea, MDTimeRangeInput } from '@/components/common/inputs/MDComponents.jsx';
import RecentAddedShifts from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddShiftTab/RecentAddedShifts/RecentAddedShifts';
import { MODIFICATION_BLOCKADES_ENABLE } from '@/constants/Permissions';
import useFetchData from '@/hooks/useFetchData/useFetchData';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { getFilteredShiftBlockOptions, isTimeRangeFormat } from '@/utils/dateHelper.js';
import { createEvent } from '@/utils/inputHelpers.js';

import { messages } from './AddShiftTab.messages';
import { BlockadeMessage } from './BlockadeMessage';

import './AddShiftTab.scss';

const MODAL_MODIFIERS = ['modal'];
const MODAL_DISABLED_MODIFIERS = ['modal', 'disabled'];

const AddShiftTab = (
  {
    comment,
    errors,
    handleInputChange,
    isEditing,
    selectedJobTitle,
    workingHours,
    userJobTitles,
    userShiftblocks,
    onSubmitShift,
    displayRepeat,
    employeeContracts,
    date,
    disableInputsInModal,
    employeeId,
    permissions,
  },
  { intl },
) => {
  const { data, isLoading } = useFetchData(
    () => conn.getModificationBlockades(date, moment(date).add(1, 'day').format('YYYY-MM-DD'), [employeeId]),
    permissions.includes(MODIFICATION_BLOCKADES_ENABLE),
  );
  const relevantBlockades = data?.blockades || [];
  const isHourFieldFocused = !isEditing && !isTimeRangeFormat(workingHours);

  const relevantJobTitles = useMemo(() => {
    const relevantContract = getRelevantContractForDate(employeeContracts || [], date);
    const relevantJobTitleIds = relevantContract ? getJobTitleIdsFromContracts([relevantContract]) : [];
    const jobTitleBasedOnContract = userJobTitles.filter((jobTitle) =>
      relevantJobTitleIds?.includes(String(jobTitle.id)),
    );
    return jobTitleBasedOnContract.length ? jobTitleBasedOnContract : [selectedJobTitle];
  }, [userJobTitles, employeeContracts, date, selectedJobTitle]);

  const jobTitlesOptions = useMemo(
    () =>
      relevantJobTitles.map((jobTitle) => ({
        key: jobTitle.title,
        value: jobTitle.id,
      })),
    [relevantJobTitles],
  );
  const shiftBlocksOptions = useMemo(
    () => getFilteredShiftBlockOptions(userShiftblocks, selectedJobTitle?.id),
    [userShiftblocks, selectedJobTitle?.id],
  );

  const valueToCompare = relevantJobTitles.map((jobTitle) => ({ job_title: jobTitle }));
  const changeSelectedJobTitle = useCallback(
    (id) =>
      handleInputChange(
        createEvent(
          'selectedJobTitle',
          userJobTitles.find((j) => j.id === id),
          { valueToCompare },
        ),
      ),
    [userJobTitles, valueToCompare],
  );
  const changeWorkingHours = useCallback((hours) => handleInputChange(createEvent('working_hours', hours)), []);
  const changeComment = useCallback((e) => handleInputChange(createEvent(e.target.name, e.target.value)), []);

  const timeRangeInputModifiers = disableInputsInModal ? MODAL_DISABLED_MODIFIERS : MODAL_MODIFIERS;
  return (
    <>
      <MDSelect
        id="Stanowisko"
        name={intl.formatMessage(messages.shiftSelectName)}
        defaultValue={selectedJobTitle?.id}
        options={jobTitlesOptions}
        onChange={changeSelectedJobTitle}
        closeOnClick
        errorMessage={errors.selectedJobTitle}
        disabled={disableInputsInModal}
      />
      {relevantBlockades?.length > 0 && (
        <div className="k-addShiftTab__blockadesMessage">
          <div>
            <Icon type="material-outlined" name="info" className="k-addShiftTab__blockadesMessageIcon" />
          </div>
          <BlockadeMessage blockades={relevantBlockades} isLoading={isLoading} />
        </div>
      )}
      <MDTimeRangeInput
        value={workingHours}
        options={shiftBlocksOptions}
        onChange={changeWorkingHours}
        label={intl.formatMessage(messages.time)}
        errorMessage={errors.working_hours || errors.shiftsOverlap}
        modifiers={timeRangeInputModifiers}
        isFocused={isHourFieldFocused}
      />
      <MDTextArea
        label={intl.formatMessage(messages.note)}
        placeholder={intl.formatMessage(messages.notePlaceholder)}
        value={comment}
        onChange={changeComment}
        id="commentShift"
        name="comment"
        errorMessage={errors.comment}
        disabled={disableInputsInModal}
      />
      {!disableInputsInModal && (
        <RecentAddedShifts {...{ handleInputChange, onSubmitShift, displayRepeat, workingHours }} />
      )}
    </>
  );
};

AddShiftTab.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddShiftTab.propTypes = {
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  workingHours: PropTypes.string,
  comment: PropTypes.string,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userShiftblocks: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({
    comment: PropTypes.string,
    working_hours: PropTypes.string,
    shiftsOverlap: PropTypes.string,
    selectedJobTitle: PropTypes.string,
  }),
  selectedJobTitle: PropTypes.shape({
    id: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  handleInputChange: PropTypes.func,
  onSubmitShift: PropTypes.func,
  displayRepeat: PropTypes.bool,
  disableInputsInModal: PropTypes.bool,
  date: PropTypes.string,
  employeeId: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default AddShiftTab;
