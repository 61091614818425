import { CHANGE_LOCATION_FILTER } from '@/constants/ActionTypes.js';

import { getNewDataForDate } from './index';

export const changeLocationFilterSuccess = locationObject => ({
  type: CHANGE_LOCATION_FILTER,
  payload: locationObject,
});

export const changeLocationFilter = (locationObject, preventUpdate = false) => (dispatch, getState) => {
  const { mainDateStore, demo } = getState().reducer;
  dispatch(changeLocationFilterSuccess(locationObject));
  if (!demo.demoAccount && !preventUpdate) {
    const from = mainDateStore.dateArray[0];
    const to = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    dispatch(getNewDataForDate(from, to));
  }
};

/**
 * Check if userLocations has any location that doesn't contain ids from locationsIdArray and updates locationFilter
 *
 * @param {Array<object>} userLocations - standard userLocations array
 * @param {Array<string>} locationIdsArray - array with location id strings
 *
 * @returns {void} - should dispatch changeLocationFilter with location object
 */
export const updateLocationFilter = (userLocations, locationIdsArray) => dispatch => {
  const relevantLocation = userLocations.find(location => !locationIdsArray.includes(location.id));
  if (relevantLocation) {
    dispatch(changeLocationFilter(relevantLocation));
  } else {
    dispatch(
      changeLocationFilter({
        id: 'Empty',
        name: 'Brak lokalizacji!',
      }),
    );
  }
};

/**
 * Update current selectedLocation in locationFilter if location is changed in companymanage
 *
 * @param {Array<object>} locationObject - changed location object
 *
 * @returns {void} - should dispatch changeLocationFilter with location object
 */
export const updateLocationFilterName = (locationObject = {}) => (dispatch, getState) => {
  const { selectedLocation } = getState().reducer.locationFilter;
  if (selectedLocation.id === locationObject.id) {
    dispatch(changeLocationFilter(locationObject));
  }
};
