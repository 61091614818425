import { parseMinutesToHumanForm } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import MDTable from '@/components/common/MDTable/MDTable';
import EmployeePayrollTableWidgets from '@/components/employee/newPayroll/employeePayrollTableWidgets/EmployeePayrollTableWidgets';
import EmptyState from '@/components/emptyState/EmptyState';
import { EmployeePayroll, EmployeePayrollSummary } from '@/types';

import { getTableData } from './EmployeePayrollTable.helpers.tsx';
import { messages } from './EmployeePayrollTable.messages';

import './employeePayrollTable.scss';

interface EmployeePayrollTableProps {
  data: EmployeePayroll[];
  summary: EmployeePayrollSummary;
  showWagesInEmployeeECP: boolean;
  showSkeletons: boolean;
}

const EmployeePayrollTable = (
  { data, summary, showWagesInEmployeeECP, showSkeletons }: EmployeePayrollTableProps,
  { intl },
) => {
  const columns = [
    {
      id: 1,
      accessor: 'date',
      width: 100,
      Header: intl.formatMessage(messages.date),
    },
    {
      id: 2,
      accessor: 'startHour',
      width: 100,
      Header: intl.formatMessage(messages.hours),
    },
    {
      id: 3,
      accessor: 'sumHours',
      width: 100,
      Header: intl.formatMessage(messages.hoursSum),
    },
    ...(showWagesInEmployeeECP
      ? [
          {
            id: 4,
            accessor: 'bonus',
            width: 100,
            Header: intl.formatMessage(messages.bonus),
          },
          {
            id: 5,
            accessor: 'payout',
            width: 100,
            Header: intl.formatMessage(messages.payout),
          },
        ]
      : []),
  ];

  const summaryData = useMemo(
    () => [
      { text: intl.formatMessage(messages.hoursSum), value: parseMinutesToHumanForm(summary.sumHours), unit: '' },
      ...(showWagesInEmployeeECP
        ? [
            { text: intl.formatMessage(messages.bonusSum), value: summary.sumBonus.toFixed(2), unit: 'PLN' },
            { text: intl.formatMessage(messages.payoutSum), value: (summary.sumPayout / 100).toFixed(2), unit: 'PLN' },
          ]
        : []),
    ],
    [summary],
  );

  const employeePayrollData = getTableData(data, showSkeletons);

  return (
    <div className="k-employeePayrollTable">
      <MDTable
        tableOptions={<EmployeePayrollTableWidgets summary={summaryData} showSkeletons={showSkeletons} />}
        columns={columns}
        data={employeePayrollData}
        withOptions={false}
        withCheckbox={false}
        withSearch={false}
        tableEmpty={
          <EmptyState
            imgSrc="/img/jobtitlesCTA.png"
            emptyStateHeader={intl.formatMessage(messages.payrollEmptyStateHeader)}
            emptyStateText={intl.formatMessage(messages.payrollEmptyStateText)}
          />
        }
      />
    </div>
  );
};

EmployeePayrollTable.contextTypes = {
  intl: PropTypes.shape({}),
};

export default EmployeePayrollTable;
