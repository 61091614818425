import { connect } from 'react-redux';

import RolesTableRowDetails from './RolesTableRowDetails';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  companySettings: state.reducer.currentCompany.settings,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RolesTableRowDetails);
