import classnames from 'classnames';
import PropTypes from 'prop-types';

import MDInputTooltip from '../MDInputTooltip/MDInputTooltip.jsx';

import './MDSwitch.scss';

const MDSwitch = ({ id, label, value, onChange, disabled, tooltip, modifiers, left, right, testId }) => {
  const handleChange = e => {
    if (!disabled) {
      onChange({
        target: {
          checked: !value,
          type: 'checkbox',
          name: id,
        },
      });
    }
    e.stopPropagation();
  };

  let modifiersArray = modifiers || [];
  if (!Array.isArray(modifiersArray)) {
    modifiersArray = modifiersArray.split(' ');
  }

  const classNames = classnames('k-switch', ...modifiersArray.map(modifier => `k-switch--${modifier}`), {
    'k-switch--disabled': disabled,
  });

  return (
    <div className={classNames}>
      <span className="k-switch__label">
        {label} <MDInputTooltip show={tooltip} content={tooltip} />
      </span>
      <div className="k-switch__case">
        {left && <span className="k-switch__left">{left}</span>}
        <label className="k-switch__toggle" data-test={testId}>
          <input
            id={id}
            name={id}
            type="checkbox"
            className="k-switch__input"
            checked={value}
            onChange={handleChange}
            disabled={disabled}
          />
          <span className="k-switch__slider" />
        </label>
        {right && <span className="k-switch__right">{right}</span>}
      </div>
    </div>
  );
};

MDSwitch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  testId: PropTypes.string,
};

export default MDSwitch;
