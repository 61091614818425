import { defineMessages,FormattedMessage } from 'react-intl';

export const messages = defineMessages({
  editPermissionRole: {
    id: 'settings.permissionRoleModal.edit',
    defaultMessage: 'Edytuj rolę',
  },
  addPermissionRole: {
    id: 'settings.permissionRoleModal.add',
    defaultMessage: 'Dodaj rolę',
  },
  employeeLabel: {
    id: 'addPermissionRole.employee.label',
    defaultMessage: 'Pracownik',
  },
  employeeSublabel: {
    id: 'addPermissionRole.employee.sublabel',
    defaultMessage: 'Rola dziedziczy uprawnienia pracownika',
  },
  managerLabel: {
    id: 'addPermissionRole.manager.label',
    defaultMessage: 'Manager',
  },
  managerSublabel: {
    id: 'addPermissionRole.manager.sublabel',
    defaultMessage: 'Rola dziedziczy uprawnienia Managera',
  },
  rankTooltip: {
    id: 'addPermissionRole.rankTooltip',
    defaultMessage:
      'Ranga określana jest w przedziale 1-10, gdzie 1 oznacza rangę najniższą, a 10 najwyższą w hierarchii pozostałych Ról. {clickHere}, aby dowiedzieć się więcej.',
  },
  clickHere: {
    id: 'common.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ',
  },
});

export const getRolesOptions = () => [
  {
    label: <FormattedMessage id="addPermissionRole.employee.label" defaultMessage="Pracownik" />,
    sublabel: (
      <FormattedMessage
        id="addPermissionRole.employee.sublabel"
        defaultMessage="Rola dziedziczy uprawnienia pracownika"
      />
    ),
    id: 'employee',
  },
  {
    label: <FormattedMessage id="addPermissionRole.manager.label" defaultMessage="Manager" />,
    sublabel: (
      <FormattedMessage id="addPermissionRole.manager.sublabel" defaultMessage="Rola dziedziczy uprawnienia Managera" />
    ),
    id: 'manager',
  },
];

export const getEmployeesWithRole = (employees, roleId) =>
  employees.reduce((list, employee) => {
    const employeeName = `${employee.first_name} ${employee.last_name}`;
    return employee.role_id === roleId ? [...list, employeeName] : list;
  }, []);
