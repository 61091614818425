import moment from 'moment';
import { useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectCurrentUser } from '@/redux-store/currentUser/currentUser.selectors.ts';
import { selectLocationGroupsForLocationId } from '@/redux-store/locationGroups/locationGroups.selectors.ts';
import { OpenShift } from '@/types/openShifts.types.ts';

export const useVisibleOpenShiftsIdsForLocationGroups = (
  openShiftsData: Record<string, OpenShift>,
  locationId: string,
  date: string,
) => {
  const locationGroups = useAppSelector(selectLocationGroupsForLocationId(locationId));
  const { user } = useAppSelector(selectCurrentUser);
  const isOwner = user.role === 'owner';
  const userEmploymentCondition = user.employment_conditions.template_id;
  const locationGroupsIds = locationGroups?.map(group => group.id);
  return useMemo(
    () =>
      Object.values(openShiftsData).reduce((acc: string[], openShift) => {
        if (
          moment(openShift.date).isSame(date) &&
          (isOwner ||
            !openShift.employmentConditions ||
            (openShift.employmentConditions && openShift.employmentConditions?.includes(userEmploymentCondition))) &&
          (openShift.location.id === locationId ||
            (!openShift.draft && openShift.location_groups?.some(group => locationGroupsIds?.includes(group))))
        ) {
          acc.push(openShift.id);
        }
        return acc;
      }, []),
    [openShiftsData, date, isOwner, userEmploymentCondition, locationId, locationGroupsIds],
  );
};
