import { connect } from 'react-redux';

import { requestAuthenticateUser } from '@/actions/auth.jsx';
import { changePassword } from '@/actions/settings';
import ExpiredPassword from '@/components/password/ExpiredPassword.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
});

const mapDispatchToProps = {
  changePassword,
  requestAuthenticateUser,
};
const ExpiredPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ExpiredPassword);

export default ExpiredPasswordContainer;
