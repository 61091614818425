/* eslint array-callback-return:1 */
import moment from 'moment';

import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import { MAIN_COLOR_PALETT } from '@/constants/colors.js';

import { seededUuid4 } from './baseHelpers.js';
import { calculateDurationBetweenTimestamps, calculateDurationMinutes } from './dateHelper.js';
import { conn } from '@/actions/index.jsx';
import { gaEvents, getDefaultGAProducts } from './ga.helpers.js';

export const seededRandom = userSeed => {
  let seed = new Date().getTime();
  const constant = 1664525;
  const prime = 1013904223;
  const max = 2 ** 32;
  if (userSeed) {
    seed = userSeed;
  }
  for (let i = 0; i < 10; i++) {
    seed *= constant;
    seed += prime;
    seed %= max;
  }
  return {
    next: () => {
      seed *= constant;
      seed += prime;
      seed %= max;
      return seed / max;
    },
  };
};

const getSubArray = (array, number, rand) => {
  let size = number;
  const { length } = array;
  if (length < number) size = length;
  const taken = new Set();
  const picked = [];
  while (picked.length < size) {
    const index = Math.floor(rand.next() * length);
    if (!taken.has(index)) {
      picked.push(array[index]);
      taken.add(index);
    }
  }
  return picked;
};

const pickOne = (array, rand) => array[Math.floor(rand.next() * array.length)];

export const generateTimestamps = (workingHours, date) => {
  const startHour = workingHours.slice(0, 5);
  const endHour = workingHours.slice(6, 11);
  const startTimestamp = `${date} ${startHour}`;
  const endTimestamp =
    startHour > endHour
      ? `${moment(date)
          .add(1, 'days')
          .format('YYYY-MM-DD')} ${endHour}`
      : `${date} ${endHour}`;

  return { startTimestamp, endTimestamp };
};

export const generateLocations = (number, locationNames, currentCompany, rand, faker) => {
  const locationsToAdd = [];
  const locations = getSubArray(locationNames, number, rand);
  for (let i = 0; i < number; i++) {
    const name = locations[i];
    const slug = name.toLowerCase();
    locationsToAdd.push({
      id: seededUuid4(rand),
      color: pickOne(MAIN_COLOR_PALETT, rand),
      name,
      slug,
      pin: `00000${Math.floor(rand.next() * 1000000)}`.slice(-6),
      city: faker.fake('{{address.city}}'),
      address: faker.fake('{{address.streetPrefix}} ') + name,
      company_id: currentCompany.id,
    });
  }
  return locationsToAdd;
};
export const generateLocation = (name, city, address, color, rand) => {
  const id = seededUuid4(rand);
  return {
    id,
    color,
    name,
    slug: name.toLowerCase(),
    pin: `00000${Math.floor(rand.next() * 1000000)}`.slice(-6),
    city,
    address,
  };
};

export const generateJobTitles = (number, jobTitles, currentCompany, rand) => {
  const jobTitlesToAdd = [];
  const companyId = currentCompany.id;
  const titles = getSubArray(jobTitles, number, rand);
  for (let i = 0; i < number; i++) {
    jobTitlesToAdd.push({
      id: seededUuid4(rand),
      color: pickOne(MAIN_COLOR_PALETT, rand),
      title: titles[i],
      hourly_wage: `000${Math.floor(1000 + rand.next() * 2000)}`.slice(-4),
      company_id: companyId,
    });
  }
  return jobTitlesToAdd;
};
export const generateJobTitle = (title, wage, color, rand) => {
  const id = seededUuid4(rand);
  return {
    id,
    color,
    title,
    hourly_wage: wage * 100,
  };
};
export const generateShiftBlocks = (number, hours, jobTitles, currentCompany, rand) => {
  const shiftBlocksToAdd = [];
  const companyId = currentCompany.id;
  const workingHoursArray = getSubArray(hours, number, rand);
  for (let i = 0; i < number; i++) {
    shiftBlocksToAdd.push({
      id: seededUuid4(rand),
      working_hours: workingHoursArray[i],
      company_id: companyId,
      job_titles: getSubArray(jobTitles, Math.floor(1 + rand.next() * 5), rand).map(j => j.id),
    });
  }
  return shiftBlocksToAdd;
};
export const generateShiftBlock = (hours, jobTitles, rand) => {
  const id = seededUuid4(rand);
  return {
    id,
    working_hours: hours,
    job_titles: jobTitles.map(j => j.id),
  };
};

export const generateAvaTypes = (number, availabilities, currentCompany, rand) => {
  const avaTypesToAdd = [];
  const names = getSubArray(availabilities, number, rand);
  const companyId = currentCompany.id;
  for (let i = 0; i < number; i++) {
    avaTypesToAdd.push({
      id: seededUuid4(rand),
      name: names[i],
      color: pickOne(MAIN_COLOR_PALETT, rand),
      requires_time: rand.next() > 0.5,
      requires_approval: rand.next() > 0.5,
      optional_comment: rand.next() > 0.5,
      company_id: companyId,
      type: 'custom',
    });
  }
  return avaTypesToAdd;
};

export const generateAvaType = (name, color, time, approval, comment, rand) => {
  const id = seededUuid4(rand);
  return {
    id,
    name,
    color,
    requires_time: time,
    requires_approval: approval,
    optional_comment: comment,
    type: 'custom',
  };
};

export const generateAvailabilityBlocks = (number, avaTypes, rand) => {
  const types = defaultAvailabilityTypes.concat(avaTypes);
  const availabilities = [];
  for (let i = 0; i < number; i++) {
    const type = types[Math.floor(rand.next() * types.length)];
    availabilities.push({
      id: seededUuid4(rand),
      hours: type.requires_time ? '01:00-22:00' : null,
      date: moment()
        .startOf('week')
        .add(i, 'days')
        .format('YYYY-MM-DD'),
      type: type.type,
      type_id: type.id,
      draft: rand.next() > 0.5 || type.id === 38,
      optional_comment: '',
    });
  }
  return availabilities;
};

export const generateSpecyficAvailabilityBlocks = (dates, type, draft, comment, hours, rand) => {
  const id = seededUuid4(rand);
  const availabilities = dates.map(date => ({
    id,
    hours: type.requires_time ? hours : null,
    date,
    type: type.type,
    type_id: type.id,
    draft,
    optional_comment: comment,
  }));
  return availabilities;
};

export const generateShifts = (number, locations, jobTitles, employee, shiftBlocks, rand) => {
  const shifts = [];
  const possibleJobTitles = jobTitles.filter(j => employee.terms.map(t => t.job_title.id).includes(j.id));
  const possibleLocations = locations.filter(j => employee.locations.map(l => l.id).includes(j.id));
  const possibleShiftBlocks = shiftBlocks.filter(s =>
    possibleJobTitles.map(j => j.id).some(e => s.job_titles.includes(e)),
  );
  if (possibleShiftBlocks.length === 0) return [];

  for (let i = 0; i < number; i++) {
    const workingHours = pickOne(possibleShiftBlocks, rand).working_hours;
    const date = moment()
      .startOf('week')
      .add(i, 'days')
      .format('YYYY-MM-DD');
    const { startTimestamp, endTimestamp } = generateTimestamps(workingHours, date);

    shifts.push({
      id: seededUuid4(rand),
      draft: rand.next() > 0.5,
      comment: '',
      location: pickOne(possibleLocations, rand),
      employee: { id: employee.id },
      job_title: pickOne(possibleJobTitles, rand),
      date,
      working_hours: workingHours,
      start_timestamp: startTimestamp,
      end_timestamp: endTimestamp,
    });
  }
  return shifts;
};
export const generateFullCoverageShifts = (dates, jobTitle, location, employees, shiftBlocks, reps, rand) => {
  const shifts = [];
  employees.forEach(() => {
    shifts.push([]);
  });
  dates.forEach(date => {
    const indexes = [];
    for (let i = 0; i < employees.length; i++) {
      indexes.push(i);
    }
    indexes.sort(() => 0.5 - rand.next());

    for (let i = 0; i < shiftBlocks.length; i++) {
      for (let j = 0; j < reps[i]; j++) {
        const workingHours = shiftBlocks[i].working_hours;
        const { startTimestamp, endTimestamp } = generateTimestamps(workingHours, date);

        const randomIndex = Math.floor(rand.next() * indexes.length);
        shifts[indexes[randomIndex]].push({
          id: seededUuid4(rand),
          draft: false,
          comment: '',
          location,
          employee: { id: employees[indexes[randomIndex]].id },
          job_title: jobTitle,
          date,
          working_hours: workingHours,
          start_timestamp: startTimestamp,
          end_timestamp: endTimestamp,
        });
        if (indexes.length > 1) indexes.splice(randomIndex, 1);
      }
    }
  });
  return shifts;
};
export const generateShiftsWithRandomBlock = (dates, jobTitle, location, employee, shiftBlocks, rand) =>
  dates.map(date => {
    const shiftBlock = pickOne(shiftBlocks, rand);
    const workingHours = shiftBlock.working_hours;
    const { startTimestamp, endTimestamp } = generateTimestamps(workingHours, date);

    return {
      id: seededUuid4(rand),
      draft: false,
      comment: '',
      location,
      employee: { id: employee.id },
      job_title: jobTitle,
      date,
      working_hours: workingHours,
      start_timestamp: startTimestamp,
      end_timestamp: endTimestamp,
    };
  });

export const generateEmployees = (number, terms, locations, avaTypes, shiftBlocks, currentCompany, rand, faker) => {
  const employeesToAdd = [];
  const companyId = currentCompany.id;
  for (let i = 0; i < number; i++) {
    employeesToAdd.push({
      id: seededUuid4(rand),
      invited: rand.next() > 0.5,
      inactive: false,
      last_name: faker.fake('{{name.lastName}}'),
      first_name: faker.fake('{{name.firstName}}'),
      role: 'employee',
      phone: '',
      email: '',
      pin: `000${Math.floor(rand.next() * 10000)}`.slice(-4),
      terms: getSubArray(terms, 1 + Math.floor(rand.next() * 2), rand).map(t => ({
        job_title: t,
        individual_hourly_wage: t.hourly_wage,
      })),
      locations: getSubArray(locations, 1 + Math.floor(rand.next() * 2), rand),
      company_id: companyId,
      shifts: [],
      availability_blocks: generateAvailabilityBlocks(7, avaTypes, rand),
      attendances: [],
      supplementary_locations_ids: [],
      employment_conditions: {},
    });
  }
  employeesToAdd.forEach(employee => {
    const shifts = generateShifts(2, locations, terms, employee, shiftBlocks, rand);
    employee.shifts = shifts;
  });
  return employeesToAdd;
};
export const generateEmployee = (terms, locations, avaBlocks, shiftBlocks, rand, faker) => {
  const id = seededUuid4(rand);
  return {
    id,
    invited: true,
    inactive: false,
    last_name: faker.fake('{{name.lastName}}'),
    first_name: faker.fake('{{name.firstName}}'),
    role: 'employee',
    phone: '',
    email: '',
    pin: `000${Math.floor(rand.next() * 10000)}`.slice(-4),
    terms: terms.map(t => ({
      job_title: t,
      individual_hourly_wage: t.hourly_wage,
    })),
    locations,
    shifts: [],
    availability_blocks: avaBlocks,
    attendances: [],
  };
};

export const addNormalAvaliablities = (dates, employees, rand) => {
  employees.forEach(employee => {
    if (employee.availability_blocks.length === 0) {
      dates.forEach(date => {
        if (employee.shifts.map(s => s.date).find(x => x === date)) {
          if (rand.next() > 0.4)
            employee.availability_blocks.push({
              id: seededUuid4(rand),
              date,
              type: 'availability',
              type_id: '38',
              draft: false,
            });
        } else if (rand.next() > 0.6) {
          employee.availability_blocks.push({
            id: seededUuid4(rand),
            date,
            type: 'unavailability',
            type_id: '38',
            draft: false,
          });
        } else if (rand.next() > 0.6) {
          employee.availability_blocks.push({
            id: seededUuid4(rand),
            date,
            type: 'availability',
            type_id: '38',
            draft: false,
          });
        }
      });
    }
  });
};

export const addAttendances = (employees, chance, rand) => {
  employees.forEach(employee => {
    employee.attendances = [];
    employee.shifts.forEach(shift => {
      let pick = rand.next();
      let extraMins = 0;
      if (pick < 0.2) {
        extraMins = 30;
      } else if (pick < 0.5) {
        extraMins = 15;
      } else if (pick < 0.75) {
        extraMins = 5;
      }
      if (rand.next() > 0.5) extraMins *= -1;
      const start = moment(shift.start_timestamp)
        .add(extraMins, 'minutes')
        .format('YYYY-MM-DD HH:mm');
      let end = null;
      extraMins = 0;
      pick = rand.next();
      if (pick < 0.2) {
        extraMins = 30;
      } else if (pick < 0.5) {
        extraMins = 15;
      } else if (pick < 0.75) {
        extraMins = 5;
      }
      if (rand.next() > 0.5) extraMins *= -1;
      end = moment(shift.end_timestamp)
        .add(extraMins, 'minutes')
        .format('YYYY-MM-DD HH:mm');
      let hoursEnd = '';
      if (moment(shift.end_timestamp).isAfter(moment())) {
        end = null;
        hoursEnd = '__:__';
      } else {
        hoursEnd = end.split(' ')[1];
      }

      if (moment(shift.start_timestamp).isBefore(moment())) {
        employee.attendances.push({
          id: seededUuid4(rand),
          start_timestamp: start,
          end_timestamp: end,
          date: shift.date,
          hours: `${start.split(' ')[1]}-${hoursEnd}`,
          location: { id: shift.location.id },
          timeWorked: calculateDurationBetweenTimestamps(start, end),
          bonus_amount: 0,
          breaks: [],
          matching_shift: shift,
          matching_shift_job_title: shift.job_title,
          matching_shift_total_wage: parseInt(
            (calculateDurationMinutes(shift.working_hours) * shift.job_title.hourly_wage) / 60,
          ),
          matching_shift_hourly_wage: shift.job_title.hourly_wage,
        });
      }
    });
  });
};

export const changeAttendanceForDemo = (attendance, startTimestamp, endTimestamp, employeeId) => {
  const out = Object.assign({}, attendance, {
    employee_id: employeeId,
    start_timestamp: startTimestamp,
    end_timestamp: endTimestamp,
    location_id: attendance.location.id,
    hours: `${startTimestamp.split(' ')[1]}-${endTimestamp.split(' ')[1]}`,
    timeWorked: calculateDurationBetweenTimestamps(startTimestamp, endTimestamp),
  });
  return out;
};

export const sendCreateAccountEvent = async () => {
  try {
    const { data } = await conn.getCurrentCompany('blocking');
    gaEvents.createdAccount(data.company.id, getDefaultGAProducts());
  } catch (e) {
    console.log(e);
  }
};
