import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import Component from './EventsTableCell.jsx';

const mapStateToProps = state => ({
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
