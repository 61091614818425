import { GlobalNotifications } from '@/middlewares/notifications/notifications';

import { createUpdateManagerNote } from './managerNotes.asyncActions';

export const managerNotesNotifications: GlobalNotifications = {
  [createUpdateManagerNote.rejected.type]: {
    title: {
      id: 'schedule.managerNoteModal.toastr.addError',
      defaultMessage: 'Wystąpił błąd podczas dodawania notatki',
    },
  },
  [createUpdateManagerNote.fulfilled.type]: {
    title: {
      id: 'schedule.managerNoteModal.toastr.addSuccess',
      defaultMessage: 'Pomyślnie dodano notatkę',
    },
  },
};
