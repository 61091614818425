import PropTypes from 'prop-types';
import { memo } from 'react';

import { messages } from './BonusTargetOptionsHeader.messages';

const BonusTargetOptionsHeader = (props, context) => (
  <div className="bonusTargetOptions__row">
    <label className="bonusTargetOptions__headerText">{context.intl.formatMessage(messages.targetSPMH)} </label>
    <label className="bonusTargetOptions__headerText">{context.intl.formatMessage(messages.percentSalary)}</label>
  </div>
  );

BonusTargetOptionsHeader.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default memo(BonusTargetOptionsHeader);
