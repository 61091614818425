import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { useLocation } from 'react-router';

const PermissionWrapper = props => {
  const location = useLocation();
  return (
    (<div>
      {props.userPermissions.isEmployee
        ? cloneElement(props.employee, { location })
        : cloneElement(props.manager, { location })}
    </div>)
  );
};

PermissionWrapper.propTypes = {
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  route: PropTypes.shape({
    employee: PropTypes.func,
    manager: PropTypes.func,
  }),
  location: PropTypes.shape({}),
  children: PropTypes.node,
};

export default PermissionWrapper;
