import cn from 'classnames';
import { PropTypes } from 'prop-types';

import './ScheduleItemSelectMarker.scss';

const ScheduleItemSelectMarker = props => {
  if (!props.enabled || props.isQuickPlanningEnabled) return null;
  const className = cn('k-scheduleItemSelectMarker', {
    'k-scheduleItemSelectMarker--coverShiftsLayer': props.availabilitiesModeEnabled,
  });
  return <div className={className} />;
};

ScheduleItemSelectMarker.propTypes = {
  enabled: PropTypes.bool,
  availabilitiesModeEnabled: PropTypes.bool,
  isQuickPlanningEnabled: PropTypes.bool,
};

export default ScheduleItemSelectMarker;
