import { connect } from 'react-redux';

import Component from './ScheduleTableContentBlocker.jsx';

const mapStateToProps = (state, ownProps) => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  locationShiftsBlockDate:
    state.reducer.settings.locationSettings[ownProps.locationId]?.disable_location_schedule_shifts_edit_until,
  scheduleViewMode: state.reducer.schedule.viewMode.viewMode,
  availabilitiesBlockDate: state.reducer.currentCompany.settings.disable_availabilities_edit_until,
});

export default connect(mapStateToProps, null)(Component);
