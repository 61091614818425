import PropTypes from 'prop-types';

import { exportsRoutes } from '@/utils/routes';

import TabNav from './common/TabNav.jsx';
import TopBar from './TopBar.jsx';

const TopBarExports = props => (
  <TopBar
    leftElements={[<TabNav key="tabNav" activePath={props.activePath} routes={exportsRoutes} />]}
    rightElements={[]}
  />
);

TopBarExports.propTypes = {
  activePath: PropTypes.string,
};

export default TopBarExports;
