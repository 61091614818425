import { connect } from 'react-redux';

import { hideModal, replaceModal } from '@/actions/uiState.js';
import ObjectHistoryModal from '@/components/common/ObjectHistoryModal.tsx';
import { OBJECT_HISTORY_MODAL } from '@/constants/modalTypes.js';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === OBJECT_HISTORY_MODAL,
  modalObject: state.reducer.uiState.modalObject[OBJECT_HISTORY_MODAL],
  userJobTitles: state.reducer.userJobTitles,
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  onHide: hideModal,
  replaceModal,
};

const ObjectHistoryModalContainer = connect(mapStateToProps, mapDispatchToProps)(ObjectHistoryModal);

export default ObjectHistoryModalContainer;
