import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import TopBarNotification from '@/components/TopBars/TopBarNotification.jsx';

import PaymentModal from './PaymentModal/PaymentModal.jsx';

const getUnpaidInvoice = invoices => {
  const unpaidInvoices = invoices?.filter(invoice => invoice.status !== 'paid') || [];
  const sortedInvoices = unpaidInvoices.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
  return sortedInvoices[sortedInvoices.length - 1];
};

const PaymentNotification = props => {
  const { payments, userPermissions, toggleNotification, payForInvoice, currentDate } = props;

  const getInvoicePdf = useCallback(
    invoiceId => {
      props.getInvoicePdf(invoiceId).then(result => {
        const binary = atob(result.data.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const pdfData = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          pdfData[i] = binary.charCodeAt(i);
        }
        const downloadUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
        window.open(downloadUrl);
      });
    },
    [props.getInvoicePdf],
  );

  const { client, notification } = payments;
  const newestUnpaidInvoice = useMemo(() => getUnpaidInvoice(client.invoices), [client.invoices]);
  const shouldShowPaymentReminder = client.enable_payu && userPermissions.roles.includes('ROLE_OWNER');

  if (!notification || !shouldShowPaymentReminder || !newestUnpaidInvoice) {
    return null;
  }
  const invoice = newestUnpaidInvoice;
  const days = moment(invoice.payment_date).diff(currentDate, 'days') + 1;
  if (days > 3) {
    return (
      <TopBarNotification
        paymentDate={invoice.payment_date}
        showInvoice={() => getInvoicePdf(invoice.id)}
        toggleNotification={toggleNotification}
        showPayment={() => payForInvoice(invoice.id)}
      />
    );
  }
  return (
    <PaymentModal
      invoice={invoice}
      showInvoice={payForInvoice}
      toggleNotification={toggleNotification}
      currentDate={currentDate}
    />
  );
};

PaymentNotification.defaultProps = {
  currentDate: moment(),
};

PaymentNotification.propTypes = {
  toggleNotification: PropTypes.func,
  getInvoicePdf: PropTypes.func,
  payForInvoice: PropTypes.func,
  payments: PropTypes.shape({
    client: PropTypes.shape({
      payment_delay: PropTypes.number,
      enable_payu: PropTypes.bool,
      invoices: PropTypes.arrayOf(
        PropTypes.shape({
          payment_date: PropTypes.string,
        }),
      ),
    }),
    notification: PropTypes.bool,
  }),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  currentDate: PropTypes.shape({
    diff: PropTypes.func,
  }),
};

export default PaymentNotification;
