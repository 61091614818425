/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes';

import './AbsencesProposalItem.scss';

const AbsencesProposalItem = props => {
  const { absence, first_name, last_name, typeName, showModal } = props;
  const { from, to, absence_hours: absenceHours } = absence;

  const isWholeDay = !absenceHours;

  if (!typeName || !first_name) return null;

  return (
    <div className="absencesProposalItem">
      <div className="absencesProposalItem__info">
        <div className="absencesProposalItem__user">{`${first_name} ${last_name}`}</div>

        <div className="absencesProposalItem__desc">
          <div>{typeName}</div>

          <div>
            {isWholeDay ? (
              <>
                <div>
                  <FormattedMessage id="from" defaultMessage="Od" /> {from}
                </div>
                <div>
                  <FormattedMessage id="to" defaultMessage="Do" /> {to}
                </div>
              </>
            ) : (
              <>
                <div>
                  <FormattedMessage id="absenceProposal.dayWithin" defaultMessage="Dnia" /> {from}
                </div>
                <div>{absenceHours}</div>
              </>
            )}
          </div>
        </div>
      </div>
      <button
        onClick={() => showModal(ADD_ABSENCE_PROPOSAL_MODAL, { ...absence, type_name: typeName })}
        className="absencesProposalItem__link"
      >
        <FormattedMessage id="dashboard.readMore" defaultMessage="Czytaj więcej" />
      </button>
    </div>
  );
};

AbsencesProposalItem.propTypes = {
  absence: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    absence_hours: PropTypes.string,
  }),
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  typeName: PropTypes.string,
  showModal: PropTypes.func,
};

export default AbsencesProposalItem;
