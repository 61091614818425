import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import {
  MDMultiSelect,
  MDMultiSelectFooter,
  MDMultiSelectHeader,
  MDTextArea,
} from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { useInputChange, useMultiSelect } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers';

import {
  determineNewTradeShiftError,
  formatNewTradeShift,
  getEmployeesOptions,
  getInputsInitialState,
  getPreselectedEmployees,
  getSelectedEmployeeIds,
} from './RequestTradeShiftModal.helpers';
import { messages } from './RequestTradeShiftModal.messages';

const RequestTradeShiftModal = (
  { showModal, modalObject, userEmployees, tradeShifts, changeTradeShift, createTradeShift, onHide, contracts },
  { intl },
) => {
  const [state, handlers] = useInputChange(getInputsInitialState(), intl);
  const [modalErrorId, setModalErrorId] = useState(null);
  const [employeesOptions, multiSelectHandlers] = useMultiSelect([]);

  const clearAndHide = useCallback(() => {
    handlers.setInitState();
    multiSelectHandlers.deselectAll();
    onHide();
  }, []);

  useEffect(() => {
    multiSelectHandlers.setItems(getEmployeesOptions(modalObject, userEmployees, contracts));
    if (!modalObject.tradeShiftData?.id) return;
    const { comment, indicated_coworkers: indicatedCoworkers } = modalObject.tradeShiftData;
    const newState = { ...state, comment };
    handlers.updateState(newState);
    const preselectedEmployees = getPreselectedEmployees(indicatedCoworkers);
    multiSelectHandlers.setActiveItems(preselectedEmployees);
  }, [userEmployees, modalObject]);

  const submit = useCallback(async () => {
    if (modalErrorId) setModalErrorId(null);
    const inputError = await handlers.validateInput(createEvent('comment', state.comment));
    if (inputError && inputError.length) return;
    const selectedEmployeesIds = getSelectedEmployeeIds(multiSelectHandlers);
    const newTradeShift = formatNewTradeShift(selectedEmployeesIds, state.comment, modalObject);
    const newModalErrorId = determineNewTradeShiftError(newTradeShift, tradeShifts, modalObject);
    if (newModalErrorId) {
      setModalErrorId(newModalErrorId);
      return;
    }
    const editingTradeShiftId = modalObject.tradeShiftData?.id;
    if (editingTradeShiftId) {
      changeTradeShift(editingTradeShiftId, newTradeShift);
      return;
    }
    createTradeShift(newTradeShift);
    onHide();
  }, [state, userEmployees, employeesOptions]);

  const title = intl.formatMessage(messages.title, {});
  const confirmText = intl.formatMessage(messages.confirmText, {});
  return (
    <MDKadroModal
      show={showModal}
      title={title}
      confirmText={confirmText}
      onSubmit={submit}
      onHide={clearAndHide}
      modifiers={['narrow']}
      errorMessage={modalErrorId ? intl.formatMessage(messages[modalErrorId], {}) : ''}
    >
      <MDTextArea
        label={intl.formatMessage(messages.noteLabel, {})}
        placeholder={intl.formatMessage(messages.notePlaceholder, {})}
        value={state.comment}
        name="comment"
        onChange={handlers.changeInput}
        id="comment"
        errorMessage={state.errors.comment}
      />
      <MDMultiSelectHeader
        title={intl.formatMessage(messages.employeesSelectLabel, {})}
        selectAll={multiSelectHandlers.selectAll}
        deselectAll={multiSelectHandlers.deselectAll}
        selectAllMessage={intl.formatMessage(messages.employeesSelectAll, {})}
        deselectAllMessage={intl.formatMessage(messages.employeesDeselectAll, {})}
      />
      <MDMultiSelect
        label={intl.formatMessage(messages.chooseEmployees, {})}
        options={employeesOptions}
        onChange={multiSelectHandlers.toggle}
      />
      <MDMultiSelectFooter selectedItems={multiSelectHandlers.getSelected()} deleteItem={multiSelectHandlers.toggle} />
    </MDKadroModal>
  );
};

RequestTradeShiftModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

RequestTradeShiftModal.propTypes = {
  modalObject: PropTypes.shape({
    tradeShiftData: PropTypes.shape({
      id: PropTypes.string,
      comment: PropTypes.string,
      indicated_coworkers: PropTypes.arrayOf(PropTypes.shape()),
    }),
    shift: PropTypes.shape({
      id: PropTypes.string,
      location: PropTypes.shape({
        id: PropTypes.string,
      }),
      job_title: PropTypes.shape({
        id: PropTypes.string,
      }),
      employee: PropTypes.shape({
        id: PropTypes.string,
      }),
      date: PropTypes.string,
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  tradeShifts: PropTypes.arrayOf(PropTypes.shape({})),
  demo: PropTypes.shape({
    demoAccount: PropTypes.bool,
  }),
  onHide: PropTypes.func,
  createTradeShift: PropTypes.func,
  changeTradeShift: PropTypes.func,
  showModal: PropTypes.bool,
};

export default RequestTradeShiftModal;
