import { messages } from './EmployeeTimesheetTable.messages';

export const columnsData = [
  {
    Header: messages.date,
    accessor: 'date',
  },
  {
    Header: messages.location,
    accessor: 'locationName',
  },
  {
    Header: messages.from,
    accessor: 'startHour',
  },
  {
    Header: messages.to,
    accessor: 'endHour',
  },
  {
    Header: messages.jobTitle,
    accessor: 'jobTitleName',
  },
  {
    Header: messages.absence,
    accessor: 'absenceInfo',
  },
  {
    Header: messages.duration,
    accessor: 'durationTime',
  },
];
