import classnames from 'classnames';
import PropTypes from 'prop-types';

import ScheduleBudgetTableRowInput from '@/components/scheduler/budget/ScheduleBudgetTableRowInput/ScheduleBudgetTableRowInput';
import { budgetMetricHash } from '@/utils/budgetHelpers';
import { isWeekend } from '@/utils/dateHelper';

import { getMetricValueSuffix } from '../ScheduleBudgetTableMetrics.helpers';

const ScheduleBudgetTableMetricsCell = ({
  budgetMetric,
  budgetMetricsData,
  date,
  today,
  dayType,
  activeLocationIds,
  selectedDisplayMode,
  showHolidaysSetting,
  canEdit,
  updateBudgetMetricData,
}) => {
  const showHolidays = selectedDisplayMode !== 'templates' && showHolidaysSetting;

  const className = classnames('k-budgetTable__cell', 'k-budgetMetricCell', {
    today: date === today,
    weekend: isWeekend(date),
    freeFromWorkHoliday: showHolidays && dayType === 'freeFromWorkHoliday',
    workDayHoliday: showHolidays && dayType === 'holiday',
  });

  const hashKey = budgetMetricHash(budgetMetric.id, date);

  const value =
    budgetMetricsData[hashKey] && !budgetMetricsData[hashKey].every(data => data === null)
      ? budgetMetricsData[hashKey].reduce((acc, data) => acc + data)
      : null;

  const onChange = e => {
    let newValue = e[budgetMetric.name];

    if (Number.isNaN(newValue)) newValue = '-';
    if (!canEdit) return;

    const formattedValue = newValue === '-' ? null : newValue;
    updateBudgetMetricData(budgetMetric.id, date, [formattedValue]);
  };
  const result = value !== null ? `${value / 100}${getMetricValueSuffix(budgetMetric)}` : '-';
  return (
    <td className={className}>
      {canEdit ? (
        <ScheduleBudgetTableRowInput
          key={date}
          name={budgetMetric.name}
          estimate={{
            date,
            location_id: activeLocationIds[0],
            [budgetMetric.name]: value,
          }}
          onChange={onChange}
          setError={() => {}}
        />
      ) : (
        `${result}`
      )}
    </td>
  );
};

ScheduleBudgetTableMetricsCell.propTypes = {
  budgetMetric: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  budgetMetricsData: PropTypes.shape({}),
  date: PropTypes.string,
  today: PropTypes.string,
  dayType: PropTypes.string,
  activeLocationIds: PropTypes.arrayOf(PropTypes.number),
  selectedDisplayMode: PropTypes.string,
  showHolidaysSetting: PropTypes.bool,
  canEdit: PropTypes.bool,
  updateBudgetMetricData: PropTypes.func,
  dateArray: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableMetricsCell;
