import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  autoGenerateStepsModalContractsWarningOneEmployee: {
    id: 'autoGenerateSteps.warning.contractsOneEmployee',
    defaultMessage: 'Pracownik: {employeeNames} ma różne stanowiska i dla niego nie wygenerujemy grafiku',
  },
  autoGenerateStepsModalContractsWarningMultipleEmployees: {
    id: 'autoGenerateSteps.warning.contractsMultipleEmployees',
    defaultMessage: 'Pracownicy: {employeeNames} mają różne stanowiska i dla nich nie wygenerujemy grafiku',
  },
});
