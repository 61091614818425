import {
  FREEMIUM_EVENT_EMPLOYEE_MODAL_ABSENCE_LIMITS,
  FREEMIUM_EVENT_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS,
} from '@/constants/intercom';
import {
  COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT,
  FREEMIUM_DISABLE_EMPLOYEE_MODAL_ABSENCE_LIMITS,
  FREEMIUM_DISABLE_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS,
} from '@/constants/Restrictions';
import { checkArrayIntersection } from '@/utils/arrayHelpers';

import { messages } from './tabs.messages';
import { CUSTOM_FIELDS_SHOW } from '@/constants/Permissions';

const nameBasicFields = ['email2', 'name', 'lastName'];

const locationsFields = ['locations'];

const contractsFields = ['jobTitles'];

const employmentConditionsFields = [
  'employmentConditionsTemplateId',
  'weeklyWorkingMinutes',
  'maxDailyWorkingMinutes',
  'scheduleCycleDuration',
];

const additionalFields = ['phone', 'pin4', 'referenceId', 'alias', 'nfcCode'];

export const employeeSidebarTabs = (intl, errors, restrictions, showAbsences, userPermissions) =>
  [
    {
      name: intl.formatMessage(messages.tabNameBasic),
      fields: nameBasicFields,
      testId: 'basicInformationTab',
    },
    {
      name: intl.formatMessage(messages.tabNameLocations),
      fields: locationsFields,
      testId: 'locationsTab',
    },
    {
      name: intl.formatMessage(messages.tabNameContracts),
      fields: contractsFields,
      testId: 'contractsTab',
    },
    {
      name: intl.formatMessage(messages.tabNameConditions),
      fields: employmentConditionsFields,
      freemiumRestrictions: [FREEMIUM_DISABLE_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS],
      intercomEvent: FREEMIUM_EVENT_EMPLOYEE_MODAL_EMPLOYMENT_CONDITIONS,
      testId: 'employmentConditionsTab',
    },
    {
      name: intl.formatMessage(messages.tabNameAdditional),
      fields: additionalFields,
      testId: 'additionalFieldsTab',
    },

    {
      name: intl.formatMessage(messages.tabNameLimits),
      disabled: restrictions.includes(COMPANY_MANAGE_EMPLOYEES_LIMITS_HIDE_EDIT) || !showAbsences,
      freemiumRestrictions: [FREEMIUM_DISABLE_EMPLOYEE_MODAL_ABSENCE_LIMITS],
      intercomEvent: FREEMIUM_EVENT_EMPLOYEE_MODAL_ABSENCE_LIMITS,
      testId: 'absenceLimitsTab',
    },
    {
      name: intl.formatMessage(messages.customFields),
      testId: 'customFieldsTab',
      permissions: [CUSTOM_FIELDS_SHOW],
    },
  ]
    .map((tab, index) => ({
      ...tab,
      id: index,
      error: (tab.fields || []).some(field => errors[field]),
      showFreemiumStar: checkArrayIntersection(restrictions, tab.freemiumRestrictions || []),
    }))
    .filter(tab => !tab.permissions || tab.permissions.some(permission => userPermissions.includes(permission)));
