import { connect } from 'react-redux';

import { getAbsencesLimitTemplates } from '@/actions/absences.js';

import AbsenceLimitsTab from './AbsenceLimitsTab.jsx';

const mapStateToProps = state => ({
  absencesTypes: state.reducer.absences.absencesTypes,
  absencesLimitTemplates: state.reducer.absences.absencesLimitTemplates,
});

const mapDispatchToProps = {
  getAbsencesLimitTemplates,
};

const AbsenceLimitsTabContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceLimitsTab);

export default AbsenceLimitsTabContainer;
