import classnames from 'classnames';
import { useRef } from 'react';

import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements';
import { Employee } from '@/types';
import { LoanEmployeesProposals } from '@/types/loanEmployeesProposals';
import { getSizeOfDates } from '@/utils/shiftHelpers';

import LoanEmployeesProposalRowTitle from './loanEmployeesProposalsRowTitle/LoanEmployeesProposalsRowTitle.redux';
import { getIncomingAndOutgoingProposals } from './scheduleTableLoanEmployeesProposals.helpers';
import ScheduleTableLoanEmployeesProposalCell from './scheduleTableLoanEmployeesProposalsCell/ScheduleTableLoanEmployeesProposalsCell.redux';

import './scheduleTableLoanEmployeesProposals.scss';

interface ScheduleTableLoanEmployeesProposalsRowInterface {
  locationId: string;
  dateArray: string[];
  isLoanEmployeesProposalsSticky: boolean;
  loanEmployeesProposals: LoanEmployeesProposals[];
  userEmployees: Employee[];
}

const ScheduleTableLoanEmployeesProposalRow = ({
  locationId,
  dateArray,
  isLoanEmployeesProposalsSticky,
  loanEmployeesProposals,
  userEmployees,
}: ScheduleTableLoanEmployeesProposalsRowInterface) => {
  const loanEmployeesProposalRef = useRef(null);
  const [top] = useMultipleStickyElements(loanEmployeesProposalRef, 'scheduleTableHeader--sticky');

  const size = getSizeOfDates(dateArray.length);
  const className = classnames('k-loanEmployeesProposalsRow', 'scheduleTableHeader', {
    'scheduleTableHeader--sticky': isLoanEmployeesProposalsSticky,
  });
  const { incomingLoanEmployeesProposals, outgoingLoanEmployeesProposals } = getIncomingAndOutgoingProposals(
    loanEmployeesProposals,
    locationId,
    userEmployees,
  );

  return (
    <tr
      ref={loanEmployeesProposalRef}
      className={className}
      style={{
        top,
      }}
    >
      <LoanEmployeesProposalRowTitle />
      {dateArray.map(date => (
        <ScheduleTableLoanEmployeesProposalCell
          date={date}
          key={date}
          size={size}
          incomingLoanEmployeesProposals={incomingLoanEmployeesProposals}
          outgoingLoanEmployeesProposals={outgoingLoanEmployeesProposals}
          locationId={locationId}
        />
      ))}
    </tr>
  );
};

export default ScheduleTableLoanEmployeesProposalRow;
