import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents';
import { VIDEO_TUTORIAL_MODAL } from '@/constants/modalTypes';
import { startTour } from '@/utils/intercomHelpers';

import messages from './startSteps.messages';
import { milestoneMessages } from './trialMilestones.constants';

const Step = ({ labelIntl, name, isNextStep, isCompleted, helpLink, videoLink, tutorialId, showModal }, { intl }) => {
  const hasLinks = !!helpLink || !!videoLink || !!tutorialId;
  const label = intl.formatMessage(labelIntl);
  const testId = `milestones.${name.toLowerCase()}`;
  return (
    <div className="step" data-test={testId}>
      <MDCheckbox
        disabled
        reverse
        id={name}
        className="step__checkbox"
        value={isCompleted}
        text={
          <div className={classnames('step__label', { 'step__label--next-step': isNextStep })} title={label}>
            {label}
          </div>
        }
      />
      {hasLinks && (
        <div className="step__links">
          <div className="step__links-label">
            <FormattedMessage {...messages.watchHow} />
          </div>
          {helpLink && (
            <a
              className="step__link"
              href={helpLink}
              target="_blank"
              rel="noreferrer"
              data-test={`${testId}.show-help`}
            >
              <i className="material-icons-outlined">chrome_reader_mode</i>
              <FormattedMessage {...messages.help} />
            </a>
          )}
          {tutorialId && (
            <div
              className="step__link"
              onClick={() => startTour(tutorialId)}
              role="presentation"
              data-test={`${testId}.start-tutorial`}
            >
              <i className="material-icons-outlined">support</i>
              <FormattedMessage {...messages.tutorial} />
            </div>
          )}
          {videoLink && (
            <div
              role="presentation"
              className="step__link"
              onClick={() =>
                showModal(VIDEO_TUTORIAL_MODAL, {
                  link: videoLink,
                  title: intl.formatMessage(milestoneMessages[name]),
                })
              }
              target="_blank"
              rel="noreferrer"
              data-test={`${testId}.show-video`}
            >
              <i className="material-icons-outlined">smart_display</i>
              <FormattedMessage {...messages.video} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Step.contextTypes = {
  intl: PropTypes.shape({}),
};

Step.propTypes = {
  helpLink: PropTypes.string,
  isCompleted: PropTypes.bool,
  isNextStep: PropTypes.bool,
  labelIntl: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  tutorialId: PropTypes.string,
  videoLink: PropTypes.string,
  onChange: PropTypes.func,
  showModal: PropTypes.func,
};

export default Step;
