import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon';
import LoadingSpinnerWithCheckmark from '@/components/common/LoadingSpinnerWithCheckmark/LoadingSpinnerWithCheckmark';

const ExportsWidgetStatusIcon = ({ status }) => {
  switch (status) {
    case 'error':
      return (
        <Icon
          className="k-exportsWidgetButton__status k-exportsWidgetButton__status--orange"
          name="error"
          type="material"
        />
      );
    default:
      return <LoadingSpinnerWithCheckmark status={status} />;
  }
};

ExportsWidgetStatusIcon.propTypes = {
  status: PropTypes.string,
};

export default ExportsWidgetStatusIcon;
