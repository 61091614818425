import { FormattedMessage } from 'react-intl';

import { messages, tooltipLinks, tooltipMessages } from './PayrollSettingsModal.messages';

export const MODAL_MODIFIERS = ['wide', 'high'];
export const TIME_INPUT_MODIFIERS = ['orange', 'smaller'];

export const isSettingTypeAsShifts = settingType => settingType === 'shifts';

export const generateTooltip = (settingId, intl) => {
  const description = tooltipMessages[settingId];
  const link = description && tooltipLinks[settingId];

  return (
    description && (
      <FormattedMessage
        {...description}
        values={{
          clickHere: link && (
            <a target="_blank" rel="noreferrer" href={intl.formatMessage(link)}>
              {intl.formatMessage(messages.clickHere)}
            </a>
          ),
        }}
      />
    )
  );
};
