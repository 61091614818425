import { connect } from 'react-redux';

import { confirmRegulations,getRegulations } from '@/actions';
import NewsBar from '@/components/news/NewsBar.jsx';

const mapStateToProps = state => ({
  currentCompany: state.reducer.currentCompany,
  currentUser: state.reducer.currentUser,
});

const mapDispatchToProps = {
  getRegulations,
  confirmRegulations,
};

const NewsBarContainer = connect(mapStateToProps, mapDispatchToProps)(NewsBar);

export default NewsBarContainer;
