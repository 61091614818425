import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon';
import { NUMBER_OF_MINUTES_IN_DAY } from '@/constants/budgetMetrics';

import ScheduleBudgetTableMetricsCell from './components/ScheduleBudgetTableMetricsCell.redux';
import {
  filterMetrics,
  getMetricsTotalValue,
  getMetricValueSuffix,
  getNumberOfFilledDays,
} from './ScheduleBudgetTableMetrics.helpers';

import './ScheduleBudgetTableMetrics.scss';

const ScheduleBudgetTableMetrics = ({ dateArray, budgetMetrics, activeLocationIds, budgetMetricsData, canEdit }) => {
  const parsedActiveLocationIds = activeLocationIds.map(value => parseInt(value)) || [];
  const filteredMetrics = filterMetrics(budgetMetrics, parsedActiveLocationIds);

  const canEditBudgetMetric = ({ time_interval: timeInterval }) =>
    canEdit && activeLocationIds.length === 1 && timeInterval === NUMBER_OF_MINUTES_IN_DAY;

  return (
    <>
      {filteredMetrics.map(budgetMetric => {
        const filledDays = getNumberOfFilledDays(budgetMetricsData, dateArray, budgetMetric);

        const { id, name, icon, settings } = budgetMetric;
        const { aggregation } = settings;
        const metricsTotalValue = getMetricsTotalValue(id, dateArray, budgetMetricsData, aggregation, filledDays);
        const formattedMetricsTotalValue = metricsTotalValue === null ? '-' : (metricsTotalValue / 100).toFixed(2);

        return (
          <tr key={id}>
            <td className="k-budgetTable__leftColumn k-budgetMetricHeaderCell">
              <span className="k-budgetMetricHeaderCell__leftRow">
                <Icon name={icon} />
                <span className="k-budgetMetricHeaderCell__name">{name}</span>
              </span>
              <span className="k-budgetMetricHeaderCell__rightRow">
                {formattedMetricsTotalValue}
                {getMetricValueSuffix(budgetMetric)}
              </span>
            </td>
            {dateArray.map(date => (
              <ScheduleBudgetTableMetricsCell
                key={date}
                budgetMetric={budgetMetric}
                date={date}
                activeLocationIds={parsedActiveLocationIds}
                canEdit={canEditBudgetMetric(budgetMetric)}
                budgetMetricsData={budgetMetricsData}
              />
            ))}
          </tr>
        );
      })}
    </>
  );
};

ScheduleBudgetTableMetrics.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  budgetMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      time_interval: PropTypes.number,
    }),
  ),
  activeLocationIds: PropTypes.arrayOf(PropTypes.number),
  budgetMetricsData: PropTypes.shape({}),
  canEdit: PropTypes.bool,
};

export default ScheduleBudgetTableMetrics;
