import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  annuallyPayment: {
    id: 'paymentToggle.annuallyPayment',
    defaultMessage: 'Płatność roczna',
  },
  monthlyPayment: {
    id: 'paymentToggle.monthlyPayment',
    defaultMessage: 'Płatność miesięczna',
  },
  savedAmount: {
    id: 'paymentToggle.savedAmount',
    defaultMessage: 'Oszczędzasz',
  },
});
