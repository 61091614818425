 
import { AbsenceType, JobTitle } from 'kadro-helpers/lib/types';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { useInputChange, useMultiSelect, useStepsCounter } from '@/hooks';
import { ExportModalObject, PayrollLocationOldFiltersState } from '@/types';
import { createEvent } from '@/utils/inputHelpers';

import { getColumnsForFormat, getUpdatedOptions } from '../exports.helpers';
import { messages } from '../exports.messages';
import {
  canContinue,
  DEFAULT_EXPORTS_STEPS_COUNT,
  displayRelevantStep,
  getAbsenceTypesOptions,
  getExportOptionsFromLocalStorage,
  getExportPayload,
  getInitialState,
  MODAL_MODIFIERS,
  saveExportOptionsToLocalStorage,
} from './NewPayrollExportModal.helpers';

export interface NewPayrollExportModalProps {
  isModalVisible: boolean;
  modalObject: ExportModalObject;
  absenceTypes: AbsenceType[];
  selectedLocations: string[];
  selectedJobTitles: JobTitle[];
  selectedEmploymentConditions: { id: string }[];
  dateArray: string[];
  userId: string;
  selectedEmployeeId?: string;
  hideModalAndLaterClearModalObject: () => void;
  getOldFiltersState: () => PayrollLocationOldFiltersState | undefined;
  startExport: (exportId: string, displayName: string, payload: any) => void;
  shouldBaseExportsOnOldFiltersState: boolean;
  sortingUseLastName: boolean;
}

const NewPayrollExportModal = (props: NewPayrollExportModalProps, { intl }) => {
  const { isModalVisible, modalObject, absenceTypes, hideModalAndLaterClearModalObject, startExport, userId } = props;

  const [state, handlers] = useInputChange(getInitialState(modalObject), intl);
  const { modalExportId } = state;
  const { currentStep, stepsCount, isLastStep, handlers: stepsHandlers } = useStepsCounter(DEFAULT_EXPORTS_STEPS_COUNT);

  const [absenceTypesItems, absenceTypesHandlers] = useMultiSelect(getAbsenceTypesOptions(absenceTypes));
  const [columnsItems, columnsSelectionHandlers] = useMultiSelect([]);

  useEffect(() => {
    if (!isModalVisible) return;
    stepsHandlers.setInitialValues();
  }, [isModalVisible]);

  useEffect(() => {
    const dataFromLocalStorage = getExportOptionsFromLocalStorage(modalExportId, userId);
    if (dataFromLocalStorage) {
      const { multiSelectOptions, options } = dataFromLocalStorage;
      const {
        absenceTypesItems: absenceTypesItemsFromLocalStorage,
        columnsItems: columnsItemsFromLocalStorage,
      } = multiSelectOptions;
      handlers.changeInput(createEvent('options', options));
      stepsHandlers.setStepsCount(DEFAULT_EXPORTS_STEPS_COUNT);
      columnsSelectionHandlers.setItems(columnsItemsFromLocalStorage);
      absenceTypesHandlers.setItems(absenceTypesItemsFromLocalStorage);
    } else {
      const selectedFormat = modalObject?.formats?.find(({ exportId }) => exportId === modalExportId);
      handlers.changeInput(createEvent('options', getUpdatedOptions(selectedFormat)));
      stepsHandlers.setStepsCount(DEFAULT_EXPORTS_STEPS_COUNT);
      columnsSelectionHandlers.setItems(getColumnsForFormat(selectedFormat));
    }
  }, [modalExportId]);

  const multiSelects = {
    absenceTypesItems,
    absenceTypesHandlers,
    columnsItems,
    columnsSelectionHandlers,
  };

  const clearHideAndSaveOptions = () => {
    if (state.modalExportId) saveExportOptionsToLocalStorage(state, { absenceTypesItems, columnsItems }, userId);
    hideModalAndLaterClearModalObject();
    handlers.setInitState();
  };

  const runExport = () => {
    const oldFiltersState = props.shouldBaseExportsOnOldFiltersState ? props.getOldFiltersState() : undefined;

    const { title, fileIconText } = props.modalObject.formats.find(exportData => exportData.exportId === modalExportId);

    const payload = getExportPayload(state, props, multiSelects, fileIconText, oldFiltersState);
    startExport(modalExportId, title, payload);
    clearHideAndSaveOptions();
  };

  const onCancel = () => {
    stepsHandlers.decreaseStep();
    if (currentStep === 1) clearHideAndSaveOptions();
  };

  const onSubmit = () => {
    stepsHandlers.increaseStep();
    if (isLastStep) runExport();
  };

  return (
    <MDKadroModal
      show={isModalVisible}
      onHide={clearHideAndSaveOptions}
      onCancelFooter={onCancel}
      title={intl.formatMessage(messages.export)}
      modifiers={MODAL_MODIFIERS}
      onSubmit={onSubmit}
      disableConfirm={!canContinue(currentStep, state, modalObject?.formats)}
      confirmText={intl.formatMessage(messages[isLastStep ? 'export' : 'next'])}
      cancelText={intl.formatMessage(messages[currentStep === 1 ? 'cancel' : 'back'])}
      showProgressBar
      currentStep={currentStep}
      stepsCount={stepsCount}
      withoutOverlayScroll
      inputAwareSubmit
    >
      {displayRelevantStep(modalObject, state, handlers, currentStep, multiSelects)}
    </MDKadroModal>
  );
};

NewPayrollExportModal.contextTypes = {
  intl: PropTypes.shape({}),
};

export default NewPayrollExportModal;
