import { messages } from './PasswordSettings.messages';

export const getPasswordRefreshRules = context => [
  {
    id: 0,
    name: context.intl.formatMessage(messages.noChange, {}),
    value: 0,
  },
  {
    id: 1,
    name: context.intl.formatMessage(messages.days30, {}),
    value: 30,
  },
  {
    id: 2,
    name: context.intl.formatMessage(messages.days90, {}),
    value: 90,
  },
  {
    id: 3,
    name: context.intl.formatMessage(messages.days180, {}),
    value: 180,
  },
];
