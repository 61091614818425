import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  schedule: {
    id: 'onboarding.secondStep.schedule',
    defaultMessage: 'Grafik Pracy',
  },
  rcp: {
    id: 'onboarding.secondStep.rcp',
    defaultMessage: 'Rejestracja Czasu Pracy',
  },
  absences: {
    id: 'onboarding.secondStep.absences',
    defaultMessage: 'Urlopy i delegacje',
  },
});

export const appOptions = [
  { icon: 'date_range', id: 'schedule' },
  { icon: 'watch_later', id: 'rcp' },
];
