import PropTypes from 'prop-types';

import MapContainer from '@/components/common/Map/MapContainer.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import { getAttendanceMarkers,messages } from './AttendanceMapModalHelpers.js';

import './AttendanceMapModal.scss';

const AttendanceMapModal = ({ isVisible, modalObject, hideModal }, { intl }) => {
  if (!modalObject) {
    return null;
  }

  const { attendance, location, locationSettings: settings } = modalObject;
  const locationCoords = settings.location_coordinates.split(' ');
  const { open_coords: startAttendanceCoords, close_coords: endAttendanceCoords } = attendance;

  const markers = getAttendanceMarkers(startAttendanceCoords, endAttendanceCoords).map(marker => ({
    ...marker,
    popup: intl.formatMessage(marker.popup),
  }));
  const circles = [
    {
      center: locationCoords,
      pathOptions: { color: 'red' },
      radius: settings.attendance_location_radius_limit,
      popup: location.name,
    },
  ];

  return (
    <MDKadroModal
      show={isVisible}
      title={intl.formatMessage(messages.title, {})}
      onHide={hideModal}
      modifiers={['narrow']}
      hideFooter
    >
      <div className="attendanceMapContainer">
        <MapContainer position={locationCoords} zoom={10} markers={markers} circles={circles} />
      </div>
    </MDKadroModal>
  );
};

AttendanceMapModal.contextTypes = {
  intl: PropTypes.shape({}),
};

AttendanceMapModal.propTypes = {
  isVisible: PropTypes.bool,
  modalObject: PropTypes.shape({
    attendance: PropTypes.shape({
      open_coords: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }),
      close_coords: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }),
    }),
    location: PropTypes.shape({
      name: PropTypes.string,
    }),
    locationSettings: PropTypes.shape({
      location_coordinates: PropTypes.string,
    }),
  }),
  hideModal: PropTypes.func,
};

export default AttendanceMapModal;
