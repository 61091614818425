import { connect } from 'react-redux';

import ScheduleBudgetTableRecommendedScheduleCell from './ScheduleBudgetTableRecommendedScheduleCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  recommendedSchedule: state.reducer.schedule.budget.recommendedSchedule[ownProps.date],
  today: state.reducer.mainDateStore.today,
  showHolidaysSetting: state.reducer.schedule.viewSettings.holidays.value,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode.type,
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitlesGrouped,
  selectedLocationIds: state.reducer.scheduleLocationFilter,
});

export default connect(mapStateToProps)(ScheduleBudgetTableRecommendedScheduleCell);
