import moment from 'moment';
import PropTypes from 'prop-types';

import AppBlocked from '../AppBlocked/AppBlocked.redux.js';
import { messages } from './UnlockAccountViewPayment.messages.js';

const UnlockAccountPayment = ({ payForInvoice, unlockPaymentData }) => {
  const handlePayForInvoice = () => {
    const invoiceToPay = unlockPaymentData.invoices_to_pay[0];
    if (!invoiceToPay) return;
    payForInvoice(invoiceToPay.id).then(result => {
      window.location.href = result.redirect_link;
    });
  };

  const unpaidInvoices = unlockPaymentData.invoices_to_pay;
  const invoiceToPay = unpaidInvoices[0] || { amount: 0 };
  const daysAfterPaymentDeadline = Math.round(moment().diff(moment(invoiceToPay.payment_date), 'hours') / 24);
  const paidToDate = unlockPaymentData.paid_to_date;

  return (
    <AppBlocked
      handleButtonClick={handlePayForInvoice}
      titleMessage={messages.paymentTitle}
      contentMessage={{
        ...(daysAfterPaymentDeadline > 0 ? messages.paymentContent : messages.paymentContentToday),
        values: { days: daysAfterPaymentDeadline },
      }}
      buttonText={messages.paymentButton}
      buttonDisabled={!paidToDate || !unpaidInvoices.length}
      additionalTextMessage={{
        ...messages.amount,
        values: { amount: (invoiceToPay.amount / 100).toFixed(2) },
      }}
      alt="klepsydra"
      imgSrc="img/unlockAccount/noTimeLeft.png"
    />
  );
};

UnlockAccountPayment.propTypes = {
  payForInvoice: PropTypes.func,
  unlockPaymentData: PropTypes.shape({
    invoices_to_pay: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    blocked: PropTypes.bool,
    paid_to_date: PropTypes.string,
    clientStatus: PropTypes.string,
  }),
};

export default UnlockAccountPayment;
