import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Popover from '@/components/common/Popover/Popover';
import { COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT } from '@/constants/Restrictions';
import { getBillingPeriod, getBillingWeek, parseMinutesToFormat } from '@/utils/dateHelper.js';
import { getEmploymentConditionName } from '@/utils/employmentConditionsHelpers';

import './scheduleTableRowTitlePopover.scss';

const ScheduleTableRowTitlePopover = ({
  employeeId,
  employmentConditions,
  employmentConditionList,
  children,
  firstDayInSchedule,
  showEmployeeModal,
  restrictions,
  companySettings,
  currentUserRoleId,
  companyRoles,
  employeeRole,
  currentUserId,
}) => {
  const {
    max_daily_working_minutes: maxDailyWorkingMinutes,
    schedule_cycle: scheduleCycle,
    validate_working_rules: validateWorkingRules,
    show_absences: showAbsences,
    elastic_work_day: elasticWorkDay,
  } = employmentConditions;

  const popoverRef = useRef(null);

  const maxDailyWorkTime = parseMinutesToFormat(maxDailyWorkingMinutes);
  const employmentConditionName = getEmploymentConditionName(employmentConditions, employmentConditionList);
  const scheduleCycleMonthName = getBillingPeriod(firstDayInSchedule, scheduleCycle, 'dddd DD.MM').start;
  const { firstDayOfBillingWeek, lastDayOfBillingWeek } = getBillingWeek(firstDayInSchedule, scheduleCycle, 'dd');
  const isUoz = employmentConditions.template_id === 'uoz';

  const employmentConditionsDuration = scheduleCycle ? scheduleCycle.duration : '';

  const employmentConditionsMonth = scheduleCycle ? scheduleCycle.month : '';

  const hasRestrictionForEditing = restrictions.includes(COMPANY_MANAGE_EMPLOYEES_HIDE_EDIT);
  const isOwner = currentUserRoleId === 'owner';
  const currentUserRank = companyRoles.find(role => role.id === currentUserRoleId)?.rank;
  const employeeRank = companyRoles.find(role => role.id === employeeRole)?.rank;
  const hasAccessToEdit =
    !hasRestrictionForEditing &&
    (isOwner || companySettings.same_rank_is_visible || currentUserRank > employeeRank || currentUserId === employeeId);

  const popoverContent = (
    <div className="k-scheduleTableRowTitlePopover">
      <ul className="k-scheduleTableRowTitlePopover__list">
        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.employmentCondition"
            defaultMessage="Warunek zatrudnienia:"
          />{' '}
          <span className="k-scheduleTableRowTitlePopover__value--bold">{employmentConditionName || '-'}</span>
        </li>
        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.maxDailyWorkTime"
            defaultMessage="Dobowy wymiar czasu pracy:"
          />{' '}
          <span className="font-bold">
            {maxDailyWorkingMinutes ? (
              maxDailyWorkTime
            ) : (
              <FormattedMessage id="scheduleTableRowTitle.popover.lack" defaultMessage="Brak" />
            )}
          </span>
        </li>
        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.scheduleCycleDuration"
            defaultMessage="Długość okresu rozliczeniowego:"
          />{' '}
          {isUoz ? (
            <span className="k-scheduleTableRowTitlePopover__value--bold">
              <FormattedMessage id="scheduleTableRowTitle.popover.lack" defaultMessage="Brak" />
            </span>
          ) : (
            <span className="k-scheduleTableRowTitlePopover__value--bold">
              {employmentConditionsDuration && `${employmentConditionsDuration} msc`}
            </span>
          )}
        </li>
        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.scheduleCycleStart"
            defaultMessage="Początek bieżącego okresu rozliczeniowego:"
          />{' '}
          {isUoz ? (
            <span className="k-scheduleTableRowTitlePopover__value--bold">
              <FormattedMessage id="scheduleTableRowTitle.popover.lack" defaultMessage="Brak" />
            </span>
          ) : (
            <span className="k-scheduleTableRowTitlePopover__value--bold">
              {employmentConditionsMonth && scheduleCycleMonthName}
            </span>
          )}
        </li>
        {firstDayOfBillingWeek && (
          <li>
            <FormattedMessage
              id="scheduleTableRowTitle.popover.startBillingWeek"
              defaultMessage="Bieżący tydzień rozliczeniowy trwa od:"
            />{' '}
            {isUoz ? (
              <span className="k-scheduleTableRowTitlePopover__value--bold">
                <FormattedMessage id="scheduleTableRowTitle.popover.lack" defaultMessage="Brak" />
              </span>
            ) : (
              <>
                <span className="k-scheduleTableRowTitlePopover__value--bold">
                  {firstDayOfBillingWeek.toLowerCase()}
                </span>{' '}
                <FormattedMessage id="scheduleTableRowTitle.popover.to" defaultMessage="do:" />{' '}
                <span className="k-scheduleTableRowTitlePopover__value--bold">
                  {lastDayOfBillingWeek.toLowerCase()}
                </span>
              </>
            )}
          </li>
        )}

        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.validateWorkingRules"
            defaultMessage="Zasady harmonogramowania:"
          />{' '}
          <span className="k-scheduleTableRowTitlePopover__value--bold">
            {employmentConditions && validateWorkingRules ? (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOn" defaultMessage="włączone" />
            ) : (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOff" defaultMessage="wyłączone" />
            )}
          </span>
        </li>
        <li>
          <FormattedMessage
            id="scheduleTableRowTitle.popover.showAbsences"
            defaultMessage="Ewidencjonowanie urlopów i delegacji:"
          />{' '}
          <span className="k-scheduleTableRowTitlePopover__value--bold">
            {employmentConditions && showAbsences ? (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOn" defaultMessage="włączone" />
            ) : (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOff" defaultMessage="wyłączone" />
            )}
          </span>
        </li>
        <li>
          <FormattedMessage id="scheduleTableRowTitle.popover.elasticWorkDay" defaultMessage="Ruchomy czas pracy:" />{' '}
          <span className="k-scheduleTableRowTitlePopover__value--bold">
            {employmentConditions && elasticWorkDay ? (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOn" defaultMessage="włączone" />
            ) : (
              <FormattedMessage id="scheduleTableRowTitle.popover.valueOff" defaultMessage="wyłączone" />
            )}
          </span>
        </li>
      </ul>
      {hasAccessToEdit && (
        <Button
          modifiers={['orange', 'teeny']}
          onClick={() => {
            showEmployeeModal(employeeId);
            popoverRef.current.hide();
          }}
        >
          <FormattedMessage id="scheduleTableRowTitle.popover.button" defaultMessage="Ustawienia pracownika" />
        </Button>
      )}
    </div>
  );

  return (
    <div className="k-scheduleTableRowTitlePopover__wrapper">
      <Popover
        ref={popoverRef}
        showOnHover
        hideOnClick
        position="right"
        xOffset={-8}
        yOffset={-5}
        showShadow={false}
        content={popoverContent}
        hideTimeout={0}
        useParentDimensions
        preventPopoverHide
        childId={employeeId}
      >
        <div className="k-scheduleTableRowTitlePopover__container">{children}</div>
      </Popover>
    </div>
  );
};

ScheduleTableRowTitlePopover.propTypes = {
  employeeId: PropTypes.string,
  employmentConditions: PropTypes.shape({
    user_id: PropTypes.number,
    schedule_cycle: PropTypes.shape({
      duration: PropTypes.number,
      month: PropTypes.number,
    }),
    max_daily_working_minutes: PropTypes.number,
    show_absences: PropTypes.bool,
    elastic_work_day: PropTypes.bool,
    validate_working_rules: PropTypes.bool,
    template_id: PropTypes.string,
  }),
  employmentConditionList: PropTypes.shape({}),
  children: PropTypes.node,
  firstDayInSchedule: PropTypes.string,
  showEmployeeModal: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  companySettings: PropTypes.shape({}),
  currentUserRoleId: PropTypes.string,
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
  employeeRole: PropTypes.string,
  currentUserId: PropTypes.string,
};

export default ScheduleTableRowTitlePopover;
