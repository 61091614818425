import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showAllAttendances: {
    id: 'attendanceSettings.showAllAttendances',
    defaultMessage: 'Pokaż wszystkie obecności',
  },
  attendanceWithoutShift: {
    id: 'attendanceSettings.attendanceWithoutShift',
    defaultMessage: 'Pokaż obecności niezaplanowane',
  },
  showLateAttendances: {
    id: 'attendanceSettings.showLateAttendances',
    defaultMessage: 'Pokaż spóźnienia',
  },
  showAttendancesStartedEarly: {
    id: 'attendanceSettings.showAttendancesStartedEarly',
    defaultMessage: 'Pokaż wcześniejsze przyjścia',
  },
  showAttendancesEndedEarly: {
    id: 'attendanceSettings.showAttendancesEndedEarly',
    defaultMessage: 'Pokaż wcześniejsze wyjścia',
  },
  showAttendancesEndedLate: {
    id: 'attendanceSettings.showAttendancesEndedLate',
    defaultMessage: 'Pokaż późniejsze wyjścia',
  },
  showFinishedAttendance: {
    id: 'attendanceSettings.showFinishedAttendance',
    defaultMessage: 'Pokaż obecności zakończone',
  },
  showUnfinishedAttendance: {
    id: 'attendanceSettings.showUnfinishedAttendance',
    defaultMessage: 'Pokaż obecności niezakończone',
  },
  showAbsences: {
    id: 'attendanceSettings.showAbsences',
    defaultMessage: 'Pokaż nieobecności',
  },
});

export const ATTENDANCE_WITHOUT_SHIFT = 'attendance_without_shift';
export const ATTENDANCE_ABSENCE = 'absence';
export const ATTENDANCE_NORMAL_START = 'attendance_normal_start';
export const ATTENDANCE_NORMAL_END = 'attendance_normal_end';
export const ATTENDANCE_EARLY_START = 'attendance_early_start';
export const ATTENDANCE_EARLY_QUIT = 'attendance_early_quit';
export const ATTENDANCE_LATE_START = 'attedance_late_start';
export const ATTENDANCE_LATE_QUIT = 'attendance_late_quit';

export const FINISHED_ATTENDANCE = 'finished_attendance';
export const UNFINISHED_ATTENDANCE = 'unfinished_attendance';

export const SHOW_ALL_ATTENDANCE = 'show_all_attendance';

export const ATTENDANCE_SETTINGS_TYPE_ARRAY = [
  ATTENDANCE_WITHOUT_SHIFT,
  ATTENDANCE_LATE_START,
  ATTENDANCE_EARLY_START,
  ATTENDANCE_EARLY_QUIT,
  ATTENDANCE_LATE_QUIT,
  FINISHED_ATTENDANCE,
  UNFINISHED_ATTENDANCE,
];

export const ATTENDANCES_SETTINGS_ARRAY = [
  {
    id: 0,
    value: true,
    type: SHOW_ALL_ATTENDANCE,
    displayText: messages.showAllAttendances,
  },
  {
    id: 1,
    value: true,
    type: ATTENDANCE_WITHOUT_SHIFT,
    displayText: messages.attendanceWithoutShift,
  },
  {
    id: 2,
    value: true,
    type: ATTENDANCE_LATE_START,
    displayText: messages.showLateAttendances,
  },
  {
    id: 3,
    value: true,
    type: ATTENDANCE_EARLY_START,
    displayText: messages.showAttendancesStartedEarly,
  },
  {
    id: 4,
    value: true,
    type: ATTENDANCE_EARLY_QUIT,
    displayText: messages.showAttendancesEndedEarly,
  },
  {
    id: 5,
    value: true,
    type: ATTENDANCE_LATE_QUIT,
    displayText: messages.showAttendancesEndedLate,
  },
  {
    id: 6,
    value: true,
    type: FINISHED_ATTENDANCE,
    displayText: messages.showFinishedAttendance,
  },
  {
    id: 7,
    value: true,
    type: UNFINISHED_ATTENDANCE,
    displayText: messages.showUnfinishedAttendance,
  },
  {
    id: 8,
    value: true,
    type: ATTENDANCE_ABSENCE,
    displayText: messages.showAbsences,
  },
];

export const ATTENDANCES_SETTINGS = ATTENDANCES_SETTINGS_ARRAY.map((setting, index) => ({ ...setting, id: index }));
