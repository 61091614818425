export const mapDataForChart = (rawData, locations) => {
  const data = Object.entries(rawData).map(([date, dataItem]) => {
    const workedHoursSum = Object.values(dataItem.worked_hours_per_location).reduce(
      (sum, duration) => sum + duration,
      0,
    );
    return {
      date,
      ...Object.entries(dataItem.worked_hours_per_location).reduce(
        (result, [locationId, sumDuration]) => ({
          ...result,
          [locationId]: Math.round((sumDuration / workedHoursSum) * 100),
        }),
        {},
      ),
    };
  });

  const metadata = Object.values(rawData)
    .reduce((result, dataItem) => {
      const locationIds = Object.keys(dataItem.worked_hours_per_location);

      return Array.from(new Set([...result, ...locationIds]));
    }, [])
    .map((locationId, index) => {
      const location = locations.find(l => l.id === locationId);

      return {
        key: locationId,
        label: location.name,
        color: location.color,
        yAxisId: 'left',
        chartType: 'bar',
        stackId: 'location',
        order: index,
      };
    });

  return { data, metadata };
};
