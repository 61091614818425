import * as AT from '@/constants/ActionTypes';
import { ADD_MASS_EMPLOYEES, ADD_NEW_EMPLOYEE, DELETE_JOBTITLE, UPDATE_EMPLOYEE } from '@/constants/ActionTypes';
import { adjustContractDates, sortContractsByDates } from '@/utils/contracts.ts';

const initialState = {} as Record<string, any[]>;

const contracts = (state = initialState, action: any) => {
  switch (action.type) {
    case AT.GET_CONTRACTS_SUCCESS: {
      return {
        ...state,
        ...action.payload.contracts,
      };
    }
    case ADD_NEW_EMPLOYEE: {
      return {
        ...state,
        [action.payload.id]: action.payload.contracts,
      };
    }
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        [action.payload.id]: action.payload.contracts,
      };
    case DELETE_JOBTITLE: {
      const jobTitleId = action.payload;

      return Object.entries(state).reduce((acc, [employeeId, contracts]) => {
        const updatedContracts = contracts.map(contract => ({
          ...contract,
          job_titles: contract.job_titles.filter(jobTitle => jobTitle.job_title_id !== jobTitleId),
        }));
        return {
          ...acc,
          [employeeId]: updatedContracts,
        };
      }, {});
    }
    case ADD_MASS_EMPLOYEES: {
      const newEmployees = action.payload;

      const newEntries = newEmployees.reduce((acc, employee) => {
        acc[employee.id] = employee.contracts;
        return acc;
      }, {});
      return {
        ...state,
        ...newEntries,
      };
    }
    case AT.ADD_CONTRACTS_SUCCESS: {
      const newContracts = action.payload.contracts;

      return Object.entries(state).reduce((acc, [employeeId, contracts]) => {
        const newContractsForEmployee = newContracts.filter(contract => contract.employee_id === employeeId);

        const updatedContracts = newContractsForEmployee.length ? newContractsForEmployee : contracts;

        return {
          ...acc,
          [employeeId]: updatedContracts,
        };
      }, {});
    }
    case AT.DELETE_CONTRACTS_SUCCESS: {
      const deletedContractIds = action.payload.ids;

      return Object.entries(state).reduce((acc, [employeeId, contracts]) => {
        const contractToDelete = contracts.find(contract => deletedContractIds.includes(contract.id));

        if (contractToDelete) {
          const updatedContracts = contracts.filter(contract => !deletedContractIds.includes(contract.id));
          const sortedUpdatedContracts = sortContractsByDates(updatedContracts);
          const mappedContracts = adjustContractDates(sortedUpdatedContracts);

          return {
            ...acc,
            [employeeId]: mappedContracts,
          };
        }

        return {
          ...acc,
          [employeeId]: contracts,
        };
      }, {});
    }

    case AT.UPDATE_CONTRACTS_SUCCESS: {
      const contractsToUpdate = action.payload.contracts;

      const updatedContractsMap = contractsToUpdate.reduce((acc, contract) => {
        if (!acc[contract.employee_id]) {
          acc[contract.employee_id] = [];
        }
        acc[contract.employee_id].push(contract);
        return acc;
      }, {});

      return Object.entries(state).reduce((acc, [employeeId, contracts]) => {
        const updatedContractsForEmployee = updatedContractsMap[employeeId];

        if (updatedContractsForEmployee) {
          const employeesEditedContracts = contracts.map(contract => {
            const updatedContract = updatedContractsForEmployee.find(c => c.id === contract.id);
            return updatedContract || contract;
          });

          const additionalContracts = updatedContractsForEmployee.filter(
            contract => !employeesEditedContracts.some(c => c.id === contract.id),
          );

          const allContracts = employeesEditedContracts.concat(additionalContracts);

          acc[employeeId] = adjustContractDates(allContracts);
        } else {
          acc[employeeId] = contracts;
        }

        return acc;
      }, {});
    }

    default:
      return state;
  }
};

export default contracts;
