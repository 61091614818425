import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chartTitle: {
    id: 'plannedAndWorkChart.chartTitle',
    defaultMessage: ' {isEcpPayoutSettingsType,select, true {Porównanie realizacji z planem} other {Plan}}',
  },
  planLineName: {
    id: 'plannedAndWorkChart.planLineName',
    defaultMessage: 'plan',
  },
  workedLineName: {
    id: 'plannedAndWorkChart.workedLineName',
    defaultMessage: 'realizacja',
  },
  tooltipMessage: {
    id: 'plannedAndWorkChart.tooltipMessage',
    defaultMessage:
      'Porównanie realizacji względem grafiku pracy w ujęciu dziennym\nw wyświetlanym okresie wg wybranego parametru.',
  },
});
