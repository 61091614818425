import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import './GoogleButton.scss';

const GoogleButton = ({
  login,
  loginWithGoogleOAuth,
  moveToOnboardingStepTwoRegistrationByOAuth,
}) => {
  const handleClick = useGoogleLogin({
    onSuccess: ({ access_token: accessToken }) =>
      login
        ? loginWithGoogleOAuth(accessToken)
        : moveToOnboardingStepTwoRegistrationByOAuth(accessToken),
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Button
      onClick={() => handleClick()}
      className="k-googleLoginButton"
      modifiers="high uppercase orange-flip"
    >
      <img
        src="/img/google.png"
        alt="google logo"
        className="k-googleLoginButton__googleLogo"
      />
      {login ? (
        <FormattedMessage
          id="loginWithGoogle"
          defaultMessage="Zaloguj się przez Google"
        />
      ) : (
        <FormattedMessage
          id="registerWithGoogle"
          defaultMessage="Zarejestruj się przez Google"
        />
      )}
    </Button>
  );
};

GoogleButton.propTypes = {
  login: PropTypes.bool,
  loginWithGoogleOAuth: PropTypes.func,
  moveToOnboardingStepTwoRegistrationByOAuth: PropTypes.func,
  loginWithGoogleError: PropTypes.func,
};

export default GoogleButton;
