import classnames from 'classnames';
import { isEmpty, isEqual, uniq } from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { ATTENDANCE_LATE_COLOR, ATTENDANCE_SUCCESS_COLOR, KADRO_BLUE_COLOR } from '@/constants/colors.js';
import { breakSettingTypes } from '@/constants/payrollSettings';

import { checkIfShiftIncludesAbsence } from './absenceHelpers.js';
import { isNumeric, roundToTwoSigDigits } from './baseHelpers.js';
import {
  calculateDurationBetweenTimestamps,
  calculateDurationBetweenTimestampsForPayroll,
  calculateDurationBetweenTimestampsWithFractions,
  calculateDurationMinutes,
  convertDateToHumanTextShortcut,
  parseMinutesToFormat,
  workingHoursToTimestamp,
} from './dateHelper.js';
import { checkIfOverlap } from './schedulerHelpers.js';

const WORKING_DAYS_IN_WEEK = 5;

const getDailyWorkingMinutes = employmentConditions =>
  employmentConditions.weekly_working_minutes / WORKING_DAYS_IN_WEEK;

export const getDefaultAbsenceTime = (countOnlyDaysWithShifts, employmentConditions) =>
  countOnlyDaysWithShifts || isEmpty(employmentConditions) ? 0 : getDailyWorkingMinutes(employmentConditions);

// UTILS -----------------------------------------------------------------------

const getRowHours = (start, end, absenceHours) => {
  if (!absenceHours) {
    return `${start}-${end}`;
  }
  const [absenceStart, absenceEnd] = absenceHours.split('-');
  const rowStart = start < absenceStart ? start : absenceStart;
  const rowEnd = end > absenceEnd ? end : absenceEnd;

  return `${rowStart}-${rowEnd}`;
};

const getAbsenceWithoutShiftDetailRow = (absence, date, employmentConditions) => ({
  absence,
  overtime: {},
  timeWorked: absence.is_paid
    ? calculateDurationMinutes(absence.absence_hours) ||
      getDefaultAbsenceTime(absence.count_only_days_with_shifts, employmentConditions)
    : 0,
  timeWorkedForXls: 0,
  isAbsence: true,
  absence_short_name: absence.short_name,
  absence_time: absence.absence_hours
    ? calculateDurationMinutes(absence.absence_hours) / 60
    : getDefaultAbsenceTime(absence.count_only_days_with_shifts, employmentConditions) / 60,
  date,
  hours: absence.absence_hours,
});

const getAbsenceTimeInDay = (absence, shifts, employmentConditions) => {
  if (!absence) {
    return null;
  }

  if (absence.absence_hours) {
    return calculateDurationMinutes(absence.absence_hours) / 60;
  }

  if (absence.count_only_days_with_shifts) {
    return shifts.reduce((sum, shift) => sum + (shift.duration || 0), 0) / 60;
  }

  return getDefaultAbsenceTime(absence.count_only_days_with_shifts, employmentConditions) / 60;
};

const getShiftsWithAbsencesRows = (shifts, relevantAbsence) =>
  shifts.map(detail => ({
    ...detail,
    startForXls: null,
    endForXls: null,
    sumPaid: null,
    absence: relevantAbsence,
    absence_short_name: relevantAbsence ? relevantAbsence.short_name : null,
    absence_time: relevantAbsence
      ? calculateDurationMinutes(relevantAbsence.absence_hours || detail.working_hours) / 60
      : null,
    timeWorked: relevantAbsence.is_paid ? detail.timeWorked : 0,
  }));

/* eslint-disable react/jsx-filename-extension */
export const displayPlannedDiff = (totalHours, totalHoursPlanned, timeFormatSetting) => {
  const diff = totalHours - totalHoursPlanned;
  const formattedDiff = parseMinutesToFormat(diff, timeFormatSetting);
  const className = classnames({
    'k-payroll__plannedHours--red': diff > 0,
    'k-payroll__plannedHours--green': diff < 0,
  });

  return <span className={className}>{formattedDiff}</span>;
};
/* eslint-enable */

/**
 * This returns the matching location name for ID
 * @param {string} id - location id
 * @param {Array} userLocations - locations to pick from
 * @param {string} locationNotFoundText  - text to return when no location found
 * default value = 'Brak lokalizacji'
 * @returns {string} name of location or message about no location
 */
export const getLocationNameForId = (id, userLocations, locationNotFoundText = null, locationName) => {
  if (userLocations && userLocations.find) {
    const foundLocation = userLocations.find(l => l.id === id);
    if (foundLocation) {
      return foundLocation.name;
    }
  } else {
    console.warn('userLocations is not an array. Check input of getLocationNameForId function.');
  }

  const notFoundText =
    locationNotFoundText === null ? (
      <FormattedMessage id="location.locationNotFoundText" defaultMessage="Brak lokalizacji" />
    ) : (
      locationNotFoundText
    );

  return locationName || notFoundText;
};

/**
 * This returns the matching individual wage (in 1/100ths)
 * @param {Object} attendance - The attendance object
 * @param {Object} employee - The employee object
 * @returns {number} individual wage * 100 - if its 15/h then it returns 1500
 */
export const getMatchingIndividualWage = (attendance, employee) => {
  const foundJob = employee.terms.find(e => e.job_title.id === attendance.matching_shift_job_title.id);
  if (foundJob && foundJob.has_individual_hourly_wage) {
    return foundJob.individual_hourly_wage ? foundJob.individual_hourly_wage : 0;
  }
  return attendance.matching_shift_job_title.hourly_wage;
};

/**
 * This returns the matching individual wage (in 1/100ths) for shift
 * @param {Object} shift - the shift object
 * @param {Object} employee - The employee object
 * @returns {number} individual wage * 100 - if its 15/h then it returns 1500
 */
export const getMatchingIndividualWageForShift = (shift, employee) => {
  const foundJob = employee.terms.find(e => e.job_title.id === shift.job_title.id);
  if (foundJob && foundJob.has_individual_hourly_wage) {
    return foundJob.individual_hourly_wage;
  }
  if (!foundJob) {
    return shift.job_title.hourly_wage;
  }
  return foundJob.job_title.hourly_wage;
};

/**
 * This funciton filters attendances by location and date
 * @param {Array} attendances - array of attendances
 * @param {Object} dateStore - date store object with date array
 * @param {Array} locations - array of locations
 * @returns {Array} filtered array of attendances
 */
export const filterAttendances = (attendances, dateStore, locationIds, jobTitlesIds = null) => {
  if (!attendances) return [];
  return attendances.filter(
    attendance =>
      dateStore.dateArray.includes(attendance.start_timestamp.split(' ')[0]) &&
      locationIds.includes(attendance.location.id) &&
      (!jobTitlesIds ||
        !attendance.matching_shift_job_title ||
        (jobTitlesIds && jobTitlesIds.includes(attendance.matching_shift_job_title.id))),
  );
};

/**
 * This funciton filters availabilities by date
 * @param {Array} availabilities - array of availabilities
 * @param {Object} dateStore - date store object with date array
 * @returns {Array} filtered array of availabilities
 */
export const filterAvailabilities = (availabilities, dateStore) => {
  if (!availabilities) return [];
  return availabilities.filter(availability => dateStore.dateArray.includes(availability.date));
};

/**
 * This funciton filters shifts by date, location and draft status
 * @param {Array} shifts - array of shifts
 * @param {Object} dateStore - date store object with date array
 * @param {Array} locations - array of locations
 * @returns {Array} filtered array of shifts wihout drafts
 */
export const filterShifts = (shifts, dateStore, selectedLocationIds, selectedJobtitles = null) => {
  if (!shifts || !dateStore || !selectedLocationIds) return [];
  const jobTitleIds = selectedJobtitles ? selectedJobtitles.map(j => j.id) : null;
  return shifts.filter(
    shift =>
      shift.draft === false &&
      dateStore.dateArray.includes(shift.date) &&
      selectedLocationIds.includes(shift.location.id) &&
      (jobTitleIds === null || jobTitleIds.includes(shift.job_title.id)),
  );
};

// UTILS - END -----------------------------------------------------------------

// MAIN FUNCTIONS --------------------------------------------------------------

/**
 * This function calculates time worked during night hours
 * @param {string} start - start timestamp
 * @param {string} end - end timestamp
 * @param {string} date - chosen date
 * @param {Object} payrollSettings - uses nighthours setting
 * @returns {number} minutes worked during night hours
 */

export const countNightHours = (start, end, payrollSettings) => {
  // ---- Night hours --------------------------------------------------------

  // Możliwe przypadki ( godziny nocne np. 22:00 - 06:00)
  //                                             <------------(6)---------->
  //                                                     <--------(5)------>
  //       <------ (1) ------->               <------- (3) ----->
  //     <--(2)-->                                       <----(4)---->
  //   0            6                                 22    0           6
  // **|************|----------------------------------|****|***********|-->
  // 13                      14.04                              15.04

  // Case 1 - starts during night hours and ends after
  // Case 2 - starts and ends during night hours - this day
  // Case 3 - starts during normal ours and ends in night hours
  // Case 4 - like case 2 but in 2 area
  // Case 5 - starts during night hours and ends after the next day
  // Case 6 - start and ends in normal hours - but night hours inside
  // Case 7 - starts on first night and ends on the other => case 1 + 3

  const date = start.split(' ')[0];
  let nightTimeWorked = 0;
  if (start && end && payrollSettings.nighthours) {
    let nightStart = payrollSettings.nighthours.split('-')[0];
    if (nightStart === '00:00') nightStart = '24:00';
    const nightEnd = payrollSettings.nighthours.split('-')[1];
    const secondNightStart = moment(`${date} ${nightStart}`);
    const secondNightEnd = moment(`${date} ${nightEnd}`);
    if (nightEnd < nightStart) {
      secondNightEnd.add(1, 'day');
    }

    const firstNightStart = secondNightStart.clone().add(-1, 'day');
    const firstNightEnd = secondNightEnd.clone().add(-1, 'day');

    const startHour = moment(start);
    const endHour = moment(end);

    let nightSpanStart;
    let nightSpanEnd;

    if (startHour.isSameOrAfter(firstNightStart) && startHour.isSameOrBefore(firstNightEnd)) {
      // Cases 1 and 2
      nightSpanStart = startHour;
      if (endHour.isSameOrBefore(firstNightEnd)) {
        // Case 2
        nightSpanEnd = endHour;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      } else {
        // Case 1
        nightSpanEnd = firstNightEnd;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      }
    }

    if (startHour.isBefore(secondNightStart)) {
      // Case 3 and 6
      nightSpanStart = secondNightStart;
      if (endHour.isSameOrBefore(secondNightEnd) && endHour.isAfter(secondNightStart)) {
        // Case 3
        nightSpanEnd = endHour;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      } else if (endHour.isAfter(secondNightEnd)) {
        // Case 6
        nightSpanEnd = secondNightEnd;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      }
    }

    if (startHour.isSameOrAfter(secondNightStart)) {
      // Cases 4 and 5
      nightSpanStart = startHour;
      if (endHour.isBefore(secondNightEnd)) {
        // Case 4
        nightSpanEnd = endHour;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      } else {
        // Case 5
        nightSpanEnd = secondNightEnd;
        nightTimeWorked += calculateDurationBetweenTimestamps(nightSpanEnd, nightSpanStart);
      }
    }
  }
  return nightTimeWorked;
  // -------------------------------------------------------------------------
};

/**
 * This function applyies rounding settings to timestamp
 * @param {string} hours - timestamp to round in format YYYY-MM-DD HH:mm
 * @param {Object} settings - payroll settings object. We use start/endRoundingType and roundingSetting
 * @param {string} t - type of the timestamp. 'start' or 'end'
 * @returns {string} rounded timestamp
 */
export const calculatePayrollHour = (hour, settings, t) => {
  if (!hour) return hour;
  let setting;
  let type;
  if (t === 'start') {
    setting = settings.startRoundingSetting;
    type = settings.startRoundingType;
  } else {
    setting = settings.endRoundingSetting;
    type = settings.endRoundingType;
  }

  let minutes = parseInt(hour.split(':')[0].slice(-2)) * 60 + parseInt(hour.split(':')[1]);
  let reminder = minutes % setting.roundNum;
  const rest = minutes - reminder;
  if (reminder > 0) {
    switch (type.type) {
      case 'math': {
        if (reminder >= setting.roundNum / 2) {
          reminder = setting.roundNum;
        } else {
          reminder = 0;
        }
        break;
      }
      case 'up': {
        reminder = setting.roundNum;
        break;
      }
      case 'down': {
        reminder = 0;
        break;
      }
      default:
        break;
    }
  }
  minutes = rest + reminder;
  const fullHours = `0${Math.floor(minutes / 60)}`.slice(-2);
  const parsedMinutes = `0${minutes % 60}`.slice(-2);
  return `${hour.slice(0, 11)}${fullHours}:${parsedMinutes}:00`;
};

const hourPlaceholder = '__:__';

export const getPayrollHours = hours => {
  const splittedHours = hours.split('-');

  return splittedHours
    .map(hour => {
      if (!hour) {
        return hourPlaceholder;
      }

      return hour === '24:00' ? '00:00' : hour;
    })
    .join(' - ');
};

/**
 * This function uses payroll setting to determine we count early starts and late leaves or not
 * @param {string} attendanceTimestamp - timestamp of the attendance
 * @param {string} shiftTimestamp - timestamp of the shift
 * @param {string} type - 'start' or 'end'
 * @param {Object} payrollSettings - settings object. Here we use startTolerance and endTolerance props
 * @returns {string} eather attendanceTimestamp or shiftTimestamp depending on the times and settings
 */
export const applyTolerance = (
  attendanceTimestamp,
  shiftTimestamp,
  type,
  payrollSettings,
  isAttendanceAccepted = false,
) => {
  const diff = calculateDurationBetweenTimestampsWithFractions(attendanceTimestamp, shiftTimestamp);
  if (type === 'start') {
    if (diff < 0 && diff * -1 <= parseInt(payrollSettings.startTolerance) && !isAttendanceAccepted) {
      return shiftTimestamp;
    }
    return attendanceTimestamp;
  }
  if (diff > 0 && diff <= parseInt(payrollSettings.endTolerance) && !isAttendanceAccepted) {
    return shiftTimestamp;
  }
  return attendanceTimestamp;
};

/**
 * This function returns colors used to show if employee was late, early or on time.
 * @param {string} attendanceStart - attendance start timestamp
 * @param {string} attendanceEnd - attendance end timestamp
 * @param {string} shiftStart - shift start timestamp
 * @param {string} shiftEnd - shift end timestamp
 * @returns {Object} object with startColor and endColor props
 */
export const getColorForHours = (attendanceStart, attendanceEnd, shiftStart, shiftEnd) => {
  let startColor;
  let endColor;
  if (shiftStart > attendanceStart) {
    startColor = KADRO_BLUE_COLOR;
  } else if (shiftStart < attendanceStart) {
    startColor = ATTENDANCE_LATE_COLOR;
  } else {
    startColor = ATTENDANCE_SUCCESS_COLOR;
  }

  if (shiftEnd < attendanceEnd) {
    endColor = KADRO_BLUE_COLOR;
  } else if (shiftEnd > attendanceEnd) {
    endColor = ATTENDANCE_LATE_COLOR;
  } else {
    endColor = ATTENDANCE_SUCCESS_COLOR;
  }
  return { startColor, endColor };
};

/**
 * This function takes row of attendance data and adds colors to itselfe and details
 * @param {Object} row - object with array of details and summary props
 * @returns {Object} row with colors
 */

export const getHoursAndColorsForPayrollRow = (row, employee) => {
  let rowAttendanceStart = '__:__';
  let rowAttendanceEnd = '00:00';
  let rowShiftStart = '__:__';
  let rowShiftEnd = '00:00';
  let earlyIn = false;
  let lateOut = false;

  const newDetails = row.details.map(detail => {
    const attendanceStart = detail.start_timestamp ? detail.start_timestamp.split(' ')[1].slice(0, 5) : '__:__';
    const attendanceEnd = detail.end_timestamp ? detail.end_timestamp.split(' ')[1].slice(0, 5) : '__:__';
    let shiftStart = '__:__';
    let shiftEnd = '__:__';

    if (detail.matching_shift) {
      let shift = detail.matching_shift;
      if (employee && employee.shifts) {
        shift = employee.shifts.find(s => s.id === detail.matching_shift.id) || detail.matching_shift;
      }
      shiftStart = shift.start_timestamp.split(' ')[1].slice(0, 5);
      shiftEnd = shift.end_timestamp.split(' ')[1].slice(0, 5);
    }
    let { startColor, endColor } = getColorForHours(attendanceStart, attendanceEnd, shiftStart, shiftEnd);
    if (!detail.matching_shift) {
      startColor = 'black';
      endColor = 'black';
    }

    if (attendanceStart !== '__:__' && attendanceStart < rowAttendanceStart) {
      rowAttendanceStart = attendanceStart;
      earlyIn = detail.early_in;
    }
    if (attendanceEnd !== '__:__' && attendanceEnd > rowAttendanceEnd) {
      rowAttendanceEnd = attendanceEnd;
      lateOut = detail.late_out;
    }
    if (shiftStart < rowShiftStart) rowShiftStart = shiftStart;
    if (shiftEnd !== '__:__' && shiftEnd > rowShiftEnd) rowShiftEnd = shiftEnd;

    return {
      ...detail,
      attendanceStart,
      attendanceEnd,
      shiftStart,
      shiftEnd,
      startColor,
      endColor,
      disableBonus: detail.hasOwnProperty('disableBonus') ? detail.disableBonus : row.disableBonus,
    };
  });

  if (!row.details.length) {
    rowShiftStart = row.shiftStart;
    rowShiftEnd = row.shiftEnd;
  }

  if (row.details.length === 1) {
    rowAttendanceEnd = row.details[0].end_timestamp ? row.details[0].end_timestamp.split(' ')[1].slice(0, 5) : '__:__';
    rowShiftEnd = row.details[0].matching_shift?.end_timestamp.split(' ')[1].slice(0, 5) || '__:__';
  }

  const { startColor, endColor } = getColorForHours(rowAttendanceStart, rowAttendanceEnd, rowShiftStart, rowShiftEnd);
  return {
    ...row,
    details: newDetails,
    displayHours: {
      attendanceStart: rowAttendanceStart,
      attendanceEnd: rowAttendanceEnd,
      shiftStart: rowShiftStart,
      shiftEnd: rowShiftEnd,
      startColor,
      endColor,
      early_in: earlyIn,
      late_out: lateOut,
    },
  };
};

export const mapOvertimeCollectionIntoRow = absence => ({
  ...absence,
  date: moment.utc(absence.start_timestamp).format('YYYY-MM-DD'),
  start: moment.utc(absence.start_timestamp).format('YYYY-MM-DD HH:mm:ss'),
  end: moment.utc(absence.end_timestamp).format('YYYY-MM-DD HH:mm:ss'),
  start_timestamp: moment.utc(absence.start_timestamp).format('YYYY-MM-DD HH:mm:ss'),
  end_timestamp: moment.utc(absence.end_timestamp).format('YYYY-MM-DD HH:mm:ss'),
  isOvertimeCollection: true,
  disableBonus: true,
});

export const getSumOvertime = (overtime, sumOfOvertimeCollections) => {
  if (!overtime) {
    return {
      sumOvertime50: 0,
      sumOvertime100: 0,
      subtractedTime: 0,
    };
  }
  const { overtime50, overtime100 } = overtime;

  if (overtime100 >= sumOfOvertimeCollections) {
    return {
      sumOvertime50: overtime50,
      sumOvertime100: overtime100 - sumOfOvertimeCollections,
      subtractedTime: sumOfOvertimeCollections,
    };
  }

  const diff = sumOfOvertimeCollections - overtime100;

  return {
    sumOvertime50: Math.max(overtime50 - diff, 0),
    sumOvertime100: 0,
    subtractedTime: overtime50 > diff ? overtime100 + diff : overtime100 + overtime50,
  };
};

const getSumDurationOfAttendanceOverlappedAbsences = (absenceForAttendance, attendance) => {
  if (!absenceForAttendance) {
    return 0;
  }

  if (!absenceForAttendance?.absence_hours) {
    return attendance.duration;
  }

  const { start_timestamp: absenceStartTimestamp, end_timestamp: absenceEndTimestamp } = workingHoursToTimestamp(
    absenceForAttendance.absence_hours,
    absenceForAttendance.from,
  );

  const isAttendanceOverlappingAbsence =
    absenceForAttendance &&
    (!absenceForAttendance.absence_hours ||
      (moment(attendance.startAtt).isBefore(absenceEndTimestamp) &&
        moment(attendance.endAtt).isAfter(absenceStartTimestamp)));

  if (isAttendanceOverlappingAbsence) {
    const notOverlappedDurationStart = moment(attendance.startAtt).isBefore(absenceStartTimestamp)
      ? moment(attendance.startAtt).diff(absenceStartTimestamp, 'minutes')
      : 0;
    const notOverlappedDurationEnd = moment(attendance.endAtt).isAfter(absenceEndTimestamp)
      ? moment(attendance.endAtt).diff(absenceEndTimestamp, 'minutes')
      : 0;
    const overlappedDuration = attendance.duration - notOverlappedDurationStart - notOverlappedDurationEnd;

    return overlappedDuration;
  }
};

/**
 * This function filters details for payroll location and creates summary data
 */

export const getRowsSummary = (
  relevantAttendancesDetails,
  relevantAvailabilitiesDetails,
  relevantShiftsDetails,
  employmentConditions,
  overtime = {},
  relevantAvailabilities = [],
  relevantOvertimeCollections = [],
  relevantAbsences = [],
  datesRange,
) => {
  const formattedOvertimeCollections = relevantOvertimeCollections.map(mapOvertimeCollectionIntoRow);
  const allShiftsDates = relevantShiftsDetails.map(shift => moment(shift.startShift).format('YYYY-MM-DD'));

  const absencesRows = relevantAbsences.flatMap(absence => {
    const filteredDateArray = absence.dateArray.filter(date => date >= datesRange.start && date <= datesRange.end);

    return filteredDateArray.map(date => ({
      date,
      ...absence,
      dateArray: [date],
    }));
  });

  const absencesRowsWithoutShift = absencesRows.filter(absence => {
    if (absence.absence_hours) {
      return true;
    }
    return !allShiftsDates.includes(absence.date) || !absence.count_only_days_with_shifts;
  });

  const relevantDetails = [
    ...relevantAttendancesDetails,
    ...relevantAvailabilitiesDetails,
    ...formattedOvertimeCollections,
    ...absencesRowsWithoutShift,
  ];

  const relevantRows = relevantDetails.filter(r => isNumeric(r.timeWorked));
  const acceptedAvailabilities = relevantAvailabilities.filter(ava => !ava.draft);
  // As we want to display planned hours we need to add rows with empty timeWorked for planned shift but no attendance
  const relevantShiftsRows = relevantShiftsDetails
    .filter(
      shift =>
        (!relevantRows.find(row => row.date === shift.date) &&
          absencesRows.every(absence => shift.date !== absence.date)) ||
        absencesRows.some(absence => shift.date === absence.date && !absence.absence_hours),
    )
    .map(shift => ({
      ...shift,
      timeWorked: 0,
      sumPaid: 0,
    }));

  const details = [...relevantDetails, ...relevantShiftsRows].map((detail, idx, detailsRawArray) => {
    const isShift = Boolean(detail.startShift);
    const isAttendance = Boolean(detail.startAtt);

    const relevantAbsence = detail.isAbsence
      ? detail
      : isShift &&
        absencesRows.find(absence => {
          if (absence.absence_hours) {
            const { startShift, endShift } = detail;
            return checkIfShiftIncludesAbsence({ startShift, endShift }, absence);
          }

          return moment(detail.startShift).isSame(absence.date, 'days') && absence.count_only_days_with_shifts;
        });

    let timeWorked = relevantAbsence ? detail.duration : detail.timeWorked;

    if (detail.isAbsence) {
      timeWorked = detail.absence_hours
        ? calculateDurationMinutes(detail.absence_hours)
        : getDefaultAbsenceTime(detail.count_only_days_with_shifts, employmentConditions);
    }

    if (isShift && relevantAbsence?.absence_hours) {
      timeWorked = calculateDurationMinutes(relevantAbsence.absence_hours);
    }

    if (relevantAbsence && !relevantAbsence.is_paid) {
      timeWorked = 0;
    }

    const absenceForAttendance =
      isAttendance &&
      absencesRows.find(absence => {
        const { start_timestamp: absenceStartTimestamp, end_timestamp: absenceEndTimestamp } = absence?.absence_hours
          ? workingHoursToTimestamp(absence.absence_hours, absence.from)
          : {};
        return (
          absence.date === detail.date &&
          (!absence.absence_hours ||
            (moment(detail.startAtt).isBefore(absenceEndTimestamp) &&
              moment(detail.endAtt).isAfter(absenceStartTimestamp)))
        );
      });

    const sumDurationOfAttendanceOverlappedAbsences = getSumDurationOfAttendanceOverlappedAbsences(
      absenceForAttendance,
      detail,
    );

    const warningTypes = getWarningTypesForDetail(
      detail,
      detailsRawArray.filter(d => Boolean(d.startAtt)),
      absencesRows,
    );
    return {
      ...detail,
      overtime: overtime[detail.date] || { overtime50: 0, overtime100: 0 },
      availability: acceptedAvailabilities.find(ava => ava.date === detail.date) || '',
      sumPaid: !relevantAbsence || relevantAbsence.is_paid ? roundToTwoSigDigits(detail.sumPaid || 0) : 0,
      start_timestamp: detail.isAbsence ? detail.date : detail.start_timestamp,
      absence: relevantAbsence,
      absence_short_name: relevantAbsence?.short_name,
      absence_time: relevantAbsence
        ? getAbsenceTimeInDay(relevantAbsence, detail.isAbsence ? [] : [detail], employmentConditions)
        : undefined,
      timeWorked,
      timeWorkedForXls: relevantAbsence ? 0 : timeWorked,
      endForXls: relevantAbsence ? null : undefined,
      startForXls: relevantAbsence ? null : undefined,
      sumDurationOfAttendanceOverlappedAbsences,
      warningTypes,
    };
  });

  const relevantOvertimes = Object.keys(overtime)
    .filter(date => details.some(detail => detail.date === date))
    .reduce(
      (result, date) => ({
        ...result,
        [date]: overtime[date],
      }),
      {},
    );

  const overtimeObject = Object.values(relevantOvertimes).reduce(
    (result, overtimeEntry) => ({
      overtime50: result.overtime50 + overtimeEntry.overtime50,
      overtime100: result.overtime100 + overtimeEntry.overtime100,
    }),
    { overtime50: 0, overtime100: 0 },
  );
  const sumOfOvertimeCollections = relevantOvertimeCollections.reduce((sum, collection) => {
    const time = moment(collection.end_timestamp).diff(collection.start_timestamp, 'minutes');

    return sum + time;
  }, 0);
  const { sumOvertime50, sumOvertime100 } = getSumOvertime(overtimeObject, sumOfOvertimeCollections);

  const sumHours = details.reduce((prev, row) => prev + (row.timeWorked || 0), 0);
  const sumHoursReal = relevantAttendancesDetails.reduce((prev, detail) => prev + (detail.timeWorked || 0), 0);
  const sumHoursPlanned = relevantShiftsDetails.reduce((prev, detail) => prev + (detail.timeWorked || 0), 0);
  const sumNightHours = relevantRows.reduce((prev, row) => prev + row.nightTimeWorked || 0, 0);
  const sumBonuses = relevantRows.reduce((prev, row) => prev + row.bonus_amount || 0, 0);
  const sumPayout = roundToTwoSigDigits(relevantRows.reduce((prev, row) => prev + row.sumPaid || 0, 0));
  const sumAvailabilities = acceptedAvailabilities.reduce(
    (sumedAvailabilities, ava) =>
      sumedAvailabilities[ava.type_name]
        ? { ...sumedAvailabilities, [ava.type_name]: sumedAvailabilities[ava.type_name] + 1 }
        : { ...sumedAvailabilities, [ava.type_name]: 1 },
    {},
  );
  const sumAbsences = details.reduce((sum, detail) => sum + (detail.absence_time * 60 || 0), 0);
  const numberOfWarnings = details.reduce((sum, detail) => sum + (detail.warningTypes?.length ? 1 : 0), 0);
  const absenceTimes = absencesRows.reduce((sum, absence) => {
    if (absence.absence_hours) {
      return sum + calculateDurationMinutes(absence.absence_hours);
    }

    const relevantShifts = relevantShiftsDetails.filter(
      shift => moment(shift.startShift).format('YYYY-MM-DD') === absence.date,
    );

    if (relevantShifts.length > 0) {
      return sum + relevantShifts.reduce((s, shift) => s + shift.duration, 0);
    }

    return sum + getDefaultAbsenceTime(absence.count_only_days_with_shifts, employmentConditions);
  }, 0);
  const sumBreaks = details.reduce((sum, detail) => sum + (detail.sumBreaks || 0), 0);

  return {
    details,
    sumBreaks,
    sumHours,
    sumHoursReal,
    sumHoursPlanned: sumHoursPlanned - absenceTimes,
    sumNightHours,
    sumBonuses,
    sumPayout,
    sumOvertime50,
    sumOvertime100,
    sumAvailabilities,
    sumAbsences,
    numberOfWarnings,
  };
};

export const getShiftRowsSummary = (
  relevantShiftDetails,
  relevantAvailabilitiesDetails,
  employmentConditions,
  overtime = {},
  relevantAvailabilities = [],
  relevantOvertimeCollections = [],
  relevantAbsences = [],
  datesRange,
) => {
  const acceptedAvailabilities = relevantAvailabilities.filter(ava => !ava.draft);
  const formattedOvertimeCollections = relevantOvertimeCollections.map(mapOvertimeCollectionIntoRow);
  const allShiftsDates = relevantShiftDetails.map(shift => moment(shift.startShift).format('YYYY-MM-DD'));
  const absencesRows = relevantAbsences.flatMap(absence => {
    const filteredDateArray = absence.dateArray.filter(date => date >= datesRange.start && date <= datesRange.end);

    return filteredDateArray.map(date => ({
      date,
      ...absence,
      dateArray: [date],
    }));
  });

  const absencesRowsWithoutShift = absencesRows.filter(absence => {
    if (absence.absence_hours) {
      const shifts = relevantShiftDetails.filter(shift => moment(shift.startShift).isSame(absence.date, 'days'));
      if (shifts.length === 0) {
        return true;
      }

      return shifts.every(shift => {
        const { startShift, endShift } = shift;
        return !checkIfShiftIncludesAbsence({ startShift, endShift }, absence);
      });
    }
    return !allShiftsDates.includes(absence.date) || !absence.count_only_days_with_shifts;
  });

  const relevantDetails = [
    ...relevantShiftDetails,
    ...relevantAvailabilitiesDetails,
    ...formattedOvertimeCollections,
    ...absencesRowsWithoutShift,
  ].map(detail => {
    const isShift = Boolean(detail.startShift);
    const relevantAbsence = detail.isAbsence
      ? detail
      : isShift &&
        absencesRows.find(absence => {
          if (absence.absence_hours) {
            const { startShift, endShift } = detail;
            return checkIfShiftIncludesAbsence({ startShift, endShift }, absence);
          }

          return moment(detail.startShift).isSame(absence.date, 'days') && absence.count_only_days_with_shifts;
        });

    let timeWorked = detail.isAbsence
      ? calculateDurationMinutes(detail.absence_hours) ||
        getDefaultAbsenceTime(detail.count_only_days_with_shifts, employmentConditions)
      : detail.timeWorked;

    if (relevantAbsence && !relevantAbsence.is_paid) {
      timeWorked = 0;
    }

    return {
      ...detail,
      availability: acceptedAvailabilities.find(ava => ava.date === detail.date) || '',
      overtime: overtime[detail.date] || { overtime50: 0, overtime100: 0 },
      absenceType: detail.type, // TODO: this absenceType property is for overtimeCollection only
      absence: relevantAbsence,
      absence_short_name: relevantAbsence?.short_name,
      absence_time: relevantAbsence
        ? getAbsenceTimeInDay(relevantAbsence, detail.isAbsence ? [] : [detail], employmentConditions)
        : undefined,
      timeWorked,
      start_timestamp: detail.isAbsence ? detail.date : detail.start_timestamp,
      sumPaid: !relevantAbsence || relevantAbsence.is_paid ? detail.sumPaid : 0,
    };
  });
  const relevantOvertimes = Object.keys(overtime)
    .filter(date => relevantDetails.some(detail => detail.date === date))
    .reduce(
      (result, date) => ({
        ...result,
        [date]: overtime[date],
      }),
      {},
    );

  const overtimeObject = Object.values(relevantOvertimes).reduce(
    (result, overtimeEntry) => ({
      overtime50: result.overtime50 + overtimeEntry.overtime50,
      overtime100: result.overtime100 + overtimeEntry.overtime100,
    }),
    { overtime50: 0, overtime100: 0 },
  );
  const sumOfOvertimeCollections = relevantOvertimeCollections
    .filter(absence => absence.type === 'overtime_collection')
    .reduce((sum, collection) => {
      const time = moment(collection.end_timestamp).diff(collection.start_timestamp, 'minutes');

      return sum + time;
    }, 0);
  const { sumOvertime50, sumOvertime100 } = getSumOvertime(overtimeObject, sumOfOvertimeCollections);

  const sumHours = relevantDetails.reduce((prev, detail) => prev + (detail.timeWorked || 0), 0);
  const sumHoursReal = relevantShiftDetails.reduce((prev, detail) => prev + (detail.timeWorked || 0), 0);
  const sumHoursPlanned = sumHours;
  const sumNightHours = relevantDetails.reduce((prev, detail) => prev + (detail.nightTimeWorked || 0), 0);
  const sumBonuses = relevantDetails.reduce((prev, detail) => prev + (detail.bonus_amount || 0), 0);
  const sumPayout = roundToTwoSigDigits(relevantDetails.reduce((prev, detail) => prev + (detail.sumPaid || 0), 0));
  const sumAvailabilities = acceptedAvailabilities.reduce(
    (sumedAvailabilities, ava) =>
      sumedAvailabilities[ava.type_name]
        ? { ...sumedAvailabilities, [ava.type_name]: sumedAvailabilities[ava.type_name] + 1 }
        : { ...sumedAvailabilities, [ava.type_name]: 1 },
    {},
  );
  const sumAbsences = relevantDetails.reduce((sum, detail) => sum + (detail.absence_time * 60 || 0), 0);
  const sumBreaks = relevantDetails.reduce((sum, detail) => sum + (detail.sumBreaks || 0), 0);

  return {
    details: relevantDetails,
    sumHours,
    sumHoursReal,
    sumHoursPlanned,
    sumNightHours,
    sumBonuses,
    sumPayout,
    sumOvertime50,
    sumOvertime100,
    sumAvailabilities,
    sumAbsences,
    sumBreaks,
  };
};

/**
 * This function formats shifts into detail objects by adding new props
 * @param {Array} relevantShifts - filtered shifts
 * @param {Object} employee - employee object
 * @param {Object} payrollSettings - payrollSettings (used for rounding and night hours)
 * @returns {Array} details - array of details for payroll table
 */
export const getRelevantShiftsDetails = (relevantShifts, employee, payrollSettings) =>
  relevantShifts.map(shift => {
    const wage = getMatchingIndividualWageForShift(shift, employee) / 100;
    const timeWorked = calculateDurationBetweenTimestampsForPayroll(
      shift.end_timestamp,
      shift.start_timestamp,
      payrollSettings.roundingSetting,
    );
    const sumPaid = roundToTwoSigDigits((wage * timeWorked) / 60);

    const nightTimeWorked =
      payrollSettings.payoutSetting.type !== 'shifts'
        ? 0
        : countNightHours(shift.start_timestamp, shift.end_timestamp, payrollSettings);

    const start = payrollSettings.payoutSetting.type !== 'shifts' ? null : shift.start_timestamp;
    const end = payrollSettings.payoutSetting.type !== 'shifts' ? null : shift.end_timestamp;

    return {
      ...shift,
      start,
      end,
      startShift: shift.start_timestamp,
      endShift: shift.end_timestamp,
      wage,
      jobTitle: wage !== null ? shift.job_title.title : `${shift.job_title.title}- usunięte stanowisko`,
      sumPaid,
      timeWorked,
      timePlanned: timeWorked,
      nightTimeWorked,
      sumQuotas: 0,
      bonus_amount: 0,
      disableBonus: true,
      errorHighlight: wage === null,
    };
  });

/**
 * This function maps availabilities to detail objects
 * @param {Array} relevantAvailabilities - list of employees availabilities in given time span
 * @returns {Array} detail objects
 */
export const getRelevantAvailabilitiesDetails = (relevantAvailabilities, addTimestamps = false) =>
  relevantAvailabilities
    .filter(availability => availability.count_hours_in_payroll)
    .map(availability => {
      let start = '',
        end = '',
        startTimestamp = '',
        endTimestamp = '';
      if (addTimestamps) {
        let startHour = '';
        let endHour = '';
        if (availability.hours && availability.requires_time) {
          startHour = availability.hours.split('-')[0];
          endHour = availability.hours.split('-')[1];
        } else {
          startHour = '__:__';
          endHour = '__:__';
        }
        startTimestamp = `${availability.date} ${startHour}`;
        endTimestamp = `${availability.date} ${endHour}`;
        start = startTimestamp;
        end = endTimestamp;
      }

      return {
        id: availability.id,
        date: availability.date,
        wage: 0,
        jobTitle: availability.type_name,
        sumPaid: 0,
        timeWorked: availability.count_hours * 60,
        nightTimeWorked: 0,
        sumQuotas: 0,
        disableBonus: true,
        errorHighlight: false,
        start,
        end,
        start_timestamp: startTimestamp,
        end_timestamp: endTimestamp,
        location: {
          id: null,
        },
        bonus_amount: 0,
        isAvailability: true,
      };
    });

const getAdditionalAbsenceRow = (relevantAbsence, date, relevantDateRows, employmentConditions) =>
  !relevantAbsence ||
  relevantDateRows.length === 0 ||
  relevantDateRows.some(detail =>
    relevantAbsence.absence_hours
      ? checkIfShiftIncludesAbsence({ startShift: detail.startShift, endShift: detail.endShift }, relevantAbsence)
      : relevantAbsence.count_only_days_with_shifts &&
        relevantAbsence.dateArray.some(
          absenceDate => !detail.startShift || moment(absenceDate).isSame(detail.startShift, 'days'),
        ),
  )
    ? undefined
    : getAbsenceWithoutShiftDetailRow(relevantAbsence, date, employmentConditions);

const getDetails = (
  date,
  relevantDateRows,
  relevantAbsence,
  shiftsForAbsence,
  timeWorkedReal,
  timePlanned,
  overtime,
  acceptedAvailabilities,
  employmentConditions,
) => {
  const baseRows = relevantDateRows.map((detail, index) => {
    const isLast = index === relevantDateRows.length - 1;
    const isShift = Boolean(detail.startShift);
    const isAbsenceForThisShift =
      relevantAbsence &&
      relevantAbsence.count_only_days_with_shifts &&
      (!relevantAbsence.absence_hours ||
        (isShift &&
          checkIfShiftIncludesAbsence({ startShift: detail.startShift, endShift: detail.endShift }, relevantAbsence)));

    return {
      ...detail,
      sumPaid: detail.isAbsence ? null : detail.sumPaid,
      overtime: isLast && overtime[detail.date] ? overtime[detail.date] : { overtime50: 0, overtime100: 0 },
      availability: acceptedAvailabilities.find(ava => ava.date === detail.date) || '',
      absenceType: detail.type,
      isAbsence: detail.isAbsence,
      absence: relevantAbsence && isShift && isAbsenceForThisShift ? relevantAbsence : null,
      absence_short_name: relevantAbsence && isShift && isAbsenceForThisShift ? relevantAbsence.short_name : null,
      absence_time:
        relevantAbsence && isShift && isAbsenceForThisShift
          ? calculateDurationMinutes(relevantAbsence.absence_hours || detail.working_hours) / 60
          : null,
      timeWorked: !relevantAbsence || !isAbsenceForThisShift || relevantAbsence.is_paid ? detail.timeWorked : 0,
    };
  });

  if (relevantDateRows.length > 0) {
    baseRows[0].timeWorkedReal = timeWorkedReal;
    baseRows[0].timePlanned = timePlanned;
  }

  return [
    ...baseRows,
    getAdditionalAbsenceRow(relevantAbsence, date, relevantDateRows, employmentConditions),
    ...getShiftsWithAbsencesRows(shiftsForAbsence, relevantAbsence),
  ].filter(Boolean);
};

/**
 * This function takes attendances and availabilities and combines them into rows by filtering them by date
 * @param {Array} relevantDetails - array of relevant details
 * @param {Array} availabilities - || - Availabilities details are from "Urlopy" case
 * @param {Array} shifts - array of relevant shifts
 * @param {Array} dateArray - array of string dates in format 'YYYY-MM-DD'
 * @returns {Array} array of rows for each day
 */
export const combineDetailsIntoRows = (
  relevantDetails,
  relevantShiftDetails,
  availabilities,
  shifts,
  dateArray,
  employmentConditions,
  overtime = {},
  relevantAvailabilities = [],
  relevantOvertimeCollections = [],
  relevantAbsences = [],
) => {
  const relevantRows = [...relevantDetails, ...availabilities, ...relevantOvertimeCollections];

  return dateArray.reduce((prev, date) => {
    const relevantDateRows = relevantRows.filter(row => row.date === date);
    const relevantDateShifts = shifts.filter(row => row.date === date);
    const relevantAbsence = relevantAbsences.find(absence => absence.dateArray.includes(date));
    const relevantDateAttendances = relevantDetails.filter(detail => detail.date === date && Boolean(detail.startAtt));
    if (relevantDateRows.length === 0 && !relevantAbsence) {
      return prev;
    }

    const shiftsForAbsence = relevantShiftDetails.filter(
      shift =>
        relevantAbsence &&
        !relevantAbsence.absence_hours &&
        relevantAbsence.count_only_days_with_shifts &&
        moment(shift.start_timestamp).isSame(date, 'days'),
    );

    const shiftsDurationSum = relevantDateShifts.reduce(
      (sum, shift) => sum + moment(shift.end_timestamp).diff(shift.start_timestamp, 'minutes'),
      0,
    );
    const absenceDuration = relevantAbsence?.absence_hours
      ? calculateDurationMinutes(relevantAbsence.absence_hours)
      : getDefaultAbsenceTime(relevantAbsence?.count_only_days_with_shifts, employmentConditions);

    if (relevantDateRows.length === 0 && relevantAbsence && shiftsForAbsence.length === 0) {
      return relevantAbsence.count_only_days_with_shifts
        ? prev
        : [
            ...prev,
            {
              humanDate: convertDateToHumanTextShortcut(date),
              date,
              timeWorked: relevantAbsence.is_paid ? absenceDuration : 0,
              timeWorkedForXls: 0,
              locations: [],
              jobTitles: '---',
              photo: null,
              disableBonus: true,
              absence: relevantAbsence,
              absence_short_name: relevantAbsence.short_name,
              absence_time: relevantAbsence.absence_hours
                ? calculateDurationMinutes(relevantAbsence.absence_hours) / 60
                : getDefaultAbsenceTime(relevantAbsence.count_only_days_with_shifts, employmentConditions) / 60,
              isAbsence: true,
              hours: relevantAbsence.absence_hours,
              timeWorkedReal: 0,
              timePlanned: shiftsDurationSum - absenceDuration > 0 ? shiftsDurationSum - absenceDuration : 0,
              details: [getAbsenceWithoutShiftDetailRow(relevantAbsence, date, employmentConditions)],
            },
          ];
    }

    const relevantDateRowsWithAbsences = [...relevantDateRows, ...shiftsForAbsence];
    const timePlanned = relevantDateShifts.reduce((prevShift, shift) => prevShift + (shift.duration || 0), 0);
    let timeWorked = relevantDateRowsWithAbsences.reduce((prevAtt, row) => prevAtt + (row.timeWorked || 0), 0);
    // timeWorkedReal is timeWorked only for attendance not including time from availabilities
    const timeWorkedReal = relevantDetails
      .filter(detail => detail.date === date)
      .reduce((prevAtt, detail) => prevAtt + (detail.timeWorked || 0), 0);
    const acceptedAvailabilities = relevantAvailabilities.filter(ava => !ava.draft);
    const start = (() => {
      const sortedRelevantDateRows = relevantDateRowsWithAbsences
        .filter(({ start }) => start && !start.includes('__:__'))
        .sort((a, b) => (a.start > b.start ? 1 : -1));
      return sortedRelevantDateRows[0]?.start ? sortedRelevantDateRows[0].start.slice(11, 16) : null;
    })();
    const end = (() => {
      const sortedRelevantDateRows = relevantDateRowsWithAbsences
        .filter(({ end }) => end && !end.includes('__:__'))
        .sort((a, b) => (a.end < b.end ? 1 : -1));
      return sortedRelevantDateRows[0]?.end ? sortedRelevantDateRows[0].end.slice(11, 16) : null;
    })();

    if (
      relevantAbsence &&
      relevantDateRows.length > 0 &&
      !relevantDateRows.some(detail =>
        relevantAbsence.absence_hours
          ? checkIfShiftIncludesAbsence({ startShift: detail.startShift, endShift: detail.endShift }, relevantAbsence)
          : relevantAbsence.dateArray.some(
              absenceDate =>
                relevantAbsence.count_only_days_with_shifts &&
                (!detail.startShift || moment(absenceDate).isSame(detail.startShift, 'days')),
            ),
      )
    ) {
      timeWorked += relevantAbsence.absence_hours
        ? calculateDurationMinutes(relevantAbsence.absence_hours)
        : getDefaultAbsenceTime(relevantAbsence.count_only_days_with_shifts, employmentConditions);
    }

    const details = getDetails(
      date,
      relevantDateRows,
      relevantAbsence,
      shiftsForAbsence,
      timeWorkedReal,
      timePlanned,
      overtime,
      acceptedAvailabilities,
      employmentConditions,
    );

    const warningTypes = getWarningTypes(relevantDateAttendances, relevantAbsence);

    return prev.concat({
      humanDate: convertDateToHumanTextShortcut(date),
      date,
      start,
      end,
      hours: getRowHours(
        start,
        end,
        relevantAbsence?.absence_hours ||
          details.find(detail => detail.absence?.id === relevantAbsence?.id)?.working_hours,
      ),
      timeWorked: !relevantAbsence || relevantAbsence.is_paid ? timeWorked || 0 : 0,
      timeWorkedReal: timeWorkedReal || 0,
      timePlanned: timePlanned
        ? timePlanned - getAbsenceTimeInDay(relevantAbsence, relevantDateShifts, employmentConditions) * 60
        : 0,
      nightTimeWorked: relevantDateRowsWithAbsences.reduce((prevAtt, row) => prevAtt + (row.nightTimeWorked || 0), 0),
      locations: uniq(relevantDateRowsWithAbsences.filter(row => row.location).map(row => row.location.id)),
      jobTitles: uniq(relevantDateRowsWithAbsences.filter(row => row.jobTitle).map(row => row.jobTitle)).join(' / '),
      wage: uniq(relevantDateRowsWithAbsences.filter(row => row.wage).map(row => row.wage)).join(' / '),
      photo: null,
      disableBonus: relevantDateRowsWithAbsences.some(row => row.disableBonus),
      sumPaid: relevantDateRows.reduce((prevAtt, row) => prevAtt + (row.sumPaid || 0), 0),
      sumQuotas: relevantDateRowsWithAbsences.reduce((prevAtt, row) => prevAtt + (row.sumQuotas || 0), 0),
      sumBreaks: relevantDateRowsWithAbsences.reduce(
        (prevAtt, row) => (row.sumBreaks ? prevAtt + row.sumBreaks : prevAtt),
        0,
      ),
      sumOvertime50: overtime[date] ? overtime[date].overtime50 : 0,
      sumOvertime100: overtime[date] ? overtime[date].overtime100 : 0,
      bonus_amount: relevantDateRowsWithAbsences.reduce((prevAtt, row) => prevAtt + (row.bonus_amount || 0), 0),
      absence: relevantAbsence,
      absence_short_name: relevantAbsence ? relevantAbsence.short_name : null,
      absence_time: getAbsenceTimeInDay(relevantAbsence, relevantDateShifts, employmentConditions),
      warningTypes,
      details,
    });
  }, []);
};

const getWarningTypes = (attendances, absence) => {
  const warnings = [];

  if (!attendances?.length) {
    return warnings;
  }

  const sumDurationOfAttendanceOverlappedAbsences = attendances.reduce((sum, attendance) => {
    const duration = getSumDurationOfAttendanceOverlappedAbsences(absence, attendance);

    return duration ? sum + duration : sum;
  }, 0);
  if (sumDurationOfAttendanceOverlappedAbsences > 0) {
    warnings.push('attendanceOverlappedAbsence');
  }

  if (attendances.some(att => checkIfOverlap(att, attendances))) {
    warnings.push('attendancesOverlap');
  }

  return warnings;
};

const getWarningTypesForDetail = (detail, attendances = [], absences = []) => {
  const warnings = [];
  const isAttendance = Boolean(detail.startAtt);
  const { isAbsence } = detail;

  if (isAttendance) {
    const absenceForAttendance = absences.find(absence => {
      const { start_timestamp: absenceStartTimestamp, end_timestamp: absenceEndTimestamp } = absence?.absence_hours
        ? workingHoursToTimestamp(absence.absence_hours, absence.from)
        : {};
      return (
        absence.date === detail.date &&
        (!absence.absence_hours ||
          (moment(detail.startAtt).isBefore(absenceEndTimestamp) &&
            moment(detail.endAtt).isAfter(absenceStartTimestamp)))
      );
    });
    const sumDurationOfAttendanceOverlappedAbsences = attendances.reduce((sum, attendance) => {
      const duration = getSumDurationOfAttendanceOverlappedAbsences(absenceForAttendance, attendance);

      return duration ? sum + duration : sum;
    }, 0);
    if (sumDurationOfAttendanceOverlappedAbsences > 0) {
      warnings.push('attendanceOverlappedAbsence');
    }
    if (checkIfOverlap(detail, attendances)) {
      warnings.push('attendancesOverlap');
    }
  } else if (isAbsence) {
    const attendanceOverlappingWithAbsence = attendances.find(attendance => {
      const { start_timestamp: absenceStartTimestamp, end_timestamp: absenceEndTimestamp } = detail.absence_hours
        ? workingHoursToTimestamp(detail.absence_hours, detail.from)
        : {};
      return (
        detail.date === attendance.date &&
        (!detail.absence_hours ||
          (moment(attendance.startAtt).isBefore(absenceEndTimestamp) &&
            moment(attendance.endAtt).isAfter(absenceStartTimestamp)))
      );
    });
    if (attendanceOverlappingWithAbsence) {
      warnings.push('attendanceOverlappedAbsence');
    }
  }

  return warnings;
};

export const getRelevantAttendancesDetails = (
  relevantAttendances,
  employee,
  payrollSettings,
  options = { skipUnfinishedBreaks: false },
) =>
  relevantAttendances.map(attendance => {
    const { date } = attendance;
    let bonusAmount = Number(attendance.bonus_amount / 100);
    let disableBonus = false;
    let errorHighlight = false;
    let errorMessage = '';
    let sumPaid = 0;
    let sumQuotas = 0;
    let timeWorked;
    let jobTitle;
    let wagePerHour;

    const sumBreaks = attendance.breaks.reduce(
      (sum, br) =>
        options.skipUnfinishedBreaks && !br.end_timestamp
          ? sum
          : sum + calculateDurationBetweenTimestamps(br.end_timestamp, br.start_timestamp),
      0,
    );
    let start = calculatePayrollHour(attendance.start_timestamp, payrollSettings, 'start');
    let end = calculatePayrollHour(attendance.end_timestamp, payrollSettings, 'end');

    if (attendance.matching_shift) {
      const matchingShift = attendance.matching_shift;

      // Wage --------------------------------------------------------------
      let wage = getMatchingIndividualWage(attendance, employee);
      if (!wage && wage !== 0) {
        wage = 0;
        console.error('Could not resolve wage for relevant attendance rows');
      }
      wagePerHour = wage / 100;

      // Job title ---------------------------------------------------------
      jobTitle = attendance.matching_shift_job_title.title;

      // Start / End -------------------------------------------------------
      switch (payrollSettings.payoutSetting.type) {
        case 'ecp': {
          start = applyTolerance(
            attendance.start_timestamp,
            matchingShift.start_timestamp,
            'start',
            payrollSettings,
            attendance.early_in,
          );
          start = calculatePayrollHour(start, payrollSettings, 'start');
          end = applyTolerance(
            attendance.end_timestamp,
            matchingShift.end_timestamp,
            'end',
            payrollSettings,
            attendance.late_out,
          );
          end = calculatePayrollHour(end, payrollSettings, 'end');
          break;
        }
        case 'shifts_unless_fixed': {
          let startHour =
            attendance.start_timestamp < matchingShift.start_timestamp
              ? matchingShift.start_timestamp
              : attendance.start_timestamp;
          if (attendance.early_in) startHour = attendance.start_timestamp;
          start = calculatePayrollHour(startHour, payrollSettings, 'start');
          end = applyTolerance(
            attendance.end_timestamp,
            matchingShift.end_timestamp,
            'end',
            payrollSettings,
            attendance.late_out,
          );
          end = calculatePayrollHour(end, payrollSettings, 'end');

          timeWorked =
            attendance.end_timestamp > start
              ? calculateDurationBetweenTimestampsForPayroll(end, start, payrollSettings.roundingSetting)
              : 0;
          break;
        }
        case 'shifts_no_extra': {
          start = calculatePayrollHour(
            attendance.start_timestamp < matchingShift.start_timestamp
              ? matchingShift.start_timestamp
              : attendance.start_timestamp,
            payrollSettings,
            'start',
          );
          end = calculatePayrollHour(
            attendance.end_timestamp > matchingShift.end_timestamp
              ? matchingShift.end_timestamp
              : attendance.end_timestamp,
            payrollSettings,
            'end',
          );
          timeWorked =
            end > start ? calculateDurationBetweenTimestampsForPayroll(end, start, payrollSettings.roundingSetting) : 0;
          break;
        }
        default:
          break;
      }

      if (attendance.end_timestamp) {
        timeWorked = calculateDurationBetweenTimestampsForPayroll(end, start, payrollSettings.roundingSetting);
      } else {
        timeWorked = 0;
        errorHighlight = true;
        errorMessage = 'Zmiana jeszcze trwa';
        disableBonus = true;
      }
    } else {
      errorHighlight = true;
      errorMessage = 'Brak przypisanej zmiany dla pracownika';
      disableBonus = true;
      bonusAmount = 0;

      if (attendance.end_timestamp) {
        timeWorked = calculateDurationBetweenTimestampsForPayroll(end, start, payrollSettings.roundingSetting);
      } else {
        timeWorked = 0;
        errorHighlight = true;
        errorMessage = 'Zmiana jeszcze trwa';
        disableBonus = true;
      }
    }
    // Decreasing breaks time --------------------------------------------------
    switch (payrollSettings.breaksSetting.type) {
      case 'tolerance':
        if (sumBreaks > payrollSettings.breaksValue) {
          timeWorked -= sumBreaks - payrollSettings.breaksValue;
        }
        break;
      case 'constant':
        timeWorked -= payrollSettings.breaksValue;
        break;
      default:
        break;
    }
    if (timeWorked < 0) timeWorked = 0;
    // -------------------------------------------------------------------------

    if (wagePerHour != null) {
      sumPaid = roundToTwoSigDigits((wagePerHour * timeWorked) / 60);
    } else {
      sumPaid = 0;
    }

    if (employee.payroll && employee.payroll.payout_breakdown_per_day[date]) {
      const attendanceQuota = employee.payroll.payout_breakdown_per_day[date].payout_breakdown_per_job_title.find(
        payout => payout.attendance_id === parseInt(attendance.id),
      );
      sumQuotas = attendanceQuota ? attendanceQuota.payout : 0;
    }

    const timeWorkedReal =
      calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp) || 0;
    const timePlanned = attendance.matching_shift
      ? calculateDurationBetweenTimestamps(
          attendance.matching_shift.end_timestamp,
          attendance.matching_shift.start_timestamp,
        )
      : 0;
    const nightTimeWorked = countNightHours(start, end, payrollSettings);
    return {
      ...attendance,
      bonus_amount: bonusAmount,
      wage: wagePerHour,
      timeWorked,
      timeWorkedReal,
      timePlanned,
      nightTimeWorked,
      errorHighlight,
      errorMessage,
      disableBonus,
      sumQuotas,
      sumPaid: sumPaid + bonusAmount + sumQuotas,
      sumBreaks,
      jobTitle,
      start,
      end,
      startAtt: attendance.start_timestamp,
      endAtt: attendance.end_timestamp,
    };
  });

/**
 * This function returns all necessary data for payroll view
 * @param {Object} employee - employee object with all data
 * @param {Object} payrollSettings - settings for payroll calculations
 * TODO: add list of used settings
 * @param {Object} mainDateStore
 * @param {Object} multipleLocationFilter
 * @returns {Object} :
 * - relevantRows - array of object with data for single row/day
 * - sumBonuses - sum of bonuses of given employee (x100)
 * - sumHours - sum of worked hours (number of minutes)
 * - sumNightHours - sum of night time hours (number of minutes)
 * - sumPayout - sum of money to payout (x100)
 * - sumQuotas - sum of money from production quotas (x100)
 * - sumBreaks - sum of time on breaks (number of minutes)
 */
export const getPayrollData = (
  employee,
  payrollSettings,
  mainDateStore,
  selectedLocationIds,
  userCustomTypes,
  options = { addAvailabilityTimestamps: false, jobTitlesToFilterWith: null, showUnpaidAbsences: false },
  overtimeCollections = {},
  absences = {},
  absenceTypes = [],
) => {
  let relevantDetails = [];
  let relevantShiftDetails = [];
  const relevantShifts = filterShifts(
    employee.shifts,
    mainDateStore,
    selectedLocationIds,
    options.jobTitlesToFilterWith,
  ).sort((a, b) => (a.date > b.date ? 1 : -1));
  const jobTitleIds = options.jobTitlesToFilterWith ? options.jobTitlesToFilterWith.map(j => j.id) : null;
  // Calculate payroll using shifts or attendances
  switch (payrollSettings.payoutSetting.type) {
    case 'shifts': {
      relevantDetails = getRelevantShiftsDetails(relevantShifts, employee, payrollSettings);

      break;
    }
    default: {
      const relevantAtts = filterAttendances(
        employee.attendances,
        mainDateStore,
        selectedLocationIds,
        jobTitleIds,
      ).sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
      relevantDetails = getRelevantAttendancesDetails(relevantAtts, employee, payrollSettings, {
        skipUnfinishedBreaks: true,
      });
      relevantShiftDetails = getRelevantShiftsDetails(relevantShifts, employee, payrollSettings);
      break;
    }
  }
  const employeeAvailabilityBlocks =
    employee.availability_blocks?.map(block => {
      const customType = userCustomTypes.find(type => type.id === block.type_id);

      return customType
        ? {
            ...customType,
            ...block,
          }
        : block;
    }) || [];
  // Adding leaves from availability blocks
  const relevantAvailabilities = filterAvailabilities(employeeAvailabilityBlocks, mainDateStore)
    .map(availability => {
      if (availability.type_id) {
        const avaType = userCustomTypes.find(type => type.id === availability.type_id);
        if (avaType) return { ...availability, type_name: avaType.name, requiresTime: avaType.requiresTime };
      }
      return availability;
    })
    .filter(ava => !ava.draft);

  const relevantAvailabilitiesDetails = getRelevantAvailabilitiesDetails(
    relevantAvailabilities,
    options.addAvailabilityTimestamps,
  );

  const relevantOvertimeCollections =
    overtimeCollections[employee.id]
      ?.filter(
        absence =>
          !absence.draft && mainDateStore.dateArray.includes(moment(absence.start_timestamp).format('YYYY-MM-DD')),
      )
      .map(mapOvertimeCollectionIntoRow) || [];

  const relevantAbsences = (absences[employee.id] || []).reduce((result, absence) => {
    const absenceType = absenceTypes.find(type => type.id === absence.type_id);

    if (
      absence.status === 'accepted' &&
      absence.from <= mainDateStore.customDate.end &&
      absence.to >= mainDateStore.customDate.start &&
      (absenceType.is_paid || options.showUnpaidAbsences)
    ) {
      const dateArray = Array.from({ length: moment(absence.to).diff(absence.from, 'days') + 1 }, (_, index) =>
        moment(absence.from)
          .add(index, 'days')
          .format('YYYY-MM-DD'),
      );
      const filteredDateArray = dateArray.filter(date => !absence.omitted_dates.includes(date));

      return [
        ...result,
        {
          ...absence,
          dateArray: filteredDateArray,
          isAbsence: true,
          short_name: absenceType?.short_name,
          is_paid: absenceType?.is_paid,
        },
      ];
    }

    return result;
  }, []);

  // RelevantRows from details and leaves
  const relevantRows = combineDetailsIntoRows(
    relevantDetails,
    relevantShiftDetails,
    relevantAvailabilitiesDetails,
    relevantShifts,
    mainDateStore.dateArray,
    employee.employment_conditions,
    employee.overtime,
    relevantAvailabilities,
    relevantOvertimeCollections,
    relevantAbsences,
  );

  // Summary data
  const relevantOvertime = Object.keys(employee.overtime || {})
    .filter(date => relevantDetails.some(detail => detail.date === date))
    .reduce(
      (result, date) => ({
        ...result,
        [date]: employee.overtime[date],
      }),
      {},
    );
  const sumedScheduleCycleOvertime =
    employee.overtimeStats && employee.overtimeStats.overtime
      ? parseMinutesToFormat(
          employee.overtimeStats.overtime.scheduleCycleOvertime,
          payrollSettings.timeFormatSetting.type,
        )
      : 0;
  const overtime50 = Object.values(relevantOvertime).reduce(
    (sum, overtimeObject) => sum + (overtimeObject.overtime50 || 0),
    0,
  );
  const overtime100 = Object.values(relevantOvertime).reduce(
    (sum, overtimeObject) => sum + (overtimeObject.overtime100 || 0),
    0,
  );
  const sumOfOvertimeCollections = relevantOvertimeCollections
    .filter(absence => absence.type === 'overtime_collection')
    .reduce((sum, collection) => {
      const time = moment(collection.end_timestamp).diff(collection.start_timestamp, 'minutes');

      return sum + time;
    }, 0);

  const { sumOvertime50, sumOvertime100 } = getSumOvertime({ overtime50, overtime100 }, sumOfOvertimeCollections);

  let sumHours = relevantRows.map(r => r.timeWorked).reduce((a, b) => a + (b || 0), 0);
  let sumHoursReal = relevantRows.map(r => r.timeWorkedReal).reduce((a, b) => a + (b || 0), 0);
  let sumHoursPlanned = relevantRows.reduce((a, b) => a + (b.timePlanned || 0), 0);
  const sumPlannedDiff = displayPlannedDiff(sumHoursReal, sumHoursPlanned, payrollSettings.timeFormatSetting.type);
  sumHours = parseMinutesToFormat(sumHours, payrollSettings.timeFormatSetting.type);
  sumHoursReal = parseMinutesToFormat(sumHoursReal, payrollSettings.timeFormatSetting.type);
  sumHoursPlanned = sumHours ? parseMinutesToFormat(sumHoursPlanned, payrollSettings.timeFormatSetting.type) : 0;
  const sumNightHours = parseMinutesToFormat(
    relevantRows.map(r => r.nightTimeWorked).reduce((a, b) => a + (b || 0), 0),
    payrollSettings.timeFormatSetting.type,
  );
  const sumBreaks = parseMinutesToFormat(
    relevantRows.reduce((sum, row) => sum + (row.sumBreaks || 0), 0),
    payrollSettings.timeFormatSetting.type,
  );
  const sumQuotas = relevantRows.map(r => r.sumQuotas).reduce((a, b) => a + (b || 0), 0);
  const sumBonuses = relevantRows.map(r => r.bonus_amount).reduce((a, b) => a + (b || 0), 0);
  const sumPayout = roundToTwoSigDigits(relevantRows.map(r => r.sumPaid).reduce((a, b) => a + (b || 0), 0));
  const sumAbsencesInMinutes = relevantRows.reduce((sum, row) => sum + (row.absence_time * 60 || 0), 0);
  const sumAbsences = parseMinutesToFormat(sumAbsencesInMinutes, payrollSettings.timeFormatSetting.type);

  return {
    sumOvertime50: parseMinutesToFormat(sumOvertime50, payrollSettings.timeFormatSetting.type),
    sumOvertime100: parseMinutesToFormat(sumOvertime100, payrollSettings.timeFormatSetting.type),
    sumHours,
    sumHoursReal,
    sumHoursPlanned,
    sumPlannedDiff,
    sumNightHours,
    sumQuotas,
    sumPayout,
    sumBonuses,
    relevantRows,
    sumBreaks,
    sumedScheduleCycleOvertime,
    sumAbsences,
  };
};

// MAIN FUNCTIONS - END --------------------------------------------------------

export const getPayrollEditedData = (originalData, data) => {
  const dataIds = data.map(d => d.id);
  const filteredOriginalData = originalData.filter(d => dataIds.includes(d.id));
  return filteredOriginalData.reduce((acc, val) => {
    const matchingData = data.find(d => d.id === val.id);
    return isEqual(matchingData, val) ? acc : acc.concat(matchingData);
  }, []);
};

export const getPayrollDataDiff = (originalData, data) => {
  const dataIds = data.map(d => d.id);
  return originalData.filter(d => !dataIds.includes(d.id));
};

export const payrollEditShiftsAndAttendancesModalOnSubmit = (state, props) => {
  const { shifts, originalShifts, originalAttandances, employee, attendances } = state;
  const shiftsToDelete = getPayrollDataDiff(originalShifts, shifts);
  const attendancesToDelete = getPayrollDataDiff(originalAttandances, attendances);
  const shiftsToEdit = getPayrollEditedData(originalShifts, shifts).reduce(
    (editedShifts, shift) => editedShifts.concat({ ...shift, draft: false }),
    [],
  );
  const attendancesToEdit = getPayrollEditedData(originalAttandances, attendances);

  if (shiftsToDelete.length) {
    props.massDeleteShifts(
      employee.id,
      shiftsToDelete.map(shift => shift.id),
    );
  }
  if (shiftsToEdit.length) {
    props.massEditShifts(employee, shiftsToEdit);
  }
  if (attendancesToDelete.length) {
    props.massDeleteAttendances(
      employee.id,
      attendancesToDelete.map(att => att.id),
    );
  }
  if (attendancesToEdit.length) {
    props.massChangeAttendance(
      employee,
      attendancesToEdit,
      attendancesToEdit.map(att => att.hours),
    );
  }
};

export const getAvailabilitiesHeadersForPayrollExport = relevantEmployeeRows =>
  (relevantEmployeeRows || []).reduce(
    (headers, val) => uniq(headers.concat(Object.keys(val.relevantRowInfo.sumAvailabilities))),
    [],
  );

export const getRelevantAvailabilities = (employeeAvailabilityBlocks, mainDateStore, userCustomTypes) =>
  (filterAvailabilities(employeeAvailabilityBlocks, mainDateStore) || []).reduce((acc, availability) => {
    if (availability.type_id && availability.type === 'custom') {
      const customAvaType = (userCustomTypes || []).find(type => type.id === availability.type_id);
      if (customAvaType) return [...acc, { ...availability, type_name: customAvaType.name }];
    }
    return acc;
  }, []);

const DAILY_WORKING_TIME = 480;

export const getWeeklyOvertimeEstimate = (plannedMinutes, realWorkedMinutes) => {
  if (plannedMinutes === realWorkedMinutes) {
    return 0;
  }

  if (plannedMinutes >= DAILY_WORKING_TIME && realWorkedMinutes >= DAILY_WORKING_TIME) {
    return 0;
  }

  const isLessThan8HoursPlanned =
    plannedMinutes > DAILY_WORKING_TIME ? realWorkedMinutes - DAILY_WORKING_TIME : realWorkedMinutes - plannedMinutes;

  return realWorkedMinutes > DAILY_WORKING_TIME
    ? realWorkedMinutes - plannedMinutes - (realWorkedMinutes - DAILY_WORKING_TIME)
    : isLessThan8HoursPlanned;
};

export const getPayrollOptionSettings = (payrollSettings, isPayoutSettingAsShifts, intl) =>
  payrollSettings.reduce((accumulator, setting) => {
    if (!isPayoutSettingAsShifts || setting.type !== breakSettingTypes.INCLUDE_BREAK_TIME) {
      accumulator.push({
        value: setting.id,
        key: intl.formatMessage(setting.message),
      });
    }
    return accumulator;
  }, []);
