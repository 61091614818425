import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absenceEmployee: {
    id: 'absences.select.employee',
    defaultMessage: 'Pracownik',
  },
  absenceSelectType: {
    id: 'absences.select.type',
    defaultMessage: 'Rodzaj',
  },
  absenceCheckboxAllDay: {
    id: 'absences.checkbox.allDay',
    defaultMessage: 'Cały dzień',
  },
  notePlaceholder: {
    id: 'schedule.managerNotesModal.noteText',
    defaultMessage: 'Treść notatki',
  },
  note: {
    id: 'common.note',
    defaultMessage: 'Notatka',
  },
  addAbsenceFor: {
    id: 'absences.addAbsenceFor',
    defaultMessage: 'Dodaj urlop dla {employeeName}',
  },
  scheduleCycle: {
    id: 'absences.scheduleCycle',
    defaultMessage: 'Odbiór nadgodzin za miesiąc',
  },
  noOvertimeCollections: {
    id: 'overtimeCollections.noOvertimeCollections',
    defaultMessage: 'Brak nadgodzin do wykorzystania w tym okresie. Wybierz inny okres.',
  },
});
