import { connect } from 'react-redux';

import ScheduleQuickPlanningItem from './ScheduleQuickPlanningItem';

const mapStateToProps = (state, ownProps) => {
  const { selected, hoveredItems, copiedMarkedArea, position } = state.reducer.schedule.quickPlanning;
  const { borderCell } = position;
  const withBorder = ownProps.date === borderCell.date && ownProps.employeeId === borderCell.employeeId;
  const isSelectedItemToCopy = selected[ownProps.employeeId]?.[ownProps.date];
  const isHoveredItem = hoveredItems[ownProps.employeeId]?.[ownProps.date];
  const withBorderTop = copiedMarkedArea.borderTop[ownProps.employeeId]?.[ownProps.date];
  const withBorderBottom = copiedMarkedArea.borderBottom[ownProps.employeeId]?.[ownProps.date];
  const withBorderLeft = copiedMarkedArea.borderLeft[ownProps.employeeId]?.[ownProps.date];
  const withBorderRight = copiedMarkedArea.borderRight[ownProps.employeeId]?.[ownProps.date];

  return {
    isSelectedItemToCopy,
    withBorder,
    isHoveredItem,
    isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
    withBorderTop,
    withBorderBottom,
    withBorderLeft,
    withBorderRight,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleQuickPlanningItem);
