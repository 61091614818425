import { connect } from 'react-redux';

import DashboardLocations from './DashboardLocations.jsx';

const mapStateToProps = ({ reducer }) => ({
  userLocations: reducer.userLocations,
  locationSummary: reducer.dashboard.locationSummary,
  locationsSorting: reducer.dashboard.locations,
});

export default connect(mapStateToProps)(DashboardLocations);
