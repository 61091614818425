import * as actionTypes from '@/constants/ActionTypes';
import { CustomFieldDefinitionData } from '@/types/customFields.types.ts';

import { conn } from '../index';
import { messages } from './messages.ts';

export const getCustomFieldsDefinitionsError = () => (dispatch, _, intl) => {
  dispatch({
    type: actionTypes.GET_CUSTOM_FIELDS_DEFINITIONS_ERROR,
    notification: {
      title: intl.formatMessage(messages.getCustomFieldsDefinitionsError),
      type: 'error',
    },
  });
};

export const getCustomFieldsDefinitionsSuccessful = (data: CustomFieldDefinitionData) => ({
  type: actionTypes.GET_CUSTOM_FIELDS_DEFINITIONS_SUCCESSFUL,
  payload: data,
});

export const getCustomFieldsDefinitions = () => async (dispatch) => {
  try {
    const { data } = await conn.getCustomFieldsDefinitions();
    dispatch(getCustomFieldsDefinitionsSuccessful(data));
  } catch (e) {
    dispatch(getCustomFieldsDefinitionsError());
    console.error(e);
  }
};

export const getUserCustomFieldsSuccessful = (data) => ({
  type: actionTypes.GET_USER_CUSTOM_FIELDS_SUCCESSFUL,
  payload: data,
});

export const getUsersCustomFields = (employeeIds: string[]) => async (dispatch) => {
  try {
    const { data } = await conn.getUsersCustomFields(employeeIds);
    dispatch(getUserCustomFieldsSuccessful(data));
  } catch (e) {
    console.error(e);
    throw e;
  }
};
