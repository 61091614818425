import { AbsenceType } from 'kadro-helpers/lib/types';

import { OVERTIME_COLLECTION_ID, OVERTIME_LABEL } from '@/constants/absences';
import { AbsencesChartDay } from '@/types/reports.types';

const getAbsenceIdsSortedBySummedValue = (
  data: Record<string, AbsencesChartDay>,
  type: string,
): { absenceId: string; value: number }[] => {
  const totalValuePerJobTitle = Object.values(data).reduce(
    (acc, dayData) => {
      const values = dayData[type];

      Object.keys(values).forEach(absenceId => {
        acc[absenceId] = (acc[absenceId] || 0) + values[absenceId];
      });
      return acc;
    },
    {} as Record<string, number>,
  );

  return Object.entries(totalValuePerJobTitle)
    .reduce((acc, [absenceId, value]) => {
      if (value > 0) {
        acc.push({ absenceId, value });
      }
      return acc;
    }, [])
    .sort((a, b) => b.value - a.value);
};

const aggregateAbsenceSums = (
  data: Record<string, AbsencesChartDay>,
  type: string,
  selectedAbsenceIds: string[],
): Record<string, number> =>
  Object.values(data).reduce((sums: Record<string, number>, dayData) => {
    const values = dayData[type] as Record<string, number>;

    Object.entries(values).forEach(([absenceId, value]) => {
      if (value > 0) {
        if (selectedAbsenceIds.includes(absenceId)) {
          sums[absenceId] = (sums[absenceId] || 0) + value;
        } else {
          sums.other = (sums.other || 0) + value;
        }
      }
    });

    return sums;
  }, {});

export const formatAndSortDataToChart = (
  absencesSum: Record<string, number>,
  absencesTypes: AbsenceType[],
  messages: { other: string },
): { name: string; value: number }[] =>
  Object.entries(absencesSum)
    .map(([id, value]) => {
      const isOvertimeCollection = id === OVERTIME_COLLECTION_ID;

      const absenceType = isOvertimeCollection
        ? { short_name: OVERTIME_LABEL }
        : absencesTypes.find(absence => absence.id === id);

      return {
        name: absenceType ? absenceType.short_name : messages.other,
        value,
      };
    })
    .sort((a, b) => b.value - a.value);

export const prepareDataForAbsencesChart = (
  data: Record<string, AbsencesChartDay>,
  absencesTypes: AbsenceType[],
  chartMode: string,
  maxAbsences: number,
  messages: { other: string },
) => {
  const absenceIdsSortedBySummedValue = getAbsenceIdsSortedBySummedValue(data, chartMode);

  const maxNumberOfAbsences = Math.min(maxAbsences, absenceIdsSortedBySummedValue.length);
  const selectedAbsenceIds = absenceIdsSortedBySummedValue
    .slice(0, maxNumberOfAbsences)
    .map(({ absenceId }) => absenceId);

  const absencesSum = aggregateAbsenceSums(data, chartMode, selectedAbsenceIds);
  return formatAndSortDataToChart(absencesSum, absencesTypes, messages);
};
