import { MDCheckbox } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { messages } from './DeleteScheduleItemsModal.utils';
import { useDeleteScheduleItemsModal } from './useDeleteScheduleItemsModal';

import './DeleteScheduleItemsModal.scss';

export const DeleteScheduleItemsModal = () => {
  const { checkboxList, intl, isModalOpen, items, onCloseModal, onSubmit, onToggleItem } =
    useDeleteScheduleItemsModal();

  return (
    <MDKadroModal
      show={isModalOpen}
      onHide={onCloseModal}
      title={intl.formatMessage(messages.title)}
      modifiers={['narrow']}
      inputAwareSubmit
      disableConfirm={isEmptyArray(items)}
      confirmText={intl.formatMessage(messages.delete)}
      className="k-deleteScheduleItemsModal"
      onSubmit={onSubmit}
    >
      {checkboxList.map(({ id, label }) => (
        <MDCheckbox reverse key={id} text={label} value={items.includes(id)} onChange={() => onToggleItem(id)} />
      ))}
    </MDKadroModal>
  );
};
