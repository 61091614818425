import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { MDInfoWithLabel } from '@/components/common/inputs/MDComponents.jsx';
import { getMonthNameFromNumber, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { firstLetterToUpperCase } from '@/utils/stringHelpers';

const messages = defineMessages({
  applicant: {
    id: 'absenceModal.overtime.applicant',
    defaultMessage: 'Wnioskodawca',
  },
  receivedOvertime: {
    id: 'absenceModal.overtime.receivedOvertime',
    defaultMessage: 'Odebrane nadgodziny',
  },
  employee: {
    id: 'absenceModal.overtime.employee',
    defaultMessage: 'Pracownik',
  },
  employer: {
    id: 'absenceModal.overtime.employer',
    defaultMessage: 'Pracodawca',
  },
  receivedOvertimeAmountMonth: {
    id: 'absenceModal.overtime.receivedOvertimeAmountMonth',
    defaultMessage: 'Miesiąc',
  },
});

const OvertimeInformation = ({ absence }, { intl }) => {
  const { multiplier, amount50, amount100, amount_potential: amountPotential, year, month } = absence;
  const isEmployeeApplicant = useMemo(() => multiplier === 1, [multiplier]);
  const amount50Time = useMemo(() => parseMinutesToHumanForm(amount50), [amount50]);
  const amount100Time = useMemo(() => parseMinutesToHumanForm(amount100), [amount100]);
  const amountPotentialTime = useMemo(() => parseMinutesToHumanForm(amountPotential), [amountPotential]);
  const amount50Text = amount50 ? `50% - ${amount50Time}` : null;
  const amount100Text = amount100 ? `100% - ${amount100Time}` : null;
  const amountPotentialText = amountPotential ? `PŚT - ${amountPotentialTime}` : null;
  const monthText = useMemo(() => firstLetterToUpperCase(getMonthNameFromNumber(month)), [month]);
  const monthMessage = intl.formatMessage(messages.receivedOvertimeAmountMonth);
  const amounts = [amount50Text, amount100Text, amountPotentialText].filter(Boolean).join(' / ');
  const labelInfo = `${monthMessage} - ${monthText} ${year}, ${amounts}`;

  return (
    <>
      <MDInfoWithLabel label={intl.formatMessage(messages.receivedOvertime)} info={labelInfo} />
      <MDInfoWithLabel
        label={intl.formatMessage(messages.applicant)}
        info={intl.formatMessage(isEmployeeApplicant ? messages.employee : messages.employer)}
      />
    </>
  );
};

OvertimeInformation.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OvertimeInformation.propTypes = {
  absence: PropTypes.shape({
    multiplier: PropTypes.number,
    amount50: PropTypes.number,
    amount100: PropTypes.number,
    amount_potential: PropTypes.number,
    year: PropTypes.number,
    month: PropTypes.number,
  }),
};

export default OvertimeInformation;
