import { connect } from 'react-redux';

import EmployeesEditMassRolesField from './EmployeesEditMassRolesField.jsx';

const mapStateToProps = state => ({
  companyRoles: state.reducer.roles.companyRoles,
  currentUser: state.reducer.currentUser.user,
  companySettings: state.reducer.currentCompany.settings,
  userPermissions: state.reducer.userPermissions,
});
const EmplyoeesEditMassRoles = connect(mapStateToProps)(EmployeesEditMassRolesField);

export default EmplyoeesEditMassRoles;
