import classnames from 'classnames';
import PropTypes from 'prop-types';

const OptionsCell = props => {
  const optionsAmount = props.options.length;
  return (
    <span>
      {props.options.map((value, i) => {
        const isDisabled = (props.disabledArray && props.disabledArray[i]) || props.disabled;
        const optionClassName = classnames('func_but', props.styles[i], {
          'func_but--disabled': isDisabled,
          'func_but--withoutMargin': optionsAmount === 1,
        });

        return (
          <a
            key={i}
            onClick={isDisabled || !props.functions[i] ? undefined : props.functions[i]}
            className={optionClassName}
            role="presentation"
            data-test={`${props.styles[i]}Option`}
          >
            {value}
          </a>
        );
      })}
    </span>
  );
};

OptionsCell.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  functions: PropTypes.arrayOf(PropTypes.func).isRequired,
  disabled: PropTypes.bool,
  disabledArray: PropTypes.arrayOf(PropTypes.bool),
};

export default OptionsCell;
