/* eslint no-console:0 array-callback-return: 0 */
/* eslint-disable no-await-in-loop */
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import ClearViewConfirmationModalDescription from '@/components/scheduler/modals/ClearViewConfirmationModalDescription.jsx';
import {
  ADD_MASS_SHIFTS_FAILURE,
  ADD_MASS_SHIFTS_SUCCESFUL,
  CHANGE_SCHEDULE_MODE,
  CHANGE_SCHEDULE_TABLE_SIZE,
  CLEAR_IMPORT_MODAL,
  CLEAR_VIEW_SCHEDULE_ERROR,
  CLEAR_VIEW_SCHEDULE_SUCCESS,
  DESELECT_ALL_SCHEDULE,
  DUPLICATE_PREVIOUS_VIEW_SUCCESS,
  FLEX_SCHEDULE_DATA_CHANGED,
  FLEX_SCHEDULE_SELECTED_DAY_CHANGED,
  FLIP_SCHEDULE_MODE,
  IMPORT_SCHEDULE_DATA,
  MOVE_SELECTED_COLUMNS,
  SET_CAN_DELETE,
  TOGGLE_IMPORT_BUDGET_MODAL_ERROR,
  TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY,
  TOGGLE_IMPORT_MODAL_OVERLAY,
  TOGGLE_NON_WORKING_DAYS,
  TOGGLE_SCHEDULE_COLUMN,
  TOGGLE_SCHEDULE_ROW,
  TOGGLE_SCHEDULE_SETTING,
  TURN_SCHEDULE_SETTINGS_ON,
  UPLOAD_FILE_TO_IMPORT,
} from '@/constants/ActionTypes.js';
import { scheduleMessages } from '@/constants/intl.js';
import { getScheduleSettingsKey } from '@/constants/localStorageKeys';
import {
  ADD_TEMPLATE_MODAL,
  BUDGET_IMPORT_MODAL,
  BUDGET_METRICS_IMPORT_MODAL,
  LOAD_BUDGET_TARGET_MODAL,
  LOAD_RECOMMENDED_SCHEDULE_MODAL,
  SAVE_SCHEDULE_AS_TEMPLATE_MODAL,
  SCHEDULE_IMPORT_MODAL,
} from '@/constants/modalTypes.js';
import { BUDGET_ESTIMATES_EDIT, BUDGET_METRICS_ENABLE } from '@/constants/Permissions.js';
import { BUDGET_INFO_HIDE, FREEMIUM_HIDE_OPEN_SHIFTS } from '@/constants/Restrictions.js';
import {
  AVAILABILITIES_DISPLAY_MODE_TYPE,
  POSITIONS_DISPLAY_MODE_TYPE,
  SCHEDULE_DISPLAY_MODE_TYPE,
  TEMPLATE_TYPES,
  TEMPLATES_DISPLAY_MODE_TYPE,
} from '@/constants/scheduleDisplayModes.js';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { getRelevantDateRange } from '@/utils/dateHelper.js';
import {
  checkShiftOverlap,
  extractAvailabilitiesFromEmployees,
  extractAvailabilitiesFromEmployeesWithFilters,
  extractAvailabilitiesFromEmployeesWithFiltersAndDateArray,
  extractAvailabilityDraftsFromEmployeesWithFilters,
  extractShiftsForPreviousViewDuplicate,
  extractShiftsFromSelectedColumns,
  extractsShiftsFromEmployees,
  extractsShiftsFromEmployeesWithLocationAndDateArrayFilter,
  extractsShiftsFromEmployeesWithLocationFilter,
  getDisableEditUntil,
  getVisibleEmployeeIds,
} from '@/utils/schedulerHelpers.js';

import { showAutoGenerateStepsModalWithOpenShiftsAsTemplate } from './autoscheduler.js';
import { checkIfCanPaste, copyAvailabilities, copyFlexSchedule, copyShifts, pasteFlexSchedule } from './copyPaste.js';
import {
  addAvailability,
  confirmAvailability,
  conn,
  deleteAvailability,
  deleteShift,
  deleteShiftArray,
  deleteUnpublishedShifts,
  getNewDataForDate,
  showConfirmModal,
} from './index';
import { mainDateChangeMode, setMainDateStore } from './mainDate.js';
import { massDeleteOpenShifts } from './openShifts';
import { deleteCurrentTemplateShifts, deleteTemplate, deleteUnpublishedTemplateShifts } from './templates.js';
import {
  decreaseLoaderCounter,
  hideModal,
  increaseLoaderCounter,
  showModal,
  toggleExportModal,
  toggleImportOpenShiftsModal,
} from './uiState.js';
import { changeDefaultViewDateStore } from './uiState/uiState.actions';
import { clearWorkingRules, getWorkingRulesViolationsForCurrentDateArray } from './workingRules.js';

export const exportSchedule = () => (dispatch, getState) => {
  console.info('Exporting schedule.');
  const { mainDateStore, locationFilter, jobtitleFilter } = getState().reducer;
  const { dateArray, dateMode } = mainDateStore;
  const monthMode = dateMode === 'month' ? 1 : 0;
  const locations = locationFilter.selectedLocation;
  const jobTitleIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);
  const baseUrl = 'https://app.kadromierz.pl'; // window.location.origin; //"https://testing.kadromierz.pl";
  const sorting = '';
  const url = `${baseUrl}/exports/schedule?from=${dateArray[0]}&to=${dateArray[dateArray.length - 1]}&auth=${
    this.props.userToken
  }&locations=${locations}&job_title_ids=${jobTitleIds}&sorting=${sorting}&monthMode=${monthMode}`;
  window.location.assign(url);
};

export const setCanDelete = canDelete => ({
  type: SET_CAN_DELETE,
  payload: canDelete,
});

const isDisabledRowSelected = (disableEditUntil, scheduleUIState, mainDateStore) =>
  disableEditUntil &&
  !!scheduleUIState.selectedRows.length &&
  !moment(mainDateStore.dateArray[0]).isAfter(moment(disableEditUntil));

const isDisabledColumnSelected = (disableEditUntil, scheduleUIState, mainDateStore) =>
  disableEditUntil &&
  !moment(mainDateStore.dateArray[0]).isAfter(moment(disableEditUntil)) &&
  scheduleUIState.selectedColumns.some(col => !moment(col).isAfter(moment(disableEditUntil)));

export const checkIfCanDelete = () => (dispatch, getState) => {
  const { mainDateStore, scheduleUIState, locationFilter, settings, currentCompany } = getState().reducer;
  const selectedLocationSettings = settings.locationSettings[locationFilter.selectedLocation.id];
  const disableEditUntil = getDisableEditUntil(scheduleUIState, selectedLocationSettings, currentCompany);

  const canDeleteSelected =
    !isDisabledRowSelected(disableEditUntil, scheduleUIState, mainDateStore) &&
    !isDisabledColumnSelected(disableEditUntil, scheduleUIState, mainDateStore);

  dispatch(setCanDelete(canDeleteSelected));
};

export const getVisibleEmployeesIdsForScheduleView = options => (dispatch, getState) => {
  const { scheduleLocationFilter, scheduleState } = getState().reducer;
  const locationIds = scheduleLocationFilter;
  return getVisibleEmployeeIds(scheduleState, locationIds, options);
};

export const showImportOpenShiftModalForCurrentLocation = () => (dispatch, getState) => {
  const { scheduleLocationFilter } = getState().reducer;
  const locationId = scheduleLocationFilter[0];
  if (!locationId) return;
  dispatch(toggleImportOpenShiftsModal(locationId));
};

export const clearViewEmptySuccess = () => ({
  type: CLEAR_VIEW_SCHEDULE_SUCCESS,
  notification: {
    type: 'success',
    title: 'Obecny widok jest pusty',
  },
});

export const notifyAboutFailureAddManyShifts = amountOfNotAddedShifts => (dispatch, getState, intl) => {
  dispatch({
    type: ADD_MASS_SHIFTS_FAILURE,
    notification: {
      title: intl.formatMessage(scheduleMessages.addMultipleShiftsFailure, { amountOfNotAddedShifts }),
      type: 'error',
    },
  });
};

export const clearViewSuccesful = shifts => (dispatch, getState, intl) => {
  const shiftsAmount = shifts.length;
  dispatch({
    type: CLEAR_VIEW_SCHEDULE_SUCCESS,
    notification: {
      type: 'success',
      title: intl.formatMessage(scheduleMessages.clearViewScheduleTitle),
      description: intl.formatMessage(scheduleMessages.clearViewScheduleDescription, { shiftsAmount }),
    },
  });
};

export const clearViewError = () => ({
  type: CLEAR_VIEW_SCHEDULE_ERROR,
  notification: {
    type: 'error',
    title: 'Coś poszło nie tak!',
    description: 'Nie udało się poprawnie wyczyścić widoku, odśwież stronę, lub skontaktuj się z nami.',
  },
});

export const clearView = () => (dispatch, getState) => {
  const { mainDateStore, userEmployees, scheduleLocationFilter, settings, jobtitleFilter } = getState().reducer;
  const selectedLocationIds = scheduleLocationFilter;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
  const relevantEmployees = userEmployees.filter(e => visibleEmployeeIds.includes(e.id));
  const relevantJobTitleIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

  const allShiftsToClear = selectedLocationIds.reduce((agg, locationId) => {
    const selectedLocationSettings = settings.locationSettings[locationId];
    const shiftEditDisabledUntil = selectedLocationSettings
      ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    const relevantDateArray = getRelevantDateRange(mainDateStore.dateArray, shiftEditDisabledUntil);
    const from = relevantDateArray[0];
    const to = relevantDateArray[relevantDateArray.length - 1];
    const locationIdArray = [locationId];
    // This extracts the necessary shifts with the provided filters.
    const shiftsToClear = extractsShiftsFromEmployees(
      relevantEmployees,
      from,
      to,
      locationIdArray,
      relevantJobTitleIds,
    );
    return [...agg, ...shiftsToClear];
  }, []);

  // We use this array to mass delete shifts.
  // TODO(oskar): Remove? const shiftsToClearIDArray = shiftsToClear.map(shift => shift.id);
  // ASYNC conn
  if (allShiftsToClear.length === 0) {
    dispatch(clearViewEmptySuccess());
    return;
  }
  dispatch(increaseLoaderCounter('blocking'));
  dispatch(deleteShiftArray(allShiftsToClear))
    .then(() => {
      dispatch({ type: CLEAR_VIEW_SCHEDULE_SUCCESS });
      dispatch(clearWorkingRules());
    })
    .finally(() => dispatch(decreaseLoaderCounter('blocking')));
};

export const clearViewBeforeDuplicate = shiftsToClear => async dispatch => {
  const chunkSize = 1000;
  const deletedShifts = [];
  for (let i = 0; i < shiftsToClear.length; i += chunkSize) {
    const chunk = shiftsToClear.slice(i, i + chunkSize);
    try {
      await conn.deleteMassShifts(
        chunk.map(s => s.id),
        'blocking',
      );
      chunk.map(s => {
        dispatch(deleteShift(s.employee.id, s.id, false));
      });
      deletedShifts.push(...chunk);
      await new Promise(resolve => setTimeout(resolve, 500));
    } catch (err) {
      dispatch(clearViewError(err));
    }
  }
  if (deletedShifts.length > 0) {
    dispatch(clearViewSuccesful(deletedShifts));
  }
};

export const duplicatePreviousView = (shiftsToClear, locationId, relevantEmployees, relevantDateArray) => dispatch => {
  dispatch(increaseLoaderCounter('blocking'));

  const target = relevantDateArray[0];
  const from = moment(target).subtract(relevantDateArray.length, 'days').format('YYYY-MM-DD');
  const to = moment(relevantDateArray[relevantDateArray.length - 1])
    .subtract(relevantDateArray.length, 'days')
    .format('YYYY-MM-DD');
  dispatch(clearViewBeforeDuplicate(shiftsToClear))
    .then(() => {
      conn
        .duplicateSchedulePeriod({
          from,
          to,
          target,
          location_id: locationId,
          employees: relevantEmployees.map(e => e.id),
        })
        .then(result => {
          dispatch({ type: DUPLICATE_PREVIOUS_VIEW_SUCCESS, payload: result.data });
          dispatch(decreaseLoaderCounter('blocking'));
        })
        .catch(() => {
          dispatch(decreaseLoaderCounter('blocking'));
        });
    })
    .catch(() => {
      dispatch(decreaseLoaderCounter('blocking'));
    });
};

export const duplicatePreviousViewConfirm = () => (dispatch, getState) => {
  const { userEmployees, mainDateStore, jobtitleFilter, scheduleLocationFilter, settings } = getState().reducer;
  scheduleLocationFilter.forEach(selectedLocationId => {
    const selectedLocationSettings = settings.locationSettings[selectedLocationId];
    const shiftEditDisabledUntil = selectedLocationSettings
      ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    const relevantDateArray = getRelevantDateRange(mainDateStore.dateArray, shiftEditDisabledUntil);

    const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
    const jobtitleIDArray = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);
    const relevantEmployees = userEmployees.filter(e => visibleEmployeeIds.includes(e.id));
    let { shiftsToClear } = extractShiftsForPreviousViewDuplicate(
      relevantEmployees,
      mainDateStore,
      [selectedLocationId],
      jobtitleIDArray,
    );
    shiftsToClear = shiftsToClear.filter(shift => relevantDateArray.includes(shift.date));
    dispatch(duplicatePreviousView(shiftsToClear, selectedLocationId, relevantEmployees, relevantDateArray));
  });
};

export const addMultipleShiftsSuccess = (shiftsData, amountOfPostedShifts) => (dispatch, getState, intl) => {
  const amountOfAddedShifts = shiftsData.length;
  if (amountOfAddedShifts > 0)
    dispatch({
      type: ADD_MASS_SHIFTS_SUCCESFUL,
      payload: shiftsData,
      notification: {
        title: intl.formatMessage(scheduleMessages.addMultipleShiftsSuccess, { amountOfAddedShifts }),
        type: 'success',
      },
    });
  const amountOfNotAddedShifts = amountOfPostedShifts - amountOfAddedShifts;
  if (amountOfNotAddedShifts > 0) dispatch(notifyAboutFailureAddManyShifts(amountOfNotAddedShifts));
};

export const addMultipleShifts =
  (shifts, shiftsNotAdded = 0, requstType = 'blocking') =>
  dispatch =>
    new Promise((resolve, reject) => {
      dispatch(increaseLoaderCounter(requstType));
      conn
        .addMultipeShifts(shifts)
        .then(result => {
          dispatch(addMultipleShiftsSuccess(result.data, shifts.length + shiftsNotAdded));
          dispatch(decreaseLoaderCounter(requstType));
          resolve(result.data);
        })
        .catch(reject);
    });

export const pasteScheduleContent = () => (dispatch, getState) => {
  const { mainDateStore, copyPaste, userEmployees, scheduleUIState, contracts, userJobTitles } = getState().reducer;

  let copyToOtherEmployees = false;
  const allShiftObjectsToAdd = [];
  if (copyPaste.selection.rows.length === 1) {
    const copiedRowEmployeeId = copyPaste.selection.rows[0];
    if (!scheduleUIState.selectedRows.includes(copiedRowEmployeeId) && scheduleUIState.selectedRows.length > 0) {
      copyToOtherEmployees = true;
    }
  }
  if (
    copyPaste.context === 'schedule' &&
    mainDateStore.dateMode === copyPaste.selection.dateStore.dateMode &&
    (mainDateStore.dateArray[0] !== copyPaste.selection.dateStore.dateArray[0] || copyToOtherEmployees)
  ) {
    let shiftsToAdd = [];
    let { dateShiftInt } = mainDateStore;
    const { dateMode } = mainDateStore;
    dateShiftInt -= copyPaste.selection.dateStore.dateShiftInt;
    if (copyPaste.selection.rows.length > 0) {
      const start = copyPaste.selection.dateStore.dateArray[0];
      const end = copyPaste.selection.dateStore.dateArray[copyPaste.selection.dateStore.dateArray.length - 1];

      shiftsToAdd = extractsShiftsFromEmployeesWithLocationFilter(
        userEmployees.filter(
          e => copyPaste.selection.relevantEmployees.includes(e.id) && copyPaste.selection.rows.includes(e.id),
        ),
        start,
        end,
        copyToOtherEmployees
          ? copyPaste.selection.rowsLocationIds[copyPaste.selection.rows[0]]
          : copyPaste.selection.locationId,
      );
    } else {
      shiftsToAdd = extractsShiftsFromEmployeesWithLocationAndDateArrayFilter(
        userEmployees.filter(e => copyPaste.selection.relevantEmployees.includes(e.id)),
        copyPaste.selection.columns,
        copyToOtherEmployees ? copyPaste.selection.rowsLocationIds[copyPaste.rows[0]] : copyPaste.selection.locationId,
      );
    }
    if (copyToOtherEmployees) {
      scheduleUIState.selectedRows.forEach(employeeId => {
        const employee = userEmployees.find(e => e.id === employeeId);
        const employeeContracts = contracts[employeeId];
        if (employee && employeeContracts?.length > 0) {
          for (const shift of shiftsToAdd) {
            const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
            const relevantJobTitleIds = getJobTitleIdsFromContracts([relevantContract]);
            let jobTitle = null;
            if (relevantJobTitleIds.includes(shift.job_title.id)) {
              jobTitle = shift.job_title;
            } else {
              jobTitle = userJobTitles.find(j => String(j.id) === relevantJobTitleIds[0]);
            }
            const newShift = { ...shift };
            newShift.date = moment(shift.date).add(dateShiftInt, dateMode).format('YYYY-MM-DD');
            newShift.draft = true;
            newShift.employee = { id: employee.id };
            newShift.job_title = jobTitle;
            newShift.location = { id: scheduleUIState.selectedRowsLocationsIds[employeeId] };
            allShiftObjectsToAdd.push(newShift);
          }
        }
      });
    } else {
      for (const shift of shiftsToAdd) {
        const newShift = { ...shift };
        newShift.date = moment(shift.date).add(dateShiftInt, dateMode).format('YYYY-MM-DD');
        newShift.draft = true;
        allShiftObjectsToAdd.push(newShift);
      }
    }
  }

  dispatch(addMultipleShifts(allShiftObjectsToAdd));
};

export const deleteAllSelectedSchedule = (relevantEmployees, locationId) => (dispatch, getState) => {
  const { scheduleUIState, mainDateStore } = getState().reducer;
  dispatch(increaseLoaderCounter('blocking'));

  let shiftsToDelete = [];
  if (scheduleUIState.selectedRows.length > 0) {
    // Shifts to delete from selected rows
    shiftsToDelete = extractsShiftsFromEmployeesWithLocationFilter(
      relevantEmployees.filter(s => scheduleUIState.selectedRows.includes(s.id)),
      mainDateStore.dateArray[0],
      mainDateStore.dateArray[mainDateStore.dateArray.length - 1],
      locationId,
    );
  } else {
    // Shifts to delete from selected columns
    shiftsToDelete = extractShiftsFromSelectedColumns(relevantEmployees, scheduleUIState.selectedColumns, locationId);
  }
  if (shiftsToDelete.length > 0) {
    dispatch(deleteShiftArray(shiftsToDelete, 'blocking')).then(() => {
      const employeeIdsForWorkingRules = shiftsToDelete.reduce(
        (employeeIds, shift) =>
          employeeIds.includes(shift.employee.id) ? employeeIds : [...employeeIds, shift.employee.id],
        [],
      );
      dispatch(
        getWorkingRulesViolationsForCurrentDateArray(employeeIdsForWorkingRules, false, { mergeWorkingRules: true }),
      );
    });
  }
  dispatch(decreaseLoaderCounter('blocking'));
};

export const deselectAllEmployeesRows = () => dispatch => {
  dispatch({
    type: DESELECT_ALL_SCHEDULE,
  });
  dispatch(checkIfCanPaste());
  dispatch(checkIfCanDelete());
};

export const changeScheduleMode = mode => (dispatch, getState) => {
  const { reducer } = getState();
  const oldMode = reducer.scheduleUIState.selectedDisplayMode.type;
  const defaultDateStore = reducer.uiState.defaultDateStores['/schedule'];
  const { mainDateStore } = reducer;
  dispatch({
    type: CHANGE_SCHEDULE_MODE,
    mode,
  });
  if (mode.type === 'templates') {
    dispatch(changeDefaultViewDateStore('/schedule', mainDateStore));
    dispatch(mainDateChangeMode('week'));
  }
  if (oldMode === 'templates') {
    dispatch(setMainDateStore(defaultDateStore));
    const { dateArray } = defaultDateStore;
    const dates =
      defaultDateStore.dateMode === 'custom'
        ? defaultDateStore.customDate
        : { start: dateArray[0], end: dateArray[dateArray.length - 1] };
    dispatch(getNewDataForDate(dates.start, dates.end));
  }
  dispatch(checkIfCanPaste());
  dispatch(checkIfCanDelete());
};

export const flipScheduleMode = () => dispatch => {
  dispatch({
    type: FLIP_SCHEDULE_MODE,
  });
  dispatch(checkIfCanPaste());
  dispatch(checkIfCanDelete());
};

export const toggleScheduleSettings = settingObject => (dispatch, getState) => {
  if (settingObject.type === 'workingRules' && !settingObject.value) {
    dispatch(getWorkingRulesViolationsForCurrentDateArray());
  }
  const scheduleSettings = [
    ...getState().reducer.scheduleUIState.settings.map(setting => {
      if (setting.type === settingObject.type) {
        return {
          ...setting,
          value: !setting.value,
        };
      }
      return {
        ...setting,
      };
    }),
  ];
  const scheduleSettingsKey = getScheduleSettingsKey();
  localStorage.setItem(scheduleSettingsKey, JSON.stringify(scheduleSettings));
  dispatch({
    type: TOGGLE_SCHEDULE_SETTING,
    payload: scheduleSettings,
  });
};

export const toggleAllScheduleSettings = scheduleSettings => (dispatch, getState) => {
  const scheduleSettingsFromStore = getState().reducer.scheduleUIState.settings;
  const settingValue = !scheduleSettings.every(({ value }) => value);

  const relevantScheduleSettings = scheduleSettingsFromStore.map(setting => {
    if (setting.type === 'workingRules' && !setting.value) {
      dispatch(getWorkingRulesViolationsForCurrentDateArray());
    }
    const matchedScheduleSetting = scheduleSettings.find(({ type }) => type === setting.type);
    if (!matchedScheduleSetting) return setting;

    return { ...matchedScheduleSetting, value: settingValue };
  });
  const scheduleSettingsKey = getScheduleSettingsKey();
  localStorage.setItem(scheduleSettingsKey, JSON.stringify(relevantScheduleSettings));

  dispatch({
    type: TOGGLE_SCHEDULE_SETTING,
    payload: relevantScheduleSettings,
  });
};

export const turnScheduleSettingsOn = settingsObject => (dispatch, getState) => {
  const settingsTypes = settingsObject.map(setting => setting.type);
  const { settings } = getState().reducer.scheduleUIState;
  const scheduleSettings = settings.map(setting => {
    if (settingsTypes.includes(setting.type) && !setting.value) {
      return {
        ...setting,
        value: true,
      };
    }
    return setting;
  });
  const scheduleSettingsKey = getScheduleSettingsKey();
  localStorage.setItem(scheduleSettingsKey, JSON.stringify(scheduleSettings));
  dispatch({
    type: TURN_SCHEDULE_SETTINGS_ON,
    payload: scheduleSettings,
  });
};

export const toggleSettingByType = settingType => (dispatch, getState) => {
  const { settings } = getState().reducer.scheduleUIState;
  const setting = settings.find(({ type }) => settingType === type);
  dispatch(toggleScheduleSettings(setting));
};

export const moveSelectedColumns = (dateShiftInt, dateMode) => ({
  type: MOVE_SELECTED_COLUMNS,
  dateShiftInt,
  dateMode,
});

export const toggleScheduleColumn = date => dispatch => {
  dispatch({
    type: TOGGLE_SCHEDULE_COLUMN,
    payload: date,
  });
  dispatch(checkIfCanPaste());
  dispatch(checkIfCanDelete());
};
export const toggleScheduleRow = (employeeId, locationId) => dispatch => {
  dispatch({
    type: TOGGLE_SCHEDULE_ROW,
    payload: { employeeId, locationId },
  });
  dispatch(checkIfCanPaste());
  dispatch(checkIfCanDelete());
};

export const deleteSelectedShifts = relevantEmployees => (dispatch, getState) => {
  const { locationFilter, uiState, mainDateStore } = getState().reducer;
  const title = <FormattedMessage {...scheduleMessages.deleteSelectedShiftsConfirmationTitle} />;
  const description = <FormattedMessage {...scheduleMessages.deleteSelectedShiftsConfirmationDesc} />;
  const confirmText = <FormattedMessage {...scheduleMessages.delete} />;
  const confirmFunc = () => {
    dispatch(deleteAllSelectedSchedule(relevantEmployees, locationFilter.selectedLocation.id));
    if (uiState.selectedOpenShiftRowLocationId) {
      const { dateArray } = mainDateStore;
      const start = dateArray[0];
      const end = dateArray[dateArray.length - 1];
      dispatch(massDeleteOpenShifts([uiState.selectedOpenShiftRowLocationId], start, end));
    }
  };
  const confirmType = 'warning';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

export const pasteShifts = () => dispatch => {
  const title = <FormattedMessage {...scheduleMessages.pasteShiftsConfirmationTitle} />;
  const description = <FormattedMessage {...scheduleMessages.pasteShiftsConfirmationDesc} />;
  const confirmText = <FormattedMessage {...scheduleMessages.paste} />;
  const confirmType = 'warning';
  const confirmFunc = () => {
    dispatch(pasteScheduleContent());
  };
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

export const copy = () => (dispatch, getState) => {
  const { scheduleUIState, userTemplates } = getState().reducer;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
  switch (scheduleUIState.selectedDisplayMode.type) {
    case 'schedule':
      dispatch(copyShifts(visibleEmployeeIds));
      break;
    case 'availabilities':
      dispatch(copyAvailabilities(visibleEmployeeIds));
      break;
    case 'templates':
      if (userTemplates.currentTemplate.type === TEMPLATE_TYPES.FLEX) {
        dispatch(copyFlexSchedule());
      }
      break;
    default:
      break;
  }
};

export const pasteAvailabilitiesContent = () => (dispatch, getState) => {
  dispatch(increaseLoaderCounter('blocking'));
  const { mainDateStore, copyPaste, userEmployees, currentCompany, userCustomTypes, scheduleUIState, contracts } =
    getState().reducer;

  if (!copyPaste.canPaste) return;
  const {
    rows: copiedRows,
    columns: copiedColumns,
    dateStore: copyDateStore,
    locationId,
    relevantJobTitles,
    relevantEmployees,
  } = copyPaste.selection;
  const { selectedRows } = scheduleUIState;

  const start = copyDateStore.dateArray[0];
  const end = copyDateStore.dateArray[copyDateStore.dateArray.length - 1];
  const timeDiff = mainDateStore.dateShiftInt - copyDateStore.dateShiftInt;

  let availabilitiesToAdd = [];
  let employees = [];
  const copyRows = copiedRows.length > 0;
  const copyColumns = copiedColumns.length > 0;

  if (copyRows) {
    employees = userEmployees.filter(e => copiedRows.includes(e.id));
    availabilitiesToAdd = extractAvailabilitiesFromEmployeesWithFilters(
      employees,
      start,
      end,
      [locationId],
      relevantJobTitles,
      contracts,
    );
    // We wanted to allow copping shifts and attendances between single employees
    if (copiedRows.length === 1 && selectedRows.length === 1 && copiedRows[0] !== selectedRows[0]) {
      availabilitiesToAdd = availabilitiesToAdd.map(a => ({ ...a, employee: { id: selectedRows[0] } }));
    }
  } else if (copyColumns) {
    employees = userEmployees.filter(e => relevantEmployees.includes(e.id));
    availabilitiesToAdd = extractAvailabilitiesFromEmployeesWithFiltersAndDateArray(
      employees,
      copiedColumns,
      [locationId],
      relevantJobTitles,
      contracts,
    );
  }
  availabilitiesToAdd.forEach(availability => {
    const customType = userCustomTypes.find(type => type.id === availability.type_id);
    let draft = false;
    if (customType) draft = customType.requires_approval;
    const newAvailability = {
      ...availability,
      draft,
      date: moment(availability.date).add(timeDiff, copyDateStore.dateMode).format('YYYY-MM-DD'),
      company_id: { id: currentCompany.id },
    };
    let availabilityToDelete = null;
    employees.map(employee => {
      if (employee.id === newAvailability.employee.id) {
        availabilityToDelete = employee.availability_blocks
          .filter(a => newAvailability.date === a.date)
          .map(a => ({ ...a, employee: { id: employee.id } }));
      }
      return null;
    });

    if (availabilityToDelete && availabilityToDelete.length > 0) {
      const toDelete = availabilityToDelete[0];
      dispatch(deleteAvailability(toDelete.employee.id, toDelete, 'blocking')).then(() => {
        dispatch(addAvailability(newAvailability, false));
      });
    } else {
      dispatch(addAvailability(newAvailability, false));
    }

    return null;
  });
  dispatch(decreaseLoaderCounter('blocking'));
};

export const deleteAllSelectedAvailabilities = (relevantEmployees, locationId) => (dispatch, getState) => {
  dispatch(increaseLoaderCounter('blocking'));
  const { mainDateStore, scheduleUIState, jobtitleFilter, contracts } = getState().reducer;
  const { selectedRows, selectedColumns } = scheduleUIState;
  const start = mainDateStore.dateArray[0];
  const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
  let availabilitiesToDelete = [];
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

  if (selectedRows.length > 0) {
    availabilitiesToDelete = extractAvailabilitiesFromEmployeesWithFilters(
      relevantEmployees.filter(e => selectedRows.includes(e.id)),
      start,
      end,
      [locationId],
      jobTitlesIds,
      contracts,
    );
  } else if (selectedColumns.length > 0) {
    availabilitiesToDelete = extractAvailabilitiesFromEmployeesWithFiltersAndDateArray(
      relevantEmployees,
      selectedColumns,
      [locationId],
      jobTitlesIds,
      contracts,
    );
  }

  availabilitiesToDelete.map(availability => {
    dispatch(deleteAvailability(availability.employee.id, availability, 'blocking'));
    return null;
  });
  dispatch(decreaseLoaderCounter('blocking'));
};

export const deleteSelectedAvailabilities = relevantEmployees => (dispatch, getState) => {
  const { locationFilter } = getState().reducer;
  const title = <FormattedMessage {...scheduleMessages.deleteSelectedAvailabilitiesConfirmTitle} />;
  const description = <FormattedMessage {...scheduleMessages.deleteSelectedAvailabilitiesConfirmDesc} />;
  const confirmText = <FormattedMessage {...scheduleMessages.delete} />;
  const confirmFunc = () => {
    dispatch(deleteAllSelectedAvailabilities(relevantEmployees, locationFilter.selectedLocation.id));
  };

  const confirmType = 'warning';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

export const confirmDraftAvailabilities = () => (dispatch, getState) => {
  const { mainDateStore, jobtitleFilter, userEmployees, contracts, scheduleLocationFilter } = getState().reducer;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
  const start = mainDateStore.dateArray[0];
  const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);
  const drafts = extractAvailabilityDraftsFromEmployeesWithFilters(
    userEmployees.filter(e => visibleEmployeeIds.includes(e.id.toString())),
    start,
    end,
    scheduleLocationFilter,
    jobTitlesIds,
    contracts,
  );
  drafts.map(draft => {
    const availability = draft;
    availability.draft = false;
    dispatch(confirmAvailability(availability));
    return null;
  });
};

export const deleteSelectedDraftAvailabilities = () => (dispatch, getState) => {
  dispatch(increaseLoaderCounter('blocking'));
  const { mainDateStore, userEmployees, jobtitleFilter, locationFilter, contracts } = getState().reducer;
  const start = mainDateStore.dateArray[0];
  const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

  const availabilitiesToDelete = extractAvailabilitiesFromEmployeesWithFilters(
    userEmployees,
    start,
    end,
    [locationFilter.selectedLocation.id],
    jobTitlesIds,
    contracts,
  ).filter(a => a.draft === true);

  availabilitiesToDelete.map(availability => {
    dispatch(deleteAvailability(availability.employee.id, availability, 'blocking'));
    return null;
  });
  dispatch(decreaseLoaderCounter('blocking'));
};

export const clearAvailabilityView = () => (dispatch, getState) =>
  new Promise(resolve => {
    dispatch(increaseLoaderCounter('blocking'));
    const { mainDateStore, userEmployees, jobtitleFilter, locationFilter, contracts } = getState().reducer;
    const start = mainDateStore.dateArray[0];
    const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

    const availabilitiesToDelete = extractAvailabilitiesFromEmployeesWithFilters(
      userEmployees,
      start,
      end,
      [locationFilter.selectedLocation.id],
      jobTitlesIds,
      contracts,
    );

    availabilitiesToDelete.map(availability => {
      dispatch(deleteAvailability(availability.employee.id, availability, 'blocking'));
      return null;
    });
    dispatch(decreaseLoaderCounter('blocking'));
    resolve();
  });

export const duplicatePreviousAvailabilityView = () => (dispatch, getState) => {
  dispatch(clearAvailabilityView()).then(() => {
    dispatch(increaseLoaderCounter('blocking'));
    const { mainDateStore, userEmployees, jobtitleFilter, locationFilter, userCustomTypes, contracts } =
      getState().reducer;
    const start = moment(mainDateStore.dateArray[0]).add(-1, mainDateStore.dateMode).format('YYYY-MM-DD');
    const end = moment(mainDateStore.dateArray[mainDateStore.dateArray.length - 1])
      .add(-1, mainDateStore.dateMode)
      .format('YYYY-MM-DD');
    const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

    const availabilitiesToCopy = extractAvailabilitiesFromEmployeesWithFilters(
      userEmployees,
      start,
      end,
      [locationFilter.selectedLocation.id],
      jobTitlesIds,
      contracts,
    );
    availabilitiesToCopy.map(availability => {
      const customType = userCustomTypes.find(type => type.id === availability.type_id);
      let draft = false;
      if (customType) draft = customType.requires_approval;
      const newAvailability = {
        ...availability,
        draft,
        date: moment(availability.date).add(1, mainDateStore.dateMode).format('YYYY-MM-DD'),
      };
      dispatch(addAvailability(newAvailability, false, 'blocking'));
      return null;
    });
    dispatch(decreaseLoaderCounter('blocking'));
  });
};

export const deleteSelected = () => (dispatch, getState) => {
  const { scheduleUIState, userEmployees } = getState().reducer;
  const visibleEmployeeIds = dispatch(getVisibleEmployeesIdsForScheduleView());
  const data = visibleEmployeeIds.map(id => userEmployees.find(e => e.id === id)).filter(e => !!e);
  switch (scheduleUIState.selectedDisplayMode.type) {
    case 'schedule':
      dispatch(deleteSelectedShifts(data));
      break;
    case 'availabilities':
      dispatch(deleteSelectedAvailabilities(data));
      break;
    default:
      break;
  }
};
export const paste = () => (dispatch, getState) => {
  const { scheduleUIState } = getState().reducer;

  switch (scheduleUIState.selectedDisplayMode.type) {
    case 'schedule':
      dispatch(pasteShifts());
      break;
    case 'availabilities':
      dispatch(pasteAvailabilitiesContent());
      break;
    case 'templates':
      dispatch(pasteFlexSchedule());
      break;
    default:
      break;
  }
};

export const clearAvailabilityViewForSingleEmployee = employee => (dispatch, getState) =>
  new Promise(resolve => {
    dispatch(increaseLoaderCounter('blocking'));
    const { mainDateStore } = getState().reducer;
    const start = mainDateStore.dateArray[0];
    const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    const availabilitiesToDelete = extractAvailabilitiesFromEmployees([employee], start, end);
    availabilitiesToDelete.map(availability => {
      dispatch(deleteAvailability(availability.employee.id, availability, 'blocking'));
      return null;
    });
    dispatch(decreaseLoaderCounter('blocking'));
    resolve();
  });

export const duplicatePreviousAvailabilityViewForSingleEmployee = employee => (dispatch, getState) => {
  dispatch(clearAvailabilityViewForSingleEmployee(employee)).then(() => {
    dispatch(increaseLoaderCounter('blocking'));
    const { mainDateStore, userCustomTypes, locationFilter, userPermissions, currentUser } = getState().reducer;
    const start = moment(mainDateStore.dateArray[0]).add(-1, mainDateStore.dateMode).format('YYYY-MM-DD');
    const end = moment(mainDateStore.dateArray[mainDateStore.dateArray.length - 1])
      .add(-1, mainDateStore.dateMode)
      .format('YYYY-MM-DD');

    let availabilitiesToCopy = [];
    const getAvailabilities = new Promise(resolve => {
      if (userPermissions.isEmployee) {
        conn
          .getSchedule(locationFilter.selectedLocation.id, start, end, 'blocking', true)
          .then(response => {
            availabilitiesToCopy = extractAvailabilitiesFromEmployees(
              [response.data.schedule.employees.find(emp => emp.id === currentUser.user.id)],
              start,
              end,
            );
            resolve();
          })
          .catch(err => {
            throw err;
          });
      } else {
        availabilitiesToCopy = extractAvailabilitiesFromEmployees([employee], start, end);
        resolve();
      }
    });

    getAvailabilities.then(() => {
      availabilitiesToCopy.map(availability => {
        const customType = userCustomTypes.find(type => type.id === availability.type_id);
        let draft = false;
        if (customType) draft = customType.requires_approval;
        const newAvailability = {
          ...availability,
          draft,
          date: moment(availability.date).add(1, mainDateStore.dateMode).format('YYYY-MM-DD'),
        };
        dispatch(addAvailability(newAvailability, false, 'blocking'));
        return null;
      });
    });
    dispatch(decreaseLoaderCounter('blocking'));
  });
};

export const deleteEmployeesDraftAvailabilities = employee => (dispatch, getState) => {
  dispatch(increaseLoaderCounter('blocking'));
  const { mainDateStore, jobtitleFilter, locationFilter, contracts } = getState().reducer;
  const start = mainDateStore.dateArray[0];
  const end = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
  const jobTitlesIds = jobtitleFilter.selectedJobtitlesGrouped.map(j => j.id);

  const availabilitiesToDelete = extractAvailabilitiesFromEmployeesWithFilters(
    [employee],
    start,
    end,
    [locationFilter.selectedLocation.id],
    jobTitlesIds,
    contracts,
  ).filter(a => a.draft === true);

  availabilitiesToDelete.map(availability => {
    dispatch(deleteAvailability(availability.employee.id, availability, 'blocking'));
    return null;
  });
  dispatch(decreaseLoaderCounter('blocking'));
};

export const deleteAvailabilityConfirm = (employeeId, availabilityObject) => dispatch => {
  const title = <FormattedMessage {...scheduleMessages.deleteAvailabilityConfirmTitle} />;
  const description = <FormattedMessage {...scheduleMessages.deleteAvailabilityConfirmDesc} />;
  const confirmText = <FormattedMessage {...scheduleMessages.delete} />;
  const confirmFunc = () => {
    dispatch(deleteAvailability(employeeId, availabilityObject));
  };

  const confirmType = 'info';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

export const highlightScheduleRow =
  (employeeId, timeout = 5000) =>
  dispatch => {
    dispatch(hideModal());
    setTimeout(() => {
      dispatch(toggleScheduleRow(employeeId));
    }, 100);

    if (timeout !== 0) {
      setTimeout(() => {
        dispatch(toggleScheduleRow(employeeId));
      }, timeout);
    }
  };

export const scheduleActions =
  (type, options = {}) =>
  dispatch => {
    switch (type) {
      case SCHEDULE_DISPLAY_MODE_TYPE:
        return [
          {
            label: <FormattedMessage {...scheduleMessages.deleteUnpublishedShifts} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.deleteUnpublishedShiftsModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.deleteUnpublishedShiftsModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.deleteUnpublishedShiftsModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(deleteUnpublishedShifts());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDeleteUnpublishedShifts,
          },
          {
            label: <FormattedMessage id="schedule.action.clearView" defaultMessage="Wyczyść bieżący widok" />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.clearViewModalTitle} />,
                  description: <ClearViewConfirmationModalDescription />,
                  confirmText: <FormattedMessage {...scheduleMessages.clearViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(clearView());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableClearView,
          },
          {
            label: <FormattedMessage id="schedule.action.duplicateView" defaultMessage="Duplikuj poprzedni okres" />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.duplicateViewModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.duplicateViewModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.duplicateViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(duplicatePreviousViewConfirm());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDuplicateView,
          },
          {
            label: <FormattedMessage id="schedule.importBudget" defaultMessage="Importuj budżet" />,
            action: () => {
              dispatch(showModal(BUDGET_IMPORT_MODAL));
            },
            permission: BUDGET_ESTIMATES_EDIT,
            restriction: BUDGET_INFO_HIDE,
            disabled: options.disableImportBudget,
          },
          {
            label: <FormattedMessage id="schedule.loadBudgetTarget" defaultMessage="Wczytaj targety budżetu" />,
            action: () => {
              dispatch(showModal(LOAD_BUDGET_TARGET_MODAL));
            },
            restrictions: [
              RESTRICTIONS.BUDGET_TARGETS_HIDE,
              RESTRICTIONS.BUDGET_INFO_HIDE,
              RESTRICTIONS.BUDGET_TARGETS_DISABLE_EDIT,
              RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT,
            ],
            disabled: options.disableLoadBudgetTarget,
          },
          {
            label: (
              <FormattedMessage id="schedule.importRecommendedSchedule" defaultMessage="Wczytaj prognozowany grafik" />
            ),
            action: () => {
              dispatch(showModal(LOAD_RECOMMENDED_SCHEDULE_MODAL));
            },
            restrictions: [
              RESTRICTIONS.RECOMMENDED_SCHEDULE_HIDE,
              RESTRICTIONS.BUDGET_INFO_HIDE,
              RESTRICTIONS.RECOMMENDED_SCHEDULE_DISABLE_EDIT,
              RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT,
            ],
            disabled: options.disableImportRecommendedSchedule,
          },
          {
            label: <FormattedMessage id="schedule.importSchedule" defaultMessage="Importuj grafik" />,
            action: () => {
              dispatch(showModal(SCHEDULE_IMPORT_MODAL));
            },
            disabled: options.disableImportSchedule,
          },
          {
            label: <FormattedMessage id="export.toFile" defaultMessage="Eksportuj do pliku" />,
            action: () => {
              dispatch(toggleExportModal());
            },
            disabled: options.disableExport,
          },
          {
            label: (
              <FormattedMessage
                id="schedule.action.saveScheduleAsTemplate"
                defaultMessage="Zapisz aktualny grafik jako szablon"
              />
            ),
            action: () => {
              dispatch(showModal(SAVE_SCHEDULE_AS_TEMPLATE_MODAL));
            },
            disabled: options.disableSaveScheduleAsTemplate,
          },
          {
            label: (
              <FormattedMessage
                id="schedule.action.addOpenShiftsFromTemplate"
                defaultMessage="Dodaj otwarte zmiany z szablonu"
              />
            ),
            action: () => {
              dispatch(showImportOpenShiftModalForCurrentLocation());
            },
            disabled: options.disableImportOpenShifts,
            restriction: FREEMIUM_HIDE_OPEN_SHIFTS,
          },
          {
            label: (
              <FormattedMessage
                id="schedule.action.autoGenerateScheduleForOpenShifts"
                defaultMessage="Przypisz automatycznie otwarte zmiany"
              />
            ),
            action: () => {
              dispatch(showAutoGenerateStepsModalWithOpenShiftsAsTemplate());
            },
            disabled: options.disableAutoGenerateScheduleForOpenShifts,
            hide: options.hideAutoGenerateScheduleForOpenShifts,
          },
          {
            label: (
              <FormattedMessage
                id="schedule.action.importBudgetMetricsData"
                defaultMessage="Importuj metryki budżetu"
              />
            ),
            action: () => {
              dispatch(showModal(BUDGET_METRICS_IMPORT_MODAL));
            },
            disabled: options.disableImportBudgetMetricsData,
            restrictions: [
              RESTRICTIONS.BUDGET_TARGETS_DISABLE_EDIT,
              RESTRICTIONS.BUDGET_TARGETS_HIDE,
              RESTRICTIONS.BUDGET_INFO_HIDE,
              RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT,
            ],
            permission: BUDGET_METRICS_ENABLE,
          },
        ];
      case AVAILABILITIES_DISPLAY_MODE_TYPE:
        return [
          {
            label: <FormattedMessage {...scheduleMessages.deleteDraftAvailabilities} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.deleteDraftAvailabilitiesModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.deleteDraftAvailabilitiesModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.deleteDraftAvailabilitiesModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(deleteSelectedDraftAvailabilities());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDeleteDraftAvailabilities,
          },
          {
            label: <FormattedMessage {...scheduleMessages.clearView} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.clearViewModalTitle} />,
                  description: (
                    <span style={{ textAlign: 'center', display: 'inline-block', whiteSpace: 'pre-wrap' }}>
                      <i className="material-icons" style={{ fontSize: '120px', color: '#e43a3a' }}>
                        error_outline
                      </i>
                      <br />
                      <strong>
                        <FormattedMessage {...scheduleMessages.clearAvailabilityViewModalDescription} />{' '}
                      </strong>
                    </span>
                  ),
                  confirmText: <FormattedMessage {...scheduleMessages.clearViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(clearAvailabilityView());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableClearViewForUser,
          },
          {
            label: <FormattedMessage {...scheduleMessages.duplicateView} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.duplicateViewModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.duplicateViewModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.duplicateViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(duplicatePreviousAvailabilityView());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDuplicateViewAvailabilities,
          },
          {
            label: <FormattedMessage {...scheduleMessages.importBudget} />,
            action: () => {
              dispatch(showModal(BUDGET_IMPORT_MODAL));
            },
            disabled: options.disableImportBudgetAvailabilities,
            permission: BUDGET_ESTIMATES_EDIT,
            restriction: BUDGET_INFO_HIDE,
          },
          {
            label: <FormattedMessage {...scheduleMessages.exportData} />,
            action: () => {
              dispatch(toggleExportModal());
            },
          },
        ];
      case POSITIONS_DISPLAY_MODE_TYPE:
        return [
          {
            label: <FormattedMessage {...scheduleMessages.clearView} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.clearViewModalTitle} />,
                  description: (
                    <span style={{ textAlign: 'center', display: 'inline-block', whiteSpace: 'pre-wrap' }}>
                      <i className="material-icons" style={{ fontSize: '120px', color: '#e43a3a' }}>
                        error_outline
                      </i>
                      <br />
                      <strong>
                        <FormattedMessage {...scheduleMessages.clearViewModalDescription} />
                      </strong>
                    </span>
                  ),
                  confirmText: <FormattedMessage {...scheduleMessages.clearViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(clearView());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableClearViewForUser,
          },
          {
            label: <FormattedMessage {...scheduleMessages.duplicateView} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.duplicateViewModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.duplicateViewModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.duplicateViewModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(duplicatePreviousViewConfirm());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDuplicateViewPositions,
          },
          {
            label: <FormattedMessage {...scheduleMessages.importBudget} />,
            action: () => {
              dispatch(showModal(BUDGET_IMPORT_MODAL));
            },
            disabled: options.disableImportBudgetPositions,
            permission: BUDGET_ESTIMATES_EDIT,
            restriction: BUDGET_INFO_HIDE,
          },
        ];
      case TEMPLATES_DISPLAY_MODE_TYPE:
        return [
          {
            label: <FormattedMessage {...scheduleMessages.deleteDraftTemplateShifts} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.deleteDraftTemplateShiftsModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.deleteDraftTemplateShiftsModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.deleteDraftTemplateShiftsModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(deleteUnpublishedTemplateShifts());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableDeleteDraftTemplateShifts,
          },
          {
            label: <FormattedMessage {...scheduleMessages.addNewTemplate} />,
            action: () => {
              dispatch(showModal(ADD_TEMPLATE_MODAL));
            },
          },
          {
            label: <FormattedMessage {...scheduleMessages.deleteCurrentTemplate} />,
            disabled: options.disableDeleteCurrentTemplate,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.deleteCurrentTemplateModalTitle} />,
                  description: <FormattedMessage {...scheduleMessages.deleteCurrentTemplateModalDescription} />,
                  confirmText: <FormattedMessage {...scheduleMessages.deleteCurrentTemplateModalButtonText} />,
                  confirmFunc: () => {
                    dispatch(deleteTemplate());
                  },
                  confirmType: 'warning',
                }),
              );
            },
          },
          {
            label: <FormattedMessage {...scheduleMessages.clearView} />,
            action: () => {
              dispatch(
                showConfirmModal({
                  title: <FormattedMessage {...scheduleMessages.clearViewModalTitle} />,
                  description: (
                    <span style={{ textAlign: 'center', display: 'inline-block', whiteSpace: 'pre-wrap' }}>
                      <i className="material-icons" style={{ fontSize: '120px', color: '#e43a3a' }}>
                        error_outline
                      </i>
                      <br />
                      <strong>
                        <FormattedMessage {...scheduleMessages.clearCurrentTemplate} />{' '}
                      </strong>
                    </span>
                  ),
                  confirmText: <FormattedMessage {...scheduleMessages.clearCurrentTemplateConfimButton} />,
                  confirmFunc: () => {
                    dispatch(deleteCurrentTemplateShifts());
                  },
                  confirmType: 'warning',
                }),
              );
            },
            disabled: options.disableClearView,
          },
        ];
      default:
        return [];
    }
  };

export const checkIfShiftsOverlapAction = (employeeId, shift, shiftIds) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const employee = userEmployees.find(e => e.id === employeeId);
  if (!employee) return false;
  return checkShiftOverlap(employee, shift, shiftIds);
};

export const toggleNonWorkingDays = value => dispatch => {
  dispatch({
    type: TOGGLE_NON_WORKING_DAYS,
    payload: value,
  });
};

export const importScheduleData = data => ({
  type: IMPORT_SCHEDULE_DATA,
  payload: data,
});

export const clearImportModal = () => ({
  type: CLEAR_IMPORT_MODAL,
});

export const uploadFileToImport = file => ({
  type: UPLOAD_FILE_TO_IMPORT,
  payload: file,
});

export const toggleImportModalOverlay = value => ({
  type: TOGGLE_IMPORT_MODAL_OVERLAY,
  payload: value,
});

export const changeScheduleTableSize = size => ({
  type: CHANGE_SCHEDULE_TABLE_SIZE,
  payload: size,
});
export const toggleImportBudgetModalOverlay = value => ({
  type: TOGGLE_IMPORT_BUDGET_MODAL_OVERLAY,
  payload: value,
});

export const toggleImportBudgetModalInputError = value => ({
  type: TOGGLE_IMPORT_BUDGET_MODAL_ERROR,
  payload: value,
});

export const changeSelectedDay = selected => dispatch => {
  dispatch({
    type: FLEX_SCHEDULE_SELECTED_DAY_CHANGED,
    payload: selected,
  });
};
export const changeHourValue = (value, hour, jobTitleId, selectedDay) => dispatch => {
  dispatch({
    type: FLEX_SCHEDULE_DATA_CHANGED,
    payload: { value, hour, jobTitleId, selectedDay },
  });
};
