import {
  CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL,
  CHANGE_TRADE_SHIFT_SUCCESFUL,
  CLOSE_TRADE_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL,
  CREATE_TRADE_SHIFT_SUCCESFUL,
  DELETE_TRADE_SHIFT_SUCCESFUL,
  GET_TRADE_SHIFTS_SUCCESFUL,
} from '@/constants/ActionTypes.js';
import { handleAddShiftFailureErrorMessage } from '@/utils/shiftHelpers.js';

import { conn, connectionError } from './index';

export const createTradeShiftSuccesful = (tradeShift) => ({
  type: CREATE_TRADE_SHIFT_SUCCESFUL,
  payload: tradeShift,
});

export const changeTradeShiftSuccesful = (tradeShift) => ({
  type: CHANGE_TRADE_SHIFT_SUCCESFUL,
  payload: tradeShift,
});

export const deleteTradeShiftSuccesful = (tradeShiftId) => ({
  type: DELETE_TRADE_SHIFT_SUCCESFUL,
  payload: tradeShiftId,
});

export const getTradeShiftsSuccesful = (tradeShiftsArray) => ({
  type: GET_TRADE_SHIFTS_SUCCESFUL,
  payload: tradeShiftsArray,
});

export const closeTradeShiftSuccesful = (tradeShift, employeeId) => ({
  type: CLOSE_TRADE_SHIFT_SUCCESFUL,
  payload: {
    tradeShift,
    employeeId,
  },
});

export const changeStatusTradeShiftSuccesful = (tradeShiftId, coworkerId, status) => ({
  type: CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL,
  payload: {
    tradeShiftId,
    coworkerId,
    status,
  },
});

export const createOpenShiftFromTradeShiftSuccesful = (tradeShiftId, openShift) => (dispatch, getState) => {
  const { userJobTitles } = getState().reducer;

  const jobTitle = userJobTitles.find(({ id }) => id === openShift.job_title.id);

  const formatOpenShift = { ...openShift, job_title: { ...openShift.job_title, color: jobTitle.color } };

  dispatch({
    type: CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL,
    payload: {
      tradeShiftId,
      openShift: formatOpenShift,
    },
  });
};

export const createTradeShift = (tradeShift) => (dispatch) => {
  conn
    .createTradeShift(tradeShift)
    .then((result) => {
      dispatch(createTradeShiftSuccesful(result.data.tradeShift));
    })
    .catch((err) => {
      dispatch(connectionError(err));
    });
};

export const changeTradeShift = (tradeShiftId, tradeShift) => (dispatch) => {
  conn
    .changeTradeShift(tradeShiftId, tradeShift)
    .then((result) => {
      dispatch(changeTradeShiftSuccesful(result.data.trade_shift));
    })
    .catch((err) => {
      dispatch(connectionError(err));
    });
};

export const deleteTradeShift = (tradeShiftId) => (dispatch) => {
  conn
    .deleteTradeShift(tradeShiftId)
    .then(() => {
      dispatch(deleteTradeShiftSuccesful(tradeShiftId));
    })
    .catch((err) => {
      dispatch(connectionError(err));
    });
};

export const massDeleteTradeShifts = (tradeShiftIds) => (dispatch) => {
  conn
    .massDeleteTradeShifts(tradeShiftIds)
    .then(() => {
      tradeShiftIds.forEach((tradeShiftId) => dispatch(deleteTradeShiftSuccesful(tradeShiftId)));
    })
    .catch((err) => {
      dispatch(connectionError(err));
    });
};

export const closeTradeShift = (tradeShift, employeeId) => (dispatch, _, intl) => {
  conn
    .closeTradeShift(tradeShift.id, employeeId)
    .then((response) => {
      dispatch(
        closeTradeShiftSuccesful({ ...tradeShift, shift: { ...tradeShift.shift, ...response.data.shift } }, employeeId),
      );
    })
    .catch((err) => {
      if (err.response?.data?.errorCode === 'SHIFTS_OVERLAPPING_WITH_BLOCKADES') {
        const notification = handleAddShiftFailureErrorMessage(err, intl);
        dispatch({
          notification: {
            ...notification,
            type: 'error',
          },
        });
      }
      dispatch(connectionError(err));
    });
};

export const changeStatusTradeShift = (tradeShiftId, coworkerId, status) => (dispatch) => {
  conn
    .indicateCoworkerStatusTradeShift(coworkerId, status)
    .then(() => {
      dispatch(changeStatusTradeShiftSuccesful(tradeShiftId, coworkerId, status));
    })
    .catch((err) => {
      dispatch(connectionError(err));
    });
};

export const createOpenShiftFromTradeShift = (tradeShiftId) => async (dispatch) => {
  try {
    const response = await conn.createOpenShiftFromTradeShift(tradeShiftId);
    dispatch(createOpenShiftFromTradeShiftSuccesful(tradeShiftId, response.data.open_shift));
  } catch (err) {
    dispatch(connectionError(err));
  }
};
