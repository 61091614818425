import PropTypes from 'prop-types';
import { useEffect } from 'react';

import TopBarNewExports from '../../../TopBars/TopBarNewExports.jsx';
import { Outlet, useLocation, useNavigate } from 'react-router';

const ExportsView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith('exports') || location.pathname.endsWith('exports/')) {
      navigate('/exports/catalog?category=all&format=all');
    }
  }, [location.pathname]);

  return (
    <>
      <TopBarNewExports activePath={location.pathname} />
      <Outlet />
    </>
  );
};

ExportsView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default ExportsView;
