import { unionBy } from 'lodash';

import { GET_PAYROLL_LOANED_EMPLOYEES } from '@/constants/ActionTypes';

const initialState = {
  data: [],
};

const mapPulledEmployees = employees =>
  employees.map(employee => ({
    ...employee,
    id: String(employee.id),
    isLoaned: true,
    attendances: [],
  }));

const payrollLoanedEmployees = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PAYROLL_LOANED_EMPLOYEES:
      const pulledEmployees = mapPulledEmployees(payload);
      return {
        ...state,
        data: unionBy(pulledEmployees, state, 'id'),
      };
    default:
      return state;
  }
};

export default payrollLoanedEmployees;
