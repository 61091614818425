import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'common.searchBox.placeholder',
    defaultMessage: 'Wpisz frazę...',
  },
});

const SearchBox = ({ getSearchWord, value, testId }, context) => (
  <div className="functionbar under">
    <div className="form-search">
      <label htmlFor="search-word">
        <FormattedMessage id="common.searchBox.search" defaultMessage="Szukaj :" />
      </label>
      <input
        id="search-word"
        onChange={getSearchWord}
        type="text"
        placeholder={context.intl.formatMessage(messages.placeholder, {})}
        value={value || ''}
        data-test={testId && `${testId}-input-searchWord`}
      />
      <div className="form-search_button">
        <i className="fa fa-search material-icons" aria-hidden="true" style={{ fontSize: '20px', marginTop: '4px' }}>
          search
        </i>
      </div>
    </div>
  </div>
);

SearchBox.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SearchBox.propTypes = {
  getSearchWord: PropTypes.func,
  value: PropTypes.string,
  testId: PropTypes.string,
};

export default SearchBox;
