import { getRelevantContractForDate } from '@/utils/contracts';
import { getFromToFromDateStore, workingHoursToTimestamp } from '@/utils/dateHelper.js';
import { getInitialRepeatShiftState } from '@/utils/repeatShiftHelpers';

import { messages } from './AddShiftModal.messages';

export const modalModifiers = ['narrow'];

export const getHeaderOptions = (demoAccount, shift, getObjectHistory) => {
  const hasAccessToHistory = !demoAccount && shift?.id;
  return hasAccessToHistory ? [{ icon: 'history', handleClick: getObjectHistory }] : [];
};

export const getFooterOptions = (
  intl,
  { modalObject, showDeleteAttendanceConfirmModal, showDeleteShiftConfirmModal },
) => {
  const employeeId = modalObject?.employee?.id;
  const attendanceId = modalObject?.attendanceToDelete?.id;
  const shiftId = modalObject?.shift?.id;

  if (attendanceId)
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.removeAttendance),
        handleClick: () => showDeleteAttendanceConfirmModal(employeeId, modalObject?.attendanceToDelete),
      },
    ];
  if (shiftId)
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteShift),
        handleClick: () => showDeleteShiftConfirmModal(employeeId, shiftId),
      },
    ];
  return [];
};

export const getInitialState = ({ userJobTitles, mainDateStore }) => ({
  employee: {},
  shift: {},
  date: '',
  location: {},
  working_hours: '__:__-__:__',
  selectedJobTitle: userJobTitles[0],
  comment: '',
  displayRepeat: false,
  ...getInitialRepeatShiftState(mainDateStore),
  errors: {},
  addAttendanceForShift: false,
});

export const getUpdatedStateFromAttendance = (modalObject, employeeContracts, jobTitles) => {
  const { defaultHours, location, employee, date } = modalObject;
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const relevantJobTitle = jobTitles.find(j => j.id === relevantContract?.job_titles[0]?.job_title_id);
  return {
    working_hours: defaultHours,
    location,
    employee,
    date,
    selectedJobTitle: relevantJobTitle,
  };
};

export const getUpdatedStateFromShift = modalObject => {
  const { location, shift, employee } = modalObject;
  return {
    location,
    date: shift.date,
    employee,
    shift,
    working_hours: shift.working_hours,
    selectedJobTitle: shift.job_title,
    comment: shift.comment,
  };
};

export const getUpdatedStateForNewShift = (modalObject, employeeContracts, jobTitles) => {
  const { location, employee, date } = modalObject;
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const relevantJobTitle = jobTitles.find(j => j.id === relevantContract?.job_titles[0]?.job_title_id);
  return {
    location,
    date,
    employee,
    selectedJobTitle: relevantJobTitle,
  };
};

export const getUpdatedStateForSelectedRange = mainDateStore => {
  const [start, end] = getFromToFromDateStore(mainDateStore);
  return { selectedRange: { start, end } };
};

export const getUpdatedStateForNewShiftWithLocationAndDateSelect = (modalObject, employeeContracts, jobTitles) => {
  const { employee, date } = modalObject;
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const relevantJobTitle = jobTitles.find(j => j.id === relevantContract?.job_titles[0]?.job_title_id);
  return {
    employee,
    location: employee.locations[0] || {},
    date,
    selectedJobTitle: relevantJobTitle || {},
  };
};

export const createShift = (
  modalObject,
  { selectedJobTitle, comment, working_hours: workingHours, date, shift, location },
) => {
  const { employee, preApproved } = modalObject;
  const createdShift = {
    id: shift.id || '',
    comment,
    working_hours: workingHours,
    employee: { id: employee.id },
    job_title: selectedJobTitle,
    draft: !preApproved,
    date,
    location,
  };
  return createdShift;
};

export const createItemShift = ({ shift, date, working_hours: workingHours }) => {
  const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = workingHoursToTimestamp(workingHours, date);
  return { id: shift?.id, date, startTimestamp, endTimestamp };
};

export const getEmployeeJobTitlesForDate = (employeeContracts, jobTitles, date) => {
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const relevantJobTitleIds = relevantContract?.job_titles.map(j => j.job_title_id);
  return jobTitles.filter(j => relevantJobTitleIds?.includes(j.id));
};
