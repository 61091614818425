import PropTypes from 'prop-types';

import KadroComposedChart from '@/components/common/charts/KadroComposedChart.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';

import { reportsMessages } from '../messages.js';
import { axisMetadata } from './constants.js';
import { mapDataForChart } from './utils.js';

const WorkedTimePerLocationChart = ({ rawData, userLocations }, { intl }) => {
  const { data, metadata } = mapDataForChart(rawData, userLocations);
  const isEmpty = data.every(item => {
    const itemToCheck = { ...item, date: undefined };

    return Object.values(itemToCheck).every(locationValue => !locationValue);
  });

  if (isEmpty) {
    return (
      <EmptyState
        name="jobtitleCTA"
        className="k-reports-empty-state"
        imgSrc="/img/jobtitlesCTA.png"
        emptyStateText={intl.formatMessage(reportsMessages.noData)}
        shiftX="0"
      />
    );
  }

  return <KadroComposedChart data={data} metadata={metadata} axisMetadata={axisMetadata} />;
};

WorkedTimePerLocationChart.contextTypes = {
  intl: PropTypes.shape({}),
};

WorkedTimePerLocationChart.propTypes = {
  rawData: PropTypes.shape({}),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkedTimePerLocationChart;
