import * as actionTypes from '@/constants/ActionTypes.js';
import { addDatesToScheduleCycleGroups, divideEmployeesIntoScheduleCycleGroups } from '@/utils/overtimeHelpers';

import { conn } from './index';
import { decreaseLoaderCounter, increaseLoaderCounter } from './uiState.js';

export const getOvertimeSuccess = overtime => ({
  type: actionTypes.GET_OVERTIME_SUCCESS,
  payload: overtime,
});

export const getOvertime = (from, to, employeeIds, options = {}) => async (dispatch, getState) => {
  const result = await conn.getOvertime({
    from,
    to,
    employeeIds: Array.from(new Set(employeeIds)),
    settings: { lateStartTolerance: 10000, earlyEndTolerance: 10000 },
  });
  const overtime = result.data;

  dispatch(getOvertimeSuccess(overtime));
};

export const getScheduleCycleOvertimeSuccess = overtime => ({
  type: actionTypes.GET_OVERTIME_STATS_SUCCESS,
  payload: overtime,
});

export const getScheduleCycleOvertime = relevantEmployeesIds => async (dispatch, getState) => {
  const { userEmployees, mainDateStore, payrollSettings } = getState().reducer;
  const firstDay = mainDateStore.dateArray[0];
  const relevantEmployees = userEmployees.filter(
    e =>
      relevantEmployeesIds.includes(e.id) && !e.overtimeStats.overtime && e.employment_conditions.template_id !== 'uoz',
  );

  const scheduleCycles = divideEmployeesIntoScheduleCycleGroups(relevantEmployees);
  const scheduleCyclesWithDates = addDatesToScheduleCycleGroups(scheduleCycles, firstDay);
  const promises = scheduleCyclesWithDates.map(s =>
    conn.getOvertimeForScheduleCycle({
      from: s.from,
      to: s.to,
      employeesIds: s.employeesIds,
      options: payrollSettings,
    }),
  );
  const result = await Promise.all(promises);

  const overtime = {};
  const overtimeStats = result.map((r, i) => ({ ...scheduleCyclesWithDates[i], overtime: r.data }));
  overtimeStats.forEach(s => {
    Object.keys(s.overtime).forEach(e => {
      overtime[e] = { ...s, overtime: s.overtime[e], employeesIds: undefined };
    });
  });
  dispatch(getScheduleCycleOvertimeSuccess(overtime));
};

export const hideRedundantOvertimeStats = date => ({
  type: actionTypes.HIDE_REDUNDANT_OVERTIME_STATS,
  payload: date,
});

export const getAllOvertimeData = (relevantEmployeesIds, from, to) => async dispatch => {
  dispatch(increaseLoaderCounter('blocking'));
  try {
    await dispatch(getOvertime(from, to, relevantEmployeesIds));
    await dispatch(getScheduleCycleOvertime(relevantEmployeesIds));
  } finally {
    dispatch(decreaseLoaderCounter('blocking'));
  }
};

export const getOvertimeDataForNewPayrollLocation = () => async (dispatch, getState) => {
  const { payrollLocation } = getState().reducer;
  const employeesIds = payrollLocation.data.map(({ employee_id: employeeId }) => employeeId);
  dispatch(increaseLoaderCounter('blocking'));
  try {
    await dispatch(getScheduleCycleOvertime(employeesIds));
  } finally {
    dispatch(decreaseLoaderCounter('blocking'));
  }
};
