import { connect } from 'react-redux';

import { scheduleActions } from '@/actions/schedule';

import ScheduleActionsButton from './ScheduleActionsButton.jsx';

const mapStateToProps = state => ({
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  mainDateStore: state.reducer.mainDateStore,
  openShifts: state.reducer.openShifts,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  userEmployees: state.reducer.userEmployees,
  userTemplates: state.reducer.userTemplates,
  userPermissions: state.reducer.userPermissions,
  budgetMetrics: state.reducer.budgetMetrics,
});

const mapDispatchToProps = {
  scheduleActions,
};

const ScheduleActionsButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleActionsButton);

export default ScheduleActionsButtonContainer;
