import { PropTypes } from 'prop-types';
import { defineMessages } from 'react-intl';

import { KADRO_BLUE_COLOR } from '@/constants/colors.js';
import { PieChart } from '../common/charts';
import { formatPieData } from './rechart.utils';

const chartMessages = defineMessages({
  others: {
    id: 'reports.others',
    defaultMessage: 'Inne',
  },
});

const JobTitleChart = (props, context) => {
  const { jobTitles, jobTitlesHours, jobTitlesCosts, showCosts } = props;
  const values = showCosts ? jobTitlesCosts : jobTitlesHours;

  let sortedJobTitles = jobTitles.map((job, i) => ({
    ...job,
    value: values[i],
  }));
  sortedJobTitles.sort((a, b) => (a.value > b.value ? -1 : 1));

  const OTHERS_INDEX = 10; // number of displayed job titles

  const others = sortedJobTitles.slice(OTHERS_INDEX); // the rest of job titles to sum up
  sortedJobTitles = sortedJobTitles.slice(0, OTHERS_INDEX);
  const othersSum = others.reduce((sum, job) => sum + job.value, 0);

  if (othersSum > 0)
    sortedJobTitles.push({
      color: KADRO_BLUE_COLOR,
      title: context.intl.formatMessage(chartMessages.others, {}),
      value: othersSum,
    });

  let data = {};
  if (showCosts) {
    data = {
      labels: sortedJobTitles.map((j) => j.title),
      datasets: [
        {
          data: sortedJobTitles.map((j) => Math.round(j.value * 100) / 100),
          backgroundColor: sortedJobTitles.map((j) => j.color),
        },
      ],
    };
  } else {
    data = {
      labels: sortedJobTitles.map((j) => j.title),
      datasets: [
        {
          data: sortedJobTitles.map((j) => Math.round(j.value / 0.6) / 100),
          backgroundColor: sortedJobTitles.map((j) => j.color),
        },
      ],
    };
  }

  return (
    <div>
      <PieChart data={formatPieData(data)} />
    </div>
  );
};

JobTitleChart.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

JobTitleChart.propTypes = {
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  jobTitlesHours: PropTypes.arrayOf(PropTypes.number),
  jobTitlesCosts: PropTypes.arrayOf(PropTypes.number),
  showCosts: PropTypes.bool,
};

export default JobTitleChart;
