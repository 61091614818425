import { defineMessages } from 'react-intl';

import { getOS } from '@/utils/baseHelpers';
import { isSameOrAfterTimestamp, isSameOrBeforeTimestamp, moment } from '@/utils/dateHelper';

const DND_WRAPPER_ID = 'fakeWrapperForDragAndDrop';

const messages = defineMessages({
  tooltipInstruction: {
    id: 'dragAndDrop.tooltipInstruction',
    defaultMessage: 'Przytrzymaj:',
  },
  tooltipCopy: {
    id: 'dragAndDrop.tooltipCopy',
    defaultMessage: 'kopiowanie',
  },
  tooltipSwap: {
    id: 'dragAndDrop.tooltipSwap',
    defaultMessage: 'zamiana',
  },
});

export const addTooltipToDragImage = (event, intl) => {
  const crt = event.target.cloneNode(true);
  const wrapper = document.createElement('div');
  const { width, height } = window.getComputedStyle(event.target);
  const widthNumber = Number(width.replace('px', ''));
  wrapper.id = DND_WRAPPER_ID;
  wrapper.style.width = `${widthNumber + 100}px`;
  wrapper.style.height = height;
  wrapper.style.opacity = 1;
  wrapper.style.top = '-150px';
  wrapper.style.position = 'absolute';
  crt.style.opacity = 1;
  crt.style.width = width;
  crt.style.height = height;
  crt.style.position = 'absolute';
  const tooltip = document.createElement('div');
  const os = getOS();
  const isMacOs = os === 'mac';
  const isWindows = os === 'windows';
  const copyButton = isMacOs ? 'OPTION' : 'CONTROL';
  const swapButton = isMacOs ? 'CMD + OPTION' : 'ALT';
  tooltip.innerHTML = `
${intl.formatMessage(messages.tooltipInstruction)}
<ul>
  <li>
   <b>${copyButton}</b> - 
    ${intl.formatMessage(messages.tooltipCopy)}
  </li>
  <li>
    <b>${swapButton}</b> - 
    ${intl.formatMessage(messages.tooltipSwap)}
  </li>
</ul>
`;

  tooltip.className = 'dragAndDropTooltip';
  tooltip.style.left = `${widthNumber - widthNumber / 2}px`;
  tooltip.style.zIndex = 1000;

  if (isWindows && widthNumber > 200) {
    wrapper.style.width = '300px';
    crt.style.width = '200px';
    tooltip.style.left = '90px';
  }

  wrapper.appendChild(crt);
  wrapper.appendChild(tooltip);
  document.body.appendChild(wrapper);
  event.dataTransfer.setDragImage(wrapper, event.nativeEvent.offsetX, event.nativeEvent.offsetY);
};

export const dragAndDropCleanup = () => {
  const elem = document.getElementById(DND_WRAPPER_ID);
  elem.parentNode.removeChild(elem);
};

export const shouldShiftConnectWithAbsence = (absence, shiftBlock, dayHasMultipleAbsences = false) => {
  if (!absence || dayHasMultipleAbsences) return false;
  const {
    absence_hours: absenceHours,
    count_only_days_with_shifts: countOnlyDaysWithShifts,
    omitted_dates: omittedDates,
    blockType,
  } = absence;
  if (blockType === 'overtimeCollection') return checkIfShiftBlockIsBetweenOvertimeCollectionHours(shiftBlock, absence);
  if (omittedDates.length && omittedDates.includes(shiftBlock.date)) return false;
  if (countOnlyDaysWithShifts) return true;
  if (absenceHours) return checkIfShiftBlockHoursAreBetweenAbsenceHours(shiftBlock, absence);
  return false;
};

export const getIndexOfLastConnectingBlock = (shiftBlocks, absence) =>
  shiftBlocks.reduce((acc, shiftBlock, index) => {
    if (shouldShiftConnectWithAbsence(absence, shiftBlock)) {
      return index + 1;
    }
    return acc;
  }, 0);

export const checkIfShiftBlockHoursAreBetweenAbsenceHours = (shiftBlock, absence) => {
  const { absence_hours: absenceHours, from: absenceFromDate, to: absenceToDate } = absence;
  const { start_timestamp: shiftStartTimestamp, end_timestamp: shiftEndTimestamp } = shiftBlock;
  const [fromAbsence, toAbsence] = absenceHours.split('-');
  const formattedFromAbsenceTimeStamp = moment(`${absenceFromDate} ${fromAbsence}`).format('YYYY-MM-DD HH:mm');
  const formattedToAbsenceTimeStamp =
    toAbsence === '00:00'
      ? moment(absenceToDate).add(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm')
      : moment(`${absenceToDate} ${toAbsence}`).format('YYYY-MM-DD HH:mm');

  const isFromShiftStartSameOrAfterFromAbsenceTime = isSameOrAfterTimestamp(
    moment(shiftStartTimestamp),
    moment(formattedFromAbsenceTimeStamp),
    'minute',
  );
  const isToShiftTimeSameOrBeforeToAbsenceTime = isSameOrBeforeTimestamp(
    moment(shiftEndTimestamp),
    moment(formattedToAbsenceTimeStamp),
    'minute',
  );
  return isFromShiftStartSameOrAfterFromAbsenceTime && isToShiftTimeSameOrBeforeToAbsenceTime;
};

export const checkIfShiftBlockIsBetweenOvertimeCollectionHours = (shiftBlock, absence) => {
  const { start_timestamp: absenceFrom, end_timestamp: absenceTo } = absence;
  const { start_timestamp: shiftStartTimestamp, end_timestamp: shiftEndTimestamp } = shiftBlock;
  const isFromShiftStartSameOrAfterFromAbsenceTime = isSameOrAfterTimestamp(
    moment(shiftStartTimestamp),
    moment(absenceFrom),
    'minute',
  );
  const isToShiftTimeSameOrBeforeToAbsenceTime = isSameOrBeforeTimestamp(
    moment(shiftEndTimestamp),
    moment(absenceTo),
    'minute',
  );
  return isFromShiftStartSameOrAfterFromAbsenceTime && isToShiftTimeSameOrBeforeToAbsenceTime;
};
