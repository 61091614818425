import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';

import './jobTitlesHeader.scss';

const JobTitlesHeader = props => (
  <thead className="k-jobTitlesHeader" data-test={props.testId && `${props.testId}-header`}>
    <tr>
      <Checkbox
        className="k-jobTitlesHeader__checkbox"
        checked={props.stateUI.selected.length > 0 && props.stateUI.selected.length === props.visible.length}
        toggle={() => {
          props.toggle(props.visible);
        }}
        testId="selectAllJobTitles"
      />
      <th
        className="tab_title tab_title--unsortedSpace k-jobTitlesHeader__headerCell k-jobTitlesHeader__headerCell--sortable"
        onClick={() => {
          props.sortFunc(0, 'jobTitles');
        }}
      >
        <span role="none">
          <FormattedMessage id="companymanage.jobTitlesHeader.jobTitles" defaultMessage="Stanowiska" />
        </span>
        <SortingArrow display={props.stateUI.sortColumn === 0} up={!props.stateUI.sortDecreesing} />
      </th>
      <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
        <th
          onClick={() => {
            props.sortFunc(1, 'jobTitles');
          }}
          className="k-jobTitlesHeader__headerCell k-jobTitlesHeader__headerCell--sortable"
        >
          <FormattedMessage id="companymanage.jobTitlesHeader.hourlyWage" defaultMessage="Stawka [PLN/h]" />
          <SortingArrow display={props.stateUI.sortColumn === 1} up={!props.stateUI.sortDecreesing} />
        </th>
      </FeatureWrapper>
      {props.showFactor ? (
        <th
          onClick={() => {
            props.sortFunc(2, 'jobTitles');
          }}
          className="k-jobTitlesHeader__headerCell k-jobTitlesHeader__headerCell--sortable"
        >
          <FormattedMessage id="companymanage.jobTitlesHeader.factor" defaultMessage="Mnożnik" />
          <SortingArrow display={props.stateUI.sortColumn === 2} up={!props.stateUI.sortDecreesing} />
        </th>
      ) : null}
      <th className="k-jobTitlesHeader__headerCell">
        <FormattedMessage id="companymanage.jobTitlesHeader.options" defaultMessage="Opcje" />
      </th>
    </tr>
  </thead>
);

JobTitlesHeader.propTypes = {
  sortFunc: PropTypes.func.isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
  }).isRequired,
  visible: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
  showFactor: PropTypes.bool,
  testId: PropTypes.string,
};

export default JobTitlesHeader;
