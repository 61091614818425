import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ADD_EVENT_MODAL } from '@/constants/modalTypes.js';
import { isHoliday,isWeekend } from '@/utils/dateHelper.js';
import { getScheduleSettingsValue } from '@/utils/schedulerHelpers.js';

import ScheduleEventBlock from './ScheduleEventBlock.jsx';

const ScheduleEvents = props => (
  <tr className="h-sorce2">
    {props.dateStore.dateArray.map(date => {
      const events = props.userEvents
        .filter(event => event.date === date && props.scheduleLocationFilter.includes(event.location.id))
        .map(event => ({
          ...event,
          locationName: props.userLocations.find(loc => loc.id === event.location.id)?.name,
        }));
      const showHolidays = getScheduleSettingsValue(props.userSettings, 'holidays');
      const holiday = isHoliday(date, props.holidays);
      const tdClassNames = classnames({
        freeFromWorkHoliday: showHolidays && holiday.freeFromWork,
        workDayHoliday: showHolidays && holiday.workDay,
        weekend: isWeekend(date),
        empty: !events.length && !props.isEmployee,
        today: date === props.dateStore.today,
      });
      if (!events.length) {
        return (
          <td
            onClick={() => props.showModal(ADD_EVENT_MODAL, { date })}
            key={date}
            className={tdClassNames}
            role="presentation"
          />
        );
      }

      return (
        <td key={date} className={tdClassNames}>
          {events.map(event => (
            <ScheduleEventBlock
              key={event.id}
              date={date}
              event={event}
              deleteUserEvent={props.deleteUserEvent}
              showModal={props.showModal}
              isEmployee={props.isEmployee}
              locationName={event.locationName}
            />
          ))}
        </td>
      );
    })}
  </tr>
);

ScheduleEvents.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  userEvents: PropTypes.arrayOf(PropTypes.shape({})),
  isEmployee: PropTypes.bool,
  deleteUserEvent: PropTypes.func,
  showModal: PropTypes.func,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  userSettings: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ScheduleEvents;
