import { PropTypes } from 'prop-types';
import { defineMessages } from 'react-intl';

import {
  baseStyle,
  blueChart,
  grayChart,
  purpleChart,
} from '@/constants/chartStyles.js';
import { AreaChart } from '../common/charts';
import { getAreaChartData, getAreaSeries } from './rechart.utils';

const chartMessages = defineMessages({
  realCosts: {
    id: 'reports.realCosts',
    defaultMessage: 'Realne koszty',
  },
  plannedCosts: {
    id: 'reports.plannedCosts',
    defaultMessage: 'Zaplanowane koszty',
  },
  realHours: {
    id: 'reports.realHours',
    defaultMessage: 'Przepracowane godziny',
  },
  plannedHours: {
    id: 'reports.plannedHours',
    defaultMessage: 'Zaplanowane godziny',
  },
  recommendedHours: {
    id: 'reports.recommendedHours',
    defaultMessage: 'Rekomendowane godziny',
  },
  recommendedCosts: {
    id: 'reports.recommendedCosts',
    defaultMessage: 'Rekomendowany koszt',
  },
});

const CostsChart = (props, context) => {
  const {
    showCosts,
    isRecommendedScheduleEnabled,
    mainDateStore,
    predictedCostsArr,
    actualCostsArr,
    recommendedCostsArr,
    predictedHoursArr,
    actualHoursArr,
    recommendedHoursArr,
  } = props;

  let datasets = [];

  if (showCosts) {
    datasets = [
      {
        label: context.intl.formatMessage(chartMessages.realCosts, {}),
        ...baseStyle,
        ...blueChart,
        data: actualCostsArr,
      },
      {
        label: context.intl.formatMessage(chartMessages.plannedCosts, {}),
        ...baseStyle,
        ...grayChart,
        data: predictedCostsArr,
      },
    ];
    if (isRecommendedScheduleEnabled) {
      datasets = [
        ...datasets,
        {
          label: context.intl.formatMessage(chartMessages.recommendedCosts, {}),
          ...baseStyle,
          ...purpleChart,
          data: recommendedCostsArr,
        },
      ];
    }
  } else {
    datasets = [
      {
        label: context.intl.formatMessage(chartMessages.realHours, {}),
        ...baseStyle,
        ...blueChart,
        data: actualHoursArr.map((h) => h / 60),
      },
      {
        label: context.intl.formatMessage(chartMessages.plannedHours, {}),
        ...baseStyle,
        ...grayChart,
        data: predictedHoursArr.map((h) => h / 60),
      },
    ];
    if (isRecommendedScheduleEnabled) {
      datasets = [
        ...datasets,
        {
          label: context.intl.formatMessage(chartMessages.recommendedHours, {}),
          ...baseStyle,
          ...purpleChart,
          data: recommendedHoursArr,
        },
      ];
    }
  }

  const data = {
    labels:
      mainDateStore.dateArray.length > 1
        ? mainDateStore.dateArray
        : [mainDateStore.dateArray[0], mainDateStore.dateArray[0]],
    datasets:
      mainDateStore.dateArray.length > 1
        ? datasets
        : datasets.map((dataset) => ({
            ...dataset,
            data: [dataset.data[0], dataset.data[0]],
          })),
  };

  return (
    <div>
      <AreaChart xAxisAngle={data.labels.length < 14 ? 0 : -45} xKey='label' data={getAreaChartData(data)} series={getAreaSeries(data)}/>
    </div>
  );
};
CostsChart.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CostsChart.propTypes = {
  showCosts: PropTypes.bool,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  predictedCostsArr: PropTypes.arrayOf(PropTypes.number),
  actualCostsArr: PropTypes.arrayOf(PropTypes.number),
  recommendedCostsArr: PropTypes.arrayOf(PropTypes.number),
  predictedHoursArr: PropTypes.arrayOf(PropTypes.number),
  actualHoursArr: PropTypes.arrayOf(PropTypes.number),
  recommendedHoursArr: PropTypes.arrayOf(PropTypes.number),
  isRecommendedScheduleEnabled: PropTypes.bool,
};
export default CostsChart;
