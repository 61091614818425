import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chooseOneLocation: {
    id: 'autoGenerate.disabled.chooseOneLocation',
    defaultMessage: 'Aby wygenerować grafik wybierz jedną lokalizację',
  },
  freemiumDisabled: {
    id: 'autoGenerate.disabled.freemiumDisabled',
    defaultMessage: 'Opcja dostępna w wersji Premium',
  },
});
