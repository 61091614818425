import { IntlShape } from 'react-intl';

import {
  FILE_CSV_BACKGROUND,
  FILE_CSV_COLOR,
  FILE_PDF_BACKGROUND,
  FILE_PDF_COLOR,
  FILE_XLS_BACKGROUND,
  FILE_XLS_COLOR,
  FILE_XML_BACKGROUND,
  FILE_XML_COLOR,
} from '@/constants/colors';
import { EXPORT_CATEGORIES, EXPORT_FILE_TYPES, EXPORT_FORMATS, EXPORT_STEPS } from '@/constants/exportConstants';
import { EXPORT_IMAGES, EXPORTS_TYPES } from '@/constants/exportFilesFormats';
import { NEW_EXPORT_MODAL } from '@/constants/modalTypes';
import { EXPORT_GSN_SHOW, OLD_XLS_PAYROLL_EXPORT, SAGE_LEGACY_EXPORT } from '@/constants/Permissions';
import { ExportConfig } from '@/types';
import { filterBasedOnPermissions } from '@/utils/permissionHelpers';

import {
  getEcpExportOptions,
  getEnovaOptions,
  getPayrollLocationPdfOptions,
  getPayrollLocationXlsSumOptions,
  getPayrollPdfOptions,
  getPayrollXlsOptions,
  getWorktimeBreakdownOptions,
} from './NewExportModal/formats/options';
import { ExportOptionsAndColumnConfigStep } from './NewExportModalSteps';
import { exportDataMessages } from './NewExports/ExportsCatalogView/ExportsCatalogView.messages';

export const getExports = (
  showModal: (type: string, params: { preselectedExport: string; formats: ExportConfig[] }) => void,
  payoutSettingType: string,
  permissions: string[],
  intl: IntlShape,
  hideEmployeeFilter = true,
  isEcpView = false,
): ExportConfig[] => {
  const modalStepsForExportAndEcp = [
    ...(isEcpView ? [EXPORT_STEPS.SELECTING_FORMAT] : []),
    ...(isEcpView ? [] : [EXPORT_STEPS.FILTERING]),
    ...(isEcpView ? [] : [EXPORT_STEPS.CHOOSING_EMPLOYEES]),
    EXPORT_STEPS.SETTINGS_OPTIONS,
  ];

  const handleExportButtonClick = preselectedExport => {
    showModal(NEW_EXPORT_MODAL, {
      preselectedExport,
      formats: getExports(showModal, payoutSettingType, permissions, intl, hideEmployeeFilter, isEcpView),
    });
  };
  const items = [
    {
      exportId: EXPORTS_TYPES.payrollLocationXls,
      title: intl.formatMessage(exportDataMessages.xls),
      description: intl.formatMessage(exportDataMessages.xlsDesc),
      steps: [
        ...(isEcpView ? [EXPORT_STEPS.SELECTING_FORMAT] : []),
        ...(isEcpView ? [] : [EXPORT_STEPS.FILTERING]),
        ...(isEcpView ? [] : [EXPORT_STEPS.CHOOSING_EMPLOYEES]),
        'customStepThree',
      ],
      options: getPayrollXlsOptions(payoutSettingType, false, intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.payrollLocationXls),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_PAYROLL_LOCATION_XLS,
      showInEcpView: true,
      customSteps: { customStepThree: ExportOptionsAndColumnConfigStep },
    },
    {
      exportId: EXPORTS_TYPES.payrollLocationXlsOld,
      title: intl.formatMessage(exportDataMessages.xlsOld),
      description: intl.formatMessage(exportDataMessages.xlsOldDesc),
      steps: modalStepsForExportAndEcp,
      options: getPayrollXlsOptions(payoutSettingType, true, intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.payrollLocationXlsOld),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_PAYROLL_LOCATION_XLS,
      showInEcpView: true,
      permissions: [OLD_XLS_PAYROLL_EXPORT],
    },
    {
      exportId: EXPORTS_TYPES.payrollLocationXlsSum,
      title: intl.formatMessage(exportDataMessages.xlsSum),
      description: intl.formatMessage(exportDataMessages.xlsSumDesc),
      steps: modalStepsForExportAndEcp,
      options: getPayrollLocationXlsSumOptions(payoutSettingType, intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.payrollLocationXlsSum),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: 'Suma',
      image: EXPORT_IMAGES.F_XLS_BRIEF_EXPORT,
      showInEcpView: true,
    },
    {
      exportId: EXPORTS_TYPES.payrollPdf,
      title: intl.formatMessage(exportDataMessages.pdf),
      description: intl.formatMessage(exportDataMessages.pdfDesc),
      steps: modalStepsForExportAndEcp,
      options: getPayrollPdfOptions(payoutSettingType, intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.payrollPdf),
      backgroundColor: FILE_PDF_BACKGROUND,
      color: FILE_PDF_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.PDF,
      filterFormat: EXPORT_FORMATS.PDF,
      fileIconText: EXPORT_FILE_TYPES.PDF,
      image: EXPORT_IMAGES.F_PAYROLL_PDF,
      showInEcpView: true,
    },
    {
      exportId: EXPORTS_TYPES.payrollLocationPdf,
      title: intl.formatMessage(exportDataMessages.pdfSum),
      description: intl.formatMessage(exportDataMessages.pdfSumDesc),
      steps: modalStepsForExportAndEcp,
      options: getPayrollLocationPdfOptions(payoutSettingType, intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.payrollLocationPdf),
      color: FILE_PDF_COLOR,
      backgroundColor: FILE_PDF_BACKGROUND,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.PDF,
      filterFormat: EXPORT_FORMATS.PDF,
      fileIconText: EXPORT_FILE_TYPES.PDF,
      fileIconSubtitle: 'SUMA',
      image: EXPORT_IMAGES.F_PAYROLL_LOCATION_PDF,
      showInEcpView: true,
    },
    {
      exportId: EXPORTS_TYPES.xlsComarch,
      title: intl.formatMessage(exportDataMessages.comarch),
      description: intl.formatMessage(exportDataMessages.comarchDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.xlsComarch),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: 'Comarch',
      image: EXPORT_IMAGES.F_XLS_COMARCH,
    },
    {
      exportId: EXPORTS_TYPES.csvR2platnik,
      title: intl.formatMessage(exportDataMessages.r2platnik),
      description: intl.formatMessage(exportDataMessages.r2platnikDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.csvR2platnik),
      backgroundColor: FILE_CSV_BACKGROUND,
      color: FILE_CSV_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FILE_TYPES.CSV,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.CSV,
      fileIconSubtitle: 'R2płatnik',
      image: EXPORT_IMAGES.F_CSV_R2PLATNIK,
    },
    {
      exportId: EXPORTS_TYPES.enovaXml,
      title: intl.formatMessage(exportDataMessages.enovaXml),
      description: intl.formatMessage(exportDataMessages.enovaXmlDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.SETTINGS_OPTIONS],
      options: getEnovaOptions(intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.enovaXml),
      backgroundColor: FILE_XML_BACKGROUND,
      color: FILE_XML_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XML,
      filterFormat: EXPORT_FORMATS.XML,
      fileIconText: EXPORT_FILE_TYPES.XML,
      fileIconSubtitle: 'Enova',
      image: EXPORT_IMAGES.F_ENOVA_XML,
    },
    {
      exportId: EXPORTS_TYPES.scheduleExecutionExport,
      title: intl.formatMessage(exportDataMessages.scheduleExecution),
      description: intl.formatMessage(exportDataMessages.scheduleExecutionDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.scheduleExecutionExport),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.OPERATIONAL],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_SCHEDULE_EXECUTION_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.spmhExport,
      title: intl.formatMessage(exportDataMessages.spmh),
      description: intl.formatMessage(exportDataMessages.spmhDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.spmhExport),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.BUDGET],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_SPMH_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.spmhExportByDay,
      title: intl.formatMessage(exportDataMessages.spmhByDay),
      description: intl.formatMessage(exportDataMessages.spmhByDayDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.spmhExportByDay),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.BUDGET],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_SPMH_EXPORT_BY_DAY,
    },
    {
      exportId: EXPORTS_TYPES.worktimeBreakdown,
      title: intl.formatMessage(exportDataMessages.worktimeBreakdown),
      description: intl.formatMessage(exportDataMessages.worktimeBreakdownDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.SETTINGS_OPTIONS],
      options: getWorktimeBreakdownOptions(intl),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.worktimeBreakdown),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.BUDGET],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: 'Statystyki czasu pracy',
      image: EXPORT_IMAGES.F_WORKTIME_BREAKDOWN_XLS,
    },
    {
      exportId: EXPORTS_TYPES.ecpExport,
      title: intl.formatMessage(exportDataMessages.ecp),
      description: intl.formatMessage(exportDataMessages.ecpDesc),
      steps: modalStepsForExportAndEcp,
      options: getEcpExportOptions(),
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.ecpExport),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_ECP_EXPORT,
      showInEcpView: true,
    },
    {
      exportId: EXPORTS_TYPES.adpExport,
      title: intl.formatMessage(exportDataMessages.adp),
      description: intl.formatMessage(exportDataMessages.adpDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.adpExport),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: 'ADP',
      image: EXPORT_IMAGES.F_ADP_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.budgetPerLocation,
      title: intl.formatMessage(exportDataMessages.budgetPerLocation),
      description: intl.formatMessage(exportDataMessages.budgetPerLocationDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.budgetPerLocation),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.BUDGET],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_BUDGET_PER_LOCATION_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.overtime,
      title: intl.formatMessage(exportDataMessages.overtime),
      description: intl.formatMessage(exportDataMessages.overtimeDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.overtime),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.OPERATIONAL],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: 'Nadgodziny',
      image: EXPORT_IMAGES.F_OVERTIME_EXPORT,
      hide: payoutSettingType === 'shifts',
    },
    {
      exportId: EXPORTS_TYPES.r2platnikAbsenceExport,
      title: intl.formatMessage(exportDataMessages.r2platnikAbsenceExport),
      description: intl.formatMessage(exportDataMessages.r2platnikAbsenceExportDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.r2platnikAbsenceExport),
      backgroundColor: FILE_CSV_BACKGROUND,
      color: FILE_CSV_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FILE_TYPES.CSV,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.CSV,
      fileIconSubtitle: 'R2płatnik',
      image: EXPORT_IMAGES.F_R2PLATNIK_ABSENCE_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.r2platnikShiftsExport,
      title: intl.formatMessage(exportDataMessages.r2platnikShiftsExport),
      description: intl.formatMessage(exportDataMessages.r2platnikShiftsExportDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.r2platnikShiftsExport),
      backgroundColor: FILE_CSV_BACKGROUND,
      color: FILE_CSV_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      image: EXPORT_IMAGES.F_R2PLATNIK_SHIFTS_EXPORT,
      displayFormat: EXPORT_FILE_TYPES.CSV,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.CSV,
      fileIconSubtitle: 'R2płatnik',
    },
    {
      exportId: EXPORTS_TYPES.absence,
      title: intl.formatMessage(exportDataMessages.absence),
      description: intl.formatMessage(exportDataMessages.absenceDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.absence),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_ABSENCE_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.timesheetControllingExport,
      title: intl.formatMessage(exportDataMessages.timesheetControllingExport),
      description: intl.formatMessage(exportDataMessages.timesheetControllingExportDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.timesheetControllingExport),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.TIME_WORKED],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_TIMESHEET_CONTROLLING_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.xlsSage,
      title: intl.formatMessage(exportDataMessages.xlsSage),
      description: intl.formatMessage(exportDataMessages.xlsSageDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.xlsSage),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_CSV_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: EXPORT_FILE_TYPES.XLS,
      image: EXPORT_IMAGES.F_XLS_SAGE,
      permissions: [SAGE_LEGACY_EXPORT],
    },
    {
      exportId: EXPORTS_TYPES.symfoniaXml,
      title: intl.formatMessage(exportDataMessages.symfoniaXml),
      description: intl.formatMessage(exportDataMessages.symfoniaXmlDesc),
      steps: [EXPORT_STEPS.FILTERING],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.symfoniaXml),
      backgroundColor: FILE_XML_BACKGROUND,
      color: FILE_CSV_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XML,
      filterFormat: EXPORT_FORMATS.XML,
      fileIconText: EXPORT_FILE_TYPES.XML,
      fileIconSubtitle: 'Enova',
      image: EXPORT_IMAGES.F_ABSENCE_AND_OVERTIME_SYMFONIA_EXPORT,
    },
    {
      exportId: EXPORTS_TYPES.xlsGsn,
      title: intl.formatMessage(exportDataMessages.gsn),
      description: intl.formatMessage(exportDataMessages.gsnDesc),
      steps: [EXPORT_STEPS.FILTERING, EXPORT_STEPS.CHOOSING_EMPLOYEES],
      options: [],
      actionButtonFunc: () => handleExportButtonClick(EXPORTS_TYPES.xlsGsn),
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      categories: [EXPORT_CATEGORIES.OTHER_SYSTEMS],
      displayFormat: EXPORT_FORMATS.XLS,
      filterFormat: EXPORT_FORMATS.XLS,
      fileIconText: EXPORT_FILE_TYPES.XLS,
      fileIconSubtitle: EXPORT_FILE_TYPES.XLS,
      permissions: [EXPORT_GSN_SHOW],
      image: EXPORT_IMAGES.F_XLS_GSN,
    },
  ];

  const filteredByPayoutSettingType = items.filter(({ hide }) => !hide);

  return filterBasedOnPermissions(filteredByPayoutSettingType, permissions);
};
