import { connect } from 'react-redux';

import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';

import PayrollLocationLoadDataButton from './PayrollLocationLoadDataButton';

const mapStateToProps = state => ({
  isLoadPayrollLocationButtonEnabled: state.reducer.payrollLocation.isLoadButtonEnabled,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  selectedEmploymentConditions: state.reducer.employmentConditionsFilter.selected,
  selectedJobtitles: state.reducer.jobtitleFilter.selectedJobtitles,
});

const mapDispatchToProps = {
  getPayrollLocationViewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationLoadDataButton);
