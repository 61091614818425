import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon';
import MDTableSummary, { SummaryItem } from '@/components/common/MDTable/Summary/MDTableSummary';

import './employeePayrollTableWidgets.scss';

interface EmployeePayrollTableWidgetsProps {
  summary: SummaryItem[];
  showSkeletons: boolean;
}

const EmployeePayrollTableWidgets = ({ summary, showSkeletons }: EmployeePayrollTableWidgetsProps) => {
  const [hideWidget, setHideWidget] = useState(false);

  return (
    <div className="k-employeePayrollTableWidgets">
      {!hideWidget && (
        <div className="k-employeePayrollTableWidgets__disclaimer">
          <Icon name="warning" type="material" className="k-employeePayrollTableWidgets__warningIcon" />
          <FormattedMessage
            id="employeePayrollTable.disclaimer"
            defaultMessage="Zastrzeżenie: To zestawienie godzin  {notConstituteText}  ewidencji godzin pracy z Art. 149 Kodeksu Pracy."
            values={{
              notConstituteText: <b>nie stanowi</b>,
            }}
          />
          <div
            className="k-employeePayrollTableWidgets__closeIconContainer"
            onClick={() => setHideWidget(true)}
            role="presentation"
          >
            <Icon name="close" type="material" className="k-employeePayrollTableWidgets__closeIcon" />
          </div>
        </div>
      )}

      <MDTableSummary summary={summary} isFetching={showSkeletons} />
    </div>
  );
};

export default EmployeePayrollTableWidgets;
