/* eslint camelcase:0 */
import PropTypes from 'prop-types';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import NameCell from '@/components/common/NameCell.jsx';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers.js';

const EmployeesRowTitle = props => {
  const { first_name, last_name, avatar, compareName } = props.data;
  const avatarURL =
    avatar && avatar.small !== 'https://static.kadromierz.pl/avatars/placeholder-avatar-small.png'
      ? avatar.small
      : null;
  return (
    <tr className="h-sorce2">
      <td className="middle" data-test={compareName}>
        <Checkbox toggle={props.toggle} checked={props.checked} testId="employeeCheckbox" />
        <NameCell
          avatar={avatarURL}
          name={getEmployeeNameShort(props.data, 17)}
          initials={first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase()}
        />
      </td>
    </tr>
  );
};
EmployeesRowTitle.propTypes = {
  data: PropTypes.shape({}).isRequired,
  toggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
export default EmployeesRowTitle;
