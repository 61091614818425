import { ABSENCES_FOR_OTHERS_HIDE_ADD, OVERTIME_COLLECTION_HIDE } from '@/constants/Restrictions';
import { getBasicOvertimeCollectionObject } from '@/utils/overtimeHelpers';

export const getAllAbsenceTypes = (absenceTypes, userPermissions, intl) => {
  const shouldAddOvertimeCollection =
    !userPermissions.isEmployee && !userPermissions.restrictions.includes(OVERTIME_COLLECTION_HIDE);
  const allAbsenceTypes = shouldAddOvertimeCollection
    ? [...absenceTypes, getBasicOvertimeCollectionObject(intl)]
    : absenceTypes;
  return allAbsenceTypes;
};

export const getAbsenceTypeOptions = (absenceTypes, isEmployee) =>
  absenceTypes.reduce(
    (list, absenceType) =>
      !absenceType.deleted_at_timestamp && (!isEmployee || absenceType.adding_by_employee)
        ? [...list, { key: absenceType.name, value: absenceType.id }]
        : list,
    [],
  );

export const checkIfCanSelectEmployee = (userPermissions, showEmployeeSelect) => {
  const canUserAddAbsenceForOthers = !userPermissions.restrictions.includes(ABSENCES_FOR_OTHERS_HIDE_ADD);
  const canSelectEmployee = !userPermissions.isEmployee && showEmployeeSelect && canUserAddAbsenceForOthers;
  return canSelectEmployee;
};

export const getEmployeeOptions = userEmployees =>
  userEmployees.reduce(
    (empList, empl) =>
      empl.employment_conditions.show_absences && !empl.inactive
        ? [...empList, { key: `${empl.first_name} ${empl.last_name}`, value: empl.id }]
        : empList,
    [],
  );
