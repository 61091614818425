import classNames from 'classnames';
import PropTypes from 'prop-types';

import './LoadingSpinnerWithCheckmark.scss';

const LoadingSpinnerWithCheckmark = ({ status }) => {
  const isFinished = status === 'finished';
  const className = classNames('loadingSpinnerWithCheckmark', {
    loadingSpinnerWithCheckmark__loaded: isFinished,
  });
  const checkMarkClassNames = classNames(
    'loadingSpinnerWithCheckmark__checkmark',
    'loadingSpinnerWithCheckmark__draw',
    {
      'loadingSpinnerWithCheckmark__checkmark--visible': isFinished,
    },
  );
  return (
    <div className={className}>
      <div className={checkMarkClassNames} />
    </div>
  );
};

LoadingSpinnerWithCheckmark.propTypes = {
  status: PropTypes.func,
};

export default LoadingSpinnerWithCheckmark;
