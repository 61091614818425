import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  manageAbsenceTypesTableId: {
    id: 'manageAbsenceTypes.table.id',
    defaultMessage: 'Nr',
  },
  manageAbsenceTypesTableName: {
    id: 'manageAbsenceTypes.table.name',
    defaultMessage: 'Nazwa',
  },
  manageAbsenceTypesTableShortName: {
    id: 'manageAbsenceTypes.table.shortName',
    defaultMessage: 'Skrócona nazwa',
  },
  manageAbsenceTypesTableCode: {
    id: 'manageAbsenceTypes.table.code',
    defaultMessage: 'Kod',
  },
  manageAbsenceTypesTableAddingByEmployee: {
    id: 'manageAbsenceTypes.table.addingByEmployee',
    defaultMessage: 'Dodawanie przez pracownika',
  },
  manageAbsenceTypesTableHasLimit: {
    id: 'manageAbsenceTypes.table.hasLimit',
    defaultMessage: 'Wymiar urlopu',
  },
  manageAbsenceTypesTableIsPaid: {
    id: 'manageAbsenceTypes.table.isPaid',
    defaultMessage: 'Typ urlopu',
  },
  manageAbsenceTypesAddingByEmployeeTrue: {
    id: 'manageAbsenceTypes.addingByEmployee.true',
    defaultMessage: 'Zezwolone',
  },
  manageAbsenceTypesAddingByEmployeeFalse: {
    id: 'manageAbsenceTypes.addingByEmployee.false',
    defaultMessage: 'Zablokowane',
  },
  manageAbsenceTypesHasLimitTrue: {
    id: 'manageAbsenceTypes.hasLimit.true',
    defaultMessage: 'Limitowany',
  },
  manageAbsenceTypesHasLimitFalse: {
    id: 'manageAbsenceTypes.hasLimit.false',
    defaultMessage: 'Nielimitowany',
  },
  manageAbsenceTypesIsPaidTrue: {
    id: 'manageAbsenceTypes.isPaid.true',
    defaultMessage: 'Płatna',
  },
  manageAbsenceTypesIsPaidFalse: {
    id: 'manageAbsenceTypes.isPaid.false',
    defaultMessage: 'Niepłatna',
  },
});
