import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { CHOOSE_LOCALIZATION_MODAL } from '@/constants/modalTypes.js';

import ChooseLocationModal from './ChooseLocationModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === CHOOSE_LOCALIZATION_MODAL,
  modalObject: state.reducer.uiState.modalObject[CHOOSE_LOCALIZATION_MODAL],
});

const mapDispatchToProps = {
  hideModal,
};
const ChooseLocationModalContainer = connect(mapStateToProps, mapDispatchToProps)(ChooseLocationModal);

export default ChooseLocationModalContainer;
