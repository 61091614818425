import { connect } from 'react-redux';

import { saveRoles } from '@/actions/companymanage/roles.js';
import { showModal } from '@/actions/uiState.js';

import RolesButtonsBar from './RolesButtonsBar.jsx';

const mapStateToProps = state => ({
  preCheckedPermissions: state.reducer.roles.preCheckedPermissions,
  checkedPermissions: state.reducer.roles.checkedPermissions,
});

const mapDispatchToProps = {
  showModal,
  saveRoles,
};

const RolesButtonsBarContainer = connect(mapStateToProps, mapDispatchToProps)(RolesButtonsBar);

export default RolesButtonsBarContainer;
