import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employer: {
    id: 'overtimeCollections.applicant.employer',
    defaultMessage: 'Na wniosek pracodawcy',
  },
  employee: {
    id: 'overtimeCollections.applicant.employee',
    defaultMessage: 'Na wniosek pracownika',
  },
  applicant: {
    id: 'overtimeCollections.applicant',
    defaultMessage: 'Wnioskodawca',
  },
  overtimeUsage: {
    id: 'overtimeCollections.usage',
    defaultMessage: 'Wykorzysta {overtimeUsage} z {totalDailyOvertimeToUse} dostępnych nadgodzin',
  },
  overtime50: {
    id: 'overtimeCollections.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'overtimeCollections.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  overtimePotential: {
    id: 'overtimeCollections.overtimePotential',
    defaultMessage: 'Nadgodziny PŚT',
  },
  overtimePotentialTooltip: {
    id: 'overtimeCollections.overtimePotentialTooltip',
    defaultMessage: 'Nadgodziny potencjalne średniotygodniowe',
  },
  maxOvertimeUsageTooltipText: {
    id: 'overtimeCollections.maxOvertimeUsageTooltipText',
    defaultMessage: 'Ilość nadgodzin możliwych do wykorzystania',
  },
  useAll: {
    id: 'overtimeCollections.useAll',
    defaultMessage: 'Użyj wszystkie',
  },
  receivedHours: {
    id: 'overtimeCollections.receivedHours',
    defaultMessage: 'Godziny do odbioru',
  },
  overtimeUsageToHigh: {
    id: 'overtimeCollections.overtimeUsageToHigh',
    defaultMessage: 'Wybrany odbiór nadgodzin przewyższa dostępną ilość możliwych nadgodzin do odbioru',
  },
  overtimeAmountExceeded: {
    id: 'overtimeCollections.overtimeAmountExceeded',
    defaultMessage: 'Wybrana wartość jest większa niż dostępna',
  },
  overtimeUsageToLow: {
    id: 'overtimeCollections.overtimeUsageToLow',
    defaultMessage: 'Ostrzeżenie: Pracownik otrzyma {differenceInHours} więcej niż powinien.',
  },
  overtimeUsageTooHigh: {
    id: 'overtimeCollections.overtimeUsageTooHigh',
    defaultMessage: 'Ostrzeżenie: Pracownik otrzyma {differenceInHours} mniej niż powinien.',
  },
});
