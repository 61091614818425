import { connect } from 'react-redux';

import { setPayrollSearchString } from '@/actions/payrollUI.js';

import PayrollLocationView from './PayrollLocationView.jsx';

const mapStateToProps = ({ reducer }) => ({
  searchString: reducer.payrollUI.searchString,
});

const mapDispatchToProps = {
  setPayrollSearchString,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationView);
