import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absencesLimitsViewTableEmployee: {
    id: 'absencesLimitsView.table.employee',
    defaultMessage: 'Pracownik',
  },
  absencesLimitsViewTableLimit: {
    id: 'absencesLimitsView.table.limit',
    defaultMessage: 'Limit',
  },
  absencesLimitsViewTableUsed: {
    id: 'absencesLimitsView.table.used',
    defaultMessage: 'Zatwierdzono',
  },
  absencesLimitsViewTableLeft: {
    id: 'absencesLimitsView.table.left',
    defaultMessage: 'Do wykorzystania',
  },
  absencesLimitsViewTableAbsenceType: {
    id: 'absencesLimitsView.table.absenceType',
    defaultMessage: 'Typ nieobecności',
  },
  absencesLimitsViewEmptyTable: {
    id: 'absencesLimitsView.emptyTable',
    defaultMessage: 'Brak limitów w wybranym przedziale czasowym',
  },
  absencesLimitsViewAbsenceLimitUnlimited: {
    id: 'absencesLimitsView.absenceLimitUnlimited',
    defaultMessage: 'Nielimitowany',
  },
  absenceTypeUnitDays: {
    id: 'absenceType.unit.days',
    defaultMessage: 'dni',
  },
});
