import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sendNotification: {
    id: 'customNotifications.sendNotification',
    defaultMessage: 'Wyślij powiadomienie',
  },
  send: {
    id: 'customNotifications.send',
    defaultMessage: 'Wyślij',
  },
  messageTitle: {
    id: 'companymanage.customNotificationsModal.title',
    defaultMessage: 'Tytuł',
  },
  messageText: {
    id: 'companymanage.customNotificationsModal.message',
    defaultMessage: 'Treść',
  },
  noInfo: {
    id: 'companymanage.customNotificationsModal.noInfo',
    defaultMessage:
      'Powiadomienie zostanie wysłane do {empNo} {empNo, plural, one {pracownika} few {pracowników} many {pracowników} other {pracowników}}',
  },
});

export const getInitialState = () => ({
  notificationTitle: '',
  notificationMessage: '',
  errors: {},
});
