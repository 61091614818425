import { groupBy, unionBy } from 'lodash';

import { CHANGE_PRODUCTION_QUOTAS_SUCCESFUL,GET_PRODUCTION_QUOTAS_SUCCESFUL } from '@/constants/ActionTypes.js';

const initialState = {};

const userProductionQuotas = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_QUOTAS_SUCCESFUL: {
      const groupedPayload = groupBy(action.payload.productionQuotas, 'date');
      const quotas = Object.values(groupedPayload).reduce((prev, quota, index) => {
        const date = Object.keys(groupedPayload)[index];
        return {
          ...prev,
          [date]: state[date] ? unionBy(quota, state[date], 'id') : quota,
        };
      }, {});
      return {
        ...state,
        ...quotas,
      };
    }
    case CHANGE_PRODUCTION_QUOTAS_SUCCESFUL: {
      // Assume we will always get array of one item in change response,
      // This will break of course if we will introduce mass updates.
      const productionQuota = action.payload.productionQuotas[0];
      const quotaExist = state[action.payload.date]
        ? state[action.payload.date].some(quota => productionQuota.id === quota.id)
        : false;
      return {
        ...state,
        [action.payload.date]: quotaExist
          ? [
              ...state[action.payload.date].map(quota => {
                if (productionQuota.id !== quota.id) {
                  return quota;
                }
                return {
                  ...productionQuota,
                };
              }),
            ]
          : [...state[action.payload.date], productionQuota],
      };
    }
    default:
      return state;
  }
};

export default userProductionQuotas;
