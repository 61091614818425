import { produce } from 'immer';

import {
  GET_REPORT_DATA_ERROR,
  GET_REPORT_DATA_INIT,
  GET_REPORT_DATA_SUCCESS,
  REPORTS_DATE_CHANGE_CUSTOM_DATE,
  REPORTS_DATE_CHANGE_MODE,
  REPORTS_DATE_CHANGE_MODE_AND_DATE,
  REPORTS_DATE_MOVE_LEFT,
  REPORTS_DATE_MOVE_RIGHT,
  REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE,
  REPORTS_JOB_TITLES_FILTER_CHANGE,
  REPORTS_LOCATIONS_FILTER_CHANGE,
} from '@/constants/ActionTypes';
import {
  GetReportDataErrorAction,
  GetReportDataInitAction,
  GetReportDataSuccessAction,
  ReportFilterChangeAction,
} from '@/types/reports.types';

export type State = {
  isLoading: boolean;
  canUpdate: boolean;
};

const initialState: State = {
  isLoading: false,
  canUpdate: false,
};

type Action =
  | GetReportDataInitAction
  | GetReportDataSuccessAction
  | GetReportDataErrorAction
  | ReportFilterChangeAction;

export const ui = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_REPORT_DATA_INIT: {
        draft.isLoading = true;
        draft.canUpdate = false;
        break;
      }
      case GET_REPORT_DATA_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case GET_REPORT_DATA_ERROR: {
        draft.isLoading = false;
        break;
      }
      case REPORTS_DATE_CHANGE_MODE:
      case REPORTS_DATE_MOVE_LEFT:
      case REPORTS_DATE_MOVE_RIGHT:
      case REPORTS_DATE_CHANGE_CUSTOM_DATE:
      case REPORTS_DATE_CHANGE_MODE_AND_DATE:
      case REPORTS_LOCATIONS_FILTER_CHANGE:
      case REPORTS_JOB_TITLES_FILTER_CHANGE:
      case REPORTS_EMPLOYMENT_CONDITIONS_FILTER_CHANGE:
        draft.canUpdate = true;
        break;
      default:
        return state;
    }
  });
