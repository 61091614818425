/* eslint class-methods-use-this:0 */
import moment from 'moment';
import xlsx from 'xlsx';

import { MAIN_COLOR_PALETT } from '@/constants/colors.js';

import { uuid4 } from './baseHelpers.js';

function ImportExeption(message, error) {
  this.message = message;
  this.name = 'ImportError';
  this.error = error;
}

export default class KadroImport {
  constructor() {
    this.wb = null;
    this.parsedData = null;
  }

  readXLSFromBuffer(file) {
    const fileReader = new FileReader();
    return new Promise(resolve => {
      let wb;
      fileReader.onload = e => {
        let binary = '';
        const bytes = new Uint8Array(e.target.result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        wb = xlsx.read(binary, { type: 'binary', cellDates: 'true', cellStyles: 'true' });
        this.wb = wb;
        resolve();
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  getRandomColor() {
    return MAIN_COLOR_PALETT[Math.floor(Math.random() * MAIN_COLOR_PALETT.length)];
  }

  randomDigit() {
    return Math.floor(Math.random() * 10);
  }

  getRandomPin() {
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += this.randomDigit();
    }
    return result;
  }

  processString(string) {
    if (!string) return '';
    let result = string;
    result = result.trim();
    return result;
  }

  parseRecommendedScheduleFile(userJobTitles) {
    try {
      const sheetName = 'prognozowany grafik';
      const scheduleList = xlsx.utils.sheet_to_json(this.wb.Sheets[sheetName]);
      const recommendedScheduleData = scheduleList.reduce((acc, row) => {
        const lowerCaseColNameRow = Object.keys(row).reduce(
          (newRow, key) => ({ ...newRow, [key.toLowerCase()]: row[key] }),
          {},
        );
        const { data, godzina, ...jobTitilesValues } = lowerCaseColNameRow;
        const time = moment(`${data} ${godzina}`);
        const hour = time.format('HH:mm');
        const date = time.format('YYYY-MM-DD');
        const jobTitlesData = Object.entries(jobTitilesValues).reduce((acc1, val) => {
          const jobTitle = userJobTitles.find(j => j.title.toLowerCase() === val[0]);
          return jobTitle && jobTitle.id && val[1] !== '' ? { ...acc1, [jobTitle.id]: val[1] } : acc1;
        }, {});
        return Object.keys(jobTitlesData).length && time.isValid()
          ? acc.concat({
              date,
              hour,
              jobTitlesData,
            })
          : acc;
      }, []);
      this.parsedData = { ...this.parsedData, recommendedScheduleData };
    } catch (err) {
      throw new ImportExeption('Couldnt parse uploaded file.', err);
    }
  }

  parse() {
    try {
      this.sheetNames = this.wb.SheetNames;

      let sheetName = 'Lokalizacje';
      let locations = xlsx.utils.sheet_to_json(this.wb.Sheets[sheetName]);
      locations = locations.map(location => ({
        id: uuid4(),
        address: this.processString(location.Ulica),
        city: this.processString(location.Miasto),
        color: this.getRandomColor(),
        name: this.processString(location.Nazwa),
        additional_information: this.processString(location.Dodatkowe),
        attachedEmployees: { toAdd: [] },
      }));

      sheetName = 'Stanowiska';
      let jobTitles = xlsx.utils.sheet_to_json(this.wb.Sheets[sheetName]);
      jobTitles = jobTitles.map(job => ({
        id: uuid4(),
        title: this.processString(job.Nazwa),
        hourly_wage: parseInt(parseFloat(job['Stawka PLN/H']) * 100),
        color: this.getRandomColor(),
        factor: parseFloat(job['Mnożnik'] || '0'),
        attachedEmployees: { toAdd: [] },
      }));

      sheetName = 'Pracownicy';
      let employees = xlsx.utils.sheet_to_json(this.wb.Sheets[sheetName]);
      employees = employees.map(employee => ({
        id: uuid4(),
        first_name: this.processString(employee['Imię']),
        last_name: this.processString(employee.Nazwisko),
        locations: employee.Lokalizacje,
        phone: employee.Telefon,
        email: this.processString(employee['E-mail']),
        pin: this.getRandomPin(),
        role: employee.Manager && employee.Manager.toLowerCase() === 'tak' ? 'manager' : 'employee',
        role_id: employee.Manager && employee.Manager.toLowerCase() === 'tak' ? 'manager' : 'employee',
        send_invitation: false,
        jobTitles: employee.Stanowiska,
        reference_id: this.processString(employee['Kod referencyjny']),
        nfc_code: this.processString(employee['Kod NFC']),
        employment_conditions: employee['Warunek zatrudnienia'],
      }));

      this.parsedData = { locations, jobTitles, employees };
    } catch (err) {
      throw new ImportExeption('Couldnt parse uploaded file.', err);
    }
  }

  getObject() {
    return this.parsedData;
  }
}
