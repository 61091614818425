import { combineReducers } from 'redux';

import { data } from './data';
import { dateStore } from './dateStore.js';
import { filters } from './filters.js';
import { reportData } from './reportData.js';
import { ui } from './ui';

export default combineReducers({
  data,
  ui,
  reportData,
  filters,
  dateStore,
});
