export const generateQueryParametersFromSearchString = (search: string): { [key: string]: string } => {
  const searchParams = new URLSearchParams(search);
  const params: { [key: string]: string } = {};

  for (const pair of searchParams.entries()) {
    params[pair[0]] = pair[1];
  }

  return params;
};
