import classnames from 'classnames';
import PropTypes from 'prop-types';

const OnboardingShowcase = props => {
  const classname = classnames('k-onboardingShowcase', props.className);
  return (
    <div className={classname}>
      <div className="k-onboardingShowcase__top">
        <div className="k-onboardingShowcase__title">{props.title}</div>
        <div className="k-onboardingShowcase__headline">{props.headline}</div>
      </div>
      <div className="k-onboardingShowcase__bottom">
        <div className="k-onboardingShowcase__mainImage">
          <img src={props.mainImage} alt="unauthenticatedShowcaseMainImage" />
        </div>
        <div className="k-onboardingShowcase__reference k-onboardingReference">
          <div className="k-onboardingReference__avatar">
            <img src={props.reference.avatar} alt="onboardingReferenceAvatar" />
          </div>
          <div className="k-onboardingReference__data">
            <div className="k-onboardingReference__text">{props.reference.text}</div>
            <div className="k-onboardingReference__name">{props.reference.name}</div>
            <div className="k-onboardingReference__position">{props.reference.position}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

OnboardingShowcase.propTypes = {
  title: PropTypes.string,
  headline: PropTypes.string,
  mainImage: PropTypes.string,
  reference: PropTypes.shape({
    avatar: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default OnboardingShowcase;
