import * as AT from '@/constants/ActionTypes.js';

const initialState = {};

const mapHolidaysToDateMap = holidays =>
  holidays.reduce(
    (sum, holiday) => ({ ...sum, [holiday.date]: holiday.freeFromWork ? 'freeFromWorkHoliday' : 'holiday' }),
    {},
  );

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_HOLIDAYS:
      return { ...state, ...mapHolidaysToDateMap(action.payload) };
    default:
      return state;
  }
};
export default reducer;
