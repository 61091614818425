import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import './SearchBox.scss';

const messages = defineMessages({
  search: {
    id: 'ui.search',
    defaultMessage: 'Szukaj',
  },
});

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { searchString: props.searchString };
    this.handleInputChange = this.handleInputChange.bind(this);

    this.updateTimeout = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchString !== prevProps.searchString) {
      this.setState({ searchString: this.props.searchString });
    }
  }

  handleInputChange(e) {
    clearTimeout(this.updateTimeout);
    const { value } = e.target;
    this.setState({ searchString: value });
    this.updateTimeout = setTimeout(() => {
      this.props.changeSearchString(value);
    }, 200);
  }

  render() {
    if (this.props.hide) return null;

    const { searchString } = this.state;

    return (
      <div className="k-searchBox">
        <input
          className="k-searchBox__input"
          type="text"
          placeholder={this.context.intl.formatMessage(messages.search, {})}
          value={searchString}
          onChange={this.handleInputChange}
        />
        <i className="material-icons k-searchBox__icon">search</i>
      </div>
    );
  }
}

SearchBox.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SearchBox.propTypes = {
  searchString: PropTypes.string,
  hide: PropTypes.bool,
  changeSearchString: PropTypes.func,
};

export default SearchBox;
