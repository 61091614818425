import type { UnknownAction } from '@reduxjs/toolkit';
import { isFulfilled, isRejected } from '@reduxjs/toolkit';
import { IntlShape } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { globalNotifications } from './notifications';

export const notificationLogger = (action: UnknownAction, intl: IntlShape) => {
  const notification = globalNotifications[action.type];
  if (!notification) return;

  const title = intl.formatMessage(notification.title);
  const description = notification.description ? intl.formatMessage(notification.description) : undefined;

  if (isRejected(action)) toastr.error(title, description);

  if (isFulfilled(action)) toastr.success(title, description);
};
