import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

const DashboardLocationsItem = ({ location, userLocations, setAttendanceModeToday }) => {
  const userLocation = userLocations.find(({ id }) => id === location.locationId);
  const navigate = useNavigate();

  if (!userLocation) return null;

  const handleShowAttendanceList = () => {
    setAttendanceModeToday(location.id, navigate);
  };

  return (
    <div className="md-dashboardLocationsItem">
      <div className="md-dashboardLocationsItem__info">
        <p className="md-dashboardLocationsItem__name">{userLocation.name}</p>
        <p className="md-dashboardLocationsItem__city">{userLocation.city}</p>
      </div>
      <p className="md-dashboardLocationsItem__text">
        {location.numberOfAttendances} / {location.numberOfShifts}
      </p>
      <p className="md-dashboardLocationsItem__text">{location.numberOfLate}</p>
      <p className="md-dashboardLocationsItem__text">{location.frequency}%</p>
      <p className="md-dashboardLocationsItem__text">{location.numberOfAbsences}</p>
      <div className="md-dashboardLocationsItem__button">
        <button onClick={handleShowAttendanceList} className="md-dashboard__link md-dashboardTeamItem__link">
          <FormattedMessage id="dashboard.showAttendanceList" defaultMessage="Zobacz listę obecności" />
        </button>
      </div>
    </div>
  );
};

DashboardLocationsItem.propTypes = {
  location: PropTypes.shape({
    numberOfAttendances: PropTypes.number,
    numberOfShifts: PropTypes.number,
    numberOfAbsences: PropTypes.number,
    numberOfLate: PropTypes.number,
    frequency: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DashboardLocationsItem;
