import { connect } from 'react-redux';

import { startShiftDrag, startSwapping, stopShiftDrag, stopSwapping } from '@/actions/schedule/dragAndDrop';

import Component from './ScheduleShift.jsx';

const mapStateToProps = (state, ownProps) => {
  const employee = state.reducer.employees.data[ownProps.employeeId];
  return {
    shift: state.reducer.shifts.data[ownProps.employeeId]?.shifts?.[ownProps.shiftId],
    employeeFirstName: employee?.first_name,
    employeeLastName: employee?.last_name,
    userJobTitles: state.reducer.userJobTitles,
    userLocations: state.reducer.userLocations,
    dragAndDropEnabled: state.reducer.schedule.dragAndDrop.enabled,
    isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
    availabilitiesModeEnabled: state.reducer.schedule.viewMode.viewMode === 'availabilities',
    isShiftDragged: state.reducer.schedule.dragAndDrop.itemType === 'shift',
    employeeContracts: state.reducer.contracts[employee?.id],
  };
};

const mapDispatchToProps = {
  startShiftDrag,
  stopShiftDrag,
  startSwapping,
  stopSwapping,
};

const ScheduleShiftContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default ScheduleShiftContainer;
