import PropTypes from 'prop-types';

import './highlightText.scss';

const HighlightText = ({ highlights }) => (
  <>
    {
      highlights.map(({ value, highlighted }, index) => (
        highlighted ?
          <span
            className='k-highlight'
            key={index}
          >
            {value}
          </span> :
          <span key={index}>
            { value }
          </span>
      ))
    }
  </>
);

HighlightText.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    highlighted: PropTypes.bool,
  })),
};

export default HighlightText;
