import PropTypes from 'prop-types';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import NameCell from '@/components/common/NameCell.jsx';
import { cutStringToMaxLength } from '@/utils/baseHelpers.js';

const AvaTypeRowTitle = props => {
  const { name, color } = props.data;
  return (
    <tr className="h-sorce">
      <td className="middle">
        <Checkbox toggle={props.toggle} checked={props.checked} disabled={props.disabled} />
        <NameCell name={cutStringToMaxLength(name, 27)} color={color} />
      </td>
    </tr>
  );
};
AvaTypeRowTitle.propTypes = {
  toggle: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default AvaTypeRowTitle;
