/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { memo } from 'react';

import ScheduleTableItemBackground from '@/components/scheduler/ScheduleTable/ScheduleTableItemBackground/ScheduleTableItemBackground.redux.js';

const EmployeeScheduleTableItem = props => {
  const { children, currentUserId, employeeId, date } = props;
  return (
    <td>
      <ScheduleTableItemBackground date={date} currentEmployeeRow={employeeId === currentUserId} />
      {children}
    </td>
  );
};

EmployeeScheduleTableItem.propTypes = {
  children: PropTypes.node,
  currentUserId: PropTypes.string,
  employeeId: PropTypes.string,
  date: PropTypes.string,
};

export default memo(EmployeeScheduleTableItem);
