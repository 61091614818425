import { useAppSelector } from '@/redux-store';
import { selectIsModalOpen } from '@/selectors/uiState.selectors';

type Props = {
  modalType: string;
  modalComponent: React.ComponentType;
};

export const OpenModal = ({ modalType, modalComponent: ModalComponent }: Props) => {
  const isModalOpen = useAppSelector(selectIsModalOpen(modalType));
  if (!isModalOpen) return null;
  return <ModalComponent />;
};
