import { RootState } from '@/redux-store';

export const selectIsModalOpen = (modalName: RootState['reducer']['uiState']['showModal']) => (state: RootState) =>
  state.reducer.uiState.showModal === modalName;

export const selectIsAnyModalOpen = (state: RootState) => state.reducer.uiState.showModal !== '';

export const selectModalObject = <T>(modalName: RootState['reducer']['uiState']['showModal']) => (
  state: RootState,
): T | undefined => state.reducer.uiState.modalObject[modalName];
