import { connect } from 'react-redux';

import Component from './ScheduleTableOpenShiftRow.jsx';

const mapStateToProps = (state, ownProps) => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  rowSelected: !!state.reducer.schedule.openShiftsRowSelect[ownProps.locationId],
  isOpenShiftsRowSticky: !!state.reducer.schedule.stickyRows.openShiftsSticky,
  restrictions: state.reducer.userPermissions.restrictions,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
