import { connect } from 'react-redux';

import { addShift, deleteShiftRequest } from '@/actions';
import { showModal } from '@/actions/uiState.js';
import DropArea from '@/components/scheduler/Position/PositionDropArea.jsx';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
  userEmployees: state.reducer.userEmployees,
  contracts: state.reducer.contracts,
});
const mapDispatchToProps = {
  addShift,
  showModal,
  deleteShiftRequest,
};
const DropAreaContainer = connect(mapStateToProps, mapDispatchToProps)(DropArea);

export default DropAreaContainer;
