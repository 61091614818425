import { connect } from 'react-redux';

import { changeReportsEmploymentConditionsFilter } from '@/actions/reports.js';
import TopBarEmploymentConditionsFilter from '@/components/TopBars/filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.jsx';

const mapStateToProps = state => ({
  employmentConditions: state.reducer.employmentConditions,
  employmentConditionsFilter: state.reducer.reports.filters.selectedEmploymentConditions,
});

const mapDispatchToProps = {
  changeEmploymentConditionsFilter: changeReportsEmploymentConditionsFilter,
};

const TopBarEmploymentConditionsFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarEmploymentConditionsFilter);

export default TopBarEmploymentConditionsFilterContainer;
