import { AVG_OPTION, BUDGET_TYPES } from '@/constants/budgetMetrics';
import { budgetMetricHash } from '@/utils/budgetHelpers';

export const calculateMetricTotalValue = (metricId, date, metricsData) => {
  const hashKey = budgetMetricHash(metricId, date);

  const totalValue =
    metricsData[hashKey] && !metricsData[hashKey].every(data => data === null)
      ? metricsData[hashKey].reduce((dataAcc, value) => dataAcc + value)
      : null;

  return totalValue;
};

export const getMetricsTotalValue = (metricId, dates, metricsData, aggregation, filledDays) => {
  const metricsTotalValue = dates.reduce((datesAcc, date) => {
    const metricValue = calculateMetricTotalValue(metricId, date, metricsData);
    if (metricValue === null) return datesAcc;
    return datesAcc + metricValue;
  }, null);

  if (metricsTotalValue === null) return null;
  return aggregation === AVG_OPTION ? metricsTotalValue / filledDays : metricsTotalValue;
};

export const getMetricValueSuffix = budgetMetric => {
  switch (budgetMetric.type) {
    case BUDGET_TYPES.CURRENCY:
      return 'PLN';
    case BUDGET_TYPES.HOUR:
      return 'h';
    case BUDGET_TYPES.PERCENT:
      return '%';
    default:
      return '';
  }
};

export const filterMetrics = (budgetMetrics, activeLocationIds) =>
  budgetMetrics.filter(({ location_ids: budgetLocationIds }) =>
    budgetLocationIds?.some(locationId => activeLocationIds.includes(locationId)),
  );

export const getNumberOfFilledDays = (budgetMetricsData, dateArray, budgetMetric) =>
  Object.entries(budgetMetricsData).reduce((filledDays, [key, value]) => {
    const [hash, date] = key.split(',');

    const isDateIncluded = dateArray.includes(date);
    const hasNonNullValues = !value.every(metricValue => metricValue === null);
    const isMatchingMetricId = budgetMetric.id === hash;

    return filledDays + (isDateIncluded && hasNonNullValues && isMatchingMetricId ? 1 : 0);
  }, 0) || 1;
