export const compareRoleObjects = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    if (a[propName].length !== b[propName].length) {
      return false;
    }
    for (let ii = 0; ii < a[propName].length; ii++) {
      if (!b[propName].find(item => item.id === a[propName][ii].id)) {
        return false;
      }
    }
  }

  return true;
};

export const settingsHasChanged = (a, b) => {
  if (JSON.stringify(a) === JSON.stringify(b)) return false;
  return true;
};

export const deepCopyObject = obj => JSON.parse(JSON.stringify(obj));
