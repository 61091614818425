import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';

const messages = defineMessages({
  textColumnFilterInputPlaceholder: {
    id: 'textColumnFilter.input.placeholder',
    defaultMessage: 'Filtruj',
  },
});

const TextColumnFilter = (props, { intl }) => {
  const {
    column: { filterValue, setFilter },
  } = props;

  return (
    <span>
      <MDTextInput
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        className="mdTable__filter"
        placeholder={intl.formatMessage(messages.textColumnFilterInputPlaceholder)}
      />
    </span>
  );
};

TextColumnFilter.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

TextColumnFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
  }),
};

export default TextColumnFilter;
