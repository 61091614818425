import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { checkArrayIntersection } from '@/utils/arrayHelpers';
import { intercomTrackEvent } from '@/utils/intercomHelpers';

import './FreemiumWrapper.scss';

const FreemiumWrapper = ({
  children,
  userRestrictions,
  freemiumRestrictions = [],
  intercomEvent,
  userId,
  companyId,
  show,
  modifiers = [],
}) => {
  if (!show && (freemiumRestrictions.length === 0 || !checkArrayIntersection(userRestrictions, freemiumRestrictions)))
    return children;

  const onClickWrapper = useCallback(
    e => {
      if (intercomEvent) {
        intercomTrackEvent(intercomEvent, { userId, companyId });
      }
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    },
    [intercomEvent, userId, companyId],
  );

  const className = classnames(
    'freemiumWrapper',
    modifiers.map(m => `freemiumWrapper--${m}`),
  );

  return (
    <div className={className} role="presentation" onClickCapture={onClickWrapper}>
      {children}
    </div>
  );
};

FreemiumWrapper.propTypes = {
  children: PropTypes.node,
  userRestrictions: PropTypes.arrayOf(PropTypes.string),
  freemiumRestrictions: PropTypes.arrayOf(PropTypes.string),
  intercomEvent: PropTypes.string,
  userId: PropTypes.string,
  companyId: PropTypes.string,
  show: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default FreemiumWrapper;
