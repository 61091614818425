import { defineMessages } from 'react-intl';

const onboardingCasesIntl = defineMessages({
  stepOneTitle: {
    id: 'onboarding.stepOne.title',
    defaultMessage: 'System RCP',
  },
  stepOneHeadline: {
    id: 'onboarding.stepOne.headline',
    defaultMessage: 'Miej wszystko pod kontrolą',
  },
  stepTwoTitle: {
    id: 'onboarding.stepTwo.title',
    defaultMessage: 'Automatyczne planowanie grafiku',
  },
  stepTwoHeadline: {
    id: 'onboarding.stepTwo.headline',
    defaultMessage: 'Grafik, który zaplanuje się sam',
  },
  stepThreeTitle: {
    id: 'onboarding.stepThree.title',
    defaultMessage: 'Aplikacja dla pracownika',
  },
  stepThreeHeadline: {
    id: 'onboarding.stepThree.headline',
    defaultMessage: 'Wygoda dla Twojego personelu',
  },
});

const onboardingIndustriesIntl = defineMessages({
  temporaryEmploymentAgency: {
    id: 'onboarding.industries.temporaryEmploymentAgency',
    defaultMessage: 'Agencja pracy tymczasowej',
  },
  architecture: {
    id: 'onboarding.industries.architecture',
    defaultMessage: 'Budownictwo',
  },
  callCenter: {
    id: 'onboarding.industries.callCenter',
    defaultMessage: 'Call Center',
  },
  education: {
    id: 'onboarding.industries.education',
    defaultMessage: 'Edukacja',
  },
  gastronomy: {
    id: 'onboarding.industries.gastronomy',
    defaultMessage: 'Gastronomia',
  },
  commerce: {
    id: 'onboarding.industries.commerce',
    defaultMessage: 'Handel',
  },
  hotel: {
    id: 'onboarding.industries.hotel',
    defaultMessage: 'Hotele',
  },
  culture: {
    id: 'onboarding.industries.culture',
    defaultMessage: 'Rozrywka, atrakcje turystyczne i kultura',
  },
  logistics: {
    id: 'onboarding.industries.logistics',
    defaultMessage: 'Logistyka',
  },
  healtcare: {
    id: 'onboarding.industries.healtcare',
    defaultMessage: 'Opieka zdrowotna',
  },
  manufacturing: {
    id: 'onboarding.industries.manufacturing',
    defaultMessage: 'Produkcja i przemysł',
  },
  sport: {
    id: 'onboarding.industries.sport',
    defaultMessage: 'Sport i Fitness',
  },
  services: {
    id: 'onboarding.industries.services',
    defaultMessage: 'Usługi',
  },
  other: {
    id: 'onboarding.industries.other',
    defaultMessage: 'Inne',
  },
});

export const onboardingRecaptchaKey = '6LdrXmsUAAAAAJ9s1WqU5OWYn45lqCYqsG4e2YsB';

/* eslint-disable max-len */
export const onboardingCases = {
  stepOne: {
    title: onboardingCasesIntl.stepOneTitle,
    headline: onboardingCasesIntl.stepOneHeadline,
    mainImage: '/img/onboarding/onboardingStepOne.png',
    reference: {
      name: 'Magda Bartczak',
      position: 'Sieć sklepów Colian, Kierownik',
      avatar: '/img/onboarding/onboardingReferenceAvatar1.png',
      text:
        'Zaplanowanie kolejnego miesiąca pracy zajmuje kilka minut, a komunikacja z pracownikami, w tym temacie, jest bardzo ułatwiona',
    },
  },
  stepTwo: {
    title: onboardingCasesIntl.stepTwoTitle,
    headline: onboardingCasesIntl.stepTwoHeadline,
    mainImage: '/img/onboarding/onboardingStepTwo.png',
    reference: {
      name: 'Ola Łapczyńska',
      position: 'Pasibus, Kelner',
      avatar: '/img/onboarding/onboardingReferenceAvatar2.png',
      text:
        'Mam wszystko czego potrzebuje: stały dostęp do grafika pracy, możliwość zgłoszenia mojej dyspozycyjności, a przy okazji bezpośrednio kontakt z pracodawcą i managerem.',
    },
  },
  stepThree: {
    title: onboardingCasesIntl.stepThreeTitle,
    headline: onboardingCasesIntl.stepThreeHeadline,
    mainImage: '/img/onboarding/onboardingStepThree.png',
    reference: {
      name: 'Piotr Podrażka',
      position: 'Kantory Dorex, Prezes Zarządu',
      avatar: '/img/onboarding/onboardingReferenceAvatar3.png',
      text: 'Tworzenie grafików i podsumowania okresu rozliczeniowego mam na jedno kliknięcie.',
    },
  },
};

export const onboardingIndustries = [
  { order: 1, value: 'Agencja pracy tymczasowej', label: onboardingIndustriesIntl.temporaryEmploymentAgency },
  { order: 1, value: 'Budownictwo', label: onboardingIndustriesIntl.architecture },
  { order: 1, value: 'Call Center', label: onboardingIndustriesIntl.callCenter },
  { order: 1, value: 'Edukacja', label: onboardingIndustriesIntl.education },
  { order: 1, value: 'Gastronomia', label: onboardingIndustriesIntl.gastronomy },
  { order: 1, value: 'Handel', label: onboardingIndustriesIntl.commerce },
  { order: 1, value: 'Hotele', label: onboardingIndustriesIntl.hotel },
  { order: 1, value: 'Rozrywka, atrakcje turystyczne i kultura', label: onboardingIndustriesIntl.culture },
  { order: 1, value: 'Logistyka', label: onboardingIndustriesIntl.logistics },
  { order: 1, value: 'Opieka zdrowotna', label: onboardingIndustriesIntl.healtcare },
  { order: 1, value: 'Produkcja i przemysł', label: onboardingIndustriesIntl.manufacturing },
  { order: 1, value: 'Sport i Fitness', label: onboardingIndustriesIntl.sport },
  { order: 1, value: 'Usługi', label: onboardingIndustriesIntl.services },
  { order: 2, value: 'Inne', label: onboardingIndustriesIntl.other },
];

export const onboardingCompanySizes = [
  { order: 1, value: '1-10', key: '1-10' },
  { order: 2, value: '11-50', key: '11-50' },
  { order: 3, value: '51-250', key: '51-250' },
  { order: 4, value: '251-1000', key: '251-1000' },
  { order: 5, value: '+1000', key: '+1000' },
];
