import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  schedule: {
    id: 'scheduleBudgetTable.options.schedule',
    defaultMessage: 'Prognoz. grafik',
  },
  estimates: {
    id: 'scheduleBudgetTable.options.estimates',
    defaultMessage: 'Planowana sprzedaż',
  },
  percentEstimates: {
    id: 'scheduleBudgetTable.options.percentEstimates',
    defaultMessage: 'Udział % kosztów pracy w sprzedaży',
  },
  sale: {
    id: 'scheduleBudgetTable.options.sale',
    defaultMessage: 'Sprzedaż na roboczogodzinę',
  },
  budgetMetrics: {
    id: 'scheduleBudgetTable.options.budgetMetrics',
    defaultMessage: 'Metryki budżetu',
  },
});
