import { defineMessages } from 'react-intl';

const messages = defineMessages({
  averageWorkedHoursPerEmployee: {
    id: 'reports.averageWorkedHoursPerEmployeeLabel',
    defaultMessage: 'Przepracowane',
  },
  averagePlannedHoursPerEmployee: {
    id: 'reports.averagePlannedHoursPerEmployeeLabel',
    defaultMessage: 'Zaplanowane',
  },
});

export const metadata = [
  {
    key: 'averagePlannedHours',
    color: '#FF6B01',
    label: messages.averagePlannedHoursPerEmployee,
    order: 1,
    yAxisId: 'left',
    chartType: 'line',
  },
  {
    key: 'averageWorkedHours',
    color: '#2C223F',
    label: messages.averageWorkedHoursPerEmployee,
    order: 2,
    yAxisId: 'left',
    chartType: 'line',
  },
];

export const axisMetadata = {
  left: {
    unit: 'h',
  },
};
