import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import ErrorMessage from '@/components/common/Basic/ErrorMessage/ErrorMessage.tsx';
import { KadroListSelect } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { NARROW } from '@/constants/modalModifiers.js';
import { useMultiSelect } from '@/hooks';
import { PayrollSummaryItem } from '@/types';

import { errorMessages, messages } from './ChoosePayrollSummaryModal.messages.ts';

interface Props {
  hideModal: () => void;
  isVisible: boolean;
  summaryValues: PayrollSummaryItem[];
  choosePayrollSummaryValues: (newItems: string[]) => void;
  restrictions: string[];
}

const getInitialItems = (summaryValues: PayrollSummaryItem[], intl: any, restrictions: string[]) =>
  summaryValues
    .filter(item => !item.restrictions?.some(restriction => restrictions.includes(restriction)))
    .map(item => ({
      id: item.id,
      value: item.id,
      label: intl.formatMessage(messages[item.id]),
      active: item.active,
    }));

const ALLOWED_NUMBER_OF_ITEMS = 4;

const ChoosePayrollSummaryValuesModal = (
  { isVisible, hideModal, summaryValues, choosePayrollSummaryValues, restrictions }: Props,
  { intl },
) => {
  const initialSummaryValues = useMemo(() => getInitialItems(summaryValues, intl, restrictions), [summaryValues]);

  const [items, handlers] = useMultiSelect(initialSummaryValues);
  const [error, setError] = useState('');

  useEffect(() => {
    const selectedItems = items.filter(item => item.active);
    if (selectedItems.length < ALLOWED_NUMBER_OF_ITEMS && selectedItems.length === items.length) {
      setError('');
      return;
    }

    if (selectedItems.length > ALLOWED_NUMBER_OF_ITEMS) {
      setError('tooMuchItemsSelected');
    }
    if (selectedItems.length < ALLOWED_NUMBER_OF_ITEMS) {
      setError('tooLittleItemsSelected');
    }
    if (selectedItems.length === ALLOWED_NUMBER_OF_ITEMS) {
      setError('');
    }
  }, [items]);

  useEffect(() => {
    handlers.setItems(initialSummaryValues);
  }, [summaryValues]);

  const handleSubmit = () => {
    choosePayrollSummaryValues(
      items.map(item => ({
        id: item.id,
        active: item.active,
      })),
    );
    hideModal();
  };

  return (
    <MDKadroModal
      show={isVisible}
      onHide={hideModal}
      modifiers={NARROW}
      title={intl.formatMessage(messages.modalTitle)}
      disableConfirm={error}
      onSubmit={handleSubmit}
    >
      <KadroListSelect
        handlers={handlers}
        items={items}
        customHeight="200px"
        withoutSelectAll
        withoutDeselectAll
        title={intl.formatMessage(messages.selectTitle)}
      />
      <ErrorMessage>
        {error && intl.formatMessage(errorMessages[error], { count: ALLOWED_NUMBER_OF_ITEMS })}
      </ErrorMessage>
    </MDKadroModal>
  );
};

ChoosePayrollSummaryValuesModal.contextTypes = {
  intl: PropTypes.shape({}),
};

export default ChoosePayrollSummaryValuesModal;
