import {
  ADD_INIT_OLD_OVERTIME_COLLECTION,
  ADD_INIT_OVERTIME_COLLECTION,
  ADD_OLD_OVERTIME_COLLECTION_FAILURE,
  ADD_OLD_OVERTIME_COLLECTION_SUCCESS,
  ADD_OVERTIME_COLLECTION_FAILURE,
  ADD_OVERTIME_COLLECTION_SUCCESS,
  DELETE_OVERTIME_COLLECTIONS_SUCCESS,
  EDIT_OVERTIME_COLLECTION_SUCCESS,
  GET_OVERTIME_COLLECTIONS,
  PUBLISH_OVERTIME_COLLECTIONS_SUCCESSFUL,
} from '@/constants/ActionTypes.js';

const overtimeCollections = (state = {}, action) => {
  switch (action.type) {
    case GET_OVERTIME_COLLECTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_INIT_OVERTIME_COLLECTION:
      return {
        ...state,
        [action.payload.employee_id]: [...(state[action.payload.employee_id] || []), action.payload],
      };
    case ADD_OVERTIME_COLLECTION_SUCCESS: {
      const newEmployeeAbsences = state[action.newAbsence.employee_id]?.filter(absence => absence.id !== action.id);

      return {
        ...state,
        [action.newAbsence.employee_id]: [...(newEmployeeAbsences || []), action.newAbsence],
      };
    }
    case ADD_OVERTIME_COLLECTION_FAILURE:
      return {
        ...state,
        [action.employeeId]: state[action.employeeId].filter(absence => absence.id !== action.temporaryId),
      };
    case ADD_INIT_OLD_OVERTIME_COLLECTION: {
      const newOvertimeCollection = { ...action.payload, isLegacyOvertime: true };
      return {
        ...state,
        [action.payload.employee_id]: [...(state[action.payload.employee_id] || []), newOvertimeCollection],
      };
    }
    case ADD_OLD_OVERTIME_COLLECTION_SUCCESS: {
      const newEmployeeAbsences = state[action.newAbsence.employee_id]?.filter(absence => absence.id !== action.id);
      const newOvertimeCollection = { ...action.newAbsence, isLegacyOvertime: true };

      return {
        ...state,
        [action.newAbsence.employee_id]: [...(newEmployeeAbsences || []), newOvertimeCollection],
      };
    }
    case ADD_OLD_OVERTIME_COLLECTION_FAILURE:
      return {
        ...state,
        [action.employeeId]: state[action.employeeId].filter(absence => absence.id !== action.temporaryId),
      };
    case EDIT_OVERTIME_COLLECTION_SUCCESS: {
      const newEmployeeAbsences = state[action.newAbsence.employee_id]?.filter(
        absence => absence.id !== action.newAbsence.id,
      );
      const newOvertimeCollection = { ...action.newAbsence, isLegacyOvertime: true };

      return {
        ...state,
        [action.newAbsence.employee_id]: [...newEmployeeAbsences, newOvertimeCollection],
      };
    }
    case DELETE_OVERTIME_COLLECTIONS_SUCCESS: {
      const newState = { ...state };

      Object.keys(newState).forEach(employeeId => {
        newState[employeeId] = newState[employeeId].filter(absence => !action.payload.includes(absence.id));
      });

      return newState;
    }
    case PUBLISH_OVERTIME_COLLECTIONS_SUCCESSFUL: {
      return Object.keys(state).reduce(
        (result, employeeId) => ({
          ...result,
          [employeeId]: state[employeeId].map(absence => {
            if (action.absenceIds.includes(absence.id)) {
              return {
                ...absence,
                draft: false,
              };
            }

            return absence;
          }),
        }),
        {},
      );
    }
    default:
      return state;
  }
};

export default overtimeCollections;
