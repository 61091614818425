import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  regular: 0,
  blocking: 0,
  init: 0,
};

const loaderState = (state = initialState, action) => {
  switch (action.type) {
    case AT.INCREASE_LOADER_COUNTER: {
      return {
        ...state,
        [action.payload]: state[action.payload] + 1,
      };
    }
    case AT.DECREASE_LOADER_COUNTER: {
      return {
        ...state,
        [action.payload]: state[action.payload] - 1,
      };
    }
    case AT.RESET_LOADER_COUNTER: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
export default loaderState;
