import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { getOS } from '@/utils/baseHelpers';

import './scheduleShiftBlockDropArea.scss';

const ScheduleShiftBlockDropArea = ({ shiftId, employeeId, handleSwapShiftDrop }) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const className = classnames('k-scheduleBlock__dropArea', {
    'k-scheduleBlock__dropArea--isDraggedOver': isDraggedOver,
  });

  const onDrop = e => {
    e.preventDefault();
    const isMacOs = getOS() === 'mac';
    const isSwapButton = isMacOs ? e.metaKey && e.altKey : e.altKey;
    handleSwapShiftDrop(shiftId, employeeId, isSwapButton);
    setIsDraggedOver(false);
  };

  const onDragEnter = e => {
    e.preventDefault();
    setIsDraggedOver(true);
  };
  const onDragLeave = e => {
    e.preventDefault();
    setIsDraggedOver(false);
  };
  return <div className={className} onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave} />;
};

ScheduleShiftBlockDropArea.propTypes = {
  shiftId: PropTypes.string,
  employeeId: PropTypes.string,
  handleSwapShiftDrop: PropTypes.func,
};

export default ScheduleShiftBlockDropArea;
