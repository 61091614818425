import { defineMessages } from 'react-intl';

export const jobTitlesMultiSelectMessages = defineMessages({
  headerTitle: { id: 'filterStep.jobTitles', defaultMessage: 'Stanowiska' },
  selectAll: { id: 'filterStep.jobTitles.all', defaultMessage: 'wszystkie' },
  deselectAll: { id: 'filterStep.jobTitles.none', defaultMessage: 'żadne' },
  bodyLabel: { id: 'filterStep.jobTitles.choose', defaultMessage: 'Wybierz stanowiska' },
});

export const locationsMultiSelectMessages = defineMessages({
  headerTitle: { id: 'filterStep.locations', defaultMessage: 'Lokalizacje' },
  selectAll: { id: 'filterStep.locations.all', defaultMessage: 'wszystkie' },
  deselectAll: { id: 'filterStep.locations.none', defaultMessage: 'żadna' },
  bodyLabel: { id: 'filterStep.locations.choose', defaultMessage: 'Wybierz lokalizacje' },
});

export const employmentConditionsMultiSelectMessages = defineMessages({
  headerTitle: { id: 'filterStep.employmentConditions', defaultMessage: 'Warunki zatrudnienia' },
  selectAll: { id: 'filterStep.employmentConditions.all', defaultMessage: 'wszystkie' },
  deselectAll: { id: 'filterStep.employmentConditions.none', defaultMessage: 'żadne' },
  bodyLabel: { id: 'filterStep.employmentConditions.choose', defaultMessage: 'Wybierz warunki zatrudnienia' },
});

export const exportMessages = defineMessages({
  exportFiltersStepDateRangeTitle: {
    id: 'exportFiltersStep.dateRange.title',
    defaultMessage: 'Zakres dat',
  },
});
