import classnames from 'classnames';
import PropTypes from 'prop-types';

import PaymentNotification from '@/components/common/PaymentNotification/PaymentNotification.redux';

const TopBar = (props) => {
  const topBarModifiers = props.modifiers || [];
  const topBarClassnames = classnames(
    'k-topBar',
    props.className,
    ...topBarModifiers.map((modifier) => `k-topBar--${modifier}`),
  );
  const topLeftClassnames = classnames('k-topBar__leftElements', {
    'k-topBar__leftElements--full': !(props.rightElements && props.rightElements.length),
    ...topBarModifiers.map((modifier) => `k-topBar__leftElements--${modifier}`),
  });
  return (
    <>
      <PaymentNotification />
      <div className={topBarClassnames}>
        {props.leftElements && props.leftElements.length ? (
          <div className={topLeftClassnames}>{props.leftElements}</div>
        ) : null}
        {props.rightElements && props.rightElements.length ? (
          <div className="k-topBar__rightElements">{props.rightElements}</div>
        ) : null}
      </div>
    </>
  );
};

TopBar.propTypes = {
  leftElements: PropTypes.arrayOf(PropTypes.shape({})),
  rightElements: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default TopBar;
