import { CHANGE_EMPLOYMENT_CONDITION_FILTER,INIT_EMPLOYMENT_CONDITION_FILTER } from '@/constants/ActionTypes.js';

export const initEmploymentConditionsFilter = employmentConditions => ({
  type: INIT_EMPLOYMENT_CONDITION_FILTER,
  payload: employmentConditions,
});

export const changeEmploymentConditionsFilter = employmentConditions => ({
  type: CHANGE_EMPLOYMENT_CONDITION_FILTER,
  payload: employmentConditions,
});
