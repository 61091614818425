import { isEmpty } from 'lodash';
import { IntlShape } from 'react-intl';

import { MappedLoanEmployeesProposal, NewProposalData } from '@/types/loanEmployeesProposals';

import { EmployeeItem } from '../../loanEmployeesProposalModals.helpers';
import { messages } from './loanEmployeesProposalDetailsStep.messages';

export const MAX_DISPLAYED_EMPLOYEES_COUNT = 3;

export const getInfo = (employeeItems: EmployeeItem[], intl: IntlShape) => {
  const areAllEmployeesSelected = employeeItems.every(({ active }) => active);

  if (areAllEmployeesSelected) {
    return intl.formatMessage(messages.all);
  }
  const numberOfSelectedEmployees = employeeItems.filter(({ active }) => active).length;
  const selectedEmployees = employeeItems.filter(({ active }) => active).map(({ label }) => label);

  if (numberOfSelectedEmployees > MAX_DISPLAYED_EMPLOYEES_COUNT) {
    const formattedSelectedEmployees = selectedEmployees.slice(0, MAX_DISPLAYED_EMPLOYEES_COUNT).join(', ');

    return intl.formatMessage(messages.andOthers, {
      selectedEmployees: formattedSelectedEmployees,
      count: numberOfSelectedEmployees - MAX_DISPLAYED_EMPLOYEES_COUNT,
    });
  }
  const formattedSelectedEmployees = selectedEmployees.join(', ');

  return formattedSelectedEmployees;
};

export const getLoanEmployeesProposalDetails = (
  loanEmployeesProposal: MappedLoanEmployeesProposal | NewProposalData,
  intl: IntlShape,
  employeeItems?: EmployeeItem[],
) => {
  if (isEmpty(loanEmployeesProposal)) return [];

  const {
    created_by: createdBy,
    location,
    employees_remaining: employeesRemaining,
    working_hours: workingHours,
    comment,
    date,
    jobTitle,
    employees_required: employeesRequired,
  } = loanEmployeesProposal;

  const applicant = `${createdBy.first_name} ${createdBy.last_name}`;
  const detailItems = [
    {
      id: 1,
      label: intl.formatMessage(messages.applicant),
      info: applicant,
      infoModifiers: ['textOverflow'],
      sortIndex: 1,
    },
    {
      id: 2,
      label: intl.formatMessage(messages.location),
      info: location.name,
      infoModifiers: ['textOverflow'],
      sortIndex: 2,
    },
    { id: 3, label: intl.formatMessage(messages.day), info: date, sortIndex: 3 },
    {
      id: 4,
      label: intl.formatMessage(messages.jobTitles),
      info: jobTitle,
      infoModifiers: ['textOverflow'],
      sortIndex: 4,
    },
    {
      id: 5,
      label: intl.formatMessage(messages.employeesRemaining),
      info: employeesRemaining || employeesRequired,
      sortIndex: 5,
    },
    { id: 6, label: intl.formatMessage(messages.workingHours), info: workingHours, sortIndex: 6 },
    { id: 8, label: intl.formatMessage(messages.comment), info: comment, sortIndex: 8 },
  ];

  if (employeeItems?.length) {
    const newEmployeeItem = {
      id: 7,
      label: intl.formatMessage(messages.employees),
      info: getInfo(employeeItems, intl),
      infoModifiers: ['unbrokenWord'],
      sortIndex: 7,
    };
    detailItems.push(newEmployeeItem);
    return detailItems.sort((a, b) => a.sortIndex - b.sortIndex);
  }

  return detailItems;
};
