import PropTypes from 'prop-types';

import './ProgressBar.scss';

const ProgressBar = ({ className = '', value, max }) => (
  <div className={`progress-bar ${className}`}>
    <div className="progress-bar__fill" style={{ transform: `scaleX(${value / max})` }} />
  </div>
);

ProgressBar.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  value: PropTypes.number,
};

export default ProgressBar;
