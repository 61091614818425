import { connect } from 'react-redux';

import { addShift } from '@/actions';
import { showModal } from '@/actions/uiState.js';

import ScheduleSingleDayNewShiftLayer from './ScheduleSingleDayNewShiftLayer';

const mapStateToProps = state => ({
  restrictions: state.reducer.userPermissions.restrictions,
  isAnyShiftDragged: Boolean(state.reducer.schedule.dragAndDrop.draggedShiftId),
  isAnyOpenShiftDragged: Boolean(state.reducer.schedule.dragAndDrop.draggedOpenShiftId),
  userEmployees: state.reducer.userEmployees,
  isAvaDragged: Boolean(state.reducer.schedule.dragAndDrop.draggedAvailability.id),
});

const mapDispatchToProps = {
  showModal,
  addShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSingleDayNewShiftLayer);
