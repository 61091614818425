import moment from 'moment';
import PropTypes from 'prop-types';
import { defineMessages,FormattedMessage } from 'react-intl';

import KadroModal from '@/components/common/KadroModal.jsx';
import { TIME_TRACKER_SUMMARY } from '@/constants/modalTypes.js';
import { parseMinutesToFormat } from '@/utils/dateHelper.js';

const messages = defineMessages({
  modalTitle: {
    id: 'timeTrackerSummaryModal.modalTitle',
    defaultMessage: 'Podsumowanie zmiany',
  },
  modalConfirm: {
    id: 'timeTrackerSummaryModal.modalConfirm',
    defaultMessage: 'Zakończ',
  },
});

const DashboardTrackerSummary = (props, { intl }) => {
  const { currentAttendance, handleEndWork, show, locations, hideModal } = props;

  const breakTime = currentAttendance.breaks?.reduce((acc, cur) => {
    const endTime = cur.end_timestamp || moment();
    const diff = moment(endTime).diff(cur.start_timestamp, 'seconds');
    return acc + diff;
  }, 0);

  const totalWorkTime = moment().diff(currentAttendance.start_timestamp, 'seconds') - breakTime;

  const startText = moment(currentAttendance.start_timestamp).format('HH:mm');
  const endText = moment().format('HH:mm');
  const breakTimeText = parseMinutesToFormat(breakTime / 60);
  const location = locations.find(loc => loc.id === currentAttendance.location_id);
  const totalWorkTimeText = parseMinutesToFormat(totalWorkTime / 60);

  const handleSubmit = () => {
    handleEndWork();
    hideModal(TIME_TRACKER_SUMMARY);
  };

  return (
    <KadroModal
      showModal={show}
      onHide={hideModal}
      title={intl.formatMessage(messages.modalTitle)}
      onSubmit={handleSubmit}
      confirmText={intl.formatMessage(messages.modalConfirm)}
    >
      <div style={{ minHeight: '300px' }}>
        <div>
          <FormattedMessage
            id="dashboard.timeTackerSummary.startHour"
            defaultMessage="Godzina rozpoczęcia: {startText}"
            values={{
              startText,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id="dashboard.timeTackerSummary.endHour"
            defaultMessage="Godzina zakończenia: {endText}"
            values={{
              endText,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id="dashboard.timeTackerSummary.breaksTime"
            defaultMessage="Czas na przerwie: {breakTimeText}"
            values={{
              breakTimeText,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id="dashboard.timeTackerSummary.location"
            defaultMessage="Lokalizacja: {locationName}"
            values={{
              locationName: location && location.name,
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id="dashboard.timeTackerSummary.totalWorkTime"
            defaultMessage="Całkowity czas: {totalWorkTimeText}"
            values={{
              totalWorkTimeText,
            }}
          />
        </div>
      </div>
    </KadroModal>
  );
};

DashboardTrackerSummary.propTypes = {
  show: PropTypes.bool,
  locations: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  currentAttendance: PropTypes.shape({
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    breaks: PropTypes.arrayOf({
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  hideModal: PropTypes.func,
  handleEndWork: PropTypes.func,
};

DashboardTrackerSummary.contextTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default DashboardTrackerSummary;
