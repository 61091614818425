import PropTypes from 'prop-types';
import { Component } from 'react';

import { absencesDisplayModes } from '@/constants/absencesDisplayModes.js';

import AbsencesLimitsView from '../AbsencesLimitsView/AbsencesLimitsViewContainer.js';
import AbsencesView from '../AbsencesView/AbsencesViewContainer.js';

class AbsencesModeView extends Component {
  componentDidMount() {
    const { restrictions } = this.props.userPermissions;
    const availableModes = absencesDisplayModes(restrictions).map(displayMode => ({
      ...displayMode,
      displayText: this.context.intl.formatMessage(displayMode.displayText),
    }));
    const availableTypes = availableModes.map(({ type }) => type);
    if (!availableTypes.includes(this.props.selectedDisplayMode.type)) {
      this.props.changeAbsencesMode(availableModes[0]);
    }
  }

  displayRelevantView(mode) {
    switch (mode) {
      case 'proposals':
        return <AbsencesView />;
      case 'limits':
        return <AbsencesLimitsView />;
      default:
        return null;
    }
  }

  render() {
    return this.displayRelevantView(this.props.selectedDisplayMode.type);
  }
}

AbsencesModeView.contextTypes = {
  intl: PropTypes.shape({}),
};

AbsencesModeView.propTypes = {
  selectedDisplayMode: PropTypes.shape({
    type: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default AbsencesModeView;
