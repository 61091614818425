import { connect } from 'react-redux';

import { setNotificationSettings } from '@/actions/settings';

import NotificationSettings from './NotificationSettings.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  userSettings: state.reducer.settings.usersSettings[state.reducer.currentUser.user.id],
  locationsSettings: state.reducer.settings.locationSettings,
});

const mapDispatchToProps = {
  setNotificationSettings,
};

const NotificationSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);

export default NotificationSettingsContainer;
