import {
  CHANGE_SORTING_PAYROLL_LOCATION,
  COMPANYMANAGE_CHANGE_SORTING,
  COMPANYMANAGE_START_EDIT,
  DELETE_AVA_TYPE,
  DELETE_AVA_TYPE_SET,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SET,
  DELETE_EMPLOYMENT_CONDITION,
  DELETE_JOBTITLE,
  DELETE_JOBTITLE_SET,
  DELETE_LOCATION,
  DELETE_LOCATION_SET,
  DELETE_SHIFTBLOCK,
  DELETE_SHIFTBLOCK_SET,
  DESELECT_ALL_COMPANYMANAGE_CHECKBOXES,
  MASS_DELETE_EMPLOYMENT_CONDITIONS,
  SET_ATTENDANCES_SEARCH_STRING,
  TOGGLE_ALL_COMPANYMANAGE_CHECKBOXES,
  TOGGLE_COMPANYMANAGE_CHECKBOX,
  TOGGLE_COMPANYMANAGE_MODAL,
} from '@/constants/ActionTypes.js';
import { deleteIfExistsOrAdd } from '@/utils/arrayHelpers.js';

const initialState = {
  avaTypes: {
    showModal: false,
    editedId: null,
    editMode: false,
    sortColumn: 0,
    sortDecreesing: false,
    selected: [],
  },
  employees: {
    editedId: null,
    editMode: false,
    sortColumn: 'name',
    sortDecreesing: false,
    selected: [],
  },
  jobTitles: {
    showModal: false,
    editedId: null,
    editMode: false,
    sortColumn: 0,
    sortDecreesing: false,
    selected: [],
  },
  locations: {
    showModal: false,
    editedId: null,
    editMode: false,
    sortColumn: 0,
    sortDecreesing: false,
    selected: [],
  },
  shiftBlocks: {
    showModal: false,
    editedId: null,
    editMode: false,
    sortColumn: 0,
    sortDecreesing: false,
    selected: [],
  },
  employmentConditions: {
    editedId: null,
    editMode: false,
    sortColumn: 0,
    sortDecreesing: false,
    selected: [],
  },
  payrollLocation: {
    sortColumn: 'name',
    sortDirection: 1,
  },
  attendance: {
    selected: [],
    searchString: '',
  },
  devices: {
    selected: [],
    sortColumn: 0,
    sortDecreesing: false,
  },
};

function deselectAfterDeleteAll(state, context) {
  const list = Object.assign({}, state[context]);
  list.selected = [];
  const newState = Object.assign({}, state);
  newState[context] = list;
  return newState;
}

function deselectAfterDelete(state, objectId, context) {
  const list = Object.assign({}, state[context]);
  list.selected = list.selected.filter(i => i !== objectId);
  const newState = Object.assign({}, state);
  newState[context] = list;
  return newState;
}

const listsUi = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COMPANYMANAGE_CHECKBOX: {
      const list = Object.assign({}, state[action.context], {
        selected: deleteIfExistsOrAdd(state[action.context].selected, action.payload),
      });
      const newState = Object.assign({}, state);
      newState[action.context] = list;
      return newState;
    }
    case DESELECT_ALL_COMPANYMANAGE_CHECKBOXES: {
      const newState = Object.assign({}, state);
      newState[action.payload.context] = Object.assign({}, newState[action.payload.context], { selected: [] });
      return newState;
    }
    case TOGGLE_ALL_COMPANYMANAGE_CHECKBOXES: {
      if (
        action.payload.length &&
        action.payload.length !== !state[action.context].selected &&
        action.payload.some(id => !state[action.context].selected.includes(id))
      ) {
        return {
          ...state,
          [action.context]: {
            ...state[action.context],
            selected: action.payload,
          },
        };
      }
      return {
        ...state,
        [action.context]: {
          ...state[action.context],
          selected: [],
        },
      };
    }
    case TOGGLE_COMPANYMANAGE_MODAL: {
      const list = Object.assign({}, state[action.context]);
      if (list.showModal) {
        list.showModal = false;
        list.editedId = null;
        list.editMode = false;
      } else {
        list.showModal = true;
      }
      const newState = Object.assign({}, state);
      newState[action.context] = list;
      return newState;
    }
    case COMPANYMANAGE_START_EDIT: {
      const list = Object.assign({}, state[action.context]);
      list.showModal = true;
      list.editMode = true;
      list.editedId = action.payload;
      const newState = Object.assign({}, state);
      newState[action.context] = list;
      return newState;
    }
    case COMPANYMANAGE_CHANGE_SORTING: {
      const list = Object.assign({}, state[action.context]);
      if (list.sortColumn === action.payload) {
        list.sortDecreesing = !list.sortDecreesing;
      } else {
        list.sortDecreesing = false;
      }
      list.sortColumn = action.payload;
      const newState = Object.assign({}, state);
      newState[action.context] = list;
      return newState;
    }
    case DELETE_LOCATION_SET:
      return deselectAfterDeleteAll(state, 'locations');
    case DELETE_LOCATION:
      return deselectAfterDelete(state, action.payload, 'locations');
    case DELETE_JOBTITLE_SET:
      return deselectAfterDeleteAll(state, 'jobTitles');
    case DELETE_JOBTITLE:
      return deselectAfterDelete(state, action.payload, 'jobTitles');
    case DELETE_SHIFTBLOCK_SET:
      return deselectAfterDeleteAll(state, 'shiftBlocks');
    case DELETE_SHIFTBLOCK:
      return deselectAfterDelete(state, action.payload, 'shiftBlocks');
    case DELETE_EMPLOYEE_SET:
      return deselectAfterDeleteAll(state, 'employees');
    case DELETE_EMPLOYEE:
      return deselectAfterDelete(state, action.payload, 'employees');
    case DELETE_AVA_TYPE_SET:
      return deselectAfterDeleteAll(state, 'avaTypes');
    case DELETE_AVA_TYPE:
      return deselectAfterDelete(state, action.payload, 'avaTypes');
    case MASS_DELETE_EMPLOYMENT_CONDITIONS:
      return deselectAfterDeleteAll(state, 'employmentConditions');
    case DELETE_EMPLOYMENT_CONDITION:
      return deselectAfterDelete(state, action.payload.deletedTemplateId, 'employmentConditions');
    case CHANGE_SORTING_PAYROLL_LOCATION:
      /* eslint-disable no-nested-ternary */
      // Direction is set to 1 (means increasing) always if key is different than previous one
      // Otherwise direction is just toggled between 1 and -1
      return {
        ...state,
        payrollLocation: {
          sortColumn: action.payload,
          sortDirection:
            state.payrollLocation.sortColumn !== action.payload
              ? 1
              : state.payrollLocation.sortDirection === 1
              ? -1
              : 1,
        },
      };
    case SET_ATTENDANCES_SEARCH_STRING:
      return {
        ...state,
        attendance: {
          ...state.attendance,
          searchString: action.payload,
        },
      };
    /* eslint-enable */
    default:
      return state;
  }
};
export default listsUi;
