import { Dispatch, SetStateAction } from 'react';

import AddAndEditLabelPopover from '@/components/common/AddAndEditLabelPopover/AddAndEditLabelPopover';
import Tag from '@/components/common/Tag/Tag';

import { Option } from '../../MultiSelectOptionManagerDropDown';

interface OptionItemProps {
  option: Option;
  handleOptionSelect: (option: Option) => void;
  setPopoverStatus: Dispatch<SetStateAction<boolean>>;
  clearInputValue: () => void;
  hideEdit: boolean;
}

const OptionItem = ({ option, handleOptionSelect, setPopoverStatus, clearInputValue, hideEdit }: OptionItemProps) => {
  const { selected, show, label, color } = option;
  const showOption = !selected && show;

  return (
    showOption && (
      <div className="k-multiSelectOptionManager__option" key={label}>
        <button className="k-multiSelectOptionManager__optionTitle" onClick={() => handleOptionSelect(option)}>
          <Tag color={color} name={label} />
        </button>
        {!hideEdit && (
          <div className="k-multiSelectOptionManager__wrapperPopover">
            <AddAndEditLabelPopover
              wrapperClassName="k-multiSelectOptionManager__wrapperPopover"
              label={option}
              setPopoverStatus={setPopoverStatus}
              edit
              clearInputValue={clearInputValue}
            >
              <button className="k-multiSelectOptionManager__editButton">
                <span className="material-symbols-outlined k-multiSelectOptionManager__icon">more_horiz</span>
              </button>
            </AddAndEditLabelPopover>
          </div>
        )}
      </div>
    )
  );
};

export default OptionItem;
