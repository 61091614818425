import { isEmptyArray } from '../array/array.helpers';

export const isEmptyObject = <T extends object>(obj: T) => obj && isEmptyArray(Object.keys(obj));

export const objectLength = <T extends object>(obj: T) => Object.keys(obj).length;

export const transformToBoolDict = <T extends string>(arr: T[]): Record<T, boolean> =>
  arr.reduce<Record<string, boolean>>((acc, item) => ({ ...acc, [item]: true }), {});

export const transformToDict = <T extends object>(data: T[], key: string & keyof T): Record<string, T> =>
  data.reduce((p, c) => ({ ...p, [c[key] as unknown as string]: c }), {} as Record<string, T>);
