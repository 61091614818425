import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState.js';

import InfoEventModal from './InfoEventModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal,
  modalObject: state.reducer.uiState.modalObject,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
};
const EditEventModalContainer = connect(mapStateToProps, mapDispatchToProps)(InfoEventModal);

export default EditEventModalContainer;
