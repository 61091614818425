import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import ManagerNoteCorn from './ManagerNoteCorn.jsx';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showModal,
};

const ManagerNoteCornContainer = connect(mapStateToProps, mapDispatchToProps)(ManagerNoteCorn);

export default ManagerNoteCornContainer;
