import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { toBase64 } from '@/utils/fileHelpers.js';

const SHIFTS_AND_ATTENDANCES_MODAL = 'Import godzin pracy';

const ImportShiftsAndAttendancesModal = props => {
  const {
    importModalObject,
    pendingExports,
    clearImportModal,
    clearScheduleImports,
    hideModal,
    runExport,
    toggleImportModalOverlay,
    uploadFileToImport,
  } = props;

  const importObject = [...pendingExports]
    .reverse()
    .find(
      e =>
        e.type === 'f-shifts-and-attendances-import' &&
        e.status === 'success' &&
        e.name === SHIFTS_AND_ATTENDANCES_MODAL,
    );

  const hideAndClear = () => {
    toggleImportModalOverlay(false);
    uploadFileToImport(null);
    hideModal();
    clearImportModal();
    clearScheduleImports();
  };

  useEffect(() => {
    if (!importObject) return;
    toggleImportModalOverlay(false);
    if (!importObject.errors || importObject.errors.length === 0) {
      hideAndClear();
    }
  }, [importObject]);

  const handleSubmit = useCallback(async () => {
    toggleImportModalOverlay(true);
    const base64File = await toBase64(importModalObject.uploadedFile);
    await runExport('f-shifts-and-attendances-import', SHIFTS_AND_ATTENDANCES_MODAL, {
      file: base64File,
    });
  }, [importModalObject.uploadedFile]);

  return (
    <MDKadroModal
      show={props.showModal}
      onHide={hideAndClear}
      onSubmit={handleSubmit}
      modifiers={['wide']}
      title={<FormattedMessage id="import.importData" defaultMessage="Importuj dane" />}
      hideConfirmButton={!importModalObject.uploadedFile}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
      loading={importModalObject.showOverlay}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importSchedule.loadData" defaultMessage="Wczytaj dane" />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop fileFormats={['xlsx']} hideButton onUpload={uploadFileToImport} />
        </div>
      </div>
    </MDKadroModal>
  );
};

ImportShiftsAndAttendancesModal.propTypes = {
  showModal: PropTypes.bool,
  runExport: PropTypes.func,
  hideModal: PropTypes.func,
  clearImportModal: PropTypes.func,
  pendingExports: PropTypes.arrayOf(PropTypes.shape({})),
  uploadFileToImport: PropTypes.func,
  toggleImportModalOverlay: PropTypes.func,
  importModalObject: PropTypes.shape({
    uploadedFile: PropTypes.string,
    importedData: PropTypes.arrayOf(PropTypes.shape({})),
    showOverlay: PropTypes.bool,
  }),
  clearScheduleImports: PropTypes.func,
};

export default ImportShiftsAndAttendancesModal;
