import PropTypes from 'prop-types';
import { useMemo } from 'react';

import AvaCorner from '@/components/scheduler/ScheduleTable/AvaMode/AvaCorner/AvaCorner.jsx';
import { getExtendedAvailabilityBlock } from '@/utils/availabilitiesHelpers';

const EmployeeScheduleAvaCorner = (props, { intl }) => {
  const { userCustomTypes, employee, date } = props;

  const availability = employee.availability_blocks.find(ava => ava.date === date);
  if (!availability) return null;

  const extendedAvailability = useMemo(
    () => getExtendedAvailabilityBlock(availability, userCustomTypes, employee, intl),
    [availability, userCustomTypes, employee],
  );

  return <AvaCorner popover={extendedAvailability.popover} cornerColor={extendedAvailability.color} />;
};

EmployeeScheduleAvaCorner.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeScheduleAvaCorner.propTypes = {
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  employee: PropTypes.shape({
    availability_blocks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  date: PropTypes.string,
};

export default EmployeeScheduleAvaCorner;
