import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';

import messages from '../AttendancesTableOptions/messages.js';

const AttendancesTableHeader = ({ field, direction, value, columns, onClick, onHeaderClick, isSelectAllBlocked }) => (
  <thead>
    <tr>
      <th>
        <MDCheckbox
          value={value}
          onChange={onClick}
          reverse
          disabled={isSelectAllBlocked}
          hoverLabel={isSelectAllBlocked && <FormattedMessage {...messages.attendancesTableOptionsEditBlocked} />}
        />
      </th>
      {columns.map(column => (
        <th onClick={() => onHeaderClick(column.accessor)} key={column.accessor}>
          <div>
            {column.header}
            {field === column.accessor && direction === 'ASC' && (
              <span className="material-icons-outlined">arrow_drop_up</span>
            )}
            {field === column.accessor && direction === 'DESC' && (
              <span className="material-icons-outlined">arrow_drop_down</span>
            )}
          </div>
        </th>
      ))}
    </tr>
  </thead>
);

AttendancesTableHeader.propTypes = {
  field: PropTypes.string,
  direction: PropTypes.string,
  value: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      header: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  isSelectAllBlocked: PropTypes.bool,
};

export default AttendancesTableHeader;
