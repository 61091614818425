import classnames from 'classnames';
import { memo, useCallback } from 'react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { UseMultiAccountsMenuValuesType } from '../../useMultiAccountsMenu';

import './InstanceLabel.scss';

type Props = {
  accountDetail: UseMultiAccountsMenuValuesType['accountsDetails'][number];
  setDefaultAccount: UseMultiAccountsMenuValuesType['setDefaultAccount'];
};

export const InstanceLabel = memo(({ accountDetail: { userId, isDefault }, setDefaultAccount }: Props) => {
  const onChangeDefaultAccount = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setDefaultAccount(userId);
    },
    [userId, isDefault, setDefaultAccount],
  );

  return (
    <div className={classnames('k-multiAccountsInstanceLabel', isDefault && 'k-multiAccountsInstanceLabel--default')}>
      {!isDefault && (
        <div className="k-multiAccountsInstanceLabel__tooltip">
          <div className="k-multiAccountsInstanceLabel__rect">
            <FormattedMessage id="accounts.setAsDefault" defaultMessage="Ustaw jako domyślne" />
          </div>
          <div className="k-multiAccountsInstanceLabel__triangle" />
        </div>
      )}
      <div role="presentation" onClick={onChangeDefaultAccount}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none" viewBox="0 0 15 18">
          <path
            className="k-multiAccountsInstanceLabel__path"
            d="M7.66 18l-6.325-4.35A1.976 1.976 0 01.461 12V2c0-.55.202-1.02.604-1.413A2.009 2.009 0 012.518 0h10.284c.565 0 1.05.196 1.452.588A1.9 1.9 0 0114.858 2v10a1.975 1.975 0 01-.874 1.65L7.66 18z"
          />
        </svg>
      </div>
    </div>
  );
});

InstanceLabel.displayName = 'InstanceLabel';
