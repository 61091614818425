import {
  ADD_NEW_TEMPLATE_SUCCESFUL,
  ADD_TEMPLATES_SHIFT_SUCCESFUL,
  CHANGE_CURRENT_TEMPLATE_SUCCESFUL,
  CHANGE_TEMPLATE_NAME_SUCCESFUL,
  DELETE_OPEN_SHIFT_TEMPLATE,
  DELETE_TEMPLATE_SUCCESFUL,
  DELETE_TEMPLATES_SHIFT_SUCCESFUL,
  DELETE_UNPUBLISHED_TEMPLATES_SHIFTS_SUCCESFUL,
  EDIT_TEMPLATES_SHIFT_SUCCESFUL,
  GET_TEMPLATES_SUCCESFUL,
  PUBLISH_DRAFT_TEMPLATE_SHIFTS_SUCCESFUL,
} from '@/constants/ActionTypes';
import { OPEN_SHIFTS_TEMPLATE_ID } from '@/constants/scheduleDisplayModes';

const initialState = {
  templatesArray: [],
  currentTemplate: {},
};

const userTemplates = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_SUCCESFUL:
      if (action.payload.length) {
        return {
          currentTemplate: { ...action.payload[0] },
          templatesArray: action.payload,
        };
      }
      return state;
    case DELETE_TEMPLATE_SUCCESFUL: {
      const templateToDeleteIndex = state.templatesArray.findIndex(template => template.id === action.payload);
      const newTemplateArray = [
        ...state.templatesArray.slice(0, templateToDeleteIndex),
        ...state.templatesArray.slice(templateToDeleteIndex + 1),
      ];
      return {
        ...state,
        currentTemplate: newTemplateArray.length ? newTemplateArray[0] : {},
        templatesArray: newTemplateArray,
      };
    }
    case ADD_TEMPLATES_SHIFT_SUCCESFUL:
    case EDIT_TEMPLATES_SHIFT_SUCCESFUL:
    case DELETE_TEMPLATES_SHIFT_SUCCESFUL:
    case DELETE_UNPUBLISHED_TEMPLATES_SHIFTS_SUCCESFUL:
      return {
        ...state,
        currentTemplate: action.payload,
        templatesArray: [
          ...state.templatesArray.map(template => {
            if (template.id === action.payload.id) {
              return action.payload;
            }
            return template;
          }),
        ],
      };
    case PUBLISH_DRAFT_TEMPLATE_SHIFTS_SUCCESFUL:
    case CHANGE_TEMPLATE_NAME_SUCCESFUL:
      return {
        ...state,
        templatesArray: [
          ...state.templatesArray.map(template => {
            if (template.id === action.payload.id) {
              return action.payload;
            }
            return template;
          }),
        ],
        currentTemplate: action.payload,
      };
    case CHANGE_CURRENT_TEMPLATE_SUCCESFUL:
      return {
        ...state,
        currentTemplate: action.payload,
      };
    case ADD_NEW_TEMPLATE_SUCCESFUL:
      return {
        ...state,
        currentTemplate: action.payload,
        templatesArray: [action.payload, ...state.templatesArray],
      };
    case DELETE_OPEN_SHIFT_TEMPLATE: {
      const newTemplateArray = state.templatesArray.filter(({ id }) => id !== OPEN_SHIFTS_TEMPLATE_ID);
      return {
        ...state,
        currentTemplate: newTemplateArray.length ? newTemplateArray[0] : {},
        templatesArray: newTemplateArray,
      };
    }
    default:
      return state;
  }
};

export default userTemplates;
