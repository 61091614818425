import { connect } from 'react-redux';

import { replaceTemplatesShifts } from '@/actions/templates.js';
import { hideModal } from '@/actions/uiState.js';
import AddTemplatesShiftModal from '@/components/scheduler/modals/AddTemplatesShiftModal.jsx';
import { ADD_TEMPLATES_SHIFT_MODAL } from '@/constants/modalTypes.js';

const mapStateToProps = state => ({
  userTemplates: state.reducer.userTemplates,
  showModal: state.reducer.uiState.showModal === ADD_TEMPLATES_SHIFT_MODAL,
  modalObject: state.reducer.uiState.modalObject[ADD_TEMPLATES_SHIFT_MODAL],
  userShiftblocks: state.reducer.userShiftblocks,
  permissions: state.reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  replaceTemplatesShifts,
  onHide: hideModal,
};
const AddTemplatesShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddTemplatesShiftModal);

export default AddTemplatesShiftModalContainer;
