import { RootState } from '@/redux-store';
import { EmploymentCondition } from '@/types/employmentConditions.types';
import { transformToDict } from '@/utils/objectHelpers/objectHelpers';

export const selectCompanyEmploymentConditions = (state: RootState) => state.reducer.employmentConditions;

export const selectEmploymentCondition = (id: EmploymentCondition['id']) => (state: RootState) => {
  if (!id) return undefined;
  return state.reducer.employmentConditions.find(employmentCondition => employmentCondition.id === id);
};

export const selectEmploymentConditionsDict = (state: RootState) =>
  transformToDict(state.reducer.employmentConditions, 'id');
