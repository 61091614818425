import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';

import { filterOptions } from './MDMultiSelectPopoverContent.helpers';
import { messages } from './MDMultiSelectPopoverContent.messages';

import './MDMultiSelectPopoverContent.scss';

const MDMultiSelectPopoverContent = ({ options, testId, onChange, width, setFilter, filter }, { intl }) => {
  const handleFilterChange = ({ target }) => setFilter(target.value);
  return (
    <div style={{ width }} className="mdMultiSelectPopoverContent__dropdown" data-test={`${testId}-dropdownList`}>
      <div className="mdMultiSelectPopoverContent__search">
        <span className="mdMultiSelectPopoverContent__itemSearch">
          <span className="mdMultiSelectPopoverContent__searchIcon material-icons">search</span>
        </span>
        <input
          className="mdMultiSelectPopoverContent__input"
          value={filter}
          onChange={handleFilterChange}
          placeholder={intl.formatMessage(messages.search)}
          data-test={`${testId}-search`}
        />
      </div>
      <OverlayScrollbarsComponent
        className="k-overlay-scrollbar"
        options={{
          overflowBehavior: {
            x: 'hidden',
            y: 'scroll',
          },
        }}
      >
        {filterOptions(options, filter).map(({ sortValue, value, label: itemLabel, active }) => (
          <button
            key={sortValue || value}
            className="mdMultiSelectPopoverContent__item"
            onClick={() =>
              onChange({
                value,
                label: itemLabel,
                active,
              })
            }
            data-test={itemLabel}
          >
            <span className="mdMultiSelectPopoverContent__checkbox">
              {active && <span className="mdMultiSelectPopoverContent__activeIcon material-icons">done</span>}
            </span>
            <span className="mdMultiSelectPopoverContent__text">{itemLabel}</span>
          </button>
        ))}
      </OverlayScrollbarsComponent>
    </div>
  );
};

MDMultiSelectPopoverContent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
    }),
  ),
  onChange: PropTypes.func,
  width: PropTypes.number,
  setFilter: PropTypes.func,
  filter: PropTypes.string,
  testId: PropTypes.string,
};

MDMultiSelectPopoverContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
export default MDMultiSelectPopoverContent;
