import { useAppSelector } from '@/redux-store';
import { selectIsRegularLoaderShown } from '@/redux-store/loaderState';

import './LogoLoader.scss';

export const LogoLoader = () => {
  const showRegularLoader = useAppSelector(selectIsRegularLoaderShown);

  if (!showRegularLoader) return <img className="k-loader__logo" src="/img/logo2.png" alt="logo" />;

  return (
    <div className="k-loader">
      <img className="k-loader__logoLoading" src="/img/load1.png" alt="logo" />
      <img className="k-loader__tick" src="/img/load2.png" alt="logo-loader" />
    </div>
  );
};
