import { connect } from 'react-redux';

import { exportSingleQRCode } from '@/actions/exports.js';

import EmployeeSettingsView from './EmployeeSettingsView.jsx';

const mapStateToProps = state => ({
  user: state.reducer.currentUser.user,
});

const mapDispatchToProps = {
  exportSingleQRCode,
};
const EmployeeSettingsViewContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeSettingsView);

export default EmployeeSettingsViewContainer;
