/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDSelect, MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MultiSelectOptionManager from '@/components/common/inputs/MultiSelectOptionManager/MultiSelectOptionManager.tsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { INPUT_MODIFIERS } from '@/constants/modifiers.js';
import { getLocationOptions } from '@/utils/attendanceHelpers.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { moveDateForwardByOne } from '@/utils/dateHelper.js';
import { inputValidation } from '@/utils/inputValidation.js';
import { checkIfCanAddEditDeleteLabels, showLabelsFeature } from '@/utils/labels.ts';

import EditBreaks from '../breaks/EditBreaks.jsx';

import './editAttendanceModal.scss';

const messages = defineMessages({
  title: {
    id: 'attendance.editModal.title',
    defaultMessage: 'Edytuj obecność',
  },
  deleteTitle: {
    id: 'attendance.editModal.deleteTitle',
    defaultMessage: 'Usuń obecność',
  },
  deleteDescription: {
    id: 'attendance.editModal.deleteDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć obecność?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
  timeError: {
    id: 'attendance.editModal.timeError',
    defaultMessage: 'Podaj poprawny czas',
  },
  workHours: {
    id: 'attendance.editModal.workHours',
    defaultMessage: 'Czas',
  },
});

// Transform HH:mm:ss to HH:mm
const normalizeHours = hours => {
  if (!hours) return '';
  const hrSplit = hours.split('-');
  return `${hrSplit[0].slice(0, 5)}-${hrSplit[1].slice(0, 5)}`;
};

class EditAttendanceModal extends Component {
  constructor(props, context) {
    super(props, context);
    const hours = this.props.modalObject.attendance.hours !== '' ? this.props.modalObject.attendance.hours : '';
    this.state = {
      hours,
      errors: {},
      selectedLocation: '',
      selectedLabels: [],
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { modalObject, showModal } = this.props;

    if (modalObject.attendance.hours !== prevProps.modalObject.attendance.hours) {
      this.setState({ hours: normalizeHours(modalObject.attendance.hours) });
    }

    if (showModal && showModal !== prevProps.showModal) {
      const { id: selectedLocationId } = modalObject.employee.attendances.find(
        ({ id }) => id === modalObject.attendance.id,
      ).location;
      this.setState({
        selectedLocation: selectedLocationId,
      });
    }
  }

  onSubmit() {
    const changes = this.breaks.getChanges();
    if (changes.error || this.state.errors.hours) return;
    changes.forEach(change => {
      switch (change.action) {
        case 'add':
          this.props.addBreak(change.data, this.props.modalObject.employee.id);
          break;
        case 'change':
          this.props.changeBreak(change.data, this.props.modalObject.employee.id);
          break;
        case 'delete':
          this.props.deleteBreak(change.data, this.props.modalObject.employee.id);
          break;
        default:
          break;
      }
    });
    const { hours, selectedLocation } = this.state;
    const hour_split = hours.split('-');
    // TODO: Add next day split
    const startDate = this.props.modalObject.attendance.date;
    let endDate = this.props.modalObject.attendance.date;
    if (hour_split[1] < hour_split[0]) {
      endDate = moveDateForwardByOne('day', startDate);
    }
    const start_timestamp = `${startDate} ${hour_split[0]}:00`;
    const end_timestamp = `${endDate} ${hour_split[1]}:00`;
    if (
      start_timestamp.slice(0, 16) !== this.props.modalObject.attendance.start_timestamp.slice(0, 16) ||
      (this.props.modalObject.attendance.end_timestamp &&
        end_timestamp.slice(0, 16) !== this.props.modalObject.attendance.end_timestamp.slice(0, 16)) ||
      (end_timestamp && !this.props.modalObject.attendance.end_timestamp) ||
      this.props.modalObject.attendance.location.id !== selectedLocation
    ) {
      this.props.changeAttendance(
        this.props.modalObject.employee.id,
        this.props.modalObject.attendance,
        start_timestamp,
        end_timestamp,
        selectedLocation,
      );
    }

    const labelsAssignedToAttendance = this.props.modalObject.attendance.labels.map(({ id }) => id);
    const unassignedLabels = labelsAssignedToAttendance.filter(label => !this.state.selectedLabels.includes(label));
    const assignLabelsToAttendance = this.state.selectedLabels.filter(
      label => !labelsAssignedToAttendance.includes(label),
    );

    if (assignLabelsToAttendance?.length)
      this.props.assignLabelsToAttendance(
        this.props.modalObject.attendance.id,
        this.props.modalObject.employee.id,
        this.state.selectedLabels,
      );

    if (unassignedLabels?.length)
      this.props.unassignLabelsFromAttendance(
        this.props.modalObject.attendance.id,
        this.props.modalObject.employee.id,
        unassignedLabels,
      );

    this.hideAndClear();
  }

  hideAndClear() {
    this.setState({ hours: '', errors: {} });
    this.props.onHide();
  }

  changeHours(e) {
    const hours = e.target.value;
    this.setState(prevState => ({
      hours,
      errors: {
        ...prevState.errors,
        hours: inputValidation('attendanceHours', hours) ? this.context.intl.formatMessage(messages.timeError, {}) : '',
      },
    }));
  }

  getObjectHistory() {
    this.props.getHistoryForAttendance(this.props.modalObject.attendance);
  }

  getFooterOptions() {
    return [
      {
        icon: 'history',
        text: <FormattedMessage id="attendance.editModal.history" defaultMessage="Historia" />,
        handleClick: this.getObjectHistory,
      },
      {
        icon: 'remove_circle_outline',
        text: <FormattedMessage id="attendance.editModal.removeAttendance" defaultMessage="Usuń obecność" />,
        handleClick: this.handleShowConfirmModal,
      },
    ];
  }

  selectLocation(locationId) {
    this.setState({ selectedLocation: locationId });
  }

  handleShowConfirmModal() {
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(messages.deleteTitle, {}),
      description: this.context.intl.formatMessage(messages.deleteDescription, {}),
      confirmText: this.context.intl.formatMessage(messages.delete, {}),
      confirmFunc: this.props.deleteAttendance.bind(
        this,
        this.props.modalObject.employee.id,
        this.props.modalObject.attendance.id,
      ),
    });
  }

  setSelectedLabels(labelsIds) {
    this.setState({ selectedLabels: labelsIds });
  }

  render() {
    const { userLocations, modalObject, locationSettings, labels, roles, currentUser, userPermissions } = this.props;
    const { employee, attendance } = modalObject;
    const { date } = attendance;
    const { selectedLocation } = this.state;
    const { companyRoles } = roles;
    const { role_id: roleId, role } = currentUser;
    const { permissions } = userPermissions;
    const showLabelsSelect = showLabelsFeature(role, permissions);
    const disabledAddAndEditLabels = !checkIfCanAddEditDeleteLabels(companyRoles, roleId, role, permissions);

    return (
      <MDKadroModal
        show={this.props.showModal}
        title={this.context.intl.formatMessage(messages.title, {})}
        onHide={this.hideAndClear}
        onSubmit={this.onSubmit}
        footerOptions={this.getFooterOptions()}
        modifiers={['narrow']}
        className="k-editAttendanceModal"
      >
        <div className="k-editAttendanceModal__heading heading--extraTopMargin">
          <FormattedMessage
            id="attendance.editModal.headerMessage"
            defaultMessage="Edytuj obecność dla {name} w dniu {date}"
            values={{
              date: this.props.modalObject.attendance.date,
              name: `${this.props.modalObject.employee.first_name} ${this.props.modalObject.employee.last_name}`,
            }}
          />
        </div>
        {/* 
        <MDSelect
          name={<FormattedMessage id="attendance.editModal.locations" defaultMessage="Lokalizacje" />}
          defaultValue={selectedLocation}
          options={getLocationOptions(employee.locations, userLocations, locationSettings, date)}
          onChange={this.selectLocation}
        /> 
        */}
        <MDTimeInput
          id="time"
          label={this.context.intl.formatMessage(messages.workHours)}
          value={this.state.hours}
          onChange={this.changeHours}
          modifiers={INPUT_MODIFIERS}
          short={false}
          errorMessage={this.state.errors.hours?.length > 0 && this.state.errors.hours}
        />

        {showLabelsSelect && (
          <MultiSelectOptionManager
            options={labels}
            defaultOptions={modalObject?.attendance?.labels?.map(({ id }) => id)}
            selectItemsId={this.setSelectedLabels}
            asInput
            hideAddAndEdit={disabledAddAndEditLabels}
            isModal
          />
        )}
        <EditBreaks
          attendance={this.props.modalObject.attendance}
          breaks={this.props.modalObject.attendance.breaks}
          ref={ref => {
            this.breaks = ref;
          }}
        />
      </MDKadroModal>
    );
  }
}

EditAttendanceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EditAttendanceModal.defaultProps = {
  modalObject: {
    employee: {
      first_name: '',
      last_name: '',
    },
    attendance: {
      date: '',
    },
  },
};

EditAttendanceModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  modalObject: PropTypes.shape({
    attendance: PropTypes.shape({
      id: PropTypes.string,
      hours: PropTypes.string,
      date: PropTypes.string,
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
      breaks: PropTypes.arrayOf(PropTypes.shape({})),
      location: PropTypes.shape({ id: PropTypes.string }),
    }),
    employee: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      locations: PropTypes.arrayOf(PropTypes.shape({})),
      attendances: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  date: PropTypes.string,
  deleteAttendance: PropTypes.func,
  changeAttendance: PropTypes.func,
  showConfirmModal: PropTypes.func,
  addBreak: PropTypes.func,
  changeBreak: PropTypes.func,
  deleteBreak: PropTypes.func,
  getHistoryForAttendance: PropTypes.func,
  demoAccount: PropTypes.bool,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  locationSettings: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      disable_location_attendances_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      disable_location_schedule_shifts_edit_until: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    }),
  }),
  assignLabelsToAttendance: PropTypes.func,
  unassignLabelsFromAttendance: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company_id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default EditAttendanceModal;
