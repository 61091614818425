import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { useInputChange } from '@/hooks';
import { intercomShowNewMessage } from '@/utils/intercomHelpers';

import PackagesOptions from './PackagesOptions/PackagesOptions';
import { getKadroPackages, SCHEDULE_AND_SETTLE_PACKAGE_ID } from './PackagesOptions/PackagesOptions.helpers';
import PaymentToggle from './PaymentToggle/PaymentToggle';
import { getIntercomMessage, initialState } from './SelectPackageContent.helpers';
import { messages } from './SelectPackageContent.messages';
import SelectPackageContentFooter from './SelectPackageContentFooter/SelectPackageContentFooter';
import SelectPackageEmployeesAndPrice from './SelectPackageEmployeesAndPrice/SelectPackageEmployeesAndPrice';

import './SelectPackageContent.scss';

const SelectPackageContent = (props, { intl }) => {
  const [state, handlers] = useInputChange(initialState, intl);
  const { selectedPackage, employeesAmount, payment } = state;
  const { changeInput } = handlers;

  const kadroPackages = useMemo(() => getKadroPackages(intl), []);

  const onSubmit = useCallback(() => {
    if (selectedPackage.id === 'freemiumPackage') {
      props.setAccountAsFremium();
    } else {
      intercomShowNewMessage(getIntercomMessage(intl, selectedPackage, employeesAmount, payment));
    }
  }, [selectedPackage, employeesAmount, payment]);

  const defaultPackage = kadroPackages.find(({ id }) => id === SCHEDULE_AND_SETTLE_PACKAGE_ID);

  const { price, id } = defaultPackage;

  return (
    <>
      <div className="k-selectPackageContent__title">{intl.formatMessage(messages.title)}</div>
      <PaymentToggle payment={payment} onChange={changeInput} />
      <PackagesOptions payment={payment} selectedPackageId={selectedPackage.id ?? id} onChange={changeInput} />
      <SelectPackageEmployeesAndPrice
        employeesAmount={employeesAmount}
        cost={selectedPackage.price?.[payment] ?? price[payment]}
        onChange={changeInput}
      />
      <SelectPackageContentFooter onSubmit={onSubmit} selectedPackageId={selectedPackage.id} />
    </>
  );
};

SelectPackageContent.contextTypes = {
  intl: PropTypes.shape({}),
};

SelectPackageContent.propTypes = {
  setAccountAsFremium: PropTypes.func,
};

export default SelectPackageContent;
