import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Szczegóły konta',
    id: 'multiAccounts.profileModal.accountDetails',
  },
  locations: {
    defaultMessage: 'Lokalizacje:',
    id: 'multiAccounts.profileModal.locations',
  },
  jobTitles: {
    defaultMessage: 'Stanowiska:',
    id: 'multiAccounts.profileModal.jobTitles',
  },
  role: {
    defaultMessage: 'Rola:',
    id: 'multiAccounts.profileModal.role',
  },
  owner: {
    defaultMessage: 'Właściciel konta',
    id: 'multiAccounts.owner',
  },
  noJobTitles: {
    defaultMessage: 'Brak przypisanych stanowisk',
    id: 'multiAccounts.noJobTitles',
  },
  noLocations: {
    defaultMessage: 'Brak przypisanych lokalizacji',
    id: 'multiAccounts.noLocations',
  },
});
