import { IntlShape } from 'react-intl';

import {
  ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_METADATA,
  AbsencesAndAttendancesOrShiftsChartDataKey,
} from '@/constants/reports';
import { AbsencesAndAttendancesOrShiftsChartDay } from '@/types/reports.types';

import { messages as tooltipContentMessages } from './TooltipContent/tooltipContent.messages';

const ECP_PAYOUT_CATEGORIES = [
  AbsencesAndAttendancesOrShiftsChartDataKey.EARLY_OR_PUNCTUAL_START_ATTENDANCES,
  AbsencesAndAttendancesOrShiftsChartDataKey.LATE_ATTENDANCES,
  AbsencesAndAttendancesOrShiftsChartDataKey.ATTENDANCES_WITHOUT_SHIFT,
  AbsencesAndAttendancesOrShiftsChartDataKey.ABSENCES,
];

const SHIFTS_PAYOUT_CATEGORIES = [
  AbsencesAndAttendancesOrShiftsChartDataKey.ABSENCES,
  AbsencesAndAttendancesOrShiftsChartDataKey.SHIFTS,
];

export const formatDataToChart = (
  data: Record<string, AbsencesAndAttendancesOrShiftsChartDay>,
  type: string,
  isEcpPayoutSettingsType: boolean,
) => {
  const categories = isEcpPayoutSettingsType ? ECP_PAYOUT_CATEGORIES : SHIFTS_PAYOUT_CATEGORIES;

  return Object.entries(data).reduce((acc, [date, dayData]) => {
    const values = categories.reduce((valueAcc, category) => {
      valueAcc[category] = dayData[type]?.[category] ?? 0;
      return valueAcc;
    }, {});

    acc.push({ date, ...values });
    return acc;
  }, []);
};

export const filterChartMetadata = (isEcpPayoutSettingsType: boolean) =>
  ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_METADATA.filter(item =>
    isEcpPayoutSettingsType
      ? ECP_PAYOUT_CATEGORIES.includes(item.dataKey)
      : SHIFTS_PAYOUT_CATEGORIES.includes(item.dataKey),
  );

export const getCustomLegend = (isEcpPayoutSettingsType: boolean, intl: IntlShape) =>
  filterChartMetadata(isEcpPayoutSettingsType).map(item => ({
    value: intl.formatMessage(tooltipContentMessages[item.dataKey]),
    color: item.fill,
    type: item.legendType,
  }));
