import { connect } from 'react-redux';

import { ADD_SHIFT_AND_ABSENCE_MODAL } from '@/constants/modalTypes';

import Sidebar from './Sidebar';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[ADD_SHIFT_AND_ABSENCE_MODAL],
  mainDateStore: state.reducer.mainDateStore,
  absenceLimitsForEmployee: state.reducer.absences.absenceLimitsForEmployee,
});

export default connect(mapStateToProps)(Sidebar);
