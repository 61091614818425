import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ImportBox = props => {
  if (!props.messages)
    return (
      <div className="k-fileDrop">
        <div className="k-fileDrop__text k-fileDrop__text--centered">
          <FormattedMessage id="settings.import.importing" defaultMessage="Trwa importowanie" />
        </div>
      </div>
    );
  return (
    <div className="k-fileDrop k-fileDrop--padding">
      <div className="k-fileDrop__text">
        <FormattedMessage id="settings.import.importSuccessful" defaultMessage="Import zakończony" />
      </div>
      <div>
        {props.messages.map((message, i) => {
          switch (message.type) {
            case 'info':
              return (
                <div key={i} className="importBox__info">
                  {message.message}
                </div>
              );
            case 'error':
              return (
                <div key={i} className="importBox__error">
                  {message.line ? (
                    <FormattedMessage
                      id="settings.import.row"
                      defaultMessage="wiersz {nr} - "
                      values={{ nr: message.line }}
                    />
                  ) : null}
                  {message.message}
                </div>
              );
            default:
              break;
          }
        })}
      </div>
    </div>
  );
};

ImportBox.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})),
};
export default ImportBox;
