import { Component } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import SearchBox from '@/components/common/SearchBox.jsx';
import { COMPANY_MANAGE_JOBTITLES_HIDE } from '@/constants/Restrictions.js';

import JobTitlesTable from './JobTitlesTable/JobTitlesTable.redux.js';

class ManageJobTitlesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
    };
    this.getSearchWord = this.getSearchWord.bind(this);
    this.clearSearchWord = this.clearSearchWord.bind(this);
  }
  getSearchWord(e) {
    this.setState({ searchWord: e.target.value });
  }

  clearSearchWord() {
    this.setState({ searchWord: '' });
  }

  render() {
    return (
      <FeatureWrapper restriction={COMPANY_MANAGE_JOBTITLES_HIDE}>
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchWord} testId="jobTitlesView" />
        <div className="k-wrapper animated fadeInRight">
          <JobTitlesTable searchWord={this.state.searchWord} clearSearchWord={this.clearSearchWord} />
        </div>
      </FeatureWrapper>
    );
  }
}

export default ManageJobTitlesView;
