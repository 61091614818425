import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import MDInputTooltip from '@/components/common/inputs/MDInputTooltip/MDInputTooltip';
import { DIRECTIONS } from '@/constants/ui';
import { getFreeDays } from '@/utils/freeDaysMarking/freeDaysMarking';

import './Legend.scss';

export const LegendContent = () => {
  const intl = useIntl();
  const freeDays = useMemo(() => Object.values(getFreeDays(intl)), [intl]).sort(
    (a, b) => a.legendOrder - b.legendOrder,
  );

  return (
    <div className="k-addFreeDayLegend__content">
      {freeDays.map(({ id, name, shortcut }) => (
        <div key={id}>
          <span>{shortcut}</span>
          <span> - </span>
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
};

const style: React.CSSProperties = { maxWidth: '350px', transform: 'translateX(-30%)' };

export const Legend = () => (
  <div>
    <MDInputTooltip
      content={<LegendContent />}
      icon="info"
      offset={-15}
      position={DIRECTIONS.BOTTOM}
      popoverStyle={style}
    />
  </div>
);
