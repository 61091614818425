import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';

import './AppInMaintenance.scss';

const AppInMaintenance = props => (
  <Modal show={props.showModal} onHide={props.hideModal} className="k-appInMaintenance">
    <Modal.Body>
      <div className="k-appInMaintenance__closeIcon" role="presentation" onClick={props.hideModal}>
        <Icon name="close" />
      </div>
      <div className="k-appInMaintenance__image">
        <img src="/img/maintenance.png" alt="maintenance" />
      </div>
      <div>
        <h2 className="k-appInMaintenance__title">
          <FormattedMessage id="appInMaintenance.title" defaultMessage="Przepraszamy, trwa konserwacja systemu." />
        </h2>
        <p className="k-appInMaintenance__desc">
          <FormattedMessage
            id="appInMaintenance.readyInMinutes"
            defaultMessage="Kadromierz będzie gotowy do pracy w ciągu kilku minut."
          />

          <br />
          <FormattedMessage id="appInMaintenance.bePatient" defaultMessage="Dziękujemy za cierpliwość." />
        </p>
      </div>
    </Modal.Body>
  </Modal>
);

AppInMaintenance.defaultProps = {
  hideModal: () => {},
};

AppInMaintenance.propTypes = {
  hideModal: PropTypes.func,
  showModal: PropTypes.bool,
};

export default AppInMaintenance;
