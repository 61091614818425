import { conn } from '@/actions/index';
import * as AT from '@/constants/ActionTypes';

const getCompanyLimitsUsageSuccessful = data => ({
  type: AT.GET_COMPANY_LIMITS_USAGE_SUCCESSFUL,
  payload: data,
});

export const getCompanyLimitsUsage = () => async (dispatch, getState) => {
  try {
    const { currentCompany } = getState().reducer;
    if (currentCompany.status.status !== 'freemium') return;
    const res = await conn.getCompanyLimitsUsage(currentCompany.id);
    dispatch(getCompanyLimitsUsageSuccessful(res.data));
  } catch (err) {
    console.log(err);
  }
};
