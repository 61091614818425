import classnames from 'classnames';

import MDSelect from '@/components/common/inputs/MDSelect/Select';
import MDTooltip from '@/components/common/inputs/MDTooltip/MDTooltip';
import { NO_PADDING, ROUNDING_MODIFIER } from '@/constants/modifiers';

import './chartHeader.scss';

type Option = {
  key: string;
  value: string;
};

type ChartHeaderProps = {
  title: string;
  options: Option[];
  chartMode: string;
  onChange: (value: string) => void;
  tooltipMessage?: string;
  className?: string;
};

export const ChartHeader = ({ title, options, chartMode, onChange, tooltipMessage, className }: ChartHeaderProps) => {
  const headerClassNames = classnames('k-chartHeader__header', className);

  return (
    <div className={headerClassNames}>
      <div className="k-chartHeader__title">
        {title} <MDTooltip text={tooltipMessage} />
      </div>
      <MDSelect
        options={options}
        defaultValue={chartMode}
        modifiers={[ROUNDING_MODIFIER, NO_PADDING]}
        noSelectedIcon
        onChange={onChange}
        closeOnClick
      />
    </div>
  );
};

export default ChartHeader;
