import { connect } from 'react-redux';

import { clearScheduleImports,runExport } from '@/actions/exports.js';
import { clearImportModal, toggleImportModalOverlay,uploadFileToImport } from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState';
import { SHIFTS_AND_ATTENDANCES_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportShiftsAndAttendancesModal from './ImportShiftsAndAttendancesModal';

const mapStateToProps = ({ reducer }) => ({
  showModal: reducer.uiState.showModal === SHIFTS_AND_ATTENDANCES_IMPORT_MODAL,
  importModalObject: reducer.scheduleUIState.importModal,
  pendingExports: reducer.exports.pendingExports,
});

const mapDispatchToProps = {
  clearImportModal,
  hideModal,
  runExport,
  uploadFileToImport,
  toggleImportModalOverlay,
  clearScheduleImports,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportShiftsAndAttendancesModal);
