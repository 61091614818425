import { connect } from 'react-redux';

import { changeTradeShift, createTradeShift } from '@/actions/tradeShifts.js';
import { closeRequestTradeShiftModal } from '@/actions/uiState.js';
import RequestTradeShiftModal from '@/components/scheduler/modals/RequestTradeShiftModal/RequestTradeShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showRequestTradeShiftModal,
  modalObject: state.reducer.uiState.requestTradeShiftModalObject,
  userEmployees: state.reducer.userEmployees,
  tradeShifts: state.reducer.tradeShifts,
  demo: state.reducer.demo,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  onHide: closeRequestTradeShiftModal,
  createTradeShift,
  changeTradeShift,
};
const RequestTradeShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(RequestTradeShiftModal);

export default RequestTradeShiftModalContainer;
