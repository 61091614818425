import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tabNameBasic: {
    id: 'employee.tab.basic',
    defaultMessage: 'Podstawowe',
  },
  tabNameLocations: {
    id: 'employee.tab.locations',
    defaultMessage: 'Lokalizacje',
  },
  tabNameContracts: {
    id: 'employee.tab.contracts',
    defaultMessage: 'Stanowiska i stawki',
  },
  tabNameConditions: {
    id: 'employee.tab.conditions',
    defaultMessage: 'Warunki zatrudnienia',
  },
  tabNameAdditional: {
    id: 'employee.tab.additional',
    defaultMessage: 'Dodatkowe',
  },
  tabNameLimits: {
    id: 'employee.tab.limits',
    defaultMessage: 'Limity urlopów',
  },
  customFields: {
    id: 'employee.tab.customFields',
    defaultMessage: 'Pozostałe informacje',
  },
});
