/* eslint-disable camelcase */
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store';

export const selectCurrentUserAvatar = (state: RootState) => state.reducer.currentUser.user.avatar;

export const selectCurrentUser = (state: RootState) => state.reducer.currentUser.user;

export const selectCurrentUserId = (state: RootState) => state.reducer.currentUser.user.id;

export const selectCurrentUserFirstName = (state: RootState) => state.reducer.currentUser.user.first_name;

export const selectCurrentUserLastName = (state: RootState) => state.reducer.currentUser.user.last_name;

export const selectCurrentUserNames = createSelector(
  [selectCurrentUserFirstName, selectCurrentUserLastName],
  (first_name, last_name) => ({ first_name, last_name }),
);

export const selectCurrentUserRoleId = (state: RootState) => state.reducer.currentUser.user.role_id;

export const selectIsCurrentUserOwner = (state: RootState) => state.reducer.currentUser.user.role_id === 'owner';

export const selectCurrentUserLocations = (state: RootState) => state.reducer.currentUser.user.locations;
