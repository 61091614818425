import PropTypes from 'prop-types';

import './ReportsView.scss';

const ChartWrapper = ({ children, title }) => (
  <div className="k-reports__chart-wrapper">
    <div className="k-reports__chart-title">{title}</div>
    <div className="k-reports__chart">{children}</div>
  </div>
);

ChartWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ChartWrapper;
