/* eslint max-len:0 */
import { FormattedMessage } from 'react-intl';

import HelpModal from '@/containers/HelpModalContainer.js';

const EmployeeDashboardHelpModal = () => (
  <HelpModal title={<FormattedMessage id="common.dashboard" defaultMessage="Pulpit" />}>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
          <br />
          <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.zoomDescription"
          defaultMessage='  Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage
            id="help.employeeDashboard.graphTitle"
            defaultMessage="Zaplanowane godziny pracy / Przepracowane godziny"
          />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.employeeDashboard.graphTitleDesc"
          defaultMessage="Wykres przedstawia ilość godzin zaplanowanych w grafiku dla danego dnia, oraz ilość czasu faktycznie spędzonego w pracy."
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="help.employeeDashboard.nextShift" defaultMessage="Następna zmiana" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.employeeDashboard.nextShiftDesc"
          defaultMessage="Przypomnienie o najbliższej zmianie."
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="help.dashboard.eventsList" defaultMessage="Wydarzenia i notatki" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.employeeDashboard.eventsListDesc"
          defaultMessage="Lista notatek i wydarzeń dodanych przez managerów."
        />
      </td>
    </tr>
  </HelpModal>
);

EmployeeDashboardHelpModal.propTypes = {};

export default EmployeeDashboardHelpModal;
