import { connect } from 'react-redux';

import ScheduleView from '@/components/ScheduleView.jsx';

const mapStateToProps = state => ({
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  amountOfDays: state.reducer.mainDateStore.dateArray.length,
});

const mapDispatchToProps = {};

const SchedulerViewContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleView);

export default SchedulerViewContainer;
