import { connect } from 'react-redux';

import { changeStatusTradeShift } from '@/actions/tradeShifts.js';
import { closeCoworkerTradeShiftModal } from '@/actions/uiState.js';
import CoworkerTradeShiftModal from '@/components/scheduler/modals/CoworkerTradeShiftModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showCoworkerTradeShiftModal,
  modalObject: state.reducer.uiState.coworkerTradeShiftModalObject,
  userEmployees: state.reducer.userEmployees,
  currentUser: state.reducer.currentUser,
});

const mapDispatchToProps = {
  onHide: closeCoworkerTradeShiftModal,
  changeStatusTradeShift,
};
const CoworkerTradeShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(CoworkerTradeShiftModal);

export default CoworkerTradeShiftModalContainer;
