import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ecp: {
    id: 'exports.ecp',
    defaultMessage: 'Czas pracy',
  },
  shifts: {
    id: 'exports.shifts',
    defaultMessage: 'Grafik pracy',
  },
  math: {
    id: 'exports.math',
    defaultMessage: 'do najbliższej',
  },
  down: {
    id: 'exports.down',
    defaultMessage: 'w dół',
  },
  up: {
    id: 'exports.up',
    defaultMessage: 'w górę',
  },
  oneM: {
    id: 'exports.oneM',
    defaultMessage: 'Do 1 minuty',
  },
  fiveM: {
    id: 'exports.fiveM',
    defaultMessage: 'Do 5 minut',
  },
  fifteenM: {
    id: 'exports.fifteenM',
    defaultMessage: 'Do 15 minut',
  },
  thirtyM: {
    id: 'exports.thirtyM',
    defaultMessage: 'Do 30 minut',
  },
  oneH: {
    id: 'exports.oneH',
    defaultMessage: 'Do pełnej godziny',
  },
  shiftsUnlessFixed: {
    id: 'exports.shiftsUnlessFixed',
    defaultMessage: 'Czas pracy bez wczesnego przyjścia',
  },
  shiftsNoExtra: {
    id: 'exports.shiftsNoExtra',
    defaultMessage: 'Z Harmonogramu/Z RCP',
  },
  hours: {
    id: 'exports.hours',
    defaultMessage: 'Godziny',
  },
  minutes: {
    id: 'exports.minutes',
    defaultMessage: 'Minuty',
  },
  withAtt: {
    id: 'exports.withAtt',
    defaultMessage: 'Z obecnościami',
  },
  all: {
    id: 'exports.all',
    defaultMessage: 'Wszyscy',
  },
  no: {
    id: 'exports.no',
    defaultMessage: 'Nie',
  },
  yes: {
    id: 'exports.yes',
    defaultMessage: 'Tak',
  },
  constant: {
    id: 'exports.constant',
    defaultMessage: 'Stały',
  },
  decimal: {
    id: 'exports.decimal',
    defaultMessage: 'Dziesiętny',
  },
  hoursFormat: {
    id: 'exports.hoursFormat',
    defaultMessage: 'Godzinowy',
  },
  teachingHours: {
    id: 'exports.teachingHours',
    defaultMessage: 'Godzina dydaktyczna',
  },
  tolerance: {
    id: 'newPayrollSettings.tolerance',
    defaultMessage: 'Tolerancja',
  },
});

export const roundingTypeSettings = [
  { id: 0, type: 'math', message: messages.math },
  { id: 1, type: 'down', message: messages.down },
  { id: 2, type: 'up', message: messages.up },
];

export const roundingSettings = [
  { id: 0, type: '1m', roundNum: 1, message: messages.oneM },
  { id: 1, type: '5m', roundNum: 5, message: messages.fiveM },
  { id: 2, type: '15m', roundNum: 15, message: messages.fifteenM },
  { id: 3, type: '30m', roundNum: 30, message: messages.thirtyM },
  { id: 4, type: 'hours', roundNum: 60, message: messages.oneH },
];

export const payoutSettings = [
  { id: 0, type: 'ecp', message: messages.ecp },
  { id: 1, type: 'shifts', message: messages.shifts },
  {
    id: 2,
    type: 'shifts_unless_fixed',

    message: messages.shiftsUnlessFixed,
  },
  {
    id: 3,
    type: 'shifts_no_extra',

    message: messages.shiftsNoExtra,
  },
];

export const newPayoutSettings = [
  { id: 0, type: 'ecp', message: messages.ecp },
  { id: 1, type: 'shifts', message: messages.shifts },
];

export const timeSettings = [
  { id: 0, type: 'hours', message: messages.hours },
  { id: 1, type: 'minutes', message: messages.minutes },
];

export const showSettings = [
  { id: 0, type: 'withAtt', message: messages.withAtt },
  { id: 1, type: 'all', message: messages.all },
];

export const breakSettingTypes = {
  DO_NOT_INCLUDE_BREAK_TIME: 'no',
  INCLUDE_BREAK_TIME: 'tolerance',
  CONSTANT_BREAK_TIME: 'constant',
};

export const breaksSettings = [
  { id: 0, type: breakSettingTypes.DO_NOT_INCLUDE_BREAK_TIME, message: messages.no },
  { id: 1, type: breakSettingTypes.INCLUDE_BREAK_TIME, message: messages.yes },
  { id: 2, type: breakSettingTypes.CONSTANT_BREAK_TIME, message: messages.constant },
];

export const newPayrollBreaksSettings = [
  { id: 0, type: breakSettingTypes.DO_NOT_INCLUDE_BREAK_TIME, message: messages.no },
  { id: 1, type: breakSettingTypes.INCLUDE_BREAK_TIME, message: messages.tolerance },
  { id: 2, type: breakSettingTypes.CONSTANT_BREAK_TIME, message: messages.constant },
];

export const timeFormatSettings = [
  { id: 0, type: 'decimal', message: messages.decimal },
  { id: 1, type: 'hours', message: messages.hoursFormat },
  { id: 2, type: 'teachingHours', message: messages.teachingHours },
];

export enum PAYROLL_MODES {
  readonly = 'readonly',
  editable = 'editable',
}

// we do not allow users to use payroll approvals before this date
export const PAYROLL_APPROVAL_START_DATE = '2023-01-01';

export enum PayoutSettingType {
  SHIFTS = 'shifts',
  ECP = 'ecp',
}
