import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { PROFILE_DETAILS_MODAL } from '@/constants/modalTypes';
import { useAppSelector } from '@/redux-store';
import { selectIsModalOpen, selectModalObject } from '@/selectors/uiState.selectors';
import { getLoggedAccounts } from '@/utils/accounts/accounts.utils.ts';

export const useProfileDetailsModal = () => {
  const dispatch = useDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen(PROFILE_DETAILS_MODAL));
  const modalObject = useAppSelector(selectModalObject<{ userId: string }>(PROFILE_DETAILS_MODAL));
  const employeeId = modalObject?.userId;
  const loggedAccounts = getLoggedAccounts();
  const relevantAccount = loggedAccounts.find((account) => account.userId === employeeId);
  const account = {
    ...relevantAccount,
    jobTitleNames: relevantAccount?.jobTitleNames?.sort() || [],
    locationNames: relevantAccount?.locationNames?.sort() || [],
  };

  const intl = useIntl();

  const onCloseModal = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return { account, intl, isModalOpen, onCloseModal };
};
