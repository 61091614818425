import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import EmployeeAvailabilitiesBlock from '@/components/common/EmployeeAvailabilitiesBlock.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import SwitchToFullApp from '@/components/onboarding/SwitchToFullApp.jsx';
import { OpenShiftAssignedForEnum } from '@/constants/openShifts';
import { OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions.js';
import { useAppSelector } from '@/redux-store';
import { selectCompanyEmploymentConditions } from '@/redux-store/employmentConditions/employmentConditions.selectors';
import { selectLocationGroupsForLocationId } from '@/redux-store/locationGroups/locationGroups.selectors';
import { selectIsPermissionGranted } from '@/redux-store/userPermissions';
import { getAvailabilityOrderIndex } from '@/utils/availabilitiesHelpers';
import { inputValidation } from '@/utils/inputValidation';
import { availableEmployeesForShift } from '@/utils/userEmployeesHelpers';

import { modalMessages } from './AddOpenShiftModal.messages.js';
import AddOpenShiftAdditionalStep from './AddOpenShiftModalSteps/AddOpenShiftAdditionalStep';
import AddOpenShiftBaseStep from './AddOpenShiftModalSteps/AddOpenShiftBaseStep';

const INPUTS_STEP_ONE_TO_VALIDATE = ['shifts_required', 'working_hours'];

const AddOpenShiftModal = ({
  modalObject,
  selectedJobTitles,
  onHide,
  userJobTitles,
  contracts,
  addShift,
  deleteOpenShift,
  changeOpenShift,
  createOpenShift,
  userCustomTypes,
  demo,
  showModal,
  userShiftblocks,
  userEmployees,
  showDeleteOpenShiftConfirmModal,
  assignUsersOpenShift,
}) => {
  const intl = useIntl();
  const isOpenShiftsForLocationGroupsEnabled = useAppSelector(
    selectIsPermissionGranted(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE),
  );
  const locationGroups = useAppSelector(selectLocationGroupsForLocationId(modalObject?.location?.id));
  const locationGroupsIds = useMemo(() => locationGroups.map(({ id }) => id), [locationGroups]);
  const locationIdsFromLocationGroups = useMemo(
    () => locationGroups.map(({ locationIds }) => locationIds).flat(),
    [locationGroups],
  );
  const companyEmploymentsConditions = useAppSelector(selectCompanyEmploymentConditions);
  const companyEmploymentConditionsIds = useMemo(
    () => companyEmploymentsConditions.map(({ id }) => id),
    [companyEmploymentsConditions],
  );
  const openShiftEmploymentConditionsNames = useMemo(
    () =>
      modalObject.openShiftData?.employmentConditions?.map(
        employmentConditionId => companyEmploymentsConditions.find(({ id }) => id === employmentConditionId)?.name,
      ),
    [modalObject.openShiftData, companyEmploymentsConditions],
  );

  const getInitialState = useCallback(
    () => ({
      comment: '',
      working_hours: '',
      jobTitleId: selectedJobTitles[0] ? selectedJobTitles[0].id : -1,
      shifts_required: 1,
      errors: {
        shifts_required: '',
        working_hours: '',
        selectedLocationGroupsIds: '',
        employmentConditionsIds: '',
      },
      selectedEmployees: [],
      currentStep: 1,
      selectedLocationGroupsIds: locationGroupsIds,
      employmentConditionsIds: companyEmploymentConditionsIds,
      openShiftAssignedFor: OpenShiftAssignedForEnum.singleLocation,
    }),
    [locationGroupsIds, selectedJobTitles, companyEmploymentConditionsIds],
  );
  const [state, setState] = useState(getInitialState());
  const locationGroupsMappedForMultiselect = useMemo(
    () =>
      locationGroups.map(({ id, name }) => ({
        value: id,
        label: name,
        active: state.selectedLocationGroupsIds.includes(id),
      })),
    [locationGroups, state.selectedLocationGroupsIds],
  );
  const companyEmploymentConditionsMappedForMultiselect = useMemo(
    () =>
      companyEmploymentsConditions.map(({ id, name }) => ({
        value: id,
        label: name,
        active: state.employmentConditionsIds.includes(id),
      })),
    [companyEmploymentsConditions, state.employmentConditionsIds],
  );

  useEffect(() => {
    const { openShiftData } = modalObject;
    if (openShiftData) {
      setState(prevState => ({
        ...prevState,
        comment: openShiftData.comment || prevState.comment,
        working_hours: openShiftData.working_hours || prevState.working_hours,
        jobTitleId: openShiftData.job_title ? openShiftData.job_title.id : prevState.jobTitleId,
        shifts_required: openShiftData.shifts_remaining || prevState.shifts_required,
      }));
    }
  }, [modalObject, modalObject.openShiftData?.id]);
  const locationGroupsNames = useMemo(
    () =>
      modalObject.openShiftData?.location_groups?.reduce((acc, id) => {
        const name = locationGroups.find(locationGroup => locationGroup.id === id)?.name;
        if (name) acc.push(name);
        return acc;
      }, []),
    [locationGroups, modalObject.openShiftData?.location_groups],
  );

  const onEdit = useCallback(
    async (editedOpenShift, openShift) => {
      if (state.selectedEmployees.length > 0) {
        const employees = state.selectedEmployees.map(e => e.value);
        const employeeIds = employees.map(e => e.id);
        const response = await assignUsersOpenShift(openShift, employeeIds);

        if (openShift.shifts_remaining - response.length < 1) {
          deleteOpenShift(openShift.id);
        } else {
          changeOpenShift(openShift.id, {
            ...editedOpenShift,
            shifts_remaining: openShift.shifts_remaining - response.length,
            shifts_required: openShift.shifts_required - response.length,
          });
        }
      } else {
        changeOpenShift(modalObject.openShiftData.id, editedOpenShift);
      }
    },
    [state.selectedEmployees, assignUsersOpenShift, deleteOpenShift, changeOpenShift, modalObject.openShiftData.id],
  );

  const handleNextStep = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      currentStep: 2,
    }));
  }, [setState]);

  const handlePreviousStep = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      currentStep: 1,
    }));
  }, [setState]);

  const clear = useCallback(() => {
    setState(getInitialState());
  }, [setState, getInitialState]);

  const onHideModal = useCallback(() => {
    clear();
    onHide();
  }, [clear, onHide]);

  const changeOpenShiftAssignedFor = useCallback(
    type => {
      setState(prevState => ({
        ...prevState,
        openShiftAssignedFor: type,
      }));
    },
    [setState],
  );

  const validateInput = useCallback(
    async event => {
      try {
        const { target } = event;
        const { value, name } = target;
        const error = inputValidation(name, value);
        setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [name]: error ? intl.formatMessage(error, {}) : error,
          },
        }));
        return error;
      } catch (error) {
        console.error('Error validating input:', error);
      }
    },
    [intl],
  );

  const onCancel = useCallback(() => {
    if (state.currentStep === 2) {
      handlePreviousStep();
    } else {
      onHideModal();
    }
  }, [state.currentStep, handlePreviousStep, onHideModal]);

  const isEmployeeSelected = useCallback(
    employee => {
      const id = employee.value?.id || employee.id;
      return state.selectedEmployees.some(selectedEmployee => selectedEmployee.value.id === id);
    },
    [state.selectedEmployees],
  );

  const getAvailableEmployees = useCallback(() => {
    const relevantEmployees = availableEmployeesForShift(
      userEmployees,
      modalObject.date,
      modalObject.openShiftData,
      modalObject.location.id,
      state.jobTitleId,
      contracts,
      false,
      locationIdsFromLocationGroups,
    );
    return relevantEmployees.reduce((prev, employee) => {
      const relevantAvailability = employee.availability_blocks.find(block => block.date === modalObject.date);

      const avaOrder = getAvailabilityOrderIndex(relevantAvailability, userCustomTypes);
      const sortValue = avaOrder + employee.first_name + employee.last_name;

      return prev.concat({
        value: employee,
        sortValue,
        label: (
          <EmployeeAvailabilitiesBlock employee={employee} date={modalObject.date} userCustomTypes={userCustomTypes} />
        ),
        active: isEmployeeSelected(employee),
      });
    }, []);
  }, [
    userEmployees,
    modalObject,
    state.jobTitleId,
    contracts,
    userCustomTypes,
    isEmployeeSelected,
    locationIdsFromLocationGroups,
  ]);

  const inputToValidateInStepTwo = useMemo(
    () =>
      state.openShiftAssignedFor === OpenShiftAssignedForEnum.locationGroups
        ? ['selectedLocationGroupsIds', 'employmentConditionsIds']
        : [],
    [state.openShiftAssignedFor],
  );
  const validateStep = useCallback(
    async inputs => {
      const promises = inputs.map(i => validateInput({ target: { name: i, value: state[i] } }));
      const result = await Promise.all(promises);
      return Object.values(result).every(error => !error);
    },
    [validateInput, state],
  );

  const validateAll = useCallback(async () => {
    const isStepOneValid = await validateStep(INPUTS_STEP_ONE_TO_VALIDATE);
    const isStepTwoValid = await validateStep(inputToValidateInStepTwo);
    return isStepOneValid && isStepTwoValid;
  }, [validateStep, inputToValidateInStepTwo]);

  const changeWorkingHours = hours => {
    if (state.errors.working_hours !== '') {
      validateInput({ target: { name: 'working_hours', value: hours } });
    }
    setState(prevState => ({
      ...prevState,
      working_hours: hours,
    }));
  };

  const deselectAllEmployees = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      selectedEmployees: [],
    }));
  }, [setState]);

  const updateEmployeeList = useCallback(
    newRequiredShifts => {
      const newRequiredShiftsAmount = parseInt(newRequiredShifts);
      const prevShiftsRequiredAmount = parseInt(state.shifts_required);
      const selectedEmployeesAmount = state.selectedEmployees.length;
      if (newRequiredShiftsAmount >= prevShiftsRequiredAmount || newRequiredShiftsAmount >= selectedEmployeesAmount) {
        return;
      }
      deselectAllEmployees();
    },
    [state.shifts_required, state.selectedEmployees.length, deselectAllEmployees],
  );

  const handleInputChange = useCallback(
    event => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      if (state.errors[name] !== '') {
        validateInput(event);
      }
      if (modalObject.openShiftData.id && name === 'shifts_required') {
        updateEmployeeList(target.value);
      }
      setState(prevState => ({
        ...prevState,
        [name]: value,
      }));
    },
    [state.errors, validateInput, modalObject.openShiftData.id, updateEmployeeList],
  );

  const handleChangeMultiSelect = useCallback(
    ({ value }, targetName) => {
      if (state.errors[targetName]) {
        validateInput({ target: { value, name: targetName } });
      }
      setState(prevState => ({
        ...prevState,
        [targetName]: prevState[targetName].includes(value)
          ? prevState[targetName].filter(stateItem => stateItem !== value)
          : [...prevState[targetName], value],
      }));
    },
    [state.errors, validateInput],
  );

  const selectAllMultiSelect = useCallback(
    (targetName, options) => {
      const allOptions = options.map(option => option.value);
      if (state.errors[targetName]) {
        validateInput({ target: { value: allOptions, name: targetName } });
      }
      setState(prevState => ({
        ...prevState,
        [targetName]: allOptions,
      }));
    },
    [state.errors, validateInput],
  );

  const deselectAllMultiSelect = useCallback(
    targetName => {
      setState(prevState => ({
        ...prevState,
        [targetName]: [],
      }));
    },
    [setState],
  );

  const changeJobTitle = useCallback(
    e => {
      setState(prevState => ({
        ...prevState,
        jobTitleId: e,
      }));
      deselectAllEmployees();
    },
    [deselectAllEmployees],
  );

  const handleEmployeesSelectChange = selectedEmployees => {
    setState(prevState => ({
      ...prevState,
      selectedEmployees: [...selectedEmployees],
    }));
  };

  const deleteShift = useCallback(() => {
    const openShiftId = modalObject.openShiftData.id;
    showDeleteOpenShiftConfirmModal(openShiftId);
    onHideModal();
  }, [modalObject.openShiftData.id, showDeleteOpenShiftConfirmModal, onHideModal]);

  const selectSome = useCallback(() => {
    const options = getAvailableEmployees();
    const maxSelectedEmployeeAmount = parseInt(state.shifts_required);
    const newEmployees = options.slice(0, maxSelectedEmployeeAmount);
    setState(prevState => ({
      ...prevState,
      selectedEmployees: newEmployees,
    }));
  }, [getAvailableEmployees, state.shifts_required]);

  const getSelectedEmployees = useCallback(
    () => getAvailableEmployees().filter(({ active }) => active),
    [getAvailableEmployees],
  );
  const handleChangeList = useCallback(
    selectedEmployee => {
      const maxSelectedEmployeeAmount = parseInt(state.shifts_required);
      if (isEmployeeSelected(selectedEmployee)) {
        const newEmployees = state.selectedEmployees.filter(
          employee => employee.value.id !== selectedEmployee.value.id,
        );
        setState(prevState => ({
          ...prevState,
          selectedEmployees: newEmployees,
        }));
      } else if (state.selectedEmployees.length < maxSelectedEmployeeAmount) {
        setState(prevState => ({
          ...prevState,
          selectedEmployees: [...prevState.selectedEmployees, selectedEmployee],
        }));
      }
    },
    [state.shifts_required, state.selectedEmployees, isEmployeeSelected],
  );

  const getFooterOptions = useCallback(() => {
    if (!modalObject.openShiftData.id) return [];
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(modalMessages.deleteText, {}),
        handleClick: deleteShift,
      },
    ];
  }, [modalObject.openShiftData.id, intl, deleteShift]);

  const onSubmit = useCallback(
    async () =>
      validateAll().then(isValid => {
        if (!isValid) return;
        const openShift = { ...modalObject.openShiftData };
        const editedOpenShift = {
          comment: state.comment,
          working_hours: state.working_hours,
          draft: true,
          date: modalObject.date,
          shifts_required: parseInt(state.shifts_required),
          shifts_remaining: parseInt(state.shifts_required),
          job_title: {
            id: state.jobTitleId,
          },
          location: {
            id: modalObject.openShiftData?.location?.id || modalObject.location.id,
          },
          location_groups_ids:
            isOpenShiftsForLocationGroupsEnabled &&
            state.openShiftAssignedFor === OpenShiftAssignedForEnum.locationGroups
              ? state.selectedLocationGroupsIds
              : undefined,
          employment_conditions_ids:
            isOpenShiftsForLocationGroupsEnabled &&
            state.openShiftAssignedFor === OpenShiftAssignedForEnum.locationGroups &&
            state.employmentConditionsIds.length &&
            state.employmentConditionsIds.length !== companyEmploymentConditionsIds.length
              ? state.employmentConditionsIds
              : undefined,
        };
        if (
          isOpenShiftsForLocationGroupsEnabled &&
          state.currentStep === 1 &&
          !modalObject.openShiftData.id &&
          locationGroups.length
        ) {
          handleNextStep();
        } else {
          if (modalObject.openShiftData.id) {
            onEdit(editedOpenShift, openShift);
          } else {
            createOpenShift(editedOpenShift);
          }

          onHide();
          clear();
        }
      }),
    [
      validateAll,
      modalObject.openShiftData,
      modalObject.openShiftData.location_groups,
      modalObject.date,
      modalObject?.location?.id,
      state.comment,
      state.working_hours,
      state.shifts_required,
      state.jobTitleId,
      state.openShiftAssignedFor,
      state.selectedLocationGroupsIds,
      state.employmentConditionsIds,
      state.currentStep,
      isOpenShiftsForLocationGroupsEnabled,
      companyEmploymentConditionsIds.length,
      locationGroups.length,
      handleNextStep,
      onHide,
      clear,
      onEdit,
      createOpenShift,
    ],
  );

  const confirmText = useMemo(() => {
    if (modalObject.openShiftData.id) {
      return intl.formatMessage(modalMessages.editConfirmText);
    }
    if (isOpenShiftsForLocationGroupsEnabled && state.currentStep === 1 && locationGroups.length) {
      return intl.formatMessage(modalMessages.nextStepText);
    }
    return intl.formatMessage(modalMessages.addConfirmText);
  }, [
    modalObject.openShiftData.id,
    isOpenShiftsForLocationGroupsEnabled,
    state.currentStep,
    intl,
    locationGroups.length,
  ]);

  const cancelText = useMemo(() => {
    if (state.currentStep === 1) {
      return intl.formatMessage(modalMessages.cancelText);
    }
    return intl.formatMessage(modalMessages.previousText);
  }, [state.currentStep, intl]);

  const availableEmployees = useMemo(
    () => modalObject.openShiftData.id && getAvailableEmployees(),
    [modalObject.openShiftData.id, getAvailableEmployees],
  );

  const filteredShiftBlockOptions = useMemo(
    () =>
      userShiftblocks
        .filter(
          shiftBlock =>
            shiftBlock.job_titles === null ||
            shiftBlock.job_titles.filter(jobTitleId => jobTitleId === state.jobTitleId).length,
        )
        .map(s => s.working_hours) || [],
    [userShiftblocks, state.jobTitleId],
  );

  const title = useMemo(
    () => (modalObject.openShiftData.id ? 'editTitle' : 'addTitle'),
    [modalObject.openShiftData.id],
  );

  if (demo.demoAccount && showModal) {
    return <SwitchToFullApp cancel={onHide} onHide={onHide} />;
  }

  return (
    <MDKadroModal
      show={showModal}
      title={intl.formatMessage(modalMessages[title], {})}
      confirmText={confirmText}
      onSubmit={onSubmit}
      onHide={onHideModal}
      modifiers={['narrow']}
      footerOptions={getFooterOptions()}
      onCancelFooter={onCancel}
      cancelText={cancelText}
    >
      {state.currentStep === 1 ? (
        <AddOpenShiftBaseStep
          {...{
            state,
            selectedJobTitles,
            changeJobTitle,
            handleInputChange,
            filteredShiftBlockOptions,
            changeWorkingHours,
            modalObject,
            availableEmployees,
            getSelectedEmployees,
            handleChangeList,
            selectSome,
            deselectAll: deselectAllEmployees,
            locationGroupsNames,
            openShiftEmploymentConditionsNames,
          }}
        />
      ) : (
        <AddOpenShiftAdditionalStep
          {...{
            changeOpenShiftAssignedFor,
            locationGroupsMappedForMultiselect,
            handleChangeMultiSelect,
            selectAllMultiSelect,
            deselectAllMultiSelect,
            companyEmploymentConditionsMappedForMultiselect,
          }}
          openShiftAssignedFor={state.openShiftAssignedFor}
          errors={state.errors}
        />
      )}
    </MDKadroModal>
  );
};

AddOpenShiftModal.propTypes = {
  modalObject: PropTypes.shape({
    openShiftData: PropTypes.shape({
      id: PropTypes.string,
      comment: PropTypes.string,
      working_hours: PropTypes.string,
      job_title: PropTypes.shape({
        id: PropTypes.string,
      }),
      shifts_required: PropTypes.number,
      employmentConditions: PropTypes.arrayOf(PropTypes.string),
    }),
    date: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    location_groups: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userShiftblocks: PropTypes.arrayOf(PropTypes.shape({})),
  createOpenShift: PropTypes.func,
  changeOpenShift: PropTypes.func,
  deleteOpenShift: PropTypes.func,
  addShift: PropTypes.func,
  onHide: PropTypes.func,
  showModal: PropTypes.bool,
  showDeleteOpenShiftConfirmModal: PropTypes.func,
  demo: PropTypes.shape({
    demoAccount: PropTypes.bool,
  }),
  contracts: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        company_id: PropTypes.string,
        employee_id: PropTypes.string,
        start_date: PropTypes.string,
        job_tiles: PropTypes.arrayOf(
          PropTypes.shape({
            job_title_id: PropTypes.string,
          }),
        ),
        end_date: PropTypes.string,
      }),
    ),
  }),
};

export default AddOpenShiftModal;
