import { getRelevantContractForDate } from '@/utils/contracts';
import { minutesToHour } from '@/utils/dateHelper';

export const createNewShift = (
  employeeId,
  locationId,
  date,
  blockStart,
  blockEnd,
  employeeContracts,
  userJobTitles,
) => {
  const relevantContract = getRelevantContractForDate(employeeContracts, date);
  const jobTitleId = relevantContract?.job_titles[0].job_title_id;
  const jobTitle = userJobTitles.find(j => j.id === jobTitleId);

  const newShift = {
    employee: { id: employeeId },
    comment: '',
    working_hours: `${blockStart}-${blockEnd}`,
    date,
    location: { id: locationId },
    job_title: jobTitle,
    draft: true,
  };
  return newShift;
};

export const getBlockEnd = (clientX, offsetLeft, containerWidth) => {
  const minutes = ((clientX - offsetLeft + 20) / containerWidth) * 1440;
  const end = minutesToHour(Math.floor(minutes), 15);
  return end;
};

export const getPropertiesForDrawing = (clientX, addLayerRef) => {
  const newStartX = clientX - addLayerRef.current.offsetLeft;
  const boundingBox = addLayerRef.current.getBoundingClientRect();
  const newOffsetLeft = boundingBox.left;
  const newContainerWidth = addLayerRef.current.offsetWidth;
  const minutes = ((newStartX - newOffsetLeft + 20) / newContainerWidth) * 1440;
  const start = minutesToHour(Math.floor(minutes), 15);
  return { newOffsetLeft, newContainerWidth, start };
};

export const getBlockHours = (blockStart, blockEnd) => {
  if (blockStart < blockEnd) return { startTime: blockStart, endTime: blockEnd };
  return { startTime: blockEnd, endTime: blockStart };
};
