import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { setDefaultEntraIdAccount, unsetDefaultEntraIdAccount } from '@/actions/auth.jsx';
import { useIsOverflow } from '@/hooks';
import { useAppDispatch } from '@/redux-store';
import { getLoggedAccounts, storeLoggedAccounts } from '@/utils/accounts/accounts.utils';

import { getSortedUsersAccountsDetails, setContainersHeight } from './MultiAccountsMenu.utils';

export const useMultiAccountsMenu = () => {
  const dispatch = useAppDispatch();
  const [accounts, setAccounts] = useState(getLoggedAccounts());
  const profilesListRef = useRef<HTMLDivElement>(null);

  const accountsDetails = useMemo(() => getSortedUsersAccountsDetails(accounts), [accounts]);

  const { isOverflowY } = useIsOverflow(profilesListRef);

  useEffect(() => {
    setContainersHeight(accountsDetails.length);
    return () => {
      setContainersHeight(accountsDetails.length, 'auto');
    };
  }, [accountsDetails]);

  const setDefaultAccount = useCallback(
    (userId: string) => {
      const currentAccounts = getLoggedAccounts();
      const currentDefaultAccount = currentAccounts.find((a) => a.isDefault);
      const isSelectedAccountAlreadyDefault = currentDefaultAccount?.userId === userId;
      const newAccounts = isSelectedAccountAlreadyDefault
        ? currentAccounts.map((a) => ({
            ...a,
            isDefault: false,
          }))
        : currentAccounts.map((a) => ({
            ...a,
            isDefault: a.userId === userId,
          }));
      setAccounts(newAccounts);
      storeLoggedAccounts(newAccounts);
      if (isSelectedAccountAlreadyDefault) {
        dispatch(unsetDefaultEntraIdAccount(userId));
      } else {
        dispatch(setDefaultEntraIdAccount(userId));
      }
    },
    [setAccounts],
  );

  return { accountsDetails, isOverflowY, profilesListRef, setDefaultAccount };
};

export type UseMultiAccountsMenuValuesType = ReturnType<typeof useMultiAccountsMenu>;
