import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Icon from '@/components/common/Basic/Icon/Icon';
import browserHistory from '@/constants/browserHistory';

import './scheduleTableWeatherPopoverContent.scss';

const messages = defineMessages({
  noCoordinatesTitle: {
    id: 'weatherForecast.noCoordinates.title',
    defaultMessage: 'Brak koordynatów dla lokalizacji',
  },
  noCoordinatesText: {
    id: 'weatherForecast.noCoordinates.text',
    defaultMessage:
      'Aby mieć dostęp do prognozy pogody dla wybranej lokalizacji, przejdź do ustawień w celu podania koordynatów.',
  },
  noWeatherText: {
    id: 'weatherForecast.noWeather.text',
    defaultMessage: 'Brak prognozy dla danego dnia',
  },
  settingsButton: {
    id: 'common.locationSettings',
    defaultMessage: 'Ustawienia lokalizacji',
  },
});

const ScheduleTableWeatherPopoverContent = (
  { locationCoordinatesExist, weatherForecast, city, startEdit, locationId, canEditLocations },
  { intl },
) => {
  const { minTemp, maxTemp, chanceForPrecipitation, weatherType, description } = weatherForecast || '';
  if (!locationCoordinatesExist)
    return (
      <div className="k-weatherPopover k-weatherPopover__noCoordinates">
        <div className="k-weatherPopover__noCoordinatesTitle">{intl.formatMessage(messages.noCoordinatesTitle)}</div>
        <div className="k-weatherPopover__noCoordinatesDescription">
          {intl.formatMessage(messages.noCoordinatesText)}
        </div>
        {canEditLocations && (
          <Button
            modifiers={['orange', 'teeny']}
            onClick={() => {
              browserHistory.push('/companymanage/locations');
              startEdit(locationId, 'locations');
            }}
          >
            {intl.formatMessage(messages.settingsButton)}
          </Button>
        )}
      </div>
    );
  if (!weatherForecast)
    return (
      <div className="k-weatherPopover k-weatherPopover__noCoordinates">
        <div className="k-weatherPopover__description">{intl.formatMessage(messages.noWeatherText)}</div>
      </div>
    );
  return (
    <div className="k-weatherPopover">
      <Icon type="material-symbols" className="k-weatherPopover__weatherIcon" name={weatherType} />
      <div className="k-weatherPopover__temperatureContainer">
        <span className="k-weatherPopover__tmperature k-weatherPopover__temperature--high">{maxTemp}°</span>
        <span className="k-weatherPopover__tmperature k-weatherPopover__temperature--low">{minTemp}°</span>
      </div>
      <div className="k-weatherPopover__precipitationContainer">
        <Icon type="material-outlined" className="k-weatherPopover__precipitationIcon" name="water_drop" />
        {chanceForPrecipitation}%
      </div>
      <div className="k-weatherPopover__description">{description}</div>
      <div className="k-weatherPopover__location">{city}</div>
    </div>
  );
};

ScheduleTableWeatherPopoverContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableWeatherPopoverContent.propTypes = {
  locationCoordinatesExist: PropTypes.bool,
  weatherForecast: PropTypes.shape({
    locationId: PropTypes.number,
    date: PropTypes.string,
    weatherType: PropTypes.string,
    description: PropTypes.string,
    minTemp: PropTypes.number,
    maxTemp: PropTypes.number,
    chanceForPrecipitation: PropTypes.number,
    rainVolume: PropTypes.number,
    snowVolume: PropTypes.number,
  }),
  city: PropTypes.string,
  startEdit: PropTypes.func,
  locationId: PropTypes.string,
  canEditLocations: PropTypes.bool,
};

export default ScheduleTableWeatherPopoverContent;
