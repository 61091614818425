import { connect } from 'react-redux';

import { setSortingColumn } from '@/actions/dashboard';

import DashboardLocationsHeader from './DashboardLocationsHeader.jsx';

const mapStateToProps = state => ({
  locationsSorting: state.reducer.dashboard.locations,
});

const mapDispatchToProps = {
  setSortingColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLocationsHeader);
