import { defineMessages } from 'react-intl';

import { GET_RCP_FAILURE,GET_RCP_SUCCESS } from '@/constants/ActionTypes';

import { conn } from '.';

const messages = defineMessages({
  getRcpDataFailure: {
    id: 'dashboard.rcp.getRcpDataFailure',
    defaultMessage: 'Nie udało się pobrać danych z RCP',
  },
});

const getRcpDataSuccess = attendances => ({
  type: GET_RCP_SUCCESS,
  payload: attendances,
});

const getRcpDataFailure = errMessage => ({
  type: GET_RCP_FAILURE,
  notification: {
    title: errMessage,
    type: 'error',
  },
});

export const getRcpData = employeeId => (dispatch, getState, intl) => {
  conn
    .getRcpData(employeeId)
    .then(res => {
      dispatch(getRcpDataSuccess(res.data));
    })
    .catch(() => {
      const errMessage = intl.formatMessage(messages.getRcpDataFailure);
      dispatch(getRcpDataFailure(errMessage));
    });
};
