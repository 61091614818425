import { connect } from 'react-redux';

import { toggleModal } from '@/actions/companymanage';

import EmptyStateJobTitleView from './EmptyStateJobTitleView.jsx';

const mapDispatchToProps = {
  toggleModal,
};

const EmptyStateJobTitleViewContainer = connect(null, mapDispatchToProps)(EmptyStateJobTitleView);

export default EmptyStateJobTitleViewContainer;
