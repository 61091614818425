import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { FreemiumWrapper } from '@/components/common/Freemium';
import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ShownCounter from '@/components/common/ShownCounter.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { ADD_NEW_LOCATION } from '@/constants/ActionTypes';
import { FREEMIUM_EVENT_ADD_MORE_LOCATIONS } from '@/constants/intercom.js';
import { COMPANY_MANAGE_LOCATIONS_HIDE_ADD, COMPANY_MANAGE_LOCATIONS_HIDE_DELETE } from '@/constants/Restrictions.js';
import { compareByKey } from '@/utils/baseHelpers.js';

import AddLocationModal from '../AddLocationModal/AddLocationModal.redux';
import LocationsHeader from '../LocationsHeader.jsx';
import LocationsRow from '../LocationsRow.jsx';

const messages = defineMessages({
  active: {
    id: 'companyManage.locationsList.active',
    defaultMessage: 'aktywny',
  },
  emptyStateTitle: {
    id: 'companyManage.locationsList.emptyStateTitle',
    defaultMessage: 'Brak dodanych lokalizacji ',
  },
  emptyStateMsg: {
    id: 'companyManage.locationsList.emptyStateMsg',
    defaultMessage: 'Aby dodać pierwszą lokalizację ',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.locationsList.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.locationsList.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaText: {
    id: 'companyManage.locationsList.ctaText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaFilterText: {
    id: 'companyManage.locationsList.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

// Komponent listy lokalizacji
class LocationsTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.compareKeys = ['compareName', 'compareCity', 'compareStatus', 'pin'];
    this.getEmptyStateView = this.getEmptyStateView.bind(this);
  }

  getEmptyStateView() {
    const showModal = !this.props.userLocations.length && !this.props.searchWord.length;
    const headerText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateTitle, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateMsg, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
    const ctaText = showModal
      ? this.context.intl.formatMessage(messages.ctaText, {})
      : this.context.intl.formatMessage(messages.ctaFilterText, {});
    const ctaHandler = showModal
      ? () => {
          this.props.toggleModal('locations');
        }
      : this.props.clearSearchWord;
    return (
      <tr>
        <td className="no-content-to-show" colSpan="5">
          <EmptyState
            name="locationCTA"
            imgSrc="/img/locationsCTA.png"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
            shiftX="0"
          />
        </td>
      </tr>
    );
  }

  filterAndSort(fraze) {
    const data = [];
    for (const loc of this.props.userLocations) {
      const compareName = loc.name ? loc.name.toLowerCase() : '';
      const compareCity = loc.city ? loc.city.toLowerCase() : '';
      const compareAdditionalInformation = loc.additional_information ? loc.additional_information.toLowerCase() : '';
      const compareStatus = this.context.intl.formatMessage(messages.active, {});
      const { pin } = loc;
      const sortingData = {
        compareName,
        compareCity,
        compareStatus,
        compareAdditionalInformation,
        checked: this.props.locationsListUi.selected.includes(loc.id),
      };
      if (
        (compareName + compareCity + compareStatus + compareAdditionalInformation + pin).includes(fraze.toLowerCase())
      )
        data.push({ ...loc, ...sortingData });
    }
    const column = this.props.locationsListUi.sortColumn;
    const direction = this.props.locationsListUi.sortDecreesing ? -1 : 1;
    if (column >= 0) data.sort(compareByKey(this.compareKeys[column], direction));
    return data;
  }

  render() {
    const { trackIntercomEvent, locationsListUi, userPermissions, userLocations, deviceInfo, searchWord } = this.props;
    const rawData = this.filterAndSort(searchWord);
    const visibleLocationsIds = rawData.map(l => l.id);
    const tableHeight = deviceInfo.windowSize.height - 300;
    const disabledDeleteButton = userLocations.length === 1;

    const disabledRemoveSelected =
      !locationsListUi.selected.length || rawData.length === locationsListUi.selected.length;

    return (
      <div>
        <AddLocationModal />

        <div className="add_blue">
          <FeatureWrapper restriction={COMPANY_MANAGE_LOCATIONS_HIDE_ADD}>
            <FreemiumWrapper show={this.props.locationsLimitReached} intercomEvent={FREEMIUM_EVENT_ADD_MORE_LOCATIONS}>
              <Button
                onClick={() => {
                  this.props.toggleModal('locations');
                  trackIntercomEvent(ADD_NEW_LOCATION);
                }}
                modifiers="blue medium inline"
              >
                <FormattedMessage id="companyManage.locationsList.addLocation" defaultMessage="Dodaj lokalizacje" />
              </Button>
            </FreemiumWrapper>
          </FeatureWrapper>
        </div>
        <FeatureWrapper restriction={COMPANY_MANAGE_LOCATIONS_HIDE_DELETE}>
          <div className="delete_selected">
            <Button
              onClick={this.props.massDeleteLocationsConfirm}
              modifiers="reverse-red medium inline"
              disabled={disabledRemoveSelected}
            >
              <i className="material-icons">delete</i>
              <FormattedMessage id="companyManage.lists.removeSelected" defaultMessage="Usuń zaznaczone" />
            </Button>
          </div>
        </FeatureWrapper>
        <Grid
          width={900}
          height={tableHeight}
          columnStyle={{ width: '16vw', minWidth: '270px' }}
          columnGroup={
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
          }
          static={rawData.length < 25}
        >
          <Header>
            <LocationsHeader
              stateUI={locationsListUi}
              toggle={() => {
                this.props.toggleAllCheckboxes(visibleLocationsIds, 'locations');
              }}
              visible={visibleLocationsIds}
              sortFunc={this.props.changeSorting}
            />
          </Header>

          <Rows className="table_company">
            {rawData.length === 0
              ? this.getEmptyStateView()
              : rawData.map((location, index) => (
                  <LocationsRow
                    disabledDeleteButton={disabledDeleteButton}
                    data={location}
                    stateUI={locationsListUi}
                    toggle={() => {
                      this.props.toggleCheckbox(location.id, 'locations');
                    }}
                    edit={() => {
                      this.props.startEdit(location.id, 'locations');
                    }}
                    delete={() => {
                      this.props.deleteLocationConfirm(location.id);
                    }}
                    key={index}
                    userPermissions={userPermissions}
                  />
                ))}
          </Rows>
        </Grid>
        <ShownCounter visible={visibleLocationsIds.length} total={userLocations.length} />
      </div>
    );
  }
}

LocationsTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LocationsTable.propTypes = {
  locationsListUi: PropTypes.shape({
    showModal: PropTypes.bool,
    editedId: PropTypes.string,
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
  }).isRequired,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchWord: PropTypes.string.isRequired,
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  userPermissions: PropTypes.shape(),
  toggleModal: PropTypes.func.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  deleteLocationConfirm: PropTypes.func.isRequired,
  massDeleteLocationsConfirm: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  clearSearchWord: PropTypes.func,
  locationsLimitReached: PropTypes.string,
};
export default LocationsTable;
