export const DEVICE_HEADER = [
  { columnId: 0, message: { id: 'companyManage.devices.name', defaultMessage: 'Nazwa urządzenia' }, title: true },
  { columnId: 1, message: { id: 'companymanage.devicesHeader.brand', defaultMessage: 'Marka' } },
  { columnId: 2, message: { id: 'companymanage.devicesHeader.model', defaultMessage: 'Model' } },
  { columnId: 3, message: { id: 'companymanage.devicesHeader.date', defaultMessage: 'Data dodania' } },
  { columnId: 4, message: { id: 'companymanage.devicesHeader.locations', defaultMessage: 'Lokalizacje' } },
  { columnId: 5, message: { id: 'companymanage.devicesHeader.options', defaultMessage: 'Opcje' }, option: true },
];

export const DESCEND = 'descend';
export const ASCENT = 'ascent';

export const DEVICES_COLUMN_NAME = 'devices';
