import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import OptionsCell from '@/components/common/OptionsCell.jsx';
import {
  COMPANY_MANAGE_SHIFTBLOCKS_HIDE_DELETE,
  COMPANY_MANAGE_SHIFTBLOCKS_HIDE_EDIT,
} from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';

class ShiftBlockRow extends PureComponent {
  render() {
    const { id } = this.props.data;
    const jobTitles = [];
    this.props.userJobTitles.forEach((title, index) => {
      if (this.props.data.job_titles !== null) {
        this.props.data.job_titles.forEach(Id => {
          if (Id === title.id) {
            jobTitles.push(<ColorLabel key={title.id + index} text={title.title} color={title.color} />);
          }
        });
      }
    });

    const rowOptions = [
      {
        option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
        restriction: COMPANY_MANAGE_SHIFTBLOCKS_HIDE_EDIT,
        style: 'edit',
        function: this.props.edit,
      },
      {
        option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
        restriction: COMPANY_MANAGE_SHIFTBLOCKS_HIDE_DELETE,
        style: 'delete',
        function: this.props.delete,
      },
    ];

    const { options, styles, functions } = applyPermissionsForOptions(rowOptions, this.props.userPermissions);
    const workingHours = this.props.data.working_hours;
    return (
      <tr data-test={`shiftsBlockRow-${workingHours}`}>
        <td style={{ textAlign: 'left ' }}>
          <Checkbox
            checked={this.props.stateUI.selected.includes(id)}
            toggle={this.props.toggle}
            style={{ margin: '0', marginLeft: '3px' }}
            testId="shiftsBlockRow-rowCheckbox"
          />

          <div className="col_fulltime" style={{ marginTop: '5px', fontSize: '16px' }}>
            {workingHours}
          </div>
        </td>
        <td className="center">{this.props.data.length}</td>
        <td>{jobTitles}</td>
        <td className="center">
          <OptionsCell options={options} styles={styles} functions={functions} />
        </td>
      </tr>
    );
  }
}
ShiftBlockRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    job_titles: PropTypes.arrayOf(PropTypes.string),
    working_hours: PropTypes.string,
    length: PropTypes.string,
  }).isRequired,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  toggle: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
};
export default ShiftBlockRow;
