import { connect } from 'react-redux';

import { openAddAvailabilityModal } from '@/actions/uiState';

import Component from './AvaCell.jsx';

const mapStateToProps = state => ({
  isAvaDragged: Boolean(state.reducer.schedule.dragAndDrop.draggedAvailability.id),
});

const mapDispatchToProps = {
  openAddAvailabilityModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
