import classnames from 'classnames';
import React from 'react';

import './panel.scss';

interface PanelProps extends React.PropsWithChildren {
  className?: string;
}

const Panel = ({ children, className }: PanelProps) => {
  const mainClassName = classnames('k-mdPanel', className);
  return <div className={mainClassName}>{children}</div>;
};

export default Panel;
