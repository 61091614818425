import PropTypes from 'prop-types';
import { memo } from 'react';

import { messages } from '../PayrollEditShiftAndAttendanceModal.messages';

const PayrollEditShiftAndAttendanceDetails = ({ fullName, date, isShift, isAttendance }, { intl }) => (
  <div className="heading heading--extraTopMargin">
    {intl.formatMessage(messages.headerMessage, {
      date,
      name: fullName,
      isShift,
      isAttendance,
      isShiftAndAttendance: isShift && isAttendance,
    })}
  </div>
);
PayrollEditShiftAndAttendanceDetails.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollEditShiftAndAttendanceDetails.propTypes = {
  fullName: PropTypes.string,
  date: PropTypes.string,
  isShift: PropTypes.bool,
  isAttendance: PropTypes.bool,
};

export default memo(PayrollEditShiftAndAttendanceDetails);
