import { connect } from 'react-redux';

import { addAbsenceType, editAbsenceType } from '@/actions/absences.js';
import { hideModal } from '@/actions/uiState.js';
import { ABSENCE_TYPE_MODAL } from '@/constants/modalTypes.js';

import AbsenceTypeModal from './AbsenceTypeModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ABSENCE_TYPE_MODAL,
  modalObject: state.reducer.uiState.modalObject[ABSENCE_TYPE_MODAL],
});

const mapDispatchToProps = {
  hideModal,
  addAbsenceType,
  editAbsenceType,
};

const AbsenceTypeModalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceTypeModal);

export default AbsenceTypeModalContainer;
