import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';
import { filterScheduleViewState, initScheduleViewState, sortScheduleViewState } from '@/actions/schedule/scheduleView';
import * as AT from '@/constants/ActionTypes';

export const uiUpdateMidddleare = store => next => action => {
  const { reducer: reducerBeforeAction } = store.getState();
  const returnState = next(action);
  const { reducer, routing } = store.getState();
  const { currentUser } = reducer;
  const currentRoute = routing.locationBeforeTransitions?.pathname;

  // Sort and filter after this actions ( getting new schedule etc. )
  if ([AT.GET_SCHEDULE_SUCCESFUL].includes(action.type)) {
    store.dispatch(initScheduleViewState());
  } else if (
    // or when user goes to schedule view from other tab
    ['@@router/LOCATION_CHANGE'].includes(action.type) &&
    action.payload.pathname === '/schedule' &&
    currentUser.authenticated
  ) {
    store.dispatch(initScheduleViewState());
  } else if (currentRoute === '/schedule') {
    if (
      [
        AT.CHANGE_JOBTITLE_FILTER,
        AT.CHANGE_JOBTITLE_FILTER_GROUPED,
        AT.CHANGE_EMPLOYMENT_CONDITION_FILTER,
        AT.CHANGE_SCHEDULE_SEARCH_STRING,
        AT.DELETE_EMPLOYEE,
        AT.DELETE_EMPLOYEE_SET,
        AT.DELETE_SHIFT,
        AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
        AT.ADD_LOANED_EMPLOYEES,
      ].includes(action.type)
    ) {
      store.dispatch(filterScheduleViewState());
    }

    if (action.type === AT.TOGGLE_SCHEDULE_SETTING) {
      const { viewSettings } = reducerBeforeAction.schedule;
      const settingsThatChangeFiltering = action.payload.filter(item =>
        [
          'supplementaryEmployees',
          'showSupplementaryEmployeesWithoutShift',
          'showEmployeesWithoutShifts',
          'showShiftsFromOtherLocations',
          'loanedEmployees',
        ].includes(item.type),
      );
      const shouldFilter = settingsThatChangeFiltering.some(setting => {
        if (!viewSettings[setting.type]) return true;
        if (setting.value !== viewSettings[setting.type].value) return true;
        return false;
      });
      if (shouldFilter) {
        store.dispatch(filterScheduleViewState());
      }
    }

    if ([AT.CHANGE_EMPLOYEE_SORTING, AT.CHANGE_CUSTOM_SORTING].includes(action.type)) {
      store.dispatch(sortScheduleViewState());
    }
  }
  if (
    currentRoute === '/reports' &&
    [AT.CHANGE_MULTIPLE_LOCATION_FILTER, AT.CHANGE_JOBTITLE_FILTER, AT.CHANGE_EMPLOYMENT_CONDITION_FILTER].includes(
      action.type,
    )
  ) {
    store.dispatch(getPayrollLocationViewData());
  }
  return returnState;
};
