import { connect } from 'react-redux';

import { addAvaType, prepareAddJobTitle, prepareAddLocation, toggleModal } from '@/actions/companymanage';
import { mainDateChangeMode } from '@/actions/mainDate.js';
import { changeScheduleSearchString, showModal } from '@/actions/uiState.js';

import Component from './ScheduleAdditionalModes.jsx';

const mapStateToProps = state => ({
  copyPaste: state.reducer.copyPaste,
  currentUser: state.reducer.currentUser,
  employeeSorting: state.reducer.employeeSorting,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  jobtitleFilter: state.reducer.jobtitleFilter,
  listUi: state.reducer.listsUi,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  mainDateStore: state.reducer.mainDateStore,
  openShifts: state.reducer.openShifts,
  scheduleUIState: state.reducer.scheduleUIState,
  scheduleState: state.reducer.scheduleState,
  tradeShifts: state.reducer.tradeShifts,
  userLocations: state.reducer.userLocations,
  userJobTitles: state.reducer.userJobTitles,
  userShiftblocks: state.reducer.userShiftblocks,
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
  userTemplates: state.reducer.userTemplates,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  mainDateChangeMode,
  toggleModal,
  prepareAddLocation,
  prepareAddJobTitle,
  showModal,
  changeScheduleSearchString,
  addAvaType,
};

const SchedulerViewContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default SchedulerViewContainer;
