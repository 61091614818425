import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice.jsx';

class TopBarJobTitleFilter extends Component {
  constructor(props) {
    super(props);

    this.changeFunc = this.changeFunc.bind(this);
  }

  componentDidMount() {
    const {
      userJobTitles,
      changeJobTitleFilter,
      changeJobTitleFilterGrouped,
      storeOnlyIds,
      selectAllWhenEmpty,
      jobtitleFilter,
      areFiltersInUrl,
    } = this.props;

    if (
      (!jobtitleFilter.selectedJobtitlesGrouped || jobtitleFilter.selectedJobtitlesGrouped.length === 0) &&
      selectAllWhenEmpty
    ) {
      const newJobTitles = storeOnlyIds ? userJobTitles.map(j => j.id) : userJobTitles;
      const jobTitleFilter = storeOnlyIds ? jobtitleFilter : jobtitleFilter.selectedJobtitles;
      if (changeJobTitleFilterGrouped && !areFiltersInUrl) {
        changeJobTitleFilterGrouped(userJobTitles);
      }

      if (!jobTitleFilter.length) changeJobTitleFilter(newJobTitles);
    }
  }

  getSelectedJobTitles() {
    if (this.props.showFilteredJobTitles) {
      return this.props.jobtitleFilter.selectedJobtitlesGrouped;
    }

    return this.props.storeOnlyIds
      ? this.props.jobtitleFilter.map(id => ({ id }))
      : this.props.jobtitleFilter.selectedJobtitles;
  }

  changeFunc(jobTitles) {
    const newJobTitles = this.props.storeOnlyIds ? jobTitles.map(j => j.id) : jobTitles;
    if (this.props.changeJobTitleFilterGrouped) {
      this.props.changeJobTitleFilterGrouped(newJobTitles);
    }
    this.props.changeJobTitleFilter(newJobTitles);
  }

  render() {
    const { userJobTitles, scheduleLocationFilter, userJobTitlesGrouped } = this.props;
    let jobTitles = userJobTitles;
    if (this.props.showFilteredJobTitles) {
      const jobTitleIds = scheduleLocationFilter.reduce(
        (result, locationId) => [...result, ...(userJobTitlesGrouped[locationId] || [])],
        [],
      );
      jobTitles = userJobTitles.filter(jobTitle => jobTitleIds.includes(jobTitle.id));
    }
    return (
      <div className="k-topBarJobTitleFilter k-topBar__leftElement">
        <TopBarMultipleChoice
          filterName={<FormattedMessage id="topbar.jobTitleFilter.title" defaultMessage="Stanowiska" />}
          objectsToChoose={jobTitles}
          selectedObjects={this.getSelectedJobTitles()}
          onChange={this.changeFunc}
          icon={<Icon type="kadro" name="suitcase" className="k-topBarChoice__selectedIcon" />}
          className="k-topBarChoice--jobTitleFilter"
          displayKey="title"
        />
      </div>
    );
  }
}

TopBarJobTitleFilter.defaultProps = {
  selectAllWhenEmpty: true,
};

TopBarJobTitleFilter.propTypes = {
  showFilteredJobTitles: PropTypes.bool,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitlesGrouped: PropTypes.shape({}),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  jobtitleFilter: PropTypes.oneOfType([
    PropTypes.shape({
      selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
      selectedJobtitlesGrouped: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  changeJobTitleFilter: PropTypes.func,
  changeJobTitleFilterGrouped: PropTypes.func,
  storeOnlyIds: PropTypes.bool,
  selectAllWhenEmpty: PropTypes.bool,
};

export default TopBarJobTitleFilter;
