import { connect } from 'react-redux';

import Component from './ScheduleItemSelectMarker.jsx';

const mapStateToProps = (state, ownProps) => ({
  enabled:
    state.reducer.schedule.selectedCells.selectionsPerLocation[ownProps.locationId]
      ?.selected
      ?.[ownProps.employeeId]
      ?.[ownProps.date],
  availabilitiesModeEnabled: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
