import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { LOAD_BUDGET_TARGET_MODAL } from '@/constants/modalTypes.js';
import { toBase64 } from '@/utils/fileHelpers.js';

const BUDGET_TARGET_IMPORT = 'Import targetów budżetu';
const BUDGET_TARGET_IMPORT_TEMPLATE = 'Szablon importu targetów budżetu';

const ImportBudgetTargetModal = props => {
  const {
    from,
    to,
    importModalObject,
    userLocations,
    pendingExports,
    clearImportModal,
    hideModal,
    runExport,
    toggleImportModalOverlay,
    uploadFileToImport,
    clearBudgetTargetsImports,
    isModalVisible,
    showModal,
  } = props;

  if (!userLocations?.length) return null;
  const locations = userLocations.map(l => String(l.id));

  const currentTemplate = [...pendingExports]
    .reverse()
    .find(e => e.type === 'f-budget-targets-import' && e.name === BUDGET_TARGET_IMPORT_TEMPLATE);
  const importObject = [...pendingExports]
    .reverse()
    .find(e => e.type === 'f-budget-targets-import' && e.status === 'success' && e.name === BUDGET_TARGET_IMPORT);

  useEffect(() => {
    if (currentTemplate?.link) {
      const link = document.createElement('a');
      link.href = currentTemplate.link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toggleImportModalOverlay(false);
      clearBudgetTargetsImports();
    }
  }, [currentTemplate]);

  const hideAndClear = () => {
    toggleImportModalOverlay(false);
    uploadFileToImport(null);
    hideModal();
    clearImportModal();
  };

  useEffect(() => {
    if (!importObject) return;
    toggleImportModalOverlay(false);
    if (!importObject.errors || importObject.errors.length === 0) {
      hideAndClear();
    }
  }, [importObject]);

  const handleExport = useCallback(async () => {
    toggleImportModalOverlay(true);
    await runExport('f-budget-targets-import', BUDGET_TARGET_IMPORT_TEMPLATE, {
      type: 'template',
      from,
      to,
      locationIds: locations,
    });
  }, [from, to, locations]);

  const handleSubmit = useCallback(async () => {
    toggleImportModalOverlay(true);
    const base64File = await toBase64(importModalObject.uploadedFile);
    await runExport('f-budget-targets-import', BUDGET_TARGET_IMPORT, {
      type: 'import',
      file: base64File,
    });
  }, [importModalObject.uploadedFile]);

  const handleCancel = useCallback(() => {
    hideAndClear();
    showModal(LOAD_BUDGET_TARGET_MODAL);
  });

  return (
    <MDKadroModal
      show={isModalVisible}
      onHide={hideAndClear}
      onSubmit={handleSubmit}
      modifiers={['wide']}
      title={<FormattedMessage id="import.importData" defaultMessage="Importuj dane" />}
      hideConfirmButton={!importModalObject.uploadedFile}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
      loading={importModalObject.showOverlay}
      onCancelFooter={handleCancel}
      cancelText={<FormattedMessage id="common.back" defaultMessage="Wstecz" />}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importSchedule.loadData" defaultMessage="Wczytaj dane" />
          </div>
          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="importSchedule.fileImport.exportFileText"
              defaultMessage="Wczytaj dane z pliku Excel. {downloadTemplate}  i wypełnij w nim dane."
              values={{
                downloadTemplate: (
                  <span className="mdImportModalExport__link" role="none" onClick={handleExport}>
                    {' '}
                    <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />{' '}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop fileFormats={['xlsx']} hideButton onUpload={uploadFileToImport} />
        </div>
      </div>
    </MDKadroModal>
  );
};

ImportBudgetTargetModal.propTypes = {
  isModalVisible: PropTypes.bool,
  runExport: PropTypes.func,
  hideModal: PropTypes.func,
  clearImportModal: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  pendingExports: PropTypes.arrayOf(PropTypes.shape({})),
  uploadFileToImport: PropTypes.func,
  toggleImportModalOverlay: PropTypes.func,
  importModalObject: PropTypes.shape({
    uploadedFile: PropTypes.string,
    importedData: PropTypes.arrayOf(PropTypes.shape({})),
    showOverlay: PropTypes.bool,
  }),
  clearBudgetTargetsImports: PropTypes.func,
  showModal: PropTypes.func,
};

export default ImportBudgetTargetModal;
