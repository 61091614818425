import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addMetricTitle: {
    id: 'budgetMetric.addModal.title',
    defaultMessage: 'Stwórz metrykę',
  },
  editMetricTitle: {
    id: 'budgetMetric.editModal.title',
    defaultMessage: 'Edytuj metrykę',
  },
  name: {
    id: 'budgetMetric.field.name',
    defaultMessage: 'Nazwa metryki',
  },
  type: {
    id: 'budgetMetric.field.type',
    defaultMessage: 'Format metryki',
  },
  timeInterval: {
    id: 'budgetMetric.field.timeInterval',
    defaultMessage: 'Przedział czasowy',
  },
  color: {
    id: 'budgetMetric.field.color',
    defaultMessage: 'Kolor',
  },
  icon: {
    id: 'budgetMetric.field.icon',
    defaultMessage: 'Ikonka',
  },
  number: {
    id: 'budgetMetric.format.number',
    defaultMessage: 'Liczbowy',
  },
  percent: {
    id: 'budgetMetric.format.percent',
    defaultMessage: 'Procentowy',
  },
  unit: {
    id: 'budgetMetric.format.unit',
    defaultMessage: 'Jednostkowy',
  },
  hour: {
    id: 'budgetMetric.format.hour',
    defaultMessage: 'Godzinowy',
  },
  currency: {
    id: 'budgetMetric.format.currency',
    defaultMessage: 'Waluta',
  },
  typeTooltip: {
    id: 'budgetMetric.tooltip.type',
    defaultMessage: 'Wybór formatu danych, mówi on w jakich jednostkach należy rozpatrywać  wartości tej metryki',
  },
  timeIntervalTooltip: {
    id: 'budgetMetric.tooltip.timeInterval',
    defaultMessage: 'Wybierz przedział czasowy dla którego będzie wpisywana oraz wyświetlona ta metryka',
  },
  '15m': {
    id: 'budgetMetric.timeInterval.15',
    defaultMessage: '15 minutowy',
  },
  '30m': {
    id: 'budgetMetric.timeInterval.30',
    defaultMessage: '30 minutowy',
  },
  hourly: {
    id: 'budgetMetric.timeInterval.hourly',
    defaultMessage: 'Godzinowy',
  },
  daily: {
    id: 'budgetMetric.timeInterval.daily',
    defaultMessage: 'Dniowy',
  },
  aggregation: {
    id: 'budgetMetric.field.aggregation',
    defaultMessage: 'Agregacja',
  },
  aggregationTooltip: {
    id: 'budgetMetric.tooltip.aggregation',
    defaultMessage:
      'Agregacja określa jak łączone są wprowadzone dane:\nSuma - wprowadzone dane są do siebie dodawane\nŚrednia - wprowadzone dane są uśredniane',
  },
});

export const locationsMultiSelectMessages = defineMessages({
  headerTitle: {
    id: 'budgetMetric.locations',
    defaultMessage: 'Lokalizacje',
  },
  selectAll: {
    id: 'budgetMetric.locations.all',
    defaultMessage: 'wszystkie',
  },
  deselectAll: {
    id: 'budgetMetric.locations.none',
    defaultMessage: 'żadna',
  },
  bodyLabel: {
    id: 'budgetMetric.locations.choose',
    defaultMessage: 'Wybierz lokalizacje',
  },
  locationsPlaceholder: {
    id: 'budgetMetric.locations.placeholder',
    defaultMessage: '({numberOfSelectedLocations}) Lokalizacje',
  },
});

export const aggregationMessages = defineMessages({
  sum: {
    id: 'budgetMetric.sum',
    defaultMessage: 'Suma',
  },
  avg: {
    id: 'budgetMetric.average',
    defaultMessage: 'Średnia',
  },
});
