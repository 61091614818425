import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDSearchBox } from '@/components/common/inputs/MDComponents.jsx';
import CustomNotificationsModal from '@/components/companymanage/employees/CustomNotificationModal/CustomNotificationsModal.redux.js';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { sortByKey } from '@/utils/arrayHelpers.js';

import DashboardTeamHeader from '../DashboardTeamHeader/DashboardTeamHeader.redux';
import DashboardTeamItem from '../DashboardTeamItem/DashboardTeamItem.jsx';
import { getFilteredEmployees } from './DashboardTeam.helpers.js';

import './DashboardTeam.scss';

const DashboardTeam = ({ attendances, teamSorting, showModal }, context) => {
  const [searchText, setSearchText] = useState('');
  const [attendancesToShow, setAttendancesToShow] = useState(attendances);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');

  useEffect(() => {
    const filteredEmployees = getFilteredEmployees(attendances, searchText, context);
    setAttendancesToShow(filteredEmployees);
  }, [searchText, attendances]);

  const { sortColumn, sortDecreasing } = teamSorting;

  const teamContainerClassname = classnames('md-dashboardContainer', 'md-dashboardTeam', {
    'md-dashboardTeam--empty': attendancesToShow.length === 0,
  });

  return (
    <>
      <CustomNotificationsModal relevantEmployees={[selectedEmployeeId]} />

      <div className={teamContainerClassname}>
        <div className="md-dashboardTeam__header">
          <div className="md-dashboardContainer__title">
            <i className="md-dashboardContainer__icon material-icons">people</i>
            <FormattedMessage id="dashboard.currentlyAtWork" defaultMessage="Obecnie w pracy" />
          </div>
          <div className="md-dashboardTeam__options">
            <MDSearchBox searchText={searchText} handleSearchText={e => setSearchText(e.target.value)} />
          </div>
        </div>
        {attendancesToShow?.length > 0 ? (
          <>
            <DashboardTeamHeader />
            <OverlayScrollbarsComponent className="k-overlay-scrollbar md-dashboardTeam__container">
              {sortByKey(attendancesToShow, sortColumn || 'fullName', false, { decrease: sortDecreasing }).map(
                attendance => (
                  <DashboardTeamItem
                    attendance={attendance}
                    key={attendance.employee_id}
                    setSelectedEmployeeId={id => setSelectedEmployeeId(id)}
                    showModal={showModal}
                  />
                ),
              )}
            </OverlayScrollbarsComponent>
          </>
        ) : (
          <EmptyState
            name="employeeCTA"
            className="md-dashboardTeam__emptyState"
            imgSrc="/img/employeesCTA.png"
            emptyStateHeader=""
            emptyStateText={
              attendances.length > 0 ? (
                <FormattedMessage
                  id="dashboard.todayTeam.noEmployeesFiltered"
                  defaultMessage="Brak pracowników zgodnych z wyszukiwaną frazą"
                />
              ) : (
                <FormattedMessage id="dashboard.todayTeam.noEmployees" defaultMessage="Brak obecnych w pracy" />
              )
            }
            ctaText=""
          />
        )}
      </div>
    </>
  );
};

DashboardTeam.contextTypes = {
  intl: PropTypes.shape({}),
};

DashboardTeam.propTypes = {
  attendances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showModal: PropTypes.func,
  teamSorting: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDecreasing: PropTypes.bool,
  }),
};

export default DashboardTeam;
