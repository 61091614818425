import { connect } from 'react-redux';

import Component from './ScheduleModeView.jsx';

const mapStateToProps = state => ({
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  scheduleState: state.reducer.scheduleState,
  eventsTableEnabled: state.reducer.schedule.viewSettings.events.value,
  showBudgetTable: state.reducer.schedule.viewSettings.budget.value,
  locationSettings: state.reducer.settings.locationSettings,
  displayMode: state.reducer.scheduleUIState.selectedDisplayMode.type,
  userPermissions: state.reducer.userPermissions,
 });

const mapDispatchToProps = {};

const SchedulerViewContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default SchedulerViewContainer;
