import { messages } from './ExportsCatalogView.messages';

export const getSections = intl => [
  {
    name: 'category',
    displayName: intl.formatMessage(messages.categories),
    items: [
      {
        name: 'all',
        displayName: intl.formatMessage(messages.all),
      },
      {
        name: 'timeWorked',
        displayName: intl.formatMessage(messages.timeWorked),
      },
      {
        name: 'otherSystems',
        displayName: intl.formatMessage(messages.otherSystems),
      },
      {
        name: 'operational',
        displayName: intl.formatMessage(messages.operational),
      },
      {
        name: 'budget',
        displayName: intl.formatMessage(messages.budget),
      },
    ],
  },
  {
    name: 'format',
    displayName: intl.formatMessage(messages.format),
    items: [
      {
        name: 'all',
        displayName: intl.formatMessage(messages.all),
      },
      {
        name: 'xls',
        displayName: intl.formatMessage(messages.xls),
      },
      {
        name: 'pdf',
        displayName: intl.formatMessage(messages.pdf),
      },
      {
        name: 'xml',
        displayName: intl.formatMessage(messages.xml),
      },
    ],
  },
];
