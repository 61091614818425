import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconButton from '@/components/common/Basic/IconButton.jsx';

const ProductionQuotasTable = props => (
  <div className="k-wrapper animated fadeInRight">
    <div className="row">
      <div className="col-lg-12">
        <div id="tab-1" className="tab-pane active">
          <div className="k-panel">
            <div className="table-responsive">
              <table className="table k-pquotas">
                {props.colgroup}
                <thead>
                  <tr>
                    <th className="k-pquotas__header stanow">
                      <FormattedMessage id="productionQuotas.table.jobTitle" defaultMessage="Stanowiska" />
                    </th>
                    <th className="k-pquotas__header stawka">
                      <FormattedMessage id="productionQuotas.table.factor" defaultMessage="Mnożnik" />
                    </th>
                    <th className="k-pquotas__header target">
                      <FormattedMessage id="productionQuotas.table.target" defaultMessage="Target" />
                      <IconButton
                        icon="help"
                        description={
                          <FormattedMessage
                            id="productionQuotas.table.targetDescription"
                            defaultMessage="Ilość / osobogodzinę"
                          />
                        }
                        wrapperClassName="k-pquotas__tableTooltip"
                      />
                    </th>
                    <th className="k-pquotas__header realization">
                      <FormattedMessage id="productionQuotas.table.realization" defaultMessage="Realizacja" />
                      <IconButton
                        icon="help"
                        description={
                          <FormattedMessage
                            id="productionQuotas.table.realizationDescription"
                            defaultMessage="Ilość / osobogodzinę"
                          />
                        }
                        wrapperClassName="k-pquotas__tableTooltip"
                      />
                    </th>
                    <th className="k-pquotas__header bonus">
                      <FormattedMessage id="productionQuotas.table.quota" defaultMessage="Norma" />
                      <IconButton
                        icon="help"
                        description={
                          <FormattedMessage
                            id="productionQuotas.table.quotaDescription"
                            defaultMessage="Ilość sztuk wyprodukowanych"
                          />
                        }
                        wrapperClassName="k-pquotas__tableTooltip"
                      />
                    </th>
                    <th className="k-pquotas__header perf">
                      <FormattedMessage id="productionQuotas.table.performance" defaultMessage="Wydajność" />
                    </th>
                    <th className="k-pquotas__header sumgodz">
                      <FormattedMessage id="productionQuotas.table.sumHours" defaultMessage="Suma Godzin" />
                    </th>
                    <th className="k-pquotas__header dowyplaty">
                      <FormattedMessage id="productionQuotas.table.toPayout" defaultMessage="Do wypłaty [PLN]" />
                    </th>
                  </tr>
                </thead>
                <tbody>{props.children}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

ProductionQuotasTable.propTypes = {
  children: PropTypes.node,
  colgroup: PropTypes.node,
};

export default ProductionQuotasTable;
