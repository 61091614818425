import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { ABSENCE_ADD_MODAL } from '@/constants/modalTypes.js';
import { ABSENCES_FOR_OTHERS_HIDE_ADD } from '@/constants/Restrictions.js';

import './AbsencesViewHeaderOptions.scss';

const AbsencesViewHeaderOptions = ({ showModal, restrictions, currentUser }) => {
  const userCanHaveAbsences = currentUser.employment_conditions.show_absences;

  if (restrictions.includes(ABSENCES_FOR_OTHERS_HIDE_ADD) && !userCanHaveAbsences) {
    return null;
  }

  return (
    <div className="absencesViewHeaderOptions">
      <Button modifiers={['orange']} onClick={() => showModal(ABSENCE_ADD_MODAL, { showEmployeeSelect: true })}>
        <span className="material-icons">add</span>
        <FormattedMessage id="absenceViewHeaderOptions.addAbsence.text" defaultMessage="Dodaj urlop" />
      </Button>
    </div>
  );
};

AbsencesViewHeaderOptions.propTypes = {
  showModal: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.shape({
    employment_conditions: PropTypes.shape({ show_absences: PropTypes.bool }),
  }),
};

export default AbsencesViewHeaderOptions;
