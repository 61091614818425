import { typesOfMultipleLocations } from '@/constants/locations.js';

export const getUpdatedLocations = (selected, locationType) => {
  if (!selected.length) return null;
  const typesOfLastSelectedLocations =
    typeof selected[0] === 'object' ? typesOfMultipleLocations.object : typesOfMultipleLocations.id;
  if (typesOfLastSelectedLocations === locationType) return null;
  if (typesOfLastSelectedLocations === typesOfMultipleLocations.object) return selected.map(({ id }) => id);
  return selected;
};
