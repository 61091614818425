import { connect } from 'react-redux';

import { handleSwapShiftDrop } from '@/actions/schedule/dragAndDrop';

import Component from './ScheduleShiftBlockDropArea';

const mapDispatchToProps = {
  handleSwapShiftDrop,
};

export default connect(null, mapDispatchToProps)(Component);
