import PropTypes from 'prop-types';

import { parseMinutesToHumanForm } from '@/utils/dateHelper';

import { messages } from '../OvertimeCollectionOptions.messages';

const OvertimeUsageDifference = ({ sumOfOvertimeAcceptances, overtimeUsageInMinutes }, { intl }) => {
  if (sumOfOvertimeAcceptances > overtimeUsageInMinutes) {
    return (
      <div className="overtimeCollectionOptions__usage">
        {intl.formatMessage(messages.overtimeUsageTooHigh, {
          differenceInHours: parseMinutesToHumanForm(Math.abs(overtimeUsageInMinutes - sumOfOvertimeAcceptances)),
        })}
      </div>
    );
  }
  if (sumOfOvertimeAcceptances < overtimeUsageInMinutes) {
    return (
      <div className="overtimeCollectionOptions__usage">
        {intl.formatMessage(messages.overtimeUsageToLow, {
          differenceInHours: parseMinutesToHumanForm(Math.abs(sumOfOvertimeAcceptances - overtimeUsageInMinutes)),
        })}
      </div>
    );
  }
  return null;
};

OvertimeUsageDifference.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OvertimeUsageDifference.propTypes = {
  sumOfOvertimeAcceptances: PropTypes.number,
  overtimeUsageInMinutes: PropTypes.number,
};

export default OvertimeUsageDifference;
