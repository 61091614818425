import classnames from 'classnames';
import PropTypes from 'prop-types';

import { PAYROLL_MODES } from '@/constants/payrollSettings';

import './PayrollMode.scss';

const PayrollMode = props => {
  const { mode, changePayrollMode } = props;

  const toggleMode = newMode => () => {
    if (mode === newMode) return;
    changePayrollMode(newMode);
  };

  const editableBtnClassname = classnames('k-payrollMode__button', {
    'k-payrollMode__button--active': mode === PAYROLL_MODES.editable,
  });
  const readonlyBtnClassname = classnames('k-payrollMode__button', {
    'k-payrollMode__button--active': mode === PAYROLL_MODES.readonly,
  });

  return (
    <div className="k-payrollMode__toggle">
      <div className={readonlyBtnClassname} role="presentation" onClick={toggleMode(PAYROLL_MODES.readonly)}>
        <i className="material-icons-outlined">lock</i>
      </div>
      <div className={editableBtnClassname} role="presentation" onClick={toggleMode(PAYROLL_MODES.editable)}>
        <i className="material-icons-outlined">lock_open</i>
      </div>
    </div>
  );
};

PayrollMode.propTypes = {
  changePayrollMode: PropTypes.func,
  mode: PropTypes.string,
};

export default PayrollMode;
