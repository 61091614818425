import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  enabled: false,
  selectionsPerLocation: {},
};

const mergeCellsToCurrentStructure = (structure, cells) => {
  const newStructure = { ...structure };
  cells.forEach(cell => {
    const { employeeId, date } = cell;
    newStructure[employeeId] = { ...(newStructure[employeeId] || {}), [date]: true };
  });
  return newStructure;
};

const removeCellsFromCurrentStructure = (structure, cells) => {
  const newStructure = { ...structure };
  cells.forEach(cell => {
    const { employeeId, date } = cell;
    const newEmployeeData = { ...(newStructure[employeeId] || {}) };
    delete newEmployeeData[date];
    newStructure[employeeId] = newEmployeeData;
  });
  Object.keys(newStructure).forEach(employeeId => {
    if (!newStructure[employeeId] || !Object.keys(newStructure[employeeId]).length) {
      delete newStructure[employeeId];
    }
  });
  return newStructure;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.REPLACE_SCHEDULE_SELECTED_CELLS:
    case AT.ADD_SCHEDULE_SELECTED_CELLS:
    case AT.REMOVE_SCHEDULE_SELECTED_CELLS:
      return {
        ...state,
        selectionsPerLocation: {
          ...state.selectionsPerLocation,
          [action.locationId]: locationReducer(state.selectionsPerLocation[action.locationId], action),
        },
      };
    case AT.MAIN_DATE_CHANGE_MODE:
    case AT.MAIN_DATE_MOVE_TO_DATE:
    case AT.MAIN_DATE_MOVE_LEFT:
    case AT.MAIN_DATE_CHANGE_MODE_AND_DATE:
    case AT.MAIN_DATE_MOVE_RIGHT:
    case AT.TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT:
    case AT.CLEAR_ALL_SCHEDULE_SELECTED_CELLS:
    case AT.TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT:
      return { ...initialState };
    default:
      return state;
  }
};

const initialLocationState = {
  selected: {},
  pivot: null,
};

const locationReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case AT.ADD_SCHEDULE_SELECTED_CELLS:
      return { ...state, selected: mergeCellsToCurrentStructure(state.selected, action.payload), pivot: action.pivot };
    case AT.REPLACE_SCHEDULE_SELECTED_CELLS:
      return { ...state, selected: mergeCellsToCurrentStructure({}, action.payload), pivot: action.pivot };
    case AT.REMOVE_SCHEDULE_SELECTED_CELLS:
      return {
        ...state,
        selected: removeCellsFromCurrentStructure(state.selected, action.payload),
        pivot: action.pivot,
      };
    default:
      return state;
  }
};

export default reducer;
