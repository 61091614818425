import { defineMessages } from 'react-intl';

const messages = defineMessages({
  comment: {
    id: 'common.comment',
    defaultMessage: 'Komentarz',
  },
});

export default messages;
