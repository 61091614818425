import PropTypes from 'prop-types';

const ReportBox = props => {
  let label = '';
  if (props.labelText) {
    label = <span className="label label-success pull-right">{props.labelText}</span>;
  }
  let percentageChange = '';
  if (props.percentageChange) {
    percentageChange = (
      <div className="stat-percent font-bold text-success">
        {props.percentageChange}% <i className="fa fa-bolt" />
      </div>
    );
  }
  return (
    <div className="col-md-3">
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          {label}
          <h5>{props.boxTitle}</h5>
        </div>
        <div className="ibox-content">
          <h1 className="no-margins">{props.boxValue}</h1>
          {percentageChange}
          <small>{props.boxDescription}</small>
        </div>
      </div>
    </div>
  );
};

ReportBox.propTypes = {
  labelText: PropTypes.string,
  boxTitle: PropTypes.node.isRequired,
  boxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxDescription: PropTypes.node.isRequired,
  percentageChange: PropTypes.string,
};

export default ReportBox;
