export const calculateTotalHoursAndCost = (shiftsSummary, dateArray) =>
  Object.entries(shiftsSummary).reduce(
    (result, [date, item]) => {
      if (dateArray.includes(date)) {
        return {
          allHours: result.allHours + item.totalMinutes,
          allCosts: result.allCosts + item.totalCosts,
        };
      }
      return result;
    },
    {
      allHours: 0,
      allCosts: 0,
    },
  );

export const getTotalLabourCost = (dateArray, budgetEstimates, shiftsSummary) =>
  dateArray.reduce(
    (result, date) => {
      const budgetEstimate = budgetEstimates.dailyValues[date];

      if (!budgetEstimate || !budgetEstimate.estimated_budget) {
        return result;
      }

      const cost = shiftsSummary[date]?.totalCosts;

      return {
        totalBudgetEstimates: result.totalBudgetEstimates + budgetEstimate.estimated_budget,
        totalLabourCost: result.totalLabourCost + cost,
      };
    },
    {
      totalBudgetEstimates: 0,
      totalLabourCost: 0,
    },
  );

export const checkIsEditEnable = (scheduleFilter, locationSettings) => {
  if (scheduleFilter.length !== 1) {
    return false;
  }

  const selectedLocation = scheduleFilter[0];
  return locationSettings[selectedLocation].budget_estimates_period === 'day';
};

export const getFormattedEstimate = value => (typeof value === 'number' ? Math.round(Number(value)) / 100 || 0 : '-');
