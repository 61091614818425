import {
  ADD_ATTENDANCE_BONUS_SUCCESFUL,
  PAYROLL_LOCATION_SET_SELECTED,
  PAYROLL_LOCATION_TOGGLE_SELECTED,
  SET_BONUS_VALUES,
  SET_PAYROLL_SEARCH_STRING,
  TOGGLE_PAYROLL_SETTINGS_MODAL,
} from '@/constants/ActionTypes.js';

export const initialState = {
  selectedEmployees: [],
  showSettingsModal: false,
  bonusValues: {},
  searchString: '',
};

export const payrollUI = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PAYROLL_SETTINGS_MODAL:
      return { ...state, showSettingsModal: !state.showSettingsModal };
    case PAYROLL_LOCATION_SET_SELECTED:
      return Object.assign({}, state, { selectedEmployees: action.payload });
    case PAYROLL_LOCATION_TOGGLE_SELECTED:
      if (state.selectedEmployees.includes(action.payload)) {
        return Object.assign({}, state, {
          selectedEmployees: state.selectedEmployees.filter(id => id !== action.payload),
        });
      }
      return Object.assign({}, state, { selectedEmployees: [...state.selectedEmployees, action.payload] });
    case SET_BONUS_VALUES:
      return {
        ...state,
        bonusValues: {
          ...state.bonusValues,
          [action.payload.employeeId]: {
            [action.payload.id]: action.payload.value,
          },
        },
      };
    case ADD_ATTENDANCE_BONUS_SUCCESFUL: {
      const newBonusValues = { ...state.bonusValues };
      delete newBonusValues[action.payload.employee_id][action.payload.attendance_id];
      return {
        ...state,
        bonusValues: newBonusValues,
      };
    }
    case SET_PAYROLL_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    default:
      return state;
  }
};
export default payrollUI;
