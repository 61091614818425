import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import { INPUT_MODIFIERS } from '@/constants/modifiers';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import classNames from 'classnames';

class EditBreaks extends Component {
  static mapHoursToBreaks(breaks) {
    return breaks ? breaks.map(b => ({ ...b, hours: EditBreaks.getHours(b) })) : [];
  }

  /* eslint-disable camelcase */
  static getHours(item) {
    let hours = '';
    const { start_timestamp, end_timestamp } = item;
    hours += start_timestamp ? start_timestamp.split(' ')[1].slice(0, 5) : '__:__';
    hours += '-';
    hours += end_timestamp ? end_timestamp.split(' ')[1].slice(0, 5) : '__:__';
    return hours;
  }

  /* eslint-enable */

  constructor(props) {
    super(props);
    this.state = {
      breaks: EditBreaks.mapHoursToBreaks(this.props.breaks),
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { breaks } = this.props;
    if (breaks && breaks.length !== prevProps.breaks.length) {
      this.setState({ breaks: EditBreaks.mapHoursToBreaks(breaks) });
    }
  }

  getChanges() {
    const valid = this.validateHours();
    if (!valid) return { error: true };

    const changes = [];
    const { breaks } = this.props;
    this.state.breaks.forEach(b => {
      const oldBreak = breaks.find(br => br.id === b.id);

      if (!oldBreak) {
        changes.push({ action: 'add', data: b });
      } else if (oldBreak.end_timestamp !== b.end_timestamp || oldBreak.start_timestamp !== b.start_timestamp) {
        changes.push({ action: 'change', data: b });
      }
    });
    breaks.forEach(b => {
      const newBreak = this.state.breaks.find(br => br.id === b.id);
      if (!newBreak) changes.push({ action: 'delete', data: b, attendance: this.props.attendance });
    });
    return changes;
  }

  addBreak() {
    const { id } = this.props.attendance;
    const newBreak = {
      attendance_id: id,
      start_timestamp: null,
      end_timestamp: null,
      comment: '',
    };
    newBreak.hours = EditBreaks.getHours(newBreak);
    this.setState({
      breaks: [...this.state.breaks, newBreak],
    });
  }

  deleteBreak(index) {
    const newBreaks = [...this.state.breaks];
    newBreaks.splice(index, 1);
    this.setState({ breaks: newBreaks });
  }

  changeHours(index, hours) {
    const { date, start_timestamp: startTimestamp } = this.props.attendance;
    let breakStartTimestamp = `${date} ${hours.split('-')[0]}:00`;
    let breakEndTimestamp = `${date} ${hours.split('-')[1]}:00`;
    if (moment(startTimestamp).isAfter(breakStartTimestamp, 'minutes')) {
      breakStartTimestamp = moment(breakStartTimestamp)
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (moment(breakStartTimestamp).isAfter(breakEndTimestamp)) {
      breakEndTimestamp = moment(breakEndTimestamp)
        .add(1, 'day')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const newBreak = {
      ...this.state.breaks[index],
      hours,
      start_timestamp: breakStartTimestamp,
      end_timestamp: breakEndTimestamp,
    };
    this.setState({ breaks: [...this.state.breaks.slice(0, index), newBreak, ...this.state.breaks.slice(index + 1)] });
  }

  validateHours() {
    let errors = 0;
    const newBreaks = this.state.breaks.map(b => {
      const error = b.hours.search('_') !== -1;
      if (error) errors++;
      return { ...b, error };
    });

    this.setState({ breaks: newBreaks });
    return errors === 0;
  }

  render() {
    const breaksClassNames = classNames('k-breaks', this.props.classNames);
    return (
      <div className={breaksClassNames}>
        {this.state.breaks.map((b, i) => (
          <div key={i} className="k-breaks__item">
            <div className="k-breaks__label">
              <FormattedMessage
                id="attendance.breaks.breakNo"
                defaultMessage="Przerwa #{number}"
                values={{ number: i + 1 }}
              />
            </div>
            <div className="k-breaks__item--right">
              <div className="k-breaks__inputGroup">
                <MDTimeRangeInput
                  onChange={hours => this.changeHours(i, hours)}
                  value={b.hours}
                  modifiers={INPUT_MODIFIERS}
                />
                <div className="k-breaks__error">
                  {b.error && (
                    <FormattedMessage
                      id="attendance.breaks.fillHours"
                      defaultMessage="Proszę uzupełnić godziny przerwy"
                    />
                  )}
                </div>
              </div>
              <i
                role="presentation"
                className="material-icons k-breaks__deleteIcon"
                onClick={() => {
                  this.deleteBreak(i);
                }}
              >
                delete_forever
              </i>
            </div>
          </div>
        ))}

        <button className="k-breaks__add" onClick={this.addBreak}>
          <i className="material-icons">add</i>
          <FormattedMessage id="attendance.breaks.addBreak" defaultMessage="Dodaj przerwę" />
        </button>
      </div>
    );
  }
}

EditBreaks.propTypes = {
  breaks: PropTypes.arrayOf(PropTypes.shape({})),
  attendance: PropTypes.shape({
    employee_id: PropTypes.string,
    id: PropTypes.string,
    date: PropTypes.string,
    start_timestamp: PropTypes.string,
  }),
};

export default EditBreaks;
