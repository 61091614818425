import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { KADRO_LIGHT_ORANGE_COLOR } from '@/constants/colors.js';
import { zipWith } from '@/utils/array/array.helpers';

import './AutoGenerateCreateScheduleSummary.scss';

export const getColumnSum = (schedule) =>
  Object.values(schedule).reduce(
    (prev, col) => zipWith(prev, col, (a, b) => (b ? a + parseInt(b) : a)),
    Array.from({ length: 24 }, () => 0),
  );

export const getSumAll = (sumCols) => sumCols.reduce((prev, sumCol) => prev + sumCol, 0);

export const getStyles = (sumCol, maxValue) => {
  const level = sumCol ? parseInt((sumCol / maxValue) * 3) + 1 : 0;

  return level ? { backgroundColor: `${KADRO_LIGHT_ORANGE_COLOR}${level * 10}` } : {};
};

const AutoGenerateCreateScheduleSummary = ({ className, schedule = {} }) => {
  const containerClassnames = classnames('k-autoGenerateCreateScheduleSummary', className);
  const sumCols = getColumnSum(schedule);
  const maxValue = Math.max(...sumCols);
  const sumAll = getSumAll(sumCols);

  return (
    <div className={containerClassnames}>
      <div className="k-autoGenerateCreateScheduleSummary__leftColumn">
        <div className="k-autoGenerateCreateScheduleSummary__sumContainer">
          <FormattedMessage id="autoGenerate.createScheduleSummary.sum" defaultMessage="Suma" />
          <span className="k-autoGenerateCreateScheduleSummary__sum">({sumAll} h)</span>
        </div>
      </div>
      <div className="k-autoGenerateCreateScheduleSummary__rows">
        <table className="k-autoGenerateCreateScheduleSummary__table">
          <tbody>
            <tr>
              {sumCols.map((sumCol, index) => {
                const style = getStyles(sumCol, maxValue);
                return (
                  <td key={index} style={style}>
                    {sumCol === 0 ? '-' : sumCol}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

AutoGenerateCreateScheduleSummary.propTypes = {
  className: PropTypes.string,
  schedule: PropTypes.shape({}),
};

export default AutoGenerateCreateScheduleSummary;
