import { connect } from 'react-redux';

import EmployeeModalLocations from './EmployeeModalLocations.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
});

const EmployeeModalJobsAndLocationsContainer = connect(mapStateToProps)(EmployeeModalLocations);

export default EmployeeModalJobsAndLocationsContainer;
