import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import KadroComposedChart from '@/components/common/charts/KadroComposedChart.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';

import { axisMetadata,metadata } from './DashboardChart.helpers.js';
import { messages } from './DashboardChart.messages.js';

const DashboardChart = ({ data }, { intl }) => {
  const formattedMetadata = metadata.map(i => ({
    ...i,
    label: intl.formatMessage(i.label),
  }));

  let foundItemWithData = false;
  const filteredData = (data || []).reduce((result, item) => {
    foundItemWithData = item.numberOfAttendances > 0 || item.numberOfShifts > 0;

    if (foundItemWithData) {
      return [...result, item];
    }

    return result;
  }, []);
  const hasData = filteredData?.some(i => i.numberOfShifts > 0 || i.numberOfAttendances > 0);

  return (
    <div className="md-dashboardContainer">
      <div className="md-dashboardContainer__title">
        <i className="md-dashboardContainer__icon material-icons">stacked_line_chart</i>
        <FormattedMessage id="dashboard.hourlyStats" defaultMessage="Przegląd dnia" />
      </div>
      <div className="k-reports__chart-wrapper">
        <div className="k-reports__chart">
          {hasData ? (
            <KadroComposedChart
              data={filteredData}
              metadata={formattedMetadata}
              tooltipLabelFormatter={value => `${String(value).padStart(2, '0')}:00`}
              axisMetadata={axisMetadata}
              xTickFormatter={value => `${String(value).padStart(2, '0')}:00`}
            />
          ) : (
            <EmptyState
              name="jobtitleCTA"
              className="k-reports-empty-state"
              imgSrc="/img/jobtitlesCTA.png"
              emptyStateText={intl.formatMessage(messages.noData)}
              shiftX="0"
            />
          )}
        </div>
      </div>
    </div>
  );
};

DashboardChart.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DashboardChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DashboardChart;
