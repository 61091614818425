import { connect } from 'react-redux';

import { State } from '@/types';

import PayrollSummary from './PayrollSummary';

const mapStateToProps = (state: State) => ({
  summary: state.reducer.payroll.summary,
  timeFormatType: state.reducer.payrollSettings.timeFormatSetting.type,
  mode: state.reducer.payroll.mode,
  isFetching: state.reducer.payroll.isFetching,
  summaryValues: state.reducer.payroll.summaryValues,
  restrictions: state.reducer.userPermissions.restrictions,
});

export default connect(mapStateToProps)(PayrollSummary);
