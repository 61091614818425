import { MessageDescriptor } from 'react-intl';

import { freeDaysMarkingNotifications } from '@/redux-store/freeDaysMarking';
import { managerNotesNotifications } from '@/redux-store/managerNotes/managerNotes.utils';

type GlobalNotification = {
  title: MessageDescriptor;
  description?: MessageDescriptor;
};

export type GlobalNotifications = Record<string, GlobalNotification>;

export const globalNotifications: GlobalNotifications = {
  //
  ...freeDaysMarkingNotifications,
  ...managerNotesNotifications,
};
