import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  reduceBreaks: {
    id: 'payrollSettingsModal.reduceBreaks',
    defaultMessage: 'Uwzględnij czas przerw',
  },
  reduceBreaksTooltip: {
    id: 'payrollSettingsModal.reduceBreaksTooltip',
    defaultMessage: 'Uwzględnij czas przerw',
  },
  breaksTolerance: {
    id: 'payrollSettingsModal.breaksTolerance',
    defaultMessage: 'Tolerancja',
  },
  breaksToleranceTooltip: {
    id: 'payrollSettingsModal.breaksToleranceTooltip',
    defaultMessage: 'Tolerancja',
  },
  breaksTimeno: {
    id: 'payrollSettingsModal.breaksTimeno',
    defaultMessage: '-',
  },
  breaksTimeconstant: {
    id: 'payrollSettingsModal.breaksTimeconstant',
    defaultMessage: 'Czas przerwy',
  },
  breaksTimetolerance: {
    id: 'payrollSettingsModal.breaksTimetolerance',
    defaultMessage: 'Tolerancja',
  },
  breaksTimeTooltipconstant: {
    id: 'payrollSettingsModal.breaksTimeTooltipconstant',
    defaultMessage: 'Czas przerwy',
  },
  breaksTimeTooltiptolerance: {
    id: 'payrollSettingsModal.breaksTimeTooltiptolerance',
    defaultMessage: 'Tolerancja',
  },
  breaksTimeTooltipno: {
    id: 'payrollSettingsModal.breaksTimeTooltipno',
    defaultMessage: '-',
  },
});
