import { FormattedMessage } from 'react-intl';

import { EXPORT_MODE } from '@/constants/exportConstants.js';
import { filterOnlyActiveTypes } from '@/utils/exports/exportHelpers';

import { messages } from './ExportScheduleModal.messages';

export const availabilitiesToExport = availabilitiesTypes => {
  const values = availabilitiesTypes.map(type => type.name);

  return {
    type: 'columnList',
    id: 'selectedAvailabilityTypes',
    title: 'Wybierz dostępności',
    values,
    preselected: [],
  };
};

export const absencesToExport = absencesTypes => {
  const values = filterOnlyActiveTypes(absencesTypes).map(type => type.name);
  return {
    type: 'columnList',
    id: 'selectedAbsencesTypes',
    title: 'Wybierz nieobecności',
    values,
    preselected: values.map((_, idx) => idx),
  };
};

export const getExportOptions = setExportMode => ({
  type: 'radio',
  id: 'exportType',
  text: 'Rodzaj exportu',
  values: [
    {
      id: EXPORT_MODE.SCHEDULE.ABSENCES,
      label: <FormattedMessage {...messages.absences} />,
    },
    {
      id: EXPORT_MODE.SCHEDULE.AVAILABILITIES,
      label: <FormattedMessage {...messages.availabilities} />,
    },
  ],
  onChange: newValue => setExportMode(newValue),
});

export const getListProp = (exportMode, types) => {
  const { absencesTypes, availabilitiesTypes } = types;
  switch (exportMode) {
    case EXPORT_MODE.SCHEDULE.ABSENCES:
      return absencesToExport(absencesTypes);

    case EXPORT_MODE.SCHEDULE.AVAILABILITIES:
      return availabilitiesToExport(availabilitiesTypes);

    default:
      return null;
  }
};
