import DetectRTC from 'detectrtc';
import { useEffect } from 'react';

import { getUserMedia, updateWindowSize } from '@/actions/deviceInfo';
import { offlineMessageOff, offlineMessageOn } from '@/actions/uiState';
import store from '@/redux-store/store';

const { dispatch } = store;

export const useAppListeners = () => {
  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout | null = null;

    const handleResize = () => {
      if (!resizeTimeout)
        resizeTimeout = setTimeout(() => {
          resizeTimeout = null;
          dispatch(updateWindowSize());
        }, 200);
    };

    const handleOffline = () => {
      dispatch(offlineMessageOn());
    };

    const handleOnline = () => {
      dispatch(offlineMessageOff());
    };

    DetectRTC.load(() => {
      dispatch(
        getUserMedia({
          hasCamera: DetectRTC.hasWebcam,
          hasMicrophone: DetectRTC.hasMicrophone,
          hasSpeakers: DetectRTC.hasSpeakers,
        }),
      );
    });

    window.addEventListener('resize', handleResize);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, []);
};
