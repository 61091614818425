import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import EmptyState from '@/components/emptyState/EmptyState';
import { GREY_300 } from '@/constants/colors';
import { CHART_MODIFIER } from '@/constants/modifiers';
import {
  CHART_MODES,
  LEGEND_WRAPPER_STYLE,
  MAX_ELEMENT_IN_CHART_LEGEND,
  MINUTES,
  STROKE_DASHARRAY,
  TICK_STYLE,
  TOOLTIP_CONTENT_STYLE,
} from '@/constants/reports';
import { ContextWithIntl } from '@/types/intl.types';
import { createFormattersForSelectedChartType, prepareRowsAndMetadataForChart } from '@/utils/reports';

import ChartHeader from '../../chartHeader/ChartHeader';
import { messages as chartsMessages } from '../charts.messages';
import { getLocationKey } from './locationsChart.helpers';
import { messages } from './locationsChart.messages';
import { useLocationsChart } from './useLocationsChart';

const LocationsChart = (_, { intl }: ContextWithIntl) => {
  const [chartMode, setChartMode] = useState(MINUTES);

  const { data, userLocations } = useLocationsChart();

  const { rows, metadata } = useMemo(
    () =>
      prepareRowsAndMetadataForChart(
        data,
        userLocations,
        chartMode,
        MAX_ELEMENT_IN_CHART_LEGEND,
        {
          otherData: intl.formatMessage(messages.otherLocations),
        },
        getLocationKey,
      ),
    [data, userLocations, chartMode, intl],
  );

  const { valueFormatter, yTickFormatter } = useMemo(
    () => createFormattersForSelectedChartType(chartMode),
    [chartMode],
  );
  const hasDataToShow = rows.length > 0 && metadata.length > 0;

  return (
    <div className="k-locationsChart">
      <ChartHeader
        title={intl.formatMessage(messages.chartTitle)}
        chartMode={chartMode}
        onChange={setChartMode}
        options={CHART_MODES}
        tooltipMessage={intl.formatMessage(messages.tooltipMessage)}
      />
      {hasDataToShow ? (
        <ResponsiveContainer width="100%" height="85.62%">
          <BarChart data={rows}>
            <CartesianGrid strokeDasharray={STROKE_DASHARRAY} />
            <XAxis dataKey="date" stroke={GREY_300} tick={TICK_STYLE} />
            <YAxis tickFormatter={yTickFormatter} allowDecimals={false} />
            <Tooltip
              wrapperStyle={{ color: GREY_300 }}
              contentStyle={TOOLTIP_CONTENT_STYLE}
              formatter={valueFormatter}
            />
            <Legend wrapperStyle={LEGEND_WRAPPER_STYLE} />
            {metadata.map(({ key, color, label }) => (
              <Bar key={key} dataKey={key} stackId="A" fill={color} name={label} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyState
          name="scheduleCTA"
          imgSrc="/img/scheduleCTA.png"
          emptyStateHeader={intl.formatMessage(chartsMessages.emptyStateHeader)}
          emptyStateText={intl.formatMessage(chartsMessages.emptyStateText)}
          modifiers={[CHART_MODIFIER]}
        />
      )}
    </div>
  );
};

LocationsChart.contextTypes = {
  intl: PropTypes.shape({}),
};

export default LocationsChart;
