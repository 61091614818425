import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';

const ScheduleBudgetTableEstimatesTotalCell = ({ summaryEstimatedBudget }) => (
  <td className="k-budgetTable__leftColumn">
    <div className="k-budgetTable__leftColumnData">
      <FormattedMessage id="schedule.budget.estimatedBudgetPlan" defaultMessage="Sprzedaż plan" />
      <span className="k-budgetTable__leftColumnDataValue">
        {roundToTwoSigDigits(summaryEstimatedBudget / 100)} PLN
      </span>
    </div>
  </td>
);

ScheduleBudgetTableEstimatesTotalCell.propTypes = {
  summaryEstimatedBudget: PropTypes.number,
};

export default ScheduleBudgetTableEstimatesTotalCell;
