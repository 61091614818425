import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absenceHoursSingleDay: {
    id: 'absenceHours.singleDay',
    defaultMessage: 'dzień',
  },
  absenceHoursMultiDay: {
    id: 'absenceHours.multiDay',
    defaultMessage: 'dni',
  },
  absenceHoursFrom: {
    id: 'absenceHours.from',
    defaultMessage: 'od',
  },
  absenceHoursTo: {
    id: 'absenceHours.to',
    defaultMessage: 'do',
  },
  omitWeekendsAndHolidays: {
    id: 'absenceModal.omitWeekendsAndHolidays',
    defaultMessage: 'pominięto weekendy oraz dni wolne od pracy',
  },
  omitWeekends: {
    id: 'absenceModal.omitWeekends',
    defaultMessage: 'pominięto weekendy',
  },
  omitHolidays: {
    id: 'absenceModal.omitHolidays',
    defaultMessage: 'pominięto dni wolne od pracy',
  },
  countedDaysWithShifts: {
    id: 'absenceModal.countedDaysWithShifts',
    defaultMessage: 'nieobecność liczona na podstawie zmian w grafiku pracy',
  },
  scheduleBlocked: {
    id: 'error.absenceModal.scheduleBlocked',
    defaultMessage:
      'Nie można usunąć wniosku, ponieważ w  {locations,plural, one{ lokalizacji {locationName}} other {# lokalizacjach}} edycja grafiku została zablokowana w okresie nachodzącym na ten urlop. Aby usunąć wniosek trzeba najpierw zmienić {locations,plural, one {datę blokady} other {daty blokad}}.',
  },
});
