import { connect } from 'react-redux';

import { prepareAddLocation, prepareChangeLocation, toggleModal } from '@/actions/companymanage';

import AddLocationModal from './AddLocationModal.jsx';

const mapStateToProps = state => ({
  visible: state.reducer.listsUi.locations.showModal,
  editedLocationId: state.reducer.listsUi.locations.editedId,
  userLocations: state.reducer.userLocations,
  userEmployees: state.reducer.userEmployees,
  isUserManager: state.reducer.currentUser.user.role === 'manager',
  currentUserId: state.reducer.currentUser.user.id,
  deviceLocation: state.reducer.deviceInfo.geoLocation,
});

const mapDispatchToProps = {
  toggleModal,
  prepareAddLocation,
  prepareChangeLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationModal);
