import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import {
  deleteAllNotifications,
  deleteNotification,
  markAllNotificationAsRead,
  markNotificationAsRead,
  notificationGoToContext,
  notificationToggleReadStatus,
  pullNotifications,
} from '@/actions/notifications.js';
import NotificationView from '@/components/notifications/NotificationView.jsx';

const mapStateToProps = state => ({
  userNotifications: state.reducer.userNotifications,
});

const mapDispatchToProps = {
  pullNotifications,
  markNotificationAsRead,
  markAllNotificationAsRead,
  notificationToggleReadStatus,
  deleteNotification,
  deleteAllNotifications,
  notificationGoToContext,
  showConfirmModal,
};

const NotificationViewContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationView);

export default NotificationViewContainer;
