import { FormattedMessage } from 'react-intl';

import { OPTIONS_COLUMN_WIDTH } from '@/constants/tables';

import OptionsColumnCell from './OptionsColumnCell';

export const getOptionsColumn = options => ({
  id: 'options',
  Header: () => <FormattedMessage id="table.header.options" defaultMessage="Opcje" />,
  Cell: cellProps => <OptionsColumnCell options={options} {...cellProps} />,
  width: OPTIONS_COLUMN_WIDTH,
  Filter: () => <div className="mdTable__cell" />,
  sticky: 'right',
});
