import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState.jsx';

import './ExportEmptyState.scss';

const ExportEmptyState = () => (
  <div className="k-exportEmptyState">
    <EmptyState
      name="payrollCTA"
      imgSrc="/img/payrollCTA.png"
      emptyStateHeader={
        <FormattedMessage id="exportEmptyState.emptyStateHeader" defaultMessage="Brak wyników do wyświetlenia" />
      }
      ctaText=""
    />
  </div>
);

export default memo(ExportEmptyState);
