import PropTypes from 'prop-types';

import MDTableCheckbox from '../MDTableCheckbox';

const CheckboxColumnCell = props => {
  const { row, rows, customOnChange, toggleRowSelected } = props;
  const toggleRowSelectedProps = row.getToggleRowSelectedProps();

  const onChange = () => {
    customOnChange(row, rows, toggleRowSelected);
  };

  return (
    <MDTableCheckbox
      {...toggleRowSelectedProps}
      disabled={row.original.disabledCheckbox}
      onChange={customOnChange ? onChange : toggleRowSelectedProps.onChange}
    />
  );
};

CheckboxColumnCell.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
    original: {
      disabledCheckbox: PropTypes.bool,
    },
  }),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  customOnChange: PropTypes.func,
  toggleRowSelected: PropTypes.func,
};

export default CheckboxColumnCell;
