import { connect } from 'react-redux';

import { changeSorting,toggleAllCheckboxes, toggleCheckbox } from '@/actions/companymanage';
import { showModal } from '@/actions/uiState.js';

import DevicesTable from './DevicesTable.jsx';

const mapStateToProps = state => ({
  devices: state.reducer.devices,
  listsUi: state.reducer.listsUi,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  toggleAllCheckboxes,
  toggleCheckbox,
  showModal,
  changeSorting,
};

const DevicesTableContainer = connect(mapStateToProps, mapDispatchToProps)(DevicesTable);

export default DevicesTableContainer;
