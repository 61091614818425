import { filterAttendances, filterShifts, getRelevantAttendancesDetails } from '../../payrollHelpers';
import { filterAbsences, filterEmployees, getAbsencesDataByEmployee,getDataByEmployeeAndDate } from './helpers.js';

export const getData = (
  {
    userEmployees,
    multipleLocationFilter,
    selectedJobtitles,
    employmentConditionsFilter,
    absences,
    mainDateStore,
    payrollSettings,
  },
  selectedEmployees,
) => {
  const selectedLocationsIds = multipleLocationFilter;
  const selectedJobTitlesIds = selectedJobtitles.map(j => j.id);
  const employeesToRender = filterEmployees(
    userEmployees,
    selectedLocationsIds,
    selectedJobTitlesIds,
    employmentConditionsFilter,
  );

  return employeesToRender.reduce((result, employee) => {
    const employeeIsRelevant = selectedEmployees.length ? selectedEmployees.includes(employee.id) : true;
    if (!employeeIsRelevant) return result;

    const relevantShifts = filterShifts(employee.shifts, mainDateStore, multipleLocationFilter, selectedJobtitles);
    const relevantAtts = filterAttendances(
      employee.attendances,
      mainDateStore,
      multipleLocationFilter,
      selectedJobTitlesIds,
    ).sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
    const relevantAttendanceDetails = getRelevantAttendancesDetails(relevantAtts, employee, payrollSettings);

    const relevantAbsences = absences[employee.id] ? filterAbsences(absences[employee.id], mainDateStore) : [];

    return {
      ...result,
      [employee.id]: getDataByEmployeeAndDate(
        relevantAttendanceDetails,
        relevantShifts,
        relevantAbsences,
        mainDateStore,
        employee,
      ),
    };
  }, {});
};

export const getAbsenceData = (
  { userEmployees, multipleLocationFilter, selectedJobtitles, employmentConditionsFilter, absences, mainDateStore },
  absenceTypes,
  selectedEmployees,
) => {
  const selectedLocationsIds = multipleLocationFilter;
  const selectedJobTitlesIds = selectedJobtitles.map(j => j.id);
  const employeesToRender = filterEmployees(
    userEmployees,
    selectedLocationsIds,
    selectedJobTitlesIds,
    employmentConditionsFilter,
  );
  const filterBySelectedEmployees = selectedEmployees.length;

  return employeesToRender.reduce((result, employee) => {
    const relevantAbsences = absences[employee.id] ? filterAbsences(absences[employee.id], mainDateStore) : [];

    if (relevantAbsences.length === 0) {
      return result;
    }

    if (filterBySelectedEmployees) {
      const employeeIsRelevant = selectedEmployees.includes(employee.id);
      if (!employeeIsRelevant) return result;
    }

    return {
      ...result,
      [employee.id]: getAbsencesDataByEmployee(relevantAbsences, mainDateStore, employee, absenceTypes),
    };
  }, {});
};
