import PropTypes from 'prop-types';

import Button from '../../Basic/Button.jsx';

const MDFileDropFileView = props => {
  const { fileName, clearFile, confirmFile, confirmText, hideButton } = props;
  return (
    <>
      <div className="mdFileDrop__text mdFileDrop__text--fileDropped">
        <span>{fileName}</span>
        <i role="presentation" className="material-icons mdFileDropText__icon" onClick={clearFile}>
          clear
        </i>
      </div>

      {!hideButton && (
        <Button onClick={confirmFile} className="mdFileDrop__button" modifiers="reverse-orange small floatright">
          <i className="material-icons">reply</i>
          {confirmText}
        </Button>
      )}
    </>
  );
};

MDFileDropFileView.propTypes = {
  fileName: PropTypes.string,
  confirmText: PropTypes.string,
  confirmFile: PropTypes.func,
  clearFile: PropTypes.func,
  hideButton: PropTypes.bool,
};

export default MDFileDropFileView;
