/**
 * Prime use is to filter out options for OptionsCell component to which user shall not see.
 * @param {Array} options - Array of options. Each option is an object which contains at least
 * option, style and function. It might contain restriction/permission
 * @param {Object} userPermissions - Object containing user permissions and restrictions
 *
 * @return {Object} extracted data of options after applyimg restricion/permission filter
 */
export const applyPermissionsForOptions = (options, userPermissions) => {
  const extractedData = {
    options: [],
    styles: [],
    functions: [],
    disabledArray: [],
  };

  options
    .filter(option => {
      if (option.restriction && userPermissions.restrictions.includes(option.restriction)) return false;
      if (option.permission && !userPermissions.permissions.includes(option.permission)) return false;
      return true;
    })
    .forEach(option => {
      extractedData.options.push(option.option);
      extractedData.styles.push(option.style);
      extractedData.functions.push(option.function);
      extractedData.disabledArray.push(option.isDisabled || false);
    });

  return extractedData;
};
