import { FormattedMessage } from 'react-intl';

import { getEmployeeById } from '@/utils/userEmployeesHelpers';

import { timestampsToWorkingHours } from './dateHelper.js';

export const getEditorName = (item, employees) => {
  const isAttendanceClose = item.action === 'close' && item.type === 'attendance' && Boolean(item.object_user_id);
  const itemEditorId = isAttendanceClose ? item.object_user_id : item.user_id;
  const editorName = employees[itemEditorId];
  return `${editorName?.first_name} ${editorName?.last_name}`;
};

export const getIcon = item => {
  switch (item.action) {
    case 'create':
      return 'add';
    case 'edit':
      return 'edit';
    case 'publish':
      return 'check';
    case 'open':
      return 'lock_open';
    case 'close':
      return 'close';
    case 'swap':
      return 'swap_horiz';
    default:
      return '';
  }
};

export const getMessage = item => {
  let objectName = '';
  if (item.type === 'shift') {
    objectName = <FormattedMessage id="objectHistory.objectName.shift" defaultMessage="zmianę" />;
  }
  if (item.type === 'attendance') {
    objectName = <FormattedMessage id="objectHistory.objectName.attendance" defaultMessage="obecność" />;
  }
  switch (item.action) {
    case 'create':
      return (
        <div>
          <FormattedMessage id="objectHistory.create" defaultMessage="Dodał nową" /> {objectName}
        </div>
      );
    case 'open':
      return (
        <div>
          <FormattedMessage id="objectHistory.open" defaultMessage="Pracownik rozpoczął" /> {objectName}
        </div>
      );
    case 'close':
      return (
        <div>
          <FormattedMessage id="objectHistory.close" defaultMessage="Pracownik zakończył" /> {objectName}
        </div>
      );
    case 'edit':
      return (
        <div>
          <FormattedMessage id="objectHistory.edit" defaultMessage="Edytował" /> {objectName}
        </div>
      );
    case 'publish':
      return (
        <div>
          <FormattedMessage id="objectHistory.publish" defaultMessage="Opublikował" /> {objectName}
        </div>
      );
    case 'swap':
      return (
        <div>
          <FormattedMessage id="objectHistory.swap" defaultMessage="Wymienił" /> {objectName}
        </div>
      );
    default:
      return <FormattedMessage id="objectHistory.unknownAction" defaultMessage="Nieznana operacja" />;
  }
};

export const getChanges = (item, currentItem, userJobTitles, userEmployees) => {
  const changes = [];
  let tempItem = { ...currentItem };
  for (const key in item.old_values) {
    if (Object.prototype.hasOwnProperty.call(item.old_values, key)) {
      switch (key) {
        case 'user_id':
          if (!item.old_values.user_id) {
            console.error('History object error: missing user_id');
          }
          tempItem = {
            ...tempItem,
            oldEmployee: getEmployeeById(userEmployees, item.old_values.user_id),
            newEmployee: getEmployeeById(userEmployees, currentItem.user_id),
          };
          changes.push(
            <FormattedMessage
              key={key}
              id="objectHistory.employeeChange"
              defaultMessage="Zmiana została wymieniona z pracownika {before} na {after}"
              values={{
                before: (
                  <span className="objectHistoryModal__value">
                    {tempItem.oldEmployee.first_name} {tempItem.oldEmployee.last_name}
                  </span>
                ),
                after: (
                  <span className="objectHistoryModal__value">
                    {tempItem.newEmployee.first_name} {tempItem.newEmployee.last_name}
                  </span>
                ),
              }}
            />,
          );
          break;
        case 'start_date':
          if (!item.old_values.end_date) {
            console.error('History object error: missing end_date');
          }
          changes.push(
            <FormattedMessage
              key={key}
              id="objectHistory.workingHoursChange"
              defaultMessage="Godzina zmieniła się z {before} na {after}"
              values={{
                before: (
                  <span className="objectHistoryModal__value">
                    {timestampsToWorkingHours(item.old_values.start_date, item.old_values.end_date)}
                  </span>
                ),
                after: (
                  <span className="objectHistoryModal__value">
                    {timestampsToWorkingHours(currentItem.start_timestamp, currentItem.end_timestamp)}
                  </span>
                ),
              }}
            />,
          );
          tempItem = {
            ...tempItem,
            start_timestamp: item.old_values.start_date,
            end_timestamp: item.old_values.end_date,
          };
          break;
        case 'date':
          if (!item.old_values.date) {
            console.error('History object error: missing date');
          }
          changes.push(
            <FormattedMessage
              key={key}
              id="objectHistory.dateChange"
              defaultMessage="Data zmieniła się z {before} na {after}"
              values={{
                before: <span className="objectHistoryModal__value">{item.old_values.date}</span>,
                after: <span className="objectHistoryModal__value">{currentItem.date}</span>,
              }}
            />,
          );
          break;
        case 'start_timestamp':
          changes.push(
            <FormattedMessage
              key={key}
              id="objectHistory.workingHoursChange"
              defaultMessage="Godzina zmieniła się z {before} na {after}"
              values={{
                before: (
                  <span className="k-objectHistory__value--before">
                    {timestampsToWorkingHours(item.old_values.start_timestamp, item.old_values.end_timestamp)}
                  </span>
                ),
                after: (
                  <span className="k-objectHistory__value--after">
                    {timestampsToWorkingHours(currentItem.start_timestamp, currentItem.end_timestamp)}
                  </span>
                ),
              }}
            />,
          );
          tempItem = {
            ...tempItem,
            start_timestamp: item.old_values.start_timestamp,
            end_timestamp: item.old_values.end_timestamp,
          };
          break;
        case 'job_title_id': {
          const oldJobTitle = userJobTitles.find(jobTitle => parseInt(jobTitle.id) === item.old_values[key]);
          if (!oldJobTitle) break;
          changes.push(
            <FormattedMessage
              key={key}
              id="objectHistory.jobTitleChange"
              defaultMessage="Stanowisko {before} -> {after}"
              values={{
                before: <span className="k-objectHistory__value--before">{oldJobTitle.title}</span>,
                after: <span className="k-objectHistory__value--after">{currentItem.job_title.title}</span>,
              }}
            />,
          );
          tempItem = {
            ...tempItem,
            job_title: oldJobTitle,
          };
          break;
        }
        default:
          break;
      }
    }
  }
  return [tempItem, changes];
};
