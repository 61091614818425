import { useToggle } from '@/hooks';
import { useAppSelector } from '@/redux-store';
import { selectLoanedEmployees } from '@/redux-store/scheduleState/locations';
import { LocationId } from '@/types';

export const useGroupNode = (locationId: LocationId) => {
  const loanedEmployeesDict = useAppSelector(selectLoanedEmployees(locationId));
  const [isOpen, toggle] = useToggle(true);

  return { isOpen, loanedEmployeesDict, toggle };
};
