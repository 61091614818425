import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import AttendancePhoto from './AttendancesPhoto/AttendancePhoto';
import PhotoEmpty from './PhotoEmpty/PhotoEmpty';
import { messages } from './photoPreviewModal.messages';

import './photoPreviewModal.scss';

const PhotoPreviewModal = ({ showModal, hideModal, modalObject, availablePhotos }, { intl }) => {
  const photos = availablePhotos[modalObject?.attendanceId] || null;
  if (!photos) return null;
  const { photoPreviewModalIn, photoPreviewModalOut } = messages;

  const { clockIn, clockOut, clockInUrl, clockOutUrl } = photos;

  const wrapperClassName = classnames('k-photoPreviewModal__wrapper');

  return (
    <MDKadroModal
      show={showModal}
      title={intl.formatMessage(messages.photoPreviewModalTitle)}
      hideFooter
      onHide={hideModal}
      className="k-photoPreviewModal"
    >
      <div className={wrapperClassName}>
        {clockIn ? (
          <AttendancePhoto
            attendanceStatus={clockIn}
            attendanceStatusUrl={clockInUrl}
            photoDescription={photoPreviewModalIn}
          />
        ) : (
          <PhotoEmpty {...{ clockIn, clockOut, clockInUrl, clockOutUrl }} />
        )}
        {clockOut ? (
          <AttendancePhoto
            attendanceStatus={clockOut}
            attendanceStatusUrl={clockOutUrl}
            photoDescription={photoPreviewModalOut}
          />
        ) : (
          <PhotoEmpty {...{ clockIn, clockOut, clockInUrl, clockOutUrl }} />
        )}
      </div>
    </MDKadroModal>
  );
};

export default PhotoPreviewModal;

PhotoPreviewModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PhotoPreviewModal.propTypes = {
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  availablePhotos: PropTypes.shape({
    clockIn: PropTypes.bool,
    clockOut: PropTypes.bool,
    clockInUrl: PropTypes.string,
    clockOutUrl: PropTypes.string,
  }),
  modalObject: PropTypes.shape({
    attendanceId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    locationId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  }),
};
