import PropTypes from 'prop-types';
import { useState } from 'react';
import { defineMessages } from 'react-intl';
import Select from 'react-select';

import KadroModal from '@/components/common/KadroModal.jsx';
import { CHOOSE_LOCALIZATION_MODAL } from '@/constants/modalTypes.js';

const messages = defineMessages({
  modalTitle: {
    id: 'chooseLocationModal.modalTitle',
    defaultMessage: 'Wybierz lokalizację',
  },
  modalConfirm: {
    id: 'chooseLocationModal.modalConfirm',
    defaultMessage: 'Start',
  },
  selectPlaceholder: {
    id: 'chooseLocationModal.selectPlaceholder',
    defaultMessage: 'Wybierz lokalizację',
  },
  selectNoOptions: {
    id: 'chooseLocationModal.selectNoOptions',
    defaultMessage: 'Brak lokalizacji przypisanej do uzytkownika',
  },
});

const ChooseLocationModal = (props, { intl }) => {
  const { show, hideModal, locations, onSubmit } = props;

  const [values, setValues] = useState({
    location: null,
  });
  const [extraStyle, setExtraStyle] = useState('');

  const selectOptions = locations.map(loc => ({
    label: loc.name,
    value: loc.id,
  }));

  const handleChange = selected => {
    setValues({ ...values, location: selected });
  };

  const handleFocus = () => {
    setExtraStyle('selected');
  };
  const handleBlur = () => {
    setExtraStyle('');
  };

  const handleSubmit = () => {
    hideModal(CHOOSE_LOCALIZATION_MODAL);
    onSubmit(values.location.value);
  };

  return (
    <KadroModal
      showModal={show}
      onHide={hideModal}
      title={intl.formatMessage(messages.modalTitle)}
      onSubmit={handleSubmit}
      confirmText={intl.formatMessage(messages.modalConfirm)}
    >
      <div style={{ minHeight: '300px' }}>
        <Select
          value={values.location}
          onChange={handleChange}
          className={`kadro-select ${extraStyle}`}
          onFocus={handleFocus}
          onBlur={handleBlur}
          options={selectOptions}
          placeholder={intl.formatMessage(messages.selectPlaceholder)}
          noResultsText={intl.formatMessage(messages.selectNoOptions)}
          clearable={false}
        />
      </div>
    </KadroModal>
  );
};

ChooseLocationModal.propTypes = {
  locations: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

ChooseLocationModal.contextTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default ChooseLocationModal;
