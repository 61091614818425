import PropTypes from 'prop-types';

import { messages } from './DashboardTeamHeader.messages';

import './DashboardTeamHeader.scss';

const columns = ['entry', 'status'];

const DashboardTeamHeader = (props, context) => {
  const { sortColumn, sortDecreasing } = props.teamSorting;
  return (
    <div className="md-dashboardTeamItem md-dashboardTeamHeader__tableHeader">
      <div />
      {columns.map(columnName => (
        <div key={columnName} onClick={() => props.setSortingColumn('team', columnName)}>
          <span>{context.intl.formatMessage(messages[columnName])}</span>
          {sortColumn === columnName &&
            (sortDecreasing ? (
              <i className="material-icons md-dashboardTeamHeader__icon">arrow_drop_down</i>
            ) : (
              <i className="material-icons md-dashboardTeamHeader__icon">arrow_drop_up</i>
            ))}
        </div>
      ))}
    </div>
  );
};

DashboardTeamHeader.contextTypes = {
  intl: PropTypes.shape({}),
};

DashboardTeamHeader.propTypes = {
  teamSorting: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDecreasing: PropTypes.bool,
  }),
};

export default DashboardTeamHeader;
