import { useMemo,useState } from 'react';

const useStepsCounter = (initialStepsCount, initialCurrentStep = 1, options = { minStepValue: 1 }) => {
  const [currentStep, setCurrentStep] = useState(initialCurrentStep);
  const [stepsCount, setStepsCount] = useState(initialStepsCount);

  const handlers = useMemo(
    () => ({
      increaseStep: () => {
        if (currentStep < stepsCount) setCurrentStep(prev => prev + 1);
      },
      decreaseStep: () => {
        if (currentStep > options.minStepValue) setCurrentStep(prev => prev - 1);
      },
      setStepsCount,
      setInitialValues: () => {
        setCurrentStep(initialCurrentStep);
        setStepsCount(initialStepsCount);
      },
    }),
    [currentStep, stepsCount],
  );

  return { currentStep, stepsCount, isLastStep: currentStep === stepsCount, handlers };
};

export default useStepsCounter;
