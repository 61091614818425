import { FormattedMessage } from 'react-intl';

export const MAX_JOB_TITLE_LENGTH = 12;

export const getLoanEmployeesProposalBlockTitle = (jobTitle: string, employeesRemaining: number) => {
  let jobTitleName = jobTitle;
  const jobTitleLength = jobTitle.length;
  if (jobTitleLength > MAX_JOB_TITLE_LENGTH) jobTitleName = `${jobTitle.slice(0, MAX_JOB_TITLE_LENGTH)}...`;

  return (
    <FormattedMessage
      id="scheduler.loanEmployeesProposal.proposalsRequired"
      defaultMessage="{jobTitle} ({employeesRemaining})"
      values={{
        jobTitle: jobTitleName,
        employeesRemaining,
      }}
    />
  );
};
