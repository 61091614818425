import PropTypes from 'prop-types';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import {
  calculateTotalDurationInMinutesForShifts,
  calculateTotalWageFromShifts,
  getScheduleSettingsValue,
} from '@/utils/schedulerHelpers.js';

const SchedulePositionTableRowTitle = props => {
  // We disable selection for PositionView for now
  // const selected = props.scheduleUIState.selectedRows.indexOf(props.rowData.id) >= 0;
  // const selected = false;
  const relevantRowData = {
    ...props.rowData,
    employees: props.rowData.employees.map(employee => ({
      ...employee,
      shifts: employee.shifts.filter(shift => props.dateStore.dateArray.includes(shift.date)),
    })),
  };

  const sumHours = relevantRowData.employees.reduce(
    (prev, employee) => prev + calculateTotalDurationInMinutesForShifts(employee.shifts),
    0,
  );

  const sumCosts = relevantRowData.employees.reduce(
    (prev, employee) =>
      prev + calculateTotalWageFromShifts(employee.shifts, props.contracts[employee.id] || [], props.userJobTitles),
    0,
  );

  const style = {};
  // if (props.canPaste && selected) {
  //   style.backgroundColor = SCHEDULE_PASTE_BACKGROUND;
  // } else if (selected) {
  //   style.backgroundColor = SCHEDULE_SELECTED_BACKGROUND;
  // }

  const details = (
    <div className="emp_det">
      {parseMinutesToHumanForm(sumHours)}, {roundToTwoSigDigits(sumCosts)}
      PLN
    </div>
  );

  const scheduleSettingBudget = getScheduleSettingsValue(props.scheduleUIState.settings, 'budget');

  return (
    <tr id="row1" className="h-sorce">
      <td className="col_name" style={style}>
        <div className="col_name_wrap">
          <div className="col_fullname">
            {props.rowData.title}
            {scheduleSettingBudget ? details : null}
          </div>
        </div>
      </td>
    </tr>
  );
};

SchedulePositionTableRowTitle.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    color: PropTypes.string,
    company_id: PropTypes.string,
    employees: PropTypes.arrayOf(PropTypes.shape({})),
    hourly_wage: PropTypes.number,
    title: PropTypes.string,
  }),
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  scheduleUIState: PropTypes.shape({
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SchedulePositionTableRowTitle;
