import classnames from 'classnames';

import './tag.scss';

interface TagProps {
  color: string;
  name: string;
  deleteTag?: () => void;
  className?: string;
  disabled?: boolean;
}

const Tag = ({ color, name, deleteTag, className, disabled }: TagProps) => {
  const tagClassName = classnames('k-tag', className, { 'k-tag--disabled': disabled });
  const buttonClassName = classnames('k-tag__closeButton', { 'k-tag__closeButton--disabled': disabled });
  return (
    <div className={tagClassName} style={{ backgroundColor: color }}>
      <span className="k-tag__name">{name}</span>
      {deleteTag && !disabled && (
        <button className={buttonClassName} onClick={deleteTag}>
          <span className="material-symbols-outlined k-tag__closeIcon">close</span>
        </button>
      )}
    </div>
  );
};

export default Tag;
