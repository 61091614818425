import moment from 'moment';

import {
  emplMassEditActions,
  emplMassEditTypes,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { Contract, Contracts } from '@/types/contracts.types.ts';
import { EmployeeWhole } from '@/types/employees.types.ts';

import { messages } from './employeesEditMassModal.messages.ts';

export const addLocations = (employees, locations) =>
  employees.map((empl) => {
    const newLocations = [...empl.locations, ...locations].reduce(
      (acc, cur) => (acc.some((loc) => loc.id === cur.id) ? acc : [...acc, cur]),
      [],
    );
    const newSupplementaryLocations = empl.supplementary_locations_ids.filter((id) =>
      newLocations.some((loc) => String(loc.id) === String(id)),
    );

    return { ...empl, locations: newLocations, supplementary_locations_ids: newSupplementaryLocations };
  });

export const addSupplementaryLocations = (employees, supplementaryLocations, options) =>
  employees.map((employee) => {
    const relevantSupplementaryLocations = options.overridePrimaryLocations
      ? supplementaryLocations
      : supplementaryLocations.filter((location) => {
          const employeeLocationIds = employee.locations.map((l) => l.id);
          const employeeSupplementaryLocationIds = employee.supplementary_locations_ids;
          const locationId = location.id;

          return !employeeLocationIds.includes(locationId) || employeeSupplementaryLocationIds.includes(locationId);
        });

    const newLocations = [...employee.locations, ...relevantSupplementaryLocations].reduce(
      (acc, cur) => (acc.some((loc) => loc.id === cur.id) ? acc : [...acc, cur]),
      [],
    );

    const newSupplementary = [
      ...employee.supplementary_locations_ids,
      ...relevantSupplementaryLocations.map((loc) => loc.id),
    ];

    return { ...employee, locations: newLocations, supplementary_locations_ids: newSupplementary };
  });

export const deleteLocations = (employees, locations) =>
  employees.map((empl) => {
    const newLocations = empl.locations.filter((empLoc) => !locations.some((loc) => loc.id === empLoc.id));
    return { ...empl, locations: newLocations };
  });

export const editEmploymentConditions = (employees, employmentConditions) => {
  const newConditions = {
    ...employmentConditions,
    template_id: employmentConditions.id,
    schedule_cycle: {
      month: employmentConditions.schedule_cycle?.month || moment().month() + 1,
      year: employmentConditions.schedule_cycle?.year || moment().year(),
      duration: employmentConditions.schedule_cycle?.duration || 1,
    },
  };
  return employees.map((empl) => ({ ...empl, employment_conditions: newConditions }));
};

const filterEmployeeWithoutUOZ = (employee) => employee?.employment_conditions?.template_id !== 'uoz';

export const editWeeklyWorkingMinutes = (employees, weeklyWorkingMinutes) =>
  employees.filter(filterEmployeeWithoutUOZ).map((empl) => ({
    ...empl,
    employment_conditions: { ...empl.employment_conditions, weekly_working_minutes: weeklyWorkingMinutes },
  }));

export const editScheduleCycleStart = (employees, startDate) =>
  employees.filter(filterEmployeeWithoutUOZ).map((empl) => ({
    ...empl,
    employment_conditions: {
      ...empl.employment_conditions,
      schedule_cycle: {
        ...empl.employment_conditions.schedule_cycle,
        month: startDate.month,
        year: startDate.year,
      },
    },
  }));

export const editScheduleCycleDuration = (employees, duration) =>
  employees.filter(filterEmployeeWithoutUOZ).map((empl) => ({
    ...empl,
    employment_conditions: {
      ...empl.employment_conditions,
      schedule_cycle: {
        ...empl.employment_conditions.schedule_cycle,
        duration,
      },
    },
  }));

export const editEmployeeRole = (employees, { role, role_id: roleId }) =>
  employees.map((empl) => ({
    ...empl,
    role,
    role_id: roleId,
  }));

export const editElasticWorkDay = (employees, isElastic) =>
  employees.filter(filterEmployeeWithoutUOZ).map((empl) => ({
    ...empl,
    employment_conditions: {
      ...empl.employment_conditions,
      elastic_work_day: isElastic,
    },
  }));

export const editAbsenceLimits = (employees, limits) =>
  employees.map((empl) => ({
    ...empl,
    limits,
  }));

export const getSelectedEmployees = (selectedEmployeeIds, employees, selectedAction) => {
  if (selectedAction !== emplMassEditActions.addNewSupplementary) {
    return selectedEmployeeIds.map((id) => employees[id]);
  }
  return selectedEmployeeIds
    .filter((id) => !['manager', 'owner'].includes(employees[id].role))
    .map((id) => employees[id]);
};

const getAdditionalOptionsForSupplementaryLocations = (selectedEmployees, currentFieldValues) => {
  const locationIds = currentFieldValues.map((location) => location.id);
  const haveLocationsThatCanBeOverwritten = selectedEmployees.some((employee) => {
    const employeeLocationIds = employee.locations.map((location) => location.id);
    const employeeSupplementaryLocationIds = employee.supplementary_locations_ids;

    return locationIds.some(
      (locationId) =>
        employeeLocationIds.includes(locationId) && !employeeSupplementaryLocationIds.includes(locationId),
    );
  });

  if (haveLocationsThatCanBeOverwritten) {
    return [
      {
        id: 'overridePrimaryLocations',
        label: messages.overridePrimaryLocations,
      },
    ];
  }

  return [];
};

export const getAvailableAdditionalOptions = (selectedType, selectedAction, selectedEmployees, currentFieldValues) => {
  const isAddingSupplementaryLocations =
    selectedType === emplMassEditTypes.location && selectedAction === emplMassEditActions.addNewSupplementary;

  if (isAddingSupplementaryLocations) {
    return getAdditionalOptionsForSupplementaryLocations(selectedEmployees, currentFieldValues);
  }

  return [];
};

export const getInitialStepsCount = (selectedType, selectedAction) => {
  if (selectedType === emplMassEditTypes.contracts && selectedAction === emplMassEditActions.remove) return 3;
  if (selectedType === emplMassEditTypes.contracts) return 2;
  return 1;
};

export const getConfirmText = (selectedType, selectedAction, isLastStep) => {
  if (selectedType === emplMassEditTypes.contracts && selectedAction === emplMassEditActions.remove && isLastStep)
    return messages.approve;
  if (isLastStep) return messages.save;
  return messages.next;
};

export const getContractsForSelectedEmployeeIds = (
  selectedEmployeesIds: EmployeeWhole['id'][],
  contracts: Contracts,
): Contracts => selectedEmployeesIds.reduce((acc, id) => (contracts[id] ? { ...acc, [id]: contracts[id] } : acc), {});

export const checkIfAllSelectedEmployeesHaveOnlyOneContract = (selectedEmployeesIds, contracts) => {
  const selectedEmployeesContracts = getContractsForSelectedEmployeeIds(selectedEmployeesIds, contracts);
  return Object.values(selectedEmployeesContracts).every((contractArray) => contractArray.length === 1);
};

export const isDisabledNextButton = (
  selectedAction: string,
  currentFieldValue: {
    date: string;
    assignJobTitlesAndWageForContract: boolean;
    jobTitles: { id: string }[];
    incorrectEmployees: { label: string; value: string }[];
    correctEmployees: { label: string; value: string }[];
    newStartContractDate: string;
  },
  currentStep: number,
  contracts: { [employeeId: string]: Contract[] },
  selectedEmployeeIds: string[],
) => {
  switch (selectedAction) {
    case emplMassEditActions.addContract:
    case emplMassEditActions.remove:
      return !currentFieldValue?.date || (currentStep === 2 && !currentFieldValue.correctEmployees?.length);
    case emplMassEditActions.addContractsJobTitles:
    case emplMassEditActions.removeContractsJobTitles:
      return (
        !currentFieldValue?.date ||
        !currentFieldValue?.jobTitles?.length ||
        (currentStep === 2 && !currentFieldValue.correctEmployees?.length)
      );
    case emplMassEditActions.editContractsStartDate:
      return (
        !currentFieldValue?.date ||
        !currentFieldValue?.newStartContractDate ||
        (currentStep === 1 && checkIfAllSelectedEmployeesHaveOnlyOneContract(selectedEmployeeIds, contracts)) ||
        (currentStep === 2 && !currentFieldValue.correctEmployees?.length)
      );

    default:
      return false;
  }
};
