import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'quickPlanning.warningModal.title',
    defaultMessage: 'Ostrzeżenie przed wklejeniem zmian',
  },
  shiftsAfterDateSelection: {
    id: 'quickPlanning.warningModal.shiftsAfterDateSelection',
    defaultMessage:
      'Zaznacz tą opcję, jeżeli zezwalasz na dodanie zmian w okresie, który nie jest widoczny w obecnym widoku',
  },
  shiftsForNotExistingEmployeeId: {
    id: 'quickPlanning.warningModal.shiftsForNotExistingEmployeeId',
    defaultMessage: `Wybrany przez Ciebie obszar do wklejenia zmian wykracza poza zakres pracowników.
       Oznacza to, że nie wszystkie skopiowane zmiany zostaną wklejone. 
       Jeżeli chcesz zmienić obszar do wklejanie zmian kliknij anuluj.`,
  },
  freeDaysAfterDateSelection: {
    id: 'quickPlanning.warningModal.freeDaysAfterDateSelection',
    defaultMessage:
      'Zaznacz tą opcję, jeżeli zezwalasz na dodanie oznaczeń dni wolnych w okresie, który nie jest widoczny w obecnym widoku',
  },
  freeDaysForNotExistingEmployeeId: {
    id: 'quickPlanning.warningModal.freeDaysForNotExistingEmployeeId',
    defaultMessage: `Wybrany przez Ciebie obszar do wklejenia oznaczeń dni wolnych wykracza poza zakres pracowników.
       Oznacza to, że nie wszystkie skopiowane oznaczenia dni wolnych zostaną wklejone. 
       Jeżeli chcesz zmienić obszar do wklejanie oznaczeń dni wolnych kliknij anuluj.`,
  },
});
