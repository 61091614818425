import { generateDateArray } from 'kadro-helpers/lib/helpers';

import { arrayIntersection } from '../../baseHelpers.js';

export const headers = [
  'Identyfikator pracownika',
  'Imię',
  'Nazwisko',
  'Data',
  'Godziny nominalne',
  'Godziny pracy fakt',
  'Godz pracy',
  'Godz nocne',
];

export const absenceHeaders = ['Kod', 'Imię', 'Nazwisko', 'Data od', 'Data do', 'Rodzaj'];

export const getDataByEmployeeAndDate = (attendances, shifts, absences, dateStore, employee) => {
  const dateArray = generateDateArray(dateStore.customDate.start, dateStore.customDate.end);

  return dateArray.reduce((result, date) => {
    const relevantAbsence = absences.find(
      absence => date >= absence.from && date <= absence.to && !absence.omitted_dates.includes(date),
    );
    const timePlanned = shifts.reduce((sum, shift) => {
      if (shift.date === date) {
        return sum + shift.duration;
      }

      return sum;
    }, 0);

    const { timeWorked, timeWorkedReal, nightTimeWorked } = attendances.reduce(
      (res, attendance) => {
        if (attendance.date !== date || !attendance.end_timestamp) {
          return res;
        }
        const isNotAcceptedAndHasNoShift = !attendance.matching_shift && !attendance.early_in && !attendance.late_out;
        const timeWorkedRealToAdd = attendance.matching_shift ? attendance.timeWorkedReal : 0;
        const timeWorkedToAdd = isNotAcceptedAndHasNoShift ? 0 : attendance.timeWorked;
        const nightTimeWorkedToAdd = isNotAcceptedAndHasNoShift ? 0 : attendance.nightTimeWorked;

        return relevantAbsence
          ? res
          : {
              timeWorked: res.timeWorked + timeWorkedToAdd,
              timeWorkedReal: res.timeWorkedReal + timeWorkedRealToAdd,
              nightTimeWorked: res.nightTimeWorked + nightTimeWorkedToAdd,
            };
      },
      {
        timeWorked: 0,
        timeWorkedReal: 0,
        nightTimeWorked: 0,
      },
    );

    return {
      ...result,
      [date]: {
        referenceId: employee.reference_id,
        firstName: employee.first_name,
        lastName: employee.last_name,
        timeWorked,
        timePlanned,
        timeWorkedReal,
        nightTimeWorked,
      },
    };
  }, {});
};

export const getAbsencesDataByEmployee = (absences, dateStore, employee, absenceTypes) => {
  const dateArray = generateDateArray(dateStore.customDate.start, dateStore.customDate.end);
  const assignedAbsenceIds = [];

  return dateArray.reduce((result, date) => {
    const relevantAbsence = absences.find(
      absence => date >= absence.from && date <= absence.to && !absence.omitted_dates.includes(date),
    );
    if (!relevantAbsence || assignedAbsenceIds.includes(relevantAbsence.id)) {
      return result;
    }

    const absenceType = absenceTypes.find(type => type.id === relevantAbsence.type_id);

    if (!absenceType) {
      return result;
    }

    assignedAbsenceIds.push(relevantAbsence.id);

    return [
      ...result,
      {
        referenceId: employee.reference_id,
        firstName: employee.first_name,
        lastName: employee.last_name,
        from: relevantAbsence.from,
        to: relevantAbsence.to,
        absenceCode: absenceType.code,
      },
    ];
  }, []);
};

export const getAbsenceBody = data => [
  absenceHeaders,
  ...Object.values(data).flatMap(employeeAbsences =>
    employeeAbsences.map(absence => [
      absence.referenceId,
      absence.firstName.toUpperCase(),
      absence.lastName.toUpperCase(),
      absence.from,
      absence.to,
      absence.absenceCode,
    ]),
  ),
];

export const filterEmployees = (
  userEmployees,
  selectedLocationsIds,
  selectedJobTitlesIds,
  employmentConditionsFilter,
) =>
  userEmployees.filter(employee => {
    const employeeLocationsIds = employee.locations.map(l => l.id);
    const employeeAttendancesLocationsIds = employee.attendances
      .filter(a => selectedLocationsIds.includes(a.location.id))
      .map(attendance => attendance.location.id);
    return (
      (arrayIntersection(employeeLocationsIds, selectedLocationsIds) ||
        arrayIntersection(employeeAttendancesLocationsIds, selectedLocationsIds)) &&
      employee.terms.some(t => selectedJobTitlesIds.includes(t.job_title.id)) &&
      employmentConditionsFilter.some(condition => condition.id === employee.employment_conditions.template_id)
    );
  });

export const getBody = data => [
  headers,
  ...Object.values(data).flatMap(dateRecord =>
    Object.entries(dateRecord).map(([date, values]) => [
      values.referenceId,
      values.firstName.toUpperCase(),
      values.lastName.toUpperCase(),
      date,
      values.timePlanned,
      values.timeWorked,
      values.timeWorked,
      values.nightTimeWorked,
    ]),
  ),
];

export const filterAbsences = (employeeAbsences, mainDateStore) =>
  employeeAbsences.filter(
    absence =>
      absence.status === 'accepted' &&
      absence.from <= mainDateStore.customDate.end &&
      absence.to >= mainDateStore.customDate.start,
  );
