import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  payout: {
    id: 'payrollSettingsModal.payout',
    defaultMessage: 'Rozliczenie',
  },
  payoutTooltip: {
    id: 'payrollSettingsModal.payoutTooltip',
    defaultMessage: 'Rozliczenie',
  },
  rounding: {
    id: 'payrollSettings.workingHoursRounding',
    defaultMessage: 'Zaokrąglenie czasu pracy',
  },
  roundingTooltip: {
    id: 'payrollSettings.workingHoursRoundingTooltip',
    defaultMessage: 'Zaokrąglenie czasu pracy',
  },
  timeFormatTooltip: {
    id: 'payrollSettings.timeFormatTooltip',
    defaultMessage: 'Format czasu',
  },
  timeFormat: {
    id: 'payrollSettings.timeFormat',
    defaultMessage: 'Format czasu',
  },
  nightHoursTime: {
    id: 'payrollSettings.nighthoursTime',
    defaultMessage: 'Godziny nocne w przedziale',
  },
  nightHoursTimeTooltip: {
    id: 'payrollSettings.nighthoursTimeTooltip',
    defaultMessage: 'Godziny nocne w przedziale',
  },
  nightHoursError: {
    id: 'payrollSettings.nighthoursError',
    defaultMessage: 'Prawidłowy czas pracy w zmianach nocnych to minimum 8 godzin między 21:00 a 7:00',
  },
});
