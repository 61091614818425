import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { MDTextInput } from '@/components/common/inputs/MDComponents';
import { createEvent } from '@/utils/inputHelpers.js';

import { roundingPrecisionTargetOptions } from '../../options/BudgetOptions/BudgetOptions.helpers';
import MultipleSettingsOptions from '../MultipleSettingsOptions/MultipleSettingsOptions';
import { validateBonusTarget } from './BonusTargetOptions.helpers';
import BonusTargetOptionsHeader from './BonusTargetOptionsHeader/BonusTargetOptionsHeader';

import './BonusTargetOptions.scss';

const BonusTargetOptions = (props, { intl }) => {
  const { moreItemsText, rowName, rowsData = [], slug, handleInput } = props;

  const addRow = useCallback(() => {
    const newId = Math.max(...rowsData.map(({ id }) => id)) + 1;
    const newBonusTargetOptions = [...rowsData, { bonusPercentage: null, spmhThreshold: null, id: newId }];
    handleInput(createEvent(slug, newBonusTargetOptions));
  }, [rowsData, slug]);

  const deleteRow = useCallback(
    id => {
      const newBonusTargetOptions = rowsData.filter(row => row.id !== id);
      handleInput(createEvent(slug, newBonusTargetOptions));
    },
    [rowsData, slug],
  );

  const changeBonusTargetOptions = (e, id) => {
    const { name, value } = e.target;
    const newWorkingHours = rowsData.map(row => (row.id !== id ? row : { ...row, [name]: value }));
    handleInput(createEvent(slug, newWorkingHours));
  };

  const roundAndValidate = (e, id) => {
    const { name, value } = e.target;
    const newWorkingHours = rowsData.map(row =>
      row.id !== id
        ? row
        : {
            ...row,
            errors: { ...row.errors, [name]: validateBonusTarget(name, value, intl) },
            [name]: value ? Number(value).toFixed(roundingPrecisionTargetOptions[name]) : '',
          },
    );
    handleInput(createEvent(slug, newWorkingHours));
  };

  const renderRowOptions = rowData => (
    <div className="bonusTargetOptions__row">
      <MDTextInput
        id="spmhThreshold"
        value={rowData.spmhThreshold}
        onChange={e => changeBonusTargetOptions(e, rowData.id)}
        errorMessage={rowData.errors?.spmhThreshold}
        type="number"
        onBlur={e => roundAndValidate(e, rowData.id)}
      />
      <MDTextInput
        id="bonusPercentage"
        value={rowData.bonusPercentage}
        onChange={e => changeBonusTargetOptions(e, rowData.id)}
        errorMessage={rowData.errors?.bonusPercentage}
        type="number"
        onBlur={e => roundAndValidate(e, rowData.id)}
        minValue={0.1}
        maxValue={100}
      />
    </div>
  );

  return (
    <MultipleSettingsOptions
      renderHeaderRow={() => <BonusTargetOptionsHeader />}
      renderRowOptions={renderRowOptions}
      moreItemsText={moreItemsText}
      rowsData={rowsData}
      rowName={rowName}
      addRow={addRow}
      deleteRow={deleteRow}
    />
  );
};

BonusTargetOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

BonusTargetOptions.propTypes = {
  rowsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      bonusPercentage: PropTypes.string,
      spmhThreshold: PropTypes.string,
    }),
  ),
  slug: PropTypes.string,
  handleInput: PropTypes.func,
};

export default BonusTargetOptions;
