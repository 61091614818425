import { COPY_CONTENT, SET_CAN_COPY,SET_CAN_PASTE } from '@/constants/ActionTypes.js';

// context:"schedule" or sth else like "employeeList" etc.
// selection = object containing any data is needed for
// given copy to work.

const initialState = {
  context: '',
  selection: null,
  canPaste: false,
  canCopy: false,
};

const copyPaste = (state = initialState, action) => {
  switch (action.type) {
    case COPY_CONTENT:
      return Object.assign({}, state, {
        context: action.context,
        selection: action.payload,
      });
    case SET_CAN_PASTE:
      return Object.assign({}, state, { canPaste: action.payload });
    case SET_CAN_COPY:
      return { ...state, canCopy: action.payload };
    default:
      return state;
  }
};
export default copyPaste;
