import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  date: {
    id: 'payroll.table.date',
    defaultMessage: 'Data',
  },
  from: {
    id: 'payroll.table.from',
    defaultMessage: 'Od',
  },
  to: {
    id: 'payroll.table.to',
    defaultMessage: 'Do',
  },
  workHours: {
    id: 'payroll.table.workHours',
    defaultMessage: 'Godziny pracy',
  },
  breaks: {
    id: 'payroll.table.breaks',
    defaultMessage: 'Przerwy',
  },
  absence: {
    id: 'payroll.table.absence',
    defaultMessage: 'Nieobecność',
  },
  workTime: {
    id: 'payroll.table.workTime',
    defaultMessage: 'Suma godzin',
  },
  jobTitleName: {
    id: 'payroll.table.jobTitleName',
    defaultMessage: 'Stanowisko',
  },
  locationName: {
    id: 'payroll.table.locationName',
    defaultMessage: 'Lokalizacja',
  },
  nightWorkTime: {
    id: 'payroll.table.nightWorkTime',
    defaultMessage: 'Godziny nocne',
  },
  overtime50: {
    id: 'payroll.table.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'payroll.table.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  potentialOvertime: {
    id: 'payrollLocation.table.potentialOvertime',
    defaultMessage: 'Nadgodziny PŚT',
  },
  fillUp: {
    id: 'payrollLocation.table.fillUp',
    defaultMessage: 'Dopełnienia',
  },
  overtime_50_100: {
    id: 'payroll.table.overtime_50_100',
    defaultMessage: 'Nadgodziny 50% / 100%',
  },
  overtimeWeekly: {
    id: 'payroll.table.overtimeWeekly',
    defaultMessage: 'Nadgodziny ŚT',
  },
  plan: {
    id: 'payroll.table.plan',
    defaultMessage: 'Plan',
  },
  difference: {
    id: 'payroll.table.difference',
    defaultMessage: 'Różnica',
  },
  bonusAmount: {
    id: 'payroll.table.bonusAmount',
    defaultMessage: 'Bonus',
  },
  wage: {
    id: 'payroll.table.wage',
    defaultMessage: 'Stawka',
  },
  payout: {
    id: 'payroll.table.payout',
    defaultMessage: 'Do wypłaty',
  },
  warnings: {
    id: 'payroll.table.warnings',
    defaultMessage: 'Alerty',
  },
  status: {
    id: 'payroll.table.status',
    defaultMessage: 'Status',
  },
  labels: {
    id: 'payroll.table.labels',
    defaultMessage: 'Etykiety',
  },
  freeDayMarkingName: {
    id: 'payroll.table.freeDayMarkingName',
    defaultMessage: 'Typ dnia',
  },
});

export const filterMessages = defineMessages({
  nightWorkTime: {
    id: 'payroll.filters.nightWorkTime',
    defaultMessage: 'Godziny nocne',
  },
  overtime50: {
    id: 'payroll.filters.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'payroll.filters.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  potentialOvertime: {
    id: 'payrollLocation.table.potentialOvertime',
    defaultMessage: 'Nadgodziny PŚT',
  },
  fillUp: {
    id: 'payrollLocation.table.fillUp',
    defaultMessage: 'Dopełnienia',
  },
  overtime_50_100: {
    id: 'payroll.filters.overtime_50_100',
    defaultMessage: 'Nadgodziny 50% / 100%',
  },
  overtimeWeekly: {
    id: 'payroll.filters.overtimeWeekly',
    defaultMessage: 'Nadgodziny ŚT',
  },
  workHours: {
    id: 'payroll.filters.workHours',
    defaultMessage: 'Godziny pracy',
  },
  workTime: {
    id: 'payroll.filters.workTime',
    defaultMessage: 'Suma godzin',
  },
});
