import { connect } from 'react-redux';

import { onOpenShiftRowClick } from '@/actions/schedule/selectedCells';
import { toggleOpenShiftsRowStickiness } from '@/actions/schedule/stickyRows';

import Component from './OpenShiftRowTitle.jsx';

const mapStateToProps = state => ({
  rowSticky: !!state.reducer.schedule.stickyRows.openShiftsSticky,
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  onOpenShiftRowClick,
  toggleOpenShiftsRowStickiness,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
