import classnames from 'classnames';
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';

import { BUDGET_ESTIMATES_VIEW, BUDGET_METRICS_ENABLE } from '@/constants/Permissions.js';

import ScheduleBudgetTableRows from '../ScheduleBudgetTableRows/ScheduleBudgetTableRows.redux.js';
import ScheduleBudgetTableOptions from './ScheduleBudgetTableOptions.jsx';

import './ScheduleBudgetTable.scss';

const ScheduleBudgetTable = props => {
  const {
    selectedDisplayModeType,
    dateArray,
    permissions,
    restrictions,
    tableClass,
    selectedOptions,
    toggleBudgetOption,
  } = props;
  const isTemplatesView = selectedDisplayModeType === 'templates';
  const tableClassname = classnames('kadroGrid--budget k-budgetTable', {
    'k-budgetTable--month': dateArray.length > 7,
  });

  const showRecommendedSchedule =
    ![RESTRICTIONS.BUDGET_INFO_HIDE, RESTRICTIONS.RECOMMENDED_SCHEDULE_HIDE].some(restriction =>
      restrictions.includes(restriction),
    ) && !isTemplatesView;
  const showBudgetEstimateView = permissions.includes(BUDGET_ESTIMATES_VIEW) && !isTemplatesView;
  const showBudgetMetrics = permissions.includes(BUDGET_METRICS_ENABLE) && showRecommendedSchedule;

  return (
    <div className={tableClassname} style={{ position: 'sticky' }}>
      <table>
        <ScheduleBudgetTableOptions
          showRecommendedSchedule={showRecommendedSchedule}
          showBudgetEstimateView={showBudgetEstimateView}
          selectedOptions={selectedOptions}
          toggleOption={toggleBudgetOption}
          showBudgetMetrics={showBudgetMetrics}
        />

        <tbody className={tableClass}>
          <ScheduleBudgetTableRows
            showRecommendedSchedule={showRecommendedSchedule}
            selectedOptions={selectedOptions}
          />
        </tbody>
      </table>
    </div>
  );
};

ScheduleBudgetTable.propTypes = {
  tableClass: PropTypes.string,
  dateArray: PropTypes.arrayOf(PropTypes.string),
  selectedDisplayModeType: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  toggleBudgetOption: PropTypes.func,
};

export default ScheduleBudgetTable;
