import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import EventsAndNotesItem from '@/components/dashboard/EventsAndNotesItem.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import ReportBox from '@/components/reports/ReportBox.jsx';
import TopBar from '@/components/TopBars/TopBar.jsx';
import TopBarDateModeContainer from '@/containers/TopBars/filters/TopBarDateModeContainer.js';
import { generateAttendancesDataForEmployee } from '@/utils/attendanceHelpers';
import { calculateDurationBetweenTimestamps } from '@/utils/dateHelper.js';

import DashboardTimeTracker from './DashboardTimeTracker.redux.js';
import HelpModal from './EmployeeDashboardHelpModal.jsx';
import { EmployeePresenceChart } from './EmployeePresenceChart.jsx';

const messages = defineMessages({
  statisticsAttendances: {
    id: 'attendances.statistics.attendances',
    defaultMessage: 'Obecności',
  },
  statisticsAttendancesDescription: {
    id: 'attendances.statistics.attendancesDescription',
    defaultMessage: 'obecności w tym {spanPhrase}',
  },
  statisticsLateAtWork: {
    id: 'attendances.statistics.lateAtWrok',
    defaultMessage: 'Spóźnienia',
  },
  statisticsLateAtWorkDescription: {
    id: 'attendances.statistics.lateAtWrokDescription',
    defaultMessage: 'spóźnień w tym {spanPhrase}',
  },
  statisticsAbsences: {
    id: 'attendances.statistics.absences',
    defaultMessage: 'Nieobecności',
  },
  statisticsAbsencesDescription: {
    id: 'attendances.statistics.absencesDescription',
    defaultMessage: 'Nieobecności w tym {spanPhrase}',
  },
  registered: {
    id: 'dashboard.registeredTime',
    defaultMessage: 'Zarejestrowano',
  },
  registeredDescription: {
    id: 'dashboard.registeredTimeDescription',
    defaultMessage: 'godzin w tym {spanPhrase}',
  },
});

const EmployeeDashboard = (props, context) => {
  const now = moment();
  const nowFormated = now.format('YYYY-MM-DD');
  const spans = defineMessages({
    month: {
      id: 'dashboard.time.inMonth',
      defaultMessage: 'miesiącu',
    },
    week: {
      id: 'dashboard.time.inWeek',
      defaultMessage: 'tygodniu',
    },
  });
  const spanPhrase = context.intl.formatMessage(props.mainDateStore.dateMode === 'month' ? spans.month : spans.week);
  const employee = props.userEmployees.find((emp) => emp.id === props.currentUser.user.id);
  const attendances = props.attendances[props.currentUser.user.id] || [];
  let attendancesNumber = 0;
  let lateNumber = 0;
  let absentNumber = 0;
  let workedHours = 0;
  let nextShift = null;
  let location = null;
  if (employee) {
    attendancesNumber = attendances.length;
    for (const attendance of attendances) {
      workedHours += calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp) / 60;
    }
    const allShifts = [];
    employee.shifts.concat(employee.shifts_for_other_locations).forEach((shift) => {
      const isDuplicate = allShifts.some((item) => item.id === shift.id);
      const isAllowAttendaceOpenClose = props.locationSettings[shift.location.id]?.allow_employee_attendace_open_close;

      if (!isDuplicate && isAllowAttendaceOpenClose) {
        allShifts.push(shift);
      }
    });

    let attendanceData = [];
    props.mainDateStore.dateArray
      .filter((date) => moment(date).isSameOrBefore(moment(), 'day'))
      .forEach((date) => {
        attendanceData = attendanceData.concat(
          generateAttendancesDataForEmployee(
            employee,
            date,
            allShifts.filter((s) => s.date === date),
            attendances.filter((a) => a.date === date),
          ),
        );
      });
    attendanceData.forEach((data) => {
      data.blocks.forEach((block) => {
        switch (block.type.name) {
          case 'attedance_late_start':
            lateNumber++;
            break;
          case 'absence':
            absentNumber++;
            break;
          default:
            break;
        }
      });
    });
    nextShift = allShifts
      .filter((shift) => {
        if (!shift) return false;
        if (shift.date > nowFormated) {
          return true;
        }
        if (shift.date === nowFormated) {
          if (moment(shift.end_timestamp).diff(now, 'minutes') >= 0) {
            return true;
          }
        }
        return false;
      })
      .sort((a, b) => {
        if (a.date > b.date || a.working_hours > b.working_hours) {
          return 1;
        }
        return -1;
      })[0];
    if (nextShift) {
      location = props.userLocations.find((loc) => loc.id === nextShift.location.id);
    }
  }
  const relevantEvents = props.userEvents
    .filter((event) => event.date >= nowFormated)
    .slice(0, 2)
    .sort((a, b) => a.date > b.date);

  const eventsAndNotesClassNames = classnames('ibox-content', {
    'ibox-content--empty': relevantEvents.length,
    employeeNotes: !relevantEvents.length,
  });
  const relevantLocations = (employee.locations || []).filter(
    (loc) => props.locationSettings[loc.id]?.allow_employee_attendace_open_close === true,
  );

  return (
    <div className="employeeDashboard">
      <TopBar
        className="employeeDashboard__topbar"
        leftElements={[<TopBarDateModeContainer key="dateMode" modes={[0, 1]} />]}
        rightElements={[
          <div key="DashboardDate" className="dashboard__date title_right">
            {moment().format('dddd, DD MMMM YYYY')}
          </div>,
        ]}
      />
      <HelpModal />
      <div className="employeeDashboard__content">
        <div className="employeeDashboard__summary">
          <ReportBox
            boxTitle={context.intl.formatMessage(messages.statisticsAttendances)}
            boxValue={attendancesNumber}
            boxDescription={context.intl.formatMessage(messages.statisticsAttendancesDescription, { spanPhrase })}
          />
          <ReportBox
            boxTitle={context.intl.formatMessage(messages.statisticsLateAtWork)}
            boxValue={lateNumber}
            boxDescription={context.intl.formatMessage(messages.statisticsLateAtWorkDescription, { spanPhrase })}
          />
          <ReportBox
            boxTitle={context.intl.formatMessage(messages.statisticsAbsences)}
            boxValue={absentNumber}
            boxDescription={context.intl.formatMessage(messages.statisticsAbsencesDescription, { spanPhrase })}
          />
          <ReportBox
            boxTitle={context.intl.formatMessage(messages.registered)}
            boxValue={parseInt(workedHours)}
            boxDescription={context.intl.formatMessage(messages.registeredDescription, { spanPhrase })}
          />
        </div>

        <div style={{ width: '100%', height: '20px', float: 'left' }} />
        <div className="employeeDashboard__chartWrapper col-md-6">
          <EmployeePresenceChart
            attendances={attendances || []}
            shifts={employee ? employee.shifts : []}
            mainDateStore={props.mainDateStore}
          />
        </div>

        {relevantLocations.length > 0 && (
          <div className="ibox col-md-6">
            <DashboardTimeTracker
              nextShift={nextShift}
              employee={employee}
              spanPhrase={spanPhrase}
              location={location}
              relevantLocations={relevantLocations}
            />
          </div>
        )}

        <div className="ibox">
          <div className="ibox-title" style={{ textAlign: 'center' }}>
            <strong>
              <FormattedMessage id="dashboard.eventsAndNotes" defaultMessage="Wydarzenia i notatki" />
            </strong>
          </div>
          <div
            className={eventsAndNotesClassNames}
            style={{ float: 'left', minHeight: '120px', padding: '15px 0 0 0', width: '100%' }}
          >
            {relevantEvents.length ? (
              relevantEvents.map((event) => (
                <EventsAndNotesItem
                  key={event.id}
                  event={{ ...event, hours: `${moment(event.date).format('D MMMM')} ${event.hours}` }}
                />
              ))
            ) : (
              <EmptyState
                name="attendanceCTA"
                className="dashboard"
                imgSrc="/img/attendanceViewCTA.png"
                emptyStateText={
                  <FormattedMessage
                    id="dashboard.eventsAndNotesTable.noEventsAndNotes"
                    defaultMessage="Brak wydarzeń i notatek"
                  />
                }
                ctaText=""
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
EmployeeDashboard.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeDashboard.propTypes = {
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  locationSettings: PropTypes.shape({
    enable_rest_break: PropTypes.bool,
    allow_employee_attendace_open_close: PropTypes.bool,
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userEvents: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeDashboard;
