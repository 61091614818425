// TODO: Create unified data sorting and put it there
export const columnSorting = (key, order, lastNameFirst) => (a, b) => {
  switch (key) {
    case 'name': {
      let aStr, bStr;
      if (lastNameFirst) {
        aStr = String(a.employee.last_name + a.employee.first_name);
        bStr = String(b.employee.last_name + b.employee.first_name);
      } else {
        aStr = String(a.employee.first_name + a.employee.last_name);
        bStr = String(b.employee.first_name + b.employee.last_name);
      }
      if (aStr === '') return order;
      if (bStr === '') return order * -1;
      return aStr.localeCompare(bStr) * order;
    }
    case 'jobTitle': {
      const aSortedDetail = a.relevantRowInfo.details.sort(
        (c, d) => c.jobTitle && d.jobTitle && c.jobTitle.localeCompare(d.jobTitle),
      )[0];
      const bSortedDetail = b.relevantRowInfo.details.sort(
        (c, d) => c.jobTitle && d.jobTitle && c.jobTitle.localeCompare(d.jobTitle),
      )[0];
      if (!aSortedDetail || !aSortedDetail.jobTitle) return order;
      if (!bSortedDetail || !bSortedDetail.jobTitle) return order * -1;
      return aSortedDetail.jobTitle.localeCompare(bSortedDetail.jobTitle) * order;
    }
    case 'wage': {
      if (
        Math.min(...a.relevantRowInfo.details.map(d => (typeof d.wage === 'string' ? -1 : d.wage))) <
        Math.min(...b.relevantRowInfo.details.map(d => (typeof d.wage === 'string' ? -1 : d.wage)))
      )
        return -1 * order;
      if (
        Math.min(...a.relevantRowInfo.details.map(d => (typeof d.wage === 'string' ? -1 : d.wage))) >
        Math.min(...b.relevantRowInfo.details.map(d => (typeof d.wage === 'string' ? -1 : d.wage)))
      )
        return 1 * order;
      return 0;
    }
    case 'plannedDiff': {
      const aValue = a.relevantRowInfo.sumHoursReal - a.relevantRowInfo.sumHoursPlanned;
      const bValue = b.relevantRowInfo.sumHoursReal - b.relevantRowInfo.sumHoursPlanned;
      if (aValue < bValue) return -1 * order;
      if (aValue > bValue) return 1 * order;
      return 0;
    }
    case 'sumHours':
    case 'sumBonuses':
    case 'sumPayout':
    case 'sumNightHours': {
      if (a.relevantRowInfo[key] < b.relevantRowInfo[key]) return -1 * order;
      if (a.relevantRowInfo[key] > b.relevantRowInfo[key]) return 1 * order;
      return 0;
    }
    default:
      return 1;
  }
};
