import { showConfirmModal } from '@/actions/index';
import { deleteOpenShift, massDeleteOpenShiftsByIds } from '@/actions/openShifts';
import messages from '@/actions/schedule/openShifts.messages';
import { getFromToFromDateStore } from '@/utils/dateHelper';

export const deleteOpenShiftsInSelectedLocationsBetweenDates = () => (dispatch, getState, intl) => {
  const { openShifts, jobtitleFilter, mainDateStore, schedule } = getState().reducer;
  const { openShiftsRowSelect } = schedule;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const locationIdsWithSelectedOpenShiftsRow = Object.keys(openShiftsRowSelect).filter(
    locationId => openShiftsRowSelect[locationId],
  );
  if (!locationIdsWithSelectedOpenShiftsRow.length) return;
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
  const shiftsToDelete = openShifts.filter(
    s =>
      s.date <= to &&
      s.date >= from &&
      locationIdsWithSelectedOpenShiftsRow.includes(s.location.id) &&
      selectedJobTitlesIds.includes(s.job_title.id),
  );
  const shiftsToDeleteIds = shiftsToDelete.map(shift => shift.id);

  if (shiftsToDelete.length) {
    dispatch(
      showConfirmModal({
        title: intl.formatMessage(messages.deleteOpenShiftsFromSelectedCellsTitle),
        description: intl.formatMessage(messages.deleteOpenShiftsFromSelectedCellsMessage, {
          shiftNo: shiftsToDelete.length,
        }),
        confirmText: intl.formatMessage(messages.deleteOpenShiftsFromSelectedCellsConfirm),
        confirmFunc: async () => {
          await dispatch(massDeleteOpenShiftsByIds(shiftsToDeleteIds));
        },
        confirmType: 'warning',
      }),
    );
  }
};

export const showDeleteOpenShiftConfirmModal = openShiftId => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteOpenShiftTitle),
      description: intl.formatMessage(messages.deleteOpenShiftDescription),
      confirmText: intl.formatMessage(messages.delete),
      confirmFunc: () => {
        dispatch(deleteOpenShift(openShiftId));
      },
    }),
  );
};
