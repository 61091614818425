import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import PublishButton from '@/components/common/PublishButton.jsx';

const PayrollLocationLoadDataButton = props => {
  const {
    getPayrollLocationViewData,
    isLoadPayrollLocationButtonEnabled,
    multipleLocationFilter,
    selectedEmploymentConditions,
    selectedJobtitles,
  } = props;

  const isButtonEnabled =
    isLoadPayrollLocationButtonEnabled &&
    multipleLocationFilter.length > 0 &&
    selectedEmploymentConditions.length > 0 &&
    selectedJobtitles.length > 0;

  const onClick = useCallback(() => {
    getPayrollLocationViewData();
  }, [getPayrollLocationViewData]);

  return (
    <PublishButton
      text={<FormattedMessage id="loadData" defaultMessage="Wczytaj" />}
      onClickFunc={onClick}
      key="getData"
      disabled={!isButtonEnabled}
      icon="update"
    />
  );
};

PayrollLocationLoadDataButton.propTypes = {
  getPayrollLocationViewData: PropTypes.func,
  isLoadPayrollLocationButtonEnabled: PropTypes.bool,
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PayrollLocationLoadDataButton;
