/* eslint react/no-did-update-set-state:0 */
/* eslint class-methods-use-this:0 */

import { PropTypes } from 'prop-types';
import { Children, cloneElement, Component } from 'react';

import Header from './Header.jsx';
import LeftColumn from './LeftColumn.jsx';
import LeftHeader from './LeftHeader.jsx';
import Rows from './Rows.jsx';
import Scroll from './Scroll.jsx';

class Container extends Component {
  constructor(props) {
    super(props);
    // 54 is the height of row in our tables
    this.avgHeight = this.props.rowHeight || 54;
    // renderLen - bazowa ilość elementów aby wypełnić widok w LL
    this.renderLen = Math.ceil(this.props.height / this.avgHeight);
    // mapping children to specyfic roles
    const { rows, leftColumn, leftHeader, header, children } = this.mapChildrenToRoles(props);
    this.rows = rows;
    this.leftColumn = leftColumn;
    this.leftHeader = leftHeader;
    this.header = header;
    this.children = children;
    if (!this.leftHeader || !this.leftColumn) {
      this.leftHeader = null;
      this.leftColumn = null;
    }
    let bottomFill = 0;
    let end;
    if (!this.props.static) {
      bottomFill = (this.rows.props.children.length - this.renderLen) * this.avgHeight;
      end = this.renderLen;
    }
    this.state = {
      start: 0, // first element to be displayed
      end, // last element to be displayed
      topFill: 0, // height of the blank space above elements when LL
      bottomFill, // hieght of the blank space beneath
      vProportion: 2, // height of the content / height of table
      hProportion: 2, // width of the content / width of the table
      containerWidth: 500, // widht of the table used by horiz. scrollbar
    };

    this.refreshTimeout = null;

    this.llTimestamp = 0; // time stamp for lazy loading
    this.resizeTimestapm = 0; // time stamp for resizing window
    this.proportionTimestamp = 0; // time stamp for proportions checking

    this.scrollBeforeUpdate = 0; // sometimes scroll is zeroed by rerendering

    this.scrollVertical = this.scrollVertical.bind(this);
    this.scrollHorizontal = this.scrollHorizontal.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.setVerticalScroll = this.setVerticalScroll.bind(this);

    this.rowsOffsets = []; // in this array we store heights of rows for smooth scrolling

    // when sync. scroll there are updates when only horizontal scroll changes
    // and then we dont want to recalculate anythink
    this.justScrollUpdate = false;
    // if this component whas the one that scrolled horizontaly then ignore next
    // horizontal scroll change
    this.changedHScroll = false;

    // if number of elements or width changed then we want to update vProportion and hProportion
    this.updateProportions = true;

    this.currentVecticalScroll = 0;
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    // we have to wait some time before tables will have their full size
    // and then calculate proportions for scrolling
    setTimeout(() => {
      if (this.container && this.rowsTable) {
        const containerWidth = this.container.offsetWidth;
        const vProportion = this.rowsTable.offsetHeight / (this.props.height - 66);
        const hProportion = this.rowsTable.offsetWidth / containerWidth;
        this.setState({ vProportion, hProportion, containerWidth });
      }
    }, 1);
  }

  componentWillReceiveProps(nextProps) {
    // checking if scroll position changed (scroll sync.)
    if (nextProps.scrollPosition !== this.props.scrollPosition) {
      if (!this.changedHScroll) {
        if (this.hScroll) this.hScroll.setScroll(nextProps.scrollPosition);
        this.scrollHorizontal(nextProps.scrollPosition);
      }
      this.changedHScroll = false;
      this.justScrollUpdate = true;
      return false;
    }
    const changedHeight = this.props.height !== nextProps.height;
    let updateLen = false;
    if (changedHeight) {
      this.renderLen = Math.ceil(nextProps.height / this.avgHeight);
      if (this.modeChanged) {
        updateLen = true;
        this.modeChanged = false;
      }
    }
    if (this.props.mode && this.props.mode !== nextProps.mode) {
      this.modeChanged = true;
    }
    // mapping children
    const { rows, leftColumn, leftHeader, header, children } = this.mapChildrenToRoles(nextProps);
    const oldRows = this.rows;
    this.rows = rows;
    this.leftColumn = leftColumn;
    this.leftHeader = leftHeader;
    this.header = header;
    this.children = children;
    if (!this.leftHeader || !this.leftColumn) {
      this.leftHeader = null;
      this.leftColumn = null;
    }
    // if number of children in rows changed more then 1 then we recalculate everythink,
    //  and start LL form 0. If it changed by 1 then its propably adding or deleting line so it doesnt matter
    if (Math.abs((this.rows.props.children.length || 0) - (oldRows.props.children.length || 0)) > 1 || updateLen) {
      if (!nextProps.static) {
        let bottomFill = ((this.rows.props.children.length || 0) - this.renderLen) * this.avgHeight;
        if (bottomFill < 0) bottomFill = 0;

        this.setState({
          start: 0,
          end: this.renderLen,
          topFill: 0,
          bottomFill,
        });
      } else {
        this.setState({
          start: 0,
          end: undefined,
          topFill: 0,
          bottomFill: 0,
        });
      }
      this.resetScroll();
      this.updateProportions = true;
    } else if (
      changedHeight ||
      this.props.width !== nextProps.width ||
      (this.props.mode && this.props.mode !== nextProps.mode)
    ) {
      // if width changed we want to update proportions
      this.updateProportions = true;
    }

    if (this.container) {
      this.scrollBeforeUpdate = this.container.scrollTop;
    }
    return true;
  }

  componentDidUpdate() {
    if (this.container && this.scrollBeforeUpdate !== 0) {
      this.container.scrollTop = this.scrollBeforeUpdate;
    }
    if (this.justScrollUpdate) {
      this.justScrollUpdate = false;

      return;
    }

    // recording heights of all displayed rows
    let rows = Array.from(this.rowsWrapper.getElementsByTagName('TR'));
    rows.splice(0, 1);
    rows.splice(rows.length - 2, 1);
    rows = rows.map(row => row.offsetHeight);
    rows.map((row, i) => {
      this.rowsOffsets[this.state.start + i] = row;
      return undefined;
    });

    // updating heights of left column
    this.updateAllHeights();

    const now = +new Date();
    if (now - this.proportionTimestamp > 50) {
      this.proportionTimestamp = now;
      // if proportions changed then update state
      const containerWidth = this.container.offsetWidth;
      const vProportion = this.rowsTable.offsetHeight / (this.props.height - 66);
      // 66 = 50 + 16 => 50 px to minimalna wysokość scrollbar-u, a 16 to wysokość dolnego scroll-a
      const hProportion = this.rowsTable.offsetWidth / containerWidth;
      if (
        this.updateProportions ||
        Math.abs(vProportion - this.state.vProportion > 0.05 || Math.abs(hProportion - this.state.hProportion) > 0.01)
      ) {
        this.updateProportions = false;
        this.setState({ vProportion, hProportion, containerWidth });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  }

  setVerticalScroll(margin) {
    const height = this.props.height - 16;
    const barHeight = height / this.state.vProportion + 50;
    const fixedMargin = barHeight + margin > height ? height - barHeight : margin;

    this.scrollVertical(fixedMargin);
    if (this.vScroll) this.vScroll.setScroll(fixedMargin);
  }

  // function that resets scroll after we change tables
  resetScroll() {
    if (this.hScroll) this.hScroll.setScroll(0);
    if (this.vScroll) this.vScroll.setScroll(0);
    this.scrollHorizontal(0);
  }

  // function that updates heights of rows
  updateAllHeights() {
    if (!this.container || !this.leftColumnRef) return;
    let leftColumnRows = this.leftColumnTBodyRef.getElementsByTagName('tr');
    const leftHeaderRow = this.leftHeaderRow.getElementsByTagName('tr');
    leftColumnRows = Array.from(leftColumnRows);
    // we slice the array not to use the blan space rows
    leftColumnRows = leftColumnRows.concat(Array.from(leftHeaderRow));
    // adding header rows to be synchronized too
    let rows = this.rowsTBody.getElementsByTagName('tr');
    const headerRow = this.headerRef.getElementsByTagName('tr');
    rows = Array.from(rows);
    rows = rows.concat(Array.from(headerRow));
    // setting height to auto to reset previously added heights
    // without it the heights would only increase, never decrease
    for (const row of leftColumnRows) {
      row.style.height = 'auto';
    }
    for (const row of rows) {
      row.style.height = 'auto';
    }

    const rowsHeights = rows.map(row => row.offsetHeight);
    const leftColumnHeights = leftColumnRows.map(row => row.offsetHeight);
    for (const i in leftColumnRows) {
      if (rowsHeights[i] > leftColumnHeights[i] && !this.props.dontUpdateInlineHeight) {
        leftColumnRows[i].style.height = `${rowsHeights[i]}px`;
      } else if (rowsHeights[i] < leftColumnHeights[i] && !this.props.dontUpdateInlineHeight) {
        rows[i].style.height = `${leftColumnHeights[i]}px`;
      }
    }
    // I dont know why but during changeing this heights sometimes scroll changes for row...
    this.leftColumnRef.scrollTop = this.container.scrollTop;
  }

  windowResize(e) {
    if (e.timeStamp - this.resizeTimestapm > 50) {
      const containerWidth = this.container.offsetWidth;
      const vProportion = this.rowsTable.offsetHeight / (this.props.height - 66);
      const hProportion = this.rowsTable.offsetWidth / containerWidth;
      this.setState({ vProportion, hProportion, containerWidth });
      this.resizeTimestapm = e.timeStamp;
    }
  }
  mapChildrenToRoles(props) {
    const children = Children.toArray(props.children);
    const other = [];
    let rows = null;
    let leftColumn = null;
    let leftHeader = null;
    let header = null;
    children.map(child => {
      switch (child.type) {
        case Rows:
          rows = child;
          break;
        case LeftColumn:
          leftColumn = child;
          break;
        case LeftHeader:
          leftHeader = child;
          break;
        case Header:
          header = child;
          break;
        default:
          other.push(child);
          break;
      }
      return undefined;
    });
    return { rows, leftColumn, leftHeader, header, children: other };
  }

  scrollVertical(margin) {
    if (!this.props.static) {
      let topFill = margin * this.state.vProportion;
      let start = 0;

      while (topFill >= 5) {
        topFill -= this.rowsOffsets[start] || this.avgHeight;
        start++;
      }

      // extraLoaded - we load some extra elements (5) befre the
      // first fisible element for smooth scrolling
      let extraLoaded = 0;
      for (let i = 0; i < 5; i++) {
        if (start > 0) {
          start--;
          extraLoaded++;
        }
      }

      // we render 3 extra elements after the visible ones
      const end = start + this.renderLen + 5 + extraLoaded;
      if (this.state.start !== start || this.state.end !== end) {
        this.oldStart = this.state.start;
        this.oldEnd = this.state.end;
        topFill = 0;
        for (let i = 0; i < start; i++) {
          topFill += this.rowsOffsets[i] || this.avgHeight;
        }
        let bottomFill = 0;
        for (let i = end; i < this.rows.props.children.length; i++) {
          bottomFill += this.rowsOffsets[i] || this.avgHeight;
        }
        if (bottomFill < 0) bottomFill = 0;

        const vProportion = this.rowsTable.offsetHeight / (this.props.height - 66);

        if (this.props.slowLoad) {
          if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
          }
          this.refreshTimeout = setTimeout(() => {
            this.setState({ start, end, topFill, bottomFill, vProportion });
            this.currentVecticalScroll = parseInt(margin * this.state.vProportion, 10);
            this.container.scrollTop = parseInt(margin * this.state.vProportion, 10);
            if (this.leftColumn) this.leftColumnRef.scrollTop = parseInt(margin * this.state.vProportion, 10);
          }, 100);
        } else {
          this.setState({ start, end, topFill, bottomFill, vProportion });
        }
      }
    }
    this.currentVecticalScroll = parseInt(margin * this.state.vProportion, 10);
    this.container.scrollTop = parseInt(margin * this.state.vProportion, 10);
    if (this.leftColumn) this.leftColumnRef.scrollTop = parseInt(margin * this.state.vProportion, 10);
  }
  scrollHorizontal(margin) {
    if (this.props.scrollCallback) {
      this.props.scrollCallback(margin);
      this.changedHScroll = true;
    }
    this.container.scrollLeft = parseInt(margin * this.state.hProportion, 10);
    this.headerRef.scrollLeft = parseInt(margin * this.state.hProportion, 10);
    this.container.scrollTop = this.currentVecticalScroll;
    if (this.leftColumn) this.leftColumnRef.scrollTop = this.currentVecticalScroll;
  }

  render() {
    const { width, columnGroup, columnStyle, className, style } = this.props;
    const height = (this.props.maxHeight || this.props.height) - 16;
    const { start, end, topFill, bottomFill } = this.state;
    return (
      (<div className={`kadroGrid ${className || ''}`} style={style}>
        {this.leftHeader
          ? cloneElement(this.leftHeader, {
              style: columnStyle,
              setRef: ref => {
                this.leftHeaderRow = ref;
              },
            })
          : null}
        {cloneElement(this.header, {
          width,
          columnGroup,
          fixedColumn: this.leftColumn !== null,
          headerRef: ref => {
            this.headerRef = ref;
          },
        })}
        <div className="kadroGrid__contentContainer">
          {this.leftColumn
            ? cloneElement(this.leftColumn, {
                getRef: ref => {
                  this.leftColumnRef = ref;
                },
                getTBodyRef: ref => {
                  this.leftColumnTBodyRef = ref;
                },
                height,
                start,
                end,
                topFill,
                bottomFill,
                style: columnStyle,
              })
            : null}
          <div className="kadroGrid__rowsContainer">
            {cloneElement(this.rows, {
              height,
              width,
              start,
              end,
              topFill,
              bottomFill,
              columnGroup,
              fixedColumn: this.leftColumn !== null,
              getContainerRef: ref => {
                this.container = ref;
              },
              getTableRef: ref => {
                this.rowsTable = ref;
              },
              getWrapperRef: ref => {
                this.rowsWrapper = ref;
              },
              getTBodyRef: ref => {
                this.rowsTBody = ref;
              },
              ...this.rows.props,
            })}
            {this.children}
            {this.state.hProportion < 1.015 ? null : (
              <Scroll
                barWidth={this.state.containerWidth / this.state.hProportion}
                onScroll={this.scrollHorizontal}
                target={() => this.container}
                ref={ref => {
                  this.hScroll = ref;
                }}
                proportion={this.state.hProportion}
              />
            )}
          </div>
          {this.state.vProportion < 1.05 || this.props.maxHeight ? null : (
            <Scroll
              vertical
              height={height}
              barHeight={height / this.state.vProportion + 50}
              onScroll={this.scrollVertical}
              target={() => this.container}
              proportion={this.state.vProportion}
              ref={ref => {
                this.vScroll = ref;
              }}
            />
          )}
        </div>
      </div>)
    );
  }
}
Container.defaultProps = {
  static: false, // If grid is static then lazy loading is turned off
  slowLoad: false, // Slow load should be used for long tables when lazy loading is too slow
  height: 500,
  width: 500,
  scrollPosition: 0, // When syncing scrolls this prop changes and is used in WRP
  mode: '', // Sometimes we whant to refresh view when we change elements, but the number is the same
  scrollCallback: () => {},
  columnGroup: null,
  className: '',
  columnStyle: null,
};
Container.propTypes = {
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  width: PropTypes.number,
  static: PropTypes.bool,
  slowLoad: PropTypes.bool,
  scrollPosition: PropTypes.number,
  mode: PropTypes.string,
  scrollCallback: PropTypes.func,
  columnGroup: PropTypes.shape({}),
  className: PropTypes.string,
  columnStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  dontUpdateInlineHeight: PropTypes.bool,
};
export default Container;
