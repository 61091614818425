import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip';

const ScheduleShiftWrapper = ({ children, tooltipText }) => {
  if (!tooltipText) return <>{children}</>;
  return <Tooltip content={tooltipText}>{children}</Tooltip>;
};

ScheduleShiftWrapper.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.node,
};

export default ScheduleShiftWrapper;
