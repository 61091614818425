import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import LoadingSpinner from '@/components/common/Basic/LoadingSpinner';
import { MDRadios } from '@/components/common/inputs/MDComponents';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { EXPORT_FILE_TYPES } from '@/constants/exportConstants';

import { GENERATE_QR_CODE_OPTIONS, messages, qrCodeGenerationOptions } from './ExportQrCodeModal.helpers';

const ExportQrCodeModal = (
  { isModalVisible, hideModal, relevantSelectedEmployeesIds, relevantEmployees, exportSelectedEmployeesAsFile },
  { intl },
) => {
  const [qrGenerateOptionSelected, setQrGenerateOptionSelected] = useState(GENERATE_QR_CODE_OPTIONS.single);
  const [isExporting, setIsExporting] = useState(false);
  const qrCodeGenerationOptionsElements = useMemo(() => qrCodeGenerationOptions(intl), []);

  const onSubmit = () => {
    setIsExporting(true);
    exportSelectedEmployeesAsFile(EXPORT_FILE_TYPES.QR_CODES, relevantEmployees, relevantSelectedEmployeesIds, {
      version: qrGenerateOptionSelected,
    });
    setTimeout(() => {
      setIsExporting(false);
    }, 1000);
  };

  return (
    <MDKadroModal
      title={intl.formatMessage(messages.generateQrCodesTitle)}
      modifiers={['narrow']}
      show={isModalVisible}
      onHide={hideModal}
      onSubmit={onSubmit}
      confirmText={isExporting ? <LoadingSpinner /> : intl.formatMessage(messages.confirmText)}
      testId="qrCodeModal"
    >
      <MDRadios
        elements={qrCodeGenerationOptionsElements}
        selectedItemType={qrGenerateOptionSelected}
        onChangeType={setQrGenerateOptionSelected}
      />
    </MDKadroModal>
  );
};

ExportQrCodeModal.contextTypes = {
  intl: PropTypes.shape({}),
};

ExportQrCodeModal.propTypes = {
  isModalVisible: PropTypes.bool,
  hideModal: PropTypes.func,
  relevantSelectedEmployeesIds: PropTypes.arrayOf(PropTypes.string),
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  exportSelectedEmployeesAsFile: PropTypes.func,
};

export default ExportQrCodeModal;
