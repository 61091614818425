import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import IconWithLabel from '@/components/common/UI/IconWithLabel/IconWithLabel.jsx';
import { convertDateToCustomFormat } from '@/utils/dateHelper';

import { SendPanelFooter,SendPanelHeader } from '../../SendPanel';
import { messages } from './AvailabilitySendPanel.messages.js';

const AvailabilitySendPanel = (props, context) => {
  const panelClass = classnames('send_panel', { open: props.showSendPanel });

  const onCancel = useCallback(() => {
    props.changeSendPanelVisibility(false);
  }, []);

  const onPublish = useCallback(() => {
    props.changeSendPanelVisibility(false);
    props.confirmDraftAvailabilities();
  });

  return (
    <div className={panelClass}>
      <SendPanelHeader onClose={onCancel} title={context.intl.formatMessage(messages.sendPanelTitle)} />
      <div className="content_pane">
        <IconWithLabel
          icon="info_outline"
          label={context.intl.formatMessage(messages.publishDetails, {
            start: convertDateToCustomFormat(props.customDate.start),
            end: convertDateToCustomFormat(props.customDate.end),
          })}
        />
        <SendPanelFooter
          submitText={context.intl.formatMessage(messages.confirm)}
          onCancel={onCancel}
          onSubmit={onPublish}
        />
      </div>
    </div>
  );
};

AvailabilitySendPanel.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AvailabilitySendPanel.propTypes = {
  changeSendPanelVisibility: PropTypes.func,
  confirmDraftAvailabilities: PropTypes.func,
  showSendPanel: PropTypes.bool,
  customDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default AvailabilitySendPanel;
