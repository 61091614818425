import { COLUMNS_OPTIONS_MAPPING, EXPORTS_COLUMNS_VALUE, EXPORTS_OPTIONS_NAME } from '@/constants/exportConstants';

import { ColumnsItems, ExportOptions } from '../../../exports.types';

export const isAbsencesColumn = (column: ColumnsItems, id: string): boolean =>
  column.value === EXPORTS_COLUMNS_VALUE.ABSENCES && id === EXPORTS_OPTIONS_NAME.ABSENCES;

export const isAttendanceGPSColumn = (column: ColumnsItems, id: string): boolean =>
  column.value === EXPORTS_COLUMNS_VALUE.ATTENDANCE_GPS && id === EXPORTS_OPTIONS_NAME.ATTENDANCE_GPS;

export const isOvertimeColumn = (column: ColumnsItems, id: string): boolean =>
  ['overtime50', 'overtime100', 'potentialOvertime', 'fillUp'].includes(column.value) &&
  id === EXPORTS_OPTIONS_NAME.OVERTIME;

export const isAvailabilitiesColumn = (column: ColumnsItems, id: string): boolean =>
  column.value === EXPORTS_COLUMNS_VALUE.AVAILABILITIES && id === EXPORTS_OPTIONS_NAME.AVAILABILITIES;

export const shouldColumnsUpdate = (column: ColumnsItems, id: string): boolean =>
  isAbsencesColumn(column, id) ||
  isAttendanceGPSColumn(column, id) ||
  isOvertimeColumn(column, id) ||
  isAvailabilitiesColumn(column, id);

export const updateColumns = (columnsItems: ColumnsItems[], id: string, checked: boolean): ColumnsItems[] =>
  columnsItems.reduce((acc, column) => {
    if (shouldColumnsUpdate(column, id)) {
      const updatedColumn: ColumnsItems = { ...column, active: checked };
      acc.push(updatedColumn);
    } else {
      acc.push({ ...column });
    }

    return acc;
  }, []);

export const mapColumnToOption = (column: ColumnsItems): [string, boolean] | null => {
  const mappedOptionKey = COLUMNS_OPTIONS_MAPPING[column.value];

  return mappedOptionKey ? [mappedOptionKey, column.active] : null;
};

export const selectExportOptions = (columnsItems: ColumnsItems[]) =>
  columnsItems.reduce((acc: ExportOptions, column: ColumnsItems) => {
    const mappedOption = mapColumnToOption(column);

    if (mappedOption) {
      const [key, value] = mappedOption;
      acc[key] = key === EXPORTS_OPTIONS_NAME.OVERTIME ? acc[key] || value : value;
    }

    return acc;
  }, {});

export const updateColumnsSelection = (columnsForSelectedFormat: ColumnsItems[], columnsItems: ColumnsItems[]) => {
  const resultColumns: ColumnsItems[] = [];

  columnsItems.forEach(columnForSelected => {
    const columnToReturn = columnsForSelectedFormat.find(
      ({ value }) => value === columnForSelected.value && columnForSelected.active,
    );
    if (columnToReturn) {
      resultColumns.push(columnToReturn);
    }
  });

  columnsForSelectedFormat.forEach(column => {
    const isColumnInResultColumns = resultColumns.some(({ value }) => value === column.value);
    if (!isColumnInResultColumns) {
      resultColumns.push({ ...column, active: false });
    }
  });

  return resultColumns;
};
