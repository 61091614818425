import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { createLimits } from '@/utils/absenceHelpers';

import AbsenceLimitsTabContainer from '../../EmployeeModal/AbsenceLimitsTab/AbsenceLimitsTab.redux';

const EmployeesEditMasAbsenceLimitsField = ({ absencesTypes, onChange }) => {
  const [state, setState] = useState({ limits: {}, changedLimits: [] });

  const handleLimitChange = (value, id) => {
    if (value) {
      setState(prevState => ({
        limits: {
          ...prevState.limits,
          [id]: value,
        },
        changedLimits: !prevState.changedLimits.includes(id)
          ? [...prevState.changedLimits, id]
          : prevState.changedLimits,
      }));
    } else {
      setState(prevState => ({
        limits: Object.keys(prevState.limits).reduce(
          (acc, cur) => (cur === id ? acc : { ...acc, [cur]: prevState.limits[cur] }),
          {},
        ),
        changedLimits: prevState.changedLimits.filter(limit => limit !== id),
      }));
    }
  };

  useEffect(() => {
    const limits = createLimits(absencesTypes, state.limits, state.changedLimits);
    onChange(limits);
  }, [state]);

  return <AbsenceLimitsTabContainer limits={state.limits} handleLimitChange={handleLimitChange} />;
};

EmployeesEditMasAbsenceLimitsField.propTypes = {
  absencesTypes: PropTypes.arrayOf(
    PropTypes.shape({
      deleted_at_timestamp: PropTypes.string,
      has_limit: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

export default EmployeesEditMasAbsenceLimitsField;
