import { DragEvent, useEffect, useState } from 'react';

import {
  addClassNamesToSelectedItems,
  changeItemsOrder,
  hideDraggedElement,
  Item,
} from './useMultiSelectDragAndDrop.helpers';

const useMultiSelectDragAndDrop = (
  selectedItems: Item[],
  shouldSort: boolean,
  setAllItems: (item: Item[]) => void,
  allItems: Item[],
  itemClassNames: string,
  itemHideClassNames: string,
) => {
  const [draggable, setDraggable] = useState(false);

  const [items, setItems] = useState(addClassNamesToSelectedItems(selectedItems, shouldSort, itemClassNames));
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e: DragEvent<HTMLDivElement>, item: Item) => {
    e.dataTransfer.effectAllowed = 'move';
    setDraggedItem(item);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault();
    if (!draggedItem) return;
    const updatedItems = changeItemsOrder(items, draggedItem, dropIndex);
    const newItems = hideDraggedElement(updatedItems, draggedItem, itemHideClassNames);
    setItems(newItems);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const newItems = addClassNamesToSelectedItems(items, shouldSort, itemClassNames);

    setItems(newItems);
    setDraggedItem(null);
    const relevantallItems = [
      ...newItems,
      ...allItems.filter(option => !newItems.find(newItem => newItem.value === option.value)),
    ];
    setDraggable(false);
    setAllItems(relevantallItems);
  };

  useEffect(() => {
    setItems(addClassNamesToSelectedItems(selectedItems, shouldSort, itemClassNames));
  }, [selectedItems, shouldSort]);

  return {
    items,
    draggable,
    setDraggable,
    handleDragStart,
    handleDragOver,
    handleDrop,
  };
};

export default useMultiSelectDragAndDrop;
