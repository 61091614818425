import { connect } from 'react-redux';

import UnauthenticatedLayoutShowcase from './UnauthenticatedLayoutShowcase.jsx';

const mapStateToProps = state => ({
  promoContents: state.reducer.promoContents,
});

const mapDispatchToProps = {};

const UnauthenticatedLayoutShowcaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnauthenticatedLayoutShowcase);

export default UnauthenticatedLayoutShowcaseContainer;
