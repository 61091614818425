import { connect } from 'react-redux';

import { getWorkingRulesViolationsForCurrentDateArrayWithToastr } from '@/actions/workingRules.js';
import { FREEMIUM_HIDE_WORKING_RULES } from '@/constants/Restrictions.js';

import WorkingRuleButton from './WorkingRuleButton';

const mapStateToProps = state => ({
  isVerifyWorkingRulesButtonActive: state.reducer.workingRules.isVerifyWorkingRulesButtonActive,
  workingRulesDisabledForFreemium: state.reducer.userPermissions.restrictions.includes(FREEMIUM_HIDE_WORKING_RULES),
});

const mapDispatchToProps = {
  getWorkingRulesViolationsForCurrentDateArrayWithToastr,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingRuleButton);
