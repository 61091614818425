import PropTypes from 'prop-types';
import { useState } from 'react';

import LabeledTextInput from '@/components/common/inputs/LabeledTextInput.jsx';
import { MAX_BUDGET_TARGETS_COST, MAX_BUDGET_TARGETS_HOURS } from '@/constants/budgetTargets';

import { messages } from '../LoadBudgetTargetModal.messages';

import './loadBudgetTargetModalManually.scss';

const LoadBudgetTargetModalManually = ({ formData, setFormData, isBudgetTargetEnabled }, { intl }) => {
  const [budgetHours, setBudgetHours] = useState('');
  const onChange = e => {
    const { target } = e;
    setFormData(prevState => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const spmhOnChange = e => {
    const { target } = e;
    setBudgetHours(target.value);
    setFormData(prevState => ({
      ...prevState,
      spmhBudget: target.value === '' ? '' : target.value * 60,
    }));
  };

  return (
    <div>
      <div className="loadBudgetTargetModalManually__inputGroup">
        <LabeledTextInput
          value={budgetHours}
          type="number"
          onChange={spmhOnChange}
          id="budgetHours"
          label={intl.formatMessage(messages.spmhBudget)}
          disabled={!isBudgetTargetEnabled}
          maxValue={MAX_BUDGET_TARGETS_HOURS}
        />
        <div className="k-loadBudgetTargetModal__suffix">{intl.formatMessage(messages.spmhBudgetSuffix)}</div>
      </div>
      <div className="loadBudgetTargetModalManually__inputGroup">
        <LabeledTextInput
          value={formData.colBudget}
          type="number"
          onChange={onChange}
          id="colBudget"
          label={intl.formatMessage(messages.colBudget)}
          disabled={!isBudgetTargetEnabled}
          maxValue={MAX_BUDGET_TARGETS_COST}
        />
        <div className="k-loadBudgetTargetModal__suffix">{intl.formatMessage(messages.budgetSuffix)}</div>
      </div>
      {!isBudgetTargetEnabled && (
        <div className="k-loadBudgetTargetModal__budgetTargetDisabled">
          {intl.formatMessage(messages.editBudgetTargetDisabled)}
        </div>
      )}
    </div>
  );
};

LoadBudgetTargetModalManually.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LoadBudgetTargetModalManually.propTypes = {
  formData: PropTypes.shape({
    estimatedBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setFormData: PropTypes.func,
  isBudgetTargetEnabled: PropTypes.bool,
};

export default LoadBudgetTargetModalManually;
