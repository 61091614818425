import { connect } from 'react-redux';

import { autoScheduleImportError, autoScheduleImportSuccess } from '@/actions/autoscheduler.js';
import { exportData } from '@/actions/exports.js';
import { importScheduleTemplate } from '@/actions/import';
import { changeSelectedDay } from '@/actions/schedule.jsx';

import AutoGenerateCreateSchedule from './AutoGenerateCreateSchedule.jsx';

const mapStateToProps = (state, ownProps) => ({
  recommendedSchedule: ownProps.recommendedSchedule || state.reducer.recommendedSchedule.recommendedSchedule,
  scheduleState: state.reducer.scheduleState,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  customDate: state.reducer.mainDateStore.customDate,
  selectedLocationId: state.reducer.scheduleLocationFilter[0],
});

const mapDispatchToProps = {
  changeSelectedDay,
  exportData,
  importScheduleTemplate,
  autoScheduleImportSuccess,
  autoScheduleImportError,
};

const AutoGenerateCreateScheduleContainer = connect(mapStateToProps, mapDispatchToProps)(AutoGenerateCreateSchedule);

export default AutoGenerateCreateScheduleContainer;
