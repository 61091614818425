import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { SCHEDULE_PASTE_BACKGROUND,SCHEDULE_SELECTED_BACKGROUND } from '@/constants/colors.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { isHoliday,isWeekend } from '@/utils/dateHelper.js';
import { getScheduleSettingsValue } from '@/utils/schedulerHelpers.js';

import ManagerNoteCorn from './ManagerNoteCorn/ManagerNoteCorn.redux.js';

class ScheduleTableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleHoverState: false,
      index: null,
    };
    bindPrototypeFunctions(this);
  }

  shouldComponentUpdate(nextProps) {
    const diffKeys = [];
    Object.keys(this.props).forEach(key => {
      if (this.props[key] !== nextProps[key]) diffKeys.push(key);
    });
    if (diffKeys.length === 1 && diffKeys[0] === 'locationId') {
      return nextProps.locationId !== this.props.locationId;
    }
    return true;
  }

  onMouseOver(index) {
    this.setState({
      index,
      toggleHoverState: true,
    });
  }

  onMouseLeave() {
    this.setState({
      index: null,
      toggleHoverState: false,
    });
  }

  render() {
    const {
      canPaste,
      dateStore,
      holidays,
      locationId,
      managerNotes,
      selectable,
      selectedColumns,
      selectedDisplayMode,
      settings,
      userPermissions,
      toggleScheduleColumn,
    } = this.props;
    const isTemplateDisplayMode = selectedDisplayMode.type === 'templates';
    const showHolidays = !isTemplateDisplayMode && getScheduleSettingsValue(settings, 'holidays');

    const editingScheduleDisabled = userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);

    const onClickHandler = date => (selectable && !editingScheduleDisabled ? toggleScheduleColumn(date) : null);

    const selected = date => selectedColumns.includes(date);
    return (
      <tr className={dateStore.dateArray.length <= 7 ? 'schedule' : ''}>
        {dateStore.dateArray.map((date, index) => {
          const holiday = isHoliday(date, holidays);
          const managerNote = managerNotes && managerNotes[date];
          const showManagerNote = managerNote && !isTemplateDisplayMode;
          const showManagerNoteWithCommentIcon = showManagerNote && managerNote.comment;
          const showManagerNoteCorn = !isTemplateDisplayMode && !userPermissions.isEmployee;

          const headerClassname = classnames('day', 'scheduleHeader', {
            today: date === dateStore.today,
            weekend: isWeekend(date),
            freeFromWorkHoliday: showHolidays && holiday.freeFromWork,
            workDayHoliday: showHolidays && holiday.workDay,
          });
          const style = {};
          const isSelected = selected(date);
          if (selectable) {
            if (canPaste && isSelected) {
              style.backgroundColor = SCHEDULE_PASTE_BACKGROUND;
            } else if (isSelected) {
              style.backgroundColor = SCHEDULE_SELECTED_BACKGROUND;
            }
          }
          const popover = {
            content: [
              <div key={index} className="holidayPopOver">
                {holiday.name}
                {''}
                {managerNote ? managerNote.comment : null}
              </div>,
            ],
          };
          const renderHeaderCell = (
            <span className="date">
              {showManagerNoteWithCommentIcon ? <i className="material-icons managerCommentIcon">comment</i> : null}
              {showHolidays && holiday.freeFromWork && <i className="material-icons calendar-icon">today</i>}
              {showHolidays && holiday.workDay && <i className="material-icons calendar-icon">calendar_today</i>}
              {isTemplateDisplayMode ? moment(date).format('dddd') : moment(date).format('dddd DD.MM')}
            </span>
          );
          return (
            <th
              key={index}
              className={headerClassname}
              onClick={() => onClickHandler(date)}
              onMouseOver={() => this.onMouseOver(index)}
              onMouseLeave={() => this.onMouseLeave(null)}
              onFocus={() => {}}
              style={style}
            >
              {showManagerNote ? (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: 6,
                    backgroundColor: `${managerNote.color}`,
                  }}
                />
              ) : null}
              <div className="schedule__content">
                {(showHolidays && (holiday.freeFromWork || holiday.workDay)) || showManagerNote ? (
                  <HoverPopOver name="holidayHover" popover={popover}>
                    {renderHeaderCell}
                  </HoverPopOver>
                ) : (
                  renderHeaderCell
                )}
              </div>
              <ManagerNoteCorn
                locationId={locationId}
                date={date}
                managerNote={managerNote}
                show={this.state.toggleHoverState && this.state.index === index && showManagerNoteCorn}
              />
            </th>
          );
        })}
      </tr>
    );
  }
}

ScheduleTableHeader.propTypes = {
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    headerDateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    selectedColumns: PropTypes.arrayOf(PropTypes.string),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  locationId: PropTypes.string,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    isEmployee: PropTypes.bool,
  }),
};
export default ScheduleTableHeader;
