import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

import { inputValidation } from '@/utils/inputValidation.js';

const ScheduleBudgetTableRowInput = ({ estimate, name, setError, onChange, disabled, children }) => {
  const [value, setValue] = useState(estimate[name]);

  useEffect(() => {
    if (estimate[name] === 0) setValue('0');
    else setValue(estimate[name] ? Number(estimate[name]) / 100 : '');
  }, [estimate, name]);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  const onEstimateChange = ({ target }) => {
    const { name: targetName } = target;
    const newValue = target.value === '' ? ' - ' : target.value.replace(',', '.');
    const error = inputValidation(targetName, newValue);
    setValue(newValue);
    setError(name, estimate.date, error !== '');
    const updatedValueConverted = newValue === '-' ? null : Math.round(Number(newValue) * 100);
    const updatedValue = {
      [name]: updatedValueConverted,
    };

    onChange(updatedValue);
  };

  return (
    <>
      <input
        name={name}
        onBlur={onEstimateChange}
        onChange={handleChange}
        type="text"
        className="form-control"
        value={value}
        disabled={disabled}
        placeholder="-"
      />
      {children}
    </>
  );
};

ScheduleBudgetTableRowInput.propTypes = {
  estimate: PropTypes.shape({
    date: PropTypes.string,
    location_id: PropTypes.string,
  }),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onChange: PropTypes.func,
  children: PropTypes.node,
  setError: PropTypes.func,
};

export default ScheduleBudgetTableRowInput;
