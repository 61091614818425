import PropTypes from 'prop-types';
import { memo } from 'react';

import IconWithLabel from '@/components/common/UI/IconWithLabel/IconWithLabel.jsx';
import { convertDateToCustomFormat } from '@/utils/dateHelper';

import { messages } from './ShiftsToPublishSummary.messages';

const ShiftsToPublishSummary = (props, context) => {
  const {
    numberOfShiftsWithErrors,
    numberOfDraftShifts,
    publishShifts,
    showWorkingRulesError,
    showWorkingRulesWarning,
    showHoursTargetError,
    showMoneyTargetError,
    showHoursAndMoneyTargetError,
    customDate: { start, end },
  } = props;
  return (
    <div>
      <IconWithLabel
        icon="info_outline"
        label={context.intl.formatMessage(messages.publishDetails, {
          start: convertDateToCustomFormat(start),
          end: convertDateToCustomFormat(end),
        })}
      />
      {numberOfShiftsWithErrors > 0 && (
        <IconWithLabel
          icon="close"
          label={context.intl.formatMessage(messages.shiftsWithErrors, { errorsNo: numberOfShiftsWithErrors })}
          modifier="error"
        />
      )}
      {showWorkingRulesError && publishShifts && numberOfDraftShifts > 0 && (
        <IconWithLabel icon="close" label={context.intl.formatMessage(messages.workingRulesError)} modifier="error" />
      )}
      {showWorkingRulesWarning && publishShifts && numberOfDraftShifts > 0 && (
        <IconWithLabel
          icon="warning"
          label={context.intl.formatMessage(messages.workingRulesWarning)}
          modifier="warning"
        />
      )}
      {showHoursTargetError && publishShifts && numberOfDraftShifts > 0 && (
        <IconWithLabel icon="close" label={context.intl.formatMessage(messages.hoursTargetError)} modifier="error" />
      )}
      {showMoneyTargetError && publishShifts && numberOfDraftShifts > 0 && (
        <IconWithLabel icon="close" label={context.intl.formatMessage(messages.moneyTargetError)} modifier="error" />
      )}
      {showHoursAndMoneyTargetError && publishShifts && numberOfDraftShifts > 0 && (
        <IconWithLabel
          icon="close"
          label={context.intl.formatMessage(messages.hoursAndMoneyTargetError)}
          modifier="error"
        />
      )}
    </div>
  );
};

ShiftsToPublishSummary.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ShiftsToPublishSummary.propTypes = {
  numberOfShiftsWithErrors: PropTypes.number,
  numberOfDraftShifts: PropTypes.number,
  publishShifts: PropTypes.bool,
  showWorkingRulesError: PropTypes.bool,
  showWorkingRulesWarning: PropTypes.bool,
  showHoursTargetError: PropTypes.bool,
  showMoneyTargetError: PropTypes.bool,
  showHoursAndMoneyTargetError: PropTypes.bool,
  customDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default memo(ShiftsToPublishSummary);
