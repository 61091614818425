import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addMassEmployeesModalTitle: {
    id: 'addMassEmployeesModal.title',
    defaultMessage: 'Dodaj',
  },
  addMassEmployeesModalDescription: {
    id: 'addMassEmployeesModal.description',
    defaultMessage: 'pracowników',
  },
  addMassEmployeesConfirmButton: {
    id: 'addMassEmployeesModal.confirmButton',
    defaultMessage: 'Dodaj',
  },
  addMassEmployeesNameInput: {
    id: 'addMassEmployeesModal.nameInput',
    defaultMessage: 'Imię',
  },
  addMassEmployeesLastNameInput: {
    id: 'addMassEmployeesModal.lastNameInput',
    defaultMessage: 'Nazwisko',
  },
  addMassEmployeesJobTitlesInput: {
    id: 'addMassEmployeesModal.jobTitlesInput',
    defaultMessage: 'Stanowiska',
  },
  addMassEmployeesLocationsInput: {
    id: 'addMassEmployeesModal.locationsInput',
    defaultMessage: 'Lokalizacje',
  },
  addMassEmployeesEmploymentConditionInput: {
    id: 'addMassEmployeesModal.employmentConditionInput',
    defaultMessage: 'Warunek zatrudnienia',
  },
  addMassEmployeesRoleInput: {
    id: 'addMassEmployeesModal.roleInput',
    defaultMessage: 'Rola',
  },
  addMassEmployeesDeleteIcon: {
    id: 'addMassEmployeesModal.deleteIcon',
    defaultMessage: 'Usuń',
  },
  addMassEmployeesAddEmployeeButton: {
    id: 'addMassEmployees.addEmployeeButton',
    defaultMessage: 'Dodaj kolejnego pracownika',
  },
});
