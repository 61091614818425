import PropTypes from 'prop-types';

import { calculateDurationBetweenTimestamps, parseMinutesToFormat } from '@/utils/dateHelper.js';

const ProductionQuotasTableRowDetails = props => (
  <table className="table k-pquotas__inner-table">
    <colgroup>
      <col style={{ width: '20%' }} />
      <col style={{ width: '60%' }} />
      <col style={{ width: '10%' }} />
      <col style={{ width: '10%' }} />
    </colgroup>
    <tbody>
      {props.relevantEmployees.map(employee => {
        const sumMinutes = employee.attendances.reduce(
          (prev, attendance) =>
            prev + calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp),
          0,
        );
        const sum = ((sumMinutes / props.sumAllMinutes) * parseFloat(props.factor) * props.quota).toFixed(2);
        return (
          <tr key={employee.id}>
            <td style={{ backgroundColor: '#e7eaec' }} />
            <td className="col_name">
              {employee.first_name} {employee.last_name}
            </td>
            <td>{parseMinutesToFormat(sumMinutes)}</td>
            <td>{sum}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

ProductionQuotasTableRowDetails.propTypes = {
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  sumAllMinutes: PropTypes.number,
  factor: PropTypes.string,
  quota: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProductionQuotasTableRowDetails;
