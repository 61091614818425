import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';

import './InactiveUser.scss';

const InactiveUser = props => (
  <Modal show={props.showModal} onHide={props.hideModal} className="inactiveUserModal">
    <Modal.Body>
      <div className="inactiveUserModal__closeIcon" role="presentation" onClick={props.hideModal}>
        <Icon name="close" />
      </div>
      <div className="inactiveUserModal__image">
        <img src="/img/maintenance.png" alt="maintenance" />
      </div>
      <div>
        <h2 className="inactiveUserModal__title">
          <FormattedMessage id="inactiveUserModal.title" defaultMessage="Twoje konto jest nieaktywne" />
        </h2>
        <p className="inactiveUserModal__description">
          <FormattedMessage
            id="inactiveUserModal.description"
            defaultMessage="Skontaktuj się z administratorem konta w celu przywrócenia dostępu do systemu"
          />
        </p>
      </div>
    </Modal.Body>
  </Modal>
);

InactiveUser.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default InactiveUser;
