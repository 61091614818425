import { connect } from 'react-redux';

import { budgetEstimatesChange } from '@/actions/budgetEstimates.js';

import ScheduleBudgetTableRows from './ScheduleBudgetTableRows.jsx';

const mapStateToProps = state => ({
  currentTemplate: state.reducer.userTemplates.currentTemplate,
  holidays: state.reducer.calendarData.holidays,
  mainDateStore: state.reducer.mainDateStore,
  scheduleUIState: state.reducer.scheduleUIState,
  userPermissions: state.reducer.userPermissions,
  locationSettings: state.reducer.settings.locationSettings,
  selectedLocationId: state.reducer.locationFilter.selectedLocation.id,
});

const mapDispatchToProps = {
  budgetEstimatesChange,
};

const ScheduleBudgetTableRowsContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleBudgetTableRows);

export default ScheduleBudgetTableRowsContainer;
