import { defineMessages } from 'react-intl';

export default defineMessages({
  addButton: {
    id: 'budgetMetricsTableOptions.add.button',
    defaultMessage: 'Dodaj metrykę',
  },
  deleteButton: {
    id: 'budgetMetricsTableOptions.remove.button',
    defaultMessage: 'Usuń zaznaczone',
  },
});