import { connect } from 'react-redux';

import RolesTableLeftRowDetails from './RolesTableLeftRowDetails';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RolesTableLeftRowDetails);
