import {
  SELECT_ALL_ATTENDANCES,
  TOGGLE_ATTENDANCE_SELECT,
  UNSELECT_ALL_ATTENDANCES  } from '@/constants/ActionTypes';

const selected = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_ATTENDANCE_SELECT: {
      const isSelecteedAttendace = state.some(
        ({ employeeId, locationId }) =>
          employeeId === action.payload.employeeId && locationId === action.payload.locationId,
      );

      const newState = isSelecteedAttendace
        ? state.filter(
            ({ employeeId, locationId }) =>
              employeeId !== action.payload.employeeId || locationId !== action.payload.locationId,
          )
        : [...state, action.payload];

      return newState;
    }
    case SELECT_ALL_ATTENDANCES:
      return action.payload;
    case UNSELECT_ALL_ATTENDANCES:
      return [];
    default:
      return state;
  }
};

export default selected;
