import { connect } from 'react-redux';

import { enableLoadButton } from '@/actions/payroll';
import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';
import { showModal } from '@/actions/uiState';

import PayrollLocationView from './PayrollLocationView';

const mapStateToProps = ({ reducer }) => ({
  alreadyLoadedData: reducer.payrollLocation.data.length > 0,
  isLoadButtonEnabled: reducer.payrollLocation.isLoadButtonEnabled,
});

const mapDispatchToProps = {
  showModal,
  getPayrollLocationViewData,
  enableLoadButton,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationView);
