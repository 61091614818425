import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { defaultAvailabilityTypes, missingTypeAvaType } from '@/constants/availabilityDefaultTypes.js';

import { getAvaCornerPopover } from './schedule/availabilityCornerHelpers.js';

export const getAvailbilitiesWithGivenDates = (availabilities, datesList) => {
  if (!availabilities || !datesList) return [];
  return availabilities.filter(ava => datesList.includes(ava.date));
};

export const sortAvailabilitiesByDate = availabilities => {
  if (!availabilities) return [];
  return availabilities.sort((a, b) => moment(a.date) - moment(b.date));
};

export const groupAvailabilitiesByDuration = availabilities => {
  if (!availabilities) return [];
  return sortAvailabilitiesByDate(availabilities).reduce((acc, ava) => {
    const dayBeforeAvaDate = moment(ava.date)
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    const lastingAvailability = acc.find(v => v.end === dayBeforeAvaDate && v.id === (ava.type_id || ava.type));
    const otherAvailabilities = acc.filter(v => v.end !== dayBeforeAvaDate);
    return lastingAvailability
      ? [...otherAvailabilities, { id: lastingAvailability.id, start: lastingAvailability.start, end: ava.date }]
      : acc.concat({ id: ava.type_id || ava.type, start: ava.date, end: ava.date });
  }, []);
};

export const parseHoursAndMinutesToFloat = time => {
  const hours = Number(time.slice(0, 2));
  const minutes = Number(time.slice(3, 5));

  return Math.round((hours + minutes / 60) * 1000000) / 1000000;
};

export const parseFloatToHoursAndMinutes = floatHours => {
  const hours = Math.floor(floatHours);
  const stringHours = `0${hours}`.slice(-2);
  const minutes = `0${Math.round((floatHours - hours) * 60)}`.slice(-2);

  return `${stringHours}:${minutes}`;
};

export const getAvailabilityName = (availability, userCustomAvailabilities) => {
  if (availability.type === 'availability') {
    return <FormattedMessage id="availability" defaultMessage="Dostępny" />;
  }

  if (availability.type === 'unavailability') {
    return <FormattedMessage id="unavailability" defaultMessage="Niedostępny" />;
  }

  return userCustomAvailabilities.find(customAvailability => customAvailability.id === availability.type_id).name;
};

export const generateShortName = name =>
  name
    .match(/\b(\w)/g)
    .join('')
    .toUpperCase();

export const getAvailabilityTypeForGivenAvailabilityObject = (availabilityObject, userCustomTypes) => {
  if (typeof availabilityObject === 'undefined') {
    return defaultAvailabilityTypes[0];
  }
  switch (availabilityObject.type) {
    case 'availability':
    case 'unavailability':
    case 'freetime':
      return defaultAvailabilityTypes.find(t => t.type === availabilityObject.type);
    case 'custom':
      return userCustomTypes.find(t => t.id === availabilityObject.type_id);
    default:
      return undefined;
  }
};

export const getAvailabilityOrderIndex = (availabilityObject, userCustomTypes) => {
  if (!availabilityObject) {
    return 5;
  }

  const { is_availability: isAvailability, type } = getAvailabilityTypeForGivenAvailabilityObject(
    availabilityObject,
    userCustomTypes,
  );
  const isCustom = type === 'custom';
  const isFreeTime = type === 'freetime';
  if (isAvailability && !isCustom && !isFreeTime) {
    return 0;
  }
  if (isAvailability && isFreeTime) {
    return 1;
  }
  if (isAvailability && isCustom) {
    return 2;
  }

  if (!isAvailability && !isCustom) {
    return 3;
  }
  if (!isAvailability && isCustom) {
    return 4;
  }

  return 5;
};

/**
 * @retunrs {object} avability block with: color, type and popup
 */
export const getExtendedAvailabilityBlock = (availabilityObject, userCustomTypes, employee, intl) => {
  let availabilityType = getAvailabilityTypeForGivenAvailabilityObject(availabilityObject, userCustomTypes);
  if (!availabilityType) availabilityType = { ...missingTypeAvaType };
  const {
    name,
    availability_short_name: availabilityShortName,
    color,
    cornColor,
    count_hours: countHours,
    count_hours_in_payroll: countHoursInPayroll,
  } = availabilityType;
  const formattedAvaObj = {
    ...availabilityObject,
    count_hours: countHours,
    count_hours_in_payroll: countHoursInPayroll,
    color: cornColor || color,
    typeName: name,
    shortTypeName: availabilityShortName,
  };

  const popover = getAvaCornerPopover(formattedAvaObj, employee, intl);

  return {
    ...formattedAvaObj,
    popover,
  };
};
