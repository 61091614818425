import * as AT from '@/constants/ActionTypes.js';

const initialState = {
  monthlyNorms: [],
  holidays: [],
};

const calendarData = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_MONTHLY_NORMS:
      return { ...state, monthlyNorms: [...state.monthlyNorms, ...action.payload] };
    case AT.GET_HOLIDAYS:
      return { ...state, holidays: [...state.holidays, ...action.payload] };
    default:
      return state;
  }
};
export default calendarData;
