import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDTextInput } from '@/components/common/inputs/MDComponents.jsx';

const EmplyeesEditMassCycleDurationField = ({ value, onChange }) => (
  <MDTextInput
    id="scheduleCycleDuration"
    type="number"
    modifiers={['modal']}
    label={
      <FormattedMessage
        id="companymanage.employmentConditions.scheduleCycleDuration"
        defaultMessage="Długość okresu rozliczeniowego"
      />
    }
    maxValue={12}
    minValue={1}
    value={value}
    onChange={e => onChange(Number(e.target.value))}
  />
);

EmplyeesEditMassCycleDurationField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmplyeesEditMassCycleDurationField;
