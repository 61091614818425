import { connect } from 'react-redux';

import {
  addShiftBlock,
  changeShiftBlock,
  changeSorting,
  deleteShiftBlockConfirm,
  massDeleteShiftBlocksConfirm,
  startEdit,
  toggleAllCheckboxes,
  toggleCheckbox,
  toggleModal,
} from '@/actions/companymanage';

import ShiftBlocksTable from './ShiftBlocksTable.jsx';

const mapStateToProps = state => ({
  userShiftblocks: state.reducer.userShiftblocks,
  userJobTitles: state.reducer.userJobTitles,
  currentCompany: state.reducer.currentCompany,
  shiftBlocksListUi: state.reducer.listsUi.shiftBlocks,
  deviceInfo: state.reducer.deviceInfo,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  addShiftBlock,
  changeShiftBlock,
  toggleCheckbox,
  toggleAllCheckboxes,
  toggleModal,
  startEdit,
  changeSorting,
  massDeleteShiftBlocksConfirm,
  deleteShiftBlockConfirm,
};

const ShiftBlocksTableContainer = connect(mapStateToProps, mapDispatchToProps)(ShiftBlocksTable);

export default ShiftBlocksTableContainer;
