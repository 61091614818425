import moment from 'moment';

import { dateModes } from '@/constants/dateModes';
import { viewDateModes } from '@/constants/viewDateModes';
import { convertDateToStandardFormat, getDateModeFromDates } from '@/utils/dateHelper';

const getRangeDates = (currentRoute, event) => {
  const start = convertDateToStandardFormat(event.start);
  const limitForRoute = viewDateModes[currentRoute]?.maxDayDiff;
  const dayDiff = moment(event.end).diff(moment(event.start), 'days');
  const end = limitForRoute && dayDiff > limitForRoute ? moment(event.start).add(limitForRoute, 'days') : event.end;
  return { start, end: convertDateToStandardFormat(end) };
};

const getDefaultModeAndDates = (modes, startDate) => {
  const defaultTypeMode = dateModes.find(({ id }) => id === modes[0]).type;
  const start = convertDateToStandardFormat(moment(startDate).startOf(defaultTypeMode));
  const end = convertDateToStandardFormat(moment(startDate).endOf(defaultTypeMode));
  return { dateMode: defaultTypeMode, start, end };
};

export const getModeAndDates = (modes, currentRoute, event) => {
  const dateRanges = getRangeDates(currentRoute, event);
  const dateMode = getDateModeFromDates(dateRanges);
  if (modes.includes(dateMode.id)) return { ...dateRanges, dateMode: dateMode.type };
  return getDefaultModeAndDates(modes, event.start);
};
