import { render as rtlRender } from '@testing-library/react';
import { mount, shallow } from 'enzyme';
import { PropTypes } from 'prop-types';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import renderer from 'react-test-renderer';

import { AppProvider } from '@/components/providers/appProvider/AppProvider';
import { IntlContextProvider } from '@/components/providers/intl/IntlContextProvider.jsx';
import store from '@/redux-store/store';
import { KadroLocaleEnum } from '@/types/locale.types.ts';

import { getCookie } from './cookieHandlers.js';
import { loadLocaleData } from './locale/locale.utils.ts';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/pl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/pl';

const intlProps = {
  locale: KadroLocaleEnum.PL,
  messages: loadLocaleData(KadroLocaleEnum.PL),
};

const cache = createIntlCache();
export const intl = createIntl(intlProps, cache);

// Disables errors from missing translations :P
if (import.meta.env.VITE_NODE_ENV !== 'production') {
  const consoleError = console.error.bind(console);
  console.error = (message, ...args) => {
    if (message?.code === 'MISSING_TRANSLATION') {
      return;
    }
    consoleError(message, ...args);
  };
}

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const getDefaultLang = () =>
  getCookie('kadroLang') ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

const language = getDefaultLang();

// Try full locale, try locale without region code
const messages = Object.values(KadroLocaleEnum).includes(language)
  ? loadLocaleData(language) || loadLocaleData(KadroLocaleEnum.PL)
  : loadLocaleData(KadroLocaleEnum.PL);

const [languageWithoutRegionCode] = language.toLowerCase().split(/[_-]+/);

export function getInitialIntl() {
  const initIntl = {
    intl: {
      locale: languageWithoutRegionCode,
      messages,
      formatMessage: intl.formatMessage,
    },
  };
  return initIntl;
}

const intlPropsJest = {
  locale: KadroLocaleEnum.PL,
  messages: loadLocaleData(KadroLocaleEnum.PL),
  onError: () => {},
  textComponent: 'span',
};
const jestIntlCache = createIntlCache();
const intlJest = createIntl(intlPropsJest, jestIntlCache);

export { intlJest };

export function shallowWithIntl(node, { context, childContextTypes } = {}) {
  return shallow(node, {
    wrappingComponent: IntlProvider,
    wrappingComponentProps: intlPropsJest,
    context: { ...context, intl: intlJest },
    childContextTypes: { intl: intlJest, ...childContextTypes },
  });
}

export function mountWithIntl(node, { context, childContextTypes } = {}) {
  return mount(node, {
    context: { ...context, intl: intlJest },
    childContextTypes: { intl: intlJest, ...childContextTypes },
    wrappingComponent: IntlProvider,
    wrappingComponentProps: intlPropsJest,
  });
}

export function createComponentWithIntl(children, props = intlPropsJest) {
  return renderer.create(
    <IntlProvider {...props}>
      <IntlContextProvider>{children}</IntlContextProvider>
    </IntlProvider>,
  );
}

export const displayTextToIntlMessage = (item, intl) => {
  const { message, ...restOfItem } = item;
  return { ...restOfItem, displayText: intl.formatMessage(message) };
};

export function renderWithIntl(ui, { props = intlPropsJest, ...renderOptions } = {}) {
  function Wrapper({ children }) {
    return (
      <AppProvider store={store}>
        <IntlProvider {...props}>{children}</IntlProvider>
      </AppProvider>
    );
  }

  Wrapper.propTypes = {
    children: PropTypes.node,
  };
  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
}

export const mapArrayOfMessages = (arrayOfMessages, intl) =>
  arrayOfMessages.map((message) => intl.formatMessage(message));

export const IntlPropType = PropTypes.shape({
  formatMessage: PropTypes.func,
});
