import classNames from 'classnames';
import PropTypes from 'prop-types';

const EventsAndNotesItem = ({ event }) => {
  const workNoteClassNames = classNames({
    'work-note__top': true,
    'work-note__top--yellow': event.type === 'note',
    'work-note__top--red': event.type === 'important',
  });
  return (
    <li className="work-note">
      <div className={workNoteClassNames}>
        <h5 className="work-note__time">{event.hours}</h5>
        <i className="work-note__icon material-icons">{event.type === 'note' ? 'border_color' : 'error_outline'}</i>
      </div>
      <div className="work-note__bottom">
        <p className="work-note__description">{event.description}</p>
      </div>
    </li>
  );
};

EventsAndNotesItem.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    hours: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default EventsAndNotesItem;
