import PropTypes from 'prop-types';

import './PulsatingDot.scss';

const PulsatingDot = ({ color }) => <div style={{ '--pulsating-dot': color }} className="pulsatingDot" />;

PulsatingDot.propTypes = {
  color: PropTypes.string,
};

export default PulsatingDot;
