import { unionBy } from 'lodash';

import {
  DELETE_ALL_NOTIFICATION_SUCCESFUL,
  DELETE_NOTIFICATION_SUCCESFUL,
  GET_NOTIFICATIONS_SUCCESFUL,
  GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL,
  MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_SUCCESFUL,
  MARK_NOTIFICATION_AS_UNREAD_ERROR,
  MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL,
} from '@/constants/ActionTypes';

const initialState = {
  notificationArray: [],
  unreadNotifications: 0,
  nextTimestamp: null,
  hasMore: false,
};

const userNotifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESFUL:
      return {
        ...state,
        nextTimestamp: action.payload.nextTimestamp,
        hasMore: action.payload.hasMore,
        notificationArray: unionBy(action.payload.messages, state.notificationArray, 'id').sort((a, b) =>
          a.createdAt < b.createdAt ? 1 : -1,
        ),
      };
    case GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    case DELETE_NOTIFICATION_SUCCESFUL: {
      const notificationToDeleteIndex = state.notificationArray.findIndex(
        notification => notification.id === action.payload,
      );
      return {
        ...state,
        notificationArray: [
          ...state.notificationArray.slice(0, notificationToDeleteIndex),
          ...state.notificationArray.slice(notificationToDeleteIndex + 1),
        ],
        unreadNotifications: state.notificationArray[notificationToDeleteIndex].read
          ? state.unreadNotifications
          : state.unreadNotifications - 1,
      };
    }
    case DELETE_ALL_NOTIFICATION_SUCCESFUL:
      return {
        ...state,
        notificationArray: [],
        unreadNotifications: 0,
      };
    case MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL:
      return {
        ...state,
        notificationArray: state.notificationArray.map(notification => ({
          ...notification,
          read: true,
        })),
        unreadNotifications: 0,
      };
    case MARK_NOTIFICATION_AS_READ_SUCCESFUL: {
      let notificationToMark;
      return {
        ...state,
        notificationArray: [
          ...state.notificationArray.map(notification => {
            if (notification.id !== action.payload) {
              return notification;
            }
            notificationToMark = notification;
            return {
              ...notification,
              read: true,
            };
          }),
        ],
        unreadNotifications: notificationToMark.read ? state.unreadNotifications : state.unreadNotifications - 1,
      };
    }
    case MARK_NOTIFICATION_AS_READ_ERROR:
      return {
        ...state,
        notificationArray: [
          ...state.notificationArray.map(notification => {
            if (notification.id !== action.payload) {
              return notification;
            }
            return {
              ...notification,
              read: false,
            };
          }),
        ],
        unreadNotifications: state.unreadNotifications + 1,
      };
    case MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL: {
      let notificationToMark;
      return {
        ...state,
        notificationArray: [
          ...state.notificationArray.map(notification => {
            if (notification.id !== action.payload) {
              return notification;
            }
            notificationToMark = notification;
            return {
              ...notification,
              read: false,
            };
          }),
        ],
        unreadNotifications: notificationToMark.read ? state.unreadNotifications : state.unreadNotifications - 1,
      };
    }
    case MARK_NOTIFICATION_AS_UNREAD_ERROR:
      return {
        ...state,
        notificationArray: [
          ...state.notificationArray.map(notification => {
            if (notification.id !== action.payload) {
              return notification;
            }
            return {
              ...notification,
              read: true,
            };
          }),
        ],
        unreadNotifications: state.unreadNotifications + 1,
      };
    default:
      return state;
  }
};

export default userNotifications;
