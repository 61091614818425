import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  date: { id: 'employeePayrollTable.columns.date.name', defaultMessage: 'Data' },
  hours: { id: 'employeePayrollTable.columns.hours.name', defaultMessage: 'Godziny pracy' },
  hoursSum: { id: 'employeePayrollTable.columns.hoursSum.name', defaultMessage: 'Czas pracy' },
  bonus: { id: 'employeePayrollTable.columns.bonus.name', defaultMessage: 'Bonus' },
  payout: { id: 'employeePayrollTable.columns.payout.name', defaultMessage: 'Do wypłaty' },
  bonusSum: { id: 'employeePayrollTable.summary.bonus.name', defaultMessage: 'Suma wynagrodzenia bonusowego' },
  payoutSum: { id: 'employeePayrollTable.summary.payout.name', defaultMessage: 'Suma wynagrodzeń' },
  payrollEmptyStateHeader: {
    id: 'employeePayrollTable.emptyState.header',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
  payrollEmptyStateText: {
    id: 'employeePayrollTable.emptyState.text',
    defaultMessage: 'Sprawdź, czy został zaznaczony poprawny przedział czasowy.',
  },
});
