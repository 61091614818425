import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Dispatch, SetStateAction, useState } from 'react';

import AddAndEditLabelPopover from '@/components/common/AddAndEditLabelPopover/AddAndEditLabelPopover';
import { DEFAULT_COLOR_TAG } from '@/constants/labels.ts';

import Tag from '../../../Tag/Tag';
import { messages } from '../multiSelectOptionManager.messages';
import OptionsList from './OptionsList/OptionsList.tsx';

export interface Option {
  label: string;
  show: boolean;
  selected: boolean;
  color: string;
  id: string;
}

interface MultiSelectOptionManagerDropDownProps {
  selectedOptions: Option[];
  searchText: string;
  handleOptionSelect: (option: Option) => void;
  width: string;
  setPopoverStatus: Dispatch<SetStateAction<boolean>>;
  error: string;
  asInput?: boolean;
  clearInputValue?: () => void;
  isDropdownActive?: boolean;
  hideAddAndEdit?: boolean;
}

const MultiSelectOptionManagerDropDown = (
  {
    selectedOptions,
    searchText,
    handleOptionSelect,
    width,
    setPopoverStatus,
    error,
    asInput,
    clearInputValue,
    isDropdownActive,
    hideAddAndEdit,
  }: MultiSelectOptionManagerDropDownProps,
  { intl },
) => {
  const [newColor, setNewColor] = useState(DEFAULT_COLOR_TAG);

  const showCreateMode = !selectedOptions.some(opt => opt.show && opt.label === searchText) && searchText;

  const showOptionToChoice = Boolean(selectedOptions.length) && selectedOptions.some(opt => opt.show);

  const dropdownClassname = classnames(' k-multiSelectOptionManager__dropdown', {
    ' k-multiSelectOptionManager__dropdown--asInput': asInput,
  });

  const descriptionTextClassName = classnames('k-multiSelectOptionManager__dropdownDescriptionText', {
    'k-multiSelectOptionManager__dropdownDescriptionText--error': error,
  });

  const dropdownDescriptionClassName = classnames('k-multiSelectOptionManager__dropdownDescription', {
    'k-multiSelectOptionManager__dropdownDescription--asInput': asInput,
  });

  const dropdownContainerClassName = classnames('k-multiSelectOptionManager__dropdownContainer', {
    'k-multiSelectOptionManager__dropdownContainer--shadow': isDropdownActive,
  });

  return (
    <div className={dropdownContainerClassName} style={{ width }}>
      <div className={dropdownDescriptionClassName}>
        <span className={descriptionTextClassName}>
          {error
            ? intl.formatMessage(messages.tooLongTagNameError)
            : intl.formatMessage(messages.dropdownDescriptionText, {
                showProperDropdownDescriptionText: hideAddAndEdit,
              })}
        </span>
        {showCreateMode && !hideAddAndEdit && (
          <AddAndEditLabelPopover
            search={searchText}
            setPopoverStatus={setPopoverStatus}
            clearInputValue={clearInputValue}
            setSelectedColor={setNewColor}
          >
            <div className="k-multiSelectOptionManager__createButtonWrapper">
              <button className="k-multiSelectOptionManager__createButton">
                <span className="k-multiSelectOptionManager__createText">{intl.formatMessage(messages.create)}</span>
                <Tag color={newColor} name={searchText} />
              </button>
            </div>
          </AddAndEditLabelPopover>
        )}
      </div>

      <div className={dropdownClassname}>
        <OverlayScrollbarsComponent
          className="k-overlay-scrollbar"
          style={{ maxHeight: '100px' }}
          options={{scrollbars: { autoHide: 'scroll' }}}
        >
          {showOptionToChoice && (
            <OptionsList
              selectedOptions={selectedOptions}
              handleOptionSelect={handleOptionSelect}
              setPopoverStatus={setPopoverStatus}
              clearInputValue={clearInputValue}
              hideEdit={hideAddAndEdit}
            />
          )}
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
};


MultiSelectOptionManagerDropDown.contextTypes = {
  intl: PropTypes.shape({}),
};

export default MultiSelectOptionManagerDropDown;
