import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { appVersion } from '@/constants/appVersion';

export const AppVersion = memo(() => (
  <span className="k-sideBarMenu__versionCode">
    <FormattedMessage id="sideBar.currentVersion" defaultMessage="Wersja {version}" values={{ version: appVersion }} />
  </span>
));

AppVersion.displayName = 'AppVersion';
