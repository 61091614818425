import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  getExportsHistoryError: {
    id: 'exportsHistory.getExportsHistoryError',
    defaultMessage: 'Nie udało się wczytać danych. Spróbuj ponownie',
  },
  deleteExportsHistorySuccess: {
    id: 'exportsHistory.deleteExportsHistorySuccess',
    defaultMessage: 'Eksport został usunięty',
  },
  deleteExportsHistoryError: {
    id: 'exportsHistory.deleteExportsHistoryError',
    defaultMessage: 'Nie udało się usunąć wybranego eksportu',
  },
  downloadExportError: {
    id: 'exportsHistory.downloadExportError',
    defaultMessage: 'Nie udało się pobrać pliku',
  },
});
