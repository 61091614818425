import { errorMessages } from './ChangePasswordBlock.messages';

export const passwordInputsModifiers = ['modal'];

export const getInitialState = () => ({
  passwordCurrent: '',
  password: '',
  passwordConfirm: '',
  errors: {},
});

export const getValueToCompare = (state, inputName) => {
  switch (inputName) {
    case 'passwordConfirm':
      return state.password;
    default:
      return null;
  }
};

export const checkIfCanSave = state => {
  if (
    !state.passwordCurrent ||
    !state.password ||
    !state.passwordConfirm ||
    Object.values(state.errors).some(err => err !== '')
  )
    return false;
  return true;
};

export const handleRequestError = err => {
  const { message } = err.response?.data || {};
  switch (message) {
    case 'Old password mismatch':
      return ['passwordCurrent', errorMessages.passwordOldMismatch];
    case 'New password cannot be the same as one of your last 6 passwords':
      return ['password', errorMessages.repeatedPassword];
    case 'New password is the same as old':
      return ['password', errorMessages.usedOldPassword];
    default:
      return null;
  }
};
