import classNames from 'classnames';
import PropTypes from 'prop-types';

import './SettingsNameWithLabel.scss';

const SettingsNameWithLabel = props => {
  const { content, name, label, onlyLabel } = props;
  const infoClassNames = classNames('settingsNameWithLabel__info', {
    'settingsNameWithLabel__info--withContent': content,
  });
  return (
    <div className="settingsNameWithLabel">
      {onlyLabel ? (
        <div className="settingsNameWithLabel__onlyLabel">{onlyLabel}</div>
      ) : (
        <div className={infoClassNames}>
          <label className="settingsNameWithLabel__name">{name}</label>
          {label && <div className="settingsNameWithLabel__description">{label}</div>}
        </div>
      )}
      {content && <div className="settingsNameWithLabel__content">{content}</div>}
    </div>
  );
};

SettingsNameWithLabel.propTypes = {
  name: PropTypes.node,
  label: PropTypes.node,
  content: PropTypes.node,
  onlyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SettingsNameWithLabel;
