import { attendanceModesIds } from '@/constants/viewDateModes';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';

import TopBarAttendanceCalendar from './filters/TopBarAttendanceDisplayMode/TopBarAttendanceCalendar.redux';
import TopBarEmploymentConditionsFilter from './filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarScheduleLocationFilter from './filters/TopBarScheduleLocationFilter/TopBarScheduleLocationFilter.redux';
import TopBarSortingFilter from './filters/TopBarSortingFilter/TopBarSortingFilter.redux.js';
import TopBar from './TopBar.jsx';

type Props = {
  isEmployee: boolean;
  isSingleDay: boolean;
};

const TopBarAttendance = ({ isSingleDay, isEmployee }: Props) => (
  <TopBar
    leftElements={[
      <TopBarAttendanceCalendar key="calswitch" modes={attendanceModesIds} allowSingleDay />,
      <TopBarScheduleLocationFilter key="locationFilter" />,
      <TopBarJobTitleFilterContainer key="jobTitleFilter" />,
      <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
      isSingleDay && <TopBarSortingFilter key="sortingFilter" isEmployee={isEmployee} />,
    ]}
  />
);

export default TopBarAttendance;
