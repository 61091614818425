import PropTypes from 'prop-types';

import MDEmployeesConditionsBlock from './MDEmployeesConditionsBlock.jsx';
import MDEmployeeSelectBlock from './MDEmployeeSelectBlock.jsx';
import MDJobTitleSelectBlock from './MDJobTitleSelectBlock.jsx';

import '@/components/autoscheduler/AutoGenerateEmployeePicker/AutoGenerateEmployeePicker.scss';

const MDEmployeeSelectBlockWithJobTitles = ({
  selectedJobTitles,
  setSelectedJobTitles,
  selectAllJobTitles,
  deselectAllJobTitles,
  jobTitleOptions,
  neededCount,
  options,
  selectedItems,
  shouldBeSorted,
  handleChange,
  handleSelectAll,
  handleDeselectAll,
  selectAllEmploymentConditions,
  deselectAllEmploymentConditions,
  selectedEmploymentConditions,
  setSelectedEmploymentConditions,
}) => (
  <>
    <MDEmployeesConditionsBlock
      {...{
        deselectAllEmploymentConditions,
        selectAllEmploymentConditions,
        selectedEmploymentConditions,
        setSelectedEmploymentConditions,
      }}
    />
    <MDJobTitleSelectBlock
      {...{
        setSelectedJobTitles,
        selectedJobTitles,
        selectAllJobTitles,
        deselectAllJobTitles,
        jobTitleOptions,
      }}
    />
    <MDEmployeeSelectBlock
      {...{ neededCount, options, selectedItems, shouldBeSorted, handleChange, handleSelectAll, handleDeselectAll }}
    />
  </>
);

MDEmployeeSelectBlockWithJobTitles.propTypes = {
  selectedJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  neededCount: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  shouldBeSorted: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
  setSelectedJobTitles: PropTypes.func,
  selectAllJobTitles: PropTypes.func,
  deselectAllJobTitles: PropTypes.func,
  jobTitleOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectAllEmploymentConditions: PropTypes.func,
  deselectAllEmploymentConditions: PropTypes.func,
  setSelectedEmploymentConditions: PropTypes.func,
  selectedEmploymentConditions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

export default MDEmployeeSelectBlockWithJobTitles;
