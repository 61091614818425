/* eslint array-callback-return:0 */
import moment from 'moment';

import { DAY_DIVIDED_IN_15_MINUTES } from '@/constants/budgetMetrics.js';
import { scheduleMessages } from '@/constants/intl.js';
import { getScheduleSettingsKey } from '@/constants/localStorageKeys';
import { createScheduleSettings, TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { getRelevantContractForDate, getRelevantContractsForMultipleDates } from '@/utils/contracts';
import { calculateNormForEmployeeHiredOrReleasedInMonth } from '@/utils/employmentConditionsHelpers.ts';

import { roundToTwoSigDigits, uuid4 } from './baseHelpers.js';
import {
  calculateDurationMinutes,
  checkIfHourIsInHourlyRange,
  extractDateFromTimestamp,
  getTimeFormatFromHourAndMinutes,
  mapTimestampsToShift,
  moveDateForwardByOne,
  substractNMonthsFromDate,
  workingHoursToTimestamp,
} from './dateHelper.js';
import { validateTimeFormat } from './inputValidation.js';
import { transformToBoolDict } from './objectHelpers/objectHelpers';
import { getDefaultAbsenceTime } from './payrollHelpers.jsx';
import { OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions.js';

/**
 * Returns the total duration in minutes for given shift array
 * @param {Array<shift>} shifts
 */
export const calculateTotalDurationInMinutesForShifts = shifts =>
  shifts
    .map(shift => {
      const amount = shift.amount || 1;
      return amount * calculateDurationMinutes(shift.working_hours);
    })
    .reduce((a, b) => a + b, 0);

/**
 * Returns the total duration in minutes for given availability_blocks array
 * @param {Array<AvailabilityBlock>} availabilityBlocks
 * @param {Array<AvailabilityType>} availabilityTypes
 */
export const calculateTotalDurationInMinutesForAvailabilities = (availabilityBlocks, userCustomTypes) =>
  availabilityBlocks.reduce((prev, block) => {
    if (block.type === 'custom') {
      const customAvailability = userCustomTypes.find(type => type.id === block.type_id);
      if (customAvailability && customAvailability.count_hours_in_payroll)
        return prev + customAvailability.count_hours * 60;
    }
    return prev;
  }, 0);

const calculateTotalDurationInMinutesForAbsencesWithoutShifts = (absences, employmentConditions) =>
  absences.reduce((sum, absence) => {
    if (absence.absence_hours) {
      return sum + calculateDurationMinutes(absence.absence_hours);
    }

    return sum + getDefaultAbsenceTime(absence.count_only_days_with_shifts, employmentConditions);
  }, 0);

export const calculateTotalWageFromShifts = (shifts, employeeContracts, jobTitles) =>
  shifts
    .map(shift => {
      let wage = 0;
      if (Number.isInteger(shift.job_title.hourly_wage)) {
        wage = shift.job_title.hourly_wage / 100;
      } else {
        const jobTitle = jobTitles.find(jobTitle => jobTitle.id === shift.job_title.id);
        wage = jobTitle ? jobTitle.hourly_wage / 100 : 0;
      }
      const amount = shift.amount || 1;
      const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
      if (relevantContract) {
        const relevantContractJobTitle = relevantContract.job_titles.find(
          contractJobTitle => contractJobTitle.job_title_id === shift.job_title.id,
        );
        const individualWage = relevantContractJobTitle?.wage;
        if (individualWage || individualWage === 0) {
          wage = individualWage / 100;
        }
      }
      return (calculateDurationMinutes(shift.working_hours) / 60) * wage * amount;
    })
    .reduce((a, b) => a + b, 0);
/**
 * Returns and array of shifts from given employees for date;
 * @param {object} userEmployees - userEmployees from redux state
 * @param {string} date  - "YYYY-MM-DD"
 */
export const extractShiftsFromEmployeesByDate = (userEmployees, date) =>
  userEmployees.reduce(
    (prev, employee) =>
      prev.concat(
        employee.shifts
          .filter(shift => shift.date === date)
          .map(shift => ({
            ...shift,
            employee: {
              ...shift.employee,
              first_name: employee.first_name,
              last_name: employee.last_name,
            },
          })),
      ),
    [],
  );

export const extractShiftsFromEmployeeWithFilters = (employee, from, to, locationIDArray, jobtitleIDArray) =>
  employee.shifts
    .filter(
      s =>
        from <= s.date &&
        s.date <= to &&
        locationIDArray.includes(s.location.id) &&
        jobtitleIDArray.includes(s.job_title.id),
    )
    .map(shift => {
      // To make sure the employee is there when we will want to delete it.
      shift.employee = {
        id: employee.id,
      };
      return shift;
    });

export const extractShiftsFromSelectedColumns = (employees, selectedColumns, locationId) => {
  let shiftsToDelete = [];
  for (const employee of employees) {
    shiftsToDelete = shiftsToDelete.concat(
      employee.shifts.filter(s => selectedColumns.includes(s.date) && locationId === s.location.id),
    );
  }
  return shiftsToDelete;
};

export const extractShiftsFromEmployeeWithLocationFilter = (employee, from, to, locationIDArray) =>
  employee.shifts
    .filter(s => from <= s.date && s.date <= to && locationIDArray.includes(s.location.id))
    .map(shift => {
      // To make sure the employee is there when we will want to delete it.
      shift.employee = {
        id: employee.id,
      };
      return shift;
    });

export const extractShiftsFromEmployeeWithLocationAndDateArrayFilter = (employee, dates, locationIDArray) =>
  employee.shifts
    .filter(s => dates.includes(s.date) && locationIDArray.includes(s.location.id))
    .map(shift => {
      // To make sure the employee is there when we will want to delete it.
      shift.employee = {
        id: employee.id,
      };
      return shift;
    });

/**
 * Extracts shifts from userEmployees based on filtlers
 * @param {array} userEmployees
 * @param {string} from
 * @param {string} to
 * @param {array} locationIDArray
 * @param {array} jobtitleIDArray
 */
export const extractsShiftsFromEmployees = (userEmployees, from, to, locationIDArray, jobtitleIDArray) =>
  userEmployees
    .map(employee => extractShiftsFromEmployeeWithFilters(employee, from, to, locationIDArray, jobtitleIDArray))
    .reduce((a, b) => a.concat(b), []);

export const extractsShiftsFromEmployeesWithLocationFilter = (userEmployees, from, to, locationIDArray) =>
  userEmployees
    .map(employee => extractShiftsFromEmployeeWithLocationFilter(employee, from, to, locationIDArray))
    .reduce((a, b) => a.concat(b), []);

export const extractsShiftsFromEmployeesWithLocationAndDateArrayFilter = (userEmployees, dates, locationIDArray) =>
  userEmployees
    .map(employee => extractShiftsFromEmployeeWithLocationAndDateArrayFilter(employee, dates, locationIDArray))
    .reduce((a, b) => a.concat(b), []);

export const extractShiftsForPreviousViewDuplicate = (userEmployees, dateStore, locationIDArray, jobtitleIDArray) => {
  const { dateArray, previousDateArray, dateMode } = dateStore;
  const [clearFrom] = dateArray;
  const clearTo = dateArray[dateArray.length - 1];
  const [copyFrom] = previousDateArray;
  const copyTo = previousDateArray[previousDateArray.length - 1];
  const shiftsToClear = extractsShiftsFromEmployees(
    userEmployees,
    clearFrom,
    clearTo,
    locationIDArray,
    jobtitleIDArray,
  );
  const shiftsToCopy = extractsShiftsFromEmployees(
    userEmployees,
    copyFrom,
    copyTo,
    locationIDArray,
    jobtitleIDArray,
  ).map(shift => ({
    ...shift,
    // To omit the same children
    id: uuid4(),
    date: moveDateForwardByOne(dateMode, shift.date),
    // Currently the backend will only initially create a draft of the shift
    draft: true,
  }));
  return { shiftsToClear, shiftsToCopy };
};

export const extractAvailabilitiesFromEmployees = (employees, from, to) => {
  let availabilityBlocks = [];
  employees.map(employee => {
    availabilityBlocks = availabilityBlocks.concat(
      employee.availability_blocks
        .filter(a => from <= a.date && a.date <= to)
        .map(a =>
          Object.assign(a, {
            employee: {
              id: employee.id,
            },
          }),
        ),
    );
  });
  return availabilityBlocks;
};

export const extractAvailabilitiesFromEmployeesWithFilters = (
  employees,
  from,
  to,
  locationIDArray,
  jobtitleIDArray,
  contracts,
) => {
  let availabilityBlocks = [];
  employees
    .filter(employee => {
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitlesIds = [
        ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
      ];
      return (
        employee.locations.some(l => locationIDArray.includes(l.id)) &&
        employeeJobTitlesIds.some(t => jobtitleIDArray.includes(t.job_title.id))
      );
    })
    .map(employee => {
      availabilityBlocks = availabilityBlocks.concat(
        employee.availability_blocks
          .filter(a => from <= a.date && a.date <= to)
          .map(a =>
            Object.assign(a, {
              employee: {
                id: employee.id,
              },
            }),
          ),
      );
    });
  return availabilityBlocks;
};

export const extractAvailabilitiesFromEmployeesWithFiltersAndDateArray = (
  employees,
  dateArray,
  locationIDArray,
  jobtitleIDArray,
  contracts,
) => {
  let availabilityBlocks = [];
  const from = dateArray[0];
  const to = dateArray[dateArray.length - 1];
  employees
    .filter(employee => {
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitlesIds = [
        ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
      ];
      return (
        employee.locations.some(l => locationIDArray.includes(l.id)) &&
        employeeJobTitlesIds.some(t => jobtitleIDArray.includes(t.job_title.id))
      );
    })
    .map(employee => {
      availabilityBlocks = availabilityBlocks.concat(
        employee.availability_blocks
          .filter(a => dateArray.includes(a.date))
          .map(a =>
            Object.assign(a, {
              employee: {
                id: employee.id,
              },
            }),
          ),
      );
    });
  return availabilityBlocks;
};

export const extractAvailabilityDraftsFromEmployeesWithFilters = (
  employees,
  from,
  to,
  locationIds,
  jobTitleIds,
  contracts,
) => {
  let drafts = [];
  employees
    .filter(employee => {
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitlesIds = [
        ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
      ];

      return (
        employee.locations.some(l => locationIds.includes(l.id)) &&
        employeeJobTitlesIds.some(jobTitleId => jobTitleIds.includes(jobTitleId))
      );
    })
    .map(employee => {
      drafts = drafts.concat(
        employee.availability_blocks
          .filter(a => a.draft && from <= a.date && a.date <= to)
          .map(d => ({
            ...d,
            employee: {
              id: employee.id,
            },
          })),
      );
    });
  return drafts;
};

/**
 * checkShiftOverlap - function to check if given shift overlaps shifts that user has already planned
 *
 * @param  {object} user - employee object with shifts
 * @param  {object} shift - shift to check if overlaps
 * @param  {array} shiftIds - array of shift ids we want to exclude from checkShiftOverlap
 *
 * @return {bool} returns true if shift overlaps
 */
export const checkShiftOverlap = (user, shift, shiftIds = []) => {
  if (!user || !shift) return false;
  let validShift = shift;
  // TODO: Probably not necessary anymore
  if (!validShift.start_timestamp) validShift = mapTimestampsToShift(shift);
  const yesterday = moment(validShift.date).add(-1, 'day').format('YYYY-MM-DD');
  const today = validShift.date;
  const tomorrow = moment(validShift.date).add(1, 'day').format('YYYY-MM-DD');

  const allUserShifts = [...user.shifts, ...(user.shifts_for_other_locations || [])];
  let relevantShifts = allUserShifts.filter(s => [yesterday, today, tomorrow].includes(s.date));

  if (shiftIds.length) {
    relevantShifts = relevantShifts.filter(s => !shiftIds.includes(s.id));
  }

  return relevantShifts.some(
    s =>
      moment(validShift.start_timestamp).isBetween(s.start_timestamp, s.end_timestamp, null, '[)') ||
      moment(validShift.end_timestamp).isBetween(s.start_timestamp, s.end_timestamp, null, '(]') ||
      moment(s.start_timestamp).isBetween(validShift.start_timestamp, validShift.end_timestamp, null, '[)') ||
      moment(s.end_timestamp).isBetween(validShift.start_timestamp, validShift.end_timestamp, null, '(]'),
  );
};

export const checkIfOverlap = (newItem, existingItems) => {
  if (!existingItems) {
    return false;
  }

  const { date, start_timestamp: startTimestamp, end_timestamp: endTimestamp } = newItem;

  const yesterday = moment(date).add(-1, 'day').format('YYYY-MM-DD');
  const today = date;
  const tomorrow = moment(date).add(1, 'day').format('YYYY-MM-DD');

  const relevantItems = existingItems.filter(
    item =>
      ([yesterday, today, tomorrow].includes(item.date) ||
        [yesterday, today, tomorrow].includes(moment(item.start_timestamp).format('YYYY-MM-DD'))) &&
      item.id !== newItem.id,
  );

  return relevantItems.some(
    item =>
      moment(startTimestamp).isBetween(item.start_timestamp, item.end_timestamp, null, '[)') ||
      moment(endTimestamp).isBetween(item.start_timestamp, item.end_timestamp, null, '(]') ||
      moment(item.start_timestamp).isBetween(startTimestamp, endTimestamp, null, '[)') ||
      moment(item.end_timestamp).isBetween(startTimestamp, endTimestamp, null, '(]'),
  );
};

/**
 * checkAvailabilityOverlap - function to check if given availability overlaps availabilities
 * that user has already planned
 *
 * @param  {object} user - employee object with availabilities
 * @param  {object} availability - availability to check if overlaps
 * @param  {array} availabilityIds - array of availabilities ids we want to exclude from checkAvailabilityOverlap
 *
 * @return {bool} returns true if availability overlaps
 */
export const checkAvailabilityOverlap = (user, availability, availabilityIds = []) => {
  if (!user || !Object.keys(user).length || !availability || !Object.keys(availability).length) return false;

  let relevantAvailabilities = user.availability_blocks;

  if (availabilityIds.length) {
    relevantAvailabilities = relevantAvailabilities.filter(
      availabilityBlock => !availabilityIds.includes(availabilityBlock.id),
    );
  }

  return relevantAvailabilities.some(availabilityBlock => availabilityBlock.date === availability.date);
};

/**
 * getStylesForScheduleDisabledEditOverlay - function that calculates widths for schedule disabled edit overlay.
 * It adds 2px to results to compensate border size(3px)
 *
 * @param  {string} disabledEditDate - date until which we cannot edit anything (schedule, availabilities, attendances)
 * @param  {array} dateArray - current dateArray from mainDateStore
 * @param  {string} dateMode - dateMode for schedule view
 * @param  {number} containerMinWidth - minimum width of table container for schedule (used in week and month modes)
 *
 * @return {objects} returns objects with calculated width and min-width to overlay disabled dates
 */
export const getStylesForScheduleDisabledEditOverlay = (disabledEditDate, dateArray, dateMode, containerMinWidth) => {
  if (!disabledEditDate) {
    return {
      display: 'none',
    };
  }
  // Adding +1 to diff to treat disabledDate as blocked
  let dayDiff = moment(disabledEditDate).diff(dateArray[0], 'days') + 1;
  if (dateMode === 'week' && dayDiff > 0) {
    dayDiff = dayDiff > 7 ? 7 : dayDiff;
    return {
      width: `calc(${(100 * dayDiff) / 7}% + 2px)`,
      minWidth: `${(containerMinWidth * dayDiff) / 7 + 2}px`,
    };
  }
  if (['month', 'custom'].includes(dateMode) && dayDiff > 0) {
    const daysInMonth = dateArray.length;
    dayDiff = dayDiff > daysInMonth ? daysInMonth : dayDiff;
    return {
      width: `calc(${(100 * dayDiff) / daysInMonth}% + 2px)`,
      minWidth: `${(containerMinWidth * dayDiff) / daysInMonth + 2}px`,
    };
  }
  if (dayDiff > 0) {
    return {
      width: '100%',
      minWidth: '100%',
    };
  }
  return {
    display: 'none',
  };
};

export const getStylesForAttendanceDisabledEditOverlay = (disabledEditDate, dateArray) => {
  if (!disabledEditDate) {
    return {
      display: 'none',
    };
  }
  // Adding +1 to diff to treat disabledDate as blocked
  const dayDiff = moment(disabledEditDate).diff(dateArray[0], 'days') + 1;
  if (dayDiff > 0) {
    return {
      width: '100%',
      minWidth: '100%',
    };
  }
  return {
    display: 'none',
  };
};

export const getStylesForAvailabilitiesDisabledEditOverlay = (disabledEditDate, dateArray) => {
  if (!disabledEditDate) {
    return {
      display: 'none',
    };
  }
  // Adding +1 to diff to treat disabledDate as blocked
  let dayDiff = moment(disabledEditDate).diff(dateArray[0], 'days') + 1;
  if (dayDiff > 0) {
    const daysInRange = dateArray.length;
    dayDiff = dayDiff > daysInRange ? daysInRange : dayDiff;
    return {
      width: '100%',
      minWidth: '100%',
      height: `${54 * dayDiff}px`,
    };
  }
  return {
    display: 'none',
  };
};

export const getBeginingDateOfScheduleCycle = scheduleCycle => {
  if (!scheduleCycle || !scheduleCycle.year || !scheduleCycle.month) {
    return moment().startOf('month');
  }
  return moment().year(scheduleCycle.year).month(scheduleCycle.month).startOf('month');
};

export const calculateMonthDiff = (scheduleCycle, date) => {
  if (!date) {
    return 0;
  }
  const beginingOfScheduleCycle = getBeginingDateOfScheduleCycle(scheduleCycle);
  return moment(date).startOf('month').diff(beginingOfScheduleCycle, 'month') + 1;
};

export const getNumOfMonthInScheduleCycle = (employee, date) => {
  const baseScheduleCycle = {
    month: moment().month() + 1,
    year: moment().year(),
    duration: 1,
  };
  let scheduleCycle = employee.employment_conditions.schedule_cycle
    ? employee.employment_conditions.schedule_cycle
    : baseScheduleCycle;
  if (!scheduleCycle.duration) {
    scheduleCycle = {
      ...scheduleCycle,
      duration: 1,
    };
  }
  if (!scheduleCycle || !scheduleCycle.duration) {
    return 0;
  }
  const monthDiff = calculateMonthDiff(scheduleCycle, date);
  let monthOfSpan = monthDiff && monthDiff % scheduleCycle.duration;
  const numOfMonthInScheduleCycle =
    monthOfSpan && (monthOfSpan < 0 ? (monthOfSpan += scheduleCycle.duration) : monthOfSpan);
  return numOfMonthInScheduleCycle;
};

export const getWorkedHoursFromShifts = (
  shifts,
  availabilityBlocks,
  userCustomTypes,
  absencesWithoutShift,
  employmentConditions,
) =>
  (calculateTotalDurationInMinutesForShifts(shifts) +
    calculateTotalDurationInMinutesForAvailabilities(availabilityBlocks, userCustomTypes) +
    calculateTotalDurationInMinutesForAbsencesWithoutShifts(absencesWithoutShift, employmentConditions)) /
  60;

export const getEmployeeMonthlyWorkingHoursMultiplier = employee =>
  employee &&
  employee.employment_conditions &&
  (employee.employment_conditions.weekly_working_minutes === 0 || employee.employment_conditions.weekly_working_minutes)
    ? parseFloat(employee.employment_conditions.weekly_working_minutes / 2400)
    : 1;

export const getNormForDate = (date, monthlyNorms, employee, holidays = []) => {
  if (!date) {
    return { totalHours: 0 };
  }
  const dateObj = moment(date);
  const month = dateObj.month() + 1;
  const year = dateObj.year();
  const employeeScheduleStatsForMonth = employee?.scheduleStats?.find(
    item => item.month === String(month) && item.year === String(year),
  );
  const normCalculatedBasedOnHireDate = employeeScheduleStatsForMonth?.work_norm;
  if (normCalculatedBasedOnHireDate) return { totalHours: normCalculatedBasedOnHireDate };
  const employeeMonthlyWorkingHoursMultiplier = getEmployeeMonthlyWorkingHoursMultiplier(employee);
  const { employment_conditions: employmentConditions } = employee || {};
  const normCalculatedOnHiredPeriod = calculateNormForEmployeeHiredOrReleasedInMonth(
    employmentConditions,
    date,
    holidays,
  );
  if (normCalculatedOnHiredPeriod || normCalculatedOnHiredPeriod === 0)
    return {
      totalHours: normCalculatedOnHiredPeriod * employeeMonthlyWorkingHoursMultiplier,
    };
  const monthlyNorm = monthlyNorms.find(item => item.start_month === month && item.start_year === year);
  return monthlyNorm
    ? { ...monthlyNorm, totalHours: monthlyNorm.totalHours * employeeMonthlyWorkingHoursMultiplier }
    : { totalHours: 0 };
};

export const getPastNorm = (currentMonthDate, monthOfSpan = 0, monthlyNorms = [], employee) =>
  Array.from({ length: monthOfSpan }, (_, i) => i + 1).reduce((pastNorm, numOfMonthToSubtract) => {
    const normMonth = substractNMonthsFromDate(currentMonthDate, numOfMonthToSubtract);

    const currentMonthNorm = getNormForDate(normMonth, monthlyNorms, employee);
    return currentMonthNorm ? pastNorm + currentMonthNorm.totalHours : pastNorm;
  }, 0);

export const getWorkedHoursInPastNorm = (currentMonthDate, monthOfSpan = 0, employee) => {
  if (!currentMonthDate || !employee) {
    return 0;
  }
  if (!employee.scheduleStats) {
    return 0;
  }
  return Array.from({ length: monthOfSpan }, (_, i) => i + 1).reduce((workedHours, numOfMonthToSubtract) => {
    const normMonth = substractNMonthsFromDate(currentMonthDate, numOfMonthToSubtract);
    const worked = employee.scheduleStats.find(item => {
      const date = moment(normMonth);
      const month = String(date.month() + 1);
      const year = String(date.year());
      return item.month === month && item.year === year;
    });
    return worked ? workedHours + worked.minutes / 60 : workedHours;
  }, 0);
};

export const getPreviousMonthBalance = (date, employee, monthlyNorms, holidays) => {
  if (!employee || !employee.employment_conditions) {
    return 0;
  }
  const monthOfSpan = getNumOfMonthInScheduleCycle(employee, date);
  const pastNorm = getPastNorm(date, monthOfSpan, monthlyNorms, employee, holidays);
  const workedHoursForPastNorm = getWorkedHoursInPastNorm(date, monthOfSpan, employee);
  return workedHoursForPastNorm - pastNorm;
};

export const getCurrentMonthBalance = (workedHoursForCurrentMonth, date, monthlyNorms, employee, holidays) => {
  if (!date || !monthlyNorms) {
    return 0;
  }
  const thisMonthsNorm = getNormForDate(date, monthlyNorms, employee, holidays).totalHours;
  return thisMonthsNorm - workedHoursForCurrentMonth;
};

export const getBalanceForScheduleCycle = (
  date,
  employee,
  workedHoursForCurrentMonth = 0,
  monthlyNorms = [],
  holidays,
) => {
  const previousMonthsBalance = getPreviousMonthBalance(date, employee, monthlyNorms, holidays);
  const currentMonthBalance = getCurrentMonthBalance(
    workedHoursForCurrentMonth,
    date,
    monthlyNorms,
    employee,
    holidays,
  );
  const balanceForScheduleCycle = roundToTwoSigDigits(previousMonthsBalance - currentMonthBalance);
  return balanceForScheduleCycle;
};

export const getScheduleSettings = (userPermissions, companyCreationTimestamp) => {
  const scheduleSettings = createScheduleSettings(userPermissions, companyCreationTimestamp);
  const settingsKey = getScheduleSettingsKey();
  const settings = localStorage.getItem(settingsKey);

  if (settings) {
    const settingsArr = JSON.parse(settings);
    if (settingsArr && settingsArr.filter) {
      return scheduleSettings
        .map(setting => {
          const localSetting = settingsArr.find(s => s.type === setting.type);
          return {
            ...setting,
            value: localSetting ? localSetting.value : setting.value,
          };
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  }
  return scheduleSettings;
};

export const getScheduleSettingsValue = (settings, type, defaultValue = false) => {
  const setting = settings.find(s => s.type === type);
  return setting ? setting.value : defaultValue;
};

export const getShiftsPassingMidnight = (employeesShifts, date, location, selectedJobtitlesIds) => {
  if (!employeesShifts || !selectedJobtitlesIds) return [];
  return employeesShifts.reduce(
    (shiftsForCurrentDay, shifts) =>
      shiftsForCurrentDay.concat(
        shifts.filter(shift => {
          const startTimestamp =
            shift.start_timestamp || workingHoursToTimestamp(shift.working_hours, shift.date).start_timestamp;
          const endTimestamp =
            shift.end_timestamp || workingHoursToTimestamp(shift.working_hours, shift.date).end_timestamp;
          const isEndHourMidnight = shift.working_hours.slice(6) === '00:00';
          return (
            extractDateFromTimestamp(startTimestamp) === date &&
            extractDateFromTimestamp(endTimestamp) !== extractDateFromTimestamp(startTimestamp) &&
            !isEndHourMidnight &&
            shift.location.id === location &&
            selectedJobtitlesIds.includes(shift.job_title.id)
          );
        }),
      ),
    [],
  );
};

export const mapShiftsPassingMidnightToCurrentDay = (shiftsPassingMidnight, date) => {
  if (!shiftsPassingMidnight) return [];
  return shiftsPassingMidnight.map(shift => {
    const shiftWorkingHours = `00:00-${shift.working_hours.slice(6)}`;
    return {
      ...shift,
      working_hours: shiftWorkingHours,
      date,
    };
  });
};

export const getShiftsForCurrentDay = (employeesShifts, locationId, date, selectedJobtitles) => {
  if (!employeesShifts || !selectedJobtitles) return [];
  const selectedJobtitlesIds = selectedJobtitles.map(jobTitle => jobTitle.id);
  const shiftsForCurrentDate = employeesShifts.reduce(
    (shiftsForCurrentDay, shifts) =>
      shiftsForCurrentDay.concat(
        shifts.filter(shift => {
          const startTimestamp =
            shift.start_timestamp || workingHoursToTimestamp(shift.working_hours, shift.date).start_timestamp;
          return (
            extractDateFromTimestamp(startTimestamp) === date &&
            shift.location.id === locationId &&
            selectedJobtitlesIds.includes(shift.job_title.id)
          );
        }),
      ),
    [],
  );
  const shiftsPassingMidnightFromPrevDay = getShiftsPassingMidnight(
    employeesShifts,
    moment(date).subtract(1, 'day').format('YYYY-MM-DD'),
    locationId,
    selectedJobtitlesIds,
  );
  return shiftsForCurrentDate.concat(mapShiftsPassingMidnightToCurrentDay(shiftsPassingMidnightFromPrevDay, date));
};

const shiftStartedYesterday = shift =>
  extractDateFromTimestamp(shift.start_timestamp) !== extractDateFromTimestamp(shift.end_timestamp) &&
  shift.working_hours.slice(6) !== '00:00';

const checkIsShiftAfterAbsence = (absenceEndDate, shiftStartTimestamp) =>
  extractDateFromTimestamp(shiftStartTimestamp) > absenceEndDate;

export const getNumberOfShiftsPer15Minutes = (shiftsForDate, absences) =>
  Array.from({ length: DAY_DIVIDED_IN_15_MINUTES }, (_, m) => m * 15).map(
    minutes =>
      shiftsForDate.filter(shift => {
        const relevantAbsence = absences?.find(
          absence =>
            absence.employee_id === shift.employee.id &&
            (moment(shift.date).isBetween(absence.from, absence.to, 'day', '[]') ||
              (absence.count_only_days_with_shifts &&
                shiftStartedYesterday(shift) &&
                !checkIsShiftAfterAbsence(absence.to, shift.start_timestamp))),
        );

        if (relevantAbsence && !relevantAbsence.absence_hours) {
          return false;
        }

        if (relevantAbsence) {
          const isAbsenceInHourlyRange = checkIfHourIsInHourlyRange(
            relevantAbsence.absence_hours,
            getTimeFormatFromHourAndMinutes(Math.floor(minutes / 60), minutes % 60),
            '[)',
          );

          if (isAbsenceInHourlyRange) {
            return false;
          }
        }

        return checkIfHourIsInHourlyRange(
          shift.working_hours,
          getTimeFormatFromHourAndMinutes(Math.floor(minutes / 60), minutes % 60),
          '[)',
        );
      }).length,
  );

export const getNumberOfShiftsForEveryMinute = shiftsForDate =>
  Array.from({ length: 24 }, (_, h) => h).map(hour =>
    Array.from({ length: 60 }, (_, m) => m).map(
      minute =>
        shiftsForDate.filter(shift =>
          checkIfHourIsInHourlyRange(shift.working_hours, getTimeFormatFromHourAndMinutes(hour, minute), '[)'),
        ).length,
    ),
  );

export const checkIfShiftIsIncludedInHourlyRange = (shiftWorkingHours, hourlyRange) => {
  const [shiftStartTimestamp, shiftEndTimestapm] = shiftWorkingHours.split('-');
  const [hourlyRangeStart, hourlyRangeEnd] = hourlyRange.split('-');
  if (
    !validateTimeFormat(shiftStartTimestamp) ||
    !validateTimeFormat(shiftEndTimestapm) ||
    !validateTimeFormat(hourlyRangeStart) ||
    !validateTimeFormat(hourlyRangeEnd)
  )
    return false;
  return (
    checkIfHourIsInHourlyRange(hourlyRange, shiftStartTimestamp, '[]') &&
    checkIfHourIsInHourlyRange(hourlyRange, shiftEndTimestapm, '[]')
  );
};

/**
 * getDisableEditUntil - function that return disabled date based on scheduleUIState displayMode
 *
 * @param  {object} scheduleUIState - object with selectedDisplayMode and type
 * @param  {object} selectedLocationSettings - object with schedule shift disable edit date
 * @param  {object} currentCompany - object with settings with availabilities disable edit date
 *
 * @return {string} returns date string or null if date is not set
 */
export const getDisableEditUntil = (scheduleUIState, selectedLocationSettings, currentCompany) => {
  if (scheduleUIState.selectedDisplayMode.type === 'schedule') {
    return selectedLocationSettings ? selectedLocationSettings.disable_location_schedule_shifts_edit_until : null;
  }
  if (scheduleUIState.selectedDisplayMode.type === 'availabilities') {
    return currentCompany.settings.disable_availabilities_edit_until;
  }
  return null;
};

export const getVisibleEmployeeIds = (scheduleState, locationIds, { includeLoaned } = { includeLoaned: false }) => {
  const visibleEmployeeIds = locationIds.reduce((sum, current) => {
    const { order, visible, loaned } = scheduleState.locations[current];
    const visibleDict = transformToBoolDict(visible);
    const loadedDict = transformToBoolDict(loaned);
    const visibleForLocation = order.filter(
      id => visibleDict[id] || (loadedDict[id] && !includeLoaned ? false : loadedDict[id]),
    );
    const result = [...sum];
    visibleForLocation.forEach(employeeId => {
      if (!result.includes(employeeId)) result.push(employeeId);
    });
    return result;
  }, []);
  return visibleEmployeeIds;
};

export const filterShiftEagerUsers = (users, locationId, jobTitleId, date, contracts, permissions) =>
  users.filter(u => {
    const employeeContracts = contracts[u.id] || [];
    const relevantContract = getRelevantContractForDate(employeeContracts, date);
    return (
      permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE) ||
      (!u.inactive &&
        u.locations.map(l => l.id.toString()).includes(locationId.toString()) &&
        relevantContract.job_titles.some(contractJobTitle => contractJobTitle.job_title_id === jobTitleId))
    );
  });
export const calculateShiftEagerUsers = (users, locationId, jobTitleId, date, contracts, permissions) =>
  filterShiftEagerUsers(users, locationId, jobTitleId, date, contracts, permissions).length;

const shouldHideSupplementaryWithoutShift = (shifts, locationId, employeeId, hideEmployeesWithoutShift = false) => {
  if (!hideEmployeesWithoutShift) return false;
  const hasShifts = shifts.data[employeeId];
  const employeeShifts = hasShifts ? Object.values(shifts.data[employeeId].shifts) : [];
  if (!hasShifts || employeeShifts.length === 0) return true;
  const hasShiftInLocation = employeeShifts.some(shift => shift.location.id === locationId);
  return !hasShiftInLocation;
};

export const supplementaryEmployeeShouldBeHidden = (
  employee,
  locationId,
  shifts,
  hideSupplementaryEmployees,
  hideEmployeesWithoutShift,
) => {
  const isSupplementary = employee.supplementary_locations_ids.map(id => id.toString()).includes(locationId);
  if (!isSupplementary) return false;
  if (isSupplementary && hideSupplementaryEmployees) return true;
  return shouldHideSupplementaryWithoutShift(shifts, locationId, employee.id, hideEmployeesWithoutShift);
};
export const getGroupedUserTemplates = templates => {
  const groupedObj = templates.reduce((acc, cur) => {
    const isFlex = cur.type === TEMPLATE_TYPES.FLEX;
    if (isFlex) {
      const oldItems = acc?.flex?.items || [];
      return {
        ...acc,
        flex: {
          label: scheduleMessages[TEMPLATE_TYPES.FLEX],
          items: [...oldItems, cur],
        },
      };
    }
    const oldItems = acc?.basic?.items || [];
    return {
      ...acc,
      basic: {
        label: scheduleMessages[TEMPLATE_TYPES.BASIC],
        items: [...oldItems, cur],
      },
    };
  }, {});

  return Object.values(groupedObj);
};

export const calculateScheduleTableHeight = (windowHeight, { showBudgetTable, showEventsTable }) => {
  const isLowResScreen = windowHeight <= 930;
  let height;
  if (isLowResScreen) {
    height = window.innerHeight - 150;
    if (showBudgetTable) height -= 96;
    if (height < 552) height = 552;
  } else {
    height = window.innerHeight - 150;
    if (showEventsTable) height -= 115;
    if (showBudgetTable) height -= 96;
  }
  return height;
};

export const isDateWithinEmploymentPeriod = (date, hireDate, releaseDate) => {
  let isWithin = true;
  if (hireDate && !releaseDate) {
    isWithin = hireDate <= date;
  } else if (!hireDate && releaseDate) {
    isWithin = releaseDate >= date;
  } else if (hireDate && releaseDate) {
    isWithin = hireDate <= date && releaseDate >= date;
  }
  return isWithin;
};
