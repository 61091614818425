import classnames from 'classnames';
import PropTypes from 'prop-types';

import './MDKadroModalSection.scss';

const MDKadroModalSection = ({ title, description, content, fullWidthContent, disabled }) => {
  const className = classnames('mdKadroModalSection', {
    'mdKadroModalSection--disabled': disabled,
  });
  const contentClassNames = classnames('mdKadroModalSection__content', {
    'mdKadroModalSection__content--fullWidth': fullWidthContent,
  });
  return (
    <div className={className}>
      <div className="mdKadroModalSection__descriptionContainer">
        <div className="mdKadroModalSection__title">{title}</div>
        <div className="mdKadroModalSection__description">{description}</div>
      </div>
      <div className={contentClassNames}>{content}</div>
    </div>
  );
};

MDKadroModalSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
  fullWidthContent: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDKadroModalSection;
