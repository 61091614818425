import { connect } from 'react-redux';

import { getInvoicePdf, getPaymentData, payForInvoice,toggleNotification } from '@/actions/payments.js';

import PaymentNotification from './PaymentNotification.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
  payments: state.reducer.payments,
});

const mapDispatchToProps = { getPaymentData, toggleNotification, getInvoicePdf, payForInvoice };

const PaymentNotificationContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentNotification);

export default PaymentNotificationContainer;
