import EmployeesEditMassActionSelect from '../EmployeesEditMassActionSelect.redux';
import EmployeesEditMassTypeSelect from '../EmployeesEditMassTypeSelect.redux';
import EmployeesEditMassValueField from '../EmployeesEditMassValueField';

export interface EmployeesMassEditStepProps {
  selectedType: string;
  handleTypeChange: (type: string) => void;
  selectedAction: string;
  setSelectedAction: (actionType: string) => void;
  currentFieldValue: any;
  setFieldValue: (actionType: any) => void;
}

const EmployeesMassEditStep = ({
  selectedType,
  handleTypeChange,
  selectedAction,
  setSelectedAction,
  currentFieldValue,
  setFieldValue,
}: EmployeesMassEditStepProps) => (
  <>
    <EmployeesEditMassTypeSelect selected={selectedType} onChange={handleTypeChange} />

    <EmployeesEditMassActionSelect
      selectedType={selectedType}
      selectedAction={selectedAction}
      onChange={setSelectedAction}
    />

    <EmployeesEditMassValueField
      selectedType={selectedType}
      fieldValue={currentFieldValue}
      onChange={setFieldValue}
      selectedAction={selectedAction}
    />
  </>
);

export default EmployeesMassEditStep;
