import PropTypes from 'prop-types';
import { memo } from 'react';

import './SendPanelHeader.scss';

export const SendPanelHeader = props => {
  const { onClose, title } = props;
  return (
    <div className="sendPanelHeader">
      <div className="sendPanelHeader__title">{title}</div>
      <i onClick={onClose} className="material-icons sendPanelHeader__icon" role="presentation">
        close
      </i>
    </div>
  );
};

SendPanelHeader.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default memo(SendPanelHeader);
