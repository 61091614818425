import { Component } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import SearchBox from '@/components/common/SearchBox.jsx';
import { AVAILABILITY_BLOCK_CREATE } from '@/constants/Permissions';
import { COMPANY_MANAGE_AVAILABILITIES_HIDE } from '@/constants/Restrictions';

import AvaTypesTable from './AvaTypesTable/AvaTypesTable.redux.js';

class ManageAvaTypesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
    };
    this.getSearchWord = this.getSearchWord.bind(this);
    this.clearSearchWord = this.clearSearchWord.bind(this);
  }

  getSearchWord(e) {
    this.setState({ searchWord: e.target.value });
  }

  clearSearchWord() {
    this.setState({ searchWord: '' });
  }

  render() {
    return (
      <FeatureWrapper permission={AVAILABILITY_BLOCK_CREATE} restriction={COMPANY_MANAGE_AVAILABILITIES_HIDE}>
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchWord} />
        <div className="k-wrapper animated fadeInRight">
          <AvaTypesTable searchWord={this.state.searchWord} clearSearchWord={this.clearSearchWord} />
        </div>
      </FeatureWrapper>
    );
  }
}

export default ManageAvaTypesView;
