import { createSelector } from '@reduxjs/toolkit';

import { EmployeeWhole } from '@/types';

import { RootState } from '../types';

export const selectUserEmployees = (state: RootState) => state.reducer.userEmployees;

export const selectUserEmployeeById = (id: EmployeeWhole['id']) =>
  createSelector(selectUserEmployees, userEmployees => userEmployees.find(employee => employee.id === id));
