import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getPayrollColumns as getNewPayrollColumns } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.helpers';
import { getPayrollLocationColumns as getNewPayrollLocationColumns } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.helpers';
import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice.jsx';

const TopBarMultipleColumnFilter = (props, context) => {
  const {
    onlyIcon,
    icon,
    className,
    getPayrollLocationColumns,
    getPayrollColumns,
    changePayrollLocationColumns,
    changePayrollColumns,
    visibleLocationColumns,
    visiblePayrollColumns,
    payrollType,
    visibleNewPayrollColumns,
    changeNewPayrollColumns,
    changeNewPayrollLocationColumns,
    visibleNewPayrollLocationColumns,
    payoutSettingType,
    userPermissions,
    companySettings,
  } = props;
  const getPayrollTypeObject = type => {
    const payrollObject = {
      location: {
        options: () => getPayrollLocationColumns(),
        changeFun: changePayrollLocationColumns,
        visibleColumns: visibleLocationColumns,
      },
      payroll: {
        options: () => getPayrollColumns(),
        changeFun: changePayrollColumns,
        visibleColumns: visiblePayrollColumns,
      },
      newPayroll: {
        options: () =>
          getNewPayrollColumns(userPermissions, payoutSettingType, companySettings)
            .filter(({ shouldBeHiddenForColumnFilter }) => !shouldBeHiddenForColumnFilter)
            .map(column => ({ ...column, title: column.filterTitle })),
        changeFun: changeNewPayrollColumns,
        visibleColumns: visibleNewPayrollColumns.filter(
          ({ shouldBeHiddenForColumnFilter }) => !shouldBeHiddenForColumnFilter,
        ),
      },
      newPayrollLocation: {
        options: () =>
          getNewPayrollLocationColumns(userPermissions, payoutSettingType, companySettings)
            .filter(({ shouldBeHiddenForColumnFilter }) => !shouldBeHiddenForColumnFilter)
            .map(column => ({ ...column, title: column.filterTitle })),
        changeFun: changeNewPayrollLocationColumns,
        visibleColumns: visibleNewPayrollLocationColumns.filter(
          ({ shouldBeHiddenForColumnFilter }) => !shouldBeHiddenForColumnFilter,
        ),
      },
    };
    return payrollObject[type];
  };
  const [options, setOptions] = useState(getPayrollTypeObject(payrollType).options());

  useEffect(() => {
    const newOptions = getPayrollTypeObject(payrollType).options();
    setOptions(newOptions);
  }, [payoutSettingType, userPermissions, payrollType]);

  const changeFunc = useCallback(
    e => {
      const checkedIds = e.map(checked => checked.id);
      getPayrollTypeObject(payrollType).changeFun(options.filter(option => checkedIds.includes(option.id)));
    },
    [payrollType, userPermissions, payoutSettingType, visibleNewPayrollColumns, visibleNewPayrollLocationColumns],
  );

  const selectedColumns = getPayrollTypeObject(payrollType).visibleColumns;
  const relevantClassNames = classNames('k-topBarChoice--multipleColumnFilter', className);
  const wrapperClassName = classNames('k-topBarMultipleColumnFilter', {
    'k-topBarMultipleColumnFilter--onlyIcon': onlyIcon,
  });

  const allOptions = options.map(option => ({
    title: context.intl.formatMessage(option.title),
    id: option.id,
  }));
  const selectedOptions = selectedColumns.map(option => ({
    title: context.intl.formatMessage(option.title),
    id: option.id,
  }));

  return (
    <div className={wrapperClassName}>
      <TopBarMultipleChoice
        disableDefaultSort
        filterName={<FormattedMessage id="topbar.multipleColumnFilter.title" defaultMessage="Kolumny" />}
        objectsToChoose={allOptions}
        selectedObjects={selectedOptions}
        onChange={changeFunc}
        className={relevantClassNames}
        displayKey="title"
        onlyIcon={onlyIcon}
        icon={icon}
      />
    </div>
  );
};

TopBarMultipleColumnFilter.contextTypes = {
  intl: PropTypes.shape({}),
};

TopBarMultipleColumnFilter.propTypes = {
  onlyIcon: PropTypes.bool,
  icon: PropTypes.node,
  className: PropTypes.string,
  getPayrollLocationColumns: PropTypes.func,
  getPayrollColumns: PropTypes.func,
  changePayrollLocationColumns: PropTypes.func,
  changePayrollColumns: PropTypes.func,
  visibleLocationColumns: PropTypes.arrayOf(PropTypes.shape({})),
  visiblePayrollColumns: PropTypes.arrayOf(PropTypes.shape({})),
  payrollType: PropTypes.string,
  visibleNewPayrollColumns: PropTypes.arrayOf(PropTypes.shape({})),
  changeNewPayrollColumns: PropTypes.func,
  changeNewPayrollLocationColumns: PropTypes.func,
  visibleNewPayrollLocationColumns: PropTypes.arrayOf(PropTypes.shape({})),
  payoutSettingType: PropTypes.string,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  companySettings: PropTypes.shape({}),
};

export default TopBarMultipleColumnFilter;
