import ScheduleTableSingleDayTradeShift from '@/components/scheduler/ScheduleTable/ScheduleTableTradeShift/ScheduleTableSingleDayTradeShift/ScheduleTableSingleDayTradeShift.redux';
import ScheduleTableTradeShift from '@/components/scheduler/ScheduleTable/ScheduleTableTradeShift/ScheduleTableTradeShift.redux.js';
import { Location } from '@/types';

import ScheduleTableHeader from '../ScheduleTableHeader/ScheduleTableHeader.redux.js';
import ScheduleTableGraphsHeader from '../ScheduleTableHeaderGraphs/ScheduleTableGraphsHeader.redux.js';
import ScheduleTableLoanEmployeesProposal from '../scheduleTableLoanEmployeesProposals/ScheduleTableLoanEmployeesProposals.redux.js';
import ScheduleTableOpenShift from '../ScheduleTableOpenShift/ScheduleTableOpenShiftRow/ScheduleTableOpenShiftRow.redux';
import ScheduleTableSingleDayOpenShift from '../ScheduleTableOpenShift/ScheduleTableSingleDayOpenShiftRow/ScheduleTableSingleDayOpenShiftRow.redux';
import ScheduleTableWeather from '../ScheduleTableWeather/ScheduleTableWeatherRow/ScheduleTableWeatherRow.redux.js';
import { useScheduleTableHead } from './useScheduleTableHead';

type Props = {
  locationId: Location['id'];
};

export const ScheduleTableHead = ({ locationId }: Props) => {
  const {
    areChartsShown,
    areLoanEmployeesProposalShown,
    isSingleDay,
    isOpenShiftsShown,
    isWeatherForecastShown,
    isTradeShiftsShown,
    scheduleBlockSize,
  } = useScheduleTableHead(locationId);

  return (
    <thead>
      {areChartsShown && <ScheduleTableGraphsHeader locationId={locationId} />}
      <ScheduleTableHeader size={scheduleBlockSize} locationId={locationId} />
      {isWeatherForecastShown && <ScheduleTableWeather locationId={locationId} />}
      {isTradeShiftsShown &&
        (isSingleDay ? (
          <ScheduleTableSingleDayTradeShift locationId={locationId} />
        ) : (
          <ScheduleTableTradeShift locationId={locationId} />
        ))}
      {isOpenShiftsShown &&
        (isSingleDay ? (
          <ScheduleTableSingleDayOpenShift locationId={locationId} />
        ) : (
          <ScheduleTableOpenShift locationId={locationId} />
        ))}
      {areLoanEmployeesProposalShown && <ScheduleTableLoanEmployeesProposal locationId={locationId} />}
    </thead>
  );
};
