import { RootState } from '@/redux-store/types';
import { Location } from '@/types';

export const selectIsEventsTableShown = (state: RootState) => state.reducer.schedule.viewSettings.events?.value;

export const selectIsWeatherForecastShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.showWeatherForecast?.value;

export const selectAreLoanEmployeesProposalsShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.loanEmployeesProposals?.value;

export const selectAreLoanedEmployeesShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.loanedEmployees?.value;

export const selectAreScheduleChartsShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.budgetMetrics?.value ||
  state.reducer.schedule.viewSettings.employeesAmount?.value;

export const selectAreSupplementaryEmployeesShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.supplementaryEmployees?.value;

export const selectAreSupplementaryEmployeesWithoutShiftsShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.showSupplementaryEmployeesWithoutShift?.value;

export const selectAreEmployeesWithoutShiftsShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.showEmployeesWithoutShifts?.value;

export const selectAreShiftsFromOtherLocationsShown = (state: RootState) =>
  state.reducer.schedule.viewSettings.showShiftsFromOtherLocations?.value;

export const selectAreTradeShiftsShownForLocation = (locationId: Location['id']) => (state: RootState) =>
  state.reducer.schedule.viewSettings.tradeShifts?.value &&
  state.reducer.settings.locationSettings[locationId]?.enable_trade_shift;

export const selectAreOpenShiftsShownForLocation = (locationId: Location['id']) => (state: RootState) =>
  state.reducer.schedule.viewSettings.openShifts?.value &&
  state.reducer.settings.locationSettings[locationId]?.enable_open_shift;
