import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { optionType, scheduleGenerationOptions } from '@/constants/autoSchedule';
import { getRangeArray } from '@/utils/arrayHelpers';
import {
  convertDateToStandardFormat,
  getNextRoundedHourForTime,
  getPreviousRoundedHourForTime,
  getStartAndEndTimestamp,
  singleHourToFormattedTime,
} from '@/utils/dateHelper';

import { pad } from './baseHelpers';

export const generateOptions = () => Object.values(scheduleGenerationOptions).filter(opt => opt.isConfigurationOption);

export const getGroupedOptions = options =>
  Object.values(options).reduce(
    (acc, cur) => {
      if (cur.isOtherOption) {
        return { ...acc, otherOptions: [...acc.otherOptions, cur] };
      }
      return { ...acc, generationOptions: [...acc.generationOptions, cur] };
    },
    { generationOptions: [], otherOptions: [] },
  );

export const getConfigurationOptions = configuration => {
  const allConfiguration = (configuration || generateOptions()).reduce((acc, it) => {
    let option;
    switch (it.type) {
      case optionType.TOGGLE:
        option = { enabled: it.enabled };
        break;
      case optionType.INTEGER:
        if (!it.enabled) return acc;
        option = { enabled: true, value: it.value };
        break;
      case optionType.SINGLE_SLIDER:
        if (!it.enabled) return acc;
        option = { enabled: true, value: it.value };
        break;
      default:
        return acc;
    }
    return {
      ...acc,
      [it.name]: option,
    };
  }, {});

  if (allConfiguration.optimize_job_category_distribution_rule) {
    allConfiguration.optimize_job_category_distribution_rule = {
      value: parseInt(allConfiguration.optimize_job_category_distribution_rule.value),
      enabled: true,
    };
  } else {
    allConfiguration.optimize_job_category_distribution_rule = { enabled: false, value: 0 };
  }

  allConfiguration.fair_job_distribution = { enabled: false };

  return allConfiguration;
};

export const getFormattedScheduleTemplate = scheduleTemplate => {
  const formattedSchedule = scheduleTemplate.reduce((acc, cur) => {
    const index = moment(cur.date).isoWeekday() - 1;
    return { ...acc, [index]: cur };
  }, {});
  return Array.from({ length: 7 }, (v, k) => formattedSchedule[k]);
};
export const createFlexScheduleDay = (selectedJobTitles, dayRecommendedSchedule) =>
  selectedJobTitles.reduce((acc, jobTitle) => {
    const jobTitleTemplate =
      dayRecommendedSchedule && dayRecommendedSchedule.template.find(templ => templ.job_title_id === jobTitle.id);
    return {
      ...acc,
      [jobTitle.id]: Array.from(
        { length: 24 },
        (v, k) => (jobTitleTemplate && jobTitleTemplate.values[`${pad(k)}:00`]) || '',
      ),
    };
  }, {});

export const createFlexScheduleGrid = (jobTitles, scheduleTemplate) => {
  const formattedTemplate = getFormattedScheduleTemplate(scheduleTemplate);
  return Array.from({ length: 7 }, (v, k) => k).reduce(
    (acc, dayIndex) => ({
      ...acc,
      [dayIndex]: createFlexScheduleDay(jobTitles, formattedTemplate[dayIndex]),
    }),
    {},
  );
};

export const userTemplateToRecommendedSchedule = userTemplate => {
  const result = userTemplate.reduce((acc, cur) => {
    const jobTitleId = cur.job_title_id || cur.job_title?.id;
    const formattedDate = moment()
      .startOf('week')
      .add('days', cur.date)
      .format('YYYY-MM-DD');
    const template = acc[cur.date]?.template || {};
    const values = template[jobTitleId]?.values || {};
    const valueKey = cur.working_hours.split('-')[0];

    return {
      ...acc,
      [cur.date]: {
        date: formattedDate,
        template: {
          ...template,
          [jobTitleId]: { job_title_id: jobTitleId, values: { ...values, [valueKey]: cur.amount } },
        },
      },
    };
  }, {});

  return Object.values(result).map(item => ({
    ...item,
    template: Object.values(item.template),
  }));
};

export const validateOptions = options => {
  const minDaysInRowRule = options.find(option => option.name === 'min_working_days_in_row');
  const maxDaysInRowRule = options.find(option => option.name === 'max_working_days_in_row');
  if (
    minDaysInRowRule &&
    maxDaysInRowRule &&
    minDaysInRowRule.enabled &&
    maxDaysInRowRule.enabled &&
    Number(minDaysInRowRule.value) > Number(maxDaysInRowRule.value)
  ) {
    return (
      <FormattedMessage
        id="schedule.autoGenerateOptions.minMaxDaysInRowError"
        defaultMessage="Minimalna ilość dni pod rząd nie może być większa niż maksymalna ilość."
      />
    );
  } else if (maxDaysInRowRule && maxDaysInRowRule.enabled && Number(maxDaysInRowRule.value) === 0) {
    return (
      <FormattedMessage
        id="schedule.autoGenerateOptions.zeroMaxDaysError"
        defaultMessage="Maksymalna ilość dni pod rząd nie może być równa 0."
      />
    );
  }
  return null;
};

export const getStepsModalModifiers = step => {
  switch (step) {
    case 0:
      return ['very-wide'];
    case 1:
      return ['narrow'];
    case 2:
      return ['extra-wide'];
    case 3:
      return ['wide'];
    default:
      return [];
  }
};
const getSplittedShifts = (shift, start, end, increaseDate = false) =>
  getRangeArray(start, end - 1).map(hour => {
    const endHour = hour < 23 ? hour + 1 : 0;
    const workingHours = `${singleHourToFormattedTime(hour)}-${singleHourToFormattedTime(endHour)}`;
    const newDate = increaseDate ? convertDateToStandardFormat(moment(shift.date).add(1, 'd')) : shift.date;
    const [startTimestamp, endTimestamp] = getStartAndEndTimestamp(workingHours, newDate);
    return {
      ...shift,
      workingHours,
      startTimestamp,
      endTimestamp,
      date: newDate,
    };
  });

export const formatExistingShiftsForFlexibleTemplate = shifts => {
  const splittedShifts = [];
  shifts.forEach(s => {
    const [start, end] = s.workingHours.split('-');
    const [roundedStartHour, roundedEndHour] = [getNextRoundedHourForTime(start), getPreviousRoundedHourForTime(end)];

    if (roundedStartHour + 1 === roundedEndHour && !(start.endsWith(':00') && end.endsWith(':00'))) return;
    if (start < end) {
      splittedShifts.push(...getSplittedShifts(s, roundedStartHour, roundedEndHour));
    } else {
      splittedShifts.push(...getSplittedShifts(s, roundedStartHour, 24));
      splittedShifts.push(...getSplittedShifts(s, 0, roundedEndHour, true));
    }
  });
  return splittedShifts;
};
