import { connect } from 'react-redux';

import LocationSelect from './LocationSelect';

const mapStateToProps = state => ({
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userLocations: state.reducer.userLocations,
});

export default connect(mapStateToProps)(LocationSelect);
