import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import EmployeeModalContracts from '@/components/companymanage/employees/EmployeeModal/EmployeeModalContracts/EmployeeModalContracts.redux';
import EmployeeModalLocations from '@/components/companymanage/employees/EmployeeModal/EmployeeModalLocations/EmployeeModalLocations.redux';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { MEDIUM, SCROLL_CONTENT } from '@/constants/modalModifiers.js';
import {
  AVAILABILITY_LIMITS_VIEW,
  CONTRACTS_EDIT_SHOW,
  CUSTOM_FIELDS_SHOW,
  EMPLOYMENT_CONDITIONS_CREATE,
  HIRE_RELEASE_DATE_SHOW,
  SUPPLEMENTARY_LOCATIONS_VIEW,
  VALIDATE_REF_ID,
} from '@/constants/Permissions';
import { CONTRACTS_EDIT_HIDE } from '@/constants/Restrictions';
import { createLimits, getInitialLimitsState } from '@/utils/absenceHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { getCustomFieldsForEmployee } from '@/utils/customFields/customFields.ts';
import { workingHoursToMinutes, workingMinutesToHours } from '@/utils/employmentConditionsHelpers';
import { inputValidation } from '@/utils/inputValidation.js';
import { getRolesAvailableForCurrentUser } from '@/utils/userEmployeesHelpers';

import AbsenceLimitsTab from '../AbsenceLimitsTab/AbsenceLimitsTab.redux.js';
import CustomFieldsTab from '../customFieldsTab/CustomFieldsTab.tsx';
import EmployeeModalBasic from '../EmployeeModaBasic/EmployeeModalBasic.jsx';
import EmployeeModalAdditional from '../EmployeeModalAdditional/EmployeeModalAdditional.jsx';
import EmployeeModalConditions from '../EmployeeModalConditions/EmployeeModalConditions.jsx';
import { employeeSidebarTabs } from '../EmployeeSidebar/tabs';
import {
  changeVariablesTypes,
  formFields,
  getInitialState,
  getSupplementaryLocationIds,
} from './EmployeeModal.helpers';
import { messages } from './EmployeeModal.messages';

import './EmployeeModal.scss';

class EmployeeModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = getInitialState(
      props.absencesTypes,
      props.absencesLimitTemplates,
      props.companyRoles,
      props.userPermissions.restrictions,
      props.customFieldsDefinition,
      props.userCustomFields,
    );
    bindPrototypeFunctions(this);
  }

  async componentWillReceiveProps(nextProps) {
    const {
      absencesTypes,
      absencesLimitTemplates,
      companyRoles,
      userPermissions,
      customFieldsDefinition,
      userCustomFields,
    } = nextProps;

    if (nextProps.modalObject.id) {
      /* eslint-disable camelcase */
      const {
        id,
        first_name,
        last_name,
        inactive,
        role,
        email,
        phone,
        phone_prefix,
        pin,
        locations,
        reference_id,
        employment_conditions,
        supplementary_locations_ids,
        alias,
        nfc_code,
        role_id,
      } = nextProps.modalObject;
      const {
        template_id,
        weekly_working_minutes,
        max_daily_working_minutes,
        validate_working_rules,
        elastic_work_day,
        show_absences,
        is_medical,
        allowed_to_work_at_night_hours,
        schedule_cycle,
        hire_date,
        release_date,
        availability_limits,
      } = employment_conditions;

      if (this.props.modalObject.id !== nextProps.modalObject.id) {
        this.props.getAbsencesLimitsEmployee(nextProps.modalObject.id);
      }
      this.setState(prev => {
        const limits = getInitialLimitsState(absencesTypes, absencesLimitTemplates);
        const baseTemplate = {
          employmentConditionsTemplateId: template_id,
          weeklyWorkingMinutes:
            !weekly_working_minutes && weekly_working_minutes !== 0
              ? prev.weeklyWorkingMinutes
              : workingMinutesToHours(weekly_working_minutes),
          maxDailyWorkingMinutes:
            !max_daily_working_minutes && max_daily_working_minutes !== 0
              ? prev.maxDailyWorkingMinutes
              : workingMinutesToHours(max_daily_working_minutes),
          validateWorkingRules: validate_working_rules,
          elasticWorkDay: elastic_work_day,
          hireDate: hire_date ? [hire_date] : [],
          releaseDate: release_date ? [release_date] : [],
          showAbsences: show_absences,
          isMedical: is_medical,
          allowedToWorkAtNightHours: allowed_to_work_at_night_hours,
          scheduleCycleStart: {
            month: schedule_cycle?.month || prev.scheduleCycleStart.month,
            year: schedule_cycle?.year || prev.scheduleCycleStart.year,
          },
          scheduleCycleDuration: schedule_cycle?.duration || prev.scheduleCycleDuration,
          availabilityLimits: availability_limits || prev.availabilityLimits,
        };
        return {
          id,
          name: first_name,
          lastName: last_name,
          inactive,
          role,
          role_id,
          manager: role === 'manager',
          email2: email,
          phone,
          phone_prefix,
          pin4: pin,
          referenceId: reference_id,
          locations,
          alias,
          nfcCode: nfc_code,
          supplementaryLocations: supplementary_locations_ids,
          changedTemplate: this.didTemplateChange(baseTemplate),
          ...baseTemplate,
          limits,
          contracts: nextProps.employeeContracts,
          customFields:
            !isEmpty(userCustomFields) && getCustomFieldsForEmployee(customFieldsDefinition, userCustomFields),
        };
      });
    } else {
      this.setState(
        getInitialState(
          absencesTypes,
          absencesLimitTemplates,
          companyRoles,
          userPermissions.restrictions,
          customFieldsDefinition,
          userCustomFields,
        ),
      );
      if (nextProps.showModal) {
        const pin4 = await this.props.getFreePin();
        this.setState({ pin4 });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { modalObject, userPermissions } = this.props;
    const { id: employeeId } = modalObject;
    const { permissions } = userPermissions;
    const { id: prevEmployeeId } = prevProps.modalObject;
    if (employeeId && employeeId !== prevEmployeeId && permissions.includes(CUSTOM_FIELDS_SHOW)) {
      try {
        await this.props.getUsersCustomFields([employeeId]);
      } catch (e) {
        this.setState({
          errors: {
            ...this.state.errors,
            employeeDataRetrievalError: this.context.intl.formatMessage(messages.employeeDataRetrievalError),
          },
        });
      }
    }
  }

  getCurrentTemplateValues() {
    return {
      templateId: this.state.employmentConditionsTemplateId,
      weeklyWorkingMinutes: this.state.weeklyWorkingMinutes,
      maxDailyWorkingMinutes: this.state.maxDailyWorkingMinutes,
      validateWorkingRules: this.state.validateWorkingRules,
      elasticWorkDay: this.state.elasticWorkDay,
      scheduleCycleStart: this.state.scheduleCycleStart,
      scheduleCycleDuration: this.state.scheduleCycleDuration === '' ? 1 : parseInt(this.state.scheduleCycleDuration),
      availabilityLimits: this.state.availabilityLimits,
      showAbsences: this.state.showAbsences,
      isMedical: this.state.isMedical,
      allowedToWorkAtNightHours: this.state.allowedToWorkAtNightHours,
      hireDate: this.state.hireDate,
      releaseDate: this.state.releaseDate,
    };
  }

  getEmploymentConditionTemplate(templateId) {
    const employmentCondition = this.props.employmentConditions.find(condition => condition.id === templateId);
    if (!employmentCondition) return {};
    return {
      templateId: employmentCondition.id,
      weeklyWorkingMinutes: workingMinutesToHours(employmentCondition.weekly_working_minutes),
      maxDailyWorkingMinutes: workingMinutesToHours(employmentCondition.max_daily_working_minutes),
      validateWorkingRules: employmentCondition.validate_working_rules,
      elasticWorkDay: employmentCondition.elastic_work_day,
      showAbsences: employmentCondition.show_absences,
      isMedical: employmentCondition.is_medical,
      allowedToWorkAtNightHours: employmentCondition.allowed_to_work_at_night_hours,
      scheduleCycleStart: {
        month: employmentCondition?.schedule_cycle?.month || moment().month() + 1,
        year: employmentCondition?.schedule_cycle?.year || moment().year(),
      },
      scheduleCycleDuration: employmentCondition?.schedule_cycle?.duration || 1,
      availabilityLimits: employmentCondition.availability_limits ? employmentCondition.availability_limits : [],
      hireDate: employmentCondition.hire_date ? [employmentCondition.hire_date] : [],
      releaseDate: employmentCondition.release_date ? [employmentCondition.release_date] : [],
    };
  }

  createEmployeeObject() {
    const newContracts = this.state.contracts.reduce((acc, contract) => {
      const newContract = {
        id: contract.isTemp ? undefined : contract.id,
        start_date: contract.start_date,
        job_titles: contract.job_titles,
      };
      acc.push(newContract);
      return acc;
    }, []);

    const newLocations = this.state.locations.reduce((prev, location) => {
      const newLocation = {
        id: location.id,
        color: location.color,
        name: location.name,
        slug: location.slug,
        pin: location.pin,
        city: location.city,
        address: location.address,
        company_id: location.company_id,
        opening_hours: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
      };
      return [...prev, newLocation];
    }, []);

    const { role_id } = this.state;
    const isManager =
      role_id && !['employee', 'manager'].includes(role_id)
        ? this.props.companyRoles.find(companyRole => companyRole.id === this.state.role_id)?.is_manager
        : role_id === 'manager';

    const employmentConditions = this.getCurrentTemplateValues();
    return {
      first_name: this.state.name.trim(),
      last_name: this.state.lastName.trim(),
      contracts: newContracts,
      locations: newLocations,
      supplementary_locations: isManager
        ? []
        : getSupplementaryLocationIds(this.state.manager, this.state.supplementaryLocations),
      role: isManager ? 'manager' : 'employee',
      role_id: this.state.role_id,
      email: this.state.email2,
      phone: this.state.phone,
      phone_prefix: this.state.phone_prefix,
      pin: this.state.pin4,
      reference_id: this.state.referenceId,
      alias: this.state.alias,
      nfc_code: this.state.nfcCode,
      inactive: this.state.inactive,
      company_id: this.props.currentCompany.id,
      employment_conditions: {
        template_id: employmentConditions.templateId,
        weekly_working_minutes: workingHoursToMinutes(employmentConditions.weeklyWorkingMinutes),
        max_daily_working_minutes: workingHoursToMinutes(employmentConditions.maxDailyWorkingMinutes),
        availability_limits: this.state.availabilityLimits,
        schedule_cycle: {
          month: employmentConditions.scheduleCycleStart.month,
          year: employmentConditions.scheduleCycleStart.year,
          duration: employmentConditions.scheduleCycleDuration,
        },
        validate_working_rules: employmentConditions.validateWorkingRules,
        elastic_work_day: employmentConditions.elasticWorkDay,
        show_absences: employmentConditions.showAbsences,
        is_medical: employmentConditions.isMedical,
        allowed_to_work_at_night_hours: employmentConditions.allowedToWorkAtNightHours,
        hire_date: employmentConditions.hireDate[0] || null,
        release_date: employmentConditions.releaseDate[0] || null,
      },
    };
  }

  async submit() {
    const valid = await this.validateAll();
    if (!valid) {
      this.setState({ errorMessage: this.context.intl.formatMessage(messages.errorsInForm) });
      return;
    }
    let submitPromise = null;
    const { email2, pin4, sendInvitation, limits: limitsFromState, changedLimits } = this.state;
    const { inviteEmployee, modalObject, absencesTypes, changeEmployee, addEmployee } = this.props;
    const limits = createLimits(absencesTypes, limitsFromState, changedLimits);
    const isEditingEmployee = modalObject.id || modalObject.id === 0;
    if (isEditingEmployee) {
      const { id } = modalObject;
      const employeeObject = this.createEmployeeObject();
      submitPromise = changeEmployee({
        id,
        ...employeeObject,
        limits,
      });
    } else {
      submitPromise = addEmployee({
        ...this.createEmployeeObject(),
        limits,
      });
    }
    this.setState({ loading: true });
    submitPromise
      .then(() => {
        if (!isEditingEmployee && sendInvitation && email2) {
          inviteEmployee(email2, pin4);
        }
        this.hideAndClear();
      })
      .catch(err => {
        const newError = err.response.data.message;
        const errors = {};

        switch (newError) {
          case 'Pin must be unique for employees of given company':
            errors.pin4 = this.context.intl.formatMessage(messages.usedPin, {});
            break;
          case 'The email is already used':
            errors.email2 = this.context.intl.formatMessage(messages.usedEmail, {});
            break;
          default:
            break;
        }

        this.setState({ errors });
      })
      .finally(() => this.setState({ loading: false }));
  }

  changeTab(id) {
    this.setState({
      activeTab: id,
    });
  }

  hideAndClear() {
    this.props.onHide();
    this.props.resetAbsencesLimits();
    this.setState(getInitialState());
  }

  didTemplateChange(changedKeyValues) {
    const currentTemplateValues = {
      ...this.getCurrentTemplateValues(),
      ...changedKeyValues,
      templateId: changedKeyValues.employmentConditionsTemplateId,
    };
    const baseTemplate = this.getEmploymentConditionTemplate(currentTemplateValues.templateId);

    return formFields.employmentConditions.some(field => !isEqual(baseTemplate[field], currentTemplateValues[field]));
  }

  handleInputChange(event) {
    const { target } = event;
    let templateHasChanged = this.state.changedTemplate;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.name === 'inactive') {
      value = !target.checked;
    }
    const { name } = target;

    if (this.state.errors[name] && this.state.employmentConditionsTemplateId !== 'uoz') {
      this.validateInput(event);
    }

    if (formFields.employmentConditions.includes(name)) {
      templateHasChanged = this.didTemplateChange({
        [name]: changeVariablesTypes(name, value),
      });
    }

    this.setState({
      [name]: value,
      changedTemplate: templateHasChanged,
    });
  }

  handleLimitChange(value, id) {
    this.setState(prevState => ({
      limits: {
        ...prevState.limits,
        [id]: value,
      },
      changedLimits: !prevState.changedLimits.includes(id) ? [...prevState.changedLimits, id] : prevState.changedLimits,
    }));
  }

  handleSelectChange(conditionId) {
    const baseTemplate = this.getEmploymentConditionTemplate(conditionId);
    this.setState(prevState => ({
      ...baseTemplate,
      employmentConditionsTemplateId: baseTemplate.templateId,
      changedTemplate: false,
      errors: {
        ...prevState.errors,
        maxDailyWorkingMinutes: '',
        scheduleCycleDuration: '',
        employmentConditionsTemplateId: '',
      },
    }));
  }

  validateInput(event) {
    return new Promise(resolve => {
      let { valueToCompare, required } = event;
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;

      if (name === 'nfcCode' && !valueToCompare) {
        valueToCompare = this.props.userEmployees
          .filter(employee => employee.id !== this.state.id && employee.nfc_code)
          .map(employee => employee.nfc_code);
      }
      if (!this.props.userPermissions.permissions.includes(VALIDATE_REF_ID) && name === 'referenceId') {
        required = false;
      }

      if (name === 'phone') {
        required = false;
      }

      if (name === 'phone' && !valueToCompare) {
        valueToCompare = this.state.phone_prefix;
      }

      const error = inputValidation(name, value, { valueToCompare, required });
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
          errorMessage: error ? this.context.intl.formatMessage(messages.errorsInForm) : '',
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  async validateAll() {
    const {
      userPermissions: { permissions, restrictions },
      currentCompany: {
        status: { status: companyStatus },
      },
    } = this.props;
    const inputs = ['name', 'lastName', 'email2', 'phone', 'pin4', 'referenceId'];
    if (this.props.isManager) inputs.push('locations');
    if (permissions.includes(EMPLOYMENT_CONDITIONS_CREATE) && this.state.employmentConditionsTemplateId !== 'uoz') {
      inputs.push(
        'maxDailyWorkingMinutes',
        'weeklyWorkingMinutes',
        'scheduleCycleDuration',
        'employmentConditionsTemplateId',
      );
    }
    if (permissions.includes(AVAILABILITY_LIMITS_VIEW)) {
      inputs.push('availabilityLimits');
    }
    if (permissions.includes(CONTRACTS_EDIT_SHOW) && !restrictions.includes(CONTRACTS_EDIT_HIDE)) {
      inputs.push('contractStartDate');
    }
    for (const i of inputs) {
      if (i === 'weeklyWorkingMinutes') {
        /* eslint-disable no-await-in-loop */
        await this.validateInput({
          target: { name: i, value: this.state[i] },
          valueToCompare: this.state.maxDailyWorkingMinutes,
        });
      } else if (i === 'referenceId') {
        const required = permissions.includes(VALIDATE_REF_ID);
        await this.validateInput({
          target: { name: i, value: this.state[i] },
          required,
        });
      } else if (i === 'contractStartDate') {
        await this.validateInput({
          target: { name: i, value: this.state.contracts },
        });
      } else {
        await this.validateInput({ target: { name: i, value: this.state[i] } });
      }
    }
    const areInputsValid = Object.values(this.state.errors).every(err => !err);

    if (companyStatus !== 'trial') {
      return areInputsValid;
    }

    const selectedAtLeastOneLocation = this.state.locations.length > 0;
    const selectedAtLeastOneJobTitle = this.state.contracts.some(contract => contract.job_titles.length > 0);
    this.setState(prev => ({
      errors: {
        ...prev.errors,
        locations: selectedAtLeastOneLocation ? '' : 'noLocationSelected',
        jobTitles: selectedAtLeastOneJobTitle ? '' : 'noJobTitleSelected',
      },
    }));

    return areInputsValid && selectedAtLeastOneLocation && selectedAtLeastOneJobTitle;
  }

  updateContract(contractId, fieldName, value) {
    const newContracts = this.state.contracts.map(contract => {
      if (contract.id === contractId) {
        return {
          ...contract,
          [fieldName]: value,
        };
      }
      return contract;
    });

    this.setState(() => ({
      contracts: newContracts,
    }));

    if (fieldName === 'start_date') {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          contractStartDate: '',
        },
      }));
    }
  }

  addContract(tempId, jobTitles) {
    const newContracts = [
      ...this.state.contracts,
      {
        id: tempId,
        start_date: null,
        job_titles: jobTitles || [],
        isTemp: true,
      },
    ].sort((a, b) => {
      if (!a.start_date) {
        return -1;
      }
      if (!b.start_date) {
        return 1;
      }
      return a.start_date > b.start_date ? 1 : -1;
    });
    this.setState(prev => ({
      contracts: newContracts,
      errors: {
        ...prev.errors,
      },
    }));
  }

  displayRelevantTab() {
    switch (this.state.activeTab) {
      case 0: {
        const { name, lastName, email2, errors, sendInvitation } = this.state;
        const { currentUser, companyRoles, userPermissions, modalObject, currentCompany } = this.props;
        const { same_rank_is_visible } = currentCompany.settings;
        const selectOptions = getRolesAvailableForCurrentUser(
          currentUser,
          companyRoles,
          userPermissions,
          modalObject.id,
          same_rank_is_visible,
        ).map(role => ({
          key: role.name || this.context.intl.formatMessage(messages[role.id], {}),
          value: role.id,
        }));

        let roleId = this.state.role_id || this.state.role;
        if (roleId === 'pracownik') {
          roleId = 'employee';
        }

        return (
          <EmployeeModalBasic
            name={name}
            lastName={lastName}
            email2={email2}
            handleInputChange={this.handleInputChange}
            validateInput={this.validateInput}
            errors={errors}
            selectOptions={selectOptions}
            role={roleId}
            showSendInvitation={modalObject.showSendInvitation}
            sendInvitation={sendInvitation}
          />
        );
      }
      case 1: {
        const { locations, supplementaryLocations, role_id } = this.state;
        const { userPermissions } = this.props;

        const isManager =
          role_id && !['employee', 'manager'].includes(role_id)
            ? this.props.companyRoles.find(companyRole => companyRole.id === this.state.role_id)?.is_manager
            : role_id === 'manager';

        return (
          <EmployeeModalLocations
            locations={locations}
            supplementaryLocations={supplementaryLocations}
            showSupplementaryLocations={userPermissions.permissions.includes(SUPPLEMENTARY_LOCATIONS_VIEW)}
            isManager={isManager}
            handleInputChange={this.handleInputChange}
          />
        );
      }
      case 2: {
        const { contracts } = this.state;

        return (
          <EmployeeModalContracts
            contracts={contracts}
            updateContract={this.updateContract}
            addContract={this.addContract}
            startDateError={this.state.errors.contractStartDate}
            changeTab={this.changeTab}
          />
        );
      }
      case 3: {
        const {
          weeklyWorkingMinutes,
          maxDailyWorkingMinutes,
          scheduleCycleStart,
          validateWorkingRules,
          elasticWorkDay,
          showAbsences,
          isMedical,
          allowedToWorkAtNightHours,
          scheduleCycleDuration,
          hireDate,
          releaseDate,
          errors,
          sendInvitation,
        } = this.state;
        const { userPermissions } = this.props;
        const showHireAndReleaseDate = userPermissions.permissions.includes(HIRE_RELEASE_DATE_SHOW);
        let selectOptions = [
          ...this.props.employmentConditions.map(condition => ({
            key: condition.name,
            value: condition.id,
          })),
        ];
        let defaultValue = 0;
        const defaultOption = selectOptions.find(option => option.value === this.state.employmentConditionsTemplateId);
        if (this.state.employmentConditionsTemplateId === -1) {
          defaultValue = this.state.employmentConditionsTemplateId;
        } else if (this.state.employmentConditionsTemplateId === '' || !defaultOption) {
          selectOptions = [{ key: 'Własny', value: 0 }, ...selectOptions];
        } else {
          defaultValue = defaultOption.value;
        }
        return (
          <EmployeeModalConditions
            conditionsSelectOptions={selectOptions}
            handleSelectChange={this.handleSelectChange}
            conditionsDefaultValue={defaultValue}
            templateId={this.state.employmentConditionsTemplateId}
            {...{
              weeklyWorkingMinutes,
              maxDailyWorkingMinutes,
              scheduleCycleStart,
              scheduleCycleDuration,
              validateWorkingRules,
              elasticWorkDay,
              hireDate,
              releaseDate,
              showAbsences,
              errors,
              showHireAndReleaseDate,
              sendInvitation,
              isMedical,
              allowedToWorkAtNightHours,
            }}
            handleInputChange={this.handleInputChange}
            validateInput={this.validateInput}
            errors={errors}
          />
        );
      }
      case 4: {
        const { phone, phone_prefix, pin4, referenceId, alias, nfcCode, inactive, errors } = this.state;
        return (
          <EmployeeModalAdditional
            phone={phone}
            phonePrefix={phone_prefix}
            pin4={pin4}
            referenceId={referenceId}
            alias={alias}
            nfcCode={nfcCode}
            inactive={inactive}
            inactiveDisabled={this.isInactiveDisabled()}
            isInactiveHidden={!this.props.modalObject.id}
            handleInputChange={this.handleInputChange}
            validateInput={this.validateInput}
            errors={errors}
          />
        );
      }
      case 5:
        return <AbsenceLimitsTab limits={this.state.limits} handleLimitChange={this.handleLimitChange} />;
      case 6: {
        const { errors } = this.state;
        const { userCustomFields } = this.props;

        return !isEmpty(userCustomFields) ? (
          <CustomFieldsTab customFields={this.state.customFields} />
        ) : (
          !errors.employeeDataRetrievalError && (
            <EmptyState
              name="scheduleCTA"
              imgSrc="/img/scheduleCTA.png"
              emptyStateHeader={this.context.intl.formatMessage(messages.emptyStateHeader)}
              emptyStateText={this.context.intl.formatMessage(messages.emptyStateText)}
              className="employeeModal__emptyState"
              modifiers={['modal']}
            />
          )
        );
      }
      default:
        return null;
    }
  }

  isInactiveDisabled() {
    const userId = this.props.userPermissions.user_id;
    const { id } = this.props.modalObject;

    return Number(userId) === Number(id) && this.props.isManager;
  }

  render() {
    const { state, props, context } = this;
    const { modalObject, showModal, userPermissions } = props;

    const allTabs = employeeSidebarTabs(
      context.intl,
      state.errors,
      userPermissions.restrictions,
      state.showAbsences,
      userPermissions.permissions,
    );
    const title = allTabs[state.activeTab].name;

    return (
      <MDKadroModal
        show={showModal}
        onHide={this.hideAndClear}
        modifiers={[MEDIUM]}
        title={title}
        onSubmit={this.submit}
        disableConfirm={state.loading || state.errors.contractStartDate}
        errorMessage={state.errorMessage}
        contentErrorMessage={state.activeTab === 6 && state.errors.employeeDataRetrievalError}
        showSidebar
        sidebarContent={<div className="employeeModal__sidebarContent">id: {state.id}</div>}
        options={allTabs}
        activeTab={state.activeTab}
        onTabClick={this.changeTab}
        user={{
          avatar: modalObject?.avatar?.medium,
        }}
        testId="manageEmployeeModal"
        withOverlayScrollContent
      >
        {this.displayRelevantTab()}
      </MDKadroModal>
    );
  }
}

EmployeeModal.defaultProps = {
  modalObject: {},
};

EmployeeModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeModal.propTypes = {
  showModal: PropTypes.bool,
  isManager: PropTypes.bool,
  modalObject: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape({
      medium: PropTypes.string,
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    pin: PropTypes.string,
    reference_id: PropTypes.string,
    alias: PropTypes.string,
    nfc_code: PropTypes.string,
    inactive: PropTypes.bool,
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    supplementary_locations_ids: PropTypes.arrayOf(PropTypes.string),
    employment_conditions: PropTypes.shape({
      template_id: PropTypes.string,
      weekly_working_minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      max_daily_working_minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      validate_working_rules: PropTypes.bool,
      schedule_cycle: PropTypes.shape({
        month: PropTypes.number,
        year: PropTypes.number,
        duration: PropTypes.number,
      }),
      availability_limits: PropTypes.shape({}),
      elastic_work_day: PropTypes.bool,
      show_absences: PropTypes.bool,
      is_medical: PropTypes.bool,
      allowed_to_work_at_night_hours: PropTypes.bool,
      hire_date: PropTypes.string,
      release_date: PropTypes.string,
    }),
    role_id: PropTypes.string,
    showSendInvitation: PropTypes.bool,
  }),
  currentCompany: PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    settings: PropTypes.shape({
      same_rank_is_visible: PropTypes.bool,
    }),
    status: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  changeEmployee: PropTypes.func,
  addEmployee: PropTypes.func,
  onHide: PropTypes.func,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    user_id: PropTypes.number,
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  absencesLimitTemplates: PropTypes.arrayOf(PropTypes.shape({})),
  getAbsencesLimitsEmployee: PropTypes.func,
  resetAbsencesLimits: PropTypes.func,
  currentUser: PropTypes.shape({}),
  getFreePin: PropTypes.func,
  inviteEmployee: PropTypes.func,
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  getUsersCustomFields: PropTypes.func,
};

export default EmployeeModal;
