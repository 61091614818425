import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { getArrayWithHourRanges, getArrayWithWeekDaysAndDayNumbers } from '@/utils/dateHelper.js';

import './AutoGenerateCreateScheduleTableHeader.scss';

const AutoGenerateCreateScheduleTableHeader = ({ changeDay, selectedDay }) => (
  <Fragment>
    <table className="k-autoGenerateCreateScheduleTableHeader__daysTable">
      <thead>
        <tr>
          {getArrayWithWeekDaysAndDayNumbers().map(({ dayNumber, dayName }) => {
            const dayClassName = classnames('k-autoGenerateCreateScheduleTableHeader__day', {
              'k-autoGenerateCreateScheduleTableHeader__day--active': dayNumber === selectedDay,
            });
            return (
              <th
                key={dayNumber}
                className={dayClassName}
                onClick={() => dayNumber !== selectedDay && changeDay(dayNumber)}
              >
                {dayName}
              </th>
            );
          })}
        </tr>
      </thead>
    </table>
    <table className="k-autoGenerateCreateScheduleTableHeader__hoursTable">
      <thead>
        <tr>
          {getArrayWithHourRanges().map(hours => (
            <th key={hours}>{hours}</th>
          ))}
        </tr>
      </thead>
    </table>
  </Fragment>
);

AutoGenerateCreateScheduleTableHeader.propTypes = {
  selectedDay: PropTypes.number,
  changeDay: PropTypes.func,
};

export default AutoGenerateCreateScheduleTableHeader;
