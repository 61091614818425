import { KADRO_ORANGE_COLOR } from '@/constants/colors';

export const trackStyle = { backgroundColor: KADRO_ORANGE_COLOR };
export const sliderTrackStyle = [trackStyle, trackStyle];
export const handleStyle = {
  backgroundColor: KADRO_ORANGE_COLOR,
  borderColor: KADRO_ORANGE_COLOR,
};
export const sliderHandleStyle = [handleStyle, handleStyle];
export const sliderDotStyle = { borderColor: KADRO_ORANGE_COLOR };

export const generateSliderObj = (n, isInclusive = false) => {
  const min = isInclusive ? -n - 1 : -n;
  const max = isInclusive ? n : n + 1;
  const values = Array.from({ length: 2 * n + 1 }, (_, i) => [i - n, i === 0 || i === 2 * n ? 9999 : i - n]).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v }),
    {},
  );
  return { min, max, values };
};
