import { connect } from 'react-redux';

import TopBarAbsencesLimits from './TopBarAbsencesLimits.jsx';

const mapStateToProps = state => ({
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const TopBarAbsencesLimitsContainer = connect(mapStateToProps)(TopBarAbsencesLimits);

export default TopBarAbsencesLimitsContainer;
