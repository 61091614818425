import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  applicant: {
    id: 'loanEmployeesProposal.applicant',
    defaultMessage: 'Wnioskujący',
  },
  location: {
    id: 'loanEmployeesProposal.location',
    defaultMessage: 'Lokalizacja',
  },
  day: {
    id: 'loanEmployeesProposal.day',
    defaultMessage: 'Dzień',
  },
  jobTitles: {
    id: 'loanEmployeesProposal.jobTitles',
    defaultMessage: 'Stanowisko',
  },
  employeesRemaining: {
    id: 'loanEmployeesProposal.employeesRemaining',
    defaultMessage: 'Liczba pracowników',
  },
  workingHours: {
    id: 'loanEmployeesProposal.workingHours',
    defaultMessage: 'Godziny',
  },
  comment: {
    id: 'loanEmployeesProposal.comment',
    defaultMessage: 'Komentarz',
  },
  employees: {
    id: 'loanEmployeesProposal.employees',
    defaultMessage: 'Pracownicy',
  },
  all: {
    id: 'loanEmployeesProposal.all',
    defaultMessage: 'Wszyscy',
  },
  andOthers: {
    id: 'loanEmployeesProposal.andOthers',
    defaultMessage: '{selectedEmployees} i {count, number} innych',
  },
});
