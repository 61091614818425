import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editShiftWithoutAttendance: {
    id: 'payrollEditShiftAndAttendanceModal.editShiftWithoutAttendance',
    defaultMessage: 'Edytuj zmianę',
  },
  editShiftWithAttendance: {
    id: 'payrollEditShiftAndAttendanceModal.editShiftWithAttendance',
    defaultMessage: 'Edytuj zmianę oraz obecność',
  },
  editAttendanceWithoutShift: {
    id: 'payrollEditShiftAndAttendanceModal.editAttendanceWithoutShift',
    defaultMessage: 'Edytuj obecność',
  },
  deleteAttendance: {
    id: 'payrollEditShiftAndAttendanceModal.deleteAttendance',
    defaultMessage: 'Usuń obecność',
  },
  deleteShift: {
    id: 'payrollEditShiftAndAttendanceModal.deleteShift',
    defaultMessage: 'Usuń zmianę',
  },
  addAttendance: {
    id: 'payrollEditShiftAndAttendanceModal.addAttendance',
    defaultMessage: 'Dodaj obecność',
  },
  addShift: {
    id: 'payrollEditShiftAndAttendanceModal.addShift',
    defaultMessage: 'Dodaj zmianę',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Zapisz',
  },
  workingRulesError: {
    id: 'shift.error.workingRulesError',
    defaultMessage: 'Ta lokalizacja ma wł. blokadę edycji zmiany w związku z walidacją grafiku z Kodeksem Pracy.',
  },
  workingHoursSingleOverlap: {
    id: 'shift.error.workingHoursSingleOverlap',
    defaultMessage: 'Zmiany się pokrywają. Wprowadź niepokrywającą się zmianę',
  },
  headerMessage: {
    id: 'payrollEditShiftAndAttendanceModal.headerMessage',
    defaultMessage:
      'Edytuj {isShift, select,true {zmianę} other {}} {isShiftAndAttendance, select,true {oraz} other {}} {isAttendance, select, true {obecność} other {}} dla {name} w dniu {date}',
  },
});
