import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { TIME_TRACKER_SUMMARY } from '@/constants/modalTypes.js';

import DashboardTimeTrackerSummary from './DashboardTimeTrackerSummary.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === TIME_TRACKER_SUMMARY,
  modalObject: state.reducer.uiState.modalObject[TIME_TRACKER_SUMMARY],
  locations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
};
const DashboardTimeTrackerSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardTimeTrackerSummary);

export default DashboardTimeTrackerSummaryContainer;
