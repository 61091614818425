import { connect } from 'react-redux';

import {} from '@/actions/uiState.js';
import ScheduleTemplatesTableRowTitle from '@/components/scheduler/Templates/ScheduleTemplatesTableRowTitle.jsx';

const mapStateToProps = state => ({
  dateStore: state.reducer.mainDateStore,
  scheduleUIState: state.reducer.scheduleUIState,
});

const mapDispatchToProps = {};

const ScheduleTemplatesTableRowTitleContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleTemplatesTableRowTitle);

export default ScheduleTemplatesTableRowTitleContainer;
