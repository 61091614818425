import { getJobTitleIdsFromContracts } from '@/utils/contracts';
import { getToday } from '@/utils/dateHelper';
import { getInitialRepeatShiftState } from '@/utils/repeatShiftHelpers';

import { errorsMessages } from './EmployeeShiftModal.messages';

export const getInitialState = (user, mainDateStore, relevantContract, shift = {}) => ({
  id: shift.id,
  selectedLocationId: shift.location?.id || user.locations[0].id,
  selectedJobTitleId: shift.job_title?.id || relevantContract?.job_titles[0].job_title_id,
  workingHours: shift.working_hours || '__:__-__:__',
  comment: shift.comment || '',
  selectedDate: shift.date || getToday(),
  errors: {},
  displayRepeat: false,
  ...getInitialRepeatShiftState(mainDateStore),
});

export const getLocationsOptions = locations =>
  locations.map(location => ({
    key: location.name,
    value: location.id,
  }));

export const getJobTitlesOptions = (relevantContract, userJobTitles) => {
  const jobTitleIds = relevantContract ? getJobTitleIdsFromContracts([relevantContract]) : [];

  return userJobTitles.reduce((acc, jobTitle) => {
    if (jobTitleIds.includes(jobTitle.id)) {
      acc.push({
        key: jobTitle.title,
        value: jobTitle.id,
      });
    }
    return acc;
  }, []);
};

export const createRepeatObject = state => {
  const { selectedRange, selectedChoices, selectedWeekdays, perWhichDay, activeRepeatTab } = state;
  return {
    type: activeRepeatTab,
    selected: {
      selectedRange,
      selectedChoices,
      selectedWeekdays,
      perWhichDay,
    },
  };
};

export const createNewShift = state => ({
  comment: state.comment,
  working_hours: state.workingHours,
  job_title: { id: state.selectedJobTitleId },
  draft: false,
  date: state.selectedDate,
  location: { id: state.selectedLocationId },
});

export const handleRequestError = err => {
  const errorCode =
    err.response?.data?.message?.errors?.[0].errorCode || err.response?.data?.errorCode || err.errorCode;
  if (errorCode) return errorsMessages[errorCode] || '';
  const errorMessage = err.response?.data?.message;
  const locationErrors = [
    'You are not allowed to access given location',
    'Given employee is not assigned to given location',
  ];
  if (locationErrors.includes(errorMessage)) return errorsMessages.notAllowedLocation;
  if (errorMessage === 'Job title not found') return errorsMessages.notAllowedJobTitle;
  return '';
};

export const getSummaryErrorMessage = errors =>
  errors.selectedDate || errors.repeatObj || errors.shiftsOverlap || errors.requestError || '';
