import { GET_SALES_REPORT,GET_WORKTIME_REPORT } from '@/constants/ActionTypes.js';

const initialState = {
  workTime: {},
  sales: {},
};

export const reportData = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKTIME_REPORT:
    case GET_SALES_REPORT:
      return {
        ...state,
        [action.reportName]: action.data,
      };
    default:
      return state;
  }
};
