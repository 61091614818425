import classnames from 'classnames';
import * as React from 'react';

import AttendanceGpsWarningIcon from '@/components/common/UI/AttendanceWarningIcon/AttendancesGpsWarningIcon';
import AttendancesUnavailabilityWarningIcon from '@/components/common/UI/AttendanceWarningIcon/AttendancesUnavailabilityWarningIcon.tsx';
import { AttendanceTableData, AttendanceTableEmployee, Location, SelectedEmployees } from '@/types';

interface EmployeeRow {
  employee: AttendanceTableEmployee;
  location: Location;
  data: AttendanceTableData;
  selectedEmployees: SelectedEmployees[];
  currentDate: string;
  handleClick: (location: Location, employee: AttendanceTableEmployee, data: AttendanceTableData) => void;
}

const EmployeeRow: React.FC<EmployeeRow> = ({
  employee,
  location,
  data,
  selectedEmployees,
  currentDate,
  handleClick,
}) => {
  const {
    id,
    availability_blocks: availabilityBlocks,
    first_name: firstName,
    last_name: lastName,
    attendances,
    absence,
    overtimeCollections,
  } = employee;

  const { locationId } = data;

  const leftColumnClassnames = classnames('attendance-leftColumn', {
    'attendance-leftColumn--selected': selectedEmployees.some(
      selected => selected.employeeId === id && selected.locationId === locationId,
    ),
  });

  const availability = availabilityBlocks.find(
    availabilityBlock => availabilityBlock.date === currentDate && availabilityBlock.count_hours_in_payroll,
  );

  return (
    <tr key={id} className={leftColumnClassnames} onClick={() => handleClick(location, employee, data)}>
      <td className="col_name">
        <div className="col_name_wrap">
          <div>
            <span className="initials">{`${firstName[0]}${lastName[0]}`}</span>
            <div className="col_fullname">{`${firstName} ${lastName}`}</div>
          </div>
          <div className="icons_wrap">
            <AttendanceGpsWarningIcon attendances={attendances} />
            <AttendancesUnavailabilityWarningIcon
              availability={availability}
              absence={absence}
              overtimeCollections={overtimeCollections}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};
export default EmployeeRow;
