import PropTypes from 'prop-types';

import './ModalHeader.scss';

const ModalHeader = ({ children }) => <div className="mdModal__header">{children}</div>;

ModalHeader.propTypes = {
  children: PropTypes.node,
};

export default ModalHeader;
