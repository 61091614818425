import { EmplMassEditActions, emplMassEditActions } from './constants.ts';
import EmployeesEditMassContractsField from './EmployeesEditMassContractsFields/EmployeesEditMassAddContractsFields/EmployeesEditMassContractsField.redux';
import EmployeeEditMassDeleteContractsFields from './EmployeesEditMassContractsFields/EmployeesEditMassDeleteContractsFields/EmployeeEditMassDeleteContractsFields.tsx';

export interface FieldValue {
  date?: string;
  assignJobTitlesAndWageForContract?: boolean;
  jobTitles?: { job_title_id: string; wage?: string }[];
  newStartContractDate?: string;
}

export const displayRelevantContractField = (
  fieldValue: FieldValue,
  onChange: (value: FieldValue) => void,
  selectedAction?: EmplMassEditActions,
) => {
  switch (selectedAction) {
    case emplMassEditActions.addContract:
    case emplMassEditActions.addContractsJobTitles:
    case emplMassEditActions.removeContractsJobTitles:
    case emplMassEditActions.editContractsStartDate:
      return <EmployeesEditMassContractsField {...{ fieldValue, onChange, selectedAction }} />;
    case emplMassEditActions.remove:
      return <EmployeeEditMassDeleteContractsFields {...{ onChange }} fieldValues={fieldValue} />;

    default:
      return null;
  }
};
