import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import Tooltip from '@/components/common/Tooltip/Tooltip';

import { messages } from './iconDropDownButton.messages';

import './iconDropdownButton.scss';

const IconDropdownButton = ({ rowData, children }, { intl }) => {
  const Y_OFFSET = 15;
  return (
    (<div className="k-IconDropdownButton">
      <ActionsButton
        icon={
          <Tooltip
            hideOnBlur
            content={intl.formatMessage(messages.iconDropdownButtonTooltipContent)}
            yOffset={Y_OFFSET}
          >
            <div className="k-IconDropdownButton__icon">
              <Icon name="more_vert" />
            </div>
          </Tooltip>
        }
        buttonTitle=""
        asIconButton
      >
        {cloneElement(children, { rowData })}
      </ActionsButton>
    </div>)
  );
};

IconDropdownButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

IconDropdownButton.propTypes = {
  children: PropTypes.node,
  rowData: PropTypes.shape({
    status_for_searching: PropTypes.shape({
      pl: PropTypes.string,
    }),
  }),
};

export default IconDropdownButton;
