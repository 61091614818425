import { updateIntl } from '@/reducers/intl.js';
import { KadroLocaleEnum } from '@/types/locale.types.ts';

import { conn } from '.';
import { setUserLanguage } from './auth.jsx';

export const updateIntlMessages = (messages) => (dispatch, getState) => {
  const lang = getState().intl.locale;
  dispatch(
    updateIntl({
      locale: lang === KadroLocaleEnum.PL ? KadroLocaleEnum.EN : KadroLocaleEnum.PL,
      messages,
    }),
  );
};

export const changeLanguage = (language) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      conn.changeCurrentUserSettings({ language }).then(() => {
        dispatch(setUserLanguage(language));
        resolve(language);
      });
    } catch (error) {
      reject();
    }
  });
