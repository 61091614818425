import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  errorTitle: {
    id: 'common.errorTitle',
    defaultMessage: 'Błąd!',
  },
  noDataToExport: {
    id: 'exports.enovaPayroll.noDataToExport',
    defaultMessage: 'Brak danych do ekpsortuj. Dostosuj filtry i spróbuj ponownie',
  },
  exportEnovaAttendancesError: {
    id: 'exports.enovaPayroll.exportEnovaAttendancesError',
    defaultMessage: 'Nie udało się eksportować czas pracy',
  },
  exportEnovaAttendancesSuccess: {
    id: 'exports.enovaPayroll.exportEnovaAttendancesSuccess',
    defaultMessage: 'Poprawnie eksportowano czas pracy',
  },
  exportEnovaScheduleError: {
    id: 'exports.enovaPayroll.exportEnovaScheduleError',
    defaultMessage: 'Nie udało się eksportować grafik',
  },
  exportEnovaScheduleSuccess: {
    id: 'exports.enovaPayroll.exportEnovaScheduleSuccess',
    defaultMessage: 'Poprawnie eksportowano grafik',
  },
});
