import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import { Grid, Header,Rows } from '@/components/common/Grid/Grid.jsx';
import Checkbox from '@/components/common/inputs/KadroCheckbox.jsx';
import { payrollLocationTableColumns } from '@/constants/tableColumns.jsx';

class PayrollLocationTable extends Component {
  constructor(props) {
    super(props);
    this.width = 900;
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  toggleCheckbox() {
    if (this.mainCheckbox) {
      this.props.setSelected([]);
    } else {
      this.props.setSelected(this.props.employeesIds);
    }
  }
  render() {
    this.mainCheckbox =
      this.props.payrollUI.selectedEmployees.length === this.props.employeesIds.length &&
      this.props.payrollUI.selectedEmployees.length > 0;

    const headers = [...payrollLocationTableColumns.headers];
    const classNames = [...payrollLocationTableColumns.classNames];
    const sorting = [...payrollLocationTableColumns.sort];
    return (
      <div style={{ clear: 'both' }}>
        <Grid
          scrollCallback={this.scrollSummary}
          height={999999}
          static
          width={this.width}
          columnStyle={{ width: '20vw', minWidth: '250px' }}
          columnGroup={
            <colgroup>
              {payrollLocationTableColumns.colgroup.map((width, i) => {
                if (this.props.visibleLocationColumns.includes(i))
                  return <col key={i} style={{ width: `${width}%` }} />;
                return null;
              })}
            </colgroup>
          }
        >
          <Header>
            <thead>
              <tr className="summaryrow">
                {headers.map((header, i) => {
                  if (this.props.visibleLocationColumns.includes(i)) {
                    return (
                      <th
                        key={header + i}
                        className={classNames[i]}
                        onClick={() => this.props.changeSorting(sorting[i])}
                      >
                        {i === 0 && (
                          <Checkbox absoluteCenter checked={this.mainCheckbox} onChange={this.toggleCheckbox} />
                        )}
                        <span className="clickable">
                          <FormattedMessage {...header} />
                        </span>
                        <SortingArrow
                          display={this.props.sortingData.sortColumn === sorting[i]}
                          up={this.props.sortingData.sortDirection === 1}
                        />
                      </th>
                    );
                  }
                  return null;
                })}
              </tr>
            </thead>
          </Header>
          <Rows className="table payroll">{this.props.children}</Rows>
        </Grid>
      </div>
    );
  }
}
PayrollLocationTable.propTypes = {
  children: PropTypes.node,
  sortingData: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDirection: PropTypes.number,
  }),
  payrollUI: PropTypes.shape({
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employeesIds: PropTypes.arrayOf(PropTypes.string),
  setSelected: PropTypes.func,
  changeSorting: PropTypes.func,
  visibleLocationColumns: PropTypes.arrayOf(PropTypes.number),
};
export default PayrollLocationTable;
