import PropTypes from 'prop-types';
import { useTable } from 'react-table';

import './KadroMinimalistTable.scss';

const KadroMinimalistTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} className="k-minimalist-table">
      <thead>
        {headers.map(header => (
          <th {...header.getHeaderProps()}>{header.render('Header')}</th>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

KadroMinimalistTable.defaultProps = {
  data: [],
  columns: [],
};

KadroMinimalistTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accessor: PropTypes.string,
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default KadroMinimalistTable;
