import { connect } from 'react-redux';

import { changeSortingNameType } from '@/actions/uiState.js';
import TopBarNameFilter from '@/components/TopBars/filters/TopBarNameFilter/TopBarNameFilter.jsx';

const mapStateToProps = state => ({
  uiState: state.reducer.uiState,
  listsUi: state.reducer.listsUi,
});

const mapDispatchToProps = {
  changeSortingNameType,
};

const TopBarNameFilterContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarNameFilter);

export default TopBarNameFilterContainer;
