import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const useClearModal = (clearModal: () => void): Dispatch<SetStateAction<boolean>> => {
  const [hideEndStatus, setHideEndStatus] = useState(false);

  useEffect(() => {
    if (hideEndStatus) {
      clearModal();
    }
  }, [hideEndStatus, clearModal]);

  return setHideEndStatus;
};

export default useClearModal;
