import cn from 'classnames';
import PropTypes from 'prop-types';

import HoverPopOver from '@/components/common/HoverPopOver';
import { getAvaBlockDisplayData } from '@/components/scheduler/ScheduleTable/AvaMode/AvaBlock/AvaBlock.helpers';
import commonMessages from '@/constants/commonMessages';
import { getPositionAndLengthForBlock } from '@/utils/shiftHelpers';

import { addTooltipToDragImage, dragAndDropCleanup } from '../../ScheduleShiftBlock/ScheduleShiftBlock.helpers';

import './AvaBlock.scss';

const AvaBlock = (
  {
    avaBlock,
    openEditAvailabilityModalByIds,
    confirmAvailability,
    deleteAvailability,
    employeeId,
    isMonthView,
    startAvailabilityDrag,
    stopAvailabilityDrag,
    date,
    isSingleDay,
  },
  { intl },
) => {
  const { blockColor, accentColor, icon, id, draft, hours, comment } = avaBlock;

  const className = cn('k-scheduleAvaBlock', { 'k-scheduleAvaBlock--draft': draft });

  const { title, showHours, showIcon, hasComment } = getAvaBlockDisplayData(avaBlock, isMonthView, intl);

  let styles = {
    backgroundColor: blockColor || 'red',
  };

  if (hours && isSingleDay) {
    const [start, end] = hours.split('-');
    const { position, length } = getPositionAndLengthForBlock(start, end, date);

    styles = {
      ...styles,
      left: position,
      width: `${length}%`,
    };
  }

  const block = (
    <div
      className={className}
      style={styles}
      onClick={e => {
        e.stopPropagation();
        if (draft) return;
        openEditAvailabilityModalByIds(id, employeeId);
      }}
      role="presentation"
      draggable
      onDragStart={e => {
        addTooltipToDragImage(e, intl);
        startAvailabilityDrag({
          ...avaBlock,
          type_id: avaBlock.typeId,
          date,
          employee_id: String(employeeId),
        });
      }}
      onDragEnd={() => {
        dragAndDropCleanup();
        stopAvailabilityDrag();
      }}
    >
      <div className="k-scheduleAvaBlock__infoContainer" style={{ color: accentColor }}>
        {hasComment && (
          <i className="material-icons k-scheduleAvaBlock__commentIcon" style={{ color: accentColor }}>
            comment
          </i>
        )}
        {showIcon && <i className="material-icons k-scheduleAvaBlock__typeIcon">{icon}</i>}
        <span className="k-scheduleAvaBlock__titleContainer">
          <span className="k-scheduleAvaBlock__title">{title}</span>
          {showHours && <span className="k-scheduleAvaBlock__hours">{hours}</span>}
        </span>
      </div>

      {draft && (
        <div className="k-scheduleAvaBlock__iconsContainer">
          <i
            className="material-icons k-scheduleAvaBlock__icon k-scheduleAvaBlock__icon--delete"
            onClick={e => {
              e.stopPropagation();
              deleteAvailability(employeeId, id);
            }}
            role="presentation"
          >
            delete
          </i>

          <i
            className="material-icons k-scheduleAvaBlock__icon k-scheduleAvaBlock__icon--delete"
            onClick={e => {
              e.stopPropagation();
              confirmAvailability(id, employeeId);
            }}
            role="presentation"
          >
            check
          </i>
        </div>
      )}
    </div>
  );
  if (hasComment) {
    return (
      <div className="k-scheduleAvaBlock__hoverPopover">
        <HoverPopOver
          name="cornAva"
          popover={{
            title: intl.formatMessage(commonMessages.comment),
            content: comment,
          }}
          wrapperClassName="k-scheduleAvaBlock__hoverPopover--withComment"
        >
          {block}
        </HoverPopOver>
      </div>
    );
  }
  return block;
};

AvaBlock.propTypes = {
  avaBlock: PropTypes.shape({
    typeName: PropTypes.shape({
      id: PropTypes.string,
      defaultMessage: PropTypes.string,
    }),
    shortTypeName: PropTypes.string,
    blockColor: PropTypes.string,
    accentColor: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    draft: PropTypes.bool,
    type: PropTypes.string,
    hours: PropTypes.string,
    comment: PropTypes.string,
    typeId: PropTypes.string,
  }),
  openEditAvailabilityModalByIds: PropTypes.func,
  confirmAvailability: PropTypes.func,
  deleteAvailability: PropTypes.func,
  employeeId: PropTypes.string,
  isMonthView: PropTypes.bool,
  isSingleDay: PropTypes.bool,
  startAvailabilityDrag: PropTypes.func,
  stopAvailabilityDrag: PropTypes.func,
  date: PropTypes.string,
};

AvaBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default AvaBlock;
