import { connect } from 'react-redux';

import { budgetEstimatesChange } from '@/actions/budgetEstimates.js';

import ScheduleBudgetTableRows from './ScheduleBudgetTableRows.jsx';

const mapStateToProps = state => ({
  today: state.reducer.mainDateStore.today,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  showHolidays: state.reducer.schedule.viewSettings.holidays.value,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

const mapDispatchToProps = {
  budgetEstimatesChange,
};

const ScheduleBudgetTableRowsContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleBudgetTableRows);

export default ScheduleBudgetTableRowsContainer;
