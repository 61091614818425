import { FreeDayItem, FreeDayItemPostDto } from '@/redux-store/freeDaysMarking';
import { Employee, EmployeeWhole } from '@/types';
import { MainDateStore } from '@/types/mainDateStore';
import { Shift, ShiftToCreate } from '@/types/shifts.types';

export type QuickPlanningSelected = Record<Employee['id'], Record<MainDateStore['dateArray'][number], boolean>>;

export type QuickPlanningSpecialSelections = {
  isOnlyOneColumnCopied: boolean;
  isOnlyOneRowCopied: boolean;
  isOnlyOneShiftCopied: boolean;
  isOnlyOneFreeDayCopied: boolean;
};

export enum ReasonsForNotAddFreeDayInQPEnum {
  EMPLOYEE_NOT_HIRED = 'employee-not-hired',
}

export enum ReasonsForNotAddShiftInQPEnum {
  EMPLOYEE_NOT_HIRED = 'employee-not-hired',
  EMPLOYEE_HAS_OVERLAPPING_SHIFT = 'employee-has-overlapping-shift',
  EMPLOYEE_HAS_NOT_LOCATION = 'employee-has-no-location',
}

export type QuickPlanningStoreState = {
  selected: QuickPlanningSelected;
  enabled: boolean;
  position: {
    start: {
      x: number;
      y: number;
    };
    end: {
      x: number;
      y: number;
    };
    borderCell: {
      employeeId: EmployeeWhole['id'] | null;
      date: MainDateStore['dateArray'][number] | null;
    };
  };
  downItem: {
    employeeId: EmployeeWhole['id'] | null;
    date: MainDateStore['dateArray'][number] | null;
  };
  hoveredItems: Record<EmployeeWhole['id'], Record<MainDateStore['dateArray'][number], boolean>>;
  datesRanges: {
    minDateIndex?: number;
    maxDateIndex?: number;
    minDate?: MainDateStore['dateArray'][number];
    maxDate?: MainDateStore['dateArray'][number];
  };
  employeesIdsRanges: {
    minEmployeeIndex?: number;
    maxEmployeeIndex?: number;
  };
  copiedMarkedArea: {
    borderTop: Record<EmployeeWhole['id'], Record<MainDateStore['dateArray'][number], boolean>>;
    borderBottom: Record<EmployeeWhole['id'], Record<MainDateStore['dateArray'][number], boolean>>;
    borderLeft: Record<EmployeeWhole['id'], Record<MainDateStore['dateArray'][number], boolean>>;
    borderRight: Record<EmployeeWhole['id'], Record<MainDateStore['dateArray'][number], boolean>>;
  };
  freeDaysToCopy: Record<EmployeeWhole['id'], FreeDayItem[]>;
  freeDaysSummary: {
    freeDaysToPaste: Array<FreeDayItemPostDto & { id?: FreeDayItem['id'] }>;
    freeDaysAfterDateSelection: FreeDayItemPostDto[];
    freeDaysForNotExistingEmployeeId: FreeDayItem['id'][];
    reasonsToNotAddFreeDay: ReasonsForNotAddFreeDayInQPEnum[];
  };
  shiftsToCopy: Record<EmployeeWhole['id'], Shift[]>;
  shiftsSummary: {
    shiftsToPaste: ShiftToCreate[];
    shiftsAfterDateSelection: ShiftToCreate[];
    shiftsForNotExistingEmployeeId: Shift['id'][];
    reasonsToNotAddShift: ReasonsForNotAddShiftInQPEnum[];
  };
  specialSelections: QuickPlanningSpecialSelections;
  translationWithShift: {
    x: number;
    y: number;
  };
  workingHours: string;
  freeDayShortcut: string;
};
