import { defineMessages, IntlShape } from 'react-intl';

import { isEmptyArray } from '@/utils/array/array.helpers';

import {
  DeleteScheduleItem,
  DeleteScheduleItemEnum,
  DeleteScheduleItemsModalObject,
} from './DeleteScheduleItemsModal.types';

export const messages = defineMessages({
  title: {
    id: 'deleteScheduleItemsModal.title',
    defaultMessage: 'Usuń zaznaczone',
  },
  shifts: {
    id: 'deleteScheduleItemsModal.shifts',
    defaultMessage: 'Zmiany',
  },
  freeDaysMarking: {
    id: 'deleteScheduleItemsModal.freeDaysMarking',
    defaultMessage: 'Oznaczenia dni wolnych',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

export const getCheckboxList = ({ freeDayIds, shiftIds }: DeleteScheduleItemsModalObject, intl: IntlShape) => {
  const result: DeleteScheduleItem[] = [];
  if (!isEmptyArray(freeDayIds))
    result.push({
      id: DeleteScheduleItemEnum.FREE_DAYS_MARKINGS,
      label: `${intl.formatMessage(messages.freeDaysMarking)} (${freeDayIds.length})`,
    });
  if (!isEmptyArray(shiftIds))
    result.push({
      id: DeleteScheduleItemEnum.SHIFTS,
      label: `${intl.formatMessage(messages.shifts)} (${shiftIds.length})`,
    });
  return result;
};
