import classnames from 'classnames';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noResults: {
    id: 'topBar.noResults',
    defaultMessage: 'Brak wyników',
  },
});

const TopBarGroupedList = (props, { intl }) => {
  const { items: groups, selected, onChange, showText } = props;

  const handleClick = item => {
    if (item.id === selected.id) {
      return;
    }
    onChange(item);
  };

  if (!groups || groups.every(group => !group.items || group.items.length === 0)) {
    return (
      <div className="k-topBarChoice__element k-topBarChoice__element--groupHeader">
        <span>{intl.formatMessage(messages.noResults)}</span>
      </div>
    );
  }

  return (
    <>
      {groups.map(
        group =>
          group.items?.length > 0 && (
            <>
              <div className="k-topBarChoice__element k-topBarChoice__element--groupHeader">
                {intl.formatMessage(group.label)}
              </div>
              {group?.items?.map(item => {
                const elementClassName = classnames('k-topBarChoice__element', item.class_name, {
                  'k-topBarChoice__element--paddingRight': item.elements,
                  'k-topBarChoice__element--selectedGroupItem': item.id === selected.id,
                });

                return (
                  <button key={item.id} className={elementClassName} onClick={() => handleClick(item)}>
                    {showText(item)}
                    {item.elements}
                  </button>
                );
              })}
            </>
          ),
      )}
    </>
  );
};

TopBarGroupedList.contextTypes = {
  intl: PropTypes.shape({}),
};

TopBarGroupedList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ids: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  selected: PropTypes.shape({
    id: PropTypes.string,
  }),
  onChange: PropTypes.func,
  showText: PropTypes.func,
};

export default TopBarGroupedList;
