import PropTypes from 'prop-types';

import PhotoPreviewContainer from '@/components/common/PhotoPreview/PhotoPreview.redux.js';
import PhotoPreviewPayrollIcon from '@/components/common/PhotoPreview/PhotoPreviewPayrollIcon.redux.js';
import { ATTENDANCE_SUCCESS_COLOR } from '@/constants/colors.js';
import { ADD_SHIFT_MODAL, EDIT_ATTENDANCE_MODAL } from '@/constants/modalTypes.js';
import { getEndAttendanceColor, getStartAttendanceColor } from '@/utils/attendanceHelpers';

import {
  findRelevantBlockToType,
  getTimeColorWithoutMatchingShift,
  isAttendanceWithoutShift,
} from './AttendanceTimeSpan.helpers';

const AttendanceTimeSpan = props => {
  const { text, attendance, type, employee, isAttendanceEditingBlocked, isShiftEditingBlocked, blocks } = props;

  let className = isAttendanceEditingBlocked ? 'nonClickable' : 'clickable';
  let color = props.color || '#000';
  let component = null;
  let onClick = !isAttendanceEditingBlocked
    ? () => {
        props.showModal(EDIT_ATTENDANCE_MODAL, {
          employee,
          attendance,
        });
      }
    : () => {};
  if (attendance) color = ATTENDANCE_SUCCESS_COLOR;
  if (attendance && attendance.matching_shift) {
    if (type === 'start') {
      const startShift = `${attendance.matching_shift.date} ${
        attendance.matching_shift.working_hours.split('-')[0]
      }:00`;
      color = getStartAttendanceColor(attendance.start_timestamp, startShift, attendance.early_in);
      component = (
        <span className={className} role="presentation" style={{ color, display: 'block' }} onClick={onClick}>
          {attendance.hours.split('-')[0]}
        </span>
      );
    } else {
      const endShift = `${attendance.matching_shift.date} ${attendance.matching_shift.working_hours.split('-')[1]}:00`;
      color = getEndAttendanceColor(attendance.end_timestamp, endShift, attendance.late_out);
      component = (
        <span className={className} role="presentation" style={{ color, display: 'block' }} onClick={onClick}>
          {attendance.hours.split('-')[1]}
        </span>
      );
    }
  } else if (attendance) {
    let attendanceText = '';
    if (type === 'start') {
      attendanceText = attendance.hours.split('-')[0];
    } else {
      attendanceText = attendance.hours.split('-')[1];
    }
    color = getTimeColorWithoutMatchingShift(type, attendance, blocks);
    const block = findRelevantBlockToType(type, blocks, attendance);

    const blockType = block?.type?.name;
    const attendanceIsWithoutShift = isAttendanceWithoutShift(blockType);
    if (attendanceIsWithoutShift && !employee.isLoaned) {
      className = !isShiftEditingBlocked ? 'clickable' : '';
      onClick = !isShiftEditingBlocked
        ? () => {
            props.showModal(ADD_SHIFT_MODAL, {
              employee,
              date: attendance.date,
              location: attendance.location,
              defaultHours: attendance.hours,
              attendanceToDelete: attendance,
            });
          }
        : () => {};
    }
    component = (
      <span className={className} role="presentation" onClick={onClick} style={{ color, display: 'block' }}>
        {attendanceText}
      </span>
    );
  } else {
    component = (
      <span role="presentation" style={{ color, display: 'block' }}>
        {text}
      </span>
    );
  }
  if (type === 'start' && attendance) {
    return (
      <div>
        {component}
        {props.showPhotoPreview && (
          <PhotoPreviewContainer
            attendanceId={attendance.id}
            locationId={attendance.location.id}
            class="k-photoPreview--small"
          >
            <PhotoPreviewPayrollIcon attendanceId={attendance.id} />
          </PhotoPreviewContainer>
        )}
      </div>
    );
  }
  return component;
};

AttendanceTimeSpan.defaultProps = {
  showPhotoPreview: true,
};

AttendanceTimeSpan.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  attendance: PropTypes.shape({
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    early_in: PropTypes.bool,
    late_out: PropTypes.bool,
  }),
  type: PropTypes.string,
  employee: PropTypes.shape({}),
  showModal: PropTypes.func,
  showPhotoPreview: PropTypes.bool,
  isAttendanceEditingBlocked: PropTypes.bool,
  isShiftEditingBlocked: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTimeSpan;
