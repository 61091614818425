import PropTypes from 'prop-types';
import { useState } from 'react';

import Popover from '@/components/common/Popover/Popover.jsx';

import MDIconButton from '../../Basic/MDIconButton/MDIconButton';

import './IconPicker.scss';

const IconPicker = ({ icons, defaultIcon, onPick }) => {
  const [activeIcon, setActiveIcon] = useState(defaultIcon);

  const pickIcon = (icon) => {
    setActiveIcon(icon);
    if (onPick) onPick(icon);
  };

  return (
    <Popover
      className='k-iconPicker'
      position="right"
      sideOffset={100}
      xOffset={0}
      useParentDimensions
      showShadow={false}
      popoverStyle={{ padding: 0 }}
      hideOnBlur
      content={
        <div
          className="k-iconPicker__options"
        >
          {icons.map(value => (
            <MDIconButton
              onClick={() => pickIcon(value)}
              key={value}
              icon={value}
              lassName="k-iconPicker__option"
            />
          ))}
        </div>
      }
    >
      <i
        role="button"
        tabIndex={0}
        className="k-iconPicker__selector material-icons"
      >
        {activeIcon}
      </i>
    </Popover>
  );
};

IconPicker.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  defaultIcon: PropTypes.string,
  onPick: PropTypes.func,
};

export default IconPicker;
