import {
  CHANGE_PRODUCTION_QUOTAS_SUCCESFUL,
  GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL,
  GET_PRODUCTION_QUOTAS_SUCCESFUL,
} from '@/constants/ActionTypes.js';

import { conn, connectionError } from './index';

export const getProductionQuotasSuccesful = productionQuotas => ({
  type: GET_PRODUCTION_QUOTAS_SUCCESFUL,
  payload: {
    productionQuotas,
  },
});

export const changeProductionQuotasSuccesful = (productionQuotas, date) => ({
  type: CHANGE_PRODUCTION_QUOTAS_SUCCESFUL,
  payload: {
    productionQuotas,
    date,
  },
});

export const getProductionQuotasPayrollSuccesful = payroll => ({
  type: GET_PRODUCTION_QUOTAS_PAYROLL_SUCCESFUL,
  payload: payroll,
});

export const getProductionQuotas = (locationId, from, to) => dispatch => {
  conn
    .getProductionQuotasInRange(locationId, from, to)
    .then(response => {
      dispatch(getProductionQuotasSuccesful(response.data.production_quotas));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const changeProductionQuotas = (locationId, date, amountsObj) => dispatch => {
  conn
    .changeProductionQuotas(locationId, date, amountsObj)
    .then(response => {
      const quotas = response.data.production_quotas;
      dispatch(changeProductionQuotasSuccesful(quotas, date));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const getProductionQuotasPayroll = (locationIdArray, from, to, requestType) => async dispatch => {
  const productionQuotasPayrollData = locationIdArray.map(locationId =>
    conn.getProductionQuotasPayroll(locationId, from, to, requestType),
  );
  const productionQuotasPayrollResponse = await Promise.all(productionQuotasPayrollData);
  const payroll = productionQuotasPayrollResponse.reduce((prev, response) => {
    if (response.data.payouts) {
      return prev.concat(Object.keys(response.data.payouts).map(employeeId => response.data.payouts[employeeId]));
    }
    return prev;
  }, []);
  dispatch(getProductionQuotasPayrollSuccesful(payroll));
};
