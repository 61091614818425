import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { REPORTS_FILTERS_MODAL } from '@/constants/modalTypes.js';
import { NEW_REPORTS_VIEW, OLD_REPORTS_VIEW } from '@/constants/Permissions.js';
import ReportViewContainer from '@/containers/ReportViewContainer.js';

import AverageHoursPerEmployeeChartContainer from '../AverageHoursPerEmployeeChart/AverageHoursPerEmployeeChart.redux.js';
import CostOfLabourChartContainer from '../CostOfLabourChart/CostOfLabourChart.redux.js';
import DailyWorkTimeChartContainer from '../DailyWorkTimeChart/DailyWorkTimeChart.redux.js';
import NumberOfEmployeesChartContainer from '../NumberOfEmployeesChart/NumberOfEmployeesChart.redux.js';
import ReportsFiltersModal from '../ReportsFiltersModal/ReportsFiltersModal.redux.js';
import SalesPerManHourChartContainer from '../SalesPerManHourChart/SalesPerManHourChart.redux.js';
import ReportsTopBar from '../TopBar/ReportsTopBar.redux.js';
import WorkedTimePerLocationChartContainer from '../WorkedTimePerLocationChart/WorkedTimePerLocationChart.redux.js';
import WorkTimeTableContainer from '../WorkTimeTable/WorkTimeTable.redux.js';
import ChartWrapper from './ChartWrapper.jsx';
import ReportsButtonBar from './ReportsButtonBar/ReportsButtonBar.redux';

const ReportsView = ({ showModal, permissions }) => {
  useEffect(() => {
    if (permissions.includes(NEW_REPORTS_VIEW)) {
      showModal(REPORTS_FILTERS_MODAL);
    }
  }, []);

  if (permissions.includes(OLD_REPORTS_VIEW)) {
    return <ReportViewContainer />;
  }

  return (
    <div className="k-reports__wrapper">
      <ReportsFiltersModal />
      <ReportsTopBar />
      <ReportsButtonBar />
      <div className="k-reports__charts-wrapper">
        <ChartWrapper title={<FormattedMessage id="reports.dailyWorkTime" defaultMessage="Dzienny czas pracy" />}>
          <DailyWorkTimeChartContainer />
        </ChartWrapper>
        <ChartWrapper
          title={
            <FormattedMessage
              id="reports.averageWorkTimePerEmployee"
              defaultMessage="Średni czas pracy per pracownik"
            />
          }
        >
          <AverageHoursPerEmployeeChartContainer />
        </ChartWrapper>
        <ChartWrapper
          title={<FormattedMessage id="reports.averageNumberOfEmployees" defaultMessage="Średnia liczba pracowników" />}
        >
          <NumberOfEmployeesChartContainer />
        </ChartWrapper>
        <ChartWrapper
          title={
            <FormattedMessage id="reports.workedTimePerLocation" defaultMessage="Udział lokalizacji w czasie pracy" />
          }
        >
          <WorkedTimePerLocationChartContainer />
        </ChartWrapper>
        <ChartWrapper
          title={<FormattedMessage id="reports.salesPerHour" defaultMessage="Sprzedaż na roboczogodzinę" />}
        >
          <SalesPerManHourChartContainer />
        </ChartWrapper>
        <ChartWrapper
          title={<FormattedMessage id="reports.costOfLabour" defaultMessage="Udział kosztów pracy w sprzedaży" />}
        >
          <CostOfLabourChartContainer />
        </ChartWrapper>
      </div>
      <div className="k-reports__chart-wrapper">
        <WorkTimeTableContainer />
      </div>
    </div>
  );
};

ReportsView.propTypes = {
  showModal: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default ReportsView;
