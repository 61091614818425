import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Popover from '@/components/common/Popover/Popover';

import MDInputTooltip from '../../MDInputTooltip/MDInputTooltip';
import MDSelectPopoverContent from './MDSelectPopoverContent/MDSelectPopoverContent';

import './MDSelectPopover.scss';

const MDSelectPopover = ({
  defaultValue,
  options,
  onChange,
  name,
  disabled,
  closeOnClick,
  withSearch,
  isSorted,
  tooltip,
  errorMessage,
  testId,
  noArrow,
  yDropdownOffset,
  wrapperClassName,
}) => {
  const [dropdownStatus, setDropdownStatus] = useState(false);

  const singleSelectRef = useRef(null);
  const popoverRef = useRef(null);
  const [dropdownListWidth, setDropdownListWidth] = useState();

  const handleOpenChange = () => {
    if (!disabled) setDropdownStatus(status => !status);
  };

  const handleOnChange = value => {
    if (closeOnClick) {
      setDropdownStatus(false);
      popoverRef.current.hide();
    }
    onChange(value);
  };

  const selected = options.find(({ value }) => value === defaultValue)?.key;
  const buttonClassNames = classnames('mdSelectPopover__button', {
    'mdSelectPopover__button--active': dropdownStatus,
    'mdSelectPopover__button--fill': !!selected,
    'mdSelectPopover__button--disabled': disabled,
  });

  return (
    <Popover
      addUpdateListeners
      showShadow={false}
      hideOnBlur
      hideOnChildClick
      noArrow
      noBorder
      yOffset={yDropdownOffset}
      popoverStyle={{ padding: 0 }}
      getRef={ref => setDropdownListWidth(ref?.clientWidth)}
      setArrowStatus={setDropdownStatus}
      centerOnMobile={false}
      wrapperClassName={wrapperClassName}
      ref={popoverRef}
      content={
        <MDSelectPopoverContent
          {...{
            withSearch,
            isSorted,
            options,
            handleOnChange,
            defaultValue,
            width: dropdownListWidth,
            dropdownStatus,
            testId,
          }}
        />
      }
    >
      <div className="mdSelectPopover">
        <button ref={singleSelectRef} onClick={handleOpenChange} className={buttonClassNames} data-test={testId}>
          <div className="mdSelectPopover__label">{name}</div>
          <div className="mdSelectPopover__container">
            <span className="mdSelectPopover__value" data-test={`${testId}-value`}>
              {selected}
            </span>
            <span className="mdSelectPopover__icons">
              {!noArrow && <span className="mdSelectPopover__arrow" />}
              {tooltip && <MDInputTooltip content={tooltip} />}
            </span>
          </div>
        </button>
        <div className="kmd-textInput__error">{errorMessage}</div>
      </div>
    </Popover>
  );
};

MDSelectPopover.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

MDSelectPopover.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  withSearch: PropTypes.bool,
  isSorted: PropTypes.bool,
  tooltip: PropTypes.string,
  errorMessage: PropTypes.string,
  noArrow: PropTypes.bool,
  testId: PropTypes.string,
  yDropdownOffset: PropTypes.number,
  wrapperClassName: PropTypes.string,
};

export default MDSelectPopover;
