import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import RecentAddedShift from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddShiftTab/RecentAddedShifts/RecentAddedShift/RecentAddedShift';
import { getRecentlyUsedWorkingHours } from '@/utils/localStorageHelpers';

import './recentAddedShifts.scss';

const messages = defineMessages({
  recentWorkingHoursTitle: {
    id: 'addShiftModal.recentAddedShifts',
    defaultMessage: 'Ostatnio dodane zmiany',
  },
});

const RecentAddedShifts = ({ handleInputChange, onSubmitShift, displayRepeat, workingHours }, { intl }) => {
  const recentlyUsedWorkingHours = getRecentlyUsedWorkingHours();
  if (!recentlyUsedWorkingHours.length) return null;
  return (
    <div className="k-recentAddedShifts">
      <div className="k-recentAddedShifts__title">{intl.formatMessage(messages.recentWorkingHoursTitle)}</div>
      <div className="k-recentAddedShifts__workingHours">
        {recentlyUsedWorkingHours.map(usedWorkingHours => (
          <RecentAddedShift
            key={usedWorkingHours}
            handleInputChange={handleInputChange}
            usedWorkingHours={usedWorkingHours}
            onSubmitShift={onSubmitShift}
            displayRepeat={displayRepeat}
            workingHours={workingHours}
          />
        ))}
      </div>
    </div>
  );
};

RecentAddedShifts.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
RecentAddedShifts.propTypes = {
  handleInputChange: PropTypes.func,
  onSubmitShift: PropTypes.func,
  displayRepeat: PropTypes.bool,
  workingHours: PropTypes.string,
};

export default RecentAddedShifts;
