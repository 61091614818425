import { DIRECTIONS } from '@/constants/ui.js';

import Icon from '../Basic/Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { getIconName, getIndicatorClassName } from './indicator.helpers.ts';

import './indicator.scss';

type IndicatorProps = {
  value: number;
  tootltipContent: string;
};

const X_OFFSET = -74;
const Y_OFFSET = -10;

const Indicator = ({ value, tootltipContent }: IndicatorProps) => {
  const indicatorClassName = getIndicatorClassName('k-indicator', value);
  const iconClassName = getIndicatorClassName('k-indicator__icon', value);
  const valueClassName = getIndicatorClassName('k-indicator__value', value);

  const iconName = getIconName(value);

  const formattedValue = value ? `${Math.abs(value)}%` : '0.00%';
  return (
    <Tooltip
      position={DIRECTIONS.BOTTOM}
      xOffset={X_OFFSET}
      yOffset={Y_OFFSET}
      content={tootltipContent}
      className="k-indicator__tooltipContent"
    >
      <div className={indicatorClassName}>
        <Icon type="material-symbols" name={iconName} className={iconClassName} />
        <div className={valueClassName}>{formattedValue}</div>
      </div>
    </Tooltip>
  );
};

export default Indicator;
