import { connect } from 'react-redux';

import { togglePayrollSettingsModal } from '@/actions/payrollUI';
import { calculateSpmhBasedBonusesForNewPayrollLocation } from '@/actions/spmhBasedBonuses.js';
import { showModal } from '@/actions/uiState.js';

import PayrollLocationActionButtons from './PayrollLocationActionButtons';

const mapStateToProps = state => ({
  areSomeEmployeesInTable: state.reducer.payrollLocation.data.length > 0,
  restrictions: state.reducer.userPermissions.restrictions,
  permissions: state.reducer.userPermissions.permissions,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
});

const mapDispatchToProps = {
  calculateSpmhBasedBonusesForNewPayrollLocation,
  showModal,
  togglePayrollSettingsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationActionButtons);
