import { defineMessages } from 'react-intl';

const messages = defineMessages({
  workedHours: {
    id: 'reports.workedHoursLabel',
    defaultMessage: 'Przepracowane',
  },
  plannedHours: {
    id: 'reports.plannedHoursLabel',
    defaultMessage: 'Zaplanowane',
  },
});

export const metadata = [
  { key: 'plannedHours', chartType: 'bar', color: '#FF6B01', label: messages.plannedHours, yAxisId: 'left', order: 1 },
  { key: 'workedHours', chartType: 'bar', color: '#2C223F', label: messages.workedHours, yAxisId: 'left', order: 2 },
];

export const axisMetadata = {
  left: {
    unit: 'h',
  },
};
