import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDMultiSelect, MDMultiSelectFooter, MDMultiSelectHeader } from '../inputs/MDComponents.jsx';
import { selectedJobTitlesAsOptions } from './MDJobTitleSelectBlock.helpers.js';

const MDJobTitleSelectBlock = ({
  selectedJobTitles,
  setSelectedJobTitles,
  selectAllJobTitles,
  deselectAllJobTitles,
  jobTitleOptions,
}) => {
  const selectedJobTitlesAsFooterOptions = useMemo(() => selectedJobTitlesAsOptions(selectedJobTitles), [
    selectedJobTitles,
  ]);

  return (
    <div className="k-autoGenerateEmployeePicker__selectedJobTitles">
      <MDMultiSelectHeader
        title={<FormattedMessage id="openShift.addModal.jobTitles" defaultMessage="Stanowiska" />}
        selectAll={selectAllJobTitles}
        deselectAll={deselectAllJobTitles}
        selectAllMessage={<FormattedMessage id="openShift.addModal.selectAllJobsButton" defaultMessage="Wszystkie" />}
        deselectAllMessage={<FormattedMessage id="openShift.addModal.deselectAllJobsButton" defaultMessage="Żadne" />}
      />
      <MDMultiSelect
        label={<FormattedMessage id="openShift.requestModal.chosenJobTitles" defaultMessage="Wybierz stanowiska" />}
        options={jobTitleOptions}
        onChange={setSelectedJobTitles}
      />
      <MDMultiSelectFooter
        selectedItems={selectedJobTitlesAsFooterOptions}
        deleteItem={setSelectedJobTitles}
        autoHeight
      />
    </div>
  );
};

MDJobTitleSelectBlock.propTypes = {
  selectedJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  setSelectedJobTitles: PropTypes.func,
  selectAllJobTitles: PropTypes.func,
  deselectAllJobTitles: PropTypes.func,
  jobTitleOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MDJobTitleSelectBlock;
