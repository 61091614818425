import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { SmartTimeRangeTextarea } from '@/components/common/inputs/MDComponents';
import { inputValidation } from '@/utils/inputValidation';

import './ScheduleQuickPlanningNewShift.scss';

const ScheduleQuickPlanningNewShift = props => {
  const {
    workingHours,
    isSelectedItem,
    isQuickPlanningEnabled,
    isFocused,
    setWorkingHoursForNewShift,
    size,
    quickPlanningAddNewShifts,
    isEditEnabled,
  } = props;

  if (!isQuickPlanningEnabled || !isSelectedItem || !isEditEnabled) return null;

  const [valid, setValid] = useState(true);

  const isMonthly = size === 'month';

  const className = classNames(`k-scheduleBlock k-scheduleBlock--${size} scheduleQuickPlanningNewShift`, {
    'scheduleQuickPlanningNewShift--monthly': isMonthly,
    'scheduleQuickPlanningNewShift--invalid': isFocused && !valid,
  });

  const onPressEnter = useCallback(() => {
    if (workingHours.length !== 11) return;
    const isValid = !inputValidation('timeRanges', workingHours);
    setValid(isValid);
    if (!isValid) return;
    quickPlanningAddNewShifts();
  }, [workingHours]);

  const cancelAddingNewShift = useCallback(() => {
    setWorkingHoursForNewShift('');
  }, []);

  const handleKeyPress = useCallback(
    key => {
      if (key === 'Enter') {
        onPressEnter();
      } else if (key === 'Escape') {
        cancelAddingNewShift();
      }
    },
    [onPressEnter, setWorkingHoursForNewShift],
  );

  const handleChange = useCallback(
    hours => {
      const isValid = !inputValidation('timeRanges', hours);
      setWorkingHoursForNewShift(hours);
      setValid(isValid);
    },
    [setWorkingHoursForNewShift],
  );

  return (
    <div className={className}>
      <SmartTimeRangeTextarea
        value={workingHours}
        handleChange={handleChange}
        isFocused={isFocused}
        initialCursorPosition={workingHours.length}
        rows={isMonthly ? 2 : 1}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
};

ScheduleQuickPlanningNewShift.propTypes = {
  workingHours: PropTypes.string,
  isSelectedItem: PropTypes.bool,
  isQuickPlanningEnabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  setWorkingHoursForNewShift: PropTypes.func,
  size: PropTypes.string,
  quickPlanningAddNewShifts: PropTypes.func,
  isEditEnabled: PropTypes.bool,
};

export default ScheduleQuickPlanningNewShift;
