import { connect } from 'react-redux';

import { getAbsenceHistory } from '@/actions/absences';
import { addShiftForPayroll, showAcceptOvertimeModalForPayroll } from '@/actions/payroll/payroll';
import { showModal } from '@/actions/uiState.js';

import PayrollTableOptions from './PayrollTableOptions';

const mapStateToProps = state => ({
  selectedEmployee: state.reducer.singleEmployeeFilter,
  userPermissions: state.reducer.userPermissions,
  payrollMode: state.reducer.payroll.mode,
  currentUser: state.reducer.currentUser,
  companyRoles: state.reducer.roles.companyRoles,
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  showModal,
  getAbsenceHistory,
  showAcceptOvertimeModalForPayroll,
  addShiftForPayroll,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTableOptions);
