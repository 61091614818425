import classNames from 'classnames';

import { getBem } from '@/utils/styles';
import { isNumberType } from '@/utils/variableTypes/variableTypes';

export const GROUP_INDENTATION = 30;

const bem = getBem('k-groupBar');

export const getClasses = (isOpen: boolean, recordsAmount?: number) =>
  ({
    tr: classNames(
      bem.element('tr'),
      !isOpen && bem.elementModifier('tr', 'closed'),
      !isNumberType(recordsAmount) && bem.elementModifier('tr', 'hasChildren'),
    ),
    td: bem.element('td'),
    iconContainer: bem.element('iconContainer'),
    icon: classNames('material-icons', bem.element('icon'), isOpen && bem.elementModifier('icon', 'open')),
    recordsAmount: bem.element('recordsAmount'),
  }) as const;
