import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium/index.js';
import Toggle from '@/components/common/inputs/Toggle.jsx';
import { FILTER_ICON_NAME } from '@/constants/iconName';
import { checkArrayIntersection } from '@/utils/arrayHelpers';

import { messages } from './scheduleSettingsButton.messages';

import './scheduleSettingsButton.scss';

const ScheduleSettingsButton = (
  {
    scheduleSettings,
    selectedDisplayModeType,
    locationSettings,
    toggleScheduleSettings,
    restrictions,
    scheduleLocationFilter,
    iconName,
    iconType,
    toggleAllScheduleSettings,
  },
  { intl },
) => {
  const onChange = setting => toggleScheduleSettings(setting);

  const filteredScheduleSettings = scheduleSettings
    .filter(
      setting =>
        setting.displayModes.includes(selectedDisplayModeType) &&
        setting.showFunc &&
        setting.showFunc(locationSettings, scheduleLocationFilter),
    )
    .sort((a, b) => intl.formatMessage(a.displayText).localeCompare(intl.formatMessage(b.displayText)));

  const isVisibilityFilterButton = iconName === FILTER_ICON_NAME;

  const numberOfActiveSettings = useMemo(() => {
    if (isVisibilityFilterButton) {
      return filteredScheduleSettings.filter(({ value }) => value).length;
    }
    return 0;
  }, [isVisibilityFilterButton, filteredScheduleSettings]);

  const iconNameDependOnButtonType =
    isVisibilityFilterButton && numberOfActiveSettings > 0 ? `${iconName}_${numberOfActiveSettings}` : iconName;

  const isButtonDisabled = filteredScheduleSettings.length === 0;

  const isFocused = useMemo(() => {
    if (isVisibilityFilterButton) {
      return !filteredScheduleSettings.every(setting => setting.value);
    }
    return false;
  }, [isVisibilityFilterButton, filteredScheduleSettings]);

  const classNames = classnames('k-scheduleSettingsButton', {
    'k-scheduleSettingsButton--focused': isFocused,
  });

  const iconClassNames = isVisibilityFilterButton ? 'k-scheduleSettingsButton__icon' : 'k-actionsButton__icon';

  const selectedAllSettings = filteredScheduleSettings.every(({ value }) => value);
  return (
    <div className={classNames}>
      <ActionsButton
        icon={<Icon type={iconType} name={iconNameDependOnButtonType} className={iconClassNames} />}
        buttonTitle=""
        disabled={isButtonDisabled}
      >
        {filteredScheduleSettings.map(setting => (
          <div key={setting.id} className="k-actionsButton__element">
            <FreemiumWrapper
              freemiumRestrictions={setting.freemiumRestrictions}
              intercomEvent={setting.intercomFreemiumEvent}
            >
              <div key={setting.id} className="k-actionsButton__element--spaceBetween">
                <div className="k-actionsButton__elementLeftLabel">{intl.formatMessage(setting.displayText, {})}</div>
                {checkArrayIntersection(restrictions, setting.freemiumRestrictions || []) ? (
                  <FreemiumStar />
                ) : (
                  <Toggle
                    modifiers="small"
                    labelId={setting.type}
                    value={setting.value}
                    onChangeFunc={() => {
                      onChange(setting);
                    }}
                    disabled={setting.disabled}
                  />
                )}
              </div>
            </FreemiumWrapper>
          </div>
        ))}
        {isVisibilityFilterButton && (
          <button
            onClick={() => toggleAllScheduleSettings(filteredScheduleSettings)}
            className="k-actionsButton__element"
          >
            {intl.formatMessage(messages.sellectAll, { selectedAllSettings })}
          </button>
        )}
      </ActionsButton>
    </div>
  );
};

ScheduleSettingsButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleSettingsButton.defaultProps = {
  iconName: 'settings',
  iconType: 'kadro',
};

ScheduleSettingsButton.propTypes = {
  scheduleSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDisplayModeType: PropTypes.string,
  locationSettings: PropTypes.shape({}),
  toggleScheduleSettings: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  toggleAllScheduleSettings: PropTypes.func,
};

export default ScheduleSettingsButton;
