import { useState } from 'react';

const useBlockingActions = (actions = {}) => {
  const [blockedActions, setBlockedActions] = useState(actions);

  const runBlockingAction = (fn, action) => async (...args) => {
    if (blockedActions[action]) return;
    setBlockedActions(prev => ({ ...prev, [action]: true }));
    try {
      await fn(...args);
    } finally {
      setBlockedActions(prev => ({ ...prev, [action]: false }));
    }
  };

  return { blockedActions, runBlockingAction };
};

export default useBlockingActions;
