import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes';
import { GetCustomFieldsDefinitionsAction } from '@/types/actions/customFields';
import { CustomFieldDefinition } from '@/types/customFields.types';

type Action = GetCustomFieldsDefinitionsAction;

const initialState: CustomFieldDefinition[] = [];

const customFieldsDefinition = (state = initialState, action: Action) =>
  produce(state, () => {
    switch (action.type) {
      case actionTypes.GET_CUSTOM_FIELDS_DEFINITIONS_SUCCESSFUL:
        return action.payload.custom_fields_definitions;

      default:
        return state;
    }
  });

export default customFieldsDefinition;
