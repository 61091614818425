import PropTypes from 'prop-types';

import Popover from './Popover/Popover.jsx';

const HoverPopOver = ({
  children,
  isDark,
  noArrow,
  popover,
  position,
  popoverStyle,
  wrapperStyle,
  xOffset,
  yOffset,
  wrapperClassName,
}) => (
  <Popover
    position={position}
    showOnHover
    hideOnClick
    showShadow={false}
    popoverStyle={{ padding: 0, pointerEvents: 'none', ...popoverStyle }}
    wrapperStyle={wrapperStyle}
    wrapperClassName={wrapperClassName}
    isDark={isDark}
    noArrow={noArrow}
    xOffset={xOffset}
    yOffset={yOffset}
    content={
      <div className="hoverPopOver">
        {popover.title && <div className="hoverPopOver__title">{popover.title}</div>}
        <div className="hoverPopOver__content">{popover.content}</div>
      </div>
    }
  >
    {children}
  </Popover>
);
HoverPopOver.defaultProps = {
  wrapperStyle: {},
  popoverStyle: {},
  position: 'top',
};
HoverPopOver.propTypes = {
  popover: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  children: PropTypes.node,
  isDark: PropTypes.bool,
  noArrow: PropTypes.bool,
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
  popoverStyle: PropTypes.shape({}),
  wrapperStyle: PropTypes.shape({}),
  position: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default HoverPopOver;
