import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDMultiSelect, MDMultiSelectFooter, MDMultiSelectHeader } from '../inputs/MDComponents.jsx';

const MDEmployeeSelectBlock = ({
  neededCount,
  options,
  selectedItems,
  shouldBeSorted,
  handleChange,
  handleSelectAll,
  handleDeselectAll,
}) => (
  <>
    <MDMultiSelectHeader
      title={<FormattedMessage id="openShift.addModal.employees" defaultMessage="Pracownicy" />}
      selectAll={handleSelectAll}
      deselectAll={handleDeselectAll}
      selectAllMessage={<FormattedMessage id="openShift.addModal.selectAllButton" defaultMessage="Dostępni" />}
      deselectAllMessage={<FormattedMessage id="openShift.addModal.deselectAllButton" defaultMessage="Żaden" />}
    />
    <MDMultiSelect
      label={<FormattedMessage id="openShift.requestModal.chooseEmployees" defaultMessage="Wybierz pracowników" />}
      options={options}
      onChange={handleChange}
      shouldBeSorted={shouldBeSorted}
    />
    <MDMultiSelectFooter
      title={
        <FormattedMessage
          id="openShift.addModal.employeeMultiSelectTitle"
          defaultMessage="Wybrani pracownicy ({left}/{needed}) :"
          values={{
            left: selectedItems.length,
            needed: neededCount,
          }}
        />
      }
      selectedItems={selectedItems}
      deleteItem={handleChange}
    />
  </>
);

MDEmployeeSelectBlock.propTypes = {
  neededCount: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  shouldBeSorted: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
};

export default MDEmployeeSelectBlock;
