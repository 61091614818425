import PropTypes from 'prop-types';
import { useEffect } from 'react';

import LabeledTextInput from '@/components/common/inputs/LabeledTextInput.jsx';
import { MDTooltip } from '@/components/common/inputs/MDComponents';
import { parseMinutesToHumanForm } from '@/utils/dateHelper';

import { messages } from '../LoadBudgetTargetModal.messages';
import { calculateColBudget, calculateSpmhBudget } from './LoadBudgetTargetModalCalculate.helpers';

import './loadBudgetTargetModalCalculate.scss';

const LoadBudgetTargetModalCalculate = ({ formData, setFormData, isEnableBudgetTarget }, { intl }) => {
  const { colTarget, spmhTarget, estimatedBudget } = formData;
  const colBudget = calculateColBudget(colTarget, estimatedBudget);
  const spmhBudget = calculateSpmhBudget(spmhTarget, estimatedBudget);
  const estimatedBudgetError =
    Number(estimatedBudget) === 0 && intl.formatMessage(messages.estimatedBudgetMustBePositive);

  const onChange = e => {
    const { target } = e;
    setFormData(prevState => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      colBudget,
      spmhBudget,
    }));
  }, [colBudget, spmhBudget]);

  return (
    <div className="k-loadBudgetTargetModalCalculate">
      <div className="k-loadBudgetTargetModalCalculate__inputGroup--budgetEstimates">
        <LabeledTextInput
          value={formData.estimatedBudget}
          type="number"
          onChange={onChange}
          id="estimatedBudget"
          label={intl.formatMessage(messages.estimatedBudget)}
          error={estimatedBudgetError}
          disabled={!isEnableBudgetTarget}
        />
        <div className="k-loadBudgetTargetModal__suffix k-loadBudgetTargetModal__suffix--budgetEstimates">
          {intl.formatMessage(messages.budgetSuffix)}
        </div>
      </div>
      <div className="k-loadBudgetTargetModalCalculate__inputGroup">
        <LabeledTextInput
          value={formData.spmhTarget}
          type="number"
          onChange={onChange}
          id="spmhTarget"
          label={intl.formatMessage(messages.spmhTarget)}
          disabled={!isEnableBudgetTarget || estimatedBudgetError}
        />
        <div className="k-loadBudgetTargetModal__suffix">{intl.formatMessage(messages.spmhSuffix)}</div>

        <div className="k-loadBudgetTargetModalCalculate__result">
          <span className="k-loadBudgetTargetModalCalculate__resultLabel">
            {intl.formatMessage(messages.spmhBudget)}
          </span>

          <span className="k-loadBudgetTargetModalCalculate__value">
            {spmhBudget || spmhBudget === 0
              ? parseMinutesToHumanForm(spmhBudget)
              : intl.formatMessage(messages.noSpmhBudget)}
            <MDTooltip text={intl.formatMessage(messages.spmhBudgetTooltip)} />
          </span>
        </div>
      </div>
      <div className="k-loadBudgetTargetModalCalculate__inputGroup">
        <LabeledTextInput
          value={formData.colTarget}
          type="number"
          onChange={onChange}
          id="colTarget"
          label={intl.formatMessage(messages.colTarget)}
          disabled={!isEnableBudgetTarget || estimatedBudgetError}
        />
        <div className="k-loadBudgetTargetModal__suffix">{intl.formatMessage(messages.colSuffix)}</div>
        <div className="k-loadBudgetTargetModalCalculate__result">
          <span className="k-loadBudgetTargetModalCalculate__resultLabel">
            {intl.formatMessage(messages.colBudget)}
          </span>

          <span className="k-loadBudgetTargetModalCalculate__value">
            {colBudget || colBudget === 0 ? colBudget : '_'}
            {intl.formatMessage(messages.currency)}
            <MDTooltip text={intl.formatMessage(messages.colBudgetTooltip)} />
          </span>
        </div>
      </div>
      {!isEnableBudgetTarget && (
        <div className="k-loadBudgetTargetModal__budgetTargetDisabled">
          {intl.formatMessage(messages.editBudgetTargetDisabled)}
        </div>
      )}
    </div>
  );
};

LoadBudgetTargetModalCalculate.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LoadBudgetTargetModalCalculate.propTypes = {
  formData: PropTypes.shape({
    estimatedBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    colBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    spmhBudget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  setFormData: PropTypes.func,
  isEnableBudgetTarget: PropTypes.bool,
};

export default LoadBudgetTargetModalCalculate;
