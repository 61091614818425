import { defineMessages } from 'react-intl';

import { accentColors, blockColors, cornerColors, icons } from '@/constants/availabilityVisuals';
import { getContrastYIQv2 } from '@/utils/colorHelpers';

const titleMessages = defineMessages({
  availability: {
    id: 'common.availabilityType.availabilityTitle',
    defaultMessage: 'Dostępny',
  },
  unavailability: {
    id: 'common.availabilityType.unavailabilityTitle',
    defaultMessage: 'Niedostępny',
  },
  freetime: {
    id: 'common.availabilityType.freetimeTitle',
    defaultMessage: 'Dostępny',
  },
  customUndefined: {
    id: 'common.availabilityType.undefinedTitle',
    defaultMessage: 'Nieznana dostępność!',
  },
});

const contentMessages = defineMessages({
  availability: {
    id: 'common.availabilityType.availabilityDescription',
    defaultMessage: '{employeeName} jest dostępny w tym dniu.',
  },
  unavailability: {
    id: 'common.availabilityType.unavailabilityDescription',
    defaultMessage: '{employeeName} jest niedostępny w tym dniu.',
  },
  freetime: {
    id: 'common.availabilityType.freetimeDescription',
    defaultMessage: '{employeeName} jest dostępny w tym dniu w godzinach {hours}',
  },
  customUndefined: {
    id: 'common.availabilityType.undefinedDescription',
    defaultMessage: 'Ten typ dostępności własnej został usunięty!',
  },
});

const messagesCustomTypes = defineMessages({
  customIsDraft: {
    id: 'schedule.availabilityCorn.customIsDraft',
    defaultMessage: 'Dostępność nie została zatwierdzona',
  },
  customRequiresTime: {
    id: 'schedule.availabilityCorn.customHours',
    defaultMessage: 'W godzinach {hours}',
  },
  customOptionalComment: {
    id: 'schedule.availabilityCorn.customCommentary',
    defaultMessage: 'Komentarz: {comment}',
  },
});

const generateCustomAvailabilityText = ({ draft, hours, optional_comment: optionalComment }) => {
  const returnContent = [];
  if (draft) {
    returnContent.push(messagesCustomTypes.customIsDraft);
  }
  if (hours) {
    returnContent.push(messagesCustomTypes.customRequiresTime);
  }
  if (optionalComment) {
    returnContent.push(messagesCustomTypes.customOptionalComment);
  }
  return returnContent;
};

const getPopoverMessages = avability => {
  if (typeof avability === 'undefined') {
    return {
      title: titleMessages.customUndefined,
      content: [contentMessages.customUndefined],
    };
  }

  if (avability.type === 'custom') {
    return {
      title: avability.typeName.id
        ? avability.typeName
        : { id: `availabilityType.unionFormat.${avability.typeName}`, defaultMessage: avability.typeName },
      content: generateCustomAvailabilityText(avability),
    };
  }

  return {
    title: titleMessages[avability.type],
    content: [contentMessages[avability.type]],
  };
};

export const getAvaCornerPopover = (avability, employee, intl) => {
  const { hours, optional_comment: comment } = avability;
  const { first_name: firstName, last_name: lastName } = employee;
  const { title, content } = getPopoverMessages(avability);
  const employeeName = `${firstName} ${lastName}`;

  return {
    title: intl.formatMessage(title),
    content: content.map(message => intl.formatMessage(message, { employeeName, hours, comment })),
  };
};

export const extractAvailabilityBlockDataFromScheduleData = employees => {
  const data = {};
  employees.forEach(employee => {
    const { id, availability_blocks: avaBlocks } = employee;
    data[id] = {};
    avaBlocks.forEach(block => {
      const {
        id: avaBlockId,
        color,
        typeName,
        shortTypeName,
        type,
        hours,
        draft,
        optional_comment: comment,
        date,
        type_id: typeId,
        popover,
      } = block;
      const blockColor = blockColors[type] || color || '#ff0000';
      data[id][date] = {
        id: avaBlockId,
        cornerColor: cornerColors[type] || color || '#ff0000',
        blockColor,
        accentColor: accentColors[type] || getContrastYIQv2(blockColor),
        icon: icons[type],
        typeName,
        shortTypeName,
        type,
        hours,
        draft,
        comment,
        typeId,
        popover,
      };
    });
  });
  return data;
};

export const updateAvaCornerState = (state, availability) => {
  const {
    date,
    employee,
    employee_id: defaultEmployeeId,
    id,
    draft,
    type,
    color,
    hours,
    optional_comment: comment,
    typeName,
    shortTypeName,
    type_id: typeId,
    popover,
  } = availability;

  const employeeId = defaultEmployeeId || employee.id;
  const blockColor = blockColors[type] || color || '#ff0000';
  return {
    ...state,
    [employeeId]: {
      ...state[employeeId],
      [date]: {
        id,
        cornerColor: cornerColors[type] || color || '#ff0000',
        blockColor,
        accentColor: accentColors[type] || getContrastYIQv2(blockColor),
        icon: icons[type],
        typeName,
        shortTypeName,
        type,
        hours,
        draft,
        comment,
        typeId,
        popover,
      },
    },
  };
};

export const updateAvailabilityTypeDataForCorners = (state, avaType) => {
  const newState = Object.keys(state).reduce((sum, employeeId) => {
    let didEmployeeChange = false;
    const newEmployeeState = Object.keys(state[employeeId]).reduce((acc, date) => {
      const avaBlock = state[employeeId][date];
      if (avaBlock.typeId === avaType.id) {
        didEmployeeChange = true;
        return { ...acc, [date]: { ...avaBlock, color: avaType.color, typeName: avaType.name } };
      }
      return { ...acc, [date]: avaBlock };
    }, {});
    if (didEmployeeChange) return { ...sum, [employeeId]: newEmployeeState };
    return { ...sum, [employeeId]: state[employeeId] };
  }, {});
  return newState;
};

export const deleteAvailabilitiesFromScheduleData = (state, payload) => {
  const newState = { ...state };
  Object.keys(newState).forEach(employeeId => {
    Object.keys(newState[employeeId]).forEach(date => {
      const availabilityBlock = newState[employeeId][date];
      if (payload.includes(availabilityBlock?.id)) delete newState[employeeId][date];
    });
  });
  return { ...state, ...newState };
};
