import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Notification from './Notification.jsx';

const NotificationList = props => {
  let renderedNotification = props.userNotifications
    .slice(0, 10)
    .map(notification => (
      <Notification
        key={notification.id}
        notification={notification}
        notificationToggleReadStatus={props.notificationToggleReadStatus}
        notificationGoToContext={props.notificationGoToContext}
      />
    ));
  if (props.userNotifications.length === 0) {
    renderedNotification = (
      <Notification
        key="empty"
        notificationToggleReadStatus={() => null}
        notificationGoToContext={() => null}
        notification={{
          level: 'success',
          datetime: '2016-12-12',
          message: <FormattedMessage id="notification.list.noNotification" defaultMessage="Brak notyfikacji!" />,
        }}
      />
    );
  }
  return (
    <ul style={{ overflowY: 'auto', maxHeight: '80vh' }} className="notify_list">
      {renderedNotification}
    </ul>
  );
};

NotificationList.propTypes = {
  notificationToggleReadStatus: PropTypes.func,
  notificationGoToContext: PropTypes.func,
  userNotifications: PropTypes.arrayOf(PropTypes.shape({})),
};

// <Notification text="Toster" type="update" date="12.12.2016" />
export default NotificationList;
