import { connect } from 'react-redux';

import { addNewTemplate } from '@/actions/templates.js';
import { hideModal, showModal } from '@/actions/uiState.js';
import { ADD_NEW_TEMPLATE_MODAL } from '@/constants/modalTypes.js';

import AddNewTemplateModal from './AddNewTemplateModal.jsx';

const mapStateToProps = state => ({
  isVisibleModal: state.reducer.uiState.showModal === ADD_NEW_TEMPLATE_MODAL,
  templates: state.reducer.userTemplates.templatesArray,
});

const mapDispatchToProps = {
  hideModal,
  addNewTemplate,
  showModal,
};

const AddTemplateModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddNewTemplateModal);

export default AddTemplateModalContainer;
