import { CHANGE_PAYROLL_SETTING } from '@/constants/ActionTypes.js';
import {
  breaksSettings,
  payoutSettings,
  roundingSettings,
  roundingTypeSettings,
  showSettings,
  timeFormatSettings,
  timeSettings,
} from '@/constants/payrollSettings';

const initialState = {
  payoutSetting: payoutSettings[0],
  roundingSetting: roundingSettings[0],
  startRoundingSetting: roundingSettings[0],
  startRoundingType: roundingTypeSettings[0],
  endRoundingSetting: roundingSettings[0],
  endRoundingType: roundingTypeSettings[0],
  timeSetting: timeSettings[0],
  showPhoto: false,
  showSetting: showSettings[0],
  startTolerance: 0,
  endTolerance: 0,
  nighthours: '',
  breaksSetting: breaksSettings[0],
  breaksValue: 0,
  timeFormatSetting: timeFormatSettings[0],
  lateStartTolerance: 0,
  earlyEndTolerance: 0,
  errors: {},
};

const payrollSettings = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PAYROLL_SETTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default payrollSettings;
