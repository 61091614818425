import { ADD_NEW_TEMPLATE_MODAL, ADD_TEMPLATE_FROM_EXISTING_MODAL } from '@/constants/modalTypes.js';

import { messages } from './ChooseAddTemplateVersionModal.messages';

export const MODAL_MODIFIERS = ['narrow'];

export const TEMPLATES_VERSIONS = {
  new: { id: 'new', modalType: ADD_NEW_TEMPLATE_MODAL },
  fromExististing: { id: 'fromExististing', modalType: ADD_TEMPLATE_FROM_EXISTING_MODAL },
};

export const getTemplatesVersionsOptions = intl => [
  {
    name: intl.formatMessage(messages.newTemplateName),
    type: TEMPLATES_VERSIONS.new.id,
    description: intl.formatMessage(messages.newTemplateDescription),
  },
  {
    name: intl.formatMessage(messages.fromExististingTemplateName),
    type: TEMPLATES_VERSIONS.fromExististing.id,
    description: intl.formatMessage(messages.fromExististingTemplateDescription),
  },
];
