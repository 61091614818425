export const EXPORT_ACTIONS = {
  delete: 'delete',
  download: 'download',
};

const MAX_LABEL_LENGTH = 100;

const getLabelForItems = items => {
  const labelsDetails = items.reduce(
    (prev, item) => {
      const allLabels = [...prev.allLabels, item];
      if (prev.allLabels.join(', ').length > MAX_LABEL_LENGTH)
        return {
          allLabels,
          visibleLabels: prev.visibleLabels,
          notVisibleLabels: [...prev.notVisibleLabels, item],
        };
      return { allLabels, visibleLabels: [...prev.visibleLabels, item], notVisibleLabels: prev.notVisibleLabels };
    },
    { allLabels: [], notVisibleLabels: [], visibleLabels: [] },
  );
  const visibleLabels = labelsDetails.visibleLabels.join(', ');
  const dotsForVisible = visibleLabels.length > MAX_LABEL_LENGTH;
  const visible = dotsForVisible ? `${visibleLabels.slice(0, MAX_LABEL_LENGTH + 3)}...` : visibleLabels;
  const additional =
    labelsDetails.notVisibleLabels.length > 0
      ? `${!dotsForVisible ? '...' : ''} (+${labelsDetails.notVisibleLabels.length})`
      : '';
  return `${visible}${additional}`;
};

export const getJobTitlesText = (jobTitleIds, allJobTitles) => {
  const jobTitleNames = jobTitleIds.map(id => allJobTitles[id]?.title).filter(Boolean);

  return getLabelForItems(jobTitleNames);
};

export const getLocationsText = (locations, userLocations) => {
  const locationNames = locations
    .map(id => {
      const name = userLocations.find(loc => loc.id === id)?.name;
      return name;
    })
    .filter(Boolean);

  return getLabelForItems(locationNames);
};
