import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tabNameAddShift: {
    id: 'absence.tab.addShift',
    defaultMessage: 'Dodaj zmianę',
  },
  tabNameEditShift: {
    id: 'absence.tab.editShift',
    defaultMessage: 'Edytuj zmianę',
  },
  tabNameAbsence: {
    id: 'absence.tab.absence',
    defaultMessage: 'Dodaj urlop',
  },
  tabNameAbsenceShorterAbsenceName: {
    id: 'absence.tab.absenceAndTraining',
    defaultMessage: 'Dodaj Absencje/Szkolenia PSD',
  },
  tabNameMarkFreeDay: {
    id: 'freeDay.tab.markFreeDay',
    defaultMessage: 'Oznacz dzień wolny',
  },
  tabNameMarkFreeDayEdit: {
    id: 'freeDay.tab.editFreeDay',
    defaultMessage: 'Edytuj dzień wolny',
  },
  absenceHoursError: {
    id: 'error.absences.hours',
    defaultMessage: 'Godziny urlopu są wymagane',
  },
  deleteOvertimeCollection: {
    id: 'addShiftAndAbsenceModal.footer.deleteOvertimeCollection',
    defaultMessage: 'Usuń odbiór nadgodzin',
  },
  editOvertimeCollection: {
    id: 'addShiftAndAbsenceModal.title.editOvertimeCollection',
    defaultMessage: 'Edytuj odbiór nadgodzin',
  },
  deleteShift: {
    id: 'addShiftAndAbsenceModal.footer.deleteShift',
    defaultMessage: 'Usuń zmianę',
  },
  deleteFreeDay: {
    id: 'addShiftAndAbsenceModal.footer.deleteFreeDay',
    defaultMessage: 'Usuń oznaczenie',
  },
  history: {
    id: 'addShiftAndAbsenceModal.footer.history',
    defaultMessage: 'Historia',
  },
});
