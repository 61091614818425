import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';

const WorkingRulesIcon = props => (
  <div className="k-workingRulesIcon">
    {props.number ? (
      <div>
        <Icon name="warning" className="k-workingRulesIcon__icon k-icon-warning" />
        <div className="k-workingRulesIcon__counter">
          <span className="k-workingRulesIcon__number">{props.number}</span>
        </div>
      </div>
    ) : (
      <div className="k-workingRulesIcon__iconBackground">
        <i className="k-workingRulesIcon__icon k-workingRulesIcon__icon--check material-icons">check</i>
      </div>
    )}
  </div>
);

WorkingRulesIcon.propTypes = {
  number: PropTypes.number,
};

export default WorkingRulesIcon;
