import { connect } from 'react-redux';

import { deleteEmploymentConditionConfirm } from '@/actions/companymanage/employmentConditions';
import { showModal } from '@/actions/uiState.js';

import EmploymentConditionsTableRow from './EmploymentConditionsTableRow.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  deleteEmploymentConditionConfirm,
};

const EmploymentConditionsTableRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentConditionsTableRow);

export default EmploymentConditionsTableRowContainer;
