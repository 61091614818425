import { connect } from 'react-redux';

import { changePayrollDaysStatus, getPayrollViewData } from '@/actions/payroll/payroll.js';
import PayrollButtonBar from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollButtonBar/PayrollButtonBar';

const mapStateToProps = ({ reducer }) => ({
  isLoadButtonEnabled: reducer.payroll.isLoadButtonEnabled,
  payrollApprovalEnabled: reducer.currentCompany.settings.payroll_approval_enabled,
});

const mapDispatchToProps = {
  getPayrollViewData,
  changePayrollDaysStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollButtonBar);
