import { connect } from 'react-redux';

import { setSortingColumn } from '@/actions/dashboard';

import DashboardTeamHeader from './DashboardTeamHeader.jsx';

const mapStateToProps = state => ({
  teamSorting: state.reducer.dashboard.team,
});

const mapDispatchToProps = {
  setSortingColumn,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTeamHeader);
