import classnames from 'classnames';
import { PropTypes } from 'prop-types';

const FormatIcon = props => {
  const componentClass = classnames('formatIconContainer', {
    selected: props.selected,
    'formatIconContainer--withoutHoverEffect': props.withoutHoverEffect,
  });
  return (
    <div className={componentClass}>
      <div
        role="presentation"
        className="formatIcon"
        style={{ backgroundColor: props.backgroundColor, color: props.color, borderColor: props.color }}
        onClick={props.onClick}
      >
        <div className="formatIcon__textContainer">
          <div className="formatIcon__title"> {props.text}</div>
          <div className="formatIcon__subtitle">{props.subtitle}</div>
        </div>

        <div className="cornMask" />
        <div className="fileCorn" style={{ borderBottomColor: props.color, borderLeftColor: props.color }} />
      </div>
    </div>
  );
};

FormatIcon.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  withoutHoverEffect: PropTypes.bool,
  subtitle: PropTypes.string,
};
export default FormatIcon;
