import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon';

import { getButtonStatus, getFinishedExports } from './ExportsWidgetButton.helpers';
import ExportsWidgetStatusIcon from './ExportsWidgetStatusIcon/ExportsWidgetStatusIcon';

import './ExportsWidgetButton.scss';

const messages = defineMessages({
  buttonTitle: {
    id: 'exportsWidget.button.title',
    defaultMessage: 'Generowanie eksportu',
  },
});

const ExportsWidgetButton = (
  { pendingExports, isWidgetBodyVisible, setIsWidgetBodyVisible, removeAllExportsFromWidget },
  { intl },
) => {
  const { isAnyError, finishedExports } = useMemo(() => getFinishedExports(pendingExports), [pendingExports]);

  const iconStatus = useMemo(() => getButtonStatus(isAnyError, pendingExports.length, finishedExports), [
    isAnyError,
    finishedExports,
    pendingExports,
  ]);

  const exportsAmount = `${finishedExports}/${pendingExports.length}`;

  return (
    <button onClick={() => setIsWidgetBodyVisible(!isWidgetBodyVisible)} className="k-exportsWidgetButton">
      <ExportsWidgetStatusIcon status={iconStatus} />
      <span className="k-exportsWidgetButton__title">{intl.formatMessage(messages.buttonTitle)}</span>
      <span className="k-exportsWidgetButton__amount">
        {exportsAmount}
      </span>
      <button className="k-exportsWidgetButton__closeIcon" onClick={removeAllExportsFromWidget}>
        <Icon name="close" type="material" />
      </button>
    </button>
  );
};

ExportsWidgetButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
ExportsWidgetButton.propTypes = {
  pendingExports: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      isShownInExportsWidget: PropTypes.bool,
      link: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  isWidgetBodyVisible: PropTypes.bool,
  setIsWidgetBodyVisible: PropTypes.func,
  removeAllExportsFromWidget: PropTypes.func,
};

export default ExportsWidgetButton;
