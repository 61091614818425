import PropTypes from 'prop-types';

import ExportModal from '@/components/common/exports/ExportModal/ExportModal.redux';
import {
  FILE_CSV_BACKGROUND,
  FILE_CSV_COLOR,
  FILE_PDF_BACKGROUND,
  FILE_PDF_COLOR,
  FILE_XLS_BACKGROUND,
  FILE_XLS_COLOR,
} from '@/constants/colors';
import { payrollExportData } from '@/constants/exportData.js';
import { getPreselected } from '@/utils/exports/exportHelpers';

const mergeInDay = {
  type: 'checkbox',
  id: 'mergeInDay',
  text: 'Połącz obecności dla danego dnia',
};

const availabilities = {
  type: 'checkbox',
  id: 'availabilities',
  text: 'Dostępności',
};

const absences = {
  type: 'checkbox',
  id: 'absences',
  text: 'Nieobecności',
};

const defaultPreselected = [0, 1, 2, 3, 4, 5, 7, 9, 16];

const columnList = (columns, format) => ({
  type: 'columnList',
  id: 'selectedColumns',
  values: columns.headers,
  preselected: getPreselected(format, 'payroll', defaultPreselected),
  hidden: columns.hidden,
});

const freeDays = {
  type: 'checkbox',
  id: 'freeDays',
  text: 'Dni wolne',
};

const ExportPayrollModal = props => {
  const relevantColumns = payrollExportData.headers.reduce(
    (result, header, index) => {
      const columnPermission = payrollExportData.columnsPermission[index];
      const newHeaders = [...result.headers, header];

      if (columnPermission && !props.userPermissions.includes(columnPermission)) {
        return {
          headers: newHeaders,
          hidden: [...result.hidden, index],
        };
      }

      return {
        headers: newHeaders,
        hidden: result.hidden,
      };
    },
    { headers: [], hidden: [] },
  );

  return (
    <ExportModal
      {...props}
      formats={[
        {
          text: 'CSV',
          backgroundColor: FILE_CSV_BACKGROUND,
          color: FILE_CSV_COLOR,
          options: [],
        },
        {
          text: 'XLS',
          backgroundColor: FILE_XLS_BACKGROUND,
          color: FILE_XLS_COLOR,
          options: [mergeInDay, freeDays, availabilities, absences],
        },
        {
          text: 'PDF',
          backgroundColor: FILE_PDF_BACKGROUND,
          color: FILE_PDF_COLOR,
          options: [freeDays, columnList(relevantColumns, 'PDF')],
        },
      ]}
      showFormatsAsList
    />
  );
};

ExportPayrollModal.propTypes = {
  userPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default ExportPayrollModal;
