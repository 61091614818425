import {
  AUTH_USER_ERROR,
  AUTH_USER_LOGOUT,
  AUTH_USER_SUCCESSFUL,
  BLOCKED_NOT_OWNER_ACCOUNT,
  CHANGE_USER_ROLE_SUCCESS,
  CHOOSE_ONBOARDING_OPTION,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESFUL,
  SET_NEW_COMPANY_DETAILS,
  SET_USER_RESTICTIONS_SUCCESSFUL,
  UPDATE_CURRENT_USER_LANGUAGE_SUCCESFUL,
  UPDATE_CURRENT_USER_SUCCESFUL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_MASS_SUCCESS,
} from '@/constants/ActionTypes.js';
import { ACCOUNT_DISABLE } from '@/constants/Restrictions.js';
import { isEmptyObject } from '@/utils/baseHelpers';

const initialState = {
  user: {},
  authenticated: false,
  disabled: false,
};

const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_ERROR:
      throw action.payload;
    case AUTH_USER_SUCCESSFUL: {
      return {
        ...state,
        authenticated: true,
      };
    }
    case GET_CURRENT_USER_SUCCESFUL: {
      const { user } = action.payload;
      const newStateWithUser = {
        ...state,
        user,
      };
      return newStateWithUser;
    }
    case GET_CURRENT_USER_ERROR: {
      const { payload } = action;
      return { ...state, user: { ...state.user, ...payload } };
    }
    case UPDATE_EMPLOYEE: {
      const hasCurrUserChanged = state.user.id === action.payload.id;
      return hasCurrUserChanged ? { ...state, user: { ...state.user, ...action.payload } } : state;
    }
    case UPDATE_EMPLOYEE_MASS_SUCCESS: {
      const newCurrentUserData = action.payload.employees.find(({ id }) => id === state.user.id);
      return newCurrentUserData && !isEmptyObject(newCurrentUserData)
        ? { ...state, user: { ...state.user, ...newCurrentUserData } }
        : state;
    }
    case CHANGE_USER_ROLE_SUCCESS: {
      const changedCurrentUser = action.payload.find((user) => state.user.id === user.id);
      return changedCurrentUser ? { ...state, user: { ...state.user, ...changedCurrentUser } } : state;
    }
    case UPDATE_CURRENT_USER_SUCCESFUL: {
      const { user } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          ...user,
        },
      };
    }
    case UPDATE_CURRENT_USER_LANGUAGE_SUCCESFUL: {
      const language = action.payload;
      return { ...state, user: { ...state.user, settings: { ...state.user.settings, language } } };
    }
    case AUTH_USER_LOGOUT: {
      const clearState = { ...initialState };
      clearState.authenticated = false;
      return clearState;
    }
    case SET_USER_RESTICTIONS_SUCCESSFUL: {
      return { ...state, disabled: action.payload.includes(ACCOUNT_DISABLE) };
    }
    case SET_NEW_COMPANY_DETAILS: {
      return { ...state, newCompanyDetails: action.payload };
    }
    case CHOOSE_ONBOARDING_OPTION:
      return {
        ...state,
        onboardingOption: action.payload,
      };
    case BLOCKED_NOT_OWNER_ACCOUNT: {
      const notOwnerBlocked = action.payload;
      return { ...state, user: { ...state.user, notOwnerBlocked } };
    }
    default:
      return state;
  }
};
export default currentUser;
