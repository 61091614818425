import PropTypes from 'prop-types';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import YearCalendar from '@/components/common/YearCalendar/YearCalendar';

import AbsenceCalendarDay from './AbsenceCalendarDay';
import AbsenceCalendarModalHeader from './AbsenceCalendarModalHeader';

import './absenceCalendarModal.scss';

const AbsenceCalendarModal = ({ modalObject, showModal, hideModal, year }) => {
  if (!modalObject) return null;

  return (
    <MDKadroModal
      show={showModal}
      onHide={hideModal}
      title={<AbsenceCalendarModalHeader modalObject={modalObject} year={year} />}
      hideFooter
      modifiers={['wide']}
    >
      <div className="absenceCalendarModal">
        <YearCalendar
          data={modalObject.absences}
          year={parseInt(year)}
          customDayRender={(day, data) => <AbsenceCalendarDay day={day} data={data} />}
        />
      </div>
    </MDKadroModal>
  );
};

AbsenceCalendarModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceCalendarModal.propTypes = {
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      employeeFullName: PropTypes.string,
    }),
    absences: PropTypes.shape({
      absence: PropTypes.shape({
        id: PropTypes.number,
        absenceId: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        absenceType: PropTypes.string,
        status: PropTypes.string,
        year: PropTypes.number,
        requestNumber: PropTypes.string,
        createdAt: PropTypes.string,
        employeeFullName: PropTypes.string,
      }),
    }),
  }),
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  year: PropTypes.number,
};

export default AbsenceCalendarModal;
