import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  chartTitle: {
    id: 'locationsChart.chartTitle',
    defaultMessage: 'Dzienny udział lokalizacji',
  },
  otherLocations: { id: 'locationsChart.otherLocations', defaultMessage: 'Pozostałe' },
  tooltipMessage: {
    id: 'locationsChart.tooltipMessage',
    defaultMessage:
      'Udział poszczególnych lokalizacji względem sumarycznej wartości dla danego dnia wg wybranego parametru.',
  },
});
