import { mdiMicrosoftExcel } from '@mdi/js';
import Icon from '@mdi/react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Basic/Button.jsx';
import MDFileDropDragView from './MDFileDropDragView.jsx';
import MDFileDropFileView from './MDFileDropFileView.jsx';

import './MDFileDrop.scss';

const MDFileDropInput = props => {
  const { accept, confirmText, onChange, maxSize, hideButton } = props;

  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState(null);

  const fileInputRef = useRef(null);
  const fileDropRef = useRef(null);

  const showError = newError => {
    setError(newError);
  };

  const handleFiles = useCallback(files => {
    const newFile = files[0];
    if (!newFile) {
      return;
    }

    const extension = newFile.name.split('.').pop();

    if (maxSize && newFile.size > maxSize * 1000000) {
      console.error('File size is too big');
      showError(
        <FormattedMessage
          id="fileDrop.fileSizeToBig"
          defaultMessage="Plik jest zbyt duży ({maxSize} MB max.)"
          values={{ maxSize }}
        />,
      );
    } else if (!accept || accept.includes(extension)) {
      setFile(newFile);
      if (props.onUpload) {
        props.onUpload(newFile);
      }
    } else {
      showError(<FormattedMessage id="fileDrop.badFormat" defaultMessage="Nieobsługiwany typ pliku" />);

      console.error(`Bad file extension .${extension}`);
    }
  }, []);

  const handleDragOver = dragging => {
    setIsDragOver(dragging);
    setError(null);
  };

  const onDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();

    handleDragOver(true);
  };

  const onDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();

    if (fileDropRef.current === e.target) {
      handleDragOver(false);
    }
  };

  const onDrop = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);
    const { files } = e.dataTransfer;
    handleFiles(files);
  };

  const chooseFile = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearFile = () => {
    setFile(null);
    if (props.onUpload) {
      props.onUpload(null);
    }
  };

  const confirmFile = () => {
    setIsImporting(true);
    if (onChange) onChange(file);
    clearFile();
  };

  const fileChange = e => {
    handleFiles(e.target.files);
    e.target.value = null;
  };

  let acceptFormat = '*';
  if (accept) {
    acceptFormat = `.${accept.join(', .')}`;
  }

  const renderContent = () => {
    if (isDragOver) {
      return <MDFileDropDragView />;
    }

    if (isImporting) {
      return (
        <div className="mdFileDrop__loader">
          <img src="/img/loading-spin.svg" alt="loader" />
        </div>
      );
    }

    if (file) {
      return (
        <MDFileDropFileView
          confirmText={confirmText}
          fileName={file.name}
          clearFile={clearFile}
          confirmFile={confirmFile}
          hideButton={hideButton}
        />
      );
    }

    return (
      <>
        {error && <div className="mdFileDrop__errorInfo">{error}</div>}
        <div className="mdFileDrop__text">
          <FormattedMessage id="fileDrop.dropFileHere" defaultMessage="Upuść plik tutaj" />
        </div>
        <div className="mdFileDrop__subText">
          <FormattedMessage id="common.or" defaultMessage="lub" />
        </div>
        <Button onClick={chooseFile} className="mdFileDrop__button" modifiers="reverse-orange small floatright">
          <div className="mdFileDropImportButton__icon">
            <Icon path={mdiMicrosoftExcel} color="#2e7d32" />
          </div>
          <FormattedMessage id="pickFile.importExcel" defaultMessage="Importuj z Excel" />
        </Button>

        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={fileChange} accept={acceptFormat} />
      </>
    );
  };

  const fileDropClassNames = classnames('mdFileDrop', {
    'mdFileDrop--dragOver': isDragOver,
  });

  return (
    <div
      className={fileDropClassNames}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      ref={fileDropRef}
    >
      <div className="mdFileDrop__content">{renderContent()}</div>
    </div>
  );
};

MDFileDropInput.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  confirmText: PropTypes.string,
  maxSize: PropTypes.number,
  onChange: PropTypes.func,
  hideButton: PropTypes.bool,
  onUpload: PropTypes.func,
};

export default MDFileDropInput;
