import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TextInput from '@/components/common/inputs/TextInput.jsx';
import PhotoPreviewContainer from '@/components/common/PhotoPreview/PhotoPreview.redux.js';
import PhotoPreviewPayrollIcon from '@/components/common/PhotoPreview/PhotoPreviewPayrollIcon.redux.js';
import { isDefined } from '@/utils/baseHelpers.js';
import {
  parseMinutesToFormat,
  parseMinutesToHumanForm,
} from '@/utils/dateHelper.js';
import { getLocationNameForId } from '@/utils/payrollHelpers.jsx';

const onBonusBlur = ({ target }, detail, addBonusToAttendance, employeeId) => {
  addBonusToAttendance(detail, parseInt(target.value * 100), employeeId);
};

const onBonusChange = (value, id, employeeId, setBonusValues) => {
  setBonusValues(id, value, employeeId);
};

const PayrollTableRowDetails = (props) => (
  <table className="table">
    {props.colgroup}
    <tbody>
      {props.details
        .sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1))
        .map((detail) => {
          const trStyle = {};
          if (detail.errorHighlight) {
            trStyle.backgroundColor = 'rgba(252, 55, 55, 0.2)';
          }
          const {
            absence_time: detailAbsenceTime,
            attendanceStart,
            attendanceEnd,
            shiftStart,
            shiftEnd,
            startColor,
            endColor,
          } = detail;

          const notFoundLocationText = detail.isAvailability ? '' : null;
          const isAbsenceWithHours =
            (!attendanceStart || attendanceStart === '__:__') &&
            (!shiftStart || shiftStart === '__:__') &&
            detail.absence &&
            detail.absence.absence_hours;
          const absenceMinutes = detailAbsenceTime
            ? Math.round(detailAbsenceTime * 60)
            : 0;
          const absenceTime = parseMinutesToHumanForm(absenceMinutes);
          const items = [
            <td key={0} style={{ backgroundColor: '#eceff1' }} />,
            <td key={1}>
              {detail.location
                ? getLocationNameForId(
                    detail.location.id,
                    props.userLocations,
                    notFoundLocationText
                  )
                : '-'}
            </td>,
            isAbsenceWithHours ? (
              <td key={2}>{detail.absence.absence_hours.slice(0, 5)}</td>
            ) : (
              <td key={2}>
                {!detail.isAvailability ? (
                  <PhotoPreviewContainer
                    attendanceId={detail.id}
                    locationId={detail.location?.id}
                  >
                    <PhotoPreviewPayrollIcon attendanceId={detail.id} />
                  </PhotoPreviewContainer>
                ) : null}
                {detail.disableBonus ? (
                  // When we are displaying form shifts row data has different structure
                  // start_timestamp is form shifts and not attendance
                  // and we have no matching attendance so we put it this way
                  (<span className="attendanceHoursSpan">
                    {attendanceStart}
                    {detail.early_in && (
                      <span className="acceptedOvertimeIcon">N</span>
                    )}
                  </span>)
                ) : (
                  <span>
                    {shiftStart}
                    <br />
                    <span
                      className="attendanceHoursSpan"
                      style={{ color: startColor }}
                    >
                      {attendanceStart}
                      {detail.early_in && (
                        <span className="acceptedOvertimeIcon">N</span>
                      )}
                    </span>
                  </span>
                )}
              </td>
            ),
            isAbsenceWithHours ? (
              <td key={3}>{detail.absence.absence_hours.slice(6, 11)}</td>
            ) : (
              <td key={3}>
                {detail.disableBonus ? (
                  <span className="attendanceHoursSpan">
                    {attendanceEnd}
                    {detail.late_out && (
                      <span className="acceptedOvertimeIcon">N</span>
                    )}
                  </span>
                ) : (
                  <span>
                    {shiftEnd}
                    <br />
                    <span
                      className="attendanceHoursSpan"
                      style={{ color: endColor }}
                    >
                      {attendanceEnd}
                      {detail.late_out && (
                        <span className="acceptedOvertimeIcon">N</span>
                      )}
                    </span>
                  </span>
                )}
              </td>
            ),
            <td key={11}>
              {detail.working_hours ||
                detail.hours ||
                `${
                  detail.start
                    ? detail.start.split(' ')[1].slice(0, 5)
                    : '__:__'
                } - ${
                  detail.end ? detail.end.split(' ')[1].slice(0, 5) : '__:__'
                }`}
            </td>,
            <td key={4}>
              {detail.isOvertimeCollection ? (
                <FormattedMessage
                  id="absences.overtimeCollection"
                  defaultMessage="Odbiór nadgodzin"
                />
              ) : (
                detail.jobTitle || '---'
              )}
            </td>,
            <td key={5}>
              {detail.wage || detail.wage === 0 ? detail.wage : '---'}
            </td>,
            <td key={12}>
              {detail.sumBreaks || detail.sumBreaks === 0
                ? parseMinutesToFormat(
                    detail.sumBreaks,
                    props.payrollSettings.timeFormatSetting.type
                  )
                : '---'}
            </td>,
            <td key={20}>
              {detail.absence_short_name
                ? `${detail.absence_short_name} - ${absenceTime}`
                : '-'}
            </td>,
            <td key={6}>
              {detail.timeWorked || detail.timeWorked === 0
                ? parseMinutesToFormat(
                    detail.timeWorked,
                    props.payrollSettings.timeFormatSetting.type
                  )
                : '---'}
            </td>,
            <td key={13}>---</td>,
            <td key={14}>---</td>,
            <td key={16}>
              {detail.nightTimeWorked || detail.nightTimeWorked === 0
                ? parseMinutesToFormat(
                    detail.nightTimeWorked,
                    props.payrollSettings.timeFormatSetting.type
                  )
                : '---'}
            </td>,
            <td key={9}>{(detail.sumQuotas || 0).toFixed(2)}</td>,
            <td key={7}>
              <TextInput
                onBlur={(e) =>
                  onBonusBlur(
                    e,
                    detail,
                    props.addBonusToAttendance,
                    props.employeeId
                  )
                }
                type="number"
                className="k-payroll__detailsBonus form-control"
                value={
                  isDefined(props.bonusValues[detail.id])
                    ? props.bonusValues[detail.id]
                    : detail.bonus_amount
                }
                disabled={detail.disableBonus}
                onChange={({ target }) =>
                  onBonusChange(
                    target.value,
                    detail.id,
                    props.employeeId,
                    props.setBonusValues
                  )
                }
                isPositive={false}
              />
            </td>,
            detail.absenceType === 'overtime_collection' && (
              <td key={17} colSpan="2">
                {parseMinutesToFormat(
                  -moment(detail.end_timestamp).diff(
                    detail.start_timestamp,
                    'minutes'
                  ),
                  props.payrollSettings.timeFormatSetting.type
                )}
              </td>
            ),
            detail.absenceType !== 'overtime_collection' && (
              <>
                <td key={17}>
                  {detail.overtime?.overtime50 ||
                  detail.overtime?.overtime50 === 0
                    ? parseMinutesToFormat(
                        detail.overtime.overtime50,
                        props.payrollSettings.timeFormatSetting.type
                      )
                    : '-'}
                </td>
                <td key={18}>
                  {detail.overtime?.overtime100 ||
                  detail.overtime?.overtime100 === 0
                    ? parseMinutesToFormat(
                        detail.overtime.overtime100,
                        props.payrollSettings.timeFormatSetting.type
                      )
                    : '-'}
                </td>
              </>
            ),
            <td key={19}>-</td>,
            <td key={8}>
              {detail.sumPaid || detail.sumPaid === 0
                ? detail.sumPaid.toFixed(2)
                : '---'}
            </td>,
          ];

          return (
            <tr key={detail.id} style={trStyle}>
              {items.filter(
                (item, i) => item && props.visibleColumns.includes(i)
              )}
            </tr>
          );
        })}
    </tbody>
  </table>
);

PayrollTableRowDetails.propTypes = {
  colgroup: PropTypes.node,
  details: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.number),
  payrollSettings: PropTypes.shape({
    timeFormatSetting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  addBonusToAttendance: PropTypes.func,
  setBonusValues: PropTypes.func,
  bonusValues: PropTypes.shape({}),
  employeeId: PropTypes.string,
};

export default PayrollTableRowDetails;
