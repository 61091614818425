import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { defineMessages } from 'react-intl';

import EmployeeModal from '@/components/companymanage/employees/EmployeeModal/EmployeeModal/EmployeeModal.redux.js';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import browserHistory from '@/constants/browserHistory';
import { EMPLOYEE_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_TABLE_EMPTY_STATE_STYLES } from '@/constants/scheduleDisplayModes.js';

const messages = defineMessages({
  emptyStateTitle: {
    id: 'scheduleView.emptyStateEmployeeTitle',
    defaultMessage: 'Brak dodanych pracowników ',
  },
  emptyStateMsg: {
    id: 'scheduleView.emptyStateMsg',
    defaultMessage: 'Zanim utworzysz grafik ',
  },
  ctaText: {
    id: 'scheduleView.ctaEmployeeText',
    defaultMessage: 'dodaj pracowników',
  },
});

const EmptyFilterView = ({ showModal, currentUser }, context) => (
  <Fragment>
    <EmployeeModal isManager={currentUser.user.role === 'manager'} />
    <EmptyState
      name="employeeCTA"
      imgSrc="/img/employeesCTA.png"
      emptyStateHeader={context.intl.formatMessage(messages.emptyStateTitle, {})}
      emptyStateText={context.intl.formatMessage(messages.emptyStateMsg, {})}
      ctaHandler={() => {
        browserHistory.push('/companymanage/employees');
        showModal(EMPLOYEE_MODAL);
      }}
      filerStyle={SCHEDULE_TABLE_EMPTY_STATE_STYLES.filerStyle}
      ctaText={context.intl.formatMessage(messages.ctaText, {})}
    />
  </Fragment>
);

EmptyFilterView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmptyFilterView.propTypes = {
  showModal: PropTypes.func,
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
};

export default EmptyFilterView;
