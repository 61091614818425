import { PureComponent } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import SearchBox from '@/components/common/SearchBox.jsx';
import Wrapper from '@/components/common/UI/Wrapper.jsx';
import { COMPANY_MANAGE_DEVICES_HIDE } from '@/constants/Restrictions.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import DevicesButtonBar from '../DevicesButtonBar/DevicesButtonBar.redux';
import AddDeviceModal from '../DevicesModal/AddDeviceModal.redux';
import SettingsDeviceModal from '../DevicesModal/SettingsDeviceModal.redux';
import DevicesTable from '../DevicesTable/DevicesTable.redux';

class DevicesView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };

    bindPrototypeFunctions(this);
  }

  getSearchWord(e) {
    this.setState({ searchString: e.target.value });
  }

  clearSearchWord() {
    this.setState({ searchString: '' });
  }

  render() {
    return (
      <FeatureWrapper restriction={COMPANY_MANAGE_DEVICES_HIDE} permission="COMPANY_MANAGE_DEVICES:SHOW">
        <AddDeviceModal />
        <SettingsDeviceModal />
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchString} />
        <Wrapper>
          <DevicesButtonBar />
          <DevicesTable searchString={this.state.searchString} clearSearchWord={this.clearSearchWord} />
        </Wrapper>
      </FeatureWrapper>
    );
  }
}

export default DevicesView;
