import { FreeDayItem } from '@/redux-store/freeDaysMarking';

import { useScheduleFreeDayBlock } from './useScheduleFreeDayBlock';

import './ScheduleFreeDayBlock.scss';

type Props = {
  freeDay: FreeDayItem;
};

export const ScheduleFreeDayBlock = ({ freeDay }: Props) => {
  const { classes, onDelete, onEdit, shortcut } = useScheduleFreeDayBlock(freeDay);

  return (
    <div className={classes.container}>
      <div className={classes.shortcut}>{shortcut}</div>
      <div className={classes.iconsContainer}>
        <i className={classes.iconDeleteButton} onClick={onDelete} role="presentation">
          delete
        </i>
        <i className={classes.iconButton} onClick={onEdit} role="presentation">
          edit
        </i>
      </div>
    </div>
  );
};
