import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';

import './shiftBlocksHeader.scss';

const ShiftBlocksHeader = props => (
  <thead className="k-shiftBlocksHeader" data-test={props.testId && `${props.testId}-header`}>
    <tr>
      <Checkbox
        className="k-shiftBlocksHeader__checkbox"
        checked={props.stateUI.selected.length > 0 && props.stateUI.selected.length === props.visible.length}
        toggle={() => {
          props.toggle(props.visible);
        }}
        testId="selectAllShiftBlocks"
      />
      <th
        className="tab_title tab_title--unsortedSpace k-shiftBlocksHeader__headerCell k-shiftBlocksHeader__headerCell--sortable"
        onClick={() => {
          props.sortFunc(0, 'shiftBlocks');
        }}
      >
        <span role="none">
          <FormattedMessage id="companymanage.shiftBlocksHeader.shiftTime" defaultMessage="Czasy zmiany" />
        </span>
        <SortingArrow display={props.stateUI.sortColumn === 0} up={!props.stateUI.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(1, 'shiftBlocks');
        }}
        className="k-shiftBlocksHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.shiftBlocksHeader.length" defaultMessage="Długość" />
        <SortingArrow display={props.stateUI.sortColumn === 1} up={!props.stateUI.sortDecreesing} />
      </th>
      <th
        onClick={() => {
          props.sortFunc(2, 'shiftBlocks');
        }}
        className="k-shiftBlocksHeader__headerCell k-shiftBlocksHeader__headerCell--sortable"
      >
        <FormattedMessage id="companymanage.shiftBlocksHeader.jobTitles" defaultMessage="Stanowiska" />
        <SortingArrow display={props.stateUI.sortColumn === 2} up={!props.stateUI.sortDecreesing} />
      </th>
      <th className="k-shiftBlocksHeader__headerCell">
        <FormattedMessage id="companymanage.shiftBlocksHeader.options" defaultMessage="Opcje" />
      </th>
    </tr>
  </thead>
);

ShiftBlocksHeader.propTypes = {
  sortFunc: PropTypes.func.isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.string,
    sortDecreesing: PropTypes.bool,
  }).isRequired,
  visible: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggle: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

export default ShiftBlocksHeader;
