import PropTypes from 'prop-types';

const CalendarDay = ({ day }) => (
  <td className="yearCalendar__day" key={day}>
    <div>{day}</div>
  </td>
);

CalendarDay.propTypes = {
  modalObject: PropTypes.shape({}),
  day: PropTypes.number,
  data: PropTypes.shape({
    color: PropTypes.string,
    popoverData: PropTypes.arrayOf(),
    isStartDate: PropTypes.bool,
    isEndDate: PropTypes.bool,
  }),
};

export default CalendarDay;
