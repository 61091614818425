import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import IconButton from '@/components/common/Basic/IconButton.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';

import Button from './Basic/Button.jsx';

class KadroModal extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.bindEnter = this.bindEnter.bind(this);
    this.unbindEnter = this.unbindEnter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal) {
      this.bindEnter();
    } else {
      this.unbindEnter();
    }
  }

  componentWillUnmount() {
    this.unbindEnter();
  }

  bindEnter() {
    if (this.props.inputAwareSubmit) {
      Mousetrap.bind(['enter'], this.props.onSubmit);
    } else {
      document.addEventListener('keyup', this.submit);
    }
  }

  unbindEnter() {
    if (this.props.inputAwareSubmit) {
      Mousetrap.unbind(['enter']);
    } else {
      document.removeEventListener('keyup', this.submit);
    }
  }

  submit(e) {
    if (e.key === 'Enter') {
      this.props.onSubmit();
    }
  }

  render() {
    const onCancel = this.props.onCancel || this.props.onHide;
    const confirmBtnModifiers = ['small', 'floatright', 'high', ...this.props.confirmBtnModifiers];
    const cancelBtnModifiers = ['small', 'floatleft', 'high', ...this.props.cancelBtnModifiers];
    return (
      <Modal show={this.props.showModal} onHide={this.props.onHide} className={this.props.className}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
          {this.props.getObjectHistory ? (
            <IconButton
              icon="history"
              description={<FormattedMessage id="common.kadroModal.objectHistory" defaultMessage="Historia edycji" />}
              onClick={this.props.getObjectHistory}
            />
          ) : null}
        </Modal.Header>
        <Modal.Body>{this.props.children}</Modal.Body>
        <Modal.Footer>
          {this.props.errorMessage ? <div className="kmodal__errorSummary">{this.props.errorMessage}</div> : null}
          <Button onClick={onCancel} modifiers={cancelBtnModifiers} id={this.props.cancelButtonId}>
            {this.props.cancelText}
          </Button>
          <Button onClick={this.props.onSubmit} modifiers={confirmBtnModifiers} id={this.props.confirmButtonId}>
            {this.props.confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

KadroModal.defaultProps = {
  confirmBtnModifiers: ['blue'],
  cancelBtnModifiers: ['reverse-blue'],
  cancelText: 'Anuluj',
};

KadroModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  confirmText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  cancelText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  confirmBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  cancelBtnModifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.string,
  getObjectHistory: PropTypes.func,
  cancelButtonId: PropTypes.string,
  confirmButtonId: PropTypes.string,
  inputAwareSubmit: PropTypes.bool,
};

export default KadroModal;
