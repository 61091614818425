import { createSlice } from '@reduxjs/toolkit';

import {
  createFreeDayMarking,
  createFreeDayMarkings,
  deleteFreeDayMarking,
  deleteFreeDayMarkings,
  getFreeDaysMarkings,
  updateFreeDayMarking,
  updateFreeDayMarkings,
} from './freeDaysMarking.asyncActions';
import { FreeDaysMarkingStoreState } from './freeDaysMarking.types';
import { deleteFreeDaysInState, dtoToFreeDay } from './freeDaysMarking.utils';

export const sliceName = 'freeDaysMarking';

export const initialState: FreeDaysMarkingStoreState = {};

const FreeDaysMarkingSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFreeDaysMarkings.fulfilled, (_state, action) => action.payload);
    builder.addCase(createFreeDayMarking.fulfilled, (state, action) => {
      const freeDay = action.payload;
      if (freeDay) {
        if (!state[freeDay.employee_id]) state[freeDay.employee_id] = {};
        state[freeDay.employee_id][freeDay.date] = dtoToFreeDay(freeDay);
      }
    });
    builder.addCase(createFreeDayMarkings.fulfilled, (state, action) => {
      action.payload.forEach(freeDay => {
        if (!state[freeDay.employee_id]) state[freeDay.employee_id] = {};
        state[freeDay.employee_id][freeDay.date] = dtoToFreeDay(freeDay);
      });
    });
    builder.addCase(deleteFreeDayMarking.fulfilled, (state, action) => {
      deleteFreeDaysInState(action.payload, state);
    });
    builder.addCase(deleteFreeDayMarkings.fulfilled, (state, action) => {
      deleteFreeDaysInState(action.payload, state);
    });
    builder.addCase(updateFreeDayMarking.fulfilled, (state, action) => {
      const freeDay = action.payload;
      if (freeDay) {
        if (!state[freeDay.employee_id]) state[freeDay.employee_id] = {};
        state[freeDay.employee_id][freeDay.date] = dtoToFreeDay(freeDay);
      }
    });
    builder.addCase(updateFreeDayMarkings.fulfilled, (state, action) => {
      action.payload.forEach(freeDay => {
        if (!state[freeDay.employee_id]) state[freeDay.employee_id] = {};
        state[freeDay.employee_id][freeDay.date] = dtoToFreeDay(freeDay);
      });
    });
  },
});

export const freeDaysMarkingReducer = FreeDaysMarkingSlice.reducer;
