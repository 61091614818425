import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';

const TopBarNotification = ({ paymentDate, showInvoice, toggleNotification, showPayment, redirectToPayUFailure }) => {
  const startPayment = async () => {
    try {
      const data = await showPayment();
      window.location.href = data.redirect_link;
    } catch (err) {
      redirectToPayUFailure();
      console.error(err);
    }
  };

  const getDay = date => moment(date).format('DD MMMM');

  return (
    <div className="k-topBar__notification">
      <div className="text">
        <FormattedMessage
          id="topBar.notification"
          defaultMessage="W systemie pojawiła się nowa faktura. Termin płatności: {date}"
          values={{
            date: <b>{getDay(paymentDate)}</b>,
          }}
        />
      </div>
      <div className="actions">
        <Button modifiers="small white-orange" onClick={startPayment}>
          <FormattedMessage id="topBar.notification.pay" defaultMessage="Opłać teraz" />
        </Button>
        <Button modifiers="orange small" onClick={() => showInvoice()}>
          <FormattedMessage id="topBar.notification.show" defaultMessage="Zobacz fakturę" />
        </Button>
        <button className="closeButton" type="button" onClick={() => toggleNotification(false)}>
          <i className="material-icons">close</i>
        </button>
      </div>
    </div>
  );
};

TopBarNotification.propTypes = {
  paymentDate: PropTypes.string,
  showInvoice: PropTypes.func,
  toggleNotification: PropTypes.func,
  showPayment: PropTypes.func,
  redirectToPayUFailure: PropTypes.func,
};

export default TopBarNotification;
