import PropTypes from 'prop-types';
import { Component } from 'react';

import { MAIN_COLOR_PALETT } from '@/constants/colors.js';

import Popover from './Popover/Popover.jsx';

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    if (this.props.colorPalet) {
      this.colorPalet = this.props.colorPalet;
    } else {
      this.colorPalet = MAIN_COLOR_PALETT;
    }

    this.state = {
      color: this.props.color ? this.props.color : this.colorPalet[0],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.color !== prevProps.color) {
      this.setState({ color: this.props.color });
    }
  }

  chooseColor(color) {
    this.setState({ color });
    this.props.colorFunc(color);
    this.popover.hide();
  }

  render() {
    const { testId } = this.props;
    return (
      <div>
        <Popover
          ref={ref => {
            this.popover = ref;
          }}
          position="right"
          sideOffset={100}
          xOffset={0}
          useParentDimensions
          showShadow={false}
          popoverStyle={{ padding: 0 }}
          hideOnBlur
          content={
            <div
              className="swatches"
              ref={ref => {
                this.pop1 = ref;
              }}
              data-test={`${testId}-dropdown`}
            >
              {this.colorPalet.map((value, i) => (
                <div
                  role="presentation"
                  onClick={() => {
                    this.chooseColor(value);
                  }}
                  key={i}
                  className="colorSquare"
                  style={{ backgroundColor: value }}
                  data-test={`${testId}-dropdown-${value}`}
                />
              ))}
            </div>
          }
        >
          <div
            role="presentation"
            tabIndex={-10}
            ref={ref => {
              this.target1 = ref;
            }}
            className="colorPickerInput"
            style={{ backgroundColor: this.state.color }}
            data-test={`${this.props.testId}-input`}
          />
        </Popover>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string,
  colorFunc: PropTypes.func.isRequired,
  colorPalet: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string,
};

export default ColorPicker;
