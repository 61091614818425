import { FormattedMessage } from 'react-intl';

import {
  FREEMIUM_EVENT_LOCATION_SETTINGS_DEVICE_SETTINGS,
  FREEMIUM_EVENT_LOCATION_SETTINGS_OPENING_HOURS,
  FREEMIUM_EVENT_LOCATION_SETTINGS_OTHER_SETTINGS,
  FREEMIUM_EVENT_LOCATION_SETTINGS_RESTRICTIONS,
  FREEMIUM_EVENT_LOCATION_SETTINGS_WORKING_RULES,
} from '@/constants/intercom.js';
import {
  ATTENDANCE_SURVEY,
  OPEN_SHIFTS_SETTING_SHOW,
  PRODUCTION_QUOTAS_SETTING_SHOW,
  TRADE_SHIFTS_SETTING_SHOW,
} from '@/constants/Permissions.js';
import { FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW } from '@/constants/Restrictions.js';
import { OPTION_TYPE } from '@/constants/settingsConstants.js';
import { createEvent } from '@/utils/inputHelpers.js';

import { getOpenHoursMethods } from '../constants';

export { getBudgetOptions } from './BudgetOptions/BudgetOptions';

export const getWorkingRulesOptions = handleInputChange => ({
  title: <FormattedMessage id="settings.locations.workingRules" defaultMessage="Reguły kodeksu pracy" />,
  freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
  intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_WORKING_RULES,
  options: [
    {
      name: (
        <FormattedMessage
          id="settings.locations.enableWorkingRulesCheck"
          defaultMessage="Blokuj publikowanie grafiku naruszającego reguły kodeksu pracy"
        />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.enableWorkingRulesCheck.desc"
          defaultMessage="Blokuje możliwość opublikowania grafiku pracy, gdy zostają naruszone reguły kodeksu pracy"
        />
      ),
      slug: 'enableWorkingRulesCheck',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
  ],
});
export const getRestrictionsOptions = handleInputChange => ({
  title: <FormattedMessage id="settings.locations.restrictions" defaultMessage="Restrykcje" />,
  freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
  intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_RESTRICTIONS,
  options: [
    {
      name: (
        <FormattedMessage
          id="settings.locations.disableAttendancesEditUntil"
          defaultMessage="Zablokuj edycję obecności do"
        />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.disableAttendancesEditUntil.desc"
          defaultMessage="Blokuje możliwość dodawania i edycji obecności dla wybranej lokalizacji do określonej daty"
        />
      ),
      slug: 'disable_location_attendances_edit_until',
      type: OPTION_TYPE.DATE,
      handleInput: handleInputChange,
    },
    {
      name: (
        <FormattedMessage id="settings.locations.disableScheduleEditUntil" defaultMessage="Zablokuj edycję zmian do" />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.disableScheduleEditUntil.desc"
          defaultMessage="Blokuje możliwość dodawania i edycji zmian w grafiku pracy dla wybranej lokalizacji do określonej daty"
        />
      ),
      slug: 'disable_location_schedule_shifts_edit_until',
      type: OPTION_TYPE.DATE,
      handleInput: handleInputChange,
    },
  ],
});

export const getDevicesOptions = (
  handleInputChange,
  isRcpGeolocalizationVisible,
  authorizationQrcodeEnable,
  allowEmployeeAttendaceOpenClose,
  enableLocalizationCheck,
  rcpRequiresGeolocation,
  availableAuthMethods,
  errors = {},
) => ({
  title: (
    <FormattedMessage
      id="settings.locations.workingTimeRecordingSettings"
      defaultMessage="Ustawienia rejestracji czasu pracy"
    />
  ),
  freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
  intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_DEVICE_SETTINGS,
  options: [
    {
      name: <FormattedMessage id="settings.locations.enableRestBreak" defaultMessage="Włącz rejestrację przerw" />,
      label: (
        <FormattedMessage
          id="settings.locations.enableRestBreak.desc"
          defaultMessage="Pozwala na rejestrację przerw w module RCP"
        />
      ),
      slug: 'enableRestBreak',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    {
      name: <FormattedMessage id="settings.locations.allowPIN" defaultMessage="Logowanie za pomocą kodu PIN" />,
      label: (
        <FormattedMessage
          id="settings.locations.allowPIN.desc"
          defaultMessage="Pozwala na rejestrację obecności z pomocą 4-cyfrowego PINu"
        />
      ),
      slug: 'authorization_pin_enable',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    {
      name: <FormattedMessage id="settings.locations.allowQR" defaultMessage="Logowanie za pomocą kodów QR" />,
      label: (
        <FormattedMessage
          id="settings.locations.allowQR.desc"
          defaultMessage="Pozwala na rejestrację obecności z pomocą kodu QR"
        />
      ),
      slug: 'authorization_qrcode_enable',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    authorizationQrcodeEnable
      ? {
          name: (
            <FormattedMessage
              id="settings.qrCodes.autoDisableCamera"
              defaultMessage="Automatycznie wyłącz skaner kodów QR"
            />
          ),
          label: (
            <FormattedMessage
              id="settings.qrCodes.autoDisableCamera.desc"
              defaultMessage="Wyłącza skaner kodów QR po określonym czasie, aby oszczędzać energię na urządzeniu"
            />
          ),
          labelText: <FormattedMessage id="settings.locations.afterTime" defaultMessage="po upływie:" />,
          toggleSlug: 'authorization_qrcode_activity_autodisable',
          rangeSlug: 'authorization_qrcode_activity_time',
          handleToggle: handleInputChange,
          handleRange: handleInputChange,
          min: 1,
          max: 1800,
          step: 1,
          labelType: 'sm',
          type: OPTION_TYPE.TOLERANCE,
          disabled: !authorizationQrcodeEnable,
        }
      : {},
    {
      name: <FormattedMessage id="settings.locations.allowNFC" defaultMessage="Logowanie za pomocą tagów NFC" />,
      label: (
        <FormattedMessage
          id="settings.locations.allowNFC.desc"
          defaultMessage="Pozwala na rejestrację obecności z pomocą kart NFC"
        />
      ),
      slug: 'authorization_nfc_enable',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    {
      name: (
        <FormattedMessage
          id="settings.locations.allowEmployeeAttendaceOpenClose"
          defaultMessage="Logowanie za pomocą aplikacji Kadromierz Pracownik"
        />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.allowEmployeeAttendaceOpenClose.desc"
          defaultMessage="Pozwala na zdalne rejestrowanie obecności poprzez aplikację Kadromierz pracownik"
        />
      ),
      slug: 'allowEmployeeAttendaceOpenClose',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    allowEmployeeAttendaceOpenClose && {
      name: (
        <FormattedMessage
          id="settings.locations.enableLocalizationCheck"
          defaultMessage="Włącz sprawdzanie współrzędnych GPS"
        />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.enableLocalizationCheck.desc"
          defaultMessage="Pozwala na weryfikację miejsca rozpoczęcia pracy na podstawie długości i szerokości geograficznej"
        />
      ),
      slug: 'enableLocalizationCheck',
      type: 'bool',
      handleInput: handleInputChange,
    },
    isRcpGeolocalizationVisible
      ? {
          name: (
            <FormattedMessage
              id="settings.locations.enableLocalizationCheckInRcp"
              defaultMessage="Włącz sprawdzanie współrzędnych GPS na aplikacji Kadromierz RCP"
            />
          ),
          label: (
            <FormattedMessage
              id="settings.locations.enableLocalizationCheckInRcp.desc"
              defaultMessage="Pozwala na weryfikację miejsca rozpoczęcia pracy na podstawie długości i szerokości geograficznej"
            />
          ),
          slug: 'rcpRequiresGeolocation',
          type: OPTION_TYPE.BOOL,
          handleInput: handleInputChange,
        }
      : {},
    ((allowEmployeeAttendaceOpenClose && enableLocalizationCheck) || rcpRequiresGeolocation) && {
      label: <FormattedMessage id="common.latitude" defaultMessage="Szerokość geograficzna" />,
      slug: 'latitude',
      type: OPTION_TYPE.TEXT,
      placeholder: 51.0786169,
      onChange: handleInputChange,
      error: errors.latitude,
      required: enableLocalizationCheck,
    },
    ((allowEmployeeAttendaceOpenClose && enableLocalizationCheck) || rcpRequiresGeolocation) && {
      label: <FormattedMessage id="common.longitude" defaultMessage="Długość geograficzna" />,
      slug: 'longitude',
      type: OPTION_TYPE.TEXT,
      placeholder: 17.0097249,
      onChange: handleInputChange,
      error: errors.longitude,
      required: enableLocalizationCheck,
    },
    ((allowEmployeeAttendaceOpenClose && enableLocalizationCheck) || rcpRequiresGeolocation) && {
      label: <FormattedMessage id="common.radiusLimit" defaultMessage="Dopuszczalny promień (w metrach)" />,
      labelDesc: (
        <FormattedMessage
          id="common.radiusLimit.desc"
          defaultMessage="Gdy pracownik zaloguje się poza określonym promieniem względem wyznaczonej lokalizacji, w widoku Lista obecności pojawi się ostrzeżenie"
        />
      ),
      slug: 'attendanceLocationRadiusLimit',
      type: OPTION_TYPE.TEXT,
      placeholder: 25,
      onChange: handleInputChange,
      error: errors.attendanceLocationRadiusLimit,
      required: enableLocalizationCheck,
    },
    {
      name: <FormattedMessage id="settings.locations.loginMethod" defaultMessage="Domyślna metoda logowania" />,
      label: (
        <FormattedMessage
          id="settings.locations.loginMethod.desc"
          defaultMessage="Wybierz, która metoda logowania pojawiać się będzie domyślnie w Kadro RCP"
        />
      ),
      slug: 'authorization_method',
      type: OPTION_TYPE.SELECT,
      options: availableAuthMethods,
    },
  ],
});

export const getOtherOptions = (
  handleInputChange,
  permissions,
  { errors, autoclose_attendances_at_time: autocloseAttendancesAtTime, autoclose_attendances: autocloseAttendances },
) => ({
  title: <FormattedMessage id="settings.locations.featuresSettings" defaultMessage="Inne" />,
  freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
  intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_OTHER_SETTINGS,
  options: [
    ...(permissions.includes(OPEN_SHIFTS_SETTING_SHOW)
      ? [
          {
            name: <FormattedMessage id="settings.locations.enableOpenShift" defaultMessage="Włącz otwarte zmiany" />,
            label: (
              <FormattedMessage
                id="settings.locations.enableOpenShift.desc"
                defaultMessage="Pozwala na tworzenie w grafiku pracy zmian nieprzypisanych do pracownika. Z poziomu aplikacji mobilnej Kadromierz Pracownik, pracownicy mogą wyrazić chęć przejęcia Otwartej Zmiany"
              />
            ),
            slug: 'enableOpenShift',
            type: OPTION_TYPE.BOOL,
            handleInput: handleInputChange,
          },
        ]
      : []),
    ...(permissions.includes(TRADE_SHIFTS_SETTING_SHOW)
      ? [
          {
            name: (
              <FormattedMessage
                id="settings.locations.enableTradeShift"
                defaultMessage="Włącz możliwość wymiany zmian"
              />
            ),
            label: (
              <FormattedMessage
                id="settings.locations.enableTradeShift.desc"
                defaultMessage="Pozwala pracownikom na stworzenie prośby o wymianę swoich godzin pracy z poziomu aplikacji Kadromierz Pracownik i wysłanie jej do wybranych pracowników"
              />
            ),
            slug: 'enableTradeShift',
            type: OPTION_TYPE.BOOL,
            handleInput: handleInputChange,
          },
        ]
      : []),
    {
      name: (
        <FormattedMessage
          id="settings.locations.autocloseAttendances"
          defaultMessage="Włącz automatyczne zakończenie obecności"
        />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.autocloseAttendances.desc"
          defaultMessage="Włączenie tej opcji pozwala na zamknięcie trwającej obecności o ustalonej godzinie. Po włączeniu opcji wprowadź godzinę, o której mają zostać zamykane otwarte obecności."
        />
      ),
      slug: 'autoclose_attendances',
      type: OPTION_TYPE.BOOL,
      handleInput: e => {
        if (!e.target.value) {
          handleInputChange(createEvent('autoclose_attendances_at_time', null));
        }
        handleInputChange(e);
      },
    },
    autocloseAttendances && {
      onlyLabel: (
        <FormattedMessage
          id="settings.locations.autocloseAttendancesTime"
          defaultMessage="Godzina zakończenia obecności"
        />
      ),
      slug: 'autoclose_attendances_at_time',
      type: OPTION_TYPE.SINGLE_TIME_INPUT,
      handleInput: handleInputChange,
      value: autocloseAttendancesAtTime,
      error: errors.autoclose_attendances_at_time,
    },
    ...(permissions.includes(PRODUCTION_QUOTAS_SETTING_SHOW)
      ? [
          {
            name: (
              <FormattedMessage
                id="settings.locations.enableUsageProductionQuotasForPayrollCalculation"
                defaultMessage="Włącz prace akordowe"
              />
            ),
            slug: 'enableUsageProductionQuotasForPayrollCalculation',
            type: OPTION_TYPE.BOOL,
            handleInput: handleInputChange,
          },
        ]
      : []),
    ...(permissions.includes(ATTENDANCE_SURVEY)
      ? [
          {
            name: (
              <FormattedMessage
                id="settings.locations.attendanceSurveyTime"
                defaultMessage="Czas wysłania notyfikacji o zmianie do pracownika"
              />
            ),
            label: (
              <FormattedMessage
                id="settings.locations.attendanceSurveyTime.desc"
                defaultMessage="Wybierz ile minut przed zmianą pracownik otrzyma notyfikację z prośbą o potwierdzenie obecności"
              />
            ),
            slug: 'attendanceSurveyTime',
            type: 'select',
            handleInput: handleInputChange,
            options: [
              {
                name: <FormattedMessage id="settings.locations.minutes15" defaultMessage="15 minut" />,
                id: 15,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes30" defaultMessage="30 minut" />,
                id: 30,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes45" defaultMessage="45 minut" />,
                id: 45,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes60" defaultMessage="60 minut" />,
                id: 60,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes90" defaultMessage="90 minut" />,
                id: 90,
              },
            ],
          },
          {
            name: (
              <FormattedMessage
                id="settings.locations.attendanceSurveyReportTime"
                defaultMessage="Czas wysłania informacji o obecnych pracownikach na nadchodzącej zmianie"
              />
            ),
            label: (
              <FormattedMessage
                id="settings.locations.attendanceSurveyReportTime.desc"
                defaultMessage="Wybierz ile minut po wysłaniu notyfikacji do pracownika manager otrzyma podsumowanie odpowiedzi pracowników"
              />
            ),
            slug: 'attendanceSurveyReportTime',
            type: 'select',
            handleInput: handleInputChange,
            options: [
              {
                name: <FormattedMessage id="settings.locations.minutes15" defaultMessage="15 minut" />,
                id: 15,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes30" defaultMessage="30 minut" />,
                id: 30,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes45" defaultMessage="45 minut" />,
                id: 45,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes60" defaultMessage="60 minut" />,
                id: 60,
              },
              {
                name: <FormattedMessage id="settings.locations.minutes90" defaultMessage="90 minut" />,
                id: 90,
              },
            ],
          },
        ]
      : []),
  ],
});

const openingHoursCloseMessage = <FormattedMessage id="common.closed" defaultMessage="zamknięte" />;

export const getOpeningHoursOptions = (handleInputChange, locationOpeningHoursEnabled, openHoursMethod, intl) => ({
  title: <FormattedMessage id="settings.locations.openingHours" defaultMessage="Godziny otwarcia" />,
  freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
  intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_OPENING_HOURS,
  options: [
    {
      name: (
        <FormattedMessage id="settings.locations.locationOpeningHours" defaultMessage="Godziny otwarcia lokalizacji" />
      ),
      label: (
        <FormattedMessage
          id="settings.locations.locationOpeningHours.desc"
          defaultMessage="Otrzymuj powiadomienia SMS, E-mail, Push jeżeli żaden z pracowników nie jest obecny w pracy w godzinie otwarcia lokalizacji."
        />
      ),
      slug: 'locationOpeningHoursEnabled',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    },
    locationOpeningHoursEnabled && {
      name: <FormattedMessage id="settings.locations.openHoursMethod" defaultMessage="Wybierz metodę" />,
      label: (
        <FormattedMessage
          id="settings.locations.openHoursMethod.desc"
          defaultMessage="Wybierz metodę ”Wg. godziny otwarć”, aby uzupełnić godziny pracy lokalizacji w poszczególnych dniach tygodnia lub wybierz metodę ”Wg. grafiku pracy”, a pierwsza zaplanowana zmiana w grafiku w danym dniu będzie traktowana jako godzina otwarcia lokalizacji."
        />
      ),
      slug: 'openHoursMethod',
      type: OPTION_TYPE.SELECT,
      options: getOpenHoursMethods(intl),
    },
    locationOpeningHoursEnabled &&
      openHoursMethod === 'custom' && {
        name: 'openHours',
        slug: 'openHours',
        type: OPTION_TYPE.CHECKBOX_LIST,
        handleInputChange,
        items: [
          {
            id: 'mon',
            label: <FormattedMessage id="common.monday" defaultMessage="Poniedziałek" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'tue',
            label: <FormattedMessage id="common.tuesday" defaultMessage="Wtorek" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'wed',
            label: <FormattedMessage id="common.wednesday" defaultMessage="Środa" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'thu',
            label: <FormattedMessage id="common.thursday" defaultMessage="Czwartek" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'fri',
            label: <FormattedMessage id="common.friday" defaultMessage="Piątek" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'sat',
            label: <FormattedMessage id="common.saturday" defaultMessage="Sobota" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },

          {
            id: 'sun',
            label: <FormattedMessage id="common.sunday" defaultMessage="Niedziela" />,
            type: OPTION_TYPE.TIME_RANGE,
            disabledMessage: openingHoursCloseMessage,
          },
        ],
      },
  ],
});
