import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ShownCounter from '@/components/common/ShownCounter.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { COMPANY_MANAGE_SHIFTBLOCKS_HIDE_DELETE } from '@/constants/Restrictions.js';
import { findIndexById } from '@/utils/arrayHelpers.js';
import { compareByKey } from '@/utils/baseHelpers.js';

import AddShiftBlock from '../AddShiftBlock.jsx';
import ShiftBlockRow from '../ShiftBlockRow.jsx';
import ShiftBlocksHeader from '../ShiftBlocksHeader.jsx';

const messages = defineMessages({
  emptyStateTitle: {
    id: 'companyManage.shiftBlocksList.emptyStateTitle',
    defaultMessage: 'Brak dodanych godzin pracy ',
  },
  emptyStateMsg: {
    id: 'companyManage.shiftBlocksList.emptyStateMsg',
    defaultMessage: 'Aby dodać pierwsze godziny pracy ',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.shiftBlocksList.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.shiftBlocksList.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaText: {
    id: 'companyManage.shiftBlocksList.ctaText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaFilterText: {
    id: 'companyManage.shiftBlocksList.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

// Komponent listy pracowników
class ShiftBlocksTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.compareKeys = ['compareName', 'compareLength', 'compareJobTitles'];
    this.submitFunc = this.submit.bind(this);
    this.getEmptyStateView = this.getEmptyStateView.bind(this);
  }

  getEmptyStateView() {
    const showModal = !this.props.userShiftblocks.length && !this.props.searchWord.length;
    const headerText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateTitle, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateMsg, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
    const ctaText = showModal
      ? this.context.intl.formatMessage(messages.ctaText, {})
      : this.context.intl.formatMessage(messages.ctaFilterText, {});
    const ctaHandler = showModal
      ? () => {
          this.props.toggleModal('shiftBlocks');
        }
      : this.props.clearSearchWord;
    return (
      <tr>
        <td className="no-content-to-show" colSpan="5">
          <EmptyState
            name="shiftBlockCTA"
            imgSrc="/img/shiftBlocksCTA.png"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
            shiftX="0"
          />
        </td>
      </tr>
    );
  }

  // Funkcja która wysyła dane do serwera po edycji lub utworzeniu
  submit(data) {
    if (this.props.shiftBlocksListUi.editMode) {
      this.props.changeShiftBlock({ ...data, company_id: this.props.currentCompany.id });
    } else {
      this.props.addShiftBlock({ ...data, company_id: this.props.currentCompany.id });
    }
  }

  filterAndSort(fraze) {
    const data = [];
    for (const block of this.props.userShiftblocks) {
      const compareName = block.working_hours.toLowerCase();

      let minutes = parseInt(block.working_hours.slice(9, 11), 10) - parseInt(block.working_hours.slice(3, 5), 10);
      let hours = parseInt(block.working_hours.slice(6, 8), 10) - parseInt(block.working_hours.slice(0, 2), 10);
      if (minutes < 0) {
        hours--;
        minutes += 60;
      }
      if (hours < 0) {
        hours += 24;
      }

      const length = (hours > 0 ? `${hours}h ` : '') + (minutes > 0 ? `${minutes}m` : '');
      const compareLength = 60 * hours + minutes;

      let compareJobTitles = '';

      this.props.userJobTitles.forEach(title => {
        if (block.job_titles != null) {
          block.job_titles.forEach(id => {
            if (id === title.id) {
              compareJobTitles += title.title.toLowerCase();
            }
          });
        }
      });

      const sortingData = {
        compareName,
        compareLength,
        compareJobTitles,
        length,
        checked: this.props.shiftBlocksListUi.selected.includes(block.id),
      };
      if ((compareName + length + compareJobTitles).includes(fraze.toLowerCase()))
        data.push({ ...block, ...sortingData });
    }

    const column = this.props.shiftBlocksListUi.sortColumn;

    const direction = this.props.shiftBlocksListUi.sortDecreesing ? -1 : 1;

    if (column >= 0) data.sort(compareByKey(this.compareKeys[column], direction));

    return { array: data, other: { userJobTitles: this.props.userJobTitles } };
  }

  render() {
    const rawData = this.filterAndSort(this.props.searchWord).array;
    const visibleShiftBlocksIds = rawData.map(s => s.id);
    const tableHeight = this.props.deviceInfo.windowSize.height - 300;
    return (
      <div>
        <AddShiftBlock
          visible={this.props.shiftBlocksListUi.showModal}
          closingFunc={() => {
            this.props.toggleModal('shiftBlocks');
          }}
          submitFunc={this.submitFunc}
          edit={this.props.shiftBlocksListUi.editedId}
          editData={
            this.props.userShiftblocks[findIndexById(this.props.userShiftblocks, this.props.shiftBlocksListUi.editedId)]
          }
          jobTitles={this.props.userJobTitles}
        />

        <div className="add_blue">
          <Button
            onClick={() => {
              this.props.toggleModal('shiftBlocks');
            }}
            modifiers="blue medium inline"
            testId="addShiftBlocks"
          >
            <FormattedMessage id="companyManage.shiftBlocksList.addShiftBlock" defaultMessage="Dodaj godziny pracy" />
          </Button>
        </div>
        <FeatureWrapper restriction={COMPANY_MANAGE_SHIFTBLOCKS_HIDE_DELETE}>
          <div className="delete_selected">
            <Button
              onClick={this.props.massDeleteShiftBlocksConfirm}
              modifiers="reverse-red medium inline"
              disabled={!this.props.shiftBlocksListUi.selected.length}
              testId="massDeleteShiftBlocks"
            >
              <i className="material-icons">delete</i>
              <FormattedMessage id="companyManage.lists.removeSelected" defaultMessage="Usuń zaznaczone" />
            </Button>
          </div>
        </FeatureWrapper>
        <Grid
          width={900}
          height={tableHeight}
          columnStyle={{ width: '16vw', minWidth: '270px' }}
          columnGroup={
            <colgroup>
              <col style={{ width: '25%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '35%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
          }
          static
        >
          <Header>
            <ShiftBlocksHeader
              testId="shiftBlocksTable"
              stateUI={this.props.shiftBlocksListUi}
              toggle={() => {
                this.props.toggleAllCheckboxes(visibleShiftBlocksIds, 'shiftBlocks');
              }}
              visible={visibleShiftBlocksIds}
              sortFunc={this.props.changeSorting}
            />
          </Header>

          <Rows className="table_company" testId="shiftBlocksTable">
            {rawData.length === 0
              ? this.getEmptyStateView()
              : rawData.map((shiftBlock, index) => (
                  <ShiftBlockRow
                    data={shiftBlock}
                    edit={() => {
                      this.props.startEdit(shiftBlock.id, 'shiftBlocks');
                    }}
                    delete={() => {
                      this.props.deleteShiftBlockConfirm(shiftBlock.id);
                    }}
                    toggle={() => {
                      this.props.toggleCheckbox(shiftBlock.id, 'shiftBlocks');
                    }}
                    key={index}
                    userJobTitles={this.props.userJobTitles}
                    userPermissions={this.props.userPermissions}
                    stateUI={this.props.shiftBlocksListUi}
                  />
                ))}
          </Rows>
        </Grid>

        <ShownCounter visible={visibleShiftBlocksIds.length} total={this.props.userShiftblocks.length} />
      </div>
    );
  }
}

ShiftBlocksTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ShiftBlocksTable.propTypes = {
  userShiftblocks: PropTypes.arrayOf(
    PropTypes.shape({
      editMode: PropTypes.bool,
    }),
  ),
  shiftBlocksListUi: PropTypes.shape({
    showModal: PropTypes.bool,
    editMode: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
    editedId: PropTypes.string,
  }).isRequired,
  changeShiftBlock: PropTypes.func.isRequired,
  currentCompany: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  addShiftBlock: PropTypes.func.isRequired,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchWord: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  massDeleteShiftBlocksConfirm: PropTypes.func.isRequired,
  deleteShiftBlockConfirm: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  clearSearchWord: PropTypes.func,
};
export default ShiftBlocksTable;
