import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addShift: {
    id: 'employeeShift.modal.addShift',
    defaultMessage: 'Dodaj czas pracy',
  },
  editShift: {
    id: 'employeeShift.modal.editShift',
    defaultMessage: 'Edytuj czas pracy',
  },
  date: {
    id: 'employeeShift.modal.date',
    defaultMessage: 'Data',
  },
  location: {
    id: 'employeeShift.modal.location',
    defaultMessage: 'Lokalizacja',
  },
  jobTitle: {
    id: 'employeeShift.modal.jobTitle',
    defaultMessage: 'Stanowisko',
  },
  time: {
    id: 'employeeShift.modal.time',
    defaultMessage: 'Czas',
  },
  note: {
    id: 'employeeShift.modal.note',
    defaultMessage: 'Notatka',
  },
  notePlaceholder: {
    id: 'employeeShift.modal.noteText',
    defaultMessage: 'Treść notatki',
  },
  repeatShiftMessage: {
    id: 'employeeShift.modal.repeatShiftMessage',
    defaultMessage: 'Powtórz czas pracy',
  },
});

export const errorsMessages = defineMessages({
  employeeIsBusy: {
    id: 'employeeShift.modal.employeeIsBusy',
    defaultMessage: 'Czasy pracy się pokrywają. Wprowadź niepokrywające się czasy pracy',
  },
  notAllowedLocation: {
    id: 'employeeShift.modal.notAllowedLocation',
    defaultMessage: 'Nie możesz dodać czas pracy dla wybranej lokalizacji',
  },
  notAllowedJobTitle: {
    id: 'employeeShift.modal.notAllowedJobTitle',
    defaultMessage: 'Nie możesz dodać czas pracy dla wybranego stanowiska',
  },
  disabledDate: {
    id: 'employeeShift.modal.disabledDate',
    defaultMessage: 'Nie możesz dodać czas pracy dla wybranej daty',
  },
});
