import { useEffect } from 'react';

import { getAppVersionAndUpdateIfNeeded } from '@/actions';
import { checkCookiesForTokens } from '@/actions/auth';
import { getPromoContent } from '@/actions/promoContents';
import { NEW_PASSWORD_PAGE_PATTERN } from '@/constants/regexPatterns';
import store from '@/redux-store/store';

import { useAppListeners } from './useAppListeners';

const { dispatch } = store;

export const useApp = () => {
  useAppListeners();

  useEffect(() => {
    dispatch(getPromoContent());
    getAppVersionAndUpdateIfNeeded();
    const isSettingNewPasswordPage = window.location.pathname.match(NEW_PASSWORD_PAGE_PATTERN);
    if (!isSettingNewPasswordPage) dispatch(checkCookiesForTokens());
  }, []);
};
