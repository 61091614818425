import { connect } from 'react-redux';

import { loginWithEntraId } from '@/actions/auth.jsx';
import { decreaseLoaderCounter, increaseLoaderCounter } from '@/actions/uiState';

import EntraIdButton from './EntraIdButton';

const mapDispatchToProps = {
  loginWithEntraId,
  increaseLoaderCounter,
  decreaseLoaderCounter,
};

export default connect(null, mapDispatchToProps)(EntraIdButton);
