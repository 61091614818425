import { PropTypes } from 'prop-types';

import './orangeSplitHorizontal.scss';

const OrangeSplitHorizontal = props => {
  const { children, hideLogo } = props;
  return (
    <div className="k-orangeHorizontalLayout">
      <div className="k-orangeHorizontalLayout__top" />
      <div className="k-orangeHorizontalLayout__bottom" />
      <div className="k-orangeHorizontalLayout__box">
        {hideLogo ? null : (
          <div className="k-orangeHorizontalLayout__logo">
            <a href="/">
              <img className="k-orangeHorizontalLayout__outscreenLogo" src="/img/logo4.png" alt="logo" />
            </a>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
OrangeSplitHorizontal.propTypes = {
  children: PropTypes.node,
  hideLogo: PropTypes.bool,
};
export default OrangeSplitHorizontal;
