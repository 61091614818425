import { connect } from 'react-redux';

import { hideTrialInfoBar } from '@/actions/uiState.js';

import TrialInfoTopBar from './TrialInfoTopBar.jsx';

const mapStateToProps = ({ reducer }) => ({
  companyStatus: reducer.currentCompany.status,
  isVisible: reducer.uiState.isTrialInfoBarVisible,
  isOwner: reducer.currentUser.user.role_id === 'owner',
});

const mapDispatchToProps = {
  hideTrialInfoBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialInfoTopBar);
