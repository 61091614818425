import { connect } from 'react-redux';

import { updateBudgetMetricData } from '@/actions/budgetMetricsData.js';

import ScheduleBudgetTableMetricsCell from './ScheduleBudgetTableMetricsCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  today: state.reducer.mainDateStore.today,
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
  showHolidaysSetting: state.reducer.schedule.viewSettings.holidays.value,
});

const mapDispatchToProps = {
  updateBudgetMetricData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleBudgetTableMetricsCell);
