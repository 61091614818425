import { useMemo, useState } from 'react';

// Warning: Do not use props when creating initialItems, utilize useEffect
// https://medium.com/@justintulk/react-anti-patterns-props-in-initial-state-28687846cc2e
const useMultiSelect = (initialItems, onChangeCallback) => {
  const [items, setItems] = useState(initialItems);
  const handlers = useMemo(
    () => ({
      selectAll: () => {
        const updatedItems = items.map(i => ({ ...i, active: true }));
        setItems(updatedItems);
        if (onChangeCallback) {
          onChangeCallback(updatedItems);
        }
      },
      deselectAll: () => {
        const updatedItems = items.map(i => ({ ...i, active: false }));
        setItems(updatedItems);
        if (onChangeCallback) {
          onChangeCallback(updatedItems);
        }
      },
      toggle: toggledItem => {
        const updatedItems = items.map(item => {
          if (item.value !== toggledItem.value) return item;
          return {
            ...item,
            active: !toggledItem.active,
          };
        });
        setItems(updatedItems);
        if (onChangeCallback) {
          onChangeCallback(updatedItems);
        }
      },
      toggleAndInsertAtEnd: toggledItem => {
        const activeItems = items.filter(item => item.active && toggledItem.value !== item.value);
        const toggledItemWithState = { ...toggledItem, active: !toggledItem.active };
        const inactiveItems = items.filter(item => !item.active && toggledItem.value !== item.value);

        const updatedItems = [...activeItems, toggledItemWithState, ...inactiveItems];

        setItems(updatedItems);

        if (onChangeCallback) {
          onChangeCallback(updatedItems);
        }
      },
      getSelected: () => items.filter(({ active }) => active),
      setActiveItems: newActiveIds => {
        const updatedItems = items.map(item => ({
          ...item,
          active: newActiveIds.includes(item.value),
        }));
        setItems(updatedItems);
        if (onChangeCallback) {
          onChangeCallback(updatedItems);
        }
      },

      setSelectedOptions: options => {
        const optionsArray = items.reduce((acc, { active, value }) => {
          if (active) {
            acc.push(value);
          }
          return acc;
        }, []);

        if (!optionsArray.length) {
          setItems(options.map(opt => ({ ...opt, active: true })));
          return;
        }

        const selectedOptions = options.reduce((acc, option) => {
          const isAllowToSelectOption = optionsArray.includes(option.value) || optionsArray.length === items.length;

          if (isAllowToSelectOption) {
            acc.push({ ...option, active: true });
          } else {
            acc.push({ ...option, active: false });
          }
          return acc;
        }, []);

        setItems(selectedOptions);
        if (onChangeCallback) {
          onChangeCallback(selectedOptions);
        }
      },
      setItems,
    }),
    [items],
  );
  return [items, handlers];
};

export default useMultiSelect;
