import { Contract } from '@/types/contracts.types.ts';
import { Employee } from '@/types/employees.types.ts';
import { MappedLoanEmployeesProposal } from '@/types/loanEmployeesProposals';
import { checkIfContractHasJobTitle, getRelevantContractForDate } from '@/utils/contracts.ts';

import { EmployeeItem } from '../../loanEmployeesProposalModals.helpers.ts';
import LoanEmployeesProposalDetailsStep from '../../loanEmployeesPropsalModalSteps/loanEmployeesProposalDetailsStep/LoanEmployeesProposalDetailsStep.tsx';
import LoanEmployeesProposalEmployeesStep from '../../loanEmployeesPropsalModalSteps/loanEmployeesProposalEmployeesStep/LoanEmployeesProposalEmployeesStep.tsx';
import { messages } from './incomingLoanEmployeesProposalModal.messages.ts';

export interface ModalObject {
  data: MappedLoanEmployeesProposal;
  locationId?: string;
  isPreview?: boolean;
  canDelete?: boolean;
  skipLocationFilter?: boolean;
}

export const displayRelevantSteps = (
  modalObject: ModalObject,
  currentStep: number,
  multiSelect: {
    employeesItems: EmployeeItem[];
    employeesHandlers: (employeeItem: EmployeeItem[]) => void;
  },
) => {
  switch (currentStep) {
    case 1:
      return (
        <LoanEmployeesProposalDetailsStep
          loanEmployeesProposal={modalObject?.data}
          employeeItems={modalObject?.isPreview && multiSelect.employeesItems}
        />
      );
    case 2:
      return (
        <LoanEmployeesProposalEmployeesStep
          employeesItems={multiSelect.employeesItems}
          employeesHandlers={multiSelect.employeesHandlers}
        />
      );
    default:
      break;
  }
};

export const getEmployeesOptions = (
  employeesFromProposal: { id: string; first_name: string; last_name: string }[],
  userEmployees: Employee[],
  contracts: Contract[],
  additionalData: {
    date: string;
    jobTitle: string;
    proposalLocationId: string;
    locationId?: string;
    skipLocationFilter?: boolean;
  },
) => {
  const { locationId, date, jobTitle, proposalLocationId, skipLocationFilter } = additionalData;

  const visiableEmployees = employeesFromProposal.filter(({ id }) =>
    userEmployees.some(employee => {
      const contract = contracts[id];
      if (!contract) return false;
      const relevantContract = getRelevantContractForDate(contracts[id], date);

      return (
        employee.id === id &&
        (skipLocationFilter ||
          employee.locations.some(location => location.id === locationId && proposalLocationId !== location.id)) &&
        checkIfContractHasJobTitle(relevantContract, jobTitle)
      );
    }),
  );

  return visiableEmployees.map(employee => ({
    label: `${employee.first_name} ${employee.last_name}`,
    value: employee.id,
    active: true,
  }));
};

export const getFooterOptions = (
  showDeleteLoanEmployeesProposalConfirmModal: (id: string) => void,
  intl,
  modalObject: ModalObject,
) => {
  if (modalObject?.isPreview && modalObject?.canDelete)
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteProposal),
        handleClick: () => showDeleteLoanEmployeesProposalConfirmModal(modalObject?.data?.id),
      },
    ];

  return [];
};
