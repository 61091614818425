import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { NARROW } from '@/constants/modalModifiers';
import { useMultiSelect, useStepsCounter } from '@/hooks';
import { Contract } from '@/types/contracts.types.ts';
import { Employee } from '@/types/employees.types.ts';
import { inputValidation } from '@/utils/inputValidation.js';

import {
  displayRelevantSteps,
  getEmployeesOptions,
  getFooterOptions,
  ModalObject,
} from './IncomingLoanEmployeesProposalModal.helpers.tsx';
import { messages } from './incomingLoanEmployeesProposalModal.messages.ts';

import './incomingLoanEmployeesProposalModal.scss';

const INITIAL_STEPS_COUNT = 2;

interface IncomingLoanEmployeesProposalModalProps {
  showModal: boolean;
  modalObject: ModalObject;
  hideModalAndLaterClearModalObject: () => void;
  userEmployees: Employee[];
  acceptLoanEmployeesProposal: (data: { id: string; selected_employees: string[] }) => void;
  contracts: Contract[];
  showDeleteLoanEmployeesProposalConfirmModal: (id: string) => void;
}

const IncomingLoanEmployeesProposalModal = (
  {
    showModal,
    modalObject,
    hideModalAndLaterClearModalObject,
    userEmployees,
    acceptLoanEmployeesProposal,
    contracts,
    showDeleteLoanEmployeesProposalConfirmModal,
  }: IncomingLoanEmployeesProposalModalProps,
  { intl },
) => {
  const { currentStep, stepsCount, isLastStep, handlers: stepsHandlers } = useStepsCounter(INITIAL_STEPS_COUNT);
  const employeesFromProposal =
    (modalObject?.data?.selected_employees && Object.values(modalObject?.data?.selected_employees)) || [];

  const addidtionalInfo = {
    locationId: modalObject?.locationId,
    date: modalObject?.data?.date,
    jobTitle: modalObject?.data?.job_title_id,
    proposalLocationId: modalObject?.data?.location.id,
    skipLocationFilter: Boolean(modalObject?.skipLocationFilter),
  };

  const [employeesItems, employeesHandlers] = useMultiSelect(
    getEmployeesOptions(employeesFromProposal, userEmployees, contracts, addidtionalInfo),
  );

  const [errors, setErrors] = useState({
    declaredMoreThanSelectedEmployees: '',
  });

  useEffect(() => {
    if (!showModal) stepsHandlers.setInitialValues();

    employeesHandlers.setItems(getEmployeesOptions(employeesFromProposal, userEmployees, contracts, addidtionalInfo));
  }, [modalObject]);

  useEffect(() => {
    const numberOfSelectedEmployees = employeesItems.filter(({ active }) => active).length;

    if (modalObject?.data?.employees_required >= numberOfSelectedEmployees && currentStep === 2) {
      setErrors(prev => ({ ...prev, declaredMoreThanSelectedEmployees: '' }));
    }
  }, [currentStep, employeesItems, setErrors, modalObject]);

  const confirmText = intl.formatMessage(messages[isLastStep ? 'approved' : 'next']);
  const cancelText = intl.formatMessage(messages[isLastStep ? 'back' : 'cancel']);

  const clearAndHide = () => {
    hideModalAndLaterClearModalObject();
  };

  const multiSelect = {
    employeesItems,
    employeesHandlers,
  };

  const acceptProposal = () => {
    const loanData = {
      id: modalObject.data.id,
      selected_employees: employeesItems.filter(({ active }) => active).map(({ value }) => value),
    };
    acceptLoanEmployeesProposal(loanData);
    clearAndHide();
  };

  const onSubmit = () => {
    if (currentStep === 2) {
      const numberOfSelectedEmployees = employeesItems.filter(({ active }) => active).length;
      const error = inputValidation('declaredMoreThanSelectedEmployees', modalObject.data.employees_required, {
        valueToCompare: numberOfSelectedEmployees,
      });

      if (!isEmpty(error)) {
        setErrors(prev => ({ ...prev, declaredMoreThanSelectedEmployees: intl.formatMessage(error) }));
        return;
      }
    }

    stepsHandlers.increaseStep();

    if (isLastStep) acceptProposal();
  };

  const onCancel = () => {
    stepsHandlers.decreaseStep();
    if (currentStep === 1) clearAndHide();
  };

  const isAtLeastSelectedOneEmployee = employeesItems.some(({ active }) => active);
  const disableConfirm = !isAtLeastSelectedOneEmployee && currentStep === 2;
  const isPreview = modalObject?.isPreview;
  return (
    <MDKadroModal
      title={intl.formatMessage(messages.title)}
      show={showModal}
      showProgressBar={!isPreview}
      currentStep={currentStep}
      stepsCount={stepsCount}
      modifiers={NARROW}
      confirmText={confirmText}
      cancelText={cancelText}
      onSubmit={onSubmit}
      onCancelFooter={onCancel}
      onHide={clearAndHide}
      disableConfirm={disableConfirm}
      hideConfirmButton={isPreview}
      errorMessage={errors.declaredMoreThanSelectedEmployees}
      footerOptions={getFooterOptions(showDeleteLoanEmployeesProposalConfirmModal, intl, modalObject)}
    >
      <div className="k-incomingLoanEmployeesProposalModal">
        {displayRelevantSteps(modalObject, currentStep, multiSelect)}
      </div>
    </MDKadroModal>
  );
};
IncomingLoanEmployeesProposalModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default IncomingLoanEmployeesProposalModal;
