import { connect } from 'react-redux';

import { changeAbsencesMode } from '@/actions/absences.js';

import TopBarAbsencesDisplayMode from './TopBarAbsencesDisplayMode.jsx';

const mapStateToProps = state => ({
  selectedDisplayMode: state.reducer.absences.selectedDisplayMode,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  changeAbsencesMode,
};

const TopBarAbsencesDisplayModeContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarAbsencesDisplayMode);

export default TopBarAbsencesDisplayModeContainer;
