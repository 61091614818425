import { connect } from 'react-redux';

import { getReportDataForCurrentDateRange } from '@/actions/reports';

import ReportsTopBar from './ReportsTopBar.tsx';

const mapStateToProps = ({ reducer }) => ({
  isButtonDisabled: !reducer.reports.ui.canUpdate,
  filters: reducer.reports.filters,
});

const mapDispatchToProps = { getReportDataForCurrentDateRange };

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTopBar);
