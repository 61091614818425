import PropTypes from 'prop-types';

import TabNav from './common/TabNav.jsx';
import TopBar from './TopBar.jsx';

const TopBarTabSettings = props => (
  <TopBar
    leftElements={[
      <TabNav key="tabNav" activePath={props.activePath} routes={props.filteredRoutes} isExactPath={false} />,
    ]}
    rightElements={[]}
  />
);

TopBarTabSettings.propTypes = {
  activePath: PropTypes.string,
  filteredRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      path: PropTypes.string,
    }),
  ),
};

export default TopBarTabSettings;
