import { connect } from 'react-redux';

import {
  acceptLoanEmployeesProposal,
  showDeleteLoanEmployeesProposalConfirmModal,
} from '@/actions/loanEmployeesProposals';
import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL } from '@/constants/ActionTypes';

import IncomingLoanEmployeesProposalModal from './IncomingLoanEmployeesProposalModal';

const mapStateToProps = state => {
  const modalObject = state.reducer.uiState.modalObject[INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL];
  return {
    showModal: state.reducer.uiState.showModal === INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL,
    modalObject,
    userEmployees: state.reducer.userEmployees,
    contracts: state.reducer.contracts,
  };
};

const mapDispatchToProps = {
  hideModalAndLaterClearModalObject,
  acceptLoanEmployeesProposal,
  showDeleteLoanEmployeesProposalConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingLoanEmployeesProposalModal);
