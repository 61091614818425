import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ExportAllItems from './ExportAllItems/ExportAllItems.redux';
import ExportError from './ExportError/ExportError.redux';

import './ExportsHistoryView.scss';

const ExportsHistoryView = ({ exportsHistoryError }) => (
  <div className="k-exportsView">
    <div className="k-exportsView__title">
      <FormattedMessage id="exportsView.exportedFiles" defaultMessage="Eksportowane pliki" />
    </div>
    {exportsHistoryError ? <ExportError /> : <ExportAllItems />}
  </div>
);

ExportsHistoryView.propTypes = {
  exportsHistoryError: PropTypes.bool,
};

export default ExportsHistoryView;
