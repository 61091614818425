import { connect } from 'react-redux';

import { clearScheduleSelections, deleteFromSelectedCells } from '@/actions/schedule/selectedCells';

import ScheduleSelectingButtons from './ScheduleSelectingButtons';

const mapStateToProps = state => ({
  canDeselect: state.reducer.schedule.buttonsState.canDeselect,
  canDelete: state.reducer.schedule.buttonsState.canDelete,
  isTempleteScheduleViewSelected: state.reducer.scheduleUIState.selectedDisplayMode.type === 'templates',
  restrictions: state.reducer.userPermissions.restrictions,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
});

const mapDispatchToProps = {
  deleteFromSelectedCells,
  clearScheduleSelections,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSelectingButtons);
