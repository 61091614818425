import { connect } from 'react-redux';

import {
  changeEndRounding,
  changeEndRoundingType,
  changeEndTolerance,
  changePayoutSetting,
  changePayrollSettings,
  changeRoundingSetting,
  changeStartRounding,
  changeStartRoundingType,
  changeStartTolerance,
  changeTimeFormatSettings,
  saveNewPayrollSettings,
  savePayrollSettings,
} from '@/actions/payrollSettings.js';
import { togglePayrollSettingsModal } from '@/actions/payrollUI.js';

import PayrollSettingsModal from './PayrollSettingsModal.jsx';

const mapStateToProps = state => ({
  settings: state.reducer.payrollSettings,
  showModal: state.reducer.payrollUI.showSettingsModal,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  changeStartRounding,
  changeStartRoundingType,
  changeEndRounding,
  changeEndRoundingType,
  changeStartTolerance,
  changeEndTolerance,
  changePayoutSetting,
  changeRoundingSetting,
  togglePayrollSettingsModal,
  changePayrollSettings,
  changeTimeFormatSettings,
  savePayrollSettings,
  saveNewPayrollSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSettingsModal);
