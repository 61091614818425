import { connect } from 'react-redux';

import DashboardWelcome from './DashboardWelcome.jsx';

const mapStateToProps = state => ({
  firstName: state.reducer.currentUser.user.first_name,
  language: state.reducer.currentUser.user.settings?.language,
});

const DashboardViewContainer = connect(mapStateToProps)(DashboardWelcome);

export default DashboardViewContainer;
