import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon';
import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import MDTable from '@/components/common/MDTable/MDTable';
import LabelsContainer from '@/components/common/UI/LabelsContainer/LabelsContainer.jsx';
import { NUMBER_OF_MINUTES_IN_DAY } from '@/constants/budgetMetrics';
import { ADD_OR_EDIT_BUDGET_METRIC_MODAL } from '@/constants/modalTypes';

import { customFilters } from './BudgetMetricsTable.helpers';
import BudgetMetricsTableOptions from './BudgetMetricsTableOptions/BudgetMetricsTableOptions';
import BudgetMetricsTableRowOptions from './BudgetMetricsTableRowOptions/BudgetMetricsTableRowOptions';
import columnsData from './columns.json';
import messages from './messages';

import './BudgetMetricsTable.scss';

const BudgetMetricsTable = ({ budgetMetrics, deleteBudgetMetric, showModal, intl, deleteBudgetMetrics, canEdit }) => {
  const columns = useMemo(() =>
    columnsData.map(col => {
      if (col.accessor === 'icon')
        return {
          Header: intl.formatMessage(messages[col.Header], {}),
          accessor: col.accessor,
          filter: customFilters(col, budgetMetrics),
          Filter: () => <></>,
        };

      return {
        Header: intl.formatMessage(messages[col.Header], {}),
        accessor: col.accessor,
        filter: customFilters(col, budgetMetrics),
      };
    }),
  );

  const data = budgetMetrics.map(({ id, name, color, locations, time_interval: timeInterval, icon, type }) => ({
    id,
    name: (
      <div className="k-budgetMetricsTable__nameCell">
        <span style={{ backgroundColor: color }} className="initials" />
        <span>{name}</span>
      </div>
    ),
    type: intl.formatMessage(messages[type]),
    locations: (
      <div className="k-budgetMetricsTable__labelCell">
        {' '}
        {locations && locations.length ? (
          <LabelsContainer
            limit={4}
            moreAction={() => {
              showModal(ADD_OR_EDIT_BUDGET_METRIC_MODAL, id);
            }}
          >
            {locations.map(location => (
              <ColorLabel key={location.id} text={location.name} color={location.color} />
            ))}
          </LabelsContainer>
        ) : (
          intl.formatMessage(messages.budgetMetricsTableNoLocations)
        )}
      </div>
    ),
    time_interval:
      parseInt(timeInterval) === NUMBER_OF_MINUTES_IN_DAY
        ? intl.formatMessage(messages.budgetMetricsTableDailyInterval)
        : intl.formatMessage(messages.budgetMetricsTableMinutelyInterval, {
            value: timeInterval,
          }),
    icon: <Icon type="material" name={icon} />,
  }));

  return (
    <MDTable
      className="k-budgetMetricsTable"
      columns={columns}
      data={data}
      options={
        <BudgetMetricsTableRowOptions deleteBudgetMetric={deleteBudgetMetric} showModal={showModal} canEdit={canEdit} />
      }
      tableOptions={
        <BudgetMetricsTableOptions deleteBudgetMetrics={deleteBudgetMetrics} showModal={showModal} canEdit={canEdit} />
      }
      withCheckbox={canEdit}
      withOptions
      withBigPadding
    />
  );
};

BudgetMetricsTable.propTypes = {
  budgetMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      time_interval: PropTypes.number,
      color: PropTypes.string,
      icon: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          color: PropTypes.string,
        }),
      ),
    }),
  ),
  deleteBudgetMetric: PropTypes.func,
  deleteBudgetMetrics: PropTypes.func,
  showModal: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  canEdit: PropTypes.bool,
};

export default injectIntl(BudgetMetricsTable);
