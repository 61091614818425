import { useCallback, useState } from 'react';

import { validateInput as inputValidationHelper } from '@/utils/inputHelpers.js';
import { inputHasErrors } from '@/utils/inputValidation';

import { parseEmployees } from './addMassEmployeesModal.helpers';

export const useAddMassEmployee = (initState, hideModal, addMassEmployees, intl) => {
  const [employees, setEmployees] = useState(initState);

  const clearAndHide = useCallback(() => {
    setEmployees(initState);
    hideModal();
  }, [hideModal]);

  const createRow = () => {
    setEmployees(prev => [...prev, initState[0]]);
  };

  const deleteRow = employeeNumber => {
    setEmployees(prev => prev.filter((employee, employeePrevNumber) => employeePrevNumber !== employeeNumber));
  };

  const validateInput = async (e, index) => {
    const [name, error] = await inputValidationHelper(e);
    if (error) {
      const errorMessage = intl.formatMessage(error);
      setEmployees(prev =>
        prev.map((employee, employeePrevNumber) => {
          if (employeePrevNumber === index) {
            return { ...employee, errors: { ...employee.errors, [name]: errorMessage } };
          }
          return employee;
        }),
      );
      return error;
    }
    setEmployees(prev =>
      prev.map((employee, employeePrevNumber) => {
        if (employeePrevNumber === index) {
          return { ...employee, errors: { ...employee.errors, [name]: '' } };
        }
        return employee;
      }),
    );
    return error;
  };

  const handleNameChange = (e, employeeNumber, errors) => {
    const { name, value } = e.target;
    setEmployees(prev =>
      prev.map((employee, employeePrevNumber) => {
        if (employeePrevNumber === employeeNumber) {
          return { ...employee, [name]: value };
        }
        return employee;
      }),
    );
    if (inputHasErrors(name, errors)) {
      validateInput(e, employeeNumber);
    }
  };

  const handleSingleSelectChange = (value, employeeNumber, prop) => {
    setEmployees(prev =>
      prev.map((employee, employeePrevNumber) => {
        if (employeePrevNumber === employeeNumber) {
          return { ...employee, [prop]: value };
        }
        return employee;
      }),
    );
  };

  const selectJobTitles = ({ value: jobTitleValue }, employeeNumber) => {
    const modifiedJobTitles = employees[employeeNumber].employeeJobTitleOptions.map(jobTitle => {
      const active = jobTitle.value === jobTitleValue ? !jobTitle.active : jobTitle.active;

      return { ...jobTitle, active };
    });

    const selectedJobTitleOptions = modifiedJobTitles.filter(({ active }) => active);

    setEmployees(prev =>
      prev.map((employee, employeePrevNumber) => {
        if (employeePrevNumber === employeeNumber) {
          return {
            ...prev[employeeNumber],
            jobTitles: selectedJobTitleOptions,
            employeeJobTitleOptions: modifiedJobTitles,
          };
        }
        return employee;
      }),
    );
  };

  const selectLocations = ({ value: locationValue }, employeeNumber) => {
    const modifiedLocations = employees[employeeNumber].employeeLocationOptions.map(location => {
      const active = location.value === locationValue ? !location.active : location.active;

      return { ...location, active };
    });

    const selectedLocation = modifiedLocations.filter(({ active }) => active);

    setEmployees(prev =>
      prev.map((employee, employeePrevNumber) => {
        if (employeePrevNumber === employeeNumber) {
          return {
            ...prev[employeeNumber],
            locations: selectedLocation,
            employeeLocationOptions: modifiedLocations,
          };
        }
        return employee;
      }),
    );
  };

  const validateAll = async () => {
    const inputs = ['name', 'lastName'];
    const validationPromises = employees.reduce((acc, employee, index) => {
      inputs.forEach(input => {
        acc.push(validateInput({ target: { name: input, value: employee[input] } }, index));
      });
      return acc;
    }, []);

    const errors = await Promise.all(validationPromises);
    const hasErrors = errors.some(error => !!error);

    return !hasErrors;
  };

  const handleSubmit = async () => {
    const valid = await validateAll();
    if (!valid) return;
    const parsedEmployees = parseEmployees(employees);
    addMassEmployees(parsedEmployees);
    clearAndHide();
  };

  return [
    employees,
    clearAndHide,
    handleNameChange,
    handleSingleSelectChange,
    selectJobTitles,
    selectLocations,
    handleSubmit,
    validateInput,
    createRow,
    deleteRow,
  ];
};
