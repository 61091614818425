import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  freemiumPackageName: {
    id: 'packagesOptions.freemiumPackageName',
    defaultMessage: 'Darmowy grafik',
  },
  workTimePackageName: {
    id: 'packagesOptions.workTimePackageName',
    defaultMessage: 'Planuję czas pracy',
  },
  scheduleAndSettlePackageName: {
    id: 'packagesOptions.scheduleAndSettlePackageName',
    defaultMessage: 'Planuję i rozliczam',
  },
  premiumPackageName: {
    id: 'packagesOptions.premiumPackageName',
    defaultMessage: 'Pakiet Premium',
  },
  freemiumPackageDescription: {
    id: 'packagesOptions.freemiumPackageDescription',
    defaultMessage: 'Rozwiązanie dla wszystkich firm chcących usprawnić planowanie grafiku pracy.',
  },
  workTimePackageDescription: {
    id: 'packagesOptions.workTimePackageDescription',
    defaultMessage: 'Doskonałe rozwiązanie dla Twojej firmy, która chce usprawnić planowanie grafiku pracy.',
  },
  scheduleAndSettlePackageDescription: {
    id: 'packagesOptions.scheduleAndSettlePackageDescription',
    defaultMessage:
      'Kompletne rozwiązanie do planowania, kontroli i rozliczania czasu pracy dla biznesów, od 1 do 500 pracowników.',
  },
  premiumPackageDescription: {
    id: 'packagesOptions.premiumPackageDescription',
    defaultMessage: 'Dla przedsiębiorstw i sieci szukających dedykowanego rozwiązania.',
  },
  monthlyPerEmployee: {
    id: 'packagesOptions.monthlyPerEmployee',
    defaultMessage: 'netto za pracownika miesięcznie',
  },
  mostPopular: {
    id: 'packagesOptions.mostPopular',
    defaultMessage: 'Najpopularniejszy',
  },
});
