import {
  addLocations,
  addSupplementaryLocations,
  deleteLocations,
  editAbsenceLimits,
  editElasticWorkDay,
  editEmployeeRole,
  editEmploymentConditions,
  editScheduleCycleDuration,
  editScheduleCycleStart,
  editWeeklyWorkingMinutes,
} from '@/components/companymanage/employees/EditMassEmployeesModal/employeesEditMassHelpers.ts';

export const emplMassEditTypes = {
  location: 'location',
  jobTitles: 'jobTitles',
  employmentConditions: 'employmentConditions',
  weeklyWorkingMinutes: 'weeklyWorkingMinutes',
  scheduleCycleStart: 'scheduleCycleStart',
  scheduleCycleDuration: 'scheduleCycleDuration',
  role: 'role',
  elasticWorkDay: 'elasticWorkDay',
  limits: 'limits',
  contracts: 'contracts',
};

export const emplMassEditActions = {
  addNewItem: 'addNewItem',
  addNewItems: 'addNewItems',
  delete: 'delete',
  remove: 'remove',
  addNewSupplementary: 'addNewSupplementary',
  edit: 'edit',
  addContract: 'addContract',
  addContractsJobTitles: 'addContractsJobTitles',
  removeContractsJobTitles: 'removeContractsJobTitles',
  editContractsStartDate: 'editContractsStartDate',
};

export type EmplMassEditActions = keyof typeof emplMassEditActions;

export const emplMassEditTypeActions = {
  location: {
    [emplMassEditActions.addNewItem]: addLocations,
    [emplMassEditActions.delete]: deleteLocations,
    [emplMassEditActions.addNewSupplementary]: addSupplementaryLocations,
  },
  employmentConditions: {
    [emplMassEditActions.edit]: editEmploymentConditions,
  },
  weeklyWorkingMinutes: {
    [emplMassEditActions.edit]: editWeeklyWorkingMinutes,
  },
  scheduleCycleStart: {
    [emplMassEditActions.edit]: editScheduleCycleStart,
  },
  scheduleCycleDuration: {
    [emplMassEditActions.edit]: editScheduleCycleDuration,
  },
  role: {
    [emplMassEditActions.edit]: editEmployeeRole,
  },
  elasticWorkDay: {
    [emplMassEditActions.edit]: editElasticWorkDay,
  },
  limits: {
    [emplMassEditActions.edit]: editAbsenceLimits,
  },
  contracts: {
    [emplMassEditActions.addContract]: [],
    [emplMassEditActions.remove]: [],
    [emplMassEditActions.addContractsJobTitles]: [],
    [emplMassEditActions.removeContractsJobTitles]: [],
    [emplMassEditActions.editContractsStartDate]: [],
  },
};

export const messages = {
  overridePrimaryLocations: {
    id: 'employeesEditMass.overridePrimaryLocations',
    defaultMessage:
      'Nadpisz aktualnie dodane do pracowników lokalizacje nominalne wybranymi lokalizacjami pomocniczymi',
  },
};
