import PropTypes from 'prop-types';

import EmptyState from '@/components/emptyState/EmptyState.jsx';

import { messages } from './EmptyLocationSettings.messages';

import './EmptyLocationSettings.scss';

const EmptyLocationSettings = (props, { intl }) => (
  <div className="emptyLocationSettings">
    <EmptyState
      name="locationCTA"
      imgSrc="/img/locationsCTA.png"
      emptyStateHeader={intl.formatMessage(messages.emptyStateHeader)}
      emptyStateText={intl.formatMessage(messages.emptyStateDescription)}
    />
  </div>
  );

EmptyLocationSettings.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmptyLocationSettings;
