import PropTypes from 'prop-types';

import { getOS } from '@/utils/baseHelpers.js';

import DropArea from '../DropArea/DropArea.jsx';

const ScheduleTableDropArea = ({ blocked, employeeId, date, handleDrop, isSwapping, activeDrag }) => {
  const onDrop = e => {
    const isMacOs = getOS() === 'mac';
    const isCopyButton = isMacOs ? e.altKey : e.ctrlKey;
    handleDrop(employeeId, date, isCopyButton);
  };

  return <DropArea enabled {...{ blocked, onDrop, isSwapping, activeDrag }} />;
};

ScheduleTableDropArea.propTypes = {
  blocked: PropTypes.bool,
  employeeId: PropTypes.string,
  date: PropTypes.string,
  handleDrop: PropTypes.func,
  isSwapping: PropTypes.bool,
  activeDrag: PropTypes.bool,
};
export default ScheduleTableDropArea;
