import { ADD_HISTORY_OBJECT } from '@/constants/ActionTypes.js';

const initialState = [];

const companyHistoryStore = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HISTORY_OBJECT:
      return [...state, action.payload];
    default:
      return state;
  }
};
export default companyHistoryStore;
