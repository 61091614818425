import { messages } from './PackagesOptions.messages';

export const FREEMIUM_PACKAGE_ID = 'freemiumPackage';
export const WOKK_TIME_PACKAGE_ID = 'workTimePackageName';
export const SCHEDULE_AND_SETTLE_PACKAGE_ID = 'scheduleAndSettlePackageName';
export const PREMIUM_PACKAGE_ID = 'premiumPackageName';

export const getKadroPackages = intl => [
  {
    id: FREEMIUM_PACKAGE_ID,
    name: intl.formatMessage(messages.freemiumPackageName),
    description: intl.formatMessage(messages.freemiumPackageDescription),
    price: {
      monthly: 0,
      annually: 0,
    },
    img: '/img/freemium/freemium1.png',
    isHidden: true,
  },
  {
    id: WOKK_TIME_PACKAGE_ID,
    name: intl.formatMessage(messages.workTimePackageName),
    description: intl.formatMessage(messages.workTimePackageDescription),
    price: {
      monthly: 12.49,
      annually: 10.99,
    },
    img: '/img/freemium/freemium2.png',
  },
  {
    id: SCHEDULE_AND_SETTLE_PACKAGE_ID,
    name: intl.formatMessage(messages.scheduleAndSettlePackageName),
    description: intl.formatMessage(messages.scheduleAndSettlePackageDescription),
    price: {
      monthly: 15.99,
      annually: 12.99,
    },
    img: '/img/freemium/freemium3.png',
    isMostPopular: true,
  },
  {
    id: PREMIUM_PACKAGE_ID,
    name: intl.formatMessage(messages.premiumPackageName),
    description: intl.formatMessage(messages.premiumPackageDescription),
    price: {
      monthly: 19.99,
      annually: 16.99,
    },
    img: '/img/freemium/freemium4.png',
  },
].filter(e => !e.isHidden);
