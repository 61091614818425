import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import InputMask from 'react-input-mask';

function setChar(s, n, c) {
  return s.substr(0, n) + c + s.substr(n + 1);
}

class TimeRangeInput extends Component {
  constructor(props) {
    super(props);
    this.formatChar = {
      9: '[0-9]',
      5: '[0-5]',
      a: '[A-Za-z]',
      '*': '[A-Za-z0-9]',
    };

    this.inputChanged = this.inputChanged.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(e) {
    const { key } = e;
    const cursor = this.input.getCursorPosition();
    if (key === 'Tab') {
      let { value } = e.target;
      if (cursor < 6) {
        e.preventDefault();
        this.input.setCursorPosition(6);
        value = value.substring(0, 6).replace(/_/g, '0') + value.substring(6);
      } else {
        value = value.replace(/_/g, '0');
      }
      const out = { ...e };
      out.target.value = value;
      this.props.onChange(out);
    } else if (key === ' ') {
      let { value } = e.target;
      if (value.charAt(cursor) === '_') {
        value = setChar(value, cursor, '0');
        const out = { ...e };
        out.target.value = value;
        this.props.onChange(out);
      }
      this.input.setCursorPosition(cursor + 1);
    }
  }

  inputChanged(e) {
    let { value } = e.target;

    // 50:00-00:00 -> 05:00-00:00
    let d = value.charAt(0);
    if (d > 2) {
      value = setChar(value, 0, '0');
      value = setChar(value, 1, d);
      setTimeout(() => {
        this.input.setCursorPosition(3);
      }, 1);
    }

    // 00:00-05:00 -> 00:00-05:00
    d = value.charAt(6);
    if (d > 2) {
      value = setChar(value, 6, '0');
      value = setChar(value, 7, d);
      setTimeout(() => {
        this.input.setCursorPosition(8);
      }, 1);
    }

    d = value.substr(0, 2);
    // 24:00-00:00 -> 00:00-00:00
    if (d === '24') {
      value = `00${value.substr(2)}`;
    } else if (d > 24) {
      // 25:00-00:00 -> 23:00-00:00 possible if first adds 5 then 2
      value = `23${value.substr(2)}`;
    }

    d = value.substring(6, 8);
    // 24:00-00:00 -> 00:00-00:00
    if (d === '24') {
      value = `${value.substring(0, 6)}00${value.substr(8)}`;
    } else if (d > 24) {
      // 25:00-00:00 -> 23:00-00:00 possible if first adds 5 then 2
      value = `${value.substring(0, 6)}23${value.substr(8)}`;
    }

    const out = { ...e };
    out.target.value = value;
    this.props.onChange(out);
  }

  render() {
    let modifiers = this.props.modifiers || [];
    if (!Array.isArray(modifiers)) {
      modifiers = modifiers.split(' ');
    }
    const { classNameInput } = this.props;
    const className = classNames(
      classNameInput,
      this.props.className,
      ...modifiers.map((modifier) => `${classNameInput}--${modifier}`),
    );

    return (
      <InputMask
        className={className}
        {...this.props}
        mask="99:59-99:59"
        maskChar="_"
        datatype="time"
        type="input"
        formatChars={this.formatChar}
        style={{ textAlign: 'center', ...this.props.style }}
        onChange={this.inputChanged}
        onKeyDown={this.onKeyDown}
        value={this.props.value}
        ref={(ref) => {
          this.input = ref;
        }}
        data-test={this.props.testId}
      />
    );
  }
}

TimeRangeInput.defaultProps = {
  classNameInput: 'k-timeInput',
};

TimeRangeInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  testId: PropTypes.string,
};

export default TimeRangeInput;
