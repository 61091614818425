import { connect } from 'react-redux';

import { addAbsencesLimitsEmployee, getAbsencesLimitsEmployee, resetAbsencesLimits } from '@/actions/absences.js';
import { addEmployee, changeEmployee, getFreePin, inviteEmployee } from '@/actions/companymanage';
import { getUsersCustomFields } from '@/actions/customFIelds/customFields.ts';
import { hideModal } from '@/actions/uiState';
import { EMPLOYEE_MODAL } from '@/constants/modalTypes';

import EmployeeModal from './EmployeeModal.jsx';

const mapStateToProps = state => {
  const modalObject = state.reducer.uiState.modalObject[EMPLOYEE_MODAL];
  const employeeId = modalObject?.id;

  return {
    absencesLimitTemplates: state.reducer.absences.absencesLimitTemplates,
    absencesTypes: state.reducer.absences.absencesTypes,
    companyRoles: state.reducer.roles.companyRoles,
    currentCompany: state.reducer.currentCompany,
    currentUser: state.reducer.currentUser.user,
    employmentConditions: state.reducer.employmentConditions,
    modalObject,
    showModal: state.reducer.uiState.showModal === EMPLOYEE_MODAL,
    userEmployees: state.reducer.userEmployees,
    userPermissions: state.reducer.userPermissions,
    employeeContracts: state.reducer.contracts[employeeId],
    customFieldsDefinition: state.reducer.customFields.customFieldsDefinition,
    userCustomFields: state.reducer.customFields.usersCustomFields[employeeId],
  };
};

const mapDispatchToProps = {
  onHide: hideModal,
  addEmployee,
  changeEmployee,
  getAbsencesLimitsEmployee,
  addAbsencesLimitsEmployee,
  resetAbsencesLimits,
  getFreePin,
  inviteEmployee,
  getUsersCustomFields,
};

const EmployeeModalContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);

export default EmployeeModalContainer;
