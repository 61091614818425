/* eslint-disable max-len */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ScheduleTableOpenShift from '@/components/scheduler/ScheduleTable/ScheduleTableOpenShift/ScheduleTableOpenShiftRow/ScheduleTableOpenShiftRow.redux';
import ScheduleTableTradeShift from '@/components/scheduler/ScheduleTable/ScheduleTableTradeShift/ScheduleTableTradeShift.redux.js';
import { FREEMIUM_HIDE_OPEN_SHIFTS, FREEMIUM_HIDE_TRADE_SHIFTS } from '@/constants/Restrictions.js';
import { sortEmployees } from '@/utils/userEmployeesHelpers.js';

import EmployeeScheduleTableHeader from '../EmployeeScheduleTableHeader/EmployeeScheduleTableHeader.redux';
import EmployeeScheduleTableRow from '../EmployeeScheduleTableRow/EmployeeScheduleTableRow.redux';
import { filterEmployeesForEmployeeSchedule } from './EmployeeScheduleTable.helpers';

const EmployeeScheduleTable = props => {
  const {
    location,
    mainDateStore,
    currentUserId,
    userEmployees,
    isSchedulePublicForEmployees,
    employeeSorting,
    height,
    isTradeShiftEnabled,
    isOpenShiftEnabled,
    restrictions,
    contracts,
    userJobTitles,
    employees,
  } = props;
  const { dateArray } = mainDateStore;
  const scheduleBlockSize = dateArray.length > 7 ? 'month' : 'week';
  const scheduleTableClassName = classnames('k-newScheduleTable', {
    'k-newScheduleTable--month': dateArray.length > 10,
  });
  const currentEmployee = useMemo(
    () => userEmployees.find(({ id }) => id === currentUserId),
    [userEmployees, currentUserId],
  );
  const relevantEmployees = useMemo(
    () => filterEmployeesForEmployeeSchedule(userEmployees, currentUserId, location.id),
    [userEmployees, currentUserId, location.id],
  );
  const relevantEmployeesWithLoanedEmployees = useMemo(
    () =>
      relevantEmployees.filter(
        employee =>
          Object.keys(employees).includes(employee.id) ||
          employee?.shifts?.some(shift => shift.location.id === location.id && dateArray.includes(shift.date)),
      ),
    [dateArray, employees, location.id, relevantEmployees],
  );
  const sortedEmployeeCoworkers = useMemo(
    () =>
      isSchedulePublicForEmployees
        ? sortEmployees(
            relevantEmployeesWithLoanedEmployees,
            {
              employeeSorting,
              mainDateStore,
            },
            location.id,
            contracts,
            userJobTitles,
          )
        : [],
    [
      isSchedulePublicForEmployees,
      relevantEmployees,
      employeeSorting,
      mainDateStore,
      location.id,
      contracts,
      userJobTitles,
    ],
  );
  const employeesInCurrentLocation = useMemo(
    () => [currentEmployee, ...sortedEmployeeCoworkers],
    [currentEmployee, sortedEmployeeCoworkers],
  );
  const showTradeShifts = isTradeShiftEnabled && !restrictions.includes(FREEMIUM_HIDE_OPEN_SHIFTS);
  const showOpenShifts = isOpenShiftEnabled && !restrictions.includes(FREEMIUM_HIDE_TRADE_SHIFTS);

  return (
    <div className={scheduleTableClassName}>
      <div style={{ maxHeight: `${height}px` }} className="k-newScheduleTable__scroll">
        <div className="k-tableWrapper">
          <table>
            <thead>
              <EmployeeScheduleTableHeader size={scheduleBlockSize} locationName={location.name} />
              {showTradeShifts && <ScheduleTableTradeShift locationId={location.id} isEmployeeView />}
              {showOpenShifts && <ScheduleTableOpenShift locationId={location.id} />}
            </thead>
            <tbody>
              {employeesInCurrentLocation.map(employee => (
                <EmployeeScheduleTableRow key={employee.id} employee={employee} locationId={location.id} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

EmployeeScheduleTable.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  dateArray: PropTypes.arrayOf(PropTypes.string),
  currentUserId: PropTypes.string,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      inactive: PropTypes.bool,
    }),
  ),
  isSchedulePublicForEmployees: PropTypes.bool,
  employeeSorting: PropTypes.shape({}),
  height: PropTypes.number,
  isTradeShiftEnabled: PropTypes.bool,
  isOpenShiftEnabled: PropTypes.bool,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default EmployeeScheduleTable;
