import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  notifications: {
    id: 'schedule.sendPanel.notifications',
    defaultMessage: 'Notyfikacje:',
  },
  sendSMS: {
    id: 'schedule.sendPanel.sendSMS',
    defaultMessage: 'Wyślij powiadomienia SMS',
  },
  comment: {
    id: 'schedule.sendPanel.comment',
    defaultMessage: 'Komentarz:',
  },
  noNotify: {
    id: 'schedule.sendPanel.noNotify',
    defaultMessage: 'Nie notyfikuj',
  },
  sendNotification: {
    id: 'schedule.sendPanel.sendNotification',
    defaultMessage: 'Wyślij notyfikację:',
  },
});
