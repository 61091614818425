/* eslint-disable max-len */
import { isEqual } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { createFlexScheduleGrid } from '@/utils/autoschedulerHelpers.jsx';

import AutoGenerateCreateScheduleHeader from '../AutoGenerateCreateScheduleHeader/AutoGenerateCreateScheduleHeader.jsx';
import AutoGenerateCreateScheduleLeftColumn from '../AutoGenerateCreateScheduleLeftColumn/AutoGenerateCreateScheduleLeftColumn.jsx';
import AutoGenerateCreateScheduleLeftHeader from '../AutoGenerateCreateScheduleLeftHeader/AutoGenerateCreateScheduleLeftHeader.jsx';
import AutoGenerateCreateScheduleRowItem from '../AutoGenerateCreateScheduleRowItem/AutoGenerateCreateScheduleRowItem.jsx';
import AutoGenerateCreateScheduleSummary from '../AutoGenerateCreateScheduleSummary/AutoGenerateCreateScheduleSummary.jsx';
import AutoGenerateCreateScheduleTableHeader from '../AutoGenerateCreateScheduleTableHeader/AutoGenerateCreateScheduleTableHeader.jsx';
import { getRecommendedScheduleData } from './autoGenerateCreateSchedule.helpers.js';

import './AutoGenerateCreateSchedule.scss';

class AutoGenerateCreateSchedule extends Component {
  static moveToElement(index) {
    const el = document.querySelector(`.k-autoGenerateCreateScheduleRowItem__input[data-cellindex="${index}"]`);
    el.focus();
    setTimeout(() => {
      el.setSelectionRange(0, 100);
    }, 5);
  }

  static createEmptyDay(selectedJobTitles) {
    return selectedJobTitles.reduce(
      (acc, jobTitle) => ({
        ...acc,
        [jobTitle.id]: Array.from({ length: 24 }, () => ''),
      }),
      {},
    );
  }

  static getInitSchedule(props) {
    const { recommendedSchedule, showForecastSchedule } = props;
    const scheduleTemplate = showForecastSchedule ? recommendedSchedule : [];
    return createFlexScheduleGrid(props.selectedJobTitles, scheduleTemplate);
  }

  constructor(props) {
    super(props);
    this.state = {
      scheduleDayToCopy: null,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.clearDay = this.clearDay.bind(this);
    this.copyDay = this.copyDay.bind(this);
    this.pasteDay = this.pasteDay.bind(this);
    this.createScheduleFromTemplate = this.createScheduleFromTemplate.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.importTemplateData = this.importTemplateData.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    this.props.setSchedule(AutoGenerateCreateSchedule.getInitSchedule(this.props));
  }

  componentDidUpdate(prevProps) {
    const { setRecommendedSchedule, schedule, customDate, selectedLocationId } = this.props;

    if (setRecommendedSchedule && !isEqual(prevProps.schedule, schedule)) {
      setRecommendedSchedule(getRecommendedScheduleData(customDate, schedule, selectedLocationId));
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    const currentIndex = parseInt(document.activeElement.getAttribute('data-cellIndex'));
    if (!currentIndex && currentIndex !== 0) return;
    switch (e.key) {
      case 'ArrowLeft':
        if (currentIndex % 24 !== 0) AutoGenerateCreateSchedule.moveToElement(currentIndex - 1);
        break;
      case 'ArrowRight':
        if (currentIndex % 24 !== 23) AutoGenerateCreateSchedule.moveToElement(currentIndex + 1);
        break;
      case 'ArrowUp':
        if (currentIndex >= 24) {
          AutoGenerateCreateSchedule.moveToElement(currentIndex - 24);
          this.grid.setVerticalScroll(parseInt((currentIndex - 24) / 24) * 12);
        } else {
          e.preventDefault();
        }
        break;
      case 'ArrowDown':
        if (currentIndex < (this.props.selectedJobTitles.length - 1) * 24) {
          AutoGenerateCreateSchedule.moveToElement(currentIndex + 24);
          this.grid.setVerticalScroll(parseInt((currentIndex + 24) / 24) * 12);
        }
        break;
      default:
        break;
    }
  }

  clearDay() {
    this.props.setSchedule({
      ...this.props.schedule,
      [this.props.scheduleState.selectedDay]: AutoGenerateCreateSchedule.createEmptyDay(this.props.selectedJobTitles),
    });
  }

  copyDay() {
    this.setState({
      scheduleDayToCopy: this.props.schedule[this.props.scheduleState.selectedDay],
    });
  }

  pasteDay() {
    if (this.state.scheduleDayToCopy) {
      this.props.setSchedule({
        ...this.props.schedule,
        [this.props.scheduleState.selectedDay]: this.state.scheduleDayToCopy,
      });
      this.props.setMaxValue(this.props.scheduleState.selectedDay, this.state.scheduleDayToCopy);
    }
  }

  createScheduleFromTemplate(scheduleTemplate) {
    const { selectedJobTitles } = this.props;
    const schedule = createFlexScheduleGrid(selectedJobTitles, scheduleTemplate);
    this.props.setSchedule(schedule);
  }

  exportTemplate() {
    this.props.exportData(null, { type: 'recommendedSchedule' });
  }

  importTemplateData(file) {
    this.props
      .importScheduleTemplate(file)
      .then(schedule => {
        this.createScheduleFromTemplate(schedule);
        this.props.setRecommendedSchedule(schedule);
        this.props.autoScheduleImportSuccess();
      })
      .catch(err => {
        console.error('error', err);
        this.props.autoScheduleImportError(err);
      });
  }

  render() {
    const { schedule, maxValues, customLeftHeader, showTitle, scheduleState } = this.props;
    const selectedJobTitlesIds = this.props.selectedJobTitles.map(({ id }) => id);
    const scheduleForDay = Object.entries(schedule[scheduleState.selectedDay] || {}).reduce(
      (filtered, [jobTitleId, values]) =>
        selectedJobTitlesIds.includes(jobTitleId) ? { ...filtered, [jobTitleId]: values } : filtered,
      {},
    );

    return (
      <>
        {showTitle && (
          <AutoGenerateCreateScheduleHeader
            exportTemplate={this.exportTemplate}
            createScheduleFromTemplate={this.createScheduleFromTemplate}
            importScheduleTemplate={this.importTemplateData}
          />
        )}
        <OverlayScrollbarsComponent className="k-overlay-scrollbar">
          <div className="k-autoGenerateCreateSchedule">
            <div className="kadroGrid k-autoGenerateCreateSchedule__table">
              <div className="kadroGrid__leftHeader k-autoGenerateCreateSchedule__leftHeader">
                {customLeftHeader || (
                  <AutoGenerateCreateScheduleLeftHeader
                    clearDay={this.clearDay}
                    copyDay={this.copyDay}
                    pasteDay={this.pasteDay}
                  />
                )}
              </div>
              <div className="kadroGrid__header">
                <AutoGenerateCreateScheduleTableHeader
                  selectedDay={this.props.scheduleState.selectedDay}
                  changeDay={this.props.changeSelectedDay}
                />
              </div>
              <div className="kadroGrid__contentContainer">
                <div className="kadroGrid__leftColumn k-autoGenerateCreateSchedule__leftColumn">
                  <table>
                    <tbody>
                      {this.props.selectedJobTitles.map(jobTitle => (
                        <AutoGenerateCreateScheduleLeftColumn
                          key={jobTitle.id}
                          jobTitle={jobTitle}
                          schedule={scheduleForDay}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="kadroGrid__rowsContainer">
                  <table>
                    <tbody>
                      {this.props.selectedJobTitles.map((jobTitle, rowIndex) => {
                        const row = scheduleForDay[jobTitle.id] || [];
                        const maxValue = maxValues[scheduleState.selectedDay] || 0;
                        return (
                          <tr key={jobTitle.id}>
                            {row.map((hourValue, index) => (
                              <AutoGenerateCreateScheduleRowItem
                                key={jobTitle.id + index}
                                index={index}
                                value={hourValue}
                                rowIndex={rowIndex}
                                jobTitleId={jobTitle.id}
                                selectedDay={scheduleState.selectedDay}
                                maxValue={maxValue}
                                changeHourValue={this.props.changeHourValue}
                              />
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <AutoGenerateCreateScheduleSummary
                className="k-autoGenerateCreateSchedule__summary"
                schedule={scheduleForDay}
              />
            </div>
          </div>
        </OverlayScrollbarsComponent>
      </>
    );
  }
}

AutoGenerateCreateSchedule.defaultProps = {
  showTitle: true,
};

AutoGenerateCreateSchedule.propTypes = {
  customLeftHeader: PropTypes.node,
  maxValues: PropTypes.shape({}),
  schedule: PropTypes.shape({}),
  scheduleState: PropTypes.shape({
    selectedDay: PropTypes.number,
  }),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  autoScheduleImportSuccess: PropTypes.func,
  autoScheduleImportError: PropTypes.func,
  changeHourValue: PropTypes.func,
  changeSelectedDay: PropTypes.func,
  exportData: PropTypes.func,
  importScheduleTemplate: PropTypes.func,
  setMaxValue: PropTypes.func,
  setSchedule: PropTypes.func,
  showTitle: PropTypes.bool,
  setRecommendedSchedule: PropTypes.func,
  customDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  selectedLocationId: PropTypes.string,
};

export default AutoGenerateCreateSchedule;
