import { getCustomDateByPathname } from '@/utils/dates/dates.helpers';

import { getPathName, shouldSaveUrl } from './urlModify.utils';

export const saveFiltersInUrl = store => next => action => {
  const nextState = next(action);
  const { reducer } = store.getState();

  if (!shouldSaveUrl(reducer.uiState.filtersLoaded, action.type)) return nextState;

  const {
    employeeGrouping: { selectedGroups },
    scheduleLocationFilter,
    scheduleUIState,
    jobtitleFilter,
    employmentConditionsFilter,
    mainDateStore: { customDate, dateMode },
  } = reducer;

  const relevantCustomDate = getCustomDateByPathname(customDate, dateMode, window.location.pathname);

  const path = getPathName(
    scheduleLocationFilter,
    scheduleUIState.selectedDisplayMode.type,
    jobtitleFilter,
    relevantCustomDate,
    employmentConditionsFilter.selected,
    selectedGroups,
  );

  if (path) window.history.pushState(null, null, path);
  return nextState;
};
