import PropTypes from 'prop-types';
import { memo } from 'react';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import NumberInCircle from '@/components/common/UI/NumberInCircle/NumberInCircle.jsx';

import { messages } from './ShiftsToPublishOptions.messages';

import './ShiftsToPublishOptions.scss';

export const ShiftsToPublishOptions = (props, context) => {
  const { publishShifts, publishOpenShifts, numberOfDraftShifts, numberOfDraftOpenShifts, handleInputChange } = props;
  return (
    <>
      <div className="heading">{context.intl.formatMessage(messages.publish)}</div>
      <MDCheckbox
        id="publishShifts"
        text={
          <>
            {context.intl.formatMessage(messages.publishShifts)}
            <NumberInCircle number={numberOfDraftShifts} />
          </>
        }
        value={publishShifts}
        onChange={handleInputChange}
        reverse
        className="shiftsToPublishOptions__checkboxWithNumber"
      />
      <MDCheckbox
        id="publishOpenShifts"
        text={
          <>
            {context.intl.formatMessage(messages.publishOpenShifts)}
            <NumberInCircle number={numberOfDraftOpenShifts} />
          </>
        }
        value={publishOpenShifts}
        onChange={handleInputChange}
        reverse
        className="shiftsToPublishOptions__checkboxWithNumber"
      />
    </>
  );
};

ShiftsToPublishOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ShiftsToPublishOptions.propTypes = {
  publishShifts: PropTypes.bool,
  publishOpenShifts: PropTypes.bool,
  numberOfDraftShifts: PropTypes.number,
  numberOfDraftOpenShifts: PropTypes.number,
  handleInputChange: PropTypes.func,
};

export default memo(ShiftsToPublishOptions);
