import classnames from 'classnames';
import PropTypes from 'prop-types';

import './EmptyState.scss';

const EmptyState = (props) => {
  const {
    imgSrc,
    name,
    emptyStateHeader,
    emptyStateText,
    ctaText,
    ctaHandler,
    className,
    style,
    filerStyle,
    modifiers,
  } = props;

  const emptyStateModifiers = modifiers || [];
  const emptyStateClassNames = classnames(
    'k-EmptyState__content',
    className,
    ...emptyStateModifiers.map((modifier) => `k-EmptyState__content--${modifier}`),
  );
  const emptyStateContentContainerClassNames = classnames(
    'k-EmptyState__contentContainer',
    className,
    ...emptyStateModifiers.map((modifier) => `k-EmptyState__contentContainer--${modifier}`),
  );

  const emptyStateImageClassNames = classnames(
    'k-EmptyState__image',
    className,
    ...emptyStateModifiers.map((modifier) => `k-EmptyState__image--${modifier}`),
  );
  return (
    <div className={emptyStateClassNames} style={style}>
      <div className={emptyStateContentContainerClassNames}>
        {imgSrc ? (
          <div className="k-EmptyState__imageContainer">
            <img className={emptyStateImageClassNames} src={imgSrc} alt={name} />
          </div>
        ) : null}
        <div className="k-EmptyState__textContainer">
          <h2 className="k-EmptyState__header"> {emptyStateHeader || ''} </h2>
          <p>
            {emptyStateText || ''}
            <span className="k-EmptyState__link" role="presentation" onClick={ctaHandler}>
              {` ${ctaText || ''}`}
            </span>
          </p>
        </div>
      </div>
      <div className="k-EmptyState__filterBlock" style={filerStyle} />
    </div>
  );
};
EmptyState.defaultProps = {
  filerStyle: {},
  style: {},
};
EmptyState.propTypes = {
  imgSrc: PropTypes.string,
  emptyStateHeader: PropTypes.node,
  emptyStateText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string,
  ctaText: PropTypes.string,
  ctaHandler: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  filerStyle: PropTypes.shape({}),
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default EmptyState;
