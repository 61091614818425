import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Area, AreaChart, ResponsiveContainer,Tooltip, XAxis, YAxis } from 'recharts';

import ScheduleTableGraphTooltip from '@/components/common/ScheduleTableGraphTooltip.jsx';

const ScheduleTableHeaderGraphCell = ({ date, points, day, hour, max, dayNumber, showRecommendedScheduleGraph }) => (
  <div className="graphWrapper">
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={points}>
        <XAxis hide />
        <YAxis hide type="number" domain={[0, max || 10]} />
        <Tooltip
          content={
            <ScheduleTableGraphTooltip
              date={date}
              hour={hour}
              day={day}
              showRecommendedScheduleGraph={showRecommendedScheduleGraph}
            />
          }
          cursor={false}
          wrapperStyle={{ zIndex: 200, color: '#85669F' }}
          position={dayNumber > 3 || hour > 12 ? { x: -160, y: 0 } : { x: 160, y: 0 }}
        />
        <Area
          type="step"
          dataKey="ye"
          stroke="#85669F"
          fill="#85669F"
          fillOpacity={0.7}
          name={<FormattedMessage id="schedule.graphEmployees" defaultMessage="Pracownicy" />}
        />
        <Area
          type="step"
          dataKey="ys"
          stroke="#1E90FF"
          fill="#1E90FF"
          fillOpacity={0.7}
          name={<FormattedMessage id="schedule.graphSuggestedEmployees" defaultMessage="Sugerowani pracownicy" />}
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

ScheduleTableHeaderGraphCell.propTypes = {
  day: PropTypes.bool,
  dayNumber: PropTypes.number,
  hour: PropTypes.number,
  max: PropTypes.number,
  date: PropTypes.string,
  showRecommendedScheduleGraph: PropTypes.bool,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ),
};

export default ScheduleTableHeaderGraphCell;
