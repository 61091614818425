import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { selectSupplementaryEmployees } from '@/redux-store/scheduleState/locations';
import { Location } from '@/types';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getScheduleDaysColSpan } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useSupplementaryEmployees = (locationId: Location['id']) => {
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const dateArray = useAppSelector(selectDateArray);
  const colSpan = getScheduleDaysColSpan(dateArray, isSingleDay);
  const supplementaryEmployeeIds = useAppSelector(selectSupplementaryEmployees(locationId));

  const hideSupplementaryEmployees = isEmptyArray(supplementaryEmployeeIds);

  return { colSpan, hideSupplementaryEmployees, isSingleDay, supplementaryEmployeeIds };
};
