import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  modalTitle: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.modalTitle',
    defaultMessage: 'Usuń dane zwolnionych pracowników',
  },
  deleteButton: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
  cancelButton: {
    id: 'common.cancel',
    defaultMessage: 'Anuluj',
  },
  notReleasedEmployeesPicked: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.notReleasedEmployeesPicked',
    defaultMessage: 'Nie można usunąć danych zwolnionych pracowników, ponieważ zostali wybrani niezwolnieni pracownicy',
  },
  deleteShifts: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.deleteShifts',
    defaultMessage: 'Usuń zmiany',
  },
  deleteAbsences: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.deleteAbsences',
    defaultMessage: 'Usuń nieobecności',
  },
  deleteAttendances: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.deleteAttendances',
    defaultMessage: 'Usuń obecności',
  },
  selectedThreeEmployeesAreNotReleased: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.selectedThreeEmployeesAreNotReleased',
    defaultMessage: 'Wybrani pracownicy nie są zwolnieni: {unreleasedEmployeesNamesString}',
  },
  selectedEmployeesAreNotReleased: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.selectedEmployeesAreNotReleased',
    defaultMessage:
      'Wybrani pracownicy nie są zwolnieni: {unreleasedEmployeesNamesString} i {remainder} {remainder, plural, one {inny} few {innych} many {innych} other {innych}}',
  },

  selectDataToDelete: {
    id: 'companymanage.employees.DeleteReleasedEmployeesDataModal.selectDataToDelete',
    defaultMessage: 'Wybierz dane do usunięcia',
  },
});
