import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  createConversationError: {
    id: 'chat.createConversationError',
    defaultMessage: 'Nie udało się utworzyć konwersacji.',
  },
  fetchingChatUsersError: {
    id: 'chat.fetchingChatUsersError',
    defaultMessage: 'Nie udało się pobrać listy użytkowników czatu',
  },
});
