import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DateRange } from 'react-date-range';

import { convertDateToStandardFormat, getToday, isBeforeTimestamp } from '@/utils/dateHelper';
import { getLocaleConfig } from '@/utils/locale/locale.utils';

import './KadroCalendarMultiple.scss';

/*
  Library for calendar has not support to select multiple dates
  Calendar needs empty selection to work correctly and show correct month after opening calendar
  Selected days are marked by using css styles
*/

const KadroCalendarMultiple = (props, context) => {
  const { selected, minDate, maxDate, disabledDates } = props;
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [lastDateSelected, setLastDateSelected] = useState(new Date(selected[0] || getToday()));
  const datesFormatted = selected.map((date) => convertDateToStandardFormat(date));
  const disabledDatesFormatted = disabledDates.map((disabledDate) => new Date(disabledDate));
  const shownDate = new Date(selected[0] || getToday());

  const emptySelection = {
    startDate: lastDateSelected,
    endDate: undefined,
    key: 'emptySelection',
    color: 'transparent',
  };

  const customDayContent = (day) => {
    const isDaySelected = datesFormatted.includes(convertDateToStandardFormat(day));
    const dayCellClassNames = classnames('kadroCalendarMultiple__date', {
      'kadroCalendarMultiple__date--selected': isDaySelected,
    });
    return <span className={dayCellClassNames}>{moment(day).format('D')}</span>;
  };

  const onChange = (item) => {
    const { startDate, endDate } = Object.values(item)[0];
    const isFocusStartDate = focusedRange[1] === 0;
    const date = isFocusStartDate || isBeforeTimestamp(startDate, lastDateSelected) ? startDate : endDate;
    setLastDateSelected(date);
    props.onChange(date);
  };

  return (
    <div className="kadroCalendarMultiple">
      <DateRange
        {...{ onChange, maxDate, minDate, shownDate }}
        disabledDates={disabledDatesFormatted}
        onRangeFocusChange={setFocusedRange}
        dayContentRenderer={customDayContent}
        ranges={[emptySelection]}
        showPreview={false}
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        preventSnapRefocus
        direction="vertical"
        // scroll={{ enabled: true }} https://github.com/hypeserver/react-date-range/issues/577
        locale={getLocaleConfig(context.intl.locale).dateFnsLocale}
        monthDisplayFormat="LLLL yyyy"
      />
    </div>
  );
};

KadroCalendarMultiple.defaultProps = {
  disabledDates: [],
};

KadroCalendarMultiple.contextTypes = {
  intl: PropTypes.shape({}),
};

KadroCalendarMultiple.propTypes = {
  onChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.date),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disabledDates: PropTypes.arrayOf(PropTypes.string),
};

export default KadroCalendarMultiple;
