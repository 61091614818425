import { changeScheduleChartsData, changeScheduleMaxValues } from '@/actions/scheduleCharts.js';
import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences';
import * as AT from '@/constants/ActionTypes.js';

import {
  getHighestEmployeesAmountForNewPoints,
  handleAddAbsence,
  handleAddMassShifts,
  handleAddShift,
  handleAddShifts,
  handleChangeJobTitleFilterGrouped,
  handleDeleteAbsence,
  handleDeleteMultipleShifts,
  handleDeleteRecommendedSchedule,
  handleDeleteShift,
  handleDuplicatePreviousView,
  handleEditAbsence,
  handleEditShift,
  handleGetAbsences,
  handleGetBudgetMetricsData,
  handleGetSchedule,
  handleNewRecommendedSchedule,
  handleUpdateScheduleViewEmployeesList,
} from './scheduleCharts.helpers.js';

const relevantActions = [
  AT.ADD_SHIFT_SUCCESFUL,
  AT.ADD_SHIFTS_SUCCESFUL,
  AT.EDIT_SHIFT_SUCCESFUL,
  AT.DELETE_SHIFT,
  AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS,
  AT.CHANGE_JOBTITLE_FILTER,
  AT.GET_BUDGET_ESTIMATES_SUCCESS,
  AT.EDIT_BUDGET_ESTIMATES_SUCCESS,
  AT.DELETE_BUDGET_ESTIMATES_SUCCESS,
  AT.ADD_MULTIPLE_ESTIMATES_SUCCESS,
  AT.GET_RECOMMENDED_SCHEDULE_SUCCESS,
  AT.ADD_RECOMMENDED_SCHEDULE_SUCCESS,
  AT.DELETE_RECOMMENDED_SCHEDULE_SUCCESS,
  AT.GET_ABSENCES_FOR_SCHEDULE,
  AT.ADD_ABSENCE_SUCCESS,
  AT.EDIT_ABSENCE_SUCCESS,
  AT.DELETE_ABSENCE_SUCCESS,
  AT.GET_SCHEDULE_SUCCESFUL,
  AT.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST,
  AT.ADD_MASS_SHIFTS_SUCCESFUL,
  AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
  AT.GET_BUDGET_METRIC_DATA_SUCCESS,
  AT.UPDATE_BUDGET_METRIC_DATA_SUCCESS,
];

const scheduleChartsMiddleware = store => next => action => {
  if (!relevantActions.includes(action.type)) {
    return next(action);
  }

  const {
    absences: { scheduleAbsences: absences },
    mainDateStore: { dateArray },
    recommendedSchedule,
    jobtitleFilter: { selectedJobtitlesGrouped },
    shifts: { data: shifts },
    schedule: { scheduleCharts },
    scheduleLocationFilter,
    userPermissions: { restrictions },
    scheduleState,
    budgetMetrics,
    budgetMetricsData,
    contracts,
  } = store.getState().reducer;

  const selectedLocations = scheduleLocationFilter;
  const flatAbsences = Object.values(absences)
    .flat()
    .filter(({ status }) => !NOT_COUNTED_ABSENCE_STATUSES.includes(status));

  switch (action.type) {
    case AT.DELETE_RECOMMENDED_SCHEDULE_SUCCESS: {
      const { locationId } = action.payload;
      const newState = handleDeleteRecommendedSchedule(action, scheduleCharts);

      store.dispatch(changeScheduleChartsData(newState, locationId));
      store.dispatch(changeScheduleMaxValues({ recommended: 0 }, locationId));

      return next(action);
    }
    case AT.ADD_SHIFT_SUCCESFUL: {
      const result = handleAddShift(
        action,
        scheduleCharts,
        flatAbsences,
        scheduleState,
        shifts,
        selectedJobtitlesGrouped,
        recommendedSchedule,
        restrictions,
        budgetMetrics,
        budgetMetricsData,
        contracts,
      );

      if (!result) {
        return next(action);
      }

      const locationId = action.payload.shift.location.id;
      const { newState, employeesMax } = result;

      store.dispatch(changeScheduleChartsData(newState, locationId));

      if (employeesMax > scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
        store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
      }

      return next(action);
    }
    case AT.EDIT_SHIFT_SUCCESFUL: {
      const newState = handleEditShift(
        action,
        scheduleCharts,
        flatAbsences,
        shifts,
        scheduleState,
        dateArray,
        selectedJobtitlesGrouped,
        recommendedSchedule,
        restrictions,
        budgetMetrics,
        budgetMetricsData,
        contracts,
      );

      if (!newState) {
        return next(action);
      }

      const locationId = action.payload.newShiftObject.location.id;

      store.dispatch(changeScheduleChartsData(newState, locationId));

      return next(action);
    }
    case AT.DELETE_SHIFT: {
      const newState = handleDeleteShift(
        action,
        scheduleCharts,
        flatAbsences,
        scheduleState,
        shifts,
        selectedJobtitlesGrouped,
        recommendedSchedule,
        restrictions,
        budgetMetrics,
        budgetMetricsData,
        contracts,
      );

      if (!newState) {
        return next(action);
      }

      const locationId = action.payload.shift.location.id;
      store.dispatch(changeScheduleChartsData(newState, locationId));
      store.dispatch(
        changeScheduleMaxValues({ employees: getHighestEmployeesAmountForNewPoints(newState) }, locationId),
      );

      return next(action);
    }
    default:
      break;
  }

  selectedLocations.forEach(locationId => {
    const locationScheduleState = scheduleState.locations[locationId];
    const visibleEmployees = locationScheduleState ? locationScheduleState.visible : [];
    switch (action.type) {
      case AT.ADD_RECOMMENDED_SCHEDULE_SUCCESS:
      case AT.GET_RECOMMENDED_SCHEDULE_SUCCESS: {
        const { newState, recommendedMax } = handleNewRecommendedSchedule(
          action,
          locationId,
          scheduleCharts,
          dateArray,
          flatAbsences,
          shifts,
          selectedJobtitlesGrouped,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (recommendedMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.recommended) {
          store.dispatch(changeScheduleMaxValues({ recommended: recommendedMax }, locationId));
        }

        break;
      }
      case AT.GET_SCHEDULE_SUCCESFUL: {
        const result = handleGetSchedule(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }
        const { employeesMax, newState } = result;

        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        store.dispatch(changeScheduleChartsData(newState, locationId));

        break;
      }
      case AT.ADD_SHIFTS_SUCCESFUL: {
        const result = handleAddShifts(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;
        store.dispatch(changeScheduleChartsData(newState, locationId));

        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS: {
        const result = handleDuplicatePreviousView(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;

        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.CHANGE_JOBTITLE_FILTER: {
        const { newState, employeesMax, recommendedMax } = handleChangeJobTitleFilterGrouped(
          action,
          locationId,
          scheduleCharts,
          dateArray,
          flatAbsences,
          shifts,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (
          employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees ||
          recommendedMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.recommended
        ) {
          store.dispatch(
            changeScheduleMaxValues(
              {
                employees: employeesMax,
                recommended: recommendedMax,
              },
              locationId,
            ),
          );
        }

        break;
      }
      case AT.GET_ABSENCES_FOR_SCHEDULE: {
        const { newState, employeesMax } = handleGetAbsences(
          action,
          locationId,
          scheduleCharts,
          dateArray,
          shifts,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.ADD_ABSENCE_SUCCESS: {
        const result = handleAddAbsence(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;
        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.EDIT_ABSENCE_SUCCESS: {
        const result = handleEditAbsence(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;
        store.dispatch(changeScheduleChartsData(newState, locationId));
        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.DELETE_ABSENCE_SUCCESS: {
        const result = handleDeleteAbsence(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;

        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        store.dispatch(changeScheduleChartsData(newState, locationId));

        break;
      }
      case AT.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST: {
        if (action.payload[locationId]?.visible) {
          const { newState, employeesMax } = handleUpdateScheduleViewEmployeesList(
            action,
            locationId,
            scheduleCharts,
            flatAbsences,
            shifts,
            dateArray,
            selectedJobtitlesGrouped,
            recommendedSchedule,
            restrictions,
            budgetMetrics,
            budgetMetricsData,
            contracts,
          );

          if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
            store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
          }

          store.dispatch(changeScheduleChartsData(newState, locationId));
        }
        break;
      }
      case AT.ADD_MASS_SHIFTS_SUCCESFUL: {
        const result = handleAddMassShifts(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!result) {
          break;
        }

        const { newState, employeesMax } = result;
        store.dispatch(changeScheduleChartsData(newState, locationId));

        if (employeesMax !== scheduleCharts.scheduleChartsMaxValues[locationId]?.employees) {
          store.dispatch(changeScheduleMaxValues({ employees: employeesMax }, locationId));
        }

        break;
      }
      case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL: {
        const newState = handleDeleteMultipleShifts(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        if (!newState) {
          break;
        }

        store.dispatch(changeScheduleChartsData(newState, locationId));
        store.dispatch(
          changeScheduleMaxValues({ employees: getHighestEmployeesAmountForNewPoints(newState) }, locationId),
        );

        break;
      }
      case AT.GET_BUDGET_METRIC_DATA_SUCCESS:
      case AT.UPDATE_BUDGET_METRIC_DATA_SUCCESS: {
        const { newState, budgetMetricsMax } = handleGetBudgetMetricsData(
          action,
          locationId,
          scheduleCharts,
          flatAbsences,
          shifts,
          dateArray,
          selectedJobtitlesGrouped,
          recommendedSchedule,
          restrictions,
          visibleEmployees,
          budgetMetrics,
          budgetMetricsData,
          contracts,
        );

        store.dispatch(changeScheduleChartsData(newState, locationId));

        store.dispatch(changeScheduleMaxValues({ budgetMetricsMax }, locationId));

        break;
      }
      // TODO: it will be required when we have budget data on charts
      case AT.GET_BUDGET_ESTIMATES_SUCCESS:
      case AT.EDIT_BUDGET_ESTIMATES_SUCCESS:
      case AT.DELETE_BUDGET_ESTIMATES_SUCCESS:
      case AT.ADD_MULTIPLE_ESTIMATES_SUCCESS:
      default:
        break;
    }
  });

  return next(action);
};

export default scheduleChartsMiddleware;
