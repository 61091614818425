import { connect } from 'react-redux';

import Component from './ScheduleTableItemBackground.jsx';

const mapStateToProps = (state, ownProps) => ({
  dayType: state.reducer.schedule.dayTypes[ownProps.date] || 'workDay',
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  holidaysEnabled: state.reducer.schedule.viewSettings?.holidays?.value,
  availabilitiesModeEnabled: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  isAvaDragged: Boolean(state.reducer.schedule.dragAndDrop.draggedAvailability.id),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
