import {
  REPORTS_DATE_CHANGE_CUSTOM_DATE,
  REPORTS_DATE_CHANGE_MODE,
  REPORTS_DATE_CHANGE_MODE_AND_DATE,
  REPORTS_DATE_MOVE_LEFT,
  REPORTS_DATE_MOVE_RIGHT,
} from '@/constants/ActionTypes.js';
import {
  generateDateArrayWithShiftInt,
  generateDateTextFromArray,
  generateHeaderDateArray,
  generateTemplatesDateArray,
  getToday,
} from '@/utils/dateHelper.js';
import { changeCustomDate, changeMode, changeModeAndDate, moveLeft, moveRight } from '@/utils/dateStoreHelpers.js';

const baseDateArray = generateDateArrayWithShiftInt('month', 0);
const basePreviousDateArray = generateDateArrayWithShiftInt('month', -1);
const baseDateText = generateDateTextFromArray('month', baseDateArray);
const baseHeaderDateArray = generateHeaderDateArray('month', baseDateArray);
const baseTemplatesDateArray = generateTemplatesDateArray();
export const initialState = {
  dateShiftInt: 0,
  dateMode: 'month', // month, week, custom
  dateArray: baseDateArray,
  customDate: {
    start: baseDateArray[0],
    end: baseDateArray[baseDateArray.length - 1],
  },
  // Previous date array refers to the previous period, use f.e. in schedule
  previousDateArray: basePreviousDateArray,
  headerDateArray: baseHeaderDateArray,
  templatesDateArray: baseTemplatesDateArray,
  dateText: baseDateText,
  today: getToday(),
};

export const dateStore = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_DATE_CHANGE_MODE: {
      return changeMode(state, action);
    }
    case REPORTS_DATE_MOVE_LEFT: {
      return moveLeft(state);
    }
    case REPORTS_DATE_MOVE_RIGHT: {
      return moveRight(state);
    }
    case REPORTS_DATE_CHANGE_CUSTOM_DATE: {
      return changeCustomDate(state, action);
    }
    case REPORTS_DATE_CHANGE_MODE_AND_DATE: {
      return changeModeAndDate(state, action);
    }
    default:
      return state;
  }
};
