import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import Popover from '@/components/common/Popover/Popover.jsx';

const messages = defineMessages({
  attendanceOverlappedAbsence: {
    id: 'payrollTableRowWarning.attendanceOverlappedAbsence',
    defaultMessage: 'W tym dniu występuje obecność i nieobecność w tych samych godzinach',
  },
  attendancesOverlap: {
    id: 'payrollTableRowWarning.attendancesOverlap',
    defaultMessage: 'W tym dniu pracownik ma dwie lub więcej obecności nachodzące',
  },
});

const PayrollTableRowWarning = ({ warningTypes, count }) => {
  const warningMessages = warningTypes ? warningTypes.map(warning => <FormattedMessage {...messages[warning]} />) : [];
  const icon = (
    <div style={{ position: 'relative' }} className="k-warning">
      <Icon name="warning" className="k-workingRulesIcon__icon k-icon-warning" />
      {count > 0 && (
        <div className="k-workingRulesIcon__counter">
          <span className="k-workingRulesIcon__number">{count}</span>
        </div>
      )}
    </div>
  );

  if (!warningMessages || !warningMessages.length) {
    return icon;
  }

  return (
    <Popover
      position="right"
      updatePosition
      content={
        <span>
          {warningMessages.map(warning => (
            <div>{warning}</div>
          ))}
        </span>
      }
      showOnHover
      showShadow={false}
      autoSideOffset
      xOffset={12}
      yOffset={5}
    >
      {icon}
    </Popover>
  );
};

PayrollTableRowWarning.propTypes = {
  warningTypes: PropTypes.arrayOf(PropTypes.string),
  count: PropTypes.number,
};

export default PayrollTableRowWarning;
