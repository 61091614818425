import PropTypes from 'prop-types';

import MDTableCheckbox from '../MDTableCheckbox';

const CheckboxColumnHeader = ({ getToggleAllRowsSelectedProps, disableSelectAllCheckbox }) => (
  <div className="mdTable__selectHeader">
    <MDTableCheckbox {...getToggleAllRowsSelectedProps()} disabled={disableSelectAllCheckbox} />
  </div>
);

CheckboxColumnHeader.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func,
  disableSelectAllCheckbox: PropTypes.bool,
};

export default CheckboxColumnHeader;
