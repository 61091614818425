import moment from 'moment';

import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences.js';

import { messages } from './EmployeeScheduleBlocks.messages';

export const getEmployeeShifts = (shifts, date, locationId) =>
  shifts.reduce(
    (prev, s) => (date === s.date && s.location.id === locationId ? [...prev, { ...s, blockType: 'shift' }] : prev),
    [],
  );

export const getEmployeeShiftsForOtherLocations = (shiftsForOtherLocations, date, locationId) =>
  shiftsForOtherLocations.reduce(
    (prev, s) =>
      date === s.date && s.location.id !== locationId
        ? [...prev, { ...s, otherLocation: true, blockType: 'shift' }]
        : prev,
    [],
  );

export const getEmployeeOvertimeCollectionForDate = (employeeOvertimeCollections, date) =>
  employeeOvertimeCollections.reduce(
    (prev, absence) =>
      !absence.draft && moment(absence.start_timestamp).isSame(date, 'days')
        ? [...prev, { ...absence, blockType: 'overtimeCollection' }]
        : prev,
    [],
  );

export const getEmployeeAbsences = (employeeAbsences, date, index) =>
  employeeAbsences.reduce(
    (prev, absence) =>
      !NOT_COUNTED_ABSENCE_STATUSES.includes(absence.status) &&
      (absence.from === date || (index === 0 && absence.from < date))
        ? [...prev, { ...absence, blockType: 'absence' }]
        : prev,
    [],
  );

export const hasEmployeeAbsence = (employeeAbsences, date) =>
  employeeAbsences.some(
    absence => absence.from <= date && absence.to >= date && !NOT_COUNTED_ABSENCE_STATUSES.includes(absence.status),
  );

export const getSortedEmployeeShifts = (shifts, shiftsForOtherLocations) =>
  [...shifts, ...shiftsForOtherLocations].sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1));

export const getAbsenceName = (absence, absencesTypes, isMonthView, currentUserId, intl) => {
  if (absence.employee_id !== currentUserId) return intl.formatMessage(messages.employeeScheduleTableAbsence);
  const absenceType = absencesTypes.find(type => type.id === absence.type_id) || {};
  const absenceName =
    (isMonthView || absence.absence_hours) && absence.from === absence.to ? absenceType.short_name : absenceType.name;
  return absenceName;
};
