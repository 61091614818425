import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { ADD_EVENT_MODAL, EDIT_EVENT_MODAL } from '@/constants/modalTypes.js';

const ScheduleEventBlock = ({
  size,
  event,
  deleteUserEvent,
  showModal,
  locationName,
  date,
  isEmployee,
  modifiers,
  enableSize,
}) => {
  const classNames = classnames(
    'sch_block',
    {
      ev_imp: event.type === 'important',
      ev_note: event.type !== 'important',
      sch_block_no_hover: isEmployee,
      'sch_block--tall': enableSize && size <= 3,
    },
    ...modifiers.map(modifier => `sch_block--${modifier}`),
  );

  const iconClassNames = classnames({
    info_outline: event.type === 'important',
    border_color: event.type !== 'important',
  });

  return (
    <HoverPopOver
      name="eventbloch"
      popover={{
        title: <FormattedMessage id="schedule.events.popupHeader" defaultMessage="Opis" />,
        content: event.description,
      }}
    >
      <div key={event.id} className={classNames}>
        <div className="sch_block__info">
          <div className="time">
            {size < 5 ? event.hours.replace('-', ' ') : event.hours}
            {size >= 5 && <i className="material-icons ev_ico">{iconClassNames}</i>}
          </div>
          <div className="sch_block__location-name">{locationName}</div>
        </div>

        <i
          className="sch_block__editIcon material-icons"
          onClick={() => showModal(EDIT_EVENT_MODAL, event)}
          role="presentation"
        >
          edit
        </i>
        <i onClick={() => deleteUserEvent(event)} className="material-icons del-block" role="presentation">
          delete
        </i>
        <i
          onClick={() => showModal(ADD_EVENT_MODAL, { date })}
          className="material-icons add-block"
          role="presentation"
        >
          add
        </i>
      </div>
    </HoverPopOver>
  );
};

ScheduleEventBlock.defaultProps = {
  size: 10,
  modifiers: [],
};

ScheduleEventBlock.propTypes = {
  size: PropTypes.number,
  event: PropTypes.shape({
    id: PropTypes.string,
    hours: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
  }),
  date: PropTypes.string,
  isEmployee: PropTypes.bool,
  locationName: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  deleteUserEvent: PropTypes.func,
  showModal: PropTypes.func,
};

export default ScheduleEventBlock;
