import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { BUDGET_ESTIMATES_EDIT, BUDGET_ESTIMATES_VIEW } from '@/constants/Permissions.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { isHoliday, isWeekend, parseMinutesToHumanForm } from '@/utils/dateHelper';
import { getScheduleSettingsValue } from '@/utils/schedulerHelpers.js';

import ScheduleBudgetTableRowInput from '../ScheduleBudgetTableRowInput/ScheduleBudgetTableRowInput.jsx';

const EMPTY_BUDGET_ESTIMATE = { estimated_budget: 0, actual_budget: 0, labour_limit: 0 };

class ScheduleBudgetTableRows extends Component {
  static getActualLabourLimitClassname(isUnder) {
    return classnames('k-budgetTable__actualLabour', {
      'k-budgetTable__actualLabour--under': isUnder,
      'k-budgetTable__actualLabour--over': !isUnder,
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };

    bindPrototypeFunctions(this);
  }

  onEstimateChange(event, estimate, name) {
    const eventKey = Object.keys(event)[0];
    const hasError = this.state.errors[name] && this.state.errors[name][estimate.date];
    if (event[eventKey] !== estimate[eventKey] && !hasError) {
      this.props.budgetEstimatesChange(estimate, {
        ...event,
        date: estimate.date,
        location_id: this.props.scheduleLocationFilter[0],
      });
    }
  }

  getBudgetClassname(date) {
    const holiday = isHoliday(date, this.props.holidays);
    const showHolidays =
      this.props.scheduleUIState.selectedDisplayMode.type !== 'templates' &&
      getScheduleSettingsValue(this.props.scheduleUIState.settings, 'holidays');
    return classnames('k-budgetTable__cell', {
      today: date === this.props.mainDateStore.today,
      weekend: isWeekend(date),
      freeFromWorkHoliday: showHolidays && holiday.freeFromWork,
      workDayHoliday: showHolidays && holiday.workDay,
    });
  }

  getBudgetEstimateClassname(estimate, isToday, name) {
    const holiday = isHoliday(estimate.date, this.props.holidays);
    const showHolidays =
      this.props.scheduleUIState.selectedDisplayMode.type !== 'templates' &&
      getScheduleSettingsValue(this.props.scheduleUIState.settings, 'holidays');

    const hasError = Boolean(this.state.errors[name] && this.state.errors[name][estimate.date]);

    return classnames('k-budgetTable__cell', {
      today: isToday,
      'k-budgetTable__cell--error': hasError,
      weekend: isWeekend(estimate.date),
      freeFromWorkHoliday: showHolidays && holiday.freeFromWork,
      workDayHoliday: showHolidays && holiday.workDay,
    });
  }

  setCellError(name, date, error) {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: {
          ...prevState.errors[name],
          [date]: error,
        },
      },
    }));
  }

  render() {
    const {
      currentTemplate,
      scheduleUIState: { selectedDisplayMode },
      sumEmployees,
      sumCosts,
      sumHours,
      sumRecommendedWorkedHours,
      relevantBudgetEstimates,
      showRecommendedSchedule,
      selectedOptions,
      locationSettings,
      scheduleLocationFilter,
    } = this.props;

    const selectedLocationSettings = locationSettings[scheduleLocationFilter[0]];
    const canEditBudget =
      scheduleLocationFilter.length === 1 && selectedLocationSettings?.budget_estimates_period === 'day';

    const isEmployeeCountHidden =
      selectedDisplayMode.type === 'templates' && currentTemplate.type === TEMPLATE_TYPES.FLEX;

    return (
      <>
        <tr>
          {this.props.mainDateStore.dateArray.map((date, idx) => (
            <td key={idx} className={this.getBudgetClassname(date)}>
              <FeatureWrapper hide={isEmployeeCountHidden}>
                <div className="k-budgetTable__cell--employee">
                  <Icon type="kadro" name="profile" />
                  {sumEmployees[idx]?.value}
                </div>
              </FeatureWrapper>
              <div className="k-budgetTable__cell--data">{parseMinutesToHumanForm(sumHours[idx]?.value)}</div>
              <div className="k-budgetTable__cell--data">{roundToTwoSigDigits(sumCosts[idx]?.value)} PLN</div>
            </td>
          ))}
        </tr>
        {showRecommendedSchedule && selectedOptions.includes('schedule') ? (
          <tr>
            {this.props.mainDateStore.dateArray.map((date, idx) => (
              <td key={idx} className={this.getBudgetClassname(date)}>
                <div>{parseMinutesToHumanForm(sumRecommendedWorkedHours[idx])}</div>
              </td>
            ))}
          </tr>
        ) : null}
        <FeatureWrapper
          permission={BUDGET_ESTIMATES_VIEW}
          hide={selectedDisplayMode.type === 'templates' || this.props.scheduleLocationFilter.length > 1}
        >
          {selectedOptions.includes('estimates') ? (
            <tr>
              {relevantBudgetEstimates.map((estimate, idx) => (
                <td
                  className={this.getBudgetEstimateClassname(
                    estimate,
                    estimate.date === this.props.mainDateStore.today,
                    'estimated_budget',
                  )}
                >
                  {canEditBudget ? (
                    <ScheduleBudgetTableRowInput
                      key={`${idx * estimate.location_id}`}
                      name="estimated_budget"
                      estimate={estimate}
                      scheduleUIState={this.props.scheduleUIState}
                      disabled={!this.props.userPermissions.permissions.includes(BUDGET_ESTIMATES_EDIT)}
                      onChange={e => this.onEstimateChange(e, estimate, 'estimated_budget')}
                      setError={this.setCellError}
                    />
                  ) : (
                    <div>{Math.round(estimate.estimated_budget / 100)} PLN</div>
                  )}
                </td>
              ))}
            </tr>
          ) : null}
          {selectedOptions.includes('percentEstimates') && (
            <tr>
              {relevantBudgetEstimates.map((estimate, idx) => {
                const date = this.props.mainDateStore.dateArray[idx];
                const budgetEstimate = relevantBudgetEstimates.find(est => est.date === date) || EMPTY_BUDGET_ESTIMATE;
                const cost = sumCosts[idx]?.value;
                const result =
                  !budgetEstimate.estimated_budget || !cost
                    ? 0
                    : roundToTwoSigDigits((cost * 100) / (budgetEstimate.estimated_budget / 100));
                return (
                  <td
                    className={this.getBudgetEstimateClassname(
                      estimate,
                      estimate.date === this.props.mainDateStore.today,
                      'labour_limit',
                    )}
                  >
                    {canEditBudget ? (
                      <ScheduleBudgetTableRowInput
                        key={`${idx * estimate.location_id}`}
                        name="labour_limit"
                        estimate={estimate}
                        scheduleUIState={this.props.scheduleUIState}
                        disabled={!this.props.userPermissions.permissions.includes(BUDGET_ESTIMATES_EDIT)}
                        onChange={e => this.onEstimateChange(e, estimate, 'labour_limit')}
                        setError={this.setCellError}
                      >
                        <span
                          className={ScheduleBudgetTableRows.getActualLabourLimitClassname(
                            result > budgetEstimate.labour_limit / 100,
                          )}
                        >
                          {result} %
                        </span>
                      </ScheduleBudgetTableRowInput>
                    ) : (
                      <div>
                        {budgetEstimate.labour_limit ? budgetEstimate.labour_limit / 100 : 0}%
                        <div
                          className={ScheduleBudgetTableRows.getActualLabourLimitClassname(
                            result > budgetEstimate.labour_limit / 100,
                          )}
                        >
                          {result} %
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          )}
          {selectedOptions.includes('sale') && (
            <tr>
              {relevantBudgetEstimates.map((estimate, idx) => {
                const plannedMinutes = sumHours[idx]?.value;
                const estimated =
                  !estimate.estimated_budget || !plannedMinutes
                    ? 0
                    : roundToTwoSigDigits(estimate.estimated_budget / 100 / (plannedMinutes / 60));
                return (
                  <td key={idx} className={this.getBudgetClassname(estimate.date)}>
                    {canEditBudget ? (
                      <ScheduleBudgetTableRowInput
                        key={`${idx * estimate.location_id}`}
                        name="target_spmh"
                        estimate={estimate}
                        scheduleUIState={this.props.scheduleUIState}
                        disabled={!this.props.userPermissions.permissions.includes(BUDGET_ESTIMATES_EDIT)}
                        onChange={e => this.onEstimateChange(e, estimate, 'target_spmh')}
                        setError={this.setCellError}
                      >
                        <span> {estimated} PLN</span>
                      </ScheduleBudgetTableRowInput>
                    ) : (
                      <div>
                        {estimate.target_spmh ? estimate.target_spmh / 100 : 0} PLN
                        <div> {estimated} PLN</div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          )}
        </FeatureWrapper>
      </>
    );
  }
}

ScheduleBudgetTableRows.propTypes = {
  currentTemplate: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TEMPLATE_TYPES)),
  }),
  sumEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  sumCosts: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  sumHours: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
  relevantBudgetEstimates: PropTypes.arrayOf(PropTypes.shape({})),
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleUIState: PropTypes.shape({
    selectedDisplayMode: PropTypes.shape({
      type: PropTypes.string,
    }),
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  budgetEstimatesChange: PropTypes.func,
  showRecommendedSchedule: PropTypes.bool,
  sumRecommendedWorkedHours: PropTypes.arrayOf(PropTypes.number),
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  locationSettings: PropTypes.shape({}),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableRows;
