import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import MDComment from '@/components/common/inputs/MDComment/MDComment.jsx';
import { MDCheckbox, MDSelect, MDTimeInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import RangePicker from '@/components/common/RangePicker/RangePicker.jsx';
import { defaultAvailabilityTypes } from '@/constants/availabilityDefaultTypes.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { getInitCalendarChoice } from '@/utils/dateHelper.js';
import { inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  title: {
    id: 'availability.addModal.title',
    defaultMessage: 'Dodaj Dostępność',
  },
  select: {
    id: 'availability.addModal.select',
    defaultMessage: 'Dostępność',
  },
});

class AddAvailabilityModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected: defaultAvailabilityTypes[0],
      comment: '',
      hours: '__:__-__:__',
      errorMessage: '',
      displayRepeat: false,
      activeRepeatTab: 'period',
      selectedRange: {
        start: props.mainDateStore.dateArray[0],
        end: props.mainDateStore.dateArray[props.mainDateStore.dateArray.length - 1],
      },
      selectedChoices: getInitCalendarChoice(moment().format('YYYY-MM-DD')),
      selectedWeekdays: [],
      perWhichDay: null,
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showModal } = this.props;
    if (showModal && showModal !== prevProps.showModal) {
      const { modalObject, mainDateStore } = this.props;
      this.setState({
        selectedChoices: [moment(modalObject.date).toDate()],
        selectedRange:
          mainDateStore.dateArray[0] !== this.state.selectedRange.start
            ? {
                start: mainDateStore.dateArray[0],
                end: mainDateStore.dateArray[mainDateStore.dateArray.length - 1],
              }
            : this.state.selectedRange,
      });
    }
  }

  onSubmit() {
    const { selected } = this.state;

    if (selected.requires_time) {
      const err = inputValidation('workingHours', this.state.hours);
      if (err !== '' || selected === null) {
        this.setState({
          errorMessage: err,
        });
        return;
      }
    }

    const availabilityObject = this.createAvailabilityObject();

    if (this.state.displayRepeat) {
      const { selectedRange, selectedChoices, selectedWeekdays, perWhichDay } = this.state;
      const error = this.props.addAvailabilitiesWithRepeat(this.props.modalObject.employee.id, availabilityObject, {
        type: this.state.activeRepeatTab,
        selected: {
          selectedRange,
          selectedChoices,
          selectedWeekdays,
          perWhichDay,
        },
      });
      if (error) {
        this.setState({ errorMessage: error });
        return;
      }
    } else {
      this.props.addAvailability(availabilityObject);
    }
    this.hideAndClear();
  }

  setActiveRepeatTab(activeRepeatTab) {
    this.setState({ activeRepeatTab });
  }

  createAvailabilityObject() {
    const { selected, comment } = this.state;
    const { modalObject, company_id: companyId } = this.props;
    const { employee, date } = modalObject;

    const availabilityObject = {
      type: selected.type,
      employee: {
        id: employee.id,
      },
      date,
      company_id: companyId.id,
      type_id: selected.is_default ? null : selected.id,
    };

    if (selected.requires_time) {
      availabilityObject.hours = this.state.hours;
    }
    if (selected.optional_comment) {
      availabilityObject.optional_comment = comment;
    }
    if (selected.requires_approval) {
      availabilityObject.draft = true;
    }

    return availabilityObject;
  }

  handleInputChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  hideAndClear() {
    this.setState({
      selected: defaultAvailabilityTypes[0],
      comment: '',
      hours: '__:__-__:__',
      errorMessage: '',
      displayRepeat: false,
      activeRepeatTab: 'period',
      selectedRange: {
        start: this.props.mainDateStore.dateArray[0],
        end: this.props.mainDateStore.dateArray[this.props.mainDateStore.dateArray.length - 1],
      },
      selectedChoices: getInitCalendarChoice(moment().format('YYYY-MM-DD')),
      selectedWeekdays: [],
      perWhichDay: null,
    });
    this.props.closeAddAvailabilityModal();
  }

  validateHours(e) {
    const { value } = e.target;
    this.setState({
      errorMessage: inputValidation('workingHours', value),
    });
  }

  render() {
    const { modalObject, isEmployee, userCustomTypes } = this.props;
    let { employee } = modalObject;
    const avaTypes = isEmployee ? userCustomTypes.filter(type => type.visible_for_employees) : userCustomTypes;
    if (typeof employee === 'undefined') {
      employee = { first_name: '', last_name: '' };
    }
    const availabilityTypes = [...defaultAvailabilityTypes, ...avaTypes];

    return (
      <MDKadroModal
        show={this.props.showModal}
        onHide={this.hideAndClear}
        modifiers={['tall', 'narrow']}
        title={this.context.intl.formatMessage(messages.title, {})}
        onSubmit={this.onSubmit}
        errorMessage={this.state.errorMessage && this.context.intl.formatMessage(this.state.errorMessage, {})}
      >
        {!isEmployee ? (
          <fieldset className="form-group">
            <FormattedMessage
              id="availability.addModal.headerMessage"
              defaultMessage="Dodaj dostępność w dniu {date} dla {employeeName}"
              values={{
                date: modalObject.date,
                employeeName: `${employee.first_name} ${employee.last_name}`,
              }}
            />
          </fieldset>
        ) : null}
        <MDSelect
          id="availability"
          name={<FormattedMessage id="availability.addModal.availability" defaultMessage="Dostępność" />}
          defaultValue={this.state.selected.id}
          options={availabilityTypes.map(availabilityType => ({
            key: availabilityType.name,
            value: availabilityType.id,
          }))}
          onChange={id =>
            this.handleInputChange(
              'selected',
              availabilityTypes.find(u => u.id === id),
            )
          }
          closeOnClick
          withSearch
        />
        {this.state.selected.requires_time ? (
          <MDTimeInput
            value={this.state.hours}
            onChange={e => this.handleInputChange('hours', e.target.value)}
            onBlur={this.validateHours}
            label={<FormattedMessage id="availability.addModal.hours" defaultMessage="Godziny" />}
            modifiers={['modal']}
            short={false}
          />
        ) : null}
        {this.state.selected.optional_comment ? (
          <MDComment
            name="comment"
            label={<FormattedMessage id="availability.addModal.comment" defaultMessage="Komentarz" />}
            value={this.state.comment}
            onChange={e => this.handleInputChange('comment', e.target.value)}
          />
        ) : null}
        <div>
          <MDCheckbox
            value={this.state.displayRepeat}
            text={<FormattedMessage id="availability.addModal.displayRepeat" defaultMessage="Powtórz dostępności" />}
            onChange={() => this.handleInputChange('displayRepeat', !this.state.displayRepeat)}
            reverse
          />
        </div>
        {this.state.displayRepeat ? (
          <div>
            <fieldset className="form-group">
              <div className="small_comment under" />
            </fieldset>
            <RangePicker
              activeRepeatTab={this.state.activeRepeatTab}
              selectedRange={this.state.selectedRange}
              selectedChoices={this.state.selectedChoices}
              selectedWeekdays={this.state.selectedWeekdays}
              perWhichDay={this.state.perWhichDay}
              handleInputChange={this.handleInputChange}
            />
          </div>
        ) : null}
      </MDKadroModal>
    );
  }
}

AddAvailabilityModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddAvailabilityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    date: PropTypes.string,
  }),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  company_id: PropTypes.shape({
    id: PropTypes.string,
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  isEmployee: PropTypes.bool,
  addAvailability: PropTypes.func,
  closeAddAvailabilityModal: PropTypes.func,
  addAvailabilitiesWithRepeat: PropTypes.func,
};

export default AddAvailabilityModal;
