export const handleOneSign = hours => {
  const firstSign = hours.charAt(0);
  if (!/\d/.test(firstSign)) return '';
  const firstSignNumber = Number(firstSign);
  if (/[0, 1, 2]/.test(firstSignNumber)) return firstSign;
  return `0${firstSignNumber}`;
};

export const handleTwoSings = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (!/[\d, \-, :]/.test(lastSign)) return hoursWithoutLastSign;
  if (/\d[:]/.test(hours)) return `0${hours}`;
  if (/[0-2]-/.test(hours)) {
    return `0${hoursWithoutLastSign}:00-`;
  }
  if (/2[4-9]/.test(hours)) {
    const addAtTheEnd = /[6-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `0${hoursWithoutLastSign}:${addAtTheEnd}`;
  }
  if (/[3-9]\d/.test(hours)) {
    const addAtTheEnd = /[6-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `0${hoursWithoutLastSign}:${addAtTheEnd}`;
  }
  if (lastSign === '-') return `0${hoursWithoutLastSign}:00-`;
  return hours;
};

export const handleThreeSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if ((hours.match(/\d/g) || []).length === 3) {
    const toAdd = /[6-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `${hoursWithoutLastSign}:${toAdd}`;
  }
  if (
    !/[\d, \-, :]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(1))) ||
    hours.match(/\d/g).length === 3 ||
    (/[6-9]/.test(lastSign) && /[:]/.test(hours.charAt(1)))
  )
    return hoursWithoutLastSign;
  if (hours.includes('-') && lastSign !== '-') {
    return `${hoursWithoutLastSign}${handleOneSign(lastSign)}`;
  }
  if (lastSign === '-') return `${hoursWithoutLastSign}:00-`;
  return hours;
};

export const handleFourSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (
    !/[\d, -]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(2))) ||
    (hours.match(/-/g) || []).length === 2
  )
    return hoursWithoutLastSign;
  if (lastSign === '-' && /:/.test(hours)) return `${hoursWithoutLastSign}0-`;
  if (hours.includes('-') && lastSign !== '-') {
    return `${hoursWithoutLastSign}${handleOneSign(lastSign)}`;
  }
  if (/\d/.test(lastSign)) {
    const toAdd = /[6-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `${hoursWithoutLastSign}${toAdd}`;
  }
  return hours;
};

export const handleFiveSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  const secondlLastSign = hours.charAt(3);
  if (
    !/[\d, -]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(3))) ||
    (hours.match(/-/g) || []).length === 2
  )
    return hoursWithoutLastSign;
  if (/\d{2}-\d{2}/.test(hours)) return hours;
  if (hours.includes('-') && !/[-,:]/.test(lastSign)) {
    return `${hoursWithoutLastSign}${handleOneSign(lastSign)}`;
  }
  if (lastSign === '-') {
    const toAdd = /[6-9]/.test(secondlLastSign) ? `0${secondlLastSign}` : `${secondlLastSign}0`;
    return `${hoursWithoutLastSign.slice(0, -1)}${toAdd}-`;
  }
  return hours;
};

export const handleSixSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (
    !/[\d, \-, :]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(4))) ||
    (hours.match(/-/g) || []).length === 2 ||
    (lastSign === ':' && (hours.match(/:/g) || []).length === 2)
  )
    return hoursWithoutLastSign;
  if (hours.includes('-') && !/[-,:]/.test(lastSign)) {
    return `${hoursWithoutLastSign}${handleOneSign(lastSign)}`;
  }
  if (/\d{2}:\d{3}/.test(hours)) {
    const toAdd = /[3-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `${hoursWithoutLastSign}-${toAdd}`;
  }
  return hours;
};

export const handleSevenSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (
    !/[\d, \-, :]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(5))) ||
    (hours.match(/-/g) || []).length === 2
  )
    return hoursWithoutLastSign;
  if (hours.includes('-') && !/[-,:]/.test(lastSign)) {
    return `${hoursWithoutLastSign}${handleOneSign(lastSign)}`;
  }
  return hours;
};

export const handleEightSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  const secondlLastSign = hours.charAt(6);
  if (
    !/[\d, \-, :]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(6))) ||
    (hours.match(/-/g) || []).length === 2
  )
    return hoursWithoutLastSign;
  if (/\d/.test(lastSign)) {
    if (/[0-1]/.test(secondlLastSign)) return hours;
    if (/2/.test(secondlLastSign)) {
      const add = /[4-9]/.test(lastSign) ? `0${secondlLastSign}:` : secondlLastSign;
      return `${hoursWithoutLastSign.slice(0, -1)}${add}${lastSign}`;
    }
  }
  if (lastSign === ':') {
    return `${hoursWithoutLastSign.slice(0, -1)}0${secondlLastSign}:`;
  }
  return hours;
};

export const handleNineSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (
    !/[\d, \-, :]/.test(lastSign) ||
    (/[-,:]/.test(lastSign) && /[:, -]/.test(hours.charAt(7))) ||
    (hours.match(/-/g) || []).length === 2
  )
    return hoursWithoutLastSign;
  if (/\d{2}:\d{2}-\d{3}/.test(hours)) {
    const toAdd = /[6-9]/.test(lastSign) ? `0${lastSign}` : lastSign;
    return `${hoursWithoutLastSign}:${toAdd}`;
  }
  return hours;
};

export const handleTenSigns = hours => {
  const lastSign = hours.slice(-1);
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (!/\d{2}:\d{2}-\d{2}:\d{1}/.test(hours)) return hoursWithoutLastSign;
  if (/[6-9]/.test(lastSign)) return `${hoursWithoutLastSign}0${lastSign}`;
  return hours;
};

export const handleElevenSigns = hours => {
  const hoursWithoutLastSign = hours.slice(0, -1);
  if (!/\d{2}:\d{2}-\d{2}:\d{2}/.test(hours)) return hoursWithoutLastSign;
  return hours;
};

const handlersForSigns = {
  0: () => '',
  1: handleOneSign,
  2: handleTwoSings,
  3: handleThreeSigns,
  4: handleFourSigns,
  5: handleFiveSigns,
  6: handleSixSigns,
  7: handleSevenSigns,
  8: handleEightSigns,
  9: handleNineSigns,
  10: handleTenSigns,
  11: handleElevenSigns,
};

export const getHoursFormatting = hours => handlersForSigns[hours.length](hours);
