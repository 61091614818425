import { PropTypes } from 'prop-types';
import { Component } from 'react';

import Modal from '@/components/common/Modal/Modal.jsx';

import Regulations from './Regulations.jsx';
import RodoInfo from './RodoInfo.jsx';

class NewsModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: 0,
    };

    this.goLeft = this.goLeft.bind(this);
    this.goRight = this.goRight.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.startStep !== nextProps.startStep) {
      this.setState({ step: nextProps.startStep });
    }
  }

  goLeft() {
    this.setState({ step: this.state.step - 1 });
  }
  goRight() {
    this.setState({ step: this.state.step + 1 });
  }

  render() {
    const steps = [
      <RodoInfo />,
      <Regulations
        regulations={this.props.regulations}
        confirmRegulations={this.props.confirmRegulations}
        closeModal={this.props.onHide}
      />,
    ];
    const { step } = this.state;
    return (
      <Modal show={this.props.showModal} onHide={this.props.onHide} className="k-newsModal">
        <Modal.Header closeButton>
          <img className="k-newsModal__logo" src="/img/logo.png" alt="" />
        </Modal.Header>
        <Modal.Body enableScroll>
          {steps[step]}
          {step > 0 ? (
            <div className="k-newsModal__arrow k-newsModal__arrow--left" onClick={this.goLeft}>
              <i className="material-icons">keyboard_arrow_left</i>
            </div>
          ) : null}
          {step < steps.length - 1 ? (
            <div className="k-newsModal__arrow k-newsModal__arrow--right" onClick={this.goRight}>
              <i className="material-icons">keyboard_arrow_right</i>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}

NewsModal.contextTypes = {
  intl: PropTypes.shape({}),
};

NewsModal.propTypes = {
  showModal: PropTypes.bool,
  currentCompany: PropTypes.shape({}),
  onHide: PropTypes.func,
  startStep: PropTypes.number,
};

export default NewsModal;
