import { FormattedMessage } from 'react-intl';

import { getContactItems } from './contact.constants';
import messages from './contact.messages';
import ContactTile from './ContactTile';

import './Contact.scss';

const Contact = () => {
  const contactItems = getContactItems();

  return (
    <div className="md-dashboardContainer contact" data-test="trial.contact">
      <div className="md-dashboardContainer__title md-dashboardContainer__title--large">
        <FormattedMessage {...messages.title} />
      </div>
      <div className="md-dashboardContainer__sub-title">
        <FormattedMessage {...messages.subTitle} />
      </div>
      <div className="contact__content">
        {contactItems.map(({ buttonText, icon, info, title, onClick, key }) => (
          <ContactTile
            key={key}
            buttonText={buttonText}
            icon={icon}
            info={info}
            title={title}
            onClick={onClick}
            testId={`trial.contact.${key}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Contact;
