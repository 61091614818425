import { connect } from 'react-redux';

import { toggleSelectAttendance } from '@/actions/attendances';
import { changeLocationFilter } from '@/actions/locationFilter.js';

import AttendanceTable from './AttendanceTable';

const mapStateToProps = state => ({
  selectedEmployees: state.reducer.attendances.selected,
  attendancesSettings: state.reducer.settings.attendancesSettings,
});

const mapDispatchToProps = {
  toggleSelectAttendance,
  changeLocationFilter,
};

const AttendanceButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(AttendanceTable);

export default AttendanceButtonBarContainer;
