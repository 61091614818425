import { typesOfMultipleLocations } from '@/constants/locations';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';
import TopBarNameFilterContainer from '@/containers/TopBars/filters/TopBarNameFilterContainer.js';

import TopBarCalendar from './filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmploymentConditionsFilter from './filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarMultipleColumnFilter from './filters/TopBarMultipleColumnFilter/TopBarMultipleColumnFilter.redux';
import TopBarMultipleLocationFilter from './filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from './TopBar.jsx';

const TopBarPayrollLocation = () => (
  <TopBar
    className="k-topBar--payrollLocation"
    leftElements={[
      <TopBarCalendar key="calswitch" modes={payrollLocationModesIds} />,
      <TopBarMultipleLocationFilter key="multipleLocationFilter" locationType={typesOfMultipleLocations.id} />,
      <TopBarJobTitleFilterContainer key="jobTitleFilter" />,
      <TopBarNameFilterContainer key="nameFilter" />,
      <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
      <TopBarMultipleColumnFilter key="multipleColumnFilter" payrollType="location" />,
    ]}
    rightElements={[]}
  />
);

TopBarPayrollLocation.propTypes = {};

export default TopBarPayrollLocation;
