import PropTypes from 'prop-types';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import './videoTutorialModal.scss';

const VideoTutorialModal = ({ showModal, onHide, link, title }) => (
  <MDKadroModal
    className="k-videoTutorialModal"
    show={showModal}
    title={title}
    modifiers={['wide']}
    onHide={onHide}
    hideFooter
  >
    <iframe
      className="k-videoTutorialModal__video"
      src={link}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </MDKadroModal>
);

VideoTutorialModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  link: PropTypes.string,
  title: PropTypes.string,
};

export default VideoTutorialModal;
