import { connect } from 'react-redux';

import ScheduleTableWeatherRow from './ScheduleTableWeatherRow';

const mapStateToProps = ({ reducer }, ownProps) => ({
  dateArray: reducer.mainDateStore.dateArray,
  locationCoordinatesExist: !!reducer.settings.locationSettings[ownProps.locationId]?.location_coordinates,
  weatherForecast: reducer.weatherForecast[ownProps.locationId],
  userLocations: reducer.userLocations,
  companyRoles: reducer.roles.companyRoles,
  userRoleId: reducer.currentUser.user.role_id,
});

export default connect(mapStateToProps)(ScheduleTableWeatherRow);
