import classNames from 'classnames';
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper';

import { calculateTotalHoursAndCost } from '../../ScheduleBudgetTable.helpers.js';

const ScheduleBudgetTableHeaderTotalCell = ({
  shiftsSummary,
  dateArray,
  budgetTarget,
  locationIds,
  locationSettings,
  restrictions,
}) => {
  const { allHours, allCosts } = calculateTotalHoursAndCost(shiftsSummary || {}, dateArray);
  const showTarget =
    locationIds.length === 1 &&
    locationSettings?.enable_budget_target &&
    !restrictions.includes(RESTRICTIONS.BUDGET_TARGETS_HIDE);
  const hoursTargetClassName = classNames({
    'k-budgetTable__leftColumnDataValue--green': budgetTarget?.target_minutes >= allHours,
    'k-budgetTable__leftColumnDataValue--red': budgetTarget?.target_minutes < allHours,
  });
  const moneyTargetClassName = classNames({
    'k-budgetTable__leftColumnDataValue--green': budgetTarget?.target_money >= allCosts,
    'k-budgetTable__leftColumnDataValue--red': budgetTarget?.target_money < allCosts,
  });

  return (
    <td className="k-budgetTable__leftColumn">
      <div className="k-budgetTable__leftColumnData">
        <span className="k-budgetTable__leftColumnLabel">
          <FormattedMessage id="schedule.budget.hours" defaultMessage="Godziny" />
        </span>
        <span className="k-budgetTable__leftColumnDataValue">
          <span>{parseMinutesToHumanForm(allHours)}</span>
          {showTarget && (budgetTarget?.target_minutes || budgetTarget?.target_minutes === 0) && (
            <span className={hoursTargetClassName}>/ {parseMinutesToHumanForm(budgetTarget.target_minutes)}</span>
          )}
        </span>
      </div>
      <div className="k-budgetTable__leftColumnData">
        <span className="k-budgetTable__leftColumnLabel">
          <FormattedMessage id="schedule.budget.costs" defaultMessage="Stawki" />
        </span>
        <span className="k-budgetTable__leftColumnDataValue">
          <span>{roundToTwoSigDigits(allCosts / 100)}</span>
          {(!showTarget || !budgetTarget?.target_money) && 'PLN'}
          {showTarget && (budgetTarget?.target_money || budgetTarget?.target_money === 0) && (
            <span className={moneyTargetClassName}>/ {roundToTwoSigDigits(budgetTarget.target_money / 100)} PLN</span>
          )}
        </span>
      </div>
    </td>
  );
};

ScheduleBudgetTableHeaderTotalCell.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  shiftsSummary: PropTypes.shape({}),
  budgetTarget: PropTypes.shape({
    target_money: PropTypes.number,
    target_minutes: PropTypes.number,
  }),
  locationIds: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({
    enable_budget_target: PropTypes.bool,
  }),
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableHeaderTotalCell;
