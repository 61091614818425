import { connect } from 'react-redux';

import { redirectToPayroll } from '@/actions/payroll/payroll';
import { getPayrollLocationViewData } from '@/actions/payrollLocation/payrollLocation';

import PayrollLocationTable from './PayrollLocationTable';

const mapStateToProps = state => ({
  data: state.reducer.payrollLocation.data,
  showSkeletons: state.reducer.payrollLocation.showSkeletons,
  visibleColumns: state.reducer.payrollLocation.visibleColumns,
  timeFormatType: state.reducer.payrollSettings.timeFormatSetting.type,
  sortingUseLastName: state.reducer.uiState.sortingUseLastName,
  overtimeStats: state.reducer.overtimeStats,
  userPermissions: state.reducer.userPermissions,
  dateFilter: state.reducer.mainDateStore.customDate,
  payoutSettingType: state.reducer.payrollSettings.payoutSetting.type,
  companySettings: state.reducer.currentCompany.settings,
  userEmployees: state.reducer.userEmployees,
  selectedLocationIds: state.reducer.multipleLocationFilter,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  selectedEmploymentConditions: state.reducer.employmentConditionsFilter.selected,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  redirectToPayroll,
  getPayrollLocationViewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationTable);
