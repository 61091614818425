import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDCheckbox, MDMonthPicker, MDSelect, MDTextArea } from '@/components/common/inputs/MDComponents.jsx';
import OvertimeCollectionOptions from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddAbsenceTab/Options/OvertimeCollectionOptions/OvertimeCollectionOptions';
import { OLD_PAYROLL_VIEW } from '@/constants/Permissions';
import { SKIP_WEEKENDS_AND_HOLIDAYS_HIDE } from '@/constants/Restrictions';
import { createEvent } from '@/utils/inputHelpers.js';

import {
  checkIfCanSelectEmployee,
  getAbsenceTypeOptions,
  getAllAbsenceTypes,
  getEmployeeOptions,
} from './AddAbsenceTab.helpers.js';
import { messages } from './AddAbsenceTab.messages.js';
import { PartDayAbsenceOptions } from './Options';
import WholeDayAbsenceOptions from './Options/WholeDayAbsenceOptions/WholeDayAbsenceOptions.redux';

import './AddAbsenceTab.scss';

const AddAbsenceTab = (
  {
    absenceOmitWeekends,
    absenceOmitHolidays,
    allDay,
    absenceComment,
    absenceHours,
    absenceTypes,
    countOnlyDaysWithShifts,
    errors,
    handleInputChange,
    isOvertimeCollection,
    selectedAbsence,
    selectedAbsenceRange,
    selectedDay,
    selectedEmployeeId,
    showEmployeeSelect = false,
    userEmployees,
    userPermissions,
    employeeName,
    overtimeCycle,
    multiplier,
    amount50,
    amount100,
    amountPotential,
    availableOvertimes,
  },
  { intl },
) => {
  const { has_limit: hasLimit } = selectedAbsence;

  const allAbsenceTypes = useMemo(() => getAllAbsenceTypes(absenceTypes, userPermissions, intl), [
    absenceTypes,
    userPermissions,
  ]);
  const absenceTypeOptions = useMemo(() => getAbsenceTypeOptions(allAbsenceTypes, userPermissions.isEmployee), [
    userPermissions,
    absenceTypes,
  ]);

  const canSelectEmployee = checkIfCanSelectEmployee(userPermissions, showEmployeeSelect);
  const employeeOptions = useMemo(() => (canSelectEmployee ? getEmployeeOptions(userEmployees) : []), [userEmployees]);

  const changeEmployeeId = useCallback(id => handleInputChange(createEvent('selectedEmployeeId', id)), []);

  const changeAbsence = useCallback(
    id =>
      handleInputChange(
        createEvent(
          'selectedAbsence',
          allAbsenceTypes.find(u => u.id === id),
        ),
      ),
    [],
  );

  const isSkipWeekendsAndHolidaysRestricted = useMemo(
    () => userPermissions.restrictions.includes(SKIP_WEEKENDS_AND_HOLIDAYS_HIDE),
    [userPermissions],
  );

  const changeAllDay = useCallback(() => {
    handleInputChange(createEvent('absenceOmitHolidays', !isSkipWeekendsAndHolidaysRestricted && !allDay));
    handleInputChange(createEvent('absenceOmitWeekends', !isSkipWeekendsAndHolidaysRestricted && !allDay));
    handleInputChange(createEvent('countOnlyDaysWithShifts', !allDay));
    handleInputChange(createEvent('allDay', !allDay));
  }, [allDay]);

  const handleChange = useCallback(e => handleInputChange(createEvent(e.target.name, e.target.value)), []);

  const absenceTypeWithLimit = !hasLimit && !allDay;
  const shouldDisplayEmployeeName = employeeName && !canSelectEmployee;
  const hasOldPayrollEnabled = userPermissions.permissions.includes(OLD_PAYROLL_VIEW);
  const newOvertimeCollectionSelected = selectedAbsence?.overtimeCollection && !hasOldPayrollEnabled;

  return (
    <div className="heading--extraTopMargin">
      {shouldDisplayEmployeeName ? (
        <FormattedMessage
          id="absences.addAbsenceFor"
          defaultMessage="Dodaj urlop dla {employeeName}"
          values={{
            employeeName,
          }}
        />
      ) : null}
      {canSelectEmployee && (
        <>
          <MDSelect
            id="Employee"
            name={intl.formatMessage(messages.absenceEmployee, {})}
            defaultValue={selectedEmployeeId}
            options={employeeOptions}
            onChange={changeEmployeeId}
            withSearch
            closeOnClick
            isSorted
            disabled={isOvertimeCollection}
            errorMessage={errors.selectedEmployeeId}
          />
        </>
      )}
      <MDSelect
        id="Rodzaj"
        name={intl.formatMessage(messages.absenceSelectType, {})}
        defaultValue={selectedAbsence?.id}
        options={absenceTypeOptions}
        onChange={changeAbsence}
        withSearch
        closeOnClick
        isSorted
        disabled={isOvertimeCollection}
      />
      {newOvertimeCollectionSelected && (
        <MDMonthPicker
          onChange={handleChange}
          value={overtimeCycle}
          name="overtimeCycle"
          testId="showMonthPicker"
          label={intl.formatMessage(messages.scheduleCycle)}
        />
      )}
      {newOvertimeCollectionSelected &&
        (!availableOvertimes ||
          (!availableOvertimes.sum50 && !availableOvertimes.sum100 && !availableOvertimes.sumPotential)) && (
          <div className="addAbsenceTab__errorMessage">{intl.formatMessage(messages.noOvertimeCollections)}</div>
        )}
      {selectedAbsence.defined_in_hours && !selectedAbsence.overtimeCollection && (
        <div className="addAbsenceTab__allDayCheckbox">
          <MDCheckbox
            value={allDay}
            text={intl.formatMessage(messages.absenceCheckboxAllDay, {})}
            onChange={changeAllDay}
            reverse
          />
        </div>
      )}
      {(allDay || !selectedAbsence.defined_in_hours) && !selectedAbsence.overtimeCollection ? (
        <WholeDayAbsenceOptions
          handleInputChange={handleInputChange}
          absenceOmitWeekends={absenceOmitWeekends}
          absenceOmitHolidays={absenceOmitHolidays}
          countOnlyDaysWithShifts={countOnlyDaysWithShifts}
          selectedAbsenceRange={selectedAbsenceRange}
          isEmployee={userPermissions.isEmployee}
          absenceTypeWithLimit={absenceTypeWithLimit}
        />
      ) : (
        <PartDayAbsenceOptions
          handleInputChange={handleInputChange}
          absenceHours={absenceHours}
          selectedDay={selectedDay}
          absenceHoursError={errors.absenceHours}
        />
      )}
      {selectedEmployeeId && newOvertimeCollectionSelected && (
        <OvertimeCollectionOptions
          overtimeCycle={overtimeCycle}
          absenceHours={absenceHours}
          selectedEmployeeId={selectedEmployeeId}
          handleInputChange={handleInputChange}
          multiplier={multiplier}
          amount50={amount50}
          amount100={amount100}
          amountPotential={amountPotential}
          availableOvertimes={availableOvertimes}
        />
      )}
      {selectedAbsence.has_note && (
        <MDTextArea
          label={intl.formatMessage(messages.note)}
          placeholder={intl.formatMessage(messages.notePlaceholder, {})}
          value={absenceComment}
          onChange={handleChange}
          id="absenceComment"
          name="absenceComment"
          errorMessage={errors.absenceComment}
        />
      )}
    </div>
  );
};

AddAbsenceTab.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddAbsenceTab.propTypes = {
  absenceOmitWeekends: PropTypes.bool,
  absenceOmitHolidays: PropTypes.bool,
  allDay: PropTypes.bool,
  absenceComment: PropTypes.string,
  absenceHours: PropTypes.string,
  selectedAbsence: PropTypes.shape({
    id: PropTypes.string,
    defined_in_hours: PropTypes.bool,
    has_note: PropTypes.bool,
    overtimeCollection: PropTypes.bool,
    has_limit: PropTypes.bool,
  }),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  handleInputChange: PropTypes.func,
  errors: PropTypes.shape({
    absenceComment: PropTypes.string,
    absenceHours: PropTypes.string,
    selectedEmployeeId: PropTypes.string,
  }),
  selectedAbsenceRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  selectedDay: PropTypes.string,
  selectedEmployeeId: PropTypes.string,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
    isEmployee: PropTypes.bool,
  }),
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      employment_conditions: PropTypes.shape({
        show_absences: PropTypes.bool,
      }),
    }),
  ),
  showEmployeeSelect: PropTypes.bool,
  isOvertimeCollection: PropTypes.bool,
  countOnlyDaysWithShifts: PropTypes.bool,
  employeeName: PropTypes.string,
  overtimeCycle: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    day: PropTypes.number,
  }),
  multiplier: PropTypes.string,
  amount50: PropTypes.number,
  amount100: PropTypes.number,
  amountPotential: PropTypes.number,
  availableOvertimes: PropTypes.shape({
    sum50: PropTypes.number,
    sum100: PropTypes.number,
    sumPotential: PropTypes.number,
    totalDailyOvertime: PropTypes.number,
  }),
};

export default AddAbsenceTab;
