import { AZURE_APP_CLIENT_ID } from '@/constants/envVariables.ts';

export const MSAL_CONFIG = {
  auth: {
    clientId: AZURE_APP_CLIENT_ID,
    // clientId: import.meta.env.VITE_AZURE_APP_CLIENT_ID,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
