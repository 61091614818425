import { HoveredProvider } from '@/hooks';

import { AppVersion } from './appVersion/AppVersion.tsx';
import { HelpCenter } from './helpCenter/HelpCenter.tsx';
import { Logout } from './logout/Logout.tsx';
import { Notification } from './notification/Notification.tsx';
import { SideBarHeader } from './sideBarHeader/SideBarHeader.tsx';
import { SideBarNavItem } from './sideBarNavItem/SideBarNavItem.tsx';
import { UserDetails } from './UserDetails/UserDetails.tsx';
import { useSideBar } from './useSideBar.tsx';

import './SideBar.scss';

export const SideBar = () => {
  const { classes, closeMobileMenu, items, toggleMobileMenu } = useSideBar();

  return (
    <HoveredProvider>
      <nav className={classes.nav}>
        <SideBarHeader {...{ closeMobileMenu, toggleMobileMenu }} />
        <UserDetails />
        <ul className={classes.ul}>
          <section className={classes.section}>
            <Notification {...{ closeMobileMenu }} />
            {items.firstSectionNavs.map(nav => (
              <SideBarNavItem key={nav.id} {...{ nav, toggleMobileMenu }} />
            ))}
          </section>
          <section>
            {items.secondSectionNavs.map(nav => (
              <SideBarNavItem key={nav.id} {...{ nav, toggleMobileMenu }} />
            ))}
          </section>
          <Logout />
          <li className={classes.spacer} />
          <HelpCenter />
        </ul>
        <AppVersion />
      </nav>
    </HoveredProvider>
  );
};
