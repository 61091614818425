import { connect } from 'react-redux';

import { changeExportsHistoryData } from '@/actions/exports/exportsHistory/exportsHistory.js';

import ExportError from './ExportError';

const mapStateToProps = state => ({
  numberOfItemsPerPage: state.reducer.exportsHistory.numberOfItemsPerPage,
});

const mapDispatchToProps = {
  changeExportsHistoryData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportError);
