import classNames from 'classnames';

import { normalizeDiacritics } from '@/utils/stringHelpers';
import { getBem } from '@/utils/styles';

const bem = getBem('k-scheduleQuickPlanningNewFreeDay');

export const getClasses = (isFocus: boolean, isValid: boolean) => ({
  container: classNames(
    'k-scheduleFreeDayBlock',
    bem.block,
    isFocus && bem.modifier('focused'),
    isFocus && !isValid && bem.modifier('invalid'),
  ),
});

export const checkIsFreeDayShortcutValid = (shortcut: string, availableShortcuts: string[]) =>
  availableShortcuts.some(
    s => normalizeDiacritics(s).toLocaleLowerCase() === normalizeDiacritics(shortcut).toLocaleLowerCase(),
  );
