import PropTypes from 'prop-types';

import ScheduleBudgetTableHeaderCell from './ScheduleBudgetTableHeaderCell.redux.js';
import ScheduleBudgetTableHeaderTotalCell from './ScheduleBudgetTableHeaderTotalCell.redux.js';

const ScheduleBudgetTableHeader = ({ dateArray }) => (
  <tr>
    <ScheduleBudgetTableHeaderTotalCell />
    {dateArray.map(date => (
      <ScheduleBudgetTableHeaderCell date={date} key={date} />
    ))}
  </tr>
);

ScheduleBudgetTableHeader.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleBudgetTableHeader;
