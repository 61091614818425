import { connect } from 'react-redux';

import Component from './AvaOverlay.jsx';

const mapStateToProps = (state, ownProps) => {
  const employeeData = state.reducer.schedule.availabilityBlocks[ownProps.employeeId];
  const avaBlock = employeeData ? employeeData[ownProps.date] : null;

  return {
    avaBlock,
    availabilitiesModeOn: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
