import { defineMessages } from 'react-intl';

import * as actionTypes from '@/constants/ActionTypes.js';

import { conn } from './index';

const scheduleMessages = defineMessages({
  addSuccess: {
    id: 'recommendedSchedule.import.toastr.addSuccess',
    defaultMessage: 'Pomyślnie dodano dane dla prognozowanego grafiku',
  },
  addError: {
    id: 'recommendedSchedule.import.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas dodawania danych dla prognozowanego budżetu',
  },
  toastrSuccessTitle: {
    id: 'toastr.successTitle',
    defaultMessage: 'Sukces!',
  },
  toastrErrorTitle: {
    id: 'toastr.errorTitle',
    defaultMessage: 'Błąd!',
  },
});

export const getRecommendedScheduleSuccess = recommendedSchedule => (dispatch, getState) => {
  const jobTitles = getState().reducer.jobtitleFilter.selectedJobtitlesGrouped;
  const selectedJobTitleIds = jobTitles.map(jobTitle => jobTitle.id);

  dispatch({
    type: actionTypes.GET_RECOMMENDED_SCHEDULE_SUCCESS,
    payload: recommendedSchedule,
    selectedJobTitleIds,
  });
};

export const addRecommendedScheduleSuccess = recommendedSchedule => (dispatch, getState, intl) => {
  const jobTitles = getState().reducer.jobtitleFilter.selectedJobtitlesGrouped;
  const selectedJobTitleIds = jobTitles.map(jobTitle => jobTitle.id);

  dispatch({
    type: actionTypes.ADD_RECOMMENDED_SCHEDULE_SUCCESS,
    notification: {
      title: intl.formatMessage(scheduleMessages.toastrSuccessTitle),
      description: intl.formatMessage(scheduleMessages.addSuccess),
      type: 'success',
    },
    payload: recommendedSchedule,
    selectedJobTitleIds,
  });
};

export const addRecommendedScheduleError = messages => (dispatch, getState, intl) => ({
  type: actionTypes.ADD_RECOMMENDED_SCHEDULE_ERROR,
  notification: {
    title: intl.formatMessage(scheduleMessages.toastrErrorTitle),
    description: intl.formatMessage(scheduleMessages.addError),
    type: 'error',
  },
  payload: messages,
});

export const deleteRecommendedScheduleSuccess = (from, to, locationId) => ({
  type: actionTypes.DELETE_RECOMMENDED_SCHEDULE_SUCCESS,
  payload: {
    from,
    to,
    locationId,
  },
});

export const getRecommendedSchedule = (from, to, locationId) => dispatch => {
  conn.getRecommendedSchedule(from, to, locationId).then(result => {
    dispatch(getRecommendedScheduleSuccess(result.data));
  });
};

export const addRecommendedSchedule = recommendedSchedule => dispatch => {
  conn
    .addRecommendedSchedule(recommendedSchedule)
    .then(result => {
      dispatch(addRecommendedScheduleSuccess(result.data));
    })
    .catch(err => {
      dispatch(addRecommendedScheduleError(err));
    });
};

export const deleteRecommendedSchedule = (from, to, locationId) => dispatch => {
  conn
    .deleteRecommendedSchedule(from, to, locationId)
    .then(() => dispatch(deleteRecommendedScheduleSuccess(from, to, locationId)));
};
