import { FormattedMessage } from 'react-intl';

import { DATE_MODE_DAY } from '@/constants/dateModes';

export const getOpenShiftTitle = (dateArraySize, numberOfShiftsRemaining, jobTitle, isEmployee) => {
  if (dateArraySize === DATE_MODE_DAY && !isEmployee) {
    return `${jobTitle} (${numberOfShiftsRemaining})`;
  }
  return (
    <FormattedMessage
      id="scheduler.openShift.shiftRequired"
      defaultMessage="Liczba zmian: {shifts_remaining}"
      values={{
        shifts_remaining: numberOfShiftsRemaining,
      }}
    />
  );
};
