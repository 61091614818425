import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  currentPassword: {
    id: 'settings.password.currentPassword',
    defaultMessage: 'Aktualne hasło',
  },
  password: {
    id: 'settings.password.password',
    defaultMessage: 'Nowe hasło',
  },
  passwordConfirm: {
    id: 'settings.password.passwordConfirm',
    defaultMessage: 'Powtórz hasło',
  },
});
