import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { canEmployeeEditLocations } from '@/utils/userEmployeesHelpers';

import ScheduleTableWeatherCell from '../ScheduleTableWeatherCell/ScheduleTableWeatherCell.redux';
import WeatherRowTitle from '../ScheduleTableWeatherRowTitle/ScheduleTableWeatherRowTitle.redux';

import './scheduleTableWeatherRow.scss';

const ScheduleTableWeatherRow = ({
  dateArray,
  weatherForecast,
  locationCoordinatesExist,
  locationId,
  userLocations,
  companyRoles,
  userRoleId,
}) => {
  const location = useMemo(() => userLocations.find(userLocation => userLocation.id === locationId), [userLocations]);
  const canEditLocations = useMemo(() => canEmployeeEditLocations(companyRoles, userRoleId), [
    companyRoles,
    userRoleId,
  ]);

  return (
    <tr className="k-weatherRow">
      <WeatherRowTitle />
      {dateArray.map(date => (
        <ScheduleTableWeatherCell
          date={date}
          locationCoordinatesExist={locationCoordinatesExist}
          weatherForecast={weatherForecast?.[date]}
          city={location.city}
          locationId={locationId}
          canEditLocations={canEditLocations}
        />
      ))}
    </tr>
  );
};

ScheduleTableWeatherRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleTableWeatherRow.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationCoordinatesExist: PropTypes.bool,
  weatherForecast: PropTypes.shape({}),
  locationId: PropTypes.string,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userRoleId: PropTypes.string,
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})),
};
export default ScheduleTableWeatherRow;
