import { showConfirmModal } from '@/actions';
import { FREE_DAYS_MARKING_ENABLE } from '@/constants/Permissions';
import { EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { isEmptyArray } from '@/utils/array/array.helpers';

import { QuickPlanningStoreState } from '../schedule/quickPlanning';
import { DisplayModeEnum, selectSelectedDisplayModeType } from '../scheduleUIState';
import { AppDispatch, GetStateType } from '../types';
import { selectUserPermissions } from '../userPermissions';
import {
  createFreeDayMarking,
  createFreeDayMarkings,
  deleteFreeDayMarking,
  deleteFreeDayMarkings,
  getFreeDaysMarkings,
  updateFreeDayMarking,
  updateFreeDayMarkings,
} from './freeDaysMarking.asyncActions';
import { FreeDayItemDto, FreeDayItemPostDto } from './freeDaysMarking.types';
import { messages } from './freeDaysMarking.utils';

export const showDeleteFreeDayConfirmModal = (freeDayItemId: FreeDayItemDto['id']) => (dispatch, _getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteFreeDayTitle),
      description: intl.formatMessage(messages.deleteFreeDayDescription),
      confirmFunc: () => dispatch(deleteFreeDayMarking(freeDayItemId)),
    }),
  );
};

export const getFreeDaysMarkingsIfNecessary =
  (from: StandardDate, to: StandardDate, employeesIds: EmployeeWhole['id'][]) =>
  (dispatch: AppDispatch, getState: GetStateType) => {
    const state = getState();
    const { isEmployee, permissions } = selectUserPermissions(state);
    if (
      isEmployee ||
      !permissions.includes(FREE_DAYS_MARKING_ENABLE) ||
      selectSelectedDisplayModeType(state) !== DisplayModeEnum.SCHEDULE
    )
      return;
    dispatch(getFreeDaysMarkings({ from, to, employeesIds }));
  };

export const addFreeDays = (freeDays: FreeDayItemPostDto[]) => (dispatch: AppDispatch) => {
  if (isEmptyArray(freeDays)) return;
  if (freeDays.length === 1) dispatch(createFreeDayMarking(freeDays[0]));
  else dispatch(createFreeDayMarkings(freeDays));
};

export const editFreeDays = (freeDays: FreeDayItemDto[]) => (dispatch: AppDispatch) => {
  if (isEmptyArray(freeDays)) return;
  if (freeDays.length === 1) dispatch(updateFreeDayMarking(freeDays[0]));
  else dispatch(updateFreeDayMarkings(freeDays));
};

export const deleteFreeDays = (freeDaysIds: FreeDayItemDto['id'][]) => (dispatch: AppDispatch) => {
  const ids = [...new Set(freeDaysIds)];
  if (isEmptyArray(ids)) return;
  if (ids.length === 1) dispatch(deleteFreeDayMarking(ids[0]));
  else dispatch(deleteFreeDayMarkings(ids));
};

export const addOrEditFreeDays =
  (freeDaysQP: QuickPlanningStoreState['freeDaysSummary']['freeDaysToPaste']) => (dispatch: AppDispatch) => {
    const { newFreeDays, updatedFreeDays } = freeDaysQP.reduce(
      (p, freeDay) => {
        if (!freeDay.id) return { ...p, newFreeDays: [...p.newFreeDays, freeDay] };
        return { ...p, updatedFreeDays: [...p.updatedFreeDays, freeDay] };
      },
      { newFreeDays: [], updatedFreeDays: [] },
    );
    dispatch(addFreeDays(newFreeDays));
    dispatch(editFreeDays(updatedFreeDays));
  };
