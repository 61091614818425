import { connect } from 'react-redux';

import { createMassAttendances, refreshData } from '@/actions/attendances';
import { showModal } from '@/actions/uiState.js';

import AttendancesTable from './AttendancesTable.jsx';

const mapStateToProps = ({ reducer }) => ({
  currentUser: reducer.currentUser.user,
  companyRoles: reducer.roles.companyRoles,
  locationSettings: reducer.settings.locationSettings,
  userPermissions: reducer.userPermissions,
});

const mapDispatchToProps = {
  showModal,
  refreshData,
  createMassAttendances,
};

const AttendancesTableContainer = connect(mapStateToProps, mapDispatchToProps)(AttendancesTable);

export default AttendancesTableContainer;
