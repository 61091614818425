import { conn } from '@/actions';
import { authUserError, authUserSuccesful, getInitData, logoutUser, storeUserIdLocally } from '@/actions/auth.jsx';
import { decreaseLoaderCounter, increaseLoaderCounter } from '@/actions/uiState.js';
import { User } from '@/types';
import { getLoggedAccounts, LoggedAccount, storeLoggedAccounts } from '@/utils/accounts/accounts.utils';
import { goToResetPasswordPage } from '@/utils/baseHelpers.js';
import { intercomShutdown } from '@/utils/intercomHelpers.js';

export const addAccount = (email: string, password: string) => async (dispatch) => {
  dispatch(increaseLoaderCounter('init'));
  intercomShutdown();
  try {
    await dispatch(logoutUser(false));
    const response = await conn.authenticateClient(email, password, 'init');
    const userId: User['id'] = response.data.user_id;
    conn.setUserId(userId);
    dispatch(getInitData());
    dispatch(authUserSuccesful());
    storeUserIdLocally(userId);
    const existingAccounts = getLoggedAccounts().map((account) => ({ ...account, active: false }));
    const newAccount: LoggedAccount = {
      email,
      userId,
      active: true,
      isDefault: false,
      avatar: null,
      companyName: '',
      firstName: '',
      jobTitleNames: [],
      lastName: '',
      locationNames: [],
      roleName: '',
    };
    storeLoggedAccounts([newAccount, ...existingAccounts]);
  } catch (err) {
    dispatch(decreaseLoaderCounter('init'));
    const isPasswordExpired = err.response.status === 403 && err.response.data.passwordExpired;
    if (isPasswordExpired) {
      goToResetPasswordPage();
      return;
    }
    dispatch(authUserError({ email, password, rememberMe: true, err }));
  }
};

export const switchAccount = (userId: string) => async (dispatch) => {
  intercomShutdown();
  dispatch(increaseLoaderCounter('init'));
  try {
    await dispatch(logoutUser(false));
    const existingAccounts = getLoggedAccounts();
    const newAccounts = existingAccounts.map((account) => ({
      ...account,
      active: account.userId === userId,
    }));
    storeLoggedAccounts(newAccounts);
    conn.setUserId(userId);
    await dispatch(getInitData());
    await dispatch(authUserSuccesful());
    storeUserIdLocally(userId);
  } catch (err) {
    dispatch(decreaseLoaderCounter('init'));
  }
};
