import {
  ADD_EMPLOYMENT_CONDITION,
  CLEAR_DEMO_DATA,
  DELETE_EMPLOYMENT_CONDITION,
  GET_EMPLOYMENT_CONDITIONS_SUCCESFUL,
  MASS_DELETE_EMPLOYMENT_CONDITIONS,
  UPDATE_EMPLOYMENT_CONDITION,
} from '@/constants/ActionTypes';
import { employmentConditions as employmentConditionsConstant } from '@/constants/employmentConditions.js';

const initialState = employmentConditionsConstant;

const employmentConditions = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYMENT_CONDITIONS_SUCCESFUL: {
      const uniqueIds = new Set(state.map(condition => condition.id));
      const filteredPayload = action.payload.filter(condition => !uniqueIds.has(condition.id));
      return [...state, ...filteredPayload];
    }
    case ADD_EMPLOYMENT_CONDITION:
      return [...state, action.payload];
    case UPDATE_EMPLOYMENT_CONDITION: {
      return state.map(condition =>
        condition.id === action.payload.savedEmploymentCondition.id
          ? action.payload.savedEmploymentCondition
          : condition,
      );
    }
    case DELETE_EMPLOYMENT_CONDITION:
      return state.filter(condition => condition.id !== action.payload.deletedTemplateId);
    case MASS_DELETE_EMPLOYMENT_CONDITIONS:
      return state.filter(condition => !action.payload.includes(condition.id));
    case CLEAR_DEMO_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default employmentConditions;
