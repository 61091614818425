import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import {
  addBreak,
  assignLabelsToAttendance,
  changeAttendance,
  changeBreak,
  deleteAttendance,
  deleteBreak,
  unassignLabelsFromAttendance,
} from '@/actions/attendances.js';
import { getHistoryForAttendance } from '@/actions/history.js';
import { hideModal } from '@/actions/uiState.js';
import EditAttendanceModal from '@/components/attendance/modals/EditAttendanceModal.jsx';
import { EDIT_ATTENDANCE_MODAL } from '@/constants/modalTypes.js';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === EDIT_ATTENDANCE_MODAL,
  modalObject: state.reducer.uiState.modalObject[EDIT_ATTENDANCE_MODAL],
  demoAccount: state.reducer.demo.demoAccount,
  userLocations: state.reducer.userLocations,
  locationSettings: state.reducer.settings.locationSettings,
  labels: state.reducer.labels,
  roles: state.reducer.roles,
  currentUser: state.reducer.currentUser.user,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {
  onHide: hideModal,
  deleteAttendance,
  showConfirmModal,
  changeAttendance,
  addBreak,
  changeBreak,
  deleteBreak,
  getHistoryForAttendance,
  assignLabelsToAttendance,
  unassignLabelsFromAttendance,
};

const EditAttendanceModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditAttendanceModal);

export default EditAttendanceModalContainer;
