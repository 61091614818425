import moment from 'moment';

import { ActivityLog, ActivityLogDisplayData } from '@/types/activityLog.types.ts';
import { getChanges, getEditorName, getIcon, getMessage } from '@/utils/objectHistoryHelpers.jsx';

interface LegacyObjectHistoryProps {
  item?: Record<string, any>;
  userJobTitles?: Array<Record<string, any>>;
  userEmployees?: Array<{
    id?: string;
    first_name?: string;
    last_name?: string;
  }>;
  modalObject?: {
    history?: {
      logs?: ActivityLog[];
      displayData?: ActivityLogDisplayData;
    };
    item?: Record<string, any>;
    previousModalName?: string;
  };
}

const LegacyObjectHistory = ({ item, userJobTitles, userEmployees, modalObject }: LegacyObjectHistoryProps) => {
  let tempItem = { ...modalObject.item };
  let changes = null;
  const date = moment(item.created_at);
  [tempItem, changes] = getChanges(item, tempItem, userJobTitles, userEmployees);
  return (
    <div className="objectHistoryModal__item">
      <div className="objectHistoryModal__date">
        <div className="objectHistoryModal__hour">{date.format('HH:mm')}</div>
        <div className="objectHistoryModal__day">{date.format('D MMM')}</div>
      </div>
      <div className="objectHistoryModal__accent">
        <div className="objectHistoryModal__historyIcon">
          <span className="material-icons">{getIcon(item)}</span>
        </div>
      </div>
      <div className="objectHistoryModal__info">
        <div className="objectHistoryModal__user">{getEditorName(item, modalObject.history.displayData.employees)}</div>
        <div className="objectHistoryModal__message">{getMessage(item)}</div>
        <div className="objectHistoryModal__changes">{changes}</div>
      </div>
    </div>
  );
};

export default LegacyObjectHistory;
