import PropTypes from 'prop-types';
import { useCallback } from 'react';
import * as React from 'react';

import Toggle from '@/components/common/inputs/Toggle.jsx';
import { TOOGLE_SMALL_MODIFIERS } from '@/constants/modifiers';
import { AttendanceSetting } from '@/types';

interface ToogleSettingButton {
  setting: AttendanceSetting;
  onChange: (setting: AttendanceSetting) => void;
}

const ToggleSettingButton: React.FC<ToogleSettingButton> = ({ setting, onChange }, { intl }) => {
  const onChangeFunc = useCallback<() => void>(() => {
    onChange(setting);
  }, [onChange, setting]);

  const { id, displayText, type, value } = setting;

  return (
    <div key={id} className="k-actionsButton__element">
      <div key={id} className="k-actionsButton__element--spaceBetween">
        <div key={id} className="k-actionsButton__elementLeftLabel">
          {intl.formatMessage(displayText)}
        </div>
        <Toggle modifiers={TOOGLE_SMALL_MODIFIERS} labelId={type} value={value} onChangeFunc={onChangeFunc} />
      </div>
    </div>
  );
};

ToggleSettingButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default ToggleSettingButton;
