import { defineMessages } from 'react-intl';

import {
  CREATE_DEVICE_TUTORIAL_ID,
  CREATE_EMPLOYEE_TUTORIAL_ID,
  INVITE_EMPLOYEE_TUTORIAL_ID,
  PUBLISH_SCHEDULE_TUTORIAL_ID,
} from '@/constants/tutorialsId';

export const MILESTONES = {
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
  INVITE_EMPLOYEE: 'INVITE_EMPLOYEE',
  PUBLISH_SCHEDULE: 'PUBLISH_SCHEDULE',
  CREATE_DEVICE: 'CREATE_DEVICE',
  CREATE_ATTENDANCE: 'CREATE_ATTENDANCE',
};

export const milestoneMessages = defineMessages({
  [MILESTONES.CREATE_ATTENDANCE]: {
    id: 'milestones.createAttendance',
    defaultMessage: 'Zarejestruj pierwszą obecność',
  },
  [MILESTONES.CREATE_COMPANY]: {
    id: 'milestones.createCompany',
    defaultMessage: 'Założ konto Kadromierz',
  },
  [MILESTONES.CREATE_DEVICE]: {
    id: 'milestones.createDevice',
    defaultMessage: 'Dodaj pierwsze urządzenie rejestrujące czas pracy',
  },
  [MILESTONES.CREATE_EMPLOYEE]: {
    id: 'milestones.createEmployee',
    defaultMessage: 'Dodaj pierwszego pracownika',
  },
  [MILESTONES.INVITE_EMPLOYEE]: {
    id: 'milestones.inviteEmployee',
    defaultMessage: 'Zaproś pracownika do korzystania z systemu',
  },
  [MILESTONES.PUBLISH_SCHEDULE]: {
    id: 'milestones.publishSchedule',
    defaultMessage: 'Opublikuj pierwszy grafik pracy',
  },
});

export const trialMilestones = {
  CREATE_ATTENDANCE: {
    labelIntl: milestoneMessages[MILESTONES.CREATE_ATTENDANCE],
    helpLink: 'https://pomoc.kadromierz.pl/pl/articles/2282515-instrukcja-obslugi-kadromierz-rcp',
  },
  CREATE_COMPANY: {
    labelIntl: milestoneMessages[MILESTONES.CREATE_COMPANY],
  },
  CREATE_DEVICE: {
    labelIntl: milestoneMessages[MILESTONES.CREATE_DEVICE],
    helpLink: 'https://pomoc.kadromierz.pl/pl/articles/4167801-autoryzacja-urzadzenia-z-rcp',
    tutorialId: CREATE_DEVICE_TUTORIAL_ID,
  },
  CREATE_EMPLOYEE: {
    labelIntl: milestoneMessages[MILESTONES.CREATE_EMPLOYEE],
    helpLink: 'https://pomoc.kadromierz.pl/pl/articles/2108357-pracownicy',
    tutorialId: CREATE_EMPLOYEE_TUTORIAL_ID,
    videoLink: 'https://www.youtube.com/embed/mTjchBctOmU?start=44',
  },
  INVITE_EMPLOYEE: {
    labelIntl: milestoneMessages[MILESTONES.INVITE_EMPLOYEE],
    helpLink: 'https://pomoc.kadromierz.pl/pl/articles/2108560-pracownicy-zaproszenie-pracownika',
    tutorialId: INVITE_EMPLOYEE_TUTORIAL_ID,
  },
  PUBLISH_SCHEDULE: {
    labelIntl: milestoneMessages[MILESTONES.PUBLISH_SCHEDULE],
    helpLink: 'https://pomoc.kadromierz.pl/pl/articles/2211505-publikowanie-grafiku-pracy',
    tutorialId: PUBLISH_SCHEDULE_TUTORIAL_ID,
    videoLink: 'https://www.youtube.com/embed/g1ybarDTFeg',
  },
};
