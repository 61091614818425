import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { MDRadios } from '@/components/common/inputs/MDComponents.jsx';

import { getLoadBudgetTargetModalInitialViewOptions } from './LoadBudgetTargetModalInitialView.helpers';

const LoadBudgetTargetModalInitialView = (
  { selectedStepOption, setSelectedStepOption, scheduleLocations },
  { intl },
) => {
  const isOneLocationSelected = scheduleLocations.length === 1;
  const budgetTargetOptions = useMemo(
    () => getLoadBudgetTargetModalInitialViewOptions(intl, isOneLocationSelected),
    [],
  );
  setSelectedStepOption(selectedStepOption);

  return (
    <MDRadios
      elements={budgetTargetOptions}
      selectedItemType={selectedStepOption}
      onChangeType={setSelectedStepOption}
    />
  );
};

LoadBudgetTargetModalInitialView.contextTypes = {
  intl: PropTypes.shape({}),
};
LoadBudgetTargetModalInitialView.propTypes = {
  selectedStepOption: PropTypes.string,
  setSelectedStepOption: PropTypes.func,
  scheduleLocations: PropTypes.arrayOf(PropTypes.number),
};

export default LoadBudgetTargetModalInitialView;
