import { connect } from 'react-redux';

import EmployeeScheduleTableRowTitle from './EmployeeScheduleTableRowTitle.jsx';

const mapStateToProps = (state, ownProps) => {
  const employee = state.reducer.employees.data[ownProps.employeeId];
  const stats = state.reducer.schedule.rowStatistics[ownProps.employeeId];
  return {
    firstName: employee?.first_name,
    lastName: employee?.last_name,
    workedHoursForSelectedPeriod: stats?.workedHoursForSelectedPeriod,
    currentUserId: state.reducer.singleEmployeeFilter.id,
    userEmployees: state.reducer.userEmployees,
  };
};

const EmployeeScheduleTableRowTitleContainer = connect(mapStateToProps)(EmployeeScheduleTableRowTitle);

export default EmployeeScheduleTableRowTitleContainer;
