import PropTypes from 'prop-types';

import Button from '@/components/common/Basic/Button';
import Icon from '@/components/common/Basic/Icon/Icon';
import { MDMultiSelectFooter } from '@/components/common/inputs/MDComponents';
import Tooltip from '@/components/common/Tooltip/Tooltip';
import {
  EmplMassEditActions,
  emplMassEditActions,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import EmployeesEditMassJobTitlesField from '@/components/companymanage/employees/EditMassEmployeesModal/JobTitlesField/EmployeesEditMassJobTitlesField.redux';

import EmployeeMassEditSummaryIncorrectEmployeesText from '../EmployeeMassEditSummaryStepTexts/EmployeeMassEditSummaryIncorrectEmployeesText.tsx';
import { tooltipMessages } from './EmployeesMassEditSummaryBottomSection.messages';

interface EmployeesMassEditSummaryBottomSectionProps {
  selectedAction: EmplMassEditActions;
  copyIncorrectEmployees: () => void;
  incorrectEmployees: { label: string; value: string }[];
  selectedJobTitles?: { job_title_id: string; wage?: string }[];
}

const EmployeesMassEditSummaryBottomSection = (
  {
    selectedAction,
    copyIncorrectEmployees,
    incorrectEmployees,
    selectedJobTitles,
  }: EmployeesMassEditSummaryBottomSectionProps,
  { intl },
) => {
  const shouldDisplayIncorrectEmployees = [
    emplMassEditActions.addContract,
    emplMassEditActions.remove,
    emplMassEditActions.editContractsStartDate,
  ].includes(selectedAction);
  const copyIncorrectEmployeesNamesTooltipMessage = shouldDisplayIncorrectEmployees
    ? intl.formatMessage(tooltipMessages[selectedAction])
    : null;
  return (
    <>
      <div className="k-employeesMassEditSummaryStep__incorrectEmployeeHeader">
        <EmployeeMassEditSummaryIncorrectEmployeesText actionType={selectedAction} />
        {shouldDisplayIncorrectEmployees && (
          <Tooltip yOffset={10} content={copyIncorrectEmployeesNamesTooltipMessage}>
            <Button
              onClick={copyIncorrectEmployees}
              modifiers="reverse-orange small only-icon"
              disabled={!incorrectEmployees.length}
            >
              <Icon type="material-symbols" name="file_copy" className="k-employeesMassEditSummaryStep__fileCopyIcon" />
            </Button>
          </Tooltip>
        )}
      </div>
      <div className="k-employeesMassEditSummaryStep__correctEmployeeFooter">
        {shouldDisplayIncorrectEmployees ? (
          <MDMultiSelectFooter selectedItems={incorrectEmployees} />
        ) : (
          <EmployeesEditMassJobTitlesField selectedTerms={selectedJobTitles} showWages />
        )}
      </div>
    </>
  );
};

EmployeesMassEditSummaryBottomSection.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeesMassEditSummaryBottomSection;
