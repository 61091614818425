import {
  CHANGE_PAYMENT_DATA_SUCCESSFULL,
  CHANGE_PAYMENT_NOTIFICATION_DATA,
  GET_PAYMENT_DATA_SUCCESSFULL,
  GET_UNLOCK_PAYMENT_DATA,
  GET_UNLOCK_PAYMENT_DATA_SUCCESSFULL,
} from '@/constants/ActionTypes';

const initialState = {
  client: {},
  invoices: [],
  unlockViewData: {
    blocked: false,
    company_id: null,
    paid_to_date: null,
    invoices_to_pay: [],
  },
  notification: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_DATA_SUCCESSFULL:
      return { ...state, client: action.payload };
    case CHANGE_PAYMENT_NOTIFICATION_DATA:
      return { ...state, notification: action.payload };
    case CHANGE_PAYMENT_DATA_SUCCESSFULL:
      return { ...state, client: { ...state.client, ...action.payload } };

    case GET_UNLOCK_PAYMENT_DATA: {
      return {
        ...state,
        unlockViewData: { ...state.unlockViewData, blocked: true, clientStatus: action.clientStatus },
      };
    }
    case GET_UNLOCK_PAYMENT_DATA_SUCCESSFULL:
      return {
        ...state,
        unlockViewData: {
          ...state.unlockViewData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
export default reducer;
