import classnames from 'classnames';
import { isEqual } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';

import Popover from '../../Popover/Popover.jsx';
import MDTextInput from '../MDTextInput/MDTextInput.jsx';

import './MDMonthPicker.scss';

class MonthPicker extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      this.state = props.value;
    } else {
      const now = moment();
      this.state = { year: now.year(), month: now.month() + 1 };
    }
  }

  componentDidMount() {
    this.onChange(this.state);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value && !isEqual(value, prevProps.value)) {
      const { year, month } = value;
      this.setState({ year, month });
    }
  }

  onChange(pickedMonth) {
    this.props.onChange({
      target: { type: 'monthPicker', value: pickedMonth, name: this.props.name },
    });
  }
  changeYear(diff) {
    this.setState({ year: this.state.year + diff });
    this.onChange({ month: this.state.month, year: this.state.year + diff });
  }
  changeMonth(month) {
    this.setState({ month });
    this.onChange({ month, year: this.state.year });
  }
  render() {
    const { label, testId } = this.props;
    if (this.props.disabled) {
      return <MDTextInput label={label} modifiers={['modal', 'disabled']} disabled />;
    }
    return (
      <Popover
        position="top"
        yOffset={50}
        popoverStyle={{ padding: 0, border: 'none' }}
        content={
          <div className="md-monthPicker" data-test="monthPicker">
            <div className="md-monthPicker__row">
              <i
                role="presentation"
                className="material-icons"
                onClick={() => {
                  this.changeYear(-1);
                }}
                data-test="monthPicker-previousCycle"
              >
                keyboard_arrow_left
              </i>
              <div className="md-monthPicker__year" data-test="monthPicker-year">
                {this.state.year}
              </div>
              <i
                role="presentation"
                className="material-icons"
                onClick={() => {
                  this.changeYear(1);
                }}
                data-test="monthPicker-nextCycle"
              >
                keyboard_arrow_right
              </i>
            </div>
            {moment.monthsShort().map((month, i) => {
              const className = classnames('md-monthPicker__item', {
                'md-monthPicker__item--picked': i === this.state.month - 1,
              });
              return (
                <div
                  role="presentation"
                  className={className}
                  key={i}
                  data-test="month"
                  onClick={() => {
                    this.changeMonth(i + 1);
                  }}
                >
                  {month.toUpperCase()}
                </div>
              );
            })}
          </div>
        }
      >
        <div>
          <MDTextInput
            label={label}
            value={moment(`${this.state.year}/${this.state.month}/01`).format('MMMM YYYY')}
            modifiers={['modal']}
            testId={testId}
          />
        </div>
      </Popover>
    );
  }
}

MonthPicker.defaultProps = {
  onChange: () => {},
};
MonthPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};
export default MonthPicker;
