/* eslint-disable no-undef */
/* eslint-disable global-require */
import { defineMessages } from 'react-intl';

import { TEMPLATE_TYPES } from './scheduleDisplayModes';

// Array of languages used in react-intl
// First language in this array is treated as default
export const languages = {
  pl: 'Polski',
  en: 'English',
};

export const availabilityMessages = defineMessages({
  availability: {
    id: 'common.availabilityType.availability',
    defaultMessage: 'Dostępny',
  },
  unavailability: {
    id: 'common.availabilityType.unavailability',
    defaultMessage: 'Niedostępny',
  },
  freetime: {
    id: 'common.availabilityType.freetime',
    defaultMessage: 'Dostępny w godzinach:',
  },
  freetimeShort: {
    id: 'common.availabilityType.freetimeShort',
    defaultMessage: 'Dostępny:',
  },
});

export const kadroCalendarMessages = defineMessages({
  blank: {
    id: 'common.kadroCalendar.blank',
    defaultMessage: 'Wybierz datę',
  },
  todayLabelLong: {
    id: 'common.kadroCalendar.todayLabelLong',
    defaultMessage: 'Dzisiaj',
  },
  todayLabelShort: {
    id: 'common.kadroCalendar.todayLabelShort',
    defaultMessage: 'Dziś',
  },
});

export const tradeShiftStatus = defineMessages({
  pending: {
    id: 'tradeShift.status.pending',
    defaultMessage: 'Oczekuje',
  },
  approved: {
    id: 'tradeShift.status.approved',
    defaultMessage: 'Zaakceptowana',
  },
  rejected: {
    id: 'tradeShift.status.rejected',
    defaultMessage: 'Odrzucona',
  },
});

export const scheduleMessages = defineMessages({
  deleteSelectedShiftsConfirmationTitle: {
    id: 'schedule.deleteSelectedShifts.confirmationTitle',
    defaultMessage: 'Usuń zmiany',
  },
  deleteSelectedShiftsConfirmationDesc: {
    id: 'schedule.deleteSelectedShifts.confirmationDesc',
    defaultMessage: 'Czy na pewno chcesz usunąć zaznaczone zmiany?',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Usuń',
  },
  pasteShiftsConfirmationTitle: {
    id: 'schedule.pasteShifts.confirmationTitle',
    defaultMessage: 'Wklej',
  },
  pasteShiftsConfirmationDesc: {
    id: 'schedule.pasteShifts.confirmationDesc',
    defaultMessage: 'Czy na pewno chcesz wkleić zmiany? Wklejone zmiany mogą się nakładać z innymi',
  },
  pasteFlexConfirmationTitle: {
    id: 'schedule.pasteFlex.confirmationTitle',
    defaultMessage: 'Wklej',
  },
  pasteFlexConfirmationDesc: {
    id: 'schedule.pasteFlex.confirmationDesc',
    defaultMessage: 'Czy na pewno chcesz wkleić zmiany? Zastąpią one poprzedni grafik na dany dzień',
  },
  paste: {
    id: 'paste',
    defaultMessage: 'Wklej',
  },
  deleteAvailabilityConfirmTitle: {
    id: 'schedule.deleteAvailabilityConfirm.title',
    defaultMessage: 'Usuń dostępność',
  },
  deleteAvailabilityConfirmDesc: {
    id: 'schedule.deleteAvailabilityConfirm.description',
    defaultMessage: 'Czy na pewno chcesz usunąć dostępność?',
  },
  deleteSelectedAvailabilitiesConfirmTitle: {
    id: 'schedule.deleteSelectedAvailabilities.confirmTitle',
    defaultMessage: 'Usuń dostępności',
  },
  deleteSelectedAvailabilitiesConfirmDesc: {
    id: 'schedule.deleteSelectedAvailabilities.confirmDesc',
    defaultMessage: 'Czy na pewno chcesz usunąć zaznaczone dostępności?',
  },
  importBudget: {
    id: 'schedule.importBudget',
    defaultMessage: 'Importuj budżet',
  },
  clearView: {
    id: 'schedule.action.clearView',
    defaultMessage: 'Wyczyść bieżący widok',
  },
  clearViewModalTitle: {
    id: 'schedule.modalClearView.title',
    defaultMessage: 'Wyczyść widok',
  },
  clearViewModalDescription: {
    id: 'schedule.modalClearView.description',
    defaultMessage:
      'Klikając “Wyczyść” usuniesz grafik z całego okresu. Nie można cofnąć wprowadzonych zmian.\nCzy na pewno chcesz wyczyścić bieżący widok?',
  },
  clearAvailabilityViewModalDescription: {
    id: 'schedule.modalClearAvailabilityView.description',
    defaultMessage:
      'Klikając “Wyczyść” usuniesz dostępności z całego okresu. Nie można cofnąć wprowadzonych zmian.\nCzy na pewno chcesz wyczyścić bieżący widok?',
  },
  clearViewModalButtonText: {
    id: 'schedule.modalClearView.buttonText',
    defaultMessage: 'Wyczyść',
  },
  duplicateView: {
    id: 'schedule.action.duplicateView',
    defaultMessage: 'Duplikuj poprzedni okres',
  },
  duplicateViewModalTitle: {
    id: 'schedule.modalDuplicateView.title',
    defaultMessage: 'Duplikuj poprzedni okres',
  },
  duplicateViewModalDescription: {
    id: 'schedule.modalDuplicateView.description',
    defaultMessage:
      'Po kliknięciu zatwierdź zostanie skopiowany widok z poprzedniego okresu, natomiast obecny widok zostanie wyczyszczony!',
  },
  duplicateViewModalButtonText: {
    id: 'schedule.modalDuplicateView.buttonText',
    defaultMessage: 'Zatwierdź',
  },
  deleteUnpublishedShifts: {
    id: 'schedule.action.deleteUnpublishedShifts',
    defaultMessage: 'Usuń nieopublikowane zmiany',
  },
  deleteUnpublishedShiftsModalTitle: {
    id: 'schedule.modalDeleteUnpublishedShifts.title',
    defaultMessage: 'Usuń nieopublikowane zmiany',
  },
  deleteUnpublishedShiftsModalDescription: {
    id: 'schedule.modalDeleteUnpublishedShifts.description',
    defaultMessage: 'Chcesz usunąć nieopublikowane zmiany?',
  },
  deleteUnpublishedShiftsModalButtonText: {
    id: 'schedule.modalDeleteUnpublishedShifts.buttonText',
    defaultMessage: 'Usuń',
  },
  deleteDraftAvailabilities: {
    id: 'schedule.action.deleteDraftAvailabilities',
    defaultMessage: 'Usuń niezatwierdzone dostępności',
  },
  deleteDraftAvailabilitiesModalTitle: {
    id: 'schedule.modalDeleteDraftAvailabilities.title',
    defaultMessage: 'Usuń niezatwierdzone dostępności',
  },
  deleteDraftAvailabilitiesModalDescription: {
    id: 'schedule.modalDeleteDraftAvailabilities.description',
    defaultMessage: 'Chcesz usunąć niezatwierdzone dostępności?',
  },
  deleteDraftAvailabilitiesModalButtonText: {
    id: 'schedule.modalDeleteDraftAvailabilities.buttonText',
    defaultMessage: 'Wyczyść',
  },
  deleteDraftTemplateShifts: {
    id: 'schedule.action.deleteDraftTemplateShifts',
    defaultMessage: 'Usuń niezatwierdzone zmiany',
  },
  deleteDraftTemplateShiftsModalTitle: {
    id: 'schedule.modalDeleteDraftTemplateShifts.title',
    defaultMessage: 'Usuń niezatwierdzone zmiany',
  },
  deleteDraftTemplateShiftsModalDescription: {
    id: 'schedule.modalDeleteDraftTemplateShifts.description',
    defaultMessage: 'Chcesz usunąć niezatwierdzone zmiany?',
  },
  deleteDraftTemplateShiftsModalButtonText: {
    id: 'schedule.modalDeleteDraftTemplateShifts.buttonText',
    defaultMessage: 'Wyczyść',
  },
  exportData: {
    id: 'export.toFile',
    defaultMessage: 'Eksportuj do pliku',
  },
  generateSchedule: {
    id: 'generateSchedule',
    defaultMessage: 'Generuj grafik',
  },
  addNewTemplate: {
    id: 'schedule.action.addNewTemplate',
    defaultMessage: 'Dodaj nowy szablon',
  },
  deleteCurrentTemplate: {
    id: 'schedule.action.deleteCurrentTemplate',
    defaultMessage: 'Usuń aktywny szablon',
  },
  deleteCurrentTemplateModalTitle: {
    id: 'schedule.modalDeleteCurrentTemplate.title',
    defaultMessage: 'Usuń szablon',
  },
  deleteCurrentTemplateModalDescription: {
    id: 'schedule.modalDeleteCurrentTemplate.description',
    defaultMessage: 'Czy na pewno chcesz usunąć obecnie wybrany szablon?',
  },
  deleteCurrentTemplateModalButtonText: {
    id: 'schedule.modalDeleteCurrentTemplate.buttonText',
    defaultMessage: 'Usuń',
  },
  saveScheduleAsTemplate: {
    id: 'schedule.action.saveScheduleAsTemplate',
    defaultMessage: 'Zapisz aktualny grafik jako szablon',
  },
  importRecommendedSchedule: {
    id: 'schedule.importRecommendedSchedule',
    defaultMessage: 'Wczytaj prognozowany grafik',
  },
  importSchedule: {
    id: 'schedule.importSchedule',
    defaultMessage: 'Importuj grafik',
  },
  clearCurrentTemplate: {
    id: 'schedule.clearCurrentTemplateMessage',
    defaultMessage:
      'Klikając “Wyczyść” usuniesz wszyskie dane z szablonu. Nie można cofnąć wprowadzonych zmian.\nCzy na pewno chcesz wyczyścić bieżący szablon?',
  },
  clearCurrentTemplateConfimButton: {
    id: 'schedule.clearCurrentTemplateConfimButton',
    defaultMessage: 'Wyczyść',
  },
  addTemplateModalConfirmText: {
    id: 'templates.addModal.confirmText',
    defaultMessage: 'Dodaj',
  },
  addTemplateModalTitle: {
    id: 'templates.addModal.title',
    defaultMessage: 'Dodaj szablon',
  },
  addTemplateModalChooseTemplateName: {
    id: 'templates.addModal.templateName',
    defaultMessage: 'Rodzaj szablonu',
  },
  [TEMPLATE_TYPES.BASIC]: {
    id: 'templates.templateBasic',
    defaultMessage: 'Szablon podstawowy',
  },
  [TEMPLATE_TYPES.FLEX]: {
    id: 'templates.templateFlex',
    defaultMessage: 'Szablon elastyczny',
  },
  [`${TEMPLATE_TYPES.BASIC}Description`]: {
    id: 'templates.addModal.templateBasic.description',
    defaultMessage:
      'Preferowany dla systemu zmianowego w którym zostaną określone stałe godziny rozpoczęcia i zakończenia zmiany',
  },
  [`${TEMPLATE_TYPES.FLEX}Description`]: {
    id: 'templates.addModal.templateFlex.description',
    defaultMessage:
      'Preferowany dla firm w którym zapotrzebowanie na godziny pracy w danych przedziałach godzinowych nie jest regularne lub zależne jest od ilości paragonów, sprzedaży itp. ',
  },
  addMultipleShiftsSuccess: {
    id: 'schedule.addMultipleShiftsSuccess.title',
    defaultMessage:
      'Poprawnie dodano {amountOfAddedShifts, plural,  one {1 zmianę} =2 {2 zmiany} =3 {3 zmiany} =4 {4 zmiany} other {# zmian}}.',
  },
  addMultipleShiftsFailure: {
    id: 'schedule.addMultipleShiftsFailure.title',
    defaultMessage: 'Nie udało się dodać {amountOfNotAddedShifts, plural,  one {1 zmiany} other {# zmian}}.',
  },
  clearViewScheduleTitle: {
    id: 'schedule.clearViewSchedule.title',
    defaultMessage: 'Poprawnie wyczyszczono widok',
  },
  clearViewScheduleDescription: {
    id: 'schedule.clearViewSchedule.description',
    defaultMessage: 'Poprawnie usunięto {shiftsAmount} zmian.',
  },
});
