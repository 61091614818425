import { PublicClientApplication } from '@azure/msal-browser';
import { FormattedMessage } from 'react-intl';

import { MSAL_CONFIG } from '@/components/common/Basic/EntraIdButton/EntraIdButton.helpers.ts';

import Button from '../Button';

import './EntraIdButton.scss';

interface Props {
  loginWithEntraId: (token: string, tenantId: string) => void;
  increaseLoaderCounter: (type: string) => void;
}

const EntraIdButton = ({ loginWithEntraId, increaseLoaderCounter }: Props) => {
  const handleClick = async () => {
    increaseLoaderCounter('init');
    const myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    await myMSALObj.initialize();
    const loginRequest = {
      scopes: ['User.Read', 'email', 'openid', 'profile'],
    };
    const response = await myMSALObj.loginPopup(loginRequest);
    await loginWithEntraId(response.accessToken, response.tenantId);
  };

  return (
    <Button onClick={handleClick} className="k-entraIdLoginButton" modifiers="high uppercase orange-flip">
      <img src="/img/microsoft.png" alt="microsoft logo" className="k-entraIdLoginButton__microsoftLogo" />
      <FormattedMessage id="loginWithEntraId" defaultMessage="Zaloguj się przez Microsoft" />
    </Button>
  );
};

export default EntraIdButton;
