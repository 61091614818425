import PropTypes from 'prop-types';

import Button from '@/components/common/Basic/Button';

const ContactTile = ({ icon, info, title, buttonText, onClick, testId }, { intl }) => (
  <div className="tile" data-test={testId}>
    <div className="tile__title">
      <i className="material-icons-outlined">{icon}</i>
      {intl.formatMessage(title)}
    </div>
    <div className="tile__info">{intl.formatMessage(info)}</div>
    <div className="tile__button">
      <Button modifiers={['reverse-orange']} onClick={onClick} data-test={`${testId}.button`}>
        {intl.formatMessage(buttonText)}
      </Button>
    </div>
  </div>
);

ContactTile.contextTypes = {
  intl: PropTypes.object.isRequired,
};

ContactTile.propTypes = {
  buttonText: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  icon: PropTypes.string,
  info: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  title: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  onClick: PropTypes.func,
  testId: PropTypes.string,
};

export default ContactTile;
