import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import {
  getAbsencesCalculatedFromEmploymentConditions,
  getAbsencesForPayroll,
  getAbsencesPerDatesFromAbsences,
} from '@/utils/absenceHelpers.js';
import { getRelevantContractForDate } from '@/utils/contracts';
import { calculateDurationMinutes, parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import { getWorkedHoursFromShifts } from '@/utils/schedulerHelpers.js';

import './SidebarShiftContent.scss';

const messages = defineMessages({
  sidebarShiftContentShiftDuration: {
    id: 'sidebarShiftContent.shiftDuration',
    defaultMessage: 'Długość zmiany',
  },
  sidebarShiftContentShiftCost: {
    id: 'sidebarShiftContent.shiftCost',
    defaultMessage: 'Koszt zmiany',
  },
  sidebarShiftContentPeriodHours: {
    id: 'sidebarShiftContent.periodHours',
    defaultMessage: 'Godzin w okresie',
  },
  sidebarShiftContentPeriodCost: {
    id: 'sidebarShiftContent.periodCost',
    defaultMessage: 'Koszt w okresie',
  },
});

const calculateShiftDuration = workingHours => {
  if (workingHours.includes('_')) {
    return 0;
  }
  return calculateDurationMinutes(workingHours);
};

const SidebarShiftContent = (props, context) => {
  const { mainDateStore, showUnpaidAbsences } = props;
  const { start, end } = mainDateStore.customDate;
  const [periodCost, setPeriodCost] = useState(0);
  const [periodMinutes, setPeriodMinutes] = useState(0);
  useEffect(() => {
    if (!props.employee) return;
    const { shifts, userJobTitles, employeeContracts } = props;
    const shiftsCost = shifts.reduce((sum, shift) => {
      const jobTitle = userJobTitles.find(j => j.id === shift.job_title.id);
      if (!jobTitle) {
        return sum;
      }

      const relevantContract = getRelevantContractForDate(employeeContracts || [], shift.date);
      const contractJobTitle = relevantContract?.job_titles.find(j => j.job_title_id === jobTitle.id);
      const wage =
        contractJobTitle?.wage || contractJobTitle?.wage === 0 ? contractJobTitle.wage : jobTitle.hourly_wage;
      const shiftDuration = shift.duration || calculateDurationMinutes(shift.working_hours);

      return sum + (shiftDuration * wage) / 60 / 100;
    }, 0);

    setPeriodCost(shiftsCost);
  }, [props.shifts, props.userJobTitles, props.employeeContracts]);

  useEffect(() => {
    const relevantAbsences = getAbsencesForPayroll(
      props.absences,
      props.absenceTypes,
      mainDateStore,
      showUnpaidAbsences,
    );

    if (!props.employee) return;
    const absencesPerDate = getAbsencesPerDatesFromAbsences(relevantAbsences, mainDateStore);
    const absencesWithoutShift = getAbsencesCalculatedFromEmploymentConditions(absencesPerDate);

    const workedHoursForSelectedPeriod = getWorkedHoursFromShifts(
      props.shifts,
      props.availabilities,
      props.userCustomTypes,
      absencesWithoutShift,
      props.employee.employment_conditions,
    );

    setPeriodMinutes(workedHoursForSelectedPeriod * 60);
  }, [
    props.employee,
    props.absences,
    props.absenceTypes,
    props.shifts,
    props.availabilities,
    mainDateStore,
    props.userCustomTypes,
  ]);

  const shiftDuration = calculateShiftDuration(props.workingHours);

  const shiftCost = useMemo(() => {
    const { jobTitle, employeeContracts } = props;
    const relevantContract = getRelevantContractForDate(employeeContracts || [], props.date);
    const contractJobTitle = relevantContract?.job_titles.find(j => j.job_title_id === jobTitle.id);
    const wage =
      contractJobTitle?.wage || contractJobTitle?.wage === 0 ? contractJobTitle.wage : props.jobTitle?.hourly_wage || 0;

    return ((shiftDuration / 60) * wage) / 100;
  }, [shiftDuration, props.employeeContracts, props.jobTitle, props.date]);

  const period =
    start === end ? moment(start).format('DD.MM') : `${moment(start).format('DD.MM')} - ${moment(end).format('DD.MM')}`;

  return (
    <div className="sidebarShiftContent">
      <div className="sidebarShiftContent__element">
        <div className="sidebarShiftContent__icon">
          <span className="material-icons">update</span>
        </div>
        <div className="sidebarShiftContent__content">
          <div className="sidebarShiftContent__value">{parseMinutesToHumanForm(shiftDuration || 0)}</div>
          <div className="sidebarShiftContent__text">
            {context.intl.formatMessage(messages.sidebarShiftContentShiftDuration)}
          </div>
        </div>
      </div>
      <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
        <div className="sidebarShiftContent__element">
          <div className="sidebarShiftContent__icon">
            <span className="material-icons">person</span>
          </div>
          <div className="sidebarShiftContent__content">
            <div className="sidebarShiftContent__value">{`${Math.round((shiftCost || 0) * 100) / 100} PLN`}</div>
            <div className="sidebarShiftContent__text">
              {context.intl.formatMessage(messages.sidebarShiftContentShiftCost)}
            </div>
          </div>
        </div>
      </FeatureWrapper>
      <div className="sidebarShiftContent__element">
        <div className="sidebarShiftContent__icon">
          <span className="material-icons">date_range</span>
        </div>
        <div className="sidebarShiftContent__content">
          <div className="sidebarShiftContent__value">
            {parseMinutesToHumanForm(periodMinutes, { roundToWholeMinutes: true })}
          </div>
          <div className="sidebarShiftContent__text">
            {context.intl.formatMessage(messages.sidebarShiftContentPeriodHours)} {period}
          </div>
        </div>
      </div>
      {!props.hideBudgetSection && (
        <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
          <div className="sidebarShiftContent__element">
            <div className="sidebarShiftContent__icon">
              <span className="material-icons">euro_symbol</span>
            </div>
            <div className="sidebarShiftContent__content">
              <div className="sidebarShiftContent__value">{`${Math.round(periodCost * 100) / 100} PLN`}</div>
              <div className="sidebarShiftContent__text">
                {context.intl.formatMessage(messages.sidebarShiftContentPeriodCost)} {period}
              </div>
            </div>
          </div>
        </FeatureWrapper>
      )}
    </div>
  );
};

SidebarShiftContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SidebarShiftContent.propTypes = {
  employee: PropTypes.shape({
    employment_conditions: PropTypes.shape({}),
  }),
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  date: PropTypes.string,
  workingHours: PropTypes.string,
  jobTitle: PropTypes.shape({
    id: PropTypes.string,
    hourly_wage: PropTypes.number,
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
  availabilities: PropTypes.arrayOf(PropTypes.shape({})),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  absences: PropTypes.arrayOf(PropTypes.shape({})),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  showUnpaidAbsences: PropTypes.bool,
  hideBudgetSection: PropTypes.bool,
};

export default SidebarShiftContent;
