import moment from 'moment';

import { createFullDate } from './dateHelper';

export const getSortedEventsForWeek = userEvents =>
  userEvents
    .filter(event => {
      const today = moment().format('YYYY-MM-DD');
      const endOfPeriod = moment()
        .add(1, 'week')
        .format('YYYY-MM-DD');

      return event.date >= today && event.date <= endOfPeriod;
    })
    .sort((a, b) => {
      const timeA = a.hours.split('-')[0];
      const dateA = createFullDate(a.date, timeA).format('X');
      const timeB = b.hours.split('-')[0];
      const dateB = createFullDate(b.date, timeB).format('X');
      return dateA - dateB;
    });
