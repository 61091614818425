import { LOGIN_METHOD } from '@/constants/devices';

export const hasLoginMethod = (loginMethod, deviceLoginMethods) => {
  if (!deviceLoginMethods) return false;
  return deviceLoginMethods.includes(loginMethod);
};

export const formatLoginMethods = ({ loginMethodQr, loginMethodRfid, loginMethodPin, loginMethodNfc }, os) => {
  const loginMethods = [];
  const isNotIos = os !== 'ios';
  if (loginMethodNfc && isNotIos) loginMethods.push(LOGIN_METHOD.NFC);
  if (loginMethodPin) loginMethods.push(LOGIN_METHOD.PIN);
  if (loginMethodRfid && isNotIos) loginMethods.push(LOGIN_METHOD.RFID);
  if (loginMethodQr) loginMethods.push(LOGIN_METHOD.QR);
  return loginMethods;
};
