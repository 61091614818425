import { timesheetModesIdsForManager } from '@/constants/viewDateModes.ts';

import TopBarCalendar from '../filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBar from '../TopBar.jsx';

const TopBarTimesheet = () => (
  <TopBar leftElements={[<TopBarCalendar key="calswitch" modes={timesheetModesIdsForManager} />]} />
);

export default TopBarTimesheet;
