import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  absenceCalendarTitle: {
    id: 'absenceCalendar.title',
    defaultMessage: 'Kalendarz nieobecności',
  },
  absenceCalendarEmployeeName: {
    id: 'absenceCalendar.emplyeename',
    defaultMessage: 'Pracownik',
  },
  absenceCalendarYear: {
    id: 'absenceCalendar.year',
    defaultMessage: 'Rok',
  },
});

const AbsenceCalendarModalHeader = ({ modalObject, year }, { intl }) => (
  <div className="absenceCalendarModalHeader">
    {intl.formatMessage(messages.absenceCalendarTitle)}
    <div>
      <span className="absenceCalendarModalHeader__detail">
        {intl.formatMessage(messages.absenceCalendarEmployeeName)}
      </span>
      <span className="absenceCalendarModalHeader__value">{modalObject.employee.employeeFullName}</span>
    </div>
    <div>
      <span className="absenceCalendarModalHeader__detail">{intl.formatMessage(messages.absenceCalendarYear)}</span>
      <span className="absenceCalendarModalHeader__value">{year}</span>
    </div>
  </div>
);

AbsenceCalendarModalHeader.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceCalendarModalHeader.propTypes = {
  modalObject: PropTypes.shape({
    employee: PropTypes.shape({
      employeeFullName: PropTypes.string,
    }),
  }),
  year: PropTypes.number,
};
export default AbsenceCalendarModalHeader;
