import { OPEN_SHIFTS_TEMPLATE_ID } from '@/constants/scheduleDisplayModes';
import { getDiff } from '@/utils/dateHelper';

export const autoSchedulerOptionNames = {
  show_forecast_schedule_for_basic_template: 'show_forecast_schedule_for_basic_template',
  omit_holidays: 'omit_holidays',
  daily_overtime: 'daily_overtime',
  daily_rest: 'daily_rest',
  max_monthly_hours: 'max_monthly_hours',
  min_working_days_in_row: 'min_working_days_in_row',
  max_working_days_in_row: 'max_working_days_in_row',
  include_current_shifts: 'include_current_shifts',
  include_supplementary_employees: 'include_supplementary_employees',
  free_sunday: 'free_sunday',
  availability_without_entered: 'availability_without_entered',
  fair_working_hours_distribution: 'fair_working_hours_distribution',
};

export const showOption = templatesArray => {
  const openShiftTemplate = templatesArray.find(({ id }) => id === OPEN_SHIFTS_TEMPLATE_ID);

  if (openShiftTemplate) {
    const { name, id } = openShiftTemplate;
    return [
      {
        key: name,
        value: id,
      },
    ];
  }
  return templatesArray.map(({ name, id }) => ({
    key: name,
    value: id,
  }));
};

export const validateOptionChange = (allOptions, selectedOption, value, mainDateStore) => {
  if (selectedOption.name === autoSchedulerOptionNames.max_working_days_in_row) {
    const minDaysInRowRule = allOptions.find(
      option => option.name === autoSchedulerOptionNames.min_working_days_in_row,
    );
    const maxDaysAvailable = getMaxDaysInRowAvailable(mainDateStore);
    if (Number(maxDaysAvailable) < Number(value)) return false;
    return !(
      minDaysInRowRule &&
      minDaysInRowRule.enabled &&
      Number(minDaysInRowRule.value) > Number(value) &&
      Number(selectedOption.value) === Number(minDaysInRowRule.value) &&
      Number(value) >= maxDaysAvailable
    );
  }
  if (selectedOption.name === autoSchedulerOptionNames.min_working_days_in_row) {
    const maxMinDaysAvailable = getMaxDaysInRowAvailable(mainDateStore);
    if (Number(maxMinDaysAvailable) < Number(value)) return false;
    const maxDaysInRowRule = allOptions.find(
      option => option.name === autoSchedulerOptionNames.max_working_days_in_row,
    );

    return !(maxDaysInRowRule && maxDaysInRowRule.enabled && Number(maxDaysInRowRule.value) < Number(value));
  }
  return true;
};

export const getMaxDaysInRowAvailable = mainDateStore =>
  getDiff(mainDateStore.customDate.start, mainDateStore.customDate.end, 'days') + 1;
