import classNames from 'classnames';
import { PropTypes } from 'prop-types';

import MDInputTooltip from '../MDInputTooltip/MDInputTooltip.jsx';

const MDCheckbox = ({
  className,
  reverse,
  errorMessage,
  disabled,
  value,
  onChange,
  style,
  id,
  defaultValue,
  text,
  tooltip,
  hoverLabel,
  testId,
}) => {
  const checkboxClassName = classNames('kmd-checkbox', className, {
    'kmd-checkbox--reverse': reverse,
    'kmd-checkbox--error': errorMessage,
    'kmd-checkbox--disabled': disabled,
  });

  return (
    <div className={checkboxClassName} data-test={testId}>
      <label className="kmd-checkbox__label">
        <input
          type="checkbox"
          checked={value}
          onChange={event => {
            if (!disabled) {
              onChange(event);
            }
          }}
          style={style}
          className="kmd-checkbox__input"
          id={id}
          name={id}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        <span className="kmd-checkbox__text">{text}</span>
      </label>
      <div className="kmd-checkbox__error">{errorMessage}</div>
      {hoverLabel && <span className="kmd-checkbox__hoverLabel">{hoverLabel}</span>}
      <MDInputTooltip content={tooltip} />
      <div className="kmd-checkbox__error">{errorMessage}</div>
    </div>
  );
};

MDCheckbox.defaultProps = {
  style: {},
  id: '',
  value: '',
};

MDCheckbox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  style: PropTypes.shape({}),
  id: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  hoverLabel: PropTypes.string,
  testId: PropTypes.string,
};

export default MDCheckbox;
