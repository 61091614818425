import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { Portal } from 'react-portal';

import Body from './ModalBody.jsx';
import Footer from './ModalFooter.jsx';
import Header from './ModalHeader.jsx';
import Title from './ModalTitle.jsx';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      animate: true,
    };
    this.hide = this.hide.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    if (props.show) {
      setTimeout(() => {
        this.setState({ animate: false });
      }, 10);
    }
  }

  getChildContext() {
    return { onHide: this.props.onHide };
  }
  onKeyUp(e) {
    if (e.key === 'Escape') this.hide();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show) {
      document.addEventListener('keyup', this.onKeyUp);
      this.setState({ animate: true, show: true });
      setTimeout(() => {
        this.setState({ animate: false });
      }, 10);
    } else if (!nextProps.show && this.props.show) {
      this.setState({ animate: true });
      setTimeout(() => {
        this.setState({ animate: false, show: false });
      }, 200);
    }
  }
  hide() {
    if (!this.props.onHide || this.props.disableCancel) return;
    this.setState({ animate: true });
    setTimeout(() => {
      this.props.onHide();
    }, 200);
    document.removeEventListener('keyup', this.onKeyUp);
  }
  render() {
    if (!this.state.show) return null;
    const modalClassNames = classnames(this.props.className, {
      kmodal__wrapper: !this.state.animate,
      'kmodal__wrapper--entering': this.state.animate,
    });
    const kmodalClassNames = classnames('kmodal', {
      'kmodal--vertical': this.props.verticalCenter,
    });
    return (
      <Portal isOpened>
        <div className={modalClassNames}>
          <div className="modalShadow--visible " onClick={this.hide} role="presentation" />
          <div className={kmodalClassNames}>{this.props.children}</div>
        </div>
      </Portal>
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  onHide: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  disableCancel: PropTypes.bool,
};
Modal.childContextTypes = {
  onHide: PropTypes.func,
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Title = Title;
export default Modal;
