/* eslint-disable camelcase */
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import { defineMessages } from 'react-intl';

import * as actionTypes from '@/constants/ActionTypes.js';

import { conn } from './index';
import { getBudgetTargets } from './schedule/budget.js';

const importMessages = defineMessages({
  budgetEstimateErrorTitle: {
    id: 'budgetEstimate.editError.title',
    defaultMessage: 'Błąd!',
  },
  budgetEstimateErrorDescription: {
    id: 'budgetEstimate.editError.description',
    defaultMessage: 'Nie udało się zmienić budżetu',
  },
  toastrSuccessTitle: {
    id: 'toastr.successTitle',
    defaultMessage: 'Sukces!',
  },
  toastrErrorTitle: {
    id: 'toastr.errorTitle',
    defaultMessage: 'Błąd!',
  },
  addSuccessInfo: {
    id: 'budgetEstimates.import.toastr.addSuccess',
    defaultMessage: 'Pomyślnie dodano dane do budżetu',
  },
  addError: {
    id: 'budgetEstimates.import.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas dodawania danych do budżetu',
  },
  budgetTargetsErrorDescription: {
    id: 'budgetTargets.editError.description',
    defaultMessage: 'Nie udało się zmienić targetów budżetów',
  },
  budgetTargetsSuccessDescription: {
    id: 'budgetTargets.editSuccess.description',
    defaultMessage: 'Pomyślnie zmodyfikowano targety budżetów',
  },
});

export const editBudgetEstimatesSuccess = estimate => ({
  type: actionTypes.EDIT_BUDGET_ESTIMATES_SUCCESS,
  payload: estimate,
});

export const editBudgetEstimatesError = (estimate, notify = true) => (dispatch, getState, intl) => {
  let notification;
  if (notify) {
    notification = {
      title: intl.formatMessage(importMessages.budgetEstimateErrorTitle, {}),
      description: intl.formatMessage(importMessages.budgetEstimateErrorDescription, {}),
      type: 'error',
    };
  }
  dispatch({
    type: actionTypes.EDIT_BUDGET_ESTIMATES_ERROR,
    payload: estimate,
    notification,
  });
};

export const getBudgetEstimatesSuccess = estimates => ({
  type: actionTypes.GET_BUDGET_ESTIMATES_SUCCESS,
  payload: estimates,
});

export const budgetEstimatesChange = (estimate, changeObject) => dispatch => {
  const { date, location_id } = changeObject;
  const fields = ['estimated_budget', 'actual_budget', 'target_spmh', 'labour_limit'];
  const newData = { date, location_id, period: 'day' };
  fields.forEach(field => {
    if (changeObject[field] !== undefined) newData[field] = [changeObject[field]];
  });

  dispatch(editBudgetEstimatesSuccess(newData));
  conn.upsertBudgetEstimates(newData).catch(() => {
    dispatch(editBudgetEstimatesError(estimate));
  });
};

export const editBudgetTargetsSuccess = budgetTargets => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_BUDGET_TARGETS_SUCCESS,
    payload: budgetTargets,
    notification: {
      title: intl.formatMessage(importMessages.toastrSuccessTitle),
      description: intl.formatMessage(importMessages.budgetTargetsSuccessDescription),
      type: 'success',
    },
  });
};

export const editBudgetTargetsError = budgetTargets => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_BUDGET_TARGETS_ERROR,
    payload: budgetTargets,
    notification: {
      title: intl.formatMessage(importMessages.toastrErrorTitle),
      description: intl.formatMessage(importMessages.budgetTargetsErrorDescription),
      type: 'error',
    },
  });
};

export const editBudgetTargets = budgetTargets => async (dispatch, getState) => {
  const { location_id, from, to } = budgetTargets;
  const fields = ['location_id', 'target_minutes', 'target_money', 'from', 'to'];
  const { reducer } = getState();
  const locationSettings = reducer.settings.locationSettings[location_id];
  const { restrictions } = reducer.userPermissions;
  const shouldGetTarget =
    locationSettings.enable_budget_target && !restrictions.includes(RESTRICTIONS.BUDGET_TARGETS_HIDE);

  const newBudgetTargets = fields.reduce(
    (agg, field) => {
      if (budgetTargets[field] !== null && budgetTargets[field] !== '')
        return { ...agg, [field]: budgetTargets[field] };
      return agg;
    },
    { location_id },
  );

  conn
    .editBudgetTargets({ data: [newBudgetTargets] })
    .then(() => {
      dispatch(editBudgetTargetsSuccess({ data: [newBudgetTargets] }));
      if (shouldGetTarget) dispatch(getBudgetTargets(from, to, location_id));
    })
    .catch(() => {
      dispatch(editBudgetTargetsError({ data: [budgetTargets] }));
    });
};

export const addMultipleBudgetEstimatesSuccess = estimates => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_MULTIPLE_ESTIMATES_SUCCESS,
    notification: {
      title: intl.formatMessage(importMessages.toastrSuccessTitle),
      description: intl.formatMessage(importMessages.addSuccessInfo),
      type: 'success',
    },
    payload: estimates,
  });
};

export const addMultipleBudgetEstimatesError = messages => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_MULTIPLE_ESTIMATES_ERROR,
    notification: {
      title: intl.formatMessage(importMessages.toastrErrorTitle),
      description: intl.formatMessage(importMessages.addError),
      type: 'error',
    },
    payload: messages,
  });
};

export const addMultipleBudgetEstimates = estimates => async dispatch => {
  await conn
    .addMultipleBudgetEstimates(estimates)
    .then(result => {
      dispatch(addMultipleBudgetEstimatesSuccess(result.data));
    })
    .catch(err => {
      dispatch(addMultipleBudgetEstimatesError(err));
    });
};

export const getBudgetEstimates = (from, to, locationIds) => dispatch => {
  if (locationIds.length === 0) {
    dispatch(getBudgetEstimatesSuccess([]));
  } else {
    conn.getBudgetEstimates(from, to, locationIds).then(result => {
      dispatch(getBudgetEstimatesSuccess(result.data));
    });
  }
};
