/* eslint no-nested-ternary:0 */
import { PropTypes } from 'prop-types';

const LeftColumn = props => {
  const topFillRows = [];
  const bottomFillRows = [];
  const h = 54;
  let c = 0;
  for (let i = props.topFill; i >= h; i -= h) {
    topFillRows.push(c);
    c++;
  }
  const lastTop = props.topFill - c * h;
  c = 0;
  for (let i = props.bottomFill; i >= h; i -= h) {
    bottomFillRows.push(c);
    c++;
  }
  const lastBottom = props.bottomFill - c * h;
  return (
    <div
      ref={props.getRef}
      className={`kadroGrid__leftColumn ${props.className || ''}`}
      style={Object.assign({}, { maxHeight: props.height }, props.style)}
    >
      <table>
        <tbody>
          <tr key={-1} className="row--loading" style={{ height: `${lastTop}px` }} />
          {topFillRows.map(row => (
            <tr key={row} className="row--loading" style={{ height: '54px' }}>
              <td />
            </tr>
          ))}
        </tbody>
        <tbody ref={props.getTBodyRef}>
          {props.children
            ? props.children.constructor === Array
              ? props.children.slice(props.start, props.end)
              : props.children
            : null}
        </tbody>
        <tbody>
          {bottomFillRows.map(row => (
            <tr className="row--loading" key={row} style={{ height: '54px' }}>
              <td />
            </tr>
          ))}
          <tr className="row--loading" key={-1} style={{ height: `${lastBottom}px` }} />
        </tbody>
      </table>
    </div>
  );
};
LeftColumn.propTypes = {
  style: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  topFill: PropTypes.number,
  bottomFill: PropTypes.number,
  start: PropTypes.number,
  end: PropTypes.number,
  getRef: PropTypes.func,
  getTBodyRef: PropTypes.func,
  height: PropTypes.number,
};
export default LeftColumn;
