import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import EventsProposalsItem from './EventsProposalsItem.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  showModal,
};

const AbsencesProposalContainer = connect(mapStateToProps, mapDispatchToProps)(EventsProposalsItem);

export default AbsencesProposalContainer;
