import { connect } from 'react-redux';

import { mainDateChangeMode, mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { changeProductionQuotas } from '@/actions/userProductionQuotas.js';
import ProductionQuotasTableView from '@/components/productionQuotas/ProductionQuotasTableView.jsx';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
  mainDateStore: state.reducer.mainDateStore,
  userProductionQuotas: state.reducer.userProductionQuotas,
  locationFilter: state.reducer.locationFilter,
  userEmployees: state.reducer.userEmployees,
  settings: state.reducer.settings,
  attendancesData: state.reducer.attendances.attendancesData.attendances,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  changeProductionQuotas,
  mainDateChangeMode,
};

const ProductionQuotasTableViewContainer = connect(mapStateToProps, mapDispatchToProps)(ProductionQuotasTableView);

export default ProductionQuotasTableViewContainer;
