import { connect } from 'react-redux';

import { addAvailability } from '@/actions';
import { addAvailabilitiesWithRepeat } from '@/actions/availability.js';
import { closeAddAvailabilityModal } from '@/actions/uiState.js';
import AddAvailabilityModal from '@/components/availability/modals/AddAvailabilityModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showAddAvailabilityModal,
  modalObject: state.reducer.uiState.addAvailabilityModalObject,
  userCustomTypes: state.reducer.userCustomTypes,
  company_id: state.reducer.currentCompany,
  mainDateStore: state.reducer.mainDateStore,
  isEmployee: state.reducer.currentUser.user.role === 'employee',
});

const mapDispatchToProps = {
  closeAddAvailabilityModal,
  addAvailability,
  addAvailabilitiesWithRepeat,
};

const AddAvailabilityModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddAvailabilityModal);

export default AddAvailabilityModalContainer;
