import cs from 'classnames';
import PropTypes from 'prop-types';

import { DIRECTIONS } from '@/constants/ui.ts';

import Popover from '../../Popover/Popover.jsx';

import './MDInputTooltip.scss';

const MDInputTooltip = ({ className, content, icon = 'help', offset, position, popoverStyle = {} }) => {
  if (!content) {
    return null;
  }

  const classNames = cs('mdInputTooltip', {
    [className]: className,
  });

  return (
    <div className={classNames}>
      <Popover
        content={content}
        yOffset={offset || 10}
        popoverStyle={{ fontSize: '12px', maxWidth: '300px', ...popoverStyle }}
        position={position || DIRECTIONS.TOP}
        className="mdInputTooltip__content"
        showShadow={false}
        centerOnMobile={false}
        hideOnBlur
        isDark
        noArrow
        showOnHover
        preventPopoverHide
      >
        <span className="material-icons mdTooltip__icon">{icon}</span>
      </Popover>
    </div>
  );
};

MDInputTooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  position: PropTypes.string,
  offset: PropTypes.number,
  popoverStyle: PropTypes.object,
};

export default MDInputTooltip;
