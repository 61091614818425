import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { MDTooltip } from '@/components/common/inputs/MDComponents.jsx';
import SearchBox from '@/components/common/inputs/SearchBox/SearchBox.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import TopBarTemplatesFilter from '@/components/TopBars/filters/TopBarTemplatesFilter/TopBarTemplatesFilter.redux.js';
import { AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE, WORKING_RULES_HIDE } from '@/constants/Restrictions.js';
import {
  AVAILABILITIES_DISPLAY_MODE_TYPE,
  POSITIONS_DISPLAY_MODE_TYPE,
  SCHEDULE_DISPLAY_MODE_TYPE,
  TEMPLATES_DISPLAY_MODE_TYPE,
} from '@/constants/scheduleDisplayModes.js';

import ScheduleActionsButton from '../ScheduleActionsButton/ScheduleActionsButton.redux.js';
import ScheduleSettingsButton from '../ScheduleSettingsButton/ScheduleSettingsButton.redux.js';

class ScheduleButtonBar extends PureComponent {
  hasSelectedShifts(onlyFromTheSameLocation = false) {
    const { shifts, scheduleLocationFilter, selectedRows, selectedColumns, selectedRowsLocationsIds } = this.props;
    if (selectedRows.length) {
      return selectedRows.some(employeeId =>
        Object.keys(shifts[employeeId]?.shifts).some(
          shiftId =>
            !onlyFromTheSameLocation ||
            shifts[employeeId]?.shifts[shiftId].location.id === selectedRowsLocationsIds[employeeId],
        ),
      );
    }

    if (selectedColumns.length) {
      const allShifts = Object.values(shifts).flatMap(row => Object.values(row.shifts));
      const shiftsFromProperLocation = allShifts.filter(shift => scheduleLocationFilter.includes(shift.location.id));

      return onlyFromTheSameLocation
        ? shiftsFromProperLocation.some(shift => selectedColumns.includes(shift.date))
        : allShifts.some(shift => selectedColumns.includes(shift.date));
    }

    return false;
  }

  hasSelectedOpenShifts() {
    const { uiState, openShifts } = this.props;

    return openShifts.some(openShift => openShift.location.id === uiState.selectedOpenShiftRowLocationId);
  }

  isEditingScheduleDisabled() {
    return this.props.userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);
  }

  isWorkingRulesActive() {
    return this.props.workingRulesEnabled;
  }

  isDeselectEnabled() {
    return this.props.selectedDisplayModeType === AVAILABILITIES_DISPLAY_MODE_TYPE;
  }

  isCopyEnabled() {
    return this.props.selectedDisplayModeType === AVAILABILITIES_DISPLAY_MODE_TYPE && this.hasSelectedShifts();
  }

  isDeleteEnabled() {
    return (
      this.props.selectedDisplayModeType === AVAILABILITIES_DISPLAY_MODE_TYPE &&
      (this.hasSelectedShifts(true) || this.hasSelectedOpenShifts())
    );
  }

  isPasteEnabled() {
    return this.props.selectedDisplayModeType === AVAILABILITIES_DISPLAY_MODE_TYPE;
  }

  isSchedule() {
    return this.props.selectedDisplayModeType === SCHEDULE_DISPLAY_MODE_TYPE;
  }

  isPositionsOrTemplates() {
    return [POSITIONS_DISPLAY_MODE_TYPE, TEMPLATES_DISPLAY_MODE_TYPE].includes(this.props.selectedDisplayModeType);
  }

  isSearchBoxDisabled() {
    return ![AVAILABILITIES_DISPLAY_MODE_TYPE, SCHEDULE_DISPLAY_MODE_TYPE].includes(this.props.selectedDisplayModeType);
  }

  isPositionDisplayMode() {
    return this.props.selectedDisplayMode.type === POSITIONS_DISPLAY_MODE_TYPE;
  }

  render() {
    const { props } = this;
    const enableDeselect = this.isDeselectEnabled();
    const enabledCopy = this.isCopyEnabled();
    const enableDelete = this.isDeleteEnabled();
    const enabledPaste = this.isPasteEnabled();
    const selectedLocationSettings = props.locationSettings[props.scheduleLocationFilter[0]];
    const shiftEditDisabledUntil = selectedLocationSettings
      ? selectedLocationSettings.disable_location_schedule_shifts_edit_until
      : null;
    const isShiftEditDisabled = moment(shiftEditDisabledUntil).isSameOrAfter(
      props.mainDateStore.dateArray[props.mainDateStore.dateArray.length - 1],
    );
    const areWorkingRulesActive = this.isWorkingRulesActive();

    return (
      <ButtonBar>
        <ScheduleActionsButton />
        <ScheduleSettingsButton scheduleSettings={props.scheduleSettings} />
        <SearchBox
          hide={this.isPositionsOrTemplates()}
          searchString={props.searchString}
          changeSearchString={props.changeScheduleSearchString}
          isDisabled={this.isSearchBoxDisabled()}
        />
        <TopBarTemplatesFilter />
        {!this.isPositionDisplayMode() && (
          <>
            <FeatureWrapper hide={this.isEditingScheduleDisabled() && !this.isTempleteScheduleViewSelected()}>
              <Button
                onClick={() => props.deleteSelected()}
                modifiers="reverse-red small"
                disabled={
                  !(
                    enableDelete &&
                    props.deleteOptions.canDelete &&
                    (props.selected || props.uiState.selectedOpenShiftRowLocationId)
                  )
                }
              >
                <i className="material-icons">delete</i>
                <FormattedMessage id="delete" defaultMessage="Usuń" />
              </Button>
            </FeatureWrapper>
            <FeatureWrapper hide={this.isEditingScheduleDisabled() && !this.isTempleteScheduleViewSelected()}>
              <Button
                onClick={() => props.copy()}
                modifiers="reverse-blue small"
                disabled={!(enabledCopy && props.selected)}
              >
                <i className="material-icons">content_copy</i>
                <FormattedMessage id="copy" defaultMessage="Kopiuj" />
              </Button>
            </FeatureWrapper>
            <FeatureWrapper hide={this.isEditingScheduleDisabled() && !this.isTempleteScheduleViewSelected()}>
              <Button
                onClick={() => props.paste()}
                modifiers="reverse-orange small"
                disabled={!(enabledPaste && props.copyPaste.canPaste)}
              >
                <i className="material-icons">content_paste</i>
                <FormattedMessage id="paste" defaultMessage="Wklej" />
              </Button>
            </FeatureWrapper>
            <FeatureWrapper hide={this.isEditingScheduleDisabled() && !this.isTempleteScheduleViewSelected()}>
              <Button
                onClick={() => props.deselectAllEmployeesRows()}
                modifiers="reverse-blue small"
                disabled={!((enableDeselect && props.selected) || props.uiState.selectedOpenShiftRowLocationId)}
              >
                <i className="material-icons">content_paste</i>
                <FormattedMessage id="unmark" defaultMessage="Odznacz" />
              </Button>
            </FeatureWrapper>
          </>
        )}
        {!this.isPositionsOrTemplates() &&
        props.employeeSorting.currentSorting.type === 'custom' &&
        props.uiState.rearrangingEmployeesOrder ? (
          <Button onClick={() => props.changeEmployeesOrderForLocation()} modifiers="blue small">
            <i className="material-icons">sort</i>
            <FormattedMessage id="saveSorting" defaultMessage="Zapisz" />
          </Button>
        ) : null}
        {!this.isPositionsOrTemplates() &&
        props.employeeSorting.currentSorting.type === 'custom' &&
        !props.uiState.rearrangingEmployeesOrder ? (
          <Button onClick={() => props.startCustomSorting()} modifiers="blue small floatright">
            <i className="material-icons">sort</i>
            <FormattedMessage id="startSorting" defaultMessage="Sortuj" />
          </Button>
        ) : null}
        {areWorkingRulesActive && this.isSchedule() && (
          <FeatureWrapper restrcition={WORKING_RULES_HIDE} hide={!this.isSchedule()}>
            <Button
              modifiers="blue small pushLeft"
              disabled={!props.isVerifyWorkingRulesButtonActive}
              onClick={() => props.getWorkingRulesViolationsForCurrentDateArrayWithToastr()}
            >
              <Icon type="material" name="verified" />
              <FormattedMessage id="checkWorkingRules" defaultMessage="Sprawdź kodeks" />
            </Button>
          </FeatureWrapper>
        )}
        <FeatureWrapper
          restrictions={[SCHEDULE_EDIT_DISABLE, AUTO_GENERATE_STEPS_MODAL]}
          hide={!this.isSchedule() || ['day'].includes(props.mainDateStore.dateMode)}
        >
          <MDTooltip
            text={
              props.scheduleLocationFilter.length !== 1 ? (
                <FormattedMessage
                  id="autoGenerate.disabled.description"
                  defaultMessage="Aby wygenerować grafik wybierz jedną lokalizację"
                />
              ) : null
            }
            withoutIcon
          >
            <Button
              onClick={() => props.showModal(AUTO_GENERATE_STEPS_MODAL)}
              modifiers={classnames('orange', 'small', { pushLeft: !areWorkingRulesActive })}
              disabled={props.scheduleLocationFilter.length !== 1 || isShiftEditDisabled}
            >
              <span role="img" aria-label="rocket" className="kButton__emoji--rocket">
                🚀
              </span>
              <FormattedMessage id="autoGenerate" defaultMessage="Generuj grafik">
                {text => <span style={{ verticalAlign: 'middle' }}>{text}</span>}
              </FormattedMessage>
            </Button>
          </MDTooltip>
        </FeatureWrapper>
      </ButtonBar>
    );
  }
}

ScheduleButtonBar.propTypes = {
  searchString: PropTypes.string,
  selected: PropTypes.bool,
  selectedDisplayModeType: PropTypes.string,
  workingRulesEnabled: PropTypes.bool,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  selectedRows: PropTypes.arrayOf(PropTypes.string),
  selectedRowsLocationsIds: PropTypes.shape({}),
  copyPaste: PropTypes.shape({
    canPaste: PropTypes.bool,
  }),
  uiState: PropTypes.shape({
    selectedOpenShiftRowLocationId: PropTypes.string,
    rearrangingEmployeesOrder: PropTypes.bool,
  }),
  employeeSorting: PropTypes.shape({
    currentSorting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  deleteOptions: PropTypes.shape({
    canDelete: PropTypes.bool,
  }),
  locationSettings: PropTypes.shape({}),
  deleteSelected: PropTypes.func,
  copy: PropTypes.func,
  paste: PropTypes.func,
  deselectAllEmployeesRows: PropTypes.func,
  changeEmployeesOrderForLocation: PropTypes.func,
  startCustomSorting: PropTypes.func,
  getWorkingRulesViolationsForCurrentDateArrayWithToastr: PropTypes.func,
  showModal: PropTypes.func,
  isVerifyWorkingRulesButtonActive: PropTypes.bool,
  shifts: PropTypes.shape({}),
  openShifts: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  changeScheduleSearchString: PropTypes.func,
  scheduleSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDisplayMode: PropTypes.shape({ type: PropTypes.string }),
};

export default ScheduleButtonBar;
