import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { parseMinutesToFormat } from '@/utils/dateHelper.js';
import {
  combineDetailsIntoRows,
  filterAttendances,
  filterAvailabilities,
  filterShifts,
  getRelevantAttendancesDetails,
  getRelevantAvailabilitiesDetails,
  getRelevantShiftsDetails,
} from '@/utils/payrollHelpers';

import EmployeePayrollTableRow from './EmployeePayrollTableRow.jsx';

const colgroup = (
  <colgroup>
    <col style={{ width: '20%' }} />
    <col style={{ width: '14%' }} />
    <col style={{ width: '6%' }} />
    <col style={{ width: '6%' }} />
    <col style={{ width: '14%' }} />
    <col style={{ width: '10%' }} />
  </colgroup>
);

const renderRows = (relevantRows, userLocations) =>
  !relevantRows.length ? (
    <tr>
      <td style={{ padding: 0 }} colSpan="6">
        <EmptyState
          name="attendanceCTA"
          imgSrc="/img/attendanceViewCTA.png"
          emptyStateText={
            <FormattedMessage
              id="employeePayroll.emptyFilterMsg"
              defaultMessage="Brak obecności w danym okresie"
            />
          }
          ctaText=""
        />
      </td>
    </tr>
  ) : (
    relevantRows.map((row) => (
      <EmployeePayrollTableRow
        key={row.humanDate}
        data={row}
        userLocations={userLocations}
        colgroup={colgroup}
      />
    ))
  );

class EmployeePayrollTable extends Component {
  constructor(props) {
    super(props);
    this.scrollSummary = this.scrollSummary.bind(this);
    this.width = 900;
  }

  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
    this.props.mainDateChangeMode('week');
  }

  scrollSummary(scroll) {
    this.summary.scrollLeft = scroll * (this.width / this.summary.offsetWidth);
  }

  render() {
    const {
      payrollSettings,
      currentUser,
      multipleLocationFilter,
      mainDateStore,
      userLocations,
      userEmployees,
    } = this.props;
    const employee = userEmployees.find((e) => e.id === currentUser.user.id);

    let relevantDetails, relevantShiftDetails;
    const relevantShifts = filterShifts(
      employee.shifts,
      mainDateStore,
      multipleLocationFilter
    ).sort((a, b) => (a.date > b.date ? 1 : -1));
    // Check the payroll settings to know what to render
    if (payrollSettings.payoutSetting.type === 'shifts') {
      // We render shifts -> no bonuses, no attendances

      relevantDetails = getRelevantShiftsDetails(
        relevantShifts,
        employee,
        payrollSettings
      );
    } else {
      // Here we only render attendances with matching shifts. Display attendances without matching shift as empty?
      const relevantAtts = filterAttendances(
        employee.attendances,
        mainDateStore,
        multipleLocationFilter
      ).sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
      relevantDetails = getRelevantAttendancesDetails(
        relevantAtts,
        employee,
        payrollSettings,
        {
          skipUnfinishedBreaks: true,
        }
      );
      relevantShiftDetails = getRelevantShiftsDetails(
        relevantShifts,
        employee,
        payrollSettings
      );
    }

    const relevantAvailabilities = filterAvailabilities(
      employee.availability_blocks,
      mainDateStore
    ).filter((a) => !a.draft);
    const relevantAvailabilitiesDetails = getRelevantAvailabilitiesDetails(
      relevantAvailabilities,
      false
    );
    const relevantRows = combineDetailsIntoRows(
      relevantDetails,
      relevantShiftDetails,
      relevantAvailabilitiesDetails,
      [],
      mainDateStore.dateArray,
      employee.employment_conditions
    );

    const renderedRows = renderRows(relevantRows, userLocations);
    const sumHours = parseMinutesToFormat(
      relevantRows.map((r) => r.timeWorked).reduce((a, b) => a + b, 0)
    );
    return (
      <div className="k-wrapper animated fadeInRight">
        <div className="row">
          <div className="col-lg-12">
            <div id="tab-1" className="tab-pane active">
              <div className="panel-nopad panel-blank">
                <Grid
                  scrollCallback={this.scrollSummary}
                  columnGroup={colgroup}
                  height={9999999}
                  width={this.width}
                  columnStyle={{ width: '20vw', minWidth: '250px' }}
                >
                  <Header>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage
                            id="employeePayroll.day"
                            defaultMessage="Dzień"
                          />
                        </th>
                        <th className="stanow">
                          <FormattedMessage
                            id="employeePayroll.location"
                            defaultMessage="Lokalizacja"
                          />
                        </th>
                        <th className="">
                          <FormattedMessage
                            id="employeePayroll.from"
                            defaultMessage="Od"
                          />
                        </th>
                        <th className="">
                          <FormattedMessage
                            id="employeePayroll.to"
                            defaultMessage="Do"
                          />
                        </th>
                        <th className="stanow">
                          <FormattedMessage
                            id="employeePayroll.jobTitle"
                            defaultMessage="Stanowisko"
                          />
                        </th>
                        <th className="sumgodz">
                          <FormattedMessage
                            id="employeePayroll.sumOfHours"
                            defaultMessage="Suma godzin"
                          />
                        </th>
                      </tr>
                    </thead>
                  </Header>
                  <Rows className="table payroll">{renderedRows}</Rows>
                  <div
                    ref={(ref) => {
                      this.summary = ref;
                    }}
                    style={{
                      overflow: 'hidden',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    <table
                      className="table payroll-location"
                      style={{ minWidth: '902px', tableLayout: 'fixed' }}
                    >
                      {colgroup}
                      <tbody>
                        <tr key="summaryRow" className="summaryrow">
                          <td className="sum_blank" colSpan="5" />
                          <td>
                            <FormattedMessage
                              id="employeePayroll.sumOfHours"
                              defaultMessage="Suma godzin"
                            />
                          </td>
                        </tr>
                        <tr key="summaryRowBottom">
                          <td className="sum_blank" colSpan="5" />
                          <td className="imptnt">{sumHours}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmployeePayrollTable.propTypes = {
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  mainDateChangeMode: PropTypes.func,
  payrollSettings: PropTypes.shape({}),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({}),
  }),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  mainDateStore: PropTypes.shape({}),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeePayrollTable;
