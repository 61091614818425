import { connect } from 'react-redux';

import { massEditContracts, massEditEmployees } from '@/actions/companymanage';
import { hideModal, showModal } from '@/actions/uiState';
import { EDIT_MASS_EMPLOYEES_MODAL } from '@/constants/modalTypes';

import EmployeesEditMassModal from './EmployeesEditMassModal.jsx';

const mapStateToProps = (state) => ({
  showModal: state.reducer.uiState.showModal === EDIT_MASS_EMPLOYEES_MODAL,
  modalObject: state.reducer.uiState.modalObject[EDIT_MASS_EMPLOYEES_MODAL],
  selectedEmployeesIds: state.reducer.listsUi.employees.selected,
  employees: state.reducer.employees.data,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  onHide: hideModal,
  massEditEmployees,
  showAdditionalModal: showModal,
  massEditContracts,
};

const EmployeesEditMassModalContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeesEditMassModal);

export default EmployeesEditMassModalContainer;
