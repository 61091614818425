import { connect } from 'react-redux';

import { quickPlanningAddNewShifts,setWorkingHoursForNewShift } from '@/actions/schedule/quickPlanning';

import ScheduleQuickPlanningNewShift from './ScheduleQuickPlanningNewShift';

const mapStateToProps = (state, ownProps) => {
  const { selected, workingHours, position } = state.reducer.schedule.quickPlanning;
  const { borderCell } = position;
  const isSelectedItem =
    workingHours.length &&
    (selected[ownProps.employeeId]?.[ownProps.date] ||
      (borderCell.employeeId === ownProps.employeeId && borderCell.date === ownProps.date));

  return {
    isSelectedItem,
    isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
    workingHours,
    isFocused: borderCell.employeeId === ownProps.employeeId && borderCell.date === ownProps.date,
  };
};

const mapDispatchToProps = {
  setWorkingHoursForNewShift,
  quickPlanningAddNewShifts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleQuickPlanningNewShift);
