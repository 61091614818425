import {
  ADD_NEW_AVA_TYPE,
  CLEAR_DEMO_DATA,
  DELETE_AVA_TYPE,
  DELETE_AVA_TYPE_SET,
  GET_AVA_TYPES_SUCCESFUL,
  UPDATE_AVA_TYPE,
} from '@/constants/ActionTypes';

const initialState = [];

const userCustomTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_AVA_TYPES_SUCCESFUL:
      return action.payload.map(c => Object.assign({}, c, { type: 'custom' }));
    case ADD_NEW_AVA_TYPE:
      return [...state, action.payload].sort((a, b) => (a.id < b.id ? 1 : -1));
    case DELETE_AVA_TYPE:
      return state.filter(type => type.id !== action.payload);
    case DELETE_AVA_TYPE_SET:
      return state.filter(a => !action.payload.includes(a.id));
    case UPDATE_AVA_TYPE: {
      const newState = state.slice(0);
      const avaType = action.payload;
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].id === avaType.id) {
          newState[i] = avaType;
        }
      }
      return newState;
    }
    case CLEAR_DEMO_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
export default userCustomTypes;
