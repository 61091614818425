import { connect } from 'react-redux';

import { changePassword } from '@/actions/settings';

import ChangePasswordBlock from './ChangePasswordBlock.jsx';

const mapDispatchToProps = {
  changePassword,
};

export default connect(null, mapDispatchToProps)(ChangePasswordBlock);
