import { connect } from 'react-redux';

import { getAbsenceLimitsForEmployee,getAbsenceLimitsUsage } from '@/actions/absences.js';
import { mainDateChangeMode } from '@/actions/mainDate.js';

import AbsencesLimitsView from './AbsencesLimitsView.jsx';

const mapStateToProps = state => ({
  selectedAbsence: state.reducer.absenceFilter.selectedAbsence,
  mainDateStore: state.reducer.mainDateStore,
  absenceLimitsUsage: state.reducer.absences.absenceLimitsUsage,
  absenceLimitsForEmployee: state.reducer.absences.absenceLimitsForEmployee,
  absenceLimitsUsageTable: state.reducer.absences.absenceLimitsUsageTable,
  userEmployees: state.reducer.userEmployees,
  absencesTypes: state.reducer.absences.absencesTypes,
  userId: state.reducer.currentUser.user.id,
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  getAbsenceLimitsUsage,
  getAbsenceLimitsForEmployee,
  mainDateChangeMode,
};

const AbsencesLimitsViewContainer = connect(mapStateToProps, mapDispatchToProps)(AbsencesLimitsView);

export default AbsencesLimitsViewContainer;
