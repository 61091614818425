import { messages } from './NewChatModal.messages';

export const modalModifiers = ['narrow'];

export const getMultiSelectEmployeesMessages = intl => ({
  headerTitle: intl.formatMessage(messages.headerTitle),
  selectAll: intl.formatMessage(messages.selectAll),
  deselectAll: intl.formatMessage(messages.deselectAll),
  bodyLabel: intl.formatMessage(messages.bodyLabel),
});

export const getEmployeesOptions = chatUsers =>
  chatUsers.map(user => ({
    label: `${user.first_name} ${user.last_name}`,
    value: user.id,
    active: false,
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
    isOwner: user.isOwner,
  }));
