import PropTypes from 'prop-types';
import { memo } from 'react';

import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import SelectBlock from '@/components/common/inputs/SelectBlock/SelectBlock';
import { FREEMIUM_EVENT_PASSWORD_REFRESH } from '@/constants/intercom';
import { FREEMIUM_DISABLE_PASSWORD_REFRESH } from '@/constants/Restrictions';

import { getPasswordRefreshRules } from './PasswordSettings.helpers';
import { messages } from './PasswordSettings.messages';

import './PasswordSettings.scss';

export const PasswordSettings = (props, context) => {
  const { passwordRuleInterval, handleInputChange, restrictions } = props;

  const passwordRefreshRules = getPasswordRefreshRules(context);
  const passwordRuleId = passwordRefreshRules.find(({ value }) => value === passwordRuleInterval).id;

  const setPasswordRefreshRule = eventRule => {
    const passwordRefreshRule = passwordRefreshRules.find(rule => rule.id === eventRule);
    handleInputChange({
      target: { name: 'passwordRuleInterval', value: passwordRefreshRule.value },
    });
  };

  return (
    <FreemiumWrapper
      freemiumRestrictions={[FREEMIUM_DISABLE_PASSWORD_REFRESH]}
      intercomEvent={FREEMIUM_EVENT_PASSWORD_REFRESH}
    >
      <div className="passwordSettings">
        <h2 className="k-panel__title">
          {context.intl.formatMessage(messages.passwordPolicy)}
          {restrictions.includes(FREEMIUM_DISABLE_PASSWORD_REFRESH) && <FreemiumStar />}
        </h2>
        <fieldset className="form-group">
          <SelectBlock
            name={context.intl.formatMessage(messages.passwordRefreshTitle)}
            label={context.intl.formatMessage(messages.passwordRefreshDescription)}
            options={getPasswordRefreshRules(context)}
            value={passwordRuleId}
            handleInput={setPasswordRefreshRule}
          />
        </fieldset>
      </div>
    </FreemiumWrapper>
  );
};

PasswordSettings.contextTypes = {
  intl: PropTypes.shape({}),
};

PasswordSettings.propTypes = {
  handleInputChange: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default memo(PasswordSettings);
