import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import {
  CALCULATE_SPMH_BASED_BONUS,
  ENOVA_INTEGRATION,
  PRODUCTION_QUOTAS_GET,
} from '@/constants/Permissions.js';
import { BUDGET_INFO_HIDE } from '@/constants/Restrictions.js';
import ExportModal from '@/containers/payroll/ExportPayrollModalContainer.js';
import PayrollSettingsModal from '@/containers/payroll/PayrollSettingsModalContainer.js';
import { getPayrollData } from '@/utils/payrollHelpers.jsx';
import { stripEmployeeForTransit } from '@/utils/userEmployeesHelpers.js';

import PayrollEditShiftsAndAttendancesModal from './PayrollEditShiftsAndAttendancesModal/PayrollEditShiftsAndAttendancesModal.redux';
import HelpModal from './PayrollHelpModal.jsx';
import PayrollTable from './PayrollTable.jsx';
import PayrollTableRow from './PayrollTableRow.jsx';
import PayrollTableSummaryRow from './PayrollTableSummaryRow.jsx';

const messages = defineMessages({
  emptyStateFilterText: {
    id: 'attendance.payrollTableView.emptyFilterMsg',
    defaultMessage:
      'Wybierz pracowników, lokalizacje oraz stanowiska dla których zostanie wyświetlone zestawienie.',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.payrollTableView.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
});

class PayrollTableView extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.renderRows = this.renderRows.bind(this);
    this.getOvertime = this.getOvertime.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }

  componentDidUpdate(prevProps) {
    const { mainDateStore } = this.props;
    if (mainDateStore.dateArray[0] !== prevProps.mainDateStore.dateArray[0]) {
      this.props.hideRedundantOvertimeStats(mainDateStore.dateArray[0]);
    }
  }

  getOvertime(relevantEmployees) {
    const { mainDateStore } = this.props;
    const from = mainDateStore.dateArray[0];
    const to = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    this.props.getAllOvertimeData(
      relevantEmployees.map((i) => i.employee.id),
      from,
      to
    );
  }

  renderRows(
    relevantRows,
    userLocations,
    visibleColumns,
    employee,
    multipleLocationFilter
  ) {
    return !relevantRows.length || !multipleLocationFilter.length ? (
      <tr>
        <td style={{ padding: 0 }} colSpan={visibleColumns.length}>
          <EmptyState
            name="payrollCTA"
            imgSrc="/img/payrollCTA.png"
            emptyStateHeader={this.context.intl.formatMessage(
              messages.emptyStateFilterTitle,
              {}
            )}
            emptyStateText={this.context.intl.formatMessage(
              messages.emptyStateFilterText,
              {}
            )}
            ctaText=""
          />
        </td>
      </tr>
    ) : (
      relevantRows.map((row) => (
        <PayrollTableRow
          employee={employee}
          showModal={this.props.showModal}
          visibleColumns={visibleColumns}
          key={row.humanDate}
          data={row}
          userLocations={userLocations}
          payrollSettings={this.props.payrollSettings}
          userPermissions={this.props.userPermissions}
        />
      ))
    );
  }

  render() {
    const {
      payrollSettings,
      singleEmployeeFilter,
      multipleLocationFilter,
      mainDateStore,
      userLocations,
      userEmployees,
      userCustomTypes,
      overtimeCollections,
      absences,
      visibleColumns,
      absenceTypes,
      calculateSpmhBasedBonuses,
      exportEnovaAttendances,
      exportEnovaScheduleWork,
      showUnpaidAbsences,
    } = this.props;
    const filteredColumns = this.props.userPermissions.permissions.includes(
      PRODUCTION_QUOTAS_GET
    )
      ? visibleColumns
      : visibleColumns.filter((column) => column.id !== 12);
    const selectedColumns = [...filteredColumns, { id: 0, columns: [0] }];
    const visibleColumnsIndexes = selectedColumns.reduce(
      (result, option) => [...result, ...option.columns],
      []
    );
    const employee =
      userEmployees.find((e) => e.id === singleEmployeeFilter.id) || {};
    const disabledEditOverlayStyle = { display: 'none' };

    const {
      relevantRows,
      sumBonuses,
      sumHours,
      sumHoursReal,
      sumHoursPlanned,
      sumPlannedDiff,
      sumNightHours,
      sumPayout,
      sumQuotas,
      sumBreaks,
      sumOvertime50,
      sumOvertime100,
      sumedScheduleCycleOvertime,
      sumAbsences,
    } = getPayrollData(
      employee,
      payrollSettings,
      mainDateStore,
      multipleLocationFilter,
      userCustomTypes,
      {
        addAvailabilityTimestamps: true,
        showUnpaidAbsences,
      },
      overtimeCollections,
      absences,
      absenceTypes
    );
    const renderedRows = this.renderRows(
      relevantRows,
      userLocations,
      visibleColumnsIndexes,
      employee,
      multipleLocationFilter
    );
    // All the data necessary to properly create a PDF or PNG
    const exportRawData = () => ({
      type: 'payroll',
      employee: stripEmployeeForTransit(employee),
      relevantRows,
      sumHours,
      sumNightHours,
      sumBonuses,
      sumHoursReal,
      sumHoursPlanned,
      sumPlannedDiff,
      sumBreaks,
      sumPayout,
      sumOvertime50,
      sumOvertime100,
      sumedScheduleCycleOvertime,
      sumAbsences,
    });

    return (
      <div className="k-wrapper animated fadeInRight">
        <HelpModal />
        <PayrollSettingsModal />
        <PayrollEditShiftsAndAttendancesModal />
        <ButtonBar>
          <ActionsButton icon={<Icon name="more_vert" />}>
            <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
              <button
                className="k-actionsButton__element"
                onClick={this.props.toggleExportModal}
                disabled={renderedRows.length < 1}
              >
                <FormattedMessage
                  id="export.toFile"
                  defaultMessage="Eksportuj do pliku"
                />
              </button>
            </FeatureWrapper>
            <FeatureWrapper permission={ENOVA_INTEGRATION}>
              <button
                className="k-actionsButton__element"
                onClick={() => exportEnovaAttendances([employee.id])}
              >
                <FormattedMessage
                  id="export.enovaAttendances"
                  defaultMessage="Eksportuj czas pracy"
                />
              </button>
            </FeatureWrapper>
            <FeatureWrapper permission={ENOVA_INTEGRATION}>
              <button
                className="k-actionsButton__element"
                onClick={() => exportEnovaScheduleWork([employee.id])}
              >
                <FormattedMessage
                  id="export.enovaSchedule"
                  defaultMessage="Eksportuj grafik pracy"
                />
              </button>
            </FeatureWrapper>
            <button
              className="k-actionsButton__element"
              onClick={() =>
                this.getOvertime(
                  relevantRows.map((row) => ({
                    employee,
                    relevantEmployeeRows: row,
                  }))
                )
              }
            >
              <FormattedMessage
                id="payroll.calculateOvertimeSummary"
                defaultMessage="Oblicz nadgodziny"
              />
            </button>
            <FeatureWrapper permission={CALCULATE_SPMH_BASED_BONUS}>
              <button
                className="k-actionsButton__element"
                onClick={() =>
                  calculateSpmhBasedBonuses([singleEmployeeFilter.id])
                }
                disabled={renderedRows.length < 1}
              >
                <FormattedMessage
                  id="payroll.calculateSpmhBasedBonuses"
                  defaultMessage="Oblicz bonusy"
                />
              </button>
            </FeatureWrapper>
            <button
              className="k-actionsButton__element"
              onClick={this.props.togglePayrollSettingsModal}
            >
              <FormattedMessage id="settings" defaultMessage="Ustawienia" />
            </button>
          </ActionsButton>
        </ButtonBar>
        <ExportModal exportRawData={exportRawData} />
        <PayrollTable
          visibleColumns={visibleColumnsIndexes}
          payrollSettings={payrollSettings}
          disabledEditOverlayStyle={disabledEditOverlayStyle}
        >
          {renderedRows}
          <PayrollTableSummaryRow
            visibleColumns={visibleColumnsIndexes}
            sumHours={sumHours}
            sumHoursReal={sumHoursReal}
            sumHoursPlanned={sumHoursPlanned}
            sumPlannedDiff={sumPlannedDiff}
            sumNightHours={sumNightHours}
            sumBonuses={sumBonuses}
            sumPayout={sumPayout}
            sumQuotas={sumQuotas}
            sumBreaks={sumBreaks}
            sumOvertime50={sumOvertime50}
            sumOvertime100={sumOvertime100}
            sumedScheduleCycleOvertime={sumedScheduleCycleOvertime}
            sumAbsences={sumAbsences}
          />
        </PayrollTable>
      </div>
    );
  }
}

PayrollTableView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollTableView.propTypes = {
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  toggleExportModal: PropTypes.func,
  togglePayrollSettingsModal: PropTypes.func,
  payrollSettings: PropTypes.shape({}),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  singleEmployeeFilter: PropTypes.shape({
    id: PropTypes.string,
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({})),
  getAllOvertimeData: PropTypes.func,
  showModal: PropTypes.func,
  overtimeCollections: PropTypes.shape({}),
  absences: PropTypes.shape({}),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  calculateSpmhBasedBonuses: PropTypes.func,
  hideRedundantOvertimeStats: PropTypes.func,
  exportEnovaAttendances: PropTypes.func,
  exportEnovaScheduleWork: PropTypes.func,
  showUnpaidAbsences: PropTypes.bool,
};

export default PayrollTableView;
