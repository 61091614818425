import { connect } from 'react-redux';

import { toggleHelpModal } from '@/actions/uiState.js';
import HelpModal from '@/components/common/HelpModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showHelpModal,
});

const mapDispatchToProps = {
  onHide: toggleHelpModal,
};

const HelpModalContainer = connect(mapStateToProps, mapDispatchToProps)(HelpModal);

export default HelpModalContainer;
