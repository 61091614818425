import { commonDatePages, viewDateModes } from '@/constants/viewDateModes';
import { UiStateStoreState } from '@/redux-store/uiState';
import { DateModeEnum, MainDateStore } from '@/types/mainDateStore';

export const getDefaultCommonDatePages = (newMainDateStore: MainDateStore): UiStateStoreState['defaultDateStores'] =>
  commonDatePages.reduce((prev, pagePath) => {
    const viewDateMode = viewDateModes[pagePath];
    if (!viewDateMode || !viewDateMode.allowed.includes(newMainDateStore.dateMode)) return prev;
    const isCustomDateMode = newMainDateStore.dateMode === DateModeEnum.CUSTOM;
    if (!isCustomDateMode && viewDateMode.allowed.includes(newMainDateStore.dateMode))
      return { ...prev, [pagePath]: newMainDateStore };
    if (viewDateMode.maxDayDiff && viewDateMode.maxDayDiff < newMainDateStore.dateArray.length) return prev;
    return { ...prev, [pagePath]: newMainDateStore };
  }, {});

export const formatDefaultViewDates = (
  path: string,
  newMainDateStore: MainDateStore,
  defaultDateStores: UiStateStoreState['defaultDateStores'],
) => {
  if (!commonDatePages.includes(path))
    return {
      ...defaultDateStores,
      [path]: newMainDateStore,
    };
  return {
    ...defaultDateStores,
    ...getDefaultCommonDatePages(newMainDateStore),
  };
};
