 import { getInitPayrollColumns, getInitPayrollLocationColumns } from '@/actions/uiState.js';
 import {
  CHANGE_ABSENCES_TABLE_DATA,
  CHANGE_ALL_DEFAULT_VIEW_DATE_STORES,
  CHANGE_CUSTOM_SORTING_SUCCESS,
  CHANGE_DEFAULT_VIEW_DATE_STORE,
  CHANGE_EMPLOYEE_SORTING,
  CHANGE_PAYROLL_COLUMNS,
  CHANGE_PAYROLL_LOCATION_COLUMNS,
  CHANGE_SEND_PANEL_VISIBILITY,
  CHANGE_SORTING_NAME_TYPE,
  CLEAR_MODAL_OBJECT,
  CLOSE_ADD_ATTENDANCE_MODAL,
  CLOSE_ADD_AVAILABILITY_MODAL,
  CLOSE_APPLY_FOR_OPEN_SHIFT_MODAL,
  CLOSE_APPROVAL_TRADE_SHIFT_MODAL,
  CLOSE_ASSIGN_USERS_OPEN_SHIFT_MODAL,
  CLOSE_COWORKER_TRADE_SHIFT_MODAL,
  CLOSE_EDIT_AVAILABILITY_MODAL,
  CLOSE_NOTIFICATION_PANEL,
  CLOSE_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL,
  CLOSE_OPEN_SHIFT_MODAL,
  CLOSE_REQUEST_TRADE_SHIFT_MODAL,
  DESELECT_ALL_SCHEDULE,
  HIDE_EXPORT_WIDGET,
  HIDE_EXPORT_WIDGET_OPEN_BUTTON,
  HIDE_MODAL,
  HIDE_MODAL_WITHOUT_CLEARING_MODAL_OBJECT,
  HIDE_SHADOW,
  HIDE_TRIAL_INFO_BAR,
  OFFLINE_MESSAGE_OFF,
  OFFLINE_MESSAGE_ON,
  REPLACE_MODAL,
  SET_AUTOSCHEDULER_LOCATION,
  SET_CURRENT_NAVBAR_ELEMENT_ID,
  SET_EMPLOYEES_VISIBLE_COLUMNS,
  SET_FILTERS_SUCCESS,
  SHOW_ADD_ATTENDANCE_MODAL,
  SHOW_ADD_AVAILABILITY_MODAL,
  SHOW_APPLY_FOR_OPEN_SHIFT_MODAL,
  SHOW_APPROVAL_TRADE_SHIFT_MODAL,
  SHOW_ASSIGN_USERS_OPEN_SHIFT_MODAL,
  SHOW_COWORKER_TRADE_SHIFT_MODAL,
  SHOW_EDIT_AVAILABILITY_MODAL,
  SHOW_EXPORT_WIDGET,
  SHOW_EXPORT_WIDGET_OPEN_BUTTON,
  SHOW_MODAL,
  SHOW_NOTIFICATION_PANEL,
  SHOW_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL,
  SHOW_OPEN_SHIFT_MODAL,
  SHOW_REQUEST_TRADE_SHIFT_MODAL,
  SHOW_SHADOW,
  START_CUSTOM_SORTING,
  TOGGLE_EXPORT_MODAL,
  TOGGLE_HELP_MODAL,
  TOGGLE_IMPORT_OPEN_SHIFTS_MODAL,
  TOGGLE_MAIN_MODAL,
  TOGGLE_MODAL,
  TOGGLE_OPEN_SHIFT_ROW_SELECT,
  TOOGLE_AUTO_GENERATE_SCHEDULE_STATUS,
  UPDATE_URL_FILTER_STATUS,
} from '@/constants/ActionTypes.js';
import { employeesTable } from '@/constants/tableColumns.jsx';
import { defaultDateStores } from '@/utils/dateHelper.js';

const initialState = {
  currentActiveNavBarElementId: 0,
  showShadow: false,
  showSendPanel: false,
  showNotificationPanel: false,
  showModal: '',
  modalObject: {},
  showPaymentRemind: false,
  showOpenShiftModal: false,
  showApplyForOpenShiftModal: false,
  showAssignUsersOpenShiftModal: false,
  addPositionShiftModalObject: {
    rowData: {
      title: '',
      color: '',
      employees: [],
    },
    date: '',
  },
  showAddEventModal: false,
  eventModalObject: {
    date: '',
  },
  openShiftModalObject: {
    date: '',
    openShiftData: {},
  },
  applyForOpenShiftModalObject: {
    openShift: {},
    current_user: {},
    date: '',
  },
  assignUsersOpenShiftModalObject: {
    openShift: {},
    current_user: {},
  },
  showEditEventModal: false,
  showAddAvailabilityModal: false,
  addAvailabilityModalObject: {},
  showEditAvailabilityModal: false,
  editAvailabilityModalObject: {},
  showAddAttendanceModal: false,
  addAttendanceModalObject: {},
  showExportModal: false,
  showHelpModal: false,
  showOfflineMsg: false,
  showRequestTradeShiftModal: false,
  requestTradeShiftModalObject: {
    shift: {
      location: {},
      job_title: {},
    },
    tradeShiftData: {},
  },
  showCoworkerTradeShiftModal: false,
  coworkerTradeShiftModalObject: {
    tradeShiftData: {
      user: {},
      location: {},
      job_title: {},
    },
  },
  showApprovalTradeShiftModal: false,
  approvalTradeShiftModalObject: {
    tradeShiftData: {
      user: {},
      location: {},
      job_title: {},
      indicated_coworkers: [],
    },
  },
  payrollVisibleColumns: getInitPayrollColumns ? getInitPayrollColumns() : [],
  payrollLocationVisibleColumns: getInitPayrollLocationColumns ? getInitPayrollLocationColumns() : [],
  showImportOpenShiftsModal: null,
  showMainModal: false,
  showOpenShiftFromAutoGenerate: false,
  openShiftFromAutoGenerateModalObject: [],
  selectedOpenShiftRowLocationId: null,
  rearrangingEmployeesOrder: false,
  sortingUseLastName: true,
  employeesVisibleColumns: employeesTable.defaultVisibleColumns,
  defaultDateStores,
  isTrialInfoBarVisible: true,
  scheduleGeneration: false,
  autoschedulerLocation: null,
  exportWidget: {
    showOpenWidgetButton: true,
    showWidget: true,
  },
  filtersLoaded: false,
  areFiltersInUrl: true,
};

const uiState = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_NAVBAR_ELEMENT_ID:
      return { ...state, currentActiveNavBarElementId: action.payload };
    case CHANGE_SEND_PANEL_VISIBILITY:
      return { ...state, showSendPanel: action.payload };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload.name,
        modalObject: {
          ...state.modalObject,
          [action.payload.name]: action.payload.data,
        },
      };
    case REPLACE_MODAL:
      return {
        ...state,
        showModal: action.payload.name,
      };
    case HIDE_MODAL:
      return {
        ...state,
        showModal: '',
        modalObject: {},
      };
    case HIDE_MODAL_WITHOUT_CLEARING_MODAL_OBJECT:
      return { ...state, showModal: '' };
    case CLEAR_MODAL_OBJECT:
      return { ...state, modalObject: {} };
    case SHOW_OPEN_SHIFT_MODAL:
      return { ...state, showOpenShiftModal: action.payload.showOpenShiftModal,
        openShiftModalObject: action.payload.modalObject };
    case CLOSE_OPEN_SHIFT_MODAL:
      return { ...state, showOpenShiftModal: false,
        openShiftModalObject: initialState.openShiftModalObject };
    case SHOW_APPLY_FOR_OPEN_SHIFT_MODAL:
      return { ...state, showApplyForOpenShiftModal: action.payload.showApplyForOpenShiftModal,
        applyForOpenShiftModalObject: action.payload.modalObject };
    case CLOSE_APPLY_FOR_OPEN_SHIFT_MODAL:
      return { ...state, showApplyForOpenShiftModal: false,
        applyForOpenShiftModalObject: initialState.applyForOpenShiftModalObject };
    case SHOW_ASSIGN_USERS_OPEN_SHIFT_MODAL:
      return { ...state, showAssignUsersOpenShiftModal: action.payload.showAssignUsersOpenShiftModal,
        assignUsersOpenShiftModalObject: action.payload.modalObject };
    case CLOSE_ASSIGN_USERS_OPEN_SHIFT_MODAL:
      return { ...state, showAssignUsersOpenShiftModal: false,
        assignUsersOpenShiftModalObject: initialState.assignUsersOpenShiftModalObject };
    case SHOW_SHADOW:
      return { ...state, showShadow: true };
    case HIDE_SHADOW:
      return { ...state, showShadow: false };
    case SHOW_NOTIFICATION_PANEL:
      return { ...state, showNotificationPanel: true,
        showShadow: true };
    case CLOSE_NOTIFICATION_PANEL:
      return { ...state, showNotificationPanel: false,
        showShadow: false };
    case SHOW_ADD_AVAILABILITY_MODAL:
      return { ...state, showAddAvailabilityModal: true,
        addAvailabilityModalObject: action.payload };
    case CLOSE_ADD_AVAILABILITY_MODAL:
      return { ...state, showAddAvailabilityModal: false,
        addAvailabilityModalObject: {} };
    case SHOW_EDIT_AVAILABILITY_MODAL:
      return { ...state, showEditAvailabilityModal: true,
        editAvailabilityModalObject: action.payload };
    case CLOSE_EDIT_AVAILABILITY_MODAL:
      return { ...state, showEditAvailabilityModal: false,
        editAvailabilityModalObject: {} };
    case SHOW_ADD_ATTENDANCE_MODAL:
      return { ...state, showAddAttendanceModal: true,
        addAttendanceModalObject: action.payload,
        showModal: '',
        modalObject: {} };
    case CLOSE_ADD_ATTENDANCE_MODAL:
      return { ...state, showAddAttendanceModal: false,
        addAttendanceModalObject: {} };
    case TOGGLE_EXPORT_MODAL:
      return { ...state, showExportModal: !state.showExportModal };
    case TOGGLE_HELP_MODAL:
      return { ...state, showHelpModal: !state.showHelpModal };
    case OFFLINE_MESSAGE_ON:
      return { ...state, showOfflineMsg: true };
    case OFFLINE_MESSAGE_OFF:
      return { ...state, showOfflineMsg: false };
    case SHOW_REQUEST_TRADE_SHIFT_MODAL:
      return { ...state, showRequestTradeShiftModal: action.payload.showRequestTradeShiftModal,
        requestTradeShiftModalObject: action.payload.modalObject };
    case CLOSE_REQUEST_TRADE_SHIFT_MODAL:
      return { ...state, showRequestTradeShiftModal: false,
        requestTradeShiftModalObject: initialState.requestTradeShiftModalObject };
    case SHOW_COWORKER_TRADE_SHIFT_MODAL:
      return { ...state, showCoworkerTradeShiftModal: action.payload.showCoworkerTradeShiftModal,
        coworkerTradeShiftModalObject: action.payload.modalObject };
    case CLOSE_COWORKER_TRADE_SHIFT_MODAL:
      return { ...state, showCoworkerTradeShiftModal: false,
        coworkerTradeShiftModalObject: initialState.coworkerTradeShiftModalObject };
    case SHOW_APPROVAL_TRADE_SHIFT_MODAL:
      return { ...state, showApprovalTradeShiftModal: action.payload.showApprovalTradeShiftModal,
        approvalTradeShiftModalObject: action.payload.modalObject };
    case CLOSE_APPROVAL_TRADE_SHIFT_MODAL:
      return { ...state, showApprovalTradeShiftModal: false,
        approvalTradeShiftModalObject: initialState.approvalTradeShiftModalObject };
    case CHANGE_PAYROLL_COLUMNS:
      return {
        ...state,
        payrollVisibleColumns: action.payload,
      };
    case CHANGE_PAYROLL_LOCATION_COLUMNS:
      return {
        ...state,
        payrollLocationVisibleColumns: action.payload,
      };
    case TOGGLE_IMPORT_OPEN_SHIFTS_MODAL: {
      let showImportOpenShiftsModal = null;
      if (state.showImportOpenShiftsModal === null) showImportOpenShiftsModal = action.payload;
      return { ...state, showImportOpenShiftsModal };
    }
    case TOGGLE_MAIN_MODAL:
      return { ...state, showMainModal: !state.showMainModal };
    case SHOW_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL:
      return {
        ...state,
        showOpenShiftFromAutoGenerate: true,
        openShiftFromAutoGenerateModalObject: action.payload,
        scheduleGeneration: false,
        selectedOpenShiftRowLocationI: action.payload,
      };
    case CLOSE_OPEN_SHIFT_FROM_AUTO_GENERATE_MODAL:
      return {
        ...state,
        showOpenShiftFromAutoGenerate: false,
        openShiftFromAutoGenerateModalObject: [],
      };
    case CHANGE_DEFAULT_VIEW_DATE_STORE: {
      return { ...state, defaultDateStores: action.payload };
    }
    case CHANGE_ALL_DEFAULT_VIEW_DATE_STORES: {
      return { ...state, defaultDateStores: action.payload };
    }
    case TOGGLE_OPEN_SHIFT_ROW_SELECT:
      return {
        ...state,
        selectedOpenShiftRowLocationId: state.selectedOpenShiftRowLocationId === action.payload ? null : action.payload,
      };
    case DESELECT_ALL_SCHEDULE:
      return {
        ...state,
        selectedOpenShiftRowLocationId: null,
      };
    case START_CUSTOM_SORTING:
      return {
        ...state,
        rearrangingEmployeesOrder: true,
      };
    case CHANGE_EMPLOYEE_SORTING:
    case CHANGE_CUSTOM_SORTING_SUCCESS:
      return {
        ...state,
        rearrangingEmployeesOrder: false,
      };
    case CHANGE_SORTING_NAME_TYPE:
      return {
        ...state,
        sortingUseLastName: action.payload,
      };
    case SET_EMPLOYEES_VISIBLE_COLUMNS:
      return {
        ...state,
        employeesVisibleColumns: action.payload,
      };
    case CHANGE_ABSENCES_TABLE_DATA:
      return {
        ...state,
        absencesTable: {
          currentPage: action.payload.currentPage,
          numberOfItemsPerPage: action.payload.numberOfItemsPerPage,
          numberOfItems: action.payload.numberOfItems,
        },
      };
    case HIDE_TRIAL_INFO_BAR:
      return {
        ...state,
        isTrialInfoBarVisible: false,
      };
    case TOGGLE_MODAL: {
      const currentModal = state.showModal;
      const { modalName, ignoreWhenOtherModalIsOpen } = action.payload;

      if (modalName === currentModal) {
        return {
          ...state,
          showModal: null,
        };
      }

      if (ignoreWhenOtherModalIsOpen && currentModal) {
        return state;
      }

      return {
        ...state,
        showModal: modalName,
      };
    }
    case TOOGLE_AUTO_GENERATE_SCHEDULE_STATUS: {
      return { ...state, scheduleGeneration: action.payload };
    }
    case SET_AUTOSCHEDULER_LOCATION: {
      return { ...state, autoschedulerLocation: action.payload };
    }
    case SHOW_EXPORT_WIDGET_OPEN_BUTTON: {
      return {
        ...state,
        exportWidget: {
          ...state.exportWidget,
          showOpenWidgetButton: true,
        },
      };
    }
    case HIDE_EXPORT_WIDGET_OPEN_BUTTON: {
      return {
        ...state,
        exportWidget: {
          ...state.exportWidget,
          showOpenWidgetButton: false,
        },
      };
    }
    case SHOW_EXPORT_WIDGET: {
      return {
        ...state,
        exportWidget: {
          ...state.exportWidget,
          showWidget: true,
        },
      };
    }
    case HIDE_EXPORT_WIDGET: {
      return {
        ...state,
        exportWidget: {
          ...state.exportWidget,
          showWidget: false,
        },
      };
    }
    case SET_FILTERS_SUCCESS: {
      return {
        ...state,
        filtersLoaded: true,
      };
    }

    case UPDATE_URL_FILTER_STATUS: {
      return {
        ...state,
        areFiltersInUrl: action.payload,
      };
    }

    default:
      return state;
  }
};

export default uiState;
