import { connect } from 'react-redux';

import ScheduleBudgetTablePercentEstimates from './ScheduleBudgetTablePercentEstimates.jsx';

const mapStateToProps = state => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  locationSettings: state.reducer.settings.locationSettings,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

export default connect(mapStateToProps)(ScheduleBudgetTablePercentEstimates);
