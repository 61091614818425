import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';

import Button from '@/components/common/Basic/Button.jsx';
import { MDMultiSelectFooter, MDMultiSelectHeader, MDTextInput } from '@/components/common/inputs/MDComponents';
import { createEvent } from '@/utils/inputHelpers.js';

import './KadroDynamicList.scss';

export const KadroDynamicList = ({ items, messages, id, handleChange }) => {
  if (!items) return null;
  const [itemName, setItemName] = useState('');

  const changeItems = newItems => handleChange(createEvent(id, newItems));

  const addOption = useCallback(() => {
    changeItems([...items, itemName]);
    setItemName('');
  }, [itemName, items]);

  const deleteItem = useCallback(item => changeItems(items.filter((_, i) => i !== item.value)), [items]);

  const deleteAll = useCallback(() => changeItems([]), []);

  return (
    <div className="k-kadroDynamicList">
      <MDMultiSelectHeader
        title={messages.headerTitle}
        withoutSelectAll
        deselectAll={deleteAll}
        deselectAllMessage={messages.deselectAll}
      />
      <div className="k-kadroDynamicList__form">
        <MDTextInput value={itemName} onChange={e => setItemName(e.target.value)} id={id} modifiers="modal" />
        <Button onClick={addOption} modifiers="orange teeny" disabled={!itemName}>
          {messages.action}
        </Button>
      </div>
      <MDMultiSelectFooter
        title={messages.footerTitle}
        selectedItems={items.map((item, i) => ({ label: item, value: i }))}
        deleteItem={deleteItem}
      />
    </div>
  );
};

KadroDynamicList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  messages: PropTypes.shape({
    headerTitle: PropTypes.string,
    deselectAll: PropTypes.string,
    footerTitle: PropTypes.string,
    action: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  id: PropTypes.string,
};

export default memo(KadroDynamicList);
