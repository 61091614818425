import { useEffect, useMemo } from 'react';

import { useIsHovered, useMediaQuery, useToggle } from '@/hooks';
import { useAppSelector } from '@/redux-store';
import { selectIsCurrentUserOwner } from '@/selectors/currentUser.selectors';
import { selectIsAnyModalOpen } from '@/selectors/uiState.selectors';

import { getClasses } from './MultiAccounts.utils';

export const useMultiAccounts = () => {
  const [isMenuOpen, toggleMenu, , closeMenu] = useToggle();
  const [isSidebarHovered, triggerSidebarHoverOff] = useIsHovered();
  const isAnyModalOpened = useAppSelector(selectIsAnyModalOpen);
  const isOwner = useAppSelector(selectIsCurrentUserOwner);
  const { isAtLeastTabletLandscape } = useMediaQuery();

  const classes = useMemo(() => getClasses(isOwner), [isOwner]);

  useEffect(() => {
    triggerSidebarHoverOff();
    closeMenu();
  }, [isAnyModalOpened, closeMenu, triggerSidebarHoverOff]);

  useEffect(() => {
    if (!isSidebarHovered) closeMenu();
  }, [isSidebarHovered]);

  return { classes, isAtLeastTabletLandscape, isMenuOpen, isSidebarHovered, isAnyModalOpened, toggleMenu };
};

export type UseMultiAccountsValuesType = ReturnType<typeof useMultiAccounts>;
