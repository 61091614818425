import PropTypes from 'prop-types';

import Button from './Basic/Button.jsx';

const PublishButton = props => (
  <div className="k-publishButton">
    <Button onClick={props.onClickFunc} modifiers="publish" disabled={props.number <= 0 || props.disabled}>
      <i className="material-icons k-publishButton__sendIcon">{props.icon}</i>
      <span>{props.text}</span>
    </Button>
  </div>
);

PublishButton.defaultProps = {
  icon: 'send',
};

PublishButton.propTypes = {
  onClickFunc: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  number: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

export default PublishButton;
