import classnames from 'classnames';
import PropTypes from 'prop-types';

import { MANAGER_NOTE_MODAL } from '@/constants/modalTypes';

import './ManagerNoteCorn.scss';

const ManagerNoteCorn = (props) => {
  const handleOnCornClick = (e) => {
    e.stopPropagation();
    props.showModal(MANAGER_NOTE_MODAL, {
      locationId: props.locationId,
      date: props.date,
    });
  };

  const cornClassNames = classnames('k-ManagerNoteCorn__corner', {
    'k-ManagerNoteCorn__corner--add': props.managerNote,
    'k-ManagerNoteCorn__corner--edit': !props.managerNote,
  });

  return props.show ? (
    <div role="presentation" className="k-ManagerNoteCorn" onClick={handleOnCornClick}>
      <div className={cornClassNames} />
      {props.managerNote ? (
        <i className="material-icons k-ManagerNoteCorn__cornerIcon k-ManagerNoteCorn__cornerIcon--edit">edit</i>
      ) : (
        <i className="material-icons k-ManagerNoteCorn__cornerIcon k-ManagerNoteCorn__cornerIcon--add">add</i>
      )}
    </div>
  ) : null;
};

ManagerNoteCorn.propTypes = {
  show: PropTypes.bool,
  showModal: PropTypes.func,
  locationId: PropTypes.string,
  date: PropTypes.string,
  managerNote: PropTypes.shape({}),
};

export default ManagerNoteCorn;
