import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import NewExportModal from '@/components/common/exports/NewExportModal/NewExportModal.redux';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import PayrollSettingsModal from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.redux';
import { generateQueryParametersFromSearchString } from '@/utils/urlModifyHelpers';

import PreviewModal from '../../../PreviewModal/PreviewModal.redux';
import SideMenu from '../../../SideMenu/SideMenu.redux';
import Tiles from '../../../Tiles/Tiles';
import { getExports } from '../../exportsConfig.ts';
import ExportsButtonBar from './ExportsButtonBar/ExportsButtonBar.redux';
import { getSections } from './ExportsCatalogView.helpers';
import { messages } from './ExportsCatalogView.messages';

import './ExportsCatalogView.scss';

const ExportsCatalogView = ({ showModal, payoutSettingType, permissions }, { intl }) => {
  const location = useLocation();
  const searchParams = generateQueryParametersFromSearchString(location.search);
  const items = useMemo(() => getExports(showModal, payoutSettingType, permissions, intl), [
    showModal,
    payoutSettingType,
    permissions,
    intl,
  ]);

  const filteredItems = items.filter(
    item =>
      (!searchParams.format || searchParams.format === 'all' || searchParams.format === item.filterFormat) &&
      (!searchParams.category || searchParams.category === 'all' || item.categories?.includes(searchParams.category)),
  );
  const sections = useMemo(() => getSections(intl), []);
  return (
    <>
      <ExportsButtonBar />
      <PayrollSettingsModal />
      <PreviewModal />
      <NewExportModal />
      <div className="exportsCatalogView">
        <SideMenu sections={sections} />
        {filteredItems.length > 0 ? (
          <Tiles items={filteredItems} sections={sections[0].items} />
        ) : (
          <EmptyState
            name="scheduleCTA"
            imgSrc="/img/scheduleCTA.png"
            emptyStateHeader={intl.formatMessage(messages.emptyState)}
            emptyStateText={intl.formatMessage(messages.emptyStateDescription)}
            ctaText=""
          />
        )}
      </div>
    </>
  );
};

ExportsCatalogView.contextTypes = {
  intl: PropTypes.shape({}),
};

ExportsCatalogView.propTypes = {
  payoutSettingType: PropTypes.string,
  showModal: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      format: PropTypes.string,
      category: PropTypes.string,
    }),
  }),
};

export default ExportsCatalogView;
