import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';
import browserHistory from '@/constants/browserHistory';

const SwitchToFullApp = props => (
  <Modal onHide={props.onHide} show className="switchToFullApp">
    <Modal.Header>
      <img className="logo" src="/img/logo.png" alt="logo" height="40" />
    </Modal.Header>

    <Modal.Body>
      <div className="signUpText">
        <FormattedMessage
          id="onboarding.switchToFullApp.description"
          defaultMessage="Nie wszystkie funkcję platformy Kadromierz są dostępne na koncie demonstracyjnym. Podoba Ci się nasza aplikacja? Wypróbuj za darmo i załóż konto na 14-dniowy okres próbny!"
        />
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        onClick={() => {
          browserHistory.push('/onboarding/stepOne');
        }}
        modifiers="blue small floatright"
        data-dismiss="modal"
      >
        <FormattedMessage id="onboarding.switchToFullApp.signUpBtn" defaultMessage="Załóż konto" />
      </Button>
      <Button
        onClick={() => {
          if (props.cancel) {
            props.cancel();
          } else {
            console.log('Switch to full app reload');
            browserHistory.push('/');
          }
        }}
        modifiers="reverse-blue small floatleft"
        data-dismiss="modal"
      >
        <FormattedMessage id="common.return" defaultMessage="Powrót" />
      </Button>
    </Modal.Footer>
  </Modal>
);

SwitchToFullApp.propTypes = {
  cancel: PropTypes.func,
  onHide: PropTypes.func,
};

export default SwitchToFullApp;
