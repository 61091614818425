export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split('base64,')[1]);
    reader.onerror = error => reject(error);
  });
export const downloadFile = url => {
  const link = document.createElement('a');
  link.download = 'name';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
