import PropTypes from 'prop-types';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';

const TopBarAbsenceFilter = props => {
  const changeFunc = absenceType => {
    props.changeAbsenceFilter(absenceType);
    // change the range as well
    props.mainDateChangeMode(absenceType.limit_period);
  };

  return (
    <div className="k-topBarAbsenceFilter">
      <TopBarSingleChoice
        objectsToChoose={props.absencesTypes.filter(
          absenceType => absenceType.has_limit && !absenceType.deleted_at_timestamp,
        )}
        selected={props.absenceFilter.selectedAbsence}
        onChange={changeFunc}
        displayKey="name"
      />
    </div>
  );
};

TopBarAbsenceFilter.propTypes = {
  absenceFilter: PropTypes.shape({
    selectedAbsence: PropTypes.shape({}),
  }),
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  changeAbsenceFilter: PropTypes.func,
  mainDateChangeMode: PropTypes.func,
};

export default TopBarAbsenceFilter;
