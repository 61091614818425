import { connect } from 'react-redux';

import { editShift } from '@/actions';
import { startShiftDrag, stopShiftDrag } from '@/actions/schedule/dragAndDrop';
import { showDeleteShiftConfirmModal } from '@/actions/schedule/shifts.js';
import { showModal } from '@/actions/uiState.js';

import ScheduleSingleDayShift from './ScheduleSingleDayShift.jsx';

const mapStateToProps = (state, ownProps) => ({
  shift: state.reducer.shifts.data[ownProps.employeeId]?.shifts?.[ownProps.shiftId],
  restrictions: state.reducer.userPermissions.restrictions,
  userEmployees: state.reducer.userEmployees,
  userJobTitles: state.reducer.userJobTitles,
  employeeContracts: state.reducer.contracts[ownProps.employeeId],
  showShiftsFromOtherLocations: state.reducer.schedule.viewSettings.showShiftsFromOtherLocations.value,
});

const mapDispatchToProps = {
  editShift,
  startShiftDrag,
  stopShiftDrag,
  showModal,
  showDeleteShiftConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSingleDayShift);
