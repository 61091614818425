import PropTypes from 'prop-types';
import { memo } from 'react';

import Button from '@/components/common/Basic/Button.jsx';

import { BUTTON_MODIFIERS, PRICE_LIST_LINK } from './SelectPackageContentFooter.helpers';
import { messages } from './SelectPackageContentFooter.messages';

import './SelectPackageContentFooter.scss';

const SelectPackageContentFooter = ({ selectedPackageId, onSubmit }, { intl }) => (
  <div className="k-selectPackageContentFooter">
    <a href={PRICE_LIST_LINK} target="_blank" className="k-selectPackageContentFooter__link" rel="noreferrer">
      {intl.formatMessage(messages.priceList)}
    </a>
    {selectedPackageId && (
      <Button onClick={onSubmit} modifiers={BUTTON_MODIFIERS}>
        {intl.formatMessage(messages[selectedPackageId === 'freemiumPackage' ? 'next' : 'contact'])}
      </Button>
    )}
  </div>
);

SelectPackageContentFooter.contextTypes = {
  intl: PropTypes.shape({}),
};

SelectPackageContentFooter.propTypes = {
  selectedPackageId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default memo(SelectPackageContentFooter);
