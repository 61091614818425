import { produce } from 'immer';

import {
  ADD_NEW_SHIFTBLOCK,
  DELETE_SHIFTBLOCK,
  DELETE_SHIFTBLOCK_SET,
  GET_SHIFTBLOCKS_SUCCESFUL,
  UPDATE_SHIFTBLOCK,
} from '@/constants/ActionTypes';
import {
  AddNewShiftBlockAction,
  DeleteShiftBlockAction,
  DeleteShiftBlockSetAction,
  GetShiftBlocksSuccesfulAction,
  UpdateShiftBlockAction,
} from '@/types/actions/shiftBlock.types.ts';
import { ShiftBlock } from '@/types/shiftBlock.types.ts';

type Action =
  | GetShiftBlocksSuccesfulAction
  | AddNewShiftBlockAction
  | DeleteShiftBlockAction
  | DeleteShiftBlockSetAction
  | UpdateShiftBlockAction;

const initialState: ShiftBlock[] = [];

const userShiftBlocks = (state = initialState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_SHIFTBLOCKS_SUCCESFUL: {
        return action.payload;
      }
      case ADD_NEW_SHIFTBLOCK: {
        draft.push(action.payload);
        break;
      }
      case DELETE_SHIFTBLOCK: {
        return state.filter(shiftBlock => shiftBlock.id !== action.payload);
      }
      case DELETE_SHIFTBLOCK_SET:
        return state.filter(s => !action.payload.includes(s.id));
      case UPDATE_SHIFTBLOCK: {
        const index = draft.findIndex(item => item.id === action.payload.id);
        draft[index] = action.payload;
        break;
      }
      default:
        return state;
    }
  });

export default userShiftBlocks;
