import { produce } from 'immer';
import { AbsenceType } from 'kadro-helpers/lib/types';

import { CHANGE_ABSENCE_FILTER } from '@/constants/ActionTypes.js';

const initialState = {
  selectedAbsence: {},
};

interface ChangeAbsenceFilterAction {
  type: typeof CHANGE_ABSENCE_FILTER;
  payload: AbsenceType;
}

const absenceFilter = (state = initialState, action: ChangeAbsenceFilterAction) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_ABSENCE_FILTER: {
        draft.selectedAbsence = action.payload;
        break;
      }
      default:
        return state;
    }
  });

export default absenceFilter;
