import PropTypes from 'prop-types';

import { BUDGET_ESTIMATES_EDIT } from '@/constants/Permissions.js';

import { getFormattedEstimate } from '../../ScheduleBudgetTable.helpers.js';
import ScheduleBudgetTableRowInput from '../../ScheduleBudgetTableRowInput/ScheduleBudgetTableRowInput.jsx';

const ScheduleBudgetTableEstimatesCell = ({
  canEditBudget,
  date,
  estimate = {},
  permissions,
  onEstimateChange,
  setCellError,
  getClassName,
  dayType,
  showHolidays,
}) => (
  <td className={getClassName(estimate, 'estimated_budget', dayType, date, showHolidays)}>
    {canEditBudget ? (
      <ScheduleBudgetTableRowInput
        key={date}
        name="estimated_budget"
        estimate={estimate}
        disabled={!permissions.includes(BUDGET_ESTIMATES_EDIT)}
        onChange={e => onEstimateChange(e, estimate, 'estimated_budget', date)}
        setError={setCellError}
      />
    ) : (
      <div>{getFormattedEstimate(estimate.estimated_budget)} PLN</div>
    )}
  </td>
);
ScheduleBudgetTableEstimatesCell.propTypes = {
  date: PropTypes.string,
  canEditBudget: PropTypes.bool,
  estimate: PropTypes.shape({
    estimated_budget: PropTypes.number,
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
  onEstimateChange: PropTypes.func,
  setCellError: PropTypes.func,
  getClassName: PropTypes.func,
  dayType: PropTypes.string,
  showHolidays: PropTypes.bool,
};

export default ScheduleBudgetTableEstimatesCell;
