import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { MDCheckbox } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { NARROW } from '@/constants/modalModifiers.js';
import { useInputChange } from '@/hooks';
import { UserEmployee } from '@/types';
import { createEvent } from '@/utils/inputHelpers.js';

import { getUnreleasedEmployeesNames } from './DeleteReleasedEmployeesDataModal.helpers';
import { messages } from './DeleteReleasedEmployeesDataModal.messages';

import './deleteReleasedEmployeesDataModal.scss';

interface DeleteReleasedEmployeesDataModalProps {
  showModal: boolean;
  hideModal: () => void;
  modalObject: {
    relevantSelectedEmployeesIds?: string[];
  };
  deleteReleasedEmployeesData: (
    employeeIds: string[],
    deleteShifts: boolean,
    deleteAttendances: boolean,
    deleteAbsences: boolean,
  ) => void;
  userEmployees: UserEmployee[];
}

const getInitialState = () => ({
  deleteShifts: false,
  deleteAbsences: false,
  deleteAttendances: false,
});

const DeleteReleasedEmployeesDataModal = (
  {
    showModal,
    hideModal,
    modalObject,
    deleteReleasedEmployeesData,
    userEmployees,
  }: DeleteReleasedEmployeesDataModalProps,
  { intl },
) => {
  const [state, handlers] = useInputChange(getInitialState(), intl);
  const relevantSelectedEmployeesIds = modalObject?.relevantSelectedEmployeesIds || undefined;
  const { deleteShifts, deleteAbsences, deleteAttendances } = state;

  const { employeeNames, numberOfAdditionalEmployees } = useMemo(
    () =>
      relevantSelectedEmployeesIds
        ? getUnreleasedEmployeesNames(userEmployees, relevantSelectedEmployeesIds)
        : {
            employeeNames: [],
            numberOfAdditionalEmployees: 0,
          },
    [relevantSelectedEmployeesIds, userEmployees],
  );

  const unreleasedEmployeesNamesString = useMemo(() => employeeNames.map(name => name).join(', '), [employeeNames]);

  const isAnyOptionSelected = deleteShifts || deleteAbsences || deleteAttendances;

  const handleChange = useCallback(e => handlers.changeInput(createEvent(e.target.name, e.target.checked)), [handlers]);

  const onHide = useCallback(() => {
    handlers.setInitState(getInitialState());
    hideModal();
  }, [handlers, hideModal]);

  const onConfirm = useCallback(() => {
    deleteReleasedEmployeesData(relevantSelectedEmployeesIds, deleteShifts, deleteAttendances, deleteAbsences);
    onHide();
  }, [onHide, deleteReleasedEmployeesData]);

  return (
    <MDKadroModal
      onHide={onHide}
      show={showModal}
      title={intl.formatMessage(messages.modalTitle)}
      confirmText={intl.formatMessage(messages.deleteButton)}
      disableConfirm={!!employeeNames?.length || !isAnyOptionSelected}
      errorMessage={!!employeeNames?.length && intl.formatMessage(messages.notReleasedEmployeesPicked)}
      onSubmit={onConfirm}
      modifiers={NARROW}
    >
      <div className="k-deleteReleasedEmployeesDataModal__description">
        {employeeNames?.length > 0 ? (
          <div>
            {numberOfAdditionalEmployees < 1 ? (
              <div>
                {intl.formatMessage(messages.selectedThreeEmployeesAreNotReleased, {
                  unreleasedEmployeesNamesString,
                  numberOfAdditionalEmployees,
                })}
              </div>
            ) : (
              <div>
                {intl.formatMessage(messages.selectedEmployeesAreNotReleased, {
                  unreleasedEmployeesNamesString,
                  numberOfAdditionalEmployees,
                })}
              </div>
            )}
          </div>
        ) : (
          <div>{intl.formatMessage(messages.selectDataToDelete)}</div>
        )}
      </div>
      <div className="k-deleteReleasedEmployeesDataModal__checkboxes">
        <MDCheckbox
          key="deleteShifts"
          id="deleteShifts"
          text={intl.formatMessage(messages.deleteShifts)}
          value={deleteShifts}
          onChange={handleChange}
          disabled={!!employeeNames?.length}
          reverse
        />
        <MDCheckbox
          key="deleteAttendances"
          id="deleteAttendances"
          text={intl.formatMessage(messages.deleteAttendances)}
          value={deleteAttendances}
          onChange={handleChange}
          disabled={!!employeeNames?.length}
          reverse
        />
        <MDCheckbox
          key="deleteAbsences"
          id="deleteAbsences"
          text={intl.formatMessage(messages.deleteAbsences)}
          value={deleteAbsences}
          onChange={handleChange}
          disabled={!!employeeNames?.length}
          reverse
        />
      </div>
    </MDKadroModal>
  );
};

DeleteReleasedEmployeesDataModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default DeleteReleasedEmployeesDataModal;
