import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addOvertimeCollectionSuccess: {
    id: 'absences.addOvertimeCollectionSuccess',
    defaultMessage: 'Odbiór nadgodzin został dodany',
  },
  addOvertimeCollectionError: {
    id: 'absences.addOvertimeCollectionError',
    defaultMessage: 'Dodanie odbioru nadgodzin nie powiodło się',
  },
  editOvertimeCollectionSuccess: {
    id: 'absences.editOvertimeCollectionSuccess',
    defaultMessage: 'Odbiór nadgodzin został zmieniony',
  },
  editOvertimeCollectionError: {
    id: 'absences.editOvertimeCollectionError',
    defaultMessage: 'Edycja odbioru nadgodzin nie powiodła się',
  },
  success: {
    id: 'overtimeCollections.success',
    defaultMessage: 'Sukces',
  },
  error: {
    id: 'overtimeCollections.error',
    defaultMessage: 'Błąd',
  },
  deleteOvertimeCollectionSuccess: {
    id: 'overtimeCollections.deleteOvertimeCollectionSuccess',
    defaultMessage: 'Odbiór nadgodzin został usunięty',
  },
  deleteOvertimeCollectionError: {
    id: 'overtimeCollections.deleteOvertimeCollectionError',
    defaultMessage: 'Usunięcie odbioru nadgodzin nie powiodło się',
  },
  deleteOvertimeCollectionTitle: {
    id: 'overtimeCollections.deleteOvertimeCollectionTitle',
    defaultMessage: 'Usuń odbiór nadgodzin',
  },
  deleteOvertimeCollectionDescription: {
    id: 'overtimeCollections.deleteOvertimeCollectionDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć odbiór nadgodzin?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
  deleteOvertimeCollectionsConfirmModalTitle: {
    id: 'deleteOvertimeCollections.confirmModal.title',
    defaultMessage: 'Usuń wniosek',
  },
  deleteOvertimeCollectionsConfirmModalDescription: {
    id: 'deleteOvertimeCollections.confirmModal.description',
    defaultMessage: 'Usuwając wniosek utracisz możliwość przywrócenia jego',
  },
  deleteOvertimeCollectionsConfirmModalText: {
    id: 'deleteOvertimeCollections.confirmModal.text',
    defaultMessage: 'Usuń',
  },
  getOvertimeCollectionsFailure: {
    id: 'getOvertimeCollections.failure',
    defaultMessage: 'Wystąpił błąd podczas wczytywania odbiorów nadgodzin.',
  },
});
