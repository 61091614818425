export const setChar = (s, n, c) => s.substr(0, n) + c + s.substr(n + 1);

export const compareWorkingHours = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':');
  const [hours2, minutes2] = time2.split(':');
  if (hours1 < hours2) return -1;
  if (hours1 > hours2) return 1;
  if (minutes1 < minutes2) return -1;
  if (minutes1 > minutes2) return 1;
  return 0;
};

export const compareTime = (prevTime, time) =>
  time && !prevTime.split('').some((sign, index) => sign !== time[index] && time[index] !== '_');

export const getInputValue = (rawValue) => {
  let inputValue = rawValue;
  let newCursorPosition = null;

  // 50:00-00:00 -> 05:00-00:00
  let d = inputValue.charAt(0);
  if (d > 2) {
    inputValue = setChar(inputValue, 0, '0');
    inputValue = setChar(inputValue, 1, d);
    newCursorPosition = 3;
  }

  // 00:00-05:00 -> 00:00-05:00
  d = inputValue.charAt(6);
  if (d > 2) {
    inputValue = setChar(inputValue, 6, '0');
    inputValue = setChar(inputValue, 7, d);
    newCursorPosition = 8;
  }

  d = inputValue.substr(0, 2);
  // 24:00-00:00 -> 00:00-00:00
  if (d === '24') {
    inputValue = `00${inputValue.substr(2)}`;
  } else if (d > 24) {
    // 25:00-00:00 -> 23:00-00:00 possible if first adds 5 then 2
    inputValue = `23${inputValue.substr(2)}`;
  }

  d = inputValue.substring(6, 8);
  // 24:00-00:00 -> 00:00-00:00
  if (d === '24') {
    inputValue = `${inputValue.substring(0, 6)}00${inputValue.substr(8)}`;
  } else if (d > 24) {
    // 25:00-00:00 -> 23:00-00:00 possible if first adds 5 then 2
    inputValue = `${inputValue.substring(0, 6)}23${inputValue.substr(8)}`;
  }

  if (inputValue === '00:00-00:00') {
    inputValue = '00:00-23:59';
  }

  return { inputValue, newCursorPosition };
};
