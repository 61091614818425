import mousetrap from 'mousetrap';
import { useCallback, useEffect } from 'react';

import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import store from '@/redux-store/store';

 
const useChangeDateByArrow = (onChangeCallback = undefined) => {
  const moveLeft = useCallback(() => {
    store.dispatch(mainDateMoveLeft());
    if (onChangeCallback) onChangeCallback();
  }, []);

  const moveRight = useCallback(() => {
    store.dispatch(mainDateMoveRight());
    if (onChangeCallback) onChangeCallback();
  }, []);

  useEffect(() => {
    mousetrap.bind('left', moveLeft);
    mousetrap.bind('right', moveRight);
    return () => {
      mousetrap.unbind('left');
      mousetrap.unbind('right');
    };
  }, []);
};

export default useChangeDateByArrow;
