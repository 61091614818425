import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import TextInput from '@/components/common/inputs/TextInput.jsx';
import { calculateDurationBetweenTimestamps, parseMinutesToFormat } from '@/utils/dateHelper.js';

import ProductionQuotasTableRowDetails from './ProductionQuotasTableRowDetails.jsx';

class ProductionQuotasTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.jobTitle.amount || 0,
      target: this.props.jobTitle.target || 0,
      showDetails: false,
    };
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showDetails = this.showDetails.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      amount: nextProps.jobTitle.amount || 0,
      target: nextProps.jobTitle.target || 0,
      showDetails: nextProps.relevantEmployees.length ? this.state.showDetails : false,
    });
  }

  onBlur(e) {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        this.props.onQuotaChange({
          objects: [
            {
              job_title_id: parseInt(this.props.jobTitle.id),
              amount: parseInt(this.state.amount),
              target: parseInt(this.state.target),
            },
          ],
        });
      },
    );
  }

  onChange({ target }) {
    this.setState({
      [target.id]: target.value,
    });
  }

  getPerformance(sumHours) {
    let performance = '- -';
    if (this.state.amount && this.state.target && sumHours) {
      performance = `${((this.state.amount / this.state.target) * 100).toFixed(2)}%`;
    } else if (this.state.target) {
      performance = '0%';
    }
    return performance;
  }

  getFactor(factor) {
    const factorValue = Number(factor);
    const factorDec = factorValue.toString().split('.')[1];
    let factorDecLen = !!factorDec && factorDec.length > 2 ? factorDec.length : 2;
    factorDecLen = (!!factorDecLen && Number(factorDecLen)) > 9 ? 9 : factorDecLen;
    return factorValue.toFixed(factorDecLen);
  }

  showDetails() {
    if (this.props.dateMode !== 'month') {
      this.setState(prev => ({
        showDetails: this.props.relevantEmployees.length ? !prev.showDetails : prev.showDetails,
      }));
    }
  }

  render() {
    const detailsClassNames = classnames('k-pquotas__row', 'k-pquotas__details', {
      'k-pquotas__details--show': this.state.showDetails,
    });
    const jobTitleClassNames = classnames('col_name', {
      'k-pquotas__col-name': !this.props.relevantEmployees.length && this.props.dateMode !== 'month',
    });
    const sumMinutes = this.props.relevantEmployees.reduce(
      (prev, employee) =>
        prev +
        employee.attendances.reduce(
          (prevAttendance, attendance) =>
            prevAttendance + calculateDurationBetweenTimestamps(attendance.end_timestamp, attendance.start_timestamp),
          0,
        ),
      0,
    );
    const target = parseFloat(this.state.target).toFixed(2);
    const performance = this.getPerformance(sumMinutes / 60);
    const realization = sumMinutes ? parseFloat(this.state.amount / (sumMinutes / 60)).toFixed(2) : 0;
    const sum = (parseFloat(this.props.jobTitle.factor) * parseInt(this.state.amount || 0)).toFixed(2);
    return (
      <tr>
        <td colSpan={8}>
          <table className="table k-pquotas__inner-table">
            {this.props.colgroup}
            <tbody>
              <tr>
                <td onClick={this.showDetails} className={jobTitleClassNames} role="presentation">
                  {this.props.relevantEmployees.length && this.props.dateMode !== 'month' ? (
                    <i className="material-icons sortdir noselect" style={{ marginTop: '0px' }}>
                      {this.state.showDetails ? 'arrow_drop_down' : 'arrow_drop_up'}
                    </i>
                  ) : null}
                  {this.props.jobTitle.title}
                </td>
                <td>{this.getFactor(this.props.jobTitle.factor)}</td>
                <td>
                  {this.props.dateMode !== 'month' ? (
                    <TextInput
                      id="target"
                      onBlur={this.onBlur}
                      onChange={this.onChange}
                      type="number"
                      className="form-control"
                      value={this.state.target}
                    />
                  ) : (
                    target
                  )}
                </td>
                <td>{realization}</td>
                <td>
                  {this.props.dateMode !== 'month' ? (
                    <TextInput
                      id="amount"
                      onBlur={this.onBlur}
                      onChange={this.onChange}
                      type="number"
                      className="form-control"
                      value={this.state.amount}
                    />
                  ) : (
                    this.state.amount
                  )}
                </td>
                <td>{performance}</td>
                <td>{parseMinutesToFormat(sumMinutes)}</td>
                <td>{sum}</td>
              </tr>
              <tr className={detailsClassNames}>
                <td colSpan={8}>
                  <ProductionQuotasTableRowDetails
                    relevantEmployees={this.props.relevantEmployees}
                    sumAllMinutes={sumMinutes}
                    quota={this.state.amount}
                    factor={this.props.jobTitle.factor}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

ProductionQuotasTableRow.propTypes = {
  jobTitle: PropTypes.shape({
    id: PropTypes.string,
    target: PropTypes.number,
    amount: PropTypes.number,
    factor: PropTypes.string,
    title: PropTypes.string,
  }),
  dateMode: PropTypes.string,
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  onQuotaChange: PropTypes.func,
  colgroup: PropTypes.node,
};

export default ProductionQuotasTableRow;
