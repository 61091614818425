import {
  RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE,
  RECOMMENDED_SCHEDULE_IMPORT_MODAL,
} from '@/constants/modalTypes.js';

import { messages } from './LoadRecommendedScheduleModal.messages';

export const MODAL_MODIFIERS = ['narrow'];

export const RECOMMENDED_SCHEDULE_VERSIONS = {
  fromExcel: { id: 'fromExcel', modalType: RECOMMENDED_SCHEDULE_IMPORT_MODAL },
  fromExisting: { id: 'fromExisting', modalType: RECOMMENDED_SCHEDULE_FROM_EXISTING_TEMPLATE },
};

export const getRecommendedScheduleVersionsOptions = intl => [
  {
    name: intl.formatMessage(messages.fromExcel),
    type: RECOMMENDED_SCHEDULE_VERSIONS.fromExcel.id,
    description: intl.formatMessage(messages.fromExcelDescription),
  },
  {
    name: intl.formatMessage(messages.fromExistingTemplate),
    type: RECOMMENDED_SCHEDULE_VERSIONS.fromExisting.id,
    description: intl.formatMessage(messages.fromExistingTemplateDescription),
  },
];
