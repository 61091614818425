import { useEffect,useState } from 'react';

import {
  checkIsDesktopTableView,
  checkIsTableBiggerThanWindow,
  countTotalWidthForVisibleColumns,
  INITIAL_TABLE_SETTINGS,
} from './useTableWithScroll.helpers';

const useTableWithScroll = (visibleColumns, allColumns, options = {}) => {
  const settings = { ...INITIAL_TABLE_SETTINGS, ...options };
  const [isTableWithScroll, setIsTableWithScroll] = useState(false);
  const [totalWidth, setTotalWidth] = useState(0);
  const [isDesktop, setIsDesktop] = useState(checkIsDesktopTableView());
  const [isTableBiggerThanWindow, setIsTableBiggerThanWindow] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setIsDesktop(checkIsDesktopTableView());
      setIsTableBiggerThanWindow(checkIsTableBiggerThanWindow(totalWidth));
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [totalWidth]);

  useEffect(() => {
    const newTotalWidth = countTotalWidthForVisibleColumns(allColumns, visibleColumns, settings);
    setTotalWidth(newTotalWidth);
  }, [visibleColumns, allColumns]);

  useEffect(() => {
    const shouldBeScroll = !isDesktop ? false : checkIsTableBiggerThanWindow(totalWidth);
    setIsTableWithScroll(shouldBeScroll);
  }, [isDesktop, totalWidth, isTableBiggerThanWindow]);

  return { isTableWithScroll };
};

export default useTableWithScroll;
