/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDSwitch } from '@/components/common/inputs/MDComponents.jsx';

const EditMassEmployeesElasticWorkDayField = ({ value, onChange }) => {
  useEffect(() => {
    onChange(value);
  }, []);
  return (
    <MDSwitch
      value={value}
      id="elasticWorkDay"
      label={
        <FormattedMessage id="companymanage.employmentConditions.elasticWorkDay" defaultMessage="Ruchomy czas pracy" />
      }
      tooltip={
        <FormattedMessage
          id="companymanage.employmentConditions.elasticWorkDayDescription"
          defaultMessage="Włącz tę opcję, jeśli pracownik korzysta z ruchomego czasu pracy. Będzie to miało wpływ na wyliczanie nadgodzin dobowych oraz walidacje reguły doby pracowniczej w grafiku pracy"
        />
      }
      onChange={e => onChange(e.target.checked)}
      modifiers="oneRow"
    />
  );
};

EditMassEmployeesElasticWorkDayField.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default EditMassEmployeesElasticWorkDayField;
