import { connect } from 'react-redux';

import { addBonusToAttendance } from '@/actions/attendances.js';
import {
  exportEnovaAttendances,
  exportEnovaScheduleWork,
} from '@/actions/exports/enovaExports/payroll/payrollEnovaExports';
import { selectAllJobTitlesAndEmploymentConditions } from '@/actions/filters.js';
import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { getAllOvertimeData } from '@/actions/overtime';
import { changeSorting } from '@/actions/payroll.js';
import { changePayrollSettings } from '@/actions/payrollSettings.js';
import {
  payrollLocationSetSelected,
  payrollLocationToggleSelected,
  togglePayrollSettingsModal,
} from '@/actions/payrollUI.js';
import { calculateSpmhBasedBonuses } from '@/actions/spmhBasedBonuses.js';
import { decreaseLoaderCounter, increaseLoaderCounter, showModal,toggleExportModal } from '@/actions/uiState.js';

import PayrollLocationTableView from './PayrollLocationTableView.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  userEmployees: state.reducer.userEmployees,
  userCustomTypes: state.reducer.userCustomTypes,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  payrollSettings: state.reducer.payrollSettings,
  payrollUI: state.reducer.payrollUI,
  mainDateStore: state.reducer.mainDateStore,
  listsUi: state.reducer.listsUi,
  selectedJobtitles: state.reducer.jobtitleFilter.selectedJobtitles,
  uiState: state.reducer.uiState,
  visibleLocationColumns: state.reducer.uiState.payrollLocationVisibleColumns,
  overtimeCollections: state.reducer.overtimeCollections,
  absences: state.reducer.absences.scheduleAbsences,
  absenceTypes: state.reducer.absences.absencesTypes,
  showUnpaidAbsences: state.reducer.currentCompany.settings.show_unpaid_absences,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  toggleExportModal,
  addBonusToAttendance,
  changeSorting,
  payrollLocationSetSelected,
  payrollLocationToggleSelected,
  togglePayrollSettingsModal,
  changePayrollSettings,
  getAllOvertimeData,
  selectAllJobTitlesAndEmploymentConditions,
  calculateSpmhBasedBonuses,
  increaseLoaderCounter,
  decreaseLoaderCounter,
  exportEnovaAttendances,
  exportEnovaScheduleWork,
  showModal,
};

const PayrollLocationTableViewContainer = connect(mapStateToProps, mapDispatchToProps)(PayrollLocationTableView);

export default PayrollLocationTableViewContainer;
