import { connect } from 'react-redux';

import { deleteReleasedEmployeesData } from '@/actions/companymanage';
import { hideModal } from '@/actions/uiState';
import { DELETE_RELEASED_EMPLOYEES_DATA_MODAL } from '@/constants/modalTypes';

import DeleteReleasedEmployeesDataModal from './DeleteReleasedEmployeesDataModal.tsx';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[DELETE_RELEASED_EMPLOYEES_DATA_MODAL],
  showModal: state.reducer.uiState.showModal === DELETE_RELEASED_EMPLOYEES_DATA_MODAL,
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  hideModal,
  deleteReleasedEmployeesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteReleasedEmployeesDataModal);
