import { GREY_600, LIME_GREEN_500, ORANGE_500, RED_500, TAG_YELLOW_1 } from './colors';

export const MINUTES = 'minutes';

export const PAYOUT = 'payout';

export const WORK_TIME_CHART_MODES = [{ key: 'Czas pracy', value: MINUTES }];

export const CHART_MODES = [
  ...WORK_TIME_CHART_MODES,
  { key: 'Liczbowo', value: 'employees' },
  { key: 'Koszty', value: PAYOUT },
];

export const ABSENCES_CHART_MODES = [...WORK_TIME_CHART_MODES, { key: 'Liczby absencji', value: 'numberOfAbsences' }];

export const ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_MODES = [
  { key: 'Godziny', value: MINUTES },
  { key: 'Frekwencja', value: 'frequency' },
];

export enum LineType {
  MONOTONE = 'monotone',
}

export enum LegendType {
  LINE = 'line',
}

export const RADIAN = Math.PI / 180;

export enum AbsencesAndAttendancesOrShiftsChartDataKey {
  EARLY_OR_PUNCTUAL_START_ATTENDANCES = 'earlyOrPunctualStartAttendances',
  LATE_ATTENDANCES = 'lateAttendances',
  ATTENDANCES_WITHOUT_SHIFT = 'attendancesWithoutShift',
  ABSENCES = 'absences',
  SHIFTS = 'shifts',
}

export const ABSENCES_AND_ATTENDANCES_OR_SHIFTS_CHART_METADATA = [
  {
    legendType: LegendType.LINE,
    lineType: LineType.MONOTONE,
    dataKey: AbsencesAndAttendancesOrShiftsChartDataKey.EARLY_OR_PUNCTUAL_START_ATTENDANCES,
    stackId: '1',
    fill: LIME_GREEN_500,
  },
  {
    legendType: LegendType.LINE,
    lineType: LineType.MONOTONE,
    dataKey: AbsencesAndAttendancesOrShiftsChartDataKey.LATE_ATTENDANCES,
    stackId: '1',
    fill: TAG_YELLOW_1,
  },
  {
    legendType: LegendType.LINE,
    lineType: LineType.MONOTONE,
    dataKey: AbsencesAndAttendancesOrShiftsChartDataKey.ATTENDANCES_WITHOUT_SHIFT,
    stackId: '1',
    fill: ORANGE_500,
  },
  {
    legendType: LegendType.LINE,
    lineType: LineType.MONOTONE,
    dataKey: AbsencesAndAttendancesOrShiftsChartDataKey.SHIFTS,
    stackId: '1',
    fill: LIME_GREEN_500,
  },
  {
    legendType: LegendType.LINE,
    lineType: LineType.MONOTONE,
    dataKey: AbsencesAndAttendancesOrShiftsChartDataKey.ABSENCES,
    stackId: '1',
    fill: RED_500,
  },
];

export const STROKE_DASHARRAY = '4 4';

export const MAX_ELEMENT_IN_CHART_LEGEND = 8;

export const TICK_STYLE = { fontSize: 14, fill: GREY_600, fontFamily: 'Lato' };

export const TOOLTIP_CONTENT_STYLE = { borderRadius: '4px' };

export const LEGEND_WRAPPER_STYLE = { fontSize: '12px' };

export const NO_JOBTITLE_ID = 'noJobTitle';
