import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import { ASCENT } from '@/constants/device';

const DeviceHeaderCell = ({ key, onClick, message, isTitle, isOption, sort }) => {
  const headerClassName = classnames('k-devicesHeader__headerCell', {
    'tab_title tab_title--unsortedSpace': isTitle,
    'k-devicesHeader__headerCell--sortable': !isOption,
  });

  return (
    <th key={key} className={headerClassName} onClick={!isOption ? onClick : undefined}>
      <FormattedMessage {...message} />
      {!isOption && <SortingArrow display={!!sort} up={sort === ASCENT} />}
    </th>
  );
};

DeviceHeaderCell.propTypes = {
  key: PropTypes.number,
  onClick: PropTypes.func,
  columnId: PropTypes.number,
  message: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  isTitle: PropTypes.bool,
  isOption: PropTypes.bool,

  checked: PropTypes.bool,
  toggle: PropTypes.func,
  isSortDecreesing: PropTypes.bool,
  isSorted: PropTypes.bool,
  sort: PropTypes.string,
};

export default DeviceHeaderCell;
