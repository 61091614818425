import { useCallback } from 'react';

export const filterLocationsColumn = (budgetMetrics) => (rows, columnIds, filterValue) =>
rows.filter((row) => {
  const dataId = row.original.id;
  const { locations } = budgetMetrics.find(({ id }) => dataId === id);

  const locationNames = locations?.map(({ name }) => name).join(' ') || '';

  return locationNames.match(new RegExp(filterValue.trim('').replaceAll(' ', '|'), 'ig'));
});

export const filterNameColumn = budgetMetrics => (rows, columnIds, filterValue) =>
  rows.filter(row => {
    const dataId = row.original.id;
    const { name } = budgetMetrics.find(({ id }) => dataId === id);

    return name.match(new RegExp(filterValue.trim()));
  });

export const customFilters = (column, budgetMetrics) => {
  switch(column.Header) {
    case 'budgetMetricsTableLocations':
      return useCallback(filterLocationsColumn(budgetMetrics));
    case 'budgetMetricsTableName':
      return useCallback(filterNameColumn(budgetMetrics));
    default:
      return null;
  }
};