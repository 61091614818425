import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { uuid4 } from '@/utils/baseHelpers.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getJobTitleIdsFromContracts, getRelevantContractsForMultipleDates } from '@/utils/contracts';
import { inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  title: {
    id: 'templates.saveScheduleAsModal.title',
    defaultMessage: 'Stwórz szablon',
  },
});

class SaveScheduleAsTemplateModal extends Component {
  static getInitialState() {
    return {
      objectName: '',
      errors: {},
    };
  }

  constructor(props) {
    super(props);
    this.state = SaveScheduleAsTemplateModal.getInitialState();
    bindPrototypeFunctions(this);
  }

  onSubmit() {
    return this.validateInput({
      target: {
        name: 'objectName',
        value: this.state.objectName,
      },
    }).then(() => {
      if (this.state.errors.objectName) {
        return;
      }
      const relevantShifts = this.getRelevantShifts();
      const mappedTemplateShifts = this.mapShifts(relevantShifts);
      this.props.addNewTemplate(this.state.objectName, mappedTemplateShifts);
      this.hideAndClear();
    });
  }

  getRelevantShifts() {
    const selectedLocationId = this.props.selectedLocations[0];
    if (!selectedLocationId) return [];
    const selectedJobTilesIds = this.props.jobtitleFilter.selectedJobtitlesGrouped.map(jobTitle => jobTitle.id);
    const searchString = this.props.scheduleUIState.searchString.toLowerCase();
    const { start: from, end: to } = this.props.mainDateStore.customDate;

    return this.props.userEmployees
      .filter(employee => {
        const employeeContracts = this.props.contracts[employee.id] || [];
        const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
        const allJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);

        return (
          employee.locations.some(l => l.id === selectedLocationId) &&
          allJobTitleIds.some(jobTitleId => selectedJobTilesIds.includes(jobTitleId)) &&
          this.props.employmentConditionsFilter.selected.some(
            condition => condition.id === employee.employment_conditions.template_id,
          ) &&
          `${employee.first_name} ${employee.last_name}`.toLowerCase().includes(searchString)
        );
      })
      .reduce((prev, employee) => prev.concat(employee.shifts), [])
      .filter(shift => shift.location.id === selectedLocationId && !shift.draft);
  }

  mapShifts(relevantShifts) {
    let mappedTemplateShifts = [];
    this.props.mainDateStore.dateArray.forEach(date => {
      let mappedShifts = [];
      const filteredShifts = relevantShifts.filter(shift => shift.date === date);
      filteredShifts.forEach(shift => {
        const relevantTemplateShiftIndex = mappedShifts.findIndex(
          mappedShift =>
            mappedShift.job_title.id === shift.job_title.id && mappedShift.working_hours === shift.working_hours,
        );
        if (!~relevantTemplateShiftIndex) {
          const templateShift = {
            id: uuid4(),
            draft: false,
            date: moment(date).day(),
            amount: 1,
            working_hours: shift.working_hours,
            job_title: shift.job_title,
          };
          mappedShifts.push(templateShift);
        } else {
          const relevantTemplateShift = mappedShifts[relevantTemplateShiftIndex];
          mappedShifts = [
            ...mappedShifts.slice(0, relevantTemplateShiftIndex),
            {
              ...relevantTemplateShift,
              amount: relevantTemplateShift.amount + 1,
            },
            ...mappedShifts.slice(relevantTemplateShiftIndex + 1),
          ];
        }
      });
      mappedTemplateShifts = mappedTemplateShifts.concat(mappedShifts);
    });
    return mappedTemplateShifts;
  }

  handleInputChange(event) {
    this.validateInput(event);
    const { target } = event;
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });
  }

  validateInput(event) {
    return new Promise(resolve => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const error = inputValidation(target.name, value);
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [target.name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  hideAndClear() {
    this.setState(SaveScheduleAsTemplateModal.getInitialState());
    this.props.hideModal();
  }

  render() {
    return (
      <MDKadroModal
        show={this.props.showModal}
        title={this.context.intl.formatMessage(messages.title, {})}
        onSubmit={this.onSubmit}
        onHide={this.hideAndClear}
        errorMessage={this.state.errorMessage && this.context.intl.formatMessage(this.state.errorMessage, {})}
        modifiers={['narrow']}
      >
        <MDTextInput
          value={this.state.objectName}
          type="text"
          onChange={this.handleInputChange}
          onBlur={this.validateInput}
          id="objectName"
          label={<FormattedMessage id="templates.saveScheduleAsModal.name" defaultMessage="Nazwa szablonu" />}
          errorMessage={this.state.errors.objectName}
          modifiers={['modal']}
        />
      </MDKadroModal>
    );
  }
}

SaveScheduleAsTemplateModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SaveScheduleAsTemplateModal.propTypes = {
  showModal: PropTypes.bool,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  locationFilter: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  jobtitleFilter: PropTypes.shape({
    selectedJobtitlesGrouped: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  scheduleUIState: PropTypes.shape({
    searchString: PropTypes.string,
  }),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  hideModal: PropTypes.func,
  addNewTemplate: PropTypes.func,
};

export default SaveScheduleAsTemplateModal;
