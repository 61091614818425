import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { PHOTO_PREVIEW_MODAL } from '@/constants/modalTypes';

import PhotoPreviewModal from './PhotoPreviewModal';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === PHOTO_PREVIEW_MODAL,
  modalObject: state.reducer.uiState.modalObject[PHOTO_PREVIEW_MODAL],
  availablePhotos: state.reducer.rcpPhotos.data,
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoPreviewModal);
