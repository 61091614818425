import moment from 'moment';

import { getNowTimestamp } from '@/utils/dateHelper';

import { messages } from './DashboardTeam.messages';

const getWorkStatus = breaks => {
  const onBreak = breaks.some(b => {
    const nowTimeStamp = getNowTimestamp();
    return b.start_timestamp < nowTimeStamp && (b.end_timestamp > nowTimeStamp || !b.end_timestamp);
  });
  const status = onBreak ? 'onBreak' : 'present';
  return status;
};

export const getFilteredEmployees = (attendances = [], searchText, context) =>
  attendances.reduce((attendancesList, attendance) => {
    const status = getWorkStatus(attendance.breaks);
    const statusText = context.intl.formatMessage(messages[status], {});
    const fullName = `${attendance.employee_first_name} ${attendance.employee_last_name}`;
    const entry = moment(attendance.start_timestamp).format('HH:mm');

    const searchTextLowerCase = searchText.toLowerCase();
    const shouldBeDisplayed =
      attendance.employee_first_name.toLowerCase().includes(searchTextLowerCase) ||
      attendance.employee_last_name.toLowerCase().includes(searchTextLowerCase) ||
      attendance.job_title?.title.toLowerCase().includes(searchTextLowerCase) ||
      statusText.toLowerCase().includes(searchTextLowerCase);

    return shouldBeDisplayed
      ? [...attendancesList, { ...attendance, entry, fullName, statusText, status }]
      : attendancesList;
  }, []);
