import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { KadroCalendarMultiple, KadroCalendarRange } from '@/components/common/KadroCalendars';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import { StandardDate } from '@/types/dates.types.ts';
import { convertDateToStandardFormat } from '@/utils/dateHelper.js';

import Popover from '../Popover/Popover.jsx';

const messages = defineMessages({
  placeholder: {
    id: 'common.calendarPopoverInput.placeholder',
    defaultMessage: 'Kliknij by zaznaczyć okres',
  },
});

type Props = {
  inputValue: string;
  position: string;
  selectedRange: { from: StandardDate; to: StandardDate };
  onInputClick: () => void;
  onRangeChange: (e: { from: StandardDate; to: StandardDate }) => void;
  modifiers: string | string[];
  singleDay: boolean;
  customDate: string;
  changeDate: (date: string) => void;
  label: string;
  inputEnabled: boolean;
  hidePicker: boolean;
  mainDateStore: { from: StandardDate; to: StandardDate };
  modes: number[];
  minDate: Date;
  maxDate: Date;
};

const KadroCalendarPopoverInput = ({
  onRangeChange,
  changeDate: changeDateCallback,
  inputValue,
  position,
  selectedRange,
  onInputClick,
  modifiers,
  singleDay,
  customDate,
  label,
  inputEnabled,
  hidePicker = true,
  mainDateStore,
  modes,
  minDate,
  maxDate,
}: Props) => {
  const intl = useIntl();
  const popoverRef = useRef(null);
  const onCalendarChange = useCallback(
    e => {
      popoverRef.current.hide();
      onRangeChange(e);
    },
    [onRangeChange],
  );

  const changeDate = useCallback(
    date => {
      const formattedDate = convertDateToStandardFormat(date);
      popoverRef.current.hide();
      changeDateCallback(formattedDate);
    },
    [changeDateCallback],
  );

  const modifiersList = Array.isArray(modifiers) ? modifiers : modifiers?.split(' ');
  const className = classNames(
    'k-calendarPopoverInput',
    ...(modifiersList || []).map(modifier => `k-calendarPopoverInput--${modifier}`),
  );
  const inputClassName = classNames('k-calendarPopoverInput__input form-control k-textInput', {
    'k-textInput--disabled': !inputEnabled,
  });

  return (
    <div onClick={onInputClick} className={className} role="presentation">
      <Popover
        ref={popoverRef}
        position={position}
        sideOffset={550}
        content={
          singleDay ? (
            <KadroCalendarMultiple onChange={changeDate} selected={[customDate]} />
          ) : (
            <KadroCalendarRange
              onChange={onCalendarChange}
              selectedRange={selectedRange}
              hidePicker={hidePicker}
              minDate={minDate}
              maxDate={maxDate}
              modes={modes || payrollLocationModesIds}
              mainDateStore={mainDateStore}
            />
          )
        }
      >
        <div>
          {label && <label className="k-calendarPopoverInput__label">{label}</label>}
          <input
            type="text"
            className={inputClassName}
            id="od"
            placeholder={intl.formatMessage(messages.placeholder, {})}
            value={inputValue}
            readOnly={!inputEnabled}
          />
        </div>
      </Popover>
    </div>
  );
};

export default KadroCalendarPopoverInput;
