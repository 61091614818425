import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const errorPage = (props) => {
  const number = props.number || 403;
  const text = props.text || (
    <FormattedMessage
      id="view.errorPage.defaultText"
      defaultMessage="Dostęp zabroniony"
    />
  );
  const errorClassNames = classnames('k-errorPage', props.classNames);

  return (
    <div className={errorClassNames}>
      <div className="k-errorPage__banner">
        <div className="k-errorPage__number">{number}</div>
        <div className="k-errorPage__text">
          {text}
          {props.children ? (
            props.children
          ) : (
            <div>
              <br />
              <Link to="/" style={{ fontSize: '14px', color: '#9f6329' }}>
                <FormattedMessage
                  id="view.errorPage.backToHomePage"
                  defaultMessage="Powrót do strony głównej >>"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

errorPage.propTypes = {
  number: PropTypes.number || PropTypes.string,
  text: PropTypes.node,
  classNames: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default errorPage;
