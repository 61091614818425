import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton.jsx';

import { messages } from './PayrollLocationTableOptions.messages';

const PayrollLocationTableOptions = (props, { intl }) => {
  const { redirectToPayroll, rowData, showSkeletons } = props;
  return (
    <div>
      <MDIconButton
        icon="read_more"
        label={intl.formatMessage(messages.readMore)}
        onClick={useCallback(() => redirectToPayroll(rowData.employee_id), [redirectToPayroll, rowData.employee_id])}
        disabled={showSkeletons}
      />
    </div>
  );
};

PayrollLocationTableOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollLocationTableOptions.propTypes = {
  redirectToPayroll: PropTypes.func,
  rowData: PropTypes.shape({
    employee_id: PropTypes.string,
  }),
  showSkeletons: PropTypes.bool,
};

export default memo(PayrollLocationTableOptions);
