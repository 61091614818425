import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addShift: {
    id: 'shift.addShift',
    defaultMessage: 'Dodaj zmianę',
  },
  editShift: {
    id: 'shift.editShift',
    defaultMessage: 'Edytuj zmianę',
  },
  editAbsence: {
    id: 'shift.editAbsence',
    defaultMessage: 'Edytuj absencję',
  },
  removeAttendance: {
    id: 'attendance.editModal.removeAttendance',
    defaultMessage: 'Usuń obecność',
  },
  deleteShift: {
    id: 'templates.tableRow.deleteShiftTitle',
    defaultMessage: 'Usuń zmianę',
  },
  budgetTargetsHoursLimitExceeded: {
    id: 'shift.error.budgetTargetsHoursLimitExceeded',
    defaultMessage: 'Nie udało się dodać zmiany z powodu przekroczonego targetu godzin',
  },
  budgetTargetsMoneyLimitExceeded: {
    id: 'shift.error.budgetTargetsMoneyLimitExceeded',
    defaultMessage: 'Nie udało się dodać zmiany z powodu przekroczonego targetu kosztów',
  },
  workingRulesError: {
    id: 'shift.error.workingRulesError',
    defaultMessage: 'Ta lokalizacja ma wł. blokadę edycji zmiany w związku z walidacją grafiku z Kodeksem Pracy.',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Dodaj',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edytuj',
  },
  workingHoursSingleOverlap: {
    id: 'shift.error.workingHoursOverlap',
    defaultMessage: 'Zmiany się pokrywają. Wprowadź niepokrywającą się zmianę',
  },
});
