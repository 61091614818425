import { connect } from 'react-redux';

import { toggleLoanEmployeesProposalRowStickiness } from '@/actions/schedule/stickyRows';

import LoanEmployeesProposalRowTitle from './LoanEmployeesProposalsRowTitle';

const mapStateToProps = state => ({
  isLoanEmployeesProposalsSticky: state.reducer.schedule.stickyRows.loanEmployeesProposalsSticky,
  isEmployee: state.reducer.userPermissions.isEmployee,
});

const mapDispatchToProps = {
  toggleLoanEmployeesProposalRowStickiness,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanEmployeesProposalRowTitle);
