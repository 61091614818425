import classnames from 'classnames';
import { useMemo } from 'react';
import * as React from 'react';

import Popover from '@/components/common/Popover/Popover.jsx';
import { DIRECTIONS } from '@/constants/ui.ts';
import { calculateMinutesFromMidnight } from '@/utils/dateHelper.js';

interface AttendanceBarBlockLabelProps {
  labelsNode?: React.ReactNode;
  hidePopover?: boolean;
  labelsAmount?: number;
  startTimestamp: string;
}

const AttendanceBarBlockLabel = ({
  hidePopover,
  labelsNode,
  labelsAmount,
  startTimestamp,
}: AttendanceBarBlockLabelProps) => {
  if (!labelsAmount) return null;
  const shouldHaveOffset = useMemo(() => {
    const minutesFromMidnight = calculateMinutesFromMidnight(startTimestamp);
    return minutesFromMidnight < 20;
  }, [startTimestamp]);
  const numberToDisplay = labelsAmount > 9 ? '9+' : labelsAmount;
  const className = classnames('k-attendanceBar__blockLabel', {
    'k-attendanceBar__blockLabel--withOffset': shouldHaveOffset,
  });

  const labelNode = useMemo(
    () => (
      <div className={className}>
        <img className="k-attendanceBar__blockLabelImg" src="/img/mdi_tag.svg" alt="label" />
        <span className="k-attendanceBar__blockLabelNumber">{numberToDisplay}</span>
      </div>
    ),
    [numberToDisplay, className],
  );

  if (hidePopover) return labelNode;
  return (
    <Popover showOnHover position={DIRECTIONS.TOP} showShadow={false} content={labelsNode}>
      {labelNode}
    </Popover>
  );
};

export default AttendanceBarBlockLabel;
