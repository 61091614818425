/* eslint-disable camelcase */

import { getRelevantContractForDate } from '@/utils/contracts';
import { deepCopyObject } from '@/utils/objectHelpers';
import { reduceShiftsInLocations } from '@/utils/schedule/scheduleFilters/scheduleFilters';
import { isDateWithinEmploymentPeriod } from '@/utils/schedulerHelpers';

import { checkIsShiftOverlappingWithEmployeeShifts } from './quickPlanning/quickPlanning.helpers';

export const getSortedVisibleEmployeeIds = (scheduleLocationFilter, scheduleState) => {
  const locationId = scheduleLocationFilter[0];
  const { visible, order } = scheduleState.locations[locationId];
  const sortedVisibleEmployeeIds = order.filter(id => visible.includes(id));
  return sortedVisibleEmployeeIds;
};

export const getCells = (sortedVisibleEmployeeIds, dateArray, position) => {
  const cells = [];
  const minY = Math.min(position.start.y, position.end.y);
  const maxY = Math.max(position.start.y, position.end.y);
  const minX = Math.min(position.start.x, position.end.x);
  const maxX = Math.max(position.start.x, position.end.x);

  for (let empIndex = minY; empIndex <= maxY; empIndex++) {
    for (let dateIndex = minX; dateIndex <= maxX; dateIndex++) {
      cells.push({ employeeId: sortedVisibleEmployeeIds[empIndex], date: dateArray[dateIndex] });
    }
  }
  return cells;
};

export const getNewPositionForSimpleNavigation = (direction, currentPosition, sortedVisibleEmployeeIds, dateArray) => {
  const { start } = currentPosition;
  if (['up', 'down'].includes(direction)) {
    const difference = direction === 'up' ? -1 : 1;
    const newY = start.y + difference;
    if (newY < 0 || newY >= sortedVisibleEmployeeIds.length) return { start, end: start };
    return { start: { x: start.x, y: newY }, end: { x: start.x, y: newY } };
  }
  const difference = direction === 'left' ? -1 : 1;
  const newX = start.x + difference;
  if (newX < 0 || newX >= dateArray.length) return { start, end: start };
  return { start: { x: newX, y: start.y }, end: { x: newX, y: start.y } };
};

export const getNewPositionAndTranslationForAdvancedNavigation = (
  direction,
  currentPosition,
  sortedVisibleEmployeeIds,
  dateArray,
  translationWithShift,
) => {
  const newPosition = deepCopyObject(currentPosition);
  const translation = deepCopyObject(translationWithShift);

  switch (direction) {
    case 'shift+up': {
      const rowsSelectedAmount = newPosition.end.y - newPosition.start.y;
      const newTranslationY = translation.y === 0 ? rowsSelectedAmount - 1 : translation.y - 1;
      const predictedEndY = newPosition.start.y + newTranslationY;
      newPosition.end.y = Math.max(predictedEndY, 0);
      translation.y = predictedEndY >= 0 ? newTranslationY : translation.y;
      break;
    }
    case 'shift+down': {
      const rowsSelectedAmount = newPosition.end.y - newPosition.start.y;
      const newTranslationY = translation.y === 0 ? rowsSelectedAmount + 1 : translation.y + 1;
      const predictedEndY = newPosition.start.y + newTranslationY;
      newPosition.end.y = Math.min(predictedEndY, sortedVisibleEmployeeIds.length - 1);
      translation.y = predictedEndY <= sortedVisibleEmployeeIds.length - 1 ? newTranslationY : translation.y;
      break;
    }
    case 'shift+left': {
      const columnsSelectedAmount = newPosition.end.x - newPosition.start.x;
      const newTranslationX = translation.x === 0 ? columnsSelectedAmount - 1 : translation.x - 1;
      const predictedEndX = newPosition.start.x + newTranslationX;
      newPosition.end.x = Math.max(predictedEndX, 0);
      translation.x = predictedEndX >= 0 ? newTranslationX : translation.x;
      break;
    }
    case 'shift+right': {
      const columnsSelectedAmount = newPosition.end.x - newPosition.start.x;
      const newTranslationX = translation.x === 0 ? columnsSelectedAmount + 1 : translation.x + 1;
      const predictedEndX = newPosition.start.x + newTranslationX;
      newPosition.end.x = Math.min(predictedEndX, dateArray.length - 1);
      translation.x = predictedEndX <= dateArray.length - 1 ? newTranslationX : translation.x;
      break;
    }
    default:
      break;
  }
  return { newPosition, translation };
};

export const getForSelectedColumnNewPosition = (date, sortedVisibleEmployeeIds, dateArray) => {
  const [firstEmployeeIdIndex, lastEmployeeIdIndex] = [0, sortedVisibleEmployeeIds.length - 1];
  const dateIndex = dateArray.findIndex(d => d === date);
  const newPosition = {
    start: { x: dateIndex, y: firstEmployeeIdIndex },
    end: { x: dateIndex, y: lastEmployeeIdIndex },
  };
  return newPosition;
};

export const getForSelectedRowNewPosition = (employeeId, sortedVisibleEmployeeIds, dateArray) => {
  const [firstDayIndex, lastDayIndex] = [0, dateArray.length - 1];
  const employeeIdIndex = sortedVisibleEmployeeIds.findIndex(empId => empId === employeeId);
  const newPosition = {
    start: { x: firstDayIndex, y: employeeIdIndex },
    end: { x: lastDayIndex, y: employeeIdIndex },
  };
  return newPosition;
};

export const getPositionForMouseSelection = (downItem, upItem, dateArray, sortedVisibleEmployeeIds) => {
  const dateIndexDown = dateArray.findIndex(d => d === downItem.date);
  const employeeIndexDown = sortedVisibleEmployeeIds.findIndex(empId => empId === downItem.employeeId);
  const dateIndexUp = dateArray.findIndex(d => d === upItem.date);
  const employeeIndexUp = sortedVisibleEmployeeIds.findIndex(empId => empId === upItem.employeeId);
  const position = {
    start: { x: Math.min(dateIndexDown, dateIndexUp), y: Math.min(employeeIndexDown, employeeIndexUp) },
    end: { x: Math.max(dateIndexDown, dateIndexUp), y: Math.max(employeeIndexDown, employeeIndexUp) },
  };
  return position;
};

export const getDatesRanges = (selected, dateArray, isCopiedOnlyNotSelectedCell, borderCell) => {
  const selectedToCopy = isCopiedOnlyNotSelectedCell
    ? { [borderCell.employeeId]: { [borderCell.date]: true } }
    : selected;
  const sortedDatesSelected = Object.values(selectedToCopy)
    .flatMap(e => Object.keys(e))
    .sort();
  const [minDate, maxDate] = [sortedDatesSelected[0], sortedDatesSelected[sortedDatesSelected.length - 1]];
  const minDateIndex = dateArray.findIndex(date => date === minDate);
  const maxDateIndex = dateArray.findIndex(date => date === maxDate);
  return { minDateIndex, maxDateIndex, minDate, maxDate };
};

export const getEmployeesRanges = (selected, sortedVisibleEmployeeIds, isCopiedOnlyNotSelectedCell, borderCell) => {
  const selectedToCopy = isCopiedOnlyNotSelectedCell
    ? { [borderCell.employeeId]: { [borderCell.date]: true } }
    : selected;
  const employeesIds = Object.keys(selectedToCopy);
  const [minEmployeeId, maxEmployeeId] = [employeesIds[0], employeesIds[employeesIds.length - 1]];
  const minEmployeeIndex = sortedVisibleEmployeeIds.findIndex(employeeId => employeeId === minEmployeeId);
  const maxEmployeeIndex = sortedVisibleEmployeeIds.findIndex(employeeId => employeeId === maxEmployeeId);
  return { minEmployeeIndex, maxEmployeeIndex };
};

export const getShiftsToCopy = (employees, selectedToCopy, shiftsData, locationIds) =>
  Object.keys(selectedToCopy).reduce((shiftsGrouppedByEmployee, employeeId) => {
    const employeesShifts = Object.keys(selectedToCopy[employeeId]).flatMap(date => {
      const shiftsIds = employees[employeeId].shifts[date] || [];
      return reduceShiftsInLocations(shiftsData, shiftsIds, locationIds, employeeId);
    });
    return employeesShifts.length
      ? { ...shiftsGrouppedByEmployee, [employeeId]: employeesShifts }
      : shiftsGrouppedByEmployee;
  }, {});

const shouldBeTopMarked = (selectedToCopy, sortedVisibleEmployeeIds, employeeId, date) => {
  const currentEmployeeIndex = sortedVisibleEmployeeIds.findIndex(empId => empId === employeeId);
  if (currentEmployeeIndex === 0) return true;
  const newEmployeeId = sortedVisibleEmployeeIds[currentEmployeeIndex - 1];
  return !selectedToCopy[newEmployeeId]?.[date];
};

const shouldBeBottomMarked = (selectedToCopy, sortedVisibleEmployeeIds, employeeId, date) => {
  const currentEmployeeIndex = sortedVisibleEmployeeIds.findIndex(empId => empId === employeeId);
  if (currentEmployeeIndex === sortedVisibleEmployeeIds.length) return true;
  const newEmployeeId = sortedVisibleEmployeeIds[currentEmployeeIndex + 1];
  return !selectedToCopy[newEmployeeId]?.[date];
};

const shouldBeLeftMarked = (selectedToCopy, dateArray, employeeId, date) => {
  const currentDateIndex = dateArray.findIndex(d => d === date);
  if (currentDateIndex === 0) return true;
  const newDate = dateArray[currentDateIndex - 1];
  return !selectedToCopy[employeeId]?.[newDate];
};

const shouldBeRightMarked = (selectedToCopy, dateArray, employeeId, date) => {
  const currentDateIndex = dateArray.findIndex(d => d === date);
  if (currentDateIndex === dateArray.length) return true;
  const newDate = dateArray[currentDateIndex + 1];
  return !selectedToCopy[employeeId]?.[newDate];
};

export const getCopiedMarkedArea = (
  selected,
  sortedVisibleEmployeeIds,
  dateArray,
  isCopiedOnlyNotSelectedCell,
  borderCell,
) => {
  const selectedToCopy = isCopiedOnlyNotSelectedCell
    ? { [borderCell.employeeId]: { [borderCell.date]: true } }
    : selected;

  return Object.entries(selectedToCopy).reduce(
    (prevSelection, [employeeId, values]) => {
      const dates = Object.keys(values);
      const [borderTop, borderBottom, borderLeft, borderRight] = [{}, {}, {}, {}];
      dates.forEach(date => {
        if (shouldBeTopMarked(selectedToCopy, sortedVisibleEmployeeIds, employeeId, date))
          borderTop[employeeId] = { ...borderTop[employeeId], [date]: true };
        if (shouldBeBottomMarked(selectedToCopy, sortedVisibleEmployeeIds, employeeId, date))
          borderBottom[employeeId] = { ...borderBottom[employeeId], [date]: true };
        if (shouldBeLeftMarked(selectedToCopy, dateArray, employeeId, date))
          borderLeft[employeeId] = { ...borderLeft[employeeId], [date]: true };
        if (shouldBeRightMarked(selectedToCopy, dateArray, employeeId, date))
          borderRight[employeeId] = { ...borderRight[employeeId], [date]: true };
      });
      return {
        borderTop: { ...prevSelection.borderTop, ...borderTop },
        borderBottom: { ...prevSelection.borderBottom, ...borderBottom },
        borderLeft: { ...prevSelection.borderLeft, ...borderLeft },
        borderRight: { ...prevSelection.borderRight, ...borderRight },
      };
    },
    { borderTop: {}, borderBottom: {}, borderLeft: {}, borderRight: {} },
  );
};

export const getShiftsToDelete = (selected, employees, shiftsData, locationIds) => {
  const shiftsIds = [];
  Object.entries(selected).forEach(([employeeId, values]) => {
    const dates = Object.keys(values);
    dates.forEach(date => {
      const shiftsIdsForDate = employees[employeeId].shifts[date] || [];
      const relevantShifts = reduceShiftsInLocations(shiftsData, shiftsIdsForDate, locationIds, employeeId);
      shiftsIds.push(...relevantShifts.map(s => s.id));
    });
  });
  return shiftsIds;
};

export const getShiftsIdsForPosition = (borderCell, employees) => {
  const { employeeId, date } = borderCell;
  return employees[employeeId]?.shifts[date] || [];
};

export const getSelectedWithBorderCell = (selected, borderCell) => {
  const allSelected = { ...selected };
  if (borderCell.employeeId && !Object.keys(allSelected).includes(borderCell.employeeId))
    allSelected[borderCell.employeeId] = { [borderCell.date]: true };
  return allSelected;
};

export const getNewShiftsToAdd = (workingHours, selected, borderCell, locationId, allShifts, contracts, jobTitles) => {
  const allSelected = getSelectedWithBorderCell(selected, borderCell);
  return Object.entries(allSelected).reduce((shifts, [employeeId, employeeData]) => {
    const employeeShifts = [];
    Object.keys(employeeData).forEach(date => {
      const relevantContract = getRelevantContractForDate(contracts[employeeId] || [], date);
      const relevantJobTitleId = relevantContract?.job_titles[0]?.job_title_id;
      const relevantJobTitle = jobTitles.find(({ id }) => id === relevantJobTitleId);

      const newShift = {
        date,
        employee: { id: employeeId },
        job_title: relevantJobTitle,
        comment: '',
        working_hours: workingHours,
        draft: true,
        location: { id: locationId },
      };
      if (!checkIsShiftOverlappingWithEmployeeShifts(allShifts, employeeId, date, newShift))
        employeeShifts.push(newShift);
    });
    return [...shifts, ...employeeShifts];
  }, []);
};

export const getEmployeeAndDateFromPosition = (sortedVisibleEmployeeIds, dateArray, userEmployees, position) => {
  const employeeId = sortedVisibleEmployeeIds[position.start.y];

  const date = dateArray[position.start.x];

  const employee = userEmployees.find(({ id }) => id === employeeId);
  return { employee, date };
};

export const isPositionWithinEmploymentPeriod = (sortedVisibleEmployeeIds, dateArray, userEmployees, position) => {
  const { employee, date } = getEmployeeAndDateFromPosition(
    sortedVisibleEmployeeIds,
    dateArray,
    userEmployees,
    position,
  );

  const employmentConditions = employee.employment_conditions;

  const { hire_date: hireDate, release_date: releaseDate } = employmentConditions;

  const isWithinHireAndReleaseDate = isDateWithinEmploymentPeriod(date, hireDate, releaseDate);

  return isWithinHireAndReleaseDate;
};

export const getInitPosition = (sortedVisibleEmployeeIds, userEmployees, dateArray) => {
  let initialPostion = {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
  };
  // The list includes employees with at least one working day in the selected period

  for (let i = 0; i <= dateArray.length - 1; i++) {
    if (!isPositionWithinEmploymentPeriod(sortedVisibleEmployeeIds, dateArray, userEmployees, initialPostion)) {
      initialPostion = {
        ...initialPostion,
        start: { ...initialPostion.start, x: initialPostion.start.x + 1 },
        end: { ...initialPostion.end, x: initialPostion.end.x + 1 },
      };
    }
  }

  return initialPostion;
};
