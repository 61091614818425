import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import FiltersModal from '@/components/common/FiltersModal/FiltersModal';
import { employmentConditionsFilterOptions } from '@/constants/employmentConditions';

const PayrollLocationFiltersModal = ({
  isOpen,
  mainDateStore,
  locations,
  jobTitles,
  employmentConditions,
  changeMultipleLocationFilter,
  changeJobTitleFilter,
  changeEmploymentConditionsFilter,
  hideModal,
  selectedLocations,
  selectedJobTitles,
  selectedEmploymentConditions,
  isConfirmButtonDisabled,
  getPayrollLocationViewData,
  mainDateChangeModeAndDate,
}) => {
  const filters = useMemo(
    () => ({
      selectedLocations,
      selectedJobTitles: selectedJobTitles.map(jobTitle => jobTitle.id),
      selectedEmploymentConditions: selectedEmploymentConditions.map(condition => condition.id),
    }),
    [selectedLocations, selectedJobTitles, selectedEmploymentConditions],
  );
  const handleEditEmploymentConditions = useCallback(
    newItems => {
      const newEmploymentConditions = newItems
        .filter(({ active }) => active)
        .map(({ value }) =>
          [...employmentConditionsFilterOptions, ...employmentConditions].find(condition => condition.id === value),
        );
      changeEmploymentConditionsFilter(newEmploymentConditions);
    },
    [employmentConditions],
  );
  const handleEditJobTitles = useCallback(
    newItems => {
      const newJobTitles = newItems
        .filter(({ active }) => active)
        .map(({ value }) => jobTitles.find(jobTitle => jobTitle.id === value));
      changeJobTitleFilter(newJobTitles);
    },
    [jobTitles],
  );

  const handleEditLocations = useCallback(
    newItems => {
      const newLocations = newItems.filter(({ active }) => active).map(({ value }) => value);
      changeMultipleLocationFilter(newLocations);
    },
    [locations],
  );

  return (
    <FiltersModal
      isOpen={isOpen}
      handleSubmit={getPayrollLocationViewData}
      locations={locations}
      jobTitles={jobTitles}
      employmentConditions={employmentConditions}
      changeLocationsFilter={handleEditLocations}
      changeJobTitlesFilter={handleEditJobTitles}
      changeEmploymentConditionsFilter={handleEditEmploymentConditions}
      hideModal={hideModal}
      filters={filters}
      mainDateStore={mainDateStore}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      mainDateChangeModeAndDate={mainDateChangeModeAndDate}
    />
  );
};

PayrollLocationFiltersModal.propTypes = {
  isOpen: PropTypes.bool,
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateChangeModeAndDate: PropTypes.func,
  changeMultipleLocationFilter: PropTypes.func,
  changeJobTitleFilter: PropTypes.func,
  changeEmploymentConditionsFilter: PropTypes.func,
  hideModal: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.string),
  selectedEmploymentConditions: PropTypes.arrayOf(PropTypes.string),
  reportsDate: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  isConfirmButtonDisabled: PropTypes.bool,
  getPayrollLocationViewData: PropTypes.func,
};

export default PayrollLocationFiltersModal;
