import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

import { convertDateToCustomFormat } from '@/utils/dateHelper';
import { createEvent } from '@/utils/inputHelpers';

import { KadroCalendarMultiple } from '../../KadroCalendars';
import Popover from '../../Popover/Popover.jsx';
import MDTextInput from '../MDTextInput/MDTextInput.jsx';
import { getDates } from './MDDatesPicker.helpers';

const MDDatesPicker = props => {
  const {
    label,
    values = [],
    id,
    singleDate,
    handleInputChange,
    tooltip,
    minDate,
    maxDate,
    disabled,
    disabledDates,
    position = 'top',
    customDisplayValue,
    readOnly,
    className,
    tooltipClassName,
    maxLabelLength,
  } = props;
  const popoverRef = useRef(null);

  const onChange = useCallback(
    date => {
      const dates = getDates(date, values, singleDate);
      handleInputChange(createEvent(id, dates));
      popoverRef.current.hide();
    },
    [values],
  );

  return (
    <Popover
      disabled={disabled || readOnly}
      ref={popoverRef}
      position={position}
      yOffset={position === 'top' ? 50 : -10}
      popoverStyle={{ padding: 0, border: 'none' }}
      content={<KadroCalendarMultiple {...{ onChange, minDate, maxDate, disabledDates }} selected={values} />}
    >
      <div className="k-MDDatesPicker">
        <MDTextInput
          label={label}
          value={customDisplayValue || values.map(v => convertDateToCustomFormat(v, 'DD.MM.YYYY')).join(', ')}
          modifiers={['modal']}
          tooltip={tooltip}
          disabled={disabled}
          errorMessage={props.errorMessage}
          readOnly={readOnly}
          className={className}
          tooltipClassName={tooltipClassName}
          maxLabelLength={maxLabelLength}
        />
      </div>
    </Popover>
  );
};

MDDatesPicker.propTypes = {
  singleDate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  values: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  handleInputChange: PropTypes.func,
  tooltip: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disabled: PropTypes.bool,
  disabledDates: PropTypes.arrayOf(PropTypes.shape({})),
  position: PropTypes.string,
  customDisplayValue: PropTypes.string,
  errorMessage: PropTypes.string,
  iconType: PropTypes.string,
  iconName: PropTypes.string,
  iconClassNames: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  maxLabelLength: PropTypes.number,
};

export default MDDatesPicker;
