import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'position.addModal.title',
    defaultMessage: 'Dodaj zmiany',
  },
  header: {
    id: 'position.addModal.header',
    defaultMessage: 'Dodaj zmianę dla {jobTitle}',
  },
  employees: {
    id: 'position.addModal.employees',
    defaultMessage: 'Pracownicy',
  },
  selectAllMessage: {
    id: 'position.addModal.selectAllButton',
    defaultMessage: 'Wszyscy',
  },
  deselectAllMessage: {
    id: 'position.addModal.deselectAllButton',
    defaultMessage: 'Żaden',
  },
  chooseEmployees: {
    id: 'position.requestModal.chooseEmployee',
    defaultMessage: 'Wybierz pracowników',
  },
  time: {
    id: 'position.addModal.workingHours',
    defaultMessage: 'Czas',
  },
  selectedEmployees: {
    id: 'position.addModal.selectedEmployees',
    defaultMessage: 'Wybrani Pracownicy',
  },
  selectedEmployeesMissing: {
    id: 'position.addModal.selectedEmployeesMissing',
    defaultMessage: 'Nie wybrano żadnego pracownika',
  },
  note: {
    id: 'position.addModal.note',
    defaultMessage: 'Notatka',
  },
  notePlaceholder: {
    id: 'position.addModal.noteText',
    defaultMessage: 'Treść notatki',
  },
  repeatShiftErrorOverlap: {
    id: 'position.addModal.repeatShiftErrorOverlap',
    defaultMessage:
      'Powtarzające się zmiany nachodzą na inne zmiany dla {numberOfEmployee, plural, one {1 pracownika} other {{numberOfEmployee} pracowników}}.',
  },
});
