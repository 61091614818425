import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { ADD_EVENT_MODAL, EDIT_EVENT_MODAL } from '@/constants/modalTypes';
import { truncateText } from '@/utils/stringHelpers';

import ScheduleShiftBlock from '../ScheduleShiftBlock/ScheduleShiftBlock.jsx';

const ScheduleEvent = ({
  event,
  size,
  deleteUserEvent,
  userLocations,
  showModal,
  date,
  isEmployee,
  multipleLocationsView,
}) => {
  const { hours, type, description } = event;
  const [from, to] = hours.split('-');
  const title =
    size === 'week' && !multipleLocationsView
      ? truncateText(description)
      : userLocations.find(loc => loc.id === event.location.id)?.name;
  const onEdit = () => showModal(EDIT_EVENT_MODAL, event);
  const onAdd = e => {
    e.stopPropagation();
    showModal(ADD_EVENT_MODAL, { date });
  };
  const onDelete = e => {
    e.stopPropagation();
    deleteUserEvent(event);
  };
  return (
    <HoverPopOver
      name="eventblock"
      popover={{
        title: <FormattedMessage id="schedule.events.popupHeader" defaultMessage="Opis" />,
        content: description,
      }}
    >
      <div>
        <ScheduleShiftBlock
          from={from}
          to={to}
          color={type === 'important' ? '#fd6060' : '#FFC124'}
          size={size}
          showNoteIcon={type === 'note'}
          showImportantNoteIcon={type === 'important'}
          title={title}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          onClick={onEdit}
          isEmployee={isEmployee}
        />
      </div>
    </HoverPopOver>
  );
};

ScheduleEvent.propTypes = {
  size: PropTypes.string,
  deleteUserEvent: PropTypes.func,
  userLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  event: PropTypes.shape({
    hours: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  date: PropTypes.string,
  showModal: PropTypes.func,
  isEmployee: PropTypes.bool,
  multipleLocationsView: PropTypes.bool,
};

export default ScheduleEvent;
