import { connect } from 'react-redux';

import { getSalesReport,getWorkTimeReport } from '@/actions/reports.js';

import ReportsTopBar from './ReportsTopBar.jsx';

const mapStateToProps = ({ reducer }) => ({
  isButtonDisabled:
    reducer.reports.filters.selectedLocations.length === 0 ||
    reducer.reports.filters.selectedJobTitles.length === 0 ||
    reducer.reports.filters.selectedEmploymentConditions.length === 0 ||
    reducer.reports.filters.isButtonDisabled,
});

const mapDispatchToProps = {
  getWorkTimeReport,
  getSalesReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTopBar);
