import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './ActionsButton.scss';

class ActionsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
    };

    this.setFocus = this.setFocus.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.blurHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.blurHandler);
  }

  setFocus() {
    this.setState(prev => ({
      isFocused: !prev.isFocused,
    }));
  }

  hide() {
    this.setState({ isFocused: false });
  }

  blurHandler(e) {
    const relevantRef = this.containerRef;

    if (relevantRef && !relevantRef.contains(e.target)) {
      setTimeout(() => {
        this.setState({
          isFocused: false,
        });
      }, 200);
    }
  }

  render() {
    const className = classnames('k-actionsButton', {
      'k-actionsButton--asTopbarButton': this.props.asTopbarButton,
      'k-actionsButton--disabled': this.props.disabled,
      'k-actionsButton--focused': this.state.isFocused,
      'k-actionsButton--asIconButton': this.props.asIconButton,
    });

    return (
      <div
        className={className}
        ref={el => {
          this.containerRef = el;
        }}
      >
        <button
          type="button"
          className="k-actionsButton__button"
          onClick={this.setFocus}
          onBlur={this.blurHandler}
          tabIndex={0}
          data-test={`${this.props.testId}-actionButton`}
        >
          {this.props.icon}
          {this.props.buttonTitle}
        </button>
        <div
          className="k-actionsButton__list"
          data-test={`${this.props.testId}-list`}
          role="option"
          aria-selected={false}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

ActionsButton.defaultProps = {
  buttonTitle: <FormattedMessage id="schedule.actionsButton.title" defaultMessage="Opcje" />,
};

ActionsButton.propTypes = {
  asTopbarButton: PropTypes.bool,
  buttonTitle: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
  asIconButton: PropTypes.bool,
  testId: PropTypes.string,
};

export default ActionsButton;
