import moment from 'moment';
import PropTypes from 'prop-types';

import Icon from '@/components/common/Basic/Icon/Icon';
import { INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL } from '@/constants/ActionTypes';
import { MappedLoanEmployeesProposal } from '@/types/loanEmployeesProposals';

import { messages } from './loanEmployeesProposalItem.messages';

import './loanEmployeesProposalItem.scss';

interface LoanEmployeesProposalItemProps {
  proposal: MappedLoanEmployeesProposal;
  showModal: (modalName: string, data: { data: MappedLoanEmployeesProposal; skipLocationFilter: boolean }) => void;
}

export const LoanEmployeesProposalItem = ({ proposal, showModal }: LoanEmployeesProposalItemProps, { intl }) => {
  const { first_name: firstName, last_name: lastName } = proposal.created_by;
  const startDate = moment(proposal.start_timestamp).format('YYYY-MM-DD');

  const createdBy = `${firstName} ${lastName}`;

  const handleClick = () => {
    showModal(INCOMING_LOAN_EMPLOYEES_PROPOSAL_MODAL, { data: proposal, skipLocationFilter: true });
  };
  return (
    <div className="k-loanEmployeesProposalItem">
      <div className="k-loanEmployeesProposalItem__info">
        <div className="k-loanEmployeesProposalItem__user">{createdBy}</div>
        <div className="k-loanEmployeesProposalItem__desc">
          <span className="k-loanEmployeesProposalItem__item">
            {intl.formatMessage(messages.loanEmployeesProposalItemLocationName, {
              locationName: proposal.location.name,
            })}
          </span>
          <span className="k-loanEmployeesProposalItem__item">{startDate}</span>
          <span className="k-loanEmployeesProposalItem__item">
            {intl.formatMessage(messages.loanEmployeesProposalItemJobTitleAndEmployee, {
              jobTitle: proposal.jobTitle,
              numberOfEmployee: proposal.employees_remaining,
            })}
          </span>
        </div>
      </div>
      <div className="k-loanEmployeesProposalItem__footer">
        <Icon type="material" name="login" className="k-loanEmployeesProposalItem__icon" />
        <button onClick={handleClick} className="k-loanEmployeesProposalItem__link">
          {intl.formatMessage(messages.readMore)}
        </button>
      </div>
    </div>
  );
};

LoanEmployeesProposalItem.contextTypes = {
  intl: PropTypes.shape({}),
};

export default LoanEmployeesProposalItem;
