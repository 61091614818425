import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Portal } from 'react-portal';

import ModalContent from './ModalContent/ModalContent.jsx';
import ModalFooter from './ModalFooter/ModalFooter.jsx';
import ModalHeader from './ModalHeader/ModalHeader.jsx';
import ModalLoading from './ModalLoading/ModalLoading.jsx';
import ModalSidebar from './ModalSidebar/ModalSidebar.jsx';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './Modal.scss';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      animate: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.setState({
          show: true,
        });
        if (this.props.onHideEnd) this.props.onHideEnd(false);
        return;
      }

      this.setState({
        animate: true,
      });
      setTimeout(() => {
        this.setState({
          animate: false,
          show: false,
        });
        if (this.props.onHideEnd) this.props.onHideEnd(true);
      }, 400);
    }
  }

  hideModal() {
    this.props.onHide();
  }

  render() {
    const modalClassnames = classnames('mdModal', this.props.className);

    const containerClassnames = classnames(
      'mdModal__container',
      ...this.props.modifiers.map(modifier => `mdModal__container--${modifier}`),
      {
        'mdModal__container--enter': !this.state.animate,
        'mdModal__container--exit': this.state.animate,
        'mdModal__container--scrollContent': this.props.withOverlayScrollContent,
      },
    );
    const overlayClassName = classnames(
      'k-overlay-scrollbar',
      ...this.props.modifiers.map(modifier => `mdModal__container--${modifier}`),
    );
    return (
      this.state.show && (
        <Portal isOpened>
          <div className={modalClassnames}>
            <div className="mdModal__shadow" onClick={this.hideModal} />
            <div className={containerClassnames} style={this.props.modalStyle}>
              {this.props.loading && <ModalLoading />}
              {this.props.withoutOverlayScroll ? (
                <div className="without-overlay-scrollbar-wrapper">{this.props.children}</div>
              ) : (
                <OverlayScrollbarsComponent className={overlayClassName}>
                  {this.props.children}
                </OverlayScrollbarsComponent>
              )}
            </div>
          </div>
        </Portal>
      )
    );
  }
}

Modal.defaultProps = {
  modifiers: [],
  withoutOverlayScroll: false,
};

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  withoutOverlayScroll: PropTypes.bool,
  loading: PropTypes.bool,
  onHideEnd: PropTypes.func,
  withOverlayScrollContent: PropTypes.bool,
};

Modal.Sidebar = ModalSidebar;
Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
