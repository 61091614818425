import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { mapQuotasToJobtitles } from '@/utils/quotasHelpers.js';

import ProductionQuotasTable from './ProductionQuotasTable.jsx';
import ProductionQuotasTableRow from './ProductionQuotasTableRow.jsx';

class ProductionQuotasTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userJobTitles: mapQuotasToJobtitles(
        this.props.userEmployees,
        this.props.userJobTitles,
        this.props.mainDateStore,
        this.props.userProductionQuotas,
        this.props.locationFilter.selectedLocation.id,
      ),
    };
    this.onQuotaChange = this.onQuotaChange.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
    this.props.mainDateChangeMode('day');
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      userJobTitles: mapQuotasToJobtitles(
        nextProps.userEmployees,
        nextProps.userJobTitles,
        nextProps.mainDateStore,
        nextProps.userProductionQuotas,
        nextProps.locationFilter.selectedLocation.id,
      ),
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }

  onQuotaChange(amountsObj) {
    this.props.changeProductionQuotas(
      this.props.locationFilter.selectedLocation.id,
      this.props.mainDateStore.dateArray[0],
      amountsObj,
    );
  }

  render() {
    const colgroup = (
      <colgroup>
        <col style={{ width: '20%' }} />
        <col style={{ width: '11%' }} />
        <col style={{ width: '13%' }} />
        <col style={{ width: '13%' }} />
        <col style={{ width: '13%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
    );
    if (
      this.props.settings.locationSettings &&
      this.props.settings.locationSettings[this.props.locationFilter.selectedLocation.id] &&
      !this.props.settings.locationSettings[this.props.locationFilter.selectedLocation.id]
        .enable_usage_production_quotas_for_payroll_calculation
    ) {
      return (
        <ProductionQuotasTable colgroup={colgroup}>
          <tr>
            <td className="col_name k-pquotas__empty-row" colSpan="24">
              <FormattedMessage
                id="productionQuotas.tableView.productionQuotasNotSet"
                defaultMessage="Ta lokalizacja nie ma włączonych prac akordowych"
              />
            </td>
          </tr>
        </ProductionQuotasTable>
      );
    }
    const relevantJobTitles = this.state.userJobTitles.filter(jobTitle => parseFloat(jobTitle.factor) > 0);
    let viewToRender;
    if (!relevantJobTitles.length) {
      viewToRender = (
        <tr>
          <td className="col_name k-pquotas__empty-row" colSpan="24">
            <FormattedMessage
              id="productionQuotas.tableView.empty"
              defaultMessage="Brak stanowisk z ustawionym mnożnikiem."
            />
          </td>
        </tr>
      );
    } else {
      viewToRender = relevantJobTitles.map(jobTitle => {
        const relevantEmployees = this.props.userEmployees.reduce((prev, employee) => {
          const dateArray =
            this.props.mainDateStore.dateMode === 'month' ? jobTitle.relevantDates : this.props.mainDateStore.dateArray;
          const employeeAttendancesData = this.props.attendancesData[employee.id] || [];
          const employeeRelevantAttendances = employeeAttendancesData.filter(
            attendance =>
              attendance.matching_shift_id &&
              attendance.location.id === this.props.locationFilter.selectedLocation.id &&
              attendance.matching_shift_job_title.id === jobTitle.id &&
              dateArray.includes(attendance.date) &&
              attendance.end_timestamp,
          );
          if (employeeRelevantAttendances.length) {
            return prev.concat({
              ...employee,
              attendances: employeeRelevantAttendances,
            });
          }
          return prev;
        }, []);

        return (
          <ProductionQuotasTableRow
            key={jobTitle.id}
            jobTitle={jobTitle}
            relevantEmployees={relevantEmployees}
            onQuotaChange={this.onQuotaChange}
            colgroup={colgroup}
            dateMode={this.props.mainDateStore.dateMode}
          />
        );
      });
    }
    return <ProductionQuotasTable colgroup={colgroup}>{viewToRender}</ProductionQuotasTable>;
  }
}

ProductionQuotasTableView.propTypes = {
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  changeProductionQuotas: PropTypes.func,
  mainDateChangeMode: PropTypes.func,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
  }),
  locationFilter: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  settings: PropTypes.shape({
    locationSettings: PropTypes.shape({}),
  }),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userProductionQuotas: PropTypes.shape({}),
};

export default ProductionQuotasTableView;
