import { FREEMIUM_HIDE_OPEN_SHIFTS, FREEMIUM_HIDE_TRADE_SHIFTS } from '@/constants/Restrictions';
import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import {
  selectAreLoanEmployeesProposalsShown,
  selectAreOpenShiftsShownForLocation,
  selectAreScheduleChartsShown,
  selectAreTradeShiftsShownForLocation,
  selectIsWeatherForecastShown,
} from '@/redux-store/schedule/viewSettings';
import { selectRestrictionsDict } from '@/redux-store/userPermissions';
import { Location } from '@/types';
import { getScheduleBlockSize } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useScheduleTableHead = (locationId: Location['id']) => {
  const dateArray = useAppSelector(selectDateArray);
  const scheduleBlockSize = getScheduleBlockSize(dateArray);
  const restrictionsDict = useAppSelector(selectRestrictionsDict);
  const isWeatherForecastShown = useAppSelector(selectIsWeatherForecastShown);
  const areChartsShown = useAppSelector(selectAreScheduleChartsShown);
  const areLoanEmployeesProposalShown = useAppSelector(selectAreLoanEmployeesProposalsShown);
  const areTradeShiftsShown = useAppSelector(selectAreTradeShiftsShownForLocation(locationId));
  const areOpenShiftsShown = useAppSelector(selectAreOpenShiftsShownForLocation(locationId));
  const isSingleDay = useAppSelector(selectIsDayDateMode);

  const freemiumHideOpenShift = restrictionsDict[FREEMIUM_HIDE_OPEN_SHIFTS];
  const freemiumHideTradeShift = restrictionsDict[FREEMIUM_HIDE_TRADE_SHIFTS];

  const isOpenShiftsShown = areOpenShiftsShown && !freemiumHideOpenShift;
  const isTradeShiftsShown = areTradeShiftsShown && !freemiumHideTradeShift;

  return {
    areChartsShown,
    areLoanEmployeesProposalShown,
    isOpenShiftsShown,
    isSingleDay,
    isTradeShiftsShown,
    isWeatherForecastShown,
    scheduleBlockSize,
  };
};
