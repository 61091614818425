import { connect } from 'react-redux';

import { addAvailability, changeAvailability, deleteAvailability } from '@/actions';
import { closeEditAvailabilityModal,openEditAvailabilityModal } from '@/actions/uiState.js';
import EditAvailabilityModal from '@/components/availability/modals/EditAvailabilityModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showEditAvailabilityModal,
  modalObject: state.reducer.uiState.editAvailabilityModalObject,
  userCustomTypes: state.reducer.userCustomTypes,
  company_id: state.reducer.currentCompany.id,
  isEmployee: state.reducer.currentUser.user.role === 'employee',
});

const mapDispatchToProps = {
  openEditAvailabilityModal,
  closeEditAvailabilityModal,
  addAvailability,
  changeAvailability,
  deleteAvailability,
};
const EditAvailabilityModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditAvailabilityModal);

export default EditAvailabilityModalContainer;
