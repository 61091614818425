import { connect } from 'react-redux';

import EmployeesMassEditSummaryStep from './EmployeesMassEditSummaryStep';

const mapStateToProps = state => ({
  selectedEmployeesIds: state.reducer.listsUi.employees.selected,
  employees: state.reducer.employees.data,
  contracts: state.reducer.contracts,
});

export default connect(mapStateToProps)(EmployeesMassEditSummaryStep);
