import { connect } from 'react-redux';

import EmployeesEditMassLocationField from './EmployeesEditMassLocationField.jsx';

const mapStateToProps = state => ({
  locations: state.reducer.userLocations,
});

const EmployeesEditMassLocationFieldContainer = connect(mapStateToProps)(EmployeesEditMassLocationField);

export default EmployeesEditMassLocationFieldContainer;
