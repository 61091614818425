import { defineMessages, IntlShape } from 'react-intl';

import { ModalFooterOption } from '@/components/common/MDKadroModal/MDKadroModal.types';
import { COLOR_PALETT_DEFAULT_COLOR } from '@/constants/colors';
import { ManagerNote, ManagerNoteToPost } from '@/redux-store/managerNotes';

import { MangerNoteModalObject } from './ManagerNoteModal.types';

export const messages = defineMessages({
  editManagerNote: {
    id: 'schedule.managerNoteModal.edit',
    defaultMessage: 'Edytuj notatkę',
  },
  addManagerNote: {
    id: 'schedule.managerNoteModal.add',
    defaultMessage: 'Dodaj notatkę',
  },
  deleteText: {
    id: 'schedule.managerNoteModal.deleteText',
    defaultMessage: 'Usuń notatkę',
  },
  deleteDescriptionText: {
    id: 'schedule.managerNoteModal.deleteDescriptionText',
    defaultMessage: 'Czy na pewno chcesz usunąć notatkę?',
  },
  deleteConfirmText: {
    id: 'schedule.managerNoteModal.deleteConfirmText',
    defaultMessage: 'Usuń',
  },
  note: {
    id: 'schedule.managerNotesModal.note',
    defaultMessage: 'Notatka',
  },
  notePlaceholder: {
    id: 'schedule.managerNotesModal.noteText',
    defaultMessage: 'Treść notatki',
  },
});

export const getInitialState = (modalObject: MangerNoteModalObject, managerNote?: ManagerNote): ManagerNoteToPost =>
  managerNote || {
    date: modalObject.date,
    location_id: modalObject.locationId,
    color: COLOR_PALETT_DEFAULT_COLOR,
    comment: '',
  };

export const getFooterOptions = (isEdited: boolean, intl: IntlShape, onDelete: () => void): ModalFooterOption[] => {
  if (!isEdited) return [];
  return [
    {
      icon: 'remove_circle_outline',
      text: intl.formatMessage(messages.deleteText, {}),
      handleClick: onDelete,
    },
  ];
};
