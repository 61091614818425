import { FormattedMessage } from 'react-intl';

import { NavigationSubRoute } from './routes.types';

export const exportsRoutes: NavigationSubRoute[] = [
  {
    title: <FormattedMessage id="topBar.exports.catalog" defaultMessage="Katalog eksportów" />,
    path: '/exports/catalog',
    restrictions: [],
  },
  {
    title: <FormattedMessage id="topBar.exports.history" defaultMessage="Historia" />,
    path: '/exports/history',
    restrictions: [],
  },
];
