import { GET_DASHBOARD_FAILURE, GET_DASHBOARD_SUCCESSFUL, SET_DASHBOARD_SORTING } from '@/constants/ActionTypes';

const initialState = {
  summary: {},
  locationSummary: {},
  currentAttendances: [],
  dashboardError: false,
  locations: {
    sortColumn: '',
    sortDecreasing: false,
  },
  team: {
    sortColumn: '',
    sortDecreasing: false,
  },
};

const dashboardData = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUCCESSFUL:
      return { ...state, ...action.payload, dashboardError: false };
    case GET_DASHBOARD_FAILURE:
      return { ...initialState, dashboardError: true };
    case SET_DASHBOARD_SORTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default dashboardData;
