import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation } from 'react-router';

import ShadowLoader from '@/components/misc/ShadowLoader/ShadowLoader.redux';
import UnauthenticatedLayout from '@/components/misc/UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import browserHistory from '@/constants/browserHistory';
import { onboardingCases } from '@/constants/onboarding.js';

import OnboardingShowcase from '../OnboardingShowcase/OnboardingShowcase.jsx';

const OnboardingStepLayout = ({ registerWithGoogleOAuth, createOwner, deviceInfo }, { intl }) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    companyName: '',
    industry: '',
    companySize: '',
    purposes: [],
  });
  const location = useLocation();
  const initialLoginWithOauth = location.state?.initialLoginWithOauth;

  const saveStepData = useCallback(
    async (data, nextRoute) => {
      if (nextRoute !== 'finish') {
        setFormData({
          ...formData,
          ...data,
        });
        browserHistory.push(`/onboarding/${nextRoute}`, {
          initialLoginWithOauth,
        });
      } else if (initialLoginWithOauth) {
        const { companyName, industry, companySize, purposes } = formData;
        const { phone: phoneFromForm, phone_prefix } = data;
        const phone = phoneFromForm ? phoneFromForm.replace(/\s/g, '') : undefined;
        registerWithGoogleOAuth({
          company_size: companySize,
          industry,
          company_name: companyName,
          purpose: purposes,
          phone,
          phone_prefix,
        });

        browserHistory.push('/');
      } else {
        const { email, firstName, lastName, password, companyName, industry, companySize, purposes } = formData;
        const { phone: phoneFromForm, recaptcha, discountCode, phone_prefix } = data;
        const phone = phoneFromForm ? phoneFromForm.replace(/\s/g, '') : undefined;

        await createOwner({
          email,
          first_name: firstName,
          last_name: lastName,
          password,
          company_name: companyName,
          industry,
          company_size: companySize,
          phone,
          discount_code: discountCode,
          recaptcha,
          purposes,
          phone_prefix,
        });

        browserHistory.push('/trial');
      }
    },
    [formData, initialLoginWithOauth, registerWithGoogleOAuth, createOwner, setFormData],
  );

  const locationName = window.location.pathname.split('onboarding/')[1];
  const stepsClassnames = classnames('k-onboardingStepLayout__progressContainer', {
    'k-onboardingStepLayout__progressContainer--stepOne': locationName === 'stepOne',
    'k-onboardingStepLayout__progressContainer--stepTwo': locationName === 'stepTwo',
    'k-onboardingStepLayout__progressContainer--stepThree': locationName === 'stepThree',
  });
  const hideLogo = deviceInfo.windowSize.height < 900 && deviceInfo.windowSize.width > 900;
  const onboardingCase = onboardingCases[locationName];
  const caseClassName = `k-onboardingShowcase--${locationName}`;
  const showcaseData = {
    ...onboardingCase,
    title: intl.formatMessage(onboardingCase.title, {}),
    headline: intl.formatMessage(onboardingCase.headline, {}),
  };
  return (
    <UnauthenticatedLayout
      hideLogo={hideLogo}
      showcase={<OnboardingShowcase {...showcaseData} className={caseClassName} />}
    >
      <ShadowLoader />
      <div className="k-onboardingStepLayout__welcomeMessage">
        <FormattedMessage
          id="onboarding.layout"
          defaultMessage="Witaj w {highlight}"
          values={{
            highlight: <span className="k-onboardingStepLayout__welcomeHighlight">Kadromierz</span>,
          }}
        />
      </div>
      <Outlet context={[saveStepData, formData]} />
      <div className={stepsClassnames}>
        <div className="k-onboardingStepLayout__steps">
          <div className="k-onboardingStepLayout__step">1/3</div>
          <div className="k-onboardingStepLayout__step">2/3</div>
          <div className="k-onboardingStepLayout__step">3/3</div>
        </div>
      </div>
    </UnauthenticatedLayout>
  );
};

OnboardingStepLayout.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OnboardingStepLayout.propTypes = {
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  createOwner: PropTypes.func,
  registerWithGoogleOAuth: PropTypes.func,
};

export default OnboardingStepLayout;
