import { connect } from 'react-redux';

import { endAttendance, endBreak,startAttendance, startBreak } from '@/actions/attendances';
import { showModal } from '@/actions/uiState';

import DashboardTimeTracker from './DashboardTimeTracker.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser.user,
  locationSettings: state.reducer.settings.locationSettings,
  rcpData: state.reducer.rcp,
});

const mapDispatchToProps = {
  showModal,
  startAttendance,
  endAttendance,
  startBreak,
  endBreak,
};
const DashboardTimeTrackerContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardTimeTracker);

export default DashboardTimeTrackerContainer;
