import { connect } from 'react-redux';

import { addDevice, checkDevice } from '@/actions/companymanage/devices.jsx';
import { hideModal } from '@/actions/uiState';
import { ADD_DEVICE_MODAL } from '@/constants/modalTypes.js';

import AddDeviceModal from './AddDeviceModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ADD_DEVICE_MODAL,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
  addDevice,
  checkDevice,
};

const AddDeviceModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);

export default AddDeviceModalContainer;
