import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton';
import Icon from '@/components/common/Basic/Icon/Icon';
import ToggleSettingButton from '@/components/common/ToggleSettingButton/ToggleSettingButton';
import { DATE_MODE_WEEK } from '@/constants/dateModes';
import { AttendanceSetting } from '@/types/attendances.types.ts';

import { messages } from './attendancesSettingsButton.messages.ts';
import { AttendancesSettingsButtonProps } from './AttendancesSettingsButton.redux.ts';

import './attendancesSettingsButton.scss';

const AttendancesSettingsButton: React.FC<AttendancesSettingsButtonProps> = ({
  attendancesSettings,
  toggleAttendanceSettings,
  sellectAllAttendancesSettings,
  desellectAllAttendancesSettings,
  attendancesDisplayModeType,
}) => {
  const intl = useIntl();
  const isWeekMode = attendancesDisplayModeType === DATE_MODE_WEEK;
  const onChange = useCallback<(setting: AttendanceSetting) => void>(
    (setting: AttendanceSetting) => {
      toggleAttendanceSettings(setting);
    },
    [toggleAttendanceSettings],
  );

  const numberOfActiveSettings = useMemo<number>(
    () => attendancesSettings.filter(({ value }) => value).length,
    [attendancesSettings],
  );

  const iconName: string = numberOfActiveSettings === 0 ? 'filter' : `filter_${numberOfActiveSettings}`;
  const isFocused = useMemo<boolean>(() => !attendancesSettings.every(setting => setting.value), [attendancesSettings]);
  const classNames: string = classnames('k-attendancesSettingsButton', {
    'k-attendancesSettingsButton--focused': isFocused,
  });

  const selectedAllSettings = useMemo<boolean>(
    () => attendancesSettings.every(({ value }) => value),
    [attendancesSettings],
  );

  const handleSelectAllButton = useCallback(
    () => (selectedAllSettings ? desellectAllAttendancesSettings() : sellectAllAttendancesSettings()),
    [selectedAllSettings, desellectAllAttendancesSettings, sellectAllAttendancesSettings],
  );

  return (
    <div className={classNames}>
      <ActionsButton
        icon={<Icon type="material-symbols" name={iconName} className="k-attendancesSettingsButton__icon" />}
        buttonTitle=""
      >
        {attendancesSettings.map(setting => {
          const { id } = setting;

          return <ToggleSettingButton key={id} setting={setting} onChange={onChange} />;
        })}
        {!isWeekMode && (
          <button className="k-actionsButton__element" onClick={handleSelectAllButton}>
            {intl.formatMessage(messages.sellectAll, { selectedAllSettings })}
          </button>
        )}
      </ActionsButton>
    </div>
  );
};

export default AttendancesSettingsButton;
