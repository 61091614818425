import { Component } from 'react';

import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import SearchBox from '@/components/common/SearchBox.jsx';
import Wrapper from '@/components/common/UI/Wrapper.jsx';
import { EMPLOYMENT_CONDITIONS_CREATE } from '@/constants/Permissions';
import { COMPANY_MANAGE_CONDITIONS_HIDE } from '@/constants/Restrictions';

import EmploymentConditionsButtonBar from '../EmploymentConditionsButtonBar/EmploymentConditionsButtonBar.redux.js';
import EmploymentConditionsDeleteModal from '../EmploymentConditionsDeleteModal/EmploymentConditionsDeleteModal.redux.js';
import EmploymentConditionsModal from '../EmploymentConditionsModal/EmploymentConditionsModal.redux.js';
import EmploymentConditionsTable from '../EmploymentConditionsTable/EmploymentConditionsTable.redux.js';

class EmploymentConditionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };
    this.getSearchWord = this.getSearchWord.bind(this);
    this.clearSearchWord = this.clearSearchWord.bind(this);
  }

  getSearchWord(e) {
    this.setState({ searchString: e.target.value });
  }

  clearSearchWord() {
    this.setState({ searchString: '' });
  }

  render() {
    return (
      <FeatureWrapper permission={EMPLOYMENT_CONDITIONS_CREATE} restriction={COMPANY_MANAGE_CONDITIONS_HIDE}>
        <EmploymentConditionsModal />
        <EmploymentConditionsDeleteModal />
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchString} />
        <Wrapper>
          <EmploymentConditionsButtonBar />
          <EmploymentConditionsTable searchString={this.state.searchString} clearSearchWord={this.clearSearchWord} />
        </Wrapper>
      </FeatureWrapper>
    );
  }
}

export default EmploymentConditionsView;
