import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'topBar.singleChoiceFilter.searchPlaceholder',
    defaultMessage: 'Szukaj...',
  },
});

class TopBarSearchInput extends Component {
  constructor(props, context) {
    super(props, context);
    this.clearPhrase = this.clearPhrase.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  setFocus() {
    this.searchInput.focus();
  }

  clearPhrase() {
    this.props.onChange({
      target: {
        value: '',
      },
    });
    this.searchInput.focus();
  }

  render() {
    const searchClassnames = classnames('k-topBarSearchInput', {
      'k-topBarSearchInput--filled': this.props.searchPhrase.length,
    });

    return (
      <div className={searchClassnames}>
        <i className="k-topBarSearchInput__icon k-icon k-icon-search_right" />
        <input
          type="text"
          className="k-topBarSearchInput__input"
          ref={input => {
            this.searchInput = input;
          }}
          onChange={this.props.onChange}
          placeholder={this.context.intl.formatMessage(messages.searchPlaceholder, {})}
          value={this.props.searchPhrase}
          data-test={this.props.testId}
        />
        <button className="k-topBarSearchInput__closeIcon" onClick={this.clearPhrase}>
          <i className="k-icon k-icon-close" />
        </button>
      </div>
    );
  }
}

TopBarSearchInput.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

TopBarSearchInput.propTypes = {
  searchPhrase: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  testId: PropTypes.string,
};

export default TopBarSearchInput;
