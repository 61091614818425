import classnames from 'classnames';
import PropTypes from 'prop-types';

import Popover from '@/components/common/Popover/Popover';
import { DIRECTIONS } from '@/constants/ui.ts';

const PayrollSettingsModalSettingRow = (
  { label, tooltip, settingInput, disabled, alignLabelToTop, isForSelect },
  { intl },
) => {
  const className = classnames('k-payrollSettingsModal__settingRow', {
    'k-payrollSettingsModal__settingRow--disabled': disabled,
  });
  const labelClassName = classnames('k-payrollSettingsModal__settingLabel', {
    'k-payrollSettingsModal__settingLabel--alignToTop': alignLabelToTop,
    'k-payrollSettingsModal__settingLabel--forSelect': isForSelect,
  });
  return (
    <div className={className}>
      <div className={labelClassName}>
        <span className="k-payrollSettingsModal__settingLabelText">{intl.formatMessage(label)}</span>
        {tooltip && (
          <Popover
            content={tooltip}
            yOffset={10}
            popoverStyle={{ fontSize: '12px', maxWidth: '300px' }}
            position={DIRECTIONS.TOP}
            showShadow={false}
            centerOnMobile
            hideOnBlur
            isDark
            noArrow
            showOnHover
            preventPopoverHide
            childExitOffsetOnHover={-10}
            popoverExitOffsetOnHover={7}
          >
            <span className="material-icons mdTooltip__icon">help</span>
          </Popover>
        )}
      </div>
      {settingInput}
    </div>
  );
};

PayrollSettingsModalSettingRow.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
PayrollSettingsModalSettingRow.propTypes = {
  label: PropTypes.shape({ id: PropTypes.string, defaultMessage: PropTypes.string }).isRequired,
  tooltip: PropTypes.node,
  settingInput: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  alignLabelToTop: PropTypes.bool,
};

export default PayrollSettingsModalSettingRow;
