import { defineMessages } from 'react-intl';

export default defineMessages({
  scheduleTableOpenShiftTitle: {
    id: 'scheduleTable.openShift.title',
    defaultMessage: 'Otwarte zmiany',
  },
  scheduleTableOpenShiftDeleteTitle: {
    id: 'scheduleTable.openShift.delete.title',
    defaultMessage: 'Usuń zmianę',
  },
  scheduleTableOpenShiftDeleteDescription: {
    id: 'scheduleTable.openShift.delete.description',
    defaultMessage: 'Czy na pewno chcesz usunąć zmianę?',
  },
  scheduleTableOpenShiftDeleteButton: {
    id: 'scheduleTable.openShift.delete.button',
    defaultMessage: 'Usuń',
  },
});
