/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import TopBarTemplatesFilter from '@/components/TopBars/filters/TopBarTemplatesFilter/TopBarTemplatesFilter.redux.js';

import ScheduleActionsButton from '../ScheduleActionsButton/ScheduleActionsButton.redux.js';
import ScheduleSettingsButton from '../ScheduleSettingsButton/ScheduleSettingsButton.redux.js';

const TemplatesButtonBar = props => {
  const {
    copyPaste,
    currentTemplate,
    selectedDay,
    copy,
    paste,
    checkIfCanCopy,
    checkIfCanPaste,
    scheduleSettings,
  } = props;
  const enabledCopy = copyPaste.canCopy;
  const enabledPaste = copyPaste.canPaste;

  const handleCopy = () => {
    copy();
  };
  const handlePaste = () => {
    paste();
  };

  useEffect(() => {
    checkIfCanCopy();
    checkIfCanPaste();
  }, [currentTemplate, selectedDay, copyPaste.selection]);

  return (
    <ButtonBar>
      <ScheduleActionsButton />
      <ScheduleSettingsButton scheduleSettings={scheduleSettings} />
      <TopBarTemplatesFilter />
      <Button onClick={handlePaste} modifiers="reverse-orange small" disabled={!enabledPaste}>
        <i className="material-icons">content_paste</i>
        <FormattedMessage id="paste" defaultMessage="Wklej" />
      </Button>
      <Button onClick={handleCopy} modifiers="reverse-blue small" disabled={!enabledCopy}>
        <i className="material-icons">content_copy</i>
        <FormattedMessage id="copy" defaultMessage="Kopiuj" />
      </Button>
    </ButtonBar>
  );
};

TemplatesButtonBar.propTypes = {
  copyPaste: PropTypes.shape({
    canCopy: PropTypes.bool,
    canPaste: PropTypes.bool,
    selection: PropTypes.shape({}),
  }),
  currentTemplate: PropTypes.shape({
    type: PropTypes.string,
  }),
  selectedDay: PropTypes.number,
  copy: PropTypes.func,
  paste: PropTypes.func,
  checkIfCanCopy: PropTypes.func,
  checkIfCanPaste: PropTypes.func,
  scheduleSettings: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TemplatesButtonBar;
