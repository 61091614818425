import { connect } from 'react-redux';

import {
  approveAttendances,
  createAttendance,
  createMassAttendances,
  refreshData,
  selectAllAttendances,
  setAttendanceSearchString,
  unselectAllAttendances,
} from '@/actions/attendances.js';
import { showModal, toggleExportModal } from '@/actions/uiState.js';

import AttendanceButtonBar from './AttendanceButtonBar.jsx';

const mapStateToProps = state => ({
  selectedEmployees: state.reducer.attendances.selected,
  mainDateStore: state.reducer.mainDateStore,
  locationSettings: state.reducer.settings.locationSettings,
  locationFilter: state.reducer.locationFilter,
  searchString: state.reducer.listsUi.attendance.searchString,
  restrictions: state.reducer.userPermissions.restrictions,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
});

const mapDispatchToProps = {
  showModal,
  createAttendance,
  toggleExportModal,
  selectAllAttendances,
  unselectAllAttendances,
  approveAttendances,
  setAttendanceSearchString,
  refreshData,
  createMassAttendances,
};

const AttendanceButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(AttendanceButtonBar);

export default AttendanceButtonBarContainer;
