import { connect } from 'react-redux';

import { addBonusToAttendance } from '@/actions/attendances.js';
import { setBonusValues } from '@/actions/payrollUI.js';

import PayrollLocationTableRowDetails from './PayrollLocationTableRowDetails.jsx';

const mapStateToProps = (state, props) => ({
  bonusValues: state.reducer.payrollUI.bonusValues[props.employeeId] || {},
});

const mapDispatchToProps = {
  addBonusToAttendance,
  setBonusValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationTableRowDetails);
