import PayrollTableViewContainer from './payroll/PayrollTableView.redux.js';
import TopBarPayroll from './TopBars/TopBarPayroll.jsx';

const PayrollView = () => (
  <>
    <TopBarPayroll />
    <PayrollTableViewContainer />
  </>
);

export default PayrollView;
