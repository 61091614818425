import { CHANGE_ATTENDANCES_MODE } from '@/constants/ActionTypes';
import { dateModes } from '@/constants/dateModes';

const displayMode = (state = dateModes[0], action) => {
  switch (action.type) {
    case CHANGE_ATTENDANCES_MODE:
      return action.payload;
    default:
      return state;
  }
};

export default displayMode;
