import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  attendancesTableLocation: {
    id: 'attendancesTable.location',
    defaultMessage: 'Lokalizacja',
  },
  attendancesTableEmployee: {
    id: 'attendancesTable.employee',
    defaultMessage: 'Pracownik',
  },
  attendancesTableStatus: {
    id: 'attendancesTable.status',
    defaultMessage: 'Status',
  },
  attendancesTablePosition: {
    id: 'attendancesTable.position',
    defaultMessage: 'Stanowisko',
  },
  attendancesTableStart: {
    id: 'attendancesTable.start',
    defaultMessage: 'Start',
  },
  attendancesTableEnd: {
    id: 'attendancesTable.end',
    defaultMessage: 'Koniec',
  },
  attendancesTableBreaks: {
    id: 'attendancesTable.breaks',
    defaultMessage: 'Czas przerw',
  },
  attendancesTableDuration: {
    id: 'attendancesTable.duration',
    defaultMessage: 'Czas trwania',
  },
  attendancesTableOptions: {
    id: 'attendancesTable.options',
    defaultMessage: 'Opcje',
  },
  attendancesTableAlerts: {
    id: 'attendancesTable.alerts',
    defaultMessage: 'Alerty',
  },
  attendancesTableEmptyPosition: {
    id: 'attendancesTable.emptyPosition',
    defaultMessage: 'Brak',
  },
});
