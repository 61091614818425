import { memo } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectDateArray } from '@/redux-store/mainDateStore';

export const GroupExtraRow = memo(() => {
  const dateArray = useAppSelector(selectDateArray);
  const colSpan = dateArray.length + 1;

  return (
    <tr className="k-groupedEmployees__extraTr">
      <td colSpan={colSpan} className="k-groupedEmployees__extraRow" />
    </tr>
  );
});

GroupExtraRow.displayName = 'GroupExtraRow';
