import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Icon from '@/components/common/Basic/Icon/Icon';
import EmptyState from '@/components/emptyState/EmptyState';
import IncomingLoanEmployeesProposalModal from '@/components/scheduler/loanEmployeesProposalModals/incomingLoanEmployeesProposalModal/incomingLoanEmployeesProposalModal/IncomingLoanEmployeesProposalModal.redux';
import OutgoingLoanEmployeesProposalModal from '@/components/scheduler/loanEmployeesProposalModals/outgoingLoanEmployeesProposalModal/OutgoingLoanEmployeesProposalModal.redux';
import { Employee, Location, User } from '@/types';
import { ContextWithIntl } from '@/types/intl.types';
import { UserJobTitle } from '@/types/jobTitles.types';
import { LoanEmployeesProposals } from '@/types/loanEmployeesProposals';
import { sortDateByDateAndStartTimestamp } from '@/utils/dateHelper';

import LoanEmployeesProposalItem from './loanEmployeesProposalItem/LoanEmployeesProposalItem.redux';
import { messages } from './loanEmployeesProposals.messages';

import './loanEmployeesProposalsSection.scss';

interface LoanEmployeesProposalsSectionProps {
  loanEmployeesProposals: LoanEmployeesProposals[];
  currentUser: User;
  jobTitles: { [key: string]: UserJobTitle };
  userLocations: Location[];
  userEmployees: Employee[];
}

const LoanEmployeesProposalsSection = (
  { loanEmployeesProposals, currentUser, jobTitles, userLocations, userEmployees }: LoanEmployeesProposalsSectionProps,
  { intl }: ContextWithIntl,
) => {
  const userEmployeeIds = useMemo(() => userEmployees.map(({ id }) => id), [userEmployees]);

  const filteredProposals = useMemo(
    () =>
      loanEmployeesProposals.reduce((acc, proposal) => {
        const selectedEmployeeIds = Object.values(proposal.selected_employees).map(({ id }) => id);
        const isProposalValid =
          Boolean(jobTitles[proposal.job_title_id]?.title) &&
          selectedEmployeeIds.some(id => userEmployeeIds.includes(id));

        if (isProposalValid) {
          acc.push({
            ...proposal,
            jobTitle: jobTitles[proposal.job_title_id].title,
            date: moment(proposal.start_timestamp).format('YYYY-MM-DD'),
          });
        }
        return acc;
      }, []),
    [loanEmployeesProposals, currentUser.id, jobTitles, userLocations, userEmployeeIds],
  );

  return (
    <>
      <IncomingLoanEmployeesProposalModal />
      <OutgoingLoanEmployeesProposalModal />
      <div className="md-dashboardContainer">
        <div className="md-dashboardContainer__title">
          <div className="k-loanEmployeesProposalsSection">
            <Icon className="md-dashboardContainer__icon" type="material" name="assignment_ind" />
          </div>
          {intl.formatMessage(messages.loanEmployeesProposalsTitle)}
        </div>
        {filteredProposals.length > 0 ? (
          <OverlayScrollbarsComponent className="k-overlay-scrollbar md-dashboardContainer__list">
            {sortDateByDateAndStartTimestamp(filteredProposals).map(proposal => (
              <LoanEmployeesProposalItem key={proposal.id} proposal={proposal} />
            ))}
          </OverlayScrollbarsComponent>
        ) : (
          <EmptyState
            name="employeeCTA"
            className="md-dashboardTeam__emptyState"
            imgSrc="/img/proposalsCTA.png"
            emptyStateHeader=""
            emptyStateText={intl.formatMessage(messages.loanEmployeesProposalsEmptyState)}
            ctaText=""
          />
        )}
      </div>
    </>
  );
};
LoanEmployeesProposalsSection.contextTypes = {
  intl: PropTypes.shape({}),
};

export default LoanEmployeesProposalsSection;
