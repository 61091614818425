import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { trackIntercomEvent } from '@/actions/intercom';
import Button from '@/components/common/Basic/Button.jsx';
import { FreemiumWrapper } from '@/components/common/Freemium';
import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import ShownCounter from '@/components/common/ShownCounter.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { ADD_NEW_JOBTITLE } from '@/constants/ActionTypes';
import { FREEMIUM_EVENT_ADD_MORE_JOB_TITLES } from '@/constants/intercom.js';
import { PRODUCTION_QUOTAS_GET } from '@/constants/Permissions.js';
import {
  BUDGET_INFO_HIDE,
  COMPANY_MANAGE_JOBTITLES_HIDE_ADD,
  COMPANY_MANAGE_JOBTITLES_HIDE_DELETE,
} from '@/constants/Restrictions.js';
import { compareByKey } from '@/utils/baseHelpers.js';

import AddJobTitleModal from '../AddJobTitleModal/AddJobTitleModal.redux';
import JobTitlesHeader from '../JobTitlesHeader.jsx';
import JobTitlesRow from '../JobTitlesRow.jsx';

const messages = defineMessages({
  emptyStateTitle: {
    id: 'companyManage.jobTitlesList.emptyStateTitle',
    defaultMessage: 'Brak dodanych stanowisk ',
  },
  emptyStateMsg: {
    id: 'companyManage.jobTitlesList.emptyStateMsg',
    defaultMessage: 'Aby dodać pierwsze stanowisko ',
  },
  emptyStateFilterTitle: {
    id: 'companyManage.jobTitlesList.emptyStateFilterTitle',
    defaultMessage: 'Brak wyników do wyświetlenia ',
  },
  emptyStateFilterMsg: {
    id: 'companyManage.jobTitlesList.emptyStateFilterMsg',
    defaultMessage: 'Dostosuj filtry lub ',
  },
  ctaText: {
    id: 'companyManage.jobTitlesList.ctaText',
    defaultMessage: 'kliknij tutaj.',
  },
  ctaFilterText: {
    id: 'companyManage.jobTitlesList.ctaFilterText',
    defaultMessage: 'usuń wyszukiwaną frazę.',
  },
});

// Komponent listy stanowisk
class JobTitlesTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.compareKeys = ['compareName', 'hourly_wage'];
    this.getEmptyStateView = this.getEmptyStateView.bind(this);
  }

  getEmptyStateView() {
    const showModal = !this.props.userJobTitles.length && !this.props.searchWord.length;
    const headerText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateTitle, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterTitle, {});
    const descriptionText = showModal
      ? this.context.intl.formatMessage(messages.emptyStateMsg, {})
      : this.context.intl.formatMessage(messages.emptyStateFilterMsg, {});
    const ctaText = showModal
      ? this.context.intl.formatMessage(messages.ctaText, {})
      : this.context.intl.formatMessage(messages.ctaFilterText, {});
    const ctaHandler = showModal
      ? () => {
          this.props.toggleModal('jobTitles');
        }
      : this.props.clearSearchWord;
    return (
      <tr>
        <td className="no-content-to-show" colSpan="5">
          <EmptyState
            name="jobtitleCTA"
            imgSrc="/img/jobtitlesCTA.png"
            emptyStateHeader={headerText}
            emptyStateText={descriptionText}
            ctaHandler={ctaHandler}
            ctaText={ctaText}
            shiftX="0"
          />
        </td>
      </tr>
    );
  }

  filterAndSort(fraze) {
    const data = [];

    for (const job of this.props.userJobTitles) {
      const compareName = job.title.toLowerCase();
      const compareWage = `0000000000${job.hourly_wage}`.slice(-10);

      const sortingData = {
        compareName,
        compareWage,
        checked: this.props.jobTitlesListUi.selected.includes(job.id),
      };
      if ((compareName + compareWage).includes(fraze.toLowerCase())) data.push({ ...job, ...sortingData });
    }

    const column = this.props.jobTitlesListUi.sortColumn;

    const direction = this.props.jobTitlesListUi.sortDecreesing ? -1 : 1;

    if (column >= 0) data.sort(compareByKey(this.compareKeys[column], direction));

    return data;
  }

  render() {
    const rawData = this.filterAndSort(this.props.searchWord);
    const visibleJobTitlesIds = rawData.map(j => j.id);
    const isProductionQuotasEnabled = this.props.userPermissions.permissions.includes(PRODUCTION_QUOTAS_GET);
    const tableHeight = this.props.deviceInfo.windowSize.height - 300;
    const { trackIntercomEvent } = this.props;
    return (
      <div>
        <AddJobTitleModal />
        <div className="add_blue">
          <FeatureWrapper restriction={COMPANY_MANAGE_JOBTITLES_HIDE_ADD}>
            <FreemiumWrapper show={this.props.jobTitlesLimitReached} intercomEvent={FREEMIUM_EVENT_ADD_MORE_JOB_TITLES}>
              <Button
                onClick={() => {
                  this.props.toggleModal('jobTitles');
                  trackIntercomEvent(ADD_NEW_JOBTITLE);
                }}
                modifiers="blue medium inline"
                testId="showAddJobTitlesModal"
              >
                <FormattedMessage id="companyManage.jobTitlesList.addJobTitle" defaultMessage="Dodaj stanowisko" />
              </Button>
            </FreemiumWrapper>
          </FeatureWrapper>
        </div>
        <FeatureWrapper restriction={COMPANY_MANAGE_JOBTITLES_HIDE_DELETE}>
          <div className="delete_selected">
            <Button
              onClick={this.props.massDeleteJobTitlesConfirm}
              modifiers="reverse-red medium inline"
              disabled={!this.props.jobTitlesListUi.selected.length}
              testId="massDeleteJobTitles"
            >
              <i className="material-icons">delete</i>
              <FormattedMessage id="companyManage.lists.removeSelected" defaultMessage="Usuń zaznaczone" />
            </Button>
          </div>
        </FeatureWrapper>
        <Grid
          width={800}
          height={tableHeight}
          columnStyle={{ width: '16vw', minWidth: '270px' }}
          columnGroup={
            <colgroup>
              <col style={{ width: isProductionQuotasEnabled ? '40%' : '60%' }} />
              <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
                <col style={{ width: '20%' }} />
              </FeatureWrapper>
              {isProductionQuotasEnabled ? <col style={{ width: '20%' }} /> : null}
              <col style={{ width: '20%' }} />
            </colgroup>
          }
          static={rawData.length < 25}
        >
          <Header>
            <JobTitlesHeader
              testId="jobTitlesTable"
              stateUI={this.props.jobTitlesListUi}
              toggle={() => {
                this.props.toggleAllCheckboxes(visibleJobTitlesIds, 'jobTitles');
              }}
              visible={visibleJobTitlesIds}
              sortFunc={this.props.changeSorting}
              showFactor={isProductionQuotasEnabled}
            />
          </Header>

          <Rows className="table_company initialsON" testId="jobTitlesTable">
            {rawData.length === 0
              ? this.getEmptyStateView()
              : rawData.map((jobTitle, index) => (
                  <JobTitlesRow
                    data={jobTitle}
                    stateUI={this.props.jobTitlesListUi}
                    toggle={() => {
                      this.props.toggleCheckbox(jobTitle.id, 'jobTitles');
                    }}
                    edit={() => {
                      this.props.startEdit(jobTitle.id, 'jobTitles');
                    }}
                    delete={() => {
                      this.props.deleteJobTitleConfirm(jobTitle.id);
                    }}
                    key={index}
                    showFactor={isProductionQuotasEnabled}
                    userPermissions={this.props.userPermissions}
                  />
                ))}
          </Rows>
        </Grid>

        <ShownCounter visible={visibleJobTitlesIds.length} total={this.props.userJobTitles.length} />
      </div>
    );
  }
}

JobTitlesTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

JobTitlesTable.propTypes = {
  jobTitlesListUi: PropTypes.shape({
    showModal: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.string),
    sortColumn: PropTypes.number,
    sortDecreesing: PropTypes.bool,
  }).isRequired,
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  deviceInfo: PropTypes.shape({
    windowSize: PropTypes.shape({
      height: PropTypes.number,
    }),
  }),
  searchWord: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  massDeleteJobTitlesConfirm: PropTypes.func.isRequired,
  deleteJobTitleConfirm: PropTypes.func.isRequired,
  startEdit: PropTypes.func.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  clearSearchWord: PropTypes.func,
  jobTitlesLimitReached: PropTypes.bool,
  trackIntercomEvent: PropTypes.func,
};

export default JobTitlesTable;
