import moment from 'moment';

import { ADD_EVENT_MODAL, EDIT_EVENT_MODAL } from '@/constants/modalTypes.js';
import { CHOICE, DAYS, PERIOD } from '@/constants/repeatTab';
import { scheduleEventTypes } from '@/constants/scheduleDisplayModes.js';
import { getFromToFromDateStore, getInitCalendarChoice, getRangeBetweenDates, getToday } from '@/utils/dateHelper';

import { messages } from './EditEventModal.messages';

export const modifiers = {
  modal: ['narrow'],
  timeRangeInput: ['modal'],
};

const getSelectedLocation = scheduleLocationFilter =>
  scheduleLocationFilter.length === 1 ? scheduleLocationFilter[0] : '';

export const getInitialRepeatEventState = (mainDateStore, selectedDay) => {
  const [start, end] = getFromToFromDateStore(mainDateStore);
  return {
    activeRepeatTab: 'period',
    selectedRange: { start, end },
    selectedChoices: getInitCalendarChoice(selectedDay),
    selectedWeekdays: [],
    perWhichDay: null,
  };
};

export const getInitialState = (modalObject, showModal, scheduleLocationFilter, mainDateStore) => {
  const editMode = showModal === EDIT_EVENT_MODAL;
  const selectedModalObject = editMode ? modalObject[EDIT_EVENT_MODAL] : modalObject[ADD_EVENT_MODAL];
  const locationId = selectedModalObject?.location?.id || getSelectedLocation(scheduleLocationFilter);
  const scheduleEventType = selectedModalObject?.type || scheduleEventTypes[0].type;
  const scheduleEventDescription = selectedModalObject?.description || '';
  const workingHours = selectedModalObject?.hours || '__:__-__:__';
  const date = selectedModalObject?.date;
  const id = selectedModalObject?.id;
  const selectedDay = editMode ? getToday() : modalObject?.[ADD_EVENT_MODAL]?.date;

  return {
    id,
    locationId,
    scheduleEventType,
    scheduleEventDescription,
    workingHours,
    date,
    errors: {},
    displayRepeat: false,
    ...getInitialRepeatEventState(mainDateStore, selectedDay),
  };
};

export const getEventTypesOptions = intl =>
  scheduleEventTypes.map(event => ({ ...event, name: intl.formatMessage(event.name) }));

export const getEveryNth = (arr, nth) => {
  if (nth < 1) return [];
  const result = [];
  for (let i = 0; i < arr.length; i += nth) {
    result.push(arr[i]);
  }
  return result;
};

export const getEventObjectToSave = (
  companyId,
  { scheduleEventType, workingHours, locationId, scheduleEventDescription, date, id },
) => {
  const eventObject = {
    type: scheduleEventType,
    hours: workingHours,
    location: { id: locationId },
    description: scheduleEventDescription,
    company_id: companyId,
    date,
  };
  if (id) eventObject.id = id;
  return eventObject;
};

export const getMassEventObjectToSave = (
  companyId,
  getDaysFreeFromWork,
  nonWorkingDays,
  {
    scheduleEventType,
    workingHours,
    locationId,
    scheduleEventDescription,
    selectedChoices,
    perWhichDay,
    selectedRange,
    selectedWeekdays,
    activeRepeatTab,
  },
) => {
  let validDays = [];
  const daysFreeFromWork = getDaysFreeFromWork();
  switch (activeRepeatTab) {
    case PERIOD: {
      const { start, end } = selectedRange;
      const dataRange = getRangeBetweenDates(start, end);
      const weekdays =
        selectedWeekdays.length >= 1
          ? dataRange.reduce((prev, day) => (selectedWeekdays.includes(moment(day).day()) ? [...prev, day] : prev), [])
          : dataRange;

      validDays = nonWorkingDays ? weekdays.filter(day => !daysFreeFromWork.includes(day)) : weekdays;

      break;
    }
    case CHOICE:
      validDays = nonWorkingDays
        ? selectedChoices.filter(day => !daysFreeFromWork.includes(moment(day).format('YYYY-MM-DD')))
        : selectedChoices;

      break;
    case DAYS: {
      const { start, end } = selectedRange;
      const dataRange = getRangeBetweenDates(start, end);
      const selectedData = getEveryNth(dataRange, perWhichDay);
      validDays = nonWorkingDays ? selectedData.filter(day => !daysFreeFromWork.includes(day)) : selectedData;

      break;
    }
    default:
      break;
  }

  return validDays.map(validDay => ({
    type: scheduleEventType,
    hours: workingHours,
    location: { id: locationId },
    description: scheduleEventDescription,
    company_id: companyId,
    date: moment(validDay).format('YYYY-MM-DD'),
  }));
};

export const getFooterOptions = (modalObject, deleteUserEvent, intl) => {
  if (!modalObject[EDIT_EVENT_MODAL]?.id) return [];

  return [
    {
      icon: 'remove_circle_outline',
      text: intl.formatMessage(messages.removeEvent),
      handleClick: () => deleteUserEvent(modalObject[EDIT_EVENT_MODAL]),
    },
  ];
};
