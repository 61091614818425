import { DIRECTIONS } from '@/constants/ui.ts';

const getBoundaredPosition = ({ left, top }) => {
  const boundaredPosition = { left: 10, top: 10 };

  if (left > 10) {
    boundaredPosition.left = left;
  }
  if (top > 10) {
    boundaredPosition.top = top;
  }

  return boundaredPosition;
};

/**
 * @param offset.x
 * @param offset.y
 * */
export const getPositionOnParentCenter = (item, parent, direction, offset, useParentDimensions, align) => {
  const parentRect = parent.getBoundingClientRect();
  const parentRectWidth = parentRect.width;
  const parentRectHeight = parentRect.height;

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  const itemRect = item.getBoundingClientRect();
  const itemWidth = itemRect.width;

  const rect = {
    top: parentRect.top + scrollTop,
    bottom: parentRect.bottom + scrollTop,
    left: parentRect.left + scrollLeft,
    right: parentRect.right + scrollLeft,
  };

  switch (direction) {
    case DIRECTIONS.LEFT: {
      const position = {
        top: rect.top + offset.y + (useParentDimensions ? parentRectHeight / 2 : 0),
        left: rect.left - item.offsetWidth + offset.x,
      };
      return getBoundaredPosition(position);
    }
    case DIRECTIONS.RIGHT: {
      const position = {
        top: rect.top + offset.y + (useParentDimensions ? parentRectHeight / 2 : 0),
        left: rect.left + offset.x + (useParentDimensions ? parentRectWidth : 0),
      };
      return getBoundaredPosition(position);
    }
    case DIRECTIONS.BOTTOM: {
      const position = {
        top: rect.bottom + offset.y,
        left: (rect.left + rect.right - item.offsetWidth) / 2 + offset.x,
      };

      if (align === 'right') {
        position.left = position.left - itemWidth / 2 + 17;
      }

      return getBoundaredPosition(position);
    }
    default: {
      const position = {
        left: (rect.left + rect.right - item.offsetWidth) / 2 + offset.x,
        top: rect.top - item.offsetHeight + offset.y,
      };

      if (align === 'right') {
        position.left = position.left - itemWidth / 2 + 17;
      }

      return getBoundaredPosition(position);
    }
  }
};

export const getPopoverStyle = (rect, sideOffset, position, { top, left }) => {
  switch (position) {
    case 'top':
      return { top: `${top - 15}px`, left: `${left}px` };
    case 'left':
      return { top: `${top - sideOffset}px`, left: `${left - 15}px` };
    case 'right':
      return { top: `${top - sideOffset}px`, left: `${left + 15}px` };
    default: {
      return {
        top: `${top + 15}px`,
        left: `${left}px`,
      };
    }
  }
};

export const calculatePopoverPosition = (
  rect,
  popover,
  child,
  popoverPosition,
  sideOffset,
  autoSideOffset,
  xOffset,
  yOffset,
  useParentDimensions,
  align,
) => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const popoverCurrentPosition = {
    top: rect.top + scrollTop,
    bottom: rect.bottom + scrollTop,
    left: rect.left + scrollLeft,
    right: rect.right + scrollLeft,
  };

  const position = getPositionOnParentCenter(
    popover,
    child,
    popoverPosition,
    {
      x: xOffset,
      y: yOffset,
    },
    useParentDimensions,
    align,
  );
  const offset = autoSideOffset ? popover?.clientHeight / 2 : sideOffset;

  return getPopoverStyle(popoverCurrentPosition, offset, popoverPosition, position);
};

export const getArrowPosition = (arrowPosition) => {
  if (arrowPosition === 'left') return { left: 6, right: 'auto' };
  if (arrowPosition === 'right') return { left: 'auto', right: 6 };
  return { left: 0, right: 0 };
};
