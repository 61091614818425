import PropTypes from 'prop-types';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton.jsx';

const KadroTableOptions = ({ options }) => (
  <div className="k-tableOptions">
    {options.map(option => (
      <MDIconButton icon={option.icon} label={option.label} onClick={option.onClick} />
    ))}
  </div>
);

KadroTableOptions.propTypes = {
  options: PropTypes.arrayOf({
    icon: PropTypes.string,
    label: PropTypes.shape({}),
    onClick: PropTypes.func,
  }),
};

export default KadroTableOptions;
