import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import MDCheckbox from '@/components/common/inputs/MDCheckbox/MDCheckbox';
import MDTimeInput from '@/components/common/inputs/MDTimeInput/MDTimeInput';
import ToggleBlock from '@/components/common/inputs/ToggleBlock/ToggleBlock';
import { OPTION_TYPE } from '@/constants/settingsConstants';
import { isNullOrEmpty } from '@/utils/baseHelpers';

import './SettingsCheckboxList.scss';

const getinitialValues = (items, initValuesObj) =>
  initValuesObj
    ? items.map(item => {
        const initValue = initValuesObj[item.id];
        const checked = initValue && !isNullOrEmpty(initValue);
        return { ...item, checked, value: initValue };
      })
    : items;

const SettingsCheckboxList = ({
  activationSwitch,
  errorMessage,
  name,
  items,
  initialValue,
  type,
  handleInputChange: onChange,
}) => {
  const [isListEnabled, setIsListEnabled] = useState();
  const [values, setValues] = useState();

  const toggleEnable = () => {
    setIsListEnabled(prev => !prev);
  };

  const handleInputChange = id => {
    setValues(prev => prev.map(item => (item.id === id ? { ...item, checked: !item.checked, value: null } : item)));
  };

  const handleValueChange = (id, value) => {
    setValues(prev => prev.map(item => (item.id === id ? { ...item, value } : item)));
  };

  useEffect(() => {
    setValues(getinitialValues(items, initialValue));
    setIsListEnabled(!activationSwitch || !!initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!onChange) return;

    if (isListEnabled) {
      const valuesObject = values.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value || cur.checked || null }), {});
      onChange({ target: { name, type, value: valuesObject } });
      return;
    }
    onChange({ target: { name, type, value: null } });
  }, [values, isListEnabled]);

  const renderSetter = item => {
    switch (item.type) {
      case OPTION_TYPE.TIME_RANGE:
        return (
          <MDTimeInput
            alwaysShowMask
            short={false}
            value={item.value}
            onChange={e => handleValueChange(item.id, e.target.value)}
            modifiers={['alignCenter']}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="settingsCheckboxList">
      {activationSwitch && <ToggleBlock {...activationSwitch} handleInput={toggleEnable} value={isListEnabled} />}
      <div className="settingsCheckboxList__list">
        {isListEnabled &&
          values.map(item => (
            <div className="settingsCheckboxList__item" key={item.id}>
              <div className="settingsCheckboxList__checkbox">
                <MDCheckbox
                  id={item.id}
                  value={!!item.checked}
                  text={item.label}
                  onChange={() => handleInputChange(item.id)}
                />
              </div>
              {item.checked ? (
                <div className="settingsCheckboxList__valueBox">
                  <span className="settingsCheckboxList__additionalValue">{renderSetter(item)}</span>
                </div>
              ) : (
                item.disabledMessage && (
                  <div className="settingsCheckboxList__valueBox">
                    <span className="settingsCheckboxList__disabledMessage">{item.disabledMessage} </span>
                  </div>
                )
              )}
            </div>
          ))}
      </div>

      <div className="settingsCheckboxList__errorMessage">{errorMessage}</div>
    </div>
  );
};

SettingsCheckboxList.propTypes = {
  activationSwitch: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.oneOf(Object.values(OPTION_TYPE)),
  }),
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  initialValue: PropTypes.shape({}),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf([OPTION_TYPE.TIME_RANGE]),
      disabledMessage: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.oneOf(Object.values(OPTION_TYPE)),
  handleInputChange: PropTypes.func,
};

export default SettingsCheckboxList;
