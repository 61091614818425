import classnames from 'classnames';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { generateSliderObj, sliderDotStyle, sliderHandleStyle, sliderTrackStyle } from '@/utils/sliderHelpers';

import 'rc-slider/assets/index.css';
import './dualRangeSliderWithInputs.scss';

const messages = defineMessages({
  minutes: {
    id: 'dualRangeSlider.minutes',
    defaultMessage: 'min',
  },
});

const DualRangeSliderWithInputs = (
  {
    maxValue,
    onSliderChangeFunc,
    values,
    onInputChange,
    infiniteButtonSideReversed,
    showInfiniteButton,
    infiniteButtonValue,
  },
  { intl },
) => {
  const [leftValue, rightValue] = values;
  const sliderRowClassName = classnames('k-dualRangeSlider__sliderRow', {
    'k-dualRangeSlider__sliderRow--reversed': infiniteButtonSideReversed,
  });
  const sliderObj = useMemo(() => generateSliderObj(maxValue, infiniteButtonSideReversed), [maxValue]);
  const marks = infiniteButtonSideReversed ? { [sliderObj.min]: ' ', 0: ' ' } : { 0: ' ', [sliderObj.max]: ' ' };
  return (
    <div className="k-dualRangeSlider">
      <div className={sliderRowClassName}>
        <Slider
          range
          value={values}
          min={sliderObj.min}
          max={sliderObj.max}
          allowCross={false}
          step={1}
          included
          marks={marks}
          onChange={onSliderChangeFunc}
          trackStyle={sliderTrackStyle}
          handleStyle={sliderHandleStyle}
          dotStyle={sliderDotStyle}
        />
        {showInfiniteButton && (
          <button
            className="k-dualRangeSlider__infiniteButton"
            onClick={() => onInputChange(infiniteButtonValue, infiniteButtonSideReversed)}
          >
            &infin;
          </button>
        )}
      </div>
      <div className="k-dualRangeSlider__inputs">
        <div className="min-hour-input">
          <input
            type="number"
            onChange={e => onInputChange(e.target.value, true)}
            value={Math.abs(leftValue) > maxValue ? 9999 : -leftValue}
            max={maxValue}
          />
          <div className="min-text">{intl.formatMessage(messages.minutes)}</div>
        </div>
        <div className="min-hour-input">
          <input
            type="number"
            onChange={e => onInputChange(e.target.value, false)}
            value={Math.abs(rightValue) > maxValue ? 9999 : rightValue}
            max={maxValue}
          />
          <div className="min-text">{intl.formatMessage(messages.minutes)}</div>
        </div>
      </div>
    </div>
  );
};

DualRangeSliderWithInputs.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DualRangeSliderWithInputs.defaultProps = {
  maxValue: 100,
};

DualRangeSliderWithInputs.propTypes = {
  maxValue: PropTypes.number,
  onSliderChangeFunc: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onInputChange: PropTypes.func,
  infiniteButtonSideReversed: PropTypes.bool,
  showInfiniteButton: PropTypes.bool,
  infiniteButtonValue: PropTypes.number,
};

export default DualRangeSliderWithInputs;
