import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noDates: {
    id: 'companymanage.employee.contracts.noDates',
    defaultMessage: 'Domyślny kontrakt',
  },
  noStartDate: {
    id: 'companymanage.employee.contracts.noStartDate',
    defaultMessage: 'Bez daty rozpoczęcia',
  },
  noEndDate: {
    id: 'companymanage.employee.contracts.noEndDate',
    defaultMessage: 'Bez daty zakończenia',
  },
  startDate: {
    id: 'companymanage.employee.contracts.startDate',
    defaultMessage: 'Data rozpoczęcia',
  },
  endDate: {
    id: 'companymanage.employee.contracts.endDate',
    defaultMessage: 'Data zakończenia',
  },
  noStartDateTooltip: {
    id: 'companymanage.employee.contracts.noStartDateTooltip',
    defaultMessage: 'Kontrakt nie posiada daty rozpoczęcia,\nponieważ jest pierwszym kontraktem pracownika',
  },
  noEndDateTooltip: {
    id: 'companymanage.employee.contracts.noEndDateTooltip',
    defaultMessage:
      'Brak daty zakończenia kontraktu wynika z tego, że nie ma kontraktu zaczynającego się po wybranej dacie rozpoczęcia kontraktu',
  },
  endDateTooltip: {
    id: 'companymanage.employee.contracts.endDateTooltip',
    defaultMessage: 'Data zakończenia kontraktu wynika z tego,\nże następnego dnia rozpoczyna się kontrakt od {date}',
  },
  jobTitlesHeader: {
    id: 'companymanage.employee.contracts.jobTitlesHeader',
    defaultMessage: 'Stanowiska',
  },
  chooseJobTitle: {
    id: 'companymanage.employee.contracts.chooseJobTitle',
    defaultMessage: 'Wybierz stanowisko',
  },
  plnPerHour: {
    id: 'companymanage.employee.contracts.plnPerHour',
    defaultMessage: 'zł/h',
  },
  contract: {
    id: 'companymanage.employee.contracts.contract',
    defaultMessage: 'Kontrakt',
  },
  selectPlaceholder: {
    id: 'companymanage.employee.contracts.selectPlaceholder',
    defaultMessage: 'Wybierz kontrakt',
  },
  addButtonTooltip: {
    id: 'companymanage.employee.contracts.addButtonTooltip',
    defaultMessage: 'Aby dodać kolejny kontrakt, musisz wpisać datę rozpoczęcia otwartego kontraktu',
  },
  addNewContract: {
    id: 'companymanage.employee.contracts.addNewContract',
    defaultMessage: 'Wybierz datę rozpoczęcia kontraktu',
  },
  to: {
    id: 'companymanage.employee.contracts.to',
    defaultMessage: 'Do',
  },
  from: {
    id: 'companymanage.employee.contracts.from',
    defaultMessage: 'Od',
  },
});
