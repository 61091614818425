import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { WIDE } from '@/constants/modalModifiers';

import './PreviewModal.scss';

const messages = defineMessages({
  preview: {
    id: 'previewModal.exportPreview',
    defaultMessage: 'Podgląd eksportu',
  },
  generate: {
    id: 'previewModal.generate',
    defaultMessage: 'Generuj Eksport',
  },
  cancel: {
    id: 'previewModal.cancel',
    defaultMessage: 'Anuluj',
  },
  format: {
    id: 'previewModal.format',
    defaultMessage: 'Format:',
  },
  category: {
    id: 'previewModal.category',
    defaultMessage: 'Kategoria:',
  },
});

const PreviewModal = ({ show, modalObject, hideModal }, { intl }) => (
  <MDKadroModal
    modifiers={WIDE}
    show={show}
    onHide={hideModal}
    title={intl.formatMessage(messages.preview)}
    confirmText={intl.formatMessage(messages.generate)}
    onSubmit={modalObject?.actionButtonFunc}
  >
    <div className="previewModal">
      <img src={modalObject?.image} className="previewModal__image" alt="" />
      <div className="previewModal__content">
        <div className="previewModal__leftContent">
          <h1 className="previewModal__title">{modalObject?.title}</h1>
          <p className="previewModal__description">{modalObject?.description}</p>
        </div>
        <div className="previewModal__rightContent">
          <p className="previewModal__detail">
            <span className="previewModal__detailTitle">{intl.formatMessage(messages.format)}</span>
            <span className="previewModal__formatName">{modalObject?.displayFormat || modalObject?.format}</span>
          </p>
          <p className="previewModal__detail">
            <span className="previewModal__detailTitle">{intl.formatMessage(messages.category)}</span>
            <span className="previewModal__categoryName">{modalObject?.categories}</span>
          </p>
        </div>
      </div>
    </div>
  </MDKadroModal>
);

PreviewModal.contextTypes = {
  intl: PropTypes.shape({}),
};

PreviewModal.propTypes = {
  modalObject: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    actionButtonFunc: PropTypes.func,
    categories: PropTypes.string,
    format: PropTypes.string,
    displayFormat: PropTypes.string,
  }),
  show: PropTypes.bool,
  hideModal: PropTypes.func,
};

export default PreviewModal;
