import { produce } from 'immer';

import {
  ADD_LOANED_EMPLOYEES,
  DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
  DELETE_SHIFT,
  GET_LOANED_EMPLOYEES,
} from '@/constants/ActionTypes';
import { EmployeeWhole } from '@/types';
import { Shift } from '@/types/shifts.types';
import { updateShiftsByDate } from '@/utils/scheduleLoanedEmployees';

type LoanedEmployee = {
  id: string;
  first_name: string;
  last_name: string;
  shifts: Record<string, any>;
  shiftsData: any[];
};

const initialState = {
  scheduleLoanedEmployees: {} as Record<string, LoanedEmployee>,
};

const scheduleLoanedEmployees = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_LOANED_EMPLOYEES: {
        action.payload.forEach(loanedEmployee => {
          const shiftsByDate = updateShiftsByDate(loanedEmployee);

          draft.scheduleLoanedEmployees[loanedEmployee.id] = {
            ...loanedEmployee,
            shifts: shiftsByDate,
            shiftsData: loanedEmployee.shifts,
          };
        });
        break;
      }
      case DELETE_SHIFT: {
        const { employee_id: employeeId, shift, shift_id: deletedShiftId } = action.payload;

        const employeeShifts = draft.scheduleLoanedEmployees[employeeId]?.shifts[shift.date];

        if (employeeShifts) {
          draft.scheduleLoanedEmployees[employeeId].shifts[shift.date] = employeeShifts?.filter(
            shiftId => shiftId !== deletedShiftId,
          );
          draft.scheduleLoanedEmployees[employeeId].shiftsData = draft.scheduleLoanedEmployees[
            employeeId
          ].shiftsData.filter(s => s.id !== deletedShiftId);
        }

        break;
      }
      case DELETE_MULTIPLE_SHIFTS_SUCCESFUL: {
        const shiftsToDelete = action.payload as Record<
          EmployeeWhole['id'],
          {
            shiftId: Shift['id'];
            date: Shift['date'];
          }[]
        >;

        Object.entries(shiftsToDelete).forEach(([employeeId, shifts]) => {
          shifts.forEach(({ shiftId, date }) => {
            const employeeShifts = draft.scheduleLoanedEmployees[employeeId]?.shifts[date];

            if (employeeShifts) {
              const newShiftsState = employeeShifts.filter(sId => sId !== shiftId);
              draft.scheduleLoanedEmployees[employeeId].shifts[date] = newShiftsState;
              draft.scheduleLoanedEmployees[employeeId].shiftsData = draft.scheduleLoanedEmployees[
                employeeId
              ].shiftsData.filter(s => s.id !== shiftId);
            }
          });
        });

        break;
      }

      case ADD_LOANED_EMPLOYEES: {
        const { payload } = action;
        payload.forEach(employeeData => {
          const { id: employeeId } = employeeData;

          if (!draft.scheduleLoanedEmployees[employeeId]) {
            const shiftsByDate = updateShiftsByDate(employeeData);
            draft.scheduleLoanedEmployees[employeeId] = {
              ...employeeData,
              shifts: shiftsByDate,
              shiftsData: employeeData.shifts,
            };
          } else {
            draft.scheduleLoanedEmployees[employeeId].shiftsData = employeeData.shifts;
          }
        });

        break;
      }

      default:
        return state;
    }
  });

export default scheduleLoanedEmployees;
