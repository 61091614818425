import { CHECKBOX_COLUMN_WIDTH, DEFAULT_COLUMN_WIDTH, OPTIONS_COLUMN_WIDTH } from '@/constants/tables';

export const INITIAL_TABLE_SETTINGS = {
  checkboxColumnWidth: CHECKBOX_COLUMN_WIDTH,
  optionsColumnWidth: OPTIONS_COLUMN_WIDTH,
};

const getInitialColumnsWidth = settings => settings.checkboxColumnWidth + settings.optionsColumnWidth;

export const countTotalWidthForVisibleColumns = (allColumns, visibleColumns, settings) =>
  visibleColumns.reduce((totalWidth, col) => {
    if (col.shouldBeHiddenInTable) {
      let widthToAdd = 0;
      col.columnAccessorsToShow.forEach(additionalColumnAccessor => {
        const additionalColumn = allColumns.find(aCol => aCol.accessor === additionalColumnAccessor);
        if (additionalColumn) widthToAdd += additionalColumn.width || DEFAULT_COLUMN_WIDTH;
      });
      return totalWidth + widthToAdd;
    }
    return totalWidth + (col.width || DEFAULT_COLUMN_WIDTH);
  }, getInitialColumnsWidth(settings));

export const checkIsDesktopTableView = () => window.innerWidth > 1024;

export const checkIsTableBiggerThanWindow = totalWidth => totalWidth > window.innerWidth;
