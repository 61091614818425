import { connect } from 'react-redux';

import { changeLocationSettings, changeMassLocationsSettings,getLocationSettings } from '@/actions/settings';

import LocationSettingsView from './LocationSettingsView.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  changeLocationSettings,
  changeLocationSettingsMass: changeMassLocationsSettings,
  getLocationSettings,
};

const LocationSettingsViewContainer = connect(mapStateToProps, mapDispatchToProps)(LocationSettingsView);

export default LocationSettingsViewContainer;
