import classnames from 'classnames';

import { OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL } from '@/constants/ActionTypes.js';
import { SelectedJobTitles } from '@/types/jobTitles.types.ts';
import { LoanEmployeesProposals } from '@/types/loanEmployeesProposals';

import LoanEmployeesProposalBlock from '../loanEmployeesProposalBlock/LoanEmployeesProposalBlock.redux';
import {
  getVisibleLoanEmployeesProposals,
  mapVisibleLoanEmployeesProposals,
} from './scheduleTableLoanEmployeesProposalsCell.helpers.ts';
import { useScheduleTableLoanEmployeesProposalsCell } from './useScheduleTableLoanEmployeesProposalsCell.ts';

interface ScheduleTableLoanEmployeesProposalsCellProps {
  selectedJobTitles: SelectedJobTitles[];
  date: string;
  size: string;
  incomingLoanEmployeesProposals: LoanEmployeesProposals[];
  outgoingLoanEmployeesProposals: LoanEmployeesProposals[];
  locationId: string;
  showModal: (modalName: string, data: { date: string; locationId: string }) => void;
}

const ScheduleTableLoanEmployeesProposalsCell = ({
  date,
  size,
  selectedJobTitles,
  incomingLoanEmployeesProposals,
  outgoingLoanEmployeesProposals,
  locationId,
  showModal,
}: ScheduleTableLoanEmployeesProposalsCellProps) => {
  const { colSpan } = useScheduleTableLoanEmployeesProposalsCell();
  const selectedJobTitlesIds = selectedJobTitles.map(({ id }) => id);

  const visibleIncomingLoanEmployeesProposals = getVisibleLoanEmployeesProposals(
    incomingLoanEmployeesProposals,
    date,
    selectedJobTitlesIds,
  );

  const visibleOutgoingLoanEmployeesProposals = getVisibleLoanEmployeesProposals(
    outgoingLoanEmployeesProposals,
    date,
    selectedJobTitlesIds,
  );

  const mappedVisibleIncomingLoanEmployeesProposals = mapVisibleLoanEmployeesProposals(
    visibleIncomingLoanEmployeesProposals,
    date,
    selectedJobTitles,
  );

  const mappedVisibleOutgoingLoanEmployeesProposals = mapVisibleLoanEmployeesProposals(
    visibleOutgoingLoanEmployeesProposals,
    date,
    selectedJobTitles,
  );

  const isEmpty = !(visibleIncomingLoanEmployeesProposals.length || visibleOutgoingLoanEmployeesProposals.length);

  const className = classnames('k-loanEmployeesProposals__cell', { 'k-loanEmployeesProposals__cell--empty': isEmpty });

  const createOutgoingLoanEmployeesProposal = e => {
    if (!isEmpty) return;
    e.stopPropagation();
    showModal(OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL, { date, locationId });
  };
  return (
    <td colSpan={colSpan} className={className} role="presentation" onClick={createOutgoingLoanEmployeesProposal}>
      <span className="material-icons-outlined k-loanEmployeesProposals__addIcon">add</span>

      {mappedVisibleIncomingLoanEmployeesProposals.map(incomingLoanEmployeesProposal => (
        <LoanEmployeesProposalBlock
          key={incomingLoanEmployeesProposal.id}
          loanEmployeesProposal={incomingLoanEmployeesProposal}
          size={size}
          isIncomingProposal
          locationId={locationId}
          date={date}
        />
      ))}
      {mappedVisibleOutgoingLoanEmployeesProposals.map(outgoingLoanEmployeesProposal => (
        <LoanEmployeesProposalBlock
          key={outgoingLoanEmployeesProposal.id}
          loanEmployeesProposal={outgoingLoanEmployeesProposal}
          size={size}
          isOutgoingProposal
          locationId={locationId}
          date={date}
        />
      ))}
    </td>
  );
};

export default ScheduleTableLoanEmployeesProposalsCell;
