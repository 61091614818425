// TODO: remove and import this from 'kadro-helpers'
import { v4 } from 'uuid';

import browserHistory from '@/constants/browserHistory';

/**
 *
 * @param {number} x - Rounds the number to two significant digits
 */
export const roundToTwoSigDigits = x => {
  if (!x) return 0;
  return Number(x.toFixed(2));
};

/**
 * Finds the intersection between two arrays. Returns null if no intersection is found.
 * @param {Array} arrayOne
 * @param {Array} arrayTwo
 */
export const arrayIntersection = (arrayOne, arrayTwo) => {
  if (arrayOne.length === 0 || arrayTwo.length === 0) {
    return null;
  }
  const intersection = [];
  for (let i = 0; i < arrayOne.length; i++) {
    const element = arrayOne[i];
    if (arrayTwo.includes(element)) {
      intersection.push(element);
    }
  }
  if (intersection.length > 0) {
    return intersection;
  }
  return null;
};

/**
 * Checks if the value is a numeric - credit -> https://stackoverflow.com/a/16655847
 * @param {string} val - a value represented as a string
 */
export const isNumeric = val => Number(parseFloat(val)) === val;

const lut = [];
for (let i = 0; i < 256; i++) {
  lut[i] = (i < 16 ? '0' : '') + i.toString(16);
}
export const uuid4 = () => v4();

export const seededUuid4 = rand => {
  const d0 = (rand.next() * 0xffffffff) | 0;
  const d1 = (rand.next() * 0xffffffff) | 0;
  const d2 = (rand.next() * 0xffffffff) | 0;
  const d3 = (rand.next() * 0xffffffff) | 0;
  return `${lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff]}-${
    lut[d1 & 0xff]
  }${lut[(d1 >> 8) & 0xff]}-${lut[((d1 >> 16) & 0x0f) | 0x40]}${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${
    lut[(d2 >> 8) & 0xff]
  }-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${lut[d3 & 0xff]}${lut[(d3 >> 8) & 0xff]}${
    lut[(d3 >> 16) & 0xff]
  }${lut[(d3 >> 24) & 0xff]}`;
};

/**
 * compareByKey - function for sorting data by given key
 *
 * @param  {string} key - name of object prop to sort by
 * @param  {int} order - decr or incr (-1/1)
 * @return {type} returns compare function
 */

export function compareByKey(key, order) {
  return (a, b) => {
    if (typeof a[key] === 'string') {
      const aStr = String(a[key] || '');
      const bStr = String(b[key] || '');
      if (aStr === '') return order;
      if (bStr === '') return order * -1;
      return aStr.localeCompare(bStr) * order;
    }
    if (a[key] < b[key]) return -1 * order;
    if (a[key] > b[key]) return 1 * order;
    return 0;
  };
}

export function cutStringToMaxLength(text, maxLength) {
  if (typeof text !== 'string') return text;
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength - 3)}...`;
  }
  return text;
}

export function listArrayItems(array, separator = ', ') {
  let str = '';
  array.forEach((el, i) => {
    if (el && i !== array.length - 1) {
      str += el + separator;
    } else if (i === array.length - 1) {
      str += el;
    }
  });
  return str;
}

export const pad = num => (num < 10 ? `0${num}` : `${num}`);

export const removeLatinAndSpecial = text => {
  let result = text.toLowerCase();
  result = result.replace(/ą/g, 'a');
  result = result.replace(/ę/g, 'e');
  result = result.replace(/ś/g, 's');
  result = result.replace(/ć/g, 'c');
  result = result.replace(/ż/g, 'z');
  result = result.replace(/ź/g, 'z');
  result = result.replace(/ł/g, 'l');
  result = result.replace(/ń/g, 'n');
  result = result.replace(/ó/g, 'o');
  result = result.replace(/[^a-zA-Z_.-]/g, '');
  return result;
};

export const isDefined = value => Boolean(value || value === 0 || value === '');

export const isNullOrEmpty = value => {
  if (value == null) {
    return true;
  }
  if (typeof value === 'number') {
    return false;
  }
  if (typeof value === 'boolean') {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  const defaultValue = Boolean(value);

  return !defaultValue;
};

export const checkBlocksOverlap = (aFrom, aTo, bFrom, bTo) =>
  (aTo > bFrom && aFrom < bTo) || (bTo > aFrom && bFrom < aTo);

export const arrayToArrayMapByKey = (array, key) =>
  array.reduce((agg, item) => ({ ...agg, [item[key]]: [...(agg[item[key]] || []), item] }), {});

export const splitByIndex = (value, index) => [value.substring(0, index), value.substring(index)];

export const sendInputEvent = ({ name, value }) => ({ target: { name, value } });

export const getOS = () => {
  if (window.navigator.platform.toLowerCase().includes('mac')) {
    return 'mac';
  }

  if (window.navigator.platform.toLowerCase().includes('linux')) {
    return 'linux';
  }

  if (window.navigator.platform.toLowerCase().includes('win')) {
    return 'windows';
  }

  return window.navigator.platform.toLowerCase();
};

export const goToResetPasswordPage = () => {
  browserHistory.push('/password/expired');
};

export const formatDecimalAsPercent = (decimal, fixed) => `${(decimal * 100).toFixed(fixed)}%`;

export const getPercent = (value, total, fixed) => {
  const ratio = total > 0 ? value / total : 0;

  return formatDecimalAsPercent(ratio, fixed);
};
export const isEmptyObject = obj =>
  typeof obj === 'object' && obj !== null && !Array.isArray(obj) && Object.keys(obj).length === 0;

const formatWithSpaces = integerString => integerString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const numberWithSpaces = number => {
  const [integerPart, decimalPart] = number.toString().split('.');
  const formattedIntegerPart = formatWithSpaces(integerPart);
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};
