import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';
import { MANAGER_VIEW_EMPLOYEES_PAYROLL } from '@/constants/Restrictions.js';

import { getRelevantEmployees } from './topBarEmployeeFilterHelpers.js';

const messages = defineMessages({
  lackOfEmployees: {
    id: 'topbarEmployeeFilter.lackOfEmployees',
    defaultMessage: 'Brak pracowników',
  },
});

class TopBarEmployeeFilter extends Component {
  constructor(props) {
    super(props);

    this.changeFunc = this.changeFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.userPermissions.restrictions.includes(MANAGER_VIEW_EMPLOYEES_PAYROLL)) {
      this.props.changeSingleEmployeeFilter(this.props.currentUser.user);
    }
  }

  changeFunc(employee) {
    this.props.changeSingleEmployeeFilter(employee);
  }

  render() {
    const {
      companyRoles,
      companySettings,
      currentUser,
      multipleLocationFilter,
      singleEmployeeFilter,
      userEmployees,
      userPermissions,
      payrollLoanedEmployees,
    } = this.props;
    const sortedEmployees = getRelevantEmployees(
      currentUser,
      multipleLocationFilter,
      userEmployees,
      userPermissions,
      companyRoles,
      companySettings,
      payrollLoanedEmployees,
    );

    return (
      <div className="k-topBarEmployeeFilter">
        <TopBarSingleChoice
          displayKey="name"
          emptyMessage={messages.lackOfEmployees}
          objectsToChoose={sortedEmployees}
          selected={singleEmployeeFilter}
          onChange={this.changeFunc}
          className="k-topBarChoice--employeeFilter"
        />
      </div>
    );
  }
}

TopBarEmployeeFilter.propTypes = {
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      rank: PropTypes.number,
    }),
  ),
  companySettings: PropTypes.shape({
    same_rank_is_visible: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  singleEmployeeFilter: PropTypes.shape({}),
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      supplementary_locations_ids: PropTypes.arrayOf(PropTypes.number),
    }),
  ),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  changeSingleEmployeeFilter: PropTypes.func,
  payrollLoanedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default TopBarEmployeeFilter;
