import { connect } from 'react-redux';

import { openAddAttendanceModal, setAttendanceSearchString } from '@/actions/attendances.js';
import { selectAllJobTitlesAndEmploymentConditions, selectFirstLocation } from '@/actions/filters.js';
import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { sellectAllAttendancesSettings } from '@/actions/settings';
import { showModal } from '@/actions/uiState.js';
import AttendanceTableView from '@/components/attendance/AttendanceTableView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
  userLocations: state.reducer.userLocations,
  currentUser: state.reducer.currentUser,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  jobtitleFilter: state.reducer.jobtitleFilter,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  employeeSorting: state.reducer.employeeSorting,
  mainDateStore: state.reducer.mainDateStore,
  deviceInfo: state.reducer.deviceInfo,
  selectedAttendances: state.reducer.attendances.selected,
  locationSettings: state.reducer.settings.locationSettings,
  searchString: state.reducer.listsUi.attendance.searchString,
  absences: state.reducer.absences.scheduleAbsences,
  companyRoles: state.reducer.roles.companyRoles,
  attendancesSettings: state.reducer.settings.attendancesSettings,
  overtimeCollections: state.reducer.overtimeCollections,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
  attendancesData: state.reducer.attendances.attendancesData.attendances,
  employeesNames: state.reducer.employeesNames.data,
  scheduleLoanedEmployees: state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  openAddAttendanceModal,
  showModal,
  setAttendanceSearchString,
  selectAllJobTitlesAndEmploymentConditions,
  selectFirstLocation,
  sellectAllAttendancesSettings,
};

const AttendanceTableViewContainer = connect(mapStateToProps, mapDispatchToProps)(AttendanceTableView);

export default AttendanceTableViewContainer;
