import { generateDateArray } from 'kadro-helpers/lib/helpers';
import { combineReducers } from 'redux';

import * as AT from '@/constants/ActionTypes.js';
import { sumArrayOfNumbers } from '@/utils/arrayHelpers.js';

import { aggregateEstimates, aggregateEstimatesByDate } from './budget.helpers.js';

const recommendedSchedule = (state = {}, action) => {
  switch (action.type) {
    case AT.ADD_RECOMMENDED_SCHEDULE_SUCCESS:
    case AT.GET_RECOMMENDED_SCHEDULE_SUCCESS: {
      return action.payload.reduce((result, item) => {
        const relevantTemplates = item.template.filter(t => action.selectedJobTitleIds.includes(t.job_title_id));

        return {
          ...result,
          [item.date]: {
            ...(result[item.date] || {}),
            [item.location_id]: {
              ...item,
              sumMinutes: relevantTemplates.reduce(
                (sum, t) => sum + Object.values(t.values).reduce((a, b) => a + b * 60, 0),
                0,
              ),
            },
          },
        };
      }, {});
    }
    case AT.DELETE_RECOMMENDED_SCHEDULE_SUCCESS: {
      const { locationId, from, to } = action.payload;
      const dateArray = generateDateArray(from, to);
      const newState = dateArray.reduce(
        (prev, date) => ({ ...prev, [date]: { ...(prev[date] || {}), [locationId]: undefined } }),
        { ...state },
      );

      return newState;
    }
    default:
      return state;
  }
};

const budgetEstimates = (state = { dailyValues: {}, summary: {} }, action) => {
  switch (action.type) {
    case AT.GET_BUDGET_ESTIMATES_SUCCESS: {
      return { dailyValues: aggregateEstimatesByDate(action.payload), summary: aggregateEstimates(action.payload) };
    }
    case AT.EDIT_BUDGET_ESTIMATES_SUCCESS: {
      const { date, location_id: _locationId, period: _period, ...values } = action.payload;
      const formattedValues = Object.entries(values).reduce(
        (result, [key, value]) => ({
          ...result,
          [key]: typeof value[0] === 'number' ? sumArrayOfNumbers(value) : null,
        }),
        {},
      );

      const newState = {
        ...state.dailyValues,
        [date]: {
          date,
          ...(state.dailyValues[date] || {}),
          ...formattedValues,
        },
      };

      const budgetItems = Object.values(newState);
      const summary = budgetItems.reduce(
        (
          result,
          {
            estimated_budget: estimatedBudget,
            actual_budget: actualBudget,
            labour_limit: labourLimit,
            target_spmh: targetSpmh,
          },
        ) => ({
          estimated_budget: result.estimated_budget + Number(estimatedBudget) || result.estimated_budget,
          actual_budget: result.actual_budget + Number(actualBudget) || result.actual_budget,
          labour_limit: result.labour_limit + Number(labourLimit) || result.labour_limit,
          target_spmh: result.target_spmh + Number(targetSpmh) || result.target_spmh,
        }),
        { estimated_budget: 0, actual_budget: 0, labour_limit: 0, target_spmh: 0 },
      );

      return {
        dailyValues: newState,
        summary: {
          ...summary,
          labour_limit:
            summary.labour_limit /
            (budgetItems.filter(({ labour_limit: labourLimit }) => typeof labourLimit === 'number').length || 1),
          target_spmh:
            summary.target_spmh /
            (budgetItems.filter(({ target_spmh: targetSpmh }) => typeof targetSpmh === 'number').length || 1),
        },
      };
    }
    default:
      return state;
  }
};

const shiftsSummary = (state = {}, action) => {
  switch (action.type) {
    case AT.CHANGE_SHIFTS_SUMMARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const selectedOptions = (state = [], action) => {
  switch (action.type) {
    case AT.TOGGLE_BUDGET_OPTION:
      return state.includes(action.payload) ? state.filter(o => o !== action.payload) : [...state, action.payload];
    default:
      return state;
  }
};

const budgetTargets = (state = [], action) => {
  switch (action.type) {
    case AT.GET_BUDGET_TARGETS_SUCCESS:
      return action.payload.reduce((result, item) => {
        const { location_id: locationId, from, to } = item;
        const key = `${locationId}_${from}_${to}`;

        return {
          ...result,
          [key]: item,
        };
      }, {});
    default:
      return state;
  }
};

export default combineReducers({
  budgetEstimates,
  recommendedSchedule,
  shiftsSummary,
  selectedOptions,
  budgetTargets,
});
