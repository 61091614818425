import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyState from '@/components/emptyState/EmptyState.jsx';

import './PayrollLocationTableEmpty.scss';

interface Props {
  hasTabs: boolean;
  numberOfFetchedItems: number;
}

const PayrollLocationTableEmpty = ({ hasTabs, numberOfFetchedItems }: Props) => {
  const header =
    hasTabs && numberOfFetchedItems > 0 ? (
      <FormattedMessage
        id="payrollLocation.emptyTable.emptyFilterWithTabsMsg"
        defaultMessage="Wybierz inny status zatwierdzenia godzin w Ewidencji czasu pracy"
      />
    ) : (
      <FormattedMessage
        id="payrollLocation.emptyTable.emptyFilterMsg"
        defaultMessage="Wybierz lokalizacje, stanowiska oraz warunki zatrudnienia i naciśnij przycisk Wczytaj"
      />
    );

  return (
    <div className="payrollLocationTableEmpty">
      <EmptyState
        name="payrollCTA"
        imgSrc="/img/payrollLocationCTA.png"
        emptyStateHeader={header}
        emptyStateText={
          <FormattedMessage
            id="payrollLocation.emptyTable.emptyStateFilterTitle"
            defaultMessage="Brak wyników do wyświetlenia"
          />
        }
        ctaText=""
      />
    </div>
  );
};

export default memo(PayrollLocationTableEmpty);
