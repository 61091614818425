import { defineMessages } from 'react-intl';

export const tabsMessages = defineMessages({
  all: {
    id: 'newPayroll.payrollLocationTable.all',
    defaultMessage: 'Wszystkie',
  },
  approved: {
    id: 'newPayroll.payrollLocationTable.approved',
    defaultMessage: 'Zatwierdzone',
  },
  unapproved: {
    id: 'newPayroll.payrollLocationTable.unapproved',
    defaultMessage: 'Oczekujące',
  },
  pending: {
    id: 'newPayroll.payrollLocationTable.pending',
    defaultMessage: 'W trakcie',
  },
});
