import cn from 'classnames';
import PropTypes from 'prop-types';

import './AvaCell.scss';

const AvaCell = ({
  children,
  date,
  employeeId,
  openAddAvailabilityModal,
  isMonthView,
  isAvaDragged,
}) => {
  const isEmpty = !children;
  const className = cn('k-scheduleAvaCell', {
    'k-scheduleAvaCell--add': isEmpty,
    'k-scheduleAvaCell--addWhenDragged': isEmpty && isAvaDragged,
    'k-scheduleAvaCell--month': isMonthView,
  });
  return (
    <div
      className={className}
      onClick={e => {
        if (!isEmpty) return;
        e.stopPropagation();
        openAddAvailabilityModal(date, employeeId);
      }}
      role="presentation"
    >
      {children || <span className="material-icons-outlined">add</span>}
    </div>
  );
};

AvaCell.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string,
  employeeId: PropTypes.string,
  openAddAvailabilityModal: PropTypes.func,
  isMonthView: PropTypes.bool,
  isAvaDragged: PropTypes.bool,
};
export default AvaCell;
