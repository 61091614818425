import {
  CustomFieldDefinition,
  CustomFieldSelectOption,
  CustomFieldType,
  CustomFieldValue,
  UserCustomFields,
} from '@/types/customFields.types';

const TYPE_ORDER = {
  boolean: 0,
  date: 1,
  dateTime: 2,
  dateRange: 3,
  dateTimeRange: 4,
  text: 5,
  number: 6,
  select: 7,
};

const parseSettings = (options: CustomFieldSelectOption[], maxSelectedOptions: number) =>
  options.map(({ id, label }) =>
    maxSelectedOptions === 1 ? { value: String(id), key: label } : { value: String(id), label },
  );

const getSelectedOption = (
  options: CustomFieldSelectOption[],
  userCustomFieldValue: number[],
  maxSelectedOptions: number,
) =>
  options
    .filter(({ id }) => userCustomFieldValue.includes(id))
    .map(({ id, label }) =>
      maxSelectedOptions === 1 ? { value: String(id), key: label } : { value: String(id), label },
    );

export const getCustomFieldsForEmployee = (
  customFieldsDefinition: CustomFieldDefinition[],
  userCustomFields: UserCustomFields,
) =>
  customFieldsDefinition
    .reduce((acc, { id, name, key, type, settings, is_required: isRequired }) => {
      const isUserCustomVieldValue = userCustomFields.hasOwnProperty(key);
      const userCustomFieldValue = userCustomFields[key];

      if (isUserCustomVieldValue && type === CustomFieldType.select && Array.isArray(userCustomFieldValue) && userCustomFieldValue.every(val => typeof val === 'number')) { 
        const selectedOption = getSelectedOption(settings.options, userCustomFieldValue, settings.maxSelectedOptions);

        const parsedSettings = parseSettings(settings.options, settings.maxSelectedOptions);
        const newSettings = { ...settings, options: parsedSettings };

        acc.push({ id, name, key, type, settings: newSettings, isRequired, value: selectedOption });
      } else if (isUserCustomVieldValue) {
        acc.push({ id, name, key, type, settings, isRequired, value: userCustomFieldValue });
      }
      return acc;
    }, [])
    .sort((a, b) => {
      const typeA = a.type;
      const typeB = b.type;

      const orderDiff = TYPE_ORDER[typeA] - TYPE_ORDER[typeB];
      if (orderDiff !== 0) return orderDiff;

      if (typeA === CustomFieldType.select) {
        const aValueLength = a.settings.maxSelectedOptions;
        const bValueLength = b.settings.maxSelectedOptions;
        return aValueLength - bValueLength;
      }

      return 0;
    });

export const isDateRangeObject = (value: CustomFieldValue): value is { from: string; to: string } =>
  typeof value === 'object' && value !== null && 'from' in value && 'to' in value;
