import {
  CHANGE_CUSTOM_SORTING,
  CHANGE_EMPLOYEE_SORTING,
  GET_CUSTOM_SORTING_SUCCESS,
  START_CUSTOM_SORTING,
} from '@/constants/ActionTypes.js';
import { employeeSortingOptions } from '@/constants/employeeSortingOptions.js';

const initialState = {
  currentSorting: employeeSortingOptions[0],
  employeeOrders: {},
};

const employeeSorting = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_EMPLOYEE_SORTING:
      return {
        ...state,
        currentSorting: action.payload,
      };
    case GET_CUSTOM_SORTING_SUCCESS: {
      return { ...state, employeeOrders: action.payload };
    }
    case START_CUSTOM_SORTING: {
      const { locationIds, userEmployees } = action;
      let newState = { ...state };
      locationIds.forEach(locationId => {
        const orderToChange = state.employeeOrders[locationId] || {};
        const orderValues = Object.values(orderToChange);
        let max = orderValues.length ? Math.max(...orderValues) : 0;
        userEmployees.forEach(employee => {
          if (!orderToChange[employee.id] && employee.locations.some(l => l.id === locationId)) {
            max++;
            orderToChange[employee.id] = max;
          }
        });
        newState = { ...newState, employeeOrders: { ...state.employeeOrders, [locationId]: orderToChange } };
      });

      return newState;
    }
    case CHANGE_CUSTOM_SORTING: {
      const { employeeOrders } = state;
      const { locationId, changeData } = action;
      const { refEmpId, empId, position } = changeData;
      const orderToChange = { ...employeeOrders[locationId] };
      const refIndex = orderToChange[refEmpId];
      const empIndex = orderToChange[empId];
      if (refIndex > empIndex) {
        let tmpIndex = refIndex;
        if (position !== 'after') tmpIndex--;
        for (const key in orderToChange) {
          if (orderToChange[key] <= tmpIndex && orderToChange[key] > empIndex) {
            orderToChange[key]--;
          }
        }
        orderToChange[empId] = tmpIndex;
      } else {
        let tmpIndex = refIndex;
        if (position === 'after') tmpIndex++;
        for (const key in orderToChange) {
          if (orderToChange[key] >= tmpIndex && orderToChange[key] < empIndex) {
            orderToChange[key]++;
          }
        }
        orderToChange[empId] = tmpIndex;
      }
      return { ...state, employeeOrders: { ...state.employeeOrders, [locationId]: orderToChange } };
    }
    default:
      return state;
  }
};
export default employeeSorting;
