import { Component } from 'react';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import './Clock.scss';

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = { hour: 0, minute: 0, hourDegree: 0, minuteDegree: 0 };
    bindPrototypeFunctions(this);
  }

  componentDidMount() {
    this.updateClock();
    this.interval = setInterval(() => {
      this.updateClock();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateClock() {
    const date = new Date();
    const hour = this.formatNumber(date.getHours());
    const minute = this.formatNumber(date.getMinutes());
    const hourDegree = (((date.getHours() + 11) % 12) + 1) * 30;
    const minuteDegree = date.getMinutes() * 6;
    this.setState({ hour, minute, hourDegree, minuteDegree });
  }

  formatNumber(number) {
    return number > 9 ? number : `0${number}`;
  }

  render() {
    const { hour, minute, hourDegree, minuteDegree } = this.state;
    return (
      <div className="k-clock">
        <div className="k-analogClock">
          <div className="k-analogClock__wrap">
            <span className="k-analogClock__hour" style={{ transform: `rotate(${hourDegree}deg)` }} />
            <span className="k-analogClock__minute" style={{ transform: `rotate(${minuteDegree}deg)` }} />
            <span className="k-analogClock__dot" />
          </div>
        </div>
        <div className="k-digitalClock">
          {hour}:{minute}
        </div>
      </div>
    );
  }
}

export default Clock;
