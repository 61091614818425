import PropTypes from 'prop-types';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';

import { MODAL_MODIFIERS } from './ImportShiftAndAttendanceErrorModal.helpers';
import { errorsMessages, messages } from './ImportShiftAndAttendanceErrorModal.messages';

import './ImportShiftAndAttendanceErrorModal.scss';

const ImportShiftAndAttendanceErrorModal = ({ show, hideModalAndLaterClearModalObject, errors = [] }, { intl }) => (
  <MDKadroModal
    show={show}
    onHide={hideModalAndLaterClearModalObject}
    title={intl.formatMessage(messages.title)}
    modifiers={MODAL_MODIFIERS}
    hideConfirmButton
    hideCancelButton
  >
    <div className="importShiftAndAttendanceErrorModal__row importShiftAndAttendanceErrorModal__row--header">
      <div>{intl.formatMessage(messages.firstName)}</div>
      <div>{intl.formatMessage(messages.lastName)}</div>
      <div>{intl.formatMessage(messages.referenceId)}</div>
      <div>{intl.formatMessage(messages.date)}</div>
      <div>{intl.formatMessage(messages.description)}</div>
    </div>
    {errors.map((error, index) => (
      <div key={index} className="importShiftAndAttendanceErrorModal__row">
        <div>{error.firstName}</div>
        <div>{error.lastName}</div>
        <div>{error.referenceId}</div>
        <div>{error.date}</div>
        <div>{intl.formatMessage(errorsMessages[error.errorCode] || errorsMessages.unexpectedError)}</div>
      </div>
    ))}
  </MDKadroModal>
);

ImportShiftAndAttendanceErrorModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ImportShiftAndAttendanceErrorModal.propTypes = {
  hideModalAndLaterClearModalObject: PropTypes.func,
  show: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ImportShiftAndAttendanceErrorModal;
