import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MinuteHourInput from '@/components/common/inputs/MinuteHourInput.jsx';
import { roundingSettings, roundingTypeSettings } from '@/constants/payrollSettings';
import { displayTextToIntlMessage } from '@/utils/intlHelpers.jsx';

const RoundingSettings = (props, { intl }) => {
  if (!props.settings.startRoundingType) return null;
  let changeSetting = () => {};
  let changeType = () => {};
  let changeTolerance = () => {};
  let settingId = 0;
  let typeId = 0;
  let tolerance = 0;
  let header = <FormattedMessage id="to" defaultMessage="Do" />;
  let toleranceTitle = (
    <FormattedMessage id="payrollSettings.endTolerance" defaultMessage="Tolerancja wyjścia po czasie" />
  );
  if (props.type === 'start') {
    header = <FormattedMessage id="from" defaultMessage="Od" />;
    toleranceTitle = (
      <FormattedMessage id="payrollSettings.startTolerance" defaultMessage="Tolerancja wejścia przed czasem" />
    );
    changeSetting = props.changeSetting.bind(this, 'startRoundingSetting');
    changeType = props.changeSetting.bind(this, 'startRoundingType');
    changeTolerance = props.changeSetting.bind(this, 'startTolerance');
    settingId = props.settings.startRoundingSetting.id;
    typeId = props.settings.startRoundingType.id;
    tolerance = props.settings.startTolerance;
  } else {
    changeSetting = props.changeSetting.bind(this, 'endRoundingSetting');
    changeType = props.changeSetting.bind(this, 'endRoundingType');
    changeTolerance = props.changeSetting.bind(this, 'endTolerance');
    settingId = props.settings.endRoundingSetting.id;
    typeId = props.settings.endRoundingType.id;
    tolerance = props.settings.endTolerance;
  }

  const roundingTypeSettingsWithIntl = roundingTypeSettings.map(item => displayTextToIntlMessage(item, intl));
  const roundingSettingsWithIntl = roundingSettings.map(item => displayTextToIntlMessage(item, intl));

  return (
    <div className="roundingOptions">
      <div className="roundingOptions__title">{header}</div>
      <div className="payrollSettings__optionName">
        <FormattedMessage id="payrollSettings.rounding" defaultMessage="Zaokrąglanie" />
      </div>
      <div className="roundingOptions__leftColumn">
        {roundingSettingsWithIntl.map((s, i) => (
          <div key={i} className="radio radio-info">
            <input
              className="styled"
              type="radio"
              id={`option${i}${props.type}`}
              value={`option${i}`}
              checked={settingId === s.id}
              onChange={() => {
                changeSetting(s);
              }}
            />
            <label htmlFor={`option${i}${props.type}`}>{s.displayText}</label>
          </div>
        ))}
      </div>
      <div className="roundingOptions__rightColumn">
        {roundingTypeSettingsWithIntl.map((s, i) => (
          <div key={i} className="radio radio-info">
            <input
              className="styled"
              type="radio"
              id={`type${i}${props.type}`}
              value={`type${i}`}
              checked={typeId === s.id}
              onChange={() => {
                changeType(s);
              }}
            />
            <label htmlFor={`type${i}${props.type}`}>{s.displayText}</label>
          </div>
        ))}
      </div>
      <div>
        <div className="payrollSettings__optionName">{toleranceTitle}</div>
        <MinuteHourInput
          value={tolerance}
          min={0}
          max={90}
          step={1}
          onChangeFunc={e => {
            changeTolerance(e.target.value);
          }}
          labelId="tolerance1"
          toleranceDisabled={props.toleranceDisabled}
        />
      </div>
    </div>
  );
};

RoundingSettings.contextTypes = {
  intl: PropTypes.shape({}),
};

RoundingSettings.propTypes = {
  type: PropTypes.string,
  settings: PropTypes.shape({
    startRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  toleranceDisabled: PropTypes.bool,
};
export default RoundingSettings;
