import PropTypes from 'prop-types';

import ScheduleBudgetTableRecommendedScheduleCell from './ScheduleBudgetTableRecommendedScheduleCell.redux.js';
import ScheduleBudgetTableRecommendedScheduleTotalCell from './ScheduleBudgetTableRecommendedScheduleTotalCell.redux.js';

const ScheduleBudgetTableRecommendedSchedule = ({ dateArray, locationId, onEstimateChange, setCellError }) => (
  <tr>
    <ScheduleBudgetTableRecommendedScheduleTotalCell locationId={locationId} />
    {dateArray.map(date => (
      <ScheduleBudgetTableRecommendedScheduleCell
        date={date}
        key={date}
        onEstimateChange={onEstimateChange}
        setCellError={setCellError}
      />
    ))}
  </tr>
);

ScheduleBudgetTableRecommendedSchedule.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationId: PropTypes.string,
  onEstimateChange: PropTypes.func,
  setCellError: PropTypes.func,
};

export default ScheduleBudgetTableRecommendedSchedule;
