export interface LabelOption {
  id: string;
  label: string;
  color: string;
}
export interface SelectedOptions extends LabelOption {
  selected: boolean;
  show: boolean;
}

export const getInitialState = (options: LabelOption[], defaultOptions?: string[]): SelectedOptions[] => {
  if (!options?.length) return [];
  if (!defaultOptions) return options.map(option => ({ ...option, selected: false, show: true }));

  return options.map(option => {
    if (defaultOptions.includes(option.id)) return { ...option, selected: true, show: false };

    return { ...option, selected: false, show: true };
  });
};

export const updateState = (
  options: LabelOption[],
  selectedOptions: SelectedOptions[],
  showOnlyProperAttendance?: boolean,
) => {
  if (options?.length > selectedOptions.length) {
    return addOptions(options, selectedOptions, showOnlyProperAttendance);
  } else if (options.length < selectedOptions.length) {
    return removeOptions(options, selectedOptions);
  }
  return editOptions(options, selectedOptions);
};

const addOptions = (options: LabelOption[], selectedOptions: SelectedOptions[], isNewLabelSelected?: boolean) =>
  options.reduce((acc, option) => {
    const optionToReturn = selectedOptions.reduce((acc, selectOption) => {
      if (selectOption.id === option.id) {
        return [...acc, selectOption];
      }
      return acc;
    }, []);

    if (optionToReturn.length) return [...acc, ...optionToReturn];
    return [...acc, { ...option, selected: isNewLabelSelected, show: true }];
  }, []);

const removeOptions = (options: LabelOption[], selectedOptions: SelectedOptions[]) =>
  selectedOptions.filter(selectOption => options.some(opt => opt.id === selectOption.id));

const editOptions = (options: LabelOption[], selectedOptions: SelectedOptions[]) =>
  options.reduce((acc, option) => {
    const optionToReturn = selectedOptions.reduce((acc, selectedOption) => {
      if (selectedOption.id !== option.id) return acc;
      const isEditOption =
        selectedOption.id === option.id &&
        (selectedOption.color !== option.color || selectedOption.label !== option.label);

      if (isEditOption) {
        return [
          ...acc,
          {
            ...option,
            color: option.color,
            label: option.label,
            show: false,
            selected: selectedOption.selected,
          },
        ];
      }

      if (selectedOption.selected) {
        return [...acc, { ...selectedOption, show: false }];
      }

      return [...acc, selectedOption];
    }, []);

    const parsedOptionToReturn = optionToReturn.filter(
      (item, index, self) => index === self.findIndex(t => t.id === item.id),
    );

    if (parsedOptionToReturn.length) {
      return [...acc, ...parsedOptionToReturn];
    }
    return acc;
  }, []);
