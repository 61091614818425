import { connect } from 'react-redux';

import ScheduleBudgetTablePercentEstimatesTotalCell from './ScheduleBudgetTablePercentEstimatesTotalCell.jsx';

const mapStateToProps = state => ({
  shiftsSummary: state.reducer.schedule.budget.shiftsSummary,
  budgetEstimates: state.reducer.schedule.budget.budgetEstimates,
  summaryLabourLimit: state.reducer.schedule.budget.budgetEstimates.summary.labour_limit,
  dateArray: state.reducer.mainDateStore.dateArray,
});

export default connect(mapStateToProps)(ScheduleBudgetTablePercentEstimatesTotalCell);
