import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'onboarding.firstHello.email',
    defaultMessage: 'E-mail',
  },
  emailUsed: {
    id: 'onboarding.firstHello.emailUsed',
    defaultMessage: 'Podany e-mail został już użyty',
  },
});