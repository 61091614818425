export const DIRECTIONS = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

export enum FilterParamEnum {
  LOCATIONS = 'locations',
  SCHEDULE_LOCATIONS = 'scheduleLocations',
  JOB_TITLES = 'jobTitles',
  JOB_TITLES_GROUPED = 'jobTitlesGrouped',
  EMPLOYMENT_CONDITIONS = 'employmentConditions',
  DISPLAY_MODE = 'displayMode',
  FROM = 'from',
  TO = 'to',
  GROUPS = 'groups',
}

export const SKELETON_WIDTH = '56px';
export const SKELETON_HEIGHT = '18px';

export const SIDEBAR_OPEN_WIDTH = 300;
export const SIDEBAR_NARROW_OPEN_WIDTH = 250;
