import {
  ADD_EMPLOYMENT_CONDITION,
  CHANGE_EMPLOYMENT_CONDITION_FILTER,
  CLEAR_DEMO_DATA,
  INIT_EMPLOYMENT_CONDITION_FILTER,
} from '@/constants/ActionTypes.js';
import {
  employmentConditions as employmentConditionsConstant,
  employmentConditionsFilterOptions,
} from '@/constants/employmentConditions.js';

const initialState = {
  selected: [...employmentConditionsConstant, ...employmentConditionsFilterOptions],
};

const employmentConditionsFilter = (state = initialState, action) => {
  switch (action.type) {
    case INIT_EMPLOYMENT_CONDITION_FILTER:
      return {
        ...state,
        selected: [...state.selected, ...action.payload],
      };
    case CHANGE_EMPLOYMENT_CONDITION_FILTER:
      return {
        ...state,
        selected: action.payload,
      };
    case CLEAR_DEMO_DATA:
      return initialState;
    case ADD_EMPLOYMENT_CONDITION:
      return {
        ...state,
        selected: [...state.selected, action.payload],
      };
    default:
      return state;
  }
};
export default employmentConditionsFilter;
