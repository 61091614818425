import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import { ROLE_MODAL } from '@/constants/modalTypes';
import { compareRoleObjects } from '@/utils/objectHelpers';

class RolesButtonBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
    this.saveRoles = this.saveRoles.bind(this);
  }

  saveRoles(permissions) {
    if (this.state.saving) return;

    this.setState({ saving: true });
    this.props.saveRoles(permissions).finally(() => this.setState({ saving: false }));
  }

  render() {
    const { preCheckedPermissions, checkedPermissions, showModal } = this.props;
    const canPublish = !compareRoleObjects(preCheckedPermissions, checkedPermissions);
    const publishButton = classnames('small fixedWidth', {
      orange: canPublish,
      'disabled reverse-orange': !canPublish,
    });

    return (
      <ButtonBar>
        <Button modifiers={publishButton} onClick={() => this.saveRoles(checkedPermissions)}>
          <i className="material-icons">send</i>
          <FormattedMessage id="roles.saveRole" defaultMessage="Zapisz role" />
        </Button>
        <Button modifiers="blue medium inline pushLeft" onClick={() => showModal(ROLE_MODAL)}>
          <i className="material-icons">add</i>
          <FormattedMessage id="roles.addRole" defaultMessage="Dodaj rolę" />
        </Button>
      </ButtonBar>
    );
  }
}

RolesButtonBar.propTypes = {
  showModal: PropTypes.func,
  checkedPermissions: PropTypes.shape({}),
  preCheckedPermissions: PropTypes.shape({}),
  saveRoles: PropTypes.func,
};

export default RolesButtonBar;
