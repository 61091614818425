import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  authCodeTooltip: {
    id: 'devices.authCodeTooltip',
    defaultMessage:
      'Kod urządzenia znajdziesz w naszej aplikacji mobilnej Kadromierz RCP i jest potrzebny w celu autoryzacji tego urządzenia. {clickHere}, aby dowiedzieć się więcej',
  },
  takePhotosTooltip: {
    id: 'devices.takePhotosTooltip',
    defaultMessage: 'Zaznacz tę opcje, aby uruchomić fotorejestracje przy każdym zdarzeniu wejścia oraz wyjścia',
  },
  managerGroupLoginTooltip: {
    id: 'devices.managerGroupLoginTooltip',
    defaultMessage:
      'Zaznacz tę opcje, aby pozwolić użytkownikowi z rolą Manager zarejestrować zdarzenia wejścia/wyjścia dla grupy pracowników',
  },
  loginMethodPinTooltip: {
    id: 'devices.loginMethodPinTooltip',
    defaultMessage: 'Zaznacz tę metodę jeśli do rejestracji chcesz używać 4-cyfrowego kodu PIN',
  },
  loginMethodQrTooltip: {
    id: 'devices.loginMethodQrTooltip',
    defaultMessage: 'Zaznacz tę metodę jeśli do rejestracji chcesz używać wygenerowanego kodu QR',
  },
  loginMethodRfidTooltip: {
    id: 'devices.loginMethodRfidTooltip',
    defaultMessage:
      'Zaznacz tę metodę jeśli używasz czytnika, przekazującego numer karty jako ciąg 10 cyfr i emulującego naciśniecie klawisza Enter',
  },
  loginMethodNfcTooltip: {
    id: 'devices.loginMethodNfcTooltip',
    defaultMessage: 'Zaznacz tę metodę jeśli używasz wbudowanego w tablet/telefon czytnika NFC',
  },
  autologinTooltip: {
    id: 'devices.autologinTooltip',
    defaultMessage:
      'Zaznacz tę opcję, jeśli chcesz mieć możliwość automatycznego rozpoczynania obecności w ustawieniach aplikacji na urządzeniu.',
  },
  autologinTimeLabel: {
    id: 'devices.autologinTimeLabel',
    defaultMessage: 'Czas automatycznego START/STOP (sekundy)',
  },
  clickHere: {
    id: 'common.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ',
  },
  changeSettingsDevice: {
    id: 'devices.settings',
    defaultMessage: 'Edytuj ustawienia urządzenia',
  },
  deviceNotFoundByAuthCode: {
    id: 'devices.deviceNotFoundByAuthCode',
    defaultMessage: 'Nie znaleziono urządzenia z tym kodem',
  },
  loginMethodsError: {
    id: 'devices.loginMethodsError',
    defaultMessage: 'Przynajmniej jedna metoda logowania musi być włączona',
  },
  noLocationsSelectedError: {
    id: 'devices.noLocationsSelectedError',
    defaultMessage: 'Przynajmniej jedna lokalizacja musi zostać wybrana',
  },
  addDevice: {
    id: 'companymanage.devices.addDevice',
    defaultMessage: 'Dodaj urządzenie',
  },
  deviceName: {
    id: 'companymanage.devices.name',
    defaultMessage: 'Nazwa urządzenia',
  },
  authCode: {
    id: 'companymanage.devices.authCode',
    defaultMessage: 'Kod urządzenia',
  },
  locationsTitle: {
    id: 'companymanage.devices.locations',
    defaultMessage: 'Lokalizacje',
  },
  chooseLocation: {
    id: 'companymanage.devices.chooseLocation',
    defaultMessage: 'Wybierz lokalizację',
  },
  loginMethodPin: {
    id: 'companymanage.devices.loginMethodPin',
    defaultMessage: 'PIN',
  },
  loginMethodQr: {
    id: 'companymanage.devices.loginMethodQr',
    defaultMessage: 'KOD QR',
  },
  loginMethodRfid: {
    id: 'companymanage.devices.loginMethodRfid',
    defaultMessage: 'RFID',
  },
  loginMethodNfc: {
    id: 'companymanage.devices.loginMethodNfc',
    defaultMessage: 'NFC',
  },
  autologin: {
    id: 'companymanage.devices.autologin',
    defaultMessage: 'Automatyczny START/STOP',
  },
});
