import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import ColorPicker from '@/components/common/ColorPicker';
import IconPicker from '@/components/common/inputs/IconPicker/IconPicker';
import { KadroMultiSelect, MDTextInput } from '@/components/common/inputs/MDComponents';
import Select from '@/components/common/inputs/MDSelect/Select';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { BUDGET_ICONS } from '@/constants/budgetMetrics';
import { useInputChange, useMultiSelect } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import {
  createBudgetMetricObject,
  getAggregationOptions,
  getInitialState,
  getLocationsMessages,
  getLocationsOptions,
  getUpdatedStateForEdit,
  inputModifiers,
  modalModifiers,
  timeIntervalOptions,
  typeOptions,
} from './AddOrEditBudgetMetricModal.helpers';
import { messages } from './messages';

const AddOrEditBudgetMetricModal = ({
  visible,
  hideModal,
  editedBudgetMetricId,
  createBudgetMetric,
  editBudgetMetric,
  userBudgetMetrics,
  userLocations,
  intl,
}) => {
  const locationsOptions = getLocationsOptions(userLocations);
  const [state, handlers] = useInputChange(getInitialState(), intl);
  const [locationItems, multiSelectHandlers] = useMultiSelect(locationsOptions);

  const clearModal = useCallback(() => {
    handlers.setInitState();
    multiSelectHandlers.setItems(locationsOptions);
  }, []);

  const hideMetricsModal = useCallback(() => {
    hideModal();
  }, []);

  const changeColor = useCallback(color => {
    handlers.changeInput(createEvent('color', color));
  }, []);

  const validateAll = async () => {
    const inputs = ['budgetMetricName', 'budgetMetricType', 'timeInterval', 'settings'];

    const validationResult = await Promise.all(
      inputs.map(inputName => handlers.validateInput(createEvent(inputName, state[inputName]))),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const submit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;

    const budgetMetricObject = createBudgetMetricObject(state, locationItems);
    if (editedBudgetMetricId) {
      editBudgetMetric({ ...budgetMetricObject, id: editedBudgetMetricId });
    } else {
      createBudgetMetric(budgetMetricObject);
    }
    hideMetricsModal();
  });

  const selectedLocations = locationItems.filter(({ active }) => active);

  useEffect(() => {
    if (!editedBudgetMetricId) return;
    const newState = { ...state, ...getUpdatedStateForEdit(editedBudgetMetricId, userBudgetMetrics) };
    handlers.updateState(newState);
    multiSelectHandlers.setActiveItems(newState.locationIds || []);
  }, [editedBudgetMetricId]);

  return (
    <MDKadroModal
      className="k-addOrEditBudgetMetricModal"
      show={visible}
      onHide={hideMetricsModal}
      onHideEnd={clearModal}
      title={intl.formatMessage(messages[editedBudgetMetricId ? 'editMetricTitle' : 'addMetricTitle'])}
      modifiers={modalModifiers}
      onSubmit={submit}
    >
      <MDTextInput
        value={state.budgetMetricName}
        onChange={handlers.changeInput}
        onBlur={handlers.validateInput}
        id="budgetMetricName"
        label={intl.formatMessage(messages.name)}
        errorMessage={state.errors.budgetMetricName}
        modifiers={inputModifiers}
      />
      <Select
        defaultValue={state.budgetMetricType}
        onChange={e => handlers.changeInput({ target: { name: 'budgetMetricType', value: e } })}
        onBlur={handlers.validateInput}
        id="budgetMetricType"
        name={intl.formatMessage(messages.type)}
        label={intl.formatMessage(messages.type)}
        errorMessage={state.errors.budgetMetricType}
        options={typeOptions}
        tooltip={intl.formatMessage(messages.typeTooltip)}
        className="k-addOrEditBudgetMetricModal__select"
        dropdownClassname="k-addOrEditBudgetMetricModal__selectDropdown"
      />
      <Select
        defaultValue={state.timeInterval}
        onChange={e => handlers.changeInput({ target: { name: 'timeInterval', value: e } })}
        onBlur={handlers.validateInput}
        id="timeInterval"
        name={intl.formatMessage(messages.timeInterval)}
        label={intl.formatMessage(messages.timeInterval)}
        errorMessage={state.errors.timeInterval}
        options={timeIntervalOptions}
        tooltip={intl.formatMessage(messages.timeIntervalTooltip)}
        className="k-addOrEditBudgetMetricModal__select"
        dropdownClassname="k-addOrEditBudgetMetricModal__selectDropdown"
      />
      <Select
        defaultValue={state.settings.aggregation}
        onChange={e => handlers.updateState({ settings: { aggregation: e } })}
        onBlur={handlers.validateInput}
        id="aggregation"
        name={intl.formatMessage(messages.aggregation)}
        label={intl.formatMessage(messages.aggregation)}
        errorMessage={state.errors.settings}
        options={getAggregationOptions(intl)}
        tooltip={intl.formatMessage(messages.aggregationTooltip)}
        className="k-addOrEditBudgetMetricModal__select"
        dropdownClassname="k-addOrEditBudgetMetricModal__selectDropdown"
      />
      <fieldset className="form-group">
        <label className="k-textInput__label k-textInput__label--side">{intl.formatMessage(messages.icon)}</label>
        <IconPicker
          icons={BUDGET_ICONS}
          defaultIcon={state.icon}
          onPick={e => handlers.changeInput({ target: { name: 'icon', value: e } })}
        />
      </fieldset>
      <fieldset className="form-group">
        <label className="k-textInput__label k-textInput__label--side">{intl.formatMessage(messages.color)}</label>
        <ColorPicker color={state.color} colorFunc={changeColor} />
      </fieldset>
      <KadroMultiSelect
        messages={getLocationsMessages(intl, selectedLocations)}
        items={locationItems}
        handlers={multiSelectHandlers}
        withoutFooter
      />
    </MDKadroModal>
  );
};

AddOrEditBudgetMetricModal.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  visible: PropTypes.bool,
  hideModal: PropTypes.func,
  createBudgetMetric: PropTypes.func,
  editBudgetMetric: PropTypes.func,
  editedBudgetMetricId: PropTypes.string,
  userBudgetMetrics: PropTypes.arrayOf(PropTypes.shape({})),
};

export default injectIntl(AddOrEditBudgetMetricModal);
