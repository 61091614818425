import { PropTypes } from 'prop-types';
import { defineMessages } from 'react-intl';
import { BarChart } from '../common/charts';
import { getBarData, getBarSeries } from './rechart.utils';

const chartMessages = defineMessages({
  attendance: {
    id: 'reports.attendance',
    defaultMessage: 'Obecności',
  },
  lateAtWrok: {
    id: 'reports.lateAtWrok',
    defaultMessage: 'Spóźnienia',
  },
  absences: {
    id: 'reports.absences',
    defaultMessage: 'Nieobecności',
  },
});

const AttendanceChart = (props, context) => {
  const { attendanceData } = props;
  const data = {
    labels: props.mainDateStore.dateArray,
    datasets: [
      {
        label: context.intl.formatMessage(chartMessages.attendance, {}),
        backgroundColor: 'rgba(134, 191, 0,1)',
        borderColor: 'rgba(134, 191, 0,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(134, 191, 0,0.8)',
        hoverBorderColor: 'rgba(134, 191, 0,1)',
        data: attendanceData.map((d) => d.attendances),
      },
      {
        label: context.intl.formatMessage(chartMessages.lateAtWrok, {}),
        backgroundColor: 'rgba(253, 206, 9,1)',
        borderColor: 'rgba(253, 206, 9, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(253, 206, 9, 0.8)',
        hoverBorderColor: 'rgba(253, 206, 9,1)',
        data: attendanceData.map((d) => d.lateAtWrok),
      },
      {
        label: context.intl.formatMessage(chartMessages.absences, {}),
        backgroundColor: 'rgba(255, 0, 0,1)',
        borderColor: 'rgba(255, 0, 0,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 0, 0,0.8)',
        hoverBorderColor: 'rgba(255, 0, 0,1)',
        data: attendanceData.map((d) => d.absences),
      },
    ],
  };

  return (
    <div>
      <BarChart xAxisAngle={data.labels.length < 14 ? 0 : -45} data={getBarData(data)} series={getBarSeries(data)} xKey='label'/>
    </div>
  );
};

AttendanceChart.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendanceChart.propTypes = {
  attendanceData: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default AttendanceChart;
