import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { defineMessages } from 'react-intl';

import AddLocationModal from '@/components/companymanage/locations/AddLocationModal/AddLocationModal.redux';
import EmptyState from '@/components/emptyState/EmptyState.jsx';

const messages = defineMessages({
  emptyStateTitle: {
    id: 'scheduleView.emptyStateLocationTitle',
    defaultMessage: 'Brak dodanych lokalizacji ',
  },
  emptyStateMsg: {
    id: 'scheduleView.emptyStateMsg',
    defaultMessage: 'Zanim utworzysz grafik ',
  },
  ctaText: {
    id: 'scheduleView.ctaLocationText',
    defaultMessage: 'dodaj lokalizację',
  },
});

const EmptyStateLocationView = (props, context) => (
  <Fragment>
    <AddLocationModal />
    <tr>
      <td className="no-content-to-show">
        <EmptyState
          name="locationCTA"
          imgSrc="/img/locationsCTA.png"
          emptyStateHeader={context.intl.formatMessage(messages.emptyStateTitle, {})}
          emptyStateText={context.intl.formatMessage(messages.emptyStateMsg, {})}
          ctaHandler={() => {
            props.toggleModal('locations');
          }}
          filerStyle={{
            width: '17vw',
            minWidth: '200px',
            maxWidth: '260px',
          }}
          ctaText={context.intl.formatMessage(messages.ctaText, {})}
        />
      </td>
    </tr>
  </Fragment>
);

EmptyStateLocationView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmptyStateLocationView.propTypes = {
  toggleModal: PropTypes.func,
};

export default EmptyStateLocationView;
