import { getSelectedWithBorderCell } from '@/actions/schedule/quickPlanning.helpers';
import { getInitialFreeDaysSummary } from '@/actions/schedule/quickPlanning/freeDaysQP';
import { getInitialShiftsSummary } from '@/actions/schedule/quickPlanning/quickPlanning.helpers';
import * as AT from '@/constants/ActionTypes.js';

import { mergeCellsToCurrentStructure, removeCellsFromCurrentStructure } from './quickPlanning.helpers';

const initialState = {
  enabled: false,
  selected: {},
  position: {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    borderCell: { employeeId: null, date: null },
  },
  downItem: {},
  hoveredItems: {},
  datesRanges: {},
  employeesIdsRanges: {},
  copiedMarkedArea: { borderTop: {}, borderBottom: {}, borderLeft: {}, borderRight: {} },
  shiftsToCopy: {},
  freeDaysToCopy: {},
  freeDaysSummary: getInitialFreeDaysSummary(),
  shiftsSummary: getInitialShiftsSummary(),
  specialSelections: {
    isOnlyOneColumnCopied: false,
    isOnlyOneRowCopied: false,
    isOnlyOneShiftCopied: false,
    isOnlyOneFreeDayCopied: false,
  },
  translationWithShift: { x: 0, y: 0 },
  workingHours: '',
  freeDayShortcut: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.TOGGLE_QUICK_PLANNING:
      return { ...state, enabled: !state.enabled };
    case AT.QUICK_PLANNING_TOGGLE_SINGLE_SELECTION: {
      const { employeeId, date, lastBorderCell } = action.payload;
      const wasCellSelected = (state.selected[employeeId] || {})[date];
      const newSelected = wasCellSelected
        ? removeCellsFromCurrentStructure(state.selected, [action.payload])
        : mergeCellsToCurrentStructure(getSelectedWithBorderCell(state.selected, lastBorderCell), [
            { employeeId, date },
          ]);
      return {
        ...state,
        selected: newSelected,
        translationWithShift: { x: 0, y: 0 },
      };
    }
    case AT.QUICK_PLANNING_SET_NEW_SELECTED:
      return {
        ...state,
        selected: mergeCellsToCurrentStructure({}, action.payload),
        workingHours: '',
        freeDayShortcut: '',
      };
    case AT.QUICK_PLANNING_ADD_TO_SELECTED: {
      return {
        ...state,
        selected: mergeCellsToCurrentStructure(state.selected, action.payload),
        translationWithShift: { x: 0, y: 0 },
      };
    }
    case AT.QUICK_PLANNING_REMOVE_FROM_SELECTED: {
      return {
        ...state,
        selected: removeCellsFromCurrentStructure(state.selected, action.payload),
        translationWithShift: { x: 0, y: 0 },
      };
    }
    case AT.QUICK_PLANNNING_SET_HOVERED_ITEMS: {
      return { ...state, hoveredItems: mergeCellsToCurrentStructure({}, action.payload) };
    }
    case AT.QUICK_PLANNING_SET_POSITION:
      return { ...state, position: action.payload };
    case AT.QUICK_PLANNING_DYNAMIC_SET_ITEM_DOWN: {
      return { ...state, downItem: action.payload };
    }
    case AT.QUICK_PLANNING_SET_DATA_TO_COPY:
      return { ...state, ...action.payload };
    case AT.QUICK_PLANNING_SET_TRANSLATION_WITH_SHIFT: {
      return { ...state, translationWithShift: action.payload };
    }
    case AT.CHANGE_SCHEDULE_LOCATION_FILTER: {
      return { ...state, enabled: state.enabled && action.payload.length === 1 };
    }
    case AT.QUICK_PLANNING_SET_SHIFTS_SUMMARY: {
      return { ...state, shiftsSummary: action.payload };
    }
    case AT.QUICK_PLANNING_SET_FREE_DAYS_SUMMARY: {
      return { ...state, freeDaysSummary: action.payload };
    }
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL:
    case AT.QUICK_PLANNING_CLEAR_SELECTION: {
      return {
        ...state,
        selected: {},
        shiftsToCopy: {},
        freeDaysToCopy: {},
        copiedMarkedArea: { borderTop: {}, borderBottom: {}, borderLeft: {}, borderRight: {} },
        workingHours: '',
        freeDayShortcut: '',
      };
    }
    case AT.QUICK_PLANNING_SET_WORKING_HOURS_FOR_NEW_SHIFT: {
      return { ...state, workingHours: action.payload };
    }
    case AT.QUICK_PLANNING_SET_SHORTCUT_FOR_NEW_FREE_DAY: {
      return { ...state, freeDayShortcut: action.payload };
    }
    case AT.MAIN_DATE_CHANGE_MODE: {
      const newMode = action.payload;

      if (newMode === 'day') {
        return initialState;
      }

      return state;
    }
    case AT.MAIN_DATE_CHANGE_MODE_AND_DATE:
    case AT.SET_MAIN_DATE_STORE: {
      const { dateMode: newMode } = action.payload;

      if (newMode === 'day') {
        return initialState;
      }

      return state;
    }
    default:
      return state;
  }
};
export default reducer;
