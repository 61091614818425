import PropTypes from 'prop-types';

import { checkIsEditEnable } from '../../ScheduleBudgetTable.helpers.js';
import ScheduleBudgetTableSpmhCell from './ScheduleBudgetTableSpmhCell.redux.js';
import ScheduleBudgetTableSpmhTotalCell from './ScheduleBudgetTableSpmhTotalCell.redux.js';

const ScheduleBudgetTableSpmh = ({
  summaryTargetSpmh,
  dateArray,
  locationSettings,
  scheduleLocationFilter,
  onEstimateChange,
  setCellError,
}) => {
  const canEdit = checkIsEditEnable(scheduleLocationFilter, locationSettings);

  return (
    <tr>
      <ScheduleBudgetTableSpmhTotalCell total={summaryTargetSpmh} />
      {dateArray.map(date => (
        <ScheduleBudgetTableSpmhCell
          key={date}
          canEdit={canEdit}
          date={date}
          onEstimateChange={onEstimateChange}
          setCellError={setCellError}
        />
      ))}
    </tr>
  );
};

ScheduleBudgetTableSpmh.propTypes = {
  dateArray: PropTypes.arrayOf(PropTypes.string),
  locationSettings: PropTypes.shape({
    budget_estimates_period: PropTypes.string,
  }),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  summaryTargetSpmh: PropTypes.number,
  onEstimateChange: PropTypes.func,
  setCellError: PropTypes.func,
};

export default ScheduleBudgetTableSpmh;
