import { CHANGE_LOCATION_FILTER, CLEAR_DEMO_DATA } from '@/constants/ActionTypes.js';

const initialState = {
  selectedLocation: {
    id: 'Empty',
    name: 'Brak lokalizacji!',
  },
};

const locationFilter = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCATION_FILTER:
      return Object.assign({}, state, { selectedLocation: action.payload });
    case CLEAR_DEMO_DATA:
      return initialState;
    default:
      return state;
  }
};
export default locationFilter;
