import { FormattedMessage } from 'react-intl';

import { ActivityLog, ActivityLogDisplayData } from '@/types/activityLog.types.ts';
import { timestampsToWorkingHours } from '@/utils/dateHelper.js';

export const getActivityLogObjectChanges = (log: ActivityLog, displayData: ActivityLogDisplayData): JSX.Element => {
  const { old_values: oldValues, new_values: newValues } = log;
  if (!oldValues || !newValues) return;
  const changes = [];
  if (oldValues.user_id !== newValues.user_id) {
    const oldEmployee = displayData.employees[Number(oldValues.user_id)];
    const newEmployee = displayData.employees[Number(newValues.user_id)];
    changes.push(
      <FormattedMessage
        id="objectHistory.employeeChange"
        defaultMessage="Zmiana została wymieniona z pracownika {before} na {after}"
        values={{
          before: (
            <span className="objectHistoryModal__value">
              {oldEmployee.first_name} {oldEmployee.last_name}
            </span>
          ),
          after: (
            <span className="objectHistoryModal__value">
              {newEmployee.first_name} {newEmployee.last_name}
            </span>
          ),
        }}
      />,
    );
  }
  if (
    Boolean(oldValues.job_title_id) &&
    Boolean(newValues.job_title_id) &&
    oldValues.job_title_id !== newValues.job_title_id &&
    oldValues.user_id === newValues.user_id
  ) {
    const oldJobTitle = displayData.jobTitles[Number(oldValues.job_title_id)].title;
    const newJobTitle = displayData.jobTitles[Number(newValues.job_title_id)].title;
    changes.push(
      <FormattedMessage
        id="activityLog.jobTitleChange"
        defaultMessage="Stanowisko zmieniło się z {before} na {after}"
        values={{
          before: <span className="objectHistoryModal__value">{oldJobTitle}</span>,
          after: <span className="objectHistoryModal__value">{newJobTitle}</span>,
        }}
      />,
    );
  }
  if (
    oldValues.start_date !== newValues.start_date ||
    oldValues.end_date !== newValues.end_date ||
    oldValues.start_timestamp !== newValues.start_timestamp || oldValues.end_timestamp !== newValues.end_timestamp
  ) {
    changes.push(
      <FormattedMessage
        id="activityLog.workingHoursChange"
        defaultMessage="Godzina zmieniła się z {before} na {after}"
        values={{
          before: (
            <span className="objectHistoryModal__value">
              {timestampsToWorkingHours(
                oldValues.start_date || oldValues.start_timestamp,
                oldValues.end_date || oldValues.end_timestamp,
              )}
            </span>
          ),
          after: (
            <span className="objectHistoryModal__value">
              {timestampsToWorkingHours(
                newValues.start_date || newValues.start_timestamp,
                newValues.end_date || newValues.end_timestamp,
              )}
            </span>
          ),
        }}
      />,
    );
  }
  return (
    <>
      {changes.map((change, index) => (
        <div key={index}>{change}</div>
      ))}
    </>
  );
};
