import { availabilityModesIds } from '@/constants/viewDateModes.ts';

import TopBarCalendar from './filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBar from './TopBar.jsx';

const TopBarAvailability = () => (
  <TopBar leftElements={[<TopBarCalendar key="calswitch" modes={availabilityModesIds} />]} rightElements={[]} />
);

TopBarAvailability.propTypes = {};

export default TopBarAvailability;
