import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { getRolesAvailableForCurrentUser, getRoleWithTheHighestRank } from '@/utils/userEmployeesHelpers.js';

import './RolesDeleteModal.scss';

const Y_OFFSET_ROLE_SELECT = -33;

const messages = defineMessages({
  permissionRoleDeleteModalTitle: {
    id: 'roles.deleteModal.title',
    defaultMessage: 'Usuń rolę',
  },
  permissionRoleDeleteModalConfirmText: {
    id: 'roles.deleteModal.confirmText',
    defaultMessage: 'Dalej',
  },
  selectRole: { id: 'roles.selectRole', defaultMessage: 'Wybierz rolę' },
});

class RolesDeleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleToReplace: '',
    };
    bindPrototypeFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showModal, companyRoles, currentUser, sameRankIsVisible, modalObject } = this.props;

    const roleWithTheHighestRank = getRoleWithTheHighestRank(companyRoles, currentUser, sameRankIsVisible, modalObject);
    if (showModal && showModal !== prevProps.showModal) {
      this.setState({
        roleToReplace: roleWithTheHighestRank,
      });
    }
  }

  onSubmit() {
    this.props.deleteRoleConfirm(this.props.modalObject.id, this.state.roleToReplace);
  }

  handleSelectChange(value) {
    this.setState({
      roleToReplace: this.props.companyRoles.find(role => role.id === value),
    });
  }

  render() {
    const { currentUser, companyRoles, userPermissions, modalObject, showModal, hideModal, companySettings } =
      this.props;
    const { formatMessage } = this.context.intl;

    const relevantOptions = getRolesAvailableForCurrentUser(
      currentUser,
      companyRoles,
      userPermissions,
      null,
      companySettings.same_rank_is_visible,
    )
      .filter(role => role.id !== modalObject.id)
      .map(role => ({
        key: role.name,
        value: role.id,
      }));
    return (
      <MDKadroModal
        show={showModal}
        title={formatMessage(messages.permissionRoleDeleteModalTitle, {})}
        confirmText={formatMessage(messages.permissionRoleDeleteModalConfirmText, {})}
        onSubmit={this.onSubmit}
        onHide={hideModal}
        modifiers={['narrow']}
        withoutOverlayScroll
      >
        <FormattedMessage
          id="roles.deleteModal.description"
          defaultMessage="Wybierz rolę którą chcesz zastąpić usuwaną rolę"
        />
        <MDSelectPopover
          id="templateToReplace"
          name={formatMessage(messages.selectRole)}
          options={relevantOptions}
          onChange={this.handleSelectChange}
          closeOnClick
          defaultValue={this.state.roleToReplace.id}
          yDropdownOffset={Y_OFFSET_ROLE_SELECT}
        />
      </MDKadroModal>
    );
  }
}

RolesDeleteModal.defaultProps = {
  modalObject: { id: null },
};

RolesDeleteModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

RolesDeleteModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    role_id: PropTypes.string,
  }),
  showModal: PropTypes.bool,
  modalObject: PropTypes.shape({
    id: PropTypes.string,
  }),
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  deleteRoleConfirm: PropTypes.func,
  hideModal: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  companySettings: PropTypes.shape({
    same_rank_is_visible: PropTypes.bool,
  }),
  sameRankIsVisible: PropTypes.bool,
};

export default RolesDeleteModal;
