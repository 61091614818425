import {
  ADD_DEVICES_SUCCESFUL,
  DELETE_DEVICES_SUCCESFUL,
  GET_DEVICES_SUCCESFUL,
  SAVE_SETTINGS_DEVICES_SUCCESFUL,
} from '@/constants/ActionTypes';

const initialState = [];

const devices = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_SUCCESFUL:
      return [...state, ...action.payload];
    case SAVE_SETTINGS_DEVICES_SUCCESFUL:
      return state.map(device => {
        if (action.payload[0].device_uuid === device.uuid) {
          return {
            ...device,
            settings: action.payload[0],
          };
        }
        return device;
      });
    case ADD_DEVICES_SUCCESFUL:
      return [...state, action.payload];
    case DELETE_DEVICES_SUCCESFUL:
      return state.filter(device => !action.payload.includes(device.uuid));
    default:
      return state;
  }
};

export default devices;
