import { connect } from 'react-redux';

import { showDeleteCurrentUserShiftConfirmModal } from '@/actions/employeeTimesheet/employeeTimesheet';
import { showModal } from '@/actions/uiState';

import EmployeeTimesheetOptions from './EmployeeTimesheetOptions';

const mapStateToProps = state => ({
  locationSettings: state.reducer.settings.locationSettings,
});

const mapDispatchToProps = {
  showModal,
  showDeleteCurrentUserShiftConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimesheetOptions);
