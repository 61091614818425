import { CREATE_LABEL, DELETE_LABEL, GET_LABELS, UPDATE_LABEL } from '@/constants/ActionTypes';

const initialState = [];

const labels = (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS:
      return action.payload;
    case CREATE_LABEL:
      return [...state, action.payload];
    case UPDATE_LABEL:
      return state.map(label => (label.id === action.payload.id ? action.payload : label));
    case DELETE_LABEL:
      return state.filter(label => !action.payload.includes(label.id));
    default:
      return state;
  }
};

export default labels;
