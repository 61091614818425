import { connect } from 'react-redux';

import { getSystemBonusExportData } from '@/actions/reports';

import ReportsButtonBar from './ReportsButtonBar';

const mapDispatchToProps = {
  getSystemBonusExportData,
};

export default connect(null, mapDispatchToProps)(ReportsButtonBar);
