import PropTypes from 'prop-types';

import HoverPopOver from './HoverPopOver.jsx';

import './PopOverCorner.scss';

const PopOverCorner = props => {
  const { children, key, popover, materialIcon } = props;

  return (
    <div key={key} className="popOverCorner">
      <HoverPopOver name="popOverCorner" popover={popover}>
        <div className="material-icons popOverCorner__icon">{materialIcon}</div>
      </HoverPopOver>
      <div className="popOverCorner__content">{children}</div>
    </div>
  );
};

PopOverCorner.propTypes = {
  popover: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  materialIcon: PropTypes.string,
  children: PropTypes.node,
  key: PropTypes.string,
};

export default PopOverCorner;
