import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionsButton from '@/components/common/Basic/ActionsButton/ActionsButton.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import ScheduleSettingsButton from '@/components/scheduler/Buttons/ScheduleSettingsButton/ScheduleSettingsButton.redux';
import { EMPLOYEE_SCHEDULE_SETTING_TYPES } from '@/constants/settingsConstants';

const EmployeeScheduleButtonBar = props => {
  const { userEmployees, currentUserId, exportEmployeeScheduleData, scheduleSettings } = props;
  const employee = useMemo(() => {
    const currentEmployee = userEmployees.find(({ id }) => id === currentUserId);
    const loanedShifts = currentEmployee.shifts_for_other_locations.filter(({ isLoaned }) => isLoaned);
    return { ...currentEmployee, shifts: [...currentEmployee.shifts, ...loanedShifts] };
  }, [userEmployees, currentUserId]);

  const exportSchedule = useCallback(() => exportEmployeeScheduleData('PDF', { employee }), [employee]);
  const filteredScheduleSettings = useMemo(
    () => scheduleSettings.filter(el => EMPLOYEE_SCHEDULE_SETTING_TYPES.includes(el.type)),
    [scheduleSettings],
  );

  return (
    <ButtonBar>
      <ActionsButton icon={<Icon name="more_vert" />}>
        <button className="k-actionsButton__element" onClick={exportSchedule}>
          <FormattedMessage id="export" defaultMessage="Eksport" />
        </button>
      </ActionsButton>
      <ScheduleSettingsButton iconName="settings" scheduleSettings={filteredScheduleSettings} />
    </ButtonBar>
  );
};

EmployeeScheduleButtonBar.propTypes = {
  userEmployees: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  currentUserId: PropTypes.string,
  exportEmployeeScheduleData: PropTypes.func,
  scheduleSettings: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(EmployeeScheduleButtonBar);
