import moment from 'moment';

import { calendarRangeOptions } from '@/constants/calendarRangeOptions';
import { initialMinMaxDates } from '@/constants/dates';
import { viewDateModes } from '@/constants/viewDateModes';
import { convertDateToStandardFormat as convertDate } from '@/utils/dateHelper';

export const getStaticCalendarRanges = (mainDateStore, modes = [], selectedRange) => {
  const { dateArray } = mainDateStore;
  return calendarRangeOptions.reduce((list, option) => {
    if (modes.includes(option.dateMode))
      return [
        ...list,
        {
          label: option.label,
          range: () => ({
            startDate: option.startDate,
            endDate: option.endDate,
            isStaticRange: true,
          }),
          isSelected: () =>
            selectedRange
              ? convertDate(option.startDate) === convertDate(selectedRange.start) &&
                convertDate(option.endDate) === convertDate(selectedRange.end)
              : convertDate(option.startDate) === dateArray[0],
        },
      ];
    return list;
  }, []);
};

export const getMinAndMaxDate = (startDate, currentRoute) => {
  const daysLimit = viewDateModes[currentRoute]?.maxDayDiff;
  if (!daysLimit) return initialMinMaxDates;
  const min = new Date(moment(startDate).subtract(daysLimit, 'd'));
  const max = new Date(moment(startDate).add(daysLimit, 'd'));
  return { min, max };
};
