import PropTypes from 'prop-types';
import { createRef, useCallback, useEffect, useState } from 'react';

import KadroModal from '@/components/common/KadroModal.jsx';
import { inputValidation } from '@/utils/inputValidation.js';
import { payrollEditShiftsAndAttendancesModalOnSubmit } from '@/utils/payrollHelpers';
import { getEmployeeJobTitlesAsSelectOptions } from '@/utils/userEmployeesHelpers.js';

import AttendanceEditDataView from './AttendanceEditDataView.jsx';
import ListSection from './ListSection.jsx';
import { getInitialState, getStateFromModalObject, messages } from './PayrollEditModalHelpers.js';
import ShiftEditDataView from './ShiftEditDataView.redux';

import './PayrollEditModal.scss';

const PayrollEditModal = (props, context) => {
  const { modalObject, hideModal, contracts, userJobTitles } = props;
  const [state, setState] = useState(getInitialState());
  const [breaksRefs, setBreaksRefs] = useState([]);

  const handleHide = () => {
    setState(getInitialState());
    setBreaksRefs([]);
    hideModal();
  };

  const handleShiftChange = useCallback(
    ({ name, value, shiftId }) => {
      const selectedShift = state.shifts.find(shift => shift.id === shiftId);
      const changedShift = { ...selectedShift, [name]: value };
      setState(prev => ({
        ...prev,
        shifts: prev.shifts.map(shift => (shift.id === shiftId ? changedShift : shift)),
      }));
    },
    [state.shifts],
  );

  const handleShiftDelete = useCallback(
    shiftId => {
      setState(prev => ({
        ...prev,
        shifts: prev.shifts.filter(shift => shift.id !== shiftId),
      }));
    },
    [state.shifts],
  );

  const handleAttendanceChange = useCallback(
    ({ name, value, attendanceId }) => {
      const selectedAttendance = state.attendances.find(attendance => attendance.id === attendanceId);
      const changedAttendance = { ...selectedAttendance, [name]: value };
      setState(prev => ({
        ...prev,
        attendances: prev.attendances.map(attendance =>
          attendance.id === attendanceId ? changedAttendance : attendance,
        ),
      }));
    },
    [state.attendances],
  );

  const handleAttendanceDelete = useCallback(
    attendanceId => {
      const filteredAttendances = state.attendances.filter(attendance => attendance.id !== attendanceId);
      setState(prev => ({
        ...prev,
        attendances: filteredAttendances,
        shifts: filteredAttendances === 0 ? [] : state.shifts,
      }));
    },
    [state.attendances, state.shifts],
  );

  const validateInput = useCallback(
    (type, data) =>
      new Promise((resolve, reject) => {
        const valuesToCompare = type === 'shiftsErrors' ? state.shifts : [];

        const errors = data.reduce((acc, val) => {
          const error = inputValidation('workingHours', val.workingHours, {
            valueToCompare: valuesToCompare.filter(c => c.id !== val.id && !c.isAvailability),
          });

          return error ? [...acc, error] : acc;
        }, []);

        setState(prev => ({
          ...prev,
          [type]: errors,
        }));

        if (errors.length) {
          reject(new Error({ [type]: errors }));
        }

        resolve();
      }),
    [state.shifts],
  );

  const validateAll = useCallback(async () => {
    const shiftsWorkingHours = state.shifts
      .filter(shift => !shift.isAvailability)
      .map(shift => ({ id: shift.id, workingHours: shift.working_hours }));
    const attendancesWorkingHours = state.attendances
      .filter(att => !att.isAvailability)
      .reduce((acc, val) => [...acc, { id: val.id, workingHours: val.newHours || val.hours }], []);

    const promises = [
      validateInput('shiftsErrors', shiftsWorkingHours),
      validateInput('attendancesErrors', attendancesWorkingHours),
    ];

    const errors = await Promise.all(promises.map(promise => promise.catch(err => err)));
    return !errors.some(err => err);
  }, [state.shifts, state.attendances, validateInput]);

  const handleSubmit = useCallback(async () => {
    const isValid = await validateAll();
    if (!isValid) {
      return;
    }

    const changedBreaks = breaksRefs.reduce((acc, bRef) => {
      try {
        return [...acc, ...bRef.current.getBreaksChanges()];
      } catch (error) {
        return acc;
      }
    }, []);
    if (changedBreaks.some(c => c.error)) {
      return;
    }

    payrollEditShiftsAndAttendancesModalOnSubmit(state, props);

    if (changedBreaks.length) {
      props.handleMultipleBreaksActions(changedBreaks, state.employee.id);
      changedBreaks.forEach(change => {
        if (change.action === 'delete' && change.attendance.breaks.filter(b => change.data.id !== b.id).length === 0) {
          const attendanceIndex = state.attendances.findIndex(attendance => attendance.id === change.attendance.id);
          setBreaksRefs(prev => prev.filter((_, i) => i !== attendanceIndex));
        }
      });
    }
    handleHide();
  }, [breaksRefs, handleHide, props, state, validateAll]);

  useEffect(() => {
    if (modalObject && modalObject.data) {
      const { detailsWithStartTime, ...newState } = getStateFromModalObject(modalObject);

      setBreaksRefs(detailsWithStartTime.map(() => createRef()));

      setState(prev => ({
        ...prev,
        ...newState,
      }));
    }
  }, [modalObject]);

  return (
    <KadroModal
      onSubmit={handleSubmit}
      showModal={props.show}
      onHide={handleHide}
      confirmText={context.intl.formatMessage(messages.confirmText, {})}
      title={context.intl.formatMessage(messages.title, {})}
      className="payrollEditModal"
    >
      <div className="payrollEditModal">
        <div className="payrollEditModal__header">
          <span>{`${state.employee?.first_name} ${state.employee?.last_name}`}</span>
          <span>{state.date}</span>
        </div>

        {state.shifts?.length > 0 && (
          <ListSection
            data={state.shifts}
            sectionName={context.intl.formatMessage(messages.shiftsSectionTitle, {})}
            render={(shift, i) => (
              <ShiftEditDataView
                shift={shift}
                index={i + 1}
                options={getEmployeeJobTitlesAsSelectOptions(contracts[state.employee.id], userJobTitles, shift.date)}
                onInputChange={handleShiftChange}
                onShiftDelete={handleShiftDelete}
                error={state.shiftsErrors[i]}
              />
            )}
          />
        )}
        <div className="payrollEditModal__separator" />
        {state.attendances?.length > 0 && (
          <ListSection
            data={state.attendances}
            sectionName={context.intl.formatMessage(messages.attendancesSectionTitle, {})}
            render={(attendance, i) => (
              <AttendanceEditDataView
                attendance={attendance}
                index={i + 1}
                onInputChange={handleAttendanceChange}
                onAttendanceDelete={handleAttendanceDelete}
                error={state.attendancesErrors[i]}
                ref={breaksRefs[i]}
              />
            )}
          />
        )}
      </div>
    </KadroModal>
  );
};

PayrollEditModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollEditModal.propTypes = {
  modalObject: PropTypes.shape({
    data: PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({
          isAbsence: PropTypes.bool,
          start_timestamp: PropTypes.string,
        }),
      ),
    }),
  }),
  payoutSetting: PropTypes.shape({ type: PropTypes.string }),
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  handleMultipleBreaksActions: PropTypes.func,
};

export default PayrollEditModal;
