import { connect } from 'react-redux';

import Component from './ExportScheduleModal.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.mainDateStore,
  displayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  userCustomTypes: state.reducer.userCustomTypes,
  absencesTypes: state.reducer.absences.absencesTypes,
});

const mapDispatchToProps = {};
const AddOpenShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export default AddOpenShiftModalContainer;
