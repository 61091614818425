import { connect } from 'react-redux';

import { deleteAbsenceTypes } from '@/actions/absences.js';
import { showModal } from '@/actions/uiState.js';

import ManageAbsenceTypesView from './ManageAbsenceTypesView.jsx';

const mapStateToProps = state => ({
  absencesTypes: state.reducer.absences.absencesTypes,
});

const mapDispatchToProps = { deleteAbsenceTypes, showModal };

const ManageAbsenceTypesViewContainer = connect(mapStateToProps, mapDispatchToProps)(ManageAbsenceTypesView);

export default ManageAbsenceTypesViewContainer;
