import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MDCheckbox from '@/components/common/inputs/MDCheckbox/MDCheckbox.jsx';
import RangePicker from '@/components/common/RangePicker/RangePicker.jsx';

import './RepeatShift.scss';

const RepeatShift = ({
  displayRepeat,
  activeRepeatTab,
  selectedRange,
  selectedChoices,
  selectedWeekdays,
  perWhichDay,
  handleInputChange,
  error,
  nonWorkingDays,
  toggleNonWorkingDays,
  repeatShiftMessage = <FormattedMessage id="shift.repeatShifts" defaultMessage="Powtórz zmiany" />,
  minDate,
  maxDate,
  hideNonWorkingDays,
}) => (
  <div className="repeatShift">
    <MDCheckbox
      value={displayRepeat}
      text={repeatShiftMessage}
      onChange={() => handleInputChange('displayRepeat', !displayRepeat)}
      reverse
    />
    {displayRepeat && (
      <div>
        <div>
          <RangePicker
            activeRepeatTab={activeRepeatTab}
            selectedRange={selectedRange}
            selectedChoices={selectedChoices}
            selectedWeekdays={selectedWeekdays}
            perWhichDay={perWhichDay}
            handleInputChange={handleInputChange}
            error={error}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        {!hideNonWorkingDays && (
          <div className="nonWorkingDays__checkbox">
            <MDCheckbox
              value={Boolean(nonWorkingDays)}
              text={<FormattedMessage id="shift.nonWorkingDays" defaultMessage="Pomijaj dni wolne od pracy" />}
              onChange={() => toggleNonWorkingDays(!nonWorkingDays)}
              reverse
            />
          </div>
        )}
      </div>
    )}
  </div>
);

RepeatShift.propTypes = {
  toggleNonWorkingDays: PropTypes.func,
  nonWorkingDays: PropTypes.bool,
  displayRepeat: PropTypes.bool,
  activeRepeatTab: PropTypes.string,
  selectedRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  selectedChoices: PropTypes.arrayOf(PropTypes.shape({})),
  selectedWeekdays: PropTypes.arrayOf(PropTypes.number),
  perWhichDay: PropTypes.string,
  handleInputChange: PropTypes.func,
  error: PropTypes.string,
  repeatShiftMessage: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  hideNonWorkingDays: PropTypes.bool,
};

export default RepeatShift;
