import { connect } from 'react-redux';

import { logoutUser } from '@/actions/auth';
import { hideModal } from '@/actions/uiState.js';
import { NO_LOCATIONS_MODAL } from '@/constants/modalTypes.js';

import NoLocationModal from './NoLocationModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === NO_LOCATIONS_MODAL,
});

const mapDispatchToProps = {
  hideModal,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoLocationModal);
