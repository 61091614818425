import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';

import { FREEMIUM_EVENT_LOCATION_SETTINGS_BONUS_SYSTEM } from '@/constants/intercom';
import { BUDGET_ESTIMATES_VIEW, CALCULATE_SPMH_BASED_BONUS } from '@/constants/Permissions';
import { FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW } from '@/constants/Restrictions';
import { OPTION_TYPE } from '@/constants/settingsConstants.js';

import { getBudgetEstimatesPeriodOptions } from './BudgetOptions.helpers';
import { messages } from './BudgetOptions.messages';

export const getBudgetOptions = (
  intl,
  handleInputChange,
  permissions,
  restrictions,
  { errors, bonusHoursBlocks, locationSystemBonusEnabled, bonusTargetOptions, enableBudgetTarget },
) => {
  const budgetEstimatesViewEnabled = permissions.includes(BUDGET_ESTIMATES_VIEW);
  const calculateSPMHBonusEnabled = permissions.includes(CALCULATE_SPMH_BASED_BONUS);
  const isBudgetTargetEnabled = !restrictions.includes(RESTRICTIONS.BUDGET_TARGETS_HIDE);

  if (!budgetEstimatesViewEnabled && !calculateSPMHBonusEnabled && !isBudgetTargetEnabled) return {};

  const options = [];

  if (budgetEstimatesViewEnabled) {
    options.push({
      name: intl.formatMessage(messages.budgetEstimatesPeriod),
      label: intl.formatMessage(messages.budgetEstimatesPeriodDesc),
      slug: 'budgetEstimatesPeriod',
      type: OPTION_TYPE.SELECT,
      handleInput: handleInputChange,
      options: getBudgetEstimatesPeriodOptions(intl),
    });
  }

  if (calculateSPMHBonusEnabled) {
    options.push({
      name: intl.formatMessage(messages.systemBonus),
      label: intl.formatMessage(messages.systemBonusDesc),
      slug: 'locationSystemBonusEnabled',
      type: OPTION_TYPE.BOOL,
      handleInput: handleInputChange,
    });
  }

  if (locationSystemBonusEnabled && bonusHoursBlocks) {
    options.push(
      {
        name: intl.formatMessage(messages.workHours),
        label: intl.formatMessage(messages.workHoursDesc),
        type: OPTION_TYPE.TITLE_WITH_DESC,
      },
      {
        rowName: intl.formatMessage(messages.shift),
        moreItemsText: intl.formatMessage(messages.addShiftHoursText),
        type: OPTION_TYPE.WORKING_HOURS_INPUTS,
        slug: 'bonusHoursBlocks',
        rowsData: bonusHoursBlocks,
        handleInput: handleInputChange,
        errorMessage: errors.bonusHoursOverlaps,
      },
    );
  }

  if (locationSystemBonusEnabled && bonusTargetOptions) {
    options.push(
      {
        name: intl.formatMessage(messages.spmhTarget),
        label: intl.formatMessage(messages.spmhTargetDesc),
        type: OPTION_TYPE.TITLE_WITH_DESC,
      },
      {
        rowName: intl.formatMessage(messages.target),
        moreItemsText: intl.formatMessage(messages.addTargetText),
        type: OPTION_TYPE.BONUS_TARGET_OPTIONS,
        rowsData: bonusTargetOptions,
        handleInput: handleInputChange,
        slug: 'bonusTargetOptions',
      },
    );
  }

  if (isBudgetTargetEnabled) {
    options.push({
      name: intl.formatMessage(messages.budgetTargets),
      label: intl.formatMessage(messages.budgetTargetsDescription),
      type: OPTION_TYPE.BOOL,
      slug: 'enableBudgetTarget',
      handleInput: handleInputChange,
    });

    if (enableBudgetTarget) {
      options.push(
        {
          name: intl.formatMessage(messages.hoursBudgetBlocksPublishing),
          label: intl.formatMessage(messages.hoursBudgetBlocksPublishingLabel),
          type: OPTION_TYPE.BOOL,
          slug: 'budgetTargetHoursBlocksPublish',
          handleInput: handleInputChange,
        },
        {
          name: intl.formatMessage(messages.costBudgetBlocksPublishing),
          label: intl.formatMessage(messages.costBudgetBlocksPublishingLabel),
          type: OPTION_TYPE.BOOL,
          slug: 'budgetTargetMoneyBlocksPublish',
          handleInput: handleInputChange,
        },
      );
    }
  }

  return {
    title: intl.formatMessage(messages.sectionTitle),
    freemiumRestrictions: [FREEMIEM_DISABLE_LOCATION_SETTINGS_VIEW],
    intercomEvent: FREEMIUM_EVENT_LOCATION_SETTINGS_BONUS_SYSTEM,
    options,
  };
};
