/* eslint-disable max-len */
import { defineMessages, FormattedMessage } from 'react-intl';

export const messages = defineMessages({
  locationSettingsChangeMassTitle: {
    id: 'settings.locationSettings.changeMass.title',
    defaultMessage: 'Zastosuj do wszystkich',
  },
  locationSettingsChangeMassConfirmText: {
    id: 'edit',
    defaultMessage: 'Edytuj',
  },
  locationSettingsChangeMassDeletedThing: {
    id: 'settings.locationSettings.changeMass.deletedThing',
    defaultMessage: 'Zastosuj do wszystkich',
  },
  locationSettingsChangeMassActionText: {
    id: 'settings.locationSettings.changeMass.actionText',
    defaultMessage: 'zatwierdzić edycję dla wszystkich lokalizacji',
  },
  passwordRefreshModalTitle: {
    id: 'settings.passwordRefresh.modalTitle',
    defaultMessage: 'Polityka haseł',
  },
  passwordRefreshModalDescription: {
    id: 'settings.passwordRefresh.modalText',
    defaultMessage: 'Czy na pewno chcesz zapisać ustawienia okresowej zmiany hasła?',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Zapisz',
  },
  changePasswordSuccess: {
    id: 'settings.password.changePasswordSuccess',
    defaultMessage: 'Hasło zostało pomyślnie zmienione',
  },
  changePasswordError: {
    id: 'settings.password.changePasswordError',
    defaultMessage: 'Nie udało się zmienić hasła. Spróbuj ponownie',
  },
});

export const LocationSettingsChangeMassDescription = () => (
  <>
    <FormattedMessage
      id="settings.locationSettings.changeMassDesc1"
      defaultMessage="Kliknięcie EDYTUJ spowoduje zmiany ustawień we wszystkich pozostałych lokalizacjach."
    />{' '}
    <b>
      <FormattedMessage
        id="settings.locationSettings.changeMassDesc2"
        defaultMessage="Zmienione zostaną wszystkie ustawienia poza współrzędnymi GPS lokalizacji oraz długości promienia."
      />
    </b>{' '}
    <FormattedMessage
      id="settings.locationSettings.changeMassDesc3"
      defaultMessage="Akcji tej nie  będzie można cofnąć."
    />
  </>
);
