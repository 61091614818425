import cn from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import './DropArea.scss';

class DropArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    bindPrototypeFunctions(this);
    this.counter = 0;
  }
  onDrop(e) {
    this.setState({ show: false });
    this.counter = 0;
    this.props.onDrop(e);
  }
  onDragOver(e) {
    e.preventDefault();
  }
  onDragEnter() {
    if (this.counter === 0) {
      this.setState({ show: true });
    }
    this.counter++;
  }
  onDragLeave() {
    this.counter--;
    if (this.counter === 0 && this.state.show) this.setState({ show: false });
  }

  render() {
    const { enabled, blocked, isSwapping } = this.props;
    const { show } = this.state;

    if (!enabled) return null;
    const blockedClassNames = cn('k-scheduleDropArea', { 'k-scheduleDropArea--blocked': !isSwapping });
    if (blocked) return <div className={blockedClassNames} />;
    const className = cn('k-scheduleDropArea', {
      'k-scheduleDropArea--selected': show && !isSwapping,
    });

    return (
      <div
        className={className}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      />
    );
  }
}

DropArea.propTypes = {
  enabled: PropTypes.bool,
  onDrop: PropTypes.func,
  isSwapping: PropTypes.bool,
};
export default DropArea;
