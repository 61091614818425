import { connect } from 'react-redux';

import { showAbsenceCalendar } from '@/actions/absences.js';

import AbsencesLimitsViewOptions from './AbsencesLimitsViewOptions';

const mapDispatchToProps = { showAbsenceCalendar };

const AbsencesLimitsViewOptionsContainer = connect(null, mapDispatchToProps)(AbsencesLimitsViewOptions);

export default AbsencesLimitsViewOptionsContainer;
