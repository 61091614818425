import { memo } from 'react';

import { isNumberType } from '@/utils/variableTypes/variableTypes';

import { GroupNodeType } from '../GroupedEmployees.types';
import { GROUP_INDENTATION } from './GroupBar.utils';
import { GroupBarContent } from './GroupBarContent/GroupBarContent';
import { useGroupBar } from './useGroupBar';

import './GroupBar.scss';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  groupKey: GroupNodeType['groupKey'];
  groupId: GroupNodeType['groupId'];
  level: number;
  recordsAmount?: number;
};

export const GroupBar = memo(({ isOpen, groupId, groupKey, level, recordsAmount, toggle }: Props) => {
  const { classes, colSpan } = useGroupBar(isOpen, recordsAmount);

  return (
    <tr className={classes.tr}>
      <td className={classes.td}>
        <div style={{ minWidth: `${level * GROUP_INDENTATION}px` }} />
        <div className={classes.iconContainer}>
          <i role="presentation" className={classes.icon} onClick={toggle}>
            keyboard_arrow_up
          </i>
        </div>
        <GroupBarContent groupId={groupId} groupKey={groupKey} />
        {isNumberType(recordsAmount) && <span className={classes.recordsAmount}>({recordsAmount})</span>}
      </td>
      <td colSpan={colSpan} />
    </tr>
  );
});

GroupBar.displayName = 'GroupBar';
