import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDCheckbox, MDSelect, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';

import { limitPeriodOptions, limitUnitOptions } from '../constants.js';

import './AbsenceTypeModalBasic.scss';

const messages = defineMessages({
  week: {
    id: 'absenceTypeModal.week',
    defaultMessage: 'Tydzień',
  },
  month: {
    id: 'absenceTypeModal.month',
    defaultMessage: 'Miesiąc',
  },
  year: {
    id: 'absenceTypeModal.year',
    defaultMessage: 'Rok',
  },
  days: {
    id: 'absenceTypeModal.days',
    defaultMessage: 'Dni',
  },
  minutes: {
    id: 'absenceTypeModal.minutes',
    defaultMessage: 'Godziny',
  },
  limitUnit: {
    id: 'absenceTypeModal.limitUnit',
    defaultMessage: 'Jednostka limitu',
  },
  limitPeriod: {
    id: 'absenceTypeModal.limitPeriod',
    defaultMessage: 'Okres limitu',
  },
  shortNameTooltip: {
    id: 'absenceTypeModal.shortNameTooltip',
    defaultMessage: 'Skrócona nazwa urlopu, która będzie wykorzystywana w wielu miejscach w systemie',
  },
  codeTooltip: {
    id: 'absenceTypeModal.codeTooltip',
    defaultMessage:
      'Kod to uniwersalne pole w którym możesz wpisać dowolną wartość dla danego typu urlopu. Często jest wykorzystywane do mapowania nieobecności z innych systemów, np. systemy kadrowo-płacowe.',
  },
  addingByEmployeeTooltip: {
    id: 'absenceTypeModal.addingByEmployeeTooltip',
    defaultMessage: 'Zaznacz tę opcje, aby pozwolić pracownikom na tworzenie wniosku urlopowego dla tego typu',
  },
  requiresApprovalTooltip: {
    id: 'absenceTypeModal.requiresApprovalTooltip',
    defaultMessage:
      'Zaznacz tę opcje, aby wymusić akceptacje wniosku urlopowego zanim pojawi się on w ewidencji godzin pracy',
  },
  isPaidTooltip: {
    id: 'absenceTypeModal.isPaidTooltip',
    defaultMessage: 'Zaznacz tę opcje jeżeli ten typ urlopu ma być wliczany do ewidencji godzin pracy',
  },
  hasLimitTooltip: {
    id: 'absenceTypeModal.hasLimitTooltip',
    defaultMessage: 'Zaznacz tę opcje, aby umożliwić określenie limitów dla tego typu',
  },
  reduceUwLimitTooltip: {
    id: 'absenceTypeModal.reduceUwLimitTooltip',
    defaultMessage: 'Zaznacz tę opcje jeżeli dodanie tego typu urlopu ma pomniejszać limit urlopu wypoczynkowego',
  },
});

const AbsenceTypeModalBasic = (
  {
    fullName,
    shortName,
    code,
    hasNote,
    addingByEmployee,
    requiresApproval,
    isPaid,
    reduceUwLimit,
    definedInHours,
    handleInputChange,
    validateInput,
    errors,
    hasLimit,
    limitUnit,
    limitPeriod,
    isDefaultUwAbsence,
    isDisabled,
  },
  { intl },
) => (
  <div className="absenceTypeModalBasic">
    <MDTextInput
      id="fullName"
      name="fullName"
      value={fullName}
      type="text"
      label={<FormattedMessage id="absenceTypeModal.fullName" defaultMessage="Nazwa" />}
      modifiers={['modal']}
      onChange={handleInputChange}
      onBlur={validateInput}
      errorMessage={errors.fullName}
      disabled={isDisabled}
    />
    <div className="absenceTypeModalBasic__inputs">
      <MDTextInput
        id="shortName"
        name="shortName"
        value={shortName}
        type="text"
        label={<FormattedMessage id="absenceTypeModal.shortName" defaultMessage="Skrót" />}
        modifiers={['modal']}
        onChange={handleInputChange}
        tooltip={intl.formatMessage(messages.shortNameTooltip)}
        onBlur={validateInput}
        errorMessage={errors.shortName}
        disabled={isDisabled}
      />
      <MDTextInput
        id="code"
        name="code"
        value={code}
        type="text"
        label={<FormattedMessage id="absenceTypeModal.code" defaultMessage="Kod" />}
        tooltip={intl.formatMessage(messages.codeTooltip)}
        modifiers={['modal']}
        onChange={handleInputChange}
        disabled={isDisabled}
      />
    </div>
    <div className="absenceTypeModalBasic__divider" />
    <div className="absenceTypeModalBasic__title">
      <FormattedMessage id="absenceTypeModal.options" defaultMessage="Opcje urlopu" />
    </div>
    <MDCheckbox
      id="hasNote"
      name="hasNote"
      text={<FormattedMessage id="absenceTypeModal.hasNote" defaultMessage="Notatka" />}
      value={hasNote}
      onChange={handleInputChange}
      reverse
      disabled={isDisabled}
    />
    <MDCheckbox
      id="addingByEmployee"
      name="addingByEmployee"
      text={
        <FormattedMessage id="absenceTypeModal.addingByEmployee" defaultMessage="Zezwól na dodanie przez pracownika" />
      }
      value={addingByEmployee}
      onChange={handleInputChange}
      tooltip={intl.formatMessage(messages.addingByEmployeeTooltip)}
      reverse
      disabled={isDisabled}
    />
    <MDCheckbox
      id="requiresApproval"
      name="requiresApproval"
      text={<FormattedMessage id="absenceTypeModal.requiresApproval" defaultMessage="Wymaga akceptacji" />}
      value={requiresApproval}
      onChange={handleInputChange}
      tooltip={intl.formatMessage(messages.requiresApprovalTooltip)}
      reverse
      disabled={isDisabled}
    />
    <MDCheckbox
      id="isPaid"
      name="isPaid"
      text={<FormattedMessage id="absenceTypeModal.isPaid" defaultMessage="Płatny" />}
      value={isPaid}
      onChange={handleInputChange}
      tooltip={intl.formatMessage(messages.isPaidTooltip)}
      reverse
      disabled={isDisabled}
    />
    <MDCheckbox
      id="definedInHours"
      name="definedInHours"
      text={
        <FormattedMessage id="absenceTypeModal.definedInHours" defaultMessage="Zezwól na dodanie dla części dnia" />
      }
      value={definedInHours}
      onChange={handleInputChange}
      reverse
      disabled={isDisabled}
    />
    <MDCheckbox
      id="hasLimit"
      name="hasLimit"
      text={<FormattedMessage id="absenceTypeModal.hasLimit" defaultMessage="Limitowany" />}
      value={isDefaultUwAbsence ? true : hasLimit}
      onChange={event => {
        if (!hasLimit) {
          handleInputChange({ target: { name: 'limitPeriod', value: limitPeriodOptions[0] } });
          handleInputChange({ target: { name: 'limitUnit', value: limitUnitOptions[0] } });
        } else {
          handleInputChange({ target: { name: 'limitPeriod', value: null } });
          handleInputChange({ target: { name: 'limitUnit', value: null } });
        }
        handleInputChange(event);
        handleInputChange({ target: { name: 'reduceUwLimit', value: false } });
      }}
      tooltip={intl.formatMessage(messages.hasLimitTooltip)}
      reverse
      disabled={isDefaultUwAbsence || isDisabled}
    />
    <MDCheckbox
      id="reduceUwLimit"
      name="reduceUwLimit"
      text={<FormattedMessage id="absenceTypeModal.reduceUwLimit" defaultMessage="Czy wpływa na limit UW" />}
      value={isDefaultUwAbsence ? true : reduceUwLimit}
      onChange={handleInputChange}
      tooltip={intl.formatMessage(messages.reduceUwLimitTooltip)}
      reverse
      disabled={!hasLimit || isDefaultUwAbsence || isDisabled}
    />
    <MDSelect
      id="limitPeriod"
      name={intl.formatMessage(messages.limitPeriod)}
      defaultValue={limitPeriod}
      options={limitPeriodOptions.map(periodName => ({
        key: intl.formatMessage(messages[periodName]),
        value: periodName,
      }))}
      onChange={key => {
        handleInputChange({
          target: {
            name: 'limitPeriod',
            value: key,
          },
        });
      }}
      closeOnClick
      disabled={!hasLimit || isDisabled}
    />
    <MDSelect
      id="limitUnit"
      name={intl.formatMessage(messages.limitUnit)}
      defaultValue={limitUnit}
      options={limitUnitOptions.map(unitName => ({
        key: intl.formatMessage(messages[unitName]),
        value: unitName,
      }))}
      onChange={key => {
        handleInputChange({
          target: {
            name: 'limitUnit',
            value: key,
          },
        });
      }}
      closeOnClick
      disabled={!hasLimit || isDisabled}
    />
  </div>
);

AbsenceTypeModalBasic.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceTypeModalBasic.propTypes = {
  fullName: PropTypes.string,
  shortName: PropTypes.string,
  code: PropTypes.string,
  hasNote: PropTypes.bool,
  addingByEmployee: PropTypes.bool,
  requiresApproval: PropTypes.bool,
  isPaid: PropTypes.bool,
  reduceUwLimit: PropTypes.bool,
  definedInHours: PropTypes.bool,
  handleInputChange: PropTypes.func,
  validateInput: PropTypes.func,
  errors: PropTypes.shape({
    fullName: PropTypes.string,
    shortName: PropTypes.string,
  }),
  hasLimit: PropTypes.bool,
  limitUnit: PropTypes.string,
  limitPeriod: PropTypes.string,
  isDefaultUwAbsence: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default AbsenceTypeModalBasic;
