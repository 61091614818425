import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDProgressRing, MDTooltip } from '@/components/common/inputs/MDComponents.jsx';

import './DashboardStatistics.scss';

const messages = defineMessages({
  currentlyPresent: {
    id: 'dashboard.statistics.currentlyPresent',
    defaultMessage: 'Obecni aktualnie',
  },
  currentlyPresentDescription: {
    id: 'dashboard.statistics.currentlyPresentDescription',
    defaultMessage: 'Liczba aktualnie rozpoczętych obecności względem liczby zmian, które trwają lub trwały',
  },
  plannedAbsences: {
    id: 'dashboard.statistics.plannedAbsences',
    defaultMessage: 'Planowane nieobecności',
  },
  plannedAbsencesDescription: {
    id: 'dashboard.statistics.plannedAbsencesDescription',
    defaultMessage: 'Zatwierdzone zaplanowane nieobecności',
  },
  frequency: {
    id: 'dashboard.statistics.frequency',
    defaultMessage: 'Frekwencja',
  },
  frequencyDescription: {
    id: 'dashboard.statistics.frequencyDescription',
    defaultMessage:
      'Liczba aktualnie rozpoczętych obecności względem liczby zmian, które trwają lub trwały w tym dniu w ujęciu procentowym',
  },
});

const DashboardStatistics = ({ summary }, { intl }) => (
  <div className="md-dashboardContainer md-dashboardStatistics">
    <div className="md-dashboardContainer__title">
      <i className="md-dashboardContainer__icon material-icons">table_chart</i>
      <FormattedMessage id="dashboard.statistics" defaultMessage="Statystyki" />
    </div>
    <div className="md-dashboardStatistics__container">
      <div className="md-dashboardStatistics__item">
        <div className="md-dashboardStatistics__type">
          {intl.formatMessage(messages.currentlyPresent, {})}
          <MDTooltip text={intl.formatMessage(messages.currentlyPresentDescription, {})} />
        </div>
        <div>
          <span className="md-dashboardStatistics__amount">{summary.numberOfAttendances} / </span>
          <span className="md-dashboardStatistics__amount md-dashboardStatistics__amount--small">
            {summary.numberOfShifts}
          </span>
        </div>
      </div>
      <div className="md-dashboardStatistics__item">
        <div className="md-dashboardStatistics__type">
          {intl.formatMessage(messages.plannedAbsences, {})}
          <MDTooltip text={intl.formatMessage(messages.plannedAbsencesDescription, {})} />
        </div>
        <span className="md-dashboardStatistics__amount">{summary.numberOfAbsences}</span>
      </div>
      <div className="md-dashboardStatistics__item">
        <div className="md-dashboardStatistics__type">
          {intl.formatMessage(messages.frequency, {})}
          <MDTooltip text={intl.formatMessage(messages.frequencyDescription, {})} />
        </div>
        <MDProgressRing
          animated={false}
          radius={28}
          stroke={4}
          progress={
            summary.numberOfShifts > 0 ? Math.round((summary.numberOfAttendances / summary.numberOfShifts) * 100) : 0
          }
        />
      </div>
      <div className="md-dashboardStatistics__item">
        <div className="md-dashboardStatistics__type">
          <FormattedMessage id="dashboard.statistics.late" defaultMessage="Spóźnienia" />
        </div>
        <span className="md-dashboardStatistics__amount">{summary.totalNumberOfLate}</span>
      </div>
    </div>
  </div>
);

DashboardStatistics.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

DashboardStatistics.propTypes = {
  summary: PropTypes.shape({
    numberOfShifts: PropTypes.number,
    numberOfAttendances: PropTypes.number,
    numberOfAbsences: PropTypes.number,
    totalNumberOfLate: PropTypes.number,
  }),
};

export default DashboardStatistics;
