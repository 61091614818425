import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState';

import StartSteps from './StartSteps';

const mapStateToProps = ({ reducer }) => ({
  companyPurpose: reducer.currentCompany.purpose,
  companyMilestones: reducer.onboarding.companyMilestones,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartSteps);
