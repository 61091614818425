import PropTypes from 'prop-types';

import MDAlert from '@/components/common/Basic/MDAlert/MDAlert.jsx';

import messages from './messages.js';
import { getOvertimeHours } from './utils.js';

import './AttendancesTableAlerts.scss';

const AttendancesTableAlerts = ({ rowData }, context) => {
  const { attendanceWithoutShift, attendanceWithAbsence, attendanceOutOfRange, longAttendance, hasMoreTime } = rowData;

  return (
    <div className="attendancesTable__alerts">
      {attendanceOutOfRange && (
        <MDAlert icon="wrong_location" iconType="outlined" label={context.intl.formatMessage(messages.wrongLocation)} />
      )}
      {attendanceWithoutShift && (
        <MDAlert icon="event_busy" label={context.intl.formatMessage(messages.attendancesTableAlertsNoShift)} />
      )}
      {attendanceWithAbsence && (
        <MDAlert
          icon="flight"
          label={context.intl.formatMessage(messages.attendancesTableAlertsAbsenceWithAttendance)}
        />
      )}
      {longAttendance && (
        <MDAlert icon="nights_stay" label={context.intl.formatMessage(messages.attendancesTableAlertsLongAttendance)} />
      )}
      {hasMoreTime && (
        <MDAlert
          icon="more_time"
          label={context.intl.formatMessage(messages.attendancesTableAlertsMoreTime, {
            hours: getOvertimeHours(rowData.attendanceObject, context.intl),
          })}
        />
      )}
    </div>
  );
};

AttendancesTableAlerts.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AttendancesTableAlerts.propTypes = {
  rowData: PropTypes.shape({
    attendanceObject: PropTypes.shape({}),
    attendanceWithoutShift: PropTypes.bool,
    attendanceWithAbsence: PropTypes.bool,
    attendanceOutOfRange: PropTypes.bool,
    longAttendance: PropTypes.bool,
    hasMoreTime: PropTypes.bool,
  }),
};

export default AttendancesTableAlerts;
