import PropTypes from 'prop-types';

import { absencesLimitsModesIdsForEmployee, absencesLimitsModesIdsForManager } from '@/constants/viewDateModes';

import TopBarAbsenceFilter from '../filters/TopBarAbsenceFilter/TopBarAbsenceFilter.redux.js';
import TopBarAbsencesDisplayMode from '../filters/TopBarAbsencesDisplayMode/TopBarAbsencesDisplayMode.redux.js';
import TopBarCalendar from '../filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBar from '../TopBar.jsx';

const TopBarAbsencesLimits = ({ isEmployee }) => {
  const modes = isEmployee ? absencesLimitsModesIdsForEmployee : absencesLimitsModesIdsForManager;
  return (
    <TopBar
      leftElements={[
        <TopBarCalendar mode="schedule" key="calswitch" modes={modes} />,
        !isEmployee ? <TopBarAbsenceFilter /> : null,
        <TopBarAbsencesDisplayMode />,
      ]}
    />
  );
};

TopBarAbsencesLimits.propTypes = {
  isEmployee: PropTypes.bool,
};

export default TopBarAbsencesLimits;
