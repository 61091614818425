export const calculateAbsenceConnectorLength = (
  lastConnectedShiftBlockIndex: number,
  isWeekView: boolean,
  isMonthView: boolean,
): number => {
  if (lastConnectedShiftBlockIndex === 1) {
    if (isWeekView) return 32;
    if (isMonthView) return 40;
    return 46;
  }
  if (isMonthView) return 40 + 52 * (lastConnectedShiftBlockIndex - 1);
  if (isWeekView) return 32 + 46 * (lastConnectedShiftBlockIndex - 1);
  return 46 + 52 * (lastConnectedShiftBlockIndex - 1);
};
