import PropTypes from 'prop-types';
import { useCallback } from 'react';

import DualRangeSliderWithInputs from '@/components/common/inputs/DualRangeSliderWithInputs';
import { generateTooltip } from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.helpers';
import PayrollSettingsModalSettingRow from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModalSettingRow/PayrollSettingsModalSettingRow';
import { messages } from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModalWorkTimeSection/PayrollSettingsModalWorkTimeSection.messages';
import PayrollSettingsModalWorkTimeSelect from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModalWorkTimeSection/PayrollSettingsModalWorkTimeSelect/PayrollSettingsModalWorkTimeSelect';
import { roundingSettings, roundingTypeSettings } from '@/constants/payrollSettings';

const PayrollSettingsModalWorkTimeColumn = ({ settings, type, changeSetting }, { intl }) => {
  if (!settings.startRoundingType) return null;
  const isStart = type === 'start';
  const header = isStart ? messages.startTime : messages.endTime;
  const settingType = isStart ? 'startRoundingSetting' : 'endRoundingSetting';
  const roundingType = isStart ? 'startRoundingType' : 'endRoundingType';
  const toleranceType = isStart ? 'startTolerance' : 'endTolerance';
  const additionalToleranceType = isStart ? 'lateStartTolerance' : 'earlyEndTolerance';
  const sliderValues =
    type === 'start'
      ? [settings[toleranceType] * -1, settings[additionalToleranceType]]
      : [settings[additionalToleranceType] * -1, settings[toleranceType]];

  const handleStartSliderChange = useCallback(
    ([startValue, endValue]) => {
      if (startValue > 0 && endValue > 0) {
        changeSetting(toleranceType, 0);
      } else if (startValue < 0 && endValue < 0) {
        changeSetting(additionalToleranceType, 0);
      } else {
        changeSetting(toleranceType, -startValue);
        changeSetting(additionalToleranceType, endValue);
      }
    },
    [toleranceType, additionalToleranceType, changeSetting],
  );

  const handleEndSliderChange = useCallback(
    ([endValue, startValue]) => {
      if (startValue > 0 && endValue > 0) {
        changeSetting(additionalToleranceType, 0);
      } else if (startValue < 0 && endValue < 0) {
        changeSetting(toleranceType, 0);
      } else {
        changeSetting(toleranceType, startValue);
        changeSetting(additionalToleranceType, -endValue);
      }
    },
    [toleranceType, additionalToleranceType, changeSetting],
  );

  const onInputChange = useCallback(
    (value, isLeft) => {
      let toleranceInputType;
      if (isStart) {
        toleranceInputType = isLeft ? 'startTolerance' : 'lateStartTolerance';
      } else {
        toleranceInputType = isLeft ? 'earlyEndTolerance' : 'endTolerance';
      }
      changeSetting(toleranceInputType, value);
    },
    [isStart, changeSetting],
  );

  const roundingTooltip = generateTooltip('rounding', intl);
  const roundTooltip = generateTooltip('round', intl);
  const toleranceTooltip = generateTooltip(isStart ? 'toleranceStart' : 'toleranceEnd', intl);

  return (
    <div className="k-payrollSettingsModal__workTimeSectionColumn">
      <div className="k-payrollSettingsModal__workTimeSectionColumnHeader">{intl.formatMessage(header)}</div>
      <PayrollSettingsModalSettingRow
        label={messages.rounding}
        tooltip={roundingTooltip}
        settingInput={
          <PayrollSettingsModalWorkTimeSelect
            settings={settings}
            changeSetting={changeSetting}
            selectOptions={roundingSettings}
            settingType={settingType}
          />
        }
        isForSelect
      />

      <PayrollSettingsModalSettingRow
        label={messages.round}
        tooltip={roundTooltip}
        settingInput={
          <PayrollSettingsModalWorkTimeSelect
            settings={settings}
            changeSetting={changeSetting}
            selectOptions={roundingTypeSettings}
            settingType={roundingType}
          />
        }
        isForSelect
      />
      <PayrollSettingsModalSettingRow
        label={messages.tolerance}
        tooltip={toleranceTooltip}
        alignLabelToTop
        settingInput={
          <DualRangeSliderWithInputs
            maxValue={120}
            onSliderChangeFunc={isStart ? handleStartSliderChange : handleEndSliderChange}
            showInfiniteButton
            infiniteButtonSideReversed={isStart}
            infiniteButtonValue={121}
            changeFunc={changeSetting}
            onInputChange={onInputChange}
            values={sliderValues}
          />
        }
      />
    </div>
  );
};

PayrollSettingsModalWorkTimeColumn.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollSettingsModalWorkTimeColumn.propTypes = {
  type: PropTypes.string,
  settings: PropTypes.shape({
    startRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    startTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endRoundingSetting: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endRoundingType: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    endTolerance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  changeSetting: PropTypes.func,
};

export default PayrollSettingsModalWorkTimeColumn;
