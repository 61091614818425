import classnames from 'classnames';
import { PropTypes } from 'prop-types';

const ModalHeader = (props, context) => {
  const className = classnames('kmodal__header', { 'kmodal__header--closeButton': props.closeButton });
  return (
    <div className={className}>
      {props.children}
      {props.closeButton ? (
        <i className="material-icons kmodal__closeIcon" onClick={context.onHide} role="presentation">
          close
        </i>
      ) : null}
    </div>
  );
};
ModalHeader.contextTypes = {
  onHide: PropTypes.func,
};
ModalHeader.propTypes = {
  closeButton: PropTypes.bool,
  children: PropTypes.node,
};
export default ModalHeader;
