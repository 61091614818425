import { useAppSelector } from '@/redux-store';
import { selectUserLocationsChartData } from '@/selectors/reports.selectors';
import { selectUserLocations } from '@/selectors/userLocations.selectors';

export const useLocationsChart = () => {
  const data = useAppSelector(selectUserLocationsChartData);

  const userLocations = useAppSelector(selectUserLocations);
  return { data, userLocations };
};
