import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  format: {
    id: 'exportsCatalog.exportsFormat',
    defaultMessage: 'Format:',
  },
  category: {
    id: 'exportsCatalog.category',
    defaultMessage: 'Kategoria:',
  },
  showPreview: {
    id: 'exportsCatalog.showPreview',
    defaultMessage: 'Klkiknij aby zobaczyć podgląd',
  },
  preview: {
    id: 'exportsCatalog.pewview',
    defaultMessage: 'Podgląd',
  },
  generate: {
    id: 'exportsCatalog.generate',
    defaultMessage: 'Generuj eksport',
  },
});
