import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  startContractDate: {
    id: 'companymanage.employee.contracts.startDate',
    defaultMessage: 'Data rozpoczęcia',
  },
  relatedContractDate: {
    id: 'companymanage.employee.contracts.relatedDate',
    defaultMessage: 'Data obowiązywania kontraktu',
  },
  checkboxText: {
    id: 'companymanage.employee.contracts.checkboxText',
    defaultMessage: 'Przypisz pracownikom ich stanowiska i stawki z kontraktu,\nw którym zawiera się powyższa data',
  },
  checkboxTooltipText: {
    id: 'companymanage.employee.contracts.checkboxTooltipText',
    defaultMessage:
      'Jeżeli pracownik posiada przypisane stanowiska\ni stawki do kontraktu w wybranej dacie, to zostaną one przypisane do nowo dodanego kontraktu.',
  },
  effectiveDateOfContract: {
    id: 'companymanage.employee.contracts.effectiveDateOfContract',
    defaultMessage: 'Wybierz datę, która zawiera się w okresie obowiązywania kontraktów',
  },
  newStartContractDate: {
    id: 'companymanage.employee.contracts.newStartContractDate',
    defaultMessage: 'Nowa data rozpoczęcia kontraktu',
  },
  changeStartContractDateIsUnavailableForAllSelectedEmployees: {
    id: 'companymanage.employee.contracts.changeStartContractDateIsUnavailableForAllSelectedEmployees',
    defaultMessage:
      'Brak możliwości edycji daty rozpoczęcia kontraktu, ponieważ wybrani pracownicy posiadają kontrakt bez daty rozpoczęcia',
  },
});
