import { connect } from 'react-redux';

import EmployeeDashboard from '@/components/employee/dashboard/EmployeeDashboard.jsx';

const mapStateToProps = (state) => ({
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  userEvents: state.reducer.userEvents,
  userLocations: state.reducer.userLocations,
  mainDateStore: state.reducer.mainDateStore,
  locationSettings: state.reducer.settings.locationSettings,
  attendances: state.reducer.attendances.attendancesData,
});

const mapDispatchToProps = {};

const EmployeeDashboardContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);

export default EmployeeDashboardContainer;
