import { defineMessages } from 'react-intl';

import { BUDGET_TARGET_IMPORT_MODAL } from '@/constants/modalTypes';

export const messages = defineMessages({
  title: {
    id: 'loadBudgetTargetInitialView.title',
    defaultMessage: 'Wczytaj targety budżetu',
  },
  fromExcel: {
    id: 'loadBudgetTargetInitialView.fromExcel',
    defaultMessage: 'Importuj z szablonu Excel',
  },
  fromExcelDescription: {
    id: 'loadBudgetTargetInitialView.fromExcelDescription',
    defaultMessage: 'Wybierz, aby wczytać targety budżetu na podstawie uzupełnionego pliku w Excel.',
  },
  fillManually: {
    id: 'loadBudgetTargetInitialView.fillManually',
    defaultMessage: 'Uzupełnij ręcznie w systemie',
  },
  fillManuallyDescription: {
    id: 'loadBudgetTargetInitialView.fillManuallyDescription',
    defaultMessage: 'Wybierz, aby uzupełnić ręcznie targety budżetu godzinowego i/lub kosztowego.',
  },
  calculateByColAndSpmh: {
    id: 'loadBudgetTargetInitialView.calculateByColAndSpmh',
    defaultMessage: 'Oblicz na podstawie metryki %COL i SPMH',
  },
  calculateByColAndSpmhDescription: {
    id: 'loadBudgetTargetInitialView.calculateByColAndSpmhDescription',
    defaultMessage: 'Wybierz, aby uzupełnić targety budżetu na podstawie metryk %COL i SPMH.',
  },
  disabledOption: {
    id: 'loadBudgetTargetInitialView.disabledOption.oneLocationMustBeSelected',
    defaultMessage: 'Opcja dostępna tylko przy wybranej pojedynczej lokalizacji.',
  },
});

export const LOAD_BUDGET_TARGET_TYPE = {
  fromExcel: { id: 'fromExcel', modalType: BUDGET_TARGET_IMPORT_MODAL },
  fillManually: { id: 'fillManually' },
  calculateByColAndSpmh: { id: 'calculateByColAndSpmh' },
};

export const getLoadBudgetTargetModalInitialViewOptions = (intl, isOneLocationSelected) => [
  {
    name: intl.formatMessage(messages.fromExcel),
    type: LOAD_BUDGET_TARGET_TYPE.fromExcel.id,
    description: intl.formatMessage(messages.fromExcelDescription),
  },
  {
    name: intl.formatMessage(messages.fillManually),
    type: LOAD_BUDGET_TARGET_TYPE.fillManually.id,
    description: intl.formatMessage(messages.fillManuallyDescription),
    disabled: !isOneLocationSelected,
    disabledTooltip: !isOneLocationSelected ? intl.formatMessage(messages.disabledOption) : undefined,
  },
  {
    name: intl.formatMessage(messages.calculateByColAndSpmh),
    type: LOAD_BUDGET_TARGET_TYPE.calculateByColAndSpmh.id,
    description: intl.formatMessage(messages.calculateByColAndSpmhDescription),
    disabled: !isOneLocationSelected,
    disabledTooltip: !isOneLocationSelected ? intl.formatMessage(messages.disabledOption) : undefined,
  },
];
