import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import { MDSelect } from '@/components/common/inputs/MDComponents.jsx';
import { weeklyWorkingHours } from '@/constants/employmentConditions.js';

const messages = defineMessages({
  title: {
    id: 'employemtnConditions.partTimeMultiplier.title',
    defaultMessage: 'Wymiar czasu pracy',
  },
});

const EmployeesEditMassWokringHours = ({ selected, onChange }, { intl }) => {
  const options = weeklyWorkingHours.map(hours => ({
    key: intl.formatMessage(hours.name),
    value: hours.value,
  }));

  return (
    <MDSelect
      id="propertyType"
      options={options}
      defaultValue={selected}
      name={intl.formatMessage(messages.title)}
      onChange={value => {
        onChange(value);
      }}
      closeOnClick
    />
  );
};

EmployeesEditMassWokringHours.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeesEditMassWokringHours.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmployeesEditMassWokringHours;
