import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

class DropArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      single: false,
    };
    bindPrototypeFunctions(this);
    this.counter = 0;
  }
  onDrop() {
    this.setState({ show: false });
    this.counter = 0;
  }
  onDragOver(e) {
    e.preventDefault();
    if (e.target.classList.contains('absence-name') || !this.props.draggable) this.setState({ show: false });
  }
  onDragEnter(e) {
    if (this.counter === 0) {
      const single = e.dataTransfer.types.includes('single');
      if (!this.props.checkIfShow || this.props.checkIfShow(e.dataTransfer.types))
        this.setState({ show: true, single });
    }
    this.counter++;
  }
  onDragLeave() {
    this.counter--;
    if (this.counter === 0) this.setState({ show: false });
  }
  cut(e) {
    this.props.cut(e);
    this.onDrop();
  }
  paste(e) {
    this.props.paste(e);
    this.onDrop();
  }
  render() {
    const showText = this.props.dateMode === 'week';

    const dropZone = this.state.single || this.props.single ? (
      <div className="dropZone__container" onDrop={this.onDrop}>
        <div className="dropZone__paste dropZone__paste--full" onDrop={this.paste}>
          {showText ? (
            <span className="dropZone__text">
              {this.props.singleText || <FormattedMessage id="dnd.assign" defaultMessage="Przypisz" />}
            </span>
          ) : (
            <i className="material-icons">content_paste</i>
          )}
        </div>
      </div>
    ) : (
      <div className="dropZone__container" onDrop={this.onDrop}>
        <div className="dropZone__cut" onDrop={this.cut}>
          {showText ? (
            <span className="dropZone__text">
              <FormattedMessage id="dnd.move" defaultMessage="Przenieś" />
            </span>
          ) : (
            <i className="material-icons">content_cut</i>
          )}
        </div>
        <div className="dropZone__paste" onDrop={this.paste}>
          {showText ? (
            <span className="dropZone__text">
              <FormattedMessage id="dnd.copy" defaultMessage="Kopiuj" />
            </span>
          ) : (
            <i className="material-icons">content_paste</i>
          )}
        </div>
      </div>
    );

    return (
      <div
        className="dropZone"
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        style={{ height: this.props.fullHeight ? '100%' : 'auto' }}
        draggable={this.props.draggable}
      >
        {this.props.children}
        {this.state.show ? dropZone : null}
      </div>
    );
  }
}

DropArea.defaultProps = {
  draggable: true,
};

DropArea.propTypes = {
  dateMode: PropTypes.string,
  cut: PropTypes.func,
  paste: PropTypes.func,
  children: PropTypes.node,
  checkIfShow: PropTypes.func,
  fullHeight: PropTypes.bool,
  single: PropTypes.bool,
  singleText: PropTypes.shape({}),
  draggable: PropTypes.bool,
};
export default DropArea;
