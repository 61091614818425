import { endOfMonth, startOfMonth } from '@/utils/dateHelper';

import { messages } from './OvertimeCollectionOptions.messages';

export const convertScheduleCycleToStartAndEndDates = scheduleCycle => {
  const from = startOfMonth({ month: scheduleCycle.month - 1, year: scheduleCycle.year });
  const to = endOfMonth({ month: scheduleCycle.month - 1, year: scheduleCycle.year });
  return { from, to };
};

export const getApplicantOptions = intl => [
  {
    name: intl.formatMessage(messages.employer),
    type: '1.5',
  },
  {
    name: intl.formatMessage(messages.employee),
    type: '1',
  },
];
