type VisibleThings = {
  isShift: boolean;
  isAbsenceOrOvertimeCollection: boolean;
  isFreeDay: boolean;
};

export const getTrHeight = ({ isAbsenceOrOvertimeCollection, isFreeDay, isShift }: VisibleThings) => {
  if (isShift) {
    if (isFreeDay && isAbsenceOrOvertimeCollection) return '110px';
    if (isFreeDay) return '80px';
    if (isAbsenceOrOvertimeCollection) return '85px';
  }
  if (isAbsenceOrOvertimeCollection) return '60px';
  return 'auto';
};

export const getStylesForSingleFreeDay = ({
  isAbsenceOrOvertimeCollection,
  isShift,
}: VisibleThings): React.CSSProperties => {
  const styles: React.CSSProperties = {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '-3px',
  };
  if (isShift || isAbsenceOrOvertimeCollection) {
    styles.bottom = '7px';
    styles.top = 'unset';
  }
  return styles;
};
