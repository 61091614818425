import { connect } from 'react-redux';

import ScheduleBudgetTableHeaderTotalCell from './ScheduleBudgetTableHeaderTotalCell.jsx';

const mapStateToProps = state => {
  const { dateArray } = state.reducer.mainDateStore;
  const locationIds = state.reducer.scheduleLocationFilter;
  const startDate = dateArray[0];
  const endDate = dateArray[dateArray.length - 1];
  const locationId = locationIds[0];

  return {
    shiftsSummary: state.reducer.schedule.budget.shiftsSummary,
    dateArray: state.reducer.mainDateStore.dateArray,
    budgetTarget: state.reducer.schedule.budget.budgetTargets[`${locationId}_${startDate}_${endDate}`],
    locationIds: state.reducer.scheduleLocationFilter,
    locationSettings: state.reducer.settings.locationSettings[locationId],
    restrictions: state.reducer.userPermissions.restrictions,
  };
};

export default connect(mapStateToProps)(ScheduleBudgetTableHeaderTotalCell);
