export const checkAttendanceEditingBlocked = (locationSettings, locationId, date, currentUser) => {
  if (currentUser.user.role !== 'owner' && !currentUser.user.locations.some(location => location.id === locationId))
    return true;
  const relevantLocationSettings = locationSettings[locationId];
  const isAttendanceEditingBlocked = relevantLocationSettings?.disable_location_attendances_edit_until >= date;
  return isAttendanceEditingBlocked;
};

export const checkShiftEditingBlocked = (locationSettings, locationId, date) => {
  const relevantLocationSettings = locationSettings[locationId];
  const isShiftEditingBlocked = relevantLocationSettings?.disable_location_schedule_shifts_edit_until >= date;
  return isShiftEditingBlocked;
};

export const getLocationsListAsMdSelectOptions = locations =>
  locations.map(location => ({
    value: location.id,
    key: location.name,
  }));

export const getFirstMatchingLocationOption = (multipleLocationFilter, relevantLocations) => {
  if (multipleLocationFilter.length) {
    for (let i = 0; i < multipleLocationFilter.length; i++) {
      const locationId = multipleLocationFilter[i];
      const match = relevantLocations.find(location => location.value === locationId);
      if (match) {
        return match;
      }
    }
    return relevantLocations[0];
  }
  return relevantLocations[0];
};

export const getLocationIdsFromEmployeesWithCommonLocations = (userEmployees, userLocations) => {
  const filteredLocations = [];
  const userLocationIds = userLocations.map(location => location.id);
  userEmployees.forEach(employee => {
    const hasAccessToEmployeesAttendances = employee.locations.some(location => userLocationIds.includes(location.id));
    if (hasAccessToEmployeesAttendances) {
      employee.locations.forEach(location => {
        if (!filteredLocations.some(filteredLocation => filteredLocation.id === location.id)) {
          filteredLocations.push(location);
        }
      });
    }
  });
  return filteredLocations;
};
