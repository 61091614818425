import PropTypes from 'prop-types';

import AttendancesTableCell from './AttendancesTableCell.jsx';
import AttendanceTableCheckbox from './AttendanceTableCheckbox.jsx';

const AttendanceTableRow = ({
  locationSettings,
  row,
  showModal,
  handleCheckboxChange,
  isSelected,
  isDisabled,
  columns,
}) => {
  const relevantLocationSettings = locationSettings[row.location.id];
  const isAttendanceEditingBlocked =
    relevantLocationSettings.disable_location_attendances_edit_until >= row.attendanceObject.date || isDisabled;
  const isShiftEditingBlocked =
    relevantLocationSettings.disable_location_schedule_shifts_edit_until >= row.attendanceObject.date || isDisabled;

  return (
    <tr>
      <td>
        <AttendanceTableCheckbox
          isDisabled={isAttendanceEditingBlocked}
          handleCheckboxChange={handleCheckboxChange}
          attendanceId={row.attendanceObject.id}
          isSelected={isSelected}
        />
      </td>
      {columns.map(column => (
        <td key={column.header}>
          <AttendancesTableCell
            column={column}
            row={row}
            showModal={showModal}
            isAttendanceEditingBlocked={isAttendanceEditingBlocked}
            isShiftEditingBlocked={isShiftEditingBlocked}
          />
        </td>
      ))}
    </tr>
  );
};

AttendanceTableRow.propTypes = {
  row: PropTypes.shape({
    attendanceObject: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showModal: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  locationSettings: PropTypes.shape({}),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTableRow;
