import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import { deleteTemplatesShift } from '@/actions/templates.js';
import { showModal } from '@/actions/uiState.js';
import ScheduleTemplatesTableRow from '@/components/scheduler/Templates/ScheduleTemplatesTableRow.jsx';

const mapStateToProps = state => ({
  userTemplates: state.reducer.userTemplates,
  dateStore: state.reducer.mainDateStore,
  holidays: state.reducer.calendarData.holidays,
  scheduleUIState: state.reducer.scheduleUIState,
});

const mapDispatchToProps = {
  showModal,
  showConfirmModal,
  deleteTemplatesShift,
};
const ScheduleTemplatesTableRowContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleTemplatesTableRow);

export default ScheduleTemplatesTableRowContainer;
