/**
 * Takes two arrays of objects and merges them (by id).
 * It assumes that the objects contain id
 * (which is most of the resources pulled from backend)
 *
 * @export
 * @param {array} firstArray
 * @param {array} secondArray
 * @returns {array}
 */
export function mergeArrayById(firstArray, secondArray) {
  let newArray = [];
  if (firstArray.length === 0) {
    return secondArray;
  }
  if (secondArray.length === 0) {
    return firstArray;
  }
  for (const i in firstArray) {
    let shared = false;
    for (const j in secondArray)
      if (secondArray[j].id === firstArray[i].id) {
        shared = true;
        break;
      }
    if (!shared) newArray.push(firstArray[i]);
  }
  newArray = newArray.concat(secondArray);
  return newArray;
}

export const sortByKey = (array, key, ignoreCase = false, options = {}) => {
  const sortedArray = array.sort((a, b) => {
    const x = ignoreCase ? a[key].toLowerCase() : a[key];
    const y = ignoreCase ? b[key].toLowerCase() : b[key];

    return x < y ? -1 : x > y;
  });
  if (options.decrease) return sortedArray.reverse();
  return sortedArray;
};
export const sortArray = array => array.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

export const deleteIfExistsOrAdd = (array, element) => {
  const resultArray = array.slice();
  for (const i in array) {
    if (array[i] === element) {
      resultArray.splice(i, 1);
      return resultArray;
    }
  }
  resultArray.push(element);
  return resultArray;
};

/**
 * findIndexById - function that returns index in array of
 * the first element with given id
 *
 * @param  {array} array - array to find index in
 * @param  {int} id
 * @return {int} if found then index, if not then -1
 */
export function findIndexById(array, id) {
  for (const i in array) {
    if (array[i].id === id) {
      return i;
    }
  }
  return -1;
}

export const findMaxElementInArray = array => (array.length ? Math.max(...array) : null);

export const sumArrayOfNumbers = numberArray => numberArray.reduce((sum, val) => sum + val, 0);

export const splitInto2EqualParts = array => {
  const middleIndex = Math.ceil(array.length / 2);

  const firstPart = array.splice(0, middleIndex);
  const secondPart = array.splice(-middleIndex);

  return [firstPart, secondPart];
};

export const splitArrayIntoBatches = (array, batchSize) => {
  if (batchSize >= array.length) return [array];
  const batches = [];
  for (let i = 0; i < array.length; i += batchSize) {
    batches.push(array.slice(i, i + batchSize));
  }
  return batches;
};

export const checkArrayIntersection = (arr1, arr2) => arr1.some(r => arr2.includes(r));

export const getRangeArray = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);

export const getLimitedArrayWithRemainder = (array, numberOfElements) => {
  const limitedArray = array.length >= numberOfElements ? array.slice(0, 3) : array;
  const remainder = Math.max(array.length - numberOfElements, 0);
  return { limitedArray, remainder };
};

export const optionalArrayElement = (element, condition) => (condition ? [element] : []);

export const mergeTwoArraysByKeyAndRemoveDuplicates = (firstArray, secondArray, key) => {
  const combinedArray = [...firstArray, ...secondArray];
  const resultArray = combinedArray.filter((item, index, self) => index === self.findIndex(t => t[key] === item[key]));

  return resultArray;
};
