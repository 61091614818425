import PropTypes from 'prop-types';

import './MDRadiosWithSublabel.scss';

const MDRadiosWithSublabel = props => {
  const { title, options, radiosId, selectedItemId, onChange } = props;

  const handleClick = item => {
    onChange({ target: { value: item.id }, name: radiosId });
  };

  return (
    <div className="kmd-radiosWithSublabel">
      {title && <label className="kmd-radiosWithSublabel__title">{title}</label>}
      {options.map(item => (
        <div key={item.id} className="kmd-radiosWithSublabel__item" onClick={() => handleClick(item)}>
          <span className="kmd-radiosWithSublabel__checkbox">
            {selectedItemId === item.id && <span className="kmd-radiosWithSublabel__checkbox--selected" />}
          </span>
          <div className="kmd-radiosWithSublabel__info">
            <span className="kmd-radiosWithSublabel__label">{item.label}</span>
            <span className="kmd-radiosWithSublabel__sublabel">{item.sublabel}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

MDRadiosWithSublabel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItemId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  radiosId: PropTypes.string,
};
MDRadiosWithSublabel.defaultProps = {
  title: '',
  radiosId: '',
};

export default MDRadiosWithSublabel;
