import { connect } from 'react-redux';

import { publishDraftShifts } from '@/actions';
import { getBudgetTargetUsage } from '@/actions/schedule/budget';
import { changeSendPanelVisibility } from '@/actions/uiState.js';
import { getWorkingRulesViolationsForCurrentDateArray } from '@/actions/workingRules.js';

import ScheduleSendPanel from './ScheduleSendPanel.jsx';

const mapStateToProps = state => ({
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  mainDateStore: state.reducer.mainDateStore,
  openShifts: state.reducer.openShifts,
  showWorkingRulesWarning: state.reducer.workingRules.isVerifyWorkingRulesButtonActive,
  showSendPanel: state.reducer.uiState.showSendPanel,
  userJobTitles: state.reducer.userJobTitles,
  userEmployees: state.reducer.userEmployees,
  scheduleState: state.reducer.scheduleState,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
});

const mapDispatchToProps = {
  getWorkingRulesViolationsForCurrentDateArray,
  changeSendPanelVisibility,
  publishDraftShifts,
  getBudgetTargetUsage,
};

const ScheduleSendPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleSendPanel);

export default ScheduleSendPanelContainer;
