import { generateDateArray } from 'kadro-helpers/lib/helpers';
import moment from 'moment';

import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences';
import { getRelevantContractForDate } from '@/utils/contracts';
import {
  checkRecommendedScheduleDisplayPermissions,
  getBudgetMetricsPoints,
  getPointsForShifts,
  getPointsForShiftsAndEmployees,
} from '@/utils/recommendedScheduleHelpers';

const getRelevantShiftsForChart = (visibleEmployees, shifts, contracts = {}) =>
  visibleEmployees.reduce((sum, employeeId) => {
    const employeeShifts = shifts[employeeId] ? Object.values(shifts[employeeId].shifts) : [];

    const filteredShifts = employeeShifts.filter(shift => {
      const employeeContracts = contracts[employeeId] || [];
      const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
      const hasEmployeeAssignedJobTitle = relevantContract?.job_titles.some(
        contractJobTitle => contractJobTitle.job_title_id === shift.job_title.id,
      );
      return hasEmployeeAssignedJobTitle;
    });

    return [...sum, filteredShifts];
  }, []);

const formatData = (
  dateArray,
  absences,
  shifts,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  locationId,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
) =>
  dateArray.map(date => {
    const showRecommendedScheduleGraph = checkRecommendedScheduleDisplayPermissions(restrictions);
    const relevantShifts = getRelevantShiftsForChart(
      visibleEmployees,
      shifts, 
      contracts,
    );

    const absencesForDate = absences.filter(
      absence =>
        moment(date).isBetween(absence.from, moment(absence.to).add(1, 'days'), 'days', '[]') &&
        !absence.omitted_dates.includes(date) &&
        absence.status !== 'rejected',
    );
    const pointsForShifts = getPointsForShifts(
      date, 
      relevantShifts, 
      locationId, 
      selectedJobtitlesGrouped, 
      absencesForDate,
    );
    const shiftsMax = Math.max(...pointsForShifts.map(p => p.ye));
    const pointsForEmployees = getPointsForShiftsAndEmployees(
      date,
      recommendedSchedule,
      locationId,
      pointsForShifts,
      selectedJobtitlesGrouped,
    );
    const employeesMax = Math.max(...pointsForEmployees.map(p => p.ye));
    const recommendedMax = Math.max(...pointsForEmployees.map(p => p.ys));
    let points = showRecommendedScheduleGraph ? pointsForEmployees : pointsForShifts;

    const budgetMetricsPoints = getBudgetMetricsPoints(date, budgetMetrics, budgetMetricsData);

    points = budgetMetricsPoints.map(budgetMetricPoint => ({
      ...budgetMetricPoint,
      ...points.find(({ x }) => x === budgetMetricPoint.x),
      x: budgetMetricPoint.x,
    }));

    const budgetMetricsMax = budgetMetricsPoints.reduce(
      (previousMetricsMax, budgetMetricsPoint) => ({
        ...previousMetricsMax,
        ...Object.keys(budgetMetricsPoint)
          .filter(key => key !== 'x')
          .reduce(
            (acc, metricId) => ({
              ...acc,
              [metricId]: Math.max(acc[metricId] || 0, previousMetricsMax[metricId] || 0, budgetMetricsPoint[metricId]),
            }),
            {},
          ),
      }),
      {},
    );

    // TODO: it will be required when we have budget data on charts
    // const estimatedBudget = getEstimatedBudgetPoints(
    //   date,
    //   budgetEstimates,
    //   hourlyEstimates,
    //   locationSettings,
    //   locationId,
    //   shifts,
    // );
    // const pointsWithBudget = points.map(item => {
    //   const relevantBudget = estimatedBudget.find(estimate => estimate.x === item.x);

    //   return {
    //     ...item,
    //     ...relevantBudget,
    //   };
    // });

    return {
      points,
      employeesMax,
      recommendedMax,
      shiftsMax,
      date,
      budgetMetricsMax,
      // estimatedBudget,
    };
  });

const formatDataToRecommendedScheduleState = (data, oldState, locationId) =>
  data.reduce((result, item) => {
    const { date } = item;
    const oldItemForTheDate = oldState.scheduleChartsData[locationId]?.[date];
    const newDateItem = {
      ...oldItemForTheDate,
      points: item.points.map(hourData => {
        const oldItem = oldItemForTheDate?.points.find(p => p.x === hourData.x);
        return {
          ...(oldItem || {}),
          x: hourData.x,
          ys: hourData.ys,
        };
      }),
    };

    return {
      ...result,
      [date]: newDateItem,
    };
  }, {});

const formatDataToScheduleState = (data, oldState, locationId) =>
  data.reduce((result, item) => {
    const oldItemForTheDate =
      oldState.scheduleChartsData[locationId] && oldState.scheduleChartsData[locationId][item.date];
    const newDateItem = {
      ...oldItemForTheDate,
      points: item.points,
    };

    return {
      ...(oldState.scheduleChartsData[locationId] || {}),
      ...result,
      [item.date]: newDateItem,
    };
  }, {});

const formatShifts = (newShifts, oldShifts) =>
  newShifts.reduce((result, shift) => {
    const employeeId = shift.employee.id;
    const employeeShifts = result[employeeId]?.shifts || {};

    const oldEmployeeShifts = Object.entries(oldShifts[employeeId]?.shifts || {}).reduce((res, [id, s]) => {
      if (Number.isNaN(Number(s.id))) {
        return res;
      }

      return {
        ...res,
        [id]: s,
      };
    }, {});

    return {
      ...result,
      [employeeId]: {
        shifts: {
          ...oldEmployeeShifts,
          ...employeeShifts,
          [shift.id]: shift,
        },
      },
    };
  }, {});

const isAbsenceForShift = (absences, shift) =>
  absences.some(
    absence =>
      !absence.absence_hours &&
      absence.employee_id === shift.employee.id &&
      shift.date >= absence.from &&
      shift.date <= absence.to,
  );

const getMaxValues = data =>
  data.reduce(
    (result, item) => ({
      employeesMax: result.employeesMax > item.employeesMax ? result.employeesMax : item.employeesMax,
      recommendedMax: result.recommendedMax > item.recommendedMax ? result.recommendedMax : item.recommendedMax,
      budgetMetricsMax: {
        ...result.budgetMetricsMax,
        ...Object.keys(item.budgetMetricsMax).reduce(
          (acc, metricId) => ({
            ...acc,
            [metricId]: Math.max(
              item.budgetMetricsMax[metricId] || 0,
              acc[metricId] || 0,
              result.budgetMetricsMax[metricId] || 0,
            ),
          }),
          {},
        ),
      },
    }),
    {
      employeesMax: 0,
      recommendedMax: 0,
      budgetMetricsMax: {},
    },
  );

export const getHighestEmployeesAmountForNewPoints = newPoints =>
  Object.keys(newPoints).reduce((prev, date) => {
    const maxEmployeesForCurrentDate = newPoints[date].points.reduce(
      (maxValue, { ye }) => (ye >= maxValue ? ye : maxValue),
      0,
    );
    return maxEmployeesForCurrentDate <= prev ? prev : maxEmployeesForCurrentDate;
  }, 0);

export const handleDeleteRecommendedSchedule = (action, oldState) => {
  const { from, to, locationId } = action.payload;
  const dates = generateDateArray(from, to);
  const data = {
    [locationId]: {
      ...(oldState.scheduleChartsData[locationId] || {}),
      ...dates.reduce((res, date) => {
        const oldPoints = oldState.scheduleChartsData[locationId]?.[date]?.points;
        const newPoints = oldPoints
          ? oldPoints.map(point => ({ ...point, ys: 0 }))
          : Array.from({ length: 96 }, (_, index) => ({ x: index, ys: 0 }));
        return {
          ...res,
          [date]: {
            points: newPoints,
            date,
          },
        };
      }, {}),
    },
  };
  const newState = formatDataToRecommendedScheduleState(Object.values(data[locationId]), oldState, locationId);

  return newState;
};

const isShiftPassingMidnight = workingHours => {
  const [startHour, endHour] = workingHours.split('-');

  return startHour > endHour;
};

const getNextDay = date =>
  moment(date)
    .add(1, 'days')
    .format('YYYY-MM-DD');

export const handleAddShift = (
  action,
  oldState,
  flatAbsences,
  scheduleState,
  shifts,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const hasAbsence = isAbsenceForShift(flatAbsences, action.payload.new_shift);
  if (hasAbsence) {
    return null;
  }
  const locationId = action.payload.new_shift.location.id;
  const { date } = action.payload.new_shift;
  const locationScheduleState = scheduleState.locations[locationId];
  const visibleEmployees = locationScheduleState ? locationScheduleState.visible : [];
  const newShifts = {
    ...shifts,
    [action.payload.new_shift.employee.id]: {
      ...(shifts[action.payload.new_shift.employee.id] || {}),
      shifts: {
        ...(shifts[action.payload.new_shift.employee.id]?.shifts || {}),
        [action.payload.new_shift.id]: action.payload.new_shift,
      },
    },
  };

  delete newShifts[action.payload.new_shift.employee.id]?.shifts[action.payload.uid];

  const dates = isShiftPassingMidnight(action.payload.new_shift.working_hours) ? [date, getNextDay(date)] : [date];

  const data = formatData(
    dates,
    flatAbsences,
    newShifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return {
    newState,
    employeesMax,
  };
};

export const handleEditShift = (
  action,
  oldState,
  flatAbsences,
  shifts,
  scheduleState,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const { employee_id: employeeId, newShiftObject } = action.payload;
  const hasAbsence = isAbsenceForShift(flatAbsences, newShiftObject);

  if (hasAbsence) {
    return null;
  }
  const locationId = newShiftObject.location.id;
  const oldShift = shifts[employeeId]?.shifts[newShiftObject.id];
  const locationScheduleState = scheduleState.locations[locationId];
  const visibleEmployees = locationScheduleState ? locationScheduleState.visible : [];

  if (
    oldShift?.working_hours === newShiftObject.working_hours &&
    oldShift?.job_title?.id === newShiftObject.job_title.id
  ) {
    return null;
  }

  const newShifts = {
    ...shifts,
    [employeeId]: {
      ...(shifts[employeeId] || {}),
      shifts: {
        ...(shifts[employeeId]?.shifts || {}),
        [newShiftObject.id]: newShiftObject,
      },
    },
  };
  const data = formatData(
    dateArray,
    flatAbsences,
    newShifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);

  return newState;
};

export const handleDeleteShift = (
  action,
  oldState,
  flatAbsences,
  scheduleState,
  shifts,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const { employee_id: employeeId, shift_id: shiftId, shift } = action.payload;
  const hasAbsence = isAbsenceForShift(flatAbsences, shift);

  if (hasAbsence) {
    return null;
  }
  const locationId = shift.location.id;
  const { date } = shift;
  const locationScheduleState = scheduleState.locations[locationId];
  const visibleEmployees = locationScheduleState ? locationScheduleState.visible : [];

  delete shifts[employeeId]?.shifts[shiftId];
  const dates = isShiftPassingMidnight(action.payload.shift.working_hours) ? [date, getNextDay(date)] : [date];

  const data = formatData(
    dates,
    flatAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);

  return newState;
};

export const handleNewRecommendedSchedule = (
  action,
  locationId,
  oldState,
  dateArray,
  flatAbsences,
  shifts,
  selectedJobtitlesGrouped,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const recommendedScheduleForLocation = action.payload.filter(item => item.location_id === locationId);
  const recommendedScheduleDates = recommendedScheduleForLocation.map(i => i.date);
  const relevantDates = recommendedScheduleDates.filter(date => dateArray.includes(date));

  const data = formatData(
    relevantDates,
    flatAbsences,
    shifts,
    selectedJobtitlesGrouped,
    { recommendedSchedule: recommendedScheduleForLocation },
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const dataForAllDays = dateArray.map(day => {
    const updatedDayData = data.find(({ date }) => date === day);
    if (updatedDayData) {
      return {
        date: day,
        points: updatedDayData.points,
      };
    }

    const oldDayData = oldState.scheduleChartsData[locationId]?.[day];
    if (oldDayData) {
      return {
        date: day,
        points: oldDayData.points,
      };
    }

    return {
      date: day,
      points: Array.from({ length: 96 }, (_, index) => ({ x: index, ys: 0 })),
    };
  });

  const newState = formatDataToRecommendedScheduleState(dataForAllDays, oldState, locationId);
  const { recommendedMax } = getMaxValues(data);

  return { newState, recommendedMax };
};

export const handleGetSchedule = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const allShifts = action.payload.flatMap(employee => employee.shifts);
  const relevantShifts = allShifts.filter(
    shift => shift.location.id === locationId && !isAbsenceForShift(flatAbsences, shift),
  );
  if (relevantShifts.length === 0) {
    return null;
  }
  const formattedShifts = relevantShifts.reduce((result, shift) => {
    const employeeId = shift.employee.id;
    const employeeShifts = result[employeeId]?.shifts || {};
    const oldEmployeeShifts = shifts[employeeId]?.shifts || {};

    return {
      ...result,
      [employeeId]: {
        shifts: {
          ...oldEmployeeShifts,
          ...employeeShifts,
          [shift.id]: shift,
        },
      },
    };
  }, {});

  const data = formatData(
    dateArray,
    flatAbsences,
    formattedShifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleAddShifts = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const { newShifts } = action;
  const relevantNewShifts = newShifts.filter(
    shift => shift.location.id === locationId && !isAbsenceForShift(flatAbsences, shift),
  );

  if (relevantNewShifts.length === 0) {
    return null;
  }

  const formattedShifts = formatShifts(relevantNewShifts, shifts);

  const data = formatData(
    dateArray,
    flatAbsences,
    { ...shifts, ...formattedShifts },
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleDuplicatePreviousView = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const newShifts = action.payload;
  const relevantNewShifts = newShifts.filter(
    shift => shift.location.id === locationId && !isAbsenceForShift(flatAbsences, shift),
  );

  if (relevantNewShifts.length === 0) {
    return null;
  }

  const formattedShifts = formatShifts(relevantNewShifts, shifts);

  const data = formatData(
    dateArray,
    flatAbsences,
    { ...shifts, ...formattedShifts },
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);

  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleChangeJobTitleFilterGrouped = (
  action,
  locationId,
  oldState,
  dateArray,
  flatAbsences,
  shifts,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const selectedJobTitles = action.payload;

  const data = formatData(
    dateArray,
    flatAbsences,
    shifts,
    selectedJobTitles,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax, recommendedMax } = getMaxValues(data);

  return { newState, employeesMax, recommendedMax };
};

export const handleGetAbsences = (
  action,
  locationId,
  oldState,
  dateArray,
  shifts,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const flatFetchedAbsences = Object.values(action.payload)
    .flat()
    .filter(({ status }) => !NOT_COUNTED_ABSENCE_STATUSES.includes(status));

  const data = formatData(
    dateArray,
    flatFetchedAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleAddAbsence = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const absence = action.payload;
  const hasShift = Object.values(shifts)
    .flatMap(s => Object.values(s.shifts))
    .some(shift => shift.employee.id === absence.employee_id && shift.date >= absence.from && shift.date <= absence.to);

  if (!hasShift) {
    return null;
  }
  const newAbsences = [...flatAbsences, absence];

  const data = formatData(
    dateArray,
    newAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleEditAbsence = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const absence = action.payload;
  const hasShift = Object.values(shifts)
    .flatMap(s => Object.values(s.shifts))
    .some(shift => shift.employee.id === absence.employee_id && shift.date >= absence.from && shift.date <= absence.to);

  if (!hasShift || absence.status !== 'rejected') {
    return null;
  }
  const newAbsences = flatAbsences.filter(a => a.id !== action.payload.id);

  const data = formatData(
    dateArray,
    newAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleDeleteAbsence = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const deletedAbsence = flatAbsences.find(absence => absence.id === action.payload.id);
  const hasShift = Object.values(shifts)
    .flatMap(s => Object.values(s.shifts))
    .some(
      shift =>
        shift.employee.id === deletedAbsence.employee_id &&
        shift.date >= deletedAbsence.from &&
        shift.date <= deletedAbsence.to,
    );

  if (!hasShift) {
    return null;
  }

  const newAbsences = flatAbsences.filter(absence => absence.id !== action.payload.id);

  const data = formatData(
    dateArray,
    newAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleUpdateScheduleViewEmployeesList = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const newVisibleEmployees = action.payload[locationId]?.visible || [];

  const data = formatData(
    dateArray,
    flatAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    newVisibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleAddMassShifts = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  dateArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const { payload: newShifts } = action;
  const relevantNewShifts = newShifts.filter(
    shift => shift.location.id === locationId && !isAbsenceForShift(flatAbsences, shift),
  );

  if (relevantNewShifts.length === 0) {
    return null;
  }

  const formattedShifts = formatShifts(relevantNewShifts, shifts);

  const data = formatData(
    dateArray,
    flatAbsences,
    { ...shifts, ...formattedShifts },
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );
  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { employeesMax } = getMaxValues(data);

  return { newState, employeesMax };
};

export const handleDeleteMultipleShifts = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const shiftIds = Object.values(action.payload).flatMap(item => item.map(({ shiftId }) => shiftId));
  const deletedShifts = Object.values(shifts)
    .flatMap(item => Object.values(item.shifts))
    .filter(shift => shiftIds.includes(shift.id));
  const hasAbsencesForAllShifts = deletedShifts.every(shift => isAbsenceForShift(flatAbsences, shift));
  if (hasAbsencesForAllShifts) {
    return null;
  }
  const dates = deletedShifts.reduce((result, shift) => {
    const shiftDates = isShiftPassingMidnight(shift.working_hours)
      ? [shift.date, getNextDay(shift.date)]
      : [shift.date];

    return [...result, ...shiftDates];
  }, []);
  const uniqueDates = Array.from(new Set(dates));
 
  const newShifts = { ...shifts };
  deletedShifts.forEach(shift => {
    delete newShifts[shift.employee.id].shifts[shift.id];
  });

  const data = formatData(
    uniqueDates,
    flatAbsences,
    newShifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    budgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);

  return newState;
};

export const handleGetBudgetMetricsData = (
  action,
  locationId,
  oldState,
  flatAbsences,
  shifts,
  datesArray,
  selectedJobtitlesGrouped,
  recommendedSchedule,
  restrictions,
  visibleEmployees,
  budgetMetrics,
  budgetMetricsData,
  contracts,
 ) => {
  const newBudgetMetricsData = {
    ...budgetMetricsData,
    ...action.payload,
  };

  const data = formatData(
    datesArray,
    flatAbsences,
    shifts,
    selectedJobtitlesGrouped,
    recommendedSchedule,
    restrictions,
    locationId,
    visibleEmployees,
    budgetMetrics,
    newBudgetMetricsData,
    contracts,
   );

  const newState = formatDataToScheduleState(data, oldState, locationId);
  const { budgetMetricsMax } = getMaxValues(data);

  return { newState, budgetMetricsMax };
};
