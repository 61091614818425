export interface LabelOption {
  id: string;
  label: string;
  color: string;
}

export const LABELS_COLORS = [
  '#ffbf42',
  '#f98849',
  '#ff7465',
  '#ff5f64',
  '#ff7ca5',
  '#da93f1',
  '#b38bf7',
  '#907af9',
  '#5b7cf9',
  '#54b8fb',
  '#3cd7e9',
  '#2ccfc0',
  '#99d660',
  '#5dc16d',
  '#41aa7f',
  '#4a94aa',
  '#7296a6',
  '#99796e',
];

export const getInitialOptionState = (label: LabelOption, search: string) => ({
  id: label?.id,
  label: search || label?.label,
  color: label?.color || '',
  error: { inputError: '', colorPickerError: '' },
});
export const getLabelsColorWithStatus = (colors: string[], editColor: string) =>
  colors.map((color, index) => ({
    index,
    color,
    active: editColor ? color.toLowerCase() === editColor.toLowerCase() : false,
  }));
