import { defineMessages } from 'react-intl';

import {
  FREEMIUM_EVENT_TOGGLE_OPEN_SHIFT,
  FREEMIUM_EVENT_TOGGLE_TRADE_SHIFT,
  FREEMIUM_EVENT_WORKING_RULES,
} from '@/constants/intercom.js';
import {
  AUTO_GENERATE_SCHEDULE_VIEW,
  BUDGET_METRICS_ENABLE,
  EMPLOYMENT_CONDITIONS_CREATE,
  LOAN_EMPLOYEES_ENABLE,
  OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE,
  OPEN_SHIFTS_GET,
  SUPPLEMENTARY_LOCATIONS_VIEW,
  TRADE_SHIFTS_GET,
} from '@/constants/Permissions';
import {
  BUDGET_INFO_HIDE,
  FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE,
  FREEMIUM_HIDE_OPEN_SHIFTS,
  FREEMIUM_HIDE_SCHEDULE_TEMPLATES,
  FREEMIUM_HIDE_TRADE_SHIFTS,
  FREEMIUM_HIDE_WORKING_RULES,
  SCHEDULE_EDIT_DISABLE,
  SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES_HIDE_ADD,
  WORKING_RULES_HIDE,
} from '@/constants/Restrictions';
import { checkArrayIntersection } from '@/utils/arrayHelpers';
import { isBeforeTimestamp } from '@/utils/dateHelper';

const scheduleMessages = defineMessages({
  autogenerateSchedule: {
    id: 'schedule.settings.generatingSchedule',
    defaultMessage: 'Generowanie grafiku',
  },
  weather: {
    id: 'schedule.settings.weather',
    defaultMessage: 'Pogoda',
  },
  budget: {
    id: 'schedule.settings.budget',
    defaultMessage: 'Budżet',
  },
  hoursBalance: {
    id: 'schedule.settings.hoursBalance',
    defaultMessage: 'Bilans godzin',
  },
  workingRules: {
    id: 'schedule.settings.workingRules',
    defaultMessage: 'Kodeks pracy',
  },
  events: {
    id: 'schedule.settings.events',
    defaultMessage: 'Wydarzenia i notatki',
  },
  holidays: {
    id: 'schedule.settings.holidays',
    defaultMessage: 'Święta',
  },
  employeesAmount: {
    id: 'schedule.settings.employeesAmount',
    defaultMessage: 'Liczba pracowników',
  },
  budgetMetrics: {
    id: 'schedule.settings.budgetMetrics',
    defaultMessage: 'Metryki budżetu',
  },
  tradeShifts: {
    id: 'schedule.settings.tradeShifts',
    defaultMessage: 'Wymiana zmian',
  },
  openShifts: {
    id: 'schedule.settings.openShifts',
    defaultMessage: 'Otwarte zmiany',
  },
  supplementaryEmployees: {
    id: 'schedule.settings.supplementaryEmployees',
    defaultMessage: 'Pokaż pracowników pomocniczych',
  },
  loanEmployeesProposals: {
    id: 'schedule.settings.loanEmployeesProposals',
    defaultMessage: 'Udostępnienie pracownika',
  },
  loanedEmployees: {
    id: 'schedule.settings.loanedEmployees',
    defaultMessage: 'Pokaż pracowników udostępnionych',
  },
  showSupplementaryEmployeesWithoutShift: {
    id: 'schedule.settings.showSupplementaryEmployeesWithoutShift',
    defaultMessage: 'Pokaż pracowników pomocniczych bez zmiany',
  },
  showEmployeesWithoutShifts: {
    id: 'schedule.settings.showEmployeesWithoutShifts',
    defaultMessage: 'Pokaż pracowników bez zaplanowanych zmian',
  },
  showShiftsFromOtherLocations: {
    id: 'schedule.settings.showShiftsFromOtherLocations',
    defaultMessage: 'Pokaż zmiany z innych lokalizacji',
  },
  shiftsView: {
    id: 'schedule.settings.shiftsView',
    defaultMessage: 'Widok zmian (stary)',
  },
  shiftsBetaView: {
    id: 'schedule.settings.shiftsBetaView',
    defaultMessage: 'Widok zmian',
  },
  availabilityView: {
    id: 'schedule.settings.availabilityView',
    defaultMessage: 'Widok dostępności',
  },
  positionsView: {
    id: 'schedule.settings.positionsView',
    defaultMessage: 'Widok stanowisk',
  },
  templatesView: {
    id: 'schedule.settings.templatesView',
    defaultMessage: 'Widok szablonów',
  },
  priority: {
    id: 'schedule.settings.priority',
    defaultMessage: 'Priorytet',
  },
  note: {
    id: 'schedule.settings.note',
    defaultMessage: 'Notatka',
  },
  showWeatherForecast: {
    id: 'schedule.settings.weatherForecast',
    defaultMessage: 'Prognoza pogody',
  },
});

export const SCHEDULE_DISPLAY_MODE_TYPE = 'schedule';
export const AVAILABILITIES_DISPLAY_MODE_TYPE = 'availabilities';
export const POSITIONS_DISPLAY_MODE_TYPE = 'positions';
export const TEMPLATES_DISPLAY_MODE_TYPE = 'templates';

export const templateDisplayMode = {
  id: 3,
  nextId: 3,
  type: TEMPLATES_DISPLAY_MODE_TYPE,
  displayText: 'Widok szablonów',
  message: scheduleMessages.templatesView,
};

export const scheduleDisplayModes = (userPermissions = { permissions: [], restrictions: [] }) => {
  let displayModes = [
    {
      id: 4,
      nextId: 4,
      type: SCHEDULE_DISPLAY_MODE_TYPE,
      displayText: 'Widok zmian',
      message: scheduleMessages.shiftsBetaView,
    },
    {
      id: 1,
      nextId: 0,
      type: AVAILABILITIES_DISPLAY_MODE_TYPE,
      displayText: 'Widok dostępności',
      message: scheduleMessages.availabilityView,
    },
    {
      id: 2,
      nextId: 2,
      type: POSITIONS_DISPLAY_MODE_TYPE,
      displayText: 'Widok stanowisk',
      message: scheduleMessages.positionsView,
    },
  ];
  if (
    !checkArrayIntersection(userPermissions.restrictions, [SCHEDULE_EDIT_DISABLE, FREEMIUM_HIDE_SCHEDULE_TEMPLATES])
  ) {
    displayModes = [
      ...displayModes,
      {
        ...templateDisplayMode,
      },
    ];
  }

  return displayModes;
};
// NEXT ID TELLS US WHERE ARE WE GOING TO CHANGE TO WHEN WE FLIP

export const scheduleEventTypes = [
  {
    name: scheduleMessages.priority,
    type: 'important',
  },
  {
    name: scheduleMessages.note,
    type: 'note',
  },
];

export function createScheduleSettings(userPermissions, companyCreationTimestamp) {
  const scheduleSettings = [];

  scheduleSettings.push({
    value: true,
    disabled: false,
    type: 'budget',
    displayText: scheduleMessages.budget,
    restriction: BUDGET_INFO_HIDE,
    displayModes: [
      SCHEDULE_DISPLAY_MODE_TYPE,
      AVAILABILITIES_DISPLAY_MODE_TYPE,
      POSITIONS_DISPLAY_MODE_TYPE,
      TEMPLATES_DISPLAY_MODE_TYPE,
    ],
    showFunc: () => true,
  });

  if (userPermissions.permissions.includes(EMPLOYMENT_CONDITIONS_CREATE)) {
    scheduleSettings.push({
      value: true,
      disabled: false,
      type: 'hoursBalance',
      displayText: scheduleMessages.hoursBalance,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE],
      showFunc: () => true,
    });
  }

  scheduleSettings.push({
    value: true,
    disabled: true,
    type: 'workingRules',
    displayText: scheduleMessages.workingRules,
    restriction: WORKING_RULES_HIDE,
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
    showFunc: () => true,
    freemiumRestrictions: [FREEMIUM_HIDE_WORKING_RULES],
    intercomFreemiumEvent: FREEMIUM_EVENT_WORKING_RULES,
  });

  scheduleSettings.push({
    value: false,
    disabled: false,
    type: 'events',
    displayText: scheduleMessages.events,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE, POSITIONS_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  scheduleSettings.push({
    value: true,
    disabled: false,
    type: 'holidays',
    displayText: scheduleMessages.holidays,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE, POSITIONS_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  if (userPermissions.permissions.includes(TRADE_SHIFTS_GET)) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'tradeShifts',
      displayText: scheduleMessages.tradeShifts,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      showFunc: (locationSettings, scheduleLocationFilter) =>
        scheduleLocationFilter.some(locId => locationSettings[locId]?.enable_trade_shift),
      freemiumRestrictions: [FREEMIUM_HIDE_TRADE_SHIFTS],
      intercomFreemiumEvent: FREEMIUM_EVENT_TOGGLE_TRADE_SHIFT,
    });
  }

  if (userPermissions.permissions.includes(OPEN_SHIFTS_GET)) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'openShifts',
      displayText: scheduleMessages.openShifts,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      showFunc: (locationSettings, scheduleLocationFilter) =>
        scheduleLocationFilter.some(locId => locationSettings[locId]?.enable_open_shift),
      freemiumRestrictions: [FREEMIUM_HIDE_OPEN_SHIFTS],
      intercomFreemiumEvent: FREEMIUM_EVENT_TOGGLE_OPEN_SHIFT,
    });
  }

  scheduleSettings.push({
    value: false,
    disabled: false,
    type: 'employeesAmount',
    displayText: scheduleMessages.employeesAmount,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  if (
    !userPermissions.restrictions.includes(BUDGET_INFO_HIDE) &&
    userPermissions.permissions.includes(BUDGET_METRICS_ENABLE)
  ) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'budgetMetrics',
      displayText: scheduleMessages.budgetMetrics,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      showFunc: () => true,
    });
  }

  if (userPermissions.permissions.includes(SUPPLEMENTARY_LOCATIONS_VIEW)) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'supplementaryEmployees',
      displayText: scheduleMessages.supplementaryEmployees,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE, POSITIONS_DISPLAY_MODE_TYPE],
      showFunc: () => true,
    });

    if (!userPermissions.restrictions.includes(SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES_HIDE_ADD)) {
      scheduleSettings.push({
        value: false,
        disabled: false,
        type: 'showSupplementaryEmployeesWithoutShift',
        displayText: scheduleMessages.showSupplementaryEmployeesWithoutShift,
        restriction: '',
        displayModes: [SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE, POSITIONS_DISPLAY_MODE_TYPE],
        showFunc: () => true,
      });
    }
  }

  scheduleSettings.push({
    value: true,
    disabled: false,
    type: 'showEmployeesWithoutShifts',
    displayText: scheduleMessages.showEmployeesWithoutShifts,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  scheduleSettings.push({
    value: true,
    disabled: false,
    type: 'showShiftsFromOtherLocations',
    displayText: scheduleMessages.showShiftsFromOtherLocations,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  scheduleSettings.push({
    value: false,
    disabled: false,
    type: 'showWeatherForecast',
    displayText: scheduleMessages.showWeatherForecast,
    restriction: '',
    displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
    showFunc: () => true,
  });

  if (
    userPermissions.permissions.includes(LOAN_EMPLOYEES_ENABLE) ||
    userPermissions.permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE)
  ) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'loanedEmployees',
      displayText: scheduleMessages.loanedEmployees,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      showFunc: () => true,
    });
  }

  if (userPermissions.permissions.includes(LOAN_EMPLOYEES_ENABLE)) {
    scheduleSettings.push({
      value: false,
      disabled: false,
      type: 'loanEmployeesProposals',
      displayText: scheduleMessages.loanEmployeesProposals,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      showFunc: () => true,
    });
  }

  if (userPermissions.permissions.includes(AUTO_GENERATE_SCHEDULE_VIEW)) {
    const dateOfReleaseWithHidingAutoScheduler = '2024.05.20';
    scheduleSettings.push({
      value: isBeforeTimestamp(companyCreationTimestamp, dateOfReleaseWithHidingAutoScheduler),
      disabled: false,
      type: 'autogenerateSchedule',
      displayText: scheduleMessages.autogenerateSchedule,
      restriction: '',
      displayModes: [SCHEDULE_DISPLAY_MODE_TYPE],
      freemiumRestrictions: [FREEMIUM_DISABLE_AUTO_GENERATE_SCHEDULE],
      showFunc: () => true,
    });
  }

  return scheduleSettings.map((s, i) => ({ ...s, id: i + 1 }));
}

export const TEMPLATE_TYPES = {
  BASIC: 'standard',
  FLEX: 'flexible',
};

export const OPEN_SHIFTS_TEMPLATE_ID = 'OPEN_SHIFTS_TEMPLATE_ID';

export const SCHEDULE_TABLE_EMPTY_STATE_STYLES = {
  filterStyle: {
    width: '17vw',
    minWidth: '200px',
    maxWidth: '260px',
  },
};

export const AVAILABILITIES = 'availabilities';

export const JOBTITLE_COUNT = 'jobtitle_count';
