import classNames from 'classnames';
import PropTypes from 'prop-types';

import './icon.scss';

const Icon = props => {
  if (!['material', 'material-outlined', 'kadro', 'material-symbols'].includes(props.type))
    throw new Error('Unsupported Icon type');

  switch (props.type) {
    case 'material': {
      const className = classNames('material-symbols-outlined', 'k-icon--filled', props.className);

      return (
        <i className={className} style={props.style}>
          {props.name}
        </i>
      );
    }

    case 'material-symbols':
    case 'material-outlined': {
      const className = classNames('material-symbols-outlined', props.className);

      return (
        <i className={className} style={props.style}>
          {props.name}
        </i>
      );
    }
    case 'kadro': {
      const className = classNames('k-icon', `k-icon-${props.name}`, props.className);

      return <i className={className} style={props.style} />;
    }
    default:
      return <i />;
  }
};

Icon.defaultProps = {
  type: 'material',
};

Icon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default Icon;
