import { Dispatch, SetStateAction, useRef } from 'react';

import Popover from '@/components/common/Popover/Popover';

import AddAndEditLabelPopoverContent from './AddAndEditLabelPopoverContent/AddAndEditLabelPopoverContent.redux.js';

const Y_OFFSET = -15;

interface AddAndEditLabelPopoverProps {
  children: JSX.Element;
  setPopoverStatus?: Dispatch<SetStateAction<boolean>>;
  label?: {
    label: string;
    show: boolean;
    selected: boolean;
    color: string;
    id: string;
  };
  search?: string;
  edit?: boolean;
  wrapperClassName?: string;
  clearInputValue?: () => void;
  setSelectedColor?: (color: string) => void;
}

const popoverStyles = { padding: 0, borderRadius: 2, border: '0px solid transparent' };

const AddAndEditLabelPopover = ({
  children,
  search,
  label,
  edit,
  wrapperClassName,
  setPopoverStatus,
  clearInputValue,
  setSelectedColor,
}: AddAndEditLabelPopoverProps) => {
  const popoverRef = useRef();

  return (
    <Popover
      ref={popoverRef}
      showShadow={false}
      noArrow
      yOffset={Y_OFFSET}
      popoverStyle={popoverStyles}
      centerOnMobile={false}
      hideOnBlur
      wrapperClassName={wrapperClassName}
      content={
        <AddAndEditLabelPopoverContent
          search={search}
          label={label}
          edit={edit}
          popoverRef={popoverRef}
          clearInputValue={clearInputValue}
          setSelectedColor={setSelectedColor}
        />
      }
      setDisplayStatus={setPopoverStatus}
    >
      {children}
    </Popover>
  );
};

export default AddAndEditLabelPopover;
