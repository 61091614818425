import { ScheduleFreeDayProps } from './ScheduleFreeDay.types';
import { ScheduleFreeDayBlock } from './ScheduleFreeDayBlock/ScheduleFreeDayBlock';
import { useScheduleFreeDay } from './useScheduleFreeDay';

export const ScheduleFreeDay = (props: ScheduleFreeDayProps) => {
  const { freeDay, isFreeDayVisible } = useScheduleFreeDay(props);

  if (!isFreeDayVisible) return null;

  return <ScheduleFreeDayBlock freeDay={freeDay} />;
};
