import { connect } from 'react-redux';

import { createOpenShift } from '@/actions/openShifts.js';
import { toggleImportOpenShiftsModal } from '@/actions/uiState.js';
import ImportOpenShiftModal from '@/components/scheduler/openShift/ImportOpenShiftModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showImportOpenShiftsModal,
  userTemplates: state.reducer.userTemplates,
  locationId: state.reducer.uiState.showImportOpenShiftsModal,
  dateStore: state.reducer.mainDateStore,
});

const mapDispatchToProps = {
  toggleImportOpenShiftsModal,
  createOpenShift,
};

const ImportOpenShiftModalContainer = connect(mapStateToProps, mapDispatchToProps)(ImportOpenShiftModal);

export default ImportOpenShiftModalContainer;
