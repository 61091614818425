import { unionBy } from 'lodash';

import * as AT from '@/constants/ActionTypes.js';
import { sumArrayOfNumbers } from '@/utils/arrayHelpers.js';
import { getNumberOfBudgetEstimateItems } from '@/utils/budgetHelpers.js';

const initialState = {
  estimates: [],
  hourEstimates: [],
  errorMessages: [],
  imported: false,
};

const budgetEstimates = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_BUDGET_ESTIMATES_SUCCESS: {
      const aggregatedPayload = action.payload.map(estimate => ({
        ...estimate,
        estimated_budget: sumArrayOfNumbers(estimate.estimated_budget),
        actual_budget: sumArrayOfNumbers(estimate.actual_budget),
        labour_limit: sumArrayOfNumbers(estimate.labour_limit),
        target_spmh: sumArrayOfNumbers(estimate.target_spmh),
      }));

      const formattedPayload = aggregatedPayload.map(item => {
        const numberOfItems = getNumberOfBudgetEstimateItems(item.period);

        return {
          ...item,
          labour_limit: item.labour_limit / numberOfItems,
          target_spmh: item.target_spmh / numberOfItems,
        };
      });

      return {
        ...state,
        estimates: formattedPayload,
        hourEstimates: action.payload,
      };
    }
    case AT.EDIT_BUDGET_ESTIMATES_SUCCESS: {
      const budgetEstimateIndex = state.estimates.findIndex(
        estimate => estimate.date === action.payload.date && estimate.location_id === action.payload.location_id,
      );

      return {
        ...state,
        estimates: [
          ...state.estimates.slice(0, budgetEstimateIndex),
          { ...state.estimates[budgetEstimateIndex], ...action.payload },
          ...state.estimates.slice(budgetEstimateIndex + 1),
        ],
      };
    }
    case AT.DELETE_BUDGET_ESTIMATES_SUCCESS:
      return { ...state, estimates: state.estimates.filter(estimate => estimate.id !== action.payload.id) };
    case AT.ADD_MULTIPLE_ESTIMATES_SUCCESS:
      return { ...state, estimates: unionBy(action.payload, state.estimates, 'date') };
    case AT.ADD_MULTIPLE_ESTIMATES_ERROR:
      return { ...state, imported: true, errorMessages: action.payload };
    case AT.HIDE_MODAL:
      return { ...state, imported: false };
    default:
      return state;
  }
};
export default budgetEstimates;
