import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { calculateScheduleTableHeight } from '@/utils/schedulerHelpers';

import EmployeeScheduleTable from '../EmployeeScheduleTable/EmployeeScheduleTable.redux';

const EmployeeScheduleAllTables = props => {
  const { scheduleLocationFilter, userLocations } = props;
  const selectedLocations = useMemo(() => userLocations.filter(loc => scheduleLocationFilter.includes(loc.id)), [
    scheduleLocationFilter,
    userLocations,
  ]);
  const windowHeight = window.innerHeight;
  const height = useMemo(
    () => calculateScheduleTableHeight(windowHeight, { showBudgetTable: false, showEventsTable: true }),
    [windowHeight],
  );

  return (
    <div>
      {selectedLocations.map(location => (
        <EmployeeScheduleTable key={location.id} location={location} height={height} />
      ))}
    </div>
  );
};

EmployeeScheduleAllTables.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeScheduleAllTables;
