import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SortingArrow from '@/components/common/Basic/SortingArrow.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { employeesTable } from '@/constants/tableColumns.jsx';

import './employeesHeader.scss';

const EmployeesHeader = ({ visibleColumns, sortFunc, uiData }) => (
  <thead className="k-employeesHeader">
    <tr>
      {employeesTable.headers
        .filter(header => visibleColumns.map(column => column.id).includes(header.id) || header.cannotBeHidden)
        .map(header => {
          const sortable = Object.keys(employeesTable.sortingKeys).includes(header.key);
          const restrictions = employeesTable.restrictions[header.key];
          const permissions = employeesTable.permissions[header.key];

          const headerCell = (
            <th
              onClick={() => {
                if (sortable) {
                  sortFunc(header.key, 'employees');
                }
              }}
              className={classnames('k-employeesHeader__headerCell', {
                'k-employeesHeader__headerCell--sortable': sortable,
              })}
              key={header.key}
              data-test={`${header.key}TableHeader`}
            >
              <FormattedMessage {...header} />
              {sortable && <SortingArrow display={uiData.sortColumn === header.key} up={!uiData.sortDecreesing} />}
            </th>
          );
          return (
            <FeatureWrapper restriction={(restrictions || [])[0]} permission={(permissions || [])[0]} key={header.key}>
              {headerCell}
            </FeatureWrapper>
          );
        })}
    </tr>
  </thead>
);

EmployeesHeader.propTypes = {
  sortFunc: PropTypes.func.isRequired,
  uiData: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDecreesing: PropTypes.bool,
  }),
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
};

export default EmployeesHeader;
