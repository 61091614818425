import { connect } from 'react-redux';

import { massEditEmployees } from '@/actions/companymanage';
import { hideModal } from '@/actions/uiState';
import { EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL } from '@/constants/modalTypes.js';

import AdditionalOptionsModal from './AdditionalOptionsModal.jsx';

const mapStateToProps = ({ reducer }) => ({
  modalObject: reducer.uiState.modalObject[EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL],
});

const mapDispatchToProps = {
  massEditEmployees,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalOptionsModal);
