import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { trackIntercomEvent } from '@/actions/intercom';
import * as AT from '@/constants/ActionTypes.js';
import { ADD_DEVICES_SUCCESFUL } from '@/constants/ActionTypes.js';

import { conn, showConfirmModal } from '../index';

const devicesMessages = defineMessages({
  confirmModalTitle: {
    id: 'devices.confirmModal.title',
    defaultMessage: 'Usuń urządzenie',
  },
  confirmModalDescription: {
    id: 'devices.confirmModal.description',
    defaultMessage: 'Czy na pewno chcesz usunąć urządzenie {name}?',
  },
  confirmModalButtonText: {
    id: 'devices.confirmModal.buttonText',
    defaultMessage: 'Usuń',
  },
  massConfirmModalTitle: {
    id: 'devices.massConfirmModal.title',
    defaultMessage: 'Usuń urządzenia',
  },
  massConfirmModalDescription: {
    id: 'devices.massConfirmModal.description',
    defaultMessage:
      'Czy na pewno chcesz usunąć {length, plural, =2 {# urządzenia} =3 {# urządzenia} =4 {# urządzenia} one {# urządzenie} other {# urządzeń}} ?',
  },
  toastrDeleteSuccess: {
    id: 'devices.toastr.deleteSuccess',
    defaultMessage:
      'Pomyślnie usunięto {length, plural, =2 {# urządzenia} =3 {# urządzenia} =4 {# urządzenia} one {# urządzenie} other {# urządzeń}}!',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Błąd',
  },
  toastrAddSuccess: {
    id: 'devices.toastr.addSuccess',
    defaultMessage: 'Dodano urządzenie {name}',
  },
  toastrAddError: {
    id: 'devices.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas dodawania urządzenia',
  },
  toastrUpdateSuccess: {
    id: 'devices.toastr.updateSuccess',
    defaultMessage: 'Pomyślnie edytowano urządzenie',
  },
  toastrUpdateError: {
    id: 'devices.toastr.updateError',
    defaultMessage: 'Wystąpił błąd podczas edytowania urządzenia',
  },
  toastrDeleteError: {
    id: 'devices.toastr.deleteError',
    defaultMessage: 'Wystąpił błąd podczas usuwania',
  },
});

export const getDevices = companyId => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getDevices(companyId)
      .then(result => {
        dispatch({ type: AT.GET_DEVICES_SUCCESFUL, payload: result.data });
        resolve();
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });

export const addDevice = (authCode, name) => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .addDevice(authCode, name)
      .then(result => {
        dispatch({ type: AT.ADD_DEVICES_SUCCESFUL, payload: result.data });
        dispatch(trackIntercomEvent(ADD_DEVICES_SUCCESFUL));
        toastr.success(intl.formatMessage(devicesMessages.toastrAddSuccess, { name }));
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(devicesMessages.error, {}),
          intl.formatMessage(devicesMessages.toastrAddError, {}),
        );
        console.error(err);
        reject(err);
      });
  });

export const saveSettings = (settings, uuid) => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .saveSettingsDevice(uuid, { ...settings })
      .then(result => {
        dispatch({ type: AT.SAVE_SETTINGS_DEVICES_SUCCESFUL, payload: result.data });
        toastr.success(intl.formatMessage(devicesMessages.toastrUpdateSuccess, {}));
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(devicesMessages.error, {}),
          intl.formatMessage(devicesMessages.toastrUpdateError, {}),
        );
        console.error(err);
        reject(err);
      });
  });

export const deleteDevices = uuids => (dispatch, getState, intl) => {
  new Promise((resolve, reject) => {
    conn
      .deleteDevice(uuids)
      .then(result => {
        dispatch({ type: AT.DELETE_DEVICES_SUCCESFUL, payload: uuids });
        toastr.success(intl.formatMessage(devicesMessages.toastrDeleteSuccess, { length: uuids.length }));
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(devicesMessages.error, {}),
          intl.formatMessage(devicesMessages.toastrDeleteError, { length: uuids.length }),
        );
        console.error(err);
        reject(err);
      });
  });
};

export const deleteDeviceConfirm = device => (dispatch, getState, intl) => {
  const title = intl.formatMessage(devicesMessages.confirmModalTitle, {});
  const description = intl.formatMessage(devicesMessages.confirmModalDescription, {
    name: device.name,
  });
  const confirmText = intl.formatMessage(devicesMessages.confirmModalButtonText, {});
  const confirmFunc = () => {
    dispatch(deleteDevices([device.uuid]));
  };
  const confirmType = 'warning';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
      showDeleteInput: true,
    }),
  );
};

export const massDeleteDevicesConfirm = () => (dispatch, getState, intl) => {
  const { selected } = getState().reducer.listsUi.devices;
  const title = intl.formatMessage(devicesMessages.massConfirmModalTitle, {});
  const description = intl.formatMessage(devicesMessages.massConfirmModalDescription, {
    length: selected.length,
  });
  const confirmText = intl.formatMessage(devicesMessages.confirmModalButtonText, {});
  const confirmFunc = () => {
    dispatch(deleteDevices(selected));
  };
  const confirmType = 'warning';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
      showDeleteInput: true,
    }),
  );
};

export const checkDevice = authCode => () => conn.checkDevice(authCode);
