import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  label: {
    id: 'employeesEditMassContractsField.date',
    defaultMessage: 'Data obowiązywania kontraktu',
  },
  tooltip: {
    id: 'employeesEditMassContractsField.tooltip',
    defaultMessage: 'Wybierz datę, która zawiera się w okresie obowiązywania kontraktów',
  },
});
