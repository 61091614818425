import { connect } from 'react-redux';

import { enableLoadButton } from '@/actions/payroll';

import PayrollLocationTopBar from './PayrollLocationTopBar';

const mapDispatchToProps = {
  enableLoadButton,
};

export default connect(null, mapDispatchToProps)(PayrollLocationTopBar);
