import { connect } from 'react-redux';

import AttendancesUnavailabilityWarningContent from './AttendancesUnavailabilityWarningContent';

const mapStateToProps = ({ reducer }) => ({
  customAvaTypes: reducer.userCustomTypes,
  absenceTypes: reducer.absences.absencesTypes,
});

export default connect(mapStateToProps)(AttendancesUnavailabilityWarningContent);
