import { getPayrollViewData } from '@/actions/payroll/payroll';
import { decreaseLoaderCounter, increaseLoaderCounter } from '@/actions/uiState';
import * as AT from '@/constants/ActionTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { extractDatesRange } from './payrollUpdates.helpers';

const relevantActionTypes = [
  AT.ADD_SHIFT_FOR_PAYROLL_SUCCESS,
  AT.ADD_ATTENDANCE_SUCCESFUL,
  AT.ADD_ABSENCE_SUCCESS,
  AT.ADD_OVERTIME_COLLECTION_SUCCESS,
  AT.EDIT_OVERTIME_COLLECTION_SUCCESS,
  AT.DELETE_OVERTIME_COLLECTIONS_SUCCESS,
  AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS,
  AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS,
  AT.DELETE_ABSENCE_SUCCESS,
  AT.DELETE_SHIFT_FOR_PAYROLL_SUCCESS,
  AT.DELETE_ATTENDANCE_SUCCESFUL,
  AT.ADD_ATTENDANCES_OVERTIME_SUCCESS,
  AT.DELETE_BREAK_SUCCESFUL,
  AT.ADD_BREAK_SUCCESFUL,
  AT.CHANGE_BREAK_SUCCESFUL,
  AT.ACCEPT_ABSENCE_SUCCESS,
  AT.REJECT_ABSENCE_SUCCESS,
  AT.ADD_ATTENDANCE_BONUS_SUCCESFUL,
  AT.PAYROLL_CHANGE_MODE,
  AT.ASSIGN_LABEL_TO_ATTENDANCE,
  AT.UNASSIGN_LABEL_FROM_ATTENDANCE,
];

const NOT_BLOCKING_ACTION_TYPES = [
  AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS,
  AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS,
  AT.ADD_ATTENDANCES_OVERTIME_SUCCESS,
  AT.DELETE_BREAK_SUCCESFUL,
  AT.ADD_BREAK_SUCCESFUL,
  AT.CHANGE_BREAK_SUCCESFUL,
  AT.ACCEPT_ABSENCE_SUCCESS,
  AT.ADD_ATTENDANCE_BONUS_SUCCESFUL,
  AT.PAYROLL_CHANGE_MODE,
  AT.ASSIGN_LABEL_TO_ATTENDANCE,
  AT.UNASSIGN_LABEL_FROM_ATTENDANCE,
];

const NOT_RELEVANT_ACTION_PAYLOADS = [PAYROLL_MODES.editable];

const NOT_MERGING_ACTION_TYPES = [AT.PAYROLL_CHANGE_MODE];

let getPayrollTimeout;

const payrollUpdatesMiddleware = store => next => action => {
  const returnState = next(action);
  const currentRoute = window.location.pathname;

  if (!currentRoute.startsWith('/newPayroll')) {
    return returnState;
  }
  if (!relevantActionTypes.includes(action.type) || NOT_RELEVANT_ACTION_PAYLOADS.includes(action.payload)) {
    return returnState;
  }
  const result = extractDatesRange(action);
  const requestType = NOT_BLOCKING_ACTION_TYPES.includes(action.type) ? 'regular' : 'blocking';
  if (getPayrollTimeout) {
    clearTimeout(getPayrollTimeout);
    getPayrollTimeout = null;
  } else if (requestType === 'blocking') {
    store.dispatch(increaseLoaderCounter(requestType));
  }

  getPayrollTimeout = setTimeout(() => {
    const { mainDateStore } = store.getState().reducer;
    const [fromParam, toParam] =
      result?.from && result?.to ? [result.from, result.to] : getFromToFromDateStore(mainDateStore);
    store.dispatch(
      getPayrollViewData(
        fromParam,
        toParam,
        { mergeWithOldData: !NOT_MERGING_ACTION_TYPES.includes(action.type) },
        requestType,
      ),
    );
    if (requestType === 'blocking') {
      store.dispatch(decreaseLoaderCounter(requestType));
    }
    getPayrollTimeout = null;
  }, 1000);

  return returnState;
};
export default payrollUpdatesMiddleware;
