import { didShiftStarted } from '@/utils/dateHelper';
import { availableEmployeesForShift } from '@/utils/userEmployeesHelpers';

export const getInputsInitialState = () => ({
  comment: '',
  errors: {},
});

const mapEmployeesForOptions = (modalObject, userEmployees, contracts) => {
  const { shift } = modalObject;
  const availableEmployees = availableEmployeesForShift(
    userEmployees,
    shift.date,
    shift,
    shift.location.id,
    shift.job_title.id,
    contracts,
    false,
  );
  return availableEmployees.map(({ id, first_name: firstName, last_name: lastName }) => ({
    value: id,
    sortValue: firstName + lastName,
    label: `${firstName} ${lastName}`,
    active: false,
  }));
};

export const getEmployeesOptions = (modalObject, userEmployees, contracts) =>
  mapEmployeesForOptions(modalObject, userEmployees, contracts);

export const getSelectedEmployeeIds = multiSelectHandlers => {
  const selectedEmployees = multiSelectHandlers.getSelected();
  return selectedEmployees.map(e => e.value);
};

export const formatNewTradeShift = (userIds, comment, modalObject) => ({
  user_ids: userIds,
  comment,
  shift: {
    id: modalObject.shift.id,
  },
});

export const getPreselectedEmployees = indicatedCoworkers => {
  if (!indicatedCoworkers) return [];
  return indicatedCoworkers.map(coworker => coworker.user.id);
};

export const determineNewTradeShiftError = (newTradeShift, tradeShifts, modalObject) => {
  if (didShiftStarted(modalObject.shift)) return 'shiftStarted';

  const tradeShiftIsAlreadyRejected = tradeShifts.some(
    tradeShift => tradeShift.shift.id === newTradeShift.shift.id && tradeShift.status === 'rejected',
  );
  if (tradeShiftIsAlreadyRejected) return 'shiftRejected';

  const tradeShiftAlreadyCreated = tradeShifts.some(
    tradeShift => tradeShift.shift.id === newTradeShift.shift.id && tradeShift.status !== 'rejected',
  );
  if (tradeShiftAlreadyCreated) return 'shiftCreated';
  return null;
};
