import PropTypes from 'prop-types';

import EmployeeModalPropList from './EmployeeModalPropList/EmployeeModalPropList.jsx';

import './employeeModalLocations.scss';

const EmployeeModalLocations = props => (
  <div className="employeeModal__locations">
    <EmployeeModalPropList
      locations={props.userLocations}
      editLocations={props.locations}
      supplementaryLocations={props.supplementaryLocations}
      showSupplementaryLocations={props.showSupplementaryLocations}
      manager={props.isManager}
      onChangeFunc={props.handleInputChange}
    />
  </div>
);

EmployeeModalLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  supplementaryLocations: PropTypes.arrayOf(PropTypes.shape({})),
  showSupplementaryLocations: PropTypes.bool,
  isManager: PropTypes.bool,
  handleInputChange: PropTypes.func,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EmployeeModalLocations;
