import { connect } from 'react-redux';

import { onColumnClick } from '@/actions/schedule/selectedCells';

import ScheduleTableHeader from './ScheduleTableHeader.jsx';

const mapStateToProps = (state, ownProps) => ({
  holidays: state.reducer.calendarData.holidays,
  mainDateStore: state.reducer.mainDateStore,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode,
  holidaysEnabled: state.reducer.schedule.viewSettings?.holidays?.value,
  managerNotes: state.reducer.managerNotes[ownProps.locationId],
  singleDay: state.reducer.mainDateStore.dateArray.length === 1,
});

const mapDispatchToProps = { onColumnClick };

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableHeader);
