import { PropTypes } from 'prop-types';

const LeftHeader = props => {
  const flatChildren = Array.isArray(props.children) ? props.children.flat() : [props.children];
  const header = flatChildren[0];
  const additionalComponents = flatChildren.slice(1);

  return (
    <div
      className={`kadroGrid__leftHeader ${props.className || ''}`}
      style={{ ...props.style, ...props.extraStyle }}
      ref={props.setRef}
      data-test={props.testId && `${props.testId}-leftHeader`}
    >
      <table>
        <thead>
          {props.showGraph && (
            <tr className="empty">
              <th />
            </tr>
          )}
          <tr>{header}</tr>
          {props.multiRows && additionalComponents}
        </thead>
      </table>
    </div>
  );
};

LeftHeader.propTypes = {
  style: PropTypes.shape({}),
  extraStyle: PropTypes.shape({}),
  className: PropTypes.string,
  setRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showGraph: PropTypes.bool,
  multiRows: PropTypes.bool,
  testId: PropTypes.string,
};
export default LeftHeader;
