import { defineMessages } from 'react-intl';

import * as AT from '@/constants/ActionTypes';
import { checkBlocksOverlap } from '@/utils/baseHelpers';
import { getRelevantContractForDate } from '@/utils/contracts';
import { checkIfEmployeeHasJobTitle } from '@/utils/shiftHelpers';

import { addAvailability, addShift, deleteAvailability, deleteShiftRequest, swapShifts } from '../index';
import { changeOpenShift, createOpenShift, deleteOpenShift } from '../openShifts';

const messages = defineMessages({
  failToast: {
    id: 'toastr.errorTitle',
    defaultMessage: 'Błąd!',
  },
  noCommonJobTitles: {
    id: 'dragAndDrop.noCommonJobTitles',
    defaultMessage: 'Wymiana zmian jest możliwa jedynie między pracownikami posiadającymi te same stanowiska',
  },
  swapError: {
    id: 'dragAndDrop.swapError',
    defaultMessage: 'Wystąpił błąd podczas wymiany zmian',
  },
});

export const startShiftDrag = (shiftId, employeeId, from, to, date) => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const blockedCells = {};
  userEmployees.forEach(employee => {
    employee.shifts.forEach(shift => {
      const [shiftFrom, shiftTo] = shift.working_hours.split('-');
      if (checkBlocksOverlap(from, to, shiftFrom, shiftTo)) {
        if (blockedCells[employee.id]) {
          blockedCells[employee.id][shift.date] = true;
        } else {
          blockedCells[employee.id] = { [shift.date]: true };
        }
      }
    });
  });
  dispatch({ type: AT.START_SHIFT_DRAG, payload: { blockedCells, shiftId, employeeId, date } });
};

export const startAvailabilityDrag = availabilityType => ({
  type: AT.START_AVAILABILITY_DRAG,
  payload: { availabilityType },
});

export const startOpenShiftDrag = (openShiftId, from, to) => (dispatch, getState) => {
  const { userEmployees, schedule, mainDateStore, contracts } = getState().reducer;
  const { openShifts } = schedule;
  const { dateArray } = mainDateStore;
  const draggedOpenshift = openShifts.data[openShiftId];
  if (!draggedOpenshift) return;
  const { date } = draggedOpenshift;
  const openShiftJobTitleId = draggedOpenshift.job_title.id.toString();

  const blockedCells = {};
  // Block employees without given job title
  const blockedEmployees = userEmployees.filter(employee => {
    const relevantContract = getRelevantContractForDate(contracts[employee.id] || [], date);
    const employeeJobTitleIds = relevantContract?.job_titles.map(jobTitle => jobTitle.job_title_id);
    const employeeLocationsIds = employee.locations.map(({ id }) => id);
    return (
      !employeeJobTitleIds?.includes(openShiftJobTitleId) ||
      !employeeLocationsIds.includes(draggedOpenshift.location.id)
    );
  });

  blockedEmployees.forEach(employee => {
    dateArray.forEach(d => {
      if (blockedCells[employee.id]) {
        blockedCells[employee.id][d] = true;
      } else {
        blockedCells[employee.id] = { [d]: true };
      }
    });
  });
  // block all days except this date
  userEmployees.forEach(employee => {
    dateArray.forEach(d => {
      if (d !== date) {
        if (blockedCells[employee.id]) {
          blockedCells[employee.id][d] = true;
        } else {
          blockedCells[employee.id] = { [d]: true };
        }
      }
    });
  });
  // block employees with overlapping shift
  userEmployees.forEach(employee => {
    employee.shifts.forEach(shift => {
      const [shiftFrom, shiftTo] = shift.working_hours.split('-');
      if (date !== shift.date || checkBlocksOverlap(from, to, shiftFrom, shiftTo)) {
        if (blockedCells[employee.id]) {
          blockedCells[employee.id][shift.date] = true;
        } else {
          blockedCells[employee.id] = { [shift.date]: true };
        }
      }
    });
  });
  dispatch({ type: AT.START_OPEN_SHIFT_DRAG, payload: { blockedCells, openShiftId } });
};

export const stopShiftDrag = () => ({ type: AT.STOP_SHIFT_DRAG });
export const stopOpenShiftDrag = () => ({ type: AT.STOP_OPEN_SHIFT_DRAG });
export const stopAvailabilityDrag = () => ({ type: AT.STOP_AVAILABILITY_DRAG });

export const draggedShiftToOpenShift = (date, locationId) => (dispatch, getState) => {
  const { shifts, schedule } = getState().reducer;
  const { openShifts, dragAndDrop } = schedule;
  const { draggedShiftId: originalShiftId, originEmployeeId } = dragAndDrop;
  const originalShift = shifts.data[originEmployeeId]?.shifts[originalShiftId];
  if (!originalShift) return;

  const openShiftIdsForDate = openShifts.structure?.[locationId]?.[date] || [];
  const openShiftsForDate = openShiftIdsForDate.map(id => openShifts.data[id]).filter(shift => !!shift);
  const shiftToIncreaseCountOf = openShiftsForDate.find(
    shift => shift.working_hours === originalShift.working_hours && shift.job_title.id === originalShift.job_title.id,
  );
  let actionPromise;
  if (shiftToIncreaseCountOf) {
    actionPromise = dispatch(
      changeOpenShift(shiftToIncreaseCountOf.id, {
        ...shiftToIncreaseCountOf,
        shifts_required: shiftToIncreaseCountOf.shifts_required + 1,
        shifts_remaining: shiftToIncreaseCountOf.shifts_remaining + 1,
      }),
    );
  } else {
    const { working_hours: workingHours, job_title: jobTitle, location } = originalShift;
    actionPromise = dispatch(
      createOpenShift({
        comment: '',
        working_hours: workingHours,
        date,
        job_title: jobTitle,
        location,
        draft: true,
        shifts_required: 1,
      }),
    );
  }
  actionPromise.then(() => {
    dispatch(deleteShiftRequest(originEmployeeId, originalShiftId, false));
  });
};

export const assignOpenShiftToEmployee = employeeId => (dispatch, getState) => {
  const { dragAndDrop, openShifts } = getState().reducer.schedule;
  const { draggedOpenShiftId } = dragAndDrop;
  const openShift = openShifts.data[draggedOpenShiftId];
  if (!draggedOpenShiftId || !openShift) return;
  const { working_hours: workingHours, job_title: jobTitle, date, location } = openShift;
  const newShift = {
    comment: '',
    working_hours: workingHours,
    location,
    job_title: jobTitle,
    draft: true,
    employee: { id: employeeId },
    date,
  };
  dispatch(addShift({ id: employeeId }, newShift, false)).then(() => {
    if (openShift.shifts_remaining - 1 === 0) {
      dispatch(deleteOpenShift(openShift.id));
    } else {
      dispatch(
        changeOpenShift(openShift.id, {
          ...openShift,
          shifts_remaining: openShift.shifts_remaining - 1,
        }),
      );
    }
  });
};

export const pasteDraggedShift = (employeeId, date, keepOriginalShift) => (dispatch, getState) => {
  const { shifts, schedule, employees, contracts } = getState().reducer;
  const { draggedShiftId: originalShiftId, originEmployeeId } = schedule.dragAndDrop;
  const originalShift = shifts.data[originEmployeeId]?.shifts[originalShiftId];
  const { comment, working_hours: workingHours, location, job_title: originalJobTitle } = originalShift;
  const targetEmployee = employees.data[employeeId];
  const targetEmployeeContracts = contracts[employeeId] || [];
  const relevantTargetEmployeeContract = getRelevantContractForDate(targetEmployeeContracts, date);
  if (!targetEmployee || !relevantTargetEmployeeContract) return;
  const employeeHasGivenJobTitle = relevantTargetEmployeeContract.job_titles.some(
    contractJobTitle => contractJobTitle.job_title_id === originalJobTitle.id.toString(),
  );
  const jobTitle = employeeHasGivenJobTitle
    ? originalJobTitle
    : { id: relevantTargetEmployeeContract.job_titles[0].job_title_id };
  const newShift = {
    comment,
    working_hours: workingHours,
    location,
    job_title: jobTitle,
    draft: true,
    employee: { id: employeeId },
    date,
  };
  dispatch(addShift(targetEmployee, newShift)).then(() => {
    if (!keepOriginalShift) {
      dispatch(deleteShiftRequest(originEmployeeId, originalShiftId, false));
    }
  });
};

const pasteDraggedAvailability =
  (availabilityType, employeeId, date, keepOriginalAvailability) => (dispatch, getState) => {
    const { userCustomTypes } = getState().reducer;
    const relevantType = userCustomTypes.find(type => type.id === availabilityType.type_id);

    dispatch(
      addAvailability({
        type_id: availabilityType.type_id,
        type: availabilityType.type,
        date,
        employee: { id: employeeId },
        hours: availabilityType.hours,
        draft: relevantType?.requires_approval || false,
      }),
    );

    if (!keepOriginalAvailability) {
      dispatch(deleteAvailability(availabilityType.employee_id, availabilityType));
    }
  };

const handleShiftSwap = (targetShiftId, targetEmployeeId) => async (dispatch, getState, intl) => {
  const { shifts, schedule, employees, contracts } = getState().reducer;
  const { draggedShiftId: originalShiftId, originEmployeeId } = schedule.dragAndDrop;
  if (targetShiftId === originalShiftId) return;
  const originShift = shifts.data[originEmployeeId].shifts[originalShiftId];
  const { job_title: originJobTitle } = originShift;
  const targetEmployee = employees.data[targetEmployeeId];
  const targetShift = shifts.data[targetEmployeeId].shifts[targetShiftId];
  const originShiftDate = originShift.date;
  const targetShiftDate = targetShift.date;
  const originEmployeeContract = getRelevantContractForDate(contracts[originEmployeeId] || [], originShiftDate);
  const targetEmployeeContract = getRelevantContractForDate(contracts[targetEmployeeId] || [], targetShiftDate);
  if (!targetEmployee || !targetEmployeeContract) return;
  const { job_title: targetJobTitle } = targetShift;

  const employeesHaveSameJobTitles =
    checkIfEmployeeHasJobTitle(originJobTitle, originEmployeeContract) &&
    checkIfEmployeeHasJobTitle(targetJobTitle, targetEmployeeContract);

  if (!employeesHaveSameJobTitles) {
    dispatch({
      type: AT.NO_COMMON_JOB_TITLES,
      notification: {
        title: intl.formatMessage(messages.failToast),
        description: intl.formatMessage(messages.noCommonJobTitles),
        type: 'error',
      },
    });
  } else {
    try {
      dispatch(swapShifts(originShift, targetShift));
    } catch (e) {
      dispatch({
        type: AT.SWAP_SHIFTS_FAILURE,
        notification: {
          title: intl.formatMessage(messages.failToast),
          description: intl.formatMessage(messages.swapError),
          type: 'error',
        },
      });
    }
  }
};

export const handleDrop = (employeeId, date, altPressed) => (dispatch, getState) => {
  const { dragAndDrop } = getState().reducer.schedule;
  switch (dragAndDrop.itemType) {
    case 'shift':
      dispatch(pasteDraggedShift(employeeId, date, altPressed));
      break;
    case 'openShift':
      dispatch(assignOpenShiftToEmployee(employeeId));
      break;
    case 'availability':
      dispatch(pasteDraggedAvailability(dragAndDrop.draggedAvailability, employeeId, date, altPressed));
      break;
    default:
      break;
  }
};

export const startSwapping = () => ({
  type: AT.START_SWAPPING,
});
export const stopSwapping = () => ({
  type: AT.STOP_SWAPPING,
});

export const handleSwapShiftDrop = (shiftId, employeeId, swapButtonPressed) => (dispatch, getState) => {
  const { dragAndDrop } = getState().reducer.schedule;
  if (dragAndDrop.itemType === 'shift' && swapButtonPressed) {
    dispatch(stopSwapping());
    dispatch(handleShiftSwap(shiftId, employeeId));
  }
};
