import { connect } from 'react-redux';

import { WholeDayAbsenceOptions } from './WholeDayAbsenceOptions';

const mapStateToProps = state => ({
  countOnlyDaysWithShiftsSelectedByDefault:
    state.reducer.currentCompany.settings.count_only_days_with_shifts_selected_by_default,
  restrictions: state.reducer.userPermissions.restrictions,
});

export default connect(mapStateToProps)(WholeDayAbsenceOptions);
