import PropTypes from 'prop-types';

import AppBlocked from '@/components/UnlockAccountView/AppBlocked/AppBlocked';
import browserHistory from '@/constants/browserHistory';
import { intercomShowNewMessage } from '@/utils/intercomHelpers';

import { messages } from './invoiceStatusView.messages';

const InvoiceStatusView = ({ location }) => {
  const {
    invoicePaidViewTitle,
    invoicePaidViewContent,
    invoiceNotFoundViewTitle,
    invoiceNotFoundViewContent,
    invoiceStatusViewButton: buttonText,
  } = messages;

  const {
    query: { status },
  } = location;

  const handleLoginViewRedirect = () => {
    console.log('handleLoginViewRedirect reload');
    browserHistory.push('/');
  };

  const handleIntercomShow = () => {
    intercomShowNewMessage();
  };

  const showInvoiceStatusMessage = () => {
    if (status === 'paid')
      return {
        titleMessage: invoicePaidViewTitle,
        contentMessage: invoicePaidViewContent,
      };
    return {
      titleMessage: invoiceNotFoundViewTitle,
      contentMessage: invoiceNotFoundViewContent,
    };
  };

  const { titleMessage, contentMessage } = showInvoiceStatusMessage();

  return (
    <AppBlocked
      titleMessage={titleMessage}
      contentMessage={contentMessage}
      buttonText={buttonText}
      alt="kalendarz"
      imgSrc="img/scheduleCTA.png"
      logoutUser={handleLoginViewRedirect}
      handleButtonClick={handleIntercomShow}
    />
  );
};

InvoiceStatusView.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({ status: PropTypes.string }),
  }),
};

export default InvoiceStatusView;
