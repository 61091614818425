import classnames from 'classnames';

import { hoursToMinutes, minutesToHour } from '@/utils/dateHelper';

export const getNewStartForShiftResizing = (from, end, clientX, currentX) => {
  const newStart = minutesToHour(hoursToMinutes(from) + clientX - currentX, 5, 'start');
  const refStart = minutesToHour(hoursToMinutes(from) + clientX - currentX + 10, 5, 'start');
  return refStart < end ? newStart : from;
};

export const getNewEndForShiftResizing = (start, to, clientX, currentX) => {
  const newEnd = minutesToHour(hoursToMinutes(to) + clientX - currentX, 5, 'end');
  const refEnd = minutesToHour(hoursToMinutes(to) + clientX - currentX - 10, 5, 'end');
  return refEnd > start ? newEnd : to;
};

export const getNewStartAndEndForShiftMoving = (from, to, clientX, currentX) => {
  const newStart = minutesToHour(hoursToMinutes(from) + clientX - currentX, 5, 'start');
  const newEnd = minutesToHour(hoursToMinutes(to) + clientX - currentX, 5, 'end');
  return { newStart, newEnd };
};

export const getClassNames = (
  draft,
  otherLocation,
  isEditingScheduleDisabled,
  isMoved,
  fullOpacity,
  isInvalid,
  shouldConnectWithAbsence,
  isAbsenceDraft,
) => {
  const className = classnames('k-shiftBlock', {
    'k-shiftBlock--draft': draft,
    'k-shiftBlock--dragged': isMoved,
    'k-shiftBlock--locked': otherLocation,
    'k-scheduleBlock--invalid': isInvalid,
    'k-shiftBlock--notEditable': isEditingScheduleDisabled,
    moveHorizontal: !otherLocation && !isEditingScheduleDisabled && !isInvalid,
    'k-shiftBlock--fullOpacity': fullOpacity,
    'k-shiftBlock--connectWithAbsence': shouldConnectWithAbsence,
    'k-shiftBlock--absenceDraft': isAbsenceDraft,
  });
  const textClassName = classnames('k-shiftBlock__text', {
    'k-shiftBlock__text--notEditable': isEditingScheduleDisabled,
  });
  const leftDragClassName = classnames('k-shiftBlock__leftDrag', {
    blocked: otherLocation || isEditingScheduleDisabled || isInvalid,
  });
  const rightDragClassName = classnames('k-shiftBlock__rightDrag', {
    blocked: otherLocation || isEditingScheduleDisabled || isInvalid,
  });
  return { className, textClassName, leftDragClassName, rightDragClassName };
};

export const getWorkingHoursForNewShift = (from, to, mouseAction, clientX, currentX) => {
  if (mouseAction === 'resizeLeft') {
    return `${getNewStartForShiftResizing(from, to, clientX, currentX)}-${to}`;
  } else if (mouseAction === 'resizeRight') {
    return `${from}-${getNewEndForShiftResizing(from, to, clientX, currentX)}`;
  } else if (mouseAction === 'blockMove') {
    const { newStart, newEnd } = getNewStartAndEndForShiftMoving(from, to, clientX, currentX);
    return `${newStart}-${newEnd}`;
  }
};
