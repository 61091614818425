import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Component } from 'react';

import NewsModal from './NewsModal.jsx';

class NewsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalStartStep: 0,
      regulationsDate: null,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (!this.props.currentCompany.regulations_accepted)
      this.props.getRegulations().then(data => {
        this.regulations = data;
        this.setState({ regulationsDate: data.published_at.split('T')[0], showModal: true });
      });
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal, modalStartStep: 1 });
  }

  render() {
    if (
      !this.props.currentUser.authenticated ||
      this.props.currentUser.user.role !== 'owner' ||
      !this.state.regulationsDate ||
      !this.props.currentCompany ||
      this.props.currentCompany.regulations_accepted
    )
      return null;
    return (
      <div>
        <NewsModal
          showModal={this.state.showModal}
          currentCompany={this.props.currentCompany}
          onHide={this.toggleModal}
          startStep={this.state.modalStartStep}
          confirmRegulations={this.props.confirmRegulations}
          regulations={this.regulations}
        />
        <div className="k-notificationBar k-notificationBar--orange clickable" onClick={this.toggleModal}>
          <div className="k-notificationBar__message">
            Pozostało {14 - moment().diff(moment(this.state.regulationsDate), 'day')} dni do zaakceptowania nowego
            regulaminu serwisu Kadromierz. Kliknij tutaj, aby zapoznać się i zaakcpetować regulamin.
          </div>
        </div>
        <div className="k-notificationBar__margin" />
      </div>
    );
  }
}

NewsBar.propTypes = {
  currentCompany: PropTypes.shape({
    id: PropTypes.string,
    created_at_timestamp: PropTypes.string,
    regulations_accepted: PropTypes.bool,
  }),
  getRegulations: PropTypes.func,
};

export default NewsBar;
