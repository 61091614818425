import {
  ADD_MASS_USER_EVENT_SUCCESFUL,
  ADD_USER_EVENT_SUCCESFUL,
  CHANGE_USER_EVENT_SUCCESFUL,
  DELETE_USER_EVENT_SUCCESFUL,
  GET_SCHEDULE_EVENTS_SUCCESFUL,
} from '@/constants/ActionTypes.js';
import {
  addEventToStructure,
  addMassEventToStructure,
  changeEventAndSort,
  mapEventsToStructure,
  removeEvent,
} from '@/reducers/schedule/events.helpers';

const initialState = {
  data: {},
  structure: {},
};

const Events = (state = initialState, action) => {
  const { structure, data } = state;
  const { payload, type } = action;
  switch (type) {
    case GET_SCHEDULE_EVENTS_SUCCESFUL:
      return mapEventsToStructure(structure, data, payload);
    case ADD_USER_EVENT_SUCCESFUL:
      return addEventToStructure(structure, data, payload);
    case CHANGE_USER_EVENT_SUCCESFUL:
      return changeEventAndSort(structure, data, payload);
    case DELETE_USER_EVENT_SUCCESFUL:
      return removeEvent(structure, data, payload);
    case ADD_MASS_USER_EVENT_SUCCESFUL:
      return addMassEventToStructure(structure, data, payload);
    default:
      return state;
  }
};

export default Events;
