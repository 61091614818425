import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import AttendanceBarBlockLabel from '@/components/attendance/AttendanceBarBlockLabel.tsx';
import Popover from '@/components/common/Popover/Popover.jsx';
import Tag from '@/components/common/Tag/Tag';
import * as attendanceTypes from '@/constants/attendanceDetailTypes.js';
import { parseMinutesToHumanForm } from '@/utils/dateHelper';
import { showLabelsFeature } from '@/utils/labels';

const popupMessages = defineMessages({
  lateStart: {
    id: 'attendance.lateStart',
    defaultMessage: 'Spóźnienie',
  },
  earlyQuit: {
    id: 'attendance.earlyQuit',
    defaultMessage: 'Wczesne wyjście',
  },
  earlyStart: {
    id: 'attendance.earlyStart',
    defaultMessage: 'Wczesne wejście',
  },
  lateQuit: {
    id: 'attendance.lateQuit',
    defaultMessage: 'Wyjście po czasie',
  },
  absence: {
    id: 'attendance.absence',
    defaultMessage: 'Nieobecność',
  },
  break: {
    id: 'attendance.break',
    defaultMessage: 'Przerwa',
  },
  noShift: {
    id: 'attendance.noShift',
    defaultMessage: 'Brak zmiany',
  },
});

const infoTypes = {
  [attendanceTypes.ATTENDANCE_LATE_START]: { title: popupMessages.lateStart },
  [attendanceTypes.ATTENDANCE_EARLY_QUIT]: { title: popupMessages.earlyQuit },
  [attendanceTypes.ATTENDANCE_EARLY_START]: { title: popupMessages.earlyStart },
  [attendanceTypes.ATTENDANCE_LATE_QUIT]: { title: popupMessages.lateQuit },
  [attendanceTypes.ATTENDANCE_ABSENCE]: { title: popupMessages.absence },
  [attendanceTypes.ATTENDANCE_WITHOUT_SHIFT]: { title: popupMessages.noShift },
  break: { title: popupMessages.break },
};

const AttendanceBarBlock = (props, context) => {
  const { block, disabled, editDisabled, permissions, role } = props;
  if (block.shouldBeHidden) return null;
  const className = classnames('k-attendanceBar__block', {
    'k-attendanceBar__block--active': block.animate,
    'k-attendanceBar__block--disabled': disabled || editDisabled,
  });
  const showLabels = showLabelsFeature(role, permissions);
  let bubble = null;
  if (block.length <= 15) {
    bubble = (
      <div className="k-attendanceBar__blockBubble" style={{ backgroundColor: block.type.color }}>
        <div className="k-attendanceBar__blockBubbleArrow" style={{ borderTopColor: block.type.color }} />
      </div>
    );
  }
  const labelsNode = useMemo(() => {
    if (!block.labels || !showLabels) return null;
    return (
      <div className="k-attendanceBar__blockLabelList">
        {block.labels.map(label => (
          <Tag key={label.id} color={label.color} name={label.label} />
        ))}
      </div>
    );
  }, [block.labels]);

  const info = infoTypes[block.type.name];
  const blockNode = (
    <div
      role="presentation"
      className={className}
      onClick={block.onClick}
      style={{ left: `${block.position}%`, width: `${block.width}%`, backgroundColor: block.type.color }}
    >
      {showLabels && (
        <AttendanceBarBlockLabel
          labelsNode={labelsNode}
          labelsAmount={block.labels?.length}
          startTimestamp={block.startTimestamp}
          hidePopover={Boolean(info)}
        />
      )}
      {bubble}
    </div>
  );

  if (!info) return blockNode;
  return (
    <Popover
      showOnHover
      hideOnClick
      position="top"
      yOffset={0}
      showShadow={false}
      noPointerEvents
      content={
        <div className="k-attendanceBar__blockInfo">
          {`${info ? context.intl.formatMessage(info.title) : null} - ${parseMinutesToHumanForm(block.length, {
            dontDisplayZeroHours: true,
          })}`}
          {showLabels && labelsNode}
        </div>
      }
    >
      {blockNode}
    </Popover>
  );
};

AttendanceBarBlock.propTypes = {
  block: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.shape({})),
    startTimestamp: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
};
AttendanceBarBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default AttendanceBarBlock;
