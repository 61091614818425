const DEFAULT_REASON = 'Nie dotyczy';
const DEFAULT_PARTIAL_ASBSENCE = '';
export const formatAbsencesExportDataAoA = rawData => (dispatch, getState) => {
  const { absencesTypes } = getState().reducer.absences;

  const exportRows = rawData.relevantEmployeeRows.map(row => {
    const { reference_id: referenceId, first_name: firstName, last_name: lastName } = row.employee;
    const employeeInfo = [referenceId, lastName, firstName];

    const absences = row.relevantRowInfo.details
      ?.filter(detail => detail.absence && detail.absence.type_id)
      .reduce((acc, cur) => {
        const { from, to, id, type_id: typeId } = cur.absence;
        const {
          code,
          is_default_uw_absence: isDefaultUwAbsence,
          name,
          reduce_uw_limit: reduceUwLimit,
        } = absencesTypes.find(type => type.id === typeId);

        if (reduceUwLimit && !isDefaultUwAbsence) {
          return {
            ...acc,
            [id]: [...employeeInfo, code, 'Urlop wypoczynkowy', from, to, DEFAULT_REASON, DEFAULT_PARTIAL_ASBSENCE, 1],
          };
        }

        return {
          ...acc,
          [id]: [...employeeInfo, code, name, from, to, DEFAULT_REASON, DEFAULT_PARTIAL_ASBSENCE, 0],
        };
      }, {});

    const absenceInfo = absences ? Object.values(absences) : [];

    return absenceInfo;
  });

  return exportRows.flat();
};

const headers = [
  'Kod',
  'Nazwisko',
  'Imie',
  'Nazwa_do_importu',
  'Nazwa_zrodlowa',
  'Data_od',
  'Data_do',
  'Przyczyna',
  'Nieobecnosc_na_czesc_dnia',
  'Urlop_na_zadanie',
];

export const createComarchXlsAbsencesSheet = formattedAbsences => [headers, ...formattedAbsences];
