import { connect } from 'react-redux';

import { removeAllExportsFromWidget } from '@/actions/exports/exportsHistory/exportsHistory';

import ExportsWidgetButton from './ExportsWidgetButton.jsx';

const mapDispatchToProps = {
  removeAllExportsFromWidget,
};

export default connect(null, mapDispatchToProps)(ExportsWidgetButton);
