import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import Button from '@/components/common/Basic/Button.jsx';
import MDPasswordInput from '@/components/common/inputs/MDPasswordInput/MDPasswordInput.jsx';
import UnauthenticatedLayout from '@/components/misc/UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from '@/components/misc/UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';
import browserHistory from '@/constants/browserHistory';
import { inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  email: {
    id: 'view.login.email',
    defaultMessage: 'E-mail',
  },
  password: {
    id: 'view.login.password',
    defaultMessage: 'Hasło',
  },
  passwordConfirm: {
    id: 'view.login.passwordConfirm',
    defaultMessage: 'Potwierdź hasło',
  },
});

class ResetPasswordSetNew extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      token: '',
      password: '',
      passwordConfirm: '',
      errors: {
        password: '',
        passwordConfirm: '',
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  componentDidMount() {
    const { token } = this.props.params;
    if (token) {
      browserHistory.replace('/password/setNew');
      this.setState({ token });
      this.props.checkPasswordToken(token);
    }
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  validateInput(e) {
    return new Promise(resolve => {
      const { name, value } = e.target;
      const valueToCompare = name === 'passwordConfirm' ? this.state.password : '';
      const error = inputValidation(name, value, { valueToCompare });
      this.setState(
        prev => ({
          ...prev,
          errors: {
            ...prev.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : '',
          },
        }),
        () => {
          resolve();
        },
      );
    });
  }

  async validateAll() {
    const inputs = ['password', 'passwordConfirm'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  async formSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const valid = await this.validateAll();
    if (!valid) return;
    const { token } = this.state;
    try {
      await this.props.setNewPassword(token, this.state.password, this.state.passwordConfirm);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
        <div className="k-loginView__welcomeMessage">
          <FormattedMessage id="resetPassword.sendEmail.welcomeMessage" defaultMessage="Ustaw hasło" />
        </div>
        <form onSubmit={this.formSubmit}>
          <MDPasswordInput
            id="password"
            value={this.state.password}
            label={this.context.intl.formatMessage(messages.password, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
            errorMessage={this.state.errors.password}
          />
          <MDPasswordInput
            id="passwordConfirm"
            value={this.state.passwordConfirm}
            label={this.context.intl.formatMessage(messages.passwordConfirm, {})}
            onChange={this.handleInputChange}
            onBlur={this.validateInput}
            errorMessage={this.state.errors.passwordConfirm}
          />
          <div className="k-password__button">
            <Button onClick={this.formSubmit} modifiers="orange high uppercase">
              <FormattedMessage id="resetPassword.setPassword" defaultMessage="Ustaw hasło" />
            </Button>
          </div>
        </form>
      </UnauthenticatedLayout>
    );
  }
}

ResetPasswordSetNew.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ResetPasswordSetNew.propTypes = {
  setNewPassword: PropTypes.func,
  checkPasswordToken: PropTypes.func,
  params: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export const ResetPasswordSetHoc = props => {
  const params = useParams();
  return <ResetPasswordSetNew {...props} params={params} />;
};

export default ResetPasswordSetHoc;
