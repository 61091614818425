import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { getOptions } from './MDSelectPopoverContent.helpers';
import { messages } from './MDSelectPopoverContent.messages';

import './MDSelectPopoverContent.scss';

const MDSelectPopoverContent = (
  { withSearch, isSorted, options, handleOnChange, defaultValue, width, dropdownStatus, testId },
  { intl },
) => {
  const [filter, setFilter] = useState('');

  const handleSearchOnChange = ({ target }) => {
    setFilter(target.value);
  };
  const dropdownClassNames = classnames('mdSelectPopoverContent__dropdown', {
    'mdSelectPopoverContent__dropdown--active': dropdownStatus,
  });
  return (
    <div style={{ width }} className={dropdownClassNames} data-test={`${testId}-dropdown`}>
      {withSearch && (
        <div className="mdSelectPopoverContent__searchItem">
          <span className="mdSelectPopoverContent__icon">
            <i className="mdSelectPopoverContent__searchIcon material-icons">search</i>
          </span>
          <input
            className="mdSelectPopoverContent__input"
            value={filter}
            type="text"
            onChange={handleSearchOnChange}
            placeholder={intl.formatMessage(messages.selectInputPlaceholder)}
          />
        </div>
      )}
      <OverlayScrollbarsComponent
        className="k-overlay-scrollbar"
        options={{
          overflowBehavior: {
            x: 'hidden',
            y: 'scroll',
          },
        }}
      >
        {getOptions(options, withSearch, isSorted, filter).map(({ value, key }) => {
          const listItemClassNames = classnames('mdSelectPopoverContent__listItem', {
            'mdSelectPopoverContent__listItem--active': defaultValue === value,
          });
          return (
            <button key={value} onClick={() => handleOnChange(value)} className={listItemClassNames} data-test={key}>
              <span className="mdSelectPopoverContent__checkbox">
                <i className="mdSelectPopoverContent__doneIcon material-icons">done</i>
              </span>
              <span className="mdSelectPopoverContent__text">{key}</span>
            </button>
          );
        })}
      </OverlayScrollbarsComponent>
    </div>
  );
};

MDSelectPopoverContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

MDSelectPopoverContent.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  withSearch: PropTypes.bool,
  isSorted: PropTypes.bool,
  testId: PropTypes.string,
  handleOnChange: PropTypes.func,
  width: PropTypes.number,
  dropdownStatus: PropTypes.bool,
};

export default MDSelectPopoverContent;
