import { connect } from 'react-redux';

import { notificationGoToContext, notificationToggleReadStatus, pullNotifications } from '@/actions/notifications.js';
import { closeNotificationPanel } from '@/actions/uiState.js';
import NotificationPanel from '@/components/notifications/NotificationPanel.jsx';

const mapStateToProps = state => ({
  uiState: state.reducer.uiState,
  userNotifications: state.reducer.userNotifications,
});

const mapDispatchToProps = {
  closeNotificationPanel,
  notificationToggleReadStatus,
  notificationGoToContext,
  pullNotifications,
};

const NotificationPanelContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationPanel);

export default NotificationPanelContainer;
