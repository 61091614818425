import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from './Basic/Button.jsx';
import Modal from './Modal/Modal.jsx';

const HelpModal = props => (
  <Modal show={props.showModal} onHide={props.onHide} className="helpModal">
    <Modal.Header closeButton>
      <Modal.Title componentClass="div">
        <h4 className="exportModalHeader">
          <i className="exportModalHeader__icon material-icons">help</i>
          {props.title}
        </h4>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {props.helpCenterLink ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={props.helpCenterLink}
          style={{ marginTop: '-10px', display: 'block' }}
        >
          <Button modifiers={['orange', 'high', 'medium', 'center', 'centerIcon']}>
            <i className="material-icons">chrome_reader_mode</i>
            <FormattedMessage id="helpModal.helpCenterIntro" defaultMessage="Wprowadzenie do działu w centrum pomocy" />
          </Button>
        </a>
      ) : null}
      <table>
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '70%', textAlign: 'left' }} />
        </colgroup>
        <tbody>{props.children}</tbody>
      </table>
    </Modal.Body>
  </Modal>
);

HelpModal.propTypes = {
  showModal: PropTypes.bool,
  helpCenterLink: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node,
  onHide: PropTypes.func,
};

export default HelpModal;
