import { AreaChartProps, BarChartProps, PieChartProps } from "../common/charts";

type DataItem =   {
    "label": string,
    "fill": boolean,
    "lineTension": number,
    "borderCapStyle": string,
    "borderDash": unknown[],
    "borderDashOffset": number,
    "borderJoinStyle": string,
    "pointBorderWidth": number,
    "pointHoverRadius": number,
    "pointHoverBorderWidth": number,
    "pointRadius": number,
    "pointHitRadius": number,
    "borderColor": string,
    "backgroundColor": string,
    "pointBorderColor": string,
    "pointHoverBackgroundColor": string,
    "pointHoverBorderColor": string,
    "pointBackgroundColor": string,
    "data": number[]
}

type Data = {
    datasets: DataItem[];
    labels: string[];
}

export const getAreaChartData = (data:Data ): AreaChartProps['data'] => data.labels.map((label, index) => {
    const points = { label };
    data.datasets.forEach(dataset => {
        points[dataset.label] = dataset.data[index]
    });
    return points;
});

export const getAreaSeries = (data: Data): AreaChartProps['series'] => data.datasets.map(dataset => ({
    id: dataset.label,
    label: dataset.label,
    stroke: dataset.borderColor,
    fill: dataset.backgroundColor,
    dataKey: dataset.label,
    dot: false,
    type: 'linear'
}));

type DoughnutData = {    
    labels: string[],
    datasets: {
        data: number[],
        backgroundColor: string[]
    }[]
};

export const formatPieData = (data: DoughnutData): PieChartProps['data'] => data.labels.map((label, index) => ({
    color: data.datasets[0].backgroundColor[index],
    name: label,
    value: data.datasets[0].data[index]
}));

type BarData = {
    datasets:  {
        "label": string,
        "backgroundColor": string,
        "borderColor": string,
        "borderWidth": number,
        "hoverBackgroundColor": string,
        "hoverBorderColor": string,
        "data": number[]
    }[],
    labels: string[];
}

export const getBarData = (data: BarData): BarChartProps['data'] => data.labels.map((label, index) => {
    const points = { label };
    data.datasets.forEach(dataset => {
        points[dataset.label] = dataset.data[index]
    });
    return points;
});

export const getBarSeries = (data: BarData): BarChartProps['series'] => data.datasets.map(dataset => ({
    dataKey: dataset.label,
    fill: dataset.backgroundColor,
    fillActive: dataset.hoverBackgroundColor,
    id: dataset.label,
    label: dataset.label,
    stroke: dataset.borderColor,
    strokeActive: dataset.hoverBorderColor,
}));