import PropTypes from 'prop-types';

import { ContextWithIntl } from '@/types/intl.types';
import { getPercent } from '@/utils/baseHelpers';

import { messages } from './tooltipContent.messages';

import './tooltipContent.scss';

const TooltipContent = (props, { intl }: ContextWithIntl) => {
  const { payload, label, formatter } = props;
  const total = payload.reduce((result, entry) => result + entry.value, 0);

  return (
    <div className="k-tooltipContent">
      <p className="k-tooltipContent__total">{`${label} (Total: ${formatter(total)})`}</p>
      <div className="k-tooltipContent__list">
        {payload.map(({ name, value, fill }, index) => (
          <div className="k-tooltipContent__item" key={`item-${index}`} style={{ color: fill }}>
            {`${intl.formatMessage(messages[name])}: ${formatter(value)} (${getPercent(value, total)})`}
          </div>
        ))}
      </div>
    </div>
  );
};

TooltipContent.contextTypes = {
  intl: PropTypes.shape({}),
};

export default TooltipContent;
