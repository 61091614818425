import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import './PayrollReloadInstruction.scss';

export const messages = defineMessages({
  click: {
    id: 'payrollMode.click',
    defaultMessage: 'Kliknij',
  },
  loadData: {
    id: 'payrollMode.loadData',
    defaultMessage: 'Wczytaj dane',
  },
  instruction: {
    id: 'payrollMode.reloadInstruction',
    defaultMessage: ', aby wyświetlić zaktualizowane dane czasu pracy.',
  },
});

const PayrollReloadInstruction = ({ handleClick }, { intl }) => (
  <div className="reloadContainer">
    <i className="material-icons">autorenew</i>
    <div>
      {intl.formatMessage(messages.click)} &quot;
      <span className="reloadContainer__loadData" onClick={() => handleClick()} role="presentation">
        {intl.formatMessage(messages.loadData)}
      </span>
      &quot; {intl.formatMessage(messages.instruction)}
    </div>
  </div>
);

PayrollReloadInstruction.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollReloadInstruction.propTypes = {
  handleClick: PropTypes.func,
};

export default PayrollReloadInstruction;
