import { COLOR_PALETT_DEFAULT_COLOR } from '@/constants/colors';

export const modalModifiers = ['narrow'];

export const inputModifiers = ['modal'];

export const getInitialState = () => ({
  locationName: '',
  city: '',
  address: '',
  postalCode: '',
  color: COLOR_PALETT_DEFAULT_COLOR,
  additionalInformation: '',
  errors: {},
  coords: {
    lat: null,
    lng: null,
  },
  defaultAddress: '',
  blurAutocomplete: false,
});

export const getUpdatedStateForEdit = (editedLocationId, userLocations) => {
  const location = userLocations.find(({ id }) => id === editedLocationId);
  return {
    locationName: location.name,
    city: location.city,
    address: location.address,
    color: location.color,
    additionalInformation: location.additional_information,
    defaultAddress: `${location.city}, ${location.address}`.trim(),
    blurAutocomplete: true,
    coords: {
      lat: location.lat,
      lng: location.lng,
    },
    postalCode: location.postal_code,
  };
};

export const getEmployeesOptions = userEmployees =>
  userEmployees.reduce((options, employee) => {
    if (employee.inactive) return options;
    return [
      ...options,
      {
        label: `${employee.first_name} ${employee.last_name}`,
        value: employee.id,
        active: false,
      },
    ];
  }, []);

export const getUpdatedSelectedEmployeesIds = (userEmployees, editedLocationId, currentUserId, isUserManager) => {
  if (!editedLocationId) return isUserManager ? [currentUserId] : [];
  return userEmployees.reduce(
    (ids, employee) => (employee.locations.some(({ id }) => id === editedLocationId) ? [...ids, employee.id] : ids),
    [],
  );
};

const getEmployeeById = (userEmployees, selectedId) => userEmployees.find(({ id }) => id === selectedId);

export const getChangedSelectedEmployees = (userEmployees, employeesItems, preselectedEmployeesIds) => {
  const toAdd = employeesItems.reduce(
    (employeesToAdd, item) =>
      !item.active || preselectedEmployeesIds.includes(item.value)
        ? employeesToAdd
        : [...employeesToAdd, getEmployeeById(userEmployees, item.value)],
    [],
  );
  const toDelete = employeesItems.reduce(
    (employessToDelete, item) =>
      !item.active && preselectedEmployeesIds.includes(item.value)
        ? [...employessToDelete, getEmployeeById(userEmployees, item.value)]
        : employessToDelete,
    [],
  );
  return { toAdd, toDelete };
};

export const createLocationObject = (
  { locationName, city, address, color, additionalInformation, coords, postalCode },
  attachedEmployees,
) => ({
  name: locationName,
  city,
  address,
  color,
  additional_information: additionalInformation,
  attachedEmployees,
  postal_code: postalCode,
  lat: coords.lat,
  lng: coords.lng,
});
