import { connect } from 'react-redux';

import { changeSendPanelVisibility } from '@/actions/uiState.js';

import TopBarSchedule from './TopBarSchedule.jsx';

const mapStateToProps = state => ({
  absences: state.reducer.overtimeCollections,
  currentCompany: state.reducer.currentCompany,
  mainDateStore: state.reducer.mainDateStore,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  selectedScheduleDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  openShifts: state.reducer.openShifts,
  userEmployees: state.reducer.userEmployees,
  userLocations: state.reducer.userLocations,
  userPermissions: state.reducer.userPermissions,
  userTemplates: state.reducer.userTemplates,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
});

const mapDispatchToProps = {
  changeSendPanelVisibility,
};

const TopBarScheduleContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarSchedule);

export default TopBarScheduleContainer;
