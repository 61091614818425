import type { IntlCache, IntlConfig, IntlShape } from 'react-intl';
import { createIntl, createIntlCache } from 'react-intl';

import { KadroLocaleEnum } from '@/types/locale.types.ts';

import { RootState } from '../types';

export const DEFAULT_LOCALE = KadroLocaleEnum.PL;

export const defaultIntlProps: RootState['intl'] = {
  locale: DEFAULT_LOCALE,
  messages: {},
};

class IntlManager {
  cache: IntlCache;
  intl: IntlShape;
  constructor(intlProps: RootState['intl']) {
    this.cache = createIntlCache();
    this.intl = createIntl(intlProps, this.cache);
  }

  changeIntlConfig(newIntlConfig: IntlConfig) {
    this.intl = createIntl(newIntlConfig, this.cache);
  }

  getIntl() {
    return this.intl;
  }

  formatMessage(...args: Parameters<IntlShape['formatMessage']>) {
    return this.intl.formatMessage(...args);
  }
}

export const intlManager = new IntlManager(defaultIntlProps);
