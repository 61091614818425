import { connect } from 'react-redux';

import WorkedTimePerLocationChart from './WorkedTimePerLocationChart.jsx';

const mapStateToProps = state => ({
  rawData: state.reducer.reports.reportData.workTime,
  userLocations: state.reducer.userLocations,
});

export default connect(mapStateToProps)(WorkedTimePerLocationChart);
