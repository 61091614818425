import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from './types';

export const createActionName = (sliceName: string, actionName: string) => `${sliceName}/${actionName}`;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
}>();
