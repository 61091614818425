import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { FreemiumStar } from '@/components/common/Freemium';

import './SettingsSection.scss';

const SettingsSection = ({
  canSave,
  children,
  panelModifiers = [],
  title,
  onSubmit,
  submitMessage = <FormattedMessage id="settings.saveChanges" defaultMessage="Zapisz zmiany" />,
  showFreemiumStar,
}) => {
  const panelClassnames = classNames(
    'k-panel panel-nopad panel-setting settingsSection',
    ...panelModifiers.map(modifier => `k-panel--${modifier}`),
  );
  return (
    <div className={panelClassnames}>
      <h2 className="k-panel__title">
        {title} {showFreemiumStar && <FreemiumStar />}
      </h2>
      <fieldset className="form-group last">{children}</fieldset>

      {canSave && (
        <div className="settingsButton">
          <Button onClick={onSubmit} modifiers="orange small">
            {submitMessage}
          </Button>
        </div>
      )}
    </div>
  );
};

SettingsSection.propTypes = {
  canSave: PropTypes.bool,
  children: PropTypes.node,
  panelModifiers: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  submitMessage: PropTypes.node,
  showFreemiumStar: PropTypes.bool,
};

export default SettingsSection;
