import { connect } from 'react-redux';

import ScheduleTableHeaderGraphCell from './ScheduleTableHeaderGraphCell.jsx';

const mapStateToProps = ({ reducer }, ownProps) => ({
  points:
    reducer.schedule.scheduleCharts.scheduleChartsData[ownProps.locationId] &&
    reducer.schedule.scheduleCharts.scheduleChartsData[ownProps.locationId][ownProps.date]?.points,
  maxValues: reducer.schedule.scheduleCharts.scheduleChartsMaxValues[ownProps.locationId],
  numberOfDays: reducer.mainDateStore.dateArray.length,
  budgetMetrics: reducer.budgetMetrics,
  showEmployeeAmount: reducer.schedule.viewSettings?.employeesAmount?.value,
  showBudgetMetrics: reducer.schedule.viewSettings?.budgetMetrics?.value,
});

export default connect(mapStateToProps)(ScheduleTableHeaderGraphCell);
