import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  publishDetails: {
    id: 'sendPanel.publishDetails',
    defaultMessage: 'Opublikowane zostaną zmiany dla wybranych lokalizacji w zakresie dat {start} - {end}.',
  },
  shiftsWithErrors: {
    id: 'sendPanel.cannotPublish.shiftsWithErrors',
    defaultMessage: 'Grafik zawiera błędne zmiany ( {errorsNo} ). Popraw je, zanim opublikujesz grafik',
  },
  workingRulesError: {
    id: 'sendPanel.cannotPublish.workingRulesError',
    defaultMessage: 'Zaplanowano zmiany niezgodne z przepisami kodeksu pracy. Nie można opublikować grafiku',
  },
  workingRulesWarning: {
    id: 'sendPanel.workingRulesWarning',
    defaultMessage: 'Publikowany grafik nie został sprawdzony z Kodeksem Pracy i może zawierać niezgodności',
  },
  hoursTargetError: {
    id: 'sendPanel.hoursTargetError',
    defaultMessage:
      'Zaplanowany czas pracy przekracza target godzinowy dla tej lokalizacji. Aby opublikować grafik zmniejsz liczbę zaplanowanych godzin w tym okresie.',
  },
  moneyTargetError: {
    id: 'sendPanel.moneyTargetError',
    defaultMessage:
      'Zaplanowany koszt pracy przekracza target kosztowy dla tej lokalizacji. Aby opublikować grafik zmniejsz zaplanowany koszt w tym okresie.',
  },
  hoursAndMoneyTargetError: {
    id: 'sendPanel.hoursAndMoneyTargetError',
    defaultMessage:
      'Zaplanowany czas pracy oraz koszt pracy przekracza target godzinowy i kosztowy dla tej lokalizacji. Aby opublikować grafik zmniejsz liczbę zaplanowanych godzin w tym okresie.',
  },
});
