import { defineMessages } from 'react-intl';

import { showModal } from '@/actions/uiState';
import * as AT from '@/constants/ActionTypes';
import { EMPLOYEE_MODAL } from '@/constants/modalTypes';
import { Contract } from '@/types/contracts.types.ts';

import { conn, showConfirmModal } from './index';

const messages = defineMessages({
  deleteContractTitle: {
    id: 'contracts.confirmDeleteModal.title',
    defaultMessage: 'Usuń kontrakt',
  },
  deleteContractMessage: {
    id: 'contracts.confirmDeleteModal.message',
    defaultMessage:
      'Kontrakt nie będzie widoczny w kontraktach pracownika. Po tej operacji czas trwania poprzedniego kontraktu zostanie wydłużony do daty zakończenia usuwanego kontraktu.\n\n' +
      'Aby potwierdzić akcję usunięcia kontraktu, kliknij ZAPISZ w oknie edycji pracownika. ',
  },
  confirmDeleteContractText: {
    id: 'contracts.confirmDeleteModal.confirmDeleteContractText',
    defaultMessage: 'Zatwierdź',
  },
  addContractTitle: {
    id: 'contracts.addContract.title',
    defaultMessage: 'Dodanie kontraktów',
  },
  addContractSuccessDescription: {
    id: 'contracts.addContract.success.description',
    defaultMessage: 'Pomyślnie dodano kontrakty pracownikom',
  },
  addContractFailureDescription: {
    id: 'contracts.addContract.failure.description',
    defaultMessage: 'Nie udało przypisać nowych kontraktów pracownikom',
  },
  deleteContractsSuccessTitle: {
    id: 'contracts.deleteContractsSuccess.title',
    defaultMessage: 'Usuwanie kontraktów',
  },
  deleteContractsSuccessDescription: {
    id: 'contracts.deleteContractsSuccess.description',
    defaultMessage: 'Pomyślnie usunięto kontrakty pracownikom',
  },
  deleteContractsFailureTitle: {
    id: 'contracts.deleteContractsFailure.title',
    defaultMessage: 'Nie udało się usunąć kontraktów',
  },
  deleteContractsFailureDescription: {
    id: 'contracts.deleteContractsFailure.description',
    defaultMessage: 'Nie udało się usunąć kontraktów pracownikom',
  },
  editContractsTitle: {
    id: 'contracts.updateContracts.title',
    defaultMessage: 'Edycja kontraktów',
  },
  editContractsSuccessDescription: {
    id: 'contracts.updateContractsSuccess.description',
    defaultMessage: 'Pomyślnie zmieniono kontrakty pracownikom',
  },
  editContractsFailureDescription: {
    id: 'contracts.updateContractsFailure.description',
    defaultMessage: 'Wystąpił błąd podczas edytowania kontraktów',
  },
});

const getContractsSuccess = contracts => ({
  type: AT.GET_CONTRACTS_SUCCESS,
  payload: {
    contracts,
  },
});

const getContractsFailure = () => ({
  type: AT.GET_CONTRACTS_FAILURE,
});

export const getContracts = (employeeIds, from, to, requestType) => async dispatch => {
  try {
    const { data } = await conn.getContracts(employeeIds, from, to, requestType);
    dispatch(getContractsSuccess(data.contracts));
  } catch (error) {
    dispatch(getContractsFailure());
  }
};

export const confirmDeleteContract = (
  contractIdToDelete,
  nextContractId,
  setSelectedContractId,
  changeTab,
  contracts,
) => (dispatch, getState, intl) => {
  const { modalObject } = getState().reducer.uiState;
  const employee = { ...modalObject[EMPLOYEE_MODAL], contracts };
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteContractTitle),
      description: intl.formatMessage(messages.deleteContractMessage),
      confirmText: intl.formatMessage(messages.confirmDeleteContractText),
      closeAsCancel: true,
      confirmFunc: () => {
        dispatch(deleteContractsSuccess(contractIdToDelete));
        // This timeout is needed to prevent modal from closing before it's fully opened,
        // since confirm modal hide action is being dispatched with a slight delay
        setTimeout(() => {
          dispatch(showModal(EMPLOYEE_MODAL, employee));
          setSelectedContractId(nextContractId);
          changeTab(2);
        }, 1);
      },
      cancelFunc: () => {
        setTimeout(() => {
          dispatch(showModal(EMPLOYEE_MODAL, employee));
          changeTab(2);
        }, 1);
      },
    }),
  );
};

export const addContracts = (contracts: Contract[]) => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.UPDATE_CONTRACTS_SUCCESS,
    payload: { contracts },
    notification: {
      title: intl.formatMessage(messages.addContractTitle),
      description: intl.formatMessage(messages.addContractSuccessDescription),
      type: 'success',
    },
  });
};

export const addContractsFailure = () => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.ADD_CONTRACTS_FAILURE,
    notification: {
      title: intl.formatMessage(messages.addContractTitle),
      description: intl.formatMessage(messages.addContractFailureDescription),
      type: 'error',
    },
  });
};

export const createContracts = (contracts: Contract[]) => async dispatch => {
  try {
    const { data } = await conn.createContracts(contracts);
    dispatch(addContracts(data.contracts));
  } catch (e) {
    dispatch(addContractsFailure());
  }
};

const deleteContractsSuccess = (ids: string[]) => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.DELETE_CONTRACTS_SUCCESS,
    payload: { ids },
    notification: {
      title: intl.formatMessage(messages.deleteContractsSuccessTitle),
      description: intl.formatMessage(messages.deleteContractsSuccessDescription),
      type: 'success',
    },
  });
};

export const deleteContractsFailure = () => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.DELETE_CONTRACTS_FAILURE,
    notification: {
      title: intl.formatMessage(messages.deleteContractsFailureTitle),
      description: intl.formatMessage(messages.deleteContractsFailureDescription),
      type: 'error',
    },
  });
};

export const deleteContracts = (ids: string[]) => async dispatch => {
  try {
    await conn.deleteContracts(ids);
    dispatch(deleteContractsSuccess(ids));
  } catch (e) {
    dispatch(deleteContractsFailure());
  }
};

export const updateContractsSuccess = (contracts: Contract[]) => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.UPDATE_CONTRACTS_SUCCESS,
    payload: { contracts },
    notification: {
      title: intl.formatMessage(messages.editContractsTitle),
      description: intl.formatMessage(messages.editContractsSuccessDescription),
      type: 'success',
    },
  });
};

export const updateContractsFailure = () => (dispatch, _getState, intl) => {
  dispatch({
    type: AT.UPDATE_CONTRACTS_FAILURE,
    notification: {
      title: intl.formatMessage(messages.editContractsTitle),
      description: intl.formatMessage(messages.editContractsFailureDescription),
      type: 'error',
    },
  });
};

export const updateContracts = (contracts: Contract[]) => async dispatch => {
  try {
    const updateContractsResponse = await conn.updateContracts(contracts);
    dispatch(updateContractsSuccess(updateContractsResponse.data.contracts));
  } catch (e) {
    dispatch(updateContractsFailure());
  }
};
