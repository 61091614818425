export const pushGAEvent = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const pushGAEcommerceEvent = (event, ecommerceData) => {
  pushGAEvent({
    event,
    ecommerce: ecommerceData,
  });
};

export const getDefaultGAProducts = () => [
  {
    name: 'Paid',
    id: 'product_3',
    price: '10.99',
    category: 'Plan_kadromierz_cennik',
    quantity: 1,
  },
];

export const GA_EVENT_TYPES = {
  ADD_TO_CART: 'eec.add',
  CHECKOUT: 'eec.checkout',
  SET_CHECKOUT_OPTION: 'eec.checkout_option',
  PURCHASE: 'eec.purchase',
  APP_EVENT: 'appEvent',
};

export const pushGAAppEvent = type => {
  pushGAEvent({ event: GA_EVENT_TYPES.APP_EVENT, appEventName: type });
};

const checkoutStart = products =>
  pushGAEcommerceEvent(GA_EVENT_TYPES.CHECKOUT, {
    checkout: {
      actionField: {
        step: 1,
      },
      products,
    },
  });

export const addDefaultProductToCart = () => {
  pushGAEcommerceEvent(GA_EVENT_TYPES.ADD_TO_CART, {
    add: {
      actionField: {
        list: 'Cennik',
      },
      products: getDefaultGAProducts(),
    },
  });
};

const setNewStep = (step, products) =>
  pushGAEcommerceEvent(GA_EVENT_TYPES.CHECKOUT, {
    checkout: {
      actionField: {
        step,
      },
      products,
    },
  });

const setCheckoutOption = (step, optionData) =>
  pushGAEcommerceEvent(GA_EVENT_TYPES.SET_CHECKOUT_OPTION, {
    checkout_option: {
      actionField: {
        step,
        option: optionData,
      },
    },
  });

const setOnboardingType = onboardingType =>
  pushGAEcommerceEvent(GA_EVENT_TYPES.SET_CHECKOUT_OPTION, {
    checkout_option: {
      actionField: {
        step: 1,
        option: onboardingType,
      },
    },
  });

const createdAccount = (companyId, products, coupon) =>
  pushGAEcommerceEvent(GA_EVENT_TYPES.PURCHASE, {
    currencyCode: 'PLN',
    purchase: {
      actionField: {
        id: companyId,
        revenue: products?.[0]?.price,
        shipping: '0.00',
        coupon,
      },
      products,
    },
  });

export const gaEvents = {
  checkoutStart,
  setNewStep,
  setCheckoutOption,
  setOnboardingType,
  createdAccount,
};
