import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import { ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes';
import { timestampsToWorkingHours } from '@/utils/dateHelper.js';

import './ScheduleOvertimeCollection.scss';

const ScheduleOvertimeCollection = (
  { overtimeCollection, handleEditAction, isEmployee, isMonthView, size, isEditDisabled, showModal },
  { intl },
) => {
  const absenceClass = classnames('k-overtimeCollectionBlock', `k-overtimeCollectionBlock--${size}`, {
    'k-overtimeCollectionBlock--disabled': isEditDisabled,
    'k-overtimeCollectionBlock--isEmployee': isEmployee,
  });

  const validComment = overtimeCollection.comment?.length > 0;

  const popoverMessage = intl.formatMessage(
    { id: 'absences.overtimeCollection', defaultMessage: 'Odbiór nadgodzin w godzinach {hours}' },
    { hours: timestampsToWorkingHours(overtimeCollection.start_timestamp, overtimeCollection.end_timestamp) },
  );
  const popover = validComment
    ? {
        title: popoverMessage,
        content: (
          <>
            <FormattedMessage id="absences.comment" defaultMessage="Komentarz do odbioru nadgodzin" />:{' '}
            <strong>{overtimeCollection.comment}</strong>
          </>
        ),
      }
    : {
        content: popoverMessage,
      };

  const blockToRender = (
    <div
      onClick={
        !(isEmployee || isEditDisabled)
          ? event => {
              event.stopPropagation();
              !overtimeCollection.isLegacyOvertime
                ? showModal(ADD_ABSENCE_PROPOSAL_MODAL, { ...overtimeCollection })
                : handleEditAction(overtimeCollection, false);
            }
          : undefined
      }
      className={absenceClass}
      role="presentation"
    >
      <div className="sch_block__info">
        <div className="absence-name">
          {isMonthView ? (
            <FormattedMessage id="absences.overtimeCollectionShortcut" defaultMessage="ON" />
          ) : (
            <FormattedMessage id="absences.overtimeCollection" defaultMessage="Odbiór nadgodzin" />
          )}{' '}
          {timestampsToWorkingHours(overtimeCollection.start_timestamp, overtimeCollection.end_timestamp)}
        </div>
      </div>
      {!isEmployee && (
        <i className={classnames('sch_block__editIcon', 'material-icons', 'sch_block__editIcon--black')}>fact_check</i>
      )}
    </div>
  );

  return <HoverPopOver popover={popover}>{blockToRender}</HoverPopOver>;
};

ScheduleOvertimeCollection.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleOvertimeCollection.propTypes = {
  overtimeCollection: PropTypes.shape({
    draft: PropTypes.bool,
    start_timestamp: PropTypes.string,
    end_timestamp: PropTypes.string,
    employee_id: PropTypes.string,
    comment: PropTypes.string,
    isLegacyOvertime: PropTypes.bool,
  }),
  handleEditAction: PropTypes.func,
  isEmployee: PropTypes.bool,
  isMonthView: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  showModal: PropTypes.func,
};

export default ScheduleOvertimeCollection;
