import PropTypes from 'prop-types';
import { useMemo } from 'react';

import MDTable from '@/components/common/MDTable/MDTable.jsx';
import TextColumnFilter from '@/components/common/MDTable/TextColumnFilter';

import AbsenceTypeModal from '../modals/AbsenceTypeModal/AbsenceTypeModal.redux.js';
import AbsenceTypesOptions from './AbsenceTypesOptions/AbsenceTypesOptions.jsx';
import AbsenceTypesRowOptions from './AbsenceTypesRowOptions/AbsenceTypesRowOptions.jsx';
import columnsData from './columns.json';
import { messages } from './ManageAbsenceTypesView.messages';

import './ManageAbsenceTypesView.scss';

const OVERTIME_COLLECTION_ABSENCE_TYPE = {
  adding_by_employee: false,
  allow_attendance: false,
  code: 'Wzn',
  defined_in_hours: true,
  deleted_at_timestamp: null,
  has_limit: false,
  has_note: true,
  id: 'overtimeCollectionFakeId',
  is_default_uw_absence: false,
  is_paid: true,
  limit_period: null,
  limit_unit: null,
  name: 'Odbiór nadgodzin',
  reduce_uw_limit: false,
  requires_approval: false,
  short_name: 'ON',
  isOvertimeCollection: true,
};

const ManageAbsenceTypesView = (props, context) => {
  const columns = useMemo(
    () =>
      columnsData.map(col => ({
        Header: context.intl.formatMessage(messages[col.Header], {}),
        accessor: col.accessor,
        Filter: col.Filter || TextColumnFilter,
        sticky: col.sticky,
      })),
    [context.intl],
  );

  const tableData = useMemo(
    () =>
      [...props.absencesTypes, OVERTIME_COLLECTION_ABSENCE_TYPE]
        .filter(absenceType => absenceType.deleted_at_timestamp === null)
        .map(absenceType => ({
          ...absenceType,
          adding_by_employee_text: context.intl.formatMessage(
            absenceType.adding_by_employee
              ? messages.manageAbsenceTypesAddingByEmployeeTrue
              : messages.manageAbsenceTypesAddingByEmployeeFalse,
          ),
          has_limit_text: context.intl.formatMessage(
            absenceType.has_limit ? messages.manageAbsenceTypesHasLimitTrue : messages.manageAbsenceTypesHasLimitFalse,
            {},
          ),
          is_paid_text: context.intl.formatMessage(
            absenceType.is_paid ? messages.manageAbsenceTypesIsPaidTrue : messages.manageAbsenceTypesIsPaidFalse,
            {},
          ),
          disabledCheckbox: absenceType.is_default_uw_absence || absenceType.isOvertimeCollection || false,
        })),
    [props.absencesTypes, context.intl],
  );

  return (
    <div className="manageAbsenceTypesView">
      <AbsenceTypeModal />
      <MDTable
        data={tableData}
        columns={columns}
        withCheckbox
        options={<AbsenceTypesRowOptions deleteAbsenceTypes={props.deleteAbsenceTypes} showModal={props.showModal} />}
        tableOptions={<AbsenceTypesOptions showModal={props.showModal} deleteAbsenceTypes={props.deleteAbsenceTypes} />}
        withOptions
      />
    </div>
  );
};

ManageAbsenceTypesView.defaultProps = {
  absencesTypes: [],
};

ManageAbsenceTypesView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ManageAbsenceTypesView.propTypes = {
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  deleteAbsenceTypes: PropTypes.func,
  showModal: PropTypes.func,
};

export default ManageAbsenceTypesView;
