import { connect } from 'react-redux';

import { checkEmail, signUp } from '@/actions/auth.jsx';
import OnboardingFirstHello from '@/components/onboarding/OnboardingFirstHello/OnboardingFirstHello.jsx';

const mapStateToProps = state => ({
  showLoader: state.unauthorizedReducer.loaderState.blocking > 0,
});

const mapDispatchToProps = {
  signUp,
  checkEmail,
};

const OnboardingFirstHelloContainer = connect(mapStateToProps, mapDispatchToProps)(OnboardingFirstHello);

export default OnboardingFirstHelloContainer;
