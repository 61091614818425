import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TopBarSearchInput from '../TopBarSearchInput/TopBarSearchInput.jsx';

class TopBarMultipleChoice extends Component {
  constructor(props) {
    super(props);

    this.setFocus = this.setFocus.bind(this);
    this.searchFilterChange = this.searchFilterChange.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.changeFunc = this.changeFunc.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.displayText = this.displayText.bind(this);
    this.getItems = this.getItems.bind(this);

    this.state = {
      searchPhrase: '',
      isFocused: false,
      items: this.getItems(),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.blurHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchPhrase !== this.state.searchPhrase) {
      this.setState({ items: this.getSortedItems() });
    }

    if (prevState.isFocused && !this.state.isFocused && this.props.onBlur) {
      this.props.onBlur();
    }
    if (!prevState.isFocused && this.state.isFocused) {
      const sortedOptions = this.getSortedItems();
      this.setState({ items: sortedOptions });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.blurHandler);
  }

  setFocus() {
    this.setState(
      prev => ({
        isFocused: !prev.isFocused,
      }),
      () => {
        if (this.state.isFocused && this.searchInput) this.searchInput.setFocus();
      },
    );
  }

  getItems() {
    return this.props.objectsToChoose
      .filter(
        object =>
          !this.state?.searchPhrase ||
          ~object[this.props.displayKey].toLowerCase().indexOf(this.state.searchPhrase.toLowerCase()),
      )
      .map(obj => ({ ...obj, isSelected: this.props.selectedObjects.some(selected => selected.id === obj.id) }));
  }

  getSortedItems() {
    const { disableDefaultSort, displayKey } = this.props;

    const items = this.getItems();
    return disableDefaultSort
      ? items
      : [...items].sort(
          (a, b) =>
            b.isSelected - a.isSelected || a[displayKey].toLowerCase().localeCompare(b[displayKey].toLowerCase()),
        );
  }
  searchFilterChange(e) {
    this.setState({
      searchPhrase: e.target.value,
    });
  }
  blurHandler(e) {
    if (this.singleChoiceRef && !this.singleChoiceRef.contains(e.target)) {
      this.setState({
        isFocused: false,
      });
    }
  }

  changeFunc(object, action) {
    if (action === 'select') {
      this.setState(
        { items: this.state.items.map(item => (item.id === object.id ? { ...item, isSelected: true } : item)) },
        () => {
          this.props.onChange([...this.props.selectedObjects, object]);
        },
      );
    } else if (action === 'deselect') {
      this.setState(
        { items: this.state.items.map(item => (item.id === object.id ? { ...item, isSelected: false } : item)) },
        () => {
          this.props.onChange(this.props.selectedObjects.filter(o => o.id !== object.id));
        },
      );
    } else {
      throw Error("Filter request an action that didn't exist? changeFunc Method");
    }
  }

  selectAll() {
    if (this.props.objectsToChoose.length === this.props.selectedObjects.length) {
      this.setState({ items: this.state.items.map(item => ({ ...item, isSelected: false })) }, () => {
        this.props.onChange([]);
      });
    } else {
      this.setState({ items: this.state.items.map(item => ({ ...item, isSelected: true })) }, () => {
        this.props.onChange(this.props.objectsToChoose);
      });
    }
  }

  displayText(object) {
    return object[this.props.displayKey];
  }

  render() {
    const { icon, objectsToChoose, selectedObjects, onlyIcon, filterName, hideSearch, testId } = this.props;

    const className = classnames('k-topBarFilter k-topBarChoice', this.props.className, {
      'k-topBarChoice--focused': this.state.isFocused,
      'k-topBarChoice--short': icon,
    });

    const allSelectedClassnames = classnames('k-topBarChoice__element', {
      'k-topBarChoice__element--selected': objectsToChoose.length === selectedObjects.length,
    });

    return (
      <div
        className={className}
        ref={el => {
          this.singleChoiceRef = el;
        }}
      >
        <div className="k-topBarChoice__selected" onClick={this.setFocus} role="presentation" data-test={testId}>
          <div className="k-topBarChoice__selectedContent">
            {icon && <span>{icon}</span>}
            {!onlyIcon && (
              <span className="k-topBarChoice__selectedText">
                ({this.props.selectedObjects.length}) {filterName}
              </span>
            )}
            <span className="k-topBarChoice__arrowDown" />
          </div>
        </div>
        <div
          className="k-topBarChoice__list"
          onBlur={this.blurHandler}
          tabIndex={0}
          role="option"
          aria-selected={false}
          data-test="columnsFilter"
        >
          {!hideSearch ? (
            <TopBarSearchInput
              onRef={ref => {
                this.searchInput = ref;
              }}
              searchPhrase={this.state.searchPhrase}
              onChange={this.searchFilterChange}
              testId="searchInputTopBarChoice"
            />
          ) : null}
          <button className={allSelectedClassnames} onClick={this.selectAll}>
            <span className="k-topBarChoice__elementCheckbox" />
            <FormattedMessage id="topbar.multipleChoice.none" defaultMessage="Wszystkie">
              {text => <span className="k-topBarChoice__elementText">{text}</span>}
            </FormattedMessage>
          </button>
          {this.state.items.map(object => {
            const elementClassnames = classnames('k-topBarChoice__element', {
              'k-topBarChoice__element--selected': object.isSelected,
            });
            return (
              <button
                key={object.id}
                className={elementClassnames}
                onClick={() => this.changeFunc(object, object.isSelected ? 'deselect' : 'select')}
                data-test={object.key}
              >
                <span className="k-topBarChoice__elementCheckbox" />
                <span className="k-topBarChoice__elementText">{this.displayText(object)}</span>
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

TopBarMultipleChoice.defaultProps = {
  disableDefaultSort: false,
  onlyIcon: false,
};

TopBarMultipleChoice.propTypes = {
  disableDefaultSort: PropTypes.bool,
  filterName: PropTypes.node,
  displayKey: PropTypes.string.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
  objectsToChoose: PropTypes.arrayOf(PropTypes.shape()),
  selectedObjects: PropTypes.arrayOf(PropTypes.shape()),
  hideSearch: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onlyIcon: PropTypes.bool,
  testId: PropTypes.string,
};

export default TopBarMultipleChoice;
