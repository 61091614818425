import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { conn } from '@/actions';
import { MDRadios } from '@/components/common/inputs/MDComponents';
import ShadowLoader from '@/components/misc/ShadowLoader/ShadowLoader';
import OvertimeUsageDifference from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddAbsenceTab/Options/OvertimeCollectionOptions/OvertimeUsageDifference/OvertimeUsageDifference';
import { calculateDurationMinutes, isHourFulfilled } from '@/utils/dateHelper';
import { createEvent } from '@/utils/inputHelpers';
import { getSumOfOvertimeAcceptancesInMinutes } from '@/utils/overtimeCollectionsHelpers';

import OvertimeCollectionInput from './OvertimeCollectionInput/OvertimeCollectionInput';
import { getApplicantOptions } from './OvertimeCollectionOptions.helpers';
import { messages } from './OvertimeCollectionOptions.messages';
import OvertimeCollectionUsage from './OvertimeCollectionUsage/OvertimeCollectionUsage';

import './OvertimeCollectionOptions.scss';

const OvertimeCollectionOptions = (
  {
    overtimeCycle,
    absenceHours,
    selectedEmployeeId,
    handleInputChange,
    amount50,
    amount100,
    amountPotential,
    multiplier,
    availableOvertimes,
  },
  { intl },
) => {
  const [isLoading, setIsLoading] = useState(false);
  const isAbsenceHoursInputFilled = isHourFulfilled(absenceHours);
  const applicantOptions = useMemo(() => getApplicantOptions(intl), []);
  const selectMultiplier = useCallback(type => handleInputChange(createEvent('multiplier', type)), [handleInputChange]);
  const sumOfOvertimeAcceptances = useMemo(
    () => getSumOfOvertimeAcceptancesInMinutes(amount50, amount100, amountPotential),
    [amount50, amount100, amountPotential],
  );
  const overtimeUsageInMinutes = useMemo(
    () => Math.floor(calculateDurationMinutes(absenceHours) / Number(multiplier)),
    [absenceHours, multiplier],
  );

  useEffect(() => {
    setIsLoading(true);
    new Promise(async (resolve, reject) => {
      conn
        .getLeftOvertimeCollection([selectedEmployeeId], overtimeCycle.month, overtimeCycle.year)
        .then(response => {
          resolve(response);
          handleInputChange(createEvent('availableOvertimes', response.data[selectedEmployeeId]));
        })
        .catch(error => {
          console.error(error);
          reject(error);
        })
        .finally(() => setIsLoading(false));
    });
  }, [overtimeCycle, selectedEmployeeId]);

  if (isLoading) return <ShadowLoader showLoading />;
  if (
    !availableOvertimes ||
    (!availableOvertimes.sum50 && !availableOvertimes.sum100 && !availableOvertimes.sumPotential)
  )
    return null;

  return (
    <>
      <div className="overtimeCollectionOptions__subHeading">{intl.formatMessage(messages.applicant)}</div>
      <MDRadios onChangeType={selectMultiplier} selectedItemType={multiplier} elements={applicantOptions} />
      {isAbsenceHoursInputFilled && (
        <OvertimeCollectionUsage
          overtimeCollections={availableOvertimes}
          overtimeUsageInMinutes={overtimeUsageInMinutes}
        />
      )}
      {availableOvertimes && (
        <>
          <div className="overtimeCollectionOptions__subHeading">{intl.formatMessage(messages.receivedHours)}</div>
          <OvertimeCollectionInput
            id="amount50"
            amount={amount50}
            handleInputChange={handleInputChange}
            maxAmount={availableOvertimes.sum50}
            title={intl.formatMessage(messages.overtime50)}
            absenceHours={absenceHours}
            multiplier={multiplier}
          />

          <OvertimeCollectionInput
            id="amount100"
            amount={amount100}
            handleInputChange={handleInputChange}
            maxAmount={availableOvertimes.sum100}
            title={intl.formatMessage(messages.overtime100)}
            absenceHours={absenceHours}
            multiplier={multiplier}
          />
          <OvertimeCollectionInput
            id="amountPotential"
            amount={amountPotential}
            handleInputChange={handleInputChange}
            maxAmount={availableOvertimes.sumPotential || 0}
            title={intl.formatMessage(messages.overtimePotential)}
            absenceHours={absenceHours}
            multiplier={multiplier}
            tooltip={intl.formatMessage(messages.overtimePotentialTooltip)}
          />
          {overtimeUsageInMinutes > 0 && (
            <OvertimeUsageDifference {...{ sumOfOvertimeAcceptances, overtimeUsageInMinutes }} />
          )}
        </>
      )}
    </>
  );
};

OvertimeCollectionOptions.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OvertimeCollectionOptions.propTypes = {
  overtimeCycle: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
  }),
  absenceHours: PropTypes.string,
  selectedEmployeeId: PropTypes.string,
  handleInputChange: PropTypes.func,
  amount50: PropTypes.number,
  amount100: PropTypes.number,
  amountPotential: PropTypes.number,
  availableOvertimes: PropTypes.shape({
    sum50: PropTypes.number,
    sum100: PropTypes.number,
    sumPotential: PropTypes.number,
    totalDailyOvertime: PropTypes.number,
  }),
  multiplier: PropTypes.string,
};

export default OvertimeCollectionOptions;
