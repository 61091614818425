import { connect } from 'react-redux';

import { finishAutoscheduler } from '@/actions/autoscheduler';
import { getExportFailed, getExportSuccess } from '@/actions/exports.js';
import { updateIntlMessages } from '@/actions/intl.js';
import { showNotificationBar } from '@/actions/notificationBar.js';
import { getReportDataSuccess } from '@/actions/reports';
import AuthenticatedApp from '@/components/AuthenticatedApp.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  currentCompany: state.reducer.currentCompany,
  userPermissions: state.reducer.userPermissions,
  deviceInfo: state.reducer.deviceInfo,
  autoschedulerLocation: state.reducer.uiState.autoschedulerLocation,
  isGhostLogin: state.reducer.ghostLogin.isGhostLogin,
});

const mapDispatchToProps = {
  showNotificationBar,
  updateIntlMessages,
  getExportSuccess,
  getExportFailed,
  finishAutoscheduler,
  getReportDataSuccess,
};

const AuthenticatedAppContainer = connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);

export default AuthenticatedAppContainer;
