import { connect } from 'react-redux';

import ScheduleTableGraphsHeader from './ScheduleTableGraphsHeader.jsx';

const mapStateToProps = state => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  userPermissions: state.reducer.userPermissions,
});

const ScheduleTableGraphsHeaderContainer = connect(mapStateToProps)(ScheduleTableGraphsHeader);

export default ScheduleTableGraphsHeaderContainer;
