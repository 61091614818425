import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages } from 'react-intl';

import { ADD_POSITION_SHIFT_MODAL, ADD_SHIFT_MODAL } from '@/constants/modalTypes.js';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import PositionDropAreaContainer from '@/containers/scheduler/Position/PositionDropAreaContainer.jsx';
import { isHoliday,isWeekend } from '@/utils/dateHelper.js';
import { getScheduleSettingsValue } from '@/utils/schedulerHelpers.js';
import { getEmployeeName } from '@/utils/userEmployeesHelpers.js';

import PositionScheduleShift from '../PositionScheduleShift.jsx';

const messages = defineMessages({
  deleteShiftTitle: {
    id: 'schedulePosition.tableRow.deleteShiftTitle',
    defaultMessage: 'Usuń zmianę',
  },
  deleteShiftDescription: {
    id: 'schedulePosition.tableRow.deleteShiftDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć zmianę dla {employeeName}?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

class SchedulePositionTableRow extends PureComponent {
  addShift(date, e) {
    e.stopPropagation();
    this.props.showModal(ADD_POSITION_SHIFT_MODAL, {
      rowData: this.props.rowData,
      date,
      location: this.props.location,
    });
  }

  deleteShift(shift, e) {
    e.stopPropagation();
    this.props.showConfirmModal({
      title: this.context.intl.formatMessage(messages.deleteShiftTitle, {}),
      description: this.context.intl.formatMessage(messages.deleteShiftDescription, {
        employeeName: shift.employee.employeeName,
      }),
      confirmText: this.context.intl.formatMessage(messages.delete, {}),
      confirmFunc: () => {
        this.props.deleteShiftRequest(shift.employee.id, shift.id);
      },
      confirmType: 'warning',
    });
  }

  editShift(shift, blocked) {
    if (blocked) return;
    this.props.showModal(ADD_SHIFT_MODAL, {
      employee: this.props.userEmployees.find(employee => employee.id === shift.employee.id),
      shift,
      location: shift.location,
    });
  }

  isEditingScheduleDisabled() {
    return this.props.userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);
  }

  render() {
    const showHolidays = getScheduleSettingsValue(this.props.scheduleUIState.settings, 'holidays');
    const relevantRowData = {
      ...this.props.rowData,
      employees: this.props.rowData.employees.map(employee => ({
        ...employee,
        shifts: employee.shifts
          .filter(shift => this.props.dateStore.dateArray.includes(shift.date))
          .map(shift => ({
            ...shift,
            employee: {
              ...shift.employee,
              locations: employee.locations,
              employeeName: getEmployeeName(employee, this.props.dateStore.dateMode),
              longEmployeeName: `${employee.first_name} ${employee.last_name}`,
            },
          })),
      })),
    };
    // We disable selection for PositionView for now
    // const selected = this.props.scheduleUIState.selectedRows.includes(this.props.rowData.id);
    const selected = false;

    const rowClassNames = classnames('h-sorce2', {
      edited: selected && !this.props.canPaste,
      edited_paste: selected && this.props.canPaste,
    });
    const itemStyle = {};
    // if (this.props.canPaste && selected) {
    //   itemStyle.backgroundColor = SCHEDULE_PASTE_BACKGROUND;
    // }

    return (
      <tr id="row1" className={rowClassNames}>
        {this.props.dateStore.dateArray.map((date, index) => {
          const shifts = relevantRowData.employees
            .reduce((prev, employee) => prev.concat(...employee.shifts.filter(shift => shift.date === date)), [])
            .sort((a, b) => (a.working_hours > b.working_hours ? 1 : -1));
          const holiday = isHoliday(date, this.props.holidays);
          const tdClassNames = classnames({
            empty: shifts.length === 0 && !this.isEditingScheduleDisabled(),
            today: date === this.props.dateStore.today,
            selected_column: selected && !this.props.canPaste,
            weekend: isWeekend(date),
            freeFromWorkHoliday: showHolidays && holiday.freeFromWork,
            workDayHoliday: showHolidays && holiday.workDay,
          });
          return (
            <td
              key={index}
              onClick={
                !shifts.length && !this.isEditingScheduleDisabled()
                  ? () => {
                      this.props.showModal(ADD_POSITION_SHIFT_MODAL, {
                        rowData: this.props.rowData,
                        date,
                        location: this.props.location,
                      });
                    }
                  : () => {}
              }
              style={itemStyle}
              className={tdClassNames}
              role="presentation"
            >
              <PositionDropAreaContainer
                date={date}
                jobTitleID={relevantRowData.id}
                rowData={this.props.rowData}
                locationId={this.props.location.id}
                draggable={!this.isEditingScheduleDisabled()}
              >
                {shifts.map(shift => {
                  const blocked =
                    this.props.currentUser.user.role !== 'owner' &&
                    !this.props.userLocations.map(location => location.id).includes(shift.location.id);
                  return (
                    <PositionScheduleShift
                      key={shift.id}
                      className="position__sch_block"
                      title={shift.employee.employeeName}
                      shift={shift}
                      employee_name={shift.employee.longEmployeeName}
                      location={shift.employee.locations.find(l => l.id === shift.location.id)}
                      blocked={blocked}
                      otherLocation={this.props.location.id !== shift.location.id}
                      handleAddAction={e => this.addShift(date, e)}
                      handleEditAction={() => this.editShift(shift, blocked)}
                      handleDeleteAction={e => this.deleteShift(shift, e)}
                      editingScheduleDisabled={this.isEditingScheduleDisabled()}
                    />
                  );
                })}
              </PositionDropAreaContainer>
            </td>
          );
        })}
      </tr>
    );
  }
}

SchedulePositionTableRow.contextTypes = {
  intl: PropTypes.shape({}),
};

SchedulePositionTableRow.propTypes = {
  rowData: PropTypes.shape({
    employees: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  dateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    today: PropTypes.string,
  }),
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
    }),
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  canPaste: PropTypes.bool,
  showModal: PropTypes.func,
  showConfirmModal: PropTypes.func,
  deleteShiftRequest: PropTypes.func,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  scheduleUIState: PropTypes.shape({
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default SchedulePositionTableRow;
