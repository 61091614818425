import { showConfirmModal } from '@/actions/index';
import { getFromToFromDateStore } from '@/utils/dateHelper';

import { changeTradeShift } from '../tradeShifts';
import { messages } from './tradeShifts.messages';

export const deleteTradeShiftsInSelectedLocationsBetweenDates = () => (dispatch, getState, intl) => {
  const { tradeShifts, jobtitleFilter, mainDateStore, schedule } = getState().reducer;
  const { tradeShiftsRowSelect } = schedule;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const locationIdsWithSelectedTradeShiftsRow = Object.keys(tradeShiftsRowSelect).filter(
    locationId => tradeShiftsRowSelect[locationId],
  );
  if (!locationIdsWithSelectedTradeShiftsRow.length) return;
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);

  const shiftsToDeleteIds = tradeShifts.reduce((acc, tradeShift) => {
    if (
      tradeShift.date <= to &&
      tradeShift.date >= from &&
      locationIdsWithSelectedTradeShiftsRow.includes(tradeShift.location.id) &&
      selectedJobTitlesIds.includes(tradeShift.job_title.id) &&
      tradeShift.status !== 'rejected'
    ) {
      acc.push(tradeShift.id);
    }
    return acc;
  }, []);

  if (shiftsToDeleteIds.length) {
    dispatch(
      showConfirmModal({
        title: intl.formatMessage(messages.deleteTradeShiftsFromSelectedCellsTitle),
        description: intl.formatMessage(messages.deleteTradeShiftsFromSelectedCellsMessage, {
          numberOfShifts: shiftsToDeleteIds.length,
        }),
        confirmText: intl.formatMessage(messages.deleteTradeShiftsFromSelectedCellsConfirm),
        confirmFunc: () => {
          shiftsToDeleteIds.forEach(shiftId => {
            dispatch(
              changeTradeShift(shiftId, {
                status: 'rejected',
              }),
            );
          });
        },
        confirmType: 'warning',
      }),
    );
  }
};
