import { defineMessages } from 'react-intl';

import { LocalStorageEnum } from '@/types/web.types';

export type RawLoggedAccount = {
  user_id: string;
  location_names: string[];
  job_title_names: string[];
  role_name: string;
  first_name: string;
  last_name: string;
  company_name: string;
  avatar: string;
  email: string;
  active: boolean;
  isDefault?: boolean;
  expires_at?: string;
};

export type LoggedAccount = {
  userId: string;
  locationNames: string[];
  jobTitleNames: string[];
  roleName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  avatar: string;
  email: string;
  active: boolean;
  isDefault?: boolean;
  expiresAt?: string;
};

export const getLoggedAccounts = (): LoggedAccount[] => {
  const accounts = localStorage.getItem(LocalStorageEnum.ACCOUNTS);
  if (!accounts) return [];
  const { loggedAccounts = [] }: { loggedAccounts: LoggedAccount[] } = JSON.parse(accounts);
  return loggedAccounts.map((account: LoggedAccount) => ({
    userId: account.userId,
    locationNames: account.locationNames,
    jobTitleNames: account.jobTitleNames,
    roleName: account.roleName,
    firstName: account.firstName,
    lastName: account.lastName,
    companyName: account.companyName,
    avatar: account.avatar,
    email: account.email,
    active: account.active,
    isDefault: account.isDefault,
    expiresAt: account.expiresAt,
  }));
};

export const getActiveAccount = (): LoggedAccount => {
  const accounts = getLoggedAccounts();
  return accounts.find(a => a.active);
};

export const storeLoggedAccounts = (loggedAccounts: LoggedAccount[]) => {
  localStorage.setItem(LocalStorageEnum.ACCOUNTS, JSON.stringify({ loggedAccounts }));
};

const messages = defineMessages({
  roleOwner: {
    defaultMessage: 'Właściciel konta',
    id: 'multiAccounts.roleOwner',
  },
  roleManager: {
    defaultMessage: 'Manager',
    id: 'multiAccounts.roleManager',
  },
  roleEmployee: {
    defaultMessage: 'Pracownik',
    id: 'multiAccounts.roleEmployee',
  },
});

export const getRoleToShow = (roleName: string, intl: any) => {
  if (roleName === 'owner') {
    return intl.formatMessage(messages.roleOwner);
  }
  if (roleName === 'manager') {
    return intl.formatMessage(messages.roleManager);
  }
  if (roleName === 'employee') {
    return intl.formatMessage(messages.roleEmployee);
  }
  return roleName;
};
