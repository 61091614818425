import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@/components/common/Basic/Button';
import Icon from '@/components/common/Basic/Icon/Icon';
import PayrollReloadInstruction from '@/components/common/PayrollReloadInstruction/PayrollReloadInstruction';
import PayrollAddRecordButton from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollButtonBar/PayrollAddRecordButton/PayrollAddRecordButton.redux.js';
import {
  getRelevantDatesToApprove,
  getRelevantDatesToUnapprove,
} from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollButtonBar/PayrollButtonBar.helpers';
import TopBarMultipleColumnFilter from '@/components/TopBars/filters/TopBarMultipleColumnFilter/TopBarMultipleColumnFilter.redux';
import { REVERSE_GREEN, REVERSE_RED } from '@/constants/buttonModifiers';

import PayrollActionButtons from './PayrollActionButtons/PayrollActionButtons.redux';
import PayrollMode from './PayrollMode/PayrollMode.redux';
import PayrollSummary from './PayrollSummary/PayrollSummary.redux';

import './PayrollButtonBar.scss';

const messages = defineMessages({
  backButton: {
    id: 'payroll.buttonBar.back',
    defaultMessage: 'Wróć do widoku grupowego',
  },
});

const PayrollButtonBar = (
  {
    isLoadButtonEnabled,
    getPayrollViewData,
    selectedData,
    changePayrollDaysStatus,
    employeeId,
    visibleRows,
    payrollApprovalEnabled,
    isFetching,
  },
  { intl },
) => {
  const handleApproveClick = useCallback(() => {
    const dates = getRelevantDatesToApprove(selectedData);

    if (dates.length > 0) {
      changePayrollDaysStatus(employeeId, dates, 'approved');
    }
  }, [changePayrollDaysStatus, selectedData, employeeId]);

  const handleUnapproveClick = useCallback(() => {
    const dates = getRelevantDatesToUnapprove(selectedData);

    if (dates.length > 0) {
      changePayrollDaysStatus(employeeId, dates, 'unapproved');
    }
  }, [changePayrollDaysStatus, selectedData, employeeId]);

  const disabledExport = useMemo(() => (visibleRows ? visibleRows.length === 0 : true), [visibleRows]);

  return (
    <div className="k-payrollButtonBar">
      <div className="k-payrollButtonBar__row">
        <div className="k-payrollButtonBar__left">
          <PayrollMode />
          <PayrollAddRecordButton />
          {isLoadButtonEnabled && !isFetching && <PayrollReloadInstruction handleClick={getPayrollViewData} />}
        </div>
        <div className="k-payrollButtonBar__right">
          {payrollApprovalEnabled && (
            <>
              <Button modifiers={REVERSE_GREEN} onClick={handleApproveClick} disabled={selectedData.length === 0}>
                <span className="material-icons">check</span>
                <FormattedMessage id="payrollTable.approve.button" defaultMessage="Oznacz jako zatwierdzone" />
              </Button>
              <Button modifiers={REVERSE_RED} onClick={handleUnapproveClick} disabled={selectedData.length === 0}>
                <span className="material-icons">remove_done</span>
                <FormattedMessage id="payrollTable.unapprove.button" defaultMessage="Oznacz jako oczekujące" />
              </Button>
            </>
          )}
          <TopBarMultipleColumnFilter
            key="multipleColumnFilter"
            payrollType="newPayroll"
            onlyIcon
            className="k-topBarChoice--small k-topBarChoice--pinned-right"
            icon={<Icon type="material-outlined" name="view_column" />}
          />
          <PayrollActionButtons {...{ disabledExport }} />
        </div>
      </div>
      <div className="k-payrollButtonBar__row">
        <div className="k-payrollButtonBar__backButton">
          <Link to="/newPayrollLocation" className="k-payrollButtonBar__backButtonLink">
            <Icon name="arrow_back" />
            <span className="k-payrollButtonBar__backButtonText">{intl.formatMessage(messages.backButton)}</span>
          </Link>
        </div>
        <PayrollSummary />
      </div>
    </div>
  );
};

PayrollButtonBar.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollButtonBar.propTypes = {
  isLoadButtonEnabled: PropTypes.bool,
  getPayrollViewData: PropTypes.func,
  selectedData: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.string })),
  changePayrollDaysStatus: PropTypes.func,
  employeeId: PropTypes.string,
  visibleRows: PropTypes.arrayOf(PropTypes.shape({})),
  payrollApprovalEnabled: PropTypes.bool,
  isFetching: PropTypes.bool,
};

export default PayrollButtonBar;
