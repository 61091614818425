import PropTypes from 'prop-types';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FreemiumStar,FreemiumWrapper } from '@/components/common/Freemium';
import DateInput from '@/components/common/inputs/DateInput/DateInput';
import { FREEMIUM_EVENT_RESTRICTIONS_SETTINGS } from '@/constants/intercom';
import { FREEMIUM_DISABLE_RESTRICTIONS_SETTINGS } from '@/constants/Restrictions';

export const RestrictionsSettings = props => {
  const { disableAvailabilitiesEditUntil, handleInputChange, restrictions } = props;

  return (
    <FreemiumWrapper
      freemiumRestrictions={[FREEMIUM_DISABLE_RESTRICTIONS_SETTINGS]}
      intercomEvent={FREEMIUM_EVENT_RESTRICTIONS_SETTINGS}
    >
      <h2 className="k-panel__title">
        <FormattedMessage id="settings.options.restrictions" defaultMessage="Restrykcje" />
        {restrictions.includes(FREEMIUM_DISABLE_RESTRICTIONS_SETTINGS) && <FreemiumStar />}
      </h2>
      <fieldset className="form-group">
        <DateInput
          slug="disableAvailabilitiesEditUntil"
          name={
            <div>
              <div className="labelTitle">
                <FormattedMessage
                  id="settings.locations.disableAvailabilitiesEditUntil"
                  defaultMessage="Zablokuj edycję dostępności do"
                />
              </div>
              <div className="labelDesc">
                <FormattedMessage
                  id="settings.locations.disableAvailabilitiesEditUntil.desc"
                  defaultMessage="Blokuje możliwość dodawania dostępności wszystkim użytkownikom do określonej daty"
                />
              </div>
            </div>
          }
          value={disableAvailabilitiesEditUntil}
          handleInput={handleInputChange}
        />
      </fieldset>
    </FreemiumWrapper>
  );
};

RestrictionsSettings.propTypes = {
  handleInputChange: PropTypes.func,
  disableAvailabilitiesEditUntil: PropTypes.string,
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default memo(RestrictionsSettings);
