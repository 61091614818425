import { Absence, AvailabilityBlock } from 'kadro-helpers/lib/types';
import * as React from 'react';

import { OvertimeCollection } from '@/types';

import Icon from '../../Basic/Icon/Icon.jsx';
import Popover from '../../Popover/Popover.jsx';
import AttendancesUnavailabilityWarningContent from './AttendancesUnavailabilityWarningContent.redux.js';

import './AttendanceWarningIcon.scss';

interface AttendanceUnavailabilityWarningIconProps {
  availability: AvailabilityBlock;
  absence: Absence;
  overtimeCollections: OvertimeCollection[];
}

const AttendanceUnavailabilityWarningIcon: React.FC<AttendanceUnavailabilityWarningIconProps> = ({
  availability,
  absence,
  overtimeCollections,
}) => {
  const hasOvertimeCollections = overtimeCollections?.length > 0;
  if (!availability && !absence && !hasOvertimeCollections) {
    return null;
  }

  return (
    <Popover
      position="right"
      content={
        <AttendancesUnavailabilityWarningContent
          availability={availability}
          absence={absence}
          overtimeCollections={overtimeCollections}
        />
      }
      showOnHover
      showShadow={false}
      autoSideOffset
      preventPopoverHide
      yOffset={10}
      xOffset={15}
      hideOnClick
      hideTimeout={0}
    >
      <div className="k-attendanceWarningIcon">
        <Icon type="material-outlined" name="info" className="k-icon-info" />
      </div>
    </Popover>
  );
};

export default AttendanceUnavailabilityWarningIcon;
