import { connect } from 'react-redux';

import {
  assignLabelsToAttendance,
  handleMultipleBreaksActions,
  showDeleteAttendanceConfirmModal,
  unassignLabelsFromAttendance,
} from '@/actions/attendances.js';
import {
  editShiftAndAttendanceNewPayroll,
  getShiftOrAttendanceForPayroll,
  showModalToAddShiftForExistingAttendance,
} from '@/actions/payroll/payroll.js';
import { showDeleteShiftForPayrollConfirmModal } from '@/actions/schedule/shifts';
import { hideModal } from '@/actions/uiState.js';
import { PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL } from '@/constants/modalTypes';

import PayrollEditShiftAndAttendanceModal from './PayrollEditShiftAndAttendanceModal';

const mapStateToProps = (state, ownProps) => ({
  visible: state.reducer.uiState.showModal === PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL,
  modalObject: state.reducer.uiState.modalObject[PAYROLL_EDIT_SHIFT_AND_ATTENDANCE_MODAL],
  shift: state.reducer[ownProps.employeeId]?.shifts?.[ownProps.shiftId],
  userEmployees: state.reducer.userEmployees,
  locationSettings: state.reducer.settings.locationSettings,
  userPermissions: state.reducer.userPermissions,
  currentUser: state.reducer.currentUser,
  companyRoles: state.reducer.roles.companyRoles,
  labels: state.reducer.labels,
  contracts: state.reducer.contracts,
  userJobTitles: state.reducer.userJobTitles,
  payrollLoanedEmployees: state.reducer.payrollLoanedEmployees.data,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  hideModal,
  showDeleteAttendanceConfirmModal,
  showDeleteShiftForPayrollConfirmModal,
  showModalToAddShiftForExistingAttendance,
  handleMultipleBreaksActions,
  editShiftAndAttendanceNewPayroll,
  getShiftOrAttendanceForPayroll,
  assignLabelsToAttendance,
  unassignLabelsFromAttendance,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollEditShiftAndAttendanceModal);
