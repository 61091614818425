import PropTypes from 'prop-types';

import SearchBox from '@/components/common/SearchBox.jsx';
import TopBarPayrollLocation from '@/components/TopBars/TopBarPayrollLocation.jsx';

import ImportShiftAndAttendanceErrorModal from './ImportShiftAndAttendanceErrorModal/ImportShiftAndAttendanceErrorModal.redux';
import PayrollLocationTableViewContainer from './PayrollLocationTableView.redux.js';

const PayrollLocationView = ({ searchString, setPayrollSearchString }) => (
  <>
    <TopBarPayrollLocation />
    <ImportShiftAndAttendanceErrorModal />
    <SearchBox getSearchWord={({ target }) => setPayrollSearchString(target.value)} value={searchString} />
    <PayrollLocationTableViewContainer searchString={searchString} clearSearchWord={() => setPayrollSearchString('')} />
  </>
);

PayrollLocationView.propTypes = {
  searchString: PropTypes.string,
  setPayrollSearchString: PropTypes.func,
};

export default PayrollLocationView;
