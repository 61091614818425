import classnames from 'classnames';
import PropTypes from 'prop-types';

const AttendanceOvertimeTimeSpan = props => {
  const { attendance, shift } = props.data;

  const attendanceStartClassnames = classnames('k-AttendanceOvertimeTimeSpan', {
    'k-AttendanceOvertimeTimeSpan--normal': !shift || attendance.start_timestamp === shift.start_timestamp,
    'k-AttendanceOvertimeTimeSpan--earlyStart': shift && attendance.start_timestamp < shift.start_timestamp,
    'k-AttendanceOvertimeTimeSpan--lateStart': shift && attendance.start_timestamp > shift.start_timestamp,
  });

  const attendanceEndClassnames = classnames('k-AttendanceOvertimeTimeSpan', {
    'k-AttendanceOvertimeTimeSpan--normal': !shift || attendance.end_timestamp === shift.end_timestamp,
    'k-AttendanceOvertimeTimeSpan--earlyQuit': shift && attendance.end_timestamp < shift.end_timestamp,
    'k-AttendanceOvertimeTimeSpan--lateQuit': shift && attendance.end_timestamp > shift.end_timestamp,
  });

  const [attendanceStart, attendanceEnd] = attendance.hours.split('-');

  return (
    <div>
      <span className={attendanceStartClassnames}>{attendanceStart}</span>
      {' - '}
      <span className={attendanceEndClassnames}>{attendanceEnd}</span>
    </div>
  );
};

AttendanceOvertimeTimeSpan.propTypes = {
  data: PropTypes.shape({
    attendance: PropTypes.shape({
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
    }),
    shift: PropTypes.shape({
      start_timestamp: PropTypes.string,
      end_timestamp: PropTypes.string,
    }),
  }),
};

export default AttendanceOvertimeTimeSpan;
