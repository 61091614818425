import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import InputMask from 'react-input-mask';

import { MDTooltip } from '@/components/common/inputs/MDComponents';
import { calculateDurationMinutes, hoursToMinutes, isHourFulfilled, parseMinutesToFullHours } from '@/utils/dateHelper';
import { createEvent } from '@/utils/inputHelpers.js';

import { messages } from '../OvertimeCollectionOptions.messages';

const OvertimeCollectionInput = (
  { amount, title, handleInputChange, id, maxAmount, absenceHours, multiplier, tooltip },
  { intl },
) => {
  const handleChange = useCallback((name, value) => {
    handleInputChange(createEvent(name, value));
  }, []);
  const maxValueToUse = parseMinutesToFullHours(maxAmount);
  const useAllAmount = value => handleInputChange(createEvent(id, value));
  const maxOvertimeUsageInMinutes = Math.floor(calculateDurationMinutes(absenceHours) / Number(multiplier));
  const maxOvertimeUsageInHours = parseMinutesToFullHours(maxOvertimeUsageInMinutes);

  const isAmountExceeded = useMemo(() => {
    if (isHourFulfilled(amount)) {
      const selectedAmount = hoursToMinutes(amount);
      return selectedAmount > maxAmount;
    }
    return false;
  }, [amount]);

  return (
    <>
      <div className="overtimeCollectionInput">
        <span className="overtimeCollectionInput__title">
          {title}
          {tooltip && <MDTooltip text={tooltip} />}
        </span>
        <div className="overtimeCollectionInput__group">
          <InputMask
            className="k-textInput"
            datatype="time"
            mask="99:99"
            maskChar="_"
            onChange={e => handleChange(e.target.name, e.target.value)}
            id={id}
            name={id}
            value={amount}
          />
          <div className="overtimeCollectionInput__suffix">
            {maxValueToUse}
            <MDTooltip text={intl.formatMessage(messages.maxOvertimeUsageTooltipText)} />
          </div>
        </div>
        <button
          className="overtimeCollectionInput__useAll"
          onClick={() => useAllAmount(maxAmount > maxOvertimeUsageInMinutes ? maxOvertimeUsageInHours : maxValueToUse)}
        >
          {intl.formatMessage(messages.useAll)}
        </button>
      </div>
      {isAmountExceeded && (
        <div className="addAbsenceTab__errorMessage">{intl.formatMessage(messages.overtimeAmountExceeded)}</div>
      )}
    </>
  );
};

OvertimeCollectionInput.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OvertimeCollectionInput.propTypes = {
  amount: PropTypes.number,
  title: PropTypes.string,
  handleInputChange: PropTypes.func,
  id: PropTypes.string,
  maxAmount: PropTypes.number,
  absenceHours: PropTypes.string,
  multiplier: PropTypes.string,
  tooltip: PropTypes.string,
};

export default OvertimeCollectionInput;
