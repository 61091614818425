import { omit, sortBy } from 'lodash';

import * as AT from '@/constants/ActionTypes.js';
import { ATTENDANCE_LABELS_MANAGE_SHOW, PERMISSIONS_TO_EDIT_ATTENDANCE } from '@/constants/Roles';

const initialState = {
  toggledGroups: [],
  companyRoles: [],
  preCheckedPermissions: {},
  checkedPermissions: {},
  availablePermissions: [],
  grouppedPermissions: {},
};

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        availablePermissions: action.payload.permissions,
        grouppedPermissions: action.payload.grouppedPermissions,
      };
    }

    case AT.GET_ROLES_SUCCESS: {
      const { companyRoles, preCheckedPermissions } = action.payload;
      return {
        ...state,
        companyRoles: [...companyRoles.splice(0, 2), ...sortBy(companyRoles, [o => o.name.toLowerCase()])],
        checkedPermissions: { ...state.checkedPermissions, ...preCheckedPermissions },
        preCheckedPermissions,
      };
    }

    case AT.TOGGLE_GROUP_SUCCESS: {
      const { toggledGroups } = state;
      const found = toggledGroups.includes(action.payload);
      return {
        ...state,
        toggledGroups: found
          ? [...toggledGroups].filter(permission => permission !== action.payload)
          : [...toggledGroups, action.payload],
      };
    }

    case AT.TOGGLE_PERMISSION_SUCCESS: {
      const { checkedPermissions } = state;
      const { roleId, perm } = action.payload;
      const found = checkedPermissions[roleId].find(permission => permission.id === perm.id);
      const parsedCheckedPermissions = found
        ? [...checkedPermissions[roleId]].filter(permission => permission.id !== perm.id)
        : [...checkedPermissions[roleId], perm];

      const hasEditAttendancePermission = parsedCheckedPermissions.some(({ id }) =>
        PERMISSIONS_TO_EDIT_ATTENDANCE.includes(id),
      );
      const filteredPermissions = parsedCheckedPermissions.filter(
        ({ id }) => !(id === ATTENDANCE_LABELS_MANAGE_SHOW && !hasEditAttendancePermission),
      );

      return {
        ...state,
        checkedPermissions: {
          ...checkedPermissions,
          [roleId]: filteredPermissions,
        },
      };
    }

    case AT.UNCHECK_PERMISSIONS_SUCCESS: {
      const { checkedPermissions } = state;
      const { roleId, groupId } = action.payload;
      return {
        ...state,
        checkedPermissions: {
          ...checkedPermissions,
          [roleId]: [...checkedPermissions[roleId]].filter(permission => permission.groupId !== groupId),
        },
      };
    }

    case AT.CHECK_PERMISSIONS_SUCCESS: {
      const { checkedPermissions } = state;
      const { roleId, perms } = action.payload;
      return {
        ...state,
        checkedPermissions: {
          ...checkedPermissions,
          [roleId]: [...checkedPermissions[roleId], ...perms],
        },
      };
    }

    case AT.SAVE_ROLES_SUCCESS: {
      return {
        ...state,
        preCheckedPermissions: action.payload,
      };
    }

    case AT.ADD_ROLE_SUCCESS: {
      const { role, preCheckedPermissions } = action.payload;
      const roles = [...state.companyRoles, ...role];
      return {
        ...state,
        companyRoles: [...roles.splice(0, 2), ...sortBy(roles, [o => o.name.toLowerCase()])],
        checkedPermissions: { ...state.checkedPermissions, ...preCheckedPermissions },
        preCheckedPermissions: { ...state.preCheckedPermissions, ...preCheckedPermissions },
      };
    }

    case AT.EDIT_ROLE_SUCCESS: {
      const role = action.payload[0];
      const roles = [...state.companyRoles];
      const index = roles.findIndex(el => el.id === role.id);
      roles[index] = role;

      return {
        ...state,
        companyRoles: roles,
      };
    }

    case AT.DELETE_ROLE_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        companyRoles: [...state.companyRoles.filter(role => role.id !== id)],
        checkedPermissions: { ...omit(state.checkedPermissions, id) },
        preCheckedPermissions: { ...omit(state.preCheckedPermissions, id) },
      };
    }
    default:
      return state;
  }
};
export default rolesReducer;
