import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loanEmployeesProposalsTitle: {
    id: 'dashboard.loanEmployeesProposals.title',
    defaultMessage: 'Prośby o udostępnienie pracowników do rozpatrzenia',
  },
  loanEmployeesProposalsEmptyState: {
    id: 'dashboard.loanEmployeesProposals.emptyState',
    defaultMessage: 'Brak wniosków do rozpatrzenia',
  },
});
