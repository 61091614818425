import { union } from 'lodash';

import {
  SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL,
  SET_USER_RESTICTIONS_SUCCESSFUL,
  UPDATE_PERMISSIONS_SUCCESSFUL,
} from '@/constants/ActionTypes.js';
import { PRODUCTION_QUOTAS_GET } from '@/constants/Permissions.js';

const initialState = {
  // Is the user an employee?
  // This should be set on start after we fetch the currentUser
  isEmployee: false,
  // Is the account Active? If this is false, we display a modal and direct the
  // person to the payment page or other
  // We check this with a call to workers. (see actions/userPermissions)
  accountActive: true,
  // An array of permissions (strings), currently not utilized, but slowly we'll be adding here
  permissions: [],
  restrictions: [],
};

const userPermissions = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PERMISSIONS_SUCCESSFUL:
      return {
        ...state,
        ...action.payload,
        restrictions: union(state.restrictions, action.payload.restrictions),
        permissions: union(state.permissions, action.payload.permissions),
      };
    case SET_PRODUCTION_QUOTAS_PERMISSION_SUCCESFUL: {
      const isInArray = state.permissions.indexOf(PRODUCTION_QUOTAS_GET);
      let permissions = [...state.permissions];
      if (action.payload && !~isInArray) {
        permissions = [...permissions, PRODUCTION_QUOTAS_GET];
      } else if (!action.payload && !!~isInArray) {
        permissions = [...permissions.slice(0, isInArray), ...permissions.slice(isInArray + 1)];
      }
      return {
        ...state,
        permissions,
      };
    }
    case SET_USER_RESTICTIONS_SUCCESSFUL:
      return {
        ...state,
        restrictions: union(state.restrictions, action.payload),
      };
    default:
      return state;
  }
};

export default userPermissions;
