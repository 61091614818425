import { defineMessages, IntlShape } from 'react-intl';

import { EmployeeGroupingEnum } from '@/types/employeeGrouping';

import { TopBarSingleChoiceOption } from '../../common/TopBarSingleChoice/TopBarSingleChoice.types';

export const getEmployeeGroupings = ({ formatMessage }: IntlShape): TopBarSingleChoiceOption<EmployeeGroupingEnum>[] =>
  [
    {
      id: EmployeeGroupingEnum.NONE,
      displayText: formatMessage({
        id: 'employeeGrouping.none',
        defaultMessage: 'Brak',
      }),
    },
    {
      id: EmployeeGroupingEnum.JOB_TITLE,
      displayText: formatMessage({
        id: 'employeeGrouping.jobTitle',
        defaultMessage: 'Stanowisko',
      }),
    },
    {
      id: EmployeeGroupingEnum.EMPLOYMENT_CONDITION,
      displayText: formatMessage({
        id: 'employeeGrouping.employmentCondition',
        defaultMessage: 'Warunek zatrudnienia',
      }),
    },
    {
      id: EmployeeGroupingEnum.WORKING_TIME,
      displayText: formatMessage({
        id: 'employeeGrouping.workingTime',
        defaultMessage: 'Wymiar etatu',
      }),
    },
  ].sort((a, b) => a.displayText.localeCompare(b.displayText));

export const messages = defineMessages({
  grouping: {
    id: 'employeeGrouping.grouping',
    defaultMessage: 'Grupowanie',
  },
});
