import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { getAbsenceHistoryIcon, getAbsenceHistoryMessage } from '@/utils/absenceHelpers.js';

import './AbsenceHistoryModal.scss';

const messages = defineMessages({
  absenceHistoryTitle: {
    id: 'absenceHistory.title',
    defaultMessage: 'Historia',
  },
});

const AbsenceHistoryModal = (props, context) => {
  const { showModal, hideModal, replaceModal } = props;

  const [modalObject, setModalObject] = useState(props.modalObject);

  useEffect(() => {
    if (props.modalObject && Object.keys(props.modalObject).length !== 0) {
      setModalObject(props.modalObject);
    }
  }, props.modalObject);

  const getMessage = item => {
    const absenceHistoryMessage = getAbsenceHistoryMessage(item, context);
    return (
      <>
        {absenceHistoryMessage.message}
        <span className="absenceHistoryModal__status"> {absenceHistoryMessage.status}</span>
      </>
    );
  };

  const handleReplaceModal = () => {
    replaceModal(modalObject.previousModalName);
  };

  const getHeaderOptions = () => {
    if (modalObject && modalObject.previousModalName) {
      return [{ icon: 'keyboard_return', handleClick: handleReplaceModal }];
    }
    return [];
  };

  return (
    <MDKadroModal
      show={showModal}
      onHide={hideModal}
      title={context.intl.formatMessage(messages.absenceHistoryTitle, {})}
      modifiers={['narrow']}
      hideFooter
      headerOptions={getHeaderOptions()}
    >
      <div className="absenceHistoryModal__history">
        {modalObject?.history.history
          .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
          .map((item, index) => {
            const date = moment(item.timestamp);
            return (
              <div key={index} className="absenceHistoryModal__item">
                <div className="absenceHistoryModal__date">
                  <div className="absenceHistoryModal__hour">{date.format('HH:mm')}</div>
                  <div className="absenceHistoryModal__day">{date.format('D MMM')}</div>
                </div>
                <div className="absenceHistoryModal__accent">
                  <div className="absenceHistoryModal__historyIcon">
                    <span className="material-icons">{getAbsenceHistoryIcon(item)}</span>
                  </div>
                </div>
                <div className="absenceHistoryModal__info">
                  <div className="absenceHistoryModal__user">{item.user_name || '-'}</div>
                  <div className="absenceHistoryModal__message">{getMessage(item)}</div>
                </div>
              </div>
            );
          })}
      </div>
    </MDKadroModal>
  );
};

AbsenceHistoryModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceHistoryModal.propTypes = {
  modalObject: PropTypes.shape({
    history: PropTypes.shape({
      history: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    item: PropTypes.shape({}),
    previousModalName: PropTypes.string,
  }),
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  replaceModal: PropTypes.func,
};

export default AbsenceHistoryModal;
