import { useMemo } from 'react';
import { Sector } from 'recharts';

import { CARBON, MILLION_GREY } from '@/constants/colors';
import { RADIAN } from '@/constants/reports';

import { calculateCoordinates } from './absencesChartShape.helpers';

type AbsencesChartShapeProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  payload: {
    name: string;
  };
  percent: number;
};

const AbsencesChartShape = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  percent,
}: AbsencesChartShapeProps) => {
  const { sx, sy, mx, my, ex, ey, textAnchor } = useMemo(
    () => calculateCoordinates(cx, cy, outerRadius, midAngle),
    [cx, cy, outerRadius, midAngle],
  );
  const pathCoordinates = `M${sx},${sy}L${mx},${my}L${ex},${ey}`;
  const textXCoordinates = ex + (Math.cos(-RADIAN * midAngle) >= 0 ? 1 : -1) * 12;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={pathCoordinates} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={textXCoordinates} y={ey} textAnchor={textAnchor} fill={CARBON}>
        {`${payload.name}`}
      </text>
      <text x={textXCoordinates} y={ey} dy={18} textAnchor={textAnchor} fill={MILLION_GREY}>
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default AbsencesChartShape;
