import { connect } from 'react-redux';

import { addShift } from '@/actions';
import { showModal } from '@/actions/uiState.js';

import ScheduleTableSingleRow from './ScheduleTableSingleRow.jsx';

const mapStateToProps = (state, ownProps) => ({
  employee: state.reducer.employees.data[ownProps.employeeId],
  mainDateStore: state.reducer.mainDateStore,
  employeeOvertimeCollections: state.reducer.overtimeCollections[ownProps.employeeId],
  employeeAbsences: state.reducer.absences.scheduleAbsences[ownProps.employeeId],
  employeeShifts: state.reducer.shifts.data[ownProps.employeeId]?.shifts,
  availabilitiesModeOn: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  employeeContracts: state.reducer.contracts[ownProps.employeeId],
  userJobTitles: state.reducer.userJobTitles,
  userEmployees: state.reducer.userEmployees,
});

const mapDispatchToProps = {
  showModal,
  addShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableSingleRow);
