import { connect } from 'react-redux';

import { runExport } from '@/actions/exports.js';
import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { NEW_EXPORT_MODAL } from '@/constants/modalTypes';

import NewExportModal from './NewExportModal';

const mapStateToProps = state => ({
  isModalVisible: state.reducer.uiState.showModal === NEW_EXPORT_MODAL,
  modalObject: state.reducer.uiState.modalObject[NEW_EXPORT_MODAL],
  userJobTitles: state.reducer.userJobTitles,
  userLocations: state.reducer.userLocations,
  employmentConditions: state.reducer.employmentConditions,
  userEmployees: state.reducer.userEmployees,
  selectedEmployeeId: state.reducer.singleEmployeeFilter.id,
  absenceTypes: state.reducer.absences.absencesTypes,
  userJobTitlesGrouped: state.reducer.jobTitlesGrouped,
  mainDateStore: state.reducer.mainDateStore,
  userId: state.reducer.currentUser.user.id,
  contracts: state.reducer.contracts,
});

const mapDispatchToProps = {
  runExport,
  hideModalAndLaterClearModalObject,
  startExport: runExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewExportModal);
