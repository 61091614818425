import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Select from '@/components/common/inputs/MDSelect/Select';
import MinuteHourInput from '@/components/common/inputs/MinuteHourInput';
import {
  generateTooltip,
  isSettingTypeAsShifts,
  TIME_INPUT_MODIFIERS,
} from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.helpers.jsx';
import { breakSettingTypes, newPayrollBreaksSettings } from '@/constants/payrollSettings';
import { getPayrollOptionSettings } from '@/utils/payrollHelpers';

import PayrollSettingsModalSettingRow from '../PayrollSettingsModalSettingRow/PayrollSettingsModalSettingRow';
import { messages } from './PayrollSettingsModalBreakSection.messages.js';

const PayrollSettingsModalBreakSection = ({ breaksValue, breaksSetting, changeSetting, payoutSetting }, { intl }) => {
  const breakTimeSettingLabel = useMemo(() => messages[`breaksTime${breaksSetting.type}`], [breaksSetting]);
  const isPayoutSettingAsShifts = isSettingTypeAsShifts(payoutSetting.type);
  const breakSettings = getPayrollOptionSettings(newPayrollBreaksSettings, isPayoutSettingAsShifts, intl);
  const reduceBreaksTooltip = generateTooltip('reduceBreaks', intl);

  return (
    <>
      <PayrollSettingsModalSettingRow
        label={messages.reduceBreaks}
        tooltip={reduceBreaksTooltip}
        settingInput={
          <Select
            onChange={value =>
              changeSetting(
                'breaksSetting',
                newPayrollBreaksSettings.find(s => s.id === value),
              )
            }
            defaultValue={breaksSetting.id}
            options={breakSettings}
            closeOnClick
          />
        }
        isForSelect
      />
      <PayrollSettingsModalSettingRow
        label={breakTimeSettingLabel}
        disabled={breaksSetting.type === breakSettingTypes.DO_NOT_INCLUDE_BREAK_TIME}
        settingInput={
          <MinuteHourInput
            value={breaksValue}
            min={0}
            max={90}
            step={1}
            onChangeFunc={e => changeSetting('breaksValue', e.target.value)}
            labelId="tolerance1"
            modifiers={TIME_INPUT_MODIFIERS}
          />
        }
      />
    </>
  );
};

PayrollSettingsModalBreakSection.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollSettingsModalBreakSection.propTypes = {
  breaksValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  breaksSetting: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  changeSetting: PropTypes.func,
  payoutSetting: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default PayrollSettingsModalBreakSection;
