import classnames from 'classnames';

import { ACCOUNT_SWITCHER_VIEW } from '@/constants/Permissions';
import { SIDEBAR_NARROW_OPEN_WIDTH, SIDEBAR_OPEN_WIDTH } from '@/constants/ui';
import { CurrentCompany } from '@/redux-store/currentCompany';
import { UiStateStoreState } from '@/redux-store/uiState';
import { UserPermissionsStoreState } from '@/redux-store/userPermissions';
import { User } from '@/types';
import { checkArrayIntersection } from '@/utils/arrayHelpers';
import { userHasPermissionsToRoute } from '@/utils/permissionHelpers';
import { allNavs, CHAT_PATH, FIRST_SECTION_NAVS_ID, NavigationItem } from '@/utils/routes';
import { getBem } from '@/utils/styles';

const checkSubViews = (
  { permissions, restrictions }: Pick<UserPermissionsStoreState, 'restrictions' | 'permissions'>,
  subRoutes?: NavigationItem['subRoutes'],
) => {
  if (!subRoutes) return true;

  return subRoutes.some(
    (subRoute) =>
      (!subRoute.permission || permissions.includes(subRoute.permission)) &&
      !checkArrayIntersection(restrictions, subRoute.restrictions),
  );
};

const canNavBeShown = (
  nav: NavigationItem,
  userPermissions: UserPermissionsStoreState,
  user: User,
  company: CurrentCompany,
) =>
  nav.icon &&
  userHasPermissionsToRoute(userPermissions, nav) &&
  (nav.restriction
    ? !nav.restriction.some((restriction) => userPermissions.restrictions.includes(restriction))
    : true) &&
  (nav.accessCheckFunction ? nav.accessCheckFunction(userPermissions, user, company) : true) &&
  checkSubViews(userPermissions, nav.subRoutes);

export const getSidebarNavs = (
  userPermissions: UserPermissionsStoreState,
  user: User,
  company: CurrentCompany,
  currentActiveNavBarElementId: NavigationItem['id'],
  unreadConversations: number,
): { firstSectionNavs: NavigationItem[]; secondSectionNavs: NavigationItem[] } =>
  allNavs(userPermissions.permissions).reduce(
    (prev, nav) => {
      if (!canNavBeShown(nav, userPermissions, user, company)) return prev;

      const newNav: NavigationItem = {
        ...nav,
        iconBadgeContent: nav.path === CHAT_PATH ? getChatBadgeContent(unreadConversations) : undefined,
        selected: nav.id === currentActiveNavBarElementId,
      };

      if (FIRST_SECTION_NAVS_ID.includes(nav.id))
        return { ...prev, firstSectionNavs: [...prev.firstSectionNavs, newNav] };

      return { ...prev, secondSectionNavs: [...prev.secondSectionNavs, newNav] };
    },
    { firstSectionNavs: [], secondSectionNavs: [] },
  );

export const setSidebarWidth = ({ permissions }: Pick<UserPermissionsStoreState, 'permissions'>) => {
  const width = permissions.includes(ACCOUNT_SWITCHER_VIEW) ? SIDEBAR_OPEN_WIDTH : SIDEBAR_NARROW_OPEN_WIDTH;
  document.documentElement.style.setProperty('--sideBarWidth', `${width}px`);
};

const sideBarBem = getBem('k-sideBar');
const sideBarMenuBem = getBem('k-sideBarMenu');

export const getClasses = (
  isMobileMenuOpen: boolean,
  showNotificationPanel: UiStateStoreState['showNotificationPanel'],
) =>
  ({
    nav: classnames(sideBarBem.block, (isMobileMenuOpen || showNotificationPanel) && sideBarBem.modifier('opened')),
    ul: classnames(sideBarBem.element('menu'), sideBarMenuBem.block, 'clearfix'),
    section: sideBarMenuBem.element('section'),
    spacer: sideBarMenuBem.element('spacer'),
  }) as const;

const getChatBadgeContent = (unreadConversations: number) => {
  if (!unreadConversations) return undefined;
  return unreadConversations > 9 ? '+9' : `${unreadConversations}`;
};
