import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import EntraIdButton from '@/components/common/Basic/EntraIdButton/EntraIdButton.redux.js';
import { AZURE_APP_CLIENT_ID, GOOGLE_CLIENT_ID } from '@/constants/envVariables';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers';

import Button from './common/Basic/Button.jsx';
import GoogleButton from './common/Basic/GoogleButton/GoogleButton.redux';
import MDCheckbox from './common/inputs/MDCheckbox/MDCheckbox.jsx';
import MDPasswordInput from './common/inputs/MDPasswordInput/MDPasswordInput.jsx';
import MDTextInput from './common/inputs/MDTextInput/MDTextInput.jsx';
import AppInMaintenance from './misc/AppInMaintenance/AppInMaintenance.redux.js';
import InactiveUser from './misc/InactiveUser/InactiveUser.redux.js';
import UnauthenticatedLayout from './misc/UnauthenticatedLayout/UnauthenticatedLayout.jsx';
import UnauthenticatedLayoutShowcase from './misc/UnauthenticatedLayoutShowcase/UnauthenticatedLayoutShowcase.redux.js';

const messages = defineMessages({
  email: {
    id: 'view.login.email',
    defaultMessage: 'E-mail',
  },
  password: {
    id: 'view.login.password',
    defaultMessage: 'Hasło',
  },
  rememberMe: {
    id: 'view.login.rememberMe',
    defaultMessage: 'Zapamiętaj mnie',
  },
});

class LoginView extends Component {
  static getAuthCodeFromHash(hash = window.location.hash) {
    const matched = hash.match(/auth-code=([^&]*)/);

    if (!matched) {
      return null;
    }

    const result = matched[1];

    if (!result) {
      return null;
    }

    return result;
  }

  constructor(props, context) {
    super(props, context);
    this.state = this.getInitialState();

    bindPrototypeFunctions(this);
  }

  componentWillMount() {
    const authCode = LoginView.getAuthCodeFromHash();
    if (authCode) {
      this.props.requestAuthenticateUserFromCode(authCode);
    }
  }

  handleInputChange(e) {
    const { target } = e;
    this.validateInput(e);
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    });
  }

  validateInput(event) {
    validateInputHelper(event).then(res => {
      const [name, error] = res;
      this.setState(prevState => ({
        errors: { ...prevState.errors, [name]: error ? this.context.intl.formatMessage(error, {}) : error },
      }));
    });
  }

  async validateAll() {
    const inputs = ['email', 'passwordLogin'];
    const promises = inputs.map(i => this.validateInput({ target: { name: i, value: this.state[i] } }));
    await Promise.all(promises);
    return !Object.values(this.state.errors).some(err => err !== '');
  }

  getInitialState() {
    return { email: '', passwordLogin: '', rememberMe: false, errors: {} };
  }

  formSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    return this.validateAll().then(valid => {
      if (!valid) return;
      const { email, passwordLogin, rememberMe } = this.state;
      this.props.authUser(email, passwordLogin, rememberMe);
      this.setState(this.getInitialState());
    });
  }

  async handleCreateAccount() {
    await this.props.startLogRocket();
  }

  render() {
    const hasAzureAppClientId = Boolean(AZURE_APP_CLIENT_ID);
    return (
      <div>
        <InactiveUser />
        <AppInMaintenance />
        <UnauthenticatedLayout showcase={<UnauthenticatedLayoutShowcase />}>
          <div className="k-loginView__welcomeMessage">
            <FormattedMessage id="login.view.welcomeMessage" defaultMessage="Zaloguj się" />
          </div>
          <form className="k-loginView" onSubmit={this.formSubmit}>
            <div className="k-loginView__form">
              <MDTextInput
                id="email"
                value={this.state.email}
                icon="k-icon-profile"
                label={this.context.intl.formatMessage(messages.email, {})}
                onChange={this.handleInputChange}
                errorMessage={this.state.errors.email}
                testId="emailInput"
              />
              <MDPasswordInput
                id="passwordLogin"
                value={this.state.passwordLogin}
                label={this.context.intl.formatMessage(messages.password, {})}
                onChange={this.handleInputChange}
                errorMessage={this.state.errors.passwordLogin}
                testId="passwordInput"
              />
              <div className="k-loginView__links">
                <FormattedMessage id="view.login.forgotPassword" defaultMessage="Nie pamiętasz hasła? " />
                <Link to="/password" className="k-loginView__forgotPassword">
                  <FormattedMessage id="view.login.remindPassword" defaultMessage="Przypomnij hasło" />
                </Link>
                <br />
              </div>
              <MDCheckbox
                id="rememberMe"
                value={this.state.rememberMe}
                text={this.context.intl.formatMessage(messages.rememberMe, {})}
                onChange={this.handleInputChange}
                testId="rememberMeCheckbox"
                reverse
              />
              <div className="k-loginView__loginButton">
                <Button type="submit" modifiers="orange high uppercase" testId="loginButton">
                  <FormattedMessage id="login" defaultMessage="Zaloguj" />
                </Button>
                {GOOGLE_CLIENT_ID && (
                  <>
                    <span className="k-loginView__loginOrText">
                      <FormattedMessage id="login.view.or" defaultMessage="lub" />
                    </span>
                    <GoogleButton login />
                  </>
                )}
                {hasAzureAppClientId && <EntraIdButton />}
              </div>
            </div>
          </form>
          <div className="k-loginView__createAccountContainer">
            <span className="k-loginView__createAccountIntro">Nie masz konta?</span>
            <Link to="newUser" onClick={this.handleCreateAccount} className="k-loginView__createAccount">
              <FormattedMessage id="view.login.createAccountFor" defaultMessage="Wypróbuj za darmo." />
            </Link>
          </div>
        </UnauthenticatedLayout>
      </div>
    );
  }
}

LoginView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

LoginView.propTypes = {
  authUser: PropTypes.func,
  requestAuthenticateUserFromCode: PropTypes.func,
  startLogRocket: PropTypes.func,
};

export default LoginView;
