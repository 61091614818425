import { conn } from '@/actions';

import { createActionName, createAppAsyncThunk } from '../utils';
import { locationGroupsSliceName } from './locationGroups.slice.ts';
import { LocationGroupsStoreState } from './locationGroups.types.ts';

export const getLocationGroups = createAppAsyncThunk(
  createActionName(locationGroupsSliceName, 'getLocationGroups'),
  async () => {
    const locationGroups = await conn.getLocationGroupsForUser();
    return locationGroups.data as LocationGroupsStoreState;
  },
);
