import { Employee, Location } from '@/types';
import { Shift, ShiftsData } from '@/types/shifts.types';

export const checkIsShiftForLocations = (locationIds: Location['id'][], shift?: Shift) =>
  locationIds.includes(shift?.location?.id);

export const checkHasEmployeeAnyShiftInLocations = (
  shiftsData: ShiftsData,
  shiftsIds: Shift['id'][],
  locationIds: Location['id'][],
  employeeId: Employee['id'],
) => shiftsIds.some(shiftId => checkIsShiftForLocations(locationIds, shiftsData[employeeId]?.shifts?.[shiftId]));

export const reduceShiftsInLocations = (
  shiftsData: ShiftsData,
  shiftsIds: Shift['id'][],
  locationIds: Location['id'][],
  employeeId: Employee['id'],
) =>
  shiftsIds.reduce((shifts, shiftId) => {
    const shift = shiftsData[employeeId]?.shifts?.[shiftId];
    return checkIsShiftForLocations(locationIds, shift) ? [...shifts, shift] : shifts;
  }, []);
