import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sendPanelTitle: {
    id: 'schedule.availabilities.sendPanel.header',
    defaultMessage: 'Zatwierdź dostępności',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Zatwierdź',
  },
  publishDetails: {
    id: 'schedule.availabilities.sendPanel.publishDetails',
    defaultMessage: 'Zatwierdzone zostaną dostępności dla wybranych lokalizacji w zakresie dat {start} - {end}.',
  },
});
