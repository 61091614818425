import {
  columnsPdfMessages,
  columnsXLSMessages,
  messages,
} from '@/components/common/exports/NewExportModal/NewExportModal.messages';
import { EXPORT_OPTIONS, EXPORTS_COLUMNS_VALUE } from '@/constants/exportConstants';
import { optionalArrayElement } from '@/utils/arrayHelpers';

export const getPayrollXlsOptions = (payoutSettingType, addUnfinalizedOption, intl) => [
  {
    id: 'freeDays',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.freeDays),
    tooltip: intl.formatMessage(messages.freeDaysTooltip),
  },
  {
    id: 'availabilities',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.availabilities),
    tooltip: intl.formatMessage(messages.availabilitiesTooltip),
  },
  {
    id: 'absences',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.absences),
    tooltip: intl.formatMessage(messages.absencesTooltip),
  },
  ...(addUnfinalizedOption
    ? [
        {
          id: 'unfinalized',
          type: EXPORT_OPTIONS.CHECKBOX,
          text: intl.formatMessage(messages.unfinalized),
          tooltip: intl.formatMessage(messages.unfinalizedTooltip),
        },
      ]
    : []),
  ...optionalArrayElement(
    {
      id: 'overtime',
      type: EXPORT_OPTIONS.CHECKBOX,
      text: intl.formatMessage(messages.overtime),
      tooltip: intl.formatMessage(messages.overtimeTooltip),
    },
    payoutSettingType !== 'shifts',
  ),
  {
    id: 'attendanceGPS',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.attendanceGPS),
    tooltip: intl.formatMessage(messages.attendanceGPSTooltip),
  },
  {
    id: 'columns',
    type: EXPORT_OPTIONS.MULTI_SELECT,
    items: getPayrollLocationXlsColumns(intl, payoutSettingType),
  },
];

export const getPayrollLocationXlsSumOptions = (payoutSettingType, intl) => [
  {
    id: 'availabilities',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.availabilities),
    tooltip: intl.formatMessage(messages.availabilitiesTooltip),
  },
  {
    id: 'absences',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.absences),
    tooltip: intl.formatMessage(messages.absencesTooltip),
  },
  ...optionalArrayElement(
    {
      id: 'showOvertimeColumns',
      type: EXPORT_OPTIONS.CHECKBOX,
      text: intl.formatMessage(messages.showOvertime),
      tooltip: intl.formatMessage(messages.showOvertimeTooltip),
    },
    payoutSettingType !== 'shifts',
  ),
];

export const getPayrollLocationPdfOptions = (payoutSettingType, intl) => [
  {
    id: 'columns',
    type: EXPORT_OPTIONS.LIST_SELECTION,
    items: getPayrollLocationPdfOptionsColumnItems(payoutSettingType, intl),
  },
];

export const getPayrollPdfOptions = (payoutSettingType, intl) => [
  {
    id: 'freeDays',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.freeDays),
    tooltip: intl.formatMessage(messages.freeDaysTooltip),
  },
  {
    id: 'addPlaceForSignature',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.addPlaceForSignature),
  },
  {
    id: 'addExtraStats',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.addExtraStats),
  },
  {
    id: 'columns',
    type: EXPORT_OPTIONS.LIST_SELECTION,
    items: getPayrollPdfOptionsColumnItems(payoutSettingType, intl),
  },
];

export const getEnovaOptions = intl => [
  {
    id: 'enovaAbsences',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.enovaAbsences),
  },
  {
    id: 'enovaWorkTime',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.enovaWorkTime),
  },
];

export const getWorktimeBreakdownOptions = intl => [
  {
    id: 'reverseStructure',
    type: EXPORT_OPTIONS.CHECKBOX,
    text: intl.formatMessage(messages.reverseStructure),
  },
];

export const getEcpExportOptions = () => [
  {
    id: 'absenceTypesToShow',
    dataKey: 'absenceTypes',
    type: EXPORT_OPTIONS.LIST_SELECTION,
  },
];

export const payrollLocationPdfColumns = intl => [
  {
    label: intl.formatMessage(columnsPdfMessages.employee),
    value: 'employee',
    active: true,
  },
  { label: intl.formatMessage(columnsPdfMessages.jobTitle), value: 'jobTitle', active: true },
  { label: intl.formatMessage(columnsPdfMessages.wage), value: 'wage', active: true },
  { label: intl.formatMessage(columnsPdfMessages.workTime), value: 'workTime', active: true },
  { label: intl.formatMessage(columnsPdfMessages.nightTime), value: 'nightTime', active: true },
  { label: intl.formatMessage(columnsPdfMessages.bonus), value: 'bonus', active: true },
  { label: intl.formatMessage(columnsPdfMessages.realization), value: 'realization', active: true },
  { label: intl.formatMessage(columnsPdfMessages.difference), value: 'difference', active: false },
  { label: intl.formatMessage(columnsPdfMessages.payout), value: 'payout', active: true },
  { label: intl.formatMessage(columnsPdfMessages.absence), value: 'absences', active: true },
];

export const payrollPdfColumns = intl => [
  { label: intl.formatMessage(columnsPdfMessages.day), value: 'day', active: true },
  { label: intl.formatMessage(columnsPdfMessages.typeOfDay), value: 'typeOfDay', active: false },
  { label: intl.formatMessage(columnsPdfMessages.location), value: 'location', active: true },
  { label: intl.formatMessage(columnsPdfMessages.jobTitle), value: 'jobTitle', active: false },
  { label: intl.formatMessage(columnsPdfMessages.wage), value: 'wage', active: false },
  { label: intl.formatMessage(columnsPdfMessages.fromToSchedule), value: 'fromToSchedule', active: false },
  { label: intl.formatMessage(columnsPdfMessages.fromTo), value: 'fromTo', active: false },
  { label: intl.formatMessage(columnsPdfMessages.availabilities), value: 'availabilities', active: false },
  { label: intl.formatMessage(columnsPdfMessages.workTime), value: 'workTime', active: true },
  { label: intl.formatMessage(columnsPdfMessages.nightTime), value: 'nightTime', active: true },
  { label: intl.formatMessage(columnsPdfMessages.bonus), value: 'bonus', active: false },
  { label: intl.formatMessage(columnsPdfMessages.realization), value: 'realization', active: true },
  { label: intl.formatMessage(columnsPdfMessages.difference), value: 'difference', active: false },
  { label: intl.formatMessage(columnsPdfMessages.payout), value: 'payout', active: false },
  { label: intl.formatMessage(columnsPdfMessages.breaks), value: 'breaks', active: false },
  { label: intl.formatMessage(columnsPdfMessages.absence), value: 'absences', active: false },
];

export const getPayrollLocationXlsColumns = (intl, payoutSettingType) => [
  { label: intl.formatMessage(columnsXLSMessages.firstName), value: EXPORTS_COLUMNS_VALUE.NAME, active: true },
  { label: intl.formatMessage(columnsXLSMessages.lastName), value: EXPORTS_COLUMNS_VALUE.LAST_NAME, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.referenceId),
    value: EXPORTS_COLUMNS_VALUE.REFERENCE_ID,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.employmentCondition),
    value: EXPORTS_COLUMNS_VALUE.EMPLOYMENT_CONDITION,
    active: true,
  },
  { label: intl.formatMessage(columnsXLSMessages.role), value: EXPORTS_COLUMNS_VALUE.ROLE, active: true },
  { label: intl.formatMessage(columnsXLSMessages.date), value: EXPORTS_COLUMNS_VALUE.DATE, active: true },
  { label: intl.formatMessage(columnsXLSMessages.weekday), value: EXPORTS_COLUMNS_VALUE.WEEKDAY, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.locationName),
    value: EXPORTS_COLUMNS_VALUE.LOCATION_NAME,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.locationExtraInfo),
    value: EXPORTS_COLUMNS_VALUE.LOCATION_EXTRA_INFO,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.jobTitleName),
    value: EXPORTS_COLUMNS_VALUE.JOB_TITLE_NAME,
    active: true,
  },
  { label: intl.formatMessage(columnsXLSMessages.wage), value: EXPORTS_COLUMNS_VALUE.WAGE, active: true },
  { label: intl.formatMessage(columnsXLSMessages.shiftStart), value: EXPORTS_COLUMNS_VALUE.SHIFT_START, active: true },
  { label: intl.formatMessage(columnsXLSMessages.shiftEnd), value: EXPORTS_COLUMNS_VALUE.SHIFT_END, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.plannedDuration),
    value: EXPORTS_COLUMNS_VALUE.PLANNED_DURATION,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.shiftDuration),
    value: EXPORTS_COLUMNS_VALUE.SHIFT_DURATION,
    active: true,
  },
  ...(payoutSettingType !== 'shifts'
    ? [
        {
          label: intl.formatMessage(columnsXLSMessages.attendanceStart),
          value: EXPORTS_COLUMNS_VALUE.ATTENDANCE_START,
          active: true,
        },

        {
          label: intl.formatMessage(columnsXLSMessages.attendanceEnd),
          value: EXPORTS_COLUMNS_VALUE.ATTENDANCE_END,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.attendanceDuration),
          value: EXPORTS_COLUMNS_VALUE.ATTENDANCE_DURATION,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.payrollStart),
          value: EXPORTS_COLUMNS_VALUE.PAYROLL_START,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.payrollEnd),
          value: EXPORTS_COLUMNS_VALUE.PAYROLL_END,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.payrollDuration),
          value: EXPORTS_COLUMNS_VALUE.PAYROLL_DURATION,
          active: true,
        },
      ]
    : []),
  {
    label: intl.formatMessage(columnsXLSMessages.accountedDuration),
    value: EXPORTS_COLUMNS_VALUE.ACCOUNTED_DURATION,
    active: true,
  },
  { label: intl.formatMessage(columnsXLSMessages.nightHours), value: EXPORTS_COLUMNS_VALUE.NIGHT_HOURS, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.bonusAmount),
    value: EXPORTS_COLUMNS_VALUE.BONUS_AMOUNT,
    active: true,
  },
  ...(payoutSettingType !== 'shifts'
    ? [
        {
          label: intl.formatMessage(columnsXLSMessages.diffDuration),
          value: EXPORTS_COLUMNS_VALUE.DIFF_DURATION,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.underworkedMinutes),
          value: EXPORTS_COLUMNS_VALUE.UNDERWORKED_MINUTES,
          active: true,
        },
      ]
    : []),
  {
    label: intl.formatMessage(columnsXLSMessages.breaksDuration),
    value: EXPORTS_COLUMNS_VALUE.BREAKS_DURATION,
    active: true,
  },
  ...(payoutSettingType !== 'shifts'
    ? [
        {
          label: intl.formatMessage(columnsXLSMessages.attendanceLabels),
          value: EXPORTS_COLUMNS_VALUE.ATTENDANCE_LABELS,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.overtime50),
          value: EXPORTS_COLUMNS_VALUE.OVERTIME_50,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.overtime100),
          value: EXPORTS_COLUMNS_VALUE.OVERTIME_100,
          active: true,
        },
        {
          label: intl.formatMessage(columnsXLSMessages.potentialOvertime),
          value: EXPORTS_COLUMNS_VALUE.POTENTIAL_OVERTIME,
          active: true,
        },
        { label: intl.formatMessage(columnsXLSMessages.fillUp), value: EXPORTS_COLUMNS_VALUE.FILL_UP, active: true },
      ]
    : []),
  { label: intl.formatMessage(columnsXLSMessages.payAmount), value: EXPORTS_COLUMNS_VALUE.PAY_AMOUNT, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.absenceName),
    value: EXPORTS_COLUMNS_VALUE.ABSENCE_NAME,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.absenceCode),
    value: EXPORTS_COLUMNS_VALUE.ABSENCE_CODE,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.absenceDuration),
    value: EXPORTS_COLUMNS_VALUE.ABSENCE_DURATION,
    active: true,
  },

  { label: intl.formatMessage(columnsXLSMessages.absences), value: EXPORTS_COLUMNS_VALUE.ABSENCES, active: true },
  {
    label: intl.formatMessage(columnsXLSMessages.attendanceGPS),
    value: EXPORTS_COLUMNS_VALUE.ATTENDANCE_GPS,
    active: true,
  },
  {
    label: intl.formatMessage(columnsXLSMessages.availabilities),
    value: EXPORTS_COLUMNS_VALUE.AVAILABILITIES,
    active: true,
  },
];

export const payrollPdfOvertimeColumns = intl => [
  {
    label: intl.formatMessage(columnsPdfMessages.overtime50),
    value: 'overtime50',
    active: false,
  },
  {
    label: intl.formatMessage(columnsPdfMessages.overtime100),
    value: 'overtime100',
    active: false,
  },
];

export const getPayrollPdfOptionsColumnItems = (payoutSettingType, intl) => {
  const optionsColumnForEcp = payrollPdfColumns(intl);
  if (payoutSettingType === 'shifts') return optionsColumnForEcp;
  return [...optionsColumnForEcp, ...payrollPdfOvertimeColumns(intl)];
};

export const getPayrollLocationPdfOptionsColumnItems = (payoutSettingType, intl) => {
  const optionsColumnForEcp = payrollLocationPdfColumns(intl);
  if (payoutSettingType === 'shifts') return optionsColumnForEcp;
  return [...optionsColumnForEcp, ...payrollPdfOvertimeColumns(intl)];
};
