import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import SearchBox from '@/components/common/inputs/SearchBox/SearchBox.jsx';
import Tooltip from '@/components/common/Tooltip/Tooltip.jsx';
import TopBarTemplatesFilter from '@/components/TopBars/filters/TopBarTemplatesFilter/TopBarTemplatesFilter.redux.js';
import { FILTER_ICON_NAME } from '@/constants/iconName.js';
import {
  AVAILABILITIES_DISPLAY_MODE_TYPE,
  POSITIONS_DISPLAY_MODE_TYPE,
  SCHEDULE_DISPLAY_MODE_TYPE,
  TEMPLATES_DISPLAY_MODE_TYPE,
} from '@/constants/scheduleDisplayModes.js';
import { DISPLAY_SETTINGS_SCHEDULE_KEYS } from '@/constants/settingsConstants';
import { DIRECTIONS } from '@/constants/ui';
import { SCHEDULE_TOOLTIP_OFFSET_Y } from '@/utils/schedule/scheduleStyles/scheduleStyles';

import ScheduleActionsButton from '../../Buttons/ScheduleActionsButton/ScheduleActionsButton.redux.js';
import ScheduleSettingsButton from '../../Buttons/ScheduleSettingsButton/ScheduleSettingsButton.redux.js';
import AutogenerateScheduleButton from './AutogenerateScheduleButton/AutogenerateScheduleButton.redux';
import QuickPlanningButtons from './QuickPlanningButtons/QuickPlanningButtons.redux';
import QuickPlanningToggle from './QuickPlanningToggle/QuickPlanningToggle.redux';
import { messages } from './ScheduleButtonBar.messages';
import ScheduleSelectingButtons from './ScheduleSelectingButtons/ScheduleSelectingButtons.redux';
import WorkingRuleButton from './WorkingRuleButton/WorkingRuleButton.redux';

export const ScheduleButtonBar = (
  {
    searchString,
    isQuickPlanningEnabled,
    refreshData,
    selectedDisplayModeType,
    workingRulesEnabled,
    scheduleSettings,
    changeScheduleSearchString,
    startCustomSorting,
    uiState,
    employeeSorting,
    changeEmployeesOrderForLocation,
    mainDateStore,
    isAutogenerateScheduleEnabled,
  },
  { intl },
) => {
  const isSchedule = selectedDisplayModeType === SCHEDULE_DISPLAY_MODE_TYPE;
  const isPositionsOrTemplates = [POSITIONS_DISPLAY_MODE_TYPE, TEMPLATES_DISPLAY_MODE_TYPE].includes(
    selectedDisplayModeType,
  );
  const areWorkingRulesActive = workingRulesEnabled;
  const isSearchBoxDisabled = ![SCHEDULE_DISPLAY_MODE_TYPE, AVAILABILITIES_DISPLAY_MODE_TYPE].includes(
    selectedDisplayModeType,
  );
  const areWorkingRulesVisible = areWorkingRulesActive && isSchedule;
  const sortedSettings = sortBy(scheduleSettings, el => intl.formatMessage(el.displayText));
  const filteredScheduleSettings = sortedSettings.filter(el => !DISPLAY_SETTINGS_SCHEDULE_KEYS.includes(el.type));
  const displaySettings = sortedSettings.filter(el => DISPLAY_SETTINGS_SCHEDULE_KEYS.includes(el.type));

  return (
    <ButtonBar>
      <ScheduleActionsButton />
      <ScheduleSettingsButton iconName="settings" scheduleSettings={filteredScheduleSettings} />
      <ScheduleSettingsButton
        iconName={FILTER_ICON_NAME}
        iconType="material-symbols"
        scheduleSettings={displaySettings}
      />
      <SearchBox
        hide={isPositionsOrTemplates}
        searchString={searchString}
        changeSearchString={changeScheduleSearchString}
        isDisabled={isSearchBoxDisabled}
      />
      <TopBarTemplatesFilter />
      {mainDateStore.dateMode !== 'day' && <QuickPlanningToggle />}
      {isQuickPlanningEnabled ? <QuickPlanningButtons /> : <ScheduleSelectingButtons />}
      <Tooltip
        yOffset={SCHEDULE_TOOLTIP_OFFSET_Y}
        position={DIRECTIONS.BOTTOM}
        content={intl.formatMessage(messages.refresh)}
      >
        <Button onClick={refreshData} modifiers="reverse-blue onlyIcon">
          <i className="material-icons">sync</i>
        </Button>
      </Tooltip>
      {!isPositionsOrTemplates &&
      employeeSorting.currentSorting.type === 'custom' &&
      uiState.rearrangingEmployeesOrder ? (
        <Button onClick={() => changeEmployeesOrderForLocation()} modifiers="blue small">
          <i className="material-icons">sort</i>
          <FormattedMessage id="saveSorting" defaultMessage="Zapisz" />
        </Button>
      ) : null}
      {!isPositionsOrTemplates &&
      employeeSorting.currentSorting.type === 'custom' &&
      !uiState.rearrangingEmployeesOrder ? (
        <Button onClick={() => startCustomSorting()} modifiers="blue small floatright">
          <i className="material-icons">sort</i>
          <FormattedMessage id="startSorting" defaultMessage="Sortuj" />
        </Button>
      ) : null}
      {areWorkingRulesVisible && <WorkingRuleButton isSchedule={isSchedule} />}
      {isAutogenerateScheduleEnabled && (
        <AutogenerateScheduleButton isSchedule={isSchedule} areWorkingRulesVisible={areWorkingRulesVisible} />
      )}
    </ButtonBar>
  );
};

ScheduleButtonBar.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleButtonBar.propTypes = {
  selectedDisplayModeType: PropTypes.string,
  workingRulesEnabled: PropTypes.bool,
  selectedRowsLocationsIds: PropTypes.shape({}),
  uiState: PropTypes.shape({
    selectedOpenShiftRowLocationId: PropTypes.string,
    rearrangingEmployeesOrder: PropTypes.bool,
  }),
  employeeSorting: PropTypes.shape({
    currentSorting: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  deleteOptions: PropTypes.shape({
    canDelete: PropTypes.bool,
  }),
  locationFilter: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  locationSettings: PropTypes.shape({}),
  changeEmployeesOrderForLocation: PropTypes.func,
  startCustomSorting: PropTypes.func,
  changeScheduleSearchString: PropTypes.func,
  searchString: PropTypes.string,
  isQuickPlanningEnabled: PropTypes.bool,
  scheduleSettings: PropTypes.arrayOf(PropTypes.shape({})),
  refreshData: PropTypes.func,
};

export default ScheduleButtonBar;
