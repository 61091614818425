import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { cloneElement } from 'react';

import TextInput from './TextInput.jsx';

const LabeledTextInput = props => {
  const { input: _, ...inputProps } = props;
  let { containerModifiers, modifiers } = props;
  containerModifiers = props.containerModifiers || [];
  if (!Array.isArray(containerModifiers)) {
    containerModifiers = containerModifiers.split(' ');
  }
  const className = classNames(
    'k-textInput__container',
    ...containerModifiers.map(modifier => `k-textInput__container--${modifier}`),
  );
  const classNameLabel = classNames({ labelTitle: props.labelDesc });
  if (props.modifiers && props.error) {
    if (typeof props.modifiers === 'string') {
      modifiers += ' error';
    } else {
      modifiers.push('error');
    }
  } else if (props.error) {
    modifiers = 'error';
  }

  return (
    (<div className={className}>
      <span className="k-textInput__label">
        <div className={classNameLabel}>{props.label}</div>
        {props.labelDesc ? <div className="labelDesc">{props.labelDesc}</div> : null}
      </span>
      {props.input ? (
        cloneElement(props.input, { ...inputProps, modifiers })
      ) : (
        <TextInput {...props} modifiers={modifiers} />
      )}
      {props.error ? <span className="k-textInput__errorMessage">{props.error}</span> : null}
    </div>)
  );
};

LabeledTextInput.propTypes = {
  input: PropTypes.node,
  inputProps: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  labelDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  containerModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
};

export default LabeledTextInput;
