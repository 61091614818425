import { connect } from 'react-redux';

import {
  exportEnovaAttendances,
  exportEnovaScheduleWork,
} from '@/actions/exports/enovaExports/payroll/payrollEnovaExports';
import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';
import { getAllOvertimeData, hideRedundantOvertimeStats } from '@/actions/overtime';
import { changePayrollSettings } from '@/actions/payrollSettings.js';
import { togglePayrollSettingsModal } from '@/actions/payrollUI.js';
import { calculateSpmhBasedBonuses } from '@/actions/spmhBasedBonuses';
import { showModal,toggleExportModal } from '@/actions/uiState.js';
import PayrollTableView from '@/components/payroll/PayrollTableView.jsx';

const mapStateToProps = state => ({
  currentUser: state.reducer.currentUser,
  userLocations: state.reducer.userLocations,
  userJobTitles: state.reducer.userJobTitles,
  userShiftblocks: state.reducer.userShiftblocks,
  userEmployees: state.reducer.userEmployees,
  userCustomTypes: state.reducer.userCustomTypes,
  payrollSettings: state.reducer.payrollSettings,
  singleEmployeeFilter: state.reducer.singleEmployeeFilter,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
  pathname: state.routing.pathname,
  userPermissions: state.reducer.userPermissions,
  visibleColumns: state.reducer.uiState.payrollVisibleColumns,
  overtimeCollections: state.reducer.overtimeCollections,
  absences: state.reducer.absences.scheduleAbsences,
  absenceTypes: state.reducer.absences.absencesTypes,
  showUnpaidAbsences: state.reducer.currentCompany.settings.show_unpaid_absences,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  toggleExportModal,
  togglePayrollSettingsModal,
  changePayrollSettings,
  showModal,
  getAllOvertimeData,
  calculateSpmhBasedBonuses,
  hideRedundantOvertimeStats,
  exportEnovaAttendances,
  exportEnovaScheduleWork,
};

const PayrollTableViewContainer = connect(mapStateToProps, mapDispatchToProps)(PayrollTableView);

export default PayrollTableViewContainer;
