import { defineMessages } from 'react-intl';

export default defineMessages({
  attendancesTableOptionsLocation: {
    id: 'attendancesTable.options.location',
    defaultMessage: 'Lokalizacja GPS',
  },
  attendancesTableOptionsEdit: {
    id: 'attendancesTable.options.edit',
    defaultMessage: 'Edytuj',
  },
  attendancesTableOptionsEditBlocked: {
    id: 'attendancesTable.options.editBlocked',
    defaultMessage: 'Edycja zablokowana',
  },
  attendancesTableOptionsHistory: {
    id: 'attendancesTable.options.history',
    defaultMessage: 'Zobacz historię',
  },
  attendancesTableOptionsPhotos: {
    id: 'attendancesTable.options.photos',
    defaultMessage: 'Zobacz zdjęcia',
  },
});
