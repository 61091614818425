import { EmployeeEmploymentCondition, EmploymentConditionsFilter } from '@/types/employmentConditions.types';

export const getSelectedEmploymentConditions = (
  employmentConditionsFilter: EmploymentConditionsFilter[],
  selectAllWhenEmpty: boolean,
  conditionsToChoose: EmployeeEmploymentCondition[],
) => {
  if (employmentConditionsFilter.length) {
    return typeof employmentConditionsFilter[0] === 'object'
      ? employmentConditionsFilter
      : employmentConditionsFilter.map(id => ({ id }));
  }
  return selectAllWhenEmpty ? conditionsToChoose : [];
};
