import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconButton from '@/components/common/Basic/IconButton.jsx';

import './AutoGenerateCreateScheduleLeftHeader.scss';

const AutoGenerateCreateScheduleLeftHeader = props => (
  <table className="k-autoGenerateCreateScheduleLeftHeader">
    <thead>
      <tr>
        <th>
          <div>
            <IconButton
              icon="block"
              popoverClassname="k-autoGenerateCreateScheduleLeftHeader__popover"
              buttonClassname="k-autoGenerateCreateScheduleLeftHeader__icon"
              onClick={props.clearDay}
              description={<FormattedMessage id="autoGenerate.createSchedule.clearButton" defaultMessage="Wyczyść" />}
            />
            <IconButton
              icon="content_paste"
              popoverClassname="k-autoGenerateCreateScheduleLeftHeader__popover"
              buttonClassname="k-autoGenerateCreateScheduleLeftHeader__icon"
              onClick={props.pasteDay}
              description={
                <FormattedMessage id="autoGenerate.createSchedule.pasteButton" defaultMessage="Wklej dzień" />
              }
            />
            <IconButton
              icon="file_copy"
              popoverClassname="k-autoGenerateCreateScheduleLeftHeader__popover"
              buttonClassname="k-autoGenerateCreateScheduleLeftHeader__icon"
              onClick={props.copyDay}
              description={
                <FormattedMessage id="autoGenerate.createSchedule.copyButton" defaultMessage="Skopiuj dzień" />
              }
            />
          </div>
        </th>
      </tr>
    </thead>
  </table>
);

AutoGenerateCreateScheduleLeftHeader.propTypes = {
  clearDay: PropTypes.func,
  pasteDay: PropTypes.func,
  copyDay: PropTypes.func,
};

export default AutoGenerateCreateScheduleLeftHeader;
