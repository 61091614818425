import { connect } from 'react-redux';

import KadroFeatureWrapper from './KadroFeatureWrapper.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

const KadroFeatureWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(KadroFeatureWrapper);

export default KadroFeatureWrapperContainer;
