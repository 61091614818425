import moment from 'moment';

import * as AT from '@/constants/ActionTypes';
import { extractShiftsFromReqeustData } from '@/utils/userEmployeesHelpers';

import { getShiftNumberPerLocation, subtractShiftNumberPerLocation } from './employees.helpers';
import { isEmptyObject } from '@/utils/baseHelpers';

const addInitDataToEmployee = (employee) => ({
  shifts: {},
  shifts_no: 0,
  numberOfShiftsPerLocation: {},
  attendances: [],
  availability_blocks: [],
  shifts_for_other_locations: [],
  overtimeStats: {},
  ...employee,
});

const mapPulledEmployees = (requestData) =>
  requestData.map(addInitDataToEmployee).reduce((obj, employee) => ({ ...obj, [employee.id]: employee }), {});

const initialState = {
  data: {},
};

const groupPerDateAndSort = (items) => {
  const result = {};
  Object.values(items).forEach((item) => {
    const date = moment(item.date).format('YYYY-MM-DD');
    if (result[date]) {
      result[date].push(item.id);
    } else {
      result[date] = [item.id];
    }
  });
  return result;
};

const employees = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.GET_EMPLOYEES_SUCCESFUL: {
      return { ...state, data: mapPulledEmployees(payload) };
    }
    case AT.ADD_NEW_EMPLOYEE: {
      const employee = payload;
      return { ...state, data: { ...state.data, [employee.id]: addInitDataToEmployee(employee) } };
    }
    case AT.UPDATE_EMPLOYEE: {
      const employee = payload;
      return {
        ...state,
        data: { ...state.data, [employee.id]: addInitDataToEmployee({ ...state.data[employee.id], ...employee }) },
      };
    }
    case AT.UPDATE_EMPLOYEE_MASS_SUCCESS: {
      const updatedEmployees = action.payload.employees;

      const newData = Object.entries(state.data).reduce((acc, [employeeId, employeeData]) => {
        const updatedEmployee = updatedEmployees.find(({ id }) => id === employeeId);
        if (updatedEmployee && !isEmptyObject(updatedEmployee)) {
          return { ...acc, [employeeId]: { ...employeeData, ...updatedEmployee } };
        }
        return { ...acc, [employeeId]: employeeData };
      }, {});

      return { ...state, data: newData };
    }
    case AT.DELETE_EMPLOYEE: {
      const newData = { ...state.data };
      delete newData[payload];
      return { ...state, data: newData };
    }
    case AT.DELETE_EMPLOYEE_SET: {
      const newData = { ...state.data };
      payload.forEach((id) => {
        delete newData[id];
      });
      delete newData[payload];
      return { ...state, data: newData };
    }
    case AT.GET_SCHEDULE_SUCCESFUL: {
      const scheduleData = extractShiftsFromReqeustData(payload);
      const newData = {};
      Object.keys(state.data).forEach((id) => {
        newData[id] = {
          ...state.data[id],
          shifts: scheduleData[id] ? groupPerDateAndSort(scheduleData[id].shifts) : [],
          shifts_no: scheduleData[id] ? Object.keys(scheduleData[id].shifts).length : 0,
          availability_blocks: scheduleData[id] ? groupPerDateAndSort(scheduleData[id].availability_blocks) : [],
          numberOfShiftsPerLocation: scheduleData[id] ? getShiftNumberPerLocation(scheduleData[id].shifts) : {},
        };
      });
      return { ...state, data: newData };
    }
    case AT.CLOSE_TRADE_SHIFT_SUCCESFUL: {
      const fromUserId = payload.tradeShift.user.id;
      const toUserId = payload.employeeId;
      const { date } = payload.tradeShift;
      const locationId = payload.tradeShift.location.id;
      return {
        ...state,
        data: {
          ...state.data,
          [fromUserId]: {
            ...state.data[fromUserId],
            shifts_no: state.data[fromUserId].shifts_no - 1,
            shifts: {
              ...state.data[fromUserId].shifts,
              [date]: state.data[fromUserId].shifts[date].filter((s) => s !== payload.tradeShift.shift.id),
            },
            numberOfShiftsPerLocation: {
              ...state.data[fromUserId].numberOfShiftsPerLocation,
              [locationId]: state.data[fromUserId].numberOfShiftsPerLocation[locationId] - 1,
            },
          },
          [toUserId]: {
            ...state.data[toUserId],
            shifts_no: state.data[toUserId].shifts_no + 1,
            shifts: {
              ...state.data[toUserId].shifts,
              [date]: [...(state.data[toUserId].shifts[date] || []), payload.tradeShift.shift.id],
            },
            numberOfShiftsPerLocation: {
              ...state.data[toUserId].numberOfShiftsPerLocation,
              [locationId]: (state.data[toUserId].numberOfShiftsPerLocation[locationId] || 0) + 1,
            },
          },
        },
      };
    }
    case AT.UPDATE_LOCATION: {
      const newData = {};
      Object.keys(state.data).forEach((id) => {
        newData[id] = {
          ...state.data[id],
          locations: state.data[id].locations.map((l) => (l.id === payload.id ? payload : l)),
        };
      });
      return { ...state, data: newData };
    }
    case AT.GET_SCHEDULE_STATS: {
      const newData = {};
      payload.forEach((stat) => {
        newData[stat.employee_id] = {
          ...state.data[stat.employee_id],
          scheduleStats: stat.scheduleStats,
        };
      });
      return { ...state, data: newData };
    }
    case AT.UPDATE_EMPLOYMENT_CONDITION:
    case AT.DELETE_EMPLOYMENT_CONDITION: {
      const newData = {};
      Object.keys(state.data).forEach((id) => {
        const newEmploymentCondition = action.payload.updatedEmploymentConditions.find(
          (condition) => condition.user_id === id,
        );
        if (newEmploymentCondition) {
          newData[id] = {
            ...state.data[id],
            employment_conditions: newEmploymentCondition,
          };
        } else {
          newData[id] = state.data[id];
        }
      });
      return { ...state, data: newData };
    }
    case AT.ADD_MASS_SHIFTS_SUCCESFUL:
    case AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS: {
      const newData = { ...state.data };
      payload.forEach((shift) => {
        newData[shift.employee.id] = {
          ...newData[shift.employee.id],
          shifts: {
            ...newData[shift.employee.id].shifts,
            [shift.date]: [...(newData[shift.employee.id].shifts[shift.date] || []), shift.id],
          },
        };
      });
      return {
        ...state,
        data: newData,
      };
    }
    case AT.ADD_SHIFT_SUCCESFUL:
    case AT.ADD_SHIFT_TO_EMPLOYEE:
    case AT.ADD_SHIFT_FAILURE:
    case AT.DELETE_SHIFT: {
      if (action.payload.isLoanedEmployee) return state;
      let shiftDiff = 0;
      if ([AT.ADD_SHIFT_FAILURE, AT.DELETE_SHIFT].includes(action.type)) {
        shiftDiff = -1;
      } else if (action.type === AT.ADD_SHIFT_TO_EMPLOYEE) {
        shiftDiff = 1;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [payload.employee_id]: {
            ...state.data[payload.employee_id],
            shifts_no: state.data[payload.employee_id].shifts_no + shiftDiff,
            shifts: employeeShifts(state.data[payload.employee_id].shifts, action),
            numberOfShiftsPerLocation: {
              ...state.data[payload.employee_id].numberOfShiftsPerLocation,
              [payload.shift.location.id]:
                (state.data[payload.employee_id].numberOfShiftsPerLocation[payload.shift.location.id] || 0) + shiftDiff,
            },
          },
        },
      };
    }
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL: {
      const newData = { ...state.data };
      Object.keys(action.payload).forEach((employeeId) => {
        if (!newData[employeeId]) return;
        const dataForEmployee = action.payload[employeeId];
        const newShifts = { ...newData[employeeId].shifts };
        dataForEmployee.forEach((item) => {
          newShifts[item.date] = (newShifts[item.date] || []).filter((i) => i !== item.shiftId);
        });
        newData[employeeId] = {
          ...newData[employeeId],
          shifts: newShifts,
          shifts_no: newData[employeeId].shifts_no - dataForEmployee.length,
          numberOfShiftsPerLocation: subtractShiftNumberPerLocation(
            newData[employeeId].numberOfShiftsPerLocation,
            dataForEmployee,
          ),
        };
      });
      return { ...state, data: newData };
    }
    case AT.DELETE_AVAILABILITIES_SUCCESFUL: {
      const newData = { ...state.data };
      Object.keys(newData).forEach((employeeId) => {
        Object.keys(newData[employeeId].availability_blocks).forEach((date) => {
          const availabilityBlockId = newData[employeeId].availability_blocks[date]?.[0];
          if (action.payload.includes(availabilityBlockId)) {
            delete newData[employeeId].availability_blocks[date];
          }
        });
      });
      return { ...state, data: newData };
    }
    case AT.ADD_SHIFTS_TO_EMPLOYEES: {
      const newEmployeesState = action.payload.reduce((result, shift) => {
        const originalShiftsNo = state.data[shift.employee.id]?.shifts_no;
        const updatedShiftsNo = state.data[shift.employee.id]?.shifts_no;
        const newestShiftsNo = updatedShiftsNo || updatedShiftsNo === 0 ? updatedShiftsNo : originalShiftsNo;
        const originalShifts = state.data[shift.employee.id]?.shifts;
        const updatedShifts = state.data[shift.employee.id]?.shifts;
        const newestShifts = updatedShifts || originalShifts;
        const currentShiftsForDate = state[shift.date] || [];

        return {
          ...result,
          [shift.employee.id]: {
            ...state.data[shift.employee.id],
            shifts_no: newestShiftsNo + 1,
            shifts: {
              ...newestShifts,
              [shift.date]: [...currentShiftsForDate, shift.id],
            },
            numberOfShiftsPerLocation: {
              ...state.data[shift.employee.id].numberOfShiftsPerLocation,
              [shift.location.id]:
                (state.data[shift.employee.id].numberOfShiftsPerLocation[shift.location.id] || 0) + 1,
            },
          },
        };
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...newEmployeesState,
        },
      };
    }
    case AT.ADD_SHIFTS_SUCCESFUL: {
      const newEmployeesState = action.newShifts.reduce((result, shift) => {
        const originalShifts = state.data[shift.employee.id]?.shifts;
        const updatedShifts = state.data[shift.employee.id]?.shifts;
        const newestShifts = updatedShifts || originalShifts;
        const currentShiftsForDate = (state[shift.date] || []).filter((s) => action.uuids.includes(s.id));

        return {
          ...result,
          [shift.employee.id]: {
            ...state.data[shift.employee.id],
            shifts_no: currentShiftsForDate.length + 1,
            shifts: {
              ...newestShifts,
              [shift.date]: [...currentShiftsForDate, shift.id],
            },
            numberOfShiftsPerLocation: {
              ...state.data[shift.employee.id].numberOfShiftsPerLocation,
              [shift.location.id]:
                (state.data[shift.employee.id].numberOfShiftsPerLocation[shift.location.id] || 0) + 1,
            },
          },
        };
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...newEmployeesState,
        },
      };
    }
    case AT.DELETE_AVAILABILITY_SUCCESFUL:
    case AT.ADD_AVAILABILITY_SUCCESFUL: {
      const employeeId = payload.employee_id || payload.employee.id;
      return {
        ...state,
        data: {
          ...state.data,
          [employeeId]: {
            ...state.data[employeeId],
            availability_blocks: employeeAvailabilities(state.data[employeeId].availability_blocks, action),
          },
        },
      };
    }
    case AT.DELETE_LOCATION: {
      const newData = Object.values(state.data).reduce((acc, employee) => {
        const { id, locations, ...restOfEmployee } = employee;
        let employeeHadDeletedLocation = false;

        const filteredEmployeeLocations = locations.filter(({ id }) => {
          const isDeletedLocation = id === payload;
          if (isDeletedLocation) {
            employeeHadDeletedLocation = true;
          }
          return !isDeletedLocation;
        });

        if (employeeHadDeletedLocation)
          return { ...acc, [id]: { ...restOfEmployee, id, locations: filteredEmployeeLocations } };
        return { ...acc, [id]: employee };
      }, {});
      return { ...state, data: newData };
    }

    case AT.ADD_MASS_EMPLOYEES: {
      const newEmployees = action.payload.reduce(
        (employeesAcc, employee) => ({ ...employeesAcc, [employee.id]: addInitDataToEmployee(employee) }),
        {},
      );

      return { ...state, data: { ...state.data, ...newEmployees } };
    }
    default:
      return state;
  }
};

const employeeShifts = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.ADD_SHIFT_TO_EMPLOYEE: {
      const currentShiftsForDate = state[payload.shift.date] || [];
      return { ...state, [payload.shift.date]: [...currentShiftsForDate, payload.shift.id] };
    }
    case AT.ADD_SHIFT_SUCCESFUL: {
      return {
        ...state,
        [payload.shift.date]: [...state[payload.shift.date].filter((i) => i !== payload.uid), payload.new_shift.id],
      };
    }
    case AT.ADD_SHIFT_FAILURE:
    case AT.DELETE_SHIFT: {
      const newShiftsForDate = state[payload.shift.date].filter((i) => i !== payload.shift.id);
      if (newShiftsForDate.length !== state[payload.shift.date].length) {
        return {
          ...state,
          [payload.shift.date]: newShiftsForDate,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const employeeAvailabilities = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.ADD_AVAILABILITY_SUCCESFUL: {
      const currentAvailabilities = state[payload.date] || [];
      return { ...state, [payload.date]: [...currentAvailabilities, payload.id] };
    }
    case AT.DELETE_AVAILABILITY_SUCCESFUL: {
      const { date } = payload.availabilityObject;
      if (!state[date]) return state;
      const newAvailabilitiesForDate = state[date].filter((i) => i !== payload.availabilityObject.id);
      if (newAvailabilitiesForDate.length !== state[date].length) {
        return {
          ...state,
          [date]: newAvailabilitiesForDate,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default employees;
