import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';
import { exportData } from '@/actions/exports.js';
import { importRecommendedScheduleFile } from '@/actions/import';
import { addRecommendedSchedule, deleteRecommendedSchedule } from '@/actions/recommendedSchedule.js';
import { hideModal } from '@/actions/uiState';
import { RECOMMENDED_SCHEDULE_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportRecommendedScheduleModal from './MDImportRecommendedScheduleModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === RECOMMENDED_SCHEDULE_IMPORT_MODAL,
  selectedLocations: state.reducer.scheduleLocationFilter,
  mainDateStore: state.reducer.mainDateStore,
});

const mapDispatchToProps = {
  hideModal,
  showConfirmModal,
  exportData,
  importRecommendedScheduleFile,
  addRecommendedSchedule,
  deleteRecommendedSchedule,
};

const ImportBudgetModalContainer = connect(mapStateToProps, mapDispatchToProps)(ImportRecommendedScheduleModal);

export default ImportBudgetModalContainer;
