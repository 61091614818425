import { connect } from 'react-redux';

import SidebarShiftContent from './SidebarShiftContent.jsx';

const mapStateToProps = (state, ownProps) => ({
  mainDateStore: state.reducer.mainDateStore,
  userCustomTypes: state.reducer.userCustomTypes,
  absences: state.reducer.absences.scheduleAbsences[ownProps.employee?.id],
  userJobTitles: state.reducer.userJobTitles,
  showUnpaidAbsences: state.reducer.currentCompany.settings.show_unpaid_absences,
  absenceTypes: state.reducer.absences.absencesTypes,
  employeeContracts: state.reducer.contracts[ownProps.employee?.id],
});

const SidebarShiftContentContainer = connect(mapStateToProps)(SidebarShiftContent);

export default SidebarShiftContentContainer;
