import moment from 'moment';

import { SelectedJobTitles } from '@/types/jobTitles.types';
import { LoanEmployeesProposals } from '@/types/loanEmployeesProposals';

export const getVisibleLoanEmployeesProposals = (
  loanEmployeesProposals: LoanEmployeesProposals[],
  date: string,
  selectedJobTitlesIds: string[],
) =>
  loanEmployeesProposals.filter(loanEmployeesProposal => {
    const startDate = moment(loanEmployeesProposal.start_timestamp).format('YYYY-MM-DD');
    const isSelectedJobTitle = selectedJobTitlesIds.includes(loanEmployeesProposal.job_title_id);

    return startDate === date && isSelectedJobTitle;
  });

export const mapVisibleLoanEmployeesProposals = (
  visibleLoanEmployeesProposals: LoanEmployeesProposals[],
  date: string,
  selectedJobTitles: SelectedJobTitles[],
) =>
  visibleLoanEmployeesProposals.map(visibleLoanEmployeesProposal => {
    const jobTitle = selectedJobTitles.find(({ id }) => id === visibleLoanEmployeesProposal.job_title_id).title;
    return { ...visibleLoanEmployeesProposal, date, jobTitle };
  });
