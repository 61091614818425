import { connect } from 'react-redux';

import { ADD_POSITION_SHIFT_MODAL } from '@/constants/modalTypes.js';

import EmployeesForPosition from './EmployeesForPosition.jsx';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[ADD_POSITION_SHIFT_MODAL],
  userEmployees: state.reducer.userEmployees,
  userCustomTypes: state.reducer.userCustomTypes,
  contracts: state.reducer.contracts,
});

export default connect(mapStateToProps)(EmployeesForPosition);
