import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  export: {
    id: 'newExportModal.export',
    defaultMessage: 'Eksport',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Dalej',
  },
  back: {
    id: 'common.back',
    defaultMessage: 'Wstecz',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Anuluj',
  },
  pdfError: {
    id: 'common.pdfError',
    defaultMessage: 'Wybierz maksymalnie 31 dniowy okres, aby pobrać eksport w formace PDF.',
  },
});
