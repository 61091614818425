import { connect } from 'react-redux';

import ScheduleBudgetTable from './ScheduleBudgetTable.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.mainDateStore,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  scheduleUIState: state.reducer.scheduleUIState,
  budgetEstimates: state.reducer.budgetEstimates.estimates,
  jobtitleFilter: state.reducer.jobtitleFilter,
  recommendedSchedule: state.reducer.recommendedSchedule.recommendedSchedule,
  userPermissions: state.reducer.userPermissions,
  scheduleState: state.reducer.scheduleState,
  userEmployees: state.reducer.userEmployees,
  contracts: state.reducer.contracts,
});

const ScheduleBudgetTableContainer = connect(mapStateToProps)(ScheduleBudgetTable);

export default ScheduleBudgetTableContainer;
