import * as AT from '@/constants/ActionTypes';
import { checkShiftOverlap } from '@/utils/schedulerHelpers';
import { getWorkingDaysShifts } from '@/utils/shiftHelpers';

import { addObjWithRepeat, conn, connectionError, showConfirmModal } from '../index';
import { messages } from './employeeTimesheet.messages';

const deleteCurrentUserShiftsError = err => (dispatch, getState, intl) => {
  dispatch(connectionError(err));
  dispatch({
    type: AT.DELETE_SHIFTS_FOR_CURRENT_USER_ERROR,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.deleleUserShiftsErrorDescription),
    },
  });
};

const deleteCurrentUserShiftsSuccess = (shiftIdsToDelete, employeeId) => (dispatch, getState, intl) => {
  const itemsToDelete = shiftIdsToDelete.map(id => ({ shiftId: id }));
  const notificationDescription =
    shiftIdsToDelete.length === 1 ? 'deleleUserOneShiftSuccessDescription' : 'deleleUserManyShiftsSuccessDescription';

  dispatch({
    type: AT.DELETE_SHIFTS_FOR_CURRENT_USER_SUCCESS,
    payload: { [employeeId]: itemsToDelete },
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages[notificationDescription]),
    },
  });
};

const deleteCurrentUserShifts = (shiftIdsToDelete, employeeId) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .deleteCurrentUserShifts(shiftIdsToDelete)
      .then(() => {
        dispatch(deleteCurrentUserShiftsSuccess(shiftIdsToDelete, employeeId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteCurrentUserShiftsError(err));
        reject(err);
      });
  });

export const showDeleteCurrentUserShiftConfirmModal = (shiftIdsToDelete, employeeId) => (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleleUserShiftModalTitle),
      description: intl.formatMessage(messages.deleleUserShiftModalDescription),
      confirmFunc: () => dispatch(deleteCurrentUserShifts(shiftIdsToDelete, employeeId)),
    }),
  );
};

const validateShifts = shifts => (dispatch, getState) => {
  const { userEmployees, currentUser, settings } = getState().reducer;
  const employee = userEmployees.find(({ id }) => currentUser.user.id === id);
  const shiftsIds = shifts.map(({ id }) => id);
  let errorCode = null;
  shifts.some(shift => {
    if (checkShiftOverlap(employee, shift, shiftsIds)) {
      errorCode = 'employeeIsBusy';
      return true;
    }
    const shiftLocationSettings = settings.locationSettings[shift.location.id];
    const disabledDate = shiftLocationSettings.disable_location_schedule_shifts_edit_until;
    if (disabledDate && shift.date < disabledDate) {
      errorCode = 'disabledDate';
      return true;
    }
    return false;
  });
  return errorCode;
};

const addCurrentUserShiftsSuccess = newShifts => (dispatch, getState, intl) => {
  const { id: employeeId } = getState().reducer.currentUser.user;
  const notificationDescription =
    newShifts.length === 1 ? 'addUserOneShiftSuccessDescription' : 'addUserManyShiftsSuccessDescription';
  dispatch({
    type: AT.ADD_SHIFTS_FOR_CURRENT_USER_SUCCESS,
    payload: { employee_id: employeeId, newShifts },
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages[notificationDescription]),
    },
  });
};

const addCurrentUserShiftsError = err => (dispatch, getState, intl) => {
  dispatch(connectionError(err));
  dispatch({
    type: AT.ADD_SHIFTS_FOR_CURRENT_USER_ERROR,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.addUserShiftsErrorDescription),
    },
  });
};

export const addCurrentUserShifts = shifts => dispatch =>
  new Promise((resolve, reject) => {
    const validationError = dispatch(validateShifts(shifts));
    if (validationError) return reject({ errorCode: validationError });
    conn
      .addCurrentUserShifts(shifts)
      .then(res => {
        dispatch(addCurrentUserShiftsSuccess(res.data));
        resolve();
      })
      .catch(err => {
        dispatch(addCurrentUserShiftsError(err));
        reject(err);
      });
  });

export const addCurrentUserShiftsWithRepeat = (shift, repeatObj) => (dispatch, getState) => {
  const { scheduleUIState, calendarData } = getState().reducer;
  const shiftsToAdd = scheduleUIState.nonWorkingDays
    ? getWorkingDaysShifts(addObjWithRepeat(shift, repeatObj), calendarData.holidays)
    : addObjWithRepeat(shift, repeatObj);
  return dispatch(addCurrentUserShifts(shiftsToAdd));
};

const editCurrentUserShiftsSuccess = newShiftObject => (dispatch, getState, intl) => {
  const { id: employeeId } = getState().reducer.currentUser.user;
  dispatch({
    type: AT.EDIT_SHIFT_FOR_CURRENT_USER_SUCCESS,
    payload: { employee_id: employeeId, newShiftObject },
    notification: {
      type: 'success',
      title: intl.formatMessage(messages.success),
      description: intl.formatMessage(messages.editUserShiftSuccessDescription),
    },
  });
};

const editCurrentUserShiftsError = err => (dispatch, getState, intl) => {
  dispatch(connectionError(err));
  dispatch({
    type: AT.EDIT_SHIFT_FOR_CURRENT_USER_ERROR,
    notification: {
      type: 'error',
      title: intl.formatMessage(messages.error),
      description: intl.formatMessage(messages.editUserShiftErrorDescription),
    },
  });
};

export const editCurrentUserShift = shift => dispatch =>
  new Promise((resolve, reject) => {
    const validationError = dispatch(validateShifts([shift]));
    if (validationError) return reject({ errorCode: validationError });
    conn
      .editCurrentUserShift(shift)
      .then(res => {
        dispatch(editCurrentUserShiftsSuccess(res.data));
        resolve();
      })
      .catch(err => {
        dispatch(editCurrentUserShiftsError(err));
        reject(err);
      });
  });
