import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sendPanelTitle: {
    id: 'schedule.templates.sendPanel.header',
    defaultMessage: 'Zatwierdź zmiany',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Zatwierdź',
  },
  templateName: {
    id: 'schedule.templates.sendPanel.templateName',
    defaultMessage: 'Nazwa szablonu',
  },
  oneShift: {
    id: 'schedule.templates.sendPanel.draftShiftsNumber.one',
    defaultMessage: 'zmiana w aktualnym grafiku.',
  },
  fewShifts: {
    id: 'schedule.templates.sendPanel.draftShiftsNumber.few',
    defaultMessage: 'zmiany w aktualnym grafiku.',
  },
  otherShiftsNumber: {
    id: 'schedule.templates.sendPanel.draftShiftsNumber.other',
    defaultMessage: 'zmian w aktualnym grafiku.',
  },
});
