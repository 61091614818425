import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { EXPORT_OPTIONS, EXPORT_STEPS, EXPORTS_FILTERS } from '@/constants/exportConstants';
import { EXPORTS_TYPES } from '@/constants/exportFilesFormats';
import { useInputChange, useMultiSelect, useStepsCounter } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import { getColumnsForFormat, getUpdatedOptions } from '../exports.helpers';
import { messages } from '../exports.messages';
import {
  canContinue,
  DEFAULT_EXPORTS_STEPS_COUNT,
  displayRelevantStep,
  getAbsenceTypesOptions,
  getEmployeesOptions,
  getEmploymentConditionsOptions,
  getExportPayload,
  getFilteredEmployeesOptions,
  getInitialState,
  getJobTitlesOptions,
  getLocationsOptions,
  getRelevantEmploymentConditionsOptions,
  getRelevantLocationsOptions,
  MODAL_MODIFIERS,
  saveDataInBrowser,
  saveFiltersInBrowser,
  validateRangeForExports,
} from './NewExportModal.helpers';

const NewExportModal = (props, { intl }) => {
  const {
    userJobTitles,
    userLocations,
    userEmployees,
    employmentConditions,
    absenceTypes,
    isModalVisible,
    modalObject,
    hideModalAndLaterClearModalObject,
    startExport,
    userJobTitlesGrouped,
    userId,
    contracts,
  } = props;
  const [state, handlers] = useInputChange(getInitialState(props), intl);
  const { modalExportId, options, selectedRange } = state;
  const initialStepsCount = props?.modalObject?.formats?.find(({ exportId }) => exportId === modalExportId)?.steps
    ?.length;
  const {
    currentStep,
    stepsCount,
    isLastStep,
    handlers: stepsHandlers,
  } = useStepsCounter(initialStepsCount || DEFAULT_EXPORTS_STEPS_COUNT);
  const [locationsItems, locationsHandlers] = useMultiSelect(getLocationsOptions(userLocations));
  const jobTitlesOptions = getJobTitlesOptions(userJobTitles, locationsItems, userJobTitlesGrouped);

  const [jobTitlesItems, jobTitlesHandlers] = useMultiSelect(jobTitlesOptions);
  const [employmentConditionsItems, employmentConditionsHandlers] = useMultiSelect(
    getEmploymentConditionsOptions(employmentConditions),
  );

  const [employeesItems, employeesHandlers] = useMultiSelect(getEmployeesOptions(userEmployees));
  const [absenceTypesItems, absenceTypesHandlers] = useMultiSelect(getAbsenceTypesOptions(absenceTypes));
  const [columnsItems, columnsSelectionHandlers] = useMultiSelect([]);

  useEffect(() => {
    jobTitlesHandlers.setSelectedOptions(jobTitlesOptions);
  }, [locationsItems]);

  useEffect(() => {
    if (!isModalVisible) return;
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(EXPORTS_FILTERS)) || {};

    const isDataInLocalStorage = dataFromLocalStorage?.[props.userId]?.[modalExportId];

    stepsHandlers.setInitialValues();
    if (!isEmpty(isDataInLocalStorage)) {
      const { selectedRange: selectedRangeFromLocalStorage } = dataFromLocalStorage[props.userId][modalExportId];
      handlers.updateState({ ...getInitialState(props), selectedRange: selectedRangeFromLocalStorage });
    } else {
      handlers.updateState(getInitialState(props));
    }
  }, [isModalVisible]);

  useEffect(() => {
    const selectedExport = modalObject?.formats?.find(({ exportId }) => exportId === modalExportId);
    handlers.changeInput(createEvent('options', getUpdatedOptions(selectedExport)));
    stepsHandlers.setStepsCount(selectedExport?.steps?.length || DEFAULT_EXPORTS_STEPS_COUNT);
    columnsSelectionHandlers.setItems(getColumnsForFormat(selectedExport));
  }, [modalExportId]);

  useEffect(() => {
    if (!isModalVisible) return;
    const dataFromLocalStorage = JSON.parse(localStorage.getItem(EXPORTS_FILTERS)) || {};
    const isSelectingFormatSteps = modalObject?.formats.some(({ steps }) =>
      steps.includes(EXPORT_STEPS.SELECTING_FORMAT),
    );
    const exportId = isSelectingFormatSteps ? modalExportId : props.modalObject?.preselectedExport || '';
    const isDataInLocalStorage = dataFromLocalStorage?.[userId]?.[modalExportId];

    if (!isEmpty(isDataInLocalStorage)) {
      const {
        options: optionsFromLocalStorage,
        absenceTypes: absenceTypesFromLocalStorage,
        columns: columnsFromLocalStorage,
      } = dataFromLocalStorage[userId]?.[exportId] || {};
      const {
        selectedRange: selectedRangeFromLocalStorage,
        jobTitles: jobTitlesFromLocalStorage,
        locations: locationsFromLocalStorage,
        employmentConditions: employmentConditionsFromLocalStorage,
      } = dataFromLocalStorage[userId]?.[exportId] || {};
      const isSelectedRangeFromLocalStorageValid = validateRangeForExports(
        selectedRangeFromLocalStorage,
        modalExportId,
      );
      const selectedExport = modalObject?.formats?.find(({ exportId }) => exportId === modalExportId);
      const relevantOptionIds = selectedExport?.options.map(({ id }) => id);
      const formattedOptions = optionsFromLocalStorage || { ...options };

      const relevantOptions = relevantOptionIds
        ? relevantOptionIds.reduce((acc, optionId) => {
            acc[optionId] = formattedOptions[optionId];

            return acc;
          }, {})
        : formattedOptions;

      if (!isEmpty(selectedRangeFromLocalStorage) && isSelectedRangeFromLocalStorageValid)
        handlers.updateState({
          ...getInitialState(props),
          selectedRange: selectedRangeFromLocalStorage,
          options: relevantOptions,
          modalExportId: exportId,
        });
      if (jobTitlesFromLocalStorage) {
        jobTitlesHandlers.setItems(jobTitlesFromLocalStorage);
      }
      if (locationsFromLocalStorage) {
        locationsHandlers.setItems(getRelevantLocationsOptions(userLocations, locationsFromLocalStorage));
      }
      if (employmentConditionsFromLocalStorage) {
        employmentConditionsHandlers.setItems(
          getRelevantEmploymentConditionsOptions(employmentConditions, employmentConditionsFromLocalStorage),
        );
      }
      if (absenceTypesFromLocalStorage) {
        absenceTypesHandlers.setItems(absenceTypesFromLocalStorage);
      }
      if (columnsFromLocalStorage) {
        columnsSelectionHandlers.setItems(columnsFromLocalStorage);
      }
    } else {
      jobTitlesHandlers.setItems(getJobTitlesOptions(userJobTitles, locationsItems, userJobTitlesGrouped));
      locationsHandlers.setItems(getLocationsOptions(userLocations));
      employmentConditionsHandlers.setItems(getEmploymentConditionsOptions(employmentConditions));
      absenceTypesHandlers.setItems(getAbsenceTypesOptions(absenceTypes));
      handlers.updateState(getInitialState({ ...props, modalObject: { ...modalObject, preselectedExport: exportId } }));
    }
  }, [modalExportId, isModalVisible]);

  useEffect(() => {
    filterEmployees();
  }, [locationsItems, jobTitlesItems, employmentConditionsItems, modalExportId, isModalVisible]);

  const multiSelects = {
    jobTitlesItems,
    jobTitlesHandlers,
    locationsItems,
    locationsHandlers,
    employmentConditionsItems,
    employmentConditionsHandlers,
    employeesItems,
    employeesHandlers,
    columnsItems,
    columnsSelectionHandlers,
    absenceTypesItems,
    absenceTypesHandlers,
  };

  const clearAndHide = () => {
    hideModalAndLaterClearModalObject();
    saveDataInBrowser(state, modalObject);
    handlers.setInitState();
  };

  const filterEmployees = () =>
    employeesHandlers.setItems(
      getFilteredEmployeesOptions(multiSelects, userEmployees, contracts, state.selectedRange),
    );

  const runExport = () => {
    const absenceTypesOptions = [EXPORTS_TYPES.ecpExport].includes(modalExportId) ? absenceTypesItems : [];

    saveFiltersInBrowser(
      jobTitlesItems,
      locationsItems,
      employmentConditionsItems,
      modalExportId,
      selectedRange,
      options,
      absenceTypesOptions,
      columnsItems,
      userId,
    );
    const multiSelectOptions = modalObject.formats
      .find(({ exportId }) => exportId === modalExportId)
      .options.filter(option => [EXPORT_OPTIONS.MULTI_SELECT, EXPORT_OPTIONS.LIST_SELECTION].includes(option.type))
      .map(option => ({ id: option.id, nonSelectedId: option.nonSelectedId }));

    const { title, fileIconText } = props.modalObject.formats.find(exportData => exportData.exportId === modalExportId);

    startExport(
      modalExportId,
      title,
      getExportPayload(state, multiSelects, props, multiSelectOptions, fileIconText),
      true,
    );
    clearAndHide();
  };

  const onSubmit = () => {
    stepsHandlers.increaseStep();
    if (isLastStep) runExport();
  };

  const onCancel = () => {
    stepsHandlers.decreaseStep();
    if (currentStep === 1) clearAndHide();
  };

  return (
    <MDKadroModal
      show={isModalVisible}
      onHide={clearAndHide}
      onCancelFooter={onCancel}
      title={intl.formatMessage(messages.export)}
      modifiers={MODAL_MODIFIERS}
      onSubmit={onSubmit}
      disableConfirm={!canContinue(currentStep, state, multiSelects, modalObject?.formats)}
      confirmText={intl.formatMessage(messages[isLastStep ? 'export' : 'next'])}
      cancelText={intl.formatMessage(messages[currentStep === 1 ? 'cancel' : 'back'])}
      showProgressBar
      currentStep={currentStep}
      stepsCount={stepsCount}
      withoutOverlayScroll
      inputAwareSubmit
    >
      {displayRelevantStep(props, state, handlers, currentStep, multiSelects)}
    </MDKadroModal>
  );
};

NewExportModal.contextTypes = {
  intl: PropTypes.shape({}),
};

NewExportModal.propTypes = {
  hideModalAndLaterClearModalObject: PropTypes.func,
  isModalVisible: PropTypes.bool,
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  absenceTypes: PropTypes.arrayOf(PropTypes.shape({})),
  modalObject: PropTypes.shape({
    formats: PropTypes.arrayOf(
      PropTypes.shape({
        exportId: PropTypes.string,
      }),
    ),
    steps: PropTypes.arrayOf(PropTypes.string),
    preselectedExport: PropTypes.string,
  }),
  userJobTitlesGrouped: PropTypes.shape({}),
  startExport: PropTypes.func,
  userId: PropTypes.string,
};

export default NewExportModal;
