import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import { computeAttendanceStatistics } from '@/utils/attendanceHelpers';

import AttendanceStatisticsHeader from '../AttendanceStatisticsHeader.jsx';
import EmployeesLeftColumn from '../EmployeesLeftColumn/EmployeesLeftColumn.tsx';

const colGroup = (
  <colgroup>
    <col style={{ width: '60%' }} />
    <col style={{ width: '6%' }} />
    <col style={{ width: '6%' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '10%' }} />
  </colgroup>
);

class AttendanceTable extends Component {
  constructor(props) {
    super(props);
    this.scrollSummary = this.scrollSummary.bind(this);
    this.width = 900;
    this.handleClick = this.handleClick.bind(this);
  }

  scrollSummary(scroll) {
    this.summary.scrollLeft = scroll * (this.width / this.summary.offsetWidth);
  }

  handleClick(location, employee, data) {
    this.props.toggleSelectAttendance({ employeeId: employee.id, locationId: data.locationId });
    this.props.changeLocationFilter(location, true);
  }

  render() {
    const {
      selectedEmployees,
      currentDate,
      data,
      location,
      showLocation,
      disabledEditOverlayStyle,
      children,
      attendancesSettings,
    } = this.props;

    const attendancesData = computeAttendanceStatistics(data.relevantEmployees, currentDate, attendancesSettings);

    return (
      <div className="row" style={{ clear: 'both' }}>
        <div className="col-lg-12">
          <div id="tab-1" className="tab-pane active">
            <div className="panel-nopad panel-blank">
              <AttendanceStatisticsHeader attendancesData={attendancesData} />
              <Grid
                scrollCallback={this.scrollSummary}
                height={999999}
                width={this.width}
                columnGroup={colGroup}
                columnStyle={{ width: '16vw', minWidth: '170px', maxWidth: '280px' }}
              >
                <LeftHeader>
                  <th className="tab_title">
                    <i className="material-icons drag_handle">drag_handle</i>
                    {showLocation ? (
                      location.name
                    ) : (
                      <FormattedMessage id="attendance.table.title" defaultMessage="Pracownik" />
                    )}
                  </th>
                </LeftHeader>
                <Header disabledEditOverlayStyle={disabledEditOverlayStyle}>
                  <thead>
                    <tr>
                      <th className="workprogress">
                        <div className="wptime">02:00</div>
                        <div className="wptime">04:00</div>
                        <div className="wptime">06:00</div>
                        <div className="wptime">08:00</div>
                        <div className="wptime">10:00</div>
                        <div className="wptime">12:00</div>
                        <div className="wptime">14:00</div>
                        <div className="wptime">16:00</div>
                        <div className="wptime">18:00</div>
                        <div className="wptime">20:00</div>
                        <div className="wptime">22:00</div>
                        <div className="wptime">00:00</div>
                      </th>
                      <th className="godzod">
                        <FormattedMessage id="attendance.table.from" defaultMessage="Od" />
                      </th>
                      <th className="godzdo">
                        <FormattedMessage id="attendance.table.to" defaultMessage="Do" />
                      </th>

                      <th className="godzsum">
                        <FormattedMessage id="attendance.table.sumBreaks" defaultMessage="Suma przerw" />
                      </th>
                      <th className="godzsum">
                        <FormattedMessage id="attendance.table.sumHours" defaultMessage="Suma godzin" />
                      </th>
                    </tr>
                  </thead>
                </Header>
                <LeftColumn className={!data.relevantEmployees.length ? 'empty' : ''}>
                  <EmployeesLeftColumn
                    data={data}
                    currentDate={currentDate}
                    selectedEmployees={selectedEmployees}
                    location={location}
                    handleClick={this.handleClick}
                  />
                </LeftColumn>
                <Rows
                  className="table attendance kadroGrid__rows--disabled"
                  columnsNo={1}
                  disabledEditOverlayStyle={disabledEditOverlayStyle}
                >
                  {children}
                </Rows>
                <div
                  ref={ref => {
                    this.summary = ref;
                  }}
                  style={{ overflow: 'hidden', borderLeft: '1px solid #ddd' }}
                >
                  <table
                    className="table attendance"
                    style={{ minWidth: `${this.width + 2}px`, tableLayout: 'fixed', borderRight: '1px solid #e7eaec' }}
                  >
                    {colGroup}
                    <tbody>{data.summary}</tbody>
                  </table>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AttendanceTable.propTypes = {
  data: PropTypes.shape({
    relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
    summary: PropTypes.node,
  }),
  showLocation: PropTypes.bool,
  location: PropTypes.shape({
    name: PropTypes.string,
  }),
  selectedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      employeeId: PropTypes.string,
      locationId: PropTypes.string,
    }),
  ),
  disabledEditOverlayStyle: PropTypes.shape({}),
  children: PropTypes.node,
  toggleSelectAttendance: PropTypes.func,
  currentDate: PropTypes.string,
  changeLocationFilter: PropTypes.func,
  attendancesSettings: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceTable;
