import { connect } from 'react-redux';

import AbsencesProposals from './AbsencesProposals.jsx';

const mapStateToProps = state => ({
  absences: state.reducer.absences.absences,
});

const AbsencesProposalsContainer = connect(mapStateToProps)(AbsencesProposals);

export default AbsencesProposalsContainer;
