import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import Popover from '@/components/common/Popover/Popover.jsx';

const IconButton = props => {
  const buttonClassname = classnames('k-iconButton', props.buttonClassname);
  const popoverClassName = classnames('k-iconButton__label', props.popoverClassname);
  return (
    <Popover
      arrowSize={5}
      showShadow={false}
      position="top"
      showOnHover
      hideOnClick
      className={popoverClassName}
      yOffset={10}
      content={<span>{props.description}</span>}
      wrapperClassName={props.wrapperClassName}
    >
      <button className={buttonClassname} onClick={props.onClick}>
        <i className="material-icons">{props.icon}</i>
      </button>
    </Popover>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  wrapperClassName: PropTypes.string,
  buttonClassname: PropTypes.string,
  popoverClassname: PropTypes.string,
  description: PropTypes.shape({}),
  onClick: PropTypes.func,
};

export default IconButton;
