import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef } from 'react';

import { getHoursFormatting } from './SmartTimeRangeTextarea.helpers';

import './SmartTimeRangeTextarea.scss';

const zerosHours = '00:00';

const SmartTimeRangeTextarea = props => {
  const { value, handleChange, isFocused, initialCursorPosition = 0, rows = 1, onKeyDown } = props;
  const textAreaRef = useRef(null);

  const textAreaClassName = classNames('smartTimeRangeTextarea', {
    'smartTimeRangeTextarea--twoRows': rows === 2,
  });

  useEffect(() => {
    if (isFocused) {
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = initialCursorPosition;
    }
  }, []);

  const onChange = e => {
    if (e.target.value.length > 12) return;
    const newValue = getHoursFormatting(e.target.value);
    handleChange(newValue);
  };

  const handleKeyDown = e => {
    const { key } = e;
    if (['Tab', 'Enter'].includes(key)) {
      e.preventDefault();
      const selectionStartPosition = textAreaRef.current.selectionStart;
      if (selectionStartPosition < 5) {
        handleChange(`${value.slice(0, selectionStartPosition)}${zerosHours.slice(selectionStartPosition)}-`);
      } else if (selectionStartPosition === 5) {
        handleChange(`${value}-${zerosHours}`);
      } else {
        handleChange(`${value}${zerosHours.slice(selectionStartPosition - 6)}`);
      }
    }
    if (onKeyDown) onKeyDown(key);
  };

  return (
    <textarea
      className={textAreaClassName}
      value={value}
      onChange={onChange}
      ref={textAreaRef}
      rows={rows}
      onKeyDown={handleKeyDown}
    />
  );
};

SmartTimeRangeTextarea.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  isFocused: PropTypes.bool,
  initialCursorPosition: PropTypes.number,
  rows: PropTypes.number,
  onKeyDown: PropTypes.number,
};

export default memo(SmartTimeRangeTextarea);
