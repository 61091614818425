import { CHANGE_MULTIPLE_LOCATION_FILTER } from '@/constants/ActionTypes.js';

import { getNewDataForDate } from './index';

export const changeMultipleLocationFilterSuccess = locations => ({
  type: CHANGE_MULTIPLE_LOCATION_FILTER,
  payload: locations,
});

export const areIdListsDifferent = (listA, listB) => {
  if (listA.length !== listB.length) return true;
  if (listA.some(id => !listB.includes(id))) return true;
  return false;
};

export const changeMultipleLocationFilter = (locationIds, preventUpdate = false) => (dispatch, getState) => {
  const { multipleLocationFilter } = getState().reducer;
  const areLocationListsDifferent = areIdListsDifferent(multipleLocationFilter, locationIds);
  if (areLocationListsDifferent) dispatch(changeMultipleLocationFilterSuccess(locationIds));
  if (locationIds.length > 0 && !preventUpdate && areLocationListsDifferent) {
    const { mainDateStore } = getState().reducer;
    const from = mainDateStore.dateArray[0];
    const to = mainDateStore.dateArray[mainDateStore.dateArray.length - 1];
    dispatch(getNewDataForDate(from, to));
  }
};
