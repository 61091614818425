import moment from 'moment';

export const getAttendancesWithoutShiftsOvertimeData = (attendanceData, employee) => {
  if (!attendanceData || !employee) return [];
  return attendanceData.detailBlocks
    .filter(d => d.detailType === 'attendance_without_shift')
    .map(a => ({
      attendance: a.sourceAttendance,
      shift: null,
      employee,
      hasEarlyIn: true,
      hasLateOut: true,
    }));
};

export const getAttendanceOvertimeData = (employee, overtimesDetails) => {
  const { attendances, shifts } = employee;
  return (overtimesDetails || []).reduce((acc, d) => {
    const attendance = attendances.find(
      a => a.end_timestamp === d.endTimestamp || a.start_timestamp === d.startTimestamp,
    );
    const shift = shifts.find(s => s.end_timestamp === d.startTimestamp || s.start_timestamp === d.endTimestamp);
    return !attendance || acc.find(e => e.attendance.id === attendance.id)
      ? acc
      : acc.concat({
          attendance,
          shift,
          employee,
          hasEarlyIn: attendance ? attendance.start_timestamp < shift.start_timestamp : false,
          hasLateOut: attendance ? attendance.end_timestamp > shift.end_timestamp : false,
        });
  }, []);
};

export const getOvertimeData = attendanceDataForEmployees =>
  (attendanceDataForEmployees || []).reduce((acc, val) => {
    const { employee, attendanceData } = val;
    const attendancesWithoutShifts = getAttendancesWithoutShiftsOvertimeData(attendanceData, employee);

    const overtimeDetails = attendanceData.detailBlocks.filter(
      b => b.detailType === 'attendance_early_start' || b.detailType === 'attendance_late_quit',
    );

    const overtimes = getAttendanceOvertimeData(employee, overtimeDetails);
    const sortedOvertimes = [...overtimes, ...attendancesWithoutShifts].sort(
      (a, b) => moment(a.attendance?.start_timestamp || 0) - moment(b.attendance?.start_timestamp || 0),
    );
    return acc.concat(sortedOvertimes);
  }, []);
