import { PropTypes } from 'prop-types';

import SelectPackageView from '@/components/selectPackageView/SelectPackageView';
import browserHistory from '@/constants/browserHistory';
import { intercomBoot } from '@/utils/intercomHelpers.js';

import AppBlocked from './AppBlocked/AppBlocked';
import AppBlockedWithoutPaymentOption from './AppBlockedWithoutPaymentOption/AppBlockedWithoutPaymentOption';
import { messages } from './UnlockAccountView.messages';
import UnlockAccountViewPayment from './UnlockAccountViewPayment/UnlockAccountViewPayment.redux';

import './unlockAccountView.scss';

const UnlockAccountView = ({ unlockPaymentData, currentUser, logoutUser }, { intl }) => {
  if (!currentUser.authenticated) {
    browserHistory.push('/');
  }
  if (currentUser.user.id) {
    const { id, company_id: companyId, client_status: clientStatus } = currentUser.user;
    intercomBoot({
      user_id: id,
      company: { company_id: companyId, 'account-status': clientStatus },
    });
  }

  const hasNoInvoicesToPay = !unlockPaymentData.invoices_to_pay.length;
  const isTrial = unlockPaymentData.clientStatus === 'trial_blocked';
  const isNotOwnerBlocked = currentUser.user.notOwnerBlocked === 'account blocked';
  const { enable_payu: enablePayu } = unlockPaymentData;

  if (isTrial) return <SelectPackageView />;
  if (isNotOwnerBlocked)
    return (
      <AppBlocked
        titleMessage={messages.blockedNotOwnerAccountTitle}
        contentMessage={messages.blockedAccountContent}
        hideButton
        logoutUser={logoutUser}
        imgSrc="img/blockedAccount.png"
        alt={intl.formatMessage(messages.blockedAccountImgAltText)}
      />
    );
  if (hasNoInvoicesToPay || !enablePayu) return <AppBlockedWithoutPaymentOption />;
  return <UnlockAccountViewPayment unlockPaymentData={unlockPaymentData} />;
};

UnlockAccountView.contextTypes = {
  intl: PropTypes.shape({}),
};

UnlockAccountView.propTypes = {
  unlockPaymentData: PropTypes.shape({
    invoices_to_pay: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    blocked: PropTypes.bool,
    paid_to_date: PropTypes.string,
    clientStatus: PropTypes.string,
  }),
  currentUser: PropTypes.shape({
    authenticated: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.string,
      company_id: PropTypes.string,
      client_status: PropTypes.string,
      enable_payu: PropTypes.bool,
      notOwnerBlocked: PropTypes.string,
    }),
  }),
  logoutUser: PropTypes.func,
};
export default UnlockAccountView;
