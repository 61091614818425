import * as AT from '@/constants/ActionTypes.js';
import browserHistory from '@/constants/browserHistory';

import { getInitData } from '../auth';
import { conn } from '../index';
import { messages } from './freemium.messages';

const setAccountAsFremiumError = () => (dispatch, getState, intl) => {
  dispatch({
    type: AT.SET_ACCOUNT_AS_FREEMIUM_ERROR,
    notification: {
      title: intl.formatMessage(messages.setAccountAsFremiumError),
      type: 'error',
    },
  });
};

export const setAccountAsFremium = () => async dispatch => {
  try {
    await conn.changeCompanyStatus('freemium');
    dispatch(getInitData());
    console.log('Freemium reload');
    browserHistory.push('/');
  } catch (err) {
    dispatch(setAccountAsFremiumError());
  }
};
