import {
  CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL,
  CHANGE_TRADE_SHIFT_SUCCESFUL,
  CLOSE_TRADE_SHIFT_SUCCESFUL,
  CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL,
  CREATE_TRADE_SHIFT_SUCCESFUL,
  DELETE_SHIFT,
  DELETE_TRADE_SHIFT_SUCCESFUL,
  GET_TRADE_SHIFTS_SUCCESFUL,
} from '@/constants/ActionTypes.js';

const initialState = [];

const tradeShifts = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRADE_SHIFT_SUCCESFUL:
      return [...state, action.payload];
    case CHANGE_TRADE_SHIFT_SUCCESFUL:
      return [
        ...state.map(tradeShift => {
          if (tradeShift.id !== action.payload.id) {
            return tradeShift;
          }
          return {
            ...tradeShift,
            ...action.payload,
          };
        }),
      ];
    case DELETE_TRADE_SHIFT_SUCCESFUL: {
      const tradeShiftToDeleteIndex = state.findIndex(tradeShift => tradeShift.id === action.payload);
      return [...state.slice(0, tradeShiftToDeleteIndex), ...state.slice(tradeShiftToDeleteIndex + 1)];
    }
    case DELETE_SHIFT: {
      return state.filter(ts => ts.shift.id !== action.payload.shift_id);
    }
    case GET_TRADE_SHIFTS_SUCCESFUL:
      return action.payload ? action.payload.reduce((prev, tradeShiftsArray) => prev.concat(tradeShiftsArray), []) : [];
    case CLOSE_TRADE_SHIFT_SUCCESFUL: {
      const tradeShiftToDeleteIndex = state.findIndex(tradeShift => tradeShift.id === action.payload.tradeShift.id);
      return [...state.slice(0, tradeShiftToDeleteIndex), ...state.slice(tradeShiftToDeleteIndex + 1)];
    }
    case CHANGE_STATUS_TRADE_SHIFT_SUCCESFUL:
      return [
        ...state.map(tradeShift => {
          if (tradeShift.id !== action.payload.tradeShiftId) {
            return tradeShift;
          }
          return {
            ...tradeShift,
            indicated_coworkers: [
              ...tradeShift.indicated_coworkers.map(coworker => {
                if (coworker.id !== action.payload.coworkerId) {
                  return coworker;
                }
                return {
                  ...coworker,
                  status: action.payload.status,
                };
              }),
            ],
          };
        }),
      ];
    case CREATE_OPEN_SHIFT_FROM_TRADE_SHIFT_SUCCESFUL: {
      const tradeShiftToDeleteIndex = state.findIndex(tradeShift => tradeShift.id === action.payload.tradeShiftId);
      return [...state.slice(0, tradeShiftToDeleteIndex), ...state.slice(tradeShiftToDeleteIndex + 1)];
    }
    default:
      return state;
  }
};

export default tradeShifts;
