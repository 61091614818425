import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MDTimeRangeInput } from '@/components/common/inputs/MDComponents';
import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover';
import Lock from '@/components/settings/SettingsFeature/EditDisabledView.jsx';
import SettingsFeatureWrapper from '@/components/settings/SettingsFeature/SettingsFeatureWrapper.redux.js';
import { INPUT_MODIFIERS } from '@/constants/modifiers';

const MD_SELECT_OFFSET = -32;

const ShiftEditDataView = props => {
  if (props.shift.isAvailability) return null;
  const onJobTitleChange = e => {
    const selectedJobTitle = props.userJobTitles.find(jobTitle => jobTitle.id === e);
    props.onInputChange({ shiftId: props.shift.id, name: 'job_title', value: selectedJobTitle });
  };

  const onChange = e => props.onInputChange({ shiftId: props.shift.id, name: 'working_hours', value: e });
  return (
    <SettingsFeatureWrapper date={props.shift.date} location={props.shift.location}>
      {({ editShitsDisabled }) => (
        <>
          {(editShitsDisabled || props.disabled || props.isShiftEditingBlocked) && <Lock />}
          <div className="editDataView__sectionWrapper">
            <div className="editDataView__header">
              <span className="editDataView__headerTitle">
                {typeof props.index === 'number' ? (
                  <FormattedMessage
                    id="attendance.PayrollEditShiftsAndAttendancesModal.shift"
                    defaultMessage="Zmiana {index}"
                    values={{ index: ` #${props.index}` }}
                  />
                ) : (
                  <FormattedMessage
                    id="attendance.PayrollEditShiftsAndAttendancesModal.shiftWithoutIndex"
                    defaultMessage="Zmiana"
                  />
                )}
              </span>
              {props.onShiftDelete && (
                <div className="editDataView__deleteBtn">
                  <span
                    className="k-textInput__label "
                    role="presentation"
                    onClick={() => props.onShiftDelete(props.shift.id)}
                  >
                    <FormattedMessage id="payroll.ShiftEditDataView.removeAttendance" defaultMessage="Usuń" />
                  </span>
                </div>
              )}
            </div>
            <MDTimeRangeInput
              label={<FormattedMessage id="payroll.ShiftEditDataView.inputLabelText" defaultMessage="Czas" />}
              onChange={onChange}
              value={props.shift.working_hours}
              errorMessage={props.error?.defaultMessage}
              modifiers={INPUT_MODIFIERS}
            />
            {props.options.length ? (
              <MDSelectPopover
                name={<FormattedMessage id="payroll.ShiftEditDataView.selectLabelText" defaultMessage="Stanowisko" />}
                defaultValue={props.shift.job_title.id}
                options={props.options}
                onChange={onJobTitleChange}
                closeOnClick
                yDropdownOffset={MD_SELECT_OFFSET}
              />
            ) : null}
          </div>
        </>
      )}
    </SettingsFeatureWrapper>
  );
};

ShiftEditDataView.propTypes = {
  shift: PropTypes.shape({
    id: PropTypes.string,
    working_hours: PropTypes.string,
    job_title: PropTypes.shape({
      id: PropTypes.string,
    }),
    isAvailability: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  onInputChange: PropTypes.func,
  onShiftDelete: PropTypes.func,
  error: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  isShiftEditingBlocked: PropTypes.bool,
};
export default ShiftEditDataView;
