import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import TopBarCompanyManage from '@/components/TopBars/TopBarCompanyManage.jsx';
import { getDefaultCompanyManagePage } from '@/utils/routesHelpers.js';

import HelpModal from '@/components/common/HelpModal';
import { filterCompanyManageRoutes } from './CompanyManageView.helpers';

const CompanyManageView = ({ permissions, restrictions }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const filteredRoutes = useMemo(
    () => filterCompanyManageRoutes(permissions, restrictions),
    [permissions, restrictions]
  );

  const isSubrouteAllowed = filteredRoutes.find(
    (route) => route.path === location.pathname && !route.showFreemiumStar
  );

  useEffect(() => {
    if (
      !isSubrouteAllowed ||
      location.pathname.endsWith('companymanage') ||
      location.pathname.endsWith('companymanage/')
    ) {
      const defaultCompanyManagePage = getDefaultCompanyManagePage(
        restrictions,
        permissions
      );
      navigate(defaultCompanyManagePage);
    }
  }, [location.pathname]);

  return (
    <>
      <TopBarCompanyManage
        activePath={location.pathname}
        filteredRoutes={filteredRoutes}
      />
      <HelpModal />
      <Outlet />
    </>
  );
};

CompanyManageView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default CompanyManageView;
