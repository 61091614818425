import { connect } from 'react-redux';

import {
  desellectAllAttendancesSettings,
  sellectAllAttendancesSettings,
  toggleAttendanceSettings,
} from '@/actions/settings';
import { AttendanceSetting, State } from '@/types';

import AttendancesSettingsButton from './AttendancesSettingsButton';

export interface AttendancesSettingsButtonProps extends StateProps, DispatchProps {}

export interface StateProps {
  attendancesSettings: AttendanceSetting[];
  attendancesDisplayModeType: string;
}

export interface DispatchProps {
  toggleAttendanceSettings: (setting: AttendanceSetting) => void;
  sellectAllAttendancesSettings: () => void;
  desellectAllAttendancesSettings: () => void;
}

const mapStateToProps = (state: State) => ({
  attendancesSettings: state.reducer.settings.attendancesSettings,
  attendancesDisplayModeType: state.reducer.attendances.displayMode.type,
});

const mapDispatchToProps = { toggleAttendanceSettings, sellectAllAttendancesSettings, desellectAllAttendancesSettings };

export default connect<StateProps, DispatchProps, AttendancesSettingsButtonProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AttendancesSettingsButton);
