import PropTypes from 'prop-types';

const Notification = props => {
  const notificationGoToContext = () => {
    props.notificationGoToContext(props.notification);
  };
  return (
    <li onClick={notificationGoToContext} role="presentation" className="notification-item">
      <div className="notification-item__content">
        <div className="notification-item__date">{props.notification.datetime}</div>
        <div className="notification-item__text">{props.notification.message}</div>
      </div>
      {props.children}
    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    datetime: PropTypes.string,
    message: PropTypes.node,
  }).isRequired,
  children: PropTypes.node,
  notificationGoToContext: PropTypes.func,
};

export default Notification;
