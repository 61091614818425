import PropTypes from 'prop-types';

import PaymentNotification from '@/components/common/PaymentNotification/PaymentNotification.redux';
import { LOAN_EMPLOYEES_ENABLE } from '@/constants/Permissions.js';

import AbsencesProposals from '../AbsencesProposals/AbsencesProposals.redux.js';
import DashboardChart from '../DashboardChart/DashboardChart.redux.js';
import DashboardError from '../DashboardError/DashboardError.jsx';
import DashboardLocations from '../DashboardLocations/DashboardLocations.redux.js';
import DashboardStatistics from '../DashboardStatistics/DashboardStatistics.redux.js';
import DashboardTeam from '../DashboardTeam/DashboardTeam.redux.js';
import DashboardWelcome from '../DashboardWelcome/DashboardWelcome.redux';
import EventsProposals from '../EventsProposals/EventsProposals.redux.js';
import LoanEmployeesProposalsSection from '../loanEmployeesProposalsSection/LoanEmployeesProposalsSection.redux.js';

import './DashboardView.scss';

const DashboardView = ({ dashboardError, getDashboard, permissions }) => {
  const isLoanedEmployeesEnabled = permissions.includes(LOAN_EMPLOYEES_ENABLE);

  return (
    <>
      <PaymentNotification />
      <div className="md-dashboard">
        <DashboardWelcome />
        {dashboardError ? (
          <DashboardError getDashboard={getDashboard} />
        ) : (
          <div className="md-dashboard__content">
            <div className="md-dashboard__content--left">
              <DashboardStatistics />
              <DashboardChart />
              <DashboardLocations />
              <DashboardTeam />
            </div>
            <div className="md-dashboard__content--right">
              <AbsencesProposals />
              {isLoanedEmployeesEnabled && <LoanEmployeesProposalsSection />}
              <EventsProposals />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

DashboardView.propTypes = {
  dashboardError: PropTypes.bool,
  getDashboard: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default DashboardView;
