import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absences: {
    id: 'export.exportSettings.absences',
    defaultMessage: 'Nieobecności',
  },
  absencesLegend: {
    id: 'export.exportSettings.absenceLegend',
    defaultMessage: 'Opis i legenda niedostępności',
  },
  availabilities: {
    id: 'export.exportSettings.availabilities',
    defaultMessage: 'Dostępności',
  },
  availabilitiesLegend: {
    id: 'export.exportSettings.availabilitiesLegend',
    defaultMessage: 'Legenda dostępności',
  },
  chooseAvailabilityType: {
    id: 'export.exportSettings.chooseAvailabilityType',
    defaultMessage: 'Wybierz dostępności',
  },
  chooseAbsenceType: {
    id: 'export.exportSettings.chooseAbsenceType',
    defaultMessage: 'Wybierz nieobecności',
  },
  exportWithAbsences: {
    id: 'export.exportSettings.exportWithAbsences',
    defaultMessage:
      'Wybierz tę opcję, a na wydruku będą widoczne skrócone nazwy zaplanowanych nieobecności. Jeśli opcja ta będzie odznaczona, wówczas każdy typ nieobecności będzie zapisany jako "Nb"',
  },
  exportType: {
    id: 'export.exportSettings.exportType',
    defaultMessage: 'Rodzaj exportu',
  },
  shiftsFromOtherLocations: {
    id: 'export.exportSettings.shiftsFromOtherLocations',
    defaultMessage: 'Zmiany z innych lokalizacji',
  },
  showSummary: {
    id: 'export.exportSettings.showSummary',
    defaultMessage: 'Podsumowanie zaplanowanych godzin',
  },
});
