import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState';
import { APP_IN_MAINTANANCE_MODAL } from '@/constants/modalTypes.js';

import AppInMaintenance from './AppInMaintenance.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === APP_IN_MAINTANANCE_MODAL,
});

const mapDispatchToProps = {
  hideModal,
};
const AppInMaintenanceContainer = connect(mapStateToProps, mapDispatchToProps)(AppInMaintenance);

export default AppInMaintenanceContainer;
