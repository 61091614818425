import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'importShiftAndAttendanceErrorModal.title',
    defaultMessage: 'Błędy w importowanych godzinach pracy',
  },
  firstName: {
    id: 'importShiftAndAttendanceErrorModal.firstName',
    defaultMessage: 'Imię',
  },
  lastName: {
    id: 'importShiftAndAttendanceErrorModal.lastName',
    defaultMessage: 'Nazwisko',
  },
  referenceId: {
    id: 'importShiftAndAttendanceErrorModal.referenceId',
    defaultMessage: 'Kod referencyjny',
  },
  date: {
    id: 'importShiftAndAttendanceErrorModal.date',
    defaultMessage: 'Data',
  },
  description: {
    id: 'importShiftAndAttendanceErrorModal.description',
    defaultMessage: 'Opis',
  },
});

export const errorsMessages = defineMessages({
  incorrectLocation: {
    id: 'importShiftAndAttendanceErrorModal.incorrectLocation',
    defaultMessage: 'Niepoprawne dane w kolumnie lokalizacja',
  },
  incorrectJobTitle: {
    id: 'importShiftAndAttendanceErrorModal.incorrectJobTitle',
    defaultMessage: 'Niepoprawne dane w kolumnie stanowisko',
  },
  incorrectEmployeeData: {
    id: 'importShiftAndAttendanceErrorModal.incorrectEmployeeData',
    defaultMessage: 'Niepoprawne dane pracownika',
  },
  incorrectDate: {
    id: 'importShiftAndAttendanceErrorModal.incorrectDate',
    defaultMessage: 'Niepoprawna data',
  },
  incorrectStartShift: {
    id: 'importShiftAndAttendanceErrorModal.incorrectStartShift',
    defaultMessage: 'Niepoprawna godzina rozpoczęcia zmiany',
  },
  incorrectEndShift: {
    id: 'importShiftAndAttendanceErrorModal.incorrectEndShift',
    defaultMessage: 'Niepoprawna godzina zakończenia zmiany',
  },
  incorrectStartAttendance: {
    id: 'importShiftAndAttendanceErrorModal.incorrectStartAttendance',
    defaultMessage: 'Niepoprawna godzina rozpoczęcia obecności',
  },
  incorrectEndAttendance: {
    id: 'importShiftAndAttendanceErrorModal.incorrectEndAttendance',
    defaultMessage: 'Niepoprawna godzina zakończenia obecności',
  },
  missingStartShift: {
    id: 'importShiftAndAttendanceErrorModal.missingStartShift',
    defaultMessage: 'Brak godziny rozpoczęcia zmiany',
  },
  missingEndShift: {
    id: 'importShiftAndAttendanceErrorModal.missingEndShift',
    defaultMessage: 'Brak godziny zakończenia zmiany',
  },
  missingStartAttendance: {
    id: 'importShiftAndAttendanceErrorModal.missingStartAttendance',
    defaultMessage: 'Brak godziny rozpoczęcia obecności',
  },
  missingEndAttendance: {
    id: 'importShiftAndAttendanceErrorModal.missingEndAttendance',
    defaultMessage: 'Brak godziny zakończenia obecności',
  },
  missingShiftAndAttendance: {
    id: 'importShiftAndAttendanceErrorModal.missingShiftAndAttendance',
    defaultMessage: 'Brak godzin zmiany i obecności',
  },
  notAllowedLocation: {
    id: 'importShiftAndAttendanceErrorModal.notAllowedLocation',
    defaultMessage: 'Użytkownik nie ma dostępu do wybranej lokalizacji',
  },
  notExistingEmployee: {
    id: 'importShiftAndAttendanceErrorModal.notExistingEmployee',
    defaultMessage: 'Pracownik nie istnieje',
  },
  employeeNotAssigned: {
    id: 'importShiftAndAttendanceErrorModal.employeeNotAssigned',
    defaultMessage: 'Pracownik jest nieprzypisany do lokalizacji',
  },
  notExistingLocations: {
    id: 'importShiftAndAttendanceErrorModal.notExistingLocations',
    defaultMessage: 'Lokalizacja nie istnieje',
  },
  notExistingJobTitles: {
    id: 'importShiftAndAttendanceErrorModal.notExistingJobTitles',
    defaultMessage: 'Stanowisko nie istnieje',
  },
  employeeIsBusy: {
    id: 'importShiftAndAttendanceErrorModal.employeeIsBusy',
    defaultMessage: 'Pracownik ma już zmianę w czasie dodawanej zmiany',
  },
  missingLocationSettings: {
    id: 'importShiftAndAttendanceErrorModal.missingLocationSettings',
    defaultMessage: 'Brak ustawień lokalizacji',
  },
  attendancesEditBlocked: {
    id: 'importShiftAndAttendanceErrorModal.attendancesEditBlocked',
    defaultMessage: 'Edycja obecności została zablokowana w dniu kiedy jest importowana',
  },
  editOwnAttendancesBlocked: {
    id: 'importShiftAndAttendanceErrorModal.editOwnAttendancesBlocked',
    defaultMessage: 'Użytkownik ma zablokowane edytowanie własnych obecności',
  },
  missingEmployee: {
    id: 'importShiftAndAttendanceErrorModal.missingEmployee',
    defaultMessage: 'Brak pracownika',
  },
  inactiveUser: {
    id: 'importShiftAndAttendanceErrorModal.inactiveUser',
    defaultMessage: 'Nieaktywny pracownik',
  },
  userNotAssignedToLocations: {
    id: 'importShiftAndAttendanceErrorModal.userNotAssignedToLocations',
    defaultMessage: 'Pracownik nieprzypisany do lokalizacji',
  },
  noAccessToLocation: {
    id: 'importShiftAndAttendanceErrorModal.noAccessToLocation',
    defaultMessage: 'Użytkownik nie ma dostępu do lokalizacji',
  },
  overlappedShifts: {
    id: 'importShiftAndAttendanceErrorModal.overlappedShifts',
    defaultMessage: 'W pliku znajdują się nachodzące na siebie zmiany',
  },
  employeeNotAssignedToJobTitle: {
    id: 'importShiftAndAttendanceErrorModal.employeeNotAssignedToJobTitle',
    defaultMessage: 'Pracownik nie jest przypisany do stanowiska',
  },
  incorrectReferenceId: {
    id: 'importShiftAndAttendanceErrorModal.incorrectReferenceId',
    defaultMessage: 'Niepoprawny kod referencyjny',
  },
  unexpectedError: {
    id: 'importShiftAndAttendanceErrorModal.unexpectedError',
    defaultMessage: 'Nieoczekiwany błąd',
  },
});
