import { connect } from 'react-redux';

import {
  changeSorting,
  deleteJobTitleConfirm,
  massDeleteJobTitlesConfirm,
  startEdit,
  toggleAllCheckboxes,
  toggleCheckbox,
  toggleModal,
} from '@/actions/companymanage';
import { trackIntercomEvent } from '@/actions/intercom';

import JobTitlesTable from './JobTitlesTable.jsx';

const mapStateToProps = state => ({
  userJobTitles: state.reducer.userJobTitles,
  currentCompany: state.reducer.currentCompany,
  jobTitlesListUi: state.reducer.listsUi.jobTitles,
  userPermissions: state.reducer.userPermissions,
  deviceInfo: state.reducer.deviceInfo,
  jobTitlesLimitReached: state.reducer.companyLimitsUsage.jobTitlesLimitReached,
});

const mapDispatchToProps = {
  toggleCheckbox,
  toggleAllCheckboxes,
  toggleModal,
  startEdit,
  changeSorting,
  massDeleteJobTitlesConfirm,
  deleteJobTitleConfirm,
  trackIntercomEvent,
};

const JobTitlesTableContainer = connect(mapStateToProps, mapDispatchToProps)(JobTitlesTable);

export default JobTitlesTableContainer;
