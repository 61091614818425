import { createSlice } from '@reduxjs/toolkit';

import { createUpdateManagerNote, deleteManagerNote, getManagerNotes } from './managerNotes.asyncActions';
import { ManagerNotesStoreState } from './managerNotes.types';

export const sliceName = 'managerNotes';

export const initialState: ManagerNotesStoreState = {};

const ManagerNotesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUpdateManagerNote.fulfilled, (state, action) => {
      const { location_id, date } = action.payload;
      if (!state[location_id]) state[location_id] = {};
      state[location_id][date] = action.payload;
    });
    builder.addCase(deleteManagerNote.fulfilled, (state, action) => {
      const deletedNote = action.meta.arg;
      delete state[deletedNote.location_id][deletedNote.date];
    });
    builder.addCase(getManagerNotes.fulfilled, (_state, action) => action.payload);
  },
});

export const managerNotesReducer = ManagerNotesSlice.reducer;
