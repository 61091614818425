import * as AT from '@/constants/ActionTypes.js';
import { SCHEDULE_IMPORTS } from '@/constants/imports';

const initialState = {
  availableExports: [],
  pendingExports: [],
};

const exports = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_AVAILABLE_EXPORTS_SUCCESS:
      return { ...state, availableExports: action.payload };
    case AT.POST_EXPORT:
      return { ...state, pendingExports: [...state.pendingExports, action.payload] };
    case AT.GET_EXPORT_SUCCESS: {
      const { id, link, errors, result } = action.payload;
      const foundExport = state.pendingExports.find(pendingExport => pendingExport.id === id);
      if (!foundExport) return state;
      clearTimeout(foundExport.timeoutId);
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport =>
          pendingExport.id === id
            ? {
                ...pendingExport,
                status: 'unseen',
                link,
                errors,
                result,
                isShownInExportsWidget: !SCHEDULE_IMPORTS.includes(pendingExport.type),
              }
            : pendingExport,
        ),
      };
    }
    case AT.EXPORT_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport =>
          pendingExport.id === action.payload.id ? { ...pendingExport, status: 'success' } : pendingExport,
        ),
      };
    }
    case AT.HIDE_EXPORT_IN_WIDGET: {
      const { id } = action.payload;
      const foundExport = state.pendingExports.find(pendingExport => pendingExport.id === id);
      if (!foundExport) return state;
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport =>
          pendingExport.id === id
            ? {
                ...pendingExport,
                isShownInExportsWidget: false,
              }
            : pendingExport,
        ),
      };
    }
    case AT.REMOVE_ALL_EXPORTS_FROM_WIDGET: {
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport => ({
          ...pendingExport,
          isShownInExportsWidget: false,
        })),
      };
    }
    case AT.GET_EXPORT_FAILED: {
      const id = action.payload;
      const foundExport = state.pendingExports.find(pendingExport => pendingExport.id === id);
      if (!foundExport) return state;
      clearTimeout(foundExport.timeoutId);
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport =>
          pendingExport.id === id ? { ...pendingExport, status: 'error' } : pendingExport,
        ),
      };
    }
    case AT.GET_EXPORT_ERROR:
      return {
        ...state,
        pendingExports: state.pendingExports.map(pendingExport =>
          pendingExport.id === action.payload ? { ...pendingExport, status: 'error' } : pendingExport,
        ),
      };
    case AT.CLEAR_SCHEDULE_IMPORTS:
      return {
        ...state,
        pendingExports: state.pendingExports.filter(e => e.type !== 'f-schedule-import'),
      };
    case AT.CLEAR_BUDGET_IMPORTS: {
      return {
        ...state,
        pendingExports: state.pendingExports.filter(e => e.type !== 'f-budget-import'),
      };
    }
    case AT.CLEAR_BUDGET_TARGETS_IMPORTS:
      return {
        ...state,
        pendingExports: state.pendingExports.filter(e => e.type !== 'f-budget-targets-import'),
      };
    case AT.CLEAR_BUDGET_METRICS_IMPORTS:
      return {
        ...state,
        pendingExports: state.pendingExports.filter(e => e.type !== 'f-budget-metrics-import'),
      };
    default:
      return state;
  }
};

export default exports;
