export const toUnderscore = string => string.replace(/([A-Z])/g, c => `_${c.toLowerCase()}`);
export const isNotEmptyText = text => text && text.trim && text.trim().length > 0;
export const truncateText = (text, length = 20) => `${text.slice(0, length)}${text.length > length ? '...' : ''}`;
export const firstLetterToUpperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

const polishMap = {
  ą: 'a',
  ć: 'c',
  ę: 'e',
  ł: 'l',
  ń: 'n',
  ó: 'o',
  ś: 's',
  ź: 'z',
  ż: 'z',
  Ą: 'A',
  Ć: 'C',
  Ę: 'E',
  Ł: 'L',
  Ń: 'N',
  Ó: 'O',
  Ś: 'S',
  Ź: 'Z',
  Ż: 'Z',
};

export const normalizeDiacritics = text => text.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, match => polishMap[match]);
