import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { addRecommendedScheduleError } from '@/actions/recommendedSchedule.js';
import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { groupAndAdjustRecommendedSchedule } from '@/utils/recommendedScheduleHelpers.js';

import '@/components/common/MDImportModal/MDImportModal.scss';

const getInitialState = () => ({
  recommendedSchedule: [],
  isTemplateImported: false,
  errors: [],
});

const MDImportRecommendedScheduleModal = props => {
  const {
    selectedLocations,
    mainDateStore,
    show,
    addRecommendedSchedule,
    deleteRecommendedSchedule,
    exportData,
    hideModal,
    importRecommendedScheduleFile,
  } = props;

  const [state, setState] = useState(getInitialState());

  const hideAndClear = () => {
    hideModal();
    setState(getInitialState());
  };

  const onSubmit = () => {
    addRecommendedSchedule(state.recommendedSchedule);
    hideAndClear();
  };

  const handleOnExport = () => {
    exportData(null, { type: 'recommendedSchedule' });
  };

  const handleImportData = file => {
    importRecommendedScheduleFile(file)
      .then(response => {
        setState({
          recommendedSchedule: groupAndAdjustRecommendedSchedule(response, selectedLocations[0]),
          isTemplateImported: true,
        });
      })
      .catch(err => {
        setState({
          errors: err,
          isTemplateImported: true,
        });
        addRecommendedScheduleError(err);
      });
  };

  const showClearDataConfirmModal = () => {
    hideAndClear();
    deleteRecommendedSchedule(mainDateStore.customDate.start, mainDateStore.customDate.end, selectedLocations[0]);
  };

  const confirmBtnModifiers = state.isTemplateImported && !state.errors ? ['orange'] : ['hide'];
  return (
    <MDKadroModal
      show={show}
      onHide={hideAndClear}
      onSubmit={onSubmit}
      modifiers={['wide']}
      confirmBtnModifiers={confirmBtnModifiers}
      cancelBtnModifiers={['gray', 'reverse-orange']}
      title={<FormattedMessage id="importBudgetModal.importData" defaultMessage="Importuj dane" />}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importBudgetModal.loadData" defaultMessage="Wczytaj dane" />
          </div>

          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="recommendedSchedule.clear"
              defaultMessage="Jeśli chcesz wyczyścić wcześniej wczytane dane, {clickHere}."
              values={{
                clickHere: (
                  <span className="mdImportModalExport__link" role="none" onClick={() => showClearDataConfirmModal()}>
                    {' '}
                    <FormattedMessage id="clickHere" defaultMessage="kliknij tutaj" />
                  </span>
                ),
              }}
            />
          </div>
          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="recommendedSchedule.fileImport.exportFileText"
              defaultMessage="Wczytaj dane z pliku Excel. {downloadTemplate}  i wypełnij w nim dane."
              values={{
                downloadTemplate: (
                  <span className="mdImportModalExport__link" role="none" onClick={() => handleOnExport()}>
                    {' '}
                    <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />{' '}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop
            isFileImported={state.isTemplateImported}
            messages={state.errors}
            handleImportData={handleImportData}
          />
        </div>
      </div>
    </MDKadroModal>
  );
};

MDImportRecommendedScheduleModal.propTypes = {
  selectedLocations: PropTypes.arrayOf(PropTypes.string),
  mainDateStore: PropTypes.shape({
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  show: PropTypes.bool,
  addRecommendedSchedule: PropTypes.func,
  deleteRecommendedSchedule: PropTypes.func,
  exportData: PropTypes.func,
  hideModal: PropTypes.func,
  importRecommendedScheduleFile: PropTypes.func,
};

export default MDImportRecommendedScheduleModal;
