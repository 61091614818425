import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';

import { sortArray } from '@/utils/arrayHelpers';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './MDListContainer.scss';

function MDListContainer(props) {
  return (
    <div className="mdListContainer">
      <span className="mdListContainer__title">{props.title}</span>
      <div className="mdListContainer__items">
        <OverlayScrollbarsComponent className="k-overlay-scrollbar">
          {sortArray(props.items).map((item, index) => (
            <div key={index} className="mdListContainer__item">
              {item}
            </div>
          ))}
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
}

MDListContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.shape({}),
};

export default MDListContainer;
