import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';

class IntlContextProviderComponent extends Component {
  getChildContext() {
    return { intl: this.props.intl };
  }

  render() {
    return this.props.children;
  }
}

IntlContextProviderComponent.childContextTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export const IntlContextProvider = injectIntl(IntlContextProviderComponent);
