import Icon from '@/components/common/Basic/Icon/Icon';

import TopBarSingleChoice from '../../common/TopBarSingleChoice/TopBarSingleChoice';
import { messages } from './TopBarEmployeeGroupingFilter.utils';
import { useTopBarEmployeeGroupingFilter } from './useTopBarEmployeeGroupingFilter';

type Props = {
  hideFilter: boolean;
};

export const TopBarEmployeeGroupingFilter = ({ hideFilter }: Props) => {
  const { intl, isSingleDateMode, onChange, options, selected } = useTopBarEmployeeGroupingFilter();

  if (hideFilter || isSingleDateMode) return null;

  return (
    <div className="k-topBarGroupingFilter k-topBar__leftElement">
      <TopBarSingleChoice
        hideSearch
        labelText={intl.formatMessage(messages.grouping)}
        objectsToChoose={options}
        selected={selected}
        onChange={onChange}
        icon={<Icon className="k-topBarChoice k-topBarChoice__selectedIcon" name="format_list_bulleted" />}
        displayKey="displayText"
        className="k-topBarChoice k-topBarChoice--sortingFilter"
        showCheckedIcon
      />
    </div>
  );
};
