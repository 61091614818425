import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import './MDSearchBox.scss';

const messages = defineMessages({
  search: {
    id: 'common.mdSearchBox.search',
    defaultMessage: 'Szukaj',
  },
});

const MDSearchBox = (props, context) => (
  <div className="mdSearchBox">
    <i className="material-icons mdSearchBox__icon">search</i>
    <input
      className="mdSearchBox__input"
      placeholder={`${context.intl.formatMessage(messages.search, {})}...`}
      value={props.searchText}
      onChange={props.handleSearchText}
    />
  </div>
);

MDSearchBox.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

MDSearchBox.propTypes = {
  searchText: PropTypes.string.isRequired,
  handleSearchText: PropTypes.func.isRequired,
};

export default MDSearchBox;
