import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import MinuteHourInput from '@/components/common/inputs/MinuteHourInput.jsx';
import SelectInput from '@/components/common/inputs/SelectInput.jsx';
import TimeRangeInput from '@/components/common/inputs/TimeRangeInput.jsx';
import Modal from '@/components/common/Modal/Modal.jsx';
import { FREEMIUM_EVENT_PAYROLL_SETTINGS } from '@/constants/intercom.js';
import { breaksSettings, payoutSettings, roundingSettings, timeFormatSettings } from '@/constants/payrollSettings';
import { FREEMIUM_DISABLE_PAYROLL_SETTINGS, PAYROLL_SETTINGS_EDIT_DISABLE } from '@/constants/Restrictions.js';

import RoundingSettings from './PayrollRoundingSettings.jsx';

class OLDPayrollSettingsModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nighthours: props.settings.nighthours,
    };
    bindClassFunctions(this);
  }

  componentDidUpdate(prevProps) {
    const { showSettingsModal } = this.props.payrollUI;
    if (showSettingsModal && showSettingsModal !== prevProps.payrollUI.showSettingsModal) {
      this.setState({ ...this.props.settings });
    }
  }

  onHide() {
    if (!this.props.showSaveButton) {
      this.applySettings();
      this.saveSettings();
    }
    this.props.togglePayrollSettingsModal();
  }

  changeSetting(key, value) {
    const { userPermissions } = this.props;
    if (
      !userPermissions.roles.includes('ROLE_OWNER') &&
      userPermissions.restrictions.includes(PAYROLL_SETTINGS_EDIT_DISABLE)
    ) {
      return;
    }

    this.setState({ [key]: value });
  }

  async saveSettings() {
    await this.props.savePayrollSettings();
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  changeNightHours(e) {
    this.setState({ nighthours: e.target.value });
  }

  applySettings() {
    const changes = this.state;
    if (changes.nighthours.search('_') >= 0) delete changes.nighthours;
    this.props.changePayrollSettings(changes);
  }

  async handleSaveClick() {
    this.applySettings();
    await this.saveSettings();
    this.props.togglePayrollSettingsModal();
  }

  render() {
    const { userPermissions, hideShiftsNoExtra } = this.props;
    const disableEdit =
      !userPermissions.roles.includes('ROLE_OWNER') &&
      userPermissions.restrictions.includes(PAYROLL_SETTINGS_EDIT_DISABLE);
    const breaksRangeInput = (
      <MinuteHourInput
        value={this.state.breaksValue}
        min={0}
        max={90}
        step={1}
        onChangeFunc={e => {
          this.changeSetting('breaksValue', e.target.value);
        }}
        labelId="tolerance1"
      />
    );
    let breaksSection = null;
    const breaksSectionStyle = { marginTop: '-10px' };
    switch (this.state.breaksSetting ? this.state.breaksSetting.type : '') {
      case 'tolerance':
        breaksSection = (
          <div className="payrollSettings__rowItem" style={breaksSectionStyle}>
            <div className="payrollSettings__selectLabel">
              <FormattedMessage id="payrollSettings.tolerance" defaultMessage="tolerancja" />
            </div>
            {breaksRangeInput}
          </div>
        );
        break;
      case 'constant':
        breaksSection = (
          <div className="payrollSettings__rowItem" style={breaksSectionStyle}>
            <div className="payrollSettings__selectLabel">
              <FormattedMessage id="payrollSettings.breakTime" defaultMessage="czas przerwy" />
            </div>
            {breaksRangeInput}
          </div>
        );
        break;
      default:
        break;
    }
    return (
      <Modal show={this.props.payrollUI.showSettingsModal} onHide={this.onHide} className="payrollSettingsModal">
        <Modal.Header closeButton>
          <FreemiumWrapper
            freemiumRestrictions={[FREEMIUM_DISABLE_PAYROLL_SETTINGS]}
            intercomEvent={FREEMIUM_EVENT_PAYROLL_SETTINGS}
          >
            <Modal.Title>
              <div className="payrollSettingsModal__title">
                <FormattedMessage id="settings" defaultMessage="Ustawienia" />
                {userPermissions.restrictions.includes(FREEMIUM_DISABLE_PAYROLL_SETTINGS) && <FreemiumStar />}
              </div>
            </Modal.Title>
          </FreemiumWrapper>
          {disableEdit ? (
            <span className="payrollSettingsModal__disabledMessage">
              <FormattedMessage
                id="payrollSettings.noPermissions"
                defaultMessage="Nie masz przyznanych uprawnień do edycji"
              />
            </span>
          ) : null}
        </Modal.Header>
        <FreemiumWrapper
          freemiumRestrictions={[FREEMIUM_DISABLE_PAYROLL_SETTINGS]}
          intercomEvent={FREEMIUM_EVENT_PAYROLL_SETTINGS}
        >
          <div className={disableEdit ? 'disabled disabled--gray' : ''}>
            <Modal.Body>
              <div className="payrollSettingsModal__section">
                <div className="payrollSettings__row">
                  <div className="payrollSettings__rowItem">
                    <div className="payrollSettings__selectLabel">
                      <FormattedMessage id="payrollSettings.payout" defaultMessage="Rozliczenie" />:
                    </div>
                    <SelectInput
                      onChangeFunc={e => {
                        this.changeSetting(
                          'payoutSetting',
                          payoutSettings.find(s => s.id === e.value),
                        );
                      }}
                      value={this.state.payoutSetting}
                      options={payoutSettings
                        .filter(s => !hideShiftsNoExtra || s.type !== 'shifts_no_extra')
                        .map(s => ({
                          value: s.id,
                          label: this.context.intl.formatMessage(s.message),
                        }))}
                    />
                  </div>
                  <div className="payrollSettings__rowItem">
                    <div className="payrollSettings__selectLabel">
                      <FormattedMessage
                        id="payrollSettings.workingHoursRounding"
                        defaultMessage="Zaokrąglenie czasu pracy"
                      />
                      :
                    </div>
                    <SelectInput
                      onChangeFunc={e => {
                        this.changeSetting(
                          'roundingSetting',
                          roundingSettings.find(s => s.id === e.value),
                        );
                      }}
                      value={this.state.roundingSetting}
                      options={roundingSettings.map(s => ({
                        value: s.id,
                        label: this.context.intl.formatMessage(s.message),
                      }))}
                    />
                  </div>
                </div>
                <div className="payrollSettings__row">
                  <div className="payrollSettings__rowItem">
                    <div className="payrollSettings__selectLabel">
                      <FormattedMessage id="payrollSettings.timeFormat" defaultMessage="Format czasu:" />
                    </div>
                    <SelectInput
                      onChangeFunc={e => {
                        this.changeSetting(
                          'timeFormatSetting',
                          timeFormatSettings.find(s => s.id === e.value),
                        );
                      }}
                      value={this.state.timeFormatSetting}
                      options={timeFormatSettings.map(s => ({
                        value: s.id,
                        label: this.context.intl.formatMessage(s.message),
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="payrollSettingsModal__section payrollSettingsModal__section--center">
                <div className="payrollSettingsModal__sectionTitle">
                  <FormattedMessage id="payrollSettings.nighthours" defaultMessage="Godziny nocne" />
                </div>
                <div className="payrollSettings__selectLabel">
                  <FormattedMessage id="payrollSettings.nighthoursTime" defaultMessage="Godziny nocne w przedziale" />:
                </div>
                <TimeRangeInput value={this.state.nighthours} onChange={this.changeNightHours} />
              </div>

              <div className="payrollSettingsModal__section payrollSettingsModal__section--center">
                <div className="payrollSettingsModal__sectionTitle">
                  <FormattedMessage id="payrollSettings.breaks" defaultMessage="Przerwy" />
                </div>
                <div className="payrollSettings__row">
                  <div className="payrollSettings__rowItem">
                    <div className="payrollSettings__selectLabel">
                      <FormattedMessage id="payrollSettings.reduceBreaks" defaultMessage="Uwzględnij czas przerw" />:
                    </div>
                    <SelectInput
                      onChangeFunc={e => {
                        this.changeSetting(
                          'breaksSetting',
                          breaksSettings.find(s => s.id === e.value),
                        );
                      }}
                      value={this.state.breaksSetting}
                      options={breaksSettings.map(s => ({
                        value: s.id,
                        label: this.context.intl.formatMessage(s.message),
                      }))}
                    />
                  </div>
                  {breaksSection}
                </div>
              </div>

              <div
                className="payrollSettingsModal__section
            payrollSettingsModal__section--noOverflow
            payrollSettingsModal__section--noMargin"
              >
                <div className="payrollSettingsModal__sectionTitle">
                  <FormattedMessage id="payrollSettings.workingHours" defaultMessage="Czas pracy" />
                </div>
                <div>
                  <RoundingSettings
                    {...this.props}
                    type="start"
                    settings={this.state}
                    changeSetting={this.changeSetting}
                    toleranceDisabled={this.state.payoutSetting?.type === 'shifts_unless_fixed'}
                  />
                  <RoundingSettings
                    {...this.props}
                    type="end"
                    settings={this.state}
                    changeSetting={this.changeSetting}
                  />
                </div>
              </div>
            </Modal.Body>
            {this.props.showSaveButton && (
              <Modal.Footer>
                <Button modifiers="orange small" onClick={this.handleSaveClick}>
                  <FormattedMessage id="payrollSettings.saveChanges" defaultMessage="Zatwierdź i wczytaj dane" />
                </Button>
              </Modal.Footer>
            )}
          </div>
        </FreemiumWrapper>
      </Modal>
    );
  }
}

OLDPayrollSettingsModal.contextTypes = {
  intl: PropTypes.shape({}),
};

OLDPayrollSettingsModal.propTypes = {
  payrollUI: PropTypes.shape({
    showSettingsModal: PropTypes.bool,
  }),
  changePayoutSetting: PropTypes.func,
  togglePayrollSettingsModal: PropTypes.func,
  settings: PropTypes.shape({
    payoutSetting: PropTypes.shape({}),
    roundingSetting: PropTypes.shape({}),
    nighthours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breaksValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breaksSetting: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
    timeFormatSetting: PropTypes.shape({}),
  }),
  changeRoundingSetting: PropTypes.func,
  changePayrollSettings: PropTypes.func,
  changeTimeFormatSettings: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  savePayrollSettings: PropTypes.func,
  hideShiftsNoExtra: PropTypes.bool,
  onSave: PropTypes.func,
  showSaveButton: PropTypes.bool,
};
export default OLDPayrollSettingsModal;
