import moment from 'moment';

import { getMonthlyNormsForExport } from '@/utils/exports/exportHelpers';

import { parseMinutesToFormat } from '../../dateHelper';

export const workingHoursHeaders = [
  'Kod referencyjny',
  'Nazwisko',
  'Imię',
  '50%',
  '100%',
  'Nocne',
  'Nominał',
  'Przepracowane',
];
export const workingHoursCodes = ['', '', '', '30006', '30007', '30008', '30003', '30010'];

export const absencesHeaders = ['Kod referencyjny', 'Nazwisko', 'Imię', 'Kod', 'Data_od', 'Data_do'];

export const getDate = mainDateStore => {
  const {
    dateMode,
    customDate: { start, end },
  } = mainDateStore;
  if (dateMode !== 'month') {
    return `${moment(start).format('DD.MM.YY')} - ${moment(end).format('DD.MM.YY')}`;
  }

  return moment(start).format('MM.YYYY');
};

export const getWorkingHoursData = (rawData, mainDateStore, monthlyNorms, timeFormatType) =>
  rawData.relevantEmployeeRows.map(({ employee, relevantRowInfo }) => {
    const employeeWeeklyHoursNorm = employee.employment_conditions.weekly_working_minutes / 60;
    const monthlyNorm = getMonthlyNormsForExport(mainDateStore, monthlyNorms, employeeWeeklyHoursNorm);

    return [
      employee.reference_id,
      employee.last_name,
      employee.first_name,
      parseMinutesToFormat(relevantRowInfo.sumOvertime50, timeFormatType, true),
      parseMinutesToFormat(relevantRowInfo.sumOvertime100, timeFormatType, true),
      parseMinutesToFormat(relevantRowInfo.sumNightHours, timeFormatType, true),
      monthlyNorm ? parseMinutesToFormat(monthlyNorm, timeFormatType, true) : '-',
      parseMinutesToFormat(relevantRowInfo.sumHours, timeFormatType, true),
    ];
  });

export const getAbsencesData = rawData =>
  rawData.relevantEmployeeRows.flatMap(({ employee, relevantRowInfo }) => {
    const absences = relevantRowInfo.details.reduce((acc, cur) => {
      if (!cur.absence || acc.some(absence => absence.id === cur.absence.id)) {
        return acc;
      }

      return [...acc, cur.absence];
    }, []);

    const absencesRows = absences.map(absence => [
      employee.reference_id,
      employee.last_name,
      employee.first_name,
      absence.code,
      absence.from,
      absence.to,
    ]);

    return absencesRows;
  });
