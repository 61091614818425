import { connect } from 'react-redux';

import { createLoanEmployeesProposal } from '@/actions/loanEmployeesProposals';
import { getByLocationGroupAndJobTitle } from '@/actions/locationGroup';
import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL } from '@/constants/ActionTypes';

import OutgoingLoanEmployeesProposalModal from './OutgoingLoanEmployeesProposalModal';

const mapStateToProps = state => ({
  modalObject: state.reducer.uiState.modalObject[OUTGOING_LOAN_EMPLOYEES_PROPOSAL_MODAL],
  userEmployees: state.reducer.userEmployees,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  userLocations: state.reducer.userLocations,
  currentUser: state.reducer.currentUser.user,
});

const mapDispatchToProps = {
  hideModalAndLaterClearModalObject,
  getByLocationGroupAndJobTitle,
  createLoanEmployeesProposal,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingLoanEmployeesProposalModal);
