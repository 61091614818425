import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import * as actionTypes from '@/constants/ActionTypes.js';

import { addAbsencesLimitTemplates } from '../absences.js';
import { initEmploymentConditionsFilter } from '../employmentConditionsFilter.js';
import { conn,showConfirmModal } from '../index';

const employementConditionsMessages = defineMessages({
  confirmModalTitle: {
    id: 'employmentCondition.confirmModal.title',
    defaultMessage: 'Usuń warunek',
  },
  confirmModalDescription: {
    id: 'employmentCondition.confirmModal.descriptionWarning',
    defaultMessage: 'Usuwając warunek zatrudnienia utracisz możliwość przywrócenia jego',
  },
  choosenOneCondition: {
    id: 'employmentCondition.confirmModal.choosenOneCondition',
    defaultMessage: 'wybrany warunek',
  },
  confirmModalButtonText: {
    id: 'employmentCondition.confirmModal.buttonText',
    defaultMessage: 'Usuń',
  },
  massConfirmModalTitle: {
    id: 'employmentCondition.massConfirmModal.title',
    defaultMessage: 'Usuń warunki',
  },
  massConfirmModalDescription: {
    id: 'employmentCondition.massConfirmModal.description',
    defaultMessage: 'Czy na pewno chcesz usunąć {length, plural, one {# warunek} few {# warunki} other {# warunków}}?',
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Błąd',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Dodano!',
  },
  toastrDeleteSuccess: {
    id: 'employmentCondition.toastr.deleteSuccess',
    defaultMessage: 'Pomyślnie usunięto {length, plural, one {warunek} other {warunki}}!',
  },
  toastrDeleteError: {
    id: 'employmentCondition.toastr.deleteError',
    defaultMessage: 'Wystąpił błąd podczas usuwania {length, plural, one {warunku} other {warunków}}',
  },
  toastrUpdateSuccess: {
    id: 'employmentConditions.toastr.updateSuccess',
    defaultMessage: 'Pomyślnie zmieniono warunek',
  },
  toastrUpdateError: {
    id: 'employmentCondition.toastr.updateError',
    defaultMessage: 'Wystąpił błąd podczas edytowania warunku',
  },
  toastrAddSuccess: {
    id: 'employmentConditions.toastr.addSuccess',
    defaultMessage: 'Dodano warunek {name}',
  },
  toastrAddError: {
    id: 'employmentCondition.toastr.addError',
    defaultMessage: 'Wystąpił błąd podczas dodawania warunku',
  },
  editEmploymentConditionConfirmationTitle: {
    id: 'employmentCondition.editConfirmation.title',
    defaultMessage: 'Edytuj warunek',
  },
  editEmploymentConditionConfirmationDescription: {
    id: 'employmentCondition.editConfirmation.description',
    defaultMessage: 'Czy chcesz nadpisać wprowadzone zmiany w warunku zatrudnienia dla wszystkich pracowników?',
  },
  editWithOverrideButton: {
    id: 'employmentCondition.editConfirmation.withOverrideButton',
    defaultMessage: 'Tak, nadpisz',
  },
  editWithoutOverrideButton: {
    id: 'employmentCondition.editConfirmation.withoutOverrideButton',
    defaultMessage: 'Nie nadpisuj',
  },
  yes: {
    id: 'common.yes',
    defaultMessage: 'Tak',
  },
  no: {
    id: 'common.no',
    defaultMessage: 'Nie',
  },
  editEmploymentConditionSecondConfirmationTitle: {
    id: 'employmentCondition.editSecondConfirmation.title',
    defaultMessage: 'Czy jesteś pewien?',
  },
  editEmploymentConditionSecondConfirmationDesc: {
    id: 'employmentCondition.editSecondConfirmation.description',
    defaultMessage:
      'Twoje zmiany wpłyną na WSZYSTKICH pracowników w Twojej firmie. Czy jesteś pewien że chcesz nadpisać zmiany?',
  },
});

export const getEmploymentConditions = (companyId, requestType) => dispatch =>
  new Promise((resolve, reject) => {
    conn
      .getEmploymentConditions(companyId, requestType)
      .then(result => {
        dispatch({ type: actionTypes.GET_EMPLOYMENT_CONDITIONS_SUCCESFUL, payload: result.data.templates });
        dispatch(initEmploymentConditionsFilter(result.data.templates));
        resolve(result.data.templates);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });

export const addEmploymentCondition = (condition, templates) => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .addEmploymentCondition(condition)
      .then(result => {
        dispatch({
          type: actionTypes.ADD_EMPLOYMENT_CONDITION,
          payload: result.data,
        });
        if (templates.length !== 0) {
          dispatch(addAbsencesLimitTemplates(templates, result.data.id));
        }
        toastr.success(
          intl.formatMessage(employementConditionsMessages.add, {}),
          intl.formatMessage(employementConditionsMessages.toastrAddSuccess, { name: condition.name }),
        );
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(employementConditionsMessages.error, {}),
          intl.formatMessage(employementConditionsMessages.toastrAddError, {}),
        );
        console.error(err);
        reject(err);
      });
  });

export const updateEmploymentCondition = condition => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .updateEmploymentCondition(condition)
      .then(result => {
        dispatch({
          type: actionTypes.UPDATE_EMPLOYMENT_CONDITION,
          payload: result.data,
        });
        toastr.success(intl.formatMessage(employementConditionsMessages.toastrUpdateSuccess, {}));
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(employementConditionsMessages.error, {}),
          intl.formatMessage(employementConditionsMessages.toastrUpdateError, {}),
        );
        console.error(err);
        reject(err);
      });
  });

export const deleteEmploymentCondition = (conditionId, templateToReplace) => (dispatch, getState, intl) =>
  new Promise((resolve, reject) => {
    conn
      .deleteEmploymentCondition(conditionId, templateToReplace)
      .then(result => {
        dispatch({ type: actionTypes.DELETE_EMPLOYMENT_CONDITION, payload: result.data });
        toastr.success(intl.formatMessage(employementConditionsMessages.toastrDeleteSuccess, { length: 1 }));
        resolve(result.data);
      })
      .catch(err => {
        toastr.error(
          intl.formatMessage(employementConditionsMessages.error, {}),
          intl.formatMessage(employementConditionsMessages.toastrDeleteError, { length: 1 }),
        );
        console.error(err);
        reject(err);
      });
  });

export const massDeleteEmploymentCondition = idArray => (dispatch, getState, intl) => {
  conn
    .deleteEmploymentConditionArray(idArray)
    .then(() => {
      dispatch({ type: actionTypes.MASS_DELETE_EMPLOYMENT_CONDITIONS, payload: idArray });
      toastr.success(intl.formatMessage(employementConditionsMessages.toastrDeleteSuccess, { length: idArray.length }));
    })
    .catch(err => {
      toastr.error(
        intl.formatMessage(employementConditionsMessages.error, {}),
        intl.formatMessage(employementConditionsMessages.toastrDeleteError, { length: idArray.length }),
      );
      console.error(err);
    });
};

export const deleteEmploymentConditionConfirm = (employmentCondition, templateToReplace) => (
  dispatch,
  getState,
  intl,
) => {
  const title = intl.formatMessage(employementConditionsMessages.confirmModalTitle, {});
  const description = intl.formatMessage(employementConditionsMessages.confirmModalDescription, {});
  const confirmText = intl.formatMessage(employementConditionsMessages.confirmModalButtonText, {});
  const confirmFunc = () => {
    dispatch(deleteEmploymentCondition(employmentCondition.id, templateToReplace));
  };
  const deletedThing = intl.formatMessage(employementConditionsMessages.choosenOneCondition, {});
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const massDeleteEmploymentConditionConfirm = () => (dispatch, getState, intl) => {
  const { selected } = getState().reducer.listsUi.employmentConditions;
  const title = intl.formatMessage(employementConditionsMessages.massConfirmModalTitle, {});
  const description = intl.formatMessage(employementConditionsMessages.massConfirmModalDescription, {
    length: selected.length,
  });
  const confirmText = intl.formatMessage(employementConditionsMessages.confirmModalButtonText, {});
  const confirmFunc = () => {
    dispatch(massDeleteEmploymentCondition(selected));
  };
  const confirmType = 'warning';
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
    }),
  );
};

export const editEmploymentConditionSecondConfirmation = submitFunc => (dispatch, getState, intl) => {
  const title = intl.formatMessage(employementConditionsMessages.editEmploymentConditionSecondConfirmationTitle, {});
  const description = (
    <span style={{ textAlign: 'center', display: 'inline-block' }}>
      <i className="material-icons" style={{ fontSize: '120px', color: '#e43a3a' }}>
        error_outline
      </i>
      <br />
      <strong>
        {intl.formatMessage(employementConditionsMessages.editEmploymentConditionSecondConfirmationDesc, {})}
      </strong>
    </span>
  );
  const cancelText = intl.formatMessage(employementConditionsMessages.yes, {});
  const confirmText = intl.formatMessage(employementConditionsMessages.no, {});
  const confirmFunc = () => {
    dispatch(editEmploymentConditionConfirmation(submitFunc));
  };
  const cancelFunc = () => {
    submitFunc(true);
  };
  const confirmType = 'info';

  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
      confirmBtnModifiers: ['reverse-blue'],
      cancelText,
      cancelFunc,
      cancelBtnModifiers: ['red'],
    }),
  );
};

export const editEmploymentConditionConfirmation = submitFunc => (dispatch, getState, intl) => {
  const title = intl.formatMessage(employementConditionsMessages.editEmploymentConditionConfirmationTitle, {});
  const description = intl.formatMessage(
    employementConditionsMessages.editEmploymentConditionConfirmationDescription,
    {},
  );
  const confirmText = intl.formatMessage(employementConditionsMessages.editWithOverrideButton, {});
  const cancelText = intl.formatMessage(employementConditionsMessages.editWithoutOverrideButton, {});
  const confirmFunc = () => {
    dispatch(editEmploymentConditionSecondConfirmation(submitFunc));
  };
  const cancelFunc = () => {
    submitFunc(false);
  };
  const confirmType = 'info';

  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      confirmType,
      cancelText,
      cancelFunc,
      preventClose: true,
    }),
  );
};
