export const formattingHeaders = {
  'Suma Godzin': 'dp',
  'Godziny nocne': 'dp',
  Różnica: 'dp',
  'Nadgodziny 50%': 'dp',
  'Nadgodziny 100%': 'dp',
  'Nadgodziny ŚT': 'dp',
  'Czas Grafik': 'dp',
  'Czas RCP': 'dp',
  'Czas Obecności': 'dp',
};
