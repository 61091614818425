/* eslint camelcase:0 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import NameCell from '@/components/common/NameCell.jsx';
import OptionsCell from '@/components/common/OptionsCell.jsx';
import {
  BUDGET_INFO_HIDE,
  COMPANY_MANAGE_JOBTITLES_HIDE_DELETE,
  COMPANY_MANAGE_JOBTITLES_HIDE_EDIT,
} from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';

class LocationsRow extends PureComponent {
  render() {
    const { id, title, color, hourly_wage, factor } = this.props.data;
    let factorValue = Number(factor);
    const factorDec = factorValue.toString().split('.')[1];
    let factorDecLen = !!factorDec && factorDec.length > 2 ? factorDec.length : 2;
    factorDecLen = (!!factorDecLen && Number(factorDecLen)) > 9 ? 9 : factorDecLen;
    factorValue = factorValue.toFixed(factorDecLen);

    const rowOptions = [
      {
        option: <FormattedMessage id="common.edit" defaultMessage="Edytuj" />,
        restriction: COMPANY_MANAGE_JOBTITLES_HIDE_EDIT,
        style: 'edit',
        function: this.props.edit,
      },
      {
        option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
        restriction: COMPANY_MANAGE_JOBTITLES_HIDE_DELETE,
        style: 'delete',
        function: this.props.delete,
      },
    ];

    const { options, styles, functions } = applyPermissionsForOptions(rowOptions, this.props.userPermissions);
    const wage = (hourly_wage / 100).toFixed(2);
    return (
      <tr data-test={`JobTitlesRow-${title}`}>
        <td>
          <Checkbox
            checked={this.props.stateUI.selected.includes(id)}
            toggle={this.props.toggle}
            style={{ margin: '0', marginLeft: '3px' }}
            testId="JobTitlesRow-rowCheckbox"
          />

          <NameCell name={title} color={color} />
        </td>

        <FeatureWrapper restriction={BUDGET_INFO_HIDE}>
          <td>{wage}</td>
        </FeatureWrapper>
        {this.props.showFactor ? <td>{factorValue}</td> : null}
        <td>
          <OptionsCell options={options} styles={styles} functions={functions} />
        </td>
      </tr>
    );
  }
}
LocationsRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    hourly_wage: PropTypes.number,
    factor: PropTypes.string,
  }).isRequired,
  stateUI: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  toggle: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  showFactor: PropTypes.bool,
};
export default LocationsRow;
