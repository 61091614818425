import { Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import AttendanceView from '@/components/attendance/AttendanceView';
import NewPayrollView from '@/components/newPayrollViews/newPayroll/PayrollView/PayrollView.redux';
import NewPayrollLocationView from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationView/PayrollLocationView.redux';
import { OnboardingStepOneFunctionalWrapper } from '@/components/onboarding/OnboardingStepOne/OnboardingStepOneFunctionalWrapper';
import { OnboardingStepThreeFunctionalWrapper } from '@/components/onboarding/OnboardingStepThree/OnboardingStepThreeFunctionalWrapper';
import { browserHistory } from '@/constants/browserHistory.ts';
import EmployeeDashboardContainer from '@/containers/employee/dashboard/EmployeeDashboard.js';
import MainAppView from '@/containers/MainApp.js';
import MainPermissionsWrapper from '@/containers/MainPermissionsWrapper.js';
import NotificationViewContainer from '@/containers/notifications/NotificationViewContainer.js';
import OnboardingFirstHelloContainer from '@/containers/onboarding/OnboardingFirstHelloContainer.jsx';
import OnboardingStepLayoutContainer from '@/containers/onboarding/OnboardingStepLayoutContainer.js';
import ExpiredPasswordContainer from '@/containers/password/ExpiredPasswordContainer.js';
import ResetPasswordSendEmailContainer from '@/containers/password/ResetPasswordSendEmailContainer.jsx';
import ResetPasswordSetNewContainer from '@/containers/password/ResetPasswordSetNewContainer.jsx';
import PermissionWrapper from '@/containers/PermissionWrapper.js';
import SchedulerViewContainer from '@/containers/SchedulerViewContainer.js';

import SettingsViewContainer from '../containers/SettingsViewContainer.js';
import AbsencesModeViewContainer from './absences/AbsencesModeView/AbsencesModeViewContainer.js';
import ManageAbsenceTypesView from './absences/ManageAbsenceTypesView/ManageAbsenceTypesView.redux.js';
import AvailabilityView from './AvailabilityView.jsx';
import ChatContainer from './chat/Chat.redux';
import ExportsCatalogView from './common/exports/NewExports/ExportsCatalogView/ExportsCatalogView.redux.js';
import ExportsHistoryView from './common/exports/NewExports/ExportsHistoryView/ExportsHistoryView.redux.js';
import NewExportsView from './common/exports/NewExports/ExportsView.jsx';
import ManageAvaTypesView from './companymanage/avatypes/ManageAvaTypesView.jsx';
import CompanyManageViewContainer from './companymanage/CompanyManageView/CompanyManageView.redux.js';
import DevicesView from './companymanage/devices/DevicesView/DevicesView.jsx';
import ManageEmployeesView from './companymanage/employees/ManageEmployeesView/ManageEmployeesView.redux.js';
import EmploymentConditionsView from './companymanage/employmentConditions/EmploymentConditionsView/EmploymentConditionsView.jsx';
import ManageJobTitlesView from './companymanage/jobtitles/ManageJobTitlesView.jsx';
import ManageLocationsView from './companymanage/locations/ManageLocationsView.jsx';
import RolesView from './companymanage/roles/RolesView.redux';
import ManageShiftBlocksView from './companymanage/shiftblocks/ManageShiftBlocksView.jsx';
import DashboardViewContainer from './dashboard/DashboardView/DashboardView.redux.js';
import OwnerTrialDashboard from './dashboard/owner/OwnerTrialDashboard.redux.js';
import EmployeeNewPayrollView from './employee/newPayroll/EmployeePayrollView.tsx';
import EmployeePayrollView from './employee/payroll/EmployeePayrollView.jsx';
import EmployeeScheduleView from './employee/schedule/EmployeeScheduleView/EmployeeScheduleView.jsx';
import ErrorPage from './ErrorPage.jsx';
import InvoiceStatusView from './invoiceStatusView/InvoiceStatusView';
import OnboardingStepTwo from './onboarding/OnboardingStepTwo/OnboardingStepTwo.jsx';
import Page404 from './Page404.jsx';
import PayrollLocationView from './payrollLocation/PayrollLocationView.redux.js';
import PayrollView from './PayrollView.jsx';
import ProductionQuotasView from './ProductionQuotasView.jsx';
import NewReportsViewContainer from './reports-NEW/ReportsView/ReportsView.redux.js';
import ReportsView from './reports-NN/ReportsView/ReportsView.tsx';
import BudgetMetricsView from './settings/BudgetMetricsView/BudgetMetricsView.redux';
import ImportView from './settings/ImportView/ImportView.redux.jsx';
import LocationSettingsView from './settings/LocationSettingsView/LocationSettingsView.redux.js';
import OptionsView from './settings/OptionsView/OptionView.redux.js';
import PaymentsView from './settings/PaymentsView/PaymentsView.redux';
import EmployeeSettingsView from './settings/UserDataView/EmployeeSettings/EmployeeSettingsView.redux';
import UserDataView from './settings/UserDataView/UserDataView.redux';
import RCPRedirect from './static/RCPRedirect.jsx';
import EmployeeTimesheetView from './timesheet/EmployeeTimesheetView/EmployeeTimesheetView';
import UnlockAccountView from './UnlockAccountView/UnlockAccountView.redux';

export const KadroRoutes = () => (
  <HistoryRouter history={browserHistory}>
    <Routes>
      <Route path="/" element={<MainAppView />}>
        <Route element={<MainPermissionsWrapper />}>
          <Route
            index
            element={
              <PermissionWrapper employee={<EmployeeDashboardContainer />} manager={<DashboardViewContainer />} />
            }
          />
          <Route path="trial" element={<OwnerTrialDashboard />} />
          <Route path="notifications" element={<NotificationViewContainer />} />
          <Route path="companymanage" element={<CompanyManageViewContainer />}>
            <Route path="employees" element={<ManageEmployeesView />} />
            <Route path="shiftblocks" element={<ManageShiftBlocksView />} />
            <Route path="locations" element={<ManageLocationsView />} />
            <Route path="jobtitles" element={<ManageJobTitlesView />} />
            <Route path="avatypes" element={<ManageAvaTypesView />} />
            <Route path="employmentConditions" element={<EmploymentConditionsView />} />
            <Route path="roles" element={<RolesView />} />
            <Route path="devices" element={<DevicesView />} />
            <Route path="absence-types" element={<ManageAbsenceTypesView />} />
          </Route>
          <Route
            path="schedule"
            element={<PermissionWrapper employee={<EmployeeScheduleView />} manager={<SchedulerViewContainer />} />}
          />
          <Route path="availabilities" element={<AvailabilityView />} />
          <Route path="attendance" element={<AttendanceView />} />
          <Route
            path="payroll"
            element={<PermissionWrapper employee={<EmployeePayrollView />} manager={<PayrollView />} />}
          />
          <Route
            path="newPayroll"
            element={<PermissionWrapper employee={<EmployeePayrollView />} manager={<NewPayrollView />} />}
          />
          <Route path="payroll_location" element={<PayrollLocationView />} />
          <Route path="newPayrollLocation" element={<NewPayrollLocationView />} />
          <Route path="employee-payroll" element={<EmployeeNewPayrollView />} />
          <Route path="exports" element={<NewExportsView />}>
            <Route path="catalog" element={<ExportsCatalogView />} />
            <Route path="history" element={<ExportsHistoryView />} />
          </Route>
          <Route path="reports" element={<NewReportsViewContainer />} />
          <Route path="reports-v2" element={<ReportsView />} />
          <Route path="settings" element={<SettingsViewContainer />}>
            <Route index element={<Navigate to="options" replace />} />
            <Route
              path="user-data"
              element={<PermissionWrapper employee={<EmployeeSettingsView />} manager={<UserDataView />} />}
            />
            <Route path="location" element={<LocationSettingsView />} />
            <Route path="location/:locationName" element={<LocationSettingsView />} />
            <Route path="options" element={<OptionsView />} />
            <Route path="import" element={<ImportView />} />
            <Route path="payments" element={<PaymentsView />} />
            <Route path="budget-metrics" element={<BudgetMetricsView />} />
          </Route>
          <Route path="timesheet" element={<EmployeeTimesheetView />} />
          <Route path="production_quotas" element={<ProductionQuotasView />} />
          <Route path="absences" element={<AbsencesModeViewContainer />} />
          <Route path="chat" element={<ChatContainer />} />
          <Route path="403" element={<ErrorPage />} />
        </Route>
      </Route>
      <Route path="password">
        <Route index element={<ResetPasswordSendEmailContainer />} />
        <Route path="expired" element={<ExpiredPasswordContainer />} />
        <Route path="setNew" element={<ResetPasswordSetNewContainer />} />
        <Route path=":token" element={<ResetPasswordSetNewContainer />} />
      </Route>

      <Route path="blocked-account" element={<UnlockAccountView />} />
      <Route path="invoice" element={<InvoiceStatusView />} />

      <Route path="ecp" element={<RCPRedirect />} />
      <Route path="newUser" element={<Navigate to="../onboarding" replace relative="route" />} />
      <Route path="registration" element={<Navigate to="../onboarding" replace />} />
      <Route path="onboarding">
        <Route index element={<Navigate to="newUser" replace />} />
        <Route path="newUser" element={<OnboardingFirstHelloContainer />} />
        <Route element={<OnboardingStepLayoutContainer />}>
          <Route path="stepOne" element={<OnboardingStepOneFunctionalWrapper />} />
          <Route path="stepTwo" element={<OnboardingStepTwo />} />
          <Route path="stepThree" element={<OnboardingStepThreeFunctionalWrapper />} />
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  </HistoryRouter>
);
