import classNames from 'classnames';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { UseSideBarValuesType } from '../useSideBar';
import { useSidebarNotification } from './useNotification';

type Props = {
  closeMobileMenu: UseSideBarValuesType['closeMobileMenu'];
};

export const Notification = memo(({ closeMobileMenu }: Props) => {
  const { isActive, isLoggedUserEmployee, openNotification } = useSidebarNotification(closeMobileMenu);

  if (isLoggedUserEmployee) return null;

  return (
    <li
      className={classNames('k-sideBarMenu__item', {
        'k-sideBarMenu__item--active': isActive,
      })}
    >
      <button className="k-sideBarMenu__itemLink" onClick={openNotification} type="button">
        <i className="k-sideBarMenu__itemIcon material-icons">notifications</i>
        <span className="k-sideBarMenu__itemLabel">
          <FormattedMessage id="view.sidebar.notificationCounter" defaultMessage="Notyfikacje" />
        </span>
      </button>
    </li>
  );
});

Notification.displayName = 'Notification';
