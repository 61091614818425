import { Timestamp } from '@/types/dates.types';

export const getAmountOfHiddenItems = <T>(arr: T[], visibleAmount = 1): string | null => {
  const totalAmount = arr.length;
  const hiddenAmount = totalAmount - visibleAmount;
  return hiddenAmount > 0 ? `(+${hiddenAmount})` : null;
};

export const isEmptyArray = <T>(arr: T[] = []) => arr.length === 0;

export const zipWith = (...arrays) => {
  const fn = arrays.pop();
  const lengthOfLongestArr = Math.max(...arrays.map(arr => arr.length));
  const result = new Array(lengthOfLongestArr);
  for (let i = 0; i < lengthOfLongestArr; i++) {
    const group = arrays.map(arr => arr[i]);
    result[i] = fn(...group);
  }
  return result;
};

export const mapId = <T extends { id: string }>(arr: T[]) => arr.map(({ id }) => id);

export const sortByStartTimestamp = <T extends { start_timestamp: Timestamp }>(arr: T[]) =>
  arr.sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));

export const sortNumbersDesc = (numbers: number[]) => numbers.sort((a, b) => b - a);

export const difference = <T = string | number>(arr1: T[] = [], arr2: T[] = []) => arr1.filter(x => !arr2.includes(x));

export const unionBy = <T>(a: T[], b: T[], iteratee: ((item: T) => unknown) | string): T[] => {
  const iterateeFn = typeof iteratee === 'function' ? iteratee : (item: T) => (item as any)[iteratee];
  const s = new Set(a.map(iterateeFn));
  return Array.from(new Set([...a, ...b.filter(x => !s.has(iterateeFn(x)))]));
};
