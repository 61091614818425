import { connect } from 'react-redux';

import { deleteShiftRequest, showConfirmModal } from '@/actions';
 import { showModal } from '@/actions/uiState.js';
import { SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes.js';
import { selectAreShiftsFromOtherLocationsShown } from '@/redux-store/schedule/viewSettings';

import ScheduleTableRow from './ScheduleTableRow.jsx';

const emptyShifts = {};

const mapStateToProps = (state, ownProps) => ({
  employee: state.reducer.employees.data[ownProps.employeeId],
  employeeShifts: state.reducer.shifts.data[ownProps.employeeId]
    ? state.reducer.shifts.data[ownProps.employeeId].shifts
    : emptyShifts,
  mainDateStore: state.reducer.mainDateStore,
  employeeOvertimeCollections: state.reducer.overtimeCollections[ownProps.employeeId],
  employeeAbsences: state.reducer.absences.scheduleAbsences[ownProps.employeeId],
  absenceTypes: state.reducer.absences.absencesTypes,
  isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
  restrictions: state.reducer.userPermissions.restrictions,
  availabilitiesModeEnabled: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  scheduleModeEnabled: state.reducer.schedule.viewMode.viewMode === SCHEDULE_DISPLAY_MODE_TYPE,
  employeeContracts: state.reducer.contracts[ownProps.employeeId],
  loanedEmployee: state.reducer.scheduleLoanedEmployees?.scheduleLoanedEmployees[ownProps.employeeId],
  userLocations: state.reducer.userLocations,
  freeDaysForEmployee: state.reducer.freeDaysMarking[ownProps.employeeId],
  showShiftsFromOtherLocation: selectAreShiftsFromOtherLocationsShown(state),
});

const mapDispatchToProps = { showModal, showConfirmModal, deleteShiftRequest   };

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableRow);
