import { connect } from 'react-redux';

import { inviteEmployees, massDeleteEmployeesConfirm } from '@/actions/companymanage';
import { exportData, exportSelectedEmployeesAsFile } from '@/actions/exports.js';
import { trackIntercomEvent } from '@/actions/intercom';
import { showModal, toggleExportModal } from '@/actions/uiState.js';

import EmployeesActionsButton from './EmployeesActionsButton.jsx';

const mapStateToProps = state => ({
  employeesListUi: state.reducer.listsUi.employees,
  currentUserId: state.reducer.currentUser.user.id,
  restrictions: state.reducer.userPermissions.restrictions,
});

const mapDispatchToProps = {
  showModal,
  massDeleteEmployeesConfirm,
  inviteEmployees,
  toggleExportModal,
  exportData,
  exportSelectedEmployeesAsFile,
  trackIntercomEvent,
};

const EmployeesButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeesActionsButton);

export default EmployeesButtonBarContainer;
