import { connect } from 'react-redux';

import { hideModal } from '@/actions/uiState.js';
import { CONFLICTS_MODAL } from '@/constants/modalTypes.js';

import ShowConflictsModal from './ShowConflictsModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === CONFLICTS_MODAL,
  modalObject: state.reducer.uiState.modalObject[CONFLICTS_MODAL],
  userEmployees: state.reducer.userEmployees,
  dateStore: state.reducer.mainDateStore,
  calendarData: state.reducer.calendarData,
});

const mapDispatchToProps = {
  hideModal,
};

const ShowConflictsModalContainer = connect(mapStateToProps, mapDispatchToProps)(ShowConflictsModal);

export default ShowConflictsModalContainer;
