import * as AT from '@/constants/ActionTypes.js';

const initialState = [];

const setMetricsVisibility = (metrics, visible = false) =>
  metrics.map(metric => ({
    ...metric,
    visible,
  }));

const budgetMetrics = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_BUDGET_METRICS_SUCCESS:
      return setMetricsVisibility(action.payload);
    case AT.DELETE_BUDGET_METRICS_SUCCESS:
      return state.filter(({ id }) => !action.payload.ids.includes(id));
    case AT.CREATE_BUDGET_METRICS_SUCCESS:
    case AT.EDIT_BUDGET_METRICS_SUCCESS: {
      const ids = action.payload.map(({ id }) => id);
      return [...state.filter(({ id }) => !ids.includes(id)), ...setMetricsVisibility(action.payload)];
    }
    case AT.SET_VISIBLE_METRICS:
      return state.map(({ id, visible: _, ...data }) => ({
        id,
        visible: action.payload.includes(id),
        ...data,
      }));
    default:
      return state;
  }
};
export default budgetMetrics;
