import { connect } from 'react-redux';

import {
  changeAbsencesData,
  exportAbsenceItemToPdf,
  getAbsenceHistory,
  showAbsenceCalendar,
} from '@/actions/absences.js';
import { showModal } from '@/actions/uiState';

import AbsenceViewOptions from './AbsenceViewOptions';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  showModal,
  changeAbsencesData,
  exportAbsenceItemToPdf,
  getAbsenceHistory,
  showAbsenceCalendar,
};

const AbsenceViewOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceViewOptions);

export default AbsenceViewOptionsContainer;
