import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  noChange: {
    id: 'settings.passwordRefresh.noChange',
    defaultMessage: 'Brak zmiany haseł',
  },
  days30: {
    id: 'settings.passwordRefresh.30days',
    defaultMessage: 'Co 30 dni',
  },
  days90: {
    id: 'settings.passwordRefresh.90days',
    defaultMessage: 'Co 90 dni',
  },
  days180: {
    id: 'settings.passwordRefresh.180days',
    defaultMessage: 'Co 180 dni',
  },
  passwordPolicy: {
    id: 'settings.options.passwordPolicy',
    defaultMessage: 'Polityka haseł',
  },
  passwordRefreshTitle: {
    id: 'settings.passwordRefresh.selectTitle',
    defaultMessage: 'Okresowa zmiana haseł',
  },
  passwordRefreshDescription: {
    id: 'settings.passwordRefresh.selectTitle.desc',
    defaultMessage:
      'Cykliczna zmiana hasła obowiązuje wszystkich użytkowników dopisanych do konta. Ustawienie cyklicznej zmiany hasła może wiązać się z potrzebą resetu hasła przez użytkowników',
  },
});
