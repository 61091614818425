import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationBar from '@/components/common/NotificationBar/NotificationBar.jsx';
import { intercomShowNewMessage } from '@/utils/intercomHelpers.js';

const salesMessage = `Dzień dobry,
Interesuje mnie wykupienie pierwszej subskrypcji Kadromierz. W jaki sposób mogę aktywować moje konto?`;

const TrialInfoTopBar = ({ companyStatus, hideTrialInfoBar, isVisible, isOwner }) => {
  if (!isVisible || !companyStatus.trial_expiration || companyStatus.status !== 'trial' || !isOwner) {
    return null;
  }

  const days = useMemo(() => moment(companyStatus.trial_expiration).diff(moment(), 'days') + 1, [
    companyStatus.trial_expiration,
  ]);

  if (days > 7 || days < 0) {
    return null;
  }

  return (
    <NotificationBar
      show
      content={
        <FormattedMessage
          id="trailInfoTopBar.message"
          defaultMessage="Do końca okresu próbnego {days, plural, one {pozostał # dzień} other {pozostało # dni}} "
          values={{ days }}
        />
      }
      canClose
      buttonText={<FormattedMessage id="trailInfoTopBar.button" defaultMessage="Skontaktuj się z nami" />}
      buttonOnClick={() => {
        intercomShowNewMessage(salesMessage);
      }}
      close={hideTrialInfoBar}
    />
  );
};

TrialInfoTopBar.propTypes = {
  companyStatus: PropTypes.shape({
    trial_expiration: PropTypes.string,
    status: PropTypes.string,
  }),
  isVisible: PropTypes.bool,
  isOwner: PropTypes.bool,
  hideTrialInfoBar: PropTypes.func,
};

export default TrialInfoTopBar;
