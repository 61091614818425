import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { parseMinutesToFullHours } from '@/utils/dateHelper';

import { messages } from '../OvertimeCollectionOptions.messages';

const OvertimeCollectionUsage = ({ overtimeCollections, overtimeUsageInMinutes }, { intl }) => {
  const { totalDailyOvertime } = overtimeCollections;
  const totalDailyOvertimeToUseInHours = useMemo(() => parseMinutesToFullHours(totalDailyOvertime), [
    totalDailyOvertime,
  ]);
  const overtimeUsageInHours = parseMinutesToFullHours(overtimeUsageInMinutes);
  const usageBiggerThanOvertime = totalDailyOvertime < overtimeUsageInMinutes;

  return (
    <>
      <div className="overtimeCollectionOptions__usage">
        {intl.formatMessage(messages.overtimeUsage, {
          totalDailyOvertimeToUse: totalDailyOvertimeToUseInHours,
          overtimeUsage: overtimeUsageInHours,
        })}
      </div>
      {usageBiggerThanOvertime && (
        <div className="overtimeCollectionOptions__usageToHigh">{intl.formatMessage(messages.overtimeUsageToHigh)}</div>
      )}
    </>
  );
};

OvertimeCollectionUsage.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

OvertimeCollectionUsage.propTypes = {
  overtimeCollections: PropTypes.shape({
    totalDailyOvertime: PropTypes.number,
  }),
  overtimeUsageInMinutes: PropTypes.number,
};

export default OvertimeCollectionUsage;
