import { connect } from 'react-redux';

import { clearBudgetMetricsImports,runExport } from '@/actions/exports.js';
import { toggleImportModalOverlay } from '@/actions/schedule.jsx';
import { hideModal } from '@/actions/uiState';
import { BUDGET_METRICS_IMPORT_MODAL } from '@/constants/modalTypes';

import ImportBudgetMetricsModal from './ImportBudgetMetricsModal';


const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === BUDGET_METRICS_IMPORT_MODAL,
  from: state.reducer.mainDateStore.customDate.start,
  to: state.reducer.mainDateStore.customDate.end,
  pendingExports: state.reducer.exports.pendingExports,
  activeLocationIds: state.reducer.scheduleLocationFilter,
  locationSettings: state.reducer.settings.locationSettings,
  showOverlay: state.reducer.scheduleUIState.importModal.showOverlay,
  language: state.reducer.currentUser.user.settings.language,
  budgetMetrics: state.reducer.budgetMetrics,
});

const mapDispatchToProps = {
  clearBudgetImports: clearBudgetMetricsImports,
  hideModal,
  runExport,
  toggleImportModalOverlay,
};

const ImportBudgetMetricsModalContainer = connect(mapStateToProps, mapDispatchToProps)(ImportBudgetMetricsModal);

export default ImportBudgetMetricsModalContainer;