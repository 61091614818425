import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import SelectBlock from '@/components/common/inputs/SelectBlock/SelectBlock.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import { languages } from '@/constants/intl';
import { LANGUAGE_SETTINGS_SHOW } from '@/constants/Permissions';
import { LANGUAGE_SETTINGS_HIDE } from '@/constants/Restrictions';
import { getDefaultLang } from '@/utils/intlHelpers.jsx';
import { settingsHasChanged } from '@/utils/objectHelpers';

import SettingsSection from '../../SettingsSection/SettingsSection.jsx';

import './LanguageSettings.scss';

const messages = defineMessages({
  panelHeader: {
    id: 'settings.language.panelHeader',
    defaultMessage: 'Ustawienia języka',
  },

  languageSettingTitle: {
    id: 'settings.language.title',
    defaultMessage: 'Wybierz język',
  },
});

const LanguageSettings = ({ currentUser, changeLanguage }, { intl }) => {
  const [language, setLanguage] = useState(getDefaultLang());
  const canSave = settingsHasChanged(currentUser.settings.language, language);
  const languageOptions = Object.keys(languages).map(lang => ({ id: lang, name: languages[lang] }));

  const handleInputChange = value => {
    setLanguage(value);
  };

  const handleSubmit = () => {
    changeLanguage(language);
  };

  useEffect(() => {
    setLanguage(currentUser.settings.language);
  }, [currentUser.settings.language]);

  return (
    <FeatureWrapper restriction={LANGUAGE_SETTINGS_HIDE} permission={LANGUAGE_SETTINGS_SHOW}>
      <SettingsSection
        canSave={canSave}
        title={intl.formatMessage(messages.panelHeader)}
        onSubmit={handleSubmit}
        panelModifiers={['no-scroll']}
      >
        <div className="languageSettings">
          <SelectBlock
            name={intl.formatMessage(messages.languageSettingTitle)}
            options={languageOptions}
            value={language}
            handleInput={handleInputChange}
          />
        </div>
      </SettingsSection>
    </FeatureWrapper>
  );
};

LanguageSettings.contextTypes = {
  intl: PropTypes.shape({}),
};

LanguageSettings.propTypes = {
  currentUser: PropTypes.shape({
    settings: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
  changeLanguage: PropTypes.func,
};

export default LanguageSettings;
