import {
  DEFAULT_COL_SPAN,
  SINGLE_DAY_COL_SPAN,
  WEEK_DAYS_LIMIT,
  WEEK_SCHEDULE_VIEW_DAYS_LIMIT,
} from '@/constants/scheduleStyleChangePoints';
import { MainDateStore } from '@/types/mainDateStore';

export const SCHEDULE_TOOLTIP_OFFSET_Y = -12;

export const getScheduleColSpan = (isSingleDay: boolean) => (isSingleDay ? SINGLE_DAY_COL_SPAN : DEFAULT_COL_SPAN);

export const getScheduleDaysColSpan = (dateArray: MainDateStore['dateArray'], isSingleDay: boolean) =>
  isSingleDay ? SINGLE_DAY_COL_SPAN : dateArray.length;

export const checkIsMonthlyScheduleView = (dateArray: MainDateStore['dateArray']) =>
  dateArray.length > WEEK_SCHEDULE_VIEW_DAYS_LIMIT;

export const checkIsWeekScheduleView = (dateArray: MainDateStore['dateArray']) => dateArray.length <= WEEK_DAYS_LIMIT;

export const getScheduleBlockSize = (dateArray: MainDateStore['dateArray']) =>
  checkIsWeekScheduleView(dateArray) ? 'week' : 'month';
