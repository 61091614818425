import PropTypes from 'prop-types';

import './ModalFooter.scss';

const ModalFooter = ({ children }) => <div className="mdModalFooter">{children}</div>;

ModalFooter.propTypes = {
  children: PropTypes.node,
};

export default ModalFooter;
