import { defineMessages } from 'react-intl';

const messages = defineMessages({
  workedHours: {
    id: 'reports.workedHours',
    defaultMessage: 'Przepracowane godziny',
  },
  plannedHours: {
    id: 'reports.plannedHours',
    defaultMessage: 'Zaplanowane godziny',
  },
  numberOfEmployeesWorked: {
    id: 'reports.numberOfEmployeesWorked',
    defaultMessage: 'Pracująca liczba pracowników',
  },
  numberOfEmployeesPlanned: {
    id: 'reports.numberOfEmployeesPlanned',
    defaultMessage: 'Zaplanowana liczba pracowników',
  },
  averageHoursPerEmployeeWorked: {
    id: 'reports.averageHoursPerEmployeeWorked',
    defaultMessage: 'Średni przepracowany czas',
  },
  averageHoursPerEmployeeWPlanned: {
    id: 'reports.averageHoursPerEmployeeWPlanned',
    defaultMessage: 'Średni zaplanowany czas',
  },
});

export const columns = [
  {
    accessor: 'date',
  },
  {
    accessor: 'workedHours',
    header: messages.workedHours,
    className: 'align-right',
  },
  {
    accessor: 'plannedHours',
    header: messages.plannedHours,
    className: 'align-right',
  },
  {
    accessor: 'numberOfEmployeesWorked',
    header: messages.numberOfEmployeesWorked,
    className: 'align-right',
  },
  {
    accessor: 'numberOfEmployeesPlanned',
    header: messages.numberOfEmployeesPlanned,
    className: 'align-right',
  },
  {
    accessor: 'averageHoursPerEmployeeWorked',
    header: messages.averageHoursPerEmployeeWorked,
    className: 'align-right',
  },
  {
    accessor: 'averageHoursPerEmployeeWPlanned',
    header: messages.averageHoursPerEmployeeWPlanned,
    className: 'align-right',
  },
];
