import { defineMessages } from 'react-intl';

import * as actionTypes from '@/constants/ActionTypes';

import { conn } from './index';

const messages = defineMessages({
  getByLocationGroupAndJobTitleError: {
    id: 'locationGroup.getByLocationGroupAndJobTitleError',
    defaultMessage: 'Nie udało się pobrać pracowników dla danej grupy lokalizacji',
  },
});

export const getByLocationGroupAndJobTitleError = () => (dispatch, _getState, intl) => {
  dispatch({
    type: actionTypes.GET_BY_LOCATION_GROUP_AND_JOB_TITLE_ERROR,
    notification: {
      title: intl.formatMessage(messages.getByLocationGroupAndJobTitleError),
      type: 'error',
    },
  });
};

export const getByLocationGroupAndJobTitle = (
  locationId: string,
  jobTitleId: string,
  date: string,
) => async dispatch => {
  try {
    const { data } = await conn.getByLocationGroupAndJobTitle(locationId, jobTitleId, date);
    return data;
  } catch (e) {
    dispatch(getByLocationGroupAndJobTitleError());
  }
};
