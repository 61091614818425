import { defineMessages } from 'react-intl';

import { changeScheduleLocationFilter } from '@/actions/schedule/scheduleLocationFilter';
import {
  DELETE_ALL_NOTIFICATION_SUCCESFUL,
  DELETE_NOTIFICATION_SUCCESFUL,
  GET_NOTIFICATIONS_SUCCESFUL,
  GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL,
  MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_SUCCESFUL,
  MARK_NOTIFICATION_AS_UNREAD_ERROR,
  MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL,
} from '@/constants/ActionTypes.js';
import browserHistory from '@/constants/browserHistory';
import { mapActivityLogMessages } from '@/utils/activityLogHelpers';

import { conn, connectionError } from './index';
import { mainDateMoveToDate } from './mainDate.js';

export const deleteAllNotificationsSuccesful = () => ({
  type: DELETE_ALL_NOTIFICATION_SUCCESFUL,
});

export const deleteNotificationSuccesful = data => ({
  type: DELETE_NOTIFICATION_SUCCESFUL,
  payload: data,
});

export const markNotificationAsReadError = data => ({
  type: MARK_NOTIFICATION_AS_READ_ERROR,
  payload: data,
});

export const markNotificationAsReadSuccesful = data => ({
  type: MARK_NOTIFICATION_AS_READ_SUCCESFUL,
  payload: data,
});

export const markNotificationAsUnreadError = data => ({
  type: MARK_NOTIFICATION_AS_UNREAD_ERROR,
  payload: data,
});

export const markNotificationAsUnreadSuccesful = data => ({
  type: MARK_NOTIFICATION_AS_UNREAD_SUCCESFUL,
  payload: data,
});

export const markAllNotificationAsReadSuccesful = () => ({
  type: MARK_ALL_NOTIFICATION_AS_READ_SUCCESFUL,
});

export const getNotificationsUnreadNumberSuccesful = data => ({
  type: GET_NOTIFICATIONS_UNREAD_NUMBER_SUCCESFUL,
  payload: data,
});

export const getNotificationsSuccesful = (data, notification) => ({
  type: GET_NOTIFICATIONS_SUCCESFUL,
  payload: data,
  notification,
});

export const deleteAllNotifications = () => dispatch => {
  conn
    .deleteAllNotifications()
    .then(() => {
      dispatch(deleteAllNotificationsSuccesful());
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const deleteNotification = notificationId => dispatch => {
  conn
    .deleteNotification(notificationId)
    .then(() => {
      dispatch(deleteNotificationSuccesful(notificationId));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const markAllNotificationAsRead = () => dispatch => {
  conn
    .markAllNotificationAsRead()
    .then(() => {
      dispatch(markAllNotificationAsReadSuccesful());
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const markNotificationAsRead = notificationId => dispatch => {
  dispatch(markNotificationAsReadSuccesful(notificationId));
  conn.markNotificationAsRead(notificationId).catch(err => {
    dispatch(markNotificationAsReadError(notificationId));
    dispatch(connectionError(err));
  });
};

export const markNotificationAsUnread = notificationId => dispatch => {
  dispatch(markNotificationAsUnreadSuccesful(notificationId));
  conn.markNotificationAsUnread(notificationId).catch(err => {
    dispatch(markNotificationAsUnreadError(notificationId));
    dispatch(connectionError(err));
  });
};

export const notificationToggleReadStatus = notification => dispatch => {
  if (notification.read) {
    dispatch(markNotificationAsUnread(notification.id));
  } else {
    dispatch(markNotificationAsRead(notification.id));
  }
};

export const pullNotificationsUnreadNumber = () => dispatch => {
  conn
    .getUnreadNotificationNumber()
    .then(response => {
      dispatch(getNotificationsUnreadNumberSuccesful(response.data.unread_notifications));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

const getPushNotificationData = (notifications, intl) => {
  const notificationMessages = defineMessages({
    titleMultiple: {
      id: 'pushNotification.pulledNotification.titleMultiple',
      defaultMessage: 'Masz {notificationsLength, plural, few {# nowe notyfikacje} other {# nowych notyfikacji} }.',
    },
    titleOne: {
      id: 'pushNotification.pulledNotification.titleOne',
      defaultMessage: 'Masz 1 nową notyfikacje',
    },
  });
  const title =
    notifications.length === 1
      ? intl.formatMessage(notificationMessages.titleOne, {})
      : intl.formatMessage(notificationMessages.titleMultiple, { notificationsLength: notifications.length });
  const body = notifications.length === 1 ? notifications[0].message : '';
  return {
    type: 'push',
    title,
    body,
    onClick: () => {
      window.focus();
      browserHistory.push('/notifications');
    },
  };
};

export const pullNotifications = (count, useNextTimestamp = true, notify = false) => (dispatch, getState, intl) => {
  const { nextTimestamp: notificationsNextTimestamp } = getState().reducer.userNotifications;
  conn
    .getActivityLog(count, useNextTimestamp ? notificationsNextTimestamp : null)
    .then(response => {
      const { logs, displayData, next_timestamp: nextTimestamp, has_more: hasMore } = response.data;
      const pushNotificationData = logs && logs.length && notify ? getPushNotificationData(logs, getState().intl) : {};
      const mappedMessages = mapActivityLogMessages(logs, displayData, intl);
      dispatch(
        getNotificationsSuccesful(
          {
            messages: mappedMessages,
            nextTimestamp,
            hasMore,
          },
          pushNotificationData,
        ),
      );
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const pullNotificationsSince = (notificationId, count = 10, notify = false) => (dispatch, getState) => {
  conn
    .getActivityLog(count)
    .then(response => {
      const { notifications } = response.data;
      const pushNotificationData =
        notifications && notifications.length && notify ? getPushNotificationData(notifications, getState().intl) : {};

      dispatch(getNotificationsSuccesful(notifications, pushNotificationData));
    })
    .catch(err => {
      dispatch(connectionError(err));
    });
};

export const notificationGoToContext = notification => (dispatch, getState) => {
  const { userLocations } = getState().reducer;
  const location = userLocations.find(loc => loc.id === String(notification.locationId));
  const locationIds = location ? [location.id] : null;

  if (notification.date) {
    dispatch(mainDateMoveToDate(notification.date));
  }

  switch (notification.type) {
    case 'absence':
      break;
    case 'generic':
    case 'shift':
      browserHistory.push('/schedule');
      if (locationIds) {
        dispatch(changeScheduleLocationFilter(locationIds));
      }
      break;
    case 'schedule':
      browserHistory.push('/schedule');
      if (locationIds) {
        dispatch(changeScheduleLocationFilter(locationIds));
      }
      break;
    case 'attendance':
      browserHistory.push('/attendance');
      if (locationIds) {
        dispatch(changeScheduleLocationFilter(locationIds));
      }
      break;
    default:
      break;
  }
};
