import { connect } from 'react-redux';

import { changeAttendancesMode } from '@/actions/attendances.js';
import { mainDateChangeModeAndDate, mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';

import TopBarCalendar from '../TopBarCalendar/TopBarCalendar.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.mainDateStore,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
  mainDateChangeModeAndDate,
  additionalChangeMode: changeAttendancesMode,
};

const TopBarCalendarContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarCalendar);

export default TopBarCalendarContainer;
