import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import {
  emplMassEditTypeActions,
  emplMassEditTypes,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL } from '@/constants/modalTypes.js';
import { useStepsCounter } from '@/hooks/index.js';
import useClearModal from '@/hooks/useClearModal/useClearModal.ts';
import { isNullOrEmpty } from '@/utils/baseHelpers.js';

import EditMassEmployeesModalStepSelector from './EditMassEmployeesModalStepSelector/EditMassEmployeesModalStepSelector';
import {
  getAvailableAdditionalOptions,
  getConfirmText,
  getInitialStepsCount,
  getSelectedEmployees,
  isDisabledNextButton,
} from './employeesEditMassHelpers.ts';
import { messages } from './employeesEditMassModal.messages.ts';

import './employeesEditMassModal.scss';

const EmployeesEditMassModal = (props, { intl }) => {
  const {
    selectedEmployeesIds,
    showModal,
    employees,
    massEditEmployees,
    onHide,
    showAdditionalModal,
    massEditContracts,
    contracts,
  } = props;

  const [selectedType, setSelectedType] = useState(emplMassEditTypes.location);
  const [selectedAction, setSelectedAction] = useState('');
  const [currentFieldValue, setFieldValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { currentStep, stepsCount, isLastStep, handlers: stepsHandlers } = useStepsCounter(1);

  const isSaveDisabled =
    isNullOrEmpty(currentFieldValue) ||
    isDisabledNextButton(selectedAction, currentFieldValue, currentStep, contracts, selectedEmployeesIds);

  const clearModal = () => {
    setSelectedType(emplMassEditTypes.location);
    setSelectedAction('');
    setFieldValue(null);
    setErrorMessage(null);
    stepsHandlers.setInitialValues();
  };

  const setHideEndStatus = useClearModal(clearModal);

  const handleTypeChange = (value) => {
    setFieldValue(null);
    setSelectedType(value);
  };

  const editEmployees = async (selectedEmployees) => {
    const action = emplMassEditTypeActions[selectedType][selectedAction];

    if (selectedType === emplMassEditTypes.contracts) {
      massEditContracts(selectedAction, currentFieldValue);
      onHide();
      return;
    }
    onHide();

    await massEditEmployees(selectedEmployees, currentFieldValue, action, []);
  };

  const handleSubmit = () => {
    if (selectedType === emplMassEditTypes.contracts && !isLastStep) {
      stepsHandlers.increaseStep();
      return;
    }

    if (isSaveDisabled) {
      return;
    }
    const selectedEmployees = getSelectedEmployees(selectedEmployeesIds, employees, selectedAction);
    const availableOptions = getAvailableAdditionalOptions(
      selectedType,
      selectedAction,
      selectedEmployees,
      currentFieldValue,
    );
    if (availableOptions.length > 0) {
      showAdditionalModal(EDIT_MASS_EMPLOYEES_ADDITIONAL_OPTIONS_MODAL, {
        availableOptions,
        selectedType,
        selectedAction,
        currentFieldValue,
        selectedEmployees,
      });
    } else {
      editEmployees(selectedEmployees);
    }
  };

  const onCancel = () => {
    if (selectedType === emplMassEditTypes.contracts && currentStep > 1) {
      stepsHandlers.decreaseStep();
      return;
    }
    onHide();
  };

  useEffect(() => {
    const initialStepsCount = getInitialStepsCount(selectedType, selectedAction);
    stepsHandlers.setStepsCount(initialStepsCount);
  }, [selectedType, selectedAction]);

  return (
    <MDKadroModal
      show={showModal}
      onHide={onHide}
      onHideEnd={setHideEndStatus}
      modifiers={['narrow']}
      title={<FormattedMessage id="employees.editMass.title" defaultMessage="Edycja pracowników" />}
      onSubmit={handleSubmit}
      confirmBtnModifiers={isSaveDisabled ? ['orange', 'disabled'] : ['orange']}
      errorMessage={errorMessage}
      withoutOverlayScroll
      currentStep={currentStep}
      stepsCount={stepsCount}
      showProgressBar
      onCancelFooter={onCancel}
      confirmText={intl.formatMessage(getConfirmText(selectedType, selectedAction, isLastStep))}
      cancelText={intl.formatMessage(messages[currentStep === 1 ? 'cancel' : 'back'])}
      className="employeesEditMassModal"
    >
      <EditMassEmployeesModalStepSelector
        {...{
          currentStep,
          selectedType,
          handleTypeChange,
          selectedAction,
          setSelectedAction,
          currentFieldValue,
          setFieldValue,
        }}
      />
    </MDKadroModal>
  );
};

EmployeesEditMassModal.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeesEditMassModal.propTypes = {
  selectedEmployeesIds: PropTypes.arrayOf(PropTypes.string),
  showModal: PropTypes.bool,
  employees: PropTypes.shape({}),
  massEditEmployees: PropTypes.func,
  onHide: PropTypes.func,
  showAdditionalModal: PropTypes.func,
  massEditContracts: PropTypes.func,
  contracts: PropTypes.shape({}),
};

export default EmployeesEditMassModal;
