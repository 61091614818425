import { connect } from 'react-redux';

import { changeOpenShift } from '@/actions/openShifts';
import { startOpenShiftDrag, stopOpenShiftDrag } from '@/actions/schedule/dragAndDrop';
import { showDeleteOpenShiftConfirmModal } from '@/actions/schedule/openShifts';
import { openApplyForOpenShiftModal, openAssignUsersOpenShiftModal, openOpenShiftModal } from '@/actions/uiState';

import Component from './OpenShiftShift.jsx';

const mapStateToProps = (state, ownProps) => {
  const shift = state.reducer.schedule.openShifts.data[ownProps.shiftId];
  const jobTitleColor = shift?.job_title?.color;
  return {
    shift,
    jobTitleColor,
    isEmployee: state.reducer.userPermissions.isEmployee,
    contracts: state.reducer.contracts,
    permissions: state.reducer.userPermissions.permissions,
    userLocations: state.reducer.userLocations,
    currentUserContracts: state.reducer.currentUser.user.contracts,
  };
};

const mapDispatchToProps = {
  showDeleteOpenShiftConfirmModal,
  openApplyForOpenShiftModal,
  openOpenShiftModal,
  openAssignUsersOpenShiftModal,
  startOpenShiftDrag,
  stopOpenShiftDrag,
  changeOpenShift,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
