import classNames from 'classnames';
import PropTypes from 'prop-types';

import Toggle from '../Toggle.jsx';

import './toggleBlock.scss';

const ToggleBlock = props => {
  const className = classNames('k-toggleBlock settingsRow', { 'k-toggleBlock--subItem': props.isSubItem });
  const labelClassName = classNames({ labelTitle: props.label });
  return (
    <div className={className}>
      <label>
        <div className={labelClassName}>{props.name}</div>
        {props.label ? <div className="labelDesc">{props.label}</div> : null}
      </label>
      <Toggle onChangeFunc={props.handleInput} value={props.value} labelId={props.slug} />
    </div>
  );
};

ToggleBlock.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleInput: PropTypes.func,
  value: PropTypes.bool,
  isSubItem: PropTypes.bool,
  slug: PropTypes.string,
};

export default ToggleBlock;
