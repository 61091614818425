/* eslint import/prefer-default-export:0 */
import { ADD_HISTORY_OBJECT } from '@/constants/ActionTypes.js';

/**
 * Adds object to company history
 * @param {object} objectOfInterest - object with regards
 */
export const addHistoryObject = objectOfInterest => ({
  type: ADD_HISTORY_OBJECT,
  payload: objectOfInterest,
});
