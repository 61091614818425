import Mousetrap from 'mousetrap';
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import PayrollSettingsModal from '@/containers/payroll/PayrollSettingsModalContainer.js';
import { generateAttendancesDataForReportView } from '@/utils/attendanceHelpers';
import { arrayIntersection, roundToTwoSigDigits } from '@/utils/baseHelpers.js';
import {
  getJobTitleIdsFromContracts,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts';
import { parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import {
  filterAttendances,
  filterShifts,
  getRelevantAttendancesDetails,
  getRelevantAvailabilities,
  getRelevantAvailabilitiesDetails,
  getRelevantShiftsDetails,
  getRowsSummary,
  getShiftRowsSummary,
} from '@/utils/payrollHelpers.jsx';
import { checkRecommendedScheduleDisplayPermissions } from '@/utils/recommendedScheduleHelpers';
import {
  calculateTotalDurationInMinutesForShifts,
  calculateTotalWageFromShifts,
} from '@/utils/schedulerHelpers.js';

import ActionsButton from './common/Basic/ActionsButton/ActionsButton.jsx';
import ButtonBar from './common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from './common/Basic/Icon/Icon.jsx';
import SmallToggle from './common/inputs/SmallToggle.jsx';
import CompareWidget from './common/UI/CompareWidget.jsx';
import FadedIcon from './common/UI/FadedIcon.jsx';
import Panel from './common/UI/Panel.jsx';
import SmallNumberWidget from './common/UI/SmallNumberWidget.jsx';
import WidgetPanel from './common/UI/WidgetPanel.jsx';
import AttendanceChart from './reports/AttendanceChart.jsx';
import CostsChart from './reports/CostsChart.jsx';
import ExportDayReportModal from './reports/ExportDayReportModal/ExportDayReportModal.redux.js';
import JobTitleChart from './reports/JobTitleChart.jsx';
import HelpModal from './reports/ReportsHelpModal.jsx';
import TopBarReports from './TopBars/TopBarReports.jsx';

const messages = defineMessages({
  currencyCode: {
    id: 'currencyCode',
    defaultMessage: 'PLN',
  },
});

class ReportView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      costsChartToggle: false,
      jobTitlesChartToggle: false,
    };

    this.getDataForRecommendedSchedule =
      this.getDataForRecommendedSchedule.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(['left'], this.props.mainDateMoveLeft);
    Mousetrap.bind(['right'], this.props.mainDateMoveRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(['left']);
    Mousetrap.unbind(['right']);
  }

  getDataForRecommendedSchedule(recommendedSchedule) {
    return recommendedSchedule.reduce(
      (prev, schedule) => {
        const { hours, costs } = schedule.template.reduce(
          (acc, template) => {
            const jobTitle = this.props.userJobTitles.find(
              (jt) => jt.id === template.job_title_id
            );
            const wage = jobTitle ? jobTitle.hourly_wage / 100 : 0;
            const templateHours = Object.values(template.values).reduce(
              (sum, val) => sum + parseInt(val),
              0
            );
            return {
              hours: acc.hours + templateHours,
              costs: acc.costs + templateHours * wage,
            };
          },
          { hours: 0, costs: 0 }
        );

        return {
          recommendedHours: prev.recommendedHours + hours,
          recommendedCosts: prev.recommendedCosts + costs,
        };
      },
      { recommendedHours: 0, recommendedCosts: 0 }
    );
  }

  render() {
    const {
      payrollSettings,
      userEmployees,
      multipleLocationFilter,
      mainDateStore,
      selectedJobtitles,
      userCustomTypes,
      employmentConditionsFilter,
      recommendedSchedule,
      userPermissions,
      payrollLocationSummary,
      attendancesData,
    } = this.props;
    const mainDateArray = mainDateStore.dateArray;
    const isRecommendedScheduleEnabled =
      checkRecommendedScheduleDisplayPermissions(userPermissions.restrictions);

    // Main variables ----------------------------------------------------------
    let relevantEmployees = []; // Array of relevant employees
    const realCosts = roundToTwoSigDigits(
      (payrollLocationSummary?.payout || 0) / 100
    );
    const realHours = parseMinutesToHumanForm(
      payrollLocationSummary?.workTime || 0
    );
    let predictedCosts = 0; // Costs from budget table
    let predictedHours = 0;
    let jobTitleWorkedHours = 0;
    let jobTitleWorkedCosts = 0;
    let totalAttendances = 0;
    let totalLate = 0;
    let totalAbsent = 0;
    let frequency = 0;
    const attendanceData = [];
    const sumHours = [];
    const sumWorkedHours = [];
    const sumRecommendedHours = [];
    const sumCosts = [];
    const sumWorkedCosts = [];
    const sumRecommendedCosts = [];
    const sumEmployees = [];
    // -------------------------------------------------------------------------

    // Filter for proper employees
    const selectedLocationsIds = multipleLocationFilter;
    const employeesToRender = userEmployees.filter(employee => {
      const employeeLocationsIds = employee.locations.map(l => l.id);
      const employeeAttendancesLocationsIds =
        attendancesData[employee.id]
          ?.filter(a => selectedLocationsIds.includes(a.location.id))
          .map(({ location }) => location.id) || [];
      const employeeContracts = this.props.contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(
        employeeContracts,
        mainDateStore.customDate.start,
        mainDateStore.customDate.end
      );
      const employeeJobTitleIds =
        getJobTitleIdsFromContracts(relevantContracts);

      return Boolean(
        (arrayIntersection(employeeLocationsIds, selectedLocationsIds) ||
          arrayIntersection(
            employeeAttendancesLocationsIds,
            selectedLocationsIds
          )) &&
          employeeJobTitleIds.some((jobTitleId) =>
            selectedJobtitles.map((j) => j.id).includes(jobTitleId)
          ) &&
          !employee.inactive &&
          employmentConditionsFilter.selected.some(
            (condition) =>
              condition.id === employee.employment_conditions.template_id
          )
      );
    });
    let relevantEmployeeRows = [];
    switch (payrollSettings.payoutSetting.type) {
      case 'shifts':
        relevantEmployeeRows = employeesToRender.map((employee) => {
          const relevantAvailabilities = getRelevantAvailabilities(
            employee.availability_blocks,
            mainDateStore,
            userCustomTypes
          ).filter((ava) => !ava.draft);
          const relevantAvailabilitiesDetails =
            getRelevantAvailabilitiesDetails(
              relevantAvailabilities,
              userCustomTypes
            );
          const relevantShifts = filterShifts(
            employee.shifts,
            mainDateStore,
            multipleLocationFilter
          );
          const relevantShiftDetails = getRelevantShiftsDetails(
            relevantShifts,
            employee,
            payrollSettings
          );

          const relevantRowInfo = getShiftRowsSummary(
            relevantShiftDetails,
            relevantAvailabilitiesDetails,
            employee.employment_conditions
          );
          // Add up for total sum
          return { employee, relevantRowInfo };
        });
        break;

      default:
        relevantEmployeeRows = employeesToRender.map((employee) => {
          const relevantAvailabilities = getRelevantAvailabilities(
            employee.availability_blocks,
            mainDateStore,
            userCustomTypes,
          ).filter(ava => !ava.draft);
          const relevantAvailabilitiesDetails = getRelevantAvailabilitiesDetails(
            relevantAvailabilities,
            userCustomTypes,
          );
          const employeeAttendances = attendancesData[employee.id] || [];
          const relevantAtts = filterAttendances(
            employeeAttendances,
            mainDateStore,
            multipleLocationFilter
          ).sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
          const relevantAttendancesDetails = getRelevantAttendancesDetails(
            relevantAtts,
            employee,
            payrollSettings
          );

          const relevantShifts = filterShifts(
            employee.shifts,
            mainDateStore,
            multipleLocationFilter
          );
          const relevantShiftsDetails = getRelevantShiftsDetails(
            relevantShifts,
            employee,
            payrollSettings
          );
          const relevantRowInfo = getRowsSummary(
            relevantAttendancesDetails,
            relevantAvailabilitiesDetails,
            relevantShiftsDetails,
            employee.employment_conditions
          );
          return { employee, relevantRowInfo };
        });
        break;
    }

    relevantEmployees = relevantEmployeeRows.filter(
      (row) => row.relevantRowInfo.sumHours > 0
    );

    // Calculating predicted costs
    const jobTitleHours = {};
    const jobTitleCosts = {};
    selectedJobtitles
      .sort((a, b) => (a.id > b.id ? 1 : -1))
      .forEach((j) => {
        jobTitleHours[j.id] = mainDateArray.map(() => 0);
        jobTitleCosts[j.id] = mainDateArray.map(() => 0);
      });

    for (let x = 0; x < mainDateArray.length; x++) {
      const date = mainDateArray[x];
      const dailyHours = employeesToRender
        .map((employee) =>
          calculateTotalDurationInMinutesForShifts(
            filterShifts(
              employee.shifts,
              { dateArray: [date] },
              multipleLocationFilter
            )
          )
        )
        .reduce((a, b) => a + b, 0);
      const workedHours = relevantEmployees.reduce(
        (sum, row) =>
          sum +
          row.relevantRowInfo.details.reduce((total, detail) => {
            if (detail.date === date) {
              if (
                detail.matching_shift_id &&
                jobTitleHours[detail.matching_shift.job_title.id]
              ) {
                jobTitleHours[detail.matching_shift.job_title.id][x] +=
                  detail.timeWorked;
              }
              if (detail.job_title && jobTitleHours[detail.job_title.id]) {
                jobTitleHours[detail.job_title.id][x] += detail.timeWorked;
              }
              return total + detail.timeWorked;
            }
            return total;
          }, 0),
        0
      );
      const workedCosts = relevantEmployees.reduce(
        (sum, row) =>
          sum +
          row.relevantRowInfo.details.reduce((total, detail) => {
            if (detail.date === date) {
              if (
                detail.matching_shift_id &&
                jobTitleCosts[detail.matching_shift.job_title.id]
              ) {
                jobTitleCosts[detail.matching_shift.job_title.id][x] +=
                  detail.sumPaid;
              }
              if (detail.job_title && jobTitleCosts[detail.job_title.id]) {
                jobTitleCosts[detail.job_title.id][x] += detail.sumPaid;
              }
              return total + detail.sumPaid;
            }
            return total;
          }, 0),
        0
      );
      const dailyCosts = employeesToRender
        .map((employee) =>
          calculateTotalWageFromShifts(
            filterShifts(
              employee.shifts,
              { dateArray: [date] },
              multipleLocationFilter
            ),
            this.props.contracts[employee.id] || [],
            this.props.userJobTitles
          )
        )
        .reduce((a, b) => a + b, 0);
      let dailyEmployees = 0;

      dailyEmployees = employeesToRender.filter(
        (employee) =>
          filterShifts(
            employee.shifts,
            { dateArray: [date] },
            multipleLocationFilter
          ).length
      ).length;

      const { recommendedHours, recommendedCosts } =
        this.getDataForRecommendedSchedule(
          recommendedSchedule.filter(
            (schedule) => schedule.date === mainDateArray[x]
          )
        );

      sumHours.push(dailyHours);
      sumWorkedHours.push(workedHours);
      sumRecommendedHours.push(recommendedHours);
      sumCosts.push(dailyCosts);
      sumWorkedCosts.push(workedCosts);
      sumRecommendedCosts.push(recommendedCosts);
      sumEmployees.push(dailyEmployees);
      attendanceData.push(generateAttendancesDataForReportView(employeesToRender, date, attendancesData));
    }
    predictedCosts = roundToTwoSigDigits(sumCosts.reduce((a, b) => a + b, 0));
    predictedHours = parseMinutesToHumanForm(
      sumHours.reduce((a, b) => a + b, 0)
    );
    // in tests jest can't use Object.values so i added this if...
    jobTitleWorkedHours = Object.values(jobTitleHours).map((j) =>
      j.reduce((a, b) => a + b, 0)
    );
    jobTitleWorkedCosts = Object.values(jobTitleCosts).map((j) =>
      j.reduce((a, b) => a + b, 0)
    );

    // Attendance data
    const attendanceParsedData = attendanceData.map((dailyData) => {
      let attendances = 0;
      let absences = 0;
      let lateAtWrok = 0;
      dailyData.forEach((employee) => {
        employee.blocks.forEach((block) => {
          switch (block.type.name) {
            case 'absence':
              absences++;
              break;
            case 'attedance_late_start':
              lateAtWrok++;
              break;
            case 'present':
              attendances++;
              break;
            default:
              break;
          }
        });
      });
      return {
        attendances,
        absences,
        lateAtWrok,
      };
    });

    totalAttendances = attendanceParsedData.reduce(
      (sum, day) => sum + day.attendances,
      0
    );
    totalLate = attendanceParsedData.reduce(
      (sum, day) => sum + day.lateAtWrok,
      0
    );
    totalAbsent = attendanceParsedData.reduce(
      (sum, day) => sum + day.absences,
      0
    );
    frequency =
      Math.round((totalAttendances / (totalAttendances + totalAbsent)) * 1000) /
      10;

    let spanText;
    switch (mainDateStore.dateMode) {
      case 'day':
        spanText = (
          <FormattedMessage id="reports.thisDay" defaultMessage="w tym dniu" />
        );
        break;
      case 'week':
        spanText = (
          <FormattedMessage
            id="reports.thisWeek"
            defaultMessage="w tym tygodniu"
          />
        );
        break;
      case 'month':
        spanText = (
          <FormattedMessage
            id="reports.thisMonth"
            defaultMessage="w tym miesiącu"
          />
        );
        break;
      default:
        spanText = (
          <FormattedMessage
            id="reports.thisSpan"
            defaultMessage="w zadanym okresie"
          />
        );
        break;
    }

    const exportRawData = () => ({
      type: 'reports',
      employeesToRender,
      multipleLocationFilter,
      dateArray: mainDateStore.dateArray,
    });

    return (
      <div>
        <TopBarReports />
        <HelpModal />
        <PayrollSettingsModal />
        <ExportDayReportModal exportRawData={exportRawData} />
        <div style={{ padding: '20px' }}>
          <ButtonBar>
            <ActionsButton icon={<Icon name="more_vert" />}>
              <button
                className="k-actionsButton__element"
                onClick={this.props.toggleExportModal}
              >
                <FormattedMessage
                  id="export.reports"
                  defaultMessage="Eksportuj raport"
                />
              </button>
            </ActionsButton>
          </ButtonBar>
          <WidgetPanel
            items={[
              <div>
                <div className="widget__titleContainer">
                  <div className="widget__title">
                    <FormattedMessage
                      id="reports.inWorkDuringSpan"
                      defaultMessage="Obecnych w pracy"
                    />
                  </div>
                  <div className="widget__subtitle">{spanText}</div>
                </div>
                <div className="widget__value">{relevantEmployees.length}</div>
                <FadedIcon icon="group" color="#06bbfe" />
              </div>,
              <div>
                <div className="widget__titleContainer">
                  <div className="widget__title">
                    <FormattedMessage
                      id="reports.costs"
                      defaultMessage="Koszty"
                    />
                  </div>
                  <div className="widget__subtitle">
                    <FormattedMessage
                      id="reports.real-planned"
                      defaultMessage="Realne | zaplanowane"
                    />
                  </div>
                </div>
                <CompareWidget
                  value1={realCosts}
                  value2={predictedCosts}
                  currency
                  unit={this.context.intl.formatMessage(
                    messages.currencyCode,
                    {}
                  )}
                />
              </div>,
              <div>
                <div className="widget__titleContainer">
                  <div className="widget__title">
                    <FormattedMessage
                      id="reports.workingHours"
                      defaultMessage="Godziny pracy "
                    />
                  </div>
                </div>
                <div className="widget__compareHours">
                  <div className="widget__compareHoursElement">
                    <FormattedMessage
                      id="reports.compare.realHours"
                      defaultMessage="Realne: "
                    />
                    <span>{realHours}</span>
                  </div>
                  <div className="widget__compareHoursElement">
                    <FormattedMessage
                      id="reports.compare.plannedHours"
                      defaultMessage="Zaplanowane: "
                    />
                    <span>{predictedHours}</span>
                  </div>
                  {isRecommendedScheduleEnabled ? (
                    <div className="widget__compareHoursElement">
                      <FormattedMessage
                        id="reports.compare.recommendedHours"
                        defaultMessage="Rekomendowane: "
                      />
                      <span>
                        {sumRecommendedHours.reduce(
                          (acc, hours) => acc + hours,
                          0
                        )}{' '}
                        h
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>,
            ]}
          />
          <div className="panelGrid">
            <Panel
              modifiers={['mobileHide']}
              title={
                <div>
                  <FormattedMessage
                    id="reports.real-planned"
                    defaultMessage="Realne | zaplanowane"
                  />

                  <SmallToggle
                    left={
                      <FormattedMessage
                        id="reports.time"
                        defaultMessage="Czas"
                      />
                    }
                    right={
                      <FormattedMessage
                        id="reports.costs"
                        defaultMessage="Koszty"
                      />
                    }
                    checked={this.state.costsChartToggle}
                    onChange={() => {
                      this.setState({
                        costsChartToggle: !this.state.costsChartToggle,
                      });
                    }}
                  />
                </div>
              }
            >
              <CostsChart
                mainDateStore={mainDateStore}
                predictedHoursArr={sumHours}
                actualHoursArr={sumWorkedHours}
                recommendedHoursArr={sumRecommendedHours}
                predictedCostsArr={sumCosts}
                actualCostsArr={sumWorkedCosts}
                recommendedCostsArr={sumRecommendedCosts}
                showCosts={this.state.costsChartToggle}
                isRecommendedScheduleEnabled={isRecommendedScheduleEnabled}
              />
            </Panel>
            <Panel
              modifiers={['mobileHide']}
              title={
                <div>
                  <FormattedMessage
                    id="reports.jobTitleCompare"
                    defaultMessage="Porównanie stanowisk"
                  />
                  <SmallToggle
                    left={
                      <FormattedMessage
                        id="reports.time"
                        defaultMessage="Czas"
                      />
                    }
                    right={
                      <FormattedMessage
                        id="reports.costs"
                        defaultMessage="Koszty"
                      />
                    }
                    checked={this.state.jobTitlesChartToggle}
                    onChange={() => {
                      this.setState({
                        jobTitlesChartToggle: !this.state.jobTitlesChartToggle,
                      });
                    }}
                  />
                </div>
              }
            >
              <JobTitleChart
                jobTitles={selectedJobtitles}
                jobTitlesHours={jobTitleWorkedHours}
                jobTitlesCosts={jobTitleWorkedCosts}
                showCosts={this.state.jobTitlesChartToggle}
              />
            </Panel>
          </div>
          <WidgetPanel
            items={[
              <SmallNumberWidget
                title={
                  <FormattedMessage
                    id="reports.attendances"
                    defaultMessage="Obecności"
                  />
                }
                subtitle={spanText}
                color="green"
                number={totalAttendances}
              />,
              <SmallNumberWidget
                title={
                  <FormattedMessage
                    id="reports.lateAtWrok"
                    defaultMessage="Spóźnienia"
                  />
                }
                subtitle={spanText}
                color="yellow"
                number={totalLate}
              />,
              <SmallNumberWidget
                title={
                  <FormattedMessage
                    id="reports.absences"
                    defaultMessage="Nieobecności"
                  />
                }
                subtitle={spanText}
                color="red"
                number={totalAbsent}
              />,
              <SmallNumberWidget
                title={
                  <FormattedMessage
                    id="reports.frequency"
                    defaultMessage="Frekwencja"
                  />
                }
                subtitle={spanText}
                color="blue"
                number={`${frequency} %`}
              />,
            ]}
          />
          <Panel modifiers={['mobileHide']}>
            <AttendanceChart
              mainDateStore={mainDateStore}
              attendanceData={attendanceParsedData}
            />
          </Panel>
        </div>
      </div>
    );
  }
}

ReportView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ReportView.propTypes = {
  mainDateStore: PropTypes.shape({
    dateMode: PropTypes.string,
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  userCustomTypes: PropTypes.arrayOf(PropTypes.shape({})),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  payrollSettings: PropTypes.shape({}),
  selectedJobtitles: PropTypes.arrayOf(PropTypes.shape({})),
  employmentConditionsFilter: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  recommendedSchedule: PropTypes.arrayOf(PropTypes.shape({})),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  mainDateMoveLeft: PropTypes.func,
  mainDateMoveRight: PropTypes.func,
  toggleExportModal: PropTypes.func,
  payrollLocationSummary: PropTypes.shape({
    payout: PropTypes.number,
    work_time: PropTypes.number,
  }),
};

export default ReportView;
