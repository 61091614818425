import classnames from 'classnames';
import PropTypes from 'prop-types';

import Popover from '../Popover/Popover.jsx';

const PhotoPreview = props => {
  const { availablePhotos, locationId, attendanceId, getAttendanceImageUrls } = props;
  if (!availablePhotos) return null;
  const { clockIn, clockOut, clockInUrl, clockOutUrl } = availablePhotos;
  const photoEmpty = (
    <div className="k-photoPreview__empty">
      <i className="k-photoPreview__emptyIcon material-icons photo_camera">photo_camera</i>
    </div>
  );
  const inPhotoClassnames = classnames('k-photoPreview__photo', {
    'k-photoPreview__photo--loading': clockIn && !clockInUrl,
  });
  const outPhotoClassnames = classnames('k-photoPreview__photo', {
    'k-photoPreview__photo--loading': clockOut && !clockOutUrl,
  });
  return (
    <Popover
      position="bottom"
      updatePosition
      onShow={() => getAttendanceImageUrls(locationId, attendanceId)}
      content={
        <div className="k-photoPreview__popover">
          {clockIn ? (
            <div className={inPhotoClassnames}>
              <a href={clockInUrl} download>
                <img src={clockInUrl} alt="clockIn" />
              </a>
            </div>
          ) : (
            photoEmpty
          )}
          {clockOut ? (
            <div className={outPhotoClassnames}>
              <a href={clockOutUrl} download>
                <img src={clockOutUrl} alt="clockOut" />
              </a>
            </div>
          ) : (
            photoEmpty
          )}
        </div>
      }
    >
      <div>{props.children}</div>
    </Popover>
  );
};

PhotoPreview.propTypes = {
  attendanceId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  locationId: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  availablePhotos: PropTypes.shape({
    clockIn: PropTypes.bool,
    clockOut: PropTypes.bool,
    clockInUrl: PropTypes.string,
    clockOutUrl: PropTypes.string,
  }),
  getAttendanceImageUrls: PropTypes.func,
  children: PropTypes.node,
};

export default PhotoPreview;
