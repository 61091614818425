import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@/redux-store';
import { selectSelectedEmployeeGroups } from '@/redux-store/employeeGrouping';
import { selectEmploymentConditionsDict } from '@/redux-store/employmentConditions';
import { selectSelectedJobTitlesGroupedIds } from '@/redux-store/jobTitleFilter';
import { selectScheduleStateForLocation } from '@/redux-store/scheduleState/locations';
import { selectUserJobTitlesDict } from '@/redux-store/userJobTitles';
import { selectEmployeesData } from '@/selectors/employees.selectors';
import { LocationId } from '@/types';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

import { groupEmployees } from './GroupedEmployees.utils';

export const useGroupedEmployees = (locationId: LocationId) => {
  const locationState = useAppSelector(selectScheduleStateForLocation(locationId));
  const userJobTitlesDict = useAppSelector(selectUserJobTitlesDict);
  const employmentConditionsDict = useAppSelector(selectEmploymentConditionsDict);
  const selectedJobTitlesIds = useAppSelector(selectSelectedJobTitlesGroupedIds);

  const visibleEmployeesIds = useMemo(() => {
    if (!locationState) return [];
    const { order, visible } = locationState;
    const visibleDict = transformToBoolDict(visible);
    return order.filter(id => visibleDict[id]);
  }, [locationState]);

  const selectedGroups = useAppSelector(selectSelectedEmployeeGroups);
  const employees = useAppSelector(selectEmployeesData);
  const [groupNodes, setGroupNodes] = useState(() =>
    groupEmployees(
      visibleEmployeesIds,
      selectedGroups,
      employees,
      userJobTitlesDict,
      employmentConditionsDict,
      selectedJobTitlesIds,
    ),
  );

  useEffect(() => {
    const nodes = groupEmployees(
      visibleEmployeesIds,
      selectedGroups,
      employees,
      userJobTitlesDict,
      employmentConditionsDict,
      selectedJobTitlesIds,
    );
    setGroupNodes(nodes);
  }, [
    employmentConditionsDict,
    userJobTitlesDict,
    selectedGroups,
    employees,
    visibleEmployeesIds,
    selectedJobTitlesIds,
  ]);

  return { groupNodes };
};

export type UseGroupedEmployeesTypeValues = ReturnType<typeof useGroupedEmployees>;
