import PropTypes from 'prop-types';

import AbsenceAddModal from '@/components/absences/modals/AbsenceAddModal/AbsenceAddModal.redux';
import AbsenceHistoryModal from '@/components/absences/modals/AbsenceHistoryModal/AbsenceHistoryModal.redux';
import AbsenceProposalModal from '@/components/absences/modals/AbsenceProposalModal/AbsenceProposalModal.redux';
import AcceptOvertimeModal from '@/components/attendance/modals/AcceptOvertimeModal/AcceptOvertimeModal.redux';
import CreateAttendanceModal from '@/components/attendance/modals/CreateAttendanceModal/CreateAttendanceModal.redux';
import NewPayrollExportModal from '@/components/common/exports/NewPayrollExportModal/NewPayrollExportModal.redux';
import ChoosePayrollSummaryValuesModal from '@/components/newPayrollViews/ChoosePayrollSummaryValuesModal/ChoosePayrollSummaryValuesModal.redux';
import PayrollSettingsModal from '@/components/payroll/PayrollSettingsModal/PayrollSettingsModal.redux';
import AddAttendanceModalContainer from '@/containers/attendance/AddAttendanceModalContainer';
import { useChangeDateByArrow } from '@/hooks';

import AddShiftModal from '../../../scheduler/modals/AddShiftModal/AddShiftModal.redux';
import PayrollEditShiftAndAttendanceModal from '../PayrollTable/PayrollEditShiftAndAttendanceModal/PayrollEditShiftAndAttendanceModal.redux';
import PayrollTable from '../PayrollTable/PayrollTable.redux';
import PayrollTopBar from '../PayrollTopBar/PayrollTopBar.redux';

import './PayrollView.scss';

const PayrollView = ({ getPayrollViewData, enableLoadButton, selectedEmployeeId, isLoadButtonEnabled }) => {
  useChangeDateByArrow(enableLoadButton);

  return (
    <div className="k-payrollView">
      <PayrollEditShiftAndAttendanceModal />
      <NewPayrollExportModal {...{ selectedEmployeeId }} shouldBaseExportsOnOldFiltersState={isLoadButtonEnabled} />
      <AbsenceProposalModal />
      <AbsenceHistoryModal />
      <AcceptOvertimeModal />
      <AbsenceAddModal />
      <CreateAttendanceModal showDatePicker useMultipleLocationFilter />
      <PayrollSettingsModal onSave={getPayrollViewData} />
      <AddAttendanceModalContainer />
      <AddShiftModal useMultipleLocationFilter />
      <ChoosePayrollSummaryValuesModal />
      <PayrollTopBar />
      <div className="k-payrollView__table">
        <PayrollTable />
      </div>
    </div>
  );
};

PayrollView.propTypes = {
  getPayrollViewData: PropTypes.func,
  enableLoadButton: PropTypes.func,
  isLoadButtonEnabled: PropTypes.bool,
  selectedEmployeeId: PropTypes.number,
};

export default PayrollView;
