import { defineMessages } from 'react-intl';

const messages = defineMessages({
  numberOfEmployeesWorked: {
    id: 'reports.numberOfEmployeesWorkedLabel',
    defaultMessage: 'Przepracowane',
  },
  numberOfEmployeesPlanned: {
    id: 'reports.numberOfEmployeesPlannedLabel',
    defaultMessage: 'Zaplanowane',
  },
});

export const metadata = [
  {
    key: 'numberOfEmployeesPlanned',
    color: '#FF6B01',
    label: messages.numberOfEmployeesPlanned,
    order: 1,
    yAxisId: 'left',
    chartType: 'bar',
  },
  {
    key: 'numberOfEmployeesWorked',
    color: '#2C223F',
    label: messages.numberOfEmployeesWorked,
    order: 2,
    yAxisId: 'left',
    chartType: 'bar',
  },
];
