import * as AT from '@/constants/ActionTypes';

const initialState = {
  openShiftsSticky: true,
  tradeShiftsSticky: true,
  loanEmployeesProposalsSticky: true,
};

const OpenShiftsRowSelect = (state = initialState, action) => {
  switch (action.type) {
    case AT.TOGGLE_OPEN_SHIFTS_ROW_STICKINESS:
      return { ...state, openShiftsSticky: !state.openShiftsSticky };
    case AT.TOGGLE_TRADE_SHIFTS_ROW_STICKINESS:
      return { ...state, tradeShiftsSticky: !state.tradeShiftsSticky };
    case AT.TOGGLE_LOAN_EMPLOYEES_PROPOSAL_ROW_STICKINESS: {
      return { ...state, loanEmployeesProposalsSticky: !state.loanEmployeesProposalsSticky };
    }
    default:
      return state;
  }
};

export default OpenShiftsRowSelect;
