export const getQueryString = (locationQuery, sectionName) =>
  Object.entries(locationQuery).reduce((queryString, [key, value]) => {
    if (key === sectionName) {
      return queryString;
    }

    const newQuery = `${queryString}${key}=${value}`;

    if (!queryString) {
      return newQuery;
    }

    return `&${newQuery}`;
  }, '');
