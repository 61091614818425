export const calculateSpmhBudget = (spmhTarget, estimatedBudget) => {
  if (Number(estimatedBudget) === 0) return null;
  if (spmhTarget === '') return '';
  if (Number(spmhTarget) === 0) return 0;
  return Math.round((estimatedBudget / spmhTarget) * 60);
};
export const calculateColBudget = (colTarget, estimatedBudget) => {
  if (Number(estimatedBudget) === 0) return null;
  if (colTarget === '') return '';
  if (Number(colTarget) === 0) return 0;
  return Math.round((estimatedBudget / 100) * colTarget * 100) / 100;
};
