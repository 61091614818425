import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'loadRecommendedScheduleModal.title',
    defaultMessage: 'Wczytaj prognozowany grafik',
  },
  fromExcel: {
    id: 'loadRecommendedScheduleModal.fromExcel',
    defaultMessage: 'Importuj z szablonu Excel',
  },
  fromExcelDescription: {
    id: 'loadRecommendedScheduleModal.fromExcelDescription',
    defaultMessage: 'Wybierz, aby wczytać prognozowany grafik na podstawie uzupełnionego pliku w Excel.',
  },
  fromExistingTemplate: {
    id: 'loadRecommendedScheduleModal.fromExistingTemplate',
    defaultMessage: 'Utwórz na podstawie istniejącego szablonu',
  },
  fromExistingTemplateDescription: {
    id: 'loadRecommendedScheduleModal.fromExistingTemplateDescription',
    defaultMessage: 'Wybierz, aby wczytać prognozowane grafik na bazie jednego z utworzonych wcześniej szablonów.',
  },
  forth: {
    id: 'loadRecommendedScheduleModal.forth',
    defaultMessage: 'Dalej',
  },
});
