import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  invoiceNotFoundViewTitle: {
    id: 'invoiceStatusView.notFoundInvoice.title',
    defaultMessage: 'Nie odnaleziono tej faktury',
  },

  invoicePaidViewTitle: {
    id: 'invoiceStatusView.paidInvoice.title',
    defaultMessage: 'Twoja faktura została opłacona',
  },

  invoiceNotFoundViewContent: {
    id: 'invoiceStatusView.notFoundInvoice.content',
    defaultMessage: 'Nie możemy odnaleźć Twojej faktury. Aby uzyskać od nas pomoc skontaktuj się z nami na czacie',
  },

  invoicePaidViewContent: {
    id: 'invoiceStatusView.paidInvoice.content',
    defaultMessage:
      'Dziękujemy za Twoją płatność. Spróbuj zalogować się do systemu, a w razie pytań napisz do nas na czacie',
  },

  invoiceStatusViewButton: {
    id: 'invoiceStatusView.button',
    defaultMessage: 'Napisz do nas',
  },
});
