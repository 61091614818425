import { GET_COMPANY_MILESTONES_SUCCESS } from '@/constants/ActionTypes';

import { conn } from '../index';

export const getCompanyMilestonesSuccess = data => ({
  type: GET_COMPANY_MILESTONES_SUCCESS,
  payload: data,
});

export const getCompanyMilestones = () => dispatch => {
  conn
    .getMilestones()
    .then(res => {
      dispatch(getCompanyMilestonesSuccess(res.data));
    })
    .catch(err => {
      console.error(err);
    });
};
