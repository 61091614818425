import classNames from 'classnames';

import Tooltip from '../../Tooltip/Tooltip';

import './dateTimeInput.scss';

interface DateTimeInputProps {
  date: string;
  time: string;
  label: string;
  tooltipClassName?: string;
  maxLabelLength?: number;
  className?: string;
}

const DateTimeInput = ({ date, time, label, tooltipClassName, maxLabelLength, className }: DateTimeInputProps) => {
  const dateTimeInputClassName = classNames('k-dataTimeInput', className);
  const showLabelTooltip = maxLabelLength && label.length > maxLabelLength;
  return (
    <div className={dateTimeInputClassName}>
      {showLabelTooltip ? (
        <Tooltip
          content={label}
          className={tooltipClassName}
          yOffset={12}
          wrapperClassName="k-dataTimeInput__tooltipWrapper"
        >
          <label className="k-dataTimeInput__label">{label}</label>
        </Tooltip>
      ) : (
        <label className="k-dataTimeInput__label">{label}</label>
      )}
      <div className="k-dataTimeInput__wrapper">
        <div className="k-dataTimeInput__date">{date}</div>
        <div className="k-dataTimeInput__border" />
        <div className="k-dataTimeInput__time">{time}</div>
      </div>
    </div>
  );
};

export default DateTimeInput;
