import moment from 'moment';

import {
  ADD_ABSENCE_SUCCESS,
  ADD_ABSENCE_TYPE_SUCCESS,
  ADD_OVERTIME_COLLECTION_SUCCESS,
  CHANGE_ABSENCE_LIMIT_USAGE_TABLE_DATA,
  CHANGE_ABSENCE_LIMITS_FOR_EMPLOYEE,
  CHANGE_ABSENCES_MODE,
  CHANGE_ABSENCES_TABLE_DATA,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_TYPE_SUCCESS,
  DELETE_OVERTIME_COLLECTIONS_SUCCESS,
  EDIT_ABSENCE_SUCCESS,
  EDIT_ABSENCE_TYPE_FAILURE,
  EDIT_ABSENCE_TYPE_SUCCESS,
  GET_ABSENCE_LIMIT_USAGE_SUCCESS,
  GET_ABSENCE_SUCCESS,
  GET_ABSENCES,
  GET_ABSENCES_FOR_SCHEDULE,
  GET_ABSENCES_TYPES,
  SET_ABSENCES_LIMITS,
} from '@/constants/ActionTypes.js';

const initialState = {
  absences: [],
  absencesTypes: [],
  absencesTable: {
    currentPage: 1,
    numberOfItemsPerPage: 10,
    numberOfItems: 0,
  },
  scheduleAbsences: {},
  absenceLimitsUsage: [],
  absenceLimitsUsageTable: {
    currentPage: 1,
    numberOfItemsPerPage: 10,
    numberOfItems: 0,
  },
  conditionsId: null,
  absencesLimitTemplates: [],
  absenceLimitsForEmployee: [],
  selectedDisplayMode: '',
};

const absences = (state = initialState, action) => {
  switch (action.type) {
    case GET_ABSENCES_FOR_SCHEDULE:
      return {
        ...state,
        scheduleAbsences: action.payload,
      };
    case GET_ABSENCES:
      return {
        ...state,
        absences: action.payload,
      };
    case ADD_ABSENCE_SUCCESS:
      return {
        ...state,
        absences: [action.payload, ...state.absences],
        scheduleAbsences: {
          ...state.scheduleAbsences,
          [action.payload.employee_id]: [...(state.scheduleAbsences[action.payload.employee_id] || []), action.payload],
        },
      };
    case ADD_OVERTIME_COLLECTION_SUCCESS: {
      const absenceToAdd = {
        ...action.newAbsence,
        category: 'overtime_collection',
        from: action.newAbsence.start_timestamp.split(' ')[0],
        to: action.newAbsence.end_timestamp.split(' ')[0],
        status: 'accepted',
        series_number: action.newAbsence.request_number,
      };
      return {
        ...state,
        absences: [absenceToAdd, ...state.absences],
      };
    }
    case EDIT_ABSENCE_SUCCESS: {
      const existingAbsencesForEmployee = state.scheduleAbsences[action.payload.employee_id] || [];
      const newStatus = action.payload.status;

      return {
        ...state,
        absences: state.absences.map(absence =>
          absence.id === action.payload.id
            ? { ...absence, recent_status_change_by_full_name: action.userName, status: newStatus }
            : absence,
        ),
        scheduleAbsences: {
          ...state.scheduleAbsences,
          [action.payload.employee_id]: [
            ...existingAbsencesForEmployee.filter(absence => absence.id !== action.payload.id),
            { ...action.payload, recent_status_change_by_full_name: action.userName },
          ],
        },
      };
    }
    case GET_ABSENCE_SUCCESS: {
      return {
        ...state,
        absences: state.absences.map(absence => (absence.id === action.payload.id ? action.payload : absence)),
      };
    }
    case DELETE_ABSENCE_SUCCESS:
      return {
        ...state,
        absences: state.absences.filter(absence => absence.id !== action.payload.id),
        scheduleAbsences: {
          ...state.scheduleAbsences,
          [action.payload.employee_id]: (state.scheduleAbsences[action.payload.employee_id] || []).filter(
            absence => absence.id !== action.payload.id,
          ),
        },
      };
    case DELETE_OVERTIME_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        absences: state.absences.filter(absence => !action.payload.includes(absence.id)),
      };
    }
    case GET_ABSENCES_TYPES:
      return {
        ...state,
        absencesTypes: action.payload,
      };
    case ADD_ABSENCE_TYPE_SUCCESS:
      return {
        ...state,
        absencesTypes: [action.payload, ...state.absencesTypes],
      };
    case EDIT_ABSENCE_TYPE_SUCCESS:
      return {
        ...state,
        absencesTypes: state.absencesTypes.map(absenceType =>
          absenceType.id === action.payload.id ? action.payload : absenceType,
        ),
      };
    case EDIT_ABSENCE_TYPE_FAILURE:
      return state;
    case DELETE_ABSENCE_TYPE_SUCCESS:
      return {
        ...state,
        absencesTypes: state.absencesTypes.map(absenceType =>
          action.payload.includes(absenceType.id)
            ? { ...absenceType, deleted_at_timestamp: moment().format('YYYY-MM-DD HH:mm:ss') }
            : absenceType,
        ),
      };
    case CHANGE_ABSENCES_TABLE_DATA:
      return {
        ...state,
        absencesTable: {
          currentPage: action.payload.currentPage,
          numberOfItemsPerPage: action.payload.numberOfItemsPerPage,
          numberOfItems: action.payload.numberOfItems,
        },
      };
    case GET_ABSENCE_LIMIT_USAGE_SUCCESS:
      return {
        ...state,
        absenceLimitsUsage: action.payload,
      };
    case CHANGE_ABSENCE_LIMIT_USAGE_TABLE_DATA:
      return {
        ...state,
        absenceLimitsUsageTable: {
          currentPage: action.payload.currentPage,
          numberOfItemsPerPage: action.payload.numberOfItemsPerPage,
          numberOfItems: action.payload.numberOfItems,
        },
      };
    case SET_ABSENCES_LIMITS:
      return {
        ...state,
        absencesLimitTemplates: action.payload,
      };
    case CHANGE_ABSENCE_LIMITS_FOR_EMPLOYEE:
      return {
        ...state,
        absenceLimitsForEmployee: action.payload,
      };
    case CHANGE_ABSENCES_MODE:
      return {
        ...state,
        selectedDisplayMode: action.payload,
      };
    default:
      return state;
  }
};

export default absences;
