import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './AutoGenerateCreateScheduleLeftColumn.scss';

class AutoGenerateCreateScheduleLeftColumn extends PureComponent {
  static getSumForRow(row) {
    return row.reduce((acc, el) => {
      if (el) return acc + parseInt(el);
      return acc;
    }, 0);
  }

  render() {
    const { schedule, jobTitle } = this.props;
    const row = schedule[jobTitle.id] || [];
    const sum = AutoGenerateCreateScheduleLeftColumn.getSumForRow(row);

    return (
      <tr className="k-autoGenerateCreateScheduleLeftColumn">
        <td>
          <div
            style={{ backgroundColor: `${jobTitle.color}30`, color: jobTitle.color }}
            className="k-autoGenerateCreateScheduleLeftColumn__jobTitleWrapper"
          >
            <div className="k-autoGenerateCreateScheduleLeftColumn__jobTitleText">{jobTitle.title}</div>
            <div className="k-autoGenerateCreateScheduleLeftColumn__jobTitleSum">({sum} h)</div>
          </div>
        </td>
      </tr>
    );
  }
}

AutoGenerateCreateScheduleLeftColumn.propTypes = {
  schedule: PropTypes.shape({}),
  jobTitle: PropTypes.shape({}),
};

export default AutoGenerateCreateScheduleLeftColumn;
