import { defineMessages } from 'react-intl';

export const AVAILABILITIES_SORTING_OPTION_ID = 5;
export const CUSTOM_SORTING_OPTION_ID = 6;

const sortByAvailabilities = (a, b) =>
  Object.values(b.availability_blocks).length - Object.values(a.availability_blocks).length;

const sortingNames = defineMessages({
  chronological: {
    id: 'sortOrder.chronological',
    defaultMessage: 'Kolejność dodania',
  },
  name: {
    id: 'sortOrder.name',
    defaultMessage: 'Imię pracownika',
  },
  lastName: {
    id: 'sortOrder.lastName',
    defaultMessage: 'Nazwisko pracownika',
  },
  jobTitles: {
    id: 'sortOrder.jobTitles',
    defaultMessage: 'Stanowiska',
  },
  shiftNumber: {
    id: 'sortOrder.shiftNumber',
    defaultMessage: 'Ilość zmian',
  },
  availabilities: {
    id: 'sortOrder.availabilities',
    defaultMessage: 'Dostępności',
  },
  custom: {
    id: 'sortOrder.custom',
    defaultMessage: 'Własny',
  },
});

export const employeeSortingOptions = [
  { id: 0, displayText: 'Kolejność dodania', type: 'added', sortFunc: null, message: sortingNames.chronological },
  {
    id: 1,
    displayText: 'Imię pracownika',
    type: 'name',
    sortFunc: (a, b) => (`${a.first_name} ${a.last_name}` > `${b.first_name} ${b.last_name}` ? 1 : -1),
    message: sortingNames.name,
  },
  {
    id: 2,
    displayText: 'Nazwisko pracownika',
    type: 'lastname',
    sortFunc: (a, b) => (`${a.last_name} ${a.first_name}` > `${b.last_name} ${b.first_name}` ? 1 : -1),
    message: sortingNames.lastName,
  },
  {
    id: 3,
    displayText: 'Stanowiska',
    type: 'job_title',
    sortFunc: (a, b) => {
      const aJobtitles = a.jobTitles.map(jobTitle => jobTitle.title);
      const bJobtitles = b.jobTitles.map(jobTitle => jobTitle.title);
      return `${aJobtitles.join(' ')}` > `${bJobtitles.join(' ')}` ? 1 : -1;
    },
    message: sortingNames.jobTitles,
  },
  {
    id: 4,
    displayText: 'Ilość zmian',
    type: 'shift_count',
    sortFunc: (a, b) => (a.shifts_no > b.shifts_no ? -1 : 1),
    message: sortingNames.shiftNumber,
  },
  {
    id: 5,
    displayText: 'Dostępności',
    type: 'jobtitle_count',
    sortFunc: (a, b) => sortByAvailabilities(a, b),
    message: sortingNames.availabilities,
  },
  {
    id: 6,
    displayText: 'Własny',
    type: 'custom',
    sortFunc: null,
    message: sortingNames.custom,
  },
];
