import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ExportModal from '@/components/common/exports/ExportModal/ExportModal.redux';
import {
  FILE_CSV_BACKGROUND,
  FILE_CSV_COLOR,
  FILE_PDF_BACKGROUND,
  FILE_PDF_COLOR,
  FILE_XLS_BACKGROUND,
  FILE_XLS_COLOR,
  FILE_XML_BACKGROUND,
  FILE_XML_COLOR,
} from '@/constants/colors';
import { payrollExportData, payrollLocationExportData } from '@/constants/exportData.js';
import { EXPORT_GSN_SHOW } from '@/constants/Permissions';
import { getPreselected } from '@/utils/exports/exportHelpers';

const withDependency = obj => ({
  ...obj,
  depends: { option: 'version', value: 'details' },
});

const detailsOption = {
  type: 'radio',
  id: 'version',
  values: [
    {
      id: 'brief',
      label: <FormattedMessage id="export.exportSettings.basicVersion" defaultMessage="Wersja podstawowa" />,
      sublabel: (
        <FormattedMessage
          id="export.exportSettings.basicVersionDescription"
          defaultMessage="Pobrany plik będzie zawierał sumaryczne zestawienie z wybranego okresu dla wybranych pracowników"
        />
      ),
    },
    {
      id: 'details',
      label: <FormattedMessage id="export.exportSettings.extendedVersion" defaultMessage="Wersja rozszerzona" />,
      sublabel: (
        <FormattedMessage
          id="export.exportSettings.extendedVersionDescription"
          defaultMessage="Pobrany plik będzie zawierał zestawienie pojedynczych dni, które pojawiły się w danym okresie dla wybranych pracowników "
        />
      ),
    },
  ],
};

const freeDays = {
  type: 'checkbox',
  id: 'freeDays',
  text: 'Dni wolne',
};

const mergeInDay = {
  type: 'checkbox',
  id: 'mergeInDay',
  text: 'Połącz obecności dla danego dnia',
};

const availabilities = {
  type: 'checkbox',
  id: 'availabilities',
  text: 'Dostępności',
};

const absences = {
  type: 'checkbox',
  id: 'absences',
  text: 'Nieobecności',
};

const defaultPreselected = [0, 1, 2, 3, 4, 6, 11];
const columnList = columns => ({
  type: 'columnList',
  id: 'selectedColumns',
  values: columns,
  preselected: getPreselected('PDF', 'payrollLocation', defaultPreselected),
});

const defaultPayrollPreselected = [0, 1, 2, 3, 4, 5, 7, 9, 10];
const payrollColumnList = columns => ({
  type: 'columnList',
  id: 'selectedColumns',
  values: columns.headers,
  preselected: getPreselected('PDF', 'payrollLocation', defaultPayrollPreselected),
  hidden: columns.hidden,
});

const sageAbsenceOptions = {
  type: 'radio',
  id: 'additional',
  values: [
    {
      id: 'no-additional',
      label: (
        <FormattedMessage id="export.exportSettings.sageNullOption" defaultMessage="Bez nieobecności i dostępności" />
      ),
    },
    {
      id: 'absences',
      label: <FormattedMessage id="export.exportSettings.sageAbsences" defaultMessage="Nieobecności" />,
    },
    {
      id: 'availabilities',
      label: <FormattedMessage id="export.exportSettings.sageAvailabilities" defaultMessage="Dostępności" />,
    },
  ],
};

const getEmployeeContractorOption = setContractorMode => ({
  type: 'radio',
  id: 'employeeContractor',
  values: [
    {
      id: 'employee',
      label: <FormattedMessage id="export.exportSettings.employee" defaultMessage="Pracownik" />,
      sublabel: (
        <FormattedMessage
          id="export.exportSettings.employeeDescription"
          defaultMessage="Wybierz, jeżeli w wygenerowanym dokumencie nagłówki kolumn mają odpowiadać pracownikowi"
        />
      ),
    },
    {
      id: 'contractor',
      label: <FormattedMessage id="export.exportSettings.contractor" defaultMessage="Zleceniobiorca" />,
      sublabel: (
        <FormattedMessage
          id="export.exportSettings.contractorDescription"
          defaultMessage="Wybierz, jeżeli w wygenerowanym dokumencie nagłówki kolumn mają odpowiadać zleceniobiorcy"
        />
      ),
    },
  ],
  onChange: newValue => setContractorMode(newValue),
});
const ExportPayrollLocationModal = props => {
  const [contractorMode, setContractorMode] = useState(false);

  const relevantColumns = payrollExportData.headers.reduce(
    (result, header, index) => {
      const columnPermission = payrollExportData.columnsPermission[index];
      const newHeaders = [...result.headers, header];

      if (columnPermission && !props.userPermissions.includes(columnPermission)) {
        return {
          headers: newHeaders,
          hidden: [...result.hidden, index],
        };
      }

      return {
        headers: newHeaders,
        hidden: result.hidden,
      };
    },
    { headers: [], hidden: [] },
  );

  const formats = [
    {
      text: 'CSV',
      backgroundColor: FILE_CSV_BACKGROUND,
      color: FILE_CSV_COLOR,
      options: [detailsOption],
    },
    props.payrollUI.selectedEmployees.length > 1
      ? {
          text: 'XLS',
          backgroundColor: FILE_XLS_BACKGROUND,
          color: FILE_XLS_COLOR,
          options: [mergeInDay, freeDays, availabilities, absences],
        }
      : {
          text: 'XLS',
          backgroundColor: FILE_XLS_BACKGROUND,
          color: FILE_XLS_COLOR,
          options: [
            detailsOption,
            withDependency(mergeInDay),
            withDependency(freeDays),
            withDependency(availabilities),
            withDependency(absences),
          ],
        },
    props.payrollUI.selectedEmployees.length > 1
      ? {
          text: 'PDF',
          backgroundColor: FILE_PDF_BACKGROUND,
          color: FILE_PDF_COLOR,
          options: [freeDays, payrollColumnList(relevantColumns)],
        }
      : {
          text: 'PDF',
          backgroundColor: FILE_PDF_BACKGROUND,
          color: FILE_PDF_COLOR,
          options: [
            getEmployeeContractorOption(setContractorMode),
            columnList(
              contractorMode ? payrollLocationExportData.contractorHeaders : payrollLocationExportData.headers,
            ),
          ],
        },
    { text: 'XML', backgroundColor: FILE_XML_BACKGROUND, color: FILE_XML_COLOR, subtitle: 'Enova', options: [] },
    { text: 'XLS', backgroundColor: FILE_XLS_BACKGROUND, color: FILE_XLS_COLOR, subtitle: 'Comarch', options: [] },
    {
      text: 'XLS',
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      subtitle: 'Sage',
      options: [sageAbsenceOptions],
    },
    {
      text: 'XLS',
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      subtitle: 'R2Płatnik',
      options: [],
    },
    props.userPermissions.includes(EXPORT_GSN_SHOW) && {
      text: 'XLS',
      backgroundColor: FILE_XLS_BACKGROUND,
      color: FILE_XLS_COLOR,
      subtitle: 'GSN',
      options: [],
    },
  ].filter(format => Boolean(format));

  return <ExportModal {...props} formats={formats} />;
};
ExportPayrollLocationModal.propTypes = {
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  payrollUI: PropTypes.shape({
    selectedEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ExportPayrollLocationModal;
