import { FormattedMessage } from 'react-intl';

import { SUPPLEMENTARY_LOCATIONS_VIEW } from './Permissions';

export const optionType = {
  TOGGLE: 'toggle',
  INTEGER: 'integer',
  SINGLE_SLIDER: 'single-slider',
};
export const FAIR_WORKING_HOURS_DISTRIBUTION = 'fair_working_hours_distribution';

export const scheduleGenerationOptions = {
  shortest_shift_block_duration: {
    name: 'shortest_shift_block_duration',
    type: optionType.SINGLE_SLIDER,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    disableCheckbox: true,
    defaultValue: 6,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.shortestWorktime"
        defaultMessage="Minimalny czas trwania pracy w trakcie dnia"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.shortestWorktimeDesc"
        defaultMessage="Ustaw minimalny czas trwania pracy pracownika na wszystkich stanowiskach w jednym dniu"
      />
    ),
  },
  omit_holidays: {
    name: 'omit_holidays',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    label: <FormattedMessage id="schedule.generationOptions.omitHolidays" defaultMessage="Pomiń dni wolne od pracy" />,
    description: (
      <FormattedMessage
        id="schedule.generationOptions.omitHolidaysDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System podczas generowania grafiku wstawi zmiany w dni wolne od pracy."
      />
    ),
  },
  daily_overtime: {
    name: 'daily_overtime',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    label: (
      <FormattedMessage id="schedule.generationOptions.dailyOvertime" defaultMessage="Unikaj nadgodzin dziennych" />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.dailyOvertimeDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System podczas generowania grafiku bedzie przydzielał zmiany dłuższe niż dobowy wymiar czasu pracy pracownika."
      />
    ),
  },
  daily_rest: {
    name: 'daily_rest',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.dailyRest"
        defaultMessage="11 godzinny nieprzerwany odpoczynek dobowy"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.dailyRestDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System nie będzie pilnował 11 godzinnego odpoczynku pracownika pomiędzy zmianami."
      />
    ),
  },
  free_sunday: {
    name: 'free_sunday',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    label: <FormattedMessage id="schedule.generationOptions.freeSunday" defaultMessage="Co 4 niedziela wolna" />,
    description: (
      <FormattedMessage
        id="schedule.generationOptions.freeSundayDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System może zaplanować pracę pracownikowi więcej niż 3 niedziele pod rząd."
      />
    ),
  },
  max_monthly_hours: {
    name: 'max_monthly_hours',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: false,
    label: (
      <FormattedMessage id="schedule.generationOptions.maxMonthlyHours" defaultMessage="Miesięczne normy czasu pracy" />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.maxMonthlyHoursDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System może przydzielić pracownikowi więcej zmian niż jego norma miesięczna."
      />
    ),
  },
  min_working_days_in_row: {
    name: 'min_working_days_in_row',
    type: optionType.INTEGER,
    enabled: false,
    value: 0,
    maxValue: 10,
    isConfigurationOption: true,
    isOtherOption: false,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.minWorkingDaysInRow"
        defaultMessage="Minimum dni pracy pod rząd"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.minWorkingDaysInRowDesc"
        defaultMessage="Jeśli zaznaczysz ten parametr i podasz liczbę x to System będzie wstawiał pracownikowi minimum x zmian pod rząd."
      />
    ),
  },
  max_working_days_in_row: {
    name: 'max_working_days_in_row',
    type: optionType.INTEGER,
    enabled: false,
    value: 0,
    maxValue: 31,
    isConfigurationOption: true,
    isOtherOption: false,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.maxWorkingDaysInRow"
        defaultMessage="Maksimum dni pracy pod rząd"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.maxWorkingDaysInRowDesc"
        defaultMessage="Jeśli zaznaczysz ten parametr i podasz liczbę x to System będzie wstawiał pracownikowi maksimum x zmian pod rząd."
      />
    ),
  },

  show_forecast_schedule: {
    name: 'show_forecast_schedule',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: false,
    isOtherOption: true,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.showForecastSchedule"
        defaultMessage="Wyświetl prognozowany grafik na bazie szablonu"
      />
    ),
  },

  show_forecast_schedule_for_basic_template: {
    name: 'show_forecast_schedule_for_basic_template',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: false,
    isOtherOption: true,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.showForecastScheduleForBasicTemplate"
        defaultMessage="Wyświetl prognozowany grafik dla wybranych szablonów"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.showForecastScheduleForBasicTemplateDesc"
        defaultMessage="Jeśli zaznaczysz ten parametr to w oparciu o wybrane szablony zostanie wyświetlony prognozowany grafik w okresie w jakim generowany jest grafik pracy."
      />
    ),
  },

  short_timeout: {
    name: 'short_timeout',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: false,
    isOtherOption: true,
    label: <FormattedMessage id="schedule.generationOptions.shortTimeout" defaultMessage="Szybki wynik" />,
    description: (
      <FormattedMessage
        id="schedule.generationOptions.shortTimeoutDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System otrzyma więcej czasu na wygenerowanie zmian. Użyj tego parametru gdy zależy Ci na najoptymalniejszym obsadzeniu zmianami."
      />
    ),
  },
  include_current_shifts: {
    name: 'include_current_shifts',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: true,
    isOtherOption: true,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.includeCurrentShifts"
        defaultMessage="Uwzględnij dotychczas zaplanowany grafik"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.includeCurrentShiftsDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System nie będzie brał pod uwagę zmian już zaplanowanych w grafiku i zaplanuje pracę według szablonu nie uwzględniając ich."
      />
    ),
  },
  include_supplementary_employees: {
    name: 'include_supplementary_employees',
    type: optionType.TOGGLE,
    permission: SUPPLEMENTARY_LOCATIONS_VIEW,
    enabled: false,
    isConfigurationOption: true,
    isOtherOption: true,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.includeSupplementaryEmployees"
        defaultMessage="Uwzględnij pracowników pomocniczych"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.includeSupplementaryEmployeesDesc"
        defaultMessage="Jeśli zaznaczysz ten parametr to System podczas generowania grafiku weźmie również pod uwagę pracowników pomocniczych przypisanych do lokalizacji."
      />
    ),
  },
  availability_without_entered: {
    name: 'availability_without_entered',
    type: optionType.TOGGLE,
    enabled: true,
    isConfigurationOption: false,
    isOtherOption: true,
    label: (
      <FormattedMessage
        id="schedule.generationOptions.availabilityWithoutEntered"
        defaultMessage="Dni bez wpisanych dostępności pracownika uznaj za dostępne"
      />
    ),
    description: (
      <FormattedMessage
        id="schedule.generationOptions.availabilityWithoutEnteredDesc"
        defaultMessage="Jeśli odznaczysz ten parametr to System nie będzie planował grafiku pracy w dniach w których pracownik nie ma wpisanych dostępności."
      />
    ),
  },
};

const fairWorkingHoursDistribution = {
  name: FAIR_WORKING_HOURS_DISTRIBUTION,
  type: optionType.TOGGLE,
  enabled: false,
  isConfigurationOption: false,
  isOtherOption: true,
  label: (
    <FormattedMessage
      id="schedule.generationOptions.fairWorkingHoursDistribution"
      defaultMessage="Równomierny rozkład godzin pracy"
    />
  ),
  description: (
    <FormattedMessage
      id="schedule.generationOptions.fairWorkingHoursDistributionDesc"
      defaultMessage="Jeśli zaznaczysz tę opcję włączoną to System będzie dążyć do tego, aby przydzielić tę samą ilość godzin porannych, popołudniowych i nocnych dla pracowników na tym samym stanowisku."
    />
  ),
};

const basicTemplateScheduleGenerationOptions = [
  'show_forecast_schedule_for_basic_template',
  'omit_holidays',
  'daily_overtime',
  'daily_rest',
  'max_monthly_hours',
  'min_working_days_in_row',
  'max_working_days_in_row',
  'include_current_shifts',
  'include_supplementary_employees',
  'free_sunday',
  'availability_without_entered',
].reduce((acc, cur) => ({ ...acc, [cur]: scheduleGenerationOptions[cur] }), {});

export const basicTemplateGenerationOptionsForProxy = {
  ...basicTemplateScheduleGenerationOptions,
  fair_working_hours_distribution: fairWorkingHoursDistribution,
};

export const basicTemplateGenerationOptions = {
  short_timeout: scheduleGenerationOptions.short_timeout,
  ...basicTemplateScheduleGenerationOptions,
};

export const elasticTemplateGenerationOptions = Object.values(scheduleGenerationOptions).filter(
  ({ name }) => name !== 'show_forecast_schedule_for_basic_template',
);
