import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MultiSelect from '@/components/common/inputs/MDMultiSelect/MultiSelect.jsx';
import { COMPANY_MANAGE_LOCATIONS_HIDE_ASSIGN } from '@/constants/Restrictions.js';

import '../../EmployeeModalPropList.scss';

const EmployeePropListLocations = ({
  isManager,
  locations = [],
  selectedLocations = [],
  showSupplementaryLocations = false,
  supplementaryLocations = [],
  addLocation,
  deleteLocation,
  onChangeLocation,
  toggleLocationAsSupplementary,
  userPermissions,
}) => {
  const isEditDisabled = userPermissions.restrictions.includes(COMPANY_MANAGE_LOCATIONS_HIDE_ASSIGN);
  return (
    <>
      <div className="multiSelect__header">
        <div className="multiSelect__header__text">
          <span className="material-icons">location_on</span>
          <span>Lokalizacje</span>
        </div>
        <div className="multiSelect__header__buttons">
          <button data-test="addAllLocations" disabled={isEditDisabled} onClick={e => addLocation(e, { all: true })}>
            <FormattedMessage id="companymanage.employees.addAllLocations" defaultMessage="Dodaj wszystkie" />
          </button>
          <button
            data-test="deleteAllLocations"
            disabled={isEditDisabled}
            onClick={e => deleteLocation(e, { all: true })}
          >
            <FormattedMessage id="companymanage.employees.deleteAllLocations" defaultMessage="Usuń wszystkie" />
          </button>
        </div>
      </div>
      <MultiSelect
        disabled={isEditDisabled}
        label="Wybierz lokalizacje"
        options={locations}
        onChange={onChangeLocation}
        testId="locationsSelect"
      />
      <div className="selectedOptions" data-test="locationsSelect-selectedOptions">
        <OverlayScrollbarsComponent className="overlay-scrollbar" style={{ width: '100%', height: '17rem' }}>
          {selectedLocations.map(location => (
            <div key={location.id} className="selectedOptions__item">
              <div className="selectedOptions__item__text">
                <span style={{ backgroundColor: location.color }} className="selectedOptions__item__text__color" />
                {location.name}
              </div>
              <div className="selectedOptions__item__actions">
                {isManager || !showSupplementaryLocations ? null : (
                  <div className="supplementaryLocation">
                    <span className="supplementaryLocation__label">
                      <FormattedMessage
                        id="companymanage.employees.supplementaryLocation"
                        defaultMessage="Pomocnicza"
                      />
                    </span>
                    <button
                      disabled={isEditDisabled}
                      onClick={() => toggleLocationAsSupplementary(location.id)}
                      className="supplementaryLocation__checkbox"
                      data-test={`toggleLocationAsSupplementary-${location.name}`}
                    >
                      {supplementaryLocations.includes(parseInt(location.id)) && (
                        <span className="material-icons">done</span>
                      )}
                    </button>
                  </div>
                )}
                {!isEditDisabled && (
                  <button
                    data-test={`deleteLocation-${location.name}`}
                    disabled={isEditDisabled}
                    onClick={() => deleteLocation(location.id)}
                  >
                    <span className="material-icons">clear</span>
                  </button>
                )}
              </div>
            </div>
          ))}
        </OverlayScrollbarsComponent>
      </div>
    </>
  );
};

EmployeePropListLocations.propTypes = {
  isManager: PropTypes.bool,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      color: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  showSupplementaryLocations: PropTypes.bool,
  supplementaryLocations: PropTypes.arrayOf(PropTypes.string),
  addLocation: PropTypes.func,
  deleteLocation: PropTypes.func,
  onChangeLocation: PropTypes.func,
  toggleLocationAsSupplementary: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default EmployeePropListLocations;
