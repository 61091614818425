import { Provider as ReduxProvider } from 'react-redux';

import store from '@/redux-store/store';

import { KadroIntlProvider } from '../intlProvider/IntlProvider';

type Props = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: Props) => (
  <ReduxProvider store={store}>
    <KadroIntlProvider>{children}</KadroIntlProvider>
  </ReduxProvider>
);
