import { connect } from 'react-redux';

import AddMassEmployeesForm from './AddMassEmployeesForm';

const mapStateToProps = state => ({
  companyEmploymentConditions: state.reducer.employmentConditions,
  companyRoles: state.reducer.roles.companyRoles,
});

export default connect(mapStateToProps)(AddMassEmployeesForm);
