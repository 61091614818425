import { useAppSelector } from '@/redux-store';
import { selectJobTitlesChartData } from '@/selectors/reports.selectors';
import { selectUserJobTitles } from '@/selectors/userJobTitles.selectors';

export const useJobTitlesChart = () => {
  const data = useAppSelector(selectJobTitlesChartData);
  const userJobTitles = useAppSelector(selectUserJobTitles);

  return { data, userJobTitles };
};
