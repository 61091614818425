import { connect } from 'react-redux';

import { logoutUser } from '@/actions/auth.jsx';
import { payForInvoice } from '@/actions/payments';

import Component from './UnlockAccountView.jsx';

const mapStateToProps = state => ({
  unlockPaymentData: state.reducer.payments.unlockViewData,
  currentUser: state.reducer.currentUser,
  isEmployee: state.reducer.userPermissions.isEmployee,
  companyRoles: state.reducer.roles.companyRoles,
});

const mapDispatchToProps = {
  payForInvoice,
  logoutUser,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;
