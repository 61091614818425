import ExportModal from '@/components/common/exports/ExportModal/ExportModal.redux';
import { FILE_PDF_BACKGROUND, FILE_PDF_COLOR, FILE_XLS_BACKGROUND, FILE_XLS_COLOR } from '@/constants/colors.js';
import { dayReportExportData } from '@/constants/exportData.js';

const columnList = columns => ({
  type: 'columnList',
  id: 'selectedColumns',
  values: columns,
  preselected: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
});

const ExportDayReportModal = props => (
  <ExportModal
    {...props}
    formats={[
      { text: 'XLS', backgroundColor: FILE_XLS_BACKGROUND, color: FILE_XLS_COLOR, options: [] },
      {
        text: 'PDF',
        backgroundColor: FILE_PDF_BACKGROUND,
        color: FILE_PDF_COLOR,
        options: [columnList(dayReportExportData.headers)],
      },
    ]}
  />
);

ExportDayReportModal.propTypes = {};

export default ExportDayReportModal;
