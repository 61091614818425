import { useCallback, useEffect, useState } from 'react';

enum BrowserMediaQueryEnum {
  PHONE = 599,
  TABLET_PORTRAIT = 600,
  TABLET_LANDSCAPE = 900,
  DESKTOP = 1200,
  BIG_DESKTOP = 1500,
  LARGE_DESKTOP = 1800,
}

export const useMediaQuery = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth < BrowserMediaQueryEnum.PHONE;
  const isTabletPortrait =
    windowWidth >= BrowserMediaQueryEnum.TABLET_PORTRAIT && windowWidth < BrowserMediaQueryEnum.TABLET_LANDSCAPE;
  const isTabletLandscape =
    windowWidth >= BrowserMediaQueryEnum.TABLET_LANDSCAPE && windowWidth < BrowserMediaQueryEnum.DESKTOP;
  const isDesktop = windowWidth >= BrowserMediaQueryEnum.DESKTOP && windowWidth < BrowserMediaQueryEnum.BIG_DESKTOP;
  const isBigDesktop =
    windowWidth >= BrowserMediaQueryEnum.BIG_DESKTOP && windowWidth < BrowserMediaQueryEnum.LARGE_DESKTOP;
  const isLargeDesktop = windowWidth >= BrowserMediaQueryEnum.LARGE_DESKTOP;

  const isAtLeastTabletPortrait = windowWidth >= BrowserMediaQueryEnum.TABLET_PORTRAIT;
  const isAtLeastTabletLandscape = windowWidth >= BrowserMediaQueryEnum.TABLET_LANDSCAPE;
  const isAtLeastDesktop = windowWidth >= BrowserMediaQueryEnum.DESKTOP;
  const isAtLeastBigDesktop = windowWidth >= BrowserMediaQueryEnum.BIG_DESKTOP;
  const isAtLeastLargeDesktop = windowWidth >= BrowserMediaQueryEnum.LARGE_DESKTOP;

  return {
    isMobile,
    isTabletPortrait,
    isTabletLandscape,
    isDesktop,
    isBigDesktop,
    isLargeDesktop,
    isAtLeastTabletPortrait,
    isAtLeastTabletLandscape,
    isAtLeastDesktop,
    isAtLeastBigDesktop,
    isAtLeastLargeDesktop,
  };
};
