import * as AT from '@/constants/ActionTypes.js';
import { ScheduleItemType } from '@/constants/dragAndDrop';

const initialState = {
  draggedShiftId: '',
  draggedShiftDate: '',
  originEmployeeId: '',
  draggedOpenShiftId: '',
  blockedCells: {},
  draggedAvailability: {},
  itemType: '',
  isSwapping: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.START_SHIFT_DRAG:
      return {
        ...state,
        blockedCells: action.payload.blockedCells,
        draggedShiftId: action.payload.shiftId,
        draggedShiftDate: action.payload.date,
        originEmployeeId: action.payload.employeeId,
        draggedOpenShiftId: '',
        draggedAvailability: {},
        itemType: ScheduleItemType.SHIFT,
      };
    case AT.STOP_OPEN_SHIFT_DRAG:
    case AT.STOP_SHIFT_DRAG:
    case AT.STOP_AVAILABILITY_DRAG:
      return { ...initialState };
    case AT.START_OPEN_SHIFT_DRAG:
      return {
        ...state,
        blockedCells: action.payload.blockedCells,
        draggedOpenShiftId: action.payload.openShiftId,
        draggedShiftId: '',
        draggedAvailability: {},
        itemType: ScheduleItemType.OPEN_SHIFT,
      };
    case AT.START_AVAILABILITY_DRAG:
      return {
        ...state,
        draggedAvailability: action.payload.availabilityType,
        itemType: ScheduleItemType.AVAILABILITY,
      };
    case AT.START_SWAPPING:
      return {
        ...state,
        isSwapping: true,
      };
    case AT.STOP_SWAPPING:
      return {
        ...state,
        isSwapping: false,
      };
    default:
      return state;
  }
};
export default reducer;
