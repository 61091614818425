import { FormattedMessage } from 'react-intl';

import MDInputTooltip from '@/components/common/inputs/MDInputTooltip/MDInputTooltip';
import { DIRECTIONS } from '@/constants/ui';

import './Definition.scss';

const style: React.CSSProperties = { maxWidth: '350px' };

export const Definition = () => (
  <div className="k-AddFreeDayDefinition">
    <MDInputTooltip
      content={
        <div className="k-AddFreeDayDefinition__content">
          <FormattedMessage
            id="addFreeDayTab.definition"
            defaultMessage="Dni wolne to typy dni w grafiku pracownika, w których nie planujesz jego pracy. Oznaczenia mają wpływ na rozliczenie pracy dodatkowej, przypadającej w dniu, w którym nie zaplanowano grafiku. W przypadku braku oznaczeń aplikacja uwzględnia rozliczenie nadgodzin w niedziele i święta na podstawie kalendarza."
          />
        </div>
      }
      offset={-15}
      position={DIRECTIONS.BOTTOM}
      popoverStyle={style}
    />
  </div>
);
