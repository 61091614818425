import { FormattedMessage } from 'react-intl';

import '../../css/scss/components/common/TranslatorHelper.scss';

const TranslatorHelper = (props) => {
  const onDropHandler = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      props.updateIntlMessages(JSON.parse(event.target.result));
    };
    reader.readAsText(file);
  };
  return (
    <div
      className="k-translator-helper"
      draggable="true"
      onDrop={(e) => onDropHandler(e)}
      onDragOver={(e) => e.preventDefault()}
    >
      <FormattedMessage
        id="common.translatorHelper.mainText"
        defaultMessage="Przeciągnij plik z tłumaczeniami w to miejsce aby załadować zmiany"
      />
    </div>
  );
};

TranslatorHelper.propTypes = {};

export default TranslatorHelper;
