import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, LeftColumn, LeftHeader, Rows } from '@/components/common/Grid/Grid.jsx';
import { ScrollContext } from '@/components/common/ScrollWrapper.jsx';
import ScheduleTableHeaderContainer from '@/containers/scheduler/ScheduleTableHeaderContainer.js';

import ScheduleEvents from './ScheduleEvents.redux.js';

export const ScheduleEventsTable = props => {
  if (props.hide) return null;
  return (
    <Grid
      width={props.width}
      columnStyle={props.styles.columnStyle}
      scrollCallback={props.scrollCallback}
      scrollPosition={props.scrollPosition}
      className="kadroGrid--events"
      static
    >
      <LeftHeader>
        <th className="tab_title">
          <i className="material-icons drag_handle">drag_handle</i>
          <FormattedMessage id="schedule.events.header" defaultMessage="Wydarzenia" />
        </th>
      </LeftHeader>
      <Header>
        <thead>
          <ScheduleTableHeaderContainer />
        </thead>
      </Header>
      <LeftColumn>
        <tr className="h-sorce">
          <td />
        </tr>
      </LeftColumn>
      <Rows className={props.tableClass}>
        <ScheduleEvents
          userSettings={props.scheduleUIState.settings}
          holidays={props.holidays}
          showModal={props.showModal}
          deleteUserEvent={props.deleteUserEvent}
          dateStore={props.dateStore}
          userEvents={props.userEvents}
          scheduleLocationFilter={props.scheduleLocationFilter}
          isEmployee={props.userPermissions.isEmployee}
        />
      </Rows>
    </Grid>
  );
};

ScheduleEventsTable.propTypes = {
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  styles: PropTypes.shape({
    columnStyle: PropTypes.shape({}),
  }),
  hide: PropTypes.bool,
  tableClass: PropTypes.string,
  scrollCallback: PropTypes.func,
  scrollPosition: PropTypes.number,
  userEvents: PropTypes.arrayOf(PropTypes.shape({})),
  dateStore: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
  }),
  scheduleUIState: PropTypes.shape({
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  deleteUserEvent: PropTypes.func,
  showModal: PropTypes.func,
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default props => (
  <ScrollContext.Consumer>
    {({ scrollCallback, scrollPosition }) => (
      <ScheduleEventsTable {...props} scrollCallback={scrollCallback} scrollPosition={scrollPosition} />
    )}
  </ScrollContext.Consumer>
);
