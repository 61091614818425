import {
  AGGREGATION_OPTIONS_VALUES,
  BUDGET_ICONS,
  BUDGET_TIME_INTERVALS,
  BUDGET_TYPES,
} from '@/constants/budgetMetrics';
import { COLOR_PALETT_DEFAULT_COLOR } from '@/constants/colors';

import { aggregationMessages, locationsMultiSelectMessages, messages } from './messages';

export const getInitialState = () => ({
  budgetMetricName: '',
  budgetMetricType: BUDGET_TYPES.NUMBER,
  timeInterval: 15,
  color: COLOR_PALETT_DEFAULT_COLOR,
  icon: BUDGET_ICONS[0],
  locationIds: [],
  errors: {},
  settings: { aggregation: 'sum' },
});

export const createBudgetMetricObject = (
  { budgetMetricName, budgetMetricType, timeInterval, color, icon, settings },
  locations,
) => ({
  time_interval: timeInterval,
  name: budgetMetricName,
  type: budgetMetricType,
  color,
  icon,
  location_ids: locations.filter(({ active }) => active).map(({ value }) => value),
  settings,
});

export const getUpdatedStateForEdit = (editedBudgetMetricId, userBudgetMetrics) => {
  const budgetMetricObject = userBudgetMetrics.find(({ id }) => id === editedBudgetMetricId);

  return {
    budgetMetricName: budgetMetricObject.name,
    budgetMetricType: budgetMetricObject.type,
    timeInterval: budgetMetricObject.time_interval,
    color: budgetMetricObject.color,
    icon: budgetMetricObject.icon,
    locationIds: budgetMetricObject.location_ids,
    settings: budgetMetricObject.settings,
  };
};

export const getLocationsOptions = userLocations =>
  userLocations.reduce(
    (options, location) => [
      ...options,
      {
        label: location.name,
        value: parseInt(location.id),
        active: false,
      },
    ],
    [],
  );

export const getBodyLabel = (intl, selectedItem) => {
  const numberOfSelectedLocations = selectedItem.length;
  if (numberOfSelectedLocations === 0) return intl.formatMessage(locationsMultiSelectMessages.bodyLabel);
  if (numberOfSelectedLocations === 1) return `(${numberOfSelectedLocations}) ${selectedItem[0].label}`;
  return ` ${intl.formatMessage(locationsMultiSelectMessages.locationsPlaceholder, {
    numberOfSelectedLocations,
  })}`;
};

export const getLocationsMessages = (intl, selectedLocations) => ({
  headerTitle: intl.formatMessage(locationsMultiSelectMessages.headerTitle),
  selectAll: intl.formatMessage(locationsMultiSelectMessages.selectAll),
  deselectAll: intl.formatMessage(locationsMultiSelectMessages.deselectAll),
  bodyLabel: getBodyLabel(intl, selectedLocations),
});

export const typeOptions = Object.values(BUDGET_TYPES)
  .map(type => ({
    value: type,
    key: messages[type],
  }))
  .sort((a, b) => a.order - b.order || a.label < b.label);

export const timeIntervalOptions = Object.keys(BUDGET_TIME_INTERVALS)
  .map(key => ({
    value: BUDGET_TIME_INTERVALS[key],
    key: messages[key],
  }))
  .sort((a, b) => a.order - b.order || a.label < b.label);

export const modalModifiers = ['narrow'];
export const inputModifiers = ['modal'];

export const getAggregationOptions = intl =>
  AGGREGATION_OPTIONS_VALUES.map(option => ({
    value: option,
    key: intl.formatMessage(aggregationMessages[option]),
  }));
