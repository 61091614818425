import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { ADD_POSITION_SHIFT_MODAL } from '@/constants/modalTypes.js';
import DropArea from '@/containers/scheduler/DropAreaContainer.jsx';
import { checkIfContractHasJobTitle, getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { mapTimestampsToShift } from '@/utils/dateHelper.js';
import { checkShiftOverlap } from '@/utils/schedulerHelpers.js';

const messages = defineMessages({
  error: {
    id: 'common.error',
    defaultMessage: 'Błąd',
  },
  addShiftErrorBusy: { id: 'error.addShift.busy', defaultMessage: 'Pracownik ma w tym czasie już inną zmiane' },
});

class PositionDropArea extends Component {
  constructor(props, context) {
    super(props, context);
    this.cutDrop = this.cutDrop.bind(this);
    this.pasteDrop = this.pasteDrop.bind(this);
    this.checkIfShow = this.checkIfShow.bind(this);
  }

  cutDrop(e) {
    const shift = JSON.parse(e.dataTransfer.getData('shift'));
    const employee = this.props.userEmployees.find(emp => emp.id === shift.employee.id);
    if (checkShiftOverlap(employee, mapTimestampsToShift({ ...shift, date: this.props.date }), [shift.id])) {
      toastr.error(
        this.context.intl.formatMessage(messages.error, {}),
        this.context.intl.formatMessage(messages.addShiftErrorBusy, {}),
        {},
      );
      return;
    }
    this.props.deleteShiftRequest(shift.employee.id, shift.id, false).then(() => {
      this.pasteShift(shift);
    });
  }

  pasteShift(s) {
    const { date, jobTitleID, contracts } = this.props;
    let shift = s;
    const employee = this.props.userEmployees.find(emp => emp.id === shift.employee.id);

    const employeeContracts = contracts[employee.id] || [];
    const relevantContract = getRelevantContractForDate(employeeContracts, date);
    const hasJobTitle = checkIfContractHasJobTitle(relevantContract, jobTitleID);

    if (hasJobTitle) {
      const jobTitle = this.props.userJobTitles.find(jobtitle => jobtitle.id === jobTitleID);
      shift = Object.assign({}, shift, { date, draft: true, job_title: jobTitle });
      this.props.addShift(employee, shift, true);
    } else {
      this.props.showModal(ADD_POSITION_SHIFT_MODAL, {
        rowData: this.props.rowData,
        date,
      });
    }
  }

  pasteDrop(e) {
    const shift = JSON.parse(e.dataTransfer.getData('shift'));
    this.pasteShift(shift);
  }

  checkIfShow(tags) {
    let locationId = tags.find(tag => tag.includes('location'));
    let employeeId = tags.find(tag => tag.includes('employee'));
    locationId = locationId ? locationId.split(':')[1] : '';
    employeeId = employeeId ? employeeId.split(':')[1] : '';
    let employeeJobTitlesId = [];
    const employee = this.props.userEmployees.find(e => e.id === employeeId);
    if (employee) {
      const employeeContracts = this.props.contracts[employee.id] || [];
      const relevantContract = getRelevantContractForDate(employeeContracts, this.props.date);
      employeeJobTitlesId = getJobTitleIdsFromContracts([relevantContract]);
    }
    return locationId === this.props.locationId && employeeJobTitlesId.includes(this.props.jobTitleID);
  }

  render() {
    return (
      <DropArea
        cut={this.cutDrop}
        paste={this.pasteDrop}
        checkIfShow={this.checkIfShow}
        draggable={this.props.draggable}
      >
        {this.props.children}
      </DropArea>
    );
  }
}

PositionDropArea.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

PositionDropArea.propTypes = {
  deleteShiftRequest: PropTypes.func,
  addShift: PropTypes.func,
  showModal: PropTypes.func,
  date: PropTypes.string,
  rowData: PropTypes.shape({}),
  jobTitleID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userEmployees: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  userJobTitles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  children: PropTypes.node,
  draggable: PropTypes.bool,
};

export default PositionDropArea;
