import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Basic/Button.jsx';

// TODO: Now it can upload only 1 file. Mabe add option for multiple files

class FileDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      error: null,
    };
    this.chooseFile = this.chooseFile.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.confirmFile = this.confirmFile.bind(this);

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  onDragEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  onDrop(e) {
    e.stopPropagation();
    e.preventDefault();
    const { files } = e.dataTransfer;
    this.handleFiles(files);
  }
  chooseFile() {
    this.fileInput.click();
  }
  showError(error) {
    this.setState({ error });
    setTimeout(() => {
      this.setState({ error: null });
    }, 2000);
  }
  handleFiles(files) {
    const file = files[0];
    if (file) {
      let extension = file.name.split('.');
      extension = extension[extension.length - 1];
      if (this.props.maxSize && file.size > this.props.maxSize * 1000000) {
        console.error('File size is too big');
        this.showError(
          <FormattedMessage
            id="fileDrop.fileSizeToBig"
            defaultMessage="Plik jest zbyt duży ({maxSize} MB max.)"
            values={{ maxSize: this.props.maxSize }}
          />,
        );
      } else if (!this.props.accept || this.props.accept.includes(extension)) {
        this.setState({ file });
      } else {
        this.showError(<FormattedMessage id="fileDrop.badFormat" defaultMessage="Nieobsługiwany typ pliku" />);

        console.error(`Bad file extension .${extension}`);
      }
    }
  }
  confirmFile() {
    if (this.props.onChange) this.props.onChange(this.state.file);
    this.clearFile();
  }
  fileChange(e) {
    this.handleFiles(e.target.files);
    if (this.props.onUpload) {
      this.props.onUpload(e.target.files);
    }
    e.target.value = null;
  }
  clearFile() {
    this.setState({ file: null });
  }
  render() {
    if (this.state.file) {
      return (
        <div className="k-fileDrop">
          <div className={classnames('k-fileDrop__file', { 'k-fileDrop__file--center': !this.props.confirmText })}>
            {this.state.file.name}{' '}
            <i role="presentation" className="material-icons" onClick={this.clearFile}>
              clear
            </i>
          </div>
          {this.props.confirmText && (
            <Button onClick={this.confirmFile} modifiers="reverse-blue small floatright">
              <i className="material-icons">reply</i>
              {this.props.confirmText}
            </Button>
          )}
        </div>
      );
    }
    let accept = '*';
    if (this.props.accept) {
      accept = `.${this.props.accept.join(', .')}`;
    }
    return (
      <div className="k-fileDrop" onDragEnter={this.onDragEnter} onDragOver={this.onDragOver} onDrop={this.onDrop}>
        <div className="k-fileDrop__text">
          <FormattedMessage id="fileDrop.dropFileHere" defaultMessage="Upuść plik tutaj" />
        </div>
        <div className="k-fileDrop__subText">
          <FormattedMessage id="common.or" defaultMessage="lub" />
        </div>
        <Button onClick={this.chooseFile} modifiers="reverse-blue small floatright">
          <i className="material-icons">folder_open</i>
          <FormattedMessage id="pickFile" defaultMessage="Wybierz plik" />
        </Button>
        <input
          type="file"
          ref={ref => {
            this.fileInput = ref;
          }}
          style={{ visibility: 'hidden' }}
          onChange={this.fileChange}
          accept={accept}
        />
        {this.props.maxSize ? (
          <div className="k-fileDrop__sizeInfo">
            {this.state.error ? (
              <span className="k-fileDrop__errorInfo">{this.state.error}</span>
            ) : (
              <FormattedMessage
                id="fileDrop.maxFileSize"
                defaultMessage="Maksymalny rozmiar: {maxSize} MB"
                values={{ maxSize: this.props.maxSize }}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
}
FileDrop.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string), // Accepted file extensions eg. ['jpg','png']
  confirmText: PropTypes.shape({}),
  onChange: PropTypes.func,
  maxSize: PropTypes.number,
  onUpload: PropTypes.func,
};
export default FileDrop;
