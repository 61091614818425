import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import { ScheduleTableBody } from './ScheduleTableBody/ScheduleTableBody';
import ScheduleTableContentBlocker from './ScheduleTableContentBlocker/ScheduleTableContentBlocker.redux';
import { ScheduleTableHead } from './ScheduleTableHead/ScheduleTableHead';
import { useScheduleTable } from './useScheduleTable';

import './ScheduleTable.scss';

const ScheduleTable = ({ locationId, height, shouldBeTableFocused }) => {
  const tableRef = useRef(null);
  const { isMonthlySchedule, isSingleDay } = useScheduleTable();

  useEffect(() => {
    if (shouldBeTableFocused) tableRef?.current?.focus();
  }, [shouldBeTableFocused, tableRef?.current]);

  const scheduleTableClassName = classnames('k-newScheduleTable', {
    'k-newScheduleTable--month': isMonthlySchedule,
  });

  const scheduleTableScrollClassName = classnames('k-newScheduleTable__scroll', {
    'k-newScheduleTable__scroll--hideScroll': isSingleDay,
  });

  return (
    <div className={scheduleTableClassName}>
      <div style={{ maxHeight: `${height}px` }} className={scheduleTableScrollClassName} tabIndex={0} ref={tableRef}>
        <div className="k-tableWrapper">
          <table>
            <ScheduleTableHead locationId={locationId} />
            <ScheduleTableBody locationId={locationId} />
          </table>
          <ScheduleTableContentBlocker locationId={locationId} />
        </div>
      </div>
    </div>
  );
};

ScheduleTable.propTypes = {
  locationId: PropTypes.string,
  height: PropTypes.number,
  shouldBeTableFocused: PropTypes.bool,
};

export default ScheduleTable;
