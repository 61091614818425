import { connect } from 'react-redux';

import { closeAddAttendanceModal, createAttendance } from '@/actions/attendances.js';
import AddAttendanceModal from '@/components/attendance/modals/AddAttendanceModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showAddAttendanceModal,
  modalObject: state.reducer.uiState.addAttendanceModalObject,
  labels: state.reducer.labels,
  companyRoles: state.reducer.roles.companyRoles,
  currentUser: state.reducer.currentUser,
  userPermissions: state.reducer.userPermissions,
  roles: state.reducer.roles,
});

const mapDispatchToProps = {
  onHide: closeAddAttendanceModal,
  createAttendance,
};
const AddAttendanceModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddAttendanceModal);

export default AddAttendanceModalContainer;
