import { connect } from 'react-redux';

import PayrollTableLabels from './PayrollTableLabels';
import { assignOrUnassignLabelsFromAttendance } from '@/actions/attendances';

const mapStateToProps = state => ({
  locationSettings: state.reducer.settings.locationSettings,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  assignOrUnassignLabelsFromAttendance,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTableLabels);
