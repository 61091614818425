import { connect } from 'react-redux';

import Component from './EmployeesEditMassEmplConditionsField.jsx';

const mapStateToProps = state => ({
  employmentConditions: state.reducer.employmentConditions,
});

const EmplyeesEditMassEmplConditions = connect(mapStateToProps)(Component);

export default EmplyeesEditMassEmplConditions;
