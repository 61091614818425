import { connect } from 'react-redux';

import { redirectToPayroll } from '@/actions/payroll/payroll';

import PayrollLocationTableOptions from './PayrollLocationTableOptions';

const mapStateToProps = state => ({
  showSkeletons: state.reducer.payrollLocation.showSkeletons,
});

const mapDispatchToProps = {
  redirectToPayroll,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLocationTableOptions);
