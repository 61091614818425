import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  workTime: {
    id: 'payroll.summary.workTime',
    defaultMessage: 'Suma godzin',
  },
  absenceTime: {
    id: 'payroll.summary.absenceTime',
    defaultMessage: 'Suma nieobecności',
  },
  overtime: {
    id: 'payroll.summary.overtime',
    defaultMessage: 'Nadgodziny dobowe',
  },
  payout: {
    id: 'payroll.summary.payout',
    defaultMessage: 'Suma wynagrodzeń',
  },
  bonuses: {
    id: 'payroll.summary.bonuses',
    defaultMessage: 'Suma bonusów',
  },
  nightWorkTime: {
    id: 'payroll.summary.nightWOrkTime',
    defaultMessage: 'Godziny nocne',
  },
  modalTitle: {
    id: 'payroll.summary.modalTitle',
    defaultMessage: 'Dostosuj podsumowanie',
  },
  selectTitle: {
    id: 'payroll.summary.selectTitle',
    defaultMessage: 'Wybierz metryki do wyświetlenia w podsumowaniu',
  },
});

export const errorMessages = defineMessages({
  tooMuchItemsSelected: {
    id: 'payroll.summary.tooManyItemsSelected',
    defaultMessage: 'Wybierz maksymalnie {count} metryki do wyświetlenia w podsumowaniu.',
  },
  tooLittleItemsSelected: {
    id: 'payroll.summary.tooLittleItemsSelected',
    defaultMessage: 'Wybierz minimalnie {count} metryki do wyświetlenia w podsumowaniu.',
  },
});
