import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  budgetMetricsTableRowOptionsEditIcon: {
    id: 'budgetMetricsTableRowOptions.editIcon',
    defaultMessage: 'Edytuj',
  },
  budgetMetricsTableRowOptionsDeleteIcon: {
    id: 'budgetMetricsTableRowOptions.deleteIcon',
    defaultMessage: 'Usuń',
  },
});