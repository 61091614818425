import { HIRE_DATE, HOURLY_WAGE_KEY, RELEASE_DATE } from '@/constants/tableColumns';

export const filterByKey = (data, isBudgetInfoPermission, isHireReleaseDataShowPermission) => {
  const hireAndReleaseKeys = [HIRE_DATE, RELEASE_DATE];
  return data.filter(
    ({ key }) =>
      (isBudgetInfoPermission || key !== HOURLY_WAGE_KEY) &&
      (isHireReleaseDataShowPermission || !hireAndReleaseKeys.includes(key)),
  );
};

export const filterColumns = (
  visibleColumns,
  employeesTable,
  isBudgetInfoPermission,
  isHireReleaseDataShowPermission,
) => {
  const filteredVisibleColumns = filterByKey(visibleColumns, isBudgetInfoPermission, isHireReleaseDataShowPermission);

  const filteredEmployeesTableHeader = filterByKey(
    employeesTable.headers,
    isBudgetInfoPermission,
    isHireReleaseDataShowPermission,
  );

  return { filteredVisibleColumns, filteredEmployeesTableHeader };
};
