import { connect } from 'react-redux';

import EmployeePropListLocations from './EmployeePropListLocations.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const EmployeePropListLocationsContainer = connect(mapStateToProps)(EmployeePropListLocations);

export default EmployeePropListLocationsContainer;
