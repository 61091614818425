import { connect } from 'react-redux';

import { toggleBudgetOption } from '@/actions/schedule/budget.js';

import ScheduleBudgetTable from './ScheduleBudgetTable.jsx';

const mapStateToProps = state => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  permissions: state.reducer.userPermissions.permissions,
  restrictions: state.reducer.userPermissions.restrictions,
  selectedOptions: state.reducer.schedule.budget.selectedOptions,
});

const mapDispatchToProps = {
  toggleBudgetOption,
};

const ScheduleBudgetTableContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleBudgetTable);

export default ScheduleBudgetTableContainer;
