import { sortByKey } from '@/utils/arrayHelpers';

export const Y_OFFSET = -16;

export const SORT_KEY = 'key';

const SORT_DECREASE_OPTIONS = { decrease: true };

export const getOptions = (options, withSearch, isSorted, filter) => {
  let result = options;
  if (withSearch) {
    result = result.filter(({ key }) => key.toLowerCase().includes(filter.toLowerCase()));
  }
  if (isSorted) {
    result = sortByKey(result, SORT_KEY, false, SORT_DECREASE_OPTIONS);
  }
  return result;
};
