import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MDCheckbox,
  MDMultiSelect,
  MDMultiSelectFooter,
  MDMultiSelectHeader,
  MDTextInput,
} from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import { formatLoginMethods } from '@/utils/devices';
import { validateInput as validateInputHelper } from '@/utils/inputHelpers';

import { getInitialState, getSelectedLocations } from './AddDeviceModalHelper';
import { messages } from './DevicesModal.messages';

import './AddDeviceModal.scss';
import DeviceLoginMethods from './DeviceLoginMethods';

class AddDeviceModal extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = getInitialState();

    bindPrototypeFunctions(this);
  }

  onSubmit() {
    return this.validateAll().then((valid) => {
      if (!valid) return;
      const {
        deviceName,
        authCode,
        managerGroupLogin,
        takePhotos,
        selectedLocations,
        autologin,
        autologinTime,
        os,
      } = this.state;
      const { loginMethodPin, loginMethodQr, loginMethodRfid, loginMethodNfc } =
        this.state;

      const payload = {
        name: deviceName,
        locations: selectedLocations,
        manager_group_login: managerGroupLogin,
        take_photos: takePhotos,
        autologin,
        autologin_time: parseInt(autologinTime),
        auth_code: authCode.replace(/ /g, ''),
        login_methods: formatLoginMethods(
          { loginMethodPin, loginMethodQr, loginMethodRfid, loginMethodNfc },
          os
        ),
      };
      this.props.addDevice(payload);
      this.hideAndClear();
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (this.state.errors[name]) {
      this.validateInput(event);
    }
    this.setState({
      [name]: value,
    });
  }

  async handleAuthCodeBlur(event) {
    this.validateInput(event);
    const { value } = event.target;
    try {
      const { data } = await this.props.checkDevice(value);
      const { os } = data;
      this.setState({ os });
      this.setState({ errors: { ...this.state.errors, deviceNotFound: '' } });
    } catch (e) {
      this.setState({ os: null });
      this.setState({
        errors: {
          ...this.state.errors,
          deviceNotFound: this.context.intl.formatMessage(
            messages.deviceNotFoundByAuthCode
          ),
        },
      });
    }
  }

  validateInput(event) {
    validateInputHelper(event).then((res) => {
      const [name, error] = res;
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: error ? this.context.intl.formatMessage(error, {}) : error,
        },
      });
    });
  }

  checkIfAtLeastOneLocationIsSelected() {
    if (!this.state.selectedLocations.length) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          selectedLocations: this.context.intl.formatMessage(
            messages.noLocationsSelectedError,
            {}
          ),
        },
      }));
      return false;
    }
    if (this.state.errors.selectedLocations) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, selectedLocations: '' },
      }));
    }
    return true;
  }

  async validateAll() {
    const inputs = ['deviceName', 'authCode'];
    const validationRequests = [];
    for (const i of inputs) {
      validationRequests.push(
        this.validateInput({ target: { name: i, value: this.state[i] } })
      );
    }

    await Promise.all(validationRequests);
    const atLeastOneLocationIsSelected =
      this.checkIfAtLeastOneLocationIsSelected();
    return (
      !Object.values(this.state.errors).some((err) => err !== '') &&
      atLeastOneLocationIsSelected
    );
  }

  hideAndClear() {
    this.setState(getInitialState());
    this.props.hideModal();
  }

  changeSelected(selected) {
    this.setState({
      selectedLocations: Array.from(selected).map((item) => item.value),
    });
  }

  handleChangeList(e) {
    const selectedLocationId = e.value;
    const newLocations = this.isLocationSelected(selectedLocationId)
      ? this.state.selectedLocations.filter((id) => id !== selectedLocationId)
      : [...this.state.selectedLocations, selectedLocationId];
    this.setState({ selectedLocations: newLocations }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  isLocationSelected(locationId) {
    return this.state.selectedLocations.includes(locationId);
  }

  selectAll() {
    const newLocations = this.props.userLocations.map(({ id }) => id);
    this.setState({ selectedLocations: newLocations }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  deselectAll() {
    this.setState({ selectedLocations: [] }, () => {
      this.checkIfAtLeastOneLocationIsSelected();
    });
  }

  render() {
    const {
      managerGroupLogin,
      takePhotos,
      deviceName,
      errors,
      authCode,
      selectedLocations,
      autologin,
      autologinTime,
      os,
    } = this.state;
    const { loginMethodNfc, loginMethodRfid, loginMethodPin, loginMethodQr } =
      this.state;
    const userLocationsOptions = this.props.userLocations.map((location) => ({
      value: location.id,
      label: location.name,
      active: this.isLocationSelected(location.id),
    }));

    return (
      <MDKadroModal
        errorMessage={errors.loginMethodsError}
        show={this.props.showModal}
        onHide={this.hideAndClear}
        title={this.context.intl.formatMessage(messages.addDevice)}
        modifiers={['narrow']}
        onSubmit={this.onSubmit}
      >
        <MDTextInput
          value={deviceName}
          onChange={this.handleInputChange}
          onBlur={this.validateInput}
          type="text"
          id="deviceName"
          label={this.context.intl.formatMessage(messages.deviceName)}
          errorMessage={errors.deviceName}
          modifiers={['modal']}
        />
        <MDTextInput
          value={authCode}
          type="text"
          onChange={this.handleInputChange}
          onBlur={this.handleAuthCodeBlur}
          id="authCode"
          label={this.context.intl.formatMessage(messages.authCode)}
          errorMessage={errors.authCode || errors.deviceNotFound}
          modifiers={['modal']}
          tooltip={
            <FormattedMessage
              {...messages.authCodeTooltip}
              values={{
                clickHere: (
                  <a
                    href="https://pomoc.kadromierz.pl/pl/articles/4167801-autoryzacja-urzadzenia-z-rcp"
                    target="__blank"
                  >
                    {this.context.intl.formatMessage(messages.clickHere)}
                  </a>
                ),
              }}
            />
          }
        />
        <MDMultiSelectHeader
          icon="location_on"
          title={this.context.intl.formatMessage(messages.locationsTitle)}
          selectAll={this.selectAll}
          deselectAll={this.deselectAll}
        />
        <MDMultiSelect
          label={this.context.intl.formatMessage(messages.chooseLocation)}
          options={userLocationsOptions}
          onChange={this.handleChangeList}
        />
        <MDMultiSelectFooter
          selectedItems={getSelectedLocations(
            this.props.userLocations,
            selectedLocations
          )}
          deleteItem={this.handleChangeList}
          errorMessage={errors.selectedLocations}
        />
        <DeviceLoginMethods
          {...{
            os,
            loginMethodNfc,
            loginMethodRfid,
            loginMethodPin,
            loginMethodQr,
          }}
          onChange={this.handleInputChange}
        />

        <div className="addDeviceModal__divider" />
        <div className="addDeviceModal__subtitle">
          <FormattedMessage
            id="companymanage.devices.additionalOptionsTitle"
            defaultMessage="Dodatkowe opcje"
          />
        </div>
        <MDCheckbox
          id="takePhotos"
          text={
            <FormattedMessage
              id="companymanage.devices.takePhotos"
              defaultMessage="Zdjęcia"
            />
          }
          value={takePhotos}
          onChange={this.handleInputChange}
          tooltip={this.context.intl.formatMessage(messages.takePhotosTooltip)}
          reverse
        />
        <MDCheckbox
          id="managerGroupLogin"
          text={
            <FormattedMessage
              id="companymanage.devices.managerGroupLogin"
              defaultMessage="Grupowe logowanie"
            />
          }
          value={managerGroupLogin}
          onChange={this.handleInputChange}
          tooltip={this.context.intl.formatMessage(
            messages.managerGroupLoginTooltip
          )}
          reverse
        />
        <MDCheckbox
          id="autologin"
          text={this.context.intl.formatMessage(messages.autologin)}
          value={autologin}
          onChange={this.handleInputChange}
          tooltip={this.context.intl.formatMessage(messages.autologinTooltip)}
          reverse
        />
        {autologin && (
          <MDTextInput
            label={this.context.intl.formatMessage(messages.autologinTimeLabel)}
            value={autologinTime}
            onChange={this.handleInputChange}
            type="number"
            disabled={!autologin}
            minValue={0}
            maxValue={5}
            id="autologinTime"
            modifiers="modal"
          />
        )}
      </MDKadroModal>
    );
  }
}

AddDeviceModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddDeviceModal.propTypes = {
  showModal: PropTypes.bool,
  hideModal: PropTypes.func,
  addDevice: PropTypes.func,
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AddDeviceModal;
