import PropTypes from 'prop-types';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';

import './AvaCorner.scss';

const AvaCorner = ({ popover, cornerColor }) => {
  const formattedPopover = {
    title: popover.title,
    content: popover.content.map((message, idx) => <div key={idx}>{message}</div>),
  };

  return (
    <div>
      <HoverPopOver popover={formattedPopover}>
        <div className="k-scheduleAvaCorner" style={{ borderBottomColor: cornerColor || 'red' }} />
      </HoverPopOver>
    </div>
  );
};

AvaCorner.propTypes = {
  cornerColor: PropTypes.string,
  popover: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default AvaCorner;
