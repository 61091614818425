import { connect } from 'react-redux';

import { choosePayrollSummaryValues } from '@/actions/payroll/payroll.js';
import { hideModal } from '@/actions/uiState.js';
import ChoosePayrollSummaryValuesModal from '@/components/newPayrollViews/ChoosePayrollSummaryValuesModal/ChoosePayrollSummaryValuesModal.tsx';
import { CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL } from '@/constants/modalTypes.js';
import { State } from '@/types';

const mapStateToProps = ({ reducer }: State) => ({
  isVisible: reducer.uiState.showModal === CHOOSE_PAYROLL_SUMMARY_VALUES_MODAL,
  summaryValues: reducer.payroll.summaryValues,
  restrictions: reducer.userPermissions.restrictions,
});

const mapDispatchToProps = {
  hideModal,
  choosePayrollSummaryValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePayrollSummaryValuesModal);
