import { connect } from 'react-redux';

import { toggleHelpModal } from '@/actions/uiState.js';
import ScheduleHelpModal from '@/components/scheduler/modals/ScheduleHelpModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showHelpModal,
});

const mapDispatchToProps = {
  onHide: toggleHelpModal,
};
const ScheduleHelpModalContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleHelpModal);

export default ScheduleHelpModalContainer;
