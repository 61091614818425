import {
  calculateDurationBetweenTimestamps,
  convertDateToStandardFormat,
  timestampsToWorkingHours,
} from '@/utils/dateHelper';

export const getStylesForOvertimeCollectionBlock = (overtimeCollection, dayHasAtLeastOneShift) => {
  const date = convertDateToStandardFormat(overtimeCollection.start_timestamp);
  const top = dayHasAtLeastOneShift > 0 ? '50px' : 0;
  const hours = timestampsToWorkingHours(overtimeCollection.start_timestamp, overtimeCollection.end_timestamp);
  const [start, end] = hours.split('-');
  const left = `${(start.split(':')[0] * 60 + 1 * start.split(':')[1]) / 14.4}%`;
  const startTimestamp = `${date} ${start}`;
  const endTimestamp = end < start ? `${date} 23:59` : `${date} ${end}`;
  const width = calculateDurationBetweenTimestamps(endTimestamp, startTimestamp) / 14.4;
  return { top, left, width };
};
