import { CurrentUser } from '@/types';
import { UserJobTitle } from '@/types/jobTitles.types';
import { LoggedAccount } from '@/utils/accounts/accounts.utils';

export type AccountDetail = {
  avatarSrc: CurrentUser['user']['avatar']['medium'];
  email: CurrentUser['user']['email'];
  isAccountActive: LoggedAccount['active'];
  isDefault: LoggedAccount['isDefault'];
  jobTitlesNames: UserJobTitle['title'][];
  locationsNames: CurrentUser['user']['locations'][number]['name'][];
  roleName: string;
  userId: CurrentUser['user']['id'];
};

const formatAccountDetail = (account: LoggedAccount): AccountDetail => ({
  avatarSrc: account.avatar,
  email: account.email,
  isAccountActive: account.active,
  isDefault: account.isDefault,
  jobTitlesNames: account.jobTitleNames.sort(),
  locationsNames: account.locationNames.sort(),
  roleName: account.roleName,
  userId: account.userId,
});

const formatAccountDetails = (accounts: LoggedAccount[]) => accounts.map((account) => formatAccountDetail(account));

export const getSortedUsersAccountsDetails = (accounts: LoggedAccount[]) =>
  formatAccountDetails(accounts).sort((a, b) => {
    if (a.isDefault !== b.isDefault) return a.isDefault ? -1 : 1;
    const locationComparison = a.locationsNames[0]?.localeCompare(b.locationsNames[0]);
    if (locationComparison !== 0) return locationComparison;
    return a.jobTitlesNames[0]?.localeCompare(b.jobTitlesNames[0]);
  });

const minAmountOfAccountsWithScroll = 4;
const instanceHeight = 82;
const hoveredInstanceDiff = 20;

const getHeightForSpaceContainer = (accountsAmount: number) => {
  const paddings = 50;
  const settingHeight = 50;
  const switcherHeight = 85;
  return `${
    instanceHeight * Math.min(...[accountsAmount, minAmountOfAccountsWithScroll]) +
    switcherHeight +
    settingHeight +
    paddings +
    hoveredInstanceDiff
  }px`;
};

const getHeightForProfilesList = (accountsAmount: number) => {
  const paddings = 5;
  if (accountsAmount >= minAmountOfAccountsWithScroll) return 'auto';
  return `${instanceHeight * accountsAmount + paddings + hoveredInstanceDiff}px`;
};

export const setContainersHeight = (accountsAmount: number, height?: string) => {
  document.documentElement.style.setProperty(
    '--sidebar-space-container-height',
    height || getHeightForSpaceContainer(accountsAmount),
  );
  document.documentElement.style.setProperty(
    '--sidebar-profiles-list-container-height',
    getHeightForProfilesList(accountsAmount),
  );
};
