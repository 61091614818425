import { produce } from 'immer';

import { GET_GOOGLE_OAUTH_USER_DATA } from '@/constants/ActionTypes';
import { GoogleOAuthUserData } from '@/types/googleOAuth';

import { GetGoogelOAuthUserDataAction } from '../types/actions/googleOAuth';

type Action = GetGoogelOAuthUserDataAction;

const initialState: GoogleOAuthUserData = {
  email: '',
  firstName: '',
  lastName: '',
  avatar: '',
  locale: '',
};

const googleOAuth = (state = initialState, action: Action) =>
  produce(state, () => {
    switch (action.type) {
      case GET_GOOGLE_OAUTH_USER_DATA: {
        return action.payload;
      }
      default:
        return state;
    }
  });

export default googleOAuth;
