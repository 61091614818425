import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import Button from '@/components/common/Basic/Button';
import ButtonWithDropdownWrapper from '@/components/common/Basic/ButtonWithDropdown/ButtonWithDropdownWrapper';
import Icon from '@/components/common/Basic/Icon/Icon';
import SortingArrow from '@/components/common/Basic/SortingArrow';
import { FreemiumStar, FreemiumWrapper } from '@/components/common/Freemium';
import Popover from '@/components/common/Popover/Popover';
import { checkArrayIntersection } from '@/utils/arrayHelpers';

import './ButtonWithDropdown.scss';

const ButtonWithDropdown = ({
  options,
  xOffset,
  arrowPosition,
  color,
  disabled,
  tooltipContent,
  showTooltip,
  restrictions = [],
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  const dropDownRef = useRef(null);
  const buttonClassName = classNames('k-buttonWithDropdown__button');
  const arrowButtonClassName = classNames('k-buttonWithDropdown__arrow', {
    'k-buttonWithDropdown__arrow--active': showOptions,
    'k-buttonWithDropdown__arrow--orange': color === 'orange',
    'k-buttonWithDropdown__arrow--disabled': disabled,
  });
  const buttonModifiers = `${color || 'blue'} medium spaceBetween`;

  const onOptionClick = onClick => {
    onClick();
    setShowOptions(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target) && !dropDownRef.current?.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <ButtonWithDropdownWrapper showTooltip={showTooltip} tooltipContent={tooltipContent}>
      <div className="k-buttonWithDropdown" ref={ref}>
        <Button
          className={buttonClassName}
          onClick={options[0].onClick}
          modifiers={buttonModifiers}
          disabled={disabled}
        >
          {options[0].icon && <Icon name={options[0].icon} />}
          <span>{options[0].title}</span>
        </Button>
        <div>
          <button onClick={() => setShowOptions(!showOptions)} className={arrowButtonClassName} disabled={disabled}>
            <SortingArrow display up={false} />
          </button>
          {showOptions && (
            <Popover
              position="bottom"
              showPopover={showOptions}
              showShadow={false}
              xOffset={xOffset || undefined}
              autoSideOffset
              hideOnClick
              arrowPosition={arrowPosition || undefined}
              popoverStyle={{ padding: 0 }}
              content={
                <div className="k-buttonWithDropdownList" ref={dropDownRef}>
                  {options.map(option => (
                    <FreemiumWrapper
                      freemiumRestrictions={option.freemiumRestrictions}
                      intercomEvent={option.freemiumIntercomEvent}
                      key={option.title}
                    >
                      <button
                        className="k-buttonWithDropdownList__button"
                        onClick={() => onOptionClick(option.onClick)}
                      >
                        {option.icon && <Icon name={option.icon} />}
                        <span>{option.title}</span>
                        {option.freemiumRestrictions &&
                          checkArrayIntersection(restrictions, option.freemiumRestrictions) && <FreemiumStar />}
                      </button>
                    </FreemiumWrapper>
                  ))}
                </div>
              }
            >
              <div />
            </Popover>
          )}
        </div>
      </div>
    </ButtonWithDropdownWrapper>
  );
};

ButtonWithDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  xOffset: PropTypes.number,
  arrowPosition: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  restrictions: PropTypes.arrayOf(PropTypes.string),
};

export default ButtonWithDropdown;
