import { deburr, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import EmployeePropListLocations from './EmployeePropListLocations.redux.js';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import '../../EmployeeModalPropList.scss';

const EmployeeModalPropList = props => {
  const [selectedLocations, setSelectedLocations] = useState(props.editLocations);
  const [supplementaryLocations, setSupplementaryLocations] = useState(props.supplementaryLocations);

  const onChangeLocation = e => {
    if (!e.active) {
      addLocation(e);
    } else {
      deleteLocation(e.value);
    }
  };

  const addLocation = (e, option = { all: false }) => {
    const newLocations = option.all ? props.locations : props.locations.find(location => location.id === e.value);
    const updatedSelectedLocations = option.all ? newLocations : [...selectedLocations, newLocations];
    setSelectedLocations(updatedSelectedLocations);
    props.onChangeFunc({
      target: {
        name: 'locations',
        value: updatedSelectedLocations,
      },
    });
  };

  const deleteLocation = (id, option = { all: false }) => {
    const updatedSelectedLocations = option.all ? [] : selectedLocations.filter(location => location.id !== id);
    setSelectedLocations(updatedSelectedLocations);
    props.onChangeFunc({
      target: {
        name: 'locations',
        value: updatedSelectedLocations,
      },
    });
  };

  const toggleLocationAsSupplementary = id => {
    let newSupplementaryLocations = [];
    const parsedId = parseInt(id);
    if (supplementaryLocations.includes(parsedId)) {
      newSupplementaryLocations = supplementaryLocations.filter(i => i !== parsedId);
    } else {
      newSupplementaryLocations = [...supplementaryLocations, parsedId];
    }
    setSupplementaryLocations(newSupplementaryLocations);
    props.onChangeFunc({
      target: {
        name: 'supplementaryLocations',
        value: newSupplementaryLocations,
      },
    });
  };

  const mappedLocations = props.locations.map(location => ({
    value: location.id,
    label: location.name,
    active: selectedLocations.some(l => location.id === l.id),
  }));
  const sortedLocations = sortBy([...mappedLocations], location => deburr(location.label.toLowerCase()));
  const sortedSelectedLocations = sortBy([...selectedLocations], location => deburr(location.name.toLowerCase()));

  return (
    <div className="employeeModal__propList">
      <EmployeePropListLocations
        isManager={props.manager}
        locations={sortedLocations}
        selectedLocations={sortedSelectedLocations}
        showSupplementaryLocations={props.showSupplementaryLocations}
        supplementaryLocations={supplementaryLocations}
        addLocation={addLocation}
        deleteLocation={deleteLocation}
        onChangeLocation={onChangeLocation}
        toggleLocationAsSupplementary={toggleLocationAsSupplementary}
      />
    </div>
  );
};

EmployeeModalPropList.propTypes = {
  editLocations: PropTypes.arrayOf(PropTypes.shape({})),
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeFunc: PropTypes.func,
  supplementaryLocations: PropTypes.arrayOf(PropTypes.shape({})),
  manager: PropTypes.bool,
  showSupplementaryLocations: PropTypes.bool,
};

export default EmployeeModalPropList;
