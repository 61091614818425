import PropTypes from 'prop-types';

import Tile from './Tile.redux';

import './Tiles.scss';

const Tiles = ({ items, sections }) => (
  <div className="tiles">
    {items.map(item => (
      <div key={item.name}>
        <Tile sections={sections} {...item} />
      </div>
    ))}
  </div>
);

Tiles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
};

export default Tiles;
