import { PropTypes } from 'prop-types';
import { Component } from 'react';

import { Grid, Header,Rows } from '@/components/common/Grid/Grid.jsx';
import { payrollTableColumns } from '@/constants/tableColumns.jsx';

class PayrollTable extends Component {
  constructor(props) {
    super(props);
    this.width = 1600;
  }
  render() {
    const rowsColgroup = (
      <colgroup>
        <col style={{ width: '100%' }} />
      </colgroup>
    );
    return (
      <div style={{ clear: 'both' }}>
        <Grid
          scrollCallback={this.scrollSummary}
          height={9999}
          width={this.width}
          static
          columnStyle={{ width: '20vw', minWidth: '150px' }}
          columnGroup={
            <colgroup>
              {payrollTableColumns.colgroup.map((width, i) => {
                if (this.props.visibleColumns.includes(i)) return <col key={i} style={{ width: `${width}%` }} />;
                return null;
              })}
            </colgroup>
          }
        >
          <Header disabledEditOverlayStyle={this.props.disabledEditOverlayStyle}>
            <thead>
              <tr>
                {payrollTableColumns.headers.map((header, i) => {
                  if (this.props.visibleColumns.includes(i)) {
                    return <th key={i}>{this.context.intl.formatMessage(header, {})}</th>;
                  }

                  return null;
                })}
              </tr>
            </thead>
          </Header>
          <Rows columnGroup={rowsColgroup} className="table payroll">
            {this.props.children}
          </Rows>
        </Grid>
      </div>
    );
  }
}

PayrollTable.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollTable.propTypes = {
  children: PropTypes.node,
  visibleColumns: PropTypes.arrayOf(PropTypes.number),
  disabledEditOverlayStyle: PropTypes.shape({}),
};
export default PayrollTable;
