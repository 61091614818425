import PropTypes from 'prop-types';
import { cloneElement } from 'react';

const OptionsColumnCell = ({ row, options }) => <>{cloneElement(options, { rowData: row.original })}</>;

OptionsColumnCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      disabledCheckbox: PropTypes.bool,
    }),
    getToggleRowSelectedProps: PropTypes.func,
  }),
  options: PropTypes.node,
};

export default OptionsColumnCell;
