import { ElementRef, RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref: RefObject<ElementRef<'div'>>) => {
  const [isOverflowX, setIsOverflowX] = useState(false);
  const [isOverflowY, setIsOverflowY] = useState(false);

  useLayoutEffect(() => {
    const { current } = ref;
    const trigger = () => {
      setIsOverflowX(current.scrollWidth > current.clientWidth);
      setIsOverflowY(current.scrollHeight > current.clientHeight);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [ref]);

  return { isOverflowX, isOverflowY };
};
