import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { GREEN, KADRO_BLUE_COLOR } from '@/constants/colors';
import { CUSTOM_NOTIFICATION_MODAL } from '@/constants/modalTypes.js';

import './DashboardTeamItem.scss';

const DashboardTeamItem = ({ attendance, showModal, setSelectedEmployeeId }) => {
  const avatar = attendance.employee_avatar?.small;
  const initials = `${attendance.employee_first_name.charAt(0)} ${attendance.employee_last_name.charAt(
    0,
  )}`.toUpperCase();
  const statusColor = attendance.status === 'onBreak' ? KADRO_BLUE_COLOR : GREEN;

  return (
    <div className="md-dashboardTeamItem">
      <div className="md-dashboardTeamItem__user">
        <div className="md-dashboardTeamItem__avatar">
          {avatar && avatar !== 'https://static.kadromierz.pl/avatars/placeholder-avatar-medium.png' ? (
            <img src={avatar} alt="avatar" />
          ) : (
            <span className="initials">{initials}</span>
          )}
        </div>
        <div className="md-dashboardTeamItem__info md-dashboardTeamItem__text">
          <p className="md-dashboardTeamItem__userName">{attendance.fullName}</p>
          <p className="md-dashboardTeamItem__jobTitle">{attendance.job_title?.title}</p>
        </div>
      </div>
      <div className="md-dashboardTeamItem__text">{attendance.entry}</div>
      <div className="md-dashboardTeamItem__text">
        <span className="md-dashboardTeamItem__statusDot" style={{ background: statusColor }} />
        {attendance.statusText}
      </div>
      <button
        className="md-dashboard__link md-dashboardTeamItem__link"
        onClick={() => {
          setSelectedEmployeeId(attendance.employee_id);
          showModal(CUSTOM_NOTIFICATION_MODAL);
        }}
      >
        <FormattedMessage id="dashboard.todayTeam.sendMessage" defaultMessage="Wyślij wiadomość" />
      </button>
    </div>
  );
};

DashboardTeamItem.propTypes = {
  attendance: PropTypes.shape({
    start_timestamp: PropTypes.string,
    employee_first_name: PropTypes.string,
    employee_last_name: PropTypes.string,
    employee_id: PropTypes.string,
    employee_avatar_url: PropTypes.string,
    job_title: PropTypes.shape({
      title: PropTypes.string,
    }),
    employee_avatar: PropTypes.shape({
      small: PropTypes.string,
    }),
    fullName: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
    entry: PropTypes.string,
  }),
  showModal: PropTypes.func,
  setSelectedEmployeeId: PropTypes.func,
};

export default DashboardTeamItem;
