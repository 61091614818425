import { connect } from 'react-redux';

import ScheduleBudgetTableHeaderCell from './ScheduleBudgetTableHeaderCell.jsx';

const mapStateToProps = (state, ownProps) => ({
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode.type,
  showHolidaysSetting: state.reducer.schedule.viewSettings.holidays.value,
  today: state.reducer.mainDateStore.today,
  sumEmployees: state.reducer.schedule.budget.shiftsSummary[ownProps.date]?.employees.length,
  sumHours: state.reducer.schedule.budget.shiftsSummary[ownProps.date]?.totalMinutes,
  sumCosts: state.reducer.schedule.budget.shiftsSummary[ownProps.date]?.totalCosts,
  currentTemplateType: state.reducer.userTemplates.currentTemplate.type,
  dateArray: state.reducer.mainDateStore.dateArray,
  dayType: state.reducer.schedule.dayTypes[ownProps.date],
});

export default connect(mapStateToProps)(ScheduleBudgetTableHeaderCell);
