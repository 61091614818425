import PropTypes from 'prop-types';

const KadroFeatureWrapper = props => {
  const { permission, permissions, restriction, restrictions, hide, userPermissions, children } = props;
  if (permission && !userPermissions.permissions.includes(permission)) {
    return null;
  }
  if (permissions && !permissions.some(perm => userPermissions.permissions.includes(perm))) {
    return null;
  }
  if (restriction && userPermissions.restrictions.includes(restriction)) {
    return null;
  }
  if (restrictions && restrictions.some(restr => userPermissions.restrictions.includes(restr))) {
    return null;
  }
  if (hide) {
    return null;
  }
  return children;
};

KadroFeatureWrapper.propTypes = {
  userPermissions: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string),
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  permission: PropTypes.string,
  restriction: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  hide: PropTypes.bool,
  children: PropTypes.node,
};

export default KadroFeatureWrapper;
