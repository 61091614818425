/* eslint no-shadow: 1 */
/* eslint no-console: 0 */
/* eslint no-unused-vars:1 */
/* eslint react/jsx-filename-extension:1 */

import { defineMessages, FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import { trackIntercomEvent } from '@/actions/intercom';
import { emplMassEditActions } from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import {
  addLocations,
  deleteLocations,
} from '@/components/companymanage/employees/EditMassEmployeesModal/employeesEditMassHelpers.ts';
import * as actionTypes from '@/constants/ActionTypes.js';
import {
  ADD_NEW_EMPLOYEE,
  ADD_NEW_LOCATION,
  DELETE_EMPLOYEE,
  INVITE_EMPLOYEES_SUCCESS,
} from '@/constants/ActionTypes.js';
import { USER_LIMIT_REACHED } from '@/constants/errorCodes.js';
import { EMPLOYEE_MODAL } from '@/constants/modalTypes.js';
import { findIndexById } from '@/utils/arrayHelpers.js';
import {
  formatEmployeesToUpdate,
  formatSupplementaryLocationsIdsForEmployeeUpdate,
} from '@/utils/userEmployeesHelpers';

import { addAbsencesLimitsEmployee } from './absences.js';
import { createContracts, deleteContracts, updateContracts } from './contracts.ts';
import { conn, showConfirmModal } from './index';
import { changeLocationFilter, updateLocationFilter, updateLocationFilterName } from './locationFilter.js';
import { getLocationSettings } from './settings.jsx';
import { decreaseLoaderCounter, increaseLoaderCounter, showModal } from './uiState.js';

const messages = defineMessages({
  editEmployeeMassSucces: {
    id: 'employees.editMass.successMessage',
    defaultMessage: 'Pomyślnie zmieniono dane pracowników!',
  },
  editEmployeeMassFailure: {
    id: 'employees.editMass.failureMessage',
    defaultMessage: 'Wystąpił błąd podczas zmiany danych pracowników',
  },
  successTitle: {
    id: 'employees.succesTitle',
    defaultMessage: 'Sukces',
  },
  errorTitle: {
    id: 'employees.errorTitle',
    defaultMessage: 'Błąd',
  },
  addEmployeesFailure: {
    id: 'employees.add.failureMessage',
    defaultMessage: 'Wystąpił błąd podczas dodawania pracownika',
  },
  addEmployeesLimitsFailure: {
    id: 'employees.add.limits.failureMessage',
    defaultMessage: 'Osiągnięto limit liczby aktywnych użytkowników na Twoim koncie',
  },
  addMassEmployeesSuccessDescription: {
    id: 'addMassEmployeesSuccess.description',
    defaultMessage: 'Dodano {numberOfEmployees} pracowników',
  },
  addMassEmployeesErrorDescription: {
    id: 'addMassEmployeesError.description',
    defaultMessage: 'Wystąpił błąd podczas dodawania pracowników',
  },
  deleteReleasedEmployeesShiftsSuccess: {
    id: 'employees.deleteReleasedEmployeesShifts.successMessage',
    defaultMessage: 'Usunięto zmiany zwolnionych pracowników',
  },
  deleteReleasedEmployeesAttendancesSuccess: {
    id: 'employees.deleteReleasedEmployeesAttendances.successMessage',
    defaultMessage: 'Usunięto obecności zwolnionych pracowników',
  },
  deleteReleasedEmployeesAbsencesSuccess: {
    id: 'employees.deleteReleasedEmployeesAbsences.successMessage',
    defaultMessage: 'Usunięto nieobecności zwolnionych pracowników',
  },
  deleteReleasedEmployeesShiftsFailure: {
    id: 'employees.deleteReleasedEmployeesShifts.failutreMessage',
    defaultMessage: 'Wystąpił błąd podczas usuwania zmian zwolnionych pracowników',
  },
  deleteReleasedEmployeesAttendancesFailure: {
    id: 'employees.deleteReleasedEmployeesAttendances.failutreMessage',
    defaultMessage: 'Wystąpił błąd podczas usuwania obecności zwolnionych pracowników',
  },
  deleteReleasedEmployeesAbsencesFailure: {
    id: 'employees.deleteReleasedEmployeesAbsences.failutreMessage',
    defaultMessage: 'Wystąpił błąd podczas usuwania nieobecności zwolnionych pracowników',
  },
  deleteReleasedEmployeesDataError: {
    id: 'employees.deleteReleasedEmployeesData.errorMessage',
    defaultMessage: 'Wystąpił błąd podczas usuwania danych zwolnionych pracowników',
  },
  addNewJobTitleSuccess: {
    id: 'jobTitles.addNewJobTitle.successMessage',
    defaultMessage: 'Dodano nowe stanowisko!',
  },
  addNewJobTitleSuccessDescription: {
    id: 'jobTitles.addNewJobTitle.successDescription',
    defaultMessage: 'Dodano stanowisko {jobTitle}',
  },
  addNewJobTitleError: {
    id: 'jobTitles.addNewJobTitle.errorMessage',
    defaultMessage: 'Wystąpił błąd podczas dodawania stanowiska',
  },
});

/** *******************************************************************************************
 * COMMON - START
 ******************************************************************************************** */
/**
 * Function that toggles checkbox for specyfic item in companymanage pages
 * @param {int} itemId - id of checked item
 * @param {string} context - name of the subpage we are checking on eg. 'employees'
 */
export const toggleCheckbox = (itemId, context) => ({
  type: actionTypes.TOGGLE_COMPANYMANAGE_CHECKBOX,
  payload: itemId,
  context,
});

/**
 * Function that toggles all Checkboxes in given category (if all selected -> diselect otherwhise select all)
 * @param {array} itemIdArray - array of ids of checked items
 * @param {string} context - name of the subpage we are checking on eg. 'employees'
 */
export const toggleAllCheckboxes = (itemIdArray, context) => ({
  type: actionTypes.TOGGLE_ALL_COMPANYMANAGE_CHECKBOXES,
  payload: itemIdArray,
  context,
});

export const deselectAllCheckboxes = context => ({
  type: actionTypes.DESELECT_ALL_COMPANYMANAGE_CHECKBOXES,
  payload: {
    context,
  },
});

/**
 * Function that opens/closes modal on companymanage pages
 * @param {string} context - name of the subpage we are toggling modal in
 */

export const toggleModal = context => ({
  type: actionTypes.TOGGLE_COMPANYMANAGE_MODAL,
  context,
});

/**
 * Function that opens modal for editing an item
 * @param {int} id - id of edited element
 * @param {string} context - name of the subpage we are toggling modal in
 */

export const startEdit = (id, context) => ({
  type: actionTypes.COMPANYMANAGE_START_EDIT,
  payload: id,
  context,
});

/**
 * Function that changes sorting priority
 * @param {int} column - index of the column we want to by sorting by
 * @param {string} context - name of the subpage we are toggling modal in
 */
export const changeSorting = (column, context) => ({
  type: actionTypes.COMPANYMANAGE_CHANGE_SORTING,
  payload: column,
  context,
});

/** *******************************************************************************************
 * COMMON - END
 ******************************************************************************************** */

/** *******************************************************************************************
 * LOCATIONS - START
 ******************************************************************************************** */
export const updateNewLocation = locationObject => ({
  type: actionTypes.ADD_NEW_LOCATION,
  payload: locationObject,
});
export const updateChangedLocation = locationObject => ({
  type: actionTypes.UPDATE_LOCATION,
  payload: locationObject,
});
export const updateDeletedLocation = locationId => ({
  type: actionTypes.DELETE_LOCATION,
  payload: locationId,
});
export const updateDeletedLocationSet = locationIdArray => ({
  type: actionTypes.DELETE_LOCATION_SET,
  payload: locationIdArray,
});

export const prepareAddLocation = data => (dispatch, getState) =>
  new Promise((resolve) => {
    const { currentCompany } = getState().reducer;
    dispatch(addLocation({ ...data, company_id: currentCompany.id, attachedEmployees: null })).then(location => {
      if (data.attachedEmployees.toAdd.length) {
        dispatch(massEditEmployees(data.attachedEmployees.toAdd, [location], addLocations));
      }
      resolve(location);
    });
  });

export const prepareChangeLocation = data => (dispatch, getState) => {
  const { userLocations, currentCompany } = getState().reducer;
  const { toAdd, toDelete } = data.attachedEmployees;
  const newLocation = {
    ...userLocations.find(location => location.id === data.id),
    ...data,
    company_id: currentCompany.id,
    attachedEmployees: null,
  };
  dispatch(changeLocation(newLocation));
  if (toAdd.length) {
    dispatch(massEditEmployees(toAdd, [newLocation], addLocations));
  }
  if (toDelete.length) {
    dispatch(massEditEmployees(toDelete, [newLocation], deleteLocations));
  }
};

export const addLocation =
  (locationObject, notify = true) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { demo, userLocations } = getState().reducer;
      dispatch(trackIntercomEvent(ADD_NEW_LOCATION));
      conn
        .addLocation(locationObject)
        .then(response => {
          if (demo.demoAccount) {
            dispatch(updateNewLocation({ ...response.data, pin: '123456' }));
            toastr.success('Dodano lokalizacje!', `Dodano lokalizacje ${locationObject.name}`);
          } else {
            conn
              .getLocation(response.data.id)
              .then(res => {
                if (!userLocations.length) dispatch(changeLocationFilter(res.data));
                dispatch(updateNewLocation(res.data));
                dispatch(getLocationSettings(response.data.id));
                if (notify) toastr.success('Dodano lokalizacje!', `Dodano lokalizacje ${locationObject.name}`);
                resolve(res.data);
              })
              .catch(err => {
                toastr.error('Wystąpił błąd podczas dodawania lokalizacji');
                console.log(err);
                reject(err);
              });
          }
        })
        .catch(err => {
          toastr.error('Błąd!', 'Wystąpił błąd podczas dodawania lokalizacji');
          console.log(err);
        });
    });
export const changeLocation = locationObject => dispatch => {
  conn
    .changeLocation(locationObject)
    .then(response => {
      dispatch(updateChangedLocation({ ...locationObject, id: response.data.id }));
      dispatch(updateLocationFilterName(locationObject));
      dispatch(getLocationSettings(response.data.id));
      toastr.success('Pomyślnie zmieniono dane lokalizacji!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas zmiany danych lokalizacji');
      console.log(err);
    });
};
export const deleteLocation = locationId => dispatch => {
  conn
    .deleteLocation(locationId)
    .then(() => {
      dispatch(updateDeletedLocation(locationId));
      toastr.success('Pomyślnie usunięto lokalizację!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania lokalizacji');
      console.log(err);
    });
};
export const massDeleteLocation = locationIdArray => dispatch => {
  conn
    .deleteLocationArray(locationIdArray)
    .then(() => {
      dispatch(updateDeletedLocationSet(locationIdArray));
      toastr.success('Pomyślnie usunięto lokalizacje!', `Liczba usuniętych lokalizacji: ${locationIdArray.length}`);
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania lokalizacji');
      console.log(err);
    });
};

export const massDeleteLocationsConfirm = () => (dispatch, getState) => {
  const { userLocations, locationFilter, listsUi } = getState().reducer;
  const { selected } = listsUi.locations;
  const title = <FormattedMessage id="locations.deleteLocations" defaultMessage="Usuń lokalizacje" />;
  const description = (
    <FormattedMessage
      id="locations.deleteLocationWarning"
      defaultMessage="Usunięcie lokalizacji spowoduje utratę wszystkich danych do niej przypisanych!"
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(massDeleteLocation(selected));
    if (selected.includes(locationFilter.selectedLocation.id)) {
      dispatch(updateLocationFilter(userLocations, selected));
    }
  };
  const deletedThing = (
    <FormattedMessage
      id="locations.locations"
      defaultMessage="{locationAmount, plural, one {wybraną lokalizację} few {wybrane lokalizacje} many {wybrane lokalizacje} other {wybrane lokalizacje}}"
      values={{ locationAmount: selected.length }}
    />
  );
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const deleteLocationConfirm = id => (dispatch, getState) => {
  const { userLocations, locationFilter } = getState().reducer;
  const title = <FormattedMessage id="locations.deleteLocation" defaultMessage="Usuń lokalizacje" />;
  const description = (
    <FormattedMessage
      id="locations.deleteLocationWarning"
      defaultMessage="Usunięcie lokalizacji spowoduje utratę wszystkich danych do niej przypisanych!"
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(deleteLocation(id));
    if (locationFilter.selectedLocation.id === id) {
      dispatch(updateLocationFilter(userLocations, [id]));
    }
  };
  const deletedThing = <FormattedMessage id="location.choosenLocation" defaultMessage="wybraną lokalizację" />;

  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

/** *******************************************************************************************
 * LOCATIONS - END
 ******************************************************************************************** */
/** *******************************************************************************************
 * JOB TITLES - START
 ******************************************************************************************** */
export const updateNewJobTitle = jobTitleObject => ({
  type: actionTypes.ADD_NEW_JOBTITLE,
  payload: jobTitleObject,
});
export const updateChangedJobTitle = jobTitleObject => ({
  type: actionTypes.UPDATE_JOBTITLE,
  payload: jobTitleObject,
});
export const updateDeletedJobTitle = jobTitleId => ({
  type: actionTypes.DELETE_JOBTITLE,
  payload: jobTitleId,
});
export const updateDeletedJobTitleSet = jobTitleIdArray => ({
  type: actionTypes.DELETE_JOBTITLE_SET,
  payload: jobTitleIdArray,
});

export const prepareAddJobTitle = data => async (dispatch, getState) => {
  const { currentCompany } = getState().reducer;
  const jobTitleToAdd = {
    ...data,
    company_id: currentCompany.id,
    attachedEmployees: null,
  };
  const newJobTitle = await dispatch(addJobTitle(jobTitleToAdd));

  return newJobTitle;
};

export const prepareChangeJobTitle = (data, editedId) => (dispatch, getState) => {
  const { currentCompany } = getState().reducer;
  const newJobTitle = {
    ...data,
    id: editedId,
    company_id: currentCompany.id,
    attachedEmployees: null,
  };
  dispatch(changeJobTitle(newJobTitle));
};

const notifyAddJobTitleSuccess = jobTitle => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.NOTIFY_ADD_JOB_TITLE_SUCCESS,
    notification: {
      title: intl.formatMessage(messages.addNewJobTitleSuccess, {}),
      description: intl.formatMessage(messages.addNewJobTitleSuccessDescription, { jobTitle }),
      type: 'success',
    },
  });
};

const notifyAddJobTitleError = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.NOTIFY_ADD_JOB_TITLE_ERROR,
    notification: {
      title: intl.formatMessage(messages.errorTitle, {}),
      description: intl.formatMessage(messages.addNewJobTitleError, {}),
      type: 'error',
    },
  });
};

export const addJobTitle =
  (jobTitleObject, notify = true) =>
  dispatch =>
    new Promise((resolve, reject) => {
      conn
        .addJobTitle(jobTitleObject)
        .then(response => {
          const newJobTitle = { ...jobTitleObject, id: response.data.id };
          dispatch(updateNewJobTitle(newJobTitle));
          if (notify) dispatch(notifyAddJobTitleSuccess(newJobTitle.title));
          resolve(newJobTitle);
        })
        .catch(err => {
          dispatch(notifyAddJobTitleError());
          reject(err);
        });
    });

export const changeJobTitle = jobTitleObject => dispatch => {
  conn
    .changeJobTitle(jobTitleObject)
    .then(response => {
      dispatch(updateChangedJobTitle({ ...jobTitleObject, id: response.data.id }));
      toastr.success('Pomyślnie zmieniono dane stanowiska!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas zmiany danych stanowiska');
      console.log(err);
    });
};

export const deleteJobTitle = jobTitleId => dispatch => {
  conn
    .deleteJobTitle(jobTitleId)
    .then(response => {
      dispatch(updateDeletedJobTitle(jobTitleId));
      toastr.success('Pomyślnie usunięto stanowisko!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania stanowiska');
      console.log(err);
    });
};

export const massDeleteJobTitle = jobTitleIdArray => dispatch => {
  conn
    .deleteJobTitleArray(jobTitleIdArray)
    .then(response => {
      dispatch(updateDeletedJobTitleSet(jobTitleIdArray));
      toastr.success('Pomyślnie usunięto stanowiska!', `Liczba usuniętych stanowisk: ${jobTitleIdArray.length}`);
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania stanowisk');
      console.log(err);
    });
};
export const massDeleteJobTitlesConfirm = () => (dispatch, getState) => {
  const { selected } = getState().reducer.listsUi.jobTitles;
  const title = <FormattedMessage id="jobTitles.deleteJobtitles" defaultMessage="Usuń stanowiska" />;
  const description = (
    <FormattedMessage
      id="jobTitles.deleteJobTitlesWarning"
      defaultMessage="Usunięcie {number, plural, one {stanowiska} other {stanowisk}} może spowodować problemy z kalkulacją wypłat i poprawnym wyświetlaniem informacji dla pracowników z danym stanowiskiem!"
      values={{ number: selected.length }}
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(massDeleteJobTitle(selected));
  };
  const deletedThing = (
    <FormattedMessage
      id="jobTitles.choosenJobTitles"
      defaultMessage="{jobTitleAmount, plural, one {wybrane stanowisko} few {wybrane stanowiska} many {wybrane stanowiska} other {wybrane stanowiska}}"
      values={{ jobTitleAmount: selected.length }}
    />
  );
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const deleteJobTitleConfirm = id => dispatch => {
  const title = <FormattedMessage id="jobTitles.deleteJobtitle" defaultMessage="Usuń stanowisko" />;
  const description = (
    <FormattedMessage
      id="jobTitles.deleteJobTitleWarning"
      defaultMessage="Usunięcie stanowiska może spowodować problemy z kalkulacją wypłat i poprawnym wyświetlaniem informacji dla pracowników z tym stanowiskiem!"
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(deleteJobTitle(id));
  };
  const deletedThing = <FormattedMessage id="jobTitles.choosenOneJobTitle" defaultMessage="wybrane stanowisko" />;
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

/** *******************************************************************************************
 * JOB TITLES - END
 ******************************************************************************************** */
/** *******************************************************************************************
 * SHIFT BLOCKS - START
 ******************************************************************************************** */
export const updateNewShiftBlock = shiftObject => ({
  type: actionTypes.ADD_NEW_SHIFTBLOCK,
  payload: shiftObject,
});
export const updateChangedShiftBlock = shiftObject => ({
  type: actionTypes.UPDATE_SHIFTBLOCK,
  payload: shiftObject,
});
export const updateDeletedShiftBlock = shiftId => ({
  type: actionTypes.DELETE_SHIFTBLOCK,
  payload: shiftId,
});
export const updateDeletedShiftBlockSet = shiftIdArray => ({
  type: actionTypes.DELETE_SHIFTBLOCK_SET,
  payload: shiftIdArray,
});

export const addShiftBlock = shiftBlockObject => dispatch => {
  conn
    .addShiftblock(shiftBlockObject)
    .then(response => {
      dispatch(updateNewShiftBlock({ ...shiftBlockObject, id: response.data.id }));
      toastr.success('Dodano nowe godziny pracy!', `Dodano ${shiftBlockObject.working_hours}`);
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas dodawania godzin pracy');
      console.log(err);
    });
};
export const deleteShiftBlock = shiftBlockId => dispatch => {
  conn
    .deleteShiftblock(shiftBlockId)
    .then(response => {
      dispatch(updateDeletedShiftBlock(shiftBlockId));
      toastr.success('Pomyślnie usunięto godziny pracy!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania godzin pracy');
      console.log(err);
    });
};
export const massDeleteShiftBlock = shiftBlockIdArray => dispatch => {
  conn
    .deleteShiftblockArray(shiftBlockIdArray)
    .then(response => {
      dispatch(updateDeletedShiftBlockSet(shiftBlockIdArray));
      toastr.success(
        'Pomyślnie usunięto godziny pracy!',
        `Liczba usuniętych godzin pracy: ${shiftBlockIdArray.length}`,
      );
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania godzin pracy');
      console.log(err);
    });
};
export const changeShiftBlock = shiftBlockObject => dispatch => {
  conn
    .changeShiftblock(shiftBlockObject)
    .then(response => {
      dispatch(updateChangedShiftBlock({ ...shiftBlockObject, id: response.data.id }));
      toastr.success('Pomyślnie zmieniono godziny pracy!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas zmiany godzin pracy');
      console.log(err);
    });
};

export const massDeleteShiftBlocksConfirm = () => (dispatch, getState) => {
  const { selected } = getState().reducer.listsUi.shiftBlocks;
  const title = <FormattedMessage id="shiftBlocks.deleteShiftBlocks" defaultMessage="Usuń godziny pracy" />;
  let description;
  if (selected.length > 1) {
    description = (
      <FormattedMessage
        id="shiftBlocks.deleteShiftBlocks.areYouSureNamed"
        defaultMessage="Czy na pewno chcesz usunąć {number} godziny pracy?"
        values={{ number: selected.length }}
      />
    );
  } else {
    description = (
      <FormattedMessage
        id="shiftBlocks.deleteShiftBlocks.areYouSureOne"
        defaultMessage="Czy na pewno chcesz usunąć te godziny pracy?"
      />
    );
  }
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(massDeleteShiftBlock(selected));
  };
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
    }),
  );
};

export const deleteShiftBlockConfirm = id => (dispatch, getState) => {
  const { userShiftblocks } = getState().reducer;
  const title = <FormattedMessage id="shiftBlocks.deleteShiftBlock" defaultMessage="Usuń godziny pracy" />;
  const index = findIndexById(userShiftblocks, id);
  let description;
  if (index >= 0) {
    const sb = userShiftblocks[index];
    description = (
      <FormattedMessage
        id="shiftBlocks.deleteShiftBlock.areYouSureNamed"
        defaultMessage="Czy na pewno chcesz usunąć godziny pracy: {workingHours}?"
        values={{ workingHours: sb.working_hours }}
      />
    );
  } else {
    description = (
      <FormattedMessage
        id="shiftBlocks.deleteShiftBlock.areYouSureOne"
        defaultMessage="Czy na pewno chcesz usunąć te godziny pracy?"
      />
    );
  }
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(deleteShiftBlock(id));
  };
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
    }),
  );
};

/** *******************************************************************************************
 * SHIFT BLOCKS - END
 ******************************************************************************************** */
/** *******************************************************************************************
 * EMPLOYEES - START
 ******************************************************************************************** */
export const updateNewEmployee = employeeObject => ({
  type: actionTypes.ADD_NEW_EMPLOYEE,
  payload: employeeObject,
});
export const updateChangedEmployee = employeeObject => ({
  type: actionTypes.UPDATE_EMPLOYEE,
  payload: employeeObject,
});
export const updateDeletedEmployee = employeeId => ({
  type: actionTypes.DELETE_EMPLOYEE,
  payload: employeeId,
});
export const updateDeletedEmployeeSet = employeeIdArray => ({
  type: actionTypes.DELETE_EMPLOYEE_SET,
  payload: employeeIdArray,
});

const createEditEmployeesSuccessNotification = intl => ({
  title: intl.formatMessage(messages.successTitle, {}),
  description: intl.formatMessage(messages.editEmployeeMassSucces, {}),
  type: 'success',
});

const massEditEmployeesSuccessToaster = () => (dispatch, getState, intl) => {
  const notification = createEditEmployeesSuccessNotification(intl);

  dispatch({
    type: actionTypes.UPDATE_EMPLOYEE_MASS_SUCCESS_TOASTER,
    notification,
  });
};

const massEditEmployeesSuccess = employees => (dispatch, getState, intl) => {
  const notification = createEditEmployeesSuccessNotification(intl);

  dispatch({
    type: actionTypes.UPDATE_EMPLOYEE_MASS_SUCCESS,
    payload: employees,
    notification,
  });
};

const massEditEmployeesFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.UPDATE_EMPLOYEE_MASS_FAILURE,
    notification: {
      title: intl.formatMessage(messages.errorTitle, {}),
      description: intl.formatMessage(messages.editEmployeeMassFailure, {}),
      type: 'error',
    },
  });
};

export const addEmployee =
  (employeeObject, notify = true) =>
  (dispatch, getState, intl) =>
    new Promise((resolve, reject) => {
      conn
        .addEmployee(employeeObject)
        .then(response => {
          dispatch(
            updateNewEmployee({
              ...response.data,
              shifts: [],
              availabilities: [],
              availability_blocks: [],
            }),
          );
          dispatch(trackIntercomEvent(ADD_NEW_EMPLOYEE));
          if (employeeObject.limits && employeeObject.limits.length !== 0) {
            dispatch(addAbsencesLimitsEmployee(employeeObject.limits, response.data.id));
          }

          resolve(response.data);
          if (notify)
            toastr.success(
              'Dodano nowego pracownika!',
              `Dodano pracownika ${`${response.data.first_name} ${response.data.last_name}`}`,
            );
          dispatch(trackIntercomEvent(ADD_NEW_EMPLOYEE));
          if (employeeObject.limits && employeeObject.limits.length !== 0) {
            dispatch(addAbsencesLimitsEmployee(employeeObject.limits, response.data.id));
          }

          resolve(response.data);
          if (notify)
            toastr.success(
              'Dodano nowego pracownika!',
              `Dodano pracownika ${`${response.data.first_name} ${response.data.last_name}`}`,
            );
        })
        .catch(err => {
          const errorCode = err.response.data.reason;

          if (errorCode === USER_LIMIT_REACHED) {
            toastr.error(
              intl.formatMessage(messages.errorTitle),
              intl.formatMessage(messages.addEmployeesLimitsFailure),
            );
            reject(err);
          } else {
            toastr.error(intl.formatMessage(messages.errorTitle), intl.formatMessage(messages.addEmployeesFailure));
            reject(err);
          }
        });
    });
export const deleteEmployee = employeeId => dispatch => {
  conn
    .deleteEmployee(employeeId)
    .then(response => {
      dispatch(trackIntercomEvent(DELETE_EMPLOYEE));
      dispatch(updateDeletedEmployee(employeeId));
      toastr.success('Pomyślnie usunięto pracownika!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania pracownika');
      console.log(err);
    });
};
export const massDeleteEmployee = employeeIdArray => dispatch => {
  conn
    .deleteEmployeeArray(employeeIdArray)
    .then(response => {
      dispatch(updateDeletedEmployeeSet(employeeIdArray));
      toastr.success('Pomyślnie usunięto pracowników!', `Liczba usuniętych pracowników: ${employeeIdArray.length}`);
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania pracowników');
      console.log(err);
    });
};
export const changeEmployee = employeeObject => dispatch =>
  new Promise((resolve, reject) => {
    const processedEmployee = formatSupplementaryLocationsIdsForEmployeeUpdate([employeeObject])[0];

    conn
      .changeEmployee(processedEmployee)
      .then(response => {
        dispatch(updateChangedEmployee(response.data));
        if (processedEmployee.limits && processedEmployee.limits.length !== 0) {
          dispatch(addAbsencesLimitsEmployee(processedEmployee.limits, response.data.id));
        }
        toastr.success('Pomyślnie zmieniono dane pracownika!');
        resolve(response.data);
      })
      .catch(err => {
        toastr.error('Błąd!', 'Wystąpił błąd podczas zmiany danych pracownika');
        console.log(err);
        reject(err);
      });
  });

export const massEditContracts = (action, currentFieldValue) => async dispatch => {
  switch (action) {
    case emplMassEditActions.addContract: {
      const contracts = currentFieldValue.correctEmployees.map(({ contracts }) => contracts).flat();

      const contractsToAdd = contracts
        .filter(contract => contract?.isTemp === true)
        .map(({ end_date, id, isTemp, ...relevantProps }) => relevantProps);
      dispatch(createContracts(contractsToAdd));
      break;
    }
    case emplMassEditActions.remove: {
      const contractsIds = currentFieldValue.relatedContracts.map(({ id }) => id);
      dispatch(deleteContracts(contractsIds));
      break;
    }
    case emplMassEditActions.removeContractsJobTitles:
    case emplMassEditActions.addContractsJobTitles:
    case emplMassEditActions.editContractsStartDate: {
      const contractsToUpdate = currentFieldValue.relatedContracts;
      dispatch(updateContracts(contractsToUpdate));
      break;
    }
    default:
      return null;
  }
};

export const massEditEmployees =
  (selectedEmployees, currentFieldValue, action, options) => async (dispatch, getState) => {
    const { contracts } = getState().reducer;

    const selectedEmployeesWithContracts = selectedEmployees.map(employee => {
      const employeeContracts = contracts[employee.id] || [];
      return {
        ...employee,
        contracts: employeeContracts,
      };
    });

    const employeesToEdit = action(selectedEmployeesWithContracts, currentFieldValue, options);

    const processedEmployees = formatSupplementaryLocationsIdsForEmployeeUpdate(employeesToEdit);

    if (Array.isArray(currentFieldValue) && currentFieldValue[0].absence_type_id) {
      await handleAbsenceTypeAction(dispatch, currentFieldValue, processedEmployees);
    } else {
      await handleEmployeeUpdate(dispatch, processedEmployees);
    }
  };

export const handleAbsenceTypeAction = async (dispatch, currentFieldValue, employees) => {
  try {
    employees.forEach(employee => {
      dispatch(addAbsencesLimitsEmployee(currentFieldValue, employee.id));
    });
    dispatch(massEditEmployeesSuccessToaster());
  } catch (error) {
    dispatch(massEditEmployeesFailure());
  }
};

export const handleEmployeeUpdate = async (dispatch, employees) => {
  try {
    const { data } = await conn.updateMultipleEmployees(formatEmployeesToUpdate(employees), 'blocking');
    dispatch(massEditEmployeesSuccess(data));
  } catch (error) {
    dispatch(massEditEmployeesFailure());
  }
};

export const massDeleteEmployeesConfirm = selectedIds => dispatch => {
  const title = <FormattedMessage id="employees.deleteEmployees" defaultMessage="Usuń pracowników" />;
  const description = (
    <FormattedMessage
      id="employees.deleteEmployeeWarning"
      defaultMessage="Usunięty pracownik nie będzie widoczny w Ewidencji czasu pracy i zostanie permanentnie usunięty z Twojego konta. Pamiętaj, że zamiast usuwać możesz dezaktywować pracownika."
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(massDeleteEmployee(selectedIds));
  };
  const deletedThing = (
    <FormattedMessage
      id="employees.choosenEmployees"
      defaultMessage="{employeeAmount, plural, one {wybranego pracownika} few {wybranych pracowników} many {wybranych pracowników} other {wybranych pracowników}}"
      values={{ employeeAmount: selectedIds.length }}
    />
  );

  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const deleteEmployeeConfirm = id => dispatch => {
  const title = <FormattedMessage id="employees.deleteEmployee" defaultMessage="Usuń pracownika" />;
  const description = (
    <FormattedMessage
      id="employees.deleteEmployeeWarning"
      defaultMessage="Usunięty pracownik nie będzie widoczny w Ewidencji czasu pracy i zostanie permanentnie usunięty z Twojego konta. Pamiętaj, że zamiast usuwać możesz dezaktywować pracownika."
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const deletedThing = <FormattedMessage id="employees.choosenOneEmployee" defaultMessage="wybranego pracownika" />;
  const confirmFunc = () => {
    dispatch(deleteEmployee(id));
  };

  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const addMassEmployees = employees => async (dispatch, getState, intl) => {
  try {
    const { data } = await conn.addMassEmployees(employees);
    const { employees: newEmployees } = employees;
    const numberOfEmployees = newEmployees.length;
    dispatch({
      type: actionTypes.ADD_MASS_EMPLOYEES,
      payload: data,
      notification: {
        title: intl.formatMessage(messages.successTitle),
        description: intl.formatMessage(messages.addMassEmployeesSuccessDescription, { numberOfEmployees }),
        type: 'success',
      },
    });
  } catch (e) {
    dispatch({
      type: actionTypes.ADD_MASS_EMPLOYEES_FAILURE,
      notification: {
        title: intl.formatMessage(messages.errorTitle),
        description: intl.formatMessage(messages.addMassEmployeesErrorDescription),
        type: 'error',
      },
    });
  }
};

/** *******************************************************************************************
 * EMPLOYEES - END
 ******************************************************************************************** */
/** *******************************************************************************************
 * AVA TYPES - START
 ******************************************************************************************** */
export const updateNewAvaType = avaObject => ({
  type: actionTypes.ADD_NEW_AVA_TYPE,
  payload: avaObject,
});
export const updateChangedAvaType = avaObject => ({
  type: actionTypes.UPDATE_AVA_TYPE,
  payload: avaObject,
});
export const updateDeletedAvaType = availabilityId => ({
  type: actionTypes.DELETE_AVA_TYPE,
  payload: availabilityId,
});
export const updateDeletedAvaTypeSet = availabilityIdArray => ({
  type: actionTypes.DELETE_AVA_TYPE_SET,
  payload: availabilityIdArray,
});

export const addAvaType = avaObject => dispatch => {
  conn
    .addAvaType(avaObject)
    .then(response => {
      dispatch(updateNewAvaType({ ...avaObject, ...response.data }));
      toastr.success('Dodano!', `Dodano ${avaObject.name} jako typ dostępności`);
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas dodawania typu dostępności');
      console.log(err);
    });
};
export const changeAvaType = avaObject => dispatch => {
  conn
    .changeAvaType(avaObject)
    .then(response => {
      dispatch(updateChangedAvaType({ ...avaObject, id: response.data.id }));
      toastr.success('Pomyślnie zmieniono typ dostępności!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas zmiany typu dostępności');
      console.log(err);
    });
};
export const deleteAvaType = availabilityId => dispatch => {
  conn
    .deleteAvaType(availabilityId)
    .then(response => {
      dispatch(updateDeletedAvaType(availabilityId));
      toastr.success('Pomyślnie usunięto typ dostępności!');
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania typu dostępności');
      console.log(err);
    });
};
export const massDeleteAvaType = availabilityIdArray => dispatch => {
  conn
    .deleteAvaTypeArray(availabilityIdArray)
    .then(response => {
      dispatch(updateDeletedAvaTypeSet(availabilityIdArray));
      toastr.success(
        'Pomyślnie usunięto typy dostępności!',
        `Liczba usuniętych typów dostępności ${availabilityIdArray.length}`,
      );
    })
    .catch(err => {
      toastr.error('Błąd!', 'Wystąpił błąd podczas usuwania typów dostępności');
      console.log(err);
    });
};

export const massDeleteAvaTypeConfirm = () => (dispatch, getState) => {
  const { selected } = getState().reducer.listsUi.avaTypes;
  const title = <FormattedMessage id="avaTypes.deleteAvaTypes" defaultMessage="Usuń typy dostępności" />;
  const description = (
    <FormattedMessage
      id="avaTypes.deleteAvaTypeWarning"
      defaultMessage="Usuwając typ dostępności utracisz możliwość przywrócenia jego"
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const confirmFunc = () => {
    dispatch(massDeleteAvaType(selected));
  };
  const deletedThing = (
    <FormattedMessage
      id="avaTypes.choosenAvaTypes"
      defaultMessage="{avaTypeAmount, plural, one {wybrany typ dostępności} other {wybrane typy dostępności}}"
      values={{ avaTypeAmount: selected.length }}
    />
  );
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

export const deleteAvaTypeConfirm = id => dispatch => {
  const title = <FormattedMessage id="avaTypes.deleteAvaType" defaultMessage="Usuń typ dostępności" />;
  const description = (
    <FormattedMessage
      id="avaTypes.deleteAvaTypeWarning"
      defaultMessage="Usuwając typ dostępności utracisz możliwość przywrócenia jego"
    />
  );
  const confirmText = <FormattedMessage id="delete" defaultMessage="Usuń" />;
  const deletedThing = <FormattedMessage id="avaTypes.choosenOneAvaType" defaultMessage="wybrany typ dostępności" />;

  const confirmFunc = () => {
    dispatch(deleteAvaType(id));
  };
  dispatch(
    showConfirmModal({
      title,
      description,
      confirmText,
      confirmFunc,
      showDeleteInput: true,
      deletedThing,
    }),
  );
};

/** *******************************************************************************************
 * AVA TYPES - END
 ******************************************************************************************** */
export const inviteEmployeesSuccess = invitations => dispatch => {
  dispatch({
    type: INVITE_EMPLOYEES_SUCCESS,
    notification: {
      title: 'Sukces!',
      description: `Wysłana liczba zaproszeń: ${invitations.length}`,
      type: 'success',
    },
  });
  dispatch(trackIntercomEvent(INVITE_EMPLOYEES_SUCCESS));
};
export const inviteEmployees = () => (dispatch, getState) => {
  const { listsUi, userEmployees } = getState().reducer;
  const { employees } = listsUi;
  const invitations = employees.selected
    .map(employeeId => {
      const emp = userEmployees.find(u => u.id === employeeId);
      return { email: emp.email, pin: emp.pin };
    })
    .filter(inv => inv.email && inv.email.length > 0);

  dispatch(
    showConfirmModal({
      title: <FormattedMessage id="employees.invite.title" defaultMessage="Zaproszenia" />,
      description: (
        <div>
          <FormattedMessage
            id="employees.invite.description"
            defaultMessage="Czy na pewno chcesz wysłać następujące zaproszenia:"
          />
          <ul>
            {invitations.map(i => (
              <li key={i.email}>{i.email}</li>
            ))}
          </ul>
        </div>
      ),
      confirmText: <FormattedMessage id="send" defaultMessage="Wyślij" />,
      confirmFunc: () => {
        conn
          .sendEmployeeInvites(invitations)
          .then(() => {
            dispatch(inviteEmployeesSuccess(invitations));
          })
          .catch(() => console.error);
        dispatch(deselectAllCheckboxes('employees'));
      },
      confirmType: 'info',
    }),
  );
};
export const inviteEmployee = (email, pin) => dispatch => {
  conn.sendEmployeeInvites([{ email, pin }]).then(() => {
    dispatch(inviteEmployeesSuccess([{ email, pin }]));
  });
};

export const addMultipleObjects = (type, objectsArray) => dispatch => {
  switch (type) {
    case 'userLocations': {
      const promises = objectsArray.map(location => dispatch(addLocation(location, false)));
      return Promise.all(promises);
    }
    case 'userJobTitles': {
      const promises = objectsArray.map(jobTitle => dispatch(addJobTitle(jobTitle, false)));
      return Promise.all(promises);
    }
    case 'userEmployees': {
      const promises = objectsArray.map(employee => dispatch(addEmployee(employee, false)));
      return Promise.all(promises);
    }
    default:
      return Promise.resolve();
  }
};

export const getFreePin = () => async dispatch => {
  const { data } = await conn.getFreePin();

  dispatch({
    type: actionTypes.GET_FREE_PIN,
    payload: data,
  });

  return data;
};

export const showEmployeeModal = employeeId => (dispatch, getState) => {
  const employee = getState().reducer.employees.data[employeeId];
  dispatch(showModal(EMPLOYEE_MODAL, employee));
};

export const deleteReleasedShiftsSuccess = (employeeIds, deleteShiftsSuccess, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_SHIFTS_SUCCESS,
  payload: {
    deleteShiftsSuccess,
    employeeIds,
  },
  notification: {
    title: intl.formatMessage(messages.successTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesShiftsSuccess),
    type: 'success',
  },
});

export const deleteReleasedAbsencesSuccess = (employeeIds, deleteAbsencesSuccess, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_ABSENCES_SUCCESS,
  payload: {
    deleteAbsencesSuccess,
    employeeIds,
  },
  notification: {
    title: intl.formatMessage(messages.successTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesAbsencesSuccess),
    type: 'success',
  },
});

export const deleteReleasedAttendancesSuccess = (employeeIds, deleteAttendancesSuccess, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_ATTENDANCES_SUCCESS,
  payload: {
    deleteAttendancesSuccess,
    employeeIds,
  },
  notification: {
    title: intl.formatMessage(messages.successTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesAttendancesSuccess),
    type: 'success',
  },
});

export const deleteReleasedShiftsFailure = (data, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_SHIFTS_FAILURE,
  payload: data,
  notification: {
    title: intl.formatMessage(messages.errorTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesShiftsFailure),
    type: 'error',
  },
});

export const deleteReleasedAbsencesFailure = (data, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_ABSENCES_FAILURE,
  payload: data,
  notification: {
    title: intl.formatMessage(messages.errorTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesAbsencesFailure),
    type: 'error',
  },
});

export const deleteReleasedAttendancesFailure = (data, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_ATTENDANCES_FAILURE,
  payload: data,
  notification: {
    title: intl.formatMessage(messages.errorTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesAttendancesFailure),
    type: 'error',
  },
});

export const deleteReleasedEmployeesFailure = (error, intl) => ({
  type: actionTypes.DELETE_RELEASED_EMPLOYEES_DATA_FAILURE,
  payload: error,
  notification: {
    title: intl.formatMessage(messages.errorTitle),
    description: intl.formatMessage(messages.deleteReleasedEmployeesDataError),
    type: 'error',
  },
});

export const deleteReleasedEmployeesData =
  (employeeIds, deleteShifts, deleteAttendances, deleteAbsences) => async (dispatch, getState, intl) => {
    try {
      dispatch(increaseLoaderCounter('blocking'));
      const { data } = await conn.deleteReleasedEmployeesData(
        employeeIds,
        deleteShifts,
        deleteAttendances,
        deleteAbsences,
      );
      const { deleteShiftsSuccess, deleteAbsencesSuccess, deleteAttendancesSuccess } = data;

      if (deleteShiftsSuccess) dispatch(deleteReleasedShiftsSuccess(employeeIds, deleteShiftsSuccess, intl));
      if (deleteAbsencesSuccess) dispatch(deleteReleasedAbsencesSuccess(employeeIds, deleteAbsencesSuccess, intl));
      if (deleteAttendancesSuccess)
        dispatch(deleteReleasedAttendancesSuccess(employeeIds, deleteAttendancesSuccess, intl));

      if (deleteShifts && !deleteShiftsSuccess) dispatch(deleteReleasedShiftsFailure(data, intl));
      if (deleteAbsences && !deleteAbsencesSuccess) dispatch(deleteReleasedAbsencesFailure(data, intl));
      if (deleteAttendances && !deleteAttendancesSuccess) dispatch(deleteReleasedAttendancesFailure(data, intl));
    } catch (error) {
      dispatch(deleteReleasedEmployeesFailure(error, intl));
    } finally {
      dispatch(decreaseLoaderCounter('blocking'));
    }
  };
