import * as AT from '@/constants/ActionTypes';

const initialState = {
  viewMode: 'schedule',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.TOGGLE_SCHEDULE_AVAILABILITIES_MODE:
      return { ...state, viewMode: state.viewMode === 'availabilities' ? 'schedule' : 'availabilities' };
    case AT.SELECT_AVAILABILITIES_MODE:
      return { ...state, viewMode: 'availabilities' };
    case AT.SELECT_SHIFTS_MODE:
      return { ...state, viewMode: 'schedule' };
    default:
      return state;
  }
};

export default reducer;
