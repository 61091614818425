import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip';

import './MDIconButton.scss';

const MDIconButton = props => {
  const { icon, label, onClick } = props;
  let modifiers = props.modifiers || [];
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(' ');
  }

  const className = classnames(
    'mdIconButton',
    props.className,
    modifiers.map(modifier => `mdIconButton--${modifier}`),
    {
      'mdIconButton--disabled': props.disabled,
    },
  );

  const content = (
    <span
      role="button"
      tabIndex={0}
      className="mdIconButton__icon material-icons"
      onClick={!props.disabled ? onClick : null}
    >
      {icon}
    </span>
  );

  return (
    <div className={className}>
      {label ? (
        <Tooltip className="mdIconButton__tooltip" content={label}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};

MDIconButton.defaultProps = {
  modifiers: [],
};

MDIconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MDIconButton;
