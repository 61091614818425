import PropTypes from 'prop-types';

const NameCell = props => (
  <span className="col_name_wrap">
    {props.avatar ? (
      <img src={props.avatar} alt="" className="avatar" />
    ) : (
      <span style={{ backgroundColor: props.color }} className="initials">
        {props.initials}
      </span>
    )}
    <div className="col_fullname">
      {props.name}
      {props.children}
    </div>
  </span>
);

NameCell.propTypes = {
  avatar: PropTypes.string,
  initials: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default NameCell;
