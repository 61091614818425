/* eslint max-len:0 */
import { FormattedMessage } from 'react-intl';

import HelpModal from '@/containers/HelpModalContainer.js';

const AvailabilityHelpModal = () => (
  <HelpModal
    title={<FormattedMessage id="common.availability" defaultMessage="Dostępności" />}
    helpCenterLink="https://kadromierz.pl/centrum-pomocy/pracownik/dostepnosc-pracownika/"
  >
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowRight" defaultMessage="Strzałka w prawo" />
        </strong>
      </td>
      <td>
        <FormattedMessage id="help.goToNextSpanInCalendar" defaultMessage="Przejdź do następnego okresu w kalendarzu" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowLeft" defaultMessage="Strzałka w lewo" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.goToPrevSpanInCalendar"
          defaultMessage="Przejdź do poprzedniego okresu w kalendarzu"
        />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
          <br />
          <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.zoomDescription"
          defaultMessage='  Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.returnToToday" defaultMessage="Powrót do dzisiaj" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.availability.returnToTodayDesc"
          defaultMessage="Pokaż dostępności na ten tydzień / miesiąc"
        />
      </td>
    </tr>
    <tr>
      <td>◀</td>
      <td>
        <FormattedMessage id="help.prevSpan" defaultMessage="Poprzedni okres" />
      </td>
    </tr>
    <tr>
      <td>▶</td>
      <td>
        <FormattedMessage id="help.nextSpan" defaultMessage="Następny okres" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.clearSpan" defaultMessage="Wyczyść bieżący widok" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.availability.clearSpanDesc"
          defaultMessage="Naciśnięcie powoduje usunięcie dostępności w wybranym okresie"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="help.availability.duplicatePrevSpan" defaultMessage="Duplikuj poprzedni okres" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.availability.duplicatePrevSpanDesc"
          defaultMessage="Naciśnięcie powoduje usunięcie wszystkich dostępności w tym okresie i skopiowanie dostępności z poprzedniego okresu na ich miejsce."
        />
      </td>
    </tr>
  </HelpModal>
);

AvailabilityHelpModal.propTypes = {};

export default AvailabilityHelpModal;
