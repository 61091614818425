import messages from './messages.js';

export const getOvertimeHours = (attendance, intl) => {
  if (!attendance.matching_shift) {
    return attendance.hours;
  }

  const [attendanceStartHour, attendanceEndHour] = attendance.hours.split('-');
  const [shiftStartHour, shiftEndHour] = attendance.matching_shift.working_hours.split('-');

  if (attendance.early_in && attendance.late_out) {
    return `${attendanceStartHour}-${shiftStartHour} ${intl.formatMessage(
      messages.and,
    )} ${shiftEndHour}-${attendanceEndHour}`;
  }

  if (attendance.early_in) {
    return `${attendanceStartHour}-${shiftStartHour}`;
  }

  if (attendance.late_out) {
    return `${shiftEndHour}-${attendanceEndHour}`;
  }
};
