import { useAppSelector } from '@/redux-store';
import { selectDateArray, selectIsDayDateMode } from '@/redux-store/mainDateStore';
import { checkIsMonthlyScheduleView } from '@/utils/schedule/scheduleStyles/scheduleStyles';

export const useScheduleTable = () => {
  const dateArray = useAppSelector(selectDateArray);
  const isSingleDay = useAppSelector(selectIsDayDateMode);
  const isMonthlySchedule = checkIsMonthlyScheduleView(dateArray);

  return { isMonthlySchedule, isSingleDay };
};
