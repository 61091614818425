import { IntlShape } from 'react-intl';

import { PayrollSummary, PayrollSummaryItem, TimeFormatType } from '@/types';
import { parseMinutesToFormat } from '@/utils/dateHelper';

import { messages } from './PayrollSummary.messages';

const TIME_SUMMARY_VALUES = ['workTime', 'absenceTime', 'nightWorkTime', 'overtime'];

const getUnit = (id: string, timeFormatType: TimeFormatType): string => {
  if (TIME_SUMMARY_VALUES.includes(id)) {
    return timeFormatType === 'decimal' ? 'h' : '';
  }
  return 'PLN';
};

const getValue = (summary: PayrollSummary, timeFormatType: TimeFormatType, item: PayrollSummaryItem) => {
  const isTimeSummaryValue = TIME_SUMMARY_VALUES.includes(item.id);
  const value = item.id === 'overtime' ? summary.overtime50 + summary.overtime100 : summary[item.id] || 0;

  return isTimeSummaryValue ? parseMinutesToFormat(value, timeFormatType) : (value / 100).toFixed(2);
};

export const getFilteredSummaryData = (
  summary: PayrollSummary,
  timeFormatType: TimeFormatType,
  summaryValues: PayrollSummaryItem[],
  intl: IntlShape,
  restrictions: string[],
  isFetchingBatches?: boolean,
): { value: string | null; text: string; unit: string }[] => {
  const selectedSummaryValues = summaryValues.filter(
    item => item.active && !item.restrictions?.some(restriction => restrictions.includes(restriction)),
  );

  return selectedSummaryValues.map(item => {
    if (isFetchingBatches) {
      return {
        value: null,
        text: intl.formatMessage(messages[item.id]),
        unit: getUnit(item.id, timeFormatType),
      };
    }

    const value = getValue(summary, timeFormatType, item);
    return {
      value,
      text: intl.formatMessage(messages[item.id]),
      unit: getUnit(item.id, timeFormatType),
    };
  });
};
