import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'recommendedScheduleFromExistingTemplate.title',
    defaultMessage: 'Prognozowany grafik',
  },
  chooseTemplate: {
    id: 'recommendedScheduleFromExistingTemplate.chooseTemplate',
    defaultMessage: 'Wybierz szablon',
  },
});
