import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';

import Button from '../Basic/Button.jsx';
import MDKadroModal from '../MDKadroModal/MDKadroModal.jsx';

import './ConfirmModal.scss';

const messages = defineMessages({
  delete: { id: 'common.delete', defaultMessage: 'Usuń' },
  cancelText: { id: 'common.cancel', defaultMessage: 'Anuluj' },
  keyWord: { id: 'confirmModal.keyWord', defaultMessage: 'potwierdzam' },
  actionText: { id: 'confirmModal.actionText', defaultMessage: 'usunąć' },
  confirm: { id: 'confirmModal.confirm', defaultMessage: 'potwierdzam' },
  placeHolderText: {
    id: 'confirmModal.placeHolderText',
    defaultMessage: 'Wpisz tutaj "{keyWord}", a następnie kliknij {delete}',
  },
});

class ConfirmModal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      confirmModalObject: this.props.confirmModalObject,
      initModalObject: this.getInitModalObject(),
      deleteMessageValue: '',
    };
    bindPrototypeFunctions(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.confirmModalObject && Object.keys(nextProps.confirmModalObject).length !== 0) {
      return {
        confirmModalObject: { ...state.initModalObject, ...nextProps.confirmModalObject },
      };
    }
    return null;
  }

  getInitModalObject() {
    return {
      confirmText: this.context.intl.formatMessage(messages.delete),
      cancelText: this.context.intl.formatMessage(messages.cancelText),
      title: this.context.intl.formatMessage(messages.delete),
      description: '',
      cancelBtnModifiers: [],
      confirmBtnModifiers: [],
      showDeleteInput: false,
      actionText: this.context.intl.formatMessage(messages.actionText),
      keyWord: this.context.intl.formatMessage(messages.keyWord),
      placeHolderText: this.context.intl.formatMessage(messages.placeHolderText),
      deletedThing: '',
    };
  }

  cancel() {
    if (this.props.confirmModalObject.cancelFunc) this.props.confirmModalObject.cancelFunc();
    if (!this.props.confirmModalObject.skipHide) this.hideAndClear();
    else {
      this.setState({ deleteMessageValue: '' });
    }
  }

  execute(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { showDeleteInput, confirmFunc, preventClose } = this.props.confirmModalObject;
    if (showDeleteInput && !this.isKeyWordCorrect()) return;
    if (confirmFunc) {
      confirmFunc();
    }
    if (!preventClose) {
      this.hideAndClear();
    }
    this.setState({ deleteMessageValue: '' });
  }

  hideAndClear() {
    this.setState({ deleteMessageValue: '' });
    this.props.hideModal();
  }

  isKeyWordCorrect() {
    if (!this.state.confirmModalObject.showDeleteInput) return false;
    const confirmWord = this.state.confirmModalObject.keyWord;
    return this.state.deleteMessageValue === confirmWord.toUpperCase();
  }

  render() {
    const {
      confirmText,
      cancelText,
      title,
      description,
      cancelBtnModifiers = [],
      confirmBtnModifiers: modifiers = [],
      showDeleteInput,
      actionText,
      keyWord,
      deletedThing,
      cancelFunc,
      closeAsCancel,
    } = this.state.confirmModalObject;

    const confirmBtnModifiers = [...modifiers, 'teeny', 'high', 'uppercase', 'orange'];
    const descriptionClassName = classnames('confirmModal__description', {
      'confirmModal__description--center': !showDeleteInput,
    });
    if (showDeleteInput && !this.isKeyWordCorrect()) confirmBtnModifiers.push('disabled');
    cancelBtnModifiers.push('reverse-orange', 'teeny', 'high', 'uppercase');
    const onHideFunction = cancelFunc && closeAsCancel ? this.cancel : this.hideAndClear;
    return (
      <MDKadroModal
        show={this.props.showModal}
        onHide={onHideFunction}
        title={title}
        modifiers={['narrow']}
        hideFooter
        inputAwareSubmit
        onSubmit={this.execute}
        testId="confirmModal"
      >
        <div className={descriptionClassName}>{description}</div>
        {showDeleteInput && (
          <>
            <div>
              <FormattedMessage
                id="confirmModal.actionConfirmationText"
                defaultMessage="Aby {actionText} {deletedThing} wpisz {keyWord} poniżej"
                values={{
                  actionText,
                  deletedThing,
                  keyWord: (
                    <span className="confirmModal__keyWord" data-test="confirmKeyWord">
                      {keyWord}
                    </span>
                  ),
                }}
              />
            </div>
            <div className="confirmModal__deleteInput">
              <MDTextInput
                value={this.state.deleteMessageValue}
                onChange={e => this.setState({ deleteMessageValue: e.target.value })}
                type="text"
                id="delete"
                placeholder={this.context.intl.formatMessage(messages.placeHolderText, {
                  keyWord: this.context.intl.formatMessage(messages.keyWord).toUpperCase(),
                  delete: this.context.intl.formatMessage(messages.delete).toUpperCase(),
                })}
                label=""
                modifiers={['modal']}
                testId="confirm"
              />
            </div>
          </>
        )}
        <div className="confirmModal__buttons">
          <Button onClick={this.cancel} modifiers={cancelBtnModifiers} testId="cancelButton">
            {cancelText || this.props.cancelText}
          </Button>
          <Button onClick={this.execute} modifiers={confirmBtnModifiers} testId="confirmButton">
            {confirmText}
          </Button>
        </div>
      </MDKadroModal>
    );
  }
}

ConfirmModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ConfirmModal.defaultProps = {
  confirmModalObject: {},
  showDeleteInput: false,
};

ConfirmModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  confirmModalObject: PropTypes.shape({
    skipHide: PropTypes.bool,
    confirmFunc: PropTypes.func,
    confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    cancelFunc: PropTypes.func,
    cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    cancelBtnModifiers: PropTypes.arrayOf(PropTypes.string),
    confirmBtnModifiers: PropTypes.arrayOf(PropTypes.string),
    showDeleteInput: PropTypes.bool,
    deletedThing: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
};

export default ConfirmModal;
