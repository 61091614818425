import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { defineMessages } from 'react-intl';
import onClickOutside from 'react-onclickoutside';

import { DIRECTIONS } from '@/constants/ui.ts';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';

import Tooltip from '../../Tooltip/Tooltip.jsx';
import MDInputTooltip from '../MDInputTooltip/MDInputTooltip.jsx';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './Select.scss';

const messages = defineMessages({
  selectInputPlaceholder: {
    id: 'select.input.placeholder',
    defaultMessage: 'Szukaj...',
  },
});

const sortArrayOfOptions = (option1, option2) => {
  if (option1.key < option2.key) {
    return -1;
  }
  if (option1.key > option2.key) {
    return 1;
  }
  return 0;
};

class Select extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      filter: '',
      options: [],
    };
    this.searchRef = createRef();
    bindPrototypeFunctions(this);
  }

  componentDidMount() {
    this.rebuildOptionsAsTranslatedElements();
  }

  componentDidUpdate(prevProps) {
    if (this.state.open && this.searchRef && this.searchRef.current) {
      this.searchRef.current.focus();
    }

    if (prevProps.options !== this.props.options) {
      this.rebuildOptionsAsTranslatedElements();
    }
  }

  getOptions() {
    let result = this.state.options;
    if (this.props.withSearch) {
      result = result.filter(option => option.key.toLowerCase().includes(this.state.filter.toLowerCase()));
    }
    if (this.props.isSorted) {
      result = result.sort(sortArrayOfOptions);
    }
    return result;
  }

  rebuildOptionsAsTranslatedElements() {
    const options = this.props.options.map(option => {
      if (typeof option.key === 'string') {
        return option;
      }
      return {
        ...option,
        key: this.context.intl.formatMessage(option.key),
      };
    });
    this.setState({ options });
  }

  handleClickOutside() {
    this.setState({ open: false });
  }

  handleOpenChange() {
    if (!this.props.disabled && !this.props.readOnly) {
      this.setState({
        open: !this.state.open,
      });
    }
  }

  handleOnChange(option) {
    if (this.props.closeOnClick) {
      this.setState({
        open: false,
      });
    }
    this.props.onChange(option.value);
  }

  handleSearchOnChange(e) {
    this.setState({
      filter: e.target.value,
    });
  }

  render() {
    const { options } = this.state;
    const { name, defaultValue, withSearch, disabled, testId, noArrow, readOnly, maxInputLength, noSelectedIcon } =
      this.props;
    const { open, filter } = this.state;
    const selected = options.find(option => option.value === defaultValue)?.key;
    let modifiers = this.props.modifiers || [];
    if (!Array.isArray(modifiers)) {
      modifiers = modifiers.split(' ');
    }
    const inputClassNames = classnames('mdSelectInput', ...modifiers.map(modifier => `mdSelectInput--${modifier}`), {
      'mdSelectInput--active': open,
      'mdSelectInput--fill': !!selected,
      'mdSelectInput--disabled': disabled,
      'mdSelectInput--readOnly': readOnly,
    });

    const dropdownClassNames = classnames(
      'mdSelectDropdown',
      ...modifiers.map(modifier => `mdSelectDropdown--${modifier}`),
      {
        'mdSelectDropdown--active': open,
      },
    );

    const overlayscrollbarClassNames = classnames(
      'k-overlay-scrollbar',
      ...modifiers.map(modifier => `k-overlay-scrollbar--${modifier}`),
    );

    const containerClassNames = classnames(
      'mdSelectInput__container',
      ...modifiers.map(modifier => `mdSelectInput__container--${modifier}`),
    );
    const selectClassNames = classnames('mdSelect', ...modifiers.map(modifier => `mdSelect--${modifier}`));
    const selectInputValueClassNames = classnames('mdSelectInput__value', {
      'mdSelectInput__value--readOnly': readOnly,
    });

    const valueTooltipContent = selected?.length >= maxInputLength ? selected : '';
    const showLabelTootlip = name?.length > 60;
    const position = showLabelTootlip ? DIRECTIONS.BOTTOM : DIRECTIONS.TOP;
    const yOffset = showLabelTootlip ? -11 : 21;

    return (
      <div className={selectClassNames}>
        <div
          onClick={this.handleOpenChange}
          className={inputClassNames}
          role="presentation"
          data-test={`${testId}-select`}
        >
          {showLabelTootlip ? (
            <Tooltip content={name} yOffset={14} className="mdSelectInput__tooltip">
              <div className="mdSelectInput__label">{name}</div>
            </Tooltip>
          ) : (
            <div className="mdSelectInput__label">{name}</div>
          )}
          <Tooltip
            content={valueTooltipContent}
            className="mdSelectInput__tooltip"
            yOffset={yOffset}
            position={position}
          >
            <div className={containerClassNames}>
              <span className={selectInputValueClassNames} data-test={`${testId}-select-value`}>
                {selected}
              </span>
              <span className="mdSelectInput__icons">
                {!noArrow && !readOnly && <span className="mdSelectInput__arrow" />}
                {this.props.tooltip && <MDInputTooltip content={this.props.tooltip} />}
              </span>
            </div>
          </Tooltip>
        </div>
        <div className="kmd-textInput__error">{this.props.errorMessage}</div>
        <div className={dropdownClassNames} data-test={`${testId}-select-dropdown`}>
          {withSearch && (
            <div className="searchItem">
              <span className="searchItem__icon">
                <i className="material-icons">search</i>
              </span>
              <input
                value={filter}
                type="text"
                onChange={this.handleSearchOnChange}
                placeholder={this.context.intl.formatMessage(messages.selectInputPlaceholder, {})}
                ref={this.searchRef}
              />
            </div>
          )}
          <OverlayScrollbarsComponent className={overlayscrollbarClassNames}>
            {this.getOptions().map(option => {
              const listItemClassNames = classnames('listItem', ...modifiers.map(modifier => `listItem--${modifier}`), {
                'listItem--active': defaultValue === option.value,
              });

              return (
                <div
                  key={option.value}
                  onClick={() => this.handleOnChange(option)}
                  className={listItemClassNames}
                  role="presentation"
                  data-test={option.key}
                >
                  {!noSelectedIcon && (
                    <span className="listItem__checkbox">
                      <i className="material-icons">done</i>
                    </span>
                  )}
                  <span className="listItem__text">{option.key}</span>
                </div>
              );
            })}
          </OverlayScrollbarsComponent>
        </div>
      </div>
    );
  }
}

Select.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

Select.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  withSearch: PropTypes.bool,
  isSorted: PropTypes.bool,
  tooltip: PropTypes.string,
  errorMessage: PropTypes.string,
  testId: PropTypes.string,
  noArrow: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  readOnly: PropTypes.bool,
  maxInputLength: PropTypes.number,
  noSelectedIcon: PropTypes.bool,
};

export default onClickOutside(Select);
