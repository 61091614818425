import { connect } from 'react-redux';

import { exportData } from '@/actions/exports.js';
import { toggleExportModal } from '@/actions/uiState.js';
import ExportModal from '@/components/payrollLocation/ExportPayrollLocationModal.jsx';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showExportModal,
  payrollUI: state.reducer.payrollUI,
  userPermissions: state.reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  exportData,
  toggleExportModal,
};

const ExportModalContainer = connect(mapStateToProps, mapDispatchToProps)(ExportModal);

export default ExportModalContainer;
