import { Component } from 'react';

class RCPRedirect extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.location.href = 'http://rcp.kadromierz.pl/';
    }, 15000);
  }
  render() {
    return (
      <div className="k-unauthBoxWrapper" style={{ position: 'relative', maxWidth: '640px', minHeight: '630px' }}>
        <div className="k-logoHeader">
          <img className="k-logoHeader__logo" src="https://app.kadromierz.pl/img/logo.png" alt="logo" />
        </div>
        <h1 className="k-static__title">Aktualizacja</h1>
        <div className="k-static__text">
          <p>
            Nowa wersja modułu rejestracji czasu pracy <br /> jest dostępna pod adresem:
            <br /> <a href="http://rcp.kadromierz.pl/">rcp.kadromierz.pl</a>
          </p>
          <p>
            Za chwile zostaniesz automatycznie przekierowany <br /> na nową stronę logowania. Jeżeli nie zostałeś
            przekierowany automatycznie, kliknij
            <br /> <a href="http://rcp.kadromierz.pl/">TUTAJ</a>
          </p>

          <p>
            Pomoc w przypięciu nowej wersji do pulpitu w Twoim tablecie znajdziesz w naszym centrum pomocy: <br />
            <a
              style={{ fontSize: '16px' }}
              href="http://pomoc.kadromierz.pl/manager/modul-rejestracji-czasu-pracy/rejestracja-czasu-pracy"
            >
              http://pomoc.kadromierz.pl/manager/modul-rejestracji-czasu-pracy/rejestracja-czasu-pracy
            </a>
          </p>
        </div>
      </div>
    );
  }
}
RCPRedirect.propTypes = {};
export default RCPRedirect;
