import { RootState } from '@/redux-store';

import { LocationGroup } from './locationGroups.types.ts';

export const selectLocationGroups = (state: RootState): LocationGroup[] => state.reducer.locationGroups.locationGroups;

export const selectAllLocationGroupsNames = (state: RootState): string[] =>
  state.reducer.locationGroups.locationGroups.map(locationGroup => locationGroup.name);

export const selectLocationGroupsForLocationId =
  (locationId: string) =>
  (state: RootState): LocationGroup[] | undefined =>
    state.reducer.locationGroups.locationGroups.filter(locationGroup => locationGroup.locationIds.includes(locationId));
