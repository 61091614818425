import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import ScheduleSingleDayAbsence from './ScheduleSingleDayAbsence';

const mapStateToProps = (state) => ({
  absenceTypes: state.reducer.absences.absencesTypes,
});

const mapDispatchToProps = {
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSingleDayAbsence);
