import PropTypes from 'prop-types';

import Popover from '@/components/common/Popover/Popover.jsx';
import { LABELS_MANAGE } from '@/constants/Permissions';
import { ATTENDANCE_LABELS_MANAGE_SHOW } from '@/constants/Roles';
import { DIRECTIONS } from '@/constants/ui.ts';

import './RolesTableLeftRowDetails.scss';

const RolesTableLeftRowDetails = (props) => {
  const { permissions, userPermissions } = props;

  return (
    <table className="table">
      <tbody>
        {permissions.map((permission) => {
          const intend = permission.name.startsWith('Opcja -') ? '10px' : 0;
          if (permission.id === ATTENDANCE_LABELS_MANAGE_SHOW && !userPermissions.permissions.includes(LABELS_MANAGE))
            return null;
          return (
            <tr key={permission.id} className="k-rolesTableLeftRowDetails">
              <td style={{ border: 'none' }}>
                <div className="k-rolesTableLeftRowDetails__textCell">
                  <span
                    className="k-rolesTableLeftRowDetails__text"
                    style={{
                      paddingLeft: intend,
                    }}
                  >
                    {permission.name}
                    {permission.tooltip && (
                      <span className="k-rolesTableLeftRowDetails__tooltip">
                        <Popover
                          content={permission.tooltip}
                          yOffset={10}
                          popoverStyle={{ fontSize: '12px', maxWidth: '300px' }}
                          position={DIRECTIONS.TOP}
                          showShadow={false}
                          centerOnMobile
                          isDark
                          noArrow
                          showOnHover
                          preventPopoverHide
                          childExitOffsetOnHover={-10}
                          popoverExitOffsetOnHover={7}
                        >
                          <span className="material-icons mdTooltip__icon">help</span>
                        </Popover>
                      </span>
                    )}
                  </span>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

RolesTableLeftRowDetails.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RolesTableLeftRowDetails;
