import { defineMessages } from 'react-intl';

export const confirmMessages = defineMessages({
  deleteContractsDescription: {
    id: 'companymanage.employee.confirmStep.deleteContractsDescription',
    defaultMessage:
      'Wybrane kontrakty nie będą widoczne w kontraktach pracowników.\nPo tej operacji czas trwania poprzednich kontraktów zostanie wydłużony do daty zakończenia usuwanych kontraktów.',
  },
  deleteContractsConfirmText: {
    id: 'companymanage.employee.confirmStep.deleteContractsConfirmText',
    defaultMessage: 'Aby potwierdzić akcję usunięcia kontraktów, kliknij ZATWIERDŹ.',
  },
});
