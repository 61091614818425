import * as AT from '@/constants/ActionTypes.js';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_SCHEDULE_INIT_DATA: {
      return action.payload.settings.reduce(
        (sum, item) => ({ ...sum, [item.type]: { value: item.value, restriction: item.restriction } }),
        {},
      );
    }
    case AT.SET_USER_RESTICTIONS_SUCCESSFUL: {
      const newState = { ...state };
      let didSomethingChange = false;
      Object.keys(newState).forEach(type => {
        const disabled = !!(newState[type].restriction && action.payload.includes(newState[type].restriction));
        if (disabled && newState[type].value) {
          newState[type].value = false;
          didSomethingChange = true;
        }
      });
      if (didSomethingChange) return newState;
      return state;
    }
    case AT.TOGGLE_SCHEDULE_SETTING: {
      const newState = { ...state };
      let didSomethingChange = false;
      action.payload.forEach(setting => {
        const { type, value, restriction } = setting;
        if (newState[type] && newState[type].value === value) return state;
        newState[type] = { value, restriction };
        didSomethingChange = true;
      });
      if (didSomethingChange) return newState;
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
