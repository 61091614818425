import { uniqBy } from 'lodash';
import { defineMessages } from 'react-intl';

export const getInitialState = () => ({
  attendances: [],
  attendancesErrors: [],
  date: '',
  employee: null,
  originalShifts: [],
  originalAttandances: [],
  shifts: [],
  shiftsErrors: [],
});

export const messages = defineMessages({
  confirmText: {
    id: 'payroll.PayrollEditShiftsAndAttendancesModal.confirmText',
    defaultMessage: 'Zatwierdź',
  },
  title: {
    id: 'payroll.PayrollEditShiftsAndAttendancesModal.title',
    defaultMessage: 'Edytuj obecności i zmiany',
  },
  attendancesSectionTitle: {
    id: 'payroll.PayrollEditShiftsAndAttendancesModal.attendanceSectionTitle',
    defaultMessage: 'Obecności',
  },
  shiftsSectionTitle: {
    id: 'payroll.PayrollEditShiftsAndAttendancesModal.shiftsSectionTitle',
    defaultMessage: 'Zmiany',
  },
});

const getOriginalShifts = (payoutSetting, detailsWithStartTime) =>
  payoutSetting.type === 'shifts'
    ? detailsWithStartTime
    : uniqBy(
        detailsWithStartTime.reduce(
          (acc, detail) => (detail.matching_shift ? acc.concat(detail.matching_shift) : acc),
          [],
        ),
        'id',
      );

const getShifts = (payoutSetting, detailsWithStartTime) =>
  payoutSetting.type === 'shifts'
    ? detailsWithStartTime
    : uniqBy(
        detailsWithStartTime.reduce(
          (acc, detail) => (detail.matching_shift ? acc.concat(detail.matching_shift) : acc),
          [],
        ),
        'id',
      );

const getShiftsErrors = (payoutSetting, detailsWithStartTime) =>
  payoutSetting.type === 'shifts'
    ? [...Array(detailsWithStartTime.length)].map(() => '')
    : [
        ...Array(
          uniqBy(
            detailsWithStartTime.map(detail => detail.matching_shift),
            'id',
          ).length,
        ),
      ].map(() => '');

const getAttendancesErrors = (payoutSetting, detailsWithStartTime) =>
  payoutSetting.type === 'shifts' ? [] : [...Array(detailsWithStartTime.length)].map(() => '');

export const getStateFromModalObject = modalObject => {
  const { employee, data, payoutSetting } = modalObject;
  const detailsWithStartTime = data.details.filter(
    detail => detail.start_timestamp && !detail.isAbsence && !detail.isOvertimeCollection,
  );

  const date = data.humanDate;

  const originalShifts = getOriginalShifts(payoutSetting, detailsWithStartTime);
  const shifts = getShifts(payoutSetting, detailsWithStartTime);

  const originalAttandances = payoutSetting.type === 'shifts' ? [] : detailsWithStartTime;
  const attendances = payoutSetting.type === 'shifts' ? [] : detailsWithStartTime;

  const shiftsErrors = getShiftsErrors(payoutSetting, detailsWithStartTime);
  const attendancesErrors = getAttendancesErrors(payoutSetting, detailsWithStartTime);

  return {
    detailsWithStartTime,
    date,
    employee,
    originalShifts,
    shifts,
    originalAttandances,
    attendances,
    shiftsErrors,
    attendancesErrors,
  };
};
