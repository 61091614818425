import { connect } from 'react-redux';

import { createLabel, updateLabel } from '@/actions/labels';

import AddAndEditLabelPopoverContent from './AddAndEditLabelPopoverContent';

const mapStateToProps = state => ({
  labels: state.reducer.labels,
});

const mapDispatchToProps = {
  createLabel,
  updateLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAndEditLabelPopoverContent);
