import moment from 'moment/moment';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import KadroSkeleton from '@/components/common/Skeleton/Skeleton.jsx';

import { messages } from './AddShiftTab.messages.js';

interface Props {
  blockades: {
    start_timestamp: string;
    end_timestamp: string;
  }[];
  isLoading: boolean;
}

export const BlockadeMessage = ({ blockades, isLoading }: Props) => {
  const intl = useIntl();
  const sortedBlockades = useMemo(
    () => blockades.sort((a, b) => moment(a.start_timestamp).diff(moment(b.start_timestamp))),
    [blockades],
  );

  return isLoading ? (
    <div>
      <KadroSkeleton width="420px" />
      <KadroSkeleton width="150px" />
    </div>
  ) : (
    <div>
      {intl.formatMessage(messages.blockadesMessage)}{' '}
      {sortedBlockades.map((blockade) => {
        const startDate = moment(blockade.start_timestamp).format('YYYY-MM-DD');
        const endDate = moment(blockade.end_timestamp).format('YYYY-MM-DD');
        const startHour = moment(blockade.start_timestamp).format('HH:mm');
        const endHour = moment(blockade.end_timestamp).format('HH:mm');

        const passesMidnight = startDate !== endDate;

        if (passesMidnight) {
          return (
            <li key={`${startDate}_${startHour}`}>
              {intl.formatMessage(messages.blockadesMessageDatesPassesMidnight, {
                startDate,
                endDate,
                startHour,
                endHour,
              })}
            </li>
          );
        }

        return (
          <li key={`${startDate}_${startHour}`}>
            {intl.formatMessage(messages.blockadesMessageDates, {
              startHour,
              endHour,
              date: startDate,
            })}
          </li>
        );
      })}
    </div>
  );
};
