import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  employee: {
    id: 'payrollLocation.table.employee',
    defaultMessage: 'Pracownik',
  },
  absences: {
    id: 'payrollLocation.table.absences',
    defaultMessage: 'Nieobecności',
  },
  nightWorkTime: {
    id: 'payrollLocation.table.nightWorkTime',
    defaultMessage: 'Godziny nocne',
  },
  timePlanned: {
    id: 'payrollLocation.table.timePlanned',
    defaultMessage: 'Plan',
  },
  timeDiff: {
    id: 'payrollLocation.table.timeDiff',
    defaultMessage: 'Różnica',
  },
  overtime50: {
    id: 'payrollLocation.table.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'payrollLocation.table.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  potentialOvertime: {
    id: 'payrollLocation.table.potentialOvertime',
    defaultMessage: 'Nadgodziny PŚT',
  },
  fillUp: {
    id: 'payrollLocation.table.fillUp',
    defaultMessage: 'Dopełnienia',
  },
  overtimeWeekly: {
    id: 'payrollLocation.table.overtimeWeekly',
    defaultMessage: 'Nadgodziny ŚT',
  },
  bonus: {
    id: 'payrollLocation.table.bonus',
    defaultMessage: 'Bonus',
  },
  payout: {
    id: 'payrollLocation.table.payout',
    defaultMessage: 'Do wypłaty',
  },
  workTime: {
    id: 'payrollLocation.table.workTime',
    defaultMessage: 'Suma godzin',
  },
  warnings: {
    id: 'payrollLocation.table.warnings',
    defaultMessage: 'Alerty',
  },
  status: {
    id: 'payrollLocation.table.status',
    defaultMessage: 'Status',
  },
});

export const filterMessages = defineMessages({
  nightWorkTime: {
    id: 'payrollLocation.filters.nightWorkTime',
    defaultMessage: 'Godziny nocne',
  },
  overtime50: {
    id: 'payrollLocation.filters.overtime50',
    defaultMessage: 'Nadgodziny 50%',
  },
  overtime100: {
    id: 'payrollLocation.filters.overtime100',
    defaultMessage: 'Nadgodziny 100%',
  },
  potentialOvertime: {
    id: 'payrollLocation.filters.potentialOvertime',
    defaultMessage: 'Potencjalne nadg. ŚT.',
  },
  fillUp: {
    id: 'payrollLocation.filters.fillUp',
    defaultMessage: 'Dopełnienia',
  },
  overtimeWeekly: {
    id: 'payrollLocation.filters.overtimeWeekly',
    defaultMessage: 'Nadgodziny ŚT',
  },
  workTime: {
    id: 'payrollLocation.filters.workTime',
    defaultMessage: 'Suma godzin',
  },
});
