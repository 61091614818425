import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  emptyStateHeader: {
    id: 'charts.emptyStateHeader',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
  emptyStateText: {
    id: 'charts.emptyStateText',
    defaultMessage: 'Sprawdź czy zaznaczone są lokalizacje, stanowiska oraz warunki zatrudnienia.',
  },
});
