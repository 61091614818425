import { CHANGE_SINGLE_EMPLOYEE_FILTER } from '@/constants/ActionTypes.js';

import { getScheduleAndAttendancesForLocations } from './index';
import { getOvertimeCollections } from './overtimeCollections/overtimeCollections.js';

export const changeSingleEmployeeFilter = employee => async (dispatch, getState) => {
  const { reducer } = getState();
  const currentRoute = window.location.pathname;
  const { mainDateStore, overtimeCollections, multipleLocationFilter, userPermissions } = reducer;
  const { start, end } = mainDateStore.customDate;

  if (currentRoute !== '/newPayroll') {
    if (!overtimeCollections[employee.id] || overtimeCollections[employee.id].length === 0) {
      await dispatch(getOvertimeCollections(start, end, [employee.id], 'blocking'));
    }

    const { isEmployee } = userPermissions;
    if (!isEmployee && multipleLocationFilter.length > 0 && currentRoute === '/payroll') {
      const locationIds = multipleLocationFilter;
      await dispatch(getScheduleAndAttendancesForLocations(locationIds, start, end, [employee.id], 'blocking'));
    }
  }

  dispatch({
    type: CHANGE_SINGLE_EMPLOYEE_FILTER,
    payload: {
      ...employee,
      name: `${employee.first_name} ${employee.last_name}`,
    },
  });
};
