import { connect } from 'react-redux';

import { changeReportsLocationsFilter } from '@/actions/reports.js';
import TopBarMultipleLocationFilter from '@/components/TopBars/filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.jsx';

const mapStateToProps = state => ({
  userLocations: state.reducer.userLocations,
  selectedLocations: state.reducer.reports.filters.selectedLocations,
});

const mapDispatchToProps = {
  changeFilter: changeReportsLocationsFilter,
};

const TopBarMultipleLocationFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarMultipleLocationFilter);

export default TopBarMultipleLocationFilterContainer;
