import socket from 'socket.io-client';

import { connections } from '@/actions';

import { getActiveAccount } from './accounts/accounts.utils';

export const initSockets = handleTaskEvent => {
  const socketAddress = connections[import.meta.env.VITE_TARGET_API].socket;
  const socketClient = socket(socketAddress, {
    transports: ['websocket'],
    withCredentials: true,
    port: 8080,
    secure: true,
    path: '/sockets/socket.io/',
  });
  socketClient.on('connect', () => {
    socketClient.emit('NEW_CONNECTION_AUTH_WEB', getActiveAccount()?.userId);
  });
  socketClient.on('connect_error', error => {
    console.log('connect_error', error);
  });
  socketClient.on('error', error => {
    console.log('connect_error2', error);
  });
  socketClient.on('disconnect', error => {
    console.log('disconnect', error);
  });
  socketClient.on('TASK_EVENT', result => {
    const data = JSON.parse(result);
    handleTaskEvent(data);
  });
};
