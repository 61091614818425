import { connect } from 'react-redux';

import SingleDayGraphHeader from './SingleDayGraphHeader.jsx';

const mapStateToProps = (state, ownProps) => {
  const visibleEmployees = state.reducer.scheduleState.locations[ownProps.location.id]
    ? state.reducer.scheduleState.locations[ownProps.location.id].visible
    : [];
  return {
    dateStore: state.reducer.mainDateStore,
    recommendedSchedule: state.reducer.recommendedSchedule,
    userPermissions: state.reducer.userPermissions,
    jobtitleFilter: state.reducer.jobtitleFilter,
    visibleEmployees,
    shifts: state.reducer.shifts.data,
  };
};

const mapDispatchToProps = {};
const SingleDayGraphHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(SingleDayGraphHeader);

export default SingleDayGraphHeaderContainer;
