import PropTypes from 'prop-types';

import MDIconButton from '@/components/common/Basic/MDIconButton/MDIconButton';
import { MDTextInput } from '@/components/common/inputs/MDComponents';
import MDMultiSelectPopover from '@/components/common/inputs/MDMultiSelect/MDMultiSelectPopover/MDMultiSelectPopover';
import MDSelectPopover from '@/components/common/inputs/MDSelect/MDSelectPopover/MDSelectPopover';
import { INPUT_MODIFIERS } from '@/constants/modifiers';

import { getMultiSelectLabel } from '../addMassEmployeesModal.helpers';
import { messages } from '../addMassEmployeesModal.messages';

const Y_OFFSET = -16;

const AddMassEmployeesForm = (
  {
    name,
    lastName,
    role,
    employmentCondition,
    employeeLocationOptions,
    employeeJobTitleOptions,
    handleNameChange,
    handleSingleSelectChange,
    selectJobTitles,
    selectLocations,
    employeeNumber,
    companyEmploymentConditions,
    companyRoles,
    validateInput,
    errors,
    deleteRow,
    disabledDeleteIcon,
  },
  { intl },
) => {
  const employmentConditionsOptions = companyEmploymentConditions.map(({ name: employmentConditionName, id }) => ({
    value: id,
    key: employmentConditionName,
  }));

  const rolesOptions = companyRoles.map(({ name: roleName, id }) => ({
    value: id,
    key: roleName,
  }));

  const selectedJobTitles = employeeJobTitleOptions.filter(({ active }) => active);

  const selectedLocations = employeeLocationOptions.filter(({ active }) => active);

  return (
    <div className="k-massEmployeesAddModal__row">
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDTextInput
          id="name"
          placeholder={intl.formatMessage(messages.addMassEmployeesNameInput)}
          modifiers={INPUT_MODIFIERS}
          value={name}
          onChange={e => handleNameChange(e, employeeNumber, errors)}
          onBlur={e => validateInput(e, employeeNumber)}
          errorMessage={errors?.name}
        />
      </div>
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDTextInput
          id="lastName"
          placeholder={intl.formatMessage(messages.addMassEmployeesLastNameInput)}
          modifiers={INPUT_MODIFIERS}
          value={lastName}
          onChange={e => handleNameChange(e, employeeNumber, errors)}
          onBlur={e => validateInput(e, employeeNumber)}
          errorMessage={errors?.lastName}
        />
      </div>
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDMultiSelectPopover
          options={employeeJobTitleOptions}
          onChange={jobTitle => selectJobTitles(jobTitle, employeeNumber)}
          label={getMultiSelectLabel(selectedJobTitles, intl.formatMessage(messages.addMassEmployeesJobTitlesInput))}
        />
      </div>
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDMultiSelectPopover
          options={employeeLocationOptions}
          onChange={location => selectLocations(location, employeeNumber)}
          label={getMultiSelectLabel(selectedLocations, intl.formatMessage(messages.addMassEmployeesLocationsInput))}
        />
      </div>
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDSelectPopover
          options={employmentConditionsOptions}
          withSearch
          onChange={selectedEmploymentCondition =>
            handleSingleSelectChange(selectedEmploymentCondition, employeeNumber, 'employmentCondition')
          }
          defaultValue={employmentCondition}
          yDropdownOffset={Y_OFFSET}
        />
      </div>
      <div className="k-massEmployeesAddModal__inputColumn">
        <MDSelectPopover
          options={rolesOptions}
          withSearch
          onChange={e => handleSingleSelectChange(e, employeeNumber, 'role')}
          defaultValue={role}
          yDropdownOffset={Y_OFFSET}
        />
      </div>
      <div className="k-massEmployeesAddModal__optionColumn">
        <MDIconButton
          icon="delete"
          label={intl.formatMessage(messages.addMassEmployeesDeleteIcon)}
          onClick={() => deleteRow(employeeNumber)}
          disabled={disabledDeleteIcon}
        />
      </div>
    </div>
  );
};
AddMassEmployeesForm.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddMassEmployeesForm.propTypes = {
  name: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  employmentCondition: PropTypes.arrayOf(),
  employeeLocationOptions: PropTypes.arrayOf(),
  employeeJobTitleOptions: PropTypes.arrayOf(),
  handleNameChange: PropTypes.func,
  handleSingleSelectChange: PropTypes.func,
  selectJobTitles: PropTypes.func,
  selectLocations: PropTypes.func,
  employeeNumber: PropTypes.number,
  companyEmploymentConditions: PropTypes.arrayOf(),
  companyRoles: PropTypes.arrayOf(),
  validateInput: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
  }),
  deleteRow: PropTypes.func,
  disabledDeleteIcon: PropTypes.bool,
};

export default AddMassEmployeesForm;
