import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  saveChanges: {
    id: 'common.saveChanges',
    defaultMessage: 'Zapisz zmiany',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Dodaj',
  },
  editEmployee: {
    id: 'employee.editEmployee',
    defaultMessage: 'Edytuj dane pracownika',
  },
  addEmployee: {
    id: 'employee.addEmployee',
    defaultMessage: 'Dodaj pracownika',
  },
  inputFirstName: {
    id: 'companymanage.employees.firstName',
    defaultMessage: 'Imię',
  },
  inputLastName: {
    id: 'companymanage.employees.lastName',
    defaultMessage: 'Nazwisko',
  },
  inputEmail: {
    id: 'companymanage.employees.email',
    defaultMessage: 'Email',
  },
  inputRole: {
    id: 'companymanage.employee.role',
    defaultMessage: 'Rola',
  },
  usedEmail: {
    id: 'employee.emailIsUsed',
    defaultMessage: 'Podany adres email został już użyty',
  },
  usedPin: {
    id: 'employee.pinUsed',
    defaultMessage: 'Podany numer PIN został już użyty ',
  },
  employee: {
    id: 'roles.employee',
    defaultMessage: 'Pracownik',
  },
  manager: {
    id: 'roles.manager',
    defaultMessage: 'Menadżer',
  },
  errorsInForm: {
    id: 'employee.errorsInForm',
    defaultMessage: 'Uzupełnij brakujące dane w sekcjach profilu pracownika oznaczonych kolorem czerwonym.',
  },
  contractStartDateError: {
    id: 'employee.contractStartDateError',
    defaultMessage: 'Kontrakt musi posiadać datę rozpoczęcia',
  },
  employeeDataRetrievalError: {
    id: 'employee.employeeDataRetrievalError',
    defaultMessage:
      'Nie udało się pobrać danych pracownika. Spróbuj odświeżyć stronę.\n W razie dalszych problemów, skontaktuj się z działem wsparcia klienta.',
  },
  emptyStateHeader: {
    id: 'employee.emptyStateHeader',
    defaultMessage: 'Brak wyników do wyświetlenia',
  },
  emptyStateText: {
    id: 'employee.emptyStateText',
    defaultMessage: 'Informacje są dostępne, gdy administrator\n uzupełni dane w tej sekcji.',
  },
});
