import PropTypes from 'prop-types';
import { memo } from 'react';

import { MDMultiSelectFooter, MDMultiSelectHeader } from '../../MDComponents';
import MDMultiSelectPopover from '../MDMultiSelectPopover/MDMultiSelectPopover';

export const KadroMultiSelect = ({
  items,
  messages,
  handlers,
  withoutFooter,
  customHeight,
  multiSelectTestId,
  selectAllTestId,
  disabled,
  withoutSelectAll,
  withoutDeselectAll,
  withoutDropdown,
  isFooterDraggable = false,
  selectAllOptionsWithDefaultOrder,
  readOnly,
  selectedItems,
  withoutHeader,
  autoHeight,
  footerModifiers,
  footerTitleTooltipMessage,
}) => (
  <>
    {!withoutHeader && (
      <MDMultiSelectHeader
        title={messages.headerTitle}
        selectAll={isFooterDraggable ? selectAllOptionsWithDefaultOrder : handlers.selectAll}
        deselectAll={handlers.deselectAll}
        selectAllMessage={messages.selectAll}
        deselectAllMessage={messages.deselectAll}
        testId={selectAllTestId}
        withoutSelectAll={withoutSelectAll}
        withoutDeselectAll={withoutDeselectAll}
      />
    )}
    {!withoutDropdown && (
      <MDMultiSelectPopover
        label={messages.bodyLabel}
        options={items}
        onChange={isFooterDraggable ? handlers.toggleAndInsertAtEnd : handlers.toggle}
        hasFrame
        testId={multiSelectTestId}
        disabled={disabled}
      />
    )}
    {!withoutFooter && (
      <MDMultiSelectFooter
        title={messages.footerTitle}
        selectedItems={!readOnly ? handlers.getSelected() : selectedItems}
        deleteItem={!readOnly && handlers.toggle}
        customHeight={customHeight}
        disabled={disabled}
        shouldSort={false}
        isDragAndDrop={isFooterDraggable}
        setAllItems={!readOnly && handlers.setItems}
        allItems={items}
        readOnly={readOnly}
        autoHeight={autoHeight}
        modifiers={footerModifiers}
        titleTooltipMessage={footerTitleTooltipMessage}
      />
    )}
  </>
);

KadroMultiSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  messages: PropTypes.shape({
    headerTitle: PropTypes.string,
    selectAll: PropTypes.string,
    deselectAll: PropTypes.string,
    bodyLabel: PropTypes.string,
    footerTitle: PropTypes.string,
  }),
  settings: PropTypes.shape({}),
  handlers: PropTypes.shape({
    selectAll: PropTypes.func,
    deselectAll: PropTypes.func,
    toggle: PropTypes.func,
    getSelected: PropTypes.func,
    setItems: PropTypes.func,
    toggleAndInsertAtEnd: PropTypes.func,
  }),
  withoutFooter: PropTypes.bool,
  customHeight: PropTypes.string,
  multiSelectTestId: PropTypes.string,
  selectAllTestId: PropTypes.string,
  disabled: PropTypes.bool,
  withoutSelectAll: PropTypes.bool,
  withoutDeselectAll: PropTypes.bool,
  withoutDropdown: PropTypes.bool,
  isFooterDraggable: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  autoHeight: PropTypes.bool,
  footerModifiers: PropTypes.arrayOf(PropTypes.string),
  footerTitleTooltipMessage: PropTypes.string,
  selectAllOptionsWithDefaultOrder: PropTypes.func,
};

export default memo(KadroMultiSelect);
