import { getExportsConfig } from '@/actions/exports';
import * as AT from '@/constants/ActionTypes';
import { downloadFileFromUrl } from '@/utils/exports/exportHelpers';

import { conn } from '../..';
import { messages } from './exportsHistory.messages';

const getExportsHistorySuccess = data => ({
  type: AT.EXPORTS_HISTORY_GET_SUCCESS,
  payload: data,
});

const getExportsHistoryError = intl => ({
  type: AT.EXPORTS_HISTORY_GET_ERROR,
  notification: {
    title: intl.formatMessage(messages.getExportsHistoryError),
    type: 'error',
  },
});

export const formatExportsHistoryData = (exportHistoryData, exports) =>
  exportHistoryData.reduce((acc, data) => {
    const matchedExport = exports.find(({ exportId }) => exportId === data.export_type);

    if (!matchedExport) return acc;

    const { color, backgroundColor, fileIconText, title } = matchedExport;
    acc.push({ ...data, color, backgroundColor, fileIconText, title });

    return acc;
  }, []);

export const getExportsHistory = (size = 10, offset = 0) => async (dispatch, getState, intl) => {
  try {
    const res = await conn.getExportHistory(size, offset);

    const exports = dispatch(getExportsConfig());

    const newExportsHistory = formatExportsHistoryData(res.data.exportHistory, exports);

    const newData = { ...res.data, exportHistory: newExportsHistory };
    dispatch(getExportsHistorySuccess(newData));
  } catch (err) {
    dispatch(getExportsHistoryError(intl));
  }
};

const deleteExportsHistorySuccess = (id, intl) => ({
  type: AT.EXPORTS_HISTORY_DELETE_SUCCESS,
  payload: id,
  notification: {
    title: intl.formatMessage(messages.deleteExportsHistorySuccess),
    type: 'success',
  },
});

const deleteExportsHistoryError = intl => ({
  type: AT.EXPORTS_HISTORY_DELETE_ERROR,
  notification: {
    title: intl.formatMessage(messages.deleteExportsHistoryError),
    type: 'error',
  },
});

export const hideExportInWidget = id => ({
  type: AT.HIDE_EXPORT_IN_WIDGET,
  payload: { id },
});

export const deleteExportHistoryItem = id => async (dispatch, getState, intl) => {
  try {
    await conn.deleteExportHistoryItem(id);
    dispatch(hideExportInWidget(id));
    dispatch(deleteExportsHistorySuccess(id, intl));
  } catch (err) {
    dispatch(deleteExportsHistoryError(intl));
  }
};

const downloadExportError = intl => ({
  type: AT.EXPORT_DOWNLOAD_ERROR,
  notification: {
    title: intl.formatMessage(messages.downloadExportError),
    type: 'error',
  },
});

const downloadExportSuccess = exportId => ({
  type: AT.EXPORT_DOWNLOAD_SUCCESS,
  payload: { id: exportId },
});

export const downloadExport = id => async (dispatch, getState, intl) => {
  try {
    const res = await conn.downloadExport(id);
    dispatch(hideExportInWidget(id));
    downloadFileFromUrl(res.data.url);
    dispatch(downloadExportSuccess(id));
  } catch (err) {
    dispatch(downloadExportError(intl));
  } finally {
    dispatch(hideExportInWidget(id));
  }
};

export const changeExportsHistoryPagination = (currentPage, numberOfItemsPerPage) => ({
  type: AT.CHANGE_EXPORTS_HISTORY_PAGINATION,
  payload: { currentPage, numberOfItemsPerPage },
});

export const changeExportsHistoryData = (newCurrentPage, newNumberOfItemsPerPage) => async dispatch => {
  const offset = (newCurrentPage - 1) * newNumberOfItemsPerPage;
  dispatch(changeExportsHistoryPagination(newCurrentPage, newNumberOfItemsPerPage));
  dispatch(getExportsHistory(newNumberOfItemsPerPage, offset));
};

export const removeAllExportsFromWidget = () => ({
  type: AT.REMOVE_ALL_EXPORTS_FROM_WIDGET,
});
