export enum ScheduleUISettingsEnum {
  AVAILABILITY_VIEW = 'availabilityView',
  BUDGET = 'hoursBalance',
  BUDGET_METRICS = 'budgetMetrics',
  EMPLOYEES_AMOUNT = 'employeesAmount',
  EVENTS = 'events',
  HOLIDAYS = 'holidays',
  LOAN_EMPLOYEES_PROPOSALS = 'loanEmployeesProposals',
  LOANED_EMPLOYEES = 'loanedEmployees',
  NOTE = 'note',
  OPEN_SHIFTS = 'openShifts',
  POSITIONS_VIEW = 'positionsView',
  PRIORITY = 'priority',
  SHIFTS_BETA_VIEW = 'shiftsBetaView',
  SHIFTS_VIEW = 'shiftsView',
  SHOW_EMPLOYEES_WITHOUT_SHIFTS = 'showEmployeesWithoutShifts',
  SHOW_SHIFTS_FROM_OTHER_LOCATIONS = 'showShiftsFromOtherLocations',
  SHOW_SUPPLEMENTARY_EMPLOYEES_WITHOUT_SHIFT = 'showSupplementaryEmployeesWithoutShift',
  SUPPLEMENTARY_EMPLOYEES = 'supplementaryEmployees',
  TEMPLATES_VIEW = 'templatesView',
  TRADE_SHIFTS = 'tradeShifts',
  WEATHER = 'weather',
  WEATHER_FORECAST = 'weatherForecast',
  WORKING_RULES = 'workingRules',
}

export enum DisplayModeEnum {
  AVAILABILITIES = 'availabilities',
  POSITIONS = 'positions',
  SCHEDULE = 'schedule',
  TEMPLATES = 'templates',
}
