/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { shouldShiftConnectWithAbsence } from '@/components/scheduler/ScheduleTable/ScheduleShiftBlock/ScheduleShiftBlock.helpers';
import { ADD_SHIFT_MODAL } from '@/constants/modalTypes';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { getJobTitleIdsFromContracts, getRelevantContractForDate } from '@/utils/contracts';
import { findJobTitleForShift, getScheduleShiftTitle, getSingleDayScheduleShiftBlockColor } from '@/utils/shiftHelpers';

import ScheduleSingleDayShiftBlock from '../ScheduleSingleDayShiftBlock/ScheduleSingleDayShiftBlock';

const ScheduleSingleDayShift = props => {
  const {
    shift,
    currentLocationId,
    restrictions,
    startShiftDrag,
    stopShiftDrag,
    editShift,
    showModal,
    employeeId,
    showDeleteShiftConfirmModal,
    userEmployees,
    userJobTitles,
    employeeContracts,
    showShiftsFromOtherLocations,
    absenceForDate,
  } = props;

  if (!shift) return null;

  const isEditingScheduleDisabled = restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const { working_hours: workingHours, draft, job_title, location, date } = shift;
  const [from, to] = workingHours.split('-');
  const shouldConnectWithAbsence = useMemo(
    () => absenceForDate && shouldShiftConnectWithAbsence(absenceForDate, shift),
    [absenceForDate, shift],
  );
  const isAbsenceDraft = useMemo(
    () => shouldConnectWithAbsence && absenceForDate?.status === 'draft',
    [shouldConnectWithAbsence, absenceForDate],
  );
  const otherLocation = currentLocationId !== location.id;
  const shiftJobTitle = useMemo(() => findJobTitleForShift(shift, userJobTitles), [shift.job_title.id, userJobTitles]);
  const jobTitleNotAssigned = useMemo(() => {
    const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
    const employeeJobTitleIds = getJobTitleIdsFromContracts([relevantContract]);

    return !employeeJobTitleIds.includes(String(shift.job_title.id));
  }, [employeeContracts, shift.job_title.id]);
  const shiftTitle = shiftJobTitle ? getScheduleShiftTitle(shift, userJobTitles) : '';

  const color = useMemo(
    () =>
      getSingleDayScheduleShiftBlockColor(
        otherLocation,
        isEditingScheduleDisabled,
        job_title.color,
        shouldConnectWithAbsence,
      ),
    [otherLocation, isEditingScheduleDisabled, job_title.color, shouldConnectWithAbsence],
  );

  const editEmployeeShift = useCallback(
    async working_hours => {
      const newShift = { ...shift, draft: true, working_hours };
      await editShift({ id: employeeId }, newShift);
    },
    [shift, employeeId],
  );

  const showEditShiftModal = useCallback(() => {
    const employee = userEmployees.find(({ id }) => id === employeeId);
    showModal(ADD_SHIFT_MODAL, {
      employee,
      shift,
      location: shift.location,
    });
  }, [shift, employeeId]);

  const deleteEmployeeShift = useCallback(() => {
    showDeleteShiftConfirmModal(employeeId, shift.id);
  }, [employeeId, shift]);

  if (!showShiftsFromOtherLocations && otherLocation) return null;

  return (
    <ScheduleSingleDayShiftBlock
      title={shiftTitle}
      from={from}
      date={date}
      to={to}
      draft={draft}
      hasComment={shift.comment?.length > 0}
      color={color}
      isInvalid={!shiftJobTitle || jobTitleNotAssigned}
      otherLocation={otherLocation}
      isEditingScheduleDisabled={isEditingScheduleDisabled}
      editEmployeeShift={editEmployeeShift}
      onDragStart={() => startShiftDrag(shift.id, shift.employee.id, from, to, date)}
      onDragEnd={stopShiftDrag}
      showEditShiftModal={showEditShiftModal}
      deleteEmployeeShift={deleteEmployeeShift}
      shouldConnectWithAbsence={shouldConnectWithAbsence}
      isAbsenceDraft={isAbsenceDraft}
      shiftId={shift.id}
      employeeId={employeeId}
      jobTitleNotAssigned={jobTitleNotAssigned}
    />
  );
};

ScheduleSingleDayShift.propTypes = {
  shift: PropTypes.shape({
    id: PropTypes.string,
    employee: PropTypes.shape({
      id: PropTypes.string,
    }),
    location: PropTypes.shape({
      id: PropTypes.string,
    }),
    working_hours: PropTypes.string,
    draft: PropTypes.bool,
    job_title: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      color: PropTypes.string,
    }),
    date: PropTypes.string,
    comment: PropTypes.string,
  }),
  restrictions: PropTypes.arrayOf(PropTypes.string),
  editShift: PropTypes.func,
  startShiftDrag: PropTypes.func,
  stopShiftDrag: PropTypes.func,
  currentLocationId: PropTypes.string,
  showModal: PropTypes.func,
  employeeId: PropTypes.string,
  showDeleteShiftConfirmModal: PropTypes.func,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  employeeContracts: PropTypes.arrayOf(PropTypes.shape({})),
  showShiftsFromOtherLocations: PropTypes.bool,
  absenceForDate: PropTypes.shape({
    absence_hours: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default ScheduleSingleDayShift;
