import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editLocation: {
    id: 'companymanage.locations.editLocation',
    defaultMessage: 'Edytuj lokalizacje',
  },
  addLocation: {
    id: 'companymanage.locations.addLocation',
    defaultMessage: 'Dodaj lokalizacje',
  },
  locationName: {
    id: 'companymanage.locations.locationName',
    defaultMessage: 'Nazwa lokalizacji',
  },
  city: {
    id: 'companymanage.locations.city',
    defaultMessage: 'Miasto',
  },
  address: {
    id: 'companymanage.locations.address',
    defaultMessage: 'Nazwa i numer ulicy',
  },
  invalidAddress: {
    id: 'validation.locations.invalidAddress',
    defaultMessage: 'Wpisz nazwę i numer ulicy, a następnie wybierz adres z listy.',
  },
  noAddressFound: {
    id: 'validation.locations.noAddressFound',
    defaultMessage: 'Wpisz nazwę i numer ulicy, a następnie wybierz adres z listy. Nie możemy odnaleźć wprowadzonego w tym polu adresu.',
  },
  invalidDefaultAddress: {
    id: 'validation.locations.invalidDefaultAddress',
    defaultMessage: 'Proszę potwierdzić wprowadzony wcześniej adres poprzez edycję. Wpisz nazwę i numer ulicy, a następnie wybierz adres z listy.',
  },
  additionalInformation: {
    id: 'companymanage.locations.additionalInformation',
    defaultMessage: 'Dodatkowe informacje',
  },
  color: {
    id: 'companymanage.locations.color',
    defaultMessage: 'Kolor',
  },
});
