import { connect } from 'react-redux';

import { getPayrollViewData } from '@/actions/payroll/payroll';

import PayrollLoadDataButton from './PayrollLoadDataButton';

const mapStateToProps = state => ({
  isLoadPayrollButtonEnabled: state.reducer.payroll.isLoadButtonEnabled,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  companyRoles: state.reducer.roles.companyRoles,
  companySettings: state.reducer.currentCompany.settings,
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  userPermissions: state.reducer.userPermissions,
  payrollLoanedEmployees: state.reducer.payrollLoanedEmployees.data,
});

const mapDispatchToProps = {
  getPayrollViewData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollLoadDataButton);
