import { connect } from 'react-redux';

import { hideModal } from '../../../actions/uiState';
import { PREVIEW_MODAL } from '../../../constants/modalTypes';
import PreviewModal from './PreviewModal';

const mapStateToProps = ({ reducer }) => ({
  show: reducer.uiState.showModal === PREVIEW_MODAL,
  modalObject: reducer.uiState.modalObject[PREVIEW_MODAL],
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);
