import PropTypes from 'prop-types';

import Tooltip from '@/components/common/Tooltip/Tooltip';

const KebabMenuWrapper = ({ children, disabled, title }) => {
  if (!disabled && !title) return children;
  return (
    <Tooltip className="k-tooltip__content--noMaxWidth" content={title} yOffset={15}>
      {children}
    </Tooltip>
  );
};

KebabMenuWrapper.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default KebabMenuWrapper;
