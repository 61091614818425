import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import ButtonWithDropdown from '@/components/common/Basic/ButtonWithDropdown/ButtonWithDropdown';
import { FREEMIUM_EVENT_ADD_ABSENCE, FREEMIUM_EVENT_ADD_ATTENDANCE } from '@/constants/intercom';
import { ABSENCE_ADD_MODAL, ADD_ATTENDANCE_MODAL, ADD_SHIFT_MODAL } from '@/constants/modalTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import {
  FREEMIUM_HIDE_ABSENCES_VIEW,
  FREEMIUM_HIDE_ATTENDANCE_VIEW,
  SCHEDULE_EDIT_DISABLE,
} from '@/constants/Restrictions';
import { checkAbsenceEditDisabled } from '@/utils/absenceHelpers';
import { isAttendanceEditDisabled } from '@/utils/attendanceHelpers';

const messages = defineMessages({
  addShift: {
    id: 'newPayroll.addRecordButton.addShift',
    defaultMessage: 'Dodaj zmianę',
  },
  addAttendance: {
    id: 'newPayroll.addRecordButton.addAttendance',
    defaultMessage: 'Dodaj obecność',
  },
  addAbsence: {
    id: 'newPayroll.addRecordButton.addAbsence',
    defaultMessage: 'Dodaj urlop',
  },
  editBlocked: {
    id: 'payrollTableOptions.editBlocked',
    defaultMessage: 'Aby wprowadzić modyfikacje odblokuj tryb edycji klikając w ikonkę ',
  },
});

const PayrollAddRecordButton = (
  {
    payrollMode,
    showModal,
    selectedEmployee,
    date,
    addShiftForPayroll,
    userPermissions,
    companyRoles,
    currentUser,
    multipleLocationFilter,
  },
  { intl },
) => {
  const isBlocked = payrollMode === PAYROLL_MODES.readonly;
  const { restrictions } = userPermissions;
  const hasSelectedEmployeeAnyOfSelectedLocations = useMemo(
    () => selectedEmployee.locations?.some(location => multipleLocationFilter.includes(location.id)),
    [selectedEmployee, multipleLocationFilter],
  );
  const isScheduleEditBlocked = useMemo(
    () => !hasSelectedEmployeeAnyOfSelectedLocations || restrictions.includes(SCHEDULE_EDIT_DISABLE),
    [restrictions, hasSelectedEmployeeAnyOfSelectedLocations],
  );
  const isAttendanceEditBlocked = useMemo(
    () => isAttendanceEditDisabled(userPermissions, currentUser, selectedEmployee, companyRoles),
    [userPermissions, currentUser, selectedEmployee, companyRoles, hasSelectedEmployeeAnyOfSelectedLocations],
  );
  const isAbsencesEditBlocked = useMemo(
    () =>
      !hasSelectedEmployeeAnyOfSelectedLocations ||
      checkAbsenceEditDisabled(restrictions, selectedEmployee, currentUser.user.id),
    [restrictions, selectedEmployee, currentUser, hasSelectedEmployeeAnyOfSelectedLocations],
  );

  const tooltipContent = (
    <div className="k-payrollTableOptions__label">
      {intl.formatMessage(messages.editBlocked)}
      <i className="material-icons-outlined">lock_open</i>
    </div>
  );

  const options = [];
  if (!isScheduleEditBlocked) {
    options.push({
      title: intl.formatMessage(messages.addShift),
      onClick: () =>
        showModal(ADD_SHIFT_MODAL, {
          employee: selectedEmployee,
          addShiftWithLocationAndDateSelect: true,
          preApproved: true,
          hideRepeatShift: true,
          customAddShift: (shift, addAttendanceForShift) => addShiftForPayroll(shift, addAttendanceForShift),
          showAddAttendanceForShift: !userPermissions.restrictions.includes(FREEMIUM_HIDE_ATTENDANCE_VIEW),
          date,
        }),
    });
  }
  if (!isAttendanceEditBlocked) {
    options.push({
      title: intl.formatMessage(messages.addAttendance),
      onClick: () => showModal(ADD_ATTENDANCE_MODAL),
      freemiumRestrictions: [FREEMIUM_HIDE_ATTENDANCE_VIEW],
      freemiumIntercomEvent: FREEMIUM_EVENT_ADD_ATTENDANCE,
    });
  }
  if (!isAbsencesEditBlocked) {
    options.push({
      title: intl.formatMessage(messages.addAbsence),
      onClick: () =>
        showModal(ABSENCE_ADD_MODAL, {
          employee: {
            name: `${selectedEmployee.first_name} ${selectedEmployee.last_name}`,
            id: selectedEmployee.id,
          },
        }),
      freemiumRestrictions: [FREEMIUM_HIDE_ABSENCES_VIEW],
      freemiumIntercomEvent: FREEMIUM_EVENT_ADD_ABSENCE,
    });
  }

  if (options.length)
    return (
      <ButtonWithDropdown
        options={options}
        xOffset={57}
        arrowPosition="left"
        color="orange"
        disabled={isBlocked}
        tooltipContent={tooltipContent}
        showTooltip={isBlocked}
        restrictions={restrictions}
      />
    );
  return null;
};

PayrollAddRecordButton.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
PayrollAddRecordButton.propTypes = {
  payrollMode: PropTypes.string,
  showModal: PropTypes.func,
  selectedEmployee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    isLoanedEmployee: PropTypes.bool,
  }),
  date: PropTypes.string,
  addShiftForPayroll: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
  multipleLocationFilter: PropTypes.arrayOf(PropTypes.string),
};

export default PayrollAddRecordButton;
