import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  absencesViewTableNr: {
    id: 'absencesView.table.nr',
    defaultMessage: 'Nr',
  },
  absencesViewTableEmployeeName: {
    id: 'absencesView.table.employeeName',
    defaultMessage: 'Pracownik',
  },
  absencesViewTableTypeName: {
    id: 'absencesView.table.typeName',
    defaultMessage: 'Typ urlopu',
  },
  absencesViewTableFrom: {
    id: 'absencesView.table.from',
    defaultMessage: 'Data od',
  },
  absencesViewTableTo: {
    id: 'absencesView.table.to',
    defaultMessage: 'Data do',
  },
  absencesViewTableRecentApprovedBy: {
    id: 'absencesView.table.recentApprovedBy',
    defaultMessage: 'Akceptujący',
  },
  absencesViewTableStatusName: {
    id: 'absencesView.table.statusName',
    defaultMessage: 'Status',
  },
  absencesViewEmptyTable: {
    id: 'absencesView.emptyTable',
    defaultMessage: 'Brak wniosków w wybranym przedziale czasowym',
  },
});
