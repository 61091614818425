import PropTypes from 'prop-types';

import KadroMinimalistTable from '@/components/common/KadroMinimalistTable/KadroMinimalistTable.jsx';

import { columns } from './constants.js';
import { mapDataForTable } from './utils.js';

const WorkTimeTable = ({ rawData }, { intl }) => {
  const data = mapDataForTable(rawData);
  const formattedColumns = columns.map(column => ({
    ...column,
    Header: column.header ? intl.formatMessage(column.header) : '',
  }));

  return <KadroMinimalistTable data={data} columns={formattedColumns} />;
};

WorkTimeTable.contextTypes = {
  intl: PropTypes.shape({}),
};

WorkTimeTable.propTypes = {
  rawData: PropTypes.shape({}),
};

export default WorkTimeTable;
