import { sortByKey } from '@/utils/arrayHelpers';

export interface Item {
  value: string;
  label: string;
  sortValue?: string;
  color?: string;
  active?: boolean;
  tooltipError?: string;
  classNames?: string;
}

export const getRelevantItems = (selectedItems: Item[], shouldSort: boolean) => {
  if (!shouldSort) return selectedItems;
  const sortKey = selectedItems.length > 0 && selectedItems[0].sortValue ? 'sortValue' : 'label';
  return sortByKey([...selectedItems], sortKey, true);
};
export const addClassNamesToSelectedItems = (selectedItems: Item[], shouldSort: boolean, classNames: string): Item[] =>
  getRelevantItems(selectedItems, shouldSort).map(item => ({
    ...item,
    classNames,
  }));

export const changeItemsOrder = (items: Item[], draggedItem: Item, dropIndex: number) => {
  const updatedItems = [...items];
  const draggedIndex = items.findIndex(({ value }) => value === draggedItem.value);

  updatedItems.splice(draggedIndex, 1);
  updatedItems.splice(dropIndex, 0, draggedItem);
  return updatedItems;
};

export const hideDraggedElement = (items: Item[], dragItem: Item, classNames: string): Item[] =>
  items.map(prevItem => (prevItem.value === dragItem.value ? { ...dragItem, classNames } : prevItem));
