import PropTypes from 'prop-types';

import MDTableSummary from '@/components/common/MDTable/Summary/MDTableSummary';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { PayrollMode, PayrollSummary as PayrollSummaryInterface, PayrollSummaryItem, TimeFormatType } from '@/types';

import { getFilteredSummaryData } from './PayrollSummary.helpers';

import './PayrollSummary.scss';

interface Props {
  summary: PayrollSummaryInterface;
  mode: PayrollMode;
  timeFormatType: TimeFormatType;
  isFetching: boolean;
  summaryValues: PayrollSummaryItem[];
  restrictions: string[];
}

const PayrollSummary = (
  { summary, mode, timeFormatType, isFetching, summaryValues, restrictions }: Props,
  { intl },
) => {
  if (Object.keys(summary).length === 0 || mode === PAYROLL_MODES.editable) return null;

  const summaryData = getFilteredSummaryData(summary, timeFormatType, summaryValues, intl, restrictions);

  return <MDTableSummary summary={summaryData} isFetching={isFetching} />;
};

// @ts-ignore
PayrollSummary.contextTypes = {
  intl: PropTypes.shape({}),
};

export default PayrollSummary;
