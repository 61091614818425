import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { MDProgressRing } from '@/components/common/inputs/MDComponents.jsx';

import { getDetailsForExportIcon } from './SideBarExportIcon.helpers';

import './SideBarExportIcon.scss';

const SideBarExportIcon = ({ pendingExports }) => {
  const relevantExports = useMemo(() => pendingExports.filter(e => !e.isTemplate), [pendingExports]);
  const { content, progress } = getDetailsForExportIcon(relevantExports);

  if (!content) {
    return <i className="k-sideBarMenu__itemIcon material-icons">file_download</i>;
  }

  return (
    <div className={classnames('k-sideBarExportIcon', { 'k-sideBarExportIcon--inProgress': progress !== 100 })}>
      <MDProgressRing radius={14} stroke={2} progress={progress}>
        <div className="k-sideBarExportIcon__content">{content}</div>
      </MDProgressRing>
    </div>
  );
};

SideBarExportIcon.propTypes = {
  pendingExports: PropTypes.arrayOf(
    PropTypes.shape({
      isTemplate: PropTypes.bool,
    }),
  ),
};

export default SideBarExportIcon;
