import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import AccountSwitcher from '@/components/settings/AccountSwitcher/AccountSwitcher.redux';
import SettingsSection from '@/components/settings/SettingsSection/SettingsSection';
import { ACCOUNT_SWITCHER_USER_SETTINGS_VIEW } from '@/constants/Permissions';
import AvatarEditor from '@/containers/common/AvatarEditorContainer.js';
import { getLoggedAccounts } from '@/utils/accounts/accounts.utils';

import ChangePasswordBlock from '../ChangePasswordBlock/ChangePasswordBlock.redux.js';
import LanguageSettings from '../LanguageSettings/LanguageSettings.redux';

import './EmployeeSettingsView.scss';

const EmployeeSettingsView = (props) => {
  const { pin } = props.user;
  const [accounts, setAccounts] = useState(getLoggedAccounts);

  return (
    <div className="k-wrapper animated fadeInRight">
      <FeatureWrapper permission={ACCOUNT_SWITCHER_USER_SETTINGS_VIEW}>
        {accounts.length > 0 && (
          <div className="row">
            <div className="col-lg-12">
              <SettingsSection canSave={false} title="Twoje konta">
                <AccountSwitcher accounts={accounts} setAccounts={setAccounts} />
              </SettingsSection>
            </div>
          </div>
        )}
      </FeatureWrapper>
      <div className="row">
        <div className="col-lg-12">
          <div className="k-panel panel-setting">
            <h2 className="k-panel__title">
              <FormattedMessage id="settings.userData.employeePIN" defaultMessage="Twoje dane" />
            </h2>
            <fieldset className="form-group">
              <AvatarEditor />
              <span>
                <FormattedMessage id="settings.userData.rcpData" defaultMessage="Dane do rejestracji czasu pracy" />
              </span>
              <div className="k-employeeAuthData">
                <div className="k-employeeAuthData__item">
                  <span className="k-employeeAuthData__itemTitle">
                    <FormattedMessage id="settings.userData.pinCode" defaultMessage="Kod PIN" />
                  </span>
                  <span className="k-employeeAuthData__pin">{pin}</span>
                </div>

                <div className="k-employeeAuthData__item">
                  <span className="k-employeeAuthData__itemTitle">
                    <FormattedMessage id="settings.userData.qrCode" defaultMessage="Kod QR" />
                  </span>
                  <Button
                    onClick={() => {
                      props.exportSingleQRCode(props.user);
                    }}
                    modifiers="orange small"
                  >
                    <FormattedMessage id="settings.employee.getQRCode" defaultMessage="Pobierz" />
                  </Button>
                </div>
              </div>
            </fieldset>
            <ChangePasswordBlock />
            <LanguageSettings />
          </div>
        </div>
      </div>
    </div>
  );
};

EmployeeSettingsView.contextTypes = {
  intl: PropTypes.shape({}),
};

EmployeeSettingsView.propTypes = {
  user: PropTypes.shape({
    pin: PropTypes.string,
  }),
  exportSingleQRCode: PropTypes.func,
};

export default EmployeeSettingsView;
