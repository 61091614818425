import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import { ADD_DEVICE_MODAL } from '@/constants/modalTypes.js';
import { COMPANY_MANAGE_DEVICES_HIDE_DELETE } from '@/constants/Restrictions.js';

const DevicesButtonBar = props => (
  <ButtonBar>
    <FeatureWrapper restriction={COMPANY_MANAGE_DEVICES_HIDE_DELETE}>
      <Button
        onClick={props.massDeleteDevicesConfirm}
        modifiers="reverse-red medium inline floatright"
        disabled={!props.listsUi.devices.selected.length}
      >
        <i className="material-icons">delete</i>
        <FormattedMessage id="companyManage.lists.removeSelected" defaultMessage="Usuń zaznaczone" />
      </Button>
    </FeatureWrapper>
    <Button
      onClick={() => {
        props.showModal(ADD_DEVICE_MODAL);
      }}
      modifiers="blue medium inline pushLeft"
    >
      <FormattedMessage id="companyManage.devices.add" defaultMessage="Dodaj urządzenie" />
    </Button>
  </ButtonBar>
);

DevicesButtonBar.propTypes = {
  listsUi: PropTypes.shape({
    devices: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  showModal: PropTypes.func,
  massDeleteDevicesConfirm: PropTypes.func,
};

export default DevicesButtonBar;
