import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  countOnlyDaysWithShifts: {
    id: 'absences.countOnlyDaysWithShifts',
    defaultMessage: 'Planuj urlop na podstawie zmian w grafiku pracy',
  },
  countOnlyDaysWithShiftsTooltip: {
    id: 'absences.countOnlyDaysWithShiftsTooltip',
    defaultMessage:
      'Zaznacz tę opcje, jeśli długość urlopu ma zostać wyliczona na podstawie zaplanowanych zmian w grafiku pracy. Jeśli pozostawisz tę opcje odznaczoną, w dniach, w których nie ma zmiany w grafiku długość urlopu zostanie obliczona na podstawie tygodniowego wymiaru czasu',
  },
  absencesOmitWeekendsCheckbox: {
    id: 'absences.omitWeekends.checkbox',
    defaultMessage: 'Pomiń weekendy',
  },
  absenceOmitHolidays: {
    id: 'absences.absenceOmitHolidays',
    defaultMessage:
      'Zaznacz tę opcję, jeżeli chcesz pominąć dni wolne od pracy w ramach tego wniosku i ewidencji godzin pracy.',
  },
  absencesOmitHolidaysCheckbox: {
    id: 'absences.omitHolidays.checkbox',
    defaultMessage: 'Pomiń dni wolne od pracy',
  },
  absenceOmitWeekends: {
    id: 'absences.absenceOmitWeekends',
    defaultMessage: 'Zaznacz tę opcję, jeżeli chcesz pominąć weekendy w ramach tego wniosku i ewidencji godzin pracy.',
  },
});
