import { connect } from 'react-redux';

import EmployeesEditMasAbsenceLimitsField from './EmployeesEditMasAbsenceLimitsField.jsx';

const mapStateToProps = state => ({
  absencesTypes: state.reducer.absences.absencesTypes,
});

const EmployeesEditMasAbsenceLimits = connect(mapStateToProps)(EmployeesEditMasAbsenceLimitsField);

export default EmployeesEditMasAbsenceLimits;
