import classnames from 'classnames';

import ExportModalListElement from '@/components/common/exports/ExportModalListElement/ExportModalListElement';
import FormatIcon from '@/components/common/exports/FormatIcon';

interface Format {
  backgroundColor: string;
  color: string;
  text: string;
  title?: string;
  subtitle?: string;
  description?: string;
  options: unknown[];
}

interface Props {
  chosenFormat: null | number;
  formats: Format[];
  showAsList?: boolean;
  onFormatClick: (i: number) => void;
}

const ExportFileFormatStep = ({ chosenFormat, formats, showAsList, onFormatClick }: Props) => {
  const className = classnames('exportIcons', {
    'exportIcons--list': showAsList,
  });
  return (
    <div className={className}>
      {formats.map((format, i) =>
        showAsList ? (
          <ExportModalListElement
            key={i}
            text={format.text}
            onClick={() => onFormatClick(i)}
            isSelected={chosenFormat === i}
            title={format.title}
            description={format.description}
          />
        ) : (
          <FormatIcon
            key={i}
            text={format.text}
            subtitle={format.subtitle}
            color={format.color}
            backgroundColor={format.backgroundColor}
            selected={chosenFormat === i}
            onClick={() => {
              onFormatClick(i);
            }}
          />
        ),
      )}
    </div>
  );
};
export default ExportFileFormatStep;
