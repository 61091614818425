import { useMemo } from 'react';

import { useAppSelector } from '@/redux-store';
import {
  selectCurrentUserAvatar,
  selectCurrentUserLocations,
  selectCurrentUserNames,
  selectCurrentUserRoleId,
  selectIsCurrentUserOwner,
} from '@/selectors/currentUser.selectors';
import { selectCompanyRoleById } from '@/selectors/roles.selectors';
import { getAmountOfHiddenItems } from '@/utils/array/array.helpers';

export const useMultiAccountsSwitcher = () => {
  const avatar = useAppSelector(selectCurrentUserAvatar);
  const names = useAppSelector(selectCurrentUserNames);
  const roleId = useAppSelector(selectCurrentUserRoleId);
  const isOwner = useAppSelector(selectIsCurrentUserOwner);
  const role = useAppSelector(selectCompanyRoleById(roleId));
  const locations = useAppSelector(selectCurrentUserLocations);
  const additionalLocationsAmount = useMemo(() => getAmountOfHiddenItems(locations), [locations]);
  const sortedLocations = useMemo(() => locations.sort((a, b) => a.name.localeCompare(b.name)), [locations]);

  return {
    additionalLocationsAmount,
    avatar,
    names,
    locationName: sortedLocations[0]?.name,
    roleName: role?.name,
    isOwner,
  };
};
