import { connect } from 'react-redux';

import { openApprovalTradeShiftModal, openCoworkerTradeShiftModal } from '@/actions/uiState';

import ScheduleTableTradeShift from './ScheduleTableTradeShift.jsx';

const mapStateToProps = (state, ownProps) => ({
  mainDateStore: state.reducer.mainDateStore,
  currentUser: state.reducer.currentUser,
  userEmployees: state.reducer.userEmployees,
  tradeShifts: state.reducer.tradeShifts,
  userPermissions: state.reducer.userPermissions,
  rowSticky: !!state.reducer.schedule.stickyRows.tradeShiftsSticky,
  selectedJobTitles: state.reducer.jobtitleFilter.selectedJobtitles,
  rowSelected: !!state.reducer.schedule.tradeShiftsRowSelect[ownProps.locationId],
});

const mapDispatchToProps = {
  openCoworkerTradeShiftModal,
  openApprovalTradeShiftModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableTradeShift);
