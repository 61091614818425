import PropTypes from 'prop-types';

const ShadowLoader = props => {
  if ((!props.showShadow && !props.showLoading && !props.show) || props.isInit) {
    return <div />;
  }
  let loader = '';
  if (props.showLoading || props.show) {
    loader = (
      <div className="k-spinner">
        <img src="/img/loading-spin.svg" alt="loader" />
      </div>
    );
  }
  return <div className="modal-backdrop allblock in">{loader}</div>;
};

ShadowLoader.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  showShadow: PropTypes.bool,
  isInit: PropTypes.bool,
  show: PropTypes.bool,
};

export default ShadowLoader;
