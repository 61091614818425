import axios from 'axios';

import { decreaseLoaderCounter, increaseLoaderCounter } from '@/actions/uiState.js';
import store from '@/redux-store/store';
import { housecall } from '@/utils/houseCallQueue.helpers';

const housecallData = {
  concurrency: import.meta.env.VITE_NODE_ENV === 'development' ? 100 : 5,
  cooldown: import.meta.env.VITE_NODE_ENV === 'development' ? 0 : 200,
};

class KadroBaseConnections {
  constructor(connections, authentication) {
    this.base_url = connections.core;
    this.workerAddress = connections.workers;
    this.authentication = authentication;
    this.auth_token = '';
    this.queue = housecall(housecallData);
    this.counter = {
      regular: 0,
      blocking: 0,
      init: 0,
    };
    this.conn = axios.create({
      timeout: 100 * 1000,
      withCredentials: true,
    });
    this.conn.defaults.headers.authV2 = true;
    this.conn.interceptors.response.use(
      (response) => response,
      (error) => {
        const { status } = error.response;
        if (status === 401 && this.authorizationErrorHandler) {
          this.authorizationErrorHandler(error, store.dispatch);
        }
        return Promise.reject(error);
      },
    );
  }

  setUserId(userId) {
    this.conn.defaults.headers.userId = userId;
  }

  getJSON(url, type = 'regular', payload = {}) {
    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .get(url, payload, { type })
          .then((response) => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch((err) => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  postJSON(url, payload, type = 'regular') {
    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .post(url, payload, { type })
          .then((response) => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch((err) => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  putJSON(url, payload, type = 'regular') {
    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .put(url, payload, { type })
          .then((response) => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch((err) => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  deleteJSON(url, payload, type = 'regular') {
    store.dispatch(increaseLoaderCounter(type));
    return new Promise((resolve, reject) => {
      this.queue.push(() =>
        this.conn
          .delete(url, payload, { type })
          .then((response) => {
            store.dispatch(decreaseLoaderCounter(type));
            resolve(response);
          })
          .catch((err) => {
            store.dispatch(decreaseLoaderCounter(type));
            reject(err);
          }),
      );
    });
  }

  all(arr) {
    return axios.all(arr);
  }

  setAuthorizationErrorHandler(errorHandler) {
    this.authorizationErrorHandler = errorHandler;
  }
}

export default KadroBaseConnections;
