import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './ScheduleTableContentBlocker.scss';

const ScheduleTableContentBlocker = ({
  locationShiftsBlockDate,
  availabilitiesBlockDate,
  scheduleViewMode,
  dateArray,
}) => {
  const locationBlockDate =
    (scheduleViewMode === 'schedule' && locationShiftsBlockDate) ||
    (scheduleViewMode === 'availabilities' && availabilitiesBlockDate);
  if (!locationBlockDate) return null;
  const blockWholeView = locationBlockDate >= dateArray[dateArray.length - 1];
  if (!blockWholeView && !dateArray.includes(locationBlockDate)) return null;
  const daysToBlock = blockWholeView ? dateArray.length : dateArray.findIndex(date => date === locationBlockDate) + 1;
  const width = (daysToBlock / dateArray.length) * 100;

  return (
    <div className="k-scheduleTableContentBlocker">
      <div className="k-scheduleTableContentBlocker__leftMargin" />
      <div className="k-scheduleTableContentBlocker__contentBlockerContext">
        <div className="k-scheduleTableContentBlocker__contentBlocker" style={{ width: `${width}%` }}>
          <div className="k-scheduleTableContentBlocker__contentBlockerMessage">
            <span className="material-icons-outlined">lock</span>
            <FormattedMessage id="schedule.editBlocked" defaultMessage="Edycja zablokowana" />
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduleTableContentBlocker.propTypes = {
  locationShiftsBlockDate: PropTypes.string,
  availabilitiesBlockDate: PropTypes.string,
  scheduleViewMode: PropTypes.string,
  dateArray: PropTypes.arrayOf(PropTypes.string),
};

export default ScheduleTableContentBlocker;
