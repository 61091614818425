import { useAppSelector } from '@/redux-store';
import { selectCurrentCompanyName } from '@/redux-store/currentCompany';
import { selectCurrentUserAvatar, selectCurrentUserNames } from '@/selectors/currentUser.selectors';
import { getEmployeeNameShort } from '@/utils/userEmployeesHelpers';

export const useSingleAccount = () => {
  const avatar = useAppSelector(selectCurrentUserAvatar);
  const companyName = useAppSelector(selectCurrentCompanyName);
  const userNames = useAppSelector(selectCurrentUserNames);
  const userShortName = userNames ? getEmployeeNameShort(userNames, 17) : '';

  return { avatar, companyName, userShortName };
};
