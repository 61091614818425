import { useAppSelector } from '@/redux-store';
import { selectPayoutSetting } from '@/selectors/payrollSettings.selectors';
import { selectPlannedAndWorkedData } from '@/selectors/reports.selectors';

export const usePlannedAndWorkedChart = () => {
  const data = useAppSelector(selectPlannedAndWorkedData);

  const payoutSetting = useAppSelector(selectPayoutSetting);
  return { data, payoutSetting };
};
