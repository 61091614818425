import { checkArrayIntersection } from '@/utils/arrayHelpers.js';
import { companyManageRoutes } from '@/utils/routes';

export const filterCompanyManageRoutes = (permissions, restrictions) =>
  companyManageRoutes
    .filter((route) => {
      if (route.permission && !permissions.includes(route.permission)) return false;
      if (route.restrictions.length > 0 && checkArrayIntersection(restrictions, route.restrictions)) return false;
      return true;
    })
    .map((route) => ({
      ...route,
      showFreemiumStar: checkArrayIntersection(restrictions, route.freemiumRestrictions || []),
    }));
