import PropTypes from 'prop-types';

import Popover from '@/components/common/Popover/Popover';
import { getDetailTitleName } from '@/utils/absenceHelpers';

const AbsenceCalendarDayPopover = ({ data, children }, { intl }) => (
  <Popover
    showOnHover
    hideOnClick
    position="top"
    yOffset={0}
    showShadow={false}
    noPointerEvents
    hideTimeout={0}
    content={
      <ul className="absenceCalendar__popoverList">
        {data.popoverData.map(item => (
          <li>
            {getDetailTitleName(Object.keys(item), intl)}{' '}
            <span className="absenceCalendar__popoverListValue">{Object.values(item)}</span>
          </li>
        ))}
      </ul>
    }
  >
    <div>{children}</div>
  </Popover>
);

AbsenceCalendarDayPopover.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceCalendarDayPopover.propTypes = {
  data: PropTypes.shape({
    isStartDate: PropTypes.bool,
    isEndDate: PropTypes.bool,
    popoverData: PropTypes.arrayOf(PropTypes.shape({})),
    color: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default AbsenceCalendarDayPopover;
