import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { roundToTwoSigDigits } from '@/utils/baseHelpers.js';

import { calculateTotalHoursAndCost } from '../../ScheduleBudgetTable.helpers.js';

const ScheduleBudgetTableSpmhTotalCell = ({ allSpmhTargets, allEstimatedBudget, dateArray, shiftsSummary = {} }) => {
  const { allHours } = calculateTotalHoursAndCost(shiftsSummary, dateArray);

  return (
    <td className="k-budgetTable__leftColumn">
      <div className="k-budgetTable__leftColumnData">
        <FormattedMessage id="schedule.budget.spmhTarget" defaultMessage="SPMH Target" />
        <span className="k-budgetTable__leftColumnDataValue">{roundToTwoSigDigits(allSpmhTargets / 100)} PLN</span>
      </div>
      <div className="k-budgetTable__leftColumnData">
        <FormattedMessage id="schedule.budget.spmhPlan" defaultMessage="SPMH Plan" />
        <span className="k-budgetTable__leftColumnDataValue">
          {!allEstimatedBudget || !allHours ? 0 : roundToTwoSigDigits(allEstimatedBudget / 100 / (allHours / 60))} PLN
        </span>
      </div>
    </td>
  );
};

ScheduleBudgetTableSpmhTotalCell.propTypes = {
  allSpmhTargets: PropTypes.number,
  allEstimatedBudget: PropTypes.number,
  dateArray: PropTypes.arrayOf(PropTypes.string),
  budgetEstimates: PropTypes.shape({}),
  shiftsSummary: PropTypes.shape({}),
};

export default ScheduleBudgetTableSpmhTotalCell;
