import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'chooseAddTemplateVersionModal.title',
    defaultMessage: 'Dodaj szablon',
  },
  newTemplateName: {
    id: 'chooseAddTemplateVersionModal.newTemplateName',
    defaultMessage: 'Utwórz nowy szablon',
  },
  newTemplateDescription: {
    id: 'chooseAddTemplateVersionModal.newTemplateDescription',
    defaultMessage: 'Wybierz, aby zbudować od początku nowy szablon.',
  },
  fromExististingTemplateName: {
    id: 'chooseAddTemplateVersionModal.fromExististingTemplateName',
    defaultMessage: 'Utwórz na podstawie istniejącego szablonu',
  },
  fromExististingTemplateDescription: {
    id: 'chooseAddTemplateVersionModal.fromExististingTemplateDescription',
    defaultMessage: 'Wybierz, aby utworzyć nowy szablon na bazie jednego z utworzonych wcześniej szablonów.',
  },
  forth: {
    id: 'chooseAddTemplateVersionModal.forth',
    defaultMessage: 'Dalej',
  },
});
