import PropTypes from 'prop-types';

import MDHoursAndMinutesInput from '@/components/common/inputs/MDHoursAndMinutesInput/MDHoursAndMinutesInput.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import { getAbsenceTypePeriod, getAbsenceTypeUnit, getMaxLimit } from '@/utils/absenceHelpers.js';

import './AbsenceLimitsTab.scss';

const AbsenceLimitsTab = (props, context) => {
  const { limits, absencesTypes } = props;

  const handleLimitChange = (value, absenceType) => {
    const maxLimit = getMaxLimit(absenceType);
    const newValue = value > maxLimit ? maxLimit : value;
    props.handleLimitChange(newValue, absenceType.id);
  };

  return (
    <div className="employeeModal__limits">
      {absencesTypes
        .filter(absenceType => absenceType.has_limit && !absenceType.deleted_at_timestamp)
        .map(absenceType => (
          <div className="absenceType" key={absenceType.id}>
            <div className="absenceType__name">{absenceType.name}</div>
            <div className="absenceType__limit">
              {absenceType.limit_unit === 'minutes' ? (
                <MDHoursAndMinutesInput
                  value={limits[absenceType.id]}
                  onChange={value => handleLimitChange(value, absenceType)}
                  testId="absenceLimit"
                />
              ) : (
                <MDTextInput
                  value={limits[absenceType.id]}
                  type="number"
                  modifiers={['modal', 'no-padding', 'center']}
                  onChange={({ target }) => handleLimitChange(target.value, absenceType)}
                  isNumberPositive
                  isNumberInteger
                  testId="absenceLimit"
                />
              )}
              <div className="absenceType__unitAndPeriod">
                {getAbsenceTypeUnit(absenceType, context.intl)} / {getAbsenceTypePeriod(absenceType, context.intl)}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

AbsenceLimitsTab.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AbsenceLimitsTab.propTypes = {
  absencesTypes: PropTypes.arrayOf(PropTypes.shape({})),
  limits: PropTypes.shape({}),
  handleLimitChange: PropTypes.func,
};

export default AbsenceLimitsTab;
