import { connect } from 'react-redux';

import { requestResetPassword } from '@/actions/auth.jsx';
import ResetPasswordSendEmail from '@/components/password/ResetPasswordSendEmail.jsx';

const mapDispatchToProps = {
  requestResetPassword,
};

const ResetPasswordSendEmailContainer = connect(null, mapDispatchToProps)(ResetPasswordSendEmail);

export default ResetPasswordSendEmailContainer;
