import * as AT from '@/constants/ActionTypes';
import { extractShiftsFromReqeustData } from '@/utils/userEmployeesHelpers';

const initialState = {
  data: {},
};

const employeeShifts = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.ADD_SHIFT_TO_EMPLOYEE: {
      return { ...state, [payload.shift.id]: { ...payload.shift } };
    }
    case AT.ADD_SHIFT_SUCCESFUL: {
      const newData = { ...state, [payload.new_shift.id]: payload.new_shift };
      delete newData[payload.uid];
      return newData;
    }
    case AT.EDIT_SHIFT_SUCCESFUL: {
      return { ...state, [payload.newShiftObject.id]: { ...payload.newShiftObject } };
    }
    case AT.DELETE_SHIFT: {
      const newData = { ...state };
      delete newData[payload.shift_id];
      return newData;
    }
    default:
      return state;
  }
};

const employeeAvailabilities = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.ADD_AVAILABILITY_SUCCESFUL: {
      return { ...state, [payload.id]: { ...payload } };
    }
    case AT.CHANGE_AVAILABILITY_SUCCESFUL: {
      return { ...state, [payload.id]: { ...payload } };
    }
    case AT.DELETE_AVAILABILITY_SUCCESFUL: {
      const newData = { ...state };
      delete newData[payload.availabilityObject.id];
      return newData;
    }
    default:
      return state;
  }
};

const shifts = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case AT.GET_SCHEDULE_SUCCESFUL: {
      const data = extractShiftsFromReqeustData(payload);
      return { ...state, data: { ...state.data, ...data } };
    }
    case AT.PUBLISH_DRAFT_SHIFTS_SUCCESFUL: {
      const newData = {};
      Object.keys(state.data).forEach(employeeId => {
        const updatedShifts = {};
        Object.keys(state.data[employeeId].shifts).forEach(shiftId => {
          const shift = state.data[employeeId].shifts[shiftId];
          if (
            shift.draft &&
            shift.location.id === action.payload.location_id &&
            action.payload.dateArray.includes(shift.date) &&
            action.payload.employeeIds.includes(shift.employee.id)
          ) {
            updatedShifts[shiftId] = { ...shift, draft: false };
          } else {
            updatedShifts[shiftId] = shift;
          }
        });
        newData[employeeId] = { ...state.data[employeeId], shifts: updatedShifts };
      });
      return { ...state, data: newData };
    }
    case AT.CLOSE_TRADE_SHIFT_SUCCESFUL: {
      const fromUserId = payload.tradeShift.user.id;
      const toUserId = payload.employeeId;
      const shiftId = payload.tradeShift.shift.id;
      const newFromUserShifts = { ...state.data[fromUserId].shifts };
      delete newFromUserShifts[shiftId];
      const newData = {
        ...state.data,
        [fromUserId]: { ...state.data[fromUserId], shifts: newFromUserShifts },
        [toUserId]: {
          ...state.data[toUserId],
          shifts: { ...state.data[toUserId].shifts, [shiftId]: payload.tradeShift.shift },
        },
      };
      return { ...state, data: newData };
    }
    case AT.ADD_SHIFT_SUCCESFUL:
    case AT.ADD_SHIFT_TO_EMPLOYEE:
    case AT.EDIT_SHIFT_SUCCESFUL:
    case AT.DELETE_SHIFT: {
      const data = {
        ...state.data,
        [payload.employee_id]: {
          ...state.data[payload.employee_id],
          shifts: employeeShifts(state.data[payload.employee_id].shifts, action),
        },
      };
      return { ...state, data };
    }
    case AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL: {
      const newData = { ...state.data };
      Object.keys(action.payload).forEach(employeeId => {
        action.payload[employeeId].forEach(item => {
          delete newData[employeeId].shifts[item.shiftId];
        });
      });
      return { ...state, data: newData };
    }
    case AT.ADD_SHIFTS_TO_EMPLOYEES: {
      const newEmployeesState = action.payload.reduce((result, shift) => {
        const originalShifts = state.data[shift.employee.id]?.shifts;
        const updatedShifts = state.data[shift.employee.id]?.shifts;
        const newestShifts = updatedShifts || originalShifts;

        return {
          ...result,
          [shift.employee.id]: {
            ...state.data[shift.employee.id],
            shifts: {
              ...newestShifts,
              [shift.id]: shift,
            },
          },
        };
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...newEmployeesState,
        },
      };
    }
    case AT.ADD_SHIFTS_SUCCESFUL: {
      const newEmployeesState = action.newShifts.reduce((result, shift) => {
        const originalShifts = state.data[shift.employee.id]?.shifts;
        const updatedShifts = state.data[shift.employee.id]?.shifts;
        const newestShifts = updatedShifts || originalShifts;

        return {
          ...result,
          [shift.employee.id]: {
            ...state.data[shift.employee.id],
            shifts: {
              ...newestShifts,
              [shift.id]: shift,
            },
          },
        };
      }, {});

      return {
        ...state,
        data: {
          ...state.data,
          ...newEmployeesState,
        },
      };
    }
    case AT.ADD_MASS_SHIFTS_SUCCESFUL:
    case AT.DUPLICATE_PREVIOUS_VIEW_SUCCESS: {
      const data = { ...state.data };
      payload.forEach(shift => {
        data[shift.employee.id] = {
          ...data[shift.employee.id],
          shifts: { ...data[shift.employee.id].shifts, [shift.id]: shift },
        };
      });
      return { ...state, data };
    }
    case AT.ADD_AVAILABILITY_SUCCESFUL:
    case AT.CHANGE_AVAILABILITY_SUCCESFUL:
    case AT.DELETE_AVAILABILITY_SUCCESFUL: {
      const employeeId = payload.employee_id || payload.employee.id;
      if (!state.data[employeeId]) return state;
      const data = {
        ...state.data,
        [employeeId]: {
          ...state.data[employeeId],
          availability_blocks: employeeAvailabilities(state.data[employeeId].availability_blocks, action),
        },
      };
      return { ...state, data };
    }
    case AT.DELETE_AVAILABILITIES_SUCCESFUL: {
      const newData = { ...state.data };
      Object.keys(newData).forEach(employeeId => {
        Object.keys(newData[employeeId].availability_blocks).forEach(availabilityBlockId => {
          if (action.payload.includes(availabilityBlockId))
            delete newData[employeeId].availability_blocks[availabilityBlockId];
        });
      });
      return { ...state, data: newData };
    }
    default:
      return state;
  }
};

export default shifts;
