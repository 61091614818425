import { connect } from 'react-redux';

import { showEmployeeModal } from '@/actions/companymanage';

import ScheduleTableRowTitlePopover from './ScheduleTableRowTitlePopover.jsx';

const mapStateToProps = (state, ownProps) => {
  const employee = state.reducer.employees.data[ownProps.employeeId];
  return {
    employeeRole: employee.role_id,
    employmentConditions: employee?.employment_conditions,
    employmentConditionList: state.reducer.employmentConditions,
    firstDayInSchedule: state.reducer.mainDateStore.dateArray[0],
    restrictions: state.reducer.userPermissions.restrictions,
    companySettings: state.reducer.currentCompany.settings,
    currentUserRoleId: state.reducer.currentUser.user.role_id,
    currentUserId: state.reducer.currentUser.user.id,
    companyRoles: state.reducer.roles.companyRoles,
  };
};

const mapDispatchToProps = { showEmployeeModal };

const ScheduleTableRowTitlePopoverContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleTableRowTitlePopover);

export default ScheduleTableRowTitlePopoverContainer;
