import { isEmptyArray } from '@/utils/array/array.helpers';
import { isEmptyObject } from '@/utils/objectHelpers/objectHelpers';
import { checkHasEmployeeAnyShiftInLocations } from '@/utils/schedule/scheduleFilters/scheduleFilters';
import { getFreeDaysIdsForSelectedCells } from '@/utils/schedule/selection/selection';
import {
  getAvailabilitiesIdsForSelectedCells,
  getShiftIdsForSelectedCells,
  isAnyOpenShiftSelected,
  isAnyTradeShiftSelected,
} from '@/utils/schedule/selectionHelpers';

import { getNewButtonsStatusFreeDaysForQP } from './freeDays';

export const getNewButtonsStatus = getState => {
  const {
    freeDaysMarking,
    mainDateStore,
    openShifts,
    schedule,
    employees,
    shifts,
    settings,
    currentCompany,
    jobtitleFilter,
    tradeShifts,
    scheduleLoanedEmployees,
  } = getState().reducer;
  const currentScheduleViewMode = schedule.viewMode.viewMode;
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
  switch (currentScheduleViewMode) {
    case 'schedule':
      return getNewButtonsStatusForSchedule(
        mainDateStore,
        openShifts,
        schedule,
        employees,
        shifts,
        settings,
        selectedJobTitlesIds,
        tradeShifts,
        scheduleLoanedEmployees.scheduleLoanedEmployees,
        freeDaysMarking,
      );
    case 'availabilities': {
      const disableAvailabilitiesEditUntil = currentCompany.settings.disable_availabilities_edit_until;
      return getNewButtonsStatusForAvailabilities(schedule, employees, disableAvailabilitiesEditUntil);
    }
    default:
      return schedule.buttonsState;
  }
};

const getNewButtonsStatusForSchedule = (
  mainDateStore,
  openShifts,
  schedule,
  employees,
  shifts,
  settings,
  selectedJobTitlesIds,
  tradeShifts,
  loanedEmployees,
  freeDaysMarking,
) => {
  const canDeselect = checkIfCanAnythingDeselect(schedule);
  const canDelete =
    checkIfCanDeleteShifts(
      mainDateStore,
      openShifts,
      schedule,
      employees,
      shifts,
      settings,
      selectedJobTitlesIds,
      tradeShifts,
      loanedEmployees,
    ) || checkIfCanDeleteFreeDays(freeDaysMarking, schedule);
  return { canDeselect, canDelete };
};

const checkIfCanAnythingDeselect = schedule => {
  const { selectedCells, openShiftsRowSelect, tradeShiftsRowSelect } = schedule;
  const anythingSelected = Object.values(selectedCells.selectionsPerLocation).some(
    selectionsForLocation => !!Object.values(selectionsForLocation.selected).length,
  );
  const tradeShiftsforSelected = Object.values(tradeShiftsRowSelect).some(value => !!value);
  const openShiftsForSelected = Object.values(openShiftsRowSelect).some(value => !!value);
  const canDeselect = anythingSelected || openShiftsForSelected || tradeShiftsforSelected;
  return canDeselect;
};

const checkIfCanDeleteShifts = (
  mainDateStore,
  openShifts,
  schedule,
  employees,
  shifts,
  settings,
  selectedJobTitlesIds,
  tradeShifts,
  loanedEmployees,
) =>
  isAnyOpenShiftSelected(mainDateStore, openShifts, schedule, selectedJobTitlesIds) ||
  Boolean(getShiftIdsForSelectedCells(schedule, employees, shifts, settings, loanedEmployees).length) ||
  isAnyTradeShiftSelected(mainDateStore, tradeShifts, schedule, selectedJobTitlesIds);

const checkIfCanDeleteFreeDays = (freeDaysMarking, schedule) =>
  !isEmptyArray(getFreeDaysIdsForSelectedCells(schedule.selectedCells.selectionsPerLocation, freeDaysMarking));

const getNewButtonsStatusForAvailabilities = (schedule, employees, disableAvailabilitiesEditUntil) => {
  const canDeselect = checkIfCanAnythingDeselect(schedule);
  const canDelete = checkIfCanDeleteAvailabilities(schedule, employees, disableAvailabilitiesEditUntil);
  return { canDeselect, canDelete };
};

const checkIfCanDeleteAvailabilities = (schedule, employees, disableAvailabilitiesEditUntil) =>
  !!getAvailabilitiesIdsForSelectedCells(schedule, employees, disableAvailabilitiesEditUntil).length;

const isAnyShiftInSelectedForQuickPlanning = (selected, employees, shiftsData, scheduleLocationFilter) =>
  Object.entries(selected).some(([employeeId, values]) => {
    const dates = Object.keys(values);
    return dates.some(date => {
      const shiftIds = employees[employeeId]?.shifts[date] || [];
      return checkHasEmployeeAnyShiftInLocations(shiftsData, shiftIds, scheduleLocationFilter, employeeId);
    });
  });

const isAnyShiftForPositionForQuickPlanning = (borderCell, employees, shiftsData, scheduleLocationFilter) => {
  const { employeeId, date } = borderCell;
  const shiftIds = employees[employeeId]?.shifts[date] || [];
  return checkHasEmployeeAnyShiftInLocations(shiftsData, shiftIds, scheduleLocationFilter, employeeId);
};

const checkAnyCellMarkedToCopy = copiedMarkedArea =>
  Object.values(copiedMarkedArea).some(e => Object.keys(e).length > 0);

export const getNewButtonsStatusForQuickPlanning = getState => {
  const {
    mainDateStore,
    openShifts,
    schedule,
    employees,
    freeDaysMarking,
    shifts,
    settings,
    jobtitleFilter,
    tradeShifts,
    scheduleLoanedEmployees,
    scheduleLocationFilter,
  } = getState().reducer;
  const { freeDaysToCopy, shiftsToCopy, selected, enabled, position, copiedMarkedArea } = schedule.quickPlanning;
  const selectedJobTitlesIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
  if (!enabled) {
    const newStateScheduleButtons = getNewButtonsStatusForSchedule(
      mainDateStore,
      openShifts,
      schedule,
      employees,
      shifts,
      settings,
      selectedJobTitlesIds,
      tradeShifts,
      scheduleLoanedEmployees.scheduleLoanedEmployees,
      freeDaysMarking,
    );
    return { ...schedule.buttonsState, ...newStateScheduleButtons };
  }

  const employeesData = { ...employees.data, ...scheduleLoanedEmployees.scheduleLoanedEmployees };
  const isAnyShiftSelected = isAnyShiftInSelectedForQuickPlanning(
    selected,
    employeesData,
    shifts.data,
    scheduleLocationFilter,
  );
  const isAnyShiftForPosition = isAnyShiftForPositionForQuickPlanning(
    position.borderCell,
    employeesData,
    shifts.data,
    scheduleLocationFilter,
  );

  const freeDayBtnState = getNewButtonsStatusFreeDaysForQP(freeDaysMarking, selected, position.borderCell);

  const isAnyCellMarkedToCopy = checkAnyCellMarkedToCopy(copiedMarkedArea);
  return {
    canPaste: !isEmptyObject(shiftsToCopy) || !isEmptyObject(freeDaysToCopy),
    canCopy: isAnyShiftSelected || isAnyShiftForPosition || freeDayBtnState.canCopy,
    canDelete: isAnyShiftSelected || isAnyShiftForPosition || freeDayBtnState.canDelete,
    canDeselect: !isEmptyObject(selected) || isAnyCellMarkedToCopy,
  };
};
