import { bindClassFunctions } from 'kadro-helpers/lib/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { defineMessages, FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { MDPhoneInput } from '@/components/common/inputs/MDComponents';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';
import browserHistory from '@/constants/browserHistory';
import { onboardingRecaptchaKey } from '@/constants/onboarding.js';
import { gaEvents } from '@/utils/ga.helpers';
import { inputHasErrors, inputValidation } from '@/utils/inputValidation.js';

const messages = defineMessages({
  phone: {
    id: 'onboarding.stepThree.phone',
    defaultMessage: 'Numer telefonu',
  },
  discountCode: {
    id: 'onboarding.stepThree.discountCode',
    defaultMessage: 'Jeśli posiadasz, wpisz kod rabatowy',
  },
});

class OnboardingStepThree extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...props.stepData,
      phone: '',
      discountCode: '',
      phone_prefix: '',
      errors: {},
    };

    bindClassFunctions(this);
  }

  componentDidMount() {
    if (this.state.initialLoginWithOauth) {
      gaEvents.setNewStep(4, window.gaProducts);
      return;
    }

    return this.validateAll(['companyName', 'industry', 'companySize']).then(valid => {
      if (!valid) {
        browserHistory.push('/onboarding/stepTwo');
      } else {
        gaEvents.setNewStep(4, window.gaProducts);
      }
    });
  }

  onCaptchaChange(recaptcha) {
    const { phone, discountCode, phone_prefix } = this.state;

    this.props.saveStepData(
      {
        phone,
        recaptcha,
        discountCode,
        phone_prefix,
      },
      'finish',
    );
  }

  onDiscountCodeBlur(e) {
    gaEvents.setCheckoutOption(4, e.target.value);
    return this.validateInput(e);
  }

  async onPhoneBlur(e) {
    const error = await this.validateInput(e);
    if (!error) gaEvents.setCheckoutOption(4, 'phone-added');
  }

  handleInputChange(e) {
    const { target } = e;
    const { name } = target;
    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value.replace(/-/g, '').trim(),
    });

    const { errors } = this.state;

    if (inputHasErrors(name, errors)) {
      this.validateInput(e);
    }
  }

  validateInput(event) {
    return new Promise(resolve => {
      let { valueToCompare } = event;
      const { target } = event;
      const { name, checked, value: targetValue, type } = target;
      const value = type === 'checkbox' ? checked : targetValue;
      if (name === 'phone' && !valueToCompare) {
        valueToCompare = this.state.phone_prefix;
      }
      const error = inputValidation(name, value, { valueToCompare });
      this.setState(
        prev => ({
          errors: {
            ...prev.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        }),
        () => {
          resolve(error);
        },
      );
      return null;
    });
  }

  async validateAll(inputs) {
    const inputsToValidate = inputs && inputs.length ? inputs : ['phone', 'discountCode'];
    const validationRequests = [];
    for (const i of inputsToValidate) {
      validationRequests.push(this.validateInput({ target: { name: i, value: this.state[i] } }));
    }
    await Promise.all(validationRequests);
    let canContinue = true;
    Object.values(this.state.errors).map(err => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  async nextAction(e) {
    e.preventDefault();
    const valid = await this.validateAll();
    if (!valid) return;
    this.recaptcha.execute();
  }

  skipAction() {
    this.recaptcha.execute();
  }

  render() {
    return (
      <div className="k-onboardingStep">
        <div className="k-onboardingStep__info">
          <FormattedMessage
            id="onboarding.stepThree.infoText"
            defaultMessage="Już prawie skończyliśmy! Podaj nam swój numer telefonu, abyśmy mogli skontaktować się z Tobą żeby zapoznać Cię lepiej z platformą."
          />
        </div>
        <form onSubmit={this.nextAction} className="k-onboardingStep__form">
          <MDPhoneInput
            phoneNumber={this.state.phone}
            errorMessage={this.state.errors.phone}
            label={this.context.intl.formatMessage(messages.phone, {})}
            onBlur={this.onPhoneBlur}
            onChange={this.handleInputChange}
            type="text"
            testId="phone"
            id="phone"
            prefix={this.state.phone_prefix}
            modifiers={['big']}
          />
          {!this.state.initialLoginWithOauth && (
            <MDTextInput
              id="discountCode"
              value={this.state.discountCode}
              errorMessage={this.state.errors.discountCode}
              iconType="material-outlined"
              icon="card_giftcard"
              label={this.context.intl.formatMessage(messages.discountCode)}
              onChange={this.handleInputChange}
              onBlur={this.onDiscountCodeBlur}
            />
          )}
          <div className="k-onboardingStep__button">
            <Button id="onboardingRegisterButton" type="submit" modifiers="orange high uppercase">
              <FormattedMessage id="common.next" defaultMessage="Dalej" />
            </Button>
          </div>
        </form>
        <div className="k-onboardingStep__captcha">
          <ReCAPTCHA
            sitekey={onboardingRecaptchaKey}
            onChange={this.onCaptchaChange}
            size="invisible"
            badge="bottomleft"
            ref={ref => {
              this.recaptcha = ref;
            }}
          />
        </div>
      </div>
    );
  }
}

OnboardingStepThree.contextTypes = {
  intl: PropTypes.shape().isRequired,
};

OnboardingStepThree.propTypes = {
  stepData: PropTypes.shape({}),
  saveStepData: PropTypes.func,
};

export default OnboardingStepThree;
