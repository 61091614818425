import { conn } from '@/actions';
import { getPayrollLoanedEmployees } from '@/actions/payrollLoanedEmployees';
import { getPayrollLocationColumns } from '@/components/newPayrollViews/newPayrollLocation/PayrollLocationTable/PayrollLocationTable.helpers';
import * as AT from '@/constants/ActionTypes';
import { LOAN_EMPLOYEES_ENABLE, OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions';
import { splitArrayIntoBatches } from '@/utils/arrayHelpers';
import { uuid4 } from '@/utils/baseHelpers';
import { getDiff, getFromToFromDateStore } from '@/utils/dateHelper';
import { filterEmployeesByFilters } from '@/utils/userEmployeesHelpers';

import { messages } from './payrollLocation.messages';

const MAX_WORKDAYS_PER_PAYROLL_BATCH = 775;
const EMPLOYEES_COUNT_TO_CHECK_WORKING_USERS = 50;

const getPayrollLocationDataSuccessfully = (data, mergeData, fetchRequestId) => ({
  type: AT.PAYROLL_LOCATION_GET_DATA_SUCCESS,
  payload: { data, mergeData, fetchRequestId },
});

const getPayrollLocationDataError = fetchRequestId => (dispatch, getState, intl) => {
  dispatch({
    type: AT.PAYROLL_LOCATION_GET_DATA_ERROR,
    payload: { fetchRequestId },
    notification: {
      title: intl.formatMessage(messages.getPayrollLocationDataError),
      type: 'error',
    },
  });
};

export const saveFiltersStateForPayrollLocation = () => (dispatch, getState) => {
  const {
    multipleLocationFilter: selectedLocationIds,
    jobtitleFilter,
    employmentConditionsFilter,
    mainDateStore,
    currentUser,
  } = getState().reducer;
  const { dateArray } = mainDateStore;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const jobTitleIds = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
  const employmentConditionIds = employmentConditionsFilter.selected.map(({ id }) => id);
  const filters = {
    selectedLocationIds,
    jobTitleIds,
    employmentConditionIds,
    dateArray,
    from,
    to,
  };
  localStorage.setItem(`k-payrollLocationFilters-${currentUser.user.id}`, JSON.stringify(filters));
};

export const getOldFiltersStateForPayrollLocation = () => (dispatch, getState) => {
  const { currentUser } = getState().reducer;
  const storageItem = localStorage.getItem(`k-payrollLocationFilters-${currentUser.user.id}`);
  if (!storageItem) {
    return;
  }
  return JSON.parse(storageItem);
};

export const startSession = (status, options, fetchRequestId) => async (dispatch, getState) => {
  const {
    mainDateStore,
    multipleLocationFilter: locations,
    jobtitleFilter,
    employmentConditionsFilter,
    userEmployees,
  } = getState().reducer;
  const [from, to] = getFromToFromDateStore(mainDateStore);
  const employmentConditions = employmentConditionsFilter.selected.map(({ id }) => id);
  const employees = userEmployees.map(({ id }) => id);
  const jobTitles = jobtitleFilter.selectedJobtitles.map(({ id }) => id);

  const requestSessionResult = await conn.startPayrollLocationSession(
    from,
    to,
    employmentConditions,
    jobTitles,
    locations,
    employees,
    true,
    true,
    status,
  );
  dispatch({
    type: AT.START_FETCHING_PAYROLL_LOCATION_IN_BATCHES,
    payload: { options, fetchRequestId },
  });
  return requestSessionResult.data.sessionId;
};

export const getPayrollLocationViewData =
  (status, options = { staleWhileRevalidate: false }) =>
  async (dispatch, getState) => {
    const fetchRequestId = uuid4();
    dispatch({
      type: AT.START_FETCHING_PAYROLL_LOCATION,
      payload: { fetchRequestId },
    });
    const {
      userEmployees,
      mainDateStore,
      multipleLocationFilter: selectedLocations,
      employmentConditionsFilter,
      jobtitleFilter,
      contracts,
      userPermissions: { permissions },
    } = getState().reducer;
    const selectedEmploymentConditions = employmentConditionsFilter.selected.map(({ id }) => id);
    const selectedJobTitles = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
    let filteredEmployees = filterEmployeesByFilters(
      userEmployees,
      selectedLocations,
      selectedJobTitles,
      selectedEmploymentConditions,
      contracts,
      mainDateStore.customDate.start,
      mainDateStore.customDate.end,
    );
    const [from, to] = getFromToFromDateStore(mainDateStore);
    const numberOfDays = getDiff(from, to, 'days');
    const maxNumberOfEmployeesPerRequest = getMaxNumberOfEmployeesPerRequest(numberOfDays);
    const isLoanEmployeesEnabled =
      permissions.includes(LOAN_EMPLOYEES_ENABLE) || permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE);
    const shouldCheckWorkingUsers =
      filteredEmployees.length > EMPLOYEES_COUNT_TO_CHECK_WORKING_USERS ||
      filteredEmployees.length > maxNumberOfEmployeesPerRequest ||
      isLoanEmployeesEnabled;
    if (shouldCheckWorkingUsers) {
      filteredEmployees = await filterOnlyWorkingUsers(
        filteredEmployees,
        from,
        to,
        selectedLocations,
        isLoanEmployeesEnabled,
      );
    }

    if (
      filteredEmployees.length &&
      (isLoanEmployeesEnabled || filteredEmployees.length > maxNumberOfEmployeesPerRequest)
    ) {
      dispatch(
        getPayrollLocationViewDataInBatches(
          maxNumberOfEmployeesPerRequest,
          filteredEmployees,
          status,
          options,
          fetchRequestId,
        ),
      );
    } else {
      dispatch(getPayrollLocationViewDataInSingleRequest(status, options, fetchRequestId));
    }
  };

const getMaxNumberOfEmployeesPerRequest = numberOfDays => {
  const maxNumberOfEmployees = Math.floor(MAX_WORKDAYS_PER_PAYROLL_BATCH / numberOfDays);
  return maxNumberOfEmployees > 50 ? 50 : maxNumberOfEmployees;
};

const filterOnlyWorkingUsers = async (users, from, to, locations, getAllWorkingUsers) => {
  const workingUsersReply = await conn.getWorkingUserIdsForPayroll(from, to, locations);
  const workingUserIds = workingUsersReply?.data?.userIds;
  if (!workingUserIds) {
    return users;
  }
  if (getAllWorkingUsers)
    return workingUserIds.reduce((agg, id) => {
      const user = users.find(({ id: userId }) => userId === id);
      if (user) {
        agg.push(user);
      } else {
        agg.push({ id });
      }
      return agg;
    }, []);

  const workingUsersMap = workingUserIds.reduce((agg, id) => {
    agg[id] = true;
    return agg;
  }, {});

  return users.filter(({ id }) => workingUsersMap[id]);
};

export const getPayrollLocationEmployeesDataBatches =
  (employeeBatches, sessionId, options, fetchRequestId) => async (dispatch, getState) => {
    const {
      uiState: { sortingUseLastName },
    } = getState().reducer;
    const results = [];
    for (let i = 0; i < employeeBatches.length; i++) {
      const currentFetchRequestId = getState().reducer.payrollLocation.currentRequestId;
      if (currentFetchRequestId !== fetchRequestId) {
        return [];
      }
      const batch = employeeBatches[i];

      // eslint-disable-next-line no-await-in-loop
      const response = await conn.getPartOfPayrollLocation(batch, sessionId);
      const sortedData = response.data.data.sort((a, b) => {
        const aFullName = sortingUseLastName ? `${a.last_name} ${a.first_name}` : `${a.first_name} ${a.last_name}`;
        const bFullName = sortingUseLastName ? `${b.last_name} ${b.first_name}` : `${b.first_name} ${b.last_name}`;

        return aFullName.localeCompare(bFullName);
      });
      results.push(response.data);
      dispatch({
        type: AT.GET_PAYROLL_LOCATION_DATA_BATCH_SUCCESS,
        payload: { data: sortedData, options, fetchRequestId },
      });
    }

    return results;
  };

export const getPayrollLocationViewDataInBatches =
  (employeesPerBatch, employees, status, options, fetchRequestId) => async (dispatch, getState) => {
    const {
      uiState: { sortingUseLastName },
      userEmployees,
      userPermissions,
    } = getState().reducer;

    const sortedEmployees = employees.sort((a, b) => {
      const aFullName = sortingUseLastName ? `${a.last_name} ${a.first_name}` : `${a.first_name} ${a.last_name}`;
      const bFullName = sortingUseLastName ? `${b.last_name} ${b.first_name}` : `${b.first_name} ${b.last_name}`;

      return aFullName.localeCompare(bFullName);
    });
    const employeeIds = sortedEmployees.map(({ id }) => id);
    let sessionId;
    try {
      sessionId = await dispatch(startSession(status, options, fetchRequestId));
    } catch (err) {
      dispatch(getPayrollLocationDataError(fetchRequestId));
      return;
    }

    try {
      const employeeBatches = splitArrayIntoBatches(employeeIds, employeesPerBatch);

      const employeesFromFiltersResult = await dispatch(
        getPayrollLocationEmployeesDataBatches(employeeBatches, sessionId, options, fetchRequestId),
      );

      const summary = employeesFromFiltersResult.reduce((agg, result) => {
        const { summary } = result;
        Object.keys(summary).forEach(key => {
          agg[key] = (agg[key] || 0) + summary[key];
        });
        return agg;
      }, {});

      dispatch({
        type: AT.GET_PAYROLL_LOCATION_SUMMARY_SUCCESS,
        payload: summary,
      });

      dispatch(saveFiltersStateForPayrollLocation());
    } catch (err) {
      dispatch(getPayrollLocationDataError(fetchRequestId));
    } finally {
      await conn.endPayrollLocationSession(sessionId);
      dispatch({
        type: AT.FINISH_FETCHING_PAYROLL_LOCATION_IN_BATCHES,
        payload: { fetchRequestId },
      });
    }
    if (
      userPermissions.permissions.includes(LOAN_EMPLOYEES_ENABLE) ||
      userPermissions.permissions.includes(OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE)
    ) {
      const employeeIdsNotPresentInUserEmployees = employeeIds.filter(id => !userEmployees.find(emp => emp.id === id));
      if (employeeIdsNotPresentInUserEmployees.length > 0) {
        try {
          const employeesNotPresentInUserEmployees = await conn.getEmployeesNames(employeeIdsNotPresentInUserEmployees);
          dispatch(getPayrollLoanedEmployees(employeesNotPresentInUserEmployees.data));
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

export const getPayrollLocationViewDataInSingleRequest =
  (status, { staleWhileRevalidate = false }, fetchRequestId) =>
  async (dispatch, getState) => {
    try {
      const requestType = staleWhileRevalidate ? 'standard' : 'blocking';
      const { mainDateStore, multipleLocationFilter, jobtitleFilter, employmentConditionsFilter } = getState().reducer;
      const [from, to] = getFromToFromDateStore(mainDateStore);
      const employmentConditions = employmentConditionsFilter.selected.map(({ id }) => id);
      const jobTitles = jobtitleFilter.selectedJobtitles.map(({ id }) => id);
      const result = await conn.getNewPayrollLocation(
        from,
        to,
        employmentConditions,
        jobTitles,
        multipleLocationFilter,
        status,
        requestType,
      );
      dispatch(getPayrollLocationDataSuccessfully(result.data, staleWhileRevalidate, fetchRequestId));
      dispatch(saveFiltersStateForPayrollLocation());
    } catch (err) {
      dispatch(getPayrollLocationDataError(fetchRequestId));
    }
  };

export const changePayrollLocationColumns = selected => (dispatch, getState) => {
  const {
    userPermissions,
    payrollSettings: { payoutSetting },
    currentCompany: { settings: companySettings },
  } = getState().reducer;
  const payrollLocationTableColumns = getPayrollLocationColumns(userPermissions, payoutSetting.type, companySettings);
  const selectedColumns = payrollLocationTableColumns.filter(
    option => option.cannotBeHidden || selected.find(sel => sel.id === option.id),
  );
  dispatch({
    type: AT.PAYROLL_LOCATION_CHANGE_VISIBLE_COLUMNS,
    payload: selectedColumns,
  });
};
