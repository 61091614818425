import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  modalTitle: {
    id: 'settings.editBillingDataModal.modalTitle',
    defaultMessage: 'Podaj dane do faktury',
  },
  email: {
    id: 'settings.editBillingDataModal.mailForInvoice',
    defaultMessage: 'Mail do wystawiania faktury',
  },
  companyName: {
    id: 'settings.editBillingDataModal.companyName',
    defaultMessage: 'Nazwa firmy',
  },
  nip: {
    id: 'settings.editBillingDataModal.nip',
    defaultMessage: 'NIP',
  },
  address: {
    id: 'settings.editBillingDataModal.street',
    defaultMessage: 'Adres',
  },
  city: {
    id: 'settings.editBillingDataModal.city',
    defaultMessage: 'Miasto',
  },
  zipCode: {
    id: 'settings.editBillingDataModal.zipCode',
    defaultMessage: 'Kod pocztowy',
  },
  modalErrorMessage: {
    id: 'settings.editBillingDataModal.modalErrorMessage',
    defaultMessage: 'Uzupełnij pola zaznaczone na czerwono, aby kontynuować',
  },
});
