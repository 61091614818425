import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  tolerance: {
    id: 'payrollSettings.workTimeSection.Tolerance',
    defaultMessage: 'Tolerancja',
  },
  toleranceTooltip: {
    id: 'payrollSettings.workTimeSectionTolerance.Tooltip',
    defaultMessage: 'Tolerancja',
  },
  startTime: {
    id: 'payrollSettings.workTimeSection.startTime',
    defaultMessage: 'Czas rozpoczęcia',
  },
  startTimeTooltip: {
    id: 'payrollSettings.workTimeSection.startTimeTooltip',
    defaultMessage: 'Czas rozpoczęcia',
  },
  endTime: {
    id: 'payrollSettings.workTimeSection.endTime',
    defaultMessage: 'Czas zakończenia',
  },
  endTimeTooltip: {
    id: 'payrollSettings.workTimeSection.endTimeTooltip',
    defaultMessage: 'Czas zakończenia',
  },
  rounding: {
    id: 'payrollSettings.workTimeSection.rounding',
    defaultMessage: 'Zaokrąglenie',
  },
  roundingTooltip: {
    id: 'payrollSettings.workTimeSection.roundingTooltip',
    defaultMessage: 'Zaokrąglenie',
  },
  round: {
    id: 'payrollSettings.workTimeSection.round',
    defaultMessage: 'Zaokrąglaj',
  },
  roundTooltip: {
    id: 'payrollSettings.workTimeSection.roundTooltip',
    defaultMessage: 'Zaokrąglaj',
  },
});
