import { connect } from 'react-redux';

import { togglePayrollSettingsModal } from '@/actions/payrollUI';
import { calculateSpmhBasedBonusesForNewPayroll } from '@/actions/spmhBasedBonuses';
import { showModal } from '@/actions/uiState';

import PayrollActionButtons from './PayrollActionButtons';

const mapStateToProps = ({ reducer }) => ({
  payoutSettingType: reducer.payrollSettings.payoutSetting.type,
  permissions: reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  calculateSpmhBasedBonusesForNewPayroll,
  showModal,
  togglePayrollSettingsModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollActionButtons);
