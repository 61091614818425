import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { quickPlanningAddNewFreeDays, setShortcutForNewFreeDay } from '@/actions/schedule/quickPlanning/freeDaysQP';
import { useAppDispatch, useAppSelector } from '@/redux-store';
import {
  selectBorderCell,
  selectIsQuickPlanningEnabled,
  selectQuickPlanningSelected,
  selectShortcutForNewFreeDay,
} from '@/redux-store/schedule/quickPlanning';
import { EmployeeWhole } from '@/types';
import { StandardDate } from '@/types/dates.types';
import { getFreeDaysShortcuts } from '@/utils/freeDaysMarking/freeDaysMarking';

import { checkIsFreeDayShortcutValid, getClasses } from './ScheduleQuickPlanningNewFreeDay.utils';

export const useScheduleQuickPlanningNewFreeDay = (employeeId: EmployeeWhole['id'], date: StandardDate) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const enabled = useAppSelector(selectIsQuickPlanningEnabled);
  const shortcut = useAppSelector(selectShortcutForNewFreeDay);
  const borderCell = useAppSelector(selectBorderCell);
  const selected = useAppSelector(selectQuickPlanningSelected);
  const isSelectedItem =
    selected[employeeId]?.[date] || (borderCell.employeeId === employeeId && borderCell.date === date);
  const isFocused = borderCell.employeeId === employeeId && borderCell.date === date;
  const availableShortcuts = useMemo(() => getFreeDaysShortcuts(intl), [intl]);
  const [isValid, setIsValid] = useState(checkIsFreeDayShortcutValid(shortcut, availableShortcuts));
  const classes = useMemo(() => getClasses(isFocused, isValid), [isFocused, isValid]);

  const isVisible = enabled && shortcut.length > 0 && isSelectedItem;

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
      setIsValid(checkIsFreeDayShortcutValid(shortcut, availableShortcuts));
    }
  }, [availableShortcuts, isFocused, shortcut]);

  const onPressEnter = useCallback(() => {
    const newIsValid = checkIsFreeDayShortcutValid(shortcut, availableShortcuts);
    setIsValid(newIsValid);
    if (!newIsValid) return;
    dispatch(quickPlanningAddNewFreeDays());
  }, [availableShortcuts, dispatch, shortcut]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;
      if (key === 'Enter') onPressEnter();
      else if (key === 'Escape') dispatch(setShortcutForNewFreeDay(''));
    },
    [onPressEnter, dispatch],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      dispatch(setShortcutForNewFreeDay(e.target.value));
      setIsValid(checkIsFreeDayShortcutValid(value, availableShortcuts));
    },
    [dispatch, availableShortcuts],
  );

  return { classes, handleChange, handleKeyPress, inputRef, isVisible, shortcut };
};
