import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  dropdownDescriptionText: {
    id: 'multiSelectOptionManager.dropdownDescriptionText',
    defaultMessage:
      '{showProperDropdownDescriptionText, select, true {Wybierz etykietę} other {Wybierz etykietę lub stwórz nową}}',
  },
  placeholder: {
    id: 'multiSelectOptionManager.placeholder',
    defaultMessage: 'Wyszukaj etykietę...',
  },
  emptyPlaceholder: {
    id: 'multiSelectOptionManager.emptyPlaceholder',
    defaultMessage: 'Kliknij aby dodać',
  },
  tooLongTagNameError: {
    id: 'multiSelectOptionManager.tooLongTagName',
    defaultMessage: 'Przekroczono limit 63 znaków',
  },
  create: {
    id: 'multiSelectOptionManager.create',
    defaultMessage: 'Stwórz',
  },
  labels: {
    id: 'multiSelectOptionManager.labels',
    defaultMessage: 'Etykiety',
  },
});
