import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AvatarSvg } from '@/components/svgComponents';

import { UseMultiAccountsValuesType } from '../useMultiAccounts';
import { useMultiAccountsSwitcher } from './useMultiAccountsSwitcher';

import './MultiAccountsSwitcher.scss';

type Props = {
  toggleMenu: UseMultiAccountsValuesType['toggleMenu'];
};

export const MultiAccountsSwitcher = memo(({ toggleMenu }: Props) => {
  const { additionalLocationsAmount, isOwner, locationName, names, avatar, roleName } = useMultiAccountsSwitcher();

  return (
    <div className="k-multiAccountsSwitcher" onClick={toggleMenu} role="presentation">
      <div className="k-multiAccountsSwitcher__avatar">
        {avatar ? <img src={avatar.medium} alt="avatar" /> : <AvatarSvg size={40} />}
      </div>
      <div className="k-multiAccountsSwitcher__details">
        <div className="k-multiAccountsSwitcher__title">
          {names.first_name} {names.last_name}
        </div>
        <div className="k-multiAccountsSwitcher__detailRow">
          <div className="k-multiAccountsSwitcher__subTitle k-multiAccountsSwitcher__subTitle--short">
            {locationName || <FormattedMessage id="location.notFoundLocation" defaultMessage="Brak lokalizacji" />}
          </div>
          <div className="k-multiAccountsSwitcher__subTitle">{additionalLocationsAmount}</div>
        </div>
        <div className="k-multiAccountsSwitcher__subTitle">
          {isOwner ? <FormattedMessage defaultMessage="Właściciel konta" id="multiAccounts.owner" /> : roleName}
        </div>
      </div>
      <div className="k-multiAccountsSwitcher__arrows">
        <i className="material-icons-outlined">unfold_more</i>
      </div>
    </div>
  );
});

MultiAccountsSwitcher.displayName = 'MultiAccountsSwitcher';
