import { connect } from 'react-redux';

import Component from './SettingsFeatureWrapper.jsx';

const mapStateToProps = state => ({
  locationSettings: state.reducer.settings.locationSettings,
  restrictions: state.reducer.userPermissions.restrictions,
});

const Container = connect(mapStateToProps)(Component);

export default Container;
