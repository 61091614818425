import { connect } from 'react-redux';

import { getInvoicePdf,payForInvoice } from '@/actions/payments';
import { showModal } from '@/actions/uiState';

import PaymentsView from './PaymentsView.jsx';

const mapStateToProps = state => ({
  payments: state.reducer.payments,
});

const mapDispatchToProps = {
  payForInvoice,
  showModal,
  getInvoicePdf,
};
const PaymentsViewContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentsView);

export default PaymentsViewContainer;
