import * as AT from '@/constants/ActionTypes';
import { deleteFreeDayMarking, deleteFreeDayMarkings } from '@/redux-store/freeDaysMarking';
import { setScheduleButtonsState } from '@/redux-store/schedule/buttonsState';

import { getNewButtonsStatus, getNewButtonsStatusForQuickPlanning } from './scheduleButtons.helpers';

const actionsThatChangeSelection = [
  AT.ADD_SCHEDULE_SELECTED_CELLS,
  AT.REMOVE_SCHEDULE_SELECTED_CELLS,
  AT.REPLACE_SCHEDULE_SELECTED_CELLS,
  AT.TOGGLE_SCHEDULE_OPEN_SHIFTS_SELECT,
  AT.MASS_DELETE_OPEN_SHIFTS_SUCCESFUL,
  AT.TOGGLE_SCHEDULE_AVAILABILITIES_MODE,
  AT.TOGGLE_SCHEDULE_TRADE_SHIFTS_SELECT,
  AT.CHANGE_TRADE_SHIFT_SUCCESFUL,
];

const actionsThatChangeQuickPlanningSelection = [
  AT.TOGGLE_QUICK_PLANNING,
  AT.QUICK_PLANNING_SET_POSITION,
  AT.QUICK_PLANNING_SET_NEW_SELECTED,
  AT.QUICK_PLANNING_ADD_TO_SELECTED,
  AT.QUICK_PLANNING_REMOVE_FROM_SELECTED,
  AT.QUICK_PLANNING_SET_DATA_TO_COPY,
  AT.QUICK_PLANNING_TOGGLE_SINGLE_SELECTION,
  AT.QUICK_PLANNING_CLEAR_SELECTION,
  AT.UPDATE_SCHEDULE_VIEW_EMPLOYEES_LIST,
  AT.DELETE_MULTIPLE_SHIFTS_SUCCESFUL,
  deleteFreeDayMarkings.fulfilled.type,
  deleteFreeDayMarking.fulfilled.type,
];

const scheduleButtonsMiddleware = store => next => action => {
  const returnState = next(action);
  // From this point we have data after action
  if (actionsThatChangeSelection.includes(action.type)) {
    const { schedule } = store.getState().reducer;
    const { buttonsState } = schedule;
    const { canDeselect, canDelete } = getNewButtonsStatus(store.getState);
    if (buttonsState.canDeselect !== canDeselect || buttonsState.canDelete !== canDelete)
      store.dispatch(setScheduleButtonsState({ canDeselect, canDelete }));
  } else if (action.type === AT.CLEAR_ALL_SCHEDULE_SELECTED_CELLS) {
    store.dispatch(setScheduleButtonsState({ canDeselect: false, canDelete: false }));
  } else if (actionsThatChangeQuickPlanningSelection.includes(action.type)) {
    store.dispatch(setScheduleButtonsState(getNewButtonsStatusForQuickPlanning(store.getState)));
  }

  return returnState;
};
export default scheduleButtonsMiddleware;
