import { getRelevantContractsForMultipleDates } from '@/utils/contracts';

export const getRelevantEmployeesDetails = (userEmployees, visibleEmployeesIds, contracts, userJobTitles, from, to) =>
  userEmployees.reduce((employees, employee) => {
    const employeeContracts = contracts[employee.id] || [];
    const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
    const relevantJobTitleIds = [
      ...new Set(relevantContracts.flatMap(contract => contract.job_titles.map(j => j.job_title_id))),
    ];
    const relevantJobTitles = userJobTitles.filter(jobTitle => relevantJobTitleIds.includes(jobTitle.id));
    return visibleEmployeesIds.includes(employee.id)
      ? [
          ...employees,
          {
            id: employee.id,
            first_name: employee.first_name,
            last_name: employee.last_name,
            job_title_id: relevantJobTitles[0]?.id,
            job_titles: relevantJobTitles.map(jobTitles => ({ id: jobTitles.id, name: jobTitles.title })),
            contracts: relevantContracts,
          },
        ]
      : employees;
  }, []);
