import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  EmplMassEditActions,
  emplMassEditActions,
} from '@/components/companymanage/employees/EditMassEmployeesModal/constants.ts';
import { ContextWithIntl } from '@/types/intl.types.ts';
import { convertDateToCustomFormat } from '@/utils/dateHelper';

const messages = defineMessages({
  added: {
    id: 'employeesEditMassSummaryStep.added',
    defaultMessage: 'dodane',
  },
  deleted: {
    id: 'employeesEditMassSummaryStep.deleted',
    defaultMessage: 'usunięte',
  },
});

interface EmployeeMassEditSummaryHeaderTextProps {
  actionType: EmplMassEditActions;
  newStartContractDate?: string;
}

const EmployeeMassEditSummaryHeaderText = (
  { actionType, newStartContractDate }: EmployeeMassEditSummaryHeaderTextProps,
  { intl }: ContextWithIntl,
) => {
  switch (actionType) {
    case emplMassEditActions.addContract:
      return (
        <FormattedMessage
          id="companymanage.employee.summaryContracts.relevantContracts"
          defaultMessage="Dla wybranych pracowników zostaną {actionDescription} kontrakty"
          values={{ actionDescription: <b>{intl.formatMessage(messages.added)}</b> }}
        />
      );
    case emplMassEditActions.remove:
      return (
        <FormattedMessage
          id="companymanage.employee.summaryContracts.relevantContracts"
          defaultMessage="Dla wybranych pracowników zostaną {actionDescription} kontrakty"
          values={{ actionDescription: <b>{intl.formatMessage(messages.deleted)}</b> }}
        />
      );
    case emplMassEditActions.addContractsJobTitles:
      return (
        <FormattedMessage
          id="companymanage.employee.summaryContracts.addContractsJobTitles"
          defaultMessage="Dla wybranych pracowników zostaną {action} stanowiska do następujących kontraktów"
          values={{ action: <b>{intl.formatMessage(messages.added)}</b> }}
        />
      );
    case emplMassEditActions.removeContractsJobTitles:
      return (
        <FormattedMessage
          id="companymanage.employee.summaryContracts.removeContractsJobTitles"
          defaultMessage="Dla wybranych pracowników zostaną {action} stanowiska z następujących kontraktów"
          values={{ action: <b>{intl.formatMessage(messages.deleted)}</b> }}
        />
      );
    case emplMassEditActions.editContractsStartDate:
      return (
        <FormattedMessage
          id="companymanage.employee.summaryContracts.editContractsStartDate"
          defaultMessage="Data {newStartContractDate} zostanie ustawiona jako data rozpoczęcia dla wybranych pracowników dla następujących kontraktów"
          values={{ newStartContractDate: <b>{convertDateToCustomFormat(newStartContractDate)}</b> }}
        />
      );

    default:
      return null;
  }
};

EmployeeMassEditSummaryHeaderText.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeeMassEditSummaryHeaderText;
