import {
  ATTENDANCE_EARLY_QUIT,
  ATTENDANCE_EARLY_START,
  ATTENDANCE_LATE_QUIT,
  ATTENDANCE_LATE_START,
  ATTENDANCE_WITHOUT_SHIFT,
  FINISHED_ATTENDANCE,
  UNFINISHED_ATTENDANCE,
} from '@/constants/attendanceDetailTypes';
import { MANAGER_EDIT_OWN_ATTENDANCES } from '@/constants/Restrictions';
import { ROLES } from '@/constants/Roles';
import { validateTimeFormat } from '@/utils/inputValidation';

export const canEditOwnAvailabilities = (userPermissions, employeeId) => {
  const { restrictions, roles, user_id: userId } = userPermissions;
  if (employeeId !== userId || !restrictions) return true;
  if (restrictions.includes(MANAGER_EDIT_OWN_ATTENDANCES) && roles.includes(ROLES.ROLE_MANAGER)) return false;
  return true;
};

export const insertAttendanceTypeForWeekView = (
  startTimeAttendance,
  startTimeShift,
  endTimeAttendance,
  endTimeShift,
  attendanceWithoutShift,
) => {
  const typeOfAttendances = [];

  if (!validateTimeFormat(endTimeAttendance)) {
    typeOfAttendances.push(UNFINISHED_ATTENDANCE);

    if (attendanceWithoutShift) {
      typeOfAttendances.push(ATTENDANCE_WITHOUT_SHIFT);
    }

    return typeOfAttendances;
  }

  typeOfAttendances.push(FINISHED_ATTENDANCE);

  if (attendanceWithoutShift) {
    typeOfAttendances.push(ATTENDANCE_WITHOUT_SHIFT);
    return typeOfAttendances;
  }

  if (startTimeAttendance < startTimeShift) {
    typeOfAttendances.push(ATTENDANCE_EARLY_START);
  }

  if (startTimeAttendance > startTimeShift) {
    typeOfAttendances.push(ATTENDANCE_LATE_START);
  }

  if (endTimeAttendance < endTimeShift) {
    typeOfAttendances.push(ATTENDANCE_EARLY_QUIT);
  }

  if (endTimeAttendance > endTimeShift) {
    typeOfAttendances.push(ATTENDANCE_LATE_QUIT);
  }

  return typeOfAttendances;
};
