import moment from 'moment';

import * as AT from '@/constants/ActionTypes.js';
import { calculateBusinessDays } from '@/utils/dateHelper.js';

import { conn } from './index';

export const getMonthlyNorms = (startYear, endYear) => (dispatch, getState) => {
  const { holidays } = getState().reducer.calendarData;
  const monthlyNorms = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      const start = moment()
        .year(year)
        .month(month)
        .startOf('month');
      const end = moment(start).endOf('month');
      const startStr = start.format('YYYY-MM-DD');
      const endStr = end.format('YYYY-MM-DD');

      const workingDays = calculateBusinessDays(start, end);
      const releventHolidays = holidays.filter(
        h => h.day !== 7 && h.freeFromWork && h.date >= startStr && h.date <= endStr,
      );
      const totalWorkingDays = workingDays - releventHolidays.length;
      monthlyNorms.push({
        start_year: start.year(),
        start_month: start.month() + 1,
        totalHours: totalWorkingDays * 8,
        totalWorkingDays,
      });
    }
  }

  dispatch({
    type: AT.GET_MONTHLY_NORMS,
    payload: monthlyNorms,
  });
};

export const getHolidays = (from, to) => dispatch =>
  new Promise((resolve, reject) => {
    const dateFrom = moment(new Date(from, 0, 1)).format('YYYY-MM-DD');
    const dateTo = moment(new Date(to, 11, 31)).format('YYYY-MM-DD');
    conn
      .getHolidays(dateFrom, dateTo)
      .then(({ data }) => {
        dispatch({
          type: AT.GET_HOLIDAYS,
          payload: data,
        });

        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
