import { EmployeeWhole } from '@/types';
import { Shift } from '@/types/shifts.types';

import { RootState } from '../types';

export const selectShiftForEmployeeByShiftId =
  (employeeId: EmployeeWhole['id'], shiftId?: Shift['id']) => (state: RootState) =>
    state.reducer.shifts.data[employeeId]?.shifts?.[shiftId];

export const selectShiftsForEmployee = (employeeId: EmployeeWhole['id']) => (state: RootState) =>
  state.reducer.shifts.data[employeeId]?.shifts;
