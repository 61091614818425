import { produce } from 'immer';

import * as actionTypes from '@/constants/ActionTypes';
import { GetUsersCustomFieldsAction } from '@/types/actions/customFields';
import { UsersCustomFieldsData } from '@/types/customFields.types';

type Action = GetUsersCustomFieldsAction;

const initialState: UsersCustomFieldsData = {};

const usersCustomFields = (state = initialState, action: Action) =>
  produce(state, () => {
    switch (action.type) {
      case actionTypes.GET_USER_CUSTOM_FIELDS_SUCCESSFUL: {
        return action.payload;
      }
      default:
        return state;
    }
  });

export default usersCustomFields;
