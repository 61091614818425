import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SETTINGS_USER_DATA_PATH } from '@/utils/routes';

import './MultiAccountsSetting.scss';

export const MultiAccountsSetting = memo(() => (
  <Link to={SETTINGS_USER_DATA_PATH}>
    <div className="k-multiAccountsSetting">
      <i className="material-icons">person</i>
      <FormattedMessage id="multiAccounts.personalInformation" defaultMessage="Informacje personalne" />
    </div>
  </Link>
));

MultiAccountsSetting.displayName = 'MultiAccountsSetting';
