import { defineMessage } from 'react-intl';

import { GlobalNotifications } from '@/middlewares/notifications/notifications';

import {
  createFreeDayMarking,
  createFreeDayMarkings,
  deleteFreeDayMarking,
  deleteFreeDayMarkings,
  updateFreeDayMarking,
  updateFreeDayMarkings,
} from './freeDaysMarking.asyncActions';

export const freeDaysMarkingNotifications: GlobalNotifications = {
  [createFreeDayMarking.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.createFreeDayMarking.error',
      defaultMessage: 'Wystąpił błąd podczas oznaczania dnia wolnego',
    }),
  },
  [createFreeDayMarking.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.createFreeDayMarking.success',
      defaultMessage: 'Pomyślnie oznaczono dzień wolny',
    }),
  },
  [createFreeDayMarkings.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.createFreeDayMarkings.error',
      defaultMessage: 'Wystąpił błąd podczas oznaczania dni wolnych',
    }),
  },
  [createFreeDayMarkings.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.createFreeDayMarkings.success',
      defaultMessage: 'Pomyślnie dodano oznaczenia dni wolnych',
    }),
  },
  [deleteFreeDayMarking.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.deleteFreeDayMarking.error',
      defaultMessage: 'Wystąpił błąd podczas usuwania oznaczenia dnia wolnego',
    }),
  },
  [deleteFreeDayMarking.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.deleteFreeDayMarking.success',
      defaultMessage: 'Pomyślnie usunięto oznaczenie dnia wolnego',
    }),
  },
  [deleteFreeDayMarkings.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.deleteFreeDayMarkings.error',
      defaultMessage: 'Wystąpił błąd podczas usuwania oznaczeń dni wolnych',
    }),
  },
  [deleteFreeDayMarkings.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.deleteFreeDayMarkings.success',
      defaultMessage: 'Pomyślnie usunięto oznaczenia dni wolnych',
    }),
  },
  [updateFreeDayMarking.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.updateFreeDayMarking.error',
      defaultMessage: 'Wystąpił błąd podczas edycji oznaczenia dnia wolnego',
    }),
  },
  [updateFreeDayMarking.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.updateFreeDayMarking.success',
      defaultMessage: 'Pomyślnie edytowano oznaczenie dnia wolnego',
    }),
  },
  [updateFreeDayMarkings.rejected.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.updateFreeDayMarkings.error',
      defaultMessage: 'Wystąpił błąd podczas edycji oznaczeń dni wolnych',
    }),
  },
  [updateFreeDayMarkings.fulfilled.type]: {
    title: defineMessage({
      id: 'freeDaysMarking.updateFreeDayMarkings.success',
      defaultMessage: 'Pomyślnie edytowano oznaczenia dni wolnych',
    }),
  },
};
