import { getNumberOfWeekday, getRangeBetweenDates } from '@/utils/dateHelper';

export const mapJobTitlesAndValues = (schedule, day) =>
  Object.keys(schedule[day]).map(jobTitleId => {
    const values = {};
    schedule[day][jobTitleId].forEach((value, i) => {
      if (value) {
        const hourFromIndex = `${`${i}`.padStart(2, '0')}:00`;
        values[hourFromIndex] = value;
      }
    });
    return {
      job_title_id: jobTitleId,
      values,
    };
  });

export const getRecommendedScheduleData = (customDate, schedule, selectedLocationId) => {
  const { start, end } = customDate;
  const dates = getRangeBetweenDates(start, end);
  return dates.reduce((acc, date) => {
    const day = getNumberOfWeekday(date);

    if (date && schedule[day]) {
      const jobTitles = mapJobTitlesAndValues(schedule, day);

      acc.push({
        date,
        location_id: selectedLocationId,
        template: jobTitles,
      });
    }

    return acc;
  }, []);
};
