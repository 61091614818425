import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/redux-store';
import { selectFreeDay } from '@/redux-store/freeDaysMarking';
import { FreeDaysMarkingEnum } from '@/types/freeDaysMarking.types';
import { getFreeDays } from '@/utils/freeDaysMarking/freeDaysMarking';

import { AddFreeDayTabProps } from './AddFreeDayTab.types';

export const useAddFreeDayTab = ({
  date,
  employeeId,
  freeDayItemId,
  selectedFreeDayId,
  handleInputChange,
}: AddFreeDayTabProps) => {
  const intl = useIntl();
  const freeDay = useAppSelector(selectFreeDay(employeeId, date));
  const freeDayError = !!freeDay && !freeDayItemId;
  const freeDays = useMemo(() => Object.values(getFreeDays(intl)), [intl]);

  const onFreeDayClick = useCallback(
    (freeDay: FreeDaysMarkingEnum) => {
      const value = selectedFreeDayId === freeDay ? null : freeDay;
      handleInputChange({ target: { value, name: 'selectedFreeDayId' } });
    },
    [handleInputChange, selectedFreeDayId],
  );

  return { freeDays, intl, onFreeDayClick, freeDayError };
};
