import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDSearchBox } from '@/components/common/inputs/MDComponents.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { sortByKey } from '@/utils/arrayHelpers';

import DashboardLocationsHeader from '../DashboardLocationsHeader/DashboardLocationsHeader.redux';
import DashboardLocationsItem from '../DashboardLocationsItem/DashboardLocationsItem.redux.js';

import './DashboardLocations.scss';

const DashboardLocations = ({ userLocations, locationSummary, locationsSorting }) => {
  const locationsWithSummary = userLocations.map(location => ({
    ...location,
    ...(locationSummary[location.id] || {}),
    locationId: location.id,
  }));

  const [searchText, setSearchText] = useState('');
  const [locationsToShow, setLocationsToShow] = useState(locationsWithSummary);

  useEffect(() => {
    const filteredLocations = locationsWithSummary.filter(location => {
      const { name, city } = userLocations.find(({ id }) => id === location.locationId);
      return (
        name.toLowerCase().includes(searchText.toLowerCase()) || city.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setLocationsToShow(filteredLocations);
  }, [searchText, locationSummary]);

  const { sortColumn, sortDecreasing } = locationsSorting;

  return (
    <div className="md-dashboardContainer md-dashboardLocation">
      <div className="md-dashboardLocation__header">
        <div className="md-dashboardContainer__title">
          <i className="md-dashboardContainer__icon material-icons">store</i>
          <FormattedMessage id="dashboard.locations" defaultMessage="Podsumowanie lokalizacji" />
        </div>
        <div className="md-dashboardLocation__options">
          <MDSearchBox searchText={searchText} handleSearchText={e => setSearchText(e.target.value)} />
        </div>
      </div>
      {locationsToShow.length > 0 ? (
        <>
          <DashboardLocationsHeader />
          <OverlayScrollbarsComponent className="k-overlay-scrollbar md-dashboardLocation__container">
            {sortByKey(locationsToShow, sortColumn || 'name', false, { decrease: sortDecreasing }).map(location => (
              <DashboardLocationsItem location={location} userLocations={userLocations} key={location.locationId} />
            ))}
          </OverlayScrollbarsComponent>
        </>
      ) : (
        <EmptyState
          name="locationCTA"
          imgSrc="/img/locationsCTA.png"
          emptyStateHeader=""
          emptyStateText={
            <FormattedMessage
              id="dashboard.locations.noLocations"
              defaultMessage="Brak danych dla wybranych lokalizacji"
            />
          }
          ctaText=""
        />
      )}
    </div>
  );
};

DashboardLocations.propTypes = {
  userLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locationSummary: PropTypes.shape({}),
  locationsSorting: PropTypes.shape({
    sortColumn: PropTypes.string,
    sortDecreasing: PropTypes.bool,
  }),
};

DashboardLocations.defaultProps = {
  locationSummary: {},
};

export default DashboardLocations;
