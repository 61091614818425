import { activateVerifyWorkingRulesButton,getWorkingRulesViolationsForEmployees } from '@/actions/workingRules.js';
import {
  ADD_MASS_SHIFTS_SUCCESFUL,
  ADD_SHIFT_FAILURE,
  ADD_SHIFT_SUCCESFUL,
  ADD_SHIFTS_SUCCESFUL,
  CLEAR_VIEW_SCHEDULE_SUCCESS,
  CLOSE_TRADE_SHIFT_SUCCESFUL,
  DELETE_MASS_SHIFTS_SUCCESFUL,
  DELETE_SHIFT,
  DUPLICATE_PREVIOUS_VIEW_SUCCESS,
  EDIT_SHIFT_SUCCESFUL,
  GET_WORKING_RULES_VIOLATIONS,
} from '@/constants/ActionTypes.js';
import { WORKING_RULES_HIDE } from '@/constants/Restrictions.js';
import { filterActiveEmployeesByLocations } from '@/utils/userEmployeesHelpers';

const workingRulesMiddleware = store => next => action => {
  const {
    userPermissions,
    userEmployees,
    scheduleUIState,
    mainDateStore,
    scheduleLocationFilter,
  } = store.getState().reducer;
  const workingRulesSettings = scheduleUIState.settings.find(setting => setting.type === 'workingRules') || {
    value: false,
  };
  if (userPermissions.restrictions.includes(WORKING_RULES_HIDE) || !workingRulesSettings.value) {
    return next(action);
  }

  const { start, end } = mainDateStore.customDate;

  switch (action.type) {
    case ADD_MASS_SHIFTS_SUCCESFUL: {
      const shiftDates = action.payload.map(shift => shift.date);
      if (shiftDates.some(date => mainDateStore.dateArray.includes(date))) {
        store.dispatch(activateVerifyWorkingRulesButton());
      }

      break;
    }
    case ADD_SHIFT_SUCCESFUL:
    case ADD_SHIFTS_SUCCESFUL:
    case EDIT_SHIFT_SUCCESFUL:
    case DELETE_SHIFT:
    case ADD_SHIFT_FAILURE:
    case DUPLICATE_PREVIOUS_VIEW_SUCCESS:
    case CLEAR_VIEW_SCHEDULE_SUCCESS:
    case DELETE_MASS_SHIFTS_SUCCESFUL:
      store.dispatch(activateVerifyWorkingRulesButton());
      break;
    case CLOSE_TRADE_SHIFT_SUCCESFUL: {
      const employee = userEmployees.find(emp => emp.id === action.payload.employeeId);
      if (employee.employment_conditions.validate_working_rules) {
        store.dispatch(getWorkingRulesViolationsForEmployees([action.payload.employeeId], start, end, true));
      }
      break;
    }
    case GET_WORKING_RULES_VIOLATIONS: {
      const locationIds = scheduleLocationFilter;
      const employeesForCurrentLocation = filterActiveEmployeesByLocations(userEmployees, locationIds);
      const userEmployeesIds = employeesForCurrentLocation.map(l => l.id);
      store.dispatch(getWorkingRulesViolationsForEmployees(userEmployeesIds, action.payload.from, action.payload.to));
      break;
    }
    default:
      break;
  }
  return next(action);
};

export default workingRulesMiddleware;
