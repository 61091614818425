import moment from 'moment';
import PropTypes from 'prop-types';

import ScheduleTableItemBackground from '../ScheduleTableItemBackground/ScheduleTableItemBackground.redux';

const ScheduleHeaderDate = ({ date, showCommentIcon, showFreeFromWorkIcon, showWorkHolidayIcon, isMonthView }) => (
  <>
    <ScheduleTableItemBackground date={date} />

    <div className="scheduleTableHeader__contentContainer">
      {!isMonthView && showCommentIcon && <i className="material-icons managerCommentIcon">comment</i>}
      {!isMonthView && showFreeFromWorkIcon && <i className="material-icons calendar-icon">today</i>}
      {!isMonthView && showWorkHolidayIcon && <i className="material-icons calendar-icon">calendar_today</i>}
      {!isMonthView ? (
        <span>{moment(date).format('dddd DD.MM')}</span>
      ) : (
        <span>
          {moment(date).format('dd')}
          <br />
          {moment(date).format('DD.MM')}
        </span>
      )}
    </div>
  </>
);

ScheduleHeaderDate.propTypes = {
  date: PropTypes.string,
  showCommentIcon: PropTypes.bool,
  showFreeFromWorkIcon: PropTypes.bool,
  showWorkHolidayIcon: PropTypes.bool,
  isMonthView: PropTypes.bool,
};
export default ScheduleHeaderDate;
