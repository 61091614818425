import { connect } from 'react-redux';

import { addAccount, switchAccount } from '@/actions/multiAccounts/multiAccounts.ts';
import { AccountSwitcher } from '@/components/settings/AccountSwitcher/AccountSwitcher.tsx';

const mapDispatchToProps = {
  addAccount,
  switchAccount,
};

export default connect(null, mapDispatchToProps)(AccountSwitcher);
