import PropTypes from 'prop-types';

import Select from '@/components/common/inputs/MDSelect/Select';
import { findSettingById, getSelectOptionsFromSettings } from '@/utils/inputHelpers';

const PayrollSettingsModalWorkTimeSelect = ({ changeSetting, settingType, settings, selectOptions }, { intl }) => (
  <Select
    onChange={value => {
      changeSetting(settingType, findSettingById(selectOptions, value));
    }}
    defaultValue={settings[settingType].id}
    options={getSelectOptionsFromSettings(selectOptions, intl)}
    closeOnClick
  />
);

PayrollSettingsModalWorkTimeSelect.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PayrollSettingsModalWorkTimeSelect.propTypes = {
  changeSetting: PropTypes.func.isRequired,
  settingType: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PayrollSettingsModalWorkTimeSelect;
