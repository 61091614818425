import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import OrangeSplitHorizontal from '@/components/common/layouts/OrangeSplitHorizontal/OrangeSplitHorizontal.jsx';

import '../unlockAccountView.scss';

const AppBlocked = ({
  logoutUser,
  buttonText,
  titleMessage,
  contentMessage,
  handleButtonClick,
  additionalTextMessage,
  buttonDisabled,
  imgSrc,
  alt,
  hideButton,
}) => (
  <OrangeSplitHorizontal hideLogo>
    <div className="k-unlockAccount__itemsContainer">
      <img alt={alt} src={imgSrc} className="k-unlockAccount__promoImage" />
      <h1 className="k-unlockAccount__title">
        <FormattedMessage {...titleMessage} />
      </h1>
      <p className="k-unlockAccount__text">
        <FormattedMessage {...contentMessage} />
      </p>
      {additionalTextMessage && (
        <p className="k-unlockAccount__text">
          <FormattedMessage {...additionalTextMessage} />
        </p>
      )}
      {!hideButton && (
        <Button
          onClick={handleButtonClick}
          className="k-unlockAccount__button"
          modifiers="high medium uppercase orange"
          disabled={buttonDisabled}
        >
          <FormattedMessage {...buttonText} />
        </Button>
      )}
      <div className="k-unlockAccount__goBackText" onClick={logoutUser} role="presentation">
        <FormattedMessage id="unblockAccountView.returnToLoginView" defaultMessage="Powrót do logowania" />
      </div>
    </div>
  </OrangeSplitHorizontal>
);

AppBlocked.propTypes = {
  logoutUser: PropTypes.func,
  buttonText: PropTypes.shape({}),
  titleMessage: PropTypes.shape({}),
  contentMessage: PropTypes.shape({}),
  handleButtonClick: PropTypes.func,
  additionalTextMessage: PropTypes.shape({}),
  buttonDisabled: PropTypes.bool,
  alt: PropTypes.string,
  imgSrc: PropTypes.string,
  hideButton: PropTypes.bool,
};
export default AppBlocked;
