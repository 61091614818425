import { connect } from 'react-redux';

import { showModalToDeleteLabel } from '@/actions/labels.ts';

import AddAndEditLabelPopoverContentFooter from './AddAndEditLabelPopoverContentFooter.tsx';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showModalToDeleteLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAndEditLabelPopoverContentFooter);
