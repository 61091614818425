import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Clock from '@/components/common/Basic/Clock.jsx';

import './DashboardWelcome.scss';

function DashboardWelcome({ firstName, language }) {
  const [location, setLocation] = useState();
  const [city, setCity] = useState('');

  useEffect(() => {
    if (!navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
    });
  }, []);

  useEffect(() => {
    const fetchCityByCoords = async () => {
      if (location) {
        try {
          const { latitude, longitude } = location;
          const params = `latitude=${latitude}&longitude=${longitude}&localityLanguage=${language}`;
          const resultGeocode = await axios.get(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?${params}`
          );
          setCity(resultGeocode.data.city);
        } catch (err) {
          console.log('Problem with getting city');
        }
      }
    };
    fetchCityByCoords();
  }, [location]);

  return (
    <div className="md-dashboardWelcome">
      <div className="md-dashboardWelcome__info">
        <h2 className="md-dashboardWelcome__title">
          <FormattedMessage
            id="dashboard.welcome.goodMorning"
            defaultMessage="Dzień dobry"
          />
          {`, ${firstName}`}
        </h2>
        <p className="md-dashboardWelcome__description">
          <FormattedMessage
            id="dashboard.welcome.day"
            defaultMessage="Dzisiaj jest"
          />{' '}
          {moment().format('dddd, DD MMMM YYYY')}{' '}
          {location && city && <span>/{city}</span>}
        </p>
      </div>
      <div className="md-dashboardWelcome__date">
        <Clock />
      </div>
    </div>
  );
}

DashboardWelcome.propTypes = {
  firstName: PropTypes.string,
  language: PropTypes.string,
};
DashboardWelcome.defaultProps = {};

export default DashboardWelcome;
