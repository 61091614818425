import { connect } from 'react-redux';

import { handleMouseDownItem, handleMouseOverItem,handleMouseUpItem } from '@/actions/schedule/quickPlanning';

import ScheduleTableItem from './ScheduleTableItem.jsx';

const mapStateToProps = (state, ownProps) => ({
  mainDateStore: state.reducer.mainDateStore,
  isQuickPlanningEnabled: state.reducer.schedule.quickPlanning.enabled,
  employeeShifts: state.reducer.shifts.data[ownProps.employee?.id]?.shifts,
  showShiftsFromOtherLocations: state.reducer.schedule.viewSettings.showShiftsFromOtherLocations.value,
  viewMode: state.reducer.schedule.viewMode.viewMode,
  canDeselect: state.reducer.schedule.buttonsState.canDeselect,
});

const mapDispatchToProps = {
  handleMouseDownItem,
  handleMouseUpItem,
  handleMouseOverItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTableItem);
