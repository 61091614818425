import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './NotificationBar.scss';

const NotificationBar = ({ show, canClose, close, content, buttonText, buttonOnClick }) => {
  if (!show) return null;

  const className = classnames('k-notificationBar', 'k-notificationBar--orange', {
    'k-notificationBar--no-hover': buttonText || buttonOnClick,
  });

  return (
    <div>
      <div className={className}>
        <div className="k-notificationBar__message">
          {content}
          {Boolean(buttonOnClick) && (
            <div className="k-notificationBar__button" role="presentation" onClick={buttonOnClick}>
              <FormattedMessage id="trailInfoTopBar.button" defaultMessage="Skontaktuj się z nami" />
            </div>
          )}
          {canClose && (
            <i role="presentation" onClick={close} className="material-icons k-notificationBar__closeIcon">
              close
            </i>
          )}
        </div>
      </div>
      <div className="k-notificationBar__margin" />
    </div>
  );
};

NotificationBar.propTypes = {
  content: PropTypes.shape({}),
  canClose: PropTypes.bool,
  close: PropTypes.func,
  show: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

export default NotificationBar;
