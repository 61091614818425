import { connect } from 'react-redux';

import { deleteExportHistoryItem, downloadExport } from '@/actions/exports/exportsHistory/exportsHistory.js';

import ExportItem from './ExportItem';

const mapStateToProps = state => ({
  allJobTitles: state.reducer.jobTitles,
  userLocations: state.reducer.userLocations,
});

const mapDispatchToProps = {
  deleteExportHistoryItem,
  downloadExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportItem);
