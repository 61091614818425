import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

import Tag from '@/components/common/Tag/Tag.tsx';
import { SPECIAL_CHARACTERS_PATTERN } from '@/constants/regexPatterns.ts';

import Popover from '../../Popover/Popover.jsx';
import { getInitialState, LabelOption, updateState } from './multiSelectOptionManager.helpers';
import { messages } from './multiSelectOptionManager.messages.ts';
import MultiSelectOptionManagerDropDown from './MultiSelectOptionManagerDropDown/MultiSelectOptionManagerDropDown.tsx';

import './multiSelectOptionManager.scss';

interface MultiSelectOptionManagerProps {
  options: LabelOption[];
  defaultOptions: string[];
  selectItemsId?: (selectedOptionsIds: string[]) => void;
  submitOnAction?: boolean;
  attendanceId?: string;
  employeeId?: string;
  asInput?: boolean;
  disabled?: boolean;
  className?: string;
  selectPopoverStatus?: (popoverStatus: boolean) => void;
  hideAddAndEdit?: boolean;
  onBlur?: () => void;
  isModal?: boolean;
}

const Y_OFFSET = -15;

const popoverStyles = { padding: 0, borderRadius: 2, border: '0px solid transparent' };

const MultiSelectOptionManager = (
  {
    options,
    defaultOptions,
    selectItemsId,
    submitOnAction = false,
    attendanceId,
    employeeId,
    asInput,
    disabled,
    className,
    selectPopoverStatus,
    hideAddAndEdit,
    onBlur,
    isModal,
  }: MultiSelectOptionManagerProps,
  { intl },
) => {
  const [placeholderText, setPlaceholderText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(getInitialState(options, defaultOptions));
  const [searchText, setSearchText] = useState('');
  const [dropdownListWidth, setDropdownListWidth] = useState();
  const [isInternalPopoverOpen, setInternalIsPopoverOpen] = useState(false);
  const [popoverStatus, setPopoverStatus] = useState(false);
  const dropDownRef = useRef(null);

  const [error, setError] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const isSelectedAtLeastOneOption = selectedOptions.some(opt => opt.selected);

  const inputCointainerClassName = classnames('k-multiSelectOptionManager__inputContainer', {
    'k-multiSelectOptionManager__inputContainer--center': !selectedOptions.length,
    'k-multiSelectOptionManager__inputContainer--asInput': asInput,
    'k-multiSelectOptionManager__inputContainer--disabled': disabled,
  });

  const inputClassName = classnames('k-multiSelectOptionManager__input', {
    'k-multiSelectOptionManager__input--default': isSelectedAtLeastOneOption,
    'k-multiSelectOptionManager__input--asInput': asInput,
    'k-multiSelectOptionManager__input--hide': isSelectedAtLeastOneOption && !popoverStatus,
    'k-multiSelectOptionManager__input--disabled': disabled,
    'k-multiSelectOptionManager__input--shwoInitialCursor': popoverStatus,
  });

  const multiSelectOptionManagerClassName = classnames('k-multiSelectOptionManager', {
    'k-multiSelectOptionManager--asInput': asInput,
    'k-multiSelectOptionManager--emptyState': asInput && !isSelectedAtLeastOneOption && !popoverStatus,
    [className]: className,
  });

  const labelClassName = classnames('k-multiSelectOptionManager__label', {
    'k-multiSelectOptionManager__label--focus': popoverStatus || isSelectedAtLeastOneOption,
  });

  const handleTagDelete = useCallback(
    (option: LabelOption) => {
      setSelectedOptions(prevOptions =>
        prevOptions.map(prevOption =>
          prevOption.id === option.id ? { ...option, show: true, selected: false } : prevOption,
        ),
      );
    },
    [setSelectedOptions, submitOnAction, attendanceId, employeeId],
  );

  const handleOptionSelect = useCallback(
    (option: LabelOption) => {
      setSelectedOptions(prevOptions =>
        prevOptions.map(prevOption =>
          prevOption.id === option.id ? { ...option, selected: true, show: false } : prevOption,
        ),
      );
    },
    [setSelectedOptions, submitOnAction, attendanceId, employeeId],
  );

  const onChange = useCallback(
    ({ target }) => {
      const containsSpecialCharacters = SPECIAL_CHARACTERS_PATTERN.test(target.value);
      if (containsSpecialCharacters) return;
      if (target.value.length <= 63) {
        setSearchText(target.value);
        setError('');
      } else {
        setError(intl.formatMessage(messages.tooLongTagNameError));
      }
    },
    [setSearchText, setError],
  );

  const clearInputValue = () => {
    setSearchText('');
    setError('');
  };

  const focusInput = () => {
    if (inputRef?.current && popoverStatus) inputRef.current.focus();
  };

  const blurInput = () => {
    if (inputRef?.current) inputRef.current.blur();
  };

  useEffect(() => {
    setSelectedOptions(getInitialState(options, defaultOptions));
  }, [defaultOptions?.length]);

  useEffect(() => {
    if (searchText !== '') {
      const selectedTags = updateState(options, selectedOptions).map(prevOption =>
        !prevOption.label.toLocaleLowerCase().includes(searchText.toLowerCase())
          ? { ...prevOption, show: false }
          : { ...prevOption, show: true },
      );
      setSelectedOptions(selectedTags);
      return;
    }
    let isNewLabelSelected;

    if (attendanceId) isNewLabelSelected = attendanceId && popoverStatus;
    if (isModal) isNewLabelSelected = true;

    const selectedTags = updateState(options, selectedOptions, isNewLabelSelected).map(prevOption => ({
      ...prevOption,
      show: true,
    }));
    setSelectedOptions(selectedTags);
  }, [searchText, options]);

  useEffect(() => {
    if (selectItemsId) {
      const selectedOptionsIds = selectedOptions.reduce((acc, label) => {
        if (label.selected === true) {
          acc.push(label.id);
        }
        return acc;
      }, []);
      selectItemsId(selectedOptionsIds);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (popoverStatus) return;

    clearInputValue();
    blurInput();

    if (!asInput) setPlaceholderText('');
  }, [popoverStatus, setPopoverStatus]);

  useEffect(() => {
    if (selectPopoverStatus) selectPopoverStatus(popoverStatus);
  }, [selectPopoverStatus, popoverStatus]);

  useEffect(() => {
    if (isInternalPopoverOpen) blurInput();
  }, [setInternalIsPopoverOpen, isInternalPopoverOpen]);

  useEffect(() => {
    if (popoverStatus && !isInternalPopoverOpen) focusInput();
  }, [selectedOptions, popoverStatus, isInternalPopoverOpen]);

  return (
    <Popover
      disabled={disabled}
      addUpdateListeners
      showShadow={false}
      hideOnBlur={!isInternalPopoverOpen}
      noArrow
      noBorder
      yOffset={Y_OFFSET}
      popoverStyle={popoverStyles}
      getRef={ref => setDropdownListWidth(ref?.clientWidth)}
      centerOnMobile={false}
      setDisplayStatus={setPopoverStatus}
      onBlur={onBlur}
      content={
        <div ref={dropDownRef}>
          <MultiSelectOptionManagerDropDown
            selectedOptions={selectedOptions}
            handleOptionSelect={handleOptionSelect}
            searchText={searchText}
            width={dropdownListWidth}
            setPopoverStatus={setInternalIsPopoverOpen}
            error={error}
            asInput={asInput}
            clearInputValue={clearInputValue}
            isDropdownActive={popoverStatus}
            hideAddAndEdit={hideAddAndEdit}
          />
        </div>
      }
    >
      <div className={multiSelectOptionManagerClassName} role="presentation" onClick={focusInput}>
        <div className={inputCointainerClassName}>
          {selectedOptions.map(
            option =>
              option.selected && (
                <Tag
                  key={option.id}
                  color={option.color}
                  name={option.label}
                  deleteTag={() => handleTagDelete(option)}
                  className="k-multiSelectOptionManager__tag"
                  disabled={disabled}
                />
              ),
          )}

          <input
            ref={inputRef}
            type="text"
            className={inputClassName}
            value={searchText}
            disabled={disabled}
            placeholder={!selectedOptions.some(opt => opt.selected) && placeholderText}
            onFocus={() => {
              !asInput && setPlaceholderText(intl.formatMessage(messages.placeholder));
            }}
            onChange={onChange}
            onMouseEnter={() =>
              !asInput && !popoverStatus && setPlaceholderText(intl.formatMessage(messages.emptyPlaceholder))
            }
            onMouseLeave={() => !asInput && !popoverStatus && setPlaceholderText('')}
          />
          {asInput && <label className={labelClassName}>{intl.formatMessage(messages.labels)}</label>}
        </div>
      </div>
    </Popover>
  );
};

MultiSelectOptionManager.contextTypes = {
  intl: PropTypes.shape({}),
};
export default MultiSelectOptionManager;
