import moment from 'moment';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ColorLabel } from '@/components/common/ColorLabel/ColorLabel';
import Checkbox from '@/components/common/inputs/Checkbox.jsx';
import OptionsCell from '@/components/common/OptionsCell.jsx';
import LabelsContainer from '@/components/common/UI/LabelsContainer/LabelsContainer.jsx';
import { SETTINGS_DEVICE_MODAL } from '@/constants/modalTypes.js';
import { COMPANY_MANAGE_DEVICES_HIDE_DELETE, COMPANY_MANAGE_DEVICES_HIDE_EDIT } from '@/constants/Restrictions.js';
import { applyPermissionsForOptions } from '@/utils/componentHelpers.jsx';

const DevicesTableRow = props => {
  const { device, toggleCheckbox, uiData } = props;

  const rowOptions = [
    {
      option: <FormattedMessage id="common.settings" defaultMessage="Ustawienia" />,
      restriction: COMPANY_MANAGE_DEVICES_HIDE_EDIT,
      style: 'edit',
      function: () => props.showModal(SETTINGS_DEVICE_MODAL, device),
    },
    {
      option: <FormattedMessage id="common.refreshData" defaultMessage="Odśwież" />,
      restriction: COMPANY_MANAGE_DEVICES_HIDE_EDIT,
      style: 'settings',
      function: () => props.refreshDeviceData(device.uuid),
    },
    {
      option: <FormattedMessage id="common.delete" defaultMessage="Usuń" />,
      restriction: COMPANY_MANAGE_DEVICES_HIDE_DELETE,
      style: 'delete',
      function: () => props.deleteDeviceConfirm(device),
    },
  ];

  const { options, styles, functions } = applyPermissionsForOptions(rowOptions, props.userPermissions);
  const locations = device.settings
    ? props.userLocations.filter(loc => device.settings.locations.includes(loc.id))
    : [];

  return (
    <tr>
      <td className="k-devicesTable__device_name">
        <Checkbox
          toggle={() => {
            toggleCheckbox(device.uuid, 'devices');
          }}
          checked={uiData.selected.includes(device.uuid)}
        />
        <div className="k-devicesTable__col_fullname">{device.settings.name}</div>
      </td>
      <td className="center">{device.brand}</td>
      <td className="center">{device.model}</td>
      <td className="center">{moment(device.authorized_at).format('YYYY-MM-DD HH:mm')}</td>
      <td className="center">
        {locations.length ? (
          <LabelsContainer
            limit={4}
            moreAction={() => {
              props.showModal(SETTINGS_DEVICE_MODAL, device);
            }}
          >
            {locations.map((location, index) => (
              <ColorLabel key={index} text={location.name} color={location.color} />
            ))}
          </LabelsContainer>
        ) : (
          <FormattedMessage id="companymanage.devices.noLocations" defaultMessage="Brak lokalizacji" />
        )}
      </td>
      <td className="center">
        <OptionsCell options={options} styles={styles} functions={functions} />
      </td>
    </tr>
  );
};

DevicesTableRow.propTypes = {
  device: PropTypes.shape({}),
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  userLocations: PropTypes.arrayOf(PropTypes.shape({})),
  showModal: PropTypes.func,
  deleteDeviceConfirm: PropTypes.func,
  toggleCheckbox: PropTypes.func,
  uiData: PropTypes.shape({}),
};

export default DevicesTableRow;
