import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import AttendanceEditDataView from '@/components/payroll/PayrollEditShiftsAndAttendancesModal/AttendanceEditDataView.jsx';
import ShiftEditDataView from '@/components/payroll/PayrollEditShiftsAndAttendancesModal/ShiftEditDataView.redux';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import { useInputChange } from '@/hooks';
import { isAttendanceEditDisabled } from '@/utils/attendanceHelpers';
import { createEvent, validateInput as validateInputHelper } from '@/utils/inputHelpers';
import { checkIfCanAddEditDeleteLabels, showLabelsFeature } from '@/utils/labels';
import { checkAttendanceEditingBlocked } from '@/utils/locationsHelpers';
import { getEmployeeJobTitlesAsSelectOptions } from '@/utils/userEmployeesHelpers';

import PayrollEditShiftAndAttendanceDetails from './PayrollEditShiftAndAttendanceDetails/PayrollEditShiftAndAttendanceDetails';
import {
  getDataForValidation,
  getFooterOptions,
  getModalTitle,
  getUpdatedState,
  initialState,
  MODAL_MODIFIERS,
} from './PayrollEditShiftAndAttendanceModal.helpers';
import { messages } from './PayrollEditShiftAndAttendanceModal.messages';

import './PayrollEditShiftAndAttendanceModal.scss';

const PayrollEditShiftAndAttendanceModal = (props, { intl }) => {
  const [state, handlers] = useInputChange(initialState, intl);
  const [originalShiftHours, setOriginalShiftHours] = useState(null);
  const [loading, setLoading] = useState(false);
  const breakRef = useRef(null);
  const [selectedLabels, setSelectedlabels] = useState([]);
  const [selectLabelsPopoverStatus, setSelectLabelsPopoverStatus] = useState(false);

  const { userPermissions, currentUser, companyRoles, contracts, userJobTitles } = props;
  const { shiftId, attendanceId, selectedEmployee } = props.modalObject || {};
  const selectedEmployeeId = selectedEmployee?.id;
  const labelsAssignedToAttendance = state?.attendance?.labels?.map(({ id }) => id);

  const { role_id: roleId, role } = currentUser.user;
  const { permissions } = userPermissions;

  const showLabels = showLabelsFeature(role, permissions);

  const clearAndHide = useCallback(() => {
    handlers.setInitState();
    props.hideModal();
  }, []);

  useEffect(() => {
    const fetchShiftOrAttendance = async () => {
      const isShift = !!shiftId;
      const id = isShift ? shiftId : attendanceId;
      setLoading(true);
      try {
        const { shift, attendance } = await props.getShiftOrAttendanceForPayroll(id, isShift);
        const updatedState = getUpdatedState(
          props.userEmployees,
          selectedEmployeeId,
          shift,
          attendance,
          props.payrollLoanedEmployees,
          props.userLocations,
        );
        handlers.updateState(updatedState);
        if (shift) setOriginalShiftHours(shift.working_hours);
      } catch (err) {
        clearAndHide();
      } finally {
        setLoading(false);
      }
    };
    if (shiftId || attendanceId) fetchShiftOrAttendance();
  }, [shiftId, attendanceId]);

  const isLoanedShiftForSupervisior = useMemo(() => {
    if (!state.shift.location) return false;
    return (
      props.userEmployees.some(({ id }) => id === selectedEmployeeId) &&
      !props.userLocations.some(({ id }) => id === state.shift.location.id)
    );
  }, [state, selectedEmployeeId, props.userLocations, props.userEmployees]);

  const isAttendanceEditingBlocked = useMemo(
    () =>
      isAttendanceEditDisabled(userPermissions, currentUser, selectedEmployee, companyRoles) ||
      checkAttendanceEditingBlocked(props.locationSettings, state.locationId, state.date, currentUser),
    [
      state.locationId,
      state.date,
      props.locationSettings,
      userPermissions,
      selectedEmployeeId,
      companyRoles,
      selectedEmployee,
      currentUser,
    ],
  );

  const isShiftEditingBlocked = useMemo(
    () => userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE) || isLoanedShiftForSupervisior,
    [userPermissions.restrictions, isLoanedShiftForSupervisior],
  );

  const footerFunctions = useMemo(
    () => ({
      showDeleteAttendanceConfirmModal: props.showDeleteAttendanceConfirmModal,
      showDeleteShiftForPayrollConfirmModal: props.showDeleteShiftForPayrollConfirmModal,
      showModalToAddShiftForExistingAttendance: props.showModalToAddShiftForExistingAttendance,
    }),
    [],
  );

  const footerOptions = useMemo(
    () =>
      getFooterOptions(
        intl,
        state.shift,
        state.originalAttendance,
        selectedEmployeeId,
        footerFunctions,
        isAttendanceEditingBlocked,
        isShiftEditingBlocked,
      ),
    [state.shift.id, state.originalAttendance, selectedEmployeeId, isAttendanceEditingBlocked, isShiftEditingBlocked],
  );

  const validateAll = async () => {
    const dataForValidation = getDataForValidation(state);
    const validationResult = await Promise.all(
      dataForValidation.map(async d => {
        const [, error] = await validateInputHelper(createEvent(d.name, d.value));
        handlers.setError(d.id, error);
        return error;
      }),
    );
    return !Object.values(validationResult).some(err => err);
  };

  const submit = useCallback(async () => {
    const isValid = await validateAll();
    if (!isValid) return;
    if (state.attendance.id) {
      const breakChanges = breakRef.current.getBreaksChanges();
      if (breakChanges.error) return;
      props.handleMultipleBreaksActions(breakChanges);
    }
    const { shift, attendance, originalShift, originalAttendance } = state;

    const unassignedLabels = labelsAssignedToAttendance?.filter(label => !selectedLabels?.includes(label));
    const assignLabelsToAttendance = selectedLabels?.filter(label => !labelsAssignedToAttendance?.includes(label));

    try {
      await props.editShiftAndAttendanceNewPayroll(shift, attendance, originalShift, originalAttendance);
      if (assignLabelsToAttendance?.length)
        props.assignLabelsToAttendance(state.attendance.id, selectedEmployeeId, assignLabelsToAttendance);

      if (unassignedLabels?.length)
        props.unassignLabelsFromAttendance(state.attendance.id, selectedEmployeeId, unassignedLabels);
    } catch (err) {
      if (err.message === 'employeeIsBusy') {
        handlers.setError('shiftsOverlap', messages.workingHoursSingleOverlap);
        return;
      }
    }
    clearAndHide();
  }, [state.shift, state.attendance, selectedLabels]);

  const handleAttendanceChange = useCallback(
    ({ name, value }) => {
      const changedAttendance = { ...state.attendance, [name]: value };
      handlers.changeInput(createEvent('attendance', changedAttendance));
    },
    [state.attendance],
  );

  const handleShiftChange = useCallback(
    ({ name, value }) => {
      const changedShift = { ...state.shift, [name]: value };
      handlers.changeInput(createEvent('shift', changedShift));
    },
    [state.shift],
  );

  const enableWorkingRulesCheck = Boolean(
    props.locationSettings[state.shift?.location?.id]?.enable_working_rules_check,
  );
  const disableConfirmButton =
    enableWorkingRulesCheck && originalShiftHours && originalShiftHours !== state.shift?.working_hours;
  const shiftError = disableConfirmButton
    ? intl.formatMessage(messages.workingRulesError)
    : state.errors.shiftHours || state.errors.shiftsOverlap;

  const disabledAddAndEditLabels = useMemo(
    () => !checkIfCanAddEditDeleteLabels(companyRoles, roleId, role, permissions),
    [companyRoles, roleId, role, permissions],
  );

  const multiSelectOptionManagerClassnames = classnames(
    'payrollEditShiftAndAttendanceModal__multiSelectOptionManager',
    {
      'payrollEditShiftAndAttendanceModal__multiSelectOptionManager--smallMargin':
        !selectedLabels.length && !selectLabelsPopoverStatus,
    },
  );

  return (
    <MDKadroModal
      show={props.visible}
      onHide={clearAndHide}
      title={getModalTitle(state.shift.id, state.attendance.id, intl)}
      modifiers={MODAL_MODIFIERS}
      onSubmit={submit}
      footerOptions={footerOptions}
      loading={loading}
      disableConfirm={disableConfirmButton}
      confirmText={intl.formatMessage(messages.save)}
    >
      <div className="payrollEditShiftAndAttendanceModal">
        <PayrollEditShiftAndAttendanceDetails
          fullName={state.fullName}
          date={state.date}
          isShift={Boolean(state.shift.id)}
          isAttendance={Boolean(state.attendance.id)}
        />
        {state.shift.id && (
          <div className="payrollEditShiftAndAttendanceModal__content">
            <ShiftEditDataView
              shift={state.shift}
              options={getEmployeeJobTitlesAsSelectOptions(
                contracts[state.employee.id],
                userJobTitles,
                state.shift.date,
              )}
              onInputChange={handleShiftChange}
              error={{ defaultMessage: shiftError }}
              isShiftEditingBlocked={state.isLoaned}
            />
          </div>
        )}
        {state.attendance.id && (
          <div className="payrollEditShiftAndAttendanceModal__content">
            <AttendanceEditDataView
              attendance={state.attendance}
              onInputChange={handleAttendanceChange}
              error={{ defaultMessage: state.errors.attendanceHours }}
              ref={breakRef}
              isAttendanceEditingBlocked={isAttendanceEditingBlocked}
              labels={props.labels}
              labelsForAttendance={labelsAssignedToAttendance}
              selectItemsId={setSelectedlabels}
              hideAddAndEditLabels={disabledAddAndEditLabels}
              multiSelectOptionManagerClassnames={multiSelectOptionManagerClassnames}
              selectPopoverStatus={setSelectLabelsPopoverStatus}
              showLabels={showLabels}
            />
          </div>
        )}
      </div>
    </MDKadroModal>
  );
};

PayrollEditShiftAndAttendanceModal.contextTypes = {
  intl: PropTypes.shape({}),
};

PayrollEditShiftAndAttendanceModal.propTypes = {
  visible: PropTypes.bool,
  hideModal: PropTypes.func,
  modalObject: PropTypes.shape({
    shiftId: PropTypes.string,
    attendanceId: PropTypes.string,
    selectedEmployeeId: PropTypes.string,
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  handleMultipleBreaksActions: PropTypes.func,
  editShiftAndAttendanceNewPayroll: PropTypes.func,
  showDeleteAttendanceConfirmModal: PropTypes.func,
  showDeleteShiftForPayrollConfirmModal: PropTypes.func,
  showModalToAddShiftForExistingAttendance: PropTypes.func,
  locationSettings: PropTypes.shape({}),
  getShiftOrAttendanceForPayroll: PropTypes.func,
  userPermissions: PropTypes.shape({
    restrictions: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }),
  currentUser: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  companyRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  assignLabelsToAttendance: PropTypes.func,
  unassignLabelsFromAttendance: PropTypes.func,
  payrollLoanedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      deleted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      reference_id: PropTypes.string,
      isLoaned: PropTypes.bool,
      attendances: PropTypes.arrayOf(),
    }),
  ),
};

export default PayrollEditShiftAndAttendanceModal;
