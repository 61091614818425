import moment from 'moment';

const CalendarMonthHeader = () => {
  const weekdays = moment.weekdaysShort(true);
  return (
    <thead>
      <tr className="yearCalendar__weekDaysNames">
        {weekdays.map(day => (
          <th key={day}>{day}</th>
        ))}
      </tr>
    </thead>
  );
};

export default CalendarMonthHeader;
