import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDCheckbox, MDSelect, MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDSingleSlider from '@/components/common/MDSliders/MDSingleSlider';
import { optionType } from '@/constants/autoSchedule';
import { OPEN_SHIFTS_TEMPLATE_ID } from '@/constants/scheduleDisplayModes.js';
import { getGroupedOptions } from '@/utils/autoschedulerHelpers.jsx';

import { showOption } from '../autoscheduler.helpers.js';

import './AutoGenerateCreateScheduleOptions.scss';

const messages = defineMessages({
  generateScheduleRules: {
    id: 'autoschedule.flex.generateScheduleRules',
    defaultMessage: 'Reguły generowania grafiku',
  },
  otherOptions: {
    id: 'autoschedule.flex.otherOptions',
    defaultMessage: 'Inne opcje',
  },
  chooseTemplate: {
    id: 'autoschedule.flex.chooseTemplate',
    defaultMessage: 'Wybierz szablon',
  },
  template: {
    id: 'autoschedule.flex.template',
    defaultMessage: 'Szablon',
  },
});

export const AutoGenerateCreateScheduleOptions = (props, { intl }) => {
  const {
    error,
    options,
    selectedTemplate,
    showTemplateSelect,
    templates,
    handleInputChange,
    handleTemplateChange,
    handleToggle,
  } = props;

  const { generationOptions, otherOptions } = getGroupedOptions(options);

  const { templatesArray } = templates;

  const selectedOpenShiftTemplate = selectedTemplate === OPEN_SHIFTS_TEMPLATE_ID;

  const displayTemplateSelect = showTemplateSelect && !selectedOpenShiftTemplate;

  const selectLabel = selectedOpenShiftTemplate
    ? intl.formatMessage(messages.template)
    : intl.formatMessage(messages.chooseTemplate);

  return (
    <div className="autoScheduleFlex">
      <div className="autoScheduleFlex__errors">{error}</div>
      <div className="autoScheduleFlex__section autoScheduleFlex__section--scheduleRules">
        {displayTemplateSelect && (
          <MDSelect
            name={selectLabel}
            defaultValue={selectedTemplate}
            options={showOption(templatesArray)}
            onChange={key => {
              handleTemplateChange(key);
            }}
            closeOnClick
            withSearch
          />
        )}

        <div className="autoScheduleFlex__header">
          <FormattedMessage {...messages.generateScheduleRules} />
        </div>
        {generationOptions.map(option => (
          <fieldset className="autoScheduleFlex__option" key={option.name}>
            <MDCheckbox
              id={option.name}
              onChange={() => handleToggle(option)}
              value={option.enabled}
              disabled={option.disableCheckbox}
              text={option.label}
              reverse
              tooltip={option.description}
            />

            {option.enabled && option.type === optionType.INTEGER && (
              <MDTextInput
                type="number"
                className="autoScheduleFlexOption__input"
                value={option.value}
                onChange={e => handleInputChange(option, e.target.value)}
                maxValue={option.maxValue}
              />
            )}

            {option.enabled && option.type === optionType.SINGLE_SLIDER && (
              <MDSingleSlider
                maxValue={12}
                minValue={0}
                defaultValue={option.defaultValue}
                className="autoScheduleFlexOption__slider-input"
                onChange={value => handleInputChange(option, value)}
                formatLabel={value => `${value}h`}
              />
            )}
          </fieldset>
        ))}
      </div>

      <div className="autoScheduleFlex__section autoScheduleFlex__section--otherOptions">
        <div className="autoScheduleFlex__header">
          <FormattedMessage {...messages.otherOptions} />{' '}
        </div>
        <div>
          {otherOptions.map(option => (
            <fieldset className="autoScheduleFlex__option" key={option.name}>
              <MDCheckbox
                id={option.name}
                onChange={() => handleToggle(option)}
                value={option.enabled}
                disabled={false}
                text={option.label}
                reverse
                tooltip={option.description}
              />
            </fieldset>
          ))}
        </div>
      </div>
    </div>
  );
};

AutoGenerateCreateScheduleOptions.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.node,
      type: PropTypes.string,
      enabled: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedTemplate: PropTypes.string,
  showTemplateSelect: PropTypes.bool,
  templates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    templatesArray: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  handleToggle: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
};

AutoGenerateCreateScheduleOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AutoGenerateCreateScheduleOptions;
