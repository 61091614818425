import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  workTime: {
    id: 'payrollSummary.workTime',
    defaultMessage: 'Suma godzin',
  },
  absenceTime: {
    id: 'payrollSummary.absenceTime',
    defaultMessage: 'Suma nieobecności',
  },
  overtime: {
    id: 'payrollSummary.overtime',
    defaultMessage: 'Nadgodziny dobowe',
  },
  payout: {
    id: 'payrollSummary.payout',
    defaultMessage: 'Suma wynagrodzeń',
  },
  nightWorkTime: {
    id: 'payrollSummary.nightWorkTime',
    defaultMessage: 'Godziny nocne',
  },
  bonuses: {
    id: 'payrollSummary.bonuses',
    defaultMessage: 'Suma bonusów',
  },
});
