import { SUPPLEMENTARY_LOCATIONS_VIEW } from '@/constants/Permissions';
import {
  ACCOUNT,
  SCHEDULE_PLANNING,
  SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES_ADD,
  SUPPLEMENTARY_EMPLOYEES_MODIFY,
} from '@/constants/Roles';
import { UserPermissionsStoreState } from '@/redux-store/userPermissions';

export const filterGrouppedPermissions = (
  userPermissions: UserPermissionsStoreState['permissions'],
  grouppedPermissions: Record<string, { id: string }[]>,
) =>
  Object.entries(grouppedPermissions).reduce((acc, [key, value]) => {
    if ([ACCOUNT, SCHEDULE_PLANNING].includes(key)) {
      const filteredAccountPermissions = value.filter(({ id }) => {
        const hasSupplementaryLocationsView = userPermissions.includes(SUPPLEMENTARY_LOCATIONS_VIEW);
        return !(
          [SCHEDULE_VIEW_SUPPLEMENTARY_EMPLOYEES_ADD, SUPPLEMENTARY_EMPLOYEES_MODIFY].includes(id) &&
          !hasSupplementaryLocationsView
        );
      });
      return { ...acc, [key]: filteredAccountPermissions };
    }
    return { ...acc, [key]: value };
  }, {});
