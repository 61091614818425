import moment from 'moment';

import { difference } from '@/utils/array/array.helpers';
import { getRelevantContractForDate } from '@/utils/contracts';
import { getShiftDataForLoanedEmployees } from '@/utils/scheduleLoanedEmployees';

const generateTimestampsFromDateAndHours = (date, hours) => {
  const [from, to] = hours.split('-');
  const start = moment(`${date} ${from}`);
  let end = moment(`${date} ${to}`);
  if (to < from) end = end.add(1, 'day');
  return { startTimestamp: start.format('YYYY-MM-DD HH:mm'), endTimestamp: end.format('YYYY-MM-DD HH:mm') };
};

const checkIfShiftIsInHourlyAbsence = (shift, absence) => {
  const { date, working_hours: shiftHours } = shift;
  const { absence_hours: absenceHours } = absence;
  const { startTimestamp: shiftStart, endTimestamp: shiftEnd } = generateTimestampsFromDateAndHours(date, shiftHours);
  const { startTimestamp: absenceStart, endTimestamp: absenceEnd } = generateTimestampsFromDateAndHours(
    date,
    absenceHours,
  );
  return shiftStart < absenceEnd && shiftEnd > absenceStart;
};

const checkIfShiftIsInAbsence = (shift, absences) => {
  const absence = absences.find(
    absence =>
      (absence.count_only_days_with_shifts || absence.absence_hours) &&
      moment(absence.from).startOf('day').isSameOrBefore(shift.date) &&
      moment(absence.to).endOf('day').isSameOrAfter(shift.date) &&
      (!absence.absence_hours || checkIfShiftIsInHourlyAbsence(shift, absence)),
  );
  return absence;
};

export const updateShiftsHoursWithAbsences = (shifts, absences) => {
  const shiftsWithAbsences = shifts.reduce((updatedShifts, shift) => {
    const employeeAbsences = absences[shift.employee.id];
    const absence = employeeAbsences && checkIfShiftIsInAbsence(shift, employeeAbsences);
    if (absence) {
      const [startHour, endHour] = absence.absence_hours?.split('-') || [];
      const absenceIsHourly = !!startHour && !!endHour;

      if (!absenceIsHourly) return updatedShifts;

      const { startTimestamp: shiftStart, endTimestamp: shiftEnd } = generateTimestampsFromDateAndHours(
        shift.date,
        shift.working_hours,
      );
      const { startTimestamp: absenceStart, endTimestamp: absenceEnd } = generateTimestampsFromDateAndHours(
        shift.date,
        absence.absence_hours,
      );
      const absenceDuration = moment
        .min(moment(shiftEnd), moment(absenceEnd))
        .diff(moment.max(moment(shiftStart), moment(absenceStart)), 'minutes');

      updatedShifts.push({
        ...shift,
        duration: shift.duration - absenceDuration,
      });
      return updatedShifts;
    }

    updatedShifts.push(shift);
    return updatedShifts;
  }, []);
  return shiftsWithAbsences;
};

export const formatFlatShiftsToSummary = (
  flatShifts,
  locationIds,
  dateArray,
  absencesPerEmployee,
  scheduleLoanedEmployees,
  shifts,
  showLoanedEmployees,
) => {
  const formattedLoanedEmployeesShifts = getShiftDataForLoanedEmployees(
    scheduleLoanedEmployees.scheduleLoanedEmployees,
    shifts,
  );
  return dateArray.reduce((acc, date) => {
    const relevantShifts = flatShifts.filter(shift => shift.date === date && locationIds.includes(shift.location.id));
    const shiftsWithAbsencesApplied = updateShiftsHoursWithAbsences(relevantShifts, absencesPerEmployee);
    const { duration, cost, shiftsEmployees } = shiftsWithAbsencesApplied.reduce(
      (result, shift) => ({
        duration: result.duration + shift.duration,
        cost: result.cost + (shift.duration / 60) * (shift.wage || shift.job_title.hourly_wage || 0),
        shiftsEmployees: [...result.shiftsEmployees, shift.employee.id],
      }),
      { duration: 0, cost: 0, shiftsEmployees: [] },
    );
    const currentEmployees = acc[date]?.employees || [];

    const relevantShiftsLoanedEmployees = formattedLoanedEmployeesShifts.filter(
      shift => shift.date === date && locationIds.includes(shift.location.id),
    );

    let loanedEmployeesShiftsDuration = 0;
    let loanedEmployeesShifts = [];
    if (relevantShiftsLoanedEmployees.length && showLoanedEmployees) {
      ({ loanedEmployeesShiftsDuration, loanedEmployeesShifts } = relevantShiftsLoanedEmployees.reduce(
        (result, shift) => ({
          loanedEmployeesShiftsDuration: result.loanedEmployeesShiftsDuration + shift.duration,
          loanedEmployeesShifts: [...result.loanedEmployeesShifts, shift.employee.id],
        }),
        { loanedEmployeesShiftsDuration: 0, loanedEmployeesShifts: [] },
      ));
    }

    const newEmployees = Array.from(new Set([...currentEmployees, ...shiftsEmployees, ...loanedEmployeesShifts]));
    return {
      ...acc,
      [date]: {
        totalMinutes: (acc[date]?.totalMinutes || 0) + duration + loanedEmployeesShiftsDuration,
        totalCosts: (acc[date]?.totalCosts || 0) + cost,
        employees: newEmployees,
      },
    };
  }, {});
};

export const getSingleShiftWithWage = (shift, employeeContracts, userJobTitles) => {
  const relevantContract = getRelevantContractForDate(employeeContracts, shift.date);
  const contractJobTitle = relevantContract?.job_titles.find(jt => jt.job_title_id === shift.job_title.id);
  if (!contractJobTitle) return { ...shift, wage: shift.job_title.hourly_wage };

  if (contractJobTitle.wage || contractJobTitle.wage === 0) {
    return { ...shift, wage: contractJobTitle.wage };
  }
  const jobTitle = userJobTitles.find(jt => jt.id === shift.job_title.id);

  return {
    ...shift,
    wage: jobTitle?.hourly_wage || shift.job_title.hourly_wage,
  };
};

export const getShiftsWithWage = (shifts, contracts, userJobTitles) =>
  shifts.map(shift => {
    const employeeContracts = contracts[shift.employee.id] || [];
    return getSingleShiftWithWage(shift, employeeContracts, userJobTitles);
  });

export const getNewSummary = (
  scheduleState,
  employees,
  selectedLocationIds,
  dateArray,
  absencesPerEmployee,
  contracts,
  userJobTitles,
  scheduleLoanedEmployees,
  shifts,
  showLoanedEmployees,
) => {
  const visibleEmployeeIds = Array.from(
    new Set(
      selectedLocationIds.reduce(
        (result, locationId) => [
          ...result,
          ...difference(scheduleState[locationId]?.visible, scheduleState[locationId]?.loaned),
        ],
        [],
      ),
    ),
  );
  const relevantEmployees = employees.filter(employee => visibleEmployeeIds?.includes(employee.id));
  const flatShifts = relevantEmployees.flatMap(employee =>
    employee.shifts.map(shift => getSingleShiftWithWage(shift, contracts[employee.id] || [], userJobTitles)),
  );

  return formatFlatShiftsToSummary(
    flatShifts,
    selectedLocationIds,
    dateArray,
    absencesPerEmployee,
    scheduleLoanedEmployees,
    shifts,
    showLoanedEmployees,
  );
};
