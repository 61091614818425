import {
  CHANGE_SHIFTS_SUMMARY,
  GET_BUDGET_TARGET_USAGE_SUCCESS,
  GET_BUDGET_TARGETS_SUCCESS,
  TOGGLE_BUDGET_OPTION,
} from '@/constants/ActionTypes.js';

import { conn } from '../index';

export const changeShiftsSummary = summary => ({
  type: CHANGE_SHIFTS_SUMMARY,
  payload: summary,
});

export const toggleBudgetOption = option => ({
  type: TOGGLE_BUDGET_OPTION,
  payload: option,
});

const getBudgetTargetsSuccess = data => ({
  type: GET_BUDGET_TARGETS_SUCCESS,
  payload: data,
});

export const getBudgetTargets = (from, to, locationId) => async dispatch => {
  const result = await conn.getBudgetTargets(from, to, locationId);

  dispatch(getBudgetTargetsSuccess(result.data));
};

const getBudgetTargetUsageSuccess = data => ({
  type: GET_BUDGET_TARGET_USAGE_SUCCESS,
  payload: data,
});

export const getBudgetTargetUsage = () => async (dispatch, getState) => {
  const {
    mainDateStore: {
      customDate: { start, end },
    },
    scheduleLocationFilter,
  } = getState().reducer;

  const result = await conn.getBudgetTargetUsage(start, end, scheduleLocationFilter);

  dispatch(getBudgetTargetUsageSuccess(result.data));
  return result.data;
};
