import classnames from 'classnames';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  sortByColumnMessage: {
    id: 'payrollLocationTableHead.sortBy',
    defaultMessage: 'Sortuj wg tej kolumny',
  },
});

const MDTableHead = ({ headerGroups, withBigPadding, shadowRightColumn, shadowLeftColumn }, { intl }) => {
  const getSortIcon = column => {
    if (column.isSorted || column.showSortIcon) {
      return column.isSortedDesc ? 'arrow_drop_down' : 'arrow_drop_up';
    }
    return '';
  };

  return (
    <div className="mdTable__thead">
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <div className="mdTable__row" {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
          {headerGroup.headers.map((column, columnIndex) => {
            const shouldHasShadowRight = column.id === shadowRightColumn;
            const shouldHasShadowLeft = column.id === shadowLeftColumn;
            const headerClassNames = classnames('mdTable__headerCell', {
              'mdTable__headerCell--bigPadding': withBigPadding,
              'mdTable__headerCell--shadowRight': shouldHasShadowRight,
              'mdTable__headerCell--shadowLeft': shouldHasShadowLeft,
              'mdTable__headerCell--flex': column.showSortIcon,
            });
            const sortIconClassNames = classnames('mdTable__sortIcon material-icons', {
              'mdTable__sortIcon--block': column.showSortIcon,
            });

            const cellTitleClassNames = classnames('mdTable__cellTitle', {
              'mdTable__cellTitle--block': column.showSortIcon,
            });
            const cellContentClassNames = classnames('mdTable__cell-content', {
              'mdTable__cell-content--flex': column.showSortIcon,
            });
            return (
              <div
                className={headerClassNames}
                key={columnIndex}
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    title: intl.formatMessage(messages.sortByColumnMessage),
                  }),
                )}
              >
                <div className={cellContentClassNames}>
                  <span className={cellTitleClassNames}>{column.render('Header')}</span>
                  <span className={sortIconClassNames}>{getSortIcon(column)}</span>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

MDTableHead.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
  withBigPadding: PropTypes.bool,
  shadowRightColumn: PropTypes.string,
  shadowLeftColumn: PropTypes.string,
};

MDTableHead.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default MDTableHead;
