import { CHECKBOX_COLUMN_WIDTH } from '@/constants/tables';

import CheckboxColumnCell from './CheckboxColumnCell';
import CheckboxColumnHeader from './CheckboxColumnHeader';

export const getCheckboxColumn = (columns, customOnSelectHandler, disableSelectAllCheckbox) => ({
  id: 'selection',
  Header: props => <CheckboxColumnHeader {...props} disableSelectAllCheckbox={disableSelectAllCheckbox} />,
  Cell: props => <CheckboxColumnCell {...props} customOnChange={customOnSelectHandler} />,
  width: CHECKBOX_COLUMN_WIDTH,
  Filter: () => <></>,
  sticky: 'left',
});
