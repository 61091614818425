import PropTypes from 'prop-types';

import KadroSkeleton from '@/components/common/Skeleton/Skeleton.jsx';
import { getFilteredSummaryData } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollButtonBar/PayrollSummary/PayrollSummary.helpers';
import { SKELETON_HEIGHT, SKELETON_WIDTH } from '@/constants/ui.ts';
import { PayrollSummary as PayrollSummaryInterface, PayrollSummaryItem, TimeFormatType } from '@/types';

import './PayrollLocationSummary.scss';

interface Props {
  summary: PayrollSummaryInterface;
  timeFormatType: TimeFormatType;
  summaryValues: PayrollSummaryItem[];
  restrictions: string[];
  showSkeletons: boolean;
}

const PayrollLocationSummary = (
  { summary, timeFormatType, summaryValues, restrictions, showSkeletons }: Props,
  { intl },
) => {
  if (Object.keys(summary).length === 0 && !showSkeletons) return null;
  const summaryData = getFilteredSummaryData(summary, timeFormatType, summaryValues, intl, restrictions, showSkeletons);
  return (
    <div className="k-payrollLocationSummary">
      {summaryData.map((el, index) => (
        <div key={index} className="k-payrollLocationSummary__item">
          {el.text}:
          <span className="k-payrollLocationSummary__value">
            {el.value || <KadroSkeleton width={SKELETON_WIDTH} height={SKELETON_HEIGHT} />}
          </span>
          {el.unit}
        </div>
      ))}
    </div>
  );
};

PayrollLocationSummary.contextTypes = {
  intl: PropTypes.shape({}),
};

export default PayrollLocationSummary;
