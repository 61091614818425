import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  publish: {
    id: 'schedule.sendPanel.publish',
    defaultMessage: 'Opublikuj:',
  },
  publishShifts: {
    id: 'schedule.sendPanel.publishShifts',
    defaultMessage: 'zmian w aktualnym grafiku',
  },
  publishOpenShifts: {
    id: 'schedule.sendPanel.publishOpenShifts',
    defaultMessage: 'otwartych zmian w aktualnym grafiku',
  },
});
