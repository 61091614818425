import { isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { validateOptionChange } from '@/components/autoscheduler/autoscheduler.helpers';
import Button from '@/components/common/Basic/Button.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput';
import MDEmployeeSelectBlockWithJobTitles from '@/components/common/MDEmployeesSelectBlock/MDEmployeeSelectBlockWithJobTitles.jsx';
import MDKadroModalHeader from '@/components/common/MDKadroModal/MDKadroModalHeader/ModalHeader.jsx';
import MDModal from '@/components/common/MDModal/Modal.jsx';
import SwitchToFullApp from '@/components/onboarding/SwitchToFullApp.jsx';
import { getDataForRecommendedScheduleFromShifts } from '@/components/scheduler/modals/LoadRecommendedScheduleModal/RecommendedScheduleFromExistingTemplate/RecommendedScheduleFromExistingTemplate.helpers.js';
import {
  basicTemplateGenerationOptionsForProxy,
  FAIR_WORKING_HOURS_DISTRIBUTION,
  scheduleGenerationOptions,
} from '@/constants/autoSchedule';
import { AUTO_GENERATE_STEPS_MODAL } from '@/constants/modalTypes';
import { OPEN_SHIFTS_TEMPLATE_ID, TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { validateOptions } from '@/utils/autoschedulerHelpers.jsx';
import { bindPrototypeFunctions } from '@/utils/constructionConventions';
import {
  findEmployeesWithDifferentJobTitlesInContractsForDateRange,
  getJobTitleIdsFromContracts,
  getRelevantContractsForMultipleDates,
} from '@/utils/contracts.ts';
import { getCalendarRangeText, getRangeBetweenDates } from '@/utils/dateHelper.js';

import AutoGenerateCreateScheduleOptions from '../AutoGenerateCreateScheduleOptions/AutoGenerateCreateScheduleOptions.jsx';
import AutoGenerateMonthPicker from '../AutoGenerateMonthPicker/AutoGenerateMonthPicker.jsx';
import { filterTemplateShifts, formatEmploymentConditions } from './AutoGenerateScheduleModal.helpers.js';
import { messages } from './AutoGenerateScheduleModal.messages.ts';
import {
  DISPLAYED_STEPS_COUNT,
  DISPLAYED_STEPS_COUNT_WHEN_OPEN_SHIFTS_TEMPLATE,
  STEPS_OFFSET,
  STEPS_OFFSET_WHEN_OPEN_SHIFTS_TEMPLATE,
} from './autoGenerateScheduleModalConstants.js';

import '../AutoGenerateStepsModal/AutoGenerateStepsModal.scss';

class AutoGenerateScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState();

    bindPrototypeFunctions(this);
  }

  componentDidUpdate(_, prevState) {
    if (
      !isEqual(prevState.selectedJobTitles, this.state.selectedJobTitles) ||
      !isEqual(prevState.selectedEmploymentConditions, this.state.selectedEmploymentConditions)
    ) {
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    }
    if (
      !isEqual(prevState.selectedEmployees, this.state.selectedEmployees) ||
      !isEqual(prevState.selectedJobTitles, this.state.selectedJobTitles)
    ) {
      const { shifts, id } = this.props.userTemplates.currentTemplate;
      this.setState({
        timeout: this.calculateShiftsSolvingTime(shifts, id, this.state.templateRanges, this.state.selectedEmployees),
      });
    }
    if (!isEqual(prevState.selectedEmployees, this.state.selectedEmployees) && this.state.activeStep === 1) {
      const { selectedEmployees } = this.state;
      const { mainDateStore } = this.props;
      const { customDate } = mainDateStore;
      const employeeIds = selectedEmployees.map(({ value }) => value);

      const inValidEmployeeIds = findEmployeesWithDifferentJobTitlesInContractsForDateRange(
        employeeIds,
        customDate,
        this.props.contracts,
      );

      const numberOfInvalidEmployeeIds = inValidEmployeeIds.length;
      if (numberOfInvalidEmployeeIds > 0) {
        this.setState({
          errors: {
            ...this.state.errors,
            contracts: this.context.intl.formatMessage(messages.autoGenerateStepsContractsError),
          },
          employeeIdsWithContractError: inValidEmployeeIds,
        });
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            contracts: '',
          },
          employeeIdsWithContractError: [],
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.showModal && this.state.activeStep !== 0) {
      this.setState({
        activeStep: 0,
      });
    }
    if (nextProps.userTemplates.currentTemplate.id !== this.props.userTemplates.currentTemplate.id) {
      const templateRanges = [
        {
          id: 0,
          templateId: nextProps.userTemplates.currentTemplate.id,
          dateText: getCalendarRangeText(
            nextProps.mainDateStore.customDate.start,
            nextProps.mainDateStore.customDate.end,
          ),
          date: {
            start: nextProps.mainDateStore.customDate.start,
            end: nextProps.mainDateStore.customDate.end,
          },
        },
      ];
      this.setState({
        selectedTemplateId: nextProps.userTemplates.currentTemplate.id,
        templateRanges,
        timeout: this.calculateShiftsSolvingTime(
          nextProps.userTemplates.currentTemplate.id === OPEN_SHIFTS_TEMPLATE_ID
            ? nextProps.userTemplates.currentTemplate.shifts
            : this.getShiftsForMultipleTemplateRanges(templateRanges, nextProps.userTemplates.templatesArray),
          nextProps.userTemplates.currentTemplate.id,
          templateRanges,
        ),
        selectedEmploymentConditions: formatEmploymentConditions(this.props.employmentConditions),
      });
    }
    const isClosingAfterOpenShiftTemplate =
      !nextProps.showModal && nextProps.userTemplates.currentTemplate.id === OPEN_SHIFTS_TEMPLATE_ID;
    if (isClosingAfterOpenShiftTemplate) {
      this.props.deleteOpenShiftTemplate();
    }
    if (!isEqual(nextProps.mainDateStore.customDate, this.props.mainDateStore.customDate)) {
      this.setCalendarDateRange(nextProps);
    }
  }

  onHide() {
    this.props.hideModal();
    this.setState(this.getInitState());
  }

  onOptionsChange(options, error) {
    this.setState({ options, errors: { ...this.state.errors, step0: error } });
  }

  getSelectedJobTitles(templateShifts) {
    return templateShifts.reduce((prev, shift) => {
      const shiftJobTitleId = shift.job_title_id;
      const jobTitle = this.props.userJobTitles.find(jt => jt.id === shiftJobTitleId);

      if (jobTitle && !prev.some(jt => jt.id === shiftJobTitleId)) {
        prev.push(jobTitle);
      }
      return prev;
    }, []);
  }

  getInitState() {
    const { shifts, id } = this.props.userTemplates.currentTemplate;

    const templateRanges = [
      {
        id: 0,
        templateId: this.props.userTemplates.currentTemplate.id,
        dateText: getCalendarRangeText(
          this.props.mainDateStore.customDate.start,
          this.props.mainDateStore.customDate.end,
        ),
        date: {
          start: this.props.mainDateStore.customDate.start,
          end: this.props.mainDateStore.customDate.end,
        },
      },
    ];

    const timeout = this.calculateShiftsSolvingTime(shifts || [], id, templateRanges);

    return {
      activeStep: 0,
      hideCancel: false,
      selectedTemplateId: this.props.userTemplates.currentTemplate.id,
      templateRanges,
      selectedEmployees: [],
      errors: {
        step0: <div />,
        step1: <div />,
        contracts: '',
      },
      options: Object.values(basicTemplateGenerationOptionsForProxy),
      shortTimeout: true,
      timeout,
      selectedEmploymentConditions: formatEmploymentConditions(this.props.employmentConditions),
      employeeIdsWithContractError: [],
    };
  }

  getSelectedEmployees(selectedJobTitles, selectedEmploymentConditions) {
    if (!selectedJobTitles) return [];
    const {
      selectedLocations: selectedLocationIds,
      contracts,
      mainDateStore: {
        customDate: { start: from, end: to },
      },
    } = this.props;
    const supplementaryEmployeesOption = this.state.options.find(
      option => option.name === 'include_supplementary_employees',
    );
    const filterOutSupplementaryEmployees = supplementaryEmployeesOption && !supplementaryEmployeesOption.enabled;

    function detectType(data) {
      if (typeof data === 'string') {
        return data;
      }
      if (typeof data === 'object' && data !== null && data instanceof Date) {
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, '0');
        const day = String(data.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return 'unknown';
    }

    const templateStart = detectType(this.state.templateRanges[0].date.start);
    let templateEnd;
    if (this.state.templateRanges.length > 1) {
      templateEnd = detectType(this.state.templateRanges[this.state.templateRanges.length - 1].date.end);
    } else {
      templateEnd = detectType(this.state.templateRanges[0].date.end);
    }
    const filteredEmployees = this.props.userEmployees.filter(employee => {
      const employeeContracts = contracts[employee.id] || [];
      const relevantContracts = getRelevantContractsForMultipleDates(employeeContracts, from, to);
      const employeeJobTitleIds = getJobTitleIdsFromContracts(relevantContracts);
      const hasJobTitle = employeeJobTitleIds.some(jobTitleId =>
        selectedJobTitles.map(jobTitle => jobTitle.id).includes(jobTitleId),
      );

      return (
        !employee.inactive &&
        employee.locations.map(l => l.id).some(id => selectedLocationIds.includes(id)) &&
        (!filterOutSupplementaryEmployees ||
          !employee.supplementary_locations_ids
            .map(id => id.toString())
            .some(id => selectedLocationIds.includes(id))) &&
        hasJobTitle &&
        selectedEmploymentConditions.some(
          ({ active, value }) => active && value === employee.employment_conditions.template_id,
        ) &&
        (employee.employment_conditions.release_date >= templateStart ||
          !employee.employment_conditions.release_date) &&
        (employee.employment_conditions.hire_date <= templateEnd || !employee.employment_conditions.hire_date)
      );
    });

    return filteredEmployees.map(employee => ({
      value: employee.id,
      label: `${employee.first_name} ${employee.last_name}`,
    }));
  }

  getEmployeesOptions() {
    return this.employeesToChoose.map(({ label, value }) => {
      const {
        first_name: firstName,
        last_name: lastName,
        id,
      } = this.props.userEmployees.find(empl => empl.id === value);
      return {
        value,
        sortValue: firstName + lastName + id,
        label,
        active: this.isEmployeeSelected({ value }),
      };
    });
  }

  setSelectedTemplateId(templateId) {
    const { shifts } = this.props.userTemplates.templatesArray.find(({ id }) => templateId === id);

    if (shifts) {
      this.setState(prev => ({
        selectedTemplateId: templateId,
        timeout: this.calculateShiftsSolvingTime(shifts, templateId),
        errors: {
          ...prev.errors,
          step0: <div />,
        },
      }));
    }
  }

  setConfiguration(option) {
    const newOptions = this.state.options.map(opt => {
      if (opt.name === option.name) {
        return { ...option, enabled: !option.enabled };
      }
      return opt;
    });
    const error = validateOptions(newOptions);
    this.setState(prev => ({
      options: newOptions,
      errors: { ...prev.errors, ...error },
    }));
  }

  setConfigurationInputValue(option, value) {
    const newOptions = this.state.options.map(opt => {
      if (
        opt.name === option.name &&
        validateOptionChange(this.state.options, option, value, this.props.mainDateStore)
      ) {
        return { ...option, value };
      }
      return opt;
    });
    const error = validateOptions(newOptions);

    this.setState(prev => ({
      options: newOptions,
      errors: { ...prev.errors, ...error },
    }));
  }

  setShortTimeout() {
    this.setState(prev => ({
      shortTimeout: !prev.shortTimeout,
    }));
  }

  setDailyOvertime() {
    this.setState(prev => ({
      dailyOvertimeEnabled: !prev.dailyOvertimeEnabled,
    }));
  }

  setTemplateRanges(ranges, validate = false) {
    this.setState(
      {
        templateRanges: ranges,
        timeout: this.calculateShiftsSolvingTime(
          this.getShiftsForMultipleTemplateRanges(ranges),
          this.state.selectedTemplateId,
          ranges,
        ),
      },
      () => {
        if (validate) {
          const isValidMonth = this.validatePeriod();
          const { isValidRange } = this.validateRanges();
          if (isValidMonth && isValidRange) {
            this.setState(prev => ({
              errors: {
                ...prev.errors,
                step0: <div />,
              },
            }));
          }
        }
      },
    );
  }

  setSelectedEmployees(selectedEmployee) {
    if (this.isEmployeeSelected(selectedEmployee)) {
      const newEmployees = this.state.selectedEmployees.filter(employee => employee.value !== selectedEmployee.value);
      this.setState({ selectedEmployees: newEmployees });
    } else {
      this.setState(prev => ({
        selectedEmployees: [...prev.selectedEmployees, selectedEmployee],
      }));
    }
  }

  setCalendarDateRange(nextProps) {
    this.setState({
      templateRanges: [
        {
          id: 0,
          templateId: this.props.userTemplates.currentTemplate.id,
          dateText: getCalendarRangeText(
            nextProps.mainDateStore.customDate.start,
            nextProps.mainDateStore.customDate.end,
          ),
          date: {
            start: nextProps.mainDateStore.customDate.start,
            end: nextProps.mainDateStore.customDate.end,
          },
        },
      ],
    });
  }

  isEmployeeSelected(employee) {
    const id = employee.value || employee.id;
    return this.state.selectedEmployees.some(selectedEmployee => selectedEmployee.value === id);
  }

  selectSome() {
    this.setState({ selectedEmployees: this.employeesToChoose });
  }

  deselectAll() {
    this.setState({ selectedEmployees: [] });
  }

  calculateShiftsSolvingTime(
    shifts,
    templateId = this.state.selectedTemplateId,
    ranges = this.state.templateRanges,
    selectedEmployees,
  ) {
    const totalNumberOfSelectedDays = ranges.reduce((days, range) => {
      const dateArray = getRangeBetweenDates(range.date.start, range.date.end).filter(day => !days.includes(day));

      return days.concat(dateArray);
    }, []).length;

    const numberOfShifts = shifts.reduce((shiftsAmount, { amount }) => shiftsAmount + amount, 0);
    const numberOfDays =
      templateId === OPEN_SHIFTS_TEMPLATE_ID
        ? shifts.reduce((distinctDays, { openShiftId }) => {
            const matchedOpenShifts = this.props.openShifts.find(openShift => openShift.id === openShiftId);

            if (!matchedOpenShifts) return distinctDays;

            return distinctDays.includes(matchedOpenShifts.date)
              ? distinctDays
              : [...distinctDays, matchedOpenShifts.date];
          }, []).length
        : totalNumberOfSelectedDays;

    const shiftSizeFactor = JSON.parse(import.meta.env.VITE_SHIFT_SIZE_FACTOR || '0.8');
    const daysAndEmployeesFactor = JSON.parse(import.meta.env.VITE_DAYS_AND_EMPLOYEES_FACTOR || '0.5');

    let scheduleDuration = Math.ceil(
      (numberOfShifts * shiftSizeFactor + numberOfDays * selectedEmployees?.length * daysAndEmployeesFactor) / 60,
    );

    scheduleDuration = scheduleDuration > 60 ? 60 : scheduleDuration || 1;

    return scheduleDuration;
  }

  validatePeriod() {
    const { mainDateStore } = this.props;
    const { dateArray, dateMode } = mainDateStore;
    const [currentPeriodStart] = dateArray;
    const currentPeriodEnd = dateArray[dateArray.length - 1];

    const isValid = !this.state.templateRanges.some(
      range =>
        !(
          moment(range.date.start).isBetween(currentPeriodStart, currentPeriodEnd, 'day', '[]') &&
          moment(range.date.end).isBetween(currentPeriodStart, currentPeriodEnd, 'day', '[]')
        ),
    );
    if (!isValid) {
      let message = (
        <FormattedMessage
          id="schedule.autoGenerateMonth.invalidPeriod"
          defaultMessage="Musisz wybrać daty z aktualnie wybranego okresu ({dateRange})"
          values={{
            dateRange: `${moment(currentPeriodStart).format('DD.MM')}-${moment(currentPeriodEnd).format(
              'DD.MM.YYYYr.',
            )}`,
          }}
        />
      );
      if (dateMode === 'month') {
        message = (
          <FormattedMessage
            id="schedule.autoGenerateMonth.invalidMonth"
            defaultMessage="Musisz wybrać daty z aktualnie wybranego miesiąca ({month})"
            values={{
              month: moment(this.props.mainDateStore.dateArray[0]).format('MMMM'),
            }}
          />
        );
      }
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: message,
        },
      }));
    }
    return isValid;
  }

  validateRanges() {
    const sortedRanges = [...this.state.templateRanges]
      .filter(range => range.date.start && range.date.end)
      .sort((a, b) => (moment(a.date.start).isAfter(b.date.start) ? 1 : -1));

    const isValidRange = sortedRanges.reduce((prev, currentRange, index, ranges) => {
      if (index === 0) {
        return true;
      }
      const previousRange = ranges[index - 1];
      return moment(previousRange.date.end).isSameOrAfter(currentRange.date.start) ? false : prev;
    }, true);
    if (!isValidRange) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.invalidRange"
              defaultMessage="Wybrane daty nie mogą na siebie nachodzić"
            />
          ),
        },
      }));
    }
    return { isValidRange, sortedRanges };
  }

  validateOptions() {
    const { options } = this.state;
    const error = validateOptions(options);
    if (error) {
      this.setState({
        errors: {
          ...this.state.errors,
          step0: error,
        },
      });
      return { hasValidOptions: false };
    }
    this.setState({
      errors: {
        ...this.state.errors,
        step0: <div />,
      },
    });
    return { hasValidOptions: true };
  }

  validateSelectedJobTitles(selectedJobTitles) {
    const hasSelectedJobTitles = selectedJobTitles.length;
    if (!hasSelectedJobTitles) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step0: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.invalidTemplate"
              defaultMessage="Wybrany szablon jest pusty"
            />
          ),
        },
      }));
    }
    return hasSelectedJobTitles;
  }

  validateSelectedEmployees() {
    const hasSelectedEmployees = this.state.selectedEmployees.length;
    if (!hasSelectedEmployees) {
      this.setState(prev => ({
        errors: {
          ...prev.errors,
          step1: (
            <FormattedMessage
              id="schedule.autoGenerateMonth.noEmployeesSelected"
              defaultMessage="Proszę wybrać conajmniej jednego pracownika"
            />
          ),
        },
      }));
    }
    return hasSelectedEmployees;
  }

  handleSolvingTimeInput(e) {
    const { value } = e.target;

    if (value === '' || (/^\d+$/.test(value) && value >= 1 && value <= 60)) {
      this.setState({ timeout: value });
    }
  }

  getShiftsForMultipleTemplateRanges(ranges, templatesArray) {
    const sortedRanges = ranges || this.validateRanges().sortedRanges;

    return sortedRanges.reduce((prev, range) => {
      const dateArray = getRangeBetweenDates(range.date.start, range.date.end);
      const template = (templatesArray || this.props.userTemplates.templatesArray).find(
        userTemplate => userTemplate.id === range.templateId,
      ) || {
        shifts: [],
      };
      return prev.concat(
        ...dateArray.map(date => {
          const dayOfWeek = moment(date).day();
          return [
            ...template.shifts
              .filter(shift => shift.date === dayOfWeek)
              .map(shift => ({
                date,
                working_hours: shift.working_hours,
                job_title_id: shift?.job_title_id || shift?.job_title?.id,
                amount: shift.amount,
                gid: shift.gid,
                openShiftId: shift.openShiftId,
              })),
          ];
        }),
      );
    }, []);
  }

  isJobTitleSelected(jobTitle) {
    const id = jobTitle.value || jobTitle.id;
    return this.state.selectedJobTitles.some(selectedJobTitle => selectedJobTitle.id === id);
  }

  deselectAllJobTitles() {
    this.setState({ selectedJobTitles: [] });
    this.employeesToChoose = [];
    this.deselectAll();
  }

  selectAllJobTitles() {
    this.setState({ selectedJobTitles: this.jobTitlesToChoose });
    this.employeesToChoose = this.getSelectedEmployees(this.jobTitlesToChoose, this.state.selectedEmploymentConditions);
    this.selectSome();
  }

  getJobTitlesOptions() {
    return this.jobTitlesToChoose.map(jobTitle => ({
      value: jobTitle.id,
      sortValue: jobTitle.title + jobTitle.id,
      label: jobTitle.title,
      color: jobTitle.color,
      active: this.isJobTitleSelected(jobTitle),
    }));
  }

  setSelectedJobTitles(selectedJobTitle) {
    if (this.isJobTitleSelected(selectedJobTitle)) {
      const newJobTitles = this.state.selectedJobTitles.filter(({ id }) => id !== selectedJobTitle.value);
      this.setState({ selectedJobTitles: newJobTitles });
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    } else {
      const newJobTitle = this.jobTitlesToChoose.find(({ id }) => id === selectedJobTitle.value);
      this.setState(prev => ({
        selectedJobTitles: [...prev.selectedJobTitles, newJobTitle],
      }));
      this.employeesToChoose = this.getSelectedEmployees(
        this.state.selectedJobTitles,
        this.state.selectedEmploymentConditions,
      );
      this.selectSome();
    }
  }

  selectAllEmploymentConditions() {
    this.setState({
      selectedEmploymentConditions: this.state.selectedEmploymentConditions.map(employmentCondition => ({
        ...employmentCondition,
        active: true,
      })),
    });
  }

  deselectAllEmploymentConditions() {
    this.setState({
      selectedEmploymentConditions: this.state.selectedEmploymentConditions.map(employmentCondition => ({
        ...employmentCondition,
        active: false,
      })),
    });
  }

  setSelectedEmploymentConditions(e) {
    const { value } = e;
    this.setState(prevState => {
      const selectedEmploymentConditions = prevState.selectedEmploymentConditions.map(employmentCondition =>
        employmentCondition.value === value
          ? { ...employmentCondition, active: !employmentCondition.active }
          : employmentCondition,
      );
      return { selectedEmploymentConditions };
    });
  }

  render() {
    let modalBody;
    const userTemplates = {
      ...this.props.userTemplates,
      templatesArray: this.props.userTemplates.templatesArray.filter(t => t.type === TEMPLATE_TYPES.BASIC),
    };
    const { openShifts } = this.props;
    const { activeStep, errors } = this.state;
    const selectedOpenShiftsTemplate = this.state.selectedTemplateId === OPEN_SHIFTS_TEMPLATE_ID;

    const options = (
      <AutoGenerateCreateScheduleOptions
        error={this.state.errors.step0}
        handleTemplateChange={this.setSelectedTemplateId}
        handleToggle={this.setConfiguration}
        handleInputChange={this.setConfigurationInputValue}
        options={this.state.options}
        selectedTemplate={this.state.selectedTemplateId}
        templates={userTemplates}
        showTemplateSelect={this.props.mainDateStore.dateMode === 'week'}
      />
    );

    switch (this.state.activeStep) {
      case 0:
        this.previousHandler = () => this.props.showModalFunc(AUTO_GENERATE_STEPS_MODAL, { step: 0 });

        if (this.props.mainDateStore.dateMode === 'week') {
          this.nextHandler = () => {
            const { hasValidOptions } = this.validateOptions();
            if (!hasValidOptions) return;
            const template = this.props.userTemplates.templatesArray.find(
              item => item.id === this.state.selectedTemplateId,
            );

            const templateShifts = template.shifts
              .filter(shift => !shift.draft)
              .map(shift => {
                const date = shift.date === 0 ? 7 : shift.date;
                return {
                  date: this.props.mainDateStore.dateArray[date - 1],
                  working_hours: shift.working_hours,
                  job_title_id: shift?.job_title_id || shift?.job_title?.id,
                  amount: shift.amount,
                  gid: shift.gid,
                  openShiftId: shift.openShiftId,
                };
              });
            this.jobTitlesToChoose = this.getSelectedJobTitles(templateShifts);
            const hasSelectedJobTitles = this.validateSelectedJobTitles(this.jobTitlesToChoose);
            this.setState({
              selectedJobTitles: this.getSelectedJobTitles(templateShifts),
            });
            this.employeesToChoose = this.getSelectedEmployees(
              this.jobTitlesToChoose,
              this.state.selectedEmploymentConditions,
            );

            if (!hasSelectedJobTitles || !this.employeesToChoose || this.employeesToChoose.length === 0) return;

            this.setState({
              activeStep: 1,
              templateShifts,
              selectedEmployees: this.employeesToChoose,
            });
          };
          modalBody = options;
        } else {
          this.nextHandler = () => {
            const isValidMonth = this.validatePeriod();
            const { isValidRange, sortedRanges } = this.validateRanges();
            const { hasValidOptions } = this.validateOptions();
            if (!isValidMonth || !isValidRange || !hasValidOptions) return;

            const templateShifts = sortedRanges.reduce((prev, range) => {
              const dateArray = getRangeBetweenDates(range.date.start, range.date.end);
              const template = this.props.userTemplates.templatesArray.find(
                userTemplate => userTemplate.id === range.templateId,
              );
              if (template.id === OPEN_SHIFTS_TEMPLATE_ID) {
                const mappedTemplateShifts = template.shifts.map(shift => {
                  const matchedOpenShift = openShifts.find(openShift => openShift.id === shift.openShiftId);
                  return {
                    date: matchedOpenShift.date,
                    working_hours: shift.working_hours,
                    job_title_id: shift?.job_title_id || shift?.job_title?.id,
                    amount: shift.amount,
                    openShiftId: shift.openShiftId,
                  };
                });
                return [...prev, ...mappedTemplateShifts];
              }
              return prev.concat(
                ...dateArray.map(date => {
                  const dayOfWeek = moment(date).day();
                  return [
                    ...template.shifts
                      .filter(shift => shift.date === dayOfWeek)
                      .map(shift => ({
                        date,
                        working_hours: shift.working_hours,
                        job_title_id: shift?.job_title_id || shift?.job_title?.id,
                        amount: shift.amount,
                        gid: shift.gid,
                        openShiftId: shift.openShiftId,
                      })),
                  ];
                }),
              );
            }, []);
            this.jobTitlesToChoose = this.getSelectedJobTitles(templateShifts);
            this.setState({
              selectedJobTitles: this.jobTitlesToChoose,
            });

            const hasSelectedJobTitles = this.validateSelectedJobTitles(this.jobTitlesToChoose);
            if (!hasSelectedJobTitles) return;
            this.employeesToChoose = this.getSelectedEmployees(
              this.state.selectedJobTitles,
              this.state.selectedEmploymentConditions,
            );
            this.setState({
              activeStep: 1,
              templateShifts,
              selectedEmployees: this.employeesToChoose,
            });
          };
          modalBody = (
            <AutoGenerateMonthPicker
              userTemplates={userTemplates}
              onRangeChange={this.setTemplateRanges}
              mainDateStore={this.props.mainDateStore}
              ranges={this.state.templateRanges}
              options={options}
              selectedTemplate={this.state.selectedTemplateId}
            />
          );
        }
        break;
      case 1: {
        this.previousHandler = () =>
          this.setState({
            activeStep: 0,
          });
        const isFairWorkingHoursDistributionOption = this.state.options.find(
          option => option.name === FAIR_WORKING_HOURS_DISTRIBUTION,
        ).enabled;
        this.nextHandler = () => {
          const { options, timeout } = this.state;
          const showForecastScheduleForBasicTemplate = this.state.options.find(
            opt => opt.name === scheduleGenerationOptions.show_forecast_schedule_for_basic_template.name,
          ).enabled;
          const hasSelectedEmployees = this.validateSelectedEmployees();
          if (!hasSelectedEmployees) {
            this.props.noEmployeesSelected();
            return;
          }
          const configOptions = options.filter(opt => opt.isConfigurationOption);
          const availableUndefinedDays = options.find(
            opt => opt.name === basicTemplateGenerationOptionsForProxy.availability_without_entered.name,
          ).enabled;

          this.setState({
            activeStep: 2,
          });
          const filteredTemplateShifts = filterTemplateShifts(this.state.templateShifts, this.state.selectedJobTitles);

          if (!isFairWorkingHoursDistributionOption) {
            this.props.sendAutoSchedulerInformation(
              filteredTemplateShifts,
              this.props.mainDateStore.dateArray,
              this.state.selectedEmployees,
              configOptions,
              true,
              availableUndefinedDays,
              TEMPLATE_TYPES.BASIC,
            );
          } else {
            this.props.sendAutoSchedulerInformationViaProxy(
              filteredTemplateShifts,
              this.props.mainDateStore.dateArray,
              this.state.selectedEmployees,
              configOptions,
              availableUndefinedDays,
              TEMPLATE_TYPES.BASIC,
              timeout || null,
            );
          }
          this.props.showModalFunc(AUTO_GENERATE_STEPS_MODAL, {
            step: 3,
            selectedOpenShiftsTemplate,
          });
          if (showForecastScheduleForBasicTemplate) {
            const { templateShifts } = this.state;
            const { mainDateStore, selectedLocations, addRecommendedSchedule } = this.props;
            const data = getDataForRecommendedScheduleFromShifts(
              templateShifts,
              mainDateStore.dateArray,
              selectedLocations[0],
            );
            addRecommendedSchedule(data);
          }
        };
        const selectedEmployees = this.state.selectedEmployees.map(employee => {
          if (this.state.employeeIdsWithContractError.includes(employee.value))
            return {
              ...employee,
              tooltipError: this.context.intl.formatMessage(messages.autoGenerateStepsContractsTooltipError),
            };
          return employee;
        });
        modalBody = (
          <>
            <MDEmployeeSelectBlockWithJobTitles
              selectedJobTitles={this.state.selectedJobTitles}
              setSelectedJobTitles={this.setSelectedJobTitles}
              selectAllJobTitles={this.selectAllJobTitles}
              jobTitlesToChoose={this.jobTitlesToChoose}
              deselectAllJobTitles={this.deselectAllJobTitles}
              neededCount={this.employeesToChoose.length}
              options={this.getEmployeesOptions()}
              jobTitleOptions={this.getJobTitlesOptions()}
              selectedItems={selectedEmployees}
              shouldBeSorted={false}
              handleChange={this.setSelectedEmployees}
              handleSelectAll={this.selectSome}
              handleDeselectAll={this.deselectAll}
              selectAllEmploymentConditions={this.selectAllEmploymentConditions}
              deselectAllEmploymentConditions={this.deselectAllEmploymentConditions}
              selectedEmploymentConditions={this.state.selectedEmploymentConditions}
              setSelectedEmploymentConditions={this.setSelectedEmploymentConditions}
            />
            {isFairWorkingHoursDistributionOption && (
              <MDTextInput
                className="k-autoGenerateSteps__solvingTimeInput"
                type="text"
                label={
                  <FormattedMessage defaultMessage="Czas trwania (minuty):" id="schedule.autoGenerate.solvingTime" />
                }
                value={this.state.timeout}
                onChange={this.handleSolvingTimeInput}
              />
            )}
          </>
        );
        break;
      }
      default:
        this.nextHandler = () => null;
        this.previousHandler = () => null;
        modalBody = <div />;
    }
    if (this.props.demo.demoAccount && this.props.showModal) {
      return <SwitchToFullApp cancel={this.onHide} onHide={this.onHide} />;
    }

    const progressBarWitdh = (100 / DISPLAYED_STEPS_COUNT) * (this.state.activeStep + STEPS_OFFSET);

    const stepsCount = selectedOpenShiftsTemplate
      ? `${this.state.activeStep + STEPS_OFFSET_WHEN_OPEN_SHIFTS_TEMPLATE}/
      ${DISPLAYED_STEPS_COUNT_WHEN_OPEN_SHIFTS_TEMPLATE}`
      : `${this.state.activeStep + STEPS_OFFSET} / ${DISPLAYED_STEPS_COUNT}`;
    const hideBackButton = selectedOpenShiftsTemplate && this.state.activeStep === 0;
    return (
      <MDModal
        className="k-autoGenerateSteps"
        show={this.props.showModal}
        onHide={this.onHide}
        modifiers={['narrow']}
        onSubmit={this.nextHandler}
        errorMessage={this.state.errorMessage}
        isAnimated={false}
      >
        <>
          <MDModal.Content withoutOverlayScroll>
            <MDKadroModalHeader onHide={this.onHide} />

            {modalBody}
            {errors.contracts && activeStep === 1 && (
              <span className="k-autoGenerateSteps__error">
                {this.context.intl.formatMessage(messages.autoGenerateStepsContractsError)}
              </span>
            )}
          </MDModal.Content>

          <MDModal.Footer>
            <div className="k-autoGenerateSteps__buttons">
              <Button
                onClick={this.nextHandler}
                modifiers="orange teeny uppercase"
                className="k-autoGenerateSteps__button--next"
                disabled={this.state.error || Boolean(this.state.errors.contracts)}
              >
                {this.state.activeStep === 1 ? (
                  <FormattedMessage id="common.send" defaultMessage="Wyślij" />
                ) : (
                  <FormattedMessage id="common.next" defaultMessage="Dalej" />
                )}
              </Button>
              {!hideBackButton && (
                <Button onClick={this.previousHandler} modifiers="reverse-orange teeny uppercase">
                  <FormattedMessage id="common.back" defaultMessage="Wstecz" />
                </Button>
              )}
            </div>
            <div className="k-autoGenerateSteps__progress">
              <div className="k-autoGenerateSteps__progressText">{stepsCount}</div>
              <div className="k-autoGenerateSteps__progressBar" style={{ width: `${progressBarWitdh}%` }} />
            </div>
          </MDModal.Footer>
        </>
      </MDModal>
    );
  }
}

AutoGenerateScheduleModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AutoGenerateScheduleModal.propTypes = {
  showModal: PropTypes.bool,
  userTemplates: PropTypes.shape({
    templatesArray: PropTypes.arrayOf(PropTypes.shape),
    currentTemplate: PropTypes.shape({
      id: PropTypes.string,
      shifts: PropTypes.arrayOf(PropTypes.shape),
    }),
  }),
  userEmployees: PropTypes.arrayOf(PropTypes.shape),
  userJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  selectedLocations: PropTypes.arrayOf(PropTypes.string),

  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateMode: PropTypes.string,
    customDate: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  showModalFunc: PropTypes.func,
  hideModal: PropTypes.func,
  sendAutoSchedulerInformationViaProxy: PropTypes.func,
  demo: PropTypes.shape({
    demoAccount: PropTypes.bool,
  }),
  sendAutoSchedulerInformation: PropTypes.func,
  openShifts: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, date: PropTypes.string })),
  deleteOpenShiftTemplate: PropTypes.func,
  noEmployeesSelected: PropTypes.func,
  employmentConditions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })),
  addRecommendedSchedule: PropTypes.func,
};

export default AutoGenerateScheduleModal;
