import { connect } from 'react-redux';

import SchedulePositionTableRowTitle from '@/components/scheduler/Position/SchedulePositionTableRowTitle.jsx';

const mapStateToProps = state => ({
  dateStore: state.reducer.mainDateStore,
  scheduleUIState: state.reducer.scheduleUIState,
  canPaste: state.reducer.copyPaste.canPaste,
  userJobTitles: state.reducer.userJobTitles,
  contracts: state.reducer.contracts,
});

const SchedulePositionTableRowTitleContainer = connect(mapStateToProps)(SchedulePositionTableRowTitle);

export default SchedulePositionTableRowTitleContainer;
