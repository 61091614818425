import PropTypes from 'prop-types';

import TopBarCalendar from '@/components/TopBars/filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmploymentConditionsFilter from '@/components/TopBars/filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarMultipleLocationFilter from '@/components/TopBars/filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from '@/components/TopBars/TopBar';
import { payrollLocationModesIds } from '@/constants/viewDateModes';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';
import TopBarNameFilterContainer from '@/containers/TopBars/filters/TopBarNameFilterContainer.js';

import PayrollLocationLoadDataButton from './PayrollLocationLoadDataButton/PayrollLocationLoadDataButton.redux';

const PayrollLocationTopBar = ({ enableLoadButton }) => (
  <TopBar
    className="k-topBar--payrollLocation"
    leftElements={[
      <TopBarCalendar key="calswitch" modes={payrollLocationModesIds} onChangeCallback={enableLoadButton} />,
      <TopBarMultipleLocationFilter key="multipleLocationFilter" />,
      <TopBarJobTitleFilterContainer key="jobTitleFilter" />,
      <TopBarNameFilterContainer key="nameFilter" />,
      <TopBarEmploymentConditionsFilter key="employmentConditionsFilter" />,
    ]}
    rightElements={[<PayrollLocationLoadDataButton key="loadDataButton" />]}
  />
);

PayrollLocationTopBar.propTypes = {
  enableLoadButton: PropTypes.func,
};

export default PayrollLocationTopBar;
