import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { sendGTMEvent } from '@/utils/analytics.js';

import ErrorPage from './ErrorPage.jsx';

class Page404 extends Component {
  componentDidMount() {
    sendGTMEvent('404');
  }
  render() {
    return (
      <ErrorPage
        number={404}
        text={<FormattedMessage id="view.page404.text" defaultMessage="Nie znaleziono strony" />}
      />
    );
  }
}

export default Page404;
