import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import PublishButton from '@/components/common/PublishButton.jsx';
import { SCHEDULE_EDIT_DISABLE } from '@/constants/Restrictions';
import {
  AVAILABILITIES_DISPLAY_MODE_TYPE,
  POSITIONS_DISPLAY_MODE_TYPE,
  SCHEDULE_DISPLAY_MODE_TYPE,
  TEMPLATES_DISPLAY_MODE_TYPE,
} from '@/constants/scheduleDisplayModes.js';
import { scheduleModesIdsForEmployee, scheduleModesIdsForManager } from '@/constants/viewDateModes';
import TopBarDisplayModeContainer from '@/containers/TopBars/filters/TopBarDisplayModeContainer.js';
import TopBarJobTitleFilterContainer from '@/containers/TopBars/filters/TopBarJobTitleFilterContainer.js';
import { getRelevantDateRange } from '@/utils/dateHelper.js';
import { countTemplatesShiftsToSave } from '@/utils/scheduleTemplatesHelpers.js';
import { getDraftShiftsCount } from '@/utils/shiftHelpers.js';

import TopBarCalendar from '../filters/TopBarCalendar/TopBarCalendar.redux.js';
import { TopBarEmployeeGroupingFilter } from '../filters/TopBarEmployeeGroupingFilter/TopBarEmployeeGroupingFilter';
import TopBarEmploymentConditionsFilter from '../filters/TopBarEmploymentConditionsFilter/TopBarEmploymentConditionsFilter.redux.js';
import TopBarMultipleLocationFilter from '../filters/TopBarScheduleLocationFilter/TopBarScheduleLocationFilter.redux';
import TopBarSortingFilter from '../filters/TopBarSortingFilter/TopBarSortingFilter.redux.js';
import TopBar from '../TopBar.jsx';

const messages = defineMessages({
  publish: {
    id: 'publish',
    defaultMessage: 'Publikuj',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Zatwierdź',
  },
});

const TopBarSchedule = (props, context) => {
  let rightCornerButton = <div key="rightCorner" />;
  switch (props.selectedScheduleDisplayModeType) {
    case SCHEDULE_DISPLAY_MODE_TYPE: {
      const draftShiftsCount = getDraftShiftsCount(
        props.scheduleLocationFilter,
        props.locationSettings,
        props.userEmployees,
        props.mainDateStore,
        props.absences,
        props.openShifts,
        props.selectedJobTitles,
      );

      rightCornerButton = (
        <PublishButton
          text={context.intl.formatMessage(messages.publish)}
          onClickFunc={() => {
            props.changeSendPanelVisibility(true);
          }}
          number={draftShiftsCount}
          key="PublishStuff"
        />
      );

      break;
    }
    case AVAILABILITIES_DISPLAY_MODE_TYPE: {
      const disabledUntilDate = props.currentCompany.settings.disable_availabilities_edit_until;
      const relevantDateRange = getRelevantDateRange(props.mainDateStore.dateArray, disabledUntilDate);
      const numberOfDraftAvailabilities = props.userEmployees
        .filter(
          e =>
            !e.inactive &&
            e.locations.map(l => l.id).some(locationId => props.scheduleLocationFilter.includes(locationId)),
        )
        .map(e => e.availability_blocks.filter(s => relevantDateRange.includes(s.date) && s.draft === true).length)
        .reduce((a, b) => a + b, 0);

      rightCornerButton = (
        <PublishButton
          text={context.intl.formatMessage(messages.confirm)}
          onClickFunc={() => {
            props.changeSendPanelVisibility(true);
          }}
          number={numberOfDraftAvailabilities}
          key="ConfirmStuff"
        />
      );
      break;
    }
    case POSITIONS_DISPLAY_MODE_TYPE:
      rightCornerButton = null;
      break;
    case TEMPLATES_DISPLAY_MODE_TYPE: {
      const selectedJobTitleIds = props.selectedJobTitles.map(({ id }) => id);
      const numberOfDraftShifts = countTemplatesShiftsToSave(
        props.userTemplates.currentTemplate.shifts,
        selectedJobTitleIds,
      );
      rightCornerButton = (
        <PublishButton
          text={context.intl.formatMessage(messages.confirm)}
          onClickFunc={() => {
            props.changeSendPanelVisibility(true);
          }}
          number={numberOfDraftShifts}
          key="PublishStuff"
        />
      );
      break;
    }

    default:
      break;
  }

  const { isEmployee } = props.userPermissions;
  const isEditingScheduleDisabled = props.userPermissions.restrictions.includes(SCHEDULE_EDIT_DISABLE);
  const isTemplateScheduleViewSelected = props.selectedScheduleDisplayModeType === TEMPLATES_DISPLAY_MODE_TYPE;
  const isPositionsScheduleViewSelected = props.selectedScheduleDisplayModeType === POSITIONS_DISPLAY_MODE_TYPE;
  const isPublishDisabled = isEmployee || (isEditingScheduleDisabled && !isTemplateScheduleViewSelected);

  return (
    <TopBar
      className="k-topBar--schedule"
      leftElements={
        isEmployee
          ? [
              <TopBarCalendar
                mode="schedule"
                key="calswitch"
                hideFilter={isTemplateScheduleViewSelected}
                modes={scheduleModesIdsForEmployee}
              />,
              <TopBarMultipleLocationFilter key="locationFilter" hideFilter={isTemplateScheduleViewSelected} />,
              <TopBarSortingFilter
                key="sortingFilter"
                isEmployee={isEmployee}
                hideFilter={isPositionsScheduleViewSelected || isTemplateScheduleViewSelected}
              />,
            ]
          : [
              <TopBarCalendar
                mode="schedule"
                key="calswitch"
                hideFilter={isTemplateScheduleViewSelected}
                modes={scheduleModesIdsForManager}
              />,
              <TopBarDisplayModeContainer key="displayMode" />,
              !isTemplateScheduleViewSelected ? <TopBarMultipleLocationFilter key="locationFilter" /> : null,
              <TopBarJobTitleFilterContainer
                key="jobTitleFilter"
                showFilteredJobTitles={!isTemplateScheduleViewSelected}
              />,
              <TopBarEmploymentConditionsFilter
                key="employmentConditionsFilter"
                hideFilter={isPositionsScheduleViewSelected || isTemplateScheduleViewSelected}
              />,
              <TopBarSortingFilter
                key="sortingFilter"
                isEmployee={isEmployee}
                hideFilter={isPositionsScheduleViewSelected || isTemplateScheduleViewSelected}
              />,
              <TopBarEmployeeGroupingFilter
                key="employeeGroupingFilter"
                hideFilter={isPositionsScheduleViewSelected || isTemplateScheduleViewSelected || isEmployee}
              />,
            ]
      }
      rightElements={isPublishDisabled ? [] : [rightCornerButton]}
    />
  );
};

TopBarSchedule.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

TopBarSchedule.propTypes = {
  selectedScheduleDisplayModeType: PropTypes.string,
  userEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  openShifts: PropTypes.arrayOf(PropTypes.shape({})),
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    dateText: PropTypes.string,
  }),
  locationSettings: PropTypes.shape({}),
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  userTemplates: PropTypes.shape({
    currentTemplate: PropTypes.shape({
      shifts: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }),
  userPermissions: PropTypes.shape({
    isEmployee: PropTypes.bool,
    restrictions: PropTypes.arrayOf(PropTypes.string),
  }),
  currentCompany: PropTypes.shape({
    settings: PropTypes.shape({
      disable_availabilities_edit_until: PropTypes.string,
    }),
  }),
  changeSendPanelVisibility: PropTypes.func,
  absences: PropTypes.shape({}),
  selectedJobTitles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default TopBarSchedule;
