import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  edit: {
    id: 'employeeTimesheet.options.edit',
    defaultMessage: 'Edytuj',
  },
  delete: {
    id: 'employeeTimesheet.options.delete',
    defaultMessage: 'Usuń',
  },
  proposal: {
    id: 'employeeTimesheet.options.proposal',
    defaultMessage: 'Wniosek',
  },
});
