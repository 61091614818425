import { connect } from 'react-redux';

import { addAbsence } from '@/actions/absences.js';
import { addOvertimeCollection } from '@/actions/overtimeCollections/overtimeCollections.js';
import { hideModal } from '@/actions/uiState.js';
import { ABSENCE_ADD_MODAL } from '@/constants/modalTypes.js';

import AbsenceAddModal from './AbsenceAddModal.jsx';

const mapStateToProps = state => ({
  absenceTypes: state.reducer.absences.absencesTypes,
  absences: state.reducer.absences.absences,
  userEmployees: state.reducer.userEmployees,
  mainDateStore: state.reducer.mainDateStore,
  showModal: state.reducer.uiState.showModal === ABSENCE_ADD_MODAL,
  modalObject: state.reducer.uiState.modalObject[ABSENCE_ADD_MODAL],
  countOnlyDaysWithShiftsSelectedByDefault:
    state.reducer.currentCompany.settings.count_only_days_with_shifts_selected_by_default,
  userId: state.reducer.currentUser.user.id,
  permissions: state.reducer.userPermissions.permissions,
  locationSettings: state.reducer.settings.locationSettings,
  restrictions: state.reducer.userPermissions.restrictions,
  currentUserRole: state.reducer.currentUser.user.role,
});

const mapDispatchToProps = {
  addAbsence,
  hideModal,
  addOvertimeCollection,
};

const AbsenceAddModalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceAddModal);

export default AbsenceAddModalContainer;
