import { connect } from 'react-redux';

import OwnerTrialDashboard from './OwnerTrialDashboard';

const mapStateToProps = ({ reducer }) => ({
  isTrialInfoBarVisible: reducer.uiState.isTrialInfoBarVisible,
  roles: reducer.userPermissions.roles,
  userFirstName: reducer.currentUser.user.first_name,
});

export default connect(mapStateToProps)(OwnerTrialDashboard);
