import { PODST_EMPLOYMENT_CONDITIONS_ID } from '@/constants/employmentConditions';
import { EMPLOYEE } from '@/constants/Roles';

import { messages } from './addMassEmployeesModal.messages';

export const initStatelocationOptions = userLocations =>
  userLocations.map(({ name, id }) => ({
    label: name,
    value: id,
    active: false,
  }));

export const initStateJobTitleOptions = jobTitles =>
  Object.values(jobTitles).map(({ title, id }) => ({
    label: title,
    value: id,
    active: false,
  }));

export const parseEmployees = employees => ({
  employees: employees.reduce((acc, { name, lastName, jobTitles, locations, employmentCondition, role }) => {
    if (name && lastName) {
      const locationsValue = locations.map(({ value }) => ({ id: value }));
      const terms = jobTitles.map(({ value }) => ({
        has_individual_hourly_wage: false,
        job_title: { id: value },
      }));

      acc.push({
        first_name: name,
        last_name: lastName,
        locations: locationsValue,
        employment_conditions: { template_id: employmentCondition },
        role_id: role,
        supplementary_locations: '',
        terms,
      });
    }
    return acc;
  }, []),
});

export const createEmployee = (jobTitles, userLocations) => ({
  name: '',
  lastName: '',
  jobTitles: [],
  locations: [],
  employmentCondition: PODST_EMPLOYMENT_CONDITIONS_ID,
  role: EMPLOYEE,
  employeeJobTitleOptions: initStateJobTitleOptions(jobTitles),
  employeeLocationOptions: initStatelocationOptions(userLocations),
  terms: [],
  errors: { name: '', lastName: '' },
});

export const getInitState = (numberOfEmployees, jobTitles, userLocations) => {
  const employees = [];
  for (let i = 0; i < numberOfEmployees; i++) {
    employees.push(createEmployee(jobTitles, userLocations));
  }
  return employees;
};

export const getTheads = intl => [
  intl.formatMessage(messages.addMassEmployeesNameInput),
  intl.formatMessage(messages.addMassEmployeesLastNameInput),
  intl.formatMessage(messages.addMassEmployeesJobTitlesInput),
  intl.formatMessage(messages.addMassEmployeesLocationsInput),
  intl.formatMessage(messages.addMassEmployeesEmploymentConditionInput),
  intl.formatMessage(messages.addMassEmployeesRoleInput),
];

export const getMultiSelectLabel = (selectedItem, label) => {
  const numberOfSelectedItem = selectedItem.length;
  if (numberOfSelectedItem === 0) return label;
  else if (numberOfSelectedItem === 1) return `(${numberOfSelectedItem}) ${selectedItem[0].label}`;
  return `(${numberOfSelectedItem}) ${label}`;
};
