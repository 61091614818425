import { connect } from 'react-redux';

import { reportsDateChangeModeAndDate,reportsDateMoveLeft, reportsDateMoveRight } from '@/actions/reports.js';
import TopBarCalendar from '@/components/TopBars/filters/TopBarCalendar/TopBarCalendar.jsx';

const mapStateToProps = state => ({
  mainDateStore: state.reducer.reports.dateStore,
});

const mapDispatchToProps = {
  mainDateMoveLeft: reportsDateMoveLeft,
  mainDateMoveRight: reportsDateMoveRight,
  mainDateChangeModeAndDate: reportsDateChangeModeAndDate,
};

const TopBarCalendarContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarCalendar);

export default TopBarCalendarContainer;
