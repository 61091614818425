import { connect } from 'react-redux';

import { toggleModal } from '@/actions/companymanage';

import EmptyStateLocationView from './EmptyStateLocationView.jsx';

const mapDispatchToProps = {
  toggleModal,
};

const EmptyStateLocationViewContainer = connect(null, mapDispatchToProps)(EmptyStateLocationView);

export default EmptyStateLocationViewContainer;
