import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MDFileDrop from '@/components/common/inputs/MDFileDrop/MDFileDrop';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal';
import { toBase64 } from '@/utils/fileHelpers.js';
import { getLocaleConfig } from '@/utils/locale/locale.utils.ts';

import { IMPORT_BUDGET_METRICS, IMPORT_BUDGET_METRICS_TEMPLATE } from './ImportBudgetMetricsModal.helpers';

const ImportBudgetMetricsModal = ({
  show,
  from,
  to,
  hideModal,
  showOverlay,
  runExport,
  toggleImportModalOverlay,
  activeLocationIds,
  budgetMetrics,
  language,
  pendingExports,
  clearBudgetImports,
}) => {
  const [file, setFile] = useState(null);

  const currentTemplate = [...pendingExports]
    .reverse()
    .find((e) => e.type === 'f-budget-metrics-import' && e.link && e.name === IMPORT_BUDGET_METRICS_TEMPLATE);
  const importObject = [...pendingExports]
    .reverse()
    .find((e) => e.type === 'f-budget-metrics-import' && e.status && e.name === IMPORT_BUDGET_METRICS);

  const hideAndClear = () => {
    toggleImportModalOverlay(false);
    setFile(null);
    hideModal();
  };

  const handleSubmit = async () => {
    toggleImportModalOverlay(true);
    const base64File = await toBase64(file);
    await runExport('f-budget-metrics-import', IMPORT_BUDGET_METRICS, {
      type: 'import',
      file: base64File,
      language: getLocaleConfig(language).kadroExportLanguage,
    });
  };

  const handleExport = async () => {
    toggleImportModalOverlay(true);
    await runExport('f-budget-metrics-import', IMPORT_BUDGET_METRICS_TEMPLATE, {
      type: 'template',
      locationIds: activeLocationIds,
      from,
      to,
      budgetMetricIds: budgetMetrics.filter(({ visible }) => visible).map(({ id }) => id),
      language: getLocaleConfig(language).kadroExportLanguage,
    });
  };

  const handleUpload = (uploadedFile) => {
    const fileToSet = Array.isArray(uploadedFile) || uploadedFile instanceof FileList ? uploadedFile[0] : uploadedFile;
    setFile(fileToSet);
  };

  useEffect(() => {
    if (currentTemplate) {
      window.location.href = currentTemplate.link;
      toggleImportModalOverlay(false);
      clearBudgetImports();
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (!importObject) return;
    toggleImportModalOverlay(false);
    if (importObject.status !== 'error' && (!importObject.errors || importObject.errors.length === 0)) {
      hideAndClear();
    }
  }, [importObject]);

  return (
    <MDKadroModal
      show={show}
      onHide={hideAndClear}
      onSubmit={handleSubmit}
      modifiers={['wide']}
      confirmBtnModifiers={['orange', showOverlay ? 'disabled' : '']}
      cancelBtnModifiers={['gray', 'reverse-orange']}
      title={<FormattedMessage id="importBudgetModal.importData" defaultMessage="Importuj dane" />}
      confirmText={<FormattedMessage id="common.saveChanges" defaultMessage="Zapisz zmiany" />}
      loading={showOverlay}
      disableConfirm={file === null}
    >
      <div className="mdImportModal">
        <div className="mdImportModal__export">
          <div className="mdImportModalExport__header">
            <FormattedMessage id="importBudgetModal.loadData" defaultMessage="Wczytaj dane" />
          </div>
          <div className="mdImportModalExport__text">
            <FormattedMessage
              id="importBudgetModal.fileImport.exportFileText"
              defaultMessage="Wczytaj dane z pliku Excel. {downloadTemplate}  i wypełnij w nim dane."
              values={{
                downloadTemplate: (
                  <span className="mdImportModalExport__link" onClick={handleExport} role="presentation">
                    {' '}
                    <FormattedMessage id="downloadTemplate" defaultMessage="Pobierz szablon" />{' '}
                  </span>
                ),
              }}
            />
          </div>
        </div>
        <div className="mdImportModal__fileDrop">
          <MDFileDrop fileFormats={['xlsx']} hideButton onUpload={handleUpload} />
        </div>
      </div>
    </MDKadroModal>
  );
};

ImportBudgetMetricsModal.propTypes = {
  show: PropTypes.bool,
  hideModal: PropTypes.func,
  showOverlay: PropTypes.func,
  runExport: PropTypes.func,
  toggleImportModalOverlay: PropTypes.func,
  activeLocationIds: PropTypes.arrayOf(PropTypes.number),
  from: PropTypes.string,
  to: PropTypes.string,
  language: PropTypes.string,
  budgetMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool,
      id: PropTypes.string,
    }),
  ),
  pendingExports: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  clearBudgetImports: PropTypes.func,
};

export default ImportBudgetMetricsModal;
