/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { MDRadios,MDTextInput } from '@/components/common/inputs/MDComponents.jsx';
import MDKadroModal from '@/components/common/MDKadroModal/MDKadroModal.jsx';
import { scheduleMessages } from '@/constants/intl.js';
import { ADD_TEMPLATE_MODAL } from '@/constants/modalTypes.js';
import { TEMPLATE_TYPES } from '@/constants/scheduleDisplayModes.js';
import { bindPrototypeFunctions } from '@/utils/constructionConventions.js';
import { inputValidation } from '@/utils/inputValidation.js';

const templateOptions = [TEMPLATE_TYPES.BASIC, TEMPLATE_TYPES.FLEX];

class AddNewTemplateModal extends Component {
  static getInitialState() {
    return {
      values: {
        objectName: '',
        templateType: TEMPLATE_TYPES.BASIC,
      },
      errors: {},
    };
  }

  constructor(props) {
    super(props);
    this.state = AddNewTemplateModal.getInitialState();
    bindPrototypeFunctions(this);
  }

  onSubmit() {
    return this.validateAll().then(valid => {
      const {
        values: { objectName, templateType },
      } = this.state;

      if (!valid) {
        return;
      }

      this.props.addNewTemplate(objectName, [], templateType);
      this.hideAndClear();
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.validateInput(event);

    this.changeValue(name, value);
  }

  changeValue(name, value) {
    this.setState(prev => ({ values: { ...prev.values, [name]: value } }));
  }

  async validateAll() {
    const { values } = this.state;
    const inputs = Object.keys(values);

    for (const i of inputs) {
      // eslint-disable-next-line no-await-in-loop
      await this.validateInput({
        target: { name: i, value: values[i] },
      });
    }
    let canContinue = true;
    Object.values(this.state.errors).map(err => {
      if (err !== '') canContinue = false;
      return null;
    });
    return canContinue;
  }

  validateInput(event) {
    return new Promise(resolve => {
      const { templates } = this.props;
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      const error = inputValidation(name, value, { valueToCompare: templates, required: true });
      this.setState(
        {
          errors: {
            ...this.state.errors,
            [name]: error ? this.context.intl.formatMessage(error, {}) : error,
          },
        },
        () => {
          resolve();
        },
      );
      return null;
    });
  }

  hideAndClear() {
    this.setState(AddNewTemplateModal.getInitialState());
    this.props.hideModal();
  }

  onCancelFooter() {
    this.setState(AddNewTemplateModal.getInitialState());
    this.props.showModal(ADD_TEMPLATE_MODAL);
  }

  render() {
    const { isVisibleModal } = this.props;
    const { onSubmit, hideAndClear, onCancelFooter, handleInputChange, validateInput, changeValue } = this;
    const { errorMessage, errors, values } = this.state;
    const { intl } = this.context;
    const radioElements = templateOptions.map(opt => ({
      name: this.context.intl.formatMessage(scheduleMessages[opt]),
      type: opt,
      description: this.context.intl.formatMessage(scheduleMessages[`${opt}Description`]),
    }));

    return (
      <MDKadroModal
        show={isVisibleModal}
        onSubmit={onSubmit}
        onHide={hideAndClear}
        title={intl.formatMessage(scheduleMessages.addTemplateModalTitle)}
        modifiers={['narrow']}
        errorMessage={errorMessage && intl.formatMessage(errorMessage)}
        confirmText={intl.formatMessage(scheduleMessages.addTemplateModalConfirmText)}
        onCancelFooter={onCancelFooter}
        cancelText={<FormattedMessage id="templates.addModal.back" defaultMessage="Wstecz" />}
        disableConfirm={!!errors.objectName}
      >
        <>
          <MDTextInput
            value={values.objectName}
            type="text"
            onChange={handleInputChange}
            onBlur={validateInput}
            id="objectName"
            label={<FormattedMessage id="templates.addModal.name" defaultMessage="Nazwa szablonu" />}
            errorMessage={errors.objectName}
            modifiers={['modal']}
          />

          <div className="addTemplateModal__radiosContainer">
            <MDRadios
              label={intl.formatMessage(scheduleMessages.addTemplateModalChooseTemplateName)}
              elements={radioElements}
              selectedItemType={values.templateType}
              onChangeType={val => changeValue('templateType', val)}
            />
          </div>
        </>
      </MDKadroModal>
    );
  }
}

AddNewTemplateModal.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

AddNewTemplateModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  addNewTemplate: PropTypes.func,
  templates: PropTypes.arrayOf(),
};

export default AddNewTemplateModal;
