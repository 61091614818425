import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

import { MDCheckbox } from '@/components/common/inputs/MDComponents';
import Select from '@/components/common/inputs/MDSelect/Select.jsx';
import MDTextInput from '@/components/common/inputs/MDTextInput/MDTextInput.jsx';

import './EmployeeModalBasic.scss';

const messages = defineMessages({
  roleTooltip: {
    id: 'companymanage.roleTooltip',
    defaultMessage:
      'Poprzez rolę możesz określić poziom uprawnień danego pracownika. {clickHere}, aby dowiedzieć się więcej na temat tej opcji.',
  },
  clickHere: {
    id: 'common.clickHere',
    defaultMessage: 'KLIKNIJ TUTAJ',
  },
  sendInvitation: {
    id: 'companymanage.sendInvitation',
    defaultMessage: 'Wyślij zaproszenie do systemu',
  },
  emailInvitationTooltip: {
    id: 'companymanage.emailInvitationTooltip',
    defaultMessage: 'Aby móc wysłać zaproszenie do systemu, należy podać poprawny adres e-mail',
  },
});

const EmployeeModalBasic = (
  {
    name,
    lastName,
    email2,
    handleInputChange,
    validateInput,
    errors,
    role,
    selectOptions,
    showSendInvitation,
    sendInvitation,
  },
  { intl },
) => (
  <div className="employeeModal__basic">
    <MDTextInput
      value={name}
      onChange={handleInputChange}
      onBlur={validateInput}
      type="text"
      id="name"
      label={<FormattedMessage id="companymanage.employees.firstName" defaultMessage="Imię" />}
      errorMessage={errors.name}
      modifiers={['modal']}
      testId="name"
    />
    <MDTextInput
      value={lastName}
      type="text"
      onChange={handleInputChange}
      onBlur={validateInput}
      id="lastName"
      label={<FormattedMessage id="companymanage.employees.lastName" defaultMessage="Nazwisko" />}
      errorMessage={errors.lastName}
      modifiers={['modal']}
      testId="lastName"
    />
    <MDTextInput
      value={email2}
      type="text"
      onChange={handleInputChange}
      onBlur={validateInput}
      id="email2"
      label={<FormattedMessage id="companymanage.employees.email" defaultMessage="Email" />}
      errorMessage={errors.email2}
      modifiers={['modal']}
      testId="email2"
    />
    {showSendInvitation && (
      <MDCheckbox
        value={sendInvitation}
        id="sendInvitation"
        text={intl.formatMessage(messages.sendInvitation)}
        onChange={e => handleInputChange(e)}
        testId="sendInvitation"
        disabled={!email2}
        reverse
        className="employeeModal__sendInvitationCheckbox"
        tooltip={intl.formatMessage(messages.emailInvitationTooltip)}
      />
    )}
    <Select
      name={<FormattedMessage id="companymanage.employee.role" defaultMessage="Rola" />}
      id="role_id"
      withSearch
      options={selectOptions}
      onChange={e => handleInputChange({ target: { name: 'role_id', value: e } })}
      tooltip={
        <FormattedMessage
          {...messages.roleTooltip}
          values={{
            clickHere: (
              <a
                href="https://pomoc.kadromierz.pl/pl/articles/3967133-role-panel-zarzadzania-rolami-uprawnienia-i-restrykcje-uzytkownikow"
                target="__blank"
              >
                {intl.formatMessage(messages.clickHere)}
              </a>
            ),
          }}
        />
      }
      defaultValue={role}
      closeOnClick
      testId="role"
    />
  </div>
);

EmployeeModalBasic.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeModalBasic.propTypes = {
  name: PropTypes.string,
  lastName: PropTypes.string,
  email2: PropTypes.string,
  handleInputChange: PropTypes.func,
  validateInput: PropTypes.func,
  errors: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    email2: PropTypes.string,
  }),
  role: PropTypes.string,
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
  showSendInvitation: PropTypes.bool,
  sendInvitation: PropTypes.bool,
};

export default EmployeeModalBasic;
