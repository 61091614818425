import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { NavigationItem } from '@/utils/routes';

import SideBarCustomIcon from '../SideBarCustomIcon/SideBarCustomIcon.jsx';
import { UseSideBarValuesType } from '../useSideBar';

type Props = {
  nav: NavigationItem;
  toggleMobileMenu: UseSideBarValuesType['toggleMobileMenu'];
};

export const SideBarNavItem = ({ nav, toggleMobileMenu }: Props) => {
  const { id, name, path, icon, customIcon, iconBadgeContent, selected, additionalLabel } = nav;
  const sideBarNavClassNames = classnames('k-sideBarMenu__item', {
    'k-sideBarMenu__item--active': selected,
  });
  return (
    <li key={id} role="menuitem" className={sideBarNavClassNames} onClick={toggleMobileMenu}>
      <Link className="k-sideBarMenu__itemLink" to={`${path}`}>
        {customIcon ? (
          <SideBarCustomIcon path={path} />
        ) : (
          <Icon className="k-sideBarMenu__itemIcon" type="material" name={icon} />
        )}
        {Boolean(iconBadgeContent) && <span className="k-sideBarMenu__itemIcon--badge">{iconBadgeContent}</span>}
        <span className="k-sideBarMenu__itemLabel">{name}</span>
      </Link>
      {additionalLabel && <div className="k-sideBarMenu__itemAdditionalLabel">{additionalLabel}</div>}
    </li>
  );
};
