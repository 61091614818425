import moment from 'moment';

import { applyTolerance, calculatePayrollHour, getHoursAndColorsForPayrollRow } from '../payrollHelpers';

export const removeAvailabilitiesFromRowDetailsForSageExport = row => {
  let { timeWorked, start, end } = row;
  const { details } = row;
  details.forEach(detail => {
    if (detail.isAvailability) timeWorked -= detail.timeWorked;
  });
  const filteredDetails = details.filter(
    detail => !detail.isAvailability && detail.end !== null && !detail.isAbsence && !detail.isOvertimeCollection,
  );
  if (filteredDetails.length) {
    start = '';
    end = '';
    filteredDetails.forEach(detail => {
      if (!start) start = detail.start.slice(-8, -3);
      if (!end) end = detail.end.slice(-8, -3);
      if (start > detail.start) start = detail.start.slice(-8, -3);
      if (end < detail.end) end = detail.end.slice(-8, -3);
    });
  }

  return {
    ...row,
    timeWorked,
    start,
    end,
    details: filteredDetails,
  };
};

export const getStartEndForAvailbilityOrAbsence = row => {
  const { start, end } = row;
  if (start && end && (start !== '__:__' || end !== '__:__')) {
    return { start, end };
  }
  const hasAvailability = row.details.find(detail => detail.isAvailability);
  const result = {
    start: '08:00',
    end: '16:00',
  };
  if (hasAvailability) {
    const durationInHours = hasAvailability.availability.count_hours || 0;
    const durationInMinutes = Math.floor(durationInHours * 60);
    const endAva = moment('08:00', 'HH:mm')
      .add(durationInMinutes, 'minutes')
      .format('HH:mm');
    result.end = endAva;
    return result;
  }
  const hasAbsence = row.details.find(detail => detail.absence);
  if (hasAbsence) {
    const durationInMinutes = Math.floor(row.timeWorked);
    const endAbsence = moment('08:00', 'HH:mm')
      .add(durationInMinutes, 'minutes')
      .format('HH:mm');
    result.end = endAbsence;
  }
  return result;
};

export const extractAvailabilityFromRowForSageExport = (row, availabilities) => {
  const hasAvailability = row.details.find(detail => detail.isAvailability);
  const hasAbsence = row.details.find(detail => detail.absence);
  if ((!hasAvailability && !hasAbsence) || !availabilities) return '';
  if (hasAbsence) return hasAbsence.absence_short_name;
  const availabilityId = hasAvailability.availability.type_id;
  const avaDetails = availabilities.find(ava => ava.id === availabilityId);
  return avaDetails?.availability_short_name || avaDetails?.name || '';
};

export const extractFreeWeekDayShortname = date => {
  const weekday = moment(date).isoWeekday();
  switch (weekday) {
    case 7:
      return 'Niedz';
    case 6:
      return 'WolnSo';
    default:
      return 'W5';
  }
};

export const findRowConflict = rowDetails => {
  const availability = rowDetails.find(detail => detail.isAvailability);
  const attendance = rowDetails.find(detail => !detail.isAvailability);
  if (availability && attendance) {
    return 'Pracownik ma obecność w trakcie urlopu!';
  }
  if (!availability && !attendance) {
    return 'Pracownik ma nieusprawiedliwioną nieobecność';
  }
  return '';
};

export const isRemoteWork = avaShortName => (avaShortName ? avaShortName.toUpperCase() === 'PZ' : false);

export const isFreeForHoliday = (row, availabilities, holidayShortname) => {
  if (!row || !row.details.length) return false;
  const shortname = extractAvailabilityFromRowForSageExport(row, availabilities);
  return shortname.toUpperCase() === holidayShortname;
};

export const shouldDisplayScheduleHours = (row, avaShortName) => {
  const hasAvailability = row.details.find(detail => detail.isAvailability);
  const hasAbsence = row.details.find(detail => detail.absence);
  if (hasAvailability || hasAbsence) return false;
  return !isRemoteWork(avaShortName);
};

export const getDayShortname = (day, freeForHoliday, freeForW5) => {
  if (freeForHoliday) return 'ZŚ';
  if (freeForW5) return 'W5';
  return extractFreeWeekDayShortname(day);
};

export const sageExport = (rawData, mainDateStore, customAvailabilities, payrollSettings) => {
  let body = [];
  const { additional } = rawData.options;
  const noAdditionals = additional === 'no-additional';
  rawData.employeeData.forEach(data => {
    const { employee, relevantRows } = data;
    const employeeRows = mainDateStore.dateArray.map((day, i) => {
      let row = relevantRows.find(r => r.date === day);
      if (row && noAdditionals) row = removeAvailabilitiesFromRowDetailsForSageExport(row);
      const freeForHoliday = row && !noAdditionals && isFreeForHoliday(row, customAvailabilities, 'ZŚ');
      const freeForW5 = row && !noAdditionals && isFreeForHoliday(row, customAvailabilities, 'W5');
      if (!row || !row.details.length || freeForHoliday || freeForW5) {
        const dayShortname = getDayShortname(day, freeForHoliday, freeForW5);
        return [
          i + 1,
          employee.last_name,
          employee.first_name,
          employee.reference_id,
          moment(day).format('YYYY/MM/DD'),
          dayShortname,
          dayShortname,
          '00:00',
          freeForHoliday ? 'ZŚ' : '',
        ];
      }

      const rowExtraHours = getHoursAndColorsForPayrollRow(row);
      const availabilityShortname = noAdditionals
        ? ''
        : extractAvailabilityFromRowForSageExport(row, customAvailabilities);
      const { timeWorked, date } = row;
      const displayScheduleHours = shouldDisplayScheduleHours(row, availabilityShortname);

      const { attendanceStart, attendanceEnd } = rowExtraHours.displayHours;

      const attendanceStartWithTolerance = applyTolerance(
        `${date} ${attendanceStart}`,
        `${date} ${row.start}`,
        'start',
        payrollSettings,
      );
      const attendanceEndWithTolerance = applyTolerance(
        `${date} ${attendanceEnd}`,
        `${date} ${row.end}`,
        'end',
        payrollSettings,
      );
      const roundedAttendanceStart = calculatePayrollHour(attendanceStartWithTolerance, payrollSettings, 'start');
      const roundedAttendanceEnd = calculatePayrollHour(attendanceEndWithTolerance, payrollSettings, 'end');
      const { start, end } = getStartEndForAvailbilityOrAbsence(row);

      return [
        i + 1,
        employee.last_name,
        employee.first_name,
        employee.reference_id,
        moment(date).format('YYYY/MM/DD'),
        displayScheduleHours ? roundedAttendanceStart.slice(11, 16) : start,
        displayScheduleHours ? roundedAttendanceEnd.slice(11, 16) : end,
        `${`00${Math.floor(timeWorked / 60)}`.slice(-2)}:${`00${Math.floor(timeWorked % 60)}`.slice(-2)}`,
        availabilityShortname,
        isRemoteWork(availabilityShortname) ? '' : findRowConflict(row.details),
      ];
    });
    body = body.concat(employeeRows);
  });
  body.unshift([
    '',
    'Nazwisko',
    'Imię',
    'Nr referencyjny',
    'data',
    'Start pracy',
    'Koniec pracy',
    'Ilość godz.',
    'Nieobecność',
    'Konflikty',
  ]);
  body = [[], [], ['', 501], [], ...body];
  body = body.map(b => ['', ...b]);
  return [
    {
      data: body,
      filename: 'sage.xls',
      sheetName: 'rozliczenie',
    },
  ];
};
