import moment from 'moment';
import { defineMessages } from 'react-intl';

import { trackIntercomEvent } from '@/actions/intercom';
import * as actionTypes from '@/constants/ActionTypes.js';
import { ACCEPT_ABSENCE_SUCCESS, ADD_ABSENCE_SUCCESS } from '@/constants/ActionTypes.js';
import { ABSENCE_CALENDAR_MODAL, ABSENCE_HISTORY_MODAL, ADD_ABSENCE_PROPOSAL_MODAL } from '@/constants/modalTypes.js';
import {
  formatAbsencesForAbsencesCalendar,
  formatHistoryEntryToPdf,
  getAbsenceStatusName,
} from '@/utils/absenceHelpers.js';
import { getMonthNameFromNumber, parseMinutesToHumanForm } from '@/utils/dateHelper.js';
import { firstLetterToUpperCase } from '@/utils/stringHelpers';

import { changeAbsenceFilter } from './absenceFilter.js';
import { conn, showConfirmModal } from './index';
import { showModal } from './uiState.js';

const messages = defineMessages({
  addAbsenceSuccessNotificationTitle: {
    id: 'addAbsence.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  addAbsenceSuccessNotificationDescription: {
    id: 'addAbsence.success.notificationDescription',
    defaultMessage: 'Poprawnie dodano nieobecność',
  },

  editAbsenceSuccessNotificationTitle: {
    id: 'editAbsence.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  editAbsenceSuccessNotificationDescription: {
    id: 'editAbsence.success.notificationDescription',
    defaultMessage: 'Poprawnie zmieniono status',
  },
  editAbsenceFailureNotificationTitle: {
    id: 'editAbsence.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  editAbsenceFailureNotificationDescription: {
    id: 'editAbsence.failure.notificationDescription',
    defaultMessage: 'Nie udało się zmienić statusu',
  },
  deleteAbsenceSuccessNotificationTitle: {
    id: 'deleteAbsence.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  deleteAbsenceSuccessNotificationDescription: {
    id: 'deleteAbsence.success.notificationDescription',
    defaultMessage: 'Poprawnie usunięto wniosek',
  },
  deleteAbsenceFailureNotificationTitle: {
    id: 'deleteAbsence.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  deleteAbsenceFailureNotificationDescription: {
    id: 'deleteAbsence.failure.notificationDescription',
    defaultMessage: 'Nie udało się usunąc wniosku',
  },
  deleteAbsenceConfirmModalTitle: {
    id: 'deleteAbsence.confirmModal.title',
    defaultMessage: 'Usuń wniosek',
  },
  deleteAbsenceConfirmModalDescription: {
    id: 'deleteAbsence.confirmModal.description',
    defaultMessage: 'Usunięcie wniosku spowoduje utratę wszystkich danych urlopowych z nim powiązanych',
  },
  deleteChoosenRequestAbsence: {
    id: 'deleteAbsence.confirmModal.choosenRequest',
    defaultMessage: 'wybrany wniosek',
  },
  deleteAbsenceConfirmModalText: {
    id: 'deleteAbsence.confirmModal.text',
    defaultMessage: 'Usuń',
  },
  deleteAbsenceTypeConfirmModalTitle: {
    id: 'deleteAbsenceType.confirmModal.title',
    defaultMessage: 'Usuń typ urlopu',
  },
  deleteChoosenAbsences: {
    id: 'deleteAbsenceTypes.choosenAbsences',
    defaultMessage: 'wybrane urlopy',
  },
  deleteChoosenOneAbsence: {
    id: 'deleteAbsenceTypes.choosenOneAbsence',
    defaultMessage: 'wybrany urlop',
  },
  deleteAbsenceTypesNextConfirmModalDescription: {
    id: 'deleteAbsenceTypes.nextConfirmModal.description',
    defaultMessage: 'Usuwając typy nieobecności nie będziesz miał możliwości ich przywrócenia.',
  },
  deleteAbsenceTypesConfirmModalText: {
    id: 'deleteAbsenceTypes.confirmModal.text',
    defaultMessage: 'Usuń',
  },
  addAbsenceTypesSuccessNotificationTitle: {
    id: 'addAbsenceTypes.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  addAbsenceTypesSuccessNotificationDescription: {
    id: 'addAbsenceTypes.success.notificationDescription',
    defaultMessage: 'Dodano nowy typ urlopu',
  },
  addAbsenceTypesFailureNotificationTitle: {
    id: 'addAbsenceTypes.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  addAbsenceTypesFailureNotificationDescription: {
    id: 'addAbsenceTypes.failure.notificationDescription',
    defaultMessage: 'Dodanie typu nieobecności nie powiodło się',
  },
  editAbsenceTypesSuccessNotificationTitle: {
    id: 'editAbsenceTypes.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  editAbsenceTypesSuccessNotificationDescription: {
    id: 'editAbsenceTypes.success.notificationDescription',
    defaultMessage: 'Udało się zedytować typ urlopu',
  },
  editAbsenceTypesFailureNotificationTitle: {
    id: 'editAbsenceTypes.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  editAbsenceTypesFailureNotificationDescription: {
    id: 'editAbsenceTypes.failure.notificationDescription',
    defaultMessage: 'Nie udało się zedytować typu nieobecności',
  },
  deleteAbsenceTypesSuccessNotificationTitle: {
    id: 'deleteAbsenceTypes.success.notificationTitle',
    defaultMessage: 'Sukces',
  },
  deleteAbsenceTypesSuccessNotificationDescription: {
    id: 'deleteAbsenceTypes.success.notificationDescription',
    defaultMessage: 'Usunięcie powiodło się',
  },
  deleteAbsenceTypesFailureNotificationTitle: {
    id: 'deleteAbsenceTypes.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  deleteAbsenceTypesFailureNotificationDescription: {
    id: 'deleteAbsenceTypes.failure.notificationDescription',
    defaultMessage: 'Usunięcie nie powiodło się',
  },
  getAbsenceLimitsUsageFailureNotificationTitle: {
    id: 'getAbsenceLimitsUsage.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  getAbsenceLimitsUsageFailureNotificationDescription: {
    id: 'getAbsenceLimitsUsage.failure.notificationDescription',
    defaultMessage: 'Nie udało się pobrać limitów',
  },
  addAbsencesLimitsFailureNotifcationTitle: {
    id: 'addAbsencesLimits.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  addAbsencesLimitsFailureNotifcationDescription: {
    id: 'addAbsencesLimits.failure.notificationDescription',
    defaultMessage: 'Nie udało się dodać limitów nieobecności',
  },
  getAbsenceFailureNotifcationTitle: {
    id: 'getAbsence.failure.notificationTitle',
    defaultMessage: 'Błąd',
  },
  getAbsenceFailureNotifcationDescription: {
    id: 'getAbsence.failure.notificationDescription',
    defaultMessage: 'Nie udało się pobrać danych o urlopie',
  },
  errorTitle: {
    id: 'common.errorTitle',
    defaultMessage: 'Błąd!',
  },
  absenceCalendarTitle: {
    id: 'absenceCalendar.title',
    defaultMessage: 'Kalendarz nieobecności',
  },
  absenceCalendarEmployeeName: {
    id: 'absenceCalendar.emplyeename',
    defaultMessage: 'Pracownik',
  },
  absenceCalendarYear: {
    id: 'absenceCalendar.year',
    defaultMessage: 'Rok',
  },
  overtimeCollection: {
    id: 'overtimeCollectionPdf.overtimeCollection',
    defaultMessage: 'Wniosek o udzielenie czasu wolnego za godziny nadliczbowe',
  },
  month: {
    id: 'overtimeCollectionPdf.month',
    defaultMessage: 'Miesiąc',
  },
  employee: {
    id: 'overtimeCollectionPdf.employee',
    defaultMessage: 'Pracownik',
  },
  employer: {
    id: 'overtimeCollectionPdf.employer',
    defaultMessage: 'Pracodawca',
  },
});

export const getAbsences = (from, to, orderBy, direction) => async dispatch => {
  try {
    const response = await conn.getAbsences(from, to, 0, orderBy, direction);
    dispatch({
      type: actionTypes.GET_ABSENCES,
      payload: response.data.absences,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAbsenceFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.GET_ABSENCE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.getAbsenceFailureNotifcationTitle, {}),
      description: intl.formatMessage(messages.getAbsenceFailureNotifcationDescription, {}),
      type: 'error',
    },
  });
};

export const getAbsence = id => async dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await conn.getAbsence(id);
      dispatch({
        type: actionTypes.GET_ABSENCE_SUCCESS,
        payload: response.data,
      });
      resolve(response.data);
    } catch (err) {
      dispatch(getAbsenceFailure());
      reject();
    }
  });

export const addAbsenceSuccess = absence => (dispatch, getState, intl) => {
  const { userEmployees, absences } = getState().reducer;
  const employee = userEmployees.find(e => e.id === absence.employee_id);
  const absenceType = absences.absencesTypes.find(type => type.id === absence.type_id);

  const formattedNewAbsence = {
    ...absence,
    request_number: absence.series_number,
    employee_full_name: `${employee.first_name} ${employee.last_name}`,
    absence_type_name: absenceType.name,
  };

  dispatch({
    type: actionTypes.ADD_ABSENCE_SUCCESS,
    payload: formattedNewAbsence,
    notification: {
      title: intl.formatMessage(messages.addAbsenceSuccessNotificationTitle, {}),
      description: intl.formatMessage(messages.addAbsenceSuccessNotificationDescription, {}),
      type: 'success',
    },
  });
  dispatch(trackIntercomEvent(ADD_ABSENCE_SUCCESS));
};

export const addAbsenceFailure = notification => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_ABSENCE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.errorTitle),
      description: notification,
      type: 'error',
    },
  });
};

export const editAbsenceSuccess = absence => (dispatch, getState, intl) => {
  const { first_name: firstName, last_name: lastName } = getState().reducer.currentUser.user;

  dispatch({
    type: actionTypes.EDIT_ABSENCE_SUCCESS,
    payload: absence,
    userName: `${firstName} ${lastName}`,
    notification: {
      title: intl.formatMessage(messages.editAbsenceSuccessNotificationTitle),
      description: intl.formatMessage(messages.editAbsenceSuccessNotificationDescription),
      type: 'success',
    },
  });
};

export const editAbsenceFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_ABSENCE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.editAbsenceFailureNotificationTitle),
      description: intl.formatMessage(messages.editAbsenceFailureNotificationDescription),
      type: 'error',
    },
  });
};

const deleteAbsenceSuccess = absence => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_ABSENCE_SUCCESS,
    payload: absence,
    notification: {
      title: intl.formatMessage(messages.deleteAbsenceSuccessNotificationTitle),
      description: intl.formatMessage(messages.deleteAbsenceSuccessNotificationDescription),
      type: 'success',
    },
  });
};

export const deleteAbsenceFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_ABSENCE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.deleteAbsenceFailureNotificationTitle),
      description: intl.formatMessage(messages.deleteAbsenceFailureNotificationDescription),
      type: 'error',
    },
  });
};

export const changeAbsencesTableData = payload => ({
  type: actionTypes.CHANGE_ABSENCES_TABLE_DATA,
  payload,
});

export const changeAbsenceLimitUsageTableData = payload => ({
  type: actionTypes.CHANGE_ABSENCE_LIMIT_USAGE_TABLE_DATA,
  payload,
});

export const addAbsence = absence => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { currentUser } = getState().reducer;
      const { role } = currentUser.user;
      if (role === 'employee') delete absence.count_only_days_with_shifts;
      const response = await conn.addAbsence(absence);
      dispatch(addAbsenceSuccess(response.data));
      resolve();
    } catch (err) {
      console.error({ addAbsenceError: err });

      // TODO: remove this condition after BE will add code for the overlap
      if (err.response.data === 'Employee already has absence in the given time') {
        reject({ massage: 'Employee already has absence in the given time', code: 'absencesOverlap' });
        return;
      }

      if (err.response.status >= 500) {
        dispatch(addAbsenceFailure(err.response.data));
        reject();
      }

      reject(err.response.data);
    }
  });

const acceptAbsenceSuccess = absence => dispatch => {
  dispatch({
    type: actionTypes.ACCEPT_ABSENCE_SUCCESS,
    payload: absence,
  });
  dispatch(trackIntercomEvent(ACCEPT_ABSENCE_SUCCESS));
};

const rejectAbsenceSuccess = absence => ({
  type: actionTypes.REJECT_ABSENCE_SUCCESS,
  payload: absence,
});

export const editAbsence = (id, status) => async dispatch => {
  try {
    const response = await conn.editAbsence(id, status);
    dispatch(editAbsenceSuccess(response.data));
    if (status === 'accepted') {
      dispatch(acceptAbsenceSuccess(response.data));
    }
    if (status === 'rejected') {
      dispatch(rejectAbsenceSuccess(response.data));
    }
  } catch (err) {
    dispatch(editAbsenceFailure());
  }
};

export const deleteAbsence = absence => async (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteAbsenceConfirmModalTitle),
      description: intl.formatMessage(messages.deleteAbsenceConfirmModalDescription),
      confirmText: intl.formatMessage(messages.deleteAbsenceConfirmModalText),
      deletedThing: intl.formatMessage(messages.deleteChoosenRequestAbsence, {}),
      showDeleteInput: true,
      confirmFunc: async () => {
        try {
          await conn.deleteAbsence(absence.id);
          dispatch(deleteAbsenceSuccess(absence));
        } catch (err) {
          console.error(err);
          dispatch(deleteAbsenceFailure());
        }
      },
    }),
  );
};

export const getAbsencesTypes = () => async dispatch => {
  try {
    const response = await conn.getAbsencesTypes();

    dispatch({
      type: actionTypes.GET_ABSENCES_TYPES,
      payload: response.data.absence_types,
    });

    const absencesTypes = response.data.absence_types;
    const type =
      absencesTypes.find(absencesType => absencesType.is_default_uw_absence && absencesType.has_limit) ||
      absencesTypes.find(absencesType => absencesType.has_limit);

    if (type) {
      dispatch(changeAbsenceFilter(type));
    }
  } catch (error) {
    console.error(error);
    dispatch({
      type: actionTypes.GET_ABSENCES_TYPES,
      payload: [],
    });
  }
};

export const addAbsenceTypeSuccess = absence => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_ABSENCE_TYPE_SUCCESS,
    payload: absence,
    notification: {
      title: intl.formatMessage(messages.addAbsenceTypesSuccessNotificationTitle),
      description: intl.formatMessage(messages.addAbsenceTypesSuccessNotificationDescription),
      type: 'success',
    },
  });
};

export const addAbsenceTypeFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_ABSENCE_TYPE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.addAbsenceTypesFailureNotificationTitle),
      description: intl.formatMessage(messages.addAbsenceTypesFailureNotificationDescription),
      type: 'error',
    },
  });
};

export const addAbsenceType = absenceType => async (dispatch, getState) => {
  try {
    const response = await conn.addAbsenceType(absenceType);
    dispatch(addAbsenceTypeSuccess(response.data));
    if (getState().reducer.absenceFilter.selectedAbsence.id === 'Empty' && response.data.has_limit) {
      dispatch(changeAbsenceFilter(response.data));
    }
  } catch (err) {
    console.error(err);
    dispatch(addAbsenceTypeFailure());
  }
};

export const editAbsenceTypeSuccess = absence => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_ABSENCE_TYPE_SUCCESS,
    payload: absence,
    notification: {
      title: intl.formatMessage(messages.editAbsenceTypesSuccessNotificationTitle),
      description: intl.formatMessage(messages.editAbsenceTypesSuccessNotificationDescription),
      type: 'success',
    },
  });
};

export const editAbsenceTypeFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.EDIT_ABSENCE_TYPE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.editAbsenceTypesFailureNotificationTitle),
      description: intl.formatMessage(messages.editAbsenceTypesFailureNotificationDescription),
      type: 'error',
    },
  });
};

export const editAbsenceType = (id, editedAbsence) => async dispatch => {
  try {
    if (!editedAbsence.has_limit) {
      delete editedAbsence.limit_period;
      delete editedAbsence.limit_unit;
    }
    const response = await conn.editAbsenceType(id, editedAbsence);
    dispatch(editAbsenceTypeSuccess(response.data));
  } catch (err) {
    console.error(err);
    dispatch(editAbsenceTypeFailure());
  }
};

export const deleteAbsenceTypesSuccess = ids => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_ABSENCE_TYPE_SUCCESS,
    payload: ids,
    notification: {
      title: intl.formatMessage(messages.deleteAbsenceTypesSuccessNotificationTitle),
      description: intl.formatMessage(messages.deleteAbsenceTypesSuccessNotificationDescription),
      type: 'success',
    },
  });
};

export const deleteAbsenceTypesFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.DELETE_ABSENCE_TYPE_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.deleteAbsenceTypesFailureNotificationTitle),
      description: intl.formatMessage(messages.deleteAbsenceTypesFailureNotificationDescription),
      type: 'error',
    },
  });
};

export const deleteAbsenceTypes = absenceTypes => async (dispatch, getState, intl) => {
  dispatch(
    showConfirmModal({
      title: intl.formatMessage(messages.deleteAbsenceTypeConfirmModalTitle),
      description: intl.formatMessage(messages.deleteAbsenceTypesNextConfirmModalDescription, {}),
      confirmText: intl.formatMessage(messages.deleteAbsenceTypesConfirmModalText),
      deletedThing: intl.formatMessage(
        absenceTypes.length > 1 ? messages.deleteChoosenAbsences : messages.deleteChoosenOneAbsence,
        {},
      ),
      showDeleteInput: true,
      confirmFunc: async () => {
        try {
          const absenceTypesIds = absenceTypes.map(absenceType => absenceType.id);
          await conn.deleteAbsenceTypes(absenceTypesIds);
          dispatch(deleteAbsenceTypesSuccess(absenceTypesIds));
        } catch (err) {
          console.error(err);
          dispatch(deleteAbsenceTypesFailure());
        }
      },
    }),
  );
};

export const changeAbsencesData =
  (currentPage, numberOfItemsPerPage, from, to, query, orderBy, direction) => async dispatch => {
    try {
      if (query?.status) {
        query.status = query.status.toLowerCase();
      }

      const filter = query
        ? Object.entries(query).reduce((t, [key, value]) => `${t}${value ? `&${key}=${value}` : ''}`, '')
        : '';
      const offset = (currentPage - 1) * numberOfItemsPerPage;
      const response = await conn.getAbsences(
        from,
        to,
        offset,
        numberOfItemsPerPage,
        filter,
        orderBy,
        direction,
        'blocking',
      );

      const { numberOfItems } = response.data;
      dispatch(
        changeAbsencesTableData({
          currentPage,
          numberOfItemsPerPage,
          numberOfItems,
        }),
      );
      dispatch({
        type: actionTypes.GET_ABSENCES,
        payload: response.data.absences,
      });
    } catch (error) {
      console.error(error);
    }
  };

export const getAbsencesForSchedule = (from, to, locations, requestType) => async dispatch => {
  try {
    const { data } = await conn.getAbsencesForSchedule(from, to, locations, requestType);

    const formattedData = data.absences.reduce(
      (result, absence) => ({
        ...result,
        [absence.employee_id]: [...(result[absence.employee_id] || []), absence],
      }),
      {},
    );

    dispatch({
      type: actionTypes.GET_ABSENCES_FOR_SCHEDULE,
      payload: formattedData,
    });
  } catch (error) {
    console.error(error);
  }
};

const exportAbsenceToPdf = (absence, userEmployees, absences, requestType, intl) =>
  new Promise(async (resolve, reject) => {
    const employee = userEmployees.find(emp => emp.id === absence.employee_id);
    const absenceType = absences.absencesTypes.find(type => type.id === absence.type_id);
    const { data } = await conn.getAbsenceHistory(absence.id);
    const history = data.history.map(entry => formatHistoryEntryToPdf(entry, userEmployees, intl));
    const [startHour, endHour] = absence.absence_hours?.split('-') || [];
    const from = startHour
      ? `${moment(absence.from).format('DD.MM.YYYY')} ${startHour}`
      : moment(absence.from).format('DD.MM.YYYY');
    const to = endHour
      ? `${moment(absence.to).format('DD.MM.YYYY')} ${endHour}`
      : moment(absence.to).format('DD.MM.YYYY');

    const exportData = {
      series_number: absence.request_number,
      first_name: employee.first_name,
      last_name: employee.last_name,
      full_name: `${employee.first_name} ${employee.last_name}`,
      type_name: absenceType.name,
      from,
      to,
      number_of_days: moment(absence.to).diff(absence.from, 'days') + 1,
      number_of_hours: parseMinutesToHumanForm(absence.duration),
      note: absence.note || '-',
      status: getAbsenceStatusName(absence.status, intl),
      history,
    };
    conn
      .exportAbsence(exportData, requestType)
      .then(response => {
        resolve(response.data.downloadLink);
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });

const exportOvertimeCollectionToPdf = (overtimeCollection, userEmployees, requestType, intl) =>
  new Promise(async (resolve, reject) => {
    const {
      from,
      to,
      note,
      multiplier,
      month,
      year,
      amount50,
      amount100,
      amount_potential: amountPotential,
      request_number: requestNumber,
      employee_id: employeeId,
      recent_status_change_by_full_name: recentStatusChangeByFullName,
      created_at_timestamp: createdAtTimestamp,
      absence_hours: absenceHours,
    } = overtimeCollection;
    const employee = userEmployees.find(emp => emp.id === employeeId);
    const [startHour, endHour] = absenceHours.split('-');
    const fakeHistoryEntry = {
      action: 'create',
      timestamp: createdAtTimestamp,
      user_name: recentStatusChangeByFullName,
    };
    const history = [formatHistoryEntryToPdf(fakeHistoryEntry, userEmployees, intl)];
    const amount50Time = parseMinutesToHumanForm(amount50);
    const amount100Time = parseMinutesToHumanForm(amount100);
    const amountPotentialTime = parseMinutesToHumanForm(amountPotential);
    const amount50Text = amount50 ? `50% - ${amount50Time}` : null;
    const amount100Text = amount100 ? `100% - ${amount100Time}` : null;
    const amountPotentialText = amountPotential ? `PŚT - ${amountPotentialTime}` : null;
    const monthText = firstLetterToUpperCase(getMonthNameFromNumber(month));
    const monthMessage = intl.formatMessage(messages.month);
    const amounts = [amount50Text, amount100Text, amountPotentialText].filter(Boolean).join(' / ');
    const collectedOvertimeText = `${monthMessage} - ${monthText} ${year}, ${amounts}`;

    const exportData = {
      series_number: requestNumber,
      full_name: `${employee.first_name} ${employee.last_name}`,
      type_name: intl.formatMessage(messages.overtimeCollection),
      from: `${moment(from).format('DD.MM.YYYY')} ${startHour}`,
      to: `${moment(to).format('DD.MM.YYYY')} ${endHour}`,
      note: note || '-',
      applicant: intl.formatMessage(multiplier === 1 ? messages.employee : messages.employer),
      collected_overtime: collectedOvertimeText,
      signature: true,
      history,
    };
    conn
      .exportAbsence(exportData, requestType)
      .then(response => {
        resolve(response.data.downloadLink);
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });

export const exportAbsenceItemToPdf = (absence, requestType) => async (dispatch, getState, intl) => {
  const { userEmployees, absences } = getState().reducer;
  switch (absence.category) {
    case 'overtime_collection':
      return exportOvertimeCollectionToPdf(absence, userEmployees, requestType, intl);
    default:
      return exportAbsenceToPdf(absence, userEmployees, absences, requestType, intl);
  }
};

export const getAbsenceHistory =
  (absence, { showPrev = false, requestType = 'blocking' } = {}) =>
  async dispatch => {
    try {
      const response = await conn.getAbsenceHistory(absence.id, requestType);
      dispatch(
        showModal(ABSENCE_HISTORY_MODAL, {
          history: response.data,
          item: absence,
          previousModalName: showPrev ? ADD_ABSENCE_PROPOSAL_MODAL : null,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

export const showAbsenceCalendar = absence => async (dispatch, getState, intl) => {
  try {
    const { start, end } = getState().reducer.mainDateStore.customDate;
    const { employee_id: employeeId } = absence;
    const response = await conn.getAbsencesForEmployee(start, end, 0, employeeId);
    const { absences } = response.data;
    const formattedAbsences = formatAbsencesForAbsencesCalendar(absences, intl);
    dispatch(
      showModal(ABSENCE_CALENDAR_MODAL, {
        employee: { employeeId: absence.employee_id, employeeFullName: absence.employee },
        absences: formattedAbsences,
      }),
    );
  } catch (err) {
    console.error(err);
  }
};

export const getAbsenceLimitsUsageSuccess = absenceLimitsUsage => dispatch => {
  dispatch({
    type: actionTypes.GET_ABSENCE_LIMIT_USAGE_SUCCESS,
    payload: absenceLimitsUsage,
  });
};

export const getAbsenceLimitsUsageFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.GET_ABSENCE_LIMIT_USAGE_FAILURE,
    payload: [],
    notification: {
      title: intl.formatMessage(messages.getAbsenceLimitsUsageFailureNotificationTitle, {}),
      description: intl.formatMessage(messages.getAbsenceLimitsUsageFailureNotificationDescription, {}),
      type: 'error',
    },
  });
};

export const getAbsenceLimitsUsage =
  (absenceType, from, to, currentPage, numberOfItemsPerPage, employeeQueryString) => async dispatch => {
    try {
      const offset = (currentPage - 1) * numberOfItemsPerPage;

      const response = await conn.getAbsenceLimitsUsage(
        absenceType.id,
        from,
        to,
        numberOfItemsPerPage,
        offset,
        employeeQueryString,
        'blocking',
      );

      const numberOfItems = response.data.number_of_items;

      dispatch(
        changeAbsenceLimitUsageTableData({
          currentPage,
          numberOfItemsPerPage,
          numberOfItems,
        }),
      );

      dispatch(getAbsenceLimitsUsageSuccess(response.data.limit_usage));
    } catch (err) {
      console.error(err);
      dispatch(getAbsenceLimitsUsageFailure());
    }
  };

export const getAbsencesLimitTemplates = conditionsId => async dispatch => {
  try {
    const response = await conn.getAbsencesLimitTemplates(conditionsId);
    dispatch({
      type: actionTypes.SET_ABSENCES_LIMITS,
      payload: response.data.limit_templates,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAbsencesLimitsEmployee = employeeId => async dispatch => {
  try {
    const response = await conn.getAbsencesLimitsEmployee(employeeId);
    dispatch({
      type: actionTypes.SET_ABSENCES_LIMITS,
      payload: response.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const addAbsencesLimitsSuccess = () => dispatch => {
  dispatch({
    type: actionTypes.ADD_ABSENCES_LIMITS_SUCCESS,
    payload: {},
  });
};

export const addAbsencesLimitsFailure = () => (dispatch, getState, intl) => {
  dispatch({
    type: actionTypes.ADD_ABSENCES_LIMITS_FAILURE,
    payload: {},
    notification: {
      title: intl.formatMessage(messages.addAbsencesLimitsFailureNotifcationTitle, {}),
      description: intl.formatMessage(messages.addAbsencesLimitsFailureNotifcationDescription, {}),
      type: 'error',
    },
  });
};

export const addAbsencesLimitsEmployee = (limits, employeeId) => async dispatch => {
  try {
    await conn.addAbsencesLimitsEmployee(limits, employeeId);
    dispatch(addAbsencesLimitsSuccess());
  } catch (err) {
    console.error(err);
    dispatch(addAbsencesLimitsFailure());
  }
};

export const addAbsencesLimitTemplates = (templates, conditionsId) => async dispatch => {
  try {
    await conn.addAbsencesLimitTemplates(
      templates.map(template => ({ ...template, employment_conditions_template_id: conditionsId })),
    );
    dispatch(addAbsencesLimitsSuccess());
  } catch (err) {
    console.error(err);
    dispatch(addAbsencesLimitsFailure());
  }
};

export const resetAbsencesLimits = () => dispatch => {
  dispatch({
    type: actionTypes.SET_ABSENCES_LIMITS,
    payload: [],
  });
};

export const getAbsenceLimitsForEmployee = (employeeId, from, to) => async dispatch => {
  try {
    const response = await conn.getAbsenceLimitsForEmployee(employeeId, from, to);
    dispatch({
      type: actionTypes.CHANGE_ABSENCE_LIMITS_FOR_EMPLOYEE,
      payload: response.data.limit_usage,
    });
  } catch (err) {
    console.error(err);
  }
};

export const changeAbsencesMode = mode => dispatch => {
  dispatch({
    type: actionTypes.CHANGE_ABSENCES_MODE,
    payload: mode,
  });
};
