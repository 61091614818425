import classnames from 'classnames';
import { PropTypes } from 'prop-types';

const KadroCheckbox = props => {
  const id = `checkbox${Math.floor(Math.random() * 100000)}`;
  const classNames = classnames('k-checkbox', {
    'k-checkbox--absoluteCenter': props.absoluteCenter,
    'k-checkbox--small': props.small,
  });
  return (
    <div className={classNames}>
      <input type="checkbox" id={id} className="k-checkbox__input" checked={props.checked} onChange={props.onChange} />
      <label htmlFor={id} className="k-checkbox__label" />
    </div>
  );
};
KadroCheckbox.propTypes = {
  checked: PropTypes.bool,
  absoluteCenter: PropTypes.bool,
  small: PropTypes.bool,
  onChange: PropTypes.func,
};
export default KadroCheckbox;
