import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useRef } from 'react';

import HoverPopOver from '@/components/common/HoverPopOver.jsx';
import ScheduleHeaderDate from '@/components/scheduler/ScheduleTable/ScheduleHeaderDate/ScheduleHeaderDate.jsx';
import { useMultipleStickyElements } from '@/hooks/useMultipleStickyElements/useMultipleStickyElements';
import { isHoliday, isWeekend } from '@/utils/dateHelper.js';

import { messages } from './EmployeeScheduleTableHeader.messages';

const EmployeeScheduleTableHeader = (props, { intl }) => {
  const tableHeaderRef = useRef(null);
  const [top] = useMultipleStickyElements(tableHeaderRef, 'scheduleTableHeader--sticky');

  const { size, locationName, holidays, mainDateStore, holidaysEnabled, scheduleLocationFilter } = props;
  const displayMultiple = scheduleLocationFilter.length > 1;
  const headerTitle = displayMultiple ? locationName : intl.formatMessage(messages.scheduleTableHeaderTitle);
  const isMonthView = size === 'month';

  return (
    <tr
      ref={tableHeaderRef}
      className="scheduleTableHeader scheduleTableHeader--sticky"
      style={{
        top,
      }}
    >
      <th>
        <div>{headerTitle}</div>
      </th>
      {mainDateStore.dateArray.map((date, index) => {
        const holiday = isHoliday(date, holidays);
        const freeFromWorkHoliday = holidaysEnabled && holiday.freeFromWork;
        const workDayHoliday = holidaysEnabled && holiday.workDay;
        const showPopover = holidaysEnabled && holiday?.name;

        const headerClassName = classnames('scheduleTableHeader__day', {
          'scheduleTableHeader--today': date === mainDateStore.today,
          'scheduleTableHeader--weekend': isWeekend(date),
          'scheduleTableHeader--freeFromWorkHoliday': freeFromWorkHoliday,
          'scheduleTableHeader--workDayHoliday': workDayHoliday,
          'scheduleTableHeader--withPopover': showPopover,
        });

        const popover = {
          content: [
            <div key={index} className="holidayPopOver">
              {holiday.name}
            </div>,
          ],
        };

        const renderedCell = (
          <ScheduleHeaderDate
            date={date}
            isMonthView={isMonthView}
            showFreeFromWorkIcon={freeFromWorkHoliday}
            showWorkHolidayIcon={workDayHoliday}
          />
        );

        return (
          <th key={index} className={headerClassName}>
            {showPopover ? (
              <HoverPopOver name="holidayHover" popover={popover}>
                <div>{renderedCell}</div>
              </HoverPopOver>
            ) : (
              renderedCell
            )}
          </th>
        );
      })}
    </tr>
  );
};

EmployeeScheduleTableHeader.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeScheduleTableHeader.propTypes = {
  scheduleLocationFilter: PropTypes.arrayOf(PropTypes.string),
  locationName: PropTypes.string,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
    today: PropTypes.string,
  }),
  holidaysEnabled: PropTypes.bool,
  holidays: PropTypes.arrayOf(PropTypes.shape({})),
  size: PropTypes.string,
};

export default memo(EmployeeScheduleTableHeader);
