import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import ScheduleShiftWrapper from '@/components/scheduler/ScheduleTable/ScheduleShiftBlock/ScheduleShiftWrapper/ScheduleShiftWrapper';
import { LIGHT_GRAY } from '@/constants/colors';

import { addTooltipToDragImage, dragAndDropCleanup, shouldShiftConnectWithAbsence } from './ScheduleShiftBlock.helpers';
import ScheduleShiftBlockDropArea from './ScheduleShiftBlockDropArea/ScheduleShiftBlockDropArea.redux';

import './ScheduleShiftBlock.scss';

const ScheduleShiftBlock = (props, { intl }) => {
  const {
    from,
    to,
    title,
    color,
    isFromOtherLocation,
    isDraft,
    isInvalid,
    isDraggable,
    isDisabled,
    hasComment,
    showNoteIcon,
    showImportantNoteIcon,
    size,
    onAdd,
    onEdit,
    onDelete,
    onClick,
    children,
    onDragStart,
    onDragEnd,
    isQuickPlanningEnabled,
    isEditDisabled,
    isEmployee,
    availabilitiesModeEnabled,
    shiftId,
    employeeId,
    isShiftDragged,
    onDragCapture,
    isAddDisabled,
    tooltipText,
    absencesForDate,
    shift,
    isAbsenceConnectingWithAnyScheduleBlock,
    className,
    isIncomingProposal,
    isOutgoingProposal,
    showDetails,
  } = props;

  const [absence] = absencesForDate;
  const dayHasMultipleAbsences = absencesForDate.length > 1;
  const isAbsenceDraft = absence?.status === 'draft';
  const shouldConnectWithAbsence = shouldShiftConnectWithAbsence(absence, shift, dayHasMultipleAbsences);

  const classNames = classnames('k-scheduleBlock', `k-scheduleBlock--${size}`, className, {
    'k-scheduleBlock--fromOtherLocation': isFromOtherLocation,
    'k-scheduleBlock--draft': isDraft,
    'k-scheduleBlock--invalid': isInvalid,
    'k-scheduleBlock--noOptions': !(onEdit || onAdd || onDelete) || isEmployee,
    'k-scheduleBlock--oneOption': !onEdit && onAdd && !onDelete,
    'k-scheduleBlock--noTitle': !title,
    'k-scheduleBlock--readonly': isQuickPlanningEnabled || isEditDisabled,
    'k-scheduleBlock--allowPointerEvents': isEmployee,
    'k-scheduleBlock--avaMode': availabilitiesModeEnabled,
    'k-scheduleBlock--hidden': isShiftDragged,
    'k-scheduleBlock--disabled': isDisabled,
    'k-scheduleBlock--absenceInDay': !!absencesForDate.length && isAbsenceConnectingWithAnyScheduleBlock,
    'k-scheduleBlock--isAbsenceInDayDraft': isAbsenceDraft,
    'k-scheduleBlock--connectWithAbsence': shouldConnectWithAbsence,
  });

  const showAddIcon = !isEmployee && onAdd && !isAddDisabled;
  const onClickHandler = useCallback(
    e => {
      if (onClick && !isDisabled) onClick(e);
    },
    [onClick, isDisabled],
  );

  return (
    <ScheduleShiftWrapper tooltipText={tooltipText}>
      <div
        className={classNames}
        style={{ backgroundColor: shouldConnectWithAbsence ? LIGHT_GRAY : color }}
        onClick={onClickHandler}
        draggable={isDraggable && !isEmployee}
        onDragStart={e => {
          addTooltipToDragImage(e, intl);
          onDragStart(e);
        }}
        onDragEnd={e => {
          dragAndDropCleanup();
          onDragEnd(e);
        }}
        onDragOver={e => e.preventDefault()}
        role="presentation"
        onDragCapture={e => {
          if (onDragCapture) {
            onDragCapture(e);
          }
        }}
      >
        <ScheduleShiftBlockDropArea shiftId={shiftId} employeeId={employeeId} />
        <div className="k-scheduleBlock__infoContainer">
          {children}
          <span className="k-scheduleBlock__hours k-scheduleBlock__hours--from">{from}</span>
          <span className="k-scheduleBlock__hours k-scheduleBlock__hours--to">{to}</span>
          <span className="k-scheduleBlock__jobTitle">{title}</span>
          {isInvalid && <i className="material-icons-outlined k-scheduleBlock__errorIcon">error_outline</i>}
          {hasComment && <i className="material-icons k-scheduleBlock__commentIcon">message</i>}
          {showNoteIcon && <i className="material-icons k-scheduleBlock__noteIcon">sticky_note_2</i>}
          {showImportantNoteIcon && <i className="material-icons k-scheduleBlock__importantNoteIcon">error_outline</i>}
          {isIncomingProposal && <i className="material-icons k-scheduleBlock__loginIcon">login</i>}
          {isOutgoingProposal && <i className="material-icons k-scheduleBlock__logoutIcon">logout</i>}
        </div>
        <div className="k-scheduleBlock__iconContainer">
          {!isEmployee && onDelete ? (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--delete"
              onClick={onDelete}
              role="presentation"
            >
              delete
            </i>
          ) : null}

          {!isEmployee && onEdit && !showDetails ? (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--edit"
              onClick={onEdit}
              role="presentation"
            >
              edit
            </i>
          ) : null}

          {showDetails ? (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--edit"
              onClick={showDetails}
              role="presentation"
            >
              feed
            </i>
          ) : null}

          {showAddIcon ? (
            <i
              className="material-icons k-scheduleBlock__icon k-scheduleBlock__icon--add"
              onClick={onAdd}
              role="presentation"
            >
              add
            </i>
          ) : null}
        </div>
      </div>
    </ScheduleShiftWrapper>
  );
};

ScheduleShiftBlock.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

ScheduleShiftBlock.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  isFromOtherLocation: PropTypes.bool,
  isDraft: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDraggable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasComment: PropTypes.bool,
  showNoteIcon: PropTypes.bool,
  showImportantNoteIcon: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  children: PropTypes.node,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  isQuickPlanningEnabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  isEmployee: PropTypes.bool,
  availabilitiesModeEnabled: PropTypes.bool,
  shiftId: PropTypes.string,
  employeeId: PropTypes.string,
  isShiftDragged: PropTypes.bool,
  onDragCapture: PropTypes.func,
  isAddDisabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  absencesForDate: PropTypes.arrayOf(
    PropTypes.shape({
      absence_hours: PropTypes.string,
      count_only_days_with_shifts: PropTypes.bool,
      status: PropTypes.string,
    }),
  ),
  shift: PropTypes.shape({}),
  isAbsenceConnectingWithAnyScheduleBlock: PropTypes.bool,
  className: PropTypes.string,
  isIncomingProposal: PropTypes.bool,
  isOutgoingProposal: PropTypes.bool,
  showDetails: PropTypes.func,
};

ScheduleShiftBlock.defaultProps = {
  absencesForDate: [],
};

export default ScheduleShiftBlock;
