import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import RolesTableRowDetails from '../RolesTableRowDetails/RolesTableRowDetails.redux.js';
import RolesTableRowCheckboxes from './RolesTableRowCheckboxes/RolesTableRowCheckboxes.jsx';

import './RolesTableRow.scss';

const RolesTableRow = props => {
  const {
    toggledGroups,
    roles,
    grouppedPermissions,
    groupId,
    checkedPermissions,
    togglePermission,
    checkPermissions,
    uncheckPermissions,
  } = props;
  const toggled = toggledGroups.includes(groupId);
  const toggledRowGroup = classnames('k-rolesTable__details', {
    'k-rolesTable__details--show': toggled,
  });
  const rowClassName = classnames('k-rolesTableRow', {
    'k-rolesTableRow--open': toggled,
  });

  return (
    <tr className={rowClassName}>
      <td className="k-rolesTableRow__row">
        <table className="table">
          <tbody>
            <tr style={{ heigh: 'auto', border: 'none' }}>
              <RolesTableRowCheckboxes
                checkedPermissions={checkedPermissions}
                uncheckPermissions={uncheckPermissions}
                checkPermissions={checkPermissions}
                roles={roles}
                grouppedPermissions={grouppedPermissions}
                groupId={groupId}
              />
            </tr>
            <tr className={toggledRowGroup}>
              <td className="k-rolesTable__detailsRow" colSpan={roles.length}>
                <RolesTableRowDetails
                  permissions={grouppedPermissions}
                  roles={roles}
                  checkedPermissions={checkedPermissions}
                  togglePermission={togglePermission}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

RolesTableRow.propTypes = {
  employee: PropTypes.shape({}),
  roles: PropTypes.arrayOf(PropTypes.shape({})),
  toggledGroups: PropTypes.arrayOf(PropTypes.string),
  grouppedPermissions: PropTypes.arrayOf(PropTypes.shape({})),
  groupId: PropTypes.string,
  checkedPermissions: PropTypes.shape({}),
  togglePermission: PropTypes.func,
  checkPermissions: PropTypes.func,
  uncheckPermissions: PropTypes.func,
};

export default RolesTableRow;
