import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { createEvent } from '@/utils/inputHelpers';

import { getKadroPackages } from './PackagesOptions.helpers';
import { messages } from './PackagesOptions.messages';

import './PackagesOptions.scss';

const PackagesOptions = ({ payment, onChange, selectedPackageId }, { intl }) => {
  const kadroPackages = useMemo(() => getKadroPackages(intl), []);

  return (
    <div className="packagesOptions">
      {kadroPackages.map(kadroPackage => {
        const selected = kadroPackage.id === selectedPackageId;
        const itemClassname = classnames('packagesOptions__item', { 'packagesOptions__item--selected': selected });

        return (
          <div
            key={kadroPackage.id}
            onClick={() => onChange(createEvent('selectedPackage', kadroPackage))}
            role="presentation"
            className={itemClassname}
          >
            {kadroPackage.isMostPopular && (
              <div className="packagesOptions__mostPopular">{intl.formatMessage(messages.mostPopular)}</div>
            )}
            <img className="packagesOptions__img" src={kadroPackage.img} alt={kadroPackage.name} />
            <div className="packagesOptions__name">{kadroPackage.name}</div>
            <div className="packagesOptions__price">
              {kadroPackage.price[payment].toFixed(2)}
              <span className="packagesOptions__currency"> zł</span>
            </div>
            <div className="packagesOptions__priceDetails">{intl.formatMessage(messages.monthlyPerEmployee)}</div>
            <div className="packagesOptions__description">{kadroPackage.description}</div>
            <div className="packagesOptions__circle">
              {selected && <div className="packagesOptions__innerCircle" />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

PackagesOptions.contextTypes = {
  intl: PropTypes.shape({}),
};

PackagesOptions.propTypes = {
  selectedPackageId: PropTypes.string,
  payment: PropTypes.string,
  onChange: PropTypes.func,
};

export default PackagesOptions;
