import { connect } from 'react-redux';

import RolesView from './RolesView.jsx';

const mapStateToProps = state => ({
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

const RolesViewContainer = connect(mapStateToProps, mapDispatchToProps)(RolesView);

export default RolesViewContainer;
