import { connect } from 'react-redux';

import EmployeeScheduleBlocks from './EmployeeScheduleBlocks.jsx';

const mapStateToProps = (state, ownProps) => ({
  employeeOvertimeCollections: state.reducer.overtimeCollections[ownProps.employee.id],
  employeeAbsences: state.reducer.absences.scheduleAbsences[ownProps.employee.id],
  dateArray: state.reducer.mainDateStore.dateArray,
  absencesTypes: state.reducer.absences.absencesTypes,
  currentUserId: state.reducer.currentUser.user.id,
});

export default connect(mapStateToProps)(EmployeeScheduleBlocks);
