import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PulsatingDot from './PulsatingDot.jsx';

const DashboardTimeTrackerStarted = props => {
  const {
    currentAttendance,
    currentBreak,
    enableRestBreak,
    showSummaryModal,
    handleStartBreak,
    handleEndBreak,
    loading,
  } = props;

  const isLongAttandance = moment(currentAttendance.start_timestamp).diff(moment().startOf('day'), 'days') > 0;
  const startTimeFormat = isLongAttandance ? 'HH:mm  -  DD MMMM YYYY' : 'HH:mm';

  const attendanceStartTimeClassname = classnames('employeeTimeTrackerContentText__mainText', {
    'employeeTimeTrackerContentText__mainText--active': !currentBreak,
  });

  return (
    <div className="ibox-content employeeTimeTracker__content">
      <div className="employeeTimeTrackerContent__text">
        <div className={attendanceStartTimeClassname}>
          <span className="employeeTimeTrackerContentText__dot">
            <PulsatingDot color="#86bf00" />
          </span>
          <FormattedMessage id="dashboard.timeTacker.startTimeWithBreak" defaultMessage="Początek pracy:" />
          <div className="employeeTimeTrackerContentText__startTime">
            {moment(currentAttendance.start_timestamp).format(startTimeFormat)}
          </div>
        </div>
        {currentBreak && (
          <div className="employeeTimeTrackerContentText__subText">
            <FormattedMessage
              id="dashboard.timeTacker.breakStartTime"
              defaultMessage="Start przerwy: {startTime}"
              values={{
                startTime: moment(currentBreak.start_timestamp).format('HH:mm'),
              }}
            />
          </div>
        )}
      </div>

      <div className="employeeTimeTracker__buttonsWrapper">
        <button
          className="employeeTimeTracker__button employeeTimeTracker__button--work"
          onClick={showSummaryModal}
          disabled={loading}
        >
          <span className="material-icons employeeTimeTrackerButton__icon">stop</span>
          <FormattedMessage id="dashboard.timeTacker.endShift" defaultMessage="Zakończ pracę" />
        </button>
        {enableRestBreak &&
          (!currentBreak ? (
            <button
              className="employeeTimeTracker__button employeeTimeTracker__button--break"
              onClick={handleStartBreak}
              disabled={loading}
            >
              <span className="material-icons employeeTimeTrackerButton__icon">play_arrow</span>
              <FormattedMessage id="dashboard.timeTacker.startBreak" defaultMessage="Rozpocznij przerwę" />
            </button>
          ) : (
            <button
              className="employeeTimeTracker__button employeeTimeTracker__button--break"
              onClick={handleEndBreak}
              disabled={loading}
            >
              <span className="material-icons employeeTimeTrackerButton__icon">pause</span>
              <FormattedMessage id="dashboard.timeTacker.endBreak" defaultMessage="Zakończ przerwę" />
            </button>
          ))}
      </div>
    </div>
  );
};

DashboardTimeTrackerStarted.propTypes = {
  currentAttendance: PropTypes.shape({
    start_timestamp: PropTypes.string,
  }),
  currentBreak: PropTypes.shape({
    start_timestamp: PropTypes.string,
  }),
  enableRestBreak: PropTypes.bool,
  showSummaryModal: PropTypes.func,
  handleStartBreak: PropTypes.func,
  handleEndBreak: PropTypes.func,
  loading: PropTypes.bool,
};

export default DashboardTimeTrackerStarted;
