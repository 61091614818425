import { connect } from 'react-redux';

import { showAttendancesForLocation } from '@/actions/attendances';

import Component from './DashboardLocationsItem';

const mapDispatchToProps = {
  setAttendanceModeToday: showAttendancesForLocation,
};

export default connect(null, mapDispatchToProps)(Component);
