import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import TimeInput from '@/components/common/inputs/TimeInput.jsx';
import { createEvent } from '@/utils/inputHelpers.js';
import { inputValidation } from '@/utils/inputValidation.js';

import SettingsNameWithLabel from '../SettingsNameWithLabel/SettingsNameWithLabel';

import './SingleTimeInput.scss';

const SingleTimeInput = props => {
  const { name, label, onlyLabel, handleInput, slug, error, value } = props;

  const changeTime = useCallback(
    time => {
      handleInput(createEvent(slug, time));
    },
    [slug],
  );

  const isHourIncorrect = inputValidation(slug, value);

  return (
    <div className="singleTimeInput">
      <SettingsNameWithLabel
        name={name}
        label={label}
        onlyLabel={onlyLabel}
        short
        content={
          <div className="singleTimeInput__content">
            <TimeInput initialValue={value} onChange={changeTime} short className="singleTimeInput__containerInput" />
            {(error || isHourIncorrect) && (
              <p className="singleTimeInput__error">
                <FormattedMessage id="singleTimeInput.incorrectHour" defaultMessage="Niepoprawna godzina" />
              </p>
            )}
          </div>
        }
      />
    </div>
  );
};

SingleTimeInput.propTypes = {
  options: PropTypes.shape({}),
  name: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onlyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleInput: PropTypes.func,
  slug: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
};

export default SingleTimeInput;
