import moment from 'moment';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import { getAbsenceTypeUnit } from '@/utils/absenceHelpers.js';
import { getMonthInflected, parseMinutesToHours } from '@/utils/dateHelper.js';

import './SidebarAbsenceContent.scss';

const messages = defineMessages({
  sidebarAbsenceContentLeft: {
    id: 'sidebarAbsenceContent.left',
    defaultMessage: 'Pozostało',
  },
  sidebarAbsenceContentIn: {
    id: 'sidebarAbsenceContent.in',
    defaultMessage: 'w',
  },
  sidebarAbsenceContentFrom: {
    id: 'sidebarAbsenceContent.from',
    defaultMessage: 'od',
  },
  sidebarAbsenceContentTo: {
    id: 'sidebarAbsenceContent.to',
    defaultMessage: 'do',
  },
});

const SidebarAbsenceContent = ({ absenceType, absenceLimits }, context) => (
  <div className="sidebarAbsenceContent">
    {absenceLimits.map(absenceLimit => {
      if (
        (!absenceLimit.left && absenceLimit.left !== 0) ||
        (!absenceLimit.used && absenceLimit.used !== 0) ||
        (!absenceLimit.limit && absenceLimit.limit !== 0)
      ) {
        return null;
      }

      const left = absenceType.limit_unit === 'days' ? absenceLimit.left : parseMinutesToHours(absenceLimit.left);
      const limit = absenceType.limit_unit === 'days' ? absenceLimit.limit : parseMinutesToHours(absenceLimit.limit);

      const from = moment(absenceLimit.from);
      const to = moment(absenceLimit.to);

      let fromTo = '';
      if (absenceType.limit_period === 'week') {
        fromTo = `${context.intl.formatMessage(messages.sidebarAbsenceContentFrom)} ${from.format(
          'DD-MM-YYYY',
        )} ${context.intl.formatMessage(messages.sidebarAbsenceContentTo)} ${to.format('DD-MM-YYYY')}`;
      } else if (absenceType.limit_period === 'month') {
        fromTo = `${context.intl.formatMessage(messages.sidebarAbsenceContentIn)} ${getMonthInflected(
          Number.parseInt(from.format('M')),
          context.intl,
        )} ${from.format('YYYY')}`;
      } else if (absenceType.limit_period === 'year') {
        fromTo = `${context.intl.formatMessage(messages.sidebarAbsenceContentIn)} ${to.format('YYYY')}`;
      }

      return (
        <div className="sidebarAbsenceContent__limit">
          <Icon className="sidebarAbsenceContent__icon" name="hourglass_empty" />
          <div>
            <div>
              {`${context.intl.formatMessage(
                messages.sidebarAbsenceContentLeft,
              )} ${left} z ${limit} ${getAbsenceTypeUnit(absenceType, context.intl)}`}
            </div>
            <div>{fromTo}</div>
          </div>
        </div>
      );
    })}
  </div>
);

SidebarAbsenceContent.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

SidebarAbsenceContent.propTypes = {
  absenceType: PropTypes.shape({
    limit_unit: PropTypes.string,
    limit_period: PropTypes.string,
  }),
  absenceLimits: PropTypes.arrayOf(
    PropTypes.shape({
      left: PropTypes.number,
    }),
  ),
};

export default SidebarAbsenceContent;
