export const sortStructure = (structure, data) =>
  Object.keys(structure).reduce(
    (agg, date) => ({
      ...agg,
      [date]: Object.keys(structure[date]).reduce(
        (dateAgg, locationId) => ({
          ...dateAgg,
          [locationId]: structure[date][locationId].sort((aId, bId) => (data[aId].hours > data[bId].hours ? 1 : -1)),
        }),
        {},
      ),
    }),
    {},
  );
export const mapEventsToStructure = (currentStructure, currentData, eventsTable) => {
  const newData = eventsTable.reduce((agg, item) => ({ ...agg, [item.id]: item }), {});
  const data = { ...currentData, ...newData };
  const structure = eventsTable.reduce(
    (agg, item) => {
      const locationId = item.location.id;
      const { date } = item;
      const locationData = agg[date]?.[locationId] || [];
      return {
        ...agg,
        [date]: {
          ...(agg[date] || {}),
          [locationId]: locationData.includes(item.id) ? locationData : [...locationData, item.id],
        },
      };
    },
    { ...currentStructure },
  );
  const sortedStructure = sortStructure(structure, data);
  return { data, structure: sortedStructure };
};

export const addEventToStructure = (currentStructure, currentData, event) => {
  const data = { ...currentData, [event.id]: event };
  const structure = {
    ...currentStructure,
    [event.date]: {
      ...(currentStructure[event.date] || {}),
      [event.location.id]: [...(currentStructure[event.date]?.[event.location.id] || []), event.id],
    },
  };
  const sortedStructure = sortStructure(structure, data);
  return { data, structure: sortedStructure };
};

export const addMassEventToStructure = (currentStructure, currentData, events) => {
  const eventsDataAndStructure = events.reduce(
    (acc, event) => ({
      eventsData: { ...acc.eventsData, [event.id]: event },
      eventsStructure: {
        ...acc.eventsStructure,
        [event.date]: {
          [event.location.id]: [...(currentStructure[event.date]?.[event.location.id] || []), event.id],
        },
      },
    }),
    {},
  );

  const { eventsData, eventsStructure } = eventsDataAndStructure;
  const data = { ...currentData, ...eventsData };
  const structure = { ...currentStructure, ...eventsStructure };
  const sortedStructure = sortStructure(structure, data);

  return { data, structure: sortedStructure };
};

export const changeEventAndSort = (currentStructure, currentData, event) => {
  const data = { ...currentData, [event.id]: { ...currentData[event.id], ...event } };
  const structure = sortStructure({ ...currentStructure }, data);
  return { data, structure };
};

export const removeEvent = (currentStructure, currentData, event) => {
  const data = { ...currentData };
  const { id, date, location } = event;
  delete data[id];
  const newStructure = { ...currentStructure };
  newStructure[date] = {
    ...newStructure[date],
    [location.id]: newStructure[date][location.id].filter(itemId => itemId !== id),
  };
  return { data, structure: newStructure };
};
