import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmployeeGroupingEnum } from '@/types/employeeGrouping';

import { EmployeeGroupingStoreState } from './employeeGrouping.types';

const initialState: EmployeeGroupingStoreState = {
  selectedGroups: [EmployeeGroupingEnum.NONE],
};

const sliceName = 'employeeGrouping';

const employeeGroupingFilterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedEmployeeGroups: (state, action: PayloadAction<EmployeeGroupingEnum>) => {
      state.selectedGroups = [action.payload];
    },
  },
});

export const { setSelectedEmployeeGroups } = employeeGroupingFilterSlice.actions;

export const employeeGroupingReducer = employeeGroupingFilterSlice.reducer;
