import { connect } from 'react-redux';

import { handleDrop } from '@/actions/schedule/dragAndDrop';

import Component from './ScheduleTableDropArea.jsx';

const mapStateToProps = (state, ownProps) => ({
  blocked: state.reducer.schedule.dragAndDrop.blockedCells?.[ownProps.employeeId]?.[ownProps.date],
  isSwapping: state.reducer.schedule.dragAndDrop.isSwapping,
  activeDrag: !!state.reducer.schedule.dragAndDrop.itemType,
});

const mapDispatchToProps = { handleDrop };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
