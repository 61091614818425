import { FormattedMessage } from 'react-intl';

import PresentationWrapper from '@/components/common/PresentationWrapper.jsx';
import PublishButton from '@/components/common/PublishButton.jsx';
import HelpModal from '@/containers/HelpModalContainer.js';

const AttendanceHelpModal = () => (
  <HelpModal title={<FormattedMessage id="common.attendance" defaultMessage="Lista Obecności" />}>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="common.keyboardShortcuts" defaultMessage="Skróty klawiszowe" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowRight" defaultMessage="Strzałka w prawo" />
        </strong>
      </td>
      <td>
        <FormattedMessage id="help.goToNextDay" defaultMessage="Przejdź do następnego dnia" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.arrowLeft" defaultMessage="Strzałka w lewo" />
        </strong>
      </td>
      <td>
        <FormattedMessage id="help.goToPrevDay" defaultMessage="Przejdź do poprzedniego dnia" />
      </td>
    </tr>
    <tr>
      <td>
        <strong>
          <FormattedMessage id="help.zoomKeys" defaultMessage='Ctrl + "+" / Ctrl + "-"' />
          <br />
          <FormattedMessage id="help.zoomScroll" defaultMessage="lub Ctrl + kółko od myszki" />
        </strong>
      </td>
      <td>
        <FormattedMessage
          id="help.zoomDescription"
          defaultMessage='  Dostosuj powiększenie widoku za pomocą klawisza "Ctrl" i znaków plus i minus, lub kółka od myszki. Przetestuj i
          zobacz jaka wielkość elementów jest optymalna.'
        />
      </td>
    </tr>
    <tr>
      <td colSpan="2" className="helpModal__tabTitle">
        <FormattedMessage id="help.instruction" defaultMessage="Instrukcja" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.locations" defaultMessage="Lokalizacje" />
        </span>
      </td>
      <td>
        <FormattedMessage
          id="help.attendance.locationsDesc"
          defaultMessage="Wybierz lokalizację z listy dla której chcesz wyświetlić obecności"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.jobTitles" defaultMessage="Stanowiska" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.attendance.jobTitlesDesc" defaultMessage="Filtrowanie wyświetlanych stanowisk" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.sort" defaultMessage="Sortuj" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.attendance.sortDesc" defaultMessage="Sortuj listę pracowników" />
      </td>
    </tr>
    <tr>
      <td>
        <span className="helpModal__elementText">
          <FormattedMessage id="common.returnToToday" defaultMessage="Powrót do dzisiaj" />
        </span>
      </td>
      <td>
        <FormattedMessage id="help.attendance.returnToTodayDesc" defaultMessage="Pokaż dzisiejszą listę obecności " />
      </td>
    </tr>

    <tr>
      <td>◀</td>
      <td>
        <FormattedMessage id="help.prevSpan" defaultMessage="Poprzedni okres" />
      </td>
    </tr>
    <tr>
      <td>▶</td>
      <td>
        <FormattedMessage id="help.nextSpan" defaultMessage="Następny okres" />
      </td>
    </tr>

    <tr>
      <td>
        <PresentationWrapper>
          <PublishButton
            text={<FormattedMessage id="common.publishAttendances" defaultMessage="Zatwierdź Obecności" />}
          />
        </PresentationWrapper>
      </td>
      <td>
        <FormattedMessage
          id="help.publishAttendancesDesc"
          defaultMessage='Kliknij przycisk "Zatwierdź Obecności" aby zapisać dodane obecności'
        />
      </td>
    </tr>
  </HelpModal>
);

AttendanceHelpModal.propTypes = {};

export default AttendanceHelpModal;
