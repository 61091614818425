import { PropTypes } from 'prop-types';

import TopBarMultipleColumnFilter from '@/components/TopBars/filters/TopBarMultipleColumnFilter/TopBarMultipleColumnFilter.redux';
import { typesOfMultipleLocations } from '@/constants/locations';
import { payrollModesIdsForEmployee, payrollModesIdsForManager } from '@/constants/viewDateModes';

import TopBarCalendar from './filters/TopBarCalendar/TopBarCalendar.redux.js';
import TopBarEmployeeFilter from './filters/TopBarEmployeeFilter/TopBarEmployeeFilter.redux.js';
import TopBarMultipleLocationFilter from './filters/TopBarMultipleLocationFilter/TopBarMultipleLocationFilter.redux';
import TopBar from './TopBar.jsx';

const TopBarPayroll = props => (
  <TopBar
    leftElements={
      props.isEmployee
        ? [<TopBarCalendar key="calswitch" modes={payrollModesIdsForEmployee} />]
        : [
            <TopBarCalendar key="calswitch" modes={payrollModesIdsForManager} />,
            <TopBarMultipleLocationFilter key="multipleLocationFilter" locationType={typesOfMultipleLocations.id} />,
            <TopBarEmployeeFilter key="payoutEmployee" />,
            <TopBarMultipleColumnFilter key="multipleColumnFilter" payrollType="payroll" />,
          ]
    }
    rightElements={[]}
  />
);

TopBarPayroll.propTypes = {
  isEmployee: PropTypes.bool,
};

export default TopBarPayroll;
