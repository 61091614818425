import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  editAva: {
    id: 'availabilities.modal.editAva',
    defaultMessage: 'Edytuj typ dostępności',
  },
  addAva: {
    id: 'availabilities.modal.addAva',
    defaultMessage: 'Dodaj typ dostępności',
  },
  avaTypeName: {
    id: 'availabilities.modal.avaTypeName',
    defaultMessage: 'Nazwa',
  },
  shortName: {
    id: 'availabilities.modal.shortName',
    defaultMessage: 'Skrócona nazwa',
  },
  color: {
    id: 'availabilities.modal.color',
    defaultMessage: 'Kolor',
  },
  count_hours: {
    id: 'availabilities.modal.count_hours',
    defaultMessage: 'Dolicz godziny',
  },
});

export const optionsMessages = defineMessages({
  isNotAvailable: {
    id: 'availabilities.modal.isNotAvailable',
    defaultMessage: 'Niedostępny',
  },
  requiresTime: {
    id: 'availabilities.modal.requiresTime',
    defaultMessage: 'Wymaga podania godzin',
  },
  requiresApproval: {
    id: 'availabilities.modal.requiresApproval',
    defaultMessage: 'Wymaga potwierdzenia',
  },
  optionalComment: {
    id: 'availabilities.modal.optionalComment',
    defaultMessage: 'Komentarze',
  },
  visibleForEmployess: {
    id: 'availabilities.modal.visibleForEmployess',
    defaultMessage: 'Zezwalaj na dodawanie przez pracownika',
  },
  countHoursInPayroll: {
    id: 'availabilities.modal.countHoursInPayroll',
    defaultMessage: 'Wlicz do wypłat',
  },
});
