import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { Grid, Header, Rows } from '@/components/common/Grid/Grid.jsx';
import { EDIT_BILLING_DATA_MODAL } from '@/constants/modalTypes.js';

import EditBillingDataModal from './EditBillingDataModal/EditBillingDataModal.redux.js';
import { headers, planMessages, statusMessages } from './PaymentsView.helpers';

class PaymentsView extends Component {
  getInvoicePdf(invoiceId) {
    this.props.getInvoicePdf(invoiceId).then(result => {
      const linkSource = `data:application/pdf;base64,${result.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `faktura_${invoiceId}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    });
  }

  getOptionsForInvoice(invoice) {
    const { status } = invoice;
    switch (status) {
      case 'issued':
        return (
          <span>
            <span
              role="presentation"
              className="k-tableOption k-tableOption--orange"
              onClick={() => {
                this.startPayment(invoice.id);
              }}
            >
              <FormattedMessage id="payments.pay" defaultMessage="Opłać" />
            </span>
            <span
              role="presentation"
              className="k-tableOption k-tableOption--blue"
              onClick={() => {
                this.getInvoicePdf(invoice.id);
              }}
            >
              <FormattedMessage id="payments.download" defaultMessage="Pobierz" />
            </span>
          </span>
        );
      case 'pending':
      case 'paid':
        return (
          <span>
            <span
              className="k-tableOption k-tableOption--blue"
              onClick={() => {
                this.getInvoicePdf(invoice.id);
              }}
              role="presentation"
            >
              <FormattedMessage id="payments.download" defaultMessage="Pobierz" />
            </span>
          </span>
        );
      default:
        return <FormattedMessage id="payments.unknownPaymentStatus" defaultMessage="Nieznany status płatności" />;
    }
  }

  startPayment(invoiceId) {
    this.props.payForInvoice(invoiceId).then(data => {
      window.location.href = data.redirect_link;
    });
  }

  render() {
    const { payments, showModal } = this.props;
    const { intl } = this.context;
    const { client } = payments;
    if (!client) return null;
    const { invoices, enable_payu: enablePayU } = client;
    const planName = client && client.plan ? intl.formatMessage(planMessages[client.plan]) : null;

    const paymentsModals = <EditBillingDataModal />;

    return (
      <div className="k-paymentsView">
        {paymentsModals}
        <div className="k-paymentsViewDetails">
          <div className="k-paymentsViewDetails__accent" />
          <div className="k-paymentsViewDetails__planTile">
            <p className="k-paymentsViewDetails__sectionTitle">
              <FormattedMessage id="payments.chosenPlan" defaultMessage="Wybrany plan" />
            </p>
            <i className="material-icons k-paymentsViewDetails__planIcon">today</i>
            <h2 className="k-paymentsViewDetails__planTitle">{planName}</h2>
            <p className="k-paymentsViewDetails__info">
              {client.payment_type === 'flat_fee' ? (
                <FormattedMessage
                  id="payments.monthlyPayment"
                  defaultMessage="Płatność miesięczna: {amount} zł"
                  values={{ amount: (client.payment_amount / 100).toFixed(2) }}
                />
              ) : (
                <FormattedMessage
                  id="payments.monthlyPaymentPerUser"
                  defaultMessage="Płatność miesięczna: {amount} zł netto / użytkownik"
                  values={{ amount: (client.payment_amount / 100).toFixed(2) }}
                />
              )}
            </p>
            <p className="k-paymentsViewDetails__info">
              <FormattedMessage
                id="payments.paidToDate"
                defaultMessage="Opłacono do {date}"
                values={{ date: moment(client.paid_to_date).format('DD.MM.YYYYr.') }}
              />
            </p>
          </div>
          <div className="k-paymentsViewDetails__infoTile">
            <p className="k-paymentsViewDetails__sectionTitle">
              <FormattedMessage id="payments.paymentData" defaultMessage="Dane do faktury" />
            </p>
            <p className="k-paymentsViewDetails__invoiceData">
              <span className="k-paymentsViewDetails__invoiceData--bold">
                <FormattedMessage id="payments.payerName" defaultMessage="Nazwa podmiotu:" />
              </span>{' '}
              {client.company_name}
            </p>
            <p className="k-paymentsViewDetails__invoiceData">
              <span className="k-paymentsViewDetails__invoiceData--bold">
                <FormattedMessage id="payments.taxNo" defaultMessage="NIP:" />
              </span>{' '}
              {client.nip_number}
            </p>
            <p className="k-paymentsViewDetails__invoiceData">
              <span className="k-paymentsViewDetails__invoiceData--bold">
                <FormattedMessage id="payments.address" defaultMessage="Adres:" />
              </span>{' '}
              {client.address} {client.city} {client.postal_code}
            </p>
            <p className="k-paymentsViewDetails__invoiceData">
              <span className="k-paymentsViewDetails__invoiceData--bold">
                <FormattedMessage id="payments.email" defaultMessage="E-mail:" />
              </span>{' '}
              {client.email}
            </p>
            {enablePayU && (
              <Button
                className="k-paymentsViewDetails__editDataButton"
                modifiers="orange small"
                onClick={() => showModal(EDIT_BILLING_DATA_MODAL)}
              >
                <FormattedMessage id="payments.editInvoiceData" defaultMessage="Edytuj dane do rozliczeń" />
              </Button>
            )}
          </div>
        </div>
        <div className="k-paymentsViewInvoices">
          <Grid width={900} height={600} rowHeight={30}>
            <Header>
              <thead>
                <tr>
                  {headers.map(header => (
                    <th>{intl.formatMessage(header)}</th>
                  ))}
                </tr>
              </thead>
            </Header>
            <Rows>
              {(invoices || [])
                .sort((a, b) => (a.issue_date < b.issue_date ? 1 : -1))
                .map(invoice => (
                  <tr>
                    <td>{moment(invoice.issue_date).format('DD.MM.YYYYr.')}</td>
                    <td>{moment(invoice.payment_date).format('DD.MM.YYYYr.')}</td>
                    <td>{`${(invoice.amount / 100).toFixed(2)} zł`}</td>
                    <td>{intl.formatMessage(statusMessages[invoice.status])}</td>
                    <td>{this.getOptionsForInvoice(invoice)}</td>
                  </tr>
                ))}
            </Rows>
          </Grid>
        </div>
      </div>
    );
  }
}

PaymentsView.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

PaymentsView.propTypes = {
  payForInvoice: PropTypes.func,
  getInvoicePdf: PropTypes.func,
  payments: PropTypes.shape({
    client: PropTypes.shape({
      payment_delay: PropTypes.number,
      email: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      postal_code: PropTypes.string,
      nip_number: PropTypes.string,
      company_name: PropTypes.string,
      paid_to_date: PropTypes.string,
      payment_name: PropTypes.string,
      payment_amount: PropTypes.number,
      payment_type: PropTypes.string,
      enable_payu: PropTypes.bool,
      plan: PropTypes.string,
      invoices: PropTypes.arrayOf(
        PropTypes.shape({
          issue_date: PropTypes.string,
          created_at: PropTypes.string,
          amount: PropTypes.number,
          status: PropTypes.string,
        }),
      ),
    }),
  }),
  showModal: PropTypes.func,
};

export default PaymentsView;
