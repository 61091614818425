import classNames from 'classnames';

import { AddFreeDayModalObject } from '@/components/scheduler/modals/AddShiftAndAbsenceModal/Tabs/AddFreeDayTab/AddFreeDayTab.types';
import { FreeDayItem } from '@/redux-store/freeDaysMarking';
import { EmployeeWhole } from '@/types';
import { getBem } from '@/utils/styles';

const bem = getBem('k-scheduleFreeDayBlock');

type ClassesOptions = {
  isQuickPlanningEnabled: boolean;
  isMonthlyView: boolean;
};

export const getClasses = ({ isQuickPlanningEnabled, isMonthlyView }: ClassesOptions) => {
  const isEditable = !isQuickPlanningEnabled;

  return {
    container: classNames(bem.block, isEditable && bem.modifier('editable'), isMonthlyView && bem.modifier('month')),
    iconsContainer: bem.element('iconsContainer'),
    iconButton: classNames('material-icons', bem.element('iconButton')),
    iconDeleteButton: classNames(
      'material-icons',
      bem.element('iconButton'),
      bem.elementModifier('iconButton', 'delete'),
    ),
    shortcut: bem.element('shortcut'),
  } as const;
};

export const getModalObject = (freeDay: FreeDayItem, employee: EmployeeWhole): AddFreeDayModalObject => ({
  date: freeDay.date,
  employee,
  freeDayItemId: freeDay.id,
  selectedFreeDayId: freeDay.markingId,
});
