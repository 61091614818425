import { useReducer } from 'react';

import { validateInput as validateInputHelper } from '@/utils/inputHelpers';

const useInputChange = (initialState, intl, settings = {}) => {
  const actions = {
    CHANGE_INPUT: 'CHANGE_INPUT',
    SET_INPUT_ERROR: 'SET_INPUT_ERROR',
    SET_INIT_STATE: 'SET_INIT_STATE',
    UPDATE_STATE: 'UPDATE_STATE',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actions.CHANGE_INPUT: {
        const newErrors = settings.preventClearErrors ? state.errors : {};
        return { ...state, errors: newErrors, [action.payload.name]: action.payload.value };
      }
      case actions.SET_INPUT_ERROR:
        return { ...state, errors: { ...state.errors, [action.payload.name]: action.payload.errorValue } };
      case actions.SET_INIT_STATE:
        return { ...initialState, errors: {} };
      case actions.UPDATE_STATE:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const validateInput = async event => {
    const [name, error] = await validateInputHelper(event, event.valueToCompare);
    const errorValue = error && intl.formatMessage(error);
    dispatch({ type: actions.SET_INPUT_ERROR, payload: { name, errorValue } });
    return errorValue;
  };

  const changeInput = event => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    dispatch({ type: actions.CHANGE_INPUT, payload: { name, value: newValue } });
    validateInput(event);
  };

  const setInitState = () => {
    dispatch({ type: actions.SET_INIT_STATE });
  };

  const setError = (name, error, values = {}) => {
    const errorValue = error && intl.formatMessage(error, values);
    dispatch({ type: actions.SET_INPUT_ERROR, payload: { name, errorValue } });
  };

  const updateState = newState => {
    dispatch({ type: actions.UPDATE_STATE, payload: newState });
  };

  return [state, { validateInput, changeInput, setInitState, setError, updateState }];
};

export default useInputChange;
