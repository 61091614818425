import { connect } from 'react-redux';

import { setEmployeesVisibleColumns, showModal } from '@/actions/uiState.js';

import EmployeesButtonBar from './EmployeesButtonBar.jsx';

const mapStateToProps = state => ({
  visibleColumns: state.reducer.uiState.employeesVisibleColumns,
  usersLimitReached: state.reducer.companyLimitsUsage.usersLimitReached,
  currentRoleId: state.reducer.currentUser.user.role_id,
  checkedPermissions: state.reducer.roles.checkedPermissions,
  userPermissions: state.reducer.userPermissions.permissions,
});

const mapDispatchToProps = {
  showModal,
  setEmployeesVisibleColumns,
};

const EmployeesButtonBarContainer = connect(mapStateToProps, mapDispatchToProps)(EmployeesButtonBar);

export default EmployeesButtonBarContainer;
