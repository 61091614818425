import PropTypes from 'prop-types';

import { AVAILABILITIES_DISPLAY_MODE_TYPE, SCHEDULE_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';
import { viewDateModes } from '@/constants/viewDateModes';
import { SCHEDULE_PATH } from '@/utils/routes';

import ScheduleAdditonalModes from './scheduler/ScheduleAdditionalModes/ScheduleAdditonalModes.redux';
import ScheduleBasicModeView from './scheduler/ScheduleMode/ScheduleModeView.redux';

const ScheduleView = props => {
  const relevantAmountOfDays = props.amountOfDays - 1;
  if (relevantAmountOfDays > viewDateModes[SCHEDULE_PATH].maxDayDiff) return null;
  if (
    props.selectedDisplayModeType === SCHEDULE_DISPLAY_MODE_TYPE ||
    props.selectedDisplayModeType === AVAILABILITIES_DISPLAY_MODE_TYPE
  ) {
    return <ScheduleBasicModeView />;
  }
  return <ScheduleAdditonalModes />;
};

ScheduleView.propTypes = {
  amountOfDays: PropTypes.number,
  selectedDisplayModeType: PropTypes.string,
};

export default ScheduleView;
