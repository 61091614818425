import RolesDeleteModal from './modals/RolesDeleteModal/RolesDeleteModal.redux';
import RolesModal from './modals/RolesModal/RolesModal.redux';
import RolesButtonBar from './RolesButtonsBar/RolesButtonsBar.redux';
import RolesTable from './RolesTable/RolesTable.redux';

const RolesView = () => (
  <div className="k-wrapper k-wrapper--rightScroll animated fadeInRight">
    <RolesModal />
    <RolesDeleteModal />
    <RolesButtonBar />
    <RolesTable />
  </div>
);

export default RolesView;
