import { combineReducers } from 'redux';

import * as AT from '@/constants/ActionTypes.js';

const scheduleChartsMaxValues = (state = {}, action) => {
  switch (action.type) {
    case AT.CHANGE_SCHEDULE_CHARTS_MAX_VALUES:
      return {
        ...state,
        [action.locationId]: {
          ...state[action.locationId],
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const scheduleChartsData = (state = {}, action) => {
  switch (action.type) {
    case AT.CHANGE_SCHEDULE_CHARTS_DATA: {
      return {
        ...state,
        [action.locationId]: action.payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  scheduleChartsData,
  scheduleChartsMaxValues,
});
