import { connect } from 'react-redux';

import { addNewTemplate } from '@/actions/templates.js';
import { hideModal, showModal } from '@/actions/uiState.js';
import { ADD_TEMPLATE_FROM_EXISTING_MODAL } from '@/constants/modalTypes.js';

import AddTemplateFromExistingModal from './AddTemplateFromExistingModal.jsx';

const mapStateToProps = state => ({
  isVisibleModal: state.reducer.uiState.showModal === ADD_TEMPLATE_FROM_EXISTING_MODAL,
  templatesArray: state.reducer.userTemplates.templatesArray,
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  addNewTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateFromExistingModal);
