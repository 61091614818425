import PropTypes from 'prop-types';

import { ContextWithIntl } from '@/types/intl.types.ts';

import { confirmMessages } from './EmployeeMassEditConfirmStep.messages.ts';

import './employeesMassEditConfirmStep.scss';

const EmployeeMassEditConfirmStep = (_, { intl }: ContextWithIntl) => (
  <div className="employeesMassEditConfirmStep__container">
    <span>{intl.formatMessage(confirmMessages.deleteContractsDescription)}</span>
    <span>{intl.formatMessage(confirmMessages.deleteContractsConfirmText)}</span>
  </div>
);

EmployeeMassEditConfirmStep.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

export default EmployeeMassEditConfirmStep;
