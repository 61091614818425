import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { ORANGE, REVERSE_ORANGE } from '@/constants/buttonModifiers';
import { ABSENCE_TYPE_MODAL } from '@/constants/modalTypes.js';

import './AbsenceTypesOptions.scss';

const AbsenceTypesOptions = ({ deleteAbsenceTypes, showModal, selectedData }) => {
  const [loading, setLoading] = useState(false);

  const handleRemoveAbsenceTypes = async () => {
    setLoading(true);
    await deleteAbsenceTypes(selectedData.filter(data => !data.is_default_uw_absence));
    setLoading(false);
  };
  return (
    <div className="absenceTypesOptions">
      <Button modifiers={ORANGE} onClick={() => showModal(ABSENCE_TYPE_MODAL)}>
        <span className="material-icons">add</span>
        <FormattedMessage id="absenceTypeOptions.add.button" defaultMessage="Dodaj typ urlopu" />
      </Button>
      <Button
        modifiers={REVERSE_ORANGE}
        onClick={handleRemoveAbsenceTypes}
        disabled={!selectedData.some(data => !data.is_default_uw_absence) || loading}
      >
        <span className="material-icons">remove</span>
        <FormattedMessage id="absenceTypeOptions.remove.button" defaultMessage="Usuń zaznaczone" />
      </Button>
    </div>
  );
};

AbsenceTypesOptions.propTypes = {
  showModal: PropTypes.func,
  selectedData: PropTypes.arrayOf(PropTypes.shape({})),
  deleteAbsenceTypes: PropTypes.func,
};

export default AbsenceTypesOptions;
