import classnames from 'classnames';
import moment from 'moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AbsenceHistoryModal from '@/components/absences/modals/AbsenceHistoryModal/AbsenceHistoryModal.redux.js';
import AbsenceProposalModal from '@/components/absences/modals/AbsenceProposalModal/AbsenceProposalModal.redux.js';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import EmptyState from '@/components/emptyState/EmptyState.jsx';
import { sortByDateAndAbsenceHours } from '@/utils/dateHelper.js';

import AbsencesProposalItem from '../AbsencesProposalItem/AbsencesProposalItem.redux.js';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import './AbsencesProposals.scss';

function AbsencesProposals({ absences }) {
  const today = moment().format('YYYY-MM-DD');
  const endOfPeriod = moment()
    .add(1, 'month')
    .format('YYYY-MM-DD');
  const relevantAbsences = absences.filter(
    absence => absence.status === 'draft' && absence.to >= today && absence.from <= endOfPeriod,
  );

  const absenceProposalContainerClassname = classnames('md-dashboardContainer', {
    'absencesProposals__container--empty': !relevantAbsences.length,
  });

  return (
    <>
      <AbsenceProposalModal />
      <AbsenceHistoryModal />
      <div className={absenceProposalContainerClassname}>
        <div className="md-dashboardContainer__title">
          <Icon className="md-dashboardContainer__icon" type="material-symbols" name="approval" />
          <FormattedMessage id="dashboard.absencesProposals.header" defaultMessage="Wnioski do rozpatrzenia" />
        </div>
        {relevantAbsences.length > 0 ? (
          <OverlayScrollbarsComponent className="k-overlay-scrollbar md-dashboardContainer__list">
            {sortByDateAndAbsenceHours(relevantAbsences).map(absence => (
              <AbsencesProposalItem key={absence.id} absence={absence} employeeId={absence.employee_id} />
            ))}
          </OverlayScrollbarsComponent>
        ) : (
          <EmptyState
            name="employeeCTA"
            className="md-dashboardTeam__emptyState"
            imgSrc="/img/proposalsCTA.png"
            emptyStateHeader=""
            emptyStateText={
              <FormattedMessage
                id="dashboard.absencesProposals.noAbsences"
                defaultMessage="Brak wniosków do rozpatrzenia"
              />
            }
            ctaText=""
          />
        )}
      </div>
    </>
  );
}

AbsencesProposals.propTypes = {
  absences: PropTypes.arrayOf(
    PropTypes.shape({
      employee_id: PropTypes.string,
      status: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      absence_hours: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
    }),
  ),
};

export default AbsencesProposals;
