import classNames from 'classnames';
import PropTypes from 'prop-types';

import Select from '@/components/common/inputs/MDSelect/Select';

const SelectBlock = props => {
  const className = classNames({ labelTitle: props.label });
  return (
    <div className="settingsRow">
      <label>
        <div className={className}>{props.name}</div>
        {props.label ? <div className="labelDesc">{props.label}</div> : null}
      </label>
      <div style={{ float: 'right', clear: 'right', width: '300px' }}>
        <Select
          onChange={props.handleInput}
          defaultValue={props.value}
          options={props.options.map(l => ({ value: l.id, key: l.name }))}
          closeOnClick
        />
      </div>
    </div>
  );
};

SelectBlock.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  handleInput: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SelectBlock;
