// import GroupAddIcon from '@material-ui/icons/GroupAdd';
import classNames from 'classnames';
import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import { PropTypes } from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import Icon from '@/components/common/Basic/Icon/Icon.jsx';
import SortingArrow from '@/components/common/Basic/SortingArrow';
import { FreemiumWrapper } from '@/components/common/Freemium';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux.js';
import TopBarMultipleChoice from '@/components/TopBars/common/TopBarMultipleChoice/TopBarMultipleChoice.jsx';
import { FREEMIUM_EVENT_ADD_MORE_EMPLOYEES } from '@/constants/intercom.js';
import { EMPLOYEE_MODAL, MASS_EMPLOYEES_ADD_MODAL } from '@/constants/modalTypes.js';
import { HIRE_RELEASE_DATE_SHOW, VALIDATE_REF_ID } from '@/constants/Permissions';
import { employeesTable } from '@/constants/tableColumns.jsx';
import { useSimplePopover } from '@/hooks/useSimplePopover/useSimplePopover';

import AddMassEmployeesModal from '../EmployeeModal/MassEmployeeModalAdditional/AddMassEmployeesModal.redux';
import EmployeesActionsButton from './EmployeesActionsButton.redux';
import { filterColumns } from './EmployeesButtonBar.helpers';

import './employeesButtonBar.scss';

const EmployeesButtonBar = ({
  showModal,
  inactive,
  relevantSelectedEmployeesIds,
  setEmployeesVisibleColumns,
  visibleColumns,
  usersLimitReached,
  relevantEmployees,
  currentRoleId,
  checkedPermissions,
  userPermissions,
}) => {
  const addEmployee = useCallback(() => {
    showModal(EMPLOYEE_MODAL, { showSendInvitation: true });
  }, [showModal]);
  const addMassEmployee = useCallback(() => {
    showModal(MASS_EMPLOYEES_ADD_MODAL);
  }, [showModal]);

  const [dropdownRef, arrowButtonRef, showOptions] = useSimplePopover();

  const arrowButtonClassName = classNames('qa-employeesButtonBar__arrow', {
    'qa-employeesButtonBar__arrow--active': showOptions,
  });
  const parsedCurrentRoleId = currentRoleId === 'owner' ? 'manager' : currentRoleId;

  const isBudgetInfoPermission = checkedPermissions[parsedCurrentRoleId]?.find(({ id }) => id === 'BUDGET_INFO:SHOW');

  const isHireReleaseDataShowPermission = userPermissions.includes(HIRE_RELEASE_DATE_SHOW);

  const { filteredVisibleColumns, filteredEmployeesTableHeader } = filterColumns(
    visibleColumns,
    employeesTable,
    isBudgetInfoPermission,
    isHireReleaseDataShowPermission,
  );

  const isRefIdPermission = userPermissions.includes(VALIDATE_REF_ID);

  const listBtnClassName = classNames('k-list__button', { 'k-list__button--disabled': isRefIdPermission });

  useEffect(() => {
    if (!isBudgetInfoPermission || !isHireReleaseDataShowPermission) {
      setEmployeesVisibleColumns(filteredVisibleColumns);
    }
  }, []);

  return (
    <div className="k-employeesButtonBar">
      <ButtonBar>
        <div className="k-buttonBar__containerRight">
          <EmployeesActionsButton
            inactive={inactive}
            relevantSelectedEmployeesIds={relevantSelectedEmployeesIds}
            relevantEmployees={relevantEmployees}
          />
          <TopBarMultipleChoice
            objectsToChoose={useMemo(() => filteredEmployeesTableHeader.filter((header) => !header.cannotBeHidden))}
            selectedObjects={filteredVisibleColumns}
            onChange={setEmployeesVisibleColumns}
            icon={<Icon type="material-outlined" name="view_column" />}
            displayKey="defaultMessage"
            onlyIcon
            className="k-topBarChoice--small k-topBarChoice--pinned-right"
          />
        </div>
        <div className="k-buttonBar__containerLeft">
          <div className="k-buttonBar--pushLeft">
            <FeatureWrapper restriction={RESTRICTIONS.COMPANY_MANAGE_EMPLOYEES_HIDE_ADD}>
              <FreemiumWrapper show={usersLimitReached} intercomEvent={FREEMIUM_EVENT_ADD_MORE_EMPLOYEES}>
                <div className="qa-employeesButtonBar__addWrapper">
                  <Button
                    className="qa-employeesButtonBar__add"
                    onClick={addEmployee}
                    modifiers="blue medium inline spaceBetween"
                    testId="showAddEmployeeModal"
                  >
                    <FormattedMessage
                      id="companyManage.employeesList.addSingleEmployee"
                      defaultMessage="Dodaj pracownika"
                    />
                  </Button>
                  <button
                    ref={arrowButtonRef}
                    className={arrowButtonClassName}
                    modifiers="blue medium inline spaceBetween"
                  >
                    <SortingArrow display />
                  </button>
                </div>
                {showOptions && (
                  <div ref={dropdownRef} className="k-list">
                    <button className="k-list__button k-list__button--first" onClick={addEmployee}>
                      <Icon className="k-list__icon" name="person_add" />
                      <FormattedMessage
                        id="companyManage.employeesList.addSingleEmployee"
                        defaultMessage="Dodaj pracownika"
                      />
                    </button>

                    <button className={listBtnClassName} onClick={addMassEmployee} disabled={isRefIdPermission}>
                      <Icon className="k-list__icon" name="group_add" />
                      <FormattedMessage
                        id="companyManage.employeesList.addMassEmployee"
                        defaultMessage="Dodaj wielu pracowników"
                      />
                    </button>
                  </div>
                )}
              </FreemiumWrapper>
            </FeatureWrapper>
          </div>
        </div>
        <AddMassEmployeesModal />
      </ButtonBar>
    </div>
  );
};

EmployeesButtonBar.propTypes = {
  showModal: PropTypes.func,
  employeesListUi: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string),
  }),
  inactive: PropTypes.bool,
  relevantSelectedEmployeesIds: PropTypes.arrayOf(PropTypes.string),
  setEmployeesVisibleColumns: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  usersLimitReached: PropTypes.bool,
  relevantEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  checkedPermissions: PropTypes.shape({}),
  currentRoleId: PropTypes.string,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
};

export default EmployeesButtonBar;
