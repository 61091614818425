export enum DateModeEnum {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export type ViewDateModes = Record<string, { allowed: DateModeEnum[]; default: DateModeEnum; maxDayDiff?: number }>;

export type MainDateStore = {
  customDate: {
    start: string;
    end: string;
  };
  dateArray: string[];
  dateMode: DateModeEnum;
  dateShiftInt: number;
  dateText: string;
  headerDateArray: string[];
  previousDateArray: MainDateStore['dateArray'];
  templatesDateArray: number[];
  today: string;
};
