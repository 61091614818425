import * as actionTypes from '@/constants/ActionTypes.js';
import { LoanedEmployees } from '@/types/scheduleLoanedEmployees';

export const getScheduleLoanedEmployees = (loanedEmployees: LoanedEmployees) => ({
  type: actionTypes.GET_LOANED_EMPLOYEES,
  payload: loanedEmployees,
});

export const addLoanedEmployees = data => ({
  type: actionTypes.ADD_LOANED_EMPLOYEES,
  payload: data,
});
