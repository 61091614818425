import classNames from 'classnames';
import { deburr, sortBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import MultiSelect from '@/components/common/inputs/MDMultiSelect/MultiSelect';
import TextInput from '@/components/common/inputs/TextInput';
import { TABLE_SKIP_INDEX } from '@/constants/tabIndex';

import { messages } from '../EmployeeModalContracts.messages';

import 'overlayscrollbars/styles/overlayscrollbars.css';
import '../../EmployeeModalPropList.scss';

const EmployeeModalContractJobTitles = (
  {
    contractJobTitles,
    allJobTitles,
    updateContractJobTitles,
    hasAccessToWage,
    disabled,
    disableWagesChange = false,
    disableJobTitleDelete,
    selectedContractId,
  },
  { intl },
) => {
  const isEditingEnabled = !!updateContractJobTitles;
  const [customWages, setCustomWages] = useState({});

  useEffect(() => {
    setCustomWages({});
  }, [selectedContractId]);

  const onChangeJobTitles = e => {
    if (!e.active) {
      addJobTitle(e);
    } else {
      deleteJobTitle(e.value);
    }
  };

  const addJobTitle = e => {
    const newJobTitle = allJobTitles.find(term => term.id === e.value);
    const newJobTitles = [
      ...contractJobTitles,
      {
        job_title_id: newJobTitle.id,
      },
    ];
    updateContractJobTitles(newJobTitles);
  };

  const deleteJobTitle = id => {
    const updatedSelectedJobTitles = contractJobTitles.filter(contractJobTitle => contractJobTitle.job_title_id !== id);
    updateContractJobTitles(updatedSelectedJobTitles);
  };

  const deleteAll = () => {
    updateContractJobTitles([]);
  };

  const addAll = () => {
    const newContractJobTitles = allJobTitles.map(jobTitle => ({
      job_title_id: jobTitle.id,
    }));
    updateContractJobTitles(newContractJobTitles);
  };

  const changeWage = e => {
    const { value, id } = e.target;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value)) return;

    setCustomWages({ ...customWages, [id]: value });
  };

  const wageBlur = e => {
    const { value, id } = e.target;
    const regex = /^\d*\.?\d{0,2}$/;
    if (!regex.test(value) || value === '') {
      const newCustomWages = { ...customWages, [id]: '0.00' };
      setCustomWages(newCustomWages);
      return;
    }

    const newJobTitles = contractJobTitles.map(contractJobTitle => {
      if (contractJobTitle.job_title_id === e.target.id) {
        const hourlyWage = Number(value) * 100;
        return { ...contractJobTitle, wage: hourlyWage };
      }
      return contractJobTitle;
    });

    updateContractJobTitles(newJobTitles);
  };

  const mappedJobTitles = allJobTitles.map(jobTitle => ({
    value: jobTitle.id,
    label: jobTitle.title,
    active: contractJobTitles.some(contractJobTitle => jobTitle.id === contractJobTitle.job_title_id),
  }));
  const sortedJobTitles = sortBy([...mappedJobTitles], term => deburr(term.label.toLowerCase()));
  const mappedSelectedJobTitles = contractJobTitles.map(contractJobTitle => {
    const relevantJobTitle = allJobTitles.find(jobTitle => jobTitle.id === contractJobTitle.job_title_id);
    const isWageDefinedInContract = contractJobTitle.wage || contractJobTitle.wage === 0;
    const hourlyWage = isWageDefinedInContract ? contractJobTitle.wage : relevantJobTitle.hourly_wage;
    return {
      ...relevantJobTitle,
      isCustom: isWageDefinedInContract,
      hourly_wage: hourlyWage,
      displayValue: (hourlyWage / 100).toFixed(2),
    };
  });
  const sortedSelectedJobTitles = sortBy([...mappedSelectedJobTitles], term => deburr(term.title.toLowerCase()));

  const employeeModalPropListClassName = classNames('employeeModal__propList', {
    'employeeModal__propList--disabled': disabled,
  });

  const termInputClassName = classNames('employeeModal__input__term', {
    'employeeModal__input__term--smaller': intl.locale === 'en',
  });

  return (
    <div className={employeeModalPropListClassName}>
      {isEditingEnabled && (
        <>
          <div className="multiSelect__header">
            <div className="multiSelect__header__text">
              <span className="material-icons">work_outline</span>
              <span>{intl.formatMessage(messages.jobTitlesHeader)}</span>
            </div>
            <div className="multiSelect__header__buttons">
              <button data-test="addAllJobTitles" onClick={addAll} disabled={disabled}>
                <FormattedMessage id="companymanage.employees.addAllJobTitles" defaultMessage="Dodaj wszystkie" />
              </button>
              <button data-test="deleteAllJobTitles" onClick={deleteAll} disabled={disabled}>
                <FormattedMessage id="companymanage.employees.deleteAllJobTitles" defaultMessage="Usuń wszystkie" />
              </button>
            </div>
          </div>
          <MultiSelect
            label={intl.formatMessage(messages.chooseJobTitle)}
            options={sortedJobTitles}
            onChange={onChangeJobTitles}
            testId="jobTitlesSelect"
            disabled={disabled}
          />
        </>
      )}
      <div className="selectedOptions" data-test="jobTitlesSelect-selectedOptions" disabled={disabled}>
        <OverlayScrollbarsComponent className="overlay-scrollbar" style={{ width: '100%', height: '17rem' }}>
          {sortedSelectedJobTitles.map(term => (
            <div key={term.id} className="selectedOptions__item">
              <div className="selectedOptions__item__text">
                <span style={{ backgroundColor: term.color }} className="selectedOptions__item__text__color" />
                <span className="employeeModalContract__jobTitleName">{term.title}</span>
              </div>
              {hasAccessToWage && (
                <div className="selectedOptions__item__actions">
                  <div
                    className={classNames('employeeModal__input', {
                      'employeeModal__input--custom': term.isCustom,
                    })}
                  >
                    <TextInput
                      type="number"
                      value={
                        customWages[term.id] || customWages[term.id] === '' ? customWages[term.id] : term.displayValue
                      }
                      id={term.id}
                      onChange={changeWage}
                      onBlur={wageBlur}
                      className={termInputClassName}
                      testId={`wage-${term.title}`}
                      disabled={disableWagesChange}
                    />
                    <span className="employeeModal__input__text">{intl.formatMessage(messages.plnPerHour)}</span>
                  </div>
                  {!disableJobTitleDelete && (
                    <button
                      data-test={`deleteTerm-${term.title}`}
                      tabIndex={TABLE_SKIP_INDEX}
                      onClick={() => deleteJobTitle(term.id)}
                    >
                      <span className="material-icons">clear</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </OverlayScrollbarsComponent>
      </div>
    </div>
  );
};

EmployeeModalContractJobTitles.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

EmployeeModalContractJobTitles.propTypes = {
  contractJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  allJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
  hasAccessToWage: PropTypes.bool,
  updateContractJobTitles: PropTypes.func,
  disableWagesChange: PropTypes.bool,
  disableJobTitleDelete: PropTypes.bool,
  selectedContractId: PropTypes.string,
};

export default EmployeeModalContractJobTitles;
