import { getNewDataForDate } from '@/actions';
import { getMonthlyNorms } from '@/actions/calendarData.js';
import { deselectAllCheckboxes } from '@/actions/companymanage';
import { checkIfCanPaste } from '@/actions/copyPaste.js';
import { hideRedundantOvertimeStats } from '@/actions/overtime';
import { checkIfCanDelete,moveSelectedColumns } from '@/actions/schedule.jsx';
import {
  MAIN_DATE_CHANGE_CUSTOM_DATE,
  MAIN_DATE_CHANGE_MODE,
  MAIN_DATE_CHANGE_MODE_AND_DATE,
  MAIN_DATE_MOVE_LEFT,
  MAIN_DATE_MOVE_RIGHT,
  MAIN_DATE_MOVE_TO_DATE,
} from '@/constants/ActionTypes.js';
import {
  generateFromToWithShiftInt,
  getDateShiftInt,
  getFromToFromDateStore,
  isCustomDateMode,
} from '@/utils/dateHelper.js';

/**
 * Middleware responsible for updating the backend data based on the actions
 * performed on frontend f.e. dates / locations etc.
 */
const backendDataAndStateUpdateMiddleware = store => next => action => {
  switch (action.type) {
    case MAIN_DATE_CHANGE_MODE: {
      if (action.preventUpdate || action.payload === 'custom') {
        return next(action);
      }

      const { dateArray, dateMode } = store.getState().reducer.mainDateStore;
      const dates = generateFromToWithShiftInt(action.payload, 0);

      if (
        (dateMode === action.payload || dateMode === 'custom') &&
        dates.from === dateArray[0] &&
        dates.to === dateArray[dateArray.length - 1]
      ) {
        return next(action);
      }

      store.dispatch(getNewDataForDate(dates.from, dates.to));

      // COPY N PASTE ACTIONS
      store.dispatch(checkIfCanPaste());
      store.dispatch(checkIfCanDelete());
      return next(action);
    }
    case MAIN_DATE_MOVE_RIGHT:
    case MAIN_DATE_MOVE_LEFT: {
      // STATE BEFORE STATE CHANGE
      const oldDate = store.getState().reducer.mainDateStore.dateArray[0];
      const returnValue = next(action);
      // STATE AFTER STATE CHANGES MADE VIA REDUCER

      const { mainDateStore } = store.getState().reducer;

      const newDate = mainDateStore.dateArray[0];

      // Delete all overtime stats that are not longer relevant to selected time period
      store.dispatch(hideRedundantOvertimeStats(newDate));

      // We calculate monthly norms for schedule cycle
      // when user changes displayed year, because maximal schedule cycle spans 12 months
      const oldYear = oldDate.slice(0, 4);
      const newYear = newDate.slice(0, 4);
      if (oldYear !== newYear) {
        let yearToAdd = parseInt(newYear);
        if (oldYear < newYear) {
          yearToAdd++;
        } else {
          yearToAdd--;
        }
        store.dispatch(getMonthlyNorms(yearToAdd, yearToAdd));
      }

      const [from, to] = getFromToFromDateStore(mainDateStore);
      store.dispatch(getNewDataForDate(from, to));

      // COPY N PASTE ACTIONS
      const side = action.type === MAIN_DATE_MOVE_LEFT ? -1 : 1;
      store.dispatch(moveSelectedColumns(side, mainDateStore.dateMode));
      store.dispatch(checkIfCanPaste());
      store.dispatch(checkIfCanDelete());
      store.dispatch(deselectAllCheckboxes('attendance'));
      return returnValue;
    }
    case MAIN_DATE_MOVE_TO_DATE: {
      const { mainDateStore } = store.getState().reducer;
      const shiftInt = getDateShiftInt(mainDateStore.dateMode, action.payload);
      const dates = generateFromToWithShiftInt(mainDateStore.dateMode, shiftInt);

      store.dispatch(getNewDataForDate(dates.from, dates.to));

      // COPY N PASTE ACTIONS
      store.dispatch(checkIfCanPaste());
      store.dispatch(checkIfCanDelete());
      store.dispatch(moveSelectedColumns(1, mainDateStore.dateMode));
      store.dispatch(deselectAllCheckboxes('attendance'));
      action.shiftInt = shiftInt;
      return next(action);
    }

    case MAIN_DATE_CHANGE_MODE_AND_DATE: {
      const { start, end, dateMode } = action.payload;
      const returnValue = next(action);

      store.dispatch(getNewDataForDate(start, end));

      // COPY N PASTE ACTIONS
      store.dispatch(checkIfCanPaste());
      store.dispatch(checkIfCanDelete());
      if (!isCustomDateMode({ dateMode })) {
        store.dispatch(moveSelectedColumns(1, dateMode));
        store.dispatch(deselectAllCheckboxes('attendance'));
      }
      return returnValue;
    }

    case MAIN_DATE_CHANGE_CUSTOM_DATE: {
      // action.payload contains the MODE that is going to be switched to.

      const dates = { from: action.payload.start, to: action.payload.end };
      store.dispatch(getNewDataForDate(dates.from, dates.to));
      return next(action);
    }
    default:
      return next(action);
  }
};

export default backendDataAndStateUpdateMiddleware;
