import PropTypes from 'prop-types';

import Icon from '../../Basic/Icon/Icon.jsx';
import Popover from '../../Popover/Popover.jsx';
import AttendanceGpsWarningContent from './AttendancesGpsWarningContent.jsx';

import './AttendanceWarningIcon.scss';

const getRelevantAttendaces = attendances =>
  attendances
    .reduce((prev, attendance) => {
      if (attendance.out_of_range_open) {
        prev.push({
          out_of_range: 'open',
          timestamp: attendance.start_timestamp,
          coords: {
            lat: attendance.open_coords.lat,
            lon: attendance.open_coords.lon,
          },
        });
      }
      if (attendance.out_of_range_close) {
        prev.push({
          out_of_range: 'close',
          timestamp: attendance.end_timestamp,
          coords: {
            lat: attendance.close_coords.lat,
            lon: attendance.close_coords.lon,
          },
        });
      }
      return prev;
    }, [])
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

const AttendanceGpsWarningIcon = props => {
  const relevantAttendances = getRelevantAttendaces(props.attendances);
  if (!relevantAttendances.length) return null;

  return (
    <Popover
      position="right"
      updatePosition
      content={<AttendanceGpsWarningContent relevantAttendances={relevantAttendances} />}
    >
      <div className="k-attendanceWarningIcon">
        <Icon name="warning" className="k-icon-warning" />
        <div className="k-attendanceWarningIcon__counter">
          <span className="k-attendanceWarningIcon__number">{relevantAttendances.length || 1}</span>
        </div>
      </div>
    </Popover>
  );
};

AttendanceGpsWarningIcon.propTypes = {
  attendances: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AttendanceGpsWarningIcon;
