import moment from 'moment';

import { absenceErrorMessages } from '@/components/absences/modals/AbsenceAddModal/AbsenceAddModal.messages';
import { FREEMIUM_EVENT_ADD_ABSENCE } from '@/constants/intercom';
import { FREE_DAYS_MARKING_ENABLE, SHORTER_ABSENCE_NAME } from '@/constants/Permissions';
import {
  ABSENCES_FOR_OTHERS_HIDE_ADD,
  FREEMIUM_DISABLE_ADD_ABSENCE,
  SKIP_WEEKENDS_AND_HOLIDAYS_HIDE,
} from '@/constants/Restrictions';
import { getFromToFromDateStore, timestampsToWorkingHours, workingHoursToTimestamp } from '@/utils/dateHelper';
import { saveRecentlyUsedWorkingHours } from '@/utils/localStorageHelpers';
import { getBasicOvertimeCollectionObject } from '@/utils/overtimeHelpers';
import { getInitialRepeatShiftState } from '@/utils/repeatShiftHelpers';
import { getDefaultJobTitleForEmployee } from '@/utils/userEmployeesHelpers';

import { messages } from './AddShiftAndAbsenceModal.messages';

export const getInitialState = (
  {
    absenceTypes,
    mainDateStore,
    modalObject,
    userJobTitles,
    countOnlyDaysWithShiftsSelectedByDefault,
    userPermissions,
  },
  intl,
) => {
  const relevantAbsenceTypes = absenceTypes.filter(type => !type.deleted_at_timestamp);
  const isSkipWeekendsAndHolidaysRestricted = userPermissions.restrictions.includes(SKIP_WEEKENDS_AND_HOLIDAYS_HIDE);
  return {
    errors: {},
    activeTab: 0,
    ...getInitialRepeatShiftState(mainDateStore),

    employee: {},
    shift: {},
    date: '',
    location: {},
    working_hours: '__:__-__:__',
    selectedJobTitle: userJobTitles[0],
    displayRepeat: false,
    comment: '',

    selectedAbsence: modalObject.isOvertimeCollection
      ? getBasicOvertimeCollectionObject(intl)
      : relevantAbsenceTypes.find(type => type.is_default_uw_absence) || relevantAbsenceTypes[0],
    selectedAbsenceRange: {
      start: modalObject.date,
      end: modalObject.date,
    },
    selectedDay: modalObject.date,
    absenceComment: '',
    absenceHours: '__:__-__:__',
    allDay: true,
    absenceOmitWeekends: !isSkipWeekendsAndHolidaysRestricted,
    absenceOmitHolidays: !isSkipWeekendsAndHolidaysRestricted,
    countOnlyDaysWithShifts: countOnlyDaysWithShiftsSelectedByDefault,
    overtimeCycle: {
      day: 1,
      month: moment().month() + 1,
      year: moment().year(),
    },
    multiplier: '1.5',
    amount50: '00:00',
    amount100: '00:00',
    amountPotential: '00:00',
    availableOvertimes: undefined,

    selectedFreeDayId: modalObject?.selectedFreeDayId || null,
  };
};

export const updatedStateForEditOvertimeCollection = (modalObject, employee, intl) => {
  const { date, absence } = modalObject;
  return {
    activeTab: 1,
    absenceHours: timestampsToWorkingHours(absence.start_timestamp, absence.end_timestamp),
    absenceComment: absence.comment,
    selectedDay: date,
    employee,
    selectedAbsence: getBasicOvertimeCollectionObject(intl),
  };
};

export const updatedStateForEditShift = (modalObject, employee) => {
  const { shift, location } = modalObject;
  return {
    employee,
    shift,
    date: shift.date,
    location,
    working_hours: shift.working_hours,
    selectedJobTitle: shift.job_title,
    comment: shift.comment,
  };
};

export const updatedStateForEditFreeDay = ({ employee, selectedFreeDayId, date }) => ({
  activeTab: 2,
  employee,
  selectedFreeDayId,
  date,
});

export const updatedStateForNewShiftOrAbsence = (
  modalObject,
  employee,
  mainDateStore,
  minDate,
  maxDate,
  employeeContracts,
  userJobTitles,
) => {
  const { location, date } = modalObject;
  const [start, end] = getFromToFromDateStore(mainDateStore, minDate, maxDate);
  const selectedJobTitle = modalObject.isLoanEmployee
    ? modalObject.shift.job_title
    : getDefaultJobTitleForEmployee(employee, employeeContracts, userJobTitles, date, modalObject.initialJobTitleId);
  return {
    employee,
    date,
    location,
    selectedJobTitle,
    selectedChoices: [date],
    selectedRange: { start, end },
    selectedAbsenceRange: { start: date, end: date },
    selectedDay: date,
  };
};

export const createUserSidebarObject = employee => ({
  avatar: employee?.avatar?.medium,
  firstName: employee?.first_name,
  lastName: employee?.last_name,
});

const getShiftTab = (modalObject, errors, intl) => {
  const tabMessage = modalObject?.shift ? messages.tabNameEditShift : messages.tabNameAddShift;
  return {
    id: 0,
    name: intl.formatMessage(tabMessage),
    error: errors.working_hours || errors.repeatedShiftOverlap || errors.comment,
    disabled: modalObject.isOvertimeCollection || modalObject.isLoanedEmployee || modalObject.shift?.isLoaned || modalObject.freeDayItemId,
  };
};

const getAbsenceTab = (props, state, intl) => {
  const canEmployeeHasAbsence = state.employee.employment_conditions?.show_absences;
  const canUserAddAbsenceForOthers = !props.userPermissions.restrictions.includes(ABSENCES_FOR_OTHERS_HIDE_ADD);
  const isAbsenceForCurrentUser = props.modalObject.employee.id === props.userPermissions.user_id.toString();
  const tabMessage = props.userPermissions?.permissions?.includes(SHORTER_ABSENCE_NAME)
    ? messages.tabNameAbsenceShorterAbsenceName
    : messages.tabNameAbsence;
  const { errors } = state;
  return {
    id: 1,
    name: intl.formatMessage(tabMessage),
    error: errors.absenceHours || errors.absenceComment || errors.absencesOverlap || errors.requestAbsenceError,
    disabled:
      !!props.modalObject?.shift ||
      (!canUserAddAbsenceForOthers && !isAbsenceForCurrentUser) ||
      !canEmployeeHasAbsence ||
      props.modalObject.freeDayItemId,
    freemiumRestrictions: [FREEMIUM_DISABLE_ADD_ABSENCE],
    intercomEvent: FREEMIUM_EVENT_ADD_ABSENCE,
    showFreemiumStar: props.userPermissions.restrictions.includes(FREEMIUM_DISABLE_ADD_ABSENCE),
  };
};

const getFreeDaysTab = (intl, modalObject) => ({
  id: 2,
  name: intl.formatMessage(modalObject.freeDayItemId ? messages.tabNameMarkFreeDayEdit : messages.tabNameMarkFreeDay),
  disabled: !!modalObject?.shift,
});

export const getTabs = (props, state, intl) => {
  const { modalObject } = props;
  const shiftTab = getShiftTab(props.modalObject, state.errors, intl);
  const absenceTab = getAbsenceTab(props, state, intl);
  const tabs = [shiftTab, absenceTab];
  if (props.userPermissions.permissions.includes(FREE_DAYS_MARKING_ENABLE))
    tabs.push(getFreeDaysTab(intl, modalObject));
  return tabs;
};

export const getErrorMessage = errors =>
  errors.absencesOverlap || errors.requestAbsenceError || errors.overtimeCollectionsExceed || '';

export const getFooterOptions = (props, intl, getObjectHistory) => {
  if (props.modalObject?.shift)
    return [
      {
        icon: 'history',
        text: intl.formatMessage(messages.history),
        handleClick: getObjectHistory,
        disabled: props.modalObject.isLoanedEmployee,
      },
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteShift),
        handleClick: () => props.showDeleteShiftConfirmModal(props.modalObject.employee.id, props.modalObject.shift.id),
        disabled: props.modalObject.deleteDisabled,
      },
    ];
  if (props.modalObject.isOvertimeCollection)
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteOvertimeCollection),
        handleClick: () => props.showDeleteOvertimeCollectionConfirmModal(props.modalObject.absence.id),
      },
    ];
  if (props.modalObject?.freeDayItemId)
    return [
      {
        icon: 'remove_circle_outline',
        text: intl.formatMessage(messages.deleteFreeDay),
        handleClick: () => props.showDeleteFreeDayConfirmModal(props.modalObject.freeDayItemId),
      },
    ];
  return [];
};

export const createItemShift = ({ shift, date, working_hours: workingHours }) => {
  const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = workingHoursToTimestamp(workingHours, date);
  return { id: shift?.id, date, start_timestamp: startTimestamp, end_timestamp: endTimestamp };
};

export const createShift = (props, state) => {
  const { selectedJobTitle, comment, working_hours: workingHours, date, shift } = state;
  const { employee, location } = props.modalObject;
  saveRecentlyUsedWorkingHours(workingHours);
  const createdShift = {
    id: shift.id || '',
    comment,
    working_hours: workingHours,
    employee: { id: employee.id },
    job_title: selectedJobTitle,
    draft: true,
    date,
    location: location || props.selectedLocation,
  };
  return createdShift;
};

export const handleAbsenceRequestError = err => {
  const errorCode = err.code || 'unknownError';
  return absenceErrorMessages[errorCode];
};

export const getInputsToValidateForAbsence = (selectedAbsence, allDay) => {
  const inputs = [];
  if (!allDay && selectedAbsence.defined_in_hours) inputs.push('absenceHours');
  if (selectedAbsence.has_note) inputs.push('absenceComment');
  return inputs;
};
