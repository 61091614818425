import { connect } from 'react-redux';

import { mainDateMoveLeft, mainDateMoveRight } from '@/actions/mainDate.js';

import AttendancesWeekView from './AttendancesWeekView.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  permissions: state.reducer.userPermissions.permissions,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
  userLocations: state.reducer.userLocations,
  jobtitleFilter: state.reducer.jobtitleFilter,
  employmentConditionsFilter: state.reducer.employmentConditionsFilter,
  mainDateStore: state.reducer.mainDateStore,
  absences: state.reducer.absences.scheduleAbsences,
  attendancesSettings: state.reducer.settings.attendancesSettings,
  companyRoles: state.reducer.roles.companyRoles,
  currentUser: state.reducer.currentUser,
  contracts: state.reducer.contracts,
  attendancesData: state.reducer.attendances.attendancesData.attendances,
  employeesNames: state.reducer.employeesNames.data,
  scheduleLoanedEmployees: state.reducer.scheduleLoanedEmployees.scheduleLoanedEmployees,
});

const mapDispatchToProps = {
  mainDateMoveLeft,
  mainDateMoveRight,
};

const AttendancesWeekViewContainer = connect(mapStateToProps, mapDispatchToProps)(AttendancesWeekView);

export default AttendancesWeekViewContainer;
