import { connect } from 'react-redux';

import { hideModal, replaceModal } from '@/actions/uiState.js';
import { ABSENCE_HISTORY_MODAL } from '@/constants/modalTypes.js';

import AbsenceHistoryModal from './AbsenceHistoryModal.jsx';

const mapStateToProps = state => ({
  showModal: state.reducer.uiState.showModal === ABSENCE_HISTORY_MODAL,
  modalObject: state.reducer.uiState.modalObject[ABSENCE_HISTORY_MODAL],
});

const mapDispatchToProps = {
  hideModal,
  replaceModal,
};

const AbsenceHistoryModalContainer = connect(mapStateToProps, mapDispatchToProps)(AbsenceHistoryModal);

export default AbsenceHistoryModalContainer;
