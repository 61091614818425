import { calculateTotalDurationInMinutesForShifts, calculateTotalWageFromShifts } from './schedulerHelpers';

export const filterShifts = (shift, date, options) => {
  const { filterByLocation, filterByJobTitle, selectedJobTitlesIds, selectedLocationsIds } = options;
  return (
    shift.date === date &&
    (filterByLocation ? selectedLocationsIds.includes(shift.location.id) : true) &&
    (filterByJobTitle ? selectedJobTitlesIds.includes(shift.job_title.id) : true)
  );
};

export const calculateDailyEmployeeCount = (relevantEmployees, date, options) => {
  let dailyEmployees = 0;
  const { displayModeType } = options;
  switch (displayModeType) {
    case 'templates':
      dailyEmployees = relevantEmployees
        .map(employee =>
          employee.shifts
            .filter(shift => filterShifts(shift, date, options))
            .reduce((prev, shift) => {
              const amount = parseInt(shift.amount) || 1;
              return prev + amount;
            }, 0),
        )
        .reduce((a, b) => a + b, 0);
      break;
    default:
      dailyEmployees = relevantEmployees.filter(
        employee => employee.shifts.filter(shift => filterShifts(shift, date, options)).length,
      ).length;
      break;
  }

  return dailyEmployees;
};

export const calculateDailyHoursAndCosts = (relevantEmployees, date, options, contracts, jobTitles) => {
  let dailyHours = 0;
  let dailyCosts = 0;
  const dailyHoursPerEmployee = {};
  const dailyCostsPerEmployee = {};
  relevantEmployees.forEach(employee => {
    const filteredShifts = employee.shifts.filter(shift => filterShifts(shift, date, options));
    const hours = calculateTotalDurationInMinutesForShifts(filteredShifts);
    const costs = calculateTotalWageFromShifts(filteredShifts, contracts[employee.id] || [], jobTitles);
    dailyHours += hours;
    dailyHoursPerEmployee[employee.id] = hours;
    dailyCosts += costs;
    dailyCostsPerEmployee[employee.id] = hours;
  });
  return { dailyHours, dailyCosts, dailyHoursPerEmployee, dailyCostsPerEmployee };
};

export const findRelevantBudgetEstimate = (budgetEstimates, date, locationIds) => {
  const budgetEstimate = budgetEstimates.find(
    estimate => estimate.date === date && locationIds.includes(estimate.location_id),
  ) || {
    date,
    location_id: locationIds[0],
    estimated_budget: 0,
    labour_limit: 0,
  };
  return budgetEstimate;
};

export const findRelevantRecommendedHours = (recommendedScheduleValue, selectedJobTitlesIds) =>
  recommendedScheduleValue &&
  recommendedScheduleValue.template &&
  recommendedScheduleValue.template.reduce(
    (sum, template) =>
      selectedJobTitlesIds.includes(template.job_title_id)
        ? sum + Object.values(template.values).reduce((a, b) => a + b * 60, 0)
        : sum,
    0,
  );

export const calculateDailyStats = (
  relevantEmployees,
  dates,
  budgetEstimates,
  recommendedSchedule,
  options = {
    selectedJobTitlesIds: [],
    selectedLocationsIds: [],
    filterByLocation: false,
    filterByJobTitle: false,
    displayModeType: 'schedule',
  },
  contracts,
) => {
  const sumHours = [];
  const sumCosts = [];
  const sumEmployees = [];
  const relevantBudgetEstimates = [];
  const sumRecommendedHours = [];

  const { selectedJobTitlesIds, selectedLocationsIds } = options;

  dates.forEach(date => {
    const { dailyHours, dailyCosts } = calculateDailyHoursAndCosts(relevantEmployees, date, options, contracts);

    const dailyEmployeeCount = calculateDailyEmployeeCount(relevantEmployees, date, options);

    const budgetEstimate = findRelevantBudgetEstimate(budgetEstimates, date, selectedLocationsIds);

    const recommendedScheduleValue = recommendedSchedule.find(
      schedule => schedule.date === date && selectedLocationsIds.includes(schedule.location_id),
    );

    const recommendedHours = findRelevantRecommendedHours(recommendedScheduleValue, selectedJobTitlesIds);

    sumHours.push({ value: dailyHours, date });
    sumCosts.push({ value: dailyCosts, date });
    sumEmployees.push({ value: dailyEmployeeCount, date });
    relevantBudgetEstimates.push(budgetEstimate);
    sumRecommendedHours.push(recommendedHours || 0);
  });

  return { sumHours, sumCosts, sumEmployees, relevantBudgetEstimates, sumRecommendedHours };
};

export const getNumberOfBudgetEstimateItems = period => {
  switch (period) {
    case 'day':
      return 1;
    case 'hour':
      return 24;
    case '15minutes':
      return 96;
    default:
      return null;
  }
};

export const budgetMetricHash = (budgetMetricId, date) => `${budgetMetricId},${date}`;

export const formatBudgetMetricData = budgetMetricData =>
  budgetMetricData.reduce(
    (acc, { budget_metric_id: metricId, date, data }) => ({
      ...acc,
      [budgetMetricHash(metricId, date)]: data,
    }),
    {},
  );
