import { defineMessages } from 'react-intl';

export const validationErrorMessages = defineMessages({
  incorrectWorkingHours: {
    id: 'settings.locations.incorrectWorkingHours',
    defaultMessage: 'Niepoprawne godziny zmian',
  },
  workingHoursOverlaps: {
    id: 'settings.locations.workingHoursOverlaps',
    defaultMessage: 'Godziny zmian nie mogą się pokrywać',
  },
});
