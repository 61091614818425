import { z } from 'zod';

export const reportsSchema = z.object({
  reportsViewFilters: z.object({
    selectedLocationIds: z.array(z.string()),
    selectedJobTitleIds: z.array(z.string()),
    selectedEmploymentConditions: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        company_id: z.string().optional(),
        weekly_working_minutes: z.number().optional(),
        max_daily_working_minutes: z.number().optional(),
        validate_working_rules: z.boolean().optional(),
        is_medical: z.boolean().optional(),
        schedule_cycle: z
          .object({
            month: z.number(),
            year: z.number(),
            duration: z.number(),
          })
          .nullable()
          .optional(),
        elastic_work_day: z.boolean().optional(),
        show_absences: z.boolean().optional(),
        allowed_to_work_at_night_hours: z.boolean().optional(),
        norm_numerator: z.number().nullable().optional(),
        norm_denominator: z.number().nullable().optional(),
        availability_limits: z.array(z.any()).optional(),
        isSelected: z.boolean().optional(),
      }),
    ),
  }),
});
