import { PropTypes } from 'prop-types';
import { memo } from 'react';

import LabeledTextInput from '@/components/common/inputs/LabeledTextInput';
import ToggleBlock from '@/components/common/inputs/ToggleBlock/ToggleBlock';

import { labelMessages, messages, nameMessages } from './CompanySettings.messages';

const SUB_OPTION_KEYS = ['showUnapprovedEmployeeECPData', 'showWagesInEmployeeECP'];

export const CompanySettings = (props, context) => {
  const { companyName, companyToggledOptions, errors, handleInputChange, validateInput } = props;
  return (
    <>
      <h2 className="k-panel__title">{context.intl.formatMessage(messages.companySettings)}</h2>
      <fieldset className="form-group">
        <LabeledTextInput
          value={companyName}
          type="text"
          onChange={handleInputChange}
          onBlur={validateInput}
          placeholder=""
          id="companyName"
          label={context.intl.formatMessage(messages.companyName)}
          error={errors.companyName}
        />
        {Object.keys(companyToggledOptions).map(option => (
          <ToggleBlock
            key={option}
            name={context.intl.formatMessage(nameMessages[option])}
            label={context.intl.formatMessage(labelMessages[option])}
            slug={option}
            isSubItem={SUB_OPTION_KEYS.includes(option)}
            value={companyToggledOptions[option]}
            handleInput={handleInputChange}
          />
        ))}
      </fieldset>
    </>
  );
};

CompanySettings.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};

CompanySettings.propTypes = {
  companyName: PropTypes.string,
  companyToggledOptions: PropTypes.shape({
    availabilitiesArePublic: PropTypes.bool,
    scheduleIsPublicForEmployees: PropTypes.bool,
    sameRankIsVisible: PropTypes.bool,
    countOnlyDaysWithShiftsSelectedByDefault: PropTypes.bool,
  }),
  errors: PropTypes.shape({ companyName: PropTypes.string }),
  handleInputChange: PropTypes.func,
  validateInput: PropTypes.func,
};

export default memo(CompanySettings);
