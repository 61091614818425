import * as React from 'react';

import { AttendanceTableData, Employee, Location, SelectedEmployees } from '@/types';

import EmployeeRow from '../EmployeeRow/EmployeeRow';

interface EmployeesLeftColumn {
  data: AttendanceTableData;
  currentDate: string;
  selectedEmployees: SelectedEmployees[];
  location: Location;
  handleClick: (location: Location, employee: Employee, data: AttendanceTableData) => void;
}

const EmployeesLeftColumn: React.FC<EmployeesLeftColumn> = ({
  data,
  currentDate,
  selectedEmployees,
  location,
  handleClick,
}) => {
  const { relevantEmployees } = data;
  return (
    <>
      {!relevantEmployees.length ? (
        <tr>
          <th className="no-content-to-show" />
        </tr>
      ) : (
        relevantEmployees.map(employee => (
          <EmployeeRow
            key={employee.id}
            employee={employee}
            location={location}
            data={data}
            currentDate={currentDate}
            selectedEmployees={selectedEmployees}
            handleClick={handleClick}
          />
        ))
      )}
    </>
  );
};

export default EmployeesLeftColumn;
