import { connect } from 'react-redux';

import ScheduleTableWeatherCell from './ScheduleTableWeatherCell';

const mapStateToProps = (state, ownProps) => ({
  dayType: state.reducer.schedule.dayTypes[ownProps.date] || 'workDay',
  selectedDisplayModeType: state.reducer.scheduleUIState.selectedDisplayMode.type,
  holidaysEnabled: state.reducer.schedule.viewSettings?.holidays?.value,
  availabilitiesModeEnabled: state.reducer.schedule.viewMode.viewMode === 'availabilities',
  isSingleDay: state.reducer.mainDateStore.dateMode === 'day',
});

export default connect(mapStateToProps)(ScheduleTableWeatherCell);
