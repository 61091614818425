import { connect } from 'react-redux';

import { showModal } from '@/actions/uiState.js';

import EmploymentConditionsButtonBar from './EmploymentConditionsButtonBar.jsx';

const mapStateToProps = state => ({
  listsUi: state.reducer.listsUi,
});

const mapDispatchToProps = {
  showModal,
};

const EmploymentConditionsButtonBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmploymentConditionsButtonBar);

export default EmploymentConditionsButtonBarContainer;
