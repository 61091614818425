import { reducer as toastrReducer } from 'react-redux-toastr';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import * as thunk from 'redux-thunk';

import { historyStoreMiddleware, jobtitlesGroupingMiddleware, notificationMiddleware } from '@/middlewares';
import companyLimitsUsageMiddleware from '@/middlewares/companyLimitsUsage/companyLimitsUsage';
import backendDataAndStateUpdateMiddleware from '@/middlewares/dataUpdates.js';
import { mainDateStoreUpdates } from '@/middlewares/mainDateStoreUpdates/mainDateStoreUpdates';
import payrollUpdatesMiddleware from '@/middlewares/payrollUpdates/payrollUpdates';
import scheduleButtonsMiddleware from '@/middlewares/scheduleButtons/scheduleButtons';
import scheduleChartsMiddleware from '@/middlewares/scheduleCharts/scheduleCharts.js';
import scheduleRowStatisticsMiddleware from '@/middlewares/scheduleRowStatistics/scheduleRowStatistics';
import shiftsSummaryMiddleware from '@/middlewares/shiftsSummary/shiftsSummary.js';
import { uiUpdateMidddleare } from '@/middlewares/uiUpdate';
import { saveFiltersInUrl } from '@/middlewares/urlModify/urlModify';
import backendDataForInitViewUpdateMiddleware from '@/middlewares/viewUpdates.js';
import workingRulesMiddleware from '@/middlewares/workingRules.js';
import { intlReducer } from '@/reducers/intl';
import { routerReducer } from '@/reducers/router/router';
import unauthorizedReducer from '@/reducers/unauthorizedReducer';

import { defaultIntlProps, intlManager } from './intl/intl.utils';
import { rootReducer } from './rootReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  (import.meta.env.VITE_NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const allReducers = combineReducers({
  reducer: rootReducer,
  intl: intlReducer,
  routing: routerReducer,
  toastr: toastrReducer,
  unauthorizedReducer,
});

const store = createStore(
  allReducers,
  { intl: { ...defaultIntlProps } },
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument(intlManager),
      notificationMiddleware(intlManager),
      historyStoreMiddleware,
      jobtitlesGroupingMiddleware,
      uiUpdateMidddleare,
      backendDataAndStateUpdateMiddleware,
      backendDataForInitViewUpdateMiddleware,
      scheduleChartsMiddleware,
      workingRulesMiddleware,
      shiftsSummaryMiddleware,
      scheduleButtonsMiddleware,
      scheduleRowStatisticsMiddleware,
      companyLimitsUsageMiddleware,
      payrollUpdatesMiddleware,
      saveFiltersInUrl,
      mainDateStoreUpdates,
    ),
  ),
);

export default store;
