import moment from 'moment';

import {
  filterAttendances,
  filterShifts,
  getRelevantAttendancesDetails,
  getRelevantAvailabilities,
  getRelevantAvailabilitiesDetails,
  getRelevantShiftsDetails,
  getRowsSummary,
  getShiftRowsSummary,
} from './payrollHelpers';

const getShiftEmployeeRows = (
  employeesToRender,
  payrollSettings,
  mainDateStore,
  multipleLocationFilter,
  selectedJobTitles,
  userCustomTypes,
  overtimeCollections,
  absences,
  absenceTypes,
  options = { showUnpaidAbsences: false },
) =>
  employeesToRender.map(employee => {
    const relevantAvailabilities = getRelevantAvailabilities(
      employee.availability_blocks,
      mainDateStore,
      userCustomTypes,
    ).filter(ava => !ava.draft);
    const relevantAvailabilitiesDetails = getRelevantAvailabilitiesDetails(relevantAvailabilities, true);
    const relevantShifts = filterShifts(employee.shifts, mainDateStore, multipleLocationFilter, selectedJobTitles);
    const relevantShiftDetails = getRelevantShiftsDetails(relevantShifts, employee, payrollSettings);
    const relevantOvertimeCollections = overtimeCollections[employee.id]?.filter(
      absence =>
        !absence.draft && mainDateStore.dateArray.includes(moment(absence.start_timestamp).format('YYYY-MM-DD')),
    );
    const relevantAbsences = (absences[employee.id] || []).reduce((result, absence) => {
      const absenceType = absenceTypes.find(type => type.id === absence.type_id);

      if (
        absence.status === 'accepted' &&
        absence.from <= mainDateStore.customDate.end &&
        absence.to >= mainDateStore.customDate.start &&
        (absenceType.is_paid || options.showUnpaidAbsences)
      ) {
        const dateArray = Array.from({ length: moment(absence.to).diff(absence.from, 'days') + 1 }, (_, index) =>
          moment(absence.from)
            .add(index, 'days')
            .format('YYYY-MM-DD'),
        );
        const filteredDateArray = dateArray.filter(date => !absence.omitted_dates.includes(date));

        return [
          ...result,
          {
            ...absence,
            dateArray: filteredDateArray,
            isAbsence: true,
            short_name: absenceType?.short_name,
            name: absenceType?.name,
            code: absenceType?.code,
            is_paid: absenceType?.is_paid,
          },
        ];
      }

      return result;
    }, []);

    const relevantRowInfo = getShiftRowsSummary(
      relevantShiftDetails,
      relevantAvailabilitiesDetails,
      employee.employment_conditions,
      employee.overtime,
      relevantAvailabilities,
      relevantOvertimeCollections,
      relevantAbsences,
      mainDateStore.customDate,
    );
    return { employee, relevantRowInfo };
  });

const getDefaultEmployeeRows = (
  employeesToRender,
  payrollSettings,
  mainDateStore,
  multipleLocationFilter,
  selectedJobTitles,
  userCustomTypes,
  overtimeCollections,
  absences,
  absenceTypes,
  options = { showUnpaidAbsences: false },
) => {
  const selectedJobtitlesIds = selectedJobTitles.map(j => j.id);
  return employeesToRender.map(employee => {
    const relevantAvailabilities = getRelevantAvailabilities(
      employee.availability_blocks,
      mainDateStore,
      userCustomTypes,
    ).filter(ava => !ava.draft && ava.count_hours_in_payroll);
    const relevantAvailabilitiesDetails = getRelevantAvailabilitiesDetails(relevantAvailabilities, true);
    const relevantAtts = filterAttendances(
      employee.attendances,
      mainDateStore,
      multipleLocationFilter,
      selectedJobtitlesIds,
    ).sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1));
    const relevantAttendancesDetails = getRelevantAttendancesDetails(relevantAtts, employee, payrollSettings, {
      skipUnfinishedBreaks: true,
    });
    const relevantShifts = filterShifts(employee.shifts, mainDateStore, multipleLocationFilter, selectedJobTitles);
    const relevantShiftsDetails = getRelevantShiftsDetails(relevantShifts, employee, payrollSettings);
    const relevantOvertimeCollections = overtimeCollections[employee.id]?.filter(
      absence =>
        !absence.draft && mainDateStore.dateArray.includes(moment(absence.start_timestamp).format('YYYY-MM-DD')),
    );
    const relevantAbsences = (absences[employee.id] || []).reduce((result, absence) => {
      const absenceType = absenceTypes.find(type => type.id === absence.type_id);

      if (
        absence.status === 'accepted' &&
        absence.from <= mainDateStore.customDate.end &&
        absence.to >= mainDateStore.customDate.start &&
        (absenceType.is_paid || options.showUnpaidAbsences)
      ) {
        const dateArray = Array.from({ length: moment(absence.to).diff(absence.from, 'days') + 1 }, (_, index) =>
          moment(absence.from)
            .add(index, 'days')
            .format('YYYY-MM-DD'),
        );
        const filteredDateArray = dateArray.filter(date => !absence.omitted_dates.includes(date));

        return [
          ...result,
          {
            ...absence,
            dateArray: filteredDateArray,
            isAbsence: true,
            short_name: absenceType?.short_name,
            name: absenceType?.name,
            code: absenceType?.code,
            is_paid: absenceType?.is_paid,
          },
        ];
      }

      return result;
    }, []);
    const relevantRowInfo = getRowsSummary(
      relevantAttendancesDetails,
      relevantAvailabilitiesDetails,
      relevantShiftsDetails,
      employee.employment_conditions,
      employee.overtime,
      relevantAvailabilities,
      relevantOvertimeCollections,
      relevantAbsences,
      mainDateStore.customDate,
    );
    return { employee, relevantRowInfo };
  });
};

const getEmployeeRows = (
  employeesToRender,
  payrollSettings,
  mainDateStore,
  multipleLocationFilter,
  selectedJobTitles,
  userCustomTypes,
  overtimeCollections,
  absences,
  absenceTypes,
  options,
) => {
  // Consider strategy pattern when adding new payoutSetting.type
  switch (payrollSettings.payoutSetting.type) {
    case 'shifts':
      return getShiftEmployeeRows(
        employeesToRender,
        payrollSettings,
        mainDateStore,
        multipleLocationFilter,
        selectedJobTitles,
        userCustomTypes,
        overtimeCollections,
        absences,
        absenceTypes,
        options,
      );

    default:
      return getDefaultEmployeeRows(
        employeesToRender,
        payrollSettings,
        mainDateStore,
        multipleLocationFilter,
        selectedJobTitles,
        userCustomTypes,
        overtimeCollections,
        absences,
        absenceTypes,
        options,
      );
  }
};

const calculateEmployeeRowsSummary = employeeRows =>
  employeeRows.reduce(
    (result, row) => {
      const currentRowInfo = row.relevantRowInfo;

      return {
        totalHours: result.totalHours + currentRowInfo.sumHours,
        totalHoursReal: result.totalHoursReal + currentRowInfo.sumHoursReal,
        totalHoursPlanned: result.totalHoursPlanned + currentRowInfo.sumHoursPlanned,
        totalBonuses: result.totalBonuses + currentRowInfo.sumBonuses,
        totalNightHours: result.totalNightHours + currentRowInfo.sumNightHours,
        totalPayout: result.totalPayout + currentRowInfo.sumPayout,
        totalOvertime50: result.totalOvertime50 + currentRowInfo.sumOvertime50,
        totalOvertime100: result.totalOvertime100 + currentRowInfo.sumOvertime100,
        totalAbsences: result.totalAbsences + (currentRowInfo.sumAbsences || 0),
      };
    },
    {
      totalHours: 0,
      totalHoursReal: 0,
      totalHoursPlanned: 0,
      totalBonuses: 0,
      totalNightHours: 0,
      totalPayout: 0,
      totalOvertime50: 0,
      totalOvertime100: 0,
      totalAbsences: 0,
    },
  );

/**
 * This function creates data for payroll location view
 * @param {Array} userEmployees - array of user employees
 * @param {Object} payrollSettings - settings to applyTolerance
 * @param {Object} mainDateStore - we need mainly dateArray, but confy to have the whole object
 * @param {Array} multipleLocationFilter - used to filter by location
 * @param {Array} selectedJobTitles - used to filter by job title
 * @param {Function} employeeRowPredicate - custom filtering of rows
 * @returns {Object} array of employees with releant data added and summary
 */
export const getPayrollLocationData = (
  userEmployees,
  payrollSettings,
  mainDateStore,
  multipleLocationFilter,
  selectedJobTitles,
  userCustomTypes,
  employeeRowPredicate = () => true,
  selectedEmploymentConditions,
  overtimeCollections,
  absences,
  absenceTypes,
  options,
) => {
  // Location ids array
  const selectedLocationsIds = multipleLocationFilter;

  // Filter for proper employees
  const employeesToRender = userEmployees.filter(employee => {
    const employeeLocationsIds = employee.locations.map(l => l.id);
    const employeeAttendancesLocationsIds = employee.attendances.reduce(
      (list, a) => (selectedLocationsIds.includes(a.location.id) ? [...list, a.location.id] : list),
      [],
    );
    const selectedJobtitlesIds = selectedJobTitles.map(j => j.id);

    const isEmployeeLocationSelected = employeeLocationsIds.some(id => selectedLocationsIds.includes(id));
    const isAttendanceLocationSelected = employeeAttendancesLocationsIds.some(id => selectedLocationsIds.includes(id));
    const isEmployeeTermSelected = employee.terms.some(t => selectedJobtitlesIds.includes(t.job_title.id));
    const isEmployeeConditionSelected = selectedEmploymentConditions.some(
      condition => condition.id === employee.employment_conditions.template_id,
    );
    return (
      (isEmployeeLocationSelected || isAttendanceLocationSelected) &&
      isEmployeeTermSelected &&
      isEmployeeConditionSelected
    );
  });

  const relevantEmployeeRows = getEmployeeRows(
    employeesToRender,
    payrollSettings,
    mainDateStore,
    multipleLocationFilter,
    selectedJobTitles,
    userCustomTypes,
    overtimeCollections,
    absences,
    absenceTypes,
    options,
  ).filter(
    row =>
      employeeRowPredicate(row) &&
      // If the sumHours or sumHoutsPlanned is equal to zero, we know for sure there the employee has not been 'active'
      // We always want to show availabilities even if they have 'wlicz do wypłat' and 'Dolicz godziny' set to 0.
      (payrollSettings.showSetting.type !== 'withAtt' ||
        row.relevantRowInfo.sumHours > 0 ||
        row.relevantRowInfo.sumHoursPlanned > 0 ||
        Object.keys(row.relevantRowInfo.sumAvailabilities).length ||
        row.relevantRowInfo.details.length > 0),
  );

  const summary = calculateEmployeeRowsSummary(relevantEmployeeRows);

  return {
    relevantEmployeeRows,
    ...summary,
  };
};
