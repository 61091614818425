import KadroSkeleton from '@/components/common/Skeleton/Skeleton';
import { SKELETON_HEIGHT, SKELETON_WIDTH } from '@/constants/ui.ts';

import './MDTableSummary.scss';

export interface SummaryItem {
  text: string;
  value: string | number;
  unit: string;
}

interface MDTableSummaryProps {
  summary: SummaryItem[];
  isFetching: boolean;
}

const MDTableSummary = ({ summary, isFetching }: MDTableSummaryProps) => (
  <div className="k-mdTableSummary">
    {summary.map((el, index) => (
      <div key={index} className="k-mdTableSummary__item">
        {el.text}:
        <span className="k-mdTableSummary__value">
          {isFetching ? <KadroSkeleton width={SKELETON_WIDTH} height={SKELETON_HEIGHT} /> : el.value}
        </span>
        {el.unit}
      </div>
    ))}
  </div>
);

export default MDTableSummary;
