import { connect } from 'react-redux';

import { changeQuickPlanningAndDisplayMode } from '@/actions/filters';
import { toogleQuickPlanningScheduleMode } from '@/actions/schedule/quickPlanning';
import TopBarDisplayMode from '@/components/TopBars/filters/TopBarDisplayMode/TopBarDisplayMode.jsx';

const mapStateToProps = state => ({
  displayModes: state.reducer.scheduleUIState.displayModes,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode,
  isQuickPlanningMode: state.reducer.schedule.quickPlanning.enabled,
  viewMode: state.reducer.schedule.viewMode.viewMode,
});

const mapDispatchToProps = {
  toogleQuickPlanningScheduleMode,
  changeQuickPlanningAndDisplayMode,
};

const TopBarDisplayModeContainer = connect(mapStateToProps, mapDispatchToProps)(TopBarDisplayMode);

export default TopBarDisplayModeContainer;
