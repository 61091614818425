export const getInitialState = () => ({
  selectedLocations: [],
  takePhotos: false,
  managerGroupLogin: false,
  deviceName: '',
  authCode: '',
  errors: [],
  autologin: false,
  autologinTime: 3,
  loginMethodPin: true,
  loginMethodQr: false,
  loginMethodNfc: false,
  loginMethodRfid: false,
  os: null,
});

export const getSelectedLocations = (userLocations, selectedLocations) =>
  userLocations.reduce(
    (list, location) =>
      selectedLocations.includes(location.id)
        ? [
            ...list,
            {
              value: location.id,
              label: location.name,
            },
          ]
        : list,
    [],
  );
