/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import { createNewShift } from '@/components/scheduler/ScheduleTable/ScheduleSingleDayNewShiftLayer/ScheduleSingleDayNewShiftLayer.helpers';
import { NOT_COUNTED_ABSENCE_STATUSES } from '@/constants/absences';
import { ADD_SHIFT_MODAL } from '@/constants/modalTypes';
import { useAppSelector } from '@/redux-store';
import { selectFreeDay } from '@/redux-store/freeDaysMarking';
import { selectAreShiftsFromOtherLocationsShown } from '@/redux-store/schedule/viewSettings';
import { isEmptyArray } from '@/utils/array/array.helpers';
import { getRelevantContractForDate } from '@/utils/contracts';
import { convertDateToStandardFormat } from '@/utils/dateHelper';

import AvaOverlay from '../AvaMode/AvaOverlay/AvaOverlay.redux';
import { ScheduleFreeDay } from '../ScheduleFreeDay/ScheduleFreeDay';
import ScheduleSingleDayAbsence from '../ScheduleSingleDayAbsence/ScheduleSingleDayAbsence.redux';
import ScheduleSingleDayNewShiftLayer from '../ScheduleSingleDayNewShiftLayer/ScheduleSingleDayNewShiftLayer.redux';
import ScheduleSingleDayOvertimeCollection from '../ScheduleSingleDayOvertimeCollection/ScheduleSingleDayOvertimeCollection';
import ScheduleSingleDayShift from '../ScheduleSingleDayShift/ScheduleSingleDayShift.redux';
import ScheduleTableDropArea from '../ScheduleTableDropArea/ScheduleTableDropArea.redux';
import { getShiftsForDate } from '../ScheduleTableRow/ScheduleTableRow.utils';
import ScheduleTableRowTitle from '../ScheduleTableRowTitle/ScheduleTableRowTitle.redux';
import ScheduleTableSingleDayItem from '../ScheduleTableSingleDayItem/ScheduleTableSingleDayItem';
import { getStylesForSingleFreeDay, getTrHeight } from './ScheduleTableSingleRow.utils';

const ScheduleTableSingleRow = props => {
  const {
    employeeId,
    locationId,
    mainDateStore,
    employee,
    employeeAbsences = [],
    employeeOvertimeCollections = [],
    showModal,
    employeeShifts,
    isAvaDragged,
    availabilitiesModeOn,
    addShift,
    userEmployees,
    employeeContracts,
    userJobTitles,
  } = props;
  const location = { id: locationId };
  const addShiftModalEmployee = userEmployees.find(({ id }) => id === employeeId);
  const date = mainDateStore.dateArray[0];
  const isFreeDay = !!useAppSelector(selectFreeDay(employeeId, date));
  const showShiftsFromOtherLocation = useAppSelector(selectAreShiftsFromOtherLocationsShown);
  const { visible: shiftsForDate, hidden: hiddenShiftsForDate } = getShiftsForDate(
    employee,
    showShiftsFromOtherLocation,
    employeeShifts,
    locationId,
    date,
  );
  const absenceForDate = employeeAbsences.find(
    absence => !NOT_COUNTED_ABSENCE_STATUSES.includes(absence.status) && absence.from <= date && absence.to >= date,
  );
  const overtimeCollectionsForDate = employeeOvertimeCollections.filter(
    overtimeCollection => convertDateToStandardFormat(overtimeCollection.start_timestamp) === date,
  );
  const isShift = shiftsForDate.length > 0;

  const isAbsenceOrOvertimeCollection = !!absenceForDate || !isEmptyArray(overtimeCollectionsForDate);
  const visibleThings = { isAbsenceOrOvertimeCollection, isFreeDay, isShift };
  const trHeight = getTrHeight(visibleThings);
  const addEmployeeShift = async (startTime, endTime) => {
    const newShift = createNewShift(
      employee.id,
      locationId,
      date,
      startTime,
      endTime,
      employeeContracts,
      userJobTitles,
    );
    const shiftId = await addShift(employee, newShift);

    const relevantContract = getRelevantContractForDate(employeeContracts, date);
    if (relevantContract?.job_titles.length > 1) {
      showModal(ADD_SHIFT_MODAL, { employee, shift: { ...newShift, id: shiftId }, location: newShift.location });
    }
  };

  const handleAdd = useCallback(
    async (duration, startTime, endTime) => {
      if (duration > 60) {
        await addEmployeeShift(startTime, endTime);
      } else {
        showModal(ADD_SHIFT_MODAL, {
          employee: addShiftModalEmployee,
          date,
          location: { id: locationId },
        });
      }
    },
    [addEmployeeShift, showModal, addShiftModalEmployee, date, locationId],
  );

  return (
    <tr className="scheduleTable__row" style={{ height: trHeight }}>
      <ScheduleTableRowTitle employeeId={employeeId} key={employeeId} locationId={location.id} />
      <ScheduleTableSingleDayItem
        key={`${employeeId}-${date}`}
        date={date}
        employeeId={employeeId}
        location={location}
        isAvaDragged={isAvaDragged}
      >
        <ScheduleSingleDayNewShiftLayer date={date} employeeId={employeeId} locationId={locationId} onAdd={handleAdd} />
        <ScheduleTableDropArea date={date} employeeId={employee.id} />
        {availabilitiesModeOn ? (
          <AvaOverlay employeeId={employeeId} date={date} isMonthView={false} isSingleDay />
        ) : (
          <>
            {shiftsForDate.map(({ id }) => (
              <ScheduleSingleDayShift
                key={id}
                shiftId={id}
                employeeId={employeeId}
                currentLocationId={location.id}
                absenceForDate={absenceForDate}
              />
            ))}
            {absenceForDate && (
              <ScheduleSingleDayAbsence
                absence={absenceForDate}
                shiftsForDate={shiftsForDate}
                hiddenShiftsForDate={hiddenShiftsForDate}
                employeeId={employee.id}
              />
            )}
            {overtimeCollectionsForDate.map(overtime => (
              <ScheduleSingleDayOvertimeCollection
                key={overtime.id}
                overtimeCollection={overtime}
                dayHasAtLeastOneShift={isShift}
                showModal={showModal}
                employee={employee}
              />
            ))}
            <div style={getStylesForSingleFreeDay(visibleThings)}>
              <ScheduleFreeDay {...{ date, employeeId }} />
            </div>
          </>
        )}
      </ScheduleTableSingleDayItem>
    </tr>
  );
};

ScheduleTableSingleRow.propTypes = {
  employeeId: PropTypes.string,
  locationId: PropTypes.string,
  mainDateStore: PropTypes.shape({
    dateArray: PropTypes.arrayOf(PropTypes.string),
  }),
  employee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    shifts: PropTypes.shape({}),
    terms: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  employeeAbsences: PropTypes.arrayOf(PropTypes.shape({})),
  employeeOvertimeCollections: PropTypes.arrayOf(PropTypes.shape({})),
  showModal: PropTypes.func,
  employeeShifts: PropTypes.shape({}),
  isAvaDragged: PropTypes.bool,
  availabilitiesModeOn: PropTypes.bool,
  addShift: PropTypes.func,
  userEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
      terms: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  userJobTitles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(ScheduleTableSingleRow);
