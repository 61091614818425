import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteShiftTitle: {
    id: 'scheduleTable.row.deleteShiftTitle',
    defaultMessage: 'Usuń zmianę',
  },
  deleteShiftDescription: {
    id: 'scheduleTable.row.deleteShiftDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć zmianę dla {employeeName}?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});
