import { connect } from 'react-redux';

import ScheduleTableWeatherPreview from './ScheduleTableWeatherPreview';

const mapStateToProps = state => ({
  numberOfDaysInSchedule: state.reducer.mainDateStore.dateArray.length,
  today: state.reducer.mainDateStore.today,
});

export default connect(mapStateToProps)(ScheduleTableWeatherPreview);
