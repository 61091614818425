import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  copy: {
    id: 'quickPlanningButtons.tooltip.copy',
    defaultMessage: 'Kopiuj',
  },
  paste: {
    id: 'quickPlanningButtons.tooltip.paste',
    defaultMessage: 'Wklej',
  },
  delete: {
    id: 'quickPlanningButtons.tooltip.delete',
    defaultMessage: 'Usuń',
  },
  deselect: {
    id: 'quickPlanningButtons.tooltip.deselect',
    defaultMessage: 'Odznacz',
  },
});
