import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/redux-store';
import { transformToBoolDict } from '@/utils/objectHelpers/objectHelpers';

export const selectIsLoggedUserEmployee = (state: RootState) => state.reducer.userPermissions.isEmployee;

export const selectUserPermissions = (state: RootState) => state.reducer.userPermissions;

export const selectRestrictions = (state: RootState) => state.reducer.userPermissions.restrictions;

export const selectPermissions = (state: RootState) => state.reducer.userPermissions.permissions;

export const selectPermissionsDict = createSelector([selectPermissions], permissions =>
  transformToBoolDict(permissions),
);

export const selectRestrictionsDict = createSelector([selectRestrictions], restrictions =>
  transformToBoolDict(restrictions),
);

export const selectIsPermissionGranted = (permission: string) => (state: RootState) =>
  state.reducer.userPermissions.permissions.includes(permission);
