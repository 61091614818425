import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { MDPasswordInput } from '@/components/common/inputs/MDComponents.jsx';
import { useInputChange } from '@/hooks';
import { createEvent } from '@/utils/inputHelpers.js';

import SettingsSection from '../../SettingsSection/SettingsSection';
import {
  checkIfCanSave,
  getInitialState,
  getValueToCompare,
  handleRequestError,
  passwordInputsModifiers,
} from './ChangePasswordBlock.helpers';
import { messages } from './ChangePasswordBlock.messages';

import './ChangePasswordBlock.scss';

const ChangePasswordBlock = (props, { intl }) => {
  const [state, handlers] = useInputChange(getInitialState(), intl, { preventClearErrors: true });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.password || state.passwordConfirm) {
      validateAll();
    }
  }, [state.password, state.passwordConfirm]);

  const validateAll = async () => {
    const inputs = ['password', 'passwordConfirm'];
    const validationResult = await Promise.all(
      inputs.map(inputName =>
        handlers.validateInput({
          ...createEvent(inputName, state[inputName]),
          valueToCompare: getValueToCompare(state, inputName),
        }),
      ),
    );
    return !Object.values(validationResult).some(err => err !== '');
  };

  const onSubmit = useCallback(async () => {
    const valid = await validateAll();
    if (!valid) return;
    try {
      setLoading(true);
      await props.changePassword(state.passwordCurrent, state.password);
      handlers.setInitState();
    } catch (err) {
      const [inputName, errorMessage] = handleRequestError(err) || [];
      if (inputName) handlers.setError(inputName, errorMessage);
    } finally {
      setLoading(false);
    }
  }, [state]);

  return (
    <SettingsSection
      canSave={!loading && checkIfCanSave(state)}
      title={intl.formatMessage(messages.changePassword)}
      onSubmit={onSubmit}
      submitMessage={intl.formatMessage(messages.changePassword)}
    >
      <form autoComplete="off" className="changePasswordBlock">
        <MDPasswordInput
          value={state.passwordCurrent}
          onChange={handlers.changeInput}
          id="passwordCurrent"
          label={intl.formatMessage(messages.currentPassword)}
          errorMessage={state.errors.passwordCurrent}
          modifiers={passwordInputsModifiers}
        />
        <MDPasswordInput
          value={state.password}
          onChange={handlers.changeInput}
          onBlur={handlers.validateInput}
          id="password"
          label={intl.formatMessage(messages.newPassword)}
          errorMessage={state.errors.password}
          modifiers={passwordInputsModifiers}
        />
        <MDPasswordInput
          value={state.passwordConfirm}
          onChange={e => handlers.changeInput({ ...e, valueToCompare: state.password })}
          onBlur={validateAll}
          id="passwordConfirm"
          label={intl.formatMessage(messages.passwordConfirm)}
          errorMessage={state.errors.passwordConfirm}
          modifiers={passwordInputsModifiers}
        />
      </form>
    </SettingsSection>
  );
};

ChangePasswordBlock.contextTypes = {
  intl: PropTypes.shape({}),
};

ChangePasswordBlock.propTypes = {
  changePassword: PropTypes.func,
};

export default ChangePasswordBlock;
