import { connect } from 'react-redux';

import EmployeeScheduleTableHeader from './EmployeeScheduleTableHeader';

const mapStateToProps = state => ({
  holidays: state.reducer.calendarData.holidays,
  mainDateStore: state.reducer.mainDateStore,
  holidaysEnabled: state.reducer.schedule.viewSettings?.holidays?.value,
  scheduleLocationFilter: state.reducer.scheduleLocationFilter,
});

export default connect(mapStateToProps)(EmployeeScheduleTableHeader);
