import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SmallToggle from '@/components/common/inputs/SmallToggle.jsx';
import FeatureWrapper from '@/components/common/KadroFeatureWrapper/KadroFeatureWrapper.redux';
import SearchBox from '@/components/common/SearchBox.jsx';
import { COMPANY_MANAGE_EMPLOYEES_HIDE, INACTIVE_EMPLOYEES_HIDE } from '@/constants/Restrictions.js';

import EmployeesTable from '../EmployeesTable/EmployeesTable.redux.js';

class ManageEmployeesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
      inactive: false,
    };
    this.getSearchWord = this.getSearchWord.bind(this);
    this.clearSearchWord = this.clearSearchWord.bind(this);
    this.toggleInactive = this.toggleInactive.bind(this);
  }

  getSearchWord(e) {
    this.setState({ searchWord: e.target.value });
  }

  clearSearchWord() {
    this.setState({ searchWord: '' });
  }

  toggleInactive(checked) {
    this.props.deselectAllCheckboxes('employees');
    this.setState({ inactive: checked });
  }

  render() {
    return (
      <FeatureWrapper restriction={COMPANY_MANAGE_EMPLOYEES_HIDE}>
        <FeatureWrapper restriction={INACTIVE_EMPLOYEES_HIDE}>
          <div style={{ padding: '10px 50px 10px 50px', float: 'left' }}>
            <SmallToggle
              onChange={this.toggleInactive}
              checked={this.state.inactive}
              left={<FormattedMessage id="companymanage.employees.inactiveSwitchOff" defaultMessage="aktywni" />}
              right={<FormattedMessage id="companymanage.employees.inactiveSwitchOn" defaultMessage="nieaktywni" />}
              testId="toggleActiveEmployees"
            />
          </div>
        </FeatureWrapper>
        <SearchBox getSearchWord={this.getSearchWord} value={this.state.searchWord} testId="employeesView" />
        <div className="k-wrapper k-wrapper--rightScroll animated fadeInRight">
          <EmployeesTable
            searchWord={this.state.searchWord}
            inactive={this.state.inactive}
            clearSearchWord={this.clearSearchWord}
          />
        </div>
      </FeatureWrapper>
    );
  }
}

ManageEmployeesView.propTypes = {
  deselectAllCheckboxes: PropTypes.func,
};

export default ManageEmployeesView;
