import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@/components/common/Basic/Button';

import { messages } from '../addAndEditLabelPopoverContent.messages.tsx';

interface AddAndEditLabelPopoverContentFooterProps {
  onHide: () => void;
  onSubmit: () => void;
  option: { id: string };
  showModalToDeleteLabel: (labelIds: string[]) => void;
  hideDeleteButton?: boolean;
}

const CANCEL_BUTTON_MODIFIRES = ['uppercase', 'reverse-orange', 'popover', 'marginRight'];

const SUBMIT_BUTTON_MODIFIRES = ['uppercase', 'orange', 'popover'];

export const AddAndEditLabelPopoverContentFooter = (
  { onHide, onSubmit, option, hideDeleteButton, showModalToDeleteLabel }: AddAndEditLabelPopoverContentFooterProps,
  { intl },
) => {
  const footerButtonsClassNames = classnames('k-AddAndEditLabelPopoverContent__footerButtons', {
    'k-AddAndEditLabelPopoverContent__footerButtons--spaceBetween': hideDeleteButton,
  });

  const deleteLabel = () => {
    showModalToDeleteLabel([option.id]);
    onHide();
  };

  return (
    <div className="k-AddAndEditLabelPopoverContent__footer">
      {!hideDeleteButton && (
        <button className="k-AddAndEditLabelPopoverContent__deleteButton" onClick={deleteLabel}>
          <span className="material-icons k-AddAndEditLabelPopoverContent__deleteIcon">delete</span>
          <span className="k-AddAndEditLabelPopoverContent__deleteText">{intl.formatMessage(messages.delete)}</span>
        </button>
      )}
      <div className={footerButtonsClassNames}>
        <Button
          className="k-AddAndEditLabelPopoverContent__footerButton"
          modifiers={CANCEL_BUTTON_MODIFIRES}
          onClick={onHide}
        >
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          className="k-AddAndEditLabelPopoverContent__footerButton"
          modifiers={SUBMIT_BUTTON_MODIFIRES}
          onClick={onSubmit}
        >
          {intl.formatMessage(messages.save)}
        </Button>
      </div>
    </div>
  );
};

// @ts-ignore
AddAndEditLabelPopoverContentFooter.contextTypes = {
  intl: PropTypes.shape({}),
};

export default AddAndEditLabelPopoverContentFooter;
