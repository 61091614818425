import { useCallback, useMemo } from 'react';

import { switchAccount } from '@/actions/multiAccounts/multiAccounts';
import { showModal } from '@/actions/uiState';
import { PROFILE_DETAILS_MODAL } from '@/constants/modalTypes';
import { useAppDispatch } from '@/redux-store';
import { getAmountOfHiddenItems } from '@/utils/array/array.helpers';

import { UseMultiAccountsMenuValuesType } from '../useMultiAccountsMenu';

export const useMultiAccountsInstance = ({
  jobTitlesNames,
  locationsNames,
  roleName,
  userId,
}: UseMultiAccountsMenuValuesType['accountsDetails'][number]) => {
  const dispatch = useAppDispatch();
  const additionalLocationsAmount = useMemo(() => getAmountOfHiddenItems(locationsNames), [locationsNames]);
  const additionalJobTitlesAmount = useMemo(() => getAmountOfHiddenItems(jobTitlesNames), [jobTitlesNames]);

  const onSwitchAccount = useCallback(() => {
    dispatch(switchAccount(userId));
  }, [dispatch, userId]);

  const openModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      dispatch(showModal(PROFILE_DETAILS_MODAL, { userId }));
    },
    [dispatch, userId],
  );

  return { additionalJobTitlesAmount, additionalLocationsAmount, onSwitchAccount, openModal, roleName };
};
