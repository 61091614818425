import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import ButtonBar from '@/components/common/Basic/ButtonBar/ButtonBar.jsx';
import { EMPLOYMENT_CONDITIONS_MODAL } from '@/constants/modalTypes.js';

const EmploymentConditionsButtonBar = props => (
  <ButtonBar>
    <Button
      onClick={() => {
        props.showModal(EMPLOYMENT_CONDITIONS_MODAL);
      }}
      modifiers="blue medium inline pushLeft"
    >
      <FormattedMessage id="companyManage.employmentConditions.add" defaultMessage="Dodaj warunek zatrudnienia" />
    </Button>
  </ButtonBar>
);

EmploymentConditionsButtonBar.propTypes = {
  showModal: PropTypes.func,
  listsUi: PropTypes.shape({
    employmentConditions: PropTypes.shape({
      selected: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
};

export default EmploymentConditionsButtonBar;
