import PropTypes from 'prop-types';

import AvaBlock from '../AvaBlock/AvaBlock.redux.js';
import AvaCell from '../AvaCell/AvaCell.redux.js';
import AvaCorner from '../AvaCorner/AvaCorner.jsx';

const AvaOverlay = ({
  avaBlock,
  availabilitiesModeOn,
  employeeId,
  date,
  isMonthView,
  isSingleDay,
}) => {
  if (!availabilitiesModeOn && !avaBlock) return null;
  if (!availabilitiesModeOn) return <AvaCorner {...avaBlock} />;
  if (!avaBlock) return <AvaCell employeeId={employeeId} date={date} />;

  return (
    <AvaCell isMonthView={isMonthView}>
      <AvaBlock
        avaBlock={avaBlock}
        employeeId={employeeId}
        date={date}
        isMonthView={isMonthView}
        isSingleDay={isSingleDay}
      />
    </AvaCell>
  );
};

AvaOverlay.propTypes = {
  avaBlock: PropTypes.shape({
    color: PropTypes.string,
    comment: PropTypes.string,
    hours: PropTypes.string,
    popover: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  availabilitiesModeOn: PropTypes.bool,
  employeeId: PropTypes.string,
  date: PropTypes.string,
  isMonthView: PropTypes.bool,
  isSingleDay: PropTypes.bool,
};
export default AvaOverlay;
