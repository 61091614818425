import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  download: {
    id: 'exportsWidget.status.download',
    defaultMessage: 'Pobierz',
  },
  error: {
    id: 'exportsWidget.status.error',
    defaultMessage: 'Wystąpił błąd podczas generowania',
  },
  inProgress: {
    id: 'exportsWidget.status.inProgress',
    defaultMessage: 'Trwa generowanie...',
  },
});

const ExportsWidgetRowStatus = ({ link, status, downloadExport, id }, { intl }) => {
  if (status === 'error')
    return <span className="k-exportsWidgetRow__error"> {intl.formatMessage(messages.error)}</span>;
  if (!link) return <span className="k-exportsWidgetRow__inProgress"> {intl.formatMessage(messages.inProgress)}</span>;
  return (
    <a onClick={() => downloadExport(id)} className="k-exportsWidgetRow__download" role="presentation">
      {intl.formatMessage(messages.download)}
    </a>
  );
};

ExportsWidgetRowStatus.contextTypes = {
  intl: PropTypes.shape({}).isRequired,
};
ExportsWidgetRowStatus.propTypes = {
  id: PropTypes.string,
  link: PropTypes.string,
  status: PropTypes.string,
  downloadExport: PropTypes.func,
};

export default ExportsWidgetRowStatus;
