import { defineMessages } from 'react-intl';

import Icon from '@/components/common/Basic/Icon/Icon.jsx';

const messages = defineMessages({
  ctrl: {
    id: 'shortcuts.ctrl',
    defaultMessage: 'Zaznacz wiele komórek',
  },
  shift: {
    id: 'shortcuts.shift',
    defaultMessage: 'Zaznacz zakres komórek',
  },
  p: {
    id: 'shortcuts.p',
    defaultMessage: 'Otwórz boczny panel Publikuj',
  },
  b: {
    id: 'shortcuts.b',
    defaultMessage: 'Pokaż/Ukryj Budżet',
  },
  arrows: {
    id: 'shortcuts.arrows',
    defaultMessage: 'Zmień okres wstecz / w przód',
  },
  delete: {
    id: 'shortcuts.delete',
    defaultMessage: 'Usuń zaznaczone komórki',
  },
  o: {
    id: 'shortcuts.o',
    defaultMessage: 'Pokaż/Ukryj Otwarte zmiany',
  },
  a: {
    id: 'shortcuts.a',
    defaultMessage: 'Włącz tryb dostępności',
  },
});

export const getKeyboardShortcuts = intl => [
  {
    text: 'Ctrl',
    description: intl.formatMessage(messages.ctrl),
  },
  {
    text: 'Shift',
    description: intl.formatMessage(messages.shift),
  },
  {
    text: 'P',
    description: intl.formatMessage(messages.p),
  },
  {
    text: 'B',
    description: intl.formatMessage(messages.b),
  },
  {
    text: (
      <>
        <Icon name="arrow_back" />
        <Icon name="arrow_forward" />
      </>
    ),
    description: intl.formatMessage(messages.arrows),
    wide: true,
  },
  {
    text: `Delete
    Backspace`,
    description: intl.formatMessage(messages.delete),
    wide: true,
  },
  {
    text: 'O',
    description: intl.formatMessage(messages.o),
  },
  {
    text: 'A',
    description: intl.formatMessage(messages.a),
  },
];
