import { defineMessages } from 'react-intl';

const messages = defineMessages({
  deleteOpenShiftsFromSelectedCellsConfirm: {
    id: 'deleteSelectedOpenShifts.confirm',
    defaultMessage: 'Usuń',
  },
  deleteOpenShiftsFromSelectedCellsTitle: {
    id: 'deleteSelectedOpenShifts.title',
    defaultMessage: 'Czy usunąć zaznaczone otwarte zmiany?',
  },
  deleteOpenShiftsFromSelectedCellsMessage: {
    id: 'deleteSelectedOpenShifts.message',
    defaultMessage:
      'Zaznaczono {shiftNo, plural,  one {1 otwartą zmianę} =2 {2 otwarte zmiany} =3 {3 otwarte zmiany} =4 {4 otwarte zmiany} other {# otwartych zmian}}. Czy na pewno chcesz je usunąć?',
  },
  deleteOpenShiftTitle: {
    id: 'openShift.deleteOpenShiftTitle',
    defaultMessage: 'Usuń otwartą zmianę',
  },
  deleteOpenShiftDescription: {
    id: 'openShift.deleteOpenShiftDescription',
    defaultMessage: 'Czy na pewno chcesz usunąć otwartą zmianę?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Usuń',
  },
});

export default messages;
