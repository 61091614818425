/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import AutoGenerateCreateSchedule from '@/components/autoscheduler/AutoGenerateCreateSchedule/AutoGenerateCreateSchedule.redux.js';
import { createFlexScheduleGrid,userTemplateToRecommendedSchedule } from '@/utils/autoschedulerHelpers.jsx';
import { pad } from '@/utils/baseHelpers.js';

import LeftHeaderFlex from './LeftHeaderFlex.jsx';

import './ScheduleTemplateFlex.scss';

const getInitialState = props => ({
  schedule: {},
  maxValues: {},
  scheduleTemplate: userTemplateToRecommendedSchedule(props.currentTemplate.shifts),
});

const getMaxValue = scheduleForDay =>
  Object.values(scheduleForDay).reduce((acc, jobTitleValues) => {
    const values = jobTitleValues.filter(v => v !== '-');

    if (!values.length) return acc;

    const jobTitleMax = Math.max(...values);
    if (jobTitleMax > acc) {
      return jobTitleMax;
    }
    return acc;
  }, 0);

const ScheduleTemplateFlexTable = props => {
  const [state, setState] = useState(getInitialState(props));

  const setSchedule = schedule => {
    setState(prev => ({ ...prev, schedule }));
  };

  const setMaxValue = (selectedDay, scheduleForDay) => {
    const newDayMax = getMaxValue(scheduleForDay);
    setState(prev => ({
      ...prev,
      maxValues: {
        ...prev.maxValues.maxValues,
        [selectedDay]: newDayMax,
      },
    }));
  };

  const changeHourValue = (value, hour, jobTitleId, selectedDay) => {
    const workingHours = `${pad(hour)}:00-${pad(hour + 1)}:00`;

    const shift = {
      working_hours: workingHours,
      amount: value,
      draft: true,
      date: selectedDay,
      job_title_id: jobTitleId,
    };

    props.changeFlexTemplateSingleShift(shift);
  };

  useEffect(() => {
    const formattedTemplate = userTemplateToRecommendedSchedule(props.currentTemplate.shifts);
    const newSchedule = createFlexScheduleGrid(props.userJobTitles, formattedTemplate);
    const newDayMax = getMaxValue(newSchedule[props.selectedDay]);

    setState(prev => ({
      ...prev,
      schedule: newSchedule,
      maxValues: {
        ...prev.maxValues,
        [props.selectedDay]: newDayMax,
      },
    }));
  }, [props.currentTemplate, props.selectedDay]);

  return (
    <div className="scheduleTemplateFlex">
      <AutoGenerateCreateSchedule
        changeHourValue={changeHourValue}
        customLeftHeader={<LeftHeaderFlex />}
        maxValues={state.maxValues}
        setMaxValue={setMaxValue}
        schedule={state.schedule}
        setSchedule={setSchedule}
        showTitle={false}
      />
    </div>
  );
};

ScheduleTemplateFlexTable.propTypes = {
  userJobTitles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  currentTemplate: PropTypes.shape({
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        working_hours: PropTypes.string,
        amount: PropTypes.string,
        draft: PropTypes.bool,
        date: PropTypes.number,
        job_title: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    ),
  }),
  selectedDay: PropTypes.number,
  changeFlexTemplateSingleShift: PropTypes.func,
};

export default ScheduleTemplateFlexTable;
