import { FreeDayItem } from '@/redux-store/freeDaysMarking';
import { Shift } from '@/types/shifts.types';

export type DeleteScheduleItemsModalObject = {
  shiftIds: Shift['id'][];
  freeDayIds: FreeDayItem['id'][];
};

export enum DeleteScheduleItemEnum {
  SHIFTS = 'shifts',
  FREE_DAYS_MARKINGS = 'freeDaysMarkings',
}

export type DeleteScheduleItem = {
  id: DeleteScheduleItemEnum;
  label: string;
};
