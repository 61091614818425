import { connect } from 'react-redux';

import AttachEmployee from '@/components/common/AttachEmployee.jsx';

const mapStateToProps = state => ({
  userEmployees: state.reducer.userEmployees,
  userJobTitles: state.reducer.userJobTitles,
  userLocations: state.reducer.userLocations,
  userPermissions: state.reducer.userPermissions,
});

const mapDispatchToProps = {};

const AttachEmployeeContainer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  AttachEmployee,
);

export default AttachEmployeeContainer;
