import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@/components/common/Basic/Button.jsx';
import { NEW_CHAT_MODAL } from '@/constants/modalTypes';

import './ChatTopBar.scss';

const buttonModifiers = ['orange', 'small'];

const ChatTopBar = ({ showModal }) => {
  const showNewChatModal = useCallback(() => showModal(NEW_CHAT_MODAL), []);

  return (
    <div className="chatTopBar">
      <Button onClick={showNewChatModal} modifiers={buttonModifiers} className="chatTopBar__button">
        <i className="material-icons">send</i>
        <FormattedMessage id="chat.topBar.newChat" defaultMessage="Nowy czat" />
      </Button>
    </div>
  );
};

ChatTopBar.propTypes = {
  showModal: PropTypes.func,
};

export default ChatTopBar;
