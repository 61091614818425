import classnames from 'classnames';
import * as React from 'react';

import Checkbox from '@/components/common/inputs/Checkbox';

import './MDCheckboxTableCheckbox.scss';

interface MDCheckboxTableCheckboxProps {
  option: {
    value: boolean;
    disabled: boolean;
    id: string;
  };
  handleOptionChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  row: {
    id: string;
  };
}

const MdCheckboxTableCheckbox = ({ option, handleOptionChange, row }: MDCheckboxTableCheckboxProps) => {
  const { disabled, value } = option;

  const checkboxClasses = classnames('kmd-checkboxTableCheckbox', {
    'kmd-checkboxTableCheckbox__disabledAndChecked': disabled && value,
  });
  return (
    <Checkbox
      className={checkboxClasses}
      checked={option.value}
      toggle={e => handleOptionChange(e, row.id)}
      disabled={option.disabled}
      name={option.id}
    />
  );
};

export default MdCheckboxTableCheckbox;
