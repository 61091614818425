import { connect } from 'react-redux';

import { addAvaType, changeAvaType,toggleModal } from '@/actions/companymanage';

import AddAvaTypeModal from './AddAvaTypeModal';

const mapStateToProps = state => ({
  visible: state.reducer.listsUi.avaTypes.showModal,
  editedId: state.reducer.listsUi.avaTypes.editedId,
  userCustomTypes: state.reducer.userCustomTypes,
});

const mapDispatchToProps = {
  toggleModal,
  addAvaType,
  changeAvaType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAvaTypeModal);
