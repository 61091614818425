import { RESTRICTIONS } from 'kadro-helpers/lib/helpers';
import { connect } from 'react-redux';

import ScheduleBudgetTableMetrics from './ScheduleBudgetTableMetrics';

const mapStateToProps = state => ({
  dateArray: state.reducer.mainDateStore.dateArray,
  budgetMetrics: state.reducer.budgetMetrics,
  activeLocationIds: state.reducer.scheduleLocationFilter,
  budgetMetricsData: state.reducer.budgetMetricsData,
  canEdit: !state.reducer.userPermissions.restrictions.some(restriction => [
    RESTRICTIONS.BUDGET_TARGETS_HIDE,
    RESTRICTIONS.BUDGET_INFO_HIDE,
    RESTRICTIONS.BUDGET_TARGETS_DISABLE_EDIT,
    RESTRICTIONS.BUDGET_INFO_DISABLE_EDIT,
  ].includes(restriction)),
});

export default connect(mapStateToProps)(ScheduleBudgetTableMetrics);
