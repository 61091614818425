import { connect } from 'react-redux';

import { showConfirmModal } from '@/actions';

import AddShiftBasic from './AddShiftBasic.jsx';

const mapStateToProps = state => ({
  locationSettings: state.reducer.settings.locationSettings,
  selectedDisplayMode: state.reducer.scheduleUIState.selectedDisplayMode,
  userShiftblocks: state.reducer.userShiftblocks,
  multipleLocationFilter: state.reducer.multipleLocationFilter,
  minDate: state.reducer.mainDateStore.customDate.start,
  maxDate: state.reducer.mainDateStore.customDate.end,
});

const mapDispatchToProps = {
  showConfirmModal,
};

const AddShiftBasicContainer = connect(mapStateToProps, mapDispatchToProps)(AddShiftBasic);

export default AddShiftBasicContainer;
