/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import ColorPicker from '../../ColorPicker';

type Props = {
  color: string;
  onChange: (event: { target: { value: Props['color']; name: Props['name'] } }) => void;
  name: string;
};

export const MDColorInput = ({ color, name, onChange }: Props) => {
  const handleChange = useCallback(
    (newColor: Props['color']) => {
      onChange({ target: { value: newColor, name } });
    },
    [name, onChange],
  );

  return (
    <fieldset className="form-group">
      <label className="k-textInput__label k-textInput__label--side">
        <FormattedMessage id="schedule.managerNotesModal.color" defaultMessage="Kolor" />
      </label>
      <ColorPicker color={color} colorFunc={handleChange} />
    </fieldset>
  );
};
