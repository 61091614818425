import PropTypes from 'prop-types';
import { useEffect } from 'react';

import TopBarSingleChoice from '@/components/TopBars/common/TopBarSingleChoice/TopBarSingleChoice.jsx';
import { AVAILABILITIES_DISPLAY_MODE_TYPE } from '@/constants/scheduleDisplayModes';

const TopBarDisplayMode = (
  {
    displayModes,
    selectedDisplayMode,
    isQuickPlanningMode,
    toogleQuickPlanningScheduleMode,
    viewMode,
    changeQuickPlanningAndDisplayMode,
  },
  { intl },
) => {
  const options = displayModes.map(option => {
    const { message, ...restOfOption } = option;
    return {
      ...restOfOption,
      displayText: intl.formatMessage(message),
    };
  });

  useEffect(() => {
    if (
      viewMode === AVAILABILITIES_DISPLAY_MODE_TYPE &&
      isQuickPlanningMode
    ) {
      toogleQuickPlanningScheduleMode();
    }
  }, [viewMode]);

  return (
    <div className="k-topBarDisplayMode k-topBar__leftElement">
      <TopBarSingleChoice
        selected={selectedDisplayMode}
        objectsToChoose={options}
        onChange={changeQuickPlanningAndDisplayMode}
        displayKey="displayText"
        hideSearch
        className="k-topBarChoice--displayMode"
      />
    </div>
  );
};

TopBarDisplayMode.contextTypes = {
  intl: PropTypes.shape({}),
};

TopBarDisplayMode.propTypes = {
  selectedDisplayMode: PropTypes.shape({}),
  displayModes: PropTypes.arrayOf(PropTypes.shape()),
  changeQuickPlanningAndDisplayMode: PropTypes.func,
  isQuickPlanningMode: PropTypes.bool,
  toogleQuickPlanningScheduleMode: PropTypes.func,
  viewMode: PropTypes.string,
};

export default TopBarDisplayMode;
