import { connect } from 'react-redux';

import { hideModalAndLaterClearModalObject } from '@/actions/uiState';
import { IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL } from '@/constants/modalTypes';

import ImportShiftAndAttendanceErrorModal from './ImportShiftAndAttendanceErrorModal';

const mapStateToProps = state => ({
  show: state.reducer.uiState.showModal === IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL,
  errors: state.reducer.uiState.modalObject[IMPORT_SHIFT_AND_ATTENDANCE_ERROR_MODAL]?.errors,
});

const mapDispatchToProps = {
  hideModalAndLaterClearModalObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportShiftAndAttendanceErrorModal);
